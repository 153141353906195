import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { GroupedOccupation } from 'models/groupedOccupations'
import { VitalsData } from 'models/vitalsData'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getEmailOfPatient,
  getIdentifierValueBySystem,
} from 'utils/fhirResourcesHelper'
import {
  getAddictionsDataFromObs,
  getSortedObservationForAddiction,
} from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { getPatientIdentifiersForSearch } from 'utils/fhirResoureHelpers/patientHelpers'
import { logger } from 'utils/logger'
import { AddictionsHistoryStatus } from './addictionsHistoryTypes'

const initialState: AddictionsHistoryStatus = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingProcedures: false,
}

const addictionsHistorySlice = createSlice({
  name: 'addictionsHistorySlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<AddictionsHistoryStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.noResultsAvailableOccupation =
        action.payload.noResultsAvailableOccupation
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.groupedObs = action.payload.groupedObs
      state.addictionsList = action.payload.addictionsList
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
      state.addictionFinalData = action.payload.addictionFinalData
    },
  },
})

export const requestAddictionsHistoryOfPatient =
  (appointmentId: string, selectedPatient: R4.IPatient): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AddictionsHistoryStatus = {
      searchingConditions: true,
      errorWhileSearchingProcedures: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(addictionsHistorySlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        category: 'social-history,survey',
        _count: 300,
        code: '64218-1,96103-7,10000-3,68518-0',
        status: 'final,preliminary',
      }

      if (getPatientIdentifiersForSearch(selectedPatient).length > 0) {
        searchParameters['patient:Patient.identifier'] =
          getPatientIdentifiersForSearch(selectedPatient)
      }

      const response: any =
        await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
          '/Observation',
          appointmentId!,
          searchParameters
        )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)

      const proceduresResponse: R4.IBundle = response

      if (proceduresResponse?.total && proceduresResponse?.total > 0) {
        const observations: R4.IObservation[] =
          proceduresResponse.entry?.map(
            (item) => item.resource as R4.IObservation
          ) ?? []

        const finalObservations: R4.IObservation[] = []
        for (let i = 0; i < observations.length; i++) {
          const codeData = observations[i].code
            ? observations[i].code.coding![0].code ?? ''
            : ''

          if (
            codeData !== '21843-8' &&
            codeData !== '408470005' &&
            codeData !== '248965005' &&
            codeData !== '41829006' &&
            codeData !== '33911006' &&
            codeData !== '252041008' &&
            codeData !== '80263-7' &&
            codeData !== '93038-8' &&
            codeData.trim() !== '182922004'
          ) {
            finalObservations.push(observations[i])
          }
        }

        if (finalObservations.length > 0) {
          finalObservations.sort((a, b) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )
          finalObservations.sort((a, b) =>
            a.code!.text! > b.code!.text!
              ? 1
              : b.code!.text! > a.code!.text!
              ? -1
              : 0
          )

          const addictionData: VitalsData[] =
            getAddictionsDataFromObs(finalObservations)
          const sortedObservation: R4.IObservation[] =
            getSortedObservationForAddiction(finalObservations)
          if (addictionData.length > 0) {
            let groupedOccupationData: GroupedOccupation[] = []
            const finalGroupedOccupationData: GroupedOccupation[] = []
            for (let i = 0; i < finalObservations.length; i++) {
              if (finalObservations[i].issued) {
                if (groupedOccupationData.length > 0) {
                  for (let j = 0; j < groupedOccupationData.length; j++) {
                    if (
                      moment(finalObservations[i].issued).format(
                        'YYYY-MM-DD'
                      ) ===
                      moment(groupedOccupationData[j].date).format('YYYY-MM-DD')
                    ) {
                      groupedOccupationData[j].occupation.push(
                        finalObservations[i]
                      )
                    } else {
                      groupedOccupationData.push({
                        date: finalObservations[i].issued ?? '',
                        occupation: [finalObservations[i]],
                      })
                      break
                    }
                  }
                } else {
                  groupedOccupationData.push({
                    date: finalObservations[i].issued ?? '',
                    occupation: [finalObservations[i]],
                  })
                }
              }
            }
            groupedOccupationData = groupedOccupationData.filter(
              (value, index, self) =>
                index ===
                self.findIndex(
                  (t) =>
                    moment(t.date).format('YYYY-MM-DD') ===
                    moment(value.date).format('YYYY-MM-DD')
                )
            )

            for (let i = 0; i < groupedOccupationData.length; i++) {
              finalGroupedOccupationData.push({
                date: groupedOccupationData[i].date,
                occupation: getSortedObservationForAddiction(
                  groupedOccupationData[i].occupation
                ),
              })
            }

            state.resultsAvailable = true
            state.searchingConditions = false
            state.addictionsList = finalObservations
            state.groupedObs = finalGroupedOccupationData
            state.addictionFinalData = addictionData
            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingProcedures = false
            dispatch(addictionsHistorySlice.actions.updatedStatus(state))
          } else {
            const errorSearchDoctor: AddictionsHistoryStatus = {
              searchingConditions: false,
              errorWhileSearchingProcedures: false,
              resultsAvailable: false,
              noResultsAvailable: true,
            }
            dispatch(
              addictionsHistorySlice.actions.updatedStatus(errorSearchDoctor)
            )
          }
        } else {
          const errorSearchDoctor: AddictionsHistoryStatus = {
            searchingConditions: false,
            errorWhileSearchingProcedures: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            addictionsHistorySlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } else {
        const errorSearchDoctor: AddictionsHistoryStatus = {
          searchingConditions: false,
          errorWhileSearchingProcedures: false,
          resultsAvailable: false,
          noResultsAvailable: true,
        }
        dispatch(
          addictionsHistorySlice.actions.updatedStatus(errorSearchDoctor)
        )
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: AddictionsHistoryStatus = {
        searchingConditions: false,
        errorWhileSearchingProcedures: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
      }
      dispatch(addictionsHistorySlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default addictionsHistorySlice.reducer
