import {
  Box,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import React from 'react'

interface NextStepsOfConsultationProps {
  planName: string
  subString: string
  onClicked?: () => void
  count?: number
  isEditAllowed?: boolean
  bottomWidget?: React.ReactNode
}

export const NextStepPlan: React.FC<NextStepsOfConsultationProps> = ({
  planName,
  subString,
  count = 0,
  onClicked,
  isEditAllowed = true,
  bottomWidget,
}: NextStepsOfConsultationProps) => {
  const primary = useTheme().palette.primary.main
  const isSelected: boolean = count > 0

  return (
    <Box
      display='flex'
      borderRadius={4}
      borderColor='lightGrey'
      border={1}
      p={1}
      width='100%'
      flexDirection='column'
      flexGrow
    >
      <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <Grid container style={{ padding: '0px', margin: '0px' }}>
          <Grid item xs={4} style={{ alignSelf: 'center' }}>
            <Typography color='initial' variant='h6' id='planName'>
              {planName}
            </Typography>
          </Grid>
          {count > 0 && (
            <Grid
              item
              xs={5}
              style={{ alignSelf: 'center', padding: '0px', margin: '0px' }}
            >
              <Typography style={{ color: isSelected ? 'white' : primary }}>
                {subString}
              </Typography>
            </Grid>
          )}
          {count > 0 && (
            <Grid
              item
              xs={2}
              style={{ alignSelf: 'center', padding: '0px', margin: '0px' }}
            >
              <Typography style={{ color: isSelected ? 'white' : primary }}>
                Change
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box
        display='flex'
        flexGrow
        flexDirection='row'
        justifyContent='flex-start'
        paddingTop={0.5}
        paddingBottom={1}
        style={{
          backgroundColor: isSelected ? primary : 'white',
          cursor: 'pointer',
        }}
      >
        <Tooltip title='' id='p1_clicked'>
          <IconButton
            aria-label='btn_ord_cancel'
            color='primary'
            id='plan_clicked'
            onClick={() => {
              if (onClicked) onClicked()
            }}
            style={{ padding: 0 }}
          >
            <AddCircleIcon
              style={{ height: '16px', padding: 0 }}
              color='primary'
              id='p2_clicked'
            />{' '}
            <Typography
              variant='subtitle2'
              color='primary'
              component={Link}
              style={{ fontSize: 13 }}
              id='link_clicked'
            >
              {' '}
              {planName}
            </Typography>
          </IconButton>
        </Tooltip>
      </Box>

      {bottomWidget && (
        <Box display='flex' flexDirection='row' width='100%' flexGrow>
          {bottomWidget}
        </Box>
      )}
    </Box>
  )
}
