import { R4 } from '@ahryman40k/ts-fhir-types'
import { AxiosResponse } from 'axios'

import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { EnrolCient } from 'services/EnrrolmentClient'
import { addAndGetEncounterDetailsOfAppointment } from 'utils/appointment_handle/cds_recommendations_util'
import { getAddedDate } from 'utils/dateUtil'
import { NetworkRequestResponseStatus } from './carePlanActivities'
import { getCarePlanOfPatient } from './careplan_utils'

export async function addLabOrderActivity(
  planDefinitions: string[],
  patientId: string,
  careplanId: string,
  encounterId: string,
  planDefinitionRes: R4.IPlanDefinition[]
): Promise<NetworkRequestResponseStatus> {
  try {
    const data = {
      labTestIds: planDefinitions,
      preferredDate: '',
      preferredAppointMentType: '',
      patientId,
      carePlanId: careplanId,
      encounterId,
      isLabOrderTask: true,
      planDefinitions: planDefinitionRes,
    }
    const enRolClient: EnrolCient = new EnrolCient()
    const networkResponse: AxiosResponse = await enRolClient.doPostRequest(
      `lab-order/`,
      data
    )
    if (networkResponse) {
      return {
        response: 'valid',
        responseData: networkResponse,
        responseHttpStatus: networkResponse.toString(),
      }
    }
  } catch (error) {
    console.error(error)
    return {
      response: 'error',
      responseData: error,
      errorReason: 'Exception',
    }
  } finally {
  }
  return {
    response: 'error',
    responseData: undefined,
    errorReason: 'Error response',
  }
}

export async function handleAddLabTestCarePlanActivities(
  patientId: string,
  planDefinitionRes: R4.IPlanDefinition[],
  inputEncounterId?: string,
  appointment?: FhirAppointmentDetail,
  carePlanUrl?: string
): Promise<NetworkRequestResponseStatus> {
  try {
    const carePlanOfPatient: R4.ICarePlan | undefined =
      await getCarePlanOfPatient(patientId, carePlanUrl ?? '', true)
    const encounterId = inputEncounterId

    if (carePlanOfPatient) {
      if (encounterId !== undefined) {
        const testCodes: string[] = planDefinitionRes.map(
          (e) => e.identifier![0].value!
        )
        const res = await addLabOrderActivity(
          testCodes,
          patientId,
          carePlanOfPatient!.id!,
          encounterId,
          planDefinitionRes
        )

        return res
      }

      return {
        response: 'error',
        responseData: 'Encounter is not available',
        errorReason: 'Encounter is not available',
      }
    }

    return {
      response: 'error',
      responseData: 'Patient is not able subscribe to Care Plan',
      errorReason: 'Patient is not able subscribe to Care Plan',
    }
  } catch (error) {
    return {
      response: 'error',
      responseData: error,
      errorReason: 'Error in deploying adding lab status',
    }
  }
}
