/* eslint-disable react/jsx-key */
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFollowUpAppointments } from 'redux/fhirMedicalResources/followUpAppointmentFetcher/followUpAppointmentSearchSlice'
import { RootState } from 'redux/rootReducer'
import { getDateForOrder } from 'utils/dateUtil'
import { getAppointmentTime } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { FollowUpAppointmentTabularTile } from '../assessment/followUpAppointmentTabularTile'

interface PatientFolloWUpAppointmentsProps {
  fhirPatientDetail: FhirAppointmentDetail
  followUp?: boolean
}

export const PatientFolloWUpAppointments: React.FC<PatientFolloWUpAppointmentsProps> =
  ({ fhirPatientDetail, followUp }: PatientFolloWUpAppointmentsProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const followUpAppointmentSearchSlice = useSelector(
      (state: RootState) => state.followUpAppointmentSearchSlice
    )

    useEffect(() => {
      dispatch(fetchFollowUpAppointments(fhirPatientDetail, followUp))
    }, [dispatch])

    return (
      <Box display='flex' flexDirection='column' width='100%'>
        <Box>
          {followUpAppointmentSearchSlice.searchingAppointments && (
            <CircularProgress size={15} />
          )}
          {followUpAppointmentSearchSlice.noResultsAvailable && (
            <Box marginY={1} display='flex' flexDirection='column' width='100%'>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                No data available
              </Typography>
            </Box>
          )}
          {followUpAppointmentSearchSlice.resultsAvailable &&
            followUpAppointmentSearchSlice.availableAppointments && (
              <Box display='flex' flexDirection='column' width='100%'>
                <FollowUpAppointmentTabularTile
                  followUps={
                    followUpAppointmentSearchSlice.availableAppointments
                  }
                  type=''
                />
                {/* {followUpAppointmentSearchSlice.availableAppointments.map(
                  (e) => (
                    <Box
                      marginY={1}
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      key={e.appointment.id ?? ''}
                    >
                      <Box key={e.appointment.id ?? ''}>
                        <Typography>
                          {`Follow up appointment is  on  ${getDateForOrder(
                            e.start
                          )} between   ${getAppointmentTime(e)}`}
                        </Typography>
                      </Box>
                    </Box>
                  )
                )} */}
              </Box>
            )}
          {followUpAppointmentSearchSlice.resultsAvailable &&
            followUpAppointmentSearchSlice.tasks &&
            followUpAppointmentSearchSlice.tasks.length > 0 && (
              <Box>
                {followUpAppointmentSearchSlice.tasks.map((e) => (
                  <Box
                    marginY={1}
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    key={e.id ?? ''}
                  >
                    <Box key={e.id ?? ''}>
                      <Typography>
                        {`Follow up appointment has to scheduled by patient after 15 days  `}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
        </Box>
      </Box>
    )
  }
