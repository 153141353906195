import MaterialTable, { Column, MTableToolbar } from '@material-table/core'
import {
  Box,
  Button,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import {
  kBackgroundDefault,
  kDialogueBackground,
  kPrimaryLight,
} from 'configs/styles/muiThemes'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import _ from 'lodash'
import { CrossOrgEnabledPractitioner } from 'models/practRoleResponse'

import React, { useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isPremiumOffer } from 'utils/admisnistration/cpg/cpgListHelper'
import { getSpecializationsList } from 'utils/fhirResourcesHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import OverflowTwoLineTypography from 'views/components/consultation/plan/ayurveda/overflowTwoLineTypography'
import OverflowTypography from 'views/components/consultation/plan/ayurveda/overflowTypography'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '2px',
      margin: '0px',
    },

    '& .MuiTableRow': {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(33, 150, 243, 0.5)',
        },
      },
    },
  },
})

interface CareTeamMemberTableProps {
  medicationsList: CrossOrgEnabledPractitioner[]
  actionComponent?: React.ReactNode

  onActionClick?: (medication: CrossOrgEnabledPractitioner) => void
}

export const CareTeamMemberTable: React.FC<CareTeamMemberTableProps> = ({
  medicationsList,
  actionComponent,

  onActionClick,
}: CareTeamMemberTableProps) => {
  const ref = React.useRef<null | HTMLDivElement>(null)

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    if (ref !== null && ref.current !== null && ref && ref.current) {
      setWidth(ref.current.offsetWidth)
      setHeight(ref.current.offsetHeight)
    }
  }, [])
  const classes = useStyles()
  const convertedData = medicationsList
  const [data, setData] = useState([...convertedData])

  const [selectedRowId, setSelectedRowId] = React.useState('')
  const [selectedRow, setSelectedRow] = React.useState(false)

  const columns: Column<CrossOrgEnabledPractitioner>[] = [
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Doctor
          </Typography>
        </Box>
      ),

      field: 'practitionerFirstName',
      customSort: (
        a: CrossOrgEnabledPractitioner,
        b: CrossOrgEnabledPractitioner
      ) =>
        a.practitionerFirstName > b.practitionerFirstName
          ? 1
          : b.practitionerFirstName > a.practitionerFirstName
          ? -1
          : 0,

      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (rowData: CrossOrgEnabledPractitioner) => (
        <Box display='flex' alignItems='center'>
          <OverflowTypography
            text={rowData.practitionerFirstName ?? ''}
            typographyProps={{
              variant: 'subtitle2',
              noWrap: true,
            }}
          />
        </Box>
      ),
      filtering: false,
    },

    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Organization
          </Typography>
        </Box>
      ),

      field: 'organizationName',
      customSort: (
        a: CrossOrgEnabledPractitioner,
        b: CrossOrgEnabledPractitioner
      ) =>
        (a.organizationName ?? '') > (b.organizationName ?? '')
          ? 1
          : (b.organizationName ?? '') > (a.organizationName ?? '')
          ? -1
          : 0,

      hiddenByColumnsButton: false,
      filtering: false,

      render: (rowData: CrossOrgEnabledPractitioner) => (
        <Box display='flex' alignItems='center'>
          <OverflowTwoLineTypography
            text={rowData.organizationName ?? ''}
            typographyProps={{
              variant: 'subtitle2',
              noWrap: false,
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Unit
          </Typography>
        </Box>
      ),

      field: 'unitName',
      customSort: (
        a: CrossOrgEnabledPractitioner,
        b: CrossOrgEnabledPractitioner
      ) =>
        (a.unitName ?? '') > (b.unitName ?? '')
          ? 1
          : (b.unitName ?? '') > (a.unitName ?? '')
          ? -1
          : 0,

      hiddenByColumnsButton: false,
      filtering: false,

      render: (rowData: CrossOrgEnabledPractitioner) => (
        <Box display='flex' alignItems='center'>
          <OverflowTwoLineTypography
            text={rowData.unitName ?? ''}
            typographyProps={{
              variant: 'subtitle2',
              noWrap: false,
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Specialization
          </Typography>
        </Box>
      ),

      field: 'specialty',
      customSort: (
        a: CrossOrgEnabledPractitioner,
        b: CrossOrgEnabledPractitioner
      ) =>
        (getSpecializationsList(a.specialty) ?? '') >
        (getSpecializationsList(b.specialty) ?? '')
          ? 1
          : (getSpecializationsList(b.specialty) ?? '') >
            (getSpecializationsList(a.specialty) ?? '')
          ? -1
          : 0,

      hiddenByColumnsButton: false,
      filtering: false,

      render: (rowData: CrossOrgEnabledPractitioner) => (
        <Box display='flex' alignItems='center'>
          <OverflowTwoLineTypography
            text={getSpecializationsList(rowData.specialty) ?? ''}
            typographyProps={{
              variant: 'subtitle2',
              noWrap: false,
            }}
          />
        </Box>
      ),
    },
  ]

  if (actionComponent && onActionClick) {
    columns.push({
      title: <Box />,
      field: 'cpgGoal',
      width: '8%',
      cellStyle: {
        paddingLeft: '0px',
        paddingRight: '0px',
        paddingBottom: '0px',
        paddingTop: '0px',
      },

      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (row: CrossOrgEnabledPractitioner) => (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          onClick={(e) => onActionClick(row)}
        >
          {actionComponent}
        </Box>
      ),
      filtering: false,
    })
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      ref={ref}
    >
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              /* className={classes.toolbarWrapper} */
              style={{
                minHeight: '0px',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
          Container: (props) => (
            <Paper
              {...props}
              elevation={0}
              style={{
                maxHeight: '100%',
                height: '100%',
                backgroundColor: useTheme().palette.background.paper,
              }}
              classes={classes}
            />
          ),
          Pagination: (props) => <div />,
        }}
        columns={columns}
        data={data}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        onRowClick={(event, rowData) => {
          /* if (rowData && onOrgSelected) {
            onOrgSelected(rowData.orgDetails, rowData.mainOrgId ?? '')
            setSelectedRowId(rowData ? rowData.orgName ?? '' : '')
          } */
        }}
        options={{
          toolbar: false,
          search: false,
          showTitle: false,
          columnResizable: false,
          paginationPosition: 'bottom',

          padding: 'dense',
          searchFieldVariant: 'outlined',
          filtering: false,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 30],
          //   columnsButton: true,
          // maxBodyHeight: `${height - 190}px`,
          //   minBodyHeight: `${height - 260}px`,
          tableLayout: 'fixed',
          sorting: true,
          thirdSortClick: false,

          headerStyle: {
            backgroundColor: kBackgroundDefault,
            width: 100,
            minHeight: '12px',
            maxHeight: '12px',
            position: 'sticky',
            top: 0,
            zIndex: 100,
            // height: 20,
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          rowStyle: (
            _data: CrossOrgEnabledPractitioner,
            index: number,
            _level: number
          ) =>
            index % 2
              ? selectedRow && _data.practitionerRoleId === selectedRowId
                ? {
                    backgroundColor: '#9E9DDC',

                    '&:hover': { backgroundColor: 'red' },
                  }
                : {
                    backgroundColor: kDialogueBackground,
                    '&:hover': { backgroundColor: 'red' },
                  }
              : selectedRow && _data.practitionerRoleId === selectedRowId
              ? {
                  backgroundColor: '#9E9DDC',

                  '&:hover': { backgroundColor: 'red' },
                }
              : { '&:hover': { backgroundColor: 'red' } },
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />
    </div>
  )
}
