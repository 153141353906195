/* eslint-disable @typescript-eslint/no-unused-expressions */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getCreatedDate,
  getEndDate,
  getLatestEncounterIdFromTaskList,
  getStartDate,
  getTasksOfSameEncounterId,
  getWeekLongDietPlanFromTasksWithMultipleDiets,
  getWeeklyDietPlanTaskFromBundle,
} from 'utils/careplan_utils/weeklyDietPlanUtils'

import { ExistingWeeklyDietPlanSearchStatus } from './existingWeeklyDietPlanSearchStatus'

const initialState: ExistingWeeklyDietPlanSearchStatus = {
  searchingTreatment: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingTreatment: false,
  isPreExistingPlanAvailable: false,
}

const existingWeeklyDietPlanSearchSlice = createSlice({
  name: 'existingWeeklyDietPlanSearchSlice',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<ExistingWeeklyDietPlanSearchStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingTreatment = action.payload.searchingTreatment
      state.resultsAvailable = action.payload.resultsAvailable
      state.dateWiseDietList = action.payload.dateWiseDietList
      state.startDate = action.payload.startDate
      state.endDate = action.payload.endDate
      state.preferredFoods = action.payload.preferredFoods
      state.itemsToAvoid = action.payload.itemsToAvoid
      state.dietList = action.payload.dietList
      state.errorReason = action.payload.errorReason
      state.isPreExistingPlanAvailable =
        action.payload.isPreExistingPlanAvailable
      state.errorWhileSearchingTreatment =
        action.payload.errorWhileSearchingTreatment
      state.createdDate = action.payload.createdDate
    },
  },
})

export const requestWeeklyDietPlanDetails =
  ({
    patientId,
    encounterId,
    practitionerRoleId,
    showPlanSuggestedBySameDoctor,
    split,
  }: {
    patientId: string
    encounterId?: string
    practitionerRoleId?: string
    split?: boolean
    showPlanSuggestedBySameDoctor?: boolean
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: ExistingWeeklyDietPlanSearchStatus = {
      searchingTreatment: true,
      errorWhileSearchingTreatment: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      isPreExistingPlanAvailable: false,
    }
    dispatch(existingWeeklyDietPlanSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      let searchParameters: any = {
        patient: `Patient/${patientId}`,
        status: 'draft,requested',
        intent: 'plan',
        code: 'C0012159',
        _count: 500,
        _sort: '-authored-on',
      }

      if (showPlanSuggestedBySameDoctor) {
        searchParameters = {
          ...searchParameters,
          requester: `PractitionerRole/${practitionerRoleId}`,
        }
      } else if (encounterId) {
        searchParameters = {
          ...searchParameters,

          encounter: `Encounter/${encounterId}`,
        }
      }

      const response: any = await fhirClient.doGetResourceForAppointmentIPD(
        `/Task`,
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingTreatment = true
        state.searchingTreatment = false
        console.error(
          '---------------rawTasksOfDietPlan--------------',
          resp.left
        )
        console.error(resp.left)
        dispatch(existingWeeklyDietPlanSearchSlice.actions.updatedStatus(state))
      } else {
        const dietPlanResponse: R4.IBundle = resp.right
        const rawTasksOfDietPlan: R4.ITask[] =
          getWeeklyDietPlanTaskFromBundle(dietPlanResponse)
        console.log(
          '---------------rawTasksOfDietPlan--------------',
          rawTasksOfDietPlan
        )
        const dietDataList: R4.ITask[] = getTasksOfSameEncounterId(
          rawTasksOfDietPlan,
          getLatestEncounterIdFromTaskList(rawTasksOfDietPlan)
        )

        console.log('---------------dietDataList--------------', dietDataList)
        const vitalLisData =
          getWeekLongDietPlanFromTasksWithMultipleDiets(dietDataList)

        console.log('---------------vitalLisData--------------', vitalLisData)
        state.resultsAvailable = true
        state.searchingTreatment = false
        state.dateWiseDietList = vitalLisData
        state.dietList = dietDataList
        state.endDate = getEndDate(dietDataList)
        state.startDate = getStartDate(dietDataList)
        state.createdDate = getCreatedDate(dietDataList)
        state.preferredFoods = undefined
        state.itemsToAvoid = undefined
        state.noResultsAvailable = getCreatedDate(dietDataList) === undefined
        state.errorReason = undefined
        state.errorWhileSearchingTreatment = false
        state.isPreExistingPlanAvailable = dietDataList.length > 0
        dispatch(existingWeeklyDietPlanSearchSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      console.error(error)
      const errorSearchDoctor: ExistingWeeklyDietPlanSearchStatus = {
        searchingTreatment: false,
        errorWhileSearchingTreatment: true,
        resultsAvailable: false,
        isPreExistingPlanAvailable: false,
        errorReason: 'Error while searching Treatment Plan',
      }
      dispatch(
        existingWeeklyDietPlanSearchSlice.actions.updatedStatus(
          errorSearchDoctor
        )
      )
    }
  }

export const resetWeeklyDietSearchState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: ExistingWeeklyDietPlanSearchStatus = {
      ...initialState,
    }
    dispatch(existingWeeklyDietPlanSearchSlice.actions.updatedStatus(state))
  }

export default existingWeeklyDietPlanSearchSlice.reducer
