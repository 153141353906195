import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { resetState } from 'redux/cohorts/cohortSearchSlice'
import {
  resetLabOffering,
  searchLabOfferings,
} from 'redux/lab/offerings/labOfferingSearchHandler/labOfferingSearchSlice'
import { resetPatientSearchPatient } from 'redux/patient/patientSearch/patientSearchSlice'
import { RootState } from 'redux/rootReducer'
import { getCompleteDateStringForLabReferral } from 'utils/dateUtil'
import {
  getAgeOfPatientData,
  getFirstNameFromHumanName,
  getLastNameFromHumanName,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getTelecomFromContactPoints,
} from 'utils/fhirResourcesHelper'
import { getPlanDefData } from 'utils/fhirResoureHelpers/planDefinitionHelper'
import {
  getPatientFromContainedResource,
  getPractitionerFromContainedResource,
  getTestLionCode,
  getTestLionCodeForReferralTile,
  getTestNameFromOrderDetails,
  getTestNameFromOrderDetailsForReferralTile,
} from 'utils/fhirResoureHelpers/referralHelpers'
import { logger } from 'utils/logger'
import { AddNewPatient } from 'views/components/add_new_patient'
import { CreateLabOrderHandler } from 'views/components/lab/orderCreationManagement/createLabOrderManagement'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { PatientChecker } from 'views/components/patients/patientChecker'

interface Props {
  ipdAppointmentDetails: R4.IServiceRequest
  index: number
  isOdd?: boolean
  onViewClicked?: () => void
  dense?: boolean
  isSelected?: boolean
  onManageClicked: () => void
  onDataUpdated?: () => void
}

export const ReferralTile: React.FC<Props> = ({
  ipdAppointmentDetails,
  index,
  isOdd = false,
  onViewClicked,
  dense = false,
  isSelected = false,
  onManageClicked,
  onDataUpdated,
}: Props) => {
  const { t } = useTranslation()
  const labOfferingSearchSlice = useSelector(
    (state: RootState) => state.labOfferingSearchSlice
  )
  const dispatch = useDispatch()
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()
  const [visitDetails, setVisitDetails] = useState<R4.IServiceRequest>(
    ipdAppointmentDetails
  )
  const [showAddPatient, setShowAddPatient] = useState<boolean>(false)
  const [showCheckPatient, setShowCheckPatient] = useState<boolean>(false)
  const [showCreateIpd, setShowCreateIpd] = useState<boolean>(false)

  const [createdPatientDetails, setCreatedPatient] = useState<R4.IPatient>()

  const [appointmentType, setAppointmentType] = useState<'order' | undefined>()

  const [loading, setLoading] = useState<boolean>(false)
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [planDef, setPlanDef] = useState<LabOfferingDetail[]>([])

  function findLabTest(loinCode: string) {
    setUpdateStatus({ requesting: true })

    setLoading(true)

    getPlanDefData(loinCode)
      .then((e: LabOfferingDetail[]) => {
        setLoading(false)
        if (e) {
          setPlanDef(e)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setShowCreateIpd(true)

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while fetching lab test. Please try again later'
            )
          )
        }
      })
      .catch((e) => {
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Grid container direction='row' spacing={1}>
        {/* ------------------------------------------------------------------------------- */}

        <Box display='flex' flexDirection='column' width='100%' flexGrow={1}>
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: index % 2 === 0 ? undefined : '#FFFFFFAF',
            }}
          >
            <Grid item xs={2}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={60}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {getCompleteDateStringForLabReferral(
                          visitDetails.authoredOn ?? ''
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '60px',

                        width: '2px',
                      }}
                    />
                  </Box> */}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={60}
                    alignItems='center'
                    paddingLeft={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      flexDirection='column'
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {getNameOfPatient(
                          getPatientFromContainedResource(visitDetails)!
                        )}{' '}
                        {getMiddleNameOfPatient(
                          getPatientFromContainedResource(visitDetails)!
                        )}{' '}
                        {getLastNameOfPatient(
                          getPatientFromContainedResource(visitDetails)!
                        )}
                      </Typography>

                      <Box>
                        {getPatientFromContainedResource(visitDetails) && (
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {` ${getTelecomFromContactPoints(
                              getPatientFromContainedResource(visitDetails)!
                                .telecom ?? [],
                              R4.ContactPointSystemKind._phone
                            )}`}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box> */}

                  {/* <Divider
                      orientation='horizontal'
                      flexItem
                      style={{
                        height: '2px',
                      }}
                    /> */}
                </Box>
                {/* <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',
                      }}
                    />
                  </Box> */}
              </Box>
            </Grid>

            <Grid item xs={1}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={60}
                    alignItems='center'
                    px={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      flexDirection='column'
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        flexDirection='row'
                        px={0.2}
                      >
                        {getPatientFromContainedResource(visitDetails)
                          ?.gender === R4.PatientGenderKind._male ? (
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {' '}
                            M /
                          </Typography>
                        ) : (
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {' '}
                            F /
                          </Typography>
                        )}
                        {getPatientFromContainedResource(visitDetails) && (
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {` ${getAgeOfPatientData(
                              getPatientFromContainedResource(visitDetails)!
                            )}`}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box> */}

                  {/* <Divider
                      orientation='horizontal'
                      flexItem
                      style={{
                        height: '2px',
                      }}
                    /> */}
                </Box>
                {/* <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',
                      }}
                    />
                  </Box> */}
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={60}
                    alignItems='center'
                    px={1.1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {getFirstNameFromHumanName(
                        getPractitionerFromContainedResource(visitDetails)
                          ?.name ?? []
                      )}{' '}
                      {getLastNameFromHumanName(
                        getPractitionerFromContainedResource(visitDetails)
                          ?.name ?? []
                      )}
                    </Typography>
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box> */}

                  {/* <Divider
                      orientation='horizontal'
                      flexItem
                      style={{
                        height: '2px',
                      }}
                    /> */}
                </Box>
                {/* <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',
                      }}
                    />
                  </Box> */}
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={60}
                    alignItems='center'
                    px={1.3}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {getTestNameFromOrderDetailsForReferralTile(visitDetails)}
                    </Typography>
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box> */}

                  {/* <Divider
                      orientation='horizontal'
                      flexItem
                      style={{
                        height: '2px',
                      }}
                    /> */}
                </Box>
                {/* <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',
                      }}
                    />
                  </Box> */}
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={60}
                    alignItems='center'
                    px={1}
                  >
                    {visitDetails.status === 'active' && (
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='row'
                        justifyContent='flex-end'
                      >
                        <Button
                          variant='contained'
                          color='primary'
                          disableElevation
                          size='small'
                          onClick={() => {
                            setAppointmentType('order')
                            // setShowCreateIpd(true)
                            // setShowCheckPatient(true)
                            if (
                              visitDetails.orderDetail &&
                              visitDetails.orderDetail.length > 0
                            ) {
                              if (
                                getTestNameFromOrderDetailsForReferralTile(
                                  visitDetails
                                ).length > 0
                              ) {
                                findLabTest(
                                  getTestLionCodeForReferralTile(visitDetails)
                                )
                              }
                            }
                          }}
                        >
                          Book Lab Test
                        </Button>
                      </Box>
                    )}
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box> */}

                  {/* <Divider
                      orientation='horizontal'
                      flexItem
                      style={{
                        height: '2px',
                      }}
                    /> */}
                </Box>
                {/* <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',
                      }}
                    />
                  </Box> */}
              </Box>
            </Grid>
          </Grid>
          {/* <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid> */}
        </Box>
      </Grid>

      {showAddPatient && (
        <AddNewPatient
          isLab={false}
          isAppointment={false}
          popup={showAddPatient}
          isMinimal={false}
          patientDetail={getPatientFromContainedResource(visitDetails)}
          onPatientCreated={(
            createdPatient?: R4.IPatient,
            patientId?: string
          ) => {
            dispatch(showSuccessAlert('Patient added successfully'))
            logger.info('Created Patient, ', createdPatient)
            if (createdPatient) {
              if (patientId) {
                const oldPatient: R4.IPatient = {
                  ...createdPatient,
                }
                const patId: string = patientId.split('/')[1]
                oldPatient.id = patId
                setCreatedPatient(oldPatient)
                setShowAddPatient(false)
                if (appointmentType === 'order') {
                  setShowCreateIpd(true)
                }
                // onPatientSelected(oldPatient)
              }
            }

            /* if (onPatientSelected && createdPatient) {
              onPatientSelected(createdPatient);
            } */
          }}
          onError={() => {
            dispatch(
              showErrorAlert('Error while adding patient. Please try later')
            )
            dispatch(resetState())
            dispatch(resetPatientSearchPatient())
            setShowAddPatient(false)
          }}
          onCancelClicked={() => {
            dispatch(resetState())
            dispatch(resetPatientSearchPatient())
            setShowAddPatient(false)
          }}
        />
      )}

      {showCheckPatient && (
        <PatientChecker
          id='patient_checker'
          onClose={() => {
            setShowCheckPatient(false)
          }}
          onNewPatientCreated={() => {
            setShowCheckPatient(false)
            setShowAddPatient(true)
          }}
          onNoPatientsAvailable={() => {
            setShowCheckPatient(false)
            setShowAddPatient(true)
          }}
          onPatientSelected={(e) => {
            setShowCheckPatient(false)

            setCreatedPatient(e)

            if (appointmentType === 'order') {
              setShowCreateIpd(true)
            }
          }}
          open={showCheckPatient}
          patientData={getPatientFromContainedResource(visitDetails)!}
          isLab={true}
        />
      )}

      {showCreateIpd && (
        <CreateLabOrderHandler
          open={showCreateIpd}
          onLabOrderCreated={() => {
            dispatch(resetLabOffering())
            setShowCreateIpd(false)
          }}
          onClose={() => {
            dispatch(resetLabOffering())
            setShowCreateIpd(false)
          }}
          preselectedPatient={getPatientFromContainedResource(visitDetails)}
          referralId={visitDetails.id}
          encounterRef={
            visitDetails.encounter ? visitDetails.encounter.reference ?? '' : ''
          }
          preSelectedTest={planDef}
          test='yes'
        />
      )}

      <NoteDialog
        id='treatment_notes_therapist'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
        dialogText='Notes'
      />
    </Box>
  )
}
