import { Box, CircularProgress, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirReferralRequest } from 'models/fhirReferralRequest'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getReferralRequestsOfAppointment } from 'redux/consultation/referrals_list/referralListSlice'
import { RootState } from 'redux/rootReducer'
import { getDbReferralDetailsFromFhir } from 'utils/fhirResoureHelpers/referralHelpers'

interface ReferralRequestProps {
  fhirAppointmentDetails: FhirAppointmentDetail
}

export const ReferralRequestList: React.FC<ReferralRequestProps> = ({
  fhirAppointmentDetails,
}: ReferralRequestProps) => {
  const initialFetch = useRef(true)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const referralsListSlice = useSelector(
    (state: RootState) => state.referralsListSlice
  )

  useEffect(() => {
    dispatch(getReferralRequestsOfAppointment(fhirAppointmentDetails))
  }, [dispatch, fhirAppointmentDetails])

  return (
    <Box display='flex' flexDirection='column'>
      {(referralsListSlice.updatingImpressions ||
        referralsListSlice.fetchingImpressions) && (
        <CircularProgress size={15} />
      )}
      {referralsListSlice.noResultsAvailable && (
        <Box marginY={1} display='flex' flexDirection='column' width='100%'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            No data available
          </Typography>
        </Box>
      )}
      {referralsListSlice.referralRequests &&
        referralsListSlice.referralRequests.length > 0 && (
          <Box marginY={1} display='flex' flexDirection='column'>
            <ul>
              {referralsListSlice.referralRequests.map(
                (e: FhirReferralRequest) => (
                  <li key={e.serviceRequest.id ?? ''}>
                    <Typography variant='body1' color='initial'>
                      {getDbReferralDetailsFromFhir(e)}
                    </Typography>
                  </li>
                )
              )}
            </ul>
          </Box>
        )}
    </Box>
  )
}
