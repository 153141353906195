import FullCalendar, { EventContentArg } from '@fullcalendar/react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { DateSelectArg } from '@fullcalendar/common'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'

import timeGridPlugin from '@fullcalendar/timegrid'
import { makeStyles, useTheme } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import moment from 'moment'
import React, { useEffect } from 'react'
import { isToday } from 'utils/dateUtil'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { logger } from 'utils/logger'
import { AppointmentTile } from './appointmentTile'

const useStyles = makeStyles((theme: any) => ({
  fc: {
    '& .fc .fc-timegrid-slots': {
      position: 'relative',
      zIndex: 1,
      lineHeight: 0,
    },
    '& .fc .fc-event': {
      maxWidth: 350,
      boxShadow: '0px 0px 0px 0px #fff',
      border: 'none',
    },

    '& .fc .fc-timegrid-slot-label': {
      verticalAlign: 'top',
      backgroundColor: theme.palette.background.paper,
    },

    '& .fc .fc-scroller-harness-liquid': {
      // height: '64vh'
    },

    '& .fc .fc-timegrid-slot': {
      height: 80,
      lineHeight: 1,
    },
    '& .fc .fc-day-future': {
      backgroundColor: theme.palette.background.paper,
    },
    // '& .fc .fc-day-past': {
    //   backgroundColor: useTheme().palette.grey[200],
    //   opacity: 0.6,
    // },
    '& .fc .fc-day-today': {
      backgroundColor: 'theme.palette.primary',
    },

    '&  .fc .fc-highlight': {
      backgroundColor: useTheme().palette.primary.main,
      background: 'none',
    },
    '& .fc .fc-scrollgrid-sync-inner': {
      backgroundColor: 'theme.palette.primary',
    },
  },
}))

interface Props {
  selectedDate?: Date
  doctorAppointments: FhirAppointmentDetail[]
  selectedOption: string
  onCancelClicked: (appointment: FhirAppointmentDetail) => void
  onRescheduleClicked: (appointment: FhirAppointmentDetail) => void
  onViewClicked: (appointment: FhirAppointmentDetail) => void
  onDownloadClicked: (appointment: FhirAppointmentDetail) => void
  onEmptyTimeBlockClicked?: (startTime?: Date, endTime?: Date) => void

  onAppointmentClicked?: (
    clickEvent: MouseEvent,
    targetElement: HTMLElement,
    appointment: FhirAppointmentDetail
  ) => void
}
export const AppointmentsCalenderView: React.FC<Props> = ({
  selectedDate,
  doctorAppointments,
  selectedOption,
  onAppointmentClicked,
  onRescheduleClicked,
  onCancelClicked,
  onViewClicked,
  onDownloadClicked,
  onEmptyTimeBlockClicked,
}: Props) => {
  const calendarRef:
    | string
    | ((instance: FullCalendar | null) => void)
    | React.RefObject<FullCalendar>
    | null
    | undefined = React.createRef()
  const renderEventContent = (eventInfo: EventContentArg) => {
    const appointMent: FhirAppointmentDetail = eventInfo.event
      .extendedProps as FhirAppointmentDetail
    const serviceProfile: R4.IPatient = appointMent?.patient ?? undefined
    eventInfo.isDraggable = true
    eventInfo.isSelected = true

    if (serviceProfile) {
      return (
        <AppointmentTile
          onCancelClicked={() => {
            onCancelClicked(appointMent)
          }}
          onRescheduleClicked={() => {
            onRescheduleClicked(appointMent)
          }}
          onViewClicked={() => {
            onViewClicked(appointMent)
          }}
          onDownloadClicked={() => {
            onDownloadClicked(appointMent)
          }}
          appointmentDetail={appointMent}
          selectedOption={selectedOption}
        />
      )
    }
    return <div />
  }
  const { height } = useWindowDimensions()
  useEffect(() => {
    if (selectedDate) {
      if (calendarRef?.current != null) {
        const calendarApi = calendarRef?.current.getApi()
        //  calendarApi.next()
        calendarApi.gotoDate(selectedDate)
      }
    }
  }, [selectedDate, calendarRef])
  useEffect(() => {
    if (selectedOption) {
      if (calendarRef?.current != null) {
        const calendarApi = calendarRef?.current.getApi()
        calendarApi.changeView(selectedOption, selectedDate)
      }
    }
  }, [selectedOption, calendarRef, selectedDate])

  const classes = useStyles()
  const val: string = `${height - 112}px`
  return (
    <div className={classes.fc} style={{ overflow: 'auto', maxHeight: '100%' }}>
      <FullCalendar
        height={val}
        ref={calendarRef}
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          momentPlugin,
        ]}
        initialView='timeGridDay'
        headerToolbar={{
          left: 'title',
          right: '',
        }}
        dayHeaders={selectedOption !== 'timeGridDay'}
        editable={false}
        selectable={true}
        scrollTime={
          selectedDate && isToday(selectedDate)
            ? moment(new Date()).format('HH:mm')
            : undefined
        }
        selectMirror={false}
        slotDuration='00:30'
        allDaySlot={false}
        slotMinTime='00:00:00'
        slotMaxTime='23:59:59'
        // eventMinHeight={50}
        eventShortHeight={30}
        eventBackgroundColor='transparent'
        eventColor='transparent'
        eventBorderColor='transparent'
        eventOverlap={false}
        slotEventOverlap={false}
        nowIndicator
        titleFormat='ddd DD'
        eventContent={renderEventContent}
        events={doctorAppointments.map((item) => ({
          title: item.practitionerDetail.practitioner
            ? getNameFromHumanName(
                item.practitionerDetail.practitioner.name ?? []
              ) ?? 'Title'
            : 'No Data',
          start: item.start,
          end: item.end,
          extendedProps: item,
          date: item.start,
        }))}
        dayMaxEvents={true}
        initialDate={new Date()}
        select={(args: DateSelectArg) => {
          logger.info('Selected Date and time')
          logger.info(args)

          if (onEmptyTimeBlockClicked)
            onEmptyTimeBlockClicked(args.start, args.end)
        }}
        selectConstraint={{
          start: moment().format('YYYY-MM-DD'),
          end: '2100-01-01',
        }}
        // custom render function
      />
    </div>
  )
}
