import { Box } from '@material-ui/core'
import { CurrentLoggedInUser } from 'models/currentUserDetails'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { searchUnits } from 'redux/administration/units/unitManagement/unitsListsOfOrganization/unitsOfOrganizationSlice'
import { requestForRoomPrice } from 'redux/clinic/ipdmanagement/roomPriceSearch/roomPriceSearchSlice'
import { searchObservationsForAddLabTest } from 'redux/lab/diaognsticLabTestSearchSlice/dianogsticObsFinderSlice'
import { updateMedicineOrderCount } from 'redux/MedicineOrderHistory/medicineOrderCount/medicineOrderCountSlice'
import { RootState } from 'redux/rootReducer'
import { getAllKriya } from 'redux/valueSet/kriyaListWithChargable/kriyaWithPriceSearchSlice'
import { getAllNutritionValueSets } from 'redux/valueSet/nutrition_catalogue/nutrtionCatalogueSlice'
import { getAllSubstance } from 'redux/valueSet/substance/substanceSearchSlice'
import { updateSessionTherapiesList } from 'redux/valueSet/therapies_catalogue/therapiesCatalogueSlice'
import { getAllTherapist } from 'redux/valueSet/therapyList/threapyCatalogSearchSlice'
import { getAllTherapiesWithPrice } from 'redux/valueSet/therapyListwithChargable/threapyCatalogWithPriceSearchSlice'
import { searchForMainOrgCount } from 'redux/welloAdmin/mainOrg/mainOrgCount/mainOrgCountSearchSlice'
import { searchLabCount } from 'redux/welloAdmin/unit/labCount/labCountSearchSlice'
import { searchUnitCountForClinic } from 'redux/welloAdmin/unit/unitCountForHeader/unitCountSearchWelloAdmin'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitDetails,
  getUserDetails,
  isAgent,
  isCurrentUnitIsClinic,
  isCurrentUnitIsLab,
  isCurrentUnitIsWellonessCenter,
  isCurrentUserDoctor,
  isDietician,
  isDoctor,
  isMedicalServiceProvider,
  isOrgAdmin,
  isPartnerLab,
  isTherapist,
  isWpAdmin,
} from 'services/userDetailsService'
import { getAppBars } from 'utils/appBarHelpers'
import { OrgAdminAppointmentDashboard } from 'views/components/administration/actors/micro-components/orgAdminAppointmentDashboard'
import { OrgAdminDashBoard } from 'views/components/administration/actors/micro-components/orgAdmissionDashboard'
import { OrgDialog } from 'views/components/administration/actors/micro-components/orgCard'
import { ReferralOrdersHomePage } from 'views/components/administration/referrals/referralList/referralListForHomePage'
import { MainOrgLandingPage } from 'views/components/administration/welloAdmin/mainOrgLandingpage'
import { OrgLandingPage } from 'views/components/administration/welloAdmin/orgLandingPage'
import { WpDashBoard } from 'views/components/administration/welloAdmin/wpDashboard'
import { WpLabLandingPage } from 'views/components/administration/welloAdmin/wpLabLaningPage'
import { ProductPage } from 'views/medicine/productPage'
import { UserSettingsPage } from 'views/pages/general/user_settings_page'
import { requestDietTiming } from 'redux/appointments/dietTimeSearch/dietTimeSearchSlice'
import { WelloClinicAppBar } from '../components/appBars/appBar'
import { PatientsManagementPage } from '../components/patients/patientsSearch'
import { CarePlanSubscriptionsLandingPage } from './carePlanSusbcriptions/carePlanSusbcriptionsLandingPage'
import { ClinicalAppointmentsList } from './clinic/clinicalAppointmentsList'
import { ClinicalDieticianHomePage } from './clinic/clinicalDieticianHomePage'
import { ClinicalTherapistHomePage } from './clinic/clinicalTherapistHomePage'
import { CohortLandingPage } from './clinic/cohortLandingPage'
import { IncomingReferralsHomePage } from './clinic/incomingReferralsHomePage'
import { IpdLandingPage } from './clinic/ipd/ipdLandingPage'
import { MedicineLandingPage } from './clinic/medOrder/medOrderHistory'
import { OPDConsultation } from './clinic/opd/opdLandingConsultation'
import { OPDLandingPage } from './clinic/opd/opdLandingPage'
import { PreAppointmentLandingPage } from './clinic/preAppointments/preAppointmentLandingPage'
import { AgentLandingPage } from './laboratory/agentLandingPage'
import { LabLandingPage } from './laboratory/labLandingPage'
import { PartnerLabLandingPage } from './laboratory/partnerLabLandingPage'
import { NotFoundPage } from './clinic/notFoundPage'

export interface IUnitAdmin {
  onTap: () => void
  preselectedTab?: string
}
export const WelloLandingPage: React.FC<IUnitAdmin> = ({
  onTap,
  preselectedTab,
}) => {
  const { t, i18n } = useTranslation(['common', 'patient'])
  const [selectedTab, setSelectedTab] = React.useState(
    preselectedTab ?? getAppBars()[0].name
  )

  const [setActive, setSelected] = React.useState(false)
  const language = sessionStorage.getItem('lang')
  const authState = useSelector(
    (state: RootState) => state.authCheck.userDetails?.practitionerRole
  )

  const labTestSearchSlice = useSelector(
    (state: RootState) => state.diagnosticObsFinderSlice
  )
  sessionStorage.removeItem('version')

  const dispatch = useDispatch()
  let loggedInUser: CurrentLoggedInUser | undefined = getUserDetails()

  useEffect(() => {
    i18n.changeLanguage(language ?? '')
    if (isOrgAdmin()) {
      dispatch(searchUnits())
      //   dispatch(searchRoles('', []))
    }
    if (isCurrentUnitIsClinic() || isCurrentUnitIsWellonessCenter()) {
      dispatch(searchUnits(getCurrentUserUnitDetails().id ?? ''))
    }

    setSelected(false)
    if (isCurrentUnitIsWellonessCenter()) {
      if (isOrgAdmin()) {
        dispatch(getAllNutritionValueSets())
        dispatch(updateSessionTherapiesList())
        dispatch(getAllTherapist())
        dispatch(getAllTherapiesWithPrice())
        dispatch(getAllSubstance())
        dispatch(requestForRoomPrice('nightCharges'))
        dispatch(requestForRoomPrice('nightCharges'))
      }
      if (isCurrentUnitIsClinic() || isCurrentUnitIsWellonessCenter()) {
        if (!isOrgAdmin() || !isCurrentUnitIsLab()) {
          dispatch(getAllNutritionValueSets())
          dispatch(updateSessionTherapiesList())
          dispatch(getAllTherapist())
          dispatch(getAllTherapiesWithPrice())
          dispatch(getAllSubstance())
          dispatch(requestForRoomPrice('nightCharges'))
          dispatch(requestForRoomPrice('nightCharges'))
        }
      }
    }

    if (!isCurrentUnitIsWellonessCenter()) {
      if (isOrgAdmin()) {
        dispatch(getAllNutritionValueSets())
        dispatch(updateSessionTherapiesList())
        dispatch(getAllTherapist())
        dispatch(getAllTherapiesWithPrice())
        dispatch(getAllSubstance())
        dispatch(requestForRoomPrice('nightCharges'))
        dispatch(requestForRoomPrice('nightCharges'))
      }
      if (isCurrentUnitIsClinic() || isCurrentUnitIsWellonessCenter()) {
        if (!isOrgAdmin() || !isCurrentUnitIsLab()) {
          dispatch(getAllNutritionValueSets())
          dispatch(updateSessionTherapiesList())
          dispatch(getAllTherapist())
          dispatch(getAllTherapiesWithPrice())
          dispatch(getAllSubstance())
          dispatch(searchObservationsForAddLabTest())
          dispatch(requestForRoomPrice('nightCharges'))
          dispatch(requestForRoomPrice('nightCharges'))
        }
      }
    }
    if (isOrgAdmin()) {
      dispatch(searchForMainOrgCount())
      dispatch(searchUnitCountForClinic())
      dispatch(searchLabCount())
    }

    if (isCurrentUnitIsClinic() || isCurrentUnitIsWellonessCenter()) {
      if (isMedicalServiceProvider()) {
        dispatch(updateMedicineOrderCount())
        dispatch(getAllNutritionValueSets())
        dispatch(getAllKriya())
      }
      if (isCurrentUserDoctor()) {
        dispatch(
          requestDietTiming(getCurrentUserPractitionerDetails().id ?? '')
        )
      }
    }
  }, [dispatch])

  const handleChangeTab = (userSelectedTab: string) => {
    setSelectedTab(userSelectedTab)
  }

  return (
    <Box
      overflow='auto'
      key={authState!.id ?? ''}
      id={authState!.id ?? ''}
      style={{
        height: '100vh',
        maxHeight: '100vh',
        paddingTop: '55px',
      }}
    >
      <WelloClinicAppBar
        onTabChange={handleChangeTab}
        selected={t(selectedTab)}
        userData={loggedInUser}
        key={authState!.id ?? ''}
      />
      <Box
        style={{
          height: '100%',
          overflow: 'auto',
          maxHeight: '100%',
          backgroundColor: '#ececec',
        }}
      >
        {/* {(isOrgAdmin() || isUnitAdmin()) && selectedTab === 'Finance' && 
          (
          <OrgDialog id='1' org={getCurrentUserMainOrganizationDetails()} />
        )}  */}
        {(isOrgAdmin() || selectedTab === 'Finance') &&
          selectedTab !== 'Settings' &&
          selectedTab !== 'Clinic' &&
          selectedTab !== 'Unauthorized' &&
          selectedTab !== 'Organization' &&
          selectedTab !== 'Lab' &&
          selectedTab !== 'Organizations' &&
          selectedTab !== 'Appointments' &&
          selectedTab !== 'Admissions' && (
            <OrgDialog id='1' org={getCurrentUserMainOrganizationDetails()} />
          )}
        {selectedTab === 'Dashboard' && isWpAdmin() && (
          <WpDashBoard id='1' org={getCurrentUserMainOrganizationDetails()} />
        )}
        {(isOrgAdmin() || selectedTab === 'Admissions') &&
          selectedTab !== 'Settings' &&
          selectedTab !== 'Clinic' &&
          selectedTab !== 'Unauthorized' &&
          selectedTab !== 'Organization' &&
          selectedTab !== 'Lab' &&
          selectedTab !== 'Organizations' &&
          selectedTab !== 'Appointments' &&
          selectedTab !== 'Finance' && <OrgAdminDashBoard />}
        {(isOrgAdmin() || selectedTab === 'Appointments') &&
          selectedTab !== 'Settings' &&
          selectedTab !== 'Clinic' &&
          selectedTab !== 'Unauthorized' &&
          selectedTab !== 'Organization' &&
          selectedTab !== 'Admissions' &&
          selectedTab !== 'Lab' &&
          selectedTab !== 'Organizations' &&
          selectedTab !== 'Finance' && <OrgAdminAppointmentDashboard />}
        {(isOrgAdmin() || selectedTab === 'Organizations') &&
          selectedTab !== 'Settings' &&
          selectedTab !== 'Admissions' &&
          selectedTab !== 'Unauthorized' &&
          selectedTab !== 'Appointments' &&
          selectedTab !== 'Clinic' &&
          selectedTab !== 'Lab' &&
          selectedTab !== 'Finance' && (
            <MainOrgLandingPage orgType='Organization' />
          )}
        {(isOrgAdmin() || selectedTab === 'Clinic') &&
          selectedTab !== 'Settings' &&
          selectedTab !== 'Admissions' &&
          selectedTab !== 'Unauthorized' &&
          selectedTab !== 'Lab' &&
          selectedTab !== 'Organizations' &&
          selectedTab !== 'Appointments' &&
          selectedTab !== 'Finance' && <OrgLandingPage orgType='Clinic' />}
        {(isOrgAdmin() || selectedTab === 'Lab') &&
          selectedTab !== 'Settings' &&
          selectedTab !== 'Unauthorized' &&
          selectedTab !== 'Admissions' &&
          selectedTab !== 'Organizations' &&
          selectedTab !== 'Appointments' &&
          selectedTab !== 'Clinic' &&
          selectedTab !== 'Finance' && <WpLabLandingPage orgType='Lab' />}
        {(selectedTab === 'Lab Orders' || selectedTab === 'प्रयोगशाला आदेश') &&
          isPartnerLab() && <PartnerLabLandingPage />}
        {(selectedTab === 'Lab Orders' || selectedTab === 'प्रयोगशाला आदेश') &&
          isPartnerLab() === false &&
          !isAgent() && <LabLandingPage />}
        {selectedTab === 'Lab Referrals' &&
          isPartnerLab() === false &&
          !isAgent() && <ReferralOrdersHomePage key='lab' />}
        {(selectedTab === 'Lab Orders' || selectedTab === 'प्रयोगशाला आदेश') &&
          isAgent() && <AgentLandingPage />}
        {selectedTab === 'Day Care' && !isTherapist() && !isDietician() && (
          <OPDLandingPage />
        )}
        {selectedTab === 'Cohorts' && isDoctor() && <CohortLandingPage />}
        {selectedTab === 'OPD' && !isTherapist() && !isDietician() && (
          <OPDConsultation />
        )}
        {selectedTab === 'IPD' && !isTherapist() && !isDietician() && (
          <IpdLandingPage />
        )}
        {selectedTab === 'Medicine Orders' &&
          !isTherapist() &&
          !isDietician() && <MedicineLandingPage />}

        {selectedTab === 'Care Plan' && !isTherapist() && !isDietician() && (
          <CarePlanSubscriptionsLandingPage />
        )}

        {selectedTab === 'Appointment' && (
          <ClinicalAppointmentsList key='appointments' />
        )}
        {selectedTab === 'Medicines' && (
          <ClinicalAppointmentsList key='appointments' />
        )}
        {selectedTab === 'Day Care' && isDietician() && (
          <ClinicalDieticianHomePage type='opd-daycare' key='opd-dietician' />
        )}
        {selectedTab === 'OPD' && isDietician() && (
          <ClinicalDieticianHomePage type='opd' key='opd-dietician' />
        )}
        {selectedTab === 'IPD' && isDietician() && (
          <ClinicalDieticianHomePage type='ipd' key='ipd-dietician' />
        )}
        {selectedTab === 'Day Care' && isTherapist() && (
          <ClinicalTherapistHomePage type='opd-daycare' key='opd-daycare' />
        )}
        {selectedTab === 'OPD' && isTherapist() && (
          <ClinicalTherapistHomePage type='opd' key='opd' />
        )}
        {selectedTab === 'IPD' && isTherapist() && (
          <ClinicalTherapistHomePage type='ipd' key='ipd' />
        )}
        {(selectedTab === 'Referrals' || selectedTab === 'मरीजों') && (
          <IncomingReferralsHomePage key='ipd' />
        )}
        {selectedTab === 'Medicine' && (
          <ProductPage
            key='ipd'
            doctorDetails={getCurrentUserPractitionerDetails()}
          />
        )}
        {(selectedTab === 'Pre-Appointment' || selectedTab === 'मरीजों') && (
          <PreAppointmentLandingPage key='pre-appointment' />
        )}
        {(selectedTab === 'Patients' || selectedTab === 'मरीजों') && (
          <PatientsManagementPage />
        )}
        {(selectedTab === 'Settings' || selectedTab === 'समायोजन') && (
          <UserSettingsPage
            onTap={() => {
              loggedInUser = getUserDetails()
              onTap()
            }}
          />
        )}
        {selectedTab === 'Unauthorized' && (
          <NotFoundPage
            onTap={() => {
              loggedInUser = getUserDetails()
              onTap()
            }}
          />
        )}
      </Box>
    </Box>
  )
}
