import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  LinearProgress,
  List,
  Paper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import { ToggleButtonGroup } from '@material-ui/lab'
import { FhirClinicTherapyBasic } from 'models/fhirClinicTherapyBasic'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import {
  requestForIncomingReferralsLists,
  searchReferrals,
} from 'redux/clinic/ipdmanagement/incomingReferralsList/incomingReferralsSlice'
import { RootState } from 'redux/rootReducer'
import { useWindowSize } from 'rooks'
import {
  getCurrentUserUnitDetails,
  isTherapist,
} from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { getVendorPartId } from 'utils/routes_helper'
import { ReferralDisplayTile } from 'views/components/ipd/ipdAppointmentList/referralDisplayTile'
import { TabbedMultiSelector } from 'views/components/toggle_multiSelector'
import '../../../App.css'
import { ToggleOption } from '../../components/toggle_selector'

const therapyOptions = [
  {
    id: 'active',
    display: 'Scheduled',
  },
]
export interface PageProps {}
const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  grouped: {
    padding: theme.spacing(0.5),

    border: 'none',
    '&.Mui-selected': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
    },
  },
}))(ToggleButtonGroup)

export const IncomingReferralsHomePage: React.FC<PageProps> =
  ({}: PageProps) => {
    const selectedDateQ = useSelector(
      (state: RootState) => state.therapiesListSlice.selectedDate
    )
    const dispatch = useDispatch()
    const [selectedDate, setSelectedDate] =
      useState<Date | undefined>(selectedDateQ)

    const appointmentSearchSlice = useSelector(
      (state: RootState) => state.incomingReferralsSlice
    )
    const displayList = useSelector(
      (state: RootState) => state.incomingReferralsSlice.filteredList
    )

    const [selectedIpdStatuses, setSelectedIPDStatuses] = useState<string[]>(
      appointmentSearchSlice.selectedStatuses
    )

    const [searchText, setSearchText] = useState<string>()

    const [selectedIpd, setSelectedIPd] = useState<FhirClinicTherapyBasic>()

    const [viewType, setViewType] = useState<string | undefined>('list')

    const { t, i18n } = useTranslation(['en', 'labelCommon'])
    const [currentOrganizationDetails, setCurrentOrganizationDetails] =
      useState<R4.IOrganization>(getCurrentUserUnitDetails())
    const loggedInUnitType =
      getCodeOfSystemFromCodableConceptList(
        currentOrganizationDetails.type ?? [],
        'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
      )?.display ?? ''

    const handleViewTypeChange = (
      event: React.MouseEvent<HTMLElement>,
      newView: string | undefined
    ) => {
      setViewType(newView)
    }

    const navigateToPath = (path: string) => {
      // history.replace(path)
      window.location.href = path
    }

    useEffect(() => {
      dispatch(searchReferrals(appointmentSearchSlice, searchText))
    }, [searchText])

    const { innerWidth, innerHeight, outerHeight, outerWidth } = useWindowSize()

    function openAppointment(appointment: FhirClinicTherapyBasic) {
      navigateToPath(
        `/${getVendorPartId()}/viewIpdAppointment?id=${
          appointment.mainServiceRequestId
        }`
      )
    }

    /*   const menu: ToggleOption[] = [-4, -3, -2, -1, 0, 1].map((e) => ({
          value: moment().add(e, 'days').startOf('day').format(),
          displayText:
            moment()
              .add(e, 'days')
              .calendar(null, {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'DD MMM',
                lastDay: '[Yesterday]',
                lastWeek: 'DD MMM',
                sameElse: 'DD MMM',
              })
              .split(' at')[0] ?? '',
        })) */

    const statusMenu: ToggleOption[] = therapyOptions.map((e) => ({
      value: e.id,
      displayText: e.display,
    }))

    useEffect(() => {
      dispatch(
        requestForIncomingReferralsLists(
          selectedIpdStatuses,
          undefined,
          undefined,
          undefined
        )
      )
    }, [dispatch, selectedDate, selectedIpdStatuses])

    return (
      <div
        style={{
          overflow: 'auto',
          height: '100%',
          borderRadius: 8,
          backgroundColor: '#ececec',
        }}
      >
        <Paper
          elevation={0}
          style={{
            backgroundColor: 'transparent',
            overflow: 'auto',
            height: '100%',
          }}
        >
          <Box
            display='flex'
            flexDirection='row'
            overflow='auto'
            style={{ overflow: 'auto', height: '100%' }}
          >
            {/* <Box
            display='flex'
            width='22%'
            maxWidth='22%'
            style={{ overflow: 'auto', height: '100%', minWidth: '290px' }}
          >
            <SimpleBar
              style={{
                height: '100%',
                width: '100%',
                overflowX: 'hidden',
                padding: '8px',
              }}
            >
              <Box px={1} width='100%'>
                <Box width='100%'>
                  <Calendar
                    size='xs'
                    style={{
                      aspectRatio: '1/1',
                    }}
                    value={selectedDate}
                    onChange={setSelectedDate}
                    // onMonthChange={setSelectedDate}
                    dayStyle={(date, modifier) => {
                      if (isToday(date)) {
                        return {
                          aspectRatio: '1',
                          borderBottom: `1px solid ${kPrimaryDark}`,
                          bottom: '20%',
                        }
                      }
                      return { aspectRatio: '1' }
                    }}
                    styles={{
                      cell: {
                        aspectRatio: '1',
                      },
                      day: {
                        aspectRatio: '1 !important',
                      },
                      weekend: {
                        color: 'black',
                      },
                      weekday: {
                        color: kPrimaryMain,
                      },
                      selected: {
                        borderRadius: '50%',
                      },
                      label: {
                        fontWeight: 'bold',
                        color: kPrimaryMain,
                      },
                    }}
                  />
                </Box>
                <IPDStatusSelection
                  onSelectionChanged={(changedTypes) => {
                    setSelectedIPDStatuses(changedTypes)
                  }}
                />
              </Box>
            </SimpleBar>
          </Box> */}

            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              width='100%'
              px={1}
              style={{ overflow: 'auto', maxHeight: '100%' }}
            >
              <Box
                flexDirection='row'
                width='100%'
                display='flex'
                py={1}
                justifyContent='center'
                alignContent='center'
                alignItems='center'
              >
                <Typography variant='h6' color='initial'>
                  Referrals
                </Typography>
              </Box>
              <Box
                flexDirection={innerWidth! < 800 ? 'column' : 'row'}
                width='100%'
                display='flex'
                py={0.5}
                justifyContent={
                  innerWidth! < 800 ? 'flex-start' : 'space-between'
                }
              >
                {/*   <Box
                  display='flex'
                  flexDirection='row'
                  alignContent='center'
                  alignItems='center'
                  py={innerWidth! < 800 ? 0.5 : 0}
                >
                  <Box width='60px'>
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      style={{
                        paddingRight: '8px',
                      }}
                    >
                      Day :
                    </Typography>
                  </Box>
                  <CustomizedDividers
                    onSelectionChanged={(value) => {
                      if (value !== null) {
                        setSelectedDate(moment(value).toDate())
                      }
                    }}
                    options={menu}
                    preSelectedValue={moment(selectedDate)
                      .startOf('day')
                      .format()}
                  />
                </Box>
 */}
                <Box
                  display='flex'
                  flexDirection='row'
                  alignContent='center'
                  alignItems='center'
                  justifyContent='space-between'
                  py={innerWidth! < 800 ? 0.5 : 0}
                  width='100%'
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignContent='center'
                    alignItems='center'
                    flexGrow='1'
                  >
                    <Typography variant='subtitle1' color='initial'>
                      {' '}
                      Search
                    </Typography>
                    <TextField
                      id='referrals_search'
                      label='Search'
                      variant='outlined'
                      size='small'
                      disabled={
                        appointmentSearchSlice.searchingAppointments ||
                        appointmentSearchSlice.noResultsAvailable ||
                        (appointmentSearchSlice.baseList ?? []).length === 0
                      }
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </Box>
                  <Box width='60px'>
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      style={{
                        paddingRight: '8px',
                      }}
                    >
                      Status :
                    </Typography>
                  </Box>
                  <TabbedMultiSelector
                    onSelectionChanged={(value) => {
                      if (value !== null) {
                        setSelectedIPDStatuses(value)
                      }
                    }}
                    options={statusMenu}
                    preSelectedValues={[therapyOptions[0].id]}
                  />
                </Box>
              </Box>
              <Box flexDirection='row' width='100%' display='flex' height='8px'>
                {appointmentSearchSlice.searchingAppointments && (
                  <LinearProgress
                    style={{
                      height: '4px',
                      width: '100%',
                    }}
                  />
                )}
              </Box>
              <Box
                display='flex'
                flexGrow={1}
                flexDirection='column'
                justifyContent='Start'
                alignContent='center'
                overflow='auto'
                style={{
                  backgroundColor: '#00000005',
                }}
              >
                {(appointmentSearchSlice.noResultsAvailable ||
                  displayList === undefined ||
                  displayList?.length === 0) &&
                  !appointmentSearchSlice.searchingAppointments && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='100%'
                      height='100%'
                      alignContent='center'
                    >
                      <Typography
                        variant='subtitle1'
                        color='initial'
                        align='center'
                      >
                        {isTherapist()
                          ? 'No Therapies to be performed'
                          : 'No Incoming Referrals'}
                      </Typography>
                    </Box>
                  )}

                {appointmentSearchSlice.errorWhileSearchingOrders && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='error'
                      align='center'
                    >
                      Error while searching Incoming referrals
                    </Typography>
                  </Box>
                )}

                {displayList && displayList.length > 0 && (
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    height='100%'
                    overflow='auto'
                  >
                    <Box
                      display='flex'
                      overflow='auto'
                      height='100%'
                      flexGrow={2}
                    >
                      <List
                        id='scrollableDiv'
                        subheader={<li style={{ padding: 0, width: '100%' }} />}
                        style={{
                          padding: 0,
                          width: '100%',
                          overflow: 'auto',
                          height: '100%',
                        }}
                      >
                        <InfiniteScroll
                          dataLength={appointmentSearchSlice.recordsCount ?? 0}
                          scrollThreshold='300px'
                          next={() => {
                            dispatch(
                              requestForIncomingReferralsLists(
                                selectedIpdStatuses,
                                appointmentSearchSlice.pageState,
                                appointmentSearchSlice.baseList?.length,
                                appointmentSearchSlice.baseList
                              )
                            )
                          }}
                          hasMore={
                            (appointmentSearchSlice.baseList?.length ?? 0) <
                            (appointmentSearchSlice.recordsCount ?? -1)
                          }
                          loader=''
                          endMessage={
                            appointmentSearchSlice.searchingAppointments ? (
                              <p />
                            ) : (
                              <p style={{ textAlign: 'center' }} />
                            )
                          }
                          scrollableTarget='scrollableDiv'
                        >
                          {displayList!.map((e) => (
                            <ReferralDisplayTile
                              ipdAppointmentDetails={e}
                              onManageClicked={() => {}}
                              key={e.id}
                              onDataUpdated={() => {
                                dispatch(
                                  requestForIncomingReferralsLists(
                                    selectedIpdStatuses,
                                    undefined,
                                    undefined,
                                    undefined
                                  )
                                )
                              }}
                            />
                          ))}
                        </InfiniteScroll>
                      </List>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Paper>
      </div>
    )
  }
