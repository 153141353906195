import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirClinicalImpressionDetail } from 'models/fhirClinicalImpression'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { updateAppointmentStatus } from 'redux/appointments/appointmentViewHandler/appointmentHandlerSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getAppointmentIdForFollowUp,
  getDefaultCodeOfSystemFromCodableConcept,
  getNameFromHumanName,
} from 'utils/fhirResourcesHelper'
import { getEncounterObjectForAppointment } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getClinicaImpressionDetailsFromBundleResponse } from 'utils/fhirResoureHelpers/clinicalImpressionHelper'
import { logger } from 'utils/logger'
import { getNameOfPatient } from 'wello-web-components'
import { AssessmentDetailsStatus } from './followUpAssessmentDetailsType'

const initialState: AssessmentDetailsStatus = {
  fetchingImpressions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  updatedImpressions: false,
  updatingImpressions: false,
  errorWhileSearchingImpressions: false,
  errorWhileUpdatingImpressions: false,
}

const followUpAssessmentDetailsSlice = createSlice({
  name: 'followUpAssessmentDetailsSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<AssessmentDetailsStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingImpressions = action.payload.fetchingImpressions
      state.resultsAvailable = action.payload.resultsAvailable
      state.clinicalImpressions = action.payload.clinicalImpressions
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingImpressions =
        action.payload.errorWhileUpdatingImpressions
      state.updatedImpressions = action.payload.updatedImpressions
      state.updatingImpressions = action.payload.updatingImpressions
      state.errorWhileSearchingImpressions =
        action.payload.errorWhileSearchingImpressions
    },
    resetState(state, action: PayloadAction<AssessmentDetailsStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingImpressions = action.payload.fetchingImpressions
      state.resultsAvailable = action.payload.resultsAvailable
      state.clinicalImpressions = action.payload.clinicalImpressions
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingImpressions =
        action.payload.errorWhileUpdatingImpressions
      state.updatedImpressions = action.payload.updatedImpressions
      state.updatingImpressions = action.payload.updatingImpressions
      state.errorWhileSearchingImpressions =
        action.payload.errorWhileSearchingImpressions
    },
  },
})

export const getClinicalImpressionsOfAppointmentForFollowUp =
  (fhirAppointmentDetails: FhirAppointmentDetail): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AssessmentDetailsStatus = { ...initialState }
    state.fetchingImpressions = true
    dispatch(followUpAssessmentDetailsSlice.actions.updatedStatus(state))
    try {
      const appointmentId: string = getAppointmentIdForFollowUp(
        fhirAppointmentDetails.appointment.supportingInformation ?? []
      )
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        'encounter.appointment': appointmentId,
        _include: 'ClinicalImpression:problem',
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/ClinicalImpression',
        appointmentId,
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingImpressions = true
        state.fetchingImpressions = false

        dispatch(followUpAssessmentDetailsSlice.actions.updatedStatus(state))
      } else {
        const conditionResponse: R4.IBundle = resp.right
        logger.info(
          'Chief Complaint resp',
          conditionResponse.entry?.[0].resource as R4.ICondition
        )

        if (conditionResponse?.total && conditionResponse?.total > 0) {
          const clinicalImpressions: FhirClinicalImpressionDetail[] =
            getClinicaImpressionDetailsFromBundleResponse(conditionResponse)

          if (clinicalImpressions && clinicalImpressions.length > 0) {
            state.resultsAvailable = true
            state.fetchingImpressions = false
            state.clinicalImpressions = clinicalImpressions[0]

            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingImpressions = false
            dispatch(
              followUpAssessmentDetailsSlice.actions.updatedStatus(state)
            )
          }
        } else {
          const errorSearchDoctor: AssessmentDetailsStatus = { ...initialState }
          errorSearchDoctor.noResultsAvailable = true
          dispatch(
            followUpAssessmentDetailsSlice.actions.updatedStatus(
              errorSearchDoctor
            )
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: AssessmentDetailsStatus = { ...initialState }
      errorSearchDoctor.errorReason = 'Error while fetching assessment details'
      errorSearchDoctor.errorWhileSearchingImpressions = false
      dispatch(
        followUpAssessmentDetailsSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export const updateClinicalImpressions =
  (
    type: string,
    operation: string,
    appointment: FhirAppointmentDetail,
    summaryNotes: string,
    findings?: R4.IClinicalImpression_Finding[],
    diagnosedConditions?: R4.ICodeableConcept[],
    diagnosedAllergies?: R4.ICoding[],
    deletedData?: R4.ICoding
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (
      summaryNotes ||
      diagnosedConditions ||
      diagnosedAllergies ||
      deletedData
    ) {
      let state: AssessmentDetailsStatus = { ...initialState }
      state.updatingImpressions = true
      dispatch(followUpAssessmentDetailsSlice.actions.updatedStatus(state))
      try {
        const bundleObject: R4.IBundle =
          createBundleObjectForClinicalImpression(
            operation,
            appointment,
            summaryNotes,
            findings,
            diagnosedConditions,
            diagnosedAllergies,
            deletedData
          )

        logger.info(
          '-------------------bundleObject clinical impression-------------------------------'
        )

        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)

        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)

        if (relatedFhirDecodeRes._tag === 'Right') {
          if (relatedFhirDecodeRes.right) {
            state = { ...initialState }
            state.updatedImpressions = true

            if (appointment.encounter === undefined) {
              dispatch(updateAppointmentStatus(appointment.appointment.id!))
            }
            dispatch(
              followUpAssessmentDetailsSlice.actions.updatedStatus(state)
            )
            if (
              operation === 'add' &&
              (type === 'condition' || type === 'alergy')
            )
              dispatch(
                showSuccessAlert(
                  type === 'condition'
                    ? 'Condition added successfully'
                    : 'Allergy added successfully'
                )
              )
            else if (
              operation === 'delete' ||
              type === 'condition' ||
              type === 'alergy'
            ) {
              dispatch(
                showSuccessAlert(
                  type === 'condition'
                    ? 'Condition deleted successfully'
                    : 'Allergy deleted successfully'
                )
              )
              dispatch(
                followUpAssessmentDetailsSlice.actions.updatedStatus(state)
              )
            } else
              dispatch(
                showSuccessAlert('Assessment summary added successfully')
              )
          } else {
            const errorSearchDoctor: AssessmentDetailsStatus = {
              ...initialState,
            }
            errorSearchDoctor.errorWhileUpdatingImpressions = true
            errorSearchDoctor.errorReason = 'error while updating . try later'

            dispatch(
              followUpAssessmentDetailsSlice.actions.updatedStatus(
                errorSearchDoctor
              )
            )
          }
        } else {
          const errorSearchDoctor: AssessmentDetailsStatus = {
            ...initialState,
          }

          errorSearchDoctor.errorWhileUpdatingImpressions = true
          errorSearchDoctor.errorReason = 'error while updating . try later'
          dispatch(
            followUpAssessmentDetailsSlice.actions.updatedStatus(
              errorSearchDoctor
            )
          )
        }
      } catch (error) {
        logger.error(error)
        const errorSearchDoctor: AssessmentDetailsStatus = { ...initialState }
        errorSearchDoctor.errorReason =
          'Error while adding/updating chief complaint details'
        errorSearchDoctor.errorWhileUpdatingImpressions = false
        dispatch(
          followUpAssessmentDetailsSlice.actions.updatedStatus(
            errorSearchDoctor
          )
        )
      }
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(followUpAssessmentDetailsSlice.actions.resetState(initialState))
}

function createBundleObjectForClinicalImpression(
  operation: string,
  appointment: FhirAppointmentDetail,
  summaryNotes: string,
  findings?: R4.IClinicalImpression_Finding[],
  diagnosedConditions?: R4.ICodeableConcept[],
  diagnosedAllergies?: R4.ICoding[],
  deletedData?: R4.ICoding
): R4.IBundle {
  const encounter: R4.IEncounter = getEncounterObjectForAppointment(appointment)
  const fullUrlEncounter: string = 'urn:uuid:1232323232324'
  const matchStringEncounter: string = `${encounter.resourceType}?appointment=${appointment.appointment.resourceType}/${appointment.appointment.id}`
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: fullUrlEncounter,
        request: {
          url: matchStringEncounter,
          method: R4.Bundle_RequestMethodKind._put,
          ifMatch: matchStringEncounter,
        },
        resource: encounter,
      },
    ],
  }
  const matchStringClinicalImpression: string = `ClinicalImpression?encounter=${fullUrlEncounter}`
  if (findings && findings.length > 0) {
    findings = findings.map((finding) => {
      const findData = finding.itemCodeableConcept

      const conditionMatchString = `Condition?encounter=${fullUrlEncounter}&code=${findData}&category=assessment-finding`
      const randomNum: number = getRandomInt(999999)
      const conditionFullUrl = `urn:uuid:${randomNum.toString()}`
      const newVal = { ...finding }
      newVal.itemReference = {
        reference: `Condition/${conditionFullUrl}`,
        type: 'Condition',
      }
      const entry: R4.IBundle_Entry = {
        fullUrl: conditionFullUrl,
        request: {
          url: conditionMatchString,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: {
          resourceType: 'Condition',
          subject: {
            display: getNameOfPatient(appointment.patient),
            id: appointment.patient.id,
            reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
            type: appointment.patient.resourceType,
          },
          clinicalStatus: {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/condition-clinical',
                code: 'active',
                display: 'Active',
              },
            ],
          },
          code: finding.itemCodeableConcept,
          encounter: {
            reference: `${encounter.resourceType}/${fullUrlEncounter}`,
            type: encounter.resourceType,
          },
          recorder: {
            display: getNameFromHumanName(
              appointment.practitionerDetail.practitioner.name ?? []
            ),
            id: appointment.practitionerDetail.practitionerRole.id,
            reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
            type: appointment.practitionerDetail.practitionerRole.resourceType,
          },
          asserter: {
            display: getNameFromHumanName(
              appointment.practitionerDetail.practitioner.name ?? []
            ),
            id: appointment.practitionerDetail.practitionerRole.id,
            reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
            type: appointment.practitionerDetail.practitionerRole.resourceType,
          },
          verificationStatus: {
            text: operation === 'delete' ? 'Refuted' : 'Confirmed',
            coding: [
              {
                code: operation === 'delete' ? 'refuted' : 'confirmed',
                display: operation === 'delete' ? 'Refuted' : 'Confirmed',
                system:
                  'http://terminology.hl7.org/CodeSystem/condition-ver-status',
              },
            ],
          },
          category: [
            {
              coding: [
                {
                  code: 'assessment-finding',
                  display: 'Assessment Finding',
                  system: 'http://wellopathy.com/condition-category',
                },
              ],
            },
          ],
        },
      }
      requestBundle.entry?.push(entry)
      return newVal
    })
    /* findings.forEach((finding) => {
      let conditionMatchString =
        "Condition?encounter=" +
        fullUrlEncounter +
        "&code=" +
        finding.itemCodeableConcept?.coding?.[0].code +
        "&category=assessment-finding";
      let randomNum: number = getRandomInt(999999);
      let conditionFullUrl = "urn:uuid:" + randomNum.toString();
      finding.itemReference = {
        reference: "Condition/" + conditionFullUrl,
        type: "Condition",
      };
      
    }); */
  }

  const cis: R4.IClinicalImpression = {
    resourceType: 'ClinicalImpression',
    status: 'in-progress',
    encounter: {
      reference: `${encounter.resourceType}/${fullUrlEncounter}`,
      type: encounter.resourceType,
    },
    assessor: {
      reference: `${appointment.practitionerDetail.practitionerRole?.resourceType}/${appointment.practitionerDetail.practitionerRole?.id}`,
      id: appointment.practitionerDetail.practitionerRole?.id,
      type: appointment.practitionerDetail.practitionerRole?.resourceType,
    },
    subject: {
      display: getNameOfPatient(appointment.patient),
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      id: appointment.patient.id,
      type: appointment.patient.resourceType,
    },
    problem: [],
    finding: findings ?? [],
    summary: summaryNotes,
    date: moment(new Date()).format(),
  }
  logger.info('Conditions ', diagnosedConditions)

  if (diagnosedConditions && diagnosedConditions.length > 0) {
    diagnosedConditions.forEach((finding: any) => {
      const codeFinal = finding.coding ? finding.coding[0].code ?? '' : ''

      const code = getDefaultCodeOfSystemFromCodableConcept(
        deletedData,
        'http://snomed.info/sct'
      )

      const conditionMatchString = `Condition?encounter=${fullUrlEncounter}&code=${
        finding.code ??
        getDefaultCodeOfSystemFromCodableConcept(
          finding,
          'http://snomed.info/sct'
        )
      }&category=encounter-diagnosis`
      const findDataCode =
        finding.code ??
        getDefaultCodeOfSystemFromCodableConcept(
          finding,
          'http://snomed.info/sct'
        )

      const randomNum: number = getRandomInt(999999)
      const conditionFullUrl = `urn:uuid:${randomNum.toString()}`
      if (
        finding.code ??
        getDefaultCodeOfSystemFromCodableConcept(
          finding,
          'http://snomed.info/sct'
        ) !== code
      ) {
        const entry: R4.IBundle_Entry = {
          fullUrl: conditionFullUrl,
          request: {
            url: conditionMatchString,
            method: R4.Bundle_RequestMethodKind._put,
          },
          resource: {
            resourceType: 'Condition',
            onsetDateTime: new Date().toISOString(),
            clinicalStatus: {
              coding: [
                {
                  system:
                    'http://terminology.hl7.org/CodeSystem/condition-clinical',
                  code: 'active',
                  display: 'Active',
                },
              ],
            },
            subject: {
              display: getNameOfPatient(appointment.patient),
              id: appointment.patient.id,
              reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
              type: appointment.patient.resourceType,
            },
            code: finding.code
              ? { coding: [finding], text: finding.display }
              : finding,
            encounter: {
              reference: `${encounter.resourceType}/${fullUrlEncounter}`,
              type: encounter.resourceType,
            },
            recorder: {
              display: getNameFromHumanName(
                appointment.practitionerDetail.practitioner.name ?? []
              ),
              id: appointment.practitionerDetail.practitionerRole.id,
              reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
              type: appointment.practitionerDetail.practitionerRole
                .resourceType,
            },
            category: [
              {
                coding: [
                  {
                    code: 'encounter-diagnosis',
                    display: 'Encounter Diagnosis',
                    system:
                      'http://terminology.hl7.org/CodeSystem/condition-category',
                  },
                ],
              },
            ],
            asserter: {
              display: getNameFromHumanName(
                appointment.practitionerDetail.practitioner.name ?? []
              ),
              id: appointment.practitionerDetail.practitionerRole.id,
              reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
              type: appointment.practitionerDetail.practitionerRole
                .resourceType,
            },
            verificationStatus: {
              text: findDataCode === code ? 'Refuted' : 'Confirmed',
              coding: [
                {
                  code: findDataCode === code ? 'refuted' : 'confirmed',
                  display: findDataCode === code ? 'refuted' : 'confirmed',
                  system:
                    'http://terminology.hl7.org/CodeSystem/condition-ver-status',
                },
              ],
            },
          },
        }
        cis.problem?.push({
          type: 'Condition',
          reference: `${conditionFullUrl}`,
        })
        logger.info('Pushing condition entry', entry)
        requestBundle.entry?.push(entry)
      }
    })
  }
  if (diagnosedAllergies && diagnosedAllergies.length > 0) {
    diagnosedAllergies.forEach((finding) => {
      const conditionMatchString = `AllergyIntolerance?patient=${appointment.patient.id}&code=${finding.code}`
      const conditionFullUrl = `urn:uuid:${getRandomInt(999999).toString()}`
      const code = deletedData ? deletedData.code ?? '' : ''
      if (finding.code !== code) {
        const entry: R4.IBundle_Entry = {
          fullUrl: conditionFullUrl,
          request: {
            url: conditionMatchString,
            method: R4.Bundle_RequestMethodKind._put,
          },
          resource: {
            resourceType: 'AllergyIntolerance',
            patient: {
              display: getNameOfPatient(appointment.patient),
              id: appointment.patient.id,
              reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
              type: appointment.patient.resourceType,
            },
            onsetDateTime: new Date().toISOString(),
            type: R4.AllergyIntoleranceTypeKind._allergy,
            code: { coding: [finding], text: finding.display },
            encounter: {
              reference: `${encounter.resourceType}/${fullUrlEncounter}`,
              type: encounter.resourceType,
            },
            recorder: {
              display: getNameFromHumanName(
                appointment.practitionerDetail.practitioner.name ?? []
              ),
              id: appointment.practitionerDetail.practitionerRole.id,
              reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
              type: appointment.practitionerDetail.practitionerRole
                .resourceType,
            },
            asserter: {
              display: getNameFromHumanName(
                appointment.practitionerDetail.practitioner.name ?? []
              ),
              id: appointment.practitionerDetail.practitionerRole.id,
              reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
              type: appointment.practitionerDetail.practitionerRole
                .resourceType,
            },
            verificationStatus: {
              text: finding.code === code ? 'Refuted' : 'Confirmed',
              coding: [
                {
                  code: finding.code === code ? 'refuted' : 'confirmed',
                  display: finding.code === code ? 'refuted' : 'confirmed',
                  system:
                    'http://terminology.hl7.org/CodeSystem/condition-ver-status',
                },
              ],
            },
          },
        }
        cis.problem?.push({
          type: 'AllergyIntolerance',
          reference: `${conditionFullUrl}`,
        })
        requestBundle.entry?.push(entry)
      }
    })
  }

  const cisEntry: R4.IBundle_Entry = {
    fullUrl: 'urn:uuid:1232323232325',
    request: {
      url: matchStringClinicalImpression,
      method: R4.Bundle_RequestMethodKind._put,
    },
    resource: cis,
  }
  requestBundle.entry?.push(cisEntry)

  return requestBundle
}

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max))
}

export default followUpAssessmentDetailsSlice.reducer
