import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core'
import { FhirAppointmentFullDetail } from 'models/fhirAppointmentFullDetail'
import { FhirLabOrderFullDetail } from 'models/fhirLabOrderFullDetails'
import { MedicalEvent, MedicalEventType } from 'models/medical_event'
import React from 'react'
import { useDispatch } from 'react-redux'
import { downloadingDocument } from 'redux/lab/download/downloadDocumentSlice'
import {
  getMedicalEventDate,
  getMedicalEventDisplayType,
  getMedicalEventStartAndEndTime,
} from 'utils/patient_profile/patient_profile_helpers'
import { MedicalEventLabTestReading } from './medical_event_lab_lest_reading'

interface IProps {
  medicalEvent: MedicalEvent
  onTap?: () => void
  dense?: boolean
  isSelected?: boolean
}

export const PatientMedicalEventTile: React.FC<IProps> = ({
  medicalEvent,
  onTap,
  dense = true,
  isSelected = false,
}: IProps) => {
  const dispatch = useDispatch()

  return (
    <Box
      width='100%'
      onClick={() => {
        if (medicalEvent.eventType === MedicalEventType.DOCTOR_VISIT) {
          if (onTap) {
            onTap()
          }
        }
      }}
      px={isSelected ? 0 : 0.5}
    >
      <Card
        elevation={0}
        style={{
          padding: 0,
          margin: 0,
          borderTopRightRadius: isSelected ? 0 : 4,
          borderBottomLeftRadius: 4,
          borderTopLeftRadius: 4,
          borderBottomRightRadius: isSelected ? 0 : 4,
        }}
      >
        <CardContent
          style={{
            paddingLeft: 16,
            paddingRight: isSelected ? 0 : 8,
            border: 0,
          }}
        >
          <Box display='flex' flexDirection='row' width='100%'>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              width={dense ? '75%' : '15%'}
            >
              <Typography variant='subtitle1' color='initial'>
                {getMedicalEventDisplayType(medicalEvent.eventType)}
              </Typography>
              <Typography variant='body2' color='initial'>
                {getMedicalEventDate(
                  medicalEvent.startTime,
                  medicalEvent.endTime
                )}
              </Typography>
              <Typography variant='body2' color='initial'>
                {getMedicalEventStartAndEndTime(
                  medicalEvent.startTime,
                  medicalEvent.endTime
                )}
              </Typography>
            </Box>
            {!dense && medicalEvent.eventType === MedicalEventType.LAB_REPORT && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                width='85%'
              >
                {(
                  medicalEvent.eventDetails as FhirLabOrderFullDetail
                ).testAndResults.map((e) => (
                  <MedicalEventLabTestReading
                    key={e.diagnosticReport.id ?? 'dfa'}
                    diagnosticReport={e.diagnosticReport}
                    observations={e.results}
                  />
                ))}
              </Box>
            )}

            {!dense &&
              medicalEvent.eventType ===
                MedicalEventType.DOCUMENT_REFERENCE && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                  flexGrow={1}
                  px={1}
                >
                  <Typography variant='subtitle1' color='initial'>
                    {medicalEvent.tileText ?? ''}
                  </Typography>
                  <Typography variant='subtitle1' color='initial'>
                    <Button
                      color='primary'
                      onClick={() => {
                        dispatch(
                          downloadingDocument(
                            `${
                              (
                                medicalEvent.eventDetails as R4.IDocumentReference
                              ).resourceType
                            }/${
                              (
                                medicalEvent.eventDetails as R4.IDocumentReference
                              ).id
                            }`
                          )
                        )
                      }}
                    >
                      View
                    </Button>
                  </Typography>
                </Box>
              )}
            {!dense &&
              medicalEvent.eventType === MedicalEventType.DOCTOR_VISIT && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                  width='35%'
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-center'
                  >
                    <Avatar
                      alt={medicalEvent.performerName}
                      src={medicalEvent.performerProfilePic}
                    />
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='flex-start'
                      flexGrow={1}
                      px={1}
                    >
                      <Typography variant='subtitle1' color='primary'>
                        {medicalEvent.performerName}
                      </Typography>
                      <Typography variant='body2' color='initial'>
                        {medicalEvent.performerSpecialization ?? ''}
                      </Typography>

                      <Typography variant='subtitle2' color='initial'>
                        {medicalEvent.summary ?? ''}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            {!dense &&
              medicalEvent.eventType === MedicalEventType.DOCTOR_VISIT &&
              (medicalEvent.eventDetails as FhirAppointmentFullDetail)
                .medications &&
              (medicalEvent.eventDetails as FhirAppointmentFullDetail)
                .medications!.length > 0 && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                  width='45%'
                >
                  <Typography variant='subtitle1' color='initial'>
                    {medicalEvent.secondaryTitle ?? ''}
                  </Typography>
                  {medicalEvent.secondaryContent?.map((e) => (
                    <Box key={e}>
                      <Typography variant='body2' color='initial'>
                        {e}
                      </Typography>
                    </Box>
                  )) ?? ''}
                </Box>
              )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
