import { Box, Card, CardContent, Typography } from '@material-ui/core'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch } from 'react-redux'
import { getTotalAmountOfLabOfferings } from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { TotalAmount } from './totalAmount'

interface Props {
  labOrderDetails: FhirLabOrderDetail
  showTitle?: boolean
}
export const PartnerLabOrderAmount: React.FC<Props> = ({
  labOrderDetails,
  showTitle = true,
}: Props) => {
  const dispatch = useDispatch()

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
        </div>
      )}
    >
      <Card
        elevation={0}
        style={{
          width: '100%',
          height: 102,
        }}
      >
        <CardContent>
          <Box display='flex' flexDirection='column' width='100%'>
            {showTitle && (
              <Box display='flex' flexDirection='row' width='100%'>
                <Typography variant='h6' color='initial'>
                  Payment Received (IN INR) :Cash
                </Typography>
              </Box>
            )}
            <Box display='flex' flexDirection='row' width='100%'>
              <TotalAmount
                currency='INR'
                totalAmount={getTotalAmountOfLabOfferings(
                  labOrderDetails.tests ?? []
                )}
              />
            </Box>

            {/* <Box display='flex' flexDirection='row' width='100%' paddingTop={1}>
              <Divider
                orientation='horizontal'
                color='#6B6B6B'
                variant='fullWidth'
                style={{
                  backgroundColor: 'black',
                  color: '#6B6B6B',
                  width: '100%',
                }}
              />
            </Box> */}
            {/* <Box display='flex' flexDirection='row' width='100%'>
              <TotalAmount
                currency='INR'
                totalAmount={getTotalAmountOfLabOfferings(
                  labOrderDetails.tests ?? []
                )}
              />
            </Box> */}
          </Box>
        </CardContent>
      </Card>
    </ErrorBoundary>
  )
}
