import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirLabDiagnosticRequest } from 'models/fhirLabDiagnosticRequest'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import { getLabDiagnosticRequestFromBundle } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { logger } from 'utils/logger'
import { WellnessReferralsListStatusType } from './wellnessReferralsListStatusType'

const initialState: WellnessReferralsListStatusType = {
  fetchingImpressions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  updatedImpressions: false,
  updatingImpressions: false,
  errorWhileSearchingImpressions: false,
  errorWhileUpdatingImpressions: false,
}

const wellnessReferralsListSliceIPD = createSlice({
  name: 'wellnessReferralsListSliceIPD',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<WellnessReferralsListStatusType>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingImpressions = action.payload.fetchingImpressions
      state.resultsAvailable = action.payload.resultsAvailable
      state.lanDiagnosticRequests = action.payload.lanDiagnosticRequests
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingImpressions =
        action.payload.errorWhileUpdatingImpressions
      state.updatedImpressions = action.payload.updatedImpressions
      state.updatingImpressions = action.payload.updatingImpressions
      state.errorWhileSearchingImpressions =
        action.payload.errorWhileSearchingImpressions
    },
  },
})

export const getWellnessServiceRequestsForIPD =
  (
    fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole,
    followUp?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: WellnessReferralsListStatusType = { ...initialState }
    state.fetchingImpressions = true
    dispatch(wellnessReferralsListSliceIPD.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        encounter: fhirAppointmentDetails.mainEncounter?.id!,
        category: '394656005',
        intent: 'proposal',
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/ServiceRequest',
        '',
        searchParameters
      )

      logger.info(
        '-----------------------------SE-----REQ----------------------------------------------',
        response
      )
      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingImpressions = true
        state.fetchingImpressions = false

        dispatch(wellnessReferralsListSliceIPD.actions.updatedStatus(state))
      } else {
        const conditionResponse: R4.IBundle = resp.right
        logger.info('Medication resp', conditionResponse.entry)
        if (conditionResponse?.total && conditionResponse?.total > 0) {
          state.resultsAvailable = true
          state.fetchingImpressions = false
          state.lanDiagnosticRequests = conditionResponse.entry?.map(
            (e) => e.resource as R4.IServiceRequest
          )

          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingImpressions = false
          dispatch(wellnessReferralsListSliceIPD.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: WellnessReferralsListStatusType = {
            ...initialState,
          }
          errorSearchDoctor.noResultsAvailable = true
          dispatch(
            wellnessReferralsListSliceIPD.actions.updatedStatus(
              errorSearchDoctor
            )
          )
        }
      } /* */
    } catch (error) {
      const errorSearchDoctor: WellnessReferralsListStatusType = {
        ...initialState,
      }
      errorSearchDoctor.errorReason = 'Error while fetching assessment details'
      errorSearchDoctor.errorWhileSearchingImpressions = false
      dispatch(
        wellnessReferralsListSliceIPD.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }
export default wellnessReferralsListSliceIPD.reducer
