import { R4 } from '@ahryman40k/ts-fhir-types'
import { Calendar } from '@mantine/dates'
import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core'
import { GridOnOutlined } from '@material-ui/icons'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { kPrimaryLight, kPrimaryMain } from 'configs/styles/muiThemes'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestOrdersCountForTodayAgent } from 'redux/lab/order/orderCount/orderCountForAgent'
import { fetchOrderDetails } from 'redux/lab/order/orderManagementSlice/orderManagementSlice'
import { requestOrdersForTheDateRangeAgentPage } from 'redux/lab/order/orderSearchSlice/orderSearchSliceForAgent'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserUnitDetails,
  getCurrentUserUnitLocationDetails,
} from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import { isToday } from 'utils/dateUtil'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { AgentLabOrderTabularView } from 'views/components/lab/orderList/agentLabOrderTableView'
import { AgentListOrderView } from 'views/components/lab/orderList/agentOrderListView'
import '../../../App.css'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selected: {
      /*  backgroundColor: `${theme.palette.background.paper}!important`,
      margin: theme.spacing(0.5),
      borderRadius: theme.shape.borderRadius, */
    },
  })
)

const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  grouped: {
    padding: theme.spacing(0.5),

    border: 'none',
    '&.Mui-selected': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
    },
  },
}))(ToggleButtonGroup)

export const AgentLandingPage: React.FC = () => {
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const classes = useStyles()

  const [openAddPatientPopup, setOpenAddPatientPopup] = useState(false)
  const [openUploadReportPopup, setOpenUploadReportPopup] = useState(false)
  const [openPartnerLabPopup, setOpenPartnerLabPopup] = useState(false)
  const selectedDoctors = useRef<PractitionerWithRole[] | undefined>()
  const [selectedOrderTypes, setSelectedOrderTypes] = useState<string[]>([
    'onsite-sample-collection',
    'home-sample-collection',
  ])
  const [selectedTests, setSelectedTests] = useState<LabOfferingDetail[]>([])
  const appointmentSearchSlice = useSelector(
    (state: RootState) => state.orderSearchSliceForAgent
  )
  const [selectedOrder, setSelectedOrder] = useState<FhirLabOrderDetail>()
  const [openCreateLabOrderPopup, setOpenCreateLabOrderPopup] = useState(false)
  const [openAgentDisplayPopup, setOpenAgentDisplayPoup] = useState(false)
  const [viewType, setViewType] = useState<string | undefined>('list')
  const [currentLocation, setCurrentLocation] = useState<R4.ILocation>(
    getCurrentUserUnitLocationDetails()
  )
  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<R4.IOrganization>(getCurrentUserUnitDetails())
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      currentOrganizationDetails.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''
  const handleViewTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | undefined
  ) => {
    setViewType(newView)
  }

  useEffect(() => {
    // if (loggedInUnitType === 'Lab') document.title = 'WelloLAB™'
    // if (loggedInUnitType === 'Clinic') document.title = 'WelloClinic™'
    // if (loggedInUnitType === 'Wellness Center') document.title = 'WelloClinic™'
    // if (loggedInUnitType === 'Collection Center')
    //   document.title = 'WelloLAB (Collection Center)™'
    // if (loggedInUnitType === 'Partner Lab')
    //   document.title = 'WelloLAB (Partner Lab)™'
    dispatch(
      requestOrdersForTheDateRangeAgentPage(
        selectedDoctors.current ?? [],
        selectedDate,
        selectedOrderTypes,
        selectedTests
      )
    )
  }, [dispatch, selectedDate, selectedOrderTypes, selectedTests])

  useEffect(() => {
    dispatch(requestOrdersCountForTodayAgent(selectedDate))
  }, [selectedDate])

  useEffect(() => {
    if (appointmentSearchSlice.searchingAppointments) {
      setSelectedOrder(undefined)
    }
  }, [appointmentSearchSlice.searchingAppointments])

  const { t } = useTranslation()

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        borderRadius: 8,
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box
            display='flex'
            width='22%'
            maxWidth='22%'
            style={{ overflow: 'auto', height: '100%', minWidth: '290px' }}
          >
            <SimpleBar
              style={{
                height: '100%',
                width: '100%',
                overflowX: 'hidden',
                padding: '8px',
              }}
            >
              <Box px={1} width='100%'>
                <Box width='100%'>
                  <Calendar
                    size='xs'
                    style={{
                      aspectRatio: '1/1',
                    }}
                    value={selectedDate}
                    onChange={setSelectedDate}
                    dayStyle={(date, modifier) => {
                      if (isToday(date)) {
                        return {
                          aspectRatio: '1',
                          backgroundColor: kPrimaryLight,
                          borderRadius: '50%',
                        }
                      }
                      return { aspectRatio: '1' }
                    }}
                    styles={{
                      cell: {
                        aspectRatio: '1',
                      },
                      day: {
                        aspectRatio: '1 !important',
                      },
                      weekend: {
                        color: 'black',
                      },
                      weekday: {
                        color: kPrimaryMain,
                      },
                      selected: {
                        borderRadius: '50%',
                      },
                      label: {
                        fontWeight: 'bold',
                        color: kPrimaryMain,
                      },
                    }}
                  />
                </Box>
              </Box>
            </SimpleBar>
          </Box>

          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            maxWidth='88%'
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            <Box flexDirection='row' width='100%' display='flex' p={1}>
              {appointmentSearchSlice.searchingAppointments && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              )}

              <Box
                display='flex'
                flexDirection='row'
                justifyContent='flex-end'
                alignContent='flex-end'
                flexGrow='1'
              >
                <Box paddingX={2}>
                  <StyledToggleButtonGroup
                    value={viewType}
                    exclusive
                    size='small'
                    onChange={handleViewTypeChange}
                    aria-label='text alignment'
                  >
                    <ToggleButton value='list' aria-label='centered'>
                      <CalendarTodayIcon color='primary' />
                    </ToggleButton>
                    <ToggleButton value='tabular' aria-label='left aligned'>
                      <GridOnOutlined color='primary' />
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                </Box>
              </Box>
            </Box>
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='Start'
              alignContent='center'
              overflow='auto'
              style={{
                backgroundColor: '#00000005',
              }}
            >
              {appointmentSearchSlice.noResultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    align='center'
                  >
                    No Orders Available.
                  </Typography>
                </Box>
              )}

              {appointmentSearchSlice.errorWhileSearchingOrders && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography variant='subtitle1' color='error' align='center'>
                    Error while searching orders
                  </Typography>
                </Box>
              )}

              {appointmentSearchSlice.availableOrders && (
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height='100%'
                  overflow='auto'
                >
                  <Box
                    display='flex'
                    flexGrow={1}
                    overflow='auto'
                    height='100%'
                  >
                    {viewType === 'list' && (
                      <AgentListOrderView
                        preselectedOrderId={selectedOrder?.serviceRequest?.id}
                        labOrders={appointmentSearchSlice.availableOrders}
                        onLabOrderSelected={(order) => {
                          setSelectedOrder(order)
                          dispatch(
                            fetchOrderDetails(order.serviceRequest.id ?? '')
                          )
                        }}
                        dense={selectedOrder !== undefined}
                      />
                    )}

                    {viewType === 'tabular' && (
                      <AgentLabOrderTabularView
                        preselectedOrderId={selectedOrder?.serviceRequest?.id}
                        labOrders={appointmentSearchSlice.availableOrders}
                        onLabOrderSelected={(order) => {
                          setSelectedOrder(order)
                          dispatch(
                            fetchOrderDetails(order.serviceRequest.id ?? '')
                          )
                        }}
                      />
                    )}
                  </Box>
                </Box>
              )}
            </Box>
            <Box />
          </Box>
        </Box>
      </Paper>
    </div>
  )
}
