import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { UserTile } from 'views/components/LeftMenu/UserTile'

export interface ExistingUserConfirmationProps {
  open: boolean
  onContinueActionClicked?: () => void
  onCancelActionClicked?: () => void
  displayText?: string
  title?: string
  user?: PractitionerWithRole
  continueActionButtonText?: string
  cancelActionButtonText?: string
  id?: string
}

export const ExistingUserConfirmation: React.FC<ExistingUserConfirmationProps> =
  ({
    open,
    onContinueActionClicked,
    onCancelActionClicked,
    displayText = 'Continue Delete',
    title = 'User Already Exists',
    user,
    continueActionButtonText: doneButtonText = 'Select user',
    cancelActionButtonText: cancelButtonText = 'Discard',
    id,
  }) => {
    const { t } = useTranslation()

    useEffect(() => {})

    return (
      <Dialog
        open={open}
        onClose={() => {
          if (onCancelActionClicked) onCancelActionClicked()
        }}
        aria-labelledby='responsive-dialog-title'
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle id='scroll-dialog-title'>{t(`${title}`)}</DialogTitle>
        <DialogContent dividers={true}>
          <Alert
            severity='warning'
            style={{
              backgroundColor: '#FFFFFF',
              color: useTheme().palette.common.black,
            }}
          >
            <Typography variant='body1'>{displayText}</Typography>
          </Alert>
          {user && <UserTile practitionerWithRole={user} />}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (onCancelActionClicked) onCancelActionClicked()
            }}
            variant='outlined'
            disableElevation
            id={`${id}confirmDialog_button`}
          >
            <Typography variant='button' id={`${id}cancel_button`}>
              {t(`${cancelButtonText}`)}
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            onClick={() => {
              if (onContinueActionClicked) onContinueActionClicked()
            }}
            variant='contained'
            color='primary'
            id={`${id}continue_button`}
          >
            <Typography variant='button' id={`${id}done_button`}>
              {t(`${doneButtonText}`)}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
