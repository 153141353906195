import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { DocumentUploadStatus } from './documentUploadStatusTypes'

const initialState: DocumentUploadStatus = {
    uploading: false,
    uploadingSuccessful: false,
    error: false,
    errorMessage: '',
}

const uploadSlice = createSlice({
    name: 'uploadDocument',
    initialState,
    reducers: {
        uploadingDocument(state, action: PayloadAction<DocumentUploadStatus>) {
            state.uploading = action.payload.uploading
            state.uploadingSuccessful = action.payload.uploadingSuccessful
            state.error = action.payload.error
        },

        documentUploaded(state, action: PayloadAction<DocumentUploadStatus>) {
            state.uploading = action.payload.uploading
            state.uploadingSuccessful = action.payload.uploadingSuccessful
            state.error = action.payload.error
            state.document = action.payload.document
        },

        errorInUploadingDocument(
            state,
            action: PayloadAction<DocumentUploadStatus>
        ) {
            state.uploading = action.payload.uploading
            state.uploadingSuccessful = action.payload.uploadingSuccessful
            state.error = action.payload.error
            state.errorMessage = action.payload.errorMessage
        },

        resetUpload(state, action: PayloadAction<DocumentUploadStatus>) {
            state.uploading = action.payload.uploading
            state.uploadingSuccessful = action.payload.uploadingSuccessful
            state.error = action.payload.error
            state.errorMessage = action.payload.errorMessage
            state.document = action.payload.document
        },
    },
})

export const uploadingDocument =
    (file: string, data: any): AppThunk =>
        async (dispatch: AppDispatch) => {
            const addingDocumentUploadedState: DocumentUploadStatus = {
                uploading: true,
                uploadingSuccessful: false,
                error: false,
            }
            dispatch(uploadSlice.actions.uploadingDocument(addingDocumentUploadedState))
            const extension = file.split('.').pop()
            let type: string = ''
            if (extension === 'xlsm')
                type = 'application/vnd.ms-excel.sheet.macroEnabled.12'
            else if (extension === 'xlsx')
                type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            else type = 'application/vnd.ms-excel'
            try {
                const documentObj = {
                    resourceType: 'DocumentReference',
                    content: [
                        {
                            attachment: {
                                contentType: `${type}`,
                            },
                        },
                    ],
                }

                logger.info('documentObj')

                logger.info(documentObj)
                const fhirClient: FHIRApiClient = new FHIRApiClient()
                const response: any = await fhirClient.doCreateFHIRResourceRequest(
                    '/DocumentReference',
                    documentObj
                )
                const respDecoded: E.Either<Errors, R4.IDocumentReference> =
                    R4.RTTI_DocumentReference.decode(response)
                if (respDecoded._tag === 'Right') {
                    logger.info('Response Document decoded', respDecoded.right)
                    const documentResponse: R4.IDocumentReference = respDecoded.right
                    logger.info('Response Document ', documentResponse)
                    if (documentResponse.id) {
                        const invResp: boolean = await uploadContent(
                            data,
                            documentResponse,
                            type
                        )
                        //   if (invResp) {
                        //     dispatch(showSuccessAlert('Invitation sent to Patient'))
                        //   } else {
                        //     dispatch(
                        //       showWarningAlert(
                        //         'Error while sending Invitation sending to Patient'
                        //       )
                        //     )
                        //   }

                        const successUploadingDocument: DocumentUploadStatus = {
                            uploading: false,
                            uploadingSuccessful: true,
                            error: false,
                            errorMessage: '',
                        }
                        dispatch(
                            uploadSlice.actions.documentUploaded(successUploadingDocument)
                        )
                    }
                    const errorUploadingDocument: DocumentUploadStatus = {
                        uploading: false,
                        uploadingSuccessful: false,
                        error: true,
                        errorMessage: 'Error while creating patient',
                        //   patient: patientResponse,
                    }
                    dispatch(
                        uploadSlice.actions.errorInUploadingDocument(errorUploadingDocument)
                    )
                    return
                }
            } catch (error) {
                const errorDocuploadedState: DocumentUploadStatus = {
                    uploading: false,
                    uploadingSuccessful: false,
                    error: true,
                    errorMessage: 'Error',
                }
                dispatch(
                    uploadSlice.actions.errorInUploadingDocument(errorDocuploadedState)
                )
            }
        }

export const resetState = () => (dispatch: AppDispatch) => {
    dispatch(uploadSlice.actions.resetUpload(initialState))
}

async function uploadContent(
    data: any,
    documentRef: R4.IDocumentReference,
    type: string
): Promise<boolean> {
    logger.info('Document Reference')
    logger.info(documentRef)
    let result: boolean = false
    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const response: any | FHIRErrorResponses =
        await fhirClient.doCreateFHIRUploadResourceRequest(
            `/DocumentReference/${documentRef.id}/$binary-access-write?path=DocumentReference.content.attachment`,
            data,
            type
        )
    logger.info('Upload with reference')
    logger.info(response.data)
    const respDecoded: E.Either<Errors, R4.IDocumentReference> =
        R4.RTTI_DocumentReference.decode(response.data)
    if (respDecoded._tag === 'Right') {
        result = true
    }

    return result
}

export default uploadSlice.reducer
