import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { VideoCallStatus } from './videocallStatus'

const initialState: VideoCallStatus = {
  fetchingAppointmentDetails: false,
  appointmentFetched: false,
  errorWhileFetchingAppointment: false,
  roomId: '',
  token: '',
}

const videoCallHandlerSlice = createSlice({
  name: 'videoCallHandler',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<VideoCallStatus>) {
      state.errorReason = action.payload.errorReason
      state.fetchingAppointmentDetails =
        action.payload.fetchingAppointmentDetails
      state.appointmentDetails = action.payload.appointmentDetails
      state.appointmentFetched = action.payload.appointmentFetched
      state.roomId = action.payload.roomId
      state.token = action.payload.token
    },
  },
})

// const videoCallHandlerSlice = createSlice({
//   name: 'videoCallHandler',
//   initialState,
//   reducers: {
//     updatedStatus(state, action: PayloadAction<VideoCallStatus>) {
//       state.errorReason = action.payload.errorReason
//       state.fetchingAppointmentDetails =
//         action.payload.fetchingAppointmentDetails
//       state.appointmentDetails = action.payload.appointmentDetails
//       state.appointmentFetched = action.payload.appointmentFetched
//   },
// })

export const startVideoAppointment =
  (appointmentData: FhirAppointmentDetail): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: VideoCallStatus = {
      fetchingAppointmentDetails: true,
      appointmentFetched: false,
      errorWhileFetchingAppointment: false,
      roomId: '',
      token: '',
    }
    dispatch(videoCallHandlerSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const response: any = await fhirClient.doGetResource(
        `/Appointment/${appointmentData.appointment.id!}/$create-meeting-room`
      )
      if (response.parameter.length > 2) {
        state.roomId = response.parameter[1].valueString
        state.token = response.parameter[2].valueString
        state.appointmentFetched = true
        state.appointmentDetails = appointmentData
        state.fetchingAppointmentDetails = false
        dispatch(videoCallHandlerSlice.actions.updatedStatus(state))
      } else {
        state.roomId = ''
        state.token = ''
        state.appointmentFetched = true
        state.appointmentDetails = appointmentData
        state.fetchingAppointmentDetails = false
        dispatch(videoCallHandlerSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      logger.error('Catch error')
      logger.error(error)
      state.errorWhileFetchingAppointment = true
      state.fetchingAppointmentDetails = false
      state.errorReason = `Error while fetching details`
      dispatch(videoCallHandlerSlice.actions.updatedStatus(state))
    }
  }

export default videoCallHandlerSlice.reducer
