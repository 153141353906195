/* eslint-disable no-irregular-whitespace */
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-vis/dist/style.css'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { GeneralSensoryExaminationListPT } from './sensoryExaminations/generalSensoryExamination'
import { HearingExaminationsListPT } from './sensoryExaminations/hearingSensoryExamination'
import { ProprioceptionExaminationListPT } from './sensoryExaminations/proprioceptionExamination'
import { VisionExaminationsListPT } from './sensoryExaminations/visionSensoryExamination'

interface SensoryOrgansExaminationForPTProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  isReadOnly?: boolean
}

export const SensoryOrgansExaminationsListPT: React.FC<SensoryOrgansExaminationForPTProps> =
  ({
    fhirAppointmentDetails,
    isReadOnly = false,
  }: SensoryOrgansExaminationForPTProps) => {
    const { t } = useTranslation(['common'])
    const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())

    return (
      <Grid container direction='column' style={{ padding: 0, margin: 0 }}>
        <Grid container direction='row' style={{ padding: 0, margin: 0 }}>
          <Box
            sx={{ width: '100%' }}
            display='flex'
            alignItems='center'
            flexDirection='row'
            bgcolor='#999999'
            minHeight={40}
          >
            <Box p={1}>
              <Typography
                variant='body1'
                style={{ color: 'white', fontWeight: 600 }}
              >
                {' '}
                Sensory Organs
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ padding: '8px' }}>
          <Grid container direction='row' style={{ padding: 0, margin: 0 }}>
            <Grid item xs={5}>
              <VisionExaminationsListPT
                fhirAppointmentDetails={fhirAppointmentDetails}
                isReadOnly={isReadOnly}
              />
            </Grid>
            <Grid item xs={5}>
              <Divider orientation='vertical' flexItem />
              <HearingExaminationsListPT
                fhirAppointmentDetails={fhirAppointmentDetails}
                isReadOnly={isReadOnly}
              />
            </Grid>
            <Grid item xs={5}>
              <GeneralSensoryExaminationListPT
                fhirAppointmentDetails={fhirAppointmentDetails}
                isReadOnly={isReadOnly}
              />
            </Grid>
            <Divider orientation='vertical' flexItem />
            <Grid item xs={5}>
              <ProprioceptionExaminationListPT
                fhirAppointmentDetails={fhirAppointmentDetails}
                isReadOnly={isReadOnly}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
