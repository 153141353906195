import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Avatar,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  OutlinedInput,
  Paper,
  Typography,
} from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getNameFromHumanName,
  getProfilePicDoctor,
  getSpecializationsList,
} from 'utils/fhirResourcesHelper'
import { WelloFormItemLabel } from 'wello-web-components'
import appCancelReasons from '../../../assets/constants/cancel_reasons.json'
import { WelloSelectFHIRCoding } from '../LeftMenu/WelloSelectFHIRCoding'
import { PatientTile } from '../patients/pateintTile'

interface Props {
  selectedSlot: FhirSlotDetail
  selectedServiceType: string
  selectedPatient: R4.IPatient
  isReschedulingChargeApplicable: boolean
  preSelectedCancelReason?: R4.ICoding
  preSelectedInformCheck: boolean
  onInformCheckChanged: (val: boolean) => void
  onCancelReasonChanged: (val: R4.ICoding) => void
  onPatientNoteTextChanged: (val: string) => void
  patientNoteText: string
  onClickModifySlot: (
    selectedSlot: FhirSlotDetail,
    patient?: R4.IPatient
  ) => void
}

export const RescheduleConfirmation: React.FC<Props> = ({
  selectedPatient,
  selectedServiceType,
  selectedSlot,
  preSelectedCancelReason,
  preSelectedInformCheck,
  onInformCheckChanged,
  onCancelReasonChanged,
  isReschedulingChargeApplicable,
  onPatientNoteTextChanged,
  patientNoteText,
  onClickModifySlot,
}: Props) => {
  const { t } = useTranslation()
  const [displayWarning, setDisplayWarning] = React.useState<boolean>(true)
  const [isPatientEditMode, setIsPatientEditMode] = useState<boolean>(true)
  const [displayAdvancedFilterOption, setDisplayAdvancedFilterOption] =
    useState<boolean>(true)
  const [selectedPatientData, setSelectedPatientData] = useState<R4.IPatient>()
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Box width='100%'>
        <Grid container>
          <Grid item xs={12}>
            <Box marginX={2} paddingX={1}>
              <Grid container justify='center' direction='column'>
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='space-between'
                >
                  <Box paddingTop={1.5}>
                    <Typography variant='subtitle2'>
                      TIME SLOT &nbsp;{' '}
                      <img
                        src={`${process.env.PUBLIC_URL}/dot_icon.png`}
                        alt='icon'
                        height='10'
                      />{' '}
                    </Typography>
                  </Box>
                  <Button
                    id='but_lab_ord_cre_modify_pat'
                    variant='text'
                    color='primary'
                    onClick={(e) => {
                      setDisplayAdvancedFilterOption(false)
                      onClickModifySlot(selectedSlot, selectedPatient)
                    }}
                  >
                    Modify
                  </Button>
                </Box>
                <Grid item>
                  <Paper elevation={0}>
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                            alt={getNameFromHumanName(
                              selectedSlot.practitioner?.name ?? []
                            )}
                            src={getProfilePicDoctor(selectedSlot.practitioner)}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          color='primary'
                          primary={
                            <>
                              <Typography variant='h6' color='primary'>
                                {getNameFromHumanName(
                                  selectedSlot.practitioner?.name ?? []
                                )}
                              </Typography>
                            </>
                          }
                          secondary={
                            <>
                              <div style={{ width: 200 }}>
                                <Typography
                                  component='span'
                                  variant='subtitle1'
                                  color='textPrimary'
                                  style={{ wordWrap: 'break-word' }}
                                >
                                  {getSpecializationsList(
                                    selectedSlot.practitionerRole?.specialty ??
                                      []
                                  )}
                                </Typography>
                              </div>
                            </>
                          }
                        />
                        <ListItemSecondaryAction>
                          <ListItemText
                            primary={
                              <>
                                <Typography variant='h6' color='primary'>
                                  {moment(selectedSlot.slot.end).format(
                                    'Do MMM YYYY'
                                  )}
                                </Typography>
                              </>
                            }
                            secondary={
                              <>
                                <Typography variant='subtitle1'>
                                  {`   ${moment(selectedSlot.slot.start).format(
                                    'hh:mm '
                                  )}-${moment(selectedSlot.slot.end).format(
                                    'hh:mm a'
                                  )}`}
                                </Typography>
                                {selectedServiceType === '556' && (
                                  <Typography variant='subtitle2' align='right'>
                                    In-Person
                                  </Typography>
                                )}
                                {/* {selectedServiceType === '538' && (
                                  <Typography variant='subtitle2' align='right'>
                                    Phone
                                  </Typography>
                                )} */}
                                {selectedServiceType ===
                                  'video-counselling' && (
                                  <Typography variant='subtitle2' align='right'>
                                    Video
                                  </Typography>
                                )}
                              </>
                            }
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </Paper>
                </Grid>

                {selectedPatient && (
                  <Grid item>
                    <Box flexDirection='column'>
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                      >
                        <WelloFormItemLabel title='Selected Patient' />
                      </Box>
                      <Paper elevation={0}>
                        <Box paddingLeft={2}>
                          <PatientTile patient={selectedPatient} />
                        </Box>
                      </Paper>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>

            {/* <Grid item xs={12}>
              <Box marginX={2} paddingX={1}>
                <Grid xs={8}>
                  <Box
                    paddingBottom={1}
                    paddingRight={2}
                    flexShrink
                    width='87%'
                  >
                    <Controls.WelloSelect
                      label='PAYMENT TYPE'
                      availableOptions={availablePaymentTypes}
                      required={true}
                      type='Payment Type'
                      displayField='title'
                      valueField='id'
                      preSelectedOption={availablePaymentTypes[0]}
                      onSelectionChanged={(value: any) => {}}
                    />
                  </Box>
                </Grid>
              </Box>
            </Grid> */}

            <Grid item>
              <Box paddingLeft={3} width='97%'>
                <WelloSelectFHIRCoding
                  title='Reason'
                  id='rescheduleReason'
                  availableCodings={appCancelReasons}
                  preSelectedCoding={preSelectedCancelReason}
                  onChanged={(e) => {
                    if (e) {
                      onCancelReasonChanged(e as R4.ICoding)
                    }
                  }}
                  textFieldProps={{
                    size: 'small',
                  }}
                />
              </Box>
              <Box paddingY={1} paddingLeft={3} width='97%'>
                <WelloFormItemLabel title='Additional Notes' />
                <OutlinedInput
                  fullWidth
                  id='reschedule_notes'
                  placeholder='Add note for the patient (optional)'
                  style={{ backgroundColor: 'white' }}
                  value={patientNoteText}
                  onChange={(e) => {
                    onPatientNoteTextChanged(e.target.value)
                  }}
                />
              </Box>
            </Grid>

            {/* <Grid item xs={7}>
              <Box marginX={1} paddingX={1}>
                <Grid item>
                  
                </Grid>
              </Box>
            </Grid> */}
          </Grid>
        </Grid>
      </Box>
    </MuiPickersUtilsProvider>
  )
}
