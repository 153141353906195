import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SubModel } from 'models/subModel'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getSubstanceListWithChargeItem } from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { logger } from 'utils/logger'
import { SubstanceSearchStatus } from './substanceSearchStatus'

const initialState: SubstanceSearchStatus = {
  searchingTreatment: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingTreatment: false,
  substanceList: [],
}

const substanceSearchSlice = createSlice({
  name: 'substanceSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<SubstanceSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingTreatment = action.payload.searchingTreatment
      state.resultsAvailable = action.payload.resultsAvailable
      state.substanceList = action.payload.substanceList
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingTreatment =
        action.payload.errorWhileSearchingTreatment
    },
  },
})

export const getAllSubstance =
  (searchString?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: SubstanceSearchStatus = {
      searchingTreatment: true,
      errorWhileSearchingTreatment: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      substanceList: [],
    }
    dispatch(substanceSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameter = {
        _count: 1000,
        status: 'active',
      }
      const response: any = await fhirClient.doGetResource(
        `Substance?category=material&_count=200&_include=Substance:substance-service-billing`,
        searchParameter
      )

      if (response._tag === 'Left') {
        const errorSearchDoctor: SubstanceSearchStatus = {
          searchingTreatment: false,
          errorWhileSearchingTreatment: true,
          resultsAvailable: false,
          substanceList: [],
          errorReason: 'Error while searching substances',
        }
        dispatch(substanceSearchSlice.actions.updatedStatus(errorSearchDoctor))

        dispatch(substanceSearchSlice.actions.updatedStatus(state))
      } else {
        const resp: R4.IBundle = response as R4.IBundle

        const therapyDataList: R4.ISubstance[] =
          resp.entry?.map((item) => item.resource as R4.ISubstance) ?? []
        const subData: SubModel[] = getSubstanceListWithChargeItem(resp)
        // if (therapyDataList.length > 0) {
        //   for (let i = 0; i < therapyDataList.length; i++) {
        //     subData.push({
        //       id: therapyDataList[i].id ?? '',
        //       name: therapyDataList[i].description ?? '',
        //       price: 0,
        //     })
        //   }
        // }

        subData.sort((a, b) =>
          a.name! > b.name! ? 1 : b.name! > a.name! ? -1 : 0
        )
        if (sessionStorage.getItem('sub') === null) {
          sessionStorage.setItem('sub', JSON.stringify(subData))
        } else {
          sessionStorage.removeItem('sub')
          sessionStorage.setItem('sub', JSON.stringify(subData))
        }

        state.resultsAvailable = true
        state.searchingTreatment = false
        state.substanceList = subData
        state.noResultsAvailable = false
        state.errorReason = undefined
        state.errorWhileSearchingTreatment = false
        dispatch(substanceSearchSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorSearchDoctor: SubstanceSearchStatus = {
        searchingTreatment: false,
        errorWhileSearchingTreatment: true,
        resultsAvailable: false,
        errorReason: 'Error while searching Treatment Plan',
        substanceList: [],
      }
      dispatch(substanceSearchSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default substanceSearchSlice.reducer
