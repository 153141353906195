import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { endClinicVisit } from 'redux/appointments/appointmentViewHandler/appointmentHandlerSlice'
import { saveDischargeNotesForVisit } from 'redux/clinic/ipdmanagement/dischargeManagement/dischargeSearchSlice'
import { addFeedback } from 'redux/clinic/ipdmanagement/feedbackManagement/feedbackAddSlice'
import { RootState } from 'redux/rootReducer'
import { dietFeedbackAns, feedbackAns, feedbackQuestion } from 'utils/constants'
import { getVendorPartId } from 'utils/routes_helper'
import { WelloLoadingIndicator, WelloTextField } from 'wello-web-components'

interface NextStepsOfConsultationProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  disable: boolean
}

export const Feedback: React.FC<NextStepsOfConsultationProps> = ({
  fhirAppointmentDetails,
  disable,
}: NextStepsOfConsultationProps) => {
  const feedbackAddSlice = useSelector(
    (state: RootState) => state.feedbackAddSlice
  )
  const { t } = useTranslation(['common', 'mediction'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [questionResponse, setQuestionResponse] = React.useState<
    R4.IQuestionnaireResponse_Item[]
  >([])
  const [questionResponse1, setQuestionResponse1] = React.useState<
    R4.IQuestionnaireResponse_Item[]
  >([])
  const [questionResponse3, setQuestionResponse3] = React.useState<
    R4.IQuestionnaireResponse_Item[]
  >([])
  const [questionResponse4, setQuestionResponse4] = React.useState<
    R4.IQuestionnaireResponse_Item[]
  >([])
  const [questionResponse2, setQuestionResponse2] = React.useState<
    R4.IQuestionnaireResponse_Item[]
  >([])

  const [innerQuestionResponse1, setInnerQuestionResponse1] =
    React.useState<R4.IQuestionnaireResponse_Item>()

  const [innerQuestionResponse2, setInnerQuestionResponse2] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse3, setInnerQuestionResponse3] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse4, setInnerQuestionResponse4] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse5, setInnerQuestionResponse5] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse6, setInnerQuestionResponse6] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse7, setInnerQuestionResponse7] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse8, setInnerQuestionResponse8] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [text8, setText8] = React.useState<string>('')
  const [text6, setText6] = React.useState<string>('')
  const [innerQuestionResponse9, setInnerQuestionResponse9] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse10, setInnerQuestionResponse10] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse11, setInnerQuestionResponse11] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse12, setInnerQuestionResponse12] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse13, setInnerQuestionResponse13] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse14, setInnerQuestionResponse14] =
    React.useState<R4.IQuestionnaireResponse_Item>()

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (feedbackAddSlice.dischargeSuccessful) {
      window.location.href = `/${getVendorPartId()}/dashboard`
    }
  }, [feedbackAddSlice.dischargeSuccessful, history])

  return (
    <Box style={{ paddingLeft: '4px' }} display='flex' flexDirection='row'>
      <Box width='100%'>
        <Grid container spacing={2} direction='column'>
          <Grid item xl>
            <ReactVisibilitySensor
              offset={{
                top: 100,
                bottom: 30,
              }}
              onChange={(isVisible) => {
                if (isVisible) {
                  //   dispatch(
                  //     setSelectedSection({ selectedSection: 'visit_summary' })
                  //   )
                }
              }}
            >
              <Grid container spacing={1} direction='column' id='visit_summary'>
                <Grid item xs={3}>
                  <Box display='flex' py={2.5}>
                    <Box flexGrow={1}>
                      <Typography
                        variant='subtitle1'
                        style={{
                          fontSize: 18,
                          fontWeight: 700,
                        }}
                      >
                        {' '}
                        Feedback
                      </Typography>
                    </Box>
                    {feedbackAddSlice.discharging && (
                      <WelloLoadingIndicator message='Adding Details' />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  {feedbackQuestion.item.map((itemData, index) => (
                    <Box key={itemData.linkId}>
                      {itemData.linkId === '1.1' && itemData.type === 'choice' && (
                        <Box display='flex' flexDirection='row' width='100%'>
                          <WelloSelectFHIRCoding
                            title={itemData.text}
                            availableCodings={feedbackAns}
                            onChanged={(type) => {
                              const outerRes: R4.IQuestionnaireResponse_Item = {
                                linkId: itemData.linkId,
                                text: itemData.text,
                              }
                              const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                questionResponse.filter(
                                  (d) => d.linkId === '1.1'
                                )
                              if (outerFilter.length === 0) {
                                setQuestionResponse([outerRes])
                              }

                              const ans1: R4.IQuestionnaireResponse_Answer = {
                                valueCoding: type,
                              }

                              const innerRes: R4.IQuestionnaireResponse_Item = {
                                linkId: itemData.linkId,
                                text: itemData.text,
                                answer: [ans1],
                              }
                              setInnerQuestionResponse1(innerRes)
                            }}
                            textFieldProps={{
                              size: 'small',
                              fullWidth: true,
                            }}
                          />
                          {/* <WelloTextField
                            title='Feedback'
                            textProps={{
                              fullWidth: true,
                              id: `actor_email_unit`,

                              type: 'text',
                              multiline: true,
                              minRows: 4,
                              onChange: (changeEmail) => {
                                const ans1: R4.IQuestionnaireResponse_Answer = {
                                  valueString: changeEmail.target.value,
                                }
                                setInnerQuestionResponse1({
                                  linkId: '1.1',
                                  text: itemData.text,
                                  answer: [ans1],
                                })
                              },
                            }}
                          /> */}
                        </Box>
                      )}

                      {itemData.linkId === '1.2' && itemData.type === 'choice' && (
                        <Box display='flex' flexDirection='row' width='100%'>
                          <WelloSelectFHIRCoding
                            title={itemData.text}
                            availableCodings={dietFeedbackAns}
                            onChanged={(type) => {
                              const outerRes: R4.IQuestionnaireResponse_Item = {
                                linkId: itemData.linkId,
                                text: itemData.text,
                              }
                              const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                questionResponse.filter(
                                  (d) => d.linkId === '1.2'
                                )
                              if (outerFilter.length === 0) {
                                setQuestionResponse([outerRes])
                              }

                              const ans1: R4.IQuestionnaireResponse_Answer = {
                                valueCoding: type,
                              }

                              const innerRes: R4.IQuestionnaireResponse_Item = {
                                linkId: itemData.linkId,
                                text: itemData.text,
                                answer: [ans1],
                              }
                              setInnerQuestionResponse3(innerRes)
                            }}
                            textFieldProps={{
                              size: 'small',
                              fullWidth: true,
                            }}
                          />
                        </Box>
                      )}

                      {itemData.linkId === '1.3' && (
                        <Box display='flex' flexDirection='row' width='100%'>
                          <WelloSelectFHIRCoding
                            title={itemData.text}
                            availableCodings={feedbackAns}
                            onChanged={(type) => {
                              const outerRes: R4.IQuestionnaireResponse_Item = {
                                linkId: itemData.linkId,
                                text: itemData.text,
                              }
                              const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                questionResponse.filter(
                                  (d) => d.linkId === '1.3'
                                )
                              if (outerFilter.length === 0) {
                                setQuestionResponse([outerRes])
                              }

                              const ans1: R4.IQuestionnaireResponse_Answer = {
                                valueCoding: type,
                              }

                              const innerRes: R4.IQuestionnaireResponse_Item = {
                                linkId: itemData.linkId,
                                text: itemData.text,
                                answer: [ans1],
                              }
                              setInnerQuestionResponse4(innerRes)
                            }}
                            textFieldProps={{
                              size: 'small',
                              fullWidth: true,
                            }}
                          />
                        </Box>
                      )}

                      {itemData.linkId === '1.4' && (
                        <Box display='flex' flexDirection='row' width='100%'>
                          <WelloTextField
                            title={itemData.text}
                            textProps={{
                              fullWidth: true,
                              id: `actor_email_unit`,
                              type: 'text',
                              multiline: true,
                              placeholder: 'Max length 256 characters',
                              error: text6.length > 256,
                              helperText:
                                text6.length > 256
                                  ? `${itemData.text} should not exceed 256 characters`
                                  : '',
                              minRows: 4,

                              onChange: (changeEmail) => {
                                setText6(changeEmail.target.value)
                                const ans1: R4.IQuestionnaireResponse_Answer = {
                                  valueString: changeEmail.target.value,
                                }
                                setInnerQuestionResponse5({
                                  linkId: '1.4',
                                  text: itemData.text,
                                  answer: [ans1],
                                })
                              },
                            }}
                          />
                        </Box>
                      )}

                      {itemData.linkId === '1.5' && (
                        <Box display='flex' flexDirection='row' width='100%'>
                          <WelloSelectFHIRCoding
                            title={itemData.text}
                            availableCodings={feedbackAns}
                            onChanged={(type) => {
                              const outerRes: R4.IQuestionnaireResponse_Item = {
                                linkId: itemData.linkId,
                                text: itemData.text,
                              }
                              const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                questionResponse.filter(
                                  (d) => d.linkId === '1.5'
                                )
                              if (outerFilter.length === 0) {
                                setQuestionResponse([outerRes])
                              }

                              const ans1: R4.IQuestionnaireResponse_Answer = {
                                valueCoding: type,
                              }

                              const innerRes: R4.IQuestionnaireResponse_Item = {
                                linkId: itemData.linkId,
                                text: itemData.text,
                                answer: [ans1],
                              }
                              setInnerQuestionResponse6(innerRes)
                            }}
                            textFieldProps={{
                              size: 'small',
                              fullWidth: true,
                            }}
                          />
                        </Box>
                      )}

                      {itemData.linkId === '1.6' && (
                        <Box display='flex' flexDirection='row' width='100%'>
                          <WelloSelectFHIRCoding
                            title={itemData.text}
                            availableCodings={feedbackAns}
                            onChanged={(type) => {
                              const outerRes: R4.IQuestionnaireResponse_Item = {
                                linkId: itemData.linkId,
                                text: itemData.text,
                              }
                              const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                questionResponse.filter(
                                  (d) => d.linkId === '1.6'
                                )
                              if (outerFilter.length === 0) {
                                setQuestionResponse([outerRes])
                              }

                              const ans1: R4.IQuestionnaireResponse_Answer = {
                                valueCoding: type,
                              }

                              const innerRes: R4.IQuestionnaireResponse_Item = {
                                linkId: itemData.linkId,
                                text: itemData.text,
                                answer: [ans1],
                              }
                              setInnerQuestionResponse7(innerRes)
                            }}
                            textFieldProps={{
                              size: 'small',
                              fullWidth: true,
                            }}
                          />
                        </Box>
                      )}

                      {itemData.linkId === '1.7' && (
                        <Box display='flex' flexDirection='row' width='100%'>
                          <WelloTextField
                            title={itemData.text}
                            textProps={{
                              fullWidth: true,
                              id: `actor_email_unit`,

                              type: 'text',
                              multiline: true,
                              placeholder: 'Max length 256 characters',
                              error: text8.length > 256,
                              helperText:
                                text8.length > 256
                                  ? `${itemData.text} should not exceed 256 characters`
                                  : '',
                              minRows: 4,

                              onChange: (changeEmail) => {
                                setText8(changeEmail.target.value)
                                const ans1: R4.IQuestionnaireResponse_Answer = {
                                  valueString: changeEmail.target.value,
                                }
                                setInnerQuestionResponse8({
                                  linkId: '1.5',
                                  text: itemData.text,
                                  answer: [ans1],
                                })
                              },
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  ))}

                  <Box py={1} display='flex' flexDirection='row' flexGrow>
                    <Button
                      variant='contained'
                      style={{ color: ' #979797;' }}
                      disabled={disable}
                      onClick={() => {
                        window.location.href = `/${getVendorPartId()}/dashboard`
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={
                        fhirAppointmentDetails.mainServiceRequest.status !==
                          'completed' ||
                        fhirAppointmentDetails.feedback !== undefined ||
                        !innerQuestionResponse1 ||
                        !innerQuestionResponse3 ||
                        !innerQuestionResponse4 ||
                        !innerQuestionResponse5 ||
                        !innerQuestionResponse6 ||
                        !innerQuestionResponse7 ||
                        !innerQuestionResponse8 ||
                        text8.length === 0 ||
                        text6.length === 0 ||
                        text8.length > 256 ||
                        text6.length > 256
                      }
                      onClick={() => {
                        dispatch(
                          addFeedback(
                            fhirAppointmentDetails,
                            innerQuestionResponse1!,
                            innerQuestionResponse3!,
                            innerQuestionResponse4!,
                            innerQuestionResponse5!,
                            innerQuestionResponse6!,
                            innerQuestionResponse7!,
                            innerQuestionResponse8!
                          )
                        )
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </ReactVisibilitySensor>
          </Grid>

          {/* {carePlanSubscriptionSlice.noResultsAvailable && (
          <Grid item>
            <Typography> Medical condition history not available</Typography>
          </Grid>
        )}
        {carePlanSubscriptionSlice.resultsAvailable &&
          carePlanSubscriptionSlice.subscribed &&
          patientSubscriptionsSlice.resultsAvailable &&
          (patientSubscriptionsSlice.hCarePlans === undefined ||
            patientSubscriptionsSlice.hCarePlans?.length === 0) && (
            <Grid item>
              <Box p={1} bgcolor='#FDFFCD' width='20%'>
                <SubscribeToHypertensionCarePlan
                  fhirAppointmentDetails={fhirAppointmentDetails}
                />
              </Box>
            </Grid>
          )} */}
        </Grid>
      </Box>
    </Box>
  )
}
