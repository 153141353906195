import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { CcClinet } from 'services/Cclinet'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitDetails,
} from 'services/userDetailsService'
import {
  getAddress,
  getNameFromHumanName,
  getTelecomFromContactPoints,
} from 'utils/fhirResourcesHelper'
import { SearchAccountList } from '../accountSearchSlice'

import { AddAccountStatus } from './addingAccountStatus'

const initialState: AddAccountStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addNewBankAccountForUnit = createSlice({
  name: 'addNewBankAccountForUnit',
  initialState,
  reducers: {
    updateAddConditionStatus(state, action: PayloadAction<AddAccountStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetAllergiesDetails(state, action: PayloadAction<AddAccountStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
    },
  },
})

export const addBankAccountDetails =
  (
    bankName: string,
    accountType: R4.ICoding,
    accountNo: string,
    panNo: string,
    gstNo: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AddAccountStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(
      addNewBankAccountForUnit.actions.updateAddConditionStatus(addingState)
    )
    try {
      const unitOrg = getCurrentUserUnitDetails()
      const practRole: R4.IPractitionerRole =
        getCurrentUserPractitionerRoleDetails()
      const pract: R4.IPractitioner = getCurrentUserPractitionerDetails()
      const inviteData = {
        bankName,
        bankAccountType: accountType.code ?? '',
        bankAccountNumber: accountNo,
        bankAccountHolderName: getNameFromHumanName(pract.name ?? []).trim(),
        bankIfscCode: '1234',
        unitId: unitOrg.id ?? '',
        providerPanNumber: panNo,
        providerGstNumber: gstNo,
        legalBusinessNameRzp: unitOrg.name ?? '',
        businessTypeRzp: 'partnership',
        emailRzp:
          getTelecomFromContactPoints(
            getCurrentUserPractitionerDetails()!.telecom ?? [],
            R4.ContactPointSystemKind._email ?? ''
          ) ?? '',
        phoneRzp:
          getTelecomFromContactPoints(
            getCurrentUserPractitionerDetails()!.telecom ?? [],
            R4.ContactPointSystemKind._phone ?? ''
          ) ?? '',
        addressRzp: {
          street1: getAddress(pract.address ?? []),
          street2: getAddress(pract.address ?? []),
          city: 'Bangalore',
          state: 'Karnataka',
          postal_code: '560083',
          country: 'IN',
        },
      }

      const fhirClient: CcClinet = new CcClinet()
      const response = await fhirClient.doCreateEnrolmentFlowRequest(
        'invitation/account',
        inviteData
      )
      console.log(response)
      if (response) {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        dispatch(SearchAccountList())
        dispatch(showSuccessAlert('Account details added successfully'))

        dispatch(
          addNewBankAccountForUnit.actions.updateAddConditionStatus(addingState)
        )
      } else {
        const errorCreatePersonState: AddAccountStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while adding account details',
        }
        dispatch(
          showErrorAlert(
            'Error while adding account details. Please try again later'
          )
        )
        dispatch(
          addNewBankAccountForUnit.actions.updateAddConditionStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: AddAccountStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while sending invite',
      }
      dispatch(
        showErrorAlert(
          'Error while adding account details. Please try again later'
        )
      )

      dispatch(
        addNewBankAccountForUnit.actions.updateAddConditionStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetAccountAdding = () => (dispatch: AppDispatch) => {
  dispatch(addNewBankAccountForUnit.actions.resetAllergiesDetails(initialState))
}

export default addNewBankAccountForUnit.reducer
