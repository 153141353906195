import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MembershipDetails } from 'models/membershipDetails'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { getMembershipsWithChargeItem } from 'utils/membershipUtils'
import { MembershipSubscribersListStatus } from './membershipSubscribersListStatus'

const initialState: MembershipSubscribersListStatus = {
  searchingTreatment: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingTreatment: false,
}

const membershipSubscribersListSlice = createSlice({
  name: 'membershipSubscribersListSlice',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<MembershipSubscribersListStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingTreatment = action.payload.searchingTreatment
      state.resultsAvailable = action.payload.resultsAvailable

      state.therapyListWithPrice = action.payload.therapyListWithPrice
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingTreatment =
        action.payload.errorWhileSearchingTreatment
    },
  },
})

export const getAllMembershipSubscriptions =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: MembershipSubscribersListStatus = {
      searchingTreatment: true,
      errorWhileSearchingTreatment: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(membershipSubscribersListSlice.actions.updatedStatus(state))

    try {
      const searchParams = {
        _include: 'Coverage:policy-holder',
        'coverage-relation': 'self',
        type: 'http://terminology.hl7.org/CodeSystem/v3-ActCode|MCPOL',
        _sort: '-policy-holder',
        _count: 50000,
      }
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doGetResource(
        `Coverage`,
        searchParams
      )

      if (response._tag === 'Left') {
        const errorSearchDoctor: MembershipSubscribersListStatus = {
          searchingTreatment: false,
          errorWhileSearchingTreatment: true,
          resultsAvailable: false,
          errorReason: 'Error while fetching memberships',
        }
        dispatch(
          membershipSubscribersListSlice.actions.updatedStatus(
            errorSearchDoctor
          )
        )

        dispatch(membershipSubscribersListSlice.actions.updatedStatus(state))
      } else {
        const resp: R4.IBundle = response as R4.IBundle

        const therapyDataListWithPrice: MembershipDetails[] =
          getMembershipsWithChargeItem(resp)

        state.resultsAvailable = true
        state.searchingTreatment = false

        state.therapyListWithPrice = therapyDataListWithPrice.sort(
          (a, b) => moment(b.startDate).unix() - moment(a.startDate).unix()
        )
        state.noResultsAvailable = false
        state.errorReason = undefined
        state.errorWhileSearchingTreatment = false
        dispatch(membershipSubscribersListSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorSearchDoctor: MembershipSubscribersListStatus = {
        searchingTreatment: false,
        errorWhileSearchingTreatment: true,
        resultsAvailable: false,
        errorReason: 'Error while searching Therapies',
      }
      dispatch(
        membershipSubscribersListSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export default membershipSubscribersListSlice.reducer
