/* eslint-disable react/display-name */
import MaterialTable, { MTableToolbar } from '@material-table/core'
import {
  Box,
  InputAdornment,
  makeStyles,
  Paper,
  TablePagination,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { DayCareRatesData } from 'models/dayCareRatesData'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { EditDayCareRateData } from './editDaycareRoomRates'

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      padding: 4,
    },
  },
})

interface IChargesProperties {
  daycareChargesData: DayCareRatesData[]
  //   onUserSelected: (selectedDetail: dayCareRatesData) => void
}

export const DayCareRateDataTable: React.FC<IChargesProperties> = ({
  daycareChargesData,
}: //   onUserSelected,
IChargesProperties) => {
  const classes = useStyles()
  const convertedData = daycareChargesData
  const { height, width } = useWindowDimensions()
  const [selectedRowId, setSelectedRowId] = React.useState('')
  const [selectedRow, setSelectedRow] = React.useState(false)
  const [daycareRateDetail, setDaycareRateDetail] =
    React.useState<DayCareRatesData>()
  const { t } = useTranslation()
  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  const [nameFocus, setNameFocus] = useState(true)
  const [priceFocus, setPriceFocus] = useState(true)
  const [descriptionFocus, setDescriptionFocus] = useState(true)

  const [nameFilter, setNameFilter] = useState('')
  const [priceFilter, setPriceFilter] = useState('')
  const [descriptionFilter, setDescriptionFilter] = useState('')

  const [data, setData] = useState([...daycareChargesData])

  const filterName = (value: any, filed: string) => {
    setNameFocus(true)
    if (value) {
      const filteredData = daycareChargesData.filter((d) =>
        (d?.name ?? '')
          .replace(/\s+/g, '')
          .toLowerCase()
          .includes(value.replace(/\s+/g, '').toLowerCase())
      )
      setData(filteredData)
    } else {
      setData([...daycareChargesData])
    }
    setNameFilter(value)
    setDescriptionFocus(false)
    setPriceFocus(false)
  }

  const filterPrice = (value: any, filed: string) => {
    setPriceFocus(true)
    if (value) {
      const filteredData = daycareChargesData.filter((d) =>
        (d?.price ?? '')
          .toString()
          .toLocaleUpperCase()
          .includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...daycareChargesData])
    }
    setPriceFilter(value)
    setNameFocus(false)
    setDescriptionFocus(false)
  }

  const filterDescription = (value: any, filed: string) => {
    setDescriptionFocus(true)
    if (value) {
      const filteredData = daycareChargesData.filter((d) =>
        (d?.description ?? '')
          .toLocaleUpperCase()
          .includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...daycareChargesData])
    }
    setDescriptionFilter(value)
    setNameFocus(false)
    setPriceFocus(false)
  }

  const columns = [
    {
      title: (
        <Box minWidth='150px' minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {t('labelCommon:Daycare Type')}
          </Typography>
        </Box>
      ),
      field: 'type_name',
      width: '20%',
      //   cellStyle: {
      //     width: 250,
      //     maxWidth: 250,
      //   },
      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (rowData: any) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <Typography variant='subtitle2' noWrap>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {rowData.name}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box maxWidth={150}>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={nameFocus}
            placeholder='Daycare Type'
            value={nameFilter}
            onChange={(e) => filterName(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography variant='subtitle1' color='primary' noWrap>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('labelCommon:Price')}
            &nbsp;(&#x20b9;)
          </Typography>
        </Box>
      ),
      field: 'price',
      width: '20%',
      //   cellStyle: {
      //     width: 150,
      //     maxWidth: 175,
      //   },
      render: (rowData: any) => (
        <Box>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {rowData.price}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box maxWidth={130}>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={priceFocus}
            placeholder={t('labelCommon: Price')}
            value={priceFilter}
            onChange={(e) => filterPrice(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
            noWrap
          >
            {t('labelCommon:description')}
          </Typography>
        </Box>
      ),
      field: 'description',
      width: '50%',
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            {rowData.description.slice(0, 50)}
            {rowData.description.length > 50 ? '...' : null}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box maxWidth={150}>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={descriptionFocus}
            placeholder={t('labelCommon:Description')}
            value={descriptionFilter}
            onChange={(e) =>
              filterDescription(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
  ]

  return (
    <Box
      style={{ overflow: 'none', width: `${width - 224}px` }}
      paddingLeft={1.5}
    >
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                minHeight: '0px',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
          Container: (props) => <Paper {...props} elevation={0} />,
          Pagination: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                display: 'flex',
                justifyContent: 'left',
                // padding: '0px',
              }}
            >
              <TablePagination
                {...props}
                labelRowsSelect={<div style={{ fontSize: 14 }}> rows</div>}
                SelectProps={{
                  style: {
                    fontSize: 14,
                    minHeight: '5px',
                    backgroundColor: useTheme().palette.background.default,
                  },
                }}
              />
            </div>
          ),
        }}
        columns={columns}
        data={data}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        onRowClick={(event, rowData) => {
          setDaycareRateDetail(rowData)
          setShowPopup(true)
        }}
        options={{
          //   searchFieldStyle: { padding: 4 },
          toolbar: false,
          search: false,
          showTitle: false,
          padding: 'dense',
          searchFieldVariant: 'outlined',
          filtering: true,
          pageSize: 10,
          paging: false,
          pageSizeOptions: [10, 20, 30],
          //   columnsButton: true,
          maxBodyHeight: `${height - 169}px`,
          minBodyHeight: `${height - 200}px`,
          tableLayout: 'auto',
          thirdSortClick: true,
          sorting: true,
          headerStyle: {
            backgroundColor: useTheme().palette.background.default,
            color: '#FFF',
            width: 100,
            minHeight: '15px',
            maxHeight: '15px',
            position: 'sticky',
            top: 0,
            // height: 20,
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          rowStyle: (_data: any, index: number, _level: number) =>
            index % 2
              ? selectedRow && _data.id === selectedRowId
                ? { backgroundColor: '#9E9DDC', height: 50 }
                : { backgroundColor: kDialogueBackground }
              : selectedRow && _data.id === selectedRowId
              ? { backgroundColor: '#9E9DDC', height: 50 }
              : {},
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />

      {showPopup && (
        <Box
          display='flex'
          flexGrow={4}
          overflow='auto'
          minWidth={`${width - 460}px`}
          height='100%'
          style={{ backgroundColor: '#ececec' }}
        >
          <EditDayCareRateData
            rowDetails={daycareRateDetail as DayCareRatesData}
            open={showPopup}
            onCancelClick={() => {
              setShowPopup(false)
            }}
          />
        </Box>
      )}
    </Box>
  )
}
