import { Box, Card, CardContent, Typography } from '@material-ui/core'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import {
  ReportColumns,
  ReportColumnsForAppointment,
} from 'models/Report/reportColumn'
import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { getDisplayOfSystemFromCodableConceptForAppointment } from 'utils/fhirResourcesHelper'
import { getAppointmentTime } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'

interface Props {
  usersData: ReportColumnsForAppointment
  type: string
  onAppointmentSelected?: (appointmentDetails: FhirAppointmentDetail) => void
  isOdd?: boolean
  onViewClicked?: () => void
  dense?: boolean
  isSelected?: boolean
}

const options = ['Start IPD']

const ITEM_HEIGHT = 48

export const OrgAdminAppointmentList: React.FC<Props> = ({
  usersData,
  type,
  onAppointmentSelected,
  isOdd = false,
  onViewClicked,
  dense = false,
  isSelected = false,
}: Props) => {
  const { t } = useTranslation()
  const [showBilling, setBillingHandler] = React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const dispatch = useDispatch()
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()

  return (
    <Box
      width='100%'
      onClick={(e) => {
        if (onAppointmentSelected)
          onAppointmentSelected(usersData.availableAppointments)
      }}
    >
      <Card
        elevation={isOdd ? 0 : 1}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 0,
          backgroundColor: isSelected
            ? kPrimaryLight
            : isOdd
            ? '#FFFFFFAF'
            : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 0,
          }}
        >
          {dense && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              onClick={(e) => {
                // if (onAppointmentSelected)
                //   onAppointmentSelected(usersData.availableAppointments)
              }}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                flexGrow={1}
              >
                <Typography
                  variant='subtitle1'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  {usersData.patient_name}
                </Typography>
                <Typography
                  variant='subtitle2'
                  color='initial'
                  style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
                >
                  {usersData.type}
                </Typography>

                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                >
                  <Typography variant='body2' color='initial'>
                    {getAppointmentTime(usersData.availableAppointments)}
                  </Typography>
                  <Typography variant='subtitle1' color='initial'>
                    {getDisplayOfSystemFromCodableConceptForAppointment(
                      usersData.availableAppointments.appointment
                        .serviceType?.[0]
                    ) ?? '  In-Person'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          {!dense && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              alignContent='center'
              p={0.5}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='10%'
              >
                <Typography variant='body2' color='initial'>
                  {moment(usersData.start).format('DD-MM-YYYY')}
                </Typography>
                <Typography variant='body2' color='initial'>
                  {getAppointmentTime(usersData.availableAppointments)}
                </Typography>
              </Box>
              <Box
                display='flex'
                flexDirection='row'
                width='15%'
                justifyContent='flex-start'
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  alignContent='flex-start'
                  width='100%'
                  px={2}
                >
                  <Typography variant='subtitle2' color='initial'>
                    {getDisplayOfSystemFromCodableConceptForAppointment(
                      usersData.availableAppointments.appointment
                        .serviceType?.[0]
                    ) ?? '  In-Person'}
                  </Typography>
                </Box>
              </Box>

              {/* <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignContent='flex-start'
                width='20%'
                px={2}
              >
                <Box display='flex' flexDirection='column' width='50%'>
                  <Typography variant='body2' color='initial'>
                    {usersData.gender}
                  </Typography>
                </Box>
              </Box> */}

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='start'
                width='15%'
              >
                <Typography
                  variant='subtitle1'
                  style={{
                    textTransform: 'capitalize',
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  {usersData.patient_name}
                </Typography>

                <Typography
                  variant='subtitle2'
                  color='initial'
                  style={{
                    textTransform: 'capitalize',
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    // color: 'black',

                    fontWeight: 600,
                    lineHeight: 'normal',
                  }}
                >
                  {usersData.phoneNo.trim()}
                </Typography>
                <Typography
                  variant='subtitle2'
                  color='initial'
                  style={{
                    textTransform: 'capitalize',
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    // color: 'black',

                    fontWeight: 600,
                    lineHeight: 'normal',
                  }}
                >
                  {usersData.gender === 'Female' ? 'F' : 'M'} {'  '}{' '}
                  {usersData.age}
                </Typography>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignContent='flex-start'
                width='30%'
                px={2}
              >
                <Typography
                  variant='subtitle2'
                  color='initial'
                  style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
                >
                  {usersData.address}
                </Typography>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignContent='flex-start'
                width='20%'
                px={2}
              >
                <Box display='flex' flexDirection='column' width='50%'>
                  <Typography variant='subtitle2' color='initial'>
                    &#x20b9; {usersData.AmountPaid}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>

      <NoteDialog
        id='notesTile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
      />
    </Box>
  )
}
