/* eslint-disable @typescript-eslint/no-shadow */
import { Box, ListItem, TextField, TextFieldProps } from '@material-ui/core'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { GoogleApiWrapper } from 'google-maps-react'
import React, { useState } from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import { WelloFormItemLabel } from 'wello-web-components'

export interface IProps {
  title: string
  nationality: string
  textProps: TextFieldProps
  displayDoneLabel?: boolean
  showingInfoWindow?: boolean
  valueData: string
  onUpdated: (
    address: string,
    mapLatlog: {
      lat: number
      lng: number
    }
  ) => void
  mapkey: string
  activeMarker?: any
  selectedPlace?: any
  mapCenter?: {
    lat: 49.2827291
    lng: -123.1207375
  }
}

export const WelloPlaceFinder: React.FC<IProps> = (props: IProps) => {
  const [testError, setTestEroor] = React.useState<string>(props.valueData)
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const handleChange = (value2: any) => {
    setTestEroor(value2)
  }

  const searchOptions = {
    types: ['(cities)'],
    componentRestrictions: {
      country: props.nationality.length === 0 ? '' : props.nationality,
    },
  }
  //   useEffect(() => {
  //     const googleMapScript = loadMapApi()
  //     googleMapScript.addEventListener('load', () => {
  //       setScriptLoaded(true)
  //     })
  //   }, [])

  const handleSelect = (value1: any) => {
    if (value1.includes(',')) {
      setTestEroor(value1.split(',')[0])
    }

    geocodeByAddress(value1)
      .then((results: any[]) => getLatLng(results[0]))
      .then((latLng: any) => {
        props.onUpdated(value1, latLng)
      })
      .catch((error: any) => console.error('Error', error))
  }
  return (
    <Box display='flex' flexDirection='column' flexGrow={1}>
      <PlacesAutocomplete
        value={testError}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <WelloFormItemLabel
              title={props.title}
              displayDoneLabel={props.displayDoneLabel}
            />
            <Box>
              <TextField
                variant='outlined'
                fullWidth
                {...props.textProps}
                size='small'
                {...getInputProps({
                  placeholder: 'Search City ...',
                  className: 'location-search-input',
                })}
              />
            </Box>
            <div
              className='autocomplete-dropdown-container'
              style={{ maxHeight: 70, overflowY: 'auto' }}
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion: any) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item'
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' }
                return (
                  // eslint-disable-next-line react/jsx-key
                  <ListItem id={`lab_test_opt_${suggestion.id}`}>
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span
                        key={suggestion.description}
                        style={{
                          backgroundColor: suggestion.description
                            ? kPrimaryLight
                            : undefined,
                          fontWeight: suggestion.description ? 700 : 400,
                        }}
                      >
                        {suggestion.description}
                      </span>
                    </div>
                  </ListItem>
                )
              })}
            </div>
          </>
        )}
      </PlacesAutocomplete>
    </Box>
  )
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDxdzgwfyF10RLAYklADHkNa49yLcPqJ9k',
})
