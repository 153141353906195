import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  TaskIntentKind,
  TaskStatusKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { LabOfferingDetail } from 'models/labOfferDetail'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import {
  LabOrderHomeCollectionTaskCodes,
  LabOrderTaskCodes,
} from 'utils/constants/lab_order_task_codes'
import { getNameOfPatient } from 'utils/fhirResourcesHelper'
import { getOrderUpdateSuccessfulMessage } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  getProvenanceObjectForOrder,
  getProvenanceObjectForTask,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import { getExpandedServiceRequestFromBundlePartner } from 'utils/fhirResoureHelpers/partnerLabOrderHelper'
import { logger } from 'utils/logger'
import { searchInvitations } from '../Search/partnerLabSearchSlice'
import { PartnerLabOrderSearchStatus } from './partnerLabOrderSearchStatus'

const initialState: PartnerLabOrderSearchStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingOrders: false,
  updatingOrderDetail: false,
  orderDetailsUpdated: false,
}

let currentSelectedDate: Date | undefined

const partnerLabOrderSearchSlice = createSlice({
  name: 'partnerLabOrderSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<PartnerLabOrderSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableOrders = action.payload.availableOrders
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingOrders = action.payload.errorWhileSearchingOrders
    },
  },
})

export const searchingPartnerLabOrders =
  (locationId: string, searchText?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PartnerLabOrderSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      orderDetailsUpdated: false,
      updatingOrderDetail: false,
    }
    dispatch(partnerLabOrderSearchSlice.actions.updatedStatus(state))
    try {
      const unitDetails = getCurrentUserUnitReference()
      currentSelectedDate?.setSeconds(new Date().getSeconds())
      const orderStatus = ['active', 'revoked']
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _count: 200,
        _include: ['ServiceRequest:patient'],
        status: orderStatus.join(','),
        _revinclude: 'Task:focus',
        '_revinclude:iterate': 'Provenance:target',
        '_include:iterate': [
          'ServiceRequest:performer',
          'ServiceRequest:instantiates-canonical',
          'PlanDefinition:service-billing',
          'Task:owner',
          'PractitionerRole:practitioner',
        ],
      }
      if (searchText) searchParameters._id = searchText
      if (unitDetails) {
        searchParameters.performer = unitDetails.reference
      }

      const response: any =
        await fhirClient.doGetResourceIncludeAndIncludeIterate(
          '/ServiceRequest',
          searchParameters
        )
      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingOrders = true
        state.searchingAppointments = false

        dispatch(partnerLabOrderSearchSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp.right
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          const fhirAppointments: FhirLabOrderDetail[] =
            getExpandedServiceRequestFromBundlePartner(
              appointmentResponse,
              locationId
            )
          fhirAppointments.sort((a, b) => moment(a.start).diff(b.start))
          logger.info(fhirAppointments)
          logger.info('Orders length')

          state.resultsAvailable = true
          state.searchingAppointments = false
          state.availableOrders = fhirAppointments
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingOrders = false
          searchInvitations([])
          dispatch(partnerLabOrderSearchSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: PartnerLabOrderSearchStatus = {
            searchingAppointments: false,
            errorWhileSearchingOrders: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            updatingOrderDetail: false,
            orderDetailsUpdated: false,
          }
          dispatch(
            partnerLabOrderSearchSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: PartnerLabOrderSearchStatus = {
        searchingAppointments: false,
        errorWhileSearchingOrders: true,
        resultsAvailable: false,
        updatingOrderDetail: false,
        orderDetailsUpdated: false,
        errorReason: 'Error while fetching orders',
      }
      dispatch(
        partnerLabOrderSearchSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export const resetLabUpDateState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: PartnerLabOrderSearchStatus = { ...initialState }
    dispatch(partnerLabOrderSearchSlice.actions.updatedStatus(state))
  }

export const requestForUpdateLabOrder =
  (
    LabOrderDetail: FhirLabOrderDetail,
    locationId: string,
    selectedLabOfferings: LabOfferingDetail[],
    selectedPatient: R4.IPatient,
    selectedServiceType: string,
    commission: number,
    selectedAddress?: R4.IAddress,
    selectedSlot?: FhirSlotDetail
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PartnerLabOrderSearchStatus = { ...initialState }
    state.updatingOrderDetail = true
    dispatch(partnerLabOrderSearchSlice.actions.updatedStatus(state))

    try {
      const bundleObject: R4.IBundle | undefined = getUpdateTransactionObject(
        LabOrderDetail,
        selectedLabOfferings,
        selectedPatient,
        selectedServiceType,
        commission,
        selectedAddress,
        selectedSlot
      )
      const fhirApi: FHIRApiClient = new FHIRApiClient()
      const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        state.orderDetailsUpdated = true
        state.updatingOrderDetail = false

        dispatch(
          showSuccessAlert(getOrderUpdateSuccessfulMessage(selectedPatient))
        )
        dispatch(searchingPartnerLabOrders(locationId))
        dispatch(partnerLabOrderSearchSlice.actions.updatedStatus(state))
      } else {
        state.orderDetailsUpdated = false
        state.updatingOrderDetail = false
        state.errorWhileSearchingOrders = false
        dispatch(partnerLabOrderSearchSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      logger.info(error)
      //   const state: PartnerLabOrderSearchStatus = { ...initialState }
      state.errorWhileSearchingOrders = true
      state.errorReason = 'Error'
      dispatch(partnerLabOrderSearchSlice.actions.updatedStatus(state))
    }
  }

function getOrderCreationTransactionObject(
  LabOrderDetail: FhirLabOrderDetail,
  selectedLabOfferings: LabOfferingDetail[],
  selectedPatient: R4.IPatient,
  selectedServiceType: string,
  selectedAddress?: R4.IAddress,
  selectedSlot?: FhirSlotDetail
): R4.IBundle {
  const requestId: string = getUniqueTempId()
  const mainTaskId: string = getUniqueTempId()

  const currentServiceRequest: R4.IServiceRequest = {
    resourceType: 'ServiceRequest',
    subject: {
      id: selectedPatient.id,
      reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
      display: getNameOfPatient(selectedPatient),
    },
    instantiatesCanonical: selectedLabOfferings.map(
      (e) => `${e.planDefinition.resourceType}/${e.planDefinition.id}` ?? ''
    ),
    requester: {
      id: selectedPatient.id,
      reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
      display: getNameOfPatient(selectedPatient),
    },
    status: 'active',
    intent: 'order',
    category: [
      {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '108252007',
            display: 'Laboratory procedure',
          },
        ],
      },
    ],

    priority: 'urgent',
    occurrenceDateTime: new Date().toISOString(),
    authoredOn: new Date().toISOString(),
    code: {
      coding: [
        {
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type',
          code: 'onsite-sample-collection',
          display: 'Onsite Collection',
        },
      ],
    },
    id: requestId,
  }
  if (selectedServiceType === 'home_collection') {
    if (selectedSlot?.slot?.start) {
      currentServiceRequest.occurrencePeriod = {
        start: selectedSlot?.slot?.start,
        end: selectedSlot?.slot?.end,
      }
    }
    currentServiceRequest.code = {
      coding: [
        {
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type',
          code: 'home-sample-collection',
          display: 'Home Collection',
        },
      ],
    }
  }

  const currentPractRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()

  const mainTask: R4.ITask = {
    resourceType: 'Task',
    id: mainTaskId,
    status: TaskStatusKind._accepted,
    intent: TaskIntentKind._order,

    code: {
      coding: LabOrderTaskCodes,
    },
    focus: {
      id: requestId,
      reference: `${currentServiceRequest.resourceType}/${currentServiceRequest.id}`,
    },
    for: {
      id: selectedPatient.id,
      reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
      display: getNameOfPatient(selectedPatient),
    },
    owner: {
      id: currentPractRole.id,
      reference: `${currentPractRole.resourceType}/${currentPractRole.id}`,
    },
    requester: {
      id: selectedPatient.id,
      reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
      display: getNameOfPatient(selectedPatient),
    },
  }

  const statusProvenance: R4.IProvenance = getProvenanceObjectForOrder(
    {
      serviceRequest: currentServiceRequest,
      task: mainTask,
      patient: selectedPatient,
      performerDetail: {
        practitioner: {
          resourceType: 'Practitioner',
        },
        practitionerRole: {
          resourceType: 'PractitionerRole',
        },
      },
      start: new Date().toISOString(),
      oldSlotRef: '',
    },
    {
      system: 'http://hl7.org/fhir/task-status',
      code: 'scheduled',
      display: 'Scheduled',
    }
  )

  mainTask.relevantHistory = [
    ...(mainTask.relevantHistory ?? []),
    {
      reference: `${statusProvenance.resourceType}/${
        statusProvenance.id ?? ''
      }`,
    },
  ]

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `${statusProvenance.resourceType}/`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: statusProvenance.resourceType,
        },
        resource: statusProvenance,
      },
      {
        fullUrl: currentServiceRequest.resourceType,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: currentServiceRequest.resourceType,
        },
        resource: currentServiceRequest,
      },
      {
        fullUrl: mainTask.resourceType,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: mainTask.resourceType,
        },
        resource: mainTask,
      },
    ],
  }
  if (selectedServiceType === 'home_collection' && selectedSlot) {
    const modifiedSlot: R4.ISlot = { ...selectedSlot?.slot }
    modifiedSlot.status = R4.SlotStatusKind._busy
    const matchString: string = `W/${JSON.stringify(
      modifiedSlot.meta?.versionId ?? ' '
    )}`

    requestBundle.entry?.push({
      fullUrl: `Slot/${selectedSlot?.slot.id}`,
      request: {
        ifMatch: matchString,
        method: R4.Bundle_RequestMethodKind._put,
        url: `Slot/${selectedSlot?.slot.id}`,
      },
      resource: modifiedSlot,
    })

    const subTask: R4.ITask = {
      resourceType: 'Task',
      id: getUniqueTempId(),
      status: TaskStatusKind._accepted,
      intent: TaskIntentKind._order,
      executionPeriod: {
        start: selectedSlot.slot.start,
        end: selectedSlot.slot.end,
      },
      focus: {
        id: requestId,
        reference: `${currentServiceRequest.resourceType}/${currentServiceRequest.id}`,
      },
      for: {
        id: selectedPatient.id,
        reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
        display: getNameOfPatient(selectedPatient),
      },
      owner: {
        id: selectedSlot.practitionerRole?.id ?? '',
        reference: `${selectedSlot.practitionerRole?.resourceType ?? ''}/${
          selectedSlot.practitionerRole?.id ?? ''
        }`,
      },
      requester: {
        id: selectedPatient.id,
        reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
        display: getNameOfPatient(selectedPatient),
      },
      code: {
        coding: LabOrderHomeCollectionTaskCodes,
      },
      partOf: [
        {
          id: mainTaskId,
          reference: `${mainTask.resourceType}/${mainTaskId}`,
        },
      ],
      input: [
        {
          type: {
            coding: [
              {
                system:
                  'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
                code: 'slot-reference',
              },
            ],
          },
          valueReference: {
            reference: `${selectedSlot.slot.resourceType}/${selectedSlot.slot.id}`,
            id: selectedSlot.slot.id,
            type: selectedSlot.slot.resourceType,
          },
        },
        {
          type: {
            coding: [
              {
                system:
                  'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
                code: 'collection-address',
              },
            ],
          },
          valueAddress: selectedAddress,
        },
      ],
    }

    const newSubTaskStatusProvenance: R4.IProvenance =
      getProvenanceObjectForTask(subTask, {
        system: 'http://hl7.org/fhir/task-status',
        code: 'created',
        display: 'Created',
      })

    subTask.relevantHistory = [
      ...(subTask.relevantHistory ?? []),
      {
        reference: `${newSubTaskStatusProvenance.resourceType}/${
          newSubTaskStatusProvenance.id ?? ''
        }`,
      },
    ]

    requestBundle.entry?.push({
      fullUrl: subTask.resourceType,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: subTask.resourceType,
      },
      resource: subTask,
    })

    requestBundle.entry?.push({
      fullUrl: `${newSubTaskStatusProvenance.resourceType}/`,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: newSubTaskStatusProvenance.resourceType,
      },
      resource: newSubTaskStatusProvenance,
    })
  }

  return requestBundle
}

function getUpdateTransactionObject(
  orderDetail: FhirLabOrderDetail,
  selectedLabOfferings: LabOfferingDetail[],
  selectedPatient: R4.IPatient,
  selectedServiceType: string,
  commission: number,
  selectedAddress?: R4.IAddress,
  newSlot?: FhirSlotDetail
): R4.IBundle | undefined {
  if (orderDetail.partnerLabTask) {
    const oldUpdatedServiceRequest: R4.IServiceRequest = {
      ...orderDetail.serviceRequest,
    }
    const partnerTask: R4.ITask = { ...orderDetail.partnerLabTask }

    const partnerTaskMatchString: string = `W/${JSON.stringify(
      partnerTask.meta?.versionId ?? ' '
    )}`

    partnerTask.input = [
      {
        type: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
              code: 'partner-commission',
            },
          ],
        },
        valueInteger: commission,
      },
    ]
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `Task/${partnerTask?.id}`,
          request: {
            ifMatch: partnerTaskMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `Task/${partnerTask?.id}`,
          },
          resource: partnerTask,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

export default partnerLabOrderSearchSlice.reducer
