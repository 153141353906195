import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core'
import _ from 'lodash'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { WelloMedication } from 'models/welloMedication'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addMedicationStateMentDetails,
  resetAddMedicationStatementState,
} from 'redux/fhirMedicalResources/addMedicationStatement/addMedicationStatementSearchSlice'
import { RootState } from 'redux/rootReducer'
import { AddedMedicationsList } from '../assessment/medicationsListTable'
import { AllopathyMedicationCaptureForm } from './allopathyMedicationCaptureComponent'
import { AyurvedaMedicationCaptureForm } from './ayurvedaMedicationCaptureComponent'

// import { MedicationTile } from './medication_tile'

interface Props {
  open: boolean
  patient: R4.IPatient
  encounter: R4.IEncounter
  onClose: () => void
  onMedicalConditionAdded: () => void
}
export const AddMedicationStatementHandlerNew: React.FC<Props> = ({
  open,
  onClose,
  onMedicalConditionAdded,
  patient,
  encounter,
}) => {
  const { t } = useTranslation(['common', 'medication', 'dosage'])
  const [addedMedications, setAddedMedications] = useState<WelloMedication[]>(
    []
  )
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '68%',
    },
  }))
  const classes = useStyles()

  const [currentMedication, setCurrentMedication] = useState<WelloMedication>({
    type: 'allopathy',
    id: _.random(1, 10000000).toString(),
  })

  const [otherData, setOtherData] = useState<string>()

  const [addAnother, setAddAnother] = React.useState<boolean>(true)

  const [brandName, setBrandName] = useState<string>('')

  const dispatch = useDispatch()

  const addMedicationStatementSearchSlice = useSelector(
    (state: RootState) => state.addMedicationStatementSearchSlice
  )

  useEffect(() => {
    if (addMedicationStatementSearchSlice.additionSuccessful) {
      resetForm()
      setAddedMedications([])

      onMedicalConditionAdded()
      dispatch(resetAddMedicationStatementState())
    }
  }, [dispatch, addMedicationStatementSearchSlice, onMedicalConditionAdded])

  function resetForm() {
    setCurrentMedication({
      type: currentMedication.type,
      id: _.random(1, 10000000).toString(),
    })
  }

  function addCurrentDetails(pushToSave?: boolean) {
    const tempMedication: WelloMedication[] = [...addedMedications]
    const newMedication: WelloMedication = {
      ...currentMedication,
    }

    tempMedication.push(newMedication)
    Promise.resolve().then(() => {
      setAddedMedications(tempMedication)
      resetForm()
    })
    if (pushToSave) {
      dispatch(
        addMedicationStateMentDetails(
          patient,
          encounter,
          tempMedication,
          brandName
        )
      )
    }
  }
  function isValidMedicationDetails() {
    if (
      currentMedication.medication !== undefined ||
      currentMedication.medicationCode !== undefined
    ) {
      console.log('---------valid medication----------')
      if (
        currentMedication.dosageQuantity !== undefined &&
        currentMedication.dosageQuantityType !== undefined &&
        currentMedication.timeOfDayCode !== undefined &&
        currentMedication.timeOfDayCode.length > 0 &&
        currentMedication.dosageQuantity > 0
      ) {
        console.log('---------valid----------')
        return true
      }
      if (currentMedication.dosageQuantityType !== undefined) {
        if (currentMedication.dosageQuantityType === '422135004') {
          return true
        }
      }

      if (currentMedication.administrationCode !== undefined) {
        if (currentMedication.administrationCode === '6064005') {
          return true
        }
      }
    }
    return false
  }
  return (
    <Dialog
      open={open}
      onClose={() => {
        resetForm()
        onClose()
        setAddedMedications([])
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:add_medication')}
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#ededed',
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0,
        }}
      >
        <Box marginX={2} marginY={1} display='flex' flexDirection='column'>
          <Box px={2}>
            <FormControl component='fieldset'>
              <RadioGroup
                aria-label=''
                name=''
                row
                value={currentMedication.type}
                onChange={(e) => {
                  setCurrentMedication({
                    type: e.target.value as 'ayurveda' | 'allopathy',
                  })
                }}
              >
                <FormControlLabel
                  value='allopathy'
                  label='Allopathy'
                  control={<Radio id='allopathy' />}
                />
                <FormControlLabel
                  value='ayurveda'
                  label='Ayurveda'
                  control={<Radio id='ayurveda' />}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          {currentMedication.type === 'allopathy' && (
            <AllopathyMedicationCaptureForm
              key={currentMedication.id}
              disableStartAndEndDateSelector={true}
              onChanged={(currentMedDetails) => {
                console.log(currentMedDetails)
                setCurrentMedication(currentMedDetails)
              }}
            />
          )}

          {currentMedication.type === 'ayurveda' && (
            <AyurvedaMedicationCaptureForm
              key={currentMedication.id}
              disableStartAndEndDateSelector={true}
              onChanged={(currentMedDetails) => {
                console.log(currentMedDetails)
                setCurrentMedication(currentMedDetails)
              }}
            />
          )}

          {addedMedications.length > 0 && (
            <Box display='flex' flexDirection='row' width='100%' px={2}>
              <Box
                marginY={1}
                display='flex'
                flexDirection='column'
                width='100%'
              >
                <Typography
                  variant='subtitle1'
                  color='initial'
                  style={{ padding: '2px' }}
                >
                  Currently Added Medications
                </Typography>

                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'

                  //   key={e.id}
                >
                  <AddedMedicationsList
                    medication={addedMedications}
                    key='1'
                    displayTypeOfMedication={true}
                    appointmentId=''
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Box
          display='flex'
          flexDirection='row'
          p={1}
          flexGrow={1}
          alignItems='center'
        >
          {addMedicationStatementSearchSlice.adding && (
            <Box
              flexDirection='row'
              display='flex'
              alignSelf='center'
              justifyContent='center'
            >
              <CircularProgress size={25} />
            </Box>
          )}
          <Box flexGrow={1} alignSelf='center' paddingX={2}>
            <FormControlLabel
              value='end'
              control={
                <Checkbox
                  inputProps={{
                    id: 'add_another',
                  }}
                  color='primary'
                  checked={addAnother}
                  onChange={(eve, checked) => {
                    setAddAnother(checked)
                  }}
                />
              }
              label={t('labelCommon:add_another_medication')}
              labelPlacement='end'
            />
          </Box>
          <Box display='flex' flexDirection='row'>
            <Box paddingX={1}>
              <Button
                onClick={() => {
                  resetForm()
                  onClose()
                  setAddedMedications([])
                }}
                disabled={addMedicationStatementSearchSlice.adding}
                variant='outlined'
                id='cancel'
                disableElevation
              >
                {t('labelCommon:cancel')}
              </Button>
            </Box>
            {addAnother && (
              <Box paddingX={1}>
                <Button
                  onClick={() => {
                    setOtherData(undefined)
                    addCurrentDetails()
                  }}
                  variant='contained'
                  color='primary'
                  id='add_more'
                  disableElevation
                  disabled={!isValidMedicationDetails()}
                >
                  {t('labelCommon:add_more_new')}
                </Button>
              </Box>
            )}

            <Box paddingX={1}>
              <Button
                onClick={() => {
                  if (isValidMedicationDetails()) {
                    addCurrentDetails(true)
                  } else if (addedMedications.length > 0) {
                    dispatch(
                      addMedicationStateMentDetails(
                        patient,
                        encounter,
                        addedMedications,
                        brandName
                      )
                    )
                  }
                }}
                variant='contained'
                color='primary'
                disableElevation
                id='save'
                disabled={
                  !isValidMedicationDetails() && addedMedications.length === 0
                }
              >
                {t('labelCommon:Save')}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
