import { R4 } from '@ahryman40k/ts-fhir-types'

import { Box, MenuItem, Select, Typography, useTheme } from '@material-ui/core'
import { kDialogueBackground, kPrimaryMain } from 'configs/styles/muiThemes'
import { CarePlanSubscriptionPlan } from 'models/subscriptions/carePlanSubscription/carePlanSubscriptionPlan'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  getCurrencySymbolFormChargeItemDef,
  getDurationOfChargeItemDef,
  getPriceFormChargeItemDef,
  getSortedChargeItemDefinition,
} from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import { PlanBenefitDetail } from './planItemBenifit'

export interface SubscriptionOfferingLineWiseDetailProps {
  offer: CarePlanSubscriptionPlan
  onPaymentPlanSelected?: (chargeItemDef: R4.IChargeItemDefinition) => void
}
export const SubscriptionOfferingLineWiseDetail: React.FC<SubscriptionOfferingLineWiseDetailProps> =
  ({ offer, onPaymentPlanSelected }) => {
    const theme = useTheme()
    const { t } = useTranslation(['common'])
    const disapatch = useDispatch()
    const [selectedPlanDuration, setSelectedPlanDuration] = useState<
      R4.IChargeItemDefinition | undefined
    >(
      offer.subscriptionPlans === undefined
        ? undefined
        : offer.subscriptionPlans[0]
    )

    return (
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        my={1}
        py={1.5}
        borderRadius={8}
        borderColor={theme.palette.divider}
        border={1}
      >
        {offer.subscriptionPlans && offer.subscriptionPlans.length > 0 && (
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            alignContent='center'
            alignItems='center'
            alignSelf='center'
            justifyContent='center'
            id='plan_charge_details_row'
            style={{
              backgroundColor: kPrimaryMain,
              color: 'white',
            }}
            p={0.5}
          >
            <Box width='85%' px={1}>
              <Typography variant='body1' color='inherit'>
                Choose duration of Care Plan
              </Typography>
            </Box>
            <Box width='15%'>
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                alignContent='center'
                alignItems='center'
                alignSelf='center'
                justifyContent='center'
              >
                <Select
                  id='duration'
                  name='duration'
                  variant='standard'
                  style={{
                    backgroundColor: 'white',
                    padding: '0px 5px',
                    color: kPrimaryMain,
                    fontWeight: 'bold',
                    fontSize: '12px',
                  }}
                  fullWidth
                  label='Duration'
                  value={selectedPlanDuration?.id ?? undefined}
                  onChange={(e) => {
                    const newSelectedPlan = offer.subscriptionPlans?.find(
                      (plan) => plan.id === e.target.value
                    )
                    console.log(
                      '------------------------newSelectedPlan---------------',
                      newSelectedPlan
                    )
                    if (newSelectedPlan) {
                      setSelectedPlanDuration(newSelectedPlan)
                      if (onPaymentPlanSelected)
                        onPaymentPlanSelected(newSelectedPlan)
                    }
                  }}
                >
                  {getSortedChargeItemDefinition(
                    offer.subscriptionPlans ?? []
                  ).map((plan) => (
                    <MenuItem key={plan.title ?? plan.id ?? ''} value={plan.id}>
                      {getDurationOfChargeItemDef(plan)}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </Box>
        )}

        {(offer.rawCpg.action ?? [])
          .sort((a, b) =>
            parseInt(a.prefix ?? '0', 10) < parseInt(b.prefix ?? '0', 10)
              ? -1
              : 1
          )
          .map((action, index) => (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              key={action.title ?? ''}
              style={
                index % 2 === 0
                  ? {
                      backgroundColor: kDialogueBackground,
                    }
                  : {}
              }
              p={0.5}
            >
              <Box width='85%' px={1}>
                <Typography variant='body1' color='inherit'>
                  {action.title ?? ''}
                </Typography>
              </Box>
              <Box width='15%'>
                <PlanBenefitDetail offer={action} />
              </Box>
            </Box>
          ))}

        {selectedPlanDuration && (
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            alignContent='center'
            justifyContent='space-between'
            id='plan_charge_details_row'
            style={{
              backgroundColor: kPrimaryMain,
              color: 'white',
            }}
            p={0.5}
          >
            <Box width='60%' px={1} />

            <Box width='40%' display='flex' flexDirection='row-reverse' px={2}>
              <Typography
                variant='subtitle1'
                color='inherit'
                style={{
                  color: 'white',
                }}
              >
                {getCurrencySymbolFormChargeItemDef(
                  selectedPlanDuration,
                  'base'
                )}{' '}
                {getPriceFormChargeItemDef(selectedPlanDuration, 'base')} for{' '}
                {getDurationOfChargeItemDef(selectedPlanDuration)}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    )
  }
