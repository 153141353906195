import { R4 } from '@ahryman40k/ts-fhir-types'
import { Errors } from 'io-ts'
import * as E from 'fp-ts/lib/Either'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserPractitionerRoleRef } from 'services/userDetailsService'
import { getCarePlanOfPatientWithCurrentPractitioner } from 'utils/careplan_utils/careplan_utils'

export async function addNewInstructionInFhir(
  instructionObject: R4.ICommunicationRequest
) {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateFHIRResourceRequest(
      '/CommunicationRequest',
      instructionObject
    )

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  return true
}

export async function updateDetails(
  prescriptionsBundle: R4.IBundle,
  appointmentId: string
) {
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response = await fhirApi.doCreateFHIRTransactionForUpdate(
    '',
    appointmentId,
    prescriptionsBundle
  )
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)

  if (relatedFhirDecodeRes._tag === 'Right') {
    return true
  }
  return false
}

export async function addNewInstruction(
  patientReference: R4.IReference,
  encounterId: R4.IReference,

  instruction: string,
  dueDate?: string
) {
  const patientId = patientReference.reference?.split('/')[1]
  const carePlanOfPatient = await getCarePlanOfPatientWithCurrentPractitioner(
    patientId!
  )
  console.log('carePlanOfPatient', carePlanOfPatient)
  console.log('patientReference', patientReference)
  console.log('encounterId', encounterId)
  let result = false
  try {
    result = await addNewInstructionInFhir(
      getCommunicationRequestObjectFrom({
        patientReference,
        encounterId,
        instruction,
        dueDate,
        carePlan: carePlanOfPatient,
      })
    )
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function UpdateInstructions(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  instruction: string,
  existingIns: R4.ICommunicationRequest,
  appointmentId: string,
  dueDate?: string
) {
  const patientId = patientReference.reference?.split('/')[1]
  const carePlanOfPatient = await getCarePlanOfPatientWithCurrentPractitioner(
    patientId!
  )
  console.log('carePlanOfPatient', carePlanOfPatient)
  console.log('patientReference', patientReference)
  console.log('encounterId', encounterId)

  const oldProcedureData: R4.ICommunicationRequest = { ...existingIns }
  oldProcedureData.status = 'entered-in-error'

  const procedureArray: R4.ICommunicationRequest[] = []
  procedureArray.push(oldProcedureData)
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: procedureArray.map((sv) => ({
      resource: sv,
      fullUrl: `${sv.resourceType}/${sv.id}`,
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `${sv.resourceType}/${sv.id}`,
      },
    })),
  }

  const finalData = getCommunicationRequestObjectFrom({
    patientReference,
    encounterId,
    instruction,
    dueDate,
    carePlan: carePlanOfPatient,
  })
  requestBundle.entry?.push({
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: finalData.resourceType,
    },
    resource: finalData,
  })
  let result = false
  try {
    result = await updateDetails(requestBundle, appointmentId)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

function getCommunicationRequestObjectFrom({
  patientReference,
  encounterId,
  instruction,
  dueDate,
  carePlan,
}: {
  patientReference: R4.IReference
  encounterId: R4.IReference
  instruction: string
  dueDate?: string
  carePlan?: R4.ICarePlan
}): R4.ICommunicationRequest {
  let basedOn: R4.IReference[] | undefined
  if (carePlan) {
    basedOn = [
      {
        reference: `CarePlan/${carePlan.id}`,
      },
    ]
  }
  const communicationRequest: R4.ICommunicationRequest = {
    resourceType: 'CommunicationRequest',
    subject: patientReference,
    status: 'active',
    priority: 'routine',
    basedOn,
    payload: [
      {
        contentString: instruction,
      },
    ],
    encounter: encounterId,
    authoredOn: new Date().toISOString(),
    occurrenceDateTime: dueDate ?? undefined,
    recipient: [patientReference],
    sender: getCurrentUserPractitionerRoleRef(),
  }

  return communicationRequest
}
