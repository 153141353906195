import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListSubheader,
  Radio,
  RadioGroup,
  Typography,
  Tooltip,
  Collapse,
  Link,
} from '@material-ui/core'
import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { DateWiseVitals } from 'models/dateWiseVitals'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { WelloSignExam } from 'models/WelloConditions'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import {
  requestForPhysicalExam,
  resetPhysicalExamSearchState,
} from 'redux/consultation/physicalExamSearch/physicalExamSearchSlice'
import {
  requestVitalDetailsOfPatient,
  resetVitalSearchState,
} from 'redux/consultation/vitalsDetailsSlice/vitalsDetailSlice'
import { resetPhysicalExamState } from 'redux/fhirMedicalResources/addPhysicalExam/addPhysicalExamSlice'
import { resetAddVitalsState } from 'redux/fhirMedicalResources/addVital/addVitalSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import {
  getDateWiseVitals,
  getTimeWiseVitals,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getObservationCodeText,
  getObservationContent,
  getPhysicalExams,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { logger } from 'utils/logger'
import { EditExamHandler } from 'views/components/consultation/edition/editPhysicalExaam'
import { VitalElement } from 'views/components/LeftMenu/VitalElement'
import { SOAPIndicativeElement } from 'wello-web-components'
import { PhysicalExaForDisplayTileFollowUp } from './physicalExamDisplayTileForFollowUp'
import { PhysicalExaForDisplayTile } from './physicalExamTileDisplay'

interface VitalsProp {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
}

export const PhysicalExaminationFollowUp: React.FC<VitalsProp> = ({
  fhirAppointmentDetails,
  split,
}: VitalsProp) => {
  const { t } = useTranslation(['common'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const [groupVitals, setGroupVitals] = useState<DateWiseVitals[]>([])
  const [existingSign, setExistingSign] = useState<WelloSignExam>()

  const dispatch = useDispatch()
  const vitalsDetailsSlice = useSelector(
    (state: RootState) => state.physicalExamSearchSlice
  )
  useEffect(() => {
    // dispatch(resetPhysicalExamSearchState())
    dispatch(
      requestForPhysicalExam(
        fhirAppointmentDetails.patient,
        getAppointmentIdForFollowUp(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        )
      )
    )
  }, [dispatch, fhirAppointmentDetails])

  //   useEffect(() => {
  //     if (
  //       vitalsDetailsSlice.resultsAvailable &&
  //       vitalsDetailsSlice.dateWiseVitals
  //     ) {
  //       updateVitals(vitalsDetailsSlice.dateWiseVitals)
  //     }
  //   }, [vitalsDetailsSlice])

  function updateVitals(vitalList: DateWiseVitals[]) {
    const results: DateWiseVitals[] = []
    for (let i = 0; i < vitalList.length; i++) {
      results.push({
        date: vitalList[i].date,
        vitals: vitalList[i].vitals,
        checked: true,
      })
    }
    setGroupVitals(results)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: DateWiseVitals[] = [...groupVitals]
    values[index].checked = rate
    setGroupVitals(values)
  }
  return (
    <ReactVisibilitySensor
      offset={{
        top: 100,
        bottom: 30,
      }}
      onChange={(isVisible) => {
        logger.info('Inside diagnostic')
        if (isVisible) {
          dispatch(setSelectedSection({ selectedSection: 'vitals' }))
        }
      }}
    >
      <Grid container direction='column' style={{ flexWrap: 'nowrap' }}>
        <Grid
          item
          onMouseEnter={() => showAddButton(true)}
          onMouseLeave={() => showAddButton(false)}
        >
          <Grid container direction='row' id='vitals'>
            {/* {!split && (
                  <IconButton
                    style={{ padding: '4px' }}
                    onClick={() => {
                      setOpenAddDialogue(true)
                    }}
                  >
                    <Add style={{ height: '16px' }} color='primary' />
                  </IconButton>
                )} */}
          </Grid>

          {/* {!split && (
              <Box
                display='flex'
                flexGrow
                flexDirection='row'
                justifyContent='flex-start'
                paddingBottom={1}
              >
                <Tooltip title='' id='exam_1'>
                  <IconButton
                    aria-label='btn_ord_cancel'
                    color='primary'
                    onClick={() => {
                      setOpenAddDialogue(true)
                    }}
                    style={{ padding: 0 }}
                  >
                    <AddCircleIcon
                      style={{ height: '16px', padding: 0 }}
                      color='primary'
                      id='exam_2'
                    />{' '}
                    <Typography
                      variant='subtitle2'
                      color='primary'
                      component={Link}
                      style={{ fontSize: 13 }}
                      id='exam_3'
                    >
                      {' '}
                      Signs on Examination
                    </Typography>
                  </IconButton>
                </Tooltip>
              </Box>
            )}
   */}
          {vitalsDetailsSlice.searchingConditions && (
            <Grid item>
              <CircularProgress size={15} />
            </Grid>
          )}
          {vitalsDetailsSlice.noResultsAvailable && (
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                {split ? 'No data available' : ''}{' '}
              </Typography>
            </Grid>
          )}
          {vitalsDetailsSlice.errorWhileSearchingProcedures && (
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                {vitalsDetailsSlice.errorReason ??
                  'Error while fetching Sign on Examination'}
              </Typography>
            </Grid>
          )}
          {vitalsDetailsSlice.resultsAvailable &&
            vitalsDetailsSlice.groupedExamData && (
              <Grid item style={{ padding: 0, margin: 0 }} xs={split ? 12 : 12}>
                <Box
                  flexGrow
                  width='100%'
                  display='flex'
                  flexDirection='column'
                >
                  {vitalsDetailsSlice.groupedExamData.map((val) => (
                    <Box py={1} key={val.date ?? ''}>
                      <Box
                        paddingX={1}
                        borderRadius={2}
                        style={{
                          backgroundColor: 'lightGrey',
                        }}
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        height={40}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow={1}
                          alignItems='center'
                        >
                          <Typography
                            variant='subtitle1'
                            style={{
                              color: 'black',

                              fontWeight: 'bold',
                            }}
                          >
                            Recorded on{' '}
                            {moment(val.date).format('Do MMM YYYY hh:mm A')}
                          </Typography>
                        </Box>
                      </Box>
                      <Grid
                        container
                        direction='row'
                        id='vitals'
                        style={{
                          backgroundColor: kDialogueBackground,
                        }}
                      >
                        <Grid item xs={12}>
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='80%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={1}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Sign
                                </Typography>
                              </Box>
                            </Box>
                            <Box display='flex' width='2%' paddingTop={0.1}>
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  height: '30px',

                                  width: '2px',
                                  //   backgroundColor: 'white',
                                }}
                              />
                            </Box>
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                              px={0.5}
                              width='20%'
                            >
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                Current Status
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <PhysicalExaForDisplayTileFollowUp
                        obsData={getPhysicalExams(val.occupation)}
                        appointmentId={getAppointmentIdForFollowUp(
                          fhirAppointmentDetails.appointment
                            .supportingInformation ?? []
                        )}
                        split={split}
                        onEditCLicked={() => {
                          setGroupVitals([])
                          dispatch(
                            requestForPhysicalExam(
                              fhirAppointmentDetails.patient,
                              getAppointmentIdForFollowUp(
                                fhirAppointmentDetails.appointment
                                  .supportingInformation ?? []
                              )
                            )
                          )
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </Grid>
            )}
        </Grid>
      </Grid>
    </ReactVisibilitySensor>
  )
}
