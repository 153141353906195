/* eslint-disable no-await-in-loop */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DateWiseGraphData,
  LocationWiseGraphData,
} from 'models/adminGraph/dateWiseGraphData'
import { GraphType } from 'models/adminGraph/graphType'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import { OutComeClient } from 'services/outComeClient'
import {
  getCurrentUserUnitDetails,
  getCurrentUserUnitReference,
  isUnitAdmin,
} from 'services/userDetailsService'
import { sleep } from 'utils/dateUtil'
import { getDateWiseDietPlansFromBundle } from 'utils/fhirResoureHelpers/ipdDietHelper'
import {
  getDataForDateWiseGraph,
  getDataForPayment,
  getDataForTypeWise,
} from 'utils/fhirResoureHelpers/orgAdminGraphHelper'
import { logger } from 'utils/logger'
import { getUnits } from 'utils/unitUtils'
import { LocationWiseGrapStatus } from './locationWiseGraphStatus'

const initialState: LocationWiseGrapStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
}

const locationSearchPatientSlice = createSlice({
  name: 'locationSearchPatientSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<LocationWiseGrapStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorReason = action.payload.errorReason
      state.locationGraphData = action.payload.locationGraphData
      state.stateGraphData = action.payload.stateGraphData
      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
    resetMonthState(state, action: PayloadAction<LocationWiseGrapStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorReason = action.payload.errorReason
      state.stateGraphData = action.payload.stateGraphData
      state.locationGraphData = action.payload.locationGraphData
      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const resetLocationState = () => (dispatch: AppDispatch) => {
  dispatch(locationSearchPatientSlice.actions.resetMonthState(initialState))
}

export const requestForLocationWisePatientData =
  (
    unit: string,
    date: Date,
    locationType: string,
    endDate?: Date,
    type?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: LocationWiseGrapStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(locationSearchPatientSlice.actions.updatedStatus(state))
    try {
      let unitArr: R4.IOrganization[] = []
      //   const res = sessionStorage.getItem('unitDetails')
      //   if (res !== null) unitArr = JSON.parse(res)
      const fhirClient: OutComeClient = new OutComeClient()

      const endDateData =
        type && endDate
          ? moment(endDate).endOf('day').format('YYYY-MM-DD')
          : moment(endDate || date)
              .endOf('month')
              .format('YYYY-MM-DD')

      const searchParameters: any = {
        start_date: moment(endDateData).toISOString(),
        end_date: moment(date).toISOString(),
      }
      unitArr = await getUnits()
      if (unitArr.length > 0) {
        const response: any = await fhirClient.doGetResource(
          `business/ipd_patient_location_count_by_units?unit_id=${
            isUnitAdmin() ? getCurrentUserUnitDetails().id ?? '' : unitArr[0].id
          }`,
          searchParameters
        )

        const resp: R4.IBundle = response as R4.IBundle
        if (response.result) {
          if (response.result.length > 0) {
            const finalGraphData: LocationWiseGraphData[] = []
            const finalGraphDataForState: LocationWiseGraphData[] = []

            const cityData = response.result.filter(
              (d: any) =>
                d.feature_id.includes('city_count') && d.value !== null
            )

            const stateData = response.result.filter(
              (d: any) =>
                d.feature_id.includes('state_count') && d.value !== null
            )

            if (cityData.length > 0) {
              for (let i = 0; i < cityData.length; i++) {
                finalGraphData.push({
                  city: cityData[i].value,
                  NoOfPatients: cityData[i].count,
                })
              }

              for (let i = 0; i < stateData.length; i++) {
                finalGraphDataForState.push({
                  city: stateData[i].value,
                  NoOfPatients: stateData[i].count,
                })
              }
              state.resultsAvailable = true
              state.searchingAppointments = false
              state.noResultsAvailable = false
              state.errorReason = undefined
              state.locationGraphData = finalGraphData.sort((a, b) =>
                a.NoOfPatients! < b.NoOfPatients!
                  ? 1
                  : b.NoOfPatients! < a.NoOfPatients!
                  ? -1
                  : 0
              )
              state.stateGraphData = finalGraphDataForState.sort((a, b) =>
                a.NoOfPatients! < b.NoOfPatients!
                  ? 1
                  : b.NoOfPatients! < a.NoOfPatients!
                  ? -1
                  : 0
              )

              state.errorWhileSearchingAppointments = false
              dispatch(locationSearchPatientSlice.actions.updatedStatus(state))
            } else {
              const errorSearchDoctor: LocationWiseGrapStatus = {
                searchingAppointments: false,
                errorWhileSearchingAppointments: false,
                resultsAvailable: false,
                noResultsAvailable: true,
              }
              dispatch(
                locationSearchPatientSlice.actions.updatedStatus(
                  errorSearchDoctor
                )
              )
            }
          } else {
            const errorSearchDoctor: LocationWiseGrapStatus = {
              searchingAppointments: false,
              errorWhileSearchingAppointments: false,
              resultsAvailable: false,
              noResultsAvailable: true,
            }
            dispatch(
              locationSearchPatientSlice.actions.updatedStatus(
                errorSearchDoctor
              )
            )
          }
        } else {
          const errorSearchDoctor: LocationWiseGrapStatus = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: true,
            resultsAvailable: false,
            errorReason: 'Error while searching',
          }
          dispatch(
            locationSearchPatientSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: LocationWiseGrapStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
      }
      dispatch(
        locationSearchPatientSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export async function getTotalNumbeOfAppointmentForDate(
  graphData: DateWiseGraphData
): Promise<DateWiseGraphData> {
  let carePlan: R4.ICommunicationRequest | undefined

  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const graphWithIPD: DateWiseGraphData = {
    fullDate: graphData.fullDate,
    date: graphData.date,
    TotalDischarge: graphData.TotalDischarge,
    NoOfAppointments: 0,
    collection: graphData.collection,
  }

  const searchParameters: any = {
    _count: 500,
    status: 'active,completed',
    occurrence: `${moment(graphData.fullDate)
      .endOf('day')
      .utcOffset('+05:30')
      .toISOString()}`,
  }
  const response: any = await fhirClient.doGetResource(
    '/ServiceRequest?code=281685003,33022008,304903009&_sort=occurrence',
    searchParameters
  )

  const responseBundle: R4.IBundle = response as R4.IBundle
  if (responseBundle.total) {
    graphWithIPD.NoOfAppointments = responseBundle.total
  }

  return graphWithIPD
}

export default locationSearchPatientSlice.reducer
