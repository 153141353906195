import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'

import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getImagingRequestsOfIPD } from 'redux/consultation/ImagingReferrals/imagingDiagnosticsListSliceIPD'
import { getImagingRequestsOfAppointment } from 'redux/consultation/imaging_diagnostics_requests_list/imagingDiagnosticsListSlice'

import { RootState } from 'redux/rootReducer'
import { LabDiagnosticTabular } from 'views/components/consultation/assessment/labTestTabularTile'

interface Props {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  followUp?: boolean
  split?: boolean
}

export const ImagingDiagnosticsListIPD: React.FC<Props> = ({
  fhirAppointmentDetails,
  followUp,
  split,
}: Props) => {
  const initialFetch = useRef(true)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const imagingDiagnosticsListSlice = useSelector(
    (state: RootState) => state.imagingDiagnosticsListSliceIPD
  )

  useEffect(() => {
    dispatch(getImagingRequestsOfIPD(fhirAppointmentDetails, false))
  }, [dispatch, fhirAppointmentDetails])

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {(imagingDiagnosticsListSlice.updatingImpressions ||
        imagingDiagnosticsListSlice.fetchingImpressions) && (
        <CircularProgress size={15} />
      )}
      {split &&
        (imagingDiagnosticsListSlice.noResultsAvailable ||
          (imagingDiagnosticsListSlice.serviceRequest &&
            imagingDiagnosticsListSlice.serviceRequest.length === 0)) && (
          <Box marginY={1} display='flex' flexDirection='column' width='100%'>
            <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
              No data available
            </Typography>
          </Box>
        )}
      {imagingDiagnosticsListSlice.serviceRequest &&
        imagingDiagnosticsListSlice.serviceRequest.length > 0 && (
          <Box display='flex' flexDirection='column' width='100%'>
            <LabDiagnosticTabular
              referrals={imagingDiagnosticsListSlice.serviceRequest}
              type='imaging'
            />
            {/* {imagingDiagnosticsListSlice.serviceRequest.map(
              (e: R4.IServiceRequest) => (
                <Box key={e.id ?? ''}>
                  <ImagingDiagnosticTile
                    referrals={e}
                    key={`${e.id ?? 't'}_${e.id ?? 's'}`}
                  />
                </Box>
              )
            )} */}
          </Box>
        )}
    </Box>
  )
}
