import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
} from '@material-ui/core'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import React, { useState } from 'react'
import {
  getLastNameOfPatient,
  getNameOfPatient,
  getProfilePicPatient,
  getTelecomOfPatient,
} from 'utils/fhirResourcesHelper'
import {
  getOrderFinalStatusText,
  getOrderTime,
  getOrderTypeDisplayCode,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getTotalAmountOfLabOfferings,
  getTotalAmountOfPartnerLabOfferings,
} from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { GenderAndAgeTile } from 'views/components/patients/genderAndAgeTile'
import { UpdatePartnerLabOrderHandler } from './updatePartnerLabOrderHandler'

interface Props {
  labOrderDetail: FhirLabOrderDetail
  percentage: number
  isOdd?: boolean
  onTap?: () => void
  dense?: boolean
  locationId: string
  isSelected?: boolean
}

export const PartnerLabOrderTile: React.FC<Props> = ({
  labOrderDetail,
  percentage,
  isOdd,
  onTap,
  dense,
  locationId,
  isSelected,
}) => {
  const [openPopUp, setOpenPopUp] = useState<boolean>(false)
  const [orderDetail, setOrderDetail] =
    useState<FhirLabOrderDetail>(labOrderDetail)

  return (
    <Box width='100%' onClick={onTap}>
      <Card
        elevation={isOdd ? 0 : 1}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 0,
          backgroundColor: isSelected
            ? kPrimaryLight
            : isOdd
            ? undefined
            : '#f2f2f2',
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 0,
          }}
        >
          {dense && (
            <Box display='flex' flexDirection='row' width='100%'>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                px={1}
              >
                <Avatar
                  alt={getNameOfPatient(labOrderDetail.patient) ?? ''}
                  src={getProfilePicPatient(labOrderDetail.patient) ?? ''}
                />
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                flexGrow={1}
              >
                <Typography variant='subtitle1' color='primary'>
                  {getNameOfPatient(labOrderDetail.patient)}
                </Typography>
                <Typography variant='body2' color='initial'>
                  {getTelecomOfPatient(labOrderDetail.patient)}
                </Typography>

                <Typography variant='subtitle2' color='initial'>
                  {`Order #${labOrderDetail!.serviceRequest.id ?? ''}`}
                </Typography>
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-start'
                >
                  <Typography variant='body2' color='initial'>
                    {`${getOrderTime(labOrderDetail)} |`}
                  </Typography>
                  <Typography variant='body2' color='initial'>
                    {getOrderTypeDisplayCode(labOrderDetail)}
                  </Typography>
                  <Typography variant='body2' color='initial'>
                    {/* {getTotalAmountOfLabOfferings(labOrderDetail.tests ?? [])} */}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          {!dense && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              alignContent='center'
              p={0.5}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='20%'
              >
                <Typography variant='body1' color='initial'>
                  {getOrderTime(labOrderDetail)}
                </Typography>
                <Typography variant='body2' color='initial'>
                  {getOrderTypeDisplayCode(labOrderDetail)}
                </Typography>
                <Typography variant='subtitle2' color='initial'>
                  Paid to Lab: &#x20b9;{' '}
                  {getTotalAmountOfPartnerLabOfferings(
                    labOrderDetail.tests ?? [],
                    percentage,
                    labOrderDetail.partnerLabTask
                  )}
                </Typography>
                <Typography variant='subtitle2' color='initial'>
                  Amount Received: &#x20b9;{' '}
                  {getTotalAmountOfLabOfferings(labOrderDetail.tests ?? [])}
                </Typography>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignContent='flex-start'
                width='20%'
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-start'
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                  >
                    <Avatar
                      alt={getNameOfPatient(labOrderDetail.patient) ?? ''}
                      src={getProfilePicPatient(labOrderDetail.patient) ?? ''}
                    />
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='start'
                    px={1}
                  >
                    <Typography variant='subtitle1' color='primary'>
                      {getNameOfPatient(labOrderDetail.patient)}{' '}
                      {getLastNameOfPatient(labOrderDetail.patient)}
                    </Typography>
                    <Typography variant='body2' color='initial'>
                      {getTelecomOfPatient(labOrderDetail.patient)}
                    </Typography>
                    <Typography variant='subtitle2' color='initial'>
                      {`Order #${labOrderDetail!.serviceRequest.id ?? ''}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='8%'
              >
                <GenderAndAgeTile
                  patient={labOrderDetail.patient}
                  status={false}
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='20%'
                paddingX={1}
              >
                <Typography variant='body1' color='initial'>
                  {labOrderDetail.tests
                    ?.map(
                      (e) => e?.planDefinition.title ?? e?.planDefinition.name
                    )
                    .join(',')}
                </Typography>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='15%'
              >
                <Typography
                  variant='subtitle1'
                  color={
                    labOrderDetail.serviceRequest.status === 'active'
                      ? 'primary'
                      : 'error'
                  }
                >
                  {getOrderFinalStatusText(labOrderDetail)}
                </Typography>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='5%'
              >
                <IconButton
                  aria-label='btn_order_menu'
                  onClick={() => {
                    if (labOrderDetail) {
                      setOrderDetail(labOrderDetail)
                      setOpenPopUp(true)
                    }
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/editIcon.png`}
                    alt='edit'
                    height={20}
                  />
                </IconButton>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>

      <UpdatePartnerLabOrderHandler
        open={openPopUp}
        locationId={locationId}
        percentage={percentage}
        onLabOrderCreated={() => {
          setOpenPopUp(false)
        }}
        onClose={() => {
          setOpenPopUp(false)
        }}
        labOrderDetail={orderDetail}
      />
    </Box>
  )
}
