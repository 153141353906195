/* eslint-disable no-irregular-whitespace */
import { Grid, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-vis/dist/style.css'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import { AssessDetailsExam } from './assessment/assesmentExam'
import { AssessDetails } from './assessment/assessDetails'
import { AssessmentCaptureDetails } from './assessment/assessmentCaptureDetails'

interface AssessmentPartOfConsultationProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
}

export const AssessmentPartOfConsultation: React.FC<AssessmentPartOfConsultationProps> =
  ({ fhirAppointmentDetails, split }: AssessmentPartOfConsultationProps) => {
    const { t } = useTranslation(['common'])
    const [addButton, showAddButton] = useState<boolean>(false)

    return (
      <Grid container spacing={1} direction='column'>
        <Grid item>
          <Typography variant='h5' color='primary'>
            {' '}
            {/* {t('labelCommon:Assessment')} */}
          </Typography>
        </Grid>

        <Grid>
          <AssessmentCaptureDetails
            fhirAppointmentDetails={fhirAppointmentDetails}
            split={split}
          />
        </Grid>
        {getAppointmentIdForFollowUp(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        ).length > 0 && (
          <Grid>
            <AssessDetails
              fhirAppointmentDetails={fhirAppointmentDetails}
              split={split}
            />
          </Grid>
        )}
        {getAppointmentIdForFollowUp(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        ).length > 0 && (
          <Grid>
            <AssessDetailsExam
              fhirAppointmentDetails={fhirAppointmentDetails}
              split={split}
            />
          </Grid>
        )}
      </Grid>
    )
  }
