import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Collapse,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { Alert } from '@material-ui/lab'
import { OperationalHoursOfDay } from 'models/operationalHoursOfDay'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ORG_LOCATION_SLOT_DURATION } from 'utils/constants/fhir_vs_ext_constants'
import { addExtensionToExtensionsOfUrl } from 'utils/fhirResourcesHelper'
import {
  defaultEndTime,
  defaultStartTime,
  GetDefaultBusinessTimings,
  GetDefaultBusinessTimingsFromLocation,
  GetFhirBusinessTimings,
  GetSingleLetterForFhirDay,
  preSelectedDays,
  updateDaySelection,
} from 'utils/fhirResoureHelpers/calender_helper'
import { BusinessHour } from './microElements/businessHour'
import { DaySelector } from './microElements/DaySelector'

interface Props {
  location: R4.ILocation
  isUpdate?: boolean
  onSelectionChanges: (val: R4.ILocation) => void
}

export const AddUnitTimeSetup: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const [openingHours, setOpeningHours] = useState<OperationalHoursOfDay[]>(
    props.isUpdate
      ? GetDefaultBusinessTimingsFromLocation(props.location) ??
          updateDaySelection(preSelectedDays, GetDefaultBusinessTimings())
      : updateDaySelection(preSelectedDays, GetDefaultBusinessTimings())
  )

  const openingHoursArr = openingHours

  const [slotMins, setSlotMins] = useState<string>('20')

  const [commonChoice, setCommonChoice] = useState<OperationalHoursOfDay>({
    allDayOpen: false,
    available: true,
    dayOfWeek: R4.HealthcareService_AvailableTimeDaysOfWeekKind._fri,
    timings: [
      {
        availableEndTime: defaultEndTime,
        availableStartTime: defaultStartTime,
        id: 'sample',
      },
    ],
  })

  function onTimesUpdated(changedTime: OperationalHoursOfDay) {
    const tempValues: OperationalHoursOfDay[] = [...openingHours]
    const index = tempValues.findIndex(
      (e) => e.dayOfWeek === changedTime.dayOfWeek
    )
    if (index > 0) {
      tempValues[index].timings = changedTime.timings
      tempValues[index].allDayOpen = changedTime.allDayOpen
    }
    setOpeningHours(tempValues)
    props.onSelectionChanges(props.location)
  }

  function onNewAddRemove(changedTime: OperationalHoursOfDay) {
    const tempValues: OperationalHoursOfDay[] = [...openingHours]
    const index = tempValues.findIndex(
      (e) => e.dayOfWeek === changedTime.dayOfWeek
    )

    if (index >= 0) {
      tempValues[index].timings = changedTime.timings
      tempValues[index].allDayOpen = changedTime.allDayOpen
      tempValues[index].available = changedTime.available
    }
    props.location.hoursOfOperation = GetFhirBusinessTimings(tempValues)
    setOpeningHours(tempValues)
    props.onSelectionChanges(props.location)
  }

  //   useEffect(() => {
  //     props.location.hoursOfOperation = GetFhirBusinessTimings(openingHours)
  //   }, [])

  //   useEffect(() => {
  //     props.location.hoursOfOperation = GetFhirBusinessTimings(openingHours)
  //     props.onSelectionChanges(props.location)
  //   }, [props.location])

  //   useEffect(() => {
  //     const selfExt: R4.IExtension = {
  //       url: ORG_LOCATION_SLOT_DURATION,
  //       valueString: slotMins,
  //     }
  //     props.location.extension = addExtensionToExtensionsOfUrl(
  //       props.location.extension,
  //       selfExt
  //     )
  //     props.onSelectionChanges(props.location)
  //   }, [props.location])

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      <Box display='flex' flexDirection='column' width='100%'>
        {openingHours.map((e) => {
          if (e.dayOfWeek) {
            return (
              <BusinessHour
                key={e.dayOfWeek}
                selectionOfDay={e}
                onSelectionChanges={(changedDetails) => {
                  onTimesUpdated(changedDetails)
                }}
                onAddRemoveChanges={(changedDetails) => {
                  onNewAddRemove(changedDetails)
                }}
              />
            )
          }
          return <div key={e.dayOfWeek} />
        })}
      </Box>
    </Box>
  )
}
