import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedWelloMedication } from 'models/groupedWelloMedication'
import { PurposeOfUse } from 'models/purposeOfUse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestMedicationHistoryOfPatient } from 'redux/patientMedicalHistory/medicationHistory/medicationHistorySearchSlice'
import { RootState } from 'redux/rootReducer'
import { isMedicalServiceProvider } from 'services/userDetailsService'
import { AddMedicationStatementHandlerNew } from '../../addition/addMedicationStatementNew'
import { AddedMedicationsList } from '../../assessment/medicationsListTable'
import { StatementMedicationTableTile } from '../../assessment/statementTabularFormat'

interface MedicationProp {
  fhirAppointmentDetails: FhirAppointmentDetail
  patient: R4.IPatient
  purposeOfUse: PurposeOfUse
  requestingAppointmentId?: string
  status: string
  splitView: boolean
}

export const MedicationOPD: React.FC<MedicationProp> = ({
  fhirAppointmentDetails,
  patient,
  purposeOfUse,
  requestingAppointmentId,
  status,
  splitView,
}: MedicationProp) => {
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const dispatch = useDispatch()
  const medicationHistorySearchSlice = useSelector(
    (state: RootState) => state.medicationHistorySearchSlice
  )
  const [groupVitals, setGroupVitals] = useState<GroupedWelloMedication[]>([])
  useEffect(() => {
    dispatch(
      requestMedicationHistoryOfPatient(
        patient,
        purposeOfUse,
        splitView,
        fhirAppointmentDetails.appointment.id ?? '',
        fhirAppointmentDetails.encounter
          ? fhirAppointmentDetails.encounter.id ?? ''
          : ''
      )
    )
  }, [dispatch, patient])

  useEffect(() => {
    if (
      medicationHistorySearchSlice.resultsAvailable &&
      medicationHistorySearchSlice.groupedMedications
    ) {
      console.log(medicationHistorySearchSlice.groupedMedications)
      updateMedicationList(medicationHistorySearchSlice.groupedMedications)
    }
  }, [medicationHistorySearchSlice])

  function updateMedicationList(procedureList: GroupedWelloMedication[]) {
    const results: GroupedWelloMedication[] = []
    for (let i = 0; i < procedureList.length; i++) {
      results.push({
        date: procedureList[i].date,
        medications: procedureList[i].medications,
        ayurvedaMedication: procedureList[i].medications.filter(
          (e) => e.type === 'ayurveda'
        ),
        collapse: true,
      })
    }
    setGroupVitals(results)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: GroupedWelloMedication[] = [...groupVitals]
    values[index].collapse = rate
    setGroupVitals(values)
  }
  return (
    <Box
      flexGrow
      width='100%'
      flexDirection='column'
      display='flex'
      style={{ padding: 0, margin: 0 }}
    >
      <Box
        flexGrow
        width='100%'
        display='flex'
        flexDirection='row'
        onMouseEnter={() => showAddButton(true)}
        onMouseLeave={() => showAddButton(false)}
      >
        <Box flexDirection='row' display='flex' flexGrow width='100%'>
          <Box py={1}>
            <Typography variant='subtitle1'> Current Medication</Typography>
          </Box>{' '}
          {/* {isMedicalServiceProvider() && status !== 'completed' && !splitView && (
            <IconButton
              style={{ padding: '4px' }}
              onClick={() => {
                setOpenAddDialogue(true)
              }}
            >
              <Add style={{ height: '14px' }} color='primary' />
            </IconButton>
          )} */}
        </Box>
      </Box>
      {isMedicalServiceProvider() && status !== 'completed' && !splitView && (
        <Box
          display='flex'
          flexGrow
          flexDirection='row'
          justifyContent='flex-start'
          paddingBottom={1}
        >
          <Tooltip title='' id='med_30'>
            <IconButton
              aria-label='btn_ord_cancel'
              color='primary'
              onClick={() => {
                setOpenAddDialogue(true)
              }}
              id='med_31'
              style={{ padding: 0 }}
            >
              <AddCircleIcon
                style={{ height: '16px', padding: 0 }}
                color='primary'
                id='med_32'
              />{' '}
              <Typography
                variant='subtitle2'
                color='primary'
                component={Link}
                style={{ fontSize: 13 }}
                id='med_33'
              >
                {' '}
                Current Medication
              </Typography>
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {medicationHistorySearchSlice.searchingConditions && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <CircularProgress size={15} />
        </Box>
      )}
      {medicationHistorySearchSlice.noResultsAvailable && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            {' '}
            {!isMedicalServiceProvider() || status === 'completed' || splitView
              ? 'No data available'
              : ''}
          </Typography>
        </Box>
      )}

      {medicationHistorySearchSlice.errorWhileSearchingAllergies && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            color='error'
            style={{
              fontWeight: 400,
            }}
          >
            {medicationHistorySearchSlice.errorReason ??
              'Error while fetching medication'}
          </Typography>
        </Box>
      )}

      {medicationHistorySearchSlice.groupedMedications &&
        medicationHistorySearchSlice.groupedMedications.length > 0 && (
          <Box
            marginY={1}
            display='flex'
            flexDirection='column'
            width='100%'
            px={splitView ? 1 : 0}
          >
            {groupVitals.map((e, index: number) => (
              <Box py={1} key={e.date ?? ''}>
                <Box
                  paddingX={1}
                  borderRadius={2}
                  style={{
                    backgroundColor: 'lightGrey',
                  }}
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height={40}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow={1}
                    alignItems='center'
                  >
                    <Typography
                      variant='subtitle1'
                      style={{
                        color: 'black',

                        fontWeight: 'bold',
                      }}
                    >
                      Recorded on{' '}
                      {moment(e.date).format('Do MMMM YYYY hh:mm A')}
                    </Typography>
                  </Box>
                  <Box
                    justifyContent='flex-end'
                    display='flex'
                    flexGrow={1}
                    alignItems='center'
                  >
                    <Box px={1}>
                      <Tooltip title=''>
                        <IconButton
                          aria-label='collapse_order_type'
                          size='small'
                          onClick={() => {
                            handleCollapseForPanel1(!e.collapse, index)
                          }}
                        >
                          {e.collapse && <ArrowDropUpOutlined />}
                          {!e.collapse && <ArrowDropDownOutlined />}
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
                <Collapse
                  in={e.collapse}
                  style={{
                    width: '100%',
                  }}
                >
                  <AddedMedicationsList
                    medication={e.medications}
                    displayTypeOfMedication={true}
                    appointmentId=''
                    history={true}
                    split={true}
                  />
                </Collapse>
              </Box>
            ))}
          </Box>
        )}

      <AddMedicationStatementHandlerNew
        open={openAddDialogue}
        encounter={fhirAppointmentDetails.encounter!}
        patient={fhirAppointmentDetails.patient}
        onMedicalConditionAdded={() => {
          setOpenAddDialogue(false)
          dispatch(
            requestMedicationHistoryOfPatient(
              patient,
              purposeOfUse,
              splitView,
              requestingAppointmentId
            )
          )
        }}
        onClose={() => {
          setOpenAddDialogue(false)
        }}
      />
    </Box>
  )
}
