import { Box, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getAppointmentIdForFollowUp,
  getCommunicationIdForAppointment,
} from 'utils/fhirResourcesHelper'
import { hasSpaces } from 'utils/fhirResoureHelpers/allergyHelpers'
import moment from 'moment'
import { FhirReferralInsightDetails } from 'models/fhirPrescriptionDetails'
import { ReasonForVisitTile } from '../appointments/reasonVisitTile'

// import { ChiefComplaint } from './subjective/chiefComplaintsDetails'

// import { MedicalAndHealthHistoryOfPatient } from './subjective/history_details'
import { MedicalHistorySubjectiveForReferral } from './refSubMedHistory'
import { ChiefComplaintsHistory } from './history/chiefComplaintHistory'

interface SubjectivePartOfConsultationProps {
  historyDetails?: FhirReferralInsightDetails
  split: boolean
}

export const ReferralHistorySubjective: React.FC<SubjectivePartOfConsultationProps> =
  ({ historyDetails, split }: SubjectivePartOfConsultationProps) => {
    // const [t] = useTranslation(['en', 'hi', 'labelCommon'])
    console.log('1')
    return (
      <Box flexDirection='column' width='100%' py={1}>
        {/* {fhirAppointmentDetails.appointment.comment &&
          fhirAppointmentDetails.appointment.comment.trim().length > 0 && (
            <Box
              display='flex'
              width={split === true ? '100%' : '80%'}
              py={0.5}
            >
              <ReasonForVisitTile
                type={fhirAppointmentDetails.appointment.comment.trim()}
                date={
                  fhirAppointmentDetails.appointment.meta
                    ? fhirAppointmentDetails.appointment.meta.lastUpdated
                      ? moment(
                          fhirAppointmentDetails.appointment.meta.lastUpdated
                        ).format('Do MMMM YYYY')
                      : ''
                    : ''
                }
              />
            </Box>
          )} */}

        <Box display='flex' width={split === true ? '100%' : '90%'}>
          <ChiefComplaintsHistory
            chiefDataArrayData={
              historyDetails ? historyDetails.chiefComplaint ?? [] : []
            }
          />
        </Box>

        <Box display='flex' width={split === true ? '100%' : '90%'}>
          <MedicalHistorySubjectiveForReferral
            fhirAppointmentDetails={historyDetails}
            split={split}
          />
        </Box>
      </Box>
    )
  }
