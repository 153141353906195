import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Tooltip,
  Link,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { requestAppetiteHistoryOfPatient } from 'redux/ipd/patientMedicalHistory/appetiteHistory/appetiteSearchSlice'
import { requestDietHistoryOfPatient } from 'redux/ipd/patientMedicalHistory/dietHistory/dietHistorySlice'
import { requestMicturitionHistoryOfPatient } from 'redux/ipd/patientMedicalHistory/micturitionHistory/micturitionSearchSlice'
import { requestFamilyConditionHistoryOfPatient } from 'redux/patientMedicalHistory/familyConditionHistory/familyMedicalConditionsHistorySlice'
import { RootState } from 'redux/rootReducer'
import { isUnitAdmin } from 'services/userDetailsService'
import { getTimeAgo } from 'utils/dateUtil'
import {
  getNotesFromAllergy,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import {
  getObservationCodeText,
  getObservationContent,
  getRelationFromFamilyResource,
} from 'utils/fhirResoureHelpers/observationHelpers'
import {
  HistoryObjectiveIndicative,
  SOAPIndicativeElement,
} from 'wello-web-components'
import { HistoryObjectiveIndicativeComp } from 'views/components/LeftMenu/HistoryObjectiveIndicative'
import { HistoryTabularFormatTile } from 'views/components/consultation/history/historyTabularFormatTile'
import { AddMicturition } from './addMicturition'
// import { AddFamilyMedicalCondtionHandler } from '../addFamilyHistory'
// import { AddMedicalConditionHandler } from '../addition/addMedicalConditions'
// import { AddDiet } from './diet/AddDiet'

interface DietProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  status: string
  splitView: boolean
}

export const Micturition: React.FC<DietProps> = ({
  fhirAppointmentDetails,
  status,
  splitView,
}: DietProps) => {
  const { t } = useTranslation(['common'])
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const dispatch = useDispatch()
  const micturitionSearchSlice = useSelector(
    (state: RootState) => state.micturitionSearchSlice
  )
  useEffect(() => {
    dispatch(
      requestMicturitionHistoryOfPatient(
        '',
        fhirAppointmentDetails.patient,
        splitView
      )
    )
  }, [dispatch, fhirAppointmentDetails])
  return (
    <>
      <Box display='flex' flexDirection='column' flexGrow width='100%'>
        <Box display='flex' flexDirection='row' flexGrow width='100%'>
          <Box py={1}>
            <Typography variant='subtitle1'> Micturition</Typography>
          </Box>
          {/* {!isUnitAdmin() && status !== 'completed' && !splitView && (
            <IconButton
              style={{ padding: '4px' }}
              onClick={() => {
                setOpenAddDialogue(true)
              }}
            >
              <Add style={{ height: '14px' }} color='primary' />
            </IconButton>
          )} */}
        </Box>

        {!isUnitAdmin() && status !== 'completed' && !splitView && (
          <Box
            display='flex'
            flexGrow
            flexDirection='row'
            justifyContent='flex-start'
            paddingBottom={1}
          >
            <Tooltip title='' id='mic_1'>
              <IconButton
                aria-label='btn_ord_cancel'
                color='primary'
                id='mic_2'
                onClick={() => {
                  setOpenAddDialogue(true)
                }}
                style={{ padding: 0 }}
              >
                <AddCircleIcon
                  style={{ height: '16px', padding: 0 }}
                  color='primary'
                  id='mic_3'
                />{' '}
                <Typography
                  variant='subtitle2'
                  color='primary'
                  component={Link}
                  style={{ fontSize: 13 }}
                  id='mic_4'
                >
                  {' '}
                  Micturition
                </Typography>
              </IconButton>
            </Tooltip>
          </Box>
        )}

        {micturitionSearchSlice.noResultsAvailable && (
          <Box display='flex' flexDirection='row' flexGrow width='100%'>
            <Typography
              variant='subtitle2'
              style={{
                fontWeight: 400,
              }}
            >
              {isUnitAdmin() || status === 'completed' || splitView
                ? 'No data available'
                : ' '}{' '}
            </Typography>
          </Box>
        )}

        {micturitionSearchSlice.searchingConditions && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <CircularProgress size={15} />
          </Box>
        )}
        {micturitionSearchSlice.errorWhileSearchingProcedures && (
          <Box display='flex' flexDirection='row' flexGrow width='100%'>
            <Typography
              variant='subtitle2'
              color='error'
              style={{
                fontWeight: 400,
              }}
            >
              Error while fetching Micturition
            </Typography>
          </Box>
        )}

        {micturitionSearchSlice.resultsAvailable &&
          micturitionSearchSlice.groupedObs && (
            <Box flexGrow width='100%' display='flex' flexDirection='row'>
              <Box display='flex' flexDirection='column' width='100%' flexGrow>
                {micturitionSearchSlice.groupedObs.map((val) => (
                  <Box py={1} key={val.date ?? ''}>
                    <Box
                      paddingX={1}
                      borderRadius={2}
                      style={{
                        backgroundColor: 'lightGrey',
                      }}
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      height={40}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow={1}
                        alignItems='center'
                      >
                        <Typography
                          variant='subtitle1'
                          style={{
                            color: 'black',

                            fontWeight: 'bold',
                          }}
                        >
                          Recorded on{' '}
                          {moment(val.date).format('DD-MM-YYYY hh:mm A')}
                        </Typography>
                      </Box>
                    </Box>
                    <HistoryTabularFormatTile
                      obsData={val.occupation}
                      type='Micturition'
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          )}
      </Box>

      <AddMicturition
        open={openAddDialogue}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onDietAdd={() => {
          setOpenAddDialogue(false)
          dispatch(
            requestMicturitionHistoryOfPatient(
              '',
              fhirAppointmentDetails.patient,
              false
            )
          )
        }}
        onClose={() => {
          setOpenAddDialogue(false)
        }}
      />
    </>
  )
}
