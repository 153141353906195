/* eslint-disable no-await-in-loop */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import axios, { CancelTokenSource } from 'axios'
import {
  FhirAppointmentDetail,
  FhirPendingAppointmentDetails,
} from 'models/fhirAppointmentDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient, cancelTokenStore } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getUserCurrentRole,
  isDoctor,
} from 'services/userDetailsService'
import {
  getAppointmentIdForFollowUp,
  getSpecializationsString,
} from 'utils/fhirResourcesHelper'
import {
  getAppointmentArrayFromBundle,
  getAppointmentList,
  getEncounterFromBundle,
  getEncounterFromBundleForList,
  getExpandedAppointmentFromBundle,
  getExpandedAppointmentFromBundlePendingList,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import { logger } from 'utils/logger'
import _ from 'lodash'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { AppointmentSearchStatus } from './appointmentSearchStatus'
import { requestAppointmentsCountForToday } from '../appointmentCount/appointmentCountSlice'

const initialState: AppointmentSearchStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
}

const appointmentSearchSlice = createSlice({
  name: 'appointmentSearch',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<AppointmentSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableAppointments = action.payload.availableAppointments
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
      state.pageState = action.payload.pageState
      state.existingDateWiseData = action.payload.existingDateWiseData
      state.recordsCount = action.payload.recordsCount
    },
    resetState(state, action: PayloadAction<AppointmentSearchStatus>) {
      state.errorReason = undefined
      state.pageState = undefined
      state.recordsCount = undefined
      state.existingDateWiseData = undefined
      state.searchingAppointments = false
      state.noResultsAvailable = false

      state.availableAppointments = undefined
      state.resultsAvailable = false

      state.errorWhileSearchingAppointments = false
    },
  },
})

export const resetSearchAppointmentForDisplay =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: AppointmentSearchStatus = {
      errorReason: undefined,
      searchingAppointments: false,
      noResultsAvailable: false,
      availableAppointments: undefined,
      resultsAvailable: false,
      errorWhileSearchingAppointments: false,
      pageState: undefined,
      existingDateWiseData: undefined,
      recordsCount: undefined,
    }
    dispatch(appointmentSearchSlice.actions.resetState(state))
  }

export const requestAppointmentSForTheDateRange =
  (
    selectedDoctors: PractitionerWithRole[],
    viewType: string,
    status: string,
    selectedDate?: Date,
    selectedSpecialization?: R4.ICoding[],
    name?: string,
    pageState?: string,
    recordsCount?: number,
    existingAppointment?: FhirAppointmentDetail[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AppointmentSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      availableAppointments: existingAppointment,

      pageState,
      recordsCount,
    }
    dispatch(appointmentSearchSlice.actions.updatedStatus(state))
    try {
      const statusType: string =
        viewType === 'list' ? 'booked' : 'fulfilled,cancelled'

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        '_include:iterate': 'Appointment:actor',
        _include: 'Appointment:slot',
        'status:': viewType === 'list' ? 'booked' : status,
        _revinclude: 'Encounter:appointment',

        _count: 70,
        _sort: '-_lastUpdated',
      }
      if (selectedDate) {
        selectedDate?.setSeconds(new Date().getSeconds())
        searchParameters.date = `ge${moment(selectedDate)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ssZ')}`
      }
      const currentRole = getUserCurrentRole()
      const pract: R4.IPractitioner = getCurrentUserPractitionerDetails()
      if (isDoctor()) {
        searchParameters.practitioner = `Practitioner/${pract.id}`
      } else if (selectedDoctors && selectedDoctors.length > 0) {
        let doctorsList: string = ''
        selectedDoctors.forEach((item) => {
          doctorsList = `${
            doctorsList + item.roleObject.practitioner?.reference
          },`
        })

        searchParameters.practitioner = doctorsList
      }

      if (selectedSpecialization && selectedSpecialization.length > 0) {
        const specializationString: string = getSpecializationsString(
          selectedSpecialization
        )
        searchParameters._filter = `specialty in ${specializationString}`
      }
      let firstName: string = ''
      let middleName: string = ''
      let lastName: string = ''
      if (name) {
        if (name.includes(' ')) {
          const localNameArr: string[] = name.split(' ')
          if (localNameArr.length > 2) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            middleName = localNameArr[2]
            lastName = localNameArr[1]
            firstName = localNameArr[0]
          } else if (localNameArr.length === 2) {
            lastName = localNameArr[1]
            firstName = localNameArr[0]
          } else {
            firstName = name
          }
        } else {
          firstName = name ?? ''
        }
      }
      //   if (pageState === undefined) {
      //     searchParameters._count = 20
      //   }

      axios.CancelToken.source()
      if (cancelTokenStore.has('ipdMainSearchControlToken')) {
        const controlTokenForSearch: CancelTokenSource = cancelTokenStore.get(
          'ipdMainSearchControlToken'
        )
        controlTokenForSearch.cancel('new param added')
        cancelTokenStore.delete('ipdMainSearchControlToken')
      }
      cancelTokenStore.set(
        'ipdMainSearchControlToken',
        axios.CancelToken.source()
      )
      let response: any
      if (pageState === undefined) {
        response = await fhirClient.doGetResource(
          `/Appointment?_include:iterate=HealthcareService:healthcare-service-billing&_revinclude:iterate=PaymentReconciliation:payment-reconciliation-app&patient:Patient.name:contains=${firstName}&patient:Patient.name:contains=${middleName}&patient:Patient.name:contains=${lastName}`,
          searchParameters,
          (
            cancelTokenStore.get(
              'ipdMainSearchControlToken'
            ) as CancelTokenSource
          ).token
        )
      } else if (pageState.length === 0) {
        response = await fhirClient.doGetResource(
          `/Appointment?_include:iterate=HealthcareService:healthcare-service-billing&_revinclude:iterate=PaymentReconciliation:payment-reconciliation-app&patient:Patient.name:contains=${firstName}&patient:Patient.name:contains=${middleName}&patient:Patient.name:contains=${lastName}`,
          searchParameters,
          (
            cancelTokenStore.get(
              'ipdMainSearchControlToken'
            ) as CancelTokenSource
          ).token
        )
      } else {
        response = await fhirClient.doGetResource(
          `?${pageState}`,
          searchParameters
        )
      }

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp) {
        if (response.entry && response.entry.length > 0) {
          const link = response.link ?? []
          let linkFinal: string = ''
          if (link.length > 0) {
            for (let i = 0; i < link.length; i++) {
              if (link[i].relation === 'next') {
                const urlData = link[i].url
                if (urlData) {
                  linkFinal = urlData.split('?')[1]
                }
              }
            }
          }

          const fhirAppointments: FhirAppointmentDetail[] =
            getExpandedAppointmentFromBundle(response, name)
          fhirAppointments.sort((a, b) => moment(a.start).diff(b.start))

          const data = [...(existingAppointment ?? []), ...fhirAppointments]
          const finalAppointmentList = getAppointmentList(data, status)

          //   state.resultsAvailable = true
          //   state.searchingAppointments = false
          //   state.availableAppointments = _.uniqBy(data, 'start').sort((a, b) =>
          //     moment(a.start).diff(b.start)
          //   )
          //   //   state.noResultsAvailable = false
          //   state.errorReason = undefined
          //   state.pageState = linkFinal
          //   state.recordsCount = response.total
          //   state.existingDateWiseData = data
          //   state.errorWhileSearchingAppointments = false
          dispatch(
            appointmentSearchSlice.actions.updatedStatus({
              ...state,
              resultsAvailable: true,
              searchingAppointments: false,
              noResultsAvailable: false,
              errorWhileSearchingAppointments: false,
              pageState: linkFinal,
              recordsCount: response.total,
              existingDateWiseData: getAppointmentList(data, status),
              availableAppointments: _.uniqBy(
                finalAppointmentList,
                'start'
              ).sort((a, b) => moment(a.start).diff(b.start)),
            })
          )
        } else {
          const errorSearchDoctor: AppointmentSearchStatus = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            appointmentSearchSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      //   const errorSearchDoctor: AppointmentSearchStatus = {
      //     searchingAppointments: false,
      //     errorWhileSearchingAppointments: true,
      //     resultsAvailable: false,
      //     errorReason: 'searching',
      //   }
      //   dispatch(appointmentSearchSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export async function getReports(
  appointment: FhirAppointmentDetail
): Promise<FhirAppointmentDetail> {
  const appointmentData: FhirAppointmentDetail = {
    ...appointment,
  }
  const reportsFiles: R4.IDocumentReference[] = []
  const diagnosticReport: R4.IDiagnosticReport[] = []
  let referredEncounter: R4.IEncounter[] = []
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  if (appointmentData.appointment.basedOn) {
    if (appointmentData.appointment.basedOn[0].reference) {
      const serviceRequestData =
        appointmentData.appointment.basedOn[0].reference.split('/')

      const newResponse =
        await fhirClient.doGetResourceForAppointmentWithIncludeIterate(
          `/ServiceRequest?_id=${serviceRequestData[1]}&_include=ServiceRequest:encounter`,
          ''
        )
      if (newResponse && newResponse.entry && newResponse.entry.length > 0) {
        referredEncounter = getEncounterFromBundle(newResponse as R4.IBundle)
        if (referredEncounter.length > 0) {
          const searchParametersOld: any = {
            encounter: referredEncounter[0].id ?? '',
            _sort: '-_lastUpdated',
          }
          let reportResponse: any

          reportResponse =
            await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformForReport(
              '/DiagnosticReport?category:missing=true&based-on:missing=false',
              referredEncounter[0].appointment
                ? referredEncounter[0].appointment[0].id ?? ''
                : '',
              searchParametersOld
            )

          logger.info('Org  Response')
          logger.info(reportResponse)
          if (
            reportResponse &&
            reportResponse.entry &&
            reportResponse.entry.length > 0
          ) {
            for (let i = 0; i < reportResponse.entry.length; i++) {
              diagnosticReport.push(
                reportResponse.entry[i].resource as R4.IDiagnosticReport
              )
            }
          } else {
            reportResponse =
              await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
                '/DocumentReference?type=11502-2&status=current',
                referredEncounter[0].appointment
                  ? referredEncounter[0].appointment[0].id ?? ''
                  : '',
                searchParametersOld
              )

            if (
              reportResponse &&
              reportResponse.entry &&
              reportResponse.entry.length > 0
            ) {
              for (let i = 0; i < reportResponse.entry.length; i++) {
                reportsFiles.push(
                  reportResponse.entry[i].resource as R4.IDocumentReference
                )
              }
            }
          }
        }
      }
    }
  }
  if (
    getAppointmentIdForFollowUp(
      appointmentData.appointment.supportingInformation ?? []
    ).length > 0
  ) {
    const serviceRequestData = getAppointmentIdForFollowUp(
      appointmentData.appointment.supportingInformation ?? []
    )

    const appointmentArray = await getAppointmentId(
      appointmentData.appointment.id ?? ''
    )

    for (let j = 0; j < appointmentArray.length; j++) {
      if (appointmentArray[j] !== appointmentData.appointment.id) {
        const newResponse =
          await fhirClient.doGetResourceForAppointmentWithIncludeIterate(
            `/Appointment?_id=${appointmentArray[j]}&_revinclude=Encounter:appointment`,
            ''
          )
        if (newResponse && newResponse.entry && newResponse.entry.length > 0) {
          referredEncounter = getEncounterFromBundle(newResponse as R4.IBundle)

          if (referredEncounter.length > 0) {
            const searchParametersOld: any = {
              encounter: referredEncounter[0].id ?? '',
              _sort: '-_lastUpdated',
            }

            const reportResponse =
              await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformForReport(
                '/DiagnosticReport?category:missing=true&based-on:missing=false',
                referredEncounter[0].appointment
                  ? referredEncounter[0].appointment[0].id ?? ''
                  : '',
                searchParametersOld
              )

            logger.info('Org  Response')
            logger.info(reportResponse)
            if (
              reportResponse &&
              reportResponse.entry &&
              reportResponse.entry.length > 0
            ) {
              for (let i = 0; i < reportResponse.entry.length; i++) {
                diagnosticReport.push(
                  reportResponse.entry[i].resource as R4.IDiagnosticReport
                )
              }
            } else {
              const docResponse =
                await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
                  '/DocumentReference?type=11502-2&status=current',
                  referredEncounter[0].appointment
                    ? referredEncounter[0].appointment[0].id ?? ''
                    : '',
                  searchParametersOld
                )

              if (
                docResponse !== undefined &&
                docResponse.entry &&
                docResponse.entry.length > 0
              ) {
                for (let i = 0; i < docResponse.entry.length; i++) {
                  reportsFiles.push(
                    docResponse.entry[i].resource as R4.IDocumentReference
                  )
                }
              }
            }
          }
        }
      }
    }
  }
  if (appointmentData.encounter) {
    const searchParameters: any = {
      encounter: appointment.encounter ? appointment.encounter.id ?? '' : '',
      _sort: '-_lastUpdated',
    }
    let response: any

    response =
      await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformForReport(
        '/DiagnosticReport?category:missing=true&based-on:missing=false',
        appointment.appointment.id!,
        searchParameters
      )

    logger.info('Org  Response')
    logger.info(response)

    if (response && response.entry && response.entry.length > 0) {
      for (let i = 0; i < response.entry.length; i++) {
        diagnosticReport.push(
          response.entry[i].resource as R4.IDiagnosticReport
        )
      }
    } else {
      response =
        await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
          '/DocumentReference?type=11502-2&status=current',
          appointment.appointment.id!,
          searchParameters
        )

      if (response && response.entry && response.entry.length > 0) {
        for (let i = 0; i < response.entry.length; i++) {
          reportsFiles.push(response.entry[i].resource as R4.IDocumentReference)
        }
      }
    }
  }

  if (appointment.appointment.id && appointment.encounter === undefined) {
    const searchParameters: any = {
      _sort: '-_lastUpdated',
    }

    const docResponseList =
      await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
        `/DocumentReference?related=Appointment/${appointment.appointment.id}&type=11502-2&status=current`,
        appointment.appointment.id!,
        searchParameters
      )

    if (
      docResponseList &&
      docResponseList.entry &&
      docResponseList.entry.length > 0
    ) {
      for (let i = 0; i < docResponseList.entry.length; i++) {
        reportsFiles.push(
          docResponseList.entry[i].resource as R4.IDocumentReference
        )
      }
    }
  }

  if (reportsFiles.length > 0) {
    appointmentData.reports = reportsFiles
  }

  if (diagnosticReport.length > 0) {
    appointmentData.diagnosticReport = diagnosticReport.sort((a, b) =>
      a.code &&
      a.code.coding &&
      a.code.coding[0]!.display! > b.code &&
      b.code.coding &&
      b.code.coding[0]!.display!
        ? 1
        : b.code &&
          b.code.coding &&
          b.code.coding[0]!.display! > a.code &&
          a.code.coding &&
          a.code.coding[0]!.display!
        ? -1
        : 0
    )
  }

  return appointmentData
}

export async function getAppointmentId(
  appointmentId: string
): Promise<string[]> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const newResponse =
    await fhirClient.doGetResourceForAppointmentWithIncludeIterate(
      `/Appointment?_sort=-_id&_id=${appointmentId}&_include:iterate=Appointment:supporting-info`,
      ''
    )
  let appointmentDataArr: string[] = []
  if (newResponse && newResponse.entry && newResponse.entry.length > 0) {
    appointmentDataArr = getAppointmentArrayFromBundle(
      newResponse as R4.IBundle
    )
  }

  return appointmentDataArr
}

export async function getPaymentUpdate(
  appointment: FhirAppointmentDetail
): Promise<FhirAppointmentDetail> {
  const appointmentData: FhirAppointmentDetail = {
    ...appointment,
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const newResponse =
    await fhirClient.doGetResourceForAppointmentWithIncludeIterate(
      `/PaymentReconciliation?payment-reconciliation-app=${appointment
        .appointment.id!}`,
      ''
    )
  if (newResponse && newResponse.entry && newResponse.entry.length > 0) {
    const observationData: R4.IPaymentReconciliation[] =
      newResponse.entry?.map(
        (item: any) => item.resource as R4.IPaymentReconciliation
      ) ?? []
    if (observationData.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      appointmentData.paymentReconcilation = observationData[0]
    }
  }

  return appointmentData
}

export async function getPaymentUpdateForLabOrder(
  appointment: FhirLabOrderDetail
): Promise<FhirLabOrderDetail> {
  const appointmentData: FhirLabOrderDetail = {
    ...appointment,
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const newResponse =
    await fhirClient.doGetResourceForAppointmentWithIncludeIterate(
      `/PaymentReconciliation?_id=${appointmentData.paymentReconilation!.id!}`,
      ''
    )
  if (newResponse && newResponse.entry && newResponse.entry.length > 0) {
    const observationData: R4.IPaymentReconciliation[] =
      newResponse.entry?.map(
        (item: any) => item.resource as R4.IPaymentReconciliation
      ) ?? []
    if (observationData.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      appointmentData.paymentReconilation = observationData[0]
    }
  }

  return appointmentData
}

export async function getPreviousAppointmentCount(
  appointment: FhirAppointmentDetail
): Promise<FhirAppointmentDetail> {
  const appointmentData: FhirAppointmentDetail = {
    ...appointment,
  }
  let referredEncounter: R4.IEncounter[] = []
  let endDate: string = ''
  if (appointment.appointment.start) {
    const startDate = moment(appointmentData.appointment.start)
    const actualStart = appointmentData.appointment.start
    const endDateData = startDate.subtract(1, 'days')
    endDate = moment(endDateData).toISOString()
    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const newResponse =
      await fhirClient.doGetResourceForAppointmentWithIncludeIterate(
        `/Appointment?_sort=-_id&patient=Patient/${appointmentData.patient
          .id!}&status=booked&date=le${actualStart}&_revinclude=Encounter:appointment&practitioner=Practitioner/${appointmentData
          .practitionerDetail.practitioner.id!}`,
        ''
      )

    if (newResponse && newResponse.entry && newResponse.entry.length > 0) {
      referredEncounter = getEncounterFromBundleForList(
        newResponse as R4.IBundle,
        appointmentData.encounter
      )
    }
  }
  if (referredEncounter.length > 0) {
    appointmentData.encounterCount = referredEncounter.length
  } else {
    appointmentData.notFound = true
  }

  return appointmentData
}

export async function getPreviousAppointmentList(
  appointment: FhirAppointmentDetail
): Promise<FhirPendingAppointmentDetails[]> {
  const appointmentData: FhirAppointmentDetail = {
    ...appointment,
  }

  let pendingAppointmentList: FhirPendingAppointmentDetails[] = []

  let endDate: string = ''
  if (appointment.appointment.start) {
    const startDate = moment(appointmentData.appointment.start)
    const actualStart = appointmentData.appointment.start
    const endDateData = startDate.subtract(1, 'days')
    endDate = moment(endDateData).toISOString()
    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const newResponse =
      await fhirClient.doGetResourceForAppointmentWithIncludeIterate(
        `/Appointment?_sort=-_id&patient=Patient/${appointmentData.patient
          .id!}&status=booked&date=le${actualStart}&_revinclude=Encounter:appointment&practitioner=Practitioner/${appointmentData
          .practitionerDetail.practitioner
          .id!}&_include:iterate=Appointment:actor`,
        ''
      )

    if (newResponse && newResponse.entry && newResponse.entry.length > 0) {
      pendingAppointmentList = getExpandedAppointmentFromBundlePendingList(
        newResponse as R4.IBundle,
        appointmentData.appointment.id ?? ''
      )
    }
  }

  return pendingAppointmentList
}

export async function FinishAppointment(
  appointment: FhirPendingAppointmentDetails
): Promise<boolean> {
  let result: boolean = false
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const bundleObject: R4.IBundle | undefined =
    createBundleObjectForEndVisit(appointment)
  const networkResponse = await fhirClient.doCreateFHIRTransaction(
    '',
    bundleObject
  )
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(networkResponse)

  if (relatedFhirDecodeRes._tag === 'Left') {
    result = false
  } else {
    result = true
  }

  return result
}

function createBundleObjectForEndVisit(
  appointment: FhirPendingAppointmentDetails
): R4.IBundle | undefined {
  if (appointment.encounter) {
    const { encounter } = appointment
    encounter.status = R4.EncounterStatusKind._finished
    const appointmentObj: R4.IAppointment = appointment.appointment
    appointmentObj.status = R4.AppointmentStatusKind._fulfilled
    const appointmentMatchString: string = `W/${JSON.stringify(
      appointmentObj.meta?.versionId ?? ' '
    )}`
    const encounterMatchString: string = `W/${JSON.stringify(
      encounter.meta?.versionId ?? ' '
    )}`
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${encounter.resourceType}/${encounter.id}`,
          request: {
            ifMatch: encounterMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${encounter.resourceType}/${encounter.id}`,
          },
          resource: encounter,
        },
        {
          fullUrl: `${appointmentObj.resourceType}/${appointmentObj.id}`,
          request: {
            ifMatch: appointmentMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${appointmentObj.resourceType}/${appointmentObj.id}`,
          },
          resource: appointmentObj,
        },
      ],
    }
    return requestBundle
  }
  return {
    resourceType: 'Bundle',
  }
}

export default appointmentSearchSlice.reducer
