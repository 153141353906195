import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@material-ui/icons'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { DateWiseVitals } from 'models/dateWiseVitals'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestVitalDetailsOfPatientIPD } from 'redux/ipd/vitalsDetailsIpd/vitalsDetailSliceIpd'
import { RootState } from 'redux/rootReducer'
import { getTimeWiseVitals } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { EmptyTile } from '../consultation/objective/tiles/emptyTile'
import { SplitVitalTile } from '../consultation/objective/tiles/splitVitalTile'
import { VitalsDataTile } from '../consultation/objective/tiles/vitalDataTile'

interface VitalsProp {
  patient: R4.IPatient
  ipdDetails: R4.IServiceRequest
}

export const VitalsTile: React.FC<VitalsProp> = ({
  patient,
  ipdDetails,
}: VitalsProp) => {
  const { t } = useTranslation(['common'])

  const dispatch = useDispatch()
  const vitalsDetailsSliceIpd = useSelector(
    (state: RootState) => state.vitalsDetailsSliceIpd
  )
  const [groupVitals, setGroupVitals] = useState<DateWiseVitals[]>([])
  useEffect(() => {
    if (ipdDetails && patient) {
      dispatch(requestVitalDetailsOfPatientIPD(patient, ipdDetails.id!))
    }
  }, [dispatch, ipdDetails, patient])

  useEffect(() => {
    if (
      vitalsDetailsSliceIpd.resultsAvailable &&
      vitalsDetailsSliceIpd.dateWiseVitals
    ) {
      updateVitals(vitalsDetailsSliceIpd.dateWiseVitals)
    }
  }, [vitalsDetailsSliceIpd])

  function updateVitals(vitalList: DateWiseVitals[]) {
    const results: DateWiseVitals[] = []
    for (let i = 0; i < vitalList.length; i++) {
      results.push({
        date: vitalList[i].date,
        vitals: vitalList[i].vitals,
        checked: true,
      })
    }
    setGroupVitals(results)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: DateWiseVitals[] = [...groupVitals]
    values[index].checked = rate
    setGroupVitals(values)
  }

  return (
    <Box>
      <Grid
        container
        direction='column'
        style={{ flexWrap: 'nowrap' }}
        spacing={2}
      >
        <Grid item>
          <Grid container direction='row' id='vitals'>
            <Box py={1}>
              <Typography
                variant='subtitle2'
                style={{ color: '#4D4D4D', textTransform: 'uppercase' }}
              >
                {' '}
                {t('labelCommon:Vitals')}{' '}
              </Typography>
            </Box>{' '}
          </Grid>

          {vitalsDetailsSliceIpd.searchingConditions && (
            <Grid item>
              <CircularProgress size={15} />
            </Grid>
          )}
          {vitalsDetailsSliceIpd.noResultsAvailable && (
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                {' '}
                No data available
              </Typography>
            </Grid>
          )}
          {vitalsDetailsSliceIpd.errorWhileSearchingProcedures && (
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                {vitalsDetailsSliceIpd.errorReason ??
                  'Error while fetching Vitals'}
              </Typography>
            </Grid>
          )}
          {vitalsDetailsSliceIpd.resultsAvailable &&
            vitalsDetailsSliceIpd.dateWiseVitals && (
              <Grid item style={{ padding: 0, margin: 0 }} xs={12}>
                <Box
                  flexGrow
                  width='100%'
                  display='flex'
                  flexDirection='column'
                >
                  {groupVitals.map((e, index: number) => (
                    <Box
                      flexGrow
                      width='100%'
                      display='flex'
                      flexDirection='column'
                      key={`1${e.date}`}
                    >
                      <Box
                        paddingX={1}
                        borderRadius={2}
                        style={{
                          backgroundColor: 'lightGrey',
                        }}
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        height={40}
                        paddingY={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow={1}
                          alignItems='center'
                        >
                          <Typography
                            variant='subtitle1'
                            style={{
                              color: 'black',

                              fontWeight: 'bold',
                            }}
                          >
                            Recorded on {moment(e.date).format('Do MMM YYYY')}
                          </Typography>
                        </Box>
                        {getTimeWiseVitals(e.vitals ?? []).map((e1) => (
                          <Grid item xs={3} key={e1.time ?? ''}>
                            <Grid container direction='row'>
                              <Grid item xs={8}>
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                  height={30}
                                  //   paddingY={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow={1}
                                    alignItems='center'
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      {`${e1.time} ${e1.timeFormat}`}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}

                        <Box
                          justifyContent='flex-end'
                          display='flex'
                          flexGrow={1}
                          alignItems='center'
                        >
                          <Box px={1}>
                            <Tooltip title=''>
                              <IconButton
                                aria-label='collapse_order_type'
                                size='small'
                                onClick={() => {
                                  handleCollapseForPanel1(!e.checked, index)
                                }}
                              >
                                {e.checked && <ArrowDropUpOutlined />}
                                {!e.checked && <ArrowDropDownOutlined />}
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                      <Collapse
                        in={e.checked}
                        style={{
                          width: '100%',
                        }}
                      >
                        <Box
                          flexGrow
                          width='100%'
                          display='flex'
                          flexDirection='column'
                        >
                          <Grid
                            container
                            direction='row'
                            style={{
                              backgroundColor: kDialogueBackground,
                            }}
                          >
                            <Grid item xs={3}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Parameter Name
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  justifyContent='flex-end'
                                  flexGrow
                                  paddingRight={0.5}
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '30px',

                                      width: '2px',
                                      //   backgroundColor: 'white',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            {getTimeWiseVitals(e.vitals ?? []).map((e1) => (
                              <Grid item xs={3} key={e1.time ?? ''}>
                                <Grid container direction='row'>
                                  <Grid item xs={8}>
                                    <Box
                                      paddingX={0.5}
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      height={30}
                                      //   paddingY={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow={1}
                                        alignItems='center'
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Value
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>

                          <Box
                            flexGrow
                            width='100%'
                            display='flex'
                            flexDirection='row'
                          >
                            <Grid container direction='row'>
                              <Grid item xs={3}>
                                <Box
                                  flexGrow
                                  width='100%'
                                  display='flex'
                                  flexDirection='column'
                                  paddingRight={0.5}
                                >
                                  <SplitVitalTile />
                                </Box>
                              </Grid>
                              {getTimeWiseVitals(e.vitals ?? []).map((e1) => (
                                <Grid
                                  item
                                  xs={
                                    getTimeWiseVitals(e.vitals ?? []).length ===
                                    1
                                      ? 9
                                      : 3
                                  }
                                  key={e1.time ?? ''}
                                >
                                  <Box
                                    flexGrow
                                    width='100%'
                                    display='flex'
                                    flexDirection='column'
                                  >
                                    <Box>
                                      <VitalsDataTile obsData={e1.vitals} />
                                    </Box>
                                  </Box>
                                </Grid>
                              ))}
                              {getTimeWiseVitals(e.vitals ?? []).length ===
                                2 && (
                                <Grid item xs={3}>
                                  <Box
                                    flexGrow
                                    width='100%'
                                    display='flex'
                                    flexDirection='column'
                                    paddingRight={0.5}
                                    paddingTop={0.1}
                                  >
                                    <EmptyTile />
                                  </Box>
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        </Box>
                      </Collapse>
                    </Box>
                  ))}
                </Box>
              </Grid>
            )}
        </Grid>
      </Grid>
    </Box>
  )
}
