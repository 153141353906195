import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { requestMenstrualOfPatient } from 'redux/fhirMedicalResources/ipd/mensurationSearch/mensurationSearchSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { sleep } from 'utils/dateUtil'
import { getVitalsObservationObjectForOPD } from 'utils/fhirResoureHelpers/observationHelpers'
// import { requestVitalDetailsOfPatientIPD } from '../vitalsDetailsIpd/vitalsDetailSliceIpd'
import { AddMensurationAddStatus } from './addMensurationStatus'

const initialState: AddMensurationAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const menstrualAddSliceOPD = createSlice({
  name: 'menstrualAddSliceOPD',
  initialState,
  reducers: {
    updateAddVitalsStatus(
      state,
      action: PayloadAction<AddMensurationAddStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addedVitalsBundle = action.payload.addedVitalsBundle
    },

    resetAddVitalsDetails(
      state,
      action: PayloadAction<AddMensurationAddStatus>
    ) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addedVitalsBundle = initialState.addedVitalsBundle
    },
  },
})

export const addMenstrualAddOPD =
  (
    appointment: FhirAppointmentDetail,
    occupation?: R4.ICoding,
    durtion?: number,
    clots?: R4.ICoding,
    leukorrhea?: R4.ICoding,
    notes?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AddMensurationAddStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(menstrualAddSliceOPD.actions.updateAddVitalsStatus(addingState))

    try {
      const bundleObject: R4.IBundle = createBundleObjectForObservations(
        appointment,
        occupation,
        durtion,
        clots,
        leukorrhea,
        notes
      )

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        bundleObject
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        await sleep(5000)
        dispatch(
          requestMenstrualOfPatient(
            appointment.appointment.id!,
            appointment.patient
          )
        )
        dispatch(showSuccessAlert('Menstrual History Added Successfully'))

        dispatch(
          menstrualAddSliceOPD.actions.updateAddVitalsStatus(addingState)
        )
      } else {
        const errorCreatePersonState: AddMensurationAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while adding Menstrual',
        }
        dispatch(
          menstrualAddSliceOPD.actions.updateAddVitalsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: AddMensurationAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while adding Menstrual',
      }
      dispatch(
        menstrualAddSliceOPD.actions.updateAddVitalsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetMesturationDetailsAdd = () => (dispatch: AppDispatch) => {
  dispatch(menstrualAddSliceOPD.actions.resetAddVitalsDetails(initialState))
}

function createBundleObjectForObservations(
  appointment: FhirAppointmentDetail,
  occupation?: R4.ICoding,
  duration?: number,
  clots?: R4.ICoding,
  leukorrhea?: R4.ICoding,
  notes?: string
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const encounterRef: R4.IReference = {
    reference: `Encounter/urn:uuid:1232323232324`,
    type: 'Encounter',
  }

  if (occupation != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForOPD(appointment, encounterRef),
    }
    observationObject.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationSocialHistory',
      ],
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Menstrual',
      coding: [
        {
          code: '248965005',
          display: 'Menstrual',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'social-history',
            display: 'Social History',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: occupation.display ?? '',
      coding: [occupation],
    }
    const practitionerRoleDetail: R4.IPractitionerRole =
      getCurrentUserPractitionerRoleDetails()

    if (duration) {
      const durationCoding: R4.ICoding = {
        code: '161720007',
        display: duration.toString(),
        system: 'http://snomed.info/sct',
      }
      if (observationObject.valueCodeableConcept) {
        if (observationObject.valueCodeableConcept.coding) {
          observationObject.valueCodeableConcept.coding.push(durationCoding)
        }
      }
    }

    if (clots) {
      if (observationObject.valueCodeableConcept) {
        if (observationObject.valueCodeableConcept.coding) {
          observationObject.valueCodeableConcept.coding.push(clots)
        }
      }
    }

    if (leukorrhea) {
      if (observationObject.valueCodeableConcept) {
        if (observationObject.valueCodeableConcept.coding) {
          observationObject.valueCodeableConcept.coding.push(leukorrhea)
        }
      }
    }
    const practitioner: R4.IPractitioner = getCurrentUserPractitionerDetails()
    if (notes && notes.length > 0) {
      observationObject.note = [
        {
          authorReference: {
            reference: `${practitioner.resourceType}/${practitioner.id}`,
          },
          text: notes,
          time: new Date().toISOString(),
        },
      ]
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  return requestBundle
}

export default menstrualAddSliceOPD.reducer
