import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import { getLabOfferingDetailsFromBundle } from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { logger } from 'utils/logger'
import { LabOfferingSearchStatus } from './labOfferingSearchStatusTypes'

const initialState: LabOfferingSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const labOfferingSearchSlice = createSlice({
  name: 'labOfferingSearchSlice',
  initialState,
  reducers: {
    searchingDoctorDetails(
      state,
      action: PayloadAction<LabOfferingSearchStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false

      state.labOfferings = undefined
    },

    searchResults(state, action: PayloadAction<LabOfferingSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true

      state.labOfferings = action.payload.labOfferings
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<LabOfferingSearchStatus>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false

      state.labOfferings = undefined
    },

    errorWhileSearching(state, action: PayloadAction<LabOfferingSearchStatus>) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false

      state.labOfferings = undefined
    },
    resetLabTestSearch(state, action: PayloadAction<LabOfferingSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.labOfferings = undefined
    },
  },
})

export const searchLabOfferings =
  (searchString?: string, lionCode?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: LabOfferingSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(labOfferingSearchSlice.actions.searchingDoctorDetails(state))
    try {
      const unitDetails = getCurrentUserUnitReference()!
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _include: '*',
        status: 'active',
        _count: 100,
        _total: 'accurate',
      }
      if (lionCode) searchParameters.identifier = lionCode

      const response: any = await fhirClient.doGetResource(
        `/PlanDefinition?plan-def-organization=Organization/${
          unitDetails.id
        }&title:contains:${searchString ?? ''}`,
        searchParameters
      )
      if (response.entry) {
        if (response.entry.length > 0) {
          const practitionerWithRoleList: LabOfferingDetail[] =
            getLabOfferingDetailsFromBundle(response, []) ?? []
          practitionerWithRoleList.sort((a, b) =>
            (a.planDefinition.title
              ? a.planDefinition.title.length === 0
                ? a.planDefinition.name ?? ''
                : a.planDefinition.title
              : a.planDefinition.name ?? ''
            ).localeCompare(
              b.planDefinition.title
                ? b.planDefinition.title
                : b.planDefinition.name ?? ''
            )
          )
          state.labOfferings = practitionerWithRoleList
          state.resultsAvailable = true
          state.searching = false
          state.error = false
          dispatch(labOfferingSearchSlice.actions.searchResults(state))
          return
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(labOfferingSearchSlice.actions.noDataFoundForSearch(state))
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = false
      state.noResultsAvailable = true
      dispatch(labOfferingSearchSlice.actions.noDataFoundForSearch(state))
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(labOfferingSearchSlice.actions.errorWhileSearching(state))
    }
  }

export const searchLabOfferingsForPackage =
  (searchString?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: LabOfferingSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(labOfferingSearchSlice.actions.searchingDoctorDetails(state))
    try {
      const unitDetails = getCurrentUserUnitReference()!
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _include: '*',
        status: 'active',
        identifier: `${unitDetails.id}`,
        _count: 100,
        _total: 'accurate',
        type: 'test',
      }
      if (searchString) searchParameters.title = searchString

      const response: any = await fhirClient.doGetResource(
        '/PlanDefinition',
        searchParameters
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const labOfferingsResponse: R4.IBundle = relatedFhirDecodeRes.right
        if (labOfferingsResponse.total) {
          if (labOfferingsResponse.total > 0) {
            if (labOfferingsResponse.entry) {
              const practitionerWithRoleList: LabOfferingDetail[] =
                getLabOfferingDetailsFromBundle(labOfferingsResponse, []) ?? []
              // practitionerWithRoleList.sort((a,b)=>  (a.planDefinition.title ?? '') > (b.planDefinition.title ?? '')
              // ? 1
              // : (b.planDefinition.title ?? '') > (a.planDefinition.title ?? '')
              // ? -1
              // : 0)
              practitionerWithRoleList.sort((a, b) =>
                (a.planDefinition.title
                  ? a.planDefinition.title.length === 0
                    ? a.planDefinition.name ?? ''
                    : a.planDefinition.title
                  : a.planDefinition.name ?? ''
                ).localeCompare(
                  b.planDefinition.title
                    ? b.planDefinition.title
                    : b.planDefinition.name ?? ''
                )
              )
              state.labOfferings = practitionerWithRoleList
              state.resultsAvailable = true
              state.searching = false
              state.error = false
              dispatch(labOfferingSearchSlice.actions.searchResults(state))
              return
            }
            state.resultsAvailable = false
            state.searching = false
            state.error = false
            state.noResultsAvailable = true
            dispatch(labOfferingSearchSlice.actions.noDataFoundForSearch(state))
            return
          }
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(labOfferingSearchSlice.actions.noDataFoundForSearch(state))
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(labOfferingSearchSlice.actions.errorWhileSearching(state))
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(labOfferingSearchSlice.actions.errorWhileSearching(state))
    }
  }

export const searchLabOfferingsforPartnerLab =
  (locationId: string, searchString?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: LabOfferingSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(labOfferingSearchSlice.actions.searchingDoctorDetails(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _include: '*',
        status: 'active',
        _total: 'accurate',
      }
      if (searchString) {
        searchParameters.name = searchString
      }

      const response: any = await fhirClient.doGetResource(
        `/PlanDefinition?partner-lab=${locationId}`,
        searchParameters
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const labOfferingsResponse: R4.IBundle = relatedFhirDecodeRes.right
        if (labOfferingsResponse.total) {
          if (labOfferingsResponse.total > 0) {
            if (labOfferingsResponse.entry) {
              const practitionerWithRoleList: LabOfferingDetail[] =
                getLabOfferingDetailsFromBundle(labOfferingsResponse, []) ?? []
              state.labOfferings = practitionerWithRoleList
              state.resultsAvailable = true
              state.searching = false
              state.error = false
              dispatch(labOfferingSearchSlice.actions.searchResults(state))
              return
            }
            state.resultsAvailable = false
            state.searching = false
            state.error = false
            state.noResultsAvailable = true
            dispatch(labOfferingSearchSlice.actions.noDataFoundForSearch(state))
            return
          }
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(labOfferingSearchSlice.actions.noDataFoundForSearch(state))
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(labOfferingSearchSlice.actions.errorWhileSearching(state))
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(labOfferingSearchSlice.actions.errorWhileSearching(state))
    }
  }

export const resetLabOffering = () => (dispatch: AppDispatch) => {
  dispatch(labOfferingSearchSlice.actions.resetLabTestSearch(initialState))
}

export function getPractitionerRoleObject(item: R4.IPractitionerRole) {
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    name: item.practitioner?.display ?? '',
    gender: '',
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: true,
  }
  return val
}

export default labOfferingSearchSlice.reducer
