import { Box, Grid } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { WelloTab } from './WelloTab'

interface TabProps {
  preSelectedTab?: string
  onTabChange: (selectedTab: string) => void
  menu: string[]
  disabled: boolean
}

export const UploadTabs: React.FC<TabProps> = ({
  preSelectedTab,
  onTabChange,
  menu,
  disabled,
}: TabProps) => {
  const [selectedTab, setSelectedTab] = React.useState(preSelectedTab)
  const { t, i18n } = useTranslation(['common', 'patient'])
  const language = sessionStorage.getItem('lang')
  const [locale, setLocale] = React.useState(
    language !== null ? language : 'en'
  )

  //   useEffect(() => {
  //     i18n.changeLanguage(language ?? '')
  //   }, [i18n, language])

  const handleChange = (selected: string) => {
    if (disabled === false) {
      onTabChange(selected)
      setSelectedTab(selected)
    }
  }

  return (
    <Box display='flex' flexDirection='row' flexGrow={1}>
      <Grid container direction='row'>
        {menu.map((value, i) => (
          <Grid item key={value[i]}>
            <WelloTab
              textTitle={t('labelCommon:' + `${value}`)}
              isSelected={
                t('labelCommon:' + `${selectedTab}`) ===
                t('labelCommon:' + `${value}`)
              }
              onClick={() => handleChange(value)}
            />
          </Grid>
        ))}
      </Grid>
      {/* <Box key={`app_tab_left_${value.name}`} paddingX={1}>
          <AppBarTab
            imgSrc={value.imgSrc}
            textTitle={value.name}
            isSelected={selectedTab === value.name}
            count={value.count}
            onClick={() => handleChange(value.name)}
            displayCount={value.displayCount}
            displayName={value.displayName}
            countDisplayType={value.countDisplayType}
            id={value.id}
          />
        </Box> */}
    </Box>
  )
}
