/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */

import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'

import { kDialogueBackground } from 'configs/styles/muiThemes'
import { StyledTableCell, StyledTableRow } from '../styled-components'
import {
  ProductDetail,
  Sku,
} from '../../../../../../models/medicine-delivery/product-models'
import { ProductHeaderTile } from './productHeader'

interface Props {
  detail: ProductDetail | undefined
  selectedSku: Sku | undefined
  onChangeSkuCallback: (size: Sku) => void
}

export const ProductSkus: React.FC<Props> = ({
  detail,
  selectedSku,
  onChangeSkuCallback,
}: Props) => {
  // NOTE: We cannot show sku preselected, becase multiple skus can be selected overtime
  // const cart = useRecoilValue(cartState);
  // useEffect(() => {
  //   if (!detail) {
  //     return;
  //   }
  //   // Find the item in cart
  //   const item = cart.find((i) => i.product.name === detail.name);
  //   if (!item) {
  //     return;
  //   }
  //   onChangeSkuCallback(item.product.sku);
  // }, [cart, detail]);

  if (!detail) {
    return <></>
  }
  const { skus } = detail
  if (!skus || skus.length === 0) {
    return <></>
  }

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      border={2}
      borderRadius={1}
      p={2}
      borderColor='#ececec'
    >
      <Box width='100%' display='flex' flexDirection='row'>
        <Box width='5%' />
        <ProductHeaderTile />
      </Box>
      {skus.map((s, index) => (
        <Box
          display='flex'
          flexDirection='column'
          width='100%'
          flexGrow={1}
          key={s.id}
        >
          <Box width='100%' display='flex' flexDirection='row'>
            <Box width='5%'>
              {' '}
              <FormControl>
                <RadioGroup
                  id='add_unit_address_choice_rad_group'
                  row
                  aria-label='graphType'
                  value={s.price}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeSkuCallback(s)
                  }}
                >
                  <Box>
                    <FormControlLabel
                      value='clinic'
                      control={
                        <Radio
                          color='primary'
                          checked={selectedSku?.sku === s.sku}
                        />
                      }
                      label=''
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </Box>

            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor:
                  index % 2 === 0 ? 'white' : kDialogueBackground,
              }}
            >
              <Grid item xs={4}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Typography variant='subtitle2'>{s.sku}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        {' '}
                        <Box paddingRight={0.3}>&#x20b9;</Box>
                        <Typography variant='subtitle2'>{s.price}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Typography variant='subtitle2'>
                          {s.discount}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Box paddingRight={0.3}>&#x20b9;</Box>
                        <Typography variant='subtitle2'>
                          {(s.price * (100 - s.discount)) / 100}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Typography variant='subtitle2'>
                          {s.inStock ? 'Yes' : 'No'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ))}
      {/* <TableContainer component={Paper}>
        <Table aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>Unit</StyledTableCell>
              <StyledTableCell align='right'>MRP</StyledTableCell>
              <StyledTableCell align='right'>Discount (%)</StyledTableCell>
              <StyledTableCell align='right'>Final Price</StyledTableCell>
              <StyledTableCell align='right'>In Stock</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {skus.map((s, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  <label>
                    <input
                      type='radio'
                      style={{ marginRight: 5 }}
                      value={s.price}
                      checked={selectedSku?.sku === s.sku}
                      onChange={() => onChangeSkuCallback(s)}
                    />
                    {s.sku}
                  </label>
                </StyledTableCell>
                <StyledTableCell align='right'>{s.price}</StyledTableCell>
                <StyledTableCell align='right'>{s.discount}</StyledTableCell>
                <StyledTableCell align='right'>
                  {(s.price * (100 - s.discount)) / 100}
                </StyledTableCell>
                <StyledTableCell align='right'>
                  {s.inStock ? 'Yes' : 'No'}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
    </Box>
  )
}
