/* eslint-disable no-eval */
import {
  Avatar,
  Box,
  Grid,
  makeStyles,
  Paper,
  Typography,
  Button,
} from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { RootState } from 'redux/rootReducer'
import {
  getSpecializationsList,
  getNameFromHumanName,
  getLastNameFromHumanName,
  getCustomDays,
  getFirstNameFromHumanName,
  getProfilePicPractitionerForDoc,
} from 'utils/fhirResourcesHelper'
import {
  addScheduleDetails,
  resetState,
} from 'redux/fhirSchedule/addAvailability'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  requestForSchedules,
  resetSchdeule,
} from 'redux/schedule/searchSchedule/SearchProviderSchedule'
import {
  isOrgAdmin,
  isUnitAdmin,
  getCurrentUserUnitLocationDetails,
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserPractitionerDetails,
  isAgent,
} from 'services/userDetailsService'
import { ScheduleDetail } from 'models/fhirScheduleDetail'
import { updatingScheduleDetails } from 'redux/schedule/UpdateScheuleHandler/updateSchedule'
import { getBulkScheduleUpdateBundle } from 'utils/fhirResoureHelpers/fhirScheduleDeleteHelper'
import { kBackGround, kBackgroundPaper } from 'configs/styles/muiThemes'
import { showWarningAlert } from 'redux/alertHandler/alertSlice'
import { fetchUser } from 'redux/practitioner/practitionerSearchHandler/PractitionerManagement'
import { PractitionerProfile } from './LeftMenu/PractitionerProfile'
import { PractitionerSelector } from './LeftMenu/PractitionerSelector'
import { CancelSchedule } from './schedule/CancelSchedule'
import { Calendar } from './schedule/Calendar'
import CustomizedDividers, { ToggleOption } from './single_toggleSelector'
import { SelectedFile, WelloFilePicker } from './LeftMenu/WelloFilePicker'

export interface ICalendar {
  onCalendarSelectionChanged?: (
    selectedValues: any[],
    existingSchedules: any[]
  ) => void
  loggedInDoc: PractitionerWithRole
  practitionerRole: R4.IPractitionerRole
  practitioner: R4.IPractitioner
  businessHours: any
  shoWFullWeek: boolean
  role?: string[]
  hiddenDays: number[]
  onDone?: () => void
}

const useStyles = makeStyles((theme) => ({
  Paper: {
    height: '100%',
    maxHeight: 'inherit',
    background: theme.palette.background.default,
    opacity: 1,
    flexGrow: 1,
  },
  docPaper: {
    background: theme.palette.background.default,
    height: '100%',
    maxHeight: '100%',
    opacity: 1,
    flexGrow: 1,
  },
  nextStyle: {
    color: theme.palette.common.black,
  },

  image: {
    width: 60,
    height: 60,
  },
  wrapper: {
    paddingLeft: 10,
    position: 'relative',
  },
}))
const LeftMenu = ['People', 'Cohorts']
const appType = ['In-Person', 'Phone', 'Video']

const menu: ToggleOption[] = [
  { value: 'Profile', displayText: 'Profile' },
  { value: 'workHours', displayText: 'Working Hours' },
]

const menuForAgent: ToggleOption[] = [
  { value: 'Profile', displayText: 'Profile' },
]

export const ScheduleSetting: React.FC<ICalendar> = ({
  onCalendarSelectionChanged,
  loggedInDoc,
  practitionerRole,
  practitioner,
  businessHours,
  shoWFullWeek,
  role,
  hiddenDays,
  onDone,
}: ICalendar) => {
  const staticData = 'My Working Hours'
  const classes = useStyles()
  const dispatch = useDispatch()
  const [selectedMenu, setSelectedMenu] = React.useState(LeftMenu[0])
  const [selectedDoctor, setSelectedDoctor] =
    React.useState<PractitionerWithRole>()
  const [tempDoctor, setTempDoctor] = React.useState<PractitionerWithRole>()
  const [settingsTab, setSelectedSettingsTab] = React.useState(
    staticData.includes('My Working Hours') ? menu[1].value : menu[0].value
  )
  const practitionerManagement = useSelector(
    (state: RootState) => state.practitionerManagement
  )
  const [practObj, setPractObj] = useState<R4.IPractitioner>(
    getCurrentUserPractitionerDetails()
  )
  const [practRoleObj, setPractRoleObj] = useState<R4.IPractitionerRole>(
    getCurrentUserPractitionerRoleDetails()
  )
  const [selectedSchedules, setSelectedSchedules] = React.useState<any>([])
  const [existingSchedules, setExistingSchedules] = React.useState<any>([])
  const [type, setType] = React.useState(appType[0])
  const [newAdd, SetNewAdd] = React.useState(false)
  const [cancel, SetCancel] = React.useState(false)
  const [openPopUp, SetOpenPopup] = React.useState(false)
  const [cancelPopUp, SetCancelPopup] = React.useState(false)
  const [sideMenu, SetSideMenu] = React.useState(false)
  const [showOnReturn, setShowOnReturn] = React.useState(false)
  const selectedProvider = useRef<PractitionerWithRole[]>()
  const [display, setDisplay] = React.useState(true)
  const scheduleData = useRef<ScheduleDetail[]>()
  const [saved, SetSaved] = React.useState(false)
  const scheduleArray: any = []
  const locationDetails = getCurrentUserUnitLocationDetails()
  const unitDetails = getCurrentUserUnitLocationDetails()
  const dayType = getCustomDays(locationDetails.hoursOfOperation ?? [])
  const weekDays: string[] = []
  if (Array.isArray(dayType) && dayType.length) {
    dayType.forEach((item) => {
      if (item.includes('S') || item.includes('Su')) {
        weekDays.push('1')
      }
    })
  }

  //   const description = practitioner.extension ?? []
  //   let profileDescription = ''
  //   if (description[1].valueString !== undefined)
  //     profileDescription = description[1].valueString

  if (onCalendarSelectionChanged) {
    onCalendarSelectionChanged(selectedSchedules, existingSchedules)
  }

  const SearchProviderScheduleSlice = useSelector(
    (state: RootState) => state.SearchProviderScheduleSlice
  )

  const addNew = () => {
    SetNewAdd(true)
    SetSaved(false)
  }

  const cancelSchedule = () => {
    if (selectedSchedules.length > 0) {
      SetCancelPopup(true)
    } else {
      setSelectedSchedules([])
      SetOpenPopup(false)
      SetNewAdd(false)
      setSelectedSettingsTab(menu[0].value)
    }
  }

  const saveSchedule = () => {
    // const locationDetails = getCurrentUserUnitLocationDetails()
    const scheduleList = SearchProviderScheduleSlice.scheduleList ?? []
    if (scheduleList.length === 0 && selectedSchedules.length === 0) {
      dispatch(showWarningAlert('Please select working hours'))
    } else {
      if (loggedInDoc !== undefined)
        dispatch(
          addScheduleDetails(
            locationDetails,
            scheduleList,
            selectedSchedules,
            loggedInDoc,
            type
          )
        )
      setSelectedSchedules([])
      SetNewAdd(false)
      SetSaved(true)
      requestSearchSchedule()
    }
  }

  function onDoctorChange(doctorSelected: any) {
    selectedProvider.current = doctorSelected
    requestSearchSchedule()
  }

  function onDoctorChangeSetting() {
    SetOpenPopup(false)
    SetNewAdd(false)
    setSelectedSettingsTab(menu[0].value)
  }

  function getName(name: string): string {
    const changedName = name.replace('Dr. ', '')
    return changedName
  }

  function requestSearchSchedule() {
    dispatch(requestForSchedules(selectedProvider.current, new Date()))
    scheduleData.current = SearchProviderScheduleSlice.scheduleList
  }

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box
            flexGrow={1}
            flexDirection='column'
            width='64%'
            bgcolor='background.paper'
            height='100%'
          >
            <Paper
              style={{
                backgroundColor: kBackgroundPaper,
                borderRadius: 0,
                position: 'sticky',
                top: 0,
              }}
            >
              <Grid
                container
                direction='row'
                alignContent='space-between'
                justify='space-between'
              >
                <Grid item xs={4}>
                  <Grid container direction='column' justify='flex-start'>
                    <Grid item xs>
                      <Box p={1}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Box display='flex' paddingX={2} paddingY={1}>
                            <WelloFilePicker
                              fileTypeToPick='photo'
                              id='unit_actor_profile_pic'
                              labelOrientation='column'
                              name='profilePic'
                              allowUpdate={false}
                              displaySelectedPhoto='circle'
                              preSelectedFile={{
                                type: 'image',
                                path: getProfilePicPractitionerForDoc(
                                  getCurrentUserPractitionerDetails()
                                ),
                              }}
                              inputProps={{}}
                              onFileChanged={(
                                selectedField: SelectedFile
                              ) => {}}
                              required={false}
                            />
                          </Box>
                          &nbsp;
                          <Grid item xs={7}>
                            <Box
                              display='flex'
                              flexDirection='Column'
                              width='100%'
                            >
                              <Box paddingX={0.5}>
                                <Typography variant='subtitle1' color='primary'>
                                  {' '}
                                  {getFirstNameFromHumanName(
                                    getCurrentUserPractitionerDetails().name ??
                                      []
                                  )}{' '}
                                  {getLastNameFromHumanName(
                                    getCurrentUserPractitionerDetails().name ??
                                      []
                                  )}
                                </Typography>
                              </Box>
                              <Box paddingX={0.5} width='100%'>
                                <Box
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: '11rem',
                                  }}
                                >
                                  <Typography variant='subtitle2'>
                                    {' '}
                                    {getSpecializationsList(
                                      getCurrentUserPractitionerRoleDetails()
                                        .specialty ?? []
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction='column'
                    alignContent='space-between'
                    justify='space-between'
                  >
                    <Grid item xs style={{ marginTop: 17 }}>
                      <Box display='flex' paddingX={2} paddingY={0}>
                        {newAdd === false && settingsTab === menu[0].value && (
                          <CustomizedDividers
                            onSelectionChanged={(value) => {
                              setSelectedSettingsTab(value)
                              setSelectedSchedules([])
                            }}
                            options={isAgent() ? menuForAgent : menu}
                            schedules={selectedSchedules}
                            existingSchedules={existingSchedules}
                            preSelectedValue={settingsTab}
                          />
                        )}
                        {newAdd === false && settingsTab === menu[1].value && (
                          <CustomizedDividers
                            onSelectionChanged={(value) => {
                              setSelectedSettingsTab(value)
                              setSelectedSchedules([])
                            }}
                            options={isAgent() ? menuForAgent : menu}
                            schedules={selectedSchedules}
                            existingSchedules={existingSchedules}
                            preSelectedValue={settingsTab}
                          />
                        )}

                        {newAdd === true && (
                          <CustomizedDividers
                            onSelectionChanged={(value) => {
                              setSelectedSettingsTab(value)
                              setSelectedSchedules([])
                            }}
                            options={isAgent() ? menuForAgent : menu}
                            schedules={selectedSchedules}
                            existingSchedules={existingSchedules}
                            preSelectedValue={settingsTab}
                          />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container justify='flex-end' style={{ marginTop: 13 }}>
                    <Box display='flex' paddingX={2} paddingY={1}>
                      {newAdd === false && settingsTab === menu[1].value && (
                        <Box paddingX={1}>
                          <Button
                            variant='contained'
                            size='small'
                            color='primary'
                            onClick={() => {
                              addNew()
                            }}
                            id='editButton'
                          >
                            <Typography variant='button'>
                              {(SearchProviderScheduleSlice.scheduleList ??
                                [].length > 0) ||
                              selectedSchedules.length > 0
                                ? 'Edit'
                                : 'Add Working Hours'}
                            </Typography>
                          </Button>
                        </Box>
                      )}

                      {newAdd === true && settingsTab === menu[1].value && (
                        <Box paddingX={1} paddingY={1} display='flex'>
                          <Box m={0.4}>
                            <Button
                              variant='contained'
                              size='small'
                              style={{
                                backgroundColor: kBackGround,
                                color: kBackgroundPaper,
                              }}
                              onClick={() => {
                                cancelSchedule()
                              }}
                              id='cancel'
                            >
                              <Typography variant='button'>Cancel</Typography>
                            </Button>
                          </Box>
                          <Box m={0.4}>
                            <Button
                              variant='contained'
                              size='small'
                              color='primary'
                              onClick={() => {
                                saveSchedule()
                              }}
                              id='saveButton'
                            >
                              <Typography variant='button'>Save</Typography>
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            {settingsTab === menu[0].value && (
              <PractitionerProfile
                practitionerRole={practRoleObj}
                practitioner={practObj}
                onDone={(
                  practObjData: R4.IPractitioner,
                  practObjRole: R4.IPractitionerRole
                ) => {
                  dispatch(
                    fetchUser(getCurrentUserPractitionerDetails().id ?? '')
                  )
                  setPractObj(practObjData)
                  setPractRoleObj(practObjRole)
                }}
              />
            )}
            {settingsTab === menu[1].value && (
              <Box
                // px={1}
                id='calendarView'
                style={{ overflow: 'auto', maxHeight: '100%' }}
              >
                <div>
                  {/* {saved === false &&( */}
                  <Calendar
                    onCalendarSelectionChanged={(
                      selectedValues: any[],
                      existSchedules: any[]
                    ) => {
                      SetSaved(false)
                      requestSearchSchedule()
                      dispatch(resetSchdeule())
                      setSelectedSchedules(selectedValues)
                      if (existSchedules.length > 0) {
                        existSchedules.forEach((event) => {
                          scheduleArray.push(event)
                        })
                        setExistingSchedules(scheduleArray)
                      }

                      SetCancel(true)
                    }}
                    onSelectAppointmentType={(appointmentType: string) => {
                      setType(appointmentType)
                    }}
                    editable={newAdd}
                    selectedDoctor={loggedInDoc}
                    discard={cancel}
                    // selectedSchedules={selectedSchedules}
                    scheduleExist={existingSchedules.length > 0}
                    saved={saved}
                    businessHoursData={businessHours}
                    businessHours={businessHours}
                    shoWFullWeek={shoWFullWeek}
                    role={role}
                    hiddenDays={hiddenDays}
                  />
                </div>
              </Box>
            )}
          </Box>

          {openPopUp === true && (
            <CancelSchedule
              open={openPopUp}
              onClose={() => {
                SetOpenPopup(false)
                SetCancel(false)
                setSelectedSettingsTab(menu[1].value)
                setShowOnReturn(true)
              }}
              onDiscard={() => {
                setSelectedDoctor(tempDoctor)
                SetOpenPopup(false)
                SetCancel(true)
                SetNewAdd(false)
                setSelectedSettingsTab(menu[0].value)
                setSelectedSchedules([])
                onDoctorChange(tempDoctor)
                SetSideMenu(false)

                if (existingSchedules.length > 0) {
                  const scheduleBundle =
                    getBulkScheduleUpdateBundle(existingSchedules)

                  dispatch(updatingScheduleDetails(scheduleBundle))
                }
                setExistingSchedules([])
              }}
            />
          )}

          {cancelPopUp === true && (
            <CancelSchedule
              open={cancelPopUp}
              onClose={() => {
                SetCancelPopup(false)
                SetCancel(false)
              }}
              onDiscard={() => {
                requestSearchSchedule()
                SetCancelPopup(false)
                SetCancel(true)
                SetNewAdd(false)
                setSelectedSettingsTab(menu[1].value)
                setExistingSchedules(scheduleArray)
                setSelectedSchedules([])
                // onDoctorChange(tempDoctor)
                SetSideMenu(false)
                if (existingSchedules.length > 0) {
                  const scheduleBundle =
                    getBulkScheduleUpdateBundle(existingSchedules)
                  dispatch(updatingScheduleDetails(scheduleBundle))
                }
                setExistingSchedules([])
              }}
            />
          )}
        </Box>
      </Paper>
    </div>
  )
}
