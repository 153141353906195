import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Grid } from '@material-ui/core'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PatientTile } from 'views/components/patients/pateintTile'
import { WelloFormItemLabel, WelloTextField } from 'wello-web-components'

interface Props {
  orderDetail: R4.IServiceRequest
  patientDetails: R4.IPatient
  isReschedulingChargeApplicable: boolean
  preSelectedCancelReason: string
  preSelectedInformCheck: boolean
  onInformCheckChanged: (val: boolean) => void
  onCancelReasonChanged: (val: R4.ICoding) => void
  onPatientNoteTextChanged: (val: string) => void
  onNewSlotSelected: (selectedSlot: string) => void
  patientNoteText: string
}

export const IPDReScheduleConfirmation: React.FC<Props> = ({
  orderDetail,
  patientDetails,
  preSelectedCancelReason,
  preSelectedInformCheck,
  onInformCheckChanged,
  onCancelReasonChanged,
  isReschedulingChargeApplicable,
  onPatientNoteTextChanged,
  patientNoteText,
  onNewSlotSelected,
}: Props) => {
  const [note, setNote] = useState('')

  const { t } = useTranslation()
  return (
    <Box width='100%'>
      <Grid container>
        <Grid item xs={12}>
          <Box marginX={2} paddingX={1}>
            <Grid container justify='center' direction='column'>
              {patientDetails && (
                <Grid item>
                  <Box paddingY={1}>
                    <WelloFormItemLabel
                      title={t('labelCommon:patient_label')}
                    />
                    <Box>
                      <PatientTile patient={patientDetails} />
                    </Box>
                  </Box>
                </Grid>
              )}
              <Grid item>
                <Box display='flex' flexDirection='column' width='100%'>
                  <WelloTextField
                    title='Existing Check Out Date'
                    textProps={{
                      id: `actor_name_unit_${t}`,
                      type: 'date',
                      defaultValue: moment(
                        orderDetail.occurrencePeriod?.end
                      ).format('YYYY-MM-DD'),
                      disabled: true,
                      inputProps: {
                        min: moment(new Date()).format('DD-MM-YYYY'),
                        max: moment().add(90, 'd').format('YYYY-MM-DD'),
                      },
                      onKeyDown: (e) => {
                        e.preventDefault()
                      },
                      onChange: (changedValue) => {},
                    }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box display='flex' flexDirection='column' width='100%'>
                  <WelloTextField
                    title='New Check Out Date'
                    textProps={{
                      id: `actor_name_unit_${t}`,
                      type: 'date',
                      inputProps: {
                        min: moment(new Date()).format('YYYY-MM-DD'),
                        max: moment().add(90, 'd').format('YYYY-MM-DD'),
                      },
                      onKeyDown: (e) => {
                        e.preventDefault()
                      },
                      onChange: (changedValue) => {
                        onNewSlotSelected(changedValue.target.value)
                      },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* <Grid item xs={12}>
          <Box marginX={2} paddingX={1}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-end'
              style={{ padding: 0 }}
            >
              <Grid xs={isReschedulingChargeApplicable ? 5 : 12}>
                <Box paddingY={1} paddingRight={2}>
                  <WelloSelectFHIRCoding
                    availableCodings={appCancelReasons}
                    title={t('labelCommon:reason')}
                    onChanged={(e) => {
                      onCancelReasonChanged(e as R4.ICoding)
                    }}
                    textFieldProps={{
                      required: true,
                    }}
                    preSelectedCoding={appCancelReasons.find(
                      (e: any) => e.code === preSelectedCancelReason
                    )}
                    displayDoneLabel={true}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid> */}
        <Grid item xs>
          {/* {isReschedulingChargeApplicable && (
            <Box marginX={2} paddingX={1}>
              <Grid item>
                <Alert severity='warning' variant='outlined' onClose={() => {}}>
                  {t('labelCommon:cancellation_deduction')}
                </Alert>
              </Grid>
            </Box>
          )} */}
          {/* <Box marginX={2} paddingX={1}>
            <Grid item>
              <Box paddingY={1}>
                <WelloFormItemLabel title={t('labelCommon:note_for_patient')} />
                <OutlinedInput
                  fullWidth
                  placeholder={t('labelCommon:add_note_patient')}
                  style={{ backgroundColor: 'white' }}
                  value={patientNoteText}
                  onChange={(e) => {
                    onPatientNoteTextChanged(e.target.value)
                  }}
                />
              </Box>
            </Grid>
          </Box> */}
        </Grid>
      </Grid>
    </Box>
  )
}
