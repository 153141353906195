/* eslint-disable react/jsx-key */
import { Box } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { DiscountRateDataTable } from './discountSettingsTable'

export const DiscountRateSearch: any = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <Box
      display='flex'
      flexDirection='column'
      flexGrow={1}
      width='100%'
      height='100%'
      marginTop={3}
    >
      <Box
        display='flex'
        flexDirection='row'
        height='100%'
        width='100%'
        style={{
          backgroundColor: '#FFFFFFAF',
        }}
      >
        <Box width='100%'>
          <DiscountRateDataTable />
        </Box>
      </Box>
    </Box>
  )
}
