import { TaskStatusKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import MaterialTable, { Column, MTableToolbar } from '@material-table/core'
import {
  Box,
  Button,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import {
  kBackgroundDefault,
  kDialogueBackground,
  kPrimaryLight,
} from 'configs/styles/muiThemes'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import _ from 'lodash'
import { IncomingCrossFunctionInvitation } from 'models/administration/IncomingCrossFunctionInvitaion'
import moment from 'moment'

import React, { useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isPremiumOffer } from 'utils/admisnistration/cpg/cpgListHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import OverflowTwoLineTypography from 'views/components/consultation/plan/ayurveda/overflowTwoLineTypography'
import OverflowTypography from 'views/components/consultation/plan/ayurveda/overflowTypography'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '2px',
      margin: '0px',
    },

    '& .MuiTableRow': {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(33, 150, 243, 0.5)',
        },
      },
    },
  },
})

interface InvitedMembersListProps {
  medicationsList: IncomingCrossFunctionInvitation[]
  actionComponent?: React.ReactNode

  onAcceptClick: (medication: IncomingCrossFunctionInvitation) => void
  onRejectClick: (medication: IncomingCrossFunctionInvitation) => void
  disableAction?: boolean
}

export const IncomingCareTeamInvitationList: React.FC<InvitedMembersListProps> =
  ({
    medicationsList,
    actionComponent,

    onAcceptClick,
    onRejectClick,
    disableAction,
  }: InvitedMembersListProps) => {
    const ref = React.useRef<null | HTMLDivElement>(null)

    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    useLayoutEffect(() => {
      if (ref !== null && ref.current !== null && ref && ref.current) {
        setWidth(ref.current.offsetWidth)
        setHeight(ref.current.offsetHeight)
      }
    }, [])
    const classes = useStyles()
    const convertedData = medicationsList
    const [data, setData] = useState([...convertedData])

    const [selectedRowId, setSelectedRowId] = React.useState('')
    const [selectedRow, setSelectedRow] = React.useState(false)
    const [openPartnerLabPopup, setOpenPartnerLabPopup] = useState(false)
    const { t } = useTranslation()
    const [nameFilter, setNameFilter] = useState('')
    const [nameFocus, setNameFocus] = useState(true)
    const [addressFilter, setAddressFilter] = useState('')
    const [addressFocus, setAddressFocus] = useState(false)
    const [phoneFilter, setPhoneFilter] = useState('')
    const [phoneFocus, setPhoneFocus] = useState(false)
    const [emailFilter, setEmailFilter] = useState('')
    const [emailFocus, setEmailFocus] = useState(false)
    const [ageFilter, setAgeFilter] = useState('')
    const [ageFocus, setAgeFocus] = useState(false)
    const [statusFilter, setStatusFilter] = useState('')
    const [statusFocus, setStatusFocus] = useState(false)
    const [typeFilter, setTypeFilter] = useState('')
    const [typeFocus, setTypeFocus] = useState(false)

    const [mainFocus, setMainFocus] = useState(false)
    const [showText, setShowText] = React.useState<boolean>(false)
    const [fullText, setFullText] = React.useState<string>()

    const handleDarkModeChange = () => {
      // setPreferDarkMode(!preferDarkMode)
      // localStorage.setItem('_tableDarkMode', !preferDarkMode)
    }

    const filterType = (value: any, filed: string) => {
      setTypeFocus(true)
      if (value) {
        const filteredData = convertedData.filter((d) =>
          d
            .invitedMemberName!.replace(/\s+/g, '')
            .toLowerCase()
            .includes(value.replace(/\s+/g, '').toLowerCase())
        )
        setData(filteredData)
      } else {
        setData([...convertedData])
      }
      setTypeFilter(value)
      setTypeFocus(true)
      setAddressFilter('')
      setAddressFocus(false)
      setPhoneFocus(false)
      setEmailFocus(false)
      setAgeFocus(false)
      setStatusFocus(false)
      setNameFocus(false)
      setMainFocus(false)
    }

    const filterName = (value: any, filed: string) => {
      setNameFocus(true)
      if (value) {
        const filteredData = convertedData.filter((d) =>
          d.invitedMemberName
            .replace(/\s+/g, '')
            .toLowerCase()
            .includes(value.replace(/\s+/g, '').toLowerCase())
        )
        setData(filteredData)
      } else {
        setData([...convertedData])
      }
      setNameFilter(value)
      setAddressFilter('')
      setAddressFocus(false)
      setPhoneFocus(false)
      setEmailFocus(false)
      setAgeFocus(false)
      setStatusFocus(false)
      setTypeFocus(false)
      setMainFocus(false)
    }

    const columns: Column<IncomingCrossFunctionInvitation>[] = [
      {
        title: (
          <Box minWidth='150px'>
            <Typography
              variant='subtitle1'
              style={{
                fontSize: 14,

                fontWeight: 'bold',
                lineHeight: 'normal',
              }}
              color='primary'
            >
              User Name
            </Typography>
          </Box>
        ),

        width: '20%',
        field: 'invitedMemberName',
        customSort: (
          a: IncomingCrossFunctionInvitation,
          b: IncomingCrossFunctionInvitation
        ) =>
          a.invitedMemberName > b.invitedMemberName
            ? 1
            : b.invitedMemberName > a.invitedMemberName
            ? -1
            : 0,

        hiddenByColumnsButton: false,
        //   defaultSort: 'asc',
        render: (rowData: IncomingCrossFunctionInvitation) => (
          <Box display='flex' alignItems='center'>
            <OverflowTypography
              text={rowData.invitedMemberName ?? ''}
              typographyProps={{
                variant: 'subtitle2',
                noWrap: true,
              }}
            />
          </Box>
        ),
        filtering: false,
      },

      {
        title: (
          <Box>
            <Typography
              variant='subtitle1'
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 'normal',
              }}
              color='primary'
            >
              Organization
            </Typography>
          </Box>
        ),

        field: 'invitingMemberOrganization',
        customSort: (
          a: IncomingCrossFunctionInvitation,
          b: IncomingCrossFunctionInvitation
        ) =>
          (a.invitingMemberOrganization ?? '') >
          (b.invitingMemberOrganization ?? '')
            ? 1
            : (b.invitingMemberOrganization ?? '') >
              (a.invitingMemberOrganization ?? '')
            ? -1
            : 0,

        hiddenByColumnsButton: false,
        filtering: false,

        render: (rowData: IncomingCrossFunctionInvitation) => (
          <Box display='flex' alignItems='center'>
            <OverflowTwoLineTypography
              text={rowData.invitingMemberOrganization ?? ''}
              typographyProps={{
                variant: 'subtitle2',
                noWrap: false,
              }}
            />
          </Box>
        ),
      },
      {
        title: (
          <Box>
            <Typography
              variant='subtitle1'
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 'normal',
              }}
              color='primary'
            >
              Unit
            </Typography>
          </Box>
        ),

        field: 'memberUnit',
        customSort: (
          a: IncomingCrossFunctionInvitation,
          b: IncomingCrossFunctionInvitation
        ) =>
          (a.invitingMemberUnit ?? '') > (b.invitingMemberUnit ?? '')
            ? 1
            : (b.invitingMemberUnit ?? '') > (a.invitingMemberUnit ?? '')
            ? -1
            : 0,

        hiddenByColumnsButton: false,
        filtering: false,

        render: (rowData: IncomingCrossFunctionInvitation) => (
          <Box display='flex' alignItems='center'>
            <OverflowTwoLineTypography
              text={rowData.invitingMemberUnit ?? ''}
              typographyProps={{
                variant: 'subtitle2',
                noWrap: false,
              }}
            />
          </Box>
        ),
      },
      {
        title: (
          <Box>
            <Typography
              variant='subtitle1'
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 'normal',
              }}
              color='primary'
            >
              Role Offered
            </Typography>
          </Box>
        ),

        field: 'offeredRoles',
        customSort: (
          a: IncomingCrossFunctionInvitation,
          b: IncomingCrossFunctionInvitation
        ) =>
          (a.offeredRoles ?? '') > (b.offeredRoles ?? '')
            ? 1
            : (b.offeredRoles ?? '') > (a.offeredRoles ?? '')
            ? -1
            : 0,

        hiddenByColumnsButton: false,
        filtering: false,

        render: (rowData: IncomingCrossFunctionInvitation) => (
          <Box display='flex' alignItems='center'>
            <OverflowTwoLineTypography
              text={rowData.offeredRoles ?? ''}
              typographyProps={{
                variant: 'subtitle2',
                noWrap: false,
              }}
            />
          </Box>
        ),
      },
      {
        title: (
          <Box>
            <Typography
              variant='subtitle1'
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 'normal',
              }}
              color='primary'
            >
              Status
            </Typography>
          </Box>
        ),

        field: 'invitationStatus',
        customSort: (
          a: IncomingCrossFunctionInvitation,
          b: IncomingCrossFunctionInvitation
        ) =>
          (a.invitationStatus ?? '') > (b.invitationStatus ?? '')
            ? 1
            : (b.invitationStatus ?? '') > (a.invitationStatus ?? '')
            ? -1
            : 0,

        hiddenByColumnsButton: false,
        filtering: false,

        render: (rowData: IncomingCrossFunctionInvitation) => (
          <Box display='flex' alignItems='center'>
            <OverflowTwoLineTypography
              text={rowData.invitationStatus ?? ''}
              typographyProps={{
                variant: 'subtitle2',
                noWrap: false,
              }}
            />
          </Box>
        ),
      },
      {
        title: (
          <Box>
            <Typography
              variant='subtitle1'
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 'normal',
              }}
              color='primary'
            >
              Invite Date
            </Typography>
          </Box>
        ),

        field: 'taskRaw',
        customSort: (
          a: IncomingCrossFunctionInvitation,
          b: IncomingCrossFunctionInvitation
        ) =>
          moment(a.rawTask.authoredOn!).diff(
            moment(b.rawTask.authoredOn!),
            'seconds'
          ),

        hiddenByColumnsButton: false,
        filtering: false,

        render: (rowData: IncomingCrossFunctionInvitation) => (
          <Box display='flex' alignItems='center'>
            <OverflowTwoLineTypography
              text={moment(rowData.rawTask.authoredOn!).format('DD/MM/YYYY')}
              typographyProps={{
                variant: 'subtitle2',
                noWrap: false,
              }}
            />
          </Box>
        ),
      },
      {
        title: <Box />,
        field: 'invitationStatus',

        hiddenByColumnsButton: false,
        //   defaultSort: 'asc',
        render: (row: IncomingCrossFunctionInvitation) => (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            flexDirection='row'
          >
            {row.rawTask.status === TaskStatusKind._requested && (
              <Button
                variant='contained'
                color='primary'
                disabled={disableAction}
                onClick={() => {
                  onAcceptClick(row)
                }}
              >
                Accept
              </Button>
            )}

            {row.rawTask.status === TaskStatusKind._requested && (
              <Button
                variant='contained'
                color='secondary'
                disabled={disableAction}
                onClick={() => {
                  onRejectClick(row)
                }}
              >
                Reject
              </Button>
            )}
          </Box>
        ),
        filtering: false,
      },
    ]

    if (actionComponent && onAcceptClick) {
      columns.push()
    }

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
        ref={ref}
      >
        <MaterialTable
          components={{
            Toolbar: (props) => (
              <div
                /* className={classes.toolbarWrapper} */
                style={{
                  minHeight: '0px',
                }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
            Container: (props) => (
              <Paper
                {...props}
                elevation={0}
                style={{
                  maxHeight: '100%',
                  height: '100%',
                  backgroundColor: useTheme().palette.background.paper,
                }}
                classes={classes}
              />
            ),
          }}
          columns={columns}
          data={data}
          onOrderChange={(orderBy, orderDirection) => {
            const key = orderBy < 1 ? 0 : orderBy
          }}
          onRowClick={(event, rowData) => {
            /* if (rowData && onOrgSelected) {
            onOrgSelected(rowData.orgDetails, rowData.mainOrgId ?? '')
            setSelectedRowId(rowData ? rowData.orgName ?? '' : '')
          } */
          }}
          options={{
            toolbar: false,
            search: false,
            showTitle: false,
            columnResizable: false,
            paginationPosition: 'bottom',
            minBodyHeight: height - 60,
            maxBodyHeight: height - 60,
            padding: 'dense',
            searchFieldVariant: 'outlined',
            filtering: false,
            pageSize: 20,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [10, 20, 30],
            //   columnsButton: true,
            // maxBodyHeight: `${height - 190}px`,
            //   minBodyHeight: `${height - 260}px`,
            tableLayout: 'fixed',
            sorting: true,
            thirdSortClick: false,

            headerStyle: {
              backgroundColor: kBackgroundDefault,
              width: 100,
              minHeight: '12px',
              maxHeight: '12px',
              position: 'sticky',
              top: 0,
              zIndex: 100,
              // height: 20,
            },
            selectionProps: (_rowData: any) => ({
              color: 'primary',
            }),
            actionsColumnIndex: -1,
            searchFieldAlignment: 'left',
            rowStyle: (
              _data: IncomingCrossFunctionInvitation,
              index: number,
              _level: number
            ) =>
              index % 2
                ? selectedRow && _data.rawTask.id! === selectedRowId
                  ? {
                      backgroundColor: '#9E9DDC',

                      '&:hover': { backgroundColor: 'red' },
                    }
                  : {
                      backgroundColor: kDialogueBackground,
                      '&:hover': { backgroundColor: 'red' },
                    }
                : selectedRow && _data.rawTask.id! === selectedRowId
                ? {
                    backgroundColor: '#9E9DDC',

                    '&:hover': { backgroundColor: 'red' },
                  }
                : { '&:hover': { backgroundColor: 'red' } },
          }}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} of {count}',
            },
            toolbar: {
              nRowsSelected: '{0} row(s) selected',
            },
            header: {
              actions: '',
            },
            body: {
              emptyDataSourceMessage: 'No records to display',
              filterRow: {
                filterTooltip: 'Filter',
              },
            },
          }}
        />
      </div>
    )
  }
