/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable guard-for-in */
import { Filter } from 'fp-ts/lib/Filterable'
import _ from 'lodash'
import {
  CohortDataStage,
  FilterByStageProps,
  ListItem,
} from 'models/chartProps'
import { CohortChart } from 'models/cohortChart'
import { CohortData } from 'models/cohortData'
import { CohortDisplayData } from 'models/cohortDisplayData'
import { CohortDisplayWithCount } from 'models/cohortDisplayWithCount'
import { SummaryData } from 'models/cohortReportData'
import { SummaryDataWithCount } from 'models/cohortReportMultiSeriesData'
import { Result } from 'models/cohortResult'
import { CohortTableData } from 'models/cohortTableData'
import { TargetCohorts } from 'models/cohortTypes'
import { DisplayData } from 'models/displayData'
import { Filters } from 'models/filters'
import { LineData } from 'models/lineData'
import { MeasurementReports } from 'models/measurementReports'

import { getEvidenceData } from 'utils/patientHelper/patientEditHelper'

export function getTargetCohortList(): TargetCohorts[] {
  const targerCohorts: TargetCohorts[] = []

  const sorttedTargerCohorts = targerCohorts.sort((a: any, b: any) => {
    const nameA = a.label.toUpperCase() // ignore upper and lowercase
    const nameB = b.label.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1 // nameA comes first
    }
    if (nameA > nameB) {
      return 1 // nameB comes first
    }
    return 0 // names must be equal
  })
  return sorttedTargerCohorts
}

export function getOutComeCohortList(): TargetCohorts[] {
  const targerCohorts: TargetCohorts[] = []

  const sorttedTargerCohorts = targerCohorts.sort((a: any, b: any) => {
    const nameA = a.label.toUpperCase() // ignore upper and lowercase
    const nameB = b.label.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1 // nameA comes first
    }
    if (nameA > nameB) {
      return 1 // nameB comes first
    }
    return 0 // names must be equal
  })

  return sorttedTargerCohorts
}

export function getcohortFormationForEvidence(xs: SummaryData[], key: string) {
  return xs.reduce((rv: any, x: any) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

function arrayFromObject(obj: any) {
  const arr: any = []
  for (const i in obj) {
    arr.push(obj[i])
  }
  return arr
}

function groupBy(array: any, f: any) {
  const groups: any = {}
  array.forEach((o: any) => {
    const group = JSON.stringify(f(o))
    groups[group] = groups[group] || []
    groups[group].push(o)
  })
  return Object.keys(groups).map((group) => groups[group])
}

export function addAlpha(color: string, opacity: number): string {
  // coerce values so ti is between 0 and 1.
  const opacityData = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
  const colorData = color + opacityData.toString(16).toUpperCase()
  return `#${colorData}`
}

export function getColorCodeForMin(index: number): string {
  switch (index) {
    case 0:
      return addAlpha('F9CA86', 0.3)
    case 1:
      return addAlpha('FEAB0D', 0.3)
    case 2:
      return addAlpha('99CE9A', 0.3)

    case 3:
      return addAlpha('3DAB34', 0.3)
    case 4:
      return addAlpha('E18685', 0.3)
    case 5:
      return addAlpha('0FB4C3', 0.3)
    case 6:
      return addAlpha('616AFA', 0.3)
    case 7:
      return addAlpha('CD83FF', 0.3)
    case 8:
      return addAlpha('FFADF0', 0.3)
    case 9:
      return addAlpha('ff96e1', 0.3)
    case 10:
      return addAlpha('b89f67', 0.3)
    case 11:
      return addAlpha('b5ffe1', 0.3)
    case 12:
      return addAlpha('00ff00', 0.3)
    case 13:
      return addAlpha('ff7431', 0.3)
    case 14:
      return addAlpha('941956', 0.3)
    case 15:
      return addAlpha('87AFC7', 0.3)
    case 16:
      return addAlpha('00FFFF', 0.3)
    case 17:
      return addAlpha('01F9C6', 0.3)
    case 18:
      return addAlpha('2C3539', 0.3)
    case 19:
      return addAlpha('16F529', 0.3)
    case 20:
      return addAlpha('F4A460', 0.3)

    default:
      return ''
  }
}

export function getColorCodeForMedian(index: number): string {
  switch (index) {
    case 0:
      return addAlpha('F9CA86', 1)
    case 1:
      return addAlpha('FEAB0D', 1)
    case 2:
      return addAlpha('99CE9A', 1)

    case 3:
      return addAlpha('3DAB34', 0.8)
    case 4:
      return addAlpha('E18685', 1)
    case 5:
      return addAlpha('0FB4C3', 1)
    case 6:
      return addAlpha('616AFA', 1)
    case 7:
      return addAlpha('CD83FF', 1)
    case 8:
      return addAlpha('FFADF0', 1)
    case 9:
      return addAlpha('FFADF0', 0.6)
    case 10:
      return addAlpha('b89f67', 0.6)
    case 11:
      return addAlpha('b5ffe1', 0.6)
    case 12:
      return addAlpha('00ff00', 0.6)
    case 13:
      return addAlpha('ff7431', 0.6)
    case 14:
      return addAlpha('941956', 0.6)
    case 15:
      return addAlpha('87AFC7', 0.6)
    case 16:
      return addAlpha('00FFFF', 0.6)
    case 17:
      return addAlpha('01F9C6', 0.6)
    case 18:
      return addAlpha('2C3539', 0.6)
    case 19:
      return addAlpha('16F529', 0.6)
    case 20:
      return addAlpha('F4A460', 0.6)

    default:
      return ''
  }
}

export function getColorCodeForMax(index: number): string {
  switch (index) {
    case 0:
      return addAlpha('F9CA86', 1)
    case 1:
      return addAlpha('FEAB0D', 1)
    case 2:
      return addAlpha('99CE9A', 1)

    case 3:
      return addAlpha('3DAB34', 0.8)
    case 4:
      return addAlpha('E18685', 1)
    case 5:
      return addAlpha('0FB4C3', 1)
    case 6:
      return addAlpha('616AFA', 1)
    case 7:
      return addAlpha('CD83FF', 1)
    case 8:
      return addAlpha('FFADF0', 1)
    case 9:
      return addAlpha('ff96e1', 1)
    case 10:
      return addAlpha('b89f67', 1)
    case 11:
      return addAlpha('b5ffe1', 1)
    case 12:
      return addAlpha('00ff00', 1)
    case 13:
      return addAlpha('ff7431', 1)
    case 14:
      return addAlpha('941956', 1)
    case 15:
      return addAlpha('87AFC7', 1)
    case 16:
      return addAlpha('00FFFF', 1)
    case 17:
      return addAlpha('01F9C6', 1)
    case 18:
      return addAlpha('2C3539', 1)
    case 19:
      return addAlpha('16F529', 1)
    case 20:
      return addAlpha('F4A460', 1)

    default:
      return ''
  }
}

export function getCohortData(
  response: any,
  analysisFilter: Filters[]
): CohortDisplayData[] {
  let postProcessedData: SummaryData[] = []
  let minData: number[] = []
  let indexData: number[] = []
  let medianData: number[] = []
  let maxData: number[] = []
  let finalResult: number[] = []
  const outComeData: CohortDisplayData[] = []

  for (let i = 0; i < analysisFilter.length; i++) {
    postProcessedData = []

    const filteredData = response.filter(
      (d: any) => d.analysisId === parseInt(analysisFilter[i].id, 10)
    )

    for (let j = 0; j < filteredData.length; j++) {
      minData = []
      medianData = []
      maxData = []
      indexData = []
      finalResult = []

      for (let k = 0; k < filteredData[j].data.length; k++) {
        indexData.push(filteredData[j].data[k].time_norm_idx)
        // minData.push(filteredData[j].data[k].min)
        // medianData.push(filteredData[j].data[k].median)
        // maxData.push(filteredData[j].data[k].max)
      }

      finalResult = indexData.filter((d: number) => d === 0)

      if (finalResult.length === 0) {
        minData.push(0)
        medianData.push(0)
        maxData.push(0)
      } else {
        minData.push(filteredData[j].data[0].min)
        medianData.push(filteredData[j].data[0].median)
        maxData.push(filteredData[j].data[0].max)
      }
      finalResult = []
      finalResult = indexData.filter((d: number) => d === 1)

      if (finalResult.length === 0) {
        minData.push(0)
        medianData.push(0)
        maxData.push(0)
      } else {
        minData.push(filteredData[j].data[1].min)
        medianData.push(filteredData[j].data[1].median)
        maxData.push(filteredData[j].data[1].max)
      }
      finalResult = []
      finalResult = indexData.filter((d: number) => d === 3)

      if (finalResult.length === 0) {
        minData.push(0)
        medianData.push(0)
        maxData.push(0)
      } else {
        minData.push(filteredData[j].data[2].min)
        medianData.push(filteredData[j].data[2].median)
        maxData.push(filteredData[j].data[2].max)
      }
      finalResult = []
      finalResult = indexData.filter((d: number) => d === 4)

      if (finalResult.length === 0) {
        minData.push(0)
        medianData.push(0)
        maxData.push(0)
      } else {
        if (filteredData[j].data.length === 2) {
          minData.push(filteredData[j].data[1].min)
          medianData.push(filteredData[j].data[1].median)
          maxData.push(filteredData[j].data[1].max)
        } else {
          minData.push(filteredData[j].data[3].min)
          medianData.push(filteredData[j].data[3].median)
          maxData.push(filteredData[j].data[3].max)
        }
      }

      const minArray: SummaryData = {
        name: `min-${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
        data: minData,
        group: `${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
        color: getColorCodeForMin(filteredData[j].group_idx),
      }

      postProcessedData.push(minArray)
      const medianObj: SummaryData = {
        name: `median-${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
        data: medianData,
        group: `${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
        color: getColorCodeForMedian(filteredData[j].group_idx),
      }
      postProcessedData.push(medianObj)
      const maxObj: SummaryData = {
        name: `max-${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
        data: maxData,
        group: `${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
        color: getColorCodeForMax(filteredData[j].group_idx),
      }
      postProcessedData.push(maxObj)
    }

    outComeData.push({
      analysisName: analysisFilter[i].name,
      summaryData: postProcessedData,
    })
  }
  return outComeData
}

export function getCohortDataForSinglePercentile(
  response: any,
  analysisFilter: Filters[],
  percentile: string
): CohortDisplayData[] {
  let postProcessedData: SummaryData[] = []
  let minData: number[] = []
  let p10Data: number[] = []
  let p25Data: number[] = []
  let indexData: number[] = []
  let medianData: number[] = []
  let p75Data: number[] = []
  let p90Data: number[] = []

  let maxData: number[] = []
  let finalResult: number[] = []
  let avgData: number[] = []
  const outComeData: CohortDisplayData[] = []

  for (let i = 0; i < analysisFilter.length; i++) {
    postProcessedData = []

    const filteredData = response.filter(
      (d: any) => d.analysisId === parseInt(analysisFilter[i].id, 10)
    )

    for (let j = 0; j < filteredData.length; j++) {
      minData = []
      medianData = []
      maxData = []
      indexData = []
      finalResult = []
      p10Data = []
      p25Data = []
      p75Data = []
      p90Data = []
      avgData = []

      for (let k = 0; k < filteredData[j].data.length; k++) {
        indexData.push(filteredData[j].data[k].time_norm_idx)
        // minData.push(filteredData[j].data[k].min)
        // medianData.push(filteredData[j].data[k].median)
        // maxData.push(filteredData[j].data[k].max)
      }

      finalResult = indexData.filter((d: number) => d === 0)

      if (finalResult.length === 0) {
        minData.push(0)
        medianData.push(0)
        maxData.push(0)
        p10Data.push(0)
        p25Data.push(0)
        p75Data.push(0)
        p90Data.push(0)
        avgData.push(0)
      } else {
        minData.push(filteredData[j].data[0].min)
        medianData.push(filteredData[j].data[0].median)
        maxData.push(filteredData[j].data[0].max)
        p10Data.push(filteredData[j].data[0].p10)
        p25Data.push(filteredData[j].data[0].p25)
        p75Data.push(filteredData[j].data[0].p75)
        p90Data.push(filteredData[j].data[0].p90)
        avgData.push(filteredData[j].data[0].avg)
      }
      finalResult = []
      finalResult = indexData.filter((d: number) => d === 1)

      if (finalResult.length === 0) {
        minData.push(0)
        medianData.push(0)
        maxData.push(0)
        p10Data.push(0)
        p25Data.push(0)
        p75Data.push(0)
        p90Data.push(0)
        avgData.push(0)
      } else {
        minData.push(filteredData[j].data[1].min)
        medianData.push(filteredData[j].data[1].median)
        maxData.push(filteredData[j].data[1].max)
        p10Data.push(filteredData[j].data[1].p10)
        p25Data.push(filteredData[j].data[1].p25)
        p75Data.push(filteredData[j].data[1].p75)
        p90Data.push(filteredData[j].data[1].p90)
        avgData.push(filteredData[j].data[1].avg)
      }
      finalResult = []
      finalResult = indexData.filter((d: number) => d === 3)

      if (finalResult.length === 0) {
        minData.push(0)
        medianData.push(0)
        maxData.push(0)
        p10Data.push(0)
        p25Data.push(0)
        p75Data.push(0)
        p90Data.push(0)
        avgData.push(0)
      } else {
        minData.push(filteredData[j].data[2].min)
        medianData.push(filteredData[j].data[2].median)
        maxData.push(filteredData[j].data[2].max)
        p10Data.push(filteredData[j].data[2].p10)
        p25Data.push(filteredData[j].data[2].p25)
        p75Data.push(filteredData[j].data[2].p75)
        p90Data.push(filteredData[j].data[2].p90)
        avgData.push(filteredData[j].data[2].avg)
      }
      finalResult = []
      finalResult = indexData.filter((d: number) => d === 4)

      if (finalResult.length === 0) {
        minData.push(0)
        medianData.push(0)
        maxData.push(0)
        p10Data.push(0)
        p25Data.push(0)
        p75Data.push(0)
        p90Data.push(0)
        avgData.push(0)
      } else {
        if (filteredData[j].data.length === 2) {
          minData.push(filteredData[j].data[1].min)
          medianData.push(filteredData[j].data[1].median)
          maxData.push(filteredData[j].data[1].max)
          p10Data.push(filteredData[j].data[1].p10)
          p25Data.push(filteredData[j].data[1].p25)
          p75Data.push(filteredData[j].data[1].p75)
          p90Data.push(filteredData[j].data[1].p90)
          avgData.push(filteredData[j].data[1].avg)
        } else if (filteredData[j].data.length === 3) {
          minData.push(filteredData[j].data[2].min)
          medianData.push(filteredData[j].data[2].median)
          maxData.push(filteredData[j].data[2].max)
          p10Data.push(filteredData[j].data[2].p10)
          p25Data.push(filteredData[j].data[2].p25)
          p75Data.push(filteredData[j].data[2].p75)
          p90Data.push(filteredData[j].data[2].p90)
          avgData.push(filteredData[j].data[2].avg)
        } else {
          minData.push(filteredData[j].data[3].min)
          medianData.push(filteredData[j].data[3].median)
          maxData.push(filteredData[j].data[3].max)
          p10Data.push(filteredData[j].data[3].p10)
          p25Data.push(filteredData[j].data[3].p25)
          p75Data.push(filteredData[j].data[3].p75)
          p90Data.push(filteredData[j].data[3].p90)
          avgData.push(filteredData[j].data[3].avg)
        }
      }
      if (percentile === 'min') {
        const minArray: SummaryData = {
          name: `min-${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
          data: minData,
          group: `${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
          color: getColorCodeForMax(filteredData[j].group_idx),
        }
        postProcessedData.push(minArray)
      }
      if (percentile === 'p10') {
        const p10Obj: SummaryData = {
          name: `P10-${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
          data: p10Data,
          group: `${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
          color: getColorCodeForMax(filteredData[j].group_idx),
        }
        postProcessedData.push(p10Obj)
      }
      if (percentile === 'p25') {
        const p25Obj: SummaryData = {
          name: `P25-${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
          data: p25Data,
          group: `${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
          color: getColorCodeForMax(filteredData[j].group_idx),
        }
        postProcessedData.push(p25Obj)
      }

      if (percentile === 'median') {
        const medianObj: SummaryData = {
          name: `median-${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
          data: medianData,
          group: `${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
          color: getColorCodeForMax(filteredData[j].group_idx),
        }
        postProcessedData.push(medianObj)
      }
      if (percentile === 'p75') {
        const p75Obj: SummaryData = {
          name: `p75-${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
          data: p75Data,
          group: `${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
          color: getColorCodeForMax(filteredData[j].group_idx),
        }
        postProcessedData.push(p75Obj)
      }
      if (percentile === 'p90') {
        const p90Obj: SummaryData = {
          name: `P90-${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
          data: p90Data,
          group: `${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
          color: getColorCodeForMax(filteredData[j].group_idx),
        }
        postProcessedData.push(p90Obj)
      }
      if (percentile === 'max') {
        const maxObj: SummaryData = {
          name: `max-${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
          data: maxData,
          group: `${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
          color: getColorCodeForMax(filteredData[j].group_idx),
        }
        postProcessedData.push(maxObj)
      }
      if (percentile === 'avg') {
        const avjObj: SummaryData = {
          name: `avg-${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
          data: avgData,
          group: `${filteredData[j].analysisName}--${filteredData[j].cohortName}--${filteredData[j].strataName}`,
          color: getColorCodeForMax(filteredData[j].group_idx),
        }
        postProcessedData.push(avjObj)
      }
    }

    outComeData.push({
      analysisName: analysisFilter[i].name,
      summaryData: postProcessedData,
    })
  }
  return outComeData
}

export function getAnalysisFilter(response: any): Filters[] {
  let outComeData: Filters[] = []
  for (let i = 0; i < response.result.length; i++) {
    outComeData.push({
      id: response.result[i].analysisId.toString(),
      name: response.result[i].analysisName,
      dummyId: response.result[i],
      color: getColorCodeForMax(response.result[i]),
    })
  }
  outComeData = outComeData.filter(
    (value, index, self) => index === self.findIndex((t) => t.id === value.id)
  )

  return outComeData
}

export function getCohortFilter(response: any): Filters[] {
  let outComeData: Filters[] = []
  for (let i = 0; i < response.length; i++) {
    outComeData.push({
      id: response[i].cohortId.toString(),
      name: response[i].cohortName ?? 'None',
      dummyId: response[i],
      color: '',
    })
  }
  outComeData = outComeData.filter(
    (value, index, self) => index === self.findIndex((t) => t.id === value.id)
  )

  return outComeData
}

export function getCohortFromCheckBox(
  response: any,
  analysisFilter: string[]
): Filters[] {
  let outComeData: Filters[] = []
  for (let k = 0; k < analysisFilter.length; k++) {
    const filteredData = response.filter(
      (d: any) => d.analysisId === parseInt(analysisFilter[k], 10)
    )

    for (let i = 0; i < filteredData.length; i++) {
      outComeData.push({
        id: filteredData[i].cohortId.toString(),
        name: filteredData[i].cohortName ?? 'None',
        dummyId: filteredData[i],
        color: getColorCodeForMax(filteredData[i]),
      })
    }
  }

  outComeData = outComeData.filter(
    (value, index, self) => index === self.findIndex((t) => t.id === value.id)
  )

  return outComeData
}

export function getStrataName(response: any): Filters[] {
  let outComeData: Filters[] = []
  for (let i = 0; i < response.result.length; i++) {
    outComeData.push({
      id: response.result[i].strataId.toString(),
      name:
        response.result[i].strataName.length > 0
          ? response.result[i].strataName
          : 'No Strata (Base Cohort)',
      dummyId: i,
      color: getColorCodeForMax(i),
    })
  }
  outComeData = outComeData.filter(
    (value, index, self) => index === self.findIndex((t) => t.id === value.id)
  )

  return outComeData
}

export function getStrataNameFromCheckBox(
  response: any,
  analysisFilter: string[]
): Filters[] {
  let outComeData: Filters[] = []
  for (let k = 0; k < analysisFilter.length; k++) {
    const filteredData = response.filter(
      (d: any) => d.analysisId === parseInt(analysisFilter[k], 10)
    )

    for (let i = 0; i < filteredData.length; i++) {
      outComeData.push({
        id: filteredData[i].strataId.toString(),
        name:
          filteredData[i].strataName.length > 0
            ? filteredData[i].strataName
            : 'No Strata (Base Cohort)',
        dummyId: i,
        color: getColorCodeForMax(i),
      })
    }
  }

  outComeData = outComeData.filter(
    (value, index, self) => index === self.findIndex((t) => t.id === value.id)
  )

  return outComeData
}

export function getMonthNumber(code: string): number {
  switch (code) {
    case 'Month 3':
      return 3
    case 'Month 2':
      return 2
    case 'Month 1':
      return 1
    case 'Month 4':
      return 4
    case 'Month 5':
      return 5
    case 'Month 6':
      return 6
    case 'Month 7':
      return 7
    case 'Month 8':
      return 8
    case 'Month 9':
      return 9
    case 'Month 10':
      return 10
    case 'Month 11':
      return 11
    case 'Month 12':
      return 12
    default:
      return 0
  }
}

export function getCohortTableData(
  response: any,
  type: string,
  cohortId: number
): CohortTableData {
  const tableDataPre = response.reports.filter(
    (item: any) => item.analysisName === type
  )
  const itemsData: MeasurementReports[] = tableDataPre[0].items.filter(
    (measureData: any) =>
      measureData.cohortId === tableDataPre[0].cohorts[0].cohortId
  )

  const cohortChart: CohortChart[] = []

  for (let i = 0; i < itemsData.length; i++) {
    if (itemsData[i].strataName === 'Stage 2') {
      cohortChart.push({
        x: getMonthNumber(itemsData[i].covariateName),
        y: itemsData[i].avg,
        persons: `${itemsData[i].count} Persons`,
        min: itemsData[i].min,
        max: itemsData[i].max,
      })
    }
  }
  //   const groupBy = getcohortFormation(itemsData, 'strataName')
  const keys: string[] = Object.keys(groupBy).sort()

  const cohortDataTableValues: CohortTableData = {
    measurementData: itemsData,
    keys,
    chartData: cohortChart,
    resultData: groupBy,
  }

  return cohortDataTableValues
}

export function getcohortFormation(xs: MeasurementReports[], key: string) {
  return xs.reduce((rv: any, x: any) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export function getCohortArray(response: any): CohortData[] {
  const tableDataPre = response.reports.filter(
    (item: any) =>
      item.analysisName === 'Measurement Value Pre-index Monthly - Systolic BP'
  )

  const cohortData: CohortData[] = tableDataPre[0].cohorts

  return cohortData
}

export function getNameOfSeries(
  sameArr: string[],
  analysisName: string,
  cohortName: string,
  strataName: string
) {
  let name: string = ''
  if (sameArr.length === 0) {
    name = `min-${analysisName}--${cohortName}--${strataName} `
  }
  return name
}

export function getCohortDataForFilter(
  response: any,
  analysisFilter: string[],
  inputData: Filters[],
  cohortFilters: string[],
  strataFilters: Filters[]
): CohortDisplayData[] {
  let postProcessedData: SummaryData[] = []
  let minData: number[] = []
  let indexData: number[] = []
  let medianData: number[] = []
  let maxData: number[] = []
  let finalResult: number[] = []
  const outComeData: CohortDisplayData[] = []
  let sameArr: string[] = []
  const filterAnalysis: number[] = []
  const cohortAnalysisFilter: number[] = []
  const strataFilterDataUpdated: any[] = []

  for (let n = 0; n < analysisFilter.length; n++) {
    filterAnalysis.push(parseInt(analysisFilter[n], 10))
  }

  for (let n = 0; n < cohortFilters.length; n++) {
    cohortAnalysisFilter.push(parseInt(cohortFilters[n], 10))
  }
  cohortAnalysisFilter.sort()
  filterAnalysis.sort()

  for (let n = 0; n < cohortAnalysisFilter.length; n++) {
    const coloredArray = strataFilters.filter(
      (d: Filters) => parseInt(d.id, 10) === cohortAnalysisFilter[n]
    )

    strataFilterDataUpdated.push({
      id: parseInt(coloredArray[0].id, 10),
      dummyId: coloredArray[0].dummyId,
      color: coloredArray[0].color,
    })
  }

  for (let i = 0; i < filterAnalysis.length; i++) {
    postProcessedData = []

    const filteredData = response.filter(
      (d: any) => d.analysisId === filterAnalysis[i]
    )

    const analyticData = inputData.filter(
      (d: Filters) => d.id === analysisFilter[i]
    )

    for (let m = 0; m < strataFilterDataUpdated.length; m++) {
      const filteredDataFinal = filteredData.filter(
        (d: any) => d.strataId === strataFilterDataUpdated[m].id
      )

      for (let j = 0; j < filteredDataFinal.length; j++) {
        minData = []
        medianData = []
        maxData = []
        indexData = []
        finalResult = []
        sameArr = []

        for (let k = 0; k < filteredDataFinal[j].data.length; k++) {
          indexData.push(filteredDataFinal[j].data[k].time_norm_idx)
        }

        finalResult = indexData.filter((d: number) => d === 0)

        if (finalResult.length === 0) {
          minData.push(0)
          medianData.push(0)
          maxData.push(0)
        } else {
          minData.push(filteredDataFinal[j].data[0].min)
          if (
            filteredDataFinal[j].data[0].median ===
            filteredDataFinal[j].data[0].min
          ) {
            sameArr.push('1')
            medianData.push(0)
          } else {
            medianData.push(filteredDataFinal[j].data[0].median)
          }
          if (
            filteredDataFinal[j].data[0].max ===
            filteredDataFinal[j].data[0].median
          ) {
            sameArr.push('1')
            maxData.push(0)
          } else {
            maxData.push(filteredDataFinal[j].data[0].max)
          }
        }
        finalResult = []
        finalResult = indexData.filter((d: number) => d === 1)

        if (finalResult.length === 0) {
          minData.push(0)
          medianData.push(0)
          maxData.push(0)
        } else {
          minData.push(filteredDataFinal[j].data[1].min)

          if (
            filteredDataFinal[j].data[1].median ===
            filteredDataFinal[j].data[1].min
          ) {
            medianData.push(0)
          } else {
            medianData.push(filteredDataFinal[j].data[1].median)
          }
          if (
            filteredDataFinal[j].data[1].max ===
            filteredDataFinal[j].data[1].median
          ) {
            maxData.push(0)
          } else {
            maxData.push(filteredDataFinal[j].data[1].max)
          }
          //   medianData.push(filteredDataFinal[j].data[1].median)
          //   maxData.push(filteredDataFinal[j].data[1].max)
        }
        finalResult = []
        finalResult = indexData.filter((d: number) => d === 3)

        if (finalResult.length === 0) {
          minData.push(0)
          medianData.push(0)
          maxData.push(0)
        } else {
          minData.push(filteredDataFinal[j].data[2].min)

          if (
            filteredDataFinal[j].data[2].median ===
            filteredDataFinal[j].data[2].min
          ) {
            medianData.push(0)
          } else {
            medianData.push(filteredDataFinal[j].data[2].median)
          }
          if (
            filteredDataFinal[j].data[2].max ===
            filteredDataFinal[j].data[2].median
          ) {
            maxData.push(0)
          } else {
            maxData.push(filteredDataFinal[j].data[2].max)
          }
          //   medianData.push(filteredDataFinal[j].data[2].median)
          //   maxData.push(filteredDataFinal[j].data[2].max)
        }
        finalResult = []
        finalResult = indexData.filter((d: number) => d === 4)

        if (finalResult.length === 0) {
          minData.push(0)
          medianData.push(0)
          maxData.push(0)
        } else {
          if (filteredDataFinal[j].data.length === 2) {
            minData.push(filteredDataFinal[j].data[1].min)
            if (
              filteredDataFinal[j].data[1].median ===
              filteredDataFinal[j].data[1].min
            ) {
              medianData.push(0)
            } else {
              medianData.push(filteredDataFinal[j].data[1].median)
            }
            if (
              filteredDataFinal[j].data[1].max ===
              filteredDataFinal[j].data[1].median
            ) {
              maxData.push(0)
            } else {
              maxData.push(filteredDataFinal[j].data[1].max)
            }
          } else if (filteredData[j].data.length === 3) {
            minData.push(filteredDataFinal[j].data[2].min)
            if (
              filteredDataFinal[j].data[2].median ===
              filteredDataFinal[j].data[2].min
            ) {
              medianData.push(0)
            } else {
              medianData.push(filteredDataFinal[j].data[1].median)
            }
            if (
              filteredDataFinal[j].data[2].max ===
              filteredDataFinal[j].data[2].median
            ) {
              maxData.push(0)
            } else {
              maxData.push(filteredDataFinal[j].data[2].max)
            }
          } else {
            minData.push(filteredDataFinal[j].data[3].min)
            if (
              filteredDataFinal[j].data[3].median ===
              filteredDataFinal[j].data[3].min
            ) {
              medianData.push(0)
            } else {
              medianData.push(filteredDataFinal[j].data[3].median)
            }
            if (
              filteredDataFinal[j].data[3].max ===
              filteredDataFinal[j].data[3].median
            ) {
              maxData.push(0)
            } else {
              maxData.push(filteredDataFinal[j].data[3].max)
            }
          }
        }

        const minArray: SummaryData = {
          name: `Minimum-${
            filteredDataFinal[j].strataName.length === 0
              ? 'No Strata (Base Cohort)'
              : filteredDataFinal[j].strataName
          }`,
          data: minData,
          group: `${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMin(strataFilterDataUpdated[m].dummyId),
        }

        postProcessedData.push(minArray)
        const medianObj: SummaryData = {
          name: `Median-${
            filteredDataFinal[j].strataName.length === 0
              ? 'No Strata (Base Cohort)'
              : filteredDataFinal[j].strataName
          }`,
          data: medianData,
          group: `${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMedian(strataFilterDataUpdated[m].dummyId),
        }
        postProcessedData.push(medianObj)
        const maxObj: SummaryData = {
          name: `Maximum-${
            filteredDataFinal[j].strataName.length === 0
              ? 'No Strata (Base Cohort)'
              : filteredDataFinal[j].strataName
          }`,
          data: maxData,
          group: `${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMax(strataFilterDataUpdated[m].dummyId),
        }
        postProcessedData.push(maxObj)
      }
    }

    outComeData.push({
      analysisName: analyticData[0].name,
      summaryData: postProcessedData,
    })
  }

  return outComeData
}

export function getCohortDataForFilterForSinglePercentile(
  response: any,
  analysisFilter: string[],
  inputData: Filters[],
  cohortFilters: string[],
  percentile: string
): CohortDisplayData[] {
  let postProcessedData: SummaryData[] = []
  let minData: number[] = []
  let p10Data: number[] = []
  let p25Data: number[] = []
  let indexData: number[] = []
  let medianData: number[] = []
  let p75Data: number[] = []
  let p90Data: number[] = []
  let avgData: number[] = []
  let maxData: number[] = []
  let finalResult: number[] = []

  const outComeData: CohortDisplayData[] = []
  const filterAnalysis: number[] = []
  const cohortAnalysisFilter: number[] = []
  for (let n = 0; n < analysisFilter.length; n++) {
    filterAnalysis.push(parseInt(analysisFilter[n], 10))
  }

  for (let n = 0; n < cohortFilters.length; n++) {
    cohortAnalysisFilter.push(parseInt(cohortFilters[n], 10))
  }
  cohortAnalysisFilter.sort()
  filterAnalysis.sort()
  for (let i = 0; i < filterAnalysis.length; i++) {
    postProcessedData = []

    const filteredData = response.filter(
      (d: any) => d.analysisId === filterAnalysis[i]
    )

    const analyticData = inputData.filter(
      (d: Filters) => parseInt(d.id, 10) === filterAnalysis[i]
    )

    for (let m = 0; m < cohortAnalysisFilter.length; m++) {
      const filteredDataFinal = filteredData.filter(
        (d: any) => d.strataId === cohortAnalysisFilter[m]
      )

      for (let j = 0; j < filteredDataFinal.length; j++) {
        minData = []
        medianData = []
        maxData = []
        indexData = []
        finalResult = []
        p10Data = []
        p25Data = []
        p75Data = []
        p90Data = []
        avgData = []

        for (let k = 0; k < filteredDataFinal[j].data.length; k++) {
          indexData.push(filteredDataFinal[j].data[k].time_norm_idx)
        }

        finalResult = indexData.filter((d: number) => d === 0)

        if (finalResult.length === 0) {
          minData.push(0)
          medianData.push(0)
          maxData.push(0)
          p10Data.push(0)
          p25Data.push(0)
          p75Data.push(0)
          p90Data.push(0)
          avgData.push(0)
        } else {
          minData.push(filteredDataFinal[j].data[0].min)
          medianData.push(
            Number(filteredDataFinal[j].data[0].median.toFixed(2))
          )
          maxData.push(filteredDataFinal[j].data[0].max)
          p10Data.push(Number(filteredDataFinal[j].data[0].p10.toFixed(2)))
          p25Data.push(Number(filteredDataFinal[j].data[0].p25.toFixed(2)))
          p75Data.push(Number(filteredDataFinal[j].data[0].p75.toFixed(2)))
          p90Data.push(Number(filteredDataFinal[j].data[0].p90.toFixed(2)))

          avgData.push(Number(filteredDataFinal[j].data[0].avg.toFixed(2)))
        }
        finalResult = []
        finalResult = indexData.filter((d: number) => d === 1)

        if (finalResult.length === 0) {
          minData.push(0)
          medianData.push(0)
          maxData.push(0)
          p10Data.push(0)
          p25Data.push(0)
          p75Data.push(0)
          p90Data.push(0)
          avgData.push(0)
        } else {
          minData.push(Number(filteredDataFinal[j].data[1].min.toFixed(2)))
          medianData.push(filteredDataFinal[j].data[1].median.toFixed(2))
          maxData.push(filteredDataFinal[j].data[1].max.toFixed(2))
          p10Data.push(filteredDataFinal[j].data[1].p10.toFixed(2))
          p25Data.push(filteredDataFinal[j].data[1].p25.toFixed(2))
          p75Data.push(filteredDataFinal[j].data[1].p75.toFixed(2))
          p90Data.push(filteredDataFinal[j].data[1].p90.toFixed(2))

          avgData.push(Number(filteredDataFinal[j].data[1].avg.toFixed(2)))
        }
        finalResult = []
        finalResult = indexData.filter((d: number) => d === 3)

        if (finalResult.length === 0) {
          minData.push(0)
          medianData.push(0)
          maxData.push(0)
          p10Data.push(0)
          p25Data.push(0)
          p75Data.push(0)
          p90Data.push(0)
          avgData.push(0)
        } else {
          minData.push(Number(filteredDataFinal[j].data[2].min.toFixed(2)))
          medianData.push(filteredDataFinal[j].data[2].median.toFixed(2))
          maxData.push(filteredDataFinal[j].data[2].max.toFixed(2))
          p10Data.push(filteredDataFinal[j].data[2].p10.toFixed(2))
          p25Data.push(filteredDataFinal[j].data[2].p25.toFixed(2))
          p75Data.push(filteredDataFinal[j].data[2].p75.toFixed(2))
          p90Data.push(filteredDataFinal[j].data[2].p90.toFixed(2))

          //   avgData.push(Number(filteredDataFinal[j].data[1].avg.toFixed(2)))
          //   minData.push(filteredDataFinal[j].data[2].min)
          //   medianData.push(filteredDataFinal[j].data[2].median)
          //   maxData.push(filteredDataFinal[j].data[2].max)
          //   p10Data.push(filteredDataFinal[j].data[2].p10)
          //   p25Data.push(filteredDataFinal[j].data[2].p25)
          //   p75Data.push(filteredDataFinal[j].data[2].p75)
          //   p90Data.push(filteredDataFinal[j].data[2].p90)

          avgData.push(Number(filteredDataFinal[j].data[2].avg.toFixed(2)))
        }
        finalResult = []
        finalResult = indexData.filter((d: number) => d === 4)

        if (finalResult.length === 0) {
          minData.push(0)
          medianData.push(0)
          maxData.push(0)
          p10Data.push(0)
          p25Data.push(0)
          p75Data.push(0)
          p90Data.push(0)
          avgData.push(0)
        } else {
          if (filteredDataFinal[j].data.length === 2) {
            // minData.push(filteredDataFinal[j].data[1].min)
            // medianData.push(filteredDataFinal[j].data[1].median)
            // maxData.push(filteredDataFinal[j].data[1].max)
            // p10Data.push(filteredDataFinal[j].data[1].p10)
            // p25Data.push(filteredDataFinal[j].data[1].p25)
            // p75Data.push(filteredDataFinal[j].data[1].p75)
            // p90Data.push(filteredDataFinal[j].data[1].p90)
            // avgData.push(Number(filteredDataFinal[j].data[1].avg.toFixed(2)))

            minData.push(Number(filteredDataFinal[j].data[1].min.toFixed(2)))
            medianData.push(filteredDataFinal[j].data[1].median.toFixed(2))
            maxData.push(filteredDataFinal[j].data[1].max.toFixed(2))
            p10Data.push(filteredDataFinal[j].data[1].p10.toFixed(2))
            p25Data.push(filteredDataFinal[j].data[1].p25.toFixed(2))
            p75Data.push(filteredDataFinal[j].data[1].p75.toFixed(2))
            p90Data.push(filteredDataFinal[j].data[1].p90.toFixed(2))
            avgData.push(Number(filteredDataFinal[j].data[1].avg.toFixed(2)))
          } else if (filteredData[j].data.length === 3) {
            minData.push(Number(filteredDataFinal[j].data[2].min.toFixed(2)))
            medianData.push(filteredDataFinal[j].data[2].median.toFixed(2))
            maxData.push(filteredDataFinal[j].data[2].max.toFixed(2))
            p10Data.push(filteredDataFinal[j].data[2].p10.toFixed(2))
            p25Data.push(filteredDataFinal[j].data[2].p25.toFixed(2))
            p75Data.push(filteredDataFinal[j].data[2].p75.toFixed(2))
            p90Data.push(filteredDataFinal[j].data[2].p90.toFixed(2))
            avgData.push(Number(filteredDataFinal[j].data[2].avg.toFixed(2)))
            // minData.push(filteredData[j].data[2].min)
            // medianData.push(filteredData[j].data[2].median)
            // maxData.push(filteredData[j].data[2].max)
            // p10Data.push(filteredData[j].data[2].p10)
            // p25Data.push(filteredData[j].data[2].p25)
            // p75Data.push(filteredData[j].data[2].p75)
            // p90Data.push(filteredData[j].data[2].p90)
            // avgData.push(filteredData[j].data[2].avg)
          } else {
            minData.push(Number(filteredDataFinal[j].data[3].min.toFixed(2)))
            medianData.push(filteredDataFinal[j].data[3].median.toFixed(2))
            maxData.push(filteredDataFinal[j].data[3].max.toFixed(2))
            p10Data.push(filteredDataFinal[j].data[3].p10.toFixed(2))
            p25Data.push(filteredDataFinal[j].data[3].p25.toFixed(2))
            p75Data.push(filteredDataFinal[j].data[3].p75.toFixed(2))
            p90Data.push(filteredDataFinal[j].data[3].p90.toFixed(2))
            avgData.push(Number(filteredDataFinal[j].data[3].avg.toFixed(2)))

            // minData.push(filteredDataFinal[j].data[3].min)
            // medianData.push(filteredDataFinal[j].data[3].median)
            // maxData.push(filteredDataFinal[j].data[3].max)
            // p10Data.push(filteredDataFinal[j].data[3].p10)
            // p25Data.push(filteredDataFinal[j].data[3].p25)
            // p75Data.push(filteredDataFinal[j].data[3].p75)
            // p90Data.push(filteredDataFinal[j].data[3].p90)
            // avgData.push(Number(filteredDataFinal[j].data[3].avg.toFixed(2)))
          }
        }

        if (percentile === 'min') {
          const minArray: SummaryData = {
            name: `Min-${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
            data: minData,
            group: `${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(filteredDataFinal[j].group_idx),
          }

          postProcessedData.push(minArray)
        }
        if (percentile === 'p10') {
          const p10Obj: SummaryData = {
            name: `P10-${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
            data: p10Data,
            group: `${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(filteredDataFinal[j].group_idx),
          }
          postProcessedData.push(p10Obj)
        }
        if (percentile === 'p25') {
          const p25Obj: SummaryData = {
            name: `P25-${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
            data: p25Data,
            group: `${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(filteredDataFinal[j].group_idx),
          }
          postProcessedData.push(p25Obj)
        }
        if (percentile === 'median') {
          const medianObj: SummaryData = {
            name: `Median-${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
            data: medianData,
            group: `${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(filteredDataFinal[j].group_idx),
          }
          postProcessedData.push(medianObj)
        }
        if (percentile === 'p75') {
          const p75Obj: SummaryData = {
            name: `P75-${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
            data: p75Data,
            group: `${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(filteredDataFinal[j].group_idx),
          }
          postProcessedData.push(p75Obj)
        }
        if (percentile === 'p90') {
          const p90Obj: SummaryData = {
            name: `P90-${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
            data: p90Data,
            group: `${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(filteredDataFinal[j].group_idx),
          }
          postProcessedData.push(p90Obj)
        }
        if (percentile === 'max') {
          const maxObj: SummaryData = {
            name: `Max-${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
            data: maxData,
            group: `${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(filteredDataFinal[j].group_idx),
          }
          postProcessedData.push(maxObj)
        }
        if (percentile === 'avg') {
          const avjObj: SummaryData = {
            name: `Avg-${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
            data: avgData,
            group: `${filteredDataFinal[j].analysisName}--${filteredDataFinal[j].cohortName}--${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(filteredDataFinal[j].group_idx),
          }
          postProcessedData.push(avjObj)
        }
      }
    }

    outComeData.push({
      analysisName: analyticData[0].name,
      summaryData: postProcessedData,
    })
  }

  return outComeData
}

export function getCohortDataForFilterForSinglePercentileForLineGraph(
  response: any,
  analysisFilter: string[],
  inputData: Filters[],
  cohortFilters: string[],
  percentile: string
): CohortDisplayData[] {
  let postProcessedData: SummaryData[] = []
  let minData: number | null[] = []
  let p10Data: number | null[] = []
  let p25Data: number | null[] = []
  let indexData: number[] = []
  let medianData: number | null[] = []
  let p75Data: number | null[] = []
  let p90Data: number | null[] = []
  let avgData: number | null[] = []
  let maxData: number | null[] = []
  let finalResult: number[] = []

  const outComeData: CohortDisplayData[] = []
  const filterAnalysis: number[] = []
  const cohortAnalysisFilter: number[] = []
  for (let n = 0; n < analysisFilter.length; n++) {
    filterAnalysis.push(parseInt(analysisFilter[n], 10))
  }

  for (let n = 0; n < cohortFilters.length; n++) {
    cohortAnalysisFilter.push(parseInt(cohortFilters[n], 10))
  }
  cohortAnalysisFilter.sort()
  filterAnalysis.sort()
  for (let i = 0; i < filterAnalysis.length; i++) {
    postProcessedData = []

    const filteredData = response.filter(
      (d: any) => d.analysisId === filterAnalysis[i]
    )

    const analyticData = inputData.filter(
      (d: Filters) => d.id === analysisFilter[i]
    )

    for (let m = 0; m < cohortAnalysisFilter.length; m++) {
      const filteredDataFinal = filteredData.filter(
        (d: any) => d.strataId === cohortAnalysisFilter[m]
      )

      for (let j = 0; j < filteredDataFinal.length; j++) {
        minData = []
        medianData = []
        maxData = []
        indexData = []
        finalResult = []
        p10Data = []
        p25Data = []
        p75Data = []
        p90Data = []
        avgData = []

        for (let k = 0; k < filteredDataFinal[j].data.length; k++) {
          indexData.push(filteredDataFinal[j].data[k].time_norm_idx)
        }

        finalResult = indexData.filter((d: number) => d === 0)

        if (finalResult.length === 0) {
          minData.push(null)
          medianData.push(null)
          maxData.push(null)
          p10Data.push(null)
          p25Data.push(null)
          p75Data.push(null)
          p90Data.push(null)
          avgData.push(null)
        } else {
          minData.push(filteredDataFinal[j].data[0].min)
          medianData.push(filteredDataFinal[j].data[0].median)
          maxData.push(filteredDataFinal[j].data[0].max)
          p10Data.push(filteredDataFinal[j].data[0].p10)
          p25Data.push(filteredDataFinal[j].data[0].p25)
          p75Data.push(filteredDataFinal[j].data[0].p75)
          p90Data.push(filteredDataFinal[j].data[0].p90)

          avgData.push(filteredDataFinal[j].data[0].avg)
        }
        finalResult = []
        finalResult = indexData.filter((d: number) => d === 1)

        if (finalResult.length === 0) {
          minData.push(null)
          medianData.push(null)
          maxData.push(null)
          p10Data.push(null)
          p25Data.push(null)
          p75Data.push(null)
          p90Data.push(null)
          avgData.push(null)
        } else {
          minData.push(filteredDataFinal[j].data[1].min)
          medianData.push(filteredDataFinal[j].data[1].median)
          maxData.push(filteredDataFinal[j].data[1].max)
          p10Data.push(filteredDataFinal[j].data[1].p10)
          p25Data.push(filteredDataFinal[j].data[1].p25)
          p75Data.push(filteredDataFinal[j].data[1].p75)
          p90Data.push(filteredDataFinal[j].data[1].p90)

          avgData.push(filteredDataFinal[j].data[1].avg)
        }
        finalResult = []
        finalResult = indexData.filter((d: number) => d === 3)

        if (finalResult.length === 0) {
          minData.push(null)
          medianData.push(null)
          maxData.push(null)
          p10Data.push(null)
          p25Data.push(null)
          p75Data.push(null)
          p90Data.push(null)
          avgData.push(null)
        } else {
          minData.push(filteredDataFinal[j].data[2].min)
          medianData.push(filteredDataFinal[j].data[2].median)
          maxData.push(filteredDataFinal[j].data[2].max)
          p10Data.push(filteredDataFinal[j].data[2].p10)
          p25Data.push(filteredDataFinal[j].data[2].p25)
          p75Data.push(filteredDataFinal[j].data[2].p75)
          p90Data.push(filteredDataFinal[j].data[2].p90)
          avgData.push(filteredDataFinal[j].data[2].avg)

          //   avgData.push(Number(filteredDataFinal[j].data[2].avg.toFixed(2)))
        }
        finalResult = []
        finalResult = indexData.filter((d: number) => d === 4)

        if (finalResult.length === 0) {
          minData.push(null)
          medianData.push(null)
          maxData.push(null)
          p10Data.push(null)
          p25Data.push(null)
          p75Data.push(null)
          p90Data.push(null)
          avgData.push(null)
        } else {
          if (filteredDataFinal[j].data.length === 2) {
            minData.push(filteredDataFinal[j].data[1].min)
            medianData.push(filteredDataFinal[j].data[1].median)
            maxData.push(filteredDataFinal[j].data[1].max)
            p10Data.push(filteredDataFinal[j].data[1].p10)
            p25Data.push(filteredDataFinal[j].data[1].p25)
            p75Data.push(filteredDataFinal[j].data[1].p75)
            p90Data.push(filteredDataFinal[j].data[1].p90)
            avgData.push(filteredDataFinal[j].data[1].avg)
            // avgData.push(Number(filteredDataFinal[j].data[1].avg.toFixed(2)))
          } else if (filteredData[j].data.length === 3) {
            minData.push(filteredDataFinal[j].data[2].min)
            medianData.push(filteredDataFinal[j].data[2].median)
            maxData.push(filteredDataFinal[j].data[2].max)
            p10Data.push(filteredDataFinal[j].data[2].p10)
            p25Data.push(filteredDataFinal[j].data[2].p25)
            p75Data.push(filteredDataFinal[j].data[2].p75)
            p90Data.push(filteredDataFinal[j].data[2].p90)
            avgData.push(filteredDataFinal[j].data[2].avg)
          } else {
            minData.push(filteredDataFinal[j].data[3].min)
            medianData.push(filteredDataFinal[j].data[3].median)
            maxData.push(filteredDataFinal[j].data[3].max)
            p10Data.push(filteredDataFinal[j].data[3].p10)
            p25Data.push(filteredDataFinal[j].data[3].p25)
            p75Data.push(filteredDataFinal[j].data[3].p75)
            p90Data.push(filteredDataFinal[j].data[3].p90)
            avgData.push(filteredDataFinal[j].data[3].avg)
          }
        }
        if (percentile === 'min') {
          const minArray: SummaryData = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `Min-${filteredDataFinal[j].strataName}`
                : 'Min',
            data: minData,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(filteredDataFinal[j].group_idx),
          }

          postProcessedData.push(minArray)
        }
        if (percentile === 'p10') {
          const p10Obj: SummaryData = {
            name: `P10-${filteredDataFinal[j].strataName}`,
            data: p10Data,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(filteredDataFinal[j].group_idx),
          }
          postProcessedData.push(p10Obj)
        }
        if (percentile === 'p25') {
          const p25Obj: SummaryData = {
            name: `P25-${filteredDataFinal[j].strataName}`,
            data: p25Data,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(filteredDataFinal[j].group_idx),
          }
          postProcessedData.push(p25Obj)
        }
        if (percentile === 'median') {
          const medianObj: SummaryData = {
            name: `Median-${filteredDataFinal[j].strataName}`,
            data: medianData,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(filteredDataFinal[j].group_idx),
          }
          postProcessedData.push(medianObj)
        }
        if (percentile === 'p75') {
          const p75Obj: SummaryData = {
            name: `P75-${filteredDataFinal[j].strataName}`,
            data: p75Data,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(filteredDataFinal[j].group_idx),
          }
          postProcessedData.push(p75Obj)
        }
        if (percentile === 'p90') {
          const p90Obj: SummaryData = {
            name: `P90-${filteredDataFinal[j].strataName}`,
            data: p90Data,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(filteredDataFinal[j].group_idx),
          }
          postProcessedData.push(p90Obj)
        }
        if (percentile === 'max') {
          const maxObj: SummaryData = {
            name: `Max-${filteredDataFinal[j].strataName}`,
            data: maxData,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(filteredDataFinal[j].group_idx),
          }
          postProcessedData.push(maxObj)
        }
        if (percentile === 'avg') {
          const avjObj: SummaryData = {
            name: `Avg-${filteredDataFinal[j].strataName}`,
            data: avgData,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(filteredDataFinal[j].group_idx),
          }
          postProcessedData.push(avjObj)
        }
      }
    }

    outComeData.push({
      analysisName: analyticData[0].name,
      summaryData: postProcessedData,
    })
  }

  return outComeData
}

export function getCohortDataForFilterForSinglePercentileForLineGraphWithCount(
  response: any,
  analysisFilter: string[],
  inputData: Filters[],
  cohortFilters: string[],
  strataFilter: Filters[],
  percentile: string
): CohortDisplayWithCount[] {
  let postProcessedData: SummaryDataWithCount[] = []
  let minData: any[] = []
  let p10Data: any[] = []
  let p25Data: any[] = []
  let indexData: number[] = []
  let medianData: any[] = []
  let p75Data: any[] = []
  let p90Data: any[] = []
  let avgData: any[] = []
  let maxData: any[] = []

  let finalResult: number[] = []

  const strataFilterDataUpdated: any[] = []

  const outComeData: CohortDisplayWithCount[] = []
  const filterAnalysis: number[] = []
  const cohortAnalysisFilter: number[] = []
  for (let n = 0; n < analysisFilter.length; n++) {
    filterAnalysis.push(parseInt(analysisFilter[n], 10))
  }

  for (let n = 0; n < cohortFilters.length; n++) {
    cohortAnalysisFilter.push(parseInt(cohortFilters[n], 10))
  }
  cohortAnalysisFilter.sort()
  filterAnalysis.sort()

  for (let n = 0; n < cohortAnalysisFilter.length; n++) {
    const coloredArray = strataFilter.filter(
      (d: Filters) => parseInt(d.id, 10) === cohortAnalysisFilter[n]
    )

    strataFilterDataUpdated.push({
      id: parseInt(coloredArray[0].id, 10),
      dummyId: coloredArray[0].dummyId,
      color: coloredArray[0].color,
    })
  }

  for (let i = 0; i < filterAnalysis.length; i++) {
    postProcessedData = []

    const filteredData = response.filter(
      (d: any) => d.analysisId === filterAnalysis[i]
    )

    const analyticData = inputData.filter(
      (d: Filters) => parseInt(d.id, 10) === filterAnalysis[i]
    )

    for (let m = 0; m < strataFilterDataUpdated.length; m++) {
      const filteredDataFinal = filteredData.filter(
        (d: any) => d.strataId === strataFilterDataUpdated[m].id
      )

      for (let j = 0; j < filteredDataFinal.length; j++) {
        minData = []
        medianData = []
        maxData = []
        indexData = []
        finalResult = []
        p10Data = []
        p25Data = []
        p75Data = []
        p90Data = []
        avgData = []

        for (let k = 0; k < filteredDataFinal[j].data.length; k++) {
          indexData.push(filteredDataFinal[j].data[k].time_norm_idx)
        }

        finalResult = indexData.filter((d: number) => d === 0)

        if (finalResult.length === 0) {
          minData.push({
            x: 'Admission Date',
            y: 0,
            meta: '',
            goals: '',
          })
          medianData.push({
            x: 'Admission Date',
            y: 0,
            meta: '',
            goals: '',
          })
          maxData.push({
            x: 'Admission Date',
            y: 0,
            meta: ' ',
            goals: '',
          })
          p10Data.push({
            x: 'Admission Date',
            y: 0,
            meta: ' ',
            goals: '',
          })
          p25Data.push({
            x: 'Admission Date',
            y: 0,
            meta: ' ',
            goals: '',
          })
          p75Data.push({
            x: 'Admission Date',
            y: 0,
            meta: ' ',
            goals: '',
          })
          p90Data.push({
            x: 'Admission Date',
            y: 0,
            meta: ' ',
            goals: '',
          })
          avgData.push({
            x: 'Admission Date',
            y: 0,
            meta: ' ',
            goals: '',
          })
        } else {
          const minLabel = calculatePeopleAtPercentile(
            'min',
            filteredDataFinal[j].data[0].count
          )
          const p10Label = calculatePeopleAtPercentile(
            'p10',
            filteredDataFinal[j].data[0].count
          )
          const p25Label = calculatePeopleAtPercentile(
            'p25',
            filteredDataFinal[j].data[0].count
          )
          const medianLabel = calculatePeopleAtPercentile(
            'median',
            filteredDataFinal[j].data[0].count
          )
          const p75Label = calculatePeopleAtPercentile(
            'p75',
            filteredDataFinal[j].data[0].count
          )
          const p90Label = calculatePeopleAtPercentile(
            'p90',
            filteredDataFinal[j].data[0].count
          )
          const maxLabel = calculatePeopleAtPercentile(
            'max',
            filteredDataFinal[j].data[0].count
          )
          const avgLabel = calculatePeopleAtPercentile(
            'avg',
            filteredDataFinal[j].data[0].count
          )
          minData.push({
            x: 'Admission Date',
            y: Number(filteredDataFinal[j].data[0].min.toFixed(2)),
            meta: `${minLabel.pLabel}: ${filteredDataFinal[j].data[0].min} | ${
              minLabel.pCount
            } of ${minLabel.totalCount} with value <= ${Number(
              filteredDataFinal[j].data[0].min.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[0].count,
          })
          medianData.push({
            x: 'Admission Date',
            y: Number(filteredDataFinal[j].data[0].median.toFixed(2)),
            meta: `${medianLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[0].median.toFixed(2)
            )} | ${medianLabel.pCount} of ${
              medianLabel.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[0].median.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[0].count,
          })
          maxData.push({
            x: 'Admission Date',
            y: Number(filteredDataFinal[j].data[0].max.toFixed(2)),
            meta: `${maxLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[0].max.toFixed(2)
            )} | ${maxLabel.pCount} of ${
              maxLabel.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[0].max.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[0].count,
          })
          p10Data.push({
            x: 'Admission Date',
            y: Number(filteredDataFinal[j].data[0].p10.toFixed(2)),
            meta: `${p10Label.pLabel}: ${Number(
              filteredDataFinal[j].data[0].p10.toFixed(2)
            )} | ${p10Label.pCount} of ${
              p10Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[0].p10.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[0].count,
          })
          p25Data.push({
            x: 'Admission Date',
            y: Number(filteredDataFinal[j].data[0].p25.toFixed(2)),
            meta: `${p25Label.pLabel}: ${Number(
              filteredDataFinal[j].data[0].p25.toFixed(2)
            )} | ${p25Label.pCount} of ${
              p25Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[0].p25.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[0].count,
          })
          p75Data.push({
            x: 'Admission Date',
            y: Number(filteredDataFinal[j].data[0].p75.toFixed(2)),
            meta: `${p75Label.pLabel}: ${Number(
              filteredDataFinal[j].data[0].p75.toFixed(2)
            )} | ${p75Label.pCount} of ${
              p75Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[0].p75.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[0].count,
          })
          p90Data.push({
            x: 'Admission Date',
            y: Number(filteredDataFinal[j].data[0].p90.toFixed(2)),
            meta: `${p90Label.pLabel}: ${Number(
              filteredDataFinal[j].data[0].p90.toFixed(2)
            )} | ${p90Label.pCount} of ${
              p90Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[0].p90.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[0].count,
          })
          avgData.push({
            x: 'Admission Date',
            y: Number(filteredDataFinal[j].data[0].avg.toFixed(2)),
            meta: `${avgLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[0].avg.toFixed(2)
            )} | ${avgLabel.pCount} of ${
              avgLabel.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[0].avg.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[0].count,
          })
          //   p90Data.push(filteredDataFinal[j].data[0].p90)
          //   //   minData.push(filteredDataFinal[j].data[0].min)
          //   medianData.push(filteredDataFinal[j].data[0].median)
          //   maxData.push(filteredDataFinal[j].data[0].max)
          //   p10Data.push(filteredDataFinal[j].data[0].p10)
          //   p25Data.push(filteredDataFinal[j].data[0].p25)
          //   p75Data.push(filteredDataFinal[j].data[0].p75)
          //   p90Data.push(filteredDataFinal[j].data[0].p90)

          //   avgData.push(filteredDataFinal[j].data[0].avg)
        }
        finalResult = []
        finalResult = indexData.filter((d: number) => d === 1)

        if (finalResult.length === 0) {
          minData.push({
            x: 'Admission Date + 1',
            y: null,
            meta: ' ',
            goals: '',
          })
          medianData.push({
            x: 'Admission Date + 1',
            y: 0,
            meta: ' ',
            goals: '',
          })
          maxData.push({
            x: 'Admission Date + 1',
            y: null,
            meta: ' ',
            goals: '',
          })
          p10Data.push({
            y: 0,
            x: 'Admission Date + 1',
            meta: ' ',
            goals: '',
          })
          p25Data.push({
            y: 0,
            x: 'Admission Date + 1',
            meta: ' ',
            goals: '',
          })
          p75Data.push({
            y: 0,
            x: 'Admission Date + 1',
            meta: ' ',
            goals: '',
          })
          p90Data.push({
            y: 0,
            x: 'Admission Date + 1',
            meta: ' ',
            goals: '',
          })
          avgData.push({
            y: 0,
            x: 'Admission Date + 1',
            meta: ' ',
            goals: '',
          })
        } else {
          const minLabel1 = calculatePeopleAtPercentile(
            'min',
            filteredDataFinal[j].data[1].count
          )
          const p10Label1 = calculatePeopleAtPercentile(
            'p10',
            filteredDataFinal[j].data[1].count
          )
          const p25Label1 = calculatePeopleAtPercentile(
            'p25',
            filteredDataFinal[j].data[1].count
          )
          const medianLabel1 = calculatePeopleAtPercentile(
            'median',
            filteredDataFinal[j].data[1].count
          )
          const p75Label1 = calculatePeopleAtPercentile(
            'p75',
            filteredDataFinal[j].data[1].count
          )
          const p90Label1 = calculatePeopleAtPercentile(
            'p90',
            filteredDataFinal[j].data[1].count
          )
          const maxLabel1 = calculatePeopleAtPercentile(
            'max',
            filteredDataFinal[j].data[1].count
          )
          const avgLabel1 = calculatePeopleAtPercentile(
            'avg',
            filteredDataFinal[j].data[1].count
          )
          minData.push({
            x: 'Admission Date + 1',
            y: Number(filteredDataFinal[j].data[1].min.toFixed(2)),
            meta: `${minLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].min.toFixed(2)
            )} | ${minLabel1.pCount} of ${
              minLabel1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].min.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
          })
          medianData.push({
            x: 'Admission Date + 1',
            y: Number(filteredDataFinal[j].data[1].median.toFixed(2)),
            meta: `${medianLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].median.toFixed(2)
            )} | ${medianLabel1.pCount} of ${
              medianLabel1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].median.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
          })
          maxData.push({
            y: Number(filteredDataFinal[j].data[1].max.toFixed(2)),
            meta: `${maxLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].max.toFixed(2)
            )} | ${maxLabel1.pCount} of ${
              maxLabel1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].max.toFixed(2)
            )}`,

            x: 'Admission Date + 1',
            goals: filteredDataFinal[j].data[1].count,
          })
          p10Data.push({
            x: 'Admission Date + 1',
            y: Number(filteredDataFinal[j].data[1].p10.toFixed(2)),
            meta: `${p10Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p10.toFixed(2)
            )} | ${p10Label1.pCount} of ${
              p10Label1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].p10.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
          })
          p25Data.push({
            x: 'Admission Date + 1',
            y: Number(filteredDataFinal[j].data[1].p25.toFixed(2)),
            meta: `${p25Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p25.toFixed(2)
            )} | ${p25Label1.pCount} of ${
              p25Label1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].p25.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
          })
          p75Data.push({
            x: 'Admission Date + 1',
            y: Number(filteredDataFinal[j].data[1].p75.toFixed(2)),
            meta: `${p75Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p75.toFixed(2)
            )} | ${p75Label1.pCount} of ${
              p75Label1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].p75.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
          })
          p90Data.push({
            x: 'Admission Date + 1',
            y: Number(filteredDataFinal[j].data[1].p90.toFixed(2)),
            meta: `${p90Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p90.toFixed(2)
            )} | ${p90Label1.pCount} of ${
              p90Label1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].p90.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
          })
          avgData.push({
            x: 'Admission Date + 1',
            y: Number(filteredDataFinal[j].data[1].avg.toFixed(2)),
            meta: `${avgLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].avg.toFixed(2)
            )} | ${avgLabel1.pCount} of ${
              avgLabel1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].avg.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
          })
        }
        finalResult = []
        finalResult = indexData.filter((d: number) => d === 3)

        if (finalResult.length === 0) {
          minData.push({
            x: 'Discharge Date - 1',
            y: null,
            meta: ' ',
            goals: '',
          })
          medianData.push({
            y: 0,
            meta: ' ',
            goals: '',
            x: 'Discharge Date - 1',
          })
          maxData.push({
            y: null,
            meta: ' ',
            goals: '',
            x: 'Discharge Date - 1',
          })
          maxData.push({
            y: null,
            meta: ' ',
            goals: '',
            x: 'Discharge Date - 1',
          })
          p10Data.push({
            y: null,
            meta: ' ',
            goals: '',
            x: 'Discharge Date - 1',
          })
          p25Data.push({
            y: null,
            meta: ' ',
            goals: '',
            x: 'Discharge Date - 1',
          })
          p75Data.push({
            y: null,
            meta: ' ',
            goals: '',
            x: 'Discharge Date - 1',
          })
          p90Data.push({
            y: null,
            meta: ' ',
            goals: '',
            x: 'Discharge Date - 1',
          })
          avgData.push({
            y: null,
            meta: ' ',
            goals: '',
            x: 'Discharge Date - 1',
          })
        } else {
          const minLabel2 = calculatePeopleAtPercentile(
            'min',
            filteredDataFinal[j].data[2].count
          )
          const p10Label2 = calculatePeopleAtPercentile(
            'p10',
            filteredDataFinal[j].data[2].count
          )
          const p25Label2 = calculatePeopleAtPercentile(
            'p25',
            filteredDataFinal[j].data[2].count
          )
          const medianLabel2 = calculatePeopleAtPercentile(
            'median',
            filteredDataFinal[j].data[2].count
          )
          const p75Label2 = calculatePeopleAtPercentile(
            'p75',
            filteredDataFinal[j].data[2].count
          )
          const p90Label2 = calculatePeopleAtPercentile(
            'p90',
            filteredDataFinal[j].data[2].count
          )
          const maxLabel2 = calculatePeopleAtPercentile(
            'max',
            filteredDataFinal[j].data[2].count
          )
          const avgLabel2 = calculatePeopleAtPercentile(
            'avg',
            filteredDataFinal[j].data[2].count
          )
          minData.push({
            y: Number(filteredDataFinal[j].data[2].min.toFixed(2)),
            meta: `${minLabel2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].min.toFixed(2)
            )} | ${minLabel2.pCount} of ${
              minLabel2.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].min.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date - 1',
          })
          medianData.push({
            y: Number(filteredDataFinal[j].data[2].median.toFixed(2)),
            meta: `${medianLabel2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].median.toFixed(2)
            )} | ${medianLabel2.pCount} of ${
              medianLabel2.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].median.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date - 1',
          })
          maxData.push({
            y: Number(filteredDataFinal[j].data[2].max.toFixed(2)),
            meta: `${maxLabel2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].max.toFixed(2)
            )} | ${maxLabel2.pCount} of ${
              maxLabel2.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].max.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date - 1',
          })
          p10Data.push({
            y: Number(filteredDataFinal[j].data[2].p10.toFixed(2)),
            meta: `${p10Label2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p10.toFixed(2)
            )} | ${p10Label2.pCount} of ${
              p10Label2.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].p10.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date - 1',
          })
          p25Data.push({
            y: Number(filteredDataFinal[j].data[2].p25.toFixed(2)),
            meta: `${p25Label2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p25.toFixed(2)
            )} | ${p25Label2.pCount} of ${
              p25Label2.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].p25.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date - 1',
          })

          p75Data.push({
            x: 'Discharge Date - 1',
            y: Number(filteredDataFinal[j].data[2].p75.toFixed(2)),
            meta: `${p75Label2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p75.toFixed(2)
            )} | ${p75Label2.pCount} of ${
              p75Label2.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].p75.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
          })
          p90Data.push({
            x: 'Discharge Date - 1',
            y: Number(filteredDataFinal[j].data[2].p90.toFixed(2)),
            meta: `${p90Label2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p90.toFixed(2)
            )} | ${p90Label2.pCount} of ${
              p90Label2.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].p90.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
          })
          avgData.push({
            x: 'Discharge Date - 1',
            y: Number(filteredDataFinal[j].data[2].avg.toFixed(2)),
            meta: `${avgLabel2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].avg.toFixed(2)
            )} | ${avgLabel2.pCount} of ${
              avgLabel2.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].avg.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
          })
        }
        finalResult = []
        finalResult = indexData.filter((d: number) => d === 4)

        if (finalResult.length === 0) {
          minData.push({
            y: null,
            meta: ' ',
            goals: '',
            x: 'Discharge Date',
          })
          medianData.push({
            y: null,
            meta: ' ',
            goals: '',
            x: 'Discharge Date',
          })
          maxData.push({
            y: null,
            meta: ' ',
            goals: '',
            x: 'Discharge Date',
          })
          p10Data.push({
            y: null,
            meta: ' ',
            goals: '',
            x: 'Discharge Date',
          })
          p25Data.push({
            y: null,
            meta: ' ',
            goals: '',
            x: 'Discharge Date',
          })
          p75Data.push({
            y: null,
            meta: ' ',
            goals: '',
            x: 'Discharge Date',
          })
          p90Data.push({
            y: null,
            meta: ' ',
            goals: '',
            x: 'Discharge Date',
          })
          avgData.push({
            y: null,
            meta: ' ',
            goals: '',
            x: 'Discharge Date',
          })
        } else {
          if (filteredDataFinal[j].data.length === 2) {
            const minLabel1 = calculatePeopleAtPercentile(
              'min',
              filteredDataFinal[j].data[1].count
            )
            const p10Label1 = calculatePeopleAtPercentile(
              'p10',
              filteredDataFinal[j].data[1].count
            )
            const p25Label1 = calculatePeopleAtPercentile(
              'p25',
              filteredDataFinal[j].data[1].count
            )
            const medianLabel1 = calculatePeopleAtPercentile(
              'median',
              filteredDataFinal[j].data[1].count
            )
            const p75Label1 = calculatePeopleAtPercentile(
              'p75',
              filteredDataFinal[j].data[1].count
            )
            const p90Label1 = calculatePeopleAtPercentile(
              'p90',
              filteredDataFinal[j].data[1].count
            )
            const maxLabel1 = calculatePeopleAtPercentile(
              'max',
              filteredDataFinal[j].data[1].count
            )
            const avgLabel1 = calculatePeopleAtPercentile(
              'avg',
              filteredDataFinal[j].data[1].count
            )
            minData.push({
              y: Number(filteredDataFinal[j].data[1].min.toFixed(2)),
              meta: `${minLabel1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].min.toFixed(2)
              )} | ${minLabel1.pCount} of ${
                minLabel1.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[1].min.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[1].count,
              x: 'Discharge Date',
            })
            medianData.push({
              y: Number(filteredDataFinal[j].data[1].median.toFixed(2)),
              meta: `${medianLabel1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].median.toFixed(2)
              )} | ${medianLabel1.pCount} of ${
                medianLabel1.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[1].median.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[1].count,
              x: 'Discharge Date',
            })
            maxData.push({
              y: Number(filteredDataFinal[j].data[1].max.toFixed(2)),
              meta: `${maxLabel1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].max.toFixed(2)
              )} | ${maxLabel1.pCount} of ${
                maxLabel1.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[1].max.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[1].count,
              x: 'Discharge Date',
            })
            p10Data.push({
              y: Number(filteredDataFinal[j].data[1].p10.toFixed(2)),
              meta: `${p10Label1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].p10.toFixed(2)
              )} | ${p10Label1.pCount} of ${
                p10Label1.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[1].p10.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[1].count,
              x: 'Discharge Date',
            })
            p25Data.push({
              y: Number(filteredDataFinal[j].data[1].p25.toFixed(2)),
              meta: `${p25Label1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].p25.toFixed(2)
              )} | ${p25Label1.pCount} of ${
                p25Label1.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[1].p25.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[1].count,
              x: 'Discharge Date',
            })
            p75Data.push({
              y: Number(filteredDataFinal[j].data[1].p75.toFixed(2)),
              meta: `${p75Label1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].p75.toFixed(2)
              )} | ${p75Label1.pCount} of ${
                p75Label1.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[1].p75.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[1].count,
              x: 'Discharge Date',
            })
            p90Data.push({
              y: Number(filteredDataFinal[j].data[1].p90.toFixed(2)),
              meta: `${p90Label1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].p90.toFixed(2)
              )} | ${p90Label1.pCount} of ${
                p90Label1.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[1].p90.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[1].count,
              x: 'Discharge Date',
            })
            avgData.push({
              y: Number(filteredDataFinal[j].data[1].avg.toFixed(2)),
              meta: `${avgLabel1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].avg.toFixed(2)
              )} | ${avgLabel1.pCount} of ${
                avgLabel1.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[1].avg.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[1].count,
              x: 'Discharge Date',
            })
            // avgData.push(Number(filteredDataFinal[j].data[1].avg.toFixed(2)))
          } else if (filteredData[j].data.length === 3) {
            const minLabel = calculatePeopleAtPercentile(
              'min',
              filteredDataFinal[j].data[2].count
            )
            const p10Label = calculatePeopleAtPercentile(
              'p10',
              filteredDataFinal[j].data[2].count
            )
            const p25Label = calculatePeopleAtPercentile(
              'p25',
              filteredDataFinal[j].data[2].count
            )
            const medianLabel = calculatePeopleAtPercentile(
              'median',
              filteredDataFinal[j].data[2].count
            )
            const p75Label = calculatePeopleAtPercentile(
              'p75',
              filteredDataFinal[j].data[2].count
            )
            const p90Label = calculatePeopleAtPercentile(
              'p90',
              filteredDataFinal[j].data[2].count
            )
            const maxLabel = calculatePeopleAtPercentile(
              'max',
              filteredDataFinal[j].data[2].count
            )
            const avgLabel = calculatePeopleAtPercentile(
              'avg',
              filteredDataFinal[j].data[2].count
            )
            minData.push({
              y: Number(filteredDataFinal[j].data[2].min.toFixed(2)),
              meta: `${minLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[2].min.toFixed(2)
              )} | ${minLabel.pCount} of ${
                minLabel.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[2].min.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[2].count,
              x: 'Discharge Date',
            })
            medianData.push({
              y: Number(filteredDataFinal[j].data[2].median.toFixed(2)),
              meta: `${medianLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[2].median.toFixed(2)
              )} | ${medianLabel.pCount} of ${
                medianLabel.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[2].median.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[2].count,
              x: 'Discharge Date',
            })
            maxData.push({
              y: Number(filteredDataFinal[j].data[2].max.toFixed(2)),
              meta: `${maxLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[2].max.toFixed(2)
              )} | ${maxLabel.pCount} of ${
                maxLabel.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[2].max.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[2].count,
              x: 'Discharge Date',
            })
            p10Data.push({
              y: Number(filteredDataFinal[j].data[2].p10.toFixed(2)),
              meta: `${p10Label.pLabel}: ${Number(
                filteredDataFinal[j].data[2].p10.toFixed(2)
              )} | ${p10Label.pCount} of ${
                p10Label.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[2].p10.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[2].count,
              x: 'Discharge Date',
            })
            p25Data.push({
              y: Number(filteredDataFinal[j].data[2].p25.toFixed(2)),
              meta: `${p25Label.pLabel}: ${Number(
                filteredDataFinal[j].data[2].p25.toFixed(2)
              )} | ${p25Label.pCount} of ${
                p25Label.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[2].p25.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[2].count,
              x: 'Discharge Date',
            })
            p75Data.push({
              y: Number(filteredDataFinal[j].data[2].p75.toFixed(2)),
              meta: `${p75Label.pLabel}: ${Number(
                filteredDataFinal[j].data[2].p75.toFixed(2)
              )} | ${p75Label.pCount} of ${
                p75Label.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[2].p75.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[2].count,
              x: 'Discharge Date',
            })
            p90Data.push({
              y: Number(filteredDataFinal[j].data[2].p90.toFixed(2)),
              meta: `${p90Label.pLabel}: ${Number(
                filteredDataFinal[j].data[2].p90.toFixed(2)
              )} | ${p90Label.pCount} of ${
                p90Label.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[2].p90.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[2].count,
              x: 'Discharge Date',
            })
            avgData.push({
              y: Number(filteredDataFinal[j].data[2].avg.toFixed(2)),
              meta: `${avgLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[2].avg.toFixed(2)
              )} | ${avgLabel.pCount} of ${
                avgLabel.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[2].avg.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[2].count,
              x: 'Discharge Date',
            })
          } else {
            const minLabel = calculatePeopleAtPercentile(
              'min',
              filteredDataFinal[j].data[3].count
            )
            const p10Label = calculatePeopleAtPercentile(
              'p10',
              filteredDataFinal[j].data[3].count
            )
            const p25Label = calculatePeopleAtPercentile(
              'p25',
              filteredDataFinal[j].data[3].count
            )
            const medianLabel = calculatePeopleAtPercentile(
              'median',
              filteredDataFinal[j].data[3].count
            )
            const p75Label = calculatePeopleAtPercentile(
              'p75',
              filteredDataFinal[j].data[3].count
            )
            const p90Label = calculatePeopleAtPercentile(
              'p90',
              filteredDataFinal[j].data[3].count
            )
            const maxLabel = calculatePeopleAtPercentile(
              'max',
              filteredDataFinal[j].data[3].count
            )
            const avgLabel = calculatePeopleAtPercentile(
              'avg',
              filteredDataFinal[j].data[3].count
            )
            minData.push({
              y: Number(filteredDataFinal[j].data[3].min.toFixed(2)),
              meta: `${minLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[3].min.toFixed(2)
              )} | ${minLabel.pCount} of ${
                minLabel.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[3].min.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[3].count,
              x: 'Discharge Date',
            })
            medianData.push({
              y: Number(filteredDataFinal[j].data[3].median.toFixed(2)),
              meta: `${medianLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[3].median.toFixed(2)
              )} | ${medianLabel.pCount} of ${
                medianLabel.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[3].median.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[3].count,
              x: 'Discharge Date',
            })
            maxData.push({
              y: Number(filteredDataFinal[j].data[3].max.toFixed(2)),
              meta: `${maxLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[3].max.toFixed(2)
              )} | ${maxLabel.pCount} of ${
                maxLabel.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[3].max.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[3].count,
              x: 'Discharge Date',
            })
            p10Data.push({
              y: Number(filteredDataFinal[j].data[3].p10.toFixed(2)),
              meta: `${p10Label.pLabel}: ${Number(
                filteredDataFinal[j].data[3].p10.toFixed(2)
              )} | ${p10Label.pCount} of ${
                p10Label.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[3].p10.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[3].count,
              x: 'Discharge Date',
            })
            p25Data.push({
              y: Number(filteredDataFinal[j].data[3].p25.toFixed(2)),
              meta: `${p25Label.pLabel}: ${Number(
                filteredDataFinal[j].data[3].p25.toFixed(2)
              )} | ${p25Label.pCount} of ${
                p25Label.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[3].p25.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[3].count,
              x: 'Discharge Date',
            })
            p75Data.push({
              y: Number(filteredDataFinal[j].data[3].p75.toFixed(2)),
              meta: `${p75Label.pLabel}: ${Number(
                filteredDataFinal[j].data[3].p75.toFixed(2)
              )} | ${p75Label.pCount} of ${
                p75Label.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[3].p75.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[3].count,
              x: 'Discharge Date',
            })
            p90Data.push({
              y: Number(filteredDataFinal[j].data[3].p90.toFixed(2)),
              meta: `${p90Label.pLabel}: ${Number(
                filteredDataFinal[j].data[3].p90.toFixed(2)
              )} | ${p90Label.pCount} of ${
                p90Label.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[3].p90.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[3].count,
              x: 'Discharge Date',
            })
            avgData.push({
              y: Number(filteredDataFinal[j].data[3].avg.toFixed(2)),
              meta: `${avgLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[3].avg.toFixed(2)
              )} | ${avgLabel.pCount} of ${
                avgLabel.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[3].avg.toFixed(2)
              )}`,
              goals: filteredDataFinal[j].data[3].count,
              x: 'Discharge Date',
            })
          }
        }
        if (percentile === 'min') {
          const minArray: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: minData,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(strataFilterDataUpdated[m].dummyId),
            type: 'line',
          }

          postProcessedData.push(minArray)
        }
        if (percentile === 'p10') {
          const p10Obj: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: p10Data,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(strataFilterDataUpdated[m].dummyId),
            type: 'line',
          }
          postProcessedData.push(p10Obj)
        }
        if (percentile === 'p25') {
          const p25Obj: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: p25Data,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(strataFilterDataUpdated[m].dummyId),
            type: 'line',
          }
          postProcessedData.push(p25Obj)
        }
        if (percentile === 'median') {
          const medianObj: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: medianData,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(strataFilterDataUpdated[m].dummyId),
            type: 'line',
          }
          postProcessedData.push(medianObj)
        }
        if (percentile === 'p75') {
          const p75Obj: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: p75Data,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(strataFilterDataUpdated[m].dummyId),
            type: 'line',
          }
          postProcessedData.push(p75Obj)
        }
        if (percentile === 'p90') {
          const p90Obj: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: p90Data,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(strataFilterDataUpdated[m].dummyId),
            type: 'line',
          }
          postProcessedData.push(p90Obj)
        }
        if (percentile === 'max') {
          const maxObj: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: maxData,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(strataFilterDataUpdated[m].dummyId),
            type: 'line',
          }
          postProcessedData.push(maxObj)
        }
        if (percentile === 'avg') {
          const avjObj: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: avgData,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMax(strataFilterDataUpdated[m].dummyId),
            type: 'line',
          }
          postProcessedData.push(avjObj)
        }
      }
    }

    outComeData.push({
      analysisName: analyticData[0].name,
      summaryWithCount: postProcessedData,
    })
  }

  return outComeData
}

export function getNameForSeries(name: string): string {
  switch (name) {
    case 'min':
      return 'Minimum'
    case 'p10':
      return '10th percentile'
    case 'p25':
      return '25th percentile'
    case 'median':
      return 'Median'
    case 'p75':
      return '75th percentile'
    case 'p90':
      return '90th percentile'
    case 'max':
      return 'Maximum'
    case 'avg':
      return 'Average'

    default:
      return ''
  }
}

export function getPercentileLabel(name: number): string {
  switch (name) {
    case 0:
      return 'Minimum'
    case 10:
      return '10th percentile'
    case 25:
      return '25th percentile'
    case 50:
      return 'Median'
    case 75:
      return '75th percentile'
    case 90:
      return '90th percentile'
    case 100:
      return 'Maximum'
    case 125:
      return 'Average'

    default:
      return ''
  }
}

export function getPercentileNumber(name: string): number {
  switch (name) {
    case 'min':
      return 0
    case 'p10':
      return 10
    case 'p25':
      return 25
    case 'median':
      return 50
    case 'p75':
      return 75
    case 'p90':
      return 90
    case 'max':
      return 100
    case 'avg':
      return 125
    default:
      return 0
  }
}

export function calculatePeopleAtPercentile(
  label: string,
  totalCount: number
): Result {
  const pNumber = getPercentileNumber(label)
  const pLabel = getPercentileLabel(pNumber)

  let countAtPercentile: number = 0

  if (totalCount < 1) {
    countAtPercentile = 0
  } else if (totalCount === 1) {
    countAtPercentile = 1
  } else if (totalCount === 2) {
    countAtPercentile = pNumber <= 50 ? 1 : 2
  } else {
    countAtPercentile = Math.floor((pNumber / 100) * totalCount)
    if (countAtPercentile === 0) {
      countAtPercentile = 1
    }
  }

  const result = {
    pLabel,
    pNumber,
    pCount: countAtPercentile,
    totalCount,
  }
  return result
}

export function getXAxisLabel(count: number): string {
  switch (count) {
    case 0:
      return 'Admission Date'
    case 1:
      return 'Admission Date + 1'
    case 2:
      return 'Day 3'
    case 3:
      return 'Discharge Date - 1'
    case 4:
      return 'Discharge Date'
    case 5:
      return 'Day 6'

    default:
      return 'Day 0'
  }
}

export function getCohortDataWithGrouping(
  response: any,
  analysisFilter: Filters[],
  strataFilter: Filters[],

  percentile: string
): CohortDisplayWithCount[] {
  let postProcessedData: SummaryDataWithCount[] = []
  let postProcessedDataCount: SummaryDataWithCount[] = []
  let minData: any[] = []
  let p10Data: any[] = []
  let p25Data: any[] = []
  let indexData: number[] = []
  let medianData: any[] = []
  let p75Data: any[] = []
  let p90Data: any[] = []
  let avgData: any[] = []
  let maxData: any[] = []

  let minDataCount: any[] = []
  let p10DataCount: any[] = []
  let p25DataCount: any[] = []

  let medianDataCount: any[] = []
  let p75DataCount: any[] = []
  let p90DataCount: any[] = []
  let avgDataCount: any[] = []
  let maxDataCount: any[] = []

  let finalResult: number[] = []

  const outComeData: CohortDisplayWithCount[] = []

  for (let i = 0; i < analysisFilter.length; i++) {
    postProcessedData = []
    postProcessedDataCount = []

    const filteredDataFinal = response.filter(
      (d: any) => d.analysisId === parseInt(analysisFilter[i].id, 10)
    )

    for (let j = 0; j < filteredDataFinal.length; j++) {
      minData = []
      medianData = []
      maxData = []
      indexData = []
      finalResult = []
      p10Data = []
      p25Data = []
      p75Data = []
      p90Data = []
      avgData = []
      minDataCount = []
      p10DataCount = []
      p25DataCount = []
      medianDataCount = []
      p75DataCount = []
      p90DataCount = []
      avgDataCount = []
      maxDataCount = []

      for (let k = 0; k < filteredDataFinal[j].data.length; k++) {
        indexData.push(filteredDataFinal[j].data[k].time_norm_idx)
      }

      finalResult = indexData.filter((d: number) => d === 0)

      if (finalResult.length === 0) {
        minData.push({
          x: 'Admission Date',
          y: null,
          meta: '',
          goals: '',
        })
        minDataCount.push({
          x: 'Admission Date',
          y: null,
          meta: '',
          goals: '',
        })
        medianData.push({
          x: 'Admission Date',
          y: null,
          meta: '',
          goals: '',
        })
        medianDataCount.push({
          x: 'Admission Date',
          y: null,
          meta: '',
          goals: '',
        })
        maxData.push({
          x: 'Admission Date',
          y: null,
          meta: ' ',
          goals: '',
        })
        maxDataCount.push({
          x: 'Admission Date',
          y: null,
          meta: ' ',
          goals: '',
        })
        p10Data.push({
          x: 'Admission Date',
          y: null,
          meta: ' ',
          goals: '',
        })
        p10DataCount.push({
          x: 'Admission Date',
          y: null,
          meta: ' ',
          goals: '',
        })
        p25Data.push({
          x: 'Admission Date',
          y: null,
          meta: ' ',
          goals: '',
        })
        p25DataCount.push({
          x: 'Admission Date',
          y: null,
          meta: ' ',
          goals: '',
        })
        p75Data.push({
          x: 'Admission Date',
          y: null,
          meta: ' ',
          goals: '',
        })
        p75DataCount.push({
          x: 'Admission Date',
          y: null,
          meta: ' ',
          goals: '',
        })
        p90Data.push({
          x: 'Admission Date',
          y: null,
          meta: ' ',
          goals: '',
        })
        p90DataCount.push({
          x: 'Admission Date',
          y: null,
          meta: ' ',
          goals: '',
        })
        avgData.push({
          x: 'Admission Date',
          y: null,
          meta: ' ',
          goals: '',
        })
        avgDataCount.push({
          x: 'Admission Date',
          y: null,
          meta: ' ',
          goals: '',
        })
      } else {
        const minLabel = calculatePeopleAtPercentile(
          'min',
          filteredDataFinal[j].data[0].count
        )
        const p10Label = calculatePeopleAtPercentile(
          'p10',
          filteredDataFinal[j].data[0].count
        )
        const p25Label = calculatePeopleAtPercentile(
          'p25',
          filteredDataFinal[j].data[0].count
        )
        const medianLabel = calculatePeopleAtPercentile(
          'median',
          filteredDataFinal[j].data[0].count
        )
        const p75Label = calculatePeopleAtPercentile(
          'p75',
          filteredDataFinal[j].data[0].count
        )
        const p90Label = calculatePeopleAtPercentile(
          'p90',
          filteredDataFinal[j].data[0].count
        )
        const maxLabel = calculatePeopleAtPercentile(
          'max',
          filteredDataFinal[j].data[0].count
        )
        const avgLabel = calculatePeopleAtPercentile(
          'avg',
          filteredDataFinal[j].data[0].count
        )
        minData.push({
          x: 'Admission Date',
          y: Number(filteredDataFinal[j].data[0].min.toFixed(2)),
          meta: `${minLabel.pLabel}: ${filteredDataFinal[j].data[0].min} | ${
            minLabel.pCount
          } of ${minLabel.totalCount} with value <= ${Number(
            filteredDataFinal[j].data[0].min.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[0].count,
        })
        minDataCount.push({
          x: 'Admission Date',
          y: filteredDataFinal[j].data[0].count,
          meta: `${minLabel.pLabel}: ${filteredDataFinal[j].data[0].min}`,
          goals: filteredDataFinal[j].data[0].count,
        })

        medianDataCount.push({
          x: 'Admission Date',
          y: filteredDataFinal[j].data[0].count,
          meta: `${medianLabel.pLabel}: ${Number(
            filteredDataFinal[j].data[0].median.toFixed(2)
          )} | ${medianLabel.pCount} of ${
            medianLabel.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[0].median.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[0].count,
        })
        medianData.push({
          x: 'Admission Date',
          y: Number(filteredDataFinal[j].data[0].median.toFixed(2)),
          meta: `${medianLabel.pLabel}: ${Number(
            filteredDataFinal[j].data[0].median.toFixed(2)
          )} | ${medianLabel.pCount} of ${
            medianLabel.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[0].median.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[0].count,
        })
        medianDataCount.push({
          x: 'Admission Date',
          y: filteredDataFinal[j].data[0].count,
          meta: `${medianLabel.pLabel}: ${Number(
            filteredDataFinal[j].data[0].median.toFixed(2)
          )} | ${medianLabel.pCount} of ${
            medianLabel.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[0].median.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[0].count,
        })
        maxData.push({
          x: 'Admission Date',
          y: Number(filteredDataFinal[j].data[0].max.toFixed(2)),
          meta: `${maxLabel.pLabel}: ${Number(
            filteredDataFinal[j].data[0].max.toFixed(2)
          )}}`,
          goals: filteredDataFinal[j].data[0].count,
        })
        maxDataCount.push({
          x: 'Admission Date',
          y: filteredDataFinal[j].data[0].count,
          meta: `${maxLabel.pLabel}: ${Number(
            filteredDataFinal[j].data[0].max.toFixed(2)
          )} `,
          goals: filteredDataFinal[j].data[0].count,
        })
        p10Data.push({
          x: 'Admission Date',
          y: Number(filteredDataFinal[j].data[0].p10.toFixed(2)),
          meta: `${p10Label.pLabel}: ${Number(
            filteredDataFinal[j].data[0].p10.toFixed(2)
          )} | ${p10Label.pCount} of ${
            p10Label.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[0].p10.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[0].count,
        })
        p10DataCount.push({
          x: 'Admission Date',
          y: filteredDataFinal[j].data[0].count,
          meta: `${p10Label.pLabel}: ${Number(
            filteredDataFinal[j].data[0].p10.toFixed(2)
          )} | ${p10Label.pCount} of ${
            p10Label.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[0].p10.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[0].count,
        })
        p25Data.push({
          x: 'Admission Date',
          y: Number(filteredDataFinal[j].data[0].p25.toFixed(2)),
          meta: `${p25Label.pLabel}: ${Number(
            filteredDataFinal[j].data[0].p25.toFixed(2)
          )} | ${p25Label.pCount} of ${
            p25Label.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[0].p25.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[0].count,
        })
        p25DataCount.push({
          x: 'Admission Date',
          y: filteredDataFinal[j].data[0].count,
          meta: `${p25Label.pLabel}: ${Number(
            filteredDataFinal[j].data[0].p25.toFixed(2)
          )} | ${p25Label.pCount} of ${
            p25Label.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[0].p25.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[0].count,
        })
        p75Data.push({
          x: 'Admission Date',
          y: Number(filteredDataFinal[j].data[0].p75.toFixed(2)),
          meta: `${p75Label.pLabel}: ${Number(
            filteredDataFinal[j].data[0].p75.toFixed(2)
          )} | ${p75Label.pCount} of ${
            p75Label.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[0].p75.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[0].count,
        })

        p75DataCount.push({
          x: 'Admission Date',
          y: filteredDataFinal[j].data[0].count,
          meta: `${p75Label.pLabel}: ${Number(
            filteredDataFinal[j].data[0].p75.toFixed(2)
          )} | ${p75Label.pCount} of ${
            p75Label.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[0].p75.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[0].count,
        })
        p90Data.push({
          x: 'Admission Date',
          y: Number(filteredDataFinal[j].data[0].p90.toFixed(2)),
          meta: `${p90Label.pLabel}: ${Number(
            filteredDataFinal[j].data[0].p90.toFixed(2)
          )} | ${p90Label.pCount} of ${
            p90Label.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[0].p90.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[0].count,
        })
        p90DataCount.push({
          x: 'Admission Date',
          y: filteredDataFinal[j].data[0].count,
          meta: `${p90Label.pLabel}: ${Number(
            filteredDataFinal[j].data[0].p90.toFixed(2)
          )} | ${p90Label.pCount} of ${
            p90Label.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[0].p90.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[0].count,
        })
        avgData.push({
          x: 'Admission Date',
          y: Number(filteredDataFinal[j].data[0].avg.toFixed(2)),
          meta: `${avgLabel.pLabel}: ${Number(
            filteredDataFinal[j].data[0].avg.toFixed(2)
          )} | ${avgLabel.pCount} of ${
            avgLabel.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[0].avg.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[0].count,
        })
        avgDataCount.push({
          x: 'Admission Date',
          y: filteredDataFinal[j].data[0].count,
          meta: `${avgLabel.pLabel}: ${Number(
            filteredDataFinal[j].data[0].avg.toFixed(2)
          )} | ${avgLabel.pCount} of ${
            avgLabel.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[0].avg.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[0].count,
        })
        //   p90Data.push(filteredDataFinal[j].data[0].p90)
        //   //   minData.push(filteredDataFinal[j].data[0].min)
        //   medianData.push(filteredDataFinal[j].data[0].median)
        //   maxData.push(filteredDataFinal[j].data[0].max)
        //   p10Data.push(filteredDataFinal[j].data[0].p10)
        //   p25Data.push(filteredDataFinal[j].data[0].p25)
        //   p75Data.push(filteredDataFinal[j].data[0].p75)
        //   p90Data.push(filteredDataFinal[j].data[0].p90)

        //   avgData.push(filteredDataFinal[j].data[0].avg)
      }
      finalResult = []
      finalResult = indexData.filter((d: number) => d === 1)

      if (finalResult.length === 0) {
        minData.push({
          x: 'Admission Date + 1',
          y: null,
          meta: ' ',
          goals: '',
        })
        medianData.push({
          x: 'Admission Date + 1',
          y: null,
          meta: ' ',
          goals: '',
        })
        maxData.push({
          x: 'Admission Date + 1',
          y: null,
          meta: ' ',
          goals: '',
        })
        p10Data.push({
          y: null,
          x: 'Admission Date + 1',
          meta: ' ',
          goals: '',
        })
        p25Data.push({
          y: null,
          x: 'Admission Date + 1',
          meta: ' ',
          goals: '',
        })
        p75Data.push({
          y: null,
          x: 'Admission Date + 1',
          meta: ' ',
          goals: '',
        })
        p90Data.push({
          y: null,
          x: 'Admission Date + 1',
          meta: ' ',
          goals: '',
        })
        avgData.push({
          y: null,
          x: 'Admission Date + 1',
          meta: ' ',
          goals: '',
        })

        minDataCount.push({
          x: 'Admission Date + 1',
          y: null,
          meta: '',
          goals: '',
        })
        medianDataCount.push({
          x: 'Admission Date + 1',
          y: null,
          meta: '',
          goals: '',
        })
        maxDataCount.push({
          x: 'Admission Date + 1',
          y: null,
          meta: ' ',
          goals: '',
        })

        p10DataCount.push({
          x: 'Admission Date + 1',
          y: null,
          meta: ' ',
          goals: '',
        })

        p25DataCount.push({
          x: 'Admission Date + 1',
          y: null,
          meta: ' ',
          goals: '',
        })

        p75DataCount.push({
          x: 'Admission Date + 1',
          y: null,
          meta: ' ',
          goals: '',
        })

        p90DataCount.push({
          x: 'Admission Date + 1',
          y: null,
          meta: ' ',
          goals: '',
        })

        avgDataCount.push({
          x: 'Admission Date + 1',
          y: null,
          meta: ' ',
          goals: '',
        })
      } else {
        const minLabel1 = calculatePeopleAtPercentile(
          'min',
          filteredDataFinal[j].data[1].count
        )
        const p10Label1 = calculatePeopleAtPercentile(
          'p10',
          filteredDataFinal[j].data[1].count
        )
        const p25Label1 = calculatePeopleAtPercentile(
          'p25',
          filteredDataFinal[j].data[1].count
        )
        const medianLabel1 = calculatePeopleAtPercentile(
          'median',
          filteredDataFinal[j].data[1].count
        )
        const p75Label1 = calculatePeopleAtPercentile(
          'p75',
          filteredDataFinal[j].data[1].count
        )
        const p90Label1 = calculatePeopleAtPercentile(
          'p90',
          filteredDataFinal[j].data[1].count
        )
        const maxLabel1 = calculatePeopleAtPercentile(
          'max',
          filteredDataFinal[j].data[1].count
        )
        const avgLabel1 = calculatePeopleAtPercentile(
          'avg',
          filteredDataFinal[j].data[1].count
        )
        minData.push({
          x: 'Admission Date + 1',
          y: Number(filteredDataFinal[j].data[1].min.toFixed(2)),
          meta: `${minLabel1.pLabel}: ${Number(
            filteredDataFinal[j].data[1].min.toFixed(2)
          )} | ${minLabel1.pCount} of ${
            minLabel1.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[1].min.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[1].count,
        })

        minDataCount.push({
          x: 'Admission Date + 1',
          y: filteredDataFinal[j].data[1].count,
          meta: `${minLabel1.pLabel}: ${Number(
            filteredDataFinal[j].data[1].min.toFixed(2)
          )} }`,
          goals: filteredDataFinal[j].data[1].count,
        })
        medianData.push({
          x: 'Admission Date + 1',
          y: Number(filteredDataFinal[j].data[1].median.toFixed(2)),
          meta: `${medianLabel1.pLabel}: ${Number(
            filteredDataFinal[j].data[1].median.toFixed(2)
          )} | ${medianLabel1.pCount} of ${
            medianLabel1.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[1].median.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[1].count,
        })
        medianDataCount.push({
          x: 'Admission Date + 1',
          y: filteredDataFinal[j].data[1].count,
          meta: `${medianLabel1.pLabel}: ${Number(
            filteredDataFinal[j].data[1].median.toFixed(2)
          )} | ${medianLabel1.pCount} of ${
            medianLabel1.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[1].median.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[1].count,
        })
        maxData.push({
          y: Number(filteredDataFinal[j].data[1].max.toFixed(2)),
          meta: `${maxLabel1.pLabel}: ${Number(
            filteredDataFinal[j].data[1].max.toFixed(2)
          )} `,

          x: 'Admission Date + 1',
          goals: filteredDataFinal[j].data[1].count,
        })
        maxDataCount.push({
          y: filteredDataFinal[j].data[1].count,
          meta: `${maxLabel1.pLabel}: ${Number(
            filteredDataFinal[j].data[1].max.toFixed(2)
          )}`,

          x: 'Admission Date + 1',
          goals: filteredDataFinal[j].data[1].count,
        })
        p10Data.push({
          x: 'Admission Date + 1',
          y: Number(filteredDataFinal[j].data[1].p10.toFixed(2)),
          meta: `${p10Label1.pLabel}: ${Number(
            filteredDataFinal[j].data[1].p10.toFixed(2)
          )} | ${p10Label1.pCount} of ${
            p10Label1.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[1].p10.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[1].count,
        })
        p10DataCount.push({
          x: 'Admission Date + 1',
          y: filteredDataFinal[j].data[1].count,
          meta: `${p10Label1.pLabel}: ${Number(
            filteredDataFinal[j].data[1].p10.toFixed(2)
          )} | ${p10Label1.pCount} of ${
            p10Label1.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[1].p10.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[1].count,
        })
        p25Data.push({
          x: 'Admission Date + 1',
          y: Number(filteredDataFinal[j].data[1].p25.toFixed(2)),
          meta: `${p25Label1.pLabel}: ${Number(
            filteredDataFinal[j].data[1].p25.toFixed(2)
          )} | ${p25Label1.pCount} of ${
            p25Label1.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[1].p25.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[1].count,
        })
        p25DataCount.push({
          x: 'Admission Date + 1',
          y: filteredDataFinal[j].data[1].count,
          meta: `${p25Label1.pLabel}: ${Number(
            filteredDataFinal[j].data[1].p25.toFixed(2)
          )} | ${p25Label1.pCount} of ${
            p25Label1.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[1].p25.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[1].count,
        })
        p75Data.push({
          x: 'Admission Date + 1',
          y: Number(filteredDataFinal[j].data[1].p75.toFixed(2)),
          meta: `${p75Label1.pLabel}: ${Number(
            filteredDataFinal[j].data[1].p75.toFixed(2)
          )} | ${p75Label1.pCount} of ${
            p75Label1.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[1].p75.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[1].count,
        })

        p75DataCount.push({
          x: 'Admission Date + 1',
          y: filteredDataFinal[j].data[1].count,
          meta: `${p75Label1.pLabel}: ${Number(
            filteredDataFinal[j].data[1].p75.toFixed(2)
          )} | ${p75Label1.pCount} of ${
            p75Label1.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[1].p75.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[1].count,
        })
        p90Data.push({
          x: 'Admission Date + 1',
          y: Number(filteredDataFinal[j].data[1].p90.toFixed(2)),
          meta: `${p90Label1.pLabel}: ${Number(
            filteredDataFinal[j].data[1].p90.toFixed(2)
          )} | ${p90Label1.pCount} of ${
            p90Label1.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[1].p90.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[1].count,
        })
        p90DataCount.push({
          x: 'Admission Date + 1',
          y: filteredDataFinal[j].data[1].count,
          meta: `${p90Label1.pLabel}: ${Number(
            filteredDataFinal[j].data[1].p90.toFixed(2)
          )} | ${p90Label1.pCount} of ${
            p90Label1.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[1].p90.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[1].count,
        })
        avgData.push({
          x: 'Admission Date + 1',
          y: Number(filteredDataFinal[j].data[1].avg.toFixed(2)),
          meta: `${avgLabel1.pLabel}: ${Number(
            filteredDataFinal[j].data[1].avg.toFixed(2)
          )} | ${avgLabel1.pCount} of ${
            avgLabel1.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[1].avg.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[1].count,
        })
        avgDataCount.push({
          x: 'Admission Date + 1',
          y: filteredDataFinal[j].data[1].count,
          meta: `${avgLabel1.pLabel}: ${Number(
            filteredDataFinal[j].data[1].avg.toFixed(2)
          )} | ${avgLabel1.pCount} of ${
            avgLabel1.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[1].avg.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[1].count,
        })
      }
      finalResult = []
      finalResult = indexData.filter((d: number) => d === 3)

      if (finalResult.length === 0) {
        minData.push({
          x: 'Discharge Date - 1',
          y: null,
          meta: ' ',
          goals: '',
        })
        medianData.push({
          y: null,
          meta: ' ',
          goals: '',
          x: 'Discharge Date - 1',
        })
        maxData.push({
          y: null,
          meta: ' ',
          goals: '',
          x: 'Discharge Date - 1',
        })
        p10Data.push({
          y: null,
          meta: ' ',
          goals: '',
          x: 'Discharge Date - 1',
        })
        p25Data.push({
          y: null,
          meta: ' ',
          goals: '',
          x: 'Discharge Date - 1',
        })
        p75Data.push({
          y: null,
          meta: ' ',
          goals: '',
          x: 'Discharge Date - 1',
        })
        p90Data.push({
          y: null,
          meta: ' ',
          goals: '',
          x: 'Discharge Date - 1',
        })
        avgData.push({
          y: null,
          meta: ' ',
          goals: '',
          x: 'Discharge Date - 1',
        })
        minDataCount.push({
          x: 'Discharge Date - 1',
          y: null,
          meta: '',
          goals: '',
        })
        medianDataCount.push({
          x: 'Discharge Date - 1',
          y: null,
          meta: '',
          goals: '',
        })
        maxDataCount.push({
          x: 'Discharge Date - 1',
          y: null,
          meta: ' ',
          goals: '',
        })

        p10DataCount.push({
          x: 'Discharge Date - 1',
          y: null,
          meta: ' ',
          goals: '',
        })

        p25DataCount.push({
          x: 'Discharge Date - 1',
          y: null,
          meta: ' ',
          goals: '',
        })

        p75DataCount.push({
          x: 'Discharge Date - 1',
          y: null,
          meta: ' ',
          goals: '',
        })

        p90DataCount.push({
          x: 'Discharge Date - 1',
          y: null,
          meta: ' ',
          goals: '',
        })

        avgDataCount.push({
          x: 'Discharge Date - 1',
          y: null,
          meta: ' ',
          goals: '',
        })
      } else {
        const minLabel2 = calculatePeopleAtPercentile(
          'min',
          filteredDataFinal[j].data[2].count
        )
        const p10Label2 = calculatePeopleAtPercentile(
          'p10',
          filteredDataFinal[j].data[2].count
        )
        const p25Label2 = calculatePeopleAtPercentile(
          'p25',
          filteredDataFinal[j].data[2].count
        )
        const medianLabel2 = calculatePeopleAtPercentile(
          'median',
          filteredDataFinal[j].data[2].count
        )
        const p75Label2 = calculatePeopleAtPercentile(
          'p75',
          filteredDataFinal[j].data[2].count
        )
        const p90Label2 = calculatePeopleAtPercentile(
          'p90',
          filteredDataFinal[j].data[2].count
        )
        const maxLabel2 = calculatePeopleAtPercentile(
          'max',
          filteredDataFinal[j].data[2].count
        )
        const avgLabel2 = calculatePeopleAtPercentile(
          'avg',
          filteredDataFinal[j].data[2].count
        )
        minData.push({
          y: Number(filteredDataFinal[j].data[2].min.toFixed(2)),
          meta: `${minLabel2.pLabel}: ${Number(
            filteredDataFinal[j].data[2].min.toFixed(2)
          )} | ${minLabel2.pCount} of ${
            minLabel2.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[2].min.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[2].count,
          x: 'Discharge Date - 1',
        })

        minDataCount.push({
          y: filteredDataFinal[j].data[2].count,
          meta: `${minLabel2.pLabel}: ${Number(
            filteredDataFinal[j].data[2].min.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[2].count,
          x: 'Discharge Date - 1',
        })

        medianDataCount.push({
          y: filteredDataFinal[j].data[2].count,
          meta: `${medianLabel2.pLabel}: ${Number(
            filteredDataFinal[j].data[2].median.toFixed(2)
          )} | ${medianLabel2.pCount} of ${
            medianLabel2.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[2].median.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[2].count,
          x: 'Discharge Date - 1',
        })
        medianData.push({
          y: Number(filteredDataFinal[j].data[2].median.toFixed(2)),
          meta: `${medianLabel2.pLabel}: ${Number(
            filteredDataFinal[j].data[2].median.toFixed(2)
          )} | ${medianLabel2.pCount} of ${
            medianLabel2.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[2].median.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[2].count,
          x: 'Discharge Date - 1',
        })
        maxData.push({
          y: Number(filteredDataFinal[j].data[2].max.toFixed(2)),
          meta: `${maxLabel2.pLabel}: ${Number(
            filteredDataFinal[j].data[2].max.toFixed(2)
          )} `,
          goals: filteredDataFinal[j].data[2].count,
          x: 'Discharge Date - 1',
        })
        maxDataCount.push({
          y: filteredDataFinal[j].data[2].count,
          meta: `${maxLabel2.pLabel}: ${Number(
            filteredDataFinal[j].data[2].max.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[2].count,
          x: 'Discharge Date - 1',
        })
        p10Data.push({
          y: Number(filteredDataFinal[j].data[2].p10.toFixed(2)),
          meta: `${p10Label2.pLabel}: ${Number(
            filteredDataFinal[j].data[2].p10.toFixed(2)
          )} | ${p10Label2.pCount} of ${
            p10Label2.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[2].p10.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[2].count,
          x: 'Discharge Date - 1',
        })

        p10DataCount.push({
          y: filteredDataFinal[j].data[2].count,
          meta: `${p10Label2.pLabel}: ${Number(
            filteredDataFinal[j].data[2].p10.toFixed(2)
          )} | ${p10Label2.pCount} of ${
            p10Label2.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[2].p10.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[2].count,
          x: 'Discharge Date - 1',
        })
        p25Data.push({
          y: Number(filteredDataFinal[j].data[2].p25.toFixed(2)),
          meta: `${p25Label2.pLabel}: ${Number(
            filteredDataFinal[j].data[2].p25.toFixed(2)
          )} | ${p25Label2.pCount} of ${
            p25Label2.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[2].p25.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[2].count,
          x: 'Discharge Date - 1',
        })
        p25DataCount.push({
          y: filteredDataFinal[j].data[2].count,
          meta: `${p25Label2.pLabel}: ${Number(
            filteredDataFinal[j].data[2].p25.toFixed(2)
          )} | ${p25Label2.pCount} of ${
            p25Label2.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[2].p25.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[2].count,
          x: 'Discharge Date - 1',
        })

        p75Data.push({
          x: 'Discharge Date - 1',
          y: Number(filteredDataFinal[j].data[2].p75.toFixed(2)),
          meta: `${p75Label2.pLabel}: ${Number(
            filteredDataFinal[j].data[2].p75.toFixed(2)
          )} | ${p75Label2.pCount} of ${
            p75Label2.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[2].p75.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[2].count,
        })
        p75DataCount.push({
          x: 'Discharge Date - 1',
          y: filteredDataFinal[j].data[2].count,
          meta: `${p75Label2.pLabel}: ${Number(
            filteredDataFinal[j].data[2].p75.toFixed(2)
          )} | ${p75Label2.pCount} of ${
            p75Label2.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[2].p75.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[2].count,
        })
        p90Data.push({
          x: 'Discharge Date - 1',
          y: Number(filteredDataFinal[j].data[2].p90.toFixed(2)),
          meta: `${p90Label2.pLabel}: ${Number(
            filteredDataFinal[j].data[2].p90.toFixed(2)
          )} | ${p90Label2.pCount} of ${
            p90Label2.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[2].p90.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[2].count,
        })
        p90DataCount.push({
          x: 'Discharge Date - 1',
          y: filteredDataFinal[j].data[2].count,
          meta: `${p90Label2.pLabel}: ${Number(
            filteredDataFinal[j].data[2].p90.toFixed(2)
          )} | ${p90Label2.pCount} of ${
            p90Label2.totalCount
          } with value <= ${Number(
            filteredDataFinal[j].data[2].p90.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[2].count,
        })
        avgData.push({
          x: 'Discharge Date - 1',
          y: Number(filteredDataFinal[j].data[2].avg.toFixed(2)),
          meta: `${avgLabel2.pLabel}: ${filteredDataFinal[j].data[2].avg} | ${
            avgLabel2.pCount
          } of ${avgLabel2.totalCount} with value <= ${Number(
            filteredDataFinal[j].data[2].avg.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[2].count,
        })
        avgDataCount.push({
          x: 'Discharge Date - 1',
          y: filteredDataFinal[j].data[2].count,
          meta: `${avgLabel2.pLabel}: ${filteredDataFinal[j].data[2].avg} | ${
            avgLabel2.pCount
          } of ${avgLabel2.totalCount} with value <= ${Number(
            filteredDataFinal[j].data[2].avg.toFixed(2)
          )}`,
          goals: filteredDataFinal[j].data[2].count,
        })
      }
      finalResult = []
      finalResult = indexData.filter((d: number) => d === 4)

      if (finalResult.length === 0) {
        minData.push({
          y: null,
          meta: ' ',
          goals: '',
          x: 'Discharge Date',
        })
        medianData.push({
          y: null,
          meta: ' ',
          goals: '',
          x: 'Discharge Date',
        })
        maxData.push({
          y: null,
          meta: ' ',
          goals: '',
          x: 'Discharge Date',
        })
        p10Data.push({
          y: null,
          meta: ' ',
          goals: '',
          x: 'Discharge Date',
        })
        p25Data.push({
          y: null,
          meta: ' ',
          goals: '',
          x: 'Discharge Date',
        })
        p75Data.push({
          y: null,
          meta: ' ',
          goals: '',
          x: 'Discharge Date',
        })
        p90Data.push({
          y: null,
          meta: ' ',
          goals: '',
          x: 'Discharge Date',
        })
        avgData.push({
          y: null,
          meta: ' ',
          goals: '',
          x: 'Discharge Date',
        })

        minDataCount.push({
          x: 'Discharge Date',
          y: null,
          meta: '',
          goals: '',
        })
        medianDataCount.push({
          x: 'Discharge Date',
          y: null,
          meta: '',
          goals: '',
        })
        maxDataCount.push({
          x: 'Discharge Date',
          y: null,
          meta: ' ',
          goals: '',
        })

        p10DataCount.push({
          x: 'Discharge Date',
          y: null,
          meta: ' ',
          goals: '',
        })

        p25DataCount.push({
          x: 'Discharge Date',
          y: null,
          meta: ' ',
          goals: '',
        })

        p75DataCount.push({
          x: 'Discharge Date',
          y: null,
          meta: ' ',
          goals: '',
        })

        p90DataCount.push({
          x: 'Discharge Date',
          y: null,
          meta: ' ',
          goals: '',
        })

        avgDataCount.push({
          x: 'Discharge Date',
          y: null,
          meta: ' ',
          goals: '',
        })
      } else {
        if (filteredDataFinal[j].data.length === 2) {
          const minLabel1 = calculatePeopleAtPercentile(
            'min',
            filteredDataFinal[j].data[1].count
          )
          const p10Label1 = calculatePeopleAtPercentile(
            'p10',
            filteredDataFinal[j].data[1].count
          )
          const p25Label1 = calculatePeopleAtPercentile(
            'p25',
            filteredDataFinal[j].data[1].count
          )
          const medianLabel1 = calculatePeopleAtPercentile(
            'median',
            filteredDataFinal[j].data[1].count
          )
          const p75Label1 = calculatePeopleAtPercentile(
            'p75',
            filteredDataFinal[j].data[1].count
          )
          const p90Label1 = calculatePeopleAtPercentile(
            'p90',
            filteredDataFinal[j].data[1].count
          )
          const maxLabel1 = calculatePeopleAtPercentile(
            'max',
            filteredDataFinal[j].data[1].count
          )
          const avgLabel1 = calculatePeopleAtPercentile(
            'avg',
            filteredDataFinal[j].data[1].count
          )
          minData.push({
            y: Number(filteredDataFinal[j].data[1].min.toFixed(2)),
            meta: `${minLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].min.toFixed(2)
            )} | ${minLabel1.pCount} of ${
              minLabel1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].min.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
            x: 'Discharge Date',
          })

          minDataCount.push({
            y: filteredDataFinal[j].data[1].count,
            meta: `${minLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].min.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
            x: 'Discharge Date',
          })
          medianData.push({
            y: Number(filteredDataFinal[j].data[1].median.toFixed(2)),
            meta: `${medianLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].median.toFixed(2)
            )} | ${medianLabel1.pCount} of ${
              medianLabel1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].median.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
            x: 'Discharge Date',
          })
          medianDataCount.push({
            y: filteredDataFinal[j].data[1].count,
            meta: `${medianLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].median.toFixed(2)
            )} | ${medianLabel1.pCount} of ${
              medianLabel1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].median.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
            x: 'Discharge Date',
          })
          maxData.push({
            y: Number(filteredDataFinal[j].data[1].max.toFixed(2)),
            meta: `${maxLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].max.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
            x: 'Discharge Date',
          })

          maxDataCount.push({
            y: filteredDataFinal[j].data[1].count,
            meta: `${maxLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].max.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
            x: 'Discharge Date',
          })
          p10Data.push({
            y: Number(filteredDataFinal[j].data[1].p10.toFixed(2)),
            meta: `${p10Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p10.toFixed(2)
            )} | ${p10Label1.pCount} of ${
              p10Label1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].p10.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
            x: 'Discharge Date',
          })
          p10DataCount.push({
            y: filteredDataFinal[j].data[1].count,
            meta: `${p10Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p10.toFixed(2)
            )} | ${p10Label1.pCount} of ${
              p10Label1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].p10.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
            x: 'Discharge Date',
          })
          p25Data.push({
            y: Number(filteredDataFinal[j].data[1].p25.toFixed(2)),
            meta: `${p25Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p25.toFixed(2)
            )} | ${p25Label1.pCount} of ${
              p25Label1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].p25.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
            x: 'Discharge Date',
          })
          p25DataCount.push({
            y: filteredDataFinal[j].data[1].count,
            meta: `${p25Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p25.toFixed(2)
            )} | ${p25Label1.pCount} of ${
              p25Label1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].p25.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
            x: 'Discharge Date',
          })
          p75Data.push({
            y: Number(filteredDataFinal[j].data[1].p75.toFixed(2)),
            meta: `${p75Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p75.toFixed(2)
            )} | ${p75Label1.pCount} of ${
              p75Label1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].p75.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
            x: 'Discharge Date',
          })
          p75DataCount.push({
            y: filteredDataFinal[j].data[1].count,
            meta: `${p75Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p75.toFixed(2)
            )} | ${p75Label1.pCount} of ${
              p75Label1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].p75.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
            x: 'Discharge Date',
          })
          p90Data.push({
            y: Number(filteredDataFinal[j].data[1].p90.toFixed(2)),
            meta: `${p90Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p90.toFixed(2)
            )} | ${p90Label1.pCount} of ${
              p90Label1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].p90.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
            x: 'Discharge Date',
          })
          p90DataCount.push({
            y: filteredDataFinal[j].data[1].count,
            meta: `${p90Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p90.toFixed(2)
            )} | ${p90Label1.pCount} of ${
              p90Label1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].p90.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
            x: 'Discharge Date',
          })
          avgData.push({
            y: Number(filteredDataFinal[j].data[1].avg.toFixed(2)),
            meta: `${avgLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].avg.toFixed(2)
            )} | ${avgLabel1.pCount} of ${
              avgLabel1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].avg.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
            x: 'Discharge Date',
          })
          avgDataCount.push({
            y: filteredDataFinal[j].data[1].count,
            meta: `${avgLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].avg.toFixed(2)
            )} | ${avgLabel1.pCount} of ${
              avgLabel1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].avg.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[1].count,
            x: 'Discharge Date',
          })
          // avgData.push(Number(filteredDataFinal[j].data[1].avg.toFixed(2)))
        } else if (filteredDataFinal[j].data.length === 3) {
          const minLabel = calculatePeopleAtPercentile(
            'min',
            filteredDataFinal[j].data[2].count
          )
          const p10Label = calculatePeopleAtPercentile(
            'p10',
            filteredDataFinal[j].data[2].count
          )
          const p25Label = calculatePeopleAtPercentile(
            'p25',
            filteredDataFinal[j].data[2].count
          )
          const medianLabel = calculatePeopleAtPercentile(
            'median',
            filteredDataFinal[j].data[2].count
          )
          const p75Label = calculatePeopleAtPercentile(
            'p75',
            filteredDataFinal[j].data[2].count
          )
          const p90Label = calculatePeopleAtPercentile(
            'p90',
            filteredDataFinal[j].data[2].count
          )
          const maxLabel = calculatePeopleAtPercentile(
            'max',
            filteredDataFinal[j].data[2].count
          )
          const avgLabel = calculatePeopleAtPercentile(
            'avg',
            filteredDataFinal[j].data[2].count
          )
          minData.push({
            y: Number(filteredDataFinal[j].data[2].min.toFixed(2)),
            meta: `${minLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[2].min.toFixed(2)
            )} | ${minLabel.pCount} of ${
              minLabel.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].min.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date',
          })
          minDataCount.push({
            y: filteredDataFinal[j].data[2].count,
            meta: `${minLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[2].min.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date',
          })
          medianData.push({
            y: Number(filteredDataFinal[j].data[2].median.toFixed(2)),
            meta: `${medianLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[2].median.toFixed(2)
            )} | ${medianLabel.pCount} of ${
              medianLabel.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].median.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date',
          })
          medianDataCount.push({
            y: filteredDataFinal[j].data[2].count,
            meta: `${medianLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[2].median.toFixed(2)
            )} | ${medianLabel.pCount} of ${
              medianLabel.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].median.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date',
          })
          maxData.push({
            y: Number(filteredDataFinal[j].data[2].max.toFixed(2)),
            meta: `${maxLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[2].max.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date',
          })

          maxDataCount.push({
            y: filteredDataFinal[j].data[2].count,
            meta: `${maxLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[2].max.toFixed(2)
            )} `,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date',
          })
          p10Data.push({
            y: Number(filteredDataFinal[j].data[2].p10.toFixed(2)),
            meta: `${p10Label.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p10.toFixed(2)
            )} | ${p10Label.pCount} of ${
              p10Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].p10.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date',
          })
          p10DataCount.push({
            y: filteredDataFinal[j].data[2].count,
            meta: `${p10Label.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p10.toFixed(2)
            )} | ${p10Label.pCount} of ${
              p10Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].p10.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date',
          })
          p25Data.push({
            y: Number(filteredDataFinal[j].data[2].p25.toFixed(2)),
            meta: `${p25Label.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p25.toFixed(2)
            )} | ${p25Label.pCount} of ${
              p25Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].p25.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date',
          })
          p25DataCount.push({
            y: filteredDataFinal[j].data[2].count,
            meta: `${p25Label.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p25.toFixed(2)
            )} | ${p25Label.pCount} of ${
              p25Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].p25.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date',
          })
          p75Data.push({
            y: Number(filteredDataFinal[j].data[2].p75.toFixed(2)),
            meta: `${p75Label.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p75.toFixed(2)
            )} | ${p75Label.pCount} of ${
              p75Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].p75.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date',
          })
          p75DataCount.push({
            y: filteredDataFinal[j].data[2].count,
            meta: `${p75Label.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p75.toFixed(2)
            )} | ${p75Label.pCount} of ${
              p75Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].p75.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date',
          })
          p90Data.push({
            y: Number(filteredDataFinal[j].data[2].p90.toFixed(2)),
            meta: `${p90Label.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p90.toFixed(2)
            )} | ${p90Label.pCount} of ${
              p90Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].p90.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date',
          })
          p90DataCount.push({
            y: filteredDataFinal[j].data[2].count,
            meta: `${p90Label.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p90.toFixed(2)
            )} | ${p90Label.pCount} of ${
              p90Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].p90.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date',
          })
          avgData.push({
            y: Number(filteredDataFinal[j].data[2].avg.toFixed(2)),
            meta: `${avgLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[2].avg.toFixed(2)
            )} | ${avgLabel.pCount} of ${
              avgLabel.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].avg.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date',
          })
          avgDataCount.push({
            y: filteredDataFinal[j].data[2].count,
            meta: `${avgLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[2].avg.toFixed(2)
            )} | ${avgLabel.pCount} of ${
              avgLabel.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].avg.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[2].count,
            x: 'Discharge Date',
          })
        } else {
          const minLabel = calculatePeopleAtPercentile(
            'min',
            filteredDataFinal[j].data[3].count
          )
          const p10Label = calculatePeopleAtPercentile(
            'p10',
            filteredDataFinal[j].data[3].count
          )
          const p25Label = calculatePeopleAtPercentile(
            'p25',
            filteredDataFinal[j].data[3].count
          )
          const medianLabel = calculatePeopleAtPercentile(
            'median',
            filteredDataFinal[j].data[3].count
          )
          const p75Label = calculatePeopleAtPercentile(
            'p75',
            filteredDataFinal[j].data[3].count
          )
          const p90Label = calculatePeopleAtPercentile(
            'p90',
            filteredDataFinal[j].data[3].count
          )
          const maxLabel = calculatePeopleAtPercentile(
            'max',
            filteredDataFinal[j].data[3].count
          )
          const avgLabel = calculatePeopleAtPercentile(
            'avg',
            filteredDataFinal[j].data[3].count
          )
          minData.push({
            y: Number(filteredDataFinal[j].data[3].min.toFixed(2)),
            meta: `${minLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[3].min.toFixed(2)
            )} | ${minLabel.pCount} of ${
              minLabel.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[3].min.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[3].count,
            x: 'Discharge Date',
          })
          minDataCount.push({
            y: filteredDataFinal[j].data[3].count,
            meta: `${minLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[3].min.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[3].count,
            x: 'Discharge Date',
          })
          medianData.push({
            y: Number(filteredDataFinal[j].data[3].median.toFixed(2)),
            meta: `${medianLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[3].median.toFixed(2)
            )} | ${medianLabel.pCount} of ${
              medianLabel.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[3].median.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[3].count,
            x: 'Discharge Date',
          })
          medianDataCount.push({
            y: filteredDataFinal[j].data[3].count,
            meta: `${medianLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[3].median.toFixed(2)
            )} | ${medianLabel.pCount} of ${
              medianLabel.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[3].median.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[3].count,
            x: 'Discharge Date',
          })
          maxData.push({
            y: Number(filteredDataFinal[j].data[3].max.toFixed(2)),
            meta: `${maxLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[3].max.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[3].count,
            x: 'Discharge Date',
          })
          maxDataCount.push({
            y: filteredDataFinal[j].data[3].count,
            meta: `${maxLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[3].max.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[3].count,
            x: 'Discharge Date',
          })
          p10Data.push({
            y: Number(filteredDataFinal[j].data[3].p10.toFixed(2)),
            meta: `${p10Label.pLabel}: ${Number(
              filteredDataFinal[j].data[3].p10.toFixed(2)
            )} | ${p10Label.pCount} of ${
              p10Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[3].p10.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[3].count,
            x: 'Discharge Date',
          })
          p10DataCount.push({
            y: filteredDataFinal[j].data[3].count,
            meta: `${p10Label.pLabel}: ${Number(
              filteredDataFinal[j].data[3].p10.toFixed(2)
            )} | ${p10Label.pCount} of ${
              p10Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[3].p10.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[3].count,
            x: 'Discharge Date',
          })
          p25Data.push({
            y: Number(filteredDataFinal[j].data[3].p25.toFixed(2)),
            meta: `${p25Label.pLabel}: ${Number(
              filteredDataFinal[j].data[3].p25.toFixed(2)
            )} | ${p25Label.pCount} of ${
              p25Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[3].p25.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[3].count,
            x: 'Discharge Date',
          })

          p25DataCount.push({
            y: filteredDataFinal[j].data[3].count,
            meta: `${p25Label.pLabel}: ${Number(
              filteredDataFinal[j].data[3].p25.toFixed(2)
            )} | ${p25Label.pCount} of ${
              p25Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[3].p25.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[3].count,
            x: 'Discharge Date',
          })
          p75Data.push({
            y: Number(filteredDataFinal[j].data[3].p75.toFixed(2)),
            meta: `${p75Label.pLabel}: ${Number(
              filteredDataFinal[j].data[3].p75.toFixed(2)
            )} | ${p75Label.pCount} of ${
              p75Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[3].p75.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[3].count,
            x: 'Discharge Date',
          })
          p75DataCount.push({
            y: filteredDataFinal[j].data[3].count,
            meta: `${p75Label.pLabel}: ${Number(
              filteredDataFinal[j].data[3].p75.toFixed(2)
            )} | ${p75Label.pCount} of ${
              p75Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[3].p75.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[3].count,
            x: 'Discharge Date',
          })
          p90Data.push({
            y: Number(filteredDataFinal[j].data[3].p90.toFixed(2)),
            meta: `${p90Label.pLabel}: ${Number(
              filteredDataFinal[j].data[3].p90.toFixed(2)
            )} | ${p90Label.pCount} of ${
              p90Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[3].p90.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[3].count,
            x: 'Discharge Date',
          })
          p90DataCount.push({
            y: filteredDataFinal[j].data[3].count,
            meta: `${p90Label.pLabel}: ${Number(
              filteredDataFinal[j].data[3].p90.toFixed(2)
            )} | ${p90Label.pCount} of ${
              p90Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[3].p90.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[3].count,
            x: 'Discharge Date',
          })
          avgData.push({
            y: Number(filteredDataFinal[j].data[3].avg.toFixed(2)),
            meta: `${avgLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[3].avg.toFixed(2)
            )} | ${avgLabel.pCount} of ${
              avgLabel.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[3].avg.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[3].count,
            x: 'Discharge Date',
          })
          avgDataCount.push({
            y: filteredDataFinal[j].data[3].count,
            meta: `${avgLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[3].avg.toFixed(2)
            )} | ${avgLabel.pCount} of ${
              avgLabel.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[3].avg.toFixed(2)
            )}`,
            goals: filteredDataFinal[j].data[3].count,
            x: 'Discharge Date',
          })
        }
      }
      if (percentile === 'min') {
        const minArray: SummaryDataWithCount = {
          name:
            filteredDataFinal[j].strataName.length > 0
              ? `${filteredDataFinal[j].strataName}`
              : 'No Strata (Base Cohort)',
          data: minData,
          group: `${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMedian(j),
          type: 'line',
        }

        const minArrayCount: SummaryDataWithCount = {
          name:
            filteredDataFinal[j].strataName.length > 0
              ? `${filteredDataFinal[j].strataName}`
              : 'No Strata (Base Cohort)',
          data: minDataCount,
          group: `${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMedian(j),
          type: 'bar',
        }
        postProcessedData.push(minArray)
        postProcessedData.push(minArrayCount)
      }
      if (percentile === 'p10') {
        const p10Obj: SummaryDataWithCount = {
          name:
            filteredDataFinal[j].strataName.length > 0
              ? `${filteredDataFinal[j].strataName}`
              : 'No Strata (Base Cohort)',
          data: p10Data,
          group: `${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMax(j),
          type: 'line',
        }
        const p10ObjCount: SummaryDataWithCount = {
          name:
            filteredDataFinal[j].strataName.length > 0
              ? `${filteredDataFinal[j].strataName}`
              : 'No Strata (Base Cohort)',
          data: p10DataCount,
          group: `${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMax(j),
          type: 'bar',
        }
        postProcessedData.push(p10Obj)
        postProcessedData.push(p10ObjCount)
      }
      if (percentile === 'p25') {
        const p25Obj: SummaryDataWithCount = {
          name:
            filteredDataFinal[j].strataName.length > 0
              ? `${filteredDataFinal[j].strataName}`
              : 'No Strata (Base Cohort)',
          data: p25Data,
          group: `${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMax(j),
          type: 'line',
        }
        const p25ObjCount: SummaryDataWithCount = {
          name:
            filteredDataFinal[j].strataName.length > 0
              ? `${filteredDataFinal[j].strataName}`
              : 'No Strata (Base Cohort)',
          data: p25DataCount,
          group: `${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMax(j),
          type: 'line',
        }
        postProcessedData.push(p25Obj)
        postProcessedData.push(p25ObjCount)
      }
      if (percentile === 'median') {
        const medianObj: SummaryDataWithCount = {
          name:
            filteredDataFinal[j].strataName.length > 0
              ? `${filteredDataFinal[j].strataName}`
              : 'No Strata (Base Cohort)',
          data: medianData,
          group: `${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMax(j),
          type: 'line',
        }
        const medianObjCount: SummaryDataWithCount = {
          name:
            filteredDataFinal[j].strataName.length > 0
              ? `${filteredDataFinal[j].strataName}`
              : 'No Strata (Base Cohort)',
          data: medianDataCount,
          group: `${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMax(j),
          type: 'bar',
        }
        postProcessedData.push(medianObj)
        postProcessedData.push(medianObjCount)
      }
      if (percentile === 'p75') {
        const p75Obj: SummaryDataWithCount = {
          name:
            filteredDataFinal[j].strataName.length > 0
              ? `${filteredDataFinal[j].strataName}`
              : 'No Strata (Base Cohort)',
          data: p75Data,
          group: `${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMax(j),
          type: 'line',
        }
        const p75ObjCount: SummaryDataWithCount = {
          name:
            filteredDataFinal[j].strataName.length > 0
              ? `${filteredDataFinal[j].strataName}`
              : 'No Strata (Base Cohort)',
          data: p75DataCount,
          group: `${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMax(j),
          type: 'bar',
        }
        postProcessedData.push(p75ObjCount)
        postProcessedData.push(p75Obj)
      }
      if (percentile === 'p90') {
        const p90Obj: SummaryDataWithCount = {
          name:
            filteredDataFinal[j].strataName.length > 0
              ? `${filteredDataFinal[j].strataName}`
              : 'No Strata (Base Cohort)',
          data: p90Data,
          group: `${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMax(j),
          type: 'line',
        }

        const p90ObjCount: SummaryDataWithCount = {
          name:
            filteredDataFinal[j].strataName.length > 0
              ? `${filteredDataFinal[j].strataName}`
              : 'No Strata (Base Cohort)',
          data: p90DataCount,
          group: `${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMax(j),
          type: 'bar',
        }
        postProcessedData.push(p90ObjCount)
        postProcessedData.push(p90Obj)
      }
      if (percentile === 'max') {
        const maxObj: SummaryDataWithCount = {
          name:
            filteredDataFinal[j].strataName.length > 0
              ? `${filteredDataFinal[j].strataName}`
              : 'No Strata (Base Cohort)',
          data: maxData,
          group: `${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMedian(j),
          type: 'line',
        }
        const maxObjCount: SummaryDataWithCount = {
          name:
            filteredDataFinal[j].strataName.length > 0
              ? `${filteredDataFinal[j].strataName}`
              : 'No Strata (Base Cohort)',
          data: maxDataCount,
          group: `${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMedian(j),
          type: 'bar',
        }
        postProcessedData.push(maxObjCount)
        postProcessedData.push(maxObj)
      }
      if (percentile === 'avg') {
        const avjObj: SummaryDataWithCount = {
          name:
            filteredDataFinal[j].strataName.length > 0
              ? `${filteredDataFinal[j].strataName}`
              : 'No Strata (Base Cohort)',
          data: avgData,
          group: `${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMax(j),
          type: 'line',
        }
        const avjObjCount: SummaryDataWithCount = {
          name:
            filteredDataFinal[j].strataName.length > 0
              ? `${filteredDataFinal[j].strataName}`
              : 'No Strata (Base Cohort)',
          data: avgDataCount,
          group: `${filteredDataFinal[j].strataName}`,
          color: getColorCodeForMax(j),
          type: 'bar',
        }
        postProcessedData.push(avjObjCount)
        postProcessedData.push(avjObj)
      }
    }

    outComeData.push({
      analysisName: analysisFilter[i].name,
      summaryWithCount: postProcessedData,
      patientSummaryWithCount: postProcessedDataCount,
    })
  }

  return outComeData
}

export function getCohortDataForFilterForSinglePercentileForMultiGraph(
  response: any,
  analysisFilter: string[],
  inputData: Filters[],
  cohortFilters: string[],
  strataFilter: Filters[],
  percentile: string
): CohortDisplayWithCount[] {
  let postProcessedData: SummaryDataWithCount[] = []
  let minData: any[] = []
  let p10Data: any[] = []
  let p25Data: any[] = []
  let indexData: number[] = []
  let medianData: any[] = []
  let p75Data: any[] = []
  let p90Data: any[] = []
  let avgData: any[] = []
  let maxData: any[] = []

  let minDataCount: any[] = []
  let p10DataCount: any[] = []
  let p25DataCount: any[] = []

  let medianDataCount: any[] = []
  let p75DataCount: any[] = []
  let p90DataCount: any[] = []
  let avgDataCount: any[] = []
  let maxDataCount: any[] = []

  let finalResult: number[] = []

  const strataFilterDataUpdated: any[] = []

  const outComeData: CohortDisplayWithCount[] = []
  const filterAnalysis: number[] = []
  const cohortAnalysisFilter: number[] = []
  for (let n = 0; n < analysisFilter.length; n++) {
    filterAnalysis.push(parseInt(analysisFilter[n], 10))
  }

  for (let n = 0; n < cohortFilters.length; n++) {
    cohortAnalysisFilter.push(parseInt(cohortFilters[n], 10))
  }
  cohortAnalysisFilter.sort()
  filterAnalysis.sort()

  for (let n = 0; n < cohortAnalysisFilter.length; n++) {
    const coloredArray = strataFilter.filter(
      (d: Filters) => parseInt(d.id, 10) === cohortAnalysisFilter[n]
    )

    strataFilterDataUpdated.push({
      id: parseInt(coloredArray[0].id, 10),
      dummyId: coloredArray[0].dummyId,
      color: coloredArray[0].color,
    })
  }

  for (let i = 0; i < filterAnalysis.length; i++) {
    postProcessedData = []

    const filteredData = response.filter(
      (d: any) => d.analysisId === filterAnalysis[i]
    )

    const analyticData = inputData.filter(
      (d: Filters) => parseInt(d.id, 10) === filterAnalysis[i]
    )

    for (let m = 0; m < strataFilterDataUpdated.length; m++) {
      const filteredDataFinal = filteredData.filter(
        (d: any) => d.strataId === strataFilterDataUpdated[m].id
      )

      for (let j = 0; j < filteredDataFinal.length; j++) {
        minData = []
        medianData = []
        maxData = []
        indexData = []
        finalResult = []
        p10Data = []
        p25Data = []
        p75Data = []
        p90Data = []
        avgData = []

        minDataCount = []
        p10DataCount = []
        p25DataCount = []
        medianDataCount = []
        p75DataCount = []
        p90DataCount = []
        avgDataCount = []
        maxDataCount = []

        for (let k = 0; k < filteredDataFinal[j].data.length; k++) {
          indexData.push(filteredDataFinal[j].data[k].time_norm_idx)
        }

        finalResult = indexData.filter((d: number) => d === 0)

        if (finalResult.length === 0) {
          minData.push({
            x: 'Admission Date',
            y: null,
            meta: '',
            goals: {
              count: '',
              label: '',
            },
          })
          minDataCount.push({
            x: 'Admission Date',
            y: null,
            meta: '',
            goals: {
              count: '',
              label: '',
            },
          })
          medianData.push({
            x: 'Admission Date',
            y: null,
            meta: '',
            goals: {
              count: '',
              label: '',
            },
          })
          medianDataCount.push({
            x: 'Admission Date',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
          maxData.push({
            x: 'Admission Date',
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
          maxDataCount.push({
            x: 'Admission Date',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
          p10Data.push({
            x: 'Admission Date',
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
          p10DataCount.push({
            x: 'Admission Date',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
          p25Data.push({
            x: 'Admission Date',
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
          p25DataCount.push({
            x: 'Admission Date',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
          p75Data.push({
            x: 'Admission Date',
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
          p75DataCount.push({
            x: 'Admission Date',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
          p90Data.push({
            x: 'Admission Date',
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
          p90DataCount.push({
            x: 'Admission Date',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
          avgData.push({
            x: 'Admission Date',
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
          avgDataCount.push({
            x: 'Admission Date',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
        } else {
          const minLabel = calculatePeopleAtPercentile(
            'min',
            filteredDataFinal[j].data[0].count
          )
          const p10Label = calculatePeopleAtPercentile(
            'p10',
            filteredDataFinal[j].data[0].count
          )
          const p25Label = calculatePeopleAtPercentile(
            'p25',
            filteredDataFinal[j].data[0].count
          )
          const medianLabel = calculatePeopleAtPercentile(
            'median',
            filteredDataFinal[j].data[0].count
          )
          const p75Label = calculatePeopleAtPercentile(
            'p75',
            filteredDataFinal[j].data[0].count
          )
          const p90Label = calculatePeopleAtPercentile(
            'p90',
            filteredDataFinal[j].data[0].count
          )
          const maxLabel = calculatePeopleAtPercentile(
            'max',
            filteredDataFinal[j].data[0].count
          )
          const avgLabel = calculatePeopleAtPercentile(
            'avg',
            filteredDataFinal[j].data[0].count
          )
          minData.push({
            x: 'Admission Date',
            y: Number(filteredDataFinal[j].data[0].min.toFixed(2)),
            meta: `${minLabel.pLabel}: ${filteredDataFinal[j].data[0].min} | ${
              minLabel.pCount
            } of ${minLabel.totalCount} with value <= ${Number(
              filteredDataFinal[j].data[0].min.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[0].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })
          minDataCount.push({
            x: 'Admission Date',
            y: filteredDataFinal[j].data[0].count,
            meta: `${minLabel.pLabel}: ${filteredDataFinal[j].data[0].min}`,
            goals: {
              count: filteredDataFinal[j].data[0].count,
              label: 'Patients',
            },
          })

          //   medianDataCount.push({
          //     x: 'Admission Date',
          //     y: filteredDataFinal[j].data[0].count,
          //     meta: `${medianLabel.pLabel}: ${Number(
          //       filteredDataFinal[j].data[0].median.toFixed(2)
          //     )}`,
          //     goals: filteredDataFinal[j].data[0].count,
          //   })
          medianData.push({
            x: 'Admission Date',
            y: Number(filteredDataFinal[j].data[0].median.toFixed(2)),
            meta: `${medianLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[0].median.toFixed(2)
            )} | ${medianLabel.pCount} of ${
              medianLabel.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[0].median.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[0].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })
          medianDataCount.push({
            x: 'Admission Date',
            y: filteredDataFinal[j].data[0].count,
            meta: `${medianLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[0].median.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[0].count,
              label: 'Patients',
            },
          })
          maxData.push({
            x: 'Admission Date',
            y: Number(filteredDataFinal[j].data[0].max.toFixed(2)),
            meta: `${maxLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[0].max.toFixed(2)
            )} | ${maxLabel.pCount} of ${
              maxLabel.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[0].max.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[0].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })
          maxDataCount.push({
            x: 'Admission Date',
            y: filteredDataFinal[j].data[0].count,
            meta: `${maxLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[0].max.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[0].count,
              label: 'Patients',
            },
          })
          p10Data.push({
            x: 'Admission Date',
            y: Number(filteredDataFinal[j].data[0].p10.toFixed(2)),
            meta: `${p10Label.pLabel}: ${Number(
              filteredDataFinal[j].data[0].p10.toFixed(2)
            )} | ${p10Label.pCount} of ${
              p10Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[0].p10.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[0].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })
          p10DataCount.push({
            x: 'Admission Date',
            y: filteredDataFinal[j].data[0].count,
            meta: `${p10Label.pLabel}: ${Number(
              filteredDataFinal[j].data[0].p10.toFixed(2)
            )} `,
            goals: {
              count: filteredDataFinal[j].data[0].count,
              label: 'Patients',
            },
          })
          p25Data.push({
            x: 'Admission Date',
            y: Number(filteredDataFinal[j].data[0].p25.toFixed(2)),
            meta: `${p25Label.pLabel}: ${Number(
              filteredDataFinal[j].data[0].p25.toFixed(2)
            )} | ${p25Label.pCount} of ${
              p25Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[0].p25.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[0].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })
          p25DataCount.push({
            x: 'Admission Date',
            y: filteredDataFinal[j].data[0].count,
            meta: `${p25Label.pLabel}: ${Number(
              filteredDataFinal[j].data[0].p25.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[0].count,
              label: 'Patients',
            },
          })
          p75Data.push({
            x: 'Admission Date',
            y: Number(filteredDataFinal[j].data[0].p75.toFixed(2)),
            meta: `${p75Label.pLabel}: ${Number(
              filteredDataFinal[j].data[0].p75.toFixed(2)
            )} | ${p75Label.pCount} of ${
              p75Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[0].p75.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[0].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })

          p75DataCount.push({
            x: 'Admission Date',
            y: filteredDataFinal[j].data[0].count,
            meta: `${p75Label.pLabel}: ${Number(
              filteredDataFinal[j].data[0].p75.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[0].count,
              label: 'Patients',
            },
          })
          p90Data.push({
            x: 'Admission Date',
            y: Number(filteredDataFinal[j].data[0].p90.toFixed(2)),
            meta: `${p90Label.pLabel}: ${Number(
              filteredDataFinal[j].data[0].p90.toFixed(2)
            )} | ${p90Label.pCount} of ${
              p90Label.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[0].p90.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[0].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })
          p90DataCount.push({
            x: 'Admission Date',
            y: filteredDataFinal[j].data[0].count,
            meta: `${p90Label.pLabel}: ${Number(
              filteredDataFinal[j].data[0].p90.toFixed(2)
            )} `,
            goals: {
              count: filteredDataFinal[j].data[0].count,
              label: 'Patients',
            },
          })
          avgData.push({
            x: 'Admission Date',
            y: Number(filteredDataFinal[j].data[0].avg.toFixed(2)),
            meta: `${avgLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[0].avg.toFixed(2)
            )} | ${avgLabel.pCount} of ${
              avgLabel.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[0].avg.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[0].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })
          avgDataCount.push({
            x: 'Admission Date',
            y: filteredDataFinal[j].data[0].count,
            meta: `${avgLabel.pLabel}: ${Number(
              filteredDataFinal[j].data[0].avg.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[0].count,
              label: 'Patients',
            },
          })
          //   p90Data.push(filteredDataFinal[j].data[0].p90)
          //   //   minData.push(filteredDataFinal[j].data[0].min)
          //   medianData.push(filteredDataFinal[j].data[0].median)
          //   maxData.push(filteredDataFinal[j].data[0].max)
          //   p10Data.push(filteredDataFinal[j].data[0].p10)
          //   p25Data.push(filteredDataFinal[j].data[0].p25)
          //   p75Data.push(filteredDataFinal[j].data[0].p75)
          //   p90Data.push(filteredDataFinal[j].data[0].p90)

          //   avgData.push(filteredDataFinal[j].data[0].avg)
        }
        finalResult = []
        finalResult = indexData.filter((d: number) => d === 1)

        if (finalResult.length === 0) {
          minData.push({
            x: 'Admission Date + 1',
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
          medianData.push({
            x: 'Admission Date + 1',
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
          maxData.push({
            x: 'Admission Date + 1',
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
          p10Data.push({
            y: null,
            x: 'Admission Date + 1',
            meta: ' ',

            goals: {
              count: '',
              label: '',
            },
          })
          p25Data.push({
            y: null,
            x: 'Admission Date + 1',
            meta: ' ',

            goals: {
              count: '',
              label: '',
            },
          })
          p75Data.push({
            y: null,
            x: 'Admission Date + 1',
            meta: ' ',

            goals: {
              count: '',
              label: '',
            },
          })
          p90Data.push({
            y: null,
            x: 'Admission Date + 1',
            meta: ' ',

            goals: {
              count: '',
              label: '',
            },
          })
          avgData.push({
            y: null,
            x: 'Admission Date + 1',
            meta: ' ',

            goals: {
              count: '',
              label: '',
            },
          })

          minDataCount.push({
            x: 'Admission Date + 1',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
          medianDataCount.push({
            x: 'Admission Date + 1',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
          maxDataCount.push({
            x: 'Admission Date + 1',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })

          p10DataCount.push({
            x: 'Admission Date + 1',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })

          p25DataCount.push({
            x: 'Admission Date + 1',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })

          p75DataCount.push({
            x: 'Admission Date + 1',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })

          p90DataCount.push({
            x: 'Admission Date + 1',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })

          avgDataCount.push({
            x: 'Admission Date + 1',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
        } else {
          const minLabel1 = calculatePeopleAtPercentile(
            'min',
            filteredDataFinal[j].data[1].count
          )
          const p10Label1 = calculatePeopleAtPercentile(
            'p10',
            filteredDataFinal[j].data[1].count
          )
          const p25Label1 = calculatePeopleAtPercentile(
            'p25',
            filteredDataFinal[j].data[1].count
          )
          const medianLabel1 = calculatePeopleAtPercentile(
            'median',
            filteredDataFinal[j].data[1].count
          )
          const p75Label1 = calculatePeopleAtPercentile(
            'p75',
            filteredDataFinal[j].data[1].count
          )
          const p90Label1 = calculatePeopleAtPercentile(
            'p90',
            filteredDataFinal[j].data[1].count
          )
          const maxLabel1 = calculatePeopleAtPercentile(
            'max',
            filteredDataFinal[j].data[1].count
          )
          const avgLabel1 = calculatePeopleAtPercentile(
            'avg',
            filteredDataFinal[j].data[1].count
          )
          minData.push({
            x: 'Admission Date + 1',
            y: Number(filteredDataFinal[j].data[1].min.toFixed(2)),
            meta: `${minLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].min.toFixed(2)
            )} | ${minLabel1.pCount} of ${
              minLabel1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].min.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[1].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })

          minDataCount.push({
            x: 'Admission Date + 1',
            y: filteredDataFinal[j].data[1].count,
            meta: `${minLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].min.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[1].count,
              label: 'Patients',
            },
          })
          medianData.push({
            x: 'Admission Date + 1',
            y: Number(filteredDataFinal[j].data[1].median.toFixed(2)),
            meta: `${medianLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].median.toFixed(2)
            )} | ${medianLabel1.pCount} of ${
              medianLabel1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].median.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[1].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })
          medianDataCount.push({
            x: 'Admission Date + 1',
            y: filteredDataFinal[j].data[1].count,
            meta: `${medianLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].median.toFixed(2)
            )}`,

            goals: {
              count: filteredDataFinal[j].data[1].count,
              label: 'Patients',
            },
          })
          maxData.push({
            y: Number(filteredDataFinal[j].data[1].max.toFixed(2)),
            meta: `${maxLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].max.toFixed(2)
            )} | ${maxLabel1.pCount} of ${
              maxLabel1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].max.toFixed(2)
            )}`,

            x: 'Admission Date + 1',
            // goals: filteredDataFinal[j].data[1].count,
            goals: {
              count: filteredDataFinal[j].data[1].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })
          maxDataCount.push({
            y: filteredDataFinal[j].data[1].count,
            meta: `${maxLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].max.toFixed(2)
            )} `,

            x: 'Admission Date + 1',
            goals: {
              count: filteredDataFinal[j].data[1].count,
              label: 'Patients',
            },
          })
          p10Data.push({
            x: 'Admission Date + 1',
            y: Number(filteredDataFinal[j].data[1].p10.toFixed(2)),
            meta: `${p10Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p10.toFixed(2)
            )} | ${p10Label1.pCount} of ${
              p10Label1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].p10.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[1].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })
          p10DataCount.push({
            x: 'Admission Date + 1',
            y: filteredDataFinal[j].data[1].count,
            meta: `${p10Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p10.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[1].count,
              label: 'Patients',
            },
          })
          p25Data.push({
            x: 'Admission Date + 1',
            y: Number(filteredDataFinal[j].data[1].p25.toFixed(2)),
            meta: `${p25Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p25.toFixed(2)
            )} | ${p25Label1.pCount} of ${
              p25Label1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].p25.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[1].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })
          p25DataCount.push({
            x: 'Admission Date + 1',
            y: filteredDataFinal[j].data[1].count,
            meta: `${p25Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p25.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[1].count,
              label: 'Patients',
            },
          })
          p75Data.push({
            x: 'Admission Date + 1',
            y: Number(filteredDataFinal[j].data[1].p75.toFixed(2)),
            meta: `${p75Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p75.toFixed(2)
            )} | ${p75Label1.pCount} of ${
              p75Label1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].p75.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[1].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })

          p75DataCount.push({
            x: 'Admission Date + 1',
            y: filteredDataFinal[j].data[1].count,
            meta: `${p75Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p75.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[1].count,
              label: 'Patients',
            },
          })
          p90Data.push({
            x: 'Admission Date + 1',
            y: Number(filteredDataFinal[j].data[1].p90.toFixed(2)),
            meta: `${p90Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p90.toFixed(2)
            )} | ${p90Label1.pCount} of ${
              p90Label1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].p90.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[1].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })
          p90DataCount.push({
            x: 'Admission Date + 1',
            y: filteredDataFinal[j].data[1].count,
            meta: `${p90Label1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].p90.toFixed(2)
            )} `,
            goals: {
              count: filteredDataFinal[j].data[1].count,
              label: 'Patients',
            },
          })
          avgData.push({
            x: 'Admission Date + 1',
            y: Number(filteredDataFinal[j].data[1].avg.toFixed(2)),
            meta: `${avgLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].avg.toFixed(2)
            )} | ${avgLabel1.pCount} of ${
              avgLabel1.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[1].avg.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[1].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })
          avgDataCount.push({
            x: 'Admission Date + 1',
            y: filteredDataFinal[j].data[1].count,
            meta: `${avgLabel1.pLabel}: ${Number(
              filteredDataFinal[j].data[1].avg.toFixed(2)
            )} `,
            goals: {
              count: filteredDataFinal[j].data[1].count,
              label: 'Patients',
            },
          })
        }
        finalResult = []
        finalResult = indexData.filter((d: number) => d === 3)

        if (finalResult.length === 0) {
          minData.push({
            x: 'Discharge Date - 1',
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
          medianData.push({
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },

            x: 'Discharge Date - 1',
          })
          maxData.push({
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },

            x: 'Discharge Date - 1',
          })
          p10Data.push({
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },

            x: 'Discharge Date - 1',
          })
          p25Data.push({
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },

            x: 'Discharge Date - 1',
          })
          p75Data.push({
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
            x: 'Discharge Date - 1',
          })
          p90Data.push({
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },

            x: 'Discharge Date - 1',
          })
          avgData.push({
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },

            x: 'Discharge Date - 1',
          })
          minDataCount.push({
            x: 'Discharge Date - 1',
            y: 0,
            meta: '',
            goals: {
              count: '',
              label: '',
            },
          })
          medianDataCount.push({
            x: 'Discharge Date - 1',
            y: 0,
            meta: '',
            goals: {
              count: '',
              label: '',
            },
          })
          maxDataCount.push({
            x: 'Discharge Date - 1',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })

          p10DataCount.push({
            x: 'Discharge Date - 1',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })

          p25DataCount.push({
            x: 'Discharge Date - 1',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })

          p75DataCount.push({
            x: 'Discharge Date - 1',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })

          p90DataCount.push({
            x: 'Discharge Date - 1',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })

          avgDataCount.push({
            x: 'Discharge Date - 1',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
        } else {
          const minLabel2 = calculatePeopleAtPercentile(
            'min',
            filteredDataFinal[j].data[2].count
          )
          const p10Label2 = calculatePeopleAtPercentile(
            'p10',
            filteredDataFinal[j].data[2].count
          )
          const p25Label2 = calculatePeopleAtPercentile(
            'p25',
            filteredDataFinal[j].data[2].count
          )
          const medianLabel2 = calculatePeopleAtPercentile(
            'median',
            filteredDataFinal[j].data[2].count
          )
          const p75Label2 = calculatePeopleAtPercentile(
            'p75',
            filteredDataFinal[j].data[2].count
          )
          const p90Label2 = calculatePeopleAtPercentile(
            'p90',
            filteredDataFinal[j].data[2].count
          )
          const maxLabel2 = calculatePeopleAtPercentile(
            'max',
            filteredDataFinal[j].data[2].count
          )
          const avgLabel2 = calculatePeopleAtPercentile(
            'avg',
            filteredDataFinal[j].data[2].count
          )
          minData.push({
            y: Number(filteredDataFinal[j].data[2].min.toFixed(2)),
            meta: `${minLabel2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].min.toFixed(2)
            )} | ${minLabel2.pCount} of ${
              minLabel2.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].min.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[2].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
            x: 'Discharge Date - 1',
          })

          minDataCount.push({
            y: filteredDataFinal[j].data[2].count,
            meta: `${minLabel2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].min.toFixed(2)
            )} `,
            goals: {
              count: filteredDataFinal[j].data[2].count,
              label: 'Patients',
            },
            x: 'Discharge Date - 1',
          })

          medianDataCount.push({
            y: filteredDataFinal[j].data[2].count,
            meta: `${medianLabel2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].median.toFixed(2)
            )} `,
            goals: {
              count: filteredDataFinal[j].data[2].count,
              label: 'Patients',
            },
            x: 'Discharge Date - 1',
          })
          medianData.push({
            y: Number(filteredDataFinal[j].data[2].median.toFixed(2)),
            meta: `${medianLabel2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].median.toFixed(2)
            )} | ${medianLabel2.pCount} of ${
              medianLabel2.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].median.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[2].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
            x: 'Discharge Date - 1',
          })
          maxData.push({
            y: Number(filteredDataFinal[j].data[2].max.toFixed(2)),
            meta: `${maxLabel2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].max.toFixed(2)
            )} | ${maxLabel2.pCount} of ${
              maxLabel2.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].max.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[2].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
            x: 'Discharge Date - 1',
          })
          maxDataCount.push({
            y: filteredDataFinal[j].data[2].count,
            meta: `${maxLabel2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].max.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[1].count,
              label: 'Patients',
            },
            x: 'Discharge Date - 1',
          })
          p10Data.push({
            y: Number(filteredDataFinal[j].data[2].p10.toFixed(2)),
            meta: `${p10Label2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p10.toFixed(2)
            )} | ${p10Label2.pCount} of ${
              p10Label2.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].p10.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[2].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
            x: 'Discharge Date - 1',
          })

          p10DataCount.push({
            y: filteredDataFinal[j].data[2].count,
            meta: `${p10Label2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p10.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[2].count,
              label: 'Patients',
            },
            x: 'Discharge Date - 1',
          })
          p25Data.push({
            y: Number(filteredDataFinal[j].data[2].p25.toFixed(2)),
            meta: `${p25Label2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p25.toFixed(2)
            )} | ${p25Label2.pCount} of ${
              p25Label2.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].p25.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[2].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
            x: 'Discharge Date - 1',
          })
          p25DataCount.push({
            y: filteredDataFinal[j].data[2].count,
            meta: `${p25Label2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p25.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[2].count,
              label: 'Patients',
            },
            x: 'Discharge Date - 1',
          })

          p75Data.push({
            x: 'Discharge Date - 1',
            y: Number(filteredDataFinal[j].data[2].p75.toFixed(2)),
            meta: `${p75Label2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p75.toFixed(2)
            )} | ${p75Label2.pCount} of ${
              p75Label2.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].p75.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[2].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })
          p75DataCount.push({
            x: 'Discharge Date - 1',
            y: filteredDataFinal[j].data[2].count,
            meta: `${p75Label2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p75.toFixed(2)
            )} `,
            goals: {
              count: filteredDataFinal[j].data[2].count,
              label: 'Patients',
            },
          })
          p90Data.push({
            x: 'Discharge Date - 1',
            y: Number(filteredDataFinal[j].data[2].p90.toFixed(2)),
            meta: `${p90Label2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p90.toFixed(2)
            )} | ${p90Label2.pCount} of ${
              p90Label2.totalCount
            } with value <= ${Number(
              filteredDataFinal[j].data[2].p90.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[2].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })
          p90DataCount.push({
            x: 'Discharge Date - 1',
            y: filteredDataFinal[j].data[2].count,
            meta: `${p90Label2.pLabel}: ${Number(
              filteredDataFinal[j].data[2].p90.toFixed(2)
            )} `,
            goals: {
              count: filteredDataFinal[j].data[2].count,
              label: 'Patients',
            },
          })
          avgData.push({
            x: 'Discharge Date - 1',
            y: Number(filteredDataFinal[j].data[2].avg.toFixed(2)),
            meta: `${avgLabel2.pLabel}: ${filteredDataFinal[j].data[2].avg} | ${
              avgLabel2.pCount
            } of ${avgLabel2.totalCount} with value <= ${Number(
              filteredDataFinal[j].data[2].avg.toFixed(2)
            )}`,
            goals: {
              count: filteredDataFinal[j].data[2].count,
              label:
                analyticData[0].name === 'Diastolic BP'
                  ? 'Diastolic'
                  : 'Systolic',
            },
          })
          avgDataCount.push({
            x: 'Discharge Date - 1',
            y: filteredDataFinal[j].data[2].count,
            meta: `${avgLabel2.pLabel}: ${filteredDataFinal[j].data[2].avg}`,
            goals: {
              count: filteredDataFinal[j].data[2].count,
              label: 'Patients',
            },
          })
        }
        finalResult = []
        finalResult = indexData.filter((d: number) => d === 4)

        if (finalResult.length === 0) {
          minData.push({
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },

            x: 'Discharge Date',
          })
          medianData.push({
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },

            x: 'Discharge Date',
          })
          maxData.push({
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },

            x: 'Discharge Date',
          })
          p10Data.push({
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },

            x: 'Discharge Date',
          })
          p25Data.push({
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },

            x: 'Discharge Date',
          })
          p75Data.push({
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },

            x: 'Discharge Date',
          })
          p90Data.push({
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },

            x: 'Discharge Date',
          })
          avgData.push({
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },

            x: 'Discharge Date',
          })

          minDataCount.push({
            x: 'Discharge Date',
            y: 0,
            meta: '',
            goals: {
              count: '',
              label: '',
            },
          })
          medianDataCount.push({
            x: 'Discharge Date',
            y: 0,
            meta: '',
            goals: {
              count: '',
              label: '',
            },
          })
          maxDataCount.push({
            x: 'Discharge Date',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })

          p10DataCount.push({
            x: 'Discharge Date',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })

          p25DataCount.push({
            x: 'Discharge Date',
            y: 0,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })

          p75DataCount.push({
            x: 'Discharge Date',
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })

          p90DataCount.push({
            x: 'Discharge Date',
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })

          avgDataCount.push({
            x: 'Discharge Date',
            y: null,
            meta: ' ',
            goals: {
              count: '',
              label: '',
            },
          })
        } else {
          if (filteredDataFinal[j].data.length === 2) {
            const minLabel1 = calculatePeopleAtPercentile(
              'min',
              filteredDataFinal[j].data[1].count
            )
            const p10Label1 = calculatePeopleAtPercentile(
              'p10',
              filteredDataFinal[j].data[1].count
            )
            const p25Label1 = calculatePeopleAtPercentile(
              'p25',
              filteredDataFinal[j].data[1].count
            )
            const medianLabel1 = calculatePeopleAtPercentile(
              'median',
              filteredDataFinal[j].data[1].count
            )
            const p75Label1 = calculatePeopleAtPercentile(
              'p75',
              filteredDataFinal[j].data[1].count
            )
            const p90Label1 = calculatePeopleAtPercentile(
              'p90',
              filteredDataFinal[j].data[1].count
            )
            const maxLabel1 = calculatePeopleAtPercentile(
              'max',
              filteredDataFinal[j].data[1].count
            )
            const avgLabel1 = calculatePeopleAtPercentile(
              'avg',
              filteredDataFinal[j].data[1].count
            )
            minData.push({
              y: Number(filteredDataFinal[j].data[1].min.toFixed(2)),
              meta: `${minLabel1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].min.toFixed(2)
              )} | ${minLabel1.pCount} of ${
                minLabel1.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[1].min.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[1].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },

              x: 'Discharge Date',
            })

            minDataCount.push({
              y: filteredDataFinal[j].data[1].count,
              meta: `${minLabel1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].min.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[1].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            medianData.push({
              y: Number(filteredDataFinal[j].data[1].median.toFixed(2)),
              meta: `${medianLabel1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].median.toFixed(2)
              )} | ${medianLabel1.pCount} of ${
                medianLabel1.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[1].median.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[1].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },

              x: 'Discharge Date',
            })
            medianDataCount.push({
              y: filteredDataFinal[j].data[1].count,
              meta: `${medianLabel1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].median.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[1].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            maxData.push({
              y: Number(filteredDataFinal[j].data[1].max.toFixed(2)),
              meta: `${maxLabel1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].max.toFixed(2)
              )} | ${maxLabel1.pCount} of ${
                maxLabel1.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[1].max.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[1].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },

              x: 'Discharge Date',
            })

            maxDataCount.push({
              y: filteredDataFinal[j].data[1].count,
              meta: `${maxLabel1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].max.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[1].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            p10Data.push({
              y: Number(filteredDataFinal[j].data[1].p10.toFixed(2)),
              meta: `${p10Label1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].p10.toFixed(2)
              )} | ${p10Label1.pCount} of ${
                p10Label1.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[1].p10.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[1].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },

              x: 'Discharge Date',
            })
            p10DataCount.push({
              y: filteredDataFinal[j].data[1].count,
              meta: `${p10Label1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].p10.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[1].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            p25Data.push({
              y: Number(filteredDataFinal[j].data[1].p25.toFixed(2)),
              meta: `${p25Label1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].p25.toFixed(2)
              )} | ${p25Label1.pCount} of ${
                p25Label1.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[1].p25.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[1].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },

              x: 'Discharge Date',
            })
            p25DataCount.push({
              y: filteredDataFinal[j].data[1].count,
              meta: `${p25Label1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].p25.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[1].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            p75Data.push({
              y: Number(filteredDataFinal[j].data[1].p75.toFixed(2)),
              meta: `${p75Label1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].p75.toFixed(2)
              )} | ${p75Label1.pCount} of ${
                p75Label1.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[1].p75.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[1].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },

              x: 'Discharge Date',
            })
            p75DataCount.push({
              y: filteredDataFinal[j].data[1].count,
              meta: `${p75Label1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].p75.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[1].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            p90Data.push({
              y: Number(filteredDataFinal[j].data[1].p90.toFixed(2)),
              meta: `${p90Label1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].p90.toFixed(2)
              )} | ${p90Label1.pCount} of ${
                p90Label1.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[1].p90.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[1].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },

              x: 'Discharge Date',
            })
            p90DataCount.push({
              y: filteredDataFinal[j].data[1].count,
              meta: `${p90Label1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].p90.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[1].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            avgData.push({
              y: Number(filteredDataFinal[j].data[1].avg.toFixed(2)),
              meta: `${avgLabel1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].avg.toFixed(2)
              )} | ${avgLabel1.pCount} of ${
                avgLabel1.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[1].avg.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[1].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },

              x: 'Discharge Date',
            })
            avgDataCount.push({
              y: filteredDataFinal[j].data[1].count,
              meta: `${avgLabel1.pLabel}: ${Number(
                filteredDataFinal[j].data[1].avg.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[1].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            // avgData.push(Number(filteredDataFinal[j].data[1].avg.toFixed(2)))
          } else if (filteredData[j].data.length === 3) {
            const minLabel = calculatePeopleAtPercentile(
              'min',
              filteredDataFinal[j].data[2].count
            )
            const p10Label = calculatePeopleAtPercentile(
              'p10',
              filteredDataFinal[j].data[2].count
            )
            const p25Label = calculatePeopleAtPercentile(
              'p25',
              filteredDataFinal[j].data[2].count
            )
            const medianLabel = calculatePeopleAtPercentile(
              'median',
              filteredDataFinal[j].data[2].count
            )
            const p75Label = calculatePeopleAtPercentile(
              'p75',
              filteredDataFinal[j].data[2].count
            )
            const p90Label = calculatePeopleAtPercentile(
              'p90',
              filteredDataFinal[j].data[2].count
            )
            const maxLabel = calculatePeopleAtPercentile(
              'max',
              filteredDataFinal[j].data[2].count
            )
            const avgLabel = calculatePeopleAtPercentile(
              'avg',
              filteredDataFinal[j].data[2].count
            )
            minData.push({
              y: Number(filteredDataFinal[j].data[2].min.toFixed(2)),
              meta: `${minLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[2].min.toFixed(2)
              )} | ${minLabel.pCount} of ${
                minLabel.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[2].min.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[2].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },
              x: 'Discharge Date',
            })
            minDataCount.push({
              y: filteredDataFinal[j].data[2].count,
              meta: `${minLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[2].min.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[2].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            medianData.push({
              y: Number(filteredDataFinal[j].data[2].median.toFixed(2)),
              meta: `${medianLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[2].median.toFixed(2)
              )} | ${medianLabel.pCount} of ${
                medianLabel.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[2].median.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[2].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },
              x: 'Discharge Date',
            })
            medianDataCount.push({
              y: filteredDataFinal[j].data[2].count,
              meta: `${medianLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[2].median.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[2].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            maxData.push({
              y: Number(filteredDataFinal[j].data[2].max.toFixed(2)),
              meta: `${maxLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[2].max.toFixed(2)
              )} | ${maxLabel.pCount} of ${
                maxLabel.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[2].max.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[2].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },
              x: 'Discharge Date',
            })

            maxDataCount.push({
              y: filteredDataFinal[j].data[2].count,
              meta: `${maxLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[2].max.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[2].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            p10Data.push({
              y: Number(filteredDataFinal[j].data[2].p10.toFixed(2)),
              meta: `${p10Label.pLabel}: ${Number(
                filteredDataFinal[j].data[2].p10.toFixed(2)
              )} | ${p10Label.pCount} of ${
                p10Label.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[2].p10.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[2].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },
              x: 'Discharge Date',
            })
            p10DataCount.push({
              y: filteredDataFinal[j].data[2].count,
              meta: `${p10Label.pLabel}: ${Number(
                filteredDataFinal[j].data[2].p10.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[2].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            p25Data.push({
              y: Number(filteredDataFinal[j].data[2].p25.toFixed(2)),
              meta: `${p25Label.pLabel}: ${Number(
                filteredDataFinal[j].data[2].p25.toFixed(2)
              )} | ${p25Label.pCount} of ${
                p25Label.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[2].p25.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[2].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },
              x: 'Discharge Date',
            })
            p25DataCount.push({
              y: filteredDataFinal[j].data[2].count,
              meta: `${p25Label.pLabel}: ${Number(
                filteredDataFinal[j].data[2].p25.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[2].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            p75Data.push({
              y: Number(filteredDataFinal[j].data[2].p75.toFixed(2)),
              meta: `${p75Label.pLabel}: ${Number(
                filteredDataFinal[j].data[2].p75.toFixed(2)
              )} | ${p75Label.pCount} of ${
                p75Label.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[2].p75.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[2].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },
              x: 'Discharge Date',
            })
            p75DataCount.push({
              y: filteredDataFinal[j].data[2].count,
              meta: `${p75Label.pLabel}: ${Number(
                filteredDataFinal[j].data[2].p75.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[2].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            p90Data.push({
              y: Number(filteredDataFinal[j].data[2].p90.toFixed(2)),
              meta: `${p90Label.pLabel}: ${Number(
                filteredDataFinal[j].data[2].p90.toFixed(2)
              )} | ${p90Label.pCount} of ${
                p90Label.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[2].p90.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[2].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },
              x: 'Discharge Date',
            })
            p90DataCount.push({
              y: filteredDataFinal[j].data[2].count,
              meta: `${p90Label.pLabel}: ${Number(
                filteredDataFinal[j].data[2].p90.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[2].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            avgData.push({
              y: Number(filteredDataFinal[j].data[2].avg.toFixed(2)),
              meta: `${avgLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[2].avg.toFixed(2)
              )} | ${avgLabel.pCount} of ${
                avgLabel.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[2].avg.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[2].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },
              x: 'Discharge Date',
            })
            avgDataCount.push({
              y: filteredDataFinal[j].data[2].count,
              meta: `${avgLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[2].avg.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[2].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
          } else {
            const minLabel = calculatePeopleAtPercentile(
              'min',
              filteredDataFinal[j].data[3].count
            )
            const p10Label = calculatePeopleAtPercentile(
              'p10',
              filteredDataFinal[j].data[3].count
            )
            const p25Label = calculatePeopleAtPercentile(
              'p25',
              filteredDataFinal[j].data[3].count
            )
            const medianLabel = calculatePeopleAtPercentile(
              'median',
              filteredDataFinal[j].data[3].count
            )
            const p75Label = calculatePeopleAtPercentile(
              'p75',
              filteredDataFinal[j].data[3].count
            )
            const p90Label = calculatePeopleAtPercentile(
              'p90',
              filteredDataFinal[j].data[3].count
            )
            const maxLabel = calculatePeopleAtPercentile(
              'max',
              filteredDataFinal[j].data[3].count
            )
            const avgLabel = calculatePeopleAtPercentile(
              'avg',
              filteredDataFinal[j].data[3].count
            )
            minData.push({
              y: Number(filteredDataFinal[j].data[3].min.toFixed(2)),
              meta: `${minLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[3].min.toFixed(2)
              )} | ${minLabel.pCount} of ${
                minLabel.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[3].min.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[3].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },
              x: 'Discharge Date',
            })
            minDataCount.push({
              y: filteredDataFinal[j].data[3].count,
              meta: `${minLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[3].min.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[3].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            medianData.push({
              y: Number(filteredDataFinal[j].data[3].median.toFixed(2)),
              meta: `${medianLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[3].median.toFixed(2)
              )} | ${medianLabel.pCount} of ${
                medianLabel.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[3].median.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[3].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },
              x: 'Discharge Date',
            })
            medianDataCount.push({
              y: filteredDataFinal[j].data[3].count,
              meta: `${medianLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[3].median.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[3].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            maxData.push({
              y: Number(filteredDataFinal[j].data[3].max.toFixed(2)),
              meta: `${maxLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[3].max.toFixed(2)
              )} | ${maxLabel.pCount} of ${
                maxLabel.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[3].max.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[3].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },
              x: 'Discharge Date',
            })
            maxDataCount.push({
              y: filteredDataFinal[j].data[3].count,
              meta: `${maxLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[3].max.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[3].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            p10Data.push({
              y: Number(filteredDataFinal[j].data[3].p10.toFixed(2)),
              meta: `${p10Label.pLabel}: ${Number(
                filteredDataFinal[j].data[3].p10.toFixed(2)
              )} | ${p10Label.pCount} of ${
                p10Label.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[3].p10.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[3].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },
              x: 'Discharge Date',
            })
            p10DataCount.push({
              y: filteredDataFinal[j].data[3].count,
              meta: `${p10Label.pLabel}: ${Number(
                filteredDataFinal[j].data[3].p10.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[3].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            p25Data.push({
              y: Number(filteredDataFinal[j].data[3].p25.toFixed(2)),
              meta: `${p25Label.pLabel}: ${Number(
                filteredDataFinal[j].data[3].p25.toFixed(2)
              )} | ${p25Label.pCount} of ${
                p25Label.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[3].p25.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[3].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },
              x: 'Discharge Date',
            })

            p25DataCount.push({
              y: filteredDataFinal[j].data[3].count,
              meta: `${p25Label.pLabel}: ${Number(
                filteredDataFinal[j].data[3].p25.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[3].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            p75Data.push({
              y: Number(filteredDataFinal[j].data[3].p75.toFixed(2)),
              meta: `${p75Label.pLabel}: ${Number(
                filteredDataFinal[j].data[3].p75.toFixed(2)
              )} | ${p75Label.pCount} of ${
                p75Label.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[3].p75.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[3].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },
              x: 'Discharge Date',
            })
            p75DataCount.push({
              y: filteredDataFinal[j].data[3].count,
              meta: `${p75Label.pLabel}: ${Number(
                filteredDataFinal[j].data[3].p75.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[3].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            p90Data.push({
              y: Number(filteredDataFinal[j].data[3].p90.toFixed(2)),
              meta: `${p90Label.pLabel}: ${Number(
                filteredDataFinal[j].data[3].p90.toFixed(2)
              )} | ${p90Label.pCount} of ${
                p90Label.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[3].p90.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[3].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },
              x: 'Discharge Date',
            })
            p90DataCount.push({
              y: filteredDataFinal[j].data[3].count,
              meta: `${p90Label.pLabel}: ${Number(
                filteredDataFinal[j].data[3].p90.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[3].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
            avgData.push({
              y: Number(filteredDataFinal[j].data[3].avg.toFixed(2)),
              meta: `${avgLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[3].avg.toFixed(2)
              )} | ${avgLabel.pCount} of ${
                avgLabel.totalCount
              } with value <= ${Number(
                filteredDataFinal[j].data[3].avg.toFixed(2)
              )}`,
              goals: {
                count: filteredDataFinal[j].data[3].count,
                label:
                  analyticData[0].name === 'Diastolic BP'
                    ? 'Diastolic'
                    : 'Systolic',
              },
              x: 'Discharge Date',
            })
            avgDataCount.push({
              y: filteredDataFinal[j].data[3].count,
              meta: `${avgLabel.pLabel}: ${Number(
                filteredDataFinal[j].data[3].avg.toFixed(2)
              )} `,
              goals: {
                count: filteredDataFinal[j].data[3].count,
                label: 'Patients',
              },
              x: 'Discharge Date',
            })
          }
        }
        if (percentile === 'min') {
          const minArray: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: minData,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMedian(strataFilterDataUpdated[m].dummyId),
            type: 'line',
          }

          const minArrayCount: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: minDataCount,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMedian(strataFilterDataUpdated[m].dummyId),
            type: 'bar',
          }

          postProcessedData.push(minArray)
          postProcessedData.push(minArrayCount)
        }
        if (percentile === 'p10') {
          const p10Obj: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: p10Data,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMedian(strataFilterDataUpdated[m].dummyId),
            type: 'line',
          }
          const p10ObjCount: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: p10DataCount,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMedian(strataFilterDataUpdated[m].dummyId),
            type: 'bar',
          }
          postProcessedData.push(p10Obj)
          postProcessedData.push(p10ObjCount)
        }
        if (percentile === 'p25') {
          const p25Obj: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: p25Data,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMedian(strataFilterDataUpdated[m].dummyId),
            type: 'line',
          }
          const p25ObjCount: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: p25DataCount,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMedian(strataFilterDataUpdated[m].dummyId),
            type: 'bar',
          }
          postProcessedData.push(p25Obj)
          postProcessedData.push(p25ObjCount)
        }
        if (percentile === 'median') {
          const medianObj: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: medianData,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMedian(strataFilterDataUpdated[m].dummyId),
            type: 'line',
          }
          const medianObjCount: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: medianDataCount,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMedian(strataFilterDataUpdated[m].dummyId),
            type: 'bar',
          }
          postProcessedData.push(medianObj)
          postProcessedData.push(medianObjCount)
        }
        if (percentile === 'p75') {
          const p75Obj: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: p75Data,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMedian(strataFilterDataUpdated[m].dummyId),
            type: 'line',
          }
          const p75ObjCount: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: p75DataCount,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMedian(strataFilterDataUpdated[m].dummyId),
            type: 'bar',
          }
          postProcessedData.push(p75ObjCount)
          postProcessedData.push(p75Obj)
        }
        if (percentile === 'p90') {
          const p90Obj: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: p90Data,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMedian(strataFilterDataUpdated[m].dummyId),
            type: 'line',
          }
          const p90ObjCount: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: p90DataCount,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMedian(strataFilterDataUpdated[m].dummyId),
            type: 'bar',
          }
          postProcessedData.push(p90ObjCount)
          postProcessedData.push(p90Obj)
        }
        if (percentile === 'max') {
          const maxObj: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: maxData,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMedian(strataFilterDataUpdated[m].dummyId),
            type: 'line',
          }
          const maxObjCount: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: maxDataCount,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMedian(strataFilterDataUpdated[m].dummyId),
            type: 'bar',
          }
          postProcessedData.push(maxObjCount)
          postProcessedData.push(maxObj)
        }
        if (percentile === 'avg') {
          const avjObj: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: avgData,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMedian(strataFilterDataUpdated[m].dummyId),
            type: 'line',
          }
          const avjObjCount: SummaryDataWithCount = {
            name:
              filteredDataFinal[j].strataName.length > 0
                ? `${filteredDataFinal[j].strataName}`
                : 'No Strata (Base Cohort)',
            data: avgDataCount,
            group: `${filteredDataFinal[j].strataName}`,
            color: getColorCodeForMedian(strataFilterDataUpdated[m].dummyId),
            type: 'bar',
          }
          postProcessedData.push(avjObjCount)
          postProcessedData.push(avjObj)
        }
      }
    }

    outComeData.push({
      analysisName: analyticData[0].name,
      summaryWithCount: postProcessedData,
    })
  }

  return outComeData
}

export function getFilterLabel(code: string) {
  switch (code.trim()) {
    case 'target':
      return 'Admission'
    case 'pre_obs':
      return 'Pre Treatment'
    case 'intervention':
      return 'Intervention'
    case 'post_obs':
      return 'Post Treatment'
    default:
      return ''
  }
}

export async function buildCohortMap(
  props: FilterByStageProps
): Promise<object> {
  const cohortStageMap: any = {}
  const cohortCodeMap: any = {}
  const stageKeys = Object.keys(props.stages)
  const l: number = stageKeys.length
  let i: number = 0
  let ii: number = 0
  let iii: number = 0

  for (i; i < l; i++) {
    const stage: CohortDataStage[] = props.stages[
      stageKeys[i]
    ] as CohortDataStage[]
    const cohorts = new Set<string>()
    if (stage !== undefined) {
      for (ii = 0; ii < stage.length; ii++) {
        for (iii = 0; iii < stage[ii].cohorts.length; iii++) {
          const c = stage[ii].cohorts[iii]
          cohorts.add(c)
          cohortCodeMap[stage[ii].cohorts[iii]] = stage[ii].code
        }
      }
    }

    props.visibleStages.map((vs: string) => {
      const stage2 = props.stages[vs]
      if (stage2) {
        const cc: ListItem[] = []
        let k = 0
        for (const [key, value] of cohorts.entries()) {
          // cc.push(value);
          const item = {
            label: value,
            value, // `${stageKeys[i]}_${k}`,
            index: k,
          } as ListItem
          cc.push(item)
          k++
        }
        cohortStageMap[stageKeys[i]] = cc
      }
    })
  }
  // console.debug("cohortStageMap", cohortStageMap, cohortCodeMap);

  return { cohortStageMap, cohortCodeMap }
}

export async function filterByStageMap(
  visibleStages: string[],
  stages: any,
  selected: any
): Promise<object> {
  const filterByStageMapData: any = {}
  let item: ListItem | undefined
  let stage: CohortDataStage[] = []
  let i: number = 0
  let items: ListItem[] = []

  visibleStages.map((vs: string) => {
    stage = stages[vs]
    items = []
    if (stage) {
      stage.map((stageData: CohortDataStage) => {
        item = {
          label: stageData.cohorts.toString(),
          value: stageData.code,
          index: i,
        } as ListItem
        items.push(item)
        i++
      })
    }
    filterByStageMapData[vs] = items
  })

  console.debug('filterByStageMap', filterByStageMap)
  return filterByStageMapData
}
