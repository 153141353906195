import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { PatientCountStatus } from './patientCountStatusTypes'

const initialState: PatientCountStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
  count: 0,
}

const patientCountSlice = createSlice({
  name: 'patientCount',
  initialState,
  reducers: {
    searchingPatientDetails(
      state,
      action: PayloadAction<PatientCountStatus>
    ) {},

    searchResults(state, action: PayloadAction<PatientCountStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.count = action.payload.count
    },

    noDataFoundForSearch(state, action: PayloadAction<PatientCountStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.count = 0
    },

    errorWhileSearching(state, action: PayloadAction<PatientCountStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.count = 0
    },
  },
})

export const updatePatientCount =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const errorSearchPatient: PatientCountStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
      count: 0,
    }
    dispatch(patientCountSlice.actions.errorWhileSearching(errorSearchPatient))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _count: 0,
        active: true,
        _total: 'accurate',
      }

      const response: any = await fhirClient.doGetResource(
        '/Patient',
        searchParameters
      )

      if (response) {
        logger.info('relatedFhirDecodeRes')
        logger.info(response)
        const patientResponses: R4.IBundle = response as R4.IBundle
        if (patientResponses.total) {
          if (patientResponses.total > 0) {
            const searchPatientResult: PatientCountStatus = {
              error: false,
              noResultsAvailable: false,
              resultsAvailable: true,
              searching: false,
              count: patientResponses.total,
            }
            dispatch(
              patientCountSlice.actions.searchResults(searchPatientResult)
            )
            return
          }
        }
        const noSearchResults: PatientCountStatus = {
          error: false,
          noResultsAvailable: true,
          resultsAvailable: false,
          searching: false,
          count: 0,
        }
        dispatch(
          patientCountSlice.actions.noDataFoundForSearch(noSearchResults)
        )
        return
      }

      const errorWhileSearchPatient: PatientCountStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error while searching',
        count: 0,
      }
      dispatch(
        patientCountSlice.actions.errorWhileSearching(errorWhileSearchPatient)
      )
      return
    } catch (error) {
      logger.info('error')
      logger.error(error)
      const errorInSearchPatient: PatientCountStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
        count: 0,
      }
      dispatch(
        patientCountSlice.actions.errorWhileSearching(errorInSearchPatient)
      )
    }
  }

export default patientCountSlice.reducer
