/* eslint-disable no-irregular-whitespace */
import { Box, Typography, withStyles } from '@material-ui/core'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-vis/dist/style.css'
import { CardioVascularExaminationsAccordion } from './cardioVascularSystemExaminationsAccordian'
import { CNSExaminationsAccordion } from './cnsExaminationsAccordion'
import { GeneralInspectionExaminationsAccordion } from './giSystemExaminationsAccordion'
import { RespiratorySystemExaminationsAccordion } from './respiratorySystemExaminationsAccordion'

interface PhysicalExaminationOfPTListProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  isReadOnly?: boolean
}

export const Accordion = withStyles({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
      paddingBottom: '8px',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '0px',
    },
  },
  expanded: {},
})(MuiAccordion)

export const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'lightGrey',
    borderRadius: 2,
    marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      minHeight: 40,
    },
    margin: '0px',
    padding: '0px',
    paddingRight: '16px',
  },
  content: {
    margin: '0px',
    padding: '0px',
    '&$expanded': {
      margin: '0px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiAccordionDetails)

export const SystemicExaminationList: React.FC<PhysicalExaminationOfPTListProps> =
  ({
    fhirAppointmentDetails,
    isReadOnly = false,
  }: PhysicalExaminationOfPTListProps) => {
    const { t } = useTranslation(['common'])
    const [addButton, showAddButton] = useState<boolean>(false)
    const [selectedSystem, setSelectedSystem] = useState<string>()
    if (
      !fhirAppointmentDetails.encounter ||
      !fhirAppointmentDetails.encounter.id
    ) {
      return (
        <Box>
          <Typography variant='body1' color='initial'>
            Please add Chief Complaints to proceed further
          </Typography>
        </Box>
      )
    }

    return (
      <Box display='flex' flexDirection='column' px={0.5} width='100%'>
        <CardioVascularExaminationsAccordion
          fhirAppointmentDetails={fhirAppointmentDetails}
          isReadOnly={isReadOnly}
          isExpanded={selectedSystem === 'cvs'}
          onSelected={(categoryLabel) => {
            if (selectedSystem === 'cvs') {
              setSelectedSystem(undefined)
            } else {
              setSelectedSystem(categoryLabel)
            }
          }}
        />
        <RespiratorySystemExaminationsAccordion
          fhirAppointmentDetails={fhirAppointmentDetails}
          isReadOnly={isReadOnly}
          isExpanded={selectedSystem === 'rs'}
          onSelected={(categoryLabel) => {
            if (selectedSystem === 'rs') {
              setSelectedSystem(undefined)
            } else {
              setSelectedSystem(categoryLabel)
            }
          }}
        />
        <GeneralInspectionExaminationsAccordion
          fhirAppointmentDetails={fhirAppointmentDetails}
          isReadOnly={isReadOnly}
          isExpanded={selectedSystem === 'gis'}
          onSelected={(categoryLabel) => {
            if (selectedSystem === 'gis') {
              setSelectedSystem(undefined)
            } else {
              setSelectedSystem(categoryLabel)
            }
          }}
        />
        <CNSExaminationsAccordion
          fhirAppointmentDetails={fhirAppointmentDetails}
          isReadOnly={isReadOnly}
          isExpanded={selectedSystem === 'cns'}
          onSelected={(categoryLabel) => {
            if (selectedSystem === 'cns') {
              setSelectedSystem(undefined)
            } else {
              setSelectedSystem(categoryLabel)
            }
          }}
        />
      </Box>
    )
  }
