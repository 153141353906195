import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import { getObServationDisplayData } from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { logger } from 'utils/logger'
import { PlanDefSearchStatus } from './planDefSearchStatus'

const initialState: PlanDefSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const plandefSearchSlice = createSlice({
  name: 'plandefSearchSlice',
  initialState,
  reducers: {
    searchingDoctorDetails(state, action: PayloadAction<PlanDefSearchStatus>) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false
    },

    searchResults(state, action: PayloadAction<PlanDefSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true
    },

    noDataFoundForSearch(state, action: PayloadAction<PlanDefSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
    },

    errorWhileSearching(state, action: PayloadAction<PlanDefSearchStatus>) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
    },
    resetState(state, action: PayloadAction<PlanDefSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
    },
  },
})

export const searchTestNames =
  (loinCode: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PlanDefSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(plandefSearchSlice.actions.searchingDoctorDetails(state))
    try {
      const unitDetails = getCurrentUserUnitReference()!
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        status: 'active',
        _total: 'accurate',
      }

      const response: any = await fhirClient.doGetResource(
        `/PlanDefinition?identifier=${loinCode}&plan-def-organization=Organization/${unitDetails.id}`,
        searchParameters
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const labOfferingsResponse: R4.IBundle = relatedFhirDecodeRes.right
        if (labOfferingsResponse.total) {
          if (labOfferingsResponse.total > 0) {
            state.searching = false
            state.error = false
            dispatch(plandefSearchSlice.actions.searchResults(state))
            return
          }
          state.resultsAvailable = false
          state.searching = false
          state.error = false
          state.noResultsAvailable = true
          dispatch(plandefSearchSlice.actions.noDataFoundForSearch(state))
          return
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(plandefSearchSlice.actions.noDataFoundForSearch(state))
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(plandefSearchSlice.actions.errorWhileSearching(state))
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(plandefSearchSlice.actions.errorWhileSearching(state))
    }
  }

export const resetPlanDefState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: PlanDefSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
    }
    dispatch(plandefSearchSlice.actions.resetState(state))
  }

export function getPractitionerRoleObject(item: R4.IPractitionerRole) {
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    name: item.practitioner?.display ?? '',
    gender: '',
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: false,
  }
  return val
}

export default plandefSearchSlice.reducer
