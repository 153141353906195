import { Box, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { getInstructionsOfIPD } from 'redux/ipd/instructions_list_slice_ipd/instructionsListSliceIPD'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { AddCustomInstructions } from 'views/components/consultation/plan/addCustomInstruction'
import { RecommendationWrapperIPD } from './cdsRecomendations/cds_reccomendation_wrapperIPD'
import { CarePlanInstructionListIPD } from './instructionsListIPD'
import { NextStepPlan } from './nextStepPlan'

interface NextStepsOfConsultationProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
}

export const NextStepsOfConsultationIPD: React.FC<NextStepsOfConsultationProps> =
  ({ fhirAppointmentDetails }: NextStepsOfConsultationProps) => {
    const { t } = useTranslation(['common', 'mediction'])
    const [addButton, showAddButton] = useState<boolean>(false)
    const [show, setShow] = useState<boolean>(false)
    const [showCohorts, setShowCohorts] = useState<boolean>(false)

    const [openAddMedicationDialogue, setOpenAddMedicationDialogue] =
      useState<boolean>(false)
    const [openAddDiagnosticDetails, setOpenAddDiagnosticDetails] =
      useState<boolean>(false)
    const dispatch = useDispatch()
    const [openCreateAppointmentPopup, setOpenCreateAppointmentPopup] =
      useState(false)
    const [openAddInstruction, setAddInstruction] = useState<boolean>(false)

    return (
      <ReactVisibilitySensor
        offset={{
          top: 100,
          bottom: 30,
        }}
        onChange={(isVisible) => {
          if (isVisible) {
            dispatch(setSelectedSection({ selectedSection: 'next_steps' }))
          }
        }}
      >
        <Box display='flex' flexDirection='row' width='100%'>
          <Box display='flex' flexDirection='column' width='50%'>
            <Box display='flex' flexDirection='row' padding={0.5} flexGrow>
              <Box py={1}>
                <Typography variant='h6'>
                  {' '}
                  {t('labelCommon:Next Steps')}{' '}
                </Typography>
              </Box>
            </Box>

            {/* <Box
              display='flex'
              flexDirection='row'
              padding={0.5}
              flexGrow
              width='100%'
            >
              <NextStepPlan
                planName={t('labelMedication:resourceName')}
                subString=''
                onClicked={() => {
                  setOpenAddMedicationDialogue(true)
                }}
                bottomWidget={
                  <MedicationsList
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                }
              />
            </Box> */}
            {/* <Box display='flex' flexDirection='row' padding={0.5}>
              <NextStepPlan
                planName={t('labelCommon:lab_test')}
                subString=''
                bottomWidget={
                  <LabDiagnosticRequestsList
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                }
                onClicked={() => {
                  setOpenAddDiagnosticDetails(true)
                }}
              />
            </Box> */}
            {/* <Box display='flex' flexDirection='row' padding={0.5}>
              <NextStepPlan
                planName={t('labelCommon:followup_appointment')}
                subString=''
                bottomWidget={
                  <PatientFolloWUpAppointments
                    fhirPatientDetail={fhirAppointmentDetails}
                  />
                }
                onClicked={() => {
                  setOpenCreateAppointmentPopup(true)
                }}
              />
            </Box> */}
            {/* <Box display='flex' flexDirection='row' padding={0.5}>
              <NextStepPlan
                planName='Referral Appointment'
                subString=''
                bottomWidget={
                  <ReferralRequestList
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                }
              />
            </Box> */}

            <Box display='flex' flexDirection='row' padding={0.5}>
              <NextStepPlan
                planName='Instructions'
                subString=''
                bottomWidget={
                  <CarePlanInstructionListIPD
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                }
                onClicked={() => {
                  if (
                    fhirAppointmentDetails.mainEncounter !== undefined &&
                    fhirAppointmentDetails.mainEncounter.id !== undefined
                  ) {
                    setAddInstruction(true)
                  } else {
                    dispatch(
                      showErrorAlert(
                        'No details have been added as part of Appointment. Please add few details about patient visit'
                      )
                    )
                  }
                }}
              />
            </Box>
            {/* <AddDiagnosticsTests
              open={openAddDiagnosticDetails}
              fhirAppointmentDetails={fhirAppointmentDetails}
              onClose={() => {
                dispatch(getLabRequestsOfAppointment(fhirAppointmentDetails))
                setOpenAddDiagnosticDetails(false)
              }}
              onLabDiagnosticsAdded={() => {
                dispatch(getLabRequestsOfAppointment(fhirAppointmentDetails))
                setOpenAddDiagnosticDetails(false)
              }}
            />
            <AddMedicationsHandler
              open={openAddMedicationDialogue}
              fhirAppointmentDetails={fhirAppointmentDetails}
              onClose={() => {
                dispatch(getMedicationsOfAppointment(fhirAppointmentDetails))
                setOpenAddMedicationDialogue(false)
              }}
              onMedicalConditionAdded={() => {
                dispatch(getMedicationsOfAppointment(fhirAppointmentDetails))
                setOpenAddMedicationDialogue(false)
              }}
            /> */}
            {/* <CreateAppointmentHandler
              allowWalkIn={false}
              open={openCreateAppointmentPopup}
              preSelectedDoctor={{
                id:
                  fhirAppointmentDetails.practitionerDetail.practitioner.id ??
                  '',
                roleObject:
                  fhirAppointmentDetails.practitionerDetail.practitionerRole,
                practRoleId:
                  fhirAppointmentDetails.practitionerDetail.practitionerRole.id,
                practitionerObject:
                  fhirAppointmentDetails.practitionerDetail.practitioner,
                name: getNameFromHumanName(
                  fhirAppointmentDetails.practitionerDetail.practitioner.name ??
                    []
                ),
                phone: '',
                fullName: '',
                color: '',
                primaryContact: '',
                status: '',
                enabled: fhirAppointmentDetails.practitionerDetail.practitioner
                  .active
                  ? fhirAppointmentDetails.practitionerDetail.practitioner
                      .active
                  : false,
              }}
              fhirAppointmentDetail={fhirAppointmentDetails}
              onClose={() => {
                setOpenCreateAppointmentPopup(false)
                dispatch(fetchFollowUpAppointments(fhirAppointmentDetails))
              }}
              onAppointmentCreated={(createdAppointment) => {
                setOpenCreateAppointmentPopup(false)
                dispatch(fetchFollowUpAppointments(fhirAppointmentDetails))
              }}
            /> */}
          </Box>
          <Box display='flex' flexDirection='column' width='50%' p={2}>
            <RecommendationWrapperIPD
              fhirAppointmentDetails={fhirAppointmentDetails}
            />
          </Box>
          {openAddInstruction && (
            <AddCustomInstructions
              open={openAddInstruction}
              patientReference={{
                reference: `Patient/${fhirAppointmentDetails.patient.id}`,
              }}
              encounterReference={{
                reference: `Encounter/${
                  fhirAppointmentDetails.mainEncounter!.id
                }`,
              }}
              onClose={() => {
                dispatch(getInstructionsOfIPD(fhirAppointmentDetails))
                setAddInstruction(false)
              }}
              onInstructionAdded={() => {
                dispatch(getInstructionsOfIPD(fhirAppointmentDetails))
                setAddInstruction(false)
              }}
            />
          )}
        </Box>
      </ReactVisibilitySensor>
    )
  }
