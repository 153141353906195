/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Doctor } from '../models/Doctor';
import type { DoctorUpdateBody } from '../models/DoctorUpdateBody';
import type { ErrorResponse } from '../models/ErrorResponse';
import type { SearchDocResponse } from '../models/SearchDocResponse';
import { request as __request } from '../core/request';

export class DoctorProfileService {

    /**
     * search doctor Profile
     * @returns SearchDocResponse OK
     * @returns ErrorResponse default error response
     * @throws ApiError
     */
    public static async searchDoctorProfile({
tid,
specialization,
specializationQ,
medicalQualification,
fromConsultationPrice,
toConsultationPrice,
language,
gender,
name,
page = 1,
limit = 5,
latitude,
longitude,
maxDistance = 50000,
}: {
/** Tenant Id **/
tid: string,
/** Doctor Specialization **/
specialization?: Array<string>,
/** Doctor Specialization text search **/
specializationQ?: string,
/** Medical Qualification **/
medicalQualification?: string,
/** Range of consultationPrice from **/
fromConsultationPrice?: number,
/** Range of consultationPrice to **/
toConsultationPrice?: number,
/** Language **/
language?: 'en' | 'gu-IN' | 'hi-IN' | 'kn-IN' | 'kok-IN' | 'mr-IN' | 'pa-IN' | 'sa-IN' | 'ta-IN' | 'te-IN',
/** Gender **/
gender?: 'male' | 'female' | 'other' | 'unknown',
/** Name **/
name?: string,
/** Page number **/
page?: number,
/** limit documents **/
limit?: number,
/** latitude of current location **/
latitude?: number,
/** longitude of current location **/
longitude?: number,
/** Maximum distance from current location in mts **/
maxDistance?: number,
}): Promise<SearchDocResponse | ErrorResponse> {
        const result = await __request({
            method: 'GET',
            path: `/clinical/tenant/${tid}/doctorProfile`,
            query: {
                'specialization': specialization,
                'specializationQ': specializationQ,
                'medicalQualification': medicalQualification,
                'fromConsultationPrice': fromConsultationPrice,
                'toConsultationPrice': toConsultationPrice,
                'language': language,
                'gender': gender,
                'name': name,
                'page': page,
                'limit': limit,
                'latitude': latitude,
                'longitude': longitude,
                'maxDistance': maxDistance,
            },
        });
        return result.body;
    }

    /**
     * add doctor
     * @returns Doctor OK
     * @returns ErrorResponse default error response
     * @throws ApiError
     */
    public static async createDoctorProfile({
tid,
requestBody,
}: {
/** Tenant Id **/
tid: string,
requestBody: Doctor,
}): Promise<Doctor | ErrorResponse> {
        const result = await __request({
            method: 'POST',
            path: `/clinical/tenant/${tid}/doctorProfile`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any OK
     * @returns ErrorResponse default error response
     * @throws ApiError
     */
    public static async deleteDoctorProfile({
tid,
id = 'abc123',
}: {
/** Tenant Id **/
tid: string,
/** Id of entity **/
id?: string,
}): Promise<any | ErrorResponse> {
        const result = await __request({
            method: 'DELETE',
            path: `/clinical/tenant/${tid}/doctorProfile/${id}`,
        });
        return result.body;
    }

    /**
     * @returns Doctor OK
     * @returns ErrorResponse default error response
     * @throws ApiError
     */
    public static async getDoctorProfileById({
tid,
id = 'abc123',
}: {
/** Tenant Id **/
tid: string,
/** Id of entity **/
id?: string,
}): Promise<Doctor | ErrorResponse> {
        const result = await __request({
            method: 'GET',
            path: `/clinical/tenant/${tid}/doctorProfile/${id}`,
        });
        return result.body;
    }

    /**
     * @returns Doctor OK
     * @returns ErrorResponse default error response
     * @throws ApiError
     */
    public static async updateDoctorProfile({
tid,
requestBody,
id = 'abc123',
}: {
/** Tenant Id **/
tid: string,
requestBody: DoctorUpdateBody,
/** Id of entity **/
id?: string,
}): Promise<Doctor | ErrorResponse> {
        const result = await __request({
            method: 'PUT',
            path: `/clinical/tenant/${tid}/doctorProfile/${id}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * search doctor
     * @returns SearchDocResponse OK
     * @returns ErrorResponse default error response
     * @throws ApiError
     */
    public static async aggregateSearchDoctorProfile({
specialization,
specializationQ,
tid,
medicalQualification,
fromConsultationPrice,
toConsultationPrice,
language,
gender,
name,
page = 1,
limit = 5,
latitude,
longitude,
maxDistance = 50000,
}: {
/** Doctor Specialization **/
specialization?: Array<string>,
/** Doctor Specialization text search **/
specializationQ?: string,
/** Tenant Id **/
tid?: string,
/** Medical Qualification **/
medicalQualification?: string,
/** Range of consultationPrice from **/
fromConsultationPrice?: number,
/** Range of consultationPrice to **/
toConsultationPrice?: number,
/** Language **/
language?: 'en' | 'gu-IN' | 'hi-IN' | 'kn-IN' | 'kok-IN' | 'mr-IN' | 'pa-IN' | 'sa-IN' | 'ta-IN' | 'te-IN',
/** Gender **/
gender?: 'male' | 'female' | 'other' | 'unknown',
/** Name **/
name?: string,
/** Page number **/
page?: number,
/** limit documents **/
limit?: number,
/** latitude of current location **/
latitude?: number,
/** longitude of current location **/
longitude?: number,
/** Maximum distance from current location in mts **/
maxDistance?: number,
}): Promise<SearchDocResponse | ErrorResponse> {
        const result = await __request({
            method: 'GET',
            path: `/clinical/doctorProfile/op/searchDoctorProfile`,
            query: {
                'specialization': specialization,
                'specializationQ': specializationQ,
                'tid': tid,
                'medicalQualification': medicalQualification,
                'fromConsultationPrice': fromConsultationPrice,
                'toConsultationPrice': toConsultationPrice,
                'language': language,
                'gender': gender,
                'name': name,
                'page': page,
                'limit': limit,
                'latitude': latitude,
                'longitude': longitude,
                'maxDistance': maxDistance,
            },
        });
        return result.body;
    }

    /**
     * @returns Doctor OK
     * @returns ErrorResponse default error response
     * @throws ApiError
     */
    public static async getDoctorByIdBizToC({
id = 'abc123',
}: {
/** Id of entity **/
id?: string,
}): Promise<Doctor | ErrorResponse> {
        const result = await __request({
            method: 'GET',
            path: `/clinical/doctorProfile/${id}`,
        });
        return result.body;
    }

}