import { R4 } from '@ahryman40k/ts-fhir-types'

export const ClinicUserRole: R4.ICoding[] = [
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
    code: 'doctor',
    display: 'Doctor',
  },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
    code: 'doctor-partner',
    display: 'Consulting Doctor',
  },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
    code: 'nurse',
    display: 'Nurse',
  },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
    code: 'ayurveda',
    display: 'Ayurveda (Vaidya)',
  },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
    code: 'receptionist',
    display: 'GRM',
  },
  //   {
  //     system: 'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
  //     code: 'radiologist',
  //     display: 'Radiologist',
  //   },
  //   {
  //     system: 'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
  //     code: 'lab-agent',
  //     display: 'Agent',
  //   },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
    code: 'unit-admin',
    display: 'Unit Admin',
  },
  {
    system: 'http://snomed.info/sct',
    code: '106296000',
    display: 'Therapist',
  },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
    code: 'dietician',
    display: 'Dietician',
  },
  {
    system: 'http://snomed.info/sct',
    code: '36682004',
    display: 'Physiotherapist',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const OrgUserRole: R4.ICoding[] = [
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
    code: 'org-admin',
    display: 'Org Admin',
  },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
    code: 'receptionist',
    display: 'Front Desk',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const LabUserRole: R4.ICoding[] = [
  //   {
  //     system: 'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
  //     code: 'radiologist',
  //     display: 'Radiologist',
  //   },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
    code: 'lab-agent',
    display: 'Agent',
  },

  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
    code: 'unit-admin',
    display: 'Unit Admin',
  },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
    code: 'receptionist',
    display: 'Front Desk',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const CollectionCenterRole: R4.ICoding[] = [
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
    code: 'partner-lab',
    display: 'Partner Lab',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
