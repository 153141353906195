import { R4 } from '@ahryman40k/ts-fhir-types'
import _ from 'lodash'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import {
  MembershipBeneficiary,
  MembershipDetails,
} from 'models/membershipDetails'
import { SplitPaymentDetails } from 'models/splitPayment/splitPaymentDetails'
import { TransactionOfMembership } from 'models/transactionOfMembership'
import { SecondaryMember } from 'redux/administration/membership/addMembershipDetails/addMembershipDetailsTypes'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getExtensionValueOfUrl,
  getFullNameOfPatient,
  getIdentifierBySystemType,
  getIdentifierValueBySystem,
  getIdentifierValueBySystemType,
  getNameFromHumanName,
  getTelecomOfPatient,
  getValueRefValueExtensionsOfUrl,
} from './fhirResourcesHelper'

export function getMembershipsWithChargeItem(
  responseBundle: R4.IBundle
): MembershipDetails[] {
  const convertedAppointments: MembershipDetails[] = []
  const coverage: any = {}
  const chargeDdef: any = {}
  const patients: any = {}
  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Coverage':
                  coverage[value.resource.id] = value.resource as R4.ICoverage
                  break
                case 'ChargeItemDefinition':
                  chargeDdef[value.resource.id] =
                    value.resource as R4.IChargeItemDefinition
                  break
                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break
                default:
                  break
              }
            }
          }
        })
        for (const key in coverage) {
          if (key) {
            let chargeItemDef: R4.IChargeItemDefinition | undefined
            let currentCredits = 0
            let startDate = ''
            const currentAppointment: R4.ICoverage = coverage[
              key
            ] as R4.ICoverage

            const patientId =
              currentAppointment.policyHolder?.reference?.split('/')[1]
            const patientRes: R4.IPatient = patients[patientId ?? '']

            if (
              getValueRefValueExtensionsOfUrl(
                currentAppointment.extension,
                'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
              )
            ) {
              const chargeID: string | undefined =
                getValueRefValueExtensionsOfUrl(
                  currentAppointment.extension,
                  'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
                )?.split('/')[1]
              chargeItemDef = chargeDdef[chargeID ?? '']
            }

            if (currentAppointment.costToBeneficiary) {
              const costToBeneficiary =
                currentAppointment.costToBeneficiary.find((e) =>
                  e.type?.coding?.find((c) => c.code === 'deductible')
                )
              if (costToBeneficiary) {
                currentCredits = costToBeneficiary.valueMoney?.value ?? 0
              }
            }
            if (currentAppointment.period) {
              startDate = currentAppointment.period.start ?? ''
            }

            convertedAppointments.push({
              coverageRaw: currentAppointment,
              mainMember: patientRes,
              chargeItemDefinition: chargeItemDef,
              memberName: getFullNameOfPatient(patientRes),
              memberShipPlanId: getMemberPlanId(currentAppointment),
              memberPhoneNumber: getTelecomOfPatient(
                patientRes,
                R4.ContactPointSystemKind._phone
              ),
              planName: getPlanNameFromCoverage(currentAppointment),
              status: currentAppointment.status ?? '',
              startDate,
              reference: _.capitalize(
                getMembershipReferenceId(currentAppointment)
              ),
              id: currentAppointment.id ?? '',
              beneficiaries: [
                {
                  coverage: currentAppointment,
                  beneficiary: patientRes,
                  type: 'primary',
                },
              ],
              remainingCredits: currentCredits,
              totalCredits: getMemberShipPlanCredit(
                currentAppointment,
                chargeItemDef
              ),
            })
          }
        }
      }
    }
  }
  return convertedAppointments
}

export function getMembershipBeneficiaries(
  responseBundle: R4.IBundle
): MembershipBeneficiary[] {
  const convertedAppointments: MembershipBeneficiary[] = []
  const coverage: any = {}

  const patients: any = {}
  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Coverage':
                  coverage[value.resource.id] = value.resource as R4.ICoverage
                  break
                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break
                default:
                  break
              }
            }
          }
        })
        for (const key in coverage) {
          if (key) {
            const currentAppointment: R4.ICoverage = coverage[
              key
            ] as R4.ICoverage

            const patientId =
              currentAppointment.beneficiary?.reference?.split('/')[1]
            const patientRes: R4.IPatient = patients[patientId ?? '']

            const isPrimary = currentAppointment.relationship?.coding?.find(
              (c) => c.code === 'self'
            )

            convertedAppointments.push({
              coverage: currentAppointment,
              beneficiary: patientRes,
              type: isPrimary ? 'primary' : 'dependent',
            })
          }
        }
      }
    }
  }
  return convertedAppointments
}

export function getPlanNameFromCoverage(coverage: R4.ICoverage): string {
  let planName = ''
  if (coverage.class?.length === 0) return planName

  const classDetails = coverage.class?.find((e) =>
    e.type?.coding?.find((c) => c.code === 'plan')
  )
  if (classDetails) {
    planName = classDetails.name ?? ''
  } else {
    planName = coverage.class?.[0].name ?? ''
  }
  return planName
}

export function getMemberPlanId(coverage: R4.ICoverage): string {
  let planId

  const planIdentifier = getIdentifierBySystemType(
    coverage.identifier ?? [],
    'http://wellopathy.com/fhir/india/core/Identifier/membership-id'
  )

  if (planIdentifier) {
    planId = planIdentifier.value
  }

  if (planId === undefined) {
    const classDetails = coverage.class?.find((e) => {
      if (e.type && e.type.coding) {
        const coding = e.type.coding.findIndex((c) => c.code === 'plan')
      }
      return e.type?.coding?.find((c) => c.code === 'plan')
    })
    if (classDetails) {
      planId = classDetails.value ?? ''
    } else {
      planId = coverage.class?.[0].value ?? ''
    }
  }

  return planId ?? ''
}

export function getPrimaryMemberPlanId(coverage: R4.ICoverage): string {
  return coverage.policyHolder?.reference?.split('/')[1] ?? ''
}

export function getMemberShipPlanCredit(
  coverage: R4.ICoverage,
  membership?: R4.IChargeItemDefinition
) {
  let amount: number | undefined

  if (coverage.costToBeneficiary && coverage.costToBeneficiary.length > 0) {
    const costToBeneficiary = coverage.costToBeneficiary.find((e) =>
      e.type?.coding?.find((c) => c.code === '900000000000499002')
    )
    if (costToBeneficiary) {
      amount = costToBeneficiary.valueMoney?.value
    }
  }

  if (!amount && membership) {
    if (membership.propertyGroup) {
      const baseProperty = membership.propertyGroup.find((property) => {
        if (property.priceComponent && property.priceComponent.length > 0) {
          property.priceComponent.find((price) => {
            if (price.type && price.type.length > 0) {
              if (price.type === 'informational') {
                amount = price.amount?.value
                return true
              }
            }
            return false
          })
        }
        return false
      })
    }
  }

  return amount ?? 0
}

export function getMembershipReferenceId(
  coverage: R4.ICoverage
): string | undefined {
  const ext = getExtensionValueOfUrl(
    coverage.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-referring-entity-ext'
  )

  if (ext) {
    return ext.valueString
  }
  return undefined
}

export function getMemberShipPaidAmount(
  coverage: R4.ICoverage,
  membership?: R4.IChargeItemDefinition
) {
  let amount: number | undefined

  if (coverage.costToBeneficiary && coverage.costToBeneficiary.length > 0) {
    const costToBeneficiary = coverage.costToBeneficiary.find((e) =>
      e.type?.coding?.find((c) => c.code === 'base')
    )
    if (costToBeneficiary) {
      amount = costToBeneficiary.valueMoney?.value
    }
  }

  if (!amount && membership) {
    if (membership.propertyGroup) {
      const baseProperty = membership.propertyGroup.find((property) => {
        if (property.priceComponent && property.priceComponent.length > 0) {
          property.priceComponent.find((price) => {
            if (price.type && price.type.length > 0) {
              if (price.type === 'base') {
                amount = price.amount?.value
                return true
              }
            }
            return false
          })
        }
        return false
      })
    }
  }

  return amount ?? 0
}

export function getMemberShipPaidAmountCurrency(
  coverage: R4.ICoverage,
  membership?: R4.IChargeItemDefinition
) {
  let amount: string | undefined

  if (coverage.costToBeneficiary && coverage.costToBeneficiary.length > 0) {
    const costToBeneficiary = coverage.costToBeneficiary.find((e) =>
      e.type?.coding?.find((c) => c.code === 'base')
    )
    if (costToBeneficiary) {
      amount = costToBeneficiary.valueMoney?.currency
    }
  }

  if (!amount && membership) {
    if (membership.propertyGroup) {
      const baseProperty = membership.propertyGroup.find((property) => {
        if (property.priceComponent && property.priceComponent.length > 0) {
          property.priceComponent.find((price) => {
            if (price.type && price.type.length > 0) {
              if (price.type === 'base') {
                amount = price.amount?.currency
                return true
              }
            }
            return false
          })
        }
        return false
      })
    }
  }

  return amount
}

export async function getMembershipTransactionsList(
  accountId: string
): Promise<TransactionOfMembership[] | FHIRErrorResponses> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    coverage: `Coverage/${accountId}`,
    _count: 500,
    _include: 'ExplanationOfBenefit:benefit-referral',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    '/ExplanationOfBenefit?_include=ExplanationOfBenefit:patient&_include=ExplanationOfBenefit:coverage',
    '',
    searchParameters
  )
  if (response.type === 'FHIRErrorResponses') {
    return response
  }

  const bundleRes: R4.IBundle = response as R4.IBundle
  if (bundleRes.entry) {
    const res = geTransactionsWithServiceRequest(bundleRes)

    return res
  }

  if (bundleRes.total === 0) {
    return []
  }

  return {
    status: 400,
    data: {},
    displayText: 'Invoice is not available',
  }
}

export async function getAllMembersOfMembershipPlan(
  planId: string
): Promise<MembershipBeneficiary[] | FHIRErrorResponses> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    identifier: `${planId}`,
    _include: 'Coverage:patient',
    status: 'active',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Coverage',
    '',
    searchParameters
  )
  if (response.type === 'FHIRErrorResponses') {
    return response
  }

  const bundleRes: R4.IBundle = response as R4.IBundle
  if (bundleRes.entry) {
    const res = getMembershipBeneficiaries(bundleRes)
    return res
  }

  if (bundleRes.total === 0) {
    return []
  }

  return {
    status: 400,
    data: {},
    displayText: 'Invoice is not available',
  }
}

export async function removeMemberFromPlan(
  planId: string,
  primaryPatientId: string,
  memberIdToBeRemoved: string
): Promise<any> {
  const fhirClient: EnrolCient = new EnrolCient()

  try {
    const response: any = await fhirClient.doCreateEnrolmentFlowRequest(
      `/membership/update/${planId}`,
      {
        primaryPatientId,
        MembersToBeRemoved: [memberIdToBeRemoved],
      }
    )

    return response
  } catch (error) {}

  return {
    status: 400,
    data: {},
    displayText: 'Invoice is not available',
  }
}

export async function addNewMemberToMembership(
  planId: string,
  primaryPatientId: string,
  memberDetails: SecondaryMember[]
): Promise<any> {
  const fhirClient: EnrolCient = new EnrolCient()

  try {
    const response: any = await fhirClient.doCreateEnrolmentFlowRequest(
      `/membership/update/${planId}`,
      {
        primaryPatientId,
        MembersToBeAdded: memberDetails,
      }
    )

    return response
  } catch (error) {}

  return {
    status: 400,
    data: {},
    displayText: 'Invoice is not available',
  }
}

export async function getActiveMembershipsOfPatient(
  patientId: string
): Promise<R4.IBundle | FHIRErrorResponses> {
  try {
    const searchParams = {
      type: 'http://terminology.hl7.org/CodeSystem/v3-ActCode|MCPOL',
      status: 'active',
      beneficiary: `Patient/${patientId}`,
    }
    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const response: any = await fhirClient.doGetResource(
      `Coverage`,
      searchParams
    )

    return response
  } catch (error) {}

  return {
    status: 400,
    data: {},
    displayText: 'Invoice is not available',
  }
}

export async function getActiveMembershipDetailsOfPatient(
  patientId: string
): Promise<R4.ICoverage | FHIRErrorResponses> {
  try {
    const coverageDetails = await getActiveMembershipsOfPatient(patientId)
    if ('status' in coverageDetails) {
      return coverageDetails
    }
    const coverageBundle = coverageDetails as R4.IBundle
    if (coverageBundle.entry && coverageBundle.entry.length > 0) {
      const coverage = coverageBundle.entry[0].resource as R4.ICoverage
      if (isPrimaryMember(coverage.relationship)) {
        return coverage
      }
      const beneficiaries = await getAllMembersOfMembershipPlan(
        getMemberPlanId(coverage)
      )
      if ('status' in beneficiaries) {
        return beneficiaries
      }
      const membershipBeneficiaries = beneficiaries as MembershipBeneficiary[]
      const primaryMember = membershipBeneficiaries.find((beneficiary) =>
        isPrimaryMember(beneficiary.coverage.relationship)
      )
      if (primaryMember) {
        return primaryMember.coverage
      }
    }
    return {
      status: 1200,
      data: {},
      displayText: 'Not subscribed to any membership plan',
    }
  } catch (error) {}

  return {
    status: 400,
    data: {},
    displayText: 'Invoice is not available',
  }
}

export function isPrimaryMember(
  relationship: R4.ICodeableConcept | undefined
): boolean {
  if (!relationship) {
    return false
  }
  if (relationship.coding) {
    const coding = relationship.coding[0]
    if (coding.code === 'self') {
      return true
    }
  }

  return false
}

export function getMemberShipAvailableCredit(coverage: R4.ICoverage) {
  let amount: number | undefined
  if (coverage) {
    if (coverage.costToBeneficiary && coverage.costToBeneficiary.length > 0) {
      const costToBeneficiary = coverage.costToBeneficiary.find((e) =>
        e.type?.coding?.find((c) => c.code === 'deductible')
      )
      if (costToBeneficiary) {
        amount = costToBeneficiary.valueMoney?.value
      }
    }
  }

  return amount ?? 0
}

export function geTransactionsWithServiceRequest(
  responseBundle: R4.IBundle
): TransactionOfMembership[] {
  const convertedAppointments: TransactionOfMembership[] = []
  const explanationOfBenefits: any = {}

  const serviceRequests: any = {}
  const patient: any = {}
  const coverage: any = {}
  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ExplanationOfBenefit':
                  explanationOfBenefits[value.resource.id] =
                    value.resource as R4.IExplanationOfBenefit
                  break

                case 'ServiceRequest':
                  serviceRequests[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                case 'Patient':
                  patient[value.resource.id] = value.resource as R4.IPatient
                  break

                case 'Coverage':
                  coverage[value.resource.id] = value.resource as R4.ICoverage
                  break
                default:
                  break
              }
            }
          }
        })
        for (const key in explanationOfBenefits) {
          if (key) {
            const currentAppointment: R4.IExplanationOfBenefit =
              explanationOfBenefits[key] as R4.IExplanationOfBenefit

            const srId = currentAppointment.referral?.reference?.split('/')[1]
            const coverageId = currentAppointment.insurance
              ?.find((e) => e.focal)
              ?.coverage?.reference?.split('/')[1]
            const patientId =
              currentAppointment.patient?.reference?.split('/')[1]
            const serviceReqRes: R4.IServiceRequest =
              serviceRequests[srId ?? '']
            const coverageRes: R4.ICoverage = coverage[coverageId ?? '']
            const patientRes: R4.IPatient = patient[patientId ?? '']
            let ipdId
            const svIsIpd = getIdentifierBySystemType(
              serviceReqRes.identifier ?? [],
              'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
            )

            if (svIsIpd) {
              ipdId = svIsIpd.value
            } else {
              const svIsOpd = getIdentifierBySystemType(
                serviceReqRes.identifier ?? [],
                'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
              )
              ipdId = svIsOpd?.value
            }

            convertedAppointments.push({
              id: currentAppointment.id!,
              amount: '0',
              explanationBenefit: currentAppointment,
              serviceRequest: serviceReqRes,
              requestId: ipdId ?? '',
              beneficiary: patientRes,
              coverage: coverageRes,
            })
          }
        }
      }
    }
  }
  return convertedAppointments
}

export function getMemberShipAvailableCreditForSplit(
  coverage: R4.ICoverage,
  split: SplitPaymentDetails[]
) {
  let amount: number
  let splitPaymentAmount: number
  splitPaymentAmount = 0
  amount = 0

  const advanceDetails: SplitPaymentDetails[] = [...split].filter(
    (e) => e.amount > 0 && e.paymentType && e.paymentType.code === 'membership'
  )
  if (advanceDetails.length > 0) {
    for (let i = 0; i < advanceDetails.length; i++) {
      splitPaymentAmount += advanceDetails[i].amount
    }
  }

  if (coverage.costToBeneficiary && coverage.costToBeneficiary.length > 0) {
    const costToBeneficiary = coverage.costToBeneficiary.find((e) =>
      e.type?.coding?.find((c) => c.code === 'deductible')
    )
    if (costToBeneficiary) {
      amount = costToBeneficiary.valueMoney?.value ?? 0
    }
  }
  if (amount === 0) {
    return 0
  }
  const finalAmount = amount - splitPaymentAmount
  if (finalAmount < 0) return finalAmount
  return finalAmount
}
