import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, TextField } from '@material-ui/core'
import _ from 'lodash'
import {
  getMedicationCategoryUrlFromCode,
  medicationMaps,
} from 'models/AyurvedaUrlCategory'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { oralUnits, routesOfAdministration } from 'utils/constants'
import { getAddedDate, getTomorrowDate } from 'utils/dateUtil'
import { validateOthers } from 'utils/patientHelper/patientEditHelper'
import { ValueSetSelectSingleDisplaySinge } from 'views/components/common/SingleSelectFromDesination'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import { CodeFromExistingValueSet } from 'views/components/common/SingleSelectFromValueSetUrlWithCode'
import { ValueSetSelectSingleDisplayCombined } from 'views/components/common/ValueSetSelectSingleDisplayCombined'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloSelect } from 'views/components/LeftMenu/WelloSelect'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { TabbedMultiSelector } from 'views/components/toggle_multiSelector'
import CustomizedDividers from 'views/components/toggle_selector'
import { WelloTextField } from 'wello-web-components'
import anupanas from '../../../../assets/constants/common-anupanas.json'

// import { MedicationTile } from './medication_tile'

interface Props {
  preSelectedMedication?: WelloMedication
  preEnteredQueryText?: string
  preSelectedMedicationCode?: R4.ICoding
  disableStartAndEndDateSelector?: boolean
  onChanged: (medications: WelloMedication) => void
}
export const AyurvedaMedicationCaptureForm: React.FC<Props> = ({
  onChanged,
  preSelectedMedication,

  preEnteredQueryText,
  preSelectedMedicationCode,
  disableStartAndEndDateSelector = false,
}) => {
  const { t } = useTranslation(['common', 'medication', 'dosage'])

  const dispatch = useDispatch()
  const [defaultSubcategoryUrl, setDefaultSubcategoryUrl] = useState<string>()
  const [defaultMedicineCategoryUrl, setDefaultMedicineCategoryUrl] =
    useState<string>(medicationMaps.all_url)

  const [selectedDatePart, setSelectedDatePart] = useState<string>('today')
  const [selectedWeekPart, setSelectedWeekPart] = useState<string>('3d')
  const [displayChoices, setdisplayChoices] = useState<boolean>(
    preSelectedMedicationCode === undefined
  )

  const [formState, setFormState] = useState(_.random(0, 109090).toFixed(2))

  const [currentMedicationTile, setCurrentMedicationTile] =
    useState<WelloMedication>(
      preSelectedMedication ?? {
        otherDataText: '',
        administrationCode: '26643006',
        startFrom: new Date(),
        till: getAddedDate(2),
        mealCode: 'after',
        timeOfDayCode: ['morning'],
        type: 'ayurveda',
      }
    )

  useEffect(() => {
    onChanged(currentMedicationTile)
  }, [currentMedicationTile])

  return (
    <Box
      marginY={1}
      display='flex'
      flexDirection='column'
      width='100%'
      px={1}
      key={formState}
    >
      <Box display='flex' flexDirection='row' width='100%'>
        {/* <Box px={1} width='15%'>
          <ValueSetSelectSingle
            title='Category'
            url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurvedic-medicine-types-vs'
            id='med_category'
            fuzzySearch={false}
            alwaysShowAllOptions={true}
            preSelectedOptions={currentMedicationTile.medicationCode}
            disabled={displayChoices}
            onOptionSelected={(selectedKriya) => {
              if (selectedKriya) {
                const res = getMedicationCategoryUrlFromCode(
                  selectedKriya.code ?? ''
                )
                if (res) {
                  if (res.leaf_node) {
                    setDefaultSubcategoryUrl(undefined)
                    setDefaultMedicineCategoryUrl(res.category_url)
                  } else {
                    setDefaultSubcategoryUrl(res.category_url)
                  }
                } else {
                  setDefaultSubcategoryUrl(undefined)
                  setDefaultMedicineCategoryUrl(medicationMaps.all_url)
                }
              }
            }}
            helperText={undefined}
          />
        </Box> */}

        {/* <Box px={1} width='15%'>
          <ValueSetSelectSingle
            key={`${defaultSubcategoryUrl}sub_category`}
            title='Sub Category'
            alwaysShowAllOptions={true}
            url={`http://wellopathy.com/fhir/india/core/ValueSet/${defaultSubcategoryUrl}`}
            id='med_sub_category'
            fuzzySearch={true}
            disabled={_.isEmpty(defaultSubcategoryUrl) && displayChoices}
            onOptionSelected={(selectedCategory) => {
              if (selectedCategory) {
                const res = getMedicationCategoryUrlFromCode(
                  selectedCategory.code ?? ''
                )
                if (res) {
                  setDefaultMedicineCategoryUrl(res?.category_url)
                  return
                }
              }

              setDefaultMedicineCategoryUrl(medicationMaps.all_url)
            }}
            helperText={undefined}
          />
        </Box> */}

        <Box px={1} width='30%' flexGrow={1} display='flex'>
          {preSelectedMedicationCode && !displayChoices && (
            <CodeFromExistingValueSet
              id='med_selections_form_value_set'
              title='Selected Medication'
              onNoOptionAvailable={() => {
                setdisplayChoices(true)
              }}
              searchCode={preSelectedMedicationCode}
              onOptionSelected={(selectedOption) => {
                setCurrentMedicationTile({
                  ...currentMedicationTile,
                  medicationCode: selectedOption,
                })
              }}
            />
          )}
          {displayChoices && (
            <ValueSetSelectSingle
              key={`${defaultMedicineCategoryUrl}_medicines`}
              title='Select Medication'
              url={`http://wellopathy.com/fhir/india/core/ValueSet/${defaultMedicineCategoryUrl}`}
              id='medication_name'
              fuzzySearch={true}
              preEnteredText={preEnteredQueryText}
              preSelectedOptions={currentMedicationTile.medicationCode}
              disabled={false}
              onOptionSelected={(selectedKriya) => {
                setCurrentMedicationTile({
                  ...currentMedicationTile,
                  medicationCode: selectedKriya,
                })
              }}
              helperText={undefined}
              placeHolder='Search Medication'
            />
          )}
        </Box>
        <Box px={1} width='30%'>
          <ValueSetSelectSingleDisplaySinge
            key='wellopathy-relationship-vs_medicines_routes'
            title='Route of Administration'
            url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-route-of-administration-vs'
            id='route_type'
            fuzzySearch={true}
            alwaysShowAllOptions={true}
            disabled={false}
            onOptionSelected={(conditionsData: R4.ICoding | undefined) => {
              if (conditionsData) {
                setCurrentMedicationTile({
                  ...currentMedicationTile,
                  administrationCode: conditionsData.code,
                })
              }
            }}
            helperText={undefined}
            preSelectedOptions={{
              system: 'http://snomed.info/sct',
              code: '26643006',
              display: 'Oral (by mouth) ',
            }}
            placeHolder='Route Of Administration'
          />
        </Box>

        <Box px={1} width='15%'>
          <Box width='100%' display='flex' flexDirection='column'>
            <WelloFormItemLabel title={t('labelDosage:resourceName')} />
            <WelloTextFieldWithoutTitle
              textProps={{
                id: `med_dosage`,
                placeholder: 'Dosage',
                error:
                  currentMedicationTile.dosageQuantity! <= 0 ||
                  currentMedicationTile.dosageQuantity! >= 10000 ||
                  Number.isNaN(currentMedicationTile.dosageQuantity!),
                type: 'number',
                // value: tests.scheduleSummary,
                disabled: currentMedicationTile.dosageQuantityType
                  ? currentMedicationTile.dosageQuantityType === '422135004'
                  : currentMedicationTile.administrationCode
                  ? currentMedicationTile.administrationCode === '6064005'
                  : false,
                helperText:
                  currentMedicationTile.dosageQuantity! <= 0 ||
                  Number.isNaN(currentMedicationTile.dosageQuantity!)
                    ? `${t('labelCommon:invlaid_dosages')}`
                    : '',
                inputProps: {
                  min: 0,
                  max: 10000,
                  step: 0.1,
                },

                //   InputProps: {
                //     endAdornment: (
                //       <InputAdornment position='end'>
                //         {getSuffixForDosage(currentMedicationTile.administrationCode)}
                //       </InputAdornment>
                //     ),
                //   },
                //   value: tests.testPrice,
                onChange: (changeData) => {
                  if (changeData) {
                    setCurrentMedicationTile({
                      ...currentMedicationTile,
                      dosageQuantity: parseFloat(
                        parseFloat(changeData.target.value).toFixed(1)
                      ),
                    })
                    /* setSelectedDosage(
                            parseFloat(
                              parseFloat(changeData.target.value).toFixed(1)
                            )
                          ) */
                  }
                },
              }}
            />
          </Box>
        </Box>

        <Box width='25%' px={1}>
          <ValueSetSelectSingleDisplaySinge
            key='wellopathy-relationship-vs_medicines'
            title='Unit'
            url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-dosage-ucum-units-vs'
            id='med_units'
            fuzzySearch={true}
            disabled={false}
            onOptionSelected={(conditionsData: R4.ICoding | undefined) => {
              if (conditionsData) {
                if (conditionsData.code === '422135004') {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    dosageQuantityType: conditionsData.code,
                    dosageQuantity: undefined,
                    aushadhaKalaCode: undefined,
                    anupanaCode: undefined,
                    anupanaDosageQuantity: undefined,
                  })
                } else {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    dosageQuantityType: conditionsData.code,
                  })
                }
              }
            }}
            helperText={undefined}
            placeHolder='Unit'
          />
          {/* <WelloSelect
            label='Unit'
            availableOptions={oralUnits}
            required={true}
            id='med_units'
            type='Choose Unit'
            displayField='display'
            valueField='code'
            onSelectionChanged={(value: any) => {
              setCurrentMedicationTile({
                ...currentMedicationTile,
                dosageQuantityType: value.code,
              })

              // setDosageType(value.code)
            }}
          /> */}
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' width='100%'>
        <Box display='flex' flexDirection='row' width='30%' px={1}>
          <ValueSetSelectSingleDisplayCombined
            title='Oushadhakala'
            url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurveda-aushadha-kala-vs'
            id='med_oushadhakala'
            fuzzySearch={true}
            preSelectedOptions={currentMedicationTile.medicationCode}
            disabled={
              currentMedicationTile.dosageQuantityType
                ? currentMedicationTile.dosageQuantityType === '422135004'
                : currentMedicationTile.administrationCode
                ? currentMedicationTile.administrationCode === '6064005'
                : false
            }
            onOptionSelected={(selectedKriya) => {
              setCurrentMedicationTile({
                ...currentMedicationTile,
                aushadhaKalaCode: selectedKriya,
              })
            }}
            helperText={undefined}
          />
          {/* <WelloSelectFHIRCoding
                title='Route Of Administration'
                availableCodings={routesOfAdministration}
                id='route_type'
                onChanged={(e) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    administrationCode: e.code ?? '',
                  })
                }}
                preSelectedCoding={{
                  system: 'http://snomed.info/sct',
                  code: '26643006',
                  display: 'Oral',
                }}
                textFieldProps={{
                  size: 'small',
                  fullWidth: true,
                }}
              /> */}
        </Box>

        <Box px={1} width='30%'>
          <Box display='flex' flexDirection='column' width='100%'>
            <WelloSelectFHIRCoding
              title='Anupana'
              onChanged={(selectedKriya) => {
                setCurrentMedicationTile({
                  ...currentMedicationTile,
                  otherDataText: '',
                  anupanaCode: selectedKriya,
                })
              }}
              disabled={
                currentMedicationTile.dosageQuantityType
                  ? currentMedicationTile.dosageQuantityType === '422135004'
                  : currentMedicationTile.administrationCode
                  ? currentMedicationTile.administrationCode === '6064005'
                  : false
              }
              availableCodings={anupanas}
              id='med_anupana'
              textFieldProps={{
                size: 'small',
                fullWidth: true,
              }}
            />
            {currentMedicationTile.anupanaCode &&
              currentMedicationTile.anupanaCode.code! === 'C0205394' && (
                <Box display='flex' width='100%' flexDirection='column'>
                  <WelloFormItemLabel title='Other Anupana' />
                  <WelloTextFieldWithoutTitle
                    textProps={{
                      id: `med_anyOther`,
                      type: 'text',
                      multiline: true,
                      error:
                        validateOthers(
                          currentMedicationTile.anupanaCode.display!,
                          true
                        ).length > 0,
                      disabled: currentMedicationTile.dosageQuantityType
                        ? currentMedicationTile.dosageQuantityType ===
                          '422135004'
                        : currentMedicationTile.administrationCode
                        ? currentMedicationTile.administrationCode === '6064005'
                        : false,
                      helperText:
                        validateOthers(
                          currentMedicationTile.anupanaCode.display!,
                          true
                        ).length > 0
                          ? `${validateOthers(
                              currentMedicationTile.anupanaCode.display!,
                              true
                            )}`
                          : '',
                      onChange: (changeData) => {
                        setCurrentMedicationTile({
                          ...currentMedicationTile,
                          otherDataText: changeData.target.value,
                          anupanaCode: {
                            code: 'C0205394',
                            system:
                              'http://terminology.hl7.org/CodeSystem/umls',
                            display: changeData.target.value,
                          },
                        })
                      },
                    }}
                  />
                </Box>
              )}
          </Box>
        </Box>

        <Box px={1} width='15%'>
          <Box width='100%' display='flex' flexDirection='column'>
            <WelloFormItemLabel title={t('labelDosage:resourceName')} />
            <WelloTextFieldWithoutTitle
              textProps={{
                id: `anupana_dosage`,
                placeholder: 'Dosage',
                error:
                  currentMedicationTile.anupanaDosageQuantity! <= 0 ||
                  currentMedicationTile.anupanaDosageQuantity! >= 10000 ||
                  Number.isNaN(currentMedicationTile.anupanaDosageQuantity!),
                type: 'number',
                // value: tests.scheduleSummary,
                helperText:
                  currentMedicationTile.anupanaDosageQuantity! <= 0 ||
                  Number.isNaN(currentMedicationTile.anupanaDosageQuantity!)
                    ? `${t('labelCommon:invlaid_dosages')}`
                    : '',
                disabled: currentMedicationTile.dosageQuantityType
                  ? currentMedicationTile.dosageQuantityType === '422135004'
                  : currentMedicationTile.administrationCode
                  ? currentMedicationTile.administrationCode === '6064005'
                  : false,
                inputProps: {
                  min: 0,
                  max: 10000,
                  step: 0.1,
                },

                //   InputProps: {
                //     endAdornment: (
                //       <InputAdornment position='end'>
                //         {getSuffixForDosage(currentMedicationTile.administrationCode)}
                //       </InputAdornment>
                //     ),
                //   },
                //   value: tests.testPrice,
                onChange: (changeData) => {
                  if (changeData) {
                    setCurrentMedicationTile({
                      ...currentMedicationTile,
                      anupanaDosageQuantity: parseFloat(
                        parseFloat(changeData.target.value).toFixed(1)
                      ),
                    })
                    /* setSelectedDosage(
                            parseFloat(
                              parseFloat(changeData.target.value).toFixed(1)
                            )
                          ) */
                  }
                },
              }}
            />
          </Box>
        </Box>

        <Box width='25%' px={1}>
          <ValueSetSelectSingleDisplaySinge
            key='wellopathy-relationship-vs_medicines'
            title='Unit'
            url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-dosage-ucum-units-vs'
            id='med_units'
            fuzzySearch={true}
            disabled={
              currentMedicationTile.dosageQuantityType
                ? currentMedicationTile.dosageQuantityType === '422135004'
                : currentMedicationTile.administrationCode
                ? currentMedicationTile.administrationCode === '6064005'
                : false
            }
            onOptionSelected={(conditionsData: R4.ICoding | undefined) => {
              if (conditionsData) {
                setCurrentMedicationTile({
                  ...currentMedicationTile,
                  anupanaDosageQuantityType: conditionsData.code,
                })
              }
            }}
            helperText={undefined}
            placeHolder='Unit'
          />
          {/* <WelloSelect
            label='Unit'
            availableOptions={oralUnits}
            required={true}
            id='anupana_units'
            type='Choose Unit'
            displayField='display'
            valueField='code'
            onSelectionChanged={(value: any) => {
              setCurrentMedicationTile({
                ...currentMedicationTile,
                anupanaDosageQuantityType: value.code,
              })

              // setDosageType(value.code)
            }}
          /> */}
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        justifyContent='space-between'
      >
        <Box display='flex' px={1} width='33%'>
          <TabbedMultiSelector
            size='small'
            preSelectedValues={['morning']}
            menuLabel='Time of the Day'
            options={[
              {
                displayText: `${t('labelCommon:morning')}`,
                value: 'morning',
              },
              {
                displayText: `${t('labelCommon:afternoon')}`,
                value: 'afternoon',
              },
              {
                displayText: `${t('labelCommon:Evening')}`,
                value: 'evening',
              },
              {
                displayText: `${t('labelCommon:night')}`,
                value: 'night',
              },
            ]}
            onSelectionChanged={(selectedValue) => {
              setCurrentMedicationTile({
                ...currentMedicationTile,
                timeOfDayCode: selectedValue,
              })
              // setSelectedTimeOfDay(selectedValue)
            }}
          />{' '}
        </Box>
        {!disableStartAndEndDateSelector && (
          <Box px={1} width='33%'>
            <CustomizedDividers
              key='_date_part'
              size='small'
              menuLabel={t('labelCommon:start From')}
              options={[
                {
                  displayText: `${t('labelCommon:today')}`,
                  value: 'today',
                },
                {
                  displayText: `${t('labelCommon:tomorrow')}`,
                  value: 'tomo',
                },
                {
                  displayText: `${t('labelCommon:custom')}`,
                  value: 'custom',
                },
              ]}
              onSelectionChanged={(selectedValue) => {
                setSelectedDatePart(selectedValue)
                if (selectedValue === 'today') {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    startFrom: new Date(),
                    till: getAddedDate(2),
                  })
                }
                if (selectedValue === 'tomo') {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    startFrom: getTomorrowDate(),
                    till: getAddedDate(3),
                  })
                }
              }}
              preSelectedValue={selectedDatePart}
            />

            {selectedDatePart === 'custom' && (
              <TextField
                key='custom'
                variant='outlined'
                style={{
                  marginTop: '16px',
                }}
                size='small'
                onChange={(val) => {
                  let tillDateLocal = currentMedicationTile.till

                  // setStartDate(new Date(val.target.value))

                  if (selectedWeekPart === '3d') {
                    if (
                      moment(new Date(val.target.value)).format(
                        'YYYY-MM-DD'
                      ) === moment().format('YYYY-MM-DD')
                    ) {
                      tillDateLocal = moment(
                        moment(new Date(val.target.value)).format('YYYY-MM-DD')
                      )
                        .add(2, 'days')
                        .toDate()
                    } else {
                      tillDateLocal = moment(
                        moment(new Date(val.target.value)).format('YYYY-MM-DD')
                      )
                        .add(2, 'days')
                        .toDate()
                    }
                  }

                  if (selectedWeekPart === '3d') {
                    if (
                      moment(new Date(val.target.value)).format(
                        'YYYY-MM-DD'
                      ) === moment().format('YYYY-MM-DD')
                    ) {
                      tillDateLocal = moment(
                        moment(new Date(val.target.value)).format('YYYY-MM-DD')
                      )
                        .add(2, 'days')
                        .toDate()
                    } else {
                      tillDateLocal = moment(
                        moment(new Date(val.target.value)).format('YYYY-MM-DD')
                      )
                        .add(2, 'days')
                        .toDate()
                    }
                  }

                  if (selectedWeekPart === '1W') {
                    if (
                      moment(new Date(val.target.value)).format(
                        'YYYY-MM-DD'
                      ) === moment().format('YYYY-MM-DD')
                    ) {
                      tillDateLocal = moment(
                        moment(new Date(val.target.value)).format('YYYY-MM-DD')
                      )
                        .add(6, 'days')
                        .toDate()
                    } else {
                      tillDateLocal = moment(
                        moment(new Date(val.target.value)).format('YYYY-MM-DD')
                      )
                        .add(6, 'days')
                        .toDate()
                    }
                  }
                  if (selectedWeekPart === '2W') {
                    if (
                      moment(new Date(val.target.value)).format(
                        'YYYY-MM-DD'
                      ) === moment().format('YYYY-MM-DD')
                    ) {
                      tillDateLocal = moment(
                        moment(new Date(val.target.value)).format('YYYY-MM-DD')
                      )
                        .add(13, 'days')
                        .toDate()
                    } else {
                      tillDateLocal = moment(
                        moment(new Date(val.target.value)).format('YYYY-MM-DD')
                      )
                        .add(13, 'days')
                        .toDate()
                    }
                  }
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    startFrom: new Date(val.target.value),
                    till: tillDateLocal,
                  })
                }}
                type='date'
                inputProps={{
                  min: moment.utc(new Date()).local().format('YYYY-MM-DD'),
                }}
              />
            )}
          </Box>
        )}

        {!disableStartAndEndDateSelector && (
          <Box px={1} width='33%'>
            <CustomizedDividers
              key='week_part'
              size='small'
              menuLabel={t('labelCommon:till')}
              options={[
                {
                  displayText: `${t('labelCommon:3_Days')}`,
                  value: '3d',
                },
                {
                  displayText: `${t('labelCommon:1_Week')}`,
                  value: '1W',
                },
                {
                  displayText: `2 Weeks`,
                  value: '2W',
                },
                {
                  displayText: `${t('labelCommon:custom')}`,
                  value: 'custom',
                },
              ]}
              onSelectionChanged={(selectedValue) => {
                let tillDateLocal = currentMedicationTile.till
                setSelectedWeekPart(selectedValue)
                if (selectedValue === '3d') {
                  if (
                    moment(currentMedicationTile.startFrom).format(
                      'YYYY-MM-DD'
                    ) === moment().format('YYYY-MM-DD')
                  ) {
                    if (selectedDatePart !== 'custom') {
                      tillDateLocal = getAddedDate(2)
                    }
                  } else if (selectedDatePart !== 'custom') {
                    tillDateLocal = getAddedDate(3)
                  }
                }
                if (selectedValue === '1W') {
                  if (
                    moment(currentMedicationTile.startFrom).format(
                      'YYYY-MM-DD'
                    ) === moment().format('YYYY-MM-DD')
                  ) {
                    if (selectedDatePart === 'custom') {
                      tillDateLocal = moment(
                        moment(currentMedicationTile.startFrom).format(
                          'YYYY-MM-DD'
                        )
                      )
                        .add(6, 'days')
                        .toDate()
                    } else {
                      tillDateLocal = getAddedDate(6)
                    }
                  } else if (selectedDatePart === 'custom') {
                    tillDateLocal = moment(
                      moment(currentMedicationTile.startFrom).format(
                        'YYYY-MM-DD'
                      )
                    )
                      .add(6, 'days')
                      .toDate()
                  } else {
                    tillDateLocal = getAddedDate(7)
                  }
                }
                if (selectedValue === '2W') {
                  if (
                    moment(currentMedicationTile.startFrom).format(
                      'YYYY-MM-DD'
                    ) === moment().format('YYYY-MM-DD')
                  ) {
                    if (selectedDatePart === 'custom') {
                      tillDateLocal = moment(
                        moment(currentMedicationTile.startFrom).format(
                          'YYYY-MM-DD'
                        )
                      )
                        .add(13, 'days')
                        .toDate()
                    } else {
                      tillDateLocal = getAddedDate(13)
                    }
                  } else if (selectedDatePart === 'custom') {
                    tillDateLocal = moment(
                      moment(currentMedicationTile.startFrom).format(
                        'YYYY-MM-DD'
                      )
                    )
                      .add(13, 'days')
                      .toDate()
                  } else {
                    tillDateLocal = getAddedDate(14)
                  }
                }
                setCurrentMedicationTile({
                  ...currentMedicationTile,
                  till: tillDateLocal,
                })
              }}
              preSelectedValue={selectedWeekPart}
            />

            {selectedWeekPart === 'custom' && (
              <TextField
                key='week_custom'
                variant='outlined'
                style={{
                  marginTop: '16px',
                }}
                id='custom_week'
                size='small'
                onChange={(val) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    till: new Date(val.target.value),
                  })

                  // setTillDate(new Date(val.target.value))
                }}
                type='date'
                inputProps={{
                  min: moment
                    .utc(currentMedicationTile.startFrom)
                    .local()
                    .format('YYYY-MM-DD'),
                }}
              />
            )}
          </Box>
        )}
      </Box>

      <Box display='flex' flexDirection='row' width='100%'>
        <Box
          display='flex'
          width='100%'
          flexDirection='column'
          flexGrow={1}
          px={1}
        >
          <WelloFormItemLabel title='Additional Notes' />

          <TextField
            size='small'
            fullWidth
            multiline
            id='txt_ayurveda_additional_notes'
            type='string'
            value={currentMedicationTile.notes}
            variant='outlined'
            onChange={(event) => {
              setCurrentMedicationTile({
                ...currentMedicationTile,
                notes: event.target.value,
              })
              // setAdditionalNotes(event.target.value)
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}
