import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
  Button,
} from '@material-ui/core'
import { kPrimaryLight, kPrimaryMain } from 'configs/styles/muiThemes'
import PendingIcon from '@mui/icons-material/Pending'
import {
  FhirAppointmentDetail,
  FhirPendingAppointmentDetails,
} from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import {
  getPreviousAppointmentCount,
  getReports,
} from 'redux/appointments/appointmentViewer/appointmentSearchSlice'
import { isAyurvedaDoctor, isDoctor } from 'services/userDetailsService'
import {
  getAddressOfPatient,
  getAddressOfPatientForOPDAppointment,
  getAppointmentIdForFollowUp,
  getDisplayOfSystemFromCodableConceptForAppointment,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getProfilePicPatient,
} from 'utils/fhirResourcesHelper'
import { hasSpaces } from 'utils/fhirResoureHelpers/allergyHelpers'
import {
  getAppointmentPaymentDetails,
  getAppointmentStatusText,
  getAppointmentTime,
  getAppointmentTimeForPending,
  getPaymentStatusForAppointment,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import SocketContext from 'views/contexts/socketMessageContext'
import { GenderAndAgeTileWithoutBack } from '../patients/patient_profile/genderAgeTilleWithoutBackGround'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from '../common/deleteConfirmationDialog'

interface Props {
  appointmentDetails: FhirPendingAppointmentDetails
  isOdd?: boolean
  onTap?: () => void
  dense?: boolean
  isSelected?: boolean
  index: number
}

export const PendingListDetailsTile: React.FC<Props> = ({
  appointmentDetails,
  isOdd = false,
  onTap,
  dense = false,
  isSelected = false,
  index,
}: Props) => {
  const dispatch = useDispatch()

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  return (
    <Box width='100%'>
      <Card
        elevation={isOdd ? 0 : 1}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 0,
          backgroundColor: isSelected
            ? kPrimaryLight
            : isOdd
            ? '#FFFFFFAF'
            : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 0,
          }}
        >
          {dense && (
            <Box display='flex' flexDirection='row' width='100%'>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                px={1}
              >
                <Avatar
                  alt={getNameOfPatient(appointmentDetails.patient) ?? ''}
                  src={getProfilePicPatient(appointmentDetails.patient) ?? ''}
                />
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                flexGrow={1}
                id='patient_name'
              >
                {getMiddleNameOfPatient(appointmentDetails.patient).length >
                  0 && (
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    style={{
                      textTransform: 'capitalize',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    {getNameOfPatient(appointmentDetails.patient)}{' '}
                    {getMiddleNameOfPatient(appointmentDetails.patient) ?? ''}{' '}
                    {getLastNameOfPatient(appointmentDetails.patient)}
                  </Typography>
                )}

                {getMiddleNameOfPatient(appointmentDetails.patient).length ===
                  0 && (
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    style={{
                      textTransform: 'capitalize',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    {getNameOfPatient(appointmentDetails.patient)}{' '}
                    {getMiddleNameOfPatient(appointmentDetails.patient) ?? ''}{' '}
                    {getLastNameOfPatient(appointmentDetails.patient)}
                  </Typography>
                )}
                <Typography
                  variant='body2'
                  color='initial'
                  style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
                >
                  {getAddressOfPatient(
                    appointmentDetails.patient.address ?? []
                  )}
                </Typography>

                <Typography variant='body2' color='initial'>
                  {getAppointmentTimeForPending(appointmentDetails)}
                </Typography>
                <Typography variant='subtitle1' color='initial'>
                  {getDisplayOfSystemFromCodableConceptForAppointment(
                    appointmentDetails.appointment.serviceType?.[0]
                  ) ?? '  In-Person'}
                </Typography>
              </Box>
            </Box>
          )}
          {dense === false && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              alignContent='center'
              p={0.5}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='25%'
              >
                <Box paddingLeft={0.1}>
                  <Typography variant='body2' color='initial'>
                    Appointment at
                  </Typography>
                </Box>
                <Typography variant='body2' color='initial'>
                  {getAppointmentTimeForPending(appointmentDetails)}
                </Typography>
                <Box paddingLeft={0.1}>
                  <Typography variant='subtitle1' color='initial'>
                    {getDisplayOfSystemFromCodableConceptForAppointment(
                      appointmentDetails.appointment.serviceType?.[0]
                    ) ?? '  In-Person'}
                  </Typography>
                </Box>

                {/* <Typography
                    variant='body2'
                    color='initial'
                    style={{ paddingLeft: 1 }}
                  >
                    {appointmentDetails.appointment.comment}
                  </Typography> */}
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
              >
                <Avatar
                  alt={getNameOfPatient(appointmentDetails.patient) ?? ''}
                  src={getProfilePicPatient(appointmentDetails.patient) ?? ''}
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignContent='flex-start'
                width='50%'
                paddingLeft={1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='start'
                    px={1}
                    id={`patient${index}`}
                  >
                    {getMiddleNameOfPatient(appointmentDetails.patient).length >
                      0 && (
                      <Typography
                        variant='subtitle1'
                        color='initial'
                        style={{
                          textTransform: 'capitalize',
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {getNameOfPatient(appointmentDetails.patient)}{' '}
                        {getMiddleNameOfPatient(appointmentDetails.patient) ??
                          ''}{' '}
                        {getLastNameOfPatient(appointmentDetails.patient)}
                      </Typography>
                    )}

                    {getMiddleNameOfPatient(appointmentDetails.patient)
                      .length === 0 && (
                      <Typography
                        variant='subtitle1'
                        color='initial'
                        style={{
                          textTransform: 'capitalize',
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {getNameOfPatient(appointmentDetails.patient)}{' '}
                        {getMiddleNameOfPatient(appointmentDetails.patient) ??
                          ''}{' '}
                        {getLastNameOfPatient(appointmentDetails.patient)}
                      </Typography>
                    )}
                  </Box>

                  <Box
                    display='flex'
                    flexDirection='column'
                    width='45%'
                    justifyContent='flex-start'
                    px={1}
                  >
                    <GenderAndAgeTileWithoutBack
                      patient={appointmentDetails.patient}
                      status={false}
                    />
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-start'
                    px={1}
                  >
                    <Typography
                      variant='body2'
                      color='initial'
                      style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
                    >
                      {getAddressOfPatientForOPDAppointment(
                        appointmentDetails.patient.address ?? []
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box flexDirection='row' justifyContent='center' paddingTop={1.5}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    setShowDeleteConfirmation({
                      open: true,
                      onContinueActionClicked: () => {
                        setShowDeleteConfirmation({ open: false })
                        if (onTap) onTap()
                      },
                      onCancelActionClicked: () => {
                        setShowDeleteConfirmation({ open: false })
                      },
                      displayText: 'Are you sure you want to end consultation?',
                      continueActionButtonText: 'End Consultation',
                      cancelActionButtonText: 'Cancel',
                    })
                  }}
                >
                  End Visit
                </Button>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
      {showDeleteConfirmation.open && (
        <ConfirmationDialog
          {...showDeleteConfirmation}
          id='delete'
          title='End Consultation'
        />
      )}
    </Box>
  )
}
