export const TherapiesCategoryAndUrl = [
  {
    name: 'manualTherapiesByPT',
    url: '/HealthcareService?service-category=305&_include=HealthcareService:healthcare-service-billing',
  },
  {
    name: 'machineTherapiesByPT',
    url: '/HealthcareService?service-category=304&_include=HealthcareService:healthcare-service-billing',
  },
]
