/* eslint-disable react/jsx-key */
import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { searchMedicalProviders } from 'redux/practitioner/medicalProvidersSearchHandler/medicalProviderSearchSlice'
import { RootState } from 'redux/rootReducer'
import { isNumber } from 'utils/stringUtils'
import { AgentListView } from './agentListView'

interface Props {
  onAgentSelected: (selectedSlot: PractitionerWithRole) => void
}

export const AgentSearch: React.FC<Props> = ({ onAgentSelected }: Props) => {
  const medicalProvidersSearchSlice = useSelector(
    (state: RootState) => state.medicalProvidersSearchSlice
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(searchMedicalProviders('', '', 'lab-agent'))
  }, [dispatch])

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      flexGrow={1}
      height='100%'
    >
      <Box display='flex' flexDirection='row' width='100%'>
        <TextField
          onChange={(event) => {
            if (isNumber(event.target.value)) {
              dispatch(
                searchMedicalProviders(event.target.value, '', 'lab-agent')
              )
            } else {
              dispatch(
                searchMedicalProviders(event.target.value, '', 'lab-agent')
              )
            }
            dispatch(searchMedicalProviders(event.target.value, 'lab-agent'))
          }}
          variant='outlined'
          size='small'
          fullWidth
          autoFocus={true}
          placeholder={t('labelCommon:search')}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box
        display='flex'
        flexGrow={1}
        flexDirection='column'
        justifyContent='center'
        alignContent='center'
        overflow='auto'
        //   paddingTop={1}
      >
        {medicalProvidersSearchSlice.noResultsAvailable && (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            width='100%'
            height='100%'
            alignContent='center'
          >
            <Typography variant='subtitle1' color='initial' align='center'>
              No Agent Available.
            </Typography>
          </Box>
        )}

        {medicalProvidersSearchSlice.error && (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            width='100%'
            height='100%'
            alignContent='center'
          >
            <Typography variant='subtitle1' color='error' align='center'>
              Error while searching Agents
            </Typography>
          </Box>
        )}

        {medicalProvidersSearchSlice.searching && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
          >
            <CircularProgress size={25} />
          </Box>
        )}
      </Box>

      {medicalProvidersSearchSlice.resultsAvailable && (
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          height='100%'
          overflow='auto'
        >
          <Box display='flex' overflow='auto' height='100%' width='100%'>
            <AgentListView
              practitionerList={
                medicalProvidersSearchSlice.doctorRoleList ?? []
              }
              valueField='id'
              id='search'
              onPractitionerSelected={(e) => {
                onAgentSelected(e)
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}
