import MaterialTable from '@material-table/core'
import { Button, Typography } from '@material-ui/core'
import { PreAppointmentDetails } from 'models/preAppointmentDetails'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'

interface Props {
  onSelected: (rows: PreAppointmentDetails[]) => void
  onAppointmentSelected: (appointment: PreAppointmentDetails) => void
}

export const PreAppointmentTable: React.FC<Props> = ({
  onSelected,
  onAppointmentSelected,
}: Props) => {
  const appointmentSearchSlice = useSelector(
    (state: RootState) => state.preAppointmentSearchSlice
  )
  return (
    <MaterialTable
      title='Positioning Actions Column Preview'
      style={{ width: '100%', height: '100%' }}
      columns={[
        {
          title: (
            <Typography variant='subtitle1' color='primary'>
              Name
            </Typography>
          ),
          field: 'patientName',
          render: (rowData) => (
            <Typography variant='body1' color='initial'>
              {rowData.patientName}
            </Typography>
          ),
        },
        {
          title: (
            <Typography variant='subtitle1' color='primary'>
              Phone Number
            </Typography>
          ),
          field: 'patientPhone',
          render: (rowData) => (
            <Typography variant='body1' color='initial'>
              {rowData.patientPhone}
            </Typography>
          ),
        },
      ]}
      localization={{
        header: {
          actions: '',
        },
        toolbar: {
          searchPlaceholder: 'Search',
          searchTooltip: 'Search',
          nRowsSelected: '',
        },
      }}
      components={{
        Toolbar: (props) => <div />,
      }}
      data={(appointmentSearchSlice.availableAppointments ?? []).map(
        (appointment) => ({
          ...appointment,
          id: appointment.patientPhone + appointment.patientName,
        })
      )}
      actions={[
        {
          icon: () => (
            <Button variant='contained' size='small' color='primary'>
              Admit
            </Button>
          ),
          tooltip: 'Admit',
          onClick: (event, rowData) => {
            onAppointmentSelected(rowData as PreAppointmentDetails)
          },
          position: 'row',
        },
      ]}
      onSelectionChange={(rows) => onSelected(rows)}
      options={{
        actionsColumnIndex: -1,
        selection: true,
        search: true,
        searchFieldVariant: 'outlined',
        searchFieldAlignment: 'left',
        paging: true,
        pageSize: 10,

        pageSizeOptions: [10, 20, 50, 100],
        searchFieldStyle: {
          padding: '0px 0px 0px 0px',
          margin: '0px 0px 0px 0px',
        },
      }}
    />
  )
}
