import { List, ListSubheader } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { AccountDetails } from 'models/labReferralDetails'
import React from 'react'
import { getCompleteDateStringWithOutDay } from 'utils/dateUtil'
import { getDateWiseAppointments } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { AccountTile } from './accountTIle'

interface Props {
  presSelectedAppointmentId?: string
  accountList: AccountDetails[]
  onAccountSelected?: (selectedAppointMent: AccountDetails) => void
  dense?: boolean
}

export const AccountDataTable: React.FC<Props> = ({
  presSelectedAppointmentId,
  accountList,
  onAccountSelected,
  dense = false,
}: Props) => (
  <List
    subheader={<li style={{ padding: 0, width: '100%' }} />}
    style={{ padding: 0, width: '100%', overflow: 'auto', height: '100%' }}
  >
    {dense}
    {accountList.map((e: AccountDetails, index: number) => (
      <li
        key={`section-${e.beneficiaryName ?? ''}`}
        style={{ padding: 0, width: '100%' }}
      >
        <ul style={{ padding: 0 }}>
          <AccountTile
            accountData={e}
            isOdd={index % 2 === 0}
            dense={dense}
            onTap={() => {
              if (onAccountSelected) {
                onAccountSelected(e)
              }
            }}
            key={`lab_item_key${e.account}` ?? ''}
            index={index}
          />
        </ul>
      </li>
    ))}
  </List>
)
