import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { FormErrors, FormMethods, useForm } from 'react-cool-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetUserCompletionState,
  updatePractitionerBasicDetails,
} from 'redux/administration/userSetup/userProfileCompletionSlice/userProfileCompletionSlice'
import { RootState } from 'redux/rootReducer'
import { IdProofType } from 'utils/constants/proof_types'
import {
  getAddress,
  getFileSizeInMB,
  getFirstNameFromHumanName,
  getLastNameFromHumanName,
  getMiddleNameFromHumanName,
  getTelecomFromContactPoints,
} from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { validateMobileNumber } from 'wello-web-components'
import { UnitAdminRegFormValues } from './finish_unit_actor_setup'
import { OrgActorBasicDetailForm } from './org_actor_basic_detail_form'

interface Props {
  open: boolean
  onBackClick: () => void
  onContinueClick: () => void
  task?: R4.ITask
}

export const FinishOrgUserAccountSetUp: React.FC<Props> = ({
  open,
  onBackClick: onClose,
  onContinueClick,
  task,
}) => {
  const userProfileCompletionSlice = useSelector(
    (state: RootState) => state.userProfileCompletionSlice
  )

  const isUpdating =
    userProfileCompletionSlice.updatingBasicDetails ||
    userProfileCompletionSlice.updatingRoleSpecificDetails
  const formRef: FormMethods<UnitAdminRegFormValues> =
    useForm<UnitAdminRegFormValues>({
      defaultValues: {
        firstName: getFirstNameFromHumanName(
          userProfileCompletionSlice.practitionerDetail.name ?? []
        ),
        lastName: getLastNameFromHumanName(
          userProfileCompletionSlice.practitionerDetail.name ?? []
        ),
        profilePic: '',
        middleName: getMiddleNameFromHumanName(
          userProfileCompletionSlice.practitionerDetail.name ?? []
        ),
        email:
          getTelecomFromContactPoints(
            userProfileCompletionSlice.practitionerDetail.telecom ?? [],
            ContactPointSystemKind._email
          ) ?? '',
        phoneNumber:
          getTelecomFromContactPoints(
            userProfileCompletionSlice.practitionerDetail.telecom ?? [],
            ContactPointSystemKind._phone
          ) ?? '',
        address:
          getAddress(
            userProfileCompletionSlice.practitionerDetail.address ?? []
          ) ?? '',
        dob: '',
        size: 0,
        gender: '',
        selfieImage: '',
        identityProofTypeCode: IdProofType[0].code ?? '',
        identityProofNumber: '',
        identityProofImageFront: '',
        identityProofImageBack: '',
        addressProofNumber: '',
        addressProofImageFront: '',
        addressProofImageBack: '',
        addressProofTypeCode: '',
        idProofSameAsAddress: true,
        identityProofImageFrontType: '',
        identityProofImageFrontName: '',
        identityProofImageBackName: '',
        identityProofImageBackType: '',
        addressProofImageFrontType: '',
        addressProofImageBackName: '',
        addressProofImageBackType: '',
        addressProofImageFrontName: '',
      },
      validate: (values) => {
        const errors: FormErrors<UnitAdminRegFormValues> = {}
        const res: string = validateMobileNumber(values.phoneNumber, true)
        if (res != null && res.length > 0) {
          errors.phoneNumber = res
        }

        const res1: number = getFileSizeInMB(values.size)
        if (res1 > 1) {
          errors.profilePic = 'Profile Pic should not be more than 1 MB'
        }

        return errors
      },
      onSubmit: (values) => {
        logger.info('values', values)
        dispatch(updatePractitionerBasicDetails(values, task))
      },
      onError: (errors) => {
        logger.info('errors', errors)
      },
    })

  const { t, i18n } = useTranslation(['common', 'patient'])

  const dispatch = useDispatch()

  useEffect(() => {
    if (userProfileCompletionSlice.updatedRoleSpecificDetails) {
      dispatch(resetUserCompletionState())
      onContinueClick()
    }
  }, [
    userProfileCompletionSlice.updatedRoleSpecificDetails,
    onContinueClick,
    dispatch,
  ])

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Enter Your Details</DialogTitle>
      <DialogContent dividers={true}>
        {userProfileCompletionSlice.currentStep === 'basic_details' && (
          <OrgActorBasicDetailForm formRef={formRef} />
        )}
      </DialogContent>
      <DialogActions>
        {userProfileCompletionSlice.updatingBasicDetails && (
          <CircularProgress />
        )}

        {!isUpdating && (
          <Box flex='display' flexDirection='row'>
            {userProfileCompletionSlice.currentStep === 'basic_details' && (
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  formRef.submit()
                }}
              >
                {t('labelCommon:continue')}
              </Button>
            )}
          </Box>
        )}
      </DialogActions>
    </Dialog>
  )
}
