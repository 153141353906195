/* eslint-disable prefer-destructuring */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Errors } from 'io-ts'
import {
  LabObsData,
  ObsDataTable,
  ObsDataTableResource,
  ObsList,
  QualifiedInterval,
  TableArray,
} from 'models/labObsData'
import * as E from 'fp-ts/lib/Either'
import {
  LabOfferingDetail,
  LabReferralService,
  LabTabularService,
} from 'models/labOfferDetail'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getAnnotationValueExtensionsOfUrl,
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultDisplayOfSystemFromCodableConcept,
  getExtensionValueOfUrl,
  getGenderOfPatient,
  getIdentifierValueBySystem,
  getStringValueExtensionsOfUrl,
  getValueNameValueExtensionsOfUrl,
  getValueReferenceValueExtensionsOfUrl,
  getValueRefValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { dispatch } from 'd3'
import { searchObservations } from 'redux/lab/observationFinder/observationFinderSlice'
import _ from 'lodash'
import { getLoincCodeFromPlanDef } from 'utils/patientHelper/cds_helper'
import { IOrganization } from '@ahryman40k/ts-fhir-types/lib/R4'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import {
  ObsDataWithVal,
  ObsServiceData,
  ObsServiceDataWithVal,
} from 'models/obsServiceData'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import moment from 'moment'
import { FHIRDefaultApiClient } from 'services/fhirDefaultServices'
import { FHIRWithMasterApiClient } from 'services/FHIRClientWithMaster'
import { getVitalsObservationObjectForLabResults } from './observationHelpers'

export const getLabOfferingDetailsFromBundle = (
  inputBundle: R4.IBundle,
  existingPlan: R4.IPlanDefinition[]
): LabOfferingDetail[] | undefined => {
  logger.info(
    '-------------------Inside distillation function------------------------'
  )
  const labOfferingsList: LabOfferingDetail[] = []
  const planDefinitions: any = {}
  const chargeDefinitions: Map<string, R4.IChargeItemDefinition> = new Map<
    string,
    R4.IChargeItemDefinition
  >()

  if (inputBundle.total) {
    if (inputBundle.total > 0) {
      if (inputBundle.entry) {
        const entries: R4.IBundle_Entry[] = inputBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'PlanDefinition':
                  planDefinitions[value.resource.id] =
                    value.resource as R4.IPlanDefinition
                  break
                case 'ChargeItemDefinition':
                  chargeDefinitions.set(
                    value.resource.id,
                    value.resource as R4.IChargeItemDefinition
                  )
                  logger.info('ChargeDefinitions', chargeDefinitions)
                  break

                default:
                  break
              }
            }
          }
        })
        logger.info(
          '-------------------Inside distillation function middle------------------------'
        )
        if (planDefinitions) {
          const filterPans: R4.IPlanDefinition[] = []
          for (const key in planDefinitions) {
            if (key) {
              const currentPD: R4.IPlanDefinition = planDefinitions[key]

              const practId: string | undefined = getExtensionValueOfUrl(
                currentPD.extension,
                'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
              )?.valueReference?.reference?.split('/')[1]

              const currentPract: R4.IChargeItemDefinition | undefined =
                chargeDefinitions.get(practId ?? '')

              labOfferingsList.push({
                id: currentPD.id ?? '',
                name: currentPD.name ?? '',

                planDefinition: currentPD,
                chargeItem: currentPract,
              })
            }
          }

          return labOfferingsList
        }
      }
    }
  }
  logger.info(
    '-------------------Inside distillation function error------------------------'
  )
  return undefined
}

export const getObServationDisplayData = (
  inputBundle: R4.IBundle
): LabObsData | undefined => {
  logger.info(
    '-------------------Inside distillation function------------------------'
  )
  const obsData: any = {}
  const obServationList: string[] = []
  const obServationDefList: R4.IObservationDefinition[] = []
  const activityDefinition: any = {}
  const obServation: any = {}

  if (inputBundle.total) {
    if (inputBundle.total > 0) {
      if (inputBundle.entry) {
        const entries: R4.IBundle_Entry[] = inputBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ActivityDefinition':
                  activityDefinition[value.resource.id] =
                    value.resource as R4.IActivityDefinition
                  break
                case 'ObservationDefinition':
                  obServation[value.resource.id] =
                    value.resource as R4.IObservationDefinition
                  logger.info('ChargeDefinitions', obServation)
                  break

                default:
                  break
              }
            }
          }
        })
        logger.info(
          '-------------------Inside distillation function middle------------------------'
        )
        if (activityDefinition) {
          for (const key in activityDefinition) {
            if (key) {
              const currentAc: R4.IActivityDefinition = activityDefinition[key]
              const referenceCode: R4.IReference[] =
                currentAc.observationResultRequirement ?? []

              for (const key1 in obServation) {
                if (key1) {
                  const ob: R4.IObservationDefinition = obServation[key1]
                  if (referenceCode.length > 0) {
                    for (let i = 0; i < referenceCode.length; i++) {
                      const ref = referenceCode[i].reference?.split('/')[1]
                      if (ob.id === ref) {
                        obServationDefList.push(ob)
                        if (ob.code.coding) {
                          for (let j = 0; j < ob.code.coding.length; j++) {
                            obServationList.push(
                              ob.code.coding[j].display ?? ''
                            )
                          }
                        }
                      }
                    }
                  }
                }
              }
              return {
                obsString: obServationList,
                obsDef: obServationDefList,
                activityDef: activityDefinition[key],
              }
            }
          }
        }
      }
    }
  }
  logger.info(
    '-------------------Inside distillation function error------------------------'
  )
  return undefined
}

export const getObServationDataTable = (
  inputBundle: R4.IBundle,
  edit?: boolean,
  searchString?: string
): ObsDataTableResource[] => {
  logger.info(
    '-------------------Inside distillation function------------------------'
  )
  const activityDefinition: any = {}
  const obServation: any = {}
  const obServationArrData: R4.IObservationDefinition[] = []
  const finalList: ObsList[] = []
  const tabArray: ObsDataTableResource[] = []

  if (inputBundle.total) {
    if (inputBundle.total > 0) {
      if (inputBundle.entry) {
        const entries: R4.IBundle_Entry[] = inputBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ActivityDefinition':
                  activityDefinition[value.resource.id] =
                    value.resource as R4.IActivityDefinition
                  break
                case 'ObservationDefinition':
                  obServationArrData.push(
                    value.resource as R4.IObservationDefinition
                  )

                  obServation[value.resource.id] =
                    value.resource as R4.IObservationDefinition
                  logger.info('ChargeDefinitions', obServation)
                  break

                default:
                  break
              }
            }
          }
        })
        logger.info(
          '-------------------Inside distillation function middle------------------------'
        )
      }
    }
  }
  logger.info(
    '-------------------Inside distillation function error------------------------'
  )
  for (let i = 0; i < obServationArrData.length; i++) {
    const intervalData = getInterValData(obServationArrData[i])

    if (intervalData.length === 1) {
      const obsFinal: ObsDataTableResource = {
        obsInitial: obServationArrData[i],
        obsName: edit
          ? obServationArrData[i].preferredReportName ?? ''
          : getDefaultDisplayOfSystemFromCodableConcept(
              obServationArrData[i].code
            ) ?? '',
        obsCode:
          getDefaultCodeOfSystemFromCodableConcept(
            obServationArrData[i].code
          ) ?? '',
        permittedDataType: getPermittedDataType(obServationArrData[i]),
        multipleResultsAllowed:
          obServationArrData[i].multipleResultsAllowed ?? false,
        unitCode: getDataUnit(obServationArrData[i]),
        decimalPrecision: getDecimalPrecison(obServationArrData[i]),
        high1: intervalData[0].high,
        low1: intervalData[0].low,
        highCode1: intervalData[0].highCode,
        lowCode1: intervalData[0].lowCode,
        gender1:
          intervalData[0].gender.length > 0 ? intervalData[0].gender : 'All',
        ageHigh1: intervalData[0].ageHigh,
        ageLow1: parseInt(intervalData[0].ageLow.toString(), 10),
        ageHighUnit1: intervalData[0].ageHighUnit.length > 0 ? 'a' : 'a',
        ageLowUnit1: intervalData[0].ageLowUnit.length > 0 ? 'a' : 'a',
        active: true,
        id: i,
        interpretation:
          getStringValueExtensionsOfUrl(
            obServationArrData[i].extension ?? [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-observation-def-interpretation-ext'
          ) ?? '',
        notes: getAnnotationValueExtensionsOfUrl(
          obServationArrData[i].extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-notes-ext'
        ),
        comment: getAnnotationValueExtensionsOfUrl(
          obServationArrData[i].extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-comments-ext'
        ),
      }
      tabArray.push(obsFinal)
    }
    if (intervalData.length === 0) {
      const obsFinal: ObsDataTableResource = {
        obsInitial: obServationArrData[i],
        obsName: edit
          ? obServationArrData[i].preferredReportName ?? ''
          : getDefaultDisplayOfSystemFromCodableConcept(
              obServationArrData[i].code
            ) ?? '',
        obsCode:
          getDefaultCodeOfSystemFromCodableConcept(
            obServationArrData[i].code
          ) ?? '',
        permittedDataType: getPermittedDataType(obServationArrData[i]),
        multipleResultsAllowed:
          obServationArrData[i].multipleResultsAllowed ?? false,
        unitCode: '',
        decimalPrecision: 0,
        high1: 0,
        low1: 0,
        highCode1: '',
        lowCode1: '',
        gender1: 'All',
        ageHigh1: 0,
        ageLow1: 0,
        ageHighUnit1: 'a',
        ageLowUnit1: 'a',
        active: true,
        id: i,
        interpretation:
          getStringValueExtensionsOfUrl(
            obServationArrData[i].extension ?? [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-observation-def-interpretation-ext'
          ) ?? '',
        notes: getAnnotationValueExtensionsOfUrl(
          obServationArrData[i].extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-notes-ext'
        ),
        comment: getAnnotationValueExtensionsOfUrl(
          obServationArrData[i].extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-comments-ext'
        ),
      }
      tabArray.push(obsFinal)
    }

    if (intervalData.length === 2) {
      const obsFinal: ObsDataTableResource = {
        obsInitial: obServationArrData[i],
        obsName: edit
          ? obServationArrData[i].preferredReportName ?? ''
          : getDefaultDisplayOfSystemFromCodableConcept(
              obServationArrData[i].code
            ) ?? '',
        obsCode:
          getDefaultCodeOfSystemFromCodableConcept(
            obServationArrData[i].code
          ) ?? '',
        permittedDataType: getPermittedDataType(obServationArrData[i]),
        multipleResultsAllowed:
          obServationArrData[i].multipleResultsAllowed ?? false,
        unitCode: getDataUnit(obServationArrData[i]),
        decimalPrecision: getDecimalPrecison(obServationArrData[i]),
        high1: intervalData[0].high,
        low1: intervalData[0].low,
        highCode1: intervalData[0].highCode,
        lowCode1: intervalData[0].lowCode,
        gender1:
          intervalData[0].gender.length > 0 ? intervalData[0].gender : 'All',
        ageHigh1: intervalData[0].ageHigh,
        ageLow1: intervalData[0].ageLow,
        ageHighUnit1: intervalData[0].ageHighUnit,
        ageLowUnit1: intervalData[0].ageLowUnit,

        high2: intervalData[1].high,
        low2: intervalData[1].low,
        highCode2: intervalData[1].highCode,
        lowCode2: intervalData[1].lowCode,
        gender2:
          intervalData[1].gender.length > 0 ? intervalData[1].gender : 'All',
        ageHigh2: intervalData[1].ageHigh,
        ageLow2: parseInt(intervalData[1].ageLow.toString(), 10),
        ageHighUnit2: intervalData[1].ageHighUnit.length > 0 ? 'a' : 'a',
        ageLowUnit2: intervalData[1].ageLowUnit.length > 0 ? 'a' : 'a',
        active: true,
        id: i,
        interpretation:
          getStringValueExtensionsOfUrl(
            obServationArrData[i].extension ?? [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-observation-def-interpretation-ext'
          ) ?? '',
        notes: getAnnotationValueExtensionsOfUrl(
          obServationArrData[i].extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-notes-ext'
        ),
        comment: getAnnotationValueExtensionsOfUrl(
          obServationArrData[i].extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-comments-ext'
        ),
      }
      tabArray.push(obsFinal)
    }

    // const obs: ObsDataTable = {
    //   obsName:
    //     getDefaultDisplayOfSystemFromCodableConcept(
    //       obServationArrData[i].code
    //     ) ?? '',
    //   obsCode:
    //     getDefaultCodeOfSystemFromCodableConcept(obServationArrData[i].code) ??
    //     '',
    //   permittedDataType: getPermittedDataType(obServationArrData[i]),
    //   multipleResultsAllowed: obServationArrData[i].multipleResultsAllowed
    //     ? obServationArrData[i].multipleResultsAllowed ?? false
    //     : false,
    //   unitCode: getDataUnit(obServationArrData[i]),
    //   decimalPrecision: getDecimalPrecison(obServationArrData[i]),
    //   intervalData: getInterValData(obServationArrData[i]),
    //   obsDef: obServationArrData[i],
    // }

    // const finalObj: ObsList = {
    //   obsDef: obServationArrData[i],
    //   obsArray: obs,
    // }
    // finalList.push(finalObj)
  }
  return tabArray
}

export const getObServationData = (
  inputBundle: R4.IBundle
): R4.IObservationDefinition[] => {
  logger.info(
    '-------------------Inside distillation function------------------------'
  )
  const obsData: any = {}
  const obsFinalData: R4.IObservationDefinition[] = []
  const obServationList: string[] = []
  const obServationDefList: R4.IObservationDefinition[] = []
  const activityDefinition: any = {}
  const obServation: any = {}
  const obServationArrData: R4.IObservationDefinition[] = []

  if (inputBundle.total) {
    if (inputBundle.total > 0) {
      if (inputBundle.entry) {
        const entries: R4.IBundle_Entry[] = inputBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ActivityDefinition':
                  activityDefinition[value.resource.id] =
                    value.resource as R4.IActivityDefinition
                  break
                case 'ObservationDefinition':
                  obServationArrData.push(
                    value.resource as R4.IObservationDefinition
                  )

                  obServation[value.resource.id] =
                    value.resource as R4.IObservationDefinition
                  logger.info('ChargeDefinitions', obServation)
                  break

                default:
                  break
              }
            }
          }
        })
        logger.info(
          '-------------------Inside distillation function middle------------------------'
        )
        if (activityDefinition) {
          for (const key in activityDefinition) {
            if (key) {
              const currentAc: R4.IActivityDefinition = activityDefinition[key]
              const referenceCode: R4.IReference[] =
                currentAc.observationResultRequirement ?? []

              for (const key1 in obServation) {
                if (key1) {
                  const ob: R4.IObservationDefinition = obServation[key1]
                  if (referenceCode.length > 0) {
                    for (let i = 0; i < referenceCode.length; i++) {
                      const ref = referenceCode[i].reference?.split('/')[1]
                      if (ob.id === ref) {
                        obServationDefList.push(ob)
                        obsFinalData.push(ob)
                        if (ob.code.coding) {
                          for (let j = 0; j < ob.code.coding.length; j++) {
                            obServationList.push(
                              ob.code.coding[j].display ?? ''
                            )
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  logger.info(
    '-------------------Inside distillation function error------------------------'
  )
  return obServationArrData
}

export const getMasterData = (
  inputBundle: R4.IBundle
): R4.IPlanDefinition[] | undefined => {
  logger.info(
    '-------------------Inside distillation function------------------------'
  )
  const labOfferingsList: R4.IPlanDefinition[] = []
  const planDefinitions: any = {}
  const chargeDefinitions: Map<string, R4.IChargeItemDefinition> = new Map<
    string,
    R4.IChargeItemDefinition
  >()

  if (inputBundle.total) {
    if (inputBundle.total > 0) {
      if (inputBundle.entry) {
        const entries: R4.IBundle_Entry[] = inputBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'PlanDefinition':
                  planDefinitions[value.resource.id] =
                    value.resource as R4.IPlanDefinition
                  break
                case 'ChargeItemDefinition':
                  chargeDefinitions.set(
                    value.resource.id,
                    value.resource as R4.IChargeItemDefinition
                  )
                  logger.info('ChargeDefinitions', chargeDefinitions)
                  break

                default:
                  break
              }
            }
          }
        })
        logger.info(
          '-------------------Inside distillation function middle------------------------'
        )
        if (planDefinitions) {
          for (const key in planDefinitions) {
            if (key) {
              const currentPD: R4.IPlanDefinition = planDefinitions[key]
              labOfferingsList.push(currentPD)
            }
          }

          return labOfferingsList
        }
      }
    }
  }
  logger.info(
    '-------------------Inside distillation function error------------------------'
  )
  return undefined
}

export function getPriceComponentFromChangeItem(
  chargeItem: R4.IChargeItemDefinition,
  type?: String
): R4.IChargeItemDefinition_PriceComponent | undefined {
  if (chargeItem.propertyGroup && chargeItem.propertyGroup.length > 0) {
    let priceComponent: R4.IChargeItemDefinition_PriceComponent | undefined =
      chargeItem.propertyGroup[0].priceComponent?.[0]
    if (type) {
      chargeItem.propertyGroup.map((e) => {
        if (e.priceComponent && e.priceComponent) {
          const p: R4.IChargeItemDefinition_PriceComponent | undefined =
            e.priceComponent.find((price) => price.type === type)
          if (p) {
            priceComponent = p
          }
        }
      })
    }

    if (priceComponent) return priceComponent
  }

  return undefined
}

export function getTotalAmountOfLabOfferings(
  selectedLabOfferings: LabOfferingDetail[]
): number {
  let total: number = 0

  selectedLabOfferings.forEach((e) => {
    if (e.chargeItem) {
      const priceComponent:
        | R4.IChargeItemDefinition_PriceComponent
        | undefined = getPriceComponentFromChangeItem(e.chargeItem)

      if (
        priceComponent &&
        priceComponent.amount &&
        priceComponent.amount.value
      ) {
        total += priceComponent.amount.value
      }
    }
  })

  return total
}

export function getTotalAmountOfPartnerLabOfferings(
  selectedLabOfferings: LabOfferingDetail[],
  percentage: number,
  partnerLabTask?: R4.ITask
): number {
  let partnerTotal: number = 0
  if (partnerLabTask) {
    if (partnerLabTask.input) {
      partnerTotal = partnerLabTask.input[0].valueInteger ?? 0
    } else {
      const total = getTotalAmountOfLabOfferings(selectedLabOfferings)
      const subTotal = (total * percentage) / 100
      partnerTotal = total - subTotal
    }
  } else {
    const total = getTotalAmountOfLabOfferings(selectedLabOfferings)
    const subTotal = (total * percentage) / 100
    partnerTotal = total - subTotal
  }
  return Math.round(partnerTotal)
}

export function getDocumentReferencesStrings(
  partnerLabTask?: R4.ITask
): string[] {
  const references: string[] = []
  if (partnerLabTask) {
    if (partnerLabTask.output) {
      for (let i = 0; i < partnerLabTask.output.length; i++) {
        references.push(partnerLabTask.output[i].valueString ?? '')
      }
    }
  }
  return references
}

export function getRestrictionsFromLabOfferings(
  selectedLabOfferings: LabOfferingDetail[]
): Map<String, String> {
  const restrictions: Map<String, String> = new Map()
  selectedLabOfferings.map((e) => {
    const ext: R4.IExtension | undefined = getExtensionValueOfUrl(
      e.planDefinition.extension,
      'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/LimitationSummary'
    )

    if (ext && ext.valueString) {
      if (!restrictions.has(e.name)) {
        restrictions.set(e.planDefinition.title ?? e.name, ext.valueString)
      }
    }
  })

  return restrictions
}

export function getHvServiceForReferralList(
  labOfferingList: LabOfferingDetail[],
  unitArr: R4.IHealthcareService[]
): LabReferralService[] {
  const services: LabReferralService[] = []
  for (let i = 0; i < labOfferingList.length; i++) {
    if (labOfferingList[i].planDefinition.extension) {
      const unitId: string | undefined = getValueReferenceValueExtensionsOfUrl(
        labOfferingList[i].planDefinition.extension,
        'http://wellopathy.com/fhir/india/core/StructureDefinition/PlanDefinitionManagingOrganization'
      )

      if (unitId) {
        const unitData = unitId.split('/')[1]
        for (let j = 0; j < unitArr.length; j++) {
          if (
            unitArr[j].providedBy &&
            unitArr[j].active &&
            unitArr[j].active === true
          ) {
            const { providedBy } = unitArr[j]
            if (providedBy) {
              if (providedBy.reference) {
                const ref = providedBy.reference ?? ''
                if (ref.split('/')[1] === unitData) {
                  services.push({
                    name: providedBy.display ?? '',
                    healthService: unitArr[j],
                    planDefinition: labOfferingList[i].planDefinition,
                  })
                }
              }
            }
          }
        }
      }
    }
  }

  return services
}

export const getObServationArrangedData = (
  inputBundle: R4.IObservationDefinition[]
): R4.IObservationDefinition[] => {
  const obServationArrData: R4.IObservationDefinition[] = []

  const indexOfBodyTemp = inputBundle.findIndex(
    (e) => e.preferredReportName === 'Colour'
  )
  if (indexOfBodyTemp > -1) {
    obServationArrData.push(inputBundle[indexOfBodyTemp])
  }

  const index2 = inputBundle.findIndex(
    (e) => e.preferredReportName === 'Appearance'
  )
  if (index2 > -1) {
    obServationArrData.push(inputBundle[index2])
  }

  const index3 = inputBundle.findIndex(
    (e) => e.preferredReportName === 'Clarity'
  )
  if (index3 > -1) {
    obServationArrData.push(inputBundle[index3])
  }

  const index4 = inputBundle.findIndex((e) => e.preferredReportName === 'Odour')
  if (index4 > -1) {
    obServationArrData.push(inputBundle[index4])
  }

  const index5 = inputBundle.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('5811-5')
  )

  //   const index5 = inputBundle.findIndex(
  //     (e) => e.preferredReportName === 'Gravity'
  //   )

  if (index5 > -1) {
    obServationArrData.push(inputBundle[index5])
  }
  const index6 = inputBundle.findIndex((e) => e.preferredReportName === 'pH')
  if (index6 > -1) {
    obServationArrData.push(inputBundle[index6])
  }
  const index7 = inputBundle.findIndex(
    (e) => e.preferredReportName === 'Protein'
  )
  if (index7 > -1) {
    obServationArrData.push(inputBundle[index7])
  }

  const index8 = inputBundle.findIndex(
    (e) => e.preferredReportName === 'Glucose'
  )
  if (index8 > -1) {
    obServationArrData.push(inputBundle[index8])
  }

  const index9 = inputBundle.findIndex(
    (e) => e.preferredReportName === 'Ketones'
  )
  if (index9 > -1) {
    obServationArrData.push(inputBundle[index9])
  }
  const index10 = inputBundle.findIndex(
    (e) => e.preferredReportName === 'Presence of Blood'
  )
  if (index10 > -1) {
    obServationArrData.push(inputBundle[index10])
  }

  const index11 = inputBundle.findIndex(
    (e) => e.preferredReportName === 'Nitrite, Urine'
  )
  if (index11 > -1) {
    obServationArrData.push(inputBundle[index11])
  }
  const index12 = inputBundle.findIndex(
    (e) => e.preferredReportName === 'Leukocyte esterase'
  )
  if (index12 > -1) {
    obServationArrData.push(inputBundle[index12])
  }
  const index13 = inputBundle.findIndex(
    (e) => e.preferredReportName === 'Bilirubin'
  )
  if (index13 > -1) {
    obServationArrData.push(inputBundle[index13])
  }
  const index14 = inputBundle.findIndex(
    (e) => e.preferredReportName === 'Urobilinogen'
  )
  if (index14 > -1) {
    obServationArrData.push(inputBundle[index14])
  }
  const index15 = inputBundle.findIndex(
    (e) => e.preferredReportName === 'Reducing substances'
  )
  if (index15 > -1) {
    obServationArrData.push(inputBundle[index15])
  }
  return obServationArrData
}

function getMinError(valData: R4.IObservationDefinition, patient: R4.IPatient) {
  let lowVal: number | undefined = 0

  if (valData.qualifiedInterval && valData.qualifiedInterval.length > 0) {
    for (let i = 0; i < valData.qualifiedInterval.length; i++) {
      if (valData.qualifiedInterval[i].gender) {
        if (
          valData.qualifiedInterval[i].gender === getGenderOfPatient(patient)
        ) {
          lowVal = valData.qualifiedInterval[i].range
            ? valData.qualifiedInterval[i].range?.low?.value
            : undefined
        }
      } else {
        lowVal = valData.qualifiedInterval[i].range
          ? valData.qualifiedInterval[i].range?.low?.value
          : 0
      }
    }
  }

  return lowVal
}

function getPermittedDataType(valData: R4.IObservationDefinition) {
  let dataType: string = ''
  const qtyData: string[] = []
  if (valData.permittedDataType && valData.permittedDataType.length > 0) {
    for (let i = 0; i < valData.permittedDataType.length; i++) {
      qtyData.push(valData.permittedDataType[i])
    }
  }
  dataType = qtyData.join(', ')
  return dataType
}

function getDataUnit(valData: R4.IObservationDefinition) {
  let dataType: string = ''

  if (valData.quantitativeDetails) {
    if (
      valData.quantitativeDetails.unit &&
      valData.quantitativeDetails.unit.coding
    ) {
      dataType = valData.quantitativeDetails.unit.coding[0].code ?? ''
    }
  }
  return dataType
}

function getDecimalPrecison(valData: R4.IObservationDefinition) {
  let dataType: number = 0
  if (valData.quantitativeDetails) {
    dataType = valData.quantitativeDetails.decimalPrecision ?? 0
  }
  return dataType
}

function getInterValData(valData: R4.IObservationDefinition) {
  const interval: QualifiedInterval[] = []

  if (valData.qualifiedInterval) {
    for (let i = 0; i < valData.qualifiedInterval.length; i++) {
      let high: number = 0
      let highUnit: string = ''
      let low: number = 0
      let lowUnit: string = ''
      let gender: string = ''
      let highAge: number = 0
      let highAgeUnit: string = ''
      let lowAge: number = 0
      let lowAgeUnit: string = ''
      if (valData.qualifiedInterval[i].range) {
        if (valData.qualifiedInterval[i].range?.high) {
          high = valData.qualifiedInterval[i].range?.high?.value ?? 0
          highUnit = valData.qualifiedInterval[i].range?.high?.code ?? ''
        }

        if (valData.qualifiedInterval[i].range?.low) {
          low = valData.qualifiedInterval[i].range?.low?.value ?? 0
          lowUnit = valData.qualifiedInterval[i].range?.low?.code ?? ''
        }
      }
      if (valData.qualifiedInterval[i].gender) {
        gender = valData.qualifiedInterval[i].gender ?? ''
      }

      if (valData.qualifiedInterval[i].age) {
        if (valData.qualifiedInterval[i].age?.high) {
          highAge = valData.qualifiedInterval[i].age?.high?.value ?? 0
          highAgeUnit = valData.qualifiedInterval[i].age?.high?.code ?? 'a'
        }
        if (valData.qualifiedInterval[i].age?.low) {
          lowAge = valData.qualifiedInterval[i].age?.low?.value ?? 0
          lowAgeUnit = valData.qualifiedInterval[i].age?.low?.code ?? 'a'
        }
      }

      interval.push({
        high,
        low,
        highCode: highUnit,
        lowCode: lowUnit,
        gender,
        ageHigh: highAge,
        ageLow: lowAge,
        ageHighUnit: highAgeUnit,
        ageLowUnit: lowAgeUnit,
      })
    }
  }
  return interval
}

function getHighError(
  valData: R4.IObservationDefinition,
  patient: R4.IPatient
) {
  let highVal: number | undefined
  if (valData.qualifiedInterval && valData.qualifiedInterval.length > 0) {
    for (let i = 0; i < valData.qualifiedInterval.length; i++) {
      if (valData.qualifiedInterval[i].gender) {
        if (
          valData.qualifiedInterval[i].gender === getGenderOfPatient(patient)
        ) {
          highVal = valData.qualifiedInterval[i].range
            ? valData.qualifiedInterval[i].range?.high?.value
            : 0
        }
      } else {
        highVal = valData.qualifiedInterval[i].range
          ? valData.qualifiedInterval[i].range?.high?.value
          : 0
      }
    }
  }

  return highVal
}

export const getErrorStatus = (
  inputBundle: R4.IObservationDefinition,
  patient: R4.IPatient,
  value: number
): boolean => {
  if (
    getMinError(inputBundle, patient) !== undefined &&
    getHighError(inputBundle, patient) !== undefined
  ) {
    const minVal = getMinError(inputBundle, patient)!
    const maxVal = getHighError(inputBundle, patient)!
    if (value < minVal || value > maxVal) {
      return true
    }
  } else if (
    getMinError(inputBundle, patient) !== undefined &&
    getHighError(inputBundle, patient) === undefined
  ) {
    const minVal = getMinError(inputBundle, patient)!
    if (value < minVal) {
      return true
    }
  } else if (
    getMinError(inputBundle, patient) === undefined &&
    getHighError(inputBundle, patient) !== undefined
  ) {
    const maxVal = getHighError(inputBundle, patient)!
    if (value > maxVal) {
      return true
    }
  } else if (Number.isNaN(value)) {
    return true
  }
  return false
}

export async function deleteObservationDef(
  labTestData: ObsDataTableResource,
  plan?: R4.IPlanDefinition
): Promise<boolean> {
  const patDetails: ObsDataTableResource = labTestData
  const oldObs: R4.IObservationDefinition = {
    ...patDetails.obsInitial,
  }
  const oldAppointmentMatchString: string = `W/${JSON.stringify(
    oldObs.meta?.versionId ?? ' '
  )}`

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `${oldObs.resourceType}/${oldObs.id}`,
        request: {
          ifMatch: oldAppointmentMatchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `${oldObs.resourceType}/${oldObs.id}`,
        },
        resource: oldObs,
      },
    ],
  }

  oldObs.permittedDataType = undefined

  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response = await fhirApi.doCreateFHIRTransaction('', requestBundle)

  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const orgResponse: R4.IBundle = relatedFhirDecodeRes.right
    if (orgResponse.total) {
      if (orgResponse.total > 0) {
        if (orgResponse.entry) {
          return true
        }
      }
    }
  }
  return false
}

export const getUpdatedTableData = (
  inputBundle: R4.IObservationDefinition,
  patient: R4.IPatient,
  value: number
): boolean => {
  if (
    getMinError(inputBundle, patient) !== undefined &&
    getHighError(inputBundle, patient) !== undefined
  ) {
    const minVal = getMinError(inputBundle, patient)!
    const maxVal = getHighError(inputBundle, patient)!
    if (value < minVal || value > maxVal) {
      return true
    }
  } else if (
    getMinError(inputBundle, patient) !== undefined &&
    getHighError(inputBundle, patient) === undefined
  ) {
    const minVal = getMinError(inputBundle, patient)!
    if (value < minVal) {
      return true
    }
  } else if (
    getMinError(inputBundle, patient) === undefined &&
    getHighError(inputBundle, patient) !== undefined
  ) {
    const maxVal = getHighError(inputBundle, patient)!
    if (value > maxVal) {
      return true
    }
  } else if (Number.isNaN(value)) {
    return true
  }
  return false
}

export const getCodeListFromBundleObs = (
  inputBundle: R4.IBundle,
  searchString?: string
): R4.ICoding[] => {
  logger.info(
    '-------------------Inside distillation function------------------------'
  )

  const activityDefinition: any = {}
  const obServation: any = {}
  const obServationArrData: R4.IObservationDefinition[] = []
  let finalList: R4.ICoding[] = []
  const tabArray: ObsDataTableResource[] = []

  if (inputBundle.total) {
    if (inputBundle.total > 0) {
      if (inputBundle.entry) {
        const entries: R4.IBundle_Entry[] = inputBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ActivityDefinition':
                  activityDefinition[value.resource.id] =
                    value.resource as R4.IActivityDefinition
                  break
                case 'ObservationDefinition':
                  obServationArrData.push(
                    value.resource as R4.IObservationDefinition
                  )

                  obServation[value.resource.id] =
                    value.resource as R4.IObservationDefinition
                  logger.info('ChargeDefinitions', obServation)
                  break

                default:
                  break
              }
            }
          }
        })
        logger.info(
          '-------------------Inside distillation function middle------------------------'
        )
      }
    }
  }
  logger.info(
    '-------------------Inside distillation function error------------------------'
  )
  for (let i = 0; i < obServationArrData.length; i++) {
    const codeData =
      getDefaultDisplayOfSystemFromCodableConcept(obServationArrData[i].code) ??
      ''

    if (codeData.length > 0) {
      if (searchString) {
        if (codeData.includes(searchString)) {
          finalList.push({
            system: 'http://loinc.org',
            display: codeData.trim(),
            code: codeData.trim(),
          })
        }
      }
    }
  }

  finalList = finalList.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.display === value.display)
  )

  return finalList
}

export const getObServationDataTableForNewTest = (
  inputBundle: R4.IBundle,
  edit: boolean,
  searchString?: string
): ObsDataTableResource[] => {
  logger.info(
    '-------------------Inside distillation function------------------------'
  )

  const activityDefinition: any = {}
  const obServation: any = {}
  const obServationArrData: R4.IObservationDefinition[] = []
  const finalList: ObsList[] = []
  const tabArray: ObsDataTableResource[] = []

  if (inputBundle.total) {
    if (inputBundle.total > 0) {
      if (inputBundle.entry) {
        const entries: R4.IBundle_Entry[] = inputBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ActivityDefinition':
                  activityDefinition[value.resource.id] =
                    value.resource as R4.IActivityDefinition
                  break
                case 'ObservationDefinition':
                  obServationArrData.push(
                    value.resource as R4.IObservationDefinition
                  )

                  obServation[value.resource.id] =
                    value.resource as R4.IObservationDefinition
                  logger.info('ChargeDefinitions', obServation)
                  break

                default:
                  break
              }
            }
          }
        })
        logger.info(
          '-------------------Inside distillation function middle------------------------'
        )
      }
    }
  }
  logger.info(
    '-------------------Inside distillation function error------------------------'
  )
  for (let i = 0; i < obServationArrData.length; i++) {
    const intervalData = getInterValData(obServationArrData[i])

    if (intervalData.length === 1) {
      const obsFinal: ObsDataTableResource = {
        obsInitial: obServationArrData[i],
        obsName:
          edit === true
            ? obServationArrData[i].preferredReportName ?? ''
            : getDefaultDisplayOfSystemFromCodableConcept(
                obServationArrData[i].code
              ) ?? '',
        obsCode:
          getDefaultCodeOfSystemFromCodableConcept(
            obServationArrData[i].code
          ) ?? '',
        permittedDataType: getPermittedDataType(obServationArrData[i]),
        multipleResultsAllowed:
          obServationArrData[i].multipleResultsAllowed ?? false,
        unitCode: getDataUnit(obServationArrData[i]),
        decimalPrecision: getDecimalPrecison(obServationArrData[i]),
        high1: intervalData[0].high,
        low1: intervalData[0].low,
        highCode1: intervalData[0].highCode,
        lowCode1: intervalData[0].lowCode,
        gender1:
          intervalData[0].gender.length > 0 ? intervalData[0].gender : 'All',
        ageHigh1: intervalData[0].ageHigh,
        ageLow1: intervalData[0].ageLow,
        ageHighUnit1: intervalData[0].ageHighUnit,
        ageLowUnit1: intervalData[0].ageLowUnit,
        active: true,
        id: i,
        interpretation:
          getStringValueExtensionsOfUrl(
            obServationArrData[i].extension ?? [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-observation-def-interpretation-ext'
          ) ?? '',
        notes: getAnnotationValueExtensionsOfUrl(
          obServationArrData[i].extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-notes-ext'
        ),
        comment: getAnnotationValueExtensionsOfUrl(
          obServationArrData[i].extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-comments-ext'
        ),
      }
      tabArray.push(obsFinal)
    }

    if (intervalData.length === 2) {
      const obsFinal: ObsDataTableResource = {
        obsInitial: obServationArrData[i],
        obsName: edit
          ? obServationArrData[i].preferredReportName ?? ''
          : getDefaultDisplayOfSystemFromCodableConcept(
              obServationArrData[i].code
            ) ?? '',
        obsCode:
          getDefaultCodeOfSystemFromCodableConcept(
            obServationArrData[i].code
          ) ?? '',
        permittedDataType: getPermittedDataType(obServationArrData[i]),
        multipleResultsAllowed:
          obServationArrData[i].multipleResultsAllowed ?? false,
        unitCode: getDataUnit(obServationArrData[i]),
        decimalPrecision: getDecimalPrecison(obServationArrData[i]),
        high1: intervalData[0].high,
        low1: intervalData[0].low,
        highCode1: intervalData[0].highCode,
        lowCode1: intervalData[0].lowCode,
        gender1:
          intervalData[0].gender.length > 0 ? intervalData[0].gender : 'All',
        ageHigh1: intervalData[0].ageHigh,
        ageLow1: intervalData[0].ageLow,
        ageHighUnit1: intervalData[0].ageHighUnit,
        ageLowUnit1: intervalData[0].ageLowUnit,

        high2: intervalData[1].high,
        low2: intervalData[1].low,
        highCode2: intervalData[1].highCode,
        lowCode2: intervalData[1].lowCode,
        gender2:
          intervalData[1].gender.length > 0 ? intervalData[1].gender : 'All',
        ageHigh2: intervalData[1].ageHigh,
        ageLow2: intervalData[1].ageLow,
        ageHighUnit2: intervalData[1].ageHighUnit,
        ageLowUnit2: intervalData[1].ageLowUnit,
        active: true,
        id: i,
        interpretation:
          getStringValueExtensionsOfUrl(
            obServationArrData[i].extension ?? [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-observation-def-interpretation-ext'
          ) ?? '',
        notes: getAnnotationValueExtensionsOfUrl(
          obServationArrData[i].extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-notes-ext'
        ),
        comment: getAnnotationValueExtensionsOfUrl(
          obServationArrData[i].extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-comments-ext'
        ),
      }
      tabArray.push(obsFinal)
    }

    // const obs: ObsDataTable = {
    //   obsName:
    //     getDefaultDisplayOfSystemFromCodableConcept(
    //       obServationArrData[i].code
    //     ) ?? '',
    //   obsCode:
    //     getDefaultCodeOfSystemFromCodableConcept(obServationArrData[i].code) ??
    //     '',
    //   permittedDataType: getPermittedDataType(obServationArrData[i]),
    //   multipleResultsAllowed: obServationArrData[i].multipleResultsAllowed
    //     ? obServationArrData[i].multipleResultsAllowed ?? false
    //     : false,
    //   unitCode: getDataUnit(obServationArrData[i]),
    //   decimalPrecision: getDecimalPrecison(obServationArrData[i]),
    //   intervalData: getInterValData(obServationArrData[i]),
    //   obsDef: obServationArrData[i],
    // }

    // const finalObj: ObsList = {
    //   obsDef: obServationArrData[i],
    //   obsArray: obs,
    // }
    // finalList.push(finalObj)
  }
  return tabArray
}

export function getTableData(
  valData: ObsDataTableResource[]
): ObsDataTableResource[] {
  const finalList: ObsDataTableResource[] = []

  for (let i = 0; i < valData.length; i++) {
    const data: ObsDataTableResource = {
      obsInitial: valData[i].obsInitial,
      obsName: valData[i].obsName,
      obsCode: valData[i].obsCode,
      permittedDataType: valData[i].permittedDataType,
      multipleResultsAllowed: valData[i].multipleResultsAllowed,
      unitCode: valData[i].unitCode,
      decimalPrecision: valData[i].decimalPrecision,
      high1: valData[i].high1,
      low1: valData[i].low1,
      highCode1: valData[i].highCode1,
      lowCode1: valData[i].lowCode1,
      gender1: valData[i].gender1,
      ageHigh1: valData[i].ageHigh1,
      ageLow1: valData[i].ageLow1,
      ageHighUnit1: valData[i].ageHighUnit1,
      ageLowUnit1: valData[i].ageLowUnit1,

      high2: valData[i].high2,
      low2: valData[i].low2,
      highCode2: valData[i].highCode2,
      lowCode2: valData[i].lowCode2,
      gender2: valData[i].gender2,
      ageHigh2: valData[i].ageHigh2,
      ageLow2: valData[i].ageLow2,
      ageHighUnit2: valData[i].ageHighUnit2,
      ageLowUnit2: valData[i].ageLowUnit2,
      active: true,
      id: i,
      interpretation: valData[i].interpretation,
      notes: valData[i].notes,
      comment: valData[i].comment,
    }
    finalList.push(data)
  }

  return finalList
}

export function getLabName(
  labService: R4.IHealthcareService,
  planDef: R4.IPlanDefinition
): string {
  let providerDetails: R4.IOrganization | undefined

  console.log(labService)
  if (labService.providedBy) {
    if (labService.providedBy!.reference) {
      const id = labService.providedBy!.reference ?? ''
      const searchId = id.split('/')[1]

      if (labService.contained && labService.contained!.length > 0) {
        const index = labService.contained?.findIndex((e) => e.id === searchId)
        providerDetails = labService.contained![index!] as IOrganization
      }
    }
  }

  return providerDetails === undefined ? '' : providerDetails.name ?? ''
}

export function getLabOrgName(
  labService: LabReferralService,
  planDef: R4.IPlanDefinition
): string {
  let providerDetails: R4.IOrganization | undefined
  if (
    getLoincCodeFromPlanDef(labService.planDefinition) ===
    getLoincCodeFromPlanDef(planDef)
  ) {
    if (labService.healthService.providedBy) {
      if (
        labService.healthService.providedBy!.reference &&
        labService.healthService.providedBy!.reference!.startsWith('#')
      ) {
        const id = labService.healthService.providedBy!.reference ?? ''
        const searchId = id.split('#')[1]
        if (
          labService.healthService.contained &&
          labService.healthService.contained!.length > 0
        ) {
          const index = labService.healthService.contained?.findIndex(
            (e) => e.id === searchId
          )
          providerDetails = labService.healthService.contained![
            index!
          ] as IOrganization
        }
      }
    }
  }

  return providerDetails === undefined ? '' : providerDetails.name ?? ''
}

export async function getPlanDefData(
  lionCode: string
): Promise<LabOfferingDetail[]> {
  let planDef: LabOfferingDetail[] = []
  const unitDetails = getCurrentUserUnitReference()!
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    _include: '*',
    status: 'active',
    _count: 100,
    _total: 'accurate',
  }
  if (lionCode) searchParameters.identifier = lionCode

  const response: any = await fhirClient.doGetResource(
    `/PlanDefinition?plan-def-organization=Organization/${unitDetails.id}`,
    searchParameters
  )

  if (response.entry) {
    if (response.entry.length > 0) {
      planDef = getLabOfferingDetailsFromBundle(response, []) ?? []
      planDef.sort((a, b) =>
        (a.planDefinition.title
          ? a.planDefinition.title.length === 0
            ? a.planDefinition.name ?? ''
            : a.planDefinition.title
          : a.planDefinition.name ?? ''
        ).localeCompare(
          b.planDefinition.title
            ? b.planDefinition.title
            : b.planDefinition.name ?? ''
        )
      )
    }
  }
  return planDef
}

export async function getLabResultsData(
  obsDefData: R4.IObservationDefinition,
  labOrderDetail: FhirLabOrderDetail
): Promise<ObsDataWithVal> {
  const finalData: ObsDataWithVal = {
    obsDef: obsDefData,
    obs: undefined,
    valInt: undefined,
    valStringData: undefined,
    checked: false,
  }
  const fhirClient: FHIRWithMasterApiClient = new FHIRWithMasterApiClient()

  const code = obsDefData.code.coding ?? []

  const response: any = await fhirClient.doGetResource(
    `/Observation?based-on=ServiceRequest/${labOrderDetail.serviceRequest.id}&code=${code[0].code}`
  )
  if (response.entry) {
    if (response.entry.length > 0) {
      const obsDataVal: R4.IObservation[] =
        response.entry?.map((item: any) => item.resource as R4.IObservation) ??
        []
      finalData.obs = obsDataVal[0]
      finalData.valInt = obsDataVal[0].valueQuantity
        ? obsDataVal[0].valueQuantity.value ?? 0
        : undefined
      finalData.valStringData = obsDataVal[0].valueString
        ? obsDataVal[0].valueString ?? ''
        : undefined
    }
  }
  return finalData
}

export async function getObsDefData(
  planDef: R4.IPlanDefinition,
  labOrderDetail: FhirLabOrderDetail
): Promise<ObsServiceDataWithVal> {
  const obsDefData: ObsServiceDataWithVal = {
    name: planDef.title ?? '',
    obsDef: [],
  }

  const obsDef: ObsDataWithVal[] = []
  const activityParmeter: string[] = []

  const fhirClient: FHIRApiClient = new FHIRApiClient()

  if (planDef.action) {
    const actionData = planDef.action[0]
    if (actionData.action) {
      for (let i = 0; i < actionData.action.length; i++) {
        if (actionData.action[i].definitionCanonical) {
          const reference = actionData.action[i].definitionCanonical
          if (reference) {
            const activeId = reference.split(
              'http://wellopathy.com/ActivityDefinition/'
            )[1]
            activityParmeter.push(activeId)
          }
        }
      }
    }
  }

  const searchParameters: any = {
    status: 'active',
    _count: 50,
  }
  const response: any = await fhirClient.doGetResourceForMasterData(
    `/ActivityDefinition?activitydef-code=${activityParmeter.toString()}&_include=ActivityDefinition:observationResultRequirement`,
    searchParameters
  )
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const labOfferingsResponse: R4.IBundle = relatedFhirDecodeRes.right
    if (labOfferingsResponse.total) {
      if (labOfferingsResponse.total > 0) {
        if (labOfferingsResponse.entry) {
          const obsGeneratedData = getObServationData(labOfferingsResponse)

          await Promise.all(
            obsGeneratedData.map(async (e) => {
              const data = await getLabResultsData(e, labOrderDetail)
              obsDef.push(data)
            })
          )

          obsDefData.obsDef = obsDef
        }
      }
    }
  }

  return obsDefData
}

export async function addObsData(
  obsData: ObsServiceDataWithVal,
  labOrderDetail: FhirLabOrderDetail
): Promise<boolean> {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }
  for (let i = 0; i < obsData.obsDef.length; i++) {
    if (obsData.obsDef[i].obs === undefined) {
      const observationObject: R4.IObservation = {
        ...getVitalsObservationObjectForLabResults(labOrderDetail),
      }

      observationObject.issued = moment().toISOString()
      observationObject.effectiveDateTime = moment().toISOString()
      observationObject.category = [
        {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/observation-category',
              code: 'laboratory',
              display: 'Laboratory',
            },
          ],
        },
      ]
      observationObject.code = {
        text: obsData.name,
        coding: obsData.obsDef[i].obsDef.code.coding ?? [],
      }
      observationObject.status = R4.ObservationStatusKind._final
      if (obsData.obsDef[i].valInt) {
        if (
          obsData.obsDef[i].obsDef.quantitativeDetails &&
          obsData.obsDef[i].obsDef.quantitativeDetails!.unit
        ) {
          observationObject.valueQuantity = {
            value: obsData.obsDef[i].valInt,
            unit: obsData.obsDef[i].obsDef.quantitativeDetails
              ? obsData.obsDef[i].obsDef.quantitativeDetails!.unit
                ? obsData.obsDef[i].obsDef.quantitativeDetails!.unit!.coding![0]
                    .code!
                : ''
              : '',
            system: 'http://unitsofmeasure.org',
            code: obsData.obsDef[i].obsDef.quantitativeDetails
              ? obsData.obsDef[i].obsDef.quantitativeDetails!.unit
                ? obsData.obsDef[i].obsDef.quantitativeDetails!.unit!.coding![0]
                    .code!
                : ''
              : '',
          }
        }
      } else {
        observationObject.valueString = obsData.obsDef[i].valStringData ?? ''
      }

      // observationObject.valueString = 'A'

      const entry: R4.IBundle_Entry = {
        fullUrl: `urn:uuid:Observation${i}`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: observationObject.resourceType,
        },
        resource: observationObject,
      }
      requestBundle.entry?.push(entry)
    } else {
      const observationObjectData: R4.IObservation = {
        ...obsData.obsDef[i].obs!,
      }
      const mainTaskMatchString: string = `W/${JSON.stringify(
        observationObjectData.meta?.versionId ?? ' '
      )}`

      if (obsData.obsDef[i].valInt) {
        if (
          obsData.obsDef[i].obsDef.quantitativeDetails &&
          obsData.obsDef[i].obsDef.quantitativeDetails!.unit
        ) {
          observationObjectData.valueQuantity = {
            value: obsData.obsDef[i].valInt,
            unit: obsData.obsDef[i].obsDef.quantitativeDetails
              ? obsData.obsDef[i].obsDef.quantitativeDetails!.unit
                ? obsData.obsDef[i].obsDef.quantitativeDetails!.unit!.coding![0]
                    .code!
                : ''
              : '',
            system: 'http://unitsofmeasure.org',
            code: obsData.obsDef[i].obsDef.quantitativeDetails
              ? obsData.obsDef[i].obsDef.quantitativeDetails!.unit
                ? obsData.obsDef[i].obsDef.quantitativeDetails!.unit!.coding![0]
                    .code!
                : ''
              : '',
          }
        }
      } else {
        observationObjectData.valueString =
          obsData.obsDef[i].valStringData ?? ''
      }

      const entry: R4.IBundle_Entry = {
        fullUrl: `${observationObjectData.resourceType}/${observationObjectData.id}`,
        request: {
          ifMatch: mainTaskMatchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `${observationObjectData.resourceType}/${observationObjectData.id}`,
        },
        resource: observationObjectData,
      }
      requestBundle.entry?.push(entry)
    }
  }

  const fhirClient: FHIRWithMasterApiClient = new FHIRWithMasterApiClient()
  const response = await fhirClient.doCreateFHIRTransaction('', requestBundle)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    return true
  }

  return false
}

export function getLabServiceDetails(
  labServiceArray: LabTabularService[],
  planDef: R4.IPlanDefinition[]
): LabTabularService[] {
  const servicesArray: LabTabularService[] = []
  for (let i = 0; i < planDef.length; i++) {
    for (let k = 0; k < labServiceArray.length; k++) {
      if (labServiceArray[k].planDefinition) {
        if (
          getIdentifierValueBySystem(
            planDef[i].identifier ?? [],
            'http://loinc.org'
          ) ===
          getIdentifierValueBySystem(
            labServiceArray[k].planDefinition!.identifier ?? [],
            'http://loinc.org'
          )
        ) {
          servicesArray.push(labServiceArray[k])
        }
      } else {
        servicesArray.push(labServiceArray[k])
      }
    }
  }

  return servicesArray
}
