export enum AuthRoutes {
  Dashboard = '/:vendor/dashboard',
  Settings = '/:vendor/settings',
  ViewAppointment = '/:vendor/viewAppointment',
  ViewIpdAppointment = '/:vendor/viewIpdAppointment',
  ViewOPDAppointment = '/:vendor/ViewOPDAppointment',
  Preferences = '/:vendor/preferences',
  Account = '/:vendor/account',
  Medicine = '/:vendor/medicine',
}

export enum NonAuthRoutes {
  Default = '/',
  Login = '/:vendor/login',
  Support = '/:vendor/support',
  Settings = '/:vendor/settings',
  AuthCallBack = '/authCallBack',
  Unauthorized = '/:vendor/unauthorized',
}
