import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { requestHabitsHistoryOfPatient } from 'redux/patientMedicalHistory/habitsHistory/habitsHistorySlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { sleep } from 'utils/dateUtil'
import { getVitalsObservationObjectForIPD } from 'utils/fhirResoureHelpers/observationHelpers'
// import { requestVitalDetailsOfPatientIPD } from '../vitalsDetailsIpd/vitalsDetailSliceIpd'
import { HabitAddStatus } from './habitAddStatus'

const initialState: HabitAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addHabitSliceIPD = createSlice({
  name: 'addHabitSliceIPD',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<HabitAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addedVitalsBundle = action.payload.addedVitalsBundle
    },

    resetAddHabitState(state, action: PayloadAction<HabitAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addedVitalsBundle = initialState.addedVitalsBundle
    },
  },
})

export const addHabits =
  (
    appointment: FhirActiveIPDDetailsForMedicalRole,
    excersise?: R4.ICoding,
    intensity?: R4.ICoding,
    energyLevel?: R4.ICoding,
    breatePattern?: R4.ICoding,
    sleepData?: R4.ICoding,
    cofee?: R4.ICoding,
    tea?: R4.ICoding,
    sleepDay?: R4.ICoding,
    others?: R4.ICoding
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: HabitAddStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(addHabitSliceIPD.actions.updateAddVitalsStatus(addingState))

    try {
      const bundleObject: R4.IBundle = createBundleObjectForObservations(
        appointment,
        excersise,
        intensity,
        energyLevel,
        breatePattern,
        sleepData,
        cofee,
        tea,
        sleepDay,
        others
      )

      const resource: any = {
        eventType: 'vitals',
        eventBody: bundleObject,
      }

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        bundleObject
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        await sleep(5000)

        dispatch(requestHabitsHistoryOfPatient('', appointment.patient, false))

        // dispatch(
        //   requestHabitsHistoryOfPatient(
        //     appointment.mainServiceRequest.id!,
        //     appointment.patient
        //   )
        // )
        dispatch(showSuccessAlert('Habits Added Successfully'))

        dispatch(addHabitSliceIPD.actions.updateAddVitalsStatus(addingState))
      } else {
        const errorCreatePersonState: HabitAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while adding habits',
        }
        dispatch(
          addHabitSliceIPD.actions.updateAddVitalsStatus(errorCreatePersonState)
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: HabitAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while adding habits',
      }
      dispatch(
        addHabitSliceIPD.actions.updateAddVitalsStatus(errorCreatePersonState)
      )
    }
  }

export const resetAddHabitState = () => (dispatch: AppDispatch) => {
  dispatch(addHabitSliceIPD.actions.resetAddHabitState(initialState))
}

function createBundleObjectForObservations(
  appointment: FhirActiveIPDDetailsForMedicalRole,
  excersise?: R4.ICoding,
  intensity?: R4.ICoding,
  energyLevel?: R4.ICoding,
  breatePattern?: R4.ICoding,
  sleepData?: R4.ICoding,
  cofee?: R4.ICoding,
  tea?: R4.ICoding,
  sleepDay?: R4.ICoding,
  others?: R4.ICoding
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const encounterRef: R4.IReference = {
    reference: `Encounter/urn:uuid:1232323232324`,
    type: 'Encounter',
  }

  if (excersise != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }

    observationObject.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationActivity',
      ],
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Exercise duration/Exercise frequency',
      coding: [
        {
          code: '74009-2',
          display: 'Exercise duration/Exercise frequency',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: excersise.display ?? '',
      coding: [excersise],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (intensity != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }
    observationObject.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationActivity',
      ],
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Exercise Intensity',
      coding: [
        {
          code: '74008-4',
          display: 'Exercise Intensity',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: intensity.display ?? '',
      coding: [intensity],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (tea != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }
    observationObject.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationActivity',
      ],
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Tea',
      coding: [
        {
          code: '10000-1',
          display: 'Tea',
          system: 'http://wellopathy.com/observation-codes',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: tea.display ?? '',
      coding: [tea],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (energyLevel != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationActivity',
      ],
    }
    observationObject.code = {
      text: 'Energy level in past 7 days',
      coding: [
        {
          code: '65515-9',
          display: 'Energy level in past 7 days',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: energyLevel.display ?? '',
      coding: [energyLevel],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (breatePattern != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationActivity',
      ],
    }

    observationObject.code = {
      text: 'Breathing pattern impairment',
      coding: [
        {
          code: '28148-5',
          display: 'Breathing pattern impairment',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: breatePattern.display ?? '',
      coding: [breatePattern],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (cofee != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }
    observationObject.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationActivity',
      ],
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Coffee',
      coding: [
        {
          code: '10000-2',
          display: 'Coffee',
          system: 'http://wellopathy.com/observation-codes',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: cofee.display ?? '',
      coding: [cofee],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (others != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationActivity',
      ],
    }
    observationObject.code = {
      text: 'Other',
      coding: [
        {
          code: 'other-habit',
          display: 'Other',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-habit-cs',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: others.display ?? '',
      coding: [others],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (sleepData != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationActivity',
      ],
    }
    observationObject.code = {
      text: 'Sleep',
      coding: [
        {
          code: '65968-0',
          display: 'How many hours do you normally sleep during day time',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: sleepData.display ?? '',
      coding: [sleepData],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (sleepDay != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationActivity',
      ],
    }
    observationObject.code = {
      text: 'Sleep',
      coding: [
        {
          code: '65561-3',
          display: 'How many hours do you normally sleep during night time',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: sleepDay.display ?? '',
      coding: [sleepDay],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  return requestBundle
}

export default addHabitSliceIPD.reducer
