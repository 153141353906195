import { Box, Dialog, makeStyles } from '@material-ui/core'
import React from 'react'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import Draggable from 'react-draggable'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import Typography from '@mui/material/Typography'
import { kBackgroundPaper, kPrimaryMain } from 'configs/styles/muiThemes'
import Close from '@material-ui/icons/Close'
import { CloseOutlined } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  pipWindow: {
    maxWidth: 1325,
    maxHeight: 'auto',
    backgroundColor: '#ffffff',
    variant: 'outlined',
  },
  dialogTitle: {
    paddingRight: '0px',
  },
}))

export default function PIP(props: any) {
  const { title, children, handleClose, setOpenPopup } = props
  const classes = useStyles()

  const { height, width } = useWindowDimensions()

  return (
    <Draggable
      defaultPosition={{
        x: width / 2 - width / 4,
        y: height / 2 - height / 4,
      }}
    >
      <div
        className='pipWindow'
        style={{
          maxWidth: 1325,
          maxHeight: 'auto',
          backgroundColor: kBackgroundPaper,

          overflow: 'hidden',
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
          width='100%'
          alignContent='center'
        >
          <Box
            width='100%'
            height='30px'
            display='flex'
            flexDirection='row'
            bgcolor={kPrimaryMain}
            alignContent='center'
            alignItems='center'
          >
            <Box width='100%' display='flex' flexGrow={1} px={1}>
              <Typography
                variant='subtitle2'
                color='initial'
                style={{
                  color: '#ffffff',
                }}
              >
                Call Window
              </Typography>
            </Box>
            <CloseOutlined
              style={{
                color: '#ffffff',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            />
          </Box>
          <Box>{children}</Box>
          <Box
            width='100%'
            height='30px'
            display='flex'
            flexDirection='row'
            bgcolor={kPrimaryMain}
            alignContent='center'
            alignItems='center'
          >
            <Box width='100%' display='flex' flexGrow={1} px={1} />
          </Box>
        </Box>
      </div>
    </Draggable>
  )
}
