import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  makeStyles,
  Tooltip,
  TextField,
  Grid,
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  Typography,
  Checkbox,
  Theme,
  useTheme,
} from '@material-ui/core'
import {
  CloseOutlined,
  Delete,
  DeleteOutlineOutlined,
} from '@material-ui/icons'
import {
  AssociatedComplaintData,
  ChiefComplaintData,
} from 'models/chiefComplaintData'
import {
  DatePicker,
  DateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { LocationData } from 'models/location'
import { UmlResponse } from 'models/umlResponse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetChiefComplaintDetails,
  updateChiefComplaintDetails,
} from 'redux/consultation/chiefComplaintSlice/chiefComplaintSlice'
import { RootState } from 'redux/rootReducer'
import {
  durationData,
  durationDataForChiefComplaints,
  durationDataForChiefComplaintsWithOne,
  durationDataWithOne,
} from 'utils/constants'
import {
  getAgeInDays,
  getAgeInMonths,
  getAgeInWeeks,
  getAgeInYears,
  getAgeOfPatientForChecking,
  getDefaultCodeOfSystemFromCodableConcept,
  titleCase,
} from 'utils/fhirResourcesHelper'
import { validateOthers } from 'utils/patientHelper/patientEditHelper'
import {
  ConfirmationDialogProps,
  ConfirmationDialog,
} from 'views/components/common/deleteConfirmationDialog'
import { WelloChiefComplaints } from 'views/components/lab/common/welloChiefComplaints'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { WarningDialog } from 'views/components/LeftMenu/warnigDialog'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloSelectUnit } from 'views/components/LeftMenu/welloSelectUnit'
import { WelloTextField } from 'wello-web-components'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import SimpleBar from 'simplebar-react'
import MomentUtils from '@date-io/moment'
import { validateCheckoutDateForChiefComplaints } from 'utils/formValidators'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { isAyurvedaDoctor } from 'services/userDetailsService'
import { getObservationForCodeCategoryComponentAndNotes } from 'utils/fhirResoureHelpers/observationHelpers'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { ChiefComplaintsSelector } from './chiefComplaintsMasterSelector'
import { ChiefComplaintsSelectorMultiple } from './chiefComplaintsMultipleSelector'
import { DurationMasterSelector } from './durationMasterSelector'
import { LocationMasterSelector } from './locationMasterSelector'
import { RadioSelectorForObservationComponent } from './chiefComplaints.tsx/radioSelector'

const availableOptions = [
  { display: 'Mild', code: 'mild' },
  { display: 'Moderate', code: 'moderate' },
  { display: 'Severe', code: 'severe' },
]
const useStyles = makeStyles((theme) => ({
  dialogCustomizedWidth: {
    'max-width': '45.9%',
  },
}))

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  onClose: () => void
  onConditionAdded: (fhirAppointmentDetails: FhirAppointmentDetail) => void
}
export const AddChiefComplanits: React.FC<Props> = ({
  open,
  onClose,
  fhirAppointmentDetails,
  onConditionAdded,
}) => {
  const { t } = useTranslation(['common', 'observation'])
  const classes = useStyles()
  const [selectedCondition, setSelectedCondition] = useState<R4.ICoding>()
  const [selectedPainProfileSymptoms, setSlectedPainProfileSymptoms] = useState<
    R4.ICoding[]
  >([])
  const [selectedSeverity, setSeverity] = useState<R4.ICoding>()
  const [selectedLocation, setLocations] = useState<R4.ICoding[]>([])
  const chiefComplaintSlice = useSelector(
    (state: RootState) => state.chiefComplaintSlice
  )
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date())
  const [chooseDate, setChooseDate] = React.useState<string>('')
  const [duration, setDuration] = React.useState<string>('Days')
  const [durationPain, setDurationPain] = React.useState<string>('Days')
  const [character, setCharacter] = React.useState<string>('')
  const [alleviating, setAlleviating] = React.useState<string>('')
  const [radiation, setRadiation] = React.useState<string>('')
  const [temporal, setTemporal] = React.useState<string>('')
  const [typeData, setTypeData] = useState('days')
  const [otherData, setOtherData] = React.useState<string>('')
  const [isActive, setIsActive] = useState<boolean>(false)
  const [additionalNotes, setAdditionalNotes] = useState<string>()
  const [patientWording, setPatientWording] = useState<string>('')
  const [other, setOther] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(false)
  const [associatdChecked, setAssociatedChecked] = useState<boolean>(false)
  const [days, setDays] = React.useState<number>(1)
  const [weeks, setWeeks] = React.useState<number>(1)
  const [month, setMonth] = React.useState<number>(1)
  const [year, setYear] = React.useState<number>(1)
  const [painDuration, setPainDuration] = React.useState<number>(1)

  const [fullTextForChiefComplaint, setFullTextForChiefComplaint] =
    useState<string>('')
  const [showTextForChiefComplaint, setShowTextForChiefComplaint] =
    useState<boolean>(false)

  const [fullText, setFullText] = useState<string>('')
  const [showText, setShowText] = useState<boolean>(false)
  const handleChange = (event: any) => {
    setChecked(event.target.checked)
    setPainDuration(1)
  }

  const [doshaStatuses, setDoshaStatuses] = useState<
    R4.IObservation_Component[]
  >([])
  const [dushyaStatuses, setDushyaStatuses] = useState<
    R4.IObservation_Component[]
  >([])
  const [malaStatuses, setMalaStatuses] = useState<R4.IObservation_Component[]>(
    []
  )

  const [doshaRemarks, setDoshaRemarks] = useState<string>()
  const [dushayRemarks, setDushyaRemarks] = useState<string>()
  const [malaRemarks, setMalaRemarks] = useState<string>()

  const handleChangeForAssociated = (event: boolean, index: number) => {
    const values: AssociatedComplaintData[] = [...associatedComplaints]
    if (event === true) {
      values[index].checked = true
      setAssociatedComplaints(values)
    } else {
      const idVal: number = values[index].id
      const newArray = [...values.slice(0, index), ...values.slice(index + 1)]

      newArray[index].id = idVal
      //   values[index].checked = false
      //   values[index].typeData = 'days'
      //   values[index].cui = undefined
      //   values[index].days = 1
      //   values[index].weeks = 1
      //   values[index].months = 1
      //   values[index].year = 1
      //   values[index].date = new Date()
      //   values[index].patientWording = ''
      //   values[index].anyOther = false
      //   values[index].anyOtherData = ''
      //   values[index].checked = false
      setAssociatedComplaints(newArray)
    }

    if (
      event === true &&
      associatedComplaints.length - 1 === index &&
      associatedComplaints.length < 4
    )
      handleAddMore()

    // if (event === false) {
    //   //   setTypeData('days')
    //   setAssociatedComplaints([
    //     {
    //       id: 0,
    //       typeData: 'days',
    //       cui: undefined,
    //       days: 1,
    //       weeks: 1,
    //       months: 1,
    //       year: 1,
    //       date: new Date(),
    //       patientWording: '',
    //       anyOther: false,
    //       anyOtherData: '',
    //       checked: false,
    //     },
    //   ])
    //   //   setYear(0)
    //   //   setMonth(0)
    //   //   setDays(0)
    // }
  }

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  const theme = useTheme()

  const [associatedComplaints, setAssociatedComplaints] = useState<
    AssociatedComplaintData[]
  >([
    {
      id: 0,
      typeData: 'days',
      cui: undefined,
      days: 1,
      weeks: 1,
      months: 1,
      year: 1,
      date: new Date(),
      patientWording: '',
      anyOther: false,
      anyOtherData: '',
      checked: false,
    },
  ])

  function handleSelectedAssociatedComplaints(comp: R4.ICoding, index: number) {
    const values: AssociatedComplaintData[] = [...associatedComplaints]
    values[index].cui = comp
    values[index].anyOther = false
    values[index].anyOtherData = ''

    setAssociatedComplaints(values)
  }

  function handleAnyOtherData(comp: string, index: number) {
    const values: AssociatedComplaintData[] = [...associatedComplaints]
    const code = {
      code: 'other-complaint',
      system:
        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-medical-condition-cs',
      display: comp,
    }
    values[index].cui = code
    values[index].anyOtherData = comp
    setAssociatedComplaints(values)
  }

  function handleAnyOther(
    condition: R4.ICoding,
    otherVal: boolean,
    index: number
  ) {
    const values: AssociatedComplaintData[] = [...associatedComplaints]
    values[index].anyOther = true
    if (condition.code && condition.code === 'other-complaint') {
      values[index].preselectedAssociated = {
        cui: condition.code ?? '',
        synonyms: condition.display ?? '',
        type: '',
        icd: condition.display ?? '',
      }
    }
    setAssociatedComplaints(values)
  }

  function handleTypeChange(type: string, index: number) {
    const values: AssociatedComplaintData[] = [...associatedComplaints]

    if (type === 'days') {
      values[index].typeData = type
      values[index].months = 1
      values[index].weeks = 1
      values[index].year = 1
      values[index].date = new Date()
    } else if (type === 'months') {
      values[index].typeData = type
      values[index].days = 1
      values[index].weeks = 1
      values[index].year = 1
      values[index].date = new Date()
    } else if (type === 'years') {
      values[index].typeData = type
      values[index].days = 1
      values[index].months = 1
      values[index].weeks = 1
      values[index].date = new Date()
    } else if (type === 'weeks') {
      values[index].typeData = type
      values[index].days = 1
      values[index].months = 1
      values[index].date = new Date()
    } else {
      values[index].typeData = type
      values[index].days = 1
      values[index].months = 1
      values[index].year = 1
    }
    setAssociatedComplaints(values)
  }

  function handleDays(rate: number) {
    setDays(rate)
  }

  function handleWeeks(rate: number) {
    setWeeks(rate)
  }

  function handleAdditonalDays(rate: number, index: number) {
    const values: AssociatedComplaintData[] = [...associatedComplaints]
    values[index].days = rate
    setAssociatedComplaints(values)
  }

  function handleAdditonalWeeks(rate: number, index: number) {
    const values: AssociatedComplaintData[] = [...associatedComplaints]
    values[index].weeks = rate
    setAssociatedComplaints(values)
  }

  function handleAssociatedSymptoms(rate: string, index: number) {
    const values: AssociatedComplaintData[] = [...associatedComplaints]
    values[index].patientWording = rate
    setAssociatedComplaints(values)
  }

  function handleAdditionalMonths(rate: number, index: number) {
    const values: AssociatedComplaintData[] = [...associatedComplaints]
    values[index].months = rate
    setAssociatedComplaints(values)
  }

  function handleAdditionalYears(rate: number, index: number) {
    const values: AssociatedComplaintData[] = [...associatedComplaints]
    values[index].year = rate
    setAssociatedComplaints(values)
  }

  const handleDateChangeForAssociated = (
    date: MaterialUiPickersDate,
    index: number
  ) => {
    const values: AssociatedComplaintData[] = [...associatedComplaints]
    values[index].date = date !== null ? moment(date).toDate() : new Date()
    setAssociatedComplaints(values)
    // setSelectedDate(date !== null ? moment(date).toDate() : new Date())
  }

  function handleMonths(rate: number) {
    setMonth(rate)
  }
  function handleYear(rate: number) {
    setYear(rate)
  }

  function getObservationsResourcesForAyurvedaExamination(): R4.IObservation[] {
    const observations: R4.IObservation[] = []
    if (isAyurvedaDoctor()) {
      if (doshaStatuses.length > 0 || (doshaRemarks ?? '').length > 0) {
        observations.push(
          getObservationForCodeCategoryComponentAndNotes({
            patientReference: fhirAppointmentDetails.patient.id!,
            encounterReference: fhirAppointmentDetails.encounter!.id!,
            code: {
              text: 'Dosha',
              coding: [
                {
                  system: 'http://www.who.int/ita',
                  code: 'ITA-2.1.2',
                  display: 'Dosha',
                },
              ],
            },
            category: [
              {
                coding: [
                  {
                    display: 'Chief Complaint',
                    code: 'chief-complaint',
                  },
                ],
              },
            ],
            component: doshaStatuses,
            note: doshaRemarks,
          })
        )
      }
      if (dushyaStatuses.length > 0 || (dushayRemarks ?? '').length > 0) {
        observations.push(
          getObservationForCodeCategoryComponentAndNotes({
            patientReference: fhirAppointmentDetails.patient.id!,
            encounterReference: fhirAppointmentDetails.encounter!.id!,
            code: {
              text: 'Dushya Status',
              coding: [
                {
                  system: 'http://www.who.int/ita',
                  code: 'ITA-2.2.2',
                  display: 'Dushya Status',
                },
              ],
            },
            category: [
              {
                coding: [
                  {
                    display: 'Chief Complaint',
                    code: 'chief-complaint',
                  },
                ],
              },
            ],
            component: dushyaStatuses,
            note: dushayRemarks,
          })
        )
      }
      if (malaStatuses.length > 0 || (malaRemarks ?? '').length > 0) {
        observations.push(
          getObservationForCodeCategoryComponentAndNotes({
            patientReference: fhirAppointmentDetails.patient.id!,
            encounterReference: fhirAppointmentDetails.encounter!.id!,
            code: {
              text: 'Mala Status',
              coding: [
                {
                  system: 'http://www.who.int/ita',
                  code: 'ITA-2.4.1',
                  display: 'Mala Status',
                },
              ],
            },
            category: [
              {
                coding: [
                  {
                    display: 'Chief Complaint',
                    code: 'chief-complaint',
                  },
                ],
              },
            ],
            component: malaStatuses,
            note: malaRemarks,
          })
        )
      }
    }

    return observations
  }

  const handleAddMore = () => {
    const values: AssociatedComplaintData[] = [...associatedComplaints]
    values.push({
      id: 1,
      typeData: 'days',
      cui: undefined,
      days: 1,
      months: 1,
      year: 1,
      weeks: 1,
      date: new Date(),
      patientWording: '',
      anyOther: false,
      anyOtherData: '',
      checked: false,
    })
    setAssociatedComplaints(values)
  }

  const handleRemove = (index: number) => {
    const list = [...associatedComplaints]
    list.splice(index, 1)
    setAssociatedComplaints(list)
  }

  const handlemodify = (index: number, additional: AssociatedComplaintData) => {
    const values: AssociatedComplaintData[] = [...associatedComplaints]

    values[index].cui = additional.anyOther ? additional.cui : undefined
    values[index].time = undefined
    values[index].days = 1
    values[index].months = 1
    values[index].year = 1
    values[index].typeData = 'days'
    values[index].date = new Date()
    values[index].anyOther = additional.anyOther
    values[index].anyOtherData = additional.anyOtherData

    setAssociatedComplaints(values)
  }

  const handleUpdate = () => {
    if (selectedCondition) {
      dispatch(
        updateChiefComplaintDetails(
          fhirAppointmentDetails,
          selectedCondition,

          typeData,
          days,
          weeks,
          month,
          year,
          'add',
          '',
          other,
          additionalNotes!,
          selectedLocation,
          selectedDate != null ? selectedDate : new Date(),
          selectedSeverity,
          duration,
          character,
          alleviating,
          radiation,
          temporal,
          otherData,
          patientWording.trim(),
          checked,
          associatedComplaints,
          selectedPainProfileSymptoms,
          associatdChecked,
          durationPain,
          painDuration,
          getObservationsResourcesForAyurvedaExamination()
        )
      )
    }
  }

  function isEnableCreateButton(): boolean | undefined {
    let result: boolean = false

    if (
      typeData === 'days' &&
      (days < 1 || days > getAgeInDays(fhirAppointmentDetails.patient))
    ) {
      result = true
    }
    if (
      typeData === 'months' &&
      (month < 1 || month > getAgeInMonths(fhirAppointmentDetails.patient))
    ) {
      result = true
    }
    if (
      typeData === 'years' &&
      (year < 1 || year > getAgeInYears(fhirAppointmentDetails.patient))
    ) {
      result = true
    }

    if (
      typeData === 'weeks' &&
      (weeks < 1 || weeks > getAgeInWeeks(fhirAppointmentDetails.patient))
    ) {
      result = true
    }

    if (
      typeData === 'custom' &&
      validateCheckoutDateForChiefComplaints(
        moment(selectedDate).format('YYYY-MM-DD'),
        getAgeOfPatientForChecking(fhirAppointmentDetails.patient),
        false
      ).length > 0
    ) {
      result = true
    }

    return result
  }

  function isEnableCreateButtonPainProfile(): string[] {
    const result: string[] = []
    if (checked && duration) {
      if (
        duration === 'Days' &&
        (painDuration < 1 ||
          painDuration > getAgeInDays(fhirAppointmentDetails.patient))
      ) {
        result.push('1')
      }
      if (
        duration === 'Weeks' &&
        (painDuration < 1 ||
          painDuration > getAgeInWeeks(fhirAppointmentDetails.patient))
      ) {
        result.push('1')
      }
      if (
        duration === 'Months' &&
        (painDuration < 1 ||
          painDuration > getAgeInMonths(fhirAppointmentDetails.patient))
      ) {
        result.push('1')
      }
      if (
        duration === 'Years' &&
        (painDuration < 1 ||
          painDuration > getAgeInYears(fhirAppointmentDetails.patient))
      ) {
        result.push('1')
      }
    }
    return result
  }

  function isShowAssociated(): boolean | undefined {
    let result: boolean = false

    if (selectedCondition!) {
      result = true
    }

    return result
  }

  function isEnableCreateButtonForAssociated(): string[] {
    const result: string[] = []

    if (associatedComplaints.length > 0) {
      for (let i = 0; i < associatedComplaints.length; i++) {
        if (associatedComplaints[i].checked) {
          if (associatedComplaints[i].patientWording.length > 0) {
            if (associatedComplaints[i].cui) {
              if (
                associatedComplaints[i].typeData === 'days' &&
                (associatedComplaints[i].days < 1 ||
                  associatedComplaints[i].days >
                    getAgeInDays(fhirAppointmentDetails.patient))
              ) {
                result.push('1')
              }
              if (
                associatedComplaints[i].typeData === 'months' &&
                (associatedComplaints[i].months < 1 ||
                  associatedComplaints[i].months >
                    getAgeInMonths(fhirAppointmentDetails.patient))
              ) {
                result.push('2')
              }

              if (
                associatedComplaints[i].typeData === 'weeks' &&
                (associatedComplaints[i].weeks < 1 ||
                  associatedComplaints[i].weeks >
                    getAgeInWeeks(fhirAppointmentDetails.patient))
              ) {
                result.push('2')
              }
              if (
                associatedComplaints[i].typeData === 'years' &&
                (associatedComplaints[i].year < 1 ||
                  associatedComplaints[i].year >
                    getAgeInYears(fhirAppointmentDetails.patient))
              ) {
                result.push('3')
              }

              if (
                associatedComplaints[i].typeData === 'custom' &&
                validateCheckoutDateForChiefComplaints(
                  moment(associatedComplaints[i].date).format('YYYY-MM-DD'),
                  getAgeOfPatientForChecking(fhirAppointmentDetails.patient),
                  false
                ).length > 0
              ) {
                result.push('3')
              }
              if (associatedComplaints[i].patientWording.length > 250) {
                result.push('3')
              }
            } else {
              result.push('3')
            }
          }
        }
      }
    }

    return result
  }

  function findDuplicate(): string[] {
    const resutlSet: string[] = []
    if (checked && checked === true) {
      if (selectedCondition) {
        const filteredData = selectedPainProfileSymptoms.filter(
          (d) => d.code! === selectedCondition.code!
        )
        // if (filteredData.length > 0) {
        //   resutlSet.push('1')
        // }

        // if (
        //   associatedComplaints.length > 0 &&
        //   associatedComplaints[0].cui !== undefined
        // ) {
        //   for (let i = 0; i < selectedPainProfileSymptoms.length; i++) {
        //     if (associatedComplaints.length > 0) {
        //       const filteredData1 = associatedComplaints.filter((d) =>
        //         d.cui
        //           ? d.cui.code! === selectedPainProfileSymptoms[i].code!
        //           : []
        //       )
        //       if (filteredData1.length > 0) {
        //         resutlSet.push('2')
        //       }
        //     }
        //   }
        // }
      }
    }

    return resutlSet
  }

  const handleDateChange = (date: MaterialUiPickersDate) => {
    setSelectedDate(date !== null ? moment(date).toDate() : new Date())
  }

  useEffect(() => {
    if (chiefComplaintSlice.updatedConditions) {
      onConditionAdded(fhirAppointmentDetails)
      resetDetails()
    }
  }, [
    chiefComplaintSlice.updatedConditions,
    fhirAppointmentDetails,
    onConditionAdded,
  ])

  function resetDetails() {
    setDurationPain('Days')
    setSelectedCondition(undefined)
    setLocations([])
    setTypeData('days')
    setAssociatedComplaints([
      {
        id: 0,
        typeData: 'days',
        cui: undefined,
        days: 1,
        months: 1,
        year: 1,
        weeks: 1,
        date: new Date(),
        patientWording: '',
        anyOther: false,
        anyOtherData: '',
        checked: false,
      },
    ])
    setYear(1)
    setMonth(1)
    setDays(1)
    setWeeks(1)
    setDuration('Days')
    setCharacter('')
    setAlleviating('')
    setRadiation('')
    setTemporal('')
    setChecked(false)
    setAssociatedChecked(false)
    setPatientWording('')
    setSlectedPainProfileSymptoms([])
    setAdditionalNotes(undefined)
    setPainDuration(1)
    setMalaRemarks('')
    if (isAyurvedaDoctor()) {
      setDoshaRemarks('')
      setDushyaRemarks('')
      setDoshaStatuses([])
      setDushyaStatuses([])
      setMalaStatuses([])
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetDetails()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Add Chief Complaint</DialogTitle>
      <DialogContent dividers={true} style={{ height: '100%' }}>
        <SimpleBar
          style={{
            height: '100%',
            overflowX: 'hidden',
          }}
        >
          <Box marginY={1} width='100%' px={1}>
            <Box display='flex' width='100%' flexDirection='column'>
              <Box display='flex' width='100%' flexDirection='column'>
                <WelloFormItemLabel title='Chief Complaint' />{' '}
                <WelloTextFieldWithoutTitle
                  textProps={{
                    id: `actor_email_unit`,
                    value: patientWording,
                    rows: 4,
                    rowsMax: 6,
                    multiline: true,
                    error: patientWording.length > 250,
                    helperText:
                      patientWording && patientWording.length > 250
                        ? 'Chief Complaint should be less than 250 chars'
                        : '',
                    onChange: (changeData) => {
                      setPatientWording(changeData.target.value)
                    },
                  }}
                />
              </Box>
              <Box display='flex' flexDirection='row' py={0.5}>
                <WelloFormItemLabel title='Mapped Symptoms' />{' '}
                {selectedCondition === undefined && (
                  <Box py={1} px={0.5}>
                    <Typography variant='subtitle1' color='error'>
                      (Mapped Symptom is required)
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box>
                <ChiefComplaintsSelector
                  onSelectionChanges={(e: ChiefComplaintData) => {
                    if (e.cui === 'other-complaint') {
                      setOther(true)
                    } else {
                      setOther(false)
                    }
                    const conditonVal: R4.ICoding = {
                      code: e.icd,
                      display: e.synonyms.trim(),
                      system: 'http://snomed.info/sct',
                    }
                    setSelectedCondition(conditonVal)
                  }}
                  disabled={false}
                  selectOther={isActive}
                  id='chief_id_1'
                />
              </Box>

              {/* {selectedCondition === undefined && (
              <Box py={0.5} px={0.5}>
                <Typography variant='subtitle1' color='error'>
                  Mapped Symptom is required
                </Typography>
              </Box>
            )} */}

              {other && (
                <Box
                  display='flex'
                  width='100%'
                  flexDirection='column'
                  flexGrow={1}
                >
                  <WelloFormItemLabel title='Any Other' />{' '}
                  <WelloTextFieldWithoutTitle
                    textProps={{
                      id: `chief_id_2`,
                      type: 'text',
                      error: validateOthers(otherData, false).length > 0,
                      helperText:
                        validateOthers(otherData, false).length > 0
                          ? `${validateOthers(otherData, false)}`
                          : '',
                      onChange: (changeData) => {
                        setOtherData(changeData.target.value)
                      },
                    }}
                  />
                </Box>
              )}
            </Box>

            <Box display='flex' flexDirection='column' width='100%' py={1}>
              <Box display='flex' flexDirection='row' width='100%' py={1}>
                <Box width='22%'>
                  <WelloFormItemLabel title='Duration / Start Date' />{' '}
                </Box>
                <Box width='25%'>
                  {typeData === 'days' && (
                    <WelloSelectUnit
                      title=''
                      id='days'
                      duration='Select Duration Unit'
                      availableCodings={
                        days === 1
                          ? durationDataForChiefComplaintsWithOne
                          : durationDataForChiefComplaints
                      }
                      onChanged={(type) => {
                        if (type.display && type.display.length > 0) {
                          if (type.display === 'Days' || type.display === 'Day')
                            setTypeData('days')
                          else if (
                            type.display === 'Months' ||
                            type.display === 'Month'
                          )
                            setTypeData('months')
                          else if (
                            type.display === 'Weeks' ||
                            type.display === 'Week'
                          )
                            setTypeData('weeks')
                          else if (
                            type.display === 'Years' ||
                            type.display === 'Year'
                          )
                            setTypeData('years')
                          else setTypeData('custom')
                        }
                        setDuration(type.display ?? '')
                      }}
                      textFieldProps={{
                        size: 'small',
                        fullWidth: true,
                      }}
                      preSelectedCoding={
                        days === 1 || weeks === 1
                          ? durationDataWithOne[0]
                          : durationData[0]
                      }
                    />
                  )}

                  {typeData === 'weeks' && (
                    <WelloSelectUnit
                      title=''
                      id='weeks'
                      duration='Select Duration Unit'
                      availableCodings={
                        weeks === 1
                          ? durationDataForChiefComplaintsWithOne
                          : durationDataForChiefComplaints
                      }
                      onChanged={(type) => {
                        if (type.display && type.display.length > 0) {
                          if (type.display === 'Days' || type.display === 'Day')
                            setTypeData('days')
                          else if (
                            type.display === 'Months' ||
                            type.display === 'Month'
                          )
                            setTypeData('months')
                          else if (
                            type.display === 'Weeks' ||
                            type.display === 'Week'
                          )
                            setTypeData('weeks')
                          else if (
                            type.display === 'Years' ||
                            type.display === 'Year'
                          )
                            setTypeData('years')
                          else setTypeData('custom')
                        }
                        setDuration(type.display ?? '')
                      }}
                      textFieldProps={{
                        size: 'small',
                        fullWidth: true,
                      }}
                      preSelectedCoding={
                        weeks === 1 ? durationDataWithOne[1] : durationData[1]
                      }
                    />
                  )}

                  {typeData === 'months' && (
                    <WelloSelectUnit
                      title=''
                      id='months'
                      duration='Select Duration Unit'
                      availableCodings={
                        month === 1
                          ? durationDataForChiefComplaintsWithOne
                          : durationDataForChiefComplaints
                      }
                      onChanged={(type) => {
                        if (type.display && type.display.length > 0) {
                          if (type.display === 'Days' || type.display === 'Day')
                            setTypeData('days')
                          else if (
                            type.display === 'Months' ||
                            type.display === 'Month'
                          )
                            setTypeData('months')
                          else if (
                            type.display === 'Weeks' ||
                            type.display === 'Week'
                          )
                            setTypeData('weeks')
                          else if (
                            type.display === 'Years' ||
                            type.display === 'Year'
                          )
                            setTypeData('years')
                          else setTypeData('custom')
                        }
                        setDuration(type.display ?? '')
                      }}
                      textFieldProps={{
                        size: 'small',
                        fullWidth: true,
                      }}
                      preSelectedCoding={
                        month === 1 ? durationDataWithOne[2] : durationData[2]
                      }
                    />
                  )}

                  {typeData === 'years' && (
                    <WelloSelectUnit
                      title=''
                      id='years'
                      duration='Select Duration Unit'
                      availableCodings={
                        year === 1
                          ? durationDataForChiefComplaintsWithOne
                          : durationDataForChiefComplaints
                      }
                      onChanged={(type) => {
                        if (type.display && type.display.length > 0) {
                          if (type.display === 'Days' || type.display === 'Day')
                            setTypeData('days')
                          else if (
                            type.display === 'Months' ||
                            type.display === 'Month'
                          )
                            setTypeData('months')
                          else if (
                            type.display === 'Weeks' ||
                            type.display === 'Week'
                          )
                            setTypeData('weeks')
                          else if (
                            type.display === 'Years' ||
                            type.display === 'Year'
                          )
                            setTypeData('years')
                          else setTypeData('custom')
                        }
                        setDuration(type.display ?? '')
                      }}
                      textFieldProps={{
                        size: 'small',
                        fullWidth: true,
                      }}
                      preSelectedCoding={
                        year === 1 ? durationDataWithOne[3] : durationData[3]
                      }
                    />
                  )}

                  {typeData === 'custom' && (
                    <WelloSelectUnit
                      title=''
                      id='customMain'
                      duration='Select Duration Unit'
                      availableCodings={
                        year === 1 && month === 1 && weeks === 1 && days === 1
                          ? durationDataForChiefComplaintsWithOne
                          : durationDataForChiefComplaints
                      }
                      onChanged={(type) => {
                        if (type.display && type.display.length > 0) {
                          if (type.display === 'Days' || type.display === 'Day')
                            setTypeData('days')
                          else if (
                            type.display === 'Months' ||
                            type.display === 'Month'
                          )
                            setTypeData('months')
                          else if (
                            type.display === 'Weeks' ||
                            type.display === 'Week'
                          )
                            setTypeData('weeks')
                          else if (
                            type.display === 'Years' ||
                            type.display === 'Year'
                          )
                            setTypeData('years')
                          else setTypeData('custom')
                        }
                        setDuration(type.display ?? '')
                      }}
                      textFieldProps={{
                        size: 'small',
                        fullWidth: true,
                      }}
                      preSelectedCoding={durationDataForChiefComplaints[4]}
                    />
                  )}
                </Box>
                <Box width='3%' />

                {typeData === 'days' && (
                  <Box width='50%' display='flex' flexDirection='column'>
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box width='18.6%' />
                      <WelloFormItemLabel title='Enter Days' />{' '}
                      <Box paddingLeft={1} width='60%'>
                        <WelloTextFieldWithoutTitle
                          textProps={{
                            id: `daysNumber`,
                            value: days,
                            type: 'number',
                            inputProps: {
                              max: 365,
                              min: 1,
                            },
                            error:
                              days < 1 ||
                              days >
                                getAgeInDays(fhirAppointmentDetails.patient) ||
                              Number.isNaN(days),
                            helperText:
                              days < 1 ||
                              days >
                                getAgeInDays(fhirAppointmentDetails.patient) ||
                              Number.isNaN(days)
                                ? `Days should be in between 1 to ${getAgeInDays(
                                    fhirAppointmentDetails.patient
                                  )}`
                                : '',
                            onChange: (rate) => {
                              handleDays(parseInt(rate.target.value, 10))
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}

                {typeData === 'weeks' && (
                  <Box width='50%' display='flex' flexDirection='column'>
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box width='20.6%' />
                      <WelloFormItemLabel title='Enter Weeks' />{' '}
                      <Box paddingLeft={1} width='55%'>
                        <WelloTextFieldWithoutTitle
                          textProps={{
                            id: `weeksNumber`,
                            value: weeks,
                            type: 'number',
                            inputProps: {
                              max: getAgeInWeeks(
                                fhirAppointmentDetails.patient
                              ),
                              min: 1,
                            },
                            error:
                              weeks < 1 ||
                              weeks >
                                getAgeInWeeks(fhirAppointmentDetails.patient) ||
                              Number.isNaN(weeks),
                            helperText:
                              weeks < 1 ||
                              weeks >
                                getAgeInWeeks(fhirAppointmentDetails.patient) ||
                              Number.isNaN(weeks)
                                ? `Weeks should be in between 1 to ${getAgeInWeeks(
                                    fhirAppointmentDetails.patient
                                  )}`
                                : '',
                            onChange: (rate) => {
                              handleWeeks(parseInt(rate.target.value, 10))
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}

                {typeData === 'months' && (
                  <Box width='50%' display='flex' flexDirection='column'>
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box
                        width={
                          (associatedComplaints.length > 1 &&
                            associatedComplaints[0].checked) ||
                          checked
                            ? '17.6%'
                            : '18.6%'
                        }
                      />
                      <WelloFormItemLabel title='Enter Months' />{' '}
                      <Box paddingLeft={1} width='55%'>
                        <WelloTextFieldWithoutTitle
                          textProps={{
                            id: `monthsNumber`,
                            value: month,
                            type: 'number',
                            inputProps: {
                              max: getAgeInMonths(
                                fhirAppointmentDetails.patient
                              ),
                              min: 1,
                            },
                            error:
                              month < 1 ||
                              month >
                                getAgeInMonths(
                                  fhirAppointmentDetails.patient
                                ) ||
                              Number.isNaN(month),
                            helperText:
                              month < 1 ||
                              month >
                                getAgeInMonths(
                                  fhirAppointmentDetails.patient
                                ) ||
                              Number.isNaN(month)
                                ? `Months should be in between 1 to ${getAgeInMonths(
                                    fhirAppointmentDetails.patient
                                  )}`
                                : '',
                            onChange: (rate) => {
                              handleMonths(parseInt(rate.target.value, 10))
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}

                {typeData === 'years' && (
                  <Box width='50%' display='flex' flexDirection='column'>
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box width='22.4%' />
                      <WelloFormItemLabel title='Enter Years' />{' '}
                      <Box paddingLeft={1} width='55%'>
                        <WelloTextFieldWithoutTitle
                          textProps={{
                            id: `yearsNumber`,
                            value: year,
                            type: 'number',
                            inputProps: {
                              max: getAgeInYears(
                                fhirAppointmentDetails.patient
                              ),
                              min: 1,
                            },
                            error:
                              year < 1 ||
                              year >
                                getAgeInYears(fhirAppointmentDetails.patient) ||
                              Number.isNaN(year),
                            helperText:
                              year < 1 ||
                              year >
                                getAgeInYears(fhirAppointmentDetails.patient) ||
                              Number.isNaN(year)
                                ? `Years should be in between 1 to ${getAgeInYears(
                                    fhirAppointmentDetails.patient
                                  )}`
                                : '',
                            onChange: (rate) => {
                              handleYear(parseInt(rate.target.value, 10))
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}

                {typeData === 'custom' && (
                  <Box display='flex' flexDirection='row' width='50%'>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Box display='flex' flexDirection='row' width='100%'>
                        <Box width='30%' />
                        <WelloFormItemLabel title='Date' />{' '}
                        <Box paddingLeft={1} width='60%'>
                          <KeyboardDatePicker
                            clearable
                            id='mainDate'
                            maxDate={moment(selectedDate)}
                            minDate={moment(
                              getAgeOfPatientForChecking(
                                fhirAppointmentDetails.patient
                              )
                            )}
                            value={selectedDate}
                            format='DD-MM-YYYY'
                            onChange={handleDateChange}
                            error={
                              validateCheckoutDateForChiefComplaints(
                                moment(selectedDate).format('YYYY-MM-DD'),
                                getAgeOfPatientForChecking(
                                  fhirAppointmentDetails.patient
                                ),
                                false
                              ).length > 0
                            }
                            helperText={validateCheckoutDateForChiefComplaints(
                              moment(selectedDate).format('YYYY-MM-DD'),
                              getAgeOfPatientForChecking(
                                fhirAppointmentDetails.patient
                              ),
                              false
                            )}
                            size='small'
                            inputVariant='outlined'
                          />
                        </Box>
                      </Box>
                    </MuiPickersUtilsProvider>
                  </Box>
                )}
              </Box>
            </Box>

            {/* {isShowAssociated() === true && (
            <Box flexDirection='row' display='flex' px={0.5} py={1}>
              <Box>
                <Checkbox
                  edge='start'
                  checked={associatdChecked}
                  onChange={handleChangeForAssociated}
                  tabIndex={-1}
                  size='small'
                  disableRipple
                  color='primary'
                  inputProps={{ 'aria-labelledby': 'consent' }}
                />
              </Box>
              <Box paddingTop={1} paddingBottom={1.5}>
                <Typography variant='subtitle1' color='initial'>
                  Add Associated Complaints
                </Typography>
              </Box>
            </Box>
          )} */}

            {isShowAssociated() === true && (
              <Box
                display='flex'
                flexDirection='column'
                id='unit_act_basic_det_container'
                width='100%'
              >
                {associatedComplaints.map(
                  (additional: AssociatedComplaintData, index: number) => (
                    <Box
                      key={additional.id}
                      display='flex'
                      flexDirection='row'
                      justifyContent='space-between'
                      alignContent='center'
                      alignItems='center'
                      width='100%'
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='flex-start'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          alignItems='flex-start'
                          width='100%'
                        >
                          <Box flexDirection='row' display='flex' px={0.5}>
                            <Box paddingTop={additional.id > 0 ? 1 : 0}>
                              <Checkbox
                                edge='start'
                                id='chief_0'
                                checked={additional.checked}
                                style={{ paddingTop: 0 }}
                                onChange={(e) => {
                                  if (e)
                                    handleChangeForAssociated(
                                      e.target.checked,
                                      index
                                    )
                                }}
                                //   onChange={handleChangeForAssociated(event, index)}
                                tabIndex={-1}
                                size='small'
                                disableRipple
                                color='primary'
                                inputProps={{ 'aria-labelledby': 'consent' }}
                              />
                            </Box>
                            <Box>
                              {additional.id === 0 && (
                                <Typography
                                  variant='subtitle1'
                                  style={{
                                    color: 'black',
                                  }}
                                >
                                  Add Associated Complaints
                                </Typography>
                              )}
                              {additional.id > 0 && (
                                <Box paddingTop={1}>
                                  <Typography
                                    variant='subtitle1'
                                    style={{
                                      textTransform: 'capitalize',
                                      color: 'black',
                                    }}
                                  >
                                    Add More Associated Complaints
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                          {additional.checked && (
                            <Box
                              display='flex'
                              width='100%'
                              flexDirection='column'
                              flexGrow={1}
                            >
                              <WelloTextField
                                title='Associated Complaint'
                                textProps={{
                                  id: `associatedWording`,
                                  value: additional.patientWording,
                                  rows: 4,
                                  rowsMax: 6,
                                  multiline: true,
                                  error: additional.patientWording.length > 250,
                                  helperText:
                                    additional.patientWording.length > 250
                                      ? 'Associated Complaint should be less than 250 chars'
                                      : '',
                                  onChange: (changeData) => {
                                    handleAssociatedSymptoms(
                                      changeData.target.value,
                                      index
                                    )
                                  },
                                }}
                              />
                            </Box>
                          )}

                          {!additional.cui && additional.checked && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              <WelloFormItemLabel title=' Associated Mapped Symptom' />
                              {additional.patientWording.length > 0 &&
                                additional.cui === undefined && (
                                  <Box px={0.5} paddingTop={1}>
                                    <Typography
                                      variant='subtitle1'
                                      color='error'
                                    >
                                      (Associated Mapped Symptom is required)
                                    </Typography>
                                  </Box>
                                )}
                            </Box>
                          )}

                          {additional.cui && additional.checked && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              justifyContent='space-between'
                              width='100%'
                            >
                              <WelloFormItemLabel title='Associated Complaints' />
                            </Box>
                          )}
                          <Box width='100%'>
                            {additional.checked && (
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                >
                                  <ChiefComplaintsSelector
                                    onSelectionChanges={(
                                      e: ChiefComplaintData
                                    ) => {
                                      //   if (e.cui === 'other-complaint') {
                                      //     setOther(true)
                                      //   } else {
                                      //     setOther(false)
                                      //   }
                                      const conditonVal: R4.ICoding = {
                                        code: e.icd,
                                        display: e.synonyms.trim(),
                                        system: 'http://snomed.info/sct',
                                      }
                                      if (e.cui === 'other-complaint') {
                                        setShowTextForChiefComplaint(false)
                                        setFullTextForChiefComplaint('')
                                        handleAnyOther(conditonVal, true, index)
                                      } else if (selectedCondition) {
                                        if (
                                          selectedCondition.code! ===
                                          conditonVal.code!
                                        ) {
                                          setShowTextForChiefComplaint(true)
                                          setFullTextForChiefComplaint(
                                            'Already selected as part of chief complaint'
                                          )
                                        } else if (
                                          associatedComplaints.length > 0
                                        ) {
                                          const filteredData: string[] = []

                                          for (
                                            let i = 0;
                                            i < associatedComplaints.length;
                                            i++
                                          ) {
                                            const { cui } =
                                              associatedComplaints[i]
                                            if (
                                              associatedComplaints[i].checked &&
                                              cui &&
                                              conditonVal.code
                                            ) {
                                              const code = cui.code ?? ''
                                              if (code.length > 0) {
                                                if (conditonVal.code === code) {
                                                  filteredData.push('1')
                                                }
                                              }
                                            }
                                          }
                                          // const filteredData =
                                          //   associatedComplaints.filter((d) =>
                                          //     d.cui &&
                                          //     d.checked === true &&
                                          //     d.cui.code &&
                                          //     conditonVal.code
                                          //       ? d.cui.code === conditonVal.code
                                          //       : []
                                          //   )
                                          if (filteredData.length === 1) {
                                            setFullText(
                                              'Associated Mapped Symptoms Already Selected'
                                            )
                                            setShowText(true)
                                          } else {
                                            setShowText(false)
                                          }

                                          if (filteredData.length === 0)
                                            handleSelectedAssociatedComplaints(
                                              conditonVal,
                                              index
                                            )
                                        } else {
                                          setShowTextForChiefComplaint(false)
                                          setFullTextForChiefComplaint('')
                                          handleSelectedAssociatedComplaints(
                                            conditonVal,
                                            index
                                          )
                                        }
                                      }
                                    }}
                                    disabled={false}
                                    selectOther={isActive}
                                    id='associated_chief_match_symp'
                                  />
                                </Box>

                                {additional.anyOther && (
                                  <Box
                                    display='flex'
                                    width='100%'
                                    flexDirection='column'
                                    flexGrow={1}
                                  >
                                    <WelloTextField
                                      title='Any Other'
                                      textProps={{
                                        id: `additional_chief_associated`,
                                        type: 'text',
                                        error:
                                          validateOthers(
                                            additional.anyOtherData,
                                            false
                                          ).length > 0,
                                        helperText:
                                          validateOthers(
                                            additional.anyOtherData,
                                            false
                                          ).length > 0
                                            ? `${validateOthers(
                                                additional.anyOtherData,
                                                false
                                              )}`
                                            : '',
                                        onBlur: (changeData) => {
                                          if (
                                            changeData.target.value.trim()
                                              .length > 0
                                          ) {
                                            handleAnyOtherData(
                                              changeData.target.value.trim(),
                                              index
                                            )
                                          }
                                        },
                                        onChange: (changeData) => {
                                          // handleAnyOtherData(
                                          //   changeData.target.value,
                                          //   index
                                          // )
                                        },
                                      }}
                                    />
                                  </Box>
                                )}
                                {/* {additional.patientWording.length > 0 &&
                                additional.cui === undefined && (
                                  <Box py={0.5} px={0.5}>
                                    <Typography
                                      variant='subtitle1'
                                      color='error'
                                    >
                                      Associated Mapped Symptom is required
                                    </Typography>
                                  </Box>
                                )} */}
                              </Box>
                            )}
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                {/* {additional.cui && additional.anyOther === false && (
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                >
                                  <Box width='60%' px={0.5}>
                                    <Typography variant='subtitle1'>
                                      {' '}
                                      {titleCase(additional.cui.display ?? '')}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Tooltip title='Edit'>
                                      <IconButton
                                        aria-label='btn_ord_cancel'
                                        color='primary'
                                        style={{ padding: 0 }}
                                        onClick={() => {
                                          handlemodify(index, additional)
                                        }}
                                      >
                                        <img
                                          src={`${process.env.PUBLIC_URL}/editVector.png`}
                                          alt='Edit'
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Box>
                              )} */}

                                {additional.cui &&
                                  additional.anyOther &&
                                  additional.anyOtherData.length > 0 && (
                                    <Box
                                      display='flex'
                                      width='100%'
                                      flexDirection='column'
                                      flexGrow={1}
                                    >
                                      <ChiefComplaintsSelector
                                        onSelectionChanges={(
                                          e: ChiefComplaintData
                                        ) => {
                                          const conditonVal: R4.ICoding = {
                                            code: e.icd,
                                            display: e.synonyms.trim(),
                                            system: 'http://snomed.info/sct',
                                          }
                                          if (e.cui === 'other-complaint') {
                                            setShowTextForChiefComplaint(false)
                                            setFullTextForChiefComplaint('')
                                            handleAnyOther(
                                              conditonVal,
                                              true,
                                              index
                                            )
                                          } else if (selectedCondition) {
                                            if (
                                              selectedCondition.code! ===
                                              conditonVal.code!
                                            ) {
                                              setShowTextForChiefComplaint(true)
                                              setFullTextForChiefComplaint(
                                                'Already selected as part of chief complaint'
                                              )
                                            } else if (
                                              associatedComplaints.length > 0
                                            ) {
                                              const filteredData: string[] = []

                                              for (
                                                let i = 0;
                                                i < associatedComplaints.length;
                                                i++
                                              ) {
                                                const { cui } =
                                                  associatedComplaints[i]
                                                if (
                                                  associatedComplaints[i]
                                                    .checked &&
                                                  cui &&
                                                  conditonVal.code
                                                ) {
                                                  const code = cui.code ?? ''
                                                  if (code.length > 0) {
                                                    if (
                                                      conditonVal.code === code
                                                    ) {
                                                      filteredData.push('1')
                                                    }
                                                  }
                                                }
                                              }
                                              // const filteredData =
                                              //   associatedComplaints.filter((d) =>
                                              //     d.cui &&
                                              //     d.checked === true &&
                                              //     d.cui.code &&
                                              //     conditonVal.code
                                              //       ? d.cui.code === conditonVal.code
                                              //       : []
                                              //   )
                                              if (filteredData.length === 1) {
                                                setFullText(
                                                  'Associated Mapped Symptoms Already Selected'
                                                )
                                                setShowText(true)
                                              } else {
                                                setShowText(false)
                                              }

                                              if (filteredData.length === 0)
                                                handleSelectedAssociatedComplaints(
                                                  conditonVal,
                                                  index
                                                )
                                              if (filteredData.length === 1)
                                                handleSelectedAssociatedComplaints(
                                                  conditonVal,
                                                  index
                                                )
                                            } else {
                                              setShowTextForChiefComplaint(
                                                false
                                              )
                                              setFullTextForChiefComplaint('')
                                              handleSelectedAssociatedComplaints(
                                                conditonVal,
                                                index
                                              )
                                            }
                                          }
                                        }}
                                        preSelectedComplaints={
                                          additional.preselectedAssociated
                                        }
                                        disabled={false}
                                        selectOther={isActive}
                                        id='associated_chief_match_symp_other'
                                      />
                                      <WelloTextField
                                        title='Any Other'
                                        textProps={{
                                          id: `associated_chief_other`,
                                          type: 'text',
                                          //   value: additional.anyOtherData,
                                          defaultValue: additional.anyOtherData,
                                          error:
                                            validateOthers(
                                              additional.anyOtherData,
                                              false
                                            ).length > 0,
                                          helperText:
                                            validateOthers(
                                              additional.anyOtherData,
                                              false
                                            ).length > 0
                                              ? `${validateOthers(
                                                  additional.anyOtherData,
                                                  false
                                                )}`
                                              : '',
                                          onBlur: (changeData) => {
                                            if (
                                              changeData.target.value.trim()
                                                .length > 0
                                            ) {
                                              handleAnyOtherData(
                                                changeData.target.value.trim(),
                                                index
                                              )
                                            }
                                          },
                                          onChange: (changeData) => {
                                            // handleAnyOtherData(
                                            //   changeData.target.value,
                                            //   index
                                            // )
                                          },
                                        }}
                                      />
                                    </Box>
                                  )}
                                {additional.cui && (
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    width='100%'
                                    py={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      py={1}
                                    >
                                      <Box width='22%'>
                                        <WelloFormItemLabel title='Duration / Start Date' />{' '}
                                      </Box>
                                      <Box width='25%' paddingLeft={1}>
                                        {additional.typeData === 'days' && (
                                          <WelloSelectUnit
                                            title=''
                                            id='associated_days'
                                            duration='Select Duration Unit'
                                            availableCodings={
                                              additional.days === 1
                                                ? durationDataForChiefComplaintsWithOne
                                                : durationDataForChiefComplaints
                                            }
                                            onChanged={(type) => {
                                              if (
                                                type.display &&
                                                type.display.length > 0
                                              ) {
                                                if (
                                                  type.display === 'Days' ||
                                                  type.display === 'Day'
                                                )
                                                  handleTypeChange(
                                                    'days',
                                                    index
                                                  )
                                                else if (
                                                  type.display === 'Months' ||
                                                  type.display === 'Month'
                                                )
                                                  handleTypeChange(
                                                    'months',
                                                    index
                                                  )
                                                else if (
                                                  type.display === 'Weeks' ||
                                                  type.display === 'Week'
                                                )
                                                  handleTypeChange(
                                                    'weeks',
                                                    index
                                                  )
                                                else if (
                                                  type.display === 'Years' ||
                                                  type.display === 'Year'
                                                )
                                                  handleTypeChange(
                                                    'years',
                                                    index
                                                  )
                                                else
                                                  handleTypeChange(
                                                    'custom',
                                                    index
                                                  )
                                              }
                                              setDuration(type.display ?? '')
                                            }}
                                            textFieldProps={{
                                              size: 'small',
                                              fullWidth: true,
                                            }}
                                            preSelectedCoding={
                                              days === 1 || weeks === 1
                                                ? durationDataWithOne[0]
                                                : durationData[0]
                                            }
                                          />
                                        )}

                                        {additional.typeData === 'weeks' && (
                                          <WelloSelectUnit
                                            title=''
                                            id='associated_weeks'
                                            duration='Select Duration Unit'
                                            availableCodings={
                                              additional.weeks === 1
                                                ? durationDataForChiefComplaintsWithOne
                                                : durationDataForChiefComplaints
                                            }
                                            onChanged={(type) => {
                                              if (
                                                type.display &&
                                                type.display.length > 0
                                              ) {
                                                if (
                                                  type.display === 'Days' ||
                                                  type.display === 'Day'
                                                )
                                                  handleTypeChange(
                                                    'days',
                                                    index
                                                  )
                                                else if (
                                                  type.display === 'Months' ||
                                                  type.display === 'Month'
                                                )
                                                  handleTypeChange(
                                                    'months',
                                                    index
                                                  )
                                                else if (
                                                  type.display === 'Weeks' ||
                                                  type.display === 'Week'
                                                )
                                                  handleTypeChange(
                                                    'weeks',
                                                    index
                                                  )
                                                else if (
                                                  type.display === 'Years' ||
                                                  type.display === 'Year'
                                                )
                                                  handleTypeChange(
                                                    'years',
                                                    index
                                                  )
                                                else
                                                  handleTypeChange(
                                                    'custom',
                                                    index
                                                  )
                                              }
                                              setDuration(type.display ?? '')
                                            }}
                                            textFieldProps={{
                                              size: 'small',
                                              fullWidth: true,
                                            }}
                                            preSelectedCoding={
                                              weeks === 1
                                                ? durationDataWithOne[1]
                                                : durationData[1]
                                            }
                                          />
                                        )}

                                        {additional.typeData === 'months' && (
                                          <WelloSelectUnit
                                            title=''
                                            id='associated_months'
                                            duration='Select Duration Unit'
                                            availableCodings={
                                              additional.months === 1
                                                ? durationDataForChiefComplaintsWithOne
                                                : durationDataForChiefComplaints
                                            }
                                            onChanged={(type) => {
                                              if (
                                                type.display &&
                                                type.display.length > 0
                                              ) {
                                                if (
                                                  type.display === 'Days' ||
                                                  type.display === 'Day'
                                                )
                                                  handleTypeChange(
                                                    'days',
                                                    index
                                                  )
                                                else if (
                                                  type.display === 'Months' ||
                                                  type.display === 'Month'
                                                )
                                                  handleTypeChange(
                                                    'months',
                                                    index
                                                  )
                                                else if (
                                                  type.display === 'Weeks' ||
                                                  type.display === 'Week'
                                                )
                                                  handleTypeChange(
                                                    'weeks',
                                                    index
                                                  )
                                                else if (
                                                  type.display === 'Years' ||
                                                  type.display === 'Year'
                                                )
                                                  handleTypeChange(
                                                    'years',
                                                    index
                                                  )
                                                else
                                                  handleTypeChange(
                                                    'custom',
                                                    index
                                                  )
                                              }
                                              setDuration(type.display ?? '')
                                            }}
                                            textFieldProps={{
                                              size: 'small',
                                              fullWidth: true,
                                            }}
                                            preSelectedCoding={
                                              month === 1
                                                ? durationDataWithOne[2]
                                                : durationData[2]
                                            }
                                          />
                                        )}

                                        {additional.typeData === 'years' && (
                                          <WelloSelectUnit
                                            title=''
                                            id='associated_years'
                                            duration='Select Duration Unit'
                                            availableCodings={
                                              additional.year === 1
                                                ? durationDataForChiefComplaintsWithOne
                                                : durationDataForChiefComplaints
                                            }
                                            onChanged={(type) => {
                                              if (
                                                type.display &&
                                                type.display.length > 0
                                              ) {
                                                if (
                                                  type.display === 'Days' ||
                                                  type.display === 'Day'
                                                )
                                                  handleTypeChange(
                                                    'days',
                                                    index
                                                  )
                                                else if (
                                                  type.display === 'Months' ||
                                                  type.display === 'Month'
                                                )
                                                  handleTypeChange(
                                                    'months',
                                                    index
                                                  )
                                                else if (
                                                  type.display === 'Weeks' ||
                                                  type.display === 'Week'
                                                )
                                                  handleTypeChange(
                                                    'weeks',
                                                    index
                                                  )
                                                else if (
                                                  type.display === 'Years' ||
                                                  type.display === 'Year'
                                                )
                                                  handleTypeChange(
                                                    'years',
                                                    index
                                                  )
                                                else
                                                  handleTypeChange(
                                                    'custom',
                                                    index
                                                  )
                                              }
                                              setDuration(type.display ?? '')
                                            }}
                                            textFieldProps={{
                                              size: 'small',
                                              fullWidth: true,
                                            }}
                                            preSelectedCoding={
                                              year === 1
                                                ? durationDataWithOne[3]
                                                : durationData[3]
                                            }
                                          />
                                        )}

                                        {additional.typeData === 'custom' && (
                                          <WelloSelectUnit
                                            title=''
                                            id='associated_custom'
                                            duration='Select Duration Unit'
                                            availableCodings={
                                              year === 1 &&
                                              month === 1 &&
                                              weeks === 1 &&
                                              days === 1
                                                ? durationDataForChiefComplaintsWithOne
                                                : durationDataForChiefComplaints
                                            }
                                            onChanged={(type) => {
                                              if (
                                                type.display &&
                                                type.display.length > 0
                                              ) {
                                                if (
                                                  type.display === 'Days' ||
                                                  type.display === 'Day'
                                                )
                                                  handleTypeChange(
                                                    'days',
                                                    index
                                                  )
                                                else if (
                                                  type.display === 'Months' ||
                                                  type.display === 'Month'
                                                )
                                                  handleTypeChange(
                                                    'months',
                                                    index
                                                  )
                                                else if (
                                                  type.display === 'Weeks' ||
                                                  type.display === 'Week'
                                                )
                                                  handleTypeChange(
                                                    'weeks',
                                                    index
                                                  )
                                                else if (
                                                  type.display === 'Years' ||
                                                  type.display === 'Year'
                                                )
                                                  handleTypeChange(
                                                    'years',
                                                    index
                                                  )
                                                else
                                                  handleTypeChange(
                                                    'custom',
                                                    index
                                                  )
                                              }
                                              setDuration(type.display ?? '')
                                            }}
                                            textFieldProps={{
                                              size: 'small',
                                              fullWidth: true,
                                            }}
                                            preSelectedCoding={
                                              durationDataForChiefComplaints[4]
                                            }
                                          />
                                        )}
                                      </Box>
                                      <Box width='3%' />
                                      {additional.typeData === 'days' && (
                                        <Box
                                          width='50%'
                                          display='flex'
                                          flexDirection='column'
                                        >
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                          >
                                            <Box width='18.6%' />
                                            <WelloFormItemLabel title='Enter Days' />{' '}
                                            <Box paddingLeft={1} width='60%'>
                                              <WelloTextFieldWithoutTitle
                                                textProps={{
                                                  id: ``,
                                                  value: additional.days,
                                                  type: 'number',
                                                  inputProps: {
                                                    max: getAgeInDays(
                                                      fhirAppointmentDetails.patient
                                                    ),
                                                    min: 1,
                                                  },
                                                  error:
                                                    additional.days < 1 ||
                                                    additional.days >
                                                      getAgeInDays(
                                                        fhirAppointmentDetails.patient
                                                      ) ||
                                                    Number.isNaN(
                                                      additional.days
                                                    ),
                                                  helperText:
                                                    additional.days < 1 ||
                                                    additional.days >
                                                      getAgeInDays(
                                                        fhirAppointmentDetails.patient
                                                      ) ||
                                                    Number.isNaN(
                                                      additional.days
                                                    )
                                                      ? `Days should be in between 1 to ${getAgeInDays(
                                                          fhirAppointmentDetails.patient
                                                        )}`
                                                      : '',
                                                  onChange: (rate) => {
                                                    handleAdditonalDays(
                                                      parseInt(
                                                        rate.target.value,
                                                        10
                                                      ),
                                                      index
                                                    )
                                                  },
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                      )}

                                      {additional.typeData === 'weeks' && (
                                        <Box
                                          width='50%'
                                          display='flex'
                                          flexDirection='column'
                                        >
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                          >
                                            <Box width='20.6%' />
                                            <WelloFormItemLabel title='Enter Weeks' />{' '}
                                            <Box paddingLeft={1} width='55%'>
                                              <WelloTextFieldWithoutTitle
                                                textProps={{
                                                  id: `associated_weeks_01`,
                                                  value: additional.weeks,
                                                  type: 'number',
                                                  inputProps: {
                                                    max: getAgeInWeeks(
                                                      fhirAppointmentDetails.patient
                                                    ),
                                                    min: 1,
                                                  },
                                                  error:
                                                    additional.weeks < 1 ||
                                                    additional.weeks >
                                                      getAgeInWeeks(
                                                        fhirAppointmentDetails.patient
                                                      ) ||
                                                    Number.isNaN(
                                                      additional.weeks
                                                    ),
                                                  helperText:
                                                    additional.weeks < 1 ||
                                                    additional.weeks >
                                                      getAgeInWeeks(
                                                        fhirAppointmentDetails.patient
                                                      ) ||
                                                    Number.isNaN(
                                                      additional.weeks
                                                    )
                                                      ? `Weeks should be in between 1 to ${getAgeInWeeks(
                                                          fhirAppointmentDetails.patient
                                                        )}`
                                                      : '',
                                                  onChange: (rate) => {
                                                    handleAdditonalWeeks(
                                                      parseInt(
                                                        rate.target.value,
                                                        10
                                                      ),
                                                      index
                                                    )
                                                  },
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                      )}

                                      {additional.typeData === 'months' && (
                                        <Box
                                          width='50%'
                                          display='flex'
                                          flexDirection='column'
                                        >
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                          >
                                            <Box width='18.3%' />
                                            <WelloFormItemLabel title='Enter Months' />{' '}
                                            <Box paddingLeft={1} width='55%'>
                                              <WelloTextFieldWithoutTitle
                                                textProps={{
                                                  id: `associated_weeks_01`,
                                                  value: additional.months,
                                                  type: 'number',
                                                  inputProps: {
                                                    max: getAgeInMonths(
                                                      fhirAppointmentDetails.patient
                                                    ),
                                                    min: 1,
                                                  },
                                                  error:
                                                    additional.months < 1 ||
                                                    additional.months >
                                                      getAgeInMonths(
                                                        fhirAppointmentDetails.patient
                                                      ) ||
                                                    Number.isNaN(
                                                      additional.months
                                                    ),
                                                  helperText:
                                                    additional.months < 1 ||
                                                    additional.months >
                                                      getAgeInMonths(
                                                        fhirAppointmentDetails.patient
                                                      ) ||
                                                    Number.isNaN(
                                                      additional.months
                                                    )
                                                      ? `Months should be in between 1 to ${getAgeInMonths(
                                                          fhirAppointmentDetails.patient
                                                        )}`
                                                      : '',
                                                  onChange: (rate) => {
                                                    handleAdditionalMonths(
                                                      parseInt(
                                                        rate.target.value,
                                                        10
                                                      ),
                                                      index
                                                    )
                                                  },
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                      )}

                                      {additional.typeData === 'years' && (
                                        <Box
                                          width='50%'
                                          display='flex'
                                          flexDirection='column'
                                        >
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                          >
                                            <Box width='22.4%' />
                                            <WelloFormItemLabel title='Enter Years' />{' '}
                                            <Box paddingLeft={1} width='55%'>
                                              <WelloTextFieldWithoutTitle
                                                textProps={{
                                                  id: `associated_weeks_01`,
                                                  value: additional.year,
                                                  type: 'number',
                                                  inputProps: {
                                                    max: getAgeInYears(
                                                      fhirAppointmentDetails.patient
                                                    ),
                                                    min: 1,
                                                  },
                                                  error:
                                                    additional.year < 1 ||
                                                    additional.year >
                                                      getAgeInYears(
                                                        fhirAppointmentDetails.patient
                                                      ) ||
                                                    Number.isNaN(
                                                      additional.year
                                                    ),
                                                  helperText:
                                                    additional.year < 1 ||
                                                    additional.year >
                                                      getAgeInYears(
                                                        fhirAppointmentDetails.patient
                                                      ) ||
                                                    Number.isNaN(
                                                      additional.year
                                                    )
                                                      ? `Year should be in between 1 to ${getAgeInYears(
                                                          fhirAppointmentDetails.patient
                                                        )}`
                                                      : '',
                                                  onChange: (rate) => {
                                                    handleAdditionalYears(
                                                      parseInt(
                                                        rate.target.value,
                                                        10
                                                      ),
                                                      index
                                                    )
                                                  },
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                      )}

                                      {additional.typeData === 'custom' && (
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='50%'
                                          paddingLeft={0.5}
                                        >
                                          <MuiPickersUtilsProvider
                                            utils={MomentUtils}
                                          >
                                            {' '}
                                            <Box
                                              display='flex'
                                              flexDirection='row'
                                              width='100%'
                                            >
                                              <Box width='30%' />
                                              <WelloFormItemLabel title='Date' />{' '}
                                              <Box paddingLeft={1} width='60%'>
                                                <KeyboardDatePicker
                                                  clearable
                                                  id='date_associated'
                                                  maxDate={moment(
                                                    additional.date
                                                  )}
                                                  minDate={moment(
                                                    getAgeOfPatientForChecking(
                                                      fhirAppointmentDetails.patient
                                                    )
                                                  )}
                                                  value={additional.date}
                                                  format='DD-MM-YYYY'
                                                  onChange={(e) =>
                                                    handleDateChangeForAssociated(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                  error={
                                                    validateCheckoutDateForChiefComplaints(
                                                      moment(
                                                        additional.date
                                                      ).format('YYYY-MM-DD'),
                                                      getAgeOfPatientForChecking(
                                                        fhirAppointmentDetails.patient
                                                      ),
                                                      false
                                                    ).length > 0
                                                  }
                                                  helperText={validateCheckoutDateForChiefComplaints(
                                                    moment(
                                                      additional.date
                                                    ).format('YYYY-MM-DD'),
                                                    getAgeOfPatientForChecking(
                                                      fhirAppointmentDetails.patient
                                                    ),
                                                    false
                                                  )}
                                                  size='small'
                                                  inputVariant='outlined'
                                                />
                                              </Box>
                                            </Box>
                                          </MuiPickersUtilsProvider>
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        {/* <Box
                        display='flex'
                        alignItems='center'
                        py={1}
                        flexDirection='row'
                      >
                        {associatedComplaints.length - 1 === index &&
                          associatedComplaints.length < 4 &&
                          additional.cui && (
                            <Box>
                              <Button
                                variant='contained'
                                size='small'
                                color='primary'
                                onClick={handleAddMore}
                              >
                                <Typography variant='subtitle1'>
                                  {' '}
                                  + Add More Associated Complaints
                                </Typography>
                              </Button>
                            </Box>
                          )}
                      </Box> */}
                      </Box>
                      {/* {associatedComplaints.length !== 1 && (
                      <Box display='flex' justifyContent='center' p={0.5}>
                        <DeleteOutlineOutlined
                          fontSize='medium'
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setShowDeleteConfirmation({
                              open: true,
                              onContinueActionClicked: () => {
                                setShowDeleteConfirmation({
                                  open: false,
                                })
                                handleRemove(index)
                              },
                              onCancelActionClicked: () => {
                                setShowDeleteConfirmation({
                                  open: false,
                                })
                              },
                              displayText:
                                'Are you sure you want to delete this record?',
                              continueActionButtonText: 'Delete',
                              cancelActionButtonText: 'Cancel',
                            })
                          }}
                        />
                      </Box>
                    )} */}
                    </Box>
                  )
                )}
              </Box>
            )}

            <Box flexDirection='row' display='flex' paddingX={0.5}>
              <Box>
                <Checkbox
                  edge='start'
                  checked={checked}
                  onChange={handleChange}
                  tabIndex={-1}
                  size='small'
                  style={{ paddingTop: 0 }}
                  id='pain'
                  disableRipple
                  color='primary'
                  inputProps={{ 'aria-labelledby': 'consent' }}
                />
              </Box>
              <Box>
                <Typography
                  variant='subtitle1'
                  style={{ textTransform: 'capitalize', color: 'black' }}
                >
                  Add Pain Profile
                </Typography>
              </Box>
            </Box>
            {checked && (
              <Box
                display='flex'
                width='100%'
                flexDirection='column'
                flexGrow={1}
              >
                <Box
                  display='flex'
                  width='100%'
                  flexDirection='column'
                  flexGrow={1}
                >
                  <Box display='flex' flexDirection='row'>
                    <WelloFormItemLabel
                      title={t('labelCondition:attr_labels.Condition.bodySite')}
                    />{' '}
                  </Box>
                  <Box>
                    <LocationMasterSelector
                      onSelectionChanges={(e: LocationData[]) => {
                        const conditonValArr: R4.ICoding[] = []
                        for (let i = 0; i < e.length; i++) {
                          const conditonVal: R4.ICoding = {
                            code: e[i].cui,
                            display: e[i].display
                              .replace('SNOMED', '')
                              .replace(/[0-9]/g, '')
                              .trim(),
                            system:
                              'http://terminology.hl7.org/CodeSystem/umls',
                          }
                          conditonValArr.push(conditonVal)
                        }

                        setLocations(conditonValArr)
                      }}
                      disabled={false}
                      id='location'
                    />
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='100%'
                  flexDirection='column'
                  flexGrow={1}
                >
                  <Box display='flex' flexDirection='row'>
                    <WelloFormItemLabel title='ONSET DURATION' />{' '}
                  </Box>

                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    py={1}
                  >
                    <Box display='flex' flexDirection='row' width='100%' py={1}>
                      <Box width='22%'>
                        <WelloFormItemLabel title='Duration / Start Date' />{' '}
                      </Box>
                      <Box width='25%'>
                        <WelloSelectUnit
                          title=''
                          duration='Select Duration Unit'
                          availableCodings={
                            painDuration === 1
                              ? durationDataWithOne
                              : durationData
                          }
                          onChanged={(type) => {
                            setDurationPain(type.display ?? '')
                          }}
                          id='paint_unit'
                          textFieldProps={{
                            id: `chief_id_23`,
                            size: 'small',
                            fullWidth: true,
                          }}
                          preSelectedCoding={
                            painDuration === 1
                              ? durationDataWithOne[0]
                              : durationData[0]
                          }
                        />
                      </Box>
                      <Box width='3%' />
                      {(durationPain === 'Days' || durationPain === 'Day') && (
                        <Box width='50%' display='flex' flexDirection='column'>
                          <Box display='flex' flexDirection='row' width='100%'>
                            <Box width='18.6%' />
                            <WelloFormItemLabel title='Enter Days' />{' '}
                            <Box paddingLeft={1} width='60%'>
                              <WelloTextFieldWithoutTitle
                                textProps={{
                                  id: `pain_days`,
                                  placeholder: 'Enter days',
                                  value: painDuration,
                                  type: 'number',
                                  fullWidth: true,
                                  inputProps: {
                                    max: getAgeInDays(
                                      fhirAppointmentDetails.patient
                                    ),
                                    min: 1,
                                  },

                                  error:
                                    painDuration < 1 ||
                                    painDuration >
                                      getAgeInDays(
                                        fhirAppointmentDetails.patient
                                      ) ||
                                    Number.isNaN(painDuration),
                                  helperText:
                                    painDuration < 1 ||
                                    painDuration >
                                      getAgeInDays(
                                        fhirAppointmentDetails.patient
                                      ) ||
                                    Number.isNaN(painDuration)
                                      ? `Days should be in between 1 to ${getAgeInDays(
                                          fhirAppointmentDetails.patient
                                        )}`
                                      : '',
                                  onChange: (rate) => {
                                    setPainDuration(
                                      parseInt(rate.target.value, 10)
                                    )
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}

                      {(durationPain === 'Months' ||
                        durationPain === 'Month') && (
                        <Box width='50%' display='flex' flexDirection='column'>
                          <Box display='flex' flexDirection='row' width='100%'>
                            <Box width='18.6%' />
                            <WelloFormItemLabel title='Enter Months' />{' '}
                            <Box paddingLeft={1} width='55%'>
                              <WelloTextFieldWithoutTitle
                                textProps={{
                                  id: `pain_month`,
                                  placeholder: 'Enter months in Nos.',
                                  value: painDuration,
                                  type: 'number',
                                  inputProps: {
                                    max: getAgeInMonths(
                                      fhirAppointmentDetails.patient
                                    ),
                                    min: 1,
                                  },

                                  error:
                                    painDuration < 1 ||
                                    painDuration >
                                      getAgeInMonths(
                                        fhirAppointmentDetails.patient
                                      ) ||
                                    Number.isNaN(painDuration),
                                  helperText:
                                    painDuration < 1 ||
                                    painDuration >
                                      getAgeInMonths(
                                        fhirAppointmentDetails.patient
                                      ) ||
                                    Number.isNaN(painDuration)
                                      ? `Months should be in between 1 to ${getAgeInMonths(
                                          fhirAppointmentDetails.patient
                                        )}`
                                      : '',
                                  onChange: (rate) => {
                                    setPainDuration(
                                      parseInt(rate.target.value, 10)
                                    )
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}

                      {(durationPain === 'Weeks' ||
                        durationPain === 'Week') && (
                        <Box width='50%' display='flex' flexDirection='column'>
                          <Box display='flex' flexDirection='row' width='100%'>
                            <Box width='22.4%' />
                            <WelloFormItemLabel title='Enter Years' />{' '}
                            <Box paddingLeft={1} width='55%'>
                              <WelloTextFieldWithoutTitle
                                textProps={{
                                  id: `pain_weeks`,
                                  placeholder: 'Enter Weeks in Nos.',
                                  value: painDuration,
                                  type: 'number',
                                  inputProps: {
                                    max: getAgeInWeeks(
                                      fhirAppointmentDetails.patient
                                    ),
                                    min: 1,
                                  },

                                  error:
                                    painDuration < 1 ||
                                    painDuration >
                                      getAgeInWeeks(
                                        fhirAppointmentDetails.patient
                                      ) ||
                                    Number.isNaN(painDuration),
                                  helperText:
                                    painDuration < 1 ||
                                    painDuration >
                                      getAgeInWeeks(
                                        fhirAppointmentDetails.patient
                                      ) ||
                                    Number.isNaN(painDuration)
                                      ? `Weeks should be in between 1 to ${getAgeInWeeks(
                                          fhirAppointmentDetails.patient
                                        )}`
                                      : '',
                                  onChange: (rate) => {
                                    setPainDuration(
                                      parseInt(rate.target.value, 10)
                                    )
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}

                      {(durationPain === 'Years' ||
                        durationPain === 'Year') && (
                        <Box width='50%' display='flex' flexDirection='column'>
                          <Box display='flex' flexDirection='row' width='100%'>
                            <Box width='30%' />
                            <WelloFormItemLabel title='Date' />{' '}
                            <Box paddingLeft={1} width='60%'>
                              <WelloTextFieldWithoutTitle
                                textProps={{
                                  id: `pain_years`,
                                  placeholder: 'Enter years in Nos.',
                                  value: painDuration,
                                  type: 'number',
                                  inputProps: {
                                    max: getAgeInYears(
                                      fhirAppointmentDetails.patient
                                    ),
                                    min: 1,
                                  },

                                  error:
                                    painDuration < 1 ||
                                    painDuration >
                                      getAgeInYears(
                                        fhirAppointmentDetails.patient
                                      ) ||
                                    Number.isNaN(painDuration),
                                  helperText:
                                    painDuration < 1 ||
                                    painDuration >
                                      getAgeInYears(
                                        fhirAppointmentDetails.patient
                                      ) ||
                                    Number.isNaN(painDuration)
                                      ? `Years should be in between 1 to ${getAgeInYears(
                                          fhirAppointmentDetails.patient
                                        )}`
                                      : '',
                                  onChange: (rate) => {
                                    setPainDuration(
                                      parseInt(rate.target.value, 10)
                                    )
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='100%'
                  flexDirection='column'
                  flexGrow={1}
                >
                  <WelloFormItemLabel
                    title={t('labelCommon:patient_experience')}
                  />{' '}
                  <WelloTextFieldWithoutTitle
                    textProps={{
                      id: `chief_id_24`,
                      type: 'text',
                      onChange: (changeData) => {
                        setCharacter(changeData.target.value)
                      },
                    }}
                  />
                </Box>
                {/* <Box
                display='flex'
                width='100%'
                flexDirection='column'
                flexGrow={1}
              >
                <WelloTextField
                  title={t('labelCommon:factors')}
                  textProps={{
                    id: `actor_email_unit`,
                    type: 'text',
                    onChange: (changeData) => {
                      setAlleviating(changeData.target.value)
                    },
                  }}
                />
              </Box> */}
                <Box
                  display='flex'
                  width='100%'
                  flexDirection='column'
                  flexGrow={1}
                >
                  <WelloFormItemLabel title={t('labelCommon:radiations')} />{' '}
                  <WelloTextFieldWithoutTitle
                    textProps={{
                      id: `chief_id_25`,
                      type: 'text',
                      multiline: true,
                      onChange: (changeData) => {
                        setRadiation(changeData.target.value)
                      },
                    }}
                  />
                </Box>
                <Box>
                  <WelloFormItemLabel title='Associated Symptoms' />{' '}
                </Box>
                <Box>
                  <ChiefComplaintsSelectorMultiple
                    onSelectionChanges={(e: ChiefComplaintData[]) => {
                      const conditonValArr: R4.ICoding[] = []
                      for (let i = 0; i < e.length; i++) {
                        const conditonVal: R4.ICoding = {
                          code: e[i].cui.toUpperCase(),
                          display: e[i].synonyms.trim(),
                          system: 'http://terminology.hl7.org/CodeSystem/umls',
                        }
                        conditonValArr.push(conditonVal)
                      }
                      setSlectedPainProfileSymptoms(conditonValArr)
                    }}
                    disabled={false}
                    selectOther={isActive}
                    id='pain_chief_match_symp'
                  />
                </Box>
                <Box>
                  {findDuplicate().length > 0 && (
                    <Box py={1}>
                      <Typography variant='subtitle1' color='error'>
                        Duplicate Symptoms can't be added.
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box
                  display='flex'
                  width='100%'
                  flexDirection='column'
                  flexGrow={1}
                >
                  <WelloFormItemLabel title='Time of aggravation (temporal patterns: is there a particular time of day when it is better or worse?)' />{' '}
                  <WelloTextFieldWithoutTitle
                    textProps={{
                      id: `chief_id_27`,
                      type: 'text',
                      multiline: true,
                      onChange: (changeData) => {
                        setTemporal(changeData.target.value)
                      },
                    }}
                  />
                </Box>
                <Box
                  display='flex'
                  width='100%'
                  flexDirection='column'
                  flexGrow={1}
                >
                  <WelloFormItemLabel title='Exacerbating & reliving factors' />{' '}
                  <WelloTextFieldWithoutTitle
                    textProps={{
                      id: `chief_id_28`,
                      type: 'text',
                      onChange: (changeData) => {
                        setAlleviating(changeData.target.value)
                      },
                    }}
                  />
                </Box>
                <Box
                  display='flex'
                  width='100%'
                  flexDirection='column'
                  flexGrow={1}
                >
                  <WelloChiefComplaints
                    title='Severity'
                    type={t('labCommon:SEVERITY')}
                    availableCodings={availableOptions}
                    onChanged={(e) => {
                      if (e) {
                        setSeverity(e)
                      }
                    }}
                    textFieldProps={{
                      id: `chief_id_29`,
                      size: 'small',
                      placeholder: t('Select Test'),
                      required: true,
                    }}
                  />
                </Box>
              </Box>
            )}

            {isAyurvedaDoctor() && (
              <Box
                display='flex'
                width='100%'
                flexDirection='column'
                flexGrow={1}
              >
                <WelloFormItemLabel title='Dosha Status' />
                <Box
                  width='100%'
                  display='flex'
                  flexDirection='column'
                  border='1px solid black'
                  borderRadius={2}
                >
                  <RadioSelectorForObservationComponent
                    id='doshaStatus'
                    code={{
                      text: 'Vata',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-2.1.1.1',
                          display: 'Vata',
                        },
                      ],
                    }}
                    options={[
                      {
                        text: 'Prakopam',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.2.1.2',
                            display: 'Prakopam',
                          },
                        ],
                      },
                      {
                        text: 'Kshayam',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.2.1.9',
                            display: 'Kshayam',
                          },
                        ],
                      },
                      {
                        text: 'Prakrutistham',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-4.8.17',
                            display: 'Prakrutistham',
                          },
                        ],
                      },
                    ]}
                    onSelectionChanges={(e: R4.IObservation_Component) => {
                      const existing = doshaStatuses.filter(
                        (eachStatus) =>
                          getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                          getDefaultCodeOfSystemFromCodableConcept(
                            eachStatus.code
                          )
                      )
                      existing.push(e)

                      setDoshaStatuses(existing)
                    }}
                  />

                  <RadioSelectorForObservationComponent
                    id='dosha_status_pita'
                    isOdd={true}
                    code={{
                      text: 'Pitta',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-2.1.2.1',
                          display: 'Pitta',
                        },
                      ],
                    }}
                    options={[
                      {
                        text: 'Prakopam',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.2.2.4',
                            display: 'Prakopam',
                          },
                        ],
                      },
                      {
                        text: 'Kshayam',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.2.2.6',
                            display: 'Kshayam',
                          },
                        ],
                      },
                      {
                        text: 'Prakrutistham',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-4.8.17',
                            display: 'Prakrutistham',
                          },
                        ],
                      },
                    ]}
                    onSelectionChanges={(e: R4.IObservation_Component) => {
                      const existing = doshaStatuses.filter(
                        (eachStatus) =>
                          getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                          getDefaultCodeOfSystemFromCodableConcept(
                            eachStatus.code
                          )
                      )
                      existing.push(e)

                      setDoshaStatuses(existing)
                    }}
                  />
                  <RadioSelectorForObservationComponent
                    id='dosha_status_kapha'
                    code={{
                      text: 'Kapha',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-2.1.3.1',
                          display: 'Kapha',
                        },
                      ],
                    }}
                    options={[
                      {
                        text: 'Prakopam',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.2.3.3',
                            display: 'Prakopam',
                          },
                        ],
                      },
                      {
                        text: 'Kshayam',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.2.3.5',
                            display: 'Kshayam',
                          },
                        ],
                      },
                      {
                        text: 'Prakrutistham',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-4.8.17',
                            display: 'Prakrutistham',
                          },
                        ],
                      },
                    ]}
                    onSelectionChanges={(e: R4.IObservation_Component) => {
                      const existing = doshaStatuses.filter(
                        (eachStatus) =>
                          getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                          getDefaultCodeOfSystemFromCodableConcept(
                            eachStatus.code
                          )
                      )
                      existing.push(e)

                      setDoshaStatuses(existing)
                    }}
                  />

                  <Grid
                    container
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: theme.spacing(1),
                      backgroundColor: kDialogueBackground,
                    }}
                  >
                    <Grid item xs={3}>
                      <Typography variant='body1' color='initial'>
                        Remarks
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      style={{
                        margin: '0px',
                      }}
                    >
                      <TextField
                        size='small'
                        id='auy_remarks'
                        multiline
                        fullWidth
                        type='string'
                        value={doshaRemarks}
                        variant='outlined'
                        onChange={(event) => {
                          setDoshaRemarks(event.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
            {isAyurvedaDoctor() && (
              <Box
                display='flex'
                width='100%'
                flexDirection='column'
                flexGrow={1}
              >
                <WelloFormItemLabel title='Dushya Status' />
                <Box
                  width='100%'
                  display='flex'
                  flexDirection='column'
                  border='1px solid black'
                  borderRadius={2}
                >
                  <RadioSelectorForObservationComponent
                    id='dushya_status'
                    code={{
                      text: 'Rasa',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-2.2.4',
                          display: 'Rasa',
                        },
                      ],
                    }}
                    options={[
                      {
                        text: 'Vruddhi',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.4',
                            display: 'Vruddhi',
                          },
                        ],
                      },
                      {
                        text: 'Kshayam',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.3',
                            display: 'Kshayam',
                          },
                        ],
                      },
                      {
                        text: 'Dushti',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.2',
                            display: 'Dushti',
                          },
                        ],
                      },
                      {
                        text: 'Prakrutistham',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-4.8.17',
                            display: 'Prakrutistham',
                          },
                        ],
                      },
                    ]}
                    onSelectionChanges={(e: R4.IObservation_Component) => {
                      const existing = dushyaStatuses.filter(
                        (eachStatus) =>
                          getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                          getDefaultCodeOfSystemFromCodableConcept(
                            eachStatus.code
                          )
                      )
                      existing.push(e)

                      setDushyaStatuses(existing)
                    }}
                  />

                  <RadioSelectorForObservationComponent
                    id='dushya_status_rakhta'
                    isOdd
                    code={{
                      text: 'Rakta',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-2.2.5',
                          display: 'Rakta',
                        },
                      ],
                    }}
                    options={[
                      {
                        text: 'Vruddhi',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.7',
                            display: 'Vruddhi',
                          },
                        ],
                      },
                      {
                        text: 'Kshayam',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.6',
                            display: 'Kshayam',
                          },
                        ],
                      },
                      {
                        text: 'Dushti',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.5',
                            display: 'Dushti',
                          },
                        ],
                      },
                      {
                        text: 'Prakrutistham',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-4.8.17',
                            display: 'Prakrutistham',
                          },
                        ],
                      },
                    ]}
                    onSelectionChanges={(e: R4.IObservation_Component) => {
                      const existing = dushyaStatuses.filter(
                        (eachStatus) =>
                          getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                          getDefaultCodeOfSystemFromCodableConcept(
                            eachStatus.code
                          )
                      )
                      existing.push(e)

                      setDushyaStatuses(existing)
                    }}
                  />
                  <RadioSelectorForObservationComponent
                    id='dushya_status_mamsa'
                    code={{
                      text: 'Mamsa',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-2.2.8',
                          display: 'Mamsa',
                        },
                      ],
                    }}
                    options={[
                      {
                        text: 'Vruddhi',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.14',
                            display: 'Vruddhi',
                          },
                        ],
                      },
                      {
                        text: 'Kshayam',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.1',
                            display: 'Kshayam',
                          },
                        ],
                      },
                      {
                        text: 'Dushti',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.12',
                            display: 'Dushti',
                          },
                        ],
                      },
                      {
                        text: 'Prakrutistham',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-4.8.17',
                            display: 'Prakrutistham',
                          },
                        ],
                      },
                    ]}
                    onSelectionChanges={(e: R4.IObservation_Component) => {
                      const existing = dushyaStatuses.filter(
                        (eachStatus) =>
                          getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                          getDefaultCodeOfSystemFromCodableConcept(
                            eachStatus.code
                          )
                      )
                      existing.push(e)

                      setDushyaStatuses(existing)
                    }}
                  />

                  <RadioSelectorForObservationComponent
                    id='dushya_status_meda'
                    isOdd
                    code={{
                      text: 'Meda',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-2.2.9',
                          display: 'Meda',
                        },
                      ],
                    }}
                    options={[
                      {
                        text: 'Vruddhi',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.17',
                            display: 'Vruddhi',
                          },
                        ],
                      },
                      {
                        text: 'Kshayam',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.16',
                            display: 'Kshayam',
                          },
                        ],
                      },
                      {
                        text: 'Dushti',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.15',
                            display: 'Dushti',
                          },
                        ],
                      },
                      {
                        text: 'Prakrutistham',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-4.8.17',
                            display: 'Prakrutistham',
                          },
                        ],
                      },
                    ]}
                    onSelectionChanges={(e: R4.IObservation_Component) => {
                      const existing = dushyaStatuses.filter(
                        (eachStatus) =>
                          getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                          getDefaultCodeOfSystemFromCodableConcept(
                            eachStatus.code
                          )
                      )
                      existing.push(e)

                      setDushyaStatuses(existing)
                    }}
                  />

                  <RadioSelectorForObservationComponent
                    id='dushya_status_asthi'
                    code={{
                      text: 'Asthi',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-2.2.10',
                          display: 'Asthi',
                        },
                      ],
                    }}
                    options={[
                      {
                        text: 'Vruddhi',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.20',
                            display: 'Vruddhi',
                          },
                        ],
                      },
                      {
                        text: 'Kshayam',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.19',
                            display: 'Kshayam',
                          },
                        ],
                      },
                      {
                        text: 'Dushti',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.18',
                            display: 'Dushti',
                          },
                        ],
                      },
                      {
                        text: 'Prakrutistham',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-4.8.17',
                            display: 'Prakrutistham',
                          },
                        ],
                      },
                    ]}
                    onSelectionChanges={(e: R4.IObservation_Component) => {
                      const existing = dushyaStatuses.filter(
                        (eachStatus) =>
                          getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                          getDefaultCodeOfSystemFromCodableConcept(
                            eachStatus.code
                          )
                      )
                      existing.push(e)

                      setDushyaStatuses(existing)
                    }}
                  />

                  <RadioSelectorForObservationComponent
                    id='dushya_status_majja'
                    isOdd
                    code={{
                      text: 'Majja',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-2.2.16',
                          display: 'Majja',
                        },
                      ],
                    }}
                    options={[
                      {
                        text: 'Vruddhi',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.23',
                            display: 'Vruddhi',
                          },
                        ],
                      },
                      {
                        text: 'Kshayam',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.22',
                            display: 'Kshayam',
                          },
                        ],
                      },
                      {
                        text: 'Dushti',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.21',
                            display: 'Dushti',
                          },
                        ],
                      },
                      {
                        text: 'Prakrutistham',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-4.8.17',
                            display: 'Prakrutistham',
                          },
                        ],
                      },
                    ]}
                    onSelectionChanges={(e: R4.IObservation_Component) => {
                      const existing = dushyaStatuses.filter(
                        (eachStatus) =>
                          getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                          getDefaultCodeOfSystemFromCodableConcept(
                            eachStatus.code
                          )
                      )
                      existing.push(e)

                      setDushyaStatuses(existing)
                    }}
                  />
                  <RadioSelectorForObservationComponent
                    id='dushya_status_shukra'
                    code={{
                      text: 'Shukra',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-2.2.19',
                          display: 'Shukra',
                        },
                      ],
                    }}
                    options={[
                      {
                        text: 'Vruddhi',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.26',
                            display: 'Vruddhi',
                          },
                        ],
                      },
                      {
                        text: 'Kshayam',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.25',
                            display: 'Kshayam',
                          },
                        ],
                      },
                      {
                        text: 'Dushti',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.3.24',
                            display: 'Dushti',
                          },
                        ],
                      },
                      {
                        text: 'Prakrutistham',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-4.8.17',
                            display: 'Prakrutistham',
                          },
                        ],
                      },
                    ]}
                    onSelectionChanges={(e: R4.IObservation_Component) => {
                      const existing = dushyaStatuses.filter(
                        (eachStatus) =>
                          getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                          getDefaultCodeOfSystemFromCodableConcept(
                            eachStatus.code
                          )
                      )
                      existing.push(e)

                      setDushyaStatuses(existing)
                    }}
                  />

                  <Grid
                    container
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: theme.spacing(1),
                      backgroundColor: '#f5f5f5',
                    }}
                  >
                    <Grid item xs={3}>
                      <Typography variant='body1' color='initial'>
                        Remarks
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        id='dushya_status_remark'
                        size='small'
                        fullWidth
                        multiline
                        type='string'
                        value={dushayRemarks}
                        variant='outlined'
                        onChange={(event) => {
                          setDushyaRemarks(event.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}

            {isAyurvedaDoctor() && (
              <Box
                display='flex'
                width='100%'
                flexDirection='column'
                flexGrow={1}
              >
                <WelloFormItemLabel title='Mala Status' />
                <Box
                  width='100%'
                  display='flex'
                  flexDirection='column'
                  border='1px solid black'
                  borderRadius={2}
                >
                  <RadioSelectorForObservationComponent
                    id='mala_status'
                    code={{
                      text: 'Mutra',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-2.4.4',
                          display: 'Mutra',
                        },
                      ],
                    }}
                    options={[
                      {
                        text: 'Vruddhi',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.4.4',
                            display: 'Vruddhi',
                          },
                        ],
                      },
                      {
                        text: 'Kshayam',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.4.3',
                            display: 'Kshayam',
                          },
                        ],
                      },
                      {
                        text: 'Dushti',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.4.2',
                            display: 'Dushti',
                          },
                        ],
                      },
                      {
                        text: 'Prakrutistham',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-4.8.17',
                            display: 'Prakrutistham',
                          },
                        ],
                      },
                    ]}
                    onSelectionChanges={(e: R4.IObservation_Component) => {
                      const existing = malaStatuses.filter(
                        (eachStatus) =>
                          getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                          getDefaultCodeOfSystemFromCodableConcept(
                            eachStatus.code
                          )
                      )
                      existing.push(e)

                      setMalaStatuses(existing)
                    }}
                  />

                  <RadioSelectorForObservationComponent
                    id='mala_status_puri'
                    isOdd
                    code={{
                      text: 'Purisha',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-2.4.3',
                          display: 'Purisha',
                        },
                      ],
                    }}
                    options={[
                      {
                        text: 'Vruddhi',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.4.7',
                            display: 'Vruddhi',
                          },
                        ],
                      },
                      {
                        text: 'Kshayam',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.4.6',
                            display: 'Kshayam',
                          },
                        ],
                      },
                      {
                        text: 'Dushti',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.4.5',
                            display: 'Dushti',
                          },
                        ],
                      },
                      {
                        text: 'Prakrutistham',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-4.8.17',
                            display: 'Prakrutistham',
                          },
                        ],
                      },
                    ]}
                    onSelectionChanges={(e: R4.IObservation_Component) => {
                      const existing = malaStatuses.filter(
                        (eachStatus) =>
                          getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                          getDefaultCodeOfSystemFromCodableConcept(
                            eachStatus.code
                          )
                      )
                      existing.push(e)

                      setMalaStatuses(existing)
                    }}
                  />
                  <RadioSelectorForObservationComponent
                    id='mala_status_sweda'
                    code={{
                      text: 'Sweda',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-2.4.5',
                          display: 'Sweda',
                        },
                      ],
                    }}
                    options={[
                      {
                        text: 'Vruddhi',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.4.11',
                            display: 'Vruddhi',
                          },
                        ],
                      },
                      {
                        text: 'Kshayam',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.4.10',
                            display: 'Kshayam',
                          },
                        ],
                      },
                      {
                        text: 'Dushti',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-5.4.9',
                            display: 'Dushti',
                          },
                        ],
                      },
                      {
                        text: 'Prakrutistham',
                        coding: [
                          {
                            system: 'http://www.who.int/ita',
                            code: 'ITA-4.8.17',
                            display: 'Prakrutistham',
                          },
                        ],
                      },
                    ]}
                    onSelectionChanges={(e: R4.IObservation_Component) => {
                      const existing = malaStatuses.filter(
                        (eachStatus) =>
                          getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                          getDefaultCodeOfSystemFromCodableConcept(
                            eachStatus.code
                          )
                      )
                      existing.push(e)

                      setMalaStatuses(existing)
                    }}
                  />

                  <Grid
                    container
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: theme.spacing(1),
                      backgroundColor: '#f5f5f5',
                    }}
                  >
                    <Grid item xs={3}>
                      <Typography variant='body1' color='initial'>
                        Remarks
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        size='small'
                        id='mala_status_sweda_text'
                        fullWidth
                        multiline
                        type='string'
                        value={malaRemarks}
                        variant='outlined'
                        onChange={(event) => {
                          setMalaRemarks(event.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
            <Box
              display='flex'
              width='100%'
              flexDirection='column'
              flexGrow={1}
            >
              <WelloFormItemLabel title='Additional Notes' />

              <TextField
                size='small'
                fullWidth
                multiline
                id='chief_id_30'
                type='string'
                value={additionalNotes}
                variant='outlined'
                onChange={(event) => {
                  setAdditionalNotes(event.target.value)
                }}
              />
            </Box>

            {showDeleteConfirmation.open && (
              <ConfirmationDialog
                {...showDeleteConfirmation}
                id='confirmDialog'
              />
            )}
          </Box>
        </SimpleBar>

        <WarningDialog
          id='1'
          open={showText}
          onClose={() => {
            setFullText('')
            setShowText(false)
          }}
          notesData={fullText!}
          dialogText='Message'
        />

        <WarningDialog
          id='603'
          open={showTextForChiefComplaint}
          onClose={() => {
            setFullTextForChiefComplaint('')
            setShowTextForChiefComplaint(false)
          }}
          notesData={fullTextForChiefComplaint!}
          dialogText='Message'
        />
      </DialogContent>
      <DialogActions>
        {chiefComplaintSlice.updatingConditions && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
          >
            <CircularProgress size={25} />
          </Box>
        )}
        <Box flexDirection='row' display='flex' paddingRight={4}>
          <Box paddingRight={1}>
            <Button
              onClick={() => {
                onClose()
                resetDetails()
              }}
              id='chief_id_32'
              variant='outlined'
              disableElevation
            >
              {t('labelCommon:cancel')}
            </Button>
          </Box>
          <Button
            onClick={() => {
              handleUpdate()
            }}
            variant='contained'
            color='primary'
            id='chief_id_31'
            disableElevation
            disabled={
              !!(
                !selectedCondition ||
                isEnableCreateButton() ||
                !patientWording ||
                patientWording!.length > 250 ||
                isEnableCreateButtonForAssociated().length > 0 ||
                findDuplicate().length > 0 ||
                isEnableCreateButtonPainProfile().length > 0 ||
                chiefComplaintSlice.updatingConditions
              )
            }
          >
            {t('labelCommon:add')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
