import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, makeStyles, Typography } from '@material-ui/core'

import React from 'react'
import { useDispatch } from 'react-redux'
import { CardsEntity } from 'redux/subscription/cpgRecommendations/cpgResponse'
import {
  getDefaultCodingOfSystemFromCodableConceptList,
  getSpecializationsList,
} from 'utils/fhirResourcesHelper'
import {
  getIdOfCardEntry,
  getAppointments,
  hasError,
} from 'utils/careplan_utils/cpg_recommendations_utils'
import { AddReferralsButton } from 'views/components/consultation/plan/cdsRecommendations/add_referrals_button'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',

    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))
interface CdsCardDetailsProps {
  patient: R4.IPatient
  encounterId: string
  carePlanIdentifier: string
  cdsCard: CardsEntity
}

export const ReferralsOfCPGRecommendation: React.FC<CdsCardDetailsProps> = ({
  patient,
  encounterId,
  carePlanIdentifier,
  cdsCard: card,
}: CdsCardDetailsProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  return (
    <Box display='flex' flexDirection='column' py={0.25} overflow='auto'>
      {!hasError(card) &&
        card.interceptedSuggestions &&
        card.interceptedSuggestions.has('specialistReferral') && (
          <Box
            display='flex'
            flexDirection='column'
            key='referrals_cpg_label'
            py={0.25}
            overflow='auto'
          >
            <Box
              display='flex'
              flexDirection='column'
              key='referrals_cpg_inner_label'
              py={0.25}
              overflow='auto'
            >
              <Box width='100%' flex='row' id='referrals_list' display='flex'>
                {(
                  card.interceptedSuggestions.get('specialistReferral') as any[]
                ).length > 0 && (
                  <Box width='100%' flex='row'>
                    {(
                      card.interceptedSuggestions.get(
                        'specialistReferral'
                      ) as any[]
                    ).length > 0 &&
                      (
                        card.interceptedSuggestions.get(
                          'specialistReferral'
                        ) as any[]
                      ).map((app) => (
                        <Typography
                          variant='body1'
                          key={getSpecializationsList(app.specialty ?? [])}
                        >
                          Consultation with{' '}
                          {getSpecializationsList(app.specialty ?? [])}
                        </Typography>
                      ))}
                  </Box>
                )}
              </Box>
              <Box
                width='100%'
                flexDirection='row-reverse'
                id='referrals_list'
                display='flex'
              >
                <AddReferralsButton
                  patient={patient}
                  encounterID={encounterId}
                  carePlanIdentifier={
                    carePlanIdentifier ?? getIdOfCardEntry(card)
                  }
                  appointments={(
                    card.interceptedSuggestions.get(
                      'specialistReferral'
                    ) as any[]
                  ).map(
                    (e) =>
                      getDefaultCodingOfSystemFromCodableConceptList(
                        e.specialty!
                      )!
                  )}
                />
              </Box>
            </Box>
          </Box>
        )}
    </Box>
  )
}
