import { R4 } from '@ahryman40k/ts-fhir-types'
import { Symptoms } from 'models/symptoms'
import moment from 'moment'
import {
  getStringDays,
  getStringMonth,
  getStringWeeks,
  getStringYear,
  getUnitAsString,
  getUnitAsStringForVal,
  getValueRefValueExtensionsOfUrl,
  getValueRefValueExtensionsOfUrlForPrescription,
} from 'utils/fhirResourcesHelper'
import { hasNotesForChiefForPatient } from './allergyHelpers'

export function getCommunicationRequestFromBundle(
  responseSlots: R4.IBundle
): R4.ICommunicationRequest[] {
  const convertedAppointments: R4.ICommunicationRequest[] = []
  const serviceRequests: any = {}

  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'CommunicationRequest':
                  serviceRequests[value.resource.id] =
                    value.resource as R4.ICommunicationRequest
                  break

                default:
                  break
              }
            }
          }
        })

        for (const key in serviceRequests) {
          if (key) {
            const currentAppointment: R4.ICommunicationRequest =
              serviceRequests[key] as R4.ICommunicationRequest

            convertedAppointments.push(currentAppointment)
          }
        }
      }
    }
  }

  return convertedAppointments
}

export function getCommunicationRequestPayloadString(
  responseSlots: R4.ICommunicationRequest
): string {
  let convertedAppointments: string = ''
  if (responseSlots.payload && responseSlots.payload.length > 0) {
    convertedAppointments = responseSlots.payload
      .map((e) => e.contentString ?? '')
      .join(',')
  }

  return convertedAppointments
}

export function getServiceRequestRequestPayloadString(
  responseSlots: R4.IServiceRequest
): string {
  let convertedAppointments: string = ''
  if (responseSlots.note && responseSlots.note.length > 0) {
    convertedAppointments = responseSlots.note
      .map((e) => e.text ?? '')
      .join(',')
  }

  return convertedAppointments
}

export function getSymptomsData(responseSlots: R4.IBundle): string[] {
  const convertedAppointments: string[] = []
  const serviceRequests: any = {}

  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Condition':
                  serviceRequests[value.resource.id] =
                    value.resource as R4.ICondition
                  break

                default:
                  break
              }
            }
          }
        })

        for (const key in serviceRequests) {
          if (key) {
            const currentAppointment: R4.ICondition = serviceRequests[
              key
            ] as R4.ICondition
            const data = hasNotesForChiefForPatient(currentAppointment.note!)
              ? hasNotesForChiefForPatient(currentAppointment.note!)?.text ?? ''
              : ''
            convertedAppointments.push(data)
          }
        }
      }
    }
  }

  return convertedAppointments
}

export function getSymptomsAlongWithAssociated(
  conditionResponse: R4.IBundle
): Symptoms[] {
  const convertedAppointments: string[] = []
  const finalSymptoms: Symptoms[] = []
  const serviceRequests: any = {}

  const conditionData: R4.ICondition[] = []
  const associatedComplaints: R4.ICondition[] = []

  if (conditionResponse.entry) {
    for (let i = 0; i < conditionResponse.entry.length; i++) {
      const condition: R4.ICondition = conditionResponse.entry[i]
        .resource as R4.ICondition

      const active =
        condition.clinicalStatus && condition.clinicalStatus.coding
          ? condition.clinicalStatus.coding[0].code === 'active'
          : false

      const cat: string =
        condition.category && condition.category[0].coding
          ? condition.category[0].coding[0].code ?? ''
          : ''

      if (cat !== 'associated-complaint') {
        conditionData.push(conditionResponse.entry[i].resource as R4.ICondition)
      } else {
        associatedComplaints.push(
          conditionResponse.entry[i].resource as R4.ICondition
        )
      }
    }

    for (let j = 0; j < conditionData.length; j++) {
      const finalCondition: R4.ICondition = conditionData[j]
      const id = finalCondition.id ?? ''

      const assichiefDataArray: string[] = []
      const filteredData = associatedComplaints.filter(
        (d: R4.ICondition) =>
          getValueRefValueExtensionsOfUrlForPrescription(
            d.extension,
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-chief-complaint-ext'
          ).split('/')[1]! === id
      )

      if (filteredData.length > 0) {
        for (let k = 0; k < filteredData.length; k++) {
          const conditionDataAssociated: R4.ICondition = filteredData[k]
          const typeData = conditionDataAssociated.onsetAge
            ? getUnitAsStringForVal(conditionDataAssociated.onsetAge.unit ?? '')
            : conditionDataAssociated.onsetDateTime
            ? 'custom'
            : ''
          const days = conditionDataAssociated.onsetAge
            ? getUnitAsString(conditionDataAssociated.onsetAge.unit ?? '') ===
              'Days'
              ? conditionDataAssociated.onsetAge.value ?? 0
              : 0
            : 0
          const weeks = conditionDataAssociated.onsetAge
            ? getUnitAsString(conditionDataAssociated.onsetAge.unit ?? '') ===
              'Weeks'
              ? conditionDataAssociated.onsetAge.value ?? 0
              : 0
            : 0
          const months = conditionDataAssociated.onsetAge
            ? getUnitAsString(conditionDataAssociated.onsetAge.unit ?? '') ===
              'Months'
              ? conditionDataAssociated.onsetAge.value ?? 0
              : 0
            : 0
          const year = conditionDataAssociated.onsetAge
            ? getUnitAsString(conditionDataAssociated.onsetAge.unit ?? '') ===
              'Years'
              ? conditionDataAssociated.onsetAge.value ?? 0
              : 0
            : 0
          let data = hasNotesForChiefForPatient(filteredData[k].note!)
            ? hasNotesForChiefForPatient(filteredData[k].note!)?.text ?? ''
            : ''

          if (typeData === 'custom') {
            const date = conditionDataAssociated.onsetDateTime
              ? moment(conditionDataAssociated.onsetDateTime).toDate()
              : new Date()

            if (date) {
              data = `${data} (${date})`
            }
          }

          if (year && year > 0) {
            data = `${data} (${year} ${getStringYear(year)})`
          }

          if (months && months > 0) {
            data = `${data} (${months} ${getStringMonth(months)})`
          }
          if (weeks && weeks > 0) {
            data = `${data} (${weeks} ${getStringWeeks(weeks)})`
          }
          if (days && days > 0) {
            data = `${data} (${days} ${getStringDays(days)})`
          }
          assichiefDataArray.push(data)
        }
      }

      const catData: string =
        finalCondition.category && finalCondition.category[0].coding
          ? finalCondition.category[0].coding[0].code ?? ''
          : ''

      if (catData !== 'associated-complaint') {
        const type = finalCondition.onsetAge
          ? getUnitAsStringForVal(finalCondition.onsetAge.unit ?? '')
          : finalCondition.onsetDateTime
          ? 'custom'
          : ''
        const days = finalCondition.onsetAge
          ? getUnitAsString(finalCondition.onsetAge.unit ?? '') === 'Days'
            ? finalCondition.onsetAge.value ?? 0
            : 0
          : 0
        const weeks = finalCondition.onsetAge
          ? getUnitAsString(finalCondition.onsetAge.unit ?? '') === 'Weeks'
            ? finalCondition.onsetAge.value ?? 0
            : 0
          : 0
        const months = finalCondition.onsetAge
          ? getUnitAsString(finalCondition.onsetAge.unit ?? '') === 'Months'
            ? finalCondition.onsetAge.value ?? 0
            : 0
          : 0
        const year = finalCondition.onsetAge
          ? getUnitAsString(finalCondition.onsetAge.unit ?? '') === 'Years'
            ? finalCondition.onsetAge.value ?? 0
            : 0
          : 0
        const date = finalCondition.onsetDateTime
          ? moment(finalCondition.onsetDateTime).toDate()
          : new Date()

        let data = hasNotesForChiefForPatient(finalCondition.note!)
          ? hasNotesForChiefForPatient(finalCondition.note!)?.text ?? ''
          : ''

        if (type === 'custom') {
          if (date) {
            data = `${data} (${date})`
          }
        }
        if (year > 0) {
          data = `${data} (${year} ${getStringYear(year)})`
        }

        if (months > 0) {
          data = `${data} (${months} ${getStringMonth(months)})`
        }
        if (weeks && weeks > 0) {
          data = `${data} (${weeks} ${getStringWeeks(weeks)})`
        }
        if (days && days > 0) {
          data = `${data} (${days} ${getStringDays(days)})`
        }

        const fSymptoms: Symptoms = {
          mainSymptoms: data,
          associatedSymptoms: [],
        }
        finalSymptoms.push(fSymptoms)
        if (assichiefDataArray.length > 0) {
          for (let i = 0; i < assichiefDataArray.length; i++) {
            const fSymptomsData: Symptoms = {
              mainSymptoms: assichiefDataArray[i],
              associatedSymptoms: [],
            }
            finalSymptoms.push(fSymptomsData)
          }
        }
      }
    }
  }

  return finalSymptoms
}

export function getServiceRequestFromBundle(
  responseSlots: R4.IBundle
): R4.IServiceRequest[] {
  const convertedAppointments: R4.IServiceRequest[] = []
  const serviceRequests: any = {}

  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ServiceRequest':
                  serviceRequests[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break

                default:
                  break
              }
            }
          }
        })

        for (const key in serviceRequests) {
          if (key) {
            const currentAppointment: R4.IServiceRequest = serviceRequests[
              key
            ] as R4.IServiceRequest

            convertedAppointments.push(currentAppointment)
          }
        }
      }
    }
  }

  return convertedAppointments
}
