import { AppointmentStatusKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Box, useTheme } from '@material-ui/core'
import {
  bindHover,
  bindPopover,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import moment from 'moment'
import React from 'react'
import { getDurationString, getStartAndEndTimeInString } from 'utils/dateUtil'
import {
  getMiddleNameOfPatient,
  getNameFromHumanName,
  getProfilePicPatient,
  getSpecializationsList,
} from 'utils/fhirResourcesHelper'
import { getLastNameOfPatient, getNameOfPatient } from 'wello-web-components'
import { AppointmentDayView } from './appointmentDayView'
import { AppointmentActionPopUp } from './appointmentPopUp'
import { AppointmentWeekView } from './appointmentWeekView'

interface AppointmentTileProps {
  appointmentDetail: FhirAppointmentDetail
  onCancelClicked: () => void
  onRescheduleClicked: () => void
  onViewClicked: () => void
  onDownloadClicked: () => void
  selectedOption: string
}

export const AppointmentTile: React.FC<AppointmentTileProps> = ({
  appointmentDetail,
  onCancelClicked,
  onViewClicked,
  onRescheduleClicked,
  onDownloadClicked,
  selectedOption,
}: AppointmentTileProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const primColor: string = useTheme().palette.primary.main
  const [hover, SetHover] = React.useState(false)
  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget)
  }
  const diff = moment(appointmentDetail.end).diff(
    appointmentDetail.start,
    'minutes'
  )

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  })

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'mouse-over-popover' : undefined

  return (
    <Box display='flex' flexDirection='row' {...bindHover(popupState)}>
      {(selectedOption === 'timeGridDay' ||
        selectedOption === 'dayGridMonth') && (
        <AppointmentDayView appointmentDetail={appointmentDetail} diff={diff} />
      )}
      {selectedOption === 'timeGridWeek' && (
        <AppointmentWeekView
          appointmentDetail={appointmentDetail}
          diff={diff}
        />
      )}

      <HoverPopover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 10,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        // style={{ zIndex: 10000 }}
      >
        <AppointmentActionPopUp
          primaryButtonName={
            appointmentDetail.appointment.status ===
            AppointmentStatusKind._booked
              ? appointmentDetail.encounter
                ? 'Continue'
                : 'Start Appointment'
              : 'View Appointment'
          }
          //   showViewProfile={
          //     !(
          //       moment(appointmentDetail.start).diff(new Date(), 'days') > 1 &&
          //       moment(appointmentDetail.start).isAfter(new Date())
          //     )
          //   }
          showStartButton={!appointmentDetail.enabled}
          patientName={`${
            getNameOfPatient(appointmentDetail.patient) ?? 'NA'
          } ${getMiddleNameOfPatient(appointmentDetail.patient) ?? ''} ${
            getLastNameOfPatient(appointmentDetail.patient) ?? ''
          }`}
          patientPic={getProfilePicPatient(appointmentDetail.patient)}
          //   onCloseClicked={handleClose}
          practitionerName={getNameFromHumanName(
            appointmentDetail.practitionerDetail.practitioner.name ?? []
          )}
          practitionerSpecialties={getSpecializationsList(
            appointmentDetail.practitionerDetail.practitionerRole.specialty ??
              []
          )}
          showMenuOptions={false}
          showCancelOption={moment(appointmentDetail.start).isAfter(
            moment(new Date())
          )}
          showDownloadInvoice={
            appointmentDetail.paymentReconcilation
              ? appointmentDetail.paymentReconcilation.status === 'active' &&
                appointmentDetail.paymentReconcilation.outcome === 'complete'
              : false
          }
          showRescheduleOption={moment(appointmentDetail.start).isAfter(
            moment(new Date())
          )}
          duration={getDurationString(
            appointmentDetail.start,
            appointmentDetail.end
          )}
          timeStartAndEnd={getStartAndEndTimeInString(
            appointmentDetail.start,
            appointmentDetail.end
          )}
          onViewProfileClicked={onViewClicked}
          onDownloadInvoiceClicked={onDownloadClicked}
          onCancelClicked={onCancelClicked}
          onRescheduleClicked={onRescheduleClicked}
          //   paymentStatus='Paid'
          paymentStatus={
            appointmentDetail.paymentReconcilation &&
            appointmentDetail.paymentReconcilation.outcome === 'complete'
              ? 'Paid'
              : 'Pending'
          }
        />
      </HoverPopover>
    </Box>
  )
}
