import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { requestAddictionsHistoryOfPatient } from 'redux/patientMedicalHistory/addictionsHistory/addictionsHistorySlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getVitalsObservationObjectForOPD } from 'utils/fhirResoureHelpers/observationHelpers'
// import { requestVitalDetailsOfPatientIPD } from '../vitalsDetailsIpd/vitalsDetailSliceIpd'
import { AddictionAddStatus } from './addictionsAddStatus'

const initialState: AddictionAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addAddictionsOPD = createSlice({
  name: 'addAddictionsOPD',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<AddictionAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addedVitalsBundle = action.payload.addedVitalsBundle
    },

    resetAddVitalsDetails(state, action: PayloadAction<AddictionAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addedVitalsBundle = initialState.addedVitalsBundle
    },
  },
})

export const addAddictionsOP =
  (
    appointment: FhirAppointmentDetail,
    smoke?: R4.ICoding,
    tobaco?: R4.ICoding,
    alcohol?: R4.ICoding,
    tea?: R4.ICoding,
    cofee?: R4.ICoding,
    drug?: R4.ICoding
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AddictionAddStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(addAddictionsOPD.actions.updateAddVitalsStatus(addingState))

    try {
      const bundleObject: R4.IBundle = createBundleObjectForObservations(
        appointment,
        smoke,
        tobaco,
        alcohol,
        tea,
        cofee,
        drug
      )

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        bundleObject
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }

        dispatch(
          requestAddictionsHistoryOfPatient(
            appointment.appointment.id!,
            appointment.patient
          )
        )
        dispatch(showSuccessAlert('Addictions Added Successfully'))

        dispatch(addAddictionsOPD.actions.updateAddVitalsStatus(addingState))
      } else {
        const errorCreatePersonState: AddictionAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while adding addictions',
        }
        dispatch(
          addAddictionsOPD.actions.updateAddVitalsStatus(errorCreatePersonState)
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: AddictionAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while adding addictions',
      }
      dispatch(
        addAddictionsOPD.actions.updateAddVitalsStatus(errorCreatePersonState)
      )
    }
  }

export const resetAddictions = () => (dispatch: AppDispatch) => {
  dispatch(addAddictionsOPD.actions.resetAddVitalsDetails(initialState))
}

export function createBundleObjectForObservations(
  appointment: FhirAppointmentDetail,
  smoke?: R4.ICoding,
  tobaco?: R4.ICoding,
  alcohol?: R4.ICoding,
  tea?: R4.ICoding,
  cofee?: R4.ICoding,
  drug?: R4.ICoding
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const encounterRef: R4.IReference = {
    reference: `Encounter/urn:uuid:1232323232324`,
    type: 'Encounter',
  }

  if (smoke != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForOPD(appointment, encounterRef),
    }
    observationObject.extension = [
      {
        url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
        valueReference: {
          reference: `Appointment/${appointment.appointment.id!}`,
        },
      },
    ]

    observationObject.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationSocialHistory',
      ],
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Smoke',
      coding: [
        {
          code: '64218-1',
          display: 'Cigarettes',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'social-history',
            display: 'Social History',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: smoke.display ?? '',
      coding: [smoke],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (tobaco != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForOPD(appointment, encounterRef),
    }
    observationObject.extension = [
      {
        url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
        valueReference: {
          reference: `Appointment/${appointment.appointment.id!}`,
        },
      },
    ]

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationActivity',
      ],
    }
    observationObject.code = {
      text: 'Tobacco/Paan/Gutkha',
      coding: [
        {
          code: '96103-7',
          display: 'Chewing Tobacco',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'social-history',
            display: 'Social History',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: tobaco.display ?? '',
      coding: [tobaco],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (alcohol != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForOPD(appointment, encounterRef),
    }
    observationObject.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationActivity',
      ],
    }
    observationObject.extension = [
      {
        url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
        valueReference: {
          reference: `Appointment/${appointment.appointment.id!}`,
        },
      },
    ]
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Alcohol',
      coding: [
        {
          code: '68518-0',
          display: 'Alcohol',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'social-history',
            display: 'Social History',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: alcohol.display ?? '',
      coding: [alcohol],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (tea != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForOPD(appointment, encounterRef),
    }
    observationObject.extension = [
      {
        url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
        valueReference: {
          reference: `Appointment/${appointment.appointment.id!}`,
        },
      },
    ]
    observationObject.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationActivity',
      ],
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Tea',
      coding: [
        {
          code: '10000-1',
          display: 'Tea',
          system: 'http://wellopathy.com/observation-codes',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'social-history',
            display: 'Social History',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: tea.display ?? '',
      coding: [tea],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  //   if (cofee != null) {
  //     const observationObject: R4.IObservation = {
  //       ...getVitalsObservationObjectForIPD(appointment, encounterRef),
  //     }

  //     observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
  //     observationObject.effectiveDateTime = moment().format(
  //       'YYYY-MM-DDTHH:mm:ssZ'
  //     )

  //     observationObject.code = {
  //       text: 'Coffee',
  //       coding: [
  //         {
  //           code: '10000-2',
  //           display: 'Coffee',
  //           system: 'http://wellopathy.com/observation-codes',
  //         },
  //       ],
  //     }
  //     observationObject.category = [
  //       {
  //         coding: [
  //           {
  //             system:
  //               'http://terminology.hl7.org/CodeSystem/observation-category',
  //             code: 'social-history',
  //             display: 'Social History',
  //           },
  //         ],
  //       },
  //     ]
  //     observationObject.status = R4.ObservationStatusKind._final
  //     observationObject.valueCodeableConcept = {
  //       text: cofee.display ?? '',
  //       coding: [cofee],
  //     }

  //     const entry: R4.IBundle_Entry = {
  //       request: {
  //         method: R4.Bundle_RequestMethodKind._post,
  //       },
  //       resource: observationObject,
  //     }
  //     requestBundle.entry?.push(entry)
  //   }

  if (drug != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForOPD(appointment, encounterRef),
    }
    observationObject.extension = [
      {
        url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
        valueReference: {
          reference: `Appointment/${appointment.appointment.id!}`,
        },
      },
    ]
    observationObject.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationActivity',
      ],
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Drugs',
      coding: [
        {
          code: '10000-3',
          display: 'Drugs',
          system: 'http://wellopathy.com/observation-codes',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'social-history',
            display: 'Social History',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: drug.display ?? '',
      coding: [drug],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  return requestBundle
}

export default addAddictionsOPD.reducer
