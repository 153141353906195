import { R4 } from '@ahryman40k/ts-fhir-types'
import { AxiosResponse } from 'axios'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import QueryString from 'qs'
import { getErrorResponse } from 'utils/apiClientHelper'
import { logger } from 'utils/logger'
import { getVendorPartId } from 'utils/routes_helper'
import { ApiClient } from './apiService'

export class WelloClinicAPIClient extends ApiClient {
  constructor() {
    const vendorId: string | null = getVendorPartId()
    const finalUrl = `${process.env.REACT_APP_CC_WORKFLOW_URL ?? ''}${vendorId}`
    super(finalUrl)
  }

  public async doPostRequest(
    path: string,
    body: any
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        path,
        body,
        {
          headers: {},
        }
      )
      if (response.status === 201) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doUpdateRequest(
    path: string,
    body: R4.IResource,
    currentVersion: string
  ): Promise<any | FHIRErrorResponses> {
    try {
      logger.info('Updating')
      logger.info(body)
      const response: AxiosResponse = await this.axiosInstance.put(path, body, {
        headers: {
          'If-Match': `W/${JSON.stringify(currentVersion)}`,
        },
      })
      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doCreateTransaction(
    path: string,
    body: any
  ): Promise<R4.IBundle | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        path,
        body,
        {
          headers: {},
        }
      )
      if (response.status === 201 || response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetRequest(
    path: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        paramsSerializer(pars) {
          return QueryString.stringify(pars, { arrayFormat: 'repeat' })
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doDelete(
    path: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.delete(path, {
        params: searchParameters,
        // baseURL: process.env.REACT_APP_FHIR_BASE_URL,
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }
}
