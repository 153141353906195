/* eslint-disable prefer-const */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { ScheduleUpdateStatus } from './updateScheuleStatusTypes'

const initialState: ScheduleUpdateStatus = {
    updating: false,
    updateSuccessful: false,
    error: false,
    errorMessage: '',
}

const updateScheduleSlice = createSlice({
    name: 'Update Schedule',
    initialState,
    reducers: {
        updatingScheduleDetails(
            state,
            action: PayloadAction<ScheduleUpdateStatus>
        ) {
            state.updating = action.payload.updating
            state.updateSuccessful = action.payload.updateSuccessful
            state.error = action.payload.error
        },

        scheduleDetailsUpdated(state, action: PayloadAction<ScheduleUpdateStatus>) {
            state.updating = action.payload.updating
            state.updateSuccessful = action.payload.updateSuccessful
            state.error = action.payload.error
        },

        errorInUpdatingScheduleDetails(
            state,
            action: PayloadAction<ScheduleUpdateStatus>
        ) {
            state.updating = action.payload.updating
            state.updateSuccessful = action.payload.updateSuccessful
            state.error = action.payload.error
            state.errorMessage = action.payload.errorMessage
        },

        resetScheduleDetails(state, action: PayloadAction<ScheduleUpdateStatus>) {
            state.updating = action.payload.updating
            state.updateSuccessful = action.payload.updateSuccessful
            state.error = action.payload.error
            state.errorMessage = action.payload.errorMessage
        },
    },
})

export const updatingScheduleDetails = (
    scheduleBundle: R4.IBundle
): AppThunk => async (dispatch: AppDispatch) => {
    const updateScheduleState: ScheduleUpdateStatus = {
        updating: true,
        updateSuccessful: true,
        error: false,
    }
    dispatch(
        updateScheduleSlice.actions.updatingScheduleDetails(updateScheduleState)
    )
    try {
        let response: any | undefined
        const fhirClient: FHIRApiClient = new FHIRApiClient()
        response = await fhirClient.doCreateFHIRTransaction('', scheduleBundle)
        const relatedFhirDecodeRes: E.Either<
            Errors,
            R4.IBundle
        > = R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
            logger.info('Updated schedule')
            logger.info(' Updated Schedule dispatching')
            const successUpdateState: ScheduleUpdateStatus = {
                updating: true,
                updateSuccessful: true,
                error: false,
                errorMessage: '',
            }
            dispatch(
                updateScheduleSlice.actions.scheduleDetailsUpdated(successUpdateState)
            )
            return
        }
        logger.info('Not Added Schedule')

        return
    } catch (error) {
        const errorUpdateScheduleState: ScheduleUpdateStatus = {
            updating: false,
            updateSuccessful: false,
            error: true,
            errorMessage: 'Error',
        }
        dispatch(
            updateScheduleSlice.actions.errorInUpdatingScheduleDetails(
                errorUpdateScheduleState
            )
        )
    }
}

export const resetState = () => (dispatch: AppDispatch) => {
    dispatch(updateScheduleSlice.actions.resetScheduleDetails(initialState))
}

export default updateScheduleSlice.reducer
