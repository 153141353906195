import { R4 } from '@ahryman40k/ts-fhir-types'
import { AddressUseKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Box, Button, CircularProgress, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addNewAddressToPatient } from 'redux/patient/updatePatient/updatePatientSlice'
import { RootState } from 'redux/rootReducer'
import {
  PatientAddressTypes,
  PatientAddressTypesWithoutHome,
} from 'utils/constants/address_types'
import {
  getAddressKindByCode,
  isHomeAddressAvailable,
} from 'utils/fhirResourcesHelper'
import { WelloSelectFHIRCoding } from '../LeftMenu/WelloSelectFHIRCoding'

interface Props {
  patient: R4.IPatient
  onCancelPressed: () => void
  onAdditionSuccess: (address: R4.IAddress, patient: R4.IPatient) => void
}

export const AddressAddition: React.FC<Props> = ({
  patient,
  onCancelPressed,
  onAdditionSuccess,
}: Props) => {
  const [selectedAddressType, setSelectedAddressType] = useState<R4.ICoding>()
  const [addressText, setAddressText] = useState<string>()
  const updatePatientSlice = useSelector(
    (state: RootState) => state.updatePatientSlice
  )

  function resetLocalDetails() {
    setSelectedAddressType(undefined)
    setAddressText(undefined)
  }

  useEffect(() => {
    if (updatePatientSlice.updatedSuccessfully) {
      onAdditionSuccess(
        {
          use: getAddressKindByCode(selectedAddressType?.code ?? 'temp'),

          line: [addressText ?? ''],
        },
        updatePatientSlice.patient!
      )
    }
  }, [updatePatientSlice.updatedSuccessfully])
  const dispatch = useDispatch()
  const { t } = useTranslation()
  return (
    <Box display='flex' flexDirection='column' width='100%'>
      <Box display='flex' flexDirection='row' my={1}>
        <WelloSelectFHIRCoding
          id='address_type'
          title='Address Type'
          availableCodings={
            isHomeAddressAvailable(patient.address ?? [], AddressUseKind._home)
              ? PatientAddressTypesWithoutHome
              : PatientAddressTypes
          }
          onChanged={(e) => {
            if (e) {
              setSelectedAddressType(e)
            }
          }}
          textFieldProps={{
            size: 'small',
            fullWidth: true,
            disabled: updatePatientSlice.updating,
          }}
        />
      </Box>
      <Box display='flex' flexDirection='row' my={1}>
        <TextField
          id='tf_pat_new_address_line'
          label='Enter Address'
          variant='outlined'
          value={addressText}
          multiline
          fullWidth
          size='medium'
          rowsMax={4}
          disabled={updatePatientSlice.updating}
          onChange={(e) => {
            setAddressText(e.target.value)
          }}
        />
      </Box>

      <Box display='flex' flexDirection='row' my={1} justifyContent='flex-end'>
        {updatePatientSlice.updating && <CircularProgress />}
        <Button
          style={{ marginRight: '8px', marginLeft: '8px' }}
          disabled={updatePatientSlice.updating}
          onClick={() => {
            resetLocalDetails()
            onCancelPressed()
          }}
          id='address_cancel'
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          style={{ marginRight: '8px', marginLeft: '8px' }}
          onClick={(e) => {
            if (addressText && addressText.length > 0) {
              dispatch(
                addNewAddressToPatient(patient, {
                  use: getAddressKindByCode(
                    selectedAddressType?.code ?? 'temp'
                  ),
                  line: [addressText],
                })
              )
            }
          }}
          disabled={
            selectedAddressType === undefined ||
            addressText === undefined ||
            addressText.length === 0
          }
          id='save_address'
        >
          Save
        </Button>
      </Box>
    </Box>
  )
}
