import { Box } from '@material-ui/core'
import React from 'react'

export interface TabProps {
  children: React.ReactNode
}

export const CommonBackground: React.FC<TabProps> = ({ children }) => (
  <Box
    display='flex'
    justifyContent='center'
    alignItems='center'
    minHeight='100vh'
    width='100%'
    style={{
      background:
        'radial-gradient(50% 50% at 50% 50%, #FFFFFF 41.67%, #F3F9FE 100%)',
    }}
  >
    {children}
  </Box>
)
