/* eslint-disable react/jsx-key */
import { Box, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { DaySelector } from './DaySelector'

interface TabProps {
  preSelectedTab: string[]
  onTabChange: (selectedTab: string) => void
  menu: string[]
  menuLabel?: string
  onValuesChanged: (selectedType: string[]) => void
}
const useStyles = makeStyles((theme) => ({
  paperHeight: {
    height: 36,
  },
}))

export const DaySelect: React.FC<TabProps> = ({
  preSelectedTab,
  onTabChange,
  menu,
  menuLabel,
  onValuesChanged,
}: TabProps) => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = React.useState(preSelectedTab)
  const availableOptions = preSelectedTab

  const handleChange = (selectedTabData: string) => {
    onTabChange(selectedTabData)
    if (selectedTab.includes(selectedTabData)) {
      if (selectedTab.length !== 1) {
        const index = selectedTab.indexOf(selectedTabData)
        selectedTab.splice(index, 1)
      }
    } else {
      selectedTab.push(selectedTabData)
      //   setSelectedTab(availableOptions)
    }
    onValuesChanged(selectedTab)
  }

  return (
    <div>
      <Box paddingBottom={1}>
        <Typography variant='subtitle2'>&nbsp; {menuLabel}</Typography>
      </Box>
      <Grid container direction='row'>
        {menu.map((value, i) => (
          <Grid item key={value}>
            <DaySelector
              textTitle={value}
              isSelected={selectedTab.some((el: any) => el === value)}
              onClick={() => handleChange(value)}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
