import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AccountDetails, LabReferralDetails } from 'models/labReferralDetails'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserUnitDetails,
  getUserDetails,
  isCurrentUnitIsClinic,
  isCurrentUnitIsWellonessCenter,
} from 'services/userDetailsService'
import {
  getEmailStringValueExtensionsOfUrl,
  getExtensionArray,
  getStringValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { AccountSearchStatus } from './accountSearchStatus'

const initialState: AccountSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const accountSearchSlice = createSlice({
  name: 'accountSearchSlice',
  initialState,
  reducers: {
    searchingPatientDetails(
      state,
      action: PayloadAction<AccountSearchStatus>
    ) {},

    searchResults(state, action: PayloadAction<AccountSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.accountList = action.payload.accountList
      state.accountDetailsList = action.payload.accountDetailsList
    },

    noDataFoundForSearch(state, action: PayloadAction<AccountSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable

      state.accountList = action.payload.accountList
      state.accountDetailsList = action.payload.accountDetailsList
    },

    errorWhileSearching(state, action: PayloadAction<AccountSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable

      state.accountList = action.payload.accountList
      state.accountDetailsList = action.payload.accountDetailsList
    },
  },
})

export const SearchAccountList = (): AppThunk => async (dispatch: any) => {
  const errorFetchingUnits: AccountSearchStatus = {
    error: false,
    noResultsAvailable: false,
    resultsAvailable: false,
    searching: true,
  }
  dispatch(accountSearchSlice.actions.errorWhileSearching(errorFetchingUnits))
  try {
    const searchParameter = {}

    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const unitDetails: R4.IOrganization = getCurrentUserUnitDetails()
    const response = await fhirClient.doGetResource(
      `/Basic?code=account&subject=Organization/${unitDetails.id!}`,
      searchParameter
    )

    const orgResponse: R4.IBundle = response
    if (orgResponse.total) {
      if (orgResponse.total > 0) {
        if (orgResponse.entry) {
          const taskArray: R4.IBasic[] = orgResponse.entry.map(
            (item) => item.resource as R4.IBasic
          )

          const accountListData: AccountDetails[] = []
          for (let i = 0; i < taskArray.length; i++) {
            const accountExtensionArr: R4.IExtension[] = getExtensionArray(
              taskArray[i].extension ?? [],
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-bank-details-ext'
            )

            const gstExtensionArray: R4.IExtension[] = getExtensionArray(
              taskArray[i].extension ?? [],
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-payment-gayway-details-ext'
            )
            const data = {
              bankName: '',
              accountNo:
                getStringValueExtensionsOfUrl(
                  accountExtensionArr ?? [],
                  'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-account-number-ext'
                ) ?? '',
              ifscCode:
                getStringValueExtensionsOfUrl(
                  accountExtensionArr ?? [],
                  'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ifsc-code-ext'
                ) ?? '',
              beneficiaryName:
                getStringValueExtensionsOfUrl(
                  accountExtensionArr ?? [],
                  'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-beneficiary-name-ext'
                ) ?? '',
              panNo:
                getStringValueExtensionsOfUrl(
                  accountExtensionArr ?? [],
                  'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-pan-card-number-ext'
                ) ?? '',
              gstNo:
                getStringValueExtensionsOfUrl(
                  gstExtensionArray ?? [],
                  'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-gst-number-ext'
                ) ?? '',
              email:
                getEmailStringValueExtensionsOfUrl(
                  gstExtensionArray ?? [],
                  'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-email-ext'
                ) ?? '',
              phone:
                getEmailStringValueExtensionsOfUrl(
                  gstExtensionArray ?? [],
                  'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-phone-ext'
                ) ?? '',
              account: taskArray[i],
            }
            accountListData.push(data)
          }

          const fetchUnitListResult: AccountSearchStatus = {
            error: false,
            noResultsAvailable: false,
            resultsAvailable: true,
            searching: false,
            accountList: taskArray,
            accountDetailsList: accountListData,
          }
          dispatch(
            accountSearchSlice.actions.searchResults(fetchUnitListResult)
          )
          return
        }
      }
    }
    const noSearchResults: AccountSearchStatus = {
      error: false,
      noResultsAvailable: true,
      resultsAvailable: false,
      searching: false,
    }
    dispatch(accountSearchSlice.actions.noDataFoundForSearch(noSearchResults))
    return
  } catch (error) {
    logger.error(error)
    const errorWhileSearchPatient: AccountSearchStatus = {
      error: true,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: 'Error',
    }
    dispatch(
      accountSearchSlice.actions.errorWhileSearching(errorWhileSearchPatient)
    )
  }
}

export default accountSearchSlice.reducer
