/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { UmlResponse } from 'models/umlResponse'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addAllergiesDetails,
  resetAddAllergiesState,
} from 'redux/fhirMedicalResources/ipd/addAllergy/addAllergySliceIPD'
import {
  addAddictions,
  resetAddictions,
} from 'redux/fhirMedicalResources/ipd/addictions/addictionsAddSlice'
import { RootState } from 'redux/rootReducer'
import {
  addictions,
  alcoholCount,
  breathe,
  drugCount,
  energyLevels,
  excersiseCode,
  intensity,
  listOfHabbits,
  sleepPattern,
  smokeCounts,
  teaCount,
  tobacoCount,
} from 'utils/constants'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { RadioRaisedButton, WelloLoadingIndicator } from 'wello-web-components'
import { AllergyMasterChosser } from '../subjective/allergyMasterChosser'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  onClose: () => void
  onAddictionAdded: () => void
}
export const AddAddiction: React.FC<Props> = ({
  open,
  onClose,
  onAddictionAdded,
  fhirAppointmentDetails,
}) => {
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '30%',
    },
  }))
  const classes = useStyles()
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const [smoke, setSmoke] = useState<R4.ICoding>()
  const [tobaco, setTobaco] = useState<R4.ICoding>()
  const [alcohol, setAlcohol] = useState<R4.ICoding>()
  const [tea, setTea] = useState<R4.ICoding>()
  const [cofee, setCofee] = useState<R4.ICoding>()
  const [drug, setDrug] = useState<R4.ICoding>()
  const dispatch = useDispatch()

  const addAddictionsIPD = useSelector(
    (state: RootState) => state.addAddictionsIPD
  )

  useEffect(() => {
    if (addAddictionsIPD.additionSuccessful) {
      onAddictionAdded()
      dispatch(resetAddictions())
      setSmoke(undefined)
      setTobaco(undefined)
      setTea(undefined)
      setCofee(undefined)
      setDrug(undefined)
      setAlcohol(undefined)
    }
  }, [dispatch, addAddictionsIPD, onAddictionAdded])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setSmoke(undefined)
        setTobaco(undefined)
        setTea(undefined)
        setCofee(undefined)
        setDrug(undefined)
        setAlcohol(undefined)
      }}
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Add Addictions</DialogTitle>
      <DialogContent dividers={true}>
        {addAddictionsIPD.adding && (
          <WelloLoadingIndicator message='Adding details' />
        )}

        {!addAddictionsIPD.adding && (
          <Box marginX={2} marginY={1}>
            <Grid container direction='column' spacing={1}>
              <Grid item>
                {addictions.map((val) => (
                  <Box display='flex' flexDirection='column' width='100%'>
                    {val.code === '68518-0' && (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <WelloSelectFHIRCoding
                          title='How often do you have a drink containing alcohol'
                          availableCodings={alcoholCount}
                          id='addictions_1'
                          onChanged={(type) => {
                            setAlcohol(type)
                          }}
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                            id: 'ad_3',
                          }}
                        />
                      </Box>
                    )}

                    {/* {val.code === '10000-2' && (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <WelloSelectFHIRCoding
                          title='How many cups of coffee do you drink per day'
                          availableCodings={teaCount}
                          onChanged={(type) => {
                            setCofee(type)
                          }}
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                          }}
                        />
                      </Box>
                    )} */}
                    {val.code === '96103-7' && (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <WelloSelectFHIRCoding
                          title='Tobacco/Paan/Gutkha per day'
                          availableCodings={tobacoCount}
                          id='addictions_2'
                          onChanged={(type) => {
                            setTobaco(type)
                          }}
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                            id: 'ad_4',
                          }}
                        />
                      </Box>
                    )}

                    {val.code === '10000-3' && (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <WelloSelectFHIRCoding
                          title='How many types of drugs do you take'
                          availableCodings={drugCount}
                          id='addictions_3'
                          onChanged={(type) => {
                            setDrug(type)
                          }}
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                            id: 'ad_5',
                          }}
                        />
                      </Box>
                    )}

                    {val.code === '64218-1' && (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <WelloSelectFHIRCoding
                          title='How many cigarettes do you smoke per day now'
                          availableCodings={smokeCounts}
                          id='addictions_4'
                          onChanged={(type) => {
                            setSmoke(type)
                          }}
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                            id: 'ad_6',
                          }}
                        />
                      </Box>
                    )}

                    {/* {val.code === '10000-1' && (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <WelloSelectFHIRCoding
                          title='How many cups of tea do you drink per day'
                          availableCodings={teaCount}
                          onChanged={(type) => {
                            setTea(type)
                          }}
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                          }}
                        />
                      </Box>
                    )} */}
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box display='flex' flexDirection='row' paddingRight={4}>
          <Button
            onClick={() => {
              onClose()
              setSmoke(undefined)
              setTobaco(undefined)
              setTea(undefined)
              setCofee(undefined)
              setDrug(undefined)
              setAlcohol(undefined)
            }}
            disabled={addAddictionsIPD.adding}
            variant='outlined'
            id='ad_7'
            disableElevation
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            onClick={() => {
              dispatch(
                addAddictions(
                  fhirAppointmentDetails,
                  smoke,
                  tobaco,
                  alcohol,
                  tea,
                  cofee,
                  drug
                )
              )
            }}
            variant='contained'
            color='primary'
            disableElevation
            id='ad_8'
            disabled={
              (tea === undefined &&
                cofee === undefined &&
                drug === undefined &&
                alcohol === undefined &&
                smoke === undefined &&
                drug === undefined &&
                tobaco === undefined) ||
              addAddictionsIPD.adding
            }
          >
            {t('labelCommon:add')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
