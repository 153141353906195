import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { Conditions } from 'models/conditions'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { requestFamilyConditionHistoryOfPatient } from 'redux/patientMedicalHistory/familyConditionHistory/familyMedicalConditionsHistorySlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { sleep } from 'utils/dateUtil'
import { getVitalsObservationObjectForIPD } from 'utils/fhirResoureHelpers/observationHelpers'
// import { requestVitalDetailsOfPatientIPD } from '../vitalsDetailsIpd/vitalsDetailSliceIpd'
import { AddFamilyHistoryStatus } from './addFamilyHistoryStatus'

const initialState: AddFamilyHistoryStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addFamilyHistorySlice = createSlice({
  name: 'addFamilyHistorySlice',
  initialState,
  reducers: {
    updateAddVitalsStatus(
      state,
      action: PayloadAction<AddFamilyHistoryStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addedVitalsBundle = action.payload.addedVitalsBundle
    },

    resetAddHabitState(state, action: PayloadAction<AddFamilyHistoryStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addedVitalsBundle = initialState.addedVitalsBundle
    },
  },
})

export const addFamilyHistory =
  (
    appointment: FhirActiveIPDDetailsForMedicalRole,
    selectedCondition: R4.ICoding,
    selectedFamilyMember: R4.ICoding,
    selectedDate: Date,
    isActive: boolean,
    notes: string,
    selectedConditionForCheckBox: Conditions[],
    setSelectedConditionForCheckBoxForOther: Conditions[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AddFamilyHistoryStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(addFamilyHistorySlice.actions.updateAddVitalsStatus(addingState))

    try {
      const practitionerDetail = getCurrentUserPractitionerDetails()
      const practitionerRoleDetail = getCurrentUserPractitionerRoleDetails()
      const data: R4.IFamilyMemberHistory = {
        resourceType: 'FamilyMemberHistory',
        meta: {
          profile: [
            'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndFamilyMemberHistoryBase',
          ],
        },
        patient: {
          id: appointment.patient.id,
          reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
        },
        relationship: {
          text: selectedFamilyMember.display,
          coding: [selectedFamilyMember],
        },
        date: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
        condition: [
          {
            code: {
              coding: [selectedCondition],
            },
            onsetString: selectedDate.toISOString(),
          },
        ],
      }
      for (let i = 0; i < selectedConditionForCheckBox.length; i++) {
        if (selectedConditionForCheckBox[i].checked) {
          if (data.condition === undefined) {
            data.condition = [
              {
                code: {
                  coding: [selectedConditionForCheckBox[i].data],
                },
                onsetString: selectedDate.toISOString(),
              },
            ]
          } else if (data.condition && data.condition.length > 0) {
            data.condition.push({
              code: {
                coding: [selectedConditionForCheckBox[i].data],
              },
              onsetString: selectedDate.toISOString(),
            })
          }
        }
      }

      for (let i = 0; i < setSelectedConditionForCheckBoxForOther.length; i++) {
        if (setSelectedConditionForCheckBoxForOther[i].checked) {
          if (data.condition === undefined) {
            data.condition = [
              {
                code: {
                  coding: [setSelectedConditionForCheckBoxForOther[i].data],
                },
                onsetString: selectedDate.toISOString(),
              },
            ]
          } else if (data.condition && data.condition.length > 0) {
            data.condition.push({
              code: {
                coding: [setSelectedConditionForCheckBoxForOther[i].data],
              },
              onsetString: selectedDate.toISOString(),
            })
          }
        }
      }
      if (notes.length > 0) {
        data.note = [
          {
            authorReference: {
              reference: `${practitionerDetail.resourceType}/${practitionerDetail.id}`,
            },
            text: notes,
            time: new Date().toISOString(),
          },
        ]
      }

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRResourceRequest(
        '/FamilyMemberHistory',
        data
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IFamilyMemberHistory> =
        R4.RTTI_FamilyMemberHistory.decode(response)
      if (response) {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        await sleep(5000)
        dispatch(
          requestFamilyConditionHistoryOfPatient('', appointment.patient, false)
        )

        dispatch(showSuccessAlert('Family Member Condition Added Successfully'))

        dispatch(
          addFamilyHistorySlice.actions.updateAddVitalsStatus(addingState)
        )
      } else {
        const errorCreatePersonState: AddFamilyHistoryStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while adding Family Member Condition',
        }
        dispatch(
          addFamilyHistorySlice.actions.updateAddVitalsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: AddFamilyHistoryStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while adding Family Member Condition',
      }
      dispatch(
        addFamilyHistorySlice.actions.updateAddVitalsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetAddFamilyHistory = () => (dispatch: AppDispatch) => {
  dispatch(addFamilyHistorySlice.actions.resetAddHabitState(initialState))
}

function createBundleObjectForObservations(
  appointment: FhirActiveIPDDetailsForMedicalRole,
  excersise?: R4.ICoding,
  intensity?: R4.ICoding,
  energyLevel?: R4.ICoding,
  breatePattern?: R4.ICoding,
  sleepData?: R4.ICoding
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const encounterRef: R4.IReference = {
    reference: `Encounter/urn:uuid:1232323232324`,
    type: 'Encounter',
  }

  if (excersise != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Exercise duration/Exercise frequency',
      coding: [
        {
          code: '74009-2',
          display: 'Exercise duration/Exercise frequency',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: excersise.display ?? '',
      coding: [excersise],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (intensity != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Exercise Intensity',
      coding: [
        {
          code: '74008-4',
          display: 'Exercise Intensity',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: intensity.display ?? '',
      coding: [intensity],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (energyLevel != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Energy level in past 7 days',
      coding: [
        {
          code: '65515-9',
          display: 'Energy level in past 7 days',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: energyLevel.display ?? '',
      coding: [energyLevel],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (breatePattern != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Breathing pattern impairment',
      coding: [
        {
          code: '28148-5',
          display: 'Breathing pattern impairment',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: breatePattern.display ?? '',
      coding: [breatePattern],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (sleepData != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'How many hours do you normally sleep',
      coding: [
        {
          code: '65968-0',
          display: 'How many hours do you normally sleep',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'activity',
            display: 'Activity',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: sleepData.display ?? '',
      coding: [sleepData],
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  return requestBundle
}

export default addFamilyHistorySlice.reducer
