import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitReference,
  isTherapist,
} from 'services/userDetailsService'
import { WPAdminAPIClient } from 'services/wpApiService'
import { getGenderOfDoctor } from 'utils/fhirResourcesHelper'
import { getCountForTherapist } from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { logger } from 'utils/logger'
import { AdminGraphStatus } from './searchAdminGraphStatus'

const initialState: AdminGraphStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
}

const graphSearchSlice = createSlice({
  name: 'graphSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<AdminGraphStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.url = action.payload.url
      state.appUrl = action.payload.appUrl

      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const resetAdminGraphSearch =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: AdminGraphStatus = {
      searchingAppointments: false,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      url: undefined,
    }
    dispatch(graphSearchSlice.actions.updatedStatus(state))
  }
export const searchGraphData =
  (type: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AdminGraphStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(graphSearchSlice.actions.updatedStatus(state))

    try {
      const fhirClient: WPAdminAPIClient = new WPAdminAPIClient()

      const response = await fhirClient.doGetResource(
        `business/wp_admin_dasboard/meta`,
        ''
      )

      if (response !== undefined) {
        const url = response.main_dashboard.embed_url
        let webUrl: string = ''

        if (response.dashboards && response.dashboards.length > 0) {
          for (let i = 0; i < response.dashboards.length; i++) {
            if (
              response.dashboards[i].name === 'WelloADMIN(TM) Dashboard' &&
              type === 'web'
            ) {
              webUrl = response.dashboards[i].embed_url
            } else if (
              type === 'app' &&
              response.dashboards[i].name === 'WelloHUM(TM) Dashboard'
            ) {
              webUrl = response.dashboards[i].embed_url
            }
          }
        }

        state.url = webUrl
        state.appUrl = ''
        state.resultsAvailable = true
        state.errorWhileSearchingAppointments = true
        state.searchingAppointments = false
        dispatch(graphSearchSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: AdminGraphStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
      }
      dispatch(graphSearchSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default graphSearchSlice.reducer
