import React from 'react'
import {
  Button,
  Grid,
  Typography,
  Box,
  Divider,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core'
import { Address } from '../../../models/medicine-delivery/cart/cart-models'

interface Props {
  address: Address
}

export const CartAddress: React.FC<Props> = ({ address }: Props) => {
  console.log('134')
  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='row'
      paddingLeft={address.type === 'shipping' ? 1 : 0}
    >
      <Box width='95%' display='flex' flexDirection='row'>
        <Box p={1} width='100%' display='flex' flexDirection='column'>
          <Box>
            <Typography variant='subtitle1'>
              {' '}
              {address.type === 'billing'
                ? 'Billing Address'
                : 'Shipping Address'}
            </Typography>
          </Box>
          <Box py={1} width='100%'>
            <Typography variant='subtitle2'>
              If you wish to change the {address.type} address, please
              <a href='#'> click here</a>
            </Typography>
          </Box>
          <Box width='100%' display='flex' flexDirection='row'>
            <Box px={1} width='60%' display='flex' flexDirection='column'>
              {address.type === 'billing' && (
                <FormControl>
                  <RadioGroup
                    id='add_unit_address_choice_rad_group'
                    row
                    aria-label='graphType'
                    value={address.isDefault}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      // if ((event.target as HTMLInputElement).value === 'doctor')
                      //   setPayment('doctor')
                      // else setPayment('patient')
                    }}
                  >
                    <Box>
                      <FormControlLabel
                        value='doctor'
                        control={<Radio color='primary' size='small' />}
                        style={{ paddingLeft: 0 }}
                        label={
                          <Typography variant='subtitle2'>
                            {' '}
                            Same as shipping address
                          </Typography>
                        }
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              )}
              <Box
                px={address.type === 'billing' ? 3 : 0}
                width='100%'
                display='flex'
                flexDirection='column'
              >
                <Typography variant='subtitle2' style={{ color: '#A9A9A9' }}>
                  {address.name}
                </Typography>
                <Typography variant='subtitle2' style={{ color: '#A9A9A9' }}>
                  {address.addressLine1}
                </Typography>
                <Typography variant='subtitle2' style={{ color: '#A9A9A9' }}>
                  {address.addressLine2}
                </Typography>
                <Typography variant='subtitle2' style={{ color: '#A9A9A9' }}>
                  {address.city} {address.pinCode}
                </Typography>
                <Typography variant='subtitle2' style={{ color: '#A9A9A9' }}>
                  {address.state}
                </Typography>
                <Typography variant='subtitle2' style={{ color: '#A9A9A9' }}>
                  {address.country}
                </Typography>
              </Box>
            </Box>
            <Box paddingLeft={2.5} justifyContent='flex-end'>
              {address.type === 'shipping' && (
                <Button
                  color='primary'
                  variant='contained'
                  disabled={address.isDefault}
                  onClick={() =>
                    console.log('API call to set address as default')
                  }
                >
                  Default Address
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box justifyContent='flex-end' paddingLeft={2}>
        {address.type === 'shipping' && (
          <Divider
            orientation='vertical'
            variant='fullWidth'
            style={{
              width: '2px',
            }}
          />
        )}
      </Box>
    </Box>
  )
}
