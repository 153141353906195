import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { Sadhka } from 'models/sadhka'
import { SadhkaCatalog, SadhkaForm } from 'models/sadhkaCatalog'
import { SadhkaResponse } from 'models/sadkahResponse'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { getNameOfPatient } from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import {
  getLastNameOfPatient,
  getMiddleNameOfPatient,
} from 'wello-web-components'

import { IPDAddSadhkaStatus } from './ipdSadhkaAddStatus'

const initialState: IPDAddSadhkaStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const IpdRegisterSadhkaSlice = createSlice({
  name: 'IpdRegisterSadhkaSlice',
  initialState,
  reducers: {
    addingPatientDetails(state, action: PayloadAction<IPDAddSadhkaStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.patientId = action.payload.patientId
      state.sadkhaFinalData = action.payload.sadkhaFinalData
    },

    patientDetailsAdded(state, action: PayloadAction<IPDAddSadhkaStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.patientId = action.payload.patientId
      state.sadkhaFinalData = action.payload.sadkhaFinalData
    },

    errorInAddingPatientDetails(
      state,
      action: PayloadAction<IPDAddSadhkaStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.patientId = action.payload.patientId
      state.sadkhaFinalData = action.payload.sadkhaFinalData
    },

    resetPatientDetails(state, action: PayloadAction<IPDAddSadhkaStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.patientId = action.payload.patientId
      state.sadkhaFinalData = action.payload.sadkhaFinalData
    },
  },
})

export const registerIpdSadhka =
  (
    sadhkaDetails: Sadhka[],
    startDate: Date,
    endDate: Date,
    discount: number,
    noOfDays: number,

    selectedVistPurpose: R4.ICoding,
    roomType?: R4.ICoding,
    chargeItemDef?: R4.IChargeItemDefinition,
    patientDetails?: R4.IPatient
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: IPDAddSadhkaStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(
      IpdRegisterSadhkaSlice.actions.addingPatientDetails(
        addingCreatePersonState
      )
    )

    try {
      const practRole: R4.IPractitionerRole =
        getCurrentUserPractitionerRoleDetails()

      logger.info('patientDetails')
      const date = moment(endDate).format('DD-MM-YYYY')

      const dateTime = moment(
        `${date} ${moment().format('HH:mm')}`,
        'DD/MM/YYYY HH:mm'
      )
      const EndDateOff = moment(endDate).format('DD-MM-YYYY')
      const endDateTime = moment(`${date} 09:00`, 'DD/MM/YYYY HH:mm')

      logger.info(patientDetails)
      const resource: SadhkaForm[] = []
      let sadhakData: any
      if (roomType && chargeItemDef && sadhkaDetails.length > 0) {
        for (let i = 0; i < sadhkaDetails.length; i++) {
          const sad: SadhkaForm = {
            patientId: sadhkaDetails[i].patient!.id ?? '',
            roomNumber: sadhkaDetails[i].bedId!,
          }
          resource.push(sad)
        }
        sadhakData = {
          discountPercentage: discount > 0 ? discount.toFixed(2) : '0',
          numberOfDays: noOfDays === 0 || noOfDays === 1 ? 1 : noOfDays,
          patientIds: resource,
          roomTypeCode: roomType,
          roomTypeChargeDetails: chargeItemDef,
          fromDate: startDate.toISOString(),
          endDate: endDateTime.toISOString(),
          isOpdBased: false,
        }
      }

      //   const fhirClient: FHIRApiClient = new FHIRApiClient()
      const enRolClient: EnrolCient = new EnrolCient()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        'ipd/request-booking/members',
        sadhakData
      )
      if (response.length > 0) {
        const sadhkaFinal: SadhkaResponse[] = []
        for (let i = 0; i < response.length; i++) {
          const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
            R4.RTTI_Bundle.decode(response[i].value)

          if (relatedFhirDecodeRes._tag === 'Right') {
            const orderResponse: R4.IBundle = relatedFhirDecodeRes.right

            if (orderResponse.entry) {
              for (let j = 0; j < orderResponse.entry.length; j++) {
                let serviceRequestId: string = ''
                const entryResponse = orderResponse.entry[j].response

                if (entryResponse) {
                  if (entryResponse.location) {
                    if (entryResponse.location.includes('ServiceRequest')) {
                      serviceRequestId = entryResponse.location.replace(
                        '/_history/1',
                        ''
                      )
                      const patient: R4.IPatient | undefined = orderResponse
                        .entry[j].resource! as R4.IPatient

                      if (serviceRequestId.length > 0) {
                        const res: SadhkaResponse = {
                          serviceId: serviceRequestId,
                          patientName: `${
                            getNameOfPatient(sadhkaDetails[i].patient!) ??
                            ''.toLocaleUpperCase()
                          } ${
                            getMiddleNameOfPatient(sadhkaDetails[i].patient!) ??
                            ''
                          } ${
                            getLastNameOfPatient(sadhkaDetails[i].patient!) ??
                            ''
                          }`,
                          patient: sadhkaDetails[i].patient!,
                        }
                        sadhkaFinal.push(res)
                      }
                    } else {
                      serviceRequestId = ''
                    }
                  }
                }
              }
            }
          }
        }
        sadhkaFinal.sort((a, b) =>
          a.patientName! > b.patientName!
            ? 1
            : b.patientName! > a.patientName!
            ? -1
            : 0
        )
        if (sessionStorage.getItem('sadhakList') === null) {
          sessionStorage.setItem('sadhakList', JSON.stringify(sadhkaFinal))
        } else {
          sessionStorage.removeItem('sadhakList')
          sessionStorage.setItem('sadhakList', JSON.stringify(sadhkaFinal))
        }

        const successCreatePersonState: IPDAddSadhkaStatus = {
          adding: false,
          additionSuccessful: true,
          sadkhaFinalData: sadhkaFinal,
          patientId: patientDetails ? patientDetails.id ?? '' : '',
          error: false,
          errorMessage: '',
        }
        dispatch(
          IpdRegisterSadhkaSlice.actions.patientDetailsAdded(
            successCreatePersonState
          )
        )

        return
      }
      const errorCreatePersonState: IPDAddSadhkaStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while register patient',
      }
      dispatch(
        IpdRegisterSadhkaSlice.actions.patientDetailsAdded(
          errorCreatePersonState
        )
      )

      return
    } catch (error) {
      const errorCreatePersonState: IPDAddSadhkaStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error While Adding Patient',
      }
      dispatch(
        IpdRegisterSadhkaSlice.actions.errorInAddingPatientDetails(
          errorCreatePersonState
        )
      )
    }
  }

export const resetAddSadhka = () => (dispatch: AppDispatch) => {
  dispatch(IpdRegisterSadhkaSlice.actions.resetPatientDetails(initialState))
}

export default IpdRegisterSadhkaSlice.reducer
