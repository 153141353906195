import { R4 } from '@ahryman40k/ts-fhir-types'
import { Calendar } from '@mantine/dates'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListSubheader,
  Paper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import { ToggleButtonGroup } from '@material-ui/lab'
import { kPrimaryDark, kPrimaryMain } from 'configs/styles/muiThemes'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { resetAppointmentState } from 'redux/appointments/appointmentManger/appointmentManagerSlice'
import { fetchIPDAppointmentDetails } from 'redux/clinic/ipdDetails/ipdAppointmentHandler/ipdAppointmentHandlerSlice'
import { requestIPDAppCountForToday } from 'redux/clinic/ipdmanagement/ipdCount/ipdCountSearchSlice'
import { requestForDateWiseIpdAppointmentLists } from 'redux/clinic/ipdmanagement/ipdSearch/ipdSearchSlice'
import { requestForDateWiseOPDAppointmentListsForConsulting } from 'redux/clinic/opdManagement/opdConsultManagement/opdConsultSearchSlice'
import { requestForDateWiseOPDAppointmentLists } from 'redux/clinic/opdManagement/opdSearch/opdSearchSlice'
import { requestForMedicineOrders } from 'redux/MedicineOrderHistory/medicineListSearch/medicineOrderSearchSlice'
import { updateMedicineOrderCount } from 'redux/MedicineOrderHistory/medicineOrderCount/medicineOrderCountSlice'
import { fetchMedicineOrderDetails } from 'redux/MedicineOrderHistory/medicineOrderDetails/medicineOrderDetailsSlice'
import { resetState } from 'redux/patient/addPatient/addPatientSlice'
import { RootState } from 'redux/rootReducer'
import { getAllNutritionValueSets } from 'redux/valueSet/nutrition_catalogue/nutrtionCatalogueSlice'
import { getAllSubstance } from 'redux/valueSet/substance/substanceSearchSlice'
import { updateSessionTherapiesList } from 'redux/valueSet/therapies_catalogue/therapiesCatalogueSlice'
import { getAllTherapist } from 'redux/valueSet/therapyList/threapyCatalogSearchSlice'
import { getAllTherapiesWithPrice } from 'redux/valueSet/therapyListwithChargable/threapyCatalogWithPriceSearchSlice'
import { useWindowSize } from 'rooks'
import {
  getCurrentUserUnitDetails,
  isCurrentUnitIsWellonessCenter,
  isPhysiotherapist,
  isTherapist,
} from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import {
  getAaddressByContactId,
  getPatientDetailsByContactId,
} from 'utils/appointment_handle/medications_util'
import { getCompleteDateStringWithOutDay, isToday } from 'utils/dateUtil'
import {
  getCodeOfSystemFromCodableConceptList,
  getValueCoDeFromValueCodeable,
} from 'utils/fhirResourcesHelper'
import { getVendorPartId } from 'utils/routes_helper'
import { desktopScreenWidth } from 'utils/screen_utils'
import { AppointmentTypeOptions } from 'views/components/appointments/appointmentTypeOptions'
import { CreateAppointmentHandler } from 'views/components/appointments/createAppointmentManager'
import { AddPatientData } from 'views/components/common/add_patient_data'
import { IpdAppointmentTile } from 'views/components/ipd/ipdAppointmentList/ipdAppointmentTile'
import { IPDStatusSelection } from 'views/components/ipd/ipdAppointmentStatusSelector'
import { IPDDetails } from 'views/components/ipd/IpdDetails'
import { IPDDetailsPopUp } from 'views/components/ipd/IpdDetailsPopUp'
import {
  ReportSelectionDialog,
  ReportSelectionDialogProps,
} from 'views/components/ipd/reportSelectionDialog'
import { ConsultationManagement } from 'views/components/ipdAppointment/consultation/consultationManagement'
import { CreateIpdAppointmentHandler } from 'views/components/ipdAppointment/createIpdAppoitmentManagement'
import { DayCareManagement } from 'views/components/ipdAppointment/dayCare/dayCareManagement'
import { AddLabNewPatient } from 'views/components/lab/patientSetup/add_patient'
import '../../../../App.css'
import { AddNewPatient } from '../../../components/add_new_patient'
import { MedicineOrderListView } from './medicineOrderListView'
import { MedOrderDetails } from './medOrderDetails'

export const MedicineLandingPage: React.FC = () => {
  const medicineOrderCountSlice = useSelector(
    (state: RootState) => state.medicineOrderCountSlice
  )
  const medicineOrderSearchSlice = useSelector(
    (state: RootState) => state.medicineOrderSearchSlice
  )

  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(new Date())

  const [selectedOrder, setSelectedOrder] = useState<any>()
  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  const [patientData, setPatientData] = useState<R4.IPatient>()

  useEffect(() => {
    // if (loggedInUnitType === 'Clinic') document.title = 'WelloClinic'
    dispatch(requestForMedicineOrders(selectedDate))
  }, [dispatch, selectedDate])

  useEffect(() => {
    dispatch(
      updateMedicineOrderCount(moment(selectedDate).format('YYYY-MM-DD'))
    )
  }, [dispatch, selectedDate])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        borderRadius: 8,
        backgroundColor: '#ececec',
        paddingTop: '16px',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box
            display='flex'
            width='22%'
            maxWidth='22%'
            style={{ overflow: 'auto', height: '100%', minWidth: '290px' }}
          >
            <SimpleBar
              style={{
                height: '100%',
                width: '100%',
                overflowX: 'hidden',
                padding: '8px',
              }}
            >
              <Box px={1} width='100%'>
                <Box width='100%'>
                  <Calendar
                    size='xs'
                    style={{
                      aspectRatio: '1/1',
                    }}
                    value={selectedDate}
                    onChange={setSelectedDate}
                    // onMonthChange={setSelectedDate}
                    dayStyle={(date, modifier) => {
                      if (isToday(date)) {
                        return {
                          aspectRatio: '1',
                          borderBottom: `1px solid ${kPrimaryDark}`,
                          bottom: '20%',
                        }
                      }
                      return { aspectRatio: '1' }
                    }}
                    styles={{
                      cell: {
                        aspectRatio: '1',
                      },
                      day: {
                        aspectRatio: '1 !important',
                      },
                      weekend: {
                        color: 'black',
                      },
                      weekday: {
                        color: kPrimaryMain,
                      },
                      selected: {
                        borderRadius: '50%',
                      },
                      label: {
                        fontWeight: 'bold',
                        color: kPrimaryMain,
                      },
                    }}
                  />
                </Box>
                {/* <IPDStatusSelection
                  type='IPD'
                  onSelectionChanged={(changedTypes) => {
                    if (changedTypes.length > 0) {
                      //   setSelectedIPDStatuses(changedTypes)
                    }
                    setSelectedIPd(undefined)
                  }}
                  date={selectedDate}
                /> */}
              </Box>
            </SimpleBar>
          </Box>

          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            maxWidth='88%'
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            <Box flexDirection='row' width='100%' display='flex' p={1}>
              {/* <TextField
                variant='outlined'
                onChange={(event) => {
                  dispatch(
                    requestForMedicineOrders(selectedDate, event.target.value)
                  )

                  setName(event.target.value)
                }}
                size='small'
                autoFocus={true}
                placeholder='Search By Patient Name'
                value={name}
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <img
                        src={`${process.env.PUBLIC_URL}/search.png`}
                        alt='s'
                        style={{ paddingLeft: name ? 1 : 0 }}
                      />
                    </IconButton>
                  ),
                  endAdornment: name && (
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => {
                        setName('')
                        dispatch(requestForMedicineOrders(selectedDate, ''))
                      }}
                    >
                      <CancelRoundedIcon />
                    </IconButton>
                  ),
                }}
              /> */}

              {medicineOrderSearchSlice.searchingAppointments && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                  px={1}
                >
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='Start'
              alignContent='center'
              overflow='auto'
              style={{
                backgroundColor: '#00000005',
              }}
            >
              {medicineOrderSearchSlice.noResultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    align='center'
                  >
                    {t('labelCommon:no_orders_available')}
                  </Typography>
                </Box>
              )}

              {medicineOrderSearchSlice.errorWhileSearchingOrders && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography variant='subtitle1' color='error' align='center'>
                    Error while searching orders
                  </Typography>
                </Box>
              )}

              {medicineOrderSearchSlice.availableOrders && (
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height='100%'
                  overflow='auto'
                >
                  <Box
                    display='flex'
                    flexGrow={1}
                    overflow='auto'
                    height='100%'
                  >
                    <MedicineOrderListView
                      //   preselectedOrderId={selectedOrder?.serviceRequest?.id}
                      labOrders={medicineOrderSearchSlice.availableOrders}
                      onLabOrderSelected={(order, patient?: R4.IPatient) => {
                        setPatientData(patient)
                        setSelectedOrder(order)

                        dispatch(fetchMedicineOrderDetails(order.salesorder_id))
                      }}
                      dense={selectedOrder !== undefined}
                    />
                  </Box>

                  {selectedOrder && (
                    <Box display='flex' flexGrow={4} overflow='auto'>
                      <MedOrderDetails
                        orderDetail={selectedOrder}
                        patientData={patientData}
                        onCloseClicked={() => {
                          setSelectedOrder(undefined)
                          setPatientData(undefined)
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            <Box />
          </Box>
        </Box>
      </Paper>

      {/* {innerWidth! <= desktopScreenWidth && selectedOrder && (
        // <IPDDetailsPopUp
        //   ipdDetails={selectedIpd}
        //   onCloseClicked={() => {
        //     setSelectedIPd(undefined)
        //   }}
        //   open={innerWidth! <= desktopScreenWidth && selectedIpd !== undefined}
        // />
      )} */}

      {/* <AppointmentTypeOptions
        open={openAppointmentTypeDialog}
        id={567}
        onClose={() => {
          setOpenAppointmentTypeDialog(false)
        }}
        onTypeSelected={(type: string) => {
          if (type === 'consultation') {
            setConsult(true)
            setOpenAppointmentTypeDialog(false)
            setDayCareCreate(false)
          } else {
            setDayCareCreate(true)
            setOpenAppointmentTypeDialog(false)
          }
        }}
      /> */}
    </div>
  )
}
