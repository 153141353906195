export interface AlertActionType {
  alertType: AlertType
  showAlert: boolean
  alertMessage: string
  alertActionLink?: string
  clearAlert?: boolean
  alertTitle?: string
  disableAutoHide?: boolean
}

export enum AlertType {
  None,
  SuccessAlert,
  ErrorAlert,
  InformationAlert,
  WarningAlert,
}
