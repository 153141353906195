import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  TextField,
  IconButton,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { CloseSharp, DeleteOutline } from '@material-ui/icons'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { FhirCatalougeDetails } from 'models/fhirCatalougeDetails'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addMembershipDetails,
  resetAddmembershipState,
} from 'redux/administration/membership/addMembershipDetails/addMembershipDetailsSlice'
import { SecondaryMember } from 'redux/administration/membership/addMembershipDetails/addMembershipDetailsTypes'
import { resetState } from 'redux/administration/userSetup/actorInvitationSlice/actorInvitationSetupSlice'
import { RootState } from 'redux/rootReducer'
import {
  getMemberShipPlanAmount,
  getMemberShipPlanAmountCurrency,
  getMemberShipPlanCredit,
} from 'utils/common/catalogueDataConverter'
import { SubscriberRelations } from 'utils/constants/relation'
import { getSymbolForCurrency } from 'utils/fhirResoureHelpers/currency_helper'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { PatientSelectorWithSearchOption } from 'views/components/patients/patientSelectorBySearch'
import { PatientSmallTile } from 'views/components/patients/patientTileSmallTile'
import CustomizedDividers from 'views/components/toggle_selector'
import { WelloFormItemLabel } from 'wello-web-components'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'

export interface SecondaryMemberUI {
  patient: R4.IPatient
  relation: R4.ICodeableConcept
  isAllowed: boolean
}

interface Props {
  open: boolean
  onBackClick: () => void
  onContinueClick: () => void
}
export const AddMembershipForm: React.FC<Props> = ({
  open,
  onBackClick: onClose,
  onContinueClick,
}) => {
  const addMembershipDetailsSlice = useSelector(
    (state: RootState) => state.addMembershipDetailsSlice
  )

  const membershipPlans = useSelector(
    (state: RootState) => state.availableMembershipTypesSlice.catalogueListView
  )

  const [selectedPlan, setSelectedPlan] = useState<FhirCatalougeDetails>()
  const [reference, setReference] = useState<string | undefined>()
  const [isAlreadySubscribed, setIsAlreadySubscribed] = useState<boolean>(false)

  const dispatch = useDispatch()

  const [availableCredit, setAvailableCredit] = useState<number>(0)
  const [offeringCredit, setOfferingCredit] = useState<number>(0)
  const [amount, setAmount] = useState<number>(0)

  const [selectedStartDate, setSelectedStartDate] = useState<Date>(
    moment().startOf('day').toDate()
  )

  const [selectedPrimaryMember, setSelectedPrimaryMember] =
    useState<R4.IPatient | undefined>(undefined)
  const [selectedSecondaryMembers, setSelectedSecondaryMembers] = useState<
    SecondaryMemberUI[]
  >([])
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  const isValidData = () => {
    if (
      selectedPlan &&
      selectedPrimaryMember &&
      selectedStartDate &&
      !isAlreadySubscribed
    ) {
      let res = true

      if (selectedSecondaryMembers.length > 0) {
        selectedSecondaryMembers.forEach((member) => {
          if (!member.relation) {
            res = false
          }
          if (!member.patient) {
            res = false
          }
          if (member.relation) {
            if (
              member.relation.coding === undefined ||
              member.relation.coding.length === 0
            ) {
              res = false
            }
          }
        })
      }
      return res
    }
    return false
  }

  function resetDetails() {
    setSelectedPlan(undefined)
    setReference(undefined)
    setAvailableCredit(0)
    setSelectedStartDate(moment().startOf('day').toDate())
    setSelectedPrimaryMember(undefined)
    setSelectedSecondaryMembers([])
  }

  function handleSubmit() {
    dispatch(
      addMembershipDetails({
        chargeItemDefinitionId: selectedPlan?.chargeItemDefinition.id!,
        primaryPatientId: selectedPrimaryMember?.id!,
        baseAmountPaid: amount,
        baseBeneficiaryAmount: offeringCredit,
        startDate: moment(selectedStartDate).toISOString(),
        reference,
        alreadyAvailableCredit: availableCredit,
        secondaryPatientIds: selectedSecondaryMembers.map((member) => ({
          patientId: member.patient.id!,
          relation: member.relation,
        })),
      })
    )
  }

  useEffect(() => {
    if (addMembershipDetailsSlice.additionSuccessful) {
      resetDetails()
      dispatch(resetAddmembershipState())
      onClose()
    }
    return () => {}
  }, [addMembershipDetailsSlice.additionSuccessful])

  return (
    <Dialog
      open={open}
      onClose={() => {
        dispatch(resetAddmembershipState())
        resetDetails()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Add Membership</DialogTitle>
      <DialogContent dividers={true}>
        <Box display='flex' flexDirection='column' width='100%'>
          {addMembershipDetailsSlice.error && (
            <Box display='flex'>
              <Alert severity='error'>
                {addMembershipDetailsSlice.errorMessage}
              </Alert>
            </Box>
          )}
          {addMembershipDetailsSlice.additionSuccessful && (
            <Box display='flex'>
              <Alert severity='success'>
                'Membership details added successfully'
              </Alert>
            </Box>
          )}
          {!addMembershipDetailsSlice.additionSuccessful && (
            <Box display='flex' flexDirection='column'>
              <Box display='flex' flexDirection='column' width='80%'>
                {isAlreadySubscribed && (
                  <Box display='flex' flexDirection='row' width='100%'>
                    <Alert severity='error'>
                      'Looks like this patient is already subscribed to a
                      membership plan. Please hover on STAR to see Existing
                      Membership details.'
                    </Alert>
                  </Box>
                )}
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <WelloFormItemLabel title='Select Primary Member' />
                    {selectedPrimaryMember && (
                      <PatientSmallTile
                        patient={selectedPrimaryMember}
                        displayDelete={true}
                        onDelete={() => {
                          setSelectedPrimaryMember(undefined)
                          setIsAlreadySubscribed(false)
                        }}
                        onSubscriptionDetailsFetched={({
                          isSubscribed,
                          coverage,
                        }: {
                          isSubscribed: boolean
                          coverage?: R4.ICoverage | undefined
                        }) => {
                          setIsAlreadySubscribed(isSubscribed)
                          if (isSubscribed && coverage) {
                            dispatch(
                              showErrorAlert(
                                'Looks like this patient is already subscribed to a membership plan,'
                              )
                            )
                          }
                        }}
                      />
                    )}
                    {selectedPrimaryMember === undefined && (
                      <PatientSelectorWithSearchOption
                        disabled={false}
                        onSelectionChanges={(e) => {
                          setSelectedPrimaryMember(e)
                        }}
                        preSelectedComplaints={[]}
                        doctorListId='primary-member'
                      />
                    )}
                  </Box>
                </Box>

                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <WelloFormItemLabel title='Add Secondary Members' />
                    {selectedSecondaryMembers.length <= 10 && (
                      <PatientSelectorWithSearchOption
                        disabled={false}
                        onSelectionChanges={(e) => {
                          setSelectedSecondaryMembers([
                            ...selectedSecondaryMembers,
                            { patient: e, relation: {}, isAllowed: false },
                          ])
                        }}
                        preSelectedComplaints={
                          selectedPrimaryMember
                            ? [
                                selectedPrimaryMember.id!,
                                ...selectedSecondaryMembers.map(
                                  (e) => e.patient.id ?? ''
                                ),
                              ]
                            : selectedSecondaryMembers.map(
                                (e) => e.patient.id ?? ''
                              )
                        }
                        doctorListId='secondary-member'
                      />
                    )}
                    {selectedSecondaryMembers.length > 0 && (
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        py={1}
                      >
                        {selectedSecondaryMembers.map((e, index) => (
                          <Box
                            key={e.patient.id}
                            display='flex'
                            flexDirection='row'
                            alignContent='center'
                            alignItems='center'
                            my={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <PatientSmallTile
                              patient={e.patient}
                              hideAge={true}
                              displayDelete={false}
                              onDelete={() => {
                                setIsAlreadySubscribed(false)
                                setSelectedSecondaryMembers(
                                  selectedSecondaryMembers.filter(
                                    (mem) => e.patient.id !== mem.patient.id
                                  )
                                )
                              }}
                              onSubscriptionDetailsFetched={({
                                isSubscribed,
                                coverage,
                              }: {
                                isSubscribed: boolean
                                coverage?: R4.ICoverage | undefined
                              }) => {
                                if (isSubscribed && coverage) {
                                  dispatch(
                                    showErrorAlert(
                                      'Looks like this patient is already subscribed to a membership plan,'
                                    )
                                  )
                                }
                                setSelectedSecondaryMembers(
                                  selectedSecondaryMembers.map((mem) => {
                                    if (mem.patient.id === e.patient.id) {
                                      return {
                                        ...mem,
                                        isAllowed: !isSubscribed,
                                        relation: {},
                                      }
                                    }
                                    return mem
                                  })
                                )
                              }}
                            />
                            <Box width='35%'>
                              <WelloSelectFHIRCoding
                                id='unit_person'
                                preSelectedCoding={e.relation}
                                availableCodings={SubscriberRelations}
                                onChanged={(selctedCoding) => {
                                  if (e) {
                                    setSelectedSecondaryMembers(
                                      selectedSecondaryMembers.map((mem) => {
                                        if (mem.patient.id === e.patient.id) {
                                          return {
                                            ...mem,
                                            relation: {
                                              coding: [selctedCoding],
                                            },
                                          }
                                        }
                                        return mem
                                      })
                                    )
                                  }
                                }}
                                textFieldProps={{
                                  size: 'small',
                                  label: 'Relation',
                                  disabled:
                                    addMembershipDetailsSlice.adding ||
                                    !e.isAllowed,
                                  style: {
                                    border: '0px solid #e0e0e0',
                                  },
                                }}
                              />
                            </Box>
                            <IconButton
                              aria-label='remove member'
                              size='small'
                              onClick={() => {
                                setSelectedSecondaryMembers(
                                  selectedSecondaryMembers.filter(
                                    (mem) => e.patient.id !== mem.patient.id
                                  )
                                )
                              }}
                            >
                              <CloseSharp />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <WelloFormItemLabel title='Select Membership Plan' />
                    <CustomizedDividers
                      onSelectionChanged={(id) => {
                        const selectedPlanDef = membershipPlans?.find(
                          (e) => e.planDefinition.id === id
                        )
                        if (selectedPlanDef === undefined) return
                        setSelectedPlan(selectedPlanDef)
                        setAvailableCredit(
                          getMemberShipPlanCredit(selectedPlanDef)
                        )
                        setOfferingCredit(
                          getMemberShipPlanCredit(selectedPlanDef)
                        )
                        setAmount(getMemberShipPlanAmount(selectedPlanDef))
                      }}
                      //   disablePreSelect={true}
                      options={(membershipPlans ?? []).map((e) => ({
                        value: e.planDefinition.id!,
                        displayText: e.planDefinition.title!,
                      }))}
                    />
                  </Box>
                </Box>
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <WelloFormItemLabel title='Referenced by' />
                    <TextField
                      id='referenced-by'
                      label=''
                      size='small'
                      variant='outlined'
                      fullWidth
                      value={reference}
                      inputProps={{
                        maxLength: 24,
                      }}
                      onChange={(e) => {
                        setReference(e.target.value)
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box display='flex' flexDirection='row' py={2} width='100%'>
                <Box flexGrow={1}>
                  <Divider />{' '}
                </Box>
              </Box>
              {selectedPlan && (
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box display='flex' flexDirection='row' width='100%'>
                    <Box display='flex' flexDirection='column' width='50%'>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <WelloFormItemLabel title='Plan start date' />
                        <KeyboardDatePicker
                          clearable
                          id='date'
                          maxDate={moment()}
                          minDate='04-01-2019'
                          value={selectedStartDate}
                          format='DD-MM-YYYY'
                          onChange={(e) => {
                            if (e) setSelectedStartDate(moment(e).toDate())
                          }}
                          size='small'
                          inputVariant='outlined'
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='50%'
                      px={1}
                    >
                      <WelloFormItemLabel title='Amount' />
                      <TextField
                        size='small'
                        variant='outlined'
                        type='tel'
                        InputProps={{
                          startAdornment: getSymbolForCurrency(
                            getMemberShipPlanAmountCurrency(selectedPlan) ?? ''
                          ),
                          inputProps: {
                            min: 0,
                            maxLength: 9,
                            max: 999999999,
                          },
                        }}
                        value={amount}
                        onChange={(e) => {
                          const regex = /^[0-9\b]+$/
                          if (
                            e.target.value === '' ||
                            regex.test(e.target.value)
                          ) {
                            setAmount(Number(e.target.value))
                          }
                        }}
                      />
                    </Box>
                  </Box>

                  <Box display='flex' flexDirection='row' width='100%'>
                    <Box display='flex' flexDirection='column' width='50%'>
                      <WelloFormItemLabel title='Total Credit Given' />
                      <TextField
                        size='small'
                        variant='outlined'
                        type='tel'
                        InputProps={{
                          startAdornment: getSymbolForCurrency(
                            getMemberShipPlanAmountCurrency(selectedPlan) ?? ''
                          ),
                          inputProps: {
                            min: 0,
                            maxLength: 9,
                            max: 999999999,
                          },
                        }}
                        value={offeringCredit}
                        onChange={(e) => {
                          const regex = /^[0-9\b]+$/
                          if (
                            e.target.value === '' ||
                            regex.test(e.target.value)
                          ) {
                            setOfferingCredit(Number(e.target.value))
                          }
                        }}
                      />
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='50%'
                      px={1}
                    >
                      <WelloFormItemLabel title='Current Available Credit' />
                      <TextField
                        size='small'
                        variant='outlined'
                        type='tel'
                        InputProps={{
                          startAdornment: getSymbolForCurrency(
                            getMemberShipPlanAmountCurrency(selectedPlan) ?? ''
                          ),
                          inputProps: {
                            min: 0,
                            max: offeringCredit,
                            maxLength: 9,
                          },
                        }}
                        value={availableCredit}
                        onChange={(e) => {
                          const regex = /^[0-9\b]+$/
                          if (
                            e.target.value === '' ||
                            regex.test(e.target.value)
                          ) {
                            if (Number(e.target.value) > offeringCredit) return
                            setAvailableCredit(Number(e.target.value))
                          }
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {addMembershipDetailsSlice.adding && (
          <CircularProgress color='inherit' size={20} />
        )}
        <Button
          onClick={() => {
            resetDetails()
            onClose()
          }}
          variant='outlined'
          disabled={addMembershipDetailsSlice.adding}
          disableElevation
        >
          {t('labelCommon:back')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={!isValidData() || addMembershipDetailsSlice.adding}
          onClick={() => {
            handleSubmit()
          }}
        >
          Create Membership
        </Button>
      </DialogActions>
    </Dialog>
  )
}
