import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { CardsEntity } from 'redux/subscription/cpgRecommendations/cpgResponse'
import {
  getDietPlans,
  getDietTasksFromEntries,
  getIdOfCardEntry,
  getKriyas,
  hasError,
} from 'utils/careplan_utils/cpg_recommendations_utils'
import {
  getTableDataForWeekLongDietPlan,
  getWeekLongDietPlanFromTasks,
} from 'utils/careplan_utils/weeklyDietPlanUtils'
import { getSelectionContextTypeSections } from 'utils/contextHelpers/cpgSelectionContextHelpers'
import { getDisplayOfSystemFromCodableConcept } from 'utils/fhirResourcesHelper'
import { WeeklyDietPlanViewFromTasks } from 'views/components/dietplan/weeklyDietPlan/weeklyDietPlan'
import CPGSelectionContext from 'views/contexts/cpgContextSelection'
import { AddWeeklyDietTaskRecommendationButton } from '../add_diet_tasks_button'
import { AddKriyaRecommendationButton } from '../add_kriya_button'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))
interface CdsCardDetailsProps {
  patient: R4.IPatient
  encounterId: string
  carePlanIdentifier: string
  cdsCard: CardsEntity
}

export const DietPlanOfCPGRecommendation: React.FC<CdsCardDetailsProps> = ({
  patient,
  encounterId,
  carePlanIdentifier,
  cdsCard: card,
}: CdsCardDetailsProps) => {
  const cdsRecommendationAddition = useContext(CPGSelectionContext)

  return (
    <Box display='flex' flexDirection='column' p={1} overflow='auto'>
      {!hasError(card) &&
        card.interceptedSuggestions &&
        card.interceptedSuggestions.has('dietPlan') && (
          <Box
            display='flex'
            flexDirection='column'
            key='diet_plan_cpg_label'
            py={0.25}
            overflow='auto'
          >
            <Box
              display='flex'
              flexDirection='column'
              key='diet_plan_cpg_inner_label'
              py={0.25}
              overflow='auto'
            >
              <Box
                width='100%'
                flex='row'
                id='referrals_list'
                display='flex'
                overflow='auto'
              >
                {(card.interceptedSuggestions.get('dietPlan') as any[]).length >
                  0 && (
                  <Box display='flex' width='100%' p={1}>
                    <WeeklyDietPlanViewFromTasks
                      tableData={getTableDataForWeekLongDietPlan(
                        getWeekLongDietPlanFromTasks(
                          card.interceptedSuggestions.get('dietPlan') as any[]
                        )
                      )}
                      displayRecordedDate={false}
                    />
                  </Box>
                )}
              </Box>
              <Box
                width='100%'
                flexDirection='row-reverse'
                id='referrals_list'
                display='flex'
              >
                <AddWeeklyDietTaskRecommendationButton
                  encounterId={encounterId}
                  patient={patient}
                  carePlanIdentifier={carePlanIdentifier}
                  dietTasks={
                    card.interceptedSuggestions.get('dietPlan') as any[]
                  }
                />
              </Box>
            </Box>
          </Box>
        )}
    </Box>
  )
}
