import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Button,
  Tooltip
} from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getIdentifierValueOfUrlType,
} from 'utils/fhirResourcesHelper'
import { AllDetailsOfRoom, getAvailableBedsForRoomParsed } from 'utils/roomsHelpers/roomSelectionHelpers1'
import KingBedOutlinedIcon from '@material-ui/icons/KingBedOutlined'
import InfoIcon from '@mui/icons-material/Info';
import { WelloLoadingIndicator } from 'wello-web-components'
import {
  getDisplayOfBedOperationalStatus,
  isBedFree,
  updateRoomStatusInFhir,
} from 'utils/fhirResoureHelpers/roomsHelper'
import {
  ConfirmationDialogProps,
  ConfirmationDialog,
} from 'views/components/common/deleteConfirmationDialog'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      margin: '4px',
    },
  })
)
interface Props {
  roomId: string
}
export const BedsOfRoom: React.FC<Props> = ({ roomId }: Props) => {
  const classes = useStyles()
  const [fetchingBeds, setFetchingBedsStatus] =
    React.useState<NetworkRequestStatus>({
      initial: true,
    })
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  const [updateBedStatus, setUpdateBedStatus] =
    React.useState<NetworkRequestStatus>({
      initial: true,
    })

  function fetchAvailableBeds() {
    setFetchingBedsStatus({
      requesting: true,
    })


    getAvailableBedsForRoomParsed(roomId, true)
      .then((e) => {
        setAvailableBeds(e)
        setFetchingBedsStatus({
          requesting: false,
          requestSuccess: true,
        })
      })
      .catch((e) => {
        setAvailableBeds([])
        setFetchingBedsStatus({
          requesting: false,
          requestError: true,
        })
      })
  }

  

  function updateBedStatusInFhir(bedId: string, status: 'block' | 'free') {
    setUpdateBedStatus({
      requesting: true,
    })

    updateRoomStatusInFhir(bedId, status)
      .then((e) => {
        fetchAvailableBeds()
        setUpdateBedStatus({
          requesting: false,
          requestSuccess: true,
        })
      })
      .catch((e) => {
        setUpdateBedStatus({
          requesting: false,
          requestError: true,
        })
      })
  }

  const [beds, setAvailableBeds] = React.useState<AllDetailsOfRoom[]>([])

  useEffect(() => {
    if (roomId.length > 0) {
      fetchAvailableBeds()
    }
  }, [])



  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
        </div>
      )}
    >
      <Box
        display='flex'
        flexGrow={1}
        alignItems='flex-end'
        justifyContent='center'
        width='100%'
      >
        {fetchingBeds.requestSuccess && beds && beds.length === 0 && (
          <WelloLoadingIndicator message='Fetching Beds' />
        )}

        {fetchingBeds.requesting && (
          <WelloLoadingIndicator message='Fetching Beds' />
        )}

        {updateBedStatus.requesting && (
          <WelloLoadingIndicator message='Updating Beds' />
        )}

        {fetchingBeds.requestSuccess &&
          !updateBedStatus.requesting &&
          beds &&
          beds.length > 0 && (
            <Grid
              container
              style={{
                margin: '0px',
                padding: 1,
              }}
            >
              {beds.map((e) => (
                <Grid item xs={3} key={e.bed.id} className={classes.root}>
                  <Box display='flex' flexDirection='row' p={0.25}>
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignContent='center'
                      alignItems='center'
                      alignSelf='center'
                    >
                      <KingBedOutlinedIcon />
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='column'
                      p={0.5}
                      flexGrow={1}
                    >
                      <Box display='flex' flexDirection='row'>
                        <Typography variant='subtitle1' color='initial'>
                          Bed No:{' '} 
                          {getIdentifierValueOfUrlType(
                            e.bed.identifier ?? [],
                            'SNO',
                            'http://terminology.hl7.org/CodeSystem/v2-0203'
                          ) ?? ''} &nbsp;&nbsp;
                        </Typography>
                      </Box>
                      <Box display='flex' flexDirection='row'>
                      <Box display='flex' flexDirection='row'>
                        <Typography variant='subtitle2' color='textSecondary'>
                          {getDisplayOfBedOperationalStatus(e.bed)}
                        </Typography>
                      </Box>
                      {!isBedFree(e.bed) && 
                      <Box display='flex' flexDirection='row' paddingLeft={2}>
                        <Tooltip title={
                        <div><h2><FontAwesomeIcon
                           icon={faUser}
                           size='1x'
                        />&nbsp;&nbsp;{e.patientName}{e.relatedPersonName}<br/>{e.ipdRegistration}</h2></div>}><InfoIcon style={{ color: '#4543d9' }} fontSize='small'/></Tooltip>
                      </Box>}
                      </Box>
                      <Box display='flex' flexDirection='row-reverse' pt={0.25}>
                        <Button
                          variant='outlined'
                          size='small'
                          color={isBedFree(e.bed) ? 'secondary' : 'primary'}
                          onClick={() => {
                            setShowDeleteConfirmation({
                              open: true,
                              title: 'Update',
                              onContinueActionClicked: () => {
                                setShowDeleteConfirmation({
                                  open: false,
                                })
                                updateBedStatusInFhir(
                                  e.bed.id!,
                                  isBedFree(e.bed) ? 'block' : 'free'
                                )
                              },
                              onCancelActionClicked: () => {
                                setShowDeleteConfirmation({
                                  open: false,
                                })
                              },
                              displayText:
                                'Are you sure you want to update the bed status?',
                              continueActionButtonText: 'Yes',
                              cancelActionButtonText: 'No',
                            })
                          }}
                        >
                          {isBedFree(e.bed)
                            ? 'Mark as Occupied'
                            : 'Mark as Available'}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        {showDeleteConfirmation.open && (
          <ConfirmationDialog {...showDeleteConfirmation} />
        )}
      </Box>
    </ErrorBoundary>
  )
}

