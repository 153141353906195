/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/react-in-jsx-scope */
import {
  Box,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  Input,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ClearOutlined } from '@material-ui/icons'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { CohortDataStage } from 'models/chartProps'
import React, { useState } from 'react'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { getFilterLabel } from 'utils/cohort/cohortUtils'

type VisibleStageSelectProps = {
  label: string
  name: string
  stages: any
  visibleStages: string[]
  handleStageChange: any
}

export const CustomVisibleStage: React.FC<VisibleStageSelectProps> = ({
  label,
  name,
  stages,
  visibleStages,
  handleStageChange,
}: VisibleStageSelectProps) => {
  const data = Object.keys(stages)
  return (
    <Box width='100%'>
      <WelloFormItemLabel title={label} />

      <Autocomplete
        style={{
          borderRadius: '4px',
        }}
        fullWidth
        size='small'
        id='visibleStages'
        multiple
        disableClearable
        getOptionSelected={(option, value) => option === value}
        getOptionLabel={(option) => getFilterLabel(option)}
        options={data}
        defaultValue={visibleStages}
        onChange={(e, changedValue, reason) => {
          handleStageChange(changedValue)

          // handleInputChangeForOccupation(
          //   'occupation',
          //   changedValue.title
          // )
        }}
        autoComplete
        ChipProps={{
          deleteIcon: (
            <ClearOutlined
              style={{
                height: '15px',
                color: 'white',
              }}
            />
          ),
          style: {
            backgroundColor: kPrimaryLight,
            borderRadius: '4px',
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Select Stages'
            fullWidth
            variant='outlined'
            id='stage_search'
            size='small'
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
    </Box>
  )
}
