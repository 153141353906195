import crypto from 'crypto'
import { logger } from './logger'

export const getUrlPathParameter = (url: string, key: string) => {
  const params = new URLSearchParams(url)

  return params.get(key)
}

function isValidHttpUrl(inputUrl: string) {
  let url

  try {
    url = new URL(inputUrl)
  } catch (_) {
    logger.error(_)
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

function base64URLEncode(str: any) {
  return str
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
}

function sha256(buffer: any) {
  return crypto.createHash('sha256').update(buffer).digest()
}

export function getVerifier() {
  return base64URLEncode(crypto.randomBytes(32))
}

export function getChallengeForVerifier(verifier: string) {
  return base64URLEncode(sha256(verifier))
}
const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'

export function generateString(length: number) {
  let result = ' '
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}
