import { R4 } from '@ahryman40k/ts-fhir-types'

export const DTRReflexObservationType: R4.ICoding[] = [
  {
    system: 'http://snomed.info/sct',
    code: '349006',
    display: 'Absent',
  },
  {
    system: 'http://snomed.info/sct',
    code: '102983008',
    display: 'Brisk',
  },
  {
    system: 'http://snomed.info/sct',
    code: '86854008',
    display: 'Hyperactive',
  },
  {
    system: 'http://snomed.info/sct',
    code: '49051001',
    display: 'Normal',
  },
  {
    system: 'http://snomed.info/sct',
    code: '49051001',
    display: 'Present',
  },

  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-dtr-reflex-finding-cs',
    code: 'other-dtr-reflex-finding',
    display: 'Other',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
