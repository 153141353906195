import { Box, makeStyles, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React from 'react'
import { CdsRecommendationsForAppointmentIPD } from '../cds_recommendations_listIPD'

interface RecommendationWrapperProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
}

const useStyles = makeStyles((theme) => ({
  titleText: {
    color: `#333333`,
    fontFamily: 'Open Sans',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '25px',

    textAlign: 'left',
    textTtransform: 'capitalize',
  },
  wrapper: {
    background: '#FDFFCD',
    border: '0.5px solid #A7B50F',
    borderRadius: '4px',
  },
  textSubtitle: {
    fontFamily: 'Open Sans',
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '13.62px',
  },
}))

export const RecommendationWrapperIPD: React.FC<RecommendationWrapperProps> = ({
  fhirAppointmentDetails,
}: RecommendationWrapperProps) => {
  const classes = useStyles()
  return (
    <Box display='flex' flexDirection='row' className={classes.wrapper}>
      <Box display='flex' flexDirection='column' paddingY={1} paddingX={2}>
        <Box display='flex' flexDirection='row'>
          <Typography className={classes.titleText}>
            Care Plan Recommendations
          </Typography>
        </Box>
        <CdsRecommendationsForAppointmentIPD
          fhirPatientDetail={fhirAppointmentDetails}
          patient={fhirAppointmentDetails.patient}
        />
      </Box>
    </Box>
  )
}
