import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAlldayCareCharges } from 'redux/administration/management/dayCareSearchSliceForRate'
import { RootState } from 'redux/rootReducer'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { DayCareRateDataTable } from './dayCareRateDataTable'

export interface IUnitAdmin {}

export const DayCareRateSearch: React.FC<IUnitAdmin> = () => {
  const dayCareRateTableSearchSlice = useSelector(
    (state: RootState) => state.dayCareRateTableSearchSlice
  )

  const dispatch = useDispatch()

  const { width } = useWindowDimensions()

  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  useEffect(() => {
    dispatch(getAlldayCareCharges('test'))
  }, [dispatch])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Grid container>
            <Grid item sm={6}>
              {/* <Box p={2}>
            <TextField
              variant='outlined'
              size='small'
              autoFocus={true}
              placeholder='Search'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                  </InputAdornment>
                ),
              }}
            />
          </Box> */}
            </Grid>
            {/* <Grid item sm={6}>
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Box mr={2}>
              <Button 
               variant='contained'
               color='primary'
               title='Book Appointment(IPD)'
               id='add_appointments_ipd'
               onClick={() => {
                 setShowPopup(true)
                }}
              >
               Add
              </Button>
              </Box>
            </Box>
          </Grid> */}
          </Grid>
          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
            >
              {dayCareRateTableSearchSlice.searchingCharges && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>
            <Box
              display='flex'
              flexGrow={4}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
              style={{
                backgroundColor: '#ececec',
              }}
            >
              {dayCareRateTableSearchSlice.noResultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    align='center'
                  >
                    No User Data Available.
                  </Typography>
                </Box>
              )}
              {dayCareRateTableSearchSlice.errorWhileSearchingCharges && (
                <Box
                  //   display='flex'
                  //   flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                  paddingLeft={50}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container justifyContent='center'>
                        <Typography
                          variant='subtitle1'
                          color='error'
                          align='center'
                        >
                          Error while searching Daycare charges. Please try
                          again
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {dayCareRateTableSearchSlice.resultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height='100%'
                  overflow='auto'
                  // flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexGrow={1}
                    overflow='auto'
                    height='100%'
                    //  paddingTop={0.2}
                  >
                    <DayCareRateDataTable
                      daycareChargesData={
                        dayCareRateTableSearchSlice.dayCareCharges
                          ? dayCareRateTableSearchSlice.dayCareCharges
                          : []
                      }

                      //   onUserSelected={(therapy) => {
                      //     setSelectedTherapyDetails(therapy)
                      //   }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </div>
  )
}
