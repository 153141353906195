/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { settingsBackgroundColor } from 'configs/styles/muiThemes'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchingPartnerLabOrders } from 'redux/collectionCenter/partnerLabOrderSearch/partnerLabOrderSearch'
import { RootState } from 'redux/rootReducer'
import { CreateLabOrderHandler } from 'views/components/lab/orderCreationManagement/createLabOrderManagement'
import { PartnerLabOrderListView } from './partnerLabOrderListView'

interface Props {
  partnerLabLocation?: R4.ILocation
  partnerLabCommission: number
  partnerLabUser: R4.IPractitionerRole
}

export const PartnerLabOrders: React.FC<Props> = ({
  partnerLabLocation,
  partnerLabCommission,
  partnerLabUser,
}: Props) => {
  const partnerLabOrderSearchSlice = useSelector(
    (state: RootState) => state.partnerLabOrderSearchSlice
  )
  const dispatch = useDispatch()
  const [selectedOrder, setSelectedOrder] = useState<FhirLabOrderDetail>()
  const [openPartnerLabPopup, setOpenPartnerLabPopup] = useState(false)
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    dispatch(
      searchingPartnerLabOrders(partnerLabLocation?.id ?? '', searchText)
    )
    setSelectedOrder(undefined)
    return () => {}
  }, [])

  useEffect(() => {
    if (partnerLabOrderSearchSlice.searchingAppointments) {
      setSelectedOrder(undefined)
    }
  }, [partnerLabOrderSearchSlice.searchingAppointments])

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      flexGrow={1}
      height='100%'
    >
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        bgcolor={settingsBackgroundColor}
      >
        <Grid
          container
          direction='row'
          alignContent='space-between'
          justify='space-between'
        >
          <Grid item>
            <Grid container direction='column' justify='flex-start'>
              <Grid item xs>
                <Box p={1}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box display='flex' paddingX={1} paddingY={1}>
                      <TextField
                        onChange={(event) => {
                          dispatch(
                            searchingPartnerLabOrders(
                              partnerLabLocation?.id ?? '',
                              event.target.value
                            )
                          )
                        }}
                        variant='outlined'
                        size='small'
                        autoFocus={true}
                        placeholder='Search By Order No'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <img
                                src={`${process.env.PUBLIC_URL}/search.png`}
                                alt='s'
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              direction='column'
              alignContent='space-between'
              justify='space-between'
            >
              <Grid item xs style={{ marginTop: 17 }} />
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            alignItems='center'
            style={{
              alignSelf: 'center',
            }}
          >
            <Box
              display='flex'
              width='100%'
              flexDirection='row'
              justifyContent='flex-end'
              paddingRight={2}
            >
              <Tooltip title='Create Order'>
                <IconButton
                  aria-label='btn_ord_cancel'
                  color='primary'
                  onClick={() => {
                    setOpenPartnerLabPopup(true)
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/labAdd.png`} alt='add' />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        display='flex'
        flexGrow={1}
        flexDirection='column'
        justifyContent='center'
        alignContent='center'
        overflow='auto'
        //   paddingTop={1}
      >
        {partnerLabOrderSearchSlice.noResultsAvailable && (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            width='100%'
            height='100%'
            alignContent='center'
          >
            <Typography variant='subtitle1' color='initial' align='center'>
              No Orders Available.
            </Typography>
          </Box>
        )}

        {partnerLabOrderSearchSlice.errorWhileSearchingOrders && (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            width='100%'
            height='100%'
            alignContent='center'
          >
            <Typography variant='subtitle1' color='error' align='center'>
              Error while searching orders
            </Typography>
          </Box>
        )}

        {partnerLabOrderSearchSlice.searchingAppointments && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
          >
            <CircularProgress size={25} />
          </Box>
        )}
      </Box>

      {partnerLabOrderSearchSlice.availableOrders && (
        <Box
          display='flex'
          flexDirection='column'
          width='100%'
          height='100%'
          overflow='auto'
        >
          {partnerLabOrderSearchSlice.availableOrders.length === 0 && (
            <Box px={1}>
              <Typography variant='subtitle1'>
                {' '}
                No Order(s) available
              </Typography>
            </Box>
          )}
          <Box display='flex' flexGrow={1} overflow='auto' height='100%'>
            <PartnerLabOrderListView
              preselectedOrderId={selectedOrder?.serviceRequest?.id}
              labOrders={partnerLabOrderSearchSlice.availableOrders}
              locationId={partnerLabLocation?.id ?? ''}
              percentage={partnerLabCommission}
              onLabOrderSelected={(order) => {
                setSelectedOrder(order)
                //   dispatch(
                //     fetchOrderDetails(order.serviceRequest.id ?? '')
                //   )
              }}
            />
          </Box>
        </Box>
      )}
      <CreateLabOrderHandler
        open={openPartnerLabPopup}
        onLabOrderCreated={() => {
          setOpenPartnerLabPopup(false)
        }}
        onClose={() => {
          setOpenPartnerLabPopup(false)
        }}
        locationId={partnerLabLocation?.id ?? ''}
        partnerLabUser={partnerLabUser}
      />
    </Box>
  )
}
