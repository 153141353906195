import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  makeStyles,
  FormControl,
  FormLabel,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { Diet } from 'models/diet'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import {
  addDietDetails,
  resetAddDietPlanDetails,
} from 'redux/consultation/dietPlan/addDietPlan/addDietPlanSlice'
import {
  getActualDiet,
  getDietPlanData,
  requestDietPlanDetails,
} from 'redux/consultation/dietPlan/existingDietPlanSearch/existingDietPlanSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  addDietDataForCustom,
  addDietDataForCustomForMultiple,
} from 'utils/fhirResoureHelpers/ipdDietHelper'
import { WelloFormItemLabel } from '../LeftMenu/WelloFormItemLabel'
import { WelloTextFieldWithoutTitle } from '../LeftMenu/WelloTextFieldWitoutTitle'
import { CustomDiet } from './customDietPlan'
import { DietPlanCardForDoctor } from './dayDietPlanForEncounter'

interface Props {
  open: boolean
  appointmentStartDate: string
  encounterID?: string
  patientID: string
  practitionerRoleId?: string
  onClose: () => void
  onReferralsAdded: () => void
}
export const DietPlanManagementOfEncounter: React.FC<Props> = ({
  open,
  appointmentStartDate,
  onClose,
  onReferralsAdded,
  encounterID,
  practitionerRoleId,
  patientID,
}) => {
  const { t } = useTranslation(['common', 'medication', 'dosage'])
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '85%',
    },
  }))
  const classes = useStyles()

  const labDiagnosticsListSlice = useSelector(
    (state: RootState) => state.existingDietPlanSearchSlice
  )

  const dietTimeSearchSlice = useSelector(
    (state: RootState) => state.dietTImeSearchSlice
  )

  const startDateForCustom = moment(appointmentStartDate).format('YYYY-MM-DD')
  const endDateForCustom = moment(appointmentStartDate)
    .add(6, 'days')
    .format('YYYY-MM-DD')

  const [startDateVal, setStartDateVal] = useState<string>(
    moment(appointmentStartDate).format('YYYY-MM-DD')
  )
  const [endDateVal, setEndDateVal] = useState<string>()

  const [startDate, setStartDate] = useState<Date>()
  const [tillDate, setTillDate] = useState<Date>()
  const [dietChart, setDietChart] = useState<Diet[]>()
  const [customDietChart, setCustomDietChart] = useState<Diet[]>()

  const [preferredFoods, setPreferredFoods] = useState<string | undefined>()
  const [foodsToAvoid, setFoodsToAvoid] = useState<string | undefined>()

  const [textFields, setTextFields] = useState<string[]>([])
  const [type, setType] = useState<'fixed' | 'custom'>('fixed')

  const dispatch = useDispatch()
  const addDietPlanSlice = useSelector(
    (state: RootState) => state.addDietPlanSlice
  )

  const [exisingStartDate, setExistingStartDate] = useState<string>(
    moment().toISOString()
  )

  const [addLoading, setAddLoading] = useState<boolean>(false)
  const [addStatus, setAddStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [existingEndDate, setExistingEndDate] = useState<string>()

  const [treatmentType, setTreatmentType] = useState<string>('range')

  const [loading, setLoading] = useState<boolean>(false)
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  function resetForm() {
    Promise.resolve().then(() => {
      setTextFields([])
      setType('fixed')
      setStartDateVal(moment(appointmentStartDate).format('YYYY-MM-DD'))
      setEndDateVal(undefined)
    })
  }

  function handleDateChange(date: any) {
    setStartDateVal(date.target.value)
  }

  function handleEndDateChange(date: any) {
    setEndDateVal(date.target.value)
  }

  function addDietPlan(
    diet: Diet[],
    encounterId: string,
    practitionerRoleIdData: string,
    patientId: string
  ) {
    setAddStatus({ requesting: true })
    setDietChart([])

    setAddLoading(true)

    addDietDataForCustomForMultiple(diet, patientId, encounterId)
      .then((e: boolean) => {
        setAddLoading(false)
        if (e) {
          setAddStatus({
            requesting: false,
            requestSuccess: true,
          })

          dispatch(showSuccessAlert('Diet added successfully'))

          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setAddStatus({
            requesting: false,
            requestSuccess: true,
          })
          setAddLoading(false)
          dispatch(
            showErrorAlert('Error while adding diet. Please try again later')
          )
        }
      })
      .catch((e) => {
        dispatch(
          showErrorAlert('Error while adding diet. Please try again later')
        )
        console.log(e)
        setAddStatus({
          requesting: false,
          requestError: true,
        })

        setAddLoading(false)
      })
  }

  function getDietPlanDataFunction(
    encounterId: string,
    practitionerRoleIdData: string,
    patientId: string,
    startDateData: string,
    endDateData?: string
  ) {
    setUpdateStatus({ requesting: true })

    setLoading(true)

    getDietPlanData(
      dietTimeSearchSlice.prSelectedCoding ?? [],
      startDateData,
      patientId,
      encounterId,
      practitionerRoleIdData,
      true,
      endDateData
    )
      .then((e: Diet[]) => {
        setLoading(false)
        if (e) {
          setDietChart(e)
          if (labDiagnosticsListSlice.resultsAvailable) {
            const customData = labDiagnosticsListSlice.dateWiseDietList ?? []

            const dietList = labDiagnosticsListSlice.dietList ?? []

            if (dietList.length > 0) {
              if (customData.length > 1) {
                setType('custom')
              } else {
                setType('fixed')
              }
            } else {
              setType('fixed')
            }
          }

          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setType('fixed')
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while fetching reports. Please try again later'
            )
          )
        }
      })
      .catch((e: any) => {
        console.log(e)
        setType('fixed')
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  useEffect(() => {
    if (addDietPlanSlice.additionSuccessful) {
      setDietChart([])
      onReferralsAdded()
      dispatch(resetAddDietPlanDetails())
    }
  }, [dispatch, addDietPlanSlice, onReferralsAdded])

  useEffect(() => {
    getDietPlanDataFunction(
      encounterID ?? '',
      practitionerRoleId ?? '',

      patientID,
      exisingStartDate,
      existingEndDate
    )
    dispatch(
      requestDietPlanDetails({
        dietTime: dietTimeSearchSlice.prSelectedCoding ?? [],
        patientId: patientID,
        encounterId: encounterID,
        practitionerRoleId,
        showPlanSuggestedBySameDoctor: true,
      })
    )
  }, [])

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetForm()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Diet Plan</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
                resetForm()
              }}
              id='close_order'
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#ededed',
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0,
        }}
      >
        <Box display='flex' flexDirection='column' width='100%' py={1} px={1}>
          {loading === false && (
            <Box display='flex' flexDirection='row' px={2}>
              {' '}
              <FormControl component='fieldset'>
                <FormLabel id='demo-controlled-radio-buttons-group'>
                  <Typography variant='subtitle1' color='initial'>
                    Diet Type
                  </Typography>
                </FormLabel>
                <RadioGroup
                  row
                  aria-label='position'
                  name='position'
                  value={type}
                  onChange={(e) => {
                    setDietChart([])
                    setType(e.target.value as 'fixed' | 'custom')
                  }}
                >
                  <FormControlLabel
                    value='fixed'
                    control={<Radio color='primary' />}
                    label={
                      <Typography variant='subtitle2'>Fixed Diet</Typography>
                    }
                  />
                  <FormControlLabel
                    value='custom'
                    control={<Radio color='primary' />}
                    label={
                      <Typography variant='subtitle2'>Custom Diet</Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          )}

          <Box display='flex' flexDirection='row' width='100%' paddingRight={1}>
            {dietTimeSearchSlice.prSelectedCoding === undefined && (
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                justifyContent='center'
                py={1}
              >
                <Typography variant='subtitle1' color='initial'>
                  Please set diet time preference from settings
                </Typography>
              </Box>
            )}
            {type === 'fixed' && dietTimeSearchSlice.prSelectedCoding && (
              <DietPlanCardForDoctor
                encounterID={encounterID}
                patientID={patientID}
                practitionerRoleId={practitionerRoleId}
                mode='edit'
                onDateFetched={(planStartDate, endDate) => {
                  if (planStartDate) setExistingStartDate(planStartDate)
                  if (endDate) setExistingEndDate(endDate)
                }}
                onDietChanged={(
                  changedDiets,
                  planStartDate,
                  endDate,
                  allowedFoods,
                  avoidingFoods
                ) => {
                  console.log(changedDiets)
                  setDietChart(changedDiets)
                  setStartDate(planStartDate)
                  setTillDate(endDate)
                  setPreferredFoods(allowedFoods)
                  setFoodsToAvoid(avoidingFoods)
                }}
              />
            )}
            {addLoading && <CircularProgress size={30} />}
            {type === 'custom' && dietTimeSearchSlice.prSelectedCoding && (
              <Box display='flex' flexDirection='column' width='100%'>
                {!addLoading && (
                  <CustomDiet
                    appointmentStartDate={appointmentStartDate}
                    encounterID={encounterID}
                    patientID={patientID}
                    practitionerRoleId={practitionerRoleId}
                    mode='edit'
                    existingStartDate={exisingStartDate}
                    existingEndDate={existingEndDate}
                    onDietAdd={(
                      changedDiets: Diet[],
                      startDateUpdated: string,
                      endDate?: string
                    ) => {
                      console.log(changedDiets)
                      setDietChart(changedDiets)
                      setExistingEndDate(endDate)
                      setExistingStartDate(startDateUpdated)
                      // setStartDate(planStartDate)
                      // setTillDate(endDate)
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      {type === 'fixed' && (
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Box
            display='flex'
            flexDirection='row'
            p={1}
            flexGrow={1}
            alignItems='end'
            alignContent='end'
            justifyContent='end'
          >
            {addDietPlanSlice.adding && <CircularProgress size={15} />}
            {!addDietPlanSlice.adding && (
              <Button
                onClick={() => {
                  onClose()
                }}
                variant='outlined'
                color='primary'
                disableElevation
                id='cancel'
              >
                {t('labelCommon:Cancel')}
              </Button>
            )}

            {!addDietPlanSlice.adding && (
              <Button
                onClick={() => {
                  if (moment(tillDate).isAfter(moment(startDate))) {
                    if (
                      dietChart &&
                      dietChart.length !== 0 &&
                      encounterID &&
                      startDate &&
                      tillDate
                    ) {
                      dispatch(
                        addDietDetails(
                          dietTimeSearchSlice.prSelectedCoding ?? [],
                          dietChart[0],
                          encounterID,
                          patientID,
                          startDate,
                          tillDate,
                          foodsToAvoid,
                          preferredFoods
                        )
                      )
                    }
                  } else {
                    dispatch(
                      showErrorAlert('Till date should be after start date')
                    )
                  }
                }}
                variant='contained'
                color='primary'
                id='save'
                disabled={
                  encounterID === undefined ||
                  dietChart === undefined ||
                  dietChart.length === 0 ||
                  startDate === undefined ||
                  tillDate === undefined
                }
                disableElevation
              >
                {t('labelCommon:Save')}
              </Button>
            )}
          </Box>
        </DialogActions>
      )}

      {type === 'custom' && (
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Box
            display='flex'
            flexDirection='row'
            p={1}
            flexGrow={1}
            alignItems='end'
            alignContent='end'
            justifyContent='end'
          >
            {addLoading && <CircularProgress size={15} />}
            {!addLoading && (
              <Button
                onClick={() => {
                  onClose()
                }}
                variant='outlined'
                color='primary'
                disableElevation
                id='cancel'
              >
                {t('labelCommon:Cancel')}
              </Button>
            )}

            {!addLoading && (
              <Button
                onClick={() => {
                  if (
                    dietChart &&
                    dietChart.length > 0 &&
                    encounterID &&
                    practitionerRoleId
                  ) {
                    addDietPlan(
                      dietChart ?? [],
                      encounterID!,
                      practitionerRoleId,
                      patientID
                    )
                  }
                }}
                variant='contained'
                color='primary'
                id='save'
                disabled={
                  encounterID === undefined ||
                  dietChart === undefined ||
                  dietChart.length === 0
                }
                disableElevation
              >
                {t('labelCommon:Save')}
              </Button>
            )}
          </Box>
        </DialogActions>
      )}
    </Dialog>
  )
}
