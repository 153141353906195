import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource } from 'axios'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { DateWiseIPDAppointments } from 'models/dateSeparatedIPDAppointments'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserUnitReference,
  isTherapist,
} from 'services/userDetailsService'
import { getValueRefValueExtensionsOfUrlForBasic } from 'utils/fhirResourcesHelper'
import {
  getDateWiseIPD,
  getExpandedServiceRequestFromBundleForIpd,
} from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { logger } from 'utils/logger'
import { VisaDetailsSearchStatus } from './visaDetailsSearchStatus'

const initialState: VisaDetailsSearchStatus = {
  searching: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  error: false,
}

let currentSelectedDate: Date | undefined

const visaDetailsSearchSlice = createSlice({
  name: 'visaDetailsSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<VisaDetailsSearchStatus>) {
      state.errorMessage = action.payload.errorMessage
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searching = action.payload.searching
      state.resultsAvailable = action.payload.resultsAvailable
      state.visDetails = action.payload.visDetails
      state.error = action.payload.error
    },
  },
})

export const findVisaDetails =
  (patientId: string, serviceId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: VisaDetailsSearchStatus = {
      searching: true,
      error: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(visaDetailsSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const response: any = await fhirClient.doGetResource(
        `/Basic?subject:Patient=${patientId}`
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (response === undefined) {
        state.error = true
        state.searching = false

        dispatch(visaDetailsSearchSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = response as R4.IBundle
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          const conditionData: R4.IBasic[] = []
          if (appointmentResponse.entry) {
            for (let i = 0; i < appointmentResponse.entry.length; i++) {
              const condition: R4.IBasic = appointmentResponse.entry[i]
                .resource as R4.IBasic
              conditionData.push(condition)
            }
          }
          if (conditionData.length > 0) {
            const filteredDataForCountry = conditionData.filter(
              (d) =>
                getValueRefValueExtensionsOfUrlForBasic(
                  d.extension,
                  'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-service-request-ref-ext'
                ) === serviceId!
            )

            if (filteredDataForCountry.length > 0) {
              state.error = false
              state.searching = false
              state.resultsAvailable = true
              state.noResultsAvailable = false
              state.visDetails = filteredDataForCountry[0]

              dispatch(visaDetailsSearchSlice.actions.updatedStatus(state))
            } else {
              const errorSearchDoctor: VisaDetailsSearchStatus = {
                searching: false,
                error: false,
                resultsAvailable: false,
                noResultsAvailable: true,
              }
              dispatch(
                visaDetailsSearchSlice.actions.updatedStatus(errorSearchDoctor)
              )
            }
          } else {
            const errorSearchDoctor: VisaDetailsSearchStatus = {
              searching: false,
              error: false,
              resultsAvailable: false,
              noResultsAvailable: true,
            }
            dispatch(
              visaDetailsSearchSlice.actions.updatedStatus(errorSearchDoctor)
            )
          }
        } else {
          const errorSearchDoctor: VisaDetailsSearchStatus = {
            searching: false,
            error: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            visaDetailsSearchSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: VisaDetailsSearchStatus = {
        searching: false,
        error: true,
        resultsAvailable: false,
        noResultsAvailable: false,
        errorMessage: 'Error while searching visa details',
      }
      dispatch(visaDetailsSearchSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default visaDetailsSearchSlice.reducer
