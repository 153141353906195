import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { FileInput } from '../../LeftMenu/FileInput'

interface Props {
  fileType: string
  fileValidationType: string[]
  onFileChange: (file: any) => void
}

export const UploadTile: React.FC<Props> = ({
  fileType,
  fileValidationType,
  onFileChange,
}) => {
  const { t } = useTranslation()
  const [errorFile, setErrorFile] = React.useState<any>()
  const [selectedFiles, setSelectedFiles] = React.useState<any>()
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const dispatch = useDispatch()
  const dragOver = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const dragEnter = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const dragLeave = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const fileDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    const { files } = e.dataTransfer
    if (files.length) {
      handleFiles(files)
    }
  }
  const validateFile = (files: any) => {
    const fileResult: string[] = []
    for (let i = 0; i < files.length; i++) {
      if (fileValidationType.indexOf(files[i].type) === -1) {
        fileResult.push('false')
      } else {
        fileResult.push('true')
      }
    }
    return fileResult
  }

  const handleFiles = (files: any) => {
    const validFiles = validateFile(files)
    if (validFiles.includes('false')) {
      files[0].invalid = true
      setErrorFile(files[0])
      setSelectedFiles(files)
      setErrorMessage('Please enter a valid file format.')
    } else {
      setSelectedFiles(files)
      onFileChange(files)
    }
  }

  const filesSelected = (e: any) => {
    handleFiles(e.target.files)
  }

  return (
    <Box width='100%'>
      {/* <Card elevation={0}>
         <CardContent> */}
      <Box
        flexDirection='column'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Box padding={2} justifyContent='center'>
          <Box flexDirection='row' display='flex'>
            <Box paddingLeft={4} py={0.3}>
              <img src='uploadRep.png' height={15} width={15} alt='' />
            </Box>
            <Box px={1}>
              <Typography
                variant='body1'
                style={{
                  textAlign: 'center',
                  textDecoration: 'underline',
                }}
              >
                <FileInput
                  style={{ display: 'none' }}
                  name='photoUri'
                  type={fileType}
                  fileType='Upload Report'
                  onChange={filesSelected}
                  multiple={true}
                />
              </Typography>
            </Box>
          </Box>
          <Typography variant='body1' style={{ textAlign: 'center' }}>
            {t('OR')}
          </Typography>

          <div
            className='drop-container'
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
          >
            <Typography
              variant='body1'
              style={{ textAlign: 'center', cursor: 'pointer' }}
              color='primary'
            >
              {t('Drag drop your PDF file(s) here')}
            </Typography>
          </div>

          <Box
            padding={2}
            flexDirection='column'
            display='flex'
            justifyContent='center'
          >
            {errorFile
              ? errorFile.invalid
                ? dispatch(showErrorAlert(errorMessage))
                : ''
              : ''}
          </Box>
        </Box>
      </Box>
      {/* </CardContent>
    </Card> */}
    </Box>
  )
}
