import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography, Button } from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import {
  WelloAyurvedaMedication,
  WelloMedication,
} from 'models/welloMedication'
import React, { useState } from 'react'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { AyurvedaMedicationsList } from 'views/components/consultation/assessment/ayurvedaMedicationsList'
import { MedicationRequestTile } from 'views/components/consultation/assessment/medicationRequestTile'
import { AyurvedaMedicationsListIPD } from 'views/components/ipd/medicationListTableIPD'

interface MedicationTileProps {
  medication: WelloMedication[]
  patientDetails?: R4.IPatient
  appointmentId: string

  onDeleteClicked?: (medication: WelloMedication) => void
  onEditCLicked?: (medication: WelloMedication) => void

  displayTypeOfMedication?: boolean
  displayStartEndDates?: boolean
  orderAllowed?: boolean
}

export const AddedMedicationsListForHistory: React.FC<MedicationTileProps> = ({
  medication,
  patientDetails,
  appointmentId,
  onDeleteClicked,
  onEditCLicked,
  displayTypeOfMedication,
  displayStartEndDates,
  orderAllowed,
}: MedicationTileProps) => {
  console.log('--------medication-----------', medication)
  const ayurvedaMedication = medication.filter((e) => e.type === 'ayurveda')
  const allopathyMedication = medication.filter((e) => e.type === 'allopathy')

  const [openOrder, setOpenOrder] = useState(false)
  const [selectedMedication, setSelectedMedication] = useState<R4.ICoding>()
  const [selectedRxNorm, setSelectedRxNorm] = useState<string>()

  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
    >
      <Box py={1}>
        <Typography variant='h6'>Medication </Typography>
      </Box>{' '}
      {allopathyMedication.length > 0 && orderAllowed && (
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
          paddingBottom={0.5}
        >
          <Button
            variant='contained'
            size='small'
            color='primary'
            onClick={() => {
              setOpenOrder(true)
            }}
            id='editButton'
          >
            Order Medicine
          </Button>
        </Box>
      )}
      {allopathyMedication.length > 0 && (
        <Box display='flex' flexDirection='column'>
          {allopathyMedication.map((e, index: number) => (
            <Box display='flex' flexDirection='column' key={e.name}>
              <MedicationRequestTile
                medication={e}
                color={index % 2 === 0 ? '#EDF2FA' : kDialogueBackground}
                index={index}
              />
            </Box>
          ))}
        </Box>
      )}
      {ayurvedaMedication.length > 0 && displayTypeOfMedication && (
        <Box display='flex' flexDirection='row' py={1} px={0.5}>
          <Typography variant='subtitle2' color='initial'>
            {' '}
            Ayurvedic Medications
          </Typography>
        </Box>
      )}
      {ayurvedaMedication.length > 0 && (
        <Box display='flex' flexDirection='row'>
          <AyurvedaMedicationsList
            medicationsList={ayurvedaMedication}
            displayStartEndDates={displayStartEndDates}
            onDelete={(medicationRepeat: WelloMedication) => {
              if (onDeleteClicked) {
                onDeleteClicked(medicationRepeat)
              }
            }}
            onEditClicked={(medicationRepeat: WelloMedication) => {
              if (onEditCLicked) onEditCLicked(medicationRepeat)
            }}
            history={true}
            split={true}
          />
        </Box>
      )}{' '}
    </Box>
  )
}
