import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Checkbox,
  Typography,
  CircularProgress,
  IconButton,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import moment from 'moment'

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import {
  getCatalogDetailsFromIds,
  getCatalogEntries,
  getCatalogList,
  getCatalogNameFromId,
} from 'utils/fhirResoureHelpers/catalogHelper'
import {
  getIdOfSelectedDetails,
  getIdsOfSelectedDetails,
  isBetweenPast2Date,
  isPastDate,
  updateNutritionOrderDetails,
} from 'utils/fhirResoureHelpers/ipdDietHelper'
import { CatalogSelector } from 'views/components/common/catalog_entry_selector'

export interface DietSelectorProps {
  nutritionOrders: R4.INutritionOrder[]
  timeOfDay: string
  onSelectionChanged: (a: any, b: any) => void
  searchType: string
  dateString: string
  onNutritionIntakeUpdated?: (orders: R4.INutritionOrder[]) => void
  disableSelection?: boolean
  hideChecking?: boolean
  disableCheckingOpt?: boolean
  id: number
  repeat?: boolean
}

export const DietSelector: React.FC<DietSelectorProps> = ({
  nutritionOrders,
  timeOfDay,
  onSelectionChanged,
  searchType,
  dateString,
  onNutritionIntakeUpdated,
  disableSelection,
  hideChecking: disableChecking = false,
  disableCheckingOpt = false,
  id,
  repeat,
}: DietSelectorProps) => {
  const addDietSlice = useSelector(
    (state: RootState) => state.nutritionCatalogueValueSetSlice
  )
  const [loading, setLoading] = React.useState(false)

  const [editing, setEditing] = React.useState(false)

  const [isCompleted, setIsCompleted] = React.useState<boolean>(
    nutritionOrders.length > 0
      ? nutritionOrders[0].status === 'completed'
      : false
  )

  function updateNutritionOrder(nutritionOrder: R4.INutritionOrder) {
    setLoading(true)
    updateNutritionOrderDetails(nutritionOrder).then((e) => {
      if (e) {
        setIsCompleted(e)
        if (onNutritionIntakeUpdated)
          onNutritionIntakeUpdated([{ ...nutritionOrder, status: 'completed' }])
        setLoading(false)
      } else {
      }
    })
  }

  return (
    <Box width='100%'>
      {!editing &&
        getIdOfSelectedDetails(nutritionOrders) !== undefined &&
        !nutritionOrders![0].id!.includes('urn') && (
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            alignContent='center'
            justifyItems='start'
            justifyContent='flex-start'
            width='100%'
          >
            {loading && (
              <Box p={1}>
                {' '}
                <CircularProgress size={20} />
              </Box>
            )}
            <Box width='20%'>
              {!loading &&
                getIdOfSelectedDetails(nutritionOrders) !== undefined &&
                !disableChecking && (
                  <Checkbox
                    size='small'
                    checked={isCompleted}
                    id={`${timeOfDay}check${id}`}
                    disabled={
                      isCompleted ||
                      isBetweenPast2Date(
                        moment(dateString).format('DD-MM-YYYY')
                      ) ||
                      disableCheckingOpt
                    }
                    onClick={() => {
                      if (nutritionOrders.length > 0)
                        updateNutritionOrder(nutritionOrders[0])
                    }}
                  />
                )}
            </Box>
            <Box width='85%' flexDirection='row' display='flex' paddingLeft={1}>
              <Box
                display='flex'
                flexDirection='column'
                paddingLeft={0.5}
                justifyContent='center'
              >
                <Typography variant='subtitle2'>
                  {' '}
                  {getCatalogDetailsFromIds(
                    addDietSlice.catalogueEntries ?? [],
                    getIdsOfSelectedDetails(nutritionOrders) ?? ''
                  )}
                </Typography>
              </Box>

              {!disableSelection && (
                <IconButton
                  style={{ paddingLeft: 0.5 }}
                  aria-label='edit-opt'
                  id={`${timeOfDay}edit${id}`}
                  onClick={(e) => {
                    setEditing(true)
                  }}
                >
                  <Edit fontSize='small' id={`${timeOfDay}icon${id}`} />
                </IconButton>
              )}
            </Box>
          </Box>
        )}

      {repeat && (
        <Box width='100%'>
          {(editing ||
            getIdOfSelectedDetails(nutritionOrders) === undefined ||
            nutritionOrders![0].id!.includes('urn')) &&
            !disableSelection && (
              <CatalogSelector
                id={`${timeOfDay}-${id}`}
                isInEditMode={true}
                onRadioOptionsChanged={(e) => {
                  onSelectionChanged(e, timeOfDay)
                }}
                selectedValue={getCatalogList(
                  addDietSlice.catalogueEntries ?? [],
                  getIdsOfSelectedDetails(nutritionOrders) ?? ''
                )}
                searchType={searchType}
              />
            )}
        </Box>
      )}
      {repeat === undefined && (
        <Box width='100%'>
          {(editing ||
            getIdOfSelectedDetails(nutritionOrders) === undefined ||
            nutritionOrders![0].id!.includes('urn')) &&
            !disableSelection && (
              <CatalogSelector
                id={`${timeOfDay}-${id}`}
                isInEditMode={true}
                onRadioOptionsChanged={(e) => {
                  onSelectionChanged(e, timeOfDay)
                }}
                selectedValue={getCatalogList(
                  addDietSlice.catalogueEntries ?? [],
                  getIdsOfSelectedDetails(nutritionOrders) ?? ''
                )}
                searchType={searchType}
              />
            )}
        </Box>
      )}
    </Box>
  )
}
