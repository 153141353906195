import { R4 } from '@ahryman40k/ts-fhir-types'
import { Avatar, Box, Paper } from '@material-ui/core'
import React from 'react'
import {
  getAgeOfPatientData,
  getProfilePicPatient,
} from 'utils/fhirResourcesHelper'
import { getNameOfPatient } from 'wello-web-components'
import female from '../../../assets/icons/f.png'
import male from '../../../assets/icons/m.png'

interface Props {
  patient: R4.IPatient
}

export const ProfilePicWithAgeAndGender: React.FC<Props> = ({
  patient,
}: Props) => (
  <Box p={1}>
    <Avatar
      alt={getNameOfPatient(patient)}
      src={getProfilePicPatient(patient)}
      style={{ height: '80px', width: '80px' }}
    />
    <Box
      display='flex'
      justifyContent='center'
      style={{
        position: 'relative',
        top: -10,
        zIndex: 3,
      }}
      alignContent='center'
    >
      <Paper>
        <Box flexShrink={0} px={2} py={0.5} justifyItems='flex-start'>
          {patient.gender === R4.PatientGenderKind._male ? (
            <img
              src={male}
              alt='male'
              height='12'
              style={{ paddingRight: 4 }}
            />
          ) : (
            <img
              src={female}
              alt='female'
              height='12'
              style={{ paddingRight: 4 }}
            />
          )}
          {getAgeOfPatientData(patient)}
        </Box>
      </Paper>
    </Box>
  </Box>
)
