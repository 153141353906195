import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Webcam from 'react-webcam'
import { RootState } from 'redux/rootReducer'
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from 'react-image-crop'
import { WelloLoadingIndicator } from 'wello-web-components'
import 'react-image-crop/dist/ReactCrop.css'
import { blobToBase64, encodeBlobToBase4 } from 'services/fileServices'

interface Props {
  id: string
  open: boolean
  onBackClick: () => void
  onContinueClick: (image: string) => void
  cropAspectRatio?: number
}

export const WebCamCapturePhoto: React.FC<Props> = ({
  id,
  open,
  onBackClick: onClose,
  onContinueClick,
  cropAspectRatio,
}) => {
  const { t } = useTranslation()
  const unitActorSetupFormsSlice = useSelector(
    (state: RootState) => state.unitActorSetupFormsSlice
  )
  const [show, setShow] = useState(false)
  const [showClose, setShowClose] = useState(false)

  const handleShow = useCallback(() => setShow(true), [setShow])
  const handleClose = React.useCallback(() => {
    setShow(false)
    setShowClose(false)
    setImgSrc(undefined)
    onClose()
  }, [setShow, setShowClose, onClose])
  const webcamRef = React.useRef<Webcam & HTMLVideoElement>(null)

  const [imgSrc, setImgSrc] = React.useState<string>()

  const capture = React.useCallback(async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current?.getScreenshot()
      if (imageSrc !== null) {
        const { width, height } = await getImageDimensions(imageSrc)
        setCrop(centerAspectCrop(width, height, cropAspectRatio))
        setCompletedCrop(
          centerAspectCrop(width, height, cropAspectRatio) as PixelCrop
        )
        setImgSrc(imageSrc)
      }
    }
  }, [webcamRef, setImgSrc])

  const handleOnUserMedia = React.useCallback(
    () => setShowClose(true),
    [setShowClose]
  )
  const dispatch = useDispatch()

  const [imageRef, setImageRef] = useState()
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Take Photo</DialogTitle>
      <DialogContent dividers={true}>
        <Box display='flex' flexDirection='column' alignItems='center'>
          {!showClose && <WelloLoadingIndicator />}
          {!imgSrc && (
            <Webcam
              audio={false}
              ref={webcamRef}
              onUserMedia={handleOnUserMedia}
              onUserMediaError={handleOnUserMedia}
              screenshotFormat='image/jpeg'
              screenshotQuality={0.9}
            />
          )}

          {/*  {imgSrc && !showCropping && <img alt='profilePic' src={imgSrc} />} */}
          {imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={cropAspectRatio}
            >
              <img src={imgSrc} alt='original' />
            </ReactCrop>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {showClose && (
          <Box display='flex' flexDirection='row'>
            <Button variant='text' onClick={handleClose}>
              Close
            </Button>
            {imgSrc && (
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  setImgSrc(undefined)
                }}
                id={`${id}recapture_button`}
              >
                Recapture
              </Button>
            )}

            {imgSrc && (
              <Button
                variant='contained'
                color='primary'
                onClick={async () => {
                  const file = await getCroppedImage(
                    await getImageFromUrl(imgSrc),
                    completedCrop!
                  )

                  onContinueClick(file as string)
                  setImgSrc(undefined)
                  onClose()
                }}
                id={`${id}confirm_button`}
              >
                Confirm
              </Button>
            )}

            {!imgSrc && (
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  capture()
                }}
                id={`${id}capture_button`}
              >
                Capture
              </Button>
            )}
          </Box>
        )}
      </DialogActions>
    </Dialog>
  )
}

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  cropAspectRatio?: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width: mediaWidth - 300,
      },
      cropAspectRatio ?? 1,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

function getImageDimensions(file: any): any {
  return new Promise((resolved, rejected) => {
    const i = new Image()
    i.onload = function () {
      resolved({ width: i.width, height: i.height })
    }
    i.src = file
  })
}
const TO_RADIANS = Math.PI / 180
function getCroppedImage(
  image: HTMLImageElement,

  crop: PixelCrop
) {
  // creating the cropped image from the source image

  /*   const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d') */

  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = crop.width
  canvas.height = crop.height
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    throw new Error('No 2d context')
  }
  const pixelRatio = window.devicePixelRatio
  canvas.width = crop.width * pixelRatio
  canvas.height = crop.height * pixelRatio
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
  ctx.imageSmoothingQuality = 'high'

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  )

  return new Promise((resolve, reject) => {
    canvas.toBlob(async (blob) => {
      // returning an error
      if (!blob) {
        reject(new Error('Canvas is empty'))
        return
      }

      // blob = fileName;
      // creating a Object URL representing the Blob object given

      const croppedImageUrlBase64 = await blobToBase64(blob)

      resolve(croppedImageUrlBase64)
    }, 'image/jpeg')
  })
}

function getImageFromUrl(url: any): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', reject)
    image.src = url
  })
}
