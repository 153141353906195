import { R4 } from '@ahryman40k/ts-fhir-types'

export const Qualifications: R4.ICoding[] = [
  {
    code: 'mbbs',
    display: 'MBBS',
  },
  {
    code: 'md',
    display: 'MD',
  },
  {
    code: 'BDS',
    display: 'BDS – Bachelor of Dental Surgery',
  },
  {
    code: 'BAMS',
    display: 'Bachelor of Ayurvedic Medicine and Surgery',
  },
  {
    code: 'BNYS',
    display: 'Bachelor of Naturopathy and Yogic Sciences',
  },
  {
    code: 'BPT',
    display: 'Bachelor of Physiotherapy',
  },
  {
    code: 'BFD',
    display: 'Bachelor in Food Production',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const NurseQualifications: R4.ICoding[] = [
  {
    code: 'anm',
    display: 'A. N. M.',
  },
  {
    code: 'hv',
    display: 'HV',
  },
  {
    code: 'GNM',
    display: 'G. N. M.',
  },
  {
    code: 'BSC-N',
    display: 'B. Sc. (N)',
  },
  {
    code: 'POST-BSC-N',
    display: 'Post Basic B. Sc. (N)',
  },
  {
    code: 'MSC-N',
    display: 'M. Sc. (N)',
  },
  {
    code: 'MPhil-PhD-N',
    display: 'MPhil and PhD (N)',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const AyurvedaQualifications: R4.ICoding[] = [
  {
    code: 'BAMS',
    display: 'Bachelor of Ayurvedic Medicine and Surgery',
  },
  {
    code: 'BNYS',
    display: 'Bachelor of Naturopathy and Yogic Sciences',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
