/* eslint-disable import/no-named-as-default */
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  InputAdornment,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Package } from 'models/package'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetPackageState,
  updateCatalog,
} from 'redux/lab/Test/addTestCatalougeSlice'
import { RootState } from 'redux/rootReducer'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { getPackageBundleUpdate } from 'utils/labHelpers/uploadHelper'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { WelloTextField } from 'wello-web-components'
import { LabOfferingSelector } from '../orderCreationManagement/labTestSearch'
import { TagOfferingSelector } from './tagsSelector'

interface Props {
  open: boolean
  onClose: () => void
  onDiscard: () => void
  packageDetails?: Package
}

export const EditPackage: React.FC<Props> = ({
  open,
  onClose,
  onDiscard,
  packageDetails,
}) => {
  const { t } = useTranslation()

  const [hasErrors, setHasErrors] = React.useState<boolean>(false)
  const dispatch = useDispatch()
  const [showDisplay, setShowDisplay] = React.useState<boolean>(false)

  const [testError, setTestEroor] = React.useState<string>('')
  const [genderError, setGenderError] = React.useState<string>('')
  const [tagError, setTagError] = React.useState<string>('')

  const creatCatalogSlice = useSelector(
    (state: RootState) => state.createTestSlice
  )

  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '50%',
    },
  }))
  const classes = useStyles()

  const [packageData, SetPackageData] = React.useState<Package>({
    id: packageDetails?.id ?? '',
    packageName: packageDetails?.packageName ?? '',
    packagePrice: packageDetails?.packagePrice ?? 0,
    minAge: packageDetails?.minAge ?? 0,
    maxAge: packageDetails?.maxAge ?? 0,
    gender: {
      Male: packageDetails?.gender.Male ?? false,
      Female: packageDetails?.gender.Female ?? false,
      Others: packageDetails?.gender.Others ?? false,
    },
    cohortInfo: '',
    tags: packageDetails?.tags ?? [],
    listOfTest: packageDetails?.listOfTest ?? [],
    groupVersion: packageDetails?.groupVersion,
    package: packageDetails?.package,
    chargeItemVersion: packageDetails?.chargeItemVersion,
    status: 'active',
    testList: '',
  })

  function resetDetails() {
    setHasErrors(false)
    SetPackageData({
      id: getUniqueTempId(),
      packageName: '',
      packagePrice: 0,
      minAge: 0,
      maxAge: 0,
      gender: {
        Male: false,
        Female: false,
        Others: false,
      },
      cohortInfo: '',
      tags: [],
      listOfTest: [],
      status: 'active',
      testList: '',
    })
  }

  function handleNameChange(name: string) {
    SetPackageData({ ...packageData, packageName: name })
  }

  function handlePriceChange(price: number) {
    SetPackageData({ ...packageData, packagePrice: price })
  }
  function handleMinAgeChange(minAgeChange: number) {
    SetPackageData({ ...packageData, minAge: minAgeChange })
  }

  function handleMaxAgeChange(maxAgeChange: number) {
    SetPackageData({ ...packageData, maxAge: maxAgeChange })
  }

  function handleMaleChange(checked: boolean) {
    SetPackageData({
      ...packageData,
      gender: {
        Male: checked,
        Female: packageData.gender.Female,
        Others: packageData.gender.Others,
      },
    })
    if (checked) setGenderError('')
    if (
      !checked &&
      packageData.gender.Male === false &&
      packageData.gender.Female === false &&
      packageData.gender.Others === false
    )
      setGenderError('Please select at least one Gender')
  }

  function handleFemaleChange(checked: boolean) {
    SetPackageData({
      ...packageData,
      gender: {
        Male: packageData.gender.Male,
        Female: checked,
        Others: packageData.gender.Others,
      },
    })
    if (checked) setGenderError('')
    if (
      !checked &&
      packageData.gender.Male === false &&
      packageData.gender.Female === false &&
      packageData.gender.Others === false
    )
      setGenderError('Please select at least one Gender')
  }

  function handleOthersChange(checked: boolean) {
    SetPackageData({
      ...packageData,
      gender: {
        Male: packageData.gender.Male,
        Female: packageData.gender.Female,
        Others: checked,
      },
    })
    if (checked) setGenderError('')
    if (
      !checked &&
      packageData.gender.Male === false &&
      packageData.gender.Female === false &&
      packageData.gender.Others === false
    )
      setGenderError('Please select at least one Gender')
  }

  function handleSubmit() {
    let hasErrorsInData: boolean = false
    let hasPriceErrorInData: boolean = false
    let hasMinErrorData: boolean = false
    let hasMaxErrorData: boolean = false
    let tagDataError: boolean = false
    let genderErrorData: boolean = false
    let testsError: boolean = false
    if (
      packageData.packageName.length === 0 ||
      !packageData.packageName.trim()
    ) {
      hasErrorsInData = true
    } else {
      hasErrorsInData = false
    }

    if (packageData.tags.length === 0) {
      tagDataError = true
      setTagError('Please select at least one Tag')
    } else {
      tagDataError = false
      setTagError('')
    }
    if (packageData.listOfTest.length < 2) {
      testsError = true
      setTestEroor('Please select at least 2 Tests')
    } else {
      testsError = false
      setTestEroor('')
    }

    if (
      packageData.gender.Male === false &&
      packageData.gender.Female === false &&
      packageData.gender.Others === false
    ) {
      setGenderError('Please select at least one Gender')
      genderErrorData = true
    } else {
      genderErrorData = false
      setGenderError('')
    }
    if (
      packageData.minAge < 0 ||
      packageData.minAge > 120 ||
      Number.isNaN(packageData.minAge)
    ) {
      hasMinErrorData = true
    } else {
      hasMinErrorData = false
    }

    if (
      packageData.packagePrice < 0 ||
      packageData.packagePrice > 7000 ||
      Number.isNaN(packageData.packagePrice)
    ) {
      hasPriceErrorInData = true
    } else {
      hasPriceErrorInData = false
    }

    if (
      packageData.maxAge < 0 ||
      packageData.maxAge > 120 ||
      Number.isNaN(packageData.maxAge) ||
      packageData.maxAge <= packageData.minAge
    ) {
      hasMaxErrorData = true
    } else {
      hasMaxErrorData = false
    }
    if (
      !hasErrorsInData &&
      !hasMaxErrorData &&
      !hasMinErrorData &&
      !hasPriceErrorInData &&
      !tagDataError &&
      !testsError &&
      !genderErrorData
    ) {
      const bundleData = getPackageBundleUpdate(packageData)

      if (bundleData) {
        dispatch(updateCatalog(bundleData, '', 'Package'))
      }
    }
    if (hasErrorsInData) setHasErrors(hasErrorsInData)
  }

  useEffect(() => {
    if (packageDetails) {
      SetPackageData({
        id: packageDetails.id || '',
        packageName: packageDetails.packageName || '',
        packagePrice: packageDetails?.packagePrice ?? 0,
        minAge: packageDetails?.minAge ?? 0,
        maxAge: packageDetails?.maxAge ?? 0,
        gender: {
          Male: packageDetails?.gender.Male ?? false,
          Female: packageDetails?.gender.Female ?? false,
          Others: packageDetails?.gender.Others ?? false,
        },
        tags: packageDetails?.tags ?? [],
        cohortInfo: '',
        listOfTest: packageDetails?.listOfTest ?? [],
        groupVersion: packageDetails?.groupVersion,
        chargeItemVersion: packageDetails?.chargeItemVersion,
        package: packageDetails?.package,
        status: 'active',
        testList: '',
      })
    }
    if (creatCatalogSlice.updateSuccessful) {
      dispatch(resetPackageState())
      resetDetails()
      onDiscard()
    }
    return () => {}
  }, [creatCatalogSlice.updateSuccessful, onDiscard, dispatch])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetDetails()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'> Edit Package</DialogTitle>
      <DialogContent>
        <Box flexDirection='column' display='flex'>
          <Box display='flex' flexDirection='column' px={3}>
            {creatCatalogSlice.error && (
              <Box display='flex'>
                <Alert severity='error'>
                  {creatCatalogSlice.errorMessage ??
                    'Error while adding package. Please try later'}
                </Alert>
              </Box>
            )}
            {creatCatalogSlice.additionSuccessful && (
              <Box display='flex'>
                <Alert severity='success'>
                  {creatCatalogSlice.errorMessage ??
                    'Package Added Successfully'}
                </Alert>
              </Box>
            )}
            <Box
              display='flex'
              flexDirection='row'
              alignItems='flex-start'
              width='100%'
            >
              <Box px={1} py={1} width='50%' flexDirection='column'>
                <WelloFormItemLabel title='Package Name' />
                <WelloTextFieldWithoutTitle
                  //   title='Package Name'
                  textProps={{
                    id: `actor_email_unit`,
                    value: packageData.packageName,
                    disabled: true,
                    placeholder: 'Package Name',
                    error:
                      hasErrors ||
                      packageData.packageName.length === 0 ||
                      !packageData.packageName.trim(),
                    type: 'text',
                    // helperText:
                    //   hasErrors ||
                    //   packageData.packageName.length === 0 ||
                    //   !packageData.packageName.trim()
                    //     ? 'Package Name is required'
                    //     : '',
                    onChange: (changeName) => {
                      handleNameChange(changeName.target.value)
                    },
                  }}
                />
              </Box>

              <Box px={1} py={1} width='20%' flexDirection='column'>
                <WelloFormItemLabel title='Package Price' />
                <WelloTextFieldWithoutTitle
                  //   title='Package Cost'
                  textProps={{
                    id: `actor_email_unit`,
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Box>
                            {' '}
                            <Typography variant='subtitle2' align='center'>
                              {' '}
                              ₹{' '}
                            </Typography>
                          </Box>
                        </InputAdornment>
                      ),
                    },
                    inputProps: {
                      max: 7000,
                      step: 0.1,
                    },
                    value: packageData.packagePrice,
                    placeholder: 'Price',
                    error:
                      hasErrors ||
                      packageData.packagePrice <= 0 ||
                      packageData.packagePrice > 7000 ||
                      Number.isNaN(packageData.packagePrice),
                    type: 'number',
                    // helperText:
                    //   hasErrors ||
                    //   packageData.packagePrice <= 0 ||
                    //   packageData.packagePrice > 7000 ||
                    //   Number.isNaN(packageData.packagePrice)
                    //     ? 'Price should be between 1 to 7000'
                    //     : '',
                    //   value: tests.testPrice,
                    onChange: (changePrice) => {
                      handlePriceChange(
                        parseFloat(
                          parseFloat(changePrice.target.value).toFixed(2)
                        )
                      )
                    },
                  }}
                />
              </Box>

              <Box px={1} py={1} width='15%' flexDirection='column'>
                <WelloFormItemLabel title='Minimum Age' />
                <WelloTextFieldWithoutTitle
                  //   title='Minimum Age'
                  textProps={{
                    id: `actor_email_unit`,
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Box
                            display='flex'
                            flexDirection='row'
                            alignItems='flex-start'
                          >
                            {' '}
                            <Typography variant='subtitle2' align='center'>
                              {' '}
                              Yrs
                            </Typography>
                          </Box>
                        </InputAdornment>
                      ),
                    },
                    inputProps: {
                      max: 120,
                    },
                    value: packageData.minAge,
                    placeholder: 'Minimum Age',
                    error:
                      hasErrors ||
                      packageData.minAge < 0 ||
                      packageData.minAge > 120 ||
                      Number.isNaN(packageData.minAge),
                    type: 'number',
                    // helperText:
                    //   hasErrors ||
                    //   packageData.minAge < 0 ||
                    //   packageData.minAge > 120 ||
                    //   Number.isNaN(packageData.minAge)
                    //     ? 'Minimum Age Should be between 0 to 120'
                    //     : '',
                    //   value: tests.testPrice,
                    onChange: (changePrice) => {
                      handleMinAgeChange(parseInt(changePrice.target.value, 10))
                    },
                  }}
                />
              </Box>

              <Box px={1} py={1} width='15%' flex-flexDirection='column'>
                <WelloFormItemLabel title='Maximum Age' />
                <WelloTextFieldWithoutTitle
                  //   title='Maximum Age'
                  textProps={{
                    id: `actor_email_unit`,
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Box
                            display='flex'
                            flexDirection='row'
                            alignItems='flex-start'
                          >
                            {' '}
                            <Typography variant='subtitle2' align='center'>
                              {' '}
                              Yrs
                            </Typography>
                          </Box>
                        </InputAdornment>
                      ),
                    },
                    inputProps: {
                      max: 120,
                    },
                    value: packageData.maxAge,
                    placeholder: 'Maximum Age',
                    error:
                      hasErrors ||
                      packageData.maxAge < 0 ||
                      packageData.maxAge > 120 ||
                      packageData.maxAge <= packageData.minAge ||
                      Number.isNaN(packageData.maxAge),
                    type: 'number',
                    // helperText:
                    //   hasErrors ||
                    //   packageData.maxAge < 0 ||
                    //   packageData.maxAge > 120 ||
                    //   Number.isNaN(packageData.maxAge)
                    //     ? 'Maximum Age should between between 1 to 120'
                    //     : packageData.maxAge < packageData.minAge
                    //     ? 'Maximum Age should be greater than minimum age'
                    //     : '',
                    //   value: tests.testPrice,
                    onChange: (changePrice) => {
                      handleMaxAgeChange(parseInt(changePrice.target.value, 10))
                    },
                  }}
                />
              </Box>
            </Box>

            <Box
              display='flex'
              flexDirection='row'
              alignItems='flex-start'
              width='100%'
            >
              <Box px={1} width='50%'>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                  >
                    <WelloFormItemLabel title='Tag' />
                  </Box>
                  <Box display='flex' flexDirection='row' width='100%'>
                    <TagOfferingSelector
                      defaultData='test'
                      onSelectionChanges={(e) => {
                        SetPackageData({
                          ...packageData,
                          tags: e,
                        })
                        setTagError('')
                      }}
                      preSelectedOrdersRoles={packageData.tags}
                      disabled={false}
                    />
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    paddingX={2}
                  >
                    <Typography
                      color='secondary'
                      style={{ fontSize: 12, fontWeight: 400 }}
                    >
                      {tagError}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                width='50%'
                paddingTop={1.5}
              >
                <Box display='flex' flexDirection='row' paddingX={1.3}>
                  <Typography variant='subtitle2'>Gender</Typography>
                </Box>

                <Box
                  display='flex'
                  flexDirection='row'
                  alignItems='flex-start'
                  width='100%'
                >
                  <Box py={2} px={1.5}>
                    <FormControlLabel
                      label='Male'
                      style={{ padding: '0px', fontSize: 400, fontWeight: 400 }}
                      control={
                        <Checkbox
                          checked={packageData.gender.Male}
                          onChange={(e) => handleMaleChange(e.target.checked)}
                          name='male'
                          color='primary'
                          size='small'
                          style={{ padding: '0px', paddingLeft: '8px' }}
                        />
                      }
                    />
                  </Box>

                  <Box py={2} px={1.5}>
                    <FormControlLabel
                      label='Female'
                      style={{ padding: '0px', fontSize: 400, fontWeight: 400 }}
                      control={
                        <Checkbox
                          checked={packageData.gender.Female}
                          onChange={(e) => handleFemaleChange(e.target.checked)}
                          name='female'
                          color='primary'
                          size='small'
                          style={{ padding: '0px', paddingLeft: '8px' }}
                        />
                      }
                    />
                  </Box>

                  <Box py={2} px={1.5}>
                    <FormControlLabel
                      label='Others'
                      style={{ padding: '0px', fontSize: 400, fontWeight: 400 }}
                      control={
                        <Checkbox
                          checked={packageData.gender.Others}
                          onChange={(e) => handleOthersChange(e.target.checked)}
                          name='others'
                          color='primary'
                          size='small'
                          style={{ padding: '0px', paddingLeft: '8px' }}
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  paddingX={2}
                >
                  <Typography
                    color='secondary'
                    style={{ fontSize: 12, fontWeight: 400 }}
                  >
                    {genderError}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              display='flex'
              flexDirection='row'
              alignItems='flex-start'
              width='100%'
            >
              <Box px={1} paddingBottom={2} width='100%'>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                  >
                    <WelloFormItemLabel title='Select Tests' />
                  </Box>
                  <Box display='flex' flexDirection='row' width='100%'>
                    <LabOfferingSelector
                      onSelectionChanges={(e) => {
                        SetPackageData({
                          ...packageData,
                          listOfTest: e,
                        })
                        setTestEroor('')
                      }}
                      preSelectedOrdersRoles={packageData.listOfTest}
                      disabled={false}
                    />
                  </Box>
                  <Box display='flex' flexDirection='row' width='100%'>
                    <Typography
                      color='secondary'
                      style={{ fontSize: 12, fontWeight: 400 }}
                    >
                      {testError}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Divider style={{ border: 1 }} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          paddingRight={2.8}
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
        >
          {creatCatalogSlice.adding && <CircularProgress />}
          <Button
            //   color='primary'
            onClick={() => {
              onClose()
            }}
            variant='outlined'
            disabled={creatCatalogSlice.adding}
            disableElevation
            size='small'
          >
            Cancel
          </Button>
          <Box paddingLeft={0.5}>
            <Button
              variant='contained'
              size='small'
              color='primary'
              onClick={() => {
                handleSubmit()
              }}
            >
              Update Package
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
