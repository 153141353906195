import { R4 } from '@ahryman40k/ts-fhir-types'
import { Calendar } from '@mantine/dates'
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Typography,
} from '@material-ui/core'
import { kPrimaryLight, kPrimaryMain } from 'configs/styles/muiThemes'
import { DoctorBase } from 'lib/openApi'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestForSlots } from 'redux/appointments/slotSelectionHandler/practitionerSlotSearchSlice'
import { getPractitionerRoleObject } from 'redux/practitioner/practitionerSearchHandler/practitionerSearchSlice'
import { RootState } from 'redux/rootReducer'
import { isCurrentUserDoctor } from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import { availableAppointmentTypes, dateOptionsList } from 'utils/constants'
import { getCompleteDateString, getTomorrowDate, isToday } from 'utils/dateUtil'
import CustomizedDividers from 'views/components/toggle_selector'
import { WelloLoadingIndicator } from 'wello-web-components'
import InfiniteScroll from 'react-infinite-scroll-component'
import { SlotDisplay } from '../primitive/slotDisplay'
import { ProviderSelector } from '../selectors/providers/providerSelector'
import { SpecializationSelector } from '../selectors/providers/specializationSelector'

interface Props {
  onSlotSelected?: (
    appointmentType: string,
    selectedSlot?: FhirSlotDetail,
    question?: R4.IQuestionnaire
  ) => void
  preSelectedSlot?: FhirSlotDetail
  preSelectedDoctor?: PractitionerWithRole
  preSelectedSlotStartTime?: Date
  allowWalkIn?: boolean
  onWalkInAppointmentClicked?: () => void
  fhirAppointmentDetail?: FhirAppointmentDetail
  followUp?: boolean
}

export const ProviderSlotSelectorNew: React.FC<Props> = ({
  onSlotSelected,
  preSelectedSlot,
  preSelectedDoctor,
  preSelectedSlotStartTime,
  allowWalkIn = false,
  onWalkInAppointmentClicked,
  fhirAppointmentDetail,
  followUp,
}: Props) => {
  const practitionerSlotSearchSlice = useSelector(
    (state: RootState) => state.practitionerSlotSearchSlice
  )
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [selectedDate, setSelectedDate] = useState(
    preSelectedSlot?.slot.start
      ? moment().format('YYYY-MM-DD') <
        moment(preSelectedSlot?.slot.start).format('YYYY-MM-DD')
        ? moment().toDate()
        : moment(preSelectedSlot?.slot.start).toDate()
      : preSelectedSlotStartTime ?? new Date()
  )
  const [selectedOptionDisplay, setSelectedOptionDisplay] = useState('doctors')
  //   const [selectedPartOfDay, setSelectedPartOfDay] = useState<string>('any')
  const selectedPartOfDay = useRef('any')
  const [selectedDatePart, setSelectedDatePart] = useState<string>('asap')
  const selectedAppointmentType = useRef(availableAppointmentTypes[0])
  const selectedSpecializations = useRef<R4.ICoding[]>()
  const selectedDoctors = useRef<PractitionerWithRole[] | undefined>(
    preSelectedSlot?.practitionerRole
      ? [getPractitionerRoleObject(preSelectedSlot?.practitionerRole)]
      : preSelectedDoctor
      ? [preSelectedDoctor]
      : []
  )
  const [selectedSlot, setSelectedSlot] =
    useState<FhirSlotDetail | undefined>(preSelectedSlot)

  let preSelectedDate = getPreConfiguredDate(selectedDate)

  function getPreConfiguredDate(date: Date): any {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)

    const currentDate = new Date()
    currentDate.setHours(0)
    currentDate.setMinutes(0)
    currentDate.setSeconds(0)
    currentDate.setMilliseconds(0)

    const daysDiff: number = moment(date).diff(moment(currentDate), 'day')
    if (daysDiff === 0) {
      return dateOptionsList[0]
    }
    if (daysDiff === 1) {
      return dateOptionsList[1]
    }
    return dateOptionsList[2]
  }
  useEffect(() => {
    requestSearchForSlots()
  }, [selectedDate])

  function onDateChanged(newSelectedDate: Date) {
    preSelectedDate = getPreConfiguredDate(newSelectedDate)

    setSelectedDate(newSelectedDate)
  }

  function onDoctorsSelectionChanged(selectedValues: PractitionerWithRole[]) {
    selectedDoctors.current = selectedValues
    requestSearchForSlots()
  }

  function onSpecializationChanged(selectedValues: R4.ICoding[]) {
    selectedSpecializations.current = selectedValues
    requestSearchForSlots()
  }

  function requestSearchForSlots() {
    setSelectedSlot(undefined)
    if (onSlotSelected) {
      onSlotSelected('', undefined)
    }
    dispatch(
      requestForSlots(
        selectedDoctors.current ?? [],
        selectedDate,
        selectedPartOfDay.current,
        selectedAppointmentType.current.code,
        selectedSpecializations.current ?? []
      )
    )
  }

  return (
    <Box
      px={2}
      width='100%'
      display='flex'
      flexDirection='column'
      overflow='auto'
      style={{ overflow: 'auto', height: '78vh' }}
    >
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        justifyContent='space-between'
      >
        <Box display='flex' flexDirection='column'>
          <CustomizedDividers
            size='small'
            menuLabel={t('labelCommon:when')}
            options={[
              {
                displayText: t('labelCommon:asap'),
                value: 'asap',
              },
              {
                displayText: t('labelCommon:tomorrow'),
                value: 'tomo',
              },
              {
                displayText: t('labelCommon:custom'),
                value: 'custom',
              },
            ]}
            onSelectionChanged={(selectedValue) => {
              setSelectedDatePart(selectedValue)
              if (selectedValue === 'asap') {
                setSelectedDate(new Date())
              }
              if (selectedValue === 'tomo') {
                setSelectedDate(getTomorrowDate())
              }
            }}
            preSelectedValue={selectedDatePart}
          />
        </Box>

        <Box display='flex' flexDirection='column'>
          {' '}
          <CustomizedDividers
            size='small'
            menuLabel={t('labelCommon:appointment_type')}
            options={availableAppointmentTypes.map((e) => ({
              displayText: e.definition,
              value: e.code,
            }))}
            onSelectionChanged={(selectedValue) => {
              if (selectedValue !== null) {
                // setSelectedDatePart(selectedValue)
                const selectedVal = availableAppointmentTypes.find(
                  (e) => e.code === selectedValue
                )
                if (selectedVal !== undefined) {
                  selectedAppointmentType.current = selectedVal
                }
                requestSearchForSlots()
              }
            }}
            preSelectedValue={selectedAppointmentType.current.code}
          />
        </Box>

        <Box display='flex' flexDirection='column'>
          {' '}
          <CustomizedDividers
            size='small'
            menuLabel={t('labelCommon:time_slot')}
            options={[
              {
                displayText: t('labelCommon:any'),
                value: 'any',
              },
              {
                displayText: t('labelCommon:morning'),
                value: 'morning',
              },
              {
                displayText: t('labelCommon:afternoon'),
                value: 'afternoon',
              },
              {
                displayText: t('labelCommon:evening'),
                value: 'evening',
              },
            ]}
            onSelectionChanged={(selectedValue) => {
              selectedPartOfDay.current = selectedValue
              requestSearchForSlots()
            }}
            preSelectedValue={selectedPartOfDay.current}
          />
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        justifyContent='space-between'
        paddingTop={2}
      >
        <Divider
          style={{
            width: '100%',
          }}
        />
      </Box>

      <Box
        width='100%'
        display='flex'
        flexDirection='row'
        overflow='auto'
        style={{ overflow: 'auto', height: '78vh' }}
      >
        <Box
          width={1 / 3.5}
          style={{ overflow: 'auto', height: '100%', maxWidth: '280px' }}
          flexGrow
        >
          <SimpleBar
            style={{
              height: '100%',

              padding: '4px',
            }}
          >
            <Box
              paddingRight={1}
              paddingY={0}
              flexDirection='column'
              display='div'
              height='100%'
            >
              {selectedDatePart === 'custom' && (
                <Box width='100%'>
                  <Calendar
                    size='xs'
                    style={{
                      aspectRatio: '1/1',
                    }}
                    id='customDate'
                    value={selectedDate}
                    disableOutsideEvents
                    onChange={setSelectedDate}
                    minDate={new Date()}
                    dayStyle={(date, modifier) => {
                      if (isToday(date)) {
                        return {
                          aspectRatio: '1',
                          backgroundColor: kPrimaryLight,
                          borderRadius: '50%',
                        }
                      }
                      return { aspectRatio: '1' }
                    }}
                    styles={{
                      cell: {
                        aspectRatio: '1',
                      },
                      day: {
                        aspectRatio: '1 !important',
                      },
                      weekday: {
                        color: kPrimaryMain,
                      },
                      selected: {
                        borderRadius: '50%',
                      },

                      //   weekend: {
                      //     color: '',
                      //   },
                      label: {
                        fontWeight: 'bold',
                        color: kPrimaryMain,
                      },
                    }}
                  />
                </Box>
              )}

              <Box paddingY={1} hidden={isCurrentUserDoctor()}>
                <Box
                  display='flex'
                  flexDirection='column'
                  alignContent='center'
                  alignItems='center'
                  justifyContent='center'
                >
                  {' '}
                  <CustomizedDividers
                    size='small'
                    options={[
                      {
                        displayText: t('labelCommon:doctors'),
                        value: 'doctors',
                      },
                      {
                        displayText: t('labelCommon:specializations'),
                        value: 'specialization',
                      },
                    ]}
                    onSelectionChanged={(selectedValue) => {
                      setSelectedOptionDisplay(selectedValue)
                    }}
                    preSelectedValue={selectedOptionDisplay}
                  />
                </Box>
              </Box>
              {selectedOptionDisplay === 'doctors' && (
                <Box hidden={isCurrentUserDoctor()}>
                  <ProviderSelector
                    showTitle={false}
                    preSelectAll={isCurrentUserDoctor()}
                    preSelectedPractitionerRoles={selectedDoctors.current}
                    onProviderSelectionChanged={(
                      selectedValues: DoctorBase[]
                    ) => {}}
                    onPractitionerSelectionChanged={(
                      selectedPractitioners: PractitionerWithRole[]
                    ) => {
                      onDoctorsSelectionChanged(selectedPractitioners)
                    }}
                    doctorListId='appointmentDoctor'
                  />
                </Box>
              )}
              {selectedOptionDisplay === 'specialization' && (
                <Box paddingTop={0} hidden={isCurrentUserDoctor()}>
                  <SpecializationSelector
                    onSpecializationsSelectionChanged={(
                      userSelectedSpecializations: R4.ICoding[]
                    ) => {
                      onSpecializationChanged(userSelectedSpecializations)
                    }}
                    specialty='appointmenteSpecialty'
                  />
                </Box>
              )}
            </Box>
          </SimpleBar>
        </Box>
        <Box
          flexDirection='column'
          display='flex'
          bgcolor='background.default'
          width='1px'
          style={{
            height: '100%',
            maxHeight: '100%',
          }}
        >
          <Box bgcolor='grey.500' height='100%' width='1px' />
        </Box>
        <Box
          flexGrow={1}
          flexDirection='column'
          style={{ overflow: 'auto', maxHeight: '100%' }}
        >
          <Box
            display='flex'
            alignContent='center'
            justifyContent='center'
            overflow='auto'
            height='100%'
          >
            {practitionerSlotSearchSlice.searching && (
              <WelloLoadingIndicator message='Searching' />
            )}

            {practitionerSlotSearchSlice.noSelection && (
              <Typography
                variant='h6'
                align='center'
                style={{ alignSelf: 'center' }}
              >
                Select Doctors to Begin
              </Typography>
            )}

            {practitionerSlotSearchSlice.noResultsAvailable && (
              <Typography
                variant='h6'
                align='center'
                style={{ alignSelf: 'center' }}
              >
                No slots available
              </Typography>
            )}

            {practitionerSlotSearchSlice.noResultsAvailable && allowWalkIn && (
              <Button
                variant='contained'
                color='primary'
                onClick={onWalkInAppointmentClicked}
              >
                Book Walk-In Appointment
              </Button>
            )}

            {practitionerSlotSearchSlice.resultsAvailable && (
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height='100%'
                overflow='auto'
              >
                <Box display='flex' overflow='auto' height='100%' flexGrow={2}>
                  <List
                    id='scrollableDiv'
                    subheader={<li style={{ padding: 0, width: '100%' }} />}
                    style={{
                      padding: 0,
                      width: '100%',
                      overflow: 'auto',
                      height: '100%',
                    }}
                  >
                    <InfiniteScroll
                      dataLength={practitionerSlotSearchSlice.recordsCount ?? 0}
                      next={() => {
                        dispatch(
                          requestForSlots(
                            selectedDoctors.current ?? [],
                            selectedDate,
                            selectedPartOfDay.current,
                            selectedAppointmentType.current.code,
                            selectedSpecializations.current ?? [],
                            practitionerSlotSearchSlice.pageState,
                            practitionerSlotSearchSlice.slotList?.length,
                            practitionerSlotSearchSlice.recordsCount,
                            practitionerSlotSearchSlice.slotList
                          )
                        )
                      }}
                      hasMore={
                        practitionerSlotSearchSlice.pageState !== undefined
                      }
                      loader=''
                      endMessage={
                        practitionerSlotSearchSlice.searching ? (
                          <p />
                        ) : (
                          <p style={{ textAlign: 'center' }} />
                        )
                      }
                      scrollableTarget='scrollableDiv'
                    >
                      {practitionerSlotSearchSlice.slotList?.map(
                        (dayWiseSlot, index: number) => (
                          <Box
                            p={1}
                            id='slots'
                            key={`key_day_wise_slots_${dayWiseSlot.date}`}
                          >
                            <Box paddingX={0.5}>
                              <Typography
                                variant='subtitle2'
                                style={{ fontWeight: 'bolder' }}
                              >
                                {getCompleteDateString(dayWiseSlot.date ?? '')}
                              </Typography>
                            </Box>
                            <List
                              style={{
                                paddingTop: -4,
                                paddingBottom: -4,
                                marginTop: -4,
                                marginBottom: -4,
                              }}
                            >
                              {(dayWiseSlot.morningSlots?.length ?? -1) > 0 && (
                                <ListItem
                                  style={{ padding: 0, cursor: 'pointer' }}
                                >
                                  <List style={{ flexGrow: 1 }}>
                                    <ListItem
                                      style={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 4,
                                        marginTop: 0,
                                        marginBottom: 0,
                                        cursor: 'pointer',
                                      }}
                                      id={`slot${index}`}
                                    >
                                      <Typography
                                        variant='subtitle1'
                                        color='primary'
                                        style={{ fontWeight: 'bolder' }}
                                      >
                                        {t('labelCommon:morning')}
                                      </Typography>
                                    </ListItem>
                                    {dayWiseSlot.morningSlots?.map(
                                      (slot: FhirSlotDetail) => (
                                        <SlotDisplay
                                          key={`key_morning_slot${slot.slot.id}`}
                                          slot={slot}
                                          isSelected={
                                            selectedSlot?.slot.id ===
                                            slot.slot.id
                                          }
                                          onSlotSelected={() => {
                                            if (onSlotSelected) {
                                              console.log(
                                                practitionerSlotSearchSlice.question
                                              )
                                              onSlotSelected(
                                                selectedAppointmentType.current
                                                  .code,
                                                slot,
                                                practitionerSlotSearchSlice.question
                                              )
                                            }
                                            setSelectedSlot(slot)
                                          }}
                                          fhirAppointmentDetail={
                                            fhirAppointmentDetail
                                          }
                                          followUp={followUp}
                                          id={`slot${index}`}
                                        />
                                      )
                                    )}
                                  </List>
                                </ListItem>
                              )}
                              {(dayWiseSlot.afterNoonSlots?.length ?? -1) >
                                0 && (
                                <ListItem style={{ padding: 0 }}>
                                  <List style={{ flexGrow: 1 }}>
                                    <ListItem
                                      style={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 4,
                                        marginTop: 0,
                                        marginBottom: 0,
                                      }}
                                    >
                                      <Typography
                                        variant='subtitle1'
                                        color='primary'
                                        style={{ fontWeight: 'bolder' }}
                                      >
                                        {t('labelCommon:afternoon')}
                                      </Typography>
                                    </ListItem>
                                    {dayWiseSlot.afterNoonSlots?.map(
                                      (slot: FhirSlotDetail) => (
                                        <SlotDisplay
                                          key={`key_afternoon_slot${slot.slot.id}`}
                                          slot={slot}
                                          isSelected={
                                            selectedSlot?.slot.id ===
                                            slot.slot.id
                                          }
                                          onSlotSelected={() => {
                                            console.log(
                                              practitionerSlotSearchSlice.question
                                            )
                                            if (onSlotSelected) {
                                              onSlotSelected(
                                                selectedAppointmentType.current
                                                  .code,
                                                slot,
                                                practitionerSlotSearchSlice.question
                                              )
                                            }
                                            setSelectedSlot(slot)
                                          }}
                                          fhirAppointmentDetail={
                                            fhirAppointmentDetail
                                          }
                                          followUp={followUp}
                                          id={`slotAfternoon${index}`}
                                        />
                                      )
                                    )}
                                  </List>
                                </ListItem>
                              )}
                              {(dayWiseSlot.eveningSlots?.length ?? -1) > 0 && (
                                <ListItem style={{ padding: 0 }}>
                                  <List style={{ flexGrow: 1 }}>
                                    <ListItem
                                      style={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 4,
                                        marginTop: 0,
                                        marginBottom: 0,
                                      }}
                                    >
                                      <Typography
                                        variant='subtitle1'
                                        color='primary'
                                        style={{ fontWeight: 'bolder' }}
                                      >
                                        {t('labelCommon:evening')}
                                      </Typography>
                                    </ListItem>
                                    {dayWiseSlot.eveningSlots?.map(
                                      (slot: FhirSlotDetail) => (
                                        <SlotDisplay
                                          key={`key_evening_slot${slot.slot.id}`}
                                          slot={slot}
                                          isSelected={
                                            selectedSlot?.slot.id ===
                                            slot.slot.id
                                          }
                                          onSlotSelected={() => {
                                            console.log(
                                              practitionerSlotSearchSlice.question
                                            )
                                            if (onSlotSelected) {
                                              onSlotSelected(
                                                selectedAppointmentType.current
                                                  .code,
                                                slot,
                                                practitionerSlotSearchSlice.question
                                              )
                                            }
                                            setSelectedSlot(slot)
                                          }}
                                          fhirAppointmentDetail={
                                            fhirAppointmentDetail
                                          }
                                          followUp={followUp}
                                          id={`slotEvening${index}`}
                                        />
                                      )
                                    )}
                                  </List>
                                </ListItem>
                              )}
                            </List>
                          </Box>
                        )
                      )}
                    </InfiniteScroll>
                  </List>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
