import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { sleep } from 'utils/dateUtil'
import { getVitalsObservationObjectForIPD } from 'utils/fhirResoureHelpers/observationHelpers'
import { AssessmentAddStatus } from './assesmentAddStatus'
import { requestForAccessment } from './assessmentSearch/assessmentSearchSlice'

const initialState: AssessmentAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addAssesmentSliceForIpd = createSlice({
  name: 'addVitalsSliceForIpd',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<AssessmentAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetAddVitalsDetails(state, action: PayloadAction<AssessmentAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
    },
  },
})

export const addAssesment =
  (
    appointment: FhirActiveIPDDetailsForMedicalRole,
    summary: string,
    isAyurveda?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AssessmentAddStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(addAssesmentSliceForIpd.actions.updateAddVitalsStatus(addingState))

    try {
      const bundleObject: R4.IBundle = createBundleObjectForObservations(
        appointment,
        summary
      )

      const resource: any = {
        eventType: 'vitals',
        eventBody: bundleObject,
      }

      const enRolClient: EnrolCient = new EnrolCient()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        `ipd/${appointment.mainServiceRequest.id}/addIpdEventDetails`,
        resource
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (response.status === 'VitalsDetails Of Patient added') {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        await sleep(2000)
        // dispatch(
        //   requestVitalDetailsOfPatientIPD(
        //     appointment.patient,
        //     appointment.mainServiceRequest.id!
        //   )
        // )
        if (isAyurveda) {
          dispatch(showSuccessAlert('Diagnosis details Added Successfully'))
        } else {
          dispatch(
            showSuccessAlert('Diagnosis & Treatment details Added Successfully')
          )
        }

        dispatch(
          requestForAccessment(
            appointment.patient,
            appointment.mainServiceRequest.id!
          )
        )

        dispatch(
          addAssesmentSliceForIpd.actions.updateAddVitalsStatus(addingState)
        )
      } else {
        const errorCreatePersonState: AssessmentAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while creating patient',
        }
        dispatch(
          addAssesmentSliceForIpd.actions.updateAddVitalsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: AssessmentAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding vitals',
      }
      dispatch(
        addAssesmentSliceForIpd.actions.updateAddVitalsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetAddAssessmentState = () => (dispatch: AppDispatch) => {
  dispatch(addAssesmentSliceForIpd.actions.resetAddVitalsDetails(initialState))
}

function createBundleObjectForObservations(
  appointment: FhirActiveIPDDetailsForMedicalRole,
  summary: string
): R4.IBundle {
  const practitionerRoleDetail: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const encounterRef: R4.IReference = {
    reference: `Encounter/urn:uuid:1232323232324`,
    type: 'Encounter',
  }

  const observationObject: R4.IObservation = {
    ...getVitalsObservationObjectForIPD(appointment, encounterRef),
  }
  observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
  observationObject.effectiveDateTime = moment().format('YYYY-MM-DDTHH:mm:ssZ')
  observationObject.meta = {
    profile: [
      'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationBase',
    ],
  }
  observationObject.code = {
    text: '',
    coding: [
      {
        code: '10210-3',
        display: 'Physical findings',
        system: 'http://loinc.org',
      },
    ],
  }
  observationObject.status = R4.ObservationStatusKind._final

  const practitionerDetails: R4.IPractitioner =
    getCurrentUserPractitionerDetails()

  if (summary.length > 0) {
    observationObject.note = [
      {
        authorReference: {
          reference: `${practitionerDetails.resourceType}/${practitionerDetails.id}`,
        },
        text: summary,
        time: new Date().toISOString(),
      },
    ]
  }
  //   observationObject.valueCodeableConcept = {
  //     text: '',
  //     coding: [
  //       { code: '29544-4', system: 'http://loinc.org', display: summary },
  //     ],
  //   }
  observationObject.valueString = summary

  const entry: R4.IBundle_Entry = {
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: observationObject.resourceType,
    },
    resource: observationObject,
  }
  requestBundle.entry?.push(entry)

  return requestBundle
}

export default addAssesmentSliceForIpd.reducer
