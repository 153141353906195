import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { PatientDataWithAppointment } from 'models/patientDataWithAppointment'
import { VisaDetails } from 'models/patientProfile'
import moment from 'moment'
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { CcClinet } from 'services/Cclinet'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getUserDetails } from 'services/userDetailsService'
import { nationalityValueSet } from 'utils/constants'
import { removeExtensionFromExtensions } from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { AddVisaDetailsStatus } from './addVisaDetailsStatus'

const initialState: AddVisaDetailsStatus = {
  adding: false,
  addedSuccessfully: false,
  error: false,
  errorMessage: '',
  basic: undefined,
}

const addVisaDetailsSlice = createSlice({
  name: 'addVisaDetailsSlice',
  initialState,
  reducers: {
    updateStatus(state, action: PayloadAction<AddVisaDetailsStatus>) {
      state.adding = action.payload.adding
      state.addedSuccessfully = action.payload.addedSuccessfully
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.basic = action.payload.basic
    },
  },
})

export const addVisaDetails =
  (
    patient: R4.IPatient,
    visaDetailsData: VisaDetails,
    visaNo: string,
    visaStartDate: Date,
    visaExpiryDate: Date,
    purposeOfVisitFormc: R4.ICoding,
    visatype: R4.ICoding,
    placeOfissue: R4.ICoding,
    visaIssueCity: string,
    issueCountry: string,
    arrivedFrom: string,
    arrivedFromCountry: string,
    arrivalDate: Date,
    nextDestination: string,
    nextDestinationCountry: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: AddVisaDetailsStatus = {
      adding: true,
      addedSuccessfully: false,
      error: false,
    }
    dispatch(addVisaDetailsSlice.actions.updateStatus(addingCreatePersonState))

    try {
      logger.info('patientDetails')

      const oldPatient: R4.IPatient = {
        ...patient,
      }

      const requestBundle: R4.IBundle = {
        resourceType: 'Bundle',
        type: R4.BundleTypeKind._transaction,
        entry: [],
      }

      const filteredDataForVisaIssue = nationalityValueSet.filter(
        (d) => d.display! === visaDetailsData.visaIssueCountry!
      )

      const arrivalCoding = nationalityValueSet.filter(
        (d) => d.display! === arrivedFromCountry!
      )

      const nextCoding = nationalityValueSet.filter(
        (d) => d.display! === nextDestinationCountry!
      )

      const visaNumberExtension: R4.IExtension | undefined = {}
      oldPatient.extension =
        removeExtensionFromExtensions(
          oldPatient.extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-number-ext'
        ) ?? []

      visaNumberExtension.url =
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-number-ext'
      visaNumberExtension.valueString = `${visaDetailsData.visaNumber}`
      oldPatient.extension.push(visaNumberExtension)

      const visaTypeExtension: R4.IExtension = {}
      oldPatient.extension =
        removeExtensionFromExtensions(
          oldPatient.extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-type-ext'
        ) ?? []

      visaTypeExtension.url =
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-type-ext'
      visaTypeExtension.valueCodeableConcept = {
        coding: [visaDetailsData.visaTypeCoding!],
      }
      oldPatient.extension.push(visaTypeExtension)

      const visaCountryExtension: R4.IExtension = {}
      oldPatient.extension =
        removeExtensionFromExtensions(
          oldPatient.extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-issued-country-ext'
        ) ?? []

      visaCountryExtension.url =
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-issued-country-ext'
      visaCountryExtension.valueCodeableConcept = {
        coding: [visaDetailsData.visaIssueCountryCoding!],
        text: visaDetailsData.visaIssueCity,
      }
      oldPatient.extension.push(visaCountryExtension)

      const visaExpiryExtension: R4.IExtension = {}
      oldPatient.extension =
        removeExtensionFromExtensions(
          oldPatient.extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext'
        ) ?? []

      visaExpiryExtension.url =
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext'
      visaExpiryExtension.valuePeriod = {
        start: moment(visaDetailsData.visaIssueDate).toISOString(),
        end: moment(visaDetailsData.visaExpiryDate).toISOString(),
      }

      oldPatient.extension.push(visaExpiryExtension)

      const data: R4.IBasic = {
        resourceType: 'Basic',
        meta: {
          profile: [
            'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndBasicCForm',
          ],
        },
        code: {
          coding: [
            {
              code: 'transfer',
              display: 'Transfer',
              system:
                'http://terminology.hl7.org/CodeSystem/basic-resource-type',
            },
          ],
        },
        extension: [
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-number-ext',
            valueString: visaDetailsData.visaNumber,
          },
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext',
            valuePeriod: {
              start: moment(visaDetailsData.visaIssueDate).toISOString(),
              end: moment(visaDetailsData.visaExpiryDate).toISOString(),
            },
          },
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-type-ext',
            valueCodeableConcept: {
              coding: [visaDetailsData.visaTypeCoding!],
            },
          },
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-issued-country-ext',
            valueCodeableConcept: {
              text: visaDetailsData.visaIssueCity,
              coding:
                filteredDataForVisaIssue.length > 0
                  ? filteredDataForVisaIssue
                  : [],
            },
          },
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-arrived-from-ext',
            valueCodeableConcept: {
              text: arrivedFrom,
              coding: arrivalCoding.length > 0 ? arrivalCoding : [],
            },
          },
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-date-of-arrival-ext',
            valueDate: moment(arrivalDate).format('YYYY-MM-DD'),
          },
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-purpose-of-visit-ext',
            valueCodeableConcept: {
              coding: [purposeOfVisitFormc],
            },
          },
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-next-destination-ext',
            valueCodeableConcept: {
              text: nextDestination,
              coding: nextCoding.length > 0 ? nextCoding : [],
            },
          },
        ],
        subject: {
          type: patient.resourceType,
          id: patient.id,
          reference: `${patient.resourceType}/${patient.id}`,
        },
      }

      const entry: R4.IBundle_Entry = {
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: data.resourceType,
        },
        resource: data,
      }
      let vData: string = ''

      const versionData = sessionStorage.getItem('version')

      if (versionData !== null) vData = JSON.stringify(versionData)
      const patMatchString: string =
        versionData === null
          ? `W/${JSON.stringify(oldPatient?.meta?.versionId ?? ' ')}`
          : `W/${vData}`
      const etntry1: R4.IBundle_Entry = {
        fullUrl: `Patient/${oldPatient.id}`,
        request: {
          ifMatch: patMatchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `Patient/${oldPatient.id}`,
        },
        resource: oldPatient,
      }
      requestBundle.entry?.push(etntry1)
      requestBundle.entry?.push(entry)

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        requestBundle
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)

      if (relatedFhirDecodeRes._tag === 'Right') {
        const appointmentResponse: R4.IBundle = relatedFhirDecodeRes.right
        let appointment: string = ''

        if (appointmentResponse.entry) {
          for (let i = 0; i < appointmentResponse.entry.length; i++) {
            const entryResponse = appointmentResponse.entry[i].response
            if (entryResponse) {
              if (entryResponse.location) {
                if (entryResponse.location.includes('Basic')) {
                  appointment = entryResponse.location.replace(
                    '/_history/1',
                    ''
                  )
                }
              }
            }
          }
        }
        const successCreatePersonState: AddVisaDetailsStatus = {
          adding: false,
          addedSuccessfully: true,
          basic: appointment,
          error: true,
          errorMessage: '',
        }
        dispatch(showSuccessAlert('Visa details added successfully'))
        dispatch(
          addVisaDetailsSlice.actions.updateStatus(successCreatePersonState)
        )
      } else {
        const errorCreatePersonState: AddVisaDetailsStatus = {
          adding: false,
          addedSuccessfully: false,
          error: true,
          errorMessage: 'Error while adding visa details',
        }
        dispatch(
          addVisaDetailsSlice.actions.updateStatus(errorCreatePersonState)
        )
        return
      }
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorCreatePersonState: AddVisaDetailsStatus = {
        adding: false,
        addedSuccessfully: false,
        error: true,
        errorMessage: 'Error while uploading patient data',
      }
      dispatch(showErrorAlert('Error while adding visa details'))
      dispatch(addVisaDetailsSlice.actions.updateStatus(errorCreatePersonState))
    }
  }

export const resetUpdateUserState = () => (dispatch: AppDispatch) => {
  dispatch(addVisaDetailsSlice.actions.updateStatus(initialState))
}

export default addVisaDetailsSlice.reducer
