import { RouteParams } from 'App'
import React from 'react'
import { Redirect, Route, RouteComponentProps } from 'react-router-dom'
import { isAuthenticatedLL } from 'utils/authHelpers'
import { storeVendorPartId } from 'utils/routes_helper'
import { AuthRoutes } from 'views/navigation/navigationRoutes'
import SocketProvider from 'views/providers/socketDataProvider'
import { BrowserTimeChecker } from './browserTimeChexker'

interface Props {
  Component: React.FC<RouteComponentProps>
  path: string
  exact?: boolean
}

export const AuthRoute = ({
  Component,
  path,
  exact = false,
}: Props): JSX.Element => {
  const isAuthenticated: string | null = isAuthenticatedLL()
  const message = 'Please log in to view this page'

  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) => {
        if (
          path !== AuthRoutes.ViewIpdAppointment &&
          path !== AuthRoutes.ViewIpdAppointment &&
          path !== AuthRoutes.ViewIpdAppointment
        ) {
          sessionStorage.removeItem('appointmentReference')
        }
        const urlparams = props.match.params as RouteParams

        if (urlparams.vendor) {
          storeVendorPartId(urlparams.vendor)
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  message,
                  requestedPath: path,
                },
              }}
            />
          )
        }
        if (isAuthenticated != null) {
          if (isAuthenticated) {
            return (
              <BrowserTimeChecker
                child={
                  <SocketProvider url={`${process.env.REACT_APP_SOCKET_URL}`}>
                    <Component {...props} />
                  </SocketProvider>
                }
              />
            )
          }
        }

        let redirectpath: string = '/'
        if (path.split('/').length > 1) {
          redirectpath = `/${urlparams.vendor}/login`
        }
        return (
          <Redirect
            to={{
              pathname: 'login',
              state: {
                message,
                requestedPath: redirectpath,
              },
            }}
          />
        )
      }}
    />
  )
}
