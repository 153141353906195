import { R4 } from '@ahryman40k/ts-fhir-types'
import { Calendar } from '@mantine/dates'
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  List,
  ListSubheader,
  Paper,
  TextField,
  Typography,
  withStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Theme,
  Divider,
  makeStyles,
} from '@material-ui/core'
import { ToggleButtonGroup } from '@material-ui/lab'
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox'

import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import moment from 'moment'
import Chart from 'react-apexcharts'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'redux/rootReducer'

import SimpleBar from 'simplebar-react'
import CustomizedDividers from 'views/components/toggle_selector'
import '../../../App.css'

import { Filters } from 'models/filters'
import { CohortDisplayWithCount } from 'models/cohortDisplayWithCount'
import { CohortDisplayData } from 'models/cohortDisplayData'
import { searchingCohorts } from 'redux/cohorts/cohortSearchSlice'
import {
  getCohortDataForFilter,
  getCohortDataForFilterForSinglePercentileForLineGraphWithCount,
  getCohortDataForFilterForSinglePercentileForMultiGraph,
  getCohortFromCheckBox,
  getNameForSeries,
  getPercentileLabel,
  getStrataNameFromCheckBox,
  getXAxisLabel,
} from 'utils/cohort/cohortUtils'
import { CohortFilters } from 'views/components/LeftMenu/cohortFilters'
import { pink } from '@material-ui/core/colors'
import {
  analyticsOptions,
  carePlanCodings,
  datas,
  dietpathWays,
  newDataSet,
  newDiet,
  pre,
} from 'utils/constants'
import {
  getDataLabelSeries,
  getSankeyChartData,
  getToolTipEnabledSeries,
  getYAxisData,
} from 'utils/graphData_handle/graphDataHelper'
// import { SankeyChart } from './ipd/cohort/shankeyChart'
import {
  resetCarePlanCohortSearch,
  searchingCareplanCohorts,
} from 'redux/careplanCohort/careplanCohortSearchSlice'
import { WelloFilter } from 'models/welloFilter'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { getChartDataForCarePlanCohort } from 'utils/cohort/careplaCohortUtils'
import { WelloSelectFHIRCodingWithoutSort } from 'views/components/LeftMenu/WelloSelectFhirCodingWithoutSort'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { CohortChartReport } from './ipd/cohort/cohortChartReport'
import { CohortChartReportDiet } from './ipd/cohort/cohortChartDiet'

const custom: boolean = true
const FILTER = {
  target: ['IPD patients'],
}

const DietFilter = {
  target: ['IPD patients'],
  pre_obs: ['Normal BP at Admission'],
  treatment: ['CCB equivalent (Diet)'],
  post_obs: ['Elevated BP at Discharge', 'Normal BP at Discharge'],
}
const VISIBLE_STAGES = Object.keys(FILTER)
const dietStages = Object.keys(DietFilter)

export const CohortCarePlanAnalysis: React.FC = () => {
  const planSearchSlice = useSelector(
    (state: RootState) => state.carePlanCohortSearchSlice
  )
  const dispatch = useDispatch()

  const useStyles = makeStyles((theme: Theme) => ({}))
  const classes = useStyles()

  const [planId, setPlanId] = useState('T2DM-AP')
  const [duration, setDuration] = useState('14')
  const [mode, setMode] = useState('INT_OUT')
  const [appliedFilter, setAppliedFilter] = useState<string>()
  const [localFilters, setLocalFilter] = useState<WelloFilter>()
  const [typeData, setTypeData] = useState<R4.ICoding>()
  const [carePlanData, setCarePlanData] = useState<R4.ICoding>(
    carePlanCodings[0]
  )

  const styles = () => ({
    root: {
      '&$checked': {
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
    checked: {},
  })

  useEffect(() => {
    dispatch(searchingCareplanCohorts(planId, duration, mode))
  }, [dispatch])

  useEffect(() => {
    if (
      planSearchSlice.resultsAvailable &&
      planSearchSlice.filters &&
      planSearchSlice.targetCoding
    ) {
      setTypeData(undefined)
      updateFilter(
        planSearchSlice.filters,
        planSearchSlice.targetCoding,
        planSearchSlice.targetArray ?? []
      )
    }
  }, [planSearchSlice])

  function updateFilter(
    filter: WelloFilter,
    codingArray: R4.ICoding[],
    targetArray: string[]
  ) {
    if (codingArray.length > 1) {
      setTypeData(codingArray[0])
    } else {
      setTypeData(codingArray[0])
    }
    setLocalFilter(filter)
    if (targetArray.length > 1) {
      setAppliedFilter(targetArray[0])
    } else {
      setAppliedFilter(targetArray[0])
    }
  }

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        borderRadius: 8,
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box display='flex' flexDirection='column'>
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            paddingLeft={3.2}
            paddingRight={3}
          >
            <Box display='flex' flexDirection='row' width='25%'>
              <Box display='flex' flexDirection='column' width='100%'>
                <Box py={1} px={0.25} display='flex' flexDirection='row'>
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    Careplan
                  </Typography>
                </Box>
                <WelloSelectFHIRCodingWithoutSort
                  availableCodings={carePlanCodings}
                  preSelectedCoding={carePlanData}
                  onChanged={(type) => {
                    setLocalFilter(undefined)
                    setTypeData(undefined)
                    setAppliedFilter(undefined)
                    setPlanId(type.code ?? '')
                    setCarePlanData(type)
                    dispatch(resetCarePlanCohortSearch())
                    dispatch(
                      searchingCareplanCohorts(type.code ?? '', duration, mode)
                    )
                  }}
                  id='hab_10'
                  textFieldProps={{
                    id: 'hab_10',
                    size: 'small',
                    fullWidth: true,
                  }}
                />
              </Box>
            </Box>
            <Box
              display='flex'
              flexDirection='row'
              width='15%'
              paddingLeft={3}
              paddingRight={3}
              paddingTop={1}
            >
              <Box display='flex' flexDirection='column'>
                <Box py={1} px={0.25} display='flex' flexDirection='row'>
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    Duration
                  </Typography>
                </Box>
                <Box width='100%' paddingLeft={0.3}>
                  <FormControl>
                    <RadioGroup
                      id='add_unit_address_choice_rad_group'
                      row
                      aria-label='graphType'
                      value={duration}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setLocalFilter(undefined)
                        setTypeData(undefined)
                        setAppliedFilter(undefined)
                        setDuration((event.target as HTMLInputElement).value)
                        dispatch(
                          searchingCareplanCohorts(
                            planId,
                            (event.target as HTMLInputElement).value,
                            mode
                          )
                        )
                      }}
                    >
                      <Box>
                        <FormControlLabel
                          value='14'
                          control={<Radio color='primary' />}
                          label={
                            <Typography variant='subtitle2'>14 Days</Typography>
                          }
                        />
                        <FormControlLabel
                          value='90'
                          control={<Radio color='primary' />}
                          label={
                            <Typography variant='subtitle2'>90 Days</Typography>
                          }
                        />
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
            </Box>

            <Box
              display='flex'
              flexDirection='row'
              width='25%'
              paddingLeft={3}
              paddingRight={3}
              paddingTop={1}
            >
              <Box display='flex' flexDirection='column'>
                <Box py={1} px={0.25} display='flex' flexDirection='row'>
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    Mode
                  </Typography>
                </Box>
                <Box width='100%' paddingLeft={0.3}>
                  <FormControl>
                    {/* <FormLabel id='demo-row-radio-buttons-group-label'>
                      <Typography variant='subtitle1' color='textPrimary'>
                        Metric
                      </Typography>
                    </FormLabel> */}
                    <RadioGroup
                      id='add_unit_address_choice_rad_group'
                      row
                      aria-label='graphType'
                      value={mode}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setLocalFilter(undefined)
                        setTypeData(undefined)
                        setAppliedFilter(undefined)
                        setMode((event.target as HTMLInputElement).value)
                        dispatch(
                          searchingCareplanCohorts(
                            planId,
                            duration,
                            (event.target as HTMLInputElement).value
                          )
                        )
                      }}
                    >
                      <Box>
                        <FormControlLabel
                          value='INT_OUT'
                          control={<Radio color='primary' />}
                          label={
                            <Typography variant='subtitle2'>
                              Intervention and Outcome
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value='OUT'
                          control={<Radio color='primary' />}
                          label={
                            <Typography variant='subtitle2'>
                              {' '}
                              Outcome
                            </Typography>
                          }
                        />
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
            </Box>

            {planSearchSlice.resultsAvailable &&
              planSearchSlice.cohortOptions &&
              planSearchSlice.filters &&
              planSearchSlice.targetArray &&
              planSearchSlice.targetArray.length > 0 && (
                <Box
                  display='flex'
                  flexDirection='row'
                  width='30%'
                  paddingLeft={3}
                  paddingRight={3}
                  paddingTop={1}
                >
                  <Box display='flex' flexDirection='column'>
                    <Box py={1} px={0.25} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        Target
                      </Typography>
                    </Box>
                    {appliedFilter && (
                      <Box width='100%' paddingLeft={0.3}>
                        <FormControl>
                          <RadioGroup
                            id='add_unit_address_choice_rad_group'
                            row
                            aria-label='graphType'
                            value={appliedFilter}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setLocalFilter(undefined)
                              const fileterData: WelloFilter = {
                                target: [
                                  (event.target as HTMLInputElement).value,
                                ],
                                pre_obs: planSearchSlice.filters!.pre_obs,
                                intervention:
                                  planSearchSlice.filters!.intervention,
                                outcome: planSearchSlice.filters!.outcome,
                              }
                              setLocalFilter(fileterData)
                              setAppliedFilter(
                                (event.target as HTMLInputElement).value
                              )
                            }}
                          >
                            {planSearchSlice.targetArray.map((part, index) => (
                              <Box key={part}>
                                <FormControlLabel
                                  value={part}
                                  control={<Radio color='primary' />}
                                  label={
                                    <Typography variant='subtitle2'>
                                      {part}
                                    </Typography>
                                  }
                                />
                              </Box>
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
          </Box>

          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            maxWidth='88%'
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            <Box flexDirection='row' width='100%' display='flex' p={1}>
              {planSearchSlice.searching && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>
            {planSearchSlice.error && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='100%'
                height='100%'
                alignContent='center'
              >
                <Typography variant='subtitle1' color='error' align='center'>
                  Error while searching cohorts
                </Typography>
              </Box>
            )}

            {planSearchSlice.noResultsAvailable && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='100%'
                height='100%'
                alignContent='center'
              >
                <Typography variant='subtitle1' color='primary' align='center'>
                  No data available
                </Typography>
              </Box>
            )}

            {/* {planSearchSlice.resultsAvailable &&
              planSearchSlice.cohortOptions &&
              planSearchSlice.filters &&
              planSearchSlice.targetArray &&
              planSearchSlice.targetArray.length > 0 && (
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box px={3.2} display='flex' flexDirection='row' width='100%'>
                    <Box
                      paddingRight={1}
                      display='flex'
                      flexDirection='row'
                      width='5%'
                      paddingTop={0.5}
                    >
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        Target
                      </Typography>
                    </Box>
                    {appliedFilter && (
                      <Box paddingLeft={7.6} width='20%'>
                        <FormControl>
                          <RadioGroup
                            id='add_unit_address_choice_rad_group'
                            row
                            aria-label='graphType'
                            value={appliedFilter}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setLocalFilter(undefined)
                              const fileterData: WelloFilter = {
                                target: [
                                  (event.target as HTMLInputElement).value,
                                ],
                                pre_obs: planSearchSlice.filters!.pre_obs,
                                intervention:
                                  planSearchSlice.filters!.intervention,
                                outcome: planSearchSlice.filters!.outcome,
                              }
                              setLocalFilter(fileterData)
                              setAppliedFilter(
                                (event.target as HTMLInputElement).value
                              )
                            }}
                          >
                            {planSearchSlice.targetArray.map((part, index) => (
                              <Box key={part}>
                                <FormControlLabel
                                  value={part}
                                  control={<Radio color='primary' />}
                                  label={
                                    <Typography variant='subtitle2'>
                                      {part}
                                    </Typography>
                                  }
                                />
                              </Box>
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                </Box>
              )} */}
            {planSearchSlice.resultsAvailable &&
              planSearchSlice.cohortOptions &&
              planSearchSlice.filters &&
              appliedFilter &&
              appliedFilter === 'Diabetic (High HbA1c (gt 6.4))' &&
              localFilters && (
                <Box width='100%' px={1}>
                  <CohortChartReport
                    data={getChartDataForCarePlanCohort(
                      planSearchSlice.cohortOptions,
                      'Diabetic (High HbA1c (gt 6.4))'
                    )}
                    title=''
                    width={1300}
                    colorSet='Set2'
                    height={400}
                    min={1}
                    baseMin={1}
                    nodeWidth={16}
                    max={
                      getChartDataForCarePlanCohort(
                        planSearchSlice.cohortOptions,
                        'Diabetic (High HbA1c (gt 6.4))'
                      ).pathway_groups[0].totalPathwaysCount
                    }
                    showMinCount={true}
                    activeFilter={true}
                    filter={localFilters}
                    accessibleTooltip={false}
                    exclusiveFilter={false}
                    showAllFilter={false}
                    visibleStages={Object.keys(localFilters)}
                    customControls={true}
                  />
                </Box>
              )}

            {planSearchSlice.resultsAvailable &&
              planSearchSlice.cohortOptions &&
              planSearchSlice.filters &&
              appliedFilter &&
              appliedFilter ===
                'Primary HTN Grade 2 (SBP gt 160 & DBP gt 100)' &&
              localFilters && (
                <Box width='100%' px={1}>
                  <CohortChartReport
                    data={getChartDataForCarePlanCohort(
                      planSearchSlice.cohortOptions,
                      'Primary HTN Grade 2 (SBP gt 160 & DBP gt 100)'
                    )}
                    title=''
                    width={1300}
                    colorSet='Set2'
                    height={400}
                    min={1}
                    baseMin={1}
                    max={
                      getChartDataForCarePlanCohort(
                        planSearchSlice.cohortOptions,
                        'Primary HTN Grade 2 (SBP gt 160 & DBP gt 100)'
                      ).pathway_groups[0].totalPathwaysCount
                    }
                    nodeWidth={16}
                    showMinCount={true}
                    activeFilter={true}
                    filter={localFilters}
                    accessibleTooltip={false}
                    exclusiveFilter={false}
                    showAllFilter={false}
                    visibleStages={Object.keys(localFilters)}
                    customControls={true}
                  />
                </Box>
              )}

            {planSearchSlice.resultsAvailable &&
              planSearchSlice.cohortOptions &&
              planSearchSlice.filters &&
              appliedFilter &&
              appliedFilter ===
                'Primary HTN Grade 1 (SBP 140-160 & DBP 90-100)' &&
              localFilters && (
                <Box width='100%' px={1}>
                  <CohortChartReport
                    data={getChartDataForCarePlanCohort(
                      planSearchSlice.cohortOptions,
                      'Primary HTN Grade 1 (SBP 140-160 & DBP 90-100)'
                    )}
                    title=''
                    width={1300}
                    colorSet='Set2'
                    height={400}
                    min={1}
                    baseMin={1}
                    max={
                      getChartDataForCarePlanCohort(
                        planSearchSlice.cohortOptions,
                        'Primary HTN Grade 1 (SBP 140-160 & DBP 90-100)'
                      ).pathway_groups[0].totalPathwaysCount
                    }
                    nodeWidth={16}
                    showMinCount={true}
                    activeFilter={true}
                    filter={localFilters}
                    accessibleTooltip={false}
                    exclusiveFilter={false}
                    showAllFilter={false}
                    visibleStages={Object.keys(localFilters)}
                    customControls={true}
                  />
                </Box>
              )}
            {planSearchSlice.resultsAvailable &&
              planSearchSlice.cohortOptions &&
              planSearchSlice.filters &&
              appliedFilter &&
              appliedFilter === 'Prediabetic (Above Normal HbA1c (5.7-6.4))' &&
              localFilters && (
                <Box width='100%' px={1}>
                  <CohortChartReport
                    data={getChartDataForCarePlanCohort(
                      planSearchSlice.cohortOptions,
                      'Prediabetic (Above Normal HbA1c (5.7-6.4))'
                    )}
                    title=''
                    width={1300}
                    colorSet='Set2'
                    height={400}
                    min={1}
                    baseMin={1}
                    max={
                      getChartDataForCarePlanCohort(
                        planSearchSlice.cohortOptions,
                        'Prediabetic (Above Normal HbA1c (5.7-6.4))'
                      ).pathway_groups[0].totalPathwaysCount
                    }
                    nodeWidth={16}
                    showMinCount={true}
                    activeFilter={true}
                    filter={localFilters}
                    accessibleTooltip={false}
                    exclusiveFilter={false}
                    showAllFilter={false}
                    visibleStages={Object.keys(localFilters)}
                    customControls={true}
                  />
                </Box>
              )}

            {planSearchSlice.resultsAvailable &&
              planSearchSlice.cohortOptions &&
              planSearchSlice.filters &&
              appliedFilter &&
              appliedFilter === 'Overweight (Elevated BMI [25-30])' &&
              localFilters && (
                <Box width='100%' px={1}>
                  <CohortChartReport
                    data={getChartDataForCarePlanCohort(
                      planSearchSlice.cohortOptions,
                      'Overweight (Elevated BMI [25-30])'
                    )}
                    title=''
                    width={1300}
                    colorSet='Set2'
                    height={400}
                    min={1}
                    baseMin={1}
                    max={
                      getChartDataForCarePlanCohort(
                        planSearchSlice.cohortOptions,
                        'Overweight (Elevated BMI [25-30])'
                      ).pathway_groups[0].totalPathwaysCount
                    }
                    nodeWidth={16}
                    showMinCount={true}
                    activeFilter={true}
                    filter={localFilters}
                    accessibleTooltip={false}
                    exclusiveFilter={false}
                    showAllFilter={false}
                    visibleStages={Object.keys(localFilters)}
                    customControls={true}
                  />
                </Box>
              )}
            {planSearchSlice.resultsAvailable &&
              planSearchSlice.cohortOptions &&
              planSearchSlice.filters &&
              appliedFilter &&
              appliedFilter === 'Obese (Critical BMI [gt 30])' &&
              localFilters && (
                <Box width='100%' px={1}>
                  <CohortChartReport
                    data={getChartDataForCarePlanCohort(
                      planSearchSlice.cohortOptions,
                      'Obese (Critical BMI [gt 30])'
                    )}
                    title=''
                    width={1300}
                    colorSet='Set2'
                    height={400}
                    min={1}
                    baseMin={1}
                    max={
                      getChartDataForCarePlanCohort(
                        planSearchSlice.cohortOptions,
                        'Obese (Critical BMI [gt 30])'
                      ).pathway_groups[0].totalPathwaysCount
                    }
                    nodeWidth={16}
                    showMinCount={true}
                    activeFilter={true}
                    filter={localFilters}
                    accessibleTooltip={false}
                    exclusiveFilter={false}
                    showAllFilter={false}
                    visibleStages={Object.keys(localFilters)}
                    customControls={true}
                  />
                </Box>
              )}
          </Box>
        </Box>
      </Paper>
    </div>
  )
}
