import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { PractitionerWithRate } from 'models/practitionerWithRate'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { searchMedicalProvidersForRate } from 'redux/practitioner/medicalProviderSearchForRate/medicalProviderSearchSliceForRate'
import { fetchUser } from 'redux/practitioner/practitionerSearchHandler/PractitionerManagement'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserPractitionerRoleDetailsWithPractitioner,
  getCurrentUserUnitDetails,
  isMedicalServiceProvider,
} from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { PractitionerRateDetails } from './practitonerRateDetails'
import { RateDataTable } from './rateDataTable'

export interface IUnitAdmin {
  onTap: () => void
}

export const AppointmentRate: React.FC<IUnitAdmin> = ({ onTap }) => {
  const medicalProviderSearchSliceForRate = useSelector(
    (state: RootState) => state.medicalProviderSearchSliceForRate
  )

  const practitionerManagement = useSelector(
    (state: RootState) => state.practitionerManagement
  )
  const { t, i18n } = useTranslation(['en', 'labelCommon'])
  const dispatch = useDispatch()
  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<R4.IOrganization>(getCurrentUserUnitDetails())
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      currentOrganizationDetails.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''
  const currentPractWithRole: PractitionerWithRole | undefined =
    getCurrentUserPractitionerRoleDetailsWithPractitioner()
  const [selectedLabTypes, setSelectedLabTypes] = useState<string[]>([
    'completed',
    'requested',
  ])

  const [selectedUserDetails, setSelectedUserDetails] =
    useState<PractitionerWithRate>()
  const { width } = useWindowDimensions()
  const [selectedPractitioners, setSelectedPractitioners] = useState<
    PractitionerWithRole[]
  >(
    isMedicalServiceProvider() && currentPractWithRole
      ? [currentPractWithRole]
      : []
  )

  useEffect(() => {
    dispatch(searchMedicalProvidersForRate())
  }, [dispatch])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box p={2}>
            <TextField
              variant='outlined'
              onChange={(event) => {
                dispatch(
                  dispatch(searchMedicalProvidersForRate(event.target.value))
                )
              }}
              size='small'
              autoFocus={true}
              placeholder='Search'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
            >
              {medicalProviderSearchSliceForRate.searching && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>
            <Box
              display='flex'
              flexGrow={4}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
              style={{
                backgroundColor: '#ececec',
              }}
            >
              {medicalProviderSearchSliceForRate.noResultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    align='center'
                  >
                    No Doctor Data Available
                  </Typography>
                </Box>
              )}
              {medicalProviderSearchSliceForRate.error && (
                <Box
                  //   display='flex'
                  //   flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                  paddingLeft={50}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container justifyContent='center'>
                        <Typography
                          variant='subtitle1'
                          color='error'
                          align='center'
                        >
                          Error while searching Doctors . Please try again
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {medicalProviderSearchSliceForRate.resultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height='100%'
                  overflow='auto'
                  // flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexGrow={1}
                    overflow='auto'
                    height='100%'
                    //  paddingTop={0.2}
                  >
                    <RateDataTable
                      usersData={
                        medicalProviderSearchSliceForRate.doctorRoleList
                          ? medicalProviderSearchSliceForRate.doctorRoleList
                          : []
                      }
                      onUserSelected={(user: PractitionerWithRate) => {
                        setSelectedUserDetails(user)
                        dispatch(fetchUser(user.practRoleId ?? ''))

                        onTap()
                      }}
                    />
                  </Box>
                  {selectedUserDetails && (
                    <Box
                      display='flex'
                      flexGrow={1}
                      overflow='auto'
                      minWidth={`${width - 625}px`}
                      maxWidth={`${width - 625}px`}
                      height='100%'
                      style={{ backgroundColor: '#ececec' }}
                    >
                      <PractitionerRateDetails
                        practionerDetails={selectedUserDetails}
                        onCloseClicked={() => {
                          setSelectedUserDetails(undefined)
                          dispatch(searchMedicalProvidersForRate())
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </div>
  )
}
