/* eslint-disable react/display-name */
import MaterialTable, { MTableToolbar } from '@material-table/core'
import {
  Box,
  InputAdornment,
  makeStyles,
  Paper,
  TablePagination,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { CatalogueData } from 'models/catalogueData'
import { FhirCatalougeDetails } from 'models/fhirCatalougeDetails'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getTableDataFromFhir } from 'utils/common/catalogueDataConverter'
import { titleCase } from 'utils/fhirResourcesHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { EditCatalogueRowData } from './editCatalogueData'

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
})

interface ICardProperties {
  testData: FhirCatalougeDetails[]
}

export const CatalogueDataTable: React.FC<ICardProperties> = ({
  testData,
}: ICardProperties) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const convertedData = getTableDataFromFhir(testData)
  const { height, width } = useWindowDimensions()
  const [data, setData] = useState([...convertedData])
  const [nameFilter, setNameFilter] = useState('')
  const [nameFocus, setNameFocus] = useState(true)

  const [typeFilter, setTypeFilter] = useState('')
  const [typeFocus, setTypeFocus] = useState(false)
  const [descFilter, setDescFilter] = useState('')
  const [descFocus, setDescFocus] = useState(false)
  const [splFilter, setSplFilter] = useState('')
  const [splFocus, setSplFocus] = useState(false)
  const [priceFilter, setPriceFilter] = useState('')
  const [priceFocus, setPriceFocus] = useState(false)
  const [summaryFilter, setSummaryFilter] = useState('')
  const [summaryFocus, setSummaryFocus] = useState(false)

  const [catalogueRowData, setCatalogueRowData] =
    React.useState<CatalogueData>()

  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  const filterName = (value: any, filed: string) => {
    setNameFocus(true)
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.testName
          .replace(/\s+/g, '')
          .toLowerCase()
          .includes(value.replace(/\s+/g, '').toLowerCase())
      )

      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setNameFilter(value)
    setDescFocus(false)
    setSplFocus(false)
    setPriceFocus(false)
    setSummaryFocus(false)
    setTypeFocus(false)
  }

  const filterType = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedData.filter((d) => d.type.includes(value))
      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setTypeFilter(value)
    setTypeFocus(true)
    setDescFocus(false)
    setSplFocus(false)
    setPriceFocus(false)
    setSummaryFocus(false)
  }

  const filterDesc = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d
          .testDesc!.replace(/\s+/g, '')
          .toLowerCase()
          .includes(value.replace(/\s+/g, '').toLowerCase())
      )

      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setDescFilter(value)
    setDescFocus(true)
    setNameFocus(false)
    setSplFocus(false)
    setPriceFocus(false)
    setSummaryFocus(false)
    setTypeFocus(false)
  }

  const filterSpl = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.special_Instructions.includes(value)
      )
      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setDescFocus(false)
    setNameFocus(false)
    setSplFocus(true)
    setPriceFocus(false)
    setSummaryFocus(false)
    setSplFilter(value)
    setTypeFocus(false)
  }

  const filterPrice = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.price.toString().includes(value)
      )
      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setDescFocus(false)
    setNameFocus(false)
    setSplFocus(false)
    setPriceFocus(true)
    setSummaryFocus(false)
    setPriceFilter(value)
    setTypeFocus(false)
  }

  const filterSchedule = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.schedule.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setDescFocus(false)
    setNameFocus(false)
    setSplFocus(false)
    setPriceFocus(false)
    setSummaryFocus(true)
    setSummaryFilter(value)
    setTypeFocus(false)
  }

  const columns = [
    {
      title: (
        <Box>
          <Typography variant='subtitle1' color='primary'>
            {'Test Name '}
          </Typography>
        </Box>
      ),
      field: 'testName',
      customSort: (a: any, b: any) =>
        a.testName.localeCompare(b.testName, 'en', {
          sensitivity: 'base',
        }),
      // a.testName > b.testName ? 1 : b.testName > a.testName ? -1 : 0,
      filterPlaceholder: 'Test Name ',

      hiddenByColumnsButton: false,
      render: (rowData: any) => (
        <Box display='flex' alignItems='center'>
          {/* <Box paddingRight={1}>
            <Avatar alt='Profile' src={rowData.profilePic} />
          </Box> */}
          <Typography variant='subtitle2' color='initial' noWrap>
            {titleCase(rowData.testName)}
          </Typography>
        </Box>
      ),
      width: 170,
      filterComponent: (props: any) => (
        <Box width={170}>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={nameFocus}
            placeholder='Test Name'
            value={nameFilter}
            onChange={(e) => filterName(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box>
          <Typography variant='subtitle1' color='primary'>
            {'Type '}
          </Typography>
        </Box>
      ),
      field: 'type',
      customSort: (a: any, b: any) =>
        a.testName.localeCompare(b.type, 'en', {
          sensitivity: 'base',
        }),
      // a.testName > b.testName ? 1 : b.testName > a.testName ? -1 : 0,
      filterPlaceholder: 'Type ',

      hiddenByColumnsButton: false,
      render: (rowData: any) => (
        <Box display='flex' alignItems='center'>
          {/* <Box paddingRight={1}>
              <Avatar alt='Profile' src={rowData.profilePic} />
            </Box> */}
          <Typography variant='subtitle2' color='initial' noWrap>
            {titleCase(rowData.type)}
          </Typography>
        </Box>
      ),
      width: 100,
      filterComponent: (props: any) => (
        <Box width={100}>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={typeFocus}
            placeholder='Type'
            value={typeFilter}
            onChange={(e) => filterType(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography variant='subtitle1' color='primary'>
            {'Description '}
          </Typography>
        </Box>
      ),
      field: 'testDesc',
      //   cellStyle: {
      //     width: 400,
      //     maxWidth: 400,
      //   },
      customSort: (a: any, b: any) =>
        a.testDesc.localeCompare(b.testDesc, 'en', {
          sensitivity: 'base',
        }),
      render: (rowData: any) => (
        <Box>
          {rowData.testDesc !== undefined && rowData.age !== null && (
            <Typography
              variant='subtitle2'
              color='initial'
              style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
            >
              {rowData.testDesc.split('\n').map((i: any, key: any) => (
                <div key={i}>{i}</div>
              ))}
            </Typography>
          )}
        </Box>
      ),
      width: 200,
      filterComponent: (props: any) => (
        <Box width={200}>
          <TextField
            variant='outlined'
            size='small'
            style={{ width: 'inherit' }}
            autoFocus={descFocus}
            placeholder='Description'
            value={descFilter}
            onChange={(e) => filterDesc(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box>
          <Typography variant='subtitle1' color='primary'>
            Pretest Instructions
          </Typography>
        </Box>
      ),
      field: 'special_Instructions',
      //   cellStyle: {
      //     width: 400,
      //     maxWidth: 400,
      //   },
      customSort: (a: any, b: any) =>
        a.special_Instructions.localeCompare(b.special_Instructions, 'en', {
          sensitivity: 'base',
        }),
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Typography
            variant='subtitle2'
            color='initial'
            display='inline'
            style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
          >
            {rowData.special_Instructions
              .split('\n')
              .map((i: any, key: any) => (
                <div key={i}>{i}</div>
              ))}
          </Typography>
        </Box>
      ),
      width: 200,

      filterComponent: (props: any) => (
        <Box width={200}>
          <TextField
            variant='outlined'
            size='small'
            style={{ width: 'inherit' }}
            autoFocus={splFocus}
            placeholder='Special Instructions'
            value={splFilter}
            onChange={(e) => filterSpl(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box display='flex' justifyContent='flex-start' flexGrow>
          <Typography variant='subtitle1' color='primary'>
            {'Price '}
          </Typography>
        </Box>
      ),
      field: 'price',

      //   cellStyle: {
      //     width: '10%',
      //   },
      render: (rowData: any) => (
        <Box paddingY={1} display='flex' justifyContent='flex-start' flexGrow>
          <Box display='flex' alignItems='center'>
            <Typography
              variant='subtitle2'
              color='initial'
              style={{ paddingLeft: 3 }}
            >
              &#x20b9; {rowData.price}
            </Typography>
          </Box>
        </Box>
      ),
      width: 100,
      filterComponent: (props: any) => (
        <Box width={100} display='flex' justifyContent='flex-start'>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={priceFocus}
            placeholder='Price'
            value={priceFilter}
            onChange={(e) => filterPrice(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box display='flex' justifyContent='flex-start' flexGrow>
          <Typography variant='subtitle1' color='primary'>
            Schedule Summary
          </Typography>
        </Box>
      ),
      field: 'schedule',
      //   cellStyle: {
      //     width: 300,
      //     maxWidth: 300,
      //   },
      customSort: (a: any, b: any) =>
        a.schedule.localeCompare(b.schedule, 'en', {
          sensitivity: 'base',
        }),
      render: (rowData: any) => (
        <Box paddingY={1} display='flex' justifyContent='flex-start' flexGrow>
          <Typography variant='subtitle2' color='initial'>
            {rowData.schedule.split('\n').map((i: any, key: any) => (
              <div key={i}>{i}</div>
            ))}
          </Typography>
        </Box>
      ),
      width: 200,

      filterComponent: (props: any) => (
        <Box width={200} display='flex' justifyContent='flex-start' flexGrow>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={summaryFocus}
            placeholder='Schedule Summary'
            value={summaryFilter}
            onChange={(e) =>
              filterSchedule(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
  ]

  return (
    <Box style={{ overflow: 'none' }}>
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                minHeight: '0px',
                paddingTop: '0px',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
          Container: (props) => <Paper {...props} elevation={0} />,
          Pagination: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                display: 'flex',
                justifyContent: 'left',
                padding: '0px',
              }}
            >
              <TablePagination
                {...props}
                labelRowsSelect={<div style={{ fontSize: 14 }}> rows</div>}
                // labelRowsSelect={row => <div style={{ fontSize: 14 }}>{row.</div>}
                SelectProps={{
                  style: {
                    fontSize: 14,
                    backgroundColor: useTheme().palette.background.default,
                  },
                }}
              />
            </div>
          ),
        }}
        columns={columns}
        data={data}
        // editable={{
        //   onBulkUpdate: (changes) =>
        //     new Promise((resolve, reject) => {
        //       //   setTimeout(() => {
        //       //     resolve()
        //       //   }, 1000)
        //     }),
        //   onRowDelete: (oldData) =>
        //     new Promise((resolve, reject) => {
        //       setTimeout(() => {
        //         // resolve()
        //       }, 1000)
        //     }),
        // }}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        onRowClick={(event, rowData) => {
          setCatalogueRowData(rowData)
          setShowPopup(true)
        }}
        options={{
          showTitle: false,
          toolbar: false,
          search: false,
          padding: 'dense',

          searchFieldVariant: 'outlined',
          filtering: true,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30],
          columnsButton: false,
          //   overflowY: 'hidden',
          maxBodyHeight: `${height - 160}px`,
          minBodyHeight: `${height - 290}px`,

          thirdSortClick: false,
          sorting: true,

          headerStyle: {
            backgroundColor: useTheme().palette.background.default,
            color: '#FFF',
            width: 100,
            minHeight: '15px',
            maxHeight: '15px',
            position: 'sticky',
            top: 0,
            // height: 20,
          },
          filterRowStyle: {
            position: 'sticky',
            zIndex: 5 /* optionally */,
            top: 40,
            backgroundColor: '#ececec',
            color: '#333333',
            paddingTop: '0px',
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          rowStyle: (_data: any, index: number, _level: number) =>
            index % 2 ? { backgroundColor: kDialogueBackground } : {},

          //   toolbar: false,
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />

      {showPopup && (
        <Box
          display='flex'
          flexGrow={4}
          overflow='auto'
          minWidth={`${width - 460}px`}
          height='100%'
          style={{ backgroundColor: '#ececec' }}
        >
          <EditCatalogueRowData
            rowDetails={catalogueRowData as CatalogueData}
            open={showPopup}
            onCancelClick={() => {
              setShowPopup(false)
            }}
          />
        </Box>
      )}
    </Box>
  )
}
