import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitReference,
  getUserCurrentRole,
  isDoctor,
  isUnitAdmin,
} from 'services/userDetailsService'
import { logger } from 'utils/logger'
import { AppointmentCountStatus } from './appointmentCountStatus'

const initialState: AppointmentCountStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
  count: 0,
}

const appointmentCountSlice = createSlice({
  name: 'appointmentCount',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<AppointmentCountStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.count = action.payload.count
      state.errorReason = action.payload.errorReason

      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const requestAppointmentsCountForToday =
  (selectedDate?: Date): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AppointmentCountStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      count: 0,
    }
    dispatch(appointmentCountSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        date: `ge${moment.utc(new Date()).local().format('YYYY-MM-DD')}`,
        status: 'booked',
        _count: 0,
        _total: 'accurate',
      }

      if (selectedDate) {
        searchParameters.date = `${moment(selectedDate)
          .local()
          .format('YYYY-MM-DD')}`
      } else {
        searchParameters.date = `ge${moment
          .utc(new Date())
          .local()
          .format('YYYY-MM-DD')}`
      }
      const currentRole = getUserCurrentRole()
      const pract: R4.IPractitioner = getCurrentUserPractitionerDetails()
      if (isDoctor()) searchParameters.practitioner = `Practitioner/${pract.id}`
      if (!isDoctor() || isUnitAdmin()) {
        const currentUnit = getCurrentUserUnitReference()
        if (currentUnit && currentUnit.reference) {
          searchParameters['actor:PractitionerRole.organization'] =
            currentUnit.reference
        }
      }
      const response: any = await fhirClient.doGetResource(
        '/Appointment',
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingAppointments = true
        state.searchingAppointments = false

        dispatch(appointmentCountSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp.right
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          state.resultsAvailable = true
          state.searchingAppointments = false
          state.count = appointmentResponse.total
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingAppointments = false
          dispatch(appointmentCountSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: AppointmentCountStatus = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            count: 0,
          }
          dispatch(
            appointmentCountSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: AppointmentCountStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
        count: 0,
      }
      dispatch(appointmentCountSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default appointmentCountSlice.reducer
