/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable consistent-return */
import { Box } from '@material-ui/core'
import { AnyARecord } from 'dns'
import {
  CohortChartProps,
  CohortControlsProps,
  CohortDataStage,
  MapDataProps,
} from 'models/chartProps'
import { SankeyData } from 'models/sankeyData'
import { SankeyLink } from 'models/sankeyLinks'
import { SankeyNode } from 'models/sankeyNode'
import React, { useEffect, useState } from 'react'
import { getFilterLabel } from 'utils/cohort/cohortUtils'
import { CustomDynamicFilter } from './controlComponent/customDynamicFilter'
import { CustomFontSize } from './controlComponent/customFontSize'
import { CustomPersonSlider } from './controlComponent/customMinFilter'

import { DynamicFilterSelect } from './controlComponent/dynamicFilterSelect'
import { InclusiveFilter } from './controlComponent/inclusiveFilter'
import { PersonSlider } from './controlComponent/perSonSlider'
import { VisibleStageSelect } from './controlComponent/visibleStageSelect'
import { CustomInclusiveFilter } from './customInclusiveFilter'
import { CustomVisibleStage } from './customVisibleStageList'
import { ErrorMessage } from './errorMessage'
import { SankeyChart } from './shankeyChart'

export const CustomControls: React.FC<CohortControlsProps> = ({
  max,
  min,
  baseMin,
  handleSetMin,
  handleStageChange,
  visibleStages,
  stages,
  handleStageFilterChange,
  filterMap,
  inclusiveFilter,
  handleInclusiveFilter,
  filters,
  handleFontDecrease,
  handleFontIncrease,
}: CohortControlsProps) => {
  console.log(Object.keys(stages))
  return (
    <Box display='flex' flexDirection='column' width='100%'>
      <Box display='flex' flexDirection='row' width='100%'>
        <Box display='flex' flexDirection='row' width='30%'>
          <CustomVisibleStage
            label='Visible Stages'
            name='stages'
            stages={stages}
            visibleStages={visibleStages}
            handleStageChange={handleStageChange}
          />
        </Box>

        <Box
          display='flex'
          flexDirection='row'
          width='15%'
          justifyContent='flex-start'
          px={1}
        >
          <CustomPersonSlider
            label='Min Person Count'
            id='person'
            name='person'
            baseMin={baseMin}
            min={min}
            max={max || 1000}
            step={1}
            value={min}
            onChange={handleSetMin}
          />
        </Box>

        <Box
          display='flex'
          flexDirection='row'
          width='15%'
          justifyContent='flex-start'
          px={1}
        >
          <CustomFontSize
            label='Font Size'
            handleFontDecrease={handleFontDecrease}
            handleFontIncrease={handleFontIncrease}
          />
        </Box>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        width='20%'
        justifyContent='center'
        paddingTop={1}
        paddingLeft={1.5}
      >
        <CustomInclusiveFilter
          label='Exclusion Filter'
          onChange={handleInclusiveFilter}
          value={inclusiveFilter}
        />
      </Box>
      <Box display='flex' flexDirection='column' width='100%'>
        <CustomDynamicFilter
          label='Filters'
          handleStageFilterChange={handleStageFilterChange}
          filterMap={filterMap}
          filters={filters}
          visibleStages={visibleStages}
        />
      </Box>
    </Box>
  )
}
