import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import React from 'react'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { hasSpaces } from 'utils/fhirResoureHelpers/allergyHelpers'

interface OccupationTileProps {
  type: string
  date?: string
}

export const ReasonForVisitTile: React.FC<OccupationTileProps> = ({
  type,
  date,
}: OccupationTileProps) => {
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Grid container direction='row' spacing={1}>
        {/* ------------------------------------------------------------------------------- */}

        <Box display='flex' flexDirection='column' width='100%' flexGrow={1}>
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <Grid item xs={12}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={40}
                      alignItems='center'
                      px={1}
                      style={{
                        backgroundColor: 'lightGrey',
                      }}
                    >
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: 'black',

                          fontWeight: 'bold',
                        }}
                      >
                        {date && date.length > 0 ? `Recorded on ${date}` : ''}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container direction='row' id='vitals'>
            <Grid item xs={12} style={{ backgroundColor: 'white' }}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    {hasSpaces(type) && (
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          wordWrap: 'break-word',
                          whiteSpace: 'pre-line',
                          textOverflow: 'ellipsis',
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {type.split('\n').map((i: any, key: any) => (
                          <div key={i}>{i}</div>
                        ))}
                      </Typography>
                    )}
                    {hasSpaces(type) === false && (
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          inlineSize: 500,
                          overflowWrap: 'break-word',
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        <div
                          style={{
                            overflowWrap: 'break-word',
                          }}
                        >
                          {type}
                        </div>
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Box>
  )
}
