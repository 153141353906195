import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'
import { AddressTile } from './addressTile'

interface Props {
  addresses: R4.IAddress[]
  onAddressSelected: (selectedAddress: R4.IAddress) => void
  onAddNewClicked: () => void
  onClosed: () => void
}

export const AddressListSelector: React.FC<Props> = ({
  addresses,
  onAddressSelected,
  onAddNewClicked,
  onClosed,
}: Props) => {
  const [value, setValue] = React.useState<R4.IAddress | null>(null)

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      <Autocomplete
        id='address_selector'
        value={value}
        size='small'
        fullWidth
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // Todo
          } else if (newValue !== null) {
            setValue(newValue)
            if (newValue) onAddressSelected(newValue)
          }
        }}
        filterOptions={(options, params) => {
          const filtered: R4.IAddress[] = [...addresses]
          if (params.inputValue === '') {
            filtered.push({
              city: '+ Add New Address',
            })
          }
          return filtered
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={addresses}
        getOptionLabel={(option: R4.IAddress) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option
          }
          // Add "xxx" option created dynamically
          if (option.use) {
            return option.use
          }
          // Regular option
          return option.line ? option.line[0] : ''
        }}
        ListboxProps={{
          style: { padding: 0, margin: 0 },
        }}
        renderOption={(option: R4.IAddress) => {
          if (option.city && option.city === '+ Add New Address') {
            return (
              <Box
                width='100%'
                onClick={() => {
                  onAddNewClicked()
                }}
                id='box_new_address'
              >
                {' '}
                <Typography color='primary' id='new_address'>
                  {' '}
                  + Add New Address
                </Typography>{' '}
              </Box>
            )
          }

          return <AddressTile addresses={option} />
        }}
        renderInput={(params) => (
          <TextField {...params} variant='outlined' id='search_new_address' />
        )}
      />
    </Box>
  )
}
