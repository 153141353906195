import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { fetchPendingTasksDetailsOfCurrentUser } from 'redux/pendingTaskChecker/pendingTasksCheckerSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { uploadFileDetails } from 'services/fileApiService'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserPractitionerRoleDetails,
  updateUserDetailsFromServer,
} from 'services/userDetailsService'
import { ORG_LOGO_SPECIALIZATION_URL } from 'utils/constants/fhir_vs_ext_constants'
import {
  getValueAttachmentFromExtensionData,
  removeExtensionFromExtensions,
} from 'utils/fhirResourcesHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { logger } from 'utils/logger'
import { OrgProfileCompletionTypes } from './orgProfileCompletionTypes'

const initialState: OrgProfileCompletionTypes = {
  updatingBasicDetails: false,
  updatedBasicDetails: false,
  organizationDetails: getCurrentUserMainOrganizationDetails(),
  error: false,
  errorMessage: '',
}

const orgProfileCompletionSlice = createSlice({
  name: 'orgProfileCompletionSlice',
  initialState,
  reducers: {
    updateState(state, action: PayloadAction<OrgProfileCompletionTypes>) {
      state.updatingBasicDetails = action.payload.updatingBasicDetails
      state.updatedBasicDetails = action.payload.updatedBasicDetails
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage

      state.organizationDetails = action.payload.organizationDetails
    },
  },
})
export const getCurrentUserOrgDetails =
  (): AppThunk => async (dispatch: any) => {
    const orgDetails: R4.IOrganization = getCurrentUserMainOrganizationDetails()
    if (orgDetails.id === undefined) {
      const res = await updateUserDetailsFromServer()
    }
    if (orgDetails.meta) {
      logger.info('Meta', orgDetails.meta)
      if (orgDetails.meta.tag && orgDetails.meta.tag.length > 0) {
        const code: R4.ICoding | undefined = orgDetails.meta.tag.find(
          (e) => e.code === 'org_details_added'
        )
        if (code) {
          const addingCreatePersonState: OrgProfileCompletionTypes = {
            ...initialState,
            updatedBasicDetails: true,
            organizationDetails: getCurrentUserMainOrganizationDetails(),
          }
          dispatch(
            orgProfileCompletionSlice.actions.updateState(
              addingCreatePersonState
            )
          )
          return
        }
      }
    }
    const addingCreatePersonState: OrgProfileCompletionTypes = {
      ...initialState,
      organizationDetails: getCurrentUserMainOrganizationDetails(),
    }
    dispatch(
      orgProfileCompletionSlice.actions.updateState(addingCreatePersonState)
    )
    dispatch(fetchPendingTasksDetailsOfCurrentUser())
  }

export const updateOrgBasicDetails =
  (
    organizationDetails: R4.IOrganization,
    location: R4.ILocation,
    task?: R4.ITask
  ): AppThunk =>
  async (dispatch: any) => {
    const addingCreatePersonState: OrgProfileCompletionTypes = {
      ...initialState,
      updatingBasicDetails: true,
    }
    dispatch(
      orgProfileCompletionSlice.actions.updateState(addingCreatePersonState)
    )

    const logo: R4.IAttachment | undefined =
      getValueAttachmentFromExtensionData(
        organizationDetails.extension,
        'http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo'
      )

    organizationDetails.extension = removeExtensionFromExtensions(
      organizationDetails.extension,
      ORG_LOGO_SPECIALIZATION_URL
    )

    organizationDetails.extension = []
    if (logo) {
      const logoExt: R4.IExtension = {}
      logoExt.url = ORG_LOGO_SPECIALIZATION_URL
      logoExt.valueAttachment = {
        contentType: logo.contentType,
        title: logo.title,
      }
      organizationDetails.extension.push(logoExt)
    }
    try {
      const requestBody: R4.IBundle | undefined = await getTransactionObject(
        organizationDetails,
        location,
        task
      )
      logger.info('request body', requestBody)
      if (requestBody) {
        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response: any | FHIRErrorResponses =
          await fhirApi.doCreateFHIRTransaction('', requestBody)
        logger.info('Response ', response)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          const res: boolean = await updateUserDetailsFromServer()
          const orgData = getCurrentUserMainOrganizationDetails()
          if (res) {
            try {
              if (logo) {
                await uploadFileDetails(
                  logo,
                  "Organization.extension('http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo').value.as(Attachment)",
                  'Organization',
                  organizationDetails.id ?? ''
                )
              }
            } catch (error) {
              logger.info(error)
              dispatch(showErrorAlert('Error in uploading logo'))
            }

            const successCreatePersonState: OrgProfileCompletionTypes = {
              ...addingCreatePersonState,
              updatedBasicDetails: true,
              updatingBasicDetails: false,
              error: false,
              errorMessage: '',

              organizationDetails: getCurrentUserMainOrganizationDetails(),
            }
            dispatch(
              orgProfileCompletionSlice.actions.updateState(
                successCreatePersonState
              )
            )
            return
          }
        }
      }
      const errorCreatePersonState: OrgProfileCompletionTypes = {
        ...addingCreatePersonState,
        updatedBasicDetails: false,
        updatingBasicDetails: false,
        error: true,
        errorMessage: 'Error while fetching updated, Please Try logging later',
      }
      dispatch(
        orgProfileCompletionSlice.actions.updateState(errorCreatePersonState)
      )
      return
    } catch (error) {
      logger.info('error')
      logger.info(error)
      const errorCreatePersonState: OrgProfileCompletionTypes = {
        ...addingCreatePersonState,
        updatedBasicDetails: false,
        updatingBasicDetails: false,
        error: true,
        errorMessage: 'Error while updating details, Please Try later',
      }
      dispatch(
        orgProfileCompletionSlice.actions.updateState(errorCreatePersonState)
      )
    }
  }

export const resetOrgCompletionState = () => (dispatch: AppDispatch) => {
  dispatch(orgProfileCompletionSlice.actions.updateState(initialState))
}

async function getTransactionObject(
  organizationDetails: R4.IOrganization,
  location: R4.ILocation,
  task?: R4.ITask
): Promise<R4.IBundle | undefined> {
  organizationDetails.meta = {
    ...(organizationDetails.meta ?? ({} as R4.IMeta)),
    tag: [
      ...(organizationDetails.meta?.tag ?? []),
      {
        code: 'org_details_added',
        system:
          'http://wellopathy.com/fhir/india/core/vs/unitCompleitionIndicators',
      },
    ],
  }

  const matchOrganizationString: string = `W/${JSON.stringify(
    organizationDetails.meta?.versionId ?? ' '
  )}`

  const practitionerRoleDetails = getCurrentUserPractitionerRoleDetails()
  const updatedPracRole: R4.IPractitionerRole = { ...practitionerRoleDetails }

  const ref: R4.IReference[] = []
  const reference: R4.IReference = {}
  reference.reference = `urn:uuid:Location`
  ref.push(reference)

  updatedPracRole.location = ref
  const mainTaskMatchString: string = `W/${JSON.stringify(
    updatedPracRole.meta?.versionId ?? ''
  )}`

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `${organizationDetails.resourceType}/${organizationDetails.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${organizationDetails.resourceType}/${organizationDetails.id}`,
          ifMatch: matchOrganizationString,
        },
        resource: organizationDetails,
      },
      {
        fullUrl: 'urn:uuid:Location',
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: location.resourceType,
        },
        resource: location,
      },
      {
        fullUrl: `${updatedPracRole.resourceType}/${updatedPracRole.id}`,
        request: {
          ifMatch: mainTaskMatchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `${updatedPracRole.resourceType}/${updatedPracRole.id}`,
        },
        resource: updatedPracRole,
      },
    ],
  }

  if (task) {
    const modifiedTask: R4.ITask = { ...task }
    modifiedTask.status = R4.TaskStatusKind._completed

    const matchTaskString: string = `W/${JSON.stringify(
      modifiedTask.meta?.versionId ?? ' '
    )}`

    requestBundle.entry?.push({
      fullUrl: `Task/${task.id}`,
      request: {
        ifMatch: matchTaskString,
        method: R4.Bundle_RequestMethodKind._put,
        url: `Task/${task.id}`,
      },
      resource: modifiedTask,
    })
  }
  logger.info('Request bundle', requestBundle)
  return requestBundle
}

async function getDocumentReference(
  type: string,
  image: string,
  pract: R4.IPractitioner
): Promise<R4.IDocumentReference> {
  const docRef: R4.IDocumentReference = {
    id: getUniqueTempId(),
    resourceType: 'DocumentReference',
    subject: {
      type: pract.resourceType,
      id: pract.id,
      reference: `${pract.resourceType}/${pract.id}`,
    },
    type: {
      coding: [
        {
          system: 'http://wellopathy.com/fhir/india/core/Code/userProofs',
          code: type,
        },
      ],
    },
    category: [
      {
        coding: [
          {
            system: 'http://wellopathy.com/fhir/india/core/Code/userProofs',
            code: 'user-proof',
          },
        ],
      },
    ],
    content: [
      {
        attachment: {
          url: image,
        },
      },
    ],
  }
  return docRef
}

async function getRoleCompletionTransactionObject(
  practitioner: R4.IPractitioner,
  practitionerRole: R4.IPractitionerRole,
  task?: R4.ITask
): Promise<R4.IBundle | undefined> {
  const matchPractitionerString: string = `W/${JSON.stringify(
    practitioner.meta?.versionId ?? ' '
  )}`
  const matchPractitionerRoleString: string = `W/${JSON.stringify(
    practitionerRole.meta?.versionId ?? ' '
  )}`

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      /* ...entries, */
      {
        fullUrl: `Practitioner/${practitioner.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `Practitioner/${practitioner.id}`,
          ifMatch: matchPractitionerString,
        },
        resource: practitioner,
      },
      {
        fullUrl: `PractitionerRole/${practitionerRole.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `PractitionerRole/${practitionerRole.id}`,
          ifMatch: matchPractitionerRoleString,
        },
        resource: practitionerRole,
      },
    ],
  }

  if (task) {
    const modifiedTask: R4.ITask = { ...task }
    modifiedTask.status = R4.TaskStatusKind._completed

    const matchTaskString: string = `W/${JSON.stringify(
      modifiedTask.meta?.versionId ?? ' '
    )}`

    requestBundle.entry?.push({
      fullUrl: `Task/${task.id}`,
      request: {
        ifMatch: matchTaskString,
        method: R4.Bundle_RequestMethodKind._put,
        url: `Task/${task.id}`,
      },
      resource: modifiedTask,
    })
  }

  return requestBundle
}

export default orgProfileCompletionSlice.reducer
