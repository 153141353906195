import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { PatientDataWithAppointment } from 'models/patientDataWithAppointment'
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { CcClinet } from 'services/Cclinet'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getUserDetails } from 'services/userDetailsService'
import { logger } from 'utils/logger'
import { DownloadLinkStatusAdmin } from './downloadLinkStatus'

const initialState: DownloadLinkStatusAdmin = {
  sending: false,
  sentSuccesFully: false,
  error: false,
  errorMessage: '',
}

const donwloadPatientLinkSliceForAdmin = createSlice({
  name: 'donwloadPatientLinkSliceForAdmin',
  initialState,
  reducers: {
    updateStatus(state, action: PayloadAction<DownloadLinkStatusAdmin>) {
      state.sending = action.payload.sending
      state.sentSuccesFully = action.payload.sentSuccesFully
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },
  },
})

export const sendDownlinkInviteForAdmin =
  (practitionerId: string, roleData: string[]): AppThunk =>
  async (dispatch: any) => {
    const addingCreatePersonState: DownloadLinkStatusAdmin = {
      sending: true,
      sentSuccesFully: false,
      error: false,
    }
    dispatch(
      donwloadPatientLinkSliceForAdmin.actions.updateStatus(
        addingCreatePersonState
      )
    )

    try {
      logger.info('patientDetails')
      const currentUserPractRole: R4.IPractitionerRole | undefined =
        getUserDetails()?.practitionerRole

        const formData = {
          practitionerId: `Practitioner/${practitionerId}`,
          application: roleData.includes('unit-admin')
            ? 'wello-ADMIN'
            : 'wello-MITRA',
        }

      const enRolClient: CcClinet = new CcClinet()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        '/invitation/provider/downloadApk',
        formData
      )
      const successCreatePersonState: DownloadLinkStatusAdmin = {
        sending: false,
        sentSuccesFully: true,
        error: true,
        errorMessage: '',
      }
      dispatch(
        donwloadPatientLinkSliceForAdmin.actions.updateStatus(
          successCreatePersonState
        )
      )
      dispatch(showSuccessAlert('Download app link sent successfully'))
      dispatch(
        donwloadPatientLinkSliceForAdmin.actions.updateStatus(initialState)
      )
      return
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorCreatePersonState: DownloadLinkStatusAdmin = {
        sending: false,
        sentSuccesFully: false,
        error: true,
        errorMessage: 'Error while uploading patient data',
      }
      dispatch(showErrorAlert('Error while sending link'))
      dispatch(
        donwloadPatientLinkSliceForAdmin.actions.updateStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetUpdateUserState = () => (dispatch: AppDispatch) => {
  dispatch(donwloadPatientLinkSliceForAdmin.actions.updateStatus(initialState))
}

export default donwloadPatientLinkSliceForAdmin.reducer
