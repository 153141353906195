import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { UnitServices } from 'models/units/unitServices'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getUserDetails } from 'services/userDetailsService'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { logger } from 'utils/logger'
import { UnitInvitationSetupType } from './unitInvitationSetupType'

const initialState: UnitInvitationSetupType = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const unitInvitationSetupSlice = createSlice({
  name: 'unitInvitation',
  initialState,
  reducers: {
    sendingUnitDetails(state, action: PayloadAction<UnitInvitationSetupType>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
    },

    sentUnitDetails(state, action: PayloadAction<UnitInvitationSetupType>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.patient = action.payload.patient
    },

    errorWhileSendingInvitation(
      state,
      action: PayloadAction<UnitInvitationSetupType>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetState(state, action: PayloadAction<UnitInvitationSetupType>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.patient = action.payload.patient
    },
  },
})

export const sendUnitInvitations =
  (unitDetails: UnitServices[]): AppThunk =>
  async (dispatch: any) => {
    const addingCreatePersonState: UnitInvitationSetupType = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(
      unitInvitationSetupSlice.actions.sendingUnitDetails(
        addingCreatePersonState
      )
    )

    try {
      const requestBody: R4.IBundle | undefined =
        getTransactionObject(unitDetails)
      if (requestBody) {
        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response = await fhirApi.doCreateFHIRTransaction('', requestBody)
        logger.info('Response ', response)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          const successCreatePersonState: UnitInvitationSetupType = {
            adding: false,
            additionSuccessful: true,
            error: false,
            errorMessage: '',
          }
          dispatch(showSuccessAlert('Unit created successfully'))
          dispatch(
            unitInvitationSetupSlice.actions.sentUnitDetails(
              successCreatePersonState
            )
          )
          return
        }
      }
      const errorCreatePersonState: UnitInvitationSetupType = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while creating unit',
      }
      dispatch(
        unitInvitationSetupSlice.actions.sentUnitDetails(errorCreatePersonState)
      )
      return
    } catch (error) {
      const errorCreatePersonState: UnitInvitationSetupType = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while creating unit',
      }
      dispatch(
        unitInvitationSetupSlice.actions.errorWhileSendingInvitation(
          errorCreatePersonState
        )
      )
    }
  }

export const resetUnitAddReducerState = () => (dispatch: AppDispatch) => {
  dispatch(unitInvitationSetupSlice.actions.resetState(initialState))
}

export default unitInvitationSetupSlice.reducer

function getTransactionObject(input: UnitServices[]): R4.IBundle | undefined {
  const mainOrganization: R4.IOrganization | undefined =
    getUserDetails()?.mainOrganization
  if (mainOrganization && mainOrganization.id) {
    const mainOrgRef: R4.IReference = {
      type: mainOrganization?.resourceType,
      id: mainOrganization.id,
      reference: `${mainOrganization?.resourceType}/${mainOrganization.id}`,
    }
    input.forEach((e: UnitServices) => {
      e.orgs.partOf = mainOrgRef
      e.orgs.active = true
      e.orgs.type?.push({
        coding: [
          {
            system: 'http://wellopathy.com/fhir/india/core/CodeSystem/org-type',
            code: 'vendor-unit',
          },
        ],
      })
    })

    input.forEach((e: UnitServices) => {
      const codingData: R4.ICoding[] = []

      const occupationExt: R4.IExtension = {}
      occupationExt.url =
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-capability-hierarchy-extn'

      if (e.allopathyEssential) {
        codingData.push(e.allopathyEssential)
      }
      if (e.ayurvedaEssentials) {
        codingData.push(e.ayurvedaEssentials)
      }
      if (codingData.length > 0) {
        occupationExt.valueCodeableConcept = {
          coding: codingData,
        }
      }
      e.orgs.extension = []
      e.orgs.extension.push(occupationExt)
    })

    const entries: R4.IBundle_Entry[] = []

    input.forEach((e: UnitServices) => {
      entries.push({
        fullUrl: e.orgs.resourceType,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: e.orgs.resourceType,
        },
        resource: e.orgs,
      })
      const matchStringEncounter: string = `Location?organization=${e.orgs.id}`
      if (e.type === 'clinic' || e.type === 'wellness_center') {
        if (e.serviceType && e.serviceType.length > 0) {
          for (let i = 0; i < e.serviceType.length; i++) {
            const randomNum: number = getRandomInt(999999)
            entries.push({
              fullUrl: `HealthcareService/`,
              request: {
                method: R4.Bundle_RequestMethodKind._post,
                url: 'HealthcareService',
              },
              resource: {
                resourceType: 'HealthcareService',
                active: true,
                name: `${e.orgs.name ?? ''} Services`,
                providedBy: {
                  reference: `${e.orgs.id}`,
                  type: 'Organization',
                  display: e.orgs.name ?? '',
                },
                category: [
                  {
                    coding: [
                      {
                        system: 'http://snomed.info/sct',
                        code: '224891009',
                        display: 'Healthcare services',
                      },
                    ],
                  },
                ],
                type: [
                  {
                    coding: [e.serviceType[i]],
                  },
                ],
              },
            })
          }
        }

        if (e.systemOfMedicine && e.systemOfMedicine.length > 0) {
          const randomNum: number = getRandomInt(888888)
          entries.push({
            fullUrl: `HealthcareService/`,
            request: {
              method: R4.Bundle_RequestMethodKind._post,
              url: 'HealthcareService',
            },
            resource: {
              resourceType: 'HealthcareService',
              active: true,
              name: `System of Medicine ${e.orgs.name ?? ''}`,
              providedBy: {
                reference: `${e.orgs.id}`,
                type: 'Organization',
                display: e.orgs.name ?? '',
              },
              category: [
                {
                  coding: [
                    {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-service-category-cs',
                      code: '306',
                      display: 'System of Medicine',
                    },
                  ],
                },
              ],
              type: [
                {
                  coding: e.systemOfMedicine ?? [],
                },
              ],
            },
          })
        }
      }
      entries.push({
        fullUrl: 'Location',
        request: {
          url: matchStringEncounter,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: {
          resourceType: 'Location',
          managingOrganization: {
            reference: `${e.orgs.id}`,
          },
          id: getUniqueTempId(),
          name: e.orgs.name,
          telecom: e.orgs.contact,
          mode: R4.LocationModeKind._instance,
          status: R4.LocationStatusKind._active,
        },
      })
    })

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: entries,
    }
    return requestBundle
  }
  return undefined
}

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max))
}
