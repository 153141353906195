import { makeStyles } from '@material-ui/core'
import { BodyPartState } from 'models/bodyPartState'
import React, { useState } from 'react'
import BodyAnt from './Components/BodyAnt'
import BodyPost from './Components/BodyPost'
import LinearGradient from './Components/LinearGradient'
import style from './index.module.css'
import {
  PainLevelSelector,
  PainLevelSelectorDialogProps,
} from './painLevelSelector'

const useStyles = makeStyles((theme) => ({
  fabEdit: {
    position: 'fixed',
    zIndex: 1000,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabDouleur: {
    position: 'fixed',
    zIndex: 1000,
    bottom: theme.spacing(2),
    right: theme.spacing(11),
  },
}))

interface BodyMapProps {
  onSlectionChanges: (e: BodyPartState) => void
  preAddedDetails?: BodyPartState[]
  isReadOnly?: boolean
}

const BodyMap = ({
  onSlectionChanges,
  isReadOnly,
  preAddedDetails,
}: BodyMapProps) => {
  const [showPainLevelSelector, setShowPainLevelSelector] =
    useState<PainLevelSelectorDialogProps>({
      open: false,
    })

  const [selectedValues, setSelectedValues] = useState<BodyPartState[]>(
    preAddedDetails ?? []
  )

  const handleClickBody = (newValue: any) => {
    const index = selectedValues.findIndex((e) => e.code === newValue.id)
    setShowPainLevelSelector({
      open: true,
      title: newValue.name,
      preSelectedPainLevel:
        index > -1 ? selectedValues[index].state : undefined,
      onContinueActionClicked: (e) => {
        setShowPainLevelSelector({
          open: false,
        })
        handleSelection(newValue.id, newValue.name, e)
      },
      onCancelActionClicked: () => {
        setShowPainLevelSelector({
          open: false,
        })
      },
    })
  }

  const handleSelection = (
    partId: string,
    partName: string,
    selectedPainLevel: 'none' | 'low' | 'moderate' | 'high'
  ) => {
    const index = selectedValues.findIndex((e) => e.code === partId)
    if (index > -1) {
      const temp = [...selectedValues]
      temp.splice(index, 1)
      setSelectedValues([
        ...temp,
        {
          code: partId,
          partName,
          state: selectedPainLevel,
        },
      ])
      onSlectionChanges({
        code: partId,
        partName,
        state: selectedPainLevel,
      })
    } else {
      const code: BodyPartState = {
        code: partId,
        partName,
        state: selectedPainLevel,
      }
      const temp = [...selectedValues, code]
      setSelectedValues(temp)
      onSlectionChanges(code)
    }
  }

  return (
    <div className={style.root}>
      <LinearGradient />
      <div className={style.container}>
        <BodyAnt
          tooltip
          selectionItems={selectedValues}
          handleClickBody={(membre: any) => {
            if (!isReadOnly) handleClickBody(membre)
          }}
        />
        <BodyPost
          tooltip
          selectionItems={selectedValues}
          handleClickBody={(membre: any) => {
            if (!isReadOnly) handleClickBody(membre)
          }}
        />
      </div>
      {showPainLevelSelector.open && (
        <PainLevelSelector {...showPainLevelSelector} />
      )}
    </div>
  )
}

export default BodyMap
