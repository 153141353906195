import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Alert } from '@material-ui/lab'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import {
  resetBulkPatientAdd,
  uploadingPreAppointmentPatientData,
} from 'redux/patient/bulkAddPatient/bulkAddPatientPreAppointmentSlice'
import { RootState } from 'redux/rootReducer'
import { getCorrectedPhone } from 'utils/fhirResourcesHelper'
import { validateMobileNumber } from 'utils/formValidators'
import excel from 'xlsx'
import { PatientPreAppointmentDataHelper } from '../../../../models/patientDataHelper'
import {
  fileType,
  menu,
  validTypes,
} from '../../../../utils/constants/labConstants'
import { WelloTabs } from '../../LeftMenu/WelloTabs'
import { DropZone } from './dropZonePreAppointment'

export const requiredColumns = [
  'Patient First Name',
  'Patient Last Name',
  'Phone',
  'Date Of Appointment (DD-MM-YYYY)',
]

interface Props {
  open: boolean
  onBackClick: () => void
  onContinueClick: () => void
}

export const PatientPreAppointmentSetUpBulkUpload: React.FC<Props> = ({
  open,
  onBackClick: onClose,
  onContinueClick,
}) => {
  const bulkPatientAddSlice = useSelector(
    (state: RootState) => state.bulkPatientAddSlice
  )

  const [selectedTab, setSelectedTab] = React.useState(menu[0])
  const [selectedFiles, setSelectedFiles] = React.useState<any>()

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleChangeTab = (selected: string) => {
    setSelectedTab(selected)
  }

  const validateExcel = (file: any) => {
    setErrorMessage(undefined)
    const errorData: string[] = []
    let columnData: string[] = []
    const name: string[] = []
    const lastName: string[] = []
    const appointmentDate: string[] = []

    const phoneNo: string[] = []

    const parsedArray: any[] = []

    let jsonObj: any
    const reader = new FileReader()
    const reader1 = new FileReader()
    reader1.onload = (evt) => {
      const test = (evt.target as FileReader).result
    }
    reader1.readAsDataURL(file)

    const rABS = !!reader.readAsArrayBuffer
    reader.onload = (e) => {
      const bstr = (e.target as FileReader).result
      const wb = excel.read(bstr, {
        type: rABS ? 'binary' : 'array',
        bookVBA: true,
      })

      const wb1 = excel.read(bstr, {
        type: 'binary',
      })
      /* Get first worksheet */
      const wsName = wb.SheetNames
      if (wsName.includes('Patients_Data')) {
        for (let i = 0; i < wsName.length; i++) {
          const ws = wb.Sheets[wsName[i]]
          const data = excel.utils.sheet_to_json(ws)

          if (wsName[i] === 'Patients_Data') {
            columnData = excel.utils.sheet_to_json(ws, { header: 1 })

            jsonObj = eval(JSON.stringify(data))

            let k = 2
            const testData: any[] = []
            for (let j = 0; j < jsonObj.length; j++) {
              if (jsonObj[j].Patient_Name === undefined) {
                errorData.push(`Patient_Name is required at column 2 row ${k}`)
                //   testData.push(' ')
              } else {
                name.push(jsonObj[j].Patient_Name)
              }

              if (jsonObj[j].Patient_Last_Name === undefined) {
                errorData.push(
                  `Patient_Last_Name is required at column 2 row ${k}`
                )
                //   testData.push(' ')
              } else {
                lastName.push(jsonObj[j].Patient_Last_Name)
              }
              if (jsonObj[j].Phone_Number === undefined) {
                errorData.push(`Phone_Number is required at column 3 row ${k}`)
              } else {
                const newPhone = jsonObj[j].Phone_Number

                if (validateMobileNumber(newPhone.trim(), true).length === 0) {
                  phoneNo.push(
                    getCorrectedPhone(String(jsonObj[j].Phone_Number).trim())
                  )
                } else {
                  errorData.push('Please check the mobile number')
                  dispatch(showErrorAlert('Please check mobile number'))
                }
              }

              if (jsonObj[j].Appointment_Date === undefined) {
                errorData.push(
                  `Appointment Date is required at column 4 row ${k}`
                )
                //   description.push(' ')
              } else {
                const unixTimestamp =
                  (jsonObj[j].Appointment_Date - 25569) * 86400
                const date = moment.unix(unixTimestamp).format()

                if (
                  moment.unix(unixTimestamp).isValid() &&
                  moment
                    .unix(unixTimestamp)
                    .isAfter(moment(new Date()).startOf('day'))
                ) {
                  const converted = moment
                    .unix(unixTimestamp)
                    .format('DD-MM-YYYY')

                  if (
                    moment
                      .unix(unixTimestamp)
                      .isAfter(moment(new Date()).startOf('day'))
                  ) {
                    appointmentDate.push(converted)
                  } else
                    errorData.push(
                      `Appointment Date should be greater than today`
                    )
                } else {
                  errorData.push(
                    `Appointment Date is invalid at column 4 row ${k}`
                  )
                  appointmentDate.push('Invalid Date')
                }
              }

              k += 1
            }

            if (
              errorData.length > 0 ||
              phoneNo.length !== name.length ||
              phoneNo.length !== lastName.length ||
              phoneNo.length !== appointmentDate.length
            ) {
              dispatch(
                showErrorAlert(
                  'Data in the excel is not valid. Please check the data and try again'
                )
              )
            } else {
              let nameDataToDisPlay: string[] = []
              if (name.length > 5) {
                nameDataToDisPlay = name.slice(0, 5)
              } else {
                nameDataToDisPlay = name
              }
              const moreNameData = name.slice(5, name.length).length

              let lastNameDataToDisPlay: string[] = []
              if (lastName.length > 5) {
                lastNameDataToDisPlay = lastName.slice(0, 5)
              } else {
                lastNameDataToDisPlay = lastName
              }
              const moreLastNameData = lastName.slice(5, lastName.length).length

              let appointmentDateDisPlay: string[] = []
              if (appointmentDate.length > 5) {
                appointmentDateDisPlay = appointmentDate.slice(0, 5)
              } else {
                appointmentDateDisPlay = appointmentDate
              }
              const moreAppointmentDateData = appointmentDate.slice(
                5,
                appointmentDate.length
              ).length

              let phoneDisPlay: string[] = []
              if (phoneNo.length > 5) {
                phoneDisPlay = phoneNo.slice(0, 5)
              } else {
                phoneDisPlay = phoneNo
              }
              const morePhone = phoneNo.slice(5, phoneNo.length).length

              const nameJson = {
                ColumnName: columnData[0][0],
                Data: nameDataToDisPlay,
                moreData: moreNameData,
              }

              const lastNameJson = {
                ColumnName: columnData[0][1],
                Data: lastNameDataToDisPlay,
                moreData: moreLastNameData,
              }

              const phoneJson = {
                ColumnName: columnData[0][2],
                Data: phoneDisPlay,
                moreData: morePhone,
              }

              const appointmentDateJson = {
                ColumnName: columnData[0][3],
                Data: appointmentDateDisPlay,
                moreData: moreAppointmentDateData,
              }

              parsedArray.push(nameJson)
              parsedArray.push(phoneJson)
              parsedArray.push(lastNameJson)
              parsedArray.push(appointmentDateJson)

              //

              if (errorData.length === 0) {
                for (let m = 0; m < jsonObj.length; m++) {
                  const dateC = new Date(
                    Math.round(
                      (jsonObj[m].Appointment_Date - 25569) * 86400 * 1000
                    )
                  )
                  let finalDate = ''
                  if (moment(dateC).isValid()) {
                    finalDate = moment(dateC).format('YYYY-MM-DD')
                  }

                  const finalDateWithTime = finalDate.concat('T01:30:00.000Z')
                  const patientData: PatientPreAppointmentDataHelper = {
                    name: jsonObj[m].Patient_Name,
                    phone: getCorrectedPhone(jsonObj[m].Phone_Number),
                    lastName: jsonObj[m].Patient_Last_Name,
                    appointmentDate: finalDateWithTime,
                    appointmentType: 'IPD',
                  }
                  testData.push(patientData)
                }

                dispatch(uploadingPreAppointmentPatientData(testData))
                setErrorMessage(undefined)

                // const patientBundle = getFhirPatientBundleFromExcel(testData)
                // setDataSet(patientBundle)
              } else {
                dispatch(
                  showErrorAlert(
                    'Data in the excel is not valid. Please check the data and try again'
                  )
                )
              }
            }
          }
        }
      } else {
        dispatch(showErrorAlert('Please upload correct format'))
      }
    }

    if (rABS) {
      reader.readAsBinaryString(file)
    } else {
      reader.readAsArrayBuffer(file)
    }
  }

  useEffect(() => {
    if (bulkPatientAddSlice.error) {
      if (bulkPatientAddSlice.error) {
        setErrorMessage(bulkPatientAddSlice.errorMessage)
      }
      dispatch(resetBulkPatientAdd())
    }
  }, [bulkPatientAddSlice.error])

  useEffect(() => {
    if (bulkPatientAddSlice.uploadingSuccessful) {
      dispatch(resetBulkPatientAdd())
      onClose()
    }
  }, [bulkPatientAddSlice.uploadingSuccessful])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth={selectedFiles === undefined ? 'lg' : 'sm'}
      fullWidth
      PaperProps={{
        style: {
          backdropFilter: 'blur(4px)',
        },
      }}
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'> {t('Add Patients')}</DialogTitle>
      <DialogContent>
        <Box flexDirection='column' display='flex'>
          <Paper
            style={{ backgroundColor: '#F1F1F1' }}
            variant='outlined'
            square
          >
            <Box width='100%'>
              <WelloTabs
                preSelectedTab={selectedTab}
                onTabChange={(e) => handleChangeTab(e)}
                menu={menu}
              />
            </Box>
          </Paper>
          <Box padding={2} boxShadow={0}>
            {selectedFiles === undefined && (
              <DropZone
                fileType={fileType}
                fileValidationType={validTypes}
                onFileChange={(file: any) => {
                  setSelectedFiles(file)
                }}
                requiredColumns={requiredColumns}
              />
            )}

            {selectedFiles !== undefined && (
              <Box display='flex' flexDirection='column' maxWidth='300px'>
                <Typography variant='h6' color='primary'>
                  Selected file
                </Typography>
                <Box
                  display='flex'
                  flexDirection='row'
                  alignContent='center'
                  my={1}
                  p={1}
                  bgcolor='#F1F1F1'
                >
                  <Typography variant='subtitle1' color='initial'>
                    {selectedFiles.name}
                  </Typography>

                  <Box px={2}>
                    <IconButton
                      aria-label='remove selected file'
                      size='small'
                      color='secondary'
                      onClick={() => {
                        setSelectedFiles(undefined)
                        setErrorMessage(undefined)
                      }}
                    >
                      <CloseIcon fontSize='small' />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            )}

            {errorMessage && errorMessage.length > 0 && (
              <Box display='flex'>
                <Alert severity='error'>{errorMessage}</Alert>
              </Box>
            )}
          </Box>
          <Divider style={{ border: 1 }} />
        </Box>
      </DialogContent>
      <DialogActions>
        {bulkPatientAddSlice.uploading && <CircularProgress size={20} />}

        <Box>
          <Button
            onClick={() => {
              onClose()
            }}
            variant='outlined'
            disableElevation
            size='small'
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              validateExcel(selectedFiles)
            }}
            variant='contained'
            color='primary'
            disabled={
              selectedFiles === undefined || bulkPatientAddSlice.uploading
            }
            size='small'
          >
            Upload
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
