import { Box, Button, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { endClinicVisit } from 'redux/appointments/appointmentViewHandler/appointmentHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { getVendorPartId } from 'utils/routes_helper'
import { WelloLoadingIndicator } from 'wello-web-components'

interface NextStepsOfConsultationProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  disable: boolean
  OnNo: () => void
}

export const EndVisit: React.FC<NextStepsOfConsultationProps> = ({
  fhirAppointmentDetails,
  disable,
  OnNo,
}: NextStepsOfConsultationProps) => {
  const appointmentHandlerSlice = useSelector(
    (state: RootState) => state.appointmentHandlerSlice
  )
  const { t } = useTranslation(['common', 'mediction'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (appointmentHandlerSlice.endedAppointment) {
      window.location.href = `/${getVendorPartId()}/dashboard`
    }
  }, [appointmentHandlerSlice.endedAppointment, history])

  return (
    <Box display='flex' flexDirection='row'>
      {appointmentHandlerSlice.endingAppointment && (
        <WelloLoadingIndicator message='Finishing Appointment' />
      )}
      {!appointmentHandlerSlice.endingAppointment && (
        <Box display='flex' flexDirection='column'>
          <Box display='flex' flexDirection='row' padding={0.5} flexGrow>
            <Box py={1}>
              <Typography
                variant='h6'
                color='primary'
                style={{ fontSize: 18, fontWeight: 'bold' }}
              >
                {' '}
                {t('labelCommon:end_visit')}{' '}
              </Typography>
            </Box>
          </Box>
          <Box display='flex' flexDirection='column' p={0.5} flexGrow>
            <Box py={1}>
              <Typography variant='subtitle1'>
                {' '}
                Do you wish to end the appointment with the current patient?{' '}
              </Typography>
            </Box>
            <Box py={1} display='flex' flexDirection='row' flexGrow>
              <Button
                variant='contained'
                color='primary'
                id='yes'
                disabled={disable}
                onClick={() => {
                  if (appointmentHandlerSlice.appointmentDetails)
                    dispatch(
                      endClinicVisit(appointmentHandlerSlice.appointmentDetails)
                    )
                }}
              >
                Yes, End Appointment
              </Button>
              <Button
                variant='contained'
                id='no'
                color='primary'
                disabled={disable}
                onClick={() => {
                  OnNo()
                }}
              >
                No
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}
