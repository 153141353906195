import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import React from 'react'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'

interface OccupationTileProps {
  type?: string
}

export const CardHeaderSummaryTilePatient: React.FC<OccupationTileProps> = ({
  type,
}: OccupationTileProps) => {
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Grid container direction='row' spacing={1}>
        {/* ------------------------------------------------------------------------------- */}

        <Box display='flex' flexDirection='column' width='100%' flexGrow={1}>
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <Grid item xs={3}>
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height={30}
                alignItems='center'
                justifyContent='flex-start'
                px={0.5}
              >
                <Typography
                  variant='subtitle1'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Name
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={1}>
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height={30}
                alignItems='center'
                justifyContent='flex-start'
              >
                <Typography
                  variant='subtitle1'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  MRP (&#x20b9;)
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height={30}
                alignItems='center'
                justifyContent='flex-start'
              >
                <Typography
                  variant='subtitle1'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Quantity
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height={30}
                alignItems='center'
                justifyContent='flex-start'
              >
                <Typography
                  variant='subtitle1'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Doctor's Margin (&#x20b9;)
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height={30}
                alignItems='center'
                justifyContent='flex-start'
              >
                <Typography
                  variant='subtitle1'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Customer Discounted (%)
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height={30}
                alignItems='center'
                justifyContent='flex-start'
              >
                <Typography
                  variant='subtitle1'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Customer Discounted Price (&#x20b9;)
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Box>
  )
}
