/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-loop-func */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
} from '@material-ui/core'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { logger } from 'utils/logger'
import {
  fileTypeReport,
  menu,
  validReportType,
} from '../../../../utils/constants/labConstants'
import { WelloTabs } from '../../LeftMenu/WelloTabs'
import { UploadErrors } from '../common/uploadErrors'
import { ReportDataUploadSuccess } from './reportDataUploadSuccess'
import { UploadZone } from './uploadZone'

interface Props {
  open: boolean

  onClose: () => void
  onDiscard: () => void
  labOrderDetails?: FhirLabOrderDetail
}

export const UploadReport: React.FC<Props> = ({
  open,
  onClose,
  onDiscard,
  labOrderDetails,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(menu[0])
  const [selectedFiles, setSelectedFiles] = React.useState<any>()
  const [errors, setErrors] = React.useState<any>()
  const [columnsData, setColumnData] = React.useState<any>()
  const [showNextPage, setShowNextPage] = React.useState<boolean>(false)
  const [showContinue, setShowContinue] = React.useState<boolean>(false)
  const [show, setShow] = React.useState<boolean>(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleChangeTab = (selected: string) => {
    setSelectedTab(selected)
    setErrors(undefined)
  }

  const validateExcel = (files: any) => {
    setShow(true)
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader()
      const rABS = !!reader.readAsArrayBuffer
      reader.onload = (e) => {
        /* Parse data */
        const bstr = (e.target as FileReader).result
        if (bstr !== null) {
          logger.error('-------- te--------')
        }
      }
      if (rABS) {
        reader.readAsArrayBuffer(files[i])
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
      PaperProps={{
        style: {
          backdropFilter: 'blur(4px)',
        },
      }}
    >
      <DialogTitle id='scroll-dialog-title'>
        {' '}
        {t('labelCommon:report_upload_header_label')}
      </DialogTitle>
      <DialogContent>
        <Box flexDirection='column' display='flex'>
          <Paper
            style={{ backgroundColor: '#F1F1F1' }}
            variant='outlined'
            square
          >
            <Box width='100%'>
              <WelloTabs
                preSelectedTab={selectedTab}
                onTabChange={(e) => handleChangeTab(e)}
                menu={menu}
              />
            </Box>
          </Paper>
          <Box padding={2} boxShadow={0}>
            {selectedTab === menu[0] && showNextPage === false && (
              <UploadZone
                fileType={fileTypeReport}
                fileValidationType={validReportType}
                onFileChange={(files: any) => {
                  setSelectedFiles(files)
                }}
                labTestCount={
                  labOrderDetails && labOrderDetails.tests
                    ? labOrderDetails.tests.length
                    : 0
                }
              />
            )}

            {selectedTab === menu[0] &&
              columnsData &&
              showNextPage &&
              errors.length > 0 && <UploadErrors errorList={errors} />}
            {selectedTab === menu[0] && showNextPage && (
              <ReportDataUploadSuccess
                files={selectedFiles}
                labOrderDetails={labOrderDetails}
                onUpload={() => {
                  if (onClose) {
                    onClose()
                  }
                }}
              />
            )}
          </Box>
          <Divider style={{ border: 1 }} />
        </Box>
      </DialogContent>
      <DialogActions>
        {selectedTab === menu[0] && showNextPage === false && (
          <Box>
            <Button
              onClick={() => {
                onClose()
                setSelectedTab(menu[0])
              }}
              variant='outlined'
              disableElevation
              size='small'
            >
              {t('labelCommon:cancel')}
            </Button>
            <Button
              onClick={() => {
                setShowNextPage(true)
                // validateExcel(selectedFiles)
              }}
              variant='contained'
              color='primary'
              disabled={selectedFiles === undefined}
              size='small'
            >
              {t('labelCommon:continue')}
            </Button>
          </Box>
        )}

        {selectedTab === menu[0] && columnsData && showNextPage === false && (
          <Box>
            <Button
              onClick={() => {
                setSelectedFiles(undefined)
                setErrors(undefined)
                setColumnData(undefined)
                setShowContinue(false)
              }}
              variant='outlined'
              //   disabled={actorInvitationSetupSlice.adding}
              disableElevation
              size='small'
            >
              Use a different file
            </Button>
            <Button
              onClick={() => {
                setShowNextPage(true)
              }}
              variant='contained'
              color='primary'
              size='small'
              disabled={showContinue === false}
            >
              {t('labelCommon:continue')}
            </Button>
          </Box>
        )}

        {selectedTab === menu[0] && showNextPage && (
          <Box>
            <Button
              onClick={() => {
                setShowNextPage(false)
                setSelectedFiles(undefined)
                setErrors(undefined)
                setColumnData(undefined)
                setShowNextPage(false)
                setShowContinue(false)
                onClose()
              }}
              variant='contained'
              color='primary'
              size='small'
            >
              {t('labelCommon:done')}
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  )
}
