import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import ReactPanZoom from 'react-image-pan-zoom-rotate'
import { getContentOfDocumentReference } from 'services/fileServices'
import { AllPages } from './allPagePdfFile'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    medium: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    big: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  })
)

interface IProps {
  documentRef: R4.IDocumentReference
  showPreview: boolean
  onCloseClicked: () => void
}

export const DocumentReferencePopUp: React.FC<IProps> = ({
  documentRef,
  showPreview,
  onCloseClicked,
}: IProps) => {
  const [file, setFile] = useState<string>()
  const [fetching, setFetching] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const fileName = documentRef.content[0].attachment.title ?? 'NA'
  const type = documentRef.content[0].attachment.contentType!

  useEffect(() => {
    getContentOfDocumentReference(documentRef, '')
      .catch(() => {
        setError(true)
        setFetching(false)
        setFile(undefined)
      })
      .then((e) => {
        setError(false)
        setFetching(false)
        setFile(e!)
      })
  }, [])

  return (
    <Dialog
      maxWidth='lg'
      open={showPreview}
      onClose={() => {
        onCloseClicked()
      }}
    >
      <DialogTitle id='scroll-dialog-title'>{fileName}</DialogTitle>
      <DialogContent>
        <Box
          display='flex'
          justifyContent='center'
          alignContent='center'
          minWidth='400px'
          minHeight='500px'
        >
          {fetching && <CircularProgress />}
          {error && (
            <Typography variant='subtitle1' color='initial'>
              Error
            </Typography>
          )}
          {file && file.length > 0 && (
            <Box>
              {type.includes('pdf') && <AllPages pdf={file} />}
              {type.includes('image') && (
                <ReactPanZoom image={file} alt='document image' />
              )}
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}
