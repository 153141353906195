import { Box, Button, Grid, Typography } from '@material-ui/core'
import { kBackGround, kBackgroundPaper } from 'configs/styles/muiThemes'
import { PractitionerWorkHour } from 'models/businessHoursHelper'
import { ScheduleDetail } from 'models/fhirScheduleDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showWarningAlert } from 'redux/alertHandler/alertSlice'
import { addScheduleDetails } from 'redux/fhirSchedule/addAvailability'
import { RootState } from 'redux/rootReducer'
import { requestForSchedules } from 'redux/schedule/searchSchedule/SearchProviderSchedule'
import { updatingScheduleDetails } from 'redux/schedule/UpdateScheuleHandler/updateSchedule'
import { getCurrentUserUnitLocationDetails } from 'services/userDetailsService'
import { getBulkScheduleUpdateBundle } from 'utils/fhirResoureHelpers/fhirScheduleDeleteHelper'
import { Calendar } from 'views/components/schedule/Calendar'
import { CancelSchedule } from 'views/components/schedule/CancelSchedule'
import { ToggleOption } from 'views/components/single_toggleSelector'

interface UserDetailsProps {
  businessHoursData: PractitionerWorkHour[]
  onCalendarSelectionChanged?: (
    selectedValues: any[],
    existingSchedules: any[]
  ) => void
  selectedUser: PractitionerWithRole

  shoWFullWeek: boolean
  businessHours: PractitionerWorkHour
  hiddenDays: number[]
}

const appType = ['In-Person', 'Phone', 'Video']

const menu: ToggleOption[] = [
  { value: 'Profile', displayText: 'Profile' },
  { value: 'workHours', displayText: 'Working Hours' },
]

export const UserDetailsComponent: React.FC<UserDetailsProps> = ({
  businessHoursData,
  onCalendarSelectionChanged,
  selectedUser,

  shoWFullWeek,
  businessHours,
  hiddenDays,
}: UserDetailsProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [selectedDoctor, setSelectedDoctor] =
    React.useState<PractitionerWithRole>()
  const [tempDoctor, setTempDoctor] = React.useState<PractitionerWithRole>()
  const [settingsTab, setSelectedSettingsTab] = React.useState(menu[0].value)
  const [selectedSchedules, setSelectedSchedules] = React.useState<any>([])
  const [existingSchedules, setExistingSchedules] = React.useState<any>([])
  const [type, setType] = React.useState(appType[0])
  const [newAdd, SetNewAdd] = React.useState(false)
  const [cancel, SetCancel] = React.useState(false)
  const [openPopUp, SetOpenPopup] = React.useState(false)
  const [cancelPopUp, SetCancelPopup] = React.useState(false)

  const [showOnReturn, setShowOnReturn] = React.useState(false)
  const selectedProvider = useRef<PractitionerWithRole[]>()

  const scheduleData = useRef<ScheduleDetail[]>()
  const [saved, SetSaved] = React.useState(false)
  const scheduleArray: any = []

  if (onCalendarSelectionChanged) {
    onCalendarSelectionChanged(selectedSchedules, existingSchedules)
  }

  const SearchProviderScheduleSlice = useSelector(
    (state: RootState) => state.SearchProviderScheduleSlice
  )

  const addNew = () => {
    SetNewAdd(true)
    SetSaved(false)
  }

  const cancelSchedule = () => {
    if (selectedSchedules.length > 0) {
      SetCancelPopup(true)
    } else {
      setSelectedSchedules([])
      SetOpenPopup(false)
      SetNewAdd(false)
    }
  }

  const saveSchedule = () => {
    const locationDetails = getCurrentUserUnitLocationDetails()
    const scheduleList = SearchProviderScheduleSlice.scheduleList ?? []
    if (scheduleList.length === 0 && selectedSchedules.length === 0) {
      dispatch(showWarningAlert('Please select working hours'))
    } else {
      if (selectedUser !== undefined)
        dispatch(
          addScheduleDetails(
            locationDetails,
            scheduleList,
            selectedSchedules,
            selectedUser,
            type
          )
        )
      setSelectedSchedules([])
      SetNewAdd(false)
      SetSaved(true)
      requestSearchSchedule()
    }
  }

  function onDoctorChange(doctorSelected: any) {
    selectedProvider.current = doctorSelected
    requestSearchSchedule()
  }

  function getName(name: string): string {
    const changedName = name.replace('Dr. ', '')
    return changedName
  }

  function requestSearchSchedule() {
    dispatch(requestForSchedules(selectedProvider.current, new Date()))
    scheduleData.current = SearchProviderScheduleSlice.scheduleList
  }
  return (
    <Box
      display='flex'
      flexDirection='column'
      width='100%'
      height='100%'
      justifyContent='center'
      alignContent='center'
    >
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        justifyContent='space-between'
        alignContent='center'
        alignItems='center'
      >
        <Grid
          container
          direction='row'
          alignContent='space-between'
          justify='space-between'
        >
          <Grid item xs={12}>
            <Grid container justify='flex-end'>
              <Box display='flex' paddingX={2} p>
                {newAdd === false && (
                  <Box paddingX={1}>
                    <Button
                      variant='contained'
                      size='small'
                      color='primary'
                      onClick={() => {
                        addNew()
                      }}
                      id='editButton'
                    >
                      <Typography variant='button'>
                        {(SearchProviderScheduleSlice.scheduleList ??
                          [].length > 0) ||
                        selectedSchedules.length > 0
                          ? t('labelCommon:edit_button_text')
                          : t('labelCommon:addWorkHours')}
                      </Typography>
                    </Button>
                  </Box>
                )}

                {newAdd === true && (
                  <Box paddingX={1} display='flex'>
                    <Box m={0.4}>
                      <Button
                        variant='contained'
                        size='small'
                        style={{
                          backgroundColor: kBackGround,
                          color: kBackgroundPaper,
                        }}
                        onClick={() => {
                          cancelSchedule()
                        }}
                        id='cancel'
                      >
                        <Typography variant='button'>
                          {t('labelCommon:cancel')}
                        </Typography>
                      </Button>
                    </Box>
                    <Box m={0.4}>
                      <Button
                        variant='contained'
                        size='small'
                        color='primary'
                        onClick={() => {
                          saveSchedule()
                        }}
                        id='saveButton'
                      >
                        <Typography variant='button'>
                          {' '}
                          {t('labelCommon:Save')}
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        height='100%'
        id='calendarView'
        style={{ overflow: 'auto', maxHeight: '100%' }}
      >
        <Calendar
          onCalendarSelectionChanged={(
            selectedValues: any[],
            existSchedules: any[]
          ) => {
            SetSaved(false)
            requestSearchSchedule()

            setSelectedSchedules(selectedValues)
            if (existSchedules.length > 0) {
              existSchedules.forEach((event) => {
                scheduleArray.push(event)
              })
              setExistingSchedules(scheduleArray)
            }
            SetCancel(true)
          }}
          onSelectAppointmentType={(appointmentType: string) => {
            setType(appointmentType)
          }}
          editable={newAdd}
          selectedDoctor={selectedUser}
          discard={cancel}
          // selectedSchedules={selectedSchedules}
          scheduleExist={existingSchedules.length > 0}
          saved={saved}
          businessHoursData={businessHoursData}
          businessHours={businessHours}
          shoWFullWeek={shoWFullWeek}
          hiddenDays={hiddenDays}
        />
      </Box>

      {openPopUp === true && (
        <CancelSchedule
          open={openPopUp}
          onClose={() => {
            SetOpenPopup(false)
            SetCancel(false)
            setSelectedSettingsTab(menu[1].value)
            setShowOnReturn(true)
          }}
          onDiscard={() => {
            setSelectedDoctor(tempDoctor)
            SetOpenPopup(false)
            SetCancel(true)
            SetNewAdd(false)
            setSelectedSettingsTab(menu[0].value)
            setSelectedSchedules([])
            onDoctorChange(tempDoctor)

            if (existingSchedules.length > 0) {
              const scheduleBundle =
                getBulkScheduleUpdateBundle(existingSchedules)

              dispatch(updatingScheduleDetails(scheduleBundle))
            }
            setExistingSchedules([])
          }}
        />
      )}

      <CancelSchedule
        open={cancelPopUp}
        onClose={() => {
          SetCancelPopup(false)
          SetCancel(false)
        }}
        onDiscard={() => {
          // setSelectedDoctor(tempDoctor)
          SetCancelPopup(false)
          SetCancel(true)
          SetNewAdd(false)
          //   setSelectedSettingsTab(menu[0].value)
          setSelectedSchedules([])
          // onDoctorChange(tempDoctor)
          if (existingSchedules.length > 0) {
            const scheduleBundle =
              getBulkScheduleUpdateBundle(existingSchedules)
            dispatch(updatingScheduleDetails(scheduleBundle))
          }
          setExistingSchedules([])
        }}
      />
    </Box>
  )
}
