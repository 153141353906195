import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { requestMicturitionHistoryOfPatient } from 'redux/ipd/patientMedicalHistory/micturitionHistory/micturitionSearchSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { sleep } from 'utils/dateUtil'
import { getVitalsObservationObjectForOPD } from 'utils/fhirResoureHelpers/observationHelpers'
// import { requestVitalDetailsOfPatientIPD } from '../vitalsDetailsIpd/vitalsDetailSliceIpd'
import { MicturitionAddStatus } from './micturitionAddStatus'

const initialState: MicturitionAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const micturitionAddSliceOPD = createSlice({
  name: 'micturitionAddSliceOPD',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<MicturitionAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addedVitalsBundle = action.payload.addedVitalsBundle
    },

    resetAddVitalsDetails(state, action: PayloadAction<MicturitionAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addedVitalsBundle = initialState.addedVitalsBundle
    },
  },
})

export const addMicturitionOPD =
  (
    appointment: FhirAppointmentDetail,
    notes: string,
    diet?: R4.ICoding
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: MicturitionAddStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(micturitionAddSliceOPD.actions.updateAddVitalsStatus(addingState))

    try {
      const bundleObject: R4.IBundle = createBundleObjectForObservationsMic(
        appointment,
        notes,
        diet
      )

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        bundleObject
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        await sleep(5000)

        dispatch(
          requestMicturitionHistoryOfPatient('', appointment.patient, false)
        )
        dispatch(showSuccessAlert('Micturition Added Successfully'))

        dispatch(
          micturitionAddSliceOPD.actions.updateAddVitalsStatus(addingState)
        )
      } else {
        const errorCreatePersonState: MicturitionAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while adding Micturition',
        }
        dispatch(
          micturitionAddSliceOPD.actions.updateAddVitalsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: MicturitionAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while adding Micturition',
      }
      dispatch(
        micturitionAddSliceOPD.actions.updateAddVitalsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetAddMicturition = () => (dispatch: AppDispatch) => {
  dispatch(micturitionAddSliceOPD.actions.resetAddVitalsDetails(initialState))
}

export function createBundleObjectForObservationsMic(
  appointment: FhirAppointmentDetail,
  notes: string,
  diet?: R4.ICoding
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const encounterRef: R4.IReference = {
    reference: `Encounter/urn:uuid:1232323232324`,
    type: 'Encounter',
  }

  if (diet != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForOPD(appointment, encounterRef),
    }
    observationObject.extension = [
      {
        url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
        valueReference: {
          reference: `Appointment/${appointment.appointment.id!}`,
        },
      },
    ]

    observationObject.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationSocialHistory',
      ],
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Micturition',
      coding: [
        {
          code: '252041008',
          display: 'Micturition',
          system: 'http://snomed.info/sct',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'social-history',
            display: 'Social History',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: diet.display ?? '',
      coding: [diet],
    }

    const practitionerRoleDetail: R4.IPractitionerRole =
      getCurrentUserPractitionerRoleDetails()

    const practitioner: R4.IPractitioner = getCurrentUserPractitionerDetails()

    if (notes.length > 0) {
      observationObject.note = [
        {
          authorReference: {
            reference: `${practitioner.resourceType}/${practitioner.id}`,
          },
          text: notes,
          time: new Date().toISOString(),
        },
      ]
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  return requestBundle
}

export default micturitionAddSliceOPD.reducer
