import { Divider } from '@mantine/core'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { MembershipDetails } from 'models/membershipDetails'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { searchAvailablePlanDetails } from 'redux/administration/management/availableMemberships/availableMembershipTypesSlice'
import { getAllMembershipSubscriptions } from 'redux/administration/membership/membershipSubscribersList/membershipSubscribersListSlice'
import { RootState } from 'redux/rootReducer'
import { isCurrentUnitIsClinic } from 'services/userDetailsService'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { AddMembershipForm } from './addMembershipForm'
import { MembershipDetailsPage } from './memberdhipDetails'
import { MembershipDataTable } from './membershipDataTable'

// import EditTherapy from './editTherapy';

export interface MembershipSearchProps {
  onTap?: () => void
  onAdd?: () => void
}

export const MembershipSearch: React.FC<MembershipSearchProps> = ({
  onTap,
  onAdd,
}) => {
  const threapyCatalogSearchSlice = useSelector(
    (state: RootState) => state.membershipSubscribersListSlice
  )

  const [selectedMembership, setselectedMembership] =
    useState<MembershipDetails>()
  const dispatch = useDispatch()

  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  useEffect(() => {
    if (onAdd) onAdd()
    dispatch(searchAvailablePlanDetails())
    dispatch(getAllMembershipSubscriptions())
  }, [dispatch])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
          width: '100%',
        }}
      >
        <Box
          display='flex'
          flexGrow={1}
          flexDirection='column'
          width='100%'
          height='100%'
          justifyContent='center'
          alignContent='center'
          paddingTop={1}
          style={{ overflow: 'auto', maxHeight: '100%' }}
        >
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='space-between'
            alignContent='center'
            alignItems='center'
            overflow='hidden'
            padding={1}

            // flexGrow={1}
          >
            <Typography
              variant='h6'
              color='primary'
              style={{
                fontFamily: 'Open Sans',

                color: 'black',

                fontWeight: 'bold',
                lineHeight: 'normal',
                marginTop: 8,
                marginBottom: 8,
              }}
            >
              Membership
            </Typography>
            {!threapyCatalogSearchSlice.searchingTreatment && (
              <Box
                display='flex'
                flexGrow={1}
                flexDirection='row'
                justifyContent='end'
                alignContent='center'
                overflow='auto'
                px={1}
              >
                <Button
                  variant='contained'
                  color='primary'
                  disabled={isCurrentUnitIsClinic()}
                  onClick={() => {
                    setShowPopup(true)
                  }}
                >
                  Add New Membership
                </Button>
              </Box>
            )}
          </Box>
          <Divider />
          {threapyCatalogSearchSlice.searchingTreatment && (
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
              height='100%'
              width='100%'
            >
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            </Box>
          )}
          <Box
            display='flex'
            flexGrow={4}
            flexDirection='column'
            justifyContent='center'
            alignContent='center'
            overflow='auto'
            style={{
              backgroundColor: '#ececec',
            }}
          >
            {threapyCatalogSearchSlice.noResultsAvailable && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='100%'
                height='100%'
                alignContent='center'
              >
                <Typography variant='subtitle1' color='initial' align='center'>
                  No User Data Available.
                </Typography>
              </Box>
            )}
            {threapyCatalogSearchSlice.errorWhileSearchingTreatment && (
              <Box
                //   display='flex'
                //   flexDirection='column'
                justifyContent='center'
                width='100%'
                height='100%'
                alignContent='center'
                paddingLeft={50}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container justifyContent='center'>
                      <Typography
                        variant='subtitle1'
                        color='error'
                        align='center'
                      >
                        Error while searching Memberships.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            {threapyCatalogSearchSlice.resultsAvailable && (
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height='100%'
                overflow='auto'
                // flexGrow={1}
              >
                <Box
                  display='flex'
                  flexGrow={1}
                  height='100%'
                  overflow='hidden'
                  //  paddingTop={0.2}
                >
                  <MembershipDataTable
                    onMembershipSelected={(membership: MembershipDetails) => {
                      setselectedMembership(membership)
                    }}
                    displayOnlyName={selectedMembership !== undefined}
                    usersData={
                      threapyCatalogSearchSlice.therapyListWithPrice
                        ? threapyCatalogSearchSlice.therapyListWithPrice
                        : []
                    }
                    selectedMembership={selectedMembership}
                  />
                </Box>
                {selectedMembership && <Divider orientation='vertical' />}
                {selectedMembership && (
                  <Box
                    display='flex'
                    flexGrow={1}
                    overflow='auto'
                    width='70%'
                    bgcolor='white'
                  >
                    <MembershipDetailsPage
                      onCloseClicked={() => {
                        setselectedMembership(undefined)
                      }}
                      usersData={selectedMembership}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>

          {showPopup && (
            <AddMembershipForm
              open={showPopup}
              onBackClick={() => {
                setShowPopup(false)
                dispatch(getAllMembershipSubscriptions())
              }}
              onContinueClick={() => {
                setShowPopup(false)
                dispatch(getAllMembershipSubscriptions())
              }}
            />
          )}
        </Box>
      </Paper>
    </div>
  )
}
