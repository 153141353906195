import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography } from '@material-ui/core'
import SpaIcon from '@material-ui/icons/Spa'
import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { isOrgAdmin } from 'services/userDetailsService'
import {
  getAgeOfPatientData,
  getIdentifierValueBySystem,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
  isMarried,
} from 'utils/fhirResourcesHelper'
import { getRoomNoFromLocation } from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
// import { getNameOfPatient } from 'wello-web-components'

interface Props {
  appointmentDetails: R4.IPatient
  serviceRequest: R4.IServiceRequest
  location?: R4.ILocation
  roomDetails?: string
}

export const PatientNameInIPDWidget: React.FC<Props> = ({
  appointmentDetails,
  serviceRequest,
  location,
  roomDetails,
}: Props) => {
  const dispatch = useDispatch()
  const patientSubscriptionsSlice = useSelector(
    (state: RootState) => state.patientSubscriptionsSlice
  )

  const appointmentType = serviceRequest.code
    ? serviceRequest.code.coding
      ? serviceRequest.code.coding[0].code ?? ''
      : ''
    : ''
  const ipdDayCare: string =
    appointmentType === '304903009'
      ? 'Day Care'
      : appointmentType === '33022008'
      ? 'Consultation'
      : ''

  //   useEffect(() => {
  //     dispatch(getHyperTensionSubscriptionsOfPatient(appointmentDetails))
  //   }, [])
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignContent='start'
      justifyContent='start'
    >
      <Box display='flex' flexDirection='row'>
        {getMiddleNameOfPatient(appointmentDetails).length > 0 && (
          <Typography
            variant='h6'
            style={{
              textTransform: 'capitalize',
              color: 'black',
              fontWeight: 'bold',
            }}
          >
            {`${getNameOfPatient(appointmentDetails)} ${
              getMiddleNameOfPatient(appointmentDetails) ?? ''
            }  ${getLastNameOfPatient(appointmentDetails)}`}
          </Typography>
        )}
        {getMiddleNameOfPatient(appointmentDetails).length === 0 && (
          <Typography
            variant='h6'
            style={{
              textTransform: 'capitalize',
              color: 'black',
              fontWeight: 'bold',
            }}
          >
            {`${getNameOfPatient(appointmentDetails)} 
            ${getLastNameOfPatient(appointmentDetails)}`}
          </Typography>
        )}
        {patientSubscriptionsSlice.resultsAvailable &&
          patientSubscriptionsSlice.hCarePlans &&
          patientSubscriptionsSlice.hCarePlans.length > 0 && (
            <Box px={1}>
              <SpaIcon
                color='primary'
                style={{
                  fontSize: '20px',
                }}
              />
            </Box>
          )}
      </Box>
      <Typography variant='subtitle1' style={{}}>
        {isMarried(appointmentDetails.maritalStatus) ? 'Married, ' : 'Single, '}{' '}
        {getAgeOfPatientData(appointmentDetails)}
      </Typography>
      <Box display='flex' flexDirection='column'>
        {ipdDayCare.length === 0 && (
          <Typography variant='subtitle1' style={{ fontWeight: 600 }}>
            {getIdentifierValueBySystem(
              serviceRequest.identifier ?? [],
              'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
            )}
          </Typography>
        )}
        {ipdDayCare.length > 0 && (
          <Typography variant='subtitle1' style={{ fontWeight: 600 }}>
            {getIdentifierValueBySystem(
              serviceRequest.identifier ?? [],
              'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
            )}
          </Typography>
        )}
        {ipdDayCare.length === 0 && (
          <Box>
            <Typography variant='subtitle1' style={{}}>
              DOA :{' '}
              {moment(serviceRequest.occurrencePeriod?.start).format(
                'DD-MM-YYYY'
              )}
            </Typography>
            <Typography variant='subtitle1' style={{}}>
              DOD :{' '}
              {moment(serviceRequest.occurrencePeriod?.end).format(
                'DD-MM-YYYY'
              )}
            </Typography>

            <Box>
              {location && (
                <Typography variant='subtitle1' color='initial'>
                  {`${getRoomNoFromLocation(location)}`}
                </Typography>
              )}
            </Box>

            {isOrgAdmin() && roomDetails && (
              <Box>
                <Typography variant='subtitle2' color='initial'>
                  {roomDetails}
                </Typography>
              </Box>
            )}
          </Box>
        )}
        {ipdDayCare.length > 0 && (
          <Box>
            <Typography variant='subtitle1' style={{}}>
              {ipdDayCare}
            </Typography>
          </Box>
        )}

        {ipdDayCare.length > 0 && (
          <Box>
            <Typography variant='subtitle1' style={{}}>
              Appointment Date :{' '}
              {moment(serviceRequest.occurrencePeriod?.start).format(
                'DD-MM-YYYY'
              )}
            </Typography>
          </Box>
        )}

        {ipdDayCare.length > 0 && (
          <Box>
            <Typography variant='subtitle1' style={{}}>
              {moment(serviceRequest.occurrencePeriod?.start).format('hh:mm A')}{' '}
              - {moment(serviceRequest.occurrencePeriod?.end).format('hh:mm A')}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}
