import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

interface Props {
  id: string
  itemName: String
  price: number
  days: number
}
export const IpdPriceDisplayData: React.FC<Props> = ({
  id,
  itemName,
  price,
  days,
}: Props) => {
  const { t } = useTranslation()

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while displaying price</div>
          <pre>{error.message}</pre>
        </div>
      )}
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        width='100%'
      >
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          width='100%'
        >
          <Box
            display='flex'
            flexDirection='column'
            flexGrow={1}
            maxWidth='400px'
          >
            <Typography
              variant='body2'
              color='initial'
              style={{
                fontWeight: 600,
              }}
              id={`${id}_text_room_type`}
            >
              Room Type: {itemName}
            </Typography>
          </Box>

          <Box display='flex' flexDirection='column'>
            <Typography variant='body2' color='initial' id={`${id}_text_price`}>
              {`INR ${price.toFixed(2)} /day`}
            </Typography>
          </Box>
        </Box>

        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          width='100%'
          py={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            flexGrow={1}
            maxWidth='400px'
          >
            <Typography
              variant='body2'
              color='initial'
              style={{
                fontWeight: 600,
              }}
              id={`${id}_text_no_of_days`}
            >
              No of Days
            </Typography>
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
          >
            <Typography variant='body2' color='initial' id={`${id}_text_days`}>
              {days === 0 || days === 1 ? `${1} day` : `${days} days`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </ErrorBoundary>
  )
}
