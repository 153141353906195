/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react'
import { DaysInSingleLetter } from 'utils/constants/calender_constants'
import {
  Typography,
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  Switch,
  Grid,
  withStyles,
  IconButton,
} from '@material-ui/core'

import { OperationalHoursOfDay } from 'models/operationalHoursOfDay'
import { useTranslation } from 'react-i18next'
import {
  GetTitleKeyForFhirDay,
  updateDaySelectionSettings,
} from 'utils/fhirResoureHelpers/calender_helper'
import { Add, Delete } from '@material-ui/icons'
import {
  kBackgroundDefault,
  kBackgroundPaper,
  kDialogueBackground,
  kPrimaryMain,
} from 'configs/styles/muiThemes'
import { TimePicker } from '@material-ui/pickers'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { constants } from 'zlib'
import moment from 'moment'

interface TabProps {
  selectionOfDay: OperationalHoursOfDay
  onSelectionChanges: (val: OperationalHoursOfDay) => void
  commonControl?: boolean
  isReadMode?: boolean
  onAddRemoveChanges: (val: OperationalHoursOfDay) => void
}

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: '#9E9DDC',
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)

export const BusinessHour: React.FC<TabProps> = ({
  selectionOfDay,
  onSelectionChanges,
  commonControl = false,
  isReadMode = false,
  onAddRemoveChanges,
}: TabProps) => {
  const { t } = useTranslation()
  const [operationDay, setOperationDay] =
    useState<OperationalHoursOfDay>(selectionOfDay)

  function onAddClicked() {
    const operationOfDay: OperationalHoursOfDay = { ...operationDay }
    const endTime = moment(
      operationOfDay.timings[operationOfDay.timings.length - 1]
        .availableEndTime,
      'H:mma'
    ).add(2, 'hours')
    operationOfDay.timings.push({
      id: getUniqueTempId(),
      availableStartTime:
        operationOfDay.timings[operationOfDay.timings.length - 1]
          .availableEndTime,
      availableEndTime: `${endTime.format('HH')}:00`,
    })
    onSelectionChanges(operationOfDay)
    setOperationDay(operationOfDay)
  }
  function onRemoveClicked(id: string | undefined) {
    const operationOfDay: OperationalHoursOfDay = { ...operationDay }
    if (id) {
      const times: any[] = operationOfDay.timings.filter((e) => e.id !== id)
      operationOfDay.timings = times
      onSelectionChanges(operationOfDay)
      setOperationDay(operationOfDay)
    }
  }
  function onStartTimeChange(index: number, value: string) {
    const opDay: OperationalHoursOfDay = { ...operationDay }
    opDay.timings[index].availableStartTime = value
    onSelectionChanges(opDay)
    setOperationDay(opDay)
  }

  function onEndTimeChange(index: number, value: string) {
    const opDay: OperationalHoursOfDay = { ...operationDay }
    opDay.timings[index].availableEndTime = value
    onSelectionChanges(opDay)
    setOperationDay(opDay)
  }

  const handleChange = (event: any) => {
    const opDay: OperationalHoursOfDay = { ...operationDay }
    if (event.target.checked === true) {
      opDay.available = true
      opDay.dayOfWeek = event.target.name
      if (opDay.timings.length === 0) {
        opDay.allDayOpen = true
        opDay.timings = [
          {
            id: getUniqueTempId(),
            availableStartTime: '09:00',
            availableEndTime: '23:00',
          },
        ]
      }
    } else {
      opDay.available = false
      opDay.allDayOpen = false
      opDay.timings = []
    }
    onAddRemoveChanges(opDay)
    setOperationDay(opDay)
  }

  function handleCheckBoxChange(checked: boolean) {
    const opDay: OperationalHoursOfDay = { ...operationDay }
    if (checked) {
      opDay.allDayOpen = true
      opDay.timings = [
        {
          id: getUniqueTempId(),
          availableStartTime: '00:00:00',
          availableEndTime: '23:59:59',
        },
      ]
    } else {
      opDay.allDayOpen = false
      opDay.available = true
      opDay.timings = [
        {
          id: getUniqueTempId(),
          availableStartTime: '09:00',
          availableEndTime: '15:00',
        },
      ]
    }
    onAddRemoveChanges(opDay)
    setOperationDay(opDay)
  }

  return (
    <Box display='flex' flexDirection='row' width='100%' py={0.5}>
      <Grid container direction='row'>
        <Grid item xs={3}>
          <Box width='30%' px={3} py={1.5}>
            <Typography
              variant='subtitle2'
              color='textPrimary'
              style={{
                textTransform: 'uppercase',
              }}
            >
              {commonControl
                ? 'Every Business Day'
                : t(
                    'labelCommon:' +
                      `${GetTitleKeyForFhirDay(operationDay.dayOfWeek)}`
                  )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box px={3} py={1.5} display='flex' width='20%' flexGrow={1}>
            <Box display='flex'>
              <Typography component='div'>
                <Grid
                  component='label'
                  container
                  alignItems='center'
                  spacing={1}
                >
                  <Grid item xs={9}>
                    <AntSwitch
                      checked={operationDay.available}
                      onChange={handleChange}
                      name={operationDay.dayOfWeek}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {operationDay.available
                      ? `${t('labelCommon:open')}`
                      : `${t('labelCommon:close')}`}
                  </Grid>
                </Grid>
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={5}>
          {operationDay.available && (
            <Box px={2.8} py={1.5} width='100%'>
              <FormControlLabel
                label={t('labelCommon:all_day')}
                control={
                  <Checkbox
                    checked={operationDay.allDayOpen}
                    onChange={(e) => handleCheckBoxChange(e.target.checked)}
                    name='checkedB'
                    color='primary'
                    size='small'
                    style={{ padding: '0px', paddingLeft: '8px' }}
                  />
                }
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container direction='row'>
        <Grid item xs={12}>
          {!operationDay.allDayOpen && (
            <Box display='flex' flexDirection='column'>
              {operationDay.timings.map((eachTiming, i) => (
                <Box
                  id={`box_${eachTiming}`}
                  display='flex'
                  flexDirection='row'
                  justifyContent='center'
                  px={1}
                  alignItems='baseline'
                  key={`key_${eachTiming.id}`}
                >
                  <Box px={1} flexDirection='row' alignSelf='center'>
                    {!isReadMode && (
                      <TextField
                        id='start_time'
                        size='small'
                        color='primary'
                        onChange={(e) => {
                          onStartTimeChange(i, e.target.value)
                        }}
                        type='time'
                        variant='outlined'
                        InputProps={{
                          inputProps: {
                            disableunderline: 'true',
                            min: '08:00',
                            max: '23:59',
                          },
                        }}
                        value={eachTiming.availableStartTime}
                      />
                    )}
                  </Box>
                  <Box
                    p={1}
                    flexDirection='row'
                    alignSelf='center'
                    alignItems='baseline'
                  >
                    <Typography> {t('labelCommon:to')} </Typography>
                  </Box>

                  <Box
                    p={1}
                    flexDirection='row'
                    alignSelf='center'
                    alignItems='baseline'
                  >
                    {!isReadMode && (
                      <TextField
                        id='end_time'
                        style={{
                          color: kPrimaryMain,
                        }}
                        onChange={(e) => {
                          onEndTimeChange(i, e.target.value)
                        }}
                        size='small'
                        color='primary'
                        InputLabelProps={{
                          shrink: true,
                          color: 'primary',
                        }}
                        variant='outlined'
                        type='time'
                        InputProps={{
                          inputProps: {
                            min: eachTiming.availableStartTime,
                            max: '23:59',
                            color: kPrimaryMain,
                            step: 300,
                          },
                        }}
                        value={eachTiming.availableEndTime}
                      />
                    )}
                  </Box>
                  {(commonControl || isReadMode) && (
                    <Box>
                      {/* <Button
                    hidden={true}
                    style={{
                      visibility: 'hidden',
                    }}
                    disabled={operationDay.allDayOpen}
                    onClick={onAddClicked}
                    id={`btn_add_newTime${operationDay.dayOfWeek}`}
                  >
                    <Add />
                  </Button> */}

                      <IconButton
                        aria-label='delete'
                        style={{ paddingLeft: 10 }}
                      >
                        <Add />
                      </IconButton>
                    </Box>
                  )}
                  {i !== 0 && !commonControl && !isReadMode && (
                    <Box>
                      <IconButton
                        aria-label='delete'
                        style={{ paddingLeft: 10 }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/deleteButton.png`}
                          alt='delete'
                          height={30}
                          width={30}
                          onClick={() => {
                            onRemoveClicked(eachTiming.id ?? i.toString())
                          }}
                        />
                      </IconButton>
                      {/* <Button
                    onClick={() => {
                      onRemoveClicked(eachTiming.id ?? i.toString())
                    }}
                    id={`delete_newTime${operationDay.dayOfWeek}_${i}`}
                  >
                    <Delete />
                  </Button> */}
                    </Box>
                  )}
                  {i === 0 && !commonControl && !isReadMode && (
                    <Box>
                      <IconButton
                        aria-label='delete'
                        style={{ paddingLeft: 10 }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/addButton.png`}
                          alt='delete'
                          height={30}
                          width={30}
                          onClick={onAddClicked}
                        />
                      </IconButton>

                      {/* <Button
                    onClick={onAddClicked}
                    id={`btn_add_newTime${operationDay.dayOfWeek}`}
                  >
                    <Add />
                    addButton
                  </Button> */}
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
