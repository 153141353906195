import { R4 } from '@ahryman40k/ts-fhir-types'
import { Grid, Typography, Box } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirReferralInsightDetails } from 'models/fhirPrescriptionDetails'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isAyurvedaDoctor } from 'services/userDetailsService'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'

import { GeneralExaminationHistory } from './objectiveHistory.tsx/generalExamHistory'
import { LabResultsHistory } from './objectiveHistory.tsx/LabTestHistory'
import { ObesityObjectiveHistory } from './objectiveHistory.tsx/obesityObjectiveHistory'
import { VitalsHistory } from './objectiveHistory.tsx/vitalHistory'

interface ObjectivePartOfConsultationProps {
  referralDetails?: FhirReferralInsightDetails
  patientData: R4.IPatient
}

export const ReferralObjectivePartOfConsultation: React.FC<ObjectivePartOfConsultationProps> =
  ({ referralDetails, patientData }: ObjectivePartOfConsultationProps) => {
    const { t } = useTranslation(['common'])
    const [addButton, showAddButton] = useState<boolean>(false)

    return (
      <Grid
        container
        spacing={1}
        direction='column'
        style={{ flexWrap: 'nowrap' }}
      >
        <Grid item xs={12}>
          {' '}
          <Box display='flex' width='90%'>
            <GeneralExaminationHistory
              generalExamList={
                referralDetails ? referralDetails.generalExamData ?? [] : []
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          {' '}
          <Box display='flex' width='90%'>
            <VitalsHistory
              vitalList={referralDetails ? referralDetails.vitals ?? [] : []}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          {' '}
          <Box display='flex' width='90%'>
            <LabResultsHistory
              patient={patientData}
              labTest={
                referralDetails ? referralDetails.labResults.labTest ?? [] : []
              }
              cbcPanelData={
                referralDetails ? referralDetails.labResults.panel1 ?? [] : []
              }
              panel2={
                referralDetails ? referralDetails.labResults.panel2 ?? [] : []
              }
              panel3={
                referralDetails ? referralDetails.labResults.panel3 ?? [] : []
              }
              lftResults={
                referralDetails ? referralDetails.labResults.lft ?? [] : []
              }
              rftResults={
                referralDetails ? referralDetails.labResults.rft ?? [] : []
              }
              tftResults={
                referralDetails ? referralDetails.labResults.tftPanel ?? [] : []
              }
              homaResults={
                referralDetails ? referralDetails.labResults.homa ?? [] : []
              }
              dexaResults={
                referralDetails ? referralDetails.labResults.dexa ?? [] : []
              }
              serumLytes={
                referralDetails
                  ? referralDetails.labResults.serumLytes ?? []
                  : []
              }
              panel4Results={
                referralDetails
                  ? referralDetails.labResults.urinePanel ?? []
                  : []
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          {' '}
          <Box display='flex' width='90%'>
            <ObesityObjectiveHistory
              obsList={
                referralDetails
                  ? referralDetails.anthropometricMeasurements ?? []
                  : []
              }
            />
          </Box>
        </Grid>
      </Grid>
    )
  }
