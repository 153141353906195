import { R4 } from '@ahryman40k/ts-fhir-types'
import { FhirSlotDetail } from 'models/fhirSlotDetail'

// eslint-disable-next-line @typescript-eslint/comma-dangle
export enum RescheduleActions {
  SlotSelection,
  Confirmation,
}

export interface RescheduleAppointmentManagementStatus {
  currentAction: RescheduleActions
  enableContinueButton: boolean
  selectedSlot?: FhirSlotDetail
  selectedPatient?: R4.IPatient
  creatingAppointment: boolean
  appointmentCreatedSuccessfully: boolean
  createdAppointment?: R4.IAppointment
  errorWhileCreatingAppointment: boolean
  errorReason?: string
}
