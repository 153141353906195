import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource } from 'axios'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import {
  getGenderOfDoctor,
  getNameFromHumanName,
} from 'utils/fhirResourcesHelper'
import { getPatientFromContainedResource } from 'utils/fhirResoureHelpers/referralHelpers'
import { isNumber } from 'utils/stringUtils'
import { IncomingReferralsListStatus } from './incomingReferralsListStatus'

const initialState: IncomingReferralsListStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingOrders: false,
  selectedStatuses: ['active'],
}

let currentSelectedDate: Date | undefined

const incomingReferralsSlice = createSlice({
  name: 'incomingReferralsSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<IncomingReferralsListStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorWhileSearchingOrders = action.payload.errorWhileSearchingOrders
      state.selectedStatuses = action.payload.selectedStatuses
      state.filteredList = action.payload.filteredList
      state.baseList = action.payload.baseList
      state.pageState = action.payload.pageState
      state.recordsCount = action.payload.recordsCount
    },
  },
})

export const requestForIncomingReferralsLists =
  (
    selectedStatus?: string[],
    pageState?: string,
    offSet?: number,
    existingDateWiseData?: R4.IServiceRequest[],

    recordsCount?: number,
    gender?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: IncomingReferralsListStatus = {
      searchingAppointments: true,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      selectedStatuses: selectedStatus ?? [],

      baseList: existingDateWiseData,
      pageState,
      recordsCount,
    }
    dispatch(incomingReferralsSlice.actions.updatedStatus(state))
    try {
      const unitId = getCurrentUserUnitReference()?.reference?.split('/')[1]
      currentSelectedDate?.setSeconds(new Date().getSeconds())
      const searchParams: any = {
        perPageCount: 1000,
        unitId,
      }

      if (pageState && offSet) {
        searchParams.stateId = pageState
        searchParams.offSetNumber = offSet
      }
      searchParams.gender = getGenderOfDoctor(
        getCurrentUserPractitionerDetails()
      )
      if (selectedStatus && selectedStatus.length > 0) {
        searchParams.status = selectedStatus.join(',')
      }

      const response: any = await getIpdSListForTherapist(
        searchParams.startDate,
        searchParams.status,
        searchParams.perPageCount,
        searchParams.stateId,
        searchParams.offSetNumber,
        searchParams.unitId
      )

      if (response !== undefined) {
        const resp: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (resp._tag === 'Left') {
          state.errorWhileSearchingOrders = true
          state.searchingAppointments = false

          dispatch(incomingReferralsSlice.actions.updatedStatus(state))
        } else {
          const appointmentResponse: R4.IBundle = resp.right
          if (appointmentResponse?.total && appointmentResponse?.total > 0) {
            const fhirAppointments: R4.IServiceRequest[] =
              appointmentResponse.entry?.map(
                (e) => e.resource as R4.IServiceRequest
              ) ?? []

            state.pageState = appointmentResponse.id
            state.recordsCount = appointmentResponse.total
            state.resultsAvailable = true
            state.searchingAppointments = false

            state.baseList = [
              ...(existingDateWiseData ?? []),
              ...(fhirAppointments ?? []),
            ]
            state.filteredList = [
              ...(existingDateWiseData ?? []),
              ...(fhirAppointments ?? []),
            ]
            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingOrders = false
            dispatch(incomingReferralsSlice.actions.updatedStatus(state))
          } else {
            const errorSearchDoctor: IncomingReferralsListStatus = {
              searchingAppointments: false,
              errorWhileSearchingOrders: false,
              resultsAvailable: false,
              noResultsAvailable: true,
              selectedStatuses: selectedStatus ?? [],

              baseList: existingDateWiseData,
              filteredList: existingDateWiseData,
              pageState,
              recordsCount,
            }
            dispatch(
              incomingReferralsSlice.actions.updatedStatus(errorSearchDoctor)
            )
          }
        } /* */
      }
    } catch (error) {
      if (error) {
        if ((error as any).message) {
          if ((error as any).message === 'new param added') {
            return
          }
        }
      }
      const errorSearchDoctor: IncomingReferralsListStatus = {
        searchingAppointments: false,
        errorWhileSearchingOrders: true,
        resultsAvailable: false,
        errorReason: 'Error while fetching therapies',
        selectedStatuses: selectedStatus ?? [],

        baseList: existingDateWiseData,
        filteredList: existingDateWiseData,
        pageState,
        recordsCount,
      }
      dispatch(incomingReferralsSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export const searchReferrals =
  (
    existingState: IncomingReferralsListStatus,
    searchQuery?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let filteredList = existingState.baseList

    if (
      searchQuery !== undefined &&
      searchQuery !== null &&
      searchQuery.length > 0
    ) {
      if (isNumber(searchQuery)) {
        const finalSearchQuery = `+91${searchQuery.trim()}`
        if (searchQuery.length >= 10) {
          filteredList = [...(existingState.baseList ?? [])]?.filter((item) => {
            const patientTelecom = getPatientFromContainedResource(
              item
            )?.telecom?.find((telecom) => telecom.system === 'phone')?.value
            return patientTelecom?.includes(finalSearchQuery)
          })
        }
      } else {
        filteredList = [...(existingState.baseList ?? [])]?.filter((item) => {
          const patientName = getNameFromHumanName(
            getPatientFromContainedResource(item)!.name ?? []
          )
          return patientName.toLowerCase().includes(searchQuery.toLowerCase())
        })
      }
    }

    const state: IncomingReferralsListStatus = {
      ...existingState,
      filteredList,
    }
    dispatch(incomingReferralsSlice.actions.updatedStatus(state))
  }
async function getIpdSListForTherapist(
  startDate: string,
  status?: string,
  perPageCount?: number,
  stateId?: string,
  pageNumber?: number,
  organizationId?: string
): Promise<R4.IBundle | R4.IOperationOutcome | undefined> {
  axios.CancelToken.source()
  if (cancelTokenStore.has('therapiesSearchControlToken')) {
    const controlTokenForSearch: CancelTokenSource = cancelTokenStore.get(
      'therapiesSearchControlToken'
    )
    controlTokenForSearch.cancel('new param added')
    cancelTokenStore.delete('therapiesSearchControlToken')
  }
  cancelTokenStore.set(
    'therapiesSearchControlToken',
    axios.CancelToken.source()
  )
  try {
    const fhirClient: FHIRApiClient = new FHIRApiClient()

    if (stateId && perPageCount && pageNumber) {
      const searchParameters: any = {
        _getpages: stateId,
        _getpagesoffset: pageNumber,
        _count: perPageCount,
      }

      const r = await fhirClient.doGetResourceIncludeAndIncludeIterate(
        '/',
        searchParameters,
        (
          cancelTokenStore.get(
            'therapiesSearchControlToken'
          ) as CancelTokenSource
        ).token
      )

      return r
    }

    let searchParameters: any = {}

    if (perPageCount === 0) {
      searchParameters = {
        _count: 0,
        intent: 'proposal',

        code: 'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-service-type-cs|wellness-therapy-referral',
        status: status ?? 'active',
      }
    } else {
      searchParameters = {
        _count: perPageCount ?? 600,

        code: 'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-service-type-cs|wellness-therapy-referral',
        status: status ?? 'active,cancelled',
        _sort: '-authored',
      }
    }

    const r = await fhirClient.doGetResourceIncludeAndIncludeIterate(
      '/ServiceRequest',
      searchParameters
    )

    const completeDate = r

    return completeDate
  } catch (error) {
    throw error
  }

  return undefined
}

export default incomingReferralsSlice.reducer
