import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { logger } from 'utils/logger'
import { OrderCountStatus } from './orderCountStatus'

const initialState: OrderCountStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
  count: 0,
}

const orderCountForAgent = createSlice({
  name: 'orderCountForAgent',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<OrderCountStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.count = action.payload.count
      state.errorReason = action.payload.errorReason

      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const requestOrdersCountForTodayAgent =
  (selectedDate?: Date): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: OrderCountStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      count: 0,
    }
    dispatch(orderCountForAgent.actions.updatedStatus(state))
    try {
      const practRole = getCurrentUserPractitionerRoleDetails()
      const searchParameters: any = {
        owner: `PractitionerRole/${practRole.id}`,
      }

      if (selectedDate) {
        searchParameters.occurrence = `ge${moment(selectedDate)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ssZ')}`
      }
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doGetResource(
        `/Task?code=home-sample-collection`,
        searchParameters
      )

      const resp: R4.IBundle = response as R4.IBundle
      if (!resp) {
        state.errorWhileSearchingAppointments = true
        state.searchingAppointments = false

        dispatch(orderCountForAgent.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          state.resultsAvailable = true
          state.searchingAppointments = false
          state.count = appointmentResponse.total
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingAppointments = false
          dispatch(orderCountForAgent.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: OrderCountStatus = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            count: 0,
          }
          dispatch(orderCountForAgent.actions.updatedStatus(errorSearchDoctor))
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: OrderCountStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
        count: 0,
      }
      dispatch(orderCountForAgent.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default orderCountForAgent.reducer
