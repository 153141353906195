import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirLabDiagnosticRequest } from 'models/fhirLabDiagnosticRequest'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import {
  getImagingDiagnosticCodingsFromBundle,
  getLabDiagnosticRequestFromBundle,
  getTestsFromDiagnostics,
  getTestsFromTasks,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import { logger } from 'utils/logger'
import { ImagingDiagnosticsListSliceStatus } from './imagingDiagnosticsListStatus'

const initialState: ImagingDiagnosticsListSliceStatus = {
  fetchingImpressions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  updatedImpressions: false,
  updatingImpressions: false,
  errorWhileSearchingImpressions: false,
  errorWhileUpdatingImpressions: false,
}

const imagingDiagnosticsListSliceIPD = createSlice({
  name: 'imagingDiagnosticsListSliceIPD',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<ImagingDiagnosticsListSliceStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingImpressions = action.payload.fetchingImpressions
      state.resultsAvailable = action.payload.resultsAvailable
      state.lanDiagnosticRequests = action.payload.lanDiagnosticRequests
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingImpressions =
        action.payload.errorWhileUpdatingImpressions
      state.tasks = action.payload.tasks
      state.updatedImpressions = action.payload.updatedImpressions
      state.updatingImpressions = action.payload.updatingImpressions
      state.diagnosticTestsLoincCodes = action.payload.diagnosticTestsLoincCodes
      state.errorWhileSearchingImpressions =
        action.payload.errorWhileSearchingImpressions
      state.serviceRequest = action.payload.serviceRequest
    },
  },
})

export const getImagingRequestsOfIPD =
  (
    fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole,
    followUp?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: ImagingDiagnosticsListSliceStatus = { ...initialState }
    state.fetchingImpressions = true
    dispatch(imagingDiagnosticsListSliceIPD.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        encounter: fhirAppointmentDetails.mainEncounter?.id,

        category: '363679005',
      }

      //   const taskSearchParameters: any = {
      //     'focus:ActivityDefinition.title': 'Task Lab Order',
      //     encounter: `Encounter/${fhirAppointmentDetails.encounter?.id}` ?? '',
      //     'status:': 'requested',
      //   }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/ServiceRequest',
        '',
        searchParameters
      )

      //   const taskResponse: any = await fhirClient.doGetResource(
      //     '/Task',
      //     taskSearchParameters
      //   )
      //   logger.info(
      //     '-----------------------------SE-----REQ----------------------------------------------',
      //     response
      //   )
      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)

      //   const taskResp: E.Either<Errors, R4.IBundle> =
      //     R4.RTTI_Bundle.decode(taskResponse)

      const imagingResponse: R4.IBundle = response
      const task: R4.ITask[] = []
      // if (taskResp._tag === 'Right') {
      //   task = (taskResp.right.entry ?? []).map((e) => e.resource as R4.ITask)
      // }
      if (
        (imagingResponse?.total && imagingResponse?.total > 0) ||
        (task && task.length > 0)
      ) {
        let imagingCodes: R4.ICoding[] = []
        const serviceRequestData: R4.IServiceRequest[] = []

        if (
          imagingResponse &&
          imagingResponse.entry &&
          imagingResponse.entry.length > 0
        ) {
          for (let i = 0; i < imagingResponse.entry.length; i++) {
            serviceRequestData.push(
              imagingResponse.entry[i].resource as R4.IServiceRequest
            )
          }
          imagingCodes = getImagingDiagnosticCodingsFromBundle(imagingResponse)
        }
        if (
          (imagingCodes && imagingCodes.length > 0) ||
          (task && task.length > 0) ||
          (serviceRequestData && serviceRequestData.length > 0)
        ) {
          state.resultsAvailable = true
          state.fetchingImpressions = false
          state.lanDiagnosticRequests = imagingCodes
          state.tasks = task
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingImpressions = false
          state.serviceRequest = serviceRequestData
          state.diagnosticTestsLoincCodes = imagingCodes.map(
            (coding) => coding.code ?? ''
          )
          dispatch(imagingDiagnosticsListSliceIPD.actions.updatedStatus(state))
        }
      } else {
        const errorSearchDoctor: ImagingDiagnosticsListSliceStatus = {
          ...initialState,
        }
        errorSearchDoctor.noResultsAvailable = true
        dispatch(
          imagingDiagnosticsListSliceIPD.actions.updatedStatus(
            errorSearchDoctor
          )
        )
      }
    } catch (error) {
      const errorSearchDoctor: ImagingDiagnosticsListSliceStatus = {
        ...initialState,
      }
      errorSearchDoctor.errorReason = 'Error while fetching assessment details'
      errorSearchDoctor.errorWhileSearchingImpressions = false
      dispatch(
        imagingDiagnosticsListSliceIPD.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }
export default imagingDiagnosticsListSliceIPD.reducer
