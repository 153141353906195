import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Card, CardContent, Divider, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import { requestForRoomPrice } from 'redux/clinic/ipdmanagement/roomPriceSearch/roomPriceSearchSlice'
import { RootState } from 'redux/rootReducer'
import { WelloLoadingIndicator } from 'wello-web-components'
import { IPDNetAmount } from '../ipdNetAmount'
import { IPDTotalAmount } from '../ipdTotalAmount'
import { DayCarePriceDisplay } from './dayCarePriceDisplayData'

interface Props {
  showTitle?: boolean
  days: number
  discount: number
  onPriceFetched: (ChargeDef: R4.IChargeItemDefinition) => void
}
export const DayCarePriceSummary: React.FC<Props> = ({
  showTitle = true,
  days,
  discount,
  onPriceFetched,
}: Props) => {
  const dispatch = useDispatch()

  const priceSearchslice = useSelector(
    (state: RootState) => state.rommPriceSearchSlice
  )

  useEffect(() => {
    dispatch(requestForRoomPrice('dayCare'))
  }, [dispatch])

  useEffect(() => {
    if (priceSearchslice.resultsAvailable && priceSearchslice.price) {
      if (onPriceFetched) {
        onPriceFetched(priceSearchslice.price)
      }
    }
  }, [priceSearchslice, onPriceFetched])

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
        </div>
      )}
    >
      <Card
        elevation={0}
        style={{
          width: '100%',
        }}
      >
        <CardContent>
          <Box display='flex' flexDirection='column' width='100%'>
            {/* {showTitle && (
              <Box display='flex' flexDirection='row' width='100%'>
                <Typography variant='h6' color='initial'>
                  Price Summary
                </Typography>
              </Box>
            )} */}

            {priceSearchslice.searchingPrice && (
              <WelloLoadingIndicator message='Fetching Price' />
            )}

            {priceSearchslice.resultsAvailable && (
              <Box display='flex' flexDirection='row ' width='100%'>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    alignContent='flex-end'
                    justifyContent='space-between'
                  >
                    <DayCarePriceDisplay
                      price={priceSearchslice.availablePrice}
                      days={days}
                    />
                  </Box>

                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    paddingTop={1}
                  >
                    <Divider
                      orientation='horizontal'
                      color='#6B6B6B'
                      variant='fullWidth'
                      style={{
                        backgroundColor: 'black',
                        color: '#6B6B6B',
                        width: '100%',
                      }}
                    />
                  </Box>

                  <Box display='flex' flexDirection='row' width='100%' py={1}>
                    <IPDTotalAmount
                      id='202'
                      currency='INR'
                      totalAmount={priceSearchslice.availablePrice}
                      days={1}
                      discount={discount}
                    />
                  </Box>

                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    paddingTop={1}
                  >
                    <Divider
                      orientation='horizontal'
                      color='#6B6B6B'
                      variant='fullWidth'
                      style={{
                        backgroundColor: 'black',
                        color: '#6B6B6B',
                        width: '100%',
                      }}
                    />
                  </Box>

                  <Box display='flex' flexDirection='row' width='100%' py={1}>
                    <IPDNetAmount
                      id='401'
                      currency='INR'
                      totalAmount={priceSearchslice.availablePrice}
                      days={1}
                      discount={discount}
                    />
                  </Box>
                </Box>
              </Box>
            )}

            {/* {planDefinitions && planDefinitions.length > 0 && (
              <Box display='flex' flexDirection='row ' width='100%'>
                <Box display='flex' flexDirection='column' width='100%'>
                  {planDefinitions?.map(
                    (eachOffering) =>
                      eachOffering.chargeItem && (
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          alignContent='flex-end'
                          justifyContent='space-between'
                          id={`ord_test_item_${eachOffering.id}`}
                          key={`ord_test_item_key_${eachOffering.id}`}
                        >
                          <ItemOrderSummary
                            chargeItemDefinition={eachOffering.chargeItem}
                            itemName={eachOffering.planDefinition.title ?? ''}
                          />
                        </Box>
                      )
                  )}

                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    paddingTop={1}
                  >
                    <Divider
                      orientation='horizontal'
                      color='#6B6B6B'
                      variant='fullWidth'
                      style={{
                        backgroundColor: 'black',
                        color: '#6B6B6B',
                        width: '100%',
                      }}
                    />
                  </Box>

                  <Box display='flex' flexDirection='row' width='100%'>
                    <TotalAmount
                      currency='INR'
                      totalAmount={getTotalAmountOfLabOfferings(
                        planDefinitions
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            )} */}
          </Box>
        </CardContent>
      </Card>
    </ErrorBoundary>
  )
}
