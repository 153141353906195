import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from 'redux/store'
import { UmlClient } from 'services/umlsClient'
import { getUserCurrentRole } from 'services/userDetailsService'
import { ExcretionSystemConditionCodes } from 'utils/constants/systems/excretion_ststem_types'
import { logger } from 'utils/logger'
import { RespiratoryConditionsSearchTypes as CardiovascularConditionsSearchTypes } from './excretoryConditionsSearchTypes'

const initialState: CardiovascularConditionsSearchTypes = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const excretoryConditionsSearchSlice = createSlice({
  name: 'excretoryConditionsSearchSlice',
  initialState,
  reducers: {
    searchingCondition(
      state,
      action: PayloadAction<CardiovascularConditionsSearchTypes>
    ) {},

    searchResults(
      state,
      action: PayloadAction<CardiovascularConditionsSearchTypes>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<CardiovascularConditionsSearchTypes>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
    },

    errorWhileSearching(
      state,
      action: PayloadAction<CardiovascularConditionsSearchTypes>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
    },
    resetState(
      state,
      action: PayloadAction<CardiovascularConditionsSearchTypes>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.surgeryList = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: CardiovascularConditionsSearchTypes = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      surgeryList: undefined,
    }
    dispatch(excretoryConditionsSearchSlice.actions.resetState(state))
  }

export const searchCardioVascularConditions =
  (searchString: string, selectOther: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: CardiovascularConditionsSearchTypes = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      excretoryConditionsSearchSlice.actions.errorWhileSearching(
        errorSearchPatient
      )
    )
    try {
      const fhirClient: UmlClient = new UmlClient()
      const currentRole = getUserCurrentRole() ?? []
      /* let response: any = await fhirClient.doGetResource(
        `umls/ValueSetMatcher?Path=cardiovascular&Match=${searchString}`
      ) */
      let response: any = ExcretionSystemConditionCodes.filter((e) =>
        (e.display ?? '').toLowerCase().includes(searchString.toLowerCase())
      )
      if (response.length > 0) {
        const searchPatientResult: CardiovascularConditionsSearchTypes = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          surgeryList: response,
          totalCount: response.length,
        }
        dispatch(
          excretoryConditionsSearchSlice.actions.searchResults(
            searchPatientResult
          )
        )
        return
      }

      if (response.length === 0 && currentRole.includes('ayurveda')) {
        /*  response = await fhirClient.doGetResource(
          `/umls/AyurvedaMatcherCondition?Path=conditions&Match=${searchString}`
        ) */
        response = ExcretionSystemConditionCodes.filter((e) =>
          (e.display ?? '').toLowerCase().includes(searchString.toLowerCase())
        )

        const searchPatientResultDatanew: CardiovascularConditionsSearchTypes =
          {
            error: false,
            noResultsAvailable: false,
            resultsAvailable: true,
            searching: false,
            surgeryList: response,
            totalCount: response.length,
          }
        dispatch(
          excretoryConditionsSearchSlice.actions.searchResults(
            searchPatientResultDatanew
          )
        )
        return
      }

      const noSearchResults: CardiovascularConditionsSearchTypes = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(
        excretoryConditionsSearchSlice.actions.noDataFoundForSearch(
          noSearchResults
        )
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: CardiovascularConditionsSearchTypes = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        excretoryConditionsSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(excretoryConditionsSearchSlice.actions.resetState(initialState))
}

export default excretoryConditionsSearchSlice.reducer
