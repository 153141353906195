import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Typography,
  useTheme,
  IconButton,
} from '@material-ui/core'
import { Visibility } from '@material-ui/icons'

import { CPGForTable } from 'models/administration/cpgDetailsForTable'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  getAvailableDurationForPlanDef,
  getChargeItemDefinitionFromList,
  getCPSubscriptionsListForWPAdmin,
  getCPSubscriptionsOrgAndUnitAdmin,
  offerSubscriptionInUnit,
  removeOfferSubscriptionInUnit,
} from 'utils/admisnistration/cpg/cpgListHelper'
import { getDurationOfChargeItemDef } from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'

interface Props {
  planDefId: string
  selectedDurationId: string
}

export const SubscriptionDurationText: React.FC<Props> = ({
  planDefId,
  selectedDurationId,
}: Props) => {
  const [fetchAvailableDurationListStatus, fetchAvailableDurationList] =
    useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })

  const [selectedDuration, setSelectedDuration] =
    useState<R4.IChargeItemDefinition | undefined>()

  function fetchCPGLists() {
    fetchAvailableDurationList({ requesting: true })
    getAvailableDurationForPlanDef(planDefId).then((e) => {
      if (e !== false) {
        fetchAvailableDurationList({
          requesting: false,
          requestSuccess: true,
        })
        console.log('----------------updated cpg list----------------', e)
        setSelectedDuration(
          getChargeItemDefinitionFromList(
            e as R4.IChargeItemDefinition[],
            selectedDurationId
          )
        )
      } else {
        fetchAvailableDurationList({
          requesting: false,
          requestError: false,
        })
      }
    })
  }

  useEffect(() => {
    fetchCPGLists()
  }, [])

  return (
    <Box>
      {fetchAvailableDurationListStatus.requestSuccess && selectedDuration && (
        <Typography> {getDurationOfChargeItemDef(selectedDuration)}</Typography>
      )}

      {fetchAvailableDurationListStatus.requestSuccess &&
        selectedDuration === undefined && <Typography>NA</Typography>}

      {fetchAvailableDurationListStatus.requesting &&
        selectedDuration === undefined && <Typography>...</Typography>}
      {fetchAvailableDurationListStatus.initial &&
        selectedDuration === undefined && <Typography>...</Typography>}

      {fetchAvailableDurationListStatus.requestError &&
        selectedDuration === undefined && <Typography>error</Typography>}
    </Box>
  )
}
