/* eslint-disable no-empty */
/* eslint-disable no-constant-condition */
import { R4 } from '@ahryman40k/ts-fhir-types'

import { BillingDetails } from 'models/BillingDetails'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import {
  PaymentDetail,
  SplitPaymentDetails,
} from 'models/splitPayment/splitPaymentDetails'
import moment from 'moment-timezone'
import print from 'print-js'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { uploadFileDetails } from 'services/fileApiService'
import { encodeBlobToBase4 } from 'services/fileServices'
import { paymentTypes } from 'utils/constants'
import {
  getCodeOfSystemFromCodableConcept,
  getDefaultCodeOfSystemFromCodableConcept,
  getExtensionValueOfUrl,
  getIdentifierValueBySystemForPayment,
  getValueCodableFromExtension,
} from 'utils/fhirResourcesHelper'
import { dataURItoBlob } from 'utils/fileHelper'
import { logger } from 'utils/logger'
import { SelectedFile } from 'views/components/LeftMenu/WelloFilePicker'
import {
  addLineItems,
  deleteLineItems,
  discountCalculationHelperFunction,
  modifyQuantityOfInvoice,
} from 'wello-web-components'
// import  from 'moment-timezone'
import { getSymbolForCurrency } from './currency_helper'

/* get date of line items */
export function getDateOfLineItem(
  lineItem: R4.IInvoice_LineItem
): string | undefined {
  const datExt: R4.IExtension | undefined = getExtensionValueOfUrl(
    lineItem.extension,
    'http://wellopathy.com/fhir/india/core/StructureDefinition/invoice-time'
  )
  if (datExt && datExt.valueDateTime) {
    return moment(datExt.valueDateTime).format('YYYY-MM-DD')
  }
  return undefined
}

/* get name of line items */
export function getNameOfLineItem(lineItem: R4.IInvoice_LineItem): string {
  if (lineItem.chargeItemReference) {
    if (lineItem.chargeItemReference.display) {
      return lineItem.chargeItemReference.display
    }
  }
  if (lineItem.chargeItemCodeableConcept) {
    if (
      lineItem.chargeItemCodeableConcept.text &&
      lineItem.chargeItemCodeableConcept.text.length > 0
    ) {
      return lineItem.chargeItemCodeableConcept.text
    }
    if (
      lineItem.chargeItemCodeableConcept.coding &&
      lineItem.chargeItemCodeableConcept.coding.length > 0
    ) {
      return ''
    }
  }
  return ''
}

/* get base price of line item */
export function getBasePriceOfLineItem(lineItem: R4.IInvoice_LineItem): number {
  if (lineItem.priceComponent && lineItem.priceComponent.length > 0) {
    const res = lineItem.priceComponent.findIndex((e) => e.type === 'base')

    if (res >= 0 && lineItem.priceComponent[res].amount) {
      const amount = lineItem.priceComponent[res].amount?.value ?? 0
      return Number(amount.toFixed(2))
    }
  }

  return 0.0
}

/* get base price of line item text */
export function getBasePriceOfLineItemText(
  lineItem: R4.IInvoice_LineItem
): string {
  if (lineItem.priceComponent && lineItem.priceComponent.length > 0) {
    const res = lineItem.priceComponent.findIndex((e) => e.type === 'base')
    const symbol =
      res >= 0
        ? getSymbolForCurrency(
            lineItem.priceComponent![res]?.amount?.currency ?? ''
          )
        : ''
    if (res >= 0 && lineItem.priceComponent[res].amount) {
      const amount = lineItem.priceComponent[res].amount?.value ?? 0
      return `${symbol} ${amount.toFixed(2)}`
    }
  }
  const symbol = getSymbolForCurrency('')

  return `${symbol} 0.00`
}

/* get quantity of line item text */
export function getQuantityOfLineItem(lineItem: R4.IInvoice_LineItem): number {
  if (lineItem.priceComponent && lineItem.priceComponent.length > 0) {
    const res = lineItem.priceComponent?.findIndex((e) => e.type === 'base')
    if (res >= 0 && lineItem.priceComponent[res]) {
      return lineItem.priceComponent[res].factor ?? 1
    }
  }

  return 0
}

/* get discount amount as string */
export function getDiscountAmountInString(
  test: R4.IInvoice_LineItem[]
): number {
  let discountAmt: number = 0
  test.forEach((e) => {
    if (e.priceComponent && e.priceComponent.length > 0) {
      const res =
        e.priceComponent?.findIndex((l) => l.type === 'discount') ?? -1
      if (res >= 0) {
        const priceComp: R4.IInvoice_PriceComponent = e.priceComponent[res]
        const amt: R4.IMoney = priceComp.amount!
        discountAmt += amt.value ?? 0
      }
    }
  })

  return discountAmt
}

/* get discount percentageVal as */
export function getDiscountPercentageWithLineItem(
  test: R4.IInvoice_LineItem[]
): number {
  let discountAmt: number = 0
  test.forEach((e) => {
    if (e.priceComponent && e.priceComponent.length > 0) {
      const res =
        e.priceComponent?.findIndex((l) => l.type === 'discount') ?? -1
      if (res >= 0) {
        const priceComp: R4.IInvoice_PriceComponent = e.priceComponent[res]
        if (priceComp.factor && priceComp.factor > 0) {
          discountAmt =
            priceComp.factor && priceComp.factor > 0
              ? priceComp.factor * 100
              : 0
        }
      }
    }
  })

  return Number(discountAmt.toFixed(2))
}

/* get discount amount as string for UI display */
export function getDiscountAmountInStringForUi(
  test: R4.IInvoice_LineItem[]
): string {
  let discountAmt: number = 0
  test.forEach((e) => {
    if (e.priceComponent && e.priceComponent.length > 0) {
      const res =
        e.priceComponent?.findIndex((l) => l.type === 'discount') ?? -1
      if (res >= 0) {
        const priceComp: R4.IInvoice_PriceComponent = e.priceComponent[res]
        const amt: R4.IMoney = priceComp.amount!
        discountAmt += amt.value ?? 0
      }
    }
  })

  return discountAmt.toFixed(2)
}

/* get discount amount for initial data */
export function getDiscountAmountForInitialData(
  test: R4.IInvoice_LineItem[]
): number {
  let discountAmt: number = 0
  test.forEach((e) => {
    if (e.priceComponent && e.priceComponent.length > 0) {
      const res =
        e.priceComponent?.findIndex((l) => l.type === 'discount') ?? -1
      if (res >= 0) {
        const priceComp: R4.IInvoice_PriceComponent = e.priceComponent[res]
        if (priceComp.factor && priceComp.factor > 0) {
          discountAmt = priceComp.factor ? priceComp.factor * 100 : 0
        }
      }
    }
  })

  return Number(discountAmt.toFixed(2))
}

/* get discount type data */
export function getPaymentType(
  test: R4.IInvoice_LineItem[]
): R4.ICoding | undefined {
  let resultData: R4.ICoding | undefined
  for (let i = 0; i < test.length; i++) {
    const priceCompData = test[i].priceComponent
    if (priceCompData && priceCompData.length > 0) {
      const res = priceCompData.findIndex((l) => l.type === 'discount') ?? -1
      if (res >= 0) {
        const priceComp: R4.IInvoice_PriceComponent = priceCompData[res]

        if (priceComp.code && priceComp.code.coding) {
          const paymentType: R4.ICoding[] = priceComp.code.coding ?? []
          if (paymentType.length > 0) {
            const paymentTypeData: string = paymentType[0].code
              ? paymentType[0].code
              : ''
            if (paymentTypeData.trim() === '%') {
              resultData = {
                code: 'percent',
                display: '%',
              }
            } else if (paymentTypeData.trim() === '[arb’U]') {
              resultData = {
                code: 'amount',
                display: 'amount',
              }
            } else {
              resultData = undefined
            }
          }
        }
      }
    }
  }

  return resultData
}

/* get discount type data in boolean */
export function getPaymentTypeForCondition(
  test: R4.IInvoice_LineItem[]
): boolean {
  for (let i = 0; i < test.length; i++) {
    const priceCompData = test[i].priceComponent
    if (priceCompData && priceCompData.length > 0) {
      const res = priceCompData.findIndex((l) => l.type === 'discount') ?? -1
      if (res >= 0) {
        const priceComp: R4.IInvoice_PriceComponent = priceCompData[res]

        if (priceComp.code && priceComp.code.coding) {
          const paymentType: R4.ICoding[] = priceComp.code.coding ?? []
          if (paymentType.length > 0) {
            const paymentTypeData: string = paymentType[0].code
              ? paymentType[0].code
              : ''
            if (paymentTypeData.trim() === '%') {
              return true
            }
          }
        }
      }
    }
  }

  return false
}

/* get discount amount updated after changing line item data */
export function getDiscountAmountInStringForUpdate(
  grandTotal: number,
  amount: number,
  discountInPercentage: number
): number {
  const totalAmount = grandTotal + amount

  return (totalAmount * discountInPercentage) / 100
}

/* get discount amount updated after deleting line item data */
export function getDiscountAmountInStringForUpdateDelete(
  grandTotal: number,
  amount: number,
  discountInPercentage: number
): number {
  const totalAmount = grandTotal

  return (totalAmount * discountInPercentage) / 100
}
/* get discount in percentage updated changing line item data */
export function getDiscountAmountPercentageUpdate(
  grandTotal: number,
  amount: number,
  discount: number
): number {
  const totalAmount = grandTotal

  return Number(((discount * 100) / totalAmount).toFixed(2))
}

/* get discount in percentage updated after deleting line item data */
export function getDiscountAmountInPercentageDelete(
  grandTotal: number,
  amount: number,
  discount: number
): number {
  const totalAmount = grandTotal
  return Number(((discount * 100) / totalAmount).toFixed(2))
}

export function getNetPayableAmount(
  lineItem: R4.IInvoice_LineItem[],
  discount: number,
  percentage: number,
  advancePayment?: SplitPaymentDetails[],
  split?: SplitPaymentDetails[]
): number {
  let advance: number = 0
  let splitPayment: number = 0
  let res: number = 0
  lineItem.forEach((e) => {
    res = getTotalOfLineItem(e) + res
  })

  if (advancePayment) {
    advance = getTotalPaymentFromSplit(advancePayment)
  }
  if (split) {
    splitPayment = getTotalPaymentFromSplit(split)
  }

  if (percentage > 0) {
    return (
      getGrandTotalPayableAmount(lineItem) -
      getGrandTotalPayableAmountWithPercentage(lineItem, percentage)
    )
  }
  return (
    getGrandTotalPayableAmount(lineItem) - (discount + advance + splitPayment)
  )
}

export function getPayableForMembership(
  lineItem: R4.IInvoice_LineItem[],
  discount: number,
  percentage: number,
  advancePayment?: SplitPaymentDetails[],
  split?: SplitPaymentDetails[]
): number {
  let advance: number = 0
  let splitPayment: number = 0
  let res: number = 0
  const lineItemDetails: R4.IInvoice_LineItem[] = []
  for (let i = 0; i < lineItem.length; i++) {
    const charge = lineItem[i].chargeItemCodeableConcept
    const chargePrice = lineItem[i].priceComponent ?? []
    const codeData: R4.ICodeableConcept | undefined =
      chargePrice.length > 0 ? chargePrice[0].code : undefined

    const codeDataFinal: R4.ICoding[] = codeData ? codeData.coding ?? [] : []

    if (lineItem[i].chargeItemCodeableConcept === undefined) {
      if (
        lineItem[i].chargeItemReference &&
        lineItem[i].chargeItemReference?.display !== 'Attendant Charges'
      ) {
        lineItemDetails.push(lineItem[i])
      }
    } else if (charge && charge.coding && charge.coding[0].code !== 'UNK') {
      lineItemDetails.push(lineItem[i])
    }
  }

  lineItemDetails.forEach((e) => {
    res = getTotalOfLineItem(e) + res
  })

  if (advancePayment) {
    advance = getTotalPaymentFromSplit(advancePayment)
  }
  if (split) {
    splitPayment = getTotalPaymentFromSplit(split)
  }

  if (percentage > 0) {
    return (
      getGrandTotalPayableAmount(lineItemDetails) -
      getGrandTotalPayableAmountWithPercentage(lineItemDetails, 0)
    )
  }
  return getGrandTotalPayableAmount(lineItemDetails) - (advance + splitPayment)
}

export function getPayableForMembershipForAdd(
  lineItem: R4.IInvoice_LineItem[],
  discount: number,
  percentage: number,
  night_charge: number,
  advancePayment?: SplitPaymentDetails[],
  split?: SplitPaymentDetails[],
  name?: String
): number {
  let advance: number = 0
  let splitPayment: number = 0
  let res: number = 0
  const lineItemDetails: R4.IInvoice_LineItem[] = []
  for (let i = 0; i < lineItem.length; i++) {
    const charge = lineItem[i].chargeItemCodeableConcept
    const chargePrice = lineItem[i].priceComponent ?? []
    const codeData: R4.ICodeableConcept | undefined =
      chargePrice.length > 0 ? chargePrice[0].code : undefined

    const codeDataFinal: R4.ICoding[] = codeData ? codeData.coding ?? [] : []

    if (lineItem[i].chargeItemCodeableConcept === undefined) {
      if (
        lineItem[i].chargeItemReference &&
        lineItem[i].chargeItemReference?.display !== 'Attendant Charges'
      ) {
        lineItemDetails.push(lineItem[i])
      }
    } else if (
      (charge && charge.coding && charge.coding[0].code !== 'UNK') ||
      name ===
        'Package charges including naturopathy, ayurvedic treatment, yoga therapy, diet therapy and accomodation'
    ) {
      lineItemDetails.push(lineItem[i])
    }
  }
  lineItemDetails.forEach((e) => {
    res = getTotalOfLineItem(e) + res
  })

  if (advancePayment) {
    advance = getTotalPaymentFromSplit(advancePayment)
  }
  if (split) {
    splitPayment = getTotalPaymentFromSplit(split)
  }

  if (percentage > 0) {
    return (
      getGrandTotalPayableAmount(lineItemDetails) -
      getGrandTotalPayableAmountWithPercentage(lineItemDetails, percentage)
    )
  }

  return (
    getGrandTotalPayableAmount(lineItemDetails) +
    night_charge -
    (discount + advance)
  )
}

export function getPayableForMembershipForDelete(
  lineItem: R4.IInvoice_LineItem[],
  discount: number,
  percentage: number,
  night_charge: number,
  advancePayment?: SplitPaymentDetails[],
  split?: SplitPaymentDetails[],
  name?: String
): number {
  let advance: number = 0
  let splitPayment: number = 0
  let res: number = 0
  let night: number = 0

  const lineItemDetails: R4.IInvoice_LineItem[] = []
  const lineItemDetailsNight: R4.IInvoice_LineItem[] = []
  for (let i = 0; i < lineItem.length; i++) {
    const charge = lineItem[i].chargeItemCodeableConcept
    const chargePrice = lineItem[i].priceComponent ?? []
    const codeData: R4.ICodeableConcept | undefined =
      chargePrice.length > 0 ? chargePrice[0].code : undefined

    const codeDataFinal: R4.ICoding[] = codeData ? codeData.coding ?? [] : []

    if (lineItem[i].chargeItemCodeableConcept === undefined) {
      if (
        lineItem[i].chargeItemReference &&
        lineItem[i].chargeItemReference?.display !== 'Attendant Charges'
      ) {
        lineItemDetails.push(lineItem[i])
      }
    } else if (
      (charge && charge.coding && charge.coding[0].code !== 'UNK') ||
      name ===
        'Package charges including naturopathy, ayurvedic treatment, yoga therapy, diet therapy and accomodation'
    ) {
      lineItemDetails.push(lineItem[i])
    }
  }
  lineItemDetails.forEach((e) => {
    res = getTotalOfLineItem(e) + res
  })

  for (let i = 0; i < lineItemDetails.length; i++) {
    if (
      getNameOfLineItem(lineItemDetails[i]) ===
      'Night Charges. Includes Food and Accommodation'
    ) {
      lineItemDetailsNight.push(lineItemDetails[i])
    }
  }

  lineItemDetailsNight.forEach((e) => {
    night = getTotalOfLineItem(e) + night
  })

  if (advancePayment) {
    advance = getTotalPaymentFromSplit(advancePayment)
  }
  if (split) {
    splitPayment = getTotalPaymentFromSplit(split)
  }

  if (percentage > 0) {
    return (
      getGrandTotalPayableAmount(lineItemDetails) -
      getGrandTotalPayableAmountWithPercentage(lineItemDetails, percentage)
    )
  }
  return (
    getGrandTotalPayableAmount(lineItemDetails) - (discount + advance + night)
  )
}

export function checkForDuplicateReference(
  split: SplitPaymentDetails[],
  refData: string
): boolean {
  if (refData.length > 0) {
    const advanceDetails: SplitPaymentDetails[] = [...split].filter(
      (existing) =>
        existing.paymentReference &&
        existing.paymentReference === refData.trim()
    )
    if (advanceDetails.length > 0) {
      return true
    }
    return false
  }

  return false
}

export function checkForSplitData(split: SplitPaymentDetails[]): boolean {
  const advanceDetails: SplitPaymentDetails[] = [...split].filter(
    (existing) =>
      existing.paymentReference &&
      existing.paymentReference.length > 0 &&
      existing.Date &&
      existing.amount > 0 &&
      existing.paymentType
  )
  if (advanceDetails.length > 0) {
    return true
  }
  return false
}

export function getGrandTotalAmountInString(
  lineItem: R4.IInvoice_LineItem[]
): string {
  return `${getGrandTotalPayableAmount(lineItem).toFixed(2)}`
}

export function getGrandTotalPayableAmount(
  lineItem: R4.IInvoice_LineItem[]
): number {
  let res: number = 0
  lineItem.forEach((e) => {
    res = getTotalOfLineItem(e) + res
  })

  return res
}

export function getTotalPaymentFromSplit(
  splitDetails: SplitPaymentDetails[]
): number {
  let res: number = 0
  splitDetails.forEach((e) => {
    if (e.amount > 0) {
      res = e.amount + res
    }
  })
  return res
}

export function getTotalPaymentFromSplitForLine(
  splitDetails: SplitPaymentDetails[]
): number {
  let res: number = 0
  splitDetails.forEach((e) => {
    if (e.amount > 0 && e.paymentType && e.Date && e.paymentReference) {
      res = e.amount + res
    }
  })
  return res
}

export function getGrandTotalPayableAmountWithPercentage(
  lineItem: R4.IInvoice_LineItem[],
  percentage: number
): number {
  let res: number = 0
  lineItem.forEach((e) => {
    res = getTotalOfLineItem(e) + res
  })

  return (res * percentage) / 100
}

export function getNetPayableAmountInString(
  lineItem: R4.IInvoice_LineItem[],
  discount: number,
  percentage: number,
  advance?: SplitPaymentDetails[],
  split?: SplitPaymentDetails[]
): string {
  return `${getNetPayableAmount(
    lineItem,
    discount,
    percentage,
    advance,
    split
  ).toFixed(2)}`
}

export function getTotalOfLineItem(lineItem: R4.IInvoice_LineItem) {
  const totalAMount =
    getBasePriceOfLineItem(lineItem) * getQuantityOfLineItem(lineItem)
  return totalAMount
}

export function getTotalOfLineItemInString(lineItem: R4.IInvoice_LineItem) {
  let res = -1
  if (lineItem.priceComponent && lineItem.priceComponent.length > 0) {
    res = lineItem.priceComponent.findIndex((e) => e.type === 'base')
  }

  const sym =
    res >= 0
      ? getSymbolForCurrency(
          lineItem.priceComponent![res]?.amount?.currency ?? ''
        )
      : ''
  const totalAMount =
    getBasePriceOfLineItem(lineItem) * getQuantityOfLineItem(lineItem)
  return `${totalAMount.toFixed(2)}`
}

export async function getInvoiceOfIPDDetails(
  accountId: string
): Promise<BillingDetails | FHIRErrorResponses> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    account: `Account/${accountId}`,
    _include: 'Invoice:invoice-paymentRef',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Invoice',
    '',
    searchParameters
  )
  if (response.type === 'FHIRErrorResponses') {
    return response
  }
  const bundleRes: R4.IBundle = response as R4.IBundle
  if (bundleRes.entry && bundleRes.entry.length > 0) {
    const res = getBillingDetailsFromBundle(bundleRes)
    if (res) {
      return res
    }
  }

  return {
    status: 400,
    data: {},
    displayText: 'Invoice is not available',
  }
}

export function getQuantityChangedLineItems(
  originalInvoice: R4.IInvoice,
  currentLineItems: R4.IInvoice_LineItem[]
) {
  const changedLineItems: R4.IInvoice_LineItem[] = []
  if (currentLineItems) {
    if (
      originalInvoice.lineItem &&
      originalInvoice.lineItem.length > 0 &&
      currentLineItems.length > 0
    ) {
      currentLineItems.forEach((e) => {
        if (e.sequence) {
          const index = originalInvoice.lineItem?.findIndex(
            (item) => item.sequence === e.sequence
          )
          if ((index ?? -1) > -1) {
            if (
              getQuantityOfLineItem(e) !==
              getQuantityOfLineItem(originalInvoice.lineItem![index!])
            ) {
              changedLineItems.push({ ...e })
            }
          }
        }
      })
    }
  }
  return changedLineItems
}

export async function updateSignatures(
  taskDetails: R4.ITask,
  patientSelectedFile?: SelectedFile,
  doctorSelectedFile?: SelectedFile
): Promise<R4.ITask | undefined> {
  try {
    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const currentTask = (await fhirClient.doGetResource(
      `/Task/${taskDetails.id}`
    )) as R4.ITask
    const extensions: R4.IExtension[] = [...(currentTask.extension ?? [])]
    if (patientSelectedFile) {
      const ext: R4.IExtension = {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/patient_signature',
        valueAttachment: {
          contentType: patientSelectedFile.files?.type,
          data: await encodeBlobToBase4(
            await dataURItoBlob(patientSelectedFile.path as string)
          ),
        },
      }
      extensions.push(ext)
    }

    if (doctorSelectedFile) {
      const ext: R4.IExtension = {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/doctor_signature',
        valueAttachment: {
          contentType: doctorSelectedFile.files?.type,
          data: await encodeBlobToBase4(
            await dataURItoBlob(doctorSelectedFile.path as string)
          ),
        },
      }
      extensions.push(ext)
    }

    const updatedTask = { ...currentTask }

    updatedTask.extension = extensions

    const response: any = await fhirClient.doUpdateFHIRResourceRequest(
      `/Task/${updatedTask.id}` ?? '',
      updatedTask,
      updatedTask.meta?.versionId ?? ''
    )

    const res: R4.ITask = response as R4.ITask

    return res
  } catch (error) {
    console.error(error)
  }
  return undefined
}

export async function updateSignaturesForPrescription(
  Practitioner: R4.IPractitioner,
  doctorSelectedFile?: SelectedFile
): Promise<R4.IPractitioner | undefined> {
  try {
    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const updatedPracDetails = { ...Practitioner }
    const extensions: R4.IExtension[] = [...(Practitioner.extension ?? [])]
    if (doctorSelectedFile) {
      const logoExt: R4.IExtension = {}
      logoExt.url =
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-doctor-signature-ext'
      logoExt.valueAttachment = {
        contentType: doctorSelectedFile.type ?? '',
        title: doctorSelectedFile.name ?? '',
      }

      extensions.push(logoExt)
      updatedPracDetails.extension = extensions
    }

    const response: any = await fhirClient.doUpdateFHIRResourceRequest(
      `/Practitioner/${updatedPracDetails.id}` ?? '',
      updatedPracDetails,
      updatedPracDetails.meta?.versionId ?? ''
    )
    const res: R4.IPractitioner = response as R4.IPractitioner
    if (res.id && res.id.length > 0) {
      let logoAttachment: R4.IAttachment | undefined = {}
      if (doctorSelectedFile) {
        logoAttachment = {
          data: doctorSelectedFile.path as string,
          contentType: doctorSelectedFile.files
            ? doctorSelectedFile.files.type
            : doctorSelectedFile.type ?? '',
          title: doctorSelectedFile.name,
        }
      }
      try {
        if (logoAttachment) {
          await uploadFileDetails(
            logoAttachment,
            "Practitioner.extension('http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-doctor-signature-ext').value.as(Attachment)",
            'Practitioner',
            updatedPracDetails.id ?? ''
          )
        }
      } catch (error) {
        logger.info(error)
      }
    }
    return res
  } catch (error) {
    console.error(error)
  }
  return undefined
}

export function taskHasPatientSign(task: R4.ITask) {
  const ext = getExtensionValueOfUrl(
    task.extension,
    'http://wellopathy.com/fhir/india/core/StructureDefinition/patient_signature'
  )
  if (ext?.valueAttachment) {
    return true
  }
  return false
}

export function getHasPatientSign(task: R4.ITask) {
  const ext = getExtensionValueOfUrl(
    task.extension,
    'http://wellopathy.com/fhir/india/core/StructureDefinition/patient_signature'
  )
  if (ext?.valueAttachment) {
    const byteCharacters = atob(ext?.valueAttachment?.data!)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], {
      type: ext?.valueAttachment?.contentType!,
    })

    const fileURL = URL.createObjectURL(file)
    return fileURL
  }
  return undefined
}

export function getEncodedData(data: string) {
  const byteCharacters = atob(data)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }

  const byteArray = new Uint8Array(byteNumbers)
  const file = new Blob([byteArray], {
    type: 'image/jpeg',
  })

  const fileURL = URL.createObjectURL(file)
  return fileURL
}

export function taskHasDoctorSign(task: R4.ITask) {
  const ext = getExtensionValueOfUrl(
    task.extension,
    'http://wellopathy.com/fhir/india/core/StructureDefinition/doctor_signature'
  )
  if (ext?.valueAttachment) {
    return true
  }
  return false
}

export function getHasDoctorSign(task: R4.ITask) {
  const ext = getExtensionValueOfUrl(
    task.extension,
    'http://wellopathy.com/fhir/india/core/StructureDefinition/doctor_signature'
  )
  if (ext?.valueAttachment) {
    const byteCharacters = atob(ext?.valueAttachment?.data!)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], {
      type: ext?.valueAttachment?.contentType!,
    })

    const fileURL = URL.createObjectURL(file)
    return fileURL
  }
  return undefined
}

export async function updateLineItemsToServer(
  lineItems: R4.IInvoice_LineItem[],
  svId: string
) {
  const sendingLineItems = lineItems.map((e) => ({ ...e, id: undefined }))

  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `ipd/${svId}/onflyCharges`,
      { eventType: 'onflycharges', lineItemArray: sendingLineItems }
    )
    if (response) {
      return true
    }
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function updateInvoiceDetailsToServer(
  invoice: R4.IInvoice,
  svId: string
) {
  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `ipd/${svId}/invoice/payment`,
      { eventType: 'update-payment', invoice }
    )
    if (response) {
      return true
    }
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function deleteLineItemsInServer(
  lineItems: R4.IInvoice_LineItem[],
  svId: string
) {
  const sendingLineItems = lineItems.map((e) => ({ ...e, id: undefined }))

  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `ipd/${svId}/deleteItem`,
      { eventType: 'deleteItem', lineItemArray: sendingLineItems }
    )
    if (response) {
      return true
    }
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function updateQuantityLineItemsInServer(
  lineItems: R4.IInvoice_LineItem[],
  svId: string
) {
  const sendingLineItems = lineItems.map((e) => ({ ...e, id: undefined }))

  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `ipd/${svId}/modifyQuantity`,
      { eventType: 'decrementQuantity', lineItemArray: sendingLineItems }
    )
    if (response) {
      return true
    }
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function updateAdvanceDetails(
  advance: SplitPaymentDetails[],
  ipdCare: boolean,
  svId: string
) {
  const advanceArr: PaymentDetail[] = []
  for (let i = 0; i < advance.length; i++) {
    if (advance[i].isDelete === false && advance[i].paymentType) {
      const type: R4.ICoding | undefined = advance[i].paymentType
      if (
        advance[i].amount > 0 &&
        type &&
        advance[i].Date &&
        advance[i].paymentReference
      )
        advanceArr.push({
          method: type ? type.code ?? '' : '',
          txnId: advance[i].paymentReference ?? '',
          txnDate: advance[i].Date
            ? moment(advance[i].Date).toISOString()
            : moment().toISOString(),
          isAdvancePayment: true,
          isOpdBased: ipdCare,
          amount: {
            value: advance[i].amount,
            currency: 'INR',
          },
        })
    }
  }

  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `ipd/${svId}/split-payment`,
      { eventType: 'offline', paymentMeta: advanceArr }
    )
    if (response) {
      return true
    }
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function updateDiscountsToServer(
  amount: number,
  percentage: number,
  svId: string
) {
  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `ipd/${svId}/modifyDiscount`,
      {
        eventType: 'modify-discount',
        discountInRs: amount,
        discountInPercentage: percentage,
      }
    )
    if (response) {
      return true
    }
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function updatePaymentStatus(
  svId: string,
  paymentMethod: string,
  txnId: string,
  txnDate: Date,
  doctorId: string,
  ipdDayCare: boolean,
  primaryMembershipId?: string
) {
  try {
    const data = {
      eventType: 'offline',
      paymentMeta: {
        eventType: 'update-payment',
        method: paymentMethod,
        txnId,
        txnDate: txnDate.toISOString(),
        doctorId,
        isOpdBased: ipdDayCare,
        primaryMembershipId: primaryMembershipId ?? '',
      },
    }
    const enRolClient: EnrolCient = new EnrolCient()
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `ipd/${svId}/payment`,
      data
    )
    if (response) {
      return true
    }
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function updateSplitPayment(
  split: SplitPaymentDetails[],
  svId: string,
  doctorId: string,
  ipdDayCare: boolean,
  primaryMembershipId?: string
) {
  try {
    const splitFInal: SplitPaymentDetails[] = [...split].filter(
      (e) =>
        e.amount &&
        e.amount > 0 &&
        e.Date &&
        e.paymentType &&
        e.isDelete === false
    )
    const splitArr: PaymentDetail[] = splitFInal.map(
      (e: SplitPaymentDetails) => ({
        method: e.paymentType ? e.paymentType.code ?? '' : '',
        txnId: e.paymentReference ?? '',
        txnDate: e.Date ? moment(e.Date).toISOString() : moment().toISOString(),
        payee: doctorId,
        isOpdBased: ipdDayCare,
        isAdvancePayment: false,
        amount: {
          value: e.amount,
          currency: 'INR',
        },
        primaryCoverageId: e.primaryCoverageId ?? '',
      })
    )
    const enRolClient: EnrolCient = new EnrolCient()
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `ipd/${svId}/split-payment`,
      { eventType: 'offline', paymentMeta: splitArr }
    )
    if (response) {
      return true
    }
    return false
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function updateSplitPaymentStatus(
  split: SplitPaymentDetails[],
  svId: string,
  doctorId: string,
  ipdDayCare: boolean,
  refund: SplitPaymentDetails[],
  refundAppliCable: boolean,
  refundAmount: number,
  primaryMembershipId?: string
) {
  try {
    const splitFInal: SplitPaymentDetails[] = [...split].filter(
      (e) =>
        e.amount &&
        e.amount > 0 &&
        e.Date &&
        e.paymentType &&
        e.isDelete === false
    )
    const splitArr: PaymentDetail[] = splitFInal.map(
      (e: SplitPaymentDetails) => ({
        method: e.paymentType ? e.paymentType.code ?? '' : '',
        txnId: e.paymentReference ?? '',
        txnDate: e.Date ? moment(e.Date).toISOString() : moment().toISOString(),
        payee: doctorId,
        isOpdBased: ipdDayCare,
        isAdvancePayment: false,
        amount: {
          value: e.amount,
          currency: 'INR',
        },
        primaryCoverageId: e.primaryCoverageId ?? '',
      })
    )
    if (splitArr.length > 0) {
      const enRolClient: EnrolCient = new EnrolCient()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        `ipd/${svId}/split-payment`,
        { eventType: 'offline', paymentMeta: splitArr }
      )
      if (response) {
        const paymentResponse: boolean = await updatePaymentDetails(
          svId,
          doctorId,
          ipdDayCare,
          primaryMembershipId
        )

        if (refundAppliCable) {
          if (paymentResponse) {
            const refundResponse: boolean = await updateRefundDetails(
              svId,
              doctorId,
              ipdDayCare,
              refundAmount,
              refund
            )
            if (refundResponse) {
              const completedResponse: boolean = await completePaymentForOther(
                svId,
                doctorId,
                ipdDayCare,
                primaryMembershipId
              )

              if (completedResponse) {
                return true
              }
              return false
            }
          }
        } else if (paymentResponse) {
          const completedResponse: boolean = await completePaymentForOther(
            svId,
            doctorId,
            ipdDayCare,
            primaryMembershipId
          )
          if (completedResponse) {
            return true
          }
          return false
        }

        return false
      }
    } else {
      const completedResponseForFinal: boolean = await completePayment(
        svId,
        doctorId,
        ipdDayCare,
        refund,
        refundAppliCable,
        refundAmount,
        primaryMembershipId
      )
      if (completedResponseForFinal) {
        return true
      }
      return false
    }
    return false
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function completePayment(
  svId: string,
  doctorId: string,
  ipdDayCare: boolean,
  refund: SplitPaymentDetails[],
  refundAppliCable: boolean,
  refundAmount: number,
  primaryMembershipId?: string
) {
  try {
    if (refundAppliCable && refund[0].isDelete === false) {
      const refundResponse: boolean = await updateRefundDetails(
        svId,
        doctorId,
        ipdDayCare,
        refundAmount,
        refund
      )

      if (refundResponse) {
        const splitArr = {
          eventType: 'offline',
          isOpdBased: ipdDayCare,
        }
        const enRolClient: EnrolCient = new EnrolCient()
        const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
          `ipd/${svId}/split-payment/complete`,
          splitArr
        )

        if (response) {
          const paymentResponse: boolean = await updatePaymentDetails(
            svId,
            doctorId,
            ipdDayCare,
            primaryMembershipId
          )
          if (paymentResponse) return true

          return false
        }
      }
    } else {
      const splitArr = {
        eventType: 'offline',
        isOpdBased: ipdDayCare,
      }
      const enRolClient: EnrolCient = new EnrolCient()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        `ipd/${svId}/split-payment/complete`,
        splitArr
      )
      if (response) {
        const paymentResponse: boolean = await updatePaymentDetails(
          svId,
          doctorId,
          ipdDayCare,
          primaryMembershipId
        )
        if (paymentResponse) return true

        return false
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function completePaymentForOther(
  svId: string,
  doctorId: string,
  ipdDayCare: boolean,
  primaryMembershipId?: string
) {
  try {
    const splitArr = {
      eventType: 'offline',
      isOpdBased: ipdDayCare,
    }
    const enRolClient: EnrolCient = new EnrolCient()
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `ipd/${svId}/split-payment/complete`,
      splitArr
    )
    if (response) {
      return true
    }
    return false
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function updatePaymentDetails(
  svId: string,
  doctorId: string,
  ipdDayCare: boolean,
  primaryMembershipId?: string
) {
  const data = {
    eventType: 'offline',
    detail: [],
    doctor: doctorId,
    primaryCoverageId: primaryMembershipId ?? '',
  }
  const enRolClient: EnrolCient = new EnrolCient()
  const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
    `ipd/${svId}/split-payment/update`,
    data
  )
  if (response) {
    return true
  }
  return false
}

export async function updateRefundDetails(
  svId: string,
  doctorId: string,
  ipdDayCare: boolean,
  refundAmount: number,
  refundData: SplitPaymentDetails[]
) {
  const data = {
    eventType: 'offline',
    isOpdBased: ipdDayCare,
    paymentMeta: {
      method: refundData[0].paymentType
        ? refundData[0].paymentType.code ?? ''
        : '',
      txnId: refundData[0].paymentReference ?? '',
      txnDate: refundData[0].Date
        ? moment(refundData[0].Date).toISOString()
        : moment().toISOString(),

      payee: doctorId,
      amount: {
        value: refundAmount,
        currency: 'INR',
      },
    },
  }
  const enRolClient: EnrolCient = new EnrolCient()
  const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
    `ipd/${svId}/split-payment/refund`,
    data
  )
  if (response) {
    return true
  }
  return false
}

export async function getInvoiceDetailsInPdf(invoiceId: string) {
  const enRolClient: FHIRApiClient = new FHIRApiClient()
  try {
    const response: any = await enRolClient.doCreateFHIRTransaction(
      `/$generate-invoice`,
      {
        resourceType: 'Parameters',
        parameter: [
          {
            name: 'order-reference',
            valueString: `Invoice/${invoiceId}`,
          },
        ],
      }
    )
    let data: string = ''
    if (response.parameter) data = response.parameter[0].valueString ?? ''
    const byteCharacters = atob(data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], { type: 'application/pdf;base64' })
    const fileURL = URL.createObjectURL(file)
    print({
      printable: fileURL,
      type: 'pdf',
      showModal: true,
    })
    return true
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export function isDischargeInitiated(task: R4.ITask) {
  let res = false
  const code = getCodeOfSystemFromCodableConcept(
    task.businessStatus!,
    'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status'
  )
  if (code) res = code.code === 'initiate-discharge'

  return res
}

export function dischargeInitiatedDate(task: R4.ITask) {
  let date: string = ''
  let res = false
  const code = getCodeOfSystemFromCodableConcept(
    task.businessStatus!,
    'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status'
  )
  if (code) res = code.code === 'initiate-discharge'

  if (res) {
    date = moment(task.lastModified ?? moment()).format('DD/MM/YYYY')
  }
  return date
}

export function isDischargeInitiatedForAssessment(task: R4.ITask) {
  let res = false

  const date = task.meta
    ? task.meta.lastUpdated
      ? moment(
          moment(task.meta.lastUpdated).format('DD-MM-YYYY'),
          "'DD-MM-YYYY'"
        )
      : moment(moment(new Date()).format('DD-MM-YYYY'), "'DD-MM-YYYY'")
    : moment(moment(new Date()).format('DD-MM-YYYY'), "'DD-MM-YYYY'")
  const todayDate = moment(
    moment(new Date()).format('DD-MM-YYYY'),
    "'DD-MM-YYYY'"
  )

  const pastDate = moment(moment(date), 'DD-MM-YYYY')

  const code = getCodeOfSystemFromCodableConcept(
    task.businessStatus!,
    'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status'
  )
  if (code)
    res = code.code === 'initiate-discharge' && todayDate.isSame(pastDate)

  return res
}

export function isDischarged(task: R4.ITask) {
  const status = task
    ? task.businessStatus!.coding
      ? task.businessStatus!.coding[0].code
      : ''
    : ''

  return status === 'feedback-received' || status === 'discharged'
}

function base64ToArrayBuffer(base64: any) {
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

export async function getUpdatedTaskDetails(taskId: string) {
  try {
    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const currentTask = (await fhirClient.doGetResource(
      `/Task/${taskId}`
    )) as R4.ITask
    return currentTask
  } catch (error) {
    console.error(error)
  } finally {
  }
  return undefined
}

export async function getUpdatedPractitionerDetails(doctorId: string) {
  try {
    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const currentTask = (await fhirClient.doGetResource(
      `/Practitioner/${doctorId}`
    )) as R4.IPractitioner
    return currentTask
  } catch (error) {
    console.error(error)
  } finally {
  }
  return undefined
}

export function getBillingDetailsFromBundle(
  bundle: R4.IBundle
): BillingDetails | undefined {
  if (bundle.entry) {
    let invoice: R4.IInvoice | undefined
    let prc: R4.IPaymentReconciliation | undefined
    const entries: R4.IBundle_Entry[] = bundle.entry
    entries.forEach((value) => {
      if (value.resource) {
        if (value.resource.resourceType === 'Invoice') {
          invoice = value.resource as R4.IInvoice
        }
        if (value.resource.resourceType === 'PaymentReconciliation') {
          prc = value.resource as R4.IPaymentReconciliation
        }
      }
    })
    if (invoice) {
      invoice = discountCalculationHelperFunction(invoice)
      return {
        id: invoice.id!,
        invoice,
        paymentReconciliation: prc,
      }
    }
  }
  return undefined
}

/**
 * It returns payment reference
 * @param billingDetails billing details
 * @returns payment reference
 */

export function getPaymentReference(billingDetails: BillingDetails) {
  if (billingDetails.paymentReconciliation) {
    if (
      billingDetails.paymentReconciliation.detail &&
      billingDetails.paymentReconciliation.detail.length > 0
    ) {
      if (
        billingDetails.paymentReconciliation.detail[0].identifier &&
        billingDetails.paymentReconciliation.detail[0].identifier.value
      ) {
        return billingDetails.paymentReconciliation.detail[0].identifier.value
      }
    }
  }
  return undefined
}

/**
 * It returns payment type coding
 * @param billingDetails billing details
 * @returns payment type coding
 */
export function getPaymentTypeCode(billingDetails: BillingDetails) {
  if (billingDetails.paymentReconciliation) {
    if (
      billingDetails.paymentReconciliation.detail &&
      billingDetails.paymentReconciliation.detail.length > 0
    ) {
      if (
        billingDetails.paymentReconciliation.detail[0].type &&
        billingDetails.paymentReconciliation.detail[0].type.coding
      )
        return billingDetails.paymentReconciliation.detail[0].type.coding[0]
    }
  }
  return undefined
}

/**
 * It returns payment date
 * @param billingDetails billing details
 * @returns payment date
 */
export function getPaymentDate(billingDetails: BillingDetails) {
  if (billingDetails.paymentReconciliation) {
    if (
      billingDetails.paymentReconciliation.detail &&
      billingDetails.paymentReconciliation.detail.length > 0
    ) {
      if (billingDetails.paymentReconciliation.detail[0].date) {
        return billingDetails.paymentReconciliation.detail[0].date
      }
    }
  }
  return undefined
}

/**
 * It returns advance payment from the invoice resource
 * @param billingDetails billing details
 * @returns  advance details
 */
export function getAdVanceData(billingDetails: BillingDetails) {
  const advancePayment: SplitPaymentDetails[] = []
  if (billingDetails.paymentReconciliation) {
    if (
      billingDetails.paymentReconciliation.detail &&
      billingDetails.paymentReconciliation.detail.length > 0
    ) {
      billingDetails.paymentReconciliation.detail.map((val, index: number) => {
        if (val.extension) {
          const advanceData: R4.ICodeableConcept | undefined =
            getValueCodableFromExtension(
              val.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-advance-payment-ext'
            )
          if (advanceData) {
            const site: SplitPaymentDetails = {
              id: index,
              Date: val.date ? moment(val.date).toDate() : moment().toDate(),
              paymentType: val.type.coding ? val.type.coding[0] : undefined,
              amount: val.amount ? val.amount.value ?? 0 : 0,
              paymentReference: getIdentifierValueBySystemForPayment(
                'http://wellopathy.com/fhir/india/core/StructureDefinition/payment-id',
                val.identifier
              ),
              isDelete: true,
            }
            advancePayment.push(site)
          }
        }
      })
    }
  }
  return advancePayment
}

/**
 * It returns refund payment from the invoice resource
 * @param billingDetails billing details
 * @returns final refund details
 */
export function getRefundData(billingDetails: BillingDetails) {
  const advancePayment: SplitPaymentDetails[] = []
  if (billingDetails.paymentReconciliation) {
    if (
      billingDetails.paymentReconciliation.detail &&
      billingDetails.paymentReconciliation.detail.length > 0
    ) {
      billingDetails.paymentReconciliation.detail.map((val, index: number) => {
        if (val.extension) {
          const advanceData: R4.ICodeableConcept | undefined =
            getValueCodableFromExtension(
              val.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-payment-ext'
            )
          if (advanceData) {
            const site: SplitPaymentDetails = {
              id: index,
              Date: val.date ? moment(val.date).toDate() : moment().toDate(),
              paymentType: val.type.coding ? val.type.coding[0] : undefined,
              amount: val.amount ? val.amount.value ?? 0 : 0,
              paymentReference: getIdentifierValueBySystemForPayment(
                'http://wellopathy.com/fhir/india/core/StructureDefinition/payment-id',
                val.identifier
              ),
              isDelete: true,
            }
            advancePayment.push(site)
          }
        }
      })
    }
  }
  return advancePayment
}

/**
 * It returns final payment from the invoice resource
 * @param billingDetails billing details
 * @returns final payment details
 */
export function getSplitPayment(billingDetails: BillingDetails) {
  const splitPayment: SplitPaymentDetails[] = []
  if (billingDetails.paymentReconciliation) {
    if (
      billingDetails.paymentReconciliation.detail &&
      billingDetails.paymentReconciliation.detail.length > 0
    ) {
      billingDetails.paymentReconciliation.detail.map((val, index: number) => {
        if (val.extension === undefined) {
          const site: SplitPaymentDetails = {
            id: index,
            Date: val.date ? moment(val.date).toDate() : moment().toDate(),
            paymentType: val.type.coding ? val.type.coding[0] : undefined,
            amount: val.amount ? val.amount.value ?? 0 : 0,
            paymentReference: getIdentifierValueBySystemForPayment(
              'http://wellopathy.com/fhir/india/core/StructureDefinition/payment-id',
              val.identifier
            ),
            isDelete: true,
          }
          splitPayment.push(site)
        }
      })
    }
  }
  return splitPayment
}

/**
 * It returns if any advance payment available or not
 * @param billingDetails billing details
 * @returns if any advance payment available or not
 */
export function getAdVanceDataCheck(billingDetails: BillingDetails) {
  const advancePayment: SplitPaymentDetails[] = []
  if (billingDetails.paymentReconciliation) {
    if (
      billingDetails.paymentReconciliation.detail &&
      billingDetails.paymentReconciliation.detail.length > 0
    ) {
      billingDetails.paymentReconciliation.detail.map((val, index: number) => {
        const site: SplitPaymentDetails = {
          id: index,
          Date: val.date ? moment(val.date).toDate() : moment().toDate(),
          paymentType: val.type.coding ? val.type.coding[0] : undefined,
          amount: val.amount ? val.amount.value ?? 0 : 0,
          paymentReference: getIdentifierValueBySystemForPayment(
            'http://wellopathy.com/fhir/india/core/StructureDefinition/payment-id',
            val.identifier
          ),
          isDelete: true,
        }
        advancePayment.push(site)
      })
    }
  }
  return !!advancePayment.length
}

/**
 * It returns the selected doctor in the billing screen
 * @param billingDetails billing details
 * @returns selected practitioner reference
 */
export function getResponsibleDoctor(billingDetails: BillingDetails) {
  if (
    billingDetails.invoice.participant &&
    billingDetails.invoice.participant.length > 0
  ) {
    const index = billingDetails.invoice.participant.findIndex((e) => {
      const roleCode = getDefaultCodeOfSystemFromCodableConcept(
        e.role,
        'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles'
      )
      return roleCode === 'doctor'
    })

    if (index > -1) {
      if (billingDetails.invoice.participant[index].actor.id) {
        return billingDetails.invoice.participant[index].actor.id
      }
      if (billingDetails.invoice.participant[index].actor.reference) {
        return billingDetails.invoice.participant[index].actor.reference?.split(
          '/'
        )[1]
      }
    }
  }
  return undefined
}

/**
 * It returns the balance amount for the final payment
 * @param advancePayment details
 * @param split final payment details
 * @returns paid amount as string
 */
export function getPaidAmount(
  advancePayment?: SplitPaymentDetails[],
  split?: SplitPaymentDetails[]
): string {
  let advance: number = 0
  let splitPayment: number = 0
  let totalPaid: number = 0
  if (advancePayment) {
    advance = getTotalPaymentFromSplit(advancePayment)
  }
  if (split) {
    splitPayment = getTotalPaymentFromSplit(split)
  }
  totalPaid = advance + splitPayment
  return `${totalPaid.toFixed(2)}`
}

/**
 * It returns the balance amount for the final payment

 * @param advancePayment details
 * @param split final payment details
 * @returns paid amount as number
 */
export function getPaidAmountNumber(
  advancePayment?: SplitPaymentDetails[],
  split?: SplitPaymentDetails[]
): number {
  let advance: number = 0
  let splitPayment: number = 0
  let totalPaid: number = 0
  if (advancePayment) {
    advance = getTotalPaymentFromSplit(advancePayment)
  }
  if (split) {
    splitPayment = getTotalPaymentFromSplit(split)
  }
  totalPaid = advance + splitPayment
  return totalPaid
}
/**
 * It returns the balance amount for the final payment
 * @param lineItem - Array of lineItems
 * @param discount in number
 * @param advancePayment details
 * @param split final payment details
 * @returns balance  as string
 */
export function getBalanceAmount(
  lineItem: R4.IInvoice_LineItem[],
  discount: number,
  advancePayment?: SplitPaymentDetails[],
  split?: SplitPaymentDetails[]
): string {
  let advance: number = 0
  let splitPayment: number = 0
  let totalPaid: number = 0
  if (advancePayment) {
    advance = getTotalPaymentFromSplit(advancePayment)
  }
  if (split) {
    splitPayment = getTotalPaymentFromSplit(split)
  }
  totalPaid = advance + splitPayment
  if (getNetPayableAmount(lineItem, discount, 0) - totalPaid < 0) return `0.00`
  return `${(getNetPayableAmount(lineItem, discount, 0) - totalPaid).toFixed(
    2
  )}`
}

/**
 * It returns the balance amount for the final payment
 * @param lineItem - Array of lineItems
 * @param discount in number
 * @param advancePayment details
 * @param split final payment details
 * @returns balance  as number
 */
export function getBalanceAmountNumber(
  lineItem: R4.IInvoice_LineItem[],
  discount: number,
  advancePayment?: SplitPaymentDetails[],
  split?: SplitPaymentDetails[]
): number {
  let advance: number = 0
  let splitPayment: number = 0
  let totalPaid: number = 0
  if (advancePayment) {
    advance = getTotalPaymentFromSplit(advancePayment)
  }
  if (split) {
    splitPayment = getTotalPaymentFromSplit(split)
  }
  totalPaid = advance + splitPayment
  if (getNetPayableAmount(lineItem, discount, 0) - totalPaid < 0) return 0
  return parseFloat(
    (getNetPayableAmount(lineItem, discount, 0) - totalPaid).toFixed(2)
  )
}

/**
 * It returns the balance amount for the final payment
 * @param lineItem - Array of lineItems
 * @param discount in number
 * @param advancePayment details
 * @param split final payment details
 * @returns balance  as number
 */
export function getBalanceAmountForSplitPayment(
  lineItem: R4.IInvoice_LineItem[],
  discount: number,
  advancePayment?: SplitPaymentDetails[],
  split?: SplitPaymentDetails[]
): number {
  let advance: number = 0
  let splitPayment: number = 0
  let totalPaid: number = 0
  if (advancePayment) {
    advance = getTotalPaymentFromSplit(advancePayment)
  }
  if (split) {
    splitPayment = getTotalPaymentFromSplit(split)
  }
  totalPaid = advance + splitPayment
  return getNetPayableAmount(lineItem, discount, 0) - totalPaid
}

/**
 * It returns refund as string
 * @param lineItem - Array of lineItems
 * @param discount in number
 * @param advancePayment details
 * @param split final payment details
 * @returns refund  as string
 */
export function getRefundString(
  lineItem: R4.IInvoice_LineItem[],
  discount: number,
  advancePayment?: SplitPaymentDetails[],
  split?: SplitPaymentDetails[]
): string {
  let advance: number = 0

  advance = getPaidAmountNumber(advancePayment, split)

  if (getNetPayableAmount(lineItem, discount, 0) - advance < 0) {
    return `${(getNetPayableAmount(lineItem, discount, 0) - advance).toFixed(
      2
    )}`
  }
  return `0.00`
}

/**
 * It returns refund amount if any after final payment as number
 * @param lineItem - Array of lineItems
 * @param discount in number
 * @param advancePayment details
 * @param split final payment details
 * @returns refund  in number
 */
export function getRefundAmount(
  lineItem: R4.IInvoice_LineItem[],
  discount: number,
  advancePayment?: SplitPaymentDetails[],
  split?: SplitPaymentDetails[]
): number {
  let paidAmount: number = 0

  paidAmount = getPaidAmountNumber(advancePayment, split)

  if (getNetPayableAmount(lineItem, discount, 0) - paidAmount < 0) {
    return paidAmount - getNetPayableAmount(lineItem, discount, 0)
  }
  return 0
}

/**
 * It returns discount in percentage
 * @param grandTotal - total of lineItems.
 * @param discountAmount
 * @returns discount in percentage
 */
export function getDiscountPercentage(
  grandTotal: number,
  discount: number
): number {
  let discountPercent: number = 0

  if (discount > 0) {
    discountPercent = (discount * 100) / grandTotal
  }
  return Number(discountPercent.toFixed(2))
}

/**
 * It returns discount in amount
 * @param grandTotal - total of lineItems.
 * @param discount in percentage
 * @returns discount in amount
 */
export function getDiscount(
  grandTotal: number,
  discountPercent: number
): number {
  let discount: number = 0

  if (discountPercent > 0) {
    discount = (grandTotal * discountPercent) / 100
  }
  return Number(discount.toFixed(2))
}

export function addOrRemoveOnFlyChargeItem(invoice: R4.IInvoice) {
  //
  let grossTotalAmount = 0
  let sequence = 1
  if (invoice) {
    const invoiceData: R4.IInvoice_LineItem[] = invoice.lineItem ?? []
    if (invoiceData && invoiceData.length > 0) {
      invoiceData.forEach((lineItem) => {
        lineItem.sequence = sequence
        const lineItemPrice: R4.IInvoice_PriceComponent[] =
          lineItem.priceComponent ?? []
        if (lineItemPrice && lineItemPrice.length > 0) {
          const firstLineItemPrice: R4.IInvoice_PriceComponent | undefined =
            lineItemPrice[0]
          if (firstLineItemPrice !== undefined) {
            const amount = firstLineItemPrice
              ? firstLineItemPrice.amount
                ? firstLineItemPrice.amount.value ?? 0
                : 0 * (firstLineItemPrice.factor ?? 0)
              : 0
            grossTotalAmount += amount
          }
        }
        sequence += 1
      })
      const invoiceGross = grossTotalAmount
      if (invoice.totalGross) {
        invoice.totalGross.value = invoiceGross
      }
    }
  }

  //   discountCalculationHelperFunction(invoice)
  return invoice
}

/**
 * It adds line items to an invoice and calculates the gross total amount
 * @param invoice - R4.IInvoice - The invoice object that you want to add line items to.
 * @param {R4.IInvoice_LineItem[]} lineItems - R4.IInvoice_LineItem[]
 * @returns The invoice object with the line items added to it.
 */
export function changeCodingForDiscount(invoice: R4.IInvoice, type: string) {
  const changedInvoiceData: R4.IInvoice = { ...invoice }
  if (changedInvoiceData) {
    const newPriceCompData: R4.IInvoice_PriceComponent[] = []
    const invoiceData: R4.IInvoice_LineItem[] =
      changedInvoiceData.lineItem ?? []
    if (invoiceData && invoiceData.length > 0) {
      const firstObj = invoiceData[0]
      if (firstObj) {
        const priceCompData: R4.IInvoice_PriceComponent[] =
          firstObj.priceComponent ?? []
        if (priceCompData.length > 0) {
          newPriceCompData.push(priceCompData[0])
          if (type === 'percent') {
            const replaceData: R4.IInvoice_PriceComponent = {
              type: R4.Invoice_PriceComponentTypeKind._discount,
              factor: priceCompData[1].factor,
              code: {
                coding: [
                  {
                    system: 'http://unitsofmeasure.org',
                    code: '%',
                    display: 'percent',
                  },
                ],
              },
              amount: priceCompData[1].amount,
            }
            newPriceCompData.push(replaceData)
            firstObj.priceComponent = newPriceCompData
            invoiceData[0] = firstObj
          }

          if (type === 'amount') {
            const replaceData: R4.IInvoice_PriceComponent = {
              type: R4.Invoice_PriceComponentTypeKind._discount,
              factor: priceCompData[1].factor,
              code: {
                coding: [
                  {
                    system: 'http://unitsofmeasure.org',
                    code: '[arb’U]',
                    display: 'arbitrary unit',
                  },
                ],
              },
              amount: priceCompData[1].amount,
            }
            newPriceCompData.push(replaceData)
            firstObj.priceComponent = newPriceCompData
            invoiceData[0] = firstObj
          }
        }
      }
    }
    changedInvoiceData.lineItem = invoiceData
  }

  return changedInvoiceData
}

export function changeDiscountPercenTage(
  invoice: R4.IInvoice,
  type: string,
  percentage: number,
  amount: number
) {
  const changedInvoiceData: R4.IInvoice = { ...invoice }
  if (changedInvoiceData) {
    const newPriceCompData: R4.IInvoice_PriceComponent[] = []
    const invoiceData: R4.IInvoice_LineItem[] =
      changedInvoiceData.lineItem ?? []
    if (invoiceData && invoiceData.length > 0) {
      const firstObj = invoiceData[0]
      if (firstObj) {
        const priceCompData: R4.IInvoice_PriceComponent[] =
          firstObj.priceComponent ?? []
        if (priceCompData.length > 0) {
          newPriceCompData.push(priceCompData[0])
          if (type === 'percent') {
            const replaceData: R4.IInvoice_PriceComponent = {
              type: R4.Invoice_PriceComponentTypeKind._discount,
              factor: priceCompData[1].factor,
              code: {
                coding: [
                  {
                    system: 'http://unitsofmeasure.org',
                    code: '%',
                    display: 'percent',
                  },
                ],
              },
              amount: {
                value: 7200,
                currency: 'INR',
              },
            }
            newPriceCompData.push(replaceData)
            firstObj.priceComponent = newPriceCompData
            invoiceData[0] = firstObj
          }

          if (type === 'amount') {
            const replaceData: R4.IInvoice_PriceComponent = {
              type: R4.Invoice_PriceComponentTypeKind._discount,
              factor: priceCompData[1].factor,
              code: {
                coding: [
                  {
                    system: 'http://unitsofmeasure.org',
                    code: '[arb’U]',
                    display: 'arbitrary unit',
                  },
                ],
              },
              amount: priceCompData[1].amount,
            }
            newPriceCompData.push(replaceData)
            firstObj.priceComponent = newPriceCompData
            invoiceData[0] = firstObj
          }
        }
      }
    }
    changedInvoiceData.lineItem = invoiceData
  }

  return changedInvoiceData
}

export function deleteLineItemsForHelper(
  invoice: R4.IInvoice,
  lineItems: R4.IInvoice_LineItem
) {
  let invoiceData: R4.IInvoice = invoice

  if (invoice) {
    const lineItemFromInvoice: R4.IInvoice_LineItem[] = invoice.lineItem ?? []
    if (lineItemFromInvoice.length > 0) {
      for (let i = 0; i < lineItemFromInvoice.length; i++) {
        if (lineItemFromInvoice[i].chargeItemCodeableConcept) {
          const data: R4.ICodeableConcept | undefined =
            lineItemFromInvoice[i].chargeItemCodeableConcept
          if (data) {
            const nightData = data.coding ? data.coding[0].code ?? '' : ''

            if (nightData.length > 0 && nightData === 'night_charge') {
              lineItemFromInvoice.splice(i, 1)
              invoiceData.lineItem = lineItemFromInvoice
            }
          }
        } else {
          const priceCompData: R4.IInvoice_PriceComponent[] =
            lineItemFromInvoice[i].priceComponent ?? []
          if (priceCompData.length > 0) {
            if (priceCompData[0].amount) {
              if (priceCompData[0].amount.value === 0) {
                lineItemFromInvoice.splice(i, 1)
                invoiceData.lineItem = lineItemFromInvoice
              }
            }
          }
        }
      }
    }
  }
  invoiceData = discountCalculationHelperFunction(invoiceData)
  return invoiceData
}

export function getDateDifferceinDays(
  checkOutDateInUTC?: string,
  checkInDateInUTC?: string
): number {
  let dayDiff: number = 0
  if (checkOutDateInUTC && checkInDateInUTC) {
    const endDateUI = moment(checkOutDateInUTC)
      .tz('Asia/Kolkata')
      .format('DD-MM-YYYY')
    const startDateUI = moment(checkInDateInUTC)
      .tz('Asia/Kolkata')
      .format('DD-MM-YYYY')
    const standardCheckOutDateTime = moment(checkOutDateInUTC)
      .tz('Asia/Kolkata')
      .set({ h: 12, m: 0 })
      .toISOString()
    const startDate = moment(startDateUI, 'DD-MM-YYYY')
    const endDate = moment(endDateUI, 'DD-MM-YYYY')
    dayDiff = endDate.diff(startDate, 'days')
    if (dayDiff === 0) {
      return dayDiff + 1
      // eslint-disable-next-line no-else-return
    } else if (
      new Date(checkOutDateInUTC) > new Date(standardCheckOutDateTime)
    ) {
      // Count as 1 day only if current day is greater than 12 pm.
      return dayDiff + 1
    } else {
      return dayDiff
    }
  }
  return dayDiff
}
