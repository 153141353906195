import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Divider,
  ListSubheader,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  Tooltip,
  Collapse,
  Link,
} from '@material-ui/core'

import {
  DeleteOutlineOutlined,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  Close,
  Add,
  ExpandMore,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedLabResults } from 'models/groupedLabResults'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { resetAddLabTestSlice } from 'redux/consultation/addlabTest/addLabTestIPD/addLabTestSlice'
import { requestLabTestDetailsDetailsOfPatient } from 'redux/consultation/labTestOPD/labDetailSlice'
import { requestVitalDetailsOfPatient } from 'redux/consultation/vitalsDetailsSlice/vitalsDetailSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isPhysiotherapist,
  isUnitAdmin,
} from 'services/userDetailsService'
import { cbcpanel } from 'utils/constants'
import { getDateWiseVitals } from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { logger } from 'utils/logger'

import { AddLabTest } from 'views/components/ipdconusltation/objective/addLabTestResult'
import { VitalElement } from 'views/components/LeftMenu/VitalElement'
import { LabResultTileHistory } from './LabResultsTileForHistory'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))

interface VitalsProp {
  patient: R4.IPatient
  labTest: GroupedLabResults[]
  cbcPanelData: GroupedLabResults[]
  panel2: GroupedLabResults[]
  panel3: GroupedLabResults[]
  lftResults: GroupedLabResults[]
  rftResults: GroupedLabResults[]
  tftResults: GroupedLabResults[]
  homaResults: GroupedLabResults[]
  dexaResults: GroupedLabResults[]
  serumLytes: GroupedLabResults[]
  panel4Results: GroupedLabResults[]
}

export const LabResultsHistory: React.FC<VitalsProp> = ({
  patient,
  labTest,
  cbcPanelData,
  panel2,
  panel3,
  lftResults,
  rftResults,
  tftResults,
  homaResults,
  dexaResults,
  serumLytes,
  panel4Results,
}: VitalsProp) => {
  const { t } = useTranslation(['common'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const [groupedLabTest, setGroupedLabTest] = useState<GroupedLabResults[]>([])
  const [groupedCbcPanel, setGroupedCbcPanel] = useState<GroupedLabResults[]>(
    []
  )

  const labTestSearchSlice = useSelector(
    (state: RootState) => state.diagnosticObsFinderSlice
  )
  const [groupedPanel2, setGroupedPanel2] = useState<GroupedLabResults[]>([])
  const [groupedPanel3, setGroupedPanel3] = useState<GroupedLabResults[]>([])
  const [groupedLft, setGroupedLft] = useState<GroupedLabResults[]>([])
  const [groupedRft, setGroupedRft] = useState<GroupedLabResults[]>([])

  const [groupedTft, setGroupedTft] = useState<GroupedLabResults[]>([])
  const [groupedHoma, setGroupedHoma] = useState<GroupedLabResults[]>([])
  const [groupedDexa, setGroupedDexa] = useState<GroupedLabResults[]>([])
  const [groupedSerumLytes, setGroupedSerumLytes] = useState<
    GroupedLabResults[]
  >([])
  const [groupedPanel4, setGroupedPanel4] = useState<GroupedLabResults[]>([])
  const classes = useStyles()
  const dispatch = useDispatch()
  const vitalsDetailsSlice = useSelector(
    (state: RootState) => state.labTestDetailsSliceOPD
  )

  useEffect(() => {
    if (labTest.length > 0) {
      updateLabTest(labTest)
    }
    if (cbcPanelData.length > 0) {
      updatePanel1(cbcPanelData)
    }
    if (panel2.length > 0) {
      updatePanel2(panel2)
    }
    if (panel3.length > 0) {
      updatePanel3(panel3)
    }
    if (lftResults.length > 0) {
      updateLft(lftResults)
    }
    if (rftResults.length > 0) {
      updateRft(rftResults)
    }

    if (tftResults.length > 0) {
      updateTft(tftResults)
    }
    if (panel4Results.length > 0) {
      updatePanel4(panel4Results)
    }

    if (homaResults.length > 0) {
      updatePanelHoma(homaResults)
    }
    if (dexaResults.length > 0) {
      updatePanelDexa(dexaResults)
    }
    if (serumLytes.length > 0) {
      updatePanelSerumLytes(serumLytes)
    }
  }, [vitalsDetailsSlice])

  function updateLabTest(labResult: GroupedLabResults[]) {
    const results: GroupedLabResults[] = []
    for (let i = 0; i < labResult.length; i++) {
      results.push({
        date: labResult[i].date,
        occupation: labResult[i].occupation,
        checked: true,
      })
    }
    setGroupedLabTest(results)
  }

  function updatePanel1(panel1: GroupedLabResults[]) {
    const results: GroupedLabResults[] = []
    for (let i = 0; i < panel1.length; i++) {
      results.push({
        date: panel1[i].date,
        occupation: panel1[i].occupation,
        checked: true,
      })
    }
    setGroupedCbcPanel(results)
  }

  function updatePanel2(panel: GroupedLabResults[]) {
    const results: GroupedLabResults[] = []
    for (let i = 0; i < panel.length; i++) {
      results.push({
        date: panel[i].date,
        occupation: panel[i].occupation,
        checked: true,
      })
    }
    setGroupedPanel2(results)
  }
  function updatePanel3(panel: GroupedLabResults[]) {
    const results: GroupedLabResults[] = []
    for (let i = 0; i < panel.length; i++) {
      results.push({
        date: panel[i].date,
        occupation: panel[i].occupation,
        checked: true,
      })
    }
    setGroupedPanel3(results)
  }

  function updateLft(panel: GroupedLabResults[]) {
    const results: GroupedLabResults[] = []
    for (let i = 0; i < panel.length; i++) {
      results.push({
        date: panel[i].date,
        occupation: panel[i].occupation,
        checked: true,
      })
    }
    setGroupedLft(results)
  }

  function updateRft(panel: GroupedLabResults[]) {
    const results: GroupedLabResults[] = []
    for (let i = 0; i < panel.length; i++) {
      results.push({
        date: panel[i].date,
        occupation: panel[i].occupation,
        checked: true,
      })
    }
    setGroupedRft(results)
  }

  function updateTft(panel: GroupedLabResults[]) {
    const results: GroupedLabResults[] = []
    for (let i = 0; i < panel.length; i++) {
      results.push({
        date: panel[i].date,
        occupation: panel[i].occupation,
        checked: true,
      })
    }
    setGroupedTft(results)
  }

  function updatePanel4(panel: GroupedLabResults[]) {
    const results: GroupedLabResults[] = []
    for (let i = 0; i < panel.length; i++) {
      results.push({
        date: panel[i].date,
        occupation: panel[i].occupation,
        checked: true,
      })
    }
    setGroupedPanel4(results)
  }

  function updatePanelHoma(panel: GroupedLabResults[]) {
    const results: GroupedLabResults[] = []
    for (let i = 0; i < panel.length; i++) {
      results.push({
        date: panel[i].date,
        occupation: panel[i].occupation,
        checked: true,
      })
    }
    setGroupedHoma(results)
  }

  function updatePanelDexa(panel: GroupedLabResults[]) {
    const results: GroupedLabResults[] = []
    for (let i = 0; i < panel.length; i++) {
      results.push({
        date: panel[i].date,
        occupation: panel[i].occupation,
        checked: true,
      })
    }
    setGroupedDexa(results)
  }

  function updatePanelSerumLytes(panel: GroupedLabResults[]) {
    const results: GroupedLabResults[] = []
    for (let i = 0; i < panel.length; i++) {
      results.push({
        date: panel[i].date,
        occupation: panel[i].occupation,
        checked: true,
      })
    }
    setGroupedSerumLytes(results)
  }

  function handleCollapse(rate: boolean, index: number) {
    const values: GroupedLabResults[] = [...groupedLabTest]
    values[index].checked = rate
    setGroupedLabTest(values)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: GroupedLabResults[] = [...groupedCbcPanel]
    values[index].checked = rate
    setGroupedCbcPanel(values)
  }
  function handleCollapseForPanel2(rate: boolean, index: number) {
    const values: GroupedLabResults[] = [...groupedPanel2]
    values[index].checked = rate
    setGroupedPanel2(values)
  }
  function handleCollapseForPanel3(rate: boolean, index: number) {
    const values: GroupedLabResults[] = [...groupedPanel3]
    values[index].checked = rate
    setGroupedPanel3(values)
  }
  function handleCollapseForLft(rate: boolean, index: number) {
    const values: GroupedLabResults[] = [...groupedLft]
    values[index].checked = rate
    setGroupedLft(values)
  }

  function handleCollapseForRft(rate: boolean, index: number) {
    const values: GroupedLabResults[] = [...groupedRft]
    values[index].checked = rate
    setGroupedRft(values)
  }

  function handleCollapseForTft(rate: boolean, index: number) {
    const values: GroupedLabResults[] = [...groupedTft]
    values[index].checked = rate
    setGroupedTft(values)
  }

  function handleCollapseForPanel4(rate: boolean, index: number) {
    const values: GroupedLabResults[] = [...groupedPanel4]
    values[index].checked = rate
    setGroupedPanel4(values)
  }

  function handleCollapseForHoma(rate: boolean, index: number) {
    const values: GroupedLabResults[] = [...groupedHoma]
    values[index].checked = rate
    setGroupedHoma(values)
  }

  function handleCollapseForDexa(rate: boolean, index: number) {
    const values: GroupedLabResults[] = [...groupedDexa]
    values[index].checked = rate
    setGroupedDexa(values)
  }

  function handleCollapseForSerumLytes(rate: boolean, index: number) {
    const values: GroupedLabResults[] = [...groupedSerumLytes]
    values[index].checked = rate
    setGroupedSerumLytes(values)
  }

  return (
    <ReactVisibilitySensor
      offset={{
        top: 100,
        bottom: 30,
      }}
      onChange={(isVisible) => {
        logger.info('Inside diagnostic')
        if (isVisible) {
          dispatch(setSelectedSection({ selectedSection: 'vitals' }))
        }
      }}
    >
      <Grid container direction='column' style={{ flexWrap: 'nowrap' }}>
        <Grid
          item
          //   onMouseEnter={() => showAddButton(true)}
          //   onMouseLeave={() => showAddButton(false)}
        >
          <Grid container direction='row' id='vitals'>
            <Box py={1}>
              <Typography variant='h6'>
                {' '}
                {t('labelCommon:lab_tests')}{' '}
              </Typography>
            </Box>{' '}
            {/* {isMedicalServiceProvider() && !isPhysiotherapist() && !split && (
                <Box>
                  <IconButton
                    style={{ padding: '4px' }}
                    onClick={() => {
                      setOpenAddDialogue(true)
                    }}
                  >
                    <Add style={{ height: '16px' }} color='primary' />
                  </IconButton>
                </Box>
              )} */}
          </Grid>

          {labTest.length === 0 &&
            panel2.length === 0 &&
            panel3.length === 0 &&
            lftResults.length === 0 &&
            homaResults.length === 0 &&
            dexaResults.length === 0 &&
            serumLytes.length === 0 &&
            cbcPanelData.length === 0 &&
            lftResults.length === 0 &&
            panel4Results.length === 0 &&
            tftResults.length === 0 && (
              <Grid item>
                <Typography
                  variant='subtitle2'
                  style={{
                    fontWeight: 400,
                  }}
                >
                  {' '}
                  No data available
                </Typography>
              </Grid>
            )}

          {labTest.length > 0 && (
            <Box display='flex' flexDirection='column' width='100%'>
              <Box py={1}>
                {groupedLabTest && groupedLabTest.length > 0 && (
                  <Grid item style={{ padding: 0, margin: 0 }} xs={12}>
                    <Box
                      flexGrow
                      width='100%'
                      display='flex'
                      flexDirection='column'
                    >
                      {groupedLabTest.map((val, index: number) => (
                        <Box key={val.date ?? ''}>
                          <Box
                            paddingX={1}
                            borderRadius={2}
                            style={{
                              backgroundColor: 'lightGrey',
                            }}
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            height={40}
                            paddingY={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Typography
                                variant='subtitle1'
                                style={{
                                  color: 'black',

                                  fontWeight: 'bold',
                                }}
                              >
                                Lab Test Results :{' '}
                                {moment(val.date).format('Do MMM YYYY hh:mm A')}
                              </Typography>
                            </Box>
                            <Box
                              justifyContent='flex-end'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Box px={1}>
                                <Tooltip title=''>
                                  <IconButton
                                    aria-label='collapse_order_type'
                                    size='small'
                                    onClick={() => {
                                      handleCollapse(!val.checked, index)
                                    }}
                                  >
                                    {val.checked && <ArrowDropUpOutlined />}
                                    {!val.checked && <ArrowDropDownOutlined />}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                          <Collapse
                            in={val.checked}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Box
                              flexGrow
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <Grid
                                container
                                direction='row'
                                id='vitals'
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Grid item xs={3}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Parameter Name
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid item key={1} xs={5}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Value
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item key={1} xs={4}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Normal Range
                                        </Typography>
                                      </Box>
                                    </Box>
                                    {/* <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box> */}
                                  </Box>
                                </Grid>
                              </Grid>
                              <LabResultTileHistory
                                obsData={val.occupation}
                                obsDefData={[]}
                                patientData={patient}
                                type='test'
                              />
                            </Box>
                          </Collapse>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}

          {/* For panel1 --------------------------------------- */}

          {cbcPanelData.length > 0 && (
            <Box display='flex' flexDirection='column' width='100%'>
              <Box>
                {groupedCbcPanel && groupedCbcPanel.length > 0 && (
                  <Grid item style={{ padding: 0, margin: 0 }} xs={12}>
                    <Box
                      flexGrow
                      width='100%'
                      display='flex'
                      flexDirection='column'
                    >
                      {groupedCbcPanel.map((val, index: number) => (
                        <Box py={1} key={val.date ?? ''}>
                          <Box
                            paddingX={1}
                            borderRadius={2}
                            style={{
                              backgroundColor: 'lightGrey',
                            }}
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            height={40}
                            paddingY={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Typography
                                variant='subtitle1'
                                style={{
                                  color: 'black',

                                  fontWeight: 'bold',
                                }}
                              >
                                CBC Panel - Blood :{' '}
                                {moment(val.date).format('Do MMM YYYY hh:mm A')}
                              </Typography>
                            </Box>
                            <Box
                              justifyContent='flex-end'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Box px={1}>
                                <Tooltip title=''>
                                  <IconButton
                                    aria-label='collapse_order_type'
                                    size='small'
                                    onClick={() => {
                                      handleCollapseForPanel1(
                                        !val.checked,
                                        index
                                      )
                                    }}
                                  >
                                    {val.checked && <ArrowDropUpOutlined />}
                                    {!val.checked && <ArrowDropDownOutlined />}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                          <Collapse
                            in={val.checked}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Box
                              flexGrow
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <Grid
                                container
                                direction='row'
                                id='vitals'
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Grid item xs={3}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Parameter Name
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid item key={1} xs={5}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Value
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item key={1} xs={4}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Normal Range
                                        </Typography>
                                      </Box>
                                    </Box>
                                    {/* <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box> */}
                                  </Box>
                                </Grid>
                              </Grid>
                              <LabResultTileHistory
                                obsData={val.occupation}
                                obsDefData={
                                  labTestSearchSlice.testData &&
                                  labTestSearchSlice.testData.length > 0
                                    ? labTestSearchSlice.testData[0].obsDef
                                    : []
                                }
                                patientData={patient}
                              />
                            </Box>
                          </Collapse>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}

          {/* For panel2 --------------------------------------- */}

          {panel2.length > 0 && (
            <Box display='flex' flexDirection='column' width='100%'>
              <Box>
                {groupedPanel2 && groupedPanel2.length > 0 && (
                  <Grid item style={{ padding: 0, margin: 0 }} xs={12}>
                    <Box
                      flexGrow
                      width='100%'
                      display='flex'
                      flexDirection='column'
                    >
                      {groupedPanel2.map((val, index: number) => (
                        <Box py={1} key={val.date ?? ''}>
                          <Box
                            paddingX={1}
                            borderRadius={2}
                            style={{
                              backgroundColor: 'lightGrey',
                            }}
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            height={40}
                            paddingY={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Typography
                                variant='subtitle1'
                                style={{
                                  color: 'black',

                                  fontWeight: 'bold',
                                }}
                              >
                                Diabetic Retinopathy Screening :{' '}
                                {moment(val.date).format('Do MMM YYYY hh:mm A')}
                              </Typography>
                            </Box>
                            <Box
                              justifyContent='flex-end'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Box px={1}>
                                <Tooltip title=''>
                                  <IconButton
                                    aria-label='collapse_order_type'
                                    size='small'
                                    onClick={() => {
                                      handleCollapseForPanel2(
                                        !val.checked,
                                        index
                                      )
                                    }}
                                  >
                                    {val.checked && <ArrowDropUpOutlined />}
                                    {!val.checked && <ArrowDropDownOutlined />}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                          <Collapse
                            in={val.checked}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Box
                              flexGrow
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <Grid
                                container
                                direction='row'
                                id='vitals'
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Grid item xs={3}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Parameter Name
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item key={1} xs={5}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Value
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item key={1} xs={4}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Normal Range
                                        </Typography>
                                      </Box>
                                    </Box>
                                    {/* <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box> */}
                                  </Box>
                                </Grid>
                              </Grid>
                              <LabResultTileHistory
                                obsData={val.occupation}
                                obsDefData={[]}
                                patientData={patient}
                              />
                            </Box>
                          </Collapse>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}

          {/* For panel3 --------------------------------------- */}

          {panel3.length > 0 && (
            <Box display='flex' flexDirection='column' width='100%'>
              <Box>
                {groupedPanel3 && groupedPanel3.length > 0 && (
                  <Grid item style={{ padding: 0, margin: 0 }} xs={12}>
                    <Box
                      flexGrow
                      width='100%'
                      display='flex'
                      flexDirection='column'
                    >
                      {groupedPanel3.map((val, index: number) => (
                        <Box py={1} key={val.date ?? ''}>
                          <Box
                            paddingX={1}
                            borderRadius={2}
                            style={{
                              backgroundColor: 'lightGrey',
                            }}
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            height={40}
                            paddingY={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Typography
                                variant='subtitle1'
                                style={{
                                  color: 'black',

                                  fontWeight: 'bold',
                                }}
                              >
                                Lipid Profile :{' '}
                                {moment(val.date).format('Do MMM YYYY hh:mm A')}
                              </Typography>
                            </Box>
                            <Box
                              justifyContent='flex-end'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Box px={1}>
                                <Tooltip title=''>
                                  <IconButton
                                    aria-label='collapse_order_type'
                                    size='small'
                                    onClick={() => {
                                      handleCollapseForPanel3(
                                        !val.checked,
                                        index
                                      )
                                    }}
                                  >
                                    {val.checked && <ArrowDropUpOutlined />}
                                    {!val.checked && <ArrowDropDownOutlined />}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                          <Collapse
                            in={val.checked}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Box
                              flexGrow
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <Grid
                                container
                                direction='row'
                                id='vitals'
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Grid item xs={3}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Parameter Name
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid item key={1} xs={5}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Value
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item key={1} xs={4}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Normal Range
                                        </Typography>
                                      </Box>
                                    </Box>
                                    {/* <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box> */}
                                  </Box>
                                </Grid>
                              </Grid>
                              <LabResultTileHistory
                                obsData={val.occupation}
                                obsDefData={
                                  labTestSearchSlice.lipidData &&
                                  labTestSearchSlice.lipidData.length > 0
                                    ? labTestSearchSlice.lipidData[0].obsDef
                                    : []
                                }
                                patientData={patient}
                              />
                            </Box>
                          </Collapse>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}

          {/* For LFT --------------------------------------- */}

          {lftResults.length > 0 && (
            <Box display='flex' flexDirection='column' width='100%'>
              <Box>
                {groupedLft && groupedLft.length > 0 && (
                  <Grid item style={{ padding: 0, margin: 0 }} xs={12}>
                    <Box
                      flexGrow
                      width='100%'
                      display='flex'
                      flexDirection='column'
                    >
                      {groupedLft.map((val, index: number) => (
                        <Box py={1} key={val.date ?? ''}>
                          <Box
                            paddingX={1}
                            borderRadius={2}
                            style={{
                              backgroundColor: 'lightGrey',
                            }}
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            height={40}
                            paddingY={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Typography
                                variant='subtitle1'
                                style={{
                                  color: 'black',

                                  fontWeight: 'bold',
                                }}
                              >
                                Liver Function Test :{' '}
                                {moment(val.date).format('Do MMM YYYY hh:mm A')}
                              </Typography>
                            </Box>
                            <Box
                              justifyContent='flex-end'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Box px={1}>
                                <Tooltip title=''>
                                  <IconButton
                                    aria-label='collapse_order_type'
                                    size='small'
                                    onClick={() => {
                                      handleCollapseForLft(!val.checked, index)
                                    }}
                                  >
                                    {val.checked && <ArrowDropUpOutlined />}
                                    {!val.checked && <ArrowDropDownOutlined />}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                          <Collapse
                            in={val.checked}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Box
                              flexGrow
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <Grid
                                container
                                direction='row'
                                id='vitals'
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Grid item xs={3}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Parameter Name
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid item key={1} xs={5}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Value
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item key={1} xs={4}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Normal Range
                                        </Typography>
                                      </Box>
                                    </Box>
                                    {/* <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box> */}
                                  </Box>
                                </Grid>
                              </Grid>
                              <LabResultTileHistory
                                obsData={val.occupation}
                                obsDefData={
                                  labTestSearchSlice.lftData &&
                                  labTestSearchSlice.lftData.length > 0
                                    ? labTestSearchSlice.lftData[0].obsDef
                                    : []
                                }
                                patientData={patient}
                              />
                            </Box>
                          </Collapse>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}

          {/* For LFT --------------------------------------- */}

          {rftResults.length > 0 && (
            <Box display='flex' flexDirection='column' width='100%'>
              <Box>
                {groupedRft && groupedRft.length > 0 && (
                  <Grid item style={{ padding: 0, margin: 0 }} xs={12}>
                    <Box
                      flexGrow
                      width='100%'
                      display='flex'
                      flexDirection='column'
                    >
                      {groupedRft.map((val, index: number) => (
                        <Box py={1} key={val.date ?? ''}>
                          <Box
                            paddingX={1}
                            borderRadius={2}
                            style={{
                              backgroundColor: 'lightGrey',
                            }}
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            height={40}
                            paddingY={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Typography
                                variant='subtitle1'
                                style={{
                                  color: 'black',

                                  fontWeight: 'bold',
                                }}
                              >
                                Renal Function Test :{' '}
                                {moment(val.date).format('Do MMM YYYY hh:mm A')}
                              </Typography>
                            </Box>
                            <Box
                              justifyContent='flex-end'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Box px={1}>
                                <Tooltip title=''>
                                  <IconButton
                                    aria-label='collapse_order_type'
                                    size='small'
                                    onClick={() => {
                                      handleCollapseForRft(!val.checked, index)
                                    }}
                                  >
                                    {val.checked && <ArrowDropUpOutlined />}
                                    {!val.checked && <ArrowDropDownOutlined />}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                          <Collapse
                            in={val.checked}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Box
                              flexGrow
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <Grid
                                container
                                direction='row'
                                id='vitals'
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Grid item xs={3}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Parameter Name
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid item key={1} xs={5}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Value
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item key={1} xs={4}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Normal Range
                                        </Typography>
                                      </Box>
                                    </Box>
                                    {/* <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box> */}
                                  </Box>
                                </Grid>
                              </Grid>
                              <LabResultTileHistory
                                obsData={val.occupation}
                                obsDefData={
                                  labTestSearchSlice.rftData &&
                                  labTestSearchSlice.rftData.length > 0
                                    ? labTestSearchSlice.rftData[0].obsDef
                                    : []
                                }
                                patientData={patient}
                              />
                            </Box>
                          </Collapse>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}

          {tftResults.length > 0 && (
            <Box display='flex' flexDirection='column' width='100%'>
              <Box>
                {groupedTft && groupedTft.length > 0 && (
                  <Grid item style={{ padding: 0, margin: 0 }} xs={12}>
                    <Box
                      flexGrow
                      width='100%'
                      display='flex'
                      flexDirection='column'
                    >
                      {groupedTft.map((val, index: number) => (
                        <Box py={1} key={val.date ?? ''}>
                          <Box
                            paddingX={1}
                            borderRadius={2}
                            style={{
                              backgroundColor: 'lightGrey',
                            }}
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            height={40}
                            paddingY={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Typography
                                variant='subtitle1'
                                style={{
                                  color: 'black',

                                  fontWeight: 'bold',
                                }}
                              >
                                Thyroid Function Test :{' '}
                                {moment(val.date).format('Do MMM YYYY hh:mm A')}
                              </Typography>
                            </Box>
                            <Box
                              justifyContent='flex-end'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Box px={1}>
                                <Tooltip title=''>
                                  <IconButton
                                    aria-label='collapse_order_type'
                                    size='small'
                                    onClick={() => {
                                      handleCollapseForTft(!val.checked, index)
                                    }}
                                  >
                                    {val.checked && <ArrowDropUpOutlined />}
                                    {!val.checked && <ArrowDropDownOutlined />}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                          <Collapse
                            in={val.checked}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Box
                              flexGrow
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <Grid
                                container
                                direction='row'
                                id='vitals'
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Grid item xs={3}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Parameter Name
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid item key={1} xs={5}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Value
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item key={1} xs={4}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Normal Range
                                        </Typography>
                                      </Box>
                                    </Box>
                                    {/* <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box> */}
                                  </Box>
                                </Grid>
                              </Grid>
                              <LabResultTileHistory
                                obsData={val.occupation}
                                obsDefData={
                                  labTestSearchSlice.tftData &&
                                  labTestSearchSlice.tftData.length > 0
                                    ? labTestSearchSlice.tftData[0].obsDef
                                    : []
                                }
                                patientData={patient}
                              />
                            </Box>
                          </Collapse>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}

          {/* For Urine --------------------------------------- */}

          {panel4Results.length > 0 && (
            <Box display='flex' flexDirection='column' width='100%'>
              <Box>
                {groupedPanel4 && groupedPanel4.length > 0 && (
                  <Grid item style={{ padding: 0, margin: 0 }} xs={12}>
                    <Box
                      flexGrow
                      width='100%'
                      display='flex'
                      flexDirection='column'
                    >
                      {groupedPanel4.map((val, index: number) => (
                        <Box key={val.date ?? ''}>
                          <Box
                            paddingX={1}
                            borderRadius={2}
                            style={{
                              backgroundColor: 'lightGrey',
                            }}
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            height={40}
                            paddingY={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Typography
                                variant='subtitle1'
                                style={{
                                  color: 'black',

                                  fontWeight: 'bold',
                                }}
                              >
                                Urinalysis Complete Panel :{' '}
                                {moment(val.date).format('Do MMM YYYY hh:mm A')}
                              </Typography>
                            </Box>
                            <Box
                              justifyContent='flex-end'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Box px={1}>
                                <Tooltip title=''>
                                  <IconButton
                                    aria-label='collapse_order_type'
                                    size='small'
                                    onClick={() => {
                                      handleCollapseForPanel4(
                                        !val.checked,
                                        index
                                      )
                                    }}
                                  >
                                    {val.checked && <ArrowDropUpOutlined />}
                                    {!val.checked && <ArrowDropDownOutlined />}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                          <Collapse
                            in={val.checked}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Box
                              flexGrow
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <Grid
                                container
                                direction='row'
                                id='vitals'
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Grid item xs={3}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Parameter Name
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid item key={1} xs={5}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Value
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item key={1} xs={4}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Normal Range
                                        </Typography>
                                      </Box>
                                    </Box>
                                    {/* <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box> */}
                                  </Box>
                                </Grid>
                              </Grid>
                              <LabResultTileHistory
                                obsData={val.occupation}
                                obsDefData={
                                  labTestSearchSlice.urineData &&
                                  labTestSearchSlice.urineData.length > 0
                                    ? labTestSearchSlice.urineData[0].obsDef
                                    : []
                                }
                                patientData={patient}
                              />
                            </Box>
                          </Collapse>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}

          {homaResults.length > 0 && (
            <Box display='flex' flexDirection='column' width='100%'>
              <Box>
                {groupedHoma && groupedHoma.length > 0 && (
                  <Grid item style={{ padding: 0, margin: 0 }} xs={12}>
                    <Box
                      flexGrow
                      width='100%'
                      display='flex'
                      flexDirection='column'
                    >
                      {groupedHoma.map((val, index: number) => (
                        <Box key={val.date ?? ''}>
                          <Box
                            paddingX={1}
                            borderRadius={2}
                            style={{
                              backgroundColor: 'lightGrey',
                            }}
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            height={40}
                            paddingY={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Typography
                                variant='subtitle1'
                                style={{
                                  color: 'black',

                                  fontWeight: 'bold',
                                }}
                              >
                                HOMA IR :{' '}
                                {moment(val.date).format('Do MMM YYYY hh:mm A')}
                              </Typography>
                            </Box>
                            <Box
                              justifyContent='flex-end'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Box px={1}>
                                <Tooltip title=''>
                                  <IconButton
                                    aria-label='collapse_order_type'
                                    size='small'
                                    onClick={() => {
                                      handleCollapseForHoma(!val.checked, index)
                                    }}
                                  >
                                    {val.checked && <ArrowDropUpOutlined />}
                                    {!val.checked && <ArrowDropDownOutlined />}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                          <Collapse
                            in={val.checked}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Box
                              flexGrow
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <Grid
                                container
                                direction='row'
                                id='vitals'
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Grid item xs={3}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Parameter Name
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item key={1} xs={5}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Value
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item key={1} xs={4}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Normal Range
                                        </Typography>
                                      </Box>
                                    </Box>
                                    {/* <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box> */}
                                  </Box>
                                </Grid>
                              </Grid>
                              <LabResultTileHistory
                                obsData={val.occupation}
                                obsDefData={
                                  labTestSearchSlice.homa &&
                                  labTestSearchSlice.homa.length > 0
                                    ? labTestSearchSlice.homa[0].obsDef
                                    : []
                                }
                                patientData={patient}
                              />
                            </Box>
                          </Collapse>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}

          {dexaResults.length > 0 && (
            <Box display='flex' flexDirection='column' width='100%'>
              <Box>
                {groupedDexa && groupedDexa.length > 0 && (
                  <Grid item style={{ padding: 0, margin: 0 }} xs={12}>
                    <Box
                      flexGrow
                      width='100%'
                      display='flex'
                      flexDirection='column'
                    >
                      {groupedDexa.map((val, index: number) => (
                        <Box key={val.date ?? ''}>
                          <Box
                            paddingX={1}
                            borderRadius={2}
                            style={{
                              backgroundColor: 'lightGrey',
                            }}
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            height={40}
                            paddingY={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Typography
                                variant='subtitle1'
                                style={{
                                  color: 'black',

                                  fontWeight: 'bold',
                                }}
                              >
                                Whole Body Dexa Scan :{' '}
                                {moment(val.date).format('Do MMM YYYY hh:mm A')}
                              </Typography>
                            </Box>
                            <Box
                              justifyContent='flex-end'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Box px={1}>
                                <Tooltip title=''>
                                  <IconButton
                                    aria-label='collapse_order_type'
                                    size='small'
                                    onClick={() => {
                                      handleCollapseForDexa(!val.checked, index)
                                    }}
                                  >
                                    {val.checked && <ArrowDropUpOutlined />}
                                    {!val.checked && <ArrowDropDownOutlined />}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                          <Collapse
                            in={val.checked}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Box
                              flexGrow
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <Grid
                                container
                                direction='row'
                                id='vitals'
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Grid item xs={3}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Parameter Name
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid item key={1} xs={5}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Value
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item key={1} xs={4}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Normal Range
                                        </Typography>
                                      </Box>
                                    </Box>
                                    {/* <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box> */}
                                  </Box>
                                </Grid>
                              </Grid>
                              <LabResultTileHistory
                                obsData={val.occupation}
                                obsDefData={
                                  labTestSearchSlice.dexa &&
                                  labTestSearchSlice.dexa.length > 0
                                    ? labTestSearchSlice.dexa[0].obsDef
                                    : []
                                }
                                patientData={patient}
                              />
                            </Box>
                          </Collapse>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}

          {serumLytes.length > 0 && (
            <Box display='flex' flexDirection='column' width='100%'>
              <Box>
                {groupedSerumLytes && groupedSerumLytes.length > 0 && (
                  <Grid item style={{ padding: 0, margin: 0 }} xs={12}>
                    <Box
                      flexGrow
                      width='100%'
                      display='flex'
                      flexDirection='column'
                    >
                      {groupedSerumLytes.map((val, index: number) => (
                        <Box key={val.date ?? ''}>
                          <Box
                            paddingX={1}
                            borderRadius={2}
                            style={{
                              backgroundColor: 'lightGrey',
                            }}
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            height={40}
                            paddingY={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Typography
                                variant='subtitle1'
                                style={{
                                  color: 'black',

                                  fontWeight: 'bold',
                                }}
                              >
                                Serum Electrolytes :{' '}
                                {moment(val.date).format('Do MMM YYYY hh:mm A')}
                              </Typography>
                            </Box>
                            <Box
                              justifyContent='flex-end'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Box px={1}>
                                <Tooltip title=''>
                                  <IconButton
                                    aria-label='collapse_order_type'
                                    size='small'
                                    onClick={() => {
                                      handleCollapseForSerumLytes(
                                        !val.checked,
                                        index
                                      )
                                    }}
                                  >
                                    {val.checked && <ArrowDropUpOutlined />}
                                    {!val.checked && <ArrowDropDownOutlined />}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                          <Collapse
                            in={val.checked}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Box
                              flexGrow
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <Grid
                                container
                                direction='row'
                                id='vitals'
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Grid item xs={3}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Parameter Name
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid item key={1} xs={5}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Value
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      paddingTop={0.1}
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item key={1} xs={4}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={1}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Normal Range
                                        </Typography>
                                      </Box>
                                    </Box>
                                    {/* <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box> */}
                                  </Box>
                                </Grid>
                              </Grid>
                              <LabResultTileHistory
                                obsData={val.occupation}
                                obsDefData={
                                  labTestSearchSlice.serumElectraLytes &&
                                  labTestSearchSlice.serumElectraLytes.length >
                                    0
                                    ? labTestSearchSlice.serumElectraLytes[0]
                                        .obsDef
                                    : []
                                }
                                patientData={patient}
                              />
                            </Box>
                          </Collapse>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </ReactVisibilitySensor>
  )
}
