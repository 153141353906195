import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { sleep } from 'utils/dateUtil'
import { getVitalsObservationObjectForIPD } from 'utils/fhirResoureHelpers/observationHelpers'
import { requestGeneralExaminationOfPatientIPD } from '../generalExaminationIPD/generalExaminationSliceIPD'
import { GeneralExamAddStatus } from './addGeneralExamStatusTypes'

const initialState: GeneralExamAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addGeneralExaminationSliceIPD = createSlice({
  name: 'addGeneralExaminationSliceIPD',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<GeneralExamAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addedVitalsBundle = action.payload.addedVitalsBundle
    },

    resetAddVitalsDetails(state, action: PayloadAction<GeneralExamAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addedVitalsBundle = initialState.addedVitalsBundle
    },
  },
})

export const addGeneralExamIPD =
  (
    appointment: FhirActiveIPDDetailsForMedicalRole,
    builtChanged: boolean,
    built?: string,
    nmentChanged?: boolean,
    nourishment?: string,
    pallorChanged?: boolean,
    pallor?: string,
    pallorRemarks?: string,
    ictChanged?: boolean,
    ict?: string,
    ictRemarks?: string,
    cynChanged?: boolean,
    cyn?: string,
    cynRemarks?: string,
    clubbingChanged?: boolean,
    clubbing?: string,
    clubbingRemarks?: string,
    lympChanged?: boolean,
    lymp?: string,
    lympRemarks?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: GeneralExamAddStatus = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(
      addGeneralExaminationSliceIPD.actions.updateAddVitalsStatus(addingState)
    )

    try {
      const bundleObject: R4.IBundle = createBundleObjectForObservations(
        appointment,
        builtChanged,
        built,
        nmentChanged,
        nourishment,
        pallorChanged,
        pallor,
        pallorRemarks,
        ictChanged,
        ict,
        ictRemarks,
        cynChanged,
        cyn,
        cynRemarks,
        clubbingChanged,
        clubbing,
        clubbingRemarks,
        lympChanged,
        lymp,
        lympRemarks
      )

      const resource: any = {
        eventType: 'vitals',
        eventBody: bundleObject,
      }

      const enRolClient: EnrolCient = new EnrolCient()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        `ipd/${appointment.mainServiceRequest.id}/addIpdEventDetails`,
        resource
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (response.status === 'VitalsDetails Of Patient added') {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        await sleep(5000)
        dispatch(
          requestGeneralExaminationOfPatientIPD(
            appointment.patient,
            appointment.mainServiceRequest.id!
          )
        )

        dispatch(showSuccessAlert('General Examination Added Successfully'))
        dispatch(
          addGeneralExaminationSliceIPD.actions.updateAddVitalsStatus(
            addingState
          )
        )
      } else {
        const errorCreatePersonState: GeneralExamAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while creating patient',
        }
        dispatch(
          addGeneralExaminationSliceIPD.actions.updateAddVitalsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: GeneralExamAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding vitals',
      }
      dispatch(
        addGeneralExaminationSliceIPD.actions.updateAddVitalsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetAddGeneralExaminationIPDState =
  () => (dispatch: AppDispatch) => {
    dispatch(
      addGeneralExaminationSliceIPD.actions.resetAddVitalsDetails(initialState)
    )
  }

function createBundleObjectForObservations(
  appointment: FhirActiveIPDDetailsForMedicalRole,
  builtChanged: boolean,
  built?: string,
  nmentChanged?: boolean,
  nourishment?: string,
  pallorChanged?: boolean,
  pallor?: string,
  pallorRemarks?: string,
  ictChanged?: boolean,
  ict?: string,
  ictRemarks?: string,
  cynChanged?: boolean,
  cyn?: string,
  cynRemarks?: string,
  clubbingChanged?: boolean,
  clubbing?: string,
  clubbingRemarks?: string,
  lympChanged?: boolean,
  lymp?: string,
  lympRemarks?: string
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }
  const practitionerRoleDetail: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()

  const practitioner: R4.IPractitioner = getCurrentUserPractitionerDetails()

  const encounterRef: R4.IReference = {
    reference: `Encounter/urn:uuid:1232323232324`,
    type: 'Encounter',
  }

  if (built != null && builtChanged) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'exam',
            display: 'exam',
          },
        ],
      },
    ]
    observationObject.code = {
      text: '',
      coding: [
        {
          code: '165184000',
          display: 'Built',
          system: 'http://snomed.info/sct',
        },
      ],
    }
    observationObject.status = R4.ObservationStatusKind._final
    if (built === 'normal') {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '301309000',
            display: 'Normal Built',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    } else if (built === 'obese') {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '271590003',
            display: 'Obese',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    } else {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '371597004',
            display: 'Emaciated',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (nourishment != null && nmentChanged) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'exam',
            display: 'exam',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'Nourishment',
      coding: [
        {
          code: '1759002',
          display: 'Nourishment',
          system: 'http://snomed.info/sct',
        },
      ],
    }
    if (nourishment === 'wellNourished') {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '248324001',
            display: 'Well nourished ',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    } else {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '248325000',
            display: 'Malnourished',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (pallor != null && pallorChanged) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }
    observationObject.meta = {
      profile: ['http://hl7.org/fhir/StructureDefinition/bodyheight'],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'exam',
            display: 'exam',
          },
        ],
      },
    ]
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.code = {
      text: '',
      coding: [
        {
          code: '1237486008',
          display: 'Pallor',
          system: 'http://snomed.info/sct',
        },
      ],
    }
    observationObject.status = R4.ObservationStatusKind._final
    if (pallor === 'present') {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '1237486008',
            display: 'Present',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    } else {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '2667000',
            display: 'Absent',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    }

    if (pallorRemarks && pallorRemarks.length > 0) {
      observationObject.note = [
        {
          authorReference: {
            reference: `${practitioner.resourceType}/${practitioner.id}`,
          },
          text: pallorRemarks,
          time: new Date().toISOString(),
        },
      ]
    }
    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (ict != null && ictChanged) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'exam',
            display: 'exam',
          },
        ],
      },
    ]
    observationObject.code = {
      text: '',
      coding: [
        {
          code: '18165001',
          display: 'Icterus',
          system: 'http://snomed.info/sct',
        },
      ],
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.status = R4.ObservationStatusKind._final
    if (ict === 'present') {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '18165001',
            display: 'Present',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    } else {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '2667000',
            display: 'Absent',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    }
    if (ictRemarks && ictRemarks.length > 0) {
      observationObject.note = [
        {
          authorReference: {
            reference: `${practitioner.resourceType}/${practitioner.id}`,
          },
          text: ictRemarks,
          time: new Date().toISOString(),
        },
      ]
    }
    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (cyn != null && cynChanged) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'exam',
            display: 'exam',
          },
        ],
      },
    ]
    observationObject.code = {
      text: '',
      coding: [
        {
          code: '3415004',
          display: 'Cyanosis',
          system: 'http://snomed.info/sct',
        },
      ],
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.status = R4.ObservationStatusKind._final
    if (cyn === 'present') {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '3415004',
            display: 'Present',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    } else {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '2667000',
            display: 'Absent',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    }
    if (cynRemarks && cynRemarks.length > 0) {
      observationObject.note = [
        {
          authorReference: {
            reference: `${practitioner.resourceType}/${practitioner.id}`,
          },
          text: cynRemarks,
          time: new Date().toISOString(),
        },
      ]
    }
    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (clubbing != null && clubbingChanged) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'exam',
            display: 'exam',
          },
        ],
      },
    ]
    observationObject.code = {
      text: '',
      coding: [
        {
          code: '4373005',
          display: 'Clubbing',
          system: 'http://snomed.info/sct',
        },
      ],
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.status = R4.ObservationStatusKind._final
    if (clubbing === 'present') {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '4373005',
            display: 'Present',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    } else {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '2667000',
            display: 'Absent',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    }
    if (clubbingRemarks && clubbingRemarks.length > 0) {
      observationObject.note = [
        {
          authorReference: {
            reference: `${practitioner.resourceType}/${practitioner.id}`,
          },
          text: clubbingRemarks,
          time: new Date().toISOString(),
        },
      ]
    }
    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (lymp != null && lympChanged) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'exam',
            display: 'exam',
          },
        ],
      },
    ]
    observationObject.code = {
      text: '',
      coding: [
        {
          code: '30746006',
          display: 'Lymphadenopathy',
          system: 'http://snomed.info/sct',
        },
      ],
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.status = R4.ObservationStatusKind._final
    if (lymp === 'present') {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '30746006',
            display: 'Present',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    } else {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '2667000',
            display: 'Absent',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    }
    if (lympRemarks && lympRemarks.length > 0) {
      observationObject.note = [
        {
          authorReference: {
            reference: `${practitioner.resourceType}/${practitioner.id}`,
          },
          text: lympRemarks,
          time: new Date().toISOString(),
        },
      ]
    }
    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  return requestBundle
}

export default addGeneralExaminationSliceIPD.reducer
