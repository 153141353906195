import { Box, Typography } from '@material-ui/core'
import { CarePlan } from 'models/carePlan'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { getLabRequestsOfAppointment } from 'redux/consultation/lab_diagnostics_requests_list/labDiagnosticsListSlice'
import { getMedicationsOfAppointment } from 'redux/consultation/medicationsListSlice/medicationsListSlice'
import { getWellnessServiceRequestsOfAppointment } from 'redux/consultation/wellness_referrals_list/wellnessReferralsListSlice'
import { fetchFollowUpAppointments } from 'redux/fhirMedicalResources/followUpAppointmentFetcher/followUpAppointmentSearchSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { CreateAppointmentHandler } from 'views/components/appointments/createAppointmentManager'
import { AddMedicationsHandler } from '../assessment/addMedication'
import { AddWellnessReferrals } from '../assessment/addWellnessReferrals'
import { MedicationsList } from '../assessment/medicationsList'
import { AddDiagnosticsTests } from './add_diagnostic_orders'
import { RecommendationWrapper } from './cdsRecommendations/cds_reccomendation_wrapper'
import { NextFollowup } from './followupPlan'
import { CarePlanInstructionList } from './instructionsList'
import { LabDiagnosticRequestsList } from './labDiagnosticList'
import { NextStepPlan } from './nextStepPlan'
import { PatientFolloWUpAppointments } from './pateintFollowUpAppointments'
import { ReferralRequestList } from './referrals_list'
import { NextSplit } from './splitPlan'
import { WellnessReferralsList } from './wellnessRefferalsList'

interface NextStepsOfConsultationProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
  followup: boolean
}

export const PlanFollowUp: React.FC<NextStepsOfConsultationProps> = ({
  fhirAppointmentDetails,
  split,
  followup,
}: NextStepsOfConsultationProps) => {
  const { t } = useTranslation(['common', 'mediction'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const [showCohorts, setShowCohorts] = useState<boolean>(false)
  const plans: CarePlan[] = [
    {
      planName: 'Hypertension Care Plan',
      planDescription: '',
    },
  ]
  const [openAddMedicationDialogue, setOpenAddMedicationDialogue] =
    useState<boolean>(false)
  const [openAddWellReferrals, setOpenAddWellReferrals] =
    useState<boolean>(false)
  const [openAddDiagnosticDetails, setOpenAddDiagnosticDetails] =
    useState<boolean>(false)
  const dispatch = useDispatch()
  const [openCreateAppointmentPopup, setOpenCreateAppointmentPopup] =
    useState(false)

  return (
    <Box display='flex' flexDirection='row' width='100%'>
      <Box display='flex' flexDirection='column' width='100%'>
        <Box display='flex' flexDirection='row' paddingY={0.5} flexGrow>
          <Box py={1}>
            <Typography
              style={{
                fontFamily: 'Open Sans',
                fontSize: 18,
                lineHeight: 'normal',
                color: 'black',
                fontWeight: 'bold',
              }}
            >
              {' '}
              Previous Appointment {t('labelCommon:Plan')}
            </Typography>
          </Box>
        </Box>

        <Box
          display='flex'
          flexDirection='row'
          padding={0.5}
          flexGrow
          width={followup ? '80%' : '60%'}
        >
          <NextFollowup
            planName={t('labelMedication:resourceName')}
            subString=''
            onClicked={() => {
              if (split === false) {
                setOpenAddMedicationDialogue(true)
              }
            }}
            bottomWidget={
              <MedicationsList
                fhirAppointmentDetails={fhirAppointmentDetails}
                split={split}
                followUp={true}
              />
            }
            /* count={2} */
          />
        </Box>
        <Box py={0.5}>
          {' '}
          <Typography variant='subtitle1'>Lab Test</Typography>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          padding={0.5}
          width={followup ? '80%' : '60%'}
        >
          <LabDiagnosticRequestsList
            fhirAppointmentDetails={fhirAppointmentDetails}
            followUp={true}
          />
          {/* <NextStepPlan
            planName={t('labelCommon:lab_test')}
            subString=''
            bottomWidget={
              <LabDiagnosticRequestsList
                fhirAppointmentDetails={fhirAppointmentDetails}
                followUp={true}
              />
            }
            onClicked={() => {
              if (split === false) {
                // setOpenAddDiagnosticDetails(true)
              }
            }}
          /> */}
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          padding={0.5}
          width={followup ? '80%' : '60%'}
        >
          <NextFollowup
            planName={t('labelCommon:followup_appointment')}
            subString=''
            bottomWidget={
              <PatientFolloWUpAppointments
                fhirPatientDetail={fhirAppointmentDetails}
                followUp={true}
              />
            }
            onClicked={() => {
              if (split === false) {
                setOpenCreateAppointmentPopup(true)
              }
            }}
          />
        </Box>
        {/*  <Box display='flex' flexDirection='row' padding={0.5}>
              <NextStepPlan
                planName='Referral Appointment'
                subString=''
                onClicked={() => {}}
                bottomWidget={
                  <ReferralRequestList
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                }
              />
            </Box> */}
        <Box
          display='flex'
          flexDirection='row'
          padding={0.5}
          width={followup ? '80%' : '60%'}
        >
          <NextFollowup
            planName='Wellness Referrals'
            subString=''
            onClicked={() => {
              if (split === false) {
                setOpenAddWellReferrals(true)
              }
            }}
            bottomWidget={
              <WellnessReferralsList
                fhirAppointmentDetails={fhirAppointmentDetails}
                followUp={true}
              />
            }
          />
        </Box>

        <Box
          display='flex'
          flexDirection='row'
          paddingY={0.5}
          width={followup ? '80%' : '60%'}
        >
          <NextFollowup
            planName='Instructions'
            subString=''
            bottomWidget={
              <CarePlanInstructionList
                fhirAppointmentDetails={fhirAppointmentDetails}
                followUp={true}
              />
            }
          />
        </Box>
        <AddDiagnosticsTests
          open={openAddDiagnosticDetails}
          fhirAppointmentDetails={fhirAppointmentDetails}
          onClose={() => {
            dispatch(getLabRequestsOfAppointment(fhirAppointmentDetails))
            setOpenAddDiagnosticDetails(false)
          }}
          onLabDiagnosticsAdded={() => {
            dispatch(getLabRequestsOfAppointment(fhirAppointmentDetails))
            setOpenAddDiagnosticDetails(false)
          }}
        />
        <AddMedicationsHandler
          open={openAddMedicationDialogue}
          fhirAppointmentDetails={fhirAppointmentDetails}
          onClose={() => {
            dispatch(getMedicationsOfAppointment(fhirAppointmentDetails))
            setOpenAddMedicationDialogue(false)
          }}
          onMedicalConditionAdded={() => {
            dispatch(getMedicationsOfAppointment(fhirAppointmentDetails))
            setOpenAddMedicationDialogue(false)
          }}
        />

        {openAddWellReferrals && (
          <AddWellnessReferrals
            open={openAddWellReferrals}
            fhirAppointmentDetails={fhirAppointmentDetails}
            onClose={() => {
              dispatch(
                getWellnessServiceRequestsOfAppointment(fhirAppointmentDetails)
              )
              setOpenAddWellReferrals(false)
            }}
            onReferralsAdded={() => {
              dispatch(
                getWellnessServiceRequestsOfAppointment(fhirAppointmentDetails)
              )
              setOpenAddWellReferrals(false)
            }}
          />
        )}
        <CreateAppointmentHandler
          open={openCreateAppointmentPopup}
          allowWalkIn={false}
          followUp={true}
          preSelectedDoctor={{
            id: fhirAppointmentDetails.practitionerDetail.practitioner.id ?? '',
            roleObject:
              fhirAppointmentDetails.practitionerDetail.practitionerRole,
            practRoleId:
              fhirAppointmentDetails.practitionerDetail.practitionerRole.id,
            practitionerObject:
              fhirAppointmentDetails.practitionerDetail.practitioner,
            name: getNameFromHumanName(
              fhirAppointmentDetails.practitionerDetail.practitioner.name ?? []
            ),
            phone: '',
            fullName: '',
            color: '',
            primaryContact: '',
            status: '',
            enabled: fhirAppointmentDetails.practitionerDetail.practitioner
              .active
              ? fhirAppointmentDetails.practitionerDetail.practitioner.active
              : false,
          }}
          fhirAppointmentDetail={fhirAppointmentDetails}
          onClose={() => {
            setOpenCreateAppointmentPopup(false)
            dispatch(fetchFollowUpAppointments(fhirAppointmentDetails))
          }}
          onAppointmentCreated={(createdAppointment) => {
            setOpenCreateAppointmentPopup(false)
            dispatch(fetchFollowUpAppointments(fhirAppointmentDetails))
          }}
        />
      </Box>
      {!split && (
        <Box display='flex' flexDirection='column' width='50%' p={2}>
          <RecommendationWrapper
            fhirAppointmentDetails={fhirAppointmentDetails}
          />
        </Box>
      )}
    </Box>
  )
}
