import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import _ from 'lodash'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedWelloMedication } from 'models/groupedWelloMedication'
import { WelloMedication } from 'models/welloMedication'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getWelloMedicationsFromBundleResponses } from 'utils/appointment_handle/medications_util'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { MedicationDetailsStatusIPD } from './medicationsListTypesIPD'

const initialState: MedicationDetailsStatusIPD = {
  fetchingImpressions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  updatedImpressions: false,
  updatingImpressions: false,
  errorWhileSearchingImpressions: false,
  errorWhileUpdatingImpressions: false,
}

const medicationsListSliceIPD = createSlice({
  name: 'medicationsListSliceIPD',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<MedicationDetailsStatusIPD>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingImpressions = action.payload.fetchingImpressions
      state.resultsAvailable = action.payload.resultsAvailable
      state.medications = action.payload.medications
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingImpressions =
        action.payload.errorWhileUpdatingImpressions
      state.updatedImpressions = action.payload.updatedImpressions
      state.updatingImpressions = action.payload.updatingImpressions
      state.errorWhileSearchingImpressions =
        action.payload.errorWhileSearchingImpressions
      state.raw = action.payload.raw
      state.groupedMedications = action.payload.groupedMedications
    },
  },
})

export const getMedicationsOfIPD =
  (
    fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole,
    followUp?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: MedicationDetailsStatusIPD = { ...initialState }
    state.fetchingImpressions = true
    dispatch(medicationsListSliceIPD.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        encounter: fhirAppointmentDetails.mainEncounter!.id!,
        _sort: '-_lastUpdated',
        '_tag:not': 'mirror-resource',
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/MedicationRequest?status=active',
        '',
        searchParameters
      )
      logger.info(
        '-----------------------------MeREQ----------------------------------------------',
        response
      )
      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingImpressions = true
        state.fetchingImpressions = false

        dispatch(medicationsListSliceIPD.actions.updatedStatus(state))
      } else {
        const conditionResponse: R4.IBundle = resp.right
        logger.info('Medication resp', conditionResponse.entry)
        if (conditionResponse?.entry && conditionResponse?.entry.length > 0) {
          logger.info(
            '-----------------------------MeREQ!----------------------------------------------'
          )
          const clinicalImpressions: WelloMedication[] =
            getWelloMedicationsFromBundleResponses(conditionResponse).sort(
              (a, b) =>
                (a.groupedDate ? a.groupedDate : '') >
                (b.groupedDate ? b.groupedDate : '')
                  ? -1
                  : (a.groupedDate ?? '') < (b.groupedDate ?? '')
                  ? 1
                  : 0
            )

          logger.info(
            '-----------------------------MeREQ2----------------------------------------------'
          )
          if (clinicalImpressions && clinicalImpressions.length > 0) {
            const finalGroupedData: GroupedWelloMedication[] = _.chain(
              clinicalImpressions
            ) // Group the elements of Array based on `color` property
              .groupBy('groupedDate')
              // `key` is group's name (color), `value` is the array of objects
              .map((value, key) => ({ date: key, medications: value,}))
              .value()
            const result = _.groupBy(clinicalImpressions, 'groupedDate')
            console.log(result)
            state.resultsAvailable = true
            state.fetchingImpressions = false
            state.medications = clinicalImpressions
            state.groupedMedications = finalGroupedData
            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingImpressions = false
            state.raw = conditionResponse.entry
              .filter((e) => e.resource?.resourceType === 'MedicationRequest')
              .map((e) => e.resource as R4.IMedicationRequest)
            dispatch(medicationsListSliceIPD.actions.updatedStatus(state))
          }
        } else {
          const errorSearchDoctor: MedicationDetailsStatusIPD = {
            ...initialState,
          }
          errorSearchDoctor.noResultsAvailable = true
          dispatch(
            medicationsListSliceIPD.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorSearchDoctor: MedicationDetailsStatusIPD = { ...initialState }
      errorSearchDoctor.errorReason = 'Error while fetching assessment details'
      errorSearchDoctor.errorWhileSearchingImpressions = false
      dispatch(medicationsListSliceIPD.actions.updatedStatus(errorSearchDoctor))
    }
  }
export default medicationsListSliceIPD.reducer
