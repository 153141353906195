import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { GroupedProcedureData } from 'models/groupedProcedure'
import { PurposeOfUse } from 'models/purposeOfUse'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getEmailOfPatient,
  getIdentifierValueBySystem,
} from 'utils/fhirResourcesHelper'
import {
  getGroupedSurgery,
  getGroupedSurgeryWithDate,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getPatientIdentifiersForSearch } from 'utils/fhirResoureHelpers/patientHelpers'
import { logger } from 'utils/logger'
import { ProcedureConditionHistoryStatus } from './proceduresHistoryTypes'

const initialState: ProcedureConditionHistoryStatus = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingProcedures: false,
}

const procedureHistorySlice = createSlice({
  name: 'procedureHistorySlice',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<ProcedureConditionHistoryStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.dateWiseProceDureList = action.payload.dateWiseProceDureList
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.procedureList = action.payload.procedureList
      state.groupedProceDure = action.payload.groupedProceDure
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
      state.dateWiseProceDureListWithDate =
        action.payload.dateWiseProceDureListWithDate
    },
  },
})

export const requestProcedureHistoryOfPatient =
  (
    selectedPatient: R4.IPatient,
    purposeOfUse: PurposeOfUse,
    split: boolean,
    appointmentId?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: ProcedureConditionHistoryStatus = {
      searchingConditions: true,
      errorWhileSearchingProcedures: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(procedureHistorySlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const endDate = `${moment(
        moment(moment(moment()).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      )
        .subtract(90, 'days')
        .toISOString()}`

      const currentDate = moment(moment().format('DD-MM-YYYY'), 'DD-MM-YYYY')
      const convertedEndDate = moment(endDate, 'DD-MM-YYYY')
      const dateForQuery = `${moment(
        moment(moment(moment()).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      )
        .subtract(90, 'days')
        .format('YYYY-MM-DD')}`

      const diffInDays = currentDate.diff(convertedEndDate, 'days')
      let diffInMonths = currentDate.diff(convertedEndDate, 'months')
      const diffInYears = currentDate.diff(convertedEndDate, 'years')

      if (diffInMonths === 2) {
        diffInMonths += 1
      }
      const searchParameters: any = {
        category: '387713003',
        _count: 500,
        _sort: '-date',
      }

      if (getPatientIdentifiersForSearch(selectedPatient).length > 0) {
        searchParameters['patient:Patient.identifier'] =
          getPatientIdentifiersForSearch(selectedPatient)
      }

      let response: any
      if (purposeOfUse === PurposeOfUse.OPDAppointment) {
        response =
          await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
            '/Procedure?verification-status= confirmed,unconfirmed',
            appointmentId!,
            searchParameters
          )
      } else if (split) {
        response = await fhirClient.doGetResourceForAppointment(
          `/Procedure?_count=500`,
          '',
          searchParameters
        )
      } else {
        response = await fhirClient.doGetResourceForAppointment(
          `/Procedure?_count=500`,
          '',
          searchParameters
        )
      }

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingProcedures = true
        state.searchingConditions = false

        dispatch(procedureHistorySlice.actions.updatedStatus(state))
      } else {
        const proceduresResponse: R4.IBundle = resp.right
        if (proceduresResponse?.total && proceduresResponse?.total > 0) {
          const finalData = getGroupedSurgery(
            proceduresResponse.entry?.map(
              (item) => item.resource as R4.IProcedure
            ) ?? []
          )

          const finalDataWithDate = getGroupedSurgeryWithDate(
            proceduresResponse.entry?.map(
              (item) => item.resource as R4.IProcedure
            ) ?? []
          )

          const finalGroupedDataWithDate: GroupedProcedureData[] = []
          if (finalDataWithDate.length > 0) {
            for (let i = 0; i < finalDataWithDate.length; i++) {
              if (finalGroupedDataWithDate.length === 0) {
                if (finalDataWithDate[i].recordedDate) {
                  finalGroupedDataWithDate.push({
                    date: finalDataWithDate[i].recordedDate!,
                    condition: [finalDataWithDate[i].condition],
                  })
                }
              } else {
                const indexData = finalGroupedDataWithDate.findIndex(
                  (x) =>
                    moment(x.date).format('YYYY-MM-DD') ===
                    moment(finalDataWithDate[i].recordedDate).format(
                      'YYYY-MM-DD'
                    )
                )
                if (indexData > -1) {
                  finalGroupedDataWithDate[indexData].condition.push(
                    finalDataWithDate[i].condition
                  )
                } else {
                  finalGroupedDataWithDate.push({
                    date: finalDataWithDate[i].recordedDate!,
                    condition: [finalDataWithDate[i].condition],
                  })
                }
              }
            }
          }

          finalGroupedDataWithDate.sort((a, b) => moment(b.date).diff(a.date))

          const finalGroupedData: GroupedProcedureData[] = []
          if (finalData.length > 0) {
            for (let i = 0; i < finalData.length; i++) {
              if (finalGroupedData.length === 0) {
                if (finalData[i].recordedDate) {
                  finalGroupedData.push({
                    date: finalData[i].recordedDate!,
                    condition: [finalData[i].condition],
                  })
                }
              } else {
                const indexData = finalGroupedData.findIndex(
                  (x) =>
                    moment(x.date).format('YYYY-MM-DD') ===
                    moment(finalData[i].recordedDate).format('YYYY-MM-DD')
                )
                if (indexData > -1) {
                  finalGroupedData[indexData].condition.push(
                    finalData[i].condition
                  )
                } else {
                  finalGroupedData.push({
                    date: finalData[i].recordedDate!,
                    condition: [finalData[i].condition],
                  })
                }
              }
            }
          }

          finalGroupedData.sort((a, b) => moment(b.date).diff(a.date))
          state.groupedProceDure = finalData
          state.dateWiseProceDureListWithDate = finalGroupedDataWithDate
          state.dateWiseProceDureList = finalGroupedData
          state.resultsAvailable = true
          state.searchingConditions = false
          state.procedureList = proceduresResponse.entry?.map(
            (item) => item.resource as R4.IProcedure
          )
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingProcedures = false
          dispatch(procedureHistorySlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: ProcedureConditionHistoryStatus = {
            searchingConditions: false,
            errorWhileSearchingProcedures: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            procedureHistorySlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: ProcedureConditionHistoryStatus = {
        searchingConditions: false,
        errorWhileSearchingProcedures: true,
        resultsAvailable: false,
        errorReason: 'Error while searching surgery',
      }
      dispatch(procedureHistorySlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default procedureHistorySlice.reducer
