import { Avatar, Box, Chip } from '@material-ui/core'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { getProfilePicDoctor } from 'utils/fhirResourcesHelper'

interface Props {
  onPractitionerSelected?: (selectedPractioner: PractitionerWithRole[]) => void
  practitionerList: PractitionerWithRole[]
  id?: string
  valueField?: string
}

export const TherapistListView: React.FC<Props> = ({
  onPractitionerSelected,
  practitionerList,
  id,
  valueField,
}: Props) => {
  const dispatch = useDispatch()
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const { t } = useTranslation()

  function handleChecked(idCheck: string) {
    if (selectedIds.includes(idCheck)) {
      const tempIds: string[] = selectedIds.filter((item) => item !== id)
      updatePractitioners(tempIds)
    } else {
      const tempIds: string[] = [...selectedIds]
      tempIds.push(idCheck)
      updatePractitioners(tempIds)
    }
  }
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false)

  function updatePractitioners(filteredIds: string[]) {
    if (onPractitionerSelected) {
      const selectedDoctors: PractitionerWithRole[] = [
        ...(practitionerList ?? []),
      ].filter((val) => filteredIds.includes(val.id ?? ''))
      onPractitionerSelected(selectedDoctors)
    }
    setSelectedIds(filteredIds)
  }

  const handleChange = (event: any) => {
    if (
      selectedIds.length > 0 &&
      selectedIds.length === practitionerList.length
    ) {
      const tempIds: string[] = []
      updatePractitioners(tempIds)
      setIsAllSelected(false)
    } else {
      const tempIds: string[] = []
      if (practitionerList) {
        for (let i = 0; i < practitionerList.length; i++) {
          tempIds.push(practitionerList[i].id ?? '')
        }
      }
      updatePractitioners(tempIds)
      setIsAllSelected(true)
    }
  }

  return (
    <Box width='100%' paddingTop={1} display='flex' flexDirection='column'>
      <Box px={2}>
        <Chip
          title='All'
          style={{
            fontSize: '18px',
            fontWeight: '500',
            padding: '2px',
            margin: '4px',
            height: '35px',
          }}
          id='all'
          label='All'
          variant={isAllSelected ? 'default' : 'outlined'}
          key='key_all'
          size='medium'
          clickable
          color='primary'
          onClick={(e) => {
            handleChange(e)
          }}
        />
        {/* <FormControlLabel
          control={
            <Checkbox
              size='small'
              onChange={handleChange}
              name='checkedB'
              color='primary'
            />
          }
          label={t('labelCommon:select_all')}
        /> */}
      </Box>

      <Box
        display='flex'
        flexWrap='wrap'
        width='75%'
        justifyContent='center'
        flexDirection='column'
        px={2}
      >
        {practitionerList &&
          practitionerList.map((item: PractitionerWithRole, index: any) => (
            <Box display='flex' flexDirection='row' key={item.name} width='75%'>
              <Chip
                title={item.fullName}
                style={{
                  fontSize: '18px',
                  fontWeight: '500',
                  padding: '2px',
                  margin: '4px',
                  height: '35px',
                }}
                avatar={
                  <Avatar
                    alt={item.name}
                    src={getProfilePicDoctor(item.practitionerObject)}
                  >
                    {item.name.charAt(0).toUpperCase()}
                  </Avatar>
                }
                label={item.fullName}
                variant={
                  selectedIds.indexOf(item.id ?? '') !== -1
                    ? 'default'
                    : 'outlined'
                }
                key={`key_${item.id}`}
                id={`doc${index}`}
                size='medium'
                clickable
                color='primary'
                onClick={(e) => {
                  let tempIds: string[] = []
                  if (selectedIds.includes(item.id ?? '')) {
                    tempIds = selectedIds.filter(
                      (newItem) => newItem !== item.id ?? ''
                    )
                  } else {
                    tempIds = [...selectedIds]
                    tempIds.push(item.id ?? '')
                  }
                  if (tempIds.length === practitionerList.length) {
                    setIsAllSelected(true)
                  } else {
                    setIsAllSelected(false)
                  }
                  updatePractitioners(tempIds)
                }}
              />
            </Box>
          ))}
      </Box>
    </Box>
  )
}
