import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core'
import Divider from '@mui/material/Divider'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { findVisaDetails } from 'redux/patient/viewVisaDetails/visaDetailsSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  getArrivalCountryCity,
  getArrivalDate,
  getDataFromCoding,
  getPassportEndDate,
  getPassportStartDate,
  getStringValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'

interface PatientProps {
  patient: R4.IPatient
  serviceId: string
}

export const VisaDetailsTile: React.FC<PatientProps> = ({
  patient,
  serviceId,
}: PatientProps) => {
  const { t } = useTranslation(['common'])

  const visaDetails = useSelector(
    (state: RootState) => state.visaDetailsSearchSlice
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(findVisaDetails(patient.id!, serviceId))

    return () => {}
  }, [])

  return (
    <>
      <Box width='100%' flexDirection='column' display='flex'>
        <Box width='100%' display='flex' flexDirection='column'>
          <Box flexDirection='column' display='flex' flexGrow width='100%'>
            <Box py={1}>
              <Box width='100%' py={1}>
                <Typography
                  variant='subtitle2'
                  style={{ color: '#4D4D4D', textTransform: 'uppercase' }}
                >
                  {' '}
                  Visa Details
                </Typography>
              </Box>
            </Box>{' '}
            {visaDetails.searching && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
                py={1}
              >
                <CircularProgress size={25} />
              </Box>
            )}
          </Box>
          {visaDetails.resultsAvailable && visaDetails.visDetails && (
            <Box>
              <Grid container>
                <Grid item xs={8}>
                  <Grid
                    container
                    direction='column'
                    justify='flex-start'
                    style={{ flexWrap: 'nowrap' }}
                  >
                    <Grid item xl>
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='column'
                        justifyContent='flex-start'
                        style={{ flexWrap: 'nowrap' }}
                      >
                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          justifyContent='flex-start'
                        >
                          <Box px={1} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              Visa No.
                            </Typography>
                          </Box>
                          <Box px={2} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              {getStringValueExtensionsOfUrl(
                                visaDetails.visDetails.extension,
                                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-number-ext'
                              )}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          justifyContent='flex-start'
                        >
                          <Box px={1} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              Visa Type
                            </Typography>
                          </Box>
                          <Box px={2} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              {getDataFromCoding(
                                visaDetails.visDetails.extension ?? [],
                                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-type-ext'
                              )}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          justifyContent='flex-start'
                        >
                          <Box px={1} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              Issue Date
                            </Typography>
                          </Box>
                          <Box px={2} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              {moment(
                                getPassportStartDate(
                                  visaDetails.visDetails.extension,
                                  'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext'
                                )
                              ).format('DD-MM-YYYY')}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          justifyContent='flex-start'
                        >
                          <Box px={1} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              Expiry Date
                            </Typography>
                          </Box>
                          <Box px={2} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              {moment(
                                getPassportEndDate(
                                  visaDetails.visDetails.extension,
                                  'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext'
                                )
                              ).format('DD-MM-YYYY')}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          justifyContent='flex-start'
                        >
                          <Box px={1} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              Visa Issue City/Country
                            </Typography>
                          </Box>
                          <Box px={2} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              {getArrivalCountryCity(
                                visaDetails.visDetails.extension ?? [],
                                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-issued-country-ext'
                              )}
                            </Typography>
                          </Box>
                        </Box>

                        <Box paddingTop={0.5}>
                          {/* <Divider textAlign='left'>
                            <Typography variant='subtitle1' color='primary'>
                              Visa Details
                            </Typography>
                          </Divider> */}
                          <Divider
                            orientation='horizontal'
                            variant='fullWidth'
                            flexItem
                            style={{ width: '100%', height: '2px' }}
                            color='primary'
                          />
                        </Box>
                        <Box width='100%' py={1}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              color: '#4D4D4D',
                              textTransform: 'uppercase',
                            }}
                          >
                            {' '}
                            Arrival Details
                          </Typography>
                        </Box>

                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          justifyContent='flex-start'
                        >
                          <Box px={1} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              Arrival Date
                            </Typography>
                          </Box>
                          <Box px={2} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              {moment(
                                getArrivalDate(
                                  visaDetails.visDetails.extension,
                                  'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-date-of-arrival-ext'
                                )
                              ).format('DD-MM-YYYY')}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          justifyContent='flex-start'
                        >
                          <Box px={1} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              Purpose Of Visit
                            </Typography>
                          </Box>
                          <Box px={2} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              {getDataFromCoding(
                                visaDetails.visDetails.extension ?? [],
                                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-purpose-of-visit-ext'
                              )}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          justifyContent='flex-start'
                        >
                          <Box px={1} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              Arrival City/Country
                            </Typography>
                          </Box>
                          <Box
                            px={2}
                            display='flex'
                            width='50%'
                            flexDirection='row'
                          >
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              {
                                getArrivalCountryCity(
                                  visaDetails.visDetails.extension ?? [],
                                  'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-arrived-from-ext'
                                )!
                              }
                            </Typography>
                          </Box>
                        </Box>

                        <Box paddingTop={0.5}>
                          {/* <Divider textAlign='left'>
                            <Typography variant='subtitle1' color='primary'>
                              Visa Details
                            </Typography>
                          </Divider> */}
                          <Divider
                            orientation='horizontal'
                            variant='fullWidth'
                            flexItem
                            style={{ width: '100%', height: '2px' }}
                            color='primary'
                          />
                        </Box>
                        <Box width='100%' py={1}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              color: '#4D4D4D',
                              textTransform: 'uppercase',
                            }}
                          >
                            {' '}
                            Next Destination Details
                          </Typography>
                        </Box>

                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          justifyContent='flex-start'
                        >
                          <Box px={1} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              Destination City/Country
                            </Typography>
                          </Box>
                          <Box
                            px={2}
                            display='flex'
                            width='50%'
                            flexDirection='row'
                          >
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              {
                                getArrivalCountryCity(
                                  visaDetails.visDetails.extension ?? [],
                                  'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-next-destination-ext'
                                )!
                              }
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}
