import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetUnitCompletionState,
  updateUnitTypeSpecificDetails,
} from 'redux/administration/unitSetup/unitProfileCompletionSlice/unitProfileCompletionSlice'
import { RootState } from 'redux/rootReducer'
import {
  isCurrentUnitIsClinic,
  isCurrentUnitIsCollectionCenter,
  isCurrentUnitIsLab,
} from 'services/userDetailsService'
import { isClinic } from 'utils/fhirResoureHelpers/unit_setup_helpers'
import { logger } from 'utils/logger'
import { AddUnitsTimingDetailsSetUp } from './add_unit_timing_details'

interface Props {
  unitOrganization: R4.IOrganization
  unitLocation: R4.ILocation
  open: boolean
  onBackClick: () => void
  onContinueClick: () => void
  task?: R4.ITask
}

export const UpdateUnitTimingsDetails: React.FC<Props> = ({
  unitOrganization,
  unitLocation,
  open,
  onBackClick: onClose,
  onContinueClick,
  task,
}) => {
  const unitProfileCompletionSlice = useSelector(
    (state: RootState) => state.unitProfileCompletionSlice
  )
  const unitOrgResource: R4.IOrganization = {
    ...unitOrganization,
  }
  const unitLocationResource: R4.ILocation = {
    ...unitLocation,
  }

  const isUpdating =
    unitProfileCompletionSlice.updatingBasicDetails ||
    unitProfileCompletionSlice.updatingUnitTypeSpecificDetails

  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    if (unitProfileCompletionSlice.updatedUnitTypeSpecificDetails) {
      dispatch(resetUnitCompletionState())
      onContinueClick()
    }
  }, [
    unitProfileCompletionSlice.updatedUnitTypeSpecificDetails,
    onContinueClick,
    dispatch,
  ])

  function validateSpecificDetails() {
    dispatch(updateUnitTypeSpecificDetails(unitLocationResource))
  }

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>
        {unitProfileCompletionSlice.currentStep === 'basic_details'
          ? isCurrentUnitIsClinic() || isCurrentUnitIsCollectionCenter()
            ? t('labelCommon:enter_clinic_details')
            : isCurrentUnitIsLab()
            ? t('labelCommon:enter_lab_details')
            : t('labelCommon:enter_clinic_details')
          : t('labelCommon:enter_business_timings')}
      </DialogTitle>
      <DialogContent dividers={true}>
        <AddUnitsTimingDetailsSetUp
          location={unitLocationResource}
          isUpdate={true}
        />
      </DialogContent>
      <DialogActions>
        {unitProfileCompletionSlice.updatingBasicDetails && (
          <CircularProgress />
        )}
        {unitProfileCompletionSlice.updatingUnitTypeSpecificDetails && (
          <CircularProgress />
        )}

        {!isUpdating && (
          <Box flex='display' flexDirection='row'>
            <Box display='flex' flexGrow width='100%' px={1}>
              {/* <Box display='flex' flexGrow='1' flexDirection='row'>
                  <Button variant='contained' color='primary'>
                    {t('preview')}
                  </Button>
                </Box> */}
              <Box display='flex' flexDirection='row'>
                <Box px={1}>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => {
                      onClose()
                    }}
                  >
                    {t('back')}
                  </Button>
                </Box>
                <Box px={1}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      logger.info('Pract Role ', unitLocationResource)
                      validateSpecificDetails()
                    }}
                  >
                    {t('finish')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  )
}
