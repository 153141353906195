/* eslint-disable prefer-destructuring */
/* eslint-disable no-await-in-loop */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource } from 'axios'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { DateWiseIPDAppointments } from 'models/dateSeparatedIPDAppointments'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import { ReportColumns } from 'models/Report/reportColumn'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getCurrentUserUnitReference,
  isOrgAdmin,
  isTherapist,
} from 'services/userDetailsService'
import { getIdentifierValueBySystem } from 'utils/fhirResourcesHelper'
import { getDataFOrDashboardTable } from 'utils/fhirResoureHelpers/dashboardReportHelper'
import {
  getDateWiseIPD,
  getExpandedServiceRequestFromBundleForIpd,
} from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { logger } from 'utils/logger'
import { DashboardSearchStatus } from './dashboardReportSearchStatus'

const initialState: DashboardSearchStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingOrders: false,
}

let currentSelectedDate: Date | undefined

const dashboardReportSearchSlice = createSlice({
  name: 'dashboardReportSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<DashboardSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableAppointments = action.payload.availableAppointments
      state.errorWhileSearchingOrders = action.payload.errorWhileSearchingOrders
    },
    resetPatientDetails(state, action: PayloadAction<DashboardSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableAppointments = action.payload.availableAppointments
      state.errorWhileSearchingOrders = action.payload.errorWhileSearchingOrders
    },
  },
})

export const requestAppointmentForReport =
  (
    type: string,
    code: string,
    nameSearchString: string,
    phoneSearchString: string,
    selectedDate?: Date,
    selectedEndDate?: Date,
    startMonth?: Date,
    endMonth?: Date
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    currentSelectedDate = selectedDate
    let phoneChangedData: string = ''
    if (phoneSearchString.length > 1)
      phoneChangedData = `%2B91${phoneSearchString}`

    const state: DashboardSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(dashboardReportSearchSlice.actions.updatedStatus(state))
    try {
      const unitDetails = getCurrentUserUnitReference()
      const start = `${moment(moment(selectedDate).format('YYYY-MM-DD'))
        .startOf('day')
        .utc()
        .format()}`
      const end = `${moment(moment(selectedEndDate).format('YYYY-MM-DD'))
        .endOf('day')
        .utc()
        .format()}`

      const searchParameters: any = {
        _count: 500,
        status: 'active,completed',
      }
      let startDateFinal: string = ''
      let endDateFinal: string = ''
      if (type === 'dateWise') {
        if (selectedDate) {
          //   searchParameters.occurrence = `ge${moment(selectedDate)
          //     .startOf('day')
          //     .format('YYYY-MM-DDTHH:mm:ssZ')}`
          startDateFinal = `ge${moment(selectedDate)
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ssZ')}`
        }
        if (selectedEndDate) {
          //   searchParameters.occurrence = `le${moment(selectedEndDate)
          //     .endOf('day')
          //     .format('YYYY-MM-DDTHH:mm:ssZ')}`
          endDateFinal = `le${moment(selectedEndDate)
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ssZ')}`
        }
      } else {
        if (startMonth) {
          //   searchParameters.occurrence = `ge${moment(startMonth)
          //     .startOf('month')
          //     .format('YYYY-MM-DDTHH:mm:ssZ')}`
          startDateFinal = `ge${moment(startMonth)
            .startOf('month')
            .format('YYYY-MM-DDTHH:mm:ssZ')}`
        }
        if (endMonth) {
          //   searchParameters.occurrence = `le${moment(endMonth)
          //     .endOf('month')
          //     .format('YYYY-MM-DDTHH:mm:ssZ')}`
          endDateFinal = `le${moment(endMonth)
            .endOf('month')
            .format('YYYY-MM-DDTHH:mm:ssZ')}`
        }
      }

      if (!isOrgAdmin()) {
        if (unitDetails) {
          searchParameters.performer = unitDetails.reference
        }
      }

      let firstName: string = ''
      let middleName: string = ''
      let lastName: string = ''

      if (nameSearchString.includes(' ')) {
        const localNameArr: string[] = nameSearchString.split(' ')
        if (localNameArr.length > 2) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          middleName = localNameArr[2]
          lastName = localNameArr[1]
          firstName = localNameArr[0]
        } else if (localNameArr.length === 2) {
          lastName = localNameArr[1]
          firstName = localNameArr[0]
        } else {
          firstName = nameSearchString
        }
      } else {
        firstName = nameSearchString
      }

      const enRolClient: MasterFhirClient = new MasterFhirClient()

      axios.CancelToken.source()
      if (cancelTokenStore.has('ipdMainSearchControlToken')) {
        const controlTokenForSearch: CancelTokenSource = cancelTokenStore.get(
          'ipdMainSearchControlToken'
        )
        controlTokenForSearch.cancel('new param added')
        cancelTokenStore.delete('ipdMainSearchControlToken')
      }
      cancelTokenStore.set(
        'ipdMainSearchControlToken',
        axios.CancelToken.source()
      )

      const response: any = await enRolClient.doGetResourceReport(
        `/ServiceRequest?code=${code}&subject.name:contains=${firstName}&subject.given=${middleName}&subject.family=${lastName}&subject.phone=${phoneChangedData}&_include=ServiceRequest:subject&occurrence=${startDateFinal}&occurrence=${endDateFinal}&_sort=occurrence`,
        searchParameters,
        (cancelTokenStore.get('ipdMainSearchControlToken') as CancelTokenSource)
          .token
      )
      if (response !== undefined) {
        const resp: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)

        const appointmentResponse: R4.IBundle = response
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          const finalFhirAppointments: FhirClinicIpdDetails[] = []
          const finalFhirAppointmentsWithLocation: FhirClinicIpdDetails[] = []
          let dataTableReport: ReportColumns[] = []
          const fhirAppointments: FhirClinicIpdDetails[] =
            getExpandedServiceRequestFromBundleForIpd(appointmentResponse)
          fhirAppointments.sort((n1, n2) => n1.ipdNumber - n2.ipdNumber)
          fhirAppointments.sort((a, b) => moment(a.start).diff(b.start))

          if (fhirAppointments.length > 0) {
            for (let i = 0; i < fhirAppointments.length; i++) {
              let ipdId: string =
                getIdentifierValueBySystem(
                  fhirAppointments[i].serviceRequest.identifier ?? [],
                  'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                ) ?? ''
              if (ipdId.length === 0)
                ipdId =
                  getIdentifierValueBySystem(
                    fhirAppointments[i].serviceRequest.identifier ?? [],
                    'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                  ) ?? ''

              if (ipdId.length > 0) {
                const ipdDetails: FhirClinicIpdDetails = await getPayment(
                  ipdId,
                  fhirAppointments[i]
                )
                finalFhirAppointments.push(ipdDetails)
              }
            }
          }

          if (code === '281685003') {
            let locationRef: string = ''
            for (let i = 0; i < finalFhirAppointments.length; i++) {
              if (finalFhirAppointments[i].serviceRequest.locationReference) {
                const locationRefArr: R4.IReference[] =
                  finalFhirAppointments[i].serviceRequest.locationReference ??
                  []

                if (locationRefArr.length > 0) {
                  locationRef = locationRefArr[0].reference?.split('/')[1] ?? ''
                  if (locationRef.length > 0) {
                    const ipdDetails: FhirClinicIpdDetails =
                      await getLocationForIPD(
                        locationRef,
                        finalFhirAppointments[i]
                      )
                    finalFhirAppointmentsWithLocation.push(ipdDetails)
                  }
                }
              }
            }

            if (type === 'dateWise') {
              dataTableReport = getDataFOrDashboardTable(
                finalFhirAppointmentsWithLocation,
                selectedDate
              )
            } else if (startMonth) {
              dataTableReport = getDataFOrDashboardTable(
                finalFhirAppointmentsWithLocation,
                moment(startMonth).startOf('month').toDate()
              )
            }
          } else if (type === 'dateWise') {
            dataTableReport = getDataFOrDashboardTable(
              finalFhirAppointments,
              selectedDate
            )
          } else {
            dataTableReport = getDataFOrDashboardTable(
              finalFhirAppointments,
              moment(startMonth).startOf('month').toDate()
            )
          }

          if (dataTableReport.length > 0) {
            state.resultsAvailable = true
            state.searchingAppointments = false
            state.availableAppointments = dataTableReport

            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingOrders = false
            dispatch(dashboardReportSearchSlice.actions.updatedStatus(state))
          } else {
            const errorSearchDoctor: DashboardSearchStatus = {
              searchingAppointments: false,
              errorWhileSearchingOrders: false,
              resultsAvailable: false,
              noResultsAvailable: true,
            }
            dispatch(
              dashboardReportSearchSlice.actions.updatedStatus(
                errorSearchDoctor
              )
            )
          }
        } else {
          console.error('---------error ---- ------------')
          const errorSearchDoctor: DashboardSearchStatus = {
            searchingAppointments: false,
            errorWhileSearchingOrders: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            dashboardReportSearchSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      }
    } catch (error) {
      console.error('---------error------------')
      console.error(error)
      /*  const errorSearchDoctor: IpdAppointmentSearchStatus = {
        searchingAppointments: false,
        errorWhileSearchingOrders: true,
        resultsAvailable: false,
        errorReason: 'Error while fetching appointments',
        selectedStatuses: selectedStatus ?? [],
        selectedDate,
        availableAppointments: existingDataRawData,
        dateWiseAppointments: existingDateWiseData,
        pageState,
        recordsCount,
      }
      dispatch(
        ipdAppointmentSearchSlice.actions.updatedStatus(errorSearchDoctor)
      ) */
    }
  }

export async function getLocationForIPD(
  locationId: string,
  fhirClinicIpdDetails: FhirClinicIpdDetails
): Promise<FhirClinicIpdDetails> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const response: any = await fhirClient.doGetResource(
    `/Location/${locationId}`
  )

  const responseBundle: R4.ILocation = response as R4.ILocation
  fhirClinicIpdDetails.location = responseBundle

  return fhirClinicIpdDetails
}

export async function getPayment(
  ipdId: string,
  fhirClinicIpdDetails: FhirClinicIpdDetails
): Promise<FhirClinicIpdDetails> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const response: any = await fhirClient.doGetResource(
    `/PaymentReconciliation?identifier=${ipdId}`
  )
  const responseBundle: R4.IBundle = response as R4.IBundle
  if (responseBundle.entry) {
    fhirClinicIpdDetails.paymentReconilation = responseBundle.entry[0]
      .resource as R4.IPaymentReconciliation
  }

  return fhirClinicIpdDetails
}

export const resetReportSlice = () => (dispatch: AppDispatch) => {
  dispatch(dashboardReportSearchSlice.actions.resetPatientDetails(initialState))
}

export default dashboardReportSearchSlice.reducer
