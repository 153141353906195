import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { CardsEntity } from 'redux/subscription/cpgRecommendations/cpgResponse'

import {
  getGoalFromEntries,
  hasError,
} from 'utils/careplan_utils/cpg_recommendations_utils'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',

    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))
interface CdsCardDetailsProps {
  cdsCard: CardsEntity
}

export const SummaryOfCPGRecommendation: React.FC<CdsCardDetailsProps> = ({
  cdsCard: card,
}: CdsCardDetailsProps) => {
  const classes = useStyles()

  return (
    <Box display='flex' flexDirection='column' py={0.25} overflow='auto'>
      {!hasError(card) &&
        card.suggestions &&
        card.suggestions?.map((each, headerIndex: number) => (
          <Box
            display='flex'
            flexDirection='column'
            key={`${each.label}_label`}
            py={0.25}
            pl={2}
            overflow='auto'
          >
            {card.detail && card.detail.length > 0 && (
              <Box
                display='flex'
                flexDirection='column'
                key={`${each.label}_each_label`}
                py={0.25}
                overflow='auto'
              >
                <Typography
                  display='inline'
                  style={{ whiteSpace: 'pre-line' }}
                  className={classes.subTitleText}
                >
                  {card.detail}
                </Typography>
              </Box>
            )}
          </Box>
        ))}
    </Box>
  )
}
