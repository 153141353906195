/* eslint-disable no-await-in-loop */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserUnitDetails,
  getCurrentUserUnitLocationDetails,
  getUserDetails,
} from 'services/userDetailsService'
import { getValueCodingFromExtension } from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import haversine from 'haversine-distance'
import { UnitSearchStatusType } from './unitSearchStatusTypes'

const initialState: UnitSearchStatusType = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const unitSearchSlice = createSlice({
  name: 'unitSearchSlice',
  initialState,
  reducers: {
    searchingPatientDetails(
      state,
      action: PayloadAction<UnitSearchStatusType>
    ) {},

    searchResults(state, action: PayloadAction<UnitSearchStatusType>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.unitsList = action.payload.unitsList
    },

    noDataFoundForSearch(state, action: PayloadAction<UnitSearchStatusType>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.unitsList = action.payload.unitsList
    },

    errorWhileSearching(state, action: PayloadAction<UnitSearchStatusType>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.unitsList = action.payload.unitsList
    },
  },
})

export const searchUnits = (): AppThunk => async (dispatch: any) => {
  const errorFetchingUnits: UnitSearchStatusType = {
    error: false,
    noResultsAvailable: false,
    resultsAvailable: false,
    searching: true,
  }
  dispatch(unitSearchSlice.actions.errorWhileSearching(errorFetchingUnits))
  try {
    let searchParameter = {}
    searchParameter = {
      _count: 300,
    }
    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const response: any = await fhirClient.doGetResource(
      '/Organization?type=lab,collection_center&_tag:not=mirror-resource',
      searchParameter
    )
    const orgResponse: R4.IBundle = response
    if (orgResponse.total) {
      if (orgResponse.total > 0) {
        if (orgResponse.entry) {
          const orgArray: R4.IOrganization[] = orgResponse.entry.map(
            (item) => item.resource as R4.IOrganization
          )

          const finalOrg: R4.IOrganization[] = []

          orgArray.sort((a, b) =>
            (a.name ?? '')
              .toLowerCase()
              .localeCompare((b.name ?? '').toLowerCase())
          )
          for (let i = 0; i < orgArray.length; i++) {
            const extenSionVal = getValueCodingFromExtension(
              orgArray[i].extension,
              'http://terminology.hl7.org/CodeSystem/v3-Confidentiality'
            )
            if (extenSionVal) {
              if (extenSionVal.code && extenSionVal.code === 'U') {
                const isValid = await getLocationByOrgId(orgArray[i].id ?? '')
                if (isValid) {
                  const existing = await getTaskByOrgId(orgArray[i].id ?? '')
                  if (existing) {
                    finalOrg.push(orgArray[i])
                  }
                }
              }
            }
          }
          if (finalOrg.length > 0) {
            const fetchUnitListResult: UnitSearchStatusType = {
              error: false,
              noResultsAvailable: false,
              resultsAvailable: true,
              searching: false,
              unitsList: finalOrg,
              totalCount: finalOrg.length,
            }
            dispatch(unitSearchSlice.actions.searchResults(fetchUnitListResult))
            return
          }
          const noSearchResults: UnitSearchStatusType = {
            error: false,
            noResultsAvailable: true,
            resultsAvailable: false,
            searching: false,
          }
          dispatch(
            unitSearchSlice.actions.noDataFoundForSearch(noSearchResults)
          )
          return
        }
      }
    }
    const noSearchResults: UnitSearchStatusType = {
      error: false,
      noResultsAvailable: true,
      resultsAvailable: false,
      searching: false,
    }
    dispatch(unitSearchSlice.actions.noDataFoundForSearch(noSearchResults))
    return
  } catch (error) {
    logger.error(error)
    const errorWhileSearchPatient: UnitSearchStatusType = {
      error: true,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: 'Error',
    }
    dispatch(
      unitSearchSlice.actions.errorWhileSearching(errorWhileSearchPatient)
    )
  }
}

async function getLocationByOrgId(orgId: string): Promise<boolean> {
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirApi.doGetResource(
    `/Location?organization=${orgId}`
  )
  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)

  if (response.entry) {
    if (response.entry.length > 0) {
      const location = response.entry[0].resource as R4.ILocation
      if (location.position) {
        const clinicLocation: R4.ILocation = getCurrentUserUnitLocationDetails()
        if (clinicLocation.position) {
          const clinicObj = {
            lat: clinicLocation.position.latitude ?? 0,
            lng: clinicLocation.position.longitude ?? 0,
          }
          const labObj = {
            lat: location.position.latitude ?? 0,
            lng: location.position.longitude ?? 0,
          }
          const distance = haversine(clinicObj, labObj) / 1000
          if (Number(distance.toFixed(2)) < 20.0) {
            return true
          }
        }
      }
    }
  }

  return false
}

async function getTaskByOrgId(orgId: string): Promise<boolean> {
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirApi.doGetResource(
    `/Task?subject=Organization/${orgId}&code=partner-lab-invitation`
  )
  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)

  if (response.entry) {
    if (response.entry.length > 0) {
      const taskData: R4.ITask[] = response.entry
        .filter((e: any) => e.resource?.resourceType === 'Task')
        .map((e: any) => e.resource as R4.ITask)
      for (let i = 0; i < taskData.length; i++) {
        const ownerData = taskData[i].owner
        if (ownerData) {
          const orgData = ownerData.id ?? ''
          if (orgData === getCurrentUserUnitDetails().id) {
            return false
          }
          return true
        }
      }
    }
  }
  return true
}

export default unitSearchSlice.reducer
