import { R4 } from '@ahryman40k/ts-fhir-types'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserPractitionerRoleRef } from 'services/userDetailsService'
import { updateDetails } from './instructionPlanHelpers'

export async function addNewImagingPrescriptionsInFhir(
  prescriptionsBundle: R4.IBundle
) {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateFHIRTransaction('', prescriptionsBundle)

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  return true
}

export async function addNewImagingPrescriptions(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  imagingCode: R4.ICoding[]
) {
  let result = false
  try {
    const svs = imagingCode.map((code) =>
      getServiceRequestObjectFrom(patientReference, encounterId, code)
    )

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: svs.map((sv) => ({
        resource: sv,
        fullUrl: 'ServiceRequest/',
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: 'ServiceRequest',
        },
      })),
    }

    result = await addNewImagingPrescriptionsInFhir(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

function getServiceRequestObjectFrom(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  instruction: R4.ICoding
): R4.IServiceRequest {
  const communicationRequest: R4.IServiceRequest = {
    resourceType: 'ServiceRequest',
    subject: patientReference,
    status: 'active',
    priority: 'routine',
    intent: 'proposal',
    category: [
      {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '363679005',
            display: 'Imaging',
          },
        ],
      },
    ],
    code: {
      coding: [instruction],
    },
    encounter: encounterId,
    authoredOn: new Date().toISOString(),

    requester: getCurrentUserPractitionerRoleRef(),
  }

  return communicationRequest
}

export async function UpdateImaging(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  imagingCode: R4.ICoding[],
  existingImaging: R4.IServiceRequest,
  appointmentId: string
) {
  let result = false
  try {
    const svs = imagingCode.map((code) =>
      getServiceRequestObjectFrom(patientReference, encounterId, code)
    )

    const oldImaging: R4.IServiceRequest = { ...existingImaging }
    oldImaging.status = 'entered-in-error'

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: svs.map((sv) => ({
        resource: sv,
        fullUrl: 'ServiceRequest/',
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: 'ServiceRequest',
        },
      })),
    }
    requestBundle.entry?.push({
      resource: oldImaging,
      fullUrl: `${oldImaging.resourceType}/${oldImaging.id}`,
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `${oldImaging.resourceType}/${oldImaging.id}`,
      },
    })

    result = await updateDetails(requestBundle, appointmentId)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}
