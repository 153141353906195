import {
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Box,
  Divider,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import _, { sumBy } from 'lodash'

import React from 'react'
import { useRecoilValue } from 'recoil'
import {
  cartCustomerTotalDiscountWithPercentage,
  cartDoctorMargin,
  CartItem,
  cartItemDiscountInRs,
  cartPrice,
  cartPriceForPatient,
} from '../../../models/medicine-delivery/cart/cart-models'
import {
  cartOrderForCachedState,
  cartState,
} from '../../../models/medicine-delivery/cart/cart-states'
import { DeliveryCharge } from './delivery-charge'

export const CartPaymentSummary: React.FC = () => {
  const cart = useRecoilValue<any>(cartState)
  const orderFor = useRecoilValue<any>(cartOrderForCachedState)

  if (!cart || cart.items.length === 0) {
    return <></>
  }
  const totalMrp = sumBy(
    cart.items,
    (item: any) => item.quantity * (item.product.sku.price || 0)
  )
  //   const customerTotalDiscount = sumBy(
  //     cart.items,
  //     (item: any) => item.customerDiscount * item.quantity
  //   )

  const customerTotalDiscount = cartCustomerTotalDiscountWithPercentage(
    cart.items
  )

  const doctorTotalMargin = sumBy(cart.items, (item: any) => {
    const marginPerItem = cartItemDiscountInRs(item)
    return marginPerItem * item.quantity
  })

  const { delivery } = cart // _.round(0.05 * totalMrp, 2);
  let totalAmount: number = 0
  if (orderFor === 'clinic') {
    totalAmount = totalMrp - doctorTotalMargin - 0 + delivery
  } else {
    totalAmount =
      totalMrp - doctorTotalMargin - customerTotalDiscount + delivery
  }

  if (orderFor === 'patient') {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <List style={{ border: '1px solid grey', paddingBottom: 0 }}>
            <ListItem>
              <ListItemText>
                <Typography variant='subtitle2'>MRP</Typography>
              </ListItemText>
              <ListItemSecondaryAction>
                <Typography variant='subtitle2'>
                  &#x20b9; {totalMrp.toFixed(2)}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText>
                {' '}
                <Typography variant='subtitle2'>Doctor's Margin</Typography>
              </ListItemText>
              <ListItemSecondaryAction>
                <Typography variant='subtitle2'>
                  &#x20b9; {doctorTotalMargin.toFixed(2)}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText>
                {' '}
                <Typography variant='subtitle2'>Customer Discount </Typography>
              </ListItemText>
              <ListItemSecondaryAction>
                <Typography variant='subtitle2'>
                  &#x20b9; {customerTotalDiscount.toFixed(2)}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText>
                {' '}
                <Typography variant='subtitle2'>
                  Customer Discounted Price
                </Typography>
              </ListItemText>
              <ListItemSecondaryAction>
                <Typography variant='subtitle2'>
                  &#x20b9; {cartPriceForPatient(cart.items).toFixed(2)}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText>
                {' '}
                <Typography variant='subtitle2'>Delivery Charge</Typography>
              </ListItemText>
              <ListItemSecondaryAction>
                <Typography variant='subtitle2'>
                  &#x20b9; {delivery.toFixed(2)}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant='middle' />
            <ListItem>
              <ListItemText>
                {' '}
                <Typography variant='subtitle2'>Amount Total</Typography>
              </ListItemText>
              <ListItemSecondaryAction>
                <Typography variant='subtitle2'>
                  &#x20b9; {totalAmount.toFixed(2)}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <Box height={30} style={{ backgroundColor: kDialogueBackground }}>
              <ListItem style={{ paddingTop: 0 }}>
                <ListItemText>
                  {' '}
                  <Typography variant='subtitle2'>
                    Doctor's Total Margin
                  </Typography>
                </ListItemText>
                <ListItemSecondaryAction>
                  <Typography variant='subtitle2'>
                    {' '}
                    &#x20b9; {cartDoctorMargin(cart.items).toFixed(2)}{' '}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            </Box>
            <Box height={30} style={{ backgroundColor: kDialogueBackground }}>
              <ListItem style={{ paddingTop: 0 }}>
                <ListItemText>
                  {' '}
                  <Typography variant='subtitle2'>Patient Saving </Typography>
                </ListItemText>
                <ListItemSecondaryAction>
                  &#x20b9; {customerTotalDiscount.toFixed(2)}
                </ListItemSecondaryAction>
              </ListItem>
            </Box>
          </List>
        </Grid>
      </Grid>
    )
  }

  return (
    <Box width='100%'>
      <Grid container>
        <Grid item xs={12}>
          <List style={{ border: '1px solid grey', paddingBottom: 0 }}>
            <ListItem>
              <ListItemText>
                {' '}
                <Typography variant='subtitle2'>MRP</Typography>
              </ListItemText>
              <ListItemSecondaryAction>
                <Typography variant='subtitle2'>
                  &#x20b9; {totalMrp.toFixed(2)}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText>
                {' '}
                <Typography variant='subtitle2'>Discount</Typography>
              </ListItemText>
              <ListItemSecondaryAction>
                <Typography variant='subtitle2'>
                  &#x20b9; {doctorTotalMargin.toFixed(2)}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <DeliveryCharge />
            <Divider variant='middle' />
            <ListItem>
              <ListItemText>
                {' '}
                <Typography variant='subtitle2'>Amount Total</Typography>
              </ListItemText>
              <ListItemSecondaryAction>
                <Typography variant='subtitle2'>
                  &#x20b9; {totalAmount.toFixed(2)}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <Box height={30} style={{ backgroundColor: kDialogueBackground }}>
              <ListItem style={{ paddingTop: 0 }}>
                <ListItemText>
                  {' '}
                  <Typography variant='subtitle2'>Total Saving</Typography>
                </ListItemText>
                <ListItemSecondaryAction>
                  <Typography variant='subtitle2'>
                    &#x20b9; {doctorTotalMargin.toFixed(2)}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            </Box>
          </List>
        </Grid>
      </Grid>
    </Box>
  )
}
