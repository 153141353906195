/* eslint-disable react-hooks/exhaustive-deps */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Calendar } from '@mantine/dates'
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core'
import { GridOnOutlined } from '@material-ui/icons'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { kPrimaryLight, kPrimaryMain } from 'configs/styles/muiThemes'
import { DoctorBase } from 'lib/openApi'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { searchInvitations } from 'redux/administration/userSetup/userOnBoardInvitations/userOnboardInvitationSlice'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { requestOrdersCountForToday } from 'redux/lab/order/orderCount/orderCountSlice'
import {
  fetchOrderDetails,
  requestCancellationOfOrder,
  requestRescheduleOfOrder,
} from 'redux/lab/order/orderManagementSlice/orderManagementSlice'
import orderSearchSlice, {
  requestOrdersForTheDateRange,
} from 'redux/lab/order/orderSearchSlice/orderSearchSlice'
import { resetState } from 'redux/patient/addPatient/addPatientSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserUnitDetails,
  getCurrentUserUnitLocationDetails,
} from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import { isToday } from 'utils/dateUtil'
import {
  getCodeOfSystemFromCodableConceptList,
  getIdentifierValueBySystemTypeDAataVal,
} from 'utils/fhirResourcesHelper'
import { AddPartnerLabHandler } from 'views/components/administration/actors/add_partner_lab'
import { AddPatientData } from 'views/components/common/add_patient_data'
import { CashCollectionHandler } from 'views/components/lab/orderCreationManagement/cashCollection'
import { CreateLabOrderHandler } from 'views/components/lab/orderCreationManagement/createLabOrderManagement'
import { LabTestSelector } from 'views/components/lab/orderCreationManagement/labTestSelector'
import { ViewPaymentForOrder } from 'views/components/lab/orderCreationManagement/viewOrderPaymentStatus'
import { LabOrderDetails } from 'views/components/lab/orderDetails/orderDetails'
import { AddLabNewPatient } from 'views/components/lab/patientSetup/add_patient'
import { UploadReport } from 'views/components/lab/Report/uploadReport'
import { ProviderSelectorNew } from 'views/components/selectors/providers/providerSelectorNew'
import { entryPatient } from 'wello-fhir-l10n/dist/in/en/entryoverlay-partials/'
import { entryPatientHi } from 'wello-fhir-l10n/dist/in/hi/entryoverlay-partials/'
import '../../../App.css'
import { downloadingDocument } from 'redux/lab/download/downloadInvoice'
import { OrderReScheduleHandler } from 'views/components/lab/orderDetails/orderRescheduleHandler'
import { OrderCancellationHandler } from 'views/components/lab/orderDetails/orderCancellationHandler'
import { UploadLabResults } from 'views/components/lab/Report/UploadLabResults'
import { AddNewPatient } from '../../components/add_new_patient'
import { LabOrderListView } from '../../components/lab/orderList/labOrderListView'
import { LabOrderTabularView } from '../../components/lab/orderList/labOrderTableView'
import { OrderTypeSelection } from '../../components/lab/orderList/orderTypeSelector'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selected: {
      /*  backgroundColor: `${theme.palette.background.paper}!important`,
      margin: theme.spacing(0.5),
      borderRadius: theme.shape.borderRadius, */
    },
  })
)

const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  grouped: {
    padding: theme.spacing(0.5),

    border: 'none',
    '&.Mui-selected': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
    },
  },
}))(ToggleButtonGroup)

export const LabLandingPage: React.FC = () => {
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const classes = useStyles()

  const [openAddPatientPopup, setOpenAddPatientPopup] = useState(false)
  const [openUploadReportPopup, setOpenUploadReportPopup] = useState(false)
  const [openPartnerLabPopup, setOpenPartnerLabPopup] = useState(false)
  const selectedDoctors = useRef<PractitionerWithRole[] | undefined>()
  const [selectedOrderTypes, setSelectedOrderTypes] = useState<string[]>([
    'home-sample-collection',
    'onsite-sample-collection',
  ])
  const [selectedTests, setSelectedTests] = useState<LabOfferingDetail[]>([])
  const appointmentSearchSlice = useSelector(
    (state: RootState) => state.orderSearchSlice
  )
  const [showCancelHandler, setShowCancelHandler] = useState<boolean>(false)
  const [showResultsHandler, setShowResultsHandler] = useState<boolean>(false)
  const [showRescheduleHandler, setShowRescheduleHandler] =
    useState<boolean>(false)

  const [selectedOrder, setSelectedOrder] = useState<FhirLabOrderDetail>()
  const [selectedOrderForUpload, setSelectedOrderForUpload] =
    useState<FhirLabOrderDetail>()
  const [selectedOrderForResults, setSelectedOrderForResults] =
    useState<FhirLabOrderDetail>()
  const [dispplayId, setDisplayId] = useState<string>('')
  const [openCreateLabOrderPopup, setOpenCreateLabOrderPopup] = useState(false)
  const [openViewOrder, setOpenViewOrder] = useState(false)
  const [openAgentDisplayPopup, setOpenAgentDisplayPoup] = useState(false)
  const [openHookPage, setOpenHookPage] = useState(false)
  const [viewType, setViewType] = useState<string | undefined>('list')
  const [currentLocation, setCurrentLocation] = useState<R4.ILocation>(
    getCurrentUserUnitLocationDetails()
  )
  const { t, i18n } = useTranslation(['en', 'labelCommon'])
  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<R4.IOrganization>(getCurrentUserUnitDetails())
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      currentOrganizationDetails.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''
  const handleViewTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | undefined
  ) => {
    setViewType(newView)
  }

  function generateListItem() {
    return `<sup>TM</sup>`
  }

  function requestAppointmentForPolling() {
    if (selectedOrder === undefined) {
      dispatch(
        requestOrdersForTheDateRange(
          selectedDoctors.current ?? [],
          selectedDate,
          selectedOrderTypes,
          selectedTests,
          appointmentSearchSlice.pageState,
          appointmentSearchSlice.recordsCount,
          appointmentSearchSlice.existingDateWiseData
        )
      )
      dispatch(requestOrdersCountForToday(selectedDate))
    }
  }

  useEffect(() => {
    const intervalId = setInterval(requestAppointmentForPolling, 10000)
    return () => clearInterval(intervalId)
  }, [requestAppointmentForPolling])

  useEffect(() => {
    // if (loggedInUnitType === 'Lab') document.title = `WelloLAB™`
    // if (loggedInUnitType === 'Clinic') document.title = 'WelloClinic'
    // if (loggedInUnitType === 'Collection Center')
    //   document.title = 'WelloLAB (Collection Center)™'
    // if (loggedInUnitType === 'Partner Lab')
    //   document.title = 'WelloLAB (Partner Lab)™'
    dispatch(
      requestOrdersForTheDateRange(
        selectedDoctors.current ?? [],
        selectedDate,
        selectedOrderTypes,
        selectedTests
      )
    )
  }, [dispatch, selectedDate, selectedOrderTypes, selectedTests])

  useEffect(() => {
    dispatch(requestOrdersCountForToday(selectedDate))
  }, [selectedDate])

  useEffect(() => {
    if (appointmentSearchSlice.searchingAppointments) {
      setSelectedOrder(undefined)
    }
  }, [appointmentSearchSlice.searchingAppointments])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        borderRadius: 8,
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box
            display='flex'
            width='22%'
            maxWidth='22%'
            style={{ overflow: 'auto', height: '100%', minWidth: '290px' }}
          >
            <SimpleBar
              style={{
                height: '100%',
                width: '100%',
                overflowX: 'hidden',
                padding: '8px',
              }}
            >
              <Box px={1} width='100%'>
                <Box width='100%' id='lab_calendar_box'>
                  <Calendar
                    size='xs'
                    style={{
                      aspectRatio: '1/1',
                    }}
                    value={selectedDate}
                    onChange={setSelectedDate}
                    // onMonthChange={setSelectedDate}
                    dayStyle={(date, modifier) => {
                      if (isToday(date)) {
                        return {
                          aspectRatio: '1',
                          backgroundColor: kPrimaryLight,
                          borderRadius: '50%',
                        }
                      }
                      return { aspectRatio: '1' }
                    }}
                    id='labCalendar'
                    styles={{
                      cell: {
                        aspectRatio: '1',
                      },
                      day: {
                        aspectRatio: '1 !important',
                      },
                      weekend: {
                        color: 'black',
                      },
                      weekday: {
                        color: kPrimaryMain,
                      },
                      selected: {
                        borderRadius: '50%',
                      },
                      label: {
                        fontWeight: 'bold',
                        color: kPrimaryMain,
                      },
                    }}
                  />
                </Box>

                <OrderTypeSelection
                  onSelectionChanged={(changedTypes) => {
                    setSelectedOrderTypes(changedTypes)
                  }}
                  preSelectedOrderTypes={selectedOrderTypes}
                />

                <LabTestSelector
                  onLabTestsChanged={(changedTests) => {
                    setSelectedTests(changedTests)
                  }}
                />

                <Box paddingY={0.5}>
                  <ProviderSelectorNew
                    preSelectedPractitionerRoles={selectedDoctors.current}
                    onProviderSelectionChanged={(
                      selectedValues: DoctorBase[]
                    ) => {}}
                    onPractitionerSelectionChanged={(
                      selectedPractitioners: PractitionerWithRole[]
                    ) => {
                      // onDoctorsSelectionChanged(selectedPractitioners)
                    }}
                    doctorListId='appointmentDoctor'
                    providerType='lab-agent'
                  />
                </Box>
              </Box>
            </SimpleBar>
          </Box>

          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            maxWidth='88%'
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            <Box flexDirection='row' width='100%' display='flex' p={1}>
              {appointmentSearchSlice.searchingAppointments && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              )}

              <Box
                display='flex'
                flexDirection='row'
                justifyContent='flex-end'
                alignContent='flex-end'
                flexGrow='1'
              >
                {/* <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    if (i18n.language === 'hi') {
                      i18n.changeLanguage('en')
                      sessionStorage.setItem('lang', i18n.language)
                    } else {
                      i18n.changeLanguage('hi')
                      sessionStorage.setItem('lang', i18n.language)
                    }
                  }}
                >
                  {t('labelCommon:toggle-language')}
                </Button> */}
                {/* {(loggedInUnitType === 'Lab' ||
                  loggedInUnitType === 'Collection Center') && (
                  <Button
                    variant='contained'
                    color='primary'
                    id='upload_reports'
                    onClick={() => {
                      setOpenUploadReportPopup(true)
                    }}
                  >
                    {t('labelCommon:upload_reports')}
                  </Button>
                )} */}

                <Button
                  variant='contained'
                  color='primary'
                  id='add_patient'
                  onClick={() => {
                    setOpenAddPatientPopup(true)
                  }}
                >
                  {t('labelCommon:add_patient')}
                </Button>

                <Button
                  variant='contained'
                  color='primary'
                  id='sample_collection'
                  onClick={() => {
                    setOpenAgentDisplayPoup(true)
                  }}
                >
                  {t('labelCommon:collect_sample')}
                </Button>

                <Button
                  variant='contained'
                  color='primary'
                  id='create_order'
                  onClick={() => {
                    setOpenCreateLabOrderPopup(true)
                  }}
                >
                  {t('labelCommon:add_order')}
                </Button>
                <Box paddingX={2}>
                  <StyledToggleButtonGroup
                    value={viewType}
                    exclusive
                    id='style_group'
                    size='small'
                    onChange={handleViewTypeChange}
                    aria-label='text alignment'
                  >
                    <ToggleButton
                      value='list'
                      aria-label='centered'
                      id='toggle_group1'
                    >
                      <CalendarTodayIcon color='primary' id='icon1' />
                    </ToggleButton>
                    <ToggleButton
                      value='tabular'
                      aria-label='left aligned'
                      id='toggle_group2'
                    >
                      <GridOnOutlined color='primary' id='icon2' />
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                </Box>
              </Box>
            </Box>
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='Start'
              alignContent='center'
              overflow='auto'
              style={{
                backgroundColor: '#00000005',
              }}
            >
              {appointmentSearchSlice.noResultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    align='center'
                  >
                    {t('labelCommon:no_orders_available')}
                  </Typography>
                </Box>
              )}

              {appointmentSearchSlice.errorWhileSearchingOrders && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography variant='subtitle1' color='error' align='center'>
                    {t('labelCommon:error_search_orders')}
                  </Typography>
                </Box>
              )}

              {appointmentSearchSlice.availableOrders && (
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height='100%'
                  overflow='auto'
                >
                  <Box
                    display='flex'
                    flexGrow={1}
                    overflow='auto'
                    height='100%'
                  >
                    {viewType === 'list' && (
                      <LabOrderListView
                        preselectedOrderId={selectedOrder?.serviceRequest?.id}
                        labOrders={appointmentSearchSlice.availableOrders}
                        onLabOrderSelected={(order) => {
                          setSelectedOrder(order)
                          dispatch(
                            fetchOrderDetails(order.serviceRequest.id ?? '')
                          )
                        }}
                        onDownloadClicked={(labOrderDetail) => {
                          dispatch(
                            downloadingDocument(
                              'ServiceRequest',
                              labOrderDetail.serviceRequest.id ?? '',
                              labOrderDetail.paymentReconilation
                                ? labOrderDetail.paymentReconilation.id ?? ''
                                : ''
                            )
                          )
                        }}
                        onUploadReport={(labOrderDetail) => {
                          setSelectedOrderForUpload(labOrderDetail)
                          setOpenUploadReportPopup(true)
                        }}
                        onReschedule={(labOrderDetail) => {
                          setSelectedOrderForUpload(labOrderDetail)
                          setShowRescheduleHandler(true)
                        }}
                        onCancel={(labOrderDetail) => {
                          setSelectedOrderForUpload(labOrderDetail)
                          setShowCancelHandler(true)
                        }}
                        onEnterLabResults={(labOrderDetail) => {
                          setSelectedOrderForResults(labOrderDetail)
                          setShowResultsHandler(true)
                        }}
                        dense={selectedOrder !== undefined}
                      />
                    )}

                    {viewType === 'tabular' && (
                      <LabOrderTabularView
                        preselectedOrderId={selectedOrder?.serviceRequest?.id}
                        labOrders={appointmentSearchSlice.availableOrders}
                        onLabOrderSelected={(order) => {
                          setSelectedOrder(order)
                          dispatch(
                            fetchOrderDetails(order.serviceRequest.id ?? '')
                          )
                        }}
                      />
                    )}
                  </Box>

                  {selectedOrder && (
                    <Box display='flex' flexGrow={4} overflow='auto'>
                      <LabOrderDetails
                        orderDetail={selectedOrder}
                        onCloseClicked={() => {
                          setSelectedOrder(undefined)
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            <Box />
          </Box>
        </Box>
      </Paper>

      {openAddPatientPopup === true && loggedInUnitType !== 'Lab' && (
        <AddNewPatient
          isLab={false}
          isAppointment={true}
          popup={openAddPatientPopup}
          onPatientCreated={(createdPatient?: R4.IPatient) => {
            dispatch(showSuccessAlert('Patient added successfully'))
            dispatch(resetState())
            setOpenAddPatientPopup(false)
            requestOrdersForTheDateRange(
              selectedDoctors.current ?? [],
              selectedDate,
              selectedOrderTypes
            )
          }}
          onError={() => {
            dispatch(
              showErrorAlert('Error while adding patient. Please try later')
            )
            dispatch(resetState())
            setOpenAddPatientPopup(false)
          }}
          onCancelClicked={() => {
            dispatch(resetState())
            setOpenAddPatientPopup(false)
          }}
        />
      )}

      {openAddPatientPopup === true &&
        loggedInUnitType !== 'Collection Center' && (
          <AddNewPatient
            isLab={false}
            isAppointment={false}
            popup={openAddPatientPopup}
            onPatientCreated={(createdPatient?: R4.IPatient) => {
              dispatch(showSuccessAlert('Patient added successfully'))
              dispatch(resetState())
              setOpenAddPatientPopup(false)
              requestOrdersForTheDateRange(
                selectedDoctors.current ?? [],
                selectedDate,
                selectedOrderTypes
              )
            }}
            onError={() => {
              dispatch(
                showErrorAlert('Error while adding patient. Please try later')
              )
              dispatch(resetState())
              setOpenAddPatientPopup(false)
            }}
            onCancelClicked={() => {
              dispatch(resetState())
              setOpenAddPatientPopup(false)
            }}
          />
        )}

      {openAddPatientPopup === true &&
        (loggedInUnitType === 'Lab' ||
          loggedInUnitType === 'Collection Center') && (
          <AddNewPatient
            isLab={true}
            isAppointment={false}
            popup={openAddPatientPopup}
            onPatientCreated={(createdPatient?: R4.IPatient) => {
              dispatch(showSuccessAlert('Patient added successfully'))
              dispatch(resetState())
              setOpenAddPatientPopup(false)
              requestOrdersForTheDateRange(
                selectedDoctors.current ?? [],
                selectedDate,
                selectedOrderTypes
              )
            }}
            onError={() => {
              dispatch(
                showErrorAlert('Error while adding patient. Please try later')
              )
              dispatch(resetState())
              setOpenAddPatientPopup(false)
            }}
            onCancelClicked={() => {
              dispatch(resetState())
              setOpenAddPatientPopup(false)
            }}
          />
        )}

      {openPartnerLabPopup === true && (
        <AddPartnerLabHandler
          open={openPartnerLabPopup}
          onContinueClick={() => {
            setOpenPartnerLabPopup(false)
          }}
          onBackClick={() => {
            setOpenPartnerLabPopup(false)
          }}
        />
      )}

      {openUploadReportPopup === true && (
        <UploadReport
          open={openUploadReportPopup}
          onClose={() => {
            dispatch(searchInvitations())
            setSelectedOrderForUpload(undefined)
            setOpenUploadReportPopup(false)
          }}
          onDiscard={() => {
            setSelectedOrderForUpload(undefined)
            dispatch(searchInvitations())
            setOpenUploadReportPopup(false)
          }}
          labOrderDetails={selectedOrderForUpload}
        />
      )}

      <AddPatientData
        open={openHookPage}
        onClose={() => {
          setOpenHookPage(false)
        }}
      />

      <CreateLabOrderHandler
        open={openCreateLabOrderPopup}
        onLabOrderCreated={(orderId: string, paymentType: string) => {
          if (paymentType === 'online') {
            setDisplayId(orderId)
            setOpenViewOrder(true)
            setOpenCreateLabOrderPopup(false)
          } else {
            setOpenCreateLabOrderPopup(false)
          }
        }}
        onClose={() => {
          setOpenCreateLabOrderPopup(false)
        }}
      />

      <ViewPaymentForOrder
        orderId={dispplayId}
        open={openViewOrder}
        onDone={() => {
          setOpenViewOrder(false)
          dispatch(
            requestOrdersForTheDateRange(
              selectedDoctors.current ?? [],
              selectedDate,
              selectedOrderTypes,
              selectedTests
            )
          )
          setDisplayId('')
        }}
        onClose={() => {
          setOpenViewOrder(false)
          dispatch(
            requestOrdersForTheDateRange(
              selectedDoctors.current ?? [],
              selectedDate,
              selectedOrderTypes,
              selectedTests
            )
          )
        }}
      />

      <CashCollectionHandler
        open={openAgentDisplayPopup}
        onFinishCollection={() => {
          setOpenAgentDisplayPoup(false)
          dispatch(
            requestOrdersForTheDateRange(
              selectedDoctors.current ?? [],
              selectedDate,
              selectedOrderTypes,
              selectedTests
            )
          )
        }}
        onClose={() => {
          setOpenAgentDisplayPoup(false)
          dispatch(
            requestOrdersForTheDateRange(
              selectedDoctors.current ?? [],
              selectedDate,
              selectedOrderTypes,
              selectedTests
            )
          )
        }}
      />
      {selectedOrderForResults && showResultsHandler && (
        <UploadLabResults
          open={showResultsHandler}
          labOrderDetails={selectedOrderForResults}
          onClose={() => {
            setShowRescheduleHandler(false)
            setShowRescheduleHandler(false)
            setSelectedOrderForResults(undefined)
          }}
        />
      )}

      {selectedOrderForUpload && showCancelHandler && (
        <OrderCancellationHandler
          open={showCancelHandler}
          onClose={() => {
            setShowCancelHandler(false)
          }}
          orderDetail={selectedOrderForUpload}
          onCancelConfirmed={(cancelReason, note) => {
            dispatch(
              requestCancellationOfOrder(
                selectedOrderForUpload,
                cancelReason,
                note
              )
            )
            setShowCancelHandler(false)
            setSelectedOrderForUpload(undefined)
          }}
        />
      )}
      {selectedOrderForUpload && showRescheduleHandler && (
        <OrderReScheduleHandler
          open={showRescheduleHandler}
          onClose={() => {
            setShowRescheduleHandler(false)
          }}
          orderDetail={selectedOrderForUpload}
          onReScheduleConfirmed={(cancelReason, note, newSlot) => {
            dispatch(
              requestRescheduleOfOrder(
                selectedOrderForUpload,
                cancelReason,
                newSlot,
                note
              )
            )
            setSelectedOrderForUpload(undefined)
            setShowRescheduleHandler(false)
          }}
        />
      )}
    </div>
  )
}
