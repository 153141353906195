import { Box, Card, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Typography } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    setRoleAndUnit
} from 'redux/authChecker/authSlice'
import { RootState } from 'redux/rootReducer'
import { getRolesAsStringFromPractRole } from 'services/userDetailsService'
import { getDisplayOfSystemFromCodableConcept, getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { CommonBackground } from './common/common_brackground'

export const UnitAndRoleSelector: React.FC<{}> = () => {

    const authState = useSelector((state: RootState) => state.authCheck)
    const dispatch = useDispatch()
    return (

        <Box display='flex' flexDirection='column' justifyContent='start' alignItems='start' width='100%'>
            <Box flexDirection='row' paddingBottom={2}>
                <Typography variant="h5" color="primary">
                    {`Welcome ${getNameFromHumanName(authState.userDetails!.practitioner.name ?? [])}`}
                </Typography>

            </Box>

            {authState.userDetails?.availableRoles && (authState.userDetails?.availableRoles.length > 0) && <Box flexDirection='column'>
                <Box flexDirection='row'>
                    <Typography variant="h6" color="primary">
                        Continue as
                    </Typography>

                </Box>
                <List style={{ width: '100%' }}>
                    {authState.userDetails?.availableRoles.map((value, i) => (
                        <ListItem key={value.practitionerRole.id ?? ''} button onClick={(e) => {
                            dispatch(setRoleAndUnit(value, authState))
                        }}
                            style={{
                                boxShadow: '0px 0px 4px #ccc',
                                margin: '4px 0px'
                                , borderRadius: '4px',
                                width: '100%'
                            }}
                        >
                            <ListItemText primary={
                                value.unitOrganization?.name
                            } secondary={
                                ` as ${getRolesAsStringFromPractRole(value.practitionerRole)}`
                            } />
                            <ListItemSecondaryAction>
                                <ChevronRight />
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}

                </List>
            </Box>}

        </Box>


    )
}
