import { Box, Slider, Typography } from '@material-ui/core'
import { kPrimaryMain } from 'configs/styles/muiThemes'

import React, { useEffect, useState } from 'react'
import {
  getColorBasedOnNumber,
  getColorBasedOnNumberForSankey,
} from 'utils/fhirResourcesHelper'

import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'

type PersonSliderProps = {
  label: string
  id: string
  name: string
  baseMin: number
  min: number
  max: number
  step?: number
  value: number
  onChange: any
}

export const CustomPersonSlider: React.FC<PersonSliderProps> = ({
  label,
  id,
  name,
  baseMin,
  min,
  max,
  step,
  value,
  onChange,
}: PersonSliderProps) => {
  console.log('455')
  return (
    <Box width='100%' justifyContent='flex-start' flexDirection='column'>
      <Box width='100%' flexDirection='row'>
        <Box width='100%'>
          <WelloFormItemLabel title={label} />
        </Box>
      </Box>
      <Box width='100%' paddingTop={1} paddingLeft={0.5}>
        <Slider
          id={id}
          name={name}
          min={baseMin}
          max={max || 1000}
          step={step || 1}
          value={value}
          onChange={onChange}
          marks={true}
          style={getColorBasedOnNumberForSankey(baseMin, 100, max || 1000)}
          valueLabelDisplay='auto'
        />
      </Box>
      {/* <Slider
        id={id}
        name={name}
        min={baseMin}
        max={max || 1000}
        step={step || 1}
        value={value}
        onChange={onChange}
      /> */}
      {/* 
      <input
        type='range'
        id={id}
        name={name}
        min={baseMin}
        max={max || 1000}
        step={step || 1}
        value={value}
        onChange={onChange}
      /> */}
    </Box>
  )
}
