import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addCarePlanFollowupsForIPD } from 'redux/ipd/recommendation_additionsIPD/care_plan_followup_addition_ipd/carePlanAdditionFollowUpSliceIPD'
import { RootState } from 'redux/rootReducer'
import { getCarePlans } from 'utils/patientHelper/cds_helper'

const useStyles = makeStyles((theme) => ({
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))

interface AddCareTeamFollowUpsButtonProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  carePanBundle: R4.IBundle
  carePlanIdentifier?: string
}

export const AddCareTeamFollowUpsButtonIPD: React.FC<AddCareTeamFollowUpsButtonProps> =
  ({
    fhirAppointmentDetails,
    carePanBundle,
    carePlanIdentifier,
  }: AddCareTeamFollowUpsButtonProps) => {
    const instructionAdditionSlice = useSelector(
      (state: RootState) => state.carePlanFollowUpAdditionSliceForIPD
    )
    const classes = useStyles()
    const dispatch = useDispatch()

    return (
      <Box>
        <Box flexDirection='row'>
          {instructionAdditionSlice.addingInstruction && (
            <Box height={25} width={25}>
              <CircularProgress size={20} />
            </Box>
          )}

          {instructionAdditionSlice.errorWhileAdding && (
            <Box flexDirection='row'>
              <Button
                variant='text'
                color='primary'
                id='care_plan_ins'
                className={classes.buttonText}
                onClick={(e) => {
                  dispatch(
                    addCarePlanFollowupsForIPD(
                      fhirAppointmentDetails.patient,
                      getCarePlans(carePanBundle.entry ?? []),
                      fhirAppointmentDetails,
                      carePlanIdentifier
                    )
                  )
                  e.stopPropagation()
                }}
              >
                Retry
              </Button>

              {/*  <Button
                variant='text'
                color='secondary'
                className={classes.rejectButtonText}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                Reject
              </Button> */}
            </Box>
          )}
          {instructionAdditionSlice.additionSuccessful && (
            <Box flexDirection='row'>
              <Typography variant='subtitle1' color='initial'>
                Added
              </Typography>
            </Box>
          )}
          {!instructionAdditionSlice.additionSuccessful &&
            !instructionAdditionSlice.errorWhileAdding &&
            !instructionAdditionSlice.addingInstruction && (
              <Box flexDirection='row'>
                <Button
                  variant='text'
                  color='primary'
                  id='care_plan_ins1'
                  className={classes.buttonText}
                  onClick={(e) => {
                    dispatch(
                      addCarePlanFollowupsForIPD(
                        fhirAppointmentDetails.patient,
                        getCarePlans(carePanBundle.entry ?? []),
                        fhirAppointmentDetails,
                        carePlanIdentifier
                      )
                    )
                    e.stopPropagation()
                  }}
                >
                  Add
                </Button>

                {/*  <Button
                  variant='text'
                  color='secondary'
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  className={classes.rejectButtonText}
                >
                  Reject
                </Button> */}
              </Box>
            )}
        </Box>
      </Box>
    )
  }
