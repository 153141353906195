/* eslint-disable no-await-in-loop */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { IHealthcareService } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { LabOfferingDetail, LabReferralService } from 'models/labOfferDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { LabOfferingSearchStatus } from 'redux/lab/offerings/labOfferingSearchHandler/labOfferingSearchStatusTypes'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import {
  getValueNameValueExtensionsOfUrl,
  getValueRefValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import {
  getHvServiceForReferralList,
  getLabOfferingDetailsFromBundle,
} from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { logger } from 'utils/logger'

const initialState: LabOfferingSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const referralLabOfferingSlice = createSlice({
  name: 'referralLabOfferingSlice',
  initialState,
  reducers: {
    searchingDoctorDetails(
      state,
      action: PayloadAction<LabOfferingSearchStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false

      state.labOfferings = undefined
      state.healthCareService = undefined
    },

    searchResults(state, action: PayloadAction<LabOfferingSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.healthCareService = action.payload.healthCareService
      state.labOfferings = action.payload.labOfferings
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<LabOfferingSearchStatus>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.healthCareService = undefined
      state.labOfferings = undefined
    },

    errorWhileSearching(state, action: PayloadAction<LabOfferingSearchStatus>) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.healthCareService = undefined
      state.labOfferings = undefined
    },

    resetLabOffering(state, action: PayloadAction<LabOfferingSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.healthCareService = undefined
      state.labOfferings = undefined
    },
  },
})

export const searchLabOfferings =
  (appointmentId: string, searchString?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: LabOfferingSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(referralLabOfferingSlice.actions.searchingDoctorDetails(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _include: '*',
        status: 'active',
        _count: 100,
        _total: 'accurate',
      }

      if (searchString) searchParameters.identifier = searchString

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/PlanDefinition',
        appointmentId,
        searchParameters
      )
      if (response.entry) {
        if (response.entry.length > 0) {
          let finalService: LabReferralService[] = []
          const labOfferingList: LabOfferingDetail[] =
            getLabOfferingDetailsFromBundle(response, []) ?? []
          for (let i = 0; i < labOfferingList.length; i++) {
            const unitArr: R4.IHealthcareService[] =
              await getHealthCareServices(
                labOfferingList[i].planDefinition,
                appointmentId
              )

            if (unitArr.length > 0) {
              finalService = getHvServiceForReferralList(
                labOfferingList,
                unitArr
              )
            }
          }

          if (finalService.length > 0) {
            finalService.sort((a, b) =>
              (a.name ? (a.name.length > 0 ? a.name : '') : '').localeCompare(
                b.name ? b.name : ''
              )
            )
            finalService.push({
              name: 'No Preference',
              healthService: {
                resourceType: 'HealthcareService',
                name: 'No Preference',
              },
              planDefinition: {
                resourceType: 'PlanDefinition',
                title: 'No Preference',
              },
            })
            const uniqueArray = _.uniqBy(finalService, 'name')
            state.labOfferings = labOfferingList
            state.healthCareService = uniqueArray
            state.resultsAvailable = true
            state.searching = false
            state.error = false
            dispatch(referralLabOfferingSlice.actions.searchResults(state))
            return
          }
          state.resultsAvailable = false
          state.searching = false
          state.error = false
          state.noResultsAvailable = true
          dispatch(referralLabOfferingSlice.actions.noDataFoundForSearch(state))
          return
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(referralLabOfferingSlice.actions.noDataFoundForSearch(state))
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = false
      state.noResultsAvailable = true
      dispatch(referralLabOfferingSlice.actions.noDataFoundForSearch(state))
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(referralLabOfferingSlice.actions.errorWhileSearching(state))
    }
  }

export async function getHealthCareServices(
  plan: R4.IPlanDefinition,
  appointmentId: string
): Promise<R4.IHealthcareService[]> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const unitArr: R4.IHealthcareService[] = []
  const unitId: string = getValueRefValueExtensionsOfUrl(
    plan.extension,
    'http://wellopathy.com/fhir/india/core/StructureDefinition/PlanDefinitionManagingOrganization'
  )!
  const response: any = await fhirClient.doGetResourceForAppointment(
    `HealthcareService?service-type=266753000&active=true`,
    appointmentId
  )
  if (response.entry) {
    if (response.entry.length > 0) {
      for (let i = 0; i < response.entry.length; i++) {
        unitArr.push(response.entry[i].resource as R4.IHealthcareService)
      }
    }
  }
  //   const unique = unitArr.filter(
  //     (obj, index) => index === unitArr.findIndex((o) => obj.name === o.name)
  //   )

  const unique = unitArr.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.active === value.active)
  )
  return unitArr
}

export const resetReferralOfferingSearch = () => (dispatch: AppDispatch) => {
  dispatch(referralLabOfferingSlice.actions.resetLabOffering(initialState))
}

export function getPractitionerRoleObject(item: R4.IPractitionerRole) {
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    name: item.practitioner?.display ?? '',
    gender: '',
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: true,
  }
  return val
}

export default referralLabOfferingSlice.reducer
