import { R4 } from '@ahryman40k/ts-fhir-types'

export const SkeletalSystemConditionCodes: R4.ICoding[] = [
  {
    system: 'http://snomed.info/sct',
    code: '3723001',
    display: 'Arthritis',
  },
  {
    system: 'http://snomed.info/sct',
    code: '125605004',
    display: 'Fracture',
  },
  {
    system: 'http://snomed.info/sct',
    code: '1163405004',
    display: 'Osteosarcoma',
  },
  {
    system: 'http://snomed.info/sct',
    code: '64859006',
    display: 'Osteoporosis',
  },
  {
    system: 'http://snomed.info/sct',
    code: '287091008',
    display: 'Sprains and tears',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-skeletal-system-finding-cs',
    code: 'other-skeletal-system-finding',
    display: 'Any Other',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
