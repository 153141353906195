import { Avatar, Box, Card, CardContent, Typography } from '@material-ui/core'
import { FhirAppointmentFullDetail } from 'models/fhirAppointmentFullDetail'
import { MedicalEvent, MedicalEventType } from 'models/medical_event'
import React from 'react'
import { AppointmentMedicalEventDetail } from './appointment_medical_event_detail'

interface IProps {
  medicalEvent: MedicalEvent
  onTap?: () => void
  dense?: boolean
  isSelected?: boolean
}

export const PatientMedicalEventDetail: React.FC<IProps> = ({
  medicalEvent,
  onTap,
  dense = true,
  isSelected = false,
}: IProps) => (
  <Box width='100%' onClick={onTap} height='100%'>
    <Card
      elevation={0}
      style={{
        padding: 0,
        margin: 0,
        borderRadius: 4,
        height: '100%',
      }}
    >
      <CardContent
        style={{
          paddingLeft: 16,
          paddingRight: isSelected ? 0 : 8,
          border: 0,
        }}
      >
        <Box display='flex' flexDirection='column' width='100%' height='100%'>
          <Box display='flex' flexDirection='row' justifyContent='flex-center'>
            <Avatar
              alt={medicalEvent.performerName}
              src={medicalEvent.performerProfilePic}
            />
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='flex-start'
              flexGrow={1}
              px={1}
            >
              <Typography variant='subtitle1' color='primary'>
                {medicalEvent.performerName}
              </Typography>
              <Typography variant='body2' color='initial'>
                {medicalEvent.performerSpecialization ?? ''}
              </Typography>
            </Box>
          </Box>

          {medicalEvent.eventType === MedicalEventType.DOCTOR_VISIT && (
            <AppointmentMedicalEventDetail
              medicalEvent={
                medicalEvent.eventDetails as FhirAppointmentFullDetail
              }
            />
          )}
        </Box>
      </CardContent>
    </Card>
  </Box>
)
