import { R4 } from '@ahryman40k/ts-fhir-types'

export const EduOrganizations: R4.IOrganization[] = [
  {
    resourceType: 'Organization',
    id: '18722',
    meta: {
      versionId: '1',
      lastUpdated: '2021-05-23T14:08:53.791+00:00',
      source: '#b1283b00-3478-9b',
    },
    identifier: [
      {
        system:
          'https://www.nmc.org.in/information-desk/for-students-to-study-in-india/list-of-college-teaching-mbbs',
        value: 'IR-D-N-15',
      },
    ],
    active: true,
    type: [
      {
        coding: [
          {
            code: 'edu',
            display: 'Educational Institute',
          },
        ],
      },
    ],
    name: 'All India Institute of Medical Sciences',
  },
  {
    resourceType: 'Organization',
    id: '18726',
    meta: {
      versionId: '1',
      lastUpdated: '2021-05-23T14:10:09.381+00:00',
      source: '#8f8860b7-d555-99',
    },
    identifier: [
      {
        system: 'http://cghealth.nic.in/ehealth/dishm/index.html',
        value: 'IR-D-N-34',
      },
    ],
    active: true,
    type: [
      {
        coding: [
          {
            code: 'edu',
            display: 'Educational Institute',
          },
        ],
      },
    ],
    name: 'Ayush and Health Sciences University of Chhattisgarh',
  },

  {
    resourceType: 'Organization',
    id: '18980',
    meta: {
      versionId: '1',
      lastUpdated: '2021-05-23T14:10:09.381+00:00',
      source: '#8f8860b7-d555-99',
    },
    identifier: [
      {
        system: 'https://www.bhu.ac.in/Site/Home/1_2_16_Main-Site',
        value: 'IR-D-N-39',
      },
    ],
    active: true,
    type: [
      {
        coding: [
          {
            code: 'edu',
            display: 'Educational Institute',
          },
        ],
      },
    ],
    name: 'Banaras Hindu University',
  },

  {
    resourceType: 'Organization',
    id: '10000202',
    meta: {
      versionId: '1',
      lastUpdated: '2021-05-23T14:10:09.381+00:00',
      source: '#8f8860b7-d555-99',
    },
    identifier: [
      {
        system: 'https://www.bhu.ac.in/Site/Home/1_2_16_Main-Site',
        value: 'IR-D-N-101',
      },
    ],
    active: true,
    type: [
      {
        coding: [
          {
            code: 'edu',
            display: 'Educational Institute',
          },
        ],
      },
    ],
    name: 'Central Council for Research in Yoga & Naturopathy, New Delhi',
  },

  {
    resourceType: 'Organization',
    id: '1605',
    meta: {
      versionId: '1',
      lastUpdated: '2021-05-23T14:08:53.791+00:00',
      source: '#b1283b00-3478-9b',
    },
    identifier: [
      {
        system: 'https://www.mkcgmch.org/',
        value: 'IR-D-N-30',
      },
    ],
    active: true,
    type: [
      {
        coding: [
          {
            code: 'edu',
            display: 'Educational Institute',
          },
        ],
      },
    ],
    name: 'MKCG Medical College, Berhampur, Odisha',
  },

  {
    resourceType: 'Organization',
    id: '18724',
    meta: {
      versionId: '1',
      lastUpdated: '2021-05-23T14:09:45.797+00:00',
      source: '#67eec9a6-10b7-9b',
    },
    identifier: [
      {
        system:
          'https://www.nmc.org.in/information-desk/for-students-to-study-in-india/list-of-college-teaching-mbbs',
        value: 'IR-D-U-0236',
      },
    ],
    active: true,
    type: [
      {
        coding: [
          {
            code: 'edu',
            display: 'Educational Institute',
          },
        ],
      },
    ],
    name: 'National Institute of Mental Health & Neuro Sciences',
  },
  {
    resourceType: 'Organization',
    id: '18723',
    meta: {
      versionId: '1',
      lastUpdated: '2021-05-23T14:09:12.282+00:00',
      source: '#d6164f23-ab22-9c',
    },
    identifier: [
      {
        system:
          'https://www.nmc.org.in/information-desk/for-students-to-study-in-india/list-of-college-teaching-mbbs',
        value: 'IR-D-U-0079',
      },
    ],
    active: true,
    type: [
      {
        coding: [
          {
            code: 'edu',
            display: 'Educational Institute',
          },
        ],
      },
    ],
    name: 'Post Graduate Institute of Medical Education and Research',
  },
  {
    resourceType: 'Organization',
    id: '18730',
    meta: {
      versionId: '1',
      lastUpdated: '2021-05-23T14:10:09.381+00:00',
      source: '#8f8860b7-d555-99',
    },
    identifier: [
      {
        system: 'https://sdmbnys.in/',
        value: 'IR-D-N-38',
      },
    ],
    active: true,
    type: [
      {
        coding: [
          {
            code: 'edu',
            display: 'Educational Institute',
          },
        ],
      },
    ],
    name: 'Royal College of Hotel Management, Nimapara, Odhisa',
  },
  {
    resourceType: 'Organization',
    id: '18725',
    meta: {
      versionId: '1',
      lastUpdated: '2021-05-23T14:10:09.381+00:00',
      source: '#8f8860b7-d555-99',
    },
    identifier: [
      {
        system:
          'https://www.nmc.org.in/information-desk/for-students-to-study-in-india/list-of-college-teaching-mbbs',
        value: 'IR-D-N-33',
      },
    ],
    active: true,
    type: [
      {
        coding: [
          {
            code: 'edu',
            display: 'Educational Institute',
          },
        ],
      },
    ],
    name: 'Sanjay Gandhi Postgraduate Institute of Medical Sciences',
  },
  {
    resourceType: 'Organization',
    id: '18727',
    meta: {
      versionId: '1',
      lastUpdated: '2021-05-23T14:10:09.381+00:00',
      source: '#8f8860b7-d555-99',
    },
    identifier: [
      {
        system: 'https://sdmbnys.in/',
        value: 'IR-D-N-36',
      },
    ],
    active: true,
    type: [
      {
        coding: [
          {
            code: 'edu',
            display: 'Educational Institute',
          },
        ],
      },
    ],
    name: 'SDM College of Naturopathy',
  },

  {
    resourceType: 'Organization',
    id: '18726',
    meta: {
      versionId: '1',
      lastUpdated: '2021-05-23T14:10:09.381+00:00',
      source: '#8f8860b7-d555-99',
    },
    identifier: [
      {
        system: 'https://www.utkaluniversity.ac/',
        value: 'IR-D-N-35',
      },
    ],
    active: true,
    type: [
      {
        coding: [
          {
            code: 'edu',
            display: 'Educational Institute',
          },
        ],
      },
    ],
    name: 'Utkal University',
  },

  {
    resourceType: 'Organization',
    id: '118000',
    meta: {
      versionId: '1',
      lastUpdated: '2021-05-23T14:10:09.381+00:00',
      source: '#8f8860b7-d555-99',
    },
    identifier: [
      {
        system: 'https://sdmayurvedacollegeudupi.in/',
        value: 'IR-D-N-UP',
      },
    ],
    active: true,
    type: [
      {
        coding: [
          {
            code: 'edu',
            display: 'Educational Institute',
          },
        ],
      },
    ],
    name: 'Sri Dharmasthala college of Ayurveda & Hospital',
  },

  //   {
  //     resourceType: 'Organization',
  //     id: '18726',
  //     meta: {
  //       versionId: '1',
  //       lastUpdated: '2021-05-23T14:10:09.381+00:00',
  //       source: '#8f8860b7-d555-99',
  //     },
  //     identifier: [
  //       {
  //         system: 'https://www.utkaluniversity.ac/',
  //         value: 'IR-D-N-35',
  //       },
  //     ],
  //     active: true,
  //     type: [
  //       {
  //         coding: [
  //           {
  //             code: 'edu',
  //             display: 'Educational Institute',
  //           },
  //         ],
  //       },
  //     ],
  //     name: 'Utkal University',
  //   },
]
