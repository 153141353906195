import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { BpCollection } from 'models/bpData'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { logger } from 'utils/logger'
import { CarePlanSubscriptionStatus } from './carePlanSubscriptionStatus'

const initialState: CarePlanSubscriptionStatus = {
  searching: false,
  resultsAvailable: false,
  error: false,
  noResultsAvailable: false,
  errorMessage: '',
  subscribed: false,
}

const carePlanSubscriptionSlice = createSlice({
  name: 'carePlanSubscription',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<CarePlanSubscriptionStatus>) {
      state.errorMessage = action.payload.errorMessage
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searching = action.payload.searching
      state.resultsAvailable = action.payload.resultsAvailable
      state.error = action.payload.error
      state.subscribed = action.payload.subscribed
    },
  },
})

export const requestForButtonEnabled =
  (selectedPatient: R4.IPatient): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: CarePlanSubscriptionStatus = {
      searching: true,
      error: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      subscribed: false,
    }
    dispatch(carePlanSubscriptionSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doGetResource(
        `/ServiceRequest??code=chronic-care-enrolment&subject=${
          selectedPatient.id ?? ''
        }`
      )
      logger.info('Patient invitation Response')
      logger.info(response)
      const respDecoded: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (respDecoded._tag === 'Right') {
        logger.info('Response Patient un decoded', respDecoded.right)
        const responseBundel: R4.IBundle = respDecoded.right
        if (responseBundel.entry) {
          const entryObj = responseBundel.entry?.[0]
            .resource as R4.IServiceRequest
          const serviceRequestId =
            entryObj && entryObj.status === 'active' ? entryObj.id ?? ' ' : ''
          if (serviceRequestId.length > 0) {
            const searchPatientResult: CarePlanSubscriptionStatus = {
              error: false,
              noResultsAvailable: false,
              resultsAvailable: true,
              searching: false,
              subscribed: true,
            }
            dispatch(
              carePlanSubscriptionSlice.actions.updatedStatus(
                searchPatientResult
              )
            )
            return
          }
          const disbaledButton: CarePlanSubscriptionStatus = {
            error: false,
            noResultsAvailable: false,
            resultsAvailable: true,
            searching: false,
            subscribed: true,
          }
          dispatch(
            carePlanSubscriptionSlice.actions.updatedStatus(disbaledButton)
          )
          return
        }
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: CarePlanSubscriptionStatus = {
        error: false,
        noResultsAvailable: false,
        resultsAvailable: true,
        searching: false,
        subscribed: false,
      }
      dispatch(
        carePlanSubscriptionSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

async function updateEnrolment(bpCollection: BpCollection): Promise<string> {
  const enRolClient: EnrolCient = new EnrolCient()
  const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
    `/enrolment/update-bp`,
    bpCollection
  )
  return response.BPCategory
}

async function updateAppointment(): Promise<string> {
  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const enRolClient: EnrolCient = new EnrolCient()
  //   const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
  //     `/enrolment/update-bp`,
  //     bpCollection
  //   )

  return 'test'
}

async function getServiceRequestId(patientId: string): Promise<string> {
  logger.info('Patient invitation body')
  let serviceRequestId: string = ''
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirClient.doGetResource(
    `/ServiceRequest??code=chronic-care-enrolment&subject=${patientId}`
  )
  logger.info('Patient invitation Response')
  logger.info(response)
  const respDecoded: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (respDecoded._tag === 'Right') {
    logger.info('Response Patient un decoded', respDecoded.right)
    const responseBundel: R4.IBundle = respDecoded.right
    if (responseBundel.entry) {
      const entryObj = responseBundel.entry?.[0].resource as R4.IServiceRequest
      serviceRequestId =
        entryObj && entryObj.status === 'active' ? entryObj.id ?? ' ' : ''
      return serviceRequestId
    }
    return serviceRequestId
  }

  return serviceRequestId
}

export default carePlanSubscriptionSlice.reducer
