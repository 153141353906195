import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getValueCodingStringFromExtension } from 'utils/fhirResourcesHelper'
import { getAppointmentCancelMessage } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getRefundBody } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { logger } from 'utils/logger'
import { requestAppointmentsCountForToday } from '../appointmentCount/appointmentCountSlice'
import {
  CancelActions,
  CancelAppointmentManagementStatus,
} from './cancelAppointmentManagerStatus'

const initialState: CancelAppointmentManagementStatus = {
  currentAction: CancelActions.SlotSelection,
  enableContinueButton: false,
  reschedulingAppointment: false,
  errorWhileReschedulingAppointment: false,
  appointmentRescheduledSuccessfully: false,
}

const cancelAppointmentManagerSlice = createSlice({
  name: 'cancelAppointmentManager',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<CancelAppointmentManagementStatus>
    ) {
      state.currentAction = action.payload.currentAction
      state.enableContinueButton = action.payload.enableContinueButton
      state.selectedPatient = action.payload.selectedPatient
      state.selectedSlot = action.payload.selectedSlot
      state.errorReason = action.payload.errorReason
      state.errorWhileReschedulingAppointment =
        action.payload.errorWhileReschedulingAppointment
      state.reschedulingAppointment = action.payload.reschedulingAppointment
      state.rescheduledAppointment = action.payload.rescheduledAppointment
      state.appointmentRescheduledSuccessfully =
        action.payload.appointmentRescheduledSuccessfully
    },
  },
})

export const onSlotSelected =
  (
    activeState: CancelAppointmentManagementStatus,
    selectedSlot: FhirSlotDetail
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: CancelAppointmentManagementStatus = {
      currentAction: CancelActions.SlotSelection,
      enableContinueButton: true,
      selectedSlot,
      selectedPatient: activeState.selectedPatient,
      reschedulingAppointment: false,
      errorWhileReschedulingAppointment: false,
      appointmentRescheduledSuccessfully: false,
    }
    dispatch(cancelAppointmentManagerSlice.actions.updatedStatus(state))
  }

export const onPatientSelected =
  (
    activeState: CancelAppointmentManagementStatus,
    selectedPatient: R4.IPatient
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: CancelAppointmentManagementStatus = {
      currentAction: CancelActions.Confirmation,
      enableContinueButton: true,
      selectedPatient,
      selectedSlot: activeState.selectedSlot,
      reschedulingAppointment: false,
      errorWhileReschedulingAppointment: false,
      appointmentRescheduledSuccessfully: false,
    }
    dispatch(cancelAppointmentManagerSlice.actions.updatedStatus(state))
  }

export const continueButtonClicked =
  (activeState: CancelAppointmentManagementStatus): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (activeState.currentAction === CancelActions.SlotSelection) {
      const state: CancelAppointmentManagementStatus = {
        currentAction: CancelActions.Confirmation,
        enableContinueButton: false,
        selectedPatient: activeState.selectedPatient,
        selectedSlot: activeState.selectedSlot,
        reschedulingAppointment: false,
        errorWhileReschedulingAppointment: false,
        appointmentRescheduledSuccessfully: false,
      }
      dispatch(cancelAppointmentManagerSlice.actions.updatedStatus(state))
    }
  }
export const disableButtonClicked =
  (activeState: CancelAppointmentManagementStatus): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (activeState.currentAction === CancelActions.SlotSelection) {
      const state: CancelAppointmentManagementStatus = {
        currentAction: activeState.currentAction,
        enableContinueButton: false,
        selectedPatient: activeState.selectedPatient,
        selectedSlot: activeState.selectedSlot,
        reschedulingAppointment: activeState.reschedulingAppointment,
        errorWhileReschedulingAppointment:
          activeState.errorWhileReschedulingAppointment,
        appointmentRescheduledSuccessfully:
          activeState.appointmentRescheduledSuccessfully,
      }
      dispatch(cancelAppointmentManagerSlice.actions.updatedStatus(state))
    }
  }

export const resetCancelAppointmentState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: CancelAppointmentManagementStatus = {
      currentAction: CancelActions.SlotSelection,
      enableContinueButton: false,
      selectedPatient: undefined,
      selectedSlot: undefined,
      reschedulingAppointment: false,
      errorWhileReschedulingAppointment: false,
      appointmentRescheduledSuccessfully: false,
    }
    dispatch(cancelAppointmentManagerSlice.actions.updatedStatus(state))
  }

export const requestCancellationAppointment =
  (
    oldAppointmentDetails: FhirAppointmentDetail,
    rescheduleReason: R4.ICoding,
    errorReason?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: CancelAppointmentManagementStatus = {
      currentAction: CancelActions.Confirmation,
      enableContinueButton: false,
      reschedulingAppointment: true,
      errorWhileReschedulingAppointment: false,
      appointmentRescheduledSuccessfully: false,
    }
    dispatch(cancelAppointmentManagerSlice.actions.updatedStatus(state))
    try {
      const bundleObject: R4.IBundle = getAppointmentTransactionObject(
        oldAppointmentDetails,
        rescheduleReason,
        errorReason
      )
      const fhirApi: FHIRApiClient = new FHIRApiClient()
      const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        if (
          oldAppointmentDetails.paymentReconcilation &&
          oldAppointmentDetails.paymentReconcilation.detail
        ) {
          const code =
            getValueCodingStringFromExtension(
              oldAppointmentDetails.paymentReconcilation.extension ?? [],
              'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentType'
            ) ?? ''

          if (code.length > 0 && code === 'prepaid') {
            const transactionResp: boolean = await getRefund(
              oldAppointmentDetails.appointment.id ?? '',
              oldAppointmentDetails.paymentReconcilation
                ? oldAppointmentDetails.paymentReconcilation.id ?? ''
                : '',
              'doctor-appointment'
            )

            if (transactionResp) {
              state.appointmentRescheduledSuccessfully = true
              state.reschedulingAppointment = false
              state.rescheduledAppointment = oldAppointmentDetails.appointment
              dispatch(
                showSuccessAlert(
                  getAppointmentCancelMessage(oldAppointmentDetails)
                )
              )
              dispatch(requestAppointmentsCountForToday())
              dispatch(
                cancelAppointmentManagerSlice.actions.updatedStatus(state)
              )
              return
            }
            state.appointmentRescheduledSuccessfully = true
            state.reschedulingAppointment = false
            state.rescheduledAppointment = oldAppointmentDetails.appointment
            dispatch(requestAppointmentsCountForToday())
            dispatch(cancelAppointmentManagerSlice.actions.updatedStatus(state))
            return
          }
        }
        state.appointmentRescheduledSuccessfully = true
        state.reschedulingAppointment = false
        state.rescheduledAppointment = oldAppointmentDetails.appointment

        dispatch(
          showSuccessAlert(getAppointmentCancelMessage(oldAppointmentDetails))
        )
        dispatch(requestAppointmentsCountForToday())
        dispatch(cancelAppointmentManagerSlice.actions.updatedStatus(state))
        return
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: CancelAppointmentManagementStatus = {
        currentAction: CancelActions.Confirmation,
        enableContinueButton: false,
        reschedulingAppointment: false,
        errorWhileReschedulingAppointment: true,
        appointmentRescheduledSuccessfully: false,
        errorReason: 'Error',
      }
      dispatch(
        cancelAppointmentManagerSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

function getAppointmentTransactionObject(
  oldAppointmentDetails: FhirAppointmentDetail,
  rescheduleReason: R4.ICoding,
  errorReason?: string
): R4.IBundle {
  const oldUpdatedAppointment: R4.IAppointment = {
    ...oldAppointmentDetails.appointment,
  }
  const oldAppointmentMatchString: string = `W/${JSON.stringify(
    oldUpdatedAppointment.meta?.versionId ?? ' '
  )}`
  oldUpdatedAppointment.status = R4.AppointmentStatusKind._cancelled
  oldUpdatedAppointment.cancelationReason = {
    coding: [rescheduleReason],
  }
  if (errorReason) {
    oldUpdatedAppointment.patientInstruction = errorReason
  }
  let modifiedOldSlot: R4.ISlot = {
    resourceType: 'Slot',
    schedule: {},
  }
  if (oldAppointmentDetails.slot) {
    modifiedOldSlot = { ...oldAppointmentDetails.slot }
    modifiedOldSlot.status = R4.SlotStatusKind._free
  }
  const matchOldSlotString: string = `W/${JSON.stringify(
    modifiedOldSlot.meta?.versionId ?? ' '
  )}`

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `Appointment/${oldUpdatedAppointment.id}`,
        request: {
          ifMatch: oldAppointmentMatchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `${oldUpdatedAppointment.resourceType}/${oldUpdatedAppointment.id}`,
        },
        resource: oldUpdatedAppointment,
      },
      {
        fullUrl: `Slot/${modifiedOldSlot.id}`,
        request: {
          ifMatch: matchOldSlotString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `Slot/${modifiedOldSlot.id}`,
        },
        resource: modifiedOldSlot,
      },
    ],
  }
  if (oldAppointmentDetails.paymentReconcilation) {
    const paymentReconciliation: R4.IPaymentReconciliation = {
      ...oldAppointmentDetails.paymentReconcilation,
    }
    const paymentVersion: string = `W/${JSON.stringify(
      paymentReconciliation.meta?.versionId ?? ' '
    )}`
    paymentReconciliation.status = 'cancelled'
    requestBundle.entry?.push({
      fullUrl: `${paymentReconciliation.resourceType}/${paymentReconciliation.id}`,
      request: {
        ifMatch: paymentVersion,
        method: R4.Bundle_RequestMethodKind._put,
        url: `${paymentReconciliation.resourceType}/${paymentReconciliation.id}`,
      },
      resource: paymentReconciliation,
    })
  }

  return requestBundle
}

async function getRefund(
  appointmentId: string,
  paymentReconciliationId: string,
  type: string
): Promise<boolean> {
  const requestBody: R4.IParameters = getRefundBody(
    appointmentId,
    paymentReconciliationId,
    type
  )
  if (requestBody) {
    logger.info('Payment body')
    logger.info(requestBody)
    const fhirApi: EnrolCient = new EnrolCient()
    const response: any = await fhirApi.doCreateEnrolmentFlowRequest(
      `payment/cancel/portal`,
      requestBody
    )
    logger.info('Payment Response')
    logger.info(response)

    if (response.status === 200) {
      return true
    }
  }

  return false
}

export default cancelAppointmentManagerSlice.reducer
