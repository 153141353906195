/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Box, Grid, Typography } from '@material-ui/core'
import { kBackgroundPaper } from 'configs/styles/muiThemes'
import React from 'react'

interface IProps {
  isSelected?: boolean
  textTitle?: string
  onClick?: () => void
}

export const DaySelector: React.FC<IProps> = ({
  isSelected = true,
  textTitle = 'text',
  onClick,
}: IProps) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  <div onClick={onClick}>
    <Grid
      container
      direction='column'
      justify='space-between'
      alignItems='center'
    >
      <Grid item>
        <div
          style={{
            height: 4,
            width: 16,
            borderRadius: 10,
            backgroundColor: 'primary',
          }}
        />
      </Grid>
      <Grid item container direction='column'>
        <Grid
          container
          direction='row'
          justify='center'
          alignItems='center'
          alignContent='center'
        >
          <Grid item container alignItems='flex-start'>
            <Box paddingRight={3} id={textTitle} paddingLeft={1}>
              <Typography
                variant='subtitle2'
                align='center'
                style={{
                  height: 24,
                  width: 24,
                  fontWeight: 'normal',
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingLeft: 1,
                  paddingRight: 1,
                  background: 'transparent',
                  color: isSelected ? kBackgroundPaper : '',
                  backgroundColor: isSelected ? '#7986CB' : '',
                  borderRadius: 20,
                  cursor: 'pointer',
                }}
              >
                {textTitle}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </div>
)
