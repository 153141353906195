import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import moment from 'moment'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { getObservationObjectForIPDDischarge } from 'utils/fhirResoureHelpers/observationHelpers'
import { DischargeStatus } from './dischargeHandlerStatus'

const initialState: DischargeStatus = {
  discharging: false,
  dischargeSuccessful: false,
  dischargeNoteUpdated: false,
  error: false,
  errorMessage: '',
}

const dischargeSlice = createSlice({
  name: 'dischargeSlice',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<DischargeStatus>) {
      state.discharging = action.payload.discharging
      state.dischargeSuccessful = action.payload.dischargeSuccessful
      state.dischargeNoteUpdated = action.payload.dischargeNoteUpdated
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetAddVitalsDetails(state, action: PayloadAction<DischargeStatus>) {
      state.discharging = initialState.discharging
      state.dischargeSuccessful = initialState.dischargeSuccessful
      state.dischargeNoteUpdated = action.payload.dischargeNoteUpdated
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
    },
  },
})

export const saveDischargeNotesForVisit =
  (
    appointment: FhirActiveIPDDetailsForMedicalRole,
    notes: string,
    discharge: R4.ICoding,
    preAddedObservation?: R4.IObservation
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: DischargeStatus = {
      discharging: true,
      dischargeSuccessful: false,
      dischargeNoteUpdated: false,
      error: false,
    }
    dispatch(dischargeSlice.actions.updateAddVitalsStatus(addingState))

    try {
      let bundleObject: R4.IBundle = createBundleObjectForObservations(
        appointment,
        notes,
        discharge
      )
      if (preAddedObservation === undefined) {
        bundleObject = createBundleObjectForObservations(
          appointment,
          notes,
          discharge
        )
      } else {
        bundleObject = updateBundleObjectForObservations(
          notes,
          discharge,
          preAddedObservation
        )
      }

      const appointmentType = appointment.mainServiceRequest.code
        ? appointment.mainServiceRequest.code.coding
          ? appointment.mainServiceRequest.code.coding[0].code ?? ''
          : ''
        : ''
      const ipdDayCare: boolean =
        appointmentType === '304903009' || appointmentType === '33022008'

      const resource: any = {
        eventType: 'saveDischargeNotes',
        isOpdBased: ipdDayCare,
        eventBody: bundleObject,
      }

      const enRolClient: EnrolCient = new EnrolCient()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        `ipd/${appointment.mainServiceRequest.id}/initiateDischarge`,
        resource
      )

      if ((response as R4.IBundle).id) {
        // const status = await initiatePayment(appointment.mainServiceRequest.id!)
        addingState = {
          discharging: false,
          dischargeSuccessful: false,
          dischargeNoteUpdated: true,
          error: false,
          errorMessage: '',
        }
        dispatch(showSuccessAlert('Discharge Note Updated Successfully'))
        dispatch(dischargeSlice.actions.updateAddVitalsStatus(addingState))
      } else {
        const errorCreatePersonState: DischargeStatus = {
          discharging: false,
          dischargeSuccessful: false,
          dischargeNoteUpdated: false,
          error: true,
          errorMessage: 'Error while discharging',
        }
        dispatch(
          dischargeSlice.actions.updateAddVitalsStatus(errorCreatePersonState)
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: DischargeStatus = {
        discharging: false,
        dischargeSuccessful: false,
        dischargeNoteUpdated: false,
        error: true,
        errorMessage: 'Error while discharging',
      }
      dispatch(
        dischargeSlice.actions.updateAddVitalsStatus(errorCreatePersonState)
      )
    }
  }

export const initiateDischarge =
  (
    appointment: FhirActiveIPDDetailsForMedicalRole,

    notes: string,
    discharge: R4.ICoding,
    preAddedObservation?: R4.IObservation
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: DischargeStatus = {
      discharging: true,
      dischargeSuccessful: false,
      dischargeNoteUpdated: false,
      error: false,
    }
    dispatch(dischargeSlice.actions.updateAddVitalsStatus(addingState))

    try {
      //   const bundleObject =
      //     getBundleObjectForDischargeObservations(preAddedObservation)
      const bundleObject: R4.IBundle = createBundleObjectForObservationsFinal(
        appointment,
        notes,
        discharge
      )

      const appointmentType = appointment.mainServiceRequest.code
        ? appointment.mainServiceRequest.code.coding
          ? appointment.mainServiceRequest.code.coding[0].code ?? ''
          : ''
        : ''
      const ipdDayCare: boolean =
        appointmentType === '304903009' || appointmentType === '33022008'

      const resource: any = {
        eventType: 'saveDischargeNotes',
        isOpdBased: ipdDayCare,
        eventBody: bundleObject,
      }

      const enRolClient: EnrolCient = new EnrolCient()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        `ipd/${appointment.mainServiceRequest.id}/initiateDischarge`,
        resource
      )

      if ((response as R4.IBundle).id) {
        const dischargeResponse: any =
          await enRolClient.doCreateEnrolmentFlowRequest(
            `ipd/${appointment.mainServiceRequest.id}/initiateDischarge`,
            {
              eventType: 'discharge',
              isOpdBased: ipdDayCare,
              eventBody: bundleObject,
            }
          )
        if (dischargeResponse.status === 'Discharge Of Patient initiated') {
          addingState = {
            discharging: false,
            dischargeSuccessful: true,
            dischargeNoteUpdated: false,
            error: false,
            errorMessage: '',
          }
          dispatch(showSuccessAlert('Discharge initiated Successfully'))
          dispatch(dischargeSlice.actions.updateAddVitalsStatus(addingState))
        } else {
          addingState = {
            discharging: false,
            dischargeSuccessful: false,
            dischargeNoteUpdated: false,
            error: true,
            errorMessage: '',
          }
          dispatch(showErrorAlert('Error while discharging , please try later'))
          dispatch(dischargeSlice.actions.updateAddVitalsStatus(addingState))
        }
      } else {
        const errorCreatePersonState: DischargeStatus = {
          discharging: false,
          dischargeSuccessful: false,
          dischargeNoteUpdated: false,
          error: true,
          errorMessage: 'Error while discharging',
        }
        dispatch(showErrorAlert('Error while discharging , please try later'))
        dispatch(
          dischargeSlice.actions.updateAddVitalsStatus(errorCreatePersonState)
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: DischargeStatus = {
        discharging: false,
        dischargeSuccessful: false,
        dischargeNoteUpdated: false,
        error: true,
        errorMessage: 'Error while discharging',
      }
      dispatch(
        dischargeSlice.actions.updateAddVitalsStatus(errorCreatePersonState)
      )
    }
  }

function updateBundleObjectForObservations(
  notes: string,
  reason: R4.ICoding,
  observation: R4.IObservation
): R4.IBundle {
  const practitionerRoleDetail: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const practitionerDetail: R4.IPractitioner =
    getCurrentUserPractitionerDetails()
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const matchPractitionerString: string = `W/${JSON.stringify(
    observation.meta?.versionId ?? ' '
  )}`

  const observationObject: R4.IObservation = {
    ...observation,
    code: {
      text: reason.display ?? '',
      coding: [reason],
    },
    note: [
      {
        authorReference: {
          reference: `${practitionerDetail.resourceType}/${practitionerDetail.id}`,
        },
        text: notes,
        time: new Date().toISOString(),
      },
    ],
    valueString: notes,
  }

  const entry: R4.IBundle_Entry = {
    fullUrl: `Observation/${observationObject.id}`,
    request: {
      method: R4.Bundle_RequestMethodKind._put,
      url: `Observation/${observationObject.id}`,
      ifMatch: matchPractitionerString,
    },
    resource: observationObject,
  }
  requestBundle.entry?.push(entry)

  return requestBundle
}

function getBundleObjectForDischargeObservations(
  observation: R4.IObservation
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const matchPractitionerString: string = `W/${JSON.stringify(
    observation.meta?.versionId ?? ' '
  )}`

  const observationObject: R4.IObservation = {
    ...observation,
    status: R4.ObservationStatusKind._final,
  }
  observationObject.meta = {
    profile: [
      'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationDischarge',
    ],
  }

  const entry: R4.IBundle_Entry = {
    fullUrl: `Observation/${observationObject.id}`,
    request: {
      method: R4.Bundle_RequestMethodKind._put,
      url: `Observation/${observationObject.id}`,
      ifMatch: matchPractitionerString,
    },
    resource: observationObject,
  }
  requestBundle.entry?.push(entry)

  return requestBundle
}

function createBundleObjectForObservations(
  appointment: FhirActiveIPDDetailsForMedicalRole,
  notes: string,
  reason: R4.ICoding
): R4.IBundle {
  const practitionerRoleDetail: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const practitionerDetails: R4.IPractitioner =
    getCurrentUserPractitionerDetails()
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const encounterRef: R4.IReference = {
    reference: `Encounter/${appointment.mainEncounter!.id!}`,
  }

  const observationObject: R4.IObservation = {
    ...getObservationObjectForIPDDischarge(appointment, encounterRef),
  }

  observationObject.meta = {
    profile: [
      'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationDischarge',
    ],
  }
  observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
  observationObject.effectiveDateTime = moment().format('YYYY-MM-DDTHH:mm:ssZ')
  observationObject.basedOn = [
    {
      reference: `ServiceRequest/${appointment.mainServiceRequest.id!}`,
    },
  ]
  observationObject.code = {
    text: reason.display ?? '',
    coding: [reason],
  }

  observationObject.category = [
    {
      text: '',
      coding: [
        {
          code: '11535-2',
          display: 'Discharge',
          system: 'http://loinc.org',
        },
      ],
    },
  ]
  if (notes.length > 0) {
    observationObject.note = [
      {
        authorReference: {
          reference: `${practitionerDetails.resourceType}/${practitionerDetails.id}`,
        },
        text: notes,
        time: new Date().toISOString(),
      },
    ]
  }
  observationObject.performer = [
    {
      display: practitionerRoleDetail.practitioner
        ? practitionerRoleDetail.practitioner.display ?? ''
        : '',
      reference: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
    },
    practitionerRoleDetail.organization!,
  ]
  observationObject.valueString = notes

  observationObject.status = R4.ObservationStatusKind._preliminary
  const entry: R4.IBundle_Entry = {
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: observationObject.resourceType,
    },
    resource: observationObject,
  }
  requestBundle.entry?.push(entry)

  return requestBundle
}

function createBundleObjectForObservationsFinal(
  appointment: FhirActiveIPDDetailsForMedicalRole,
  notes: string,
  reason: R4.ICoding
): R4.IBundle {
  const practitionerRoleDetail: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const practitionerDetail: R4.IPractitioner =
    getCurrentUserPractitionerDetails()
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const encounterRef: R4.IReference = {
    reference: `Encounter/${appointment.mainEncounter!.id!}`,
  }

  const observationObject: R4.IObservation = {
    ...getObservationObjectForIPDDischarge(appointment, encounterRef),
  }

  observationObject.meta = {
    profile: [
      'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationDischarge',
    ],
  }
  observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
  observationObject.effectiveDateTime = moment().format('YYYY-MM-DDTHH:mm:ssZ')
  observationObject.basedOn = [
    {
      reference: `ServiceRequest/${appointment.mainServiceRequest.id!}`,
    },
  ]
  observationObject.code = {
    text: reason.display ?? '',
    coding: [reason],
  }

  observationObject.category = [
    {
      text: '',
      coding: [
        {
          code: '11535-2',
          display: 'Discharge',
          system: 'http://loinc.org',
        },
      ],
    },
  ]
  if (notes.length > 0) {
    observationObject.note = [
      {
        authorReference: {
          reference: `${practitionerDetail.resourceType}/${practitionerDetail.id}`,
        },
        text: notes,
        time: new Date().toISOString(),
      },
    ]
  }
  observationObject.performer = [
    {
      display: practitionerRoleDetail.practitioner
        ? practitionerRoleDetail.practitioner.display ?? ''
        : '',
      reference: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
    },
    practitionerRoleDetail.organization!,
  ]
  observationObject.valueString = notes

  observationObject.status = R4.ObservationStatusKind._final
  const entry: R4.IBundle_Entry = {
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: observationObject.resourceType,
    },
    resource: observationObject,
  }
  requestBundle.entry?.push(entry)

  return requestBundle
}

async function initiatePayment(serviceRequestId: string): Promise<string> {
  const data = {
    eventType: 'offline',
  }
  const enRolClient: EnrolCient = new EnrolCient()
  const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
    `ipd/${serviceRequestId}/payment`,
    data
  )
  return response
}

export const resetAddDischarge = () => (dispatch: AppDispatch) => {
  dispatch(dischargeSlice.actions.resetAddVitalsDetails(initialState))
}

export default dischargeSlice.reducer
