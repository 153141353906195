import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  withStyles,
  Box,
  Typography,
} from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { kSelectedTabBackground } from 'configs/styles/muiThemes'
import React, { useEffect } from 'react'
import { logger } from 'utils/logger'
import { WelloFormItemLabel } from './LeftMenu/WelloFormItemLabel'
// import { WelloFormItemLabel } from './LeftMenu/WelloFormItemLabel'

export interface TabOption {
  value: string
  displayText: string
}

export interface TabbedMultiSelectorProps {
  options: TabOption[]
  preSelectedValues: string[]
  onSelectionChanged: (option: string[]) => void
  menuLabel?: string
  size?: 'small' | 'medium' | 'large'
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: `1px solid ${theme.palette.divider}`,
    },
    divider: {
      margin: theme.spacing(1, 0.5),
    },
  })
)

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup)

export function TabbedMultiSelector(props: TabbedMultiSelectorProps) {
  const [values, setValues] = React.useState(props.preSelectedValues)

  const handleSelection = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string[]
  ) => {
    if (newAlignment.length > 0) {
      setValues(newAlignment)
      props.onSelectionChanged(newAlignment)
    }
  }

  const classes = useStyles()

  const defaultBackgroundColor = useTheme().palette.background.default
  const paperBackGroundColor = useTheme().palette.background.paper

  return (
    <div>
      <Box>
        {props.menuLabel && (
          <Box py={1} px={0.25} display='flex' flexDirection='row'>
            <Typography variant='subtitle2'>{props.menuLabel ?? ''}</Typography>
          </Box>
        )}
      </Box>
      <StyledToggleButtonGroup
        size={props.size ?? 'small'}
        value={values}
        onChange={handleSelection}
        aria-label='text alignment'
        style={{
          backgroundColor: defaultBackgroundColor,
          margin: props.size === 'small' ? '0px' : undefined,
          padding: props.size === 'small' ? '4px' : undefined,
        }}
        //   id={value}
      >
        {props.options.map((option) => (
          <ToggleButton
            //   id={value}
            key={`tg_button_key${option.value}`}
            value={option.value}
            aria-label='left aligned'
            id={`tg_button_key${option.value}`}
            style={{
              background: values.includes(option.value)
                ? paperBackGroundColor
                : defaultBackgroundColor,
              margin: props.size === 'small' ? 1 : undefined,
              padding: props.size === 'small' ? 2 : undefined,
            }}
          >
            <Typography
              variant='subtitle2'
              style={{
                color: kSelectedTabBackground,
                letterSpacing: '1.0',
                paddingLeft: 8,
                paddingRight: 8,
              }}
            >
              {option.displayText}
            </Typography>
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </div>
  )
}
