import { R4 } from '@ahryman40k/ts-fhir-types'
import axios, { CancelTokenSource } from 'axios'

import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import { isNumber } from 'utils/stringUtils'

export async function getPatientsSearch(
  searchString: string,
  cancelToken: string
): Promise<R4.IPatient[] | FHIRErrorResponses> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const searchParameters: any = {
    _count: 1000,
    _total: 'accurate',
    active: true,
  }
  if (isNumber(searchString)) {
    searchParameters.phone = `+91${searchString}`
  } else {
    searchParameters['name:contains'] = searchString
  }

  axios.CancelToken.source()
  if (cancelTokenStore.has(cancelToken)) {
    const controlTokenForSearch: CancelTokenSource =
      cancelTokenStore.get(cancelToken)
    controlTokenForSearch.cancel('new param added')
    cancelTokenStore.delete(cancelToken)
  }
  cancelTokenStore.set(cancelToken, axios.CancelToken.source())
  try {
    const response: any = await fhirClient.doGetResource(
      `/Patient`,
      searchParameters,
      (cancelTokenStore.get(cancelToken) as CancelTokenSource).token
    )
    if (response === undefined) {
      console.error('Request canceled ')
      return {
        status: 301,
        data: {},
        displayText: 'Request canceled',
      }
    }
    if (response && response.entry) {
      return response.entry.map((entry: any) => entry.resource as R4.IPatient)
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.error('Request canceled ', error)
      return {
        status: 301,
        data: error,
        displayText: 'Request canceled',
      }
    }
    console.error(error)
  }

  return []
}
