import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CardContent,
  CardActions,
  Typography,
  Card,
  Paper,
  CircularProgress,
} from '@material-ui/core'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
  Label,
} from 'recharts'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestForDateWiseCollectionGraph } from 'redux/administration/graph/dateWiseGraph/dateWiseSearchGraphSearchSlice'
import { RootState } from 'redux/rootReducer'
import { requestDataForDashboardTable } from 'redux/administration/dashboardTable/dahsboardTableSearchSlice'
import { InvoiceDataDisplay } from './invoiceTableDisplay'

interface Props {
  date: Date
}
export const InvoiceDataTable: React.FC<Props> = ({ date }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const adminGraphSearchSlice = useSelector(
    (state: RootState) => state.dashboardTableSearchSlice
  )

  useEffect(() => {
    dispatch(requestDataForDashboardTable(date, new Date(), 'range'))
  }, [dispatch])

  return (
    <Box width='100%' paddingRight={0.5}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        width='100%'
        height='100%'
        alignContent='center'
        paddingBottom={2}
      >
        <Box flexDirection='row' width='100%' display='flex'>
          {adminGraphSearchSlice.searchingAppointments && (
            <Box
              flexDirection='row'
              display='flex'
              alignSelf='center'
              justifyContent='center'
              paddingBottom={7}
            >
              {/* <CircularProgress size={25} /> */}
            </Box>
          )}

          {adminGraphSearchSlice.errorWhileSearchingAppointments && (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              width='100%'
              height='100%'
              alignContent='center'
            >
              <Typography variant='subtitle1' color='error' align='center'>
                {/* Error while searching data */}
              </Typography>
            </Box>
          )}

          {adminGraphSearchSlice.noResultsAvailable && (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              width='100%'
              height='100%'
              alignContent='center'
              paddingBottom={7}
            >
              <Typography variant='subtitle1' color='primary' align='center'>
                {/* No data found */}
              </Typography>
            </Box>
          )}
          {adminGraphSearchSlice.resultsAvailable &&
            adminGraphSearchSlice.tableData && (
              <InvoiceDataDisplay
                usersData={adminGraphSearchSlice.tableData ?? []}
              />
            )}
        </Box>
      </Box>
    </Box>
  )
}
