import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitReference,
  isTherapist,
} from 'services/userDetailsService'
import { getGenderOfDoctor } from 'utils/fhirResourcesHelper'
import { getCountForTherapist } from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { logger } from 'utils/logger'
import { WellnessAppointmentCountStatus } from './WellnessAppointmentCountStatus'

const initialState: WellnessAppointmentCountStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
  completedCount: 0,
  activeCount: 0,
  cancelled: 0,
}

const appointmentWellnessCountPerUnitDetailsSlice = createSlice({
  name: 'appointmentWellnessCountPerUnitDetailsSlice',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<WellnessAppointmentCountStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.activeCount = action.payload.activeCount
      state.completedCount = action.payload.completedCount
      state.cancelled = action.payload.cancelled
      state.errorReason = action.payload.errorReason

      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const resetWellnessAppointmentForUnitCountDetail =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: WellnessAppointmentCountStatus = {
      searchingAppointments: false,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      completedCount: 0,
      activeCount: 0,
      cancelled: 0,
    }
    dispatch(
      appointmentWellnessCountPerUnitDetailsSlice.actions.updatedStatus(state)
    )
  }
export const requestForWellnessAppointmentCountBoth =
  (unitId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: WellnessAppointmentCountStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      completedCount: 0,
      activeCount: 0,
      cancelled: 0,
    }
    dispatch(
      appointmentWellnessCountPerUnitDetailsSlice.actions.updatedStatus(state)
    )

    try {
      const searchParameters: any = {}
      const fhirClient: MasterFhirClient = new MasterFhirClient()
      const response: any =
        await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
          `PractitionerRole?organization=Organization/${unitId}&role=doctor,ayurveda&active=true`,
          '',
          searchParameters
        )

      const resp: R4.IBundle = response as R4.IBundle
      if (!resp) {
        state.errorWhileSearchingAppointments = true
        state.searchingAppointments = false
        dispatch(
          appointmentWellnessCountPerUnitDetailsSlice.actions.updatedStatus(
            state
          )
        )
      } else {
        const appointmentResponse: R4.IBundle = resp
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          if (appointmentResponse.entry) {
            const practRoleArray: R4.IPractitionerRole[] =
              appointmentResponse.entry.map(
                (item) => item.resource as R4.IPractitionerRole
              )
            const roleIds: string[] = []
            practRoleArray.forEach((role) => {
              if (role.practitioner)
                roleIds.push(role.practitioner.reference ?? '')
            })
            const data = await getAppointmentCount(roleIds)
            const completedCount = await getAppointmentCompletedCount(roleIds)
            const cancelled = await getAppointmentCancelledCount(roleIds)
            state.resultsAvailable = true
            state.searchingAppointments = false
            state.activeCount = data
            state.completedCount = completedCount
            state.cancelled = cancelled
            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingAppointments = false
            dispatch(
              appointmentWellnessCountPerUnitDetailsSlice.actions.updatedStatus(
                state
              )
            )
          }
        } else {
          const errorSearchDoctor: WellnessAppointmentCountStatus = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            completedCount: 0,
            activeCount: 0,
            cancelled: 0,
          }
          dispatch(
            appointmentWellnessCountPerUnitDetailsSlice.actions.updatedStatus(
              errorSearchDoctor
            )
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: WellnessAppointmentCountStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
        completedCount: 0,
        activeCount: 0,
        cancelled: 0,
      }
      dispatch(
        appointmentWellnessCountPerUnitDetailsSlice.actions.updatedStatus(
          errorSearchDoctor
        )
      )
    }
  }

async function getAppointmentCount(rolesArray: string[]): Promise<number> {
  const searchParameters: any = {
    _count: 0,
    _total: 'accurate',
    status: 'booked',
  }
  const fhirClient: MasterFhirClient = new MasterFhirClient()
  const response: any =
    await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
      `Appointment?practitioner=${rolesArray.join(',')}`,
      '',
      searchParameters
    )
  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const appointmentResponse: R4.IBundle = response as R4.IBundle
  if (appointmentResponse.total) {
    if (appointmentResponse.total > 0) {
      return appointmentResponse.total
    }
  }

  return 0
}

async function getAppointmentCompletedCount(
  rolesArray: string[]
): Promise<number> {
  const searchParameters: any = {
    _count: 0,
    _total: 'accurate',
    status: 'fulfilled',
  }
  const fhirClient: MasterFhirClient = new MasterFhirClient()
  const response: any =
    await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
      `Appointment?practitioner=${rolesArray.join(',')}`,
      '',
      searchParameters
    )
  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const appointmentResponse: R4.IBundle = response as R4.IBundle
  if (appointmentResponse.total) {
    if (appointmentResponse.total > 0) {
      return appointmentResponse.total
    }
  }

  return 0
}

async function getAppointmentCancelledCount(
  rolesArray: string[]
): Promise<number> {
  const searchParameters: any = {
    _count: 0,
    _total: 'accurate',
    status: 'cancelled',
  }
  const fhirClient: MasterFhirClient = new MasterFhirClient()
  const response: any =
    await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
      `Appointment?practitioner=${rolesArray.join(',')}`,
      '',
      searchParameters
    )
  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const appointmentResponse: R4.IBundle = response as R4.IBundle
  if (appointmentResponse.total) {
    if (appointmentResponse.total > 0) {
      return appointmentResponse.total
    }
  }

  return 0
}

export default appointmentWellnessCountPerUnitDetailsSlice.reducer
