export const DaysInSingleLetter: string[] = [
  'M',
  'T',
  'W',
  'Th',
  'F',
  'Sa',
  'S',
]

const myArray: number[] = []
for (let i = 1940; i <= new Date().getFullYear(); i++) {
  myArray.push(i)
}

interface KeyValue {
  key: number
  value: number
}

export const arrayYears: KeyValue[] = myArray.map((e) => {
  const v: KeyValue = {
    key: e,
    value: e,
  }
  return v
})
