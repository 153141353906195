import { R4 } from '@ahryman40k/ts-fhir-types'
import { as } from 'fp-ts/lib/Option'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import * as _ from 'lodash'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import moment from 'moment'
import {
  ActionsEntity,
  CardsEntity,
  CPGRecommendationResponse,
  SuggestionsEntity,
} from 'redux/subscription/cpgRecommendations/cpgResponse'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCodeOfSystemFromCodableConcept,
  getCodeOfSystemFromCodableConceptList,
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultCodeOfSystemFromCodableConceptList,
  getDefaultDisplayOfSystemFromCodableConcept,
  getDisplayOfSystemFromCodableConcept,
  getIdentifierValueBySystem,
  getIdOfFirstOccurrenceOfResource,
} from 'utils/fhirResourcesHelper'
import { KriyaDetail } from 'views/components/consultation/plan/ayurveda/addKriyasForEncounter'

export async function getCarePlanOfPatient(
  patientId: String,
  planDefinitionUrl: String,
  requestCareCreation?: boolean
): Promise<R4.ICarePlan | undefined> {
  let carePlan: R4.ICarePlan | undefined

  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const searchParameters: any = {
    patient: `Patient/${patientId}`,
    'instantiates-canonical:PlanDefinition.name': planDefinitionUrl,
    status: 'active',
  }

  const response: any = await fhirClient.doGetResource(
    '/CarePlan',
    searchParameters
  )

  const responseBundle: R4.IBundle = response as R4.IBundle
  if (responseBundle.entry && responseBundle.entry.length > 0) {
    carePlan = responseBundle.entry[0].resource as R4.ICarePlan
  }

  if (carePlan === undefined && requestCareCreation) {
    const enRolClient: EnrolCient = new EnrolCient()

    const subResponse = await enRolClient.doCreateEnrolmentFlowRequest(
      'careplan/request-booking',
      {
        patientId,
        carePlan: planDefinitionUrl,
        fromDate: `${moment(moment(new Date()).format('YYYY-MM-DD'))
          .startOf('day')
          .toISOString()}`,
        endDate: `${moment(
          moment(new Date()).add(50, 'day').format('YYYY-MM-DD')
        )
          .startOf('day')
          .toISOString()}`,
      }
    )

    const resultId = getIdOfFirstOccurrenceOfResource(
      subResponse as R4.IBundle,
      'CarePlan'
    )

    if (resultId) carePlan = await getCarePlanByIdCurrentState(resultId)
  }

  return carePlan
}

export function getActionBundleFromCds(inputResponse: CardsEntity) {
  const actions: ActionsEntity[] = []
  if (inputResponse.suggestions && inputResponse.suggestions.length > 0) {
    inputResponse.suggestions.forEach((suggestion) => {
      if (suggestion.actions && suggestion.actions.length > 0) {
        suggestion.actions.forEach((action) => {
          actions.push(action)
        })
      }
    })
  }

  return actions
}

export function interceptCdsResponse(inputResponse: CardsEntity): CardsEntity {
  const entity: CardsEntity = { ...inputResponse }

  const categoryBasedSuggestions: Map<string, any> = new Map<string, any>()

  if (inputResponse.suggestions && inputResponse.suggestions.length > 0) {
    inputResponse.suggestions.forEach((suggestion) => {
      if (suggestion.actions && suggestion.actions.length > 0) {
        suggestion.actions.forEach((action) => {
          if (action.resource && action.resource.resourceType === 'Bundle') {
            const resource: R4.IBundle = action.resource as R4.IBundle

            const goals = getGoalFromEntries(resource.entry ?? [])
            const addressingConditions = getAddressingConditions(
              resource.entry ?? []
            )

            const specialistReferrals = getAppointments(resource.entry ?? [])

            const labTests = getTestsToBePerformed(resource.entry ?? [])
            const medications = getMedications(resource.entry ?? [])

            const dietPlans = getDietTasksFromEntries(resource.entry ?? [])
            const kriyas = getKriyas(resource.entry ?? [])
            const careTeamFollowUps = getCarePlanDescriptions(
              resource.entry ?? []
            )

            const instructions = getInstructions(resource.entry ?? [])
            const carePlanActivities = getCarePlanActivities(
              resource.entry ?? []
            )

            if (goals.length > 0) {
              if (categoryBasedSuggestions.has('goals')) {
                categoryBasedSuggestions.set('goals', [
                  ...categoryBasedSuggestions.get('goals'),
                  ...goals,
                ])
              } else {
                categoryBasedSuggestions.set('goals', goals)
              }
            }

            if (addressingConditions.length > 0) {
              if (categoryBasedSuggestions.has('conditions')) {
                categoryBasedSuggestions.set('conditions', [
                  ...categoryBasedSuggestions.get('conditions'),
                  ...addressingConditions,
                ])
              } else {
                categoryBasedSuggestions.set('conditions', addressingConditions)
              }
            }

            if (specialistReferrals.length > 0) {
              if (categoryBasedSuggestions.has('specialistReferral')) {
                categoryBasedSuggestions.set('specialistReferral', [
                  ...categoryBasedSuggestions.get('specialistReferral'),
                  ...specialistReferrals,
                ])
              } else {
                categoryBasedSuggestions.set(
                  'specialistReferral',
                  specialistReferrals
                )
              }
            }

            if (labTests.length > 0) {
              if (categoryBasedSuggestions.has('labTests')) {
                categoryBasedSuggestions.set('labTests', [
                  ...categoryBasedSuggestions.get('labTests'),
                  ...labTests,
                ])
              } else {
                categoryBasedSuggestions.set('labTests', labTests)
              }
            }

            if (medications.length > 0) {
              if (categoryBasedSuggestions.has('medications')) {
                categoryBasedSuggestions.set('medications', [
                  ...categoryBasedSuggestions.get('medications'),
                  ...medications,
                ])
              } else {
                categoryBasedSuggestions.set('medications', medications)
              }
            }

            if (dietPlans.length > 0) {
              if (categoryBasedSuggestions.has('dietPlan')) {
                categoryBasedSuggestions.set('dietPlan', [
                  ...categoryBasedSuggestions.get('dietPlan'),
                  ...dietPlans,
                ])
              } else {
                categoryBasedSuggestions.set('dietPlan', dietPlans)
              }
            }

            if (kriyas.length > 0) {
              if (categoryBasedSuggestions.has('kriyas')) {
                categoryBasedSuggestions.set('kriyas', [
                  ...categoryBasedSuggestions.get('kriyas'),
                  ...kriyas,
                ])
              } else {
                categoryBasedSuggestions.set('kriyas', kriyas)
              }
            }

            if (careTeamFollowUps.length > 0) {
              if (categoryBasedSuggestions.has('careTeamFollowUps')) {
                categoryBasedSuggestions.set('careTeamFollowUps', [
                  ...categoryBasedSuggestions.get('careTeamFollowUps'),
                  careTeamFollowUps,
                ])
              } else {
                categoryBasedSuggestions.set('careTeamFollowUps', [
                  careTeamFollowUps,
                ])
              }
            }

            if (instructions.length > 0) {
              if (categoryBasedSuggestions.has('instructions')) {
                categoryBasedSuggestions.set('instructions', [
                  ...categoryBasedSuggestions.get('instructions'),
                  ...instructions,
                ])
              } else {
                categoryBasedSuggestions.set('instructions', instructions)
              }
            }

            if (carePlanActivities.length > 0) {
              if (categoryBasedSuggestions.has('carePlanActivities')) {
                categoryBasedSuggestions.set('carePlanActivities', [
                  ...categoryBasedSuggestions.get('carePlanActivities'),
                  ...carePlanActivities,
                ])
              } else {
                categoryBasedSuggestions.set(
                  'carePlanActivities',
                  carePlanActivities
                )
              }
            }
          }
        })
      }
    })
  }

  entity.interceptedSuggestions = categoryBasedSuggestions

  return entity
}

async function getCarePlanByIdCurrentState(
  carePlanId: string
): Promise<R4.ICarePlan> {
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response = await fhirApi.doGetResource(`/${carePlanId}`)
  const relatedFhirDecodeRes = response as R4.ICarePlan
  return relatedFhirDecodeRes
}
export function mergeAndGetSingleCard(
  inputCdsResponse: CPGRecommendationResponse
): CardsEntity | undefined {
  const entity: CardsEntity = inputCdsResponse.cards![0]

  const mergedInterceptedSuggestions: Map<string, any> = new Map<string, any>()

  if (inputCdsResponse.cards && inputCdsResponse.cards.length > 0) {
    inputCdsResponse.cards.forEach((card) => {
      if (card.interceptedSuggestions) {
        card.interceptedSuggestions.forEach((value, key) => {
          if (mergedInterceptedSuggestions.has(key)) {
            mergedInterceptedSuggestions.set(key, [
              ...mergedInterceptedSuggestions.get(key),
              ...value,
            ])
          } else {
            mergedInterceptedSuggestions.set(key, value)
          }
        })
      }
    })
  }

  const combinedEntity = {
    ...entity,
    interceptedSuggestions: mergedInterceptedSuggestions,
  }

  const deDuplicationMap: Map<string, any> = new Map<string, any>()

  if (combinedEntity.interceptedSuggestions) {
    combinedEntity.interceptedSuggestions.forEach((value, key) => {
      if (key === 'medications' && value.length > 0) {
        deDuplicationMap.set(key, deDuplicateMedicationRequests(value as any[]))
      } else if (key === 'goals' && value.length > 0) {
        deDuplicationMap.set(key, deDuplicateGoals(value as any[]))
      } else if (key === 'conditions' && value.length > 0) {
        deDuplicationMap.set(key, deDuplicateConditions(value as any[]))
      } else if (key === 'labTests' && value.length > 0) {
        deDuplicationMap.set(key, deDuplicateLabTests(value as any[]))
      } else if (key === 'kriyas' && value.length > 0) {
        deDuplicationMap.set(key, deDuplicateKriyas(value as any[]))
      } else if (key === 'careTeamFollowUps' && value.length > 0) {
        deDuplicationMap.set(key, deDuplicateCareTeamFollowUps(value as any[]))
      } else if (key === 'instructions' && value.length > 0) {
        deDuplicationMap.set(key, deDuplicateInstructions(value as any[]))
      } else if (key === 'carePlanActivities' && value.length > 0) {
        deDuplicationMap.set(key, deDuplicateCarePlanActivities(value as any[]))
      } else {
        deDuplicationMap.set(key, value)
      }
    })
  }

  const returningEntity = {
    ...combinedEntity,
    interceptedSuggestions: deDuplicationMap,
  }

  console.log('---------returnEntity-------------', returningEntity)

  return returningEntity
}

function mergeSuggestions(
  baseEntity: CardsEntity,
  incomingSuggestion: SuggestionsEntity
) {
  if (baseEntity.suggestions && baseEntity.suggestions.length > 0) {
    if (incomingSuggestion.actions && incomingSuggestion.actions.length > 0) {
      incomingSuggestion.actions.forEach((eachAction) => {
        mergeActions(baseEntity, eachAction)
      })
    }
  } else {
    baseEntity.suggestions = [incomingSuggestion]
  }
}

function mergeActions(baseEntity: CardsEntity, incomingAction: ActionsEntity) {
  if (baseEntity.suggestions && baseEntity.suggestions.length > 0) {
    if (
      baseEntity.suggestions[0].actions &&
      baseEntity.suggestions[0].actions.length > 0
    ) {
      mergeActionDetails(baseEntity.suggestions[0].actions[0], incomingAction)
    } else {
      baseEntity.suggestions[0].actions = [incomingAction]
    }
  }
}

function mergeActionDetails(
  baseEntityAction: ActionsEntity,
  incomingAction: ActionsEntity
) {
  if (baseEntityAction.resource) {
    const baseBundle: R4.IBundle = baseEntityAction.resource as R4.IBundle
    const incomingBundle: R4.IBundle = incomingAction.resource as R4.IBundle
    mergeActionBundles(baseBundle, incomingBundle)
  } else {
    baseEntityAction.resource = incomingAction.resource
    baseEntityAction.type = incomingAction.type
    baseEntityAction.description = incomingAction.description
  }
}

function mergeActionBundles(
  baseActionBundle: R4.IBundle,
  incomingActionBundle: R4.IBundle
) {
  if (baseActionBundle.entry && baseActionBundle.entry.length > 0) {
    if (incomingActionBundle.entry && incomingActionBundle.entry.length > 0) {
      incomingActionBundle.entry.forEach((e) => {
        switch (e.resource?.resourceType) {
          case 'CarePlan': {
            const indexOfBaseCarePlan = baseActionBundle.entry?.findIndex(
              (eachRes) => eachRes.resource?.resourceType === 'CarePlan'
            )
            if (indexOfBaseCarePlan && indexOfBaseCarePlan >= 0) {
              mergeActionEntryCarePlanEntity(
                baseActionBundle.entry![indexOfBaseCarePlan]
                  .resource as R4.ICarePlan,
                e.resource
              )
            } else {
              baseActionBundle.entry?.push(e)
            }

            break
          }

          case 'PlanDefinition': {
            const indexOfBaseCarePlan = baseActionBundle.entry?.findIndex(
              (eachRes) => eachRes.resource?.resourceType === 'PlanDefinition'
            )
            if (indexOfBaseCarePlan && indexOfBaseCarePlan >= 0) {
              mergeActionEntryPlanDefinitionEntity(
                baseActionBundle.entry![indexOfBaseCarePlan]
                  .resource as R4.IPlanDefinition,
                e.resource
              )
            } else {
              baseActionBundle.entry?.push(e)
            }

            break
          }

          case 'RequestGroup': {
            const indexOfBaseCarePlan = baseActionBundle.entry?.findIndex(
              (eachRes) => eachRes.resource?.resourceType === 'RequestGroup'
            )
            if (indexOfBaseCarePlan && indexOfBaseCarePlan >= 0) {
              mergeActionEntryRequestGroupEntity(
                baseActionBundle.entry![indexOfBaseCarePlan]
                  .resource as R4.IRequestGroup,
                e.resource
              )
            } else {
              baseActionBundle.entry?.push(e)
            }

            break
          }

          default:
            break
        }
      })
    }
  } else {
    baseActionBundle.entry = incomingActionBundle.entry
  }
}
function mergeActionEntryCarePlanEntity(
  baseCarePlanEntity: R4.ICarePlan,
  inputCarePlan: R4.ICarePlan
) {
  if (baseCarePlanEntity.contained && baseCarePlanEntity.contained.length > 0) {
    if (inputCarePlan.contained && inputCarePlan.contained.length > 0) {
      inputCarePlan.contained.forEach((eachRes) => {
        const res: R4.IBundle_Entry = eachRes as R4.IBundle_Entry
        switch (res.resource?.resourceType) {
          case 'Goal': {
            const indexOfRes = (
              baseCarePlanEntity.contained as R4.IBundle_Entry[]
            ).findIndex((eachReq) => {
              if (eachReq.resource?.resourceType === 'Goal') {
                return (
                  (eachReq.resource?.description?.text ?? '').length <
                  ((res.resource as R4.IGoal).description?.text ?? '').length
                )
              }
              return false
            })

            if (indexOfRes > -1) {
              const indexOfGoal = baseCarePlanEntity.contained?.findIndex(
                (e) => (e as R4.IBundle_Entry).resource?.resourceType === 'Goal'
              )

              ;(baseCarePlanEntity.contained![
                indexOfGoal!
              ] as R4.IBundle_Entry) = res
            }
            break
          }

          default:
            break
        }
      })
    }
  } else {
    baseCarePlanEntity.contained = inputCarePlan.contained
  }
}

function mergeActionEntryRequestGroupEntity(
  baseCarePlanEntity: R4.IRequestGroup,
  inputCarePlan: R4.IRequestGroup
) {
  if (baseCarePlanEntity.contained && baseCarePlanEntity.contained.length > 0) {
    if (inputCarePlan.contained && inputCarePlan.contained.length > 0) {
      inputCarePlan.contained.forEach((eachRes) => {
        const res: R4.IBundle_Entry = eachRes as R4.IBundle_Entry
        switch (res.resource?.resourceType) {
          case 'MedicationRequest': {
            const indexOfRes = (
              baseCarePlanEntity.contained as R4.IBundle_Entry[]
            ).findIndex((eachReq) => {
              if (eachReq.resource?.resourceType === 'MedicationRequest') {
                return (
                  getDisplayOfSystemFromCodableConcept(
                    eachReq.resource?.medicationCodeableConcept
                  ) ===
                  getDisplayOfSystemFromCodableConcept(
                    (res.resource as R4.IMedicationRequest)
                      .medicationCodeableConcept
                  )
                )
              }
              return false
            })

            if (indexOfRes === undefined || indexOfRes < 0) {
              ;(baseCarePlanEntity.contained as R4.IBundle_Entry[]).push(res)
            }
            break
          }
          case 'Appointment': {
            const indexOfRes = (
              baseCarePlanEntity.contained as R4.IBundle_Entry[]
            ).findIndex((eachReq) => {
              if (eachReq.resource?.resourceType === 'Appointment') {
                return (
                  getDefaultCodeOfSystemFromCodableConceptList(
                    eachReq.resource?.specialty ?? []
                  ) ===
                  getDefaultCodeOfSystemFromCodableConceptList(
                    (res.resource as R4.IAppointment).specialty ?? []
                  )
                )
              }
              return false
            })

            if (indexOfRes === undefined || indexOfRes < 0) {
              ;(baseCarePlanEntity.contained as R4.IBundle_Entry[]).push(res)
            }
            break
          }
          case 'PlanDefinition': {
            const indexOfRes = (
              baseCarePlanEntity.contained as R4.IBundle_Entry[]
            ).findIndex((eachReq) => {
              if (eachReq.resource?.resourceType === 'PlanDefinition') {
                return (
                  eachReq.resource?.identifier?.[0].value ===
                  (res.resource as R4.IPlanDefinition).identifier?.[0].value
                )
              }
              return false
            })
            if (indexOfRes === undefined || indexOfRes < 0) {
              ;(baseCarePlanEntity.contained as R4.IBundle_Entry[]).push(res)
            }
            break
          }

          default:
            ;(baseCarePlanEntity.contained as R4.IBundle_Entry[]).push(res)
            break
        }
      })
    }
  } else {
    baseCarePlanEntity.contained = inputCarePlan.contained
  }
}

function mergeActionEntryPlanDefinitionEntity(
  baseCarePlanEntity: R4.IPlanDefinition,
  inputCarePlan: R4.IPlanDefinition
) {}

export function getCommunicationResourceForFollowUp(
  appointmentDetails: FhirAppointmentDetail,
  payLoad: string,
  encounterId?: string
): R4.ICommunicationRequest {
  const output: R4.ICommunicationRequest = {
    resourceType: 'CommunicationRequest',
    payload: [
      {
        contentString: payLoad,
      },
    ],
    subject: {
      reference: `${
        appointmentDetails.patient.resourceType
      }/${appointmentDetails.patient.id!}`,
    },
    encounter: encounterId
      ? {
          reference: `Encounter/${encounterId}`,
        }
      : undefined,
    category: [
      {
        coding: [
          {
            code: 'instruction',
            display: 'Instruction',
            system: 'http://hl7.org/fhir/ValueSet/communication-category',
          },
        ],
        text: 'Instruction',
      },
    ],

    sender: {
      reference: `${
        appointmentDetails.practitionerDetail.practitionerRole.resourceType
      }/${appointmentDetails.practitionerDetail.practitionerRole.id!}`,
    },
    requester: {
      reference: `${
        appointmentDetails.practitionerDetail.practitionerRole.resourceType
      }/${appointmentDetails.practitionerDetail.practitionerRole.id!}`,
    },
  }

  return output
}

export function getIdOfCardEntry(cardEntry: CardsEntity): string | undefined {
  if (cardEntry.suggestions && cardEntry.suggestions.length > 0) {
    for (let index = 0; index < cardEntry.suggestions.length; index++) {
      const element = cardEntry.suggestions[index]
      if (element.actions && element.actions.length > 0) {
        for (let j = 0; j < element.actions?.length; j++) {
          const action = element.actions[j]
          const carePlans = getCarePlans(
            (action.resource as R4.IBundle).entry ?? []
          )

          let identifier: string | undefined
          for (let l = 0; l < carePlans.length; l++) {
            const cp = carePlans[l]

            identifier = getIdentifierValueBySystem(
              cp.identifier ?? [],
              'http://wellopathy.com/care-paln-definition-id'
            )
            if (identifier) {
              return identifier
            }
          }
        }
      }
    }
  }
  return undefined
}

export async function hasPlanOfferedInOrg(planUrl: string) {
  console.log('-----Plan def url.........', planUrl)
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    name: planUrl,
    status: 'active',
  }
  const response: any = await fhirClient.doGetResource(
    '/PlanDefinition',
    searchParameters
  )

  const bundle: R4.IBundle = response as R4.IBundle

  if (bundle.entry && bundle.entry.length > 0) {
    return true
  }

  return false
}

export function getCommunicationResourceForFollowUpForIPD(
  appointmentDetails: FhirActiveIPDDetailsForMedicalRole,
  payLoad: string,
  encounterId?: string
): R4.ICommunicationRequest {
  const output: R4.ICommunicationRequest = {
    resourceType: 'CommunicationRequest',
    payload: [
      {
        contentString: payLoad,
      },
    ],
    subject: {
      reference: `${
        appointmentDetails.patient.resourceType
      }/${appointmentDetails.patient.id!}`,
    },
    encounter: encounterId
      ? {
          reference: `Encounter/${encounterId}`,
        }
      : undefined,
    category: [
      {
        coding: [
          {
            code: 'instruction',
            display: 'Instruction',
            system: 'http://hl7.org/fhir/ValueSet/communication-category',
          },
        ],
        text: 'Instruction',
      },
    ],

    sender: {
      reference: `${
        appointmentDetails.practitionerDetail.practitionerRole.resourceType
      }/${appointmentDetails.practitionerDetail.practitionerRole.id!}`,
    },
    requester: {
      reference: `${
        appointmentDetails.practitionerDetail.practitionerRole.resourceType
      }/${appointmentDetails.practitionerDetail.practitionerRole.id!}`,
    },
  }

  return output
}

export function getSpecificTypeResourcesFromBundleEntries(
  entries: R4.IBundle_Entry[],
  resourceType: string
): R4.IBundle_Entry[] {
  return _.filter(entries, (e) => e.resource!.resourceType === resourceType)
}

export function getAppointmentsFromEntries(
  entries: R4.IBundle_Entry[]
): R4.IAppointment[] {
  const appointments: R4.IAppointment[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'Appointment'
  ).map((e) => e.resource as R4.IAppointment)

  return appointments
}

export function getRecommendedPlans(
  entries: R4.IBundle_Entry[]
): R4.IPlanDefinition[] {
  const appointments: R4.IPlanDefinition[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'PlanDefinition'
  ).map((e) => e.resource as R4.IPlanDefinition)

  return appointments
}

export function getDietTasksFromEntries(
  entries: R4.IBundle_Entry[]
): R4.ITask[] {
  console.log('---------Diet bundle enties ----------', entries)
  const appointments: R4.ITask[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'Task'
  )
    .map((e) => e.resource as R4.ITask)
    .filter((e) => {
      if (e.code) {
        const code = getDefaultCodeOfSystemFromCodableConcept(e.code)

        return code === 'C0012159'
      }
      return false
    })

  console.log('---------Diet tasks ----------', appointments)

  return appointments
}

export function getGoalFromEntries(entries: R4.IBundle_Entry[]): R4.IGoal[] {
  const carePlans: R4.ICarePlan[] = getCarePlans(entries)

  let appointments: R4.IGoal[] = []

  carePlans.forEach((element) => {
    appointments = [...appointments, ...getGoalsFromCarePlan(element)]
  })

  return appointments
}

export function getTestsToBePerformed(
  entries: R4.IBundle_Entry[],
  type?: string
): R4.IPlanDefinition[] {
  const appointments: R4.IRequestGroup[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'RequestGroup'
  ).map((e) => e.resource as R4.IRequestGroup)

  let tests: R4.IPlanDefinition[] = []

  appointments.forEach((element) => {
    tests = [...tests, ...getTestsFromRequestGroup(element)]
  })
  if (type && type.length > 0) {
    tests = [...tests].filter((e) => isSameType(e, type))
  }

  return tests
}

export function getSuggestedConditionFromEntries(
  entries: R4.IBundle_Entry[]
): R4.ICondition[] {
  const appointments: R4.ICondition[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'Condition'
  ).map((e) => e.resource as R4.ICondition)

  return appointments
}

export function getTestsFromRequestGroup(requestGroup: R4.IRequestGroup) {
  const tests: R4.IPlanDefinition[] = _.filter(
    requestGroup.contained,
    (e) => (e as any).resource.resourceType === 'PlanDefinition'
  ).map((e) => (e as any).resource as R4.IPlanDefinition)

  return tests
}

export function getAppointments(
  entries: R4.IBundle_Entry[]
): R4.IAppointment[] {
  const requestGroups: R4.IRequestGroup[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'RequestGroup'
  ).map((e) => e.resource as R4.IRequestGroup)

  let appointments: R4.IAppointment[] = []

  requestGroups.forEach((element) => {
    appointments = [...appointments, ...getAppointmentFromRequestGroup(element)]
  })

  return appointments
}

export function getInstructionsFromRequestGroup(
  requestGroup: R4.IRequestGroup
): R4.ICommunicationRequest[] {
  const tests: R4.ICommunicationRequest[] = _.filter(
    requestGroup.contained,
    (e) => (e as any).resource.resourceType === 'CommunicationRequest'
  ).map((e) => (e as any).resource as R4.ICommunicationRequest)

  return tests
}

export function getAppointmentFromRequestGroup(
  requestGroup: R4.IRequestGroup
): R4.IAppointment[] {
  const tests: R4.IAppointment[] = _.filter(
    requestGroup.contained,
    (e) => (e as any).resource.resourceType === 'Appointment'
  ).map((e) => (e as any).resource as R4.IAppointment)

  return tests
}

export function getMedicationRequestFromRequestGroup(
  requestGroup: R4.IRequestGroup
): R4.IMedicationRequest[] {
  const tests: R4.IMedicationRequest[] = _.filter(
    requestGroup.contained,
    (e) => (e as any).resource.resourceType === 'MedicationRequest'
  ).map((e) => (e as any).resource as R4.IMedicationRequest)

  return tests
}

export function getServiceRequestFromRequestGroup(
  requestGroup: R4.IRequestGroup
): R4.IServiceRequest[] {
  const tests: R4.IServiceRequest[] = _.filter(
    requestGroup.contained,
    (e) => (e as any).resource.resourceType === 'ServiceRequest'
  ).map((e) => (e as any).resource as R4.IServiceRequest)

  return tests
}
export function getMedications(
  entries: R4.IBundle_Entry[]
): R4.IMedicationRequest[] {
  const requestGroups: R4.IRequestGroup[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'RequestGroup'
  ).map((e) => e.resource as R4.IRequestGroup)

  let appointments: R4.IMedicationRequest[] = []

  requestGroups.forEach((element) => {
    appointments = [
      ...appointments,
      ...getMedicationRequestFromRequestGroup(element),
    ]
  })

  return appointments
}

export function getKriyas(entries: R4.IBundle_Entry[]): R4.IServiceRequest[] {
  console.log('---------Kriyas bundle enties ----------', entries)
  const careplans: R4.ICarePlan[] = getCarePlans(entries)
  let appointments: R4.IServiceRequest[] = []

  careplans.forEach((element) => {
    appointments = [...appointments, ...getKriyasFromCarePlan(element)]
  })

  const kriyas = appointments.filter((e) => {
    if (e.category) {
      const code = getCodeOfSystemFromCodableConceptList(
        e.category,
        'http://snomed.info/sct'
      )
      if (code) {
        return code.code === '452091000124101'
      }
    }
    return false
  })

  return kriyas
}

export interface CategorizedKriyas {
  kriyas: R4.IServiceRequest[]
  category: R4.ICodeableConcept
}

export function getCategorizedKriyas(kriyas: R4.IServiceRequest[]) {
  const categorizedKriyas: CategorizedKriyas[] = []

  kriyas.forEach((e) => {
    if (e.category && e.category.length > 0) {
      let currentCategory = e.category[0]
      if (e.category.length > 1) {
        const index = e.category.findIndex(
          (c) =>
            getDefaultCodeOfSystemFromCodableConcept(
              c,
              'http://snomed.info/sct'
            ) !== '452091000124101'
        )
        if (index > -1) {
          currentCategory = e.category[index]
        }
      }
      if (categorizedKriyas.length > 0) {
        const index = categorizedKriyas.findIndex((c) => {
          if (
            c.category &&
            getDefaultCodeOfSystemFromCodableConcept(c.category)
          ) {
            return (
              getDefaultCodeOfSystemFromCodableConcept(c.category) ===
              getDefaultCodeOfSystemFromCodableConcept(currentCategory)
            )
          }
          return false
        })
        if (index >= 0) {
          categorizedKriyas[index].kriyas.push(e)
        } else {
          categorizedKriyas.push({
            category: currentCategory,
            kriyas: [e],
          })
        }
      } else {
        categorizedKriyas.push({
          category: currentCategory,
          kriyas: [e],
        })
      }
    }
  })

  console.log('---------categorizedKriyas----------', categorizedKriyas)

  return categorizedKriyas
}

export function getKriyasOrderDetailsFromEntries(kriya: R4.IServiceRequest) {
  let orderDetails
  if (kriya.orderDetail && kriya.orderDetail.length > 0) {
    orderDetails = kriya.orderDetail
      .map((e) => getDefaultDisplayOfSystemFromCodableConcept(e))
      .join(', ')
  }
  return orderDetails
}

export function getInstructions(
  entries: R4.IBundle_Entry[]
): R4.ICommunicationRequest[] {
  const requestGroups: R4.IRequestGroup[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'RequestGroup'
  ).map((e) => e.resource as R4.IRequestGroup)

  let appointments: R4.ICommunicationRequest[] = []

  requestGroups.forEach((element) => {
    appointments = [
      ...appointments,
      ...getInstructionsFromRequestGroup(element),
    ]
  })

  return appointments
}

export function getAddressingConditionsFromCarePlan(
  carePlan: R4.ICarePlan
): R4.ICondition[] {
  const appointments: R4.ICondition[] = _.filter(
    carePlan.contained,
    (e) => (e as any).resource.resourceType === 'Condition'
  ).map((e) => (e as any).resource as R4.ICondition)

  return appointments
}

export function getKriyasFromCarePlan(
  carePlan: R4.ICarePlan
): R4.IServiceRequest[] {
  console.log('---------Kriyas cp----------', carePlan)
  const appointments: R4.IServiceRequest[] = _.filter(
    carePlan.activity ?? [],
    (e) => {
      if (e.detail) {
        if (e.detail.kind) {
          if (e.detail.kind === 'ServiceRequest') {
            return true
          }
        }
      }
      return false
    }
  ).map((e) => ({
    resourceType: 'ServiceRequest',
    ...e.detail,
    subject: carePlan.subject,
  }))

  console.log('---------Kriyas----------', appointments)

  return appointments.filter((e) => e !== undefined)
}

export function getGoalsFromCarePlan(carePlan: R4.ICarePlan): R4.IGoal[] {
  const appointments: R4.IGoal[] = _.filter(
    carePlan.contained,
    (e) => (e as any).resource.resourceType === 'Goal'
  ).map((e) => (e as any).resource as R4.IGoal)

  return appointments
}

export function getCarePlans(entries: R4.IBundle_Entry[]): R4.ICarePlan[] {
  const appointments: R4.ICarePlan[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'CarePlan'
  ).map((e) => (e as any).resource as R4.ICarePlan)

  return appointments
}

export function getCarePlanDescriptions(entries: R4.IBundle_Entry[]): string {
  const appointments: R4.ICarePlan[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'CarePlan'
  ).map((e) => (e as any).resource as R4.ICarePlan)
  let deascriptions: string = ''
  appointments.forEach((e) => {
    if (e.description) deascriptions += e.description
  })

  return deascriptions
}

export function getAddressingConditions(
  entries: R4.IBundle_Entry[]
): R4.ICondition[] {
  const careplans: R4.ICarePlan[] = getCarePlans(entries)
  let conditions: R4.ICondition[] = []

  careplans.forEach((element) => {
    conditions = [
      ...conditions,
      ...getAddressingConditionsFromCarePlan(element),
    ]
  })

  return conditions
}

export function getAddressingConditionCodes(
  entries: R4.IBundle_Entry[]
): R4.ICoding[] {
  const conditions: R4.ICondition[] = getAddressingConditions(entries)

  const codings: R4.ICoding[] = conditions.map((e) => e.code?.coding![0]!) ?? []

  return codings
}

export function hasError(cardEntry: CardsEntity): boolean {
  if (
    cardEntry.extension &&
    cardEntry.extension.errors &&
    cardEntry.extension.errors.length > 0
  ) {
    if (
      cardEntry.extension.errors?.[0] &&
      cardEntry.extension.errors?.[0].length > 0
    ) {
      return true
    }
  }
  return false
}

export function getErrors(cardEntry: CardsEntity): string[] | undefined {
  if (
    cardEntry.extension &&
    cardEntry.extension.errors &&
    cardEntry.extension.errors.length > 0
  ) {
    return cardEntry.extension.errors
  }
  return undefined
}

export function isEssential(resource: any): boolean {
  return isSameType(resource, 'essential')
}

export function isSameType(resource: any, type: string): boolean {
  const res: R4.IResource = resource as R4.IResource
  if (res.meta && res.meta.tag) {
    const ind = res.meta.tag.findIndex(
      (e) =>
        e.system ===
        'http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-hypertension-cc-type'
    )
    if (ind >= 0) {
      return res.meta.tag[ind].code === type
    }
  }
  return false
}

export function getLoincCodeFromPlanDef(
  planDef: R4.IPlanDefinition
): string | undefined {
  if (planDef.identifier) {
    const index = planDef.identifier.findIndex(
      (e) => e.system === 'http://loinc.org'
    )
    if (index >= 0) {
      return planDef.identifier[index].value
    }
  }
  return undefined
}

export function getTestsOfTypeInCard(
  cardEntry: CardsEntity,
  type?: string
): R4.IPlanDefinition[] {
  let tests: R4.IPlanDefinition[] = []

  if (cardEntry.suggestions) {
    cardEntry.suggestions?.forEach((item) => {
      if (item.actions) {
        item.actions.forEach((action) => {
          tests = [
            ...tests,
            ...getTestsToBePerformed(
              (action.resource as R4.IBundle).entry ?? [],
              type
            ),
          ]
        })
      }
    })
  }

  return tests
}

export function hasLabTestsOfType(
  combined: R4.IPlanDefinition[],
  system: string,
  code: string
) {
  if (combined.length > 0) {
    return combined.some((e) => {
      if (e.type) {
        const type = getCodeOfSystemFromCodableConcept(e.type, system)

        return type?.code === code
      }
      return false
    })
  }
  return false
}

export function getLabTestsOfType(
  input: R4.IPlanDefinition[],
  system: string,
  code: string
) {
  const combined = [...input]
  if (combined.length > 0) {
    return combined
      .filter((e) => {
        if (e.type) {
          const type = getCodeOfSystemFromCodableConcept(e.type, system)
          return type?.code === code
        }
        return false
      })
      .sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0)
  }
  return undefined
}

export function getMedicationOfTypeInCard(
  cardEntry: CardsEntity
): R4.IMedicationRequest[] {
  let tests: R4.IMedicationRequest[] = []

  if (cardEntry.suggestions) {
    cardEntry.suggestions?.forEach((item) => {
      if (item.actions) {
        item.actions.forEach((action) => {
          tests = [
            ...tests,
            ...getMedications((action.resource as R4.IBundle).entry ?? []),
          ]
        })
      }
    })
  }

  return tests
}

export function getJustificationOfPlanDef(
  planDef: R4.IPlanDefinition
): string | undefined {
  if (planDef.relatedArtifact && planDef.relatedArtifact?.length > 0) {
    const index = planDef.relatedArtifact.findIndex(
      (e) => e.type === R4.RelatedArtifactTypeKind._justification
    )
    if (index >= 0) {
      return planDef.relatedArtifact[index].display
    }
  }
  return ''
}

export function getServiceRequests(
  entries: R4.IBundle_Entry[]
): R4.ICarePlan_Detail[] {
  const appointments: R4.ICarePlan[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'CarePlan'
  ).map((e) => (e as any).resource as R4.ICarePlan)
  const serviceRequests: R4.ICarePlan_Detail[] = []
  appointments.forEach((e) => {
    if (e.activity && e.activity.length > 0) {
      e.activity.forEach((j) => {
        if (j.detail) {
          if (j.detail.kind === 'ServiceRequest') {
            serviceRequests.push(j.detail)
          }
        }
      })
    }
  })

  return serviceRequests
}

export function getCarePlanActivities(
  entries: R4.IBundle_Entry[]
): R4.ICarePlan_Detail[] {
  const appointments: R4.ICarePlan[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'CarePlan'
  ).map((e) => (e as any).resource as R4.ICarePlan)
  const serviceRequests: R4.ICarePlan_Detail[] = []
  appointments.forEach((e) => {
    if (e.activity && e.activity.length > 0) {
      e.activity.forEach((j) => {
        if (j.detail) {
          serviceRequests.push(j.detail)
        }
      })
    }
  })
  console.log(serviceRequests)
  return serviceRequests
}

export function getRepeatedAppointments(
  entries: R4.IBundle_Entry[]
): R4.ICarePlan_Detail[] {
  const appointments: R4.ICarePlan[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'CarePlan'
  ).map((e) => (e as any).resource as R4.ICarePlan)
  const serviceRequests: R4.ICarePlan_Detail[] = []
  appointments.forEach((e) => {
    if (e.activity && e.activity.length > 0) {
      e.activity.forEach((j) => {
        if (j.detail) {
          if (j.detail.kind === 'Appointment') {
            serviceRequests.push(j.detail)
          }
        }
      })
    }
  })

  return serviceRequests
}

export function getKriyaNameFromKriyaDetail(kriyaDetails: KriyaDetail) {
  if (kriyaDetails.rawKriyaDetail) {
    if (kriyaDetails.rawKriyaDetail.code) {
      const kriyaName = getDefaultDisplayOfSystemFromCodableConcept(
        kriyaDetails.rawKriyaDetail.code
      )
      return kriyaName
    }
  }

  const kriyaName = getDefaultDisplayOfSystemFromCodableConcept(
    kriyaDetails.kriya
  )
  return kriyaName
}

export function getKriyaNameFormServiceRequest(sv: R4.IServiceRequest) {
  if (sv.code) {
    return getDefaultDisplayOfSystemFromCodableConcept(sv.code)
  }
  return undefined
}

export function getSectionsInCDSCards(cards: CardsEntity) {
  const res = []

  console.log('---------cards----------', cards)
  console.log('---------cards----------', cards.interceptedSuggestions)

  if (cards.interceptedSuggestions && cards.interceptedSuggestions.size > 0) {
    console.log(
      '---------cards inside res----------',
      cards.interceptedSuggestions
    )
    if (cards.interceptedSuggestions.has('goals')) {
      res.push({
        name: 'goals',
        label: 'Goal',
      })
    }

    if (cards.interceptedSuggestions.has('conditions')) {
      res.push({
        name: 'conditions',
        label: 'Conditions',
      })
    }

    if (cards.interceptedSuggestions.has('specialistReferral')) {
      res.push({
        name: 'specialistReferral',
        label: 'Specialist Referral',
      })
    }

    if (cards.interceptedSuggestions.has('labTests')) {
      res.push({
        name: 'labTests',
        label: 'Lab Tests',
      })
    }

    if (cards.interceptedSuggestions.has('medications')) {
      res.push({
        name: 'medications',
        label: 'Medications',
      })
    }

    if (cards.interceptedSuggestions.has('dietPlan')) {
      res.push({
        name: 'dietPlan',
        label: 'Diet Plan',
      })
    }

    if (cards.interceptedSuggestions.has('kriyas')) {
      res.push({
        name: 'kriyas',
        label: 'Kriyas',
      })
    }

    if (cards.interceptedSuggestions.has('careTeamFollowUps')) {
      res.push({
        name: 'careTeamFollowUps',
        label: 'Care Team Follow Ups',
      })
    }

    if (cards.interceptedSuggestions.has('instructions')) {
      res.push({
        name: 'instructions',
        label: 'Instructions',
      })
    }

    if (cards.interceptedSuggestions.has('carePlanActivities')) {
      res.push({
        name: 'carePlanActivities',
        label: 'CarePlan Activities',
      })
    }
  }
  if (cards.detail && cards.detail.length > 0) {
    res.push({
      name: 'summary',
      label: 'Summary',
    })
  }

  console.log('---------sections----------', res)
  return res
}

export function getDietPlans(entries: R4.IBundle_Entry[]): R4.ITask[] {
  console.log('---------Diet bundle enties ----------', entries)
  const careplans: R4.ICarePlan[] = getCarePlans(entries)
  let appointments: R4.ITask[] = []

  careplans.forEach((element) => {
    appointments = [...appointments, ...getTasksCarePlan(element)]
  })

  const kriyas = appointments.filter((e) => {
    if (e.code) {
      const code = getDefaultCodeOfSystemFromCodableConcept(e.code)
      if (code) {
        return code === 'C0012159'
      }
    }
    return false
  })
  console.log('---------Diet----------', kriyas)

  return kriyas
}

export function getTasksCarePlan(carePlan: R4.ICarePlan): R4.ITask[] {
  const appointments: R4.ITask[] = _.filter(
    carePlan.contained,
    (e) => (e as any).resource.resourceType === 'Task'
  ).map((e) => (e as any).resource as R4.ITask)

  return appointments.filter((e) => e !== undefined)
}

export function deDuplicateMedicationRequests(
  medicationRequests: R4.IMedicationRequest[]
): R4.IMedicationRequest[] {
  const originalVales = medicationRequests ?? []
  const uniqueValues: R4.IMedicationRequest[] = []
  _.uniqBy(originalVales, (e) => e.medicationCodeableConcept?.coding?.[0].code)
    .sort((a, b) =>
      (
        getDefaultDisplayOfSystemFromCodableConcept(
          a.medicationCodeableConcept
        ) ?? ''
      ).localeCompare(
        getDefaultDisplayOfSystemFromCodableConcept(
          b.medicationCodeableConcept
        ) ?? ''
      )
    )
    .forEach((element) => {
      uniqueValues.push(element)
    })

  return uniqueValues
}

export function deDuplicateGoals(goals: R4.IGoal[]): R4.IGoal[] {
  const originalVales = goals ?? []
  const uniqueValues: R4.IGoal[] = []
  _.uniqBy(originalVales, (e) => e.description?.text)
    .sort((a, b) =>
      (a.description?.text ?? '').localeCompare(b.description?.text ?? '')
    )
    .forEach((element) => {
      uniqueValues.push(element)
    })

  return uniqueValues
}

export function deDuplicateConditions(
  conditions: R4.ICondition[]
): R4.ICondition[] {
  const originalVales = conditions ?? []
  const uniqueValues: R4.ICondition[] = []
  _.uniqBy(originalVales, (e) => e.code?.coding?.[0].code)
    .sort((a, b) =>
      (a.code?.coding?.[0].display ?? '').localeCompare(
        b.code?.coding?.[0].display ?? ''
      )
    )
    .forEach((element) => {
      uniqueValues.push(element)
    })

  return uniqueValues
}

export function deDuplicateLabTests(
  labTests: R4.IPlanDefinition[]
): R4.IPlanDefinition[] {
  const originalVales = labTests ?? []
  const uniqueValues: R4.IPlanDefinition[] = []
  _.uniqBy(originalVales, (e) => e.identifier?.[0].value).forEach((element) => {
    uniqueValues.push(element)
  })

  return uniqueValues
}

export function deDuplicateKriyas(
  kriyas: R4.IServiceRequest[]
): R4.IServiceRequest[] {
  const originalVales = kriyas ?? []
  const uniqueValues: R4.IServiceRequest[] = []
  _.uniqBy(originalVales, (e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)
  )
    .sort((a, b) =>
      (getDefaultDisplayOfSystemFromCodableConcept(a.code) ?? '').localeCompare(
        getDefaultDisplayOfSystemFromCodableConcept(b.code) ?? ''
      )
    )
    .forEach((element) => {
      uniqueValues.push(element)
    })

  return uniqueValues
}

export function deDuplicateCareTeamFollowUps(
  careTeamFollowUps: string[]
): string[] {
  const originalVales = careTeamFollowUps ?? []
  const uniqueValues: string[] = []

  originalVales.forEach((element) => {
    console.log('---------element----------', element)
    if (!uniqueValues.includes(element.trim())) {
      uniqueValues.push(element.trim())
    }
  })

  const restValue = _.uniqBy(uniqueValues, (e) => e)

  console.log(
    '---------uniqueValues----------',
    _.uniqBy(uniqueValues, (e) => e)
  )

  console.log('---------restValue----------', restValue)

  return restValue
}

export function deDuplicateInstructions(
  instructions: R4.ICommunicationRequest[]
): R4.ICommunicationRequest[] {
  const originalVales = instructions ?? []
  const uniqueValues: R4.ICommunicationRequest[] = []
  _.uniqBy(originalVales, (e) => e.payload?.[0].contentString)
    .sort((a, b) =>
      (a.payload?.[0].contentString ?? '').localeCompare(
        b.payload?.[0].contentString ?? ''
      )
    )
    .forEach((element) => {
      uniqueValues.push(element)
    })

  return uniqueValues
}

export function deDuplicateCarePlanActivities(
  carePlanActivities: R4.ICarePlan_Detail[]
): R4.ICarePlan_Detail[] {
  const originalVales = carePlanActivities ?? []
  const uniqueValues: R4.ICarePlan_Detail[] = []
  _.uniqBy(originalVales, (e) => e.kind).forEach((element) => {
    uniqueValues.push(element)
  })

  return uniqueValues
}
