import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateWiseGraphData } from 'models/adminGraph/dateWiseGraphData'
import { GraphType } from 'models/adminGraph/graphType'
import { ServiceDataForGraph } from 'models/adminGraph/serviceDataForGraph'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import { getDateWiseDietPlansFromBundle } from 'utils/fhirResoureHelpers/ipdDietHelper'
import {
  getAmountTotal,
  getDataForDateWiseGraph,
  getDataForServiceRequest,
  getDataForTypeWise,
} from 'utils/fhirResoureHelpers/orgAdminGraphHelper'
import { logger } from 'utils/logger'
import { ObjectivePartOfConsultation } from 'views/components/ipdconusltation/objectiveDetailsOfConsultation'
import { ServiceWiseGraph } from './serviceWiseGraphStatus'

const initialState: ServiceWiseGraph = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
}

interface IPD {
  name: string
  amount: number
  percentage: number
}

const serviceWiseCollectionSlice = createSlice({
  name: 'serviceWiseCollectionSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<ServiceWiseGraph>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorReason = action.payload.errorReason
      state.graphType = action.payload.graphType
      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const requestForServiceTypeCollectionGraph =
  (date: Date, endDate?: Date, type?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: ServiceWiseGraph = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(serviceWiseCollectionSlice.actions.updatedStatus(state))
    try {
      const fhirClient: MasterFhirClient = new MasterFhirClient()

      const endDateData =
        type && endDate
          ? moment(endDate).endOf('day').utcOffset('+05:30').toISOString()
          : moment(endDate || date)
              .endOf('month')
              .utcOffset('+05:30')
              .toISOString()

      const searchParameters: any = {
        _count: 1000,
        status: 'completed',
        occurrence: `ge${moment(date)
          .startOf(type ? 'day' : 'month')
          .utcOffset('+05:30')
          .toISOString()}`,
      }
      const response: any =
        await fhirClient.doGetResourceIncludeAndIncludeIterate(
          `/ServiceRequest?code=281685003,33022008,304903009&occurrence=le${endDateData}&_sort=occurrence`,
          searchParameters
        )

      const resp: R4.IBundle = response as R4.IBundle
      if (!resp) {
        state.errorWhileSearchingAppointments = true
        state.searchingAppointments = false

        dispatch(serviceWiseCollectionSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp
        // const resultData1: GraphType[] = getDataForTypeWise(
        //   appointmentResponse,
        //   date
        // )
        const finalData: GraphType[] = []
        const serviceData: ServiceDataForGraph = getDataForServiceRequest(
          appointmentResponse,
          date
        )

        let ipdAmount: number = 0

        if (serviceData.ipdData.length > 120) {
          const threePartIndex = Math.ceil(serviceData.ipdData.length / 3)

          const thirdPart = serviceData.ipdData.splice(-threePartIndex)
          const secondPart = serviceData.ipdData.splice(-threePartIndex)
          const firstPart = serviceData.ipdData

          const ipdAmount1 = await getTotalAmount(thirdPart)
          const ipdAmount2 = await getTotalAmount(secondPart)
          const ipdAmount3 = await getTotalAmount(firstPart)
          ipdAmount = ipdAmount1 + ipdAmount2 + ipdAmount3
        } else {
          ipdAmount = await getTotalAmount(serviceData.ipdData)
        }

        const dayCareAmount: number = await getTotalAmount(
          serviceData.daycareData
        )

        const opdAmount: number = await getTotalAmount(serviceData.opd)
        const totalValue: number = ipdAmount + dayCareAmount + opdAmount

        if (ipdAmount > 0) {
          const ipdData: IPD = {
            name: 'IPD',
            amount: ipdAmount,
            percentage: Math.round((ipdAmount / totalValue) * 100),
          }
          finalData.push(ipdData)
        }

        if (dayCareAmount > 0) {
          const dayCareData: IPD = {
            name: 'Day Care',
            amount: dayCareAmount,
            percentage: Math.round((dayCareAmount / totalValue) * 100),
          }
          finalData.push(dayCareData)
        }

        if (opdAmount > 0) {
          const opdData: IPD = {
            name: 'OPD',
            amount: opdAmount,
            percentage: Math.round((opdAmount / totalValue) * 100),
          }
          finalData.push(opdData)
        }

        if (finalData.length > 0) {
          state.resultsAvailable = true
          state.searchingAppointments = false
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.graphType = finalData

          state.errorWhileSearchingAppointments = false
          dispatch(serviceWiseCollectionSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: ServiceWiseGraph = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            serviceWiseCollectionSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: ServiceWiseGraph = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
      }
      dispatch(
        serviceWiseCollectionSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export async function getTotalAmount(serviceData: string[]): Promise<number> {
  let totalAmount: number = 0
  if (serviceData.length > 0) {
    const str: string = String(serviceData)

    const fhirClient: FHIRApiClient = new FHIRApiClient()

    const searchParameters: any = {
      _count: 500,
      outcome: 'complete',
    }
    const response: any = await fhirClient.doGetResource(
      `/PaymentReconciliation?identifier=${str}`,
      searchParameters
    )

    const responseBundle: R4.IBundle = response as R4.IBundle

    totalAmount = getAmountTotal(responseBundle)
  }

  return totalAmount
}

export default serviceWiseCollectionSlice.reducer
