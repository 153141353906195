/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { ObservationStatusKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  TextField,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { UmlResponse } from 'models/umlResponse'
import { WelloSubjective } from 'models/WelloConditions'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showSuccessAlert, showErrorAlert } from 'redux/alertHandler/alertSlice'
import {
  addAllergiesDetails,
  resetAddAllergiesState,
} from 'redux/fhirMedicalResources/ipd/addAllergy/addAllergySliceIPD'

import {
  addDietOPD,
  createBundleObjectForObservationsForDiet,
  resetAddDiet,
} from 'redux/fhirMedicalResources/opd/diet/dietAddSlice'
import { RootState } from 'redux/rootReducer'
import {
  addictions,
  alcoholCount,
  breathe,
  dietType,
  drugCount,
  energyLevels,
  excersiseCode,
  intensity,
  listOfHabbits,
  sleepPattern,
  smokeCounts,
  teaCount,
  tobacoCount,
} from 'utils/constants'
import {
  updateAddictions,
  updateObsForEdit,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import { createBundleObjectForObservations } from 'utils/fhirResoureHelpers/observationHelpers'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import {
  RadioRaisedButton,
  WelloFormItemLabel,
  WelloLoadingIndicator,
} from 'wello-web-components'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  onClose: () => void
  onDietAdd: () => void
  existingDiet: WelloSubjective
}
export const EditDietOPD: React.FC<Props> = ({
  open,
  onClose,
  onDietAdd,
  fhirAppointmentDetails,
  existingDiet,
}) => {
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '30%',
    },
  }))
  const classes = useStyles()
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const [diet, setDiet] = useState<R4.ICoding>()
  const [additionalNotes, setAdditionalNotes] = useState<string>()
  const dispatch = useDispatch()

  const dietAddSlice = useSelector((state: RootState) => state.dietAddSliceOPD)
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [currentCondition, setCurrentCondition] = useState<WelloSubjective>({
    id: existingDiet.id,
    condition: existingDiet.condition,
    existingObs: existingDiet.existingObs,
    notes: existingDiet.notes,
  })

  function updateObsFinal() {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.IObservation = {
      ...currentCondition.existingObs!,
    }

    const newObs: R4.IBundle = createBundleObjectForObservationsForDiet(
      fhirAppointmentDetails,
      currentCondition.notes!,
      currentCondition.condition!
    )
    setLoading(true)
    oldProcedure.status = ObservationStatusKind._enteredInError
    newObs.entry?.push({
      resource: oldProcedure,
      fullUrl: `${oldProcedure.resourceType}/${oldProcedure.id}`,
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `${oldProcedure.resourceType}/${oldProcedure.id}`,
      },
    })

    updateAddictions(newObs, fhirAppointmentDetails.appointment.id!).then(
      (e) => {
        if (e) {
          setLoading(false)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(showSuccessAlert('Diet details updated successfully'))
          onDietAdd()
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while updating Diet details. Please try again later'
            )
          )
        }
      }
    )
  }

  useEffect(() => {
    setCurrentCondition({
      id: existingDiet.id,
      condition: existingDiet.condition,
      existingObs: existingDiet.existingObs,
      notes: existingDiet.notes,
    })
  }, [existingDiet])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setDiet(undefined)
        setAdditionalNotes('')
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Edit Diet</DialogTitle>
      <DialogContent dividers={true}>
        {loading && <WelloLoadingIndicator message='Adding details' />}

        {!loading && (
          <Box marginX={2} marginY={1}>
            <Grid container direction='column' spacing={1}>
              <Grid item>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box display='flex' flexDirection='row' width='100%'>
                    <WelloSelectFHIRCoding
                      title='Diet'
                      availableCodings={dietType}
                      onChanged={(type) => {
                        setCurrentCondition({
                          ...currentCondition,
                          condition: type,
                        })
                      }}
                      id='diet_5'
                      textFieldProps={{
                        size: 'small',
                        fullWidth: true,
                      }}
                      preSelectedCoding={currentCondition.condition}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Grid container direction='column'>
                  <Grid item>
                    <WelloFormItemLabel title='Additional Notes' />
                  </Grid>
                  <Grid item style={{ paddingBottom: '16px' }}>
                    <TextField
                      size='small'
                      fullWidth
                      multiline
                      id='diet_6'
                      type='string'
                      value={currentCondition.notes}
                      variant='outlined'
                      onChange={(event) => {
                        setCurrentCondition({
                          ...currentCondition,
                          notes: event.target.value,
                        })
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box display='flex' flexDirection='row' paddingRight={4}>
          <Button
            onClick={() => {
              onClose()
              setDiet(undefined)
              setAdditionalNotes('')
            }}
            id='diet_7'
            disabled={loading}
            variant='outlined'
            disableElevation
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            onClick={() => {
              updateObsFinal()
            }}
            variant='contained'
            color='primary'
            id='diet_8'
            disableElevation
            disabled={loading}
          >
            Update
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
