import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitReference,
  isTherapist,
} from 'services/userDetailsService'
import { getGenderOfDoctor } from 'utils/fhirResourcesHelper'
import { getCountForTherapist } from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { logger } from 'utils/logger'
import { IpdCountStatus } from './ipdCountStatus'

const initialState: IpdCountStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
  count: 0,
}

const ipdDischargedCount = createSlice({
  name: 'ipdDischargedCount',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<IpdCountStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.count = action.payload.count
      state.errorReason = action.payload.errorReason

      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const requestDischargeCount =
  (type: string, selectedDate?: Date): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: IpdCountStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      count: 0,
    }
    dispatch(ipdDischargedCount.actions.updatedStatus(state))
    const unitId = getCurrentUserUnitReference()?.reference?.split('/')[1]
    try {
      const enRolClient: EnrolCient = new EnrolCient()

      const date = `${moment
        .utc(new Date())
        .subtract(150, 'days')
        .local()
        .format('YYYY-MM-DD')}`
      const endDate = moment(date).add(150, 'days')
      const apiDate = `${moment.utc(endDate).local().format('YYYY-MM-DD')}`
      let response: any

      if (!isTherapist()) {
        const searchParams: any = {
          perPageCount: 0,
          status: 'completed',
          unitId,
          type:
            type === 'IPD'
              ? 'ipd'
              : type === 'OPD-DAYCARE'
              ? 'opd-daycare'
              : 'opd',
        }
        if (selectedDate) {
          const tempDate = `${moment(moment(selectedDate).format('YYYY-MM-DD'))
            .startOf('day')
            .utc()
            .format()}`
          searchParams.startDate = tempDate
        }
        response = await enRolClient.doGetResource(`ipd`, searchParams)
      } else {
        const startdate = `${moment
          .utc(new Date())

          .local()
          .format('YYYY-MM-DD')}`

        response = await getCountForTherapist(
          unitId!,
          startdate,
          type === 'IPD'
            ? 'ipd'
            : type === 'OPD-DAYCARE'
            ? 'opd-daycare'
            : 'opd',
          getGenderOfDoctor(getCurrentUserPractitionerDetails())
        )
      }

      const resp: R4.IBundle = response as R4.IBundle
      if (!resp) {
        state.errorWhileSearchingAppointments = true
        state.searchingAppointments = false

        dispatch(ipdDischargedCount.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          state.resultsAvailable = true
          state.searchingAppointments = false
          state.count = appointmentResponse.total
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingAppointments = false
          dispatch(ipdDischargedCount.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: IpdCountStatus = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            count: 0,
          }
          dispatch(ipdDischargedCount.actions.updatedStatus(errorSearchDoctor))
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: IpdCountStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
        count: 0,
      }
      dispatch(ipdDischargedCount.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default ipdDischargedCount.reducer
