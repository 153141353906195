import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { getExpandedAppointmentFromBundle } from 'utils/common/patientDataTableHelper'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import { UmlClient } from 'services/umlsClient'
import { getUserCurrentRole } from 'services/userDetailsService'
import { MensurationSearchStatus } from './mensurationSearchStatus'

const initialState: MensurationSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const mensurationSearchSlice = createSlice({
  name: 'mensuration',
  initialState,
  reducers: {
    searchingCondition(
      state,
      action: PayloadAction<MensurationSearchStatus>
    ) {},

    searchResults(state, action: PayloadAction<MensurationSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<MensurationSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
    },

    errorWhileSearching(state, action: PayloadAction<MensurationSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
    },
    resetState(state, action: PayloadAction<MensurationSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.surgeryList = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: MensurationSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      surgeryList: undefined,
    }
    dispatch(mensurationSearchSlice.actions.resetState(state))
  }

export const searchConditions =
  (searchString: string, type: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: MensurationSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      mensurationSearchSlice.actions.errorWhileSearching(errorSearchPatient)
    )
    try {
      const fhirClient: UmlClient = new UmlClient()
      const currentRole = getUserCurrentRole()
      const response: any = await fhirClient.doGetResource(
        `umls/ValueSetMatcher?Path=${type}&Match=${searchString}`
      )
      if (response.length > 0) {
        const searchPatientResult: MensurationSearchStatus = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          surgeryList: response,
          totalCount: response.total,
        }
        dispatch(
          mensurationSearchSlice.actions.searchResults(searchPatientResult)
        )
        return
      }

      const noSearchResults: MensurationSearchStatus = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(
        mensurationSearchSlice.actions.noDataFoundForSearch(noSearchResults)
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: MensurationSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        mensurationSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export const resetStateMensuration = () => (dispatch: AppDispatch) => {
  dispatch(mensurationSearchSlice.actions.resetState(initialState))
}

export default mensurationSearchSlice.reducer
