/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable consistent-return */
import { Box, Checkbox } from '@material-ui/core'
import { AnyARecord } from 'dns'
import {
  CohortChartProps,
  CohortControlsProps,
  CohortDataStage,
  MapDataProps,
} from 'models/chartProps'
import { SankeyData } from 'models/sankeyData'
import { SankeyLink } from 'models/sankeyLinks'
import { SankeyNode } from 'models/sankeyNode'
import React, { useEffect, useState } from 'react'
import { getFilterLabel } from 'utils/cohort/cohortUtils'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'

type PersonSliderProps = {
  label: string

  value: boolean
  onChange: any
}

export const CustomInclusiveFilter: React.FC<PersonSliderProps> = ({
  label,
  value,
  onChange,
}: PersonSliderProps) => {
  console.log('455')
  return (
    <Box>
      <Box display='flex' flexDirection='row'>
        <Box paddingTop={0.7}>
          <Checkbox
            edge='start'
            id='check'
            checked={value}
            onChange={onChange}
            tabIndex={-1}
            size='small'
            disableRipple
            color='primary'
            style={{ padding: 0 }}
            inputProps={{ 'aria-labelledby': 'consent' }}
          />
        </Box>
        <Box paddingLeft={1}>
          <WelloFormItemLabel title={label} />
        </Box>
      </Box>

      {/* <input
        type='checkbox'
        id='inclusive'
        name='inclusive'
        onChange={onChange}
        checked={value}
      /> */}
    </Box>
  )
}
