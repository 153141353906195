/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable consistent-return */
import { Box, Typography } from '@material-ui/core'
import React from 'react'

interface Props {
  message: string
  svg: boolean
}
export const ErrorMessage: React.FC<Props> = ({ message, svg }: Props) => (
  <Box>
    {svg && (
      <text x={0} y={24} fill='red'>
        {message}
      </text>
    )}
    <Typography variant='h1' color='error'>
      {message}
    </Typography>
  </Box>
)
