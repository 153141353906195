import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import React from 'react'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'

interface OccupationTileProps {
  type?: string
}

export const ProductHeaderTile: React.FC<OccupationTileProps> = ({
  type,
}: OccupationTileProps) => {
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Grid container direction='row' spacing={1}>
        {/* ------------------------------------------------------------------------------- */}

        <Box display='flex' flexDirection='column' width='100%' flexGrow={1}>
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <Grid item xs={4}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle1'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Unit
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle1'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      MRP
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle1'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      Discount (%)
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle1'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      Final Price
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle1'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      In Stock
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Box>
  )
}
