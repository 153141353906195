import {
  IPractitioner,
  IPractitionerRole,
  IReference,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  useTheme,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Radio,
  FormControlLabel,
  Box,
  RadioGroup,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { kPrimaryMain } from 'configs/styles/muiThemes'

import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { SubscriptionInterest } from 'models/subscriptionInterestDetails'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { acceptSubscription } from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import {
  getDisplayOfSystemFromCodableConcept,
  getFullNameFromHumanName,
} from 'utils/fhirResourcesHelper'
import {
  CareTeamMember,
  getCareTeamDetails,
} from 'utils/fhirResoureHelpers/referralHelpers'

export interface AddReferralPopUpProps {
  open: boolean
  onCancelActionClicked?: () => void
  onSuccessfulAction?: (
    referralNote: string,
    selectedDoctorPractRole: IPractitionerRole,
    selectedDoctorPract: IPractitioner
  ) => void
  id?: string
  carePlanId?: string
}

export const AddReferralPopUp: React.FC<AddReferralPopUpProps> = ({
  open,
  onCancelActionClicked,
  onSuccessfulAction,
  id,
  carePlanId,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [fetchAvailableDocsStatus, setFetchAvailableDocsStatus] =
    useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })

  const [availableDocs, setAvailableDocs] = useState<CareTeamMember[]>([])

  const [selectedReferralDoc, setSelectedReferralDoc] = useState<string>()

  function fetchAvailableDocs() {
    setFetchAvailableDocsStatus({ initial: false, requesting: true })
    getCareTeamDetails(carePlanId!)
      .then((response) => {
        if (response) {
          setAvailableDocs(response)
          setFetchAvailableDocsStatus({
            initial: false,
            requesting: false,
            requestSuccess: true,
          })
        }
      })
      .catch((error) => {
        setFetchAvailableDocsStatus({
          initial: false,
          requesting: false,
          requestError: true,
          errorDescription: error.message,
        })
      })
  }

  useEffect(() => {
    fetchAvailableDocs()
  }, [carePlanId])

  const [referralNote, setReferralNote] = useState<string>('')

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (onCancelActionClicked) onCancelActionClicked()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Add Referral</DialogTitle>
      <DialogContent dividers={true}>
        {fetchAvailableDocsStatus.requesting && (
          <Box display='flex' justifyContent='center' alignItems='center'>
            <CircularProgress />
          </Box>
        )}

        {fetchAvailableDocsStatus.requestError && (
          <Alert severity='error'>
            {fetchAvailableDocsStatus.errorDescription}
          </Alert>
        )}

        {fetchAvailableDocsStatus.requestSuccess && (
          <Box display='flex' flexDirection='column' width='100%' height='100%'>
            {availableDocs.length === 0 && (
              <Alert severity='info'>No available doctors for referral</Alert>
            )}
            {availableDocs.length > 0 && (
              <Box display='flex' flexDirection='column' width='100%'>
                <Typography variant='subtitle1' color='initial'>
                  Select Doctor
                </Typography>
                {/* react material list with radio button for selection */}
                <RadioGroup
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='controlled-radio-buttons-group'
                  value={selectedReferralDoc}
                  onChange={(e) => setSelectedReferralDoc(e.target.value)}
                >
                  <List>
                    {availableDocs.map((doc) => (
                      <ListItem key={doc.roleId}>
                        <FormControlLabel
                          control={<Radio />}
                          value={doc.roleId}
                          label={
                            <ListItemText
                              primary={getFullNameFromHumanName(
                                doc.practitioner.name ?? []
                              )}
                              secondary={
                                <Box display='flex' flexDirection='column'>
                                  <Typography variant='body2'>
                                    {`Care Team Role: ${doc.roleInCareTeam
                                      .map((e) =>
                                        getDisplayOfSystemFromCodableConcept(e)
                                      )
                                      .join(', ')}`}
                                  </Typography>

                                  <Typography variant='caption'>
                                    {`Specialty: ${(
                                      doc.practitionerRole.specialty ?? []
                                    )
                                      .map((e) =>
                                        getDisplayOfSystemFromCodableConcept(e)
                                      )
                                      .join(', ')}`}
                                  </Typography>
                                </Box>
                              }
                            />
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </RadioGroup>
              </Box>
            )}
            <Box marginY={1} display='flex' flexDirection='column' width='100%'>
              <Typography
                variant='subtitle1'
                color='initial'
                style={{
                  paddingBottom: '10px',
                }}
              >
                Enter Referral Note
              </Typography>

              <TextField
                id='txt_referral_note'
                fullWidth={true}
                variant='outlined'
                multiline
                value={referralNote}
                onChange={(e) => {
                  setReferralNote(e.target.value)
                }}
              />
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (onCancelActionClicked) onCancelActionClicked()
          }}
          variant='outlined'
          disableElevation
          id='referral_note_cancel_button'
        >
          <Typography variant='button' id={`${id}cancel_button`}>
            Cancel
          </Typography>
        </Button>
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            if (
              referralNote &&
              referralNote.length > 0 &&
              selectedReferralDoc &&
              onSuccessfulAction
            ) {
              const index = availableDocs.findIndex(
                (doc) => doc.roleId === selectedReferralDoc
              )
              const selectedDoctor = availableDocs[index].practitionerRole
              onSuccessfulAction(
                referralNote,
                selectedDoctor,
                availableDocs[index].practitioner
              )
            }
          }}
          variant='contained'
          color='primary'
          id='referral_note_refer_button'
          disabled={
            referralNote === undefined ||
            referralNote === '' ||
            selectedReferralDoc === undefined ||
            selectedReferralDoc === ''
          }
        >
          <Typography variant='button' id={`${id}done_button`}>
            Refer
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
