import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { uploadFileDetails } from 'services/fileApiService'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitDetails,
  getCurrentUserUnitLocationDetails,
  updateUserDetailsFromServer,
} from 'services/userDetailsService'
import { ORG_LOGO_SPECIALIZATION_URL } from 'utils/constants/fhir_vs_ext_constants'
import {
  getValueAttachmentFromExtensionData,
  getValueCodingFromExtension,
  removeExtensionFromExtensions,
} from 'utils/fhirResourcesHelper'
import { isClinic } from 'utils/fhirResoureHelpers/unit_setup_helpers'
import { logger } from 'utils/logger'
import { UnitProfileCompletionTypes } from './unitProfileCompletionTypes'

const initialState: UnitProfileCompletionTypes = {
  currentStep: 'basic_details',
  updatingBasicDetails: false,
  updatedBasicDetails: false,
  updatedUnitTypeSpecificDetails: false,
  updatingUnitTypeSpecificDetails: false,
  organizationDetails: getCurrentUserUnitDetails(),
  locationDetails: getCurrentUserUnitLocationDetails(),
  error: false,
  errorMessage: '',
}

const unitProfileCompletionSlice = createSlice({
  name: 'unitProfileCompletionSlice',
  initialState,
  reducers: {
    updateState(state, action: PayloadAction<UnitProfileCompletionTypes>) {
      state.updatingBasicDetails = action.payload.updatingBasicDetails
      state.updatedBasicDetails = action.payload.updatedBasicDetails
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.currentStep = action.payload.currentStep
      state.organizationDetails = action.payload.organizationDetails
      state.locationDetails = action.payload.locationDetails
      state.updatedUnitTypeSpecificDetails =
        action.payload.updatedUnitTypeSpecificDetails
      state.updatingUnitTypeSpecificDetails =
        action.payload.updatingUnitTypeSpecificDetails
    },
  },
})
export const getCurrentUserUnitsDetails =
  (): AppThunk => async (dispatch: any) => {
    const pract: R4.IOrganization = getCurrentUserUnitDetails()
    if (pract.id === undefined) {
      const res = await updateUserDetailsFromServer()
    }
    if (pract.meta) {
      logger.info('Meta', pract.meta)
      if (pract.meta.tag && pract.meta.tag.length > 0) {
        const code: R4.ICoding | undefined = pract.meta.tag.find(
          (e) => e.code === 'unit_details_added'
        )
        if (code) {
          const addingCreatePersonState: UnitProfileCompletionTypes = {
            ...initialState,
            updatedBasicDetails: true,
            updatedUnitTypeSpecificDetails: false,
            currentStep: 'unit_specific_details',
            organizationDetails: getCurrentUserUnitDetails(),
            locationDetails: getCurrentUserUnitLocationDetails(),
          }
          dispatch(
            unitProfileCompletionSlice.actions.updateState(
              addingCreatePersonState
            )
          )
          return
        }
        logger.info('Did not find any completion details')
      }
    }
    const addingCreatePersonState: UnitProfileCompletionTypes = {
      ...initialState,
      organizationDetails: getCurrentUserUnitDetails(),
      locationDetails: getCurrentUserUnitLocationDetails(),
    }
    dispatch(
      unitProfileCompletionSlice.actions.updateState(addingCreatePersonState)
    )
  }

export const updateUnitsBasicDetails =
  (
    organizationDetails: R4.IOrganization,
    location: R4.ILocation,
    consent: boolean,
    task?: R4.ITask
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: UnitProfileCompletionTypes = {
      ...initialState,
      updatingBasicDetails: true,
    }
    dispatch(
      unitProfileCompletionSlice.actions.updateState(addingCreatePersonState)
    )

    try {
      const logo: R4.IAttachment | undefined =
        getValueAttachmentFromExtensionData(
          organizationDetails.extension,
          'http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo'
        )
      organizationDetails.extension = removeExtensionFromExtensions(
        organizationDetails.extension,
        ORG_LOGO_SPECIALIZATION_URL
      )
      organizationDetails.active = true
      if (organizationDetails.extension === undefined)
        organizationDetails.extension = []

      if (logo) {
        const logoExt: R4.IExtension = {}
        logoExt.url = ORG_LOGO_SPECIALIZATION_URL
        logoExt.valueAttachment = {
          contentType: logo.contentType,
          title: logo.title,
        }
        organizationDetails.extension.push(logoExt)
      }
      if (consent) {
        const visibilityExt: R4.IExtension = {}
        visibilityExt.url =
          'http://terminology.hl7.org/CodeSystem/v3-Confidentiality'
        visibilityExt.valueCoding = {
          system: 'http://terminology.hl7.org/CodeSystem/v3-Confidentiality',
          code: 'U',
          display: 'unrestricted',
        }
        if (
          organizationDetails.extension &&
          organizationDetails.extension.length > 0
        ) {
          organizationDetails.extension.push(visibilityExt)
        } else {
          organizationDetails.extension = [visibilityExt]
        }
      }

      const extenSionVal = getValueCodingFromExtension(
        organizationDetails.extension,
        'http://terminology.hl7.org/CodeSystem/v3-Confidentiality'
      )

      const requestBody: R4.IBundle | undefined = await getTransactionObject(
        organizationDetails,
        location,
        isClinic(getCurrentUserUnitDetails()) ? undefined : task,
        extenSionVal
      )

      logger.info('request body', requestBody)
      if (requestBody) {
        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response: any | FHIRErrorResponses = ''
        await fhirApi.doCreateFHIRTransaction('', requestBody)
        logger.info('Response ', response)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        // if (relatedFhirDecodeRes._tag === 'Right') {

        try {
          let logoAttachment: R4.IAttachment | undefined = {}
          if (logo) {
            logoAttachment = {
              data: logo.url,
              contentType: logo.contentType,
              title: logo.title,
            }
          }

          if (logoAttachment) {
            await uploadFileDetails(
              logoAttachment,
              "Organization.extension('http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo').value.as(Attachment)",
              'Organization',
              organizationDetails.id ?? ''
            )
          }
        } catch (error) {
          logger.info(error)
          //   dispatch(showErrorAlert('Patient created. Error in uploading proofs'))
        }

        const res: boolean = await updateUserDetailsFromServer()
        if (res) {
          if (isClinic(getCurrentUserUnitDetails())) {
            const successCreatePersonState: UnitProfileCompletionTypes = {
              ...addingCreatePersonState,
              updatedBasicDetails: true,
              updatingBasicDetails: false,
              error: false,
              errorMessage: '',
              currentStep: 'unit_specific_details',
              organizationDetails: getCurrentUserUnitDetails(),
              locationDetails: getCurrentUserUnitLocationDetails(),
            }
            dispatch(
              unitProfileCompletionSlice.actions.updateState(
                successCreatePersonState
              )
            )
            return
          }
          const successCreatePersonState: UnitProfileCompletionTypes = {
            ...addingCreatePersonState,
            updatedBasicDetails: true,
            updatingBasicDetails: false,
            error: false,
            errorMessage: '',
            currentStep: 'unit_specific_details',
            organizationDetails: getCurrentUserUnitDetails(),
            locationDetails: getCurrentUserUnitLocationDetails(),
          }
          dispatch(
            unitProfileCompletionSlice.actions.updateState(
              successCreatePersonState
            )
          )
          return
        }
      }
      const errorCreatePersonState: UnitProfileCompletionTypes = {
        ...addingCreatePersonState,
        updatedBasicDetails: false,
        updatingBasicDetails: false,
        error: true,
        errorMessage: 'Error while updating details, Please Try later',
      }
      dispatch(
        unitProfileCompletionSlice.actions.updateState(errorCreatePersonState)
      )
      return
    } catch (error) {
      logger.info('error')
      logger.info(error)
      const errorCreatePersonState: UnitProfileCompletionTypes = {
        ...addingCreatePersonState,
        updatedBasicDetails: false,
        updatingBasicDetails: false,
        error: true,
        errorMessage: 'Error while updating details, Please Try later',
      }
      dispatch(
        unitProfileCompletionSlice.actions.updateState(errorCreatePersonState)
      )
    }
  }

export const updateUnitTypeSpecificDetails =
  (location: R4.ILocation, task?: R4.ITask): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: UnitProfileCompletionTypes = {
      ...initialState,
      updatingUnitTypeSpecificDetails: true,
    }
    dispatch(
      unitProfileCompletionSlice.actions.updateState(addingCreatePersonState)
    )

    try {
      const requestBody: R4.IBundle | undefined =
        await getLocationTransactionObject(location, task)

      logger.info('request body', requestBody)
      if (requestBody) {
        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response: any | FHIRErrorResponses =
          await fhirApi.doCreateFHIRTransaction('', requestBody)
        logger.info('Response ', response)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          const res: boolean = await updateUserDetailsFromServer()
          if (res) {
            logger.info('DetailsStored')

            const successCreatePersonState: UnitProfileCompletionTypes = {
              ...addingCreatePersonState,
              updatedBasicDetails: true,
              updatingBasicDetails: false,
              currentStep: 'unit_specific_details',
              updatedUnitTypeSpecificDetails: true,
              error: false,
              errorMessage: '',
              locationDetails: getCurrentUserUnitLocationDetails(),
              organizationDetails: getCurrentUserUnitDetails(),
            }

            dispatch(
              unitProfileCompletionSlice.actions.updateState(
                successCreatePersonState
              )
            )
            dispatch(showSuccessAlert('Working hours updated successfully'))
            return
          }
          logger.info('DetailsStore error')
        }
      }

      logger.info('Error Decoding error')
      const errorCreatePersonState: UnitProfileCompletionTypes = {
        ...addingCreatePersonState,
        updatedBasicDetails: false,
        updatingBasicDetails: false,
        error: true,
        errorMessage: 'Error while updating details, Please Try later',
      }
      dispatch(
        unitProfileCompletionSlice.actions.updateState(errorCreatePersonState)
      )
      return
    } catch (error) {
      logger.info('error')
      logger.info(error)
      const errorCreatePersonState: UnitProfileCompletionTypes = {
        ...addingCreatePersonState,
        updatedBasicDetails: false,
        updatingBasicDetails: false,
        error: true,
        errorMessage: 'Exception while updating details, Please Try later',
      }
      dispatch(
        unitProfileCompletionSlice.actions.updateState(errorCreatePersonState)
      )
    }
  }

export const resetUnitCompletionState = () => (dispatch: AppDispatch) => {
  dispatch(unitProfileCompletionSlice.actions.updateState(initialState))
}

export default unitProfileCompletionSlice.reducer

async function getTransactionObject(
  organizationDetails: R4.IOrganization,
  location: R4.ILocation,
  task?: R4.ITask,
  extVal?: R4.ICoding
): Promise<R4.IBundle | undefined> {
  const practitionerRoleDetail: R4.IPractitionerRole = {
    ...getCurrentUserPractitionerRoleDetails(),
  }
  practitionerRoleDetail.location = [
    {
      reference: `${location.resourceType}/${location.id}`,
    },
  ]

  logger.info('request body', practitionerRoleDetail)
  organizationDetails.meta = {
    ...(organizationDetails.meta ?? ({} as R4.IMeta)),
    tag: [
      ...(organizationDetails.meta?.tag ?? []),
      {
        code: 'unit_details_added',
        system:
          'http://wellopathy.com/fhir/india/core/vs/unitCompleitionIndicators',
      },
    ],
  }
  const matchPractitionerString: string = `W/${JSON.stringify(
    practitionerRoleDetail.meta?.versionId ?? ' '
  )}`
  const matchOrganizationString: string = `W/${JSON.stringify(
    organizationDetails.meta?.versionId ?? ' '
  )}`

  const matchLocationString: string = `W/${JSON.stringify(
    location.meta?.versionId ?? ' '
  )}`

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `${organizationDetails.resourceType}/${organizationDetails.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${organizationDetails.resourceType}/${organizationDetails.id}`,
          ifMatch: matchOrganizationString,
        },
        resource: organizationDetails,
      },
      {
        fullUrl: `${location.resourceType}/`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${location.resourceType}/${location.id}`,
          ifMatch: matchLocationString,
        },
        resource: location,
      },
      {
        fullUrl: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
          ifMatch: matchPractitionerString,
        },
        resource: practitionerRoleDetail,
      },
    ],
  }

  if (extVal) {
    if (extVal.code && extVal.code === 'U') {
      const consent: R4.IConsent = {
        resourceType: 'Consent',

        status: R4.ConsentStatusKind._active,
        scope: {
          coding: [
            {
              system: 'http://terminology.hl7.org/CodeSystem/consentscope',
              code: ' research',
              display: 'Data Sharing',
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system: 'http://loinc.org',
                code: '57017-6',
                display: 'Privacy policy Organization Document',
              },
            ],
          },
        ],

        dateTime: '2021-09-20T08:24:03+00:00',
        policyRule: {
          coding: [
            {
              system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
              code: 'OPTIN',
            },
          ],
        },
        provision: {
          period: {
            start: '2021-09-20T08:24:03+00:00',
            end: '2022-09-20T08:24:03+00:00',
          },
          provision: [
            {
              type: R4.Consent_ProvisionTypeKind._deny,
              action: [
                {
                  coding: [
                    {
                      system:
                        'http://terminology.hl7.org/CodeSystem/consentaction',
                      code: 'access',
                    },
                  ],
                },
              ],
              securityLabel: [
                {
                  system:
                    'http://terminology.hl7.org/CodeSystem/v3-Confidentiality',
                  code: 'U',
                },
              ],
              purpose: [
                {
                  system: 'http://terminology.hl7.org/ValueSet/v3-PurposeOfUse',
                  code: 'HMARKT',
                },
              ],
            },
          ],
        },
      }
      //   requestBundle.entry?.push({
      //     fullUrl: `Consent`,
      //     request: {
      //       method: R4.Bundle_RequestMethodKind._post,
      //     },
      //     resource: consent,
      //   })
    }
  }

  if (task) {
    const modifiedTask: R4.ITask = { ...task }
    modifiedTask.status = R4.TaskStatusKind._completed

    const matchTaskString: string = `W/${JSON.stringify(
      modifiedTask.meta?.versionId ?? ' '
    )}`

    requestBundle.entry?.push({
      fullUrl: `Task/${task.id}`,
      request: {
        ifMatch: matchTaskString,
        method: R4.Bundle_RequestMethodKind._put,
        url: `Task/${task.id}`,
      },
      resource: modifiedTask,
    })
  }
  logger.info('Request bundle', requestBundle)
  return requestBundle
}

async function getLocationTransactionObject(
  location: R4.ILocation,
  task?: R4.ITask
): Promise<R4.IBundle | undefined> {
  const matchLocationString: string = `W/${JSON.stringify(
    location.meta?.versionId ?? ' '
  )}`

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      /* ...entries, */
      {
        fullUrl: `${location.resourceType}/${location.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${location.resourceType}/${location.id}`,
          ifMatch: matchLocationString,
        },
        resource: location,
      },
    ],
  }

  if (task) {
    const modifiedTask: R4.ITask = { ...task }
    modifiedTask.status = R4.TaskStatusKind._completed

    const matchTaskString: string = `W/${JSON.stringify(
      modifiedTask.meta?.versionId ?? ' '
    )}`

    requestBundle.entry?.push({
      fullUrl: `Task/${task.id}`,
      request: {
        ifMatch: matchTaskString,
        method: R4.Bundle_RequestMethodKind._put,
        url: `Task/${task.id}`,
      },
      resource: modifiedTask,
    })
  }

  return requestBundle
}
