import { R4 } from '@ahryman40k/ts-fhir-types'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import { ReportColumns } from 'models/Report/reportColumn'
import moment from 'moment'
import {
  getAddressOfPatient,
  getAgeOfPatient,
  getAgeOfPatientData,
  getGenderOfPatient,
  getIdentifierValueBySystem,
  getIdentifierValueBySystemForTable,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getTelecomOfPatient,
  titleCase,
} from 'utils/fhirResourcesHelper'
import {
  getCheckOutTIme,
  getCheckOutTImeReport,
  getCheckOutTImeReportForDashboard,
  getIpdAdmitTIme,
  getIpdAdmitTImeForReport,
  getIpdAdmitTImeForReportForDashboard,
  getRoomNo,
} from './ipdAppointmentHelper'

export function getDataFOrDashboardTable(
  availableAppointments: FhirClinicIpdDetails[],
  selectedDate?: Date
): ReportColumns[] {
  const tableData: ReportColumns[] = []
  if (selectedDate) {
    const startDateFormat: string = moment(selectedDate).format('YYYY-MM-DD')
    availableAppointments.forEach((item) => {
      let patientName: string = ''

      const startDate: string = moment(item.start).format('YYYY-MM-DD hh:mm')
      if (startDate >= startDateFormat) {
        if (getMiddleNameOfPatient(item.patient).length > 0)
          patientName = `${getNameOfPatient(item.patient) ?? ''} ${
            getMiddleNameOfPatient(item.patient) ?? ''
          } ${getLastNameOfPatient(item.patient) ?? ''}`
        else
          patientName = `${getNameOfPatient(item.patient) ?? ''} ${
            getLastNameOfPatient(item.patient) ?? ''
          }`

        const money = item.paymentReconilation
          ? item.paymentReconilation.paymentAmount.value?.toFixed(2) ?? '0'
          : '0'

        tableData.push({
          admission_date: getIpdAdmitTImeForReportForDashboard(item) ?? '',
          checkOut_date: getCheckOutTImeReportForDashboard(item) ?? '',
          room_number_type:
            item.type === 'ipd'
              ? `${getRoomNo(item) ?? ''}`
              : `${getRoomNo(item) ?? ''}`,
          patient_name: patientName,
          type:
            item.type === 'ipd'
              ? `${getIdentifierValueBySystem(
                  item.serviceRequest.identifier ?? [],
                  'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                )}`
              : `${getIdentifierValueBySystem(
                  item.serviceRequest.identifier ?? [],
                  'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                )}`,
          age: getAgeOfPatientData(item.patient),
          phoneNo:
            getTelecomOfPatient(
              item.patient,
              R4.ContactPointSystemKind._phone
            ) ?? '',
          AmountPaid: parseFloat(money),
          address: getAddressOfPatient(item.patient.address ?? []) ?? '',
          gender: titleCase(getGenderOfPatient(item.patient)),
          availableAppointments: item,
          ipNumber: 0,
          start: '',
          serviceRequest: '',
          patientId: '',
        })
      }
    })
  } else {
    availableAppointments.forEach((item) => {
      let patientName: string = ''
      if (getMiddleNameOfPatient(item.patient).length > 0)
        patientName = `${getNameOfPatient(item.patient) ?? ''} ${
          getMiddleNameOfPatient(item.patient) ?? ''
        } ${getLastNameOfPatient(item.patient) ?? ''}`
      else
        patientName = `${getNameOfPatient(item.patient) ?? ''} ${
          getLastNameOfPatient(item.patient) ?? ''
        }`

      const money = item.paymentReconilation
        ? item.paymentReconilation.paymentAmount.value?.toFixed(2) ?? '0'
        : '0'

      tableData.push({
        admission_date: getIpdAdmitTImeForReportForDashboard(item) ?? '',
        checkOut_date: getCheckOutTImeReportForDashboard(item) ?? '',
        room_number_type:
          item.type === 'ipd'
            ? `${getRoomNo(item) ?? ''}`
            : getIdentifierValueBySystem(
                item.serviceRequest.identifier ?? [],
                'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
              ) ?? '',
        type:
          item.type === 'ipd'
            ? `${getIdentifierValueBySystem(
                item.serviceRequest.identifier ?? [],
                'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
              )}`
            : `${getIdentifierValueBySystem(
                item.serviceRequest.identifier ?? [],
                'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
              )}`,
        age: getAgeOfPatientData(item.patient),
        patient_name: patientName,
        phoneNo:
          getTelecomOfPatient(item.patient, R4.ContactPointSystemKind._phone) ??
          '',
        AmountPaid: parseFloat(money),
        address: getAddressOfPatient(item.patient.address ?? []) ?? '',
        gender: titleCase(getGenderOfPatient(item.patient)),
        availableAppointments: item,
        ipNumber: 0,
        start: '',
        serviceRequest: '',
        patientId: '',
      })
    })
  }

  return tableData
}

export function getDataFOrDashboardTableFromAnalytics(
  resultData: any,
  code: string
): ReportColumns[] {
  const tableData: ReportColumns[] = []

  resultData.forEach((item: any) => {
    let patientName: string = ''

    const startDate: string = moment(item.start).format('YYYY-MM-DD')
    if (item.patient_id !== null) {
      if (code === item.sr_code.coding[0].code) {
        const ipd =
          item.sr_code.coding[0].code === '281685003'
            ? getIdentifierValueBySystemForTable(
                item.sr_identifier ?? [],
                'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
              )
            : getIdentifierValueBySystemForTable(
                item.sr_identifier ?? [],
                'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
              ) ?? ''
        if (item.patient_name[0].given.length > 1)
          patientName = `${item.patient_name[0].given[0] ?? ''} ${
            item.patient_name[0].given[1] ?? ''
          } ${item.patient_name[0].family ?? ''}`
        else
          patientName = `${item.patient_name[0].given[0] ?? ''} ${
            item.patient_name[0].family ?? ''
          }`

        const money = item.payrec_paymentAmount
          ? item.payrec_paymentAmount.value.toFixed(2) ?? '0'
          : '0'

        tableData.push({
          admission_date: moment(item.sr_occurrence.period.start).format(
            'DD-MM-YYYY'
          ),
          checkOut_date: moment(item.sr_occurrence.period.end).format(
            'DD-MM-YYYY'
          ),
          room_number_type:
            item.sr_code.coding[0].code === '281685003'
              ? `${item.location_name} - ${getIdentifierValueBySystemForTable(
                  item.sr_identifier ?? [],
                  'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                )}`
              : `${getIdentifierValueBySystemForTable(
                  item.sr_identifier ?? [],
                  'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                )}`,
          type:
            item.sr_code.coding[0].code === '281685003'
              ? `${getIdentifierValueBySystemForTable(
                  item.sr_identifier ?? [],
                  'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                )}`
              : `${getIdentifierValueBySystemForTable(
                  item.sr_identifier ?? [],
                  'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                )}`,
          age: getAgeOfPatientData(item.patient),
          patient_name: patientName,
          phoneNo: item.patient_telecom[0].value,
          AmountPaid: parseFloat(money),
          address: getAddressOfPatient(item.patient_address ?? []) ?? '',
          gender: titleCase(item.patient_gender),
          availableAppointments: item,
          ipNumber: ipd ? parseInt(ipd.split('/')[3], 10) : 0,
          start: item.sr_occurrence.period.start,
          serviceRequest: item.sr_id,
          patientId: item.patient_id,
        })
      } else if (code === item.sr_code.coding[0].code) {
        const ipd =
          getIdentifierValueBySystemForTable(
            item.sr_identifier ?? [],
            'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
          ) ?? ''
        if (item.patient_name[0].given.length > 1)
          patientName = `${item.patient_name[0].given[0] ?? ''} ${
            item.patient_name[0].given[1] ?? ''
          } ${item.patient_name[0].family ?? ''}`
        else
          patientName = `${item.patient_name[0].given[0] ?? ''} ${
            item.patient_name[0].family ?? ''
          }`

        const money = item.payrec_paymentAmount
          ? item.payrec_paymentAmount.value.toFixed(2) ?? '0'
          : '0'

        tableData.push({
          admission_date: moment(item.sr_occurrence.period.start).format(
            'DD-MM-YYYY'
          ),
          checkOut_date: moment(item.sr_occurrence.period.end).format(
            'DD-MM-YYYY'
          ),
          room_number_type: `${item.location_name} ${
            getIdentifierValueBySystemForTable(
              item.sr_identifier ?? [],
              'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
            ) ?? ''
          }`,
          type:
            getIdentifierValueBySystemForTable(
              item.sr_identifier ?? [],
              'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
            ) ?? '',
          age: getAgeOfPatientData(item.patient),
          patient_name: patientName,
          phoneNo: item.patient_telecom[0].value,
          AmountPaid: parseFloat(money),
          address: getAddressOfPatient(item.patient_address ?? []) ?? '',
          gender: titleCase(item.patient_gender),
          availableAppointments: item,
          ipNumber: ipd ? parseInt(ipd.split('/')[3], 10) : 0,
          start: item.sr_occurrence.period.start,
          serviceRequest: item.sr_id,
          patientId: item.patient_id,
        })
      } else if (code === item.sr_code.coding[0].code) {
        const ipd =
          getIdentifierValueBySystemForTable(
            item.sr_identifier ?? [],
            'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
          ) ?? ''
        if (item.patient_name[0].given.length > 1)
          patientName = `${item.patient_name[0].given[0] ?? ''} ${
            item.patient_name[0].given[1] ?? ''
          } ${item.patient_name[0].family ?? ''}`
        else
          patientName = `${item.patient_name[0].given[0] ?? ''} ${
            item.patient_name[0].family ?? ''
          }`

        const money = item.payrec_paymentAmount
          ? item.payrec_paymentAmount.value.toFixed(2) ?? '0'
          : '0'

        tableData.push({
          admission_date: moment(item.sr_occurrence.period.start).format(
            'DD-MM-YYYY'
          ),
          checkOut_date: moment(item.sr_occurrence.period.end).format(
            'DD-MM-YYYY'
          ),
          room_number_type: `${item.location_name} ${
            getIdentifierValueBySystemForTable(
              item.sr_identifier ?? [],
              'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
            ) ?? ''
          }`,
          type:
            getIdentifierValueBySystemForTable(
              item.sr_identifier ?? [],
              'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
            ) ?? '',
          age: getAgeOfPatientData(item.patient),
          patient_name: patientName,
          phoneNo: item.patient_telecom[0].value,
          AmountPaid: parseFloat(money),
          address: getAddressOfPatient(item.patient_address ?? []) ?? '',
          gender: titleCase(item.patient_gender),
          availableAppointments: item,
          ipNumber: ipd ? parseInt(ipd.split('/')[3], 10) : 0,
          start: item.sr_occurrence.period.start,
          serviceRequest: item.sr_id,
          patientId: item.patient_id,
        })
      }
    }
  })

  return tableData
}
