import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListSubheader,
  Radio,
  RadioGroup,
  Typography,
  Tooltip,
  Link,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedOccupation } from 'models/groupedOccupations'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestAddictionsHistoryOfPatient } from 'redux/patientMedicalHistory/addictionsHistory/addictionsHistorySlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import {
  getDateWiseVitals,
  getDateWiseVitalsForOthers,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { AddAddiction } from 'views/components/ipdconusltation/addition/addAddiction'
import { HistoryObjectiveIndicativeComp } from 'views/components/LeftMenu/HistoryObjectiveIndicative'
import { VitalElement } from 'views/components/LeftMenu/VitalElement'
import { SOAPIndicativeElement } from 'wello-web-components'
import { AddictionTileTabular } from './addictionTabularTile'

interface AddictionsProps {
  addictionList: GroupedOccupation[]
}

export const AddictionsHistory: React.FC<AddictionsProps> = ({
  addictionList,
}: AddictionsProps) => {
  const { t } = useTranslation(['common'])

  return (
    <Box
      flexGrow
      width='100%'
      flexDirection='column'
      display='flex'
      style={{ padding: 0, margin: 0 }}
    >
      <Box flexGrow width='100%' display='flex' flexDirection='row'>
        <Box flexDirection='row' display='flex' flexGrow width='100%'>
          <Box py={1}>
            <Typography variant='subtitle1'>
              {' '}
              {t('labelCommon:addictions')}{' '}
            </Typography>
          </Box>{' '}
          {/* {isMedicalServiceProvider() && !splitView && (
              <IconButton
                style={{ padding: '4px' }}
                onClick={() => {
                  setOpenAddDialogue(true)
                }}
              >
                <Add style={{ height: '14px' }} color='primary' />
              </IconButton>
            )} */}
        </Box>
      </Box>

      {addictionList.length === 0 && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            {' '}
            No data available
          </Typography>
        </Box>
      )}

      {addictionList.length > 0 && (
        <Box flexGrow width='100%' display='flex' flexDirection='column'>
          {addictionList.map((val) => (
            <Box py={1} key={val.date ?? ''}>
              <Box
                paddingX={1}
                borderRadius={2}
                style={{
                  backgroundColor: 'lightGrey',
                }}
                display='flex'
                flexDirection='row'
                width='100%'
                height={40}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow={1}
                  alignItems='center'
                >
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: 'black',

                      fontWeight: 'bold',
                    }}
                  >
                    Recorded on{' '}
                    {moment(val.date).format('Do MMMM YYYY hh:mm A')}
                  </Typography>
                </Box>
              </Box>
              <AddictionTileTabular topObs={val.occupation} />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}
