import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import ReactPanZoom from 'react-image-pan-zoom-rotate'
import { getContentOfDocumentReference } from 'services/fileServices'
import { AllPages } from './allPagePdfFile'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    medium: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    big: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  })
)

interface IProps {
  data: string
  showPreview: boolean
  onCloseClicked: () => void
}

export const PdfViewer: React.FC<IProps> = ({
  data,
  showPreview,
  onCloseClicked,
}: IProps) => {
  const [file, setFile] = useState<string>()
  const [fetching, setFetching] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const fileName = ''

  useEffect(() => {
    setFile(data)
  }, [])

  return (
    <Dialog
      maxWidth='md'
      open={showPreview}
      onClose={() => {
        onCloseClicked()
      }}
    >
      <DialogTitle id='scroll-dialog-title'>
        {' '}
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Preview</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={() => {
                onCloseClicked()
              }}
              id='close_order'
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          display='flex'
          justifyContent='center'
          alignContent='center'
          minWidth='400px'
          minHeight='500px'
        >
          {file && file.length > 0 && <AllPages pdf={file} />}
        </Box>
      </DialogContent>
    </Dialog>
  )
}
