import { Box, Button, TextField, Typography } from '@material-ui/core'
import { RouteParams } from 'App'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  checkAuthentication,
  checkAuthorization,
} from 'redux/authChecker/authSlice'
import { fetchPendingTasksDetailsOfCurrentUser } from 'redux/pendingTaskChecker/pendingTasksCheckerSlice'
import { RootState } from 'redux/rootReducer'
import * as urlencode from 'urlencode'
import { logger } from 'utils/logger'
import { storeVendorPartId } from 'utils/routes_helper'
import {
  generateString,
  getChallengeForVerifier,
  getVerifier,
} from 'utils/urlHelper'
import { CommonBackground } from 'views/components/common/common_brackground'
import { LandingLogoComponent } from 'views/components/common/landing_logo_componant'
import { UnitAndRoleSelector } from 'views/components/roleSelecterForAUth'
import { PendingTaskChecker } from 'views/components/task_checker/taskChecker'
import { WelloLoadingIndicator } from 'wello-web-components'

export const AuthChecker: React.FC<RouteParams> = () => {
  const [orgid, setOrgid] = useState<string>()
  const authState = useSelector((state: RootState) => state.authCheck)
  const dispatch = useDispatch()
  const verifier = getVerifier()
  const challenge = getChallengeForVerifier(verifier)
  const state = generateString(12)
  const history = useHistory()
  const params = useParams<RouteParams>()
  const unitAdmin = [
    'Verify email & Set up Account Security',
    'Enter your Personal Information',
    'Manage patient and appointments',
  ]

  const { t } = useTranslation()
  useEffect(() => {
    if (window.location.search) {
      logger.error('inside search')
      dispatch(checkAuthorization(window.location.search))
    } else {
      if (params.vendor !== undefined) {
        storeVendorPartId(params.vendor)
      }

      dispatch(checkAuthentication())
    }
  }, [dispatch])

  useEffect(() => {
    if (
      authState.checking === false &&
      authState.authorized === false &&
      authState.authenticated === false &&
      authState.error === false &&
      authState.noOrganization === false
    ) {
      requestLogin()
    }
  }, [authState])

  useEffect(() => {
    if (
      authState.authenticated === true &&
      authState.displayRoleSelection === false
    ) {
      dispatch(fetchPendingTasksDetailsOfCurrentUser())
    }
  }, [authState])

  function requestLogin() {
    sessionStorage.setItem('state', state)
    localStorage.setItem('verifier', verifier)
    storeVendorPartId(params.vendor)

    const clientId: string =
      process.env.REACT_APP_NAME === 'WelloLAB'
        ? 'wello_lab_web'
        : process.env.REACT_APP_NAME === 'WelloDR'
        ? 'wello_clinic_web'
        : 'wello_admin_web'

    const redirectUrl: string = urlencode.encode(
      process.env.REACT_APP_REDIRECT_URL ?? ''
    )
    const url: string = `${process.env.REACT_APP_AUTH_URL}/oidc/auth?response_type=code&scope=__provider%20__org:${params.vendor}%20openid%20fhirUser%20email%20profile%20phone%20address%20roles%20&client_id=${clientId}&state=${state}&code_challenge=${challenge}&code_challenge_method=S256&redirect_uri=${redirectUrl}&prompt=select_account&resource=https%3A%2F%2Fapi.wellopathy.com%2Fwellostack`

    window.location.href = url
    // window.location.href = `${process.env.REACT_APP_AUTH_URL}/oidc/auth?response_type=code&scope=__provider%20__org:healthville%20openid%20fhirUser%20email%20profile%20phone%20address%20roles%20offline_access&client_id=labadminweb&state=${state}&code_challenge=${challenge}&code_challenge_method=S256&redirect_uri=${redirectUrl}&prompt=consent&resource=https%3A%2F%2Fapi.wellopathy.com%2Fwellostack&org_id=myorg2`
  }

  return (
    <CommonBackground>
      {authState.authenticated && !authState.displayRoleSelection ? (
        <PendingTaskChecker />
      ) : authState.authenticated && authState.displayRoleSelection ? (
        <LandingLogoComponent mainComponent={<UnitAndRoleSelector />} />
      ) : (
        <LandingLogoComponent
          mainComponent={
            <Box>
              {authState.checking ? (
                <WelloLoadingIndicator
                  message={t('labelCommon:login_on_process')}
                />
              ) : (
                <Box
                  display='flex'
                  flexDirection='column'
                  alignContent='center'
                  justifyContent='center'
                >
                  <Typography variant='body1' color='initial' align='center'>
                    Enter your organization id
                  </Typography>

                  <form
                    onSubmit={(e) => {
                      window.location.href = `${orgid}/login`
                      e.preventDefault()
                    }}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignContent='stretch'
                      justifyContent='center'
                      padding={2}
                    >
                      <Box
                        paddingY={1}
                        display='flex'
                        alignContent='center'
                        justifyContent='center'
                        flexDirection='row'
                        flexGrow
                      >
                        <TextField
                          id='neo_org_id_textfield'
                          variant='outlined'
                          size='small'
                          fullWidth
                          required={true}
                          label=''
                          value={orgid}
                          onChange={(val) => {
                            if (val.currentTarget.value.includes(' ')) {
                              val.currentTarget.value =
                                val.currentTarget.value.replace(/\s/g, '')
                            }
                            setOrgid(val.target.value)
                          }}
                        />
                      </Box>
                      <Box
                        paddingY={1}
                        flexDirection='row'
                        display='flex'
                        alignContent='center'
                        justifyContent='center'
                      >
                        <Button
                          variant='contained'
                          color='primary'
                          type='submit'
                          fullWidth
                          disabled={orgid === undefined || orgid.length === 0}
                        >
                          <Typography variant='body2' color='initial'>
                            Next
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </form>
                  {authState.error && (
                    <Typography variant='body2' color='error'>
                      {authState.errorMessage ??
                        'Error while opening login page'}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          }
        />
      )}
    </CommonBackground>
  )
}
