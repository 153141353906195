/* eslint-disable no-loop-func */
/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  IconButton,
  Tooltip,
  InputAdornment,
} from '@material-ui/core'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import TextField from '@mui/material/TextField/TextField'
import { DeleteOutlineOutlined, Info } from '@material-ui/icons'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirTherapyListWithChargeDef } from 'models/fhirTherapyList'
import { AyurvedaTreatment, Treatment } from 'models/treatMent'
import {
  AyurvedaTreatmentPlanData,
  TreatmentPlanData,
} from 'models/treatmentPlanData'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import {
  deleteTherapy,
  resetDeleteState,
} from 'redux/ipd/deleteTherapy/deleteTherapySlice'
import {
  addTreatmentDetails,
  resetTreatmentState,
} from 'redux/ipd/treatment/addTreatment/addTreatmentSlice'
import { requestForTreatmentForAyurveda } from 'redux/ipd/treatment/viewAyurvedaTreatment/ayurvedaTreatmentSearchSlice'
import { requestForTreatment } from 'redux/ipd/treatment/viewTreatment/treatmentSearchSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { isUnitAdmin } from 'services/userDetailsService'
import { therapyType } from 'utils/constants'
import { getExtensionValueData } from 'utils/fhirResourcesHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  dateCompare,
  getNotes,
  getNotesFromTherapy,
  getOrderStatusText,
  inBetWeenTime,
  isAfter,
  isAfterSame,
  isBeforeFinal,
  isCurrentDate,
  isSameOrBeforeAddEveing,
  isSameOrBeforeBetweenAdd,
  isSameTherapy,
  isTherapybetweenForAfterNoon,
  isTherapybetweenForEvening,
  isTherapybetweenForMidMoring,
  isTherapybetweenForMorning,
} from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'
import { AlertDialog } from 'views/components/LeftMenu/alertDialog'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { WelloSelectFhirCodingForTherapy } from 'views/components/LeftMenu/WelloSelectFhirCodingForTherapy'
import { WelloSelectTherapyList } from 'views/components/LeftMenu/WelloSelectTherapyList'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import {
  WelloFormItemLabel,
  WelloLoadingIndicator,
  WelloTextField,
} from 'wello-web-components'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import { medicationMaps } from 'models/AyurvedaUrlCategory'
import { addTreatmentDetailsForAyurveda } from 'redux/ipd/treatment/addTreatmentAyurveda/addTreatmentAyurvedaSlice'
import { TherapySelectorForPlan } from 'views/components/LeftMenu/therapyListSelector'

interface AssessmentCaptureProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  status: string
}

export const AyurvedaTreatmentCard: React.FC<AssessmentCaptureProps> = ({
  fhirAppointmentDetails,
  status,
}: AssessmentCaptureProps) => {
  let checkinDate = moment(
    fhirAppointmentDetails.mainServiceRequest.occurrencePeriod?.start
  ).format('YYYY-MM-DD')
  let checkOutDate = moment(
    fhirAppointmentDetails.mainServiceRequest.occurrencePeriod?.end
  ).format('YYYY-MM-DD')

  const [defaultMedicineCategoryUrl, setDefaultMedicineCategoryUrl] =
    useState<string>(medicationMaps.all_url)

  const currentDate = moment().format('YYYY-MM-DD')
  let maxDate = new Date(
    `${currentDate} ${moment(moment(), ['hh:mm A']).format('21:00')}`
  )
  if (isAfter('21:00')) {
    maxDate = new Date(`${currentDate} 21:00`)
  }

  let error: string[] = []

  const endDateForCheck = moment(moment().format('YYYY-MM-DD'))
  const duration = moment.duration(endDateForCheck.diff(moment(checkinDate)))
  const days = duration.asDays()
  if (days >= 5) {
    const newDate = moment().add(1, 'days')
    const checkoutDateDurtation = moment.duration(
      moment(checkOutDate).diff(endDateForCheck)
    )
    const checkOutDays = checkoutDateDurtation.asDays()
    if (checkOutDays < 0) {
      checkOutDate = moment().format('YYYY-MM-DD')
    }
    const newCheckinDate = newDate.subtract(5, 'days')
    checkinDate = moment(newCheckinDate).format('YYYY-MM-DD')
  }
  const [value1, setValue1] = React.useState(new Date('2020-01-01 12:00'))

  const [loading, setLoading] = React.useState<boolean>(false)
  const [fullText, setFullText] = useState<string>('')
  const [showTextForNotes, setShowTextForNotes] = useState<boolean>(false)

  const appointmentTypeData = fhirAppointmentDetails.mainServiceRequest.code
    ? fhirAppointmentDetails.mainServiceRequest.code.coding
      ? fhirAppointmentDetails.mainServiceRequest.code.coding[0].code ?? ''
      : ''
    : ''

  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()

  const addtreatmentSlice = useSelector(
    (state: RootState) => state.addTreatmentSliceForAyurveda
  )

  const treatmentSearchSlice = useSelector(
    (state: RootState) => state.treatmentSearchSliceForAyurvedaIPD
  )

  const deleteTreatmentSlice = useSelector(
    (state: RootState) => state.deleteTreatmentSlice
  )

  const kriyaSearchPriceSlice = useSelector(
    (state: RootState) => state.kriyaSearchPriceSlice
  )

  const threapyCatalogSearchPriceSlice = useSelector(
    (state: RootState) => state.threapyCatalogSearchPriceSlice
  )

  const substanceSearchSlice = useSelector(
    (state: RootState) => state.substanceSearchSlice
  )

  function handleDateChange(date: any) {
    const treatmentData: AyurvedaTreatment = {
      ...treatMent,
    }

    treatmentData.date = date.target.value

    setTreatMent(treatmentData)
  }

  function handleEndDateChange(date: any) {
    const treatmentData: AyurvedaTreatment = {
      ...treatMent,
    }
    treatmentData.endDate = date.target.value

    setTreatMent(treatmentData)
  }

  function resetValues() {
    error = []
    setTreatMent({
      id: getUniqueTempId(),
      date: moment(moment()).format('YYYY-MM-DD'),
      startTime: moment(moment()).format('HH:mm'),
      endTime: undefined,
      endDate: undefined,
      therapy: undefined,
      duration: undefined,
      threapyId: undefined,
      medication: undefined,
      notes: undefined,
    })
    setTreatmentType('day')
  }

  function handleTherapyChange(th: FhirTherapyListWithChargeDef) {
    const treatmentData: AyurvedaTreatment = {
      ...treatMent,
    }
    const therapyCode: R4.ICoding | undefined = th.therapy.type
      ? th.therapy.type[0].coding
        ? th.therapy.type[0].coding[0]
        : undefined
      : undefined

    treatmentData.duration = getExtensionValueData(
      th.therapy.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
    )

    if (treatmentData.duration) {
      treatmentData.endTime = moment(treatmentData.startTime!, 'HH:mm:ss')
        .add(treatmentData.duration, 'minutes')
        .format('HH:mm')
    }
    treatmentData.threapyId = th.therapy.id
    treatmentData.therapy = therapyCode
    setTreatMent(treatmentData)
  }

  const duplicate: string[] = []

  const [treatmentType, setTreatmentType] = useState<string>('day')
  const [therapyName, setTherapyName] = useState<string>('')
  const [indexData, setIndexData] = useState<number>()
  const [start, setStart] = React.useState<Date>(
    new Date(
      `2020-01-01 ${isAfter('21:00') ? '21:00' : moment().format('HH:mm')}`
    )
  )
  const [showText, setShowText] = useState<boolean>(false)
  const [showTextForSlot, setShowTextForSlot] = useState<boolean>(false)
  const [showTextForMorning, setShowTextForMorning] = useState<boolean>(false)
  const [showTextForMidMorning, setShowTextForMidMorning] =
    useState<boolean>(false)

  const [showTextForAfterNoon, setShowTextForAfterNoon] =
    useState<boolean>(false)
  const [showTextForEvening, setShowTextForEvening] = useState<boolean>(false)

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  const [treatMent, setTreatMent] = useState<AyurvedaTreatment>({
    id: getUniqueTempId(),
    date: moment(moment()).format('YYYY-MM-DD'),
    startTime: moment(moment()).format('HH:mm'),
  })

  function handleTime(timeChange: any) {
    const treatmentData: AyurvedaTreatment = {
      ...treatMent,
    }
    setStart(timeChange)

    treatmentData.startTime = moment(timeChange).format('HH:mm')
    if (treatmentData.duration) {
      treatmentData.endTime = moment(
        moment(timeChange).format('HH:mm'),
        'HH:mm:ss'
      )
        .add(treatmentData.duration, 'minutes')
        .format('HH:mm')
    }
    setTreatMent(treatmentData)
  }

  function handleMedication(kriya: R4.ICoding) {
    const treatmentData: AyurvedaTreatment = {
      ...treatMent,
    }
    treatmentData.medication = kriya
    setTreatMent(treatmentData)
  }

  function handleNotes(event: any) {
    const treatmentData: AyurvedaTreatment = {
      ...treatMent,
    }
    treatmentData.notes = event.target.value
    setTreatMent(treatmentData)
  }

  function handleCheck(): string[] {
    const check: string[] = []
    if (treatmentType === 'range') {
      if (treatMent.endDate === undefined) {
        check.push('1')
      }
      if (treatMent.therapy === undefined) {
        check.push('1')
      }
    }
    return check
  }

  function checkForExistingTreatment(
    existingTreatment: AyurvedaTreatmentPlanData[],
    planData: AyurvedaTreatment
  ) {
    error = []
    if (existingTreatment.length > 0) {
      for (let i = 0; i < existingTreatment.length; i++) {
        if (treatmentType === 'day') {
          if (planData.date && planData.therapy) {
            const search = dateCompare(existingTreatment[i].date, planData.date)

            if (search === true) {
              let scoreData1 =
                inBetWeenTime(
                  planData.startTime!,
                  moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                    'HH:mm'
                  )
                ) ||
                isSameTherapy(
                  moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  planData.startTime!
                ) ||
                isSameTherapy(
                  moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  planData.endTime!
                )
              inBetWeenTime(
                planData.endTime!,
                moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                  'HH:mm'
                ),
                moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                  'HH:mm'
                )
              )

              if (scoreData1 === false) {
                scoreData1 = isSameOrBeforeAddEveing(
                  planData.startTime!,
                  planData.endTime!,
                  moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                    'HH:mm'
                  )
                )
                if (scoreData1 === false) {
                  scoreData1 = isSameOrBeforeBetweenAdd(
                    planData.startTime!,
                    planData.endTime!,
                    moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                      'HH:mm'
                    ),
                    moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                      'HH:mm'
                    )
                  )
                }
                if (
                  scoreData1 === true &&
                  existingTreatment[i].status !== 'Finished'
                ) {
                  error.push('1')
                }
              }
            }
          }
        }

        if (treatmentType === 'range') {
          const dates: string[] = []
          for (
            let m = moment(planData.date);
            m.isSameOrBefore(planData.endDate!);
            m.add(1, 'days')
          ) {
            dates.push(m.format('YYYY-MM-DD'))
          }

          if (planData.date && planData.therapy) {
            for (let j = 0; j < dates.length; j++) {
              const search = dateCompare(existingTreatment[i].date, dates[j])

              if (search === true) {
                let scoreData1 =
                  inBetWeenTime(
                    planData.startTime!,
                    moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                      'HH:mm'
                    ),
                    moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                      'HH:mm'
                    )
                  ) ||
                  isSameTherapy(
                    moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                      'HH:mm'
                    ),
                    planData.startTime!
                  ) ||
                  isSameTherapy(
                    moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                      'HH:mm'
                    ),
                    planData.endTime!
                  )
                inBetWeenTime(
                  planData.endTime!,
                  moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                    'HH:mm'
                  ),
                  moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                    'HH:mm'
                  )
                )

                if (scoreData1 === false) {
                  scoreData1 = isSameOrBeforeAddEveing(
                    planData.startTime!,
                    planData.endTime!,
                    moment(existingTreatment[i].startTime, ['hh:mm A']).format(
                      'HH:mm'
                    ),
                    moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                      'HH:mm'
                    )
                  )
                  if (scoreData1 === false) {
                    scoreData1 = isSameOrBeforeBetweenAdd(
                      planData.startTime!,
                      planData.endTime!,
                      moment(existingTreatment[i].startTime, [
                        'hh:mm A',
                      ]).format('HH:mm'),
                      moment(existingTreatment[i].endTime, ['hh:mm A']).format(
                        'HH:mm'
                      )
                    )
                  }
                  if (
                    scoreData1 === true &&
                    existingTreatment[i].status !== 'Finished'
                  ) {
                    error.push('1')
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    if (addtreatmentSlice.additionSuccessful) {
      setLoading(false)
      resetValues()

      dispatch(resetTreatmentState())
      dispatch(
        requestForTreatmentForAyurveda(
          fhirAppointmentDetails.mainServiceRequest.id!
        )
      )
    }
    if (deleteTreatmentSlice.updateSuccessful) {
      dispatch(resetDeleteState())
    }
  }, [dispatch, addtreatmentSlice, deleteTreatmentSlice])

  useEffect(() => {
    dispatch(
      requestForTreatmentForAyurveda(
        fhirAppointmentDetails.mainServiceRequest.id!
      )
    )
  }, [])

  return (
    <Box
      display='flex'
      borderRadius={4}
      borderColor='lightGrey'
      border={1}
      p={1}
      width='100%'
      flexDirection='column'
      flexGrow
    >
      <Box width='100%'>
        <Grid container direction='column'>
          <Grid item>
            <ReactVisibilitySensor
              onChange={(isVisible) => {
                if (isVisible) {
                  dispatch(setSelectedSection({ selectedSection: 'diagnosis' }))
                }
              }}
            >
              <Grid container direction='column'>
                <Box paddingBottom={2}>
                  <Typography variant='subtitle1'>Treatment</Typography>
                </Box>

                {treatmentSearchSlice.resultsAvailable &&
                  treatmentSearchSlice.treatmentList &&
                  treatmentSearchSlice.treatmentList.length > 0 && (
                    <Grid item xl>
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-start'
                        width='100%'
                        border={4}
                        style={{
                          boxShadow: '0px 0px 4px #ccc',
                          margin: '2px 0px',
                          borderRadius: '1px',
                          borderColor: '#F8F8F8',
                          width: '100%',
                          height: 'auto',
                          backgroundColor: '#F8F8F8',
                        }}
                      >
                        <Grid container direction='row' spacing={1}>
                          <Grid
                            container
                            direction='row'
                            id='vitals'
                            style={{
                              backgroundColor: kDialogueBackground,
                            }}
                          >
                            <Grid item xs={1}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={0.5}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Date
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  justifyContent='flex-end'
                                  flexGrow
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      minHeight: '30px',
                                      maxHeight: '100px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={0.5}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Therapy
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  justifyContent='flex-end'
                                  flexGrow
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      minHeight: '30px',
                                      maxHeight: '100px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={1}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={0.5}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Start Time
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  justifyContent='flex-end'
                                  flexGrow
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      minHeight: '30px',
                                      maxHeight: '100px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={1}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={0.5}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Duration
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  justifyContent='flex-end'
                                  flexGrow
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      minHeight: '30px',
                                      maxHeight: '100px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={0.5}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Medicine
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  justifyContent='flex-end'
                                  flexGrow
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      minHeight: '30px',
                                      maxHeight: '100px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={0.5}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Notes
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  justifyContent='flex-end'
                                  flexGrow
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      minHeight: '30px',
                                      maxHeight: '100px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={1}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={0.5}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Status
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  justifyContent='flex-end'
                                  flexGrow
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      minHeight: '30px',
                                      maxHeight: '100px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={1}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={0.5}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Performed By
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  justifyContent='flex-end'
                                  flexGrow
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      minHeight: '30px',
                                      maxHeight: '100px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={1}>
                              <Box
                                display='flex'
                                width='100%'
                                flexDirection='row'
                                justifyContent='flex-start'
                              >
                                <Typography
                                  variant='body1'
                                  color='primary'
                                  style={{ fontWeight: 600 }}
                                >
                                  {' '}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  )}

                {treatmentSearchSlice.resultsAvailable &&
                  treatmentSearchSlice.treatmentList &&
                  treatmentSearchSlice.treatmentList.length > 0 && (
                    <Grid item xl>
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-start'
                        width='100%'
                        border={4}
                        style={{
                          boxShadow: '0px 0px 4px #ccc',
                          margin: '2px 0px',
                          borderRadius: '1px',
                          borderColor: '#F8F8F8',
                          width: '100%',
                          height: 'auto',
                          backgroundColor: '#F8F8F8',
                        }}
                      >
                        <Grid container direction='row' spacing={1}>
                          {treatmentSearchSlice.treatmentList.map(
                            (
                              treatmentData: AyurvedaTreatmentPlanData,
                              index: number
                            ) => (
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                // style={{
                                //   backgroundColor:
                                //     index % 2 ? kDialogueBackground : 'white',
                                // }}
                              >
                                {treatmentData.treatmentPlan &&
                                  treatmentData.treatmentPlan.status !==
                                    'unknown' && (
                                    <Box
                                      width='100%'
                                      //   style={{
                                      //     backgroundColor:
                                      //       index % 2 ? kDialogueBackground : 'white',
                                      //   }}
                                    >
                                      <Grid
                                        container
                                        direction='row'
                                        id='vitals'
                                        style={{
                                          backgroundColor:
                                            index % 2
                                              ? kDialogueBackground
                                              : 'white',
                                        }}
                                      >
                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={0.5}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 10,

                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {moment(
                                                    treatmentData.date
                                                  ).format('DD-MM-YYYY')}
                                                </Typography>
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              justifyContent='flex-end'
                                              flexGrow
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  minHeight: '40px',
                                                  maxHeight: '100px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={0.5}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 10,

                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {treatmentData.therapy}{' '}
                                                  {treatmentData.treatmentType
                                                    ? `- ${treatmentData.treatmentType}`
                                                    : ''}
                                                </Typography>
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              justifyContent='flex-end'
                                              flexGrow
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  minHeight: '40px',
                                                  maxHeight: '100px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={0.5}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 10,

                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {treatmentData.startTime}
                                                </Typography>
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              justifyContent='flex-end'
                                              flexGrow
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  minHeight: '40px',
                                                  maxHeight: '100px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={0.5}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 10,

                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {treatmentData.duration}
                                                </Typography>
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              justifyContent='flex-end'
                                              flexGrow
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  minHeight: '40px',
                                                  maxHeight: '100px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={0.5}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    paddingLeft: 2,
                                                    cursor: 'pointer',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 10,

                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {treatmentData.medication ??
                                                    ''}
                                                </Typography>
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              justifyContent='flex-end'
                                              flexGrow
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  minHeight: '40px',
                                                  maxHeight: '100px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>

                                        <Grid item xs={2}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={0.5}
                                              >
                                                {treatmentData.treatmentPlan && (
                                                  <Box>
                                                    <Typography
                                                      variant='body2'
                                                      color='initial'
                                                      style={{
                                                        cursor: 'pointer',
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 10,

                                                        lineHeight: 'normal',
                                                      }}
                                                      onClick={() => {
                                                        if (
                                                          getNotesFromTherapy(
                                                            treatmentData.treatmentPlan!
                                                          ).length > 60
                                                        ) {
                                                          setFullText(
                                                            getNotesFromTherapy(
                                                              treatmentData.treatmentPlan!
                                                            )
                                                          )
                                                          setShowTextForNotes(
                                                            true
                                                          )
                                                        }
                                                      }}
                                                    >
                                                      {getNotesFromTherapy(
                                                        treatmentData.treatmentPlan!
                                                      ).slice(0, 60)}
                                                      {getNotesFromTherapy(
                                                        treatmentData.treatmentPlan!
                                                      ).length > 60
                                                        ? '...'
                                                        : null}
                                                    </Typography>

                                                    {/* <Typography
                                                  variant='body1'
                                                  color='initial'
                                                  style={{
                                                    paddingLeft: 2,
                                                    cursor: 'pointer',
                                                  }}
                                                  //   component=''
                                                  onClick={() => {
                                                    if (
                                                      getNotes(
                                                        treatmentData.procedure
                                                      ).length > 60
                                                    ) {
                                                      setFullText(
                                                        ipdAppointmentDetails.notes
                                                      )
                                                      setShowText(true)
                                                    }
                                                  }}
                                                >
                                                  {ipdAppointmentDetails.notes.slice(
                                                    0,
                                                    60
                                                  )}
                                                </Typography> */}
                                                  </Box>
                                                )}
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              justifyContent='flex-end'
                                              flexGrow
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  minHeight: '40px',
                                                  maxHeight: '100px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>

                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='row'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                paddingLeft={0.4}
                                                paddingRight={0.7}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color={
                                                    (treatmentData.treatmentPlan &&
                                                      treatmentData
                                                        .treatmentPlan
                                                        .status === 'active') ||
                                                    (treatmentData.treatmentPlan &&
                                                      treatmentData
                                                        .treatmentPlan
                                                        .status === 'completed')
                                                      ? 'primary'
                                                      : 'error'
                                                  }
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 10,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {treatmentData.procedure ===
                                                    undefined && (
                                                    <Box
                                                      width='100%'
                                                      flexDirection='row'
                                                    >
                                                      <Typography
                                                        variant='body2'
                                                        color={
                                                          (treatmentData.treatmentPlan &&
                                                            treatmentData
                                                              .treatmentPlan
                                                              .status ===
                                                              'active') ||
                                                          (treatmentData.treatmentPlan &&
                                                            treatmentData
                                                              .treatmentPlan
                                                              .status ===
                                                              'completed') ||
                                                          treatmentData.procedure
                                                            ? 'primary'
                                                            : 'error'
                                                        }
                                                        style={{
                                                          fontFamily:
                                                            'Open Sans',
                                                          fontSize: 10,

                                                          fontWeight: 600,
                                                          lineHeight: 'normal',
                                                        }}
                                                      >
                                                        {' '}
                                                        {treatmentData.treatmentPlan &&
                                                          treatmentData.procedure ===
                                                            undefined &&
                                                          getOrderStatusText(
                                                            treatmentData.treatmentPlan
                                                          )}
                                                      </Typography>
                                                    </Box>
                                                  )}

                                                  {treatmentData.procedure && (
                                                    <Typography
                                                      variant='body2'
                                                      color='primary'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 10,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {' '}
                                                      Completed
                                                    </Typography>
                                                  )}
                                                </Typography>
                                                {treatmentData.procedure ===
                                                  undefined &&
                                                  treatmentData.treatmentPlan &&
                                                  getOrderStatusText(
                                                    treatmentData.treatmentPlan
                                                  ) === 'Cancelled' && (
                                                    <Box paddingLeft={0.5}>
                                                      <Tooltip
                                                        title={
                                                          treatmentData
                                                            .treatmentPlan
                                                            .reasonCode &&
                                                          treatmentData
                                                            .treatmentPlan
                                                            .reasonCode.length >
                                                            0
                                                            ? treatmentData
                                                                .treatmentPlan
                                                                .reasonCode[0]
                                                                .coding &&
                                                              treatmentData
                                                                .treatmentPlan
                                                                .reasonCode[0]
                                                                .coding.length >
                                                                0
                                                              ? (treatmentData
                                                                  .treatmentPlan
                                                                  .reasonCode[0]
                                                                  .coding &&
                                                                  treatmentData
                                                                    .treatmentPlan
                                                                    .reasonCode[0]
                                                                    .coding[0]
                                                                    .display) ??
                                                                ''
                                                              : ''
                                                            : ''
                                                        }
                                                        placement='right-end'
                                                      >
                                                        <IconButton
                                                          id='performed_time'
                                                          size='small'
                                                          style={{ padding: 0 }}
                                                          aria-label='info'
                                                          onClick={() => {
                                                            //   setShowTherapyDetails(
                                                            //     true
                                                            //   )
                                                          }}
                                                        >
                                                          <Info />
                                                        </IconButton>
                                                      </Tooltip>
                                                    </Box>
                                                  )}
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              justifyContent='flex-end'
                                              flexGrow
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  minHeight: '40px',
                                                  maxHeight: '100px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={0.5}
                                              >
                                                {treatmentData.treatmentPlan &&
                                                  treatmentData.treatmentPlan
                                                    .status === 'revoked' && (
                                                    <Typography
                                                      variant='subtitle2'
                                                      color='initial'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 10,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {treatmentData
                                                        .treatmentPlan.performer
                                                        ? treatmentData
                                                            .treatmentPlan
                                                            .performer[0]
                                                            .display ?? ''
                                                        : ''}
                                                    </Typography>
                                                  )}
                                                {treatmentData.procedure && (
                                                  <Typography
                                                    variant='subtitle1'
                                                    color='initial'
                                                    style={{
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 10,

                                                      fontWeight: 600,
                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    {' '}
                                                    {treatmentData.procedure.performer
                                                      ?.map(
                                                        (e) =>
                                                          e.actor.display ?? ''
                                                      )
                                                      .join(', ')}
                                                  </Typography>
                                                )}
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              width='2%'
                                              justifyContent='flex-end'
                                              flexGrow
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  minHeight: '40px',
                                                  maxHeight: '100px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>

                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            width='100%'
                                            flexDirection='row'
                                            justifyContent='center'
                                            py={1}
                                          >
                                            {treatmentData.procedure ===
                                              undefined &&
                                              treatmentData.treatmentPlan &&
                                              treatmentData.treatmentPlan
                                                .status !== 'revoked' &&
                                              status !== 'completed' && (
                                                <DeleteOutlineOutlined
                                                  fontSize='small'
                                                  style={{
                                                    cursor: 'pointer',
                                                  }}
                                                  id={`del${index}`}
                                                  onClick={() => {
                                                    setShowDeleteConfirmation({
                                                      open: true,
                                                      onContinueActionClicked:
                                                        () => {
                                                          setShowDeleteConfirmation(
                                                            {
                                                              open: false,
                                                            }
                                                          )
                                                          if (
                                                            treatmentData &&
                                                            treatmentData.treatmentPlan
                                                          ) {
                                                            dispatch(
                                                              deleteTherapy(
                                                                treatmentData.treatmentPlan,
                                                                fhirAppointmentDetails,
                                                                'ayurveda'
                                                              )
                                                            )
                                                          }
                                                        },
                                                      onCancelActionClicked:
                                                        () => {
                                                          setShowDeleteConfirmation(
                                                            {
                                                              open: false,
                                                            }
                                                          )
                                                        },
                                                      displayText:
                                                        'Are you sure you want to delete this record?',
                                                      continueActionButtonText:
                                                        'Delete',
                                                      cancelActionButtonText:
                                                        'Cancel',
                                                    })
                                                  }}
                                                />
                                              )}
                                          </Box>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        direction='row'
                                        id='vitals'
                                      >
                                        <Grid item xs={12}>
                                          <Box width='100%' paddingLeft={0.2}>
                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  )}
                              </Box>
                            )
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  )}
                <Box paddingTop={2} />
                <Grid item xl>
                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='row'
                    style={{
                      backgroundColor: kDialogueBackground,
                    }}
                    minHeight={30}
                  >
                    <Box
                      width={treatmentType === 'day' ? '15%' : '25%'}
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      paddingLeft={2}
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {' '}
                        Date
                      </Typography>
                    </Box>
                    <Box
                      width={treatmentType === 'day' ? '22%' : '17%'}
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {' '}
                        Therapy
                      </Typography>
                    </Box>
                    <Box
                      width='12%'
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {' '}
                        Start Time
                      </Typography>
                    </Box>
                    <Box
                      width='8%'
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {' '}
                        Duration
                      </Typography>
                    </Box>
                    <Box
                      width={treatmentType === 'day' ? '23%' : '20%'}
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {' '}
                        Medicine
                      </Typography>
                    </Box>
                    <Box
                      width='15%'
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {' '}
                        Notes
                      </Typography>
                    </Box>

                    <Box
                      width='5%'
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                    />
                  </Box>
                </Grid>
                <Grid item xl>
                  <Box width='100%' display='flex' flexDirection='column'>
                    <Box width='100%' display='flex' flexDirection='row'>
                      <Box
                        width='20%'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        paddingLeft={2}
                      >
                        <FormControl>
                          <RadioGroup
                            id='add_unit_address_choice_rad_group'
                            row
                            aria-label='graphType'
                            value={treatmentType}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setTreatMent({
                                id: getUniqueTempId(),
                                date: moment(moment()).format('YYYY-MM-DD'),
                                startTime: moment(moment()).format('HH:mm'),
                                therapy: undefined,
                                endTime: undefined,
                                endDate: undefined,
                                medication: undefined,
                                notes: undefined,
                                duration: undefined,
                              })
                              setTreatmentType(
                                (event.target as HTMLInputElement).value
                              )
                            }}
                          >
                            <Box>
                              <FormControlLabel
                                value='day'
                                control={<Radio color='primary' />}
                                label={
                                  <Typography variant='subtitle2'>
                                    One Day
                                  </Typography>
                                }
                              />
                              <FormControlLabel
                                value='range'
                                control={<Radio color='primary' />}
                                label={
                                  <Typography variant='subtitle2'>
                                    Date Range
                                  </Typography>
                                }
                              />
                            </Box>
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                    {loading === false && (
                      <Box
                        width='100%'
                        display='flex'
                        flexDirection='row'
                        py={1.5}
                      >
                        <Box
                          width={treatmentType === 'day' ? '15%' : '25%'}
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          paddingLeft={0.5}
                        >
                          {treatmentType === 'day' && (
                            <Box width='70%'>
                              <WelloTextFieldWithoutTitle
                                textProps={{
                                  id: `treatment_date`,
                                  value: treatMent.date,

                                  inputProps: {
                                    min: checkinDate,
                                    max: checkOutDate,
                                  },
                                  onKeyDown: (e) => {
                                    e.preventDefault()
                                  },

                                  type: 'date',
                                  onChange: (changeData) => {
                                    handleDateChange(changeData)
                                  },
                                }}
                              />
                            </Box>
                          )}
                          <Box display='flex' flexDirection='row' width='100%'>
                            {treatmentType === 'range' && (
                              <Box width='50%' px={1}>
                                <WelloTextFieldWithoutTitle
                                  textProps={{
                                    id: `treatment_date`,
                                    value: treatMent.date,

                                    inputProps: {
                                      min: checkinDate,
                                      max: checkOutDate,
                                    },
                                    onKeyDown: (e) => {
                                      e.preventDefault()
                                    },

                                    type: 'date',
                                    onChange: (changeData) => {
                                      handleDateChange(changeData)
                                    },
                                  }}
                                />
                              </Box>
                            )}

                            {treatmentType === 'range' && (
                              <Box width='50%' px={1}>
                                <WelloTextFieldWithoutTitle
                                  textProps={{
                                    id: `treatment_date`,
                                    value: treatMent.endDate,

                                    inputProps: {
                                      min: treatMent.date,
                                      max: checkOutDate,
                                    },
                                    onKeyDown: (e) => {
                                      e.preventDefault()
                                    },

                                    type: 'date',
                                    onChange: (changeData) => {
                                      handleEndDateChange(changeData)
                                    },
                                  }}
                                />
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <Box
                          width={treatmentType === 'day' ? '22%' : '17%'}
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          px={1}
                        >
                          <TherapySelectorForPlan
                            showPrice={true}
                            id='treatment_list'
                            title=''
                            appointmentTypeDataVal={appointmentTypeData}
                            availableCodings={
                              appointmentTypeData === '304903009' ||
                              appointmentTypeData === '33022008'
                                ? kriyaSearchPriceSlice.therapyList ?? []
                                : kriyaSearchPriceSlice.therapyList ?? []
                            }
                            onChanged={(e) => {
                              if (e) {
                                handleTherapyChange(e)
                              }
                            }}
                            // textFieldProps={{
                            //   size: 'small',
                            // }}
                          />
                        </Box>

                        <Box
                          width='12%'
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          px={1}
                        >
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size='small'
                                  disabled={!isAfterSame('05:00', ',')}
                                  id='obj_12'
                                />
                              )}
                              value={start}
                              onChange={(newValue) => {
                                handleTime(newValue)
                              }}
                              minTime={
                                isCurrentDate(treatMent.date)
                                  ? new Date(
                                      `${currentDate} ${moment(moment(), [
                                        'hh:mm A',
                                      ]).format('HH:mm')}`
                                    )
                                  : new Date(0, 0, 0, 5)
                              }
                              maxTime={maxDate}
                            />
                          </LocalizationProvider>
                        </Box>
                        <Box
                          width='8%'
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          px={1}
                        >
                          <TextField
                            variant='outlined'
                            fullWidth
                            type='number'
                            id='treatment_duration'
                            size='small'
                            disabled={true}
                            value={treatMent.duration}
                            onKeyDown={(e: any) => {
                              e.preventDefault()
                            }}
                            style={{ padding: 0 }}
                            InputProps={{
                              style: { textAlign: 'center', margin: 0 },
                              endAdornment: (
                                <InputAdornment
                                  position='end'
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  {' '}
                                  <Typography variant='subtitle2'>
                                    Min
                                  </Typography>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {/* <WelloTextFieldWithoutTitle
                            textProps={{
                              id: `treatment_duration`,
                              value: treatMent.duration,

                              onKeyDown: (e) => {
                                e.preventDefault()
                              },
                              //   disabled: true,

                              type: 'number',
                              onChange: (changeData) => {
                                handleDateChange(changeData)
                              },
                              inputProps: {
                                endAdornment: (
                                  <InputAdornment position='start'>
                                    {' '}
                                    <Typography>Min</Typography>
                                  </InputAdornment>
                                ),
                              },
                            }}

                            // }}
                          /> */}
                        </Box>

                        <Box
                          width={treatmentType === 'day' ? '23%' : '20%'}
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          px={1}
                          style={{ position: 'relative' }}
                        >
                          <ValueSetSelectSingle
                            key={`${defaultMedicineCategoryUrl}medicines`}
                            title=''
                            url={`http://wellopathy.com/fhir/india/core/ValueSet/${defaultMedicineCategoryUrl}`}
                            id='kriyas'
                            fuzzySearch={true}
                            disabled={false}
                            onOptionSelected={(selectedKriya) => {
                              if (selectedKriya) {
                                handleMedication(selectedKriya)
                              }
                            }}
                            helperText={undefined}
                            isAyurveda={true}
                          />
                        </Box>
                        <Box
                          width='15%'
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          px={1}
                        >
                          <TextField
                            size='small'
                            fullWidth
                            multiline
                            type='string'
                            variant='outlined'
                            value={treatMent.notes}
                            onChange={(event) => {
                              handleNotes(event)
                            }}
                          />
                        </Box>
                        <Box
                          width='5%'
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          paddingRight={1}
                        >
                          <Button
                            variant='contained'
                            size='small'
                            color='primary'
                            disabled={
                              treatMent.therapy === undefined ||
                              treatMent.startTime === undefined ||
                              treatMent.endTime === undefined ||
                              treatMent.duration === undefined ||
                              handleCheck().length > 1
                            }
                            onClick={() => {
                              if (
                                treatmentSearchSlice.treatmentList &&
                                treatmentSearchSlice.treatmentList.length > 0
                              ) {
                                checkForExistingTreatment(
                                  treatmentSearchSlice.treatmentList,
                                  treatMent
                                )
                              }

                              if (error.length > 0) {
                                setShowText(true)
                              } else {
                                setLoading(true)
                                dispatch(
                                  addTreatmentDetailsForAyurveda(
                                    treatMent,
                                    fhirAppointmentDetails,
                                    treatmentType
                                  )
                                )
                              }
                            }}
                          >
                            <Typography variant='subtitle2'>Save</Typography>
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>

                {treatmentSearchSlice.searchingTreatment && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      align='center'
                    >
                      <CircularProgress size={25} />
                    </Typography>
                  </Box>
                )}

                {treatmentSearchSlice.errorWhileSearchingTreatment && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='error'
                      align='center'
                    >
                      Some Error has occurred
                    </Typography>
                  </Box>
                )}

                {treatmentSearchSlice.noResultsAvailable && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      align='center'
                    >
                      Treatment Plans not available
                    </Typography>
                  </Box>
                )}

                {/* {treatmentSearchSlice.resultsAvailable &&
                  treatmentSearchSlice.treatmentList &&
                  treatmentSearchSlice.treatmentList.length === 0 && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='100%'
                      height='100%'
                      alignContent='center'
                    >
                      <Typography
                        variant='subtitle1'
                        color='initial'
                        align='center'
                      >
                        {isUnitAdmin() || status === 'completed'
                          ? 'No data available'
                          : 'Add Treatment Plan'}
                      </Typography>
                    </Box>
                  )} */}

                {addtreatmentSlice.adding && (
                  <WelloLoadingIndicator message='Adding details' />
                )}
              </Grid>
            </ReactVisibilitySensor>
          </Grid>
        </Grid>
      </Box>

      {showDeleteConfirmation.open && (
        <ConfirmationDialog {...showDeleteConfirmation} />
      )}
      <AlertDialog
        open={showText}
        onClose={() => {
          //   resetValues()
          setShowText(false)
        }}
        notesData='This time slot is already booked'
      />

      <NoteDialog
        id='680'
        open={showTextForNotes}
        onClose={() => {
          setFullText('')
          setShowTextForNotes(false)
        }}
        notesData={fullText!}
        dialogText='Notes'
      />
      <AlertDialog
        open={showTextForSlot}
        onClose={() => {
          setShowTextForSlot(false)
        }}
        notesData='Please choose slot in given time frame'
      />

      <AlertDialog
        open={showTextForMorning}
        onClose={() => {
          setShowTextForMorning(false)
        }}
        notesData='Please choose morning slot in given time frame '
      />

      <AlertDialog
        open={showTextForMidMorning}
        onClose={() => {
          setShowTextForMidMorning(false)
        }}
        notesData='Please choose mid morning slot in given time frame '
      />

      <AlertDialog
        open={showTextForAfterNoon}
        onClose={() => {
          setShowTextForAfterNoon(false)
        }}
        notesData='Please choose afternoon slot in given time frame '
      />
      <AlertDialog
        open={showTextForEvening}
        onClose={() => {
          setShowTextForEvening(false)
        }}
        notesData='Please choose evening slot in given time frame '
      />
    </Box>
  )
}
