import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { getNameOfPatient } from 'wello-web-components'
import { ConditionAddStatus } from './addConditionStatusTypes'

const initialState: ConditionAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const historyConditionSliceIPD = createSlice({
  name: 'addHistoryCondition',
  initialState,
  reducers: {
    updateAddConditionStatus(state, action: PayloadAction<ConditionAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.condition = action.payload.condition
    },

    resetPatientDetails(state, action: PayloadAction<ConditionAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.condition = initialState.condition
    },
  },
})

export const addHistoryConditionDetails =
  (
    patientDetails: R4.IPatient,
    conditionCode: R4.ICoding,
    practitionerDetail: R4.IPractitioner,
    practitionerRoleDetail: R4.IPractitionerRole,
    severity: R4.ICoding,
    onSetDate: Date,
    isActive: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: ConditionAddStatus = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(
      historyConditionSliceIPD.actions.updateAddConditionStatus(addingState)
    )

    try {
      const practioner: R4.IPractitioner = getCurrentUserPractitionerDetails()
      const practitionerRole: R4.IPractitionerRole =
        getCurrentUserPractitionerRoleDetails()
      const condition: R4.ICondition = {
        resourceType: 'Condition',
        subject: {
          display: getNameOfPatient(patientDetails),
          id: patientDetails.id,
          reference: `${patientDetails.resourceType}/${patientDetails.id}`,
          type: patientDetails.resourceType,
        },
        code: {
          coding: [conditionCode],
          text: conditionCode.display,
        },

        onsetDateTime: onSetDate.toISOString(),
        clinicalStatus: {
          coding: [
            {
              code: isActive ? 'active' : 'inactive',
              system: 'http://terminology.hl7.org/ValueSet/condition-clinical',
              display: isActive ? 'Active' : 'Inactive',
            },
          ],
        },
        recorder: {
          display: getNameFromHumanName(practioner.name ?? []),
          id: practitionerRole.id,
          reference: `${practitionerRole.resourceType}/${practitionerRole.id}`,
          type: practitionerRole.resourceType,
        },
        severity: {
          coding: [severity],
        },
        recordedDate: new Date().toISOString(),
        category: [
          {
            coding: [
              {
                code: 'problem-list-item',
                display: 'Problem List Item',
                system:
                  'http://terminology.hl7.org/CodeSystem/condition-category',
              },
            ],
          },
        ],
        verificationStatus: {
          text: 'Unconfirmed',
          coding: [
            {
              code: 'unconfirmed',
              display: 'Unconfirmed',
              system:
                'http://terminology.hl7.org/CodeSystem/condition-ver-status',
            },
          ],
        },
      }
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doCreateFHIRResourceRequest(
        `/${condition.resourceType}`,
        condition
      )
      const respDecoded: E.Either<Errors, R4.ICondition> =
        R4.RTTI_Condition.decode(response)
      if (respDecoded._tag === 'Right') {
        const patientResponse: R4.ICondition = respDecoded.right
        addingState = {
          adding: false,
          additionSuccessful: true,
          condition: patientResponse,
          error: false,
          errorMessage: '',
        }
        dispatch(
          historyConditionSliceIPD.actions.updateAddConditionStatus(addingState)
        )
      } else {
        const errorCreatePersonState: ConditionAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while creating patient',
        }
        dispatch(
          historyConditionSliceIPD.actions.updateAddConditionStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: ConditionAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding',
      }
      dispatch(
        historyConditionSliceIPD.actions.updateAddConditionStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetAddConditionState = () => (dispatch: AppDispatch) => {
  dispatch(historyConditionSliceIPD.actions.resetPatientDetails(initialState))
}

export default historyConditionSliceIPD.reducer
