/* eslint-disable react/display-name */
import MaterialTable, { MTableToolbar } from '@material-table/core'
import {
  Box,
  InputAdornment,
  makeStyles,
  Paper,
  TablePagination,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useWindowDimensions from 'utils/hooks/useDimensionHook'

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      // padding: 4,
    },
  },
})

interface ICardProperties {
  usersData: PractitionerWithRole[]
  onUserSelected?: (selectedDetail: PractitionerWithRole) => void
}

export const OrgUserDataTable: React.FC<ICardProperties> = ({
  usersData,
  onUserSelected,
}: ICardProperties) => {
  const classes = useStyles()
  const convertedData = usersData
  const [data, setData] = useState([...convertedData])
  const { height, width } = useWindowDimensions()
  const [selectedRowId, setSelectedRowId] = React.useState('')
  const [selectedRow, setSelectedRow] = React.useState(false)
  const [openPartnerLabPopup, setOpenPartnerLabPopup] = useState(false)
  const { t } = useTranslation()

  const [nameFilter, setNameFilter] = useState('')
  const [nameFocus, setNameFocus] = useState(true)
  const [eanbleFilter, setEnableFilter] = useState('')
  const [enableFocus, setEnableFocus] = useState(false)
  const [phoneFilter, setPhoneFilter] = useState('')
  const [phoneFocus, setPhoneFocus] = useState(false)
  const [emailFilter, setEmailFilter] = useState('')
  const [emailFocus, setEmailFocus] = useState(false)
  const [roleFilter, setRoleFilter] = useState('')
  const [roleFocus, setRoleFocus] = useState(false)
  const [statusFilter, setStatusFilter] = useState('')
  const [statusFocus, setStatusFocus] = useState(false)
  const [unitNameFilter, setUnitNameFilter] = useState('')
  const [unitNameFocus, setUnitNameFocus] = useState(false)

  const handleDarkModeChange = () => {
    // setPreferDarkMode(!preferDarkMode)
    // localStorage.setItem('_tableDarkMode', !preferDarkMode)
  }

  const filterName = (value: any, filed: string) => {
    setNameFocus(true)
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.fullName
          .replace(/\s+/g, '')
          .toLowerCase()
          .includes(value.replace(/\s+/g, '').toLowerCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setNameFilter(value)
    setEnableFilter('')
    setEnableFocus(false)
    setPhoneFocus(false)
    setEmailFocus(false)
    setRoleFocus(false)
    setStatusFocus(false)
    setUnitNameFocus(false)
  }

  const filterUnitName = (value: any, filed: string) => {
    setNameFocus(true)
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d
          .unitName!.replace(/\s+/g, '')
          .toLowerCase()
          .includes(value.replace(/\s+/g, '').toLowerCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setUnitNameFilter(value)
    setEnableFilter('')
    setEnableFocus(false)
    setPhoneFocus(false)
    setEmailFocus(false)
    setRoleFocus(false)
    setStatusFocus(false)
    setUnitNameFocus(true)
  }

  const filterPhone = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.phone.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setNameFocus(false)
    setPhoneFocus(true)
    setEnableFocus(false)
    setPhoneFilter(value)
    setEmailFocus(false)
    setRoleFocus(false)
    setStatusFocus(false)
    setUnitNameFocus(false)
  }

  const filterEmail = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.primaryContact.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )

      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setNameFocus(false)
    setPhoneFocus(false)
    setEmailFocus(true)
    setEnableFocus(false)
    setEmailFilter(value)
    setRoleFocus(false)
    setStatusFocus(false)
    setUnitNameFocus(false)
  }

  const filterRole = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.role!.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )

      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setNameFocus(false)
    setPhoneFocus(false)
    setEmailFocus(true)
    setEnableFocus(false)
    setRoleFilter(value)
    setRoleFocus(true)
    setStatusFocus(true)
    setUnitNameFocus(false)
  }

  const filterEnabled = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedData.filter((d) => d.enabled === value)

      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setNameFocus(false)
    setPhoneFocus(false)
    setEmailFocus(true)
    setEnableFocus(true)
    setEnableFilter(value)
    setRoleFocus(true)
    setStatusFocus(true)
    setUnitNameFocus(false)
  }

  const filterStatus = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.status.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )

      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setNameFocus(false)
    setPhoneFocus(false)
    setEmailFocus(true)
    setEnableFocus(false)
    setStatusFilter(value)
    setRoleFocus(false)
    setStatusFocus(true)
    setUnitNameFocus(false)
  }

  const columns = [
    {
      title: (
        <Box minWidth='150px' minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            {t('labelCommon:userName')}
          </Typography>
        </Box>
      ),
      field: 'fullName',
      customSort: (a: any, b: any) =>
        a.fullName > b.fullName ? 1 : b.fullName > a.fullName ? -1 : 0,

      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (rowData: any) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <Typography variant='subtitle2' noWrap>
            {rowData.fullName}
          </Typography>
        </Box>
      ),
      width: 250,
      filterComponent: (props: any) => (
        <Box maxWidth={200}>
          <TextField
            variant='outlined'
            size='small'
            id='list3'
            autoFocus={nameFocus}
            placeholder='Name'
            value={nameFilter}
            onChange={(e) => filterName(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box minWidth='150px' minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            {' '}
            Unit
          </Typography>
        </Box>
      ),
      field: 'unitName',
      customSort: (a: any, b: any) =>
        a.unitName > b.unitName ? 1 : b.unitName > a.unitName ? -1 : 0,

      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (rowData: any) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <Typography variant='subtitle2' noWrap>
            {rowData.unitName ?? ''}
          </Typography>
        </Box>
      ),
      width: 250,
      filterComponent: (props: any) => (
        <Box maxWidth={200}>
          <TextField
            variant='outlined'
            size='small'
            id='list3'
            autoFocus={unitNameFocus}
            placeholder='Unit'
            value={unitNameFilter}
            onChange={(e) =>
              filterUnitName(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            color='primary'
            style={{
              fontSize: 14,
              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            noWrap
          >
            Email
          </Typography>
        </Box>
      ),
      field: 'primaryContact',
      render: (rowData: any) => (
        <Box>
          {rowData.primaryContact !== undefined && (
            <Typography variant='subtitle2' color='textPrimary' noWrap>
              {rowData.primaryContact}
            </Typography>
          )}
        </Box>
      ),
      width: 250,
      filterComponent: (props: any) => (
        <Box width='105%'>
          <TextField
            variant='outlined'
            size='small'
            id='list18'
            autoFocus={emailFocus}
            placeholder='Email'
            value={emailFilter}
            onChange={(e) => filterEmail(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
            noWrap
          >
            Phone
          </Typography>
        </Box>
      ),
      field: 'phone',

      render: (rowData: any) => (
        <Box paddingY={1}>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            {rowData.phone}
          </Typography>
        </Box>
      ),
      width: 250,
      filterComponent: (props: any) => (
        <Box maxWidth={250}>
          <TextField
            variant='outlined'
            size='small'
            id='list15'
            autoFocus={phoneFocus}
            placeholder='Phone'
            value={phoneFilter}
            onChange={(e) => filterPhone(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            &nbsp;{t('labelCommon:Status')}
          </Typography>
        </Box>
      ),
      field: 'status',
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Box paddingLeft={0.5} display='flex' alignItems='center'>
            <Typography variant='subtitle2' color='textPrimary' noWrap>
              {rowData.status}
            </Typography>
          </Box>
        </Box>
      ),
      width: 150,
      filterComponent: (props: any) => (
        <Box>
          <TextField
            variant='outlined'
            size='small'
            id='list18'
            autoFocus={statusFocus}
            placeholder='Status'
            value={statusFilter}
            onChange={(e) =>
              filterStatus(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box minWidth='150px' minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Enabled
          </Typography>
        </Box>
      ),
      field: 'enabled',
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Box paddingLeft={0.5} display='flex' alignItems='center'>
            <Typography variant='subtitle2' color='textPrimary' noWrap>
              {rowData.enabled === true ? 'Yes' : 'No'}
            </Typography>
          </Box>
        </Box>
      ),
      width: 100,
      filterComponent: (props: any) => (
        <Box>
          <TextField
            variant='outlined'
            size='small'
            id='list18'
            autoFocus={enableFocus}
            placeholder='Enabled'
            value={eanbleFilter}
            onChange={(e) =>
              filterEnabled(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
  ]

  return (
    <Box id='list39'>
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                minHeight: '0px',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
          Container: (props) => <Paper {...props} elevation={0} />,
          Pagination: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                display: 'flex',
                justifyContent: 'left',
                // padding: '0px',
              }}
            >
              <TablePagination
                {...props}
                labelRowsSelect={<div style={{ fontSize: 14 }}> rows</div>}
                SelectProps={{
                  style: {
                    fontSize: 14,
                    minHeight: '5px',
                    backgroundColor: useTheme().palette.background.default,
                  },
                }}
              />
            </div>
          ),
        }}
        columns={columns}
        data={data}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        onRowClick={(event, rowData) => {
          //   if (rowData) onUserSelected(rowData)
          //   setSelectedRow(true)
          //   setSelectedRowId(rowData?.id ?? '')
        }}
        options={{
          searchFieldStyle: { padding: 4 },
          toolbar: false,
          search: false,
          showTitle: false,
          padding: 'dense',
          searchFieldVariant: 'outlined',
          filtering: true,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30],
          //   maxBodyHeight: `${height - 169}px`,
          //   minBodyHeight: `${height - 240}px`,
          tableLayout: 'auto',
          sorting: true,
          thirdSortClick: false,
          headerStyle: {
            backgroundColor: useTheme().palette.background.default,
            color: '#FFF',
            width: 100,
            minHeight: '15px',
            maxHeight: '15px',
            position: 'sticky',
            top: 0,
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          rowStyle: (_data: any, index: number, _level: number) =>
            index % 2
              ? selectedRow && _data.id === selectedRowId
                ? { backgroundColor: '#9E9DDC', height: 50 }
                : { backgroundColor: kDialogueBackground }
              : selectedRow && _data.id === selectedRowId
              ? { backgroundColor: '#9E9DDC', height: 50 }
              : {},
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />
    </Box>
  )
}
