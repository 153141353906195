import _, { debounce } from 'lodash'
import React, { useCallback } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Divider from '@mui/material/Divider'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'

import { R4 } from '@ahryman40k/ts-fhir-types'
import { getCodesOfValueSetFromTerminologyService } from 'utils/valusetsHelpers'
import match from 'autosuggest-highlight/match'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Tooltip,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  IconButton,
  Checkbox,
  withStyles,
  Switch,
} from '@material-ui/core'
import Info from '@material-ui/icons/Info'
import {
  getDisplay,
  getHoverText,
} from 'utils/fhirResoureHelpers/organizationHelpers'
import {
  getDisplayForQuestion,
  getOrderedList,
  titleCase,
} from 'utils/fhirResourcesHelper'
import { WelloFormItemLabel } from '../LeftMenu/WelloFormItemLabel'

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: '#9E9DDC',
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)

export interface ValueSetSearchProps {
  id: string
  url: string
  title: string
  preSelectedOptions?: R4.ICoding[]
  preEnteredText?: string
  onOptionSelected: (selectedOption: R4.ICoding[]) => void
  helperText?: string
  disabled: boolean
  fuzzySearch?: boolean
  alwaysShowAllOptions?: boolean
  error?: boolean
  onLoading?: (loaded: boolean) => void
}

export const ValueSetSelectMultipleWithSwitch: React.FC<ValueSetSearchProps> =
  ({
    id,
    url,
    title,
    preSelectedOptions,
    preEnteredText,
    onOptionSelected,
    helperText,
    disabled,
    fuzzySearch = false,
    alwaysShowAllOptions = false,
    error,
    onLoading,
  }) => {
    const [value, setValue] = React.useState<R4.ICoding[]>(
      preSelectedOptions ?? []
    )
    const [inputValue, setInputValue] = React.useState('')
    const [options, setOptions] = React.useState<R4.ICoding[]>([])
    const [optionsRemaining, setOptionsRemaining] = React.useState<
      R4.ICoding[]
    >([])
    const [loading, setLoading] = React.useState(false)
    const [open, setOpen] = React.useState<boolean>(!_.isEmpty(preEnteredText))

    const handleChange = (event: any, condition: R4.ICoding, index: number) => {
      const values: R4.ICoding[] = [...value]

      if (event.target.checked === true) {
        const finalData = values.filter(
          (d: R4.ICoding) => d.code === condition.code
        )
        if (finalData.length === 0) {
          values.push(condition)
        }
      } else {
        const indexData = values.findIndex(
          (x) => x.code && x.code === condition.code
        )
        if (indexData > -1) {
          // only splice array when item is found
          values.splice(indexData, 1) // 2nd parameter means remove one item only
        }
      }

      setValue(values)
      onOptionSelected(values)
    }

    const loadOptions = useCallback(
      debounce((inputText, callback) => {
        getCodesOfValueSetFromTerminologyService({
          valueSetUrl: url,
          fuzzySearch,
          searchString: inputText,
        }).then((response: R4.ICoding[]) => {
          callback(response)
        })
      }, 1000),
      []
    )

    React.useEffect(() => {
      setLoading(true)
      loadOptions(inputValue, (response: R4.ICoding[]) => {
        setLoading(false)
        setOptions(getOrderedList(response))
        if (onLoading) onLoading(true)
      })
    }, [inputValue])

    return (
      <Box display='flex' flexDirection='column' width='100%'>
        {loading && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
            px={1}
          >
            <CircularProgress size={25} />
          </Box>
        )}
        <Box width='100%' display='flex' flexDirection='row' py={1}>
          {loading === false && options.length > 0 && (
            <Box width='100%'>
              {options.map((coding: R4.ICoding, qindex: number) => (
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  key={coding.code}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    key={coding.code}
                    width='100%'
                    height={40}
                  >
                    <Box
                      width='50%'
                      paddingBottom={1}
                      paddingRight={1}
                      paddingLeft={2}
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                    >
                      <Typography variant='subtitle2' color='initial'>
                        {getDisplayForQuestion(coding.code ?? '')}
                        {/* {titleCase(coding.display ?? '')} */}
                      </Typography>
                    </Box>
                    <Box
                      paddingLeft={0.5}
                      width='50%'
                      paddingBottom={1}
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                    >
                      <AntSwitch
                        onChange={(event) => {
                          if (coding) {
                            handleChange(event, coding, qindex)
                          }
                        }}
                        name=''
                        checked={
                          value.filter(
                            (org: R4.ICoding) => org.code === coding.code
                          ).length > 0
                        }
                      />
                    </Box>
                  </Box>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '1px',
                    }}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    )
  }
