import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { FileInput } from '../../LeftMenu/FileInput'

interface Props {
  fileType: string
  fileValidationType: string[]
  onFileChange: (file: any) => void
  requiredColumns: string[]
}

const useStyles = makeStyles((theme) => ({
  paperHeight: {
    height: 36,
  },
  container: {
    transform: 'translateY(-100%)',
  },
  test: {
    border: '1px dashed #4543D9',
  },
}))
export const DropZone: React.FC<Props> = ({
  fileType,
  fileValidationType,
  onFileChange,
  requiredColumns,
}) => {
  const { t } = useTranslation()
  const [selectedFiles, setSelectedFiles] = React.useState<any>()
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const dispatch = useDispatch()
  const classes = useStyles()

  const dragOver = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const dragEnter = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const dragLeave = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const fileDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    const { files } = e.dataTransfer
    if (files.length) {
      handleFiles(files)
    }
  }
  const validateFile = (file: any) => {
    if (fileValidationType.indexOf(file.type) === -1) {
      return false
    }
    return true
  }

  const handleFiles = (files: any) => {
    if (files.length > 1) {
      dispatch(showErrorAlert('Please select only one file'))
    } else if (validateFile(files[0])) {
      setSelectedFiles(files[0])
      onFileChange(files[0])
    } else {
      // add a new property called invalid
      files[0].invalid = true
      // add to the same array so we can display the name of the file
      // set error message
      setSelectedFiles(files[0])
      setErrorMessage('Please enter a valid file format.')
    }
  }

  const filesSelected = (e: any) => {
    handleFiles(e.target.files)
  }

  return (
    <div>
      <Box
        flexDirection='column'
        display='flex'
        justifyContent='center'
        className={classes.test}
        alignItems='center'
      >
        <Box padding={2} justifyContent='center'>
          <Box paddingLeft={29}>
            <img
              src={`${process.env.PUBLIC_URL}/upload.png`}
              height={24}
              width={24}
              alt=''
            />
          </Box>
          <div
            className='drop-container'
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
          >
            <Typography
              variant='body1'
              style={{ textAlign: 'center', cursor: 'pointer' }}
              color='primary'
            >
              {t('Drag drop your Excel file here')}
            </Typography>
          </div>
          <Typography variant='body1' style={{ textAlign: 'center' }}>
            {t('OR')}
          </Typography>
          <Typography
            variant='body1'
            style={{
              textAlign: 'center',
              textDecoration: 'underline',
            }}
          >
            <FileInput
              style={{ display: 'none' }}
              name='photoUri'
              type={fileType}
              fileType=' Select a file from your computer'
              onChange={filesSelected}
              multiple={false}
            />
          </Typography>
          <Box
            padding={2}
            flexDirection='column'
            display='flex'
            justifyContent='center'
          >
            <Typography
              variant='overline'
              style={{
                textAlign: 'center',
              }}
            >
              Please ensure that the file contains values for the following
              mandatory columns:
            </Typography>
            <Box>
              {' '}
              <Grid container spacing={1} alignItems='center' justify='center'>
                {requiredColumns &&
                  requiredColumns.map((item: string) => (
                    <Grid item key={item}>
                      <Box flexDirection='row' display='flex' height={30}>
                        <Box
                          border={1}
                          borderColor='grey'
                          p={0.7}
                          borderRadius={2}
                        >
                          <Box px={0.5}>
                            <Typography variant='body2' color='initial'>
                              {t(` ${item} `)}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          bgcolor='primary.main'
                          width={20}
                          justifyContent='center'
                          borderRadius={1}
                        >
                          <Typography
                            variant='body1'
                            style={{
                              textAlign: 'center',
                              marginTop: 10,
                              color: 'white',
                            }}
                            // color='textSecondary'
                          >
                            *
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                {/* <Grid item>
                  <Box flexDirection='row' display='flex'>
                    <Box
                      border={1}
                      borderColor='primary.main'
                      p={1}
                      borderRadius={2}
                      height={30}
                    >
                      <Typography variant='body2' color='primary'>
                        {t('Price')}
                      </Typography>
                    </Box>
                    <Box
                      bgcolor='primary.main'
                      width={20}
                      justifyContent='center'
                    >
                      <Typography
                        variant='body1'
                        style={{
                          textAlign: 'center',
                          marginTop: 10,
                          color: 'white',
                        }}
                        // color='textSecondary'
                      >
                        *
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item>
                  <Box flexDirection='row' display='flex'>
                    <Box
                      border={1}
                      borderColor='primary.main'
                      p={1}
                      borderRadius={2}
                      height={30}
                    >
                      <Typography variant='body2' color='primary'>
                        {t('Schedule Summary')}
                      </Typography>
                    </Box>
                    <Box
                      bgcolor='primary.main'
                      width={20}
                      justifyContent='center'
                    >
                      <Typography
                        variant='body1'
                        style={{
                          textAlign: 'center',
                          marginTop: 10,
                          color: 'white',
                        }}
                        // color='textSecondary'
                      >
                        *
                      </Typography>
                    </Box>
                  </Box>
                </Grid> */}
              </Grid>
            </Box>
            {selectedFiles
              ? selectedFiles.invalid
                ? dispatch(showErrorAlert(errorMessage))
                : ''
              : ''}
            {/* {selectedFiles.invalid &&
              selectedFiles !== undefined &&
              dispatch(showErrorAlert(errorMessage))} */}
          </Box>
        </Box>
      </Box>
      <Box>
        {selectedFiles !== undefined && (
          <Typography variant='subtitle1' color='primary'>
            {selectedFiles.name}
          </Typography>
        )}
      </Box>
    </div>
  )
}
