import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Popper,
  PopperProps,
  TextField,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import {} from 'models/chiefComplaintData'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import {
  getAgeOfPatientData,
  getLastNameOfPatient,
  getNameOfPatient,
  getProfilePicPatient,
  getTelecomOfPatient,
} from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { getPatientsSearch } from 'utils/patientHelper/patientSearchHelper'

interface PatientSelectorWithSearchOptionProps {
  onSelectionChanges?: (selectedValues: R4.IPatient) => void
  onDelete?: (selectedValue: R4.IPatient) => void
  doctorListId?: string
  preSelectedComplaints?: string[]
  disabled: boolean
}

const PopperMy = function (props: JSX.IntrinsicAttributes & PopperProps) {
  return <Popper {...props} style={{ height: 250 }} placement='bottom-start' />
}

export const PatientSelectorWithSearchOption: React.FC<PatientSelectorWithSearchOptionProps> =
  ({
    onSelectionChanges,
    onDelete,
    doctorListId,
    preSelectedComplaints = [],
    disabled,
  }: PatientSelectorWithSearchOptionProps) => {
    const [observations, setObservations] = useState<R4.IPatient[]>([])
    const [addingStatus, setAddingStatus] = useState<NetworkRequestStatus>({
      initial: true,
    })

    const [inputTextValue, setInputValue] = useState<string>()

    function getValueSetsInEncounter(searchString: string) {
      setAddingStatus({
        requesting: true,
      })

      getPatientsSearch(searchString, doctorListId ?? 'memberSearch')
        .then((e) => {
          if ('status' in e) {
            logger.info(e.displayText ?? '')
          } else if (e) {
            const preSelectedCodes = preSelectedComplaints.map(
              (preCode) => preCode ?? ''
            )
            setObservations(
              (e as R4.IPatient[]).filter(
                (code) => !preSelectedCodes.includes(code.id ?? '')
              )
            )
            setAddingStatus({
              requestSuccess: true,
            })
          } else {
            setAddingStatus({
              requestError: true,
            })
          }
        })
        .catch((e) => {
          logger.error(e)
        })
    }

    const { t } = useTranslation(['common'])

    const [selectedCoding, setSelectedCoding] = useState<R4.IPatient>()

    return (
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <div role='alert'>
            <div>Error while fetching surgeryList list</div>
            <pre>{error.message}</pre>
            <Button
              onClick={() => {
                // dispatch(searchConditions(text, selectOther))
              }}
            >
              Try again
            </Button>
          </div>
        )}
      >
        <Box width='100%'>
          <Box width='100%'>
            <Autocomplete
              id='tf_search_tests'
              multiple={false}
              onOpen={() => {}}
              onClose={() => {
                setObservations([])
              }}
              style={{
                borderRadius: '4px',
              }}
              ListboxProps={{
                style: {
                  maxHeight: '250px',
                },
              }}
              fullWidth
              size='small'
              disabled={disabled}
              options={observations}
              loading={addingStatus.requesting}
              autoComplete
              onChange={(e, changedValue, reason, detail) => {
                if (reason === 'remove-option') {
                  if (detail) {
                    if (onDelete) onDelete(detail.option)
                  }
                } else if (changedValue) {
                  if (onSelectionChanges) {
                    setInputValue(undefined)
                    onSelectionChanges(changedValue as R4.IPatient)
                  }
                }
              }}
              ChipProps={{
                style: {
                  backgroundColor: kPrimaryLight,
                  borderRadius: '4px',
                },
              }}
              renderOption={(option, { inputValue }) => (
                <List
                  id={`${option.id}patient_list`}
                  style={{ flexGrow: 1, padding: 0 }}
                >
                  <ListItem
                    style={{ flexGrow: 1, padding: 0 }}
                    component='div'
                    id={`pat_card_${option.id}`}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={getNameOfPatient(option) ?? ''}
                        src={getProfilePicPatient(option) ?? ''}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      color='primary'
                      primary={
                        <>
                          <Typography variant='h6' color='primary'>
                            {getNameOfPatient(option) ?? ''}{' '}
                            {getLastNameOfPatient(option)}
                          </Typography>
                        </>
                      }
                      secondary={
                        <Typography
                          component='span'
                          variant='subtitle1'
                          color='textPrimary'
                        >
                          {getTelecomOfPatient(option)}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <ListItemText
                        primary={
                          <>
                            <Typography variant='subtitle1' color='primary'>
                              {getAgeOfPatientData(option)}
                            </Typography>
                          </>
                        }
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
                /*  <div id={`lab_test_opt_${option.id}`}>
                    {parts.map((part, index) => (
                      <span
                        key={part.text}
                        style={{
                          backgroundColor: part.highlight
                            ? kPrimaryLight
                            : undefined,
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div> */
              )}
              filterOptions={(x) => x}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder='Enter full phone number or 3 character of name '
                  variant='outlined'
                  value={inputTextValue}
                  onChange={(e) => {
                    setInputValue(e.target.value)
                    if (e.target.value.length > 2) {
                      getValueSetsInEncounter(e.target.value)
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {addingStatus.requesting ? (
                          <CircularProgress color='inherit' size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </ErrorBoundary>
    )
  }
