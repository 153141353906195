import { R4 } from '@ahryman40k/ts-fhir-types'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  Collapse,
  Divider,
  Typography,
  TextField,
} from '@material-ui/core'
import { DeleteOutlineOutlined } from '@material-ui/icons'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined'
import { Alert } from '@material-ui/lab'
import { kPrimaryMain } from 'configs/styles/muiThemes'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ORG_LOGO_SPECIALIZATION_URL } from 'utils/constants/fhir_vs_ext_constants'
import { getSortedOrganizationTypes } from 'utils/constants/organization_address_type'
import {
  addExtensionToExtensionsOfUrl,
  getAddress,
  getAddressData,
  getFileSizeInMB,
  getIdentifierValueBySystem,
  getTelecomFromContactPoints,
  getTelecomFromContactPointsForUnit,
  getValueAttachmentFromExtensionData,
  removeExtensionFromExtensions,
} from 'utils/fhirResourcesHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { isValidCodeableConcent } from 'utils/fhirResoureHelpers/unit_setup_helpers'
import { WelloFilePicker } from 'views/components/LeftMenu/WelloFilePicker'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloPhoneNumberField } from 'views/components/LeftMenu/WelloPhoneNumberField'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { MapComp } from 'views/components/mapComponent'
import { WelloAddress } from 'views/mapComponent/addressAutoComplete'
import {
  validateEmail,
  validateMobileNumber,
  WelloTextField,
} from 'wello-web-components'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from '../../common/deleteConfirmationDialog'

interface Props {
  organizationDetails: R4.IOrganization
  hasError: boolean
  latLng: R4.ILocation
}

export const AddOrgPrimaryDetailsSetUp: React.FC<Props> = ({
  organizationDetails,
  hasError,
  latLng,
}: Props) => {
  const { t } = useTranslation()
  const [address, setAddress] = useState<string>(
    getAddress(organizationDetails.address ?? [])
  )
  const [size, setSize] = useState<number>(0)

  const [organizationContacts, setOrganizationContacts] = useState<
    R4.IOrganization_Contact[]
  >(organizationDetails.contact ?? [{}])
  const [displayUrlWarning, setDisplayUrlWarning] = useState<boolean>(true)
  const [locationLatLng, setLocationLatlng] = useState<R4.ILocation_Position>(
    latLng!
  )

  //   const [address1, setAddress1] = useState(
  //     getAddress(organizationDetails.address ?? [])
  //   )
  const [mapCenter, setMapCenter] = useState({
    lat: 0,
    lng: 0,
  })

  const [displayLogoError, setDisplayLogoError] = useState<boolean>(true)
  const [displayContInfoWarning, setDisplayContInfoWarning] =
    useState<boolean>(true)

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  useEffect(() => {
    organizationDetails.contact = organizationContacts
  }, [organizationContacts, organizationDetails])

  useEffect(() => {
    if (address) {
      organizationDetails.address = [{ line: [address] }]
    } else {
      organizationDetails.address = undefined
    }
  }, [address, organizationDetails, latLng])

  useEffect(() => {
    if (locationLatLng) {
      latLng.position = locationLatLng
    }
  }, [locationLatLng, latLng])

  function handleEmailChange(event: any, index: number) {
    const values: R4.IOrganization_Contact[] = [...organizationContacts]
    const currentContactDetail: R4.IOrganization_Contact = values[index]
    const valueTelecom: R4.IContactPoint[] = [
      ...(currentContactDetail.telecom ?? []),
    ]
    const filteredContact: R4.IContactPoint[] = valueTelecom.filter(
      (e) => e.system !== R4.ContactPointSystemKind._email
    )

    const emailContact: R4.IContactPoint = {
      rank: 1,
      system: R4.ContactPointSystemKind._email,
      value: event.target.value,
    }
    values[index].telecom = [...filteredContact, emailContact]
    setOrganizationContacts(values)
  }

  //   function handlePhoneNumberChange(event: any, index: number) {
  //     const values: R4.IOrganization_Contact[] = [...organizationContacts]
  //     const currentContactDetail: R4.IOrganization_Contact = values[index]
  //     const valueTelecom: R4.IContactPoint[] = [
  //       ...(currentContactDetail.telecom ?? []),
  //     ]
  //     const filteredContact: R4.IContactPoint[] = valueTelecom.filter(
  //       (e) => e.system !== R4.ContactPointSystemKind._phone
  //     )

  //     const emailContact: R4.IContactPoint = {
  //       rank: 1,
  //       system: R4.ContactPointSystemKind._phone,
  //       value: event,
  //     }
  //     values[index].telecom = [...filteredContact, emailContact]
  //     setOrganizationContacts(values)
  //   }

  function handlePhoneNumberChangeChange(event: string, index: number) {
    const values: R4.IOrganization_Contact[] = [...organizationContacts]
    const currentContactDetail: R4.IOrganization_Contact = values[index]
    const valueTelecom: R4.IContactPoint[] = [
      ...(currentContactDetail.telecom ?? []),
    ]
    const filteredContact: R4.IContactPoint[] = valueTelecom.filter(
      (e) => e.system !== R4.ContactPointSystemKind._phone
    )

    const emailContact: R4.IContactPoint = {
      rank: 1,
      system: R4.ContactPointSystemKind._phone,
      value: event,
    }
    values[index].telecom = [...filteredContact, emailContact]
    setOrganizationContacts(values)
  }

  function handleChangeUnitTypeSelection(event: R4.ICoding, index: number) {
    const values: R4.IOrganization_Contact[] = [...organizationContacts]
    values[index].purpose = {
      coding: [event],
    }
    organizationDetails.contact = values
    setOrganizationContacts(values)
  }

  const handleAddMore = () => {
    const values: R4.IOrganization_Contact[] = [...organizationContacts]
    values.push({ id: getUniqueTempId() })
    setOrganizationContacts(values)
  }

  function handleRemove(id: number) {
    const values: R4.IOrganization_Contact[] = [...organizationContacts]
    values.splice(id, 1)
    setOrganizationContacts(values)
  }

  return (
    <Box
      display='flex'
      flexDirection='row'
      alignContent='center'
      justifyContent='center'
    >
      <Box
        display='flex'
        flexDirection='column'
        id='unit_act_prof_pic_container'
        marginY={2}
        width='25%'
        alignItems='center'
      />

      <Box display='flex' flexGrow={1} flexDirection='column'>
        <Box display='flex' flexDirection='row'>
          <WelloTextField
            title={t('labelCommon:org_name_label')}
            textProps={{
              name: 'clinicName',
              id: 'add_unit_first_name',
              defaultValue: organizationDetails.name,
              disabled: true,
              placeholder: t('labelCommon:clinic_name_label'),
            }}
          />
        </Box>
        <Box display='flex' flexDirection='row'>
          <WelloTextField
            title={t('labelCommon:org_url_label')}
            textProps={{
              required: true,
              name: 'clinicDescriptions',
              id: 'org_profile_url',
              defaultValue:
                getIdentifierValueBySystem(
                  organizationDetails.identifier ?? [],
                  'http://wellopathy.com/fhir/india/core/Id/tenant-id'
                ) ?? '',
              disabled: true,
              placeholder: t('labelCommon:org_url_label'),
            }}
          />
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          alignContent='center'
          justifyContent='center'
          py={1}
        >
          <Collapse in={displayUrlWarning}>
            <Alert
              variant='outlined'
              severity='warning'
              onClose={() => {
                setDisplayUrlWarning(false)
              }}
              hidden={displayUrlWarning}
              icon={<WarningOutlinedIcon />}
            >
              {t('labelCommon:unit_url_info')}
            </Alert>
          </Collapse>
        </Box>
        {/* <Box display='flex' flexDirection='row'>
          <WelloTextField
            title={t('labelCommon:org_address_label')}
            textProps={{
              required: true,
              name: 'orgAddress',
              id: 'org_profile_url',
              rows: 4,
              rowsMax: 6,
              multiline: true,
              error: hasError && getAddressData(address, true).length > 0,
              helperText: hasError ? getAddressData(address, true) : '',
              defaultValue: getAddress(organizationDetails.address ?? []),

              placeholder: 'Add Address',
              onChange: (e) => {
                setAddress(e.target.value)
              },
            }}
          />
        </Box> */}
        <Box display='flex' flexDirection='row'>
          <Box display='flex' flexDirection='column' flexGrow={1}>
            <Box display='flex' flexDirection='row'>
              <WelloFormItemLabel title='Upload Logo' />{' '}
            </Box>

            <Box
              display='flex'
              flexDirection='row'
              justifyContent='flex-start'
              padding={1}
              marginY={1}
              borderRadius={1}
              width='100%'
            >
              <WelloFilePicker
                fileTypeToPick='any'
                id='unit_actor_id_front_file'
                name='identityProofImageFront'
                onFileChanged={(selectedFile) => {
                  if (selectedFile) {
                    if (selectedFile.path) {
                      const selfExt: R4.IExtension = {
                        url: ORG_LOGO_SPECIALIZATION_URL,
                        valueAttachment: {
                          url: selectedFile?.path as string,
                          contentType: selectedFile?.type,
                          data: selectedFile.path as string,
                          size: selectedFile.files
                            ? selectedFile.files.size
                            : 0,
                          title: selectedFile.name ?? '',
                        },
                      }
                      organizationDetails.extension =
                        addExtensionToExtensionsOfUrl(
                          organizationDetails.extension,
                          selfExt
                        )
                    }
                    if (selectedFile.files) {
                      setSize(selectedFile.files.size)
                    }
                    setDisplayLogoError(false)
                  }
                }}
                size='medium'
                showSelectedFileName={false}
                onDeleteClicked={() => {
                  organizationDetails.extension = removeExtensionFromExtensions(
                    organizationDetails.extension,
                    ORG_LOGO_SPECIALIZATION_URL
                  )

                  setDisplayLogoError(true)
                  setSize(0)
                }}
                allowUpdate={true}
                allowDelete={true}
                labelIcon={
                  <FontAwesomeIcon
                    icon={faUpload}
                    color={kPrimaryMain}
                    size='1x'
                  />
                }
                labelName={t('labelCommon:select_logo_file')}
              />
            </Box>
          </Box>
        </Box>
        {displayLogoError && (
          <Box py={0.5}>
            {getValueAttachmentFromExtensionData(
              organizationDetails.extension,
              'http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo'
            ) === undefined && (
              <Typography variant='subtitle1' color='error'>
                Logo Required
              </Typography>
            )}
          </Box>
        )}

        {displayLogoError === false && size > 0 && (
          <Box py={0.5}>
            {getFileSizeInMB(size) > 5 && (
              <Typography variant='subtitle1' color='error'>
                Logo size must be of less than equal to 5 MB
              </Typography>
            )}
          </Box>
        )}

        <Box display='flex' flexDirection='row' width='100%'>
          <Box display='flex' flexDirection='column' width='100%'>
            <Box display='flex' flexDirection='row'>
              <WelloAddress
                title='Your Current Home Address'
                textProps={{
                  required: true,
                  id: 'unit_actor_current_address',
                  placeholder: 'Your Home address',
                  value: address,
                  onChange: (e) => {
                    setAddress(e.target.value)
                  },
                  name: 'address1',
                }}
                valueData={address}
                mapkey=''
                onUpdated={(
                  addressData: string,
                  mapLatlog: {
                    lat: number
                    lng: number
                  }
                ) => {
                  setAddress(addressData)
                  mapCenter.lat = mapLatlog.lat
                  mapCenter.lat = mapLatlog.lng
                  const locDetails: R4.ILocation_Position = {
                    latitude: mapLatlog.lat,
                    longitude: mapLatlog.lng,
                  }
                  setLocationLatlng(locDetails)
                  setMapCenter(mapLatlog)
                }}
              />
            </Box>

            <Box
              display='flex'
              flexDirection='row'
              paddingY={2}
              paddingRight={6}
            >
              <MapComp
                address=''
                latitude={25}
                longitude={85}
                mapLatlog={mapCenter}
                settingPage={false}
              />
            </Box>
            <Box display='flex' flexDirection='row'>
              <TextField
                fullWidth
                size='small'
                autoComplete='new-password'
                variant='outlined'
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder={t('labelCommon:add_address')}
              />
            </Box>
          </Box>
        </Box>

        <Box paddingTop={1}>
          <Typography variant='subtitle1'>
            {t('labelCommon:contact_information')}
          </Typography>
        </Box>
        <Box display='flex' flexDirection='column'>
          <Box display='flex' flexDirection='column'>
            {organizationContacts.map(
              (org: R4.IOrganization_Contact, index: number) => (
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  key={`unit_actor_small_details_${org.id}`}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    key={`unit_actor_small_details_${org.id}`}
                    justifyContent='space-between'
                    alignContent='center'
                    //   alignItems='center'
                    width='100%'
                  >
                    <Box flexGrow={1} p={0.5} width='20%'>
                      <WelloSelectFHIRCoding
                        title='Type'
                        availableCodings={getSortedOrganizationTypes()}
                        onChanged={(e) => {
                          if (e) {
                            handleChangeUnitTypeSelection(e, index)
                          }
                        }}
                        id={`unit_actor_small_details_${org.id}`}
                        textFieldProps={{
                          size: 'small',
                          error:
                            hasError &&
                            !isValidCodeableConcent(
                              organizationContacts[index].purpose
                            ),
                          helperText:
                            hasError &&
                            !isValidCodeableConcent(
                              organizationContacts[index].purpose
                            )
                              ? 'Select type'
                              : '',
                        }}
                        preSelectedCoding={
                          organizationContacts[index].purpose?.coding?.[0] ?? {
                            code: '',
                          }
                        }
                      />
                    </Box>

                    <Box
                      display='flex'
                      flexGrow={1}
                      alignItems='flex-end'
                      justifyContent='center'
                      p={0.5}
                      width='35%'
                    >
                      <WelloPhoneNumberField
                        textProps={{
                          id: 'unit_actor_phone',
                          countryCodeEditable: false,
                          defaultCountry: 'in',
                          fullWidth: true,
                          defaultValue: getTelecomFromContactPointsForUnit(
                            organizationContacts[index].telecom ?? [],
                            R4.ContactPointSystemKind._phone
                          ),
                          inputMode: 'tel',
                          onlyCountries: ['in'],
                          disableDropdown: false,
                          onChange: (
                            e:
                              | React.ChangeEvent<
                                  HTMLInputElement | HTMLTextAreaElement
                                >
                              | string
                          ) => {
                            if (typeof e === 'string') {
                              handlePhoneNumberChangeChange(e, index)
                            }
                          },
                          regions: ['asia'],
                          autoFormat: false,
                          disabled: false,
                          size: 'small',
                          variant: 'outlined',
                          inputProps: { maxLength: 13 },
                          error:
                            hasError &&
                            validateMobileNumber(
                              getTelecomFromContactPoints(
                                organizationContacts[index].telecom ?? [],
                                R4.ContactPointSystemKind._phone
                              ) ?? '',
                              true
                            )?.length > 0,
                          helperText: hasError
                            ? validateMobileNumber(
                                getTelecomFromContactPoints(
                                  organizationContacts[index].telecom ?? [],
                                  R4.ContactPointSystemKind._phone
                                ) ?? '',
                                true
                              )
                            : '',
                          required: true,
                        }}
                        title='Phone Number'
                      />
                      {/* <WelloTextField
                        title={t('labelCommon:phone_number_label')}
                        textProps={{
                          id: `actor_phone_unit_${t}`,
                          defaultValue: getTelecomFromContactPoints(
                            organizationContacts[index].telecom ?? [],
                            R4.ContactPointSystemKind._phone
                          ),

                          error:
                            hasError &&
                            validateMobileNumberForAdd(
                              getTelecomFromContactPoints(
                                organizationContacts[index].telecom ?? [],
                                R4.ContactPointSystemKind._phone
                              ) ?? '',
                              true
                            )?.length > 0,
                          helperText: hasError
                            ? validateMobileNumberForAdd(
                                getTelecomFromContactPoints(
                                  organizationContacts[index].telecom ?? [],
                                  R4.ContactPointSystemKind._phone
                                ) ?? '',
                                true
                              )
                            : '',
                          onChange: (changedPhoneNumber) => {
                            handlePhoneNumberChangeChange(
                              changedPhoneNumber,
                              index
                            )
                          },
                          type: 'tel',
                          autoComplete: 'off',
                        }}
                      /> */}

                      {/* <WelloPhoneNumberField
                        textProps={{
                          id: `actor_phone_unit_${t}`,
                          value: getTelecomFromContactPoints(
                            organizationContacts[index].telecom ?? [],
                            R4.ContactPointSystemKind._phone
                          ),
                          countryCodeEditable: false,
                          defaultCountry: 'in',
                          fullWidth: true,
                          disabled: true,
                          name: t('phone_name_label'),
                          inputMode: 'tel',
                          onlyCountries: ['in'],
                          disableDropdown: false,
                          inputProps: { maxLength: 13 },
                          onChange: (
                            e:
                              | React.ChangeEvent<
                                  HTMLInputElement | HTMLTextAreaElement
                                >
                              | string
                          ) => {
                            if (typeof e === 'string') {
                              handlePhoneNumberChangeChange(e, index)
                            }
                          },
                          regions: ['asia'],
                          autoFormat: false,
                          size: 'small',
                          variant: 'outlined',
                          error:
                            hasError &&
                            validateMobileNumber(
                              getTelecomFromContactPoints(
                                organizationContacts[index].telecom ?? [],
                                R4.ContactPointSystemKind._phone
                              ) ?? '',
                              true
                            )?.length > 0,
                          helperText: hasError
                            ? validateMobileNumber(
                                getTelecomFromContactPoints(
                                  organizationContacts[index].telecom ?? [],
                                  R4.ContactPointSystemKind._phone
                                ) ?? '',
                                true
                              )
                            : '',

                          required: true,
                        }}
                        title={t('labelCommon:phone_number_label')}
                      /> */}
                    </Box>

                    <Box
                      display='flex'
                      flexGrow={1}
                      alignItems='flex-end'
                      justifyContent='center'
                      p={0.5}
                      width='35%'
                    >
                      <WelloTextField
                        title={t('labelCommon:email_address')}
                        textProps={{
                          id: `actor_email_unit`,
                          defaultValue: getTelecomFromContactPoints(
                            organizationContacts[index].telecom ?? [],
                            R4.ContactPointSystemKind._email
                          ),
                          error:
                            hasError &&
                            validateEmail(
                              getTelecomFromContactPoints(
                                organizationContacts[index].telecom ?? [],
                                R4.ContactPointSystemKind._email
                              ) ?? '',
                              true
                            )?.length > 0,
                          helperText: hasError
                            ? validateEmail(
                                getTelecomFromContactPoints(
                                  organizationContacts[index].telecom ?? [],
                                  R4.ContactPointSystemKind._email
                                ) ?? '',
                                true
                              )
                            : '',
                          onChange: (changeEmail) => {
                            handleEmailChange(changeEmail, index)
                          },
                          autoComplete: 'off',
                        }}
                      />
                    </Box>

                    {organizationContacts.length !== 1 && (
                      <Box
                        display='flex'
                        justifyContent='center'
                        paddingTop={4}
                      >
                        <DeleteOutlineOutlined
                          fontSize='medium'
                          id={`$${index}orgContact`}
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setShowDeleteConfirmation({
                              open: true,
                              onContinueActionClicked: () => {
                                setShowDeleteConfirmation({
                                  open: false,
                                })
                                handleRemove(index)
                              },
                              onCancelActionClicked: () => {
                                setShowDeleteConfirmation({
                                  open: false,
                                })
                              },
                              displayText:
                                'Are you sure you want to delete this record?',
                              continueActionButtonText: 'Delete',
                              cancelActionButtonText: 'Cancel',
                            })
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box>
                    {organizationContacts.length - 1 === index &&
                      organizationContacts.length < 3 && (
                        <Box
                          display='flex'
                          alignItems='center'
                          py={0.5}
                          flexDirection='row'
                        >
                          <Box>
                            <Button variant='text' onClick={handleAddMore}>
                              <Typography variant='subtitle2'>
                                {t('labelCommon:add_more')}
                              </Typography>
                            </Button>
                          </Box>
                          <Box flexGrow={1} paddingLeft={2}>
                            {' '}
                            <Divider />{' '}
                          </Box>
                        </Box>
                      )}
                  </Box>
                </Box>
              )
            )}
          </Box>
          {/* <Box display='flex' alignItems='center' py={0.5} flexDirection='row'>
            <Box>
              <Button variant='text' onClick={handleAddMore}>
                <Typography variant='subtitle2'>
                  {t('labelCommon:add_more')}
                </Typography>
              </Button>
            </Box>
            <Box flexGrow={1} paddingLeft={2}>
              {' '}
              <Divider />{' '}
            </Box>
          </Box> */}
          <Box
            display='flex'
            flexDirection='row'
            alignContent='center'
            justifyContent='center'
            py={1}
          >
            <Collapse in={displayContInfoWarning}>
              <Alert
                variant='outlined'
                severity='info'
                icon={<CheckBoxIcon />}
                onClose={() => {
                  setDisplayContInfoWarning(false)
                }}
                hidden={displayContInfoWarning}
              >
                {t('labelCommon:org_contact_info')}
              </Alert>
            </Collapse>
          </Box>
        </Box>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        marginY={2}
        width='25%'
        alignItems='flex-end'
      />

      {showDeleteConfirmation.open && (
        <ConfirmationDialog {...showDeleteConfirmation} />
      )}
    </Box>
  )
}
