import { R4 } from '@ahryman40k/ts-fhir-types'

export const Specializations: R4.ICoding[] = [
  {
    system: 'http://snomed.info/sct',
    code: '394579002',
    display: 'Cardiology',
  },

  {
    system: 'http://snomed.info/sct',
    code: '394802001',
    display: 'General Medicine',
  },

  {
    system: 'http://snomed.info/sct',
    code: '408450004',
    display: 'Sleep Studies',
  },
  {
    system: 'http://snomed.info/sct',
    code: '231189271000087109',
    display: 'Ayurveda',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-specialty-cs',
    code: '20000000',
    display: 'BNYS',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-specialty-cs',
    code: '20000001',
    display: 'PT',
  },
  {
    system: 'http://snomed.info/sct',
    code: '159033005',
    display: 'Dietitian',
  },

  {
    system: 'http://snomed.info/sct',
    code: '409968004',
    display: 'Preventive Medicine',
  },

  {
    system: 'http://snomed.info/sct',
    code: '309350005',
    display: 'Diabetologist',
  },

  {
    system: 'http://snomed.info/sct',
    code: '439809005',
    display: 'Naturopathy and Yoga',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const NurseSpecializations: R4.ICoding[] = [
  {
    code: '310182000',
    display: 'General Nurse',
    system: 'http://snomed.info/sct',
  },

  {
    code: '224550000',
    display: 'Neurology Nurse',
    system: 'http://snomed.info/sct',
  },

  {
    code: '158992006',
    display: 'Midwifery Sister',
    system: 'http://snomed.info/sct',
  },

  {
    code: '224563006',
    display: 'Psychiatric Nurse',
    system: 'http://snomed.info/sct',
  },

  {
    code: '224552008',
    display: 'Oncology Nurse',
    system: 'http://snomed.info/sct',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const AyurVedaSpecializations: R4.ICoding[] = [
  { code: '231189271000087109', display: 'Ayurveda' },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
