import { Typography, TypographyProps } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import React, { useEffect, useRef, useState } from 'react'

interface Props {
  text: string
  typographyProps: TypographyProps
}

const OverflowTypography = (props: Props) => {
  const textElementRef = useRef<HTMLInputElement | null>(null)

  const compareSize = () => {
    const compare =
      textElementRef.current!.scrollWidth > textElementRef.current!.clientWidth
    setHover(compare)
  }

  useEffect(() => {
    compareSize()
    window.addEventListener('resize', compareSize)
  }, [])

  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize)
    },
    []
  )

  const [hoverStatus, setHover] = useState(false)

  return (
    <Tooltip
      title={props.text ?? ''}
      interactive
      disableHoverListener={!hoverStatus}
    >
      <Typography
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...props.typographyProps.style,
        }}
        {...props.typographyProps}
      >
        {props.text}
      </Typography>
    </Tooltip>
  )
}

export default OverflowTypography
