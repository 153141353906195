import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { GroupedOccupation } from 'models/groupedOccupations'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getEmailOfPatient,
  getIdentifierValueBySystem,
} from 'utils/fhirResourcesHelper'
import { getPatientIdentifiersForSearch } from 'utils/fhirResoureHelpers/patientHelpers'
import { logger } from 'utils/logger'
import { BowelsSearchStatus } from './bowelsSearchStatus'

const initialState: BowelsSearchStatus = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingProcedures: false,
}

const bowelsSearchSlice = createSlice({
  name: 'bowelsSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<BowelsSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.dietList = action.payload.dietList
      state.groupedObs = action.payload.groupedObs
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
    },
  },
})

export const requestBowelsHistoryOfPatient =
  (appointmentId: string, selectedPatient: R4.IPatient): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: BowelsSearchStatus = {
      searchingConditions: true,
      errorWhileSearchingProcedures: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(bowelsSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        category: 'social-history',
        _count: 500,
      }

      if (getPatientIdentifiersForSearch(selectedPatient).length > 0) {
        searchParameters['patient:Patient.identifier'] =
          getPatientIdentifiersForSearch(selectedPatient)
      }

      const response: any =
        await fhirClient.doGetResourceForAppointmentCrossSearch(
          '/Observation',
          appointmentId,
          searchParameters
        )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)

      const proceduresResponse: R4.IBundle = response

      if (proceduresResponse?.total && proceduresResponse?.total > 0) {
        const observations: R4.IObservation[] =
          proceduresResponse.entry?.map(
            (item) => item.resource as R4.IObservation
          ) ?? []

        const finalObservations: R4.IObservation[] = []

        for (let i = 0; i < observations.length; i++) {
          const codeData = observations[i].code
            ? observations[i].code.coding![0].code ?? ''
            : ''

          if (codeData === '80263-7') {
            finalObservations.push(observations[i])
          }
        }
        if (finalObservations.length > 0) {
          finalObservations.sort((a, b) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )

          let groupedOccupationData: GroupedOccupation[] = []

          for (let i = 0; i < finalObservations.length; i++) {
            if (finalObservations[i].issued) {
              if (groupedOccupationData.length > 0) {
                for (let j = 0; j < groupedOccupationData.length; j++) {
                  if (
                    moment(finalObservations[i].issued).format('YYYY-MM-DD') ===
                    moment(groupedOccupationData[j].date).format('YYYY-MM-DD')
                  ) {
                    groupedOccupationData[j].occupation.push(
                      finalObservations[i]
                    )
                  } else {
                    groupedOccupationData.push({
                      date: finalObservations[i].issued ?? '',
                      occupation: [finalObservations[i]],
                    })
                  }
                }
              } else {
                groupedOccupationData.push({
                  date: finalObservations[i].issued ?? '',
                  occupation: [finalObservations[i]],
                })
              }
            }
          }
          groupedOccupationData = groupedOccupationData.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  moment(t.date).format('YYYY-MM-DD') ===
                  moment(value.date).format('YYYY-MM-DD')
              )
          )
          state.resultsAvailable = true
          state.searchingConditions = false
          state.dietList = finalObservations
          state.groupedObs = groupedOccupationData
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingProcedures = false
          dispatch(bowelsSearchSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: BowelsSearchStatus = {
            searchingConditions: false,
            errorWhileSearchingProcedures: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(bowelsSearchSlice.actions.updatedStatus(errorSearchDoctor))
        }
      } else {
        const errorSearchDoctor: BowelsSearchStatus = {
          searchingConditions: false,
          errorWhileSearchingProcedures: false,
          resultsAvailable: false,
          noResultsAvailable: true,
        }
        dispatch(bowelsSearchSlice.actions.updatedStatus(errorSearchDoctor))
      }
      /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: BowelsSearchStatus = {
        searchingConditions: false,
        errorWhileSearchingProcedures: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
      }
      dispatch(bowelsSearchSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default bowelsSearchSlice.reducer
