/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable consistent-return */
import { Box } from '@material-ui/core'
import { AnyARecord } from 'dns'
import {
  CohortChartProps,
  CohortControlsProps,
  CohortDataStage,
  MapDataProps,
} from 'models/chartProps'
import { SankeyData } from 'models/sankeyData'
import { SankeyLink } from 'models/sankeyLinks'
import { SankeyNode } from 'models/sankeyNode'
import React, { useEffect, useState } from 'react'
import { getFilterLabel } from 'utils/cohort/cohortUtils'
import '../../../../../../App.css'

type fontSizeProps = {
  label: string
  handleFontIncrease: any
  handleFontDecrease: any
}

export const FontSize: React.FC<fontSizeProps> = ({
  label,
  handleFontIncrease,
  handleFontDecrease,
}: fontSizeProps) => {
  console.log('455')
  return (
    <Box display='flex' flexDirection='column'>
      <label id='label'>{label}</label>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <button
          onClick={handleFontDecrease}
          type='button'
          style={{
            minWidth: 24,
            display: 'flex',
            height: 24,
            margin: '8x 0',
            textAlign: 'center',
            lineHeight: '24px',
          }}
        >
          &ndash;
        </button>
        <p style={{ margin: '8px', height: '24px', display: 'flex' }}>
          <span
            style={{
              fontSize: '9pt',
            }}
          >
            A
          </span>
          <span
            style={{
              fontSize: '14pt',
            }}
          >
            A
          </span>
        </p>
        <button
          onClick={handleFontIncrease}
          type='button'
          style={{
            minWidth: 24,
            display: 'flex',
            height: 24,
            margin: '8x 0',
            textAlign: 'center',
            lineHeight: '24px',
          }}
        >
          +
        </button>
      </div>
    </Box>
  )
}
