import { Box, List, ListSubheader, Typography } from '@material-ui/core'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import React from 'react'
import { getCompleteDateStringWithOutDay } from 'utils/dateUtil'
import { getDateWiseOrders } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { PartnerLabOrderTile } from './partnerLabOrderTile'

interface Props {
  preselectedOrderId?: string
  labOrders?: FhirLabOrderDetail[]
  locationId: string
  percentage: number
  onLabOrderSelected?: (selectedOrder: FhirLabOrderDetail) => void
  dense?: boolean
}

export const PartnerLabOrderListView: React.FC<Props> = ({
  preselectedOrderId,
  labOrders,
  locationId,
  percentage,
  onLabOrderSelected,
  dense = false,
}: Props) => (
  <List
    subheader={<li style={{ padding: 0, width: '100%' }} />}
    style={{ padding: 0, width: '100%', overflow: 'auto', height: '100%' }}
  >
    {getDateWiseOrders(labOrders ?? []).map((e) => (
      <li key={`section-${e.date ?? ''}`} style={{ padding: 0, width: '100%' }}>
        <ul style={{ padding: 0 }}>
          <ListSubheader
            style={{
              color: '#333333',
              paddingLeft: 12,
              height: 30,
              width: '100%',
              backgroundColor: '#ececec',
            }}
          >
            <Box paddingTop={0.3} px={1}>
              <Typography variant='subtitle1'>
                {getCompleteDateStringWithOutDay(e.date ?? '')}
              </Typography>
            </Box>
          </ListSubheader>
          {e.orders?.map((item, index) => (
            <PartnerLabOrderTile
              labOrderDetail={item}
              percentage={percentage}
              isOdd={index % 2 === 0}
              dense={dense}
              locationId={locationId}
              isSelected={
                preselectedOrderId !== undefined &&
                item.serviceRequest.id === preselectedOrderId
              }
              onTap={() => {
                if (onLabOrderSelected) {
                  onLabOrderSelected(item)
                }
              }}
              key={`lab_item_key${item.serviceRequest.id}` ?? ''}
            />
          ))}
        </ul>
      </li>
    ))}
  </List>
)
