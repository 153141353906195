import { R4 } from '@ahryman40k/ts-fhir-types'

export const CharacterSeObservationCodes: R4.ICoding[] = [
  {
    system: 'http://snomed.info/sct',
    code: '86684002',
    display: 'Slow respiration',
  },
  {
    system: 'http://snomed.info/sct',
    code: '301288007',
    display: 'Quiet respiration',
  },
  {
    system: 'http://snomed.info/sct',
    code: '271823003',
    display: 'Tachypnea',
  },
  {
    system: 'http://snomed.info/sct',
    code: '248573009',
    display: 'Noisy respiration',
  },
  {
    system: 'http://snomed.info/sct',
    code: '248578000',
    display: 'Sobbing respiration',
  },
  {
    system: 'http://snomed.info/sct',
    code: '248575002',
    display: 'Groaning respiration',
  },
  {
    system: 'http://snomed.info/sct',
    code: '62085005',
    display: 'Grunting respiration',
  },
  {
    system: 'http://snomed.info/sct',
    code: '225914005',
    display: 'Bubbling respiration',
  },
  {
    system: 'http://snomed.info/sct',
    code: '60537006',
    display: 'Whooping respiration',
  },
  {
    system: 'http://snomed.info/sct',
    code: '271621004',
    display: 'Stertorous breathin',
  },
  {
    system: 'http://snomed.info/sct',
    code: '40296006',
    display: 'Meningitic respiration',
  },
  {
    system: 'http://snomed.info/sct',
    code: '276888009',
    display: 'Normal Spontaneous Respiration',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-respiratory-character-rate-finding-cs',
    code: 'other-respiratory-character-rate-finding',
    display: 'Other',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
