import {
  Box,
  Button,
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestDischargeCount } from 'redux/clinic/ipdmanagement/ipdDischargedCount/ipdDischargeCountSearchSlice'
import { RootState } from 'redux/rootReducer'
import { isTherapist } from 'services/userDetailsService'
import { WelloFormItemLabel } from 'wello-web-components'

const orderOption = [
  {
    id: 'active',
    display: 'Active',
  },
  {
    id: 'completed',
    display: 'Discharged',
  },
]

const therapyOptions = [
  {
    id: 'active',
    display: 'Scheduled',
  },
  {
    id: 'completed',
    display: 'Completed',
  },
  {
    id: 'revoked',
    display: 'Cancelled',
  },
]
interface Props {
  type: string
  onSelectionChanged?: (selectedValues: string[]) => void
  date?: Date
}
export const IPDStatusSelection: React.FC<Props> = ({
  type,
  onSelectionChanged,
  date,
}: Props) => {
  const statuses = useSelector((state: RootState) =>
    type === 'IPD'
      ? state.ipdAppointmentSearchSlice.selectedStatuses
      : state.opdAppointmentSearchSlice.selectedStatuses
  )
  const dischargeCount = useSelector(
    (state: RootState) => state.ipdDischargedCount
  )
  const { t } = useTranslation()
  const [selectedIds, setSelectedIds] = useState<string[]>(statuses)
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)
  const dispatch = useDispatch()

  function handleChecked(id: string) {
    if (id === 'all') {
      if (selectedIds.length === 2) {
        updateDetails([])
      } else {
        updateDetails([...orderOption.map((e) => e.id)])
      }
    } else if (selectedIds.includes(id)) {
      if (selectedIds.length > 1) {
        const tempIds: string[] = selectedIds.filter((item) => item !== id)
        updateDetails(tempIds.filter((item) => item !== 'all'))
      }
    } else {
      const tempIds: string[] = [...selectedIds]
      tempIds.push(id)
      updateDetails(tempIds)
    }
  }

  function updateDetails(selection: string[]) {
    setSelectedIds(selection)
    if (onSelectionChanged) {
      onSelectionChanged(selection.filter((item) => item !== 'any'))
    }
  }

  useEffect(() => {
    dispatch(requestDischargeCount(type, date))
  }, [dispatch, date])

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
          <Button
            onClick={() => {
              // Todo
            }}
          >
            Try again
          </Button>
        </div>
      )}
    >
      <Box display='flex' flexDirection='column'>
        <Box
          display='flex'
          flexDirection='row'
          px={1}
          justifyContent='space-between'
          alignContent='center'
          alignItems='center'
        >
          <WelloFormItemLabel
            title={isTherapist() ? 'Therapy Status' : 'Status'}
          />
          <IconButton
            aria-label='collapse_order_type'
            size='small'
            onClick={() => {
              setIsCollapsed(!isCollapsed)
            }}
          >
            {isCollapsed && <ArrowDropUpOutlined />}
            {!isCollapsed && <ArrowDropDownOutlined />}
          </IconButton>
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          style={{
            width: '100%',
          }}
        >
          <Collapse
            in={isCollapsed}
            style={{
              width: '100%',
            }}
          >
            {!isTherapist() && (
              <List
                style={{
                  width: '100%',
                  marginTop: 0,
                  marginBottom: 0,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                {orderOption.map((value) => {
                  const labelId = `checkbox-list-label-${value.id}`

                  return (
                    <ListItem
                      key={value.id}
                      role={undefined}
                      dense
                      style={{
                        width: '100%',
                        marginTop: 0,
                        marginBottom: 0,
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                      button
                      onClick={() => {
                        handleChecked(value.id)
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge='start'
                          size='small'
                          checked={selectedIds.indexOf(value.id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          color='primary'
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={
                          value.id === 'completed'
                            ? t(
                                `labelCommon:${value.display}` +
                                  ` (${dischargeCount.count})`
                              )
                            : t('labelCommon:' + `${value.display}`)
                        }
                        primaryTypographyProps={{
                          variant: 'body1',
                          color:
                            selectedIds.indexOf(value.id) !== -1
                              ? 'primary'
                              : undefined,
                        }}
                      />
                    </ListItem>
                  )
                })}
              </List>
            )}

            {isTherapist() && (
              <List
                style={{
                  width: '100%',
                  marginTop: 0,
                  marginBottom: 0,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                {therapyOptions.map((value) => {
                  const labelId = `checkbox-list-label-${value.id}`

                  return (
                    <ListItem
                      key={value.id}
                      role={undefined}
                      dense
                      style={{
                        width: '100%',
                        marginTop: 0,
                        marginBottom: 0,
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                      button
                      onClick={() => {
                        handleChecked(value.id)
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge='start'
                          size='small'
                          checked={selectedIds.indexOf(value.id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          color='primary'
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={t('labelCommon:' + `${value.display}`)}
                        primaryTypographyProps={{
                          variant: 'body1',
                          color:
                            selectedIds.indexOf(value.id) !== -1
                              ? 'primary'
                              : undefined,
                        }}
                      />
                    </ListItem>
                  )
                })}
              </List>
            )}
          </Collapse>
        </Box>
      </Box>
    </ErrorBoundary>
  )
}
