import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { isDietician } from 'utils/fhirResourcesHelper'
import { WelloSelectAutoComplete } from 'views/components/LeftMenu/WelloSelectAutoComplete'
import { searchMedicalProviders } from '../../../../redux/practitioner/medicalProvidersSearchHandler/medicalProviderSearchSlice'

interface Props {
  onPractitionerSelectionChanged?: (
    selectedValues: PractitionerWithRole
  ) => void
  doctorListId?: string
  preSelectedPractitionerRoleId?: string
  providerType?: string
  disabled?: boolean
}
export const PractitionerSingleSelector: React.FC<Props> = ({
  onPractitionerSelectionChanged,
  doctorListId,
  preSelectedPractitionerRoleId,
  providerType,
  disabled = false,
}: Props) => {
  const practitionerSearchState = useSelector(
    (state: RootState) => state.medicalProvidersSearchSlice
  )
  const dispatch = useDispatch()

  const { t } = useTranslation()

  function getSelectedOptions(
    id: string | undefined,
    list: PractitionerWithRole[]
  ) {
    if (id) {
      const resIndex = list.findIndex((e) => e.practRoleId === id)

      if (resIndex > -1) {
        return list[resIndex]
      }
    }

    return undefined
  }

  useEffect(() => {
    dispatch(searchMedicalProviders('', undefined, providerType))
  }, [dispatch])
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
          <Button
            onClick={() => {
              dispatch(searchMedicalProviders())
            }}
          >
            Try again
          </Button>
        </div>
      )}
    >
      <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
        {' '}
        {practitionerSearchState.searching && <CircularProgress size={15} />}
        {practitionerSearchState.error && (
          <Typography>
            {' '}
            {practitionerSearchState.errorMessage ??
              t('labelCommon:error_search')}
          </Typography>
        )}
        {practitionerSearchState.noResultsAvailable && (
          <Typography> No Results available</Typography>
        )}
        {practitionerSearchState.resultsAvailable &&
          practitionerSearchState.doctorRoleList && (
            <WelloSelectAutoComplete
              id={doctorListId ?? 'select_doctor'}
              availableOptions={practitionerSearchState.doctorRoleList
                .filter((e) => e.name.length > 0 && e.status === 'Active')
                .filter((each) => !isDietician(each.roleObject))
                .map((e) => ({
                  ...e,
                  fullName:
                    e.fullName.toLowerCase().includes('Dr') ||
                    e.fullName.toLowerCase().includes('Dr.')
                      ? e.fullName.replace('Dr ', '')
                      : `${e.fullName}`,
                }))}
              displayField='fullName'
              onOptionSelected={(selectedValues) => {
                if (onPractitionerSelectionChanged) {
                  onPractitionerSelectionChanged(selectedValues)
                }
              }}
              valuesField='id'
              preSelectedOption={getSelectedOptions(
                preSelectedPractitionerRoleId,
                practitionerSearchState.doctorRoleList
              )}
              disabled={disabled}
            />
          )}
      </Box>
    </ErrorBoundary>
  )
}
