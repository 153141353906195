import specialities from '../assets/constants/ayurvedicCategory.json'

export interface Root {
  display: string
  all_url: string
  root_cat_url: string
  child_nodes: ChildNode[]
}

export interface ChildNode {
  display: string
  code: string
  category_url: string
  leaf_node: boolean
}

export const medicationMaps: Root = specialities as Root

export function getMedicationCategoryUrlFromCode(
  code: string
): ChildNode | undefined {
  console.log('code', code)
  const category = medicationMaps.child_nodes.find((c) => c.code === code)
  console.log('category', category)
  if (category) {
    return category
  }
  return undefined
}
