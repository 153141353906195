import { R4 } from '@ahryman40k/ts-fhir-types'
import { CircularProgress, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'
import { getCatalogName } from 'utils/fhirResoureHelpers/catalogHelper'

export interface CatalogEntrySelectorProps {
  isInEditMode: boolean
  id: string
  searchType: string
  onRadioOptionsChanged: (changedValue: R4.ICatalogEntry[]) => void
  availableOptions: R4.ICatalogEntry[]
  showProcessing: boolean
  selectedValue?: R4.ICatalogEntry[]
  placeHolderString?: string
  catalogEntryPath?: string
  detachLabel?: boolean
  labelString?: string
  selectedId?: string
}

export const CatalogSelector: React.FC<CatalogEntrySelectorProps> = ({
  selectedValue: selectedValues,
  availableOptions,
  showProcessing,
  isInEditMode,
  placeHolderString,
  detachLabel,
  labelString,
  id,
  searchType,
  onRadioOptionsChanged,
  selectedId,
}: CatalogEntrySelectorProps) => {
  const [options, setOptions] =
    React.useState<R4.ICatalogEntry[]>(availableOptions)

  return (
    <Autocomplete
      multiple
      id={id}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => getCatalogName(option)}
      fullWidth
      options={options}
      loading={showProcessing}
      onChange={(e, changedValue) => {
        if (changedValue != null) {
          onRadioOptionsChanged(changedValue)
        }
      }}
      style={{ width: 155 }}
      defaultValue={selectedValues}
      renderInput={(params) => (
        <TextField
          {...params}
          label={labelString}
          variant='outlined'
          id={`${id}diet`}
          size='small'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {showProcessing ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
