import { R4 } from '@ahryman40k/ts-fhir-types'
import { AxiosResponse, CancelToken } from 'axios'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import QueryString from 'qs'
import { getErrorResponse } from 'utils/apiClientHelper'
import { logger } from 'utils/logger'
import { getVendorPartId } from 'utils/routes_helper'
import { ApiClient } from './apiService'

export class CPGApiService extends ApiClient {
  constructor() {
    const vendorId: string | null = getVendorPartId()
    const CPGUrl = process.env.REACT_APP_CDS_SERVICE_URL ?? ''
    const finalUrl = `${process.env.REACT_APP_FHIR_BASE_URL ?? ''}${vendorId}`
    super(CPGUrl)
  }

  public async doGet(
    path: string,
    body: any
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        path,
        body,
        {
          headers: {},
        }
      )
      if (response.status === 201) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doPost(path: string, body: any): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        path,
        body,
        {
          timeout: 250000,
        }
      )
      return response
    } catch (error) {
      logger.error(error)
      throw error
    }
  }
}
