import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { getIdentifierValueBySystem } from 'utils/fhirResourcesHelper'
import { IPDDetails } from './IpdDetails'
//   import { WelloFormItemLabel, WelloLoadingIndicator } from 'wello-web-components'
//   import { RestrictionsOfSelectedOfferings } from '../common/restriction_detail'
//   import { PaymentTile } from '../orderCreationManagement/paymentTile'
//   import { RefundTile } from '../orderCreationManagement/refundTile'
//   import { OrderAppointmentDetails } from './orderAppointmentDetails'
//   import { OrderCancellationHandler } from './orderCancellationHandler'
//   import { OrderReScheduleHandler } from './orderRescheduleHandler'
//   import { OrderTimeLine } from './orderTimeLine'

interface Props {
  open: boolean
  ipdDetails: FhirClinicIpdDetails
  isOdd?: boolean
  onCloseClicked: () => void
  dense?: boolean
}

export const IPDDetailsPopUp: React.FC<Props> = ({
  open = false,
  ipdDetails,

  isOdd = false,
  onCloseClicked,
  dense = false,
}: Props) => {
  const orderManagementSlice = useSelector(
    (state: RootState) => state.orderManagementSlice
  )
  const { t } = useTranslation()

  const arr: string[] = []

  return (
    <Dialog
      open={open}
      onClose={onCloseClicked}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>
        {getIdentifierValueBySystem(
          ipdDetails.serviceRequest.identifier ?? [],
          'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
        )}
      </DialogTitle>
      <DialogContent
        style={{
          padding: 0,
          overflow: 'auto',
          margin: 0,
        }}
      >
        <IPDDetails
          ipdDetailsDataFinal={ipdDetails}
          onCloseClicked={onCloseClicked}
        />
      </DialogContent>
    </Dialog>
  )
}
