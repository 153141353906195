/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Grid, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { PurposeOfUse } from 'models/purposeOfUse'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-vis/dist/style.css'
import { HearingObservationType } from 'utils/constants/observations/hearing_observation_codes'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { getStringCodesFromObservations } from 'utils/fhirResoureHelpers/observationHelpers'
import { AddObservationWithNotesForEncounter } from '../observationManagement/addObservationWithNotes'
import { ViewObservations } from '../observationManagement/viewObservations'

interface PrimitiveReflectionExaminationsListPTProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  isReadOnly?: boolean
}

export const HearingExaminationsListPT: React.FC<PrimitiveReflectionExaminationsListPTProps> =
  ({
    fhirAppointmentDetails,
    isReadOnly = false,
  }: PrimitiveReflectionExaminationsListPTProps) => {
    const { t } = useTranslation(['common'])
    const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())
    const [preselectedObservations, setPreSelectedObservations] = useState<
      R4.IObservation[]
    >([])

    return (
      <Grid container direction='column' style={{ padding: 0, margin: 0 }}>
        <Grid container direction='row' style={{ padding: 0, margin: 0 }}>
          <Box display='flex' alignItems='center' flexDirection='row'>
            <Box p={1}>
              <Typography variant='h6' color='primary'>
                {' '}
                Hearing
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item style={{ padding: '8px' }}>
          <ViewObservations
            patient={fhirAppointmentDetails.patient}
            purposeOfUse={PurposeOfUse.IPDAppointment}
            category='118230007'
            encounterId={fhirAppointmentDetails.mainEncounter!.id!}
            status='completed'
            fetchAgain={fetcAgain ?? 'new'}
            allowEditing={!isReadOnly}
            onObservationFetched={(res) => {
              setPreSelectedObservations(res)
            }}
            id='hear_view'
          />
        </Grid>
        {!isReadOnly && (
          <Grid item style={{ padding: '8px' }}>
            <AddObservationWithNotesForEncounter
              onObservationAdded={() => {
                setFetchAgain(getUniqueTempId())
              }}
              searchOptions={HearingObservationType}
              encounterId={fhirAppointmentDetails.mainEncounter!.id!}
              patient={fhirAppointmentDetails.patient}
              otherCode='other-hearing-finding'
              preSelectedCodes={getStringCodesFromObservations(
                preselectedObservations
              )}
              observationCategory={[
                {
                  coding: [
                    {
                      system: 'http://snomed.info/sct',
                      code: '118230007',
                      display: 'Hearing',
                    },
                  ],
                },
              ]}
              id='hear_add'
            />
          </Grid>
        )}
      </Grid>
    )
  }
