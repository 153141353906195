/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { requestForDischarge } from 'redux/ipd/discharge/dischargeFindSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import {
  getNotesFromAllergy,
  hasNotes,
} from 'utils/fhirResoureHelpers/allergyHelpers'

interface AssessmentCaptureProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
}

export const DischargeTile: React.FC<AssessmentCaptureProps> = ({
  fhirAppointmentDetails,
}: AssessmentCaptureProps) => {
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const [summary, setSummary] = React.useState<string>()

  const dischargeFindSlice = useSelector(
    (state: RootState) => state.dischargeFindSlice
  )

  useEffect(() => {
    dispatch(
      requestForDischarge(
        fhirAppointmentDetails.patient,
        fhirAppointmentDetails.mainServiceRequest.id!
      )
    )
  }, [dispatch])

  return (
    <Box style={{ paddingLeft: '4px' }} display='flex' flexDirection='row'>
      <Box width='100%'>
        <Grid container direction='column'>
          <Grid item>
            <ReactVisibilitySensor
              onChange={(isVisible) => {
                if (isVisible) {
                  dispatch(setSelectedSection({ selectedSection: 'diagnosis' }))
                }
              }}
            >
              <Grid container direction='column'>
                <Grid item id='diagnosis'>
                  <Grid
                    container
                    direction='row'
                    style={{ padding: 0, margin: 0 }}
                  >
                    <Box py={1}>
                      <Typography
                        variant='subtitle2'
                        style={{ color: '#4D4D4D', textTransform: 'uppercase' }}
                      >
                        {' '}
                        Discharge
                      </Typography>
                    </Box>{' '}
                  </Grid>
                </Grid>
                {dischargeFindSlice.resultsAvailable &&
                  dischargeFindSlice.vitalsList &&
                  dischargeFindSlice.vitalsList.length > 0 && (
                    <Grid item xl>
                      <Box height='100%' py={1}>
                        <Grid
                          container
                          direction='row'
                          alignContent='space-between'
                          justify='space-between'
                        >
                          <Grid container direction='row' justify='flex-start'>
                            <Grid item xs={2}>
                              <Box
                                display='flex'
                                width='100%'
                                flexDirection='row'
                                justifyContent='flex-start'
                              >
                                <Typography
                                  variant='body1'
                                  color='primary'
                                  style={{ fontWeight: 600 }}
                                >
                                  {' '}
                                  Date
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box
                                display='flex'
                                width='100%'
                                flexDirection='row'
                                justifyContent='flex-start'
                              >
                                <Typography
                                  variant='body1'
                                  color='primary'
                                  style={{ fontWeight: 600 }}
                                >
                                  {' '}
                                  Discharge Note
                                </Typography>
                              </Box>
                            </Grid>

                            {/* <Grid item xs={3}>
                              <Box
                                display='flex'
                                width='100%'
                                flexDirection='row'
                                justifyContent='flex-start'
                              >
                                <Typography
                                  variant='body1'
                                  color='primary'
                                  style={{ fontWeight: 600 }}
                                >
                                  {' '}
                                  Discharge Reason
                                </Typography>
                              </Box>
                            </Grid> */}
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  )}
                {dischargeFindSlice.searchingConditions && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      align='center'
                    >
                      <CircularProgress size={25} />
                    </Typography>
                  </Box>
                )}

                {dischargeFindSlice.errorWhileSearchingProcedures && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      align='center'
                    >
                      Some Error has occured
                    </Typography>
                  </Box>
                )}

                {dischargeFindSlice.noResultsAvailable && (
                  <Box display='flex' flexDirection='column' width='100%'>
                    <Typography
                      variant='subtitle2'
                      style={{
                        fontWeight: 400,
                      }}
                    >
                      No data available
                    </Typography>
                  </Box>
                )}

                {dischargeFindSlice.resultsAvailable &&
                  dischargeFindSlice.vitalsList &&
                  dischargeFindSlice.vitalsList.length === 0 && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='100%'
                      alignContent='center'
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          fontWeight: 400,
                        }}
                      >
                        No data available
                      </Typography>
                    </Box>
                  )}

                {dischargeFindSlice.resultsAvailable &&
                  dischargeFindSlice.vitalsList &&
                  dischargeFindSlice.vitalsList.length > 0 && (
                    <Grid item xl>
                      <Box height='50%'>
                        <Grid
                          container
                          direction='row'
                          alignContent='space-between'
                          justify='space-between'
                        >
                          {dischargeFindSlice.vitalsList.map(
                            (
                              assessmentData: R4.IObservation,
                              index: number
                            ) => (
                              <Box width='100%'>
                                <Grid
                                  container
                                  direction='row'
                                  justify='flex-start'
                                >
                                  <Grid item xs={2}>
                                    <Box
                                      display='flex'
                                      width='100%'
                                      flexDirection='row'
                                      justifyContent='flex-start'
                                      py={1}
                                    >
                                      {' '}
                                      <Typography
                                        variant='body2'
                                        color='primary'
                                      >
                                        {' '}
                                        {moment(assessmentData.issued).format(
                                          'DD-MM-YYYY'
                                        )}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Box
                                      display='flex'
                                      width='100%'
                                      flexDirection='row'
                                      justifyContent='flex-start'
                                      py={1}
                                    >
                                      <Typography
                                        variant='subtitle2'
                                        color='primary'
                                        style={{
                                          wordWrap: 'break-word',
                                          whiteSpace: 'pre-line',
                                          textOverflow: 'ellipsis',
                                        }}
                                      >
                                        {assessmentData.valueString ?? ''}
                                      </Typography>
                                    </Box>
                                  </Grid>

                                  {/* <Grid item xs={3}>
                                    <Box
                                      display='flex'
                                      width='100%'
                                      flexDirection='row'
                                      justifyContent='flex-start'
                                      py={1}
                                    >
                                      <Typography
                                        variant='body2'
                                        color='initial'
                                      >
                                        {' '}
                                        {assessmentData.valueCodeableConcept
                                          ? assessmentData.valueCodeableConcept
                                              .coding
                                            ? assessmentData
                                                .valueCodeableConcept.coding[0]
                                                .display ?? ''
                                            : ''
                                          : ''}
                                      </Typography>
                                    </Box>
                                  </Grid> */}
                                </Grid>
                              </Box>
                            )
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  )}
              </Grid>
            </ReactVisibilitySensor>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
