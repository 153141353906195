/* eslint-disable @typescript-eslint/no-unused-expressions */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import _ from 'lodash'
import { BpCollection } from 'models/bpData'
import { Diet, DietByWeek } from 'models/diet'
import { DietTiming } from 'models/dietSetting/dietTImeSettings'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { getSortedObservation } from 'utils/appointment_handle/vitals_util'
import {
  getAvoidingFoods,
  getCreatedDate,
  getDietPlanFromBundle,
  getDietPlanFromBundleForCustom,
  getDietPlanFromBundleForFixed,
  getDietPlanFromBundleRawNutrition,
  getDietPlanFromBundleRawNutritionForFixed,
  getEndDate,
  getPreferredFoods,
  getStartDate,
} from 'utils/fhirResoureHelpers/dietPlanHelpers'
import { getDietPlansPlanFromBundle } from 'utils/fhirResoureHelpers/ipdDietHelper'
import { getObserVationFfromEncounter } from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { getTreatmentPlanFromBundle } from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { divideDateByWeek } from 'utils/fhirResoureHelpers/observationHelpers'
import { logger } from 'utils/logger'
import { ExistingDietPlanSearchStatus } from './existingDietPlanSearchStatus'

const initialState: ExistingDietPlanSearchStatus = {
  searchingTreatment: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingTreatment: false,
  isPreExistingPlanAvailable: false,
  isFixedDietAvailable: false,
}

const existingDietPlanSearchSlice = createSlice({
  name: 'existingDietPlanSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<ExistingDietPlanSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingTreatment = action.payload.searchingTreatment
      state.resultsAvailable = action.payload.resultsAvailable
      state.dateWiseDietList = action.payload.dateWiseDietList
      state.startDate = action.payload.startDate
      state.endDate = action.payload.endDate
      state.preferredFoods = action.payload.preferredFoods
      state.itemsToAvoid = action.payload.itemsToAvoid
      state.dietList = action.payload.dietList
      state.errorReason = action.payload.errorReason
      state.isPreExistingPlanAvailable =
        action.payload.isPreExistingPlanAvailable
      state.errorWhileSearchingTreatment =
        action.payload.errorWhileSearchingTreatment
      state.createdDate = action.payload.createdDate
      state.fixedPlan = action.payload.fixedPlan
      state.dateWiseDietListFixed = action.payload.dateWiseDietListFixed
      state.isFixedDietAvailable = action.payload.isFixedDietAvailable
      state.dietByWeeksData = action.payload.dietByWeeksData
    },
  },
})

export const requestDietPlanDetails =
  ({
    dietTime,
    patientId,
    encounterId,
    practitionerRoleId,
    showPlanSuggestedBySameDoctor,
    split,
  }: {
    dietTime: DietTiming[]
    patientId: string
    encounterId?: string
    practitionerRoleId?: string
    split?: boolean
    showPlanSuggestedBySameDoctor?: boolean
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: ExistingDietPlanSearchStatus = {
      searchingTreatment: true,
      errorWhileSearchingTreatment: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      isPreExistingPlanAvailable: false,
      isFixedDietAvailable: false,
    }
    dispatch(existingDietPlanSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: MasterFhirClient = new MasterFhirClient()

      let searchParameters: any = {
        patient: `Patient/${patientId}`,
        status: split ? 'completed' : 'active',
        _count: 500,
        _sort: '-datetime',
      }

      if (showPlanSuggestedBySameDoctor) {
        searchParameters = {
          patient: `Patient/${patientId}`,
          provider: `PractitionerRole/${practitionerRoleId}`,
          status: split ? 'completed' : 'active',
          _count: 500,
          _sort: '-datetime',
        }
      } else if (encounterId) {
        searchParameters = {
          patient: `Patient/${patientId}`,
          encounter: `Encounter/${encounterId}`,
          status: split ? 'completed' : 'active',
          _count: 500,
          _sort: '-datetime',
        }
      }

      const response: any = await fhirClient.doGetResourceForAppointmentIPD(
        `/NutritionOrder?_tag:not=mirror-resource`,
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingTreatment = true
        state.searchingTreatment = false

        dispatch(existingDietPlanSearchSlice.actions.updatedStatus(state))
      } else {
        const dietPlanResponse: R4.IBundle = resp.right

        const dietDataList: R4.INutritionOrder[] =
          getDietPlanFromBundleRawNutrition(
            dietPlanResponse,
            {
              reference: `Patient/${patientId}`,
            },
            encounterId ?? '',
            showPlanSuggestedBySameDoctor
          )

        const dietDataListFixed: R4.INutritionOrder[] =
          getDietPlanFromBundleRawNutritionForFixed(
            dietPlanResponse,
            {
              reference: `Patient/${patientId}`,
            },
            encounterId ?? '',
            showPlanSuggestedBySameDoctor
          )

        const finalDate = moment.utc(getEndDate(dietDataList)).local()
        const start = moment.utc(getStartDate(dietDataList)).local()

        const vitalLisData = getDietPlanFromBundle(
          dietTime,
          dietPlanResponse,
          moment(start).format('YYYY-MM-DD'),
          moment(finalDate).format('YYYY-MM-DD'),
          {
            reference: `Patient/${patientId}`,
          },
          encounterId ?? '',
          showPlanSuggestedBySameDoctor
        )

        const vitalLisDataFixed = getDietPlanFromBundleForFixed(
          dietTime,
          dietPlanResponse,

          {
            reference: `Patient/${patientId}`,
          },
          encounterId ?? '',
          showPlanSuggestedBySameDoctor
        )

        const finalGroupedData: DietByWeek[] = []
        if (vitalLisData.length > 0) {
          for (let i = 0; i < vitalLisData.length; i++) {
            if (finalGroupedData.length === 0) {
              if (vitalLisData[i].week) {
                finalGroupedData.push({
                  week: parseInt(vitalLisData[i].week!, 10),
                  dietData: [vitalLisData[i]],
                })
              }
            } else {
              const indexData = finalGroupedData.findIndex(
                (x) => x.week === parseInt(vitalLisData[i].week!, 10)
              )
              if (indexData > -1) {
                finalGroupedData[indexData].dietData.push(vitalLisData[i])
              } else {
                finalGroupedData.push({
                  week: parseInt(vitalLisData[i].week!, 10),
                  dietData: [vitalLisData[i]],
                })
              }
            }

            //   finalGroupedData[indexData].condition.push(finalData[i].condition)
          }
        }

        const lastSet: DietByWeek[] = []

        for (let i = 0; i < finalGroupedData.length; i++) {
          lastSet.push({
            week: finalGroupedData[i].week,
            dietData: finalGroupedData[i].dietData.sort((a, b) =>
              moment(b.date).diff(a.date)
            ),
          })
        }

        // lastSet.sort((a, b) => moment(b.week!).diff(a.week!))

        state.resultsAvailable = true
        state.searchingTreatment = false
        state.dateWiseDietList = vitalLisData.sort((a, b) =>
          moment(b.date).diff(a.date)
        )
        state.dietByWeeksData = lastSet.sort((a, b) =>
          moment(b.week!).diff(a.week!)
        )
        state.dateWiseDietListFixed = vitalLisDataFixed
        state.fixedPlan = dietDataListFixed
        state.isFixedDietAvailable = !(vitalLisData.length > 1)
        state.dietList = dietDataList
        state.endDate = getEndDate(dietDataList)
        state.startDate = getStartDate(dietDataList)
        state.createdDate = getCreatedDate(dietDataList)
        state.preferredFoods = getPreferredFoods(dietDataList)
        state.itemsToAvoid = getAvoidingFoods(dietDataList)
        state.noResultsAvailable = getCreatedDate(dietDataList) === undefined
        state.errorReason = undefined
        state.errorWhileSearchingTreatment = false
        state.isPreExistingPlanAvailable = dietDataList.length > 0
        dispatch(existingDietPlanSearchSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      console.error(error)
      const errorSearchDoctor: ExistingDietPlanSearchStatus = {
        searchingTreatment: false,
        errorWhileSearchingTreatment: true,
        resultsAvailable: false,
        isPreExistingPlanAvailable: false,
        errorReason: 'Error while searching Treatment Plan',
        isFixedDietAvailable: false,
      }
      dispatch(
        existingDietPlanSearchSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export const resetDietSearchState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: ExistingDietPlanSearchStatus = {
      ...initialState,
    }
    dispatch(existingDietPlanSearchSlice.actions.updatedStatus(state))
  }

export async function getDietPlanData(
  dietTime: DietTiming[],
  startDate: string,
  patientId: string,
  encounterId?: string,
  practitionerRoleId?: string,
  showPlanSuggestedBySameDoctor?: boolean,
  endDate?: string,
  existingDiet?: Diet[]
): Promise<Diet[]> {
  let dietData: Diet[] = []

  const fhirClient: MasterFhirClient = new MasterFhirClient()

  let searchParameters: any = {
    patient: `Patient/${patientId}`,
    status: 'active',
    _count: 500,
    _sort: '-datetime',
  }

  if (showPlanSuggestedBySameDoctor) {
    searchParameters = {
      patient: `Patient/${patientId}`,
      provider: `PractitionerRole/${practitionerRoleId}`,
      status: 'active',
      _count: 500,
      _sort: '-datetime',
    }
  } else if (encounterId) {
    searchParameters = {
      patient: `Patient/${patientId}`,
      encounter: `Encounter/${encounterId}`,
      status: 'active',
      _count: 500,
      _sort: '-datetime',
    }
  }

  const response: any = await fhirClient.doGetResourceForAppointmentIPD(
    `/NutritionOrder`,
    searchParameters
  )

  const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
  if (resp._tag === 'Left') {
    console.log('error')
  } else {
    const dietPlanResponse: R4.IBundle = resp.right

    dietData = getDietPlanFromBundleForCustom(
      dietTime,
      startDate,
      dietPlanResponse,
      {
        reference: `Patient/${patientId}`,
      },
      encounterId ?? '',
      showPlanSuggestedBySameDoctor,
      endDate
    )
  }

  const finalDate = [...(existingDiet ?? []), ...dietData]

  return _.uniqBy(finalDate, 'date').sort((a, b) => moment(a.date).diff(b.date))
}

export function getActualDiet(
  diet: Diet[],
  startDate: string,
  endDate?: string
): Diet[] {
  let mainFinalData: Diet[] = []
  if (diet && diet.length > 0) {
    const subData: Diet[] = []
    for (let i = 0; i < diet.length; i++) {
      if (moment(diet[i].date).format('YYYY-MM-DD') >= startDate) {
        if (endDate) {
          if (moment(diet[i].date).format('YYYY-MM-DD') <= endDate) {
            subData.push(diet[i])
          }
        }
      }
    }
    mainFinalData = subData
  } else {
    mainFinalData = diet
  }
  return mainFinalData
}

export function getTotalDiet(master: Diet[], newDiet: Diet[]): Diet[] {
  let mainFinalData: Diet[] = []

  if (master && master.length > 0) {
    const subData: Diet[] = []
    if (master.length >= newDiet.length) {
      for (let i = 0; i < master.length; i++) {
        if (newDiet.length > 0) {
          const finalData = newDiet.filter(
            (d: Diet) => d.date === master[i].date
          )
          if (finalData.length > 0) {
            subData.push(finalData[0])
          } else {
            if (newDiet[i] !== undefined) {
              subData.push(newDiet[i])
            }
            subData.push(master[i])
          }
        } else {
          subData.push(master[i])
        }
      }
    } else {
      for (let i = 0; i < newDiet.length; i++) {
        if (master.length > 0) {
          const finalData = master.filter(
            (d: Diet) => d.date === newDiet[i].date
          )
          if (finalData.length > 0) {
            subData.push(finalData[0])
          } else {
            if (master[i] !== undefined) {
              subData.push(master[i])
            }
            subData.push(newDiet[i])
          }
        } else {
          subData.push(newDiet[i])
        }
      }
    }

    mainFinalData = subData
  } else {
    mainFinalData = newDiet
  }

  return mainFinalData
}

export default existingDietPlanSearchSlice.reducer
