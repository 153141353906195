import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedFamilyHistory } from 'models/groupedFamilyHistory'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCommaSeparatedCondition,
  getDefaultCodeOfSystemFromCodableConcept,
  getEmailOfPatient,
  getIdentifierValueBySystem,
} from 'utils/fhirResourcesHelper'
import { getExpandedAppointmentFromBundle } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getPatientIdentifiersForSearch } from 'utils/fhirResoureHelpers/patientHelpers'
import { logger } from 'utils/logger'
import { FamilyMedicalConditionHistoryStatus } from './familyMedicalHistoryConditionsType'

const initialState: FamilyMedicalConditionHistoryStatus = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingConditions: false,
}

const familyMedicalConditionsHistorySlice = createSlice({
  name: 'familyMedicalConditionsHistory',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<FamilyMedicalConditionHistoryStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.conditions = action.payload.conditions
      state.groupedHistory = action.payload.groupedHistory
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingConditions =
        action.payload.errorWhileSearchingConditions
    },
  },
})

export const requestFamilyConditionHistoryOfPatient =
  (
    appointmentId: string,
    selectedPatient: R4.IPatient,
    split: boolean,
    isOPD?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: FamilyMedicalConditionHistoryStatus = {
      searchingConditions: true,
      errorWhileSearchingConditions: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _sort: '-_lastUpdated',
      }

      if (getPatientIdentifiersForSearch(selectedPatient).length > 0) {
        searchParameters['patient:Patient.identifier'] =
          getPatientIdentifiersForSearch(selectedPatient)
      }

      const date = `${moment(
        moment(moment(moment()).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      ).toISOString()}`

      const endDate = `${moment(
        moment(moment(moment()).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      )
        .subtract(90, 'days')
        .toISOString()}`

      let response: any

      if (split) {
        response =
          await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
            `/FamilyMemberHistory?_count=500&date=gt${endDate}`,
            '',
            searchParameters
          )
      } else if (isOPD) {
        response = await fhirClient.doGetResourceForAppointmentCrossSearch(
          '/FamilyMemberHistory?_count=500',
          appointmentId,
          searchParameters
        )
      } else {
        response = await fhirClient.doGetResourceForAppointmentCrossSearch(
          '/FamilyMemberHistory?_count=500',
          appointmentId,
          searchParameters
        )
      }

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)

      const appointmentResponse: R4.IBundle = response
      if (appointmentResponse?.total && appointmentResponse?.total > 0) {
        const fhirAppointments: FhirAppointmentDetail[] =
          getExpandedAppointmentFromBundle(appointmentResponse)
        const history: R4.IFamilyMemberHistory[] =
          appointmentResponse.entry?.map(
            (item) => item.resource as R4.IFamilyMemberHistory
          ) ?? []

        let groupedData: GroupedFamilyHistory[] = []
        for (let i = 0; i < history.length; i++) {
          if (history[i].meta && history[i].meta?.lastUpdated) {
            if (groupedData.length > 0) {
              for (let j = 0; j < groupedData.length; j++) {
                if (
                  moment(history[i].meta?.lastUpdated).format('YYYY-MM-DD') ===
                  moment(groupedData[j].date).format('YYYY-MM-DD')
                ) {
                  groupedData[j].conditions.push(history[i])
                } else {
                  groupedData.push({
                    date: history[i].date ?? '',
                    conditions: [history[i]],
                  })
                  break
                }
              }
            } else {
              groupedData.push({
                date: history[i].date ?? '',
                conditions: [history[i]],
              })
            }
          }
        }
        groupedData = groupedData.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                moment(t.date).format('YYYY-MM-DD') ===
                moment(value.date).format('YYYY-MM-DD')
            )
        )

        const finalGroupedOccupationData: GroupedFamilyHistory[] = []
        for (let i = 0; i < groupedData.length; i++) {
          finalGroupedOccupationData.push({
            date: groupedData[i].date,
            conditions: groupedData[i].conditions.filter(
              (value, index, self) =>
                index ===
                self.findIndex(
                  (t) =>
                    t.relationship!.coding![0].code ===
                    value.relationship!.coding![0].code
                )
            ),
          })
        }

        state.resultsAvailable = true
        state.searchingConditions = false
        state.conditions = appointmentResponse.entry?.map(
          (item) => item.resource as R4.IFamilyMemberHistory
        )
        state.groupedHistory = finalGroupedOccupationData
        state.noResultsAvailable = false
        state.errorReason = undefined
        state.errorWhileSearchingConditions = false
        //   dispatch(
        //     requestFamilyConditionHistoryOfPatient('', selectedPatient, split)
        //   )
        dispatch(
          familyMedicalConditionsHistorySlice.actions.updatedStatus(state)
        )
      } else {
        const errorSearchDoctor: FamilyMedicalConditionHistoryStatus = {
          searchingConditions: false,
          errorWhileSearchingConditions: false,
          resultsAvailable: false,
          noResultsAvailable: true,
        }
        dispatch(
          familyMedicalConditionsHistorySlice.actions.updatedStatus(
            errorSearchDoctor
          )
        )
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: FamilyMedicalConditionHistoryStatus = {
        searchingConditions: false,
        errorWhileSearchingConditions: true,
        resultsAvailable: false,
        errorReason: 'Error while searching family history',
      }
      dispatch(
        familyMedicalConditionsHistorySlice.actions.updatedStatus(
          errorSearchDoctor
        )
      )
    }
  }

export default familyMedicalConditionsHistorySlice.reducer
