import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { getMedicationsOfAppointment } from 'redux/consultation/medicationsListSlice/medicationsListSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { Errors } from 'io-ts'
import * as E from 'fp-ts/lib/Either'
import {
  addAndGetEncounterDetailsOfAppointment,
  addAndGetEncounterIdOfAppointment,
  getMedicationRequest,
} from 'utils/appointment_handle/cds_recommendations_util'
import {
  getCarePlanByIdCurrentState,
  getCarePlanOfPatient,
  getCommunicationResourceForFollowUp,
  hasPlanOfferedInOrg,
} from 'utils/careplan_utils/careplan_utils'
import { logger } from 'utils/logger'
import { getVendorPartId } from 'utils/routes_helper'
import { sleep } from 'utils/dateUtil'
import { updateAppointmentDetails } from 'redux/appointments/appointmentViewHandler/appointmentHandlerSlice'
import { MedicationAdditionStatus } from './medicationAdditionState'

const initialState: MedicationAdditionStatus = {
  addingInstruction: false,
  additionSuccessful: false,
  noResultsAvailable: false,
  errorWhileAdding: false,
}

const medicationAdditionSlice = createSlice({
  name: 'medicationAdditionSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<MedicationAdditionStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.addingInstruction = action.payload.addingInstruction
      state.additionSuccessful = action.payload.additionSuccessful
      state.successMessage = action.payload.successMessage
      state.errorReason = action.payload.errorReason
      state.errorWhileAdding = action.payload.errorWhileAdding
    },
  },
})

export const addMedicationRecommendations =
  (
    patient: R4.IPatient,
    communications: R4.IMedicationRequest[],
    appointment: FhirAppointmentDetail,
    carePlanUrl?: string,
    carePlanId?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: MedicationAdditionStatus = {
      ...initialState,
    }
    state.addingInstruction = true
    dispatch(medicationAdditionSlice.actions.updatedStatus(state))
    try {
      const hasPlanDef = true

      if (hasPlanDef) {
        let encounterId: string | undefined = appointment.encounter?.id
        if (
          appointment.encounter === undefined ||
          appointment.encounter?.id === undefined ||
          appointment.encounter?.id?.length === 0
        ) {
          const encounter: R4.IEncounter | undefined =
            await addAndGetEncounterDetailsOfAppointment(appointment)

          if (encounter) {
            encounterId = encounter.id!
          }
        }

        const carePlanOfPatient: R4.ICarePlan | undefined =
          carePlanId !== undefined
            ? await getCarePlanByIdCurrentState(carePlanId)
            : await getCarePlanOfPatient(patient.id!, carePlanUrl ?? '', true)

        if (carePlanOfPatient) {
          const responses: AxiosResponse[] | undefined =
            await addMedicationApiCalls(
              communications,
              appointment.patient,
              appointment,
              carePlanOfPatient!.id!
            )
          if (responses) {
            let isSuccess: boolean = true
            responses.forEach((e) => {
              if (e.status !== 200 && e.status !== 201) {
                isSuccess = false
              }
            })
            await sleep(5000)
            if (isSuccess) {
              state.addingInstruction = false
              state.additionSuccessful = true
              state.errorWhileAdding = false
              state.successMessage =
                'Medications have been updated in care portal.'
              state.errorReason = undefined
              dispatch(
                medicationAdditionSlice.actions.updatedStatus(initialState)
              )
              dispatch(showSuccessAlert(state.successMessage!))
              setTimeout(() => {
                dispatch(getMedicationsOfAppointment(appointment))
              }, 500)
            } else {
              let isPartial: boolean = false
              responses.forEach((e) => {
                if (e.status === 200 || e.status === 201) {
                  isPartial = true
                }
              })
              if (isPartial) {
                state.addingInstruction = false
                state.additionSuccessful = false
                state.errorWhileAdding = true
                state.successMessage = undefined
                state.errorReason =
                  'Added few of the medications. Check Plan sections'
                dispatch<any>(showErrorAlert(state.errorReason!))
                dispatch(
                  medicationAdditionSlice.actions.updatedStatus(initialState)
                )
                setTimeout(() => {
                  dispatch(getMedicationsOfAppointment(appointment))
                }, 500)
              } else {
                state.addingInstruction = false
                state.additionSuccessful = false
                state.errorWhileAdding = true
                state.successMessage = undefined
                state.errorReason = 'Error while adding medications. Try later'
                dispatch(showErrorAlert(state.errorReason!))
                dispatch(medicationAdditionSlice.actions.updatedStatus(state))
              }
            }
          }
        } else {
          state.addingInstruction = false
          state.additionSuccessful = false
          state.errorWhileAdding = true
          state.successMessage = undefined
          state.errorReason = 'User is not subscribed to Care Plan'
          dispatch<any>(showErrorAlert(state.errorReason!))
          dispatch(medicationAdditionSlice.actions.updatedStatus(state))
        }
      } else {
        let encounterId: string | undefined = appointment.encounter?.id
        if (appointment.encounter === undefined) {
          encounterId = await addAndGetEncounterIdOfAppointment(appointment)
        }
        const bundle: R4.IBundle = {
          resourceType: 'Bundle',
          type: R4.BundleTypeKind._transaction,
          entry: communications.map(
            (e) =>
              ({
                request: {
                  method: R4.Bundle_RequestMethodKind._post,
                  url: 'MedicationRequest',
                },
                resource: getMedicationRequest(
                  e,
                  encounterId ?? '',
                  appointment.patient.id ?? '',
                  carePlanUrl
                ),
              } as R4.IBundle_Entry)
          ),
        }

        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response = await fhirApi.doCreateFHIRTransaction('', bundle)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          state.addingInstruction = false
          state.additionSuccessful = true
          state.errorWhileAdding = false
          state.successMessage = 'Medications have been updated in care portal.'
          state.errorReason = undefined
          dispatch(medicationAdditionSlice.actions.updatedStatus(initialState))
          dispatch(showSuccessAlert(state.successMessage!))
          setTimeout(() => {
            dispatch(getMedicationsOfAppointment(appointment))
          }, 500)
        } else {
          state.addingInstruction = false
          state.additionSuccessful = false
          state.errorWhileAdding = true
          state.successMessage = undefined
          state.errorReason = 'Error while adding medications. Try later'
          dispatch(showErrorAlert(state.errorReason!))
          dispatch(medicationAdditionSlice.actions.updatedStatus(state))
        }
      }
    } catch (error) {
      console.error(error)
      const resState: MedicationAdditionStatus = {
        addingInstruction: false,
        errorWhileAdding: true,
        additionSuccessful: false,
        noResultsAvailable: false,
        errorReason: (error as AxiosError).response?.data,
      }
      dispatch<any>(showErrorAlert(state.errorReason!))
      dispatch(medicationAdditionSlice.actions.updatedStatus(resState))
    }
  }

export const addMedicationApiCalls = async (
  medicationRequests: R4.IMedicationRequest[],
  patient: R4.IPatient,
  appointment: FhirAppointmentDetail,
  careplanId: string
): Promise<AxiosResponse[] | undefined> => {
  try {
    let encounterId: string | undefined = appointment.encounter?.id
    if (encounterId) {
      encounterId = await addAndGetEncounterIdOfAppointment(appointment)
    }
    const body: any = {
      appointmentId: appointment.appointment.id,
      patientId: patient.id,
      carePlanId: careplanId,
      encounterId: encounterId!,
    }
    const enRolClient: EnrolCient = new EnrolCient()
    const responses: any[] = []
    medicationRequests.forEach(async (e) => {
      body.medicationRequest = e

      const resp: AxiosResponse =
        await enRolClient.doCreateEnrolmentFlowRequest('prescription/', body)

      responses.push(resp)
    })

    return responses
  } catch (error) {
    logger.info('_______error________________')
    logger.info(error)
  }
  return undefined
}

export default medicationAdditionSlice.reducer
