import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  TaskIntentKind,
  TaskStatusKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { requestForIncomingLabReferralsCount } from 'redux/administration/referralSetup/fetchReferralCount/labReferralsCountSlice'
import { requestForIncomingReferralsListsForLab } from 'redux/administration/referralSetup/fetchReferralList/incomingReferralsSliceForLabOrders'

import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import {
  LabOrderHomeCollectionTaskCodes,
  LabOrderTaskCodes,
  PartnerLabOrderTaskCodes,
} from 'utils/constants/lab_order_task_codes'
import {
  getNameOfPatient,
  getValueRefValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import { getOrderCreationSuccessfulMessage } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  getReferralResource,
  updateServiceRequest,
} from 'utils/fhirResoureHelpers/ipdHelper'
import {
  getChargeItemForSelectedPlans,
  getPaymentReconciliationResource,
  getProvenanceObjectForOrder,
  getProvenanceObjectForTask,
  getTransactionBodyForOfflinePayment,
  getTransactionBodyForOfflinePaymentForLabOrder,
  getTransactionBodyResource,
  getTransactionBodyResourceAppointmentOnline,
  getTransactionBodyResourceLabOrderOnline,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import { getTotalAmountOfLabOfferings } from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { logger } from 'utils/logger'
import { searchingPartnerLabOrders } from '../../../collectionCenter/partnerLabOrderSearch/partnerLabOrderSearch'
import { requestOrdersCountForToday } from '../orderCount/orderCountSlice'
import { fetchOrderDetails } from '../orderManagementSlice/orderManagementSlice'
import { requestOrdersForTheDateRange } from '../orderSearchSlice/orderSearchSlice'
import { CreateOrderStatus } from './createOrderStatus'

const initialState: CreateOrderStatus = {
  creatingAppointment: false,
  orderId: '',
  errorWhileCreatingAppointment: false,
  appointmentCreatedSuccessfully: false,
}

const createOrderSlice = createSlice({
  name: 'createOrderSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<CreateOrderStatus>) {
      state.appointmentCreatedSuccessfully =
        action.payload.appointmentCreatedSuccessfully
      state.orderId = action.payload.orderId
      state.errorReason = action.payload.errorReason
      state.errorWhileCreatingAppointment =
        action.payload.errorWhileCreatingAppointment
      state.creatingAppointment = action.payload.creatingAppointment
      state.createdAppointment = action.payload.createdAppointment
      state.appointmentCreatedSuccessfully =
        action.payload.appointmentCreatedSuccessfully
    },
  },
})

export const resetLabOrderCreationState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: CreateOrderStatus = { ...initialState }
    dispatch(createOrderSlice.actions.updatedStatus(state))
  }

export const requestLabOrder =
  (
    selectedLabOfferings: LabOfferingDetail[],
    selectedPatient: R4.IPatient,
    selectedServiceType: string,
    selectedPaymentOption: string,
    selectedAddress?: R4.IAddress,
    selectedSlot?: FhirSlotDetail,
    locationId?: string,
    partnerLabUser?: R4.IPractitionerRole,
    referralId?: string,
    encounterRef?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: CreateOrderStatus = { ...initialState }
    state.creatingAppointment = true
    dispatch(createOrderSlice.actions.updatedStatus(state))

    try {
      const bundleObject: R4.IBundle = getOrderCreationTransactionObject(
        selectedLabOfferings,
        selectedPaymentOption,
        selectedPatient,
        selectedServiceType,
        selectedAddress,
        selectedSlot,
        locationId,
        partnerLabUser,
        referralId,
        encounterRef
      )
      const fhirApi: FHIRApiClient = new FHIRApiClient()
      const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const orderResponse: R4.IBundle = relatedFhirDecodeRes.right
        let serviceRequest: string = ''
        let paymentReconciliation: string = ''
        const tasks: string[] = []
        if (orderResponse.entry) {
          for (let i = 0; i < orderResponse.entry.length; i++) {
            const entryResponse = orderResponse.entry[i].response
            if (entryResponse) {
              if (entryResponse.location) {
                if (entryResponse.location.includes('ServiceRequest')) {
                  serviceRequest = entryResponse.location.replace(
                    '/_history/1',
                    ''
                  )
                }
                if (entryResponse.location.includes('PaymentReconciliation')) {
                  paymentReconciliation = entryResponse.location.replace(
                    '/_history/1',
                    ''
                  )
                }
                if (entryResponse.location.includes('Task')) {
                  const taskRef = entryResponse.location.replace(
                    '/_history/1',
                    ''
                  )
                  tasks.push(taskRef)
                }
              }
            }
          }
        }

        const linkResponseForFinal: boolean = await handleNotification(
          serviceRequest,
          selectedPatient.id ?? ''
        )

        if (selectedPaymentOption === 'online') {
          const transactionResp: boolean = await createTransactionLink(
            selectedLabOfferings,
            selectedServiceType,
            serviceRequest,
            paymentReconciliation,
            selectedPaymentOption,
            tasks,
            'lab-order',
            selectedPatient.id ?? '',
            selectedSlot
          )

          if (transactionResp) {
            if (referralId) {
              try {
                const serviceReq = await getReferralResource(referralId)
                const updateSRStatus = await updateServiceRequest({
                  ...serviceReq!,
                  status: 'completed',
                })
                if (updateSRStatus) {
                  dispatch(showSuccessAlert('Referral status updated '))
                }
              } catch (error) {
                dispatch(showErrorAlert('Error while updating referral status'))
              }
            }
            state.appointmentCreatedSuccessfully = true
            state.creatingAppointment = false
            state.orderId = serviceRequest
            dispatch(
              showSuccessAlert(
                getOrderCreationSuccessfulMessage(selectedPatient)
              )
            )
            dispatch(requestForIncomingLabReferralsCount())
            dispatch(requestForIncomingReferralsListsForLab())
            if (locationId) dispatch(searchingPartnerLabOrders(locationId))
            dispatch(requestOrdersCountForToday())

            dispatch(requestOrdersForTheDateRange([]))
            dispatch(requestOrdersForTheDateRange([]))
            dispatch(fetchOrderDetails(serviceRequest))
            dispatch(createOrderSlice.actions.updatedStatus(state))
            return
          }

          state.appointmentCreatedSuccessfully = false
          state.creatingAppointment = false
          state.orderId = ''
          state.errorWhileCreatingAppointment = false
          dispatch(createOrderSlice.actions.updatedStatus(state))
          return
        }
        if (selectedPaymentOption === 'cash') {
          const invResp: boolean = await finishTransaction(
            selectedLabOfferings,
            selectedServiceType,
            serviceRequest,
            paymentReconciliation,
            selectedPaymentOption,
            tasks,
            'lab-order',
            selectedPatient.id ?? '',
            selectedSlot,
            referralId
          )
          if (invResp) {
            state.appointmentCreatedSuccessfully = true
            state.creatingAppointment = false
            state.orderId = serviceRequest
            if (referralId) {
              try {
                const serviceReq = await getReferralResource(referralId)
                const updateSRStatus = await updateServiceRequest({
                  ...serviceReq!,
                  status: 'completed',
                })
                if (updateSRStatus) {
                  dispatch(showSuccessAlert('Referral status updated '))
                }
              } catch (error) {
                dispatch(showErrorAlert('Error while updating referral status'))
              }
            }

            dispatch(
              showSuccessAlert(
                getOrderCreationSuccessfulMessage(selectedPatient)
              )
            )
            dispatch(requestForIncomingLabReferralsCount())
            dispatch(requestForIncomingReferralsListsForLab())
            if (locationId) dispatch(searchingPartnerLabOrders(locationId))
            dispatch(requestOrdersCountForToday())

            dispatch(requestOrdersForTheDateRange([]))
            dispatch(requestOrdersForTheDateRange([]))
            dispatch(createOrderSlice.actions.updatedStatus(state))
            return
          }
          state.appointmentCreatedSuccessfully = false
          state.creatingAppointment = false
          state.orderId = ''
          state.errorWhileCreatingAppointment = false
          dispatch(createOrderSlice.actions.updatedStatus(state))
          return
        }
        if (referralId) {
          try {
            const serviceReq = await getReferralResource(referralId)
            const updateSRStatus = await updateServiceRequest({
              ...serviceReq!,
              status: 'completed',
            })
            if (updateSRStatus) {
              dispatch(showSuccessAlert('Referral status updated '))
            }
          } catch (error) {
            dispatch(showErrorAlert('Error while updating referral status'))
          }
        }
        state.appointmentCreatedSuccessfully = true
        state.creatingAppointment = false
        state.orderId = serviceRequest

        dispatch(
          showSuccessAlert(getOrderCreationSuccessfulMessage(selectedPatient))
        )
        dispatch(requestForIncomingLabReferralsCount())
        dispatch(requestForIncomingReferralsListsForLab())
        if (locationId) dispatch(searchingPartnerLabOrders(locationId))
        dispatch(requestOrdersCountForToday())
        dispatch(requestOrdersForTheDateRange([]))
        dispatch(requestOrdersForTheDateRange([]))

        dispatch(createOrderSlice.actions.updatedStatus(state))
        return
      }
      state.appointmentCreatedSuccessfully = false
      state.orderId = ''
      state.creatingAppointment = false
      state.errorWhileCreatingAppointment = false
      dispatch(createOrderSlice.actions.updatedStatus(state))
      return
    } catch (error) {
      logger.info(error)
      const errorSearchDoctor: CreateOrderStatus = {
        creatingAppointment: false,
        orderId: '',
        errorWhileCreatingAppointment: true,
        appointmentCreatedSuccessfully: false,
        errorReason: 'Error',
      }
      dispatch(createOrderSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

async function createTransactionLink(
  selectedLabOfferings: LabOfferingDetail[],
  selectedServiceType: string,
  serviceId: string,
  paymentReconciliationId: string,
  paymentType: string,
  tasks: string[],
  transactiontype: string,
  patientId: string,
  slotDetails?: FhirSlotDetail
): Promise<boolean> {
  const requestBody: R4.IParameters = getTransactionBodyResourceLabOrderOnline(
    selectedLabOfferings,
    selectedServiceType,
    serviceId,
    paymentReconciliationId,
    paymentType,
    transactiontype,
    patientId,
    slotDetails
  )
  if (requestBody) {
    logger.info('Payment body')
    logger.info(requestBody)
    const fhirApi: EnrolCient = new EnrolCient()
    const response: any = await fhirApi.doCreateEnrolmentFlowRequest(
      `/payment/initiate`,
      requestBody
    )
    logger.info('Payment Response')
    logger.info(response)

    if (response.status === 'created') {
      return true
    }
  }

  return false
}

async function finishTransaction(
  selectedLabOfferings: LabOfferingDetail[],
  selectedServiceType: string,
  serviceId: string,
  paymentReconciliationId: string,
  paymentType: string,
  tasks: string[],
  transactiontype: string,
  patientId: string,
  slotDetails?: FhirSlotDetail,
  referralId?: string
): Promise<boolean> {
  const requestBody: R4.IParameters =
    getTransactionBodyForOfflinePaymentForLabOrder(
      selectedLabOfferings,
      selectedServiceType,
      serviceId,
      paymentReconciliationId,
      paymentType,
      transactiontype,
      patientId,
      slotDetails,
      referralId
    )
  if (requestBody) {
    logger.info('Payment body')
    logger.info(requestBody)
    const fhirApi: EnrolCient = new EnrolCient()
    const response: any = await fhirApi.doCreateEnrolmentFlowRequest(
      `/payment/offline`,
      requestBody
    )
    logger.info('Payment Response')
    logger.info(response)

    if (response.status === 'active') {
      return true
    }
  }

  return false
}

function getOrderCreationTransactionObject(
  selectedLabOfferings: LabOfferingDetail[],
  selectedPaymentOption: string,
  selectedPatient: R4.IPatient,
  selectedServiceType: string,
  selectedAddress?: R4.IAddress,
  selectedSlot?: FhirSlotDetail,
  locationId?: string,
  partnerLabUser?: R4.IPractitionerRole,
  referralId?: string,
  encounterRef?: string
): R4.IBundle {
  const requestId: string = getUniqueTempId()
  const mainTaskId: string = getUniqueTempId()

  const currentServiceRequest: R4.IServiceRequest = {
    resourceType: 'ServiceRequest',
    subject: {
      id: selectedPatient.id,
      reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
      display: getNameOfPatient(selectedPatient),
    },
    instantiatesCanonical: selectedLabOfferings.map(
      (e) => `${e.planDefinition.resourceType}/${e.planDefinition.id}` ?? ''
    ),
    requester: {
      id: selectedPatient.id,
      reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
      display: getNameOfPatient(selectedPatient),
    },
    status: 'active',
    intent: 'order',
    category: [
      {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '108252007',
            display: 'Laboratory procedure',
          },
        ],
      },
    ],

    performer: [getCurrentUserUnitReference()!],
    priority: 'urgent',

    authoredOn: new Date().toISOString(),
    code: {
      coding: [
        {
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type',
          code: 'onsite-sample-collection',
          display: 'Onsite Collection',
        },
      ],
    },
    id: requestId,
  }

  if (selectedServiceType === 'home_collection') {
    if (selectedSlot?.slot?.start) {
      currentServiceRequest.occurrencePeriod = {
        start: selectedSlot?.slot?.start,
        end: selectedSlot?.slot?.end,
      }
    }
    currentServiceRequest.code = {
      coding: [
        {
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type',
          code: 'home-sample-collection',
          display: 'Home Collection',
        },
      ],
    }
  }

  if (selectedServiceType !== 'home_collection') {
    currentServiceRequest.occurrenceDateTime = new Date().toISOString()
  }

  //   if (referralId) {
  //     const refArray: R4.IReference[] = []
  //     const orderRef: R4.IReference = {
  //       reference: `ServiceRequest/${referralId}`,
  //       display: 'ServiceRequest',
  //     }
  //     refArray.push(orderRef)
  //     currentServiceRequest.supportingInfo = refArray
  //   }
  if (encounterRef) {
    const orderRef: R4.IReference = {
      reference: encounterRef ?? '',
      display: 'Encounter',
    }

    currentServiceRequest.encounter = orderRef
  }

  const currentPractRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()

  const mainTask: R4.ITask = {
    resourceType: 'Task',
    id: mainTaskId,
    status: TaskStatusKind._accepted,
    intent: TaskIntentKind._order,
    businessStatus: {
      text: 'Scheduled',
      coding: [
        {
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status',
          code: 'scheduled',
          display: 'Scheduled',
        },
      ],
    },

    code: {
      coding: LabOrderTaskCodes,
    },
    focus: {
      id: requestId,
      reference: `${currentServiceRequest.resourceType}/${currentServiceRequest.id}`,
    },
    for: {
      id: selectedPatient.id,
      reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
      display: getNameOfPatient(selectedPatient),
    },
    owner: {
      id: currentPractRole.id,
      reference: `${currentPractRole.resourceType}/${currentPractRole.id}`,
    },
    requester: {
      id: selectedPatient.id,
      reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
      display: getNameOfPatient(selectedPatient),
    },
  }

  const statusProvenance: R4.IProvenance = getProvenanceObjectForOrder(
    {
      serviceRequest: currentServiceRequest,
      task: mainTask,
      patient: selectedPatient,
      performerDetail: {
        practitioner: {
          resourceType: 'Practitioner',
        },
        practitionerRole: {
          resourceType: 'PractitionerRole',
        },
      },
      start: new Date().toISOString(),
      oldSlotRef: '',
    },
    {
      system: 'http://hl7.org/fhir/task-status',
      code: 'scheduled',
      display: 'Scheduled',
    }
  )

  mainTask.relevantHistory = [
    ...(mainTask.relevantHistory ?? []),
    {
      reference: `${statusProvenance.resourceType}/${
        statusProvenance.id ?? ''
      }`,
    },
  ]

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `${statusProvenance.resourceType}/`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: statusProvenance.resourceType,
        },
        resource: statusProvenance,
      },
      {
        fullUrl: currentServiceRequest.resourceType,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: currentServiceRequest.resourceType,
        },
        resource: currentServiceRequest,
      },
      {
        fullUrl: mainTask.resourceType,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: mainTask.resourceType,
        },
        resource: mainTask,
      },
    ],
  }

  for (let i = 0; i < selectedLabOfferings.length; i++) {
    const ref = getValueRefValueExtensionsOfUrl(
      selectedLabOfferings[i].planDefinition.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/PartnerLabRef'
    )

    if (ref) {
      const partnerTask: R4.ITask = {
        resourceType: 'Task',
        id: getUniqueTempId(),
        status: TaskStatusKind._accepted,
        intent: TaskIntentKind._order,
        focus: {
          id: requestId,
          reference: `${currentServiceRequest.resourceType}/${currentServiceRequest.id}`,
        },
        for: {
          id: selectedPatient.id,
          reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
          display: getNameOfPatient(selectedPatient),
        },
        businessStatus: {
          text: 'Scheduled',
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status',
              code: 'scheduled',
              display: 'Scheduled',
            },
          ],
        },
        owner: {
          id: currentPractRole.id,
          reference: `${currentPractRole.resourceType}/${currentPractRole.id}`,
        },
        requester: {
          id: selectedPatient.id,
          reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
          display: getNameOfPatient(selectedPatient),
        },
        code: {
          coding: PartnerLabOrderTaskCodes,
        },
        partOf: [
          {
            id: mainTaskId,
            reference: `${mainTask.resourceType}/${mainTaskId}`,
          },
        ],
      }

      const newPartnerTaskStatusProvenance: R4.IProvenance =
        getProvenanceObjectForTask(partnerTask, {
          system: 'http://hl7.org/fhir/task-status',
          code: 'scheduled',
          display: 'Scheduled',
        })

      partnerTask.relevantHistory = [
        ...(partnerTask.relevantHistory ?? []),
        {
          reference: `${newPartnerTaskStatusProvenance.resourceType}/${
            newPartnerTaskStatusProvenance.id ?? ''
          }`,
        },
      ]

      requestBundle.entry?.push({
        fullUrl: partnerTask.resourceType,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: partnerTask.resourceType,
        },
        resource: partnerTask,
      })

      requestBundle.entry?.push({
        fullUrl: `${newPartnerTaskStatusProvenance.resourceType}/`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: newPartnerTaskStatusProvenance.resourceType,
        },
        resource: newPartnerTaskStatusProvenance,
      })
    }
  }

  if (selectedServiceType === 'home_collection' && selectedSlot) {
    const modifiedSlot: R4.ISlot = { ...selectedSlot?.slot }
    modifiedSlot.status = R4.SlotStatusKind._busy
    const matchString: string = `W/${JSON.stringify(
      modifiedSlot.meta?.versionId ?? ' '
    )}`

    requestBundle.entry?.push({
      fullUrl: `Slot/${modifiedSlot.id}`,
      request: {
        ifMatch: matchString,
        method: R4.Bundle_RequestMethodKind._put,
        url: `Slot/${modifiedSlot.id}`,
      },
      resource: modifiedSlot,
    })

    const inputForAgentTaskForCash = [
      {
        type: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
              code: 'slot-reference',
            },
          ],
        },
        valueReference: {
          reference: `${selectedSlot.slot.resourceType}/${selectedSlot.slot.id}`,
          id: selectedSlot.slot.id,
          type: selectedSlot.slot.resourceType,
        },
      },
      {
        type: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
              code: 'collection-address',
            },
          ],
        },
        valueAddress: selectedAddress,
      },

      {
        type: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
              code: 'payment-pending',
            },
          ],
        },
        valueString: 'Payment Pending',
      },
    ]

    const inputForAgentTaskForOnline = [
      {
        type: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
              code: 'slot-reference',
            },
          ],
        },
        valueReference: {
          reference: `${selectedSlot.slot.resourceType}/${selectedSlot.slot.id}`,
          id: selectedSlot.slot.id,
          type: selectedSlot.slot.resourceType,
        },
      },
      {
        type: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
              code: 'collection-address',
            },
          ],
        },
        valueAddress: selectedAddress,
      },
    ]

    const subTask: R4.ITask = {
      resourceType: 'Task',
      id: getUniqueTempId(),
      status: TaskStatusKind._accepted,
      intent: TaskIntentKind._order,
      executionPeriod: {
        start: selectedSlot.slot.start,
        end: selectedSlot.slot.end,
      },
      focus: {
        id: requestId,
        reference: `${currentServiceRequest.resourceType}/${currentServiceRequest.id}`,
      },
      for: {
        id: selectedPatient.id,
        reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
        display: getNameOfPatient(selectedPatient),
      },
      businessStatus: {
        text: 'Scheduled',
        coding: [
          {
            system:
              'http://wellopathy.com/fhir/india/core/CodeSystem/trip-business-status',
            code: 'scheduled',
            display: 'Scheduled',
          },
        ],
      },
      owner: {
        id: selectedSlot.practitionerRole?.id ?? '',
        reference: `${selectedSlot.practitionerRole?.resourceType ?? ''}/${
          selectedSlot.practitionerRole?.id ?? ''
        }`,
      },
      requester: {
        id: selectedPatient.id,
        reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
        display: getNameOfPatient(selectedPatient),
      },
      code: {
        coding: LabOrderHomeCollectionTaskCodes,
      },
      partOf: [
        {
          id: mainTaskId,
          reference: `${mainTask.resourceType}/${mainTaskId}`,
        },
      ],
    }

    if (selectedPaymentOption === 'cash')
      subTask.input = inputForAgentTaskForCash
    else subTask.input = inputForAgentTaskForOnline

    const newSubTaskStatusProvenance: R4.IProvenance =
      getProvenanceObjectForTask(subTask, {
        system: 'http://hl7.org/fhir/task-status',
        code: 'created',
        display: 'Created',
      })

    subTask.relevantHistory = [
      ...(subTask.relevantHistory ?? []),
      {
        reference: `${newSubTaskStatusProvenance.resourceType}/${
          newSubTaskStatusProvenance.id ?? ''
        }`,
      },
    ]

    requestBundle.entry?.push({
      fullUrl: subTask.resourceType,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: subTask.resourceType,
      },
      resource: subTask,
    })

    requestBundle.entry?.push({
      fullUrl: `${newSubTaskStatusProvenance.resourceType}/`,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: newSubTaskStatusProvenance.resourceType,
      },
      resource: newSubTaskStatusProvenance,
    })
  }

  requestBundle.entry?.push({
    fullUrl: `ChargeItem/`,
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: 'ChargeItem',
    },
    resource: getChargeItemForSelectedPlans(
      selectedLabOfferings.map((e) => e.chargeItem!),
      selectedPatient,
      {
        reference: `ServiceRequest/${currentServiceRequest.id}`,
      }
    ),
  })

  requestBundle.entry?.push({
    fullUrl: `PaymentReconciliation`,
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: 'PaymentReconciliation',
    },
    resource: getPaymentReconciliationResource(
      {
        reference: `${mainTask.resourceType}/${mainTask.id}`,
      },
      {
        currency: 'INR',
        value: getTotalAmountOfLabOfferings(selectedLabOfferings),
      },
      selectedPaymentOption,
      selectedServiceType
    ),
  })

  logger.info(requestBundle)

  return requestBundle
}

async function handleNotification(
  appointment: string,
  patientId: string
): Promise<boolean> {
  const requestBody = {
    appointmentId: appointment.split('/')[1],
    unitType: 'lab',
  }

  if (requestBody) {
    logger.info('Payment body')
    logger.info(requestBody)
    const enRolClient: EnrolCient = new EnrolCient()
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `/reminder/appointmentReminder`,
      requestBody
    )
    logger.info('Payment Response')
    logger.info(response)

    if (response.includes(process.env.REACT_APP_PRE_APPOINTMENT_URL)) {
      return true
    }
  }

  return false
}

export default createOrderSlice.reducer
