import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { randomInt } from 'fp-ts/lib/Random'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import moment from 'moment'
import React from 'react'
import { isCurrentUserDoctor } from 'services/userDetailsService'
import {
  getExtensionValueOfNumber,
  getNameFromHumanName,
  getProfilePicDoctor,
  getSpecializationsList,
} from 'utils/fhirResourcesHelper'
import {
  getDateDiff,
  getSlotAmountForAppointment,
} from 'utils/fhirResoureHelpers/fhirSlotHelper'

interface Props {
  slot: FhirSlotDetail
  onSlotSelected: (selectedSlot: FhirSlotDetail) => void
  isSelected?: boolean
  fhirAppointmentDetail?: FhirAppointmentDetail
  followUp?: boolean
  id: string
}

export const SlotDisplay: React.FC<Props> = ({
  slot,
  onSlotSelected,
  isSelected = false,
  fhirAppointmentDetail,
  followUp,
  id,
}) => {
  const nonSelectedTextColor = useTheme().palette.primary.dark
  const nonSelectedBodyTextColor = useTheme().palette.text.primary

  return (
    <Box paddingY={0.5} style={{ cursor: 'pointer' }}>
      <Paper elevation={0}>
        <ListItem
          id={id}
          alignItems='center'
          selected={isSelected}
          style={{ borderRadius: 4, cursor: 'pointer' }}
          key={slot.slot.start ?? randomInt(345, 12345).toString()}
          onClick={(e) => {
            if (onSlotSelected) {
              onSlotSelected(slot)
            }
          }}
        >
          {!isCurrentUserDoctor() && (
            <ListItemAvatar>
              <Avatar
                alt={getNameFromHumanName(slot.practitioner?.name ?? [])}
                src={getProfilePicDoctor(slot.practitioner)}
              />
            </ListItemAvatar>
          )}
          <ListItemText
            color='primary'
            primary={
              <>
                {!isCurrentUserDoctor() && (
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: isSelected ? 'white' : nonSelectedTextColor,
                    }}
                  >
                    {getNameFromHumanName(slot.practitioner?.name ?? [])}
                  </Typography>
                )}
                {isCurrentUserDoctor() && (
                  <Typography
                    variant='subtitle2'
                    style={{
                      color: isSelected ? 'white' : nonSelectedBodyTextColor,
                    }}
                  >
                    {`${moment(slot.slot.start).format('hh:mm')}-${moment(
                      slot.slot.end
                    ).format('hh:mm a')}`}
                  </Typography>
                )}
              </>
            }
            secondary={
              <>
                {!isCurrentUserDoctor() && (
                  <Typography
                    component='span'
                    variant='subtitle2'
                    style={{
                      color: isSelected ? 'white' : nonSelectedBodyTextColor,
                    }}
                  >
                    {getSpecializationsList(
                      slot.practitionerRole?.specialty ?? []
                    )}
                  </Typography>
                )}
              </>
            }
          />
          <ListItemSecondaryAction>
            <ListItemText
              primary={
                <>
                  {fhirAppointmentDetail &&
                    followUp &&
                    slot.chargeItemDefiniton &&
                    getExtensionValueOfNumber(
                      slot.chargeItemDefiniton.extension ?? [],
                      'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                    ) > 0 && (
                      <Box>
                        {getDateDiff(
                          fhirAppointmentDetail.appointment.start,
                          slot.slot.start
                        ) <=
                          getExtensionValueOfNumber(
                            slot.chargeItemDefiniton.extension ?? [],
                            'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                          ) && (
                          <Typography
                            variant='subtitle1'
                            align='right'
                            style={{
                              color: isSelected
                                ? 'white'
                                : nonSelectedTextColor,
                              fontWeight: 'bold',
                            }}
                          >
                            ₹ 0
                          </Typography>
                        )}

                        {getDateDiff(
                          fhirAppointmentDetail.appointment.start,
                          slot.slot.start
                        ) >
                          getExtensionValueOfNumber(
                            slot.chargeItemDefiniton.extension ?? [],
                            'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                          ) && (
                          <Typography
                            variant='subtitle1'
                            align='right'
                            style={{
                              color: isSelected
                                ? 'white'
                                : nonSelectedTextColor,
                              fontWeight: 'bold',
                            }}
                          >
                            ₹{' '}
                            {getSlotAmountForAppointment(
                              slot.chargeItemDefiniton
                            )}
                          </Typography>
                        )}
                      </Box>
                    )}

                  {followUp === undefined && (
                    <Box>
                      <Typography
                        variant='subtitle1'
                        align='right'
                        style={{
                          color: isSelected ? 'white' : nonSelectedTextColor,
                          fontWeight: 'bold',
                        }}
                      >
                        ₹{' '}
                        {getSlotAmountForAppointment(slot.chargeItemDefiniton)}
                      </Typography>
                    </Box>
                  )}
                </>
              }
              secondary={
                <>
                  {!isCurrentUserDoctor() && (
                    <Typography
                      variant='subtitle2'
                      style={{
                        color: isSelected ? 'white' : nonSelectedBodyTextColor,
                      }}
                    >
                      {`${moment(slot.slot.start).format('hh:mm')}-${moment(
                        slot.slot.end
                      ).format('hh:mm a')}`}
                    </Typography>
                  )}
                </>
              }
            />
          </ListItemSecondaryAction>
        </ListItem>
      </Paper>
    </Box>
  )
}
