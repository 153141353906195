import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  LinearProgress,
  makeStyles,
  Typography,
  withStyles,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { uploadingData } from 'redux/patient/bulkAddPatient/bulkAddPatientSlice'
import { updatePatientCount } from 'redux/patient/patientCount/patientCountSlice'
import { RootState } from 'redux/rootReducer'

interface Props {
  patientDataBundle: R4.IBundle | undefined
}

const useStyles = makeStyles((theme) => ({
  test: {
    border: '1px',
    backgroundColor: 'white',
  },
  root: {
    width: 100,
  },
}))

const StyledLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 5,
  },
})(LinearProgress)
export const PatientDataUploadSuccess: React.FC<Props> = ({
  patientDataBundle,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const bulkPatientAddSlice = useSelector(
    (state: RootState) => state.bulkPatientAddSlice
  )
  const [uploadOrDownloadCount, setUploadOrDownloadCount] = React.useState(10)

  useEffect(() => {
    if (patientDataBundle) {
      dispatch(uploadingData(patientDataBundle))
    }
    dispatch(updatePatientCount())
  }, [dispatch, patientDataBundle])

  React.useEffect(() => {
    const timer = setInterval(() => {
      setUploadOrDownloadCount((beforeValue) =>
        beforeValue >= 100 ? 0 : beforeValue + 10
      )
    }, 800)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <div>
      <Box
        flexDirection='column'
        display='flex'
        justifyContent='flex-start'
        alignItems='flex-start'
        className={classes.test}
        p={1}
      >
        {bulkPatientAddSlice.uploading && (
          <Box>
            <Box paddingY={0.5}>
              <Typography variant='body1' color='primary'>
                {t(' Please wait while file data is getting uploaded.')}
              </Typography>
            </Box>

            <div className={classes.root}>
              <Box display='flex' alignItems='center'>
                <Box minWidth={30}>
                  <Typography variant='body2' color='primary'>{`${Math.round(
                    uploadOrDownloadCount
                  )}%`}</Typography>
                </Box>
                <Box width='100%' mr={0.8}>
                  <StyledLinearProgress
                    variant='determinate'
                    value={uploadOrDownloadCount}
                  />
                </Box>
              </Box>
            </div>
          </Box>
        )}
        {bulkPatientAddSlice.uploading === false &&
          bulkPatientAddSlice.error === false &&
          bulkPatientAddSlice.uploadingSuccessful === true && (
            <Box display='flex' flexDirection='column'>
              <Box paddingY={0.5}>
                <Typography variant='body1' color='primary'>
                  {t(' Patient data has been uploaded successfully.')}
                </Typography>
              </Box>
            </Box>
          )}

        {bulkPatientAddSlice.uploading === false &&
          bulkPatientAddSlice.error === true && (
            <Box display='flex' flexDirection='row'>
              <Box p={0.5}>
                <img
                  src={`${process.env.PUBLIC_URL}/Alerterror.png}`}
                  alt='test'
                  height={12.5}
                  width={12}
                />
              </Box>
              <Box paddingY={0.5}>
                <Typography variant='body1' color='secondary'>
                  {t(' Duplicate Data found while uploading. ')}
                </Typography>
              </Box>
            </Box>
          )}
      </Box>
    </div>
  )
}
