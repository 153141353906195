import {
  Box,
  Button,
  CircularProgress,
  ListItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { LocationData } from 'models/location'
import React, { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import {
  resetState,
  searchLocations,
} from 'redux/umls/location/locationSearchSlice'
import { logger } from 'utils/logger'

interface LabOfferingSelectorProps {
  onSelectionChanges?: (selectedValues: LocationData) => void
  doctorListId?: string
  preSelectedComplaints?: LocationData
  preSelectAll?: boolean
  disabled: boolean
  id?: string
  multiple?: boolean
}

export const SiteSelector: React.FC<LabOfferingSelectorProps> = ({
  onSelectionChanges,
  doctorListId,
  preSelectedComplaints,
  preSelectAll = false,
  disabled,
  id,
  multiple,
}: LabOfferingSelectorProps) => {
  const locationSearchSlice = useSelector(
    (state: RootState) => state.locationSearchSlice
  )
  const { t } = useTranslation(['labelCommon'])
  const dispatch = useDispatch()
  const [text, setText] = useState<string>('')

  //   useEffect(() => {
  //     dispatch(searchLabOfferings())
  //   }, [dispatch])
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
          <Button
            onClick={() => {
              dispatch(searchLocations(text))
            }}
          >
            Try again
          </Button>
        </div>
      )}
    >
      <Box width='100%'>
        <Box width='100%'>
          <Autocomplete
            id={id}
            multiple={false}
            onOpen={() => {}}
            onClose={() => {
              dispatch(resetState())
            }}
            style={{
              borderRadius: '4px',
            }}
            disableClearable
            fullWidth
            size='small'
            getOptionSelected={(option, value) =>
              option.display === value.display
            }
            disabled={disabled}
            defaultValue={preSelectedComplaints}
            getOptionLabel={(option) => option.display}
            options={locationSearchSlice.locationList ?? []}
            loading={locationSearchSlice.searching}
            autoComplete
            includeInputInList
            filterSelectedOptions
            onChange={(e, changedValue, reason) => {
              logger.info(changedValue)
              if (onSelectionChanges) onSelectionChanges(changedValue ?? [])
            }}
            ChipProps={{
              deleteIcon: (
                <ClearOutlined
                  style={{
                    height: '15px',
                    color: 'white',
                  }}
                />
              ),
              style: {
                backgroundColor: kPrimaryLight,
                borderRadius: '4px',
              },
            }}
            renderOption={(option, { inputValue }) => {
              const matches = match(option.display, inputValue)
              const parts = parse(option.display, matches)
              return (
                <ListItem id={`lab_test_opt_${option.cui}`}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={part.text}
                        style={{
                          backgroundColor: part.highlight
                            ? kPrimaryLight
                            : undefined,
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </ListItem>
              )
            }}
            filterOptions={(x) => x}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('labelCommon:type_atleast_3_chars')}
                variant='outlined'
                onChange={(e) => {
                  if (e.target.value.length > 2) {
                    dispatch(searchLocations(e.target.value))
                    setText(e.target.value)
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {locationSearchSlice.searching ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>

        {locationSearchSlice.error && (
          <Typography>
            {' '}
            {locationSearchSlice.errorMessage ?? 'Error while searching'}
          </Typography>
        )}
        {locationSearchSlice.noResultsAvailable && (
          <Typography> No Results available</Typography>
        )}
      </Box>
    </ErrorBoundary>
  )
}
