import { R4 } from '@ahryman40k/ts-fhir-types'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import {
  BundleTypeKind,
  ContactPointUseKind,
  HumanNameUseKind,
  IdentifierUseKind,
  PatientGenderKind,
  SlotStatusKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { truncate } from 'fs'
import moment from 'moment'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitDetails,
  getPractitionerRoleObject,
} from 'services/userDetailsService'
import {
  getTaskObjectForConsumerInvitation,
  getTaskObjectForProviderInvitation,
} from 'utils/fhirResoureHelpers/invitationHelpers'
import { getEmailOfPatient, getTelecomOfPatient } from 'wello-web-components'
import { titleCase } from 'utils/fhirResourcesHelper'
import { PatientDataHelper } from '../../models/patientDataHelper'

const getInviteDetails = (patientData: R4.IPatient, ref: string) => {
  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const taskObject: R4.ITask[] = getTaskObjectForConsumerInvitation(
    getEmailOfPatient(patientData) ?? '',
    getTelecomOfPatient(patientData, R4.ContactPointSystemKind._phone) ?? '',
    {
      type: 'PractitionerRole',
      reference: `${'PractitionerRole/'}${practRole.id ?? ''}`,
    },
    ref ?? '',
    false
  )

  return taskObject
}

export function getFhirPatientBundleFromExcel(
  patientDataArr: PatientDataHelper[]
): R4.IBundle {
  const bundleArray: R4.IBundle_Entry[] = []
  for (let i = 0; i < patientDataArr.length; i++) {
    const pat: R4.IPatient = {
      resourceType: 'Patient',
    }
    const nameArr = patientDataArr[i].name.split(' ')
    let lastName = ''
    if (nameArr.length > 1) lastName = nameArr[1]
    const humanName: R4.IHumanName = {}
    humanName.given = [nameArr[0]]
    humanName.family = lastName
    humanName.use = HumanNameUseKind._official
    if (pat.name == null) {
      pat.name = [humanName]
    } else {
      pat.name.push(humanName)
    }

    if (patientDataArr[i].address.length > 0) {
      const address: R4.IAddress = {}
      const add: string[] = []
      const patAddress: R4.IAddress[] = []
      add.push(patientDataArr[i].address)
      address.line = add
      address.use = R4.AddressUseKind._home
      address.type = R4.AddressTypeKind._both
      patAddress.push(address)
      pat.address = patAddress
    }

    const mainOrganization: R4.IOrganization =
      getCurrentUserMainOrganizationDetails()

    pat.managingOrganization = {
      id: mainOrganization.id ?? '',
      display: mainOrganization.name,
      reference: `${mainOrganization.resourceType}/${mainOrganization.id}`,
    }

    const phoneContact: R4.IContactPoint = {}
    phoneContact.use = ContactPointUseKind._mobile
    phoneContact.system = R4.ContactPointSystemKind._phone
    phoneContact.rank = 1
    phoneContact.value = `+91${patientDataArr[i].phone}`

    if (pat.telecom == null) {
      pat.telecom = [phoneContact]
    } else {
      pat.telecom.push(phoneContact)
    }

    const emailContact: R4.IContactPoint = {}
    emailContact.use = ContactPointUseKind._home
    emailContact.system = R4.ContactPointSystemKind._email
    emailContact.rank = 2
    emailContact.value = patientDataArr[i].email

    if (pat.telecom == null) {
      pat.telecom = [emailContact]
    } else {
      pat.telecom.push(emailContact)
    }
    const dateRes: E.Either<Errors, string> = R4.RTTI_date.decode(
      patientDataArr[i].dob
    )

    if (dateRes._tag === 'Right') {
      pat.birthDate = dateRes.right
    } else {
      throw Error('Invalid date format')
    }

    pat.gender =
      patientDataArr[i].gender === PatientGenderKind._male
        ? PatientGenderKind._male
        : patientDataArr[i].gender === PatientGenderKind._female
        ? PatientGenderKind._female
        : patientDataArr[i].gender === PatientGenderKind._other
        ? PatientGenderKind._other
        : PatientGenderKind._unknown
    pat.deceasedBoolean = false
    pat.active = true

    const identifiers: R4.IIdentifier[] = []
    // const emailIdentifier: R4.IIdentifier = {}
    // emailIdentifier.system = 'email'
    // emailIdentifier.value = patientDataArr[i].email
    // identifiers.push(emailIdentifier)

    const phoneIdentifier: R4.IIdentifier = {}
    phoneIdentifier.value = `+91${patientDataArr[i].phone}`
    phoneIdentifier.system = 'phone'
    identifiers.push(phoneIdentifier)
    pat.extension = []
    const unitOrg = getCurrentUserUnitDetails()
    const unitExtension: R4.IExtension = {
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/ReferralSourceOrganization',
      valueReference: {
        id: unitOrg.id,
        reference: `Organization/${unitOrg.id}`,
        display: unitOrg.name,
      },
    }
    pat.extension.push(unitExtension)

    const labRefIdentifier: R4.IIdentifier = {}
    labRefIdentifier.value = `${patientDataArr[i].labRefId}`
    labRefIdentifier.system = 'labReference'
    identifiers.push(labRefIdentifier)

    const matchString: string = `identifier=${patientDataArr[i].labRefId}`
    pat.identifier = identifiers
    const iRequest: R4.IBundle_Request = {}
    iRequest.method = R4.Bundle_RequestMethodKind._post
    iRequest.url = `Patient?identifier=${patientDataArr[i].labRefId}`
    iRequest.ifNoneExist = matchString
    const bundleEntry: R4.IBundle_Entry = {
      fullUrl: `urn:uuid:patient${i}`,
      request: iRequest,
      resource: pat,
    }
    bundleArray.push(bundleEntry)
    const ref = `urn:uuid:patient${i}`
    const taskArray = getInviteDetails(pat, ref)
    for (let j = 0; j < taskArray.length; j++) {
      const taskEntry: R4.IBundle_Entry = {
        fullUrl: `urn:uuid:task${j}`,
        request: {
          url: `${taskArray[j].resourceType}`,
          method: R4.Bundle_RequestMethodKind._post,
        },
        resource: taskArray[j],
      }
      bundleArray.push(taskEntry)
    }
  }
  const patientBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: bundleArray,
  }

  return patientBundle
}

export function getFhirRelatedObjectFromForm(
  formData: any
): R4.IRelatedPerson | undefined {
  if (formData && formData.fathersName.length > 0) {
    const relatedPat: R4.IRelatedPerson = {
      resourceType: 'RelatedPerson',
      patient: {
        reference: 'Patient/example',
      },
    }
    if ('id' in formData) {
      relatedPat.id = formData.id
    }

    const identifier: R4.IIdentifier = {}
    identifier.use = IdentifierUseKind._usual
    identifier.system = 'mrn-tenant1'
    identifier.value = `related${Math.random().toString(36).substring(2)}`
    if (relatedPat.identifier == null) {
      relatedPat.identifier = [identifier]
    } else {
      relatedPat.identifier.push(identifier)
    }

    const humanName: R4.IHumanName = {}
    let nameData: string[] = []
    if (formData.fathersName.indexOf(' ') === -1) {
      titleCase(formData.lastName)
      nameData.push(titleCase(formData.fathersName))
      humanName.given = [titleCase(formData.fathersName)]
    } else {
      nameData = formData.fathersName.split(' ')
      if (nameData.length === 3) {
        humanName.given = [titleCase(nameData[0])]
        humanName.family = titleCase(nameData[2])
      } else if (nameData.length === 2) {
        humanName.given = [titleCase(nameData[0])]
        humanName.family = titleCase(nameData[1])
      }
    }
    humanName.use = HumanNameUseKind._official
    if (relatedPat.name == null) {
      relatedPat.name = [humanName]
    } else {
      relatedPat.name.push(humanName)
    }

    const relationShipCodeableList: React.SetStateAction<
      R4.ICodeableConcept[]
    > = []
    const relationShipCodeable: R4.ICodeableConcept = {}
    const relationShipCodingList: React.SetStateAction<R4.ICoding[]> = []
    const fatherRelation: R4.ICoding = {
      system: 'http://hl7.org/fhir/ValueSet/relatedperson-relationshiptype',
      code: 'FTH',
      display: 'father',
    }
    relationShipCodingList.push(fatherRelation)
    relationShipCodeable.coding = relationShipCodingList
    relationShipCodeableList.push(relationShipCodeable)
    relatedPat.relationship = relationShipCodeableList

    return relatedPat
  }

  return undefined
}

export function getFhirPractitionerObjectFromForm(
  formData: any
): R4.IPractitioner | undefined {
  if (formData) {
    const pat: R4.IPractitioner = {
      resourceType: 'Practitioner',
    }
    if ('id' in formData) {
      pat.id = formData.id
    }
    const identifiers: R4.IIdentifier[] = []
    const emailIdentifier: R4.IIdentifier = {}

    emailIdentifier.system = 'email'
    emailIdentifier.value = formData.email
    identifiers.push(emailIdentifier)

    /*  if (pat.identifier == null) {
      pat.identifier = [emailIdentifier]
    } else {
      pat.identifier.push(emailIdentifier)
    } */

    const phoneIdentifier: R4.IIdentifier = {}

    phoneIdentifier.value = `+91${formData.phone}`
    emailIdentifier.system = 'phone'
    identifiers.push(phoneIdentifier)

    pat.identifier = identifiers

    const humanName: R4.IHumanName = {}
    humanName.given = [formData.name]
    humanName.use = HumanNameUseKind._official
    if (pat.name == null) {
      pat.name = [humanName]
    } else {
      pat.name.push(humanName)
    }
    const phoneContact: R4.IContactPoint = {}
    phoneContact.use = ContactPointUseKind._mobile
    phoneContact.system = R4.ContactPointSystemKind._phone
    phoneContact.rank = 1
    phoneContact.value = formData.phone

    if (pat.telecom == null) {
      pat.telecom = [phoneContact]
    } else {
      pat.telecom.push(phoneContact)
    }

    const emailContact: R4.IContactPoint = {}
    emailContact.use = ContactPointUseKind._home
    emailContact.system = R4.ContactPointSystemKind._email
    emailContact.rank = 1
    emailContact.value = formData.email

    if (pat.telecom == null) {
      pat.telecom = [emailContact]
    } else {
      pat.telecom.push(emailContact)
    }
    return pat
  }

  return undefined
}

export function getFhirPractitionerRoleObjectFromForm(
  formData: any
): R4.IPractitionerRole | undefined {
  if (formData) {
    const patRole: R4.IPractitionerRole = {
      resourceType: 'PractitionerRole',
    }
    if ('id' in formData) {
      patRole.id = formData.id
    }

    const identifier: R4.IIdentifier = {}
    identifier.use = IdentifierUseKind._usual
    identifier.system = 'mrn-tenant1'
    identifier.value = `patRole${Math.random().toString(36).substring(2)}`
    if (patRole.identifier == null) {
      patRole.identifier = [identifier]
    } else {
      patRole.identifier.push(identifier)
    }

    const patRoleList: React.SetStateAction<R4.ICodeableConcept[]> = []
    const roleCodeable: R4.ICodeableConcept = {}
    const roleCodingList: React.SetStateAction<R4.ICoding[]> = []
    const role1: R4.ICoding = {
      code: formData.role,
      display: 'Doctor',
    }
    roleCodingList.push(role1)
    roleCodeable.coding = roleCodingList
    patRoleList.push(roleCodeable)
    patRole.code = patRoleList

    return patRole
  }

  return undefined
}
