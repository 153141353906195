import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { BpCollection } from 'models/bpData'
import { DateWiseVitals } from 'models/dateWiseVitals'
import { VitalsData } from 'models/vitalsData'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import {
  getSortedObservation,
  getSortedObservationForIpd,
} from 'utils/appointment_handle/vitals_util'
import {
  getObserVationFfromEncounter,
  getObserVationFfromEncounterForDateWise,
} from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { getDateWiseVitals } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { logger } from 'utils/logger'
import { VitalsDetails } from './vitalsDetailStatusType'

const initialState: VitalsDetails = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingProcedures: false,
  showAppointment: false,
}

const vitalsDetailsSliceIpd = createSlice({
  name: 'vitalsDetailsSliceIpd',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<VitalsDetails>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.vitalsList = action.payload.vitalsList
      state.keys = action.payload.keys
      state.dateWiseVitals = action.payload.dateWiseVitals
      state.vitalData = action.payload.vitalData
      state.resultData = action.payload.resultData
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
      state.showAppointment = action.payload.showAppointment
      state.vital = action.payload.vital
    },
  },
})

export const requestVitalDetailsOfPatientIPD =
  (selectedPatient: R4.IPatient, appointmentId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: VitalsDetails = {
      searchingConditions: true,
      errorWhileSearchingProcedures: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      showAppointment: false,
    }
    dispatch(vitalsDetailsSliceIpd.actions.updatedStatus(state))
    try {
      const fhirClient: MasterFhirClient = new MasterFhirClient()
      const searchParameters: any = {
        'based-on': appointmentId,
      }

      const response: any = await fhirClient.doGetResourceForAppointmentIPD(
        `Encounter?_revinclude=Encounter:part-of&_revinclude=Observation:encounter&_count=500`,
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingProcedures = true
        state.searchingConditions = false
        state.showAppointment = false

        dispatch(vitalsDetailsSliceIpd.actions.updatedStatus(state))
      } else {
        const proceduresResponse: R4.IBundle = resp.right
        if (proceduresResponse?.total && proceduresResponse?.total > 0) {
          const observationData = getObserVationFfromEncounter(
            proceduresResponse
          ).sort((a, b) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )

          if (observationData.length > 0) {
            const vitalLisData: VitalsData[] =
              getObserVationFfromEncounterForDateWise(proceduresResponse)
            const dateWise: DateWiseVitals[] = getDateWiseVitals(vitalLisData)
            dateWise.sort((a, b) => moment(b.date).diff(a.date))
            console.log(dateWise.sort)

            const groupByData = groupBy(vitalLisData, 'startDate')
            if (vitalLisData.length > 0) {
              const keys: string[] = Object.keys(groupByData).sort()
              vitalLisData.sort((a, b) => moment(b.startDate).diff(a.startDate))
              state.resultsAvailable = true
              state.searchingConditions = false
              state.dateWiseVitals = dateWise
              state.keys = keys
              state.vitalData = vitalLisData
              state.resultData = groupByData
              state.vital = vitalLisData[vitalLisData.length - 1]
              state.noResultsAvailable = false
              state.errorReason = undefined
              state.errorWhileSearchingProcedures = false
              dispatch(vitalsDetailsSliceIpd.actions.updatedStatus(state))
            } else {
              const errorSearchDoctor: VitalsDetails = {
                searchingConditions: false,
                errorWhileSearchingProcedures: false,
                resultsAvailable: false,
                noResultsAvailable: true,
                showAppointment: false,
              }
              dispatch(
                vitalsDetailsSliceIpd.actions.updatedStatus(errorSearchDoctor)
              )
            }
          } else {
            const errorSearchDoctor: VitalsDetails = {
              searchingConditions: false,
              errorWhileSearchingProcedures: false,
              resultsAvailable: false,
              noResultsAvailable: true,
              showAppointment: false,
            }
            dispatch(
              vitalsDetailsSliceIpd.actions.updatedStatus(errorSearchDoctor)
            )
          }
        } else {
          const errorSearchDoctor: VitalsDetails = {
            searchingConditions: false,
            errorWhileSearchingProcedures: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            showAppointment: false,
          }
          dispatch(
            vitalsDetailsSliceIpd.actions.updatedStatus(errorSearchDoctor)
          )
        }
      }
    } catch (error) {
      /* */
      logger.error(error)
      const errorSearchDoctor: VitalsDetails = {
        searchingConditions: false,
        errorWhileSearchingProcedures: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
        showAppointment: false,
      }
      dispatch(vitalsDetailsSliceIpd.actions.updatedStatus(errorSearchDoctor))
    }
  }

async function updateEnrolment(bpCollection: BpCollection): Promise<string> {
  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `/enrolment/update-bp`,
      bpCollection
    )
    return response.BPCategory
  } catch (error) {
    return ''
  }
}

const groupBy = function (xs: VitalsData[], key: string) {
  return xs.reduce((rv: any, x: any) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

async function updateAppointment(): Promise<string> {
  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const enRolClient: EnrolCient = new EnrolCient()
  //   const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
  //     `/enrolment/update-bp`,
  //     bpCollection
  //   )

  return 'test'
}

async function getServiceRequestId(patientId: string): Promise<string> {
  logger.info('Patient invitation body')
  let serviceRequestId: string = ''
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirClient.doGetResource(
    `/ServiceRequest??code=chronic-care-enrolment&subject=${patientId}`
  )
  logger.info('Patient invitation Response')
  logger.info(response)
  const respDecoded: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (respDecoded._tag === 'Right') {
    logger.info('Response Patient un decoded', respDecoded.right)
    const responseBundel: R4.IBundle = respDecoded.right
    if (responseBundel.entry) {
      const entryObj = responseBundel.entry?.[0].resource as R4.IServiceRequest
      serviceRequestId =
        entryObj && entryObj.status === 'active' ? entryObj.id ?? ' ' : ''
      return serviceRequestId
    }
    return serviceRequestId
  }

  return serviceRequestId
}

export default vitalsDetailsSliceIpd.reducer
