import { Box, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getAppointmentIdForFollowUp,
  getCommunicationIdForAppointment,
} from 'utils/fhirResourcesHelper'
import { hasSpaces } from 'utils/fhirResoureHelpers/allergyHelpers'
import moment from 'moment'
import { ReasonForVisitTile } from '../appointments/reasonVisitTile'
import { AudioAttachment } from '../common/audioPlayer'
import { PlanFollowUp } from './plan/nextStepsFollowUp'
import { PlanSplit } from './plan/nextStepsSplit'
import { ChiefComplaint } from './subjective/chiefComplaintsDetails'
import { FollowUpChiefComplaint } from './subjective/follwoUpChiefComplaints'
import { MedicalAndHealthHistoryOfPatient } from './subjective/history_details'
import { ReviewOfSystem } from './subjective/review_of_systoms'

interface SubjectivePartOfConsultationProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
}

export const SubjectivePartOfConsultation: React.FC<SubjectivePartOfConsultationProps> =
  ({ fhirAppointmentDetails, split }: SubjectivePartOfConsultationProps) => {
    const [t] = useTranslation(['en', 'hi', 'labelCommon'])
    const [addButton, showAddButton] = useState<boolean>(false)

    return (
      <Box flexDirection='column' width='101%' py={1}>
        <Typography variant='subtitle1' style={{ fontSize: 15 }}>
          Reason for Visit
        </Typography>

        {getCommunicationIdForAppointment(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        ).length === 0 &&
          fhirAppointmentDetails.appointment.comment === undefined && (
            <Box display='flex' width='40%' py={1}>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                No data available
              </Typography>
            </Box>
          )}
        {getCommunicationIdForAppointment(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        ) && (
          <Box display='flex' width={split ? '60%' : '40%'} py={1}>
            <AudioAttachment
              contentType='audio/aac'
              decodingRequired={false}
              audioPath='Communication.payload.content'
              resourceId={getCommunicationIdForAppointment(
                fhirAppointmentDetails.appointment.supportingInformation ?? []
              )}
              resourceType='Communication'
            />
          </Box>
        )}
        {fhirAppointmentDetails.appointment.comment &&
          fhirAppointmentDetails.appointment.comment.trim().length > 0 && (
            <Box
              display='flex'
              width={split === true ? '100%' : '80%'}
              py={0.5}
            >
              <ReasonForVisitTile
                type={fhirAppointmentDetails.appointment.comment.trim()}
                date={
                  fhirAppointmentDetails.appointment.meta
                    ? fhirAppointmentDetails.appointment.meta.lastUpdated
                      ? moment(
                          fhirAppointmentDetails.appointment.meta.lastUpdated
                        ).format('Do MMMM YYYY')
                      : ''
                    : ''
                }
              />
            </Box>
          )}

        <Box display='flex' width={split === true ? '100%' : '80%'}>
          <ChiefComplaint
            fhirAppointmentDetails={fhirAppointmentDetails}
            split={split}
          />
        </Box>
        {getAppointmentIdForFollowUp(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        ).length > 0 && (
          <Box display='flex' width={split === true ? '100%' : '80%'}>
            <FollowUpChiefComplaint
              fhirAppointmentDetails={fhirAppointmentDetails}
              split={split}
            />
          </Box>
        )}
        {/* <Box display='flex' width='70%'>
          <ReviewOfSystem
            fhirAppointmentDetails={fhirAppointmentDetails}
            split={split}
          />
        </Box> */}
        <Box display='flex' width={split === true ? '100%' : '80%'}>
          <MedicalAndHealthHistoryOfPatient
            fhirAppointmentDetails={fhirAppointmentDetails}
            split={split}
          />
        </Box>
        {getAppointmentIdForFollowUp(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        ).length > 0 &&
          split === false && (
            <Box display='flex' flexDirection='row' width='100%'>
              <PlanFollowUp
                fhirAppointmentDetails={fhirAppointmentDetails}
                split={true}
                followup={true}
              />
            </Box>
          )}
      </Box>
    )
  }
