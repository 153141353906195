import Resizer from 'react-image-file-resizer'

/* export const resizeFile = (file: string): Promise<string> => {
  const convertedFile: Blob = dataURItoBlob(file)
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      convertedFile,
      300,
      300,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri as string)
      },
      'base64'
    )
  })
} */

export async function dataURItoBlob(dataURI: string): Promise<Blob> {
  const blob = await (await fetch(dataURI)).blob()
  return blob
}
