import { CURRENT_VENDOR } from './appConstants'

export const storeVendorPartId = (vendorPart: string) => {
  localStorage.removeItem(CURRENT_VENDOR)
  localStorage.setItem(CURRENT_VENDOR, vendorPart)
}

export const getVendorPartId = (): string | null =>
  localStorage.getItem(CURRENT_VENDOR)

export const removeVendorPart = () => {
  localStorage.removeItem(CURRENT_VENDOR)
}

export const getAdjustedPath = (path: string): string => {
  if (getVendorPartId() != null) {
    if (window.location.href.includes(getVendorPartId() as string)) {
      return `/${getVendorPartId()}${path}`
    }
    return `/${getVendorPartId()}${path}`
  }
  return path
}
