import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography } from '@material-ui/core'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import moment from 'moment'
import React from 'react'
import {
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
} from 'utils/fhirResourcesHelper'
import { getRoomNo, isOPD } from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { MembershipCheckOfPatient } from '../administration/membership/memberShipCheckerOfPatient'

interface Props {
  ipdDetails: FhirClinicIpdDetails
  id: string
  onSubscriptionDetailsFetched: ({
    isSubscribed,
    coverage,
  }: {
    isSubscribed: boolean
    coverage?: R4.ICoverage
  }) => void
}

export const PatientAndRoomDetailsOfIPDForInvoice: React.FC<Props> = ({
  ipdDetails,
  id,
  onSubscriptionDetailsFetched,
}: Props) => (
  <Box
    display='flex'
    flexDirection='row'
    alignContent='start'
    justifyContent='start'
  >
    <Box
      display='flex'
      flexDirection='column'
      alignContent='start'
      justifyContent='start'
      width='50%'
    >
      <Box display='flex' flexDirection='row'>
        <Typography variant='h6' color='initial'>
          {`${getNameOfPatient(ipdDetails.patient)} ${getMiddleNameOfPatient(
            ipdDetails.patient
          )} ${getLastNameOfPatient(ipdDetails.patient)}`}
        </Typography>

        <MembershipCheckOfPatient
          patientResource={ipdDetails.patient}
          display={false}
          onSubscriptionDetailsFetched={({
            isSubscribed,
            coverage,
          }: {
            isSubscribed: boolean
            coverage?: R4.ICoverage | undefined
          }) => {
            onSubscriptionDetailsFetched({ isSubscribed, coverage })
          }}
        />
      </Box>
      <Box>
        {!isOPD(ipdDetails) && (
          <Typography variant='subtitle1' style={{}}>
            {`${getRoomNo(ipdDetails)}`}
          </Typography>
        )}
      </Box>

      {/* <Typography variant='subtitle1' style={{ fontWeight: 600 }}>
        {getIdentificationNumber(ipdDetails)}
      </Typography>
      <Typography variant='subtitle1' style={{}}>
        {isMarried(ipdDetails.patient.maritalStatus) ? 'Married, ' : 'Single, '}{' '}
        {getAgeOfPatientData(ipdDetails.patient)}
      </Typography> */}
    </Box>

    {/* <Box
      display='flex'
      flexDirection='column'
      alignContent='start'
      justifyContent='start'
      flexGrow={1}
      width='40%'
    >
      <Box display='flex' flexDirection='column'>
        <Typography variant='subtitle1' style={{ fontWeight: 600 }}>
          {getIdentificationNumber(ipdDetails)}
        </Typography>

        <Box>
          {!isOPD(ipdDetails) && (
            <Typography variant='subtitle1' style={{}}>
              {`${getRoomNo(ipdDetails)}`}
            </Typography>
          )}
        </Box>
      </Box>
    </Box> */}

    <Box
      display='flex'
      flexDirection='column'
      alignContent='start'
      justifyContent='start'
      flexGrow={1}
      width='30%'
    >
      <Box display='flex' flexDirection='column'>
        <Box display='flex' flexDirection='row'>
          <Typography
            variant='subtitle1'
            color='initial'
            style={{
              fontWeight: 'bold',
            }}
          >
            DOA :{'  '}
          </Typography>
          <Typography
            variant='subtitle1'
            color='initial'
            style={{ paddingLeft: 3.2 }}
          >
            {moment(ipdDetails.serviceRequest.occurrencePeriod?.start).format(
              'DD-MM-YYYY'
            )}
          </Typography>
        </Box>
        <Box display='flex' flexDirection='row'>
          <Typography
            variant='subtitle1'
            color='initial'
            style={{
              fontWeight: 'bold',
            }}
          >
            DOD :{'  '}
          </Typography>
          <Typography
            variant='subtitle1'
            color='initial'
            style={{ paddingLeft: 3.2 }}
          >
            {moment(ipdDetails.serviceRequest.occurrencePeriod?.end).format(
              'DD-MM-YYYY'
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  </Box>
)
