import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { addMedicationRecommendations } from 'redux/consultation/recommendation_additions/meidcation_addition/medicationAddidionSlice'
import { RootState } from 'redux/rootReducer'
import { getCarePlanIdFromTaskOutput } from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import { getDisplayOfSystemFromCodableConcept } from 'utils/fhirResourcesHelper'
import {
  ConfirmationDialogProps,
  ConfirmationDialog,
} from 'views/components/common/deleteConfirmationDialog'
import VisitAppointmentContext from 'views/contexts/visitContextDetails'
import { AddMedicationsFromCPGHandler } from './add_medication_pop_up'

const useStyles = makeStyles((theme) => ({
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))

interface MedicationForPopUp {
  open: boolean
  medications?: R4.IMedicationRequest[]
}
interface AddMedicationRecommendationProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  medications: R4.IMedicationRequest[]
  carePlanIdentifier?: string
}

export const AddMedicationRecommendationButton: React.FC<AddMedicationRecommendationProps> =
  ({
    fhirAppointmentDetails,
    medications,
    carePlanIdentifier,
  }: AddMedicationRecommendationProps) => {
    const visitContext = useContext(VisitAppointmentContext)

    const medicationAdditionSlice = useSelector(
      (state: RootState) => state.medicationAdditionSlice
    )
    const subscriptionStatus = useSelector(
      (state: RootState) => state.patientSubscriptionForUnitSlice
    )

    const [showOfferDeleteConfirmation, setShowOfferDeleteConfirmation] =
      useState<MedicationForPopUp>({
        open: false,
      })
    const classes = useStyles()
    const dispatch = useDispatch()
    const [openAddMedicationDialogue, setOpenAddMedicationDialogue] =
      useState<MedicationForPopUp>({
        open: false,
      })
    const [medStored, setMedStored] = useState<R4.IMedicationRequest[]>()
    return (
      <Box>
        <Box flexDirection='row'>
          {medicationAdditionSlice.addingInstruction && (
            <Box height={25} width={25}>
              <CircularProgress size={20} />
            </Box>
          )}

          {medicationAdditionSlice.errorWhileAdding && medStored && (
            <Box flexDirection='row'>
              <Button
                variant='text'
                color='primary'
                id='med_retry'
                className={classes.buttonText}
                onClick={(e) => {
                  if (subscriptionStatus.tasks) {
                    const carePlanId = getCarePlanIdFromTaskOutput(
                      subscriptionStatus.tasks[0].task
                    )

                    if (carePlanId)
                      dispatch(
                        addMedicationRecommendations(
                          fhirAppointmentDetails.patient,
                          medStored,
                          fhirAppointmentDetails,
                          carePlanIdentifier,
                          carePlanId
                        )
                      )
                  }
                  e.stopPropagation()
                }}
              >
                Retry
              </Button>

              <Button
                variant='text'
                color='secondary'
                className={classes.rejectButtonText}
                onClick={(e) => {}}
              >
                Reject
              </Button>
            </Box>
          )}
          {medicationAdditionSlice.additionSuccessful && (
            <Box flexDirection='row' px={2}>
              <Typography variant='subtitle1' color='initial'>
                Added
              </Typography>
            </Box>
          )}
          {!medicationAdditionSlice.additionSuccessful &&
            !medicationAdditionSlice.errorWhileAdding &&
            !medicationAdditionSlice.addingInstruction && (
              <Box flexDirection='row'>
                <Button
                  variant='text'
                  color='primary'
                  className={classes.buttonText}
                  disabled={medications.length === 0}
                  onClick={(e) => {
                    if (
                      subscriptionStatus.subscriptionStatus === 'interested' ||
                      subscriptionStatus.subscriptionStatus === 'active'
                    ) {
                      setOpenAddMedicationDialogue({
                        open: true,
                        medications,
                      })
                    } else {
                      dispatch(
                        showErrorAlert(
                          'Please subscribe to the plan to add medications'
                        )
                      )
                    }

                    e.stopPropagation()
                  }}
                  id='med_finalize'
                >
                  Finalize & Add
                </Button>

                {/*   <Button
                  variant='text'
                  color='secondary'
                  className={classes.rejectButtonText}
                >
                  Reject
                </Button> */}
              </Box>
            )}
        </Box>

        {openAddMedicationDialogue.open &&
          visitContext &&
          visitContext.patient &&
          visitContext.visitReference && (
            <AddMedicationsFromCPGHandler
              open={openAddMedicationDialogue.open}
              fhirAppointmentDetails={
                visitContext.visitReference as FhirAppointmentDetail
              }
              preAddedMedications={(
                openAddMedicationDialogue.medications ?? []
              ).sort((a, b) =>
                (
                  getDisplayOfSystemFromCodableConcept(
                    a.medicationCodeableConcept
                  ) ?? ''
                ).localeCompare(
                  getDisplayOfSystemFromCodableConcept(
                    b.medicationCodeableConcept
                  ) ?? ''
                )
              )}
              onClose={() => {
                setOpenAddMedicationDialogue({
                  open: false,
                })
              }}
              onMedicalConditionAdded={(e) => {
                setOpenAddMedicationDialogue({
                  open: false,
                })
                setMedStored(e)
                if (subscriptionStatus.tasks) {
                  const carePlanId = getCarePlanIdFromTaskOutput(
                    subscriptionStatus.tasks[0].task
                  )

                  if (carePlanId)
                    dispatch(
                      addMedicationRecommendations(
                        visitContext.patient!,
                        e,
                        visitContext.visitReference as FhirAppointmentDetail,
                        carePlanIdentifier,
                        carePlanId
                      )
                    )
                }
              }}
            />
          )}
      </Box>
    )
  }
