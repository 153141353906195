import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { AyurvedaTreatment, Treatment } from 'models/treatMent'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import { sleep } from 'utils/dateUtil'
import { getNameOfPatient } from 'utils/fhirResourcesHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { requestForTreatment } from '../viewTreatment/treatmentSearchSlice'
import { TreatmentAddStatus } from './addTreatmentStatusTypesForAyurveda'

const initialState: TreatmentAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addTreatmentSliceForAyurveda = createSlice({
  name: 'addTreatmentSliceForAyurveda',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<TreatmentAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addTreatmentBundle = action.payload.addTreatmentBundle
    },

    resetAddVitalsDetails(state, action: PayloadAction<TreatmentAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addTreatmentBundle = initialState.addTreatmentBundle
    },
  },
})

export const addTreatmentDetailsForAyurveda =
  (
    treatment: AyurvedaTreatment,
    fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole,
    type: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: TreatmentAddStatus = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(
      addTreatmentSliceForAyurveda.actions.updateAddVitalsStatus(addingState)
    )
    try {
      const bundleObject: R4.IBundle = createBundleObjectForObservations(
        treatment,
        fhirAppointmentDetails,
        type
      )

      const appointmentType = fhirAppointmentDetails.mainServiceRequest.code
        ? fhirAppointmentDetails.mainServiceRequest.code.coding
          ? fhirAppointmentDetails.mainServiceRequest.code.coding[0].code ?? ''
          : ''
        : ''
      const ipdDayCare: boolean = appointmentType === '304903009'
      const consultation: boolean = appointmentType === '33022008'

      const resource: any = {
        isOpdConsult: consultation,
        isOpdDayCare: ipdDayCare,
        actionBody: bundleObject,
      }

      const enRolClient: EnrolCient = new EnrolCient()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        `ipd/${fhirAppointmentDetails.mainServiceRequest.id}/addIpdCarePlanDetails`,
        resource
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      await sleep(3000)
      if (response.status === 'Updated Care Plans for IPD') {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }

        dispatch(showSuccessAlert('Treatment card updated successfully'))
        dispatch(
          requestForTreatment(fhirAppointmentDetails.mainServiceRequest.id!)
        )
        dispatch(
          addTreatmentSliceForAyurveda.actions.updateAddVitalsStatus(
            addingState
          )
        )
      } else {
        const errorCreatePersonState: TreatmentAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while creating patient',
        }
        dispatch(
          addTreatmentSliceForAyurveda.actions.updateAddVitalsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: TreatmentAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding vitals',
      }
      dispatch(
        addTreatmentSliceForAyurveda.actions.updateAddVitalsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetTreatmentState = () => (dispatch: AppDispatch) => {
  dispatch(
    addTreatmentSliceForAyurveda.actions.resetAddVitalsDetails(initialState)
  )
}

function createBundleObjectForObservations(
  treatment: AyurvedaTreatment,
  fhirIpdDetails: FhirActiveIPDDetailsForMedicalRole,
  type: string
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  if (type === 'day') {
    const newDate = new Date(`${treatment.date}T${treatment.startTime!}`)
    const newDateEnd = new Date(`${treatment.date}T${treatment.endTime!}`)
    const isoStartDateTIme = newDate.toISOString()
    const isoEndDateTIme = newDateEnd.toISOString()
    const momentObj = moment(
      treatment.date + treatment.startTime!,
      'YYYY-MM-DDLT'
    )
    const enmomentObj = moment(
      treatment.date + treatment.endTime!,
      'YYYY-MM-DDLT'
    )
    const currentServiceRequest1: R4.IServiceRequest = {
      resourceType: 'ServiceRequest',
      meta: {
        profile: [
          'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndServiceRequestTherapy',
        ],
      },
      subject: {
        id: fhirIpdDetails.patient.id,
        reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
        display: getNameOfPatient(fhirIpdDetails.patient),
      },

      requester: {
        id: fhirIpdDetails.patient.id,
        reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
        display: getNameOfPatient(fhirIpdDetails.patient),
      },
      status: 'active',
      intent: 'order',
      category: [
        {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '225365006',
              display: 'Regime care therapy',
            },
          ],
        },
      ],
      performer: [getCurrentUserUnitReference()!],
      priority: 'urgent',
      occurrencePeriod: {
        start: isoStartDateTIme,
        end: isoEndDateTIme,
      },
      code: {
        coding: [treatment.therapy!],
      },
      authoredOn: new Date().toISOString(),
    }

    const performerData: R4.IReference = {
      id: treatment.threapyId!,
      reference: `HealthcareService/${treatment.threapyId}`,
    }
    if (currentServiceRequest1.performer)
      currentServiceRequest1.performer.push(performerData)
    const supportingInfoData: R4.IReference[] = []
    if (treatment.medication) {
      const medicationRef: R4.IReference = {
        id: treatment.medication.code ?? '',
        reference: `Medication/${treatment.medication.code ?? ''}`,
        display: treatment.medication.display ?? '',
      }
      supportingInfoData.push(medicationRef)
      if (supportingInfoData.length > 0) {
        currentServiceRequest1.supportingInfo = supportingInfoData
      }
    }
    const practRole: R4.IPractitionerRole =
      getCurrentUserPractitionerRoleDetails()
    const pract: R4.IPractitioner = getCurrentUserPractitionerDetails()

    const noteData: R4.IAnnotation[] = []
    if (treatment.notes && treatment.notes.length > 0) {
      noteData.push({
        authorReference: {
          reference: `${pract.resourceType}/${pract.id}`,
        },
        text: treatment.notes,
        time: new Date().toISOString(),
      })
    }
    if (noteData.length > 0) {
      currentServiceRequest1.note = noteData
    }

    requestBundle.entry?.push({
      fullUrl: `${currentServiceRequest1.resourceType}/`,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: currentServiceRequest1.resourceType,
      },
      resource: currentServiceRequest1,
    })
  } else {
    const dates: string[] = []
    for (
      let m = moment(treatment.date);
      m.isSameOrBefore(treatment.endDate!);
      m.add(1, 'days')
    ) {
      dates.push(m.format('YYYY-MM-DD'))
    }
    for (let i = 0; i < dates.length; i++) {
      const newDate = new Date(`${dates[i]}T${treatment.startTime!}`)
      const newDateEnd = new Date(`${dates[i]}T${treatment.endTime!}`)
      const isoStartDateTIme = newDate.toISOString()
      const isoEndDateTIme = newDateEnd.toISOString()

      const currentServiceRequest1: R4.IServiceRequest = {
        resourceType: 'ServiceRequest',
        meta: {
          profile: [
            'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndServiceRequestTherapy',
          ],
        },
        subject: {
          id: fhirIpdDetails.patient.id,
          reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
          display: getNameOfPatient(fhirIpdDetails.patient),
        },

        requester: {
          id: fhirIpdDetails.patient.id,
          reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
          display: getNameOfPatient(fhirIpdDetails.patient),
        },
        status: 'active',
        intent: 'order',
        category: [
          {
            coding: [
              {
                system: 'http://snomed.info/sct',
                code: '225365006',
                display: 'Regime care therapy',
              },
            ],
          },
        ],
        performer: [getCurrentUserUnitReference()!],
        priority: 'urgent',

        occurrencePeriod: {
          start: isoStartDateTIme,
          end: isoEndDateTIme,
        },
        code: {
          coding: [treatment.therapy!],
        },
        authoredOn: new Date().toISOString(),
      }

      const performerData: R4.IReference = {
        id: treatment.id!,
        reference: `HealthcareService/${treatment.threapyId}`,
      }
      if (currentServiceRequest1.performer)
        currentServiceRequest1.performer.push(performerData)
      const supportingInfoData: R4.IReference[] = []
      if (treatment.medication) {
        const medicationRef: R4.IReference = {
          id: treatment.medication.code ?? '',
          reference: `Medication/${treatment.medication.code ?? ''}`,
          display: treatment.medication.display ?? '',
        }
        supportingInfoData.push(medicationRef)
        if (supportingInfoData.length > 0) {
          currentServiceRequest1.supportingInfo = supportingInfoData
        }
      }
      const practRole: R4.IPractitionerRole =
        getCurrentUserPractitionerRoleDetails()
      const pract: R4.IPractitioner = getCurrentUserPractitionerDetails()

      const noteData: R4.IAnnotation[] = []
      if (treatment.notes && treatment.notes.length > 0) {
        noteData.push({
          authorReference: {
            reference: `${pract.resourceType}/${pract.id}`,
          },
          text: treatment.notes,
          time: new Date().toISOString(),
        })
      }
      if (noteData.length > 0) {
        currentServiceRequest1.note = noteData
      }

      requestBundle.entry?.push({
        fullUrl: `${currentServiceRequest1.resourceType}/`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: currentServiceRequest1.resourceType,
        },
        resource: currentServiceRequest1,
      })
    }
  }

  return requestBundle
}

export default addTreatmentSliceForAyurveda.reducer
