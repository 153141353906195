/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Card,
  Grid,
  Typography,
  CircularProgress,
  CardContent,
  InputAdornment,
  TextField,
  useTheme,
} from '@material-ui/core'
import { kDialogueBackground, kPrimaryMain } from 'configs/styles/muiThemes'
import { OperationalHoursOfDay } from 'models/operationalHoursOfDay'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { searchInvitations } from 'redux/administration/userSetup/userOnBoardInvitations/userOnboardInvitationSlice'
import { RootState } from 'redux/rootReducer'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitLocationDetails,
  isOrgAdmin,
} from 'services/userDetailsService'
import {
  resetUnitCompletionState,
  updateUnitTypeSpecificDetails,
} from 'redux/administration/unitSetup/unitProfileCompletionSlice/unitProfileCompletionSlice'
import {
  getCodeOfSystemFromCodableConceptList,
  getStringValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import { GetDefaultBusinessTimingsFromLocation } from 'utils/fhirResoureHelpers/calender_helper'
import { UserTileWithRole } from 'views/pages/general/userTile'
import { fetchUser } from 'redux/practitioner/practitionerSearchHandler/PractitionerManagement'
import { WelloTabs } from 'views/components/LeftMenu/WelloTabs'
import _ from 'lodash'
import { AddUnitsTimingDetailsSetUp } from './add_unit_timing_details'
import { AddUnitSchedule } from './unitLists/add_unit_schedule'
import { UpdateUnitTimingsDetails } from './update_unit_timings'
import { AddUnitTimeSetup } from './add_unit_time'

export interface UnitDetailProps {
  unitOrgDetail: R4.IOrganization
  unitLocationDetail: R4.ILocation
  onUpdated?: () => void
}

export const WorkingHourSettings: React.FC<UnitDetailProps> = (
  props: UnitDetailProps
) => {
  const unitProfileCompletionSlice = useSelector(
    (state: RootState) => state.unitProfileCompletionSlice
  )
  const [showAddUsers, setShowAddUsers] = useState<boolean>(false)
  const [showUpdateTime, setShowUpdateTime] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [businessHours, setBusinessHours] = useState<OperationalHoursOfDay[]>(
    GetDefaultBusinessTimingsFromLocation(props.unitLocationDetail) ?? []
  )
  const [location, setLocation] = useState<R4.ILocation>(
    props.unitLocationDetail
  )

  const [id, setId] = useState<string>(_.random(1, 10000000).toString())

  const isUpdating =
    unitProfileCompletionSlice.updatingBasicDetails ||
    unitProfileCompletionSlice.updatingUnitTypeSpecificDetails

  useEffect(() => {
    setBusinessHours(
      GetDefaultBusinessTimingsFromLocation(props.unitLocationDetail) ?? []
    )
  }, [props.unitLocationDetail])
  const menu = ['working_hours', 'holiday']
  const [selectedTab, setSelectedTab] = React.useState(menu[0])

  const slotTime =
    getStringValueExtensionsOfUrl(
      props.unitLocationDetail.extension,
      'http://wellopathy.com/fhir/india/core/ExtUrl/locationSlotDuration'
    ) ?? ''
  const [slotMins, setSlotMins] = useState<number>(parseInt(slotTime, 10))
  const [edit, setEdit] = useState<boolean>(false)

  const handleChangeTab = (selected: string) => {
    setSelectedTab(selected)
  }

  function handleCancel() {
    setLocation(getCurrentUserUnitLocationDetails())
    setSlotMins(parseInt(slotTime, 10))
    setId(_.random(1, 10000000).toString())
    setEdit(false)
  }

  useEffect(() => {
    if (unitProfileCompletionSlice.updatedUnitTypeSpecificDetails) {
      dispatch(resetUnitCompletionState())
      if (props.onUpdated) props.onUpdated()
    }
  }, [unitProfileCompletionSlice.updatedUnitTypeSpecificDetails, dispatch])

  function validateSpecificDetails(locationDetails: R4.ILocation) {
    dispatch(updateUnitTypeSpecificDetails(locationDetails))
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      flexGrow={1}
      width='100%'
      height='100%'
      bgcolor={kDialogueBackground}
      key={id}
    >
      <Box display='flex' flexDirection='row' width='100%' height='7.5%' />
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        height='5%'
        bgcolor={useTheme().palette.background.default}
      >
        <Grid
          container
          direction='row'
          alignContent='space-between'
          justify='space-between'
        >
          <Grid item>
            <Grid container direction='column' justify='flex-start'>
              <Grid item xs>
                <Box px={3} py={1}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {/* <Box display='flex' paddingX={2} paddingY={1}>
                      <Avatar alt={props.unitOrgDetail.name} src='test.jpg' />
                    </Box> */}

                    <Grid item xs>
                      <Box display='flex' flexDirection='Column' height='50%'>
                        <Box paddingX={0.5}>
                          <Typography
                            variant='subtitle1'
                            color='primary'
                            style={{
                              textTransform: 'uppercase',
                              fontFamily: 'Open Sans',

                              color: 'black',

                              fontWeight: 'bold',
                              lineHeight: 'normal',
                            }}
                          >
                            WORKING HOURS AND HOLIDAYS
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        display='flex'
        flexDirection='row'
        height='100%'
        width='100%'
        style={{
          backgroundColor: 'white',
        }}
      >
        <Box width='100%'>
          <Card
            elevation={0}
            style={{
              padding: 0,
              margin: 0,
              borderRadius: 8,
              //   backgroundColor: '#FFFFFFAF',
            }}
          >
            <CardContent
              style={{
                paddingRight: 16,
                border: 1,
              }}
            >
              <Box display='flex' flexDirection='column' width='100%'>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  justifyContent='space-between'
                  alignContent='center'
                  alignItems='center'
                >
                  <Box>
                    <WelloTabs
                      preSelectedTab={t('labelCommon:' + `${selectedTab}`)}
                      onTabChange={(e) => handleChangeTab(e)}
                      menu={menu}
                    />
                  </Box>
                </Box>

                <Divider
                  orientation='horizontal'
                  variant='fullWidth'
                  flexItem
                  style={{ width: '100%', height: '2px' }}
                  color='primary'
                />
                {selectedTab === menu[0] && (
                  <Box>
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box display='flex' flexDirection='column' width='70%'>
                        <Box display='flex' flexDirection='row' width='100%'>
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            {edit === false && (
                              <AddUnitTimeSetup
                                location={location}
                                isUpdate={true}
                                onSelectionChanges={(changedDetails) => {
                                  setLocation(changedDetails)
                                  setEdit(true)
                                }}
                              />
                            )}

                            {edit === true && (
                              <AddUnitTimeSetup
                                location={location}
                                isUpdate={true}
                                onSelectionChanges={(changedDetails) => {
                                  setLocation(changedDetails)
                                  setEdit(true)
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Box py={0.5}>
                      <Divider
                        orientation='horizontal'
                        variant='fullWidth'
                        flexItem
                        style={{ width: '100%', height: '2px' }}
                        color='primary'
                      />
                    </Box>
                    <Box
                      px={3}
                      display='flex'
                      justifyContent='space-between'
                      width='25%'
                    >
                      <Box my={2}>
                        <Typography
                          variant='subtitle1'
                          color='textPrimary'
                          style={{ textTransform: 'uppercase' }}
                        >
                          {t('labelCommon:slot_duration')}
                        </Typography>
                      </Box>
                      <Box my={1}>
                        <TextField
                          size='small'
                          fullWidth={false}
                          variant='outlined'
                          style={{ maxWidth: '120px' }}
                          type='number'
                          onChange={(e) => {
                            const loc: R4.ILocation = { ...location }
                            loc.extension = [
                              {
                                url: 'http://wellopathy.com/fhir/india/core/ExtUrl/locationSlotDuration',
                                valueString: `${e.target.value}`,
                              },
                            ]
                            setSlotMins(parseInt(e.target.value, 10))
                            setEdit(true)
                            setLocation(loc)
                          }}
                          value={slotMins}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                {t('labelCommon:mins')}
                              </InputAdornment>
                            ),
                            inputProps: {
                              min: 5,
                              max: 60,
                            },
                          }}
                        />
                      </Box>
                    </Box>
                    <Box py={0.5}>
                      <Divider
                        orientation='vertical'
                        variant='fullWidth'
                        flexItem
                        style={{ width: '100%', height: '2px' }}
                        color='primary'
                      />
                    </Box>

                    <Box flex='display' flexDirection='row'>
                      <Box display='flex' flexGrow width='100%' px={1}>
                        {unitProfileCompletionSlice.updatingBasicDetails && (
                          <CircularProgress />
                        )}
                        {unitProfileCompletionSlice.updatingUnitTypeSpecificDetails && (
                          <CircularProgress />
                        )}
                        <Box display='flex' flexDirection='row'>
                          <Box px={1}>
                            <Button
                              variant='contained'
                              color='primary'
                              disabled={edit === false}
                              onClick={() => {
                                validateSpecificDetails(location)
                              }}
                            >
                              {t('labelCommon:Save')}
                            </Button>
                          </Box>
                        </Box>
                        <Button
                          variant='contained'
                          color='primary'
                          disabled={edit === false}
                          onClick={() => {
                            handleCancel()
                          }}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <UpdateUnitTimingsDetails
        open={showUpdateTime}
        unitLocation={props.unitLocationDetail}
        unitOrganization={props.unitOrgDetail}
        onContinueClick={() => {
          setShowUpdateTime(false)
          //   if (props.onUpdated) props.onUpdated()
        }}
        onBackClick={() => {
          setShowUpdateTime(false)
          //   if (props.onUpdated) props.onUpdated()
        }}
      />
    </Box>
  )
}
