import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import React from 'react'
import { ReportTileForAppointmentDiagnosticReport } from 'views/components/consultation/history/OPD/diaognisticReportTile'

interface IProps {
  documentRef: R4.IDiagnosticReport[]
  showPreview: boolean
  onCloseClicked: () => void
}

export const MultiDiagnosticReport: React.FC<IProps> = ({
  documentRef,
  showPreview,
  onCloseClicked,
}: IProps) => {
  console.log(documentRef)

  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '40%',
    },
  }))

  const classes = useStyles()

  return (
    <Dialog
      open={showPreview}
      onClose={() => {
        onCloseClicked()
      }}
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>
        {' '}
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Reports</Box>
          <Box>
            <Tooltip title='Close'>
              <CloseOutlined
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  onCloseClicked()
                }}
              />
            </Tooltip>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box width='100%' px={2} py={2}>
          <ReportTileForAppointmentDiagnosticReport
            reports={documentRef}
            type='Report'
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
