import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { BpCollection } from 'models/bpData'
import { GroupedOccupation } from 'models/groupedOccupations'
import { VitalsData } from 'models/vitalsData'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import {
  getSortedObservation,
  getSortedObservationForGeneralExam,
  getSortedObservationForIpd,
} from 'utils/appointment_handle/vitals_util'
import {
  getLatestObservations,
  getObserVationFfromEncounter,
  getObserVationFfromEncounterForDateWise,
  getObserVationFfromEncounterForGeneralExam,
  getObserVationFfromObserVationDateWise,
  getObserVationFfromObserVationDateWiseForGeneralExam,
} from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { logger } from 'utils/logger'
import { GeneralExaminationDetails } from './generalExaminationSearchStatusType'

const initialState: GeneralExaminationDetails = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingProcedures: false,
  showAppointment: false,
}

const generalExaminationSearchSliceIPD = createSlice({
  name: 'generalExaminationSearchSliceIPD',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<GeneralExaminationDetails>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.vitalsList = action.payload.vitalsList
      state.vitalData = action.payload.vitalData
      state.groupedExamData = action.payload.groupedExamData
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
      state.showAppointment = action.payload.showAppointment
    },
  },
})

export const requestGeneralExaminationOfPatientIPD =
  (selectedPatient: R4.IPatient, appointmentId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: GeneralExaminationDetails = {
      searchingConditions: true,
      errorWhileSearchingProcedures: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      showAppointment: false,
    }
    dispatch(generalExaminationSearchSliceIPD.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        'based-on': appointmentId,
      }

      const response: any = await fhirClient.doGetResourceForAppointmentIPD(
        `Encounter?_revinclude=Encounter:part-of&_revinclude=Observation:encounter&_count=500`,
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingProcedures = true
        state.searchingConditions = false
        state.showAppointment = false

        dispatch(generalExaminationSearchSliceIPD.actions.updatedStatus(state))
      } else {
        const proceduresResponse: R4.IBundle = resp.right
        if (proceduresResponse?.total && proceduresResponse?.total > 0) {
          const observationData =
            getObserVationFfromEncounterForGeneralExam(proceduresResponse)
          if (observationData.length > 0) {
            observationData.sort((a, b) =>
              (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
                ? -1
                : (a.issued ?? '') < (b.issued ?? '')
                ? 1
                : 0
            )
            const finalObservationData: R4.IObservation[] =
              getLatestObservations(observationData)
            const vitalLisDataFinal: VitalsData[] =
              getObserVationFfromObserVationDateWiseForGeneralExam(
                getSortedObservationForGeneralExam(finalObservationData)
              )

            if (vitalLisDataFinal.length > 0) {
              const vitalLisData: R4.IObservation[] =
                proceduresResponse.entry?.map(
                  (item) => item.resource as R4.IObservation
                ) ?? []

              let groupedOccupationData: GroupedOccupation[] = []
              const finalGroupedOccupationData: GroupedOccupation[] = []

              for (let i = 0; i < finalObservationData.length; i++) {
                if (finalObservationData[i].issued) {
                  if (groupedOccupationData.length > 0) {
                    for (let j = 0; j < groupedOccupationData.length; j++) {
                      if (
                        moment(finalObservationData[i].issued).format(
                          'YYYY-MM-DD'
                        ) ===
                        moment(groupedOccupationData[j].date).format(
                          'YYYY-MM-DD'
                        )
                      ) {
                        groupedOccupationData[j].occupation.push(
                          finalObservationData[i]
                        )
                      } else {
                        groupedOccupationData.push({
                          date: finalObservationData[i].issued ?? '',
                          occupation: [finalObservationData[i]],
                        })
                      }
                    }
                  } else {
                    groupedOccupationData.push({
                      date: finalObservationData[i].issued ?? '',
                      occupation: [finalObservationData[i]],
                    })
                  }
                }
              }
              groupedOccupationData = groupedOccupationData.filter(
                (value, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      moment(t.date).format('YYYY-MM-DD') ===
                      moment(value.date).format('YYYY-MM-DD')
                  )
              )
              for (let i = 0; i < groupedOccupationData.length; i++) {
                finalGroupedOccupationData.push({
                  date: groupedOccupationData[i].date,
                  occupation: getSortedObservationForGeneralExam(
                    groupedOccupationData[i].occupation
                  ),
                })
              }
              state.resultsAvailable = true
              state.searchingConditions = false
              state.groupedExamData = finalGroupedOccupationData
              state.vitalsList = getSortedObservationForIpd(vitalLisData)
              state.noResultsAvailable = false
              state.vitalData = vitalLisDataFinal
              state.errorReason = undefined
              state.errorWhileSearchingProcedures = false
              dispatch(
                generalExaminationSearchSliceIPD.actions.updatedStatus(state)
              )
            } else {
              const errorSearchDoctor: GeneralExaminationDetails = {
                searchingConditions: false,
                errorWhileSearchingProcedures: false,
                resultsAvailable: false,
                noResultsAvailable: true,
                showAppointment: false,
              }
              dispatch(
                generalExaminationSearchSliceIPD.actions.updatedStatus(
                  errorSearchDoctor
                )
              )
            }
          } else {
            const errorSearchDoctor: GeneralExaminationDetails = {
              searchingConditions: false,
              errorWhileSearchingProcedures: false,
              resultsAvailable: false,
              noResultsAvailable: true,
              showAppointment: false,
            }
            dispatch(
              generalExaminationSearchSliceIPD.actions.updatedStatus(
                errorSearchDoctor
              )
            )
          }
        } else {
          const errorSearchDoctor: GeneralExaminationDetails = {
            searchingConditions: false,
            errorWhileSearchingProcedures: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            showAppointment: false,
          }
          dispatch(
            generalExaminationSearchSliceIPD.actions.updatedStatus(
              errorSearchDoctor
            )
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: GeneralExaminationDetails = {
        searchingConditions: false,
        errorWhileSearchingProcedures: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
        showAppointment: false,
      }
      dispatch(
        generalExaminationSearchSliceIPD.actions.updatedStatus(
          errorSearchDoctor
        )
      )
    }
  }

async function updateEnrolment(bpCollection: BpCollection): Promise<string> {
  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `/enrolment/update-bp`,
      bpCollection
    )
    return response.BPCategory
  } catch (error) {
    return ''
  }
}

export const resetVitalSearchState = () => (dispatch: AppDispatch) => {
  dispatch(generalExaminationSearchSliceIPD.actions.updatedStatus(initialState))
}

async function updateAppointment(): Promise<string> {
  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const enRolClient: EnrolCient = new EnrolCient()
  //   const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
  //     `/enrolment/update-bp`,
  //     bpCollection
  //   )

  return 'test'
}

async function getServiceRequestId(patientId: string): Promise<string> {
  logger.info('Patient invitation body')
  let serviceRequestId: string = ''
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirClient.doGetResource(
    `/ServiceRequest??code=chronic-care-enrolment&subject=${patientId}`
  )
  logger.info('Patient invitation Response')
  logger.info(response)
  const respDecoded: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (respDecoded._tag === 'Right') {
    logger.info('Response Patient un decoded', respDecoded.right)
    const responseBundel: R4.IBundle = respDecoded.right
    if (responseBundel.entry) {
      const entryObj = responseBundel.entry?.[0].resource as R4.IServiceRequest
      serviceRequestId =
        entryObj && entryObj.status === 'active' ? entryObj.id ?? ' ' : ''
      return serviceRequestId
    }
    return serviceRequestId
  }

  return serviceRequestId
}

export default generalExaminationSearchSliceIPD.reducer
