import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { PatientDataWithAppointment } from 'models/patientDataWithAppointment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchPatientDetails,
  resetState,
} from 'redux/patient/addPatient/addPatientSlice'
import { resetUpdatePatientState } from 'redux/patient/editPatient/editPatientSlice'
import { searchPatients } from 'redux/patient/patientSearch/patientSearchSlice'
import { RootState } from 'redux/rootReducer'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { PatientDataTable } from '../common/patientDataTable'
import { EditPatient } from './editPatient'

export interface IUnitAdmin {
  children?: React.ReactNode
}

export const PatientsManagementPage: React.FC = () => {
  const patientsSearchState = useSelector(
    (state: RootState) => state.patientSearchSlice
  )
  const patientAddSlice = useSelector((state: RootState) => state.patientSlice)
  const dispatch = useDispatch()
  //   sessionStorage.removeItem('version')
  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<R4.IOrganization>(getCurrentUserUnitDetails())
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      currentOrganizationDetails.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''
  const [selectedPatientDetails, setSelectedPatientDetails] =
    useState<PatientDataWithAppointment>()

  const patientDataSelected = useRef<PatientDataWithAppointment>()

  const { height } = useWindowDimensions()
  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['common', 'patient'])

  useEffect(() => {
    i18n.changeLanguage(language ?? 'en')

    dispatch(searchPatients('', ''))
  }, [])

  return (
    <Box style={{ maxWidth: '100%', overflow: 'none' }}>
      <Box
        display='flex'
        flexGrow={1}
        flexDirection='column'
        justifyContent='center'
        alignContent='center'
        overflow='auto'
      >
        {patientsSearchState.searching && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
          >
            <CircularProgress size={25} />
          </Box>
        )}
      </Box>
      {patientsSearchState.resultsAvailable && (
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          overflow='auto'
          maxWidth='100%'
          flexGrow={1}
        >
          <Box display='flex' flexGrow={1} overflow='auto' height='100%' p={1}>
            <PatientDataTable
              patientData={
                patientsSearchState.patientListWithAppointment
                  ? patientsSearchState.patientListWithAppointment
                  : []
              }
              onPatientSelected={(patient) => {
                sessionStorage.removeItem('version')
                setSelectedPatientDetails(patient)
                patientDataSelected.current = patient
                dispatch(resetState())
                dispatch(fetchPatientDetails(patient.id ?? '', patient.status))
                i18n.changeLanguage(language ?? 'en')
              }}
            />
          </Box>
          {patientDataSelected.current && (
            <Box
              display='flex'
              paddingTop={1.5}
              minWidth='760px'
              style={{ backgroundColor: '#ececec' }}
            >
              <EditPatient
                isLab={
                  !!(
                    loggedInUnitType === 'Lab' ||
                    loggedInUnitType === 'Collection Center'
                  )
                }
                patientData={patientDataSelected.current.fhirPatientDetail}
                fathersData={patientDataSelected.current.fatherDetails}
                husbandDetails={patientDataSelected.current.husbandDetails}
                patProfile={patientDataSelected.current.patProfile}
                status={patientDataSelected.current.status}
                unitType={loggedInUnitType}
                isPrimary={patientDataSelected.current.isPrimary}
                onCloseClicked={() => {
                  setSelectedPatientDetails(undefined)
                  patientDataSelected.current = undefined
                  dispatch(resetUpdatePatientState())
                }}
                onEditSucsess={() => {
                  dispatch(resetUpdatePatientState())
                  dispatch(searchPatients('', ''))
                  setSelectedPatientDetails(undefined)
                  patientDataSelected.current = undefined
                }}
              />
            </Box>
          )}
        </Box>
      )}

      {patientsSearchState.noResultsAvailable && (
        <Typography> No Patient Data Available</Typography>
      )}

      {patientsSearchState.error && (
        <Typography>
          {' '}
          {patientsSearchState.errorMessage ??
            'Error while searching patients . Pleas try again'}
        </Typography>
      )}
    </Box>
  )
}
