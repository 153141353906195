/* eslint-disable no-void */
import {
  makeStyles,
  Typography,
  Box,
  TextField,
  IconButton,
  CircularProgress,
} from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { TreeItem, TreeView } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import {
  getProductDetails,
  getProducts,
} from 'utils/medicineHelper/product-apis'

import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import {
  Product,
  ProductsByCategory,
} from '../../../models/medicine-delivery/product-models'
import {
  productsState,
  selectedProductIdState,
  viewState,
} from '../../../models/medicine-delivery/product-states'

const useStyles = makeStyles({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
})

export const Products: React.FC = () => {
  const [products, setProducts] = useRecoilState(productsState)
  const setSelectedProductId = useSetRecoilState(selectedProductIdState)
  const setView = useSetRecoilState(viewState)
  const [name, setName] = useState<string>('')
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    void (async () => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      setLoading(true)
      setProducts([])
      const productsData = await getProducts()
      setLoading(false)
      setProducts(productsData)
    })()
  }, [])

  const onSearch = async (event: any) => {
    const title = event.target.value.trim()
    if (title === '') {
      return
    }
    const response = await getProductDetails(event.target.value)

    if (response !== undefined) {
      setSelectedProductId(response.title)
      setView('productDetails')
    }
  }

  //   if (!products || products.length === 0) {
  //     return <>No products available</>
  //   }

  const renderProducts = (p: Product[], index: number) =>
    p.map((pData: Product, i: number) => (
      <TreeItem
        key={pData.name}
        nodeId={`${10 * index + i}`}
        style={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 0 }}
        label={
          <Typography variant='body2' color='primary'>
            {pData.title}
          </Typography>
        }
        onClick={() => {
          setSelectedProductId(pData.title)
          setView('productDetails')
        }}
      />
    ))

  return (
    <Box width='100%' display='flex' flexDirection='column' paddingLeft={2}>
      <Box width='100%' display='flex' flexDirection='column'>
        <Box py={0.5} px={0.25} display='flex' flexDirection='row'>
          <Typography variant='subtitle2'>
            Please select a product or search for it
          </Typography>
        </Box>

        <Box width='95.5%'>
          <TextField
            variant='outlined'
            onChange={(event) => {
              setName(event.target.value)
              onSearch(event)
            }}
            size='small'
            autoFocus={true}
            placeholder='Medicine'
            value={name}
            fullWidth
            InputProps={{
              startAdornment: (
                <IconButton>
                  <img
                    src={`${process.env.PUBLIC_URL}/search.png`}
                    alt='s'
                    style={{ paddingLeft: name ? 1 : 0 }}
                  />
                </IconButton>
              ),
              endAdornment: name && (
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => {
                    setName('')
                  }}
                >
                  <CancelRoundedIcon />
                </IconButton>
              ),
            }}
          />
        </Box>
      </Box>
      {loading && (
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='center'
          px={1}
          paddingTop={1}
        >
          <CircularProgress />
        </Box>
      )}

      <Box width='100%' paddingTop={1} paddingRight={3}>
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {products.map((p: ProductsByCategory, index: number) => (
            <TreeItem
              key={p.category.name}
              nodeId={index.toString()}
              label={
                <Typography variant='subtitle2' color='primary'>
                  {p.category.title}
                </Typography>
              }
            >
              <ul key={p.category.name} style={{ paddingLeft: 0 }}>
                {renderProducts(p.products, index + 1)}
              </ul>
            </TreeItem>
          ))}
        </TreeView>
      </Box>
    </Box>
  )
}
