/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Typography,
  Divider,
  Grid,
  CircularProgress,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import ContentShimmer from 'react-content-shimmer'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import 'react-vis/dist/style.css'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { isMedicalServiceProvider } from 'services/userDetailsService'
import { getAccessToken } from 'utils/authHelpers'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  addObservationDetailsForSys,
  deleteObservationDetails,
  fetchScore,
  getAddStatusForOCA,
  getObservationResourceForPostForSystemicExam,
  getObservationsOfSpecificCategoryInEncounter,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { getVendorPartId } from 'utils/routes_helper'
import { OCARenderer } from 'wello-web-components'

interface AyurvedaHistoryAndSubjectiveProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  status: string
  splitView: boolean
  onDataAdded: () => void
}

export const ToxScoreJointMuscle: React.FC<AyurvedaHistoryAndSubjectiveProps> =
  ({
    fhirAppointmentDetails,
    status,
    splitView,
    onDataAdded,
  }: AyurvedaHistoryAndSubjectiveProps) => {
    const { t } = useTranslation(['common'])
    const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())
    const [observations, setObservations] = useState<R4.IObservation[]>([])
    const dispatch = useDispatch()
    const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
      initial: true,
    })
    const [total, setTotal] = useState<string>()

    const [scoreFetchingStatus, setScoreFetchingStatus] =
      useState<NetworkRequestStatus>({
        initial: true,
      })

    function getScoreDetails() {
      setScoreFetchingStatus({ requesting: true })

      fetchScore(fhirAppointmentDetails.patient, 'wp_tox_score', 'joint')
        .then((e) => {
          setTotal(e)
          setScoreFetchingStatus({
            requestSuccess: true,
            requesting: false,
          })
        })
        .catch((e) => {
          setScoreFetchingStatus({
            requestError: true,
            requesting: false,
          })
        })
    }

    function getObservationDetails() {
      setFetchingStatus({
        requesting: true,
      })

      getObservationsOfSpecificCategoryInEncounter({
        encounterId: fhirAppointmentDetails.encounter!.id!,
        patient: fhirAppointmentDetails.patient,
        category: 'C4045976',
        isForHistory: false,
      })
        .then((e) => {
          if (e) {
            const obs = e as R4.IObservation[]
            if (obs.length > 0) {
              getScoreDetails()
            }
            setObservations(e as R4.IObservation[])

            setFetchingStatus({
              requestSuccess: true,
            })
          } else {
            setFetchingStatus({
              requestError: true,
            })
          }
        })
        .catch((e) => {
          setFetchingStatus({
            requestError: true,
          })
        })
    }
    useEffect(() => {
      getObservationDetails()
    }, [])

    return (
      <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
        {fetchingStatus.requesting && (
          <ContentShimmer
            rows={3}
            size={{
              width: '100%',
              height: '800',
            }}
          />
        )}
        {fetchingStatus.requestError && (
          <Typography variant='body1' color='error'>
            {t('common:errorWhileFetchingData')}
          </Typography>
        )}
        {/* <ObservationOCAForViewOnly
        fhirAppointmentDetails={fhirAppointmentDetails}
        category='objective'
        isForHistory={false}
        formName='Obesity Root Cause Assessment'
        observationCode='C1444322'
        isReadOnly={!isMedicalServiceProvider()}
      /> */}
        {splitView === false && (
          <Box py={1}>
            <Typography variant='subtitle1'>
              {' '}
              TOX Score Joints/Muscles{' '}
            </Typography>
          </Box>
        )}
        {fetchingStatus.requestSuccess && (
          <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
            <OCARenderer
              OCAFormProviderBaseURL={`${
                process.env.REACT_APP_CC_WORKFLOW_URL
              }${getVendorPartId()}/ocaForms/forms`}
              OCAFormProviderHeader={{
                Authorization: `Bearer ${getAccessToken()}`,

                'Cache-Control': 'no-cache',
              }}
              isViewOnly={splitView}
              showAdded={getAddStatusForOCA(
                observations,
                'C0026860',
                fhirAppointmentDetails.encounter
                  ? fhirAppointmentDetails.encounter.id!
                  : ''
              )}
              onSubmit={(
                observation: R4.IObservation,
                onSubmitSuccess?: (data: any) => void
              ) => {
                const updatedObservation =
                  getObservationResourceForPostForSystemicExam(
                    fhirAppointmentDetails.patient.id!,
                    fhirAppointmentDetails.encounter!.id!,
                    [],
                    observation,
                    true
                  )

                addObservationDetailsForSys(updatedObservation).then((e) => {
                  if (e === false) {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  } else if (
                    (e as R4.IObservation).resourceType === 'Observation'
                  ) {
                    if (onSubmitSuccess) {
                      onSubmitSuccess(e)
                    }
                    getObservationDetails()
                    onDataAdded()
                    dispatch(
                      showSuccessAlert(
                        'Observation details updated successfully'
                      )
                    )
                  } else {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  }
                })
              }}
              onEdit={(
                observation: R4.IObservation,
                onSubmitSuccess?: (data: any) => void
              ) => {
                const updatedObservation =
                  getObservationResourceForPostForSystemicExam(
                    fhirAppointmentDetails.patient.id!,
                    fhirAppointmentDetails.encounter!.id!,
                    [],
                    observation
                  )

                addObservationDetailsForSys(updatedObservation).then((e) => {
                  if (e === false) {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  } else if (
                    (e as R4.IObservation).resourceType === 'Observation'
                  ) {
                    if (onSubmitSuccess) {
                      onSubmitSuccess(e)
                    }
                    getObservationDetails()
                    onDataAdded()
                    dispatch(
                      showSuccessAlert(
                        'Observation details updated successfully'
                      )
                    )
                  } else {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  }
                })
              }}
              onDelete={(
                observation: R4.IObservation,
                onSuccess?: ((data: any) => void) | undefined,
                onError?: ((data: any) => void) | undefined
              ) => {}}
              formSection='soap'
              formCategory='history'
              displayAsPopup={true}
              formName='TOX Score-Joints-Muscles_OCA'
              existingObservations={observations}
            />
            {scoreFetchingStatus.requesting && (
              <Box flexGrow width='100%' display='flex' flexDirection='row'>
                <CircularProgress size={15} />
              </Box>
            )}

            {scoreFetchingStatus.requestSuccess && total && total.length > 0 && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                width='100%'
                border={4}
                style={{
                  boxShadow: '0px 0px 4px #ccc',
                  margin: '2px 0px',
                  borderRadius: '1px',
                  borderColor: '#F8F8F8',
                  width: '100%',
                  height: 'auto',
                  backgroundColor: '#F8F8F8',
                }}
              >
                <Grid container direction='column' spacing={1}>
                  <Grid
                    container
                    direction='row'
                    id='vitals'
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <Grid item xs={3}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            minHeight={30}
                            maxHeight={100}
                            alignItems='center'
                            px={1}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Joints/Muscles Score
                            </Typography>
                          </Box>
                        </Box>
                        <Box display='flex' width='2%'>
                          <Divider
                            orientation='vertical'
                            flexItem
                            style={{
                              minHeight: '30px',
                              maxHeight: '100px',

                              width: '2px',
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={9}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            minHeight={30}
                            maxHeight={100}
                            alignItems='center'
                            px={1}
                          >
                            <Typography
                              color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,

                                fontWeight: 600,
                                lineHeight: 'normal',
                                textTransform: 'capitalize',
                              }}
                            >
                              {total}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  {/* ------------------------------------------------------------------------------- */}
                </Grid>
              </Box>
            )}
          </Box>
        )}
      </Box>
    )
  }
