import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core'
import { AttachFile } from '@material-ui/icons'
import PublishIcon from '@material-ui/icons/Publish'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { getLabAndRadiologyListOfEncounter } from 'redux/consultation/labAndRadiologyDocumentList/labAndRadiologyDocumentListSlice'
import { uploadingDocument } from 'redux/fhirMedicalResources/addDocumentReferance/documentReferenceUploadSlice'
import { RootState } from 'redux/rootReducer'
import { DocumentReferenceTile } from 'views/components/common/fhirResources/documentReferenceTile'
import {
  SelectedFile,
  WelloFilePicker,
} from 'views/components/LeftMenu/WelloFilePicker'

interface LabAndRadiologyResultsProps {
  encounterId: string
  patientId: string
  allowAddition: boolean
  isAyurveda?: boolean
}

export const LabAndRadiologyResults: React.FC<LabAndRadiologyResultsProps> = ({
  encounterId,
  patientId,
  allowAddition,
  isAyurveda,
}: LabAndRadiologyResultsProps) => {
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([])

  /*  const [findings, setFindings] = useState<R4.IClinicalImpression_Finding[]>([]) */
  const assessmentDetailsSlice = useSelector(
    (state: RootState) => state.uploadDocumentReferenceSlice
  )

  const assessmentDetailsUpdated = useSelector(
    (state: RootState) => state.uploadDocumentReferenceSlice.uploadingSuccessful
  )

  const labAndRadiologyDocumentListSlice = useSelector(
    (state: RootState) => state.labAndRadiologyDocumentListSlice
  )

  function getTotalAddedLength() {
    let total = 0

    if (labAndRadiologyDocumentListSlice.documentReferences) {
      total += labAndRadiologyDocumentListSlice.documentReferences.length
    }

    if (selectedFiles) {
      total += selectedFiles.length
    }

    return total
  }

  useEffect(() => {
    if (assessmentDetailsUpdated) {
      setSelectedFiles([])
      dispatch(getLabAndRadiologyListOfEncounter(encounterId))
    }
  }, [assessmentDetailsUpdated])

  useEffect(() => {
    dispatch(getLabAndRadiologyListOfEncounter(encounterId))
  }, [])

  return (
    <Box
      style={{ paddingLeft: '4px', paddingBottom: '24px' }}
      display='flex'
      flexDirection='row'
    >
      <Box width='100%'>
        <Grid container direction='column' style={{ width: '100%' }}>
          <Grid item style={{ width: '100%' }}>
            <ReactVisibilitySensor
              offset={{
                top: 100,
                bottom: 80,
              }}
            >
              <Grid
                container
                direction='column'
                id='visit_documents_adding'
                style={{ width: '100%' }}
              >
                <Grid item style={{ width: '100%' }}>
                  <Box display='flex' py={1} width='100%'>
                    {isAyurveda === undefined && (
                      <Box
                        width='100%'
                        display='flex'
                        alignItems='center'
                        flexDirection='row'
                        bgcolor='#999999'
                        minHeight={45}
                      >
                        <Box py={2} width='100%'>
                          <Typography
                            variant='body1'
                            style={{ color: 'white', fontWeight: 600 }}
                          >
                            Lab & Radiology Investigational Results
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {isAyurveda && (
                      <Box
                        width='100%'
                        display='flex'
                        alignItems='center'
                        flexDirection='row'
                      >
                        <Box py={1} width='100%'>
                          <Typography variant='h6'>
                            Lab & Radiology Investigational Results
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item style={{ width: '100%' }}>
                  {labAndRadiologyDocumentListSlice.fetchingImpressions && (
                    <CircularProgress />
                  )}
                  {labAndRadiologyDocumentListSlice.noResultsAvailable && (
                    <Box />
                  )}

                  {labAndRadiologyDocumentListSlice.resultsAvailable &&
                    labAndRadiologyDocumentListSlice.documentReferences && (
                      <Grid item container>
                        <Typography variant='subtitle2'>
                          Uploaded documents
                        </Typography>
                        <Grid item container direction='row'>
                          {labAndRadiologyDocumentListSlice.documentReferences.map(
                            (e) => (
                              <Grid key={e.id ?? ''} item xs={2}>
                                <DocumentReferenceTile
                                  documentRef={e}
                                  size='medium'
                                />
                              </Grid>
                            )
                          )}
                        </Grid>
                      </Grid>
                    )}
                </Grid>
                {assessmentDetailsSlice.uploading && (
                  <Grid>
                    <CircularProgress />
                  </Grid>
                )}
                {selectedFiles.length > 0 && !assessmentDetailsSlice.uploading && (
                  <Grid>
                    <Typography variant='body2' color='initial'>
                      Added files
                    </Typography>
                  </Grid>
                )}
                {selectedFiles.length > 0 && !assessmentDetailsSlice.uploading && (
                  <Box display='flex' flexDirection='row' width='100%'>
                    {selectedFiles.map((e, index) => {
                      if (index === selectedFiles.length - 1) {
                        return (
                          <Box
                            key={e.name ?? index}
                            display='flex'
                            flexDirection='row'
                            p={1}
                            alignItems='center'
                            // key={e.name}
                          >
                            <Box px={1}>
                              <WelloFilePicker
                                id={`file_${index}`}
                                fileTypeToPick='any'
                                size='medium'
                                preSelectedFile={e}
                                name='Pick File'
                                labelName='Add New File'
                                allowUpdate={false}
                                allowDelete={true}
                                showSelectedFileName={true}
                                labelIcon={<AttachFile />}
                                onDeleteClicked={() => {
                                  const currentFiles = [...selectedFiles]
                                  currentFiles.splice(index, 1)
                                  setSelectedFiles(currentFiles)
                                }}
                                onFileChanged={(changedFile) => {}}
                              />
                            </Box>
                            <Box
                              px={1}
                              display='flex'
                              flexDirection='column'
                              alignContent='center'
                              justifyContent='center'
                              alignItems='center'
                            >
                              {getTotalAddedLength() <= 8 && (
                                <WelloFilePicker
                                  id={`file_${index + 1}`}
                                  fileTypeToPick='any'
                                  name='Pick File'
                                  labelName='Add New File'
                                  size='medium'
                                  allowUpdate={true}
                                  allowDelete={true}
                                  showSelectedFileName={true}
                                  labelIcon={<AttachFile fontSize='small' />}
                                  onFileChanged={(changedFile) => {
                                    const currentFiles = [
                                      ...selectedFiles,
                                      changedFile,
                                    ]
                                    setSelectedFiles(currentFiles)
                                  }}
                                />
                              )}
                              {getTotalAddedLength() <= 8 && (
                                <Typography variant='subtitle2' color='primary'>
                                  Or
                                </Typography>
                              )}
                              <Button
                                variant='outlined'
                                color='primary'
                                size='small'
                                startIcon={<PublishIcon />}
                                onClick={() => {
                                  dispatch(
                                    uploadingDocument(
                                      selectedFiles,
                                      encounterId,
                                      patientId
                                    )
                                  )
                                }}
                              >
                                Upload Selected
                              </Button>
                            </Box>
                          </Box>
                        )
                      }
                      return (
                        <Box p={1} key={`file_${e.name}`}>
                          <WelloFilePicker
                            id={`file_${index}`}
                            fileTypeToPick='any'
                            preSelectedFile={e}
                            name='Pick File'
                            labelName='Add New File'
                            size='medium'
                            allowUpdate={false}
                            allowDelete={true}
                            showSelectedFileName={true}
                            labelIcon={<AttachFile />}
                            onFileChanged={(changedFile) => {}}
                            onDeleteClicked={() => {
                              const currentFiles = [...selectedFiles]
                              currentFiles.splice(index, 1)
                              setSelectedFiles(currentFiles)
                            }}
                          />
                        </Box>
                      )
                    })}
                  </Box>
                )}
                {selectedFiles.length === 0 &&
                  !assessmentDetailsSlice.uploading && (
                    <Grid>
                      <WelloFilePicker
                        id={`file_${selectedFiles.length}`}
                        fileTypeToPick='any'
                        name='Pick File'
                        labelName='Add New File'
                        allowUpdate={true}
                        preSelectedFile={{}}
                        allowDelete={true}
                        showSelectedFileName={true}
                        labelIcon={<AttachFile />}
                        onFileChanged={(changedFile) => {
                          const currentFiles = [...selectedFiles, changedFile]
                          setSelectedFiles(currentFiles)
                        }}
                      />
                    </Grid>
                  )}
              </Grid>
            </ReactVisibilitySensor>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
