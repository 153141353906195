import { R4 } from '@ahryman40k/ts-fhir-types'
import { TaskIntentKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { SubstanceData } from 'models/substanceData'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { requestForSubstance } from 'redux/ipd/viewSubstance/substanceSearchSliceFinal'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { getNameOfPatient } from 'utils/fhirResourcesHelper'
// import { requestForTreatment } from '../viewTreatment/treatmentSearchSlice'
import { SubstanceAddStatus } from './substanceAddStatus'

const initialState: SubstanceAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addSubstanceSlice = createSlice({
  name: 'addSubstanceSlice',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<SubstanceAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addTreatmentBundle = action.payload.addTreatmentBundle
    },

    resetAddVitalsDetails(state, action: PayloadAction<SubstanceAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addTreatmentBundle = initialState.addTreatmentBundle
    },
  },
})

export const addSubstanceDetails =
  (
    sub: SubstanceData[],
    fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: SubstanceAddStatus = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(addSubstanceSlice.actions.updateAddVitalsStatus(addingState))
    try {
      const bundleObject: R4.IBundle = createBundleObjectForObservations(
        sub,
        fhirAppointmentDetails
      )

      const appointmentType = fhirAppointmentDetails.mainServiceRequest.code
        ? fhirAppointmentDetails.mainServiceRequest.code.coding
          ? fhirAppointmentDetails.mainServiceRequest.code.coding[0].code ?? ''
          : ''
        : ''
      const ipdDayCare: boolean = appointmentType === '304903009'
      const consultation: boolean = appointmentType === '33022008'

      const resource: any = {
        isOpdConsult: consultation,
        isOpdDayCare: ipdDayCare,
        actionBody: bundleObject,
      }

      const enRolClient: EnrolCient = new EnrolCient()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        `ipd/${fhirAppointmentDetails.mainServiceRequest.id}/addIpdCarePlanDetails`,
        resource
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (response.status === 'Updated Care Plans for IPD') {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }

        dispatch(
          requestForSubstance(fhirAppointmentDetails.mainServiceRequest.id!)
        )
        dispatch(showSuccessAlert('Consumable added successfully'))

        dispatch(addSubstanceSlice.actions.updateAddVitalsStatus(addingState))
      } else {
        const errorCreatePersonState: SubstanceAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while creating Substance',
        }
        dispatch(
          addSubstanceSlice.actions.updateAddVitalsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: SubstanceAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding vitals',
      }
      dispatch(
        addSubstanceSlice.actions.updateAddVitalsStatus(errorCreatePersonState)
      )
    }
  }

export const resetAddSubstancesState = () => (dispatch: AppDispatch) => {
  dispatch(addSubstanceSlice.actions.resetAddVitalsDetails(initialState))
}

function createBundleObjectForObservations(
  sub: SubstanceData[],
  fhirIpdDetails: FhirActiveIPDDetailsForMedicalRole
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const org: R4.IOrganization = getCurrentUserUnitDetails()

  for (let i = 0; i < sub.length; i++) {
    if (sub[i].substances) {
      const subData: R4.ITask = {
        resourceType: 'Task',
        status: R4.TaskStatusKind._requested,

        instantiatesUri: new Date(sub[i].date).toISOString(),
        intent: TaskIntentKind._order,
        focus: {
          reference: `Substance/${sub[i].substanceId}`,
        },
        for: {
          id: fhirIpdDetails.patient.id,
          reference: `${fhirIpdDetails.patient.resourceType}/${fhirIpdDetails.patient.id}`,
          display: getNameOfPatient(fhirIpdDetails.patient),
        },
        code: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/task-code',
              code: 'supply-request',
            },
          ],
        },
        owner: {
          reference: `${org.resourceType}/${org.id}`,
        },
        input: [
          {
            type: {
              coding: [
                {
                  system:
                    'http://terminology.hl7.org/CodeSystem/financialtaskinputtype',
                  code: 'item',
                },
              ],
            },
            valueInteger: sub[i].unit,
          },
        ],
      }
      requestBundle.entry?.push({
        fullUrl: `${subData.resourceType}/`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: subData.resourceType,
        },
        resource: subData,
      })
    }
  }

  return requestBundle
}

export default addSubstanceSlice.reducer
