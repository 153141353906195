import {
  Box,
  CardContent,
  Typography,
  Card,
  CircularProgress,
  Grid,
} from '@material-ui/core'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Label,
  LabelList,
} from 'recharts'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestForDateWiseCollectionGraph } from 'redux/administration/graph/dateWiseGraph/dateWiseSearchGraphSearchSlice'

import { RootState } from 'redux/rootReducer'
import { requestForLocationWisePatientData } from 'redux/administration/graph/locationWiseGraph/locationWiseSearchSlice'
import { R4 } from '@ahryman40k/ts-fhir-types'

interface Props {
  date: Date
  location: string
  endDate: Date
  type: string
  unit: string
}
export const LocationWisePAtientData: React.FC<Props> = ({
  date,
  location,
  endDate,
  type,
  unit,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const adminGraphSearchSlice = useSelector(
    (state: RootState) => state.locationSearchPatientSlice
  )

  const unitsOfOrganizationSlice = useSelector(
    (state: RootState) => state.unitsOfOrganizationSlice
  )

  let units: R4.IOrganization[] = []
  if (
    unitsOfOrganizationSlice.unitsList &&
    unitsOfOrganizationSlice.unitsList.length > 0
  ) {
    units = unitsOfOrganizationSlice.unitsList
  }

  const renderCustomizedLabel = (props: any) => {
    const { x, y, width, height, value } = props
    const radius = 10

    return (
      <g>
        {/* <circle cx={x + width + 8} cy={y + 10} r={radius} fill='#8884d8' /> */}
        <text
          x={x + width + 15}
          y={y + 10}
          fill='black'
          textAnchor='middle'
          dominantBaseline='middle'
        >
          {value}
        </text>
      </g>
    )
  }

  //   const CustomTooltip = ({ active, payload, label }: any) => {
  //     if (active) {
  //       return (
  //         <Box
  //           display='flex'
  //           flexDirection='column'
  //           style={{ backgroundColor: '#FDFFABB2' }}
  //         >
  //           <Typography
  //             variant='subtitle1'
  //             color='textSecondary'
  //             style={{ color: 'green', fontSize: 10 }}
  //           >
  //             {`${label}`}
  //           </Typography>
  //           {/* <Typography
  //               variant='subtitle1'
  //               style={{ color: 'green', fontSize: 10 }}
  //             >
  //               Total Appointments : {`${payload?.[0].payload.NoOfAppointments}`}
  //             </Typography> */}
  //           <Typography
  //             variant='subtitle1'
  //             color='primary'
  //             style={{ color: 'green', fontSize: 10 }}
  //           >
  //             Total Discharge : {`${payload?.[0].payload.TotalDischarge}`}
  //           </Typography>
  //           <Typography
  //             variant='subtitle1'
  //             color='inherit'
  //             style={{ color: 'green', fontSize: 10 }}
  //           >
  //             Collection : &#x20b9;{' '}
  //             {`${payload?.[0].payload.collection.toFixed(2)}`}
  //           </Typography>
  //         </Box>
  //       )
  //     }

  //     return null
  //   }

  const [activeBarIndex, setActiveBarIndex] = useState<any>()
  const [toolTipYPosition, setToolTipYPosition] = useState<any>({})

  useEffect(() => {
    const barCharts = document.querySelectorAll('.recharts-bar-rectangle')
    if (!barCharts || !activeBarIndex) return
    // Init tooltip values
    const barChart = barCharts[activeBarIndex]
    setToolTipYPosition(barChart.getBoundingClientRect().height - 40)
  }, [activeBarIndex])

  useEffect(() => {
    dispatch(
      requestForLocationWisePatientData(
        units && units.length > 0 ? units[0].id ?? '' : unit,
        date,
        location,
        endDate,
        type === 'dateWise' ? 'day' : 'month'
      )
    )
  }, [])

  return (
    <div
      style={{
        height: '100%',
        backgroundColor: '#FFFFFF',
      }}
    >
      <Card
        elevation={2}
        style={{
          width: '100%',
        }}
      >
        <CardContent>
          <Box display='flex' flexDirection='row'>
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              width='100%'
            >
              <Box
                display='flex'
                flexGrow={1}
                flexDirection='column'
                justifyContent='center'
                alignContent='center'
              >
                <Box
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                  p={0.5}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container>
                        <Typography
                          variant='subtitle1'
                          color='primary'
                          align='center'
                        >
                          Patients by{' '}
                          {location === 'city_count'
                            ? 'city (Top 10)'
                            : 'states (Top 10)'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                {adminGraphSearchSlice.searchingAppointments && (
                  <Box
                    flexDirection='row'
                    display='flex'
                    alignSelf='center'
                    justifyContent='center'
                  >
                    <CircularProgress size={25} />
                  </Box>
                )}
              </Box>
              <Box
                display='flex'
                flexGrow={4}
                flexDirection='column'
                justifyContent='center'
                alignContent='center'
              >
                {adminGraphSearchSlice.errorWhileSearchingAppointments && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='error'
                      align='center'
                    >
                      Error while searching location data . Please try again
                    </Typography>
                  </Box>
                )}
                {adminGraphSearchSlice.noResultsAvailable && (
                  <Box
                    //   display='flex'
                    //   flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container justifyContent='center'>
                          <Typography
                            variant='subtitle1'
                            color='primary'
                            align='center'
                          >
                            No patient data available.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {adminGraphSearchSlice.locationGraphData &&
                  adminGraphSearchSlice.locationGraphData.length > 0 &&
                  adminGraphSearchSlice.stateGraphData &&
                  adminGraphSearchSlice.locationGraphData.length > 0 && (
                    <ResponsiveContainer height={300}>
                      <BarChart
                        data={
                          location === 'city_count'
                            ? adminGraphSearchSlice.locationGraphData.slice(
                                0,
                                10
                              )
                            : adminGraphSearchSlice.stateGraphData.slice(0, 10)
                        }
                        layout='vertical'
                        barCategoryGap={1}
                        barGap={10}
                        margin={{
                          top: 10,
                          right: 20,
                          left: 50,
                          bottom: 10,
                        }}
                        width={1100}
                        onMouseMove={(e) => {
                          //   setActiveBarIndex(e.activeTooltipIndex)
                        }}
                      >
                        <XAxis
                          dataKey='NoOfPatients'
                          type='number'
                          tickLine={false}
                          tick={false}
                          width={1100}
                        >
                          <Label
                            value='No. of Patients'
                            position='insideBottom'
                            offset={-3}
                            style={{
                              textAnchor: 'middle',
                              fill: 'blue',
                            }}
                          />
                        </XAxis>
                        <YAxis
                          type='category'
                          dataKey='city'
                          tickLine={false}
                          scale='auto'

                          //   padding={{ top: 10, bottom: 30 }}
                        >
                          <Label
                            angle={270}
                            position='left'
                            offset={25}
                            style={{
                              textAnchor: 'middle',
                              fill: 'blue',
                            }}
                          >
                            {location === 'city_count' ? 'City' : 'State'}
                          </Label>
                        </YAxis>
                        {/* <Tooltip
                            content={<CustomTooltip />}
                            // cursor={false}
                            cursor={false}
                            //   position={{ y: 170 - toolTipYPosition }}
                            //   offset={-90}
                            allowEscapeViewBox={{ x: true, y: true }}
                          /> */}

                        <Bar
                          dataKey='NoOfPatients'
                          fill='#8884d8'
                          barSize={20}
                          minPointSize={5}
                        >
                          <LabelList
                            dataKey='NoOfPatients'
                            content={renderCustomizedLabel}
                            style={{ paddingTop: 10 }}
                          />
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  )}
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  )
}
