/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { UmlResponse } from 'models/umlResponse'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addAllergiesDetails,
  resetAddAllergiesState,
} from 'redux/fhirMedicalResources/ipd/addAllergy/addAllergySliceIPD'
import {
  addAddictions,
  resetAddictions,
} from 'redux/fhirMedicalResources/ipd/addictions/addictionsAddSlice'
import { RootState } from 'redux/rootReducer'
import {
  addictions,
  alcoholCount,
  breathe,
  drugCount,
  energyLevels,
  excersiseCode,
  intensity,
  listOfHabbits,
  obsData,
  occupations,
  sleepPattern,
  smokeCounts,
  teaCount,
  tobacoCount,
} from 'utils/constants'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import {
  RadioRaisedButton,
  WelloFormItemLabel,
  WelloLoadingIndicator,
} from 'wello-web-components'
import { ClearOutlined } from '@material-ui/icons'
import {
  addOccupation,
  resetOccupationDetailsAdd,
} from 'redux/fhirMedicalResources/ipd/addOccupation/occupationAddSlice'
import { MensurationMasterSelector } from 'views/components/consultation/subjective/mensurationMasterSelector'
import {
  addMenstrualAdd,
  resetMesturationDetailsAdd,
} from 'redux/fhirMedicalResources/ipd/addMensuration/AddMensurationSlice'
import { ObecityMasterSelecor } from 'views/components/consultation/subjective/obecityMasterSelector'
import {
  addObsData,
  resetObsAdd,
} from 'redux/fhirMedicalResources/ipd/addObs/addObsSliceData'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  onClose: () => void
  onAddictionAdded: () => void
}
export const AddObs: React.FC<Props> = ({
  open,
  onClose,
  onAddictionAdded,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const [occupation, setOccupation] = useState<R4.ICoding>()
  const [occupationsData, setOccupationData] =
    useState<R4.ICoding[]>(occupations)
  const [additionalNotes, setAdditionalNotes] = useState<string>()

  const [text, setText] = useState<string>('')

  const dispatch = useDispatch()

  const occupationalAddSlice = useSelector(
    (state: RootState) => state.addObsSlice
  )

  useEffect(() => {
    if (occupationalAddSlice.additionSuccessful) {
      onAddictionAdded()
      dispatch(resetObsAdd())
      setOccupation(undefined)
      setAdditionalNotes(undefined)
    }
  }, [dispatch, occupationalAddSlice, onAddictionAdded])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setOccupation(undefined)
        setAdditionalNotes(undefined)
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Add Obstetrics Details</DialogTitle>
      <DialogContent dividers={true}>
        {occupationalAddSlice.adding && (
          <WelloLoadingIndicator message='Adding details' />
        )}

        {!occupationalAddSlice.adding && (
          <Box marginX={2} marginY={1}>
            <Grid container direction='column' spacing={1}>
              <Grid item>
                <Box display='flex' flexDirection='column' width='100%'>
                  {/* <WelloFormItemLabel title='Obstetrics' /> */}
                  <Box display='flex' flexDirection='row' width='100%'>
                    <WelloSelectFHIRCoding
                      title='Mode of Delivery'
                      id='unit_person_mode'
                      availableCodings={obsData}
                      onChanged={(e) => {
                        if (e) {
                          setOccupation(e)
                        }
                      }}
                      textFieldProps={{
                        size: 'small',
                        disabled: occupationalAddSlice.adding,
                      }}
                    />
                    {/* <ObecityMasterSelecor
                      onSelectionChanges={(e: R4.ICoding) => {
                        setOccupation(e)
                      }}
                      disabled={false}
                    /> */}
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Grid container direction='column'>
                  <Grid item>
                    <WelloFormItemLabel title='GPAL(notes)' />
                  </Grid>
                  <Grid item style={{ paddingBottom: '16px' }}>
                    <TextField
                      size='small'
                      fullWidth
                      multiline
                      id='obs_5'
                      type='number'
                      value={additionalNotes}
                      variant='outlined'
                      onChange={(event) => {
                        setAdditionalNotes(event.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
            setOccupation(undefined)
            setAdditionalNotes(undefined)
          }}
          disabled={occupationalAddSlice.adding}
          variant='outlined'
          id='obs_6'
          disableElevation
        >
          {t('labelCommon:cancel')}
        </Button>
        <Button
          onClick={() => {
            dispatch(
              addObsData(fhirAppointmentDetails, occupation, additionalNotes)
            )
          }}
          variant='contained'
          color='primary'
          id='obs_7'
          disableElevation
          disabled={occupation === undefined || occupationalAddSlice.adding}
        >
          {t('labelCommon:add')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
