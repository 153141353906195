import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import _ from 'lodash'
import {
  WelloAyurvedaMedication,
  WelloMedication,
} from 'models/welloMedication'
import moment from 'moment'
import React from 'react'
import {
  getTimeOfDayDisplayForStatement,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import OverflowTypography from './overflowTypography'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '4px',
      margin: '0px',
    },
  },
})

interface MedicationsListProps {
  medicationsList: WelloMedication[]
}

export const AyurvedaMedicationsTempList: React.FC<MedicationsListProps> = ({
  medicationsList,
}: MedicationsListProps) => {
  const classes = useStyles()

  return (
    <TableContainer
      style={{
        width: '100%',
        margin: '0px',
        padding: '0px',
      }}
    >
      <Table
        className={classes.table}
        aria-label='simple table'
        style={{
          tableLayout: 'fixed',
        }}
      >
        <TableHead
          style={{
            backgroundColor: kDialogueBackground,
          }}
        >
          <TableRow
            style={{
              fontWeight: 'bold',
            }}
          >
            <TableCell width={20}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Oushadhi (Medication)
              </Typography>
            </TableCell>
            <TableCell width={8}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Dose
              </Typography>
            </TableCell>
            <TableCell width={20}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Route of Administration
              </Typography>
            </TableCell>
            <TableCell width={15}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Time of Day
              </Typography>
            </TableCell>
            <TableCell width={15}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Oushadhakala
              </Typography>
            </TableCell>
            <TableCell width={15}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Anupana
              </Typography>
            </TableCell>
            <TableCell width={8}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Dose
              </Typography>
            </TableCell>
            <TableCell width={12}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Start from
              </Typography>
            </TableCell>
            <TableCell width={12}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Till
              </Typography>
            </TableCell>
            <TableCell width={15}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Additional Notes
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {medicationsList.map((row: WelloAyurvedaMedication, index) => (
            <TableRow
              key={row.id ?? row.medicationCode?.code ?? row.code ?? ''}
              style={{
                backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f5',
              }}
            >
              <TableCell>
                <OverflowTypography
                  text={_.capitalize(
                    row.medicationCode
                      ? row.medicationCode?.display ?? row.code ?? ''
                      : ''
                  )}
                  typographyProps={{
                    variant: 'subtitle2',
                  }}
                />
              </TableCell>
              <TableCell>
                {row.dosageQuantity && (
                  <OverflowTypography
                    text={_.capitalize(
                      `${row.dosageQuantity?.toFixed(
                        0
                      )}  ${getUnitOfDosageFromRequest(
                        row.dosageQuantityType ?? ''
                      )}`
                    )}
                    typographyProps={{
                      variant: 'subtitle2',
                    }}
                  />
                )}
                {row.dosageQuantity === undefined && (
                  <OverflowTypography
                    text={_.capitalize(
                      `${getUnitOfDosageFromRequest(
                        row.dosageQuantityType ?? ''
                      )}`
                    )}
                    typographyProps={{
                      variant: 'subtitle2',
                    }}
                  />
                )}
              </TableCell>
              <TableCell>
                {row.administrationCode && (
                  <OverflowTypography
                    text={getUnitOfRouteRequest(row.administrationCode)}
                    typographyProps={{
                      variant: 'subtitle2',
                    }}
                  />
                )}
              </TableCell>
              <TableCell>
                <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    width: '85px',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                    wordWrap: 'break-word',
                  }}
                >
                  {getTimeOfDayDisplayForStatement(
                    row.timeOfDayCode ?? []
                  ).join(', ') ?? ''}
                </Typography>
              </TableCell>
              <TableCell>
                {row.aushadhaKalaCode && (
                  <OverflowTypography
                    text={_.capitalize(
                      row.aushadhaKalaCode
                        ? row.aushadhaKalaCode.display ?? ''
                        : ''
                    )}
                    typographyProps={{
                      variant: 'subtitle2',
                    }}
                  />
                )}
              </TableCell>
              <TableCell>
                <OverflowTypography
                  text={_.capitalize(
                    row.anupanaCode ? row.anupanaCode.display ?? '' : ''
                  )}
                  typographyProps={{
                    variant: 'subtitle2',
                  }}
                />
              </TableCell>
              <TableCell>
                {row.anupanaDosageQuantity && (
                  <OverflowTypography
                    text={`${row.anupanaDosageQuantity?.toFixed(
                      0
                    )} ${getUnitOfDosageFromRequest(
                      row.anupanaDosageQuantityType ?? ''
                    )}`}
                    typographyProps={{
                      variant: 'subtitle2',
                    }}
                  />
                )}
              </TableCell>
              <TableCell>
                <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    width: '65px',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  {`${moment(row.startFrom).format('DD-MM-YYYY')}`}
                </Typography>
              </TableCell>
              <TableCell size='medium' width='50px'>
                <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    width: '65px',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  {`${moment(row.till).format('DD-MM-YYYY')}`}
                </Typography>
              </TableCell>
              <TableCell>
                <OverflowTypography
                  text={row.notes ?? ''}
                  typographyProps={{
                    color: 'initial',
                    variant: 'body2',
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
