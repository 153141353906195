import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserUnitDetails,
  getUserCurrentRole,
  isOrgAdmin,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getPractitionersWithRoleObject } from 'utils/fhirResoureHelpers/practitioner_and_role_helpers'
import { logger } from 'utils/logger'
import { CrossCarePractitionerSearchStatus } from './crossCarePractioinerSearchStatusTypes'

const initialState: CrossCarePractitionerSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const crossCarePractitionerSearchSlice = createSlice({
  name: 'crossCarePractitionerSearch',
  initialState,
  reducers: {
    searchingDoctorDetails(
      state,
      action: PayloadAction<CrossCarePractitionerSearchStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false

      state.errorMessage = ''
      state.resultsAvailable = false

      state.doctorRoleList = undefined
      state.userDetails = undefined
    },

    searchResults(
      state,
      action: PayloadAction<CrossCarePractitionerSearchStatus>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false

      state.errorMessage = ''
      state.resultsAvailable = true

      state.doctorRoleList = action.payload.doctorRoleList
      state.userDetails = action.payload.userDetails
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<CrossCarePractitionerSearchStatus>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.doctorRoleList = undefined
      state.userDetails = undefined
    },

    errorWhileSearching(
      state,
      action: PayloadAction<CrossCarePractitionerSearchStatus>
    ) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.doctorRoleList = undefined
      state.userDetails = undefined
    },
  },
})

export const searchActivePractitionersForCrossCare =
  (name?: string, roles?: R4.ICoding[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: CrossCarePractitionerSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      crossCarePractitionerSearchSlice.actions.searchingDoctorDetails(state)
    )
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _include: 'PractitionerRole:practitioner',
        _count: 500,
        active: true,
        'practitioner:Practitioner._tag': 'profile_details_completed',
      }

      if (
        isUnitAdmin() &&
        !isOrgAdmin() &&
        !(getUserCurrentRole() ?? []).includes('receptionist')
      ) {
        searchParameters.organization = `Organization/${
          getCurrentUserUnitDetails().id
        }`
      }

      if (roles && roles.length > 0) {
        const selectedRoles: string | undefined = roles
          ?.map((role) => role.code)
          .join(',')
        searchParameters.role = selectedRoles
      }
      if (name) {
        searchParameters['practitioner.name'] = name
      }

      const response: any = await fhirClient.doGetResource(
        '/PractitionerRole',
        searchParameters
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const practitionerRolesResponses: R4.IBundle =
          relatedFhirDecodeRes.right
        if (practitionerRolesResponses.total) {
          if (practitionerRolesResponses.total > 0) {
            if (practitionerRolesResponses.entry) {
              const practitionerWithRoleList: PractitionerWithRole[] =
                getPractitionersWithRoleObject(practitionerRolesResponses) ?? []

              state.doctorRoleList = practitionerWithRoleList
              state.resultsAvailable = true
              state.searching = false
              state.error = false
              dispatch(
                crossCarePractitionerSearchSlice.actions.searchResults(state)
              )
              return
            }
            state.resultsAvailable = false
            state.searching = false
            state.error = false
            state.noResultsAvailable = true
            dispatch(
              crossCarePractitionerSearchSlice.actions.noDataFoundForSearch(
                state
              )
            )
            return
          }
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(
          crossCarePractitionerSearchSlice.actions.noDataFoundForSearch(state)
        )
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(
        crossCarePractitionerSearchSlice.actions.errorWhileSearching(state)
      )
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(
        crossCarePractitionerSearchSlice.actions.errorWhileSearching(state)
      )
    }
  }

export function getPractitionerRoleObject(item: R4.IPractitionerRole) {
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    gender: '',
    name: item.practitioner?.display ?? '',
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: false,
  }
  return val
}

export default crossCarePractitionerSearchSlice.reducer
