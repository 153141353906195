import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { sleep } from 'utils/dateUtil'
import { getStressLevelUnit } from 'utils/fhirResourcesHelper'
import { getVitalsObservationObjectForIPD } from 'utils/fhirResoureHelpers/observationHelpers'
import { requestOccupationHistoryOfPatient } from '../occupation/occupationSearchSlice'
// import { requestVitalDetailsOfPatientIPD } from '../vitalsDetailsIpd/vitalsDetailSliceIpd'
import { OccupationAddStatus } from './addOccupationStatus'

const initialState: OccupationAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const occupationalAddSlice = createSlice({
  name: 'occupationalAddSlice',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<OccupationAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addedVitalsBundle = action.payload.addedVitalsBundle
    },

    resetAddVitalsDetails(state, action: PayloadAction<OccupationAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addedVitalsBundle = initialState.addedVitalsBundle
    },
  },
})

export const addOccupation =
  (
    appointment: FhirActiveIPDDetailsForMedicalRole,
    mentalVal: number,
    physicalVal: number,
    occupation?: R4.ICoding,
    notes?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: OccupationAddStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(occupationalAddSlice.actions.updateAddVitalsStatus(addingState))

    try {
      const bundleObject: R4.IBundle = createBundleObjectForObservations(
        appointment,
        mentalVal,
        physicalVal,
        occupation,
        notes
      )

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        bundleObject
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        await sleep(5000)
        dispatch(
          requestOccupationHistoryOfPatient(
            appointment.mainServiceRequest.id!,
            appointment.patient
          )
        )
        dispatch(showSuccessAlert('Occupation History Added Successfully'))

        dispatch(
          occupationalAddSlice.actions.updateAddVitalsStatus(addingState)
        )
      } else {
        const errorCreatePersonState: OccupationAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while adding addictions',
        }
        dispatch(
          occupationalAddSlice.actions.updateAddVitalsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: OccupationAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while adding addictions',
      }
      dispatch(
        occupationalAddSlice.actions.updateAddVitalsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetOccupationDetailsAdd = () => (dispatch: AppDispatch) => {
  dispatch(occupationalAddSlice.actions.resetAddVitalsDetails(initialState))
}

function createBundleObjectForObservations(
  appointment: FhirActiveIPDDetailsForMedicalRole,
  mentalVal: number,
  physicalVal: number,
  occupation?: R4.ICoding,
  notes?: string
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const encounterRef: R4.IReference = {
    reference: `Encounter/urn:uuid:1232323232324`,
    type: 'Encounter',
  }

  if (occupation != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObjectForIPD(appointment, encounterRef),
    }

    observationObject.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationOccupation',
      ],
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    observationObject.code = {
      text: 'Occupation',
      coding: [
        {
          code: '21843-8',
          display: 'Occupation',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'social-history',
            display: 'Social History',
          },
        ],
      },
    ]
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueCodeableConcept = {
      text: occupation.display ?? '',
      coding: [occupation],
    }
    const practitionerRoleDetail: R4.IPractitionerRole =
      getCurrentUserPractitionerRoleDetails()
    if (mentalVal > 0) {
      observationObject.component = [
        {
          code: {
            coding: [
              {
                system: 'http://snomed.info/sct',
                code: '405052004',
                display: 'Mental Stress Level',
              },
            ],
          },
          valueInteger: getStressLevelUnit(mentalVal),
        },
      ]
    }

    if (physicalVal > 0) {
      if (observationObject.component === undefined) {
        observationObject.component = [
          {
            code: {
              coding: [
                {
                  system: 'http://snomed.info/sct',
                  code: '55539008',
                  display: 'Physical Stress Level',
                },
              ],
            },
            valueInteger: getStressLevelUnit(physicalVal),
          },
        ]
      } else {
        observationObject.component.push({
          code: {
            coding: [
              {
                system: 'http://snomed.info/sct',
                code: '55539008',
                display: 'Physical Stress Level',
              },
            ],
          },
          valueInteger: getStressLevelUnit(physicalVal),
        })
      }
    }
    const practitioner: R4.IPractitioner = getCurrentUserPractitionerDetails()
    if (notes && notes.length > 0) {
      observationObject.note = [
        {
          authorReference: {
            reference: `${practitioner.resourceType}/${practitioner.id}`,
          },
          text: notes,
          time: new Date().toISOString(),
        },
      ]
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  return requestBundle
}

export default occupationalAddSlice.reducer
