import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource } from 'axios'
import { AppDispatch, AppThunk } from 'redux/store'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import { getGenderOfDoctor } from 'utils/fhirResourcesHelper'
import { IncomingReferralsListStatus } from './incomingReferralsCount'

const initialState: IncomingReferralsListStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
  count: 0,
}

let currentSelectedDate: Date | undefined

const incomingReferralsCountSlice = createSlice({
  name: 'incomingReferralsCountSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<IncomingReferralsListStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.count = action.payload.count
      state.errorReason = action.payload.errorReason

      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const requestForIncomingReferralsCount =
  (
    selectedStatus?: string[],
    pageState?: string,
    offSet?: number,
    existingDateWiseData?: R4.IServiceRequest[],
    recordsCount?: number,
    gender?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: IncomingReferralsListStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      count: 0,
    }
    dispatch(incomingReferralsCountSlice.actions.updatedStatus(state))
    try {
      const unitId = getCurrentUserUnitReference()?.reference?.split('/')[1]
      currentSelectedDate?.setSeconds(new Date().getSeconds())
      const searchParams: any = {
        perPageCount: 10,
        unitId,
        status: 'active',
      }

      if (pageState && offSet) {
        searchParams.stateId = pageState
        searchParams.offSetNumber = offSet
      }
      searchParams.gender = getGenderOfDoctor(
        getCurrentUserPractitionerDetails()
      )
      if (selectedStatus && selectedStatus.length > 0) {
        searchParams.status = selectedStatus.join(',')
      }

      const response: any = await getIpdSListForTherapist(
        searchParams.startDate,
        searchParams.status,
        searchParams.perPageCount,
        searchParams.stateId,
        searchParams.offSetNumber,
        searchParams.unitId
      )

      const resp: R4.IBundle = response as R4.IBundle
      if (!resp) {
        state.errorWhileSearchingAppointments = true
        state.searchingAppointments = false

        dispatch(incomingReferralsCountSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          state.resultsAvailable = true
          state.searchingAppointments = false
          state.count = appointmentResponse.total
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingAppointments = false
          dispatch(incomingReferralsCountSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: IncomingReferralsListStatus = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            count: 0,
          }
          dispatch(
            incomingReferralsCountSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      }
    } catch (error) {
      console.error('---------error------------')
      console.error(error)
      if (error) {
        if ((error as any).message) {
          if ((error as any).message === 'new param added') {
            return
          }
        }
      }
      const errorSearchDoctor: IncomingReferralsListStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
        count: 0,
      }
      dispatch(
        incomingReferralsCountSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

async function getIpdSListForTherapist(
  startDate: string,
  status?: string,
  perPageCount?: number,
  stateId?: string,
  pageNumber?: number,
  organizationId?: string
): Promise<R4.IBundle | R4.IOperationOutcome | undefined> {
  axios.CancelToken.source()
  if (cancelTokenStore.has('therapiesSearchControlToken')) {
    const controlTokenForSearch: CancelTokenSource = cancelTokenStore.get(
      'therapiesSearchControlToken'
    )
    controlTokenForSearch.cancel('new param added')
    cancelTokenStore.delete('therapiesSearchControlToken')
  }
  cancelTokenStore.set(
    'therapiesSearchControlToken',
    axios.CancelToken.source()
  )
  try {
    const fhirClient: FHIRApiClient = new FHIRApiClient()

    const searchParameters: any = {
      _count: 0,
      status: 'active',
      intent: 'proposal',

      code: 'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-service-type-cs|wellness-therapy-referral',
    }

    const r = await fhirClient.doGetResourceIncludeAndIncludeIterate(
      '/ServiceRequest',
      searchParameters
    )

    const completeDate = r

    return completeDate
  } catch (error) {
    throw error
  }

  return undefined
}

export default incomingReferralsCountSlice.reducer
