import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { PatientDataWithAppointment } from 'models/patientDataWithAppointment'
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { CcClinet } from 'services/Cclinet'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getUserDetails } from 'services/userDetailsService'
import { logger } from 'utils/logger'
import { ResendStatus } from './resendInviteStatus'

const initialState: ResendStatus = {
  sending: false,
  sentSuccesFully: false,
  error: false,
  errorMessage: '',
  patient: undefined,
}

const resendInviteSlice = createSlice({
  name: 'resendInviteSlice',
  initialState,
  reducers: {
    updateStatus(state, action: PayloadAction<ResendStatus>) {
      state.sending = action.payload.sending
      state.sentSuccesFully = action.payload.sentSuccesFully
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.patient = action.payload.patient
    },
  },
})

export const resndInvite =
  (patientId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: ResendStatus = {
      sending: true,
      sentSuccesFully: false,
      error: false,
    }
    dispatch(resendInviteSlice.actions.updateStatus(addingCreatePersonState))

    try {
      logger.info('patientDetails')
      const currentUserPractRole: R4.IPractitionerRole | undefined =
        getUserDetails()?.practitionerRole

      const formData = {
        owner: `Patient/${patientId}`,
        requestor: `PractitionerRole/${
          currentUserPractRole ? currentUserPractRole.id ?? '' : ''
        }`,
        taskCode: 'user-invitation',
      }

      const enRolClient: CcClinet = new CcClinet()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        'invitation/resend',
        formData
      )
      const successCreatePersonState: ResendStatus = {
        sending: false,
        sentSuccesFully: true,
        error: true,
        errorMessage: '',
      }
      dispatch(resendInviteSlice.actions.updateStatus(successCreatePersonState))
      dispatch(showSuccessAlert('Invitation resent successfully'))
      dispatch(resendInviteSlice.actions.updateStatus(initialState))
      return
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorCreatePersonState: ResendStatus = {
        sending: false,
        sentSuccesFully: false,
        error: true,
        errorMessage: 'Error while uploading patient data',
      }
      dispatch(showErrorAlert('Error while sending invite'))
      dispatch(resendInviteSlice.actions.updateStatus(errorCreatePersonState))
    }
  }

export const resetUpdateUserState = () => (dispatch: AppDispatch) => {
  dispatch(resendInviteSlice.actions.updateStatus(initialState))
}

export default resendInviteSlice.reducer
