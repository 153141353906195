import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CohortDisplayData } from 'models/cohortDisplayData'
import { CohortDisplayWithCount } from 'models/cohortDisplayWithCount'
import { Filters } from 'models/filters'
import { WelloFilter } from 'models/welloFilter'
import { AppDispatch, AppThunk } from 'redux/store'
import { CohortClient } from 'services/cohortServiceClient'
import { OutComeClient } from 'services/outComeClient'
import {
  getPreObs,
  getTargetValues,
  getTreatment,
} from 'utils/cohort/careplaCohortUtils'
import {
  getAnalysisFilter,
  getCohortData,
  getCohortDataForSinglePercentile,
  getCohortDataWithGrouping,
  getCohortFilter,
  getStrataName,
  getCohortDataForFilterForSinglePercentileForMultiGraph,
} from 'utils/cohort/cohortUtils'
import { pilotDataResponse } from 'utils/constants'
import { logger } from 'utils/logger'
import { getVendorPartId } from 'utils/routes_helper'
import { CarePlanCohortSearchStatus } from './cohortSearchDataStatus'

const initialState: CarePlanCohortSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const carePlanCohortSearchSlice = createSlice({
  name: 'carePlanCohortSearchSlice',
  initialState,
  reducers: {
    searchingCohorts(
      state,
      action: PayloadAction<CarePlanCohortSearchStatus>
    ) {},

    searchResults(state, action: PayloadAction<CarePlanCohortSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.cohortOptions = action.payload.cohortOptions
      state.filters = action.payload.filters
      state.targetCoding = action.payload.targetCoding
      state.targetArray = action.payload.targetArray
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<CarePlanCohortSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.cohortOptions = action.payload.cohortOptions
      state.filters = action.payload.cohortOptions
      state.targetCoding = action.payload.targetCoding
      state.targetArray = action.payload.targetArray
    },

    errorWhileSearching(
      state,
      action: PayloadAction<CarePlanCohortSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.cohortOptions = action.payload.cohortOptions
      state.filters = undefined
      state.targetCoding = undefined
      state.targetArray = undefined
    },
    resetState(state, action: PayloadAction<CarePlanCohortSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.cohortOptions = undefined
      state.filters = undefined
      state.targetCoding = undefined
      state.targetArray = undefined
    },
  },
})

export const resetCarePlanCohortSearch =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: CarePlanCohortSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      cohortOptions: undefined,
      filters: undefined,
      targetCoding: undefined,
      targetArray: undefined,
    }
    dispatch(carePlanCohortSearchSlice.actions.resetState(state))
  }

export const searchingCareplanCohorts =
  (planId: string, duration: string, mode: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: CarePlanCohortSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      carePlanCohortSearchSlice.actions.errorWhileSearching(errorSearchPatient)
    )
    try {
      const fhirClient: OutComeClient = new OutComeClient()
      const vendorId: string | null = getVendorPartId()
      const response: any = await fhirClient.doGetResource(
        `/cohort/careplan_pathway?careplan=${planId}&tenant_id=${vendorId}&duration=${duration}d&mode=${mode}`
      )
      if (response) {
        const targetData: string[] = getTargetValues(response)
        if (targetData.length > 0) {
          const codingTarget: R4.ICoding[] = []
          for (let i = 0; i < targetData.length; i++) {
            codingTarget.push({
              code: targetData[i],
              system: '',
              display: targetData[i],
            })
          }
          const preObs: string[] = getPreObs(response)
          const treatmentData: string[] = getTreatment(response)
          const postObs: string[] = getPreObs(response)
          const filterData: WelloFilter = {
            target: targetData.length > 1 ? [targetData[0]] : [targetData[0]],
            pre_obs: preObs,
            intervention: treatmentData,
            outcome: postObs,
          }
          const searchPatientResult: CarePlanCohortSearchStatus = {
            error: false,
            noResultsAvailable: false,
            resultsAvailable: true,
            searching: false,
            cohortOptions: response.results,
            filters: filterData,
            targetArray: targetData,
            targetCoding: codingTarget.sort((a, b) =>
              b.display!.localeCompare(a.display!)
            ),
          }
          dispatch(
            carePlanCohortSearchSlice.actions.searchResults(searchPatientResult)
          )
          return
        }
        const noSearchResults: CarePlanCohortSearchStatus = {
          error: false,
          noResultsAvailable: true,
          resultsAvailable: false,
          searching: false,
        }
        dispatch(
          carePlanCohortSearchSlice.actions.noDataFoundForSearch(
            noSearchResults
          )
        )
        return
      }
      if (response.error) {
        const noSearchResults: CarePlanCohortSearchStatus = {
          error: false,
          noResultsAvailable: true,
          resultsAvailable: false,
          searching: false,
        }
        dispatch(
          carePlanCohortSearchSlice.actions.noDataFoundForSearch(
            noSearchResults
          )
        )
      }

      const noSearchResults: CarePlanCohortSearchStatus = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(
        carePlanCohortSearchSlice.actions.noDataFoundForSearch(noSearchResults)
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: CarePlanCohortSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        carePlanCohortSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(carePlanCohortSearchSlice.actions.resetState(initialState))
}

export default carePlanCohortSearchSlice.reducer
