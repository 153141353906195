import {
  Box,
  CircularProgress,
  Typography,
  useTheme,
  IconButton,
  Button,
} from '@material-ui/core'
import { Visibility } from '@material-ui/icons'

import { CPGForTable } from 'models/administration/cpgDetailsForTable'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  getCPSubscriptionsListForWPAdmin,
  getCPSubscriptionsOrgAndUnitAdmin,
  getOfferingCPSubscriptionsOrgAndUnitAdmin,
  offerSubscriptionInUnit,
  removeOfferSubscriptionInUnit,
} from 'utils/admisnistration/cpg/cpgListHelper'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'

import { kBackgroundDefault, kBackgroundPaper } from 'configs/styles/muiThemes'
import { getInvitedMembersForTaskOfUnit } from 'utils/admisnistration/crossFunctional/crossFunctinalUtils'
import { InvitedMemberForCareFunction } from 'models/administration/InvitedMemberDetail'
import {
  InviteCrossOrgCareTeamMemberPopUp,
  InviteCrossOrgCareTeamMemberPopUpProps,
} from './inviteCareTeamMember/inviteCareTeamMemberPopUp'
import { InvitedMembersListTable } from './inviteCareTeamMember/invitedMembersListTable'

interface Props {
  isReadonly?: boolean
}

export const OutSideOrgCareTeamMembersPage: React.FC<Props> = ({
  isReadonly: split,
}: Props) => {
  const [fetchStatus, setFetchStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [key, setKey] = useState<string>('initial')

  const [invitedMembers, setInvitedMembers] =
    useState<InvitedMemberForCareFunction[]>()

  const [inviteCareMember, setInviteCareMember] =
    useState<InviteCrossOrgCareTeamMemberPopUpProps>({
      open: false,
    })

  function fetchInvitedMembersList() {
    setFetchStatus({ requesting: true })
    getInvitedMembersForTaskOfUnit().then((e) => {
      if (e !== false) {
        setFetchStatus({
          requesting: false,
          requestSuccess: true,
        })
        setInvitedMembers(undefined)
        setInvitedMembers(e as InvitedMemberForCareFunction[])
      } else {
        setFetchStatus({
          requesting: false,
          requestSuccess: false,
        })
      }
    })
  }

  useEffect(() => {
    fetchInvitedMembersList()
  }, [])

  return (
    <Box
      display='flex'
      flexDirection='column'
      width='100%'
      height='100%'
      key={key}
    >
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        height='100%'
        px={2}
        bgcolor={kBackgroundPaper}
      >
        <Box display='flex' flexDirection='row-reverse' width='100%' pt={1}>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => {
              setInviteCareMember({
                open: true,
                onClose: () => {
                  setInviteCareMember({ open: false })
                  setKey(Math.random().toString())
                  fetchInvitedMembersList()
                },
              })
            }}
          >
            Invite
          </Button>
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          pt={1}
          pb={1}
          justifyContent='space-between'
        >
          {fetchStatus.requesting && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              pt={1}
              pb={1}
              justifyContent='center'
            >
              <CircularProgress />
            </Box>
          )}
          {!fetchStatus.requesting &&
            invitedMembers &&
            invitedMembers.length > 0 && (
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                pt={1}
                pb={1}
                height='100%'
              >
                <InvitedMembersListTable medicationsList={invitedMembers} />
              </Box>
            )}
          {!fetchStatus.requesting &&
            (!invitedMembers || invitedMembers.length === 0) && (
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                pt={1}
                pb={1}
                height='100%'
                justifyContent='center'
              >
                <Typography>No invited members</Typography>
              </Box>
            )}
        </Box>
      </Box>
      {inviteCareMember.open && (
        <InviteCrossOrgCareTeamMemberPopUp {...inviteCareMember} />
      )}
    </Box>
  )
}
