import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { CrossOrgEnabledPractitioner } from 'models/practRoleResponse'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import {
  getAvailableProvidersForCareTeam,
  inviteDoctorForCareTeam,
} from 'utils/admisnistration/crossFunctional/crossFunctinalUtils'
import { getSpecializationsList } from 'utils/fhirResourcesHelper'
import { ValueSetSelectMultiple } from 'views/components/common/MultiSelectFromValueSetUrl'

import { CareTeamMemberTable } from './crossOrgCareMemebrTable'

export interface DoctorWithCareTeamRole {
  doctor: CrossOrgEnabledPractitioner
  careTeamRole?: R4.ICoding[]
}

export interface InviteCrossOrgCareTeamMemberPopUpProps {
  open: boolean
  onClose?: () => void
}
export const InviteCrossOrgCareTeamMemberPopUp: React.FC<InviteCrossOrgCareTeamMemberPopUpProps> =
  ({ open, onClose }) => {
    const { t } = useTranslation(['common'])

    const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })

    const [inviteRequestStatus, setInviteRequestStatus] =
      useState<NetworkRequestStatus>({
        initial: true,
        requesting: false,
      })

    const [availableProviders, setAvailableProvidersList] =
      useState<CrossOrgEnabledPractitioner[]>()

    const [selectedProviderSearchType, setSelectedProviderSearch] =
      useState<'practitioner' | 'organization' | 'unit'>('practitioner')

    const [searchString, setSearchProvider] = useState<string>()

    const [currentSelectedProvider, setCurrentSelectedProvider] =
      useState<DoctorWithCareTeamRole>()

    const [currentSelectedProviders, setCurrentSelectedProviders] = useState<
      DoctorWithCareTeamRole[]
    >([])
    const dispatch = useDispatch()

    function fetchProvidersForSelectedCriteria() {
      let practitionerName: string | undefined
      let organizationName: string | undefined
      let unitName: string | undefined
      if (selectedProviderSearchType === 'practitioner') {
        practitionerName = searchString
      }
      if (selectedProviderSearchType === 'organization') {
        organizationName = searchString
      }
      if (selectedProviderSearchType === 'unit') {
        unitName = searchString
      }
      setUpdateStatus({ requesting: true })
      getAvailableProvidersForCareTeam({
        practitionerName,
        organizationName,
        unitName,
      }).then((e) => {
        if (e !== false) {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          console.log('----------------updated cpg list----------------', e)
          setAvailableProvidersList(e as CrossOrgEnabledPractitioner[])
        } else {
          setAvailableProvidersList([])
          setUpdateStatus({
            requesting: false,
            requestError: false,
          })
        }
      })
    }

    function sendInvitation() {
      const updatedPractPrefs = [...currentSelectedProviders]
      if (currentSelectedProvider) {
        updatedPractPrefs.push(currentSelectedProvider)
      }
      if (updatedPractPrefs.length > 0) {
        setInviteRequestStatus({ requesting: true })
        inviteDoctorForCareTeam(updatedPractPrefs).then((e) => {
          if (e !== false) {
            setInviteRequestStatus({
              requesting: false,
              requestSuccess: true,
            })
            console.log('----------------Invite response----------------', e)
            dispatch(showSuccessAlert('Invitation Sent Successfully'))
            if (onClose) onClose()
          } else {
            setInviteRequestStatus({
              requesting: false,
              requestError: false,
            })
            dispatch(showErrorAlert('Error while sending invitation'))
          }
        })
      }
    }

    return (
      <Dialog
        open={open}
        onClose={() => {
          if (onClose) onClose()
        }}
        aria-labelledby='responsive-dialog-title'
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle id='scroll-dialog-title'>
          Invite Care Team Member
        </DialogTitle>
        <DialogContent
          dividers={true}
          style={{
            backgroundColor: '#ededed',
            padding: 0,
            overflowWrap: 'normal',
            overscrollBehaviorX: 'none',
            margin: 0,
          }}
        >
          {currentSelectedProvider === undefined && (
            <Box
              px={2}
              py={1}
              display='flex'
              flexDirection='column'
              height='100%'
              width='100%'
            >
              <Box
                display='flex'
                flexDirection='row'
                alignContent='start'
                width='100%'
              >
                <FormControl component='fieldset'>
                  <RadioGroup
                    row
                    aria-label='position'
                    name='position'
                    value={selectedProviderSearchType}
                    onChange={(e) => {
                      setSelectedProviderSearch(
                        e.target.value as
                          | 'practitioner'
                          | 'organization'
                          | 'unit'
                      )
                      setAvailableProvidersList([])
                      setSearchProvider('')
                    }}
                  >
                    <FormControlLabel
                      value='practitioner'
                      control={<Radio color='primary' />}
                      label={<Typography variant='body1'>Doctor</Typography>}
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      value='organization'
                      control={<Radio color='primary' />}
                      label={
                        <Typography variant='body1'>Organization</Typography>
                      }
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      value='unit'
                      control={<Radio color='primary' />}
                      label={<Typography variant='body1'>Unit</Typography>}
                      labelPlacement='end'
                    />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box
                display='flex'
                flexDirection='row'
                alignContent='start'
                width='100%'
              >
                <TextField
                  id='search_provider'
                  label=''
                  value={searchString}
                  fullWidth
                  variant='outlined'
                  size='small'
                  placeholder='Enter 3 or more characters to search'
                  onChange={(e) => {
                    setSearchProvider(e.target.value)
                    if (e.target.value.length > 0) {
                      fetchProvidersForSelectedCriteria()
                    }
                  }}
                />
              </Box>

              {updateStatus.requesting && (
                <Box
                  display='flex'
                  flexDirection='row'
                  alignContent='center'
                  width='100%'
                  height='20px'
                >
                  <CircularProgress size={25} />
                  <Typography variant='body1' color='initial'>
                    Fetching Providers
                  </Typography>
                </Box>
              )}

              {!updateStatus.requesting &&
                availableProviders &&
                availableProviders?.length > 0 && (
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignContent='start'
                    width='100%'
                    minHeight='200px'
                    flexGrow={1}
                  >
                    <CareTeamMemberTable
                      medicationsList={availableProviders}
                      actionComponent={
                        <Button
                          variant='contained'
                          color='primary'
                          size='small'
                        >
                          Select
                        </Button>
                      }
                      onActionClick={(cpg: CrossOrgEnabledPractitioner) => {
                        setCurrentSelectedProvider({
                          doctor: cpg,
                          careTeamRole: undefined,
                        })
                      }}
                    />
                  </Box>
                )}

              {!updateStatus.requesting &&
                availableProviders !== undefined &&
                availableProviders.length === 0 && (
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignContent='center'
                    width='100%'
                  >
                    <Typography variant='body1' color='initial'>
                      No Providers Found
                    </Typography>
                  </Box>
                )}
            </Box>
          )}
          {currentSelectedProvider !== undefined && (
            <Box
              px={2}
              py={1}
              display='flex'
              flexDirection='column'
              width='100%'
            >
              <Box
                display='flex'
                flexDirection='row'
                alignContent='start'
                width='100%'
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  alignContent='start'
                  width='100%'
                >
                  <Box py={1} px={0.25} display='flex' flexDirection='row'>
                    <Typography variant='subtitle2'>Doctor</Typography>
                  </Box>
                  <TextField
                    id='selected_provider'
                    label=''
                    value={
                      currentSelectedProvider?.doctor.practitionerName ?? ''
                    }
                    fullWidth
                    variant='outlined'
                    size='small'
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='row'
                alignContent='start'
                width='100%'
                justifyContent='space-between'
              >
                <Box display='flex' flexDirection='column' flexGrow={1} px={1}>
                  <Box py={0.5} display='flex' flexDirection='row'>
                    <Typography variant='subtitle2' color='initial'>
                      Organization
                    </Typography>
                  </Box>

                  <Typography variant='body1' color='initial'>
                    {currentSelectedProvider?.doctor.organizationName}
                  </Typography>
                </Box>

                <Box display='flex' flexDirection='column' flexGrow={1}>
                  <Box py={0.5} display='flex' flexDirection='row'>
                    <Typography variant='subtitle2' color='initial'>
                      Unit
                    </Typography>
                  </Box>
                  <Typography variant='body1' color='initial'>
                    {currentSelectedProvider?.doctor.unitName}
                  </Typography>
                </Box>

                <Box display='flex' flexDirection='column' flexGrow={1}>
                  <Box py={0.5} display='flex' flexDirection='row'>
                    <Typography variant='subtitle2' color='initial'>
                      Specialization
                    </Typography>
                  </Box>

                  <Typography variant='body1' color='initial'>
                    {getSpecializationsList(
                      currentSelectedProvider?.doctor.specialty ?? []
                    )}
                  </Typography>
                </Box>
              </Box>

              <Box
                display='flex'
                flexDirection='row'
                alignContent='start'
                width='100%'
                pt={2}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  alignContent='start'
                  width='100%'
                >
                  <ValueSetSelectMultiple
                    key='wellopathy-care-team-roles-vs'
                    title='Role Offered'
                    url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-care-team-roles-vs'
                    id='careTeamRoleOfferSelect'
                    fuzzySearch={true}
                    disabled={false}
                    onOptionSelected={(selectedServiceType) => {
                      if (selectedServiceType.length > 0) {
                        setCurrentSelectedProvider({
                          ...currentSelectedProvider,
                          careTeamRole: selectedServiceType,
                        })
                      } else {
                        setCurrentSelectedProvider({
                          ...currentSelectedProvider,
                          careTeamRole: undefined,
                        })
                      }
                    }}
                    helperText={undefined}
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='row'
                alignContent='start'
                width='100%'
                pt={2}
              >
                <Button
                  variant='text'
                  color='primary'
                  size='small'
                  onClick={() => {
                    setCurrentSelectedProviders([
                      ...currentSelectedProviders,
                      currentSelectedProvider,
                    ])
                    setCurrentSelectedProvider(undefined)
                  }}
                >
                  Invite More
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box display='flex' flexDirection='row'>
              <Box paddingX={1}>
                <Button
                  onClick={() => {
                    if (onClose) onClose()
                  }}
                  variant='outlined'
                  disableElevation
                  id='cancel'
                >
                  {t('labelCommon:cancel')}
                </Button>
              </Box>
              {currentSelectedProvider !== undefined &&
                currentSelectedProvider.doctor && (
                  <Box paddingX={1}>
                    <Button
                      onClick={() => {
                        sendInvitation()
                      }}
                      variant='contained'
                      color='primary'
                      disableElevation
                      id='invite'
                      disabled={
                        currentSelectedProvider.careTeamRole === undefined ||
                        inviteRequestStatus.requesting
                      }
                    >
                      {inviteRequestStatus.requesting && (
                        <CircularProgress size={20} />
                      )}

                      {!inviteRequestStatus.requesting && 'Invite'}
                    </Button>
                  </Box>
                )}
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    )
  }
