/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetPatientSearchPatient,
  searchPatients,
} from 'redux/patient/patientSearch/patientSearchSlice'
import { RootState } from 'redux/rootReducer'
import { nationalityValueSet, preDefinedCountry } from 'utils/constants'
import { martialList } from 'utils/constants/marital_status_types'
import { getAgeOfPatient, getNameOfPatient } from 'utils/fhirResourcesHelper'
import {
  getFhirPatientObjectFromForm,
  getFhirRelatedObjectHSForm,
} from 'utils/formHelper'
import { getFhirRelatedObjectFromForm } from 'utils/labHelpers/patientBulkUploadHelper'
import {
  Controls,
  Form,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getTelecomOfPatient,
  WelloFormItemLabel,
  WelloLoadingIndicator,
  WelloTextField,
} from 'wello-web-components'
import female from '../../../assets/icons/f.png'
import male from '../../../assets/icons/m.png'
import {
  validateAddress,
  validateDob,
  validateEmail,
  validateFathersName,
  validateGender,
  validateHusbandsName,
  validateLastName,
  validateLRNumber,
  validateMiddleName,
  validateMobileNumberForAdd,
  validateName,
  validateNationality,
  validateOtherData,
} from '../../../utils/formValidators'
import { ValueSetSelectSingle } from '../common/SingleSelectFromValueSetUrl'
import { PhoneNumber } from '../LeftMenu/PhoneNumber'
import { WelloButton } from '../LeftMenu/WelloButton'
import { WelloEmailInput } from '../LeftMenu/welloEmailInput'
import { WelloSelectFormForPatient } from '../LeftMenu/welloSelectFormPatient'
import { UseForm } from '../UseForm'
import { ChoosePatient } from './choosePatientDialog'

export interface IFormLabels {
  nameLabel?: string
  mNameLabel?: string
  lNameLabel?: string
  emailLabel?: string
  phoneNumberLabel?: string
  genderLabel?: string
  addressLabel?: string
  photoLabel?: string
  identityLabel?: string
  maritalStatusLabel?: string
  nationalityLabel?: string
  occupationLabel?: string
  fatherNameLabel?: string
  husbandNameLabel?: string
  referenceIdLabel?: string
  addressTypeLabel?: string
  doblabelForKey?: string
}

export interface IPlaceHolderLablesLabels {
  nameLabel?: string
  mNameLabel?: string
  lNameLabel?: string
  emailLabel?: string
  phoneNumberLabel?: string
  genderLabel?: string
  addressLabel?: string
  photoLabel?: string
  identityLabel?: string
  maritalStatusLabel?: string
  nationalityLabel?: string
  occupationLabel?: string
  fatherNameLabel?: string
  husbandNameLabel?: string
  addPatient?: string
  addPhoto?: string
  doblabel?: string
  referenceIdLabel?: string
  addressTypeLabel?: string
}
export interface IUnitAdmin {
  children?: React.ReactNode
  id: number
  count: number
  submitButtonText?: string
  resetButtonText?: string
  onValidSubmit?: (
    patientDetail: R4.IPatient,
    relatedPersonDetail?: R4.IRelatedPerson,
    relatedPersonHusbandDetail?: R4.IRelatedPerson,
    occupation?: string,
    nationality?: string,
    addressProofFront?: R4.IAttachment,
    addressProofBack?: R4.IAttachment,
    addressProofBoth?: R4.IAttachment,
    referredId?: string,
    relation?: R4.ICoding,
    isPrimary?: boolean
  ) => void
  preAddedPatient?: R4.IPatient
  preAddedRelatedPatient?: R4.IRelatedPerson
  onClickToCancel: (val: boolean) => void
  formLabels?: IFormLabels
  placeholderLables?: IPlaceHolderLablesLabels
  popupSate: boolean
}

const errorValues = {
  name: '',
  phone: '',
  email: '',
  address: '',
}

function getInitializeValues(
  patient?: R4.IPatient,
  relatedPerson?: R4.IRelatedPerson
) {
  let initialFValues = {
    id: '0',
    name: '',
    middleName: '',
    lastName: '',
    gender: '',
    phone: '',
    email: '',

    dob: '',
    age: 0,
    width: 60,

    maritalStatus: '',
    addressType: 'home',
    nationality: 'IN',
    occupation: '',
    fathersName: '',
    husbandName: '',
    lrNumber: '',
    address: '',
    otherData: '',
  }
  if (patient != null || relatedPerson != null) {
    initialFValues = {
      id: patient?.id ?? '',
      name: getNameOfPatient(patient!) ?? '',
      middleName: getMiddleNameOfPatient(patient!) ?? '',
      lastName: getLastNameOfPatient(patient!) ?? '',
      gender: '',
      phone:
        getTelecomOfPatient(patient!, R4.ContactPointSystemKind._phone) ?? '',
      email:
        getTelecomOfPatient(patient!, R4.ContactPointSystemKind._email) ?? '',

      dob: patient?.birthDate ?? '',
      age: getAgeOfPatient(patient!),
      width: 60,

      maritalStatus: '',
      addressType: 'home',
      nationality: 'IN',
      occupation: '',
      fathersName: '',
      husbandName: '',
      lrNumber: '',
      address: '',
      otherData: '',
    }
  }
  return initialFValues
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  topHeader: {
    color: theme.palette.common.white,
    marginBottom: 5,
  },

  mainPage: {
    maxWidth: 960,
    height: 'auto',
    backgroundColor: '#cdcdcd',
  },
  distance: {
    marginTop: 10,
  },

  large: {
    width: 60,
    height: 60,
  },
  ageLabel: {
    height: 39,
    width: 59,
    borderRadius: 6,
    backgroundColor: '#ffffff',
    marginTop: 16,
    textAlign: 'center',
  },
  overlapping: {
    height: 20,
    display: 'inline-flex',
    align: 'center',
    marginLeft: 5,
    marginTop: 14,
    fontSize: 12,
    fontWeight: 600,
  },
  textLabel: {
    textTransform: 'uppercase',
  },
}))

export const NewPatientMinimal: React.FC<IUnitAdmin> = ({
  children,
  count,
  id,
  submitButtonText,
  resetButtonText,
  preAddedPatient,
  preAddedRelatedPatient,
  onClickToCancel,
  onValidSubmit,
  formLabels = {},
  placeholderLables = {},
  popupSate = false,
}: IUnitAdmin) => {
  const classes = useStyles()
  const initialFValues: any = getInitializeValues(
    preAddedPatient,
    preAddedRelatedPatient
  )
  const [openWebCam, setOpenWebCam] = useState<boolean>(false)
  const [refferedId, setRefferedId] = useState<string>()
  const [isPrimary, setIsPrimary] = useState<boolean>()
  const [relation, setRelation] = useState<R4.ICoding>()
  const [openPopup, setOpenPopup] = useState(popupSate)

  const [dialog, setDialog] = useState<boolean>(false)

  const patientsSearchState = useSelector(
    (state: RootState) => state.patientSearchSlice
  )

  const [valueData, setValueData] = useState('file')
  const [photoType, setPhotoType] = useState('both')

  const [callBackForCapturePhoto, setCallBackForCapturePhoto] = React.useState(
    () => (imageName: any, imageDataType: any, imageUri: any, data?: any) => {}
  )
  const [aspectRatio, setAspectRatio] = useState<number>(1)

  const dispatch = useDispatch()

  function validate(fieldValues = values): boolean | undefined {
    const temp = { ...errors }

    if ('email' in fieldValues)
      temp.email = validateEmail(fieldValues.email, false)
    if ('name' in fieldValues) {
      temp.name = validateName(fieldValues.name, true)
    }
    if ('lastName' in fieldValues) {
      temp.lastName = validateLastName(fieldValues.lastName, true)
    }
    if ('dob' in fieldValues) temp.dob = validateDob(fieldValues.dob, true)
    if ('phone' in fieldValues) {
      temp.phone = validateMobileNumberForAdd(fieldValues.phone, true)
    }
    // if ('photoUri' in fieldValues) {
    //   temp.photoUri = validateProfilePic(fieldValues.photoUri, true)
    // }
    if ('address' in fieldValues)
      temp.address = validateAddress(fieldValues.address, false)

    if ('lrNumber' in fieldValues)
      temp.lrNumber = validateLRNumber(fieldValues.lrNumber, false)

    if ('gender' in fieldValues)
      temp.gender = validateGender(fieldValues.gender, true)
    if ('fathersName' in fieldValues)
      temp.fathersName = validateFathersName(fieldValues.fathersName, false)

    if ('husbandName' in fieldValues)
      temp.husbandName = validateHusbandsName(fieldValues.husbandName, false)
    if ('middleName' in fieldValues)
      temp.middleName = validateMiddleName(fieldValues.middleName, false)

    // if ('fathersName' in fieldValues)
    //   temp.fathersName = validateFathersName(fieldValues.fathersName, true)

    if ('nationality' in fieldValues)
      temp.nationality = validateNationality(fieldValues.nationality, true)

    if ('occupation' in fieldValues) {
      if (fieldValues.occupation === 'other') {
        if ('otherData' in fieldValues)
          temp.otherData = validateOtherData(fieldValues.otherData, true)
      }
    }

    setErrors({
      ...temp,
    })

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')

    return undefined
  }

  function handleSubmit() {
    if (validate()) {
      const pat: R4.IPatient | undefined = getFhirPatientObjectFromForm(values)
      const relatedPat: R4.IRelatedPerson | undefined =
        getFhirRelatedObjectFromForm(values)
      const relatedPatHs: R4.IRelatedPerson | undefined =
        getFhirRelatedObjectHSForm(values.pa)
      const occupation: string | undefined =
        values.occupation === 'other' ? values.otherData : values.occupation
      const { nationality } = values

      if (pat) {
        if (onValidSubmit)
          onValidSubmit(
            pat,
            relatedPat,
            relatedPatHs,
            occupation,
            nationality,
            undefined,
            undefined,
            undefined,
            refferedId,
            relation,
            isPrimary
          )
        /* popupSate = false
        setOpenPopup(popupSate) */
      } else {
      }
    } else {
    }
  }
  const cancel = () => {
    onClickToCancel(false)
    setOpenPopup(false)
  }
  const handleClose = () => {
    onClickToCancel(false)
    setOpenPopup(false)
  }
  const { t } = useTranslation()
  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    handleInputChangeForPhoto,
    uploadPic,
    handleDateChange,
    handleInputChangeForProofs,
    handlePhoneChange,
    handleInputChangeForOccupation,
    handleInputChangeNationality,
  } = UseForm(initialFValues, errorValues, true, validate)

  // eslint-disable-next-line no-restricted-globals
  const isSecure = location.protocol !== 'https:'

  const theme = useTheme()

  const occupations = [
    { id: 'govtOfficer', title: 'Govt. Officer' },
    { id: 'Politician', title: 'Politician' },
    { id: 'Self Employed', title: 'Self Employed' },
    { id: 'businessman', title: 'Businessman' },
    { id: 'Doctor', title: 'Doctor' },
    { id: 'Hospitality service', title: 'Hospitality service' },
    { id: 'Pvt Sector Executive', title: 'Pvt Sector Executive' },
    { id: 'Chartered Accountant', title: 'Chartered Accountant' },
    { id: 'Finance Professional', title: 'Finance Professional' },
    { id: 'Housewife', title: 'Housewife' },
    { id: 'Student', title: 'Student' },
  ].sort((a, b) => (a.title! > b.title! ? 1 : b.title! > a.title! ? -1 : 0))

  useEffect(() => {
    if (validateMobileNumberForAdd(values.phone, true).length === 0) {
      dispatch(searchPatients(values.phone, ''))
    }
  }, [values.phone])

  useEffect(() => {
    if (
      validateMobileNumberForAdd(values.phone, true).length === 0 &&
      dialog === false
    ) {
      if (
        patientsSearchState.resultsAvailable &&
        patientsSearchState.groupDataList
      ) {
        setDialog(true)
      }
    } else {
      dispatch(resetPatientSearchPatient())
    }
  }, [patientsSearchState.patientList, patientsSearchState.resultsAvailable])

  return (
    <Dialog
      open={openPopup}
      maxWidth='md'
      fullWidth
      onClose={handleClose}
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        {placeholderLables?.addPatient ?? 'Add Patient'}
      </DialogTitle>
      <DialogContent dividers={true}>
        {/* <Paper className={classes.mainPage} square={true} variant='outlined'> */}

        {patientsSearchState.searching && (
          <WelloLoadingIndicator message='Searching' />
        )}

        <Form
          onSubmit={() => {
            handleSubmit()
          }}
          title={placeholderLables?.addPatient ?? 'Add Patient'}
        >
          <Box display='flex' flexDirection='column'>
            <Box
              display='flex'
              width='90%'
              paddingLeft={26}
              // paddingLeft={10}
            >
              <Box px={1} flexGrow={1} width='100%'>
                <Controls.WelloInput
                  name='name'
                  required={false}
                  label={formLabels?.nameLabel ?? 'FIRST NAME'}
                  type='text'
                  value={values.name}
                  error={errors.name}
                  placeholder={placeholderLables?.nameLabel ?? 'First Name'}
                  size='small'
                  onChange={handleInputChange}
                  charsAllowed={100}
                />
              </Box>
              <Box px={1} flexGrow={1} width='100%'>
                <Controls.WelloInput
                  name='middleName'
                  required={false}
                  label={formLabels?.mNameLabel ?? 'MIDDLE NAME'}
                  type='text'
                  value={values.middleName}
                  error={errors.middleName}
                  placeholder={placeholderLables?.mNameLabel ?? '(optional)'}
                  // width={235}
                  size='small'
                  onChange={handleInputChange}
                  charsAllowed={100}
                />
              </Box>
              <Box px={1} flexGrow={1} width='100%'>
                <Controls.WelloInput
                  name='lastName'
                  required={false}
                  label={formLabels?.lNameLabel ?? 'LAST NAME'}
                  type='text'
                  size='small'
                  value={values.lastName}
                  error={errors.lastName}
                  placeholder={placeholderLables?.lNameLabel ?? 'Last Name'}
                  // width={235}
                  onChange={handleInputChange}
                  charsAllowed={100}
                />
              </Box>
              <Box px={1}>
                {values.gender.length !== 0 && values.dob.length !== 0 && (
                  <Paper className={classes.ageLabel}>
                    {values.gender === 'male' && (
                      <Typography
                        color='primary'
                        className={classes.overlapping}
                      >
                        <img src={male} alt='' height={15.3} width={5.33} />{' '}
                        &nbsp;
                        {values.age === 0 ? `${values.age}m` : `${values.age}y`}
                      </Typography>
                    )}

                    {values.gender === 'other' && (
                      <Typography
                        color='primary'
                        className={classes.overlapping}
                      >
                        {/* <img
                                src={male}
                                alt=''
                                height={15.3}
                                width={5.33}
                              />{' '} */}
                        &nbsp;
                        {values.age === 0 ? `${values.age}m` : `${values.age}y`}
                      </Typography>
                    )}

                    {values.gender === 'unknown' && (
                      <Typography
                        color='primary'
                        className={classes.overlapping}
                      >
                        &nbsp;
                        {values.age === 0 ? `${values.age}m` : `${values.age}y`}
                      </Typography>
                    )}

                    {values.gender === 'female' && (
                      <Typography
                        color='primary'
                        className={classes.overlapping}
                      >
                        <img src={female} alt='' height={15.3} width={5.33} />{' '}
                        &nbsp;
                        {values.age === 0 ? `${values.age}m` : `${values.age}y`}
                      </Typography>
                    )}
                  </Paper>
                )}
              </Box>
            </Box>

            <Box display='flex' py={1} width='80%'>
              <Box
                paddingLeft={
                  values.gender.length !== 0 && values.dob.length !== 0
                    ? 26
                    : 27
                }
              >
                <Controls.WelloDate
                  name='dob'
                  label={formLabels?.doblabelForKey ?? 'DATE OF BIRTH'}
                  placeholder={placeholderLables?.doblabel ?? 'dd/mm/yyyy'}
                  required={false}
                  value={values.dob}
                  width={285}
                  size='small'
                  error={errors.dob}
                  onChange={handleDateChange}
                />
              </Box>
              <Box paddingLeft={2}>
                <Controls.WelloSelectGender
                  name='gender'
                  required={true}
                  label={formLabels?.genderLabel ?? 'GENDER'}
                  type={placeholderLables?.genderLabel ?? 'Select Gender'}
                  value={values.gender}
                  width={283}
                  onChange={handleInputChange}
                  error={errors.gender}
                />
              </Box>
            </Box>
            <Box display='flex' py={1}>
              <Box
                paddingRight={1}
                paddingLeft={
                  values.gender.length !== 0 && values.dob.length !== 0
                    ? 26
                    : 27
                }
              >
                <PhoneNumber
                  name='phone'
                  label={formLabels?.phoneNumberLabel ?? 'PHONE NUMBER'}
                  placeholder={
                    placeholderLables?.phoneNumberLabel ?? 'Phone Number'
                  }
                  type='tel'
                  size='small'
                  value={values.phone}
                  required={false}
                  width={285}
                  error={errors.phone}
                  onChange={handleInputChange}
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                width={285}
                py={0.3}
                paddingLeft={1}
              >
                <WelloFormItemLabel title='Nationality' />
                <Autocomplete
                  style={{
                    borderRadius: '4px',
                  }}
                  fullWidth
                  size='small'
                  id='free-solo-2-demo'
                  disableClearable
                  getOptionSelected={(option, value) =>
                    option.display! === value.display!
                  }
                  getOptionLabel={(option) => option.display ?? ''}
                  options={nationalityValueSet}
                  defaultValue={preDefinedCountry}
                  onChange={(e, changedValue, reason) => {
                    if (e) {
                      handleInputChangeNationality(
                        changedValue,
                        'nationality',
                        'country',
                        'addressProofTypeCode',
                        'visaIssueCountry'
                      )
                    }
                    // handleInputChangeForOccupation(
                    //   'occupation',
                    //   changedValue.title
                    // )
                  }}
                  autoComplete
                  ChipProps={{
                    deleteIcon: (
                      <ClearOutlined
                        style={{
                          height: '15px',
                          color: 'white',
                        }}
                      />
                    ),
                    style: {
                      backgroundColor: kPrimaryLight,
                      borderRadius: '4px',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder='Select Occupation (Optional)'
                      fullWidth
                      variant='outlined'
                      size='small'
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        style: {
                          maxWidth: '480px',
                          width: '283px',
                        },
                      }}
                    />
                  )}
                />
                {/* <WelloSelectFHIRCoding
                  title={formLabels?.nationalityLabel ?? 'NATIONALITY'}
                  id={`${id}nationality`}
                  duration='Select Nationality'
                  availableCodings={nationalityValueSet}
                  onChanged={(e) => {
                    if (e) {
                      handleInputChangeNationality(
                        e,
                        'nationality',
                        'country',
                        'addressProofTypeCode'
                      )
                    }
                  }}
                  //   onChanged={(val) => {}}
                  textFieldProps={{
                    fullWidth: true,
                    // required: true,
                    variant: 'outlined',
                    size: 'small',
                    // value: values.nationality,
                    style: {
                      maxWidth: '480px',
                      width: '283px',
                    },
                    // onChange: handleInputChange,
                    name: 'nationality',
                    error: errors.nationality,
                    helperText: errors.nationality,

                    id: 'patient_nationality',
                  }}
                  preSelectedCoding={preDefinedCountry}
                /> */}
                {/* <Controls.WelloSelectNationality
                  name='nationality'
                  required={true}
                  label={formLabels?.nationalityLabel ?? 'NATIONALITY'}
                  type={
                    placeholderLables?.nationalityLabel ?? 'Select Nationality'
                  }
                  value={values.nationality}
                  error={errors.nationality}
                  width={283}
                  onChange={handleInputChange}
                /> */}
              </Box>
            </Box>

            <Box display='flex' py={1}>
              <Box
                paddingRight={1}
                paddingLeft={
                  values.gender.length !== 0 && values.dob.length !== 0
                    ? 26
                    : 27
                }
              >
                <Box display='flex' flexDirection='column' width={283}>
                  {/* <WelloFormItemLabel title='Occupation' /> */}
                  <Box display='flex' flexDirection='row' width='100%'>
                    <ValueSetSelectSingle
                      key='occupation_Search'
                      title='Occupation'
                      url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-occupation-vs'
                      id='occupation'
                      fuzzySearch={true}
                      alwaysShowAllOptions={false}
                      disabled={false}
                      onOptionSelected={(selectedKriya) => {
                        // setOccupation(changedValue)
                        if (selectedKriya) {
                          handleInputChangeForOccupation(
                            'occupation',
                            selectedKriya.display ?? ''
                          )
                        }
                      }}
                      helperText={undefined}
                      placeHolder='Select Occupation (Optional)'
                    />
                    {/* <Autocomplete
                      style={{
                        borderRadius: '4px',
                      }}
                      fullWidth
                      size='small'
                      id='free-solo-2-demo'
                      disableClearable
                      getOptionSelected={(option, value) =>
                        option.title === value.title
                      }
                      getOptionLabel={(option) => option.title ?? ''}
                      options={occupations}
                      onChange={(e, changedValue, reason) => {
                        handleInputChangeForOccupation(
                          'occupation',
                          changedValue.title
                        )
                      }}
                      autoComplete
                      ChipProps={{
                        deleteIcon: (
                          <ClearOutlined
                            style={{
                              height: '15px',
                              color: 'white',
                            }}
                          />
                        ),
                        style: {
                          backgroundColor: kPrimaryLight,
                          borderRadius: '4px',
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder='Select Occupation (Optional)'
                          fullWidth
                          variant='outlined'
                          size='small'
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                          }}
                        />
                      )}
                    /> */}
                  </Box>
                </Box>

                {values.occupation === 'other' && (
                  <Box paddingTop={1} width='100%'>
                    <Controls.WelloInput
                      name='otherData'
                      required={false}
                      label='Other Value'
                      type='text'
                      size='small'
                      value={values.otherData}
                      error={errors.otherData}
                      placeholder='Occupation'
                      width={285}
                      onChange={handleInputChange}
                      charsAllowed={50}
                    />
                  </Box>
                )}
              </Box>
              <Box px={1}>
                <WelloTextField
                  title={formLabels?.referenceIdLabel ?? 'REFERENCE ID'}
                  textProps={{
                    required: true,
                    id: 'lrNumber',
                    placeholder:
                      placeholderLables?.referenceIdLabel ?? 'Reference Id',

                    name: 'lrNumber',
                    rows: 3,
                    style: {
                      width: 285,
                    },

                    value: values.lrNumber,
                    onChange: (changeDescription) => {
                      handleInputChange(changeDescription)
                    },
                    error: errors.lrNumber || values.lrNumber.length > 30,
                    helperText: errors.lrNumber,
                  }}
                />
                {/* <Controls.WelloInput
                  name='lrNumber'
                  required={false}
                  label={formLabels?.referenceIdLabel ?? 'REFERENCE ID'}
                  type='text'
                  size='small'
                  value={values.lrNumber}
                  error={errors.lrNumber}
                  placeholder={
                    placeholderLables?.referenceIdLabel ?? 'Reference Id'
                  }
                  width={285}
                  onChange={handleInputChange}
                  charsAllowed={30}
                /> */}
              </Box>
              {/* <Box px={1} py={1.7}>
                <Controls.WelloInput
                  name='lrNumber'
                  required={false}
                  label={formLabels?.referenceIdLabel ?? 'REFERENCE ID'}
                  type='text'
                  size='small'
                  value={values.lrNumber}
                  error={errors.lrNumber}
                  placeholder={
                    placeholderLables?.referenceIdLabel ?? 'Reference Id'
                  }
                  width={283}
                  onChange={handleInputChange}
                  charsAllowed={30}
                />
              </Box> */}
            </Box>

            <Box display='flex' py={1}>
              <Box
                paddingRight={1}
                paddingLeft={
                  values.gender.length !== 0 && values.dob.length !== 0
                    ? 26
                    : 27
                }
              >
                <WelloSelectFormForPatient
                  name='maritalStatus'
                  required={true}
                  label='Marital Status'
                  type={
                    placeholderLables?.maritalStatusLabel ??
                    'Select Marital Status'
                  }
                  options={martialList()}
                  value={values.maritalStatus}
                  error={errors.maritalStatus}
                  width={285}
                  onChange={handleInputChange}
                />
              </Box>
              <Box paddingLeft={1}>
                <WelloEmailInput
                  name='email'
                  label={formLabels?.emailLabel ?? 'EMAIL ADDRESS'}
                  placeholder={placeholderLables?.emailLabel ?? 'Email Address'}
                  type='email'
                  value={values.email}
                  error={errors.email}
                  required={true}
                  size='small'
                  width={283}
                  onChange={handleInputChange}
                />
              </Box>
            </Box>

            <Box display='flex' py={1}>
              <Box
                paddingRight={1}
                paddingLeft={
                  values.gender.length !== 0 && values.dob.length !== 0
                    ? 26
                    : 27
                }
              >
                <Controls.WelloTextAreaAddress
                  name='address'
                  required={false}
                  label={formLabels?.addressLabel ?? 'ADDRESS'}
                  type='text'
                  placeholder={
                    placeholderLables?.addressLabel ?? 'Your Home Address'
                  }
                  value={values.address}
                  onChange={handleInputChange}
                  error={errors.address}
                  width={480}
                  size='small'
                  charsAllowed={100}
                />
              </Box>

              <Box paddingLeft={1}>
                <Controls.WelloType
                  name='addressType'
                  required={true}
                  label={formLabels?.addressTypeLabel ?? 'TYPE'}
                  type={placeholderLables?.addressTypeLabel ?? 'Type'}
                  value={values.addressType}
                  onChange={handleInputChange}
                  width={90}
                />
              </Box>
            </Box>
            <Box display='flex' py={1}>
              <Box
                paddingRight={1}
                paddingLeft={
                  values.gender.length !== 0 && values.dob.length !== 0
                    ? 26
                    : 27
                }
              >
                <Controls.WelloInput
                  name='fathersName'
                  required={false}
                  size='small'
                  label={formLabels?.fatherNameLabel ?? `FATHER'S NAME `}
                  type='text'
                  value={values.fathersName}
                  error={errors.fathersName}
                  placeholder={
                    placeholderLables?.fatherNameLabel ??
                    'First, Middle and Last Name'
                  }
                  width={480}
                  onChange={handleInputChange}
                  charsAllowed={300}
                />
              </Box>
            </Box>
            {values.gender === 'female' &&
              values.maritalStatus === 'M' &&
              values.age >= 18 && (
                <Box display='flex' py={1}>
                  <Box
                    paddingRight={1}
                    paddingLeft={
                      values.gender.length !== 0 && values.dob.length !== 0
                        ? 26
                        : 27
                    }
                  >
                    <Controls.WelloInput
                      name='husbandName'
                      required={false}
                      size='small'
                      label={formLabels?.husbandNameLabel ?? `Husband’s Name`}
                      type='text'
                      value={values.husbandName}
                      error={errors.husbandName}
                      placeholder={
                        placeholderLables?.husbandNameLabel ??
                        'First, Middle and Last Name'
                      }
                      width={480}
                      onChange={handleInputChange}
                      charsAllowed={300}
                    />
                  </Box>
                </Box>
              )}
          </Box>
          {dialog && (
            <ChoosePatient
              id='1'
              open={dialog}
              patients={patientsSearchState.groupDataList ?? []}
              onClose={() => {
                setDialog(false)
              }}
              onChoose={(
                patientId: string,
                relationShip: R4.ICoding,
                checked: boolean
              ) => {
                setRelation(relationShip)
                setRefferedId(patientId)
                setIsPrimary(checked)
              }}
            />
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <WelloButton
          type='button'
          text={resetButtonText ?? 'Cancel (esc)'}
          size='small'
          variant='outlined'
          disabled={false}
          backgroundColor='#fefefe'
          color='#4543d9'
          onClick={cancel}
          id='cancel'
        />
        <WelloButton
          type='button'
          text={submitButtonText ?? 'Add Patient'}
          size='small'
          variant='outlined'
          disabled={
            patientsSearchState.groupDataList
              ? patientsSearchState.groupDataList.length > 0 &&
                refferedId === undefined
              : false
          }
          backgroundColor={
            patientsSearchState.groupDataList
              ? patientsSearchState.groupDataList.length > 0 &&
                refferedId === undefined
                ? 'grey'
                : '#4543d9'
              : '#4543d9'
          }
          //   backgroundColor={
          //     patientsSearchState.patientList ??
          //     ([].length > 0 && refferedId === undefined)
          //       ? 'grey'
          //       : '#4543d9'
          //   }
          color='#fefefe'
          onClick={() => {
            handleSubmit()
          }}
          id='add_patient'
        />
      </DialogActions>
    </Dialog>
  )
}
