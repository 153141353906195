/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-void */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  Badge,
  IconButton,
} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import React, { FormEvent, useEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

import {
  selectedProductIdState,
  viewState,
} from 'models/medicine-delivery/product-states'
import { getProductDetails } from 'utils/medicineHelper/product-apis'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { PatientSearchByText } from 'views/components/patients/patientSearchByText'
import { PatientTileSplit } from 'views/components/patients/patientTileSplit'
import { WelloSelectUnit } from 'views/components/LeftMenu/welloSelectUnit'
import { OrderTab } from 'utils/constants'
import { AddressTile } from 'views/components/patients/addressTile'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { AppBarTabs } from 'views/components/appBars/AppBarTabs'
import { ConfirmationDialogProps } from 'views/components/common/deleteConfirmationDialog'
import { WelloSelectUnitForMedicine } from 'views/components/LeftMenu/WelloSelectUnitForMedicine'
import { Cart } from 'models/medicine-delivery/cart/cart-models'
import {
  cartDoctorIdState,
  cartOrderForCachedState,
  cartPatientIdState,
  cartPatientObjectState,
  cartState,
  orderForObject,
} from '../../../models/medicine-delivery/cart/cart-states'

interface Props {
  patientId: string
  doctorId: string
  patient?: R4.IPatient
  doctor?: R4.IPractitioner
  doctorRole?: R4.IPractitionerRole
  onPatientCallBack?: (patient: R4.IPatient) => void
  isSoapNotes?: boolean
}

export const CartOrderFor: React.FC<Props> = ({
  patientId,
  doctorId,
  patient,
  doctor,
  doctorRole,
  onPatientCallBack,
  isSoapNotes,
}: Props) => {
  const [orderFor, setOrderFor] = useRecoilState(cartOrderForCachedState)
  const [medicineTitle, setMedicineTitle] = useState('')
  const setSelectedProductId = useSetRecoilState(selectedProductIdState)
  const setView = useSetRecoilState(viewState)
  const setCartPatientId = useSetRecoilState(cartPatientIdState)
  const [cartPatient, setCartPatient] = useRecoilState(cartPatientObjectState)
  const [orderForData, setOrderForData] = useRecoilState(orderForObject)
  const [cart, setCart] = useRecoilState<any>(cartState)

  const setCartDoctorId = useSetRecoilState(cartDoctorIdState)
  const [selectedPatient, setSelectedPatient] =
    useState<R4.IPatient | undefined>(patient)

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  /* When this component renders or change in the patientId or doctorId
   * The useEffect will set the recoils which will be read in other components.
   */
  useEffect(() => {
    if (patientId.trim() === '' && doctorId.trim() === '') {
      return
    }
    setCartPatientId(patientId)
  }, [patientId])

  const [isPatientEditMode, setIsPatientEditMode] = useState<boolean>(false)

  const handleChangeTab = (newSelectedTab: string) => {
    setView('cart')
  }

  /* On searching the medicine, will check the server if medicine exists, if Yes, it will update recoil
       which will renender the product-details.tsx */
  const onSearch = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const title = medicineTitle.trim()
    if (title === '') {
      return
    }
    const response = await getProductDetails(title)
    if (!response) {
      alert('Medicine does not exists')
      return
    }
    setMedicineTitle('')
    setSelectedProductId(response.title)
    setView('productDetails')
  }
  return (
    <Box width='100%' display='flex' flexDirection='row'>
      <Box width='80%'>
        <Grid container>
          <Grid item xs={12}>
            <Box width='100%' display='flex' flexDirection='row'>
              {isSoapNotes === undefined && (
                <Box width='15%' paddingTop={3}>
                  <WelloSelectUnitForMedicine
                    title=''
                    duration='Select'
                    availableCodings={OrderTab}
                    onChanged={(type) => {
                      setCart({ items: [], delivery: 0 } as Cart)
                      if (type.code && type.code === 'clinic') {
                        setOrderFor('clinic')

                        setCartDoctorId(doctorId)
                        setSelectedPatient(undefined)
                        setCartPatient(undefined)
                        setCartPatientId(undefined)

                        setOrderForData(type)
                      } else {
                        setOrderForData(type)

                        setOrderFor('patient')
                      }
                      // setOrderFor(type.code ?? 'clinic')
                    }}
                    textFieldProps={{
                      size: 'small',
                      fullWidth: true,
                    }}
                    preSelectedCoding={orderForData}
                  />
                </Box>
              )}
              {orderFor === 'patient' && (
                <Box width='40%' display='flex' flexDirection='column' px={2}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                  >
                    <Box py={0.6} px={0.25} display='flex' flexDirection='row'>
                      <Typography variant='subtitle2'>
                        {cartPatient ? 'Selected Patient' : 'Select Patient'}
                      </Typography>
                    </Box>

                    {cartPatient && (
                      <Button
                        id='but_lab_ord_cre_modify_pat'
                        variant='text'
                        color='primary'
                        size='small'
                        style={{ paddingTop: 0 }}
                        onClick={(e) => {
                          setIsPatientEditMode(false)
                          setSelectedPatient(undefined)
                          setCartPatient(undefined)
                        }}
                      >
                        Modify
                      </Button>
                    )}
                  </Box>
                  <Box display='flex' flexDirection='column'>
                    <Box display='flex' flexDirection='row'>
                      {cartPatient === undefined && (
                        <PatientSearchByText
                          id={2}
                          onNewPatientButtonClicked={() => {}}
                          onPatientSelected={(patientData) => {
                            setSelectedPatient(patientData)
                            setCartPatient(patientData)

                            //   setIsPatientEditMode(true)
                            if (onPatientCallBack)
                              onPatientCallBack(patientData)
                          }}
                          isMedicine={true}
                        />
                      )}
                    </Box>
                    {orderFor === 'patient' && cartPatient === undefined && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        paddingLeft={0.3}
                        py={0.5}
                      >
                        <Typography variant='subtitle2' color='error'>
                          Select a patient
                        </Typography>
                      </Box>
                    )}

                    {cartPatient && (
                      <Box display='flex' flexDirection='column' width='100%'>
                        <PatientTileSplit patient={cartPatient} />
                      </Box>
                    )}
                  </Box>
                </Box>
              )}

              {orderFor === 'clinic' && (
                <Box
                  width='30%'
                  display='flex'
                  flexDirection='column'
                  px={2}
                  paddingTop={3}
                >
                  {getCurrentUserUnitDetails().address &&
                    getCurrentUserUnitDetails().address!.length > 0 && (
                      <AddressTile
                        addresses={getCurrentUserUnitDetails().address![0]}
                        isMedicine={true}
                      />
                    )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        width='20%'
        paddingTop={3}
        display='flex'
        flexDirection='row'
        justifyContent='flex-end'
        paddingRight={1}
      >
        <Box
          width='30%'
          display='flex'
          flexDirection='column'
          onClick={() => {
            setView('cart')
            setSelectedProductId(undefined)
          }}
          style={{ cursor: 'pointer' }}
        >
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='flex-start'
            width='50%'
            py={0.2}
          >
            <Tooltip title='Cart'>
              <IconButton
                aria-label='btn_ord_reschedule'
                color='primary'
                style={{ padding: 0 }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/Medicine_Cart.ico`}
                  alt='ipd'
                  height='80%'
                  width='70%'
                />
              </IconButton>
            </Tooltip>
          </Box>
          <Box paddingLeft={0.4} height='100%'>
            <Box
              width='50%'
              style={{ backgroundColor: 'gray' }}
              height='25%'
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
            >
              {cart !== undefined && (
                <Typography variant='subtitle2' style={{ color: 'white' }}>
                  {cart && cart.items.length > 0 ? cart.items.length : 0}
                </Typography>
              )}
              {cart === undefined && (
                <Typography variant='subtitle2' style={{ color: 'white' }}>
                  0
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
