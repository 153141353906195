/* eslint-disable react-hooks/exhaustive-deps */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import { ToggleButtonGroup } from '@material-ui/lab'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'redux/rootReducer'
import { requestForSubscriptionInterests } from 'redux/subscription/subscriptionInterestListForProvider/subscriptionInterestListForProviderSearchSlice'
import { useWindowSize } from 'rooks'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { CPSInterestListTable } from 'views/components/administration/susbcriptions/cpsInterestTable'
import '../../../App.css'
import {
  AcceptForSubscriptionPopUp,
  AcceptForSubscriptionProps,
} from './acceptUserForSusbcriptionPopUp'

const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  grouped: {
    padding: theme.spacing(0.5),

    border: 'none',
    '&.Mui-selected': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
    },
  },
}))(ToggleButtonGroup)

export const CarePlanSubscriptionsLandingPage: React.FC = () => {
  const subscriptionInterestListForProviderSearchSlice = useSelector(
    (state: RootState) => state.subscriptionInterestListForProviderSearchSlice
  )

  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(new Date())

  const [name, setName] = useState<string>('')
  const [acceptSubscription, setAcceptSubscription] =
    useState<AcceptForSubscriptionProps>({
      open: false,
    })

  const [selectedOrder, setSelectedOrder] = useState<any>()

  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  function requestAppointmentForPolling() {
    if (
      acceptSubscription.open === false &&
      subscriptionInterestListForProviderSearchSlice.availableOrders
    ) {
      //   dispatch(resetAppointmentState())
      dispatch(
        requestForSubscriptionInterests(
          name,
          subscriptionInterestListForProviderSearchSlice.count,
          subscriptionInterestListForProviderSearchSlice.availableOrders,
          subscriptionInterestListForProviderSearchSlice.nextPageUrl
        )
      )
    }
  }

  useEffect(() => {
    const intervalId = setInterval(requestAppointmentForPolling, 10000)
    return () => clearInterval(intervalId)
  }, [requestAppointmentForPolling])

  useEffect(() => {
    // if (loggedInUnitType === 'Clinic') document.title = 'WelloClinic'
    dispatch(requestForSubscriptionInterests())
  }, [])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        borderRadius: 8,
        backgroundColor: '#ececec',
        paddingTop: '16px',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            px={2}
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            <Box flexDirection='row' width='100%' display='flex' p={1}>
              <TextField
                variant='outlined'
                onChange={(event) => {
                  dispatch(requestForSubscriptionInterests(event.target.value))

                  setName(event.target.value)
                }}
                size='small'
                autoFocus={true}
                placeholder='Care Plan Member'
                value={name}
                InputProps={{
                  startAdornment: (
                    <IconButton size='small'>
                      <img
                        src={`${process.env.PUBLIC_URL}/search.png`}
                        alt='s'
                        style={{ paddingLeft: 1 }}
                      />
                    </IconButton>
                  ),
                  endAdornment: name && (
                    <IconButton
                      size='small'
                      aria-label='toggle password visibility'
                      onClick={() => {
                        setName('')
                        dispatch(requestForSubscriptionInterests())
                      }}
                    >
                      <CancelRoundedIcon />
                    </IconButton>
                  ),
                }}
              />

              {subscriptionInterestListForProviderSearchSlice.searchingAppointments && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                  px={1}
                >
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='Start'
              alignContent='center'
              overflow='auto'
              style={{
                backgroundColor: '#00000005',
              }}
            >
              {subscriptionInterestListForProviderSearchSlice.noResultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    align='center'
                  >
                    No interest details available
                  </Typography>
                </Box>
              )}

              {subscriptionInterestListForProviderSearchSlice.errorWhileSearchingOrders && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography variant='subtitle1' color='error' align='center'>
                    Error while searching care plans
                  </Typography>
                </Box>
              )}

              {subscriptionInterestListForProviderSearchSlice.availableOrders && (
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height='100%'
                  overflow='auto'
                >
                  <Box
                    display='flex'
                    flexGrow={1}
                    overflow='auto'
                    height='100%'
                  >
                    <CPSInterestListTable
                      medicationsList={
                        subscriptionInterestListForProviderSearchSlice.availableOrders
                      }
                      onOptForOfferClick={(interest: any) => {
                        setAcceptSubscription({
                          open: true,

                          interest,
                          id: `${interest.id}_susbcription`,
                          onCancelActionClicked: () => {
                            setAcceptSubscription({
                              open: false,
                            })
                          },
                          onSuccessfulAction: () => {
                            setAcceptSubscription({
                              open: false,
                            })
                            dispatch(requestForSubscriptionInterests())
                          },
                        })
                      }}
                    />
                  </Box>

                  {selectedOrder && (
                    <Box display='flex' flexGrow={4} overflow='auto' />
                  )}
                </Box>
              )}
            </Box>
            <Box />
          </Box>
          <AcceptForSubscriptionPopUp {...acceptSubscription} />
        </Box>
      </Paper>
    </div>
  )
}
