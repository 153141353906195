import { Box, Typography } from '@material-ui/core'
import _ from 'lodash'
import { CarePlan } from 'models/carePlan'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { getLabRequestsOfAppointment } from 'redux/consultation/lab_diagnostics_requests_list/labDiagnosticsListSlice'
import { getMedicationsOfAppointment } from 'redux/consultation/medicationsListSlice/medicationsListSlice'
import { getWellnessServiceRequestsOfAppointment } from 'redux/consultation/wellness_referrals_list/wellnessReferralsListSlice'
import { fetchFollowUpAppointments } from 'redux/fhirMedicalResources/followUpAppointmentFetcher/followUpAppointmentSearchSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { CreateAppointmentHandler } from 'views/components/appointments/createAppointmentManager'
import { AyurVedaMedicationsList } from 'views/components/ipdconusltation/assessment/ayurvedaMediactionLIst'
import { ImagingDiagnosticsListIPD } from 'views/components/ipdconusltation/plan/ImagingReferralList'
import { CarePlanInstructionListIPD } from 'views/components/ipdconusltation/plan/instructionsListIPD'
import { LabReferralList } from 'views/components/ipdconusltation/plan/labRefeeralList'
import { AddMedicationsHandler } from '../assessment/addMedication'
import { AddWellnessReferrals } from '../assessment/addWellnessReferrals'
import { MedicationsList } from '../assessment/medicationsList'
import { AddDiagnosticsTests } from './add_diagnostic_orders'
import { AddPathyaApathyaProps } from './ayurveda/addPathyaAndApathya'
import { KriyasListForEncounterIPD } from './ayurveda/kriyasForEncounterIPD'
import { PathyaApathyaListForEncounterForIPD } from './ayurveda/pathyaApathyaListIPD'

import { CarePlanInstructionList } from './instructionsList'
import { LabDiagnosticRequestsList } from './labDiagnosticList'
import { NextStepPlan } from './nextStepPlan'
import { NextStepMultipleSubPlan } from './nextStepPlanMultipleSubPlan'
import { NextStepMultipleSubPlanSplit } from './nextStepsMultipleSubPlanSplit'
import { PatientFolloWUpAppointments } from './pateintFollowUpAppointments'
import { ReferralRequestList } from './referrals_list'
import { SpecialistReferralList } from './specialistRequestList'
import { NextSplit } from './splitPlan'
import { NextSplitAyurveda } from './splitPlanAyurVeda'
import { WellnessReferralsListIPD } from './wellnessReferralListIPD'
import { WellnessReferralsList } from './wellnessRefferalsList'

interface NextStepsOfConsultationProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  split: boolean
}

export const PlanSplitAyurveda: React.FC<NextStepsOfConsultationProps> = ({
  fhirAppointmentDetails,
  split,
}: NextStepsOfConsultationProps) => {
  const { t } = useTranslation(['common', 'mediction'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const [showCohorts, setShowCohorts] = useState<boolean>(false)
  const plans: CarePlan[] = [
    {
      planName: 'Hypertension Care Plan',
      planDescription: '',
    },
  ]
  const [openAddMedicationDialogue, setOpenAddMedicationDialogue] =
    useState<boolean>(false)
  const [openAddWellReferrals, setOpenAddWellReferrals] =
    useState<boolean>(false)
  const [openAddDiagnosticDetails, setOpenAddDiagnosticDetails] =
    useState<boolean>(false)
  const dispatch = useDispatch()
  const [openCreateAppointmentPopup, setOpenCreateAppointmentPopup] =
    useState(false)
  const [kriyaListState, setKriyaListState] = useState<string>(
    _.random(0, 10000).toString()
  )

  const [addPathyaApathyProps, setAddPathyaApathyProps] =
    useState<AddPathyaApathyaProps>({
      encounterReference: {
        reference: `Encounter/${fhirAppointmentDetails.mainEncounter!.id}`,
      },
      patientReference: {
        reference: `Patient/${fhirAppointmentDetails.patient.id}`,
      },
      onClose: () => {},
      onLabDiagnosticsAdded: () => {},
      open: false,
    })

  const [aharaUpdateState, setAharaUpdateState] = useState<string>(
    _.random(0, 10000).toString()
  )
  const [viharaUpdateState, setViharaUpdateState] = useState<string>(
    _.random(0, 10000).toString()
  )

  return (
    <Box display='flex' flexDirection='row' width='100%'>
      <Box display='flex' flexDirection='column' width='100%'>
        <Box
          display='flex'
          flexDirection='row'
          padding={0.5}
          flexGrow
          width='100%'
        >
          <NextSplitAyurveda
            planName={`Oushadhi (${t('labelMedication:resourceName')})`}
            subString=''
            onClicked={() => {
              if (split === undefined) {
                setOpenAddMedicationDialogue(true)
              }
            }}
            bottomWidget={
              <AyurVedaMedicationsList
                fhirAppointmentDetails={fhirAppointmentDetails}
                split={split}
              />
            }
          />
        </Box>
        <Box display='flex' flexDirection='row' padding={0.5} width='100%'>
          <NextSplitAyurveda
            planName={t('labelCommon:lab_test')}
            subString=''
            bottomWidget={
              <LabReferralList
                fhirAppointmentDetails={fhirAppointmentDetails}
                split={split}
              />
            }
            onClicked={() => {
              //   setOpenAddDiagnosticDetails(true)
            }}
          />
        </Box>
        <Box display='flex' flexDirection='row' padding={0.5}>
          <NextSplitAyurveda
            planName='Imaging Studies'
            subString=''
            bottomWidget={
              <ImagingDiagnosticsListIPD
                fhirAppointmentDetails={fhirAppointmentDetails}
                split={split}
              />
            }
            onClicked={() => {
              // setOpenAddImagingDiagnosticDetails(true)
            }}
          />
        </Box>
        {/*  <Box display='flex' flexDirection='row' padding={0.5}>
              <NextStepPlan
                planName='Referral Appointment'
                subString=''
                onClicked={() => {}}
                bottomWidget={
                  <ReferralRequestList
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                }
              />
            </Box> */}
        <Box display='flex' flexDirection='row' padding={0.5}>
          <NextSplitAyurveda
            planName='Wellness Referrals'
            subString=''
            onClicked={() => {
              if (split === false) {
                setOpenAddWellReferrals(true)
              }
            }}
            bottomWidget={
              <WellnessReferralsListIPD
                fhirAppointmentDetails={fhirAppointmentDetails}
              />
            }
          />
        </Box>

        <Box display='flex' flexDirection='row' padding={0.5}>
          <NextSplitAyurveda
            planName='Instructions'
            subString=''
            bottomWidget={
              <CarePlanInstructionListIPD
                fhirAppointmentDetails={fhirAppointmentDetails}
              />
            }
          />
        </Box>

        <Box display='flex' flexDirection='row' padding={0.5} width='100%'>
          <NextStepMultipleSubPlanSplit
            planName='Pathya Apathya'
            subPlans={[
              {
                subPlanName: 'Ahara',

                bottomWidget: (
                  <PathyaApathyaListForEncounterForIPD
                    fhirAppointmentDetails={fhirAppointmentDetails}
                    pathyaApathyaTypeCode={{
                      system: 'http://www.who.int/ita',
                      code: 'ITA-8.1.2',
                      display: 'Ahara',
                    }}
                    updateList={aharaUpdateState}
                    split={split}
                  />
                ),
              },
              {
                subPlanName: 'Vihara',

                bottomWidget: (
                  <PathyaApathyaListForEncounterForIPD
                    fhirAppointmentDetails={fhirAppointmentDetails}
                    pathyaApathyaTypeCode={{
                      system: 'http://www.who.int/ita',
                      code: 'ITA-10.2.14',
                      display: 'Vihara',
                    }}
                    updateList={viharaUpdateState}
                  />
                ),
              },
            ]}
          />
        </Box>
      </Box>
    </Box>
  )
}
