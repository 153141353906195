import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getInvoicePdf } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { DocumentDownLoadStatus } from './documentDownloadStatus'

const initialState: DocumentDownLoadStatus = {
  downloading: false,
  downloadingSuccess: false,
  error: false,
  errorMessage: '',
}

const downLoadInvoiceSlice = createSlice({
  name: 'downloadInvoice',
  initialState,
  reducers: {
    downlodingDocument(state, action: PayloadAction<DocumentDownLoadStatus>) {
      state.downloading = action.payload.downloading
      state.downloadingSuccess = action.payload.downloadingSuccess
      state.error = action.payload.error
    },

    documentDownloaded(state, action: PayloadAction<DocumentDownLoadStatus>) {
      state.downloading = action.payload.downloading
      state.downloadingSuccess = action.payload.downloadingSuccess
      state.error = action.payload.error
    },

    errorInDownloadingDocument(
      state,
      action: PayloadAction<DocumentDownLoadStatus>
    ) {
      state.downloading = action.payload.downloading
      state.downloadingSuccess = action.payload.downloadingSuccess
      state.error = action.payload.error
    },

    resetDownload(state, action: PayloadAction<DocumentDownLoadStatus>) {
      state.downloading = action.payload.downloading
      state.downloadingSuccess = action.payload.downloadingSuccess
      state.error = action.payload.error
    },
  },
})

export const downloadingDocument =
  (type: string, orderId: string, paymentReconcillationId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingDocumentDownloadState: DocumentDownLoadStatus = {
      downloading: true,
      downloadingSuccess: false,
      error: false,
    }
    dispatch(
      downLoadInvoiceSlice.actions.downlodingDocument(
        addingDocumentDownloadState
      )
    )

    try {
      const fhirApi: FHIRApiClient = new FHIRApiClient()
      const requestBody: R4.IParameters = getInvoicePdf(
        type,
        orderId,
        paymentReconcillationId
      )
      const response: any = await fhirApi.doCreateFHIRResourceRequest(
        `$generate-invoice`,
        requestBody
      )
      let data: string = ''
      if (response.data.parameter)
        data = response.data.parameter[0].valueString ?? ''
      const byteCharacters = atob(data)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const file = new Blob([byteArray], { type: 'application/pdf;base64' })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
      const documentDownloadedState: DocumentDownLoadStatus = {
        downloading: false,
        downloadingSuccess: true,
        error: false,
      }
      dispatch(
        downLoadInvoiceSlice.actions.documentDownloaded(documentDownloadedState)
      )
      return
    } catch (error) {
      const errorDocuploadedState: DocumentDownLoadStatus = {
        downloading: false,
        downloadingSuccess: false,
        error: true,
        errorMessage: 'Error',
      }
      dispatch(
        downLoadInvoiceSlice.actions.errorInDownloadingDocument(
          errorDocuploadedState
        )
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(downLoadInvoiceSlice.actions.resetDownload(initialState))
}

export default downLoadInvoiceSlice.reducer
