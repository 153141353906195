/* eslint-disable no-lone-blocks */
/* eslint-disable guard-for-in */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { PractitionerWithRate } from 'models/practitionerWithRate'
import {
  getExtensionValueOfNumber,
  getFirstNameFromHumanName,
  getLastNameFromHumanName,
  getNameFromHumanName,
  getProfilePicPractitioner,
  getRoleDetailsFromPractRole,
  getSpecializationsList,
  getTotalExperience,
} from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { getSlotAmountForAppointment } from './fhirSlotHelper'

export const getPractitionersWithRoleObject = (
  inputBundle: R4.IBundle
): PractitionerWithRate[] | undefined => {
  logger.info(
    '-------------------Inside distillation function------------------------'
  )
  const practListWithRate: PractitionerWithRate[] = []
  const practitionerRoles: any = {}
  const practitioners: Map<string, R4.IPractitioner> = new Map<
    string,
    R4.IPractitioner
  >()

  const location: any = {}
  const healthService: any = {}
  const charDef: R4.IChargeItemDefinition[] = []
  const inPersonRate: any = {}
  const videoRate: any = {}
  const phoneRate: any = {}

  if (inputBundle.total) {
    if (inputBundle.total > 0) {
      if (inputBundle.entry) {
        const entries: R4.IBundle_Entry[] = inputBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break
                case 'Practitioner':
                  practitioners.set(
                    value.resource.id,
                    value.resource as R4.IPractitioner
                  )
                  logger.info('Practitioners', practitioners)
                  break
                case 'Location':
                  location[value.resource.id] = value.resource as R4.ILocation
                  break
                case 'HealthcareService':
                  healthService[value.resource.id] =
                    value.resource as R4.IHealthcareService
                  break
                case 'ChargeItemDefinition':
                  if (value.resource.url) {
                    const data = value.resource.url.split(
                      'http://wellopathy.com/ChargeItemDefinition/'
                    )[1]
                    if (data === 'in-person') {
                      inPersonRate[value.resource.id] =
                        value.resource as R4.IChargeItemDefinition
                    }
                    if (data === 'video') {
                      videoRate[value.resource.id] =
                        value.resource as R4.IChargeItemDefinition
                    }
                    if (data === 'phone') {
                      phoneRate[value.resource.id] =
                        value.resource as R4.IChargeItemDefinition
                    }
                    charDef.push(value.resource as R4.IChargeItemDefinition)
                  }

                  break

                default:
                  break
              }
            }
          }
        })
        logger.info(
          '-------------------Inside distillation function middle------------------------'
        )

        if (practitionerRoles) {
          for (const key in practitionerRoles) {
            if (key) {
              let locationShow: boolean = false
              const chargeItemDef: R4.IChargeItemDefinition[] = []
              const currentRole: R4.IPractitionerRole = practitionerRoles[key]
              let currentLoc: R4.ILocation = location[0]
              const practId: string | undefined =
                currentRole.practitioner?.reference?.split('/')[1]
              const currentPract: R4.IPractitioner | undefined =
                practitioners.get(practId ?? '')
              for (const key1 in location) {
                if (key1) {
                  const loc: R4.ILocation = location[key1] as R4.ILocation
                  if (loc.description === 'PartnerLab') {
                    locationShow = true
                    currentLoc = loc
                  }
                }
              }
              for (const key2 in healthService) {
                {
                  if (key2) {
                    const service: R4.IHealthcareService = healthService[key2]
                    if (currentRole.healthcareService) {
                      const practService =
                        currentRole.healthcareService[0].reference?.split(
                          '/'
                        )[1]
                      if (service.id === practService) {
                        if (service.extension) {
                          for (let i = 0; i < service.extension.length; i++) {
                            const serviceDef = service.extension[i]
                            if (serviceDef) {
                              const ref =
                                serviceDef.valueReference?.reference?.split(
                                  '/'
                                )[1]
                              for (let k = 0; k < charDef.length; k++) {
                                if (charDef[k].id === ref) {
                                  chargeItemDef.push(charDef[k])
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              const role = getRoleDetailsFromPractRole(currentRole)

              let statusData: string = currentPract?.meta?.tag
                ? 'Active'
                : 'Inactive'
              if (role === 'doctor') {
                if (currentRole.healthcareService) {
                  statusData = 'Active'
                } else {
                  statusData = 'Inactive'
                }
              }

              if (statusData === 'Active' && currentRole.active === true) {
                statusData = 'Active'
              } else if (
                statusData === 'Inactive' &&
                currentRole.active === true
              ) {
                statusData = 'Inactive'
              } else {
                statusData = 'Deactivate'
              }
              if (statusData === 'Active') {
                practListWithRate.push({
                  id: practId ?? '',
                  practRoleId: currentRole.id ?? '',
                  name: getNameFromHumanName(currentPract?.name ?? []),
                  fullName: `${getFirstNameFromHumanName(
                    currentPract?.name ?? []
                  )} ${getLastNameFromHumanName(currentPract?.name ?? [])}`,
                  department: getSpecializationsList(
                    currentRole.specialty ?? []
                  ),
                  inPersonRate:
                    chargeItemDef.length > 0
                      ? getSlotAmountForAppointment(chargeItemDef[0])
                      : 0,
                  phoneRate:
                    chargeItemDef.length > 0
                      ? getSlotAmountForAppointment(chargeItemDef[2])
                      : 0,
                  status: statusData,
                  followUp:
                    chargeItemDef.length > 0
                      ? getExtensionValueOfNumber(
                          chargeItemDef[0].extension ?? [],
                          'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext'
                        )
                      : 0,
                  videoRate:
                    chargeItemDef.length > 0
                      ? getSlotAmountForAppointment(chargeItemDef[1])
                      : 0,

                  roleObject: currentRole,
                  practitionerObject: currentPract,
                  locationObject:
                    locationShow === true ? currentLoc : undefined,
                  chargeItemDef,
                  inPerson:
                    chargeItemDef.length > 0 ? chargeItemDef[0] : undefined,
                  phone:
                    chargeItemDef.length > 0 ? chargeItemDef[1] : undefined,
                  video:
                    chargeItemDef.length > 0 ? chargeItemDef[2] : undefined,
                  profilePic: getProfilePicPractitioner(currentPract),
                  totalExp: getTotalExperience(
                    currentPract?.qualification ?? []
                  )
                    .replace('(', '')
                    .replace(')', ''),
                })
              }
            }
          }

          return practListWithRate
        }
      }
    }
  }
  logger.info(
    '-------------------Inside distillation function error------------------------'
  )
  return undefined
}
