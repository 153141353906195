import {
  Box,
  Button,
  CircularProgress,
  ListItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import {} from 'models/chiefComplaintData'
import { UmlResponse } from 'models/umlResponse'
import React, { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import {
  resetState,
  searchConditions,
} from 'redux/umls/condition/conditionSearchSlice'
import { logger } from 'utils/logger'

interface LabOfferingSelectorProps {
  onSelectionChanges?: (selectedValues: UmlResponse[]) => void
  onDelete?: (selectedValue: UmlResponse) => void
  doctorListId?: string
  preSelectedComplaints?: UmlResponse[]
  preSelectAll?: boolean
  disabled: boolean
  selectOther: boolean
}

export const ConditionMasterSelector: React.FC<LabOfferingSelectorProps> = ({
  onSelectionChanges,
  onDelete,
  doctorListId,
  preSelectedComplaints,
  preSelectAll = false,
  disabled,
  selectOther,
}: LabOfferingSelectorProps) => {
  const conditionSearchSlice = useSelector(
    (state: RootState) => state.conditionSearchSlice
  )
  const dispatch = useDispatch()
  const { t } = useTranslation(['common'])
  const [text, setText] = useState<string>('')
  const [inputValueData, setInputValueData] = React.useState('')
  //   useEffect(() => {
  //     dispatch(searchLabOfferings())
  //   }, [dispatch])
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching surgeryList list</div>
          <pre>{error.message}</pre>
          <Button
            onClick={() => {
              dispatch(
                searchConditions(text, selectOther, preSelectedComplaints ?? [])
              )
            }}
          >
            Try again
          </Button>
        </div>
      )}
    >
      <Box width='100%'>
        <Box width='100%'>
          <Autocomplete
            id='condition_selector'
            multiple={true}
            onOpen={() => {}}
            onClose={() => {
              dispatch(resetState())
            }}
            style={{
              borderRadius: '4px',
            }}
            disableClearable
            fullWidth
            size='small'
            getOptionSelected={(option, value) =>
              option.display === value.display
            }
            disabled={disabled}
            defaultValue={preSelectedComplaints}
            getOptionLabel={(option) => option.display}
            options={conditionSearchSlice.surgeryList ?? []}
            loading={conditionSearchSlice.searching}
            autoComplete
            includeInputInList
            filterSelectedOptions
            onChange={(e, changedValue, reason, detail) => {
              logger.info(changedValue)
              if (reason === 'remove-option') {
                if (detail) {
                  if (onDelete) onDelete(detail.option)
                }
              } else if (onSelectionChanges) {
                onSelectionChanges(changedValue ?? [])
              }
            }}
            ChipProps={{
              style: {
                backgroundColor: kPrimaryLight,
                borderRadius: '4px',
              },
            }}
            renderOption={(option, { inputValue }) => {
              const matches = match(option.display, inputValue)
              const parts = parse(option.display, matches)
              return (
                <ListItem id={`lab_condition_opt_${option.cui}`}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={part.text}
                        style={{
                          backgroundColor: part.highlight
                            ? kPrimaryLight
                            : undefined,
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </ListItem>
              )
            }}
            filterOptions={(x) => x}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('labelCommon:type_atleast_3_chars')}
                variant='outlined'
                id='condition_text_box'
                onChange={(e) => {
                  if (e.target.value.length > 2) {
                    dispatch(
                      searchConditions(
                        e.target.value,
                        selectOther,
                        preSelectedComplaints ?? []
                      )
                    )
                    setText(e.target.value)
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {conditionSearchSlice.searching ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>

        {conditionSearchSlice.error && (
          <Typography>
            {' '}
            {conditionSearchSlice.errorMessage ?? 'Error while searching'}
          </Typography>
        )}
        {conditionSearchSlice.noResultsAvailable && (
          <Typography> No Results available</Typography>
        )}
      </Box>
    </ErrorBoundary>
  )
}
