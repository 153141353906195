import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { getEncounterObjectForAppointment } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { logger } from 'utils/logger'
import { getNameOfPatient } from 'wello-web-components'
import { ReviewOfSystemsStatus } from './reviewOfSystemSliceTypes'

const initialState: ReviewOfSystemsStatus = {
  searchingReviewOfSystems: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  updatedConditions: false,
  updatingConditions: false,
  errorWhileSearchingProcedures: false,
  errorWhileUpdatingProcedures: false,
}

const reviewOfSystemsSlice = createSlice({
  name: 'reviewOfSystemsSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<ReviewOfSystemsStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingReviewOfSystems = action.payload.searchingReviewOfSystems
      state.resultsAvailable = action.payload.resultsAvailable
      state.reviewOfSystems = action.payload.reviewOfSystems
      state.errorReason = action.payload.errorReason
      state.updatedConditions = action.payload.updatedConditions
      state.updatingConditions = action.payload.updatingConditions
      state.errorWhileUpdatingProcedures =
        action.payload.errorWhileUpdatingProcedures
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
    },
  },
})

export const getReviewOfSystemsOfAppointment =
  (fhirAppointmentDetails: FhirAppointmentDetail): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: ReviewOfSystemsStatus = { ...initialState }
    state.searchingReviewOfSystems = true
    dispatch(reviewOfSystemsSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        'encounter.appointment': fhirAppointmentDetails.appointment.id,
        category: 'review-of-systems',
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/Condition',
        fhirAppointmentDetails.appointment.id!,
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingProcedures = true
        state.searchingReviewOfSystems = false

        dispatch(reviewOfSystemsSlice.actions.updatedStatus(state))
      } else {
        const conditionResponse: R4.IBundle = resp.right
        logger.info('Chief Complaint resp', conditionResponse.entry)
        if (conditionResponse?.total && conditionResponse?.total > 0) {
          state.resultsAvailable = true
          state.searchingReviewOfSystems = false
          state.reviewOfSystems = conditionResponse.entry?.map(
            (entry) => entry.resource as R4.ICondition
          )
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingProcedures = false
          dispatch(reviewOfSystemsSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: ReviewOfSystemsStatus = { ...initialState }
          errorSearchDoctor.noResultsAvailable = true
          dispatch(
            reviewOfSystemsSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: ReviewOfSystemsStatus = { ...initialState }
      errorSearchDoctor.errorReason =
        'Error while fetching chief complaint details'
      errorSearchDoctor.errorWhileSearchingProcedures = false
      dispatch(reviewOfSystemsSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export const updateReviewOfSystemsDetails =
  (
    appointment: FhirAppointmentDetail,
    systemCode: R4.ICoding,
    details: string[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let state: ReviewOfSystemsStatus = { ...initialState }
    state.updatingConditions = true
    dispatch(reviewOfSystemsSlice.actions.updatedStatus(state))
    try {
      const bundleObject: R4.IBundle = createBundleObjectForClinicalImpression(
        appointment,
        systemCode,
        details
      )
      const fhirApi: FHIRApiClient = new FHIRApiClient()
      const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        logger.info('Review of system response', relatedFhirDecodeRes.right)
        if (relatedFhirDecodeRes.right) {
          if (
            relatedFhirDecodeRes?.right?.entry !== undefined &&
            relatedFhirDecodeRes?.right?.entry.length > 1
          ) {
            logger.info(
              'Review of system response - All Success',
              relatedFhirDecodeRes.right
            )
            state = { ...initialState }
            state.updatedConditions = true
            state.searchingReviewOfSystems = false

            logger.info('Dispatching update status', relatedFhirDecodeRes.right)
            dispatch(reviewOfSystemsSlice.actions.updatedStatus(state))
            return
          }
        } else {
          const errorSearchDoctor: ReviewOfSystemsStatus = { ...initialState }
          errorSearchDoctor.errorWhileUpdatingProcedures = true
          errorSearchDoctor.errorReason = 'error while updating . try later'
          dispatch(
            reviewOfSystemsSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } else {
        const errorSearchDoctor: ReviewOfSystemsStatus = { ...initialState }
        errorSearchDoctor.errorWhileUpdatingProcedures = true
        errorSearchDoctor.errorReason = 'error while updating . try later'
        dispatch(reviewOfSystemsSlice.actions.updatedStatus(errorSearchDoctor))
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: ReviewOfSystemsStatus = { ...initialState }
      errorSearchDoctor.errorReason =
        'Error while adding/updating chief complaint details'
      errorSearchDoctor.errorWhileUpdatingProcedures = false
      dispatch(reviewOfSystemsSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

function createBundleObjectForClinicalImpression(
  appointment: FhirAppointmentDetail,
  complaintCode: R4.ICoding,
  details: string[]
): R4.IBundle {
  const encounter: R4.IEncounter = getEncounterObjectForAppointment(appointment)
  const fullUrlEncounter: string = 'urn:uuid:1232323232324'
  const matchStringEncounter: string = `${encounter.resourceType}?appointment=${appointment.appointment.resourceType}/${appointment.appointment.id}`
  const detailsData: string[] = details.filter((d: any) => d.length > 0)
  const notes: R4.IAnnotation[] = detailsData.map((val) => {
    const res: R4.IAnnotation = {
      authorReference: {
        display: getNameFromHumanName(
          appointment.practitionerDetail.practitioner.name ?? []
        ),
        id: appointment.practitionerDetail.practitioner.id,
        reference: `${appointment.practitionerDetail.practitioner.resourceType}/${appointment.practitionerDetail.practitioner.id}`,
        type: appointment.practitionerDetail.practitioner.resourceType,
      },
      text: val,
    }
    return res
  })
  const matchStringClinicalImpressrion: string = `Condition?encounter=${fullUrlEncounter}&code=${complaintCode.code}?category=review-of-systems`
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: fullUrlEncounter,
        request: {
          url: matchStringEncounter,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: encounter,
      },
      {
        fullUrl: 'urn:uuid:1232323232325',

        request: {
          url: matchStringClinicalImpressrion,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: {
          resourceType: 'Condition',
          subject: {
            display: getNameOfPatient(appointment.patient),
            id: appointment.patient.id,
            reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
            type: appointment.patient.resourceType,
          },
          code: {
            coding: [complaintCode],
            text: complaintCode.display,
          },

          recorder: {
            display: getNameFromHumanName(
              appointment.practitionerDetail.practitioner.name ?? []
            ),
            id: appointment.practitionerDetail.practitionerRole.id,
            reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
            type: appointment.practitionerDetail.practitionerRole.resourceType,
          },

          note: notes,
          category: [
            {
              coding: [
                {
                  code: 'review-of-systems',
                  display: 'Review of Systems',
                  system: 'http://wellopathy.com/condition-category',
                },
              ],
            },
          ],
          encounter: {
            reference: `${encounter.resourceType}/urn:uuid:1232323232324`,
            type: encounter.resourceType,
          },
          verificationStatus: {
            text: 'Unconfirmed',
            coding: [
              {
                code: 'unconfirmed',
                display: 'Unconfirmed',
                system:
                  'http://terminology.hl7.org/CodeSystem/condition-ver-status',
              },
            ],
          },
        },
      },
    ],
  }

  return requestBundle
}

export default reviewOfSystemsSlice.reducer
