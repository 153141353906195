import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  MenuItem,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core'
import { ArrowDropDownSharp } from '@material-ui/icons'
import { cartState } from 'models/medicine-delivery/cart/cart-states'

import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import { WelloFormItemLabel } from 'wello-web-components'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from '../common/deleteConfirmationDialog'

interface WelloSelectCodingProps {
  title?: string
  id?: string
  duration?: string
  textFieldProps: TextFieldProps
  availableCodings: R4.ICoding[]
  onChanged: (selectedCoding: R4.ICoding) => void
  preSelectedCoding?: R4.ICoding
  displayDoneLabel?: boolean
}

export const WelloSelectUnitForMedicine: React.FunctionComponent<WelloSelectCodingProps> =
  ({
    title,
    id,
    duration,
    textFieldProps,
    availableCodings,
    onChanged,
    preSelectedCoding,
    displayDoneLabel,
  }: WelloSelectCodingProps) => {
    const options: R4.ICoding[] = [
      { code: '', display: `${duration || 'Select'}` },
      ...availableCodings,
    ]
    const [item, setItem] = useState<R4.ICoding>(
      preSelectedCoding ?? { code: '', display: `${duration || 'Select'}` }
    )
    const [showDeleteConfirmation, setShowDeleteConfirmation] =
      useState<ConfirmationDialogProps>({
        open: false,
      })

    const [cart, setCart] = useRecoilState<any>(cartState)

    return (
      <Box display='flex' flexDirection='column' flexGrow={1}>
        {title && (
          <WelloFormItemLabel
            title={title}
            displayDoneLabel={displayDoneLabel}
          />
        )}
        <Box display='flex' flexDirection='column'>
          <TextField
            variant='outlined'
            fullWidth
            size='small'
            select
            id={id}
            SelectProps={{
              IconComponent: ArrowDropDownSharp,
            }}
            InputProps={{
              id: id ?? 'select_unit',
            }}
            style={{ height: 100 }}
            value={item?.code}
            label={item?.code === '' ? `${duration || 'Select'}` : ''}
            onChange={(val) => {
              const selectedItem: R4.ICoding | undefined =
                availableCodings.find(
                  (gender) => gender.code === val.target.value
                )
              if (selectedItem) {
                if (cart !== undefined) {
                  if (cart && cart.items.length > 0) {
                    setShowDeleteConfirmation({
                      open: true,
                      onContinueActionClicked: () => {
                        setShowDeleteConfirmation({ open: false })
                        setItem(selectedItem)
                        onChanged(selectedItem)
                      },
                      onCancelActionClicked: () => {
                        setShowDeleteConfirmation({ open: false })
                      },
                      displayText:
                        'Cart items will be removed. Are you sure you want to change?',
                      continueActionButtonText: 'Change',
                      cancelActionButtonText: 'Cancel',
                    })
                  } else {
                    setItem(selectedItem)
                    onChanged(selectedItem)
                  }
                }
              }
            }}
            {...textFieldProps}
          >
            {options.map((option) => (
              <MenuItem
                style={{ maxHeight: availableCodings.length <= 5 ? 500 : 200 }}
                id={option.code}
                key={option.code}
                value={option.code}
                selected={item?.code === option.code}
                disabled={option?.code === ''}
              >
                <Typography variant='subtitle2'>{option.display}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Box>
        {showDeleteConfirmation.open && (
          <ConfirmationDialog
            {...showDeleteConfirmation}
            id='Warning'
            title='Warning'
          />
        )}
      </Box>
    )
  }
