import { Address } from '../models/medicine-delivery/cart/cart-models'
import {
  Category,
  ProductDetail,
} from '../models/medicine-delivery/product-models'

export const delay = async () => {
  try {
    const timeout = 1000
    const controller = new AbortController()
    const id = setTimeout(() => controller.abort(), timeout)
    await fetch('https://hub.dummyapis.com/delay?seconds=1', {
      signal: controller.signal,
    })
    clearTimeout(id)
  } catch (error) {
    console.error('error fetching delay url', error)
  }
}

/*= ==================== PRODUCT ============================ */
export const powderChurn = {
  name: 'powderChurn',
  title: 'Power/Churn',
} as Category

export const asavaArishta = {
  name: 'asavaArishta',
  title: 'Asava Arishta',
} as Category

export const kwath = {
  name: 'kwath',
  title: 'Kwath',
} as Category

export const tablet = {
  name: 'tablet',
  title: 'Tablet',
} as Category

export const oilAndGhee = {
  name: 'oilAndGhee',
  title: 'Oil & Ghee',
} as Category

export const haritakiChurnDetails = {
  category: powderChurn,
  name: 'haritakiChurn',
  title: 'Haritaki Churna',
  description:
    'Haritaki churna is made from haritaki which is a clinically proven, time-tested remedy for all digestion-related problems.',
  image:
    'https://onemg.gumlet.io/l_watermark_346,w_690,h_700/a_ignore,w_690,h_700,c_pad,q_auto,f_auto/zqfr5afpxr2bkiwejowq.jpg',
  skus: [
    {
      discount: 40,
      inStock: true,
      price: 500,
    },
    {
      discount: 40,
      inStock: true,
      price: 250,
      unit: 'gm',
      weight: 250,
      doctorMargin: 100,
    },
    {
      discount: 40,
      inStock: true,
      price: 100,
      unit: 'gm',
      weight: 100,
      doctorMargin: 50,
    },
  ],
} as ProductDetail

// Amalaki Churna
export const amalakiChurnDetails = {
  category: powderChurn,
  name: 'amalakiChurn',
  title: 'Amalaki Churna',
  description:
    'Maharishi Ayurveda Amalaki Churna is an Ayurvedic medicine that helps in preventing premature ageing, boosting immunity. Amalaki Churna also helps in strengthening the body along with enhancing the body’s defence mechanism. Amalaki Churna has anti-inflammatory properties and is very rich in Vitamin C.',
  image:
    'https://onemg.gumlet.io/l_watermark_346,w_690,h_700/a_ignore,w_690,h_700,c_pad,q_auto,f_auto/mt2aobl7oxulbmjse9gr.jpg',
  skus: [
    {
      discount: 30,
      inStock: true,
      price: 500,
    },
    {
      discount: 20,
      inStock: true,
      price: 300,
    },
    {
      discount: 10,
      inStock: true,
      price: 65,
    },
  ],
} as ProductDetail

/*= ==================== ADDRESS ============================ */
export const sampleShippingAddress = {
  name: 'Mr. Rajiv Rao',
  type: 'shipping',
  addressLine1: '6, Plazzo esquare',
  addressLine2: 'HSR Layout',
  city: 'Bengaluru',
  pinCode: '560087',
  state: 'Karnataka',
  country: 'India',
  isDefault: true,
} as Address

export const sampleBillingAddress = {
  name: 'Mr. Rajiv Rao',
  type: 'billing',
  addressLine1: '6, Plazzo esquare',
  addressLine2: 'HSR Layout',
  city: 'Bengaluru',
  pinCode: '560087',
  state: 'Karnataka',
  country: 'India',
  isDefault: true,
} as Address
