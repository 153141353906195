import { Box, Grid, Typography } from '@material-ui/core'
import { FhirClinicalImpressionDetail } from 'models/fhirClinicalImpression'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import {
  getDischargeDiagnosisOfPTOfEncounter,
  resetState,
} from 'redux/fhirMedicalResources/ipd/addDischargeDiagnosisOfPT/addDischargeDiagnosisOfPTSlice'
import { RootState } from 'redux/rootReducer'
import { logger } from 'utils/logger'
import { NotesTile } from 'views/components/appointments/notesTile'
import { WelloFormItemLabel } from 'wello-web-components'

interface DischargeDiagnosisForPTProps {
  encounterId: string
}

export const DischargeDiagnosisOfPT: React.FC<DischargeDiagnosisForPTProps> = ({
  encounterId,
}: DischargeDiagnosisForPTProps) => {
  const [summary, setSummary] = React.useState<string>()
  const [fetchedImp, setFetchedImp] =
    React.useState<FhirClinicalImpressionDetail>()

  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()

  //   const [preSelectedSurgery, setPreSelectedSurgery] = useState<UmlResponse[]>(
  //     []
  //   )

  /*  const [findings, setFindings] = useState<R4.IClinicalImpression_Finding[]>([]) */
  const assessmentDetailsSlice = useSelector(
    (state: RootState) => state.addDischargeDiagnosisOfPTSlice
  )

  useEffect(() => {
    dispatch(getDischargeDiagnosisOfPTOfEncounter(encounterId))
  }, [])

  useEffect(() => {
    if (assessmentDetailsSlice.updatedImpressions) {
      dispatch(getDischargeDiagnosisOfPTOfEncounter(encounterId))
      dispatch(resetState())
    }
    if (assessmentDetailsSlice.resultsAvailable) {
      if (assessmentDetailsSlice.clinicalImpressions) {
        updateAssessmentDetails(assessmentDetailsSlice.clinicalImpressions)
        dispatch(resetState())
      }
    }
  }, [dispatch, assessmentDetailsSlice])

  const updateAssessmentDetails = (condition: FhirClinicalImpressionDetail) => {
    setFetchedImp(condition)

    logger.info('FhirAssessment Details,', condition)
    if (condition.clinicalImpression.summary) {
      setSummary(condition.clinicalImpression.summary)
    }
  }

  return (
    <Box display='flex' flexDirection='row'>
      <Box width='100%'>
        {summary && summary?.length > 0 && (
          <Grid container direction='column' style={{ width: '100%' }}>
            <Grid item style={{ width: '100%' }}>
              <ReactVisibilitySensor
                offset={{
                  top: 100,
                  bottom: 30,
                }}
              >
                <Grid
                  container
                  direction='column'
                  id='visit_summary'
                  style={{ width: '100%' }}
                >
                  <NotesTile
                    type={summary}
                    data='Physiotherapist Discharge Notes'
                  />
                </Grid>
              </ReactVisibilitySensor>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  )
}
