import { R4 } from '@ahryman40k/ts-fhir-types'

export const ChestObservationCodes: R4.ICoding[] = [
  {
    system: 'http://snomed.info/sct',
    code: '248698002',
    display: 'Exocardial sounds',
  },
  {
    system: 'http://snomed.info/sct',
    code: '52653008',
    display: 'Respiratory sounds',
  },
  {
    system: 'http://snomed.info/sct',
    code: '6427007',
    display: 'Chest expansion',
  },
  {
    system: 'http://snomed.info/sct',
    code: '364059007',
    display: 'Cardiac dullness to percussion',
  },
  {
    system: 'http://snomed.info/sct',
    code: '248609003',
    display: 'Chest resonance to percussion',
  },
  {
    system: 'http://snomed.info/sct',
    code: '248612000',
    display: 'Diaphragmatic dullness to percussion',
  },
  {
    system: 'http://snomed.info/sct',
    code: '248610008',
    display: 'Hepatic dullness to percussion in chest',
  },
  {
    system: 'http://snomed.info/sct',
    code: '364057009',
    display: 'Appearance of chest movement',
  },
  {
    system: 'http://snomed.info/sct',
    code: '248557003',
    display: 'Chest movement',
  },
  {
    system: 'http://snomed.info/sct',
    code: '111969002',
    display: 'Diaphragmatic movement',
  },
  {
    system: 'http://snomed.info/sct',
    code: '830011000',
    display: 'Normal Chest',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-chest-finding-cs',
    code: 'other-chest-finding',
    display: 'Other',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
