import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import React from 'react'
import {
  getLastNameOfPatient,
  getNameOfPatient,
  getProfilePicPatient,
  getTelecomOfPatient,
} from 'utils/fhirResourcesHelper'
import {
  getOrderAgentStatusText,
  getOrderTime,
  getOrderTypeDisplayCode,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import { GenderAndAgeTile } from 'views/components/patients/genderAndAgeTile'

interface Props {
  labOrderDetail: FhirLabOrderDetail
  isOdd?: boolean
  onTap?: () => void
  dense?: boolean
  isSelected?: boolean
}

export const AgentOrderTile: React.FC<Props> = ({
  labOrderDetail,
  isOdd = false,
  onTap,
  dense = false,
  isSelected = false,
}: Props) => (
  <Box width='100%' onClick={onTap}>
    <Card
      elevation={isOdd ? 0 : 1}
      style={{
        padding: 0,
        margin: 0,
        borderRadius: 0,
        backgroundColor: isSelected
          ? kPrimaryLight
          : isOdd
          ? '#FFFFFFAF'
          : undefined,
      }}
    >
      <CardContent
        style={{
          padding: 8,
          paddingLeft: 16,
          paddingRight: 16,
          border: 0,
        }}
      >
        {dense && (
          <Box display='flex' flexDirection='row' width='100%'>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              px={1}
            >
              <Avatar
                alt={getNameOfPatient(labOrderDetail.patient) ?? ''}
                src={getProfilePicPatient(labOrderDetail.patient) ?? ''}
              />
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              justifyContent='flex-start'
              flexGrow={1}
            >
              <Typography variant='subtitle1' color='primary'>
                {getNameOfPatient(labOrderDetail.patient)}
              </Typography>
              <Typography variant='body2' color='initial'>
                {getTelecomOfPatient(labOrderDetail.patient)}
              </Typography>

              <Typography variant='subtitle2' color='initial'>
                {`Order #${labOrderDetail!.serviceRequest.id ?? ''}`}
              </Typography>
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='flex-start'
              >
                <Typography variant='body2' color='initial'>
                  {`${getOrderTime(labOrderDetail)} |`}
                </Typography>
                <Typography variant='body2' color='initial'>
                  {getOrderTypeDisplayCode(labOrderDetail)}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {!dense && (
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='space-between'
            alignContent='center'
            p={0.5}
          >
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              width='15%'
            >
              <Typography variant='body1' color='initial'>
                {getOrderTime(labOrderDetail)}
              </Typography>
              <Typography variant='body2' color='initial'>
                {getOrderTypeDisplayCode(labOrderDetail)}
              </Typography>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignContent='flex-start'
              width='20%'
            >
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='flex-start'
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                >
                  <Avatar
                    alt={getNameOfPatient(labOrderDetail.patient) ?? ''}
                    src={getProfilePicPatient(labOrderDetail.patient) ?? ''}
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='start'
                  px={1}
                >
                  <Typography variant='subtitle1' color='primary'>
                    {getNameOfPatient(labOrderDetail.patient)}{' '}
                    {getLastNameOfPatient(labOrderDetail.patient)}
                  </Typography>
                  <Typography variant='body2' color='initial'>
                    {getTelecomOfPatient(labOrderDetail.patient)}
                  </Typography>
                  <Typography variant='subtitle2' color='initial'>
                    {`Order #${labOrderDetail!.serviceRequest.id ?? ''}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              width='6%'
            >
              <GenderAndAgeTile
                patient={labOrderDetail.patient}
                status={false}
              />
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              width='35%'
              paddingX={1}
            >
              <Typography variant='body1' color='initial'>
                {labOrderDetail.tests
                  ?.map(
                    (e) => e?.planDefinition.title ?? e?.planDefinition.name
                  )
                  .join(',')}
              </Typography>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              width='20%'
            >
              <Typography
                variant='subtitle1'
                color={
                  labOrderDetail.serviceRequest.status === 'active'
                    ? labOrderDetail.task &&
                      labOrderDetail.task.status !== 'rejected'
                      ? 'primary'
                      : 'error'
                    : 'error'
                }
              >
                {getOrderAgentStatusText(labOrderDetail)}
              </Typography>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              width='5%'
            >
              <IconButton aria-label='btn_order_menu' onClick={() => {}}>
                <MoreVert />
              </IconButton>
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  </Box>
)
