import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { ValueSetSearchClient } from 'services/valueSetSearchSlice'
import { getCatalogName } from 'utils/fhirResoureHelpers/catalogHelper'
import { logger } from 'utils/logger'
import { NutritionCatalogueStatus } from './nutrtionCatalogueStatus'

const initialState: NutritionCatalogueStatus = {
  searchingTreatment: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingTreatment: false,
}

const nutritionCatalogueValueSetSlice = createSlice({
  name: 'nutritionCatalogueValueSetSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<NutritionCatalogueStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingTreatment = action.payload.searchingTreatment
      state.resultsAvailable = action.payload.resultsAvailable
      state.catalogueEntries = action.payload.catalogueEntries
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingTreatment =
        action.payload.errorWhileSearchingTreatment
    },
  },
})

export const getAllNutritionValueSets =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: NutritionCatalogueStatus = {
      searchingTreatment: true,
      errorWhileSearchingTreatment: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(nutritionCatalogueValueSetSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doGetResourceCrossPartition(
        `/CatalogEntry?_count=1000`
      )

      const resp: R4.IBundle = response as R4.IBundle

      const responses = resp.entry?.map(
        (e) => e.resource as any as R4.ICatalogEntry
      )
      const filteredResponse: R4.ICatalogEntry[] = []
      if (responses) {
        for (let i = 0; i < responses.length; i++) {
          if (responses[i].orderable === true) {
            filteredResponse.push(responses[i])
          }
        }
      }

      const items = responses
        ?.filter((e) => e.orderable === true)
        .sort((a, b) => getCatalogName(b).localeCompare(getCatalogName(a)))

      state.resultsAvailable = true
      state.searchingTreatment = false
      state.catalogueEntries =
        responses !== undefined
          ? responses.sort((a, b) =>
              getCatalogName(b).localeCompare(getCatalogName(a))
            )
          : undefined
      state.noResultsAvailable = false
      state.errorReason = undefined
      state.errorWhileSearchingTreatment = false
      dispatch(nutritionCatalogueValueSetSlice.actions.updatedStatus(state))
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorSearchDoctor: NutritionCatalogueStatus = {
        searchingTreatment: false,
        errorWhileSearchingTreatment: true,
        resultsAvailable: false,
        errorReason: 'Error while searching Treatment Plan',
      }
      dispatch(
        nutritionCatalogueValueSetSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export default nutritionCatalogueValueSetSlice.reducer
