import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from 'redux/store'
import {
  UnitActorSetupForms,
  UnitActorSetupFormsType,
} from './unitActorSetupFormsType'

const initialState: UnitActorSetupForms = {
  currentForm: UnitActorSetupFormsType.BasicForm,
}

const unitActorSetupFormsSlice = createSlice({
  name: 'unitActorSetupFormsSlice',
  initialState,
  reducers: {
    openBasicDetailsForm(state, action: PayloadAction<UnitActorSetupForms>) {
      state.currentForm = UnitActorSetupFormsType.BasicForm
    },
    openClinicianDetails(state, action: PayloadAction<UnitActorSetupForms>) {
      state.currentForm = UnitActorSetupFormsType.ClinicianForm
    },
  },
})

/* export const showSuccessAlert = (alertMessage: string, link?: string) => (
  dispatch: AppDispatch
) => {
  const alertType: UnitActorSetupForms = {
    alertMessage: alertMessage,
    alertType: UnitActorSetupForms.SuccessAlert,
    showAlert: true,
    alertActionLink: link ?? "",
  };
  dispatch(alertSlice.actions.showAlert(alertType));
};

export const showErrorAlert = (alertMessage: string, link?: string) => (
  dispatch: AppDispatch
) => {
  const alertType: UnitActorSetupForms = {
    alertMessage: alertMessage,
    alertType: UnitActorSetupForms.ErrorAlert,
    showAlert: true,
    alertActionLink: link ?? "",
  };
  dispatch(alertSlice.actions.showAlert(alertType));
};
export const showWarningAlert = (alertMessage: string, link?: string) => (
  dispatch: AppDispatch
) => {
  const alertType: UnitActorSetupForms = {
    alertMessage: alertMessage,
    alertType: UnitActorSetupForms.WarningAlert,
    showAlert: true,
    alertActionLink: link ?? "",
  };
  dispatch(alertSlice.actions.showAlert(alertType));
};
export const clearAlert = () => (dispatch: AppDispatch) => {
  const alertType: UnitActorSetupForms = {
    alertMessage: "",
    alertType: UnitActorSetupForms.SuccessAlert,
    showAlert: false,
    clearAlert: true,
  };
  dispatch(alertSlice.actions.clearAlert(alertType));
}; */

export const openBasicForm = () => (dispatch: AppDispatch) => {
  const state: UnitActorSetupForms = {
    currentForm: UnitActorSetupFormsType.BasicForm,
  }
  dispatch(unitActorSetupFormsSlice.actions.openBasicDetailsForm(state))
}
export const { openBasicDetailsForm, openClinicianDetails } =
  unitActorSetupFormsSlice.actions
export default unitActorSetupFormsSlice.reducer
