import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, TextField, Typography } from '@material-ui/core'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultCodingOfSystemFromCodableConceptList,
  getDefaultDisplayOfSystemFromCodableConcept,
} from 'utils/fhirResourcesHelper'
import { ValueSetSelectMultiple } from 'views/components/common/MultiSelectFromValueSetUrl'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import { CodeFromExistingValueSet } from 'views/components/common/SingleSelectFromValueSetUrlWithCode'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { KriyaDetail } from './addKriyasForEncounter'

interface Props {
  existingKriyaDetails?: KriyaDetail
  kriyaValueSetUrl?: string
  onChanged: (kriyaDetails: KriyaDetail) => void
}
export const KriyaDetailsCaptureForm: React.FC<Props> = ({
  existingKriyaDetails,
  kriyaValueSetUrl = 'http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurveda-treatments-vs',
  onChanged,
}) => {
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()

  const [currentKriya, setCurrentKriya] = useState<KriyaDetail>({
    kriya: existingKriyaDetails
      ? existingKriyaDetails.rawKriyaDetail
        ? existingKriyaDetails.rawKriyaDetail.code
          ? getDefaultCodingOfSystemFromCodableConceptList([
              existingKriyaDetails.rawKriyaDetail.code,
            ])
          : undefined
        : undefined
      : undefined,
    medications: existingKriyaDetails
      ? existingKriyaDetails.rawKriyaDetail
        ? existingKriyaDetails.rawKriyaDetail.orderDetail
          ? existingKriyaDetails.rawKriyaDetail.orderDetail.map(
              (e) => e.coding![0]
            )
          : undefined
        : undefined
      : undefined,

    priority: existingKriyaDetails
      ? existingKriyaDetails.rawKriyaDetail
        ? existingKriyaDetails.rawKriyaDetail.priority
          ? existingKriyaDetails.rawKriyaDetail.priority
          : 'routine'
        : 'routine'
      : 'routine',
  })

  console.log('currentKriya', currentKriya)
  console.log(
    '---------existingKriyaDetails--------',
    getDefaultDisplayOfSystemFromCodableConcept(
      existingKriyaDetails?.rawKriyaDetail?.code
    )
  )

  const [displayChoices, setDisplayChoices] = useState<boolean>(false)

  useEffect(() => {
    onChanged(currentKriya)
  }, [])

  return (
    <Box
      marginX={3}
      display='flex'
      flexDirection='column'
      marginTop={1}
      marginBottom={2}
      width='100%'
    >
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        justifyContent='space-between'
      >
        <Box width='29%' key='kriya_search'>
          {existingKriyaDetails === undefined && displayChoices && (
            <ValueSetSelectSingle
              title='Select Kriya (Procedure)'
              url={kriyaValueSetUrl}
              id='kriyas'
              /* preEnteredText={
              existingKriyaDetails
                ? getDefaultDisplayOfSystemFromCodableConcept(
                    existingKriyaDetails?.rawKriyaDetail?.code
                  )
                : undefined
            } */
              fuzzySearch={true}
              disabled={existingKriyaDetails !== undefined}
              onOptionSelected={(selectedKriya) => {
                setCurrentKriya({
                  ...currentKriya,
                  kriya: selectedKriya,
                })
                onChanged({
                  ...currentKriya,
                  kriya: selectedKriya,
                })
              }}
              helperText={undefined}
            />
          )}

          {existingKriyaDetails !== undefined && displayChoices && (
            <Box display='flex' flexDirection='column'>
              <Box display='flex' flexDirection='row'>
                <WelloFormItemLabel title='Selected Kriya (Procedure)' />
              </Box>
              <Box
                display='flex'
                flexDirection='row'
                py={0.75}
                px={0.5}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  backgroundColor: '#ffffff',
                }}
              >
                <Typography
                  variant='subtitle1'
                  color='initial'
                  style={{ padding: '2px' }}
                >
                  {getDefaultDisplayOfSystemFromCodableConcept(
                    existingKriyaDetails?.rawKriyaDetail?.code
                  )}
                </Typography>
              </Box>
            </Box>
          )}
          {existingKriyaDetails !== undefined &&
            existingKriyaDetails.rawKriyaDetail !== undefined &&
            getDefaultCodeOfSystemFromCodableConcept(
              existingKriyaDetails.rawKriyaDetail.code
            ) &&
            !displayChoices && (
              <CodeFromExistingValueSet
                id='kriya_selections_form_value_set'
                title='Selected Kriya (Procedure)'
                onNoOptionAvailable={() => {
                  setDisplayChoices(true)
                }}
                searchCode={
                  existingKriyaDetails.rawKriyaDetail.code?.coding![0]!
                }
                onOptionSelected={(selectedKriya) => {
                  setCurrentKriya({
                    ...currentKriya,
                    kriya: selectedKriya,
                  })
                  onChanged({
                    ...currentKriya,
                    kriya: selectedKriya,
                  })
                }}
              />
            )}
        </Box>

        <Box width='49%' key='medicine_search'>
          <ValueSetSelectMultiple
            title='Select Medicines'
            url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurvedic-med-vs'
            id='medicines'
            disabled={false}
            fuzzySearch={true}
            pluralWord='Medicines'
            singularWorld='Medicine'
            preSelectedOptions={
              existingKriyaDetails
                ? existingKriyaDetails.rawKriyaDetail
                  ? existingKriyaDetails.rawKriyaDetail.orderDetail
                    ? existingKriyaDetails.rawKriyaDetail.orderDetail.map(
                        (e) => e.coding![0]
                      )
                    : undefined
                  : undefined
                : undefined
            }
            onOptionSelected={(selectedMedicines) => {
              console.log(
                '------------selectedMedicines---------------',
                selectedMedicines
              )
              setCurrentKriya({
                ...currentKriya,
                medications: selectedMedicines,
              })
              onChanged({
                ...currentKriya,
                medications: selectedMedicines,
              })
            }}
            helperText={undefined}
          />
        </Box>

        <Box width='19%' key='priority_search'>
          <Box display='flex' flexDirection='column'>
            <WelloSelectFHIRCoding
              title='Priority'
              availableCodings={[
                {
                  system: 'http://hl7.org/fhir/ValueSet/request-priority',
                  code: 'routine',
                  display: 'Normal',
                },
                {
                  system: 'http://hl7.org/fhir/ValueSet/request-priority',
                  code: 'urgent',
                  display: 'Urgent',
                },
                {
                  system: 'http://hl7.org/fhir/ValueSet/request-priority',
                  code: 'asap',
                  display: 'ASAP',
                },
                {
                  system: 'http://hl7.org/fhir/ValueSet/request-priority',
                  code: 'stat',
                  display: 'Immediate',
                },
              ]}
              id='kriya_priority_type'
              onChanged={(e) => {
                setCurrentKriya({
                  ...currentKriya,
                  priority: e.code ?? 'routine',
                })

                onChanged({
                  ...currentKriya,
                  priority: e.code ?? 'routine',
                })
              }}
              preSelectedCoding={{
                system: 'http://hl7.org/fhir/ValueSet/request-priority',
                code: 'routine',
                display: 'Normal',
              }}
              textFieldProps={{
                size: 'small',
                fullWidth: true,
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box display='flex' flexDirection='row'>
        <Box display='flex' flexDirection='column' width='100%'>
          <WelloFormItemLabel title='Additional Notes' />
          <TextField
            id='txt_kriya_notes'
            variant='outlined'
            size='small'
            fullWidth
            multiline
            value={currentKriya.remarks ?? ''}
            onChange={(e) => {
              setCurrentKriya({
                ...currentKriya,
                remarks: e.target.value,
              })
              onChanged({
                ...currentKriya,
                remarks: e.target.value,
              })
              e.stopPropagation()
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}
