import _, { debounce } from 'lodash'
import React, { useCallback } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'

import { R4 } from '@ahryman40k/ts-fhir-types'
import { getCodesOfValueSetFromTerminologyService } from 'utils/valusetsHelpers'
import match from 'autosuggest-highlight/match'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Tooltip,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  IconButton,
} from '@material-ui/core'
import Info from '@material-ui/icons/Info'
import {
  getDisplay,
  getHoverText,
} from 'utils/fhirResoureHelpers/organizationHelpers'
import { WelloFormItemLabel } from '../LeftMenu/WelloFormItemLabel'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

export interface ValueSetSearchProps {
  id: string
  url: string
  title: string
  preSelectedOptions?: R4.ICoding
  preEnteredText?: string
  onOptionSelected: (selectedOption?: R4.ICoding) => void
  helperText?: string
  disabled: boolean
  fuzzySearch?: boolean
  alwaysShowAllOptions?: boolean
  error?: boolean
}

export const ValueSetSelectSingleForUnitRadio: React.FC<ValueSetSearchProps> =
  ({
    id,
    url,
    title,
    preSelectedOptions,
    preEnteredText,
    onOptionSelected,
    helperText,
    disabled,
    fuzzySearch = false,
    alwaysShowAllOptions = false,
    error,
  }) => {
    const classes = useStyles()
    const [value, setValue] = React.useState<R4.ICoding | null>(
      preSelectedOptions ?? null
    )
    const [inputValue, setInputValue] = React.useState('')
    const [options, setOptions] = React.useState<R4.ICoding[]>([])
    const [loading, setLoading] = React.useState(false)
    const [open, setOpen] = React.useState<boolean>(!_.isEmpty(preEnteredText))

    function isSameAsSelectedOption(option: string) {
      if (value && value.code === option) {
        return true
      }
      return false
    }

    const loadOptions = useCallback(
      debounce((inputText, callback) => {
        getCodesOfValueSetFromTerminologyService({
          valueSetUrl: url,
          fuzzySearch,
          searchString: inputText,
        }).then((response: R4.ICoding[]) => {
          callback(response)
        })
      }, 1000),
      []
    )

    React.useEffect(() => {
      setLoading(true)
      loadOptions(inputValue, (response: R4.ICoding[]) => {
        setLoading(false)
        setOptions(response)
      })
    }, [inputValue])

    return (
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <Box py={1} px={0.25} display='flex' flexDirection='row'>
          <Typography variant='subtitle2'>{title}</Typography>
        </Box>

        {loading && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
            px={1}
          >
            <CircularProgress size={25} />
          </Box>
        )}
        {loading === false && options.length > 0 && (
          <Box>
            {options.map((coding: R4.ICoding, qindex) => (
              <FormControl key={coding.code ?? ''}>
                <RadioGroup
                  id='add_unit_address_choice_rad_group'
                  row
                  aria-label='graphType'
                  value={value ? value.code ?? '' : ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue(coding as R4.ICoding)
                    onOptionSelected(coding as R4.ICoding)
                  }}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    paddingLeft={0.4}
                  >
                    <Box width='80%'>
                      <FormControlLabel
                        value={coding.code ?? ''}
                        control={<Radio color='primary' size='small' />}
                        label={getDisplay(coding.display ?? '')}
                      />
                    </Box>
                    <Box
                      paddingRight={1}
                      paddingTop={0.5}
                      width='30%'
                      paddingLeft={
                        getDisplay(coding.display ?? '') === 'Optimal'
                          ? 0.65
                          : coding.display === 'Level 3' ||
                            coding.display === 'Level 4'
                          ? 0.1
                          : 0
                      }
                    >
                      <Tooltip
                        title={getHoverText(coding.display ?? '')}
                        placement='right-end'
                      >
                        <IconButton
                          aria-label='btn_ord_cancel'
                          color='primary'
                          id='manage_ipd'
                          style={{ padding: 0 }}
                        >
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </RadioGroup>
              </FormControl>
            ))}
          </Box>
        )}
      </Box>
    )
  }
