import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Typography,
  Grid,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core'
import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { getIdentifierValueOfUrlType } from 'utils/fhirResourcesHelper'
import {
  getAvailableBedsForRoomParsed,
  getAvailableRoomsForRoomTypesParsed,
} from 'utils/roomsHelpers/roomSelectionHelpers'
import { WelloLoadingIndicator } from 'wello-web-components'
import { BedsOfRoom } from './bedsOfRoom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'fit-content',
      border: `1px solid ${theme.palette.divider}`,

      backgroundColor: theme.palette.background.paper,
    },
  })
)

interface Props {
  roomTypeCode: string
  rooms: R4.ILocation[]
  price: number
}
export const RoomSListOfSelectedType: React.FC<Props> = ({
  roomTypeCode,
  rooms,
  price,
}: Props) => {
  const classes = useStyles()

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
        </div>
      )}
    >
      <Box
        display='flex'
        flexGrow={1}
        alignItems='flex-end'
        justifyContent='center'
        px={0.5}
        width='100%'
      >
        <Grid container>
          {rooms.map((e) => (
            <Grid item xs={6} key={e.id} className={classes.root}>
              <Box display='flex' flexDirection='column' p={0.5}>
                <Box display='flex' flexDirection='row'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignContent='center'
                    alignItems='center'
                    alignSelf='center'
                  >
                    <MeetingRoomOutlinedIcon />
                  </Box>
                  <Box display='flex' flexDirection='column' p={0.5}>
                    <Box display='flex' flexDirection='row'>
                      <Typography variant='subtitle1' color='initial'>
                        Room No{' '}
                        {getIdentifierValueOfUrlType(
                          e.identifier ?? [],
                          'SNO',
                          'http://terminology.hl7.org/CodeSystem/v2-0203'
                        ) ?? ''}
                      </Typography>
                    </Box>
                    <Box display='flex' flexDirection='row'>
                      <Typography variant='body2' color='textSecondary'>
                        {e.description ?? e.name}
                      </Typography>
                    </Box>
                    {price > 0 && (
                      <Box key={e.id} display='flex' flexDirection='row'>
                        <Typography variant='body2' color='initial'>
                          Price : &#x20b9; {price.toFixed(2)}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box key={e.id} display='flex' flexDirection='row'>
                  <BedsOfRoom roomId={e.id ?? ''} />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ErrorBoundary>
  )
}
