import { R4 } from '@ahryman40k/ts-fhir-types'

export const AutonomicObservationCodes: R4.ICoding[] = [
  {
    system: 'http://snomed.info/sct',
    code: '402604004',
    display: 'Skin Changes',
  },
  {
    system: 'http://snomed.info/sct',
    code: '65124004',
    display: 'Swelling',
  },
  {
    system: 'http://snomed.info/sct',
    code: '180333002',
    display: 'Temperature',
  },
  {
    system: 'http://snomed.info/sct',
    code: '185823004',
    display: 'Texture',
  },
  {
    system: 'http://snomed.info/sct',
    code: '39211005',
    display: 'Bowel',
  },
  {
    system: 'http://snomed.info/sct',
    code: '302771004',
    display: 'Bladder Regulation',
  },
  {
    system: 'http://snomed.info/sct',
    code: '16262004',
    display: 'Sex',
  },
  {
    system: 'http://snomed.info/sct',
    code: '301145006',
    display: 'Peripheral Puslation',
  },
  {
    system: 'http://hl7.org/fhir/sid/icd-10',
    code: 'G90.8',
    display: 'Other disorders of autonomic nervous system',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
