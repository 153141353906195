import { MedicalEventType } from 'models/medical_event'
import {
  getCompleteDateInProfileDetailView,
  getCompleteTimeInProfileDetailView,
  getDateTime,
} from 'utils/dateUtil'

export const getMedicalEventDisplayType = (
  eventType: MedicalEventType
): string => {
  switch (eventType) {
    case MedicalEventType.DOCTOR_VISIT:
      return 'Doctor Visit'

    case MedicalEventType.LAB_REPORT:
      return 'Lab Report'

    case MedicalEventType.DOCUMENT_REFERENCE:
      return 'Document'

    default:
      return 'Lab Report'
  }
}

export const getMedicalEventDate = (
  startTime: Date,
  endTime?: Date
): string => {
  const dateInStringFormat: string = `${getCompleteDateInProfileDetailView(
    startTime
  )}`
  return dateInStringFormat
}

export const getMedicalEventStartAndEndTime = (
  startTime: Date,
  endTime?: Date
): string => {
  const dateInStringFormat: string = `${getCompleteTimeInProfileDetailView(
    startTime,
    endTime
  )}`
  return dateInStringFormat
}
