import {
  Box,
  Button,
  CircularProgress,
  ListItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { LabOfferingDetail } from 'models/labOfferDetail'
import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { searchLabOfferings } from 'redux/lab/offerings/labOfferingSearchHandler/labOfferingSearchSlice'
import { RootState } from 'redux/rootReducer'
import { logger } from 'utils/logger'

interface LabOfferingSelectorProps {
  onSelectionChanges?: (selectedValues: LabOfferingDetail) => void
  doctorListId?: string
  offerRings: LabOfferingDetail[]
  preSelectedOrdersRoles?: LabOfferingDetail
  disabled: boolean
  preSelectedText?: string
}

export const LabOfferingSelectorForResult: React.FC<LabOfferingSelectorProps> =
  ({
    onSelectionChanges,
    doctorListId,
    offerRings,
    preSelectedOrdersRoles,
    disabled,
    preSelectedText,
  }: LabOfferingSelectorProps) => {
    console.log('test')
    return (
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <div role='alert'>
            <div>Error while fetching provider list</div>
            <pre>{error.message}</pre>
            <Button
              onClick={() => {
                // dispatch(searchLabOfferings())
              }}
            >
              Try again
            </Button>
          </div>
        )}
      >
        <Box width='100%'>
          <Box width='100%'>
            <Autocomplete
              id='select_tests_packages'
              multiple={false}
              onOpen={() => {
                // setOpen(true)
              }}
              onClose={() => {
                // setOpen(false)
              }}
              style={{
                borderRadius: '4px',
              }}
              disableClearable
              fullWidth
              size='small'
              getOptionSelected={(option, value) => option.name === value.name}
              disabled={disabled}
              defaultValue={preSelectedOrdersRoles}
              getOptionLabel={(option) => option.planDefinition?.title ?? ''}
              options={offerRings}
              autoComplete
              includeInputInList
              filterSelectedOptions
              onChange={(e, changedValue, reason) => {
                logger.info(changedValue)
                if (onSelectionChanges) onSelectionChanges(changedValue)
              }}
              ChipProps={{
                deleteIcon: (
                  <ClearOutlined
                    style={{
                      height: '15px',
                      color: 'white',
                    }}
                  />
                ),
                style: {
                  backgroundColor: kPrimaryLight,
                  borderRadius: '4px',
                },
              }}
              renderOption={(option, { inputValue }) => {
                const matches = match(
                  option.planDefinition.title ?? option.name ?? '',
                  inputValue
                )
                const parts = parse(
                  option.planDefinition.title ?? option.name ?? '',
                  matches
                )
                return (
                  <ListItem id={`lab_test_opt_${option.id}`}>
                    <div>
                      {parts.map((part, index) => (
                        <span
                          key={part.text}
                          style={{
                            backgroundColor: part.highlight
                              ? kPrimaryLight
                              : undefined,
                            fontWeight: part.highlight ? 700 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                  </ListItem>
                )
              }}
              filterOptions={(x) => x}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  placeholder='Search Lab Test/Panel'
                  variant='outlined'
                  id='text_search_lab_package'
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </ErrorBoundary>
    )
  }
