import { R4 } from '@ahryman40k/ts-fhir-types'
import { Calendar } from '@mantine/dates'
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  List,
  ListSubheader,
  Paper,
  TextField,
  Typography,
  withStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Theme,
  Divider,
  makeStyles,
} from '@material-ui/core'
import { ToggleButtonGroup } from '@material-ui/lab'
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox'

import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import moment from 'moment'
import Chart from 'react-apexcharts'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'redux/rootReducer'

import SimpleBar from 'simplebar-react'
import CustomizedDividers from 'views/components/toggle_selector'
import '../../../App.css'

import { Filters } from 'models/filters'
import { CohortDisplayWithCount } from 'models/cohortDisplayWithCount'
import { CohortDisplayData } from 'models/cohortDisplayData'
import { searchingCohorts } from 'redux/cohorts/cohortSearchSlice'
import {
  getCohortDataForFilter,
  getCohortDataForFilterForSinglePercentileForLineGraphWithCount,
  getCohortDataForFilterForSinglePercentileForMultiGraph,
  getCohortFromCheckBox,
  getNameForSeries,
  getPercentileLabel,
  getStrataNameFromCheckBox,
  getXAxisLabel,
} from 'utils/cohort/cohortUtils'
import { CohortFilters } from 'views/components/LeftMenu/cohortFilters'
import { pink } from '@material-ui/core/colors'
import {
  analyticsOptions,
  clinicAnalyticsOptions,
  datas,
  dietpathWays,
  newDataSet,
  newDiet,
} from 'utils/constants'
import {
  getDataLabelSeries,
  getSankeyChartData,
  getToolTipEnabledSeries,
  getYAxisData,
} from 'utils/graphData_handle/graphDataHelper'
// import { SankeyChart } from './ipd/cohort/shankeyChart'
import {
  isCurrentUnitIsClinic,
  isCurrentUnitIsWellonessCenter,
} from 'services/userDetailsService'
import { CohortChartReport } from './ipd/cohort/cohortChartReport'
import { CohortChartReportDiet } from './ipd/cohort/cohortChartDiet'
import { CohortCarePlanAnalysis } from './cohortCareplanLandingPage'

const custom: boolean = true
const FILTER = {
  //   "pre_obs": [
  //     "Normal BP at Admission"
  //   ],
  //   "treatment": [
  //     "CCB equivalent (Diet)"
  //   ]
  target: ['IPD patients'],
  pre_obs: ['Normal BP at Admission', 'Elevated BP at Admission'],
  treatment: [
    'Face, Body Pack (Lep) & Hair Pack (Therapy)',
    'Hydro (Therapy)',
    'Massage (Therapy)',
    'Packs or Wraps (Therapy)',
    'Steam (Therapy)',
    'Mud (Therapy)',
    'Machine Physiotherapy (Therapy)',
    'Acupuncture (Therapy)',
    'Yoga (Therapy)',
    'Manual Physiotherapy (Therapy)',
  ],
  post_obs: ['Elevated BP at Discharge', 'Normal BP at Discharge'],
}

const DietFilter = {
  target: ['IPD patients'],
  pre_obs: ['Normal BP at Admission'],
  treatment: ['CCB equivalent (Diet)'],
  post_obs: ['Elevated BP at Discharge', 'Normal BP at Discharge'],
}
const VISIBLE_STAGES = Object.keys(FILTER)
const dietStages = Object.keys(DietFilter)
export const CohortLandingPage: React.FC = () => {
  const cohortSlice = useSelector((state: RootState) => state.cohortSearchSlice)
  const dispatch = useDispatch()

  const useStyles = makeStyles((theme: Theme) => ({}))
  const classes = useStyles()

  const [value, setValue] = useState('single')
  const [sankeyType, setSankeyType] = useState('therapy')
  const [type, setType] = useState('max')
  const [selectedFilter, setSelectedFilter] = useState<string[]>([])
  const [cohortFilterData, setCohortFilterData] = useState<string[]>([])
  const [strataFilterData, setStrataFilterData] = useState<string[]>([])
  const [strataFilter, setStrataFilter] = useState<Filters[]>([])
  const [reportData, setReportData] = useState<CohortDisplayWithCount[]>([])
  const [optionId, setOptionId] = useState<string>(analyticsOptions[0].value)
  const [reportDataWihtCount, setReportDataWithCount] = useState<
    CohortDisplayWithCount[]
  >([])
  const [multiReport, setMultiReport] = useState<CohortDisplayData[]>([])
  const [mainData, setMainData] = useState<any[]>([])

  const SHOW_ALL: string = 'all'

  useEffect(() => {
    if (optionId === 'blg') {
      dispatch(searchingCohorts(value, type))
    }
  }, [dispatch])

  useEffect(() => {
    if (cohortSlice.resultsAvailable && cohortSlice.analysisFilter) {
      updateFilter(cohortSlice.analysisFilter)
    }
    if (cohortSlice.resultsAvailable && cohortSlice.cohortFilter) {
      updateCohortFilter(cohortSlice.cohortFilter)
    }
    if (cohortSlice.resultsAvailable && cohortSlice.strataFilter) {
      setStrataFilter(cohortSlice.strataFilter)
      updateStrataFilter(cohortSlice.strataFilter)
    }
    if (cohortSlice.resultsAvailable && cohortSlice.cohortOptions) {
      setReportData(cohortSlice.cohortOptions)
    }
    if (cohortSlice.resultsAvailable && cohortSlice.fullDataSet) {
      setMainData(cohortSlice.fullDataSet)
    }
  }, [cohortSlice])

  useEffect(() => {}, [sankeyType])

  function updateFilter(filters: Filters[]) {
    const analyseFilter: string[] = []
    for (let i = 0; i < filters.length; i++) {
      analyseFilter.push(filters[i].id)
    }
    setSelectedFilter(analyseFilter)
  }

  const styles = () => ({
    root: {
      '&$checked': {
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
    checked: {},
  })

  function updateCohortFilter(filters: Filters[]) {
    const analyseFilter: string[] = []
    for (let i = 0; i < filters.length; i++) {
      analyseFilter.push(filters[i].id)
    }
    setCohortFilterData(analyseFilter)
  }
  function updateStrataFilter(filters: Filters[]) {
    setStrataFilterData([])
    const analyseFilter: string[] = []
    // for (let i = 0; i < filters.length; i++) {
    //   if (filters[i].name === 'Mud (Therapy') {
    //     analyseFilter.push(filters[i].id)
    //   }
    // }
    analyseFilter.push(filters[3].id)
    setStrataFilterData(analyseFilter)
  }

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        borderRadius: 8,
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box display='flex' flexDirection='column'>
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='center'
            py={1}
            // style={{ paddingLeft: '300px' }}
          >
            {isCurrentUnitIsWellonessCenter() && (
              <CustomizedDividers
                onSelectionChanged={(id) => {
                  setOptionId(id)
                  if (id === 'blg') {
                    if (
                      cohortSlice.resultsAvailable &&
                      cohortSlice.analysisFilter
                    ) {
                      updateFilter(cohortSlice.analysisFilter)
                    }
                    if (
                      cohortSlice.resultsAvailable &&
                      cohortSlice.cohortFilter
                    ) {
                      updateCohortFilter(cohortSlice.cohortFilter)
                    }
                    if (
                      cohortSlice.resultsAvailable &&
                      cohortSlice.strataFilter
                    ) {
                      setStrataFilter(cohortSlice.strataFilter)
                      updateStrataFilter(cohortSlice.strataFilter)
                    }
                    if (
                      cohortSlice.resultsAvailable &&
                      cohortSlice.cohortOptions
                    ) {
                      setReportData(cohortSlice.cohortOptions)
                    }
                    if (
                      cohortSlice.resultsAvailable &&
                      cohortSlice.fullDataSet
                    ) {
                      setMainData(cohortSlice.fullDataSet)
                    }
                  } else {
                    setSankeyType('therapy')
                    setMainData([])
                    setReportDataWithCount([])
                    setReportData([])
                    setStrataFilter([])
                    setStrataFilterData([])
                    setCohortFilterData([])
                    setSelectedFilter([])
                    setType('max')
                    setValue('single')
                  }
                }}
                options={
                  isCurrentUnitIsClinic()
                    ? clinicAnalyticsOptions
                    : analyticsOptions
                }
              />
            )}
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            style={{ height: '100%' }}
          />

          {optionId === 'plan' && (
            <Box width='100%'>
              <CohortCarePlanAnalysis />
            </Box>
          )}

          {optionId === 'blg' && (
            <Box
              display='flex'
              flexDirection='row'
              overflow='auto'
              style={{ overflow: 'auto', height: '100%' }}
            >
              <Box display='flex' width='22%' maxWidth='22%'>
                <SimpleBar
                  style={{
                    height: '100%',
                    width: '100%',
                    overflowX: 'hidden',
                    padding: '8px',
                  }}
                >
                  {cohortSlice.resultsAvailable && (
                    <Box px={1} width='100%'>
                      <Box width='100%'>
                        {cohortSlice.analysisFilter &&
                          cohortSlice.analysisFilter.length > 0 &&
                          selectedFilter.length > 0 && (
                            <CohortFilters
                              onSelectionChanged={(changedTypes) => {
                                updateCohortFilter(
                                  getCohortFromCheckBox(mainData, changedTypes)
                                )
                                setStrataFilter(
                                  getStrataNameFromCheckBox(
                                    mainData,
                                    changedTypes
                                  )
                                )
                                updateStrataFilter(
                                  getStrataNameFromCheckBox(
                                    mainData,
                                    changedTypes
                                  )
                                )

                                if (value === 'single') {
                                  setReportData([])

                                  setReportData(
                                    getCohortDataForFilterForSinglePercentileForMultiGraph(
                                      mainData,
                                      changedTypes,
                                      cohortSlice.analysisFilter ?? [],
                                      strataFilterData,
                                      cohortSlice.strataFilter ?? [],
                                      type
                                    )
                                  )
                                } else {
                                  setMultiReport(
                                    getCohortDataForFilter(
                                      mainData,
                                      changedTypes,
                                      cohortSlice.analysisFilter ?? [],
                                      strataFilterData,
                                      cohortSlice.strataFilter ?? []
                                    )
                                  )
                                }

                                if (value === 'singleLine') {
                                  setReportDataWithCount([])
                                  setReportDataWithCount(
                                    getCohortDataForFilterForSinglePercentileForMultiGraph(
                                      mainData,
                                      changedTypes,
                                      cohortSlice.analysisFilter ?? [],
                                      strataFilterData,
                                      cohortSlice.strataFilter ?? [],
                                      type
                                    )
                                  )
                                }

                                setSelectedFilter(changedTypes)
                              }}
                              filterOptions={cohortSlice.analysisFilter}
                              preSelectedFilters={selectedFilter}
                              name='Analysis'
                            />
                          )}
                      </Box>
                      {cohortSlice.cohortFilter &&
                        cohortSlice.cohortFilter.length > 0 &&
                        cohortFilterData.length > 0 && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <Box>
                              <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                  <Box paddingLeft={1.5} py={1}>
                                    <Typography
                                      variant='subtitle2'
                                      style={{
                                        textTransform: 'uppercase',
                                      }}
                                    >
                                      Cohort
                                    </Typography>
                                  </Box>
                                </FormLabel>
                                <Box paddingLeft={2.1} py={1}>
                                  <RadioGroup
                                    id='add_unit_address_choice_rad_group'
                                    row
                                    aria-label='graphType'
                                    value={cohortFilterData[0]}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {}}
                                  >
                                    <FormControlLabel
                                      value={cohortFilterData[0]}
                                      control={<Radio color='primary' />}
                                      label='IPD patients with elevated BP at visit start'
                                    />
                                  </RadioGroup>
                                </Box>
                              </FormControl>
                            </Box>
                          </Box>
                        )}

                      {strataFilter.length > 0 && strataFilterData.length > 0 && (
                        <CohortFilters
                          onSelectionChanged={(changedTypes) => {
                            if (value === 'single') {
                              setReportData(
                                getCohortDataForFilterForSinglePercentileForMultiGraph(
                                  mainData,
                                  selectedFilter,
                                  cohortSlice.analysisFilter ?? [],
                                  changedTypes,
                                  cohortSlice.strataFilter ?? [],
                                  type
                                )
                              )
                            } else {
                              setMultiReport(
                                getCohortDataForFilter(
                                  mainData,
                                  selectedFilter,
                                  cohortSlice.analysisFilter ?? [],
                                  changedTypes,
                                  cohortSlice.strataFilter ?? []
                                )
                              )
                            }
                            if (value === 'singleLine') {
                              setReportDataWithCount([])

                              setReportDataWithCount(
                                getCohortDataForFilterForSinglePercentileForLineGraphWithCount(
                                  mainData,
                                  selectedFilter,
                                  cohortSlice.analysisFilter ?? [],
                                  changedTypes,
                                  cohortSlice.strataFilter ?? [],
                                  type
                                )
                              )
                            }
                            setStrataFilterData(changedTypes)
                          }}
                          filterOptions={strataFilter}
                          preSelectedFilters={strataFilterData}
                          name='Strata'
                        />
                      )}
                    </Box>
                  )}
                </SimpleBar>
              </Box>

              <Box
                display='flex'
                flexGrow={1}
                flexDirection='column'
                width='100%'
                maxWidth='88%'
                style={{ overflow: 'auto', maxHeight: '100%' }}
              >
                <Box flexDirection='row' width='100%' display='flex' p={1}>
                  {cohortSlice.searching && (
                    <Box
                      flexDirection='row'
                      display='flex'
                      alignSelf='center'
                      justifyContent='center'
                    >
                      <CircularProgress size={25} />
                    </Box>
                  )}
                </Box>

                <Box
                  display='flex'
                  flexGrow={1}
                  flexDirection='column'
                  justifyContent='Start'
                  alignContent='center'
                  overflow='auto'
                  style={{
                    backgroundColor: '#00000005',
                  }}
                >
                  {cohortSlice.error && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='100%'
                      height='100%'
                      alignContent='center'
                    >
                      <Typography
                        variant='subtitle1'
                        color='error'
                        align='center'
                      >
                        Error while searching cohorts
                      </Typography>
                    </Box>
                  )}
                  {cohortSlice.resultsAvailable && (
                    <Box display='flex' flexDirection='column' width='100%'>
                      {/* <Box
                        px={3.2}
                        display='flex'
                        flexDirection='row'
                        width='100%'
                      >
                        <Box
                          py={1}
                          paddingRight={1}
                          display='flex'
                          flexDirection='row'
                        >
                          <Typography
                            variant='subtitle1'
                            style={{
                              color: 'black',
                              fontWeight: 'bold',
                            }}
                          >
                            Display Type
                          </Typography>
                        </Box>
                        <Box paddingTop={0.4}>
                          <FormControl>
                            <RadioGroup
                              id='add_unit_address_choice_rad_group'
                              row
                              aria-label='graphType'
                              value={value}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setValue(
                                  (event.target as HTMLInputElement).value
                                )
                                if (
                                  (event.target as HTMLInputElement).value ===
                                  'single'
                                ) {
                                  setReportDataWithCount([])
                                  setMultiReport([])
                                  setType('max')
                                  setReportData(
                                    getCohortDataForFilterForSinglePercentileForMultiGraph(
                                      mainData,
                                      selectedFilter,
                                      cohortSlice.analysisFilter ?? [],
                                      strataFilterData,
                                      cohortSlice.strataFilter ?? [],
                                      'max'
                                    )
                                  )
                                } else if (
                                  (event.target as HTMLInputElement).value ===
                                  'singleLine'
                                ) {
                                  setReportData([])
                                  setReportDataWithCount([])
                                  setMultiReport([])
                                  setType('max')
                                  setReportDataWithCount(
                                    getCohortDataForFilterForSinglePercentileForLineGraphWithCount(
                                      mainData,
                                      selectedFilter,
                                      cohortSlice.analysisFilter ?? [],
                                      strataFilterData,
                                      cohortSlice.strataFilter ?? [],
                                      'max'
                                    )
                                  )
                                } else {
                                  setReportDataWithCount([])
                                  setReportData([])
                                  setMultiReport(
                                    getCohortDataForFilter(
                                      mainData,
                                      selectedFilter,
                                      cohortSlice.analysisFilter ?? [],
                                      strataFilterData,
                                      cohortSlice.strataFilter ?? []
                                    )
                                  )
                                }
                              }}
                            >
                              <Box>
                                <FormControlLabel
                                  value='single'
                                  control={<Radio color='primary' />}
                                  label='Single Metric (Combo Bar/Line)'
                                />
                                <FormControlLabel
                                  value='singleLine'
                                  control={<Radio color='primary' />}
                                  label='Single Metric (Line)'
                                />
                                <FormControlLabel
                                  value='multi'
                                  control={<Radio color='primary' />}
                                  label='Multi Metric (Bar)'
                                />
                              </Box>
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Box> */}

                      {value === 'single' && (
                        <Box
                          px={3.2}
                          display='flex'
                          flexDirection='row'
                          width='100%'
                        >
                          <Box
                            py={1}
                            paddingRight={1}
                            display='flex'
                            flexDirection='row'
                          >
                            <Typography
                              variant='subtitle1'
                              style={{
                                color: 'black',
                                fontWeight: 'bold',
                              }}
                            >
                              Metric
                            </Typography>
                          </Box>
                          <Box paddingTop={0.4} paddingLeft={5.3}>
                            <FormControl>
                              {/* <FormLabel id='demo-row-radio-buttons-group-label'>
                      <Typography variant='subtitle1' color='textPrimary'>
                        Metric
                      </Typography>
                    </FormLabel> */}
                              <RadioGroup
                                id='add_unit_address_choice_rad_group'
                                row
                                aria-label='graphType'
                                value={type}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setReportData([])
                                  setReportData(
                                    getCohortDataForFilterForSinglePercentileForMultiGraph(
                                      mainData,
                                      selectedFilter,
                                      cohortSlice.analysisFilter ?? [],
                                      strataFilterData,
                                      cohortSlice.strataFilter ?? [],
                                      (event.target as HTMLInputElement).value
                                    )
                                  )
                                  setType(
                                    (event.target as HTMLInputElement).value
                                  )
                                }}
                              >
                                <Box>
                                  <FormControlLabel
                                    value='min'
                                    control={<Radio color='primary' />}
                                    label={getPercentileLabel(0)}
                                  />
                                  <FormControlLabel
                                    value='p10'
                                    control={<Radio color='primary' />}
                                    label={getPercentileLabel(10)}
                                  />
                                  <FormControlLabel
                                    value='p25'
                                    control={<Radio color='primary' />}
                                    label={getPercentileLabel(25)}
                                  />
                                  <FormControlLabel
                                    value='median'
                                    control={<Radio color='primary' />}
                                    label={getPercentileLabel(50)}
                                  />
                                  <FormControlLabel
                                    value='p75'
                                    control={<Radio color='primary' />}
                                    label={getPercentileLabel(75)}
                                  />
                                  <FormControlLabel
                                    value='p90'
                                    control={<Radio color='primary' />}
                                    label={getPercentileLabel(90)}
                                  />
                                  <FormControlLabel
                                    value='max'
                                    control={<Radio color='primary' />}
                                    label={getPercentileLabel(100)}
                                  />
                                  <FormControlLabel
                                    value='avg'
                                    control={<Radio color='primary' />}
                                    label={getPercentileLabel(125)}
                                  />
                                </Box>
                              </RadioGroup>
                            </FormControl>
                          </Box>
                        </Box>
                      )}

                      {value === 'singleLine' && (
                        <Box
                          px={3.2}
                          display='flex'
                          flexDirection='row'
                          width='100%'
                        >
                          <Box
                            py={1}
                            paddingRight={1}
                            display='flex'
                            flexDirection='row'
                          >
                            <Typography
                              variant='subtitle1'
                              style={{
                                color: 'black',
                                fontWeight: 'bold',
                              }}
                            >
                              Metric
                            </Typography>
                          </Box>
                          <Box paddingTop={0.4} paddingLeft={5.3}>
                            <FormControl>
                              {/* <FormLabel id='demo-row-radio-buttons-group-label'>
                      <Typography variant='subtitle1' color='textPrimary'>
                        Metric
                      </Typography>
                    </FormLabel> */}
                              <RadioGroup
                                id='add_unit_address_choice_rad_group'
                                row
                                aria-label='graphType'
                                value={type}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setReportDataWithCount([])
                                  setReportDataWithCount(
                                    getCohortDataForFilterForSinglePercentileForLineGraphWithCount(
                                      mainData,
                                      selectedFilter,
                                      cohortSlice.analysisFilter ?? [],
                                      strataFilterData,
                                      cohortSlice.strataFilter ?? [],
                                      (event.target as HTMLInputElement).value
                                    )
                                  )
                                  setType(
                                    (event.target as HTMLInputElement).value
                                  )
                                }}
                              >
                                <Box>
                                  <FormControlLabel
                                    value='min'
                                    control={<Radio color='primary' />}
                                    label={getPercentileLabel(0)}
                                  />
                                  <FormControlLabel
                                    value='p10'
                                    control={<Radio color='primary' />}
                                    label={getPercentileLabel(10)}
                                  />
                                  <FormControlLabel
                                    value='p25'
                                    control={<Radio color='primary' />}
                                    label={getPercentileLabel(25)}
                                  />
                                  <FormControlLabel
                                    value='median'
                                    control={<Radio color='primary' />}
                                    label={getPercentileLabel(50)}
                                  />
                                  <FormControlLabel
                                    value='p75'
                                    control={<Radio color='primary' />}
                                    label={getPercentileLabel(75)}
                                  />
                                  <FormControlLabel
                                    value='p90'
                                    control={<Radio color='primary' />}
                                    label={getPercentileLabel(90)}
                                  />
                                  <FormControlLabel
                                    value='max'
                                    control={<Radio color='primary' />}
                                    label={getPercentileLabel(100)}
                                  />
                                  <FormControlLabel
                                    value='avg'
                                    control={<Radio color='primary' />}
                                    label={getPercentileLabel(125)}
                                  />
                                </Box>
                              </RadioGroup>
                            </FormControl>
                          </Box>
                        </Box>
                      )}

                      {reportData &&
                        reportData.length > 0 &&
                        value === 'single' && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <SimpleBar
                              style={{
                                height: '100%',
                                width: '100%',
                                overflowX: 'hidden',
                                padding: '8px',
                              }}
                            >
                              {reportData.map((part, index) => (
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  key={part.analysisName}
                                >
                                  {part.analysisName === 'Diastolic BP' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                    >
                                      <Box
                                        px={5.5}
                                        display='flex'
                                        flexDirection='row'
                                        width='100%'
                                      >
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='32%'
                                          style={{ backgroundColor: 'white' }}
                                          height={30}
                                          px={0.5}
                                          justifyContent='center'
                                          alignContent='center'
                                          paddingTop={0.8}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            style={{ color: 'black' }}
                                          >
                                            Cohort :
                                          </Typography>
                                          {cohortSlice.resultsAvailable &&
                                            cohortSlice.cohortFilter &&
                                            cohortSlice.cohortFilter.length >
                                              0 && (
                                              <Typography
                                                variant='subtitle2'
                                                style={{
                                                  color: 'black',
                                                  paddingLeft: 2,
                                                }}
                                              >
                                                {
                                                  cohortSlice.cohortFilter[0]
                                                    .name
                                                }
                                              </Typography>
                                            )}
                                        </Box>
                                        <Box px={2} />
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          minWidth='20%'
                                          maxWidth='30%'
                                          style={{ backgroundColor: 'white' }}
                                          height={30}
                                          px={0.5}
                                          justifyContent='center'
                                          alignContent='center'
                                          paddingTop={0.8}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            style={{ color: 'black' }}
                                          >
                                            Analysis :
                                          </Typography>
                                          <Box paddingLeft={0.5}>
                                            <Typography
                                              variant='subtitle2'
                                              style={{ color: 'black' }}
                                            >
                                              {part.analysisName}
                                            </Typography>
                                          </Box>
                                          <Typography
                                            variant='subtitle2'
                                            style={{ paddingLeft: 2 }}
                                          >
                                            ({getNameForSeries(type)})
                                          </Typography>
                                        </Box>
                                      </Box>

                                      <Chart
                                        options={{
                                          chart: {
                                            toolbar: {
                                              show: false,
                                            },
                                            type: 'bar',
                                          },
                                          legend: {
                                            show: false,
                                          },
                                          dataLabels: {
                                            enabled: true,
                                            style: {
                                              colors: ['#111'],
                                              fontSize: '10',
                                            },
                                            background: {
                                              enabled: false,
                                              //   foreColor: '#fff',
                                              borderWidth: 0,
                                            },
                                            formatter(val: any, opt: any) {
                                              const seriesIndexData =
                                                opt.seriesIndex
                                              const dataIndexData =
                                                opt.dataPointIndex
                                              const data =
                                                opt.w.globals.initialSeries[
                                                  seriesIndexData
                                                ].data[dataIndexData]

                                              //       return `'<div >'${data.goals.label}
                                              //   <br>
                                              //   <p>&nbsp;${val}
                                              //   </div>`
                                              if (
                                                data.goals.label === 'Patients'
                                              )
                                                return `${val}`
                                              return `${data.goals.label} ${val} `
                                            },
                                          },
                                          xaxis: {
                                            title: {
                                              text: 'IPD Period ---- >',

                                              style: {
                                                color: undefined,
                                                fontSize: '12px',
                                                fontFamily:
                                                  'Helvetica, Arial, sans-serif',
                                                fontWeight: 600,
                                                cssClass:
                                                  'apexcharts-yaxis-title',
                                              },
                                            },
                                          },
                                          markers: {
                                            discrete: [
                                              {
                                                seriesIndex: 0,
                                                dataPointIndex: 7,
                                                fillColor: '#e3e3e3',
                                                strokeColor: '#fff',
                                                size: 5,
                                                shape: 'circle', // "circle" | "square" | "rect"
                                              },
                                              {
                                                seriesIndex: 2,
                                                dataPointIndex: 11,
                                                fillColor: '#f7f4f3',
                                                strokeColor: '#eee',
                                                size: 4,
                                                shape: 'circle', // "circle" | "square" | "rect"
                                              },
                                            ],
                                          },
                                          yaxis: getYAxisData(
                                            part.summaryWithCount,
                                            part.analysisName
                                          ),

                                          tooltip: {
                                            enabledOnSeries:
                                              getToolTipEnabledSeries(
                                                part.summaryWithCount,
                                                part.analysisName
                                              ),
                                            shared: false,
                                            intersect: true,
                                            x: {
                                              formatter(
                                                val: number,
                                                opts: any
                                              ) {
                                                const seriesIndexData =
                                                  opts.seriesIndex
                                                const dataIndexData =
                                                  opts.dataPointIndex
                                                const data =
                                                  opts.w.globals.initialSeries[
                                                    seriesIndexData
                                                  ].data[dataIndexData]

                                                const { name } =
                                                  opts.w.globals.initialSeries[
                                                    seriesIndexData
                                                  ]

                                                return `${'<div >On'} ${val} </div>`
                                              },
                                            },
                                            y: {
                                              title: {
                                                formatter(val: any) {
                                                  return ``
                                                },
                                              },
                                              formatter(
                                                val: number,
                                                opts: any
                                              ) {
                                                const seriesIndexData =
                                                  opts.seriesIndex
                                                const dataIndexData =
                                                  opts.dataPointIndex
                                                const data =
                                                  opts.w.globals.initialSeries[
                                                    seriesIndexData
                                                  ].data[dataIndexData]

                                                const { name } =
                                                  opts.w.globals.initialSeries[
                                                    seriesIndexData
                                                  ]

                                                const labelData: string[] =
                                                  data.meta.split(':')

                                                return `${'<div >Strata: '}${name} | Patients count: ${
                                                  data.goals.count
                                                }
                                        <br>
                                        <p>&nbsp;${labelData[0]} (${
                                                  part.analysisName
                                                }) : ${labelData[1]} mmHg
                                        </div>`
                                              },
                                            },
                                          },
                                          fill: {
                                            opacity: 1,
                                          },
                                          plotOptions: {
                                            bar: {
                                              // rangeBarOverlap: true,
                                              // rangeBarGroupRows: true,
                                              // columnWidth: 2,
                                            },
                                          },
                                          // plotOptions: {
                                          //   rangeBarOverlap: true,
                                          //   rangeBarGroupRows: true,
                                          // },

                                          stroke: {
                                            show: true,
                                            curve: 'straight',
                                            width: 2,
                                            colors: ['transparent'],
                                            lineCap: 'butt',
                                          },
                                        }}
                                        series={part.summaryWithCount}
                                        type='bar'
                                        width='1200'
                                        height={500}
                                        stacked={false}
                                      />
                                    </Box>
                                  )}

                                  {part.analysisName === 'Systolic BP' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                    >
                                      <Box
                                        px={5}
                                        display='flex'
                                        flexDirection='row'
                                        width='100%'
                                      >
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='32%'
                                          style={{ backgroundColor: 'white' }}
                                          height={30}
                                          px={0.5}
                                          justifyContent='center'
                                          alignContent='center'
                                          paddingTop={0.8}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            style={{ color: 'black' }}
                                          >
                                            Cohort :
                                          </Typography>
                                          {cohortSlice.resultsAvailable &&
                                            cohortSlice.cohortFilter &&
                                            cohortSlice.cohortFilter.length >
                                              0 && (
                                              <Typography
                                                variant='subtitle2'
                                                style={{
                                                  color: 'black',
                                                  paddingLeft: 2,
                                                }}
                                              >
                                                {
                                                  cohortSlice.cohortFilter[0]
                                                    .name
                                                }
                                              </Typography>
                                            )}
                                        </Box>
                                        <Box px={2} />
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          minWidth='20%'
                                          maxWidth='30%'
                                          style={{ backgroundColor: 'white' }}
                                          height={30}
                                          px={0.5}
                                          justifyContent='center'
                                          alignContent='center'
                                          paddingTop={0.8}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            style={{ color: 'black' }}
                                          >
                                            Analysis :
                                          </Typography>
                                          <Box paddingLeft={0.5}>
                                            <Typography
                                              variant='subtitle2'
                                              style={{ color: 'black' }}
                                            >
                                              {part.analysisName}
                                            </Typography>
                                          </Box>
                                          <Typography
                                            variant='subtitle2'
                                            style={{ paddingLeft: 2 }}
                                          >
                                            ({getNameForSeries(type)})
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Chart
                                        options={{
                                          chart: {
                                            toolbar: {
                                              show: false,
                                            },
                                            // type: 'bar',
                                          },
                                          legend: {
                                            show: false,
                                          },
                                          dataLabels: {
                                            enabled: true,
                                            style: {
                                              colors: ['#111'],
                                              fontSize: '10',
                                            },
                                            background: {
                                              enabled: false,
                                              //   foreColor: '#fff',
                                              borderWidth: 0,
                                            },
                                            formatter(val: any, opt: any) {
                                              const seriesIndexData =
                                                opt.seriesIndex
                                              const dataIndexData =
                                                opt.dataPointIndex
                                              const data =
                                                opt.w.globals.initialSeries[
                                                  seriesIndexData
                                                ].data[dataIndexData]

                                              //       return `'<div >'${data.goals.label}
                                              //   <br>
                                              //   <p>&nbsp;${val}
                                              //   </div>`
                                              if (
                                                data.goals.label === 'Patients'
                                              )
                                                return `${val}`
                                              return `${data.goals.label} ${val} `
                                            },
                                          },
                                          xaxis: {
                                            title: {
                                              text: 'IPD Period ---- >',

                                              style: {
                                                color: undefined,
                                                fontSize: '12px',
                                                fontFamily:
                                                  'Helvetica, Arial, sans-serif',
                                                fontWeight: 600,
                                                cssClass:
                                                  'apexcharts-yaxis-title',
                                              },
                                            },
                                          },
                                          yaxis: getYAxisData(
                                            part.summaryWithCount,
                                            part.analysisName
                                          ),

                                          tooltip: {
                                            enabledOnSeries:
                                              getToolTipEnabledSeries(
                                                part.summaryWithCount,
                                                part.analysisName
                                              ),
                                            shared: false,
                                            intersect: true,
                                            x: {
                                              formatter(
                                                val: number,
                                                opts: any
                                              ) {
                                                const seriesIndexData =
                                                  opts.seriesIndex
                                                const dataIndexData =
                                                  opts.dataPointIndex
                                                const data =
                                                  opts.w.globals.initialSeries[
                                                    seriesIndexData
                                                  ].data[dataIndexData]

                                                const { name } =
                                                  opts.w.globals.initialSeries[
                                                    seriesIndexData
                                                  ]

                                                return `${'<div >On'} ${val} </div>`
                                              },
                                            },
                                            y: {
                                              title: {
                                                formatter(val: any) {
                                                  return ``
                                                },
                                              },
                                              formatter(
                                                val: number,
                                                opts: any
                                              ) {
                                                const seriesIndexData =
                                                  opts.seriesIndex
                                                const dataIndexData =
                                                  opts.dataPointIndex
                                                const data =
                                                  opts.w.globals.initialSeries[
                                                    seriesIndexData
                                                  ].data[dataIndexData]

                                                const { name } =
                                                  opts.w.globals.initialSeries[
                                                    seriesIndexData
                                                  ]

                                                const labelData: string[] =
                                                  data.meta.split(':')

                                                return `${'<div >Strata: '}${name} | Patients count: ${
                                                  data.goals.count
                                                }
                                        <br>
                                        <p>&nbsp;${labelData[0]} (${
                                                  part.analysisName
                                                }) : ${labelData[1]} mmHg
                                        </div>`
                                              },
                                            },
                                          },
                                          fill: {
                                            opacity: 1,
                                          },
                                          plotOptions: {
                                            bar: {
                                              // rangeBarOverlap: true,
                                              // rangeBarGroupRows: true,
                                              // columnWidth: 2,
                                            },
                                          },
                                          // plotOptions: {
                                          //   rangeBarOverlap: true,
                                          //   rangeBarGroupRows: true,
                                          // },
                                          stroke: {
                                            curve: 'straight',
                                            width: 1,
                                          },
                                        }}
                                        series={part.summaryWithCount}
                                        type='bar'
                                        width='1200'
                                        height={500}
                                        stacked={false}
                                      />
                                    </Box>
                                  )}
                                </Box>
                              ))}
                            </SimpleBar>
                          </Box>
                        )}

                      {reportDataWihtCount &&
                        reportDataWihtCount.length > 0 &&
                        value === 'singleLine' && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <SimpleBar
                              style={{
                                height: '100%',
                                width: '100%',
                                overflowX: 'hidden',
                                padding: '8px',
                              }}
                            >
                              {reportDataWihtCount.map((part, index) => (
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  key={part.analysisName}
                                >
                                  <Box
                                    px={5}
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='32%'
                                      style={{ backgroundColor: 'white' }}
                                      height={30}
                                      px={0.5}
                                      justifyContent='center'
                                      alignContent='center'
                                      paddingTop={0.8}
                                    >
                                      <Typography
                                        variant='subtitle2'
                                        style={{ color: 'black' }}
                                      >
                                        Cohort :
                                      </Typography>
                                      {cohortSlice.resultsAvailable &&
                                        cohortSlice.cohortFilter &&
                                        cohortSlice.cohortFilter.length > 0 && (
                                          <Typography
                                            variant='subtitle2'
                                            style={{
                                              color: 'black',
                                              paddingLeft: 2,
                                            }}
                                          >
                                            {cohortSlice.cohortFilter[0].name}
                                          </Typography>
                                        )}
                                    </Box>
                                    <Box px={2} />
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      minWidth='20%'
                                      maxWidth='30%'
                                      style={{ backgroundColor: 'white' }}
                                      height={30}
                                      px={0.5}
                                      justifyContent='center'
                                      alignContent='center'
                                      paddingTop={0.8}
                                    >
                                      <Typography
                                        variant='subtitle2'
                                        style={{ color: 'black' }}
                                      >
                                        Analysis :
                                      </Typography>
                                      <Box paddingLeft={0.5}>
                                        <Typography
                                          variant='subtitle2'
                                          style={{ color: 'black' }}
                                        >
                                          {part.analysisName}
                                        </Typography>
                                      </Box>
                                      <Typography
                                        variant='subtitle2'
                                        style={{ paddingLeft: 2 }}
                                      >
                                        ({getNameForSeries(type)})
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Chart
                                    options={{
                                      chart: {
                                        type: 'line',
                                        toolbar: {
                                          show: false,
                                        },
                                        stacked: false,
                                        zoom: {
                                          enabled: false,
                                        },
                                      },
                                      dataLabels: {
                                        enabled: true,
                                      },
                                      legend: {
                                        show: false,
                                      },
                                      grid: {
                                        row: {
                                          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                                          opacity: 0.5,
                                        },
                                      },

                                      stroke: {
                                        curve: 'straight',
                                        width: 1,
                                      },
                                      xaxis: {
                                        //   categories: [0, 1, 3, 4],
                                        labels: {
                                          // formatter(val: any) {
                                          //   return `${getXAxisLabel(val)}`
                                          // },
                                        },

                                        title: {
                                          text: 'IPD Period ---- >',

                                          style: {
                                            color: undefined,
                                            fontSize: '12px',
                                            fontFamily:
                                              'Helvetica, Arial, sans-serif',
                                            fontWeight: 600,
                                            cssClass: 'apexcharts-yaxis-title',
                                          },
                                        },
                                      },
                                      yaxis: {
                                        showAlways: true,
                                        labels: {
                                          show: true,
                                          formatter(val: any) {
                                            return val.toFixed(0)
                                          },
                                        },
                                        title: {
                                          text: `${part.analysisName} in mmHg`,

                                          style: {
                                            color: undefined,
                                            fontSize: '12px',
                                            fontFamily:
                                              'Helvetica, Arial, sans-serif',
                                            fontWeight: 600,
                                            cssClass: 'apexcharts-yaxis-title',
                                          },
                                        },
                                        axisBorder: {
                                          show: true,
                                          width: 2,
                                        },
                                      },
                                      tooltip: {
                                        y: {
                                          title: {
                                            formatter(val: any) {
                                              return ``
                                            },
                                          },
                                          formatter(val: number, opts: any) {
                                            const seriesIndexData =
                                              opts.seriesIndex
                                            const dataIndexData =
                                              opts.dataPointIndex
                                            const data =
                                              opts.w.globals.initialSeries[
                                                seriesIndexData
                                              ].data[dataIndexData]

                                            const { name } =
                                              opts.w.globals.initialSeries[
                                                seriesIndexData
                                              ]

                                            return `${'<div >Strata: '}${name} | Total count: ${
                                              data.y === null ? 0 : data.goals
                                            }
                                      <br>
                                      <p>&nbsp;${
                                        data.y === null ? 'No Data' : data.meta
                                      }
                                      </div>`
                                          },
                                        },
                                        shared: true,
                                        intersect: false,
                                        fixed: {
                                          enabled: true,
                                          position: 'topRight',
                                        },
                                      },
                                      fill: {
                                        opacity: 1,
                                      },

                                      plotOptions: {
                                        bar: {
                                          rangeBarOverlap: true,
                                          rangeBarGroupRows: true,
                                          columnWidth: 160,
                                        },
                                      },
                                      // plotOptions: {
                                      //   rangeBarOverlap: true,
                                      //   rangeBarGroupRows: true,
                                      // },
                                      // stroke: {
                                      //   show: true,
                                      //   width: 2,
                                      //   colors: ['transparent'],
                                      // },
                                    }}
                                    series={part.summaryWithCount}
                                    width='1000'
                                    height={700}
                                    stacked={false}
                                  />
                                </Box>
                              ))}
                            </SimpleBar>
                          </Box>
                        )}

                      {multiReport &&
                        multiReport.length > 0 &&
                        value === 'multi' && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <SimpleBar
                              style={{
                                height: '100%',
                                width: '100%',
                                overflowX: 'hidden',
                                padding: '8px',
                              }}
                            >
                              {multiReport.map((part, index) => (
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  key={part.analysisName}
                                >
                                  <Box
                                    px={3.5}
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='32%'
                                      style={{ backgroundColor: 'white' }}
                                      height={30}
                                      px={0.5}
                                      justifyContent='center'
                                      alignContent='center'
                                      paddingTop={0.8}
                                    >
                                      <Typography
                                        variant='subtitle2'
                                        style={{ color: 'black' }}
                                      >
                                        Cohort :
                                      </Typography>
                                      {cohortSlice.resultsAvailable &&
                                        cohortSlice.cohortFilter &&
                                        cohortSlice.cohortFilter.length > 0 && (
                                          <Typography
                                            variant='subtitle2'
                                            style={{
                                              color: 'black',
                                              paddingLeft: 2,
                                            }}
                                          >
                                            {cohortSlice.cohortFilter[0].name}
                                          </Typography>
                                        )}
                                    </Box>
                                    <Box px={2} />
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      minWidth='20%'
                                      maxWidth='30%'
                                      style={{ backgroundColor: 'white' }}
                                      height={30}
                                      px={0.5}
                                      justifyContent='center'
                                      alignContent='center'
                                      paddingTop={0.8}
                                    >
                                      <Typography
                                        variant='subtitle2'
                                        style={{ color: 'black' }}
                                      >
                                        Analysis :
                                      </Typography>
                                      <Box paddingLeft={0.5}>
                                        <Typography
                                          variant='subtitle2'
                                          style={{ color: 'black' }}
                                        >
                                          {part.analysisName}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Chart
                                    options={{
                                      chart: {
                                        id: 'basic-bar',
                                        toolbar: {
                                          show: false,
                                        },
                                        // type: 'bar',

                                        stacked: true,
                                      },
                                      legend: {
                                        show: false,
                                      },
                                      xaxis: {
                                        categories: [0, 1, 3, 4],
                                        labels: {
                                          formatter(val: any) {
                                            return `${getXAxisLabel(val)}`
                                          },
                                        },
                                        title: {
                                          text: 'IPD Period ---- >',

                                          style: {
                                            color: undefined,
                                            fontSize: '12px',
                                            fontFamily:
                                              'Helvetica, Arial, sans-serif',
                                            fontWeight: 600,
                                            cssClass: 'apexcharts-yaxis-title',
                                          },
                                        },
                                      },
                                      yaxis: {
                                        showAlways: true,
                                        labels: {
                                          show: false,
                                          formatter(val: any) {
                                            return val.toFixed(0)
                                          },
                                        },
                                        title: {
                                          text: `${part.analysisName} in mmHg`,

                                          style: {
                                            color: undefined,
                                            fontSize: '12px',
                                            fontFamily:
                                              'Helvetica, Arial, sans-serif',
                                            fontWeight: 600,
                                            cssClass: 'apexcharts-yaxis-title',
                                          },
                                        },

                                        axisBorder: {
                                          show: true,
                                          width: 2,
                                        },
                                      },
                                      tooltip: {
                                        y: {
                                          title: {
                                            formatter(val: any) {
                                              return val
                                            },
                                          },
                                        },
                                      },
                                      fill: {
                                        opacity: 1,
                                      },
                                      plotOptions: {
                                        bar: {
                                          barHeight: 20,
                                          columnWidth: 220,
                                        },
                                      },
                                      // plotOptions: {
                                      //   rangeBarOverlap: true,
                                      //   rangeBarGroupRows: true,
                                      // },
                                      stroke: {
                                        show: true,
                                        width: 2,
                                        colors: ['transparent'],
                                      },
                                    }}
                                    series={part.summaryData}
                                    type='bar'
                                    width='1200'
                                    height={
                                      part.analysisName === 'Systolic BP'
                                        ? 600
                                        : 600
                                    }
                                    stacked={true}
                                  />
                                </Box>
                              ))}
                            </SimpleBar>
                          </Box>
                        )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}
          {optionId === 'sc' && (
            <Box display='flex' flexDirection='column' paddingTop={1} px={2}>
              <Box display='flex' flexDirection='row' width='100%'>
                <Box py={1} paddingRight={1} display='flex' flexDirection='row'>
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    Cohort Analysis Type
                  </Typography>
                </Box>
                <Box paddingTop={0.4}>
                  <FormControl>
                    {/* <FormLabel id='demo-row-radio-buttons-group-label'>
                    <Typography variant='subtitle1' color='textPrimary'>
                      Display Type
                    </Typography>
                  </FormLabel> */}
                    <RadioGroup
                      id='add_unit_address_choice_rad_group'
                      row
                      aria-label='graphType'
                      value={sankeyType}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setSankeyType((event.target as HTMLInputElement).value)
                      }}
                    >
                      <Box>
                        <FormControlLabel
                          value='therapy'
                          control={<Radio color='primary' />}
                          label='Therapy'
                        />
                        <FormControlLabel
                          value='diet'
                          control={<Radio color='primary' />}
                          label='Diet'
                        />
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>

              {sankeyType === 'therapy' && (
                <CohortChartReport
                  data={newDataSet.results}
                  title={`Analysis for ${newDataSet.design.name}`}
                  width={1300}
                  colorSet='Set2'
                  height={600}
                  min={5}
                  baseMin={1}
                  max={newDataSet.results.pathway_groups[0].totalPathwaysCount}
                  nodeWidth={16}
                  showMinCount={true}
                  activeFilter={true}
                  filter={FILTER}
                  accessibleTooltip={false}
                  exclusiveFilter={false}
                  visibleStages={VISIBLE_STAGES}
                  customControls={true}
                  showAllFilter={true}
                />
              )}

              {sankeyType === 'diet' && (
                <CohortChartReportDiet
                  data={newDiet.results}
                  title={`Analysis for ${newDiet.design.name} IPD Patients`}
                  width={1300}
                  height={600}
                  min={1}
                  baseMin={1}
                  colorSet='Set2'
                  max={newDiet.results.pathway_groups[0].totalPathwaysCount}
                  nodeWidth={16}
                  showMinCount={true}
                  activeFilter={true}
                  exclusiveFilter={false}
                  filter={DietFilter}
                  accessibleTooltip={false}
                  visibleStages={dietStages}
                  customControls={true}
                  showAllFilter={true}
                />
              )}
            </Box>
          )}
        </Box>
      </Paper>
    </div>
  )
}
