import { R4 } from '@ahryman40k/ts-fhir-types'

export const signs: R4.ICoding[] = [
  {
    display: 'Radiating chest pain (finding)',
    code: '10000006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bacterial sepsis (disorder)',
    code: '10001005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Coffin-Siris syndrome (disorder)',
    code: '10007009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pulmonary embolism with pulmonary infarction (disorder)',
    code: '1001000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal left ventricular systolic function (finding)',
    code: '1001000124104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Occlusal wear of teeth (disorder)',
    code: '10017004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Eosinophilic mucin rhinosinusitis (disorder)',
    code: '100171000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Asymmetry of prostate (finding)',
    code: '100191000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Muscle spasm of thoracic back (finding)',
    code: '100211000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Apocrine miliaria of areola (disorder)',
    code: '1002229008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Immunization series incomplete (finding)',
    code: '1002253002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acquired pericardial cyst (disorder)',
    code: '100231000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Religious discrimination (finding)',
    code: '1003002',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ehlers-Danlos syndrome, non hydroxylysine deficient ocular type (disorder)',
    code: '10033001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Distal deletion of short arm of chromosome 3 (disorder)',
    code: '1003322006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital synostosis of bilateral tibias and fibulas (disorder)',
    code: '1003324007',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Simple syndactyly of toes of first web space of bilateral feet (disorder)',
    code: '1003330007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital split of bilateral feet (disorder)',
    code: '1003337005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital split of bilateral hands (disorder)',
    code: '1003339008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Distal deletion of short arm of chromosome 8 (disorder)',
    code: '1003358004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Distal deletion of chromosome 13 (disorder)',
    code: '1003364006',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Molybdenum cofactor deficiency complementation group A (disorder)',
    code: '1003367004',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Molybdenum cofactor deficiency complementation group B (disorder)',
    code: '1003368009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Microphthalmos due to Delleman syndrome (disorder)',
    code: '1003369001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Microphthalmos due to Fryns syndrome (disorder)',
    code: '1003370000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Micromelic dwarfism Fryn type (disorder)',
    code: '1003371001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Microphthalmos due to branchio-oculo-facial syndrome (disorder)',
    code: '1003372008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Microcephaly with simplified gyral pattern (disorder)',
    code: '1003373003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Microlissencephaly (disorder)',
    code: '1003374009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Metachromatic leukodystrophy due to sphingolipid activator protein I deficiency (disorder)',
    code: '1003375005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Medial duplication of long arm of chromosome 9 (disorder)',
    code: '1003376006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Medial duplication of short arm of chromosome 1 (disorder)',
    code: '1003377002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Optic nerve hypoplasia due to endocrine deficiency (disorder)',
    code: '1003378007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Osteogenesis imperfecta type 5 (disorder)',
    code: '1003379004',
    system: 'http://snomed.info/sct',
  },
  {
    display: '6q16 microdeletion syndrome (disorder)',
    code: '1003380001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Onycho-tricho-dysplasia neutropenia syndrome (disorder)',
    code: '1003381002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Multiple congenital defects of vertebral segmentation (disorder)',
    code: '1003384005',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Neurodevelopmental disorder due to maternal use of alcohol (disorder)',
    code: '1003385006',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Molybdenum cofactor deficiency complementation group C (disorder)',
    code: '1003387003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'X-linked oligodontia (disorder)',
    code: '1003388008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mosaic 1q duplication (disorder)',
    code: '1003389000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'X-linked hypodontia (disorder)',
    code: '1003390009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Medial deletion of long arm of chromosome 4 (disorder)',
    code: '1003391008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Medial deletion of long arm of chromosome 5 (disorder)',
    code: '1003392001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Medial deletion of chromosome 14 (disorder)',
    code: '1003393006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Medial deletion of long arm of chromosome 2 (disorder)',
    code: '1003394000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Maternal uniparental disomy of chromosome 7 (disorder)',
    code: '1003395004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Medial deletion of chromosome 13 (disorder)',
    code: '1003396003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital atresia of intestine at multiple levels (disorder)',
    code: '1003397007',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Congenital anorectal fistula due to intermediate anorectal malformation (disorder)',
    code: '1003398002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital anomaly of first branchial cleft (disorder)',
    code: '1003399005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital gastric heterotopia of duodenum (disorder)',
    code: '1003400003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Maternal uniparental disomy of chromosome 15 (disorder)',
    code: '1003402006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Maternal uniparental disomy of chromosome 11 (disorder)',
    code: '1003403001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Maternal uniparental disomy of chromosome 14 (disorder)',
    code: '1003405008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital intermediate sized melanocytic nevus (disorder)',
    code: '1003406009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Marfan syndrome type 1 (disorder)',
    code: '1003407000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital dystrophy of cornea (disorder)',
    code: '1003408005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Medial duplication of long arm of chromosome 5 (disorder)',
    code: '1003410007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Medial duplication of long arm of chromosome 7 (disorder)',
    code: '1003411006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Medial duplication of long arm of chromosome 2 (disorder)',
    code: '1003412004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Medial duplication of long arm of chromosome 4 (disorder)',
    code: '1003413009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Medial duplication of chromosome 14 (disorder)',
    code: '1003414003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Medial duplication of long arm of chromosome 1 (disorder)',
    code: '1003415002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Medial duplication of chromosome 13 (disorder)',
    code: '1003416001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Medial deletion of short arm of chromosome 1 (disorder)',
    code: '1003417005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Medial deletion of long arm of chromosome 1 (disorder)',
    code: '1003418000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Medial deletion of long arm of chromosome 7 (disorder)',
    code: '1003419008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Medial deletion of long arm of chromosome 9 (disorder)',
    code: '1003420002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Intra-abdominal omphalomesenteric duct cyst (disorder)',
    code: '1003421003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Hypoplasia of optic nerve due to central nervous system malformation (disorder)',
    code: '1003422005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Dislocation of incudostapedial articulation (disorder)',
    code: '1003423000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hutterite type cataract (disorder)',
    code: '1003424006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Glaucoma due to Lowe syndrome (disorder)',
    code: '1003425007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Glomerular disease due to Fabry disease (disorder)',
    code: '1003426008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Genochondromatosis type 1 (disorder)',
    code: '1003427004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Glaucoma due to congenital anomaly of eye (disorder)',
    code: '1003428009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Focal cortical dysplasia type IIa (disorder)',
    code: '1003429001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Focal cortical dysplasia type IIb (disorder)',
    code: '1003430006',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Mandibuloacral dysostosis co-occurrent with type A lipodystrophy (disorder)',
    code: '1003431005',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Mandibuloacral dysostosis co-occurrent with type B lipodystrophy (disorder)',
    code: '1003432003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Malrotation of small intestine (disorder)',
    code: '1003433008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lipoma due to neurospinal dysraphism (disorder)',
    code: '1003434002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Linear basal cell nevus (disorder)',
    code: '1003435001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Linear umbilical cord disruption (disorder)',
    code: '1003436000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Leydig cell hypoplasia due to complete luteinizing hormone receptor inactivation (disorder)',
    code: '1003437009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Leydig cell hypoplasia due to partial luteinizing hormone receptor inactivation (disorder)',
    code: '1003438004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Intra-abdominal vitelline remnant (disorder)',
    code: '1003439007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Laryngeal cleft type 0 (disorder)',
    code: '1003440009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Synostosis of bilateral humerus, radius and ulna (disorder)',
    code: '1003442001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital stenosis of male external urethral orifice (disorder)',
    code: '1003443006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Type 3 lissencephaly (disorder)',
    code: '1003444000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lumbosacral spina bifida aperta with hydrocephalus (disorder)',
    code: '1003445004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Synostosis of bilateral humerus and ulna (disorder)',
    code: '1003446003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pelizaeus-Merzbacher disease null syndrome (disorder)',
    code: '1003447007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lumbosacral spina bifida aperta (disorder)',
    code: '1003448002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Paternal 14q32.2 microdeletion (disorder)',
    code: '1003449005',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Progressive avascular necrosis of bilateral lunate bones (disorder)',
    code: '1003460001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Focal cortical dysplasia type II (disorder)',
    code: '1003461002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Focal cortical dysplasia type Ib (disorder)',
    code: '1003462009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Focal cortical dysplasia type I (disorder)',
    code: '1003463004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Focal cortical dysplasia type Ia (disorder)',
    code: '1003464005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Familial spinal neurofibromatosis (disorder)',
    code: '1003465006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fetal disorder due to maternal autoimmune disease (disorder)',
    code: '1003466007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital teratoma of nasopharynx (disorder)',
    code: '1003468008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Screening for neoplasm done (situation)',
    code: '1003470004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'B cell lymphocyte aplasia caused by drug (disorder)',
    code: '1003473002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Angle closure glaucoma suspect (disorder)',
    code: '1003488007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Angle closure glaucoma suspect of right eye (disorder)',
    code: '1003489004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Angle closure glaucoma suspect of left eye (disorder)',
    code: '1003490008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Angle closure glaucoma suspect of bilateral eyes (disorder)',
    code: '1003491007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Traumatic fracture of bone (disorder)',
    code: '1003502008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of rib (disorder)',
    code: '1003505005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of lobe of lung (disorder)',
    code: '1003507002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of foot (disorder)',
    code: '1003509004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of hand (disorder)',
    code: '1003510009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of ovary (disorder)',
    code: '1003512001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of femur (disorder)',
    code: '1003513006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of liver (disorder)',
    code: '1003514000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of tibia (disorder)',
    code: '1003515004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of ilium (disorder)',
    code: '1003516003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of pubis (disorder)',
    code: '1003518002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of talus (disorder)',
    code: '1003519005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of vomer (disorder)',
    code: '1003520004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Glaucoma suspect caused by corticosteroid (disorder)',
    code: '1003530008',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Glaucoma suspect of right eye caused by corticosteroid (disorder)',
    code: '1003532000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Glaucoma suspect of left eye caused by corticosteroid (disorder)',
    code: '1003534004',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Glaucoma suspect of bilateral eyes caused by corticosteroid (disorder)',
    code: '1003535003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of fibula (disorder)',
    code: '1003546000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of ureter (disorder)',
    code: '1003547009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of testis (disorder)',
    code: '1003548004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of radius (disorder)',
    code: '1003549007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of thymus (disorder)',
    code: '1003550007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of spleen (disorder)',
    code: '1003551006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of bladder (disorder)',
    code: '1003552004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of cilia of eyelid (disorder)',
    code: '1003553009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of humerus (disorder)',
    code: '1003554003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of trachea (disorder)',
    code: '1003555002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of stomach (disorder)',
    code: '1003556001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of ischium (disorder)',
    code: '1003557005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of maxilla (disorder)',
    code: '1003558000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of scapula (disorder)',
    code: '1003559008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of vertebra (disorder)',
    code: '1003563001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of clitoris (disorder)',
    code: '1003564007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of mandible (disorder)',
    code: '1003566009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of bile duct (disorder)',
    code: '1003567000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of diaphragm (disorder)',
    code: '1003568005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of esophagus (disorder)',
    code: '1003569002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of calcaneus (disorder)',
    code: '1003571002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of sternebra (disorder)',
    code: '1003573004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of left hand (disorder)',
    code: '1003574005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of epididymis (disorder)',
    code: '1003575006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of hyoid bone (disorder)',
    code: '1003576007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of nasal bone (disorder)',
    code: '1003577003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of premaxilla (disorder)',
    code: '1003578008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of right hand (disorder)',
    code: '1003579000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of carpal bone (disorder)',
    code: '1003581003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of tarsal bone (disorder)',
    code: '1003582005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of bilateral testes (disorder)',
    code: '1003583000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Hind brain laceration with open intracranial wound and loss of consciousness (disorder)',
    code: '1003584006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Glaucoma suspect due to diabetes mellitus type 2 (disorder)',
    code: '1003605004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Conjunctival suffusion (finding)',
    code: '1003611001',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Cytochrome P450 family 4 subfamily F member 2 poor metabolizer (finding)',
    code: '1003612008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Open angle glaucoma suspect (disorder)',
    code: '1003614009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Open angle glaucoma suspect of left eye (disorder)',
    code: '1003615005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Open angle glaucoma suspect of right eye (disorder)',
    code: '1003616006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Open angle glaucoma suspect of bilateral eyes (disorder)',
    code: '1003617002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital clinodactyly of finger (disorder)',
    code: '1003620005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital clinodactyly of little finger (disorder)',
    code: '1003621009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Human leukocyte antigen DQB1*02:02 detected (finding)',
    code: '1003641004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of bilateral feet (disorder)',
    code: '1003644007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital glaucoma of left eye (disorder)',
    code: '1003657004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Heart disease due to thyrotoxicosis (disorder)',
    code: '1003678001',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Human immunodeficiency virus Centers for Disease Control and Prevention dual infection with human immunodeficiency virus-1 and human immunodeficiency virus-2 (finding)',
    code: '1003679009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Human immunodeficiency virus Centers for Disease Control and Prevention undifferentiated type (finding)',
    code: '1003680007',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Human immunodeficiency virus Centers for Disease Control and Prevention stage 0 (finding)',
    code: '1003681006',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Human immunodeficiency virus Centers for Disease Control and Prevention stage 1 (finding)',
    code: '1003682004',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Human immunodeficiency virus Centers for Disease Control and Prevention stage 2 (finding)',
    code: '1003683009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Human immunodeficiency virus Centers for Disease Control and Prevention stage 3 (finding)',
    code: '1003684003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Human immunodeficiency virus Centers for Disease Control and Prevention stage unknown (finding)',
    code: '1003685002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Transperitoneal migration of ovum (finding)',
    code: '1003693002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Transperitoneal migration of spermatozoa (finding)',
    code: '1003694008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Transperitoneal migration of zygote (finding)',
    code: '1003695009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Transuterine migration of ovum (finding)',
    code: '1003701003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Transuterine migration of zygote (finding)',
    code: '1003702005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of metacarpal bone (disorder)',
    code: '1003704006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pain of joint of knee (finding)',
    code: '1003721002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pain of knee region (finding)',
    code: '1003722009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fetal disorder due to maternal hypertension (disorder)',
    code: '1003728008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fetal disorder due to maternal pre-eclampsia (disorder)',
    code: '1003729000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neonatal disorder due to maternal hypertension (disorder)',
    code: '1003730005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Allergy to Hevea brasiliensis latex protein (finding)',
    code: '1003755004',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Hevea brasiliensis latex protein-induced angioedema-urticaria (disorder)',
    code: '1003756003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Allergic reaction caused by Hevea brasiliensis latex protein (disorder)',
    code: '1003757007',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Anaphylaxis caused by Hevea brasiliensis latex protein (disorder)',
    code: '1003758002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal flora of pharynx (finding)',
    code: '1003772006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Traumatic hemarthrosis of glenohumeral joint (disorder)',
    code: '1003773001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'No known Hevea brasiliensis latex allergy (situation)',
    code: '1003774007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Transuterine migration (finding)',
    code: '1003796001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Transperitoneal migration (finding)',
    code: '1003797005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Remnant of vitelline vein (disorder)',
    code: '1003841002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Renal tubular dysgenesis caused by drug (disorder)',
    code: '1003842009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pyruvate dehydrogenase phosphatase deficiency (disorder)',
    code: '1003847003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Recoarctation of aorta following procedure (disorder)',
    code: '1003848008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pyruvate dehydrogenase complex E2 subunit deficiency (disorder)',
    code: '1003849000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Pyruvate dehydrogenase complex E1-alpha subunit deficiency (disorder)',
    code: '1003850000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Pyruvate dehydrogenase complex E1 beta subunit deficiency (disorder)',
    code: '1003851001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Rhizomelic chondrodysplasia punctata type 3 (disorder)',
    code: '1003858007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Rhizomelic chondrodysplasia punctata type 2 (disorder)',
    code: '1003860009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Renal tubular dysgenesis due to twin to twin transfusion (disorder)',
    code: '1003861008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Rhizomelic chondrodysplasia punctata type 1 (disorder)',
    code: '1003862001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Stenosis of truncal valve following procedure (disorder)',
    code: '1003863006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of long arm of chromosome 5 (disorder)',
    code: '1003864000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of long arm of chromosome 6 (disorder)',
    code: '1003865004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of long arm of chromosome 3 (disorder)',
    code: '1003866003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of long arm of chromosome 4 (disorder)',
    code: '1003867007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of long arm of chromosome 18 (disorder)',
    code: '1003868002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of long arm of chromosome 2 (disorder)',
    code: '1003869005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Uniparental disomy of paternal origin of chromosome 4 (disorder)',
    code: '1003870006',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Uniparental disomy of paternal origin of chromosome 15 (disorder)',
    code: '1003871005',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Uniparental disomy of paternal origin of chromosome 14 (disorder)',
    code: '1003872003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Uniparental disomy of paternal origin of chromosome 11 (disorder)',
    code: '1003873008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Dilatation of neo-ascending aorta following procedure (disorder)',
    code: '1003874002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of long arm of chromosome 17 (disorder)',
    code: '1003875001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of long arm of chromosome 12 (disorder)',
    code: '1003876000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pfeiffer syndrome type 1 (disorder)',
    code: '1003877009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of long arm of chromosome 16 (disorder)',
    code: '1003878004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Megalourethra of spongiose portion of urethra (disorder)',
    code: '1003879007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of long arm of chromosome 10 (disorder)',
    code: '1003880005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pelizaeus-Merzbacher disease in female carrier (disorder)',
    code: '1003881009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of long arm of chromosome 11 (disorder)',
    code: '1003882002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of short arm of chromosome 9 (disorder)',
    code: '1003883007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pulverulent cataract (disorder)',
    code: '1003884001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of short arm of chromosome 7 (disorder)',
    code: '1003885000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of short arm of chromosome 8 (disorder)',
    code: '1003886004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of short arm of chromosome 3 (disorder)',
    code: '1003887008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of short arm of chromosome 6 (disorder)',
    code: '1003888003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of short arm of chromosome 2 (disorder)',
    code: '1003889006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of long arm of chromosome 9 (disorder)',
    code: '1003890002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of short arm of chromosome 1 (disorder)',
    code: '1003891003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of long arm of chromosome 7 (disorder)',
    code: '1003892005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of long arm of chromosome 8 (disorder)',
    code: '1003893000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of long arm of chromosome 8 (disorder)',
    code: '1003894006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of long arm of chromosome 9 (disorder)',
    code: '1003895007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of long arm of chromosome 6 (disorder)',
    code: '1003896008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of long arm of chromosome 7 (disorder)',
    code: '1003897004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of long arm of chromosome 5 (disorder)',
    code: '1003898009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of long arm of chromosome 3 (disorder)',
    code: '1003899001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of long arm of chromosome 4 (disorder)',
    code: '1003900006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of long arm of chromosome 18 (disorder)',
    code: '1003901005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of long arm of chromosome 2 (disorder)',
    code: '1003902003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of long arm of chromosome 16 (disorder)',
    code: '1003903008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of long arm of chromosome 17 (disorder)',
    code: '1003904002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of chromosome 21 (disorder)',
    code: '1003905001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of long arm of chromosome 1 (disorder)',
    code: '1003906000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of chromosome 14 (disorder)',
    code: '1003907009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of chromosome 15 (disorder)',
    code: '1003908004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal duplication of chromosome 13 (disorder)',
    code: '1003909007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of short arm of chromosome 8 (disorder)',
    code: '1003910002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of short arm of chromosome 9 (disorder)',
    code: '1003911003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of short arm of chromosome 6 (disorder)',
    code: '1003912005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of short arm of chromosome 7 (disorder)',
    code: '1003913000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of short arm of chromosome 1 (disorder)',
    code: '1003914006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of short arm of chromosome 3 (disorder)',
    code: '1003915007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pfeiffer syndrome type 2 (disorder)',
    code: '1003916008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Secondary congenital hyperplasia of lung (disorder)',
    code: '1003917004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pfeiffer syndrome type 3 (disorder)',
    code: '1003918009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Acquired stenosis of common atrioventricular valve following procedure (disorder)',
    code: '1003919001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sabinas brittle hair syndrome (disorder)',
    code: '1003920007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sclerocornea of bilateral corneas (disorder)',
    code: '1003921006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Rothmund Thomson syndrome type 1 (disorder)',
    code: '1003922004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Rothmund Thomson syndrome type 2 (disorder)',
    code: '1003923009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of long arm of chromosome 11 (disorder)',
    code: '1003929008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of long arm of chromosome 12 (disorder)',
    code: '1003930003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of long arm of chromosome 10 (disorder)',
    code: '1003931004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of chromosome 21 (disorder)',
    code: '1003932006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of long arm of chromosome 1 (disorder)',
    code: '1003933001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of chromosome 14 (disorder)',
    code: '1003934007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of chromosome 15 (disorder)',
    code: '1003935008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Primary congenital hyperplasia of lung (disorder)',
    code: '1003936009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proximal deletion of chromosome 13 (disorder)',
    code: '1003937000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Phenylketonuria due to tetrahydrobiopterin deficiency (disorder)',
    code: '1003938005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Polydactyly of bilateral index fingers (disorder)',
    code: '1003939002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Serratia marcescens or Serratia ureilytica (finding)',
    code: '1004000003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Primary tocophobia (finding)',
    code: '1004004007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Secondary tocophobia (finding)',
    code: '1004005008',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'World Health Organization human immunodeficiency virus infection clinical stage (finding)',
    code: '1004006009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Fetal disorder due to and following fetal blood sampling (disorder)',
    code: '1004007000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Neonatal disorder due to and following fetal blood sampling (disorder)',
    code: '1004011006',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Fetal disorder due to and following operative procedure on fetus (disorder)',
    code: '1004012004',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Neonatal disorder due to and following operative procedure on fetus (disorder)',
    code: '1004013009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Fetal disorder due to and following sampling of chorionic villus (disorder)',
    code: '1004014003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Neonatal disorder due to and following sampling of chorionic villus (disorder)',
    code: '1004015002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fetal disorder due to and following amniocentesis (disorder)',
    code: '1004016001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neonatal disorder due to and following amniocentesis (disorder)',
    code: '1004017005',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Neonatal disorder due to and following operative procedure on mother (disorder)',
    code: '1004018000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Fetal disorder due to and following operative procedure on mother (disorder)',
    code: '1004019008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fetal disorder due to maternal respiratory disease (disorder)',
    code: '1004020002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neonatal disorder due to maternal respiratory disease (disorder)',
    code: '1004021003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Fetal disorder due to chronic maternal respiratory disease (disorder)',
    code: '1004022005',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Neonatal disorder due to chronic maternal respiratory disease (disorder)',
    code: '1004023000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fetal disorder due to maternal renal disease (disorder)',
    code: '1004024006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neonatal disorder due to maternal renal disease (disorder)',
    code: '1004025007',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Fetal disorder due to maternal disorder of urinary tract (disorder)',
    code: '1004026008',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Neonatal disorder due to maternal disorder of urinary tract (disorder)',
    code: '1004027004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neonatal disorder due to disease in mother (disorder)',
    code: '1004081001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fetal disorder due to maternal periodontal disease (disorder)',
    code: '1004082008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neonatal disorder due to maternal periodontal disease (disorder)',
    code: '1004083003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Entrapment of left ulnar nerve at elbow (disorder)',
    code: '1004090008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Entrapment of right ulnar nerve at elbow (disorder)',
    code: '1004092000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Tracheal stenosis following tracheostomy (disorder)',
    code: '10041001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Middle ear effusion (disorder)',
    code: '1004115000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Uncertain genetic susceptibility to malignant hyperthermia due to calcium voltage-gated channel subunit alpha1 S gene mutation (finding)',
    code: '1004155003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pyogenic bacterial arthritis of shoulder (disorder)',
    code: '100451000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pyogenic bacterial arthritis of hand (disorder)',
    code: '100461000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Myelopathy co-occurrent and due to spinal stenosis of lumbar region (disorder)',
    code: '100491000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Contusion of chest (disorder)',
    code: '10050004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mitral facies (finding)',
    code: '10051000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Myelopathy co-occurrent and due to spinal stenosis of thoracic region (disorder)',
    code: '100511000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic lichenoid pityriasis (disorder)',
    code: '10057001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Miscarriage with amniotic fluid embolism (disorder)',
    code: '10058006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sequela of traumatic intracranial hemorrhage (disorder)',
    code: '100581000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Excoriated acne (disorder)',
    code: '10065003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sensory somatic cortical disorder (disorder)',
    code: '10068001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'High grade astrocytoma of brain (disorder)',
    code: '100721000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Low grade astrocytoma of brain (disorder)',
    code: '100731000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Venous thrombosis due to central venous access device (disorder)',
    code: '100751000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ischemic muscular atrophy (disorder)',
    code: '10076004',
    system: 'http://snomed.info/sct',
  },
  {
    display: "Turner's tooth (disorder)",
    code: '10078003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Maternal tobacco use in pregnancy (finding)',
    code: '100801000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Progressive rubella panencephalitis (disorder)',
    code: '10082001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Previous bariatric surgery complicating pregnancy (disorder)',
    code: '100841000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Metatarsalgia (finding)',
    code: '10085004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Infection caused by Schistosoma (disorder)',
    code: '10087007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'High output heart failure (disorder)',
    code: '10091002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Anisakiasis caused by larva of Anisakis simplex (disorder)',
    code: '10093004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Epilepsy in mother complicating pregnancy (disorder)',
    code: '100941000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Numbness and tingling sensation of skin (finding)',
    code: '101000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'No safeguarding concern identified (situation)',
    code: '1010194003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Safeguarding concern (finding)',
    code: '1010196001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Traumatic amputation of left upper limb (disorder)',
    code: '1010206000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Traumatic amputation of right upper limb (disorder)',
    code: '1010207009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Hematoma of spleen with open wound into abdominal cavity (disorder)',
    code: '1010208004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Traumatic amputation of left lower limb (disorder)',
    code: '1010210002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Traumatic amputation of right lower limb (disorder)',
    code: '1010211003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital dysplasia of left hip (disorder)',
    code: '1010212005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital dysplasia of right hip (disorder)',
    code: '1010213000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital subluxation of left hip joint (disorder)',
    code: '1010215007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital subluxation of right hip joint (disorder)',
    code: '1010217004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Traumatic amputation of right foot (disorder)',
    code: '1010227005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Traumatic amputation of left foot (disorder)',
    code: '1010228000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neural hearing loss of left ear (disorder)',
    code: '1010229008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neural hearing loss of right ear (disorder)',
    code: '1010230003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Otalgia of left ear (finding)',
    code: '1010233001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Otalgia of right ear (finding)',
    code: '1010234007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pain of left breast (finding)',
    code: '1010235008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Conductive hearing loss of right ear (disorder)',
    code: '1010236009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pain of right breast (finding)',
    code: '1010237000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Conductive hearing loss of left ear (disorder)',
    code: '1010238005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ring chromosome (disorder)',
    code: '1010276004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neonatal disorder due to maternal traumatic injury (disorder)',
    code: '1010282001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fetal disorder due to maternal traumatic injury (disorder)',
    code: '1010283006',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Fetal disorder due to abnormal maternal blood chemistry (disorder)',
    code: '1010284000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Neonatal disorder due to abnormal maternal blood chemistry (disorder)',
    code: '1010285004',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Fetal disorder due to chronic maternal cardiovascular disease (disorder)',
    code: '1010286003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Neonatal disorder due to chronic maternal cardiovascular disease (disorder)',
    code: '1010287007',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Possible thiopurine S-methyltransferase intermediate metabolizer (situation)',
    code: '1010293004',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Neonatal vitamin B12 deficiency due to maternal vitamin B12 deficiency (disorder)',
    code: '1010297003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fetal disorder due to maternal overweight (disorder)',
    code: '1010298008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neonatal disorder due to maternal overweight (disorder)',
    code: '1010299000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neonatal disorder due to maternal obesity (disorder)',
    code: '1010301007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fetal disorder due to maternal obesity (disorder)',
    code: '1010302000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Traumatic amputation of left lower limb at level between knee and ankle (disorder)',
    code: '1010309009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Traumatic amputation of right lower limb at level between knee and ankle (disorder)',
    code: '1010312007',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Fetal disorder due to maternal obesity with adult body mass index equal to or greater than 40 (disorder)',
    code: '1010314008',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Neonatal disorder due to maternal obesity with adult body mass index equal to or greater than 40 (disorder)',
    code: '1010315009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Fetal disorder due to maternal obesity with adult body mass index 30 or greater but less than 40 (disorder)',
    code: '1010316005',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Neonatal disorder due to maternal obesity with adult body mass index 30 or greater but less than 40 (disorder)',
    code: '1010317001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fetal disorder due to maternal nutritional disorder (disorder)',
    code: '1010319003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Neonatal disorder due to maternal nutritional disorder (disorder)',
    code: '1010320009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Extension of left side of sacral spine (finding)',
    code: '1010323006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Extension of right side of sacral spine (finding)',
    code: '1010326003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Flexion of left side of sacral spine (finding)',
    code: '1010330000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Flexion of right side of sacral spine (finding)',
    code: '1010331001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Emphysema of left lung (disorder)',
    code: '1010333003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Emphysema of right lung (disorder)',
    code: '1010334009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Dribbling from left side of mouth (finding)',
    code: '1010338007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Dribbling from right side of mouth (finding)',
    code: '1010339004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sweating on left half of body (finding)',
    code: '1010341003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sweating on right half of body (finding)',
    code: '1010342005',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Severe deficiency of glucose-6-phosphate dehydrogenase (disorder)',
    code: '1010397000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Genetic susceptibility to malignant hyperthermia due to ryanodine receptor 1 gene mutation (finding)',
    code: '1010400009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Paroxysmal atrial fibrillation with rapid ventricular response (disorder)',
    code: '1010405004',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Fetal disorder due to maternal gestational edema and proteinuria without hypertension (disorder)',
    code: '1010415005',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Neonatal disorder due to maternal gestational edema and proteinuria without hypertension (disorder)',
    code: '1010416006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hyperplasia of left condyloid process of mandible (disorder)',
    code: '1010421009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hyperplasia of right condyloid process of mandible (disorder)',
    code: '1010422002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hyperplasia of left coronoid process of mandible (disorder)',
    code: '1010423007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hyperplasia of right coronoid process of mandible (disorder)',
    code: '1010424001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hypoplasia of left coronoid process of mandible (disorder)',
    code: '1010425000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hypoplasia of right coronoid process of mandible (disorder)',
    code: '1010426004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hypoplasia of left condyloid process of mandible (disorder)',
    code: '1010427008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hypoplasia of right condyloid process of mandible (disorder)',
    code: '1010428003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Downward displacement of right half of diaphragm (disorder)',
    code: '1010438008',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Conductive hearing loss of left ear with normal hearing on right side (disorder)',
    code: '1010439000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Conductive hearing loss of right ear with normal hearing on left side (disorder)',
    code: '1010440003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Sensorineural hearing loss of right ear with normal hearing on left side (disorder)',
    code: '1010441004',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Sensorineural hearing loss of left ear with normal hearing on right side (disorder)',
    code: '1010442006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Downward displacement of left half of diaphragm (disorder)',
    code: '1010445008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lesion of left upper motor neuron (finding)',
    code: '1010448005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lesion of right upper motor neuron (finding)',
    code: '1010449002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Right ocular fixation preference (finding)',
    code: '1010450002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Left ocular fixation preference (finding)',
    code: '1010451003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Left motor nerve conduction block (finding)',
    code: '1010456008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Right motor nerve conduction block (finding)',
    code: '1010457004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Right sensory nerve conduction block (finding)',
    code: '1010458009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Left sensory nerve conduction block (finding)',
    code: '1010459001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Uniocular nystagmus of right eye (disorder)',
    code: '1010460006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Uniocular nystagmus of left eye (disorder)',
    code: '1010461005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of right hemisphere of cerebellum (disorder)',
    code: '1010464002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agenesis of left hemisphere of cerebellum (disorder)',
    code: '1010465001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Undescended left testicle (disorder)',
    code: '1010568002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Undescended right testicle (disorder)',
    code: '1010569005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Primary mucoepidermoid carcinoma of nasopharynx (disorder)',
    code: '1010603001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ventriculomegaly due to developmental anomaly (disorder)',
    code: '1010604007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Waardenburg syndrome type 1 (disorder)',
    code: '1010606009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Primary lymphoepithelial carcinoma of nasopharynx (disorder)',
    code: '1010607000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mesomelic dysplasia of upper limb (disorder)',
    code: '1010609002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Retinitis pigmentosa-deafness syndrome type 3 (disorder)',
    code: '1010610007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Thoracoomphalopagus (disorder)',
    code: '1010611006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Torsion of umbilical cord (disorder)',
    code: '1010612004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Tetrasomy 15q (disorder)',
    code: '1010613009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Tetrasomy 5p mosaicism (disorder)',
    code: '1010614003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Late syphilis of lung (disorder)',
    code: '1010615002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cirrhosis of liver due to classical cystic fibrosis (disorder)',
    code: '1010616001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Syndactyly of fingers of bilateral hands (disorder)',
    code: '1010618000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Inflammatory tracheobronchial papilloma (disorder)',
    code: '1010619008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Infection of lung caused by Mycobacterium malmoense (disorder)',
    code: '1010620002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Infection of lung caused by Mycobacterium xenopi (disorder)',
    code: '1010622005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disorder of lung due to immunoglobulin A vasculitis (disorder)',
    code: '1010623000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Primary salivary gland type neoplasm of nasopharynx (disorder)',
    code: '1010624006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Recurrent peritonsillar abscess (disorder)',
    code: '1010626008',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Pulmonary hypertension due to developmental abnormality (disorder)',
    code: '1010627004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'X-linked congenital generalized hypertrichosis (disorder)',
    code: '1010628009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'X-linked complicated corpus callosum dysgenesis (disorder)',
    code: '1010630006',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Primary malignant melanoma of overlapping sites of accessory sinuses (disorder)',
    code: '1010631005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pseudocyst of umbilical cord (disorder)',
    code: '1010632003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Primary papillary adenocarcinoma of nasopharynx (disorder)',
    code: '1010633008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pneumonia caused by Acinetobacter (disorder)',
    code: '1010634002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neoplasm of umbilical cord (disorder)',
    code: '1010635001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Waardenburg syndrome type 2 (disorder)',
    code: '1010636000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Obstruction of eustachian tube due to inflammation (disorder)',
    code: '1010637009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Anomaly of umbilical vein group I (disorder)',
    code: '1010639007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Anomaly of umbilical vein group II (disorder)',
    code: '1010641008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sporadic infantile bilateral striatal necrosis (disorder)',
    code: '1010642001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Thoracolumbosacral spina bifida aperta (disorder)',
    code: '1010643006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Spina bifida aperta of upper thoracic spine (disorder)',
    code: '1010644000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Single umbilical cord artery type III (disorder)',
    code: '1010645004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Single umbilical cord artery type IV (disorder)',
    code: '1010646003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Acute vestibular syndrome due to episodic vestibular disorder (disorder)',
    code: '1010647007',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Acute vestibular syndrome due to cerebrovascular disease (disorder)',
    code: '1010648002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute vestibular syndrome due to anxiety and fear (disorder)',
    code: '1010649005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute endogenous lipoid pneumonia (disorder)',
    code: '1010650005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acquired cholesteatoma of middle ear (disorder)',
    code: '1010651009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Single umbilical cord artery type II (disorder)',
    code: '1010652002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Segmental neurofibromatosis type 1 (disorder)',
    code: '1010653007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Single umbilical cord artery type I (disorder)',
    code: '1010655000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Adenoid squamous cell carcinoma of nasopharynx (disorder)',
    code: '1010656004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Secondary hypoplasia of bilateral lungs (disorder)',
    code: '1010657008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Segmental infantile hemangioma (disorder)',
    code: '1010659006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Infantile hemangioma of subglottis (disorder)',
    code: '1010660001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Infection of lung caused by Mycobacterium kansasii (disorder)',
    code: '1010662009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Subcortical nodular heterotopia (disorder)',
    code: '1010663004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Stickler syndrome type 2 (disorder)',
    code: '1010664005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Helical ulceration of umbilical cord (disorder)',
    code: '1010665006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Stickler syndrome type 4 (disorder)',
    code: '1010666007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sternopagus (disorder)',
    code: '1010667003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Stickler syndrome type 1 (disorder)',
    code: '1010668008',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Primary squamous cell carcinoma of superior wall of nasopharynx (disorder)',
    code: '1010669000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic endogenous lipoid pneumonia (disorder)',
    code: '1010670004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Benign positional paroxysmal vertigo lateral canal (disorder)',
    code: '1010672007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Benign positional paroxysmal vertigo posterior canal (disorder)',
    code: '1010673002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Basaloid squamous cell carcinoma of nasopharynx (disorder)',
    code: '1010674008',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Squamous cell carcinoma of posterior wall of nasopharynx (disorder)',
    code: '1010675009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Benign clear-cell neoplasm of lung (disorder)',
    code: '1010676005',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Primary squamous cell carcinomas of anterior wall of nasopharynx (disorder)',
    code: '1010677001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Anomaly of umbilical vein group III (disorder)',
    code: '1010678006',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Primary squamous cell carcinoma of lateral wall of nasopharynx (disorder)',
    code: '1010679003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Anomaly of umbilical vein group IV (disorder)',
    code: '1010680000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Primary squamous cell carcinoma of overlapping sites of nasopharynx (disorder)',
    code: '1010681001',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Not entitled to social services assistance due to income above limit (finding)',
    code: '1010682008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Traumatic amputation of left lower limb through thigh (disorder)',
    code: '1010683003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Traumatic amputation of right lower limb through thigh (disorder)',
    code: '1010684009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Oculo-auriculo-vertebral spectrum (disorder)',
    code: '1010685005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Traumatic amputation through left forearm (disorder)',
    code: '1010686006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Traumatic amputation through right forearm (disorder)',
    code: '1010687002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Longitudinal deficiency of bilateral fibulae (disorder)',
    code: '1010709006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neonatal screening abnormal (finding)',
    code: '101071000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Autosomal dominant Emery-Dreifuss muscular dystrophy (disorder)',
    code: '1010712009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Thromboembolus of artery of upper limb (disorder)',
    code: '1010729007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Thromboembolus of artery of lower limb (disorder)',
    code: '1010730002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Vaginal pessary in situ (finding)',
    code: '1011000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Influenza A virus subtype H5 asian strain detected (finding)',
    code: '1011000124101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Female sterility (finding)',
    code: '10114008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Open wound of nape of neck without complication (disorder)',
    code: '10121008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Rosacea conjunctivitis (disorder)',
    code: '10128002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congestive heart failure due to cardiomyopathy (disorder)',
    code: '101281000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Injury of brachial artery (disorder)',
    code: '10129005',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Acute hypercapnic respiratory failure due to obstructive sleep apnea (disorder)',
    code: '101301000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Burns of multiple sites (disorder)',
    code: '10132008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Enchondroma of short bone of upper limb (disorder)',
    code: '101321000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chondroma of periosteum (disorder)',
    code: '101351000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Epidermal burn of chin (disorder)',
    code: '10137002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Adamantinoma of long bone of lower limb (disorder)',
    code: '101371000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bone marrow hyperplasia (disorder)',
    code: '10138007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Difficulty adjusting to work situation (finding)',
    code: '10139004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Benign chondroblastoma of bone (disorder)',
    code: '101391000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pulmonary granuloma (disorder)',
    code: '101401000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Benign cortical defect of bone (disorder)',
    code: '101411000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: "Dementia due to Parkinson's disease (disorder)",
    code: '101421000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Drug coated stent in branch of left coronary artery (finding)',
    code: '101451331000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Musculocutaneous nerve palsy (disorder)',
    code: '101461000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fever caused by virus (finding)',
    code: '10151000132103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Postpartum state, 4 weeks (finding)',
    code: '10152009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Systemic fibrinogenolysis (disorder)',
    code: '10153004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bilateral congenital dislocation of hip (disorder)',
    code: '10155006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of skin of back (finding)',
    code: '101551000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ependymoma of cerebrum (disorder)',
    code: '101571000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Post surgical neuroma of lower limb (disorder)',
    code: '101601000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Traumatic neuroma of lower limb (disorder)',
    code: '101611000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Postoperative neuroma of upper limb (disorder)',
    code: '101621000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Traumatic neuroma of upper limb (disorder)',
    code: '101631000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Parasystole (disorder)',
    code: '10164001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Subungual hyperkeratosis (disorder)',
    code: '10165000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Black locks, oculocutaneous albinism, AND deafness of the sensorineural type (disorder)',
    code: '10170007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Behavioral and psychological symptoms of dementia (finding)',
    code: '10171000132106',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Glomerulonephritis co-occurrent and due to antineutrophil cytoplasmic antibody positive vasculitis (disorder)',
    code: '101711000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Interstitial pulmonary fibrosis due to inhalation of substance (disorder)',
    code: '1017196003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Interstitial pulmonary fibrosis due to inhalation of drug (disorder)',
    code: '1017197007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'At increased risk of forced marriage (finding)',
    code: '1017198002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'At increased risk of financial abuse (finding)',
    code: '1017199005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'At increased risk of institutional abuse (finding)',
    code: '1017200008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'At increased risk of human trafficking (finding)',
    code: '1017202000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Viremia caused by severe acute respiratory syndrome coronavirus 2 (disorder)',
    code: '1017214008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Triploidy, diploidy, mixoploidy syndrome (disorder)',
    code: '10177005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Focal hemosiderosis (disorder)',
    code: '10180006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Recurrent cholangitis (disorder)',
    code: '10184002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute osteomyelitis of hand (disorder)',
    code: '10188004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Anticoagulant effect (finding)',
    code: '10191004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Arthritis caused by Pseudomonas (disorder)',
    code: '10193001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic orchitis (disorder)',
    code: '10194007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Juvenile osteochondrosis of acetabulum (disorder)',
    code: '10196009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Nairovirus (disorder)',
    code: '1020003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Paratesticular mass (finding)',
    code: '102031000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Megaloblastic anemia due to exfoliative dermatitis (disorder)',
    code: '10205009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Peripherally inserted central venous catheter in situ (finding)',
    code: '1021000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Perinatal depression (disorder)',
    code: '10211000132109',
    system: 'http://snomed.info/sct',
  },
  {
    display: "Kocher's sign (finding)",
    code: '10212000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Relationship problems with family members due to mental condition (finding)',
    code: '102161000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Third degree perineal laceration (disorder)',
    code: '10217006',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Relationship problems with family members due to medical condition (finding)',
    code: '102171000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'History of injury of globe of eye (situation)',
    code: '102191000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lupinus digitatus poisoning (disorder)',
    code: '10221004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Macrobiotic diet (finding)',
    code: '102268008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Herpetic infection of penis (disorder)',
    code: '10227000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lack of bone formation (finding)',
    code: '102274008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Dumbbell ossification (finding)',
    code: '102275009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bipartite ossification (finding)',
    code: '102276005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Interrupted ossification (finding)',
    code: '102277001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Erythrocyte sequestration (finding)',
    code: '102278006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Apnea (finding)',
    code: '1023001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'In-vitro fertilization pregnancy (finding)',
    code: '10231000132102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Female cystourethrocele (disorder)',
    code: '102331000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute ulcerative proctitis (disorder)',
    code: '102334311000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Male cystourethrocele (disorder)',
    code: '102341000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Superior semicircular canal dehiscence syndrome of right ear (disorder)',
    code: '102344001000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Tetrachloroethylene poisoning (disorder)',
    code: '10235002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic pneumonia (disorder)',
    code: '102361000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acquired velopharyngeal incompetence (disorder)',
    code: '102401000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Postmenopausal osteoporosis (disorder)',
    code: '102447009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Drug-induced dyskinesia (disorder)',
    code: '102448004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Tardive dyskinesia (disorder)',
    code: '102449007',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Complete atrioventricular block as complication of atrioventricular nodal ablation (disorder)',
    code: '102451000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Membranous conjunctivitis (disorder)',
    code: '102451006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of thyroid (disorder)',
    code: '102452004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Peritonsillar cellulitis (disorder)',
    code: '102453009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Battered baby AND/OR child syndrome (finding)',
    code: '102457005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Shaken baby syndrome (finding)',
    code: '102458000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Increased tolerance (finding)',
    code: '102459008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Decreased tolerance (finding)',
    code: '102460003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Increased intolerance (finding)',
    code: '102461004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Decreased intolerance (finding)',
    code: '102462006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal susceptibility to infections (finding)',
    code: '102464007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal susceptibility to infections (finding)',
    code: '102465008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Increased susceptibility to infections (finding)',
    code: '102466009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Decreased susceptibility to infections (finding)',
    code: '102467000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Increased therapeutic response (finding)',
    code: '102469002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Decreased therapeutic response (finding)',
    code: '102470001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Prolonged therapeutic response (finding)',
    code: '102472009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Shortened therapeutic response (finding)',
    code: '102473004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Patient reaction finding (finding)',
    code: '102475006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal patient reaction (finding)',
    code: '102476007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unevaluable reaction (finding)',
    code: '102477003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pre-existing condition (finding)',
    code: '102478008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pre-existing condition improved (finding)',
    code: '102479000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Generalized acute body pains (finding)',
    code: '102480002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Generalized chronic body pains (finding)',
    code: '102481003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Growing pains (finding)',
    code: '102482005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Associated sign (finding)',
    code: '102484006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Failure to maintain weight (finding)',
    code: '102492002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Increased body growth (finding)',
    code: '102493007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Decreased body growth (finding)',
    code: '102494001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Spiking fever (finding)',
    code: '102496004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Symptoms of stress (finding)',
    code: '102497008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agony (finding)',
    code: '102498003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fit and well (finding)',
    code: '102499006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Good neonatal condition at birth (finding)',
    code: '102500002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Well male newborn (finding)',
    code: '102501003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Well female newborn (finding)',
    code: '102502005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Well premature newborn (finding)',
    code: '102503000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Well premature male newborn (finding)',
    code: '102504006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Well premature female newborn (finding)',
    code: '102505007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Well child (finding)',
    code: '102506008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Well male child (finding)',
    code: '102507004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Well female child (finding)',
    code: '102508009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Well adolescent (finding)',
    code: '102509001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Well male adolescent (finding)',
    code: '102510006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Well female adolescent (finding)',
    code: '102511005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Well adult (finding)',
    code: '102512003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Well male adult (finding)',
    code: '102513008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Well female adult (finding)',
    code: '102514002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Well elder (finding)',
    code: '102515001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Well male elder (finding)',
    code: '102516000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Well female elder (finding)',
    code: '102517009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Age under 20 years (finding)',
    code: '102520001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Age under 25 years (finding)',
    code: '102521002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Age 19 to 59 years (finding)',
    code: '102522009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Age 40 to 49 years, female (finding)',
    code: '102523004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Age more than 50 years (finding)',
    code: '102524005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Age more than 50 years, male (finding)',
    code: '102525006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Age more than 50 years, female (finding)',
    code: '102526007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Age 60 to 64 years (finding)',
    code: '102527003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Age more than 65 years (finding)',
    code: '102528008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Excessive exercise (finding)',
    code: '102533007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Right lateral decubitus position (finding)',
    code: '102535000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Left lateral decubitus position (finding)',
    code: '102536004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Recumbent body position (finding)',
    code: '102538003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Semi-erect body position (finding)',
    code: '102539006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Headfirst position (finding)',
    code: '102540008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Feet first position (finding)',
    code: '102541007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormally decreased muscle contraction (finding)',
    code: '102543005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Straining (finding)',
    code: '102548001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cramp in lower leg associated with rest (finding)',
    code: '102549009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cramp in foot (finding)',
    code: '102551008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Increased bone formation (finding)',
    code: '102553006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cervical spinous process tenderness (finding)',
    code: '102554000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neck pain aggravated by recumbency (finding)',
    code: '102555004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pain in upper limb (finding)',
    code: '102556003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Arm claudication (finding)',
    code: '102557007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Edema of the upper extremity (finding)',
    code: '102558002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Edema of forearm (finding)',
    code: '102560000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Dyspraxia of arm (finding)',
    code: '102561001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Edema of elbow (finding)',
    code: '102562008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Edema of wrist (finding)',
    code: '102563003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Edema of hand (finding)',
    code: '102564009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Edema of finger (finding)',
    code: '102565005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Edema of thumb (finding)',
    code: '102566006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Loss of control of walking (finding)',
    code: '102567002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Paresis of lower extremity (finding)',
    code: '102568007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Decreased range of hip movement (finding)',
    code: '102569004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Inguinal pain (finding)',
    code: '102570003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Edema of lower extremity (finding)',
    code: '102572006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Edema of thigh (finding)',
    code: '102573001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Edema of knee (finding)',
    code: '102575008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Edema of foot (finding)',
    code: '102576009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Trepopnea (finding)',
    code: '102577000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Paradoxical bronchospasm (finding)',
    code: '102578005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cough suppression (finding)',
    code: '102580004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Excessive bronchial secretion (finding)',
    code: '102581000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unequal blood pressure in arms (finding)',
    code: '102584008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Absent second heart sound, S>2< (finding)',
    code: '102586005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute chest pain (finding)',
    code: '102587001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chest wall pain (finding)',
    code: '102588006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Atypical chest pain (finding)',
    code: '102589003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Intermittent palpitations (finding)',
    code: '102590007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chest wall tenderness (finding)',
    code: '102591006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Electrocardiogram abnormal (finding)',
    code: '102594003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unpleasant odor of axilla (finding)',
    code: '102595002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unpleasant odor of genitalia (finding)',
    code: '102596001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Clammy skin (finding)',
    code: '102598000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Warm skin (finding)',
    code: '102599008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Uterine dysfunction (finding)',
    code: '10260002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Perioral numbness (finding)',
    code: '102601005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Numbness of skin (finding)',
    code: '102603008',
    system: 'http://snomed.info/sct',
  },
  {
    display: "Hutchinson's mask (finding)",
    code: '102605001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Leser-Trélat sign (disorder)',
    code: '102606000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbohydrate craving (finding)',
    code: '102607009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Excessive appetite (finding)',
    code: '102608004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Feeding problem in child (finding)',
    code: '102609007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Salmonella Ebrie or Salmonella Monschaui (finding)',
    code: '10261000112105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Inadequate food diet (finding)',
    code: '102610002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Alcohol intolerance (finding)',
    code: '102612005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized abdominal pain (finding)',
    code: '102613000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Generalized abdominal pain (finding)',
    code: '102614006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer-type pain (finding)',
    code: '102615007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Painful mouth (finding)',
    code: '102616008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congestion of throat (finding)',
    code: '102617004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pharyngeal dryness (finding)',
    code: '102618009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pain in esophagus (finding)',
    code: '102619001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of skin due to type 2 diabetes mellitus (disorder)',
    code: '102621000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Impaired gastric emptying (finding)',
    code: '102621006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Regurgitation of food (finding)',
    code: '102622004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Increased small bowel motility (finding)',
    code: '102623009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Increased intestinal motility (finding)',
    code: '102624003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Increased large bowel motility (finding)',
    code: '102625002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Liver pain (finding)',
    code: '102626001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Liver tender (finding)',
    code: '102627005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Gallbladder pain (finding)',
    code: '102628000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pancreatic sign (finding)',
    code: '102629008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Gangrene due to peripheral vascular disease (disorder)',
    code: '102631000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pain due to disorder of pancreas (finding)',
    code: '102631004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Defective biosynthesis (finding)',
    code: '102632006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Orthodeoxia (finding)',
    code: '102633001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Base deficit (finding)',
    code: '102634007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute nutritional deficiency (finding)',
    code: '102635008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic nutritional deficiency (finding)',
    code: '102636009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Glucose level within reference range (finding)',
    code: '102659003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Glucose level outside reference range (finding)',
    code: '102660008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Massive aspiration syndrome (disorder)',
    code: '10269001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Alkaline reflux disease (disorder)',
    code: '1027000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pneumonia caused by Enterococcus faecium (disorder)',
    code: '10271000175107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute infarction of papillary muscle (disorder)',
    code: '10273003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cerebellopontine angle syndrome (disorder)',
    code: '10277002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sensory neuropathy due to type 1 diabetes mellitus (disorder)',
    code: '102781000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal renin secretion (finding)',
    code: '102799005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal renin secretion (finding)',
    code: '102800009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Increased renin secretion (finding)',
    code: '102801008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Decreased renin secretion (finding)',
    code: '102802001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Absence of renin secretion (disorder)',
    code: '102803006',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Unprepared colon segment with mucosa not seen due to solid stool that cannot be cleared (finding)',
    code: '10281000132103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pneumonia caused by Enterococcus faecalis (disorder)',
    code: '10281000175105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Isosthenuria (finding)',
    code: '102829006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Renal angle tenderness (finding)',
    code: '102830001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital stenosis of cervical canal (disorder)',
    code: '10283004',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Paralytic syndrome of both lower limbs as sequela of stroke (disorder)',
    code: '102831000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Renal angle pain (finding)',
    code: '102831002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Uninhibited neurogenic bladder (finding)',
    code: '102832009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal micturition (finding)',
    code: '102834005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Difficulty passing urine (finding)',
    code: '102835006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urethral spasm (finding)',
    code: '102836007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary cast, waxy (finding)',
    code: '102837003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary cast, leukocyte (finding)',
    code: '102838008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary cast, erythrocyte (finding)',
    code: '102840003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary cast, fatty (finding)',
    code: '102841004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary cast, granular (finding)',
    code: '102843001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary cast, broad (finding)',
    code: '102844007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary cast, broad waxy (finding)',
    code: '102845008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary sediment (finding)',
    code: '102846009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Amorphous urinary sediment (finding)',
    code: '102847000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fat globules in urine (finding)',
    code: '102848005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lipiduria (finding)',
    code: '102849002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary crystal, ammonium urate (finding)',
    code: '102851003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary crystal, bilirubin (finding)',
    code: '102852005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary crystal, calcium carbonate (finding)',
    code: '102853000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary crystal, calcium oxalate (finding)',
    code: '102854006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary crystal, calcium phosphate (finding)',
    code: '102855007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary crystal, calcium sulfate (finding)',
    code: '102856008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary crystal, cholesterol (finding)',
    code: '102857004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary crystal, cystine (finding)',
    code: '102858009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary crystal, hippurate (finding)',
    code: '102859001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary crystal, leucine (finding)',
    code: '102860006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary crystal, pyrophosphate (finding)',
    code: '102861005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary crystal, sulfonamide (finding)',
    code: '102862003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary crystal, magnesium ammonium phosphate (finding)',
    code: '102863008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary crystal, tyrosine (finding)',
    code: '102864002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Uric acid crystalluria (finding)',
    code: '102865001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal urine (finding)',
    code: '102866000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Discolored urine (finding)',
    code: '102867009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Intermenstrual spotting caused by intrauterine device (finding)',
    code: '102868004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hypothyroidism due to thyroiditis (disorder)',
    code: '102871000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pregnancy on oral contraceptive (finding)',
    code: '102872000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Surrogate pregnancy (finding)',
    code: '102875003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Multigravida (finding)',
    code: '102876002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nulliparous (finding)',
    code: '102877006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Recurrent miscarriage (disorder)',
    code: '102878001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Post-term delivery (finding)',
    code: '102879009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal placental secretion of chorionic gonadotropin (finding)',
    code: '102881006',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Abnormal placental secretion of chorionic gonadotropin (disorder)',
    code: '102882004',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Increased placental secretion of chorionic gonadotropin (finding)',
    code: '102883009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Decreased placental secretion of chorionic gonadotropin (finding)',
    code: '102884003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Absence of placental secretion of chorionic gonadotropin (disorder)',
    code: '102885002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Increased amniotic fluid production (finding)',
    code: '102886001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Decreased amniotic fluid production (finding)',
    code: '102887005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Thelarche (finding)',
    code: '102888000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Premature development of the breasts (finding)',
    code: '102889008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Age-related cognitive decline (finding)',
    code: '102891000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neglected appearance (finding)',
    code: '102892007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Excitability (finding)',
    code: '102893002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Feeling calm (finding)',
    code: '102894008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Feeling intoxicated (finding)',
    code: '102897001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Emotional relationship (finding)',
    code: '102898006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abusive emotional relationship (finding)',
    code: '102899003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abusive emotional relationship with parents (finding)',
    code: '102900008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abusive emotional relationship with spouse (finding)',
    code: '102901007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abusive emotional relationship with husband (finding)',
    code: '102902000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abusive emotional relationship with wife (finding)',
    code: '102903005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abusive emotional relationship with child (finding)',
    code: '102904004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Absence of guilt (situation)',
    code: '102905003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Delinquent behavior (finding)',
    code: '102906002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Gesticulation (finding)',
    code: '102907006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nose-picking (finding)',
    code: '102908001',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Portion of mucosa of colon segment seen, but other areas of colon segment not well seen due to staining, residual stool and/or opaque liquid (finding)',
    code: '10291000132101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pneumonia caused by Enterococcus (disorder)',
    code: '10291000175108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Prelogical thinking (finding)',
    code: '102910004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Thoughts of self harm (finding)',
    code: '102911000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fear of death (finding)',
    code: '102912007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Taphophobia (finding)',
    code: '102913002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nosophobia (finding)',
    code: '102915009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Arachnophobia (finding)',
    code: '102916005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fear of blood (finding)',
    code: '102917001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Microphobia (finding)',
    code: '102918006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fear of infection (finding)',
    code: '102919003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Fear of contracting human immunodeficiency virus infection (finding)',
    code: '102920009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Toxicophobia (finding)',
    code: '102921008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Tocophobia (finding)',
    code: '102922001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Teratophobia (finding)',
    code: '102923006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pyrophobia (finding)',
    code: '102924000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Astrapophobia (finding)',
    code: '102925004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nyctophobia (finding)',
    code: '102927007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Theophobia (finding)',
    code: '102928002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Androphobia (finding)',
    code: '102929005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Gynephobia (finding)',
    code: '102930000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fear of heart attack (finding)',
    code: '102931001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fear of hypertension (finding)',
    code: '102932008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mirror speech (finding)',
    code: '102933003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Embololalia (finding)',
    code: '102934009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Explosive speech (finding)',
    code: '102935005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Staccato speech (finding)',
    code: '102936006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mimmation (finding)',
    code: '102937002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Difficulty writing (finding)',
    code: '102938007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hypermimia (finding)',
    code: '102939004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Reactive mastocytosis (disorder)',
    code: '10294000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Schizophrenic reaction (finding)',
    code: '102940002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Alethia (finding)',
    code: '102941003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Intelligence quotient low (finding)',
    code: '102942005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Personality change (finding)',
    code: '102943000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal sexual behavior (finding)',
    code: '102945007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal sexual behavior (finding)',
    code: '102946008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'High risk sexual behavior (finding)',
    code: '102947004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sexual inhibition (finding)',
    code: '102948009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Excessive masturbation (finding)',
    code: '102949001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abusive sexual relationship (finding)',
    code: '102950001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic viral hepatitis (disorder)',
    code: '10295004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abusive sexual relationship with partner (finding)',
    code: '102951002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abusive sexual relationship with wife (finding)',
    code: '102952009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abusive sexual relationship with husband (finding)',
    code: '102953004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Uses morning after pill method of contraception (finding)',
    code: '102954005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Contraception failure (finding)',
    code: '102955006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Non-consummation of sexual intercourse (finding)',
    code: '102956007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neurological finding (finding)',
    code: '102957003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Increased polarization of nerve cell and nerve fiber (finding)',
    code: '102958008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal evoked potential (finding)',
    code: '102961009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal evoked potential (finding)',
    code: '102962002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal cerebral evoked potential (finding)',
    code: '102964001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal cerebral evoked potential (finding)',
    code: '102965000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal visual evoked potential (finding)',
    code: '102967008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal visual evoked potential (finding)',
    code: '102968003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal auditory evoked potential (finding)',
    code: '102970007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal auditory evoked potential (finding)',
    code: '102971006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal brain stem auditory evoked potential (finding)',
    code: '102973009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal brain stem auditory evoked potential (finding)',
    code: '102974003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Victim, motorcycle rider in vehicular AND/OR traffic accident (finding)',
    code: '10298002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal reflex (finding)',
    code: '102981005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unequal reflexes (finding)',
    code: '102982003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Brisk tendon reflex (finding)',
    code: '102983008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Romberg test evokes stiff fall backward (finding)',
    code: '102984002',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Romberg test positive and direction of fall affected by head turn (finding)',
    code: '102985001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal straight line walking test (finding)',
    code: '102987009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal straight line walking test (finding)',
    code: '102988004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal eyes closed straight line walking test (finding)',
    code: '102990003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal eyes closed straight line walking test (finding)',
    code: '102991004',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Scissors gait on eyes closed straight line walking test (finding)',
    code: '102992006',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Deviation to one side on eyes closed straight line walking test (finding)',
    code: '102993001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Horizontal board test (finding)',
    code: '102994007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Positive horizontal board test (finding)',
    code: '102995008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cryesthesia (finding)',
    code: '102996009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Psychroalgia (finding)',
    code: '102997000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cold sensation absent (finding)',
    code: '102998005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cryalgesia (finding)',
    code: '102999002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Crymodynia (finding)',
    code: '103000001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lesion of skin of foot (disorder)',
    code: '103001000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Feeling feverish (finding)',
    code: '103001002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sensation of hot and cold (finding)',
    code: '103002009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Impaired body position sense (finding)',
    code: '103003004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Inadequate analgesia (finding)',
    code: '103005006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Headache due to external compression of head (finding)',
    code: '103007003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Postpartum headache (finding)',
    code: '103008008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Headache due to cold exposure (finding)',
    code: '103009000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Minor amount of residual staining, small fragments of stool and/or opaque liquid, but mucosa of colon segment seen well (finding)',
    code: '10301000132102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pneumonia caused by Serratia (disorder)',
    code: '10301000175109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Headache associated with sexual activity (finding)',
    code: '103010005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Tick-borne relapsing fever (disorder)',
    code: '10301003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Coronary arteriosclerosis in patient with history of previous myocardial infarction (situation)',
    code: '103011000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Benign exertional headache (finding)',
    code: '103011009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Analgesic overuse headache (finding)',
    code: '103012002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Smarting pain (finding)',
    code: '103013007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cervical nerve root pain (finding)',
    code: '103014001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Thoracic nerve root pain (finding)',
    code: '103015000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lumbosacral nerve root pain (finding)',
    code: '103016004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Postural dizziness (finding)',
    code: '103017008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Exertional dizziness (finding)',
    code: '103018003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bulging fontanelle (finding)',
    code: '103019006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Adrenarche (finding)',
    code: '103020000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic epiglottitis (disorder)',
    code: '103021000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Premature adrenarche (finding)',
    code: '103021001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fetal exposure to teratogenic substance (disorder)',
    code: '103031000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Closed fracture of naso orbital ethmoid (disorder)',
    code: '103061000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Humoral immune defect (finding)',
    code: '103075007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Immunodeficiency secondary to neoplasm (disorder)',
    code: '103077004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Immunodeficiency secondary to trauma (disorder)',
    code: '103078009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Immunodeficiency caused by corticosteroid (disorder)',
    code: '103079001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Immunodeficiency secondary to radiation therapy (disorder)',
    code: '103080003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Immunodeficiency secondary to chemotherapy (disorder)',
    code: '103081004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal host response (finding)',
    code: '103083001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Insufficient prenatal care (finding)',
    code: '1031000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Open fracture of C1-C4 level with incomplete spinal cord lesion (disorder)',
    code: '10310006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Open wound of trachea without complication (disorder)',
    code: '1031006',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Entire mucosa of colon segment seen well with no residual staining, small fragments of stool or opaque liquid (finding)',
    code: '10311000132100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hyponasality syndrome (disorder)',
    code: '10314002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Spinal enthesopathy (disorder)',
    code: '10317009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Apgar score 6 (finding)',
    code: '10318004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Kinking of colon co-occurrent and due to adhesions (disorder)',
    code: '10321000132105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Megakaryocyte production normal (finding)',
    code: '103210004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of mastoid (disorder)',
    code: '10321002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal megakaryocyte production (finding)',
    code: '103211000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Increased megakaryocyte production (finding)',
    code: '103212007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Decreased megakaryocyte production (finding)',
    code: '103213002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'P>2< phenotype (finding)',
    code: '103225004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Anomic aphasia (finding)',
    code: '10325006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal ocular motility (finding)',
    code: '103251002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal ocular motility (finding)',
    code: '103252009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Decreased corneal reflex (finding)',
    code: '103254005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Conjugate gaze (finding)',
    code: '103255006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lateral conjugate gaze (finding)',
    code: '103256007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal lateral conjugate gaze (finding)',
    code: '103257003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Vertical conjugate gaze (finding)',
    code: '103258008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal vertical conjugate gaze (finding)',
    code: '103259000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Downward conjugate gaze (finding)',
    code: '103260005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Upward conjugate gaze (finding)',
    code: '103261009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Staring (finding)',
    code: '103262002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Dysconjugate gaze (finding)',
    code: '103263007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Rolling of eyes (finding)',
    code: '103264001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Postbasic stare (finding)',
    code: '103265000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Eye muscle imbalance (finding)',
    code: '103266004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Circles under eyes (finding)',
    code: '103268003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Contact lens intolerance (finding)',
    code: '103269006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cocaine-induced mood disorder (disorder)',
    code: '10327003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fixed dilatation of pupil (finding)',
    code: '103271006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Impaired pupillary reflex (finding)',
    code: '103272004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Absence of direct light pupillary reflex (finding)',
    code: '103273009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal intraocular pressure with narrow cleft (finding)',
    code: '103274003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal intraocular pressure with imperforate pectinate (finding)',
    code: '103275002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Decreased hearing (finding)',
    code: '103276001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal acoustic reflex (finding)',
    code: '103277005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal acoustic reflex (finding)',
    code: '103278000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sensation of blocked ear (finding)',
    code: '103281005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Essential vertigo (finding)',
    code: '103282003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Positional vertigo (finding)',
    code: '103284002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Horizontal vertigo (finding)',
    code: '103286000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Vertigo on awakening (finding)',
    code: '103287009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Vertical vertigo (finding)',
    code: '103288004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Vertigo produced by neck hyperextension (finding)',
    code: '103289007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Paroxysmal vertigo (finding)',
    code: '103290003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Intermittent vertigo (finding)',
    code: '103291004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Constant vertigo (finding)',
    code: '103292006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Persistent postural perceptual dizziness (finding)',
    code: '103293001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Vertigo produced by neck pressure (finding)',
    code: '103294007',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Vertigo preceded by prolonged dental work or oral surgery (finding)',
    code: '103295008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Vertigo preceded by chewing (finding)',
    code: '103296009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Vertigo caused by drug (disorder)',
    code: '103297000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Severe vertigo (finding)',
    code: '103298005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Severe vertigo, acute onset (finding)',
    code: '103299002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Vertigo preceded by unsteady feeling (finding)',
    code: '103300005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Family history of inclusion body myositis (situation)',
    code: '10330001000004107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Thoracic arthritis (disorder)',
    code: '1033009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Vertigo, acute onset with vomiting and inability to stand (finding)',
    code: '103301009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Vertigo when trying to urinate (finding)',
    code: '103302002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Vertigo associated with recent change in eyeglasses (finding)',
    code: '103304001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Senile voice (finding)',
    code: '103305000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Tortuous colon (disorder)',
    code: '10331000132107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic right-sided heart failure (disorder)',
    code: '10335000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Major postpartum hemorrhage (disorder)',
    code: '1033591000000101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Incompetence of any valvular structure (finding)',
    code: '10337008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Isolated gonadotropin deficiency (disorder)',
    code: '10340008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mesenteric-portal fistula (disorder)',
    code: '1034003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Primary syphilis of tonsils (disorder)',
    code: '10345003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Solar urticaria (disorder)',
    code: '10347006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Multi-infarct dementia with delirium (disorder)',
    code: '10349009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Post polypectomy electrocoagulation syndrome (disorder)',
    code: '10351000132101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Suppurative tonsillitis (disorder)',
    code: '10351008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Melanoma carcinomatosis (disorder)',
    code: '103511000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: "Crow's feet (finding)",
    code: '10360000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Masklike facies (finding)',
    code: '103606006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acellular smear (finding)',
    code: '103607002',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Specimen less than optimal for diagnosis for lack of clinical information (finding)',
    code: '103608007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Specimen with abnormal presence of endometrial cells (finding)',
    code: '103609004',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Abnormal presence of endometrial cells in specimen from a menopausal woman (finding)',
    code: '103610009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Exhaustion delirium (finding)',
    code: '10361001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cirrhosis of liver due to hepatitis B (disorder)',
    code: '103611000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Specimen too thick for optimal interpretation (finding)',
    code: '103611008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Accessory spleen (disorder)',
    code: '10362008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Amianthium muscaetoxicum poisoning (disorder)',
    code: '10363003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal quantity of cells (finding)',
    code: '103631007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Right main coronary artery thrombosis (disorder)',
    code: '10365005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Open wound of pelvic region with complication (disorder)',
    code: '10366006',
    system: 'http://snomed.info/sct',
  },
  {
    display: "Restrictive strabismus due to Graves' disease (disorder)",
    code: '103681000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Gastric ulcer caused by Helicobacter pylori (disorder)',
    code: '103691000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Finding related to non-diagnostic procedure (finding)',
    code: '103694001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Rhythm from artificial pacing (finding)',
    code: '10370003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Patient status determination resolved (finding)',
    code: '103707005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Failed attempted procedure (situation)',
    code: '103709008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Middle ear mass visible on otoscopy (finding)',
    code: '103728001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Finding related to non-diagnostic medical procedure (finding)',
    code: '103732007',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Contracture of joint of finger co-occurrent and due to scar (disorder)',
    code: '103741000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Extrapulmonary subpleural pulmonary sequestration (disorder)',
    code: '10375008',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Paralytic syndrome of all four limbs as sequela of stroke (disorder)',
    code: '103761000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lipoma co-occurrent with spina bifida (disorder)',
    code: '103771000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Allergic bronchopulmonary mycosis (disorder)',
    code: '103781000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Musculoskeletal hypomobility (finding)',
    code: '10379002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disacchariduria (disorder)',
    code: '1038000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Crushing injury of finger (disorder)',
    code: '10380004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Infection caused by Pneumostrongylus (disorder)',
    code: '10381000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pain following radiation therapy (finding)',
    code: '103821000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Retinopathy as late effect of radiation (disorder)',
    code: '103841000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Poisoning caused by digoxin (disorder)',
    code: '10385009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Restrictive lung disease due to amyotrophic lateral sclerosis (disorder)',
    code: '103851000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Drug-induced autoimmune reaction (finding)',
    code: '10387001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Restrictive lung disease due to Parkinson disease (disorder)',
    code: '103871000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Acute gastrojejunal ulcer without hemorrhage AND without perforation but with obstruction (disorder)',
    code: '10389003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Injury of abdominal aorta (disorder)',
    code: '10392004',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Sampling of vagina for Papanicolaou smear after hysterectomy for malignant disease done (situation)',
    code: '103921000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hepatic coma due to hepatitis (disorder)',
    code: '103931000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Friedreich ataxia (disorder)',
    code: '10394003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Radiation diet (finding)',
    code: '10396001',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Proliferative retinopathy following surgery due to diabetes mellitus (disorder)',
    code: '103981000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hypochloremia (disorder)',
    code: '10399008',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Genital herpes simplex in mother complicating pregnancy (disorder)',
    code: '104011000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fungal sinusitis (disorder)',
    code: '104041000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lesch-Nyhan syndrome (disorder)',
    code: '10406007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Benign inverted papilloma (disorder)',
    code: '104081000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Partner relationship problem (finding)',
    code: '1041000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Patient declines smoking cessation information (situation)',
    code: '1041000175104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Atonic pupil (finding)',
    code: '104111000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Full thickness burn of lower leg (disorder)',
    code: '10416004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hemorrhage requiring transfusion (finding)',
    code: '10421000132103',
    system: 'http://snomed.info/sct',
  },
  {
    display: "Braun von Fernwald's sign (finding)",
    code: '10423003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute ulcer of skin (disorder)',
    code: '104231000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Thrombophlebitis of right peroneal vein (disorder)',
    code: '104346171000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized infection (disorder)',
    code: '10443009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lipomyelomeningocele (disorder)',
    code: '104431000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Aspergilloma (disorder)',
    code: '10446001',
    system: 'http://snomed.info/sct',
  },
  {
    display: "Ophthalmoplegia due to Graves' disease (disorder)",
    code: '104461000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Charcot arthropathy due to syphilis (disorder)',
    code: '104471000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Colonospasm (disorder)',
    code: '1045000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Double aortic arch (disorder)',
    code: '10451007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Keratopathy medicamentosa (disorder)',
    code: '104521000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of hand (disorder)',
    code: '10453005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ureteritis glandularis (disorder)',
    code: '1046004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nonvenomous insect bite with infection (disorder)',
    code: '10461000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Recurrent dislocation of hand (disorder)',
    code: '10464008',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Sampling of vagina for Papanicolaou smear after hysterectomy for dysplasia of cervix done (situation)',
    code: '104651000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Reactive arthritis of spine (disorder)',
    code: '104681000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic hypokalemia (disorder)',
    code: '10469003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Extravasation cyst of salivary gland (disorder)',
    code: '10480003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Colloid brain cyst (disorder)',
    code: '10481000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Drug induced central sleep apnea (disorder)',
    code: '104831000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Infection caused by Choanotaenia (disorder)',
    code: '10485008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Postpartum major depression in remission (disorder)',
    code: '104851000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Poisoning caused by medazepam (disorder)',
    code: '10486009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Complication of colostomy (disorder)',
    code: '10491000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Herpes zoster with meningitis (disorder)',
    code: '10491005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic kidney disease due to hypertension (disorder)',
    code: '104931000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ischemia of retina due to type 1 diabetes mellitus (disorder)',
    code: '104941000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Vitreous hemorrhage due to type 1 diabetes mellitus (disorder)',
    code: '104951000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ischemia of retina due to type 2 diabetes mellitus (disorder)',
    code: '104961000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Oligodendroglioma of cerebrum (disorder)',
    code: '104981000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pulmonary alveolar proteinosis (disorder)',
    code: '10501004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Removal of intrauterine contraceptive device done (situation)',
    code: '105011000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal voice (finding)',
    code: '10503001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Metastatic squamous cell carcinoma to lung (disorder)',
    code: '105041000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Metastatic undifferentiated large cell carcinoma to lung (disorder)',
    code: '105051000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute bronchitis (disorder)',
    code: '10509002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acquired penile adhesion (disorder)',
    code: '1051000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Patient advised about the health risks of second hand smoke (situation)',
    code: '1051000175102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hyperplasia of islet alpha cells with gastrin excess (disorder)',
    code: '1051005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Primary squamous cell carcinoma of thymus (disorder)',
    code: '105111000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Squamous cell carcinoma of vagina (disorder)',
    code: '105121000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Postoperative heterotopic calcification of skeletal muscle (disorder)',
    code: '105151000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Pathological external resorption of tooth due to impacted tooth (disorder)',
    code: '105171000220101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acquired central alveolar hypoventilation (disorder)',
    code: '105191000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Common peroneal neuropathy at the fibular head (disorder)',
    code: '10521000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Mixed conductive and sensorineural hearing loss of left ear with normal hearing on right side (disorder)',
    code: '1052205008',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Mixed conductive and sensorineural hearing loss of right ear with normal hearing on left side (disorder)',
    code: '1052206009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Phlebosclerosis of intrahepatic vein (disorder)',
    code: '1052210007',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Hypoactive labyrinthine dysfunction of right inner ear (disorder)',
    code: '1052223000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hypoactive labyrinthine dysfunction of left inner ear (disorder)',
    code: '1052224006',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Hyperactive labyrinthine dysfunction of right inner ear (disorder)',
    code: '1052225007',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Hyperactive labyrinthine dysfunction of left inner ear (disorder)',
    code: '1052226008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Suppression of visual signal of eye (finding)',
    code: '1052230006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Suppression of visual signal of left eye (finding)',
    code: '1052231005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Suppression of visual signal of right eye (finding)',
    code: '1052232003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Constriction of visual field of left eye (finding)',
    code: '1052233008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Constriction of visual field of right eye (finding)',
    code: '1052234002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Paralysis of left side of tongue (disorder)',
    code: '1052237009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Paralysis of right side of tongue (disorder)',
    code: '1052238004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Paresis of left vocal cord (disorder)',
    code: '1052239007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Paresis of right vocal cord (disorder)',
    code: '1052240009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hemiparesis of left side of face (disorder)',
    code: '1052243006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hemiparesis of right side of face (disorder)',
    code: '1052244000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Enlargement of left tonsil (finding)',
    code: '1052245004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Enlargement of right tonsil (finding)',
    code: '1052246003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acquired cryptorchidism (disorder)',
    code: '1052255000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acquired cryptorchidism of left testis (disorder)',
    code: '1052256004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acquired cryptorchidism of right testis (disorder)',
    code: '1052257008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Restless leg syndrome due to uremia (disorder)',
    code: '1052282008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Restless leg syndrome due to iron deficiency anemia (disorder)',
    code: '1052283003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Restless leg syndrome in pregnancy (disorder)',
    code: '1052284009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Propriospinal myoclonus at sleep onset in infancy (finding)',
    code: '1052285005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acquired partial absence of breast (disorder)',
    code: '1052286006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Myocarditis caused by Neisseria gonorrhoeae (disorder)',
    code: '1052287002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Gastritis due to secondary syphilis (disorder)',
    code: '1052296002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Rupture of flexor tendon of hand (disorder)',
    code: '1052298001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Rupture of flexor tendon of wrist (disorder)',
    code: '1052299009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Proctocolitis due to secondary syphilis (disorder)',
    code: '1052300001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Agminated lentiginosis (disorder)',
    code: '1052318003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Secondary syphilis of nervous system (disorder)',
    code: '1052319006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Secondary syphilis of digestive system (disorder)',
    code: '1052321001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Secondary syphilis of eye (disorder)',
    code: '1052322008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Secondary syphilis of musculoskeletal system (disorder)',
    code: '1052323003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Parasitic infection of female genital tract caused by Trichomonas vaginalis (disorder)',
    code: '1052324009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Exacerbation of psoriasis due to human immunodeficiency virus infection (disorder)',
    code: '1052325005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Rachischisis partialis (disorder)',
    code: '1052326006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bowel-associated dermatosis-arthritis syndrome (disorder)',
    code: '1052354004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sclerosing adenosis of breast (disorder)',
    code: '105261000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lupus vulgaris (disorder)',
    code: '10528009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Primary degenerative dementia of the Alzheimer type, presenile onset, with depression (disorder)',
    code: '10532003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Horizontal alveolar bone loss surrounding dental implant (disorder)',
    code: '105331000220102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Endometriosis of broad ligament (disorder)',
    code: '10535001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Diabetes mellitus due to pancreatic injury (disorder)',
    code: '105401000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Social withdrawal (finding)',
    code: '105411000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Social exclusion (finding)',
    code: '105412007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acculturation difficulty (finding)',
    code: '105413002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Personal relationship breakdown (finding)',
    code: '105414008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Broken engagement (finding)',
    code: '105415009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Breakup of romance (finding)',
    code: '105416005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disappearance of partner (finding)',
    code: '105417001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Dissatisfaction with school environment (finding)',
    code: '105418006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Academic underachievement (finding)',
    code: '105419003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      "Early onset Alzheimer's disease with behavioral disturbance (disorder)",
    code: '105421000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Poor education (finding)',
    code: '105422001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Good education (finding)',
    code: '105423006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Adoption, life event (finding)',
    code: '105430000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Child given for adoption (finding)',
    code: '105432008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Homosexual marriage (finding)',
    code: '105433003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Homosexual marriage, male (finding)',
    code: '105434009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Homosexual marriage, female (finding)',
    code: '105435005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sickness in the family (finding)',
    code: '105448009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Amnestic disorder associated with general medical condition (disorder)',
    code: '105451000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Failed exams (finding)',
    code: '105477005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fighting at school (finding)',
    code: '105478000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Truancy (finding)',
    code: '105479008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Procedure declined by patient (situation)',
    code: '105480006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Refusing food (finding)',
    code: '105481005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Occupational environmental problem (finding)',
    code: '105482003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Overprotective parent (finding)',
    code: '105483008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Midlife crisis, life event (finding)',
    code: '105484002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Family tension (finding)',
    code: '105485001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Difficulty with partner (finding)',
    code: '105486000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Marital reconciliation, life event (finding)',
    code: '105487009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Difficulty with parents (finding)',
    code: '105488004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Difficulty with in-laws (finding)',
    code: '105489007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Overwork (finding)',
    code: '105490003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Primary hyposomnia (disorder)',
    code: '105491000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Dissatisfied with job (finding)',
    code: '105491004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Career changed (finding)',
    code: '105492006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Retired, life event (finding)',
    code: '105493001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Early retirement, life event (finding)',
    code: '105494007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Forced retirement, life event (finding)',
    code: '105495008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Dismissed from job (finding)',
    code: '105496009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Threat of dismissal (finding)',
    code: '105497000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Voluntarily redundant (finding)',
    code: '105498005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Convalescence (finding)',
    code: '105499002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Stenosis of precerebral artery (disorder)',
    code: '1055001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Dependence on enabling machine or device (finding)',
    code: '105501005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Dependence on renal dialysis (finding)',
    code: '105502003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Dependence on wheelchair (finding)',
    code: '105503008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Dependence on walking stick (finding)',
    code: '105504002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Dependence on seeing eye dog (finding)',
    code: '105506000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Patient dependence on care provider (finding)',
    code: '105507009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Arrested by police (finding)',
    code: '105508004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'On probation (finding)',
    code: '105509007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Social discord (finding)',
    code: '105510002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Discord with lodgers (finding)',
    code: '105511003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Discord with landlord (finding)',
    code: '105512005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Problem with neighbors (finding)',
    code: '105513000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Discord in the workplace (finding)',
    code: '105514006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Discord in school (finding)',
    code: '105515007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Discord with teachers (finding)',
    code: '105516008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Discord with students (finding)',
    code: '105517004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Discord with classmates (finding)',
    code: '105518009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Discord with social worker (finding)',
    code: '105519001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Discord with counselor (finding)',
    code: '105520007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Calcification of lung (disorder)',
    code: '10552002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Organic mixed sleep disorder (disorder)',
    code: '105521000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Discord with probation officer (finding)',
    code: '105521006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Food fads (finding)',
    code: '105522004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Gambling (finding)',
    code: '105523009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disturbance in life pattern associated with travel (finding)',
    code: '105524003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Foreign travel problem (finding)',
    code: '105525002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Homeless family (finding)',
    code: '105526001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Living in residence with poor sanitation (finding)',
    code: '105527005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lives alone (finding)',
    code: '105529008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Gonarthrosis of left knee due to and following trauma (disorder)',
    code: '1055297002',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Gonarthrosis of right knee due to and following trauma (disorder)',
    code: '1055298007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Living in residential institution (finding)',
    code: '105530003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Poisoning caused by vitamin D (disorder)',
    code: '10553007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Traumatic amputation of left lower limb through knee (disorder)',
    code: '1055305002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Traumatic amputation of right lower limb through knee (disorder)',
    code: '1055306001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Housing unsatisfactory (finding)',
    code: '105531004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Traumatic amputation through right elbow (disorder)',
    code: '1055311004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Traumatic amputation through left elbow (disorder)',
    code: '1055312006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Overcrowded in house (finding)',
    code: '105532006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lack of heat in house (finding)',
    code: '105535008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Living in housing without electricity (finding)',
    code: '105536009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Living in housing with technical defects (finding)',
    code: '105537000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Non-smoker for personal reasons (finding)',
    code: '105539002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Non-smoker for religious reasons (finding)',
    code: '105540000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Non-smoker for medical reasons (finding)',
    code: '105541001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Current non-drinker of alcohol (finding)',
    code: '105542008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Non-drinker for personal reasons (finding)',
    code: '105543003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Non-drinker for religious reasons (finding)',
    code: '105544009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Non-drinker for medical reasons (finding)',
    code: '105545005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Occasional drug abuser (finding)',
    code: '105546006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abuses volatile solvents (finding)',
    code: '105549004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Misused volatile solvents in past (finding)',
    code: '105550004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'In prison (finding)',
    code: '105568001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Spiritual problem (finding)',
    code: '105571009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Spiritual strength (finding)',
    code: '105572002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Spiritual weakness (finding)',
    code: '105573007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bankruptcy (finding)',
    code: '105577008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Electrolyte imbalance (disorder)',
    code: '105593004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Blindness AND/OR vision impairment level (disorder)',
    code: '105597003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Anemia related to disturbed deoxyribonucleic acid synthesis (disorder)',
    code: '105599000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disorder of neutrophils (disorder)',
    code: '105600002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Quantitative disorder of neutrophils (disorder)',
    code: '105601003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Quantitative abnormality of granulocytes (disorder)',
    code: '105602005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Non-malignant lymphocyte AND/OR plasma cell disorder (disorder)',
    code: '105603000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Deficiency of naturally occurring coagulation factor inhibitor (disorder)',
    code: '105604006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Injury of musculoskeletal system (disorder)',
    code: '105606008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Injury of diaphragm during surgery (disorder)',
    code: '10561000087108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sprain of ligament of joint (disorder)',
    code: '105611005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Injury of internal organ (disorder)',
    code: '105612003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Open wound of trunk (disorder)',
    code: '105615001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Open wound of limb (disorder)',
    code: '105616000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Effect of foreign body (disorder)',
    code: '105620001',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Malignant hypertension complicating AND/OR reason for care during childbirth (disorder)',
    code: '10562009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chlamydial infection (disorder)',
    code: '105629000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Poxviridae (disorder)',
    code: '105630005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Paramyxoviridae (disorder)',
    code: '105631009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Bunyaviridae (disorder)',
    code: '105632002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Picornaviridae (disorder)',
    code: '105633007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Reoviridae (disorder)',
    code: '105634001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Retroviridae (disorder)',
    code: '105635000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Endamoebidae (disorder)',
    code: '105637008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Balantidiidae (disorder)',
    code: '105638003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Chilomastigidae (disorder)',
    code: '105639006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Monocercomonadidae (disorder)',
    code: '105640008',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Severe hereditary spherocytosis due to combined deficiency of spectrin AND ankyrin (disorder)',
    code: '10564005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Eimeriidae (disorder)',
    code: '105641007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Sarcocystidae (disorder)',
    code: '105642000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Retortamonadidae (disorder)',
    code: '105643005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Hexamitidae (disorder)',
    code: '105644004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Acanthamoebidae (disorder)',
    code: '105645003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Hartmannellidae (disorder)',
    code: '105646002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Vahlkampfiidae (disorder)',
    code: '105647006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Trichomonadidae (disorder)',
    code: '105648001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Plasmodiidae (disorder)',
    code: '105649009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Adeleidae (disorder)',
    code: '105650009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Recurrent kidney stone (disorder)',
    code: '1056501000112102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'History of pre-eclampsia (situation)',
    code: '105651000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Haemogregarinidae (disorder)',
    code: '105651008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Babesiidae (disorder)',
    code: '105652001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Theileriidae (disorder)',
    code: '105653006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Cryptosporidiidae (disorder)',
    code: '105654000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Trypanosomatidae (disorder)',
    code: '105655004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Enteromonadidae (disorder)',
    code: '105656003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Nosematidae (disorder)',
    code: '105657007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by ciliate protozoa (disorder)',
    code: '105658002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Myxozoa (disorder)',
    code: '105659005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Otosclerosis involving round window (disorder)',
    code: '10566007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Problem with aged spouse or partner (finding)',
    code: '105661000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Schistosomatidae (disorder)',
    code: '105661001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Fasciolidae (disorder)',
    code: '105662008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Diplostomatidae (disorder)',
    code: '105663003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Echinostomatidae (disorder)',
    code: '105664009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Strigeidae (disorder)',
    code: '105665005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Paramphistomatidae (disorder)',
    code: '105666006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Gastrodiscoides (disorder)',
    code: '105667002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Dicrocoeliidae (disorder)',
    code: '105668007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Plagiorchiidae (disorder)',
    code: '105669004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Four X syndrome (disorder)',
    code: '10567003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Cyclocoelidae (disorder)',
    code: '105671004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Opisthorchiidae (disorder)',
    code: '105672006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Notocotylidae (disorder)',
    code: '105673001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Heterophyidae (disorder)',
    code: '105674007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Dactylogyridae (disorder)',
    code: '105675008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Sanguinicolidae (disorder)',
    code: '105676009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Diphyllobothriidae (disorder)',
    code: '105679002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by cestodes of order Cyclophyllidea (disorder)',
    code: '105680004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Open fracture of eight OR more ribs (disorder)',
    code: '10568008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Dilepididae (disorder)',
    code: '105681000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Hymenolepididae (disorder)',
    code: '105682007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Taeniidae (disorder)',
    code: '105683002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Infection caused by tapeworm larvae (disorder)',
    code: '105684008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Anoplocephalidae (disorder)',
    code: '105686005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Davaineidae (disorder)',
    code: '105688006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Mesocestoididae (disorder)',
    code: '105689003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Linstowiidae (disorder)',
    code: '105690007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by cestodes of order Caryophyllidea (disorder)',
    code: '105691006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Caryophyllaeidae (disorder)',
    code: '105692004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by superfamily Ancylostomatoidea (disorder)',
    code: '105694003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by superfamily Protostrongyloidea (disorder)',
    code: '105695002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by superfamily Ascaridoidea (disorder)',
    code: '105696001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by superfamily Heterocheiloidea (disorder)',
    code: '105697005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by superfamily Spiruroidea (disorder)',
    code: '105698000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by superfamily Thelazioidea (disorder)',
    code: '105699008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by superfamily Trichinelloidea (disorder)',
    code: '105700009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by superfamily Dioctophymatoidea (disorder)',
    code: '105701008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by superfamily Oxyuroidea (disorder)',
    code: '105702001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by superfamily Syphacioidea (disorder)',
    code: '105703006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by superfamily Strongyloidea (disorder)',
    code: '105704000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by superfamily Heterakoidea (disorder)',
    code: '105705004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by superfamily Filarioidea (disorder)',
    code: '105706003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by superfamily Dracunculoidea (disorder)',
    code: '105707007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by superfamily Trichostrongyloidea (disorder)',
    code: '105708002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by superfamily Rhabdiasoidea (disorder)',
    code: '105709005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cotyledon species poisoning (disorder)',
    code: '10571000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by superfamily Rhabditoidea (disorder)',
    code: '105710000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by superfamily Metastrongyloidea (disorder)',
    code: '105711001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Acanthocephala (disorder)',
    code: '105713003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease caused by Annelida (disorder)',
    code: '105714009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Infectious disease caused by plant (disorder)',
    code: '105715005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Infection caused by chordate (disorder)',
    code: '105716006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Body disability AND/OR failure state (finding)',
    code: '105719004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Finding related to general body function (finding)',
    code: '105720005',
    system: 'http://snomed.info/sct',
  },
  {
    display: '13q partial trisomy syndrome (disorder)',
    code: '10572007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'General problem AND/OR complaint (finding)',
    code: '105721009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Body weight AND/OR growth problem (finding)',
    code: '105722002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Body temperature finding (finding)',
    code: '105723007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease related state (finding)',
    code: '105724001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronobiology AND/OR biorhythm finding (finding)',
    code: '105725000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Age AND/OR growth finding (finding)',
    code: '105726004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nursing observation AND/OR diagnosis (finding)',
    code: '105728003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Finding of health perception, health management pattern (finding)',
    code: '105729006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Infection of amniotic cavity (disorder)',
    code: '10573002',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Open fracture of vertebral column with spinal cord injury (disorder)',
    code: '10575009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Immunity to varicella determined by serology (finding)',
    code: '105781000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Finding of odor of urine (finding)',
    code: '10579003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Immunity to rubella determined by serology (finding)',
    code: '105791000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Immunity to hepatitis A determined by serology (finding)',
    code: '105801000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Injury of ureter during surgery (disorder)',
    code: '10581000087102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Immunity to hepatitis B determined by serology (finding)',
    code: '105811000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Finding related to substance type (finding)',
    code: '105812000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Immunity to mumps determined by serology (finding)',
    code: '105821000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Immunity to measles determined by serology (finding)',
    code: '105841000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Occupation-related stress disorder (disorder)',
    code: '10586006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Thickening of lens capsule (disorder)',
    code: '105881000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Genetic transcription (finding)',
    code: '10589004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Opisthorchiasis (disorder)',
    code: '1059007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pleural effusion caused by bacterial infection (disorder)',
    code: '105901000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Posttransfusion state (finding)',
    code: '10593005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Herpes zoster vaccination given (situation)',
    code: '105941000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sodium, potassium AND/OR calcium diet (finding)',
    code: '105955002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Infant diet AND/OR formula (finding)',
    code: '105956001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Liquid AND/OR soft diet (finding)',
    code: '105957005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Test AND/OR disease related diet (finding)',
    code: '105958000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Peripheral vascular complication (disorder)',
    code: '10596002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disorder of connective tissue (disorder)',
    code: '105969002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disorder of bursa (disorder)',
    code: '10597006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of subcutaneous tissue of back (finding)',
    code: '105971000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disorder of maxillary sinus (disorder)',
    code: '105972009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disorder of frontal sinus (disorder)',
    code: '105973004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disorder of ethmoidal sinus (disorder)',
    code: '105974005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disorder of sphenoidal sinus (disorder)',
    code: '105975006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Non-infectious pneumonia (disorder)',
    code: '105977003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disease affecting entire cardiovascular system (disorder)',
    code: '105980002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disorder of cardiac function (disorder)',
    code: '105981003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Non-infectious disorder of lymphatics (disorder)',
    code: '105983000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Osteochondrodysplasia syndrome (disorder)',
    code: '105985007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital skeletal dysplasia (disorder)',
    code: '105986008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Malformation sequence (disorder)',
    code: '105989001',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Congenital anomaly of tongue, salivary gland AND/OR pharynx (disorder)',
    code: '105992002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disorder of teeth AND/OR supporting structures (disorder)',
    code: '105995000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Disorder of biliary tract (disorder)',
    code: '105997008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acquired renal cystic disease (disorder)',
    code: '105999006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Metabolic renal disease (disorder)',
    code: '106000008',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Chronic obstructive lung disease co-occurrent with acute bronchitis (disorder)',
    code: '106001000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Disorder associated with menstruation AND/OR menopause (disorder)',
    code: '106002000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hemorrhagic complication of pregnancy (disorder)',
    code: '106004004',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Hypertension AND/OR vomiting complicating pregnancy childbirth AND/OR puerperium (disorder)',
    code: '106005003',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Delivery AND/OR maternal condition affecting management (disorder)',
    code: '106008001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fetal condition affecting obstetrical care of mother (disorder)',
    code: '106009009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pelvic dystocia AND/OR uterine disorder (disorder)',
    code: '106010004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pain in lower limb (finding)',
    code: '10601006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mental disorder AND/OR culture bound syndrome (disorder)',
    code: '106015009',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Hereditary degenerative disease of central nervous system (disorder)',
    code: '106018006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Finding of elimination pattern (finding)',
    code: '106019003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Finding of activity exercise pattern (finding)',
    code: '106020009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Diphonia (finding)',
    code: '10602004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Multi-infarct dementia due to atherosclerosis (disorder)',
    code: '106021000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Finding of sleep rest pattern (finding)',
    code: '106021008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Finding of cognitive perceptual pattern (finding)',
    code: '106022001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Finding of self perception, self concept pattern (finding)',
    code: '106023006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Finding of role relationship pattern (finding)',
    code: '106024000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Musculoskeletal finding (finding)',
    code: '106028002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Muscle finding (finding)',
    code: '106030000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Respiratory finding (finding)',
    code: '106048009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Respiratory rate AND/OR rhythm finding (finding)',
    code: '106049001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Respiratory movement finding (finding)',
    code: '106050001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Finding related to respiratory sounds (finding)',
    code: '106051002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nervous system respiratory control finding (finding)',
    code: '106059000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Diaphragmatic finding (finding)',
    code: '106062002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cardiovascular finding (finding)',
    code: '106063007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cardiovascular pressure AND/OR pulse finding (finding)',
    code: '106064001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cardiac rhythm AND/OR rate finding (finding)',
    code: '106066004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sinus node rhythm (finding)',
    code: '106067008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Atrial rhythm (finding)',
    code: '106068003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cardiac auscultation finding (finding)',
    code: '106070007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Secondary hypothalamic insufficiency (disorder)',
    code: '106071000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Skin finding (finding)',
    code: '106076001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Integumentary system finding (finding)',
    code: '106077005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Polytetrafluoroethylene poisoning (disorder)',
    code: '10608000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Metabolic finding (finding)',
    code: '106089007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Urinary system finding (finding)',
    code: '106098005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Lower urinary tract finding (finding)',
    code: '106100005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Excess panniculus of abdomen (finding)',
    code: '1061000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abnormal urinary product (finding)',
    code: '106102002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Female reproductive finding (finding)',
    code: '106104001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Male reproductive finding (finding)',
    code: '106106004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Number of previous induced termination of pregnancy (finding)',
    code: '106109006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Clinical sign related to pregnancy (finding)',
    code: '106111002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fetal finding (finding)',
    code: '106112009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Motivation finding (finding)',
    code: '106121005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Self related adjustment AND/OR maladjustment (finding)',
    code: '106122003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Emotional state finding (finding)',
    code: '106126000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Motor function behavior finding (finding)',
    code: '106129007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute berylliosis (disorder)',
    code: '10613001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mood finding (finding)',
    code: '106131003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Speech finding (finding)',
    code: '106132005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Language finding (finding)',
    code: '106133000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Perception AND/OR perception disturbance (finding)',
    code: '106134006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Memory finding (finding)',
    code: '106136008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Intelligence finding (finding)',
    code: '106137004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Learning finding (finding)',
    code: '106138009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Personality AND/OR personality trait (finding)',
    code: '106139001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nutrition deficiency due to a particular kind of food (disorder)',
    code: '10614007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sexuality related problem (finding)',
    code: '106143002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nerve cell AND/OR nerve fiber finding (finding)',
    code: '106144008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Motor nervous system finding (finding)',
    code: '106145009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Reflex finding (finding)',
    code: '106146005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sensory nervous system finding (finding)',
    code: '106147001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cranial nerve finding (finding)',
    code: '106150003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Finding of sense of smell (finding)',
    code: '106151004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Second cranial nerve finding (finding)',
    code: '106152006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Third cranial nerve finding (finding)',
    code: '106153001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fourth cranial nerve finding (finding)',
    code: '106154007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fifth cranial nerve finding (finding)',
    code: '106155008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Sixth cranial nerve finding (finding)',
    code: '106156009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Seventh cranial nerve finding (finding)',
    code: '106157000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Eighth cranial nerve finding (finding)',
    code: '106158005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ninth cranial nerve finding (finding)',
    code: '106159002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Tenth cranial nerve finding (finding)',
    code: '106160007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Eleventh cranial nerve finding (finding)',
    code: '106161006',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Twelfth cranial nerve finding (finding)',
    code: '106162004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Finding related to cerebral function (finding)',
    code: '106165002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Consciousness related finding (finding)',
    code: '106167005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Finding related to sleep (finding)',
    code: '106168000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Aphasia, agnosia, dyslexia AND/OR apraxia (finding)',
    code: '106169008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Dyslexia AND/OR speech dysfunction (finding)',
    code: '106170009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Endocrine finding (finding)',
    code: '106176003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Immune system finding (finding)',
    code: '106182000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Megaloblastic anemia due to poor nutrition (disorder)',
    code: '10619002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Autoimmune AND/OR graft reaction (finding)',
    code: '106198007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Graft reaction AND/OR rejection (finding)',
    code: '106199004',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hematopoietic system finding (finding)',
    code: '106200001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Genetic finding (finding)',
    code: '106221001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Fibrous dysplasia of bone (disorder)',
    code: '10623005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bilateral paralytic syndrome as late effect of stroke (disorder)',
    code: '106241000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Meibomianitis of left upper and lower eyelid (disorder)',
    code: '10624121000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Meibomianitis of right upper and lower eyelid (disorder)',
    code: '10624161000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Eczematous dermatitis of left upper and lower eyelids (disorder)',
    code: '10624201000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Eczematous dermatitis of right upper and lower eyelids (disorder)',
    code: '10624241000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Eczematous dermatitis of bilateral eyelids (disorder)',
    code: '10624281000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Blepharitis of left upper and lower eyelids (disorder)',
    code: '10624321000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Blepharitis of right upper and lower eyelids (disorder)',
    code: '10624361000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Family history of stroke due to aneurysm (situation)',
    code: '10624401000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Family history of aneurysm of blood vessel of brain (situation)',
    code: '10624451000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'History of infection due to vancomycin resistant enterococcus (situation)',
    code: '10624491000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'History of infection caused by multiple drug resistant bacterium (situation)',
    code: '10624531000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ossification of anterior longitudinal ligament of spine (disorder)',
    code: '10624571000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Stress fracture of lateral condyle of right femur (disorder)',
    code: '10624611000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Stress fracture of lateral condyle of left femur (disorder)',
    code: '10624651000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital skin contracture (disorder)',
    code: '10624871000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Otitis media caused by H1N1 influenza (disorder)',
    code: '10624911000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Otitis media caused by influenza (disorder)',
    code: '10624951000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchopneumonia caused by Achromobacter (disorder)',
    code: '10624991000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchopneumonia caused by anaerobic bacteria (disorder)',
    code: '10625031000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchopneumonia caused by bacteria (disorder)',
    code: '10625071000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchopneumonia caused by Escherichia coli (disorder)',
    code: '10625111000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchopneumonia caused by Group A Streptococcus (disorder)',
    code: '10625151000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchopneumonia caused by Group B Streptococcus (disorder)',
    code: '10625191000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchopneumonia caused by Haemophilus influenzae (disorder)',
    code: '10625231000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchopneumonia caused by Human metapneumovirus (disorder)',
    code: '10625271000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchopneumonia caused by Klebsiella pneumoniae (disorder)',
    code: '10625311000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Bronchopneumonia caused by methicillin resistant Staphylococcus aureus (disorder)',
    code: '10625351000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Bronchopneumonia caused by methicillin susceptible Staphylococcus aureus (disorder)',
    code: '10625391000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchopneumonia caused by Mycoplasma pneumoniae (disorder)',
    code: '10625431000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchopneumonia caused by Proteus mirabilis (disorder)',
    code: '10625471000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchopneumonia caused by Pseudomonas (disorder)',
    code: '10625511000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Bronchopneumonia caused by respiratory syncytial virus (disorder)',
    code: '10625551000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchopneumonia caused by Staphylococcus (disorder)',
    code: '10625591000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchopneumonia caused by Staphylococcus aureus (disorder)',
    code: '10625631000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchopneumonia caused by Streptococcus (disorder)',
    code: '10625671000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchopneumonia caused by Streptococcus pneumoniae (disorder)',
    code: '10625711000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchopneumonia due to virus (disorder)',
    code: '10625751000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchitis caused by chemical (disorder)',
    code: '10625791000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chemical pneumonitis caused by anesthesia (disorder)',
    code: '10625831000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pneumoconiosis caused by organic dust (disorder)',
    code: '10625871000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of left axilla (disorder)',
    code: '10625991000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Multifocal premature ventricular complexes (disorder)',
    code: '10626002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of right axilla (disorder)',
    code: '10626031000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of finger of left hand (disorder)',
    code: '10626071000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of finger of right hand (disorder)',
    code: '10626111000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of left shoulder (disorder)',
    code: '10626151000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of right shoulder (disorder)',
    code: '10626191000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of left forearm (disorder)',
    code: '10626231000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of right forearm (disorder)',
    code: '10626271000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of thumb of left hand (disorder)',
    code: '10626391000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of thumb of right hand (disorder)',
    code: '10626431000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of finger of right hand (disorder)',
    code: '10626471000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of finger of left hand (disorder)',
    code: '10626511000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of left hand (disorder)',
    code: '10626551000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of right hand (disorder)',
    code: '10626591000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of right upper limb (disorder)',
    code: '10626631000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of left upper limb (disorder)',
    code: '10626671000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of left knee (disorder)',
    code: '10626711000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of right knee (disorder)',
    code: '10626751000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of left thigh (disorder)',
    code: '10626791000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of right thigh (disorder)',
    code: '10626831000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of left hip (disorder)',
    code: '10626871000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of right hip (disorder)',
    code: '10626911000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of toe of left foot (disorder)',
    code: '10626951000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of toe of right foot (disorder)',
    code: '10626991000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of left foot (disorder)',
    code: '10627031000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of right foot (disorder)',
    code: '10627071000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of left ankle (disorder)',
    code: '10627111000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of right ankle (disorder)',
    code: '10627151000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of left lower limb (disorder)',
    code: '10627191000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of right lower limb (disorder)',
    code: '10627231000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of left forearm (disorder)',
    code: '10627271000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of right forearm (disorder)',
    code: '10627311000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of left axilla (disorder)',
    code: '10627351000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of right axilla (disorder)',
    code: '10627391000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of left shoulder (disorder)',
    code: '10627431000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of right shoulder (disorder)',
    code: '10627471000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of left thumb (disorder)',
    code: '10627511000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of right thumb (disorder)',
    code: '10627551000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of left hand (disorder)',
    code: '10627591000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of right hand (disorder)',
    code: '10627631000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of right wrist (disorder)',
    code: '10627671000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of left wrist (disorder)',
    code: '10627711000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of left upper limb (disorder)',
    code: '10627751000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Furuncle of right upper limb (disorder)',
    code: '10627791000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of right thigh (disorder)',
    code: '10627831000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of left thigh (disorder)',
    code: '10627871000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of toe of left foot (disorder)',
    code: '10627911000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of toe of right foot (disorder)',
    code: '10627951000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of left knee (disorder)',
    code: '10627991000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of right knee (disorder)',
    code: '10628031000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of left foot (disorder)',
    code: '10628071000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Pre-existing diabetes mellitus in mother complicating childbirth (disorder)',
    code: '106281000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of right foot (disorder)',
    code: '10628111000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of left lower limb (disorder)',
    code: '10628151000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Carbuncle of right lower limb (disorder)',
    code: '10628191000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Paronychia of toe of left foot (disorder)',
    code: '10628231000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Paronychia of toe of right foot (disorder)',
    code: '10628271000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Paronychia of finger of left hand (disorder)',
    code: '10628311000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Paronychia of finger of right hand (disorder)',
    code: '10628351000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Paronychia of left thumb (disorder)',
    code: '10628391000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Paronychia of right thumb (disorder)',
    code: '10628431000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Paronychia of thumb (disorder)',
    code: '10628471000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Irritant contact dermatitis caused by detergent (disorder)',
    code: '10628511000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Irritant contact dermatitis caused by rubber (disorder)',
    code: '10628551000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Irritant contact dermatitis caused by chemical (disorder)',
    code: '10628591000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Irritant contact dermatitis caused by metal (disorder)',
    code: '10628631000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Allergic contact dermatitis caused by plant material (disorder)',
    code: '10628711000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Allergic contact dermatitis caused by pollen (disorder)',
    code: '10628751000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Gastroenteritis caused by influenza (disorder)',
    code: '10628871000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Gastroenteritis caused by Influenza A virus (disorder)',
    code: '10628911000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphadenitis of face (disorder)',
    code: '10628951000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphadenitis of head (disorder)',
    code: '10628991000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Tetany (disorder)',
    code: '10629009',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphadenitis of neck (disorder)',
    code: '10629031000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphadenitis of axilla (disorder)',
    code: '10629071000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphadenitis of inguinal lymph nodes (disorder)',
    code: '10629111000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Bronchiolitis caused by influenza virus (disorder)',
    code: '10629191000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Recurrent acute streptococcal tonsillitis (disorder)',
    code: '10629231000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute tonsillitis caused by Streptococcus (disorder)',
    code: '10629271000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Psoriatic arthritis mutilans (disorder)',
    code: '10629311000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Myocarditis caused by Influenza A virus (disorder)',
    code: '10629351000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Allergic rhinitis caused by feathers (disorder)',
    code: '10629391000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Allergic rhinitis caused by insects (disorder)',
    code: '10629431000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Allergic rhinitis caused by mold (disorder)',
    code: '10629471000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Rhinitis of pregnancy (disorder)',
    code: '10629511000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Recurrent acute sphenoid sinusitis (disorder)',
    code: '10629551000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Recurrent acute frontal sinusitis (disorder)',
    code: '10629591000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Recurrent acute pansinusitis (disorder)',
    code: '10629631000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Recurrent acute maxillary sinusitis (disorder)',
    code: '10629671000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Recurrent acute ethmoid sinusitis (disorder)',
    code: '10629711000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of left axilla (disorder)',
    code: '10629791000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of right axilla (disorder)',
    code: '10629831000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of left hip (disorder)',
    code: '10629871000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of right hip (disorder)',
    code: '10629911000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of right ankle region (disorder)',
    code: '10630111000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of left ankle region (disorder)',
    code: '10630151000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of left foot (disorder)',
    code: '10630271000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of right foot (disorder)',
    code: '10630311000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of left axilla (disorder)',
    code: '10630351000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of right axilla (disorder)',
    code: '10630391000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of left forearm (disorder)',
    code: '10630431000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of right forearm (disorder)',
    code: '10630471000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of finger (disorder)',
    code: '10630511000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of finger of left hand (disorder)',
    code: '10630591000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of finger of right hand (disorder)',
    code: '10630631000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of right hand (disorder)',
    code: '10630671000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of left hand (disorder)',
    code: '10630791000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of left thigh (disorder)',
    code: '10630831000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of right thigh (disorder)',
    code: '10630871000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Trilobed left lung (disorder)',
    code: '10631000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Female genital tract infection due to complete miscarriage (disorder)',
    code: '10631000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of left lower limb (disorder)',
    code: '10631111000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of left upper limb (disorder)',
    code: '10631151000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of right upper limb (disorder)',
    code: '10631191000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute lymphangitis of right lower limb (disorder)',
    code: '10631231000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of skin of left knee (disorder)',
    code: '10631271000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of skin of left ankle (disorder)',
    code: '10631311000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of skin of left hip (disorder)',
    code: '10631351000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of skin of left shoulder (disorder)',
    code: '10631391000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of skin of left wrist (disorder)',
    code: '10631431000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of skin of right ankle (disorder)',
    code: '10631471000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of skin of right hip (disorder)',
    code: '10631511000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of skin of right knee (disorder)',
    code: '10631551000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of skin of right wrist (disorder)',
    code: '10631591000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Onychia of toe of left foot (disorder)',
    code: '10631631000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Onychia of toe of right foot (disorder)',
    code: '10631671000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Onychia of finger of left hand (disorder)',
    code: '10631711000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Onychia of left thumb (disorder)',
    code: '10631751000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Onychia of finger of right hand (disorder)',
    code: '10631791000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Thumb onychia (disorder)',
    code: '10631831000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Onychia of right thumb (disorder)',
    code: '10631871000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of left axilla (disorder)',
    code: '10631911000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of right axilla (disorder)',
    code: '10631951000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pulp abscess of finger of left hand (disorder)',
    code: '10631991000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pulp abscess of finger of right hand (disorder)',
    code: '10632031000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of finger of left hand (disorder)',
    code: '10632071000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of finger of right hand (disorder)',
    code: '10632111000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of toe of left foot (disorder)',
    code: '10632151000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of toe of right foot (disorder)',
    code: '10632191000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of thumb of left hand (disorder)',
    code: '10632231000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of pulp of left thumb (disorder)',
    code: '10632271000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pulp abscess of thumb (disorder)',
    code: '10632311000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of thumb of right hand (disorder)',
    code: '10632351000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of pulp of right thumb (disorder)',
    code: '10632391000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of left thigh (disorder)',
    code: '10632431000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of right thigh (disorder)',
    code: '10632471000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of left foot (disorder)',
    code: '10632511000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of right foot (disorder)',
    code: '10632551000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of left lower limb (disorder)',
    code: '10632591000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of right lower limb (disorder)',
    code: '10632631000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of right hand (disorder)',
    code: '10632671000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of left hand (disorder)',
    code: '10632711000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of skin of right shoulder (disorder)',
    code: '10632751000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of left forearm (disorder)',
    code: '10632791000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of right forearm (disorder)',
    code: '10632831000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of right upper limb (disorder)',
    code: '10632871000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of left upper limb (disorder)',
    code: '10632911000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of left thigh (disorder)',
    code: '10632951000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of right thigh (disorder)',
    code: '10632991000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute congestive heart failure (disorder)',
    code: '10633002',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of left ankle (disorder)',
    code: '10633031000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of right ankle (disorder)',
    code: '10633071000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of toe of left foot (disorder)',
    code: '10633111000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of toe of right foot (disorder)',
    code: '10633151000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of left foot (disorder)',
    code: '10633191000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of right foot (disorder)',
    code: '10633231000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of left knee (disorder)',
    code: '10633271000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of right knee (disorder)',
    code: '10633311000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of left lower limb (disorder)',
    code: '10633351000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of right lower limb (disorder)',
    code: '10633391000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of left wrist (disorder)',
    code: '10633431000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of right wrist (disorder)',
    code: '10633471000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of left shoulder (disorder)',
    code: '10633511000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of right shoulder (disorder)',
    code: '10633551000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of left thumb (disorder)',
    code: '10633591000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of right thumb (disorder)',
    code: '10633631000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of finger of left hand (disorder)',
    code: '10633671000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of finger of right hand (disorder)',
    code: '10633711000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of left hand (disorder)',
    code: '10633791000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of left forearm (disorder)',
    code: '10633831000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of right forearm (disorder)',
    code: '10633871000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of right upper limb (disorder)',
    code: '10633911000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cellulitis of left upper limb (disorder)',
    code: '10633951000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unstageable pressure injury of left heel (disorder)',
    code: '10634631000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unstageable pressure injury of right heel (disorder)',
    code: '10634671000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of toe of left foot (disorder)',
    code: '10634711000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of toe of right foot (disorder)',
    code: '10634911000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of heel (disorder)',
    code: '10635431000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of left foot (disorder)',
    code: '10635631000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of left heel (disorder)',
    code: '10635831000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of left midfoot (disorder)',
    code: '10636031000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of midfoot (disorder)',
    code: '10636231000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of right foot (disorder)',
    code: '10636431000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of right heel (disorder)',
    code: '10636631000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of right midfoot (disorder)',
    code: '10636831000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pressure injury of buttock stage I (disorder)',
    code: '10637031000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pressure injury of buttock stage II (disorder)',
    code: '10637071000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Osteopathy co-occurrent and due to non active poliomyelitis of lower leg (disorder)',
    code: '106371000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pressure injury of buttock stage III (disorder)',
    code: '10637111000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pressure injury of buttock stage IV (disorder)',
    code: '10637151000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unstageable pressure injury of buttock (disorder)',
    code: '10637591000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unstageable pressure injury of left buttock (disorder)',
    code: '10637631000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unstageable pressure injury of right buttock (disorder)',
    code: '10637671000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of skin of ankle (disorder)',
    code: '10637751000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Necrosis of ankle bone co-occurrent and due to chronic ulcer of ankle (disorder)',
    code: '10637791000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Chronic non-pressure ulcer of ankle extending to fat level (disorder)',
    code: '10637831000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Necrosis of ankle muscle co-occurrent and due to chronic ulcer of ankle (disorder)',
    code: '10637871000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of left ankle (disorder)',
    code: '10637911000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of right ankle (disorder)',
    code: '10638111000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of left thigh (disorder)',
    code: '10638311000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of right thigh (disorder)',
    code: '10638511000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neuropathic ulcer of left calf (disorder)',
    code: '10638911000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neuropathic ulcer of left lower leg (disorder)',
    code: '10638991000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neuropathic ulcer of right calf (disorder)',
    code: '10639271000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neuropathic ulcer of right thigh (disorder)',
    code: '10640311000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neuropathic ulcer of left thigh (disorder)',
    code: '10640351000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neuropathic ulcer of right lower leg (disorder)',
    code: '10640631000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of skin of calf (disorder)',
    code: '10640791000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Necrosis of calf bone co-occurrent and due to chronic ulcer of calf (disorder)',
    code: '10640831000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Chronic non-pressure ulcer of calf extending to fat level (disorder)',
    code: '10640871000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Necrosis of calf muscle co-occurrent and due to chronic ulcer of calf (disorder)',
    code: '10640911000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of left calf (disorder)',
    code: '10640951000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Sampling of vagina for Papanicolaou smear after benign hysterectomy done (situation)',
    code: '106411000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of left lower leg (disorder)',
    code: '10641151000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of skin of lower leg (disorder)',
    code: '10641391000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Necrosis of bone of lower leg co-occurrent and due to chronic ulcer of lower leg (disorder)',
    code: '10641431000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Necrosis of lower leg muscle co-occurrent and due to chronic ulcer of lower leg (disorder)',
    code: '10641511000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of right calf (disorder)',
    code: '10641551000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic ulcer of right lower leg (disorder)',
    code: '10641751000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unstageable pressure injury of sacral region of back (disorder)',
    code: '10642111000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Necrosis of muscle of buttock co-occurrent and due to chronic ulcer of buttock (disorder)',
    code: '10642151000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Necrosis of sacral bone co-occurrent and due to chronic ulcer of sacrum (disorder)',
    code: '10642191000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Necrosis of bone of buttock co-occurrent and due to chronic ulcer of buttock (disorder)',
    code: '10642231000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Necrosis of sacral muscle co-occurrent and due to chronic ulcer of sacrum (disorder)',
    code: '10642311000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Chronic skin ulcer of sacrum (disorder)',
    code: '10642351000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Chronic non-pressure ulcer of sacrum extending to fat level (disorder)',
    code: '10642391000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Varicose ulcer of left ankle (disorder)',
    code: '10643231000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Varicose ulcer of left calf (disorder)',
    code: '10643431000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Varicose ulcer of left foot (disorder)',
    code: '10643631000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Varicose ulcer of left heel (disorder)',
    code: '10643831000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Varicose ulcer of left lower leg (disorder)',
    code: '10644031000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Varicose ulcer of left thigh (disorder)',
    code: '10644431000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Varicose ulcer of right ankle (disorder)',
    code: '10645031000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Goiter due to thyroiditis (disorder)',
    code: '106451000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Varicose ulcer of right calf (disorder)',
    code: '10645231000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Varicose ulcer of right foot (disorder)',
    code: '10645431000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Varicose ulcer of right heel (disorder)',
    code: '10645631000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Varicose ulcer of right lower leg (disorder)',
    code: '10645831000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Varicose ulcer of right thigh (disorder)',
    code: '10646231000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Undecided about sexual orientation (finding)',
    code: '1064711000000108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of left thigh (disorder)',
    code: '10647831000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of right thigh (disorder)',
    code: '10647871000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hyperpituitarism (disorder)',
    code: '10649000',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of left calf (disorder)',
    code: '10649111000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of right calf (disorder)',
    code: '10649151000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Poisoning caused by ketamine (disorder)',
    code: '10650000',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Venous stasis ulcer of left lower leg co-occurrent with edema of left lower leg (disorder)',
    code: '10650831000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Temporal lobectomy behavior syndrome (disorder)',
    code: '10651001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Long-term current use of mycophenolate (situation)',
    code: '106511000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Long-term current use of cyclophosphamide (situation)',
    code: '106521000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Venous stasis ulcer of right lower leg co-occurrent with edema of right lower leg (disorder)',
    code: '10652791000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Congenital mandibular hyperplasia (disorder)',
    code: '10653003',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Long-term current use of penicillamine (situation)',
    code: '106531000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ischemic ulcer of toe of left foot due to atherosclerotic disease (disorder)',
    code: '10653951000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ischemic ulcer of toe of right foot due to atherosclerotic disease (disorder)',
    code: '10654151000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ischemic ulcer of left foot due to atherosclerotic disease (disorder)',
    code: '10654871000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ischemic ulcer of left heel due to atherosclerotic disease (disorder)',
    code: '10655071000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Long-term current use of medicinal gold (situation)',
    code: '106551000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ischemic ulcer of left midfoot due to atherosclerotic disease (disorder)',
    code: '10655271000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ischemic ulcer of right foot due to atherosclerotic disease (disorder)',
    code: '10655671000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ischemic ulcer of right heel due to atherosclerotic disease (disorder)',
    code: '10655831000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ischemic ulcer of right midfoot due to atherosclerotic disease (disorder)',
    code: '10656031000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of skin of toe due to type 1 diabetes mellitus (disorder)',
    code: '10656231000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of skin of toe due to type 2 diabetes mellitus (disorder)',
    code: '10656271000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of toe of left foot (disorder)',
    code: '10656311000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of toe of right foot (disorder)',
    code: '10656351000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neuropathic ulcer of left foot (disorder)',
    code: '10656671000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neuropathic ulcer of right midfoot (disorder)',
    code: '10656711000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neuropathic ulcer of right foot (disorder)',
    code: '10656951000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Long-term current use of sulfasalazine (situation)',
    code: '106571000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neuropathic ulcer of left heel (disorder)',
    code: '10657711000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neuropathic ulcer of toe of right foot (disorder)',
    code: '10657831000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neuropathic ulcer of toe of left foot (disorder)',
    code: '10657911000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Long-term current use of cyclosporine (situation)',
    code: '106581000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neuropathic ulcer of right heel (disorder)',
    code: '10658111000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neuropathic ulcer of left midfoot (disorder)',
    code: '10658711000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of right heel (disorder)',
    code: '10659391000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of left heel (disorder)',
    code: '10659431000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of left foot due to type 2 diabetes mellitus (disorder)',
    code: '10660471000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Injury of bladder during surgery (disorder)',
    code: '10661000087107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hernia of ovary (disorder)',
    code: '10661008',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of right foot due to type 2 diabetes mellitus (disorder)',
    code: '10661671000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nonvenomous insect bite of face with infection (disorder)',
    code: '10662001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of left midfoot region (disorder)',
    code: '10662471000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of midfoot (disorder)',
    code: '10662511000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of right midfoot region (disorder)',
    code: '10662591000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of left ankle (disorder)',
    code: '10662631000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of right ankle (disorder)',
    code: '10662671000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Long-term current use of infliximab (situation)',
    code: '106631000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ischemic ulcer of left lower leg due to atherosclerotic disease (disorder)',
    code: '10663991000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ischemic ulcer of left calf due to atherosclerotic disease (disorder)',
    code: '10664071000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ischemic ulcer of right calf due to atherosclerotic disease (disorder)',
    code: '10664231000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ischemic ulcer of right lower leg due to atherosclerotic disease (disorder)',
    code: '10664351000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ischemic ulcer of right ankle due to atherosclerotic disease (disorder)',
    code: '10664951000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mumps pancreatitis (disorder)',
    code: '10665004',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ischemic ulcer of right thigh due to atherosclerotic disease (disorder)',
    code: '10665551000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ischemic ulcer of left thigh due to atherosclerotic disease (disorder)',
    code: '10665791000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ischemic ulcer of left ankle due to atherosclerotic disease (disorder)',
    code: '10665871000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Ischemic ulcer of lower leg due to atherosclerotic disease (disorder)',
    code: '10666031000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of left lower leg (disorder)',
    code: '10666391000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of right lower leg (disorder)',
    code: '10666431000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pressure injury of right lower leg stage IV (disorder)',
    code: '10666711000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pressure injury of left lower leg stage IV (disorder)',
    code: '10666791000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unstageable pressure injury of head (disorder)',
    code: '10666951000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pressure injury of left lower leg stage II (disorder)',
    code: '10667431000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pressure injury of right lower leg stage II (disorder)',
    code: '10667471000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pressure injury of right lower leg stage I (disorder)',
    code: '10667511000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pressure injury of left lower leg stage I (disorder)',
    code: '10667751000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Long-term current use of etanercept (situation)',
    code: '106691000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unstageable pressure injury of back (disorder)',
    code: '10669431000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pressure injury of coccygeal region of back stage IV (disorder)',
    code: '10669591000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pressure injury of coccygeal region of back stage III (disorder)',
    code: '10669631000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pressure injury of coccygeal region of back stage II (disorder)',
    code: '10669671000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pressure injury of coccygeal region of back stage I (disorder)',
    code: '10669711000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Unstageable pressure injury of coccygeal region of back (disorder)',
    code: '10669751000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pressure injury of left lower leg stage III (disorder)',
    code: '10669831000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pressure injury of right lower leg stage III (disorder)',
    code: '10670031000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Long-term current use of leflunomide (situation)',
    code: '106701000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unstageable pressure injury of left elbow (disorder)',
    code: '10670791000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unstageable pressure injury of right elbow (disorder)',
    code: '10670831000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unstageable pressure injury of right lower leg (disorder)',
    code: '10670951000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unstageable pressure injury of right hip (disorder)',
    code: '10670991000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Blister of scapular region with infection (disorder)',
    code: '10671005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unstageable pressure injury of left lower leg (disorder)',
    code: '10671071000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unstageable pressure injury of left ankle (disorder)',
    code: '10671111000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unstageable pressure injury of right ankle (disorder)',
    code: '10671191000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Unstageable pressure injury of left hip (disorder)',
    code: '10671231000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pressure injury of left lower leg (disorder)',
    code: '10671471000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pressure injury of right lower leg (disorder)',
    code: '10671511000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of buttock (disorder)',
    code: '10672191000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute-on-chronic hypoxemic respiratory failure (disorder)',
    code: '10672271000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cyst of ethmoid sinus (disorder)',
    code: '10674551000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cyst of frontal sinus (disorder)',
    code: '10674591000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Cyst of sphenoid sinus (disorder)',
    code: '10674631000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Abscess of nasal sinus (disorder)',
    code: '10674671000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Acute severe exacerbation of asthma co-occurrent with allergic rhinitis (disorder)',
    code: '10674711000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Acute exacerbation of intermittent allergic asthma (disorder)',
    code: '10674791000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pulmonary edema caused by chemical fumes (disorder)',
    code: '10674871000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Otitis media caused by Influenza A virus (disorder)',
    code: '10674911000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Intermittent allergic asthma (disorder)',
    code: '10674991000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Normal pulse rate (finding)',
    code: '10675001',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Severe controlled persistent asthma (disorder)',
    code: '10675391000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Severe persistent allergic asthma (disorder)',
    code: '10675431000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Acute severe exacerbation of severe persistent allergic asthma (disorder)',
    code: '10675471000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Acute severe exacerbation of severe persistent asthma co-occurrent with allergic rhinitis (disorder)',
    code: '10675551000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Severe persistent allergic asthma controlled (finding)',
    code: '10675591000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Severe persistent asthma controlled co-occurrent with allergic rhinitis (disorder)',
    code: '10675631000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Severe persistent allergic asthma uncontrolled (finding)',
    code: '10675671000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Severe persistent asthma uncontrolled co-occurrent with allergic rhinitis (disorder)',
    code: '10675711000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Severe uncontrolled persistent asthma (disorder)',
    code: '10675751000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mild persistent allergic asthma (disorder)',
    code: '10675871000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Acute severe exacerbation of mild persistent allergic asthma (disorder)',
    code: '10675911000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Acute severe exacerbation of mild persistent allergic asthma co-occurrent with allergic rhinitis (disorder)',
    code: '10675991000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mild persistent allergic asthma controlled (finding)',
    code: '10676031000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mild persistent asthma controlled (finding)',
    code: '10676071000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Mild persistent asthma controlled co-occurrent with allergic rhinitis (disorder)',
    code: '10676111000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mild persistent allergic asthma uncontrolled (finding)',
    code: '10676151000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mild persistent asthma uncontrolled (finding)',
    code: '10676191000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Mild persistent asthma uncontrolled co-occurrent with allergic rhinitis (disorder)',
    code: '10676231000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Acute exacerbation of moderate persistent allergic asthma (disorder)',
    code: '10676271000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Moderate persistent asthma controlled (finding)',
    code: '10676351000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Moderate persistent allergic asthma (disorder)',
    code: '10676391000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Acute severe exacerbation of moderate persistent allergic asthma (disorder)',
    code: '10676431000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Acute severe exacerbation of moderate persistent asthma co-occurrent with allergic rhinitis (disorder)',
    code: '10676511000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Moderate persistent allergic asthma controlled (finding)',
    code: '10676551000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Moderate persistent controlled asthma co-occurrent with allergic rhinitis (disorder)',
    code: '10676591000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Moderate persistent allergic asthma uncontrolled (finding)',
    code: '10676631000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Moderate persistent asthma uncontrolled co-occurrent with allergic rhinitis (disorder)',
    code: '10676671000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Moderate persistent asthma uncontrolled (finding)',
    code: '10676711000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Hypoxemic respiratory failure (disorder)',
    code: '10676831000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Allergic contact dermatitis caused by chemical (disorder)',
    code: '10676871000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Pruritis of skin of anogenital region (disorder)',
    code: '10676911000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neuropathic ulcer of left ankle (disorder)',
    code: '10677031000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Neuropathic ulcer of right ankle (disorder)',
    code: '10677231000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Infection of toenail (disorder)',
    code: '10677511000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Primary focal hyperhidrosis of axilla (disorder)',
    code: '10677551000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Primary focal hyperhidrosis of face (disorder)',
    code: '10677591000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Primary focal hyperhidrosis of palm of hand (disorder)',
    code: '10677631000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Primary focal hyperhidrosis of sole of foot (disorder)',
    code: '10677671000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Encephalopathy caused by Influenza A virus (disorder)',
    code: '10677711000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of abdomen (disorder)',
    code: '10677751000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of subcutaneous tissue of abdominal wall (finding)',
    code: '10677791000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of skin of abdomen (finding)',
    code: '10677831000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of abdominal wall (finding)',
    code: '10677871000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of forearm (finding)',
    code: '10677951000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of bilateral forearms (finding)',
    code: '10677991000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Necrosis of back bone co-occurrent and due to chronic ulcer of back (disorder)',
    code: '10678071000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Ulcer of back (disorder)',
    code: '10678111000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of bilateral lower legs (finding)',
    code: '10678191000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of toe of bilateral feet (finding)',
    code: '10678231000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of toe of left foot (finding)',
    code: '10678271000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of toe of right foot (finding)',
    code: '10678351000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of right foot (finding)',
    code: '10678431000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of left foot (finding)',
    code: '10678471000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of bilateral feet (finding)',
    code: '10678511000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of bilateral lower limbs (finding)',
    code: '10678591000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of right lower leg (finding)',
    code: '10678631000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of right lower limb (finding)',
    code: '10678671000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of left lower leg (finding)',
    code: '10678711000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of left lower limb (finding)',
    code: '10678751000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of finger of bilateral hands (finding)',
    code: '10678791000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of finger of left hand (finding)',
    code: '10678831000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of finger of right hand (finding)',
    code: '10678911000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of bilateral hands (finding)',
    code: '10678951000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Infection caused by Giardia lamblia (disorder)',
    code: '10679007',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of right hand (finding)',
    code: '10679031000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of left hand (finding)',
    code: '10679071000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of right upper limb (finding)',
    code: '10679151000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of right forearm (finding)',
    code: '10679191000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of left upper limb (finding)',
    code: '10679231000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of left forearm (finding)',
    code: '10679271000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of bilateral upper limbs (finding)',
    code: '10679311000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of head (finding)',
    code: '10679351000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of skin of head (finding)',
    code: '10679391000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of back (finding)',
    code: '10679431000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of back (disorder)',
    code: '10679471000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of skin of chest (finding)',
    code: '10679511000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Localized swelling of chest wall (finding)',
    code: '10679591000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of subcutaneous tissue of bilateral upper limbs (finding)',
    code: '10679631000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display:
      'Mass of subcutaneous tissue of finger of bilateral hands (finding)',
    code: '10679671000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of subcutaneous tissue of bilateral forearms (finding)',
    code: '10679711000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of subcutaneous tissue of bilateral hands (finding)',
    code: '10679751000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of subcutaneous tissue of left upper limb (finding)',
    code: '10679791000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of subcutaneous tissue of finger of left hand (finding)',
    code: '10679831000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of subcutaneous tissue of left forearm (finding)',
    code: '10679871000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of subcutaneous tissue of left hand (finding)',
    code: '10679911000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of subcutaneous tissue of right upper limb (finding)',
    code: '10679951000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of subcutaneous tissue of finger of right hand (finding)',
    code: '10679991000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Insulin receptor defect (disorder)',
    code: '10680005',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of subcutaneous tissue of right forearm (finding)',
    code: '10680031000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of subcutaneous tissue of right hand (finding)',
    code: '10680071000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of skin of left lower leg (finding)',
    code: '10680231000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of bilateral upper limbs (finding)',
    code: '10680271000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of bilateral feet (finding)',
    code: '10680311000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of finger of bilateral hands (disorder)',
    code: '10680351000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of bilateral forearms (disorder)',
    code: '10680391000119104',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of bilateral hands (finding)',
    code: '10680431000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of bilateral lower limbs (finding)',
    code: '10680471000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of bilateral lower legs (finding)',
    code: '10680511000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of toe of bilateral feet (disorder)',
    code: '10680551000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of left upper limb (disorder)',
    code: '10680591000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of finger of left hand (disorder)',
    code: '10680631000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of left foot (disorder)',
    code: '10680671000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of left forearm (finding)',
    code: '10680711000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of left hand (disorder)',
    code: '10680751000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of left lower limb (disorder)',
    code: '10680791000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of left lower leg (disorder)',
    code: '10680831000119106',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of left toe (finding)',
    code: '10680871000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of right foot (disorder)',
    code: '10680911000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of right lower limb (disorder)',
    code: '10680951000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of right lower leg (disorder)',
    code: '10680991000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of right toe (finding)',
    code: '10681031000119109',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of chest (disorder)',
    code: '10681111000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of head (disorder)',
    code: '10681311000119102',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Nodule of skin of neck (disorder)',
    code: '10681431000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of skin of bilateral upper limbs (finding)',
    code: '10681511000119107',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of skin of bilateral feet (finding)',
    code: '10681551000119108',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of skin of finger of bilateral hands (finding)',
    code: '10681591000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of skin of bilateral forearms (finding)',
    code: '10681631000119103',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of skin of bilateral hands (finding)',
    code: '10681671000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of skin of bilateral lower limbs (finding)',
    code: '10681711000119101',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of skin of bilateral lower legs (finding)',
    code: '10681751000119100',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of skin of toe of bilateral feet (finding)',
    code: '10681791000119105',
    system: 'http://snomed.info/sct',
  },
  {
    display: 'Mass of subcutaneous tissue of bilateral feet (finding)',
    code: '10681831000119104',
    system: 'http://snomed.info/sct',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
