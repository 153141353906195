import { Box, Typography } from '@material-ui/core'
import React from 'react'

interface IProps {
  title: string
  subTitle: React.ReactNode
}

export const MedicalEventContent: React.FC<IProps> = ({
  title,
  subTitle,
}: IProps) => (
  <Box display='flex' flexDirection='row' justifyContent='flex-start' py={0.5}>
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='30%'
    >
      <Typography variant='body1' color='initial'>
        {title}
      </Typography>
    </Box>

    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='70%'
    >
      {subTitle}
    </Box>
  </Box>
)
