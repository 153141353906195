import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Switch,
  Typography,
} from '@material-ui/core'
import { kBackgroundPaper } from 'configs/styles/muiThemes'
import { MembershipDetails } from 'models/membershipDetails'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { TransactionOfMembership } from 'models/transactionOfMembership'
import moment from 'moment'
// import { kDialogueBackground } from 'configs/styles/muiThemes'
import React, { useEffect, useState } from 'react'
import { useEffectOnceWhen } from 'rooks'
import {
  getCodeOfSystemFromCodableConcept,
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultCodeOfSystemFromCodableConceptList,
  getDisplayOfSystemFromCodableConceptList,
  getNameFromHumanName,
  getTelecomFromContactPoints,
} from 'utils/fhirResourcesHelper'
import { getSymbolForCurrency } from 'utils/fhirResoureHelpers/currency_helper'
import {
  getMemberShipPaidAmount,
  getMemberShipPaidAmountCurrency,
  getMembershipTransactionsList,
} from 'utils/membershipUtils'

const useStyles = makeStyles({
  bigIndicator: {
    width: '10px',
  },
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      //   padding: 4,
    },
  },
})

interface MembershipSummaryProperties {
  usersData: R4.ICoverage
}

export const MembershipPlanTransactions: React.FC<MembershipSummaryProperties> =
  ({ usersData }: MembershipSummaryProperties) => {
    const classes = useStyles()

    const [transactions, setTransactions] = React.useState<
      TransactionOfMembership[]
    >([])

    function fetchInvoice() {
      if (usersData?.id) {
        setFetchingStatus({
          requesting: true,
        })

        getMembershipTransactionsList(usersData.id)
          .then((e) => {
            if ('status' in e) {
              setFetchingStatus({
                requesting: false,
                requestError: true,
              })
            } else {
              setFetchingStatus({
                requesting: false,
                requestSuccess: true,
              })

              setTransactions(e)
            }
          })
          .catch((e) => {
            setFetchingStatus({
              requesting: false,
              requestError: true,
            })
          })
      }
    }

    const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
      initial: true,
      requesting: true,
    })

    useEffect(() => {
      fetchInvoice()
    }, [])

    return (
      <Box
        display='flex'
        flexDirection='column'
        overflow='auto'
        width='100%'
        height='100%'
        p={2}
      >
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          width='100%'
        >
          <Box
            display='flex'
            flexDirection='column'
            overflow='auto'
            width='100%'
          >
            <Typography variant='h6' color='initial'>
              Transactions
            </Typography>
            <Box
              display='flex'
              flexDirection='column'
              py={1}
              my={1}
              bgcolor={kBackgroundPaper}
              borderRadius={4}
            >
              {fetchingStatus.requesting && (
                <Box px={2}>
                  <Typography variant='body1' color='initial'>
                    Fetching Transactions...
                  </Typography>
                </Box>
              )}
              {fetchingStatus.requestSuccess && transactions.length > 0 && (
                <List>
                  {transactions.map((e) => (
                    <ListItem
                      key={e.id}
                      style={{
                        borderBottom: '1px solid #e0e0e0',
                      }}
                    >
                      <ListItemIcon>
                        <Box display='flex' flexDirection='column'>
                          <Typography variant='subtitle1' color='initial'>
                            {moment(e.explanationBenefit.created).format(
                              'DD MMM'
                            )}
                          </Typography>
                          <Typography variant='subtitle1' color='initial'>
                            {moment(e.explanationBenefit.created).format(
                              'YYYY'
                            )}
                          </Typography>
                        </Box>
                      </ListItemIcon>
                      <ListItemText
                        id={e.id}
                        style={{ paddingLeft: '32px ', paddingRight: '32px' }}
                        primary={`${getNameFromHumanName(
                          e.beneficiary.name ?? []
                        )} (${
                          e.coverage.relationship?.coding?.[0].code === 'self'
                            ? 'P'
                            : e.coverage.relationship?.coding?.[0].display ?? ''
                        })`}
                        secondary={` Ref : ${e.requestId}`}
                      />

                      <ListItemSecondaryAction>
                        <Typography variant='subtitle1'>
                          {`${getSymbolForCurrency('')} ${
                            e.explanationBenefit.total?.find(
                              (t) =>
                                getDefaultCodeOfSystemFromCodableConcept(
                                  t.category
                                ) === 'benefit'
                            )?.amount.value ?? 0
                          }`}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              )}

              {fetchingStatus.requestSuccess && transactions.length === 0 && (
                <Box display='flex' flexDirection='column'>
                  <Typography variant='subtitle2' color='initial'>
                    No Transactions are done yet
                  </Typography>
                </Box>
              )}
              {fetchingStatus.requestError && (
                <Box display='flex' flexDirection='column'>
                  <Typography variant='body1' color='initial'>
                    Error Fetching Transactions
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
