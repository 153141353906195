import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListSubheader,
  Radio,
  RadioGroup,
  Typography,
  Tooltip,
  Collapse,
  Link,
} from '@material-ui/core'
import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
} from '@material-ui/icons'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { DateWiseVitals } from 'models/dateWiseVitals'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { requestVitalDetailsOfFollowUpPatient } from 'redux/consultation/followUpVital/FollowUpVitalsDetailSlice'

import { resetAddVitalsState } from 'redux/fhirMedicalResources/addVital/addVitalSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import {
  getDateWiseVitals,
  getTimeWiseVitals,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { logger } from 'utils/logger'
import { VitalElement } from 'views/components/LeftMenu/VitalElement'
import { SOAPIndicativeElement } from 'wello-web-components'
import { AddVital } from '../addition/addVitals'
import { EmptyTile } from './tiles/emptyTile'
import { VitalsDataTile } from './tiles/vitalDataTile'
import { VitalsTile } from './tiles/vitalsTile'

interface VitalsProp {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
  followUp?: boolean
}

export const FollwupVitals: React.FC<VitalsProp> = ({
  fhirAppointmentDetails,
  split,
  followUp,
}: VitalsProp) => {
  const { t } = useTranslation(['common'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const [groupVitals, setGroupVitals] = useState<DateWiseVitals[]>([])

  const dispatch = useDispatch()
  const vitalsDetailsSlice = useSelector(
    (state: RootState) => state.followUpVitalsDetailsSlice
  )

  const appointmentId: string = getAppointmentIdForFollowUp(
    fhirAppointmentDetails.appointment.supportingInformation ?? []
  )
  useEffect(() => {
    if (followUp) {
      dispatch(
        requestVitalDetailsOfFollowUpPatient(
          fhirAppointmentDetails.patient,
          appointmentId
        )
      )
    }
  }, [dispatch, fhirAppointmentDetails])

  useEffect(() => {
    if (
      vitalsDetailsSlice.resultsAvailable &&
      vitalsDetailsSlice.dateWiseVitals
    ) {
      updateVitals(vitalsDetailsSlice.dateWiseVitals)
    }
  }, [vitalsDetailsSlice])

  function updateVitals(vitalList: DateWiseVitals[]) {
    const results: DateWiseVitals[] = []
    for (let i = 0; i < vitalList.length; i++) {
      results.push({
        date: vitalList[i].date,
        vitals: vitalList[i].vitals,
        checked: vitalList[i].checked,
      })
    }
    setGroupVitals(results)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: DateWiseVitals[] = [...groupVitals]
    values[index].checked = rate
    setGroupVitals(values)
  }
  return (
    <ReactVisibilitySensor
      offset={{
        top: 100,
        bottom: 30,
      }}
      onChange={(isVisible) => {
        logger.info('Inside diagnostic')
        if (isVisible) {
          dispatch(setSelectedSection({ selectedSection: 'vitals' }))
        }
      }}
    >
      <Grid container direction='column' style={{ flexWrap: 'nowrap' }}>
        <Grid
          item
          onMouseEnter={() => showAddButton(true)}
          onMouseLeave={() => showAddButton(false)}
        >
          <Grid container direction='row' id='vitals'>
            <Box py={1}>
              <Typography variant='h6'>
                {' '}
                Previous Appointment Vitals{' '}
              </Typography>
            </Box>{' '}
          </Grid>

          {vitalsDetailsSlice.searchingConditions && (
            <Grid item>
              <CircularProgress size={15} />
            </Grid>
          )}
          {vitalsDetailsSlice.noResultsAvailable && (
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                No data available
              </Typography>
            </Grid>
          )}
          {vitalsDetailsSlice.errorWhileSearchingProcedures && (
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                {vitalsDetailsSlice.errorReason ??
                  'Error while fetching Vitals'}
              </Typography>
            </Grid>
          )}
          {vitalsDetailsSlice.resultsAvailable &&
            vitalsDetailsSlice.dateWiseVitals && (
              <Grid item style={{ padding: 0, margin: 0 }} xs={split ? 12 : 12}>
                <Box
                  flexGrow
                  width='100%'
                  display='flex'
                  flexDirection='column'
                >
                  {groupVitals.map((e, index: number) => (
                    <Box
                      flexGrow
                      width='100%'
                      display='flex'
                      flexDirection='column'
                      key={`1${e.date}`}
                    >
                      <Box
                        paddingX={1}
                        borderRadius={2}
                        style={{
                          backgroundColor: 'lightGrey',
                        }}
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        height={40}
                        paddingY={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow={1}
                          alignItems='center'
                        >
                          <Typography
                            variant='subtitle1'
                            style={{
                              color: 'black',

                              fontWeight: 'bold',
                            }}
                          >
                            Recorded on {moment(e.date).format('Do MMM YYYY')}
                          </Typography>
                        </Box>
                        {getTimeWiseVitals(e.vitals ?? []).map((e1) => (
                          <Grid item xs={3} key={e1.time ?? ''}>
                            <Grid container direction='row'>
                              <Grid item xs={8}>
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                  height={30}
                                  //   paddingY={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow={1}
                                    alignItems='center'
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      {`${e1.time} ${e1.timeFormat}`}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}

                        <Box
                          justifyContent='flex-end'
                          display='flex'
                          flexGrow={1}
                          alignItems='center'
                        >
                          <Box px={1}>
                            <Tooltip title=''>
                              <IconButton
                                aria-label='collapse_order_type'
                                size='small'
                                onClick={() => {
                                  handleCollapseForPanel1(!e.checked, index)
                                }}
                              >
                                {e.checked && <ArrowDropUpOutlined />}
                                {!e.checked && <ArrowDropDownOutlined />}
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                      <Collapse
                        in={e.checked}
                        style={{
                          width: '100%',
                        }}
                      >
                        <Box
                          flexGrow
                          width='100%'
                          display='flex'
                          flexDirection='column'
                        >
                          <Grid
                            container
                            direction='row'
                            style={{
                              backgroundColor: kDialogueBackground,
                            }}
                          >
                            <Grid item xs={3}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Parameter Name
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  justifyContent='flex-end'
                                  flexGrow
                                  paddingRight={0.5}
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '30px',

                                      width: '2px',
                                      //   backgroundColor: 'white',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            {getTimeWiseVitals(e.vitals ?? []).map((e1) => (
                              <Grid item xs={3} key={e1.time ?? ''}>
                                <Grid container direction='row'>
                                  <Grid item xs={8}>
                                    <Box
                                      paddingX={0.5}
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      height={30}
                                      //   paddingY={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow={1}
                                        alignItems='center'
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Value
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>

                          <Box
                            flexGrow
                            width='100%'
                            display='flex'
                            flexDirection='row'
                          >
                            <Grid container direction='row'>
                              <Grid item xs={3}>
                                <Box
                                  flexGrow
                                  width='100%'
                                  display='flex'
                                  flexDirection='column'
                                  paddingRight={0.5}
                                >
                                  <VitalsTile />
                                </Box>
                              </Grid>
                              {getTimeWiseVitals(e.vitals ?? []).map((e1) => (
                                <Grid
                                  item
                                  xs={
                                    getTimeWiseVitals(e.vitals ?? []).length ===
                                    1
                                      ? 9
                                      : 3
                                  }
                                  key={e1.time ?? ''}
                                >
                                  <Box
                                    flexGrow
                                    width='100%'
                                    display='flex'
                                    flexDirection='column'
                                  >
                                    <Box>
                                      <VitalsDataTile obsData={e1.vitals} />
                                    </Box>
                                  </Box>
                                </Grid>
                              ))}
                              {getTimeWiseVitals(e.vitals ?? []).length ===
                                2 && (
                                <Grid item xs={3}>
                                  <Box
                                    flexGrow
                                    width='100%'
                                    display='flex'
                                    flexDirection='column'
                                    paddingRight={0.5}
                                    paddingTop={0.1}
                                  >
                                    <EmptyTile />
                                  </Box>
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        </Box>
                      </Collapse>
                    </Box>
                  ))}
                </Box>
              </Grid>
            )}
        </Grid>
      </Grid>
    </ReactVisibilitySensor>
  )
}
