import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export interface ConfirmationDialogProps {
  open: boolean
  onContinueActionClicked?: () => void
  onCancelActionClicked?: () => void
  displayText?: string
  title?: string
  continueActionButtonText?: string
  cancelActionButtonText?: string
  id?: string
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onContinueActionClicked,
  onCancelActionClicked,
  displayText = 'Continue Delete',
  title = 'Delete',
  continueActionButtonText: doneButtonText = 'Delete',
  cancelActionButtonText: cancelButtonText = 'Cancel',
  id,
}) => {
  const { t } = useTranslation()

  useEffect(() => {})

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (onCancelActionClicked) onCancelActionClicked()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>{t(`${title}`)}</DialogTitle>
      <DialogContent dividers={true}>
        <Alert
          severity='warning'
          style={{
            backgroundColor: '#FFFFFF',
            color: useTheme().palette.common.black,
          }}
        >
          <Typography variant='body1'>{displayText}</Typography>
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (onCancelActionClicked) onCancelActionClicked()
          }}
          variant='outlined'
          disableElevation
          id={`${id}confirmDialog_button`}
        >
          <Typography variant='button' id={`${id}cancel_button`}>
            {t(`${cancelButtonText}`)}
          </Typography>
        </Button>
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            if (onContinueActionClicked) onContinueActionClicked()
          }}
          variant='contained'
          color='primary'
          id={`${id}continue_button`}
        >
          <Typography variant='button' id={`${id}done_button`}>
            {t(`${doneButtonText}`)}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
