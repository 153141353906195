import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetUnitCompletionState,
  updateUnitsBasicDetails,
  updateUnitTypeSpecificDetails,
} from 'redux/administration/unitSetup/unitProfileCompletionSlice/unitProfileCompletionSlice'
import { resetUserCompletionState } from 'redux/administration/userSetup/userProfileCompletionSlice/userProfileCompletionSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserUnitDetails,
  getCurrentUserUnitLocationDetails,
  isCurrentUnitIsClinic,
  isCurrentUnitIsCollectionCenter,
  isCurrentUnitIsLab,
} from 'services/userDetailsService'
import {
  getAddress,
  getTelecomFromContactPoints,
} from 'utils/fhirResourcesHelper'
import {
  isClinic,
  isValidCodeableConcent,
} from 'utils/fhirResoureHelpers/unit_setup_helpers'
import { validateMobileNumberForAdd } from 'utils/formValidators'
import { logger } from 'utils/logger'
import { validateEmail, validateMobileNumber } from 'wello-web-components'
import { AddUnitsPrimaryDetailsSetUp } from './add_unit_primary_details'
import { AddUnitsTimingDetailsSetUp } from './add_unit_timing_details'
import { UpdateUnitPrimaryDetails } from './update_unit_primary_details'

interface Props {
  open: boolean
  onBackClick: () => void
  onContinueClick: () => void
  task?: R4.ITask
}

export const FinishUnitDetailsSetUp: React.FC<Props> = ({
  open,
  onBackClick: onClose,
  onContinueClick,
  task,
}) => {
  const unitProfileCompletionSlice = useSelector(
    (state: RootState) => state.unitProfileCompletionSlice
  )

  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '55%',
    },
  }))
  const classes = useStyles()
  const unitOrgResource: R4.IOrganization = {
    ...unitProfileCompletionSlice.organizationDetails,
  }
  const unitOrgResourceForUpdate: R4.IOrganization = {
    ...getCurrentUserUnitDetails(),
  }
  console.log(unitOrgResource)
  const unitLocationResource: R4.ILocation = {
    ...unitProfileCompletionSlice.locationDetails,
  }
  const unitLocationResourceForUpdate: R4.ILocation = {
    ...getCurrentUserUnitLocationDetails(),
  }
  const [openConsent, setOpenConsent] = useState<boolean>(false)
  const [backClicked, setBackClicked] = useState<boolean>(false)
  const [basicDetailsFormsErrors, setBasicDetailsFormsErrors] =
    useState<boolean>(false)
  const isUpdating =
    unitProfileCompletionSlice.updatingBasicDetails ||
    unitProfileCompletionSlice.updatingUnitTypeSpecificDetails

  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    if (unitProfileCompletionSlice.updatedUnitTypeSpecificDetails) {
      dispatch(resetUnitCompletionState())
      onContinueClick()
    }
  }, [
    unitProfileCompletionSlice.updatedUnitTypeSpecificDetails,
    onContinueClick,
    dispatch,
  ])

  function validateFormEntires() {
    let res: boolean = true
    if (
      unitLocationResource.description === undefined ||
      unitLocationResource.description.length === 0
    ) {
      res = false
    }
    if (unitLocationResource.position === undefined) {
      res = true
    }
    if (
      getAddress(unitOrgResource.address) === undefined ||
      getAddress(unitOrgResource.address).length === 0
    ) {
      res = false
    }
    if (unitOrgResource.contact) {
      unitOrgResource.contact.forEach((element) => {
        if (!isValidCodeableConcent(element.purpose)) {
          res = false
        }
        if (
          validateMobileNumber(
            getTelecomFromContactPoints(
              element.telecom ?? [],
              R4.ContactPointSystemKind._phone
            ) ?? '',
            true
          ).length > 0
        ) {
          res = false
        }
        if (
          validateEmail(
            getTelecomFromContactPoints(
              element.telecom ?? [],
              R4.ContactPointSystemKind._email
            ) ?? '',
            true
          ).length > 0
        ) {
          res = false
        }
      })
    } else {
      return false
    }
    return res
  }

  function validateBasicDetails() {
    const res: Boolean = validateFormEntires()

    if (res) {
      logger.info('No Error')
      logger.info('Org Details', unitOrgResource)
      logger.info('Location Details', unitLocationResource)
      console.log(unitOrgResource)
      if (backClicked) {
        console.log(unitLocationResourceForUpdate)
        console.log(unitOrgResourceForUpdate)
        dispatch(
          updateUnitsBasicDetails(
            unitOrgResourceForUpdate,
            unitLocationResourceForUpdate,
            openConsent
          )
          /* updatePractitionerRoleDetails(practitioner, practitionerRole, task) */
        )
      } else {
        dispatch(
          updateUnitsBasicDetails(
            unitOrgResource,
            unitLocationResource,
            openConsent
          )
          /* updatePractitionerRoleDetails(practitioner, practitionerRole, task) */
        )
      }

      setBackClicked(false)
    } else {
      logger.info('Error in form')
    }
    setBasicDetailsFormsErrors(!res)
  }

  function validateSpecificDetails() {
    dispatch(updateUnitTypeSpecificDetails(unitLocationResource, task))
  }

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>
        {(unitProfileCompletionSlice.currentStep === 'basic_details' ||
          backClicked) &&
          (isCurrentUnitIsClinic() || isCurrentUnitIsCollectionCenter()) && (
            <Box>Enter Clinic Details</Box>
          )}

        {(unitProfileCompletionSlice.currentStep === 'basic_details' ||
          backClicked) &&
          isCurrentUnitIsLab() && <Box>Enter Lab Details</Box>}
        {unitProfileCompletionSlice.currentStep === 'unit_specific_details' &&
          backClicked === false && <Box>Add Business Timings</Box>}
      </DialogTitle>
      <DialogContent dividers={true}>
        {unitProfileCompletionSlice.currentStep === 'basic_details' &&
          !isUpdating && (
            <AddUnitsPrimaryDetailsSetUp
              organizationDetails={unitOrgResource}
              locationDetails={unitLocationResource}
              mainOrganizationDetails={getCurrentUserMainOrganizationDetails()}
              hasError={basicDetailsFormsErrors}
              onAgree={(checkData: boolean) => {
                setOpenConsent(true)
              }}
            />
          )}

        {backClicked && !isUpdating && (
          <UpdateUnitPrimaryDetails
            organizationDetails={unitOrgResourceForUpdate}
            locationDetails={unitLocationResourceForUpdate}
            mainOrganizationDetails={getCurrentUserMainOrganizationDetails()}
            hasError={basicDetailsFormsErrors}
            // onAgree={(checkData: boolean) => {
            //   setOpenConsent(true)
            // }}
          />
        )}
        {unitProfileCompletionSlice.currentStep === 'unit_specific_details' &&
          !isUpdating &&
          backClicked === false && (
            <AddUnitsTimingDetailsSetUp location={unitLocationResource} />
          )}
      </DialogContent>
      <DialogActions>
        {unitProfileCompletionSlice.updatingBasicDetails && (
          <CircularProgress />
        )}
        {unitProfileCompletionSlice.updatingUnitTypeSpecificDetails && (
          <CircularProgress />
        )}

        {!isUpdating && (
          <Box flex='display' flexDirection='row'>
            {backClicked && (
              <Button
                variant='outlined'
                color='primary'
                onClick={() => {
                  setBackClicked(false)
                }}
              >
                Cancel
              </Button>
            )}
            {(unitProfileCompletionSlice.currentStep === 'basic_details' ||
              backClicked) && (
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  if (backClicked === false) {
                    validateBasicDetails()
                  } else {
                    validateBasicDetails()
                  }
                }}
              >
                {backClicked ? 'Update' : t('labelCommon:continue')}
              </Button>
            )}

            {unitProfileCompletionSlice.currentStep ===
              'unit_specific_details' &&
              backClicked === false && (
                <Box display='flex' flexGrow width='100%' px={1}>
                  {/* <Box display='flex' flexGrow='1' flexDirection='row'>
                  <Button variant='tcontained' color='primary'>
                    {t('preview')}
                  </Button>
                </Box> */}
                  <Box display='flex' flexDirection='row'>
                    <Box px={1}>
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => {
                          logger.info('Pract Role ', unitLocationResource)
                          setBackClicked(true)
                        }}
                      >
                        Back
                      </Button>
                    </Box>
                    <Box px={1}>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          logger.info('Pract Role ', unitLocationResource)
                          validateSpecificDetails()
                        }}
                      >
                        {t('labelCommon:finish')}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
          </Box>
        )}
      </DialogActions>
    </Dialog>
  )
}
