import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getTimeOfDayDisplayForStatement,
  getTimeOfDayDisplayMeal,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { ShowIngredients } from './ShowIngrenient'

interface MedicationTileProps {
  medication: WelloMedication
  color: string
  index: number
  onDeleteClicked?: () => {}
  onEditClicked?: (ins: WelloMedication) => void
  displayStartEndDates?: boolean
  history?: boolean
  split?: boolean
}

export const MedicationRequestTile: React.FC<MedicationTileProps> = ({
  medication,
  color,
  index,
  onDeleteClicked,
  onEditClicked,
  displayStartEndDates,
  history,
  split,
}: MedicationTileProps) => {
  const [openIngredient, setOpenIngredient] = useState(false)
  const [selectedMedication, setSelectedMedication] = useState<R4.ICoding>()
  const [selectedRxNorm, setSelectedRxNorm] = useState<string>()

  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()
  const [showNotesText, setShowNotesText] = React.useState<boolean>(false)

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Grid container direction='column' spacing={1}>
        {displayStartEndDates && (
          <Grid
            container
            direction='row'
            id='vitals'
            style={{ backgroundColor: 'lightgray' }}
          >
            <Grid item xs={3}>
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                flexGrow={1}
                justifyContent='flex-end'
                height={30}
              >
                <Box display='flex' width='2%' justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2.4px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      Start Date
                    </Typography>
                  </Box>

                  {/* <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  /> */}
                </Box>
                <Box display='flex' width='2%' justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',
                      // backgroundColor: 'white',
                      width: '2.4px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {`${moment(medication.startFrom).format('DD-MM-YYYY')}`}
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' width='2%' justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',
                      // backgroundColor: 'white',
                      width: '2.4px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      Till Date
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' width='2%' justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',
                      // backgroundColor: 'white',
                      width: '2.4px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {`${moment(medication.till).format('DD-MM-YYYY')}`}
                    </Typography>
                  </Box>
                </Box>
                {/* <Box display='flex' width='2%' >
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '30px',

                    width: '2px',
                  }}
                />
              </Box> */}
              </Box>
            </Grid>
          </Grid>
        )}

        {/* <Grid container direction='row' id='vitals'>
          <Grid item xs={12}>
            <Box width='100%' paddingLeft={0.2}>
              <Divider
                orientation='horizontal'
                flexItem
                style={{
                  height: '2px',
                }}
              />
            </Box>
          </Grid>
        </Grid> */}
        <Grid
          container
          direction='row'
          id='vitals'
          style={{
            backgroundColor: kDialogueBackground,
          }}
        >
          <Grid item xs={3}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Medication
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' width='2%' justifyContent='flex-end'>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '34.7px',

                    width: '2.4px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Dosage
                  </Typography>
                </Box>

                {/* <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  /> */}
              </Box>
              <Box
                display='flex'
                width='2%'
                justifyContent='flex-end'
                paddingRight={0.1}
              >
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '34.7px',

                    width: '2.4px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Unit
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' width='2%' justifyContent='flex-end'>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '34.7px',

                    width: '2.4px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  paddingLeft={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Route of Administration
                  </Typography>
                </Box>
              </Box>
              <Box
                display='flex'
                width='2%'
                justifyContent='flex-end'
                // paddingRight={0.5}
              >
                {/* <Box display='flex' width='1.5%'> */}
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '35px',

                    width: '2.4px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={split === false && history === false ? 3 : 4}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    TIme of day
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          {split === false && history === false && (
            <Grid item xs={1}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  />
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid container direction='row' id='vitals'>
          <Grid item xs={12}>
            <Box width='100%' paddingLeft={0.2}>
              <Divider
                orientation='horizontal'
                flexItem
                style={{
                  height: '2px',
                }}
              />
            </Box>
          </Grid>
        </Grid>

        {/* ------------------------------------------------------------------------------- */}

        <Box
          display='flex'
          flexDirection='column'
          width='100%'
          flexGrow={1}
          key={medication.id}
        >
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: 'white',
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    {medication.medication && medication.medication.code && (
                      <Typography
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                          textTransform: 'capitalize',
                        }}
                      >
                        {medication.medication.code.text ?? ''}
                      </Typography>
                    )}

                    {medication.name && medication.name.length > 0 && (
                      <Typography
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                          textTransform: 'capitalize',
                        }}
                      >
                        {medication.name}
                      </Typography>
                    )}

                    {medication.medicationCode && (
                      <Typography
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                          textTransform: 'capitalize',
                        }}
                      >
                        {medication.medicationCode.display}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box display='flex' width='1.5%' justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '35px',

                      width: '2.4px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    {medication.dosageQuantity &&
                      medication.dosageQuantityType && (
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          {medication.dosageQuantity}
                        </Typography>
                      )}
                  </Box>

                  {/* <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  /> */}
                </Box>
                <Box display='flex' width='2%' justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '35px',

                      width: '2.4px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    {medication.dosageQuantity &&
                      medication.dosageQuantityType && (
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            wordWrap: 'break-word',
                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          {getUnitOfDosageFromRequest(
                            medication.dosageQuantityType
                          )}
                        </Typography>
                      )}
                    {medication.dosageQuantity === undefined &&
                      medication.dosageQuantityType && (
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            wordWrap: 'break-word',
                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          {getUnitOfDosageFromRequest(
                            medication.dosageQuantityType
                          )}
                        </Typography>
                      )}
                  </Box>
                </Box>
                <Box display='flex' width='1.5%' justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '35px',

                      width: '2.4px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    {medication.administrationCode && (
                      <Box display='flex' flexGrow flexDirection='row'>
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          {getUnitOfRouteRequest(medication.administrationCode)}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box display='flex' width='1.5%' justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '35px',

                      width: '2.4px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={split === false && history === false ? 3 : 4}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    {medication.timeOfDayCode && medication.mealCode && (
                      <Typography
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {getTimeOfDayDisplayMeal(medication.mealCode) ===
                        'Anytime'
                          ? `${getTimeOfDayDisplayForStatement(
                              medication.timeOfDayCode
                            ).join(', ')}`
                          : `${getTimeOfDayDisplayForStatement(
                              medication.timeOfDayCode
                            ).join(', ')}  (${
                              getTimeOfDayDisplayMeal(medication.mealCode) ===
                              'Anytime'
                                ? ''
                                : getTimeOfDayDisplayMeal(medication.mealCode)
                            } ${
                              getTimeOfDayDisplayMeal(medication.mealCode) ===
                              'Anytime'
                                ? ''
                                : 'Meal'
                            })`}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            {split === false && history === false && (
              <Grid item xs={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Tooltip title='Edit' id='chief_tool_edit'>
                        <IconButton
                          aria-label='btn_ord_cancel'
                          color='primary'
                          id='medication_edit_button'
                          style={{ padding: 0 }}
                          onClick={() => {
                            if (onEditClicked) onEditClicked(medication)
                          }}
                        >
                          <img
                            id='chief_tool_edit_img'
                            src={`${process.env.PUBLIC_URL}/editVector.png`}
                            alt='Edit'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          {medication.notes && medication.notes.length > 0 && (
            <Grid
              container
              direction='row'
              id='vitals'
              style={{ backgroundColor: kDialogueBackground }}
            >
              <Grid item xs={12}>
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        alignItems='center'
                        minHeight={30}
                        flexDirection='row'
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          paddingRight={0.3}
                        >
                          <Typography
                            variant='subtitle2'
                            // color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,
                              color: 'black',

                              fontWeight: 'bold',
                              lineHeight: 'normal',
                            }}
                          >
                            Notes :
                          </Typography>
                        </Box>
                        <Typography
                          // display='inline'
                          // dangerouslySetInnerHTML={{
                          //   __html: getCommunicationRequestPayloadString(e) ?? '',
                          // }}
                          onClick={() => {
                            setFullText(medication.notes ?? '')
                            setShowText(true)
                          }}
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            wordWrap: 'break-word',
                            whiteSpace: 'pre-line',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {medication.notes ?? ''}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>

        {/* <Grid item xs={6}>
              <Box>
                {medication.timeOfDayCode && (
                  <Box display='flex' width='50%' flexGrow flexDirection='row'>
                    <Typography variant='body2' color='initial'>
                      {getTimeOfDayDisplayForStatement(
                        medication.timeOfDayCode
                      ).join(', ')}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid> */}
        {/* <Grid item xs={3}>
              <Box px={2}>
                {medication.mealCode && (
                  <Box display='flex' flexGrow flexDirection='row'>
                    <Typography variant='body2' color='initial'>
                      Meal : {getTimeOfDayDisplayMeal(medication.mealCode)}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid> */}
        {/* <Grid item xs={3}>
              <Box px={2}>
                {medication.administrationCode && (
                  <Box display='flex' flexGrow flexDirection='row'>
                    <Typography variant='body2' color='initial'>
                      {getUnitOfRouteRequest(medication.administrationCode)}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid> */}
        {/* </Grid> */}
        {/* <Grid container direction='row' id='vitals'>
            <Grid item xs={6}>
              <Box>
                {medication.date && (
                  <Box display='flex' flexGrow flexDirection='row'>
                    <Typography variant='subtitle2' color='initial'>
                      Recorded on {moment(medication.date).format('DD-MM-YYYY')}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box px={2}>
                 {medication.textForm && (
                    <Box display='flex' width='50%' flexGrow flexDirection='row'>
                      <Typography variant='body2' color='initial'>
                        {medication.textForm}
                      </Typography>
                    </Box>
                  )} 
              </Box>
            </Grid>
          </Grid> */}
      </Grid>
      <NoteDialog
        id='notesTile'
        open={showNotesText}
        onClose={() => {
          setFullText('')
          setShowNotesText(false)
        }}
        notesData={fullText!}
        dialogText='Notes'
      />
    </Box>
  )
}
