import { Box, Tab, Tabs } from '@material-ui/core'
import React from 'react'

export interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ overflow: 'auto', height: '100%' }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}
