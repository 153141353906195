import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  getCodeOfSystemFromCodableConceptList,
  getDefaultCodeOfSystemFromCodableConcept,
} from 'utils/fhirResourcesHelper'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getTimeOfDayDisplayForStatement,
  getTimeOfDayDisplayMeal,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import { getObservationContent } from 'utils/fhirResoureHelpers/observationHelpers'
import {
  getBottom4HabitList,
  getTop4HabitListForTile,
} from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { kDialogueBackground } from 'configs/styles/muiThemes'

interface MedicationTileProps {
  topObs: R4.IObservation[]
  id: string
}

export const HabitStatementTile: React.FC<MedicationTileProps> = ({
  topObs,
  id,
}: MedicationTileProps) => {
  const bottom4Habits: R4.IObservation[] = getBottom4HabitList(topObs)
  const top4: R4.IObservation[] = getTop4HabitListForTile(topObs)

  const firstColumn: R4.IObservation[] = top4.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) => getDefaultCodeOfSystemFromCodableConcept(t.code) === '10000-2'
      )
  )
  const secColumn: R4.IObservation[] = top4.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) => getDefaultCodeOfSystemFromCodableConcept(t.code) === '65515-9'
      )
  )
  const thirdColumn: R4.IObservation[] = top4.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) => getDefaultCodeOfSystemFromCodableConcept(t.code) === '74008-4'
      )
  )

  const fourthColumn: R4.IObservation[] = top4.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) => getDefaultCodeOfSystemFromCodableConcept(t.code) === '74009-2'
      )
  )

  const fiveColumn: R4.IObservation[] = bottom4Habits.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) => getDefaultCodeOfSystemFromCodableConcept(t.code) === '65968-0'
      )
  )
  const sixColumn: R4.IObservation[] = bottom4Habits.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) => getDefaultCodeOfSystemFromCodableConcept(t.code) === '65561-3'
      )
  )
  const sevenColumn: R4.IObservation[] = bottom4Habits.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) => getDefaultCodeOfSystemFromCodableConcept(t.code) === '10000-1'
      )
  )

  const otherColumn: R4.IObservation[] = bottom4Habits.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          getDefaultCodeOfSystemFromCodableConcept(t.code) === 'other-habit'
      )
  )

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
      id={`${id}column`}
    >
      <Grid container direction='column' spacing={1} id={`${id}columnData`}>
        <Grid
          container
          direction='row'
          id={`${id}rowData`}
          style={{
            backgroundColor: 'white',
          }}
        >
          <Grid item xs={3} id={`${id}obs1Def`}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Coffee
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' width='2%'>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',
                    maxHeight: '100px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} id={`${id}obs1Data`}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  {firstColumn.length > 0 && (
                    <Typography
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      {getObservationContent(firstColumn[0])}
                    </Typography>
                  )}
                </Box>

                {/* <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  /> */}
              </Box>
              <Box display='flex' width='2%'>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',
                    maxHeight: '100px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} id={`${id}obs2Def`}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Energy level in past seven days
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' width='2%'>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',
                    maxHeight: '100px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} id={`${id}obs2Data`}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  {secColumn.length > 0 && (
                    <Typography
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      {getObservationContent(secColumn[0])}
                    </Typography>
                  )}
                </Box>
              </Box>
              {/* <Box display='flex' width='2%' >
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '30px',

                    width: '2px',
                  }}
                />
              </Box> */}
            </Box>
          </Grid>
        </Grid>

        <Grid container direction='row' id='vitals'>
          <Grid item xs={12}>
            <Box width='100%' paddingLeft={0.2}>
              <Divider
                orientation='horizontal'
                flexItem
                style={{
                  height: '2px',
                }}
              />
            </Box>
          </Grid>
        </Grid>

        {/* ------------------------------------------------------------------------------- */}

        <Grid
          container
          direction='row'
          style={{
            backgroundColor: kDialogueBackground,
          }}
          id={`${id}obs2DataRow`}
        >
          <Grid item xs={3} id={`${id}obs2DataRowDef`}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Exercise Intensity
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' width='2%' paddingTop={0.1}>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',
                    maxHeight: '100px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} id={`${id}obs2DataRowObsData`}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  {thirdColumn.length > 0 && (
                    <Typography
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      {getObservationContent(thirdColumn[0])}
                    </Typography>
                  )}
                </Box>

                {/* <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  /> */}
              </Box>
              <Box display='flex' width='2%' paddingTop={0.1}>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',
                    maxHeight: '100px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} id={`${id}obs2DataRowObsData2`}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Exercise duration/Exercise Frequency
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' width='2%' paddingTop={0.1}>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',
                    maxHeight: '100px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} id={`${id}obs2DataRowObsData3`}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  {fourthColumn.length > 0 && (
                    <Typography
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      {getObservationContent(fourthColumn[0])}
                    </Typography>
                  )}
                </Box>
              </Box>
              {/* <Box display='flex' width='2%' paddingTop={0.1}>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box> */}
            </Box>
          </Grid>
        </Grid>

        <Grid container direction='row' id='vitals'>
          <Grid item xs={12}>
            <Box width='100%' paddingLeft={0.2}>
              <Divider
                orientation='horizontal'
                flexItem
                style={{
                  height: '2px',
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          direction='row'
          id={`${id}obs2DataRowObsData4`}
          style={{
            backgroundColor: 'white',
          }}
        >
          <Grid item xs={3}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Sleep during day time
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' width='2%'>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',
                    maxHeight: '100px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} id={`${id}obs2DataRowObsData5`}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  {fiveColumn.length > 0 && (
                    <Typography
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      {getObservationContent(fiveColumn[0])}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box display='flex' width='2%'>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',
                    maxHeight: '100px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} id={`${id}obs2DataRowObsData6`}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Sleep during night time
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' width='2%'>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',
                    maxHeight: '100px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} id={`${id}obs2DataRowObsData7`}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  {sixColumn.length > 0 && (
                    <Typography
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      {getObservationContent(sixColumn[0])}
                    </Typography>
                  )}
                </Box>
              </Box>
              {/* <Box display='flex' width='2%' >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box> */}
            </Box>
          </Grid>
        </Grid>

        <Grid container direction='row' id='vitals'>
          <Grid item xs={12}>
            <Box width='100%' paddingLeft={0.2}>
              <Divider
                orientation='horizontal'
                flexItem
                style={{
                  height: '2px',
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          direction='row'
          style={{
            backgroundColor: kDialogueBackground,
          }}
          id={`${id}obs2DataRowObsData8`}
        >
          <Grid item xs={3} id={`${id}obs2DataRowObsData9`}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Tea
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' width='2%'>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',
                    maxHeight: '100px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} id={`${id}obs2DataRowObsData10`}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  {sevenColumn.length > 0 && (
                    <Typography
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      {getObservationContent(sevenColumn[0])}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box display='flex' width='2%'>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',
                    maxHeight: '100px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} id={`${id}obs2DataRowObsData11`}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Other Habits
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' width='2%'>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',
                    maxHeight: '100px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} id={`${id}obs2DataRowObsData12`}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  minHeight={30}
                  maxHeight={100}
                  alignItems='center'
                  px={1}
                >
                  {otherColumn.length > 0 && (
                    <Typography
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      {getObservationContent(otherColumn[0])}
                    </Typography>
                  )}
                </Box>
              </Box>
              {/* <Box display='flex' width='2%' >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box> */}
            </Box>
          </Grid>
        </Grid>

        <Grid container direction='row' id='vitals'>
          <Grid item xs={12}>
            <Box width='100%' paddingLeft={0.2}>
              <Divider
                orientation='horizontal'
                flexItem
                style={{
                  height: '2px',
                }}
              />
            </Box>
          </Grid>
        </Grid>

        {/* ------------------------------------------------------------------------------- */}
      </Grid>
    </Box>
  )
}
