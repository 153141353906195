import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchReports } from 'redux/lab/reportManagement/reportSearchSlice'
import { RootState } from 'redux/rootReducer'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { ReportDataTable } from './reportDataTable'

export interface IUnitAdmin {
  onTap: () => void
}

export const ReportSearch: React.FC<IUnitAdmin> = ({ onTap }) => {
  const reportSearchSlice = useSelector(
    (state: RootState) => state.reportSearchSlice
  )

  const dispatch = useDispatch()
  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<R4.IOrganization>(getCurrentUserUnitDetails())
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      currentOrganizationDetails.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  useEffect(() => {
    dispatch(searchReports())
  }, [dispatch])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
            >
              {reportSearchSlice.searching && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>
            <Box
              display='flex'
              flexGrow={4}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
              style={{
                backgroundColor: '#ececec',
              }}
            >
              {reportSearchSlice.noResultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    align='center'
                  >
                    No Report(s) found.
                  </Typography>
                </Box>
              )}
              {reportSearchSlice.error && (
                <Box
                  //   display='flex'
                  //   flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                  paddingLeft={50}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container justifyContent='center'>
                        <Typography
                          variant='subtitle1'
                          color='error'
                          align='center'
                        >
                          Error while searching Reports . Please try again
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {reportSearchSlice.resultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height='100%'
                  overflow='auto'
                  // flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexGrow={1}
                    overflow='auto'
                    height='100%'
                  >
                    <ReportDataTable
                      usersData={
                        reportSearchSlice.reportDisplay
                          ? reportSearchSlice.reportDisplay
                          : []
                      }
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </div>
  )
}
