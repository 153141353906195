/* eslint-disable no-irregular-whitespace */
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-vis/dist/style.css'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { DTRReflectionExaminationsListPT } from './reflectionsExaminations/dTRReflectionExamination'
import { PrimitiveReflectionExaminationsListPT } from './reflectionsExaminations/premitiveReflectionExamination'
import { SuperficialReflectionsExaminationsListPT } from './reflectionsExaminations/superficialReflectionExamination'

interface ReflectionsExaminationsListPTProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  isReadOnly?: boolean
}

export const ReflectionsExaminationsListPT: React.FC<ReflectionsExaminationsListPTProps> =
  ({
    fhirAppointmentDetails,
    isReadOnly = false,
  }: ReflectionsExaminationsListPTProps) => {
    const { t } = useTranslation(['common'])
    const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())

    return (
      <Grid container direction='column' style={{ padding: 0, margin: 0 }}>
        <Grid container direction='row' style={{ padding: 0, margin: 0 }}>
          <Box
            sx={{ width: '100%' }}
            display='flex'
            alignItems='center'
            flexDirection='row'
            bgcolor='#999999'
            minHeight={40}
          >
            <Box p={1}>
              <Typography
                variant='body1'
                style={{ color: 'white', fontWeight: 600 }}
              >
                {' '}
                Reflexes
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid container direction='row' style={{ padding: 0, margin: 0 }}>
          <Grid item xs={5}>
            <PrimitiveReflectionExaminationsListPT
              fhirAppointmentDetails={fhirAppointmentDetails}
              isReadOnly={isReadOnly}
            />
          </Grid>
          <Divider orientation='vertical' flexItem />
          <Grid item xs={5}>
            <DTRReflectionExaminationsListPT
              fhirAppointmentDetails={fhirAppointmentDetails}
              isReadOnly={isReadOnly}
            />
          </Grid>
          <Grid item xs={4}>
            <SuperficialReflectionsExaminationsListPT
              fhirAppointmentDetails={fhirAppointmentDetails}
              isReadOnly={isReadOnly}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }
