import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { BpCollection } from 'models/bpData'
import { ConsumableData } from 'models/consumableData'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { getSortedObservation } from 'utils/appointment_handle/vitals_util'
import { getAddedSubsTance } from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { getObserVationFfromEncounter } from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { getTreatmentPlanFromBundle } from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { logger } from 'utils/logger'
import { SubstanceSearchTypeStatus } from './substanceSearchTypeStatus'

const initialState: SubstanceSearchTypeStatus = {
  searchingTreatment: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingTreatment: false,
}

const substanceSearchSliceFinal = createSlice({
  name: 'substanceSearchSliceFinal',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<SubstanceSearchTypeStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingTreatment = action.payload.searchingTreatment
      state.resultsAvailable = action.payload.resultsAvailable
      state.treatmentList = action.payload.treatmentList
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingTreatment =
        action.payload.errorWhileSearchingTreatment
    },
  },
})

export const requestForSubstance =
  (appointmentId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: SubstanceSearchTypeStatus = {
      searchingTreatment: true,
      errorWhileSearchingTreatment: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(substanceSearchSliceFinal.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const response: any = await fhirClient.doGetResourceForAppointmentIPD(
        `Task?_include:iterate=Substance:substance-service-billing&_include:iterate=Task:focus&code=supply-request&based-on=ServiceRequest/${appointmentId}`
      )
      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      //   if (resp._tag === 'Left') {

      //     state.errorWhileSearchingTreatment = true
      //     state.searchingTreatment = false

      //     dispatch(substanceSearchSliceFinal.actions.updatedStatus(state))
      //   } else {
      const proceduresResponse: R4.IBundle = response

      if (proceduresResponse?.total && proceduresResponse?.total > 0) {
        const finalData: ConsumableData[] =
          getAddedSubsTance(proceduresResponse)
        finalData.sort((a, b) => moment(b.date).diff(a.date))

        state.resultsAvailable = true
        state.searchingTreatment = false
        state.treatmentList = finalData
        state.noResultsAvailable = false
        state.errorReason = undefined
        state.errorWhileSearchingTreatment = false
        dispatch(substanceSearchSliceFinal.actions.updatedStatus(state))
      } else {
        const errorSearchDoctor: SubstanceSearchTypeStatus = {
          searchingTreatment: false,
          errorWhileSearchingTreatment: false,
          resultsAvailable: false,
          noResultsAvailable: true,
        }
        dispatch(
          substanceSearchSliceFinal.actions.updatedStatus(errorSearchDoctor)
        )
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: SubstanceSearchTypeStatus = {
        searchingTreatment: false,
        errorWhileSearchingTreatment: true,
        resultsAvailable: false,
        errorReason: 'Error while searching substance',
      }
      dispatch(
        substanceSearchSliceFinal.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

async function updateEnrolment(bpCollection: BpCollection): Promise<string> {
  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `/enrolment/update-bp`,
      bpCollection
    )
    return response.BPCategory
  } catch (error) {
    return ''
  }
}

async function updateAppointment(): Promise<string> {
  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const enRolClient: EnrolCient = new EnrolCient()
  //   const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
  //     `/enrolment/update-bp`,
  //     bpCollection
  //   )

  return 'test'
}

async function getServiceRequestId(patientId: string): Promise<string> {
  logger.info('Patient invitation body')
  let serviceRequestId: string = ''
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirClient.doGetResource(
    `/ServiceRequest??code=chronic-care-enrolment&subject=${patientId}`
  )
  logger.info('Patient invitation Response')
  logger.info(response)
  const respDecoded: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (respDecoded._tag === 'Right') {
    logger.info('Response Patient un decoded', respDecoded.right)
    const responseBundel: R4.IBundle = respDecoded.right
    if (responseBundel.entry) {
      const entryObj = responseBundel.entry?.[0].resource as R4.IServiceRequest
      serviceRequestId =
        entryObj && entryObj.status === 'active' ? entryObj.id ?? ' ' : ''
      return serviceRequestId
    }
    return serviceRequestId
  }

  return serviceRequestId
}

export default substanceSearchSliceFinal.reducer
