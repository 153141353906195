import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'

import { logger } from 'utils/logger'

import { EbmClinet } from 'services/ebmServiceClient'

import { TargetCohorts } from 'models/cohortTypes'
import { TargetCoortSearchStatus } from './targetCohortSearchStatus'

const initialState: TargetCoortSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const targetCohortSearchSlice = createSlice({
  name: 'targetCohortSearchSlice',
  initialState,
  reducers: {
    searchingAllergy(state, action: PayloadAction<TargetCoortSearchStatus>) {},

    searchResults(state, action: PayloadAction<TargetCoortSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable

      state.cohortList = action.payload.cohortList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<TargetCoortSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.cohortList = action.payload.cohortList
    },

    errorWhileSearching(state, action: PayloadAction<TargetCoortSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable

      state.cohortList = action.payload.cohortList
    },
    resetState(state, action: PayloadAction<TargetCoortSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false

      state.cohortList = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: TargetCoortSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      cohortList: undefined,
    }
    dispatch(targetCohortSearchSlice.actions.resetState(state))
  }

export const searchCohorts = (): AppThunk => async (dispatch: AppDispatch) => {
  const errorSearchPatient: TargetCoortSearchStatus = {
    error: false,
    noResultsAvailable: false,
    resultsAvailable: false,
    searching: true,
  }
  dispatch(
    targetCohortSearchSlice.actions.errorWhileSearching(errorSearchPatient)
  )
  try {
    const fhirClient: EbmClinet = new EbmClinet()
    const response: any = await fhirClient.doGetResource(
      `/ir/definition/drug_to_bp`
    )

    const targerCohorts: TargetCohorts[] = []
    response.expression.targetCohorts.forEach((el: any) => {
      targerCohorts.push({
        targetId: el.id,
        label: el.name,
        id: el.id.toString(),
      })
    })

    const sorttedTargerCohorts: TargetCohorts[] = targerCohorts.sort(
      (a: any, b: any) => {
        const nameA = a.label.toUpperCase() // ignore upper and lowercase
        const nameB = b.label.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) {
          return -1 // nameA comes first
        }
        if (nameA > nameB) {
          return 1 // nameB comes first
        }
        return 0 // names must be equal
      }
    )
    if (sorttedTargerCohorts.length > 0) {
      const searchPatientResult: TargetCoortSearchStatus = {
        error: false,
        noResultsAvailable: false,
        resultsAvailable: true,
        searching: false,
        cohortList: sorttedTargerCohorts,
      }

      dispatch(
        targetCohortSearchSlice.actions.searchResults(searchPatientResult)
      )
      return
    }

    const noSearchResults: TargetCoortSearchStatus = {
      error: false,
      noResultsAvailable: true,
      resultsAvailable: false,
      searching: false,
    }
    dispatch(
      targetCohortSearchSlice.actions.noDataFoundForSearch(noSearchResults)
    )
    return
  } catch (error) {
    logger.error(error)
    const errorWhileSearchPatient: TargetCoortSearchStatus = {
      error: true,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: 'Error',
    }
    dispatch(
      targetCohortSearchSlice.actions.errorWhileSearching(
        errorWhileSearchPatient
      )
    )
  }
}

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(targetCohortSearchSlice.actions.resetState(initialState))
}

export default targetCohortSearchSlice.reducer
