import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, MenuItem, TextField, TextFieldProps } from '@material-ui/core'
import React, { useState } from 'react'
import { PatientAddressTypes } from 'utils/constants/address_types'
import { WelloFormItemLabel } from 'wello-web-components'

interface WelloOccupationProps {
  title: string
  textFieldProps: TextFieldProps
  onChanged: (selectedOccupation: any) => void
  preSelectedOccupation?: any
  displayDoneLabel?: boolean
}

export const WelloAddressTypeSelector: React.FunctionComponent<WelloOccupationProps> =
  (props: WelloOccupationProps) => {
    const [selectedOccupation, setSelectedOccupation] = useState<any>(
      props.preSelectedOccupation ?? { code: '', display: 'Select' }
    )

    return (
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <WelloFormItemLabel
          title={props.title}
          displayDoneLabel={props.displayDoneLabel}
        />
        <Box>
          <TextField
            variant='outlined'
            fullWidth
            size='small'
            required={false}
            select
            value={selectedOccupation?.id}
            label={selectedOccupation?.id === '' ? 'Select' : ''}
            onChange={(val) => {
              const selectedOccu: R4.ICoding | undefined =
                PatientAddressTypes.find(
                  (type: R4.ICoding) => type.code === val.target.value
                )
              setSelectedOccupation(
                selectedOccu ?? { code: '', display: 'Select' }
              )
              props.onChanged(selectedOccu)
            }}
            {...props.textFieldProps}
          >
            {PatientAddressTypes.map((option) => (
              <MenuItem
                id={option.code}
                key={option.code}
                value={option.code}
                selected={selectedOccupation?.code === option.code}
                disabled={option?.code === ''}
              >
                {option.display}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
    )
  }
