/* eslint-disable import/no-named-as-default */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, MenuItem, TextField, TextFieldProps } from '@material-ui/core'
import { ArrowDropDownSharp } from '@material-ui/icons'

import React, { useState } from 'react'
import { WelloFormItemLabel } from 'wello-web-components'

interface WelloSelectCodingProps {
  title?: string
  id?: string
  duration?: string
  textFieldProps: TextFieldProps
  availableCodings: R4.ICoding[]
  onChanged: (selectedCoding: R4.ICoding) => void
  preSelectedCoding?: R4.ICoding
  displayDoneLabel?: boolean
  selectedColumn?: any[]
  role?: string
}

export const WelloSelectFHIRCodingWithoutTitle: React.FunctionComponent<WelloSelectCodingProps> =
  ({
    title,
    id,
    duration,
    textFieldProps,
    availableCodings,
    onChanged,
    preSelectedCoding,
    displayDoneLabel,
    selectedColumn,
    role,
  }: WelloSelectCodingProps) => {
    const options: R4.ICoding[] = [
      { code: '', display: `${duration || 'Select'}` },
      ...availableCodings.sort((first, second) => {
        if ((first.display ?? '') < (second.display ?? '')) {
          return -1
        }
        if ((first.display ?? '') > (second.display ?? '')) {
          return 1
        }
        return 0
      }),
    ]

    const [item, setItem] = useState<R4.ICoding>(
      preSelectedCoding || { code: '', display: `${duration || 'Select'}` }
    )

    return (
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <Box display='flex' flexDirection='column'>
          <TextField
            variant='outlined'
            fullWidth
            size='small'
            select
            InputProps={{
              id: id ?? 'select_sum',
            }}
            placeholder={role}
            SelectProps={{
              IconComponent: ArrowDropDownSharp,
              MenuProps: {
                transformOrigin: {
                  vertical: options.length <= 10 ? -80 : 100,
                  horizontal: 100,
                },
                anchorOrigin: { vertical: 200, horizontal: 100 },
              },
            }}
            value={item?.code}
            label={item?.code === '' ? `${duration || 'Select'}` : ''}
            onChange={(val) => {
              const selectedItem: R4.ICoding | undefined =
                availableCodings.find(
                  (gender) => gender.code === val.target.value
                )

              if (selectedItem) {
                setItem(selectedItem)
                onChanged(selectedItem)
              }
            }}
            {...textFieldProps}
          >
            {options.map((option) => (
              <MenuItem
                style={{
                  maxHeight: availableCodings.length <= 5 ? 500 : 200,
                }}
                id={option.code}
                key={option.code}
                value={option.code}
                role={`menu${option.code}`}
                selected={item?.code === option.code}
                disabled={
                  option?.code === '' ||
                  (selectedColumn && selectedColumn.includes(option.code))
                }
              >
                {option.display}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
    )
  }
