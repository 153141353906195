import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
  onClose: () => void
  onDiscard: () => void
  displayText: string
  title: string
  doneButtonText: string
  cancelButtonText: string
}
export const InformationDialog: React.FC<Props> = ({
  open,
  onClose,
  onDiscard,
  displayText,
  title,
  doneButtonText,
  cancelButtonText,
}) => {
  const { t } = useTranslation()

  useEffect(() => {})

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>{t(`${title}`)}</DialogTitle>
      <DialogContent dividers={true}>
        <Alert
          severity='warning'
          style={{
            backgroundColor: '#FFFFFF',
            color: useTheme().palette.common.black,
          }}
        >
          <Typography variant='body1'>{displayText}</Typography>
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onDiscard()
          }}
          variant='contained'
          color='primary'
        >
          <Typography variant='button'>Okay</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
