import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  IdentifierUseKind,
  SlotStatusKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import moment from 'moment'
import { getCurrentUserUnitLocationDetails } from 'services/userDetailsService'
import { getStringValueExtensionsOfUrl } from 'utils/fhirResourcesHelper'

const appType = ['In-Person', 'Phone', 'Video']
const locationDetails = getCurrentUserUnitLocationDetails()

let slotDuration = 10
const slotTime = getStringValueExtensionsOfUrl(
  locationDetails.extension,
  'http://wellopathy.com/fhir/india/core/ExtUrl/locationSlotDuration'
)
if (slotTime) slotDuration = parseInt(slotTime, 10)

const getSlot = (
  startDate: any,
  ref: string,
  min: any,
  selectedDoc: any,
  appointmentType: any
) => {
  const serviceTypeListLab: React.SetStateAction<R4.ICodeableConcept[]> = []
  const serviceTypeLab: R4.ICoding = {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
    code: 'specimen-collection',
    display: 'Specimen Collection',
  }
  const serviceTypeCodeable: R4.ICodeableConcept = {}
  const serviceTypeCodingList: React.SetStateAction<R4.ICoding[]> = []
  serviceTypeCodingList.push(serviceTypeLab)
  serviceTypeCodeable.coding = serviceTypeCodingList
  serviceTypeListLab.push(serviceTypeCodeable)
  const iRequest: R4.IBundle_Request = {}
  iRequest.method = R4.Bundle_RequestMethodKind._post
  iRequest.url = 'Slot'
  const slot: R4.ISlot = {
    resourceType: 'Slot',
    schedule: {
      reference: ref,
    },
  }
  const identifier: R4.IIdentifier = {}
  identifier.use = IdentifierUseKind._usual
  identifier.system = 'mrn-tenant1'
  identifier.value = `Slot${Math.random().toString(36).substring(2)}`
  if (slot.identifier == null) {
    slot.identifier = [identifier]
  } else {
    slot.identifier.push(identifier)
  }
  const serviceCategoryList: React.SetStateAction<R4.ICodeableConcept[]> = []
  const serviceCodeable: R4.ICodeableConcept = {}
  const serviceCodingList: React.SetStateAction<R4.ICoding[]> = []
  const clinicService: R4.ICoding = {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-category',
    code: '8',
    display: 'Counselling',
  }
  const labServiceType: R4.ICoding = {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-category',
    code: '9',
    display: 'Laboratory Service',
  }
  if (selectedDoc.roleObject.specialty !== undefined) {
    serviceCodingList.push(clinicService)
  } else {
    serviceCodingList.push(labServiceType)
  }
  serviceCodeable.coding = serviceCodingList
  serviceCategoryList.push(serviceCodeable)
  slot.serviceCategory = serviceCategoryList
  const serviceTypeList = getAppointmentTypeconst(appointmentType)
  if (selectedDoc.roleObject.specialty !== undefined) {
    slot.serviceType = serviceTypeList
  } else {
    slot.serviceType = serviceTypeListLab
  }

  const specialtyList: React.SetStateAction<R4.ICodeableConcept[]> = []
  if (selectedDoc.roleObject.specialty !== undefined) {
    for (let i = 0; i < selectedDoc.roleObject.specialty.length; i++) {
      specialtyList.push(selectedDoc.roleObject.specialty[i])
    }
    slot.specialty = specialtyList
  }

  serviceTypeCodingList.push(serviceTypeLab)
  serviceTypeCodeable.coding = serviceTypeCodingList

  serviceTypeListLab.push(serviceTypeCodeable)

  const appointmentTypeCodeable: R4.ICodeableConcept = {}
  const appointmentTypeCodingList: React.SetStateAction<R4.ICoding[]> = []
  const type: R4.ICoding = {
    code: 'FOLLOWUP',
    system: 'http://terminology.hl7.org/CodeSystem/v2-0276',
  }
  appointmentTypeCodingList.push(type)
  appointmentTypeCodeable.coding = appointmentTypeCodingList
  if (selectedDoc.roleObject.specialty !== undefined) {
    slot.appointmentType = appointmentTypeCodeable
  } else {
    slot.appointmentType = serviceTypeCodeable
  }

  slot.status = SlotStatusKind._free
  slot.start = startDate
  const endDate: any = moment(startDate)
    .add(slotDuration, 'minutes')
    .format('YYYY-MM-DDTHH:mm:ssZ')
  slot.end = moment(startDate).add(slotDuration, 'minutes').toISOString()
  const bundleEntry: R4.IBundle_Entry = {
    request: iRequest,
    resource: slot,
  }
  return bundleEntry
}

export function getScheduleBundle(
  locationDetails1: R4.ILocation,
  scheduleList: any,
  selectedSchedules: any,
  selectedDoctor: any,
  appType1: any
): R4.IBundle {
  const slotTime1 = getStringValueExtensionsOfUrl(
    locationDetails1.extension,
    'http://wellopathy.com/fhir/india/core/ExtUrl/locationSlotDuration'
  )

  slotDuration = parseInt(slotTime1 ?? '10', 10)
  const bundleArray: any = []
  const serviceTypeListLab: React.SetStateAction<R4.ICodeableConcept[]> = []
  const serviceTypeLab: R4.ICoding = {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
    code: 'specimen-collection',
    display: 'Specimen Collection',
  }
  const serviceTypeCodeable: R4.ICodeableConcept = {}
  const serviceTypeCodingList: React.SetStateAction<R4.ICoding[]> = []
  serviceTypeCodingList.push(serviceTypeLab)
  serviceTypeCodeable.coding = serviceTypeCodingList
  serviceTypeListLab.push(serviceTypeCodeable)
  if (scheduleList.length > 0) {
    for (let i = 0; i < selectedSchedules.length; i++) {
      const serviceTypeList = getAppointmentTypeconst(
        selectedSchedules[i].extendedProps.title
      )
      const isPresent = scheduleList.some(
        (el: any) =>
          el.startTime ===
          moment(selectedSchedules[i].start).utc().toISOString()
      )
      if (isPresent === false) {
        const practitionerRoleRef: R4.IReference = {
          reference: `PractitionerRole/${selectedDoctor.roleObject.id}`,
        }

        const practitionerRef: R4.IReference = {
          reference: `${selectedDoctor.roleObject.practitioner.reference}`,
          display: `${selectedDoctor.roleObject.practitioner.display}`,
          type: `${selectedDoctor.roleObject.practitioner.type}`,
        }
        const schedule: R4.ISchedule = {
          resourceType: 'Schedule',
          actor: [],
        }
        schedule.active = true
        if (selectedDoctor.roleObject.specialty !== undefined) {
          schedule.serviceType = serviceTypeList
        } else {
          schedule.serviceType = serviceTypeListLab
        }
        if (selectedDoctor.roleObject.healthcareService) {
          const healthcareServiceRef: R4.IReference = {
            reference: selectedDoctor.roleObject.healthcareService[0].reference,
          }

          schedule.actor.push(healthcareServiceRef)
        }
        schedule.actor.push(practitionerRoleRef)
        schedule.actor.push(practitionerRef)
        const identifier: R4.IIdentifier = {}
        identifier.use = IdentifierUseKind._usual
        identifier.system = 'mrn-tenant1'
        identifier.value = `Schedule${Math.random().toString(36).substring(2)}`
        if (schedule.identifier == null) {
          schedule.identifier = [identifier]
        } else {
          schedule.identifier.push(identifier)
        }
        const horizon: R4.IPeriod = {}

        horizon.start = moment(selectedSchedules[i].start).utc().toISOString()
        horizon.end = moment(selectedSchedules[i].end).utc().toISOString()
        schedule.planningHorizon = horizon
        const iRequest: R4.IBundle_Request = {}
        iRequest.method = R4.Bundle_RequestMethodKind._post
        iRequest.url = 'Schedule'
        const bundleEntry: R4.IBundle_Entry = {
          fullUrl: `urn:uuid:schedule${i}`,
          request: iRequest,
          resource: schedule,
        }
        bundleArray.push(bundleEntry)
        const startDate = moment(selectedSchedules[i].startStr)
        const endDate = moment(selectedSchedules[i].endStr)
        const test = moment.duration(endDate.diff(startDate))
        schedule.comment = selectedSchedules[i].extendedProps.repeat
        const mins = test.asHours() * 60
        for (let time = 0; time < mins; time += slotDuration) {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const startDate = moment(selectedSchedules[i].startStr).add(
            time,
            'minutes'
          )
          const ref = `urn:uuid:schedule${i}`
          const slot = getSlot(
            startDate,
            ref,
            time,
            selectedDoctor,
            selectedSchedules[i].extendedProps.title
          )
          bundleArray.push(slot)
        }
      }
    }
  } else {
    for (let m = 0; m < selectedSchedules.length; m++) {
      const practitionerRoleRef: R4.IReference = {
        reference: `PractitionerRole/${selectedDoctor.roleObject.id}`,
      }

      const practitionerRef: R4.IReference = {
        reference: `${selectedDoctor.roleObject.practitioner.reference}`,
        display: `${selectedDoctor.roleObject.practitioner.display}`,
        type: `${selectedDoctor.roleObject.practitioner.type}`,
      }
      const schedule: R4.ISchedule = {
        resourceType: 'Schedule',
        actor: [],
      }
      schedule.active = true
      if (selectedDoctor.roleObject.healthcareService) {
        const healthcareServiceRef: R4.IReference = {
          reference: selectedDoctor.roleObject.healthcareService[0].reference,
        }

        schedule.actor.push(healthcareServiceRef)
      }

      schedule.actor.push(practitionerRoleRef)
      schedule.actor.push(practitionerRef)

      const identifier: R4.IIdentifier = {}
      identifier.use = IdentifierUseKind._usual
      identifier.system = 'mrn-tenant1'
      identifier.value = `Schedule${Math.random().toString(36).substring(2)}`
      if (schedule.identifier == null) {
        schedule.identifier = [identifier]
      } else {
        schedule.identifier.push(identifier)
      }
      const serviceTypeList = getAppointmentTypeconst(
        selectedSchedules[m].extendedProps.title
      )
      if (selectedDoctor.roleObject.specialty !== undefined) {
        schedule.serviceType = serviceTypeList
      } else {
        schedule.serviceType = serviceTypeListLab
      }
      schedule.comment = selectedSchedules[m].extendedProps.repeat
      const horizon: R4.IPeriod = {}
      horizon.start = moment(selectedSchedules[m].start).utc().toISOString()
      horizon.end = moment(selectedSchedules[m].end).utc().toISOString()
      schedule.planningHorizon = horizon
      const iRequest: R4.IBundle_Request = {}
      iRequest.method = R4.Bundle_RequestMethodKind._post
      iRequest.url = 'Schedule'
      const bundleEntry: R4.IBundle_Entry = {
        fullUrl: `urn:uuid:schedule${m}`,
        request: iRequest,
        resource: schedule,
      }
      bundleArray.push(bundleEntry)
      const startDate = moment(selectedSchedules[m].startStr)
      const endDate = moment(selectedSchedules[m].endStr)
      const test = moment.duration(endDate.diff(startDate))
      const mins = test.asHours() * 60
      for (let timeSlot = 0; timeSlot < mins; timeSlot += slotDuration) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const startDate = moment(selectedSchedules[m].startStr).add(
          timeSlot,
          'minutes'
        )
        const ref = `urn:uuid:schedule${m}`
        const slot = getSlot(
          startDate,
          ref,
          timeSlot,
          selectedDoctor,
          selectedSchedules[m].extendedProps.title
        )
        bundleArray.push(slot)
      }
    }
  }
  const scheduleBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: bundleArray,
  }
  return scheduleBundle
}

const getAppointmentTypeconst = (appointmentType: any) => {
  const serviceTypeList: React.SetStateAction<R4.ICodeableConcept[]> = []
  const serviceTypePhone: R4.ICoding = {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
    code: '538',
    display: 'Telephone Counselling',
  }
  const serviceTypeInPerson: R4.ICoding = {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
    code: '556',
    display: 'Walk-in Centre /Non-Emergency',
  }
  const serviceTypeVideo: R4.ICoding = {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
    code: 'video-counselling',
    display: 'Video Counselling',
  }

  if (appointmentType !== undefined) {
    if (
      appointmentType === appType[0] ||
      appointmentType === appType[1] ||
      appointmentType === appType[2]
    ) {
      const serviceTypeCodeable: R4.ICodeableConcept = {}
      const serviceTypeCodingList: React.SetStateAction<R4.ICoding[]> = []
      if (appointmentType === appType[0]) {
        serviceTypeCodingList.push(serviceTypeInPerson)
      } else if (appointmentType === appType[1]) {
        serviceTypeCodingList.push(serviceTypePhone)
      } else {
        serviceTypeCodingList.push(serviceTypeVideo)
      }
      serviceTypeCodeable.coding = serviceTypeCodingList
      serviceTypeList.push(serviceTypeCodeable)
    } else {
      const appArray = appointmentType.split(' . ')
      for (let i = 0; i < appArray.length; i++) {
        const serviceTypeCodeable: R4.ICodeableConcept = {}
        const serviceTypeCodingList: React.SetStateAction<R4.ICoding[]> = []
        if (appArray[i] === appType[0]) {
          serviceTypeCodingList.push(serviceTypeInPerson)
        } else if (appArray[i] === appType[1]) {
          serviceTypeCodingList.push(serviceTypePhone)
        } else if (appArray[i] === appType[2]) {
          serviceTypeCodingList.push(serviceTypeVideo)
        }
        serviceTypeCodeable.coding = serviceTypeCodingList
        serviceTypeList.push(serviceTypeCodeable)
      }
    }
  }
  return serviceTypeList
}
