import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { rules } from 'utils/constants'

interface Props {
  id: string
  open: boolean
  data: false
  onClose: (chk: boolean) => void
}
export const ConsentDialog: React.FC<Props> = ({ id, open, data, onClose }) => {
  const { t } = useTranslation()

  function resetForm() {
    setChecked(false)
  }
  const [checked, setChecked] = useState<boolean>(data)

  const handleChange = (event: any) => {
    setChecked(event.target.checked)
  }

  useEffect(() => {
    setChecked(data)
  }, [])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose(false)
        resetForm()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='lg'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Consent, Rules & Regulations</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose(checked)
                resetForm()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        <Box display='flex' flexDirection='row' width='100%'>
          <Box>
            <Typography
              variant='body1'
              display='inline'
              dangerouslySetInnerHTML={{
                __html: rules ?? '',
              }}
              id={`${id}text_consent`}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box py={2}>
          <Checkbox
            edge='start'
            checked={checked}
            onChange={handleChange}
            tabIndex={-1}
            size='small'
            disableRipple
            color='primary'
            inputProps={{ 'aria-labelledby': 'consent' }}
            id={`${id}check_consent`}
          />
        </Box>
        <Button
          onClick={() => {
            onClose(true)
            resetForm()
          }}
          disabled={!checked}
          variant='outlined'
          disableElevation
          id={`${id}done_consent`}
        >
          I Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
}
