import { R4 } from '@ahryman40k/ts-fhir-types'
import { FhirSlotDetail } from 'models/fhirSlotDetail'

// eslint-disable-next-line @typescript-eslint/comma-dangle
export enum CancelActions {
  SlotSelection,
  Confirmation,
}

export interface CancelAppointmentManagementStatus {
  currentAction: CancelActions
  enableContinueButton: boolean
  selectedSlot?: FhirSlotDetail
  selectedPatient?: R4.IPatient
  reschedulingAppointment: boolean
  appointmentRescheduledSuccessfully: boolean
  rescheduledAppointment?: R4.IAppointment
  errorWhileReschedulingAppointment: boolean
  errorReason?: string
}
