import { Badge, Box, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { kPrimaryMain } from 'configs/styles/muiThemes'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  isSelected?: boolean
  textTitle?: string
  count?: number
  imgSrc: React.ReactNode
  id: any
  onClick: () => void

  displayCount?: boolean
  displayName?: boolean
  countDisplayType?: 'badge' | 'text'
}

export const AppBarTab: React.FC<IProps> = ({
  isSelected = true,
  textTitle = 'text',
  count = 0,
  imgSrc,
  id,
  onClick,
  displayCount = false,
  displayName = true,
  countDisplayType = 'text',
}: IProps) => {
  const { t, i18n } = useTranslation(['common', 'patient'])
  const language = sessionStorage.getItem('lang')

  useEffect(() => {
    i18n.changeLanguage(language ?? '')
  }, [])

  return (
    <Grid
      container
      direction='column'
      justify='flex-end'
      alignItems='center'
      onClick={onClick}
      style={{
        height: '100%',
        borderWidth: '1px',
        borderColor: 'blue',
        cursor: 'pointer',
      }}
    >
      <Grid item container direction='column' style={{ paddingBottom: '8px' }}>
        <Grid
          container
          direction='row'
          justify='center'
          alignItems='center'
          alignContent='center'
        >
          <Grid item id={`grid${id}`}>
            <Badge
              color='primary'
              badgeContent={count}
              variant='standard'
              overlap='rectangle'
              max={99}
              showZero
              id={`${id}`}
              invisible={!(countDisplayType === 'badge' && displayCount)}
            >
              {imgSrc}
            </Badge>
          </Grid>

          {displayCount && countDisplayType === 'text' && (
            <Grid item>
              <Typography
                color={isSelected ? 'primary' : 'textPrimary'}
                variant='subtitle2'
                align='center'
                id={`${textTitle}${id}`}
                style={{
                  marginLeft: textTitle && textTitle.length > 8 ? 0 : 4,
                  marginRight: 4,
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingLeft: 4,
                  paddingRight: 4,
                  backgroundColor: isSelected ? kPrimaryMain : 'grey',
                  color: 'white',
                  borderRadius: 3,
                  fontSize: '10px',
                }}
              >
                {count}
              </Typography>
            </Grid>
          )}
        </Grid>

        {displayName && (
          <Grid container direction='row' justify='center' alignItems='center'>
            <Grid item>
              <Box id={textTitle} style={{ cursor: 'pointer' }}>
                <Typography
                  style={{ textTransform: 'uppercase' }}
                  variant='subtitle2'
                  color={isSelected ? 'primary' : 'textSecondary'}
                  id={`${textTitle}${id}`}
                >
                  {t('labelCommon:' + `${textTitle}`)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item container direction='row' justify='center' alignItems='center'>
        <Grid item>
          <div
            style={{
              height: 2,
              width: 16,
              borderRadius: 2,
              backgroundColor: isSelected ? kPrimaryMain : 'transparent',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
