import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { PractitionerWithUnit } from 'models/practionerWithUnit'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getUserDetails } from 'services/userDetailsService'
import {
  getPractitionersWithRoleObject,
  getPractitionersWithUnit,
} from 'utils/fhirResoureHelpers/practitioner_and_role_helpers'
import { logger } from 'utils/logger'
import { PractitionerWithUnitSearchStatus } from './practitionerUnitSearchStatus'

const initialState: PractitionerWithUnitSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const practitionerWithUnitSearchSlice = createSlice({
  name: 'practitionerWithUnitSearchSlice',
  initialState,
  reducers: {
    searchingDetails(
      state,
      action: PayloadAction<PractitionerWithUnitSearchStatus>
    ) {},

    searchResults(
      state,
      action: PayloadAction<PractitionerWithUnitSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.unitsList = action.payload.unitsList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<PractitionerWithUnitSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.unitsList = action.payload.unitsList
    },

    errorWhileSearching(
      state,
      action: PayloadAction<PractitionerWithUnitSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.unitsList = action.payload.unitsList
    },
  },
})

export const searchPractitionerWithUnits =
  (): AppThunk => async (dispatch: any) => {
    const errorFetchingUnits: PractitionerWithUnitSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      practitionerWithUnitSearchSlice.actions.errorWhileSearching(
        errorFetchingUnits
      )
    )
    try {
      let searchParameters = {}
      searchParameters = {
        _count: 200,
      }

      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const response: any = await fhirClient.doGetResource(
        'PractitionerRole?_count=500&&role=unit-admin&_include=PractitionerRole:organization',
        searchParameters
      )

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const orgResponse: R4.IBundle = relatedFhirDecodeRes.right
        if (orgResponse.total) {
          if (orgResponse.total > 0) {
            if (orgResponse.entry) {
              const unitWithPractitionerData: PractitionerWithUnit[] =
                getPractitionersWithUnit(orgResponse) ?? []
              const fetchUnitListResult: PractitionerWithUnitSearchStatus = {
                error: false,
                noResultsAvailable: false,
                resultsAvailable: true,
                searching: false,
                unitsList: unitWithPractitionerData,
                //   totalCount: orgResponse.total,
              }
              dispatch(
                practitionerWithUnitSearchSlice.actions.searchResults(
                  fetchUnitListResult
                )
              )
              return
            }
          }
        }
        const noSearchResults: PractitionerWithUnitSearchStatus = {
          error: false,
          noResultsAvailable: true,
          resultsAvailable: false,
          searching: false,
        }
        dispatch(
          practitionerWithUnitSearchSlice.actions.noDataFoundForSearch(
            noSearchResults
          )
        )
        return
      }
      const errorWhileSearchPatient: PractitionerWithUnitSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error while searching',
      }
      dispatch(
        practitionerWithUnitSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: PractitionerWithUnitSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        practitionerWithUnitSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export default practitionerWithUnitSearchSlice.reducer
