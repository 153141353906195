/* eslint-disable import/no-named-as-default */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputAdornment,
  LinearProgress,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core'
import { CatalogueData } from 'models/catalogueData'
import { EditCatalogueDetailsModel } from 'models/editCatalogueDataModel'
import { ObsDataTableResource } from 'models/labObsData'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { updateCatalog } from 'redux/lab/catalougeSearch/addLabTestCatalogueSlice'
import {
  resetObservationState,
  searchObservations,
} from 'redux/lab/observationFinder/observationFinderSlice'
import { RootState } from 'redux/rootReducer'
import { getCatalogueBundleUpdate } from 'utils/labHelpers/uploadHelper'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloTabs } from 'views/components/LeftMenu/WelloTabs'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { WelloTextField } from 'wello-web-components'
import { ObservationDataTable } from './observationDataTable'

export interface EditCatalogueRowDataProps {
  open: boolean
  onCancelClick: () => void
  rowDetails: CatalogueData
}

const menu = ['Test/Panel Header', 'Test/Panel Details']

export function EditCatalogueRowData(props: EditCatalogueRowDataProps) {
  const [selectedTab, setSelectedTab] = React.useState(menu[0])
  const { open, rowDetails, onCancelClick: onClose } = props
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': selectedTab === menu[0] ? '50%' : '100%',
      //   'min-height': selectedTab === menu[0] ? '45%' : '100%',
    },
  }))
  const classes = useStyles()

  const { t, i18n } = useTranslation(['en', 'labelCommon'])
  const [dataSet, setDataSet] = React.useState<ObsDataTableResource[]>([])
  const [bulk, setBulk] = React.useState<boolean>()
  const handleClose = React.useCallback(() => {
    dispatch(resetObservationState())
    onClose()
  }, [onClose])

  const dispatch = useDispatch()

  const observtionSearchSlice = useSelector(
    (state: RootState) => state.observtionSearchSlice
  )

  const handleChangeTab = (selected: string) => {
    setSelectedTab(selected)
  }

  useEffect(() => {
    dispatch(resetObservationState())
    if (rowDetails.fhriPlanDef) {
      dispatch(searchObservations(rowDetails.fhriPlanDef!, true))
    }

    return () => {}
  }, [])

  useEffect(() => {
    if (
      observtionSearchSlice.resultsAvailable &&
      observtionSearchSlice.finalObsList
    ) {
      setDataSet(observtionSearchSlice.finalObsList)
    }
    return () => {}
  }, [observtionSearchSlice.resultsAvailable, dispatch])

  const [catalogueRowData, setCatalogueRowData] =
    React.useState<EditCatalogueDetailsModel>({
      testName: rowDetails.testName,
      description: rowDetails.testDesc,
      specialInstructions: rowDetails.special_Instructions,
      price: rowDetails.price,
      scheduleSummary: rowDetails.schedule,
      planDefinitionResource: rowDetails.fhriPlanDef,
      reportName: rowDetails.fhriPlanDef
        ? rowDetails.fhriPlanDef.name ?? ''
        : '',
    })

  function handleNameChange(testNameCatalogueData: string) {
    setCatalogueRowData({
      ...catalogueRowData,
      testName: testNameCatalogueData,
    })
  }

  function handleDescriptionChange(descriptionCatalogueData: string) {
    setCatalogueRowData({
      ...catalogueRowData,
      description: descriptionCatalogueData,
    })
  }

  function handleReportName(descriptionCatalogueData: string) {
    setCatalogueRowData({
      ...catalogueRowData,
      reportName: descriptionCatalogueData,
    })
  }

  function handlePretestInstructionsChange(
    specialInstructionsCatalogueData: string
  ) {
    setCatalogueRowData({
      ...catalogueRowData,
      specialInstructions: specialInstructionsCatalogueData,
    })
  }

  function handlePriceChange(priceCatalogueData: number) {
    setCatalogueRowData({ ...catalogueRowData, price: priceCatalogueData })
  }

  function handleScheduleSummaryChange(scheduleSummaryCatalogueData: string) {
    setCatalogueRowData({
      ...catalogueRowData,
      scheduleSummary: scheduleSummaryCatalogueData,
    })
  }

  function handleSubmit() {
    let hasErrorsInData: boolean = false
    if (
      !catalogueRowData.price ||
      catalogueRowData.price <= 0 ||
      catalogueRowData.price > 7000 ||
      Number.isNaN(catalogueRowData.price)
    ) {
      hasErrorsInData = true
    } else if (
      catalogueRowData.scheduleSummary?.length === 0 ||
      !catalogueRowData.scheduleSummary?.trim() ||
      catalogueRowData.scheduleSummary?.length > 110
    ) {
      hasErrorsInData = true
    } else if (
      catalogueRowData.reportName.length === 0 ||
      catalogueRowData.reportName.length > 100
    ) {
      hasErrorsInData = true
    } else {
      hasErrorsInData = false
    }

    if (!hasErrorsInData) {
      const bundleData = getCatalogueBundleUpdate(
        catalogueRowData,
        dataSet,
        observtionSearchSlice.resultsAvailable &&
          observtionSearchSlice.finalLabObsData
          ? observtionSearchSlice.finalLabObsData
          : undefined
      )
      console.log(bundleData)

      if (bundleData) {
        dispatch(updateCatalog(bundleData, '', 'Lab test details'))
      }
    }
  }

  return (
    <Dialog
      open={open}
      aria-labelledby='responsive-dialog-title'
      //   maxWidth={selectedTab === menu[0] ? 'lg' : 'md'}
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick
      PaperProps={{
        style: {
          backdropFilter: 'blur(4px)',
        },
      }}
    >
      <DialogTitle id='scroll-dialog-title'>Update Test/Panel</DialogTitle>
      <DialogContent>
        <Box flexDirection='column' display='flex'>
          <Paper
            style={{ backgroundColor: '#F1F1F1' }}
            variant='outlined'
            square
          >
            <Box width='100%'>
              <WelloTabs
                preSelectedTab={selectedTab}
                onTabChange={(e) => handleChangeTab(e)}
                menu={menu}
              />
            </Box>
          </Paper>
          {selectedTab === menu[0] && (
            <Box display='flex' flexDirection='column'>
              <Box display='flex' py={1} width='100%'>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='75%'
                  //   paddingTop={1.6}
                  px={1.5}
                >
                  <WelloFormItemLabel title='Test/Panel Name' />
                  <WelloTextFieldWithoutTitle
                    textProps={{
                      id: 'Test name',
                      disabled: true,
                      value: catalogueRowData.testName,
                      placeholder: 'Test name',
                      onChange: (changeName) => {
                        handleNameChange(changeName.target.value)
                      },
                    }}
                  />
                </Box>
                <Box px={1} width='25%' paddingRight={1} flexDirection='column'>
                  <WelloFormItemLabel title='Price' />
                  <WelloTextFieldWithoutTitle
                    textProps={{
                      id: 'Price',
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Box>
                              {' '}
                              <Typography variant='subtitle2' align='center'>
                                {' '}
                                &#x20b9;
                              </Typography>
                            </Box>
                          </InputAdornment>
                        ),
                      },
                      inputProps: {
                        max: 100000,
                        maxLength: 6,
                      },
                      error:
                        (catalogueRowData?.price ?? '') <= 0 ||
                        (catalogueRowData?.price ?? '') > 100000 ||
                        Number.isNaN(catalogueRowData.price),

                      defaultValue: catalogueRowData.price,
                      type: 'number',
                      placeholder: 'Price',
                      onChange: (changePrice) => {
                        handlePriceChange(
                          parseInt(changePrice.target.value, 10)
                        )
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box display='flex' paddingBottom={1} width='100%'>
                <Box px={1.5} width='50%' flexDirection='column'>
                  <WelloFormItemLabel title='Description' />
                  <WelloTextFieldWithoutTitle
                    // title='Description'
                    textProps={{
                      id: 'description',
                      //   value: ipdRoomRateData?.description,
                      defaultValue: catalogueRowData.description,
                      type: 'text',
                      rows: 4,
                      rowsMax: 6,
                      multiline: true,
                      inputProps: {
                        maxLength: 256,
                      },
                      // error:
                      // catalogueRowData.description?.length === 0 || !catalogueRowData.description?.trim() || catalogueRowData.description?.length > 256,
                      // helperText:
                      // catalogueRowData.description?.length === 0 ||
                      //   !catalogueRowData.description?.trim() || catalogueRowData.description?.length > 256
                      //     ? 'Description is required and characters between 1 and 256'
                      //     : '',
                      placeholder: 'Description',
                      onChange: (changeDescription) => {
                        handleDescriptionChange(changeDescription.target.value)
                      },
                    }}
                  />
                </Box>
                <Box px={1.5} width='50%' flexDirection='column'>
                  <WelloFormItemLabel title='Pretest Instructions' />
                  <WelloTextFieldWithoutTitle
                    textProps={{
                      id: 'pretest_instructions',
                      //   value: ipdRoomRateData?.description,
                      defaultValue: catalogueRowData.specialInstructions,
                      type: 'text',
                      rows: 4,
                      rowsMax: 6,
                      multiline: true,
                      inputProps: {
                        maxLength: 256,
                      },
                      // error:
                      // catalogueRowData.specialInstructions?.length === 0 || !catalogueRowData.specialInstructions?.trim() || catalogueRowData.specialInstructions?.length > 256,
                      // helperText:
                      // catalogueRowData.specialInstructions?.length === 0 ||
                      //   !catalogueRowData.specialInstructions?.trim() || catalogueRowData.specialInstructions?.length > 256
                      //     ? 'Pretest Instructions is required and characters between 1 and 256'
                      //     : '',
                      placeholder: 'Pretest Instructions',
                      onChange: (changeSpecialInstructions) => {
                        handlePretestInstructionsChange(
                          changeSpecialInstructions.target.value
                        )
                      },
                    }}
                  />
                </Box>
              </Box>

              <Box display='flex' paddingBottom={1} width='100%'>
                <Box px={1.5} width='50%' flexDirection='column'>
                  <WelloFormItemLabel title='Report Display name' />
                  <WelloTextFieldWithoutTitle
                    // title='Report Display name'
                    textProps={{
                      id: 'report_name',
                      //   value: ipdRoomRateData?.description,
                      defaultValue: catalogueRowData.reportName,
                      type: 'text',
                      error:
                        catalogueRowData.reportName.length === 0 ||
                        catalogueRowData.reportName.length > 100,
                      inputProps: {
                        maxLength: 100,
                      },
                      // error:
                      // catalogueRowData.description?.length === 0 || !catalogueRowData.description?.trim() || catalogueRowData.description?.length > 256,
                      // helperText:
                      // catalogueRowData.description?.length === 0 ||
                      //   !catalogueRowData.description?.trim() || catalogueRowData.description?.length > 256
                      //     ? 'Description is required and characters between 1 and 256'
                      //     : '',
                      placeholder: 'Report Display Name',
                      onChange: (changeDescription) => {
                        handleReportName(changeDescription.target.value)
                      },
                    }}
                  />
                </Box>
                <Box px={1.5} width='50%' flexDirection='column'>
                  <WelloFormItemLabel title='Schedule Summary' />
                  <WelloTextFieldWithoutTitle
                    // title='Schedule Summary'
                    textProps={{
                      id: `test_scheduleSummary${t}`,
                      value: catalogueRowData.scheduleSummary,
                      placeholder: 'Schedule Summary',
                      error:
                        catalogueRowData.scheduleSummary.length === 0 ||
                        catalogueRowData.scheduleSummary.length > 100,
                      type: 'text',
                      inputProps: {
                        maxLength: 110,
                      },

                      //   value: tests.testPrice,
                      onChange: (changeSummary) => {
                        handleScheduleSummaryChange(changeSummary.target.value)
                      },
                    }}
                  />
                </Box>
              </Box>

              {rowDetails.testName.length > 0 && (
                <Box px={2}>
                  <Box flexDirection='row' width='100%' display='flex' py={1}>
                    {observtionSearchSlice.searching && (
                      <LinearProgress
                        style={{
                          height: '4px',
                          width: '100%',
                        }}
                      />
                    )}
                  </Box>

                  {observtionSearchSlice.noResultsAvailable && (
                    <Box>No Parameters Available</Box>
                  )}

                  {observtionSearchSlice.error && (
                    <Box>Something went Wrong</Box>
                  )}
                </Box>
              )}
            </Box>
          )}
          {dataSet.length > 0 && selectedTab === menu[1] && (
            <Box
              display='flex'
              flexDirection='column'
              alignItems='flex-start'
              width='100%'
              px={1.5}
            >
              <Box
                py={0.5}
                width='100%'
                display='flex'
                flexDirection='row'
                justifyContent='center'
              >
                {' '}
                <Typography variant='subtitle1' color='textPrimary'>
                  Test Parameters for {rowDetails.testName}
                </Typography>
              </Box>

              <Box display='flex' flexDirection='column' width='100%'>
                <ObservationDataTable
                  operation='edit'
                  testData={dataSet}
                  onBulkEdit={(dataSetFinal: ObsDataTableResource[]) => {
                    setDataSet(dataSetFinal)
                    setBulk(false)
                    console.log(dataSetFinal)
                  }}
                  onBulkStarted={(isStarted?: boolean) => {
                    if (isStarted) {
                      if (isStarted === true) {
                        setBulk(true)
                      } else {
                        setBulk(false)
                      }
                    }
                  }}
                  onClickAdd={() => {}}
                />
              </Box>
            </Box>
          )}
          <Box flexGrow={1}>
            {' '}
            <Divider />{' '}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          paddingRight={0.3}
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
        >
          <Button
            onClick={handleClose}
            variant='outlined'
            disableElevation
            style={{ height: 32 }}
            size='small'
          >
            Close
          </Button>
          <Box paddingLeft={0.5}>
            <Button
              variant='contained'
              disableElevation
              disabled={bulk}
              color='primary'
              onClick={() => {
                handleSubmit()
              }}
              id='Update'
            >
              Save
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
