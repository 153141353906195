import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core'
// import Checkbox, { checkboxClasses } from '@mui/material/Checkbox'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React from 'react'
import { useDispatch } from 'react-redux'
import { CardsEntity } from 'redux/subscription/cpgRecommendations/cpgResponse'
import { getDisplayOfSystemFromCodableConcept } from 'utils/fhirResourcesHelper'
import {
  getAddressingConditions,
  getAddressingConditionsFromCarePlan,
  getCarePlans,
  hasError,
} from 'utils/careplan_utils/cpg_recommendations_utils'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',

    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))
interface CdsCardDetailsProps {
  cdsCard: CardsEntity
}

export const ConditionsOFCpgRecommendation: React.FC<CdsCardDetailsProps> = ({
  cdsCard: card,
}: CdsCardDetailsProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  return (
    <Box display='flex' flexDirection='column' py={0.25} overflow='auto'>
      {!hasError(card) &&
        card.interceptedSuggestions &&
        card.interceptedSuggestions.has('conditions') && (
          <Box
            display='flex'
            flexDirection='column'
            key='conditions_cpg_outer_label'
            py={0.25}
            pl={2}
            overflow='auto'
          >
            {/* <Typography variant="subtitle2" color="initial">
                            {getIdOfCardEntry(card) ?? 'Id not available'}
                        </Typography> */}
            {/* <Typography variant='subtitle1' color='initial'>
                          {each.label}
                        </Typography> */}
            <Box
              display='flex'
              flexDirection='column'
              key='conditions_cpg_inner_label'
              py={0.25}
              overflow='auto'
            >
              {(card.interceptedSuggestions.get('conditions') as any[]).length >
                0 && (
                <Box display='flex' flexDirection='row' width='100%'>
                  {(card.interceptedSuggestions.get('conditions') as any[]).map(
                    (cond) => (
                      <Typography
                        className={classes.subTitleText}
                        key={getDisplayOfSystemFromCodableConcept(cond.code)}
                      >
                        {getDisplayOfSystemFromCodableConcept(cond.code)}
                      </Typography>
                    )
                  )}
                </Box>
              )}
            </Box>
          </Box>
        )}
    </Box>
  )
}
