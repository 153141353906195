import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import moment from 'moment'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitDetails,
} from 'services/userDetailsService'
import { getSortedOrderStatus } from 'utils/appointment_handle/vitals_util'
import { getValueCodingStringFromExtension } from 'utils/fhirResourcesHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  getExpandedServiceRequestFromBundle,
  getInputIdentifierValueByCodemOfInvitation,
  getOrderTypeCode,
  getProvenanceObjectForOrder,
  getProvenanceObjectForTask,
  getRefundBody,
  getRefundBodyForOrder,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import { getExpandedServiceRequestFromBundlePartner } from 'utils/fhirResoureHelpers/partnerLabOrderHelper'
import { logger } from 'utils/logger'
import { requestOrdersCountForToday } from '../orderCount/orderCountSlice'
import { requestOrdersForTheDateRange } from '../orderSearchSlice/orderSearchSlice'
import { OrderManagementStatus } from './orderManagementSliceStatus'

const initialState: OrderManagementStatus = {
  fetchingOrderDetails: false,
  orderDetailsAvailable: false,
  noResultsAvailable: false,
  errorWhileFetchingOrderDetail: false,
  orderCancelled: false,
  orderDetailsUpdated: false,
  updatingOrderDetail: false,
}

const orderManagementSlice = createSlice({
  name: 'orderManagementSlice',
  initialState,

  reducers: {
    updatedStatus(state, action: PayloadAction<OrderManagementStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingOrderDetails = action.payload.fetchingOrderDetails
      state.orderDetailsAvailable = action.payload.orderDetailsAvailable
      state.orderDetail = action.payload.orderDetail
      state.errorReason = action.payload.errorReason
      state.errorWhileFetchingOrderDetail =
        action.payload.errorWhileFetchingOrderDetail
    },
  },
})

export const fetchOrderDetails =
  (orderId: String): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: OrderManagementStatus = { ...initialState }
    state.fetchingOrderDetails = true
    dispatch(orderManagementSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _include: 'ServiceRequest:patient',
        _revinclude: 'Task:focus',
        _id: orderId,
        '_revinclude:iterate': 'Provenance:target',
        '_include:iterate': [
          'ServiceRequest:performer',
          'ServiceRequest:instantiates-canonical',
          'PlanDefinition:service-billing',
          'Task:owner',
          'PractitionerRole:practitioner',
        ],
      }

      const response: any =
        await fhirClient.doGetResourceIncludeAndIncludeIterate(
          `/ServiceRequest?_revinclude:iterate=PaymentReconciliation:request&_revinclude:iterate=DocumentReference:related`,
          searchParameters
        )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)

      // const appointmentResponse: R4.IBundle = resp.right
      if (response?.total && response?.total > 0) {
        const fhirAppointments: FhirLabOrderDetail[] =
          getExpandedServiceRequestFromBundle(response)

        const finalData: FhirLabOrderDetail = {
          ...fhirAppointments[0],
        }
        if (fhirAppointments[0].statuses) {
          const reArrangedStatus: R4.IProvenance[] = getSortedOrderStatus(
            fhirAppointments[0].statuses
          )

          console.log(reArrangedStatus)

          //   reArrangedStatus.sort((a, b) =>
          //     moment(a.occurredDateTime).diff(b.occurredDateTime)
          //   )
          finalData.statuses = reArrangedStatus
          logger.info('Orders length')
          const currentState = { ...initialState }
          currentState.orderDetailsAvailable = true
          currentState.orderDetail = finalData
          dispatch(orderManagementSlice.actions.updatedStatus(currentState))
        }
      } else {
        const currentState = { ...initialState }
        currentState.noResultsAvailable = true

        dispatch(orderManagementSlice.actions.updatedStatus(currentState))
      }
    } catch (error) {
      logger.error(error)

      const currentState = { ...initialState }
      currentState.errorWhileFetchingOrderDetail = true
      currentState.errorReason = 'Error while fetching orders'

      dispatch(orderManagementSlice.actions.updatedStatus(currentState))
    }
  }

export const fetchOrderDetailsForPartner =
  (orderId: String, locationId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: OrderManagementStatus = { ...initialState }
    state.fetchingOrderDetails = true
    dispatch(orderManagementSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _include: 'ServiceRequest:patient',
        _revinclude: 'Task:focus',
        _id: orderId,
        '_revinclude:iterate': 'Provenance:target',
        '_include:iterate': [
          'ServiceRequest:performer',
          'ServiceRequest:instantiates-canonical',
          'PlanDefinition:service-billing',
          'Task:owner',
          'PractitionerRole:practitioner',
        ],
      }

      const response: any =
        await fhirClient.doGetResourceIncludeAndIncludeIterate(
          `/ServiceRequest/`,
          searchParameters
        )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        const currentState = { ...initialState }
        currentState.errorWhileFetchingOrderDetail = true
        dispatch(orderManagementSlice.actions.updatedStatus(currentState))
      } else {
        const appointmentResponse: R4.IBundle = resp.right
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          const fhirAppointments: FhirLabOrderDetail[] =
            getExpandedServiceRequestFromBundlePartner(
              appointmentResponse,
              locationId
            )
          logger.info('Orders length')
          const currentState = { ...initialState }
          currentState.orderDetailsAvailable = true
          currentState.orderDetail = fhirAppointments[0]
          dispatch(orderManagementSlice.actions.updatedStatus(currentState))
        } else {
          const currentState = { ...initialState }
          currentState.noResultsAvailable = true

          dispatch(orderManagementSlice.actions.updatedStatus(currentState))
        }
      } /* */
    } catch (error) {
      logger.error(error)

      const currentState = { ...initialState }
      currentState.errorWhileFetchingOrderDetail = true
      currentState.errorReason = 'Error while fetching orders'

      dispatch(orderManagementSlice.actions.updatedStatus(currentState))
    }
  }

export const requestCancellationOfOrder =
  (
    orderDetail: FhirLabOrderDetail,
    rescheduleReason: R4.ICoding,
    errorReason?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: OrderManagementStatus = { ...initialState }
    state.updatingOrderDetail = true
    state.orderDetail = orderDetail
    dispatch(orderManagementSlice.actions.updatedStatus(state))
    try {
      const oldSlot: R4.ISlot | undefined = await getSlotCurrentState(
        orderDetail.oldSlotRef
      )
      const bundleObject: R4.IBundle | undefined =
        getCancelRequestTransactionObject(
          orderDetail,
          rescheduleReason,
          errorReason,
          oldSlot
        )
      if (bundleObject) {
        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          if (
            orderDetail.paymentReconilation &&
            orderDetail.paymentReconilation.outcome
          ) {
            const code =
              getValueCodingStringFromExtension(
                orderDetail.paymentReconilation.extension ?? [],
                'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentType'
              ) ?? ''

            if (
              code.length > 0 &&
              code === 'prepaid' &&
              orderDetail.paymentReconilation.outcome === 'complete'
            ) {
              const transactionResp: boolean = await getRefund(
                orderDetail.serviceRequest.id ?? '',
                orderDetail.paymentReconilation
                  ? orderDetail.paymentReconilation.id ?? ''
                  : '',
                'lab-order'
              )
              if (transactionResp) {
                state.updatingOrderDetail = false
                state.orderCancelled = true
              } else {
                state.updatingOrderDetail = false
                state.orderCancelled = true
              }
            }
          }
          state.updatingOrderDetail = false
          state.orderCancelled = true

          dispatch(showSuccessAlert('Lab order cancellation is successful'))
          dispatch(requestOrdersCountForToday())
          dispatch(requestOrdersForTheDateRange([]))

          dispatch(orderManagementSlice.actions.updatedStatus(state))
          return
        }
      }

      state.updatingOrderDetail = false
      state.errorWhileFetchingOrderDetail = true
      dispatch(showErrorAlert('Error while cancelling order. Please try later'))
      dispatch(orderManagementSlice.actions.updatedStatus(state))
      return
    } catch (error) {
      logger.error(error)
      console.error(error)
      const currentState = { ...initialState }
      currentState.errorWhileFetchingOrderDetail = true
      currentState.errorReason = 'Error while fetching orders'

      dispatch(orderManagementSlice.actions.updatedStatus(currentState))
    }
  }

export const requestRescheduleOfOrder =
  (
    orderDetail: FhirLabOrderDetail,
    rescheduleReason: R4.ICoding,
    newSlot: FhirSlotDetail,
    errorReason?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: OrderManagementStatus = { ...initialState }
    state.updatingOrderDetail = true
    state.orderDetail = orderDetail
    dispatch(orderManagementSlice.actions.updatedStatus(state))
    try {
      const oldSlot: R4.ISlot | undefined = await getSlotCurrentState(
        orderDetail.oldSlotRef
      )
      const bundleObject: R4.IBundle | undefined =
        getRescheduleRequestTransactionObject(
          orderDetail,
          rescheduleReason,
          newSlot,
          errorReason,
          oldSlot
        )
      if (bundleObject) {
        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          state.updatingOrderDetail = false
          state.orderCancelled = true

          dispatch(showSuccessAlert('Lab order rescheduled successfully'))
          dispatch(requestOrdersForTheDateRange([]))

          dispatch(orderManagementSlice.actions.updatedStatus(state))
          return
        }
      }

      state.updatingOrderDetail = false
      state.errorWhileFetchingOrderDetail = true
      dispatch(
        showErrorAlert('Error while rescheduling order. Please try later')
      )
      dispatch(orderManagementSlice.actions.updatedStatus(state))
      return
    } catch (error) {
      logger.error(error)
      console.error(error)
      const currentState = { ...initialState }
      currentState.errorWhileFetchingOrderDetail = true
      currentState.errorReason = 'Error while fetching orders'

      dispatch(orderManagementSlice.actions.updatedStatus(currentState))
    }
  }

export const requestUpdateStatusOfOrder =
  (
    orderDetail: FhirLabOrderDetail,
    newStatus: R4.ICoding,
    errorReason?: string,
    isCompleted?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: OrderManagementStatus = { ...initialState }
    state.updatingOrderDetail = true
    state.orderDetail = orderDetail
    dispatch(orderManagementSlice.actions.updatedStatus(state))
    try {
      const bundleObject: R4.IBundle | undefined = getUpdateBundle(
        orderDetail,
        newStatus,
        errorReason,
        isCompleted
      )
      if (bundleObject) {
        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          state.updatingOrderDetail = false
          state.orderCancelled = true

          dispatch(showSuccessAlert('Lab order status updated  successfully'))
          dispatch(requestOrdersForTheDateRange([]))

          dispatch(orderManagementSlice.actions.updatedStatus(state))
          return
        }
      }

      state.updatingOrderDetail = false
      state.errorWhileFetchingOrderDetail = true
      dispatch(
        showErrorAlert(
          'Error while status updating of an order. Please try later'
        )
      )
      dispatch(orderManagementSlice.actions.updatedStatus(state))
      return
    } catch (error) {
      logger.error(error)
      console.error(error)
      const currentState = { ...initialState }
      currentState.errorWhileFetchingOrderDetail = true
      currentState.errorReason =
        'Error while status updating of an order. Please try later'

      dispatch(orderManagementSlice.actions.updatedStatus(currentState))
    }
  }

export const requestForCollectionUpdate =
  (orderDetails: FhirLabOrderDetail[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: OrderManagementStatus = { ...initialState }
    state.updatingOrderDetail = true
    dispatch(orderManagementSlice.actions.updatedStatus(state))
    try {
      const status: R4.ICoding = {
        system:
          'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status',
        code: 'sample_received',
        display: 'Sample Received',
      }
      const bundleObject: R4.IBundle | undefined = getBundleFOrCollectionUpdate(
        orderDetails,
        status
      )

      if (bundleObject) {
        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          state.updatingOrderDetail = false
          state.orderCancelled = true

          dispatch(showSuccessAlert('Sample collection finished  successfully'))
          dispatch(requestOrdersForTheDateRange([]))

          dispatch(orderManagementSlice.actions.updatedStatus(state))
          return
        }
      }

      state.updatingOrderDetail = false
      state.errorWhileFetchingOrderDetail = true
      dispatch(
        showErrorAlert(
          'Error while status updating of order(s). Please try later'
        )
      )
      dispatch(orderManagementSlice.actions.updatedStatus(state))
      return
    } catch (error) {
      logger.error(error)
      const currentState = { ...initialState }
      currentState.errorWhileFetchingOrderDetail = true
      currentState.errorReason =
        'Error while status updating of  order(S). Please try later'

      dispatch(orderManagementSlice.actions.updatedStatus(currentState))
    }
  }

export const resetOrderStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: OrderManagementStatus = { ...initialState }
    dispatch(orderManagementSlice.actions.updatedStatus(state))
  }

function getBundleFOrCollectionUpdate(
  orderDetails: FhirLabOrderDetail[],
  newStaus: R4.ICoding
): R4.IBundle | undefined {
  const bundleEntry: R4.IBundle_Entry[] = []
  for (let i = 0; i < orderDetails.length; i++) {
    const orderDetailTask: R4.ITask | undefined = orderDetails[i].task
    if (orderDetailTask) {
      const mainTask: R4.ITask = { ...orderDetailTask }
      mainTask.status = R4.TaskStatusKind._received
      mainTask.businessStatus = {
        text: newStaus.display,
        coding: [newStaus],
      }
      mainTask.reasonCode = { coding: [newStaus] }
      const statusProvenance: R4.IProvenance = getProvenanceObjectForOrder(
        orderDetails[i],
        newStaus
      )

      mainTask.relevantHistory = [
        ...(mainTask.relevantHistory ?? []),
        {
          reference: `${statusProvenance.resourceType}/${
            statusProvenance.id ?? ''
          }`,
        },
      ]
      const mainTaskMatchString: string = `W/${JSON.stringify(
        mainTask.meta?.versionId ?? ''
      )}`
      bundleEntry.push({
        fullUrl: `${statusProvenance.resourceType}/`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: statusProvenance.resourceType,
        },
        resource: statusProvenance,
      })
      bundleEntry.push({
        fullUrl: `${mainTask.resourceType}/${mainTask.id}`,
        request: {
          ifMatch: mainTaskMatchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `${mainTask.resourceType}/${mainTask.id}`,
        },
        resource: mainTask,
      })

      if (!orderDetails[i].isPrepaid) {
        const paymentType = orderDetails[i].paymentReconilation
        if (paymentType) {
          const org = getCurrentUserUnitDetails()
          const paymentRecon: R4.IPaymentReconciliation = { ...paymentType }

          const paymentNotice: R4.IPaymentNotice = {
            id: getUniqueTempId(),
            resourceType: 'PaymentNotice',
            status: 'active',
            provider: {
              id: org.id,
              reference: `Organization/${org.id}`,
              type: 'Organization',
              display: org.name,
            },
            payment: {
              id: paymentRecon.id,
              reference: `PaymentReconciliation/${paymentRecon.id}`,
            },
            recipient: {
              id: org.id,
              reference: `Organization/${org.id}`,
              type: 'Organization',
              display: org.name,
            },
            amount: {
              value: paymentRecon.paymentAmount.value,
              currency: 'INR',
            },
          }

          bundleEntry.push({
            fullUrl: `${paymentNotice.resourceType}/`,
            request: {
              method: R4.Bundle_RequestMethodKind._post,
              url: paymentNotice.resourceType,
            },
            resource: paymentNotice,
          })
        }
      }
    }
  }

  if (bundleEntry.length > 0) {
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: bundleEntry,
    }
    return requestBundle
  }

  return undefined
}

function getUpdateBundle(
  orderDetail: FhirLabOrderDetail,
  newStaus: R4.ICoding,
  errorReason?: string,
  isCompleted?: boolean
): R4.IBundle | undefined {
  if (orderDetail.task) {
    const oldUpdatedServiceRequest: R4.IServiceRequest = {
      ...orderDetail.serviceRequest,
    }
    const oldAppointmentMatchString: string = `W/${JSON.stringify(
      oldUpdatedServiceRequest.meta?.versionId ?? ' '
    )}`

    const mainTask: R4.ITask = { ...orderDetail.task }
    if (newStaus.code === 'sample_colleccted') {
      mainTask.status = R4.TaskStatusKind._received
    } else if (newStaus.code === 'processing_samples') {
      mainTask.status = R4.TaskStatusKind._draft
    } else if (newStaus.code === 'rejected') {
      mainTask.status = R4.TaskStatusKind._rejected
    } else if (newStaus.code === 'report_ready') {
      mainTask.status = R4.TaskStatusKind._ready
    } else if (newStaus.code === 'reports_delivered') {
      mainTask.status = R4.TaskStatusKind._completed
    }

    mainTask.businessStatus = {
      text: newStaus.display,
      coding: [newStaus],
    }

    mainTask.reasonCode = {
      coding: [newStaus],
    }

    const statusProvenance: R4.IProvenance = getProvenanceObjectForOrder(
      orderDetail,
      newStaus
    )

    mainTask.relevantHistory = [
      ...(mainTask.relevantHistory ?? []),
      {
        reference: `${statusProvenance.resourceType}/${
          statusProvenance.id ?? ''
        }`,
      },
    ]

    const mainTaskMatchString: string = `W/${JSON.stringify(
      mainTask.meta?.versionId ?? ' '
    )}`

    if (errorReason) {
      oldUpdatedServiceRequest.patientInstruction = errorReason
    }
    if (isCompleted) {
      oldUpdatedServiceRequest.status = 'completed'
    }

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${statusProvenance.resourceType}/`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: statusProvenance.resourceType,
          },
          resource: statusProvenance,
        },
        {
          fullUrl: `${oldUpdatedServiceRequest.resourceType}/${oldUpdatedServiceRequest.id}`,
          request: {
            ifMatch: oldAppointmentMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedServiceRequest.resourceType}/${oldUpdatedServiceRequest.id}`,
          },
          resource: oldUpdatedServiceRequest,
        },

        {
          fullUrl: `${mainTask.resourceType}/${mainTask.id}`,
          request: {
            ifMatch: mainTaskMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${mainTask.resourceType}/${mainTask.id}`,
          },
          resource: mainTask,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

function getRescheduleRequestTransactionObject(
  orderDetail: FhirLabOrderDetail,
  rescheduleReason: R4.ICoding,
  newSlot: FhirSlotDetail,
  errorReason?: string,
  oldSlotState?: R4.ISlot
): R4.IBundle | undefined {
  if (orderDetail.task) {
    const oldUpdatedServiceRequest: R4.IServiceRequest = {
      ...orderDetail.serviceRequest,
    }
    const oldAppointmentMatchString: string = `W/${JSON.stringify(
      oldUpdatedServiceRequest.meta?.versionId ?? ' '
    )}`

    if (newSlot?.slot?.start) {
      oldUpdatedServiceRequest.occurrencePeriod = {
        start: newSlot?.slot?.start,
        end: newSlot?.slot?.end,
      }
    }

    const mainTask: R4.ITask = { ...orderDetail.task }

    mainTask.reasonCode = {
      coding: [rescheduleReason],
    }

    const statusProvenance: R4.IProvenance = getProvenanceObjectForOrder(
      orderDetail,
      {
        system: 'http://hl7.org/fhir/task-status',
        code: 'rescheduled',
        display: 'ReScheduled',
      }
    )

    mainTask.relevantHistory = [
      ...(mainTask.relevantHistory ?? []),
      {
        reference: `${statusProvenance.resourceType}/${
          statusProvenance.id ?? ''
        }`,
      },
    ]

    const mainTaskMatchString: string = `W/${JSON.stringify(
      mainTask.meta?.versionId ?? ' '
    )}`

    if (errorReason) {
      oldUpdatedServiceRequest.patientInstruction = errorReason
    }
    let modifiedOldSlot: R4.ISlot = {
      resourceType: 'Slot',
      schedule: {},
    }

    if (orderDetail.slot) {
      modifiedOldSlot = { ...orderDetail.slot }

      modifiedOldSlot.status = R4.SlotStatusKind._free
    }

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${statusProvenance.resourceType}/`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: statusProvenance.resourceType,
          },
          resource: statusProvenance,
        },
        {
          fullUrl: `${oldUpdatedServiceRequest.resourceType}/${oldUpdatedServiceRequest.id}`,
          request: {
            ifMatch: oldAppointmentMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedServiceRequest.resourceType}/${oldUpdatedServiceRequest.id}`,
          },
          resource: oldUpdatedServiceRequest,
        },

        {
          fullUrl: `${mainTask.resourceType}/${mainTask.id}`,
          request: {
            ifMatch: mainTaskMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${mainTask.resourceType}/${mainTask.id}`,
          },
          resource: mainTask,
        },
      ],
    }

    const code: string | undefined = getOrderTypeCode(orderDetail)
    if (code === 'home-sample-collection') {
      if (orderDetail.homeServiceTask) {
        const subTask: R4.ITask = { ...orderDetail.homeServiceTask }
        const newSubTask: R4.ITask = { ...orderDetail.homeServiceTask }
        const newStatus = {
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/trip-business-status',
          code: 'cancelled',
          display: 'Cancelled',
        }
        subTask.businessStatus = {
          text: newStatus.display,
          coding: [newStatus],
        }
        subTask.status = R4.TaskStatusKind._cancelled
        subTask.reasonCode = {
          coding: [rescheduleReason],
        }
        newSubTask.id = getUniqueTempId()
        newSubTask.status = R4.TaskStatusKind._accepted

        newSubTask.owner = {
          id: newSlot.practitionerRole?.id ?? '',
          reference: `${newSlot.practitionerRole?.resourceType ?? ''}/${
            newSlot.practitionerRole?.id ?? ''
          }`,
        }

        newSubTask.executionPeriod = {
          start: newSlot.slot.start,
          end: newSlot.slot.end,
        }
        newSubTask.input = newSubTask.input?.filter((item, index, arr) => {
          if (item.type?.coding?.[0].code !== 'slot-reference') {
            return true
          }
          return false
        })

        newSubTask.input = [
          ...(newSubTask.input ?? []),
          {
            type: {
              coding: [
                {
                  system:
                    'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
                  code: 'slot-reference',
                },
              ],
            },
            valueReference: {
              reference: `${newSlot.slot.resourceType}/${newSlot.slot.id}`,
              id: newSlot.slot.id,
              type: newSlot.slot.resourceType,
            },
          },
        ]

        const newSubTaskStatusProvenance: R4.IProvenance =
          getProvenanceObjectForTask(newSubTask, {
            system: 'http://hl7.org/fhir/task-status',
            code: 'created',
            display: 'Created',
          })

        newSubTask.relevantHistory = [
          ...(newSubTask.relevantHistory ?? []),
          {
            reference: `${newSubTaskStatusProvenance.resourceType}/${
              newSubTaskStatusProvenance.id ?? ''
            }`,
          },
        ]

        const subTaskStatusProvenance: R4.IProvenance =
          getProvenanceObjectForTask(newSubTask, {
            system: 'http://hl7.org/fhir/task-status',
            code: 'cancelled',
            display: 'Cancelled',
          })

        subTask.relevantHistory = [
          ...(subTask.relevantHistory ?? []),
          {
            reference: `${subTaskStatusProvenance.resourceType}/${
              subTaskStatusProvenance.id ?? ''
            }`,
          },
        ]

        const subTaskMatchString: string = `W/${JSON.stringify(
          subTask.meta?.versionId ?? ' '
        )}`

        requestBundle.entry?.push({
          fullUrl: `${subTaskStatusProvenance.resourceType}/`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: subTaskStatusProvenance.resourceType,
          },
          resource: subTaskStatusProvenance,
        })

        requestBundle.entry?.push({
          fullUrl: `${subTask.resourceType}/${subTask.id}`,
          request: {
            ifMatch: subTaskMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${subTask.resourceType}/${subTask.id}`,
          },
          resource: subTask,
        })

        requestBundle.entry?.push({
          fullUrl: `${newSubTaskStatusProvenance.resourceType}/`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: newSubTaskStatusProvenance.resourceType,
          },
          resource: newSubTaskStatusProvenance,
        })
        requestBundle.entry?.push({
          fullUrl: `${newSubTask.resourceType}/`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: newSubTask.resourceType,
          },
          resource: newSubTask,
        })

        const slotRef: R4.IReference | undefined =
          getInputIdentifierValueByCodemOfInvitation(
            orderDetail.homeServiceTask,
            'slot-reference'
          )?.valueReference

        if (oldSlotState) {
          const slotDataOld: R4.ISlot = { ...oldSlotState }
          const slotVesrsionOld: string = `W/${JSON.stringify(
            slotDataOld.meta?.versionId ?? ' '
          )}`
          slotDataOld.status = R4.SlotStatusKind._free
          requestBundle.entry?.push({
            fullUrl: `${slotDataOld.resourceType}/${slotDataOld.id}`,
            request: {
              ifMatch: slotVesrsionOld,
              method: R4.Bundle_RequestMethodKind._put,
              url: `${slotDataOld.resourceType}/${slotDataOld.id}`,
            },
            resource: slotDataOld,
          })
        }
        const slotData: R4.ISlot = { ...newSlot.slot }
        const slotVesrsion: string = `W/${JSON.stringify(
          slotData.meta?.versionId ?? ' '
        )}`
        slotData.status = R4.SlotStatusKind._busy
        requestBundle.entry?.push({
          fullUrl: `${newSlot.slot.resourceType}/${newSlot.slot.id}`,
          request: {
            ifMatch: slotVesrsion,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${newSlot.slot.resourceType}/${newSlot.slot.id}`,
          },
          resource: slotData,
        })
      }
    }

    return requestBundle
  }

  return undefined
}

function getCancelRequestTransactionObject(
  orderDetail: FhirLabOrderDetail,
  rescheduleReason: R4.ICoding,
  errorReason?: string,
  oldSlot?: R4.ISlot
): R4.IBundle | undefined {
  const currentUserDetails: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  if (orderDetail.task) {
    const oldUpdatedServiceRequest: R4.IServiceRequest = {
      ...orderDetail.serviceRequest,
    }
    const oldAppointmentMatchString: string = `W/${JSON.stringify(
      oldUpdatedServiceRequest.meta?.versionId ?? ' '
    )}`
    oldUpdatedServiceRequest.status = 'revoked'
    oldUpdatedServiceRequest.doNotPerform = true
    const mainTask: R4.ITask = { ...orderDetail.task }

    mainTask.status = R4.TaskStatusKind._cancelled
    mainTask.reasonCode = {
      coding: [rescheduleReason],
    }
    const newStatusForOrder = {
      system:
        'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status',
      code: 'cancelled',
      display: 'Cancelled',
    }
    mainTask.businessStatus = {
      text: newStatusForOrder.display,
      coding: [newStatusForOrder],
    }

    const mainProvenanceId: string = getUniqueTempId()
    const statusProvenance: R4.IProvenance = {
      id: mainProvenanceId,
      resourceType: 'Provenance',
      occurredDateTime: new Date().toISOString(),

      activity: {
        text: 'Cancelled',
        coding: [
          {
            system: 'http://hl7.org/fhir/task-status',
            code: 'cancelled',
            display: 'Cancelled',
          },
        ],
      },
      agent: [
        {
          type: {
            coding: [
              {
                code: 'enterer',
                display: 'Enterer',
                system:
                  'http://terminology.hl7.org/CodeSystem/provenance-participant-type',
              },
            ],
          },
          who: {
            id: currentUserDetails.id,
            reference:
              `${currentUserDetails.resourceType}/${currentUserDetails.id}` ??
              '',
          },
        },
      ],
      target: [
        {
          id: orderDetail.serviceRequest.id,
          reference:
            `${orderDetail.serviceRequest.resourceType}/${orderDetail.serviceRequest.id}` ??
            '',
        },
        {
          id: orderDetail.task.id,
          reference:
            `${orderDetail.task.resourceType}/${orderDetail.task.id}` ?? '',
        },
      ],
    }
    mainTask.relevantHistory = [
      ...(mainTask.relevantHistory ?? []),
      {
        reference: `${statusProvenance.resourceType}/${mainProvenanceId}`,
      },
    ]

    const mainTaskMatchString: string = `W/${JSON.stringify(
      mainTask.meta?.versionId ?? ' '
    )}`

    if (errorReason) {
      oldUpdatedServiceRequest.patientInstruction = errorReason
    }
    let modifiedOldSlot: R4.ISlot = {
      resourceType: 'Slot',
      schedule: {},
    }

    if (orderDetail.slot) {
      modifiedOldSlot = { ...orderDetail.slot }
      modifiedOldSlot.status = R4.SlotStatusKind._free
    }
    const matchOldSlotString: string = `W/${JSON.stringify(
      modifiedOldSlot.meta?.versionId ?? ' '
    )}`

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${statusProvenance.resourceType}/`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: statusProvenance.resourceType,
          },
          resource: statusProvenance,
        },
        {
          fullUrl: `${oldUpdatedServiceRequest.resourceType}/${oldUpdatedServiceRequest.id}`,
          request: {
            ifMatch: oldAppointmentMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedServiceRequest.resourceType}/${oldUpdatedServiceRequest.id}`,
          },
          resource: oldUpdatedServiceRequest,
        },

        {
          fullUrl: `${mainTask.resourceType}/${mainTask.id}`,
          request: {
            ifMatch: mainTaskMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${mainTask.resourceType}/${mainTask.id}`,
          },
          resource: mainTask,
        },
      ],
    }

    if (orderDetail.paymentReconilation) {
      const paymentReconciliation: R4.IPaymentReconciliation = {
        ...orderDetail.paymentReconilation,
      }
      const paymentVersion: string = `W/${JSON.stringify(
        paymentReconciliation.meta?.versionId ?? ' '
      )}`
      paymentReconciliation.status = 'cancelled'
      requestBundle.entry?.push({
        fullUrl: `${paymentReconciliation.resourceType}/${paymentReconciliation.id}`,
        request: {
          ifMatch: paymentVersion,
          method: R4.Bundle_RequestMethodKind._put,
          url: `${paymentReconciliation.resourceType}/${paymentReconciliation.id}`,
        },
        resource: paymentReconciliation,
      })
    }

    const code: string | undefined = getOrderTypeCode(orderDetail)
    if (code === 'home-sample-collection') {
      const subTaskProvenanceId: string = getUniqueTempId()
      if (orderDetail.homeServiceTask) {
        const subTask: R4.ITask = { ...orderDetail.homeServiceTask }
        subTask.status = R4.TaskStatusKind._cancelled
        subTask.reasonCode = {
          coding: [rescheduleReason],
        }
        const newStatus = {
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/trip-business-status',
          code: 'cancelled',
          display: 'Cancelled',
        }
        subTask.businessStatus = {
          text: newStatus.display,
          coding: [newStatus],
        }

        const subtTaskStatusProvenance: R4.IProvenance = {
          id: subTaskProvenanceId,
          resourceType: 'Provenance',
          occurredDateTime: new Date().toISOString(),

          activity: {
            text: 'Cancelled',
            coding: [
              {
                system: 'http://hl7.org/fhir/task-status',
                code: 'cancelled',
                display: 'Cancelled',
              },
            ],
          },
          agent: [
            {
              type: {
                coding: [
                  {
                    code: 'enterer',
                    display: 'Enterer',
                    system:
                      'http://terminology.hl7.org/CodeSystem/provenance-participant-type',
                  },
                ],
              },
              who: {
                id: currentUserDetails.id,
                reference:
                  `${currentUserDetails.resourceType}/${currentUserDetails.id}` ??
                  '',
              },
            },
          ],
          target: [
            {
              id: orderDetail.homeServiceTask.id,
              reference:
                `${orderDetail.homeServiceTask.resourceType}/${orderDetail.homeServiceTask.id}` ??
                '',
            },
          ],
        }

        subTask.relevantHistory = [
          ...(subTask.relevantHistory ?? []),
          {
            reference: `${subtTaskStatusProvenance.resourceType}/${subTaskProvenanceId}`,
          },
        ]

        const subTaskMatchString: string = `W/${JSON.stringify(
          subTask.meta?.versionId ?? ' '
        )}`

        requestBundle.entry?.push({
          fullUrl: `${subtTaskStatusProvenance.resourceType}/`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: subtTaskStatusProvenance.resourceType,
          },
          resource: subtTaskStatusProvenance,
        })

        requestBundle.entry?.push({
          fullUrl: `${subTask.resourceType}/${subTask.id}`,
          request: {
            ifMatch: subTaskMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${subTask.resourceType}/${subTask.id}`,
          },
          resource: subTask,
        })

        const slotRef: R4.IReference | undefined =
          getInputIdentifierValueByCodemOfInvitation(
            orderDetail.homeServiceTask,
            'slot-reference'
          )?.valueReference

        if (oldSlot) {
          const slotDataOld: R4.ISlot = { ...oldSlot }
          const slotVesrsionOld: string = `W/${JSON.stringify(
            slotDataOld.meta?.versionId ?? ' '
          )}`
          slotDataOld.status = R4.SlotStatusKind._free
          requestBundle.entry?.push({
            fullUrl: `${slotDataOld.resourceType}/${slotDataOld.id}`,
            request: {
              ifMatch: slotVesrsionOld,
              method: R4.Bundle_RequestMethodKind._put,
              url: `${slotDataOld.resourceType}/${slotDataOld.id}`,
            },
            resource: slotDataOld,
          })
        }
      }
    }

    return requestBundle
  }

  return undefined
}

async function getSlotCurrentState(
  slotId: string
): Promise<R4.ISlot | undefined> {
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response = await fhirApi.doGetResource(`/Slot/${slotId}`)
  const relatedFhirDecodeRes: E.Either<Errors, R4.ISlot> =
    R4.RTTI_Slot.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const slotResponse: R4.ISlot = relatedFhirDecodeRes.right
    return slotResponse
  }
  return undefined
}

async function getRefund(
  appointmentId: string,
  paymentReconciliationId: string,
  type: string
): Promise<boolean> {
  const requestBody: R4.IParameters = getRefundBodyForOrder(
    appointmentId,
    paymentReconciliationId,
    type
  )
  if (requestBody) {
    logger.info('Payment body')
    logger.info(requestBody)
    const fhirApi: EnrolCient = new EnrolCient()
    const response: any = await fhirApi.doCreateEnrolmentFlowRequest(
      `payment/cancel/portal`,
      requestBody
    )
    logger.info('Payment Response')
    logger.info(response)

    if (response.status === 200) {
      return true
    }
  }

  return false
}

export default orderManagementSlice.reducer
