import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetPractionerEditState } from 'redux/practitioner/PractitionerManagement/practionerupdateSlice'
import { fetchUser } from 'redux/practitioner/practitionerSearchHandler/PractitionerManagement'
import { searchPractitioners } from 'redux/practitioner/practitionerSearchHandler/practitionerSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserPractitionerRoleDetailsWithPractitioner,
  getCurrentUserUnitDetails,
  getCurrentUserUnitLocationDetails,
  isMedicalServiceProvider,
} from 'services/userDetailsService'
import { getPractitionersListFourUser } from 'utils/admisnistration/users/usersHelper'
import {
  getCodeOfSystemFromCodableConceptList,
  getWorkingHours,
} from 'utils/fhirResourcesHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { UserDetails } from './userDetails'
import { UsersDataTable } from './usersDataTable'

export interface IUnitAdmin {
  onClosed: () => void
  onTap?: () => void
  onAdd?: () => void
  searchText?: string
}

export const UserSearch: React.FC<IUnitAdmin> = ({
  onClosed,
  onTap,
  onAdd,
  searchText,
}) => {
  const practitionerSearchSlice = useSelector(
    (state: RootState) => state.practitionerSearchSlice
  )

  const practitionerManagement = useSelector(
    (state: RootState) => state.practitionerManagement
  )
  const dispatch = useDispatch()
  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<R4.IOrganization>(getCurrentUserUnitDetails())
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      currentOrganizationDetails.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''
  const currentPractWithRole: PractitionerWithRole | undefined =
    getCurrentUserPractitionerRoleDetailsWithPractitioner()
  const [selectedLabTypes, setSelectedLabTypes] = useState<string[]>([
    'completed',
    'requested',
  ])

  const [text, setText] = useState<string>()
  const locationDetails = getCurrentUserUnitLocationDetails()

  const businessHours = getWorkingHours(locationDetails.hoursOfOperation ?? [])

  const [selectedUserDetails, setSelectedUserDetails] =
    useState<PractitionerWithRole>()
  const { width } = useWindowDimensions()
  const [selectedPractitioners, setSelectedPractitioners] = useState<
    PractitionerWithRole[]
  >(
    isMedicalServiceProvider() && currentPractWithRole
      ? [currentPractWithRole]
      : []
  )

  useEffect(() => {
    if (onAdd) onAdd()
    dispatch(searchPractitioners(searchText ?? ''))
  }, [dispatch, onAdd])

  useEffect(() => {
    if (onAdd) onAdd()
    if (practitionerSearchSlice.searching) {
      setSelectedUserDetails(undefined)
    }
  }, [onAdd, practitionerSearchSlice.searching])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        backgroundColor: '#ececec',
      }}
    >
      {/* <Box display='flex' px={1} paddingBottom={1}>
        <TextField
          variant='outlined'
          onChange={(event) => {
            setText(event.target.value)
            dispatch(searchPractitioners(event.target.value))
          }}
          size='small'
          value={text}
          autoFocus={true}
          placeholder='Search By User Name'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
              </InputAdornment>
            ),
          }}
        />
      </Box> */}
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
            >
              {practitionerSearchSlice.searching && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>
            <Box
              display='flex'
              flexGrow={4}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
              style={{
                backgroundColor: '#ececec',
              }}
            >
              {practitionerSearchSlice.noResultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    align='center'
                  >
                    No User Data Available.
                  </Typography>
                </Box>
              )}
              {practitionerSearchSlice.error && (
                <Box
                  //   display='flex'
                  //   flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                  paddingLeft={50}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container justifyContent='center'>
                        <Typography
                          variant='subtitle1'
                          color='error'
                          align='center'
                        >
                          Error while searching Users . Please try again
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {practitionerSearchSlice.resultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height='100%'
                  overflow='auto'
                  // flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexGrow={1}
                    overflow='auto'
                    height='100%'
                    //  paddingTop={0.2}
                  >
                    <UsersDataTable
                      usersData={
                        practitionerSearchSlice.doctorRoleList
                          ? getPractitionersListFourUser(
                              practitionerSearchSlice.doctorRoleList
                            )
                          : []
                      }
                      onUserSelected={(user: PractitionerWithRole) => {
                        setSelectedUserDetails(user)

                        dispatch(fetchUser(user.practRoleId ?? ''))
                        if (onTap) onTap()
                      }}
                    />
                  </Box>
                  {selectedUserDetails && (
                    <Box
                      display='flex'
                      flexGrow={4}
                      overflow='auto'
                      minWidth={`${width - 460}px`}
                      height='100%'
                      style={{ backgroundColor: '#ececec' }}
                    >
                      <UserDetails
                        businessHoursData={businessHours}
                        userDetailsData={selectedUserDetails}
                        onCloseClicked={() => {
                          setText('')
                          onClosed()
                          setSelectedUserDetails(undefined)
                        }}
                        onCloseClickedWithoutChange={() => {
                          setSelectedUserDetails(undefined)
                        }}
                        onEnabled={() => {
                          setSelectedUserDetails(undefined)
                          dispatch(resetPractionerEditState())
                          dispatch(searchPractitioners())
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </div>
  )
}
