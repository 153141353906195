import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Grid, Typography } from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserPractitionerRoleDetailsWithPractitioner,
  getCurrentUserUnitDetails,
  isMedicalServiceProvider,
} from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { PackageList } from './packageList'

export interface IUnitAdmin {
  onTap: () => void
}

export const PackageSearch: React.FC<IUnitAdmin> = ({ onTap }) => {
  const practitionerSearchSlice = useSelector(
    (state: RootState) => state.practitionerSearchSlice
  )

  const practitionerManagement = useSelector(
    (state: RootState) => state.practitionerManagement
  )
  const dispatch = useDispatch()
  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<R4.IOrganization>(getCurrentUserUnitDetails())
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      currentOrganizationDetails.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''
  const currentPractWithRole: PractitionerWithRole | undefined =
    getCurrentUserPractitionerRoleDetailsWithPractitioner()
  const [selectedLabTypes, setSelectedLabTypes] = useState<string[]>([
    'completed',
    'requested',
  ])

  const [selectedUserDetails, setSelectedUserDetails] =
    useState<PractitionerWithRole>()
  const { width } = useWindowDimensions()
  const [selectedPractitioners, setSelectedPractitioners] = useState<
    PractitionerWithRole[]
  >(
    isMedicalServiceProvider() && currentPractWithRole
      ? [currentPractWithRole]
      : []
  )

  //   useEffect(() => {
  //     dispatch(searchPractitioners())
  //   }, [dispatch])

  return (
    <Box display='flex' flexDirection='column' flexGrow={1} width='100%'>
      <Box
        height='100%'
        width='100%'
        style={{
          backgroundColor: 'white',
        }}
      >
        <PackageList />
      </Box>
    </Box>
  )
}
