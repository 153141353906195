import { R4 } from '@ahryman40k/ts-fhir-types'
import { Typography } from '@material-ui/core'
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab'
import { kPrimaryMain } from 'configs/styles/muiThemes'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getShortTimeWithDate } from 'utils/dateUtil'
import { getOrderStatusAlongWithAgent } from 'utils/fhirResoureHelpers/labOrderHelpers'

interface Props {
  orderEvent: R4.IProvenance
  isStartEvent?: boolean
  isEndEvent?: boolean
}

export const OrderTimeLineEvent: React.FC<Props> = ({
  orderEvent,
  isStartEvent = false,
  isEndEvent = false,
}: Props) => {
  const { t } = useTranslation()

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography
          color={
            orderEvent.activity?.text !== 'Cancelled' &&
            !orderEvent.activity?.text?.includes('Aborted')
              ? 'initial'
              : 'error'
          }
          variant='body2'
        >
          {t(
            'labelCommon:' +
              `${getOrderStatusAlongWithAgent(orderEvent.activity?.text)}`
          )}
        </Typography>
      </TimelineOppositeContent>
      {orderEvent.activity?.text?.includes('Delivered') && (
        <TimelineDot color='primary' />
      )}
      {orderEvent.activity?.text?.includes('Aborted') && (
        <TimelineDot
          style={{
            backgroundColor: 'red',
          }}
        />
      )}
      {!orderEvent.activity?.text?.includes('Delivered') &&
        !orderEvent.activity?.text?.includes('Aborted') && (
          <TimelineSeparator>
            <TimelineDot
              color={
                orderEvent.activity?.text !== 'Cancelled'
                  ? 'primary'
                  : 'secondary'
              }
            />

            {orderEvent.activity?.text !== 'Cancelled' && (
              <TimelineConnector
                style={{
                  backgroundColor: isEndEvent ? undefined : kPrimaryMain,
                }}
              />
            )}
          </TimelineSeparator>
        )}
      <TimelineContent>
        <Typography
          variant='body2'
          color={
            orderEvent.activity?.text !== 'Cancelled' &&
            !orderEvent.activity?.text?.includes('Aborted')
              ? 'initial'
              : 'error'
          }
        >
          {getShortTimeWithDate(orderEvent.occurredDateTime ?? '')}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  )
}
