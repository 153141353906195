import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitDetails,
} from 'services/userDetailsService'

import { AddingLabReferralInviteStatus } from './addingLabReferralInviteStatus'

const initialState: AddingLabReferralInviteStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addLabReferralSlice = createSlice({
  name: 'addLabReferralSlice',
  initialState,
  reducers: {
    updateAddConditionStatus(
      state,
      action: PayloadAction<AddingLabReferralInviteStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetAllergiesDetails(
      state,
      action: PayloadAction<AddingLabReferralInviteStatus>
    ) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
    },
  },
})

export const addReferralDetails =
  (organizationDetails: R4.IOrganization): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AddingLabReferralInviteStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(addLabReferralSlice.actions.updateAddConditionStatus(addingState))
    try {
      const unitOrg = getCurrentUserUnitDetails()
      const practRole: R4.IPractitionerRole =
        getCurrentUserPractitionerRoleDetails()
      const inviteData: R4.ITask = {
        resourceType: 'Task',
        identifier: [
          {
            value: 'unit-referral-invite',
          },
        ],
        status: R4.TaskStatusKind._requested,
        intent: R4.TaskIntentKind._proposal,
        priority: 'routine',
        code: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-task-code-cs',
              code: 'partner-lab-invitation',
              display: 'Partner Lab Invitation',
            },
          ],
        },
        for: {
          id: organizationDetails.id ?? '',
          reference: `Organization/${organizationDetails.id ?? ''}`,
          display: organizationDetails.name,
        },
        authoredOn: new Date().toISOString(),
        lastModified: new Date().toISOString(),
        owner: {
          id: unitOrg.id ?? '',
          reference: `Organization/${unitOrg.id ?? ''}`,
          display: unitOrg.name ?? '',
        },
        requester: {
          id: practRole.id ?? '',
          reference: `PractitionerRole/${practRole.id}`,
        },
      }

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRResourceRequest(
        '/Task',
        inviteData
      )

      const respDecoded: E.Either<Errors, R4.ITask> =
        R4.RTTI_Task.decode(response)
      if (respDecoded._tag === 'Right') {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        dispatch(showSuccessAlert('Invite sent successfully'))

        dispatch(
          addLabReferralSlice.actions.updateAddConditionStatus(addingState)
        )
      } else {
        const errorCreatePersonState: AddingLabReferralInviteStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while sending invite',
        }
        dispatch(
          addLabReferralSlice.actions.updateAddConditionStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: AddingLabReferralInviteStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while sending invite',
      }
      dispatch(
        addLabReferralSlice.actions.updateAddConditionStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetReferralAdd = () => (dispatch: AppDispatch) => {
  dispatch(addLabReferralSlice.actions.resetAllergiesDetails(initialState))
}

export default addLabReferralSlice.reducer
