/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import ContentShimmer from 'react-content-shimmer'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import 'react-vis/dist/style.css'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { isMedicalServiceProvider } from 'services/userDetailsService'
import { getAccessToken } from 'utils/authHelpers'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  addObservationDetailsForSys,
  deleteObservationDetails,
  getObservationResourceForPostForSystemicExam,
  getObservationsOfSpecificCategoryInEncounter,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { getVendorPartId } from 'utils/routes_helper'
import { OCARenderer } from 'wello-web-components'

interface AyurvedaHistoryAndSubjectiveProps {
  obsList: R4.IObservation[]
}

export const ObesityObjectiveHistory: React.FC<AyurvedaHistoryAndSubjectiveProps> =
  ({ obsList }: AyurvedaHistoryAndSubjectiveProps) => {
    const { t } = useTranslation(['common'])
    const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())
    const [observations, setObservations] = useState<R4.IObservation[]>([])
    const dispatch = useDispatch()
    const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
      initial: true,
    })

    // useEffect(() => {
    //   setObservations(obsList)
    // }, [])

    return (
      <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
        <Box py={1}>
          <Typography variant='h6'> General </Typography>
        </Box>{' '}
        <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
          <OCARenderer
            OCAFormProviderBaseURL={`${
              process.env.REACT_APP_CC_WORKFLOW_URL
            }${getVendorPartId()}/ocaForms/forms`}
            OCAFormProviderHeader={{
              Authorization: `Bearer ${getAccessToken()}`,

              'Cache-Control': 'no-cache',
            }}
            isViewOnly={true}
            onSubmit={(
              observation: R4.IObservation,
              onSubmitSuccess?: (data: any) => void
            ) => {
              // const updatedObservation =
              //   getObservationResourceForPostForSystemicExam(
              //     fhirAppointmentDetails.patient.id!,
              //     fhirAppointmentDetails.encounter!.id!,
              //     [],
              //     observation
              //   )
              // addObservationDetailsForSys(updatedObservation).then((e) => {
              //   if (e === false) {
              //     dispatch(
              //       showErrorAlert('Error while updating observation details')
              //     )
              //   } else if (
              //     (e as R4.IObservation).resourceType === 'Observation'
              //   ) {
              //     if (onSubmitSuccess) {
              //       onSubmitSuccess(e)
              //     }
              //     // getObservationDetails()
              //     dispatch(
              //       showSuccessAlert(
              //         'Observation details updated successfully'
              //       )
              //     )
              //   } else {
              //     dispatch(
              //       showErrorAlert('Error while updating observation details')
              //     )
              //   }
              // })
            }}
            onDelete={(
              observation: R4.IObservation,
              onSuccess?: ((data: any) => void) | undefined,
              onError?: ((data: any) => void) | undefined
            ) => {}}
            formSection='soap'
            formCategory='objective'
            displayAsPopup={true}
            formName='Anthropometric Measurements_OCA'
            existingObservations={obsList}
          />
        </Box>
      </Box>
    )
  }
