import { SocketEventData } from 'models/socketEventData'

import React, { useState, useEffect } from 'react'
import { io, Socket } from 'socket.io-client'
import { getAccessToken } from 'utils/authHelpers'
import SocketContext from 'views/contexts/socketMessageContext'

interface SocketProviderProps {
  url: string
}

function SocketProvider({
  url,
  children,
}: React.PropsWithChildren<SocketProviderProps>) {
  const [socket, setSocket] = useState<Socket | null>(null)
  const [eventData, setEventData] = useState<SocketEventData | null>(null)

  useEffect(() => {
    console.log('----------socket url----------')
    console.log(url)
    const newSocket = io(url, {
      forceNew: true,
      path: '/apis/cc-workflows/socket.io',
      extraHeaders: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      reconnectionAttempts: 3,
      rememberUpgrade: true,
      retries: 3,
      transports: ['websocket'],
      auth: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      autoConnect: true,
      upgrade: true,
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        },
      },
    })

    console.log(newSocket)
    newSocket.on('connect', () => {
      console.log('Connection established!')
      console.log(newSocket.id)
    })
    setEventData(null)

    newSocket.on('workflowUpdate', (data: any) => {
      setEventData(null)
      console.log(data)
      console.log(JSON.stringify(data))
      setEventData(data)
    })

    return () => {
      newSocket.disconnect()
    }
  }, [url])

  return (
    <SocketContext.Provider value={{ socket, eventData }}>
      {children}
    </SocketContext.Provider>
  )
}

export default SocketProvider
