import { R4 } from '@ahryman40k/ts-fhir-types'
import { FhirPartnerLabDetails } from 'models/fhirPartnerLabDetails'
import {
  getIdentifierValueBySystem,
  getNumberValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'

export function getExpandedPartnerLabFromBundle(
  responseTaskBundle: R4.IBundle
): FhirPartnerLabDetails[] {
  const convertedPartnerLabData: FhirPartnerLabDetails[] = []
  const task: any = {}
  const practitionerRole: any = {}

  if (responseTaskBundle.total) {
    if (responseTaskBundle.total > 0) {
      if (responseTaskBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseTaskBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Task':
                  task[value.resource.id] = value.resource as R4.ITask
                  break
                case 'PractitionerRole':
                  practitionerRole[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break

                default:
                  break
              }
            }
          }
        })
        for (const key in task) {
          if (key) {
            const taskResource: R4.ITask = task[key] as R4.ITask

            const taskOwner = taskResource.owner?.reference ?? ''
            const ownerRef = taskOwner.split('/')
            let labName: string = ''
            let primaryRole: string = ''
            const iden = taskResource.identifier
              ? taskResource.identifier[0].value
              : ''
            let id: string = ''
            let primaryContact: string | undefined = ''
            const isVerified = taskResource.status ?? ''
            let locId: string = ''
            for (const key1 in practitionerRole) {
              if (key1) {
                const pract: R4.IPractitionerRole = practitionerRole[
                  key1
                ] as R4.IPractitionerRole
                const roles: R4.ICodeableConcept[] = pract.code ?? []

                if (pract.id === ownerRef[1]) {
                  if (roles.length > 0) {
                    if (roles[0] && roles[0].coding && roles[0].coding[0]) {
                      primaryRole = roles[0].coding[0].display
                        ? roles[0].coding[0].display
                        : ''
                    }
                  }
                  id = pract.id
                  labName = pract.practitioner?.display ?? ''
                  primaryContact = getIdentifierValueBySystem(
                    pract.identifier ?? [],
                    'email'
                  )
                  if (pract.location) {
                    if (pract.location.length > 1) {
                      locId = pract.location[1].reference ?? ''
                    }
                  }
                }
              }
            }

            if (
              iden === 'unit-prole-invite' &&
              primaryRole.includes('Partner Lab')
            ) {
              convertedPartnerLabData.push({
                id,
                labName,
                primaryContact,
                commission: '0%',
                commission_Data: 0,
                testCount: 0,
                task,
                orderCount: 0,
                isVerified: isVerified !== 'requested',
                locationId: locId,
              })
            }
          }
        }
      }
    }
  }
  return convertedPartnerLabData
}

export function getExpandedPartnerLabFromBundleCount(
  responseTaskBundle: R4.IBundle,
  fhirlabdetail: FhirPartnerLabDetails
): FhirPartnerLabDetails {
  const convertedPartnerLabData: FhirPartnerLabDetails = fhirlabdetail
  const planDef: any = {}
  const serviceRequest: any = {}
  const location: any = {}
  const testCount: string[] = []
  let commission: number = 0

  if (responseTaskBundle.total) {
    if (responseTaskBundle.total > 0) {
      if (responseTaskBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseTaskBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'PlanDefinition':
                  planDef[value.resource.id] =
                    value.resource as R4.IPlanDefinition
                  break
                case 'ServiceRequest':
                  serviceRequest[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                case 'Location':
                  location[value.resource.id] = value.resource as R4.ILocation
                  break

                default:
                  break
              }
            }
          }
        })

        for (const key in serviceRequest) {
          if (key) {
            const plan: R4.IServiceRequest = serviceRequest[
              key
            ] as R4.IServiceRequest
            if (plan.id) testCount.push(plan.id)
          }
        }

        for (const key in location) {
          if (key) {
            const loc: R4.ILocation = location[key] as R4.ILocation

            if (loc.extension)
              commission = getNumberValueExtensionsOfUrl(
                loc.extension,
                'http://wellopathy.com/fhir/india/core/CodeSystem/commission'
              )
          }
        }
        convertedPartnerLabData.commission = `${commission} %`
        convertedPartnerLabData.commission_Data = commission
        convertedPartnerLabData.orderCount = testCount.length
      }
    }
  }
  return convertedPartnerLabData
}
