import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { getExpandedAppointmentFromBundle } from 'utils/common/patientDataTableHelper'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import { UmlClient } from 'services/umlsClient'
import { EbmClinet } from 'services/ebmServiceClient'
import { getEvidenceData } from 'utils/patientHelper/patientEditHelper'
import { EvidenceReportSearchStatus } from './evidenceReportSearchStatus'

const initialState: EvidenceReportSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const evidenceSearchSlice = createSlice({
  name: 'evidence',
  initialState,
  reducers: {
    searchingAllergy(
      state,
      action: PayloadAction<EvidenceReportSearchStatus>
    ) {},

    searchResults(state, action: PayloadAction<EvidenceReportSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.keys = action.payload.keys
      state.evidenceList = action.payload.evidenceList
      state.resultData = action.payload.resultData
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<EvidenceReportSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.keys = action.payload.keys
      state.evidenceList = action.payload.evidenceList
      state.resultData = action.payload.resultData
    },

    errorWhileSearching(
      state,
      action: PayloadAction<EvidenceReportSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.keys = action.payload.keys
      state.evidenceList = action.payload.evidenceList
      state.resultData = action.payload.resultData
    },
    resetState(state, action: PayloadAction<EvidenceReportSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.keys = undefined
      state.evidenceList = undefined
      state.resultData = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: EvidenceReportSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      keys: undefined,
      evidenceList: undefined,
      resultData: undefined,
    }
    dispatch(evidenceSearchSlice.actions.resetState(state))
  }

export const searchEvidence =
  (drugId: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: EvidenceReportSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      evidenceSearchSlice.actions.errorWhileSearching(errorSearchPatient)
    )
    try {
      const fhirClient: EbmClinet = new EbmClinet()
      const response: any = await fhirClient.doGetResource(
        `/drug/evidences?ingredientConceptId=${drugId}`
      )
      if (response.length > 0) {
        const searchPatientResult: EvidenceReportSearchStatus = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          evidenceList: response,
        }
        const groupBy = getEvidenceData(response, 'hoiConceptName')
        searchPatientResult.resultData = groupBy
        const keys: string[] = Object.keys(groupBy).sort()
        searchPatientResult.keys = keys

        dispatch(evidenceSearchSlice.actions.searchResults(searchPatientResult))
        return
      }

      const noSearchResults: EvidenceReportSearchStatus = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(
        evidenceSearchSlice.actions.noDataFoundForSearch(noSearchResults)
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: EvidenceReportSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        evidenceSearchSlice.actions.errorWhileSearching(errorWhileSearchPatient)
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(evidenceSearchSlice.actions.resetState(initialState))
}

export default evidenceSearchSlice.reducer
