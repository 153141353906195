import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { updateAppointmentDetails } from 'redux/appointments/appointmentViewHandler/appointmentHandlerSlice'
import { getInstructionsOfAppointment } from 'redux/consultation/instructions_list_slice/instructionsListSlice'
import { getLabRequestsOfAppointment } from 'redux/consultation/lab_diagnostics_requests_list/labDiagnosticsListSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import {
  addAndGetEncounterDetailsOfAppointment,
  addAndGetEncounterIdOfAppointment,
} from 'utils/appointment_handle/cds_recommendations_util'
import {
  getCarePlanByIdCurrentState,
  getCarePlanOfPatient,
  getCommunicationResourceForFollowUp,
  hasPlanOfferedInOrg,
} from 'utils/careplan_utils/careplan_utils'
import { logger } from 'utils/logger'
import { getVendorPartId } from 'utils/routes_helper'
import { LabTestsAdditionStatus } from './labTestsAdditionState'

const initialState: LabTestsAdditionStatus = {
  addingLabTests: false,
  additionSuccessful: false,
  noResultsAvailable: false,
  errorWhileAdding: false,
}

const labTestsAdditionSlice = createSlice({
  name: 'labTestsAdditionSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<LabTestsAdditionStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.addingLabTests = action.payload.addingLabTests
      state.additionSuccessful = action.payload.additionSuccessful
      state.successMessage = action.payload.successMessage
      state.errorReason = action.payload.errorReason
      state.errorWhileAdding = action.payload.errorWhileAdding
    },
  },
})

export const requestCareTeamLabOrder =
  (
    patient: R4.IPatient,
    planDefinitions: R4.IPlanDefinition[],
    appointment: FhirAppointmentDetail,
    carePlanUrl?: string,
    carePlanId?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: LabTestsAdditionStatus = {
      ...initialState,
    }
    state.addingLabTests = true
    dispatch(labTestsAdditionSlice.actions.updatedStatus(state))
    try {
      const carePlanOfPatient: R4.ICarePlan | undefined =
        carePlanId !== undefined
          ? await getCarePlanByIdCurrentState(carePlanId)
          : await getCarePlanOfPatient(patient.id!, carePlanUrl ?? '', true)
      if (carePlanOfPatient) {
        let encounterId: string | undefined = appointment.encounter?.id
        if (
          appointment.encounter === undefined ||
          appointment.encounter?.id === undefined ||
          appointment.encounter?.id?.length === 0
        ) {
          const encounter: R4.IEncounter | undefined =
            await addAndGetEncounterDetailsOfAppointment(appointment)
          dispatch(updateAppointmentDetails(appointment.appointment.id!))

          if (encounter) {
            encounterId = encounter.id!
          }
        }

        const testCodes: string[] = planDefinitions.map(
          (e) => e.identifier![0].value!
        )

        let hasPlanDef = false
        if (carePlanUrl) hasPlanDef = await hasPlanOfferedInOrg(carePlanUrl)
        if (hasPlanDef) {
          const response: AxiosResponse = (await requestLabOrders(
            testCodes,
            appointment.patient,
            appointment,
            carePlanOfPatient!.id!,
            encounterId!,
            planDefinitions ?? []
          )) as AxiosResponse
          if (response) {
            if (response.status === 200 || response.status === 201) {
              state.addingLabTests = false
              state.additionSuccessful = true
              state.errorWhileAdding = false
              state.successMessage =
                'Lab Order Created. Care team will take care'
              state.errorReason = undefined
              dispatch(
                labTestsAdditionSlice.actions.updatedStatus(initialState)
              )
              dispatch(showSuccessAlert(state.successMessage!))
              setTimeout(() => {
                dispatch(getLabRequestsOfAppointment(appointment))
              }, 500)
            } else {
              state.addingLabTests = false
              state.additionSuccessful = false
              state.errorWhileAdding = true
              state.successMessage = undefined
              state.errorReason =
                'Error while adding lab tests recommendation. Try later'
              dispatch(showErrorAlert(state.errorReason!))
              dispatch(
                labTestsAdditionSlice.actions.updatedStatus(initialState)
              )
            }
          }
        }
      } else {
        state.addingLabTests = false
        state.additionSuccessful = false
        state.errorWhileAdding = true
        state.successMessage = undefined
        state.errorReason = 'User is not subscribed to Care Plan'
        dispatch(showErrorAlert(state.errorReason!))
        dispatch(labTestsAdditionSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      console.error(error)
      const resState: LabTestsAdditionStatus = {
        addingLabTests: false,
        errorWhileAdding: true,
        additionSuccessful: false,
        noResultsAvailable: false,
        errorReason: (error as AxiosError).response?.data,
      }
      dispatch(showErrorAlert(state.errorReason!))
      dispatch(labTestsAdditionSlice.actions.updatedStatus(resState))
    }
  }

export const requestLabOrders = async (
  planDefinitions: string[],
  patient: R4.IPatient,
  appointment: FhirAppointmentDetail,
  careplanId: string,
  encounterId: string,
  planDefinitionRes: R4.IPlanDefinition[]
): Promise<AxiosResponse | undefined> => {
  try {
    const body: any = {
      labTestIds: planDefinitions,
      preferredDate: '',
      preferredAppointMentType: '',
      patientId: patient.id,
      carePlanId: careplanId,
      encounterId,
      isLabOrderTask: true,
      planDefinitions: planDefinitionRes,
    }

    const enRolClient: EnrolCient = new EnrolCient()
    const response: AxiosResponse =
      await enRolClient.doCreateEnrolmentFlowRequest('lab-order/', body)

    return response
  } catch (error) {
    console.error('--------error in referring lab tests---------')
    console.error(error)
  }
  return undefined
}

export default labTestsAdditionSlice.reducer
