import { Box } from '@material-ui/core'
import MuiPhoneInput, { MuiPhoneNumberProps } from 'material-ui-phone-number'
import React from 'react'
import { WelloFormItemLabel } from './WelloFormItemLabel'

interface WelloPhoneNumberFieldProps {
  title: string
  textProps: MuiPhoneNumberProps
}

export const WelloPhoneNumberField: React.FunctionComponent<WelloPhoneNumberFieldProps> =
  (props: WelloPhoneNumberFieldProps) => (
    <Box display='flex' flexDirection='column' flexGrow={1}>
      <WelloFormItemLabel title={props.title} />
      <MuiPhoneInput
        variant='outlined'
        id='phone'
        preferredCountries={['in']}
        {...props.textProps}
      />
    </Box>
  )
