import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'

import { TherapyDataUpdate } from 'models/therapyDataUpdate'
import { AppDispatch, AppThunk } from 'redux/store'

import { FHIRApiClient } from 'services/fhirApiServices'

import {
  getCodeOfSystemFromCodableConceptArray,
  getValueDurationFromExtensionTherapy,
  getValueRefValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import { getPriceComponentFromChangeItem } from 'utils/fhirResoureHelpers/fhirSlotHelper'

import { logger } from 'utils/logger'
import { TherapyTableCatalogSearchStatus } from './therapyTableSearchStatus'

const initialState: TherapyTableCatalogSearchStatus = {
  searchingTreatment: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingTreatment: false,
}

const therapyTableCatalogSearchSlice = createSlice({
  name: 'threapyCatalogSearchSlice',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<TherapyTableCatalogSearchStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingTreatment = action.payload.searchingTreatment
      state.resultsAvailable = action.payload.resultsAvailable
      state.therapyList = action.payload.therapyList
      state.therapyListWithPrice = action.payload.therapyListWithPrice
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingTreatment =
        action.payload.errorWhileSearchingTreatment
    },
  },
})

export const getAllTherapist =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: TherapyTableCatalogSearchStatus = {
      searchingTreatment: true,
      errorWhileSearchingTreatment: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(therapyTableCatalogSearchSlice.actions.updatedStatus(state))

    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doGetResource(
        `HealthcareService?service-category=300,301,302,303,387854002,44868003,229224000&_count=200&_include=HealthcareService:healthcare-service-billing`
      )

      if (response._tag === 'Left') {
        const errorSearchDoctor: TherapyTableCatalogSearchStatus = {
          searchingTreatment: false,
          errorWhileSearchingTreatment: true,
          resultsAvailable: false,
          errorReason: 'Error while searching Treatment Plan',
        }
        dispatch(
          therapyTableCatalogSearchSlice.actions.updatedStatus(
            errorSearchDoctor
          )
        )

        dispatch(therapyTableCatalogSearchSlice.actions.updatedStatus(state))
      } else {
        const resp: R4.IBundle = response as R4.IBundle

        const therapyDataList: R4.IHealthcareService[] =
          resp.entry?.map((item) => item.resource as R4.IHealthcareService) ??
          []

        const therapyDataListWithPrice: TherapyDataUpdate[] =
          getTherapyListWithChargeItem(resp)

        therapyDataListWithPrice.sort((a, b) =>
          a.name! > b.name! ? 1 : b.name! > a.name! ? -1 : 0
        )

        state.resultsAvailable = true
        state.searchingTreatment = false
        state.therapyList = therapyDataList
        state.therapyListWithPrice = therapyDataListWithPrice
        state.noResultsAvailable = false
        state.errorReason = undefined
        state.errorWhileSearchingTreatment = false
        dispatch(therapyTableCatalogSearchSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorSearchDoctor: TherapyTableCatalogSearchStatus = {
        searchingTreatment: false,
        errorWhileSearchingTreatment: true,
        resultsAvailable: false,
        errorReason: 'Error while searching Therapies',
      }
      dispatch(
        therapyTableCatalogSearchSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export function getTherapyListWithChargeItem(
  responseBundle: R4.IBundle
): TherapyDataUpdate[] {
  const convertedAppointments: TherapyDataUpdate[] = []
  const healthService: any = {}
  const chargeDdef: any = {}
  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'HealthcareService':
                  healthService[value.resource.id] =
                    value.resource as R4.IHealthcareService
                  break
                case 'ChargeItemDefinition':
                  chargeDdef[value.resource.id] =
                    value.resource as R4.IChargeItemDefinition
                  break
                default:
                  break
              }
            }
          }
        })
        for (const key in healthService) {
          if (key) {
            const currentAppointment: R4.IHealthcareService = healthService[
              key
            ] as R4.IHealthcareService
            let payment: number = 0
            const chargeID: string | undefined =
              getValueRefValueExtensionsOfUrl(
                currentAppointment.extension,
                'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
              )?.split('/')[1]
            const chargeDdefData: R4.IChargeItemDefinition =
              chargeDdef[chargeID ?? '']
            if (chargeDdefData) {
              const priceComponent:
                | R4.IChargeItemDefinition_PriceComponent
                | undefined = getPriceComponentFromChangeItem(chargeDdefData)
              if (
                priceComponent &&
                priceComponent.amount &&
                priceComponent.amount.value
              ) {
                payment = priceComponent.amount.value
              }
            }
            const billing: string = currentAppointment.characteristic
              ? currentAppointment.characteristic[0].text ?? ''
              : ''

            //   const categorytherapy: string = currentAppointment.category ? (currentAppointment.category[0].coding ? (currentAppointment.category[0].coding[0].display ?? '') : '') : ''

            const therapyCode = getCodeOfSystemFromCodableConceptArray(
              currentAppointment.category!,
              'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-service-category-cs',
              'http://snomed.info/sct'
            )
            const therapyDuration = getValueDurationFromExtensionTherapy(
              currentAppointment.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndExtDurationTherapyDuration'
            )
            const therapyBilling = getCodeOfSystemFromCodableConceptArray(
              currentAppointment.characteristic!,
              'http://hl7.org/fhir/chargeitem-status',
              'http://hl7.org/fhir/chargeitem-status'
            )

            let therapyStatus: string = ''
            if (currentAppointment.active === true) therapyStatus = 'Active'
            else therapyStatus = 'Inactive'

            convertedAppointments.push({
              therapy: currentAppointment,
              price: payment ?? 0,
              name: currentAppointment.name ?? '',
              category: therapyCode?.display,
              duration: therapyDuration ?? 0,
              active: currentAppointment.active,
              billable: therapyBilling?.display,
              billableCode: therapyBilling ?? {},
              status: therapyStatus,
            })
          }
        }
      }
    }
  }
  return convertedAppointments
}

export default therapyTableCatalogSearchSlice.reducer
