import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'

import { getScheduleFromSlotsRelations } from 'utils/fhirResoureHelpers/fhirScheduleHelper'
import { logger } from 'utils/logger'
import { SearchSlotsStatus } from './SearchSlotsStatus'

const initialState: SearchSlotsStatus = {
  searchingSlots: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingSlots: false,
}

const SearchSlotsByScheduleSlice = createSlice({
  name: 'ScheduleSlotSearch',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<SearchSlotsStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingSlots = action.payload.searchingSlots
      state.resultsAvailable = action.payload.resultsAvailable
      state.slots = action.payload.slots
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingSlots = action.payload.errorWhileSearchingSlots
    },
  },
})

export const requestForSlots =
  (schedule: any): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: SearchSlotsStatus = {
      searchingSlots: true,
      errorWhileSearchingSlots: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(SearchSlotsByScheduleSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const convertedSlots: string[] = []
      const searchParameters: any = {
        schedule: `Schedule/${schedule.id}`,
        status: 'busy',
      }
      const slotResponse: any = await fhirClient.doGetResource(
        '/Slot',
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(slotResponse)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingSlots = true
        state.searchingSlots = false
        dispatch(SearchSlotsByScheduleSlice.actions.updatedStatus(state))
      } else {
        const slotResponseData: R4.IBundle = resp.right
        if (slotResponseData?.total && slotResponseData?.total > 0) {
          if (slotResponseData.entry) {
            for (let i = 0; i < slotResponseData.entry.length; i++) {
              const slots: string = slotResponseData.entry[i].resource?.id || ''
              convertedSlots.push(slots)
            }
          }

          state.resultsAvailable = true
          state.searchingSlots = false
          state.slots = convertedSlots
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingSlots = false
          dispatch(SearchSlotsByScheduleSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: SearchSlotsStatus = {
            searchingSlots: false,
            errorWhileSearchingSlots: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            SearchSlotsByScheduleSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: SearchSlotsStatus = {
        searchingSlots: false,
        errorWhileSearchingSlots: true,
        resultsAvailable: false,
        errorReason: 'Error while searching schedule',
      }
      dispatch(
        SearchSlotsByScheduleSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export default SearchSlotsByScheduleSlice.reducer
