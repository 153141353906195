import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, MenuItem, TextField, TextFieldProps } from '@material-ui/core'
import React, { useState } from 'react'
import { WelloFormItemLabel } from 'wello-web-components'

const maritalStatusList: R4.ICoding[] = [
  { code: '', display: 'Select' },
  {
    code: 'D',
    display: 'Divorced',
    system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
  },
  {
    code: 'M',
    display: 'Married',
    system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
  },
  {
    code: 'U',
    display: 'Single',
    system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
  },

  {
    code: 'W',
    display: 'Widowed',
    system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
  },
]

interface WelloMaritalStatusSelectorProps {
  title: string
  textFieldProps: TextFieldProps
  onChanged: (selectedGender: R4.ICoding | undefined) => void
  preSelectedGender?: R4.ICoding
  displayDoneLabel?: boolean
}

export const WelloMaritalStatusSelector: React.FunctionComponent<WelloMaritalStatusSelectorProps> =
  (props: WelloMaritalStatusSelectorProps) => {
    const [selectedMarital, setSelectedMarital] = useState<R4.ICoding>(
      props.preSelectedGender ?? { code: '', display: '' }
    )

    return (
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <WelloFormItemLabel
          title={props.title}
          displayDoneLabel={props.displayDoneLabel}
        />
        <Box>
          <TextField
            variant='outlined'
            fullWidth
            size='small'
            select
            value={selectedMarital?.code}
            label={selectedMarital?.code === '' ? 'Select' : ''}
            onChange={(val) => {
              const selectedGen: R4.ICoding | undefined =
                maritalStatusList.find(
                  (gender) => gender.code === val.target.value
                )
              setSelectedMarital(selectedGen ?? { code: '', display: 'Select' })
              props.onChanged(selectedGen)
            }}
            {...props.textFieldProps}
          >
            {maritalStatusList.map((option) => (
              <MenuItem
                id={option.code}
                key={option.code}
                value={option.code}
                selected={selectedMarital?.code === option.code}
                disabled={option?.code === ''}
              >
                {option.display}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
    )
  }
