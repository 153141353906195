import { R4 } from '@ahryman40k/ts-fhir-types'

export interface PractitionerWithRole {
  id: string
  practRoleId?: string
  name: string
  gender?: string
  color: string
  fullName: string
  primaryContact: string
  phone: string
  status: string
  unitName?: string
  roleObject: R4.IPractitionerRole
  role?: string
  unitDetails?: R4.IOrganization
  practitionerObject?: R4.IPractitioner
  locationObject?: R4.ILocation
  task?: R4.ITask
  chargeItemDef?: R4.IChargeItemDefinition[]
  inPersonRate?: R4.IChargeItemDefinition
  videoRate?: R4.IChargeItemDefinition
  phoneRate?: R4.IChargeItemDefinition
  enabled: boolean
}

export function getPractitionerRoleDisplay(
  PractitionerRole: R4.IPractitionerRole
): string | undefined {
  const roles: R4.ICodeableConcept[] = PractitionerRole.code ?? []
  if (roles.length > 0) {
    if (roles[0] && roles[0].coding && roles[0].coding[0]) {
      if (roles[0].coding[0].code === 'ayurveda') {
        return 'Ayurveda (Vaidya)'
      }

      return roles[0].coding[0].display
    }
  }
  return undefined
}
