/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { PurposeOfUse } from 'models/purposeOfUse'
import React from 'react'
import { useTranslation } from 'react-i18next'
import 'react-vis/dist/style.css'
import { CVSExaminationsListForEncounter } from './cvsExaminations/CVSExaminationsListForEncounter'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from './systemicExaminationList'

interface CardioVascularExaminationsListProps {
  onSelected: (categoryLabel: string) => void
  purposeOfUseType: PurposeOfUse
  encounterId: string
  patient: R4.IPatient
  isExpanded?: boolean
  isReadOnly?: boolean
}

export const CVSExaminationsAccordionForEncounter: React.FC<CardioVascularExaminationsListProps> =
  ({
    purposeOfUseType,
    encounterId,
    patient,
    onSelected,
    isReadOnly = false,
    isExpanded = false,
  }: CardioVascularExaminationsListProps) => {
    const { t } = useTranslation(['common'])

    return (
      <Accordion
        square
        expanded={isExpanded}
        onChange={(e) => {
          onSelected('cvs')
        }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          id='systems_cvs_accordion_header'
          expandIcon={<ExpandMoreIcon />}
        >
          <Box
            borderRadius={2}
            display='flex'
            flexDirection='row'
            width='100%'
            height={40}
            px={1}
          >
            <Box
              justifyContent='flex-start'
              display='flex'
              flexGrow={1}
              alignItems='center'
            >
              <Typography
                variant='subtitle1'
                style={{
                  color: 'black',
                  fontSize: '13px',
                  fontWeight: 'semibold',
                }}
              >
                Cardiovascular System
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <CVSExaminationsListForEncounter
            encounterId={encounterId}
            patient={patient}
            purposeOfUseType={purposeOfUseType}
            isReadOnly={false}
          />
        </AccordionDetails>
      </Accordion>
    )
  }
