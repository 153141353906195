import { R4 } from '@ahryman40k/ts-fhir-types'

export const SuperficialReflexObservationType: R4.ICoding[] = [
  {
    system: 'http://snomed.info/sct',
    code: '112219002',
    display: 'Corneal reflex brisk',
  },
  {
    system: 'http://snomed.info/sct',
    code: '299810004',
    display: 'Corneal reflex normal',
  },
  {
    system: 'http://snomed.info/sct',
    code: '78710008',
    display: 'Corneal reflex absent',
  },
  {
    system: 'http://snomed.info/sct',
    code: '112220008',
    display: 'Abnormal corneal reflex',
  },
  {
    system: 'http://snomed.info/sct',
    code: '299854005',
    display: 'Abdominal reflex brisk',
  },
  {
    system: 'http://snomed.info/sct',
    code: '274606006',
    display: 'Abdominal reflex absent',
  },
  {
    system: 'http://snomed.info/sct',
    code: '299853004',
    display: 'Abdominal reflex normal',
  },
  {
    system: 'http://snomed.info/sct',
    code: '419751003',
    display: 'Abdominal reflex unequal',
  },
  {
    system: 'http://snomed.info/sct',
    code: '299852009',
    display: 'Abdominal reflex reduced',
  },
  {
    system: 'http://snomed.info/sct',
    code: '299852009',
    display: 'Abdominal reflex reduced',
  },
  {
    system: 'http://snomed.info/sct',
    code: '274608007',
    display: 'Cremasteric reflex positive',
  },
  {
    system: 'http://snomed.info/sct',
    code: '274607002',
    display: 'Cremasteric reflex negative',
  },
  {
    system: 'http://snomed.info/sct',
    code: '130978009',
    display: 'Decreased cremasteric reflex',
  },
  {
    system: 'http://snomed.info/sct',
    code: '46670006',
    display: 'Abnormal Plantar Reflex',
  },
  {
    system: 'http://snomed.info/sct',
    code: '91365008',
    display: 'Plantar reflex normal',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-superficial-reflex-finding-cs',
    code: 'other-superficial-reflex-finding',
    display: 'Other',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
