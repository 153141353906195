import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { LabReferralService } from 'models/labOfferDetail'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { addAndGetEncounterDetailsOfAppointment } from 'utils/appointment_handle/cds_recommendations_util'
import { createBundleObjectForRequests } from 'utils/appointment_handle/medications_util'
import { getEncounterObjectForAppointment } from 'utils/fhirResoureHelpers/appointmentHelpers'
import {
  createBundleObjectForLabReferral,
  createBundleObjectForLabReferralForIPD,
  createBundleObjectForLabReferralForNoReferral,
  createBundleObjectForLabReferralForNoReferralForIPD,
} from 'utils/fhirResoureHelpers/referralHelpers'
import { getLoincCodeFromPlanDef } from 'utils/patientHelper/cds_helper'
import { MedicationAddStatus as AddWellnessReferralsStatusTypes } from './addLabTestReferralsStatusTypesIPD'

const initialState: AddWellnessReferralsStatusTypes = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addLabTestReferralSliceIPD = createSlice({
  name: 'addLabTestReferralSliceIPD',
  initialState,
  reducers: {
    updateAddMedicationsStatus(
      state,
      action: PayloadAction<AddWellnessReferralsStatusTypes>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.allergy = action.payload.allergy
    },

    resetMedicationsDetails(
      state,
      action: PayloadAction<AddWellnessReferralsStatusTypes>
    ) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.allergy = initialState.allergy
    },
  },
})

export const addLabTestReferralDetailsForIPD =
  (
    appointment: FhirActiveIPDDetailsForMedicalRole,
    labReferrals: LabReferralService[],
    selectedPlans: R4.IPlanDefinition[],
    extraInfo?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AddWellnessReferralsStatusTypes = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(
      addLabTestReferralSliceIPD.actions.updateAddMedicationsStatus(addingState)
    )
    const workflowClient = new EnrolCient()

    const encounterData: R4.IEncounter | undefined = appointment.mainEncounter
    const bodyReferrals: any[] = []

    const plans: R4.IPlanDefinition[] = []
    for (let i = 0; i < selectedPlans.length; i++) {
      if (labReferrals.length > 0) {
        for (let j = 0; j < labReferrals.length; j++) {
          if (
            getLoincCodeFromPlanDef(labReferrals[j].planDefinition) !==
            getLoincCodeFromPlanDef(selectedPlans[i])
          )
            plans.push(selectedPlans[i])
        }
      } else {
        plans.push(selectedPlans[i])
      }
    }

    if (plans.length > 0) {
      const bundleObject: R4.IBundle =
        createBundleObjectForLabReferralForNoReferralForIPD(
          appointment,
          encounterData!,
          plans,
          ''
        )
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        bundleObject
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (labReferrals.length === 0) {
        if (relatedFhirDecodeRes._tag === 'Right') {
          if (relatedFhirDecodeRes.right) {
            addingState = {
              adding: false,
              additionSuccessful: true,
              error: false,
              errorMessage: '',
            }

            dispatch(
              addLabTestReferralSliceIPD.actions.updateAddMedicationsStatus(
                addingState
              )
            )
          } else {
            const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
              adding: false,
              additionSuccessful: false,
              error: true,
              errorMessage: 'Error while  details',
            }
            dispatch(
              addLabTestReferralSliceIPD.actions.updateAddMedicationsStatus(
                errorCreatePersonState
              )
            )
            return
          }
        } else {
          const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
            adding: false,
            additionSuccessful: false,
            error: true,
            errorMessage: 'Error while adding  details',
          }
          dispatch(
            addLabTestReferralSliceIPD.actions.updateAddMedicationsStatus(
              errorCreatePersonState
            )
          )
          return
        }
      }
    }

    try {
      if (labReferrals.length > 0) {
        for (let i = 0; i < labReferrals.length; i++) {
          const body = {
            referralServiceId: labReferrals[i].healthService.id,
            encounterId: encounterData ? encounterData.id! : '',
            labTests: [
              {
                system: 'http://loinc.org',
                code: getLoincCodeFromPlanDef(labReferrals[i].planDefinition),
                display: labReferrals[i].planDefinition.title ?? '',
              },
            ],
          }
          bodyReferrals.push(body)
        }
        const res = await workflowClient.doCreateEnrolmentFlowRequest(
          'referral/lab-referral',
          {
            patientId: appointment.patient.id,
            labReferralDetails: bodyReferrals,
          }
        )

        if (res[0].entry && res[0].entry.length > 0) {
          const bundleObject: R4.IBundle =
            createBundleObjectForLabReferralForIPD(
              appointment,
              encounterData!,

              labReferrals,
              selectedPlans
            )
          console.log(bundleObject)

          const fhirClient: FHIRApiClient = new FHIRApiClient()
          const response = await fhirClient.doCreateFHIRTransaction(
            '',
            bundleObject
          )
          const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
            R4.RTTI_Bundle.decode(response)

          if (relatedFhirDecodeRes._tag === 'Right') {
            if (relatedFhirDecodeRes.right) {
              addingState = {
                adding: false,
                additionSuccessful: true,
                error: false,
                errorMessage: '',
              }
              dispatch(
                addLabTestReferralSliceIPD.actions.updateAddMedicationsStatus(
                  addingState
                )
              )
            } else {
              const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
                adding: false,
                additionSuccessful: false,
                error: true,
                errorMessage: 'Error while referral details',
              }
              dispatch(
                addLabTestReferralSliceIPD.actions.updateAddMedicationsStatus(
                  errorCreatePersonState
                )
              )
              return
            }
          } else {
            const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
              adding: false,
              additionSuccessful: false,
              error: true,
              errorMessage: 'Error while adding referral details',
            }
            dispatch(
              addLabTestReferralSliceIPD.actions.updateAddMedicationsStatus(
                errorCreatePersonState
              )
            )
            return
          }
        } else {
          const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
            adding: false,
            additionSuccessful: false,
            error: true,
            errorMessage:
              'Error while creating referral details in other organizationß',
          }
          dispatch(
            addLabTestReferralSliceIPD.actions.updateAddMedicationsStatus(
              errorCreatePersonState
            )
          )
          return
        }
      }
    } catch (error) {
      const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding',
      }
      dispatch(
        addLabTestReferralSliceIPD.actions.updateAddMedicationsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const addCDSMedicationsDetails =
  (
    appointment: FhirAppointmentDetail,
    medications: R4.IMedicationRequest[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AddWellnessReferralsStatusTypes = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(
      addLabTestReferralSliceIPD.actions.updateAddMedicationsStatus(addingState)
    )
    const encounter: R4.IEncounter =
      getEncounterObjectForAppointment(appointment)

    try {
      const bundleObject: R4.IBundle = createBundleObjectForRequests(
        appointment,
        encounter,
        medications
      )

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        bundleObject
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)

      if (relatedFhirDecodeRes._tag === 'Right') {
        if (relatedFhirDecodeRes.right) {
          addingState = {
            adding: false,
            additionSuccessful: true,

            error: false,
            errorMessage: '',
          }
          dispatch(
            addLabTestReferralSliceIPD.actions.updateAddMedicationsStatus(
              addingState
            )
          )
          dispatch(showSuccessAlert('Medications added successfully'))
        } else {
          const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
            adding: false,
            additionSuccessful: false,
            error: true,
            errorMessage: 'Error while allergy details',
          }
          dispatch(
            addLabTestReferralSliceIPD.actions.updateAddMedicationsStatus(
              errorCreatePersonState
            )
          )
          return
        }
      } else {
        const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while adding medications',
        }
        dispatch(
          addLabTestReferralSliceIPD.actions.updateAddMedicationsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding',
      }
      dispatch(
        addLabTestReferralSliceIPD.actions.updateAddMedicationsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetAddTestReferralsState = () => (dispatch: AppDispatch) => {
  dispatch(
    addLabTestReferralSliceIPD.actions.resetMedicationsDetails(initialState)
  )
}

export default addLabTestReferralSliceIPD.reducer
