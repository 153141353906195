import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import { getExpandedAppointmentFromBundle } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { logger } from 'utils/logger'
import { AppointmentSearchStatus as FollowUpAppointmentSearchStatus } from './followUpAppointmentSearchStatus'

const initialState: FollowUpAppointmentSearchStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
}

const followUpAppointmentSearchSlice = createSlice({
  name: 'followUpAppointmentSearchSlice',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<FollowUpAppointmentSearchStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableAppointments = action.payload.availableAppointments
      state.tasks = action.payload.tasks
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const fetchFollowUpAppointments =
  (currentAppointment: FhirAppointmentDetail, followUp?: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: FollowUpAppointmentSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(followUpAppointmentSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      let searchParameters: any = {
        '_include:iterate': 'Appointment:actor',
        _include: 'Appointment:slot',
        'supporting-info':
          `Appointment/${currentAppointment.appointment.id}` ?? '',
        'status:': 'booked',
      }
      if (
        followUp &&
        getAppointmentIdForFollowUp(
          currentAppointment.appointment.supportingInformation ?? []
        ).length > 0
      ) {
        searchParameters = {
          '_include:iterate': 'Appointment:actor',
          _include: 'Appointment:slot',
          'supporting-info': `Appointment/${getAppointmentIdForFollowUp(
            currentAppointment.appointment.supportingInformation ?? []
          )}`,
          'status:': 'booked',
        }
      }

      let taskSearchParameters: any = {
        'focus:ActivityDefinition.title': 'Task Doctor Appointment',

        encounter: `Encounter/${currentAppointment.encounter?.id}` ?? '',
        'status:': 'requested',
      }

      if (
        followUp &&
        getAppointmentIdForFollowUp(
          currentAppointment.appointment.supportingInformation ?? []
        ).length > 0
      ) {
        taskSearchParameters = {
          'focus:ActivityDefinition.title': 'Task Doctor Appointment',

          encounter: `Encounter/${getAppointmentIdForFollowUp(
            currentAppointment.appointment.supportingInformation ?? []
          )}`,
          'status:': 'requested',
        }
      }
      const response: any = await fhirClient.doGetResource(
        '/Appointment',
        searchParameters
      )

      const taskResponse: any = await fhirClient.doGetResource(
        '/Task',
        taskSearchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      const taskResp: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(taskResponse)
      if (resp || taskResp) {
        if (
          (response.total && response?.total > 0) ||
          (taskResponse.total && taskResponse?.total > 0)
        ) {
          let task: R4.ITask[] = []
          if (taskResp._tag === 'Right') {
            task = (taskResp.right.entry ?? []).map(
              (e) => e.resource as R4.ITask
            )
          }
          const fhirAppointments: FhirAppointmentDetail[] =
            getExpandedAppointmentFromBundle(response)
          fhirAppointments.sort((a, b) => moment(a.start).diff(b.start))
          state.resultsAvailable = true
          state.searchingAppointments = false
          state.availableAppointments = fhirAppointments
          state.noResultsAvailable = false
          state.tasks = task
          state.errorReason = undefined
          state.errorWhileSearchingAppointments = false
          dispatch(followUpAppointmentSearchSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: FollowUpAppointmentSearchStatus = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            followUpAppointmentSearchSlice.actions.updatedStatus(
              errorSearchDoctor
            )
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: FollowUpAppointmentSearchStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
      }
      dispatch(
        followUpAppointmentSearchSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export default followUpAppointmentSearchSlice.reducer
