import { R4 } from '@ahryman40k/ts-fhir-types'
import { LabCodes } from '../../models/labCode'

export const LabTest: R4.ICoding[] = [
  {
    system: 'http://loinc.org',
    code: '2823-3',
    display: 'Lipid Panel',
  },

  {
    system: 'http://loinc.org',
    code: '2823-4',
    display: 'Calprotectin',
  },
]

export const LabCodeValueSet: LabCodes[] = [
  {
    system: 'http://loinc.org',
    code: '2093-3',
    display: 'Cholesterol, Total',
  },

  {
    system: 'http://loinc.org',
    code: '2571-8',
    display: 'Triglycerides',
  },
  {
    system: 'http://loinc.org',
    code: '2085-9',
    display: 'HDL Cholesterol',
  },

  {
    system: 'http://loinc.org',
    code: '13458-5',
    display: 'VLDL Cholesterol Cal',
  },
  {
    system: 'http://loinc.org',
    code: '13457-7',
    display: 'LDL Chol Calc (NIH)',
  },
]
