// bpa pour bodypart antérieure
export const bpa = {
  tete: 'Head (ant.)',
  couG: 'Neck (G)',
  couD: 'Neck (D)',
  trapG: 'Left trapezius',
  trapD: 'Right trapezius',

  epG: 'Left shoulder (ant.)',
  epD: 'Right shoulder  (ant.)',
  biG: 'Left Biceps',
  biD: 'Right Biceps',
  coudG: 'Left Elbow (ant.)',
  coudD: 'Right Elbow (ant.)',
  avbG: 'Left forearm (ant.) (flexors)',
  avbD: 'Right forearm (ant.) (flexors)',
  poiG: 'Left wrist (ant.)',
  poiD: 'Right wrist (ant.)',
  mainG: 'Left hand (ant.) (palmar side)',
  mainD: 'Right hand (ant.) (palmar side)',

  pecG: 'Left pectoral',
  pecD: 'Right pectoral',
  cotG: 'Left ribs',
  cotD: 'Right ribs',
  abdG: 'Left abdominals',
  abdD: 'Right abdominals',
  oblG: 'Left obliques',
  oblD: 'Right obliques',

  hanG: 'Left hip',
  hanD: 'Right hip',
  quadG: 'Left Quadriceps',
  quadD: 'Right Quadriceps',
  addG: 'Left adductor',
  addD: 'Right addict',
  genG: 'Left knee (ant.)',
  genD: 'Right knee (ant.)',
  tibG: 'Left tibia',
  tibD: 'Right tibia',
  cheG: 'Left ankle(ant.)',
  cheD: 'Right ankle (ant.)',
  pieG: 'Left foot',
  pieD: 'Right foot',
}

export const bpp = {
  tete: 'Head (post.)',
  trapG: 'Left trapezius',
  trapD: 'Right trapezius',

  epG: 'Left shoulder (post.)',
  epD: 'Right shoulder (post.)',
  triG: 'Left triceps',
  triD: 'Right triceps',
  couG: 'Left Elbow(post.)',
  couD: 'Right Elbow (post.)',
  avbG: 'Left forearm (post.) (extensors)',
  avbD: 'Right forearm (post.) (extensors)',
  poiG: 'Left wrist (post.)',
  poiD: 'Right wrist (post.)',
  mainG: 'Left hand (post.) (do dorsale)',
  mainD: 'Main droit (post.) (do dorsale)',

  col: 'Spine',
  scaG: 'Left scapular region',
  scaD: 'Right scapular region',
  dosG: 'Back (left flank)',
  dosD: 'Back (right flank)',
  lombG: 'Left lumbar',
  lombD: 'Right lumbar',

  fesG: 'Left buttock',
  fesD: 'Right buttock',
  iscG: 'Left -handed ischios',
  iscD: 'Right ischios',
  genG: 'Left knee (post.)',
  genD: 'Right knee (post.)',
  molG: 'Left calf',
  molD: 'Right calf',
  cheG: 'Left ankle (post.)',
  cheD: 'Right ankle (post.)',
  pieG: 'Left foot (post.)',
  pieD: 'Right foot (post.)',
}
