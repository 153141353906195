import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  getCurrentUserOrgDetails,
  resetOrgCompletionState,
} from 'redux/administration/orgSetUp/orgProfileCompletionSlice/orgProfileCompletionSlice'
import {
  getCurrentUserUnitsDetails,
  resetUnitCompletionState,
} from 'redux/administration/unitSetup/unitProfileCompletionSlice/unitProfileCompletionSlice'
import { resetUserCompletionState } from 'redux/administration/userSetup/userProfileCompletionSlice/userProfileCompletionSlice'
import { fetchPendingTasksDetailsOfCurrentUser } from 'redux/pendingTaskChecker/pendingTasksCheckerSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserUnitDetails,
  isOrgUser,
  isUnitUser,
  isWpOrgUser,
} from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { getAdjustedPath } from 'utils/routes_helper'
import { WelloLoadingIndicator } from 'wello-web-components'
import { FinishOrgUserAccountSetUp } from '../administration/actors/finish_org_user_setup'
import { FinishUnitAccountSetUp } from '../administration/actors/finish_unit_actor_setup'
import { FinishOrgDetailsSetUp } from '../administration/organization/org_profile_competion'
import { FinishUnitDetailsSetUp } from '../administration/units/unit_profile_competion'

export interface IUnitAdmin {
  children?: React.ReactNode
}

export const PendingTaskChecker: React.FC = () => {
  const pendingTasksCheckerSlice = useSelector(
    (state: RootState) => state.pendingTasksCheckerSlice
  )

  const history = useHistory()
  const dispatch = useDispatch()
  const [showUnitDetails, setShowUnitDetails] = useState<boolean>(false)
  const [showOrgDetails, setShowOrgDetails] = useState<boolean>(false)
  const [showActorDetailsAddition, setShowActorDetailsAddition] =
    useState<boolean>(false)
  const [showOrgActorDetailsAddition, setShowOrgActorDetailsAddition] =
    useState<boolean>(false)
  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<R4.IOrganization>(getCurrentUserUnitDetails())
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      currentOrganizationDetails.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  useEffect(() => {
    dispatch(getCurrentUserUnitsDetails())
    dispatch(getCurrentUserOrgDetails())
  }, [])

  useEffect(() => {
    logger.info('taskList ', pendingTasksCheckerSlice.tasksList)
    if (
      pendingTasksCheckerSlice.resultsAvailable &&
      pendingTasksCheckerSlice.tasksList &&
      pendingTasksCheckerSlice.tasksList?.length > 0
    ) {
      logger.info('taskList ', pendingTasksCheckerSlice.tasksList)
      const taskCode: string = pendingTasksCheckerSlice.tasksList?.[0] ?? ''

      if (
        pendingTasksCheckerSlice.tasksList &&
        pendingTasksCheckerSlice.tasksList.length > 0
      ) {
        switch (taskCode) {
          case 'unit-profile-completion':
            setShowUnitDetails(true)
            break
          case 'org-profile-completion':
            setShowOrgDetails(true)
            break
          case 'profile-completion': {
            logger.info(getCurrentUserUnitDetails())
            // setShowOrgActorDetailsAddition(true)
            if (isOrgUser() || isWpOrgUser()) {
              logger.info('profile-completion Org admin')
              setShowOrgActorDetailsAddition(true)
              logger.info('profile-completion Org admin++++++++++')
              break
            }
            if (isUnitUser()) {
              logger.info('profile-completion Unit admin')
              setShowActorDetailsAddition(true)
              break
            }

            break
          }

          default:
            break
        }
      }
    }
  }, [
    pendingTasksCheckerSlice.resultsAvailable,
    /*  ,
        pendingTasksCheckerSlice.tasksList,
        pendingTasksCheckerSlice.totalCount, */
  ])

  useEffect(() => {
    if (pendingTasksCheckerSlice.noResultsAvailable) {
      window.location.href = `${getAdjustedPath('/dashboard')}`
    }
  }, [pendingTasksCheckerSlice.noResultsAvailable])

  return (
    <Box display='flex' flexDirection='column'>
      <Box>
        {pendingTasksCheckerSlice.searching && (
          <WelloLoadingIndicator message='Searching' />
        )}
        {pendingTasksCheckerSlice.resultsAvailable &&
          pendingTasksCheckerSlice.tasksList && (
            <Box display='flex' flexDirection='column' />
            /*  <Typography>
                            {pendingTasksCheckerSlice.tasksList.length} "Pending tasks"
                          </Typography>
                        </Box> */
          )}
        {/*    {pendingTasksCheckerSlice.noResultsAvailable && <Dashboard />} */}

        {pendingTasksCheckerSlice.error && (
          <Typography>
            {' '}
            {pendingTasksCheckerSlice.errorMessage ??
              'Error while searching Units . Pleas try again'}
          </Typography>
        )}
      </Box>
      <FinishOrgUserAccountSetUp
        open={showOrgActorDetailsAddition}
        onBackClick={() => {}}
        onContinueClick={() => {
          setShowActorDetailsAddition(false)
          dispatch(resetUserCompletionState())
          dispatch(fetchPendingTasksDetailsOfCurrentUser())
        }}
        task={pendingTasksCheckerSlice.tasksResources?.find(
          (e) => e.code?.coding?.[0]?.code === 'profile-completion'
        )}
      />
      <FinishUnitAccountSetUp
        open={showActorDetailsAddition}
        onBackClick={() => {}}
        onContinueClick={() => {
          setShowActorDetailsAddition(false)
          dispatch(resetUserCompletionState())
          dispatch(fetchPendingTasksDetailsOfCurrentUser())
        }}
        task={pendingTasksCheckerSlice.tasksResources?.find(
          (e) => e.code?.coding?.[0]?.code === 'profile-completion'
        )}
      />
      <FinishUnitDetailsSetUp
        open={showUnitDetails}
        onBackClick={() => {
          // setShowUnitDetails(false)
        }}
        onContinueClick={() => {
          setShowUnitDetails(false)
          dispatch(resetUnitCompletionState())
          dispatch(fetchPendingTasksDetailsOfCurrentUser())
        }}
        task={pendingTasksCheckerSlice.tasksResources?.find(
          (e) => e.code?.coding?.[0]?.code === 'unit-profile-completion'
        )}
      />
      <FinishOrgDetailsSetUp
        open={showOrgDetails}
        onBackClick={() => {
          // setShowUnitDetails(false)
        }}
        onContinueClick={() => {
          setShowOrgDetails(false)
          dispatch(resetOrgCompletionState())
          dispatch(fetchPendingTasksDetailsOfCurrentUser())
        }}
        task={pendingTasksCheckerSlice.tasksResources?.find(
          (e) => e.code?.coding?.[0]?.code === 'org-profile-completion'
        )}
      />
    </Box>
  )
}
