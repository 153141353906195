import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getNavOptionsId } from 'utils/navHelpers/navHelpers'
import { SoapNavSelectionStatus } from './soapPageNavigationHandlerTypes'

const initialState: SoapNavSelectionStatus = {
  selectedSection: getNavOptionsId()[0],
}

const soapNavSelectionSlice = createSlice({
  name: 'soapNavSelectionSlice',
  initialState,
  reducers: {
    setSelectedSection: (
      state,
      action: PayloadAction<SoapNavSelectionStatus>
    ) => {
      state.selectedSection = action.payload.selectedSection
    },
  },
})
export const { setSelectedSection } = soapNavSelectionSlice.actions

export default soapNavSelectionSlice.reducer
