import { R4 } from '@ahryman40k/ts-fhir-types'

export const IdProofType: R4.ICoding[] = [
  {
    system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
    code: 'PPN',
    display: 'Passport',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
    code: 'DL',
    display: 'Driving Licence',
  },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/id-prrof-type',
    code: 'aadhaar',
    display: 'Aadhaar Card',
  },

  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'VID',
    display: 'Voter ID',
  },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/id-prrof-type',
    code: 'pan-card',
    display: 'PAN Card Number',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'AL',
    display: 'Arms License',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'RCN',
    display: 'Ration Card Number',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'PSC',
    display: 'Pensioners Card',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'FF',
    display: 'Freedom Fighter Card having photo',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'KP',
    display: 'Kissan Passbook',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'SLR',
    display: 'Sri Lankan Refugees Identity Card',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'CGHS',
    display: 'Central Government Health Scheme (CGHS) ID',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'ECHS',
    display: 'Ex-servicemen Contributory Health Scheme (ECHS) ID',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'CCF',
    display: 'Caste Certificate',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'DCF',
    display: 'Domicile Certificate',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const AddressProofType: R4.ICoding[] = [
  {
    system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
    code: 'PPN',
    display: 'Passport',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
    code: 'DL',
    display: 'Driving Licence',
  },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/id-prrof-type',
    code: 'aadhaar',
    display: 'Aadhaar Card',
  },

  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'VID',
    display: 'Voter ID',
  },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/id-prrof-type',
    code: 'pan-card',
    display: 'PAN Card Number',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'AL',
    display: 'Arms License',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'RCN',
    display: 'Ration Card Number',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'PSC',
    display: 'Pensioners Card',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'FF',
    display: 'Freedom Fighter Card having photo',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'KP',
    display: 'Kissan Passbook',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'SLR',
    display: 'Sri Lankan Refugees Identity Card',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'CGHS',
    display: 'Central Government Health Scheme (CGHS) ID',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'ECHS',
    display: 'Ex-servicemen Contributory Health Scheme (ECHS) ID',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'CCF',
    display: 'Caste Certificate',
  },
  {
    system:
      'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    code: 'DCF',
    display: 'Domicile Certificate',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const AddressProofTypeForForeign: R4.ICoding[] = [
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/address-prrof-type',
    code: 'passport',
    display: 'Passport',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
