import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { getExpandedAppointmentFromBundle } from 'utils/common/patientDataTableHelper'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import { UmlClient } from 'services/umlsClient'
import { LocationSearchStatus } from './locationSearchStatusTypes'

const initialState: LocationSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const locationSearchSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    searchingLocations(
      state,
      action: PayloadAction<LocationSearchStatus>
    ) {},

    searchResults(state, action: PayloadAction<LocationSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.locationList =
        action.payload.locationList
    },

    noDataFoundForSearch(state, action: PayloadAction<LocationSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.locationList =
        action.payload.locationList
    },

    errorWhileSearching(state, action: PayloadAction<LocationSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.locationList =
        action.payload.locationList
    },
    resetState(state, action: PayloadAction<LocationSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.locationList = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: LocationSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      locationList: undefined,
    }
    dispatch(locationSearchSlice.actions.resetState(state))
  }

export const searchLocations =
  (searchString:string): AppThunk =>
  async (dispatch: any) => {
    const errorSearchPatient: LocationSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(locationSearchSlice.actions.errorWhileSearching(errorSearchPatient))
    try {   
      const fhirClient: UmlClient = new UmlClient()
      const response: any = await fhirClient.doGetResource(
        `umls/Search?Path=location&Match=${searchString}`
      )
              if(response.length >0)
              {
                const searchPatientResult: LocationSearchStatus = {
                    error: false,
                    noResultsAvailable: false,
                    resultsAvailable: true,
                    searching: false,
                    locationList: response,
                    totalCount: response.total,
                  }
                  dispatch(
                    locationSearchSlice.actions.searchResults(searchPatientResult)
                  )
                  return
              }
              
                const noSearchResults: LocationSearchStatus = {
                    error: false,
                    noResultsAvailable: true,
                    resultsAvailable: false,
                    searching: false,
                  }
                  dispatch(
                    locationSearchSlice.actions.noDataFoundForSearch(noSearchResults)
                  )
                  return
              
          
        
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: LocationSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        locationSearchSlice.actions.errorWhileSearching(errorWhileSearchPatient)
      )
    }
  }

  export const resetState = () => (dispatch: AppDispatch) => {
    dispatch(locationSearchSlice.actions.resetState(initialState))
  }



export default locationSearchSlice.reducer

