import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource } from 'axios'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { DateWiseIPDAppointments } from 'models/dateSeparatedIPDAppointments'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import { PreAppointmentDetails } from 'models/preAppointmentDetails'
import moment from 'moment'
import { IpdAppointmentSearchStatus } from 'redux/clinic/ipdmanagement/ipdSearch/ipdAppointmentSearchStatus'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { cancelTokenStore } from 'services/fhirApiServices'
import {
  getCurrentUserUnitReference,
  isTherapist,
} from 'services/userDetailsService'
import {
  getFullNameOfPatient,
  getLastNameOfPatient,
  getNameOfPatient,
} from 'utils/fhirResourcesHelper'
import { getExpandedPreAppointmentDetailsFromResponse } from 'utils/fhirResoureHelpers/preAppointmentHelper'
import { logger } from 'utils/logger'
import { PreAppointmentSearchStatus } from './preAppointmentSearchStatus'

const initialState: PreAppointmentSearchStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingOrders: false,
}

let currentSelectedDate: Date | undefined

const preAppointmentSearchSlice = createSlice({
  name: 'preAppointmentSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<PreAppointmentSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableAppointments = action.payload.availableAppointments
      state.errorWhileSearchingOrders = action.payload.errorWhileSearchingOrders
    },
  },
})

export const requestForDateWisePreAppointmentLists =
  (name: string, selectedDate?: Date): AppThunk =>
  async (dispatch: AppDispatch) => {
    currentSelectedDate = selectedDate

    const state: PreAppointmentSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(preAppointmentSearchSlice.actions.updatedStatus(state))
    try {
      currentSelectedDate?.setSeconds(new Date().getSeconds())
      const enRolClient: EnrolCient = new EnrolCient()

      const searchParams: any = {
        nameFilter: name,
        checkInDate: moment(moment(new Date()).format('YYYY-MM-DD'))
          .add(1, 'days')
          .utc()
          .format()
          .slice(0, 10)
          .concat('T01:30:00.000Z'),
      }

      if (selectedDate) {
        const date = `${moment(moment(selectedDate).format('YYYY-MM-DD'))
          .add(1, 'days')
          .utc()
          .format()}`
        const newDate = date.slice(0, 10)
        const newDateConcatenated = newDate.concat('T01:30:00.000Z')

        searchParams.checkInDate = newDateConcatenated
      }

      axios.CancelToken.source()
      if (cancelTokenStore.has('ipdMainSearchControlToken')) {
        const controlTokenForSearch: CancelTokenSource = cancelTokenStore.get(
          'ipdMainSearchControlToken'
        )
        controlTokenForSearch.cancel('new param added')
        cancelTokenStore.delete('ipdMainSearchControlToken')
      }
      cancelTokenStore.set(
        'ipdMainSearchControlToken',
        axios.CancelToken.source()
      )

      const response: any = await enRolClient.doGetResource(
        `enrolment/pre-appt/patientForm`,
        searchParams,
        (cancelTokenStore.get('ipdMainSearchControlToken') as CancelTokenSource)
          .token
      )

      const entries: PreAppointmentDetails[] = []

      response.map((e: any) => {
        entries.push({
          patientId: e.id,
          patientPhone: e.phone,
          patientResource: e.patient,
          patientCheckInDate: e.checkinDate,
          patientName: getNameOfPatient(e.patient).concat(
            ' ',
            getLastNameOfPatient(e.patient)
          ),
          relatedPersonResource: e.relatedPerson,
        })
      })

      if (entries.length > 0) {
        state.resultsAvailable = true
        state.searchingAppointments = false
        state.availableAppointments = entries

        state.noResultsAvailable = false
        state.errorReason = undefined
        state.errorWhileSearchingOrders = false
        dispatch(preAppointmentSearchSlice.actions.updatedStatus(state))
      } else {
        console.error('---------error ---- ------------')
        const errorSearchDoctor: PreAppointmentSearchStatus = {
          searchingAppointments: false,
          errorWhileSearchingOrders: false,
          resultsAvailable: false,
          noResultsAvailable: true,
        }
        dispatch(
          preAppointmentSearchSlice.actions.updatedStatus(errorSearchDoctor)
        )
      }
    } catch (error) {
      console.error('---------error------------')
      console.error(error)
    }
  }

export default preAppointmentSearchSlice.reducer
