import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import { logger } from 'utils/logger'
import { OPDCountStatus } from './opdCountStatus'

const initialState: OPDCountStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
  count: 0,
}

const opdCount = createSlice({
  name: 'opdCount',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<OPDCountStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.count = action.payload.count
      state.errorReason = action.payload.errorReason

      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const requestOPDAppCountForToday =
  (selectedDate?: Date): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: OPDCountStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      count: 0,
    }
    dispatch(opdCount.actions.updatedStatus(state))
    const unitId = getCurrentUserUnitReference()?.reference?.split('/')[1]
    try {
      const enRolClient: EnrolCient = new EnrolCient()

      const date = `${moment
        .utc(new Date())
        .subtract(150, 'days')
        .local()
        .format('YYYY-MM-DD')}`
      const endDate = moment(date).add(150, 'days')
      const apiDate = `${moment.utc(endDate).local().format('YYYY-MM-DD')}`

      const searchParams: any = {
        perPageCount: 0,
        status: 'active',
        unitId,
        type: 'opd-daycare',
      }
      if (selectedDate) {
        const tempDate = `${moment
          .utc(
            selectedDate || moment.utc(new Date()).subtract(30, 'days').toDate()
          )
          .local()
          .format('YYYY-MM-DD')}`
        searchParams.startDate = tempDate
      }

      const response: any = await enRolClient.doGetResource(`ipd`, searchParams)

      const resp: R4.IBundle = response as R4.IBundle
      if (!resp) {
        state.errorWhileSearchingAppointments = true
        state.searchingAppointments = false

        dispatch(opdCount.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          state.resultsAvailable = true
          state.searchingAppointments = false
          state.count = appointmentResponse.total
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingAppointments = false
          dispatch(opdCount.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: OPDCountStatus = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            count: 0,
          }
          dispatch(opdCount.actions.updatedStatus(errorSearchDoctor))
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: OPDCountStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
        count: 0,
      }
      dispatch(opdCount.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default opdCount.reducer
