import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetOrgCompletionState,
  updateOrgBasicDetails,
} from 'redux/administration/orgSetUp/orgProfileCompletionSlice/orgProfileCompletionSlice'
import { RootState } from 'redux/rootReducer'
import {
  getAddress,
  getTelecomFromContactPoints,
  getValueAttachmentFromExtensionData,
  getValueAttachmentFromExtensionDataForLogo,
} from 'utils/fhirResourcesHelper'
import { isValidCodeableConcent } from 'utils/fhirResoureHelpers/unit_setup_helpers'
import { addressValidate } from 'utils/formValidators'
import { logger } from 'utils/logger'
import { validateEmail, validateMobileNumber } from 'wello-web-components'
import { AddOrgPrimaryDetailsSetUp } from './add_org_primary_details'

interface Props {
  open: boolean
  onBackClick: () => void
  onContinueClick: () => void
  task?: R4.ITask
}

export const FinishOrgDetailsSetUp: React.FC<Props> = ({
  open,
  onBackClick: onClose,
  onContinueClick,
  task,
}) => {
  const orgProfileCompletionSlice = useSelector(
    (state: RootState) => state.orgProfileCompletionSlice
  )
  const orgResource: R4.IOrganization = {
    ...orgProfileCompletionSlice.organizationDetails,
  }

  const location: R4.ILocation = {
    resourceType: 'Location',
    status: R4.LocationStatusKind._active,
    name: orgResource.name ?? '',
    mode: R4.LocationModeKind._instance,
    managingOrganization: {
      reference: `Organization/${orgResource.id}`,
    },
  }

  const [basicDetailsFormsErrors, setBasicDetailsFormsErrors] =
    useState<boolean>(false)

  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    if (orgProfileCompletionSlice.updatedBasicDetails) {
      dispatch(resetOrgCompletionState())
      onContinueClick()
    }
  }, [orgProfileCompletionSlice.updatedBasicDetails, onContinueClick, dispatch])

  function validateFormEntires() {
    let res: boolean = true

    if (
      getValueAttachmentFromExtensionData(
        orgResource.extension,
        'http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo'
      ) === undefined
    ) {
      res = false
    }

    if (
      getValueAttachmentFromExtensionDataForLogo(
        orgResource.extension,
        'http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo'
      ) > 5
    ) {
      res = false
    }

    if (
      getAddress(orgResource.address) === undefined ||
      getAddress(orgResource.address).length === 0
    ) {
      if (
        addressValidate(getAddress(orgResource.address ?? []) ?? '', true)
          .length > 0
      ) {
        res = false
      }
    }
    if (orgResource.contact) {
      orgResource.contact.forEach((element) => {
        if (!isValidCodeableConcent(element.purpose)) {
          res = false
        }
        if (
          validateMobileNumber(
            getTelecomFromContactPoints(
              element.telecom ?? [],
              R4.ContactPointSystemKind._phone
            ) ?? '',
            true
          ).length > 0
        ) {
          res = false
        }
        if (
          validateEmail(
            getTelecomFromContactPoints(
              element.telecom ?? [],
              R4.ContactPointSystemKind._email
            ) ?? '',
            true
          ).length > 0
        ) {
          res = false
        }
      })
    } else {
      return false
    }
    return res
  }

  function validateBasicDetails() {
    console.log(location)
    const res: Boolean = validateFormEntires()

    if (res) {
      logger.info('No Error')
      logger.info('Org Details', orgResource)
      logger.info('Org Details', location)

      dispatch(updateOrgBasicDetails(orgResource, location, task))
      //     /* updatePractitionerRoleDetails(practitioner, practitionerRole, task) */
      //   //   )
    } else {
      logger.info('Error in form')
    }
    setBasicDetailsFormsErrors(!res)
  }

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>
        Tell Us About Your Organization
      </DialogTitle>
      <DialogContent dividers={true}>
        <AddOrgPrimaryDetailsSetUp
          organizationDetails={orgResource}
          hasError={basicDetailsFormsErrors}
          latLng={location}
        />
      </DialogContent>
      <DialogActions>
        {orgProfileCompletionSlice.updatingBasicDetails && <CircularProgress />}

        {!orgProfileCompletionSlice.updatingBasicDetails && (
          <Box flex='display' flexDirection='row'>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                validateBasicDetails()
              }}
            >
              {t('labelCommon:continue')}
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  )
}
