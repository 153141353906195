/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-void */
import {
  Button,
  FormControl,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  Box,
  CircularProgress,
  Divider,
  TextField,
} from '@material-ui/core'
import { cloneDeep } from 'lodash'
import React, { useEffect, useState } from 'react'

import { useRecoilState, useRecoilValue } from 'recoil'

import { NumericFormat } from 'react-number-format'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { isAyurvedaDoctor, isOnlyDoctor } from 'services/userDetailsService'
import { CartOrderFor } from '../cart/cart-order-for'
import {
  cartOrderForCachedState,
  cartPatientObjectState,
  cartState,
} from '../../../models/medicine-delivery/cart/cart-states'
import { getProductDetails } from '../../../utils/medicineHelper/product-apis'
import {
  ProductDetail,
  Sku,
} from '../../../models/medicine-delivery/product-models'
import { ProductSkus } from './product-skus'
import {
  appState,
  selectedProductIdState,
  viewState,
} from '../../../models/medicine-delivery/product-states'
import { CartItem } from '../../../models/medicine-delivery/cart/cart-models'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
)

interface Props {
  onAddToCartCallback?: (product: ProductDetail) => void
  onPatientCallBack?: (patient: R4.IPatient) => void
  doctorDetails: R4.IPractitioner
  isSoapNotes?: boolean
  medicationNames?: string[]
}

export const ProductDetails: React.FC<Props> = ({
  onAddToCartCallback,
  onPatientCallBack,
  doctorDetails,
  isSoapNotes,
  medicationNames,
}: Props) => {
  //   const selectedProductId = useRecoilValue(selectedProductIdState)
  const [selectedProductId, setSelectedProductId] = useRecoilState<any>(
    selectedProductIdState
  )
  const [cart, setCart] = useRecoilState<any>(cartState)
  const [detail, setDetail] = useState<ProductDetail | undefined>() // useRecoilState(selectedProductDetailsState);
  const [loading, setLoading] = useRecoilState<any>(appState)
  const [view, setView] = useRecoilState(viewState)
  const [quantity, setQuantity] = useState<any>(0)
  const [sku, setSku] = useState<Sku | undefined>()
  const [currentIndex, setCurrentIndex] = useState(0)

  const [patientId, setPatientId] = useState<string>()

  useEffect(() => {
    if (medicationNames) {
      setSelectedProductId(medicationNames[currentIndex])
    }
    if (!selectedProductId) {
      setDetail(undefined)
      return
    }
    void (async () => {
      setLoading({ state: 'processing', message: 'fetching product details' })
      const details = await getProductDetails(selectedProductId)
      setDetail(details)
      setQuantity(0)
      setSku(undefined)
      setView('productDetails')
      setLoading({
        state: 'completed',
        message: 'Product details fetched successfully',
      })
    })()
  }, [selectedProductId])

  if (view !== 'productDetails') {
    return <></>
  }

  const onAddToCart = (product: ProductDetail) => {
    setCurrentIndex(currentIndex + 1)
    if (!sku || !quantity) {
      alert('Please select and sku')
      return
    }
    const cartItem = {
      customerDiscount: 0,
      product: {
        category: cloneDeep(product.category),
        description: product.description,
        name: product.name,
        sku,
        title: product.title,
        image: product.image,
      },
      quantity,
    } as CartItem
    const clone = cloneDeep(cart)
    // Remove this this item from collection
    const filtered = clone.items.filter((i: any) => i.product.sku.id !== sku.id)
    clone.items = [...filtered, cartItem]
    setCart(clone)
    setSelectedProductId(undefined)
    onAddToCartCallback && onAddToCartCallback(product)
  }

  const renderDetail = () => {
    if (loading.state === 'processing') {
      return (
        <Box
          paddingLeft={3}
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='center'
        >
          <CircularProgress />
        </Box>
      )
    }

    if (!detail) {
      return (
        <Box width='100%' padding={0}>
          {isOnlyDoctor() && (
            <img
              src={`${process.env.PUBLIC_URL}/allopathy.png`}
              alt='ipd'
              height='90%'
            />
          )}

          {isAyurvedaDoctor() && (
            <img
              src={`${process.env.PUBLIC_URL}/emptyPage.png`}
              alt='ipd'
              height='90%'
            />
          )}
        </Box>
      )
    }

    return (
      <Box
        width='100%'
        height='70%'
        style={{ backgroundColor: 'white', overflow: 'hidden' }}
        display='flex'
        flexDirection='row'
        paddingLeft={1}
      >
        <Grid item xs={3}>
          <Box
            width='100%'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            paddingTop={10}
          >
            <Box
              width='80%'
              display='flex'
              flexDirection='row'
              justifyContent='flex-start'
              height='100%'
            >
              <Box
                width='100%'
                border={5}
                borderRadius={1}
                display='flex'
                flexDirection='row'
                justifyContent='center'
                borderColor='#ececec'
                p={1}
              >
                <img
                  src={detail.image}
                  width={200}
                  height={200}
                  alt='detail.title'
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid container item xs={8}>
          <Grid item xs={12}>
            <Box width='100%' paddingTop={4}>
              <Typography variant='subtitle1'>
                {detail.title.toUpperCase()}
              </Typography>
            </Box>
            <Typography variant='subtitle2'>{detail.category.title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box width='90%' display='flex' flexDirection='column'>
              <Box paddingTop={1} paddingBottom={1.5} width='100%'>
                <Divider />
              </Box>
              <Typography variant='body2'> {detail.description}</Typography>
              <Box paddingTop={1.5} paddingBottom={1.5} width='100%'>
                <Divider />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box width='90%'>
              <ProductSkus
                selectedSku={sku}
                detail={detail}
                onChangeSkuCallback={setSku}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box width='100%' display='flex' flexDirection='column' py={1}>
              <Box
                width='25%'
                border={2}
                borderRadius={1}
                display='flex'
                flexDirection='column'
                paddingRight={1}
                paddingLeft={1}
                paddingBottom={1}
                borderColor='#ececec'
              >
                <WelloFormItemLabel title='Quantity' />
                <TextField
                  size='small'
                  variant='outlined'
                  type='number'
                  placeholder='Quantity'
                  disabled={sku === undefined}
                  inputProps={{
                    step: '1',
                  }}
                  value={quantity}
                  onKeyPress={(e) => {
                    if (e.key === '.') {
                      e.preventDefault()
                    }
                  }}
                  onChange={(e) => {
                    const newValue = Number(e.target.value)
                    if (newValue <= 0) {
                      showErrorAlert('Quantity cannot be <= 0')
                    } else if (newValue > 0 && newValue <= 1000) {
                      setQuantity(Number(newValue ?? 0))
                    }
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box py={2}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => onAddToCart(detail)}
                disabled={!sku || quantity === 0}
              >
                Add to Cart
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <Box width='100%'>
      <Grid container>
        <Grid item xs={12}>
          <CartOrderFor
            patientId={patientId ?? ''}
            doctorId={doctorDetails ? doctorDetails.id ?? '' : ''}
            doctor={doctorDetails}
            onPatientCallBack={(patient: R4.IPatient) => {
              setPatientId(patient.id ?? '')
              if (onPatientCallBack) onPatientCallBack(patient)
            }}
            isSoapNotes={isSoapNotes}
          />
        </Grid>
        {renderDetail()}
      </Grid>
    </Box>
  )
}
