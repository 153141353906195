import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { searchingUnitForOrg } from 'redux/welloAdmin/unitDetailsForOrg/orgUnitDetailsSlice'
import SimpleBar from 'simplebar-react'
import { UnitDetailsDataTable } from './unitDetailsDataTable'

interface Props {
  id: string
  name: string
  open: boolean
  role: string
  status: string
  onClose: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogCustomizedWidth: {
    'max-width': '68%',
  },
}))
export const UnitDetailsForOrg: React.FC<Props> = ({
  id,
  name,
  open,
  status,
  onClose,
  role,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const orgUnitSearchSlice = useSelector(
    (state: RootState) => state.orgUnitSearchSlice
  )
  const classes = useStyles()

  function resetForm() {}

  useEffect(() => {
    if (role.length > 0) {
      dispatch(searchingUnitForOrg(id, role, status))
    }
  }, [dispatch, id, role])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetForm()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>{`${name} Details`}</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
                resetForm()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        <Box display='flex' flexGrow={1} flexDirection='column' width='100%'>
          {orgUnitSearchSlice.searching && (
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
            >
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            </Box>
          )}
          {orgUnitSearchSlice.error && (
            <Box
              //   display='flex'
              //   flexDirection='column'
              justifyContent='center'
              width='100%'
              height='100%'
              alignContent='center'
              paddingLeft={50}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Grid container justifyContent='center'>
                    <Typography
                      variant='subtitle1'
                      color='error'
                      align='center'
                    >
                      Error while searching. Please try again
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
          {/* {orgUnitSearchSlice.resultsAvailable && orgUnitSearchSlice.orgList && (
            <Box>
              <UserDetailsHeader />
            </Box>
          )} */}

          {orgUnitSearchSlice.resultsAvailable && orgUnitSearchSlice.unitList && (
            <SimpleBar
              style={{
                height: '100%',
                width: '100%',
              }}
            >
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height='100%'
              >
                <Box
                  display='flex'
                  flexGrow={1}
                  flexDirection='column'

                  //  paddingTop={0.2}
                >
                  {/* <OrgLIst /> */}

                  <UnitDetailsDataTable
                    orgData={orgUnitSearchSlice.unitList ?? []}
                  />
                </Box>
              </Box>
            </SimpleBar>
          )}
        </Box>
      </DialogContent>
      {/* <DialogActions>
        <Box paddingRight={2}>
          <Button
            onClick={() => {
              onClose()
            }}
            variant='outlined'
            disableElevation
          >
            Ok
          </Button>
        </Box>
      </DialogActions> */}
    </Dialog>
  )
}
