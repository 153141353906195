import { Box, CircularProgress, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestWeeklyDietPlanDetails } from 'redux/consultation/dietPlan/existingWeeklyDietPlanSearch/existingWeeklyDietPlanSearchSlice'
import { RootState } from 'redux/rootReducer'
import { getTableDataForWeekLongDietPlan } from 'utils/careplan_utils/weeklyDietPlanUtils'
import { DietPlanCardForEncounterView } from 'views/components/dietplan/dayDietPlanForEncounterView'
import { WeeklyDietPlanViewFromTasks } from 'views/components/dietplan/weeklyDietPlan/weeklyDietPlan'

interface Props {
  fhirAppointmentDetails: FhirAppointmentDetail
  onClick?: () => void
}

export const WeeklyDietPlanList: React.FC<Props> = ({
  fhirAppointmentDetails,
  onClick,
}: Props) => {
  const dispatch = useDispatch()

  const existingWeeklyDietPlanSearchSlice = useSelector(
    (state: RootState) => state.existingWeeklyDietPlanSearchSlice
  )

  useEffect(() => {
    if (fhirAppointmentDetails.encounter !== undefined)
      dispatch(
        requestWeeklyDietPlanDetails({
          patientId: fhirAppointmentDetails.patient.id!,
          encounterId: fhirAppointmentDetails.encounter.id,
          practitionerRoleId:
            fhirAppointmentDetails.practitionerDetail.practitionerRole.id!,
          showPlanSuggestedBySameDoctor: true,
        })
      )
  }, [dispatch, fhirAppointmentDetails])

  return (
    <Box display='flex' flexDirection='column'>
      {existingWeeklyDietPlanSearchSlice.searchingTreatment && (
        <CircularProgress size={15} />
      )}
      {/* {labDiagnosticsListSlice.noResultsAvailable && (
        <Box marginY={1} display='flex' flexDirection='column' width='100%'>
          <Typography variant='subtitle2'>&nbsp; No data available</Typography>
        </Box>
      )} */}
      {existingWeeklyDietPlanSearchSlice.resultsAvailable &&
        existingWeeklyDietPlanSearchSlice.dateWiseDietList &&
        existingWeeklyDietPlanSearchSlice.dateWiseDietList!.length > 0 && (
          <Box display='flex' width='100%' p={1}>
            <WeeklyDietPlanViewFromTasks
              tableData={getTableDataForWeekLongDietPlan(
                existingWeeklyDietPlanSearchSlice.dateWiseDietList
              )}
              displayRecordedDate={false}
            />
          </Box>
        )}
    </Box>
  )
}
