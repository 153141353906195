import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { showSuccessAlert, showErrorAlert } from 'redux/alertHandler/alertSlice'
import { logger } from 'utils/logger'
import { searchingTests } from 'redux/collectionCenter/SearchPartnerLabTest/partnerLabTestSearchSlice'
import { searchInvitations } from 'redux/collectionCenter/Search/partnerLabSearchSlice'
import { TestAddStatus } from './testAddStatusTypes'
import { searchingCatalogueDetails } from '../packageSearch/packageSearchSlice'
import { searchingCatalogueDetailsLabTest } from '../catalougeSearch/catalougeSearchSlice'

const initialState: TestAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
  updating: false,
  updateSuccessful: false,
  deleting: false,
  deletionSuccessful: false,
}

const createTestSlice = createSlice({
  name: 'createTestSlice',
  initialState,
  reducers: {
    addingCatalog(state, action: PayloadAction<TestAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
    },

    catalogAdded(state, action: PayloadAction<TestAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
    },

    updatingCatalog(state, action: PayloadAction<TestAddStatus>) {
      state.updating = action.payload.updating
      state.updateSuccessful = action.payload.updateSuccessful
      state.error = action.payload.error
    },

    catalogUpdated(state, action: PayloadAction<TestAddStatus>) {
      state.updating = action.payload.updating
      state.updateSuccessful = action.payload.updateSuccessful
      state.error = action.payload.error
    },

    errorInCreateCatalog(state, action: PayloadAction<TestAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetCreateCatalog(state, action: PayloadAction<TestAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.updating = action.payload.updating
      state.updateSuccessful = action.payload.updateSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },
  },
})

export const creatingCatalog =
  (catalogBundle: R4.IBundle, locationId: string, type: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCatalogState: TestAddStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
      updating: false,
      updateSuccessful: false,
      deleting: false,
      deletionSuccessful: false,
    }
    dispatch(createTestSlice.actions.addingCatalog(addingCatalogState))

    try {
      logger.info(catalogBundle)
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doCreateFHIRTransaction(
        '/',
        catalogBundle
      )
      const respDecoded: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (respDecoded._tag === 'Right') {
        logger.info('Response Document decoded', respDecoded.right)

        const successCreate: TestAddStatus = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
          updating: false,
          updateSuccessful: false,
          deleting: false,
          deletionSuccessful: false,
        }
        dispatch(showSuccessAlert('Test created successfully'))
        if (type.length > 0) {
          dispatch(showSuccessAlert('Package created successfully'))

          dispatch(searchingCatalogueDetails())
        } else {
          dispatch(searchingCatalogueDetailsLabTest())
        }
        dispatch(searchingTests(locationId, ''))

        dispatch(createTestSlice.actions.catalogAdded(successCreate))
      } else {
        const errorAdding: TestAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while adding catalog',
          updating: false,
          updateSuccessful: false,
          deleting: false,
          deletionSuccessful: false,
          //   patient: patientResponse,
        }
        // dispatch(showErrorAlert('Error while adding Test'))
        dispatch(createTestSlice.actions.errorInCreateCatalog(errorAdding))
        return
      }
    } catch (error) {
      const errorAddState: TestAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while adding catalog',
        updating: false,
        updateSuccessful: false,
        deleting: false,
        deletionSuccessful: false,
      }
      //   dispatch(showErrorAlert('Error while adding Test'))
      dispatch(createTestSlice.actions.errorInCreateCatalog(errorAddState))
    }
  }

export const updateCatalog =
  (catalogBundle: R4.IBundle, locationId: string, type: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const updatingCatalogState: TestAddStatus = {
      adding: false,
      additionSuccessful: false,
      error: false,
      updating: true,
      updateSuccessful: false,
      deleting: false,
      deletionSuccessful: false,
    }
    dispatch(createTestSlice.actions.updatingCatalog(updatingCatalogState))

    try {
      logger.info(catalogBundle)
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doCreateFHIRTransaction(
        '/',
        catalogBundle
      )
      const respDecoded: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (respDecoded._tag === 'Right') {
        logger.info('Response Document decoded', respDecoded.right)

        const successUpdate: TestAddStatus = {
          error: false,
          errorMessage: '',
          updating: false,
          updateSuccessful: true,
          deleting: false,
          deletionSuccessful: false,
          adding: false,
          additionSuccessful: false,
        }
        dispatch(showSuccessAlert(`${type} Updated successfully`))
        dispatch(searchingCatalogueDetails())
        searchInvitations([])

        dispatch(createTestSlice.actions.catalogUpdated(successUpdate))
      } else {
        const errorAdding: TestAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: `Error while updating ${type}`,
          updating: false,
          updateSuccessful: false,
          deleting: false,
          deletionSuccessful: false,
          //   patient: patientResponse,
        }
        dispatch(showErrorAlert(`Error while updating ${type}`))
        dispatch(createTestSlice.actions.errorInCreateCatalog(errorAdding))
        return
      }
    } catch (error) {
      const errorAddState: TestAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: `Error while updating ${type}`,
        updating: false,
        updateSuccessful: false,
        deleting: false,
        deletionSuccessful: false,
      }
      dispatch(showErrorAlert(`Error while updating ${type}`))
      dispatch(createTestSlice.actions.errorInCreateCatalog(errorAddState))
    }
  }

export const deletingCatalog =
  (catalogBundle: R4.IBundle, locationId: string, type: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const updatingCatalogState: TestAddStatus = {
      adding: false,
      additionSuccessful: false,
      error: false,
      updating: false,
      updateSuccessful: false,
      deleting: true,
      deletionSuccessful: false,
    }
    dispatch(createTestSlice.actions.updatingCatalog(updatingCatalogState))

    try {
      logger.info(catalogBundle)
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doCreateFHIRTransaction(
        '/',
        catalogBundle
      )
      const respDecoded: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (respDecoded._tag === 'Right') {
        logger.info('Response Document decoded', respDecoded.right)

        const successUpdate: TestAddStatus = {
          error: false,
          errorMessage: '',
          updating: false,
          updateSuccessful: false,
          deleting: false,
          deletionSuccessful: true,
          adding: false,
          additionSuccessful: false,
        }
        dispatch(showSuccessAlert(`${type} Deleted successfully`))
        dispatch(searchingTests(locationId, ''))
        searchInvitations([])
        dispatch(searchingCatalogueDetails())
        dispatch(createTestSlice.actions.catalogUpdated(successUpdate))
      } else {
        const errorAdding: TestAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: `Error while Deleted ${type}`,
          updating: false,
          updateSuccessful: false,
          deleting: false,
          deletionSuccessful: false,
          //   patient: patientResponse,
        }
        dispatch(showErrorAlert(`Error while Deleted ${type}`))
        dispatch(createTestSlice.actions.errorInCreateCatalog(errorAdding))
        return
      }
    } catch (error) {
      const errorAddState: TestAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: `Error while Deleted ${type}`,
        updating: false,
        updateSuccessful: false,
        deleting: false,
        deletionSuccessful: false,
      }
      dispatch(showErrorAlert(`Error while Deleted ${type}`))
      dispatch(createTestSlice.actions.errorInCreateCatalog(errorAddState))
    }
  }

//   export const deletingCatalog =
//   (catalogBundle: R4.IBundle,locationId:string): AppThunk =>
//   async (dispatch: AppDispatch) => {
//     const updatingCatalogState: TestAddStatus = {
//       adding: false,
//       additionSuccessful: false,
//       error: false,
//       updating:false,
//       updateSuccessful: false,
//       deleting:true,
//       deletionSuccessful: false

//     }
//     dispatch(createTestSlice.actions.addingCatalog(updatingCatalogState))

//     try {
//       logger.info(catalogBundle)
//       const fhirClient: FHIRApiClient = new FHIRApiClient()
//       const response: any = await fhirClient.doCreateFHIRTransaction(
//         '/',
//         catalogBundle
//       )
//       const respDecoded: E.Either<Errors, R4.IBundle> =
//         R4.RTTI_Bundle.decode(response)
//       if (respDecoded._tag === 'Right') {
//         logger.info('Response Document decoded', respDecoded.right)

//         const successUpdate: TestAddStatus = {
//           adding: false,
//           additionSuccessful: false,
//           error: false,
//           errorMessage: '',
//           updating:false,
//           updateSuccessful: false,
//           deleting:false,
//           deletionSuccessful: true
//         }
//         dispatch(showSuccessAlert('Test Updated successfully'))
//         dispatch(searchingTests(locationId,''))
//         dispatch(createTestSlice.actions.catalogUpdated(successUpdate))
//       } else {
//         const errorAdding: TestAddStatus = {
//           adding: false,
//           additionSuccessful: false,
//           error: true,
//           errorMessage: 'Error while updating catalog',
//           updating:false,
//           updateSuccessful: false,
//           deleting:false,
//           deletionSuccessful: false
//           //   patient: patientResponse,
//         }
//         dispatch(showErrorAlert('Error while updating Test'))
//         dispatch(createTestSlice.actions.errorInCreateCatalog(errorAdding))
//         return
//       }
//     } catch (error) {
//       const errorAddState: TestAddStatus = {
//         adding: false,
//         additionSuccessful: false,
//         error: true,
//         errorMessage: 'Error while deleting package',
//         updating:false,
//         updateSuccessful: false,
//         deleting:false,
//         deletionSuccessful: false
//       }
//       dispatch(showErrorAlert('Error while deleting package'))
//       dispatch(createTestSlice.actions.errorInCreateCatalog(errorAddState))
//     }
//   }

export const resetPackageState = () => (dispatch: AppDispatch) => {
  dispatch(createTestSlice.actions.resetCreateCatalog(initialState))
}

export default createTestSlice.reducer
