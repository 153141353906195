import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  continueButtonClicked,
  disableButtonClicked,
  onPatientSelected,
  onQuickSelected,
  onSlotSelected,
  onWalkInSlotSelected,
  requestAppointment,
  requestAppointmentForQuickDataEntry,
  resetAppointmentState,
  resetAppointmentStateForModifySlot,
} from 'redux/appointments/appointmentManger/appointmentManagerSlice'
import { ACTIONS } from 'redux/appointments/appointmentManger/appointmentManagerStatus'
import { resetSlotSelectionStatus } from 'redux/appointments/slotSelectionHandler/practitionerSlotSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  availablePaymentTypes,
  availablePaymentTypesRemovedOnline,
} from 'utils/constants'
import {
  validateLastName,
  validateMiddleName,
  validateMobileNumberForAdd,
  validateName,
} from 'utils/formValidators'
import { WelloLoadingIndicator } from 'wello-web-components'
import { ProviderSlotSelectorNew } from './providerSlotSelector_new'
import { PatientSelector } from './selectPateint'

interface Props {
  open: boolean
  onClose: () => void
  onAppointmentCreated: (
    createdAppointment: R4.IAppointment | undefined
  ) => void
  preSelectedDoctor?: PractitionerWithRole
  preSelectedSlotStartTime?: Date
  fhirAppointmentDetail?: FhirAppointmentDetail
  allowWalkIn?: boolean
  isWalkIn?: boolean
  followUp?: boolean
  quickDataEntry?: boolean
}
export const CreateAppointmentHandler: React.FC<Props> = ({
  open,
  onClose,
  onAppointmentCreated,
  preSelectedDoctor,
  preSelectedSlotStartTime,
  fhirAppointmentDetail,
  allowWalkIn = false,
  isWalkIn = false,
  followUp,
  quickDataEntry,
}) => {
  const appointmentManagerSlice = useSelector(
    (state: RootState) => state.appointmentManagerSlice
  )

  const dispatch = useDispatch()
  const selectedAppointmentType = useRef('')

  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '56%',
    },
  }))
  const { t } = useTranslation()
  const [isPatientEditMode, setIsPatientEditMode] = useState<boolean>(true)
  const selecctedPaymentType = useRef('cash')

  const [firstName, setFirstName] = useState<string>('')
  const [middleName, setMiddleName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [refferedId, setRefferedId] = useState<string>()
  const [isPrimary, setIsPrimary] = useState<boolean>()
  const [relation, setRelation] = useState<R4.ICoding>()

  const classes = useStyles()
  useEffect(() => {
    if (appointmentManagerSlice.appointmentCreatedSuccessfully) {
      if (onAppointmentCreated) {
        onAppointmentCreated(
          appointmentManagerSlice.createdAppointment ?? undefined
        )
      }
    }
  }, [appointmentManagerSlice, onAppointmentCreated])

  /* useEffect(() => {
    if (preSelectedPatient) {
      dispatch(onPatientSelected(appointmentManagerSlice, preSelectedPatient))
    }
  }, []) */

  function requestForAppointment() {
    if (followUp) {
      if (appointmentManagerSlice.selectedSlot && fhirAppointmentDetail) {
        dispatch(
          requestAppointment(
            appointmentManagerSlice.selectedSlot,
            fhirAppointmentDetail.patient,
            selectedAppointmentType.current,

            selecctedPaymentType.current === 'online',
            fhirAppointmentDetail,
            appointmentManagerSlice.isWalkIn,
            followUp,
            false,
            appointmentManagerSlice.question
          )
        )
      }
    } else if (
      appointmentManagerSlice.selectedSlot &&
      appointmentManagerSlice.selectedPatient
    ) {
      dispatch(
        requestAppointment(
          appointmentManagerSlice.selectedSlot,
          appointmentManagerSlice.selectedPatient,
          selectedAppointmentType.current,

          selecctedPaymentType.current === 'online',
          fhirAppointmentDetail,
          appointmentManagerSlice.isWalkIn,
          followUp,
          appointmentManagerSlice.isQuickEntry,
          appointmentManagerSlice.question
        )
      )
    } else if (appointmentManagerSlice.selectedSlot) {
      dispatch(
        requestAppointmentForQuickDataEntry(
          appointmentManagerSlice.selectedSlot,
          firstName,
          middleName,
          lastName,
          phone,
          selectedAppointmentType.current,
          selecctedPaymentType.current === 'online',

          fhirAppointmentDetail,
          appointmentManagerSlice.isQuickEntry,
          refferedId,
          isPrimary,
          relation,
          appointmentManagerSlice.question
        )
      )
    }
  }
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>
        {appointmentManagerSlice.currentAction === ACTIONS.PatientSelection
          ? fhirAppointmentDetail === undefined
            ? `${t('labelCommon:new_patient_app')}`
            : t('labelCommon:followup_patient_app')
          : fhirAppointmentDetail === undefined
          ? `${t('labelCommon:new_appointment_title')}`
          : t('labelCommon:followUp_appointment')}
      </DialogTitle>
      <DialogContent
        style={{
          padding: 0,
          overflow: 'hidden',
          margin: 0,
        }}
      >
        {appointmentManagerSlice.creatingAppointment && (
          <WelloLoadingIndicator message='Requesting for Appointment ' />
        )}

        {appointmentManagerSlice.decidingAction && <WelloLoadingIndicator />}

        {appointmentManagerSlice.currentAction === ACTIONS.SlotSelection &&
          !appointmentManagerSlice.creatingAppointment &&
          !appointmentManagerSlice.decidingAction && (
            <ProviderSlotSelectorNew
              onSlotSelected={(
                appointmentType: string,
                selectedSlot?: FhirSlotDetail,
                question?: R4.IQuestionnaire
              ) => {
                if (selectedSlot) {
                  selectedAppointmentType.current = appointmentType

                  dispatch(
                    onSlotSelected(
                      appointmentManagerSlice,
                      selectedSlot,
                      appointmentManagerSlice.selectedPatient,
                      question
                    )
                  )
                } else {
                  dispatch(disableButtonClicked(appointmentManagerSlice))
                }
              }}
              preSelectedDoctor={preSelectedDoctor}
              preSelectedSlotStartTime={preSelectedSlotStartTime}
              allowWalkIn={allowWalkIn}
              onWalkInAppointmentClicked={() => {
                dispatch(
                  onWalkInSlotSelected(
                    appointmentManagerSlice,
                    appointmentManagerSlice.selectedPatient
                  )
                )
              }}
              fhirAppointmentDetail={fhirAppointmentDetail}
              followUp={followUp}
            />
          )}
        {appointmentManagerSlice.currentAction === ACTIONS.PatientSelection &&
          !appointmentManagerSlice.decidingAction &&
          (appointmentManagerSlice.selectedSlot ||
            appointmentManagerSlice.isWalkIn) &&
          (appointmentManagerSlice.selectedSlot ||
            appointmentManagerSlice.isQuickEntry) &&
          !appointmentManagerSlice.creatingAppointment && (
            <PatientSelector
              selectedServiceType={selectedAppointmentType.current}
              selectedSlot={appointmentManagerSlice.selectedSlot}
              patient={
                appointmentManagerSlice.selectedPatient ||
                fhirAppointmentDetail?.patient
              }
              onPatientSelected={(selectedPatient) => {
                dispatch(
                  onPatientSelected(
                    appointmentManagerSlice,
                    true,
                    selectedPatient
                  )
                )
              }}
              onPaymentSelected={(selectedPaymentType) => {
                selecctedPaymentType.current = selectedPaymentType
              }}
              onClickOnModfifyPatient={(modified, selectedPatient) => {
                dispatch(
                  onPatientSelected(
                    appointmentManagerSlice,
                    modified,
                    undefined
                  )
                )
              }}
              onClickModifySlot={(
                selectedSlot: FhirSlotDetail,
                patient?: R4.IPatient
              ) => {
                dispatch(
                  resetAppointmentStateForModifySlot(
                    appointmentManagerSlice,
                    patient
                  )
                )
              }}
              onQuickEntry={(
                fName: string,
                mName: string,
                lName: string,
                phoneNo: string,
                isValid?: boolean,
                referredId?: string,
                isPrimaryData?: boolean,
                relationData?: R4.ICoding
              ) => {
                setFirstName(fName)
                setMiddleName(mName)
                setLastName(lName)
                setPhone(phoneNo)
                setRefferedId(referredId)
                setIsPrimary(isPrimaryData)
                setRelation(relationData)

                if (isValid) {
                  if (
                    validateMobileNumberForAdd(
                      phoneNo.replaceAll('+91', ''),
                      true
                    ).length === 0 &&
                    validateLastName(lName, true).length === 0 &&
                    validateName(fName, true).length === 0 &&
                    validateMiddleName(mName, false).length === 0
                  ) {
                    dispatch(onQuickSelected(appointmentManagerSlice, true))
                  } else {
                    dispatch(onQuickSelected(appointmentManagerSlice, false))
                  }
                } else {
                  dispatch(onQuickSelected(appointmentManagerSlice, false))
                }
              }}
              fhirAppointmentDetail={fhirAppointmentDetail}
              followUp={followUp}
              quickDataEntry={quickDataEntry}
            />
          )}
      </DialogContent>
      <DialogActions
        style={{ background: useTheme().palette.background.default }}
      >
        <Button
          onClick={() => {
            dispatch(resetAppointmentState())
            dispatch(resetSlotSelectionStatus())
            onClose()
          }}
          id='cancel'
          variant='outlined'
          disableElevation
        >
          {t('labelCommon:cancel')}
        </Button>
        {appointmentManagerSlice.currentAction !== ACTIONS.PatientSelection &&
          allowWalkIn && (
            <Button
              variant='contained'
              id='walkIn'
              color='primary'
              onClick={() => {
                dispatch(
                  onWalkInSlotSelected(
                    appointmentManagerSlice,
                    appointmentManagerSlice.selectedPatient
                  )
                )
              }}
            >
              Walk-In Appointment
            </Button>
          )}
        {followUp === undefined && quickDataEntry === false && (
          <Button
            onClick={() => {
              if (
                appointmentManagerSlice.currentAction ===
                ACTIONS.PatientSelection
              ) {
                requestForAppointment()
              } else {
                dispatch(continueButtonClicked(appointmentManagerSlice))
              }
            }}
            id={
              appointmentManagerSlice.currentAction === ACTIONS.PatientSelection
                ? 'create'
                : 'continue'
            }
            variant='contained'
            color='primary'
            disableElevation
            disabled={!appointmentManagerSlice.enableContinueButton}
          >
            {appointmentManagerSlice.currentAction === ACTIONS.PatientSelection
              ? t('labelCommon:create_appointment')
              : t('labelCommon:continue')}
          </Button>
        )}

        {appointmentManagerSlice.enableContinueButton}

        {followUp === undefined && quickDataEntry === true && (
          <Button
            onClick={() => {
              if (
                appointmentManagerSlice.currentAction ===
                ACTIONS.PatientSelection
              ) {
                requestForAppointment()
              } else {
                dispatch(continueButtonClicked(appointmentManagerSlice))
              }
            }}
            id={
              followUp === undefined && quickDataEntry === true
                ? 'create'
                : 'continue'
            }
            variant='contained'
            color='primary'
            disableElevation
            disabled={!appointmentManagerSlice.enableContinueButton}
          >
            {appointmentManagerSlice.currentAction === ACTIONS.PatientSelection
              ? t('labelCommon:create_appointment')
              : t('labelCommon:continue')}
          </Button>
        )}

        {followUp && (
          <Button
            onClick={() => {
              if (
                appointmentManagerSlice.currentAction ===
                ACTIONS.PatientSelection
              ) {
                requestForAppointment()
              } else {
                dispatch(continueButtonClicked(appointmentManagerSlice))
              }
            }}
            id={
              appointmentManagerSlice.currentAction === ACTIONS.PatientSelection
                ? 'create'
                : 'continue'
            }
            variant='contained'
            color='primary'
            disableElevation
            disabled={!appointmentManagerSlice.selectedSlot}
          >
            {appointmentManagerSlice.currentAction ===
              ACTIONS.PatientSelection || followUp
              ? t('labelCommon:create_appointment')
              : t('labelCommon:continue')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
