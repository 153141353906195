import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import _ from 'lodash'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import { getExpandedServiceRequestFromBundle } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { getExpandedServiceRequestFromBundlePartner } from 'utils/fhirResoureHelpers/partnerLabOrderHelper'
import { logger } from 'utils/logger'
import { OrderSearchStatus } from './orderSearchSliceStatus'

const initialState: OrderSearchStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingOrders: false,
}

let currentSelectedDate: Date | undefined
let currentSelectedDoctors: PractitionerWithRole[]
let currentSelectedOrderTypes: string[] | undefined
let currentSelectedTests: LabOfferingDetail[] | undefined

const orderSearchSlice = createSlice({
  name: 'orderSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<OrderSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableOrders = action.payload.availableOrders
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingOrders = action.payload.errorWhileSearchingOrders
      state.pageState = action.payload.pageState
      state.recordsCount = action.payload.recordsCount
      state.existingDateWiseData = action.payload.existingDateWiseData
    },
  },
})

export const requestOrdersForTheDateRange =
  (
    selectedDoctors: PractitionerWithRole[],
    selectedDate?: Date,
    selectedOrderTypes?: string[],
    selectedTests?: LabOfferingDetail[],
    pageState?: string,
    recordsCount?: number,
    existingOrders?: FhirLabOrderDetail[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    currentSelectedDate = selectedDate
    currentSelectedDoctors = selectedDoctors
    currentSelectedOrderTypes = selectedOrderTypes
    currentSelectedTests = selectedTests
    const state: OrderSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      availableOrders: existingOrders,
    }
    dispatch(orderSearchSlice.actions.updatedStatus(state))
    try {
      const unitDetails = getCurrentUserUnitReference()
      currentSelectedDate?.setSeconds(new Date().getSeconds())
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        /* '_include:iterate': 'ServiceRequest:performer', */

        intent: 'order',
        _include: ['ServiceRequest:patient'],
        occurrence: `ge${moment(selectedDate)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ssZ')}`,
        status: 'active,revoked,completed',
        _sort: 'occurrence',
        '_include:iterate': [
          'ServiceRequest:performer',
          'ServiceRequest:instantiates-canonical',
          'PlanDefinition:service-billing',
          'Task:owner',
          'PractitionerRole:practitioner',
        ],
        _revinclude: ['Task:focus'],
      }
      if (currentSelectedTests && currentSelectedTests.length > 0) {
        const params: string[] = currentSelectedTests.map(
          (e) => `${e.planDefinition.resourceType}/${e.planDefinition.id}`
        )
        searchParameters['instantiates-canonical'] = params.join(',')
        searchParameters.code = params.join(',')
      }
      if (currentSelectedOrderTypes && currentSelectedOrderTypes.length > 0) {
        searchParameters.code = currentSelectedOrderTypes.join(',')
      }
      //   if (currentSelectedDoctors && currentSelectedDoctors.length > 0) {
      //     let doctorsList: string = ''
      //     currentSelectedDoctors.forEach((item) => {
      //       doctorsList = `${
      //         doctorsList + item.roleObject.practitioner?.reference
      //       },`
      //     })
      if (unitDetails) {
        searchParameters.performer = unitDetails.reference
      }
      let response: any
      if (pageState === undefined) {
        response = await fhirClient.doGetResourceIncludeAndIncludeIterate(
          '/ServiceRequest?_revinclude:iterate=PaymentReconciliation:request&_revinclude:iterate=DocumentReference:related',
          searchParameters
        )
      } else if (pageState.length === 0) {
        response = await fhirClient.doGetResourceIncludeAndIncludeIterate(
          '/ServiceRequest?_revinclude:iterate=PaymentReconciliation:request&_revinclude:iterate=DocumentReference:related',
          searchParameters
        )
      } else {
        response = await fhirClient.doGetResource(
          `?${pageState}`,
          searchParameters
        )
      }

      //   const appointmentResponse: R4.IBundle = resp.right
      if (response.entry && response.entry.length > 0) {
        const link = response.link ?? []
        let linkFinal: string = ''
        if (link.length > 0) {
          for (let i = 0; i < link.length; i++) {
            if (link[i].relation === 'next') {
              const urlData = link[i].url
              if (urlData) {
                linkFinal = urlData.split('?')[1]
              }
            }
          }
        }
        const fhirAppointments: FhirLabOrderDetail[] =
          getExpandedServiceRequestFromBundle(response)
        const data = [...(existingOrders ?? []), ...fhirAppointments]

        data.sort((a, b) => moment(b.start).diff(a.start))
        logger.info(fhirAppointments)
        logger.info('Orders length')

        state.resultsAvailable = true
        state.searchingAppointments = false
        state.availableOrders = _.uniqBy(data, 'start').sort((a, b) =>
          moment(b.start).diff(a.start)
        )
        state.pageState = linkFinal
        state.recordsCount = response.total
        state.existingDateWiseData = _.uniqBy(data, 'start').sort((a, b) =>
          moment(b.start).diff(a.start)
        )
        state.noResultsAvailable = false
        state.errorReason = undefined
        state.errorWhileSearchingOrders = false
        dispatch(orderSearchSlice.actions.updatedStatus(state))
      } else {
        const errorSearchDoctor: OrderSearchStatus = {
          searchingAppointments: false,
          errorWhileSearchingOrders: false,
          resultsAvailable: false,
          noResultsAvailable: true,
        }
        dispatch(orderSearchSlice.actions.updatedStatus(errorSearchDoctor))
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: OrderSearchStatus = {
        searchingAppointments: false,
        errorWhileSearchingOrders: true,
        resultsAvailable: false,
        errorReason: 'Error while fetching orders',
      }
      dispatch(orderSearchSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export const requestOrdersForPartnerLogin =
  (
    selectedDoctors: PractitionerWithRole[],
    locationId: string,
    selectedDate?: Date,
    selectedOrderTypes?: string[],
    selectedTests?: LabOfferingDetail[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    currentSelectedDate = selectedDate
    currentSelectedDoctors = selectedDoctors
    currentSelectedOrderTypes = selectedOrderTypes
    currentSelectedTests = selectedTests
    const state: OrderSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(orderSearchSlice.actions.updatedStatus(state))
    try {
      currentSelectedDate?.setSeconds(new Date().getSeconds())
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        /* '_include:iterate': 'ServiceRequest:performer', */
        _include: ['ServiceRequest:patient'],
        occurrence: `ge${moment(selectedDate)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ssZ')}`,
        'status:': 'active',
        '_include:iterate': [
          'ServiceRequest:performer',
          'ServiceRequest:instantiates-canonical',
          'PlanDefinition:service-billing',
          'Task:owner',
          'PractitionerRole:practitioner',
        ],
        _revinclude: 'Task:focus',
      }
      if (currentSelectedTests && currentSelectedTests.length > 0) {
        const params: string[] = currentSelectedTests.map(
          (e) => `${e.planDefinition.resourceType}/${e.planDefinition.id}`
        )
        searchParameters['instantiates-canonical'] = params.join(',')
        searchParameters.code = params.join(',')
      }
      if (currentSelectedOrderTypes && currentSelectedOrderTypes.length > 0) {
        searchParameters.code = currentSelectedOrderTypes.join(',')
      }
      if (currentSelectedDoctors && currentSelectedDoctors.length > 0) {
        let doctorsList: string = ''
        currentSelectedDoctors.forEach((item) => {
          doctorsList = `${
            doctorsList + item.roleObject.practitioner?.reference
          },`
        })

        searchParameters.performer = doctorsList
      }

      const response: any =
        await fhirClient.doGetResourceIncludeAndIncludeIterate(
          '/ServiceRequest',
          searchParameters
        )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingOrders = true
        state.searchingAppointments = false

        dispatch(orderSearchSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp.right
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          const fhirAppointments: FhirLabOrderDetail[] =
            getExpandedServiceRequestFromBundlePartner(
              appointmentResponse,
              locationId
            )
          logger.info(fhirAppointments)
          logger.info('Orders length')

          state.resultsAvailable = true
          state.searchingAppointments = false
          state.availableOrders = fhirAppointments
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingOrders = false
          dispatch(orderSearchSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: OrderSearchStatus = {
            searchingAppointments: false,
            errorWhileSearchingOrders: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(orderSearchSlice.actions.updatedStatus(errorSearchDoctor))
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: OrderSearchStatus = {
        searchingAppointments: false,
        errorWhileSearchingOrders: true,
        resultsAvailable: false,
        errorReason: 'Error while fetching orders',
      }
      dispatch(orderSearchSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default orderSearchSlice.reducer
