import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FeedbackStatus } from './feedbackStatus'

const initialState: FeedbackStatus = {
  discharging: false,
  dischargeSuccessful: false,
  error: false,
  errorMessage: '',
}

const feedbackAddSlice = createSlice({
  name: 'feedbackAddSlice',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<FeedbackStatus>) {
      state.discharging = action.payload.discharging
      state.dischargeSuccessful = action.payload.dischargeSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetAddVitalsDetails(state, action: PayloadAction<FeedbackStatus>) {
      state.discharging = initialState.discharging
      state.dischargeSuccessful = initialState.dischargeSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
    },
  },
})

export const addFeedback =
  (
    appointment: FhirActiveIPDDetailsForMedicalRole,
    ans1: R4.IQuestionnaireResponse_Item,
    ans2: R4.IQuestionnaireResponse_Item,
    ans3: R4.IQuestionnaireResponse_Item,
    ans4: R4.IQuestionnaireResponse_Item,
    ans5: R4.IQuestionnaireResponse_Item,
    ans6: R4.IQuestionnaireResponse_Item,
    ans7: R4.IQuestionnaireResponse_Item
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: FeedbackStatus = {
      discharging: true,
      dischargeSuccessful: false,
      error: false,
    }
    dispatch(feedbackAddSlice.actions.updateAddVitalsStatus(addingState))

    try {
      const questionnaire: R4.IQuestionnaireResponse =
        getQuestionResponseFormat(
          appointment.mainServiceRequest.id!,
          ans1,
          ans2,
          ans3,
          ans4,
          ans5,
          ans6,
          ans7
        )

      const requestBundle: R4.IBundle = {
        resourceType: 'Bundle',
        type: R4.BundleTypeKind._transaction,
        entry: [
          {
            fullUrl: `${questionnaire.resourceType}/`,
            request: {
              method: R4.Bundle_RequestMethodKind._post,
              url: questionnaire.resourceType,
            },
            resource: questionnaire,
          },
        ],
      }
      const resource: any = {
        eventType: 'feedback',
        eventBody: requestBundle,
      }

      const enRolClient: EnrolCient = new EnrolCient()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        `ipd/${appointment.mainServiceRequest.id}/feedback`,
        resource
      )

      if (response.status === 'Feedback Of Patient received') {
        addingState = {
          discharging: false,
          dischargeSuccessful: true,
          error: false,
          errorMessage: '',
        }
        dispatch(showSuccessAlert('Feedback submitted successfully'))
        dispatch(feedbackAddSlice.actions.updateAddVitalsStatus(addingState))
      } else {
        const errorCreatePersonState: FeedbackStatus = {
          discharging: false,
          dischargeSuccessful: false,
          error: true,
          errorMessage: 'Error while discharging',
        }
        dispatch(
          feedbackAddSlice.actions.updateAddVitalsStatus(errorCreatePersonState)
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: FeedbackStatus = {
        discharging: false,
        dischargeSuccessful: false,
        error: true,
        errorMessage: 'Error while discharging',
      }
      dispatch(
        feedbackAddSlice.actions.updateAddVitalsStatus(errorCreatePersonState)
      )
    }
  }

function getQuestionResponseFormat(
  serviceId: string,
  q1: R4.IQuestionnaireResponse_Item,
  q2: R4.IQuestionnaireResponse_Item,
  q3: R4.IQuestionnaireResponse_Item,
  q4: R4.IQuestionnaireResponse_Item,
  q5: R4.IQuestionnaireResponse_Item,
  q6: R4.IQuestionnaireResponse_Item,
  q7: R4.IQuestionnaireResponse_Item
): R4.IQuestionnaireResponse {
  const topAns: R4.IQuestionnaireResponse_Item[] = []

  if (q1) topAns.push(q1)
  if (q2) topAns.push(q2)
  if (q3) topAns.push(q3)
  if (q4) topAns.push(q4)
  if (q5) topAns.push(q5)
  if (q6) topAns.push(q6)
  if (q7) topAns.push(q7)
  const topItem: R4.IQuestionnaireResponse_Item[] = topAns

  const resp: R4.IQuestionnaireResponse = {
    resourceType: 'QuestionnaireResponse',
    status: R4.QuestionnaireResponseStatusKind._completed,
    basedOn: [
      {
        reference: `ServiceRequest/${serviceId}`,
      },
    ],
    subject: {
      reference: `ServiceRequest/${serviceId}`,
    },
    item: topItem,
  }

  const dateRes: E.Either<Errors, R4.IQuestionnaireResponse> =
    R4.RTTI_QuestionnaireResponse.decode(resp)

  return resp
}

export const resetAddVitalsState = () => (dispatch: AppDispatch) => {
  dispatch(feedbackAddSlice.actions.resetAddVitalsDetails(initialState))
}

export default feedbackAddSlice.reducer
