import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { CloseOutlined } from '@material-ui/icons'
import { PractitionerWithRate } from 'models/practitionerWithRate'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMedicalProviderDetails } from 'redux/practitioner/medicalProviderManagement/medicalServiceProviderManagementSlice'
import { RootState } from 'redux/rootReducer'
import { EditPartnerLabHandler } from 'views/components/administration/actors/edit_partner_lab'
import { WelloTabs } from 'views/components/LeftMenu/WelloTabs'
import { InPersonTile } from './inPersonTile'
import { PhoneTile } from './phoneTile'
import { VideoTile } from './videoTile'

interface Props {
  practionerDetails: PractitionerWithRate
  isOdd?: boolean
  onCloseClicked?: () => void
  dense?: boolean
}

export const PractitionerRateDetails: React.FC<Props> = ({
  practionerDetails,
  isOdd = false,
  onCloseClicked,
  dense = false,
}: Props) => {
  const medicalServiceProviderManagementSlice = useSelector(
    (state: RootState) => state.medicalServiceProviderManagementSlice
  )
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showCancelHandler, setShowCancelHandler] = useState<boolean>(false)
  const [partnerDetails, setPartnerDetails] =
    useState<PractitionerWithRate>(practionerDetails)
  const [partnerLabRole, setPartnerLabRole] = useState<PractitionerWithRate>()
  const menu = ['In-Person', 'Video']
  const [selectedTab, setSelectedTab] = React.useState(menu[0])
  const [showEditPopUp, setShowEditPopup] = React.useState(false)

  useEffect(() => {
    dispatch(fetchMedicalProviderDetails(practionerDetails.roleObject.id ?? ''))
    return () => {}
  }, [dispatch, practionerDetails.roleObject.id])

  useEffect(() => {
    if (medicalServiceProviderManagementSlice.partnerDetailsAvailable) {
      if (medicalServiceProviderManagementSlice.practDetails)
        setPartnerLabRole(medicalServiceProviderManagementSlice.practDetails)
    }
    return () => {}
  }, [
    medicalServiceProviderManagementSlice.partnerDetailsAvailable,
    medicalServiceProviderManagementSlice.practDetails,
  ])

  const handleChangeTab = (selected: string) => {
    setSelectedTab(selected)
  }
  return (
    <Box width='100%' px={1}>
      <Card
        elevation={0}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 8,
          backgroundColor: isOdd ? '#FFFFFFAF' : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 4,
            paddingLeft: 16,
            paddingRight: 16,
            border: 1,
          }}
        >
          <Box display='flex' flexDirection='column' width='100%'>
            <Box flexDirection='row' width='100%' display='flex' p={1}>
              {medicalServiceProviderManagementSlice.fetchingPartnerDetails && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>
            {medicalServiceProviderManagementSlice.partnerDetailsAvailable &&
              partnerLabRole && (
                <Box display='flex' alignItems='center' p={1}>
                  <Box paddingRight={1}>
                    <Avatar
                      alt={partnerLabRole.fullName.charAt(0)}
                      src={partnerLabRole.profilePic}
                    />
                  </Box>
                  <Box display='flex' flexDirection='column'>
                    <Typography variant='subtitle1' color='primary'>
                      {partnerLabRole.fullName}
                    </Typography>
                    <Typography variant='subtitle2' color='initial'>
                      {partnerLabRole.department}
                    </Typography>
                    <Typography variant='subtitle2' color='initial'>
                      {partnerLabRole.totalExp}
                    </Typography>
                  </Box>
                </Box>
              )}
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              alignContent='center'
            >
              <WelloTabs
                preSelectedTab={t('labelCommon:' + `${selectedTab}`)}
                onTabChange={(e) => handleChangeTab(e)}
                menu={menu}
              />

              <Box display='flex' flexDirection='row'>
                <Box>
                  <Tooltip title={t('close') ?? 'Re-Schedule'}>
                    <IconButton
                      aria-label='btn_ord_detail_menu'
                      onClick={onCloseClicked}
                    >
                      <CloseOutlined color='primary' />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <Divider
              orientation='horizontal'
              variant='fullWidth'
              flexItem
              style={{ width: '100%', height: '2px' }}
              color='primary'
            />

            {selectedTab === menu[0] && (
              <Box>
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' flexDirection='column' width='60%'>
                    <Box display='flex' flexDirection='row' width='100%'>
                      {partnerLabRole?.inPersonRate && (
                        <Box display='flex' flexDirection='column' width='100%'>
                          <Box paddingY={2}>
                            <InPersonTile practDetails={partnerLabRole} />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

            {/* {selectedTab === menu[1] && (
              <Box>
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' flexDirection='column' width='60%'>
                    <Box display='flex' flexDirection='row' width='100%'>
                      {partnerLabRole?.phoneRate && (
                        <Box display='flex' flexDirection='column' width='100%'>
                          <Box paddingY={2}>
                            <PhoneTile practDetails={partnerLabRole} />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )} */}

            {selectedTab === menu[1] && (
              <Box>
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' flexDirection='column' width='60%'>
                    <Box display='flex' flexDirection='row' width='100%'>
                      {partnerLabRole?.phoneRate && (
                        <Box display='flex' flexDirection='column' width='100%'>
                          {partnerLabRole?.videoRate && (
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                            >
                              <Box paddingY={2}>
                                <VideoTile practDetails={partnerLabRole} />
                              </Box>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
      <EditPartnerLabHandler
        open={showEditPopUp}
        onBackClick={() => {
          setShowEditPopup(false)
        }}
        location={partnerLabRole?.locationObject}
        practitionerRole={partnerLabRole?.roleObject}
        onContinueClick={() => {
          setShowEditPopup(false)
        }}
      />
    </Box>
  )
}
