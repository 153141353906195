import { R4 } from '@ahryman40k/ts-fhir-types'
import { Calendar } from '@mantine/dates'
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  List,
  ListSubheader,
  Paper,
  TextField,
  Typography,
  withStyles,
  IconButton,
} from '@material-ui/core'
import { ToggleButtonGroup } from '@material-ui/lab'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import { kPrimaryDark, kPrimaryMain } from 'configs/styles/muiThemes'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { resetAppointmentState } from 'redux/appointments/appointmentManger/appointmentManagerSlice'
import { requestForDateWiseIpdAppointmentLists } from 'redux/clinic/ipdmanagement/ipdSearch/ipdSearchSlice'
import { resetState } from 'redux/patient/addPatient/addPatientSlice'
import { RootState } from 'redux/rootReducer'
import { getAllNutritionValueSets } from 'redux/valueSet/nutrition_catalogue/nutrtionCatalogueSlice'
import { updateSessionTherapiesList } from 'redux/valueSet/therapies_catalogue/therapiesCatalogueSlice'
import { getAllSubstance } from 'redux/valueSet/substance/substanceSearchSlice'
import { getAllTherapist } from 'redux/valueSet/therapyList/threapyCatalogSearchSlice'
import { useWindowSize } from 'rooks'
import {
  getCurrentUserUnitDetails,
  isPhysiotherapist,
  isTherapist,
} from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import { getCompleteDateStringWithOutDay, isToday } from 'utils/dateUtil'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { getVendorPartId } from 'utils/routes_helper'
import { desktopScreenWidth } from 'utils/screen_utils'
import { CreateAppointmentHandler } from 'views/components/appointments/createAppointmentManager'
import { AddPatientData } from 'views/components/common/add_patient_data'
import { IPDStatusSelection } from 'views/components/ipd/ipdAppointmentStatusSelector'
import { IPDDetails } from 'views/components/ipd/IpdDetails'
import { IPDDetailsPopUp } from 'views/components/ipd/IpdDetailsPopUp'
import { CreateIpdAppointmentHandler } from 'views/components/ipdAppointment/createIpdAppoitmentManagement'
import { AddLabNewPatient } from 'views/components/lab/patientSetup/add_patient'
import '../../../../App.css'
import { AppointmentTypeOptions } from 'views/components/appointments/appointmentTypeOptions'
import { DayCareManagement } from 'views/components/ipdAppointment/dayCare/dayCareManagement'
import { getAllTherapiesWithPrice } from 'redux/valueSet/therapyListwithChargable/threapyCatalogWithPriceSearchSlice'
import { ConsultationManagement } from 'views/components/ipdAppointment/consultation/consultationManagement'
import { requestForDateWiseOPDAppointmentLists } from 'redux/clinic/opdManagement/opdSearch/opdSearchSlice'
import { OpdAppointmentTile } from 'views/components/ipd/ipdAppointmentList/opdAppointmentTile'
import { requestForDateWiseOPDAppointmentListsForConsulting } from 'redux/clinic/opdManagement/opdConsultManagement/opdConsultSearchSlice'
import { requestOPDAppCountForTodayConsult } from 'redux/clinic/opdManagement/opdConsultManagement/opdConsultCount/opdConsultCountSlice'
import { AddNewPatient } from '../../../components/add_new_patient'

const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  grouped: {
    padding: theme.spacing(0.5),

    border: 'none',
    '&.Mui-selected': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
    },
  },
}))(ToggleButtonGroup)

export const OPDConsultation: React.FC = () => {
  const selectedDateQ = useSelector(
    (state: RootState) => state.opdAppointmentSearchSlice.selectedDate
  )
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] =
    useState<Date | undefined>(selectedDateQ)

  const [openAddPatientPopup, setOpenAddPatientPopup] = useState(false)

  const [openIpdCreate, setOpenIpdCreate] = useState(false)
  const [dayCareCreate, setDayCareCreate] = useState(false)

  const appointmentSearchSlice = useSelector(
    (state: RootState) => state.opdConsultppointmentSearchSlice
  )

  const [selectedIpdStatuses, setSelectedIPDStatuses] = useState<string[]>(
    appointmentSearchSlice.selectedStatuses
  )

  const [selectedIpd, setSelectedIPd] = useState<FhirClinicIpdDetails>()
  const [name, setName] = useState<string>('')

  const [openCreateAppointmentPopup, setOpenCreateAppointmentPopup] =
    useState(false)
  const [consult, setConsult] = useState(false)

  const [openAppointmentTypeDialog, setOpenAppointmentTypeDialog] =
    useState(false)
  const [dispplayId, setDisplayId] = useState<string>('')
  const [openHookPage, setOpenHookPage] = useState(false)
  const [viewType, setViewType] = useState<string | undefined>('list')

  const { t, i18n } = useTranslation(['en', 'labelCommon'])
  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<R4.IOrganization>(getCurrentUserUnitDetails())
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      currentOrganizationDetails.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  const handleViewTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | undefined
  ) => {
    setViewType(newView)
  }

  const navigateToPath = (path: string) => {
    // history.replace(path)
    window.location.href = path
  }

  const { innerWidth } = useWindowSize()

  function openAppointment(appointment: FhirClinicIpdDetails) {
    navigateToPath(
      `/${getVendorPartId()}/viewOPDAppointment?id=${
        appointment.serviceRequest.id
      }`
    )
  }

  useEffect(() => {
    // if (loggedInUnitType === 'Clinic') document.title = 'WelloClinic'
    dispatch(
      requestForDateWiseOPDAppointmentListsForConsulting(
        name,
        selectedDate,
        selectedIpdStatuses,
        undefined,
        undefined,
        [],
        []
      )
    )
  }, [dispatch, selectedDate, selectedIpdStatuses])

  useEffect(() => {
    // if (loggedInUnitType === 'Clinic') document.title = 'WelloClinic'

    dispatch(requestOPDAppCountForTodayConsult(selectedDate))
  }, [selectedDate])

  useEffect(() => {
    dispatch(getAllNutritionValueSets())
    if (isPhysiotherapist()) dispatch(updateSessionTherapiesList())
    dispatch(getAllTherapist())
    dispatch(getAllTherapiesWithPrice())
    dispatch(getAllSubstance())
  }, [])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        borderRadius: 8,
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box
            display='flex'
            width='22%'
            maxWidth='22%'
            style={{ overflow: 'auto', height: '100%', minWidth: '290px' }}
          >
            <SimpleBar
              style={{
                height: '100%',
                width: '100%',
                overflowX: 'hidden',
                padding: '8px',
              }}
            >
              <Box px={1} width='100%'>
                <Box width='100%'>
                  <Calendar
                    size='xs'
                    style={{
                      aspectRatio: '1/1',
                    }}
                    value={selectedDate}
                    onChange={setSelectedDate}
                    // onMonthChange={setSelectedDate}
                    dayStyle={(date, modifier) => {
                      if (isToday(date)) {
                        return {
                          aspectRatio: '1',
                          borderBottom: `1px solid ${kPrimaryDark}`,
                          bottom: '20%',
                        }
                      }
                      return { aspectRatio: '1' }
                    }}
                    styles={{
                      cell: {
                        aspectRatio: '1',
                      },
                      day: {
                        aspectRatio: '1 !important',
                      },
                      weekend: {
                        color: 'black',
                      },
                      weekday: {
                        color: kPrimaryMain,
                      },
                      selected: {
                        borderRadius: '50%',
                      },
                      label: {
                        fontWeight: 'bold',
                        color: kPrimaryMain,
                      },
                    }}
                  />
                </Box>
                <IPDStatusSelection
                  type='OPD'
                  onSelectionChanged={(changedTypes) => {
                    setSelectedIPDStatuses(changedTypes)
                  }}
                />
              </Box>
            </SimpleBar>
          </Box>

          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            maxWidth='88%'
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            <Box flexDirection='row' width='100%' display='flex' p={1}>
              <TextField
                variant='outlined'
                onChange={(event) => {
                  dispatch(
                    requestForDateWiseOPDAppointmentListsForConsulting(
                      event.target.value,
                      undefined,
                      selectedIpdStatuses,
                      undefined,
                      undefined,
                      [],
                      []
                    )
                  )
                  setName(event.target.value)
                }}
                size='small'
                value={name}
                autoFocus={true}
                placeholder='Search By Patient Name'
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <img
                        src={`${process.env.PUBLIC_URL}/search.png`}
                        alt='s'
                        style={{ paddingLeft: name ? 1 : 0 }}
                      />
                    </IconButton>
                  ),

                  endAdornment: name && (
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => {
                        setName('')
                        dispatch(
                          requestForDateWiseOPDAppointmentListsForConsulting(
                            '',
                            undefined,
                            selectedIpdStatuses,
                            undefined,
                            undefined,
                            [],
                            []
                          )
                        )
                      }}
                    >
                      <CancelRoundedIcon />
                    </IconButton>
                  ),
                }}
              />
              {appointmentSearchSlice.searchingAppointments && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              )}
              {!isTherapist() && (
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-end'
                  alignContent='flex-end'
                  flexGrow='1'
                >
                  <Button
                    variant='contained'
                    color='primary'
                    title='Book Appointment(IPD)'
                    id='add_appointments_ipd'
                    onClick={() => {
                      sessionStorage.removeItem('selecttedNames')
                      setOpenIpdCreate(true)
                    }}
                  >
                    Admit New Patient (IPD)
                  </Button>

                  <Button
                    variant='contained'
                    color='primary'
                    title={t('labelCommon:add_appointment')}
                    id='add_appointments'
                    onClick={() => {
                      setOpenAppointmentTypeDialog(true)
                    }}
                  >
                    Add Appointment (OPD)
                  </Button>

                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      setOpenAddPatientPopup(true)
                    }}
                  >
                    {t('labelCommon:add_patient')}
                  </Button>
                </Box>
              )}
            </Box>
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='Start'
              alignContent='center'
              overflow='auto'
              style={{
                backgroundColor: '#00000005',
              }}
            >
              {appointmentSearchSlice.noResultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  {selectedIpdStatuses.length === 1 &&
                    selectedIpdStatuses[0] === 'completed' && (
                      <Typography
                        variant='subtitle1'
                        color='initial'
                        align='center'
                      >
                        {isTherapist()
                          ? 'No Therapies to be performed'
                          : 'No data available'}
                      </Typography>
                    )}
                  {selectedIpdStatuses.length > 0 &&
                    selectedIpdStatuses[0] !== 'completed' && (
                      <Typography
                        variant='subtitle1'
                        color='initial'
                        align='center'
                      >
                        {isTherapist() ||
                        moment(selectedDate).isBefore(moment())
                          ? 'No OPD Appointments for the selected date'
                          : name.length > 0
                          ? 'No OPD found for this patient'
                          : 'No OPD Appointments available'}
                      </Typography>
                    )}
                </Box>
              )}

              {appointmentSearchSlice.errorWhileSearchingOrders && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography variant='subtitle1' color='error' align='center'>
                    Error while searching OPD Appointments
                  </Typography>
                </Box>
              )}

              {appointmentSearchSlice.dateWiseAppointments && (
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height='100%'
                  overflow='auto'
                >
                  <Box
                    display='flex'
                    overflow='auto'
                    height='100%'
                    flexGrow={2}
                  >
                    <List
                      id='scrollableDiv'
                      subheader={<li style={{ padding: 0, width: '100%' }} />}
                      style={{
                        padding: 0,
                        width: '100%',
                        overflow: 'auto',
                        height: '100%',
                      }}
                    >
                      {/* {(appointmentSearchSlice.recordsCount === undefined ||
                        appointmentSearchSlice.recordsCount === 0) &&
                        !appointmentSearchSlice.searchingAppointments && (
                          <Typography variant='body1' color='initial'>
                            No IPD's available .
                          </Typography>
                        )} */}
                      <InfiniteScroll
                        dataLength={appointmentSearchSlice.recordsCount ?? 0}
                        scrollThreshold='300px'
                        next={() => {
                          dispatch(
                            requestForDateWiseIpdAppointmentLists(
                              name,
                              selectedDate,
                              selectedIpdStatuses,
                              appointmentSearchSlice.pageState,
                              appointmentSearchSlice.availableAppointments
                                ?.length,
                              appointmentSearchSlice.dateWiseAppointments,
                              appointmentSearchSlice.availableAppointments
                            )
                          )
                        }}
                        hasMore={
                          (appointmentSearchSlice.availableAppointments
                            ?.length ?? 0) <
                          (appointmentSearchSlice.recordsCount ?? -1)
                        }
                        loader=''
                        endMessage={
                          appointmentSearchSlice.searchingAppointments ? (
                            <p />
                          ) : (
                            <p style={{ textAlign: 'center' }} />
                          )
                        }
                        scrollableTarget='scrollableDiv'
                      >
                        {appointmentSearchSlice.dateWiseAppointments!.map(
                          (e) => (
                            <li
                              key={`section-${e.date ?? ''}`}
                              style={{ padding: 0, width: '100%' }}
                            >
                              <ul style={{ padding: 0 }}>
                                <ListSubheader
                                  style={{
                                    color: '#333333',
                                    fontWeight: 600,
                                    position: 'sticky',
                                    paddingLeft: 12,
                                    width: '100%',
                                    backgroundColor: '#ececec',
                                  }}
                                >
                                  {getCompleteDateStringWithOutDay(
                                    e.date ?? ''
                                  )}
                                </ListSubheader>
                                {e.orders?.map((item, index) => (
                                  <OpdAppointmentTile
                                    ipdAppointmentDetails={item}
                                    isOdd={index % 2 === 0}
                                    dense={selectedIpd !== undefined}
                                    isSelected={
                                      selectedIpd !== undefined &&
                                      item.serviceRequest.id ===
                                        selectedIpd?.serviceRequest.id &&
                                      innerWidth! > desktopScreenWidth
                                    }
                                    onViewClicked={() => {
                                      setSelectedIPd(item)
                                    }}
                                    onManageClicked={() => {
                                      openAppointment(item)
                                    }}
                                    key={
                                      `lab_item_key${item.serviceRequest.id}` ??
                                      ''
                                    }
                                  />
                                ))}
                              </ul>
                            </li>
                          )
                        )}
                      </InfiniteScroll>
                    </List>
                  </Box>

                  {selectedIpd && innerWidth! > desktopScreenWidth && (
                    <Box
                      display='flex'
                      //   flexGrow={40}
                      overflow='auto'
                      width='90%'
                    >
                      <IPDDetails
                        ipdDetailsDataFinal={selectedIpd}
                        onCloseClicked={() => {
                          setSelectedIPd(undefined)
                        }}
                        date={selectedDate}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            <Box />
          </Box>
        </Box>
      </Paper>

      {innerWidth! <= desktopScreenWidth && selectedIpd && (
        <IPDDetailsPopUp
          ipdDetails={selectedIpd}
          onCloseClicked={() => {
            setSelectedIPd(undefined)
          }}
          open={innerWidth! <= desktopScreenWidth && selectedIpd !== undefined}
        />
      )}

      <AppointmentTypeOptions
        open={openAppointmentTypeDialog}
        id={1233}
        onClose={() => {
          setOpenAppointmentTypeDialog(false)
        }}
        onTypeSelected={(type: string) => {
          if (type === 'consultation') {
            setConsult(true)
            setOpenAppointmentTypeDialog(false)
            setDayCareCreate(false)
          } else {
            setDayCareCreate(true)
            setOpenAppointmentTypeDialog(false)
          }
        }}
      />

      <CreateAppointmentHandler
        open={openCreateAppointmentPopup}
        onClose={() => {
          setOpenCreateAppointmentPopup(false)
          dispatch(resetAppointmentState())
          //   dispatch(resetSlotSelectionStatus())
          /* dispatch(
            requestAppointmentSForTheDateRange(
              new Date(),
              new Date(),
              selectedDoctors
            )
          ); */
        }}
        onAppointmentCreated={(createdAppointment) => {
          setOpenCreateAppointmentPopup(false)
          dispatch(resetAppointmentState())
          //   dispatch(resetSlotSelectionStatus())
          setSelectedDate(moment(createdAppointment?.start).toDate())
          //   requestSearchForSlots()
          /* dispatch(
            requestAppointmentSForTheDateRange(
              new Date(),
              new Date(),
              selectedDoctors
            )
          ); */
        }}
      />

      {openAddPatientPopup === true && loggedInUnitType !== 'Lab' && (
        <AddNewPatient
          isLab={false}
          isAppointment={true}
          popup={openAddPatientPopup}
          onPatientCreated={(createdPatient?: R4.IPatient) => {
            // dispatch(showSuccessAlert('Patient added successfully'))
            dispatch(resetState())
            setOpenAddPatientPopup(false)
          }}
          onError={(message: string) => {
            dispatch(showErrorAlert(message))
            dispatch(resetState())
            // setOpenAddPatientPopup(false)
          }}
          onCancelClicked={() => {
            dispatch(resetState())
            setOpenAddPatientPopup(false)
          }}
        />
      )}

      <CreateIpdAppointmentHandler
        open={openIpdCreate}
        id={2}
        onLabOrderCreated={(orderId: string, paymentType: string) => {
          dispatch(
            requestForDateWiseIpdAppointmentLists(
              name,
              selectedDate,
              selectedIpdStatuses,
              undefined,
              undefined,
              [],
              []
            )
          )
          if (paymentType === 'online') {
            setDisplayId(orderId)
            setOpenIpdCreate(false)
          } else {
            setOpenIpdCreate(false)
          }
        }}
        onClose={() => {
          setOpenIpdCreate(false)
        }}
      />

      <DayCareManagement
        id={1002}
        open={dayCareCreate}
        onLabOrderCreated={(orderId: string, paymentType: string) => {
          dispatch(
            requestForDateWiseOPDAppointmentLists(
              name,
              selectedDate,
              selectedIpdStatuses,
              undefined,
              undefined,
              [],
              []
            )
          )
          if (paymentType === 'online') {
            setDisplayId(orderId)
            setDayCareCreate(false)
          } else {
            setDayCareCreate(false)
          }
        }}
        onClose={() => {
          setDayCareCreate(false)
        }}
      />

      <ConsultationManagement
        id={2002}
        open={consult}
        onLabOrderCreated={(orderId: string, paymentType: string) => {
          dispatch(
            requestForDateWiseOPDAppointmentListsForConsulting(
              name,
              selectedDate,
              selectedIpdStatuses,
              undefined,
              undefined,
              [],
              []
            )
          )

          if (paymentType === 'online') {
            setDisplayId(orderId)
            setConsult(false)
          } else {
            setConsult(false)
          }
        }}
        onClose={() => {
          setConsult(false)
        }}
      />

      {openAddPatientPopup === true &&
        (loggedInUnitType === 'Lab' ||
          loggedInUnitType === 'Collection Center') && (
          <AddLabNewPatient
            popup={openAddPatientPopup}
            onPatientCreated={(createdPatient?: R4.IPatient) => {
              dispatch(showSuccessAlert('Patient added successfully'))
              dispatch(resetState())
              setOpenAddPatientPopup(false)
            }}
            onError={() => {
              dispatch(
                showErrorAlert('Error while adding patient. Please try later')
              )
              dispatch(resetState())
              setOpenAddPatientPopup(false)
            }}
            onCancelClicked={() => {
              dispatch(resetState())
              setOpenAddPatientPopup(false)
            }}
          />
        )}

      <AddPatientData
        open={openHookPage}
        onClose={() => {
          setOpenHookPage(false)
        }}
      />
    </div>
  )
}
