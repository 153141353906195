import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { truncate } from 'lodash'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { RoomPrice } from 'models/roomPrice'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import { getRoomPriceFromBundle } from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { getExpandedServiceRequestFromBundle } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { logger } from 'utils/logger'
import { RoomBedSearchStatus } from './roomBedSearchStatus'

const initialState: RoomBedSearchStatus = {
  searchingPrice: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingPrice: false,
}

const roomBedSearchSlice = createSlice({
  name: 'roomBedSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<RoomBedSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingPrice = action.payload.searchingPrice
      state.resultsAvailable = action.payload.resultsAvailable
      state.roomData = action.payload.roomData
      state.location = action.payload.location
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingPrice = action.payload.errorWhileSearchingPrice
    },
    resetState(state, action: PayloadAction<RoomBedSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingPrice = action.payload.searchingPrice
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorWhileSearchingPrice = action.payload.errorWhileSearchingPrice
    },
  },
})

export const resetRoomBedSearchSlice =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: RoomBedSearchStatus = {
      searchingPrice: true,
      errorWhileSearchingPrice: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(roomBedSearchSlice.actions.resetState(state))
  }

export const requestForLocation =
  (roomNumber: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: RoomBedSearchStatus = {
      searchingPrice: true,
      errorWhileSearchingPrice: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(roomBedSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const response: any =
        await fhirClient.doGetResourceIncludeAndIncludeIterate(
          `/Location/${roomNumber}`
        )

      const resp: E.Either<Errors, R4.ILocation> =
        R4.RTTI_Location.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingPrice = true
        state.searchingPrice = false

        dispatch(roomBedSearchSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.ILocation = resp.right

        state.resultsAvailable = true
        state.searchingPrice = false
        state.roomData = appointmentResponse.alias
          ? `${appointmentResponse.alias[0]}  ${appointmentResponse.alias[1]}`
          : ''
        state.location = appointmentResponse
        state.noResultsAvailable = false
        state.errorReason = undefined
        state.errorWhileSearchingPrice = false
        dispatch(roomBedSearchSlice.actions.updatedStatus(state))
      }
      /* */
    } catch (error) {
      const errorSearchDoctor: RoomBedSearchStatus = {
        searchingPrice: false,
        resultsAvailable: false,
        errorWhileSearchingPrice: true,
        errorReason: 'Error while fetching price',
      }
      dispatch(roomBedSearchSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default roomBedSearchSlice.reducer
