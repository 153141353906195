import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
  CircularProgress,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { kPrimaryMain } from 'configs/styles/muiThemes'

import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { SubscriptionInterest } from 'models/subscriptionInterestDetails'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { acceptSubscription } from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'

export interface AcceptForSubscriptionProps {
  open: boolean
  interest?: SubscriptionInterest
  onCancelActionClicked?: () => void
  onSuccessfulAction?: () => void

  id?: string
}

export const AcceptForSubscriptionPopUp: React.FC<AcceptForSubscriptionProps> =
  ({ open, interest, onCancelActionClicked, onSuccessfulAction, id }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })

    function addCurrentDetails() {
      if (interest) {
        setUpdateStatus({ requesting: true })
        acceptSubscription(interest.task).then((e) => {
          if (e) {
            setUpdateStatus({
              requesting: false,
              requestSuccess: true,
            })
            dispatch(showSuccessAlert('Subscription successfully confirmed'))
            if (onSuccessfulAction) {
              onSuccessfulAction()
            }
          } else {
            setUpdateStatus({
              requesting: false,
              requestSuccess: true,
            })
            dispatch(
              showErrorAlert(
                'Error while adding confirming subscription. Please try again later'
              )
            )
          }
        })
      }
    }

    return (
      <Dialog
        open={open}
        onClose={() => {
          if (onCancelActionClicked) onCancelActionClicked()
        }}
        aria-labelledby='responsive-dialog-title'
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle id='scroll-dialog-title'>{`Subscription for ${interest?.patientName}`}</DialogTitle>
        <DialogContent dividers={true}>
          <Alert
            severity='warning'
            style={{
              backgroundColor: '#FFFFFF',
              color: useTheme().palette.common.black,
            }}
          >
            <Typography variant='body1'>
              {' '}
              {`${interest?.patientName} has accepted terms and condition of ${interest?.subscriptionName}. Confirm trial subscription ?`}
            </Typography>
          </Alert>
        </DialogContent>
        <DialogActions>
          {updateStatus.requesting && (
            <CircularProgress size={20} style={{ color: kPrimaryMain }} />
          )}
          &nbsp;&nbsp;
          <Button
            onClick={() => {
              if (onCancelActionClicked) onCancelActionClicked()
            }}
            variant='outlined'
            disableElevation
            disabled={updateStatus.requesting}
            id={`${id}confirmDialog_button`}
          >
            <Typography variant='button' id={`${id}cancel_button`}>
              No
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            onClick={() => {
              addCurrentDetails()
            }}
            variant='contained'
            color='primary'
            id='subs_continue_button'
            disabled={updateStatus.requesting}
          >
            <Typography variant='button' id={`${id}done_button`}>
              Yes
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
