/* eslint-disable no-constant-condition */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/react-in-jsx-scope */
import { Box } from '@material-ui/core'
import chroma from 'chroma-js'
import * as d3 from 'd3'
import {
  sankey,
  sankeyCenter,
  sankeyJustify,
  sankeyLeft,
  sankeyLinkHorizontal,
  SankeyNodeMinimal,
  sankeyRight,
} from 'd3-sankey'
import { SankeyProps } from 'models/shankeyProps'
import React from 'react'
import { getLabelData } from 'utils/cohort/careplaCohortUtils'

const formatTooltip = (tooltip: string) => {
  // break out number
  const parts: string[] = tooltip.split(':')
  // console.debug("tooltip", tooltip);
  // console.debug("parts for label", parts);

  let formatted: string = ''
  let labels: string[] = []
  if (parts.length !== 2) {
    formatted = ''
    labels = parts[0].split(',')
  } else {
    formatted = `<p>Total: ${parts[0]}</p>`
    labels = parts[1].split(',')
  }

  let i: number = 0
  const l: number = labels.length

  for (i = 0; i < l; i++) {
    formatted += `<p>${getLabelData(labels[i])}</p>`
  }
  return formatted
}

const SankeyNode = ({
  name,
  value,
  id,
  x0,
  x1,
  y0,
  y1,
  color,
  textColor,
  containerWidth,
  tooltip,
  nodeWidth,
  accessibleTooltip,
}: any) => {
  const showTooltip = (e: any) => {
    const tooltipData = document.getElementById('tooltip')
    // console.debug("t", tooltip, e);
    if (tooltipData) {
      tooltipData.innerHTML = formatTooltip(tooltip)
      tooltipData.style.display = 'block'
      tooltipData.style.left = `${e.pageX + 10}px`
      tooltipData.style.top = `${e.pageY + 10}px`
      tooltipData.style.overflowX = 'auto'
      tooltipData.style.width = '200px'
      tooltipData.style.background = 'white'
      tooltipData.style.border = '1px solid black'
      tooltipData.style.padding = '4px'
    }
  }

  const hideTooltip = () => {
    const tooltipY = document.getElementById('tooltip')
    if (tooltipY) {
      tooltipY.style.display = 'none'
    }
  }

  return (
    <>
      {accessibleTooltip ? (
        <rect
          x={x0 + nodeWidth > containerWidth ? x0 - (nodeWidth - 1) : x0}
          y={y0}
          width={x1 - x0 + (nodeWidth || 0)}
          height={y1 - y0}
          fill={color}
          stroke='black'
          strokeWidth={1}
          strokeOpacity={75}
          paintOrder='stroke'
        >
          <title>{`Total: ${tooltip}`}</title>
        </rect>
      ) : (
        <rect
          x={x0 + nodeWidth > containerWidth ? x0 - (nodeWidth - 1) : x0}
          y={y0}
          width={x1 - x0 + (nodeWidth || 0)}
          height={y1 - y0}
          fill={color}
          stroke='black'
          strokeWidth={1}
          strokeOpacity={75}
          paintOrder='stroke'
          onMouseMove={showTooltip}
          onMouseOut={hideTooltip}
        />
      )}

      {y1 - y0 !== 0 ? (
        <text
          x={
            x0 + nodeWidth < containerWidth
              ? x0 + 13 + (nodeWidth || 0)
              : x0 - nodeWidth - 6
          }
          y={y0 + (y1 - y0) / 2 + 5}
          fontWeight={600}
          fill={textColor}
          width={64}
          height={64}
          textAnchor={x0 + 10 < containerWidth ? 'start' : 'end'}
          stroke='white'
          strokeOpacity={75}
          strokeWidth={2}
          paintOrder='stroke'
        >
          {name ? `${String(value)} : ${getLabelData(name)}` : id}
        </text>
      ) : (
        ''
      )}
    </>
  )
}

const SankeyLink = ({ link, color, accessibleTooltip }: any) => {
  const showTooltip = (e: any) => {
    const tooltip = document.getElementById('tooltip')
    // console.debug("t", tooltip, e);

    if (tooltip) {
      tooltip.innerHTML = /* html */ `<p>Total: ${
        link.value
      }</p><p>${getLabelData(link.target.name)}</p>`
      tooltip.style.display = 'block'
      tooltip.style.left = `${e.pageX + 10}px`
      tooltip.style.top = `${e.pageY + 10}px`
      tooltip.style.overflowX = 'auto'
      tooltip.style.width = '200px'
      tooltip.style.background = 'white'
      tooltip.style.border = '1px solid black'
      tooltip.style.padding = '4px'
    }
  }

  const hideTooltip = () => {
    const tooltip = document.getElementById('tooltip')
    if (tooltip) {
      tooltip.style.display = 'none'
    }
  }

  return (
    <>
      {accessibleTooltip ? (
        <path
          d={sankeyLinkHorizontal()(link) ?? undefined}
          style={{
            fill: 'none',
            strokeOpacity: '.3',
            stroke: color,
            strokeWidth: Math.max(1, link.width),
          }}
        >
          <title>{`Total: ${link.value} - ${link.target.name}`}</title>
        </path>
      ) : (
        <path
          d={sankeyLinkHorizontal()(link) ?? undefined}
          style={{
            fill: 'none',
            strokeOpacity: '.3',
            stroke: color,
            strokeWidth: Math.max(1, link.width),
          }}
          onMouseMove={showTooltip}
          onMouseOut={hideTooltip}
        />
      )}
    </>
  )
}
const id = (d: any) => d.id

export const BaseSankeyChart: React.FC<SankeyProps> = ({
  data,
  width,
  height,
  colorSet,
  min,
  nodeAlign,
  nodeWidth,
  accessibleTooltip,
}: SankeyProps) => {
  try {
    let align: {
      (node: SankeyNodeMinimal<{}, {}>, n: number): number
      (node: SankeyNodeMinimal<{}, {}>, n: number): number
      (node: SankeyNodeMinimal<{}, {}>, n: number): number
      (node: SankeyNodeMinimal<{}, {}>, n: number): number
      (node: SankeyNodeMinimal<{}, {}>, n: number): number
      (node: SankeyNodeMinimal<{}, {}>, n: number): number
    }
    if (nodeAlign) {
      if (nodeAlign === 'left') {
        align = sankeyLeft
      } else if (nodeAlign === 'right') {
        align = sankeyRight
      } else if (nodeAlign === 'center') {
        align = sankeyCenter
      } else {
        align = sankeyJustify
      }
    } else {
      align = sankeyJustify
    }

    const { nodes, links } = sankey()
      .nodeId(id)
      .nodeWidth(8)
      .nodePadding(10)
      .nodeAlign(align)
      .extent([
        [1, 1],
        [width - 1, height - 5],
      ])(data)
    // const { nodes, links } = sankey()
    //   .nodeId(id)
    //   .nodeWidth(8)
    //   .nodePadding(10)
    //   .nodeAlign(align)
    //   .extent([
    //     [1, 1],
    //     [width - 1, height - 5],
    //   ])(data)

    // console.debug("Nodes", nodes);

    const color = chroma.scale(colorSet || 'Set1').classes(nodes.length)
    const colorScale = d3.scaleLinear().domain([0, nodes.length]).range([0, 1])

    return (
      <g style={{ mixBlendMode: 'multiply' }}>
        {links.map((link: any, i: number) => (
          <SankeyLink
            link={link}
            color={color(colorScale(link.source.index)).hex()}
            key={i}
            accessibleTooltip={accessibleTooltip}
          />
        ))}
        {nodes.map((node: any, i: number) => (
          <SankeyNode
            {...node}
            color={color(colorScale(i)).hex()}
            textColor={color(colorScale(i)).darken(2).hex()}
            key={i}
            nodeWidth={nodeWidth}
            containerWidth={width}
            value={node.value}
            accessibleTooltip={accessibleTooltip}
          />
        ))}
      </g>
    )
  } catch (e: any) {
    console.error(e)
    return (
      <Box>Error</Box>
      //        <ErrorMessage
      //          message={`An Error Occurred!  ${e && e.message ? e.message : ''}`}
      //          svg={true}
      //    />
    )
  }
}
