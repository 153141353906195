import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { WelloCondition } from 'models/WelloConditions'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { getNameOfPatient } from 'wello-web-components'
import { ProcedureAddStatus } from './addProcedureStatusTypes'

const initialState: ProcedureAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addProcedureCondition = createSlice({
  name: 'addProcedureCondition',
  initialState,
  reducers: {
    updateAddConditionStatus(state, action: PayloadAction<ProcedureAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.procedure = action.payload.procedure
    },

    resetPatientDetails(state, action: PayloadAction<ProcedureAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.procedure = initialState.procedure
    },
  },
})

export const addHistoryProcedureDetails =
  (
    patientDetails: R4.IPatient,
    procedures: WelloCondition[],
    appointmentId?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: ProcedureAddStatus = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(
      addProcedureCondition.actions.updateAddConditionStatus(addingState)
    )
    const practitionerDetail = getCurrentUserPractitionerDetails()
    const practitionerRoleDetail = getCurrentUserPractitionerRoleDetails()
    try {
      const requestBundle: R4.IBundle = {
        resourceType: 'Bundle',
        type: R4.BundleTypeKind._transaction,
        entry: [],
      }

      for (let i = 0; i < procedures.length; i++) {
        const condition: R4.IProcedure = {
          resourceType: 'Procedure',
          meta: {
            profile: [
              'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndProcedureTLCProcedure',
            ],
          },
          subject: {
            display: getNameOfPatient(patientDetails),
            id: patientDetails.id,
            reference: `${patientDetails.resourceType}/${patientDetails.id}`,
            type: patientDetails.resourceType,
          },
          code: {
            coding: [procedures[i].condition!],
            text: procedures[i].condition!.display,
          },

          recorder: {
            display: getNameFromHumanName(practitionerDetail.name ?? []),
            id: practitionerRoleDetail.id,
            reference: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
            type: practitionerRoleDetail.resourceType,
          },

          category: {
            coding: [
              {
                code: '387713003',
                display: 'Surgical procedure',
                system: 'http://snomed.info/sct',
              },
            ],
          },
        }

        if (
          procedures[i].selectedType &&
          procedures[i].selectedType!.length > 0
        ) {
          if (procedures[i].selectedType! === 'days') {
            if (procedures[i].days && procedures[i].days! > 0) {
              condition.performedAge = {
                system: 'http://unitsofmeasure.org',
                unit: 'd',
                code: 'd',
                value: procedures[i].days!,
              }
            }
          } else if (procedures[i].selectedType === 'months') {
            condition.performedAge = {
              system: 'http://unitsofmeasure.org',
              unit: 'mo',
              code: 'mo',
              value: procedures[i].month!,
            }
          } else if (procedures[i].selectedType === 'weeks') {
            condition.performedAge = {
              system: 'http://unitsofmeasure.org',
              unit: 'wk',
              code: 'wk',
              value: procedures[i].week!,
            }
          } else if (procedures[i].selectedType === 'years') {
            condition.performedAge = {
              system: 'http://unitsofmeasure.org',
              unit: 'a',
              code: 'a',
              value: procedures[i].year!,
            }
          } else if (procedures[i].selectedType === 'custom') {
            if (procedures[i].selectedDate)
              condition.performedDateTime =
                procedures[i].selectedDate!.toISOString()
          }
        }
        if (appointmentId) {
          condition.extension = [
            {
              url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
              valueReference: {
                reference: `Appointment/${appointmentId}`,
              },
            },
            {
              url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-verification-status-ext',
              valueCodeableConcept: {
                coding: [
                  {
                    system:
                      'http://terminology.hl7.org/CodeSystem/allergyintolerance-verification',
                    code: 'unconfirmed',
                    display: 'UnConfirmed',
                  },
                ],
                text: 'UnConfirmed',
              },
            },
          ]
        }

        if (procedures[i].notes && procedures[i].notes!.length > 0) {
          condition.note = [
            {
              authorReference: {
                reference: `${practitionerDetail.resourceType}/${practitionerDetail.id}`,
              },
              text: procedures[i].notes!,
              time: new Date().toISOString(),
            },
          ]
        }
        const entry: R4.IBundle_Entry = {
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: condition.resourceType,
          },
          resource: condition,
        }
        requestBundle.entry?.push(entry)
      }

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        requestBundle
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        dispatch(showSuccessAlert('Surgery added successfully'))
        dispatch(
          addProcedureCondition.actions.updateAddConditionStatus(addingState)
        )
      } else {
        const errorCreatePersonState: ProcedureAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while creating patient',
        }
        dispatch(showSuccessAlert('Surgery added successfully'))
        dispatch(
          addProcedureCondition.actions.updateAddConditionStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: ProcedureAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding procedure',
      }
      dispatch(
        addProcedureCondition.actions.updateAddConditionStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetAddConditionState = () => (dispatch: AppDispatch) => {
  dispatch(addProcedureCondition.actions.resetPatientDetails(initialState))
}

export default addProcedureCondition.reducer
