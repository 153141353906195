import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Grid,
  Divider,
  Tooltip,
  Link,
  Collapse,
  Button,
} from '@material-ui/core'
import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
} from '@material-ui/icons'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import moment from 'moment'

import React, { useEffect, useState } from 'react'
import { getMRP } from 'utils/appointment_handle/medications_util'
import {
  getCompleteDateStringForLabReferral,
  getCompleteDateStringForOrder,
} from 'utils/dateUtil'

interface AllergiesProp {
  salesOrder: any
}

export const PaymentTileOrder: React.FC<AllergiesProp> = ({
  salesOrder,
}: AllergiesProp) => {
  const [checked, setChecked] = React.useState<boolean>(false)

  function handleCollapseForPanel1(rate: boolean) {
    setChecked(rate)
  }
  return (
    <Box
      flexGrow
      width='100%'
      flexDirection='column'
      display='flex'
      style={{ padding: 0, margin: 0 }}
    >
      <Box flexGrow width='100%' display='flex' flexDirection='row'>
        <Box display='flex' flexDirection='column' width='100%' flexGrow>
          <Box width='100%' py={1}>
            <Box
              paddingX={1}
              borderRadius={2}
              style={{
                backgroundColor: 'lightGrey',
              }}
              display='flex'
              flexDirection='row'
              width='100%'
              height={40}
            >
              <Box
                justifyContent='flex-start'
                display='flex'
                flexGrow={1}
                alignItems='center'
              >
                <Typography
                  variant='subtitle1'
                  style={{
                    color: 'black',

                    fontWeight: 'bold',
                  }}
                >
                  Payment Details
                </Typography>
              </Box>
              <Box
                justifyContent='flex-end'
                display='flex'
                flexGrow={1}
                alignItems='center'
              >
                <Box px={1}>
                  <Tooltip title=''>
                    <IconButton
                      aria-label='collapse_order_type'
                      size='small'
                      onClick={() => {
                        handleCollapseForPanel1(!checked)
                      }}
                    >
                      {checked && <ArrowDropUpOutlined />}
                      {checked === false && <ArrowDropDownOutlined />}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <Collapse
              in={checked}
              style={{
                width: '100%',
              }}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                width='100%'
                border={4}
                style={{
                  boxShadow: '0px 0px 4px #ccc',
                  margin: '2px 0px',
                  borderRadius: '1px',
                  borderColor: '#F8F8F8',
                  width: '100%',
                  height: 'auto',
                  backgroundColor: '#F8F8F8',
                }}
              >
                <Grid container direction='row' spacing={1}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Grid
                      container
                      direction='row'
                      id='vitals'
                      style={{
                        backgroundColor: kDialogueBackground,
                      }}
                    >
                      <Grid item xs={3}>
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexDirection='column'
                                height={80}
                                px={1}
                                paddingTop={1}
                                paddingBottom={0.5}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  MRP
                                </Typography>
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  paddingBottom={0.5}
                                  paddingTop={0.5}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Discount
                                  </Typography>
                                </Box>

                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Delivery Charge
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              display='flex'
                              flexGrow={1}
                              justifyContent='flex-end'
                            >
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  height: '80px',

                                  width: '2px',
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexDirection='column'
                              height={80}
                              px={1}
                              paddingTop={1}
                              paddingBottom={0.5}
                            >
                              <Typography variant='subtitle2' color='initial'>
                                &#x20b9; {getMRP(salesOrder).toFixed(2)}
                              </Typography>
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                py={0.5}
                              >
                                <Typography variant='subtitle2' color='initial'>
                                  &#x20b9; {salesOrder.discount.toFixed(2)}
                                </Typography>
                              </Box>

                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                              >
                                <Typography variant='subtitle2' color='initial'>
                                  &#x20b9;{' '}
                                  {salesOrder.shipping_charge_exclusive_of_tax.toFixed(
                                    2
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container direction='row' id='vitals'>
                      <Grid item xs={12}>
                        <Box width='100%' paddingLeft={0.2}>
                          <Divider
                            orientation='horizontal'
                            flexItem
                            style={{
                              height: '2px',
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      direction='row'
                      id='vitals'
                      style={{
                        backgroundColor: 'white',
                      }}
                    >
                      <Grid item xs={3}>
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={1}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Total Amount
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display='flex'
                              flexGrow={1}
                              justifyContent='flex-end'
                            >
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  height: '30px',

                                  width: '2px',
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                              px={1}
                            >
                              <Typography variant='subtitle2' color='initial'>
                                &#x20b9; {salesOrder.total.toFixed(2)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container direction='row' id='vitals'>
                      <Grid item xs={12}>
                        <Box width='100%' paddingLeft={0.2}>
                          <Divider
                            orientation='horizontal'
                            flexItem
                            style={{
                              height: '2px',
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      direction='row'
                      id='vitals'
                      style={{
                        backgroundColor: kDialogueBackground,
                      }}
                    >
                      <Grid item xs={3}>
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={1}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Payment History
                                </Typography>
                              </Box>
                            </Box>
                            {/* <Box
                              display='flex'
                              flexGrow={1}
                              justifyContent='flex-end'
                            >
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  height: '30px',

                                  width: '2px',
                                }}
                              />
                            </Box> */}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                              px={1}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container direction='row' id='vitals'>
                      <Grid item xs={12}>
                        <Box width='100%' paddingLeft={0.2}>
                          <Divider
                            orientation='horizontal'
                            flexItem
                            style={{
                              height: '2px',
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction='row'
                      id='vitals'
                      style={{
                        backgroundColor: 'white',
                      }}
                    >
                      <Grid item xs={3}>
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={1}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Ref ID
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display='flex'
                              flexGrow={1}
                              justifyContent='flex-end'
                            >
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  height: '30px',

                                  width: '2px',
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                              px={1}
                            >
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                Amount
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            display='flex'
                            flexGrow={1}
                            justifyContent='flex-end'
                          >
                            <Divider
                              orientation='vertical'
                              flexItem
                              style={{
                                height: '30px',

                                width: '2px',
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={3}>
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                              px={1}
                            >
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                Payment Time
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            display='flex'
                            flexGrow={1}
                            justifyContent='flex-end'
                          >
                            <Divider
                              orientation='vertical'
                              flexItem
                              style={{
                                height: '30px',

                                width: '2px',
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                              px={1}
                            >
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                Transaction Note
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            display='flex'
                            flexGrow={1}
                            justifyContent='flex-end'
                          >
                            <Divider
                              orientation='vertical'
                              flexItem
                              style={{
                                height: '30px',

                                width: '2px',
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container direction='row' id='vitals'>
                      <Grid item xs={12}>
                        <Box width='100%' paddingLeft={0.2}>
                          <Divider
                            orientation='horizontal'
                            flexItem
                            style={{
                              height: '2px',
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      direction='row'
                      id='vitals'
                      style={{
                        backgroundColor: kDialogueBackground,
                      }}
                    >
                      <Grid item xs={3}>
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={1}
                              >
                                {salesOrder.order_status === 'confirmed' && (
                                  <Typography
                                    variant='subtitle2'
                                    color='initial'
                                  >
                                    {
                                      salesOrder.notes
                                        .split(',')[1]
                                        .split(':')[1]
                                    }
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                            <Box
                              display='flex'
                              flexGrow={1}
                              justifyContent='flex-end'
                            >
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  height: '30px',

                                  width: '2px',
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                              px={1}
                            >
                              {salesOrder.order_status === 'confirmed' && (
                                <Typography variant='subtitle2' color='initial'>
                                  &#x20b9; {salesOrder.total.toFixed(2)}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                          <Box
                            display='flex'
                            flexGrow={1}
                            justifyContent='flex-end'
                          >
                            <Divider
                              orientation='vertical'
                              flexItem
                              style={{
                                height: '30px',

                                width: '2px',
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={3}>
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                              px={1}
                            >
                              {salesOrder.order_status === 'confirmed' && (
                                <Typography variant='subtitle2' color='initial'>
                                  {getCompleteDateStringForOrder(
                                    salesOrder.created_time
                                  )}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                          <Box
                            display='flex'
                            flexGrow={1}
                            justifyContent='flex-end'
                          >
                            <Divider
                              orientation='vertical'
                              flexItem
                              style={{
                                height: '30px',

                                width: '2px',
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                              px={1}
                            >
                              {salesOrder.order_status === 'confirmed' && (
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                />
                              )}
                            </Box>
                          </Box>
                          <Box
                            display='flex'
                            flexGrow={1}
                            justifyContent='flex-end'
                          >
                            <Divider
                              orientation='vertical'
                              flexItem
                              style={{
                                height: '30px',

                                width: '2px',
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container direction='row' id='vitals'>
                      <Grid item xs={12}>
                        <Box width='100%' paddingLeft={0.2}>
                          <Divider
                            orientation='horizontal'
                            flexItem
                            style={{
                              height: '2px',
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Box>
            </Collapse>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
