import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DashboardData } from 'models/adminGraph/dashboardData'
import { DateWiseGraphData } from 'models/adminGraph/dateWiseGraphData'
import axios, { CancelToken, CancelTokenSource } from 'axios'
import { GraphType } from 'models/adminGraph/graphType'
import { TableData } from 'models/adminGraph/tableData'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import { sleep } from 'utils/dateUtil'
import { getDateWiseDietPlansFromBundle } from 'utils/fhirResoureHelpers/ipdDietHelper'
import {
  getDataForDateWiseGraph,
  getDataForTable,
  getDataForTypeWise,
  gettableDataForDashboard,
} from 'utils/fhirResoureHelpers/orgAdminGraphHelper'
import { logger } from 'utils/logger'
import { InvoiceTableSearchStatus } from './invoiceTableSearchStatus'

const initialState: InvoiceTableSearchStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
}

const dashboardTableSearchSlice = createSlice({
  name: 'dashboardTableSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<InvoiceTableSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
      state.tableData = action.payload.tableData
    },
    resetReportState(state, action: PayloadAction<InvoiceTableSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
      state.tableData = action.payload.tableData
    },
  },
})

export const resetReportState = () => (dispatch: AppDispatch) => {
  dispatch(dashboardTableSearchSlice.actions.resetReportState(initialState))
}

export const requestDataForDashboardTable =
  (date: Date, endDate?: Date, type?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: InvoiceTableSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(dashboardTableSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: MasterFhirClient = new MasterFhirClient()
      const endDateData =
        type && endDate
          ? moment(endDate).endOf('day').format('YYYY-MM-DD')
          : moment(endDate || date)
              .endOf('month')
              .format('YYYY-MM-DD')

      const searchParameters: any = {
        _count: 500,
        outcome: 'complete',
      }

      const startDate = type
        ? moment(date).endOf('day').format('YYYY-MM-DD')
        : moment(date).startOf('month').format('YYYY-MM-DD')

      axios.CancelToken.source()
      if (cancelTokenStore.has('patientSearchControlToken')) {
        const controlTokenForSearch: CancelTokenSource = cancelTokenStore.get(
          'patientSearchControlToken'
        )
        controlTokenForSearch.cancel('new param added')
        cancelTokenStore.delete('patientSearchControlToken')
      }
      cancelTokenStore.set(
        'patientSearchControlToken',
        axios.CancelToken.source()
      )
      const response: any = await fhirClient.doGetResource(
        `/PaymentReconciliation?payment-reconciliation-date=ge${moment(
          startDate
        ).format(
          'YYYY-MM-DD'
        )}&payment-reconciliation-date=le${endDateData}&_sort=payment-reconciliation-date&_include=Invoice:subject&_revinclude:iterate=Invoice:invoice-paymentRef`,
        searchParameters
      )

      const resp: R4.IBundle = response as R4.IBundle
      if (!resp) {
        state.errorWhileSearchingAppointments = true
        state.searchingAppointments = false

        dispatch(dashboardTableSearchSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp

        const resultData: DashboardData[] = gettableDataForDashboard(
          appointmentResponse,
          date
        )

        if (resultData.length > 0) {
          const tableData: TableData[] = getDataForTable(resultData)

          if (tableData.length > 0) {
            state.resultsAvailable = true
            state.searchingAppointments = false
            state.noResultsAvailable = false
            state.errorReason = undefined
            state.tableData = tableData
            state.errorWhileSearchingAppointments = false
            dispatch(dashboardTableSearchSlice.actions.updatedStatus(state))
          } else {
            const errorSearchDoctor: InvoiceTableSearchStatus = {
              searchingAppointments: false,
              errorWhileSearchingAppointments: false,
              resultsAvailable: false,
              noResultsAvailable: true,
            }
            dispatch(
              dashboardTableSearchSlice.actions.updatedStatus(errorSearchDoctor)
            )
          }
        } else {
          const errorSearchDoctor: InvoiceTableSearchStatus = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            dashboardTableSearchSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: InvoiceTableSearchStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while ',
      }
      dispatch(
        dashboardTableSearchSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export default dashboardTableSearchSlice.reducer
