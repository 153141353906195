import { R4 } from '@ahryman40k/ts-fhir-types'
import { CircularProgress, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { TherapyDetail } from 'models/therapyDetail'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { getDefaultCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { InformationDialog } from './informationDialog'

export interface TherapiesSelectorProps {
  isInEditMode: boolean
  id: string
  searchType: string
  onRadioOptionsChanged: (changedValue: TherapyDetail[]) => void
  selectedValue?: R4.ICatalogEntry
  placeHolderString?: string
  catalogEntryPath?: string
  detachLabel?: boolean
  labelString?: string
  selectedId?: string
  preSelectedCodes?: string[]
}

export const TherapiesSelector: React.FC<TherapiesSelectorProps> = ({
  selectedValue: selectedValues,
  isInEditMode = true,
  placeHolderString,
  detachLabel,
  labelString,
  id,
  searchType,
  onRadioOptionsChanged,
  selectedId,
  preSelectedCodes = [],
}: TherapiesSelectorProps) => {
  const addDietSlice = useSelector(
    (state: RootState) => state.therapiesCatalogueSlice
  )
  const [loading, setLoading] = React.useState(addDietSlice.fetchingTherapies)
  const [options, setOptions] = React.useState<TherapyDetail[]>(
    addDietSlice.fetchingTherapies === true
      ? []
      : addDietSlice.catalogueEntries![searchType] ?? []
  )

  const [pricePopUp, setPricePricePopUp] = useState<boolean>(false)
  const [therapyName, setTherapyName] = useState<string>('')

  useEffect(() => {
    if (!addDietSlice.fetchingTherapies) {
      setLoading(false)
      setOptions(addDietSlice.catalogueEntries![searchType] ?? [])
    }
  }, [addDietSlice.fetchingTherapies])

  function getDisplayname(therapyDetail: TherapyDetail) {
    if (
      therapyDetail.isChargeable &&
      therapyDetail.baseAmount &&
      therapyDetail.baseAmount > 0
    ) {
      return `${therapyDetail.displayName}- RS. ${therapyDetail.baseAmount}`
    }
    return therapyDetail.displayName
  }

  return (
    <>
      <Autocomplete
        multiple
        id={id}
        placeholder={placeHolderString}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => getDisplayname(option)}
        fullWidth
        disabled={!isInEditMode}
        options={options.filter(
          (e) =>
            !preSelectedCodes.includes(
              getDefaultCodeOfSystemFromCodableConceptList(
                e.healtcareService.type ?? []
              ) ?? ''
            )
        )}
        loading={loading}
        onChange={(e, changedValue) => {
          if (changedValue != null) {
            onRadioOptionsChanged(changedValue)
            if (
              changedValue.length > 0 &&
              changedValue[changedValue.length - 1].isChargeable
            ) {
              if (changedValue[changedValue.length - 1].baseAmount! > 0) {
                setTherapyName(
                  `Price of ${
                    changedValue[changedValue.length - 1].displayName ?? ''
                  } is Rs. ${
                    changedValue[changedValue.length - 1].baseAmount ?? 0
                  }. Do you want to add to the plan ?`
                )
                setPricePricePopUp(true)
              } else {
                setTherapyName('')
                setPricePricePopUp(false)
              }
            }
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={labelString}
            variant='outlined'
            size='small'
            id={`${id}textFiled`}
            placeholder={placeHolderString}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <InformationDialog
        open={pricePopUp}
        displayText={therapyName}
        title='Therapy Info'
        doneButtonText='Yes'
        cancelButtonText='No'
        onClose={() => {
          setPricePricePopUp(false)
        }}
        onDiscard={() => {
          setPricePricePopUp(false)
        }}
      />
    </>
  )
}
