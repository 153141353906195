import { Box, CircularProgress, IconButton, Tooltip } from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { getTreatmentReportOfVisit } from 'utils/fhirResoureHelpers/reportHelper'

export interface DischargeReportButtonProps {
  serviceReqId: string
  disabled?: boolean
  title?: string
}

export const TreatmentReportOfIPDButton: React.FC<DischargeReportButtonProps> =
  ({ serviceReqId, disabled = false, title }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [generatingStatus, setGeneratingStatus] =
      useState<NetworkRequestStatus>({
        initial: true,
      })

    function handleInvoiceGenerationButton() {
      setGeneratingStatus({
        requesting: true,
      })

      if (serviceReqId) {
        getTreatmentReportOfVisit(serviceReqId).then((e) => {
          if (e) {
            setGeneratingStatus({
              requesting: false,
              requestSuccess: true,
            })
          } else {
            setGeneratingStatus({
              requesting: false,
              requestError: true,
            })
            dispatch(showErrorAlert('Treatment Card is not available'))
          }
        })
      }
    }

    return (
      <Box px={1} paddingTop={0.5}>
        {generatingStatus.requesting && <CircularProgress size={20} />}
        {!generatingStatus.requesting && (
          <Tooltip title='Print Treatment Card'>
            <IconButton
              onClick={() => {
                handleInvoiceGenerationButton()
              }}
              color='primary'
              size='small'
              disabled={disabled}
            >
              <img
                src={`${process.env.PUBLIC_URL}/treatment.png`}
                alt='delete'
                height={40}
              />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    )
  }
