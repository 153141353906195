import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { WelloMedication } from 'models/welloMedication'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  createBundleObjectForRequests,
  createBundleObjectForWelloMedications,
} from 'utils/appointment_handle/medications_util'
import { getCarePlanOfPatientWithCurrentPractitioner } from 'utils/careplan_utils/careplan_utils'
import { getEncounterObjectForAppointment } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { MedicationAddStatus } from './addMedicationStatusTypes'

const initialState: MedicationAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addMedicationsSlice = createSlice({
  name: 'addMedicationsSlice',
  initialState,
  reducers: {
    updateAddMedicationsStatus(
      state,
      action: PayloadAction<MedicationAddStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.allergy = action.payload.allergy
    },

    resetMedicationsDetails(state, action: PayloadAction<MedicationAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.allergy = initialState.allergy
    },
  },
})

export const addMedicationsDetails =
  (
    appointment: FhirAppointmentDetail,
    medications: WelloMedication[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: MedicationAddStatus = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(
      addMedicationsSlice.actions.updateAddMedicationsStatus(addingState)
    )
    const encounter: R4.IEncounter =
      getEncounterObjectForAppointment(appointment)

    const patientId = appointment.patient.id!
    const carePlanOfPatient = await getCarePlanOfPatientWithCurrentPractitioner(
      patientId!
    )

    try {
      const bundleObject: R4.IBundle = createBundleObjectForWelloMedications(
        appointment,
        encounter,
        medications,
        carePlanOfPatient
      )

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        bundleObject
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)

      if (relatedFhirDecodeRes._tag === 'Right') {
        if (relatedFhirDecodeRes.right) {
          addingState = {
            adding: false,
            additionSuccessful: true,

            error: false,
            errorMessage: '',
          }
          dispatch(
            addMedicationsSlice.actions.updateAddMedicationsStatus(addingState)
          )
        } else {
          const errorCreatePersonState: MedicationAddStatus = {
            adding: false,
            additionSuccessful: false,
            error: true,
            errorMessage: 'Error while adding medications',
          }
          dispatch(
            addMedicationsSlice.actions.updateAddMedicationsStatus(
              errorCreatePersonState
            )
          )
          return
        }
      } else {
        const errorCreatePersonState: MedicationAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while adding medications',
        }
        dispatch(
          addMedicationsSlice.actions.updateAddMedicationsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: MedicationAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding',
      }
      dispatch(
        addMedicationsSlice.actions.updateAddMedicationsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const addCDSMedicationsDetails =
  (
    appointment: FhirAppointmentDetail,
    medications: R4.IMedicationRequest[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: MedicationAddStatus = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(
      addMedicationsSlice.actions.updateAddMedicationsStatus(addingState)
    )
    const encounter: R4.IEncounter =
      getEncounterObjectForAppointment(appointment)

    try {
      const bundleObject: R4.IBundle = createBundleObjectForRequests(
        appointment,
        encounter,
        medications
      )

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        bundleObject
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)

      if (relatedFhirDecodeRes._tag === 'Right') {
        if (relatedFhirDecodeRes.right) {
          addingState = {
            adding: false,
            additionSuccessful: true,

            error: false,
            errorMessage: '',
          }
          dispatch(
            addMedicationsSlice.actions.updateAddMedicationsStatus(addingState)
          )
          dispatch(showSuccessAlert('Medications added successfully'))
        } else {
          const errorCreatePersonState: MedicationAddStatus = {
            adding: false,
            additionSuccessful: false,
            error: true,
            errorMessage: 'Error while allergy details',
          }
          dispatch(
            addMedicationsSlice.actions.updateAddMedicationsStatus(
              errorCreatePersonState
            )
          )
          return
        }
      } else {
        const errorCreatePersonState: MedicationAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while adding medications',
        }
        dispatch(
          addMedicationsSlice.actions.updateAddMedicationsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: MedicationAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding',
      }
      dispatch(
        addMedicationsSlice.actions.updateAddMedicationsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetAddMedicationsState = () => (dispatch: AppDispatch) => {
  dispatch(addMedicationsSlice.actions.resetMedicationsDetails(initialState))
}

export default addMedicationsSlice.reducer
