/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography, CircularProgress } from '@material-ui/core'

import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { Anthropometric } from 'models/fhirLabOrderDetails'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import ContentShimmer from 'react-content-shimmer'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import 'react-vis/dist/style.css'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { isMedicalServiceProvider } from 'services/userDetailsService'
import { getAccessToken } from 'utils/authHelpers'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  addObservationDetailsForSys,
  deleteObservationDetails,
  fetchAnthropometric,
  fetchAnthropometricForUpdate,
  getAddStatusForOCA,
  getObservationResourceForPostForSystemicExam,
  getObservationsOfSpecificCategoryInEncounter,
  updateComponentData,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { getVendorPartId } from 'utils/routes_helper'
import { OCARenderer } from 'wello-web-components'
import { ObservationOCAForViewOnly } from '../assessment/ayurvedaAssessment/doshaVikruthiAssemment/doshaAssessmentFromHistory'
import { ObesityCalculation } from './ObesityCalculation'

interface AyurvedaHistoryAndSubjectiveProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  onDataAdded: () => void
  isReadOnly?: boolean
}

export const ObesityObjective: React.FC<AyurvedaHistoryAndSubjectiveProps> = ({
  fhirAppointmentDetails,
  onDataAdded,
  isReadOnly = false,
}: AyurvedaHistoryAndSubjectiveProps) => {
  const { t } = useTranslation(['common'])
  const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())
  const [observations, setObservations] = useState<R4.IObservation[]>([])
  const dispatch = useDispatch()
  const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
    initial: true,
  })

  const [total, setTotal] = useState<Anthropometric>()
  const [totalForDisplay, setTotalForDisplay] = useState<Anthropometric>()
  const [scoreFetchingStatus, setScoreFetchingStatus] =
    useState<NetworkRequestStatus>({
      initial: true,
    })

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
  })
  const [displayFetchingStatus, setDisplayFetchingStatus] =
    useState<NetworkRequestStatus>({
      initial: true,
    })

  function getObservationDetails() {
    setFetchingStatus({
      requesting: true,
    })
    setTotalForDisplay(undefined)

    getObservationsOfSpecificCategoryInEncounter({
      encounterId: fhirAppointmentDetails.encounter!.id!,
      patient: fhirAppointmentDetails.patient,
      category: 'C0582103',
    })
      .then((e) => {
        if (e) {
          const data = e as R4.IObservation[]
          if (data.length > 0 && isReadOnly === false) {
            getScoreDetails(data[0])
          }

          if (data.length > 0 && isReadOnly !== undefined) {
            getScoreDetailsForDisplay()
          }

          setObservations(e as R4.IObservation[])
          setFetchingStatus({
            requestSuccess: true,
          })
        } else {
          setFetchingStatus({
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setFetchingStatus({
          requestError: true,
        })
      })
  }

  function getScoreDetails(obsData: R4.IObservation) {
    setScoreFetchingStatus({ requesting: true })
    fetchAnthropometricForUpdate(
      fhirAppointmentDetails.patient,
      'wp_anthropometric_measures',
      obsData
    )
      .then((e) => {
        if (e) {
          setTotal(e)
          updateComponent(obsData, e as Anthropometric)

          setScoreFetchingStatus({
            requestSuccess: true,
            requesting: false,
          })
        }
      })
      .catch((e) => {
        setScoreFetchingStatus({
          requestError: true,
          requesting: false,
        })
      })
  }

  function getScoreDetailsForDisplay() {
    setDisplayFetchingStatus({ requesting: true })
    fetchAnthropometric(
      fhirAppointmentDetails.patient,
      'wp_anthropometric_measures',
      ''
    )
      .then((e) => {
        if (e) {
          setTotalForDisplay(e)

          setDisplayFetchingStatus({
            requestSuccess: true,
            requesting: false,
          })
        }
      })
      .catch((e) => {
        setDisplayFetchingStatus({
          requestError: true,
          requesting: false,
        })
      })
  }

  function updateComponent(
    obsData: R4.IObservation,
    totalData: Anthropometric
  ) {
    setUpdateStatus({ requesting: true })
    updateComponentData(obsData, totalData)
      .then((e) => {
        if (e) {
          setUpdateStatus({
            requestSuccess: true,
            requesting: false,
          })
        }
      })
      .catch((e) => {
        setUpdateStatus({
          requestError: true,
          requesting: false,
        })
      })
  }

  useEffect(() => {
    getObservationDetails()
  }, [])

  return (
    <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
      {fetchingStatus.requesting && (
        <ContentShimmer
          rows={3}
          size={{
            width: '100%',
            height: '800',
          }}
        />
      )}
      {fetchingStatus.requestError && (
        <Typography variant='body1' color='error'>
          {t('common:errorWhileFetchingData')}
        </Typography>
      )}
      {/* <ObservationOCAForViewOnly
        fhirAppointmentDetails={fhirAppointmentDetails}
        category='objective'
        isForHistory={false}
        formName='Obesity Root Cause Assessment'
        observationCode='C1444322'
        isReadOnly={!isMedicalServiceProvider()}
      /> */}
      <Box py={1}>
        <Typography variant='h6'> General </Typography>
      </Box>{' '}
      {fetchingStatus.requestSuccess && (
        <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
          <OCARenderer
            OCAFormProviderBaseURL={`${
              process.env.REACT_APP_CC_WORKFLOW_URL
            }${getVendorPartId()}/ocaForms/forms`}
            OCAFormProviderHeader={{
              Authorization: `Bearer ${getAccessToken()}`,

              'Cache-Control': 'no-cache',
            }}
            isViewOnly={isReadOnly}
            showAdded={getAddStatusForOCA(
              observations,
              '52480-1',
              fhirAppointmentDetails.encounter
                ? fhirAppointmentDetails.encounter.id!
                : ''
            )}
            onSubmit={(
              observation: R4.IObservation,
              onSubmitSuccess?: (data: any) => void
            ) => {
              const updatedObservation =
                getObservationResourceForPostForSystemicExam(
                  fhirAppointmentDetails.patient.id!,
                  fhirAppointmentDetails.encounter!.id!,
                  [],
                  observation
                )

              addObservationDetailsForSys(updatedObservation).then((e) => {
                if (e === false) {
                  dispatch(
                    showErrorAlert('Error while updating observation details')
                  )
                } else if (
                  (e as R4.IObservation).resourceType === 'Observation'
                ) {
                  if (onSubmitSuccess) {
                    onSubmitSuccess(e)
                  }
                  getObservationDetails()
                  onDataAdded()
                  dispatch(
                    showSuccessAlert('Observation details updated successfully')
                  )
                } else {
                  dispatch(
                    showErrorAlert('Error while updating observation details')
                  )
                }
              })
            }}
            onEdit={(
              observation: R4.IObservation,
              onSubmitSuccess?: (data: any) => void
            ) => {
              const updatedObservation =
                getObservationResourceForPostForSystemicExam(
                  fhirAppointmentDetails.patient.id!,
                  fhirAppointmentDetails.encounter!.id!,
                  [],
                  observation,
                  false
                )

              addObservationDetailsForSys(updatedObservation).then((e) => {
                if (e === false) {
                  dispatch(
                    showErrorAlert('Error while updating observation details')
                  )
                } else if (
                  (e as R4.IObservation).resourceType === 'Observation'
                ) {
                  if (onSubmitSuccess) {
                    onSubmitSuccess(e)
                  }
                  getObservationDetails()
                  onDataAdded()
                  dispatch(
                    showSuccessAlert('Observation details updated successfully')
                  )
                } else {
                  dispatch(
                    showErrorAlert('Error while updating observation details')
                  )
                }
              })
            }}
            onDelete={(
              observation: R4.IObservation,
              onSuccess?: ((data: any) => void) | undefined,
              onError?: ((data: any) => void) | undefined
            ) => {}}
            formSection='soap'
            formCategory='objective'
            displayAsPopup={true}
            formName='Anthropometric Measurements_OCA'
            existingObservations={observations}
          />
        </Box>
      )}
      {displayFetchingStatus.requesting && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <CircularProgress size={15} />
        </Box>
      )}
      <Box display='flex' flexDirection='column' width='100%'>
        {totalForDisplay &&
          (totalForDisplay.waist_height_ratio.length > 0 ||
            totalForDisplay.waist_hip_ratio.length > 0 ||
            totalForDisplay.bmiVal.length > 0) && (
            <Box display='flex' flexDirection='row' width='100%'>
              <ObesityCalculation
                waistToHeight={
                  totalForDisplay ? totalForDisplay.waist_height_ratio : ''
                }
                waistToHip={
                  totalForDisplay ? totalForDisplay.waist_hip_ratio : ''
                }
                bmi={totalForDisplay ? totalForDisplay.bmiVal : ''}
              />
            </Box>
          )}
      </Box>
    </Box>
  )
}
