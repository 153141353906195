import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
// import { kDialogueBackground } from 'configs/styles/muiThemes'
import DeleteIcon from '@material-ui/icons/Delete'
import { kBackgroundPaper, kPrimaryDark } from 'configs/styles/muiThemes'
import { MembershipBeneficiary } from 'models/membershipDetails'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import {
  getNameFromHumanName,
  getTelecomFromContactPoints,
} from 'utils/fhirResourcesHelper'
import {
  getAllMembersOfMembershipPlan,
  getMemberPlanId,
  getPrimaryMemberPlanId,
  removeMemberFromPlan,
} from 'utils/membershipUtils'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'
import {
  AddNewMemberToMembershipForm,
  AddNewMemberToMembershipFormProps,
} from './addNewMemberToMembershipForm'
import { MembershipCheckOfPatient } from './memberShipCheckerOfPatient'

const useStyles = makeStyles({
  bigIndicator: {
    width: '10px',
  },
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      //   padding: 4,
    },
  },
})

interface MembershipBeneficiariesProperties {
  usersData: R4.ICoverage
}

export const MembershipPlanBeneficiaries: React.FC<MembershipBeneficiariesProperties> =
  ({ usersData }: MembershipBeneficiariesProperties) => {
    const classes = useStyles()
    const theme = useTheme()

    const [transactions, setTransactions] = React.useState<
      MembershipBeneficiary[]
    >([])

    const [showDeleteConfirmation, setShowDeleteConfirmation] =
      useState<ConfirmationDialogProps>({
        open: false,
      })

    const [showAddNewMembers, setShowAddNewMembers] =
      useState<AddNewMemberToMembershipFormProps>({
        open: false,
        mainCoverageResourceId: '',
        mainMemberPatientId: '',
        onClosed: () => {},
      })

    const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })

    const [
      updatingMembersModificationList,
      setUpdatingMemberModificationStatus,
    ] = useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })

    function fetchMembersOfPlan() {
      if (usersData?.id) {
        setFetchingStatus({
          requesting: true,
        })

        getAllMembersOfMembershipPlan(getMemberPlanId(usersData))
          .then((e) => {
            if ('status' in e) {
              setFetchingStatus({
                requesting: false,
                requestError: true,
              })
            } else {
              setFetchingStatus({
                requesting: false,
                requestSuccess: true,
              })

              setTransactions(e)
            }
          })
          .catch((e) => {
            setFetchingStatus({
              requesting: false,
              requestError: true,
            })
          })
      }
    }

    function removeMemberFromCurrentPlan(member: MembershipBeneficiary) {
      if (usersData?.id) {
        setUpdatingMemberModificationStatus({
          requesting: true,
        })

        removeMemberFromPlan(
          usersData?.id,
          getPrimaryMemberPlanId(usersData),
          member.beneficiary.id!
        )
          .then((e) => {
            if ('status' in e) {
              setUpdatingMemberModificationStatus({
                requesting: false,
                requestError: true,
              })
            } else {
              setUpdatingMemberModificationStatus({
                requesting: false,
                requestSuccess: true,
              })

              fetchMembersOfPlan()
            }
          })
          .catch((e) => {
            setUpdatingMemberModificationStatus({
              requesting: false,
              requestError: true,
            })
          })
      }
    }

    useEffect(() => {
      fetchMembersOfPlan()
    }, [])

    return (
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        width='100%'
      >
        <Box display='flex' flexDirection='column' overflow='auto' width='100%'>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            width='100%'
          >
            <Typography variant='h6' color='initial'>
              Members
            </Typography>
            {}
            <Box px={2}>
              {updatingMembersModificationList.requesting && (
                <CircularProgress size={18} color='inherit' />
              )}
              {fetchingStatus.requestSuccess &&
                transactions.length > 0 &&
                !updatingMembersModificationList.requesting &&
                transactions.length <= 10 && (
                  <Tooltip title='Add New Member'>
                    <IconButton
                      aria-label='close membership details'
                      size='small'
                      style={{
                        padding: 2,
                        margin: 0,
                        borderRadius: 4,
                        border: `1px solid ${kPrimaryDark}`,
                      }}
                      onClick={() => {
                        setShowAddNewMembers({
                          open: true,
                          mainCoverageResourceId: usersData?.id!,
                          mainMemberPatientId:
                            usersData?.policyHolder?.reference?.split('/')[1]!,
                          existingMembers: transactions.map(
                            (e) => e.beneficiary.id!
                          ),
                          maxNumbersOfMembers: 10 - transactions.length,
                          onClosed: () => {
                            setShowAddNewMembers({
                              open: false,
                              mainCoverageResourceId: '',
                              mainMemberPatientId: '',
                              onClosed: () => {},
                            })
                            fetchMembersOfPlan()
                          },
                        })
                      }}
                    >
                      <Add fontSize='small' color='primary' />
                    </IconButton>
                  </Tooltip>
                )}
            </Box>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            py={2}
            my={1}
            bgcolor={kBackgroundPaper}
            borderRadius={4}
          >
            {fetchingStatus.requesting && (
              <Typography variant='body1' color='initial'>
                Fetching Members...
              </Typography>
            )}
            {fetchingStatus.requestSuccess && transactions.length > 0 && (
              <Box display='flex' flexDirection='column'>
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='space-between'
                  alignItems='center'
                  width='100%'
                  px={2}
                  py={1}
                >
                  <Box width='30%'>
                    <Typography variant='subtitle1' color='initial'>
                      Name
                    </Typography>
                  </Box>

                  <Box width='15%'>
                    <Typography variant='subtitle1' color='initial'>
                      Relationship
                    </Typography>
                  </Box>
                  <Box width='15%'>
                    <Typography variant='subtitle1' color='initial'>
                      Phone
                    </Typography>
                  </Box>
                  <Box width='35%'>
                    <Typography variant='subtitle1' color='initial'>
                      Email
                    </Typography>
                  </Box>
                  <Box width='5%'>
                    <Typography variant='subtitle1' color='initial'>
                      {` `}
                    </Typography>
                  </Box>
                </Box>
                {transactions.map((e) => (
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='center'
                    width='100%'
                    px={2}
                    py={1}
                    key={e.coverage.id ?? ''}
                    borderTop={`1px solid ${theme.palette.divider}`}
                  >
                    <Box width='30%'>
                      <Typography variant='body1' color='initial'>
                        {`${getNameFromHumanName(e.beneficiary.name ?? [])} ${
                          e.type === 'primary' ? '(P)' : '(S)'
                        }`}
                      </Typography>
                    </Box>
                    <Box width='15%'>
                      <Typography variant='body1' color='initial'>
                        {e.coverage.relationship?.coding?.[0].display}
                      </Typography>
                    </Box>
                    <Box width='15%'>
                      <Typography variant='body1' color='initial'>
                        {getTelecomFromContactPoints(
                          e.beneficiary.telecom ?? [],
                          R4.ContactPointSystemKind._phone
                        )}
                      </Typography>
                    </Box>
                    <Box width='35%'>
                      <Typography
                        variant='body1'
                        color='initial'
                        noWrap={false}
                      >
                        {getTelecomFromContactPoints(
                          e.beneficiary.telecom ?? [],
                          R4.ContactPointSystemKind._email
                        )}
                      </Typography>
                    </Box>
                    <Box width='5%'>
                      {e.type === 'dependent' && (
                        <Tooltip title='Remove member'>
                          <IconButton
                            disabled={
                              updatingMembersModificationList.requesting
                            }
                            aria-label={`remove ${getNameFromHumanName(
                              e.beneficiary.name ?? []
                            )}`}
                            onClick={() => {
                              setShowDeleteConfirmation({
                                title: 'Remove Member',
                                open: true,
                                cancelActionButtonText: 'Cancel',
                                displayText: `Are you sure you want to remove ${getNameFromHumanName(
                                  e.beneficiary.name ?? []
                                )} from this plan?`,
                                continueActionButtonText: 'Remove',
                                onContinueActionClicked: () => {
                                  removeMemberFromCurrentPlan(e)
                                  setShowDeleteConfirmation({
                                    open: false,
                                  })
                                },
                                onCancelActionClicked: () => {
                                  setShowDeleteConfirmation({
                                    open: false,
                                  })
                                },
                              })
                            }}
                            size='small'
                          >
                            <DeleteIcon fontSize='small' />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            )}

            {fetchingStatus.requestSuccess && transactions.length === 0 && (
              <Box display='flex' flexDirection='column' px={2}>
                <Typography variant='body2' color='initial'>
                  Members list not avialable
                </Typography>
              </Box>
            )}
            {fetchingStatus.requestError && (
              <Box display='flex' flexDirection='column'>
                <Typography variant='body1' color='initial'>
                  {fetchingStatus.errorDescription ??
                    'Error while getting members'}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        {showDeleteConfirmation.open && (
          <ConfirmationDialog {...showDeleteConfirmation} />
        )}
        {showAddNewMembers.open && (
          <AddNewMemberToMembershipForm {...showAddNewMembers} />
        )}
      </Box>
    )
  }
