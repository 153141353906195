import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  getInputIdentifierValueByCodemOfInvitation,
  getOrderTypeCode,
  getProvenanceObjectForPartnerTask,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import { getExpandedServiceRequestFromBundlePartner } from 'utils/fhirResoureHelpers/partnerLabOrderHelper'
import { logger } from 'utils/logger'
import { requestOrdersForPartnerLogin } from '../orderSearchSlice/orderSearchSlice'
import { OrderManagementStatus } from './orderManagementSliceStatus'

const initialState: OrderManagementStatus = {
  fetchingOrderDetails: false,
  orderDetailsAvailable: false,
  noResultsAvailable: false,
  errorWhileFetchingOrderDetail: false,
  orderCancelled: false,
  orderDetailsUpdated: false,
  updatingOrderDetail: false,
}

const partnerLabManagementService = createSlice({
  name: 'partnerLabManagementService',
  initialState,

  reducers: {
    updatedStatus(state, action: PayloadAction<OrderManagementStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingOrderDetails = action.payload.fetchingOrderDetails
      state.orderDetailsAvailable = action.payload.orderDetailsAvailable
      state.orderDetail = action.payload.orderDetail
      state.errorReason = action.payload.errorReason
      state.errorWhileFetchingOrderDetail =
        action.payload.errorWhileFetchingOrderDetail
    },
  },
})

export const fetchOrderDetailsForPartner =
  (orderId: String, locationId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: OrderManagementStatus = { ...initialState }
    state.fetchingOrderDetails = true
    dispatch(partnerLabManagementService.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _include: 'ServiceRequest:patient',
        _revinclude: 'Task:focus',
        _id: orderId,
        '_revinclude:iterate': 'Provenance:target',
        '_include:iterate': [
          'ServiceRequest:performer',
          'ServiceRequest:instantiates-canonical',
          'PlanDefinition:service-billing',
          'Task:owner',
          'PractitionerRole:practitioner',
        ],
      }

      const response: any =
        await fhirClient.doGetResourceIncludeAndIncludeIterate(
          `/ServiceRequest/`,
          searchParameters
        )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        const currentState = { ...initialState }
        currentState.errorWhileFetchingOrderDetail = true
        dispatch(
          partnerLabManagementService.actions.updatedStatus(currentState)
        )
      } else {
        const appointmentResponse: R4.IBundle = resp.right
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          const fhirAppointments: FhirLabOrderDetail[] =
            getExpandedServiceRequestFromBundlePartner(
              appointmentResponse,
              locationId
            )
          logger.info('Orders length')
          const currentState = { ...initialState }
          currentState.orderDetailsAvailable = true
          currentState.orderDetail = fhirAppointments[0]
          dispatch(
            partnerLabManagementService.actions.updatedStatus(currentState)
          )
        } else {
          const currentState = { ...initialState }
          currentState.noResultsAvailable = true

          dispatch(
            partnerLabManagementService.actions.updatedStatus(currentState)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)

      const currentState = { ...initialState }
      currentState.errorWhileFetchingOrderDetail = true
      currentState.errorReason = 'Error while fetching orders'

      dispatch(partnerLabManagementService.actions.updatedStatus(currentState))
    }
  }

// export const requestCancellationOfOrder =
//   (
//     orderDetail: FhirLabOrderDetail,
//     rescheduleReason: R4.ICoding,
//     errorReason?: string
//   ): AppThunk =>
//   async (dispatch: AppDispatch) => {
//     const state: OrderManagementStatus = { ...initialState }
//     state.updatingOrderDetail = true
//     state.orderDetail = orderDetail
//     dispatch(orderManagementSlice.actions.updatedStatus(state))
//     try {
//       const bundleObject: R4.IBundle | undefined =
//         getCancelRequestTransactionObject(
//           orderDetail,
//           rescheduleReason,
//           errorReason
//         )
//       if (bundleObject) {
//         const fhirApi: FHIRApiClient = new FHIRApiClient()
//         const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)
//         const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
//           R4.RTTI_Bundle.decode(response)
//         if (relatedFhirDecodeRes._tag === 'Right') {
//           state.updatingOrderDetail = false
//           state.orderCancelled = true

//           dispatch(showSuccessAlert('Lab order cancellation is successful'))
//           dispatch(requestOrdersCountForToday())
//           dispatch(requestOrdersForTheDateRange([]))

//           dispatch(orderManagementSlice.actions.updatedStatus(state))
//           return
//         }
//       }

//       state.updatingOrderDetail = false
//       state.errorWhileFetchingOrderDetail = true
//       dispatch(showErrorAlert('Error while cancelling order. Please try later'))
//       dispatch(orderManagementSlice.actions.updatedStatus(state))
//       return
//     } catch (error) {
//       logger.error(error)
//       console.error(error)
//       const currentState = { ...initialState }
//       currentState.errorWhileFetchingOrderDetail = true
//       currentState.errorReason = 'Error while fetching orders'

//       dispatch(orderManagementSlice.actions.updatedStatus(currentState))
//     }
//   }

export const requestForUpdatePartnerLabOrder =
  (
    orderDetail: FhirLabOrderDetail,
    newStatus: R4.ICoding,
    locationId: string,
    statusReason?: string,
    docReferences?: string[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: OrderManagementStatus = { ...initialState }
    state.updatingOrderDetail = true
    state.orderDetail = orderDetail
    dispatch(partnerLabManagementService.actions.updatedStatus(state))
    try {
      const bundleObject: R4.IBundle | undefined =
        getUpdatePartnerOrderRequestTransactionObject(
          orderDetail,
          newStatus,
          statusReason,
          docReferences
        )
      if (bundleObject) {
        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          state.updatingOrderDetail = false
          state.orderCancelled = true

          dispatch(showSuccessAlert('Lab order updated is successfully'))
          dispatch(requestOrdersForPartnerLogin([], locationId))

          dispatch(partnerLabManagementService.actions.updatedStatus(state))
          return
        }
      }

      state.updatingOrderDetail = false
      state.errorWhileFetchingOrderDetail = true
      dispatch(
        showErrorAlert('Error while updating order status. Please try later')
      )
      dispatch(partnerLabManagementService.actions.updatedStatus(state))
      return
    } catch (error) {
      logger.error(error)
      console.error(error)
      const currentState = { ...initialState }
      currentState.errorWhileFetchingOrderDetail = true
      currentState.errorReason =
        'Error while updating order status. Please try later'

      dispatch(partnerLabManagementService.actions.updatedStatus(currentState))
    }
  }

function getUpdatePartnerOrderRequestTransactionObject(
  orderDetail: FhirLabOrderDetail,
  newStatus: R4.ICoding,
  changeReason?: string,
  docReferences?: string[]
): R4.IBundle | undefined {
  if (orderDetail.partnerLabTask) {
    const oldUpdatedServiceRequest: R4.IServiceRequest = {
      ...orderDetail.serviceRequest,
    }
    const oldAppointmentMatchString: string = `W/${JSON.stringify(
      oldUpdatedServiceRequest.meta?.versionId ?? ' '
    )}`

    const partnerTask: R4.ITask = { ...orderDetail.partnerLabTask }
    if (newStatus.code === 'sample_received') {
      partnerTask.status = R4.TaskStatusKind._received
    } else if (newStatus.code === 'processing_samples') {
      partnerTask.status = R4.TaskStatusKind._inProgress
    } else if (newStatus.code === 'processed_sample') {
      partnerTask.status = R4.TaskStatusKind._ready
    } else if (newStatus.code === 'reports_delivered') {
      partnerTask.status = R4.TaskStatusKind._completed
    }
    partnerTask.businessStatus = {
      text: newStatus.display,
      coding: [newStatus],
    }
    const outputData: R4.ITask_Output[] = []
    if (newStatus.code === 'reports_delivered' && docReferences) {
      for (let i = 0; i < docReferences.length; i++) {
        const data: R4.ITask_Output = {
          type: {
            coding: [
              {
                system:
                  'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
                code: 'partner-report',
              },
            ],
          },
          valueString: docReferences[i],
        }
        outputData.push(data)
      }

      partnerTask.output = outputData
    }
    const statusProvenance: R4.IProvenance = getProvenanceObjectForPartnerTask(
      orderDetail.partnerLabTask,
      newStatus
    )

    partnerTask.relevantHistory = [
      ...(partnerTask.relevantHistory ?? []),
      {
        reference: `${statusProvenance.resourceType}/${
          statusProvenance.id ?? ''
        }`,
      },
    ]

    const partnerTaskMatchString: string = `W/${JSON.stringify(
      partnerTask.meta?.versionId ?? ' '
    )}`

    if (changeReason) {
      partnerTask.description = changeReason
    }

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${statusProvenance.resourceType}/`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: statusProvenance.resourceType,
          },
          resource: statusProvenance,
        },
        {
          fullUrl: `${partnerTask.resourceType}/${partnerTask.id}`,
          request: {
            ifMatch: partnerTaskMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${partnerTask.resourceType}/${partnerTask.id}`,
          },
          resource: partnerTask,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

function getCancelRequestTransactionObject(
  orderDetail: FhirLabOrderDetail,
  rescheduleReason: R4.ICoding,
  errorReason?: string
): R4.IBundle | undefined {
  const currentUserDetails: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  if (orderDetail.task) {
    const oldUpdatedServiceRequest: R4.IServiceRequest = {
      ...orderDetail.serviceRequest,
    }
    const oldAppointmentMatchString: string = `W/${JSON.stringify(
      oldUpdatedServiceRequest.meta?.versionId ?? ' '
    )}`
    oldUpdatedServiceRequest.status = 'revoked'
    oldUpdatedServiceRequest.doNotPerform = true
    const mainTask: R4.ITask = { ...orderDetail.task }
    mainTask.status = R4.TaskStatusKind._cancelled
    mainTask.reasonCode = {
      coding: [rescheduleReason],
    }

    const mainProvenanceId: string = getUniqueTempId()
    const statusProvenance: R4.IProvenance = {
      id: mainProvenanceId,
      resourceType: 'Provenance',
      occurredDateTime: new Date().toISOString(),

      activity: {
        text: 'Cancelled',
        coding: [
          {
            system: 'http://hl7.org/fhir/task-status',
            code: 'cancelled',
            display: 'Cancelled',
          },
        ],
      },
      agent: [
        {
          type: {
            coding: [
              {
                code: 'enterer',
                display: 'Enterer',
                system:
                  'http://terminology.hl7.org/CodeSystem/provenance-participant-type',
              },
            ],
          },
          who: {
            id: currentUserDetails.id,
            reference:
              `${currentUserDetails.resourceType}/${currentUserDetails.id}` ??
              '',
          },
        },
      ],
      target: [
        {
          id: orderDetail.serviceRequest.id,
          reference:
            `${orderDetail.serviceRequest.resourceType}/${orderDetail.serviceRequest.id}` ??
            '',
        },
        {
          id: orderDetail.task.id,
          reference:
            `${orderDetail.task.resourceType}/${orderDetail.task.id}` ?? '',
        },
      ],
    }
    mainTask.relevantHistory = [
      ...(mainTask.relevantHistory ?? []),
      {
        reference: `${statusProvenance.resourceType}/${mainProvenanceId}`,
      },
    ]

    const mainTaskMatchString: string = `W/${JSON.stringify(
      mainTask.meta?.versionId ?? ' '
    )}`

    if (errorReason) {
      oldUpdatedServiceRequest.patientInstruction = errorReason
    }
    let modifiedOldSlot: R4.ISlot = {
      resourceType: 'Slot',
      schedule: {},
    }

    if (orderDetail.slot) {
      modifiedOldSlot = { ...orderDetail.slot }
      modifiedOldSlot.status = R4.SlotStatusKind._free
    }
    const matchOldSlotString: string = `W/${JSON.stringify(
      modifiedOldSlot.meta?.versionId ?? ' '
    )}`

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${statusProvenance.resourceType}/`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: statusProvenance.resourceType,
          },
          resource: statusProvenance,
        },
        {
          fullUrl: `${oldUpdatedServiceRequest.resourceType}/${oldUpdatedServiceRequest.id}`,
          request: {
            ifMatch: oldAppointmentMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedServiceRequest.resourceType}/${oldUpdatedServiceRequest.id}`,
          },
          resource: oldUpdatedServiceRequest,
        },

        {
          fullUrl: `${mainTask.resourceType}/${mainTask.id}`,
          request: {
            ifMatch: mainTaskMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${mainTask.resourceType}/${mainTask.id}`,
          },
          resource: mainTask,
        },
      ],
    }

    const code: string | undefined = getOrderTypeCode(orderDetail)
    if (code === 'home-sample-collection') {
      const subTaskProvenanceId: string = getUniqueTempId()
      if (orderDetail.homeServiceTask) {
        const subTask: R4.ITask = { ...orderDetail.homeServiceTask }
        subTask.status = R4.TaskStatusKind._cancelled
        subTask.reasonCode = {
          coding: [rescheduleReason],
        }

        const subtTaskStatusProvenance: R4.IProvenance = {
          id: subTaskProvenanceId,
          resourceType: 'Provenance',
          occurredDateTime: new Date().toISOString(),

          activity: {
            text: 'Cancelled',
            coding: [
              {
                system: 'http://hl7.org/fhir/task-status',
                code: 'cancelled',
                display: 'Cancelled',
              },
            ],
          },
          agent: [
            {
              type: {
                coding: [
                  {
                    code: 'enterer',
                    display: 'Enterer',
                    system:
                      'http://terminology.hl7.org/CodeSystem/provenance-participant-type',
                  },
                ],
              },
              who: {
                id: currentUserDetails.id,
                reference:
                  `${currentUserDetails.resourceType}/${currentUserDetails.id}` ??
                  '',
              },
            },
          ],
          target: [
            {
              id: orderDetail.homeServiceTask.id,
              reference:
                `${orderDetail.homeServiceTask.resourceType}/${orderDetail.homeServiceTask.id}` ??
                '',
            },
          ],
        }

        subTask.relevantHistory = [
          ...(subTask.relevantHistory ?? []),
          {
            reference: `${subtTaskStatusProvenance.resourceType}/${subTaskProvenanceId}`,
          },
        ]

        const subTaskMatchString: string = `W/${JSON.stringify(
          subTask.meta?.versionId ?? ' '
        )}`

        requestBundle.entry?.push({
          fullUrl: `${subtTaskStatusProvenance.resourceType}/`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: subtTaskStatusProvenance.resourceType,
          },
          resource: subtTaskStatusProvenance,
        })

        requestBundle.entry?.push({
          fullUrl: `${subTask.resourceType}/${subTask.id}`,
          request: {
            ifMatch: subTaskMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${subTask.resourceType}/${subTask.id}`,
          },
          resource: subTask,
        })

        const slotRef: R4.IReference | undefined =
          getInputIdentifierValueByCodemOfInvitation(
            orderDetail.homeServiceTask,
            'slot-reference'
          )?.valueReference

        if (slotRef) {
          requestBundle.entry?.push({
            fullUrl: slotRef.reference,
            request: {
              method: R4.Bundle_RequestMethodKind._patch,
              url: slotRef.reference,
            },
            resource: {
              resourceType: 'Parameters',
              parameter: [
                {
                  name: 'operation',
                  part: [
                    {
                      name: 'type',
                      valueCode: 'replace',
                    },
                    {
                      name: 'path',
                      valueCode: 'Slot.status',
                    },
                    {
                      name: 'value',
                      valueCode: 'free',
                    },
                  ],
                },
              ],
            },
          })
        }
      }
    }

    return requestBundle
  }

  return undefined
}

export default partnerLabManagementService.reducer
