import { R4 } from "@ahryman40k/ts-fhir-types";

export interface UnitActorSetupForms {
  currentForm: UnitActorSetupFormsType;
  practitioner?: R4.IPractitioner;
  practitionerRole?: R4.IPractitionerRole;
}

export enum UnitActorSetupFormsType {
  BasicForm,
  ClinicianForm,
  ClinicianFormPreview
}
