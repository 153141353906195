/* eslint-disable no-await-in-loop */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateWiseGraphData } from 'models/adminGraph/dateWiseGraphData'
import { GraphType } from 'models/adminGraph/graphType'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import { sleep } from 'utils/dateUtil'
import { getDateWiseDietPlansFromBundle } from 'utils/fhirResoureHelpers/ipdDietHelper'
import {
  getDataForDateWiseGraph,
  getDataForPayment,
  getDataForTypeWise,
} from 'utils/fhirResoureHelpers/orgAdminGraphHelper'
import { logger } from 'utils/logger'
import { DateWiseGraph } from './dateWiseGraphStatus'

const initialState: DateWiseGraph = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
}

const dateWiseCollectionSlice = createSlice({
  name: 'dateWiseCollectionSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<DateWiseGraph>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorReason = action.payload.errorReason
      state.collectionData = action.payload.collectionData
      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const requestForDateWiseCollectionGraph =
  (date: Date, endDate?: Date, type?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: DateWiseGraph = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(dateWiseCollectionSlice.actions.updatedStatus(state))
    try {
      const fhirClient: MasterFhirClient = new MasterFhirClient()

      const endDateData =
        type && endDate
          ? moment(endDate).endOf('day').format('YYYY-MM-DD')
          : moment(endDate || date)
              .endOf('month')
              .format('YYYY-MM-DD')

      const searchParameters: any = {
        _count: 500,
        outcome: 'complete',
      }
      const response: any = await fhirClient.doGetResource(
        `/PaymentReconciliation?payment-reconciliation-date=ge${moment(
          date
        ).format(
          'YYYY-MM-DD'
        )}&payment-reconciliation-date=le${endDateData}&_sort=payment-reconciliation-date&_revinclude=Invoice:invoice-paymentRef`,
        searchParameters
      )

      const resp: R4.IBundle = response as R4.IBundle
      if (!resp) {
        state.errorWhileSearchingAppointments = true
        state.searchingAppointments = false

        dispatch(dateWiseCollectionSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp
        const resultData: DateWiseGraphData[] = getDataForPayment(
          appointmentResponse,
          date
        )

        const finalGraphData: DateWiseGraphData[] = []
        if (resultData.length > 0) {
          for (let i = 0; i < resultData.length; i++) {
            const finalData: DateWiseGraphData =
              await getTotalNumbeOfAppointmentForDate(resultData[i])
            finalGraphData.push(finalData)
          }
          if (finalGraphData.length > 0) {
            state.resultsAvailable = true
            state.searchingAppointments = false
            state.noResultsAvailable = false
            state.errorReason = undefined
            state.collectionData = finalGraphData

            state.errorWhileSearchingAppointments = false
            dispatch(dateWiseCollectionSlice.actions.updatedStatus(state))
          } else {
            const errorSearchDoctor: DateWiseGraph = {
              searchingAppointments: false,
              errorWhileSearchingAppointments: false,
              resultsAvailable: false,
              noResultsAvailable: true,
            }
            dispatch(
              dateWiseCollectionSlice.actions.updatedStatus(errorSearchDoctor)
            )
          }
        } else {
          const errorSearchDoctor: DateWiseGraph = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            dateWiseCollectionSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: DateWiseGraph = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
      }
      dispatch(dateWiseCollectionSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export async function getTotalNumbeOfAppointmentForDate(
  graphData: DateWiseGraphData
): Promise<DateWiseGraphData> {
  let carePlan: R4.ICommunicationRequest | undefined

  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const graphWithIPD: DateWiseGraphData = {
    fullDate: graphData.fullDate,
    date: graphData.date,
    TotalDischarge: graphData.TotalDischarge,
    NoOfAppointments: 0,
    collection: graphData.collection,
  }

  const searchParameters: any = {
    _count: 500,
    status: 'active,completed',
    occurrence: `${moment(graphData.fullDate)
      .endOf('day')
      .utcOffset('+05:30')
      .toISOString()}`,
  }
  const response: any = await fhirClient.doGetResource(
    '/ServiceRequest?code=281685003,33022008,304903009&_sort=occurrence',
    searchParameters
  )

  const responseBundle: R4.IBundle = response as R4.IBundle
  if (responseBundle.total) {
    graphWithIPD.NoOfAppointments = responseBundle.total
  }

  return graphWithIPD
}

export default dateWiseCollectionSlice.reducer
