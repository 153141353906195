/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import {
  addAssesment,
  resetAddAssessmentState,
} from 'redux/ipd/assesmentmanagement/assesmentAddSearchSlice'
import { requestForAccessment } from 'redux/ipd/assesmentmanagement/assessmentSearch/assessmentSearchSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { isUnitAdmin } from 'services/userDetailsService'
import { getNotesData } from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { NoteDialog } from '../LeftMenu/TextDialog'

interface AssessmentCaptureProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  isAyurveda?: boolean
}

export const Assessment: React.FC<AssessmentCaptureProps> = ({
  fhirAppointmentDetails,
  isAyurveda,
}: AssessmentCaptureProps) => {
  const checkinDate = moment(
    fhirAppointmentDetails.mainServiceRequest.occurrencePeriod?.start
  ).format('YYYY-MM-DD')
  const checkOutDate = moment(
    fhirAppointmentDetails.mainServiceRequest.occurrencePeriod?.end
  ).format('YYYY-MM-DD')

  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const [summary, setSummary] = React.useState<string>()

  const [fullText, setFullText] = useState<string>('')
  const [showText, setShowText] = useState<boolean>(false)

  const addAssesmentSliceForIpd = useSelector(
    (state: RootState) => state.addAssesmentSliceForIpd
  )

  const assessmentSearchSliceIPD = useSelector(
    (state: RootState) => state.assessmentSearchSliceIPD
  )

  function resetValues() {
    setSummary(undefined)
  }

  useEffect(() => {
    if (fhirAppointmentDetails) {
      dispatch(
        requestForAccessment(
          fhirAppointmentDetails.patient,
          fhirAppointmentDetails.mainServiceRequest.id!
        )
      )
    }
    if (addAssesmentSliceForIpd.additionSuccessful) {
      setSummary(undefined)
      dispatch(resetAddAssessmentState())
    }
  }, [
    addAssesmentSliceForIpd.additionSuccessful,
    dispatch,
    fhirAppointmentDetails,
  ])

  useEffect(() => {
    if (addAssesmentSliceForIpd.additionSuccessful) {
      setSummary('')
      dispatch(resetAddAssessmentState())
    }
  }, [addAssesmentSliceForIpd.additionSuccessful])

  return (
    <Box
      style={{ paddingLeft: '4px' }}
      display='flex'
      flexDirection='row'
      marginY={1}
    >
      <Box width='100%'>
        <Grid container direction='column'>
          <Grid item>
            <ReactVisibilitySensor
              onChange={(isVisible) => {
                if (isVisible) {
                  dispatch(setSelectedSection({ selectedSection: 'diagnosis' }))
                }
              }}
            >
              <Grid container direction='column'>
                <Grid item id='diagnosis'>
                  <Grid
                    container
                    direction='row'
                    style={{ padding: 0, margin: 0 }}
                  >
                    <Box
                      sx={{ width: '100%' }}
                      display='flex'
                      alignItems='center'
                      flexDirection='row'
                      bgcolor='#999999'
                      minHeight={45}
                    >
                      <Box p={2}>
                        <Typography
                          variant='body1'
                          style={{ color: 'white', fontWeight: 600 }}
                        >
                          {' '}
                          Final Diagnosis
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                {(assessmentSearchSliceIPD.searchingConditions ||
                  addAssesmentSliceForIpd.adding) && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      align='center'
                    >
                      <CircularProgress size={25} />
                    </Typography>
                  </Box>
                )}

                {assessmentSearchSliceIPD.errorWhileSearchingProcedures && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      align='center'
                    >
                      Some Error has occurred
                    </Typography>
                  </Box>
                )}

                {assessmentSearchSliceIPD.noResultsAvailable && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='start'
                    width='100%'
                    alignContent='center'
                    py={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      align='left'
                    >
                      Add Final Diagnosis
                    </Typography>
                  </Box>
                )}

                {assessmentSearchSliceIPD.resultsAvailable &&
                  assessmentSearchSliceIPD.vitalsList &&
                  assessmentSearchSliceIPD.vitalsList.length === 0 && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='100%'
                      alignContent='center'
                    >
                      <Typography
                        variant='subtitle1'
                        color='initial'
                        align='center'
                      >
                        {isUnitAdmin() ||
                        fhirAppointmentDetails.mainServiceRequest.status ===
                          'completed'
                          ? 'No data available'
                          : 'Add Assessment'}
                      </Typography>
                    </Box>
                  )}

                {assessmentSearchSliceIPD.resultsAvailable &&
                  assessmentSearchSliceIPD.vitalsList &&
                  assessmentSearchSliceIPD.vitalsList.length > 0 && (
                    <Grid item xl>
                      <Box height='50%'>
                        <Grid
                          container
                          direction='row'
                          alignContent='space-between'
                          justify='space-between'
                        >
                          <Grid container direction='row' justify='flex-start'>
                            <Grid item xs={1}>
                              <Box
                                display='flex'
                                width='100%'
                                flexDirection='row'
                                justifyContent='flex-start'
                                py={0.5}
                              >
                                <Typography
                                  variant='body1'
                                  color='primary'
                                  style={{ fontWeight: 600 }}
                                >
                                  {' '}
                                  Date
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                display='flex'
                                width='100%'
                                flexDirection='row'
                                justifyContent='flex-start'
                              >
                                <Typography
                                  variant='body1'
                                  color='primary'
                                  style={{ fontWeight: 600 }}
                                >
                                  {' '}
                                  Diagnosis
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          {assessmentSearchSliceIPD.vitalsList.map(
                            (
                              assessmentData: R4.IObservation,
                              index: number
                            ) => (
                              <Box
                                width='100%'
                                bgcolor={
                                  index % 2 === 0 ? kPrimaryLight : undefined
                                }
                              >
                                <Grid
                                  container
                                  direction='row'
                                  justify='flex-start'
                                >
                                  <Grid item xs={1}>
                                    <Box
                                      display='flex'
                                      width='100%'
                                      flexDirection='row'
                                      justifyContent='flex-start'
                                      py={1}
                                      px={0.5}
                                    >
                                      {' '}
                                      <Typography
                                        variant='body2'
                                        color='primary'
                                      >
                                        {' '}
                                        {moment(assessmentData.issued).format(
                                          'DD-MM-YYYY'
                                        )}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <Box>
                                      <Typography
                                        variant='body2'
                                        color='initial'
                                        style={{
                                          paddingLeft: 2,
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          if (
                                            getNotesData(
                                              assessmentData.valueString ?? ''
                                            ).length > 60
                                          ) {
                                            setFullText(
                                              getNotesData(
                                                assessmentData.valueString ?? ''
                                              )
                                            )
                                            setShowText(true)
                                          }
                                        }}
                                      >
                                        {getNotesData(
                                          assessmentData.valueString ?? ''
                                        ).slice(0, 60)}
                                        {getNotesData(
                                          assessmentData.valueString ?? ''
                                        ).length > 60
                                          ? '...'
                                          : null}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            )
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  )}
                {!isUnitAdmin() &&
                  fhirAppointmentDetails.mainServiceRequest.status !==
                    'completed' && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      py={2}
                      width='95%'
                    >
                      <TextField
                        id='consult_assessment_summary_tf'
                        fullWidth
                        multiline={true}
                        value={summary}
                        variant='outlined'
                        InputProps={{
                          color: 'primary',

                          endAdornment: (
                            <InputAdornment position='end'>
                              {' '}
                              {addAssesmentSliceForIpd.adding === true && (
                                <CircularProgress size={20} />
                              )}
                              {addAssesmentSliceForIpd.adding !== true && (
                                <Button
                                  variant='contained'
                                  color='primary'
                                  disabled={
                                    summary === undefined ||
                                    summary.trim().length === 0 ||
                                    addAssesmentSliceForIpd.adding
                                  }
                                  onClick={() => {
                                    if (summary && summary.length > 0) {
                                      dispatch(
                                        addAssesment(
                                          fhirAppointmentDetails,
                                          summary,
                                          isAyurveda
                                        )
                                      )
                                    }
                                  }}
                                >
                                  {t('labelCommon:done')}
                                </Button>
                              )}
                            </InputAdornment>
                          ),
                        }}
                        onChange={(event) => {
                          setSummary(event.target.value)
                        }}
                      />
                    </Box>
                  )}
              </Grid>
            </ReactVisibilitySensor>
          </Grid>
        </Grid>
      </Box>
      <NoteDialog
        id='treatment_notes_tile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
        dialogText='Notes'
      />
    </Box>
  )
}
