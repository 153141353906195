import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { fetchIPDAppointmentDetails } from 'redux/clinic/ipdDetails/ipdAppointmentHandler/ipdAppointmentHandlerSlice'
import {
  isDoctor,
  isGre,
  isPhysiotherapist,
  isTherapist,
  isUnitAdmin,
} from 'services/userDetailsService'
import {
  getAddressOfPatient,
  getAddressOfPatientForOPDAppointment,
  getIdentifierValueBySystem,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getProfilePicPatient,
  titleCase,
} from 'utils/fhirResourcesHelper'
import { isDischargeInitiated } from 'utils/fhirResoureHelpers/invoice_helper'
import {
  getAdmissionBy,
  getCheckOutTIme,
  getDispositionTime,
  getIpdAdmitTIme,
  getOPDStartDate,
  getRoomNo,
} from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { GenderAndAgeTileWithoutBack } from 'views/components/patients/patient_profile/genderAgeTilleWithoutBackGround'
import { ViewBillingOfIPD } from '../view_billing'

interface Props {
  ipdAppointmentDetails: FhirClinicIpdDetails
  isOdd?: boolean
  onViewClicked?: () => void
  dense?: boolean
  isSelected?: boolean
  onManageClicked: () => void
}

const options = ['Start IPD']

const ITEM_HEIGHT = 48

export const OpdAppointmentTile: React.FC<Props> = ({
  ipdAppointmentDetails,
  isOdd = false,
  onViewClicked,
  dense = false,
  isSelected = false,
  onManageClicked,
}: Props) => {
  const { t } = useTranslation()
  const [showBilling, setBillingHandler] = React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const dispatch = useDispatch()
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()

  return (
    <Box width='100%'>
      <Card
        elevation={isOdd ? 0 : 1}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 0,
          backgroundColor: isSelected
            ? ipdAppointmentDetails.serviceRequest.status === 'draft'
              ? 'lightgrey'
              : kPrimaryLight
            : isOdd
            ? ipdAppointmentDetails.serviceRequest.status === 'draft'
              ? 'lightgrey'
              : '#FFFFFFAF'
            : ipdAppointmentDetails.serviceRequest.status === 'draft'
            ? 'lightgrey'
            : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 0,
          }}
        >
          {dense && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              onClick={(e) => {
                if (onViewClicked) onViewClicked()
              }}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                px={1}
              >
                <Avatar
                  alt={getNameOfPatient(ipdAppointmentDetails.patient) ?? ''}
                  src={
                    getProfilePicPatient(ipdAppointmentDetails.patient) ?? ''
                  }
                />
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                flexGrow={1}
              >
                <Typography
                  variant='subtitle1'
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                >
                  {`${getNameOfPatient(ipdAppointmentDetails.patient) ?? ''} ${
                    getLastNameOfPatient(ipdAppointmentDetails.patient) ?? ''
                  }`}
                </Typography>
                <Typography variant='body2' color='initial'>
                  {getAddressOfPatient(
                    ipdAppointmentDetails.patient.address ?? []
                  )}
                </Typography>

                <Typography variant='subtitle2' color='initial'>
                  {getIdentifierValueBySystem(
                    ipdAppointmentDetails.serviceRequest.identifier ?? [],
                    'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                  )}
                </Typography>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                >
                  {ipdAppointmentDetails.type === 'ipd' && (
                    <Typography variant='body2' color='initial'>
                      DOA {`${getIpdAdmitTIme(ipdAppointmentDetails)}`}
                    </Typography>
                  )}
                  {ipdAppointmentDetails.type === 'opd' && (
                    <Typography variant='body2' color='initial'>
                      DOA {`${getOPDStartDate(ipdAppointmentDetails)}`}
                    </Typography>
                  )}

                  {/* {ipdAppointmentDetails.type === 'opd' && (
                    <Typography variant='body2' color='initial'>
                      {`${getOPDStartDate(ipdAppointmentDetails)}`}
                    </Typography>
                  )} */}

                  {ipdAppointmentDetails.type === 'ipd' && (
                    <Typography variant='body2' color='initial'>
                      {`${getRoomNo(ipdAppointmentDetails)}`}
                    </Typography>
                  )}
                  {ipdAppointmentDetails.type === 'ipd' && (
                    <Typography variant='body2' color='initial'>
                      DOD {` ${getCheckOutTIme(ipdAppointmentDetails)}`}
                    </Typography>
                  )}

                  {ipdAppointmentDetails.appType.length > 0 && (
                    <Typography variant='body2' color='initial'>
                      {ipdAppointmentDetails.appType}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          )}
          {!dense && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              alignContent='center'
              p={0.5}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='15%'
              >
                {ipdAppointmentDetails.type === 'opd' &&
                  ipdAppointmentDetails.serviceRequest.status !== 'draft' && (
                    <Typography variant='body2' color='initial'>
                      DOA {`${getOPDStartDate(ipdAppointmentDetails)}`}
                    </Typography>
                  )}

                {ipdAppointmentDetails.type === 'opd' &&
                  ipdAppointmentDetails.serviceRequest.status === 'draft' && (
                    <Typography
                      variant='body2'
                      style={{
                        textTransform: 'capitalize',
                        color: '#00000042',
                      }}
                    >
                      DOA {`${getOPDStartDate(ipdAppointmentDetails)}`}
                    </Typography>
                  )}

                {/* {ipdAppointmentDetails.type === 'opd' && (
                  <Typography variant='body2' color='initial'></Typography>
                )} */}

                {/* {ipdAppointmentDetails.type === 'opd' && (
                    <Typography variant='body2' color='initial'>
                      {`${getOPDStartTIme(ipdAppointmentDetails)}`} -{' '}
                      {`${getAppointmentEndTIme(ipdAppointmentDetails)}`}
                    </Typography>
                  )} */}
                {ipdAppointmentDetails.appType.length > 0 &&
                  ipdAppointmentDetails.serviceRequest.status !== 'draft' && (
                    <Typography variant='body2' color='initial'>
                      {ipdAppointmentDetails.appType}
                    </Typography>
                  )}
                {ipdAppointmentDetails.appType.length > 0 &&
                  ipdAppointmentDetails.serviceRequest.status === 'draft' && (
                    <Typography
                      variant='body2'
                      style={{
                        color: '#00000042',
                      }}
                    >
                      {ipdAppointmentDetails.appType}
                    </Typography>
                  )}
                {ipdAppointmentDetails.type === 'ipd' && (
                  <Typography variant='body2' color='initial'>
                    DOA {` ${getIpdAdmitTIme(ipdAppointmentDetails)}`}
                  </Typography>
                )}
                {/* {ipdAppointmentDetails.type === 'ipd' && (
                  <Typography variant='body2' color='initial'></Typography>
                )} */}
                {ipdAppointmentDetails.type === 'ipd' && (
                  <Typography variant='body2' color='initial'>
                    {`${getRoomNo(ipdAppointmentDetails)}`}
                  </Typography>
                )}
                {ipdAppointmentDetails.type === 'ipd' && (
                  <Typography variant='body2' color='initial'>
                    DOD {` ${getCheckOutTIme(ipdAppointmentDetails)}`}
                  </Typography>
                )}
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignContent='flex-start'
                px={1}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-start'
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                  >
                    <Avatar
                      alt={
                        getNameOfPatient(ipdAppointmentDetails.patient) ?? ''
                      }
                      src={
                        getProfilePicPatient(ipdAppointmentDetails.patient) ??
                        ''
                      }
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='17%'
              >
                {getMiddleNameOfPatient(ipdAppointmentDetails.patient).length >
                  0 && (
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    style={{
                      textTransform: 'capitalize',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    {getNameOfPatient(ipdAppointmentDetails.patient)}{' '}
                    {getMiddleNameOfPatient(ipdAppointmentDetails.patient) ??
                      ''}{' '}
                    {getLastNameOfPatient(ipdAppointmentDetails.patient)}
                  </Typography>
                )}

                {getMiddleNameOfPatient(ipdAppointmentDetails.patient)
                  .length === 0 && (
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    style={{
                      textTransform: 'capitalize',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    {getNameOfPatient(ipdAppointmentDetails.patient)}{' '}
                    {getLastNameOfPatient(ipdAppointmentDetails.patient)}
                  </Typography>
                )}

                <Box
                  display='flex'
                  flexDirection='column'
                  width='45%'
                  justifyContent='flex-start'
                >
                  <GenderAndAgeTileWithoutBack
                    patient={ipdAppointmentDetails.patient}
                    status={false}
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                >
                  <Typography
                    variant='body2'
                    color='initial'
                    style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
                  >
                    {getAddressOfPatientForOPDAppointment(
                      ipdAppointmentDetails.patient.address ?? []
                    )}
                  </Typography>
                </Box>

                {/* {ipdDetails.serviceRequest.status === 'draft' && (
                    <Typography
                      variant='body2'
                      color='initial'
                      style={{
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-line',
                        color: '#00000042',
                      }}
                    >
                      {getAddressOfPatient(ipdDetails.patient.address ?? [])}
                    </Typography>
                  )} */}
              </Box>

              {/* <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='10%'
              >
                <GenderAndAgeTile patient={ipdAppointmentDetails.patient} />
              </Box> */}
              {isTherapist() && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  alignContent='flex-start'
                  width='45%'
                  paddingLeft={5}
                />
              )}

              {!isTherapist() && (
                <Box
                  display='flex'
                  flexDirection={
                    isDischargeInitiated(ipdAppointmentDetails.task!)
                      ? 'row'
                      : 'column'
                  }
                  justifyContent='center'
                  alignContent='flex-start'
                  alignItems={
                    isDischargeInitiated(ipdAppointmentDetails.task!)
                      ? 'center'
                      : undefined
                  }
                  width='45%'
                  paddingLeft={5}
                >
                  {ipdAppointmentDetails.serviceRequest.status === 'draft' && (
                    <Box display='flex' flexDirection='row'>
                      <Typography
                        variant='body1'
                        color='primary'
                        style={{ fontWeight: 600 }}
                      >
                        Processing
                      </Typography>
                      {/* <Typography
                        variant='body1'
                        color='primary'
                        style={{ paddingLeft: 2 }}
                      >
                        {getAdmissionBy(ipdAppointmentDetails)}
                      </Typography> */}
                    </Box>
                  )}
                  {ipdAppointmentDetails.serviceRequest.status !== 'draft' && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      alignContent='flex-start'
                    >
                      <Box display='flex' flexDirection='row'>
                        <Typography
                          variant='body1'
                          color='initial'
                          style={{ fontWeight: 600 }}
                        >
                          Admission By :
                        </Typography>
                        <Typography
                          variant='body1'
                          color='initial'
                          style={{ paddingLeft: 2 }}
                        >
                          {getAdmissionBy(ipdAppointmentDetails)}
                        </Typography>
                      </Box>
                      <Typography variant='subtitle2' color='initial'>
                        {getIdentifierValueBySystem(
                          ipdAppointmentDetails.serviceRequest.identifier ?? [],
                          'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                        )}
                        {/* {`${ipdAppointmentDetails!.serviceRequest.id ?? ''}`} */}
                      </Typography>
                    </Box>
                  )}
                  {ipdAppointmentDetails.serviceRequest.status &&
                    ipdAppointmentDetails.serviceRequest.status === 'active' &&
                    isDischargeInitiated(ipdAppointmentDetails.task!) && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        flexGrow={1}
                        alignContent='center'
                        justifyContent='center'
                        alignItems='center'
                        px={2}
                      >
                        <Typography variant='subtitle2' color='initial'>
                          Discharge Initiated
                        </Typography>
                      </Box>
                    )}
                  {ipdAppointmentDetails.serviceRequest.status &&
                    ipdAppointmentDetails.serviceRequest.status !== 'active' &&
                    ipdAppointmentDetails.serviceRequest.status !== 'draft' && (
                      <Box display='flex' flexDirection='column'>
                        <Box display='flex' flexDirection='row'>
                          <Typography
                            variant='body1'
                            color='initial'
                            style={{ fontWeight: 600 }}
                          >
                            Disposition By :{' '}
                          </Typography>
                          <Typography
                            variant='body1'
                            color='initial'
                            style={{ paddingLeft: 2 }}
                          >
                            {titleCase(
                              ipdAppointmentDetails.dispositionByDetails
                            )}
                          </Typography>
                        </Box>

                        <Box display='flex' flexDirection='row' width='100%'>
                          <Typography
                            variant='body1'
                            color='initial'
                            style={{ fontWeight: 600 }}
                          >
                            Disposition Time :
                          </Typography>
                          <Typography
                            variant='body1'
                            color='initial'
                            style={{ paddingLeft: 2 }}
                          >
                            {getDispositionTime(ipdAppointmentDetails)}
                          </Typography>
                        </Box>

                        <Box display='flex' flexDirection='row'>
                          <Typography
                            variant='body1'
                            color='initial'
                            style={{ fontWeight: 600 }}
                          >
                            Notes :
                          </Typography>
                          {ipdAppointmentDetails.notes && (
                            <Typography
                              variant='body1'
                              color='initial'
                              style={{ paddingLeft: 2, cursor: 'pointer' }}
                              //   component=''
                              onClick={() => {
                                if (
                                  ipdAppointmentDetails.notes &&
                                  ipdAppointmentDetails.notes.length > 60
                                ) {
                                  setFullText(ipdAppointmentDetails.notes)
                                  setShowText(true)
                                }
                              }}
                            >
                              {ipdAppointmentDetails.notes.slice(0, 60)}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    )}
                </Box>
              )}

              {ipdAppointmentDetails.serviceRequest.status === 'draft' && (
                <Box width='15%' />
              )}
              {!isUnitAdmin() &&
                ipdAppointmentDetails.serviceRequest.status !== 'draft' &&
                ipdAppointmentDetails.serviceRequest.status !== 'completed' &&
                !isGre() && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='5%'
                  >
                    <Tooltip
                      title={isGre() ? 'Feedback' : 'Manage OPD Details'}
                    >
                      <IconButton
                        aria-label='btn_ord_cancel'
                        color='primary'
                        disabled={
                          isGre()
                            ? ipdAppointmentDetails.task
                              ? ipdAppointmentDetails.task.businessStatus!
                                  .coding
                                ? ipdAppointmentDetails.task.businessStatus!
                                    .coding[0].code !== 'discharged'
                                : false
                              : false
                            : false
                        }
                        style={{ padding: 0 }}
                        onClick={() => {
                          onManageClicked()
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/editIcon.png`}
                          alt='view'
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              {ipdAppointmentDetails.serviceRequest.status === 'completed' &&
                isGre() && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='5%'
                  >
                    <Tooltip
                      title={isGre() ? 'Feedback' : 'Manage IPD Details'}
                      id='manage_ipd_tooltip'
                    >
                      <IconButton
                        aria-label='btn_ord_cancel'
                        color='primary'
                        id='manage_ipd'
                        disabled={
                          isGre()
                            ? ipdAppointmentDetails.task
                              ? ipdAppointmentDetails.task.businessStatus!
                                  .coding
                                ? ipdAppointmentDetails.task.businessStatus!
                                    .coding[0].code !== 'discharged'
                                : false
                              : false
                            : false
                        }
                        style={{ padding: 0 }}
                        onClick={() => {
                          onManageClicked()
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/editIcon.png`}
                          alt='view'
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              {!isPhysiotherapist() &&
                !isTherapist() &&
                !isDoctor() &&
                ipdAppointmentDetails.serviceRequest.status !== 'draft' && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='5%'
                  >
                    <Tooltip title='View Billing'>
                      <IconButton
                        aria-label='btn_view_billing'
                        color='inherit'
                        disabled={
                          ipdAppointmentDetails.serviceRequest.status ===
                          'draft'
                        }
                        onClick={() => {
                          dispatch(
                            fetchIPDAppointmentDetails(
                              ipdAppointmentDetails.serviceRequest.id!
                            )
                          )
                          setBillingHandler(true)
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/bill.ico`}
                          height='50%'
                          width='50%'
                          alt='view'
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              {!isTherapist() &&
                ipdAppointmentDetails.serviceRequest.status !== 'draft' && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='5%'
                  >
                    <Tooltip title='View OPD Details'>
                      <IconButton
                        aria-label='btn_ord_reschedule'
                        color='primary'
                        disabled={
                          ipdAppointmentDetails.serviceRequest.status ===
                          'draft'
                        }
                        onClick={() => {
                          if (onViewClicked) onViewClicked()
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/view.ico`}
                          alt='view'
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
            </Box>
          )}
        </CardContent>
      </Card>

      <NoteDialog
        id='notesTile_opd'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
      />
      {showBilling && (
        <ViewBillingOfIPD
          ipdDetails={ipdAppointmentDetails}
          onClose={() => {
            setBillingHandler(false)
          }}
          open={showBilling}
        />
      )}
    </Box>
  )
}
