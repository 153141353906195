import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { CloseOutlined } from '@material-ui/icons'
import { FhirPartnerLabDetails } from 'models/fhirPartnerLabDetails'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPartnerLabDetails } from 'redux/collectionCenter/partnerLabManagementSlice'
import { RootState } from 'redux/rootReducer'
import { getNumberValueExtensionsOfUrl } from 'utils/fhirResourcesHelper'
import { EditPartnerLabHandler } from 'views/components/administration/actors/edit_partner_lab'
import { WelloTabs } from 'views/components/LeftMenu/WelloTabs'
import { WelloFormItemLabel } from 'wello-web-components'
import { ParterLabTile } from './parterLabTile'
import { PartnerLabOrders } from './partnerLabOrders'
import { PartnerLabTestListView } from './partnerLabTestListview'
import { PrimaryContactTile } from './primaryContactTile'

interface Props {
  partnerLabDetailsData: FhirPartnerLabDetails
  isOdd?: boolean
  onCloseClicked?: () => void
  dense?: boolean
}

export const PartnerLabDetails: React.FC<Props> = ({
  partnerLabDetailsData,
  isOdd = false,
  onCloseClicked,
  dense = false,
}: Props) => {
  const partnerLabManagementSlice = useSelector(
    (state: RootState) => state.partnerLabManagementSlice
  )
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showCancelHandler, setShowCancelHandler] = useState<boolean>(false)
  const [partnerDetails, setPartnerDetails] = useState<FhirPartnerLabDetails>(
    partnerLabDetailsData
  )
  const [partnerLabRole, setPartnerLabRole] = useState<PractitionerWithRole>()
  const menu = ['DETAILS', 'LIST OF TESTS ', 'TRANSACTION HISTORY']
  const [selectedTab, setSelectedTab] = React.useState(menu[0])
  const [showEditPopUp, setShowEditPopup] = React.useState(false)

  useEffect(() => {
    dispatch(fetchPartnerLabDetails(partnerDetails.id ?? ''))
    return () => {}
  }, [])

  useEffect(() => {
    if (partnerLabManagementSlice.partnerDetailsAvailable) {
      if (partnerLabManagementSlice.partnerDetails)
        setPartnerLabRole(partnerLabManagementSlice.partnerDetails)
    }
    return () => {}
  }, [partnerLabManagementSlice.partnerDetailsAvailable])

  const handleChangeTab = (selected: string) => {
    setSelectedTab(selected)
  }
  return (
    <Box width='100%' px={1}>
      <Card
        elevation={0}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 8,
          backgroundColor: isOdd ? '#FFFFFFAF' : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 4,
            paddingLeft: 16,
            paddingRight: 16,
            border: 1,
          }}
        >
          <Box display='flex' flexDirection='column' width='100%'>
            <Box>
              {partnerLabManagementSlice.fetchingPartnerDetails && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>

            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              alignContent='center'
              alignItems='center'
            >
              <Box paddingTop={4}>
                <WelloTabs
                  preSelectedTab={selectedTab}
                  onTabChange={(e) => handleChangeTab(e)}
                  menu={menu}
                />
              </Box>
              {partnerLabManagementSlice.partnerDetailsAvailable &&
                partnerLabManagementSlice.partnerDetails && (
                  <Box display='flex' flexDirection='row'>
                    {selectedTab === menu[0] && (
                      <Box display='flex' flexDirection='row'>
                        <Box paddingTop={1.2}>
                          <Tooltip title='Edit'>
                            <IconButton
                              aria-label='btn_ord_reschedule'
                              color='primary'
                              onClick={() => {
                                setShowEditPopup(true)
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/editIcon.png`}
                                alt='edit'
                                height={20}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Box paddingTop={1.2}>
                          <Tooltip title='Delete'>
                            <IconButton
                              aria-label='btn_ord_cancel'
                              color='primary'
                              onClick={() => {
                                setShowCancelHandler(true)
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/remove.png`}
                                alt='remove'
                                height={20}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    )}
                    <Box paddingTop={1.2}>
                      <Tooltip title={t('close') ?? 'Re-Schedule'}>
                        <IconButton
                          aria-label='btn_ord_detail_menu'
                          onClick={onCloseClicked}
                        >
                          <CloseOutlined color='primary' />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                )}
            </Box>

            <Divider
              orientation='horizontal'
              variant='fullWidth'
              flexItem
              style={{ width: '100%', height: '2px' }}
              color='primary'
            />
            {partnerLabManagementSlice.partnerDetailsAvailable &&
              partnerLabManagementSlice.partnerDetails && (
                <Box>
                  {selectedTab === menu[0] && (
                    <Box>
                      <Box display='flex' flexDirection='row' width='100%'>
                        <Box display='flex' flexDirection='column' width='60%'>
                          <Box display='flex' flexDirection='row' width='100%'>
                            {partnerLabRole?.locationObject && (
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <WelloFormItemLabel title='LAB INFORMATION:' />

                                <Box
                                  border={1}
                                  borderRadius={4}
                                  borderColor='lightGrey'
                                >
                                  <ParterLabTile
                                    partnerLabLocation={
                                      partnerLabManagementSlice.partnerDetails
                                        .locationObject
                                    }
                                  />
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>

                      <Box display='flex' flexDirection='row' width='100%'>
                        <Box display='flex' flexDirection='column' width='60%'>
                          <Box display='flex' flexDirection='row' width='100%'>
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                            >
                              <WelloFormItemLabel title='PRIMARY CONTACT:' />
                              <Box
                                border={1}
                                borderRadius={4}
                                borderColor='lightGrey'
                              >
                                <PrimaryContactTile
                                  primaryContactDetails={
                                    partnerLabManagementSlice.partnerDetails
                                      .roleObject
                                  }
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      {partnerLabRole?.locationObject &&
                        partnerLabManagementSlice.partnerDetails && (
                          <Box display='flex' paddingTop={1} paddingLeft={0.3}>
                            <WelloFormItemLabel title='Partner Lab Commission (in %) : ' />
                            <Box
                              display='flex'
                              paddingTop={1}
                              paddingLeft={0.3}
                            >
                              <Typography
                                component='span'
                                variant='subtitle2'
                                color='textPrimary'
                              >
                                {partnerLabRole.locationObject
                                  ? getNumberValueExtensionsOfUrl(
                                      partnerLabRole.locationObject.extension,
                                      'http://wellopathy.com/fhir/india/core/CodeSystem/commission'
                                    )
                                  : 0}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                    </Box>
                  )}
                </Box>
              )}
            {partnerLabManagementSlice.partnerDetailsAvailable &&
              partnerLabManagementSlice.partnerDetails && (
                <Box>
                  {selectedTab === menu[1] && (
                    <Box p={2}>
                      <Box display='flex' flexDirection='row' width='100%'>
                        <Box display='flex' flexDirection='column' width='100%'>
                          <Box display='flex' flexDirection='row' width='100%'>
                            {!partnerLabRole?.locationObject && (
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <Box>
                                  <Typography
                                    component='span'
                                    variant='subtitle2'
                                    color='textPrimary'
                                  >
                                    Please update partner lab info in Details
                                    tab
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                            {partnerLabManagementSlice.partnerDetails
                              .locationObject && (
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <Box
                                  border={1}
                                  borderRadius={4}
                                  borderColor='lightGrey'
                                >
                                  <PartnerLabTestListView
                                    partnerLabLocation={
                                      partnerLabManagementSlice.partnerDetails
                                        .locationObject
                                    }
                                  />
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
            {partnerLabManagementSlice.partnerDetailsAvailable &&
              partnerLabManagementSlice.partnerDetails && (
                <Box>
                  {selectedTab === menu[2] && (
                    <Box p={2}>
                      <Box display='flex' flexDirection='row' width='100%'>
                        <Box display='flex' flexDirection='column' width='100%'>
                          <Box display='flex' flexDirection='row' width='100%'>
                            {partnerLabRole?.locationObject && (
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <Box
                                  border={1}
                                  borderRadius={4}
                                  borderColor='lightGrey'
                                >
                                  <PartnerLabOrders
                                    partnerLabLocation={
                                      partnerLabManagementSlice.partnerDetails
                                        .locationObject
                                    }
                                    partnerLabCommission={
                                      partnerDetails.commission_Data
                                    }
                                    partnerLabUser={
                                      partnerLabManagementSlice.partnerDetails
                                        .roleObject
                                    }
                                  />
                                </Box>
                              </Box>
                            )}

                            {!partnerLabRole?.locationObject && (
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <Box>
                                  <Typography
                                    component='span'
                                    variant='subtitle2'
                                    color='textPrimary'
                                  >
                                    Please update partner lab info in Details
                                    tab
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
          </Box>
        </CardContent>
      </Card>
      <EditPartnerLabHandler
        open={showEditPopUp}
        onBackClick={() => {
          setShowEditPopup(false)
        }}
        location={partnerLabRole?.locationObject}
        practitionerRole={partnerLabRole?.roleObject}
        onContinueClick={() => {
          setShowEditPopup(false)
        }}
      />
    </Box>
  )
}
