import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { FileInput } from '../../LeftMenu/FileInput'

interface Props {
  fileType: string
  fileValidationType: string[]
  onFileChange: (file: any) => void
  labTestCount?: number
}

const useStyles = makeStyles((theme) => ({
  paperHeight: {
    height: 36,
  },
  container: {
    transform: 'translateY(-100%)',
  },
  test: {
    border: '1px dashed #4543D9',
  },
}))
export const UploadZone: React.FC<Props> = ({
  fileType,
  fileValidationType,
  onFileChange,
  labTestCount,
}) => {
  const { t } = useTranslation()
  const [errorFile, setErrorFile] = React.useState<any>()
  const [selectedFiles, setSelectedFiles] = React.useState<any>()
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const dispatch = useDispatch()
  const classes = useStyles()

  const dragOver = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const dragEnter = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const dragLeave = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const fileDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    const { files } = e.dataTransfer
    if (files.length) {
      handleFiles(files)
    }
  }
  const validateFile = (files: any) => {
    const fileResult: string[] = []
    for (let i = 0; i < files.length; i++) {
      if (fileValidationType.indexOf(files[i].type) === -1) {
        fileResult.push('false')
      } else {
        fileResult.push('true')
      }
    }
    return fileResult
  }

  const handleFiles = (files: any) => {
    const validFiles = validateFile(files)
    if (validFiles.includes('false')) {
      files[0].invalid = true
      setErrorFile(files[0])
      setSelectedFiles(files)
      setErrorMessage('Please enter a valid file format.')
    } else if (labTestCount && labTestCount > 0) {
      if (files.length > labTestCount!) {
        // setErrorMessage(`Please select ${labTestCount!} file.`)
        dispatch(showErrorAlert(`Please select ${labTestCount!} file.`))
      } else {
        setSelectedFiles(files)
        onFileChange(files)
      }
    } else {
      setSelectedFiles(files)
      onFileChange(files)
    }
  }

  const filesSelected = (e: any) => {
    handleFiles(e.target.files)
  }

  return (
    <div>
      <Box
        flexDirection='column'
        display='flex'
        justifyContent='center'
        className={classes.test}
        alignItems='center'
      >
        <Box padding={2} justifyContent='center'>
          <Box paddingLeft={14}>
            <img
              src={`${process.env.PUBLIC_URL}/upload.png`}
              height={24}
              width={24}
              alt=''
            />
          </Box>
          <div
            className='drop-container'
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
          >
            <Typography
              variant='body1'
              style={{ textAlign: 'center', cursor: 'pointer' }}
              color='primary'
            >
              {t('labelCommon:dragFiles')}
            </Typography>
          </div>
          <Typography variant='body1' style={{ textAlign: 'center' }}>
            {t('OR')}
          </Typography>
          <Typography
            variant='body1'
            style={{
              textAlign: 'center',
              textDecoration: 'underline',
            }}
          >
            <FileInput
              style={{ display: 'none' }}
              name='photoUri'
              type={fileType}
              fileType={t('labelCommon:selectPdfFiles')}
              onChange={filesSelected}
              multiple={true}
            />
          </Typography>
          <Box
            padding={2}
            flexDirection='column'
            display='flex'
            justifyContent='center'
          >
            {errorFile
              ? errorFile.invalid
                ? dispatch(showErrorAlert(errorMessage))
                : ''
              : ''}
          </Box>
        </Box>
      </Box>
      <Box padding={1}>
        {selectedFiles !== undefined && (
          <Typography variant='subtitle1' color='primary'>
            {selectedFiles.length} {t('labelCommon:filesSelected')}
          </Typography>
        )}
      </Box>
    </div>
  )
}
