import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

interface Props {
  price: number
  days: number
}
export const ConsultationPriceDisplay: React.FC<Props> = ({
  price,
  days,
}: Props) => {
  const { t } = useTranslation()

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while displaying price</div>
          <pre>{error.message}</pre>
        </div>
      )}
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        width='100%'
      >
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          width='100%'
        >
          <Box display='flex' flexDirection='column'>
            <Typography variant='body2' color='initial'>
              {`INR ${price.toFixed(2)} `}
            </Typography>
          </Box>
        </Box>
      </Box>
    </ErrorBoundary>
  )
}
