import { R4 } from '@ahryman40k/ts-fhir-types'
import { AxiosResponse } from 'axios'

import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { EnrolCient } from 'services/EnrrolmentClient'
import { addAndGetEncounterDetailsOfAppointment } from 'utils/appointment_handle/cds_recommendations_util'
import { getAddedDate } from 'utils/dateUtil'
import {
  getCarePlanByIdCurrentState,
  getCarePlanOfPatient,
} from './careplan_utils'

export interface NetworkRequestResponseStatus {
  response: 'valid' | 'error'
  responseData: any
  responseHttpStatus?: string
  errorReason?: string
}

export async function addLabOrderActivity(): Promise<NetworkRequestResponseStatus> {
  try {
    const data = {
      eventType: 'offline',
      paymentMeta: {
        eventType: 'update-payment',
      },
    }
    const enRolClient: EnrolCient = new EnrolCient()
    const networkResponse: AxiosResponse = await enRolClient.doPostRequest(
      ``,
      data
    )
    if (networkResponse) {
      return {
        response: 'valid',
        responseData: networkResponse,
        responseHttpStatus: networkResponse.toString(),
      }
    }
  } catch (error) {
    console.error(error)
    return {
      response: 'error',
      responseData: error,
      errorReason: 'Exception',
    }
  } finally {
  }
  return {
    response: 'error',
    responseData: undefined,
    errorReason: 'Error response',
  }
}

export async function addRecurringAppointment(
  patientId: string,
  encounterId: string,
  carePlanId: string,
  timing: R4.ITiming,
  slotTiming: string
): Promise<NetworkRequestResponseStatus> {
  try {
    const data = {
      patientId,
      slotId: slotTiming,
      appointmentServiceType: 'online',
      encounterId,
      isDoctorAppointmentTask: true,
      carePlanId,
      timings: timing,
    }
    const enRolClient: EnrolCient = new EnrolCient()
    const networkResponse: AxiosResponse = await enRolClient.doPostRequest(
      `doctor-appointment`,
      data
    )
    if (networkResponse) {
      return {
        response: 'valid',
        responseData: networkResponse,
        responseHttpStatus: networkResponse.toString(),
      }
    }
  } catch (error) {
    console.error(error)
    return {
      response: 'error',
      responseData: error,
      errorReason: 'Exception',
    }
  } finally {
  }
  return {
    response: 'error',
    responseData: undefined,
    errorReason: 'Error response',
  }
}

export async function handleAddCarePlanActivities(
  patientId: string,
  activitiesToBeAdded: R4.ICarePlan_Detail[],
  inputEncounterId?: string,
  appointment?: FhirAppointmentDetail,
  carePlanUrl?: string,
  carePlanId?: string
): Promise<NetworkRequestResponseStatus[]> {
  try {
    const carePlanOfPatient: R4.ICarePlan | undefined =
      carePlanId !== undefined
        ? await getCarePlanByIdCurrentState(carePlanId)
        : await getCarePlanOfPatient(patientId, carePlanUrl ?? '', true)
    let encounterId = inputEncounterId

    if (carePlanOfPatient) {
      if (encounterId === undefined || encounterId?.length === 0) {
        const encounter: R4.IEncounter | undefined =
          await addAndGetEncounterDetailsOfAppointment(appointment!)

        if (encounter) {
          encounterId = encounter.id!
        }
      }
      if (encounterId !== undefined) {
        const res = await Promise.all(
          activitiesToBeAdded.map(async (docRef, i) => {
            console.log('----------activity ref-----------', docRef)
            /* if (docRef.kind === 'ServiceRequest') {
              const resp = await addLabOrderActivity()
              return resp
            } */
            if (docRef.kind === 'Appointment') {
              const resp = await addRecurringAppointment(
                patientId,
                encounterId!,
                carePlanOfPatient.id!,
                docRef.scheduledTiming!,
                getAddedDate(
                  docRef.scheduledTiming?.repeat?.period ?? 15
                ).toISOString()
              )

              return resp
            }

            return {
              response: 'valid',
              responseData: {},
              responseHttpStatus: 200,
            }
          })
        )

        const resToReturn = res
          .filter((e) => e !== undefined)
          .map((e) => e as unknown as NetworkRequestResponseStatus)

        return resToReturn
      }

      return [
        {
          response: 'error',
          responseData: 'Encounter is not available',
          errorReason: 'Encounter is not available',
        },
      ]
    }

    return [
      {
        response: 'error',
        responseData: 'Patient is not able subscribe to Care Plan',
        errorReason: 'Patient is not able subscribe to Care Plan',
      },
    ]
  } catch (error) {
    return [
      {
        response: 'error',
        responseData: error,
        errorReason: 'Error in deploying adding lab status',
      },
    ]
  }
}
