import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getServiceRequest } from 'utils/appointment_handle/cds_recommendations_util'
import {
  getCarePlanByIdCurrentState,
  getCarePlanOfPatient,
  hasPlanOfferedInOrg,
} from 'utils/careplan_utils/careplan_utils'
import { sleep } from 'utils/dateUtil'
import { logger } from 'utils/logger'
import { KriyasAdditionStatus } from './kriyasAdditionState'

const initialState: KriyasAdditionStatus = {
  addingInstruction: false,
  additionSuccessful: false,
  noResultsAvailable: false,
  errorWhileAdding: false,
}

const kriyasAdditionSlice = createSlice({
  name: 'kriyasAdditionSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<KriyasAdditionStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.addingInstruction = action.payload.addingInstruction
      state.additionSuccessful = action.payload.additionSuccessful
      state.successMessage = action.payload.successMessage
      state.errorReason = action.payload.errorReason
      state.errorWhileAdding = action.payload.errorWhileAdding
    },
  },
})

export const addKriyasRecommendations =
  (
    patient: R4.IPatient,
    communications: R4.IServiceRequest[],
    encounterId: string,
    carePlanUrl?: string,
    carePlanId?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: KriyasAdditionStatus = {
      ...initialState,
    }
    state.addingInstruction = true
    dispatch(kriyasAdditionSlice.actions.updatedStatus(state))
    try {
      const hasPlanDef = true
      if (hasPlanDef) {
        const carePlanOfPatient: R4.ICarePlan | undefined =
          carePlanId !== undefined
            ? await getCarePlanByIdCurrentState(carePlanId)
            : await getCarePlanOfPatient(patient.id!, carePlanUrl ?? '', true)
        if (carePlanOfPatient) {
          const responses: AxiosResponse[] | undefined =
            await addKriyasApiCalls(
              communications,
              patient,
              encounterId,
              carePlanOfPatient!.id!
            )
          console.log('.........responses............', responses)
          if (responses) {
            let isSuccess: boolean = false
            responses.forEach((e) => {
              if (e.status === 200 || e.status === 201) {
                isSuccess = true
              }
            })

            if (isSuccess) {
              state.addingInstruction = false
              state.additionSuccessful = true
              state.errorWhileAdding = false
              state.successMessage = 'Kriyas have been updated in care portal.'
              state.errorReason = undefined
              dispatch(kriyasAdditionSlice.actions.updatedStatus(state))
              dispatch(showSuccessAlert(state.successMessage!))
            } else {
              let isPartial: boolean = false
              responses.forEach((e) => {
                if (e.status === 200 || e.status === 201) {
                  isPartial = true
                }
              })
              if (isPartial) {
                state.addingInstruction = false
                state.additionSuccessful = false
                state.errorWhileAdding = true
                state.successMessage = undefined
                state.errorReason =
                  'Added few of the Kriyas. Check Plan sections'
                dispatch<any>(showErrorAlert(state.errorReason!))
                dispatch(kriyasAdditionSlice.actions.updatedStatus(state))
              } else {
                state.addingInstruction = false
                state.additionSuccessful = false
                state.errorWhileAdding = true
                state.successMessage = undefined
                state.errorReason =
                  'Error while adding Kriya details. Try later'
                dispatch(showErrorAlert(state.errorReason!))
                dispatch(kriyasAdditionSlice.actions.updatedStatus(state))
              }
            }
          } else {
            state.addingInstruction = false
            state.additionSuccessful = false
            state.errorWhileAdding = true
            state.successMessage = undefined
            state.errorReason = 'Error while adding Kriya details. Try later'
            dispatch<any>(showErrorAlert(state.errorReason!))
            dispatch(kriyasAdditionSlice.actions.updatedStatus(state))
          }
        } else {
          state.addingInstruction = false
          state.additionSuccessful = false
          state.errorWhileAdding = true
          state.successMessage = undefined
          state.errorReason = 'User is not subscribed to Care Plan'
          dispatch<any>(showErrorAlert(state.errorReason!))
          dispatch(kriyasAdditionSlice.actions.updatedStatus(state))
        }
      } else {
        const bundle: R4.IBundle = {
          resourceType: 'Bundle',
          type: R4.BundleTypeKind._transaction,
          entry: communications.map(
            (e) =>
              ({
                request: {
                  method: R4.Bundle_RequestMethodKind._post,
                  url: 'ServiceRequest',
                },
                resource: getServiceRequest(
                  e,
                  encounterId ?? '',
                  patient.id!,
                  carePlanUrl
                ),
              } as R4.IBundle_Entry)
          ),
        }

        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response = await fhirApi.doCreateFHIRTransaction('', bundle)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          state.addingInstruction = false
          state.additionSuccessful = true
          state.errorWhileAdding = false
          state.successMessage = 'Kriyas have been updated in care portal.'
          state.errorReason = undefined
          dispatch(kriyasAdditionSlice.actions.updatedStatus(state))
          dispatch(showSuccessAlert(state.successMessage!))
        } else {
          state.addingInstruction = false
          state.additionSuccessful = false
          state.errorWhileAdding = true
          state.successMessage = undefined
          state.errorReason = 'Error while adding kriyas. Try later'
          dispatch(showErrorAlert(state.errorReason!))
          dispatch(kriyasAdditionSlice.actions.updatedStatus(state))
        }
      }
    } catch (error) {
      console.error(error)
      const resState: KriyasAdditionStatus = {
        addingInstruction: false,
        errorWhileAdding: true,
        additionSuccessful: false,
        noResultsAvailable: false,
        errorReason: (error as AxiosError).response?.data,
      }
      dispatch<any>(showErrorAlert(state.errorReason!))
      dispatch(kriyasAdditionSlice.actions.updatedStatus(resState))
    }
  }

export const resetKriyaAdditionState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: KriyasAdditionStatus = {
      ...initialState,
    }

    dispatch(kriyasAdditionSlice.actions.updatedStatus(state))
  }

export const addKriyasApiCalls = async (
  kriyaDetails: R4.IServiceRequest[],
  patient: R4.IPatient,
  encounterId: string,
  careplanId: string
): Promise<AxiosResponse[] | undefined> => {
  try {
    const body: any = {
      patientId: patient.id,
      carePlanId: careplanId,
      encounterId: encounterId!,
    }
    const enRolClient: EnrolCient = new EnrolCient()
    let responses: any[] = []
    responses = await Promise.all(
      kriyaDetails.map(async (e) => {
        body.serviceRequest = e
        const resp: AxiosResponse =
          await enRolClient.doCreateEnrolmentFlowRequestWithRawResponse(
            'cpg-recommendations/kriyas/',
            body
          )
        return resp
      })
    )
    /* kriyaDetails.forEach(async (e) => {
      body.serviceRequest = e

      const resp: AxiosResponse =
        await enRolClient.doCreateEnrolmentFlowRequest(
          'cpg-recommendations/kriyas/',
          body
        )

      responses.push(resp)
    }) */
    logger.info('...........success............')
    logger.info(responses)
    return responses
  } catch (error) {
    logger.info('_______error________________')
    logger.info(error)
  }
  return undefined
}

export default kriyasAdditionSlice.reducer
