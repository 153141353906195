import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React from 'react'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getAddressOfPatient,
  getAddressOfPatientForOPDAppointment,
  getAgeOfAttendant,
  getAgeOfPatientData,
  getCodeOfSystemFromCodableConceptList,
  getEmailOfIRelatedPerson,
  getEmailOfPatient,
  getFirstNameOfRelatedPerson,
  getGenderOfPatient,
  getGenderOfRelatedPerson,
  getIdentifierValueBySystem,
  getIdentifierValueBySystemType,
  getLastNameOfPatient,
  getLastNameOfRelatedPerson,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getTelecomOfAttendant,
  getTelecomOfPatient,
  getValueAttachmentFromExtension,
  titleCase,
} from 'utils/fhirResourcesHelper'
import { getRoomNoFromLocation } from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { AttachmentImage } from '../common/attachementImageViewer'
import OverflowTypography from '../consultation/plan/ayurveda/overflowTypography'

interface PatientProps {
  patient: R4.IPatient
  ipdData?: FhirActiveIPDDetailsForMedicalRole
  roomData?: string
  location?: R4.ILocation
}

export const PatientDetailsTile: React.FC<PatientProps> = ({
  patient,
  ipdData,
  roomData,
  location,
}: PatientProps) => {
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Grid container direction='row' spacing={1}>
        {/* ------------------------------------------------------------------------------- */}

        <Box display='flex' flexDirection='column' width='100%' flexGrow={1}>
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <Grid item xs={3}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    />
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ipdData && ipdData.attendant ? 5 : 9}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      Patient Details
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            {ipdData && ipdData.attendant && (
              <Grid item xs={4}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Attendant Details
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: 'white',
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      Name
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ipdData && ipdData.attendant ? 5 : 9}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {`${getNameOfPatient(patient) ?? ''} ${
                        getMiddleNameOfPatient(patient) ?? ''
                      }  ${getLastNameOfPatient(patient) ?? ''}`}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            {ipdData && ipdData.attendant && (
              <Grid item xs={4}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {`${
                          getFirstNameOfRelatedPerson(ipdData.attendant) ?? ''
                        } ${
                          getLastNameOfRelatedPerson(ipdData.attendant) ?? ''
                        }`}
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',
  
                          width: '2px',
                        }}
                      />
                    </Box> */}
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      Gender
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ipdData && ipdData.attendant ? 5 : 9}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {titleCase(getGenderOfPatient(patient))}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            {ipdData && ipdData.attendant && (
              <Grid item xs={4}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {getGenderOfRelatedPerson(ipdData.attendant)}
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',
  
                          width: '2px',
                        }}
                      />
                    </Box> */}
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: 'white',
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      Age
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ipdData && ipdData.attendant ? 5 : 9}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {getAgeOfPatientData(patient)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            {ipdData && ipdData.attendant && (
              <Grid item xs={4}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {getAgeOfAttendant(ipdData.attendant)}
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',
  
                          width: '2px',
                        }}
                      />
                    </Box> */}
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      Phone
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ipdData && ipdData.attendant ? 5 : 9}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {getTelecomOfPatient(
                        patient,
                        R4.ContactPointSystemKind._phone
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            {ipdData && ipdData.attendant && (
              <Grid item xs={4}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {getTelecomOfAttendant(ipdData.attendant)}
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',
  
                          width: '2px',
                        }}
                      />
                    </Box> */}
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: 'white',
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      Email
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ipdData && ipdData.attendant ? 5 : 9}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {getEmailOfPatient(patient)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            {ipdData && ipdData.attendant && (
              <Grid item xs={4}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {getEmailOfIRelatedPerson(ipdData.attendant)}
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',
  
                          width: '2px',
                        }}
                      />
                    </Box> */}
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      Address
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ipdData && ipdData.attendant ? 5 : 9}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <OverflowTypography
                      text={getAddressOfPatient(patient.address ?? []) ?? ''}
                      typographyProps={{
                        variant: 'subtitle2',
                        color: 'initial',
                        noWrap: true,
                      }}
                    />

                    {/* {location && (
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {`${getRoomNoFromLocation(location)}`}
                      </Typography>
                    )} */}
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            {ipdData && ipdData.attendant && (
              <Grid item xs={4}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {roomData}
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',
  
                          width: '2px',
                        }}
                      />
                    </Box> */}
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: 'white',
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      ID Proof Type
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ipdData && ipdData.attendant ? 5 : 9}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {titleCase(
                        getIdentifierValueBySystemType(
                          patient.identifier ?? [],
                          'address-proof'
                        )!
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            {ipdData && ipdData.attendant && (
              <Grid item xs={4}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {titleCase(
                          getIdentifierValueBySystemType(
                            ipdData.attendant!.identifier ?? [],
                            'related-address-proof'
                          )!
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',
  
                          width: '2px',
                        }}
                      />
                    </Box> */}
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      ID Proof Number
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ipdData && ipdData.attendant ? 5 : 9}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {
                        getIdentifierValueBySystem(
                          patient.identifier ?? [],
                          'address-proof'
                        )!
                      }
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            {ipdData && ipdData.attendant && (
              <Grid item xs={4}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {
                          getIdentifierValueBySystem(
                            ipdData.attendant!.identifier ?? [],
                            'related-address-proof'
                          )!
                        }
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.2}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',
  
                          width: '2px',
                        }}
                      />
                    </Box> */}
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: 'white',
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      ID Proof
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '100px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ipdData && ipdData.attendant ? 5 : 9}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    width='100%'
                  >
                    {' '}
                    {getIdentifierValueBySystemType(
                      patient.identifier ?? [],
                      'address-proof'
                    )!.length > 0 &&
                      getValueAttachmentFromExtension(
                        patient.extension,
                        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
                      ) === '0' && (
                        <Box
                          display='flex'
                          width='50%'
                          flexDirection='row'
                          justifyContent='flex-start'
                          py={0.5}
                        >
                          <Box
                            px={1}
                            display='flex'
                            width='100%'
                            flexDirection='row'
                          >
                            <AttachmentImage
                              contentType='image/jpeg'
                              decodingRequired={false}
                              imagePath="Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front').value.as(Attachment)"
                              resourceId={patient.id ?? ''}
                              resourceType='Patient'
                            />
                          </Box>
                        </Box>
                      )}
                    {getIdentifierValueBySystemType(
                      patient.identifier ?? [],
                      'address-proof'
                    )!.length > 0 &&
                      getValueAttachmentFromExtension(
                        patient.extension,
                        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
                      ) === '0' && (
                        <Box
                          display='flex'
                          width='50%'
                          flexDirection='row'
                          justifyContent='flex-start'
                          paddingLeft={1}
                          paddingRight={0.3}
                          py={0.3}
                        >
                          <AttachmentImage
                            contentType='image/jpeg'
                            decodingRequired={false}
                            imagePath="Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-back').value.as(Attachment)"
                            resourceId={patient.id ?? ''}
                            resourceType='Patient'
                          />
                        </Box>
                      )}
                    {getIdentifierValueBySystemType(
                      patient.identifier ?? [],
                      'address-proof'
                    )!.length > 0 &&
                      getValueAttachmentFromExtension(
                        patient.extension,
                        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
                      ) === '1' && (
                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          justifyContent='flex-start'
                          py={1}
                        >
                          <Box
                            px={1}
                            display='flex'
                            width='50%'
                            flexDirection='row'
                            height={50}
                          >
                            <AttachmentImage
                              contentType='image/jpeg'
                              decodingRequired={false}
                              imagePath="Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both').value.as(Attachment)"
                              resourceId={patient.id ?? ''}
                              resourceType='Patient'
                            />
                          </Box>
                        </Box>
                      )}
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '100px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            {ipdData && ipdData.attendant && (
              <Grid item xs={4}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box display='flex' flexDirection='column' width='100%'>
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      width='100%'
                    >
                      {' '}
                      {getIdentifierValueBySystemType(
                        ipdData.attendant!.identifier ?? [],
                        'related-address-proof'
                      )!.length > 0 &&
                        ipdData.attendant!.extension &&
                        ipdData.attendant!.extension.length > 1 && (
                          <Box
                            display='flex'
                            width='50%'
                            flexDirection='row'
                            justifyContent='flex-start'
                            py={0.5}
                          >
                            <Box
                              px={1}
                              display='flex'
                              width='100%'
                              flexDirection='row'
                            >
                              <AttachmentImage
                                contentType='image/jpeg'
                                decodingRequired={false}
                                imagePath="RelatedPerson.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front').value.as(Attachment)"
                                resourceId={ipdData.attendant.id ?? ''}
                                resourceType='RelatedPerson'
                                attendant={true}
                              />
                            </Box>
                          </Box>
                        )}
                      {getIdentifierValueBySystemType(
                        ipdData.attendant!.identifier ?? [],
                        'related-address-proof'
                      )!.length > 0 &&
                        ipdData.attendant!.extension &&
                        ipdData.attendant!.extension.length > 1 && (
                          <Box
                            display='flex'
                            width='50%'
                            flexDirection='row'
                            justifyContent='flex-start'
                            paddingLeft={1}
                            paddingRight={0.3}
                            py={0.3}
                          >
                            <AttachmentImage
                              contentType='image/jpeg'
                              decodingRequired={false}
                              imagePath="RelatedPerson.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-back').value.as(Attachment)"
                              resourceId={ipdData.attendant.id ?? ''}
                              resourceType='RelatedPerson'
                              attendant={true}
                            />
                          </Box>
                        )}
                      {getIdentifierValueBySystemType(
                        ipdData.attendant!.identifier ?? [],
                        'related-address-proof'
                      )!.length > 0 &&
                        ipdData.attendant!.extension &&
                        ipdData.attendant!.extension.length === 1 && (
                          <Box
                            display='flex'
                            width='100%'
                            flexDirection='row'
                            justifyContent='flex-start'
                            py={1}
                          >
                            <Box
                              px={1}
                              display='flex'
                              width='50%'
                              flexDirection='row'
                              height={50}
                            >
                              <AttachmentImage
                                contentType='image/jpeg'
                                decodingRequired={false}
                                imagePath="RelatedPerson.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both').value.as(Attachment)"
                                resourceId={ipdData.attendant.id ?? ''}
                                resourceType='RelatedPerson'
                              />
                            </Box>
                          </Box>
                        )}
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    width='2%'
                    justifyContent='flex-end'
                    flexGrow
                  >
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '100px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Box>
  )
}
