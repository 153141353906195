import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { delay } from 'lodash'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { LabTestReport, Panel1 } from 'models/labTestReport'
import { ObsRecord } from 'models/obsRecords'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { requestLabTestDetailsDetailsOfPatient } from 'redux/consultation/labTestOPD/labDetailSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { fToC } from 'utils/appointment_handle/vitals_util'
import { cbcpanel } from 'utils/constants'
import { sleep } from 'utils/dateUtil'
import { getEncounterObjectForAppointment } from 'utils/fhirResoureHelpers/appointmentHelpers'
import {
  getDiagnosticReportOPD,
  getVitalsObservationObject,
  getVitalsObservationObjectForIPD,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { LabTestAddStatus } from './addAddLabTestTypes'

const initialState: LabTestAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addLabTestSliceOPD = createSlice({
  name: 'addLabTestSliceOPD',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<LabTestAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addedVitalsBundle = action.payload.addedVitalsBundle
    },

    resetAddVitalsDetails(state, action: PayloadAction<LabTestAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addedVitalsBundle = initialState.addedVitalsBundle
    },
  },
})

export const addLabTestOPD =
  (
    reportDate: Date,
    appointment: FhirAppointmentDetail,
    cbcPanelData: ObsRecord[],
    lipidPanelDat: ObsRecord[],
    lft: ObsRecord[],
    rft: ObsRecord[],
    tftData: ObsRecord[],
    urineDataPanel: ObsRecord[],
    homa: ObsRecord[],
    dexaScan: ObsRecord[],
    serumLytes: ObsRecord[],
    fastingBloodSugar?: number,
    hba1c?: number,
    homo?: number,
    pp?: number,
    serumC?: number,
    serum?: number,
    serumU?: number,
    eos?: number,
    eosabs?: number,
    nrbc?: number,
    lr5?: number
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: LabTestAddStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(addLabTestSliceOPD.actions.updateAddVitalsStatus(addingState))

    try {
      const bundleObject: R4.IBundle = createBundleObjectForObservations(
        reportDate,
        appointment,
        cbcPanelData,
        lipidPanelDat,
        lft,
        rft,
        tftData,
        urineDataPanel,
        homa,
        dexaScan,
        serumLytes,
        fastingBloodSugar,
        hba1c,
        homo,
        pp,
        serumC,
        serum,
        serumU,
        eos,
        eosabs,
        nrbc,
        lr5
      )

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        bundleObject
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        await sleep(5000)

        dispatch(
          requestLabTestDetailsDetailsOfPatient(
            appointment.patient,
            appointment.appointment.id!
          )
        )
        dispatch(showSuccessAlert('Lab Test(s) Added Successfully'))

        dispatch(addLabTestSliceOPD.actions.updateAddVitalsStatus(addingState))
      } else {
        const errorCreatePersonState: LabTestAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while creating patient',
        }
        dispatch(
          addLabTestSliceOPD.actions.updateAddVitalsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: LabTestAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding vitals',
      }
      dispatch(
        addLabTestSliceOPD.actions.updateAddVitalsStatus(errorCreatePersonState)
      )
    }
  }

export const resetAddLabTestSlice = () => (dispatch: AppDispatch) => {
  dispatch(addLabTestSliceOPD.actions.resetAddVitalsDetails(initialState))
}

function createBundleObjectForObservations(
  reportDate: Date,
  appointment: FhirAppointmentDetail,
  cbcPanelData: ObsRecord[],
  lipidPanelData: ObsRecord[],
  lft: ObsRecord[],
  rft: ObsRecord[],
  tftData: ObsRecord[],
  urineDataPanel: ObsRecord[],
  homa: ObsRecord[],
  dexaScan: ObsRecord[],
  serumLytes: ObsRecord[],
  fastingBloodSugar?: number,
  hba1c?: number,
  homo?: number,
  pp?: number,
  serumC?: number,
  serum?: number,
  serumU?: number,
  eos?: number,
  eosabs?: number,
  nrbc?: number,
  lr5?: number
): R4.IBundle {
  const encounter: R4.IEncounter = getEncounterObjectForAppointment(appointment)
  const matchString: string = `${encounter.resourceType}?appointment=${appointment.appointment.resourceType}/${appointment.appointment.id}`
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: 'urn:uuid:1232323232324',
        request: {
          url: matchString,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: encounter,
      },
    ],
  }

  const encounterRef: R4.IReference = {
    reference: `${encounter.resourceType}/${
      appointment.encounter
        ? appointment.encounter.id
        : 'urn:uuid:1232323232324'
    }`,
    type: encounter.resourceType,
  }
  const panel1: R4.IDiagnosticReport = {
    ...getDiagnosticReportOPD(appointment, encounterRef),
  }
  panel1.category = [
    {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          code: 'laboratory',
          display: 'Laboratory',
        },
      ],
    },
  ]

  panel1.issued = reportDate.toISOString()
  panel1.effectiveDateTime = reportDate.toISOString()
  const panel1Ref: R4.IReference[] = []

  panel1.code = {
    coding: [
      {
        system: 'http://loinc.org',
        code: '57021-8',
        display: 'Complete Blood Count',
      },
    ],
  }

  const lipidPanel: R4.IDiagnosticReport = {
    ...getDiagnosticReportOPD(appointment, encounterRef),
  }
  lipidPanel.code = {
    coding: [
      {
        system: 'http://loinc.org',
        code: '24331-1',
        display: 'Lipid Profile',
      },
    ],
  }
  lipidPanel.category = [
    {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          code: 'laboratory',
          display: 'Laboratory',
        },
      ],
    },
  ]
  lipidPanel.issued = reportDate.toISOString()
  lipidPanel.effectiveDateTime = reportDate.toISOString()
  const lipidPanel1Ref: R4.IReference[] = []

  const urinePanel: R4.IDiagnosticReport = {
    ...getDiagnosticReportOPD(appointment, encounterRef),
  }
  urinePanel.category = [
    {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          code: 'laboratory',
          display: 'Laboratory',
        },
      ],
    },
  ]
  urinePanel.issued = reportDate.toISOString()
  urinePanel.effectiveDateTime = reportDate.toISOString()
  const urinePanelRef: R4.IReference[] = []
  urinePanel.code = {
    coding: [
      {
        system: 'http://loinc.org',
        code: '24357-6',
        display: 'Urinalysis complete panel',
      },
    ],
  }

  if (fastingBloodSugar != null && fastingBloodSugar > 0) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }

    const fastingReport: R4.IDiagnosticReport = {
      ...getDiagnosticReportOPD(appointment, encounterRef),
    }
    fastingReport.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ]

    fastingReport.issued = reportDate.toISOString()
    fastingReport.effectiveDateTime = reportDate.toISOString()

    fastingReport.code = {
      text: 'Test',
      coding: [
        {
          system: 'http://loinc.org',
          code: '1558-6',
          display: 'Fasting Blood Sugar',
        },
      ],
    }

    const date = moment().format('DD-MM-YYYY')

    const dateTime = moment(`${moment().format('HH:mm')}`, 'DD/MM/YYYY HH:mm')

    const isoStartDateTIme = dateTime.toISOString()

    observationObject.issued = reportDate.toISOString()
    observationObject.effectiveDateTime = reportDate.toISOString()
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'Test',
      coding: [
        {
          system: 'http://loinc.org',
          code: '1558-6',
          display: 'Fasting Blood Sugar',
        },
      ],
    }
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueQuantity = {
      value: fastingBloodSugar,
      unit: 'mg/dL',
      system: 'http://unitsofmeasure.org',
      code: 'mg/dL',
    }
    // observationObject.valueString = 'A'

    const entry: R4.IBundle_Entry = {
      fullUrl: `urn:uuid:Observation`,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }

    const ref: R4.IReference[] = []
    const reference: R4.IReference = {}
    reference.reference = `urn:uuid:Observation`
    ref.push(reference)
    fastingReport.result = ref
    const entry1: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: fastingReport.resourceType,
      },
      resource: fastingReport,
    }
    requestBundle.entry?.push(entry)
    requestBundle.entry?.push(entry1)
  }

  if (hba1c != null && hba1c > 0) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }

    const fastingReport: R4.IDiagnosticReport = {
      ...getDiagnosticReportOPD(appointment, encounterRef),
    }
    fastingReport.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ]

    fastingReport.issued = reportDate.toISOString()
    fastingReport.effectiveDateTime = reportDate.toISOString()

    fastingReport.code = {
      text: 'Test',
      coding: [
        {
          system: 'http://loinc.org',
          code: '4548-4',
          display: 'HbA1c',
        },
      ],
    }

    const date = moment().format('DD-MM-YYYY')

    const dateTime = moment(`${moment().format('HH:mm')}`, 'DD/MM/YYYY HH:mm')

    const isoStartDateTIme = dateTime.toISOString()

    observationObject.issued = reportDate.toISOString()
    observationObject.effectiveDateTime = reportDate.toISOString()
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'Test',
      coding: [
        {
          system: 'http://loinc.org',
          code: '4548-4',
          display: 'HbA1c',
        },
      ],
    }
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueQuantity = {
      value: hba1c,
      unit: '%',
      system: 'http://unitsofmeasure.org',
      code: '%',
    }

    const entry: R4.IBundle_Entry = {
      fullUrl: `urn:uuid:Observation1`,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }

    const ref: R4.IReference[] = []
    const reference: R4.IReference = {}
    reference.reference = `urn:uuid:Observation1`
    ref.push(reference)
    fastingReport.result = ref
    const entry1: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: fastingReport.resourceType,
      },
      resource: fastingReport,
    }
    requestBundle.entry?.push(entry)
    requestBundle.entry?.push(entry1)
  }

  if (homo != null && homo > 0) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }

    const fastingReport: R4.IDiagnosticReport = {
      ...getDiagnosticReportOPD(appointment, encounterRef),
    }
    fastingReport.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ]

    fastingReport.issued = reportDate.toISOString()
    fastingReport.effectiveDateTime = reportDate.toISOString()

    fastingReport.code = {
      text: 'Test',
      coding: [
        {
          system: 'http://loinc.org',
          code: '718-7',
          display: 'Hemoglobin',
        },
      ],
    }

    const date = moment().format('DD-MM-YYYY')

    const dateTime = moment(`${moment().format('HH:mm')}`, 'DD/MM/YYYY HH:mm')

    const isoStartDateTIme = dateTime.toISOString()

    observationObject.issued = reportDate.toISOString()
    observationObject.effectiveDateTime = reportDate.toISOString()
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'Test',
      coding: [
        {
          system: 'http://loinc.org',
          code: '718-7',
          display: 'Hemoglobin',
        },
      ],
    }
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueQuantity = {
      value: homo,
      unit: 'g/dL',
      system: 'http://unitsofmeasure.org',
      code: 'g/dL',
    }
    // observationObject.valueString = 'A'

    const entry: R4.IBundle_Entry = {
      fullUrl: `urn:uuid:Observation2`,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }

    const ref: R4.IReference[] = []
    const reference: R4.IReference = {}
    reference.reference = `urn:uuid:Observation2`
    ref.push(reference)
    fastingReport.result = ref
    const entry1: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: fastingReport.resourceType,
      },
      resource: fastingReport,
    }
    requestBundle.entry?.push(entry)
    requestBundle.entry?.push(entry1)
  }

  if (pp != null && pp > 0) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }

    const fastingReport: R4.IDiagnosticReport = {
      ...getDiagnosticReportOPD(appointment, encounterRef),
    }
    fastingReport.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ]

    fastingReport.issued = reportDate.toISOString()
    fastingReport.effectiveDateTime = reportDate.toISOString()

    fastingReport.code = {
      text: '',
      coding: [
        {
          system: 'http://loinc.org',
          code: '1521-4',
          display: 'Post Prandial Blood Sugar',
        },
      ],
    }
    observationObject.issued = reportDate.toISOString()
    observationObject.effectiveDateTime = reportDate.toISOString()
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'Test',
      coding: [
        {
          system: 'http://loinc.org',
          code: '1521-4',
          display: 'Post Prandial Blood Sugar',
        },
      ],
    }
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueQuantity = {
      value: pp,
      unit: 'mg/dL',
      system: 'http://unitsofmeasure.org',
      code: 'mg/dL',
    }
    // observationObject.valueString = 'A'

    const entry: R4.IBundle_Entry = {
      fullUrl: `urn:uuid:Observation3`,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }

    const ref: R4.IReference[] = []
    const reference: R4.IReference = {}
    reference.reference = `urn:uuid:Observation3`
    ref.push(reference)
    fastingReport.result = ref
    const entry1: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: fastingReport.resourceType,
      },
      resource: fastingReport,
    }
    requestBundle.entry?.push(entry)
    requestBundle.entry?.push(entry1)
  }

  if (serumC != null && serumC > 0) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }
    const fastingReport: R4.IDiagnosticReport = {
      ...getDiagnosticReportOPD(appointment, encounterRef),
    }
    fastingReport.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ]

    fastingReport.issued = reportDate.toISOString()
    fastingReport.effectiveDateTime = reportDate.toISOString()

    fastingReport.code = {
      text: 'Serum C peptide',
      coding: [
        {
          system: 'http://loinc.org',
          code: '1986-9',
          display: 'Serum C peptide',
        },
      ],
    }
    observationObject.issued = reportDate.toISOString()
    observationObject.effectiveDateTime = reportDate.toISOString()
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'Test',
      coding: [
        {
          system: 'http://loinc.org',
          code: '1986-9',
          display: 'Serum C peptide',
        },
      ],
    }
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueQuantity = {
      value: serumC,
      unit: 'ng/dL',
      system: 'http://unitsofmeasure.org',
      code: 'ng/dL',
    }
    // observationObject.valueString = 'A'

    const entry: R4.IBundle_Entry = {
      fullUrl: `urn:uuid:Observation4`,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }

    const ref: R4.IReference[] = []
    const reference: R4.IReference = {}
    reference.reference = `urn:uuid:Observation4`
    ref.push(reference)
    fastingReport.result = ref
    const entry1: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: fastingReport.resourceType,
      },
      resource: fastingReport,
    }
    requestBundle.entry?.push(entry)
    requestBundle.entry?.push(entry1)
  }

  if (serum != null && serum > 0) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }

    const fastingReport: R4.IDiagnosticReport = {
      ...getDiagnosticReportOPD(appointment, encounterRef),
    }
    fastingReport.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ]

    fastingReport.issued = reportDate.toISOString()
    fastingReport.effectiveDateTime = reportDate.toISOString()

    fastingReport.code = {
      text: 'Test',
      coding: [
        {
          system: 'http://loinc.org',
          code: '2160-0',
          display: 'Serum Creatinine',
        },
      ],
    }
    observationObject.issued = reportDate.toISOString()
    observationObject.effectiveDateTime = reportDate.toISOString()
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'Test',
      coding: [
        {
          system: 'http://loinc.org',
          code: '2160-0',
          display: 'Serum Creatinine',
        },
      ],
    }
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueQuantity = {
      value: serum,
      unit: 'mg/dL',
      system: 'http://unitsofmeasure.org',
      code: 'mg/dL',
    }
    // observationObject.valueString = 'A'

    const entry: R4.IBundle_Entry = {
      fullUrl: `urn:uuid:Observation5`,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }

    const ref: R4.IReference[] = []
    const reference: R4.IReference = {}
    reference.reference = `urn:uuid:Observation5`
    ref.push(reference)
    fastingReport.result = ref
    const entry1: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: fastingReport.resourceType,
      },
      resource: fastingReport,
    }
    requestBundle.entry?.push(entry)
    requestBundle.entry?.push(entry1)
  }

  if (serumU != null && serumU > 0) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }
    const fastingReport: R4.IDiagnosticReport = {
      ...getDiagnosticReportOPD(appointment, encounterRef),
    }
    fastingReport.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ]

    fastingReport.issued = reportDate.toISOString()
    fastingReport.effectiveDateTime = reportDate.toISOString()

    fastingReport.code = {
      text: 'ABC',
      coding: [
        {
          system: 'http://loinc.org',
          code: '3091-6',
          display: 'Serum Urea',
        },
      ],
    }
    observationObject.issued = reportDate.toISOString()
    observationObject.effectiveDateTime = reportDate.toISOString()
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'Test',
      coding: [
        {
          system: 'http://loinc.org',
          code: '3091-6',
          display: 'Serum Urea',
        },
      ],
    }
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueQuantity = {
      value: serumU,
      unit: 'mg/dL',
      system: 'http://unitsofmeasure.org',
      code: 'mg/dL',
    }
    // observationObject.valueString = 'A'

    const entry: R4.IBundle_Entry = {
      fullUrl: `urn:uuid:Observation6`,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }

    const ref: R4.IReference[] = []
    const reference: R4.IReference = {}
    reference.reference = `urn:uuid:Observation6`
    ref.push(reference)
    fastingReport.result = ref
    const entry1: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: fastingReport.resourceType,
      },
      resource: fastingReport,
    }
    requestBundle.entry?.push(entry)
    requestBundle.entry?.push(entry1)
  }
  if (cbcPanelData.length > 0) {
    for (let i = 0; i < cbcPanelData.length; i++) {
      if (
        cbcPanelData[i].quantity !== undefined &&
        Number.isNaN(cbcPanelData[i].quantity) === false
      ) {
        const observationObject: R4.IObservation = {
          ...getVitalsObservationObject(appointment, encounterRef),
        }
        observationObject.issued = reportDate.toISOString()
        observationObject.effectiveDateTime = reportDate.toISOString()
        observationObject.category = [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ]

        const reference: R4.IReference = {}
        reference.reference = `urn:uuid:Observation1${i}`
        panel1Ref.push(reference)

        observationObject.code = {
          text: `H${i}`,
          coding: [cbcPanelData[i].code!],
        }
        observationObject.status = R4.ObservationStatusKind._final
        if (cbcPanelData[i].quantity === 0) {
          observationObject.valueString = cbcPanelData[i].measureInString
        } else {
          observationObject.valueQuantity = {
            value: cbcPanelData[i].quantity,
            unit: cbcPanelData[i].unit,
            system: 'http://unitsofmeasure.org',
            code: cbcPanelData[i].unit,
          }
        }

        // observationObject.valueString = 'A'

        const entry: R4.IBundle_Entry = {
          fullUrl: `urn:uuid:Observation1${i}`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: observationObject.resourceType,
          },
          resource: observationObject,
        }
        requestBundle.entry?.push(entry)
      }
    }
  }

  if (eos !== undefined && eos >= 0) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }

    const reference: R4.IReference = {}
    reference.reference = `urn:uuid:Observation90`
    panel1Ref.push(reference)

    observationObject.issued = reportDate.toISOString()
    observationObject.effectiveDateTime = reportDate.toISOString()
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'i',
      coding: [cbcpanel[2].code],
    }
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueQuantity = {
      value: eos,
      unit: '%',
      system: 'http://unitsofmeasure.org',
      code: '%',
    }
    // observationObject.valueString = 'A'

    const entry: R4.IBundle_Entry = {
      fullUrl: `urn:uuid:Observation90`,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }

    requestBundle.entry?.push(entry)
  }

  if (eosabs !== undefined && eosabs >= 0) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }

    const reference: R4.IReference = {}
    reference.reference = `urn:uuid:Observation91`
    panel1Ref.push(reference)

    observationObject.issued = reportDate.toISOString()
    observationObject.effectiveDateTime = reportDate.toISOString()
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'J',
      coding: [cbcpanel[3].code],
    }
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueQuantity = {
      value: eosabs,
      unit: 'x10E3/uL',
      system: 'http://unitsofmeasure.org',
      code: 'x10E3/uL',
    }
    // observationObject.valueString = 'A'

    const entry: R4.IBundle_Entry = {
      fullUrl: `urn:uuid:Observation91`,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }

    requestBundle.entry?.push(entry)
  }

  if (nrbc !== undefined && nrbc >= 0) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }

    const reference: R4.IReference = {}
    reference.reference = `urn:uuid:Observation94`
    panel1Ref.push(reference)

    observationObject.issued = reportDate.toISOString()
    observationObject.effectiveDateTime = reportDate.toISOString()
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'K',
      coding: [
        {
          system: 'http://loinc.org',
          code: '58413-6',
          display: 'NRBC',
        },
      ],
    }
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueQuantity = {
      value: nrbc,
      unit: '%',
      system: 'http://unitsofmeasure.org',
      code: '%',
    }
    // observationObject.valueString = 'A'

    const entry: R4.IBundle_Entry = {
      fullUrl: `urn:uuid:Observation94`,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }

    requestBundle.entry?.push(entry)
  }

  if (panel1Ref.length > 0) {
    panel1.result = panel1Ref
    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: panel1.resourceType,
      },
      resource: panel1,
    }
    requestBundle.entry?.push(entry)
  }

  //   if (lipidPanelData.length > 0) {
  //     for (let i = 0; i < lipidPanelData.length; i++) {
  //       if (lipidPanelData[i].measureInString.length > 0) {
  //         const observationObject1: R4.IObservation = {
  //           ...getVitalsObservationObject(appointment, encounterRef),
  //         }
  //         observationObject1.issued = reportDate.toISOString()
  //         observationObject1.effectiveDateTime = reportDate.toISOString()
  //         observationObject1.category = [
  //           {
  //             coding: [
  //               {
  //                 system:
  //                   'http://terminology.hl7.org/CodeSystem/observation-category',
  //                 code: 'laboratory',
  //                 display: 'Laboratory',
  //               },
  //             ],
  //           },
  //         ]
  //         observationObject1.code = {
  //           text: `L${i}`,
  //           coding: [labTestReport1[i].code!],
  //         }
  //         observationObject1.status = R4.ObservationStatusKind._final
  //         if (labTestReport1[i].quantity === 0) {
  //           observationObject1.valueString = labTestReport1[i].measureInString
  //         } else {
  //           observationObject1.valueQuantity = {
  //             value: labTestReport1[i].quantity,
  //             unit: labTestReport1[i].unit,
  //             system: 'http://unitsofmeasure.org',
  //             code: labTestReport1[i].unit,
  //           }
  //         }

  //         // observationObject.valueString = 'A'

  //         const entry: R4.IBundle_Entry = {
  //           request: {
  //             method: R4.Bundle_RequestMethodKind._post,
  //           },
  //           resource: observationObject1,
  //         }
  //         requestBundle.entry?.push(entry)
  //       }
  //     }
  //   }

  if (lipidPanelData.length > 0) {
    for (let i = 0; i < lipidPanelData.length; i++) {
      if (
        lipidPanelData[i].quantity !== undefined &&
        Number.isNaN(lipidPanelData[i].quantity) === false
      ) {
        const observationObjec2: R4.IObservation = {
          ...getVitalsObservationObject(appointment, encounterRef),
        }
        observationObjec2.issued = reportDate.toISOString()
        observationObjec2.effectiveDateTime = reportDate.toISOString()
        observationObjec2.category = [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ]
        observationObjec2.code = {
          text: `M${i}`,
          coding: [lipidPanelData[i].code!],
        }
        // lipidPanel.code.coding?.push(lipidPanelData[i].code!)
        // const reference: R4.IReference = {}
        // reference.reference = `urn:uuid:Observation3${i}`
        // lipidPanel1Ref.push(reference)
        observationObjec2.status = R4.ObservationStatusKind._final
        if (lipidPanelData[i].quantity === 0) {
          observationObjec2.valueString = lipidPanelData[i].measureInString
        } else {
          observationObjec2.valueQuantity = {
            value: lipidPanelData[i].quantity,
            unit: lipidPanelData[i].unit,
            system: 'http://unitsofmeasure.org',
            code: lipidPanelData[i].unit,
          }
        }

        // observationObject.valueString = 'A'

        const entry: R4.IBundle_Entry = {
          fullUrl: `urn:uuid:Observation3${i}`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: observationObjec2.resourceType,
          },
          resource: observationObjec2,
        }
        requestBundle.entry?.push(entry)
      }
    }
  }

  if (lr5 !== undefined && lr5 >= 0) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }
    // lipidPanel.code.coding?.push({
    //   system: 'http://loinc.org',
    //   code: 'I9830-1',
    //   display: 'Total Cholesterol/HDL Cholesterol',
    // })
    const reference: R4.IReference = {}
    reference.reference = `urn:uuid:Observation96`
    lipidPanel1Ref.push(reference)

    observationObject.issued = reportDate.toISOString()
    observationObject.effectiveDateTime = reportDate.toISOString()
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'laboratory',
            display: 'Laboratory',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'LR5',
      coding: [
        {
          system: 'http://loinc.org',
          code: 'I9830-1',
          display: 'Total Cholesterol/HDL Cholesterol',
        },
      ],
    }
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueQuantity = {
      value: lr5,
      unit: '{ratio}',
      system: 'http://unitsofmeasure.org',
      code: '{ratio}',
    }
    // observationObject.valueString = 'A'

    const entry: R4.IBundle_Entry = {
      fullUrl: `urn:uuid:Observation96`,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }

    requestBundle.entry?.push(entry)
  }

  if (lipidPanel1Ref.length > 0) {
    lipidPanel.result = lipidPanel1Ref
    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: lipidPanel.resourceType,
      },
      resource: lipidPanel,
    }
    requestBundle.entry?.push(entry)
  }

  if (lft && lft.length > 0) {
    for (let i = 0; i < lft.length; i++) {
      if (
        lft[i].quantity !== undefined &&
        Number.isNaN(lft[i].quantity) === false
      ) {
        const observationObjec2: R4.IObservation = {
          ...getVitalsObservationObject(appointment, encounterRef),
        }
        observationObjec2.issued = reportDate.toISOString()
        observationObjec2.effectiveDateTime = reportDate.toISOString()
        observationObjec2.category = [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ]
        observationObjec2.code = {
          text: 'LFT',
          coding: [lft[i].code!],
        }

        const reference: R4.IReference = {}
        reference.reference = `urn:uuid:Observation400${i}`
        // lipidPanel1Ref.push(reference)
        observationObjec2.status = R4.ObservationStatusKind._final
        if (lft[i].quantity === 0) {
          observationObjec2.valueString = lft[i].measureInString
        } else {
          observationObjec2.valueQuantity = {
            value: lft[i].quantity,
            unit: lft[i].unit,
            system: 'http://unitsofmeasure.org',
            code: lft[i].unit,
          }
        }

        // observationObject.valueString = 'A'

        const entry: R4.IBundle_Entry = {
          fullUrl: `urn:uuid:Observation400${i}`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: observationObjec2.resourceType,
          },
          resource: observationObjec2,
        }
        requestBundle.entry?.push(entry)
      }
    }
  }

  if (rft && rft.length > 0) {
    for (let i = 0; i < rft.length; i++) {
      if (
        rft[i].quantity !== undefined &&
        Number.isNaN(rft[i].quantity) === false
      ) {
        const observationObjec2: R4.IObservation = {
          ...getVitalsObservationObject(appointment, encounterRef),
        }
        observationObjec2.issued = reportDate.toISOString()
        observationObjec2.effectiveDateTime = reportDate.toISOString()
        observationObjec2.category = [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ]
        observationObjec2.code = {
          text: 'RFT',
          coding: [rft[i].code!],
        }

        const reference: R4.IReference = {}
        reference.reference = `urn:uuid:Observation300${i}`

        observationObjec2.status = R4.ObservationStatusKind._final
        if (rft[i].quantity === 0) {
          observationObjec2.valueString = rft[i].measureInString
        } else {
          observationObjec2.valueQuantity = {
            value: rft[i].quantity,
            unit: rft[i].unit,
            system: 'http://unitsofmeasure.org',
            code: rft[i].unit,
          }
        }

        // observationObject.valueString = 'A'

        const entry: R4.IBundle_Entry = {
          fullUrl: `urn:uuid:Observation300${i}`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: observationObjec2.resourceType,
          },
          resource: observationObjec2,
        }
        requestBundle.entry?.push(entry)
      }
    }
  }

  if (tftData && tftData.length > 0) {
    for (let i = 0; i < tftData.length; i++) {
      if (
        tftData[i].quantity !== undefined &&
        Number.isNaN(tftData[i].quantity) === false
      ) {
        const observationObjec2: R4.IObservation = {
          ...getVitalsObservationObject(appointment, encounterRef),
        }
        observationObjec2.issued = reportDate.toISOString()
        observationObjec2.effectiveDateTime = reportDate.toISOString()
        observationObjec2.category = [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ]
        observationObjec2.code = {
          text: 'TFT',
          coding: [tftData[i].code!],
        }

        const reference: R4.IReference = {}
        reference.reference = `urn:uuid:Observation600${i}`

        observationObjec2.status = R4.ObservationStatusKind._final
        if (tftData[i].quantity === 0) {
          observationObjec2.valueString = rft[i].measureInString
        } else {
          observationObjec2.valueQuantity = {
            value: tftData[i].quantity,
            unit: tftData[i].unit,
            system: 'http://unitsofmeasure.org',
            code: tftData[i].unit,
          }
        }

        // observationObject.valueString = 'A'

        const entry: R4.IBundle_Entry = {
          fullUrl: `urn:uuid:Observation3600${i}`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: observationObjec2.resourceType,
          },
          resource: observationObjec2,
        }
        requestBundle.entry?.push(entry)
      }
    }
  }

  if (urineDataPanel && urineDataPanel.length > 0) {
    for (let i = 0; i < urineDataPanel.length; i++) {
      if (
        (urineDataPanel[i].quantity !== undefined ||
          urineDataPanel[i].measureInString !== undefined) &&
        urineDataPanel[i].code !== undefined
      ) {
        const observationObjec2: R4.IObservation = {
          ...getVitalsObservationObject(appointment, encounterRef),
        }
        observationObjec2.issued = reportDate.toISOString()
        observationObjec2.effectiveDateTime = reportDate.toISOString()
        observationObjec2.category = [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ]
        observationObjec2.code = {
          text: 'URINE',
          coding: [urineDataPanel[i].code!],
        }

        const reference: R4.IReference = {}
        reference.reference = `urn:uuid:Observation800${i}`

        observationObjec2.status = R4.ObservationStatusKind._final

        const dataString: string = urineDataPanel[i].measureInString ?? ''

        const qty: number = urineDataPanel[i].quantity ?? 0

        if (dataString.length > 0) {
          observationObjec2.valueString = urineDataPanel[i].measureInString
        } else {
          observationObjec2.valueQuantity = {
            value: qty,
            unit: urineDataPanel[i].unit,
            system: 'http://unitsofmeasure.org',
            code: urineDataPanel[i].unit,
          }
        }

        // observationObject.valueString = 'A'

        const entry: R4.IBundle_Entry = {
          fullUrl: `urn:uuid:Observation800${i}`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: observationObjec2.resourceType,
          },
          resource: observationObjec2,
        }
        requestBundle.entry?.push(entry)
      }
    }
  }

  if (homa && homa.length > 0) {
    for (let i = 0; i < homa.length; i++) {
      if (
        homa[i].quantity !== undefined &&
        Number.isNaN(homa[i].quantity) === false
      ) {
        const observationObjec2: R4.IObservation = {
          ...getVitalsObservationObject(appointment, encounterRef),
        }
        observationObjec2.issued = reportDate.toISOString()
        observationObjec2.effectiveDateTime = reportDate.toISOString()
        observationObjec2.category = [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ]
        observationObjec2.code = {
          text: 'HOMA',
          coding: [homa[i].code!],
        }

        const reference: R4.IReference = {}
        reference.reference = `urn:uuid:Observation5600${i}`

        observationObjec2.status = R4.ObservationStatusKind._final
        if (homa[i].quantity === 0) {
          observationObjec2.valueString = homa[i].measureInString
        } else {
          observationObjec2.valueQuantity = {
            value: homa[i].quantity,
            unit: homa[i].unit,
            system: 'http://unitsofmeasure.org',
            code: homa[i].unit,
          }
        }

        // observationObject.valueString = 'A'

        const entry: R4.IBundle_Entry = {
          fullUrl: `urn:uuid:Observation5600${i}`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: observationObjec2.resourceType,
          },
          resource: observationObjec2,
        }
        requestBundle.entry?.push(entry)
      }
    }
  }

  if (dexaScan && dexaScan.length > 0) {
    for (let i = 0; i < dexaScan.length; i++) {
      if (
        dexaScan[i].quantity !== undefined &&
        Number.isNaN(dexaScan[i].quantity) === false
      ) {
        const observationObjec2: R4.IObservation = {
          ...getVitalsObservationObject(appointment, encounterRef),
        }
        observationObjec2.issued = reportDate.toISOString()
        observationObjec2.effectiveDateTime = reportDate.toISOString()
        observationObjec2.category = [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ]
        observationObjec2.code = {
          text: 'DEXA',
          coding: [dexaScan[i].code!],
        }

        const reference: R4.IReference = {}
        reference.reference = `urn:uuid:Observation5800${i}`

        observationObjec2.status = R4.ObservationStatusKind._final
        if (dexaScan[i].quantity === 0) {
          observationObjec2.valueString = dexaScan[i].measureInString
        } else {
          observationObjec2.valueQuantity = {
            value: dexaScan[i].quantity,
            unit: dexaScan[i].unit,
            system: 'http://unitsofmeasure.org',
            code: dexaScan[i].unit,
          }
        }

        // observationObject.valueString = 'A'

        const entry: R4.IBundle_Entry = {
          fullUrl: `urn:uuid:Observation5800${i}`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: observationObjec2.resourceType,
          },
          resource: observationObjec2,
        }
        requestBundle.entry?.push(entry)
      }
    }
  }

  if (serumLytes && serumLytes.length > 0) {
    for (let i = 0; i < serumLytes.length; i++) {
      if (
        serumLytes[i].quantity !== undefined &&
        Number.isNaN(serumLytes[i].quantity) === false
      ) {
        const observationObjec2: R4.IObservation = {
          ...getVitalsObservationObject(appointment, encounterRef),
        }
        observationObjec2.issued = reportDate.toISOString()
        observationObjec2.effectiveDateTime = reportDate.toISOString()
        observationObjec2.category = [
          {
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
                code: 'laboratory',
                display: 'Laboratory',
              },
            ],
          },
        ]
        observationObjec2.code = {
          text: 'Serum Lytes',
          coding: [serumLytes[i].code!],
        }

        const reference: R4.IReference = {}
        reference.reference = `urn:uuid:Observation7000${i}`

        observationObjec2.status = R4.ObservationStatusKind._final
        if (serumLytes[i].quantity === 0) {
          observationObjec2.valueString = serumLytes[i].measureInString
        } else {
          observationObjec2.valueQuantity = {
            value: serumLytes[i].quantity,
            unit: serumLytes[i].unit,
            system: 'http://unitsofmeasure.org',
            code: serumLytes[i].unit,
          }
        }

        // observationObject.valueString = 'A'

        const entry: R4.IBundle_Entry = {
          fullUrl: `urn:uuid:Observation7000${i}`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: observationObjec2.resourceType,
          },
          resource: observationObjec2,
        }
        requestBundle.entry?.push(entry)
      }
    }
  }

  return requestBundle
}

export default addLabTestSliceOPD.reducer
