/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Checkbox,
  makeStyles,
  Theme,
  Typography,
  Divider,
  TextField,
} from '@material-ui/core'
import * as _ from 'lodash'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addLabTestIPD,
  resetAddVitalsState,
} from 'redux/ipd/addLabTestIPD/addLabTestSlice'
import { RootState } from 'redux/rootReducer'
import { cToF, fToC } from 'utils/appointment_handle/vitals_util'
import { WelloLoadingPage, WelloTextField } from 'wello-web-components'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'

import moment from 'moment'
import {
  isAfter,
  isAfterSame,
  isBeforeTime,
  isTherapybetweenForMorning,
} from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import {
  cbcpanel,
  lft,
  lipidPanel,
  obsDataForLabTest,
  panelData,
  rft,
  urinepanel,
} from 'utils/constants'
import { ExpandMore } from '@material-ui/icons'
import {
  LabTestReport,
  LabTestReport1,
  LabTestReport2,
  LabTestReport3,
  Panel1,
} from 'models/labTestReport'
import { addLabTestOPD } from 'redux/consultation/addlabTest/addLabTestIPD/addLabTestSlice'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import { getGenderOfPatient } from 'utils/fhirResourcesHelper'
import { validate } from 'uuid'
import { ObsRecord } from 'models/obsRecords'
import {
  addObsData,
  getErrorStatus,
  getObsDefData,
} from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { LabOfferingSelectorForResult } from 'views/components/LeftMenu/welloSelectFhirLabTestSelector'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { ObsServiceData, ObsServiceDataWithVal } from 'models/obsServiceData'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))
interface Props {
  open: boolean
  labOrderDetails: FhirLabOrderDetail
  onClose: () => void
  //   onVitalAdded: (createdCondition: R4.IBundle | undefined) => void
}
export const UploadLabResults: React.FC<Props> = ({
  open,
  onClose,
  //   onVitalAdded,
  labOrderDetails,
}) => {
  const { t } = useTranslation(['common', 'observation'])
  const useStylesDialog = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '50%',
    },
  }))
  const classes = useStyles()
  const classesDialog = useStylesDialog()

  const dispatch = useDispatch()

  const [loading, setLoading] = useState<boolean>(false)
  const [saveLoading, setSaveLoading] = useState<boolean>(false)

  const [planDefData, setPlanDefData] = useState<R4.IPlanDefinition>()
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [addStatus, setAddStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [obsData, setObsData] = useState<ObsServiceDataWithVal>()

  function getObservationDefinitionData(PlanDef: R4.IPlanDefinition) {
    setUpdateStatus({ requesting: true })

    setLoading(true)

    getObsDefData(PlanDef, labOrderDetails)
      .then((e: ObsServiceDataWithVal) => {
        setLoading(false)
        if (e) {
          setObsData(e)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while fetching details. Please try again later'
            )
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  function updateLabResults(obsDataFInal: ObsServiceDataWithVal) {
    setAddStatus({ requesting: true })

    setSaveLoading(true)

    addObsData(obsDataFInal, labOrderDetails)
      .then((e: boolean) => {
        setSaveLoading(false)
        if (e === true) {
          getObservationDefinitionData(planDefData!)
          dispatch(showSuccessAlert('Lab results added successfully'))
          setAddStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setAddStatus({
            requesting: false,
            requestSuccess: true,
          })
          setSaveLoading(false)
          dispatch(
            showErrorAlert(
              'Error while fetching details. Please try again later'
            )
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setSaveLoading(false)
        setAddStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  function enableData() {
    const data: string[] = []
    if (obsData && obsData.obsDef) {
      for (let i = 0; i < obsData.obsDef.length; i++)
        if (obsData.obsDef[i].valInt === undefined) {
          data.push('1')
        }
    } else {
      data.push('1')
    }
    return data.length
  }

  function getMinError(
    valData: R4.IObservationDefinition,
    patient: R4.IPatient
  ) {
    let lowVal: number | undefined = 0

    if (valData.qualifiedInterval && valData.qualifiedInterval.length > 0) {
      for (let i = 0; i < valData.qualifiedInterval.length; i++) {
        if (valData.qualifiedInterval[i].gender) {
          if (
            valData.qualifiedInterval[i].gender === getGenderOfPatient(patient)
          ) {
            lowVal = valData.qualifiedInterval[i].range
              ? valData.qualifiedInterval[i].range?.low?.value
              : undefined
          }
        } else {
          lowVal = valData.qualifiedInterval[i].range
            ? valData.qualifiedInterval[i].range?.low?.value
            : 0
        }
      }
    }

    return lowVal
  }

  function getHighError(
    valData: R4.IObservationDefinition,
    patient: R4.IPatient
  ) {
    let highVal: number | undefined
    if (valData.qualifiedInterval && valData.qualifiedInterval.length > 0) {
      for (let i = 0; i < valData.qualifiedInterval.length; i++) {
        if (valData.qualifiedInterval[i].gender) {
          if (
            valData.qualifiedInterval[i].gender === getGenderOfPatient(patient)
          ) {
            highVal = valData.qualifiedInterval[i].range
              ? valData.qualifiedInterval[i].range?.high?.value
              : 0
          }
        } else {
          highVal = valData.qualifiedInterval[i].range
            ? valData.qualifiedInterval[i].range?.high?.value
            : 0
        }
      }
    }

    return highVal
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classesDialog.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Add Lab Test Result</DialogTitle>
      <DialogContent dividers={true}>
        <Box display='flex' flexDirection='column' width='100%'>
          {labOrderDetails.tests && (
            <Box display='flex' flexDirection='column' width='50%'>
              <WelloFormItemLabel title='Select Lab Test/Panel' />
              <LabOfferingSelectorForResult
                onSelectionChanges={(e: LabOfferingDetail) => {
                  setObsData(undefined)
                  setPlanDefData(e.planDefinition)
                  getObservationDefinitionData(e.planDefinition)
                }}
                disabled={false}
                offerRings={labOrderDetails.tests ?? []}
              />
            </Box>
          )}

          <Box display='flex' flexDirection='column' width='100%' py={1}>
            {loading && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
              >
                <CircularProgress size={20} />
              </Box>
            )}
            {obsData && (
              <Box display='flex' flexDirection='column'>
                {obsData.obsDef.length > 0 && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height={40}
                    style={{
                      backgroundColor: 'lightGrey',
                    }}
                  >
                    <Box width='100%' display='flex' flexDirection='row' px={1}>
                      <Box width='30%'>
                        <Typography
                          variant='subtitle2'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          {' '}
                          Parameter Name
                        </Typography>
                      </Box>
                      <Box
                        width='20%'
                        display='flex'
                        flexDirection='row'
                        justifyContent='center'
                      >
                        <Typography
                          variant='subtitle2'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          {' '}
                          Value
                        </Typography>
                      </Box>
                      <Box
                        width='15%'
                        display='flex'
                        flexDirection='row'
                        justifyContent='center'
                      >
                        <Typography
                          variant='subtitle2'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          {' '}
                          Unit
                        </Typography>
                      </Box>
                      <Box
                        width='15%'
                        display='flex'
                        flexDirection='row'
                        justifyContent='center'
                      >
                        <Typography
                          variant='subtitle2'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          {' '}
                          Normal Range
                        </Typography>
                      </Box>

                      <Box
                        width='20%'
                        display='flex'
                        flexDirection='row'
                        justifyContent='center'
                      >
                        <Typography
                          variant='subtitle2'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          {' '}
                          Highlight
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
                {obsData.obsDef.map((item, index) => (
                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='row'
                    px={1}
                    py={1}
                    key={item.obsDef.id}
                  >
                    <Box width='30%' py={1}>
                      <Typography
                        variant='subtitle2'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {item.obsDef.preferredReportName ?? ''}
                      </Typography>
                    </Box>

                    <Box
                      width='20%'
                      display='flex'
                      flexDirection='row'
                      justifyContent='center'
                    >
                      <TextField
                        variant='outlined'
                        fullWidth
                        type='number'
                        size='small'
                        disabled={saveLoading}
                        value={item.valInt ? item.valInt : undefined}
                        onChange={(event) => {
                          const values: ObsServiceDataWithVal = { ...obsData }
                          console.log(event.target.value)
                          if (event.target.value.length > 0) {
                            values.obsDef[index].valInt = parseFloat(
                              parseFloat(event.target.value).toFixed(2)
                            )
                          } else {
                            values.obsDef[index].valInt = undefined
                          }

                          if (
                            getErrorStatus(
                              item.obsDef,
                              labOrderDetails.patient,
                              parseFloat(
                                parseFloat(event.target.value).toFixed(2)
                              )
                            )
                          ) {
                            values.obsDef[index].checked = true
                          } else {
                            values.obsDef[index].checked = false
                          }
                          setObsData(values)
                        }}
                        error={
                          item.valInt
                            ? getErrorStatus(
                                item.obsDef,
                                labOrderDetails.patient,
                                item.valInt
                              )
                            : false
                        }
                        InputProps={{
                          inputProps: {
                            step: 0.1,
                          },
                        }}
                      />
                    </Box>
                    <Box
                      py={1}
                      display='flex'
                      flexDirection='row'
                      justifyContent='center'
                      width='15%'
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {item.obsDef.quantitativeDetails
                          ? item.obsDef.quantitativeDetails.unit
                            ? item.obsDef.quantitativeDetails.unit.coding![0]
                                .code!
                            : ''
                          : ''}
                      </Typography>
                    </Box>

                    <Box
                      width='15%'
                      display='flex'
                      flexDirection='row'
                      justifyContent='center'
                      py={1}
                    >
                      {getMinError(item.obsDef, labOrderDetails.patient) !==
                        undefined &&
                        getHighError(item.obsDef, labOrderDetails.patient) ===
                          undefined && (
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {` >= ${getMinError(
                              item.obsDef,
                              labOrderDetails.patient
                            )}`}
                          </Typography>
                        )}

                      {getMinError(item.obsDef, labOrderDetails.patient) ===
                        undefined &&
                        getHighError(item.obsDef, labOrderDetails.patient) !==
                          undefined && (
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {` <= ${getHighError(
                              item.obsDef,
                              labOrderDetails.patient
                            )}`}
                          </Typography>
                        )}
                      {getMinError(item.obsDef, labOrderDetails.patient) !==
                        undefined &&
                        getHighError(item.obsDef, labOrderDetails.patient) !==
                          undefined && (
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {`${getMinError(
                              item.obsDef,
                              labOrderDetails.patient
                            )} - ${getHighError(
                              item.obsDef,
                              labOrderDetails.patient
                            )}`}
                          </Typography>
                        )}
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='center'
                      width='20%'
                    >
                      <Checkbox
                        edge='start'
                        checked={item.checked}
                        // onChange={handleChange}
                        tabIndex={-1}
                        size='small'
                        disableRipple
                        color='primary'
                        inputProps={{ 'aria-labelledby': 'consent' }}
                        role='unit_search_consent'
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            onClose()
          }}
          id='cancel'
          variant='outlined'
          disableElevation
        >
          {t('labelCommon:cancel')}
        </Button>

        <Button
          onClick={() => {
            if (obsData) updateLabResults(obsData)
          }}
          variant='contained'
          color='primary'
          id='add'
          disableElevation
          disabled={enableData() > 0 || saveLoading}
        >
          Save
        </Button>
        <Button
          onClick={() => {}}
          variant='contained'
          color='primary'
          id='add'
          disableElevation
          disabled={enableData() > 0 || saveLoading}
        >
          Print All
        </Button>
      </DialogActions>
    </Dialog>
  )
}
