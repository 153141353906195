import { R4 } from '@ahryman40k/ts-fhir-types'

export const martialList = () => [
  { id: 'D', title: 'Divorced' },
  { id: 'M', title: 'Married' },
  { id: 'U', title: 'Single' },
  { id: 'W', title: 'Widowed' },
]

export const maritalListFhirList: R4.ICoding[] = [
  {
    code: 'D',
    display: 'Divorced',
    system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
  },
  {
    code: 'M',
    display: 'Married',
    system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
  },
  {
    code: 'U',
    display: 'Single',
    system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
  },
  {
    code: 'W',
    display: 'Widowed',
    system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
  },
  //   { code: 'online', display: 'Online' },
]
