import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from 'redux/store'
import { logger } from 'utils/logger'
import { ServiceSearchStatus } from './tcamServiceSearchStatusTypes'

const initialState: ServiceSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const serviceSearchSlice = createSlice({
  name: 'tcamServiceSearch',
  initialState,
  reducers: {
    searchingServiceDetails(state, action: PayloadAction<ServiceSearchStatus>) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.serviceList = undefined
    },

    searchResults(state, action: PayloadAction<ServiceSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.serviceList = action.payload.serviceList
    },

    noDataFoundForSearch(state, action: PayloadAction<ServiceSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.serviceList = undefined
    },

    errorWhileSearching(state, action: PayloadAction<ServiceSearchStatus>) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.serviceList = undefined
    },
  },
})

export const searchTcamServices =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: ServiceSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(serviceSearchSlice.actions.searchingServiceDetails(state))
    try {
      /* const oidcUser: OIDCUser | null = getOIDCUserObject()
    if (oidcUser != null) {
      const response:
        | TcamServiceMap
        | ErrorResponse = await TcamProfileService.getTcamServiceMap({})

      const errorResp: ErrorResponse = response as ErrorResponse
      if (errorResp.code) {
        if (errorResp.code === '401') {
          state.errorMessage =
            'Yor are not allowed to access this. Please check permissions'
        }
        if (errorResp.code?.includes('500')) {
          state.errorMessage = 'Server is down. Please try later'
        }
        dispatch(serviceSearchSlice.actions.noDataFoundForSearch(state))
      } else {
        const successResp: TcamServiceMap = response as TcamServiceMap

        if (successResp) {
          const serviceList: TcamServiceMapBase[] = successResp
          state.serviceList = serviceList

          dispatch(serviceSearchSlice.actions.searchResults(state))
        } else {
          const errorSearchService: ServiceSearchStatus = {
            error: false,
            noResultsAvailable: true,
            resultsAvailable: false,
            searching: false,
          }
          dispatch(
            serviceSearchSlice.actions.noDataFoundForSearch(errorSearchService)
          )
        }
      }
    } else {
      // TODO Log out user
    } */
      // TODO
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: ServiceSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        serviceSearchSlice.actions.errorWhileSearching(errorSearchDoctor)
      )
    }
  }

export default serviceSearchSlice.reducer
