import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  Checkbox,
} from '@material-ui/core'
import { Diet } from 'models/diet'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { addDietDetails } from 'redux/ipd/diet/addDiet/addDiet'
import { requestDietDetails } from 'redux/ipd/diet/viewDiet/dietSearchSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { isCurrentUserDoctor } from 'services/userDetailsService'
import { getCatalogName } from 'utils/fhirResoureHelpers/catalogHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'

import {
  isFutureDateTimeForDiet,
  isPastDateTimeForDiet,
  isExistingDiet,
  isPastDate,
  isDietCompleted,
} from 'utils/fhirResoureHelpers/ipdDietHelper'

import { CatalogSelector } from 'views/components/common/catalog_entry_selector'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'

import { DietSelector } from './diet_selector'

interface AssessmentCaptureProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  status: string
}

export const DietCard: React.FC<AssessmentCaptureProps> = ({
  fhirAppointmentDetails,
  status,
}: AssessmentCaptureProps) => {
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()

  const addDietSlice = useSelector((state: RootState) => state.addDietSlice)

  const getDietDetails = useSelector(
    (state: RootState) => state.dietSearchSlice
  )

  function updateCurrentSelection(
    catalogType: R4.ICatalogEntry[],
    dietDate: number,
    timeIndex: string
  ) {
    const selectedDiet: Diet = { ...dietChart[dietDate] }
    switch (timeIndex) {
      case '6AM':
        selectedDiet.six = [updateNutrition(catalogType, selectedDiet.six![0])]
        break
      case '830AM':
        selectedDiet.eight = [
          updateNutrition(catalogType, selectedDiet.eight![0]),
        ]
        break
      case '12PM':
        selectedDiet.twelve = [
          updateNutrition(catalogType, selectedDiet.twelve![0]),
        ]
        break
      case '2PM':
        selectedDiet.two = [updateNutrition(catalogType, selectedDiet.two![0])]
        break
      case '5PM':
        selectedDiet.five = [
          updateNutrition(catalogType, selectedDiet.five![0]),
        ]
        break
      case '7PM':
        selectedDiet.seven = [
          updateNutrition(catalogType, selectedDiet.seven![0]),
        ]
        break
      default:
        break
    }

    const newDietChart = [...dietChart]
    newDietChart[dietDate] = selectedDiet

    setDietChart(newDietChart)
  }

  function updateNutritionCompletionStatus(
    no: R4.INutritionOrder[],
    dietDate: number,
    timeIndex: string
  ) {
    const selectedDiet: Diet = { ...dietChart[dietDate] }
    switch (timeIndex) {
      case '6AM':
        selectedDiet.six = no
        break
      case '830AM':
        selectedDiet.eight = no
        break
      case '12PM':
        selectedDiet.twelve = no
        break
      case '2PM':
        selectedDiet.two = no
        break
      case '5PM':
        selectedDiet.five = no
        break
      case '7PM':
        selectedDiet.seven = no
        break
      default:
        break
    }

    const newDietChart = [...dietChart]
    newDietChart[dietDate] = selectedDiet

    setDietChart(newDietChart)
  }

  const [dietChart, setDietChart] = useState<Diet[]>([])

  function updateNutrition(
    catalogType: R4.ICatalogEntry[],
    nutritionResource: R4.INutritionOrder
  ) {
    let nutritionOrder: R4.INutritionOrder = { ...nutritionResource }
    nutritionOrder.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndNutritionOrderNutritionProduct',
      ],
    }

    const extList: R4.IExtension[] = []

    catalogType.forEach((e) => {
      extList.push({
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-product-ext',
        valueReference: {
          reference: `CatalogEntry/${e.id}`,
          display: getCatalogName(e),
        },
      })
    })

    nutritionOrder = {
      ...nutritionOrder,
      oralDiet: {
        ...nutritionOrder.oralDiet,
        extension: [
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-intake-ext',
            extension: extList,
          },
        ],
      },
    }
    return nutritionOrder
  }

  useEffect(() => {
    dispatch(
      requestDietDetails(
        fhirAppointmentDetails.mainEncounter?.id!,
        fhirAppointmentDetails.mainServiceRequest,
        fhirAppointmentDetails.mainEncounter?.id!
      )
    )
  }, [])

  useEffect(() => {
    if (getDietDetails.resultsAvailable && getDietDetails.dateWiseDietList) {
      setDietChart(getDietDetails.dateWiseDietList)
    }
  }, [getDietDetails.resultsAvailable])

  return (
    <Box style={{ paddingLeft: '4px' }} display='flex' flexDirection='row'>
      <Box width='100%'>
        <Grid container spacing={2} direction='column'>
          <Grid container direction='column'>
            {addDietSlice.adding && <LinearProgress />}
          </Grid>
          <Grid item>
            <ReactVisibilitySensor
              onChange={(isVisible) => {
                if (isVisible) {
                  dispatch(setSelectedSection({ selectedSection: 'diagnosis' }))
                }
              }}
            >
              <Grid item id='diagnosis'>
                <Grid item id='diagnosis'>
                  <Grid
                    container
                    direction='row'
                    style={{ padding: 0, margin: 0 }}
                  >
                    <Box
                      sx={{ width: '100%' }}
                      display='flex'
                      alignItems='center'
                      flexDirection='row'
                      bgcolor='#999999'
                      minHeight={45}
                    >
                      <Box p={2}>
                        <Typography
                          variant='body1'
                          style={{ color: 'white', fontWeight: 600 }}
                        >
                          {' '}
                          Diet Plan
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xl style={{ padding: 0, margin: 0 }}>
                  <Grid
                    container
                    direction='row'
                    style={{ padding: 0, margin: 0 }}
                  >
                    <Box width='100%' display='flex' flexDirection='row'>
                      <Box paddingTop={2} width='15%'>
                        <Typography
                          variant='body1'
                          color='primary'
                          style={{ fontWeight: 600 }}
                        >
                          {' '}
                          Date
                        </Typography>
                      </Box>

                      <Box paddingTop={2} width='15%'>
                        <Typography
                          variant='body1'
                          color='primary'
                          style={{ fontWeight: 600 }}
                        >
                          {' '}
                          6 AM
                        </Typography>
                      </Box>

                      <Box paddingTop={2} width='15%'>
                        <Typography
                          variant='body1'
                          color='primary'
                          style={{ fontWeight: 600 }}
                        >
                          {' '}
                          8.30 AM
                        </Typography>
                      </Box>

                      <Box paddingTop={2} width='15%'>
                        <Typography
                          variant='body1'
                          color='primary'
                          style={{ fontWeight: 600 }}
                        >
                          {' '}
                          12:30 PM
                        </Typography>
                      </Box>

                      <Box paddingTop={2} width='15%'>
                        <Typography
                          variant='body1'
                          color='primary'
                          style={{ fontWeight: 600 }}
                        >
                          {' '}
                          2 PM
                        </Typography>
                      </Box>
                      <Box paddingTop={2} width='15%'>
                        <Typography
                          variant='body1'
                          color='primary'
                          style={{ fontWeight: 600 }}
                        >
                          {' '}
                          4:30 PM
                        </Typography>
                      </Box>
                      <Box paddingTop={2} width='15%'>
                        <Typography
                          variant='body1'
                          color='primary'
                          style={{ fontWeight: 600 }}
                        >
                          {' '}
                          6:30 PM
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                {getDietDetails.searchingTreatment && (
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    alignContent='center'
                    alignItems='center'
                    justifyContent='center'
                    justifyItems='center'
                  >
                    {' '}
                    <CircularProgress size={28} />
                  </Box>
                )}

                {getDietDetails.resultsAvailable && (
                  <Grid item xl style={{ padding: 0, margin: 0 }}>
                    <Box display='flex' flexDirection='column' width='100%'>
                      {dietChart.map((dietRow: Diet, index: number) => (
                        <Box
                          display='flex'
                          flexDirection='row'
                          alignItems='flex-start'
                          justifyContent='center'
                          key={`unit_actor_small_details_${dietRow.id}`}
                        >
                          <Box width='12%' py={0.5}>
                            <WelloFormItemLabel
                              title={moment(dietRow.date).format('DD-MM-YYYY')}
                              displayDoneLabel={false}
                              id={`${index}date`}
                            />
                          </Box>

                          <Box px={1} width='20%' py={0.5}>
                            <DietSelector
                              dateString={dietRow.date}
                              nutritionOrders={dietRow.six ?? []}
                              onSelectionChanged={(a, b) => {
                                updateCurrentSelection(a, index, b)
                              }}
                              id={index}
                              searchType='MORN.early'
                              timeOfDay='6AM'
                              disableCheckingOpt={
                                fhirAppointmentDetails.mainServiceRequest
                                  .status !== 'active'
                              }
                              hideChecking={
                                isFutureDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '6AM'
                                ) || !isCurrentUserDoctor()
                              }
                              disableSelection={
                                isPastDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '6AM'
                                ) ||
                                !isCurrentUserDoctor() ||
                                !(
                                  fhirAppointmentDetails.mainServiceRequest
                                    .status === 'active'
                                ) ||
                                isDietCompleted(dietRow.six)
                              }
                              onNutritionIntakeUpdated={(e) => {
                                updateNutritionCompletionStatus(e, index, '6AM')
                              }}
                            />
                          </Box>

                          <Box px={1} width='20%' py={0.5}>
                            <DietSelector
                              dateString={dietRow.date}
                              id={index}
                              nutritionOrders={dietRow.eight ?? []}
                              onSelectionChanged={(a, b) => {
                                updateCurrentSelection(a, index, b)
                              }}
                              searchType='MORN'
                              timeOfDay='830AM'
                              disableCheckingOpt={
                                fhirAppointmentDetails.mainServiceRequest
                                  .status !== 'active'
                              }
                              hideChecking={
                                isFutureDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '830AM'
                                ) || !isCurrentUserDoctor()
                              }
                              disableSelection={
                                isPastDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '830AM'
                                ) ||
                                !isCurrentUserDoctor() ||
                                !(
                                  fhirAppointmentDetails.mainServiceRequest
                                    .status === 'active'
                                ) ||
                                isDietCompleted(dietRow.eight)
                              }
                              onNutritionIntakeUpdated={(e) => {
                                updateNutritionCompletionStatus(
                                  e,
                                  index,
                                  '830AM'
                                )
                              }}
                            />
                          </Box>
                          <Box px={1} width='20%' py={0.5}>
                            <DietSelector
                              dateString={dietRow.date}
                              id={index}
                              nutritionOrders={dietRow.twelve ?? []}
                              onSelectionChanged={(a, b) => {
                                updateCurrentSelection(a, index, b)
                              }}
                              disableCheckingOpt={
                                fhirAppointmentDetails.mainServiceRequest
                                  .status !== 'active'
                              }
                              searchType='MORN.late'
                              timeOfDay='12PM'
                              hideChecking={
                                isFutureDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '12PM'
                                ) || !isCurrentUserDoctor()
                              }
                              disableSelection={
                                isPastDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '12PM'
                                ) ||
                                !isCurrentUserDoctor() ||
                                !(
                                  fhirAppointmentDetails.mainServiceRequest
                                    .status === 'active'
                                ) ||
                                isDietCompleted(dietRow.twelve)
                              }
                              onNutritionIntakeUpdated={(e) => {
                                updateNutritionCompletionStatus(
                                  e,
                                  index,
                                  '12PM'
                                )
                              }}
                            />
                          </Box>
                          <Box px={1} width='20%' py={0.5}>
                            <DietSelector
                              dateString={dietRow.date}
                              id={index}
                              nutritionOrders={dietRow.two ?? []}
                              onSelectionChanged={(a, b) => {
                                updateCurrentSelection(a, index, b)
                              }}
                              disableCheckingOpt={
                                fhirAppointmentDetails.mainServiceRequest
                                  .status !== 'active'
                              }
                              searchType='NOON'
                              timeOfDay='2PM'
                              hideChecking={
                                isFutureDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '2PM'
                                ) || !isCurrentUserDoctor()
                              }
                              disableSelection={
                                isPastDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '2PM'
                                ) ||
                                !isCurrentUserDoctor() ||
                                !(
                                  fhirAppointmentDetails.mainServiceRequest
                                    .status === 'active'
                                ) ||
                                !(
                                  fhirAppointmentDetails.mainServiceRequest
                                    .status === 'active'
                                ) ||
                                isDietCompleted(dietRow.two)
                              }
                              onNutritionIntakeUpdated={(e) => {
                                updateNutritionCompletionStatus(e, index, '2PM')
                              }}
                            />
                          </Box>
                          <Box px={1} width='20%' py={0.5}>
                            <DietSelector
                              dateString={dietRow.date}
                              nutritionOrders={dietRow.five ?? []}
                              id={index}
                              onSelectionChanged={(a, b) => {
                                updateCurrentSelection(a, index, b)
                              }}
                              disableCheckingOpt={
                                fhirAppointmentDetails.mainServiceRequest
                                  .status !== 'active'
                              }
                              searchType='EVE'
                              timeOfDay='5PM'
                              hideChecking={
                                isFutureDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '5PM'
                                ) || !isCurrentUserDoctor()
                              }
                              disableSelection={
                                isPastDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '5PM'
                                ) ||
                                !isCurrentUserDoctor() ||
                                !(
                                  fhirAppointmentDetails.mainServiceRequest
                                    .status === 'active'
                                ) ||
                                isDietCompleted(dietRow.five)
                              }
                              onNutritionIntakeUpdated={(e) => {
                                updateNutritionCompletionStatus(e, index, '5PM')
                              }}
                            />
                          </Box>
                          <Box px={1} width='20%' py={0.5}>
                            <DietSelector
                              dateString={dietRow.date}
                              id={index}
                              nutritionOrders={dietRow.seven ?? []}
                              onSelectionChanged={(a, b) => {
                                updateCurrentSelection(a, index, b)
                              }}
                              searchType='NIGHT'
                              timeOfDay='7PM'
                              disableCheckingOpt={
                                fhirAppointmentDetails.mainServiceRequest
                                  .status !== 'active'
                              }
                              disableSelection={
                                isPastDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '7PM'
                                ) ||
                                !isCurrentUserDoctor() ||
                                !(
                                  fhirAppointmentDetails.mainServiceRequest
                                    .status === 'active'
                                ) ||
                                isDietCompleted(dietRow.seven)
                              }
                              hideChecking={
                                isFutureDateTimeForDiet(
                                  moment(dietRow.date).format('DD-MM-YYYY'),
                                  '7PM',
                                  3
                                ) || !isCurrentUserDoctor()
                              }
                              onNutritionIntakeUpdated={(e) => {
                                updateNutritionCompletionStatus(e, index, '7PM')
                              }}
                            />
                          </Box>
                          <Box py={0.5} width='10%'>
                            {status !== 'completed' && (
                              <Button
                                variant='contained'
                                size='small'
                                color='primary'
                                id={`${index}button`}
                                onClick={() => {
                                  dispatch(
                                    addDietDetails(
                                      dietChart[index],
                                      fhirAppointmentDetails
                                    )
                                  )
                                  setDietChart([])
                                }}
                              >
                                <Typography variant='subtitle2'>
                                  {isExistingDiet(dietRow) ? 'Update' : 'Add'}
                                </Typography>
                              </Button>
                            )}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </ReactVisibilitySensor>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
