/* eslint-disable no-await-in-loop */
import axios from 'axios'
import _ from 'lodash'
import { getAccessToken } from 'utils/authHelpers'
import { isDoctor, isOnlyDoctor } from 'services/userDetailsService'
import { cartState } from 'models/medicine-delivery/cart/cart-states'
import { useRecoilValue } from 'recoil'
import { Cart, CartItem } from 'models/medicine-delivery/cart/cart-models'
import {
  Category,
  ProductDetail,
  ProductsByCategory,
  Sku,
} from '../../models/medicine-delivery/product-models'
import {
  amalakiChurnDetails,
  asavaArishta,
  delay,
  haritakiChurnDetails,
  powderChurn,
} from '../samples'
import { ItemGroupsResponse } from '../../models/medicine-delivery/items-groups-response'
import {
  Item,
  ItemDetailResponse,
} from '../../models/medicine-delivery/item-detail-response'

const token = getAccessToken() != null ? getAccessToken() : ''

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
}
const baseUrl = 'https://dev.apis.wellopathy.com/apis/cc-workflows/inventory/v1'
const zohoProxy = 'https://dev.apis.wellopathy.com/apis/cc-workflows/zohoProxy'
const ccWorkflowsUrl = process.env.REACT_APP_CC_WORKFLOW_URL
const defaultDiscount = 0.05

export const getSampleProducts = async () => {
  const productsByCategory = [
    {
      category: powderChurn,
      products: [
        {
          category: powderChurn,
          name: 'haritakiChurn',
          title: 'Haritaki Churn',
        },
        {
          category: powderChurn,
          name: 'amalakiChurn',
          title: 'Aamlaki Chrun',
        },
        {
          category: powderChurn,
          name: 'vibhitakiChrn',
          title: 'Vibhitaki Chrun',
        },
      ],
    },
    {
      category: asavaArishta,
      products: [
        {
          category: asavaArishta,
          name: 'productAsava',
          title: 'Product Asava',
        },
      ],
    },
  ] as ProductsByCategory[]
  await delay()
  return productsByCategory
}

export const getSampleProductDetails = async (name: string) => {
  await delay()
  if (name === 'haritakiChurn') {
    return haritakiChurnDetails
  }
  if (name === 'amalakiChurn') {
    return amalakiChurnDetails
  }
  return undefined
}

export const getProducts = async () => {
  const productsByCategory: ProductsByCategory[] = []
  try {
    const url = `${baseUrl}/itemgroups`
    const response = await axios.get<ItemGroupsResponse>(url, config)
    if (response.status === 200 && response.data.message !== 'success') {
      // TODO;
      console.log('API error', response)
    }
    const { data } = response
    for (const group of data.itemgroups) {
      if (isOnlyDoctor()) {
        if (group.group_name === 'Allopathy Medicines') {
          const category = {
            name: group.group_id,
            title: group.group_name,
          } as Category
          let products = group.items.map((i) => ({
            category,
            name: i.item_id,
            title: i.name,
          }))
          // zoho-proxy api retuning items with same title, for SKU support, hence deduping it here
          products = _.uniqBy(products, 'title')
          productsByCategory.push({ category, products })
        }
      } else if (group.group_name !== 'Allopathy Medicines') {
        const category = {
          name: group.group_id,
          title: group.group_name,
        } as Category
        let products = group.items.map((i) => ({
          category,
          name: i.item_id,
          title: i.name,
        }))
        // zoho-proxy api retuning items with same title, for SKU support, hence deduping it here
        products = _.uniqBy(products, 'title')
        productsByCategory.push({ category, products })
      }
    }
    console.log(response)
  } catch (error) {
    console.error(error)
  }
  return productsByCategory
}

const fetchImage2 = async (itemId: string) => {
  let urlData: string = ''
  try {
    const url = `${ccWorkflowsUrl}inventory/v1/items/${itemId}/image`
    const myHeaders = new Headers()
    myHeaders.set('Authorization', `Bearer ${token}`)
    const image = await axios(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    const byteCharacters = atob(image.data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const imageData = new Blob([byteArray], { type: 'image/jpeg' })
    urlData = URL.createObjectURL(imageData)
    console.log(urlData)
  } catch (error) {
    console.error('Error:', error)
  }
  return urlData
}

export async function fetchImageData(appointmentId: string): Promise<string> {
  let urlData: string = ''
  try {
    const url = `${ccWorkflowsUrl}inventory/v1/items/${appointmentId}/image`
    const myHeaders = new Headers()
    myHeaders.set('Authorization', `Bearer ${token}`)
    const image = await axios(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    const byteCharacters = atob(image.data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const imageData = new Blob([byteArray], { type: 'image/jpeg' })
    urlData = URL.createObjectURL(imageData)
    console.log(urlData)
  } catch (error) {
    console.error('Error:', error)
  }
  return urlData
}

const fetchImage = async () => {
  // const url = `${zohoProxy}/items/1315841000000018001/image?organization_id=60021308872`;
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     responseType: "blob",
  //   },
  // };
  const url = `https://www.zohoapis.in/inventory/v1//items/1315841000000018001/image?organization_id=60021308872`
  const configForImages = {
    headers: {
      Authorization: `Bearer 1000.3186691b92639f34f5ba5c1035b8582a.2bf9dde76c45a52f0702a1d5deee7ddf`,
      responseType: 'blob',
    },
  }
  //
  // Make the Axios request to fetch the image
  const response = await axios.get(url, configForImages)
  console.log('i: raw ', response)
  const blob = response.data
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
  const imageUrl = URL.createObjectURL(blob)
  return imageUrl
}

const buildSKUs = (
  items: Item[],
  discounts: { itemId: string; welloItemDiscountFee: number }[]
) =>
  items.map((i) => {
    const disc = discounts.find((d) => d.itemId === i.item_id)
    const percent = (disc?.welloItemDiscountFee || defaultDiscount) * 100 // API is returning 0.05 as 5%
    return {
      discount: percent,
      inStock: i.available_stock > 0,
      price: i.rate,
      sku: i.sku,
      id: i.item_id,
    } as Sku
  })

const getDiscounts = async (
  data: { itemId: string; amount: number; quantity: number }[]
) => {
  const defaultDiscounts = data.map((i) => ({
    itemId: i.itemId,
    welloItemDiscountFee: defaultDiscount,
  }))
  try {
    const url = `${baseUrl}/payment/itemsDiscount`
    const response = await axios.post<
      { itemId: string; welloItemDiscountFee: number }[]
    >(url, data, config)
    if (response.status !== 201) {
      console.error('getDiscount API not successful')
      return defaultDiscounts
    }
    return response.data
  } catch (error) {
    console.error('getDiscount: ', error)
  }
  return defaultDiscounts
}

export const getProductDetails = async (title: string) => {
  try {
    const url = `${baseUrl}/items?name=${title}`
    const response = await axios.get<ItemDetailResponse>(url, config)
    if (response.status === 200 && response.data.message !== 'success') {
      // TODO;
      console.error('API error', response)
      return undefined
    }
    const { data } = response
    const { items } = data
    const item = items[0]
    if (!item) {
      return undefined
    }
    const discountRequest = data.items.map((i) => ({
      itemId: i.item_id,
      quantity: 1,
      amount: i.rate,
    }))
    const discounts = await getDiscounts(discountRequest)
    const skus = buildSKUs(items, discounts)
    // const imageRes = await fetchImage();
    // console.log(imageRes);
    const img = await fetchImage2(item.item_id)
    const imageUrl =
      'https://onemg.gumlet.io/l_watermark_346,w_690,h_700/a_ignore,w_690,h_700,c_pad,q_auto,f_auto/zqfr5afpxr2bkiwejowq.jpg'

    const product: ProductDetail = {
      category: {
        name: item.group_id || '',
        title: item.group_name || '',
      },
      name: item.item_id,
      title: item.item_name,
      description: item.description,
      image: img,
      skus,
    }
    return product
  } catch (error) {
    console.error(error)
  }
  return undefined
}

export const getCartDetails = async (cartStateData: Cart) => {
  try {
    const appointmentData: Cart = {
      ...cartStateData,
    }
    const cartItemData: CartItem[] = []
    for (let i = 0; i < appointmentData.items.length; i++) {
      const img: string = await fetchImage2(
        appointmentData.items[i].product.name
      )

      //   appointmentData.items[i].product.image = img
      cartItemData.push({
        customerDiscount: appointmentData.items[i].customerDiscount,
        product: {
          category: {
            name: appointmentData.items[i].product.category.name,
            title: appointmentData.items[i].product.title,
          },
          description: appointmentData.items[i].product.description,
          name: appointmentData.items[i].product.name,
          sku: appointmentData.items[i].product.sku,
          title: appointmentData.items[i].product.title,
          image: img,
        },
        quantity: appointmentData.items[i].quantity,
      })
    }
    appointmentData.items = cartItemData

    return appointmentData
  } catch (error) {
    console.error(error)
  }
  return undefined
}
