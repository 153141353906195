import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AssociatedComplaintData } from 'models/chiefComplaintData'
import { EnrolData } from 'models/enrolData'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { fetchAppointmentDetails } from 'redux/appointments/appointmentViewHandler/appointmentHandlerSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import {
  getAppointmentIdForFollowUp,
  getIdentifierValueBySystem,
  getNameFromHumanName,
} from 'utils/fhirResourcesHelper'
import { getPatientIdentifiersForSearch } from 'utils/fhirResoureHelpers/patientHelpers'
import { logger } from 'utils/logger'
import { getNameOfPatient } from 'wello-web-components'
import { ChiefComplaintsStatus } from './followUpChiefComplaintStatusType'

const initialState: ChiefComplaintsStatus = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  updatedConditions: false,
  updatingConditions: false,
  errorWhileSearchingProcedures: false,
  errorWhileUpdatingProcedures: false,
}

const count: number = 0

const followUpChiefComplaintSlice = createSlice({
  name: 'followUpChiefComplaintSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<ChiefComplaintsStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.chiefCondition = action.payload.chiefCondition
      state.associatedCondition = action.payload.associatedCondition
      state.updatedConditions = action.payload.updatedConditions
      state.updatingConditions = action.payload.updatingConditions
      state.errorWhileUpdatingProcedures =
        action.payload.errorWhileUpdatingProcedures
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
    },
    resetChiefComplaintDetails(
      state,
      action: PayloadAction<ChiefComplaintsStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.chiefCondition = action.payload.chiefCondition
      state.associatedCondition = action.payload.associatedCondition
      state.updatedConditions = action.payload.updatedConditions
      state.updatingConditions = action.payload.updatingConditions
      state.errorWhileUpdatingProcedures =
        action.payload.errorWhileUpdatingProcedures
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
    },
  },
})

export const getChiefComplaintOfFollowUpAppointment =
  (
    fhirAppointmentDetails: FhirAppointmentDetail,
    split?: boolean,
    followUp?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: ChiefComplaintsStatus = { ...initialState }
    state.searchingConditions = true
    dispatch(followUpChiefComplaintSlice.actions.updatedStatus(state))
    try {
      const appointmentId: string = getAppointmentIdForFollowUp(
        fhirAppointmentDetails.appointment.supportingInformation ?? []
      )
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const endDate = `${moment(
        moment(moment(moment()).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      )
        .subtract(90, 'days')
        .format('DD-MM-YYYY')}`
      let response: any
      const currentDate = moment(moment().format('DD-MM-YYYY'), 'DD-MM-YYYY')
      const convertedEndDate = moment(endDate, 'DD-MM-YYYY')
      const dateForQuery = `${moment(
        moment(moment(moment()).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      )
        .subtract(90, 'days')
        .format('YYYY-MM-DD')}`

      const diffInDays = currentDate.diff(convertedEndDate, 'days')
      let diffInMonths = currentDate.diff(convertedEndDate, 'months')
      const diffInYears = currentDate.diff(convertedEndDate, 'years')

      if (diffInMonths === 2) {
        diffInMonths += 1
      }

      if (split === false) {
        const searchParameters: any = {
          'encounter.appointment': appointmentId,
          category: 'chief-complaint',
          _sort: '_lastUpdated',
        }
        if (followUp) {
          response = await fhirClient.doGetResourceForAppointment(
            '/Condition?clinical-status=active&_revinclude:iterate=Condition:condition-chief-comp',
            appointmentId,
            searchParameters
          )
        } else {
          response = await fhirClient.doGetResourceForAppointment(
            '/Condition?clinical-status=active&_revinclude:iterate=Condition:condition-chief-comp',
            fhirAppointmentDetails.appointment.id!,
            searchParameters
          )
        }
      } else {
        const searchParametersForSplit: any = {
          category: 'chief-complaint',
          _sort: '-_lastUpdated',
        }

        if (
          getPatientIdentifiersForSearch(fhirAppointmentDetails.patient)
            .length > 0
        ) {
          searchParametersForSplit['patient:Patient.identifier'] =
            getPatientIdentifiersForSearch(fhirAppointmentDetails.patient)
        }
        if (followUp) {
          response = await fhirClient.doGetResourceForAppointment(
            `/Condition?clinical-status=active&_revinclude:iterate=Condition:condition-chief-comp&_filter=onset-age le ${diffInDays} or onset-date gt ${dateForQuery}`,
            appointmentId,
            searchParametersForSplit
          )
        } else {
          response = await fhirClient.doGetResourceForAppointment(
            `/Condition?clinical-status=active&_revinclude:iterate=Condition:condition-chief-comp&_filter=onset-age le ${diffInDays} or onset-date gt ${dateForQuery}`,
            fhirAppointmentDetails.appointment.id!,
            searchParametersForSplit
          )
        }
      }

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingProcedures = true
        state.searchingConditions = false

        dispatch(followUpChiefComplaintSlice.actions.updatedStatus(state))
      } else {
        const conditionResponse: R4.IBundle = resp.right
        logger.info(
          'Chief Complaint resp',
          conditionResponse.entry?.[0].resource as R4.ICondition
        )
        if (conditionResponse?.total && conditionResponse?.total > 0) {
          const conditionData: R4.ICondition[] = []
          const associatedComplaints: R4.ICondition[] = []
          if (conditionResponse.entry) {
            for (let i = 0; i < conditionResponse.entry.length; i++) {
              const condition: R4.ICondition = conditionResponse.entry[i]
                .resource as R4.ICondition

              const active =
                condition.clinicalStatus && condition.clinicalStatus.coding
                  ? condition.clinicalStatus.coding[0].code === 'active'
                  : false

              const cat: string =
                condition.category && condition.category[0].coding
                  ? condition.category[0].coding[0].code ?? ''
                  : ''
              if (split) {
                if (active) {
                  if (cat !== 'associated-complaint') {
                    if (condition.onsetAge) {
                      const measure: string = condition.onsetAge
                        ? condition.onsetAge.code ?? ''
                        : ''
                      if (measure === 'mo') {
                        const monthVal = condition.onsetAge
                          ? condition.onsetAge.value ?? 0
                          : 0

                        if (diffInMonths >= monthVal) {
                          conditionData.push(condition)
                        }
                      } else if (measure === 'a') {
                        const yearVal = condition.onsetAge
                          ? condition.onsetAge.value ?? 0
                          : 0
                        if (diffInYears >= yearVal) {
                          conditionData.push(condition)
                        }
                      } else {
                        conditionData.push(condition)
                      }
                    } else {
                      conditionData.push(condition)
                    }
                  } else {
                    const ashCondition: R4.ICondition = conditionResponse.entry[
                      i
                    ].resource as R4.ICondition

                    if (ashCondition.onsetAge) {
                      const measure: string = ashCondition.onsetAge
                        ? ashCondition.onsetAge.code ?? ''
                        : ''
                      if (measure === 'mo') {
                        const monthVal = ashCondition.onsetAge
                          ? ashCondition.onsetAge.value ?? 0
                          : 0
                        if (diffInMonths >= monthVal) {
                          associatedComplaints.push(ashCondition)
                        }
                      } else if (measure === 'a') {
                        const yearVal = condition.onsetAge
                          ? condition.onsetAge.value ?? 0
                          : 0
                        if (diffInYears >= yearVal) {
                          associatedComplaints.push(ashCondition)
                        }
                      } else {
                        associatedComplaints.push(ashCondition)
                      }
                    } else {
                      associatedComplaints.push(ashCondition)
                    }

                    // associatedComplaints.push(
                    //   conditionResponse.entry[i].resource as R4.ICondition
                    // )
                  }
                }
              } else if (active) {
                if (cat !== 'associated-complaint') {
                  conditionData.push(
                    conditionResponse.entry[i].resource as R4.ICondition
                  )
                } else {
                  associatedComplaints.push(
                    conditionResponse.entry[i].resource as R4.ICondition
                  )
                }
              }
            }
          }
          if (conditionData.length === 0) {
            localStorage.setItem('count', conditionData.length.toString())
            const errorSearchDoctor: ChiefComplaintsStatus = { ...initialState }
            errorSearchDoctor.noResultsAvailable = true
            dispatch(
              followUpChiefComplaintSlice.actions.updatedStatus(
                errorSearchDoctor
              )
            )
          } else {
            localStorage.setItem('count', conditionData.length.toString())
            state.resultsAvailable = true
            state.searchingConditions = false
            state.chiefCondition = conditionData
            state.associatedCondition = associatedComplaints.sort((a, b) =>
              (a.id ? a.id : '') < (b.id ? b.id : '')
                ? -1
                : (a.id ?? '') > (b.id ?? '')
                ? 1
                : 0
            )

            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingProcedures = false
            dispatch(followUpChiefComplaintSlice.actions.updatedStatus(state))
          }
        } else {
          localStorage.setItem('count', '0')
          const errorSearchDoctor: ChiefComplaintsStatus = { ...initialState }
          errorSearchDoctor.noResultsAvailable = true
          dispatch(
            followUpChiefComplaintSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: ChiefComplaintsStatus = { ...initialState }
      errorSearchDoctor.errorReason =
        'Error while fetching chief complaint details'
      errorSearchDoctor.errorWhileSearchingProcedures = false
      dispatch(
        followUpChiefComplaintSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export const updateChiefComplaintDetails =
  (
    appointment: FhirAppointmentDetail,
    complaintCode: R4.ICoding,
    typeData: string,
    days: number,
    weeks: number,
    month: number,
    year: number,
    operation: string,
    id: string,
    other: boolean,
    notes: string,
    location?: R4.ICoding[],
    onsetDate?: Date,
    severity?: R4.ICoding,
    duration?: string,
    character?: string,
    alleviatingFactor?: string,
    radiation?: string,
    temporal?: string,
    otherData?: string,
    patientWording?: string,
    checked?: boolean,
    associatedComplaints?: AssociatedComplaintData[],
    selectedPainProfileSymptoms?: R4.ICoding[],
    associatedChecked?: boolean,
    painDuration?: number
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let state: ChiefComplaintsStatus = { ...initialState }
    state.updatingConditions = true
    dispatch(followUpChiefComplaintSlice.actions.updatedStatus(state))
    try {
      const bundleObject: R4.IBundle = createBundleObjectForCHiefComplaint(
        appointment,
        complaintCode,
        typeData,
        days,
        weeks,
        month,
        year,
        operation,

        id,
        other,
        notes,
        location,
        onsetDate,
        severity,
        duration,
        character,
        alleviatingFactor,
        radiation,
        temporal,
        otherData,
        patientWording,
        checked,
        associatedComplaints,
        selectedPainProfileSymptoms,
        associatedChecked,
        painDuration
      )

      const countdata = localStorage.getItem('count')
      const fhirApi: FHIRApiClient = new FHIRApiClient()
      const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const conditionResponse: R4.IBundle = relatedFhirDecodeRes.right
        const conditionData: R4.ICondition[] = []
        conditionData.push(
          conditionResponse.entry?.[1].resource as R4.ICondition
        )
        if (operation === 'add') {
          const serviceId: string = await getServiceRequestId(
            appointment.patient.id ?? ''
          )
          if (serviceId.length > 0) {
            let conditionId: string = ''
            if (conditionResponse.entry) {
              const entryObj =
                conditionResponse.entry?.[1].response?.location ?? ''
              const pateintObj = entryObj.replace('/_history/1', '')
              // eslint-disable-next-line prefer-destructuring
              conditionId = pateintObj.split('/')[1]
            }
            const enolmentData: EnrolData = {
              observation: [''],
              procedure: [''],
              condition: [conditionId],
              serviceRequestId: serviceId,
            }
            const status: string = await addEnroment(enolmentData)
            state = { ...initialState }
            state.updatingConditions = false
            state.updatedConditions = true
            state.chiefCondition = conditionData
            /*  dispatch(
              fetchAppointmentDetails(appointment.appointment.id ?? '', true)
            ) */
            dispatch(followUpChiefComplaintSlice.actions.updatedStatus(state))
            if (operation === 'add' || operation === 'update')
              dispatch(
                showSuccessAlert(
                  operation === 'add'
                    ? 'Chief Complaints added successfully'
                    : 'Chief Complaints updted successfully'
                )
              )
            else
              dispatch(
                showSuccessAlert('Chief Complaints deleted successfully')
              )
            return
          }
          state = { ...initialState }
          state.updatingConditions = false
          state.updatedConditions = true
          state.chiefCondition = conditionData

          dispatch(followUpChiefComplaintSlice.actions.updatedStatus(state))
          if (operation === 'add' || operation === 'update')
            dispatch(
              showSuccessAlert(
                operation === 'add'
                  ? 'Chief Complaints added successfully'
                  : 'Chief Complaints updted successfully'
              )
            )
          else
            dispatch(showSuccessAlert('Chief Complaints deleted successfully'))
          return
        }
        state = { ...initialState }
        state.updatingConditions = false
        state.updatedConditions = true
        state.chiefCondition = conditionData

        dispatch(followUpChiefComplaintSlice.actions.updatedStatus(state))
        if (operation === 'add' || operation === 'update')
          dispatch(
            showSuccessAlert(
              operation === 'add'
                ? 'Chief Complaints added successfully'
                : 'Chief Complaints updted successfully'
            )
          )
        else dispatch(showSuccessAlert('Chief Complaints deleted successfully'))

        return
      }

      const errorSearchDoctor: ChiefComplaintsStatus = { ...initialState }
      errorSearchDoctor.errorWhileUpdatingProcedures = true
      errorSearchDoctor.errorReason = 'error while updating . try later'
      dispatch(
        followUpChiefComplaintSlice.actions.updatedStatus(errorSearchDoctor)
      )
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: ChiefComplaintsStatus = { ...initialState }
      errorSearchDoctor.errorReason =
        'Error while adding/updating chief complaint details'
      errorSearchDoctor.errorWhileUpdatingProcedures = false
      dispatch(
        followUpChiefComplaintSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export const resetChiefComplaintDetailsFollowUp =
  () => (dispatch: AppDispatch) => {
    dispatch(
      followUpChiefComplaintSlice.actions.resetChiefComplaintDetails(
        initialState
      )
    )
  }

async function getServiceRequestId(patientId: string): Promise<string> {
  logger.info('Patient invitation body')
  let serviceRequestId: string = ''
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirClient.doGetResource(
    `/ServiceRequest??code=chronic-care-enrolment&subject=${patientId}`
  )
  logger.info('Patient invitation Response')
  logger.info(response)
  const respDecoded: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (respDecoded._tag === 'Right') {
    logger.info('Response Patient un decoded', respDecoded.right)
    const responseBundel: R4.IBundle = respDecoded.right
    if (responseBundel.entry) {
      const entryObj = responseBundel.entry?.[0].resource as R4.IServiceRequest
      serviceRequestId =
        entryObj && entryObj.status === 'active' ? entryObj.id ?? ' ' : ''
      return serviceRequestId
    }

    return serviceRequestId
  }

  return serviceRequestId
}

async function addEnroment(enrolData: EnrolData): Promise<string> {
  logger.info('Enrolment Taken')

  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `/enrolment/pre-appt/patient`,
      enrolData
    )

    return response.success
  } catch (error) {
    return 'false'
  }
}

function createBundleObjectForCHiefComplaint(
  appointment: FhirAppointmentDetail,
  complaintCode: R4.ICoding,
  typeData: string,
  days: number,
  weeks: number,
  months: number,
  years: number,
  operation: string,
  id: string,
  other: boolean,
  notes: string,
  location?: R4.ICoding[],
  onsetDate?: Date,
  severity?: R4.ICoding,
  duration?: string,
  character?: string,
  alleviatingFactor?: string,
  radiation?: string,
  temporal?: string,
  otherData?: string,
  patientWording?: string,
  checked?: boolean,
  associatedComplaints?: AssociatedComplaintData[],
  selectedPainProfileSymptoms?: R4.ICoding[],
  associatedChecked?: boolean,
  painDuration?: number
): R4.IBundle {
  let codeData: R4.ICoding = complaintCode

  if (other) {
    codeData = {
      system:
        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-medical-condition-cs',
      code: 'other-complaint',
      display: otherData ?? '',
    }
  }
  const chiefComplaintExtns: R4.IExtension[] = []
  if (checked && checked === true) {
    if (alleviatingFactor) {
      chiefComplaintExtns.push({
        url: 'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/AllevatingFactor',
        valueString: alleviatingFactor,
      })
    }
    if (character) {
      chiefComplaintExtns.push({
        url: 'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Character',
        valueString: character,
      })
    }
    if (radiation) {
      chiefComplaintExtns.push({
        url: 'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Radiation',
        valueString: radiation,
      })
    }
    if (temporal) {
      chiefComplaintExtns.push({
        url: 'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Temporal',
        valueString: temporal,
      })
    }
    if (duration) {
      if (painDuration) {
        chiefComplaintExtns.push({
          url: 'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration',
          valueString: `${painDuration} ${duration}`,
        })
      } else {
        chiefComplaintExtns.push({
          url: 'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration',
          valueString: `${duration}`,
        })
      }
    }
  }

  const encounter: R4.IEncounter = {
    resourceType: 'Encounter',
    class: {
      system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
      code: 'AMB',
      display: 'ambulatory',
    },
    status: R4.EncounterStatusKind._inProgress,
    subject: {
      display: getNameOfPatient(appointment.patient),
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      id: appointment.patient.id,
      type: appointment.patient.resourceType,
    },
    appointment: [
      {
        reference: `${appointment.appointment.resourceType}/${appointment.appointment.id}`,
        id: appointment.appointment.id,
        type: appointment.appointment.resourceType,
      },
    ],
    participant: [
      {
        type: [
          {
            text: 'primary performer',
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/v3-ParticipationType',
                code: 'PPRF',
                display: 'primary performer',
              },
            ],
          },
        ],
        individual: {
          display: getNameFromHumanName(
            appointment.practitionerDetail.practitioner?.name ?? []
          ),
          reference: `${appointment.practitionerDetail.practitioner?.resourceType}/${appointment.practitionerDetail.practitioner?.id}`,
          id: appointment.practitionerDetail.practitioner?.id,
          type: appointment.practitionerDetail.practitioner?.resourceType,
        },
      },
      {
        type: [
          {
            text: 'primary performer',
            coding: [
              {
                system:
                  'http://terminology.hl7.org/CodeSystem/v3-ParticipationType',
                code: 'PPRF',
                display: 'primary performer',
              },
            ],
          },
        ],
        individual: {
          reference: `${appointment.practitionerDetail.practitionerRole?.resourceType}/${appointment.practitionerDetail.practitionerRole?.id}`,
          id: appointment.practitionerDetail.practitionerRole?.id,
          type: appointment.practitionerDetail.practitionerRole?.resourceType,
        },
      },
    ],
  }

  const pract: R4.IPractitioner = getCurrentUserPractitionerDetails()
  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()

  const condtionData: R4.ICondition = {
    resourceType: 'Condition',
    meta: {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndConditionChiefComplaint',
      ],
    },
    subject: {
      display: getNameOfPatient(appointment.patient),
      id: appointment.patient.id,
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      type: appointment.patient.resourceType,
    },

    code: {
      coding: [codeData],
      text: codeData.display,
    },

    clinicalStatus: {
      coding: [
        {
          code: operation === 'delete' ? 'inactive' : 'active',
          system: 'http://terminology.hl7.org/ValueSet/condition-clinical',
          display: operation === 'delete' ? 'inActive' : 'Active',
        },
      ],
    },
    bodySite: location
      ? location.map((val) => {
          const site: R4.ICodeableConcept = {
            text: val.display,
            coding: [val],
          }
          return site
        })
      : [],
    recorder: {
      display: getNameFromHumanName(pract.name ?? []),
      id: practRole.id,
      reference: `${practRole.resourceType}/${practRole.id}`,
      type: practRole.resourceType,
    },

    severity: severity
      ? {
          coding: [severity],
        }
      : {},
    category: [
      {
        coding: [
          {
            code: 'chief-complaint',
            display: 'Chief Complaint',
            system: 'http://wellopathy.com/condition-category',
          },
        ],
      },
    ],
    encounter: {
      reference: `${encounter.resourceType}/urn:uuid:1232323232324`,
      type: encounter.resourceType,
    },
    extension: chiefComplaintExtns,
    verificationStatus: {
      text: 'Unconfirmed',
      coding: [
        {
          code: 'unconfirmed',
          display: 'Unconfirmed',
          system: 'http://terminology.hl7.org/CodeSystem/condition-ver-status',
        },
      ],
    },
  }
  if (checked && checked === true) {
    if (selectedPainProfileSymptoms && selectedPainProfileSymptoms.length > 0) {
      const evidenceCodeArry: R4.ICondition_Evidence[] = []
      for (let i = 0; i < selectedPainProfileSymptoms.length; i++) {
        const evidenceCode: R4.ICondition_Evidence = {
          code: [
            {
              coding: [selectedPainProfileSymptoms[i]],
            },
          ],
        }
        evidenceCodeArry.push(evidenceCode)
        if (evidenceCodeArry.length > 0) {
          condtionData.evidence = evidenceCodeArry
        }
      }
    }
  }

  if (typeData.length > 0) {
    if (typeData === 'days') {
      if (days > 0) {
        condtionData.onsetAge = {
          system: 'http://unitsofmeasure.org',
          unit: 'd',
          code: 'd',
          value: days,
        }
      }
    } else if (typeData === 'months') {
      condtionData.onsetAge = {
        system: 'http://unitsofmeasure.org',
        unit: 'mo',
        code: 'mo',
        value: months,
      }
    } else if (typeData === 'weeks') {
      condtionData.onsetAge = {
        system: 'http://unitsofmeasure.org',
        unit: 'wk',
        code: 'wk',
        value: weeks,
      }
    } else if (typeData === 'years') {
      condtionData.onsetAge = {
        system: 'http://unitsofmeasure.org',
        unit: 'a',
        code: 'a',
        value: years,
      }
    } else if (typeData === 'custom') {
      if (onsetDate) condtionData.onsetDateTime = onsetDate.toISOString()
    }
  }

  const noteData: R4.IAnnotation[] = []
  if (notes) {
    if (notes.length > 0) {
      noteData.push({
        authorReference: {
          reference: `${pract.resourceType}/${pract.id}`,
        },
        text: notes,
        time: new Date().toISOString(),
      })
    }
  }
  if (patientWording) {
    if (patientWording.length > 0) {
      noteData.push({
        authorReference: {
          reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
        },
        text: patientWording,
        time: new Date().toISOString(),
      })
    }
  }
  if (noteData.length > 0) {
    condtionData.note = noteData
  }

  const fullUrlEncounter: string = 'urn:uuid:1232323232324'
  const fullUrlCondition: string = 'urn:uuid:1232323232325'

  const matchStringEncounter: string = `${encounter.resourceType}?appointment=${appointment.appointment.resourceType}/${appointment.appointment.id}`
  const matchStringClinicalImpression: string = `Condition?encounter=${fullUrlEncounter}&category=chief-complaint&code=${
    complaintCode.code ?? ''
  }`

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: fullUrlEncounter,
        request: {
          url: matchStringEncounter,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: encounter,
      },
      {
        fullUrl: id.length === 0 ? 'urn:uuid:1232323232325' : `Condition/${id}`,
        request: {
          url:
            id.length === 0 ? matchStringClinicalImpression : `Condition/${id}`,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: condtionData,
      },
    ],
  }
  if (associatedComplaints && associatedComplaints.length) {
    for (let i = 0; i < associatedComplaints.length; i++) {
      if (
        associatedComplaints[i].cui &&
        associatedComplaints[i].patientWording.length > 0
      ) {
        const additionalComplaints: R4.ICondition = {
          resourceType: 'Condition',
          meta: {
            profile: [
              'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndConditionChiefComplaint',
            ],
          },
          subject: {
            display: getNameOfPatient(appointment.patient),
            id: appointment.patient.id,
            reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
            type: appointment.patient.resourceType,
          },
          extension: [
            {
              url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-chief-complaint-ext',
              valueReference: {
                reference: `${condtionData.resourceType}/${
                  id.length === 0 ? 'urn:uuid:1232323232325' : id
                }`,
                type: condtionData.resourceType,
              },
            },
          ],

          code: {
            coding: [associatedComplaints[i].cui!],
            text: associatedComplaints[i].cui!.display,
          },

          clinicalStatus: {
            coding: [
              {
                code:
                  operation === 'delete' || associatedChecked === false
                    ? 'inactive'
                    : 'active',
                system:
                  'http://terminology.hl7.org/ValueSet/condition-clinical',
                display:
                  operation === 'delete' || associatedChecked === false
                    ? 'inActive'
                    : 'Active',
              },
            ],
          },

          recorder: {
            display: getNameFromHumanName(pract.name ?? []),
            id: practRole.id,
            reference: `${practRole.resourceType}/${practRole.id}`,
            type: practRole.resourceType,
          },
          category: [
            {
              coding: [
                {
                  code: 'associated-complaint',
                  display: 'Associated Complaint',
                  system:
                    'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-condition-category-cs',
                },
              ],
            },
          ],
          encounter: {
            reference: `${encounter.resourceType}/urn:uuid:1232323232324`,
            type: encounter.resourceType,
          },
          verificationStatus: {
            text: 'Unconfirmed',
            coding: [
              {
                code: 'unconfirmed',
                display: 'Unconfirmed',
                system:
                  'http://terminology.hl7.org/CodeSystem/condition-ver-status',
              },
            ],
          },
        }

        const noteDataforAssociated: R4.IAnnotation[] = []

        if (associatedComplaints[i].patientWording.length > 0) {
          noteDataforAssociated.push({
            authorReference: {
              reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
            },
            text: associatedComplaints[i].patientWording,
            time: new Date().toISOString(),
          })
        }

        if (noteDataforAssociated.length > 0) {
          additionalComplaints.note = noteDataforAssociated
        }

        if (associatedComplaints[i].typeData.length > 0) {
          if (associatedComplaints[i].typeData === 'days') {
            if (associatedComplaints[i].days > 0) {
              additionalComplaints.onsetAge = {
                system: 'http://unitsofmeasure.org',
                unit: 'd',
                code: 'd',
                value: associatedComplaints[i].days,
              }
            }
          } else if (associatedComplaints[i].typeData === 'months') {
            additionalComplaints.onsetAge = {
              system: 'http://unitsofmeasure.org',
              unit: 'mo',
              code: 'mo',
              value: associatedComplaints[i].months,
            }
          } else if (associatedComplaints[i].typeData === 'weeks') {
            additionalComplaints.onsetAge = {
              system: 'http://unitsofmeasure.org',
              unit: 'wk',
              code: 'wk',
              value: associatedComplaints[i].weeks,
            }
          } else if (associatedComplaints[i].typeData === 'years') {
            additionalComplaints.onsetAge = {
              system: 'http://unitsofmeasure.org',
              unit: 'a',
              code: 'a',
              value: associatedComplaints[i].year,
            }
          } else if (associatedComplaints[i].typeData === 'custom') {
            additionalComplaints.onsetDateTime =
              associatedComplaints[i].date.toISOString()
          }
        }

        const matchStringClinicalImpressionForAdditional: string = `Condition?encounter=${fullUrlEncounter}&category=associated-complaint&code=${
          associatedComplaints[i].cui!.code ?? ''
        }`

        const entry: R4.IBundle_Entry = {
          fullUrl: associatedComplaints[i].preselectedId
            ? `Condition/${associatedComplaints[i].preselectedId}`
            : `urn:uuid:1232323232325${i}`,
          request: {
            url: associatedComplaints[i].preselectedId
              ? `Condition/${associatedComplaints[i].preselectedId}`
              : matchStringClinicalImpressionForAdditional,
            method: R4.Bundle_RequestMethodKind._put,
          },

          resource: additionalComplaints,
        }
        requestBundle.entry?.push(entry)
      }
    }
  }

  return requestBundle
}

export default followUpChiefComplaintSlice.reducer
