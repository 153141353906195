/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable consistent-return */
import { Box } from '@material-ui/core'
import { AnyARecord } from 'dns'
import {
  CohortChartProps,
  CohortControlsProps,
  CohortDataStage,
  MapDataProps,
} from 'models/chartProps'
import { SankeyData } from 'models/sankeyData'
import { SankeyLink } from 'models/sankeyLinks'
import { SankeyNode } from 'models/sankeyNode'
import React, { useEffect, useState } from 'react'
import { getFilterLabel } from 'utils/cohort/cohortUtils'

type PersonSliderProps = {
  label: string

  value: boolean
  onChange: any
}

export const InclusiveFilter: React.FC<PersonSliderProps> = ({
  label,

  value,
  onChange,
}: PersonSliderProps) => {
  console.log('455')
  return (
    <Box>
      <label htmlFor='exclusive'>{label}</label>
      {value === true ? (
        <input
          type='checkbox'
          id='exclusive'
          name='exclusive'
          onChange={onChange}
          checked={true}
        />
      ) : (
        <input
          type='checkbox'
          id='exclusive'
          name='exclusive'
          onChange={onChange}
        />
      )}
    </Box>
  )
}
