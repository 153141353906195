/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { getPaymentUpdateForLabOrder } from 'redux/appointments/appointmentViewer/appointmentSearchSlice'
import {
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getProfilePicPatient,
  getTelecomOfPatient,
} from 'utils/fhirResourcesHelper'
import {
  getOrderDate,
  getOrderStartDate,
} from 'utils/fhirResoureHelpers/ipdHelper'
import {
  getOrderFinalStatusText,
  getOrderTime,
  getOrderTypeDisplayCode,
  isHomeOrder,
  isLabOrderEditable,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import { GenderAgeTile } from 'views/components/patients/genderAgeTile'
import SocketContext from 'views/contexts/socketMessageContext'

interface Props {
  index: string
  labOrderDetail: FhirLabOrderDetail
  isOdd?: boolean
  onDownloadClicked: () => void
  onTap?: () => void
  onUpload?: () => void
  onReschedule?: () => void
  onCancel?: () => void
  onEnterLabResults?: () => void

  dense?: boolean
  isSelected?: boolean
}

export const LabOrderTile: React.FC<Props> = ({
  index,
  labOrderDetail,
  isOdd = false,
  onDownloadClicked,
  onTap,
  onUpload,
  onCancel,
  onReschedule,
  onEnterLabResults,
  dense = false,
  isSelected = false,
}: Props) => {
  const { t } = useTranslation()
  const [showCancelHandler, setShowCancelHandler] = useState<boolean>(false)
  const [showRescheduleHandler, setShowRescheduleHandler] =
    useState<boolean>(false)

  const [paymentDetails, setPaymentDetails] =
    useState<FhirLabOrderDetail>(labOrderDetail)

  const { eventData } = useContext(SocketContext)

  const [paymentStatus, setPaymentStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  function getPaymentStatus(appointmentDetailsData: FhirLabOrderDetail) {
    setPaymentStatus({ requesting: true })

    setLoading(true)

    getPaymentUpdateForLabOrder(appointmentDetailsData)
      .then((e: FhirLabOrderDetail) => {
        setLoading(false)
        if (e) {
          setPaymentDetails(e)
          setPaymentStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setPaymentStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while fetching payment update. Please try again later'
            )
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        setPaymentStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  function requestAppointmentForPolling() {
    if (
      paymentDetails &&
      paymentDetails.paymentReconilation &&
      paymentDetails.paymentReconilation.outcome &&
      paymentDetails.paymentReconilation.outcome !== 'complete'
    ) {
      getPaymentStatus(paymentDetails)
    }
  }

  useEffect(() => {
    const intervalId = setInterval(requestAppointmentForPolling, 10000)
    return () => clearInterval(intervalId)
  }, [requestAppointmentForPolling])

  useEffect(() => {
    const convertedData = eventData as any

    if (convertedData !== null) {
      if (
        paymentDetails &&
        paymentDetails.paymentReconilation &&
        paymentDetails.paymentReconilation.outcome &&
        paymentDetails.paymentReconilation.outcome !== 'complete'
      ) {
        getPaymentStatus(paymentDetails)
      }
      if (convertedData.infoMessages && convertedData.infoMessages.length > 0) {
        dispatch(showSuccessAlert(convertedData.infoMessages[0]))
      }
      if (
        convertedData.errorMessages &&
        convertedData.errorMessages.length > 0
      ) {
        dispatch(showErrorAlert(convertedData.errorMessages[0]))
      }
    }
  }, [eventData])
  return (
    <Box width='100%'>
      <Card
        elevation={isOdd ? 0 : 1}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 0,
          backgroundColor: isSelected
            ? kPrimaryLight
            : isOdd
            ? '#FFFFFFAF'
            : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 0,
          }}
        >
          {dense && (
            <Box display='flex' flexDirection='row' width='100%'>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                px={1}
              >
                <Avatar
                  alt={getNameOfPatient(labOrderDetail.patient) ?? ''}
                  src={getProfilePicPatient(labOrderDetail.patient) ?? ''}
                />
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                flexGrow={1}
              >
                <Typography
                  variant='subtitle2'
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',

                    lineHeight: 'normal',
                  }}
                >
                  {`${getNameOfPatient(labOrderDetail.patient) ?? ''} ${
                    getMiddleNameOfPatient(labOrderDetail.patient) ?? ''
                  } ${getLastNameOfPatient(labOrderDetail.patient) ?? ''}`}
                </Typography>
                <Typography
                  variant='subtitle2'
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,

                    fontWeight: 600,
                    lineHeight: 'normal',
                  }}
                >
                  {getTelecomOfPatient(labOrderDetail.patient)}
                </Typography>

                <Typography
                  variant='subtitle2'
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,

                    fontWeight: 600,
                    lineHeight: 'normal',
                  }}
                >
                  {`Order #${labOrderDetail!.serviceRequest.id ?? ''}`}
                </Typography>
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-start'
                >
                  <Typography
                    variant='subtitle2'
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,

                      fontWeight: 600,
                      lineHeight: 'normal',
                    }}
                  >
                    {`${getOrderTime(labOrderDetail)} |`}
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,

                      fontWeight: 600,
                      lineHeight: 'normal',
                    }}
                  >
                    {getOrderTypeDisplayCode(labOrderDetail)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          {!dense && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              alignContent='center'
              p={0.5}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignContent='flex-start'
                width='20%'
                id={`patient_details${index}`}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-start'
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                  >
                    <Avatar
                      alt={getNameOfPatient(labOrderDetail.patient) ?? ''}
                      src={getProfilePicPatient(labOrderDetail.patient) ?? ''}
                    />
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='start'
                    px={1}
                  >
                    {getMiddleNameOfPatient(labOrderDetail.patient).length >
                      0 && (
                      <Typography
                        variant='subtitle1'
                        color='primary'
                        style={{
                          textTransform: 'capitalize',
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {getNameOfPatient(labOrderDetail.patient)}{' '}
                        {getMiddleNameOfPatient(labOrderDetail.patient) ?? ''}{' '}
                        {getLastNameOfPatient(labOrderDetail.patient)}
                      </Typography>
                    )}
                    {getMiddleNameOfPatient(labOrderDetail.patient).length ===
                      0 && (
                      <Typography
                        variant='subtitle1'
                        color='primary'
                        style={{
                          textTransform: 'capitalize',
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {getNameOfPatient(labOrderDetail.patient)}{' '}
                        {getLastNameOfPatient(labOrderDetail.patient)}
                      </Typography>
                    )}
                    <GenderAgeTile
                      patient={labOrderDetail.patient}
                      status={false}
                    />
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {getTelecomOfPatient(labOrderDetail.patient)}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='2%'
              />
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='15%'
              >
                <Typography
                  variant='subtitle2'
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,

                    fontWeight: 600,
                    lineHeight: 'normal',
                  }}
                >
                  {`Order #${labOrderDetail!.serviceRequest.id ?? ''}`}
                </Typography>
                <Typography
                  variant='subtitle2'
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    paddingLeft: 0.5,

                    fontWeight: 600,
                    lineHeight: 'normal',
                  }}
                >
                  {/* {getOrderTime(labOrderDetail)} */}
                  {getOrderStartDate(labOrderDetail)}
                </Typography>
                {getOrderTypeDisplayCode(labOrderDetail) !==
                  'Onsite Collection' && (
                  <Typography
                    variant='subtitle2'
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      paddingLeft: 0.5,

                      fontWeight: 600,
                      lineHeight: 'normal',
                    }}
                  >
                    {/* {getOrderTime(labOrderDetail)} */}
                    {getOrderDate(labOrderDetail)}
                  </Typography>
                )}
                <Typography
                  variant='subtitle2'
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    paddingLeft: 0.5,

                    fontWeight: 600,
                    lineHeight: 'normal',
                  }}
                >
                  {getOrderTypeDisplayCode(labOrderDetail)}
                </Typography>
              </Box>

              {/* <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='6%'
              >
                <GenderAndAgeTile
                  patient={labOrderDetail.patient}
                  status={false}
                />
              </Box> */}
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='30%'
                paddingX={2}
                id={`test_details${index}`}
              >
                <Typography
                  variant='subtitle2'
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,

                    fontWeight: 600,
                    lineHeight: 'normal',
                  }}
                >
                  {labOrderDetail.tests
                    ?.map(
                      (e) => e?.planDefinition.title ?? e?.planDefinition.name
                    )
                    .join(', ')}
                </Typography>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='15%'
              >
                <Typography
                  variant='subtitle1'
                  //   color={
                  //     labOrderDetail.serviceRequest.status === 'active' ||
                  //     labOrderDetail.serviceRequest.status === 'completed'
                  //       ? labOrderDetail.task &&
                  //         labOrderDetail.task.status !== 'rejected'
                  //         ? 'initial'
                  //         : 'error'
                  //       : 'error'
                  //   }
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color:
                      labOrderDetail.serviceRequest.status === 'active' ||
                      labOrderDetail.serviceRequest.status === 'completed'
                        ? labOrderDetail.task &&
                          labOrderDetail.task.status !== 'rejected'
                          ? 'black'
                          : 'red'
                        : 'red',
                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  {t(
                    'labelCommon:' +
                      `${getOrderFinalStatusText(labOrderDetail)}`
                  )}
                </Typography>
              </Box>
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='flex-start'
                width='15%'
                px={2}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='30%'
                >
                  <Tooltip title='View' id='view'>
                    <IconButton
                      aria-label='btn_ord_reschedule'
                      color='primary'
                      onClick={() => {
                        if (onTap) onTap()
                      }}
                      id={`order_view_icon${index}`}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/view.ico`}
                        alt='view'
                        id='view_img'
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
                {getOrderFinalStatusText(labOrderDetail) ===
                  'Processing Sample (s)' && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='30%'
                  >
                    <Tooltip title='Upload Reports' id='upload_rep'>
                      <IconButton
                        aria-label='btn_ord_reschedule'
                        color='primary'
                        onClick={() => {
                          if (onUpload) onUpload()
                        }}
                        id={`upload_report_icon${index}`}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/Lab_report_Upload.ico`}
                          alt='upload'
                          id='upload_img'
                          height={20}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
                {/* {getOrderFinalStatusText(labOrderDetail) ===
                  'Processing Sample (s)' && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='30%'
                  >
                    <Tooltip title='Upload Reports' id='result_rep'>
                      <IconButton
                        aria-label='btn_ord_reschedule'
                        color='primary'
                        onClick={() => {
                          if (onEnterLabResults) onEnterLabResults()
                        }}
                        id={`results_report_icon${index}`}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/labResultsEntry.png`}
                          alt='upload'
                          id='result_img'
                          height={20}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )} */}

                {paymentDetails.paymentReconilation && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='30%'
                    paddingTop={0.3}
                  >
                    {(paymentDetails.paymentReconilation.status === 'active' ||
                      paymentDetails.paymentReconilation.status ===
                        'cancelled') &&
                      paymentDetails.paymentReconilation.outcome ===
                        'complete' && (
                        <Tooltip title='Invoice' id='order_invoice'>
                          <IconButton
                            aria-label='btn_ord_reschedule'
                            color='primary'
                            onClick={() => {
                              if (onDownloadClicked) onDownloadClicked()
                            }}
                            id={`invoice_appointment${index}`}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/bill.ico`}
                              alt='view'
                              id='start_img'
                              height={20}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                  </Box>
                )}
                {isLabOrderEditable(labOrderDetail) &&
                  isHomeOrder(labOrderDetail) &&
                  getOrderFinalStatusText(labOrderDetail) === 'Scheduled' && (
                    <Tooltip title={t('reschedule') ?? 'Re-Schedule'}>
                      <IconButton
                        aria-label='btn_ord_reschedule'
                        color='primary'
                        onClick={() => {
                          if (onReschedule) {
                            onReschedule()
                          }
                        }}
                        id='reschedule'
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/reschedule.ico`}
                          alt='reschedule'
                          height={20}
                          // height='110%'
                          // width='110%'
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                {isLabOrderEditable(labOrderDetail) &&
                  getOrderFinalStatusText(labOrderDetail) === 'Scheduled' && (
                    <Tooltip title={t('cancel') ?? 'Cancel'}>
                      <IconButton
                        aria-label='btn_ord_cancel'
                        color='primary'
                        onClick={() => {
                          if (onCancel) {
                            onCancel()
                          }
                        }}
                        id='cancel'
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/cancel_ico.ico`}
                          alt='cancel'
                          height={20}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}
