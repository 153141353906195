import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { DocumentDownLoadStatus } from './documentDownloadStatus'

const initialState: DocumentDownLoadStatus = {
  downloading: false,
  downloadingSuccess: false,
  error: false,
  errorMessage: '',
}

const diagnosticReportDownloadSlice = createSlice({
  name: 'diagnosticReportDownloadSlice',
  initialState,
  reducers: {
    downloadingDocument(state, action: PayloadAction<DocumentDownLoadStatus>) {
      state.downloading = action.payload.downloading
      state.downloadingSuccess = action.payload.downloadingSuccess
      state.error = action.payload.error
    },

    documentDownloaded(state, action: PayloadAction<DocumentDownLoadStatus>) {
      state.downloading = action.payload.downloading
      state.downloadingSuccess = action.payload.downloadingSuccess
      state.error = action.payload.error
    },

    errorInDownloadingDocument(
      state,
      action: PayloadAction<DocumentDownLoadStatus>
    ) {
      state.downloading = action.payload.downloading
      state.downloadingSuccess = action.payload.downloadingSuccess
      state.error = action.payload.error
    },

    resetDownload(state, action: PayloadAction<DocumentDownLoadStatus>) {
      state.downloading = action.payload.downloading
      state.downloadingSuccess = action.payload.downloadingSuccess
      state.error = action.payload.error
    },
  },
})

export const diagnosticReportDownload =
  (reportId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingDocumentDownloadState: DocumentDownLoadStatus = {
      downloading: true,
      downloadingSuccess: false,
      error: false,
    }
    dispatch(
      diagnosticReportDownloadSlice.actions.downloadingDocument(
        addingDocumentDownloadState
      )
    )

    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doGetResource(
        `/DiagnosticReport//$binary-access-read?path=DiagnosticReport.presentedForm`
      )
      // const pdfWindow = window.open("");
      // if(pdfWindow)
      // pdfWindow.document.write(`<iframe width='100%' height='100%' src='data:application/pdf;base64,${ response }'></iframe>`);
      const byteCharacters = atob(response)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)

      const file = new Blob([byteArray], { type: 'application/pdf;base64' })

      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
      const documentDownloadedState: DocumentDownLoadStatus = {
        downloading: false,
        downloadingSuccess: true,
        error: false,
      }
      dispatch(
        diagnosticReportDownloadSlice.actions.documentDownloaded(
          documentDownloadedState
        )
      )

      return
    } catch (error) {
      const errorDocumentDownloadState: DocumentDownLoadStatus = {
        downloading: false,
        downloadingSuccess: false,
        error: true,
        errorMessage: 'Error',
      }
      dispatch(
        diagnosticReportDownloadSlice.actions.errorInDownloadingDocument(
          errorDocumentDownloadState
        )
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(diagnosticReportDownloadSlice.actions.resetDownload(initialState))
}

export default diagnosticReportDownloadSlice.reducer
