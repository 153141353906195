import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirClinicalImpressionDetail } from 'models/fhirClinicalImpression'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { getClinicaImpressionDetailsFromBundleResponse } from 'utils/fhirResoureHelpers/clinicalImpressionHelper'
import { logger } from 'utils/logger'
import { RespiratorySystemsDiagnosisStatus } from './addDischargeDiagnosisOfPTTypes'

const initialState: RespiratorySystemsDiagnosisStatus = {
  fetchingImpressions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  updatedImpressions: false,
  updatingImpressions: false,
  errorWhileSearchingImpressions: false,
  errorWhileUpdatingImpressions: false,
}

const addDischargeDiagnosisOfPTSlice = createSlice({
  name: 'addDischargeDiagnosisOfPTSlice',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<RespiratorySystemsDiagnosisStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingImpressions = action.payload.fetchingImpressions
      state.resultsAvailable = action.payload.resultsAvailable
      state.clinicalImpressions = action.payload.clinicalImpressions
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingImpressions =
        action.payload.errorWhileUpdatingImpressions
      state.updatedImpressions = action.payload.updatedImpressions
      state.updatingImpressions = action.payload.updatingImpressions
      state.errorWhileSearchingImpressions =
        action.payload.errorWhileSearchingImpressions
    },
    resetState(
      state,
      action: PayloadAction<RespiratorySystemsDiagnosisStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingImpressions = action.payload.fetchingImpressions
      state.resultsAvailable = action.payload.resultsAvailable
      state.clinicalImpressions = action.payload.clinicalImpressions
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingImpressions =
        action.payload.errorWhileUpdatingImpressions
      state.updatedImpressions = action.payload.updatedImpressions
      state.updatingImpressions = action.payload.updatingImpressions
      state.errorWhileSearchingImpressions =
        action.payload.errorWhileSearchingImpressions
    },
  },
})

export const getDischargeDiagnosisOfPTOfEncounter =
  (encounterId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: RespiratorySystemsDiagnosisStatus = { ...initialState }
    state.fetchingImpressions = true
    dispatch(addDischargeDiagnosisOfPTSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        encounter: encounterId,
        identifier: '89100005',
        _include: 'ClinicalImpression:problem',
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/ClinicalImpression',
        '',
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingImpressions = true
        state.fetchingImpressions = false

        dispatch(addDischargeDiagnosisOfPTSlice.actions.updatedStatus(state))
      } else {
        const conditionResponse: R4.IBundle = resp.right
        logger.info(
          'Chief Complaint resp',
          conditionResponse.entry?.[0].resource as R4.ICondition
        )

        if (conditionResponse?.total && conditionResponse?.total > 0) {
          const clinicalImpressions: FhirClinicalImpressionDetail[] =
            getClinicaImpressionDetailsFromBundleResponse(conditionResponse)
          clinicalImpressions.sort((a, b) =>
            (a.clinicalImpression.date ? a.clinicalImpression.date : '') <
            (b.clinicalImpression.date ? b.clinicalImpression.date : '')
              ? -1
              : (a.clinicalImpression.date ? a.clinicalImpression.date : '') <
                (b.clinicalImpression.date ? b.clinicalImpression.date : '')
              ? 1
              : 0
          )

          if (clinicalImpressions && clinicalImpressions.length > 0) {
            state.resultsAvailable = true
            state.fetchingImpressions = false
            state.clinicalImpressions = clinicalImpressions[0]

            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingImpressions = false
            dispatch(
              addDischargeDiagnosisOfPTSlice.actions.updatedStatus(state)
            )
            return
          }
        } else {
          const errorSearchDoctor: RespiratorySystemsDiagnosisStatus = {
            ...initialState,
          }
          errorSearchDoctor.noResultsAvailable = true
          dispatch(
            addDischargeDiagnosisOfPTSlice.actions.updatedStatus(
              errorSearchDoctor
            )
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: RespiratorySystemsDiagnosisStatus = {
        ...initialState,
      }
      errorSearchDoctor.errorReason = 'Error while fetching assessment details'
      errorSearchDoctor.errorWhileSearchingImpressions = false
      dispatch(
        addDischargeDiagnosisOfPTSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export const updateDischargeDiagnosisDetailsOfPT =
  (
    type: string,
    operation: string,
    encounterId: string,
    patientId: string,
    summaryNotes: string,
    findings?: R4.IClinicalImpression_Finding[],
    diagnosedConditions?: R4.ICoding[],
    diagnosedAllergies?: R4.ICoding[],
    deletedData?: R4.ICoding
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (
      summaryNotes ||
      diagnosedConditions ||
      diagnosedAllergies ||
      deletedData
    ) {
      let state: RespiratorySystemsDiagnosisStatus = { ...initialState }
      state.updatingImpressions = true
      dispatch(addDischargeDiagnosisOfPTSlice.actions.updatedStatus(state))
      try {
        const bundleObject: R4.IBundle =
          createBundleObjectForClinicalImpression(
            operation,
            encounterId,
            patientId,
            summaryNotes,

            findings,
            diagnosedConditions,
            diagnosedAllergies,
            deletedData
          )

        logger.info(
          '-------------------bundleObject clinical impression-------------------------------'
        )

        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)

        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)

        if (relatedFhirDecodeRes._tag === 'Right') {
          if (relatedFhirDecodeRes.right) {
            state = { ...initialState }
            state.updatedImpressions = true

            dispatch(
              addDischargeDiagnosisOfPTSlice.actions.updatedStatus(state)
            )
            if (
              operation === 'add' &&
              (type === 'condition' || type === 'allergy')
            )
              dispatch(
                showSuccessAlert(
                  type === 'condition'
                    ? 'Condition added successfully'
                    : 'Allergy added successfully'
                )
              )
            else if (
              operation === 'delete' ||
              type === 'condition' ||
              type === 'allergy'
            ) {
              dispatch(
                showSuccessAlert(
                  type === 'condition'
                    ? 'Condition deleted successfully'
                    : 'Allergy deleted successfully'
                )
              )
              dispatch(
                addDischargeDiagnosisOfPTSlice.actions.updatedStatus(state)
              )
            } else
              dispatch(showSuccessAlert('Discharge Notes added successfully'))
          } else {
            const errorSearchDoctor: RespiratorySystemsDiagnosisStatus = {
              ...initialState,
            }
            errorSearchDoctor.errorWhileUpdatingImpressions = true
            errorSearchDoctor.errorReason = 'error while updating . try later'

            dispatch(
              addDischargeDiagnosisOfPTSlice.actions.updatedStatus(
                errorSearchDoctor
              )
            )
          }
        } else {
          const errorSearchDoctor: RespiratorySystemsDiagnosisStatus = {
            ...initialState,
          }

          errorSearchDoctor.errorWhileUpdatingImpressions = true
          errorSearchDoctor.errorReason = 'error while updating . try later'
          dispatch(
            addDischargeDiagnosisOfPTSlice.actions.updatedStatus(
              errorSearchDoctor
            )
          )
        }
      } catch (error) {
        logger.error(error)
        const errorSearchDoctor: RespiratorySystemsDiagnosisStatus = {
          ...initialState,
        }
        errorSearchDoctor.errorReason =
          'Error while adding/updating chief complaint details'
        errorSearchDoctor.errorWhileUpdatingImpressions = false
        dispatch(
          addDischargeDiagnosisOfPTSlice.actions.updatedStatus(
            errorSearchDoctor
          )
        )
      }
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(addDischargeDiagnosisOfPTSlice.actions.resetState(initialState))
}

function createBundleObjectForClinicalImpression(
  operation: string,
  encounterId: string,
  patientId: string,
  summaryNotes: string,
  findings?: R4.IClinicalImpression_Finding[],
  diagnosedConditions?: R4.ICoding[],
  diagnosedAllergies?: R4.ICoding[],
  deletedData?: R4.ICoding
): R4.IBundle {
  const practitionerDetail = getCurrentUserPractitionerDetails()
  const practitionerRoleDetail = getCurrentUserPractitionerRoleDetails()
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }
  const matchStringClinicalImpression: string = `ClinicalImpression?encounter=${encounterId}&identifier=89100005`
  if (findings && findings.length > 0) {
    findings = findings.map((finding) => {
      const conditionMatchString = `Condition?encounter=${encounterId}&code=${finding.itemCodeableConcept?.coding?.[0].code}&category=assessment-finding`
      const randomNum: number = getRandomInt(999999)
      const conditionFullUrl = `urn:uuid:${randomNum.toString()}`
      const newVal = { ...finding }
      newVal.itemReference = {
        reference: `Condition/${conditionFullUrl}`,
        type: 'Condition',
      }
      const entry: R4.IBundle_Entry = {
        fullUrl: conditionFullUrl,
        request: {
          url: conditionMatchString,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: {
          resourceType: 'Condition',
          meta: {
            profile: [
              'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndConditionSystemExamination',
            ],
          },
          subject: {
            id: patientId,
            reference: `Patient/${patientId}`,
            type: 'Patient',
          },
          code: finding.itemCodeableConcept,
          encounter: {
            reference: `Encounter/${encounterId}`,
            type: 'Encounter',
          },
          recorder: {
            display: getNameFromHumanName(practitionerDetail.name ?? []),
            id: practitionerRoleDetail.id,
            reference: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
            type: practitionerRoleDetail.resourceType,
          },
          asserter: {
            display: getNameFromHumanName(practitionerDetail.name ?? []),
            id: practitionerRoleDetail.id,
            reference: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
            type: practitionerRoleDetail.resourceType,
          },
          verificationStatus: {
            text: operation === 'delete' ? 'Refuted' : 'Confirmed',
            coding: [
              {
                code: operation === 'delete' ? 'refuted' : 'confirmed',
                display: operation === 'delete' ? 'Refuted' : 'Confirmed',
                system:
                  'http://terminology.hl7.org/CodeSystem/condition-ver-status',
              },
            ],
          },
          category: [
            {
              coding: [
                {
                  code: '89100005',
                  display: 'Discharge Note PT',
                  system: 'http://snomed.info/sct',
                },
              ],
            },
          ],
        },
      }
      requestBundle.entry?.push(entry)
      return newVal
    })
    /* findings.forEach((finding) => {
      let conditionMatchString =
        "Condition?encounter=" +
        encounterId +
        "&code=" +
        finding.itemCodeableConcept?.coding?.[0].code +
        "&category=assessment-finding";
      let randomNum: number = getRandomInt(999999);
      let conditionFullUrl = "urn:uuid:" + randomNum.toString();
      finding.itemReference = {
        reference: "Condition/" + conditionFullUrl,
        type: "Condition",
      };
      
    }); */
  }

  const cis: R4.IClinicalImpression = {
    resourceType: 'ClinicalImpression',
    meta: {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndClinicalImpressionSystemExamination',
      ],
    },
    status: 'in-progress',
    identifier: [
      {
        system: 'http://snomed.info/sct',
        value: '89100005',
      },
    ],
    code: {
      coding: [
        {
          code: '89100005',
          display: 'Discharge Note PT',
          system: 'http://snomed.info/sct',
        },
      ],
    },
    encounter: {
      reference: `Encounter/${encounterId}`,
      type: 'Encounter',
    },
    assessor: {
      reference: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
      id: practitionerRoleDetail.id,
      type: practitionerRoleDetail.resourceType,
    },
    subject: {
      reference: `Patient/${patientId}`,
      id: patientId,
      type: 'Patient',
    },
    problem: [],
    finding: findings ?? [],
    summary: summaryNotes,
    date: moment(new Date()).format(),
  }
  logger.info('Conditions ', diagnosedConditions)
  if (diagnosedConditions && diagnosedConditions.length > 0) {
    diagnosedConditions.forEach((finding) => {
      const code = deletedData ? deletedData.code ?? '' : ''
      const conditionMatchString = `Condition?encounter=${encounterId}&code=${finding.code}&category=encounter-diagnosis`

      const randomNum: number = getRandomInt(999999)
      const conditionFullUrl = `urn:uuid:${randomNum.toString()}`
      if (finding.code !== code) {
        const entry: R4.IBundle_Entry = {
          fullUrl: conditionFullUrl,
          request: {
            url: conditionMatchString,
            method: R4.Bundle_RequestMethodKind._put,
          },
          resource: {
            resourceType: 'Condition',
            onsetDateTime: new Date().toISOString(),
            subject: {
              id: patientId,
              reference: `Patient/${patientId}`,
            },
            code: { coding: [finding], text: finding.display },
            encounter: {
              reference: `Encounter/${encounterId}`,
              type: 'Encounter',
            },
            recorder: {
              display: getNameFromHumanName(practitionerDetail.name ?? []),
              id: practitionerRoleDetail.id,
              reference: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
              type: practitionerRoleDetail.resourceType,
            },
            category: [
              {
                coding: [
                  {
                    code: '89100005',
                    display: 'Discharge Note PT',
                    system: 'http://snomed.info/sct',
                  },
                ],
              },
            ],
            asserter: {
              display: getNameFromHumanName(practitionerDetail.name ?? []),
              id: practitionerRoleDetail.id,
              reference: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
              type: practitionerRoleDetail.resourceType,
            },
            verificationStatus: {
              text:
                deletedData && deletedData.code === finding.code
                  ? 'Refuted'
                  : 'Confirmed',
              coding: [
                {
                  code:
                    deletedData && deletedData.code === finding.code
                      ? 'refuted'
                      : 'confirmed',
                  display:
                    deletedData && deletedData.code === finding.code
                      ? 'Refuted'
                      : 'Confirmed',
                  system:
                    'http://terminology.hl7.org/CodeSystem/condition-ver-status',
                },
              ],
            },
          },
        }
        cis.problem?.push({
          type: 'Condition',
          reference: `Condition/${conditionFullUrl}`,
        })
        logger.info('Pushing condition entry', entry)
        requestBundle.entry?.push(entry)
      }
    })
  }
  if (diagnosedAllergies && diagnosedAllergies.length > 0) {
    diagnosedAllergies.forEach((finding) => {
      const conditionMatchString = `AllergyIntolerance?patient=${patientId}&code=${finding.code}`
      const conditionFullUrl = `urn:uuid:${getRandomInt(999999).toString()}`
      const code = deletedData ? deletedData.code ?? '' : ''
      if (finding.code !== code) {
        const entry: R4.IBundle_Entry = {
          fullUrl: conditionFullUrl,
          request: {
            url: conditionMatchString,
            method: R4.Bundle_RequestMethodKind._put,
          },
          resource: {
            resourceType: 'AllergyIntolerance',
            meta: {
              profile: [
                'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndAllergyIntoleranceBase',
              ],
            },
            patient: {
              id: patientId,
              reference: `Patient/${patientId}`,
              type: 'Patient',
            },
            onsetDateTime: new Date().toISOString(),
            type: R4.AllergyIntoleranceTypeKind._allergy,
            code: { coding: [finding], text: finding.display },
            encounter: {
              reference: `Encounter/${encounterId}`,
              type: 'Encounter',
            },
            recorder: {
              display: getNameFromHumanName(practitionerDetail.name ?? []),
              id: practitionerRoleDetail.id,
              reference: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
              type: practitionerRoleDetail.resourceType,
            },
            asserter: {
              display: getNameFromHumanName(practitionerDetail.name ?? []),
              id: practitionerRoleDetail.id,
              reference: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
              type: practitionerRoleDetail.resourceType,
            },
            verificationStatus: {
              text: operation === 'delete' ? 'Refuted' : 'Confirmed',
              coding: [
                {
                  code: operation === 'delete' ? 'refuted' : 'confirmed',
                  display: operation === 'delete' ? 'Refuted' : 'Confirmed',
                  system:
                    'http://terminology.hl7.org/CodeSystem/condition-ver-status',
                },
              ],
            },
          },
        }
        cis.problem?.push({
          type: 'AllergyIntolerance',
          reference: `AllergyIntolerance/${conditionFullUrl}`,
        })
        requestBundle.entry?.push(entry)
      }
    })
  }

  const cisEntry: R4.IBundle_Entry = {
    fullUrl: 'urn:uuid:1232323232325',
    request: {
      url: matchStringClinicalImpression,
      method: R4.Bundle_RequestMethodKind._put,
    },
    resource: cis,
  }
  requestBundle.entry?.push(cisEntry)

  return requestBundle
}

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max))
}

export default addDischargeDiagnosisOfPTSlice.reducer
