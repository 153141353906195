import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { PatientDataWithAppointment } from 'models/patientDataWithAppointment'
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { CcClinet } from 'services/Cclinet'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getUserDetails } from 'services/userDetailsService'
import { logger } from 'utils/logger'
import { ResendStatusAdmin } from './resendInviteAdminStatus'

const initialState: ResendStatusAdmin = {
  sending: false,
  sentSuccesFully: false,
  error: false,
  errorMessage: '',
  patient: undefined,
}

const resendInviteAdminSlice = createSlice({
  name: 'resendInviteAdminSlice',
  initialState,
  reducers: {
    updateStatus(state, action: PayloadAction<ResendStatusAdmin>) {
      state.sending = action.payload.sending
      state.sentSuccesFully = action.payload.sentSuccesFully
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.patient = action.payload.patient
    },
  },
})

export const resendInviteForAdmin =
  (practitionerId: string, roleObject: R4.IPractitionerRole): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: ResendStatusAdmin = {
      sending: true,
      sentSuccesFully: false,
      error: false,
    }
    dispatch(
      resendInviteAdminSlice.actions.updateStatus(addingCreatePersonState)
    )

    try {
      logger.info('patientDetails')
      const currentUserPractRole: R4.IPractitionerRole | undefined =
        getUserDetails()?.practitionerRole

      const practitionerRoleId = currentUserPractRole
        ? currentUserPractRole.id ?? ''
        : ''

      const formData = {
        // owner: `PractitionerRole/${practitionerRoleId}`,
        owner: `PractitionerRole/${roleObject.id}`,
        requestor: `PractitionerRole/${
          currentUserPractRole ? currentUserPractRole.id ?? '' : ''
        }`,
        taskCode: 'user-invitation',
        overrideExpiry: true,
      }

      const enRolClient: CcClinet = new CcClinet()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        'invitation/resend',
        formData
      )

      const successCreatePersonState: ResendStatusAdmin = {
        sending: false,
        sentSuccesFully: true,
        error: true,
        errorMessage: '',
      }
      dispatch(
        resendInviteAdminSlice.actions.updateStatus(successCreatePersonState)
      )
      if (response.status === 'Task Invitation is not found')
        dispatch(
          showSuccessAlert(
            'The user has already accepted the invitation. He is yet to complete his profile.'
          )
        )
      else dispatch(showSuccessAlert('Invitation resent successfully'))
      dispatch(resendInviteAdminSlice.actions.updateStatus(initialState))
      return
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorCreatePersonState: ResendStatusAdmin = {
        sending: false,
        sentSuccesFully: false,
        error: true,
        errorMessage: 'Error while uploading patient data',
      }
      dispatch(showErrorAlert('Error while sending invite'))
      dispatch(
        resendInviteAdminSlice.actions.updateStatus(errorCreatePersonState)
      )
    }
  }

export const resetUpdateUserState = () => (dispatch: AppDispatch) => {
  dispatch(resendInviteAdminSlice.actions.updateStatus(initialState))
}

export default resendInviteAdminSlice.reducer
