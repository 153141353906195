import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import {
  requestForIPDCountWp,
  resetIPDCountWp,
} from 'redux/welloAdmin/ipcCountWp/ipdCountWpSlice'
import {
  requestForWellnessAppointmentCountBoth,
  resetWellnessAppointmentForUnitCountDetail,
} from 'redux/welloAdmin/wellnessAppointmentBasedOnStatus/wellnessAppointmentBasedonStatusSlice'
import { getUnitTypeForWp } from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import { WelloSelectOrgs } from 'views/components/LeftMenu/welloSelectOrgs'
import { AppointmentCountTileCount } from './appointmentDetailCount'

interface Props {
  id: string
  open: boolean
  type: string
  onClose: () => void
  unitList: R4.IOrganization[]
}
export const OrgAppointmentDetails: React.FC<Props> = ({
  id,
  open,
  onClose,
  type,
  unitList,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const appointmentWellnessCountPerUnitDetailsSlice = useSelector(
    (state: RootState) => state.appointmentWellnessCountPerUnitDetailsSlice
  )

  const appointmentCountPerUnitSlice = useSelector(
    (state: RootState) => state.appointmentCountPerUnitSlice
  )

  const [orgData, setOrgData] = useState<R4.IOrganization>()

  const ipdCountWpSlice = useSelector(
    (state: RootState) => state.ipdCountWpSlice
  )

  function resetForm() {}

  //   useEffect(() => {
  //     if (type.length > 0 && type === 'wellness_center') {
  //       dispatch(requestForWellnessAppointmentCountBoth(id))
  //     }
  //   }, [dispatch, id, type])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetForm()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>
            {' '}
            {`${
              type === 'wellness_center'
                ? 'Appointment Details'
                : type === 'lab'
                ? 'Order Details'
                : 'Appointment Details'
            }`}{' '}
          </Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
                resetForm()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        <Box display='flex' flexGrow={1} flexDirection='column' width='100%'>
          <WelloSelectOrgs
            label={t('labelCommon:unit_label')}
            availableOptions={unitList.filter(
              (org: R4.IOrganization) => getUnitTypeForWp(org) === type
            )}
            required={true}
            type='Select unit'
            displayField='name'
            valueField='id'
            onSelectionChanged={(value: any) => {
              setOrgData(value as R4.IOrganization)
              if (type === 'wellness_center') {
                dispatch(resetIPDCountWp())
                dispatch(resetWellnessAppointmentForUnitCountDetail())
                dispatch(requestForWellnessAppointmentCountBoth(value.id))
              } else if (type === 'clinic') {
                dispatch(requestForIPDCountWp(value.id, 'clinic', ''))
                dispatch(resetIPDCountWp())
              } else if (type === 'lab') {
                dispatch(requestForIPDCountWp(value.id, 'lab', ''))
                dispatch(resetIPDCountWp())
              }
            }}
          />
          {appointmentWellnessCountPerUnitDetailsSlice.searchingAppointments && (
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
              paddingTop={2}
            >
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            </Box>
          )}

          {ipdCountWpSlice.searchingDetails && (
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
              paddingTop={2}
            >
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            </Box>
          )}

          {ipdCountWpSlice.error && (
            <Box
              //   display='flex'
              //   flexDirection='column'
              justifyContent='center'
              width='100%'
              height='100%'
              alignContent='center'
              paddingLeft={50}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Grid container justifyContent='center'>
                    <Typography
                      variant='subtitle1'
                      color='error'
                      align='center'
                    >
                      Error while searching. Please try again
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
          {ipdCountWpSlice.detailsAvailable && (
            <SimpleBar
              style={{
                height: '100%',
                width: '100%',
              }}
            >
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height='100%'
              >
                <Box display='flex' flexGrow={1} flexDirection='column' py={1}>
                  <Box
                    paddingBottom={0.5}
                    paddingLeft={0.5}
                    display='flex'
                    flexDirection='row'
                  >
                    {type === 'lab' && (
                      <Typography variant='subtitle2'>
                        {`Order details for `}
                      </Typography>
                    )}
                    {type !== 'lab' && (
                      <Typography variant='subtitle2'>
                        {`Appointment details for `}
                      </Typography>
                    )}

                    <Box paddingLeft={0.3} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          textTransform: 'uppercase',
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {` ${orgData ? orgData.name ?? '' : ''}`}
                      </Typography>
                    </Box>
                  </Box>
                  <AppointmentCountTileCount
                    activeCount={ipdCountWpSlice.count}
                    completedCount={ipdCountWpSlice.discharged}
                    cancelled={ipdCountWpSlice.cancelled}
                    type={type}
                  />
                </Box>
              </Box>
            </SimpleBar>
          )}

          {ipdCountWpSlice.noResultsAvailable && (
            <SimpleBar
              style={{
                height: '100%',
                width: '100%',
              }}
            >
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height='100%'
              >
                <Box display='flex' flexGrow={1} flexDirection='column' py={1}>
                  <Box
                    paddingBottom={0.5}
                    paddingLeft={0.5}
                    display='flex'
                    flexDirection='row'
                  >
                    <Typography variant='subtitle2'>
                      {`Appointment details for `}
                    </Typography>
                    <Box paddingLeft={0.3} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          textTransform: 'uppercase',
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {` ${orgData ? orgData.name ?? '' : ''}`}
                      </Typography>
                    </Box>
                  </Box>
                  <AppointmentCountTileCount
                    activeCount={ipdCountWpSlice.count}
                    completedCount={ipdCountWpSlice.discharged}
                    cancelled={ipdCountWpSlice.cancelled}
                    type={type}
                  />
                </Box>
              </Box>
            </SimpleBar>
          )}

          {appointmentWellnessCountPerUnitDetailsSlice.noResultsAvailable && (
            <SimpleBar
              style={{
                height: '100%',
                width: '100%',
              }}
            >
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height='100%'
              >
                <Box display='flex' flexGrow={1} flexDirection='column' py={1}>
                  <Box
                    paddingBottom={0.5}
                    paddingLeft={0.5}
                    display='flex'
                    flexDirection='row'
                  >
                    <Typography variant='subtitle2'>
                      {`Appointment details for `}
                    </Typography>
                    <Box paddingLeft={0.3} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          textTransform: 'uppercase',
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {` ${orgData ? orgData.name ?? '' : ''}`}
                      </Typography>
                    </Box>
                  </Box>
                  <AppointmentCountTileCount
                    activeCount={
                      appointmentWellnessCountPerUnitDetailsSlice.activeCount
                    }
                    completedCount={
                      appointmentWellnessCountPerUnitDetailsSlice.completedCount
                    }
                    cancelled={
                      appointmentWellnessCountPerUnitDetailsSlice.cancelled
                    }
                    type={type}
                  />
                </Box>
              </Box>
            </SimpleBar>
          )}

          {appointmentWellnessCountPerUnitDetailsSlice.errorWhileSearchingAppointments && (
            <Box
              //   display='flex'
              //   flexDirection='column'
              justifyContent='center'
              width='100%'
              height='100%'
              alignContent='center'
              paddingLeft={50}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Grid container justifyContent='center'>
                    <Typography
                      variant='subtitle1'
                      color='error'
                      align='center'
                    >
                      Error while searching. Please try again
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}

          {appointmentWellnessCountPerUnitDetailsSlice.resultsAvailable && (
            <SimpleBar
              style={{
                height: '100%',
                width: '100%',
              }}
            >
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height='100%'
              >
                <Box display='flex' flexGrow={1} flexDirection='column' py={1}>
                  <Box
                    paddingBottom={0.5}
                    paddingLeft={0.5}
                    display='flex'
                    flexDirection='row'
                  >
                    <Typography variant='subtitle2'>
                      {`Appointment details for `}
                    </Typography>
                    <Box paddingLeft={0.3} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          textTransform: 'uppercase',
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {` ${orgData ? orgData.name ?? '' : ''}`}
                      </Typography>
                    </Box>
                  </Box>
                  <AppointmentCountTileCount
                    activeCount={
                      appointmentWellnessCountPerUnitDetailsSlice.activeCount
                    }
                    completedCount={
                      appointmentWellnessCountPerUnitDetailsSlice.completedCount
                    }
                    cancelled={
                      appointmentWellnessCountPerUnitDetailsSlice.cancelled
                    }
                    type={type}
                  />
                </Box>
              </Box>
            </SimpleBar>
          )}
        </Box>
      </DialogContent>
      {/* <DialogActions>
        <Box paddingRight={2}>
          <Button
            onClick={() => {
              onClose()
            }}
            variant='outlined'
            disableElevation
          >
            Ok
          </Button>
        </Box>
      </DialogActions> */}
    </Dialog>
  )
}
