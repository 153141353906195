import { R4 } from '@ahryman40k/ts-fhir-types'
import MaterialTable, { MTableToolbar } from '@material-table/core'
import {
  Box,
  makeStyles,
  Paper,
  TablePagination,
  Typography,
  useTheme,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import {
  kBackgroundDefault,
  kDialogueBackground,
} from 'configs/styles/muiThemes'
import { get } from 'lodash'
import { SubscriptionInterest } from 'models/subscriptionInterestDetails'
import moment from 'moment'
import React, { useState } from 'react'
import {
  getColorOfSubscriptionStatus,
  getSelectedDurationFromTaskInput,
  isTermsAndConditionAccepted,
} from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import { getTelecomOfPatient } from 'utils/fhirResourcesHelper'
import OverflowTypography from 'views/components/consultation/plan/ayurveda/overflowTypography'
import { SubscriptionDurationText } from 'views/components/subscription.tsx/planDurationDetails'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '4px',
      margin: '0px',
    },

    '& .MuiTableRow': {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(33, 150, 243, 0.5)',
        },
      },
    },
  },
})

interface CPSInterestListTableProps {
  medicationsList: SubscriptionInterest[]
  actionComponent?: React.ReactNode
  onOptForOfferClick?: (medication: SubscriptionInterest) => void
  onRemoveFromOfferClick?: (medication: SubscriptionInterest) => void
}

export const CPSInterestListTable: React.FC<CPSInterestListTableProps> = ({
  medicationsList,
  actionComponent,
  onOptForOfferClick,
  onRemoveFromOfferClick,
}: CPSInterestListTableProps) => {
  const classes = useStyles()
  const convertedData = medicationsList
  const [data, setData] = useState([...convertedData])

  const [selectedRowId, setSelectedRowId] = React.useState('')
  const [selectedRow, setSelectedRow] = React.useState(false)

  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()

  const handleDarkModeChange = () => {
    // setPreferDarkMode(!preferDarkMode)
    // localStorage.setItem('_tableDarkMode', !preferDarkMode)
  }

  const columns = [
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Patient Name
          </Typography>
        </Box>
      ),
      width: '20%',
      field: 'patientName',
      customSort: (a: SubscriptionInterest, b: SubscriptionInterest) =>
        (a.patientName ?? '') > (b.patientName ?? '')
          ? 1
          : (b.patientName ?? '') > (a.patientName ?? '')
          ? -1
          : 0,

      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (rowData: SubscriptionInterest) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <Box display='flex' flexDirection='column' alignItems='start'>
            <Typography variant='subtitle2' noWrap={false}>
              {rowData.patientName ?? ''}
            </Typography>
            {rowData.patient && (
              <Typography variant='body2' noWrap>
                {getTelecomOfPatient(
                  rowData.patient,
                  R4.ContactPointSystemKind._phone
                )}
              </Typography>
            )}
          </Box>
        </Box>
      ),
      filtering: false,
    },

    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Sex/Age
          </Typography>
        </Box>
      ),
      width: '10%',
      field: 'patientAge',
      customSort: (a: SubscriptionInterest, b: SubscriptionInterest) =>
        (a.patientAge ?? '') > (b.patientAge ?? '')
          ? 1
          : (b.patientAge ?? '') > (a.patientAge ?? '')
          ? -1
          : 0,

      hiddenByColumnsButton: false,
      filtering: false,
      render: (rowData: SubscriptionInterest) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <Typography variant='body2' noWrap>
            {rowData.patientGender ?? ''} / {rowData.patientAge ?? ''}
          </Typography>
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Care Plan
          </Typography>
        </Box>
      ),
      width: '30%',
      field: 'subscriptionName',
      customSort: (a: SubscriptionInterest, b: SubscriptionInterest) =>
        (a.subscriptionName ?? '') > (b.subscriptionName ?? '')
          ? 1
          : (b.subscriptionName ?? '') > (a.subscriptionName ?? '')
          ? -1
          : 0,

      hiddenByColumnsButton: false,
      filtering: false,
      render: (rowData: SubscriptionInterest) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <OverflowTypography
            text={rowData.subscriptionName ?? ''}
            typographyProps={{
              variant: 'body2',
              noWrap: true,
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Duration
          </Typography>
        </Box>
      ),
      width: '30%',
      field: 'subscriptionName',
      customSort: (a: SubscriptionInterest, b: SubscriptionInterest) =>
        (a.subscriptionName ?? '') > (b.subscriptionName ?? '')
          ? 1
          : (b.subscriptionName ?? '') > (a.subscriptionName ?? '')
          ? -1
          : 0,

      hiddenByColumnsButton: false,
      filtering: false,
      render: (rowData: SubscriptionInterest) => (
        <Box display='flex' alignItems='center' p={0.5}>
          {getSelectedDurationFromTaskInput(rowData.task) && (
            <SubscriptionDurationText
              planDefId={rowData.task.focus?.reference?.split('/')[1]!}
              selectedDurationId={
                getSelectedDurationFromTaskInput(rowData.task)!
              }
            />
          )}
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Care Plan ID
          </Typography>
        </Box>
      ),
      width: '20%',
      field: 'subscriptionId',
      customSort: (a: SubscriptionInterest, b: SubscriptionInterest) =>
        (a.subscriptionId ?? '') > (b.subscriptionId ?? '')
          ? 1
          : (b.subscriptionId ?? '') > (a.subscriptionId ?? '')
          ? -1
          : 0,

      hiddenByColumnsButton: false,
      filtering: false,
      render: (rowData: SubscriptionInterest) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <Typography variant='body2' noWrap>
            {rowData.subscriptionId}
          </Typography>
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Activated
          </Typography>
        </Box>
      ),
      width: '20%',
      field: 'subscriptionName',
      customSort: (a: SubscriptionInterest, b: SubscriptionInterest) =>
        (a.startedDate ?? '') > (b.startedDate ?? '')
          ? 1
          : (b.startedDate ?? '') > (a.startedDate ?? '')
          ? -1
          : 0,

      hiddenByColumnsButton: false,
      filtering: false,
      render: (rowData: SubscriptionInterest) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <Typography variant='body2' noWrap>
            {moment(rowData.startedDate ?? '').format('MMM DD, YYYY')}
          </Typography>
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Deactivated
          </Typography>
        </Box>
      ),
      width: '20%',
      field: 'subscriptionName',
      customSort: (a: SubscriptionInterest, b: SubscriptionInterest) =>
        (a.startedDate ?? '') > (b.startedDate ?? '')
          ? 1
          : (b.startedDate ?? '') > (a.startedDate ?? '')
          ? -1
          : 0,

      hiddenByColumnsButton: false,
      filtering: false,
      render: (rowData: SubscriptionInterest) => (
        <Box display='flex' alignItems='center' p={0.5}>
          {rowData.endDate && (
            <Typography variant='body2' noWrap>
              {moment(rowData.endDate ?? '').format('MMM DD, YYYY')}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      title: (
        <Box minWidth='150px' minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Status
          </Typography>
        </Box>
      ),
      width: '20%',
      field: 'statusDisplay',
      customSort: (a: SubscriptionInterest, b: SubscriptionInterest) =>
        a.status > b.status ? 1 : b.status > a.status ? -1 : 0,

      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (rowData: SubscriptionInterest) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <Typography
            variant='body2'
            id={`txt_status_${rowData.id}`}
            noWrap
            style={{
              color: getColorOfSubscriptionStatus(rowData.status),
              fontWeight: 'bold',
            }}
          >
            {rowData.statusDisplay}
          </Typography>
        </Box>
      ),
      filtering: false,
    },

    {
      title: <Box />,
      width: '5%',
      field: 'subscriptionName',

      hiddenByColumnsButton: false,
      filtering: false,
      render: (rowData: SubscriptionInterest) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <MoreVert
            style={{ cursor: 'pointer' }}
            id={`txt_menu_${rowData.id}`}
            onClick={() => {
              if (rowData.status === 'requested') {
                if (isTermsAndConditionAccepted(rowData.task) === true) {
                  onOptForOfferClick && onOptForOfferClick(rowData)
                }
              }
            }}
          />
        </Box>
      ),
    },
  ]

  return (
    <Box width='100%' id='list38' height='100%'>
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              /* className={classes.toolbarWrapper} */
              style={{
                minHeight: '0px',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
          Container: (props) => (
            <Paper
              {...props}
              elevation={0}
              style={{
                maxHeight: '94%',
                height: '92%',
                backgroundColor: useTheme().palette.background.paper,
              }}
              classes={classes}
            />
          ),
          Pagination: (props) => (
            <td>
              <table
                style={{
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                }}
              >
                <tbody>
                  <tr>
                    <TablePagination
                      {...props}
                      labelRowsSelect={
                        <div style={{ fontSize: 14 }}> rows</div>
                      }
                      rowsPerPageOptions={[10, 20, 30]}
                      m
                      SelectProps={{
                        style: {
                          fontSize: 14,
                          minHeight: '5px',
                          backgroundColor:
                            useTheme().palette.background.default,
                        },
                      }}
                    />
                  </tr>
                </tbody>
              </table>
            </td>
          ),
        }}
        columns={columns}
        data={data}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        onRowClick={(event, rowData) => {
          /* if (rowData && onOrgSelected) {
            onOrgSelected(rowData.orgDetails, rowData.mainOrgId ?? '')
            setSelectedRowId(rowData ? rowData.orgName ?? '' : '')
          } */
        }}
        options={{
          searchFieldStyle: { padding: 4 },
          toolbar: false,
          search: false,
          showTitle: false,
          padding: 'dense',
          searchFieldVariant: 'outlined',
          filtering: false,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30],
          tableLayout: 'fixed',
          sorting: true,
          thirdSortClick: false,

          headerStyle: {
            backgroundColor: kBackgroundDefault,
            width: 100,
            minHeight: '15px',
            maxHeight: '15px',
            position: 'sticky',
            top: 0,
            // height: 20,
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          rowStyle: (
            _data: SubscriptionInterest,
            index: number,
            _level: number
          ) =>
            index % 2
              ? selectedRow && _data.id === selectedRowId
                ? {
                    backgroundColor: '#9E9DDC',
                    height: 50,
                    '&:hover': { backgroundColor: 'red' },
                  }
                : {
                    backgroundColor: kDialogueBackground,
                    '&:hover': { backgroundColor: 'red' },
                  }
              : selectedRow && _data.id === selectedRowId
              ? {
                  backgroundColor: '#9E9DDC',
                  height: 50,
                  '&:hover': { backgroundColor: 'red' },
                }
              : { '&:hover': { backgroundColor: 'red' } },
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },

          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />
    </Box>
  )
}
