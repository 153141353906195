import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { getExpandedAppointmentFromBundle } from 'utils/common/patientDataTableHelper'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import { UmlClient } from 'services/umlsClient'
import { EbmClinet } from 'services/ebmServiceClient'
import { IngredientSearchStatus } from './ingredientSearchStatus'

const initialState: IngredientSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const ingredientSearchSlice = createSlice({
  name: 'ingredient',
  initialState,
  reducers: {
    searchingAllergy(state, action: PayloadAction<IngredientSearchStatus>) {},

    searchResults(state, action: PayloadAction<IngredientSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.ingredientList = action.payload.ingredientList
    },

    noDataFoundForSearch(state, action: PayloadAction<IngredientSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.ingredientList = action.payload.ingredientList
    },

    errorWhileSearching(state, action: PayloadAction<IngredientSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.ingredientList = action.payload.ingredientList
    },
    resetState(state, action: PayloadAction<IngredientSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.ingredientList = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: IngredientSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      ingredientList: undefined,
    }
    dispatch(ingredientSearchSlice.actions.resetState(state))
  }

export const searchIngredient =
  (drugId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: IngredientSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      ingredientSearchSlice.actions.errorWhileSearching(errorSearchPatient)
    )
    try {
      const fhirClient: EbmClinet = new EbmClinet()
      const response: any = await fhirClient.doGetResource(
        `/drug/ingredients?drugCode=${drugId}`
      )
      if (response.length > 0) {
        const searchPatientResult: IngredientSearchStatus = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          ingredientList: response,
        }
        dispatch(
          ingredientSearchSlice.actions.searchResults(searchPatientResult)
        )
        return
      }

      const noSearchResults: IngredientSearchStatus = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(
        ingredientSearchSlice.actions.noDataFoundForSearch(noSearchResults)
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: IngredientSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        ingredientSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(ingredientSearchSlice.actions.resetState(initialState))
}

export default ingredientSearchSlice.reducer
