import { R4 } from '@ahryman40k/ts-fhir-types'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getUserDetails } from 'services/userDetailsService'

export async function getUnits() {
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const mainOrganization: R4.IOrganization | undefined =
    getUserDetails()?.mainOrganization
  let searchParametes = {}
  if (mainOrganization) {
    searchParametes = {
      partof: `${mainOrganization?.resourceType}/${mainOrganization.id ?? ''}`,
    }
  }

  const response: any = await fhirClient.doGetResource(
    '/Organization?_count=4&_tag:not=mirror-resource',
    searchParametes
  )

  const bundle: R4.IBundle = response as R4.IBundle

  if (bundle.entry && bundle.entry.length > 0) {
    const orgArray: R4.IOrganization[] = bundle.entry.map(
      (item) => item.resource as R4.IOrganization
    )
    return orgArray
  }

  return []
}
