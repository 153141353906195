import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import {
  WelloAyurvedaMedication,
  WelloMedication,
} from 'models/welloMedication'
import React, { useState } from 'react'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { AyurvedaMedicationsListIPD } from 'views/components/ipd/medicationListTableIPD'

interface MedicationTileProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  medication: WelloMedication[]
  onRepeat: (medication: WelloMedication) => void
  onDeleteClicked?: (medication: WelloMedication) => void
  onEditClicked?: (medication: WelloMedication) => void

  displayTypeOfMedication?: boolean
  displayStartEndDates?: boolean
  checked?: boolean
  split?: boolean
}

export const AddedMedicationsListIPD: React.FC<MedicationTileProps> = ({
  fhirAppointmentDetails,
  medication,
  onRepeat,
  onDeleteClicked,
  onEditClicked,
  displayTypeOfMedication = false,
  displayStartEndDates,
  checked,
  split,
}: MedicationTileProps) => {
  console.log('--------medication-----------', medication)
  const ayurvedaMedication = medication.filter((e) => e.type === 'ayurveda')
  const allopathyMedication = medication.filter((e) => e.type === 'allopathy')

  const [openIngredient, setOpenIngredient] = useState(false)
  const [selectedMedication, setSelectedMedication] = useState<R4.ICoding>()
  const [selectedRxNorm, setSelectedRxNorm] = useState<string>()

  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
    >
      {/* {displayTypeOfMedication && allopathyMedication.length > 0 && (
        <Box display='flex' flexDirection='row' py={1} px={0.5}>
          <Typography variant='subtitle2' color='initial'>
            {' '}
            Allopathy Medications
          </Typography>
        </Box>
      )}
      {allopathyMedication.length > 0 && (
        <Box display='flex' flexDirection='row'>
          <StatementMedicationTableTile medication={allopathyMedication} />
        </Box>
      )} */}
      {/* {ayurvedaMedication.length > 0 && displayTypeOfMedication && (
        <Box display='flex' flexDirection='row' py={1} px={0.5}>
          <Typography variant='subtitle2' color='initial'>
            {' '}
            Ayurvedic Medications
          </Typography>
        </Box>
      )} */}

      {ayurvedaMedication.length > 0 && (
        <Box display='flex' flexDirection='row'>
          <AyurvedaMedicationsListIPD
            fhirAppointmentDetails={fhirAppointmentDetails}
            medicationsList={ayurvedaMedication}
            displayStartEndDates={displayStartEndDates}
            onRepeat={(medicationRepeat: WelloMedication) => {
              onRepeat(medicationRepeat)
            }}
            onDelete={(medicationRepeat: WelloMedication) => {
              if (onDeleteClicked) {
                onDeleteClicked(medicationRepeat)
              }
            }}
            onEditClicked={(medicationRepeat: WelloMedication) => {
              if (onEditClicked) onEditClicked(medicationRepeat)
            }}
            checked={checked}
            split={split}
          />
        </Box>
      )}
    </Box>
  )
}
