import Paper from '@material-ui/core/Paper'
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  withStyles,
  Box,
  Typography,
} from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { kSelectedTabBackground } from 'configs/styles/muiThemes'
import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { WelloFormItemLabel } from './WelloFormItemLabel'

export interface ToggleOption {
  value: string
  displayText: string
}

export interface ToggleOptionProps {
  options: ToggleOption[]
  preSelectedValue?: string
  onSelectionChanged: (option: string) => void
  menuLabel?: string
  size?: 'small' | 'medium' | 'large'
  disablePreSelect?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: `1px solid ${theme.palette.divider}`,
    },
    divider: {
      margin: theme.spacing(1, 0.5),
    },
  })
)

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup)

export default function CustomizedDividersForLab(props: ToggleOptionProps) {
  const [value, setValue] = React.useState(
    props.disablePreSelect
      ? props.options[0].value
      : props.preSelectedValue ?? props.options[0].value
  )

  const { t, i18n } = useTranslation(['common', 'patient'])
  const language = sessionStorage.getItem('lang')

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      props.onSelectionChanged(newAlignment)
      setValue(newAlignment)
    }
  }

  useEffect(() => {
    i18n.changeLanguage(language ?? '')
  }, [])
  const classes = useStyles()

  const defaultBackgroundColor = useTheme().palette.background.default
  const paperBackGroundColor = useTheme().palette.background.paper

  return (
    <Box display='flex' flexDirection='row' py={1}>
      <Box paddingRight={2}>
        {props.menuLabel && <WelloFormItemLabel title={props.menuLabel} />}
      </Box>
      <StyledToggleButtonGroup
        size={props.size ?? 'small'}
        value={value}
        exclusive
        onChange={handleAlignment}
        aria-label='text alignment'
        style={{
          backgroundColor: defaultBackgroundColor,
          margin: props.size === 'small' ? '0px' : undefined,
          padding: props.size === 'small' ? '4px' : undefined,
        }}
        id='order_type_styled_button'
      >
        {props.options.map((option, index: number) => (
          <ToggleButton
            id={`order_type$${index}`}
            key={`tg_button_key${option.value}`}
            value={option.value}
            aria-label='left aligned'
            size='small'
            style={{
              background:
                option.value === value
                  ? paperBackGroundColor
                  : defaultBackgroundColor,
              margin: props.size === 'small' ? 1 : undefined,
              padding: props.size === 'small' ? 2 : undefined,
            }}
          >
            <Typography
              id={`order_typeText$${index}`}
              variant='subtitle2'
              style={{
                color: kSelectedTabBackground,
                letterSpacing: '1.0',
                paddingLeft: 8,
                paddingRight: 8,
              }}
            >
              {t('labelCommon:' + `${option.displayText}`)}
              {/* {option.displayText} */}
            </Typography>
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </Box>
  )
}
