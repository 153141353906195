import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import Divider from '@mui/material/Divider'
import { VisaDetails } from 'models/patientProfile'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addVisaDetails } from 'redux/patient/addVisaDetails/addVisaDetailsSlice'
import { RootState } from 'redux/rootReducer'
import { getVisaDetails } from 'utils/common/patientDataConverter'
import { purposeOfVisitForForegin, visaTypes } from 'utils/constants'
import {
  getPassportEndDate,
  getPassportStartDate,
} from 'utils/fhirResourcesHelper'
import {
  validateDateForPassportValidError,
  visaArrivalDateValidation,
  visaExpiryDateValidate,
  visaStartDateValidation,
  visaStartMaxDate,
  visaStartMinDate,
} from 'utils/formValidators'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { PatientTile } from 'views/components/patients/pateintTile'
import { WelloPlaceFinder } from 'views/mapComponent/placeAutoComplete'
import { WelloFormItemLabel, WelloTextField } from 'wello-web-components'
import { WelloSelect } from '../LeftMenu/WelloSelect'
import { PassportTile } from '../patients/passportTIle'

interface Props {
  patient: R4.IPatient
  open: boolean
  onClose: (chk: boolean) => void
  onVisaDetailsAdded: (visaDetails?: string) => void
}
export const AddVisaDetails: React.FC<Props> = ({
  patient,
  open,
  onClose,
  onVisaDetailsAdded,
}) => {
  const { t } = useTranslation()

  //   ------------ visa details -----------------
  const dispatch = useDispatch()
  const [visaNo, setVisaNo] = React.useState<string>('')
  const [purposeOfVisitFormc, setPurposeOfVisitFormc] = useState<R4.ICoding>(
    purposeOfVisitForForegin[0]
  )

  const visaData: VisaDetails = getVisaDetails(patient)

  const [existingPatient, setExistingPatient] = useState<VisaDetails>(visaData)
  const [visatype, setVisaType] = useState<R4.ICoding>(visaTypes[0])
  const [visaStartDate, setVisaStartDate] = useState<Date>()
  const [visaExpiryDate, setVisaExpiryDate] = useState<Date>()
  const [placeOfissue, setPlaceOfIssue] = useState<R4.ICoding>()
  const [arrivedFrom, setArrivedFrom] = useState<R4.ICoding>()
  const [arrivalDate, setArrivalDate] = useState<Date>()
  const [nextDestination, setNextDestination] = useState<R4.ICoding>()
  const [visaIssueCity, setVisaIssueCity] = useState<string>()
  const [visaIssueCountry, setVisaIssueCountry] = useState<string>()
  const [destCity, setDistCity] = useState<string>()
  const [distCountry, setDistCountry] = useState<string>()
  const [arrivalCity, setArrivalCity] = useState<string>()
  const [arrivalCountry, setArrivalCountry] = useState<string>()

  const addvisaDetailsSlice = useSelector(
    (state: RootState) => state.addVisaDetailsSlice
  )

  const handleVisaStartDate = (date: MaterialUiPickersDate) => {
    setVisaStartDate(date !== null ? moment(date).toDate() : new Date())
  }

  const handleVisaExpiry = (date: MaterialUiPickersDate) => {
    setVisaStartDate(date !== null ? moment(date).toDate() : new Date())
  }

  //   const handleArrivalDate = (date: MaterialUiPickersDate) => {
  //     setArrivalDate(date !== null ? moment(date).toDate() : new Date())
  //   }

  useEffect(() => {
    if (addvisaDetailsSlice.addedSuccessfully) {
      onVisaDetailsAdded(addvisaDetailsSlice.basic)
      resetForm()
    }
  }, [
    addvisaDetailsSlice.addedSuccessfully,
    addvisaDetailsSlice,
    onVisaDetailsAdded,
  ])

  function resetForm() {
    setVisaType(visaTypes[0])
    setVisaNo('')
    setPurposeOfVisitFormc(purposeOfVisitForForegin[0])
    setVisaStartDate(undefined)
    setVisaExpiryDate(undefined)
    setPlaceOfIssue(undefined)
    setArrivedFrom(undefined)
    setArrivalDate(undefined)
    setNextDestination(undefined)
    setVisaIssueCity(undefined)

    setDistCity(undefined)
    setDistCountry(undefined)
    setArrivalCity(undefined)
    setArrivalCountry(undefined)
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose(false)
        resetForm()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Add Visa Details</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                resetForm()
                onClose(false)
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='column' width='100%'>
            <WelloFormItemLabel title='Selected Patient' />
            <PatientTile patient={patient} />
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            //   justifyContent='space-between'
          >
            <Box width='100%' py={1}>
              <Typography variant='subtitle1' color='primary'>
                Passport Details
              </Typography>
              <Box paddingTop={0.5}>
                <Divider
                  orientation='horizontal'
                  variant='fullWidth'
                  flexItem
                  style={{ width: '100%' }}
                />
              </Box>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              width='100%'
              //   justifyContent='space-between'
            >
              <PassportTile patient={patient} />
              <Box py={0.5} px={0.5}>
                <Typography variant='subtitle2' color='error'>
                  {' '}
                  {validateDateForPassportValidError(
                    moment(
                      getPassportEndDate(
                        patient.extension,
                        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
                      )
                    ).format('YYYY-MM-DD'),
                    moment(
                      getPassportStartDate(
                        patient.extension,
                        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
                      )
                    ).format('YYYY-MM-DD'),
                    true
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            //   justifyContent='space-between'
          >
            <Box width='100%' py={1}>
              <Typography variant='subtitle1' color='primary'>
                Visa Details
              </Typography>
              <Box paddingTop={0.5}>
                <Divider
                  orientation='horizontal'
                  variant='fullWidth'
                  flexItem
                  style={{ width: '100%' }}
                />
              </Box>
            </Box>
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              //   justifyContent='space-between'
            >
              <Box
                display='flex'
                flexGrow={1}
                py={0.5}
                paddingRight={1}
                width='50%'
              >
                <WelloTextField
                  title='Visa No.'
                  textProps={{
                    value: existingPatient.visaNumber,
                    type: 'text',

                    error: existingPatient
                      ? existingPatient.visaNumber.length === 0 ||
                        existingPatient.visaNumber.length > 20
                      : false,
                    helperText: existingPatient
                      ? existingPatient.visaNumber.length === 0 ||
                        visaData.visaNumber.length > 20
                        ? 'Visa number is required'
                        : ''
                      : '',
                    onChange: (visaDataNo) => {
                      setExistingPatient({
                        ...existingPatient,
                        visaNumber: visaDataNo.target.value,
                      })
                      setVisaNo(visaDataNo.target.value)
                    },
                  }}
                />
              </Box>

              <Box
                display='flex'
                flexGrow={1}
                // alignItems='flex-end'
                // justifyContent='center'
                py={0.5}
                paddingLeft={1}
                width='50%'
              >
                <WelloSelect
                  label={t('formCLabel:visaType')}
                  availableOptions={
                    t(
                      'formCEntry:entry_attrs.visaType.value-set'
                    ) as R4.ICoding[]
                  }
                  required={true}
                  type='Select Visa Type'
                  displayField='display'
                  valueField='code'
                  preSelectedOption={existingPatient.visaTypeCoding}
                  onSelectionChanged={(valueData: any) => {
                    setExistingPatient({
                      ...existingPatient,
                      visaTypeCoding: valueData,
                    })
                    setVisaType(valueData)
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            //   justifyContent='space-between'
          >
            <Box
              display='flex'
              flexGrow={1}
              py={0.5}
              paddingRight={1}
              width='50%'
            >
              <WelloTextField
                title={t('formCLabel:visaDateOfIssue')}
                textProps={{
                  id: `actor_email_unit`,
                  value: existingPatient.visaIssueDate
                    ? moment(existingPatient.visaIssueDate).format('YYYY-MM-DD')
                    : undefined,
                  error: existingPatient.visaIssueDate
                    ? visaStartDateValidation(
                        patient,
                        true,
                        existingPatient.visaIssueDate
                      ).length > 0
                    : false,
                  helperText: visaStartDate
                    ? visaStartDateValidation(
                        patient,
                        true,
                        existingPatient.visaIssueDate
                      )
                    : false,
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Box>
                          <Tooltip title=''>
                            <IconButton
                              aria-label='btn_ord_cancel'
                              color='primary'
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/Shape.png`}
                                alt=''
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </InputAdornment>
                    ),
                  },
                  inputProps: {
                    min: moment(visaStartMinDate(patient)).format('YYYY-MM-DD'),
                    max: moment(visaStartMaxDate(patient)).format('YYYY-MM-DD'),
                  },

                  type: 'date',
                  onChange: (changeData) => {
                    setExistingPatient({
                      ...existingPatient,
                      visaIssueDate: moment(
                        changeData.target.value,
                        'YYYY-MM-DD'
                      ).toDate(),
                    })
                    setVisaStartDate(
                      moment(changeData.target.value, 'YYYY-MM-DD').toDate()
                    )
                  },
                }}
              />
            </Box>

            <Box
              display='flex'
              flexGrow={1}
              // alignItems='flex-end'
              // justifyContent='center'
              py={0.5}
              paddingLeft={1}
              width='50%'
            >
              <WelloTextField
                title={t('formCLabel:visaValidUntilDate')}
                textProps={{
                  id: `actor_email_unit`,
                  value: existingPatient.visaExpiryDate
                    ? moment(existingPatient.visaExpiryDate).format(
                        'YYYY-MM-DD'
                      )
                    : undefined,
                  error: existingPatient.visaExpiryDate
                    ? visaExpiryDateValidate(
                        patient,
                        true,
                        existingPatient.visaIssueDate,
                        existingPatient.visaExpiryDate
                      ).length > 0
                    : false,
                  helperText: existingPatient.visaExpiryDate
                    ? visaExpiryDateValidate(
                        patient,
                        true,
                        existingPatient.visaIssueDate,
                        existingPatient.visaExpiryDate
                      )
                    : '',
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Box>
                          <Tooltip title=''>
                            <IconButton
                              aria-label='btn_ord_cancel'
                              color='primary'
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/Shape.png`}
                                alt=''
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </InputAdornment>
                    ),
                  },
                  inputProps: {
                    min: existingPatient.visaIssueDate
                      ? moment(visaStartDate).format('YYYY-MM-DD')
                      : moment().format('YYYY-MM-DD'),
                    max: moment(visaStartMaxDate(patient)).format('YYYY-MM-DD'),
                  },

                  type: 'date',
                  onChange: (changeData) => {
                    setExistingPatient({
                      ...existingPatient,
                      visaExpiryDate: moment(
                        changeData.target.value,
                        'YYYY-MM-DD'
                      ).toDate(),
                    })
                    setVisaExpiryDate(
                      moment(changeData.target.value, 'YYYY-MM-DD').toDate()
                    )
                  },
                }}
              />
            </Box>
          </Box>

          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            //   justifyContent='space-between'
          >
            <Box
              display='flex'
              flexGrow={1}
              py={0.5}
              paddingRight={1}
              width='50%'
            >
              <WelloPlaceFinder
                title={t('formCLabel:visaPlaceOfIssue')}
                nationality=''
                textProps={{
                  required: true,
                  id: 'unit_actor_current_address',
                  placeholder: 'Your Home address',
                  name: 'city',
                  value: 'Bangalore',
                  onChange: (changedValue: any) => {
                    //   handleLabAddressChange(changedValue.target.value)
                  },
                }}
                valueData={existingPatient.visaIssueCity}
                mapkey=''
                onUpdated={(
                  address: string,
                  mapLatlog: {
                    lat: number
                    lng: number
                  }
                ) => {
                  if (address.split(',').length > 2) {
                    setExistingPatient({
                      ...existingPatient,
                      visaIssueCity: address
                        .split(',')[0]
                        .replaceAll(',', '')
                        .trim(),
                      visaIssueCountry: address
                        .split(',')[0]
                        .replaceAll(',', '')
                        .trim(),
                    })

                    setVisaIssueCity(
                      address.split(',')[0].replaceAll(',', '').trim()
                    )
                    if (address.split(',').length === 4) {
                      setExistingPatient({
                        ...existingPatient,
                        visaIssueCity: address
                          .split(',')[3]
                          .replaceAll(',', '')
                          .trim(),
                        visaIssueCountry: address
                          .split(',')[3]
                          .replaceAll(',', '')
                          .trim(),
                      })
                      setVisaIssueCountry(
                        address.split(',')[3].replaceAll(',', '').trim()
                      )
                    } else {
                      setExistingPatient({
                        ...existingPatient,
                        visaIssueCity: address
                          .split(',')[2]
                          .replaceAll(',', '')
                          .trim(),
                        visaIssueCountry: address
                          .split(',')[2]
                          .replaceAll(',', '')
                          .trim(),
                      })
                      setVisaIssueCountry(
                        address.split(',')[2].replaceAll(',', '').trim()
                      )
                    }
                  } else {
                    setExistingPatient({
                      ...existingPatient,
                      visaIssueCity: address
                        .split(',')[0]
                        .replaceAll(',', '')
                        .trim(),
                      visaIssueCountry: address
                        .split(',')[1]
                        .replaceAll(',', '')
                        .trim(),
                    })
                    setVisaIssueCity(
                      address.split(',')[0].replaceAll(',', '').trim()
                    )

                    setVisaIssueCountry(
                      address.split(',')[1].replaceAll(',', '').trim()
                    )
                  }

                  // handleChangeState(address.split(',')[1], 'state')
                }}
              />
            </Box>

            <Box
              display='flex'
              flexGrow={1}
              // alignItems='flex-end'
              // justifyContent='center'
              py={0.5}
              paddingLeft={1}
              width='50%'
            >
              <Box>
                <WelloTextField
                  title={t('formCLabel:visitorPermanentAddressCountry')}
                  textProps={{
                    style: {
                      //   maxWidth: '480px',
                      width: '263px',
                    },
                    id: 'passportIssueCountry',
                    fullWidth: true,
                    required: true,
                    disabled: true,
                    // onChange: handleInputChange,
                    value: existingPatient.visaIssueCountry ?? '',
                    name: 'passportIssueCountry',
                    // error: errors.addressProofId,
                    // helperText: errors.addressProofId,
                    size: 'small',
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box display='flex' flexDirection='column' width='100%'>
            <Box width='100%' py={1}>
              <Typography variant='subtitle1' color='primary'>
                Arrival Details
              </Typography>
              <Box paddingTop={0.5}>
                <Divider
                  orientation='horizontal'
                  variant='fullWidth'
                  flexItem
                  style={{ width: '100%' }}
                />
              </Box>
            </Box>
            <Box display='flex' flexDirection='row' width='100%'>
              <Box
                display='flex'
                flexGrow={1}
                py={0.5}
                paddingRight={1}
                width='50%'
              >
                <WelloTextField
                  title={t('formCLabel:arrivalDate')}
                  textProps={{
                    id: `actor_email_unit`,
                    value: arrivalDate
                      ? moment(arrivalDate).format('YYYY-MM-DD')
                      : undefined,
                    error: arrivalDate
                      ? visaArrivalDateValidation(
                          true,
                          visaStartDate,
                          visaExpiryDate,
                          arrivalDate
                        ).length > 0
                      : false,
                    helperText: arrivalDate
                      ? visaArrivalDateValidation(
                          true,
                          visaStartDate,
                          visaExpiryDate,
                          arrivalDate
                        )
                      : '',
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Box>
                            <Tooltip title=''>
                              <IconButton
                                aria-label='btn_ord_cancel'
                                color='primary'
                              >
                                <img
                                  src={`${process.env.PUBLIC_URL}/Shape.png`}
                                  alt=''
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </InputAdornment>
                      ),
                    },
                    inputProps: {
                      min: visaStartDate
                        ? moment(visaStartDate).format('YYYY-MM-DD')
                        : moment().format('YYYY-MM-DD'),
                      max: moment().format('YYYY-MM-DD'),
                    },

                    type: 'date',
                    onChange: (changeData) => {
                      setArrivalDate(
                        moment(changeData.target.value, 'YYYY-MM-DD').toDate()
                      )
                    },
                  }}
                />
              </Box>

              <Box
                display='flex'
                flexGrow={1}
                // alignItems='flex-end'
                // justifyContent='center'
                py={0.5}
                paddingLeft={1}
                width='50%'
              >
                <WelloSelectFHIRCoding
                  title={t('formCLabel:purposeOfVisit')}
                  //   id='ipd_purpose_of_visit'
                  id='purpose'
                  availableCodings={
                    t(
                      'formCEntry:entry_attrs.purposeOfVisit.value-set'
                    ) as R4.ICoding[]
                  }
                  onChanged={(type) => {
                    setPurposeOfVisitFormc(type)
                  }}
                  preSelectedCoding={purposeOfVisitForForegin[0]}
                  textFieldProps={{
                    size: 'small',
                    fullWidth: true,
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            //   justifyContent='space-between'
          >
            <Box
              display='flex'
              flexGrow={1}
              py={0.5}
              paddingRight={1}
              width='50%'
            >
              <WelloPlaceFinder
                title={t('formCLabel:arrivalFrom')}
                nationality=''
                textProps={{
                  required: true,
                  id: 'unit_actor_current_address',
                  placeholder: 'Your Home address',
                  name: 'city',
                  value: 'Bangalore',
                  onChange: (changedValue: any) => {
                    //   handleLabAddressChange(changedValue.target.value)
                  },
                }}
                valueData=''
                mapkey=''
                onUpdated={(
                  address: string,
                  mapLatlog: {
                    lat: number
                    lng: number
                  }
                ) => {
                  if (address.split(',').length > 2) {
                    setArrivalCity(
                      address.split(',')[0].replaceAll(',', '').trim()
                    )
                    if (address.split(',').length === 4) {
                      setArrivalCountry(
                        address.split(',')[3].replaceAll(',', '').trim()
                      )
                    } else {
                      setArrivalCountry(
                        address.split(',')[2].replaceAll(',', '').trim()
                      )
                    }
                  } else {
                    setArrivalCity(
                      address.split(',')[0].replaceAll(',', '').trim()
                    )

                    setArrivalCountry(
                      address.split(',')[1].replaceAll(',', '').trim()
                    )
                  }

                  // handleChangeState(address.split(',')[1], 'state')
                }}
              />
            </Box>

            <Box
              display='flex'
              flexGrow={1}
              // alignItems='flex-end'
              // justifyContent='center'
              py={0.5}
              paddingLeft={1}
              width='50%'
            >
              {arrivalCountry! && (
                <Box>
                  <WelloTextField
                    title='Arrival Country'
                    textProps={{
                      style: {
                        //   maxWidth: '480px',
                        width: '263px',
                      },
                      id: 'arrivalCountry',
                      fullWidth: true,
                      required: true,
                      disabled: true,
                      // onChange: handleInputChange,
                      value: arrivalCountry ?? '',
                      name: 'arrivalCountry',
                      // error: errors.addressProofId,
                      // helperText: errors.addressProofId,
                      size: 'small',
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            //   justifyContent='space-between'
          >
            <Box width='100%' py={1}>
              <Typography variant='subtitle1' color='primary'>
                Destination Details
              </Typography>
              <Box paddingTop={0.5}>
                <Divider
                  orientation='horizontal'
                  variant='fullWidth'
                  flexItem
                  style={{ width: '100%' }}
                />
              </Box>
            </Box>

            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              //   justifyContent='space-between'
            >
              <Box
                display='flex'
                flexGrow={1}
                py={0.5}
                paddingRight={1}
                width='50%'
              >
                <WelloPlaceFinder
                  title={t('formCLabel:nextDestination')}
                  nationality=''
                  textProps={{
                    style: {
                      //   maxWidth: '480px',
                      width: '261px',
                    },
                    required: true,
                    id: 'unit_actor_current_address',
                    placeholder: 'Your Home address',
                    name: 'city',
                    value: 'Bangalore',
                    onChange: (changedValue: any) => {
                      //   handleLabAddressChange(changedValue.target.value)
                    },
                  }}
                  valueData=''
                  mapkey=''
                  onUpdated={(
                    address: string,
                    mapLatlog: {
                      lat: number
                      lng: number
                    }
                  ) => {
                    if (address.split(',').length > 2) {
                      setDistCity(
                        address.split(',')[0].replaceAll(',', '').trim()
                      )
                      if (address.split(',').length === 4) {
                        setDistCountry(
                          address.split(',')[3].replaceAll(',', '').trim()
                        )
                      } else {
                        setDistCountry(
                          address.split(',')[2].replaceAll(',', '').trim()
                        )
                      }
                    } else {
                      setDistCity(
                        address.split(',')[0].replaceAll(',', '').trim()
                      )

                      setDistCountry(
                        address.split(',')[1].replaceAll(',', '').trim()
                      )
                    }

                    // handleChangeState(address.split(',')[1], 'state')
                  }}
                />
                <Box
                  display='flex'
                  flexGrow={1}
                  // alignItems='flex-end'
                  // justifyContent='center'

                  paddingLeft={2}
                  width='50%'
                >
                  {distCountry! && (
                    <Box>
                      <WelloTextField
                        title='Destination Country'
                        textProps={{
                          style: {
                            //   maxWidth: '480px',
                            width: '263px',
                          },
                          id: 'distCountry',
                          fullWidth: true,
                          required: true,
                          disabled: true,
                          // onChange: handleInputChange,
                          value: distCountry ?? '',
                          name: 'distCountry',
                          // error: errors.addressProofId,
                          // helperText: errors.addressProofId,
                          size: 'small',
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {addvisaDetailsSlice.adding && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
          >
            <CircularProgress size={25} />
          </Box>
        )}
        <Button
          onClick={() => {
            dispatch(
              addVisaDetails(
                patient,
                existingPatient,
                visaNo,
                visaStartDate!,
                visaExpiryDate!,
                purposeOfVisitFormc,
                visatype,
                placeOfissue!,
                visaIssueCity!,
                visaIssueCountry!,
                arrivalCity!,
                arrivalCountry!,
                arrivalDate!,
                destCity!,
                distCountry!
              )
            )
          }}
          disabled={
            existingPatient.visaNumber.length === 0 ||
            addvisaDetailsSlice.adding ||
            existingPatient.visaIssueDate === undefined ||
            existingPatient.visaExpiryDate === undefined ||
            existingPatient.visaTypeCoding === undefined ||
            existingPatient.visaIssueCity === undefined ||
            distCountry === undefined ||
            arrivalDate === undefined ||
            visaStartDateValidation(
              patient,
              true,
              existingPatient.visaIssueDate
            ).length > 0 ||
            visaExpiryDateValidate(
              patient,
              true,
              existingPatient.visaIssueDate,
              existingPatient.visaExpiryDate
            ).length > 0 ||
            visaArrivalDateValidation(
              true,
              visaStartDate,
              visaExpiryDate,
              arrivalDate
            ).length > 0
          }
          variant='contained'
          color='primary'
          disableElevation
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}
