/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestDietDetailsHistory } from 'redux/ipd/diet/dietHistory/dietHistorySearchSlice'
import { RootState } from 'redux/rootReducer'
import { getCatalogDetailsFromIds } from 'utils/fhirResoureHelpers/catalogHelper'
import {
  getIdsOfSelectedDetailsForHistory,
  getTimeString,
} from 'utils/fhirResoureHelpers/ipdDietHelper'

interface VitalsProp {
  serviceRequest: R4.IServiceRequest
}

export const DietTile: React.FC<VitalsProp> = ({
  serviceRequest,
}: VitalsProp) => {
  const { t } = useTranslation(['common'])

  const dispatch = useDispatch()
  const dietSearchSlice = useSelector(
    (state: RootState) => state.dietHistorySearchSlice
  )
  const addDietSlice = useSelector(
    (state: RootState) => state.nutritionCatalogueValueSetSlice
  )

  useEffect(() => {
    dispatch(requestDietDetailsHistory(serviceRequest.id!, 'yes'))
  }, [])

  return (
    <Box>
      <Grid container direction='column' style={{ flexWrap: 'nowrap' }}>
        <Grid item>
          <Grid container direction='row' id='vitals'>
            <Box py={1}>
              <Typography
                variant='subtitle2'
                style={{ color: '#4D4D4D', textTransform: 'uppercase' }}
              >
                {' '}
                Diet
              </Typography>
            </Box>{' '}
          </Grid>
        </Grid>
        {dietSearchSlice.resultsAvailable &&
          dietSearchSlice.dietList &&
          dietSearchSlice.dietList.length > 0 && (
            <Grid item>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                width='100%'
                border={4}
                style={{
                  boxShadow: '0px 0px 4px #ccc',
                  margin: '2px 0px',
                  borderRadius: '1px',
                  borderColor: '#F8F8F8',
                  width: '100%',
                  height: 'auto',
                  backgroundColor: '#F8F8F8',
                }}
              >
                <Grid container direction='row' spacing={1}>
                  <Grid
                    container
                    direction='row'
                    id='vitals'
                    style={{
                      backgroundColor: kDialogueBackground,
                    }}
                  >
                    <Grid item xs={3}>
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                              px={1}
                            >
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                Date
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            display='flex'
                            flexGrow={1}
                            justifyContent='flex-end'
                          >
                            <Divider
                              orientation='vertical'
                              flexItem
                              style={{
                                height: '30px',

                                width: '2px',
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                              px={1}
                            >
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                Time
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            display='flex'
                            flexGrow={1}
                            justifyContent='flex-end'
                          >
                            <Divider
                              orientation='vertical'
                              flexItem
                              style={{
                                height: '30px',

                                width: '2px',
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                              px={1}
                            >
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                Diet
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            display='flex'
                            flexGrow={1}
                            justifyContent='flex-end'
                          >
                            <Divider
                              orientation='vertical'
                              flexItem
                              style={{
                                height: '30px',

                                width: '2px',
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                              px={1}
                            >
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                Status
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            display='flex'
                            flexGrow={1}
                            justifyContent='flex-end'
                          >
                            <Divider
                              orientation='vertical'
                              flexItem
                              style={{
                                height: '30px',

                                width: '2px',
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        {dietSearchSlice.searchingTreatment && (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            width='100%'
            height='100%'
            alignContent='center'
          >
            <Typography variant='subtitle1' color='initial' align='center'>
              <CircularProgress size={25} />
            </Typography>
          </Box>
        )}

        {dietSearchSlice.errorWhileSearchingTreatment && (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            width='100%'
            height='100%'
            alignContent='center'
          >
            <Typography variant='subtitle1' color='initial' align='center'>
              Some Error has occured
            </Typography>
          </Box>
        )}

        {dietSearchSlice.noResultsAvailable && (
          <Box
            display='flex'
            flexDirection='column'
            alignContent='flex-start'
            width='100%'
            height='100%'
          >
            <Typography
              variant='subtitle2'
              style={{
                fontWeight: 400,
              }}
            >
              No data available
            </Typography>
          </Box>
        )}

        {dietSearchSlice.resultsAvailable &&
          dietSearchSlice.dietList &&
          dietSearchSlice.dietList.length === 0 && (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='flex-start'
              width='100%'
              height='100%'
            >
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                No data available
              </Typography>
            </Box>
          )}
        {dietSearchSlice.resultsAvailable &&
          dietSearchSlice.dietList &&
          dietSearchSlice.dietList.length > 0 && (
            <Grid item>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                width='100%'
                border={4}
                style={{
                  boxShadow: '0px 0px 4px #ccc',
                  margin: '2px 0px',
                  borderRadius: '1px',
                  borderColor: '#F8F8F8',
                  width: '100%',
                  height: 'auto',
                  backgroundColor: '#F8F8F8',
                }}
              >
                <Grid container direction='row' spacing={1}>
                  {dietSearchSlice.dietList.map(
                    (dietData: R4.INutritionOrder, index: number) => (
                      <Box width='100%'>
                        <Grid
                          container
                          direction='row'
                          id='vitals'
                          style={{
                            backgroundColor:
                              index % 2 ? kDialogueBackground : 'white',
                          }}
                        >
                          <Grid item xs={3}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,

                                      fontWeight: 600,
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    {moment(
                                      dietData.oralDiet
                                        ? dietData.oralDiet.schedule
                                          ? dietData.oralDiet.schedule[0].event
                                            ? dietData.oralDiet.schedule[0]
                                                .event[0]
                                            : new Date()
                                          : new Date()
                                        : new Date()
                                    ).format('DD-MM-YYYY')}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                flexGrow={1}
                                justifyContent='flex-end'
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    height: '30px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={3}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,

                                      fontWeight: 600,
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    {getTimeString(
                                      dietData.oralDiet
                                        ? dietData.oralDiet.schedule
                                          ? dietData.oralDiet.schedule[0].code
                                            ? dietData.oralDiet.schedule[0].code
                                                .coding
                                              ? dietData.oralDiet.schedule[0]
                                                  .code.coding[0].code ?? ''
                                              : ''
                                            : ''
                                          : ''
                                        : ''
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                flexGrow={1}
                                justifyContent='flex-end'
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    height: '30px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={4}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,

                                      fontWeight: 600,
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    {getCatalogDetailsFromIds(
                                      addDietSlice.catalogueEntries ?? [],
                                      getIdsOfSelectedDetailsForHistory(
                                        dietData
                                      ) ?? ''
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                flexGrow={1}
                                justifyContent='flex-end'
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    height: '30px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={2}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,

                                      fontWeight: 600,
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    {dietData.status
                                      ? dietData.status === 'completed'
                                        ? 'Completed'
                                        : ''
                                      : ''}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                flexGrow={1}
                                justifyContent='flex-end'
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    height: '30px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container direction='row' id='vitals'>
                          <Grid item xs={12}>
                            <Box width='100%' paddingLeft={0.2}>
                              <Divider
                                orientation='horizontal'
                                flexItem
                                style={{
                                  height: '2px',
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    )
                  )}
                </Grid>
              </Box>
            </Grid>
          )}
      </Grid>
    </Box>
  )
}
