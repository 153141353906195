import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { getExpandedAppointmentFromBundle } from 'utils/common/patientDataTableHelper'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import { UmlClient } from 'services/umlsClient'
import { getUserCurrentRole } from 'services/userDetailsService'
import { RespiratoryConditionsSearchTypes as GastroIntestinalConditionsSearchStateType } from './gastroIntestinalConditionsSearchTypes'

const initialState: GastroIntestinalConditionsSearchStateType = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const gastroIntestinalConditionsSearchSlice = createSlice({
  name: 'gastroIntestinalConditionsSearchSlice',
  initialState,
  reducers: {
    searchingCondition(
      state,
      action: PayloadAction<GastroIntestinalConditionsSearchStateType>
    ) {},

    searchResults(
      state,
      action: PayloadAction<GastroIntestinalConditionsSearchStateType>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<GastroIntestinalConditionsSearchStateType>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
    },

    errorWhileSearching(
      state,
      action: PayloadAction<GastroIntestinalConditionsSearchStateType>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
    },
    resetState(
      state,
      action: PayloadAction<GastroIntestinalConditionsSearchStateType>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.surgeryList = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: GastroIntestinalConditionsSearchStateType = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      surgeryList: undefined,
    }
    dispatch(gastroIntestinalConditionsSearchSlice.actions.resetState(state))
  }

export const searchConditions =
  (searchString: string, selectOther: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: GastroIntestinalConditionsSearchStateType = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      gastroIntestinalConditionsSearchSlice.actions.errorWhileSearching(
        errorSearchPatient
      )
    )
    try {
      const fhirClient: UmlClient = new UmlClient()
      const currentRole = getUserCurrentRole()
      const response: any = await fhirClient.doGetResource(
        `umls/ValueSetMatcher?Path=gastro&Match=${searchString}`
      )
      if (response.length > 0) {
        const searchPatientResult: GastroIntestinalConditionsSearchStateType = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          surgeryList: response,
          totalCount: response.total,
        }
        dispatch(
          gastroIntestinalConditionsSearchSlice.actions.searchResults(
            searchPatientResult
          )
        )
        return
      }

      const noSearchResults: GastroIntestinalConditionsSearchStateType = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(
        gastroIntestinalConditionsSearchSlice.actions.noDataFoundForSearch(
          noSearchResults
        )
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: GastroIntestinalConditionsSearchStateType =
        {
          error: true,
          noResultsAvailable: false,
          resultsAvailable: false,
          searching: false,
          errorMessage: 'Error',
        }
      dispatch(
        gastroIntestinalConditionsSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(
    gastroIntestinalConditionsSearchSlice.actions.resetState(initialState)
  )
}

export default gastroIntestinalConditionsSearchSlice.reducer
