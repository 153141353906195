import {
  Avatar,
  Box,
  Card,
  CardContent,
  List,
  Typography,
} from '@material-ui/core'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React from 'react'
import { getTelecomOfPractitioner } from 'utils/fhirResourcesHelper'

interface Props {
  onPractitionerSelected?: (selectedPractioner: PractitionerWithRole) => void
  practitionerList: PractitionerWithRole[]
  id?: string
  valueField?: string
}

export const AgentListView: React.FC<Props> = ({
  onPractitionerSelected,
  practitionerList,
  id,
  valueField,
}: Props) => (
  <Box width='100%' py={1}>
    <List
      style={{
        padding: 0,
        width: '100%',
        overflow: 'auto',
        height: '100%',
      }}
    >
      <li style={{ padding: 0, width: '100%' }}>
        {practitionerList &&
          practitionerList.map((item: PractitionerWithRole, index: any) => (
            <>
              <Box
                width='100%'
                borderRadius={1}
                onClick={() => {
                  if (onPractitionerSelected) onPractitionerSelected(item)
                }}
              >
                <Card
                  variant='outlined'
                  elevation={1}
                  style={{
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <CardContent
                    style={{
                      padding: 8,
                      paddingLeft: 16,
                      paddingRight: 16,
                      border: 0,
                    }}
                  >
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        px={1}
                      >
                        <Avatar alt={item.fullName} src='test.png' />
                      </Box>

                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        px={0.5}
                      >
                        <Box>
                          <Typography color='primary' variant='subtitle2'>
                            {item.name}
                          </Typography>
                        </Box>
                        <Box>{getTelecomOfPractitioner(item.roleObject)}</Box>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </>
          ))}
      </li>
    </List>
  </Box>
)
