import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedWelloMedication } from 'models/groupedWelloMedication'
import { PurposeOfUse } from 'models/purposeOfUse'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestMedicationHistoryOfPatient } from 'redux/patientMedicalHistory/medicationHistory/medicationHistorySearchSlice'
import { RootState } from 'redux/rootReducer'
import { isMedicalServiceProvider } from 'services/userDetailsService'
import { StatementMedicationTableTile } from 'views/components/consultation/assessment/statementTabularFormat'
import { StatementMedicationTableTileForHistory } from './statementMedicationHistoryTile'

interface MedicationProp {
  medicationList: GroupedWelloMedication[]
}

export const CurrentMedication: React.FC<MedicationProp> = ({
  medicationList,
}: MedicationProp) => {
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const dispatch = useDispatch()
  const medicationHistorySearchSlice = useSelector(
    (state: RootState) => state.medicationHistorySearchSlice
  )
  const [groupVitals, setGroupVitals] = useState<GroupedWelloMedication[]>([])

  useEffect(() => {
    if (medicationList.length > 0) {
      updateMedicationList(medicationList)
    }
  }, [medicationHistorySearchSlice])

  function updateMedicationList(procedureList: GroupedWelloMedication[]) {
    const results: GroupedWelloMedication[] = []
    for (let i = 0; i < procedureList.length; i++) {
      results.push({
        date: procedureList[i].date,
        medications: procedureList[i].medications,
        collapse: true,
      })
    }
    setGroupVitals(results)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: GroupedWelloMedication[] = [...groupVitals]
    values[index].collapse = rate
    setGroupVitals(values)
  }
  return (
    <Box
      flexGrow
      width='100%'
      flexDirection='column'
      display='flex'
      style={{ padding: 0, margin: 0 }}
    >
      <Box
        flexGrow
        width='100%'
        display='flex'
        flexDirection='row'
        onMouseEnter={() => showAddButton(true)}
        onMouseLeave={() => showAddButton(false)}
      >
        <Box flexDirection='row' display='flex' flexGrow width='100%'>
          <Box py={1}>
            <Typography variant='subtitle1'> Current Medication</Typography>
          </Box>{' '}
          {/* {isMedicalServiceProvider() && status !== 'completed' && !splitView && (
            <IconButton
              style={{ padding: '4px' }}
              onClick={() => {
                setOpenAddDialogue(true)
              }}
            >
              <Add style={{ height: '14px' }} color='primary' />
            </IconButton>
          )} */}
        </Box>
      </Box>

      {medicationList.length === 0 && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            No data available
          </Typography>
        </Box>
      )}

      {medicationList.length > 0 && (
        <Box marginY={1} display='flex' flexDirection='column' width='100%'>
          {groupVitals.map((e, index: number) => (
            <Box py={1} key={e.date ?? ''}>
              <Box
                paddingX={1}
                borderRadius={2}
                style={{
                  backgroundColor: 'lightGrey',
                }}
                display='flex'
                flexDirection='row'
                width='100%'
                height={40}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow={1}
                  alignItems='center'
                >
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: 'black',

                      fontWeight: 'bold',
                    }}
                  >
                    Recorded on {moment(e.date).format('Do MMMM YYYY hh:mm A')}
                  </Typography>
                </Box>
                <Box
                  justifyContent='flex-end'
                  display='flex'
                  flexGrow={1}
                  alignItems='center'
                >
                  <Box px={1}>
                    <Tooltip title=''>
                      <IconButton
                        aria-label='collapse_order_type'
                        size='small'
                        onClick={() => {
                          handleCollapseForPanel1(!e.collapse, index)
                        }}
                      >
                        {e.collapse && <ArrowDropUpOutlined />}
                        {!e.collapse && <ArrowDropDownOutlined />}
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
              <Collapse
                in={e.collapse}
                style={{
                  width: '100%',
                }}
              >
                <StatementMedicationTableTileForHistory
                  medication={e.medications}
                />
              </Collapse>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}
