import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  getCodeOfSystemCodings,
  getCodeOfSystemFromCodableConceptList,
  getColorBasedOnNumber,
} from 'utils/fhirResourcesHelper'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getTimeOfDayDisplayForStatement,
  getTimeOfDayDisplayMeal,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import {
  getNotesFromAllergy,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import OverflowTypography from '../plan/ayurveda/overflowTypography'

interface OccupationTileProps {
  obsData: R4.IObservation[]
  type: string
}

export const DischargeNotesTabular: React.FC<OccupationTileProps> = ({
  obsData,
  type,
}: OccupationTileProps) => {
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Grid
        container
        direction='row'
        id='vitals'
        style={{
          backgroundColor: kDialogueBackground,
        }}
      >
        <Grid item xs={3}>
          <Box display='flex' flexDirection='column' width='100%'>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Discharge Reason
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '30px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={2}>
          <Box display='flex' flexDirection='column' width='100%' flexGrow={1}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Recorded By
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '30px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
            <Box
              display='flex'
              flexDirection='column'
              width='100%'
              flexGrow={1}
            >
              <Box
                justifyContent='flex-start'
                display='flex'
                flexGrow
                height={30}
                alignItems='center'
                px={1}
              >
                <Typography
                  variant='subtitle2'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Additional Notes
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* <Grid container direction='row' id='vitals'>
        <Grid item xs={12}>
          <Box width='100%' paddingLeft={0.2}>
            <Divider
              orientation='horizontal'
              flexItem
              style={{
                height: '2px',
              }}
            />
          </Box>
        </Grid>
      </Grid> */}
      <Grid container direction='row' spacing={1}>
        {/* ------------------------------------------------------------------------------- */}
        {obsData.map((e) => (
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            flexGrow={1}
            key={e.id}
          >
            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor: 'white',
              }}
            >
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {e.code
                          ? e.code.coding
                            ? e.code.coding[0].display ?? ''
                            : ''
                          : ''}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {e.performer && e.performer.length > 0
                          ? e.performer[0].display ?? ''
                          : ''}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={7}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      {hasNotes(e.note) && (
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow
                          overflow='auto'
                        >
                          {getNotesFromAllergy(e.note)?.map((eData) => (
                            <Box
                              key={eData}
                              width='100%'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                            >
                              <OverflowTypography
                                text={eData ?? ''}
                                typographyProps={{
                                  variant: 'body2',
                                  color: 'initial',
                                }}
                              />
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.2}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',
    
                            width: '2px',
                          }}
                        />
                      </Box> */}
                </Box>
              </Grid>
            </Grid>
            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Grid>
    </Box>
  )
}
