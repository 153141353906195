/* eslint-disable prefer-destructuring */
/* eslint-disable no-await-in-loop */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource } from 'axios'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { DateWiseIPDAppointments } from 'models/dateSeparatedIPDAppointments'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import {
  ReportColumns,
  ReportColumnsForAppointment,
} from 'models/Report/reportColumn'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getCurrentUserUnitReference,
  isOrgAdmin,
  isTherapist,
} from 'services/userDetailsService'
import { getIdentifierValueBySystem } from 'utils/fhirResourcesHelper'
import { getDataFOrDashboardTable } from 'utils/fhirResoureHelpers/dashboardReportHelper'
import {
  getDateWiseIPD,
  getExpandedAppointmentFromBundleForReport,
  getExpandedServiceRequestFromBundleForIpd,
} from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { logger } from 'utils/logger'
import { DashboardSearchStatus } from './dashboardReportAppointmentSearchStatus'

const initialState: DashboardSearchStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingOrders: false,
}

let currentSelectedDate: Date | undefined

const dashboardReportAppointmentSearchSlice = createSlice({
  name: 'dashboardReportAppointmentSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<DashboardSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableAppointments = action.payload.availableAppointments
      state.errorWhileSearchingOrders = action.payload.errorWhileSearchingOrders
    },
    resetReportForAppointment(
      state,
      action: PayloadAction<DashboardSearchStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableAppointments = action.payload.availableAppointments
      state.errorWhileSearchingOrders = action.payload.errorWhileSearchingOrders
    },
  },
})

export const requestAppointmentForReportForClinic =
  (
    type: string,
    code: string,
    nameSearchString: string,
    phoneSearchString: string,
    selectedDate?: Date,
    selectedEndDate?: Date,
    startMonth?: Date,
    endMonth?: Date
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    currentSelectedDate = selectedDate
    let phoneChangedData: string = ''
    if (phoneSearchString.length > 1)
      phoneChangedData = `%2B91${phoneSearchString}`

    const state: DashboardSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(dashboardReportAppointmentSearchSlice.actions.updatedStatus(state))
    try {
      const unitDetails = getCurrentUserUnitReference()
      const start = `${moment(moment(selectedDate).format('YYYY-MM-DD'))
        .startOf('day')
        .utc()
        .format()}`
      const end = `${moment(moment(selectedEndDate).format('YYYY-MM-DD'))
        .endOf('day')
        .utc()
        .format()}`

      const searchParameters: any = {
        _count: 500,

        status: 'booked,fulfilled',
        '_include:iterate': 'Appointment:actor',
        _include: 'Appointment:slot',
        _revinclude: 'Encounter:appointment',
      }
      let startDateFinal: string = ''
      let endDateFinal: string = ''
      if (type === 'dateWise') {
        if (selectedDate) {
          //   searchParameters.occurrence = `ge${moment(selectedDate)
          //     .startOf('day')
          //     .format('YYYY-MM-DDTHH:mm:ssZ')}`
          startDateFinal = `ge${moment(selectedDate)
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ssZ')}`
        }
        if (selectedEndDate) {
          //   searchParameters.occurrence = `le${moment(selectedEndDate)
          //     .endOf('day')
          //     .format('YYYY-MM-DDTHH:mm:ssZ')}`
          endDateFinal = `le${moment(selectedEndDate)
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ssZ')}`
        }
      } else {
        if (startMonth) {
          //   searchParameters.occurrence = `ge${moment(startMonth)
          //     .startOf('month')
          //     .format('YYYY-MM-DDTHH:mm:ssZ')}`
          startDateFinal = `ge${moment(startMonth)
            .startOf('month')
            .format('YYYY-MM-DDTHH:mm:ssZ')}`
        }
        if (endMonth) {
          //   searchParameters.occurrence = `le${moment(endMonth)
          //     .endOf('month')
          //     .format('YYYY-MM-DDTHH:mm:ssZ')}`
          endDateFinal = `le${moment(endMonth)
            .endOf('month')
            .format('YYYY-MM-DDTHH:mm:ssZ')}`
        }
      }

      if (!isOrgAdmin()) {
        if (unitDetails) {
          searchParameters.performer = unitDetails.reference
        }
      }

      let firstName: string = ''
      let middleName: string = ''
      let lastName: string = ''

      if (nameSearchString.includes(' ')) {
        const localNameArr: string[] = nameSearchString.split(' ')
        if (localNameArr.length > 2) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          middleName = localNameArr[2]
          lastName = localNameArr[1]
          firstName = localNameArr[0]
        } else if (localNameArr.length === 2) {
          lastName = localNameArr[1]
          firstName = localNameArr[0]
        } else {
          firstName = nameSearchString
        }
      } else {
        firstName = nameSearchString
      }

      const enRolClient: MasterFhirClient = new MasterFhirClient()

      axios.CancelToken.source()
      if (cancelTokenStore.has('ipdMainSearchControlToken')) {
        const controlTokenForSearch: CancelTokenSource = cancelTokenStore.get(
          'ipdMainSearchControlToken'
        )
        controlTokenForSearch.cancel('new param added')
        cancelTokenStore.delete('ipdMainSearchControlToken')
      }
      cancelTokenStore.set(
        'ipdMainSearchControlToken',
        axios.CancelToken.source()
      )

      const response: any = await enRolClient.doGetResourceReport(
        `/Appointment?_include:iterate=HealthcareService:healthcare-service-billing&_revinclude:iterate=PaymentReconciliation:payment-reconciliation-app&patient:Patient.name:contains=${firstName}&patient:Patient.name:contains=${middleName}&patient:Patient.name:contains=${lastName}&patient:Patient.phone:contains=${phoneChangedData}&date=${startDateFinal}&date=${endDateFinal}&service-type=${code}&_sort=date`,
        searchParameters,
        (cancelTokenStore.get('ipdMainSearchControlToken') as CancelTokenSource)
          .token
      )
      if (response !== undefined) {
        const resp: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)

        const appointmentResponse: R4.IBundle = response
        if (
          appointmentResponse?.entry &&
          appointmentResponse?.entry.length > 0
        ) {
          const dataTableReport: ReportColumnsForAppointment[] =
            getExpandedAppointmentFromBundleForReport(appointmentResponse)

          if (dataTableReport.length > 0) {
            state.resultsAvailable = true
            state.searchingAppointments = false
            state.availableAppointments = dataTableReport

            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingOrders = false
            dispatch(
              dashboardReportAppointmentSearchSlice.actions.updatedStatus(state)
            )
          } else {
            const errorSearchDoctor: DashboardSearchStatus = {
              searchingAppointments: false,
              errorWhileSearchingOrders: false,
              resultsAvailable: false,
              noResultsAvailable: true,
            }
            dispatch(
              dashboardReportAppointmentSearchSlice.actions.updatedStatus(
                errorSearchDoctor
              )
            )
          }
        } else {
          console.error('---------error ---- ------------')
          const errorSearchDoctor: DashboardSearchStatus = {
            searchingAppointments: false,
            errorWhileSearchingOrders: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            dashboardReportAppointmentSearchSlice.actions.updatedStatus(
              errorSearchDoctor
            )
          )
        }
      }
    } catch (error) {
      console.error('---------error------------')
      console.error(error)
      /*  const errorSearchDoctor: IpdAppointmentSearchStatus = {
        searchingAppointments: false,
        errorWhileSearchingOrders: true,
        resultsAvailable: false,
        errorReason: 'Error while fetching appointments',
        selectedStatuses: selectedStatus ?? [],
        selectedDate,
        availableAppointments: existingDataRawData,
        dateWiseAppointments: existingDateWiseData,
        pageState,
        recordsCount,
      }
      dispatch(
        ipdAppointmentSearchSlice.actions.updatedStatus(errorSearchDoctor)
      ) */
    }
  }

export async function getLocationForIPD(
  locationId: string,
  fhirClinicIpdDetails: FhirClinicIpdDetails
): Promise<FhirClinicIpdDetails> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const response: any = await fhirClient.doGetResource(
    `/Location/${locationId}`
  )

  const responseBundle: R4.ILocation = response as R4.ILocation
  fhirClinicIpdDetails.location = responseBundle

  return fhirClinicIpdDetails
}

export async function getPayment(
  ipdId: string,
  fhirClinicIpdDetails: FhirClinicIpdDetails
): Promise<FhirClinicIpdDetails> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const response: any = await fhirClient.doGetResource(
    `/PaymentReconciliation?identifier=${ipdId}`
  )
  const responseBundle: R4.IBundle = response as R4.IBundle
  if (responseBundle.entry) {
    fhirClinicIpdDetails.paymentReconilation = responseBundle.entry[0]
      .resource as R4.IPaymentReconciliation
  }

  return fhirClinicIpdDetails
}

export const resetAppointmentReportSlice = () => (dispatch: AppDispatch) => {
  dispatch(
    dashboardReportAppointmentSearchSlice.actions.resetReportForAppointment(
      initialState
    )
  )
}

export default dashboardReportAppointmentSearchSlice.reducer
