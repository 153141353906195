import { FormControl, makeStyles, useTheme } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { textMain } from 'configs/styles/muiThemes'
import React from 'react'

export interface IProps {
  name: string
  label?: string
  type?: any
  icon?: any
  value?: any
  error?: null | undefined
  onChange?: any
  required?: boolean
  fileType?: any
  [other: string]: any
  style?: any
  multiple: boolean
}

const useStyles = makeStyles((theme) => ({
  label: {
    color: useTheme().palette.text.primary,
    textTransform: 'uppercase',
  },
  distance: {
    marginTop: 10,
  },
  labelProps: {
    display: 'none',
    padding: 2,
    cursor: 'pointer',
    backgroundColor: textMain,
  },
}))

export const FileInput: React.FC<IProps> = ({
  name,
  label,
  type,
  value,
  error,
  onChange,
  fileType,
  icon,
  other,
  style,
  ref,
  multiple,
}: IProps) => {
  const classes = useStyles()
  return (
    <FormControl {...(error && { error: true })}>
      <input
        name={name}
        id='upload-photo'
        value={value}
        type='file'
        required={false}
        multiple={multiple}
        className={classes.labelProps}
        accept={type}
        onChange={onChange}
      />

      <label htmlFor='upload-photo'>
        <Typography
          variant='body1'
          style={{
            textAlign: 'center',
            textDecoration: 'underline',
            color: '#007AFF',
          }}
        >
          {fileType}
        </Typography>
      </label>
    </FormControl>
  )
}
