import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { PatientDataWithAppointment } from 'models/patientDataWithAppointment'
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { CcClinet } from 'services/Cclinet'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getUserDetails } from 'services/userDetailsService'
import { logger } from 'utils/logger'
import { DownloadLinkStatus } from './downloadLinkSearchStatus'

const initialState: DownloadLinkStatus = {
  sending: false,
  sentSuccesFully: false,
  error: false,
  errorMessage: '',
}

const donwloadPatientLinkSlice = createSlice({
  name: 'donwloadPatientLinkSlice',
  initialState,
  reducers: {
    updateStatus(state, action: PayloadAction<DownloadLinkStatus>) {
      state.sending = action.payload.sending
      state.sentSuccesFully = action.payload.sentSuccesFully
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },
  },
})

export const sendDownlinkInvite =
  (patientId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: DownloadLinkStatus = {
      sending: true,
      sentSuccesFully: false,
      error: false,
    }
    dispatch(
      donwloadPatientLinkSlice.actions.updateStatus(addingCreatePersonState)
    )

    try {
      logger.info('patientDetails')
      const currentUserPractRole: R4.IPractitionerRole | undefined =
        getUserDetails()?.practitionerRole

      const formData = {
        patientId: `${patientId}`,
      }

      const enRolClient: CcClinet = new CcClinet()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        '/invitation/consumer/downloadApk',
        formData
      )
      const successCreatePersonState: DownloadLinkStatus = {
        sending: false,
        sentSuccesFully: true,
        error: true,
        errorMessage: '',
      }
      dispatch(
        donwloadPatientLinkSlice.actions.updateStatus(successCreatePersonState)
      )
      dispatch(
        showSuccessAlert('Download patient app link resent successfully')
      )
      dispatch(donwloadPatientLinkSlice.actions.updateStatus(initialState))
      return
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorCreatePersonState: DownloadLinkStatus = {
        sending: false,
        sentSuccesFully: false,
        error: true,
        errorMessage: 'Error while uploading patient data',
      }
      dispatch(showErrorAlert('Error while sending link'))
      dispatch(
        donwloadPatientLinkSlice.actions.updateStatus(errorCreatePersonState)
      )
    }
  }

export const resetUpdateUserState = () => (dispatch: AppDispatch) => {
  dispatch(donwloadPatientLinkSlice.actions.updateStatus(initialState))
}

export default donwloadPatientLinkSlice.reducer
