import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Grid,
  Divider,
  Tooltip,
  Link,
  Collapse,
  Button,
} from '@material-ui/core'
import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
} from '@material-ui/icons'
import AodIcon from '@mui/icons-material/Aod'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedProceDure } from 'models/groupedProcedure'
import { PurposeOfUse } from 'models/purposeOfUse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestProcedureHistoryOfPatient } from 'redux/patientMedicalHistory/proceduresHistory/proceduresHistorySlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getAgeOf, getTimeAgo } from 'utils/dateUtil'
import {
  getNotesFromAllergy,
  getNotesString,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import OverflowTypography from 'views/components/consultation/plan/ayurveda/overflowTypography'

interface ProceduresProp {
  procedures: GroupedProceDure[]
}

export const ProceduresHistory: React.FC<ProceduresProp> = ({
  procedures,
}: ProceduresProp) => {
  const { t } = useTranslation(['common', 'procedure'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const dispatch = useDispatch()
  const procedureHistorySlice = useSelector(
    (state: RootState) => state.procedureHistorySlice
  )

  const [groupVitals, setGroupVitals] = useState<GroupedProceDure[]>([])
  const [fullText, setFullText] = React.useState<string>()
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullTextForNotes, setFullTextForNotes] = React.useState<string>()
  const [showTextNotes, setShowTextNotes] = React.useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (procedures.length > 0) {
      updateProcedureList(procedures)
    }
  }, [])

  function updateProcedureList(procedureList: GroupedProceDure[]) {
    const results: GroupedProceDure[] = []
    for (let i = 0; i < procedureList.length; i++) {
      results.push({
        condition: procedureList[i].condition,
        checked: true,
      })
    }
    setGroupVitals(results)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: GroupedProceDure[] = [...groupVitals]
    values[index].checked = rate
    setGroupVitals(values)
  }
  return (
    <>
      <Box
        flexGrow
        width='100%'
        flexDirection='column'
        display='flex'
        style={{ padding: 0, margin: 0 }}
      >
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Box flexDirection='row' display='flex' flexGrow width='100%'>
            <Box py={1}>
              <Typography variant='subtitle1'>
                {' '}
                {t('labelCommon:surgery')}{' '}
              </Typography>
            </Box>{' '}
          </Box>
        </Box>

        {groupVitals.length === 0 && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Typography
              variant='subtitle2'
              style={{
                fontWeight: 400,
              }}
            >
              {' '}
              No data available
            </Typography>
          </Box>
        )}

        {groupVitals.length > 0 && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Box display='flex' flexDirection='column' width='100%' flexGrow>
              {groupVitals.map((val, index: number) => (
                <Box key={`med_cond${val.condition.id}`} width='100%' py={1}>
                  <Box
                    paddingX={1}
                    borderRadius={2}
                    style={{
                      backgroundColor: 'lightGrey',
                    }}
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    height={40}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow={1}
                      alignItems='center'
                    >
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: 'black',

                          fontWeight: 'bold',
                        }}
                      >
                        Recorded on{' '}
                        {moment(
                          val.condition.meta
                            ? val.condition.meta.lastUpdated
                            : new Date()
                        ).format('Do MMMM YYYY hh:mm A')}
                      </Typography>
                    </Box>
                    <Box
                      justifyContent='flex-end'
                      display='flex'
                      flexGrow={1}
                      alignItems='center'
                    >
                      <Box px={1}>
                        <Tooltip title=''>
                          <IconButton
                            aria-label='collapse_order_type'
                            size='small'
                            onClick={() => {
                              handleCollapseForPanel1(!val.checked, index)
                            }}
                          >
                            {val.checked && <ArrowDropUpOutlined />}
                            {!val.checked && <ArrowDropDownOutlined />}
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>

                  <Collapse
                    in={val.checked}
                    style={{
                      width: '100%',
                    }}
                  >
                    <Box
                      style={{
                        boxShadow: '0px 0px 4px #ccc',
                        margin: '3px 0px',
                        borderRadius: '4px',
                        borderColor: '#F8F8F8',
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#F8F8F8',
                      }}
                      key={`med_cond${val.condition.id}`}
                    >
                      <Grid direction='column' container>
                        <Grid
                          container
                          direction='row'
                          id='vitals'
                          style={{
                            backgroundColor: kDialogueBackground,
                          }}
                        >
                          <Grid item xs={3}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  minHeight={30}
                                  maxHeight={100}
                                  alignItems='center'
                                  px={1}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Surgery
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                paddingTop={0.2}
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    minHeight: '30px',
                                    maxHeight: '100px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={2}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  minHeight={30}
                                  maxHeight={100}
                                  alignItems='center'
                                  px={1}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    {val.condition.performedDateTime
                                      ? 'Performed On'
                                      : 'Performed'}
                                  </Typography>
                                </Box>

                                {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                paddingTop={0.2}
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    minHeight: '30px',
                                    maxHeight: '100px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={7}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  minHeight={30}
                                  maxHeight={100}
                                  alignItems='center'
                                  px={1}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Additional Notes
                                  </Typography>
                                </Box>

                                {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          direction='row'
                          id='vitals'
                          style={{ backgroundColor: '#EDF2FA' }}
                        >
                          <Grid item xs={3}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  minHeight={30}
                                  maxHeight={100}
                                  alignItems='center'
                                  px={1}
                                >
                                  {val.condition.meta &&
                                    val.condition.meta.security &&
                                    val.condition.meta.security[0].code ===
                                      'PATRPT' && (
                                      <Box paddingRight={1}>
                                        <Tooltip title=''>
                                          <IconButton
                                            aria-label='collapse_order_type'
                                            size='small'
                                          >
                                            <AodIcon color='primary' />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    )}
                                  {val.condition.code &&
                                    val.condition.code!.coding &&
                                    val.condition.code!.coding.length > 0 &&
                                    val.condition.code!.coding![0].display && (
                                      <OverflowTypography
                                        text={
                                          val.condition.code!.coding![0]
                                            .display!
                                        }
                                        typographyProps={{
                                          variant: 'subtitle2',
                                          color: 'initial',
                                        }}
                                      />
                                    )}
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                paddingTop={0.2}
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    minHeight: '30px',
                                    maxHeight: '100px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={2}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  minHeight={30}
                                  maxHeight={100}
                                  alignItems='center'
                                  px={1}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,
                                      //   color: 'black',

                                      //   fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    {val.condition.performedDateTime ||
                                    val.condition.performedPeriod
                                      ? `${moment(
                                          val.condition.performedDateTime ??
                                            new Date()
                                        ).format('DD-MM-YYYY')}`
                                      : `${getAgeOf(
                                          val.condition.performedAge
                                        )} ago`}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                paddingTop={0.2}
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    minHeight: '30px',
                                    maxHeight: '100px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={7}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                paddingLeft={1}
                                paddingTop={1}
                              >
                                {hasNotes(val.condition.note) && (
                                  <OverflowTypography
                                    text={getNotesString(val.condition.note)}
                                    typographyProps={{
                                      variant: 'subtitle2',
                                      color: 'initial',
                                    }}
                                  />
                                )}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Collapse>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>

      {showText && (
        <NoteDialog
          id='notesTile'
          open={showText}
          onClose={() => {
            setFullText('')
            setShowText(false)
          }}
          notesData={fullText!}
          dialogText='Surgery'
        />
      )}
      <NoteDialog
        id='notesTile1'
        open={showTextNotes}
        onClose={() => {
          setFullTextForNotes('')
          setShowTextNotes(false)
        }}
        notesData={fullTextForNotes!}
        dialogText='Additional Notes'
      />
    </>
  )
}
