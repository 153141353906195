import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { getDocumentBundle } from 'utils/labHelpers/fhirDocReferenceBuncle'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { PartnerOrderStatusTypes } from 'utils/constants/order_status'
import { DocumentUploadStatus } from './documentUploadStatusTypes'
import { requestUpdateStatusOfOrder } from '../order/orderManagementSlice/orderManagementSlice'

const initialState: DocumentUploadStatus = {
  uploading: false,
  uploadingSuccessful: false,
  error: false,
  errorMessage: '',
}

const uploadSuccess: string[] = []

const uploadReportSlice = createSlice({
  name: 'uploadDocument',
  initialState,
  reducers: {
    uploadingDocument(state, action: PayloadAction<DocumentUploadStatus>) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
    },

    documentUploaded(state, action: PayloadAction<DocumentUploadStatus>) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
      state.document = action.payload.document
      state.references = action.payload.references
    },

    errorInUploadingDocument(
      state,
      action: PayloadAction<DocumentUploadStatus>
    ) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetUpload(state, action: PayloadAction<DocumentUploadStatus>) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.document = action.payload.document
    },
  },
})

export const uploadingDocument =
  (files: any, labOrderDetails?: FhirLabOrderDetail): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingDocumentUploadedState: DocumentUploadStatus = {
      uploading: true,
      uploadingSuccessful: false,
      error: false,
    }
    dispatch(
      uploadReportSlice.actions.uploadingDocument(addingDocumentUploadedState)
    )

    const type: string = 'application/pdf'
    const referencesDoc: string[] = []

    try {
      const documentObj: R4.IDocumentReference = {
        resourceType: 'DocumentReference',
        content: [
          {
            attachment: {
              contentType: `${type}`,
            },
          },
        ],
      }
      const docBundle = getDocumentBundle(type, files, labOrderDetails)

      logger.info('documentObj')

      logger.info(documentObj)

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doCreateFHIRTransaction(
        '/',
        docBundle
      )
      const respDecoded: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (respDecoded._tag === 'Right') {
        logger.info('Response Document decoded', respDecoded.right)
        const documentResponse: R4.IBundle = respDecoded.right
        if (documentResponse.entry) {
          const resultSet: string[] = []
          for (let i = 0; i < documentResponse.entry.length; i++) {
            const docRefVal: string =
              documentResponse.entry[i].response?.location ?? ''
            const docObj = docRefVal.replace('/_history/1', '')
            referencesDoc.push(docObj)
            uploadContent(files[i], docObj, type)
          }
          if (labOrderDetails) {
            dispatch(
              requestUpdateStatusOfOrder(
                labOrderDetails,
                PartnerOrderStatusTypes[2],
                'report_uploaded',
                true
              )
            )
          }
        }
        const successUploadingDocument: DocumentUploadStatus = {
          uploading: false,
          uploadingSuccessful: true,
          error: false,
          errorMessage: '',
          references: referencesDoc,
        }
        dispatch(
          uploadReportSlice.actions.documentUploaded(successUploadingDocument)
        )
      } else {
        const errorUploadingDocument: DocumentUploadStatus = {
          uploading: false,
          uploadingSuccessful: false,
          error: true,
          errorMessage: 'Error while uploading',
        }
        dispatch(
          uploadReportSlice.actions.errorInUploadingDocument(
            errorUploadingDocument
          )
        )
      }

      return
    } catch (error) {
      const errorDocuploadedState: DocumentUploadStatus = {
        uploading: false,
        uploadingSuccessful: false,
        error: true,
        errorMessage: 'Error',
      }
      dispatch(
        uploadReportSlice.actions.errorInUploadingDocument(
          errorDocuploadedState
        )
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(uploadReportSlice.actions.resetUpload(initialState))
}

async function uploadContent(file: any, documentRef: string, type: string) {
  logger.info('Document Reference')
  logger.info(documentRef)
  let result: boolean = false
  const reader = new FileReader()
  const rABS = !!reader.readAsArrayBuffer
  reader.onload = async (e) => {
    /* Parse data */
    const bstr = (e.target as FileReader).result
    const data = new Uint8Array(<ArrayBuffer>bstr)
    const convertedData = Buffer.from(data).toString('base64')
    if (data !== null) {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any | FHIRErrorResponses =
        await fhirClient.doCreateFHIRUploadResourceRequest(
          `/${documentRef}/$binary-access-write?path=DocumentReference.content.attachment`,
          convertedData,
          type
        )
      logger.info('Upload with reference')
      logger.info(response.data)
      const respDecoded: E.Either<Errors, R4.IDocumentReference> =
        R4.RTTI_DocumentReference.decode(response.data)
      if (respDecoded._tag === 'Right') {
        result = true
        uploadSuccess.push('success')
      }
    }
  }
  if (rABS) {
    reader.readAsArrayBuffer(file)
  }
}

export default uploadReportSlice.reducer
