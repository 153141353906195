import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  TaskIntentKind,
  TaskStatusKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { leftTask } from 'fp-ts/lib/TaskThese'
import _ from 'lodash'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserPractitionerRoleRef } from 'services/userDetailsService'

export async function addNewPathyaAndApathya(prescriptionsBundle: R4.IBundle) {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateFHIRTransaction('', prescriptionsBundle)

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  return true
}

export async function addNewPathyaApathyBundle(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  imagingCode: R4.ICoding,
  pathyaText?: string,
  apathyaText?: string
) {
  let result = false
  try {
    const svs = getTaskObjectForPathyaApathya(
      patientReference,
      encounterId,
      imagingCode,
      pathyaText,
      apathyaText
    )

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          resource: svs,
          fullUrl: 'Task/',
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: 'Task',
          },
        },
      ],
    }

    result = await addNewPathyaAndApathya(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function updatePathyaApathyBundle(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  imagingCode: R4.ICoding,
  pathyaText?: string,
  apathyaText?: string,
  taskData?: R4.ITask
) {
  let result = false
  try {
    const svs = getTaskObjectForPathyaApathya(
      patientReference,
      encounterId,
      imagingCode,
      pathyaText,
      apathyaText
    )

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          resource: svs,
          fullUrl: `Task/${taskData!.id!}`,
          request: {
            method: R4.Bundle_RequestMethodKind._put,
            url: `${svs.resourceType}/${taskData!.id!}`,
          },
        },
      ],
    }

    result = await addNewPathyaAndApathya(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

function getTaskObjectForPathyaApathya(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  pathyaApathyaType: R4.ICoding,
  pathyaText?: string,
  apathyaText?: string
): R4.ITask {
  const communicationRequest: R4.ITask = {
    resourceType: 'Task',
    for: patientReference,
    status: TaskStatusKind._requested,
    priority: 'routine',
    intent: TaskIntentKind._proposal,
    input: [],
    code: {
      coding: [pathyaApathyaType],
    },
    encounter: encounterId,
    authoredOn: new Date().toISOString(),

    requester: getCurrentUserPractitionerRoleRef(),
  }
  if (!_.isEmpty(pathyaText)) {
    const pathyaTextObj: R4.ITask_Input = {
      type: {
        coding: [
          {
            system: 'http://www.who.int/ita',
            code: 'ITA-8.1.3',
            display: 'Pathya',
          },
        ],
      },
      valueString: pathyaText,
    }
    communicationRequest.input!.push(pathyaTextObj)
  }

  if (!_.isEmpty(apathyaText)) {
    const pathyaTextObj: R4.ITask_Input = {
      type: {
        coding: [
          {
            system: 'http://www.who.int/ita',
            code: 'ITA-8.1.4',
            display: 'Apathya',
          },
        ],
      },
      valueString: apathyaText,
    }
    communicationRequest.input!.push(pathyaTextObj)
  }

  return communicationRequest
}

export interface PathyaApathyaDetails {
  pathyaText?: string
  apathyaText?: string
  recordedDate?: string
  task?: R4.ITask
}

export async function getPathyaApathyForTypeAndEncounter(body: {
  encounterId: string
  type: string
  appointmentId: string
}): Promise<PathyaApathyaDetails[] | boolean> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doGetResourceForAppointment(`/Task`, body.appointmentId, {
      encounter: `Encounter/${body.encounterId}`,
      code: body.type,
      _count: 500,
    })

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  const resp: R4.IBundle = response
  if (resp.entry && resp.entry.length === 0) {
    return false
  }
  const taskObject = resp.entry?.map((entry) => entry.resource as R4.ITask)

  let returnResult: PathyaApathyaDetails[] = []
  if (taskObject && taskObject.length > 0) {
    returnResult = taskObject.map((task) => {
      const res: PathyaApathyaDetails = {
        recordedDate: task.authoredOn ?? '',
        task,
      }
      task.input?.forEach((input) => {
        if (input.type?.coding && input.type?.coding.length > 0) {
          if (input.type?.coding[0].code === 'ITA-8.1.3') {
            res.pathyaText = input.valueString ?? ''
          } else if (input.type?.coding[0].code === 'ITA-8.1.4') {
            res.apathyaText = input.valueString ?? ''
          }
        }
      })
      return res
    })
  }

  console.log('--------------------returnResult----------------', returnResult)
  return returnResult
}
