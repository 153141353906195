import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirLabDiagnosticRequest } from 'models/fhirLabDiagnosticRequest'
import { FhirReferralRequest } from 'models/fhirReferralRequest'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getLabDiagnosticRequestFromBundle } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { getReferralRequestsFromBundle } from 'utils/fhirResoureHelpers/referralHelpers'
import { logger } from 'utils/logger'
import { ReferralsSliceStatus } from './referralListSliceStatus'

const initialState: ReferralsSliceStatus = {
  fetchingImpressions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  updatedImpressions: false,
  updatingImpressions: false,
  errorWhileSearchingImpressions: false,
  errorWhileUpdatingImpressions: false,
}

const referralsListSlice = createSlice({
  name: 'referralsListSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<ReferralsSliceStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingImpressions = action.payload.fetchingImpressions
      state.resultsAvailable = action.payload.resultsAvailable
      state.referralRequests = action.payload.referralRequests
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingImpressions =
        action.payload.errorWhileUpdatingImpressions
      state.updatedImpressions = action.payload.updatedImpressions
      state.updatingImpressions = action.payload.updatingImpressions
      state.errorWhileSearchingImpressions =
        action.payload.errorWhileSearchingImpressions
    },
  },
})

export const getReferralRequestsOfAppointment =
  (fhirAppointmentDetails: FhirAppointmentDetail): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: ReferralsSliceStatus = { ...initialState }
    state.fetchingImpressions = true
    dispatch(referralsListSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        'encounter.appointment': fhirAppointmentDetails.appointment.id,
        code: 'doctor-referral',
        _revinclude: 'Task:focus',
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/ServiceRequest',
        fhirAppointmentDetails.appointment.id!,
        searchParameters
      )
      logger.info(
        '-----------------------------SE-----REQ----------------------------------------------',
        response
      )
      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingImpressions = true
        state.fetchingImpressions = false

        dispatch(referralsListSlice.actions.updatedStatus(state))
      } else {
        const conditionResponse: R4.IBundle = resp.right
        logger.info('Medication resp', conditionResponse.entry)
        if (conditionResponse?.total && conditionResponse?.total > 0) {
          const clinicalImpressions: FhirReferralRequest[] =
            getReferralRequestsFromBundle(conditionResponse)

          if (clinicalImpressions && clinicalImpressions.length > 0) {
            state.resultsAvailable = true
            state.fetchingImpressions = false
            state.referralRequests = clinicalImpressions

            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingImpressions = false
            dispatch(referralsListSlice.actions.updatedStatus(state))
          }
        } else {
          const errorSearchDoctor: ReferralsSliceStatus = {
            ...initialState,
          }
          errorSearchDoctor.noResultsAvailable = true
          dispatch(referralsListSlice.actions.updatedStatus(errorSearchDoctor))
        }
      } /* */
    } catch (error) {
      const errorSearchDoctor: ReferralsSliceStatus = {
        ...initialState,
      }
      errorSearchDoctor.errorReason = 'Error while fetching assessment details'
      errorSearchDoctor.errorWhileSearchingImpressions = false
      dispatch(referralsListSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }
export default referralsListSlice.reducer
