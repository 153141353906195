/* eslint-disable guard-for-in */
/* eslint-disable no-lone-blocks */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { DashboardData } from 'models/adminGraph/dashboardData'
import { DateWiseGraphData } from 'models/adminGraph/dateWiseGraphData'
import { GraphType } from 'models/adminGraph/graphType'
import { PieGraph } from 'models/adminGraph/pieGraph'
import { ServiceDataForGraph } from 'models/adminGraph/serviceDataForGraph'
import { TableData } from 'models/adminGraph/tableData'
import moment from 'moment'
import {
  getIdentifierValueBySystem,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getValueRefValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import { getTotalAmountFoRIpd } from './ipdHelper'

interface DateWiseAppointments {
  date: string
  orders: R4.IServiceRequest[]
}

interface DateWisePayments {
  date: string
  orders: R4.IPaymentReconciliation[]
}

interface MonthWisePayments {
  fullDate: Date
  date: string
  orders: R4.IPaymentReconciliation[]
}

interface IPD {
  name: string
  amount: number
  percentage: number
}

export function getDataForDateWiseGraph(
  responseBundle: R4.IBundle,
  date: Date
): DateWiseGraphData[] {
  const paymentRef: any = {}
  const invoice: any = {}

  const dateWiseOrders: DateWiseAppointments[] = []
  const dateWisePlans: DateWiseGraphData[] = []
  try {
    if (responseBundle.entry) {
      const allServiceRequest: R4.IServiceRequest[] = []
      const paymentRecon: R4.IPaymentReconciliation[] = []
      if (responseBundle.entry && responseBundle.entry.length > 0) {
        responseBundle.entry.forEach((e) => {
          if (e.resource?.resourceType === 'ServiceRequest') {
            allServiceRequest.push(e.resource)
          }
          if (e.resource) {
            if (e.resource.id) {
              if (e.resource?.resourceType === 'PaymentReconciliation') {
                paymentRecon.push(e.resource)
                paymentRef[e.resource.id] =
                  e.resource as R4.IPaymentReconciliation
              }
              if (e.resource?.resourceType === 'Invoice') {
                invoice[e.resource.id] = e.resource as R4.IInvoice
              }
            }
          }
        })
      }
      allServiceRequest.forEach((item) => {
        const dateDet = moment(
          item.occurrencePeriod
            ? item.occurrencePeriod.start ?? moment()
            : moment()
        ).format('YYYY-MM-DD')
        if (dateDet >= moment(date).format('YYYY-MM-DD')) {
          const index = dateWiseOrders.findIndex((s) => s.date === dateDet)
          if (index < 0) {
            dateWiseOrders.push({
              date: dateDet,
              orders: [item],
            })
          } else {
            dateWiseOrders[index].orders.push(item)
          }
        }
      })
    }

    dateWiseOrders.forEach((e) => {
      const index = dateWisePlans.findIndex((eDate) => eDate.date === e.date)
      let amount: number = 0
      let active: number = 0
      let completed: number = 0
      if (index < 0) {
        amount = 0
        active = 0
        completed = 0
        for (const key1 in paymentRef) {
          if (key1) {
            const paymentInfo: R4.IPaymentReconciliation = paymentRef[
              key1
            ] as R4.IPaymentReconciliation
            if (paymentInfo.detail && paymentInfo.detail[0].date) {
              if (e.date === paymentInfo.detail[0].date) {
                if (paymentInfo.paymentAmount) {
                  const collectionAmount: number = paymentInfo.paymentAmount
                    ? paymentInfo.paymentAmount.value
                      ? paymentInfo.paymentAmount.value
                      : 0
                    : 0
                  amount += collectionAmount
                  completed += 1
                }
              }
            }
          }
        }
        // for (let i = 0; i < e.orders.length; i++) {
        //   if (e.orders[i].status === 'completed') {
        //     completed += 1
        //   }
        // }
        if (amount > 0) {
          dateWisePlans.push({
            fullDate: moment(e.date).toDate(),
            date: moment(e.date).format('Do MMM'),
            TotalDischarge: completed,
            NoOfAppointments: e.orders.length,
            collection: amount,
          })
        }
      } else {
      }
    })
  } catch (error) {
    console.error(error)
  }

  return dateWisePlans
}

export function getDataForTypeWise(
  responseBundle: R4.IBundle,
  date: Date
): GraphType[] {
  const paymentRef: any = {}
  const invoice: any = {}
  let ipdAmount: number = 0
  let dayCareAmount: number = 0
  let consultAmount: number = 0

  const dateWiseOrders: DateWiseAppointments[] = []
  const dateWisePlans: DateWiseGraphData[] = []
  const ipdGraph: GraphType[] = []
  try {
    if (responseBundle.entry) {
      const allServiceRequest: R4.IServiceRequest[] = []
      const paymentRecon: R4.IPaymentReconciliation[] = []
      if (responseBundle.entry && responseBundle.entry.length > 0) {
        responseBundle.entry.forEach((e) => {
          if (e.resource?.resourceType === 'ServiceRequest') {
            allServiceRequest.push(e.resource)
          }
          if (e.resource) {
            if (e.resource.id) {
              if (e.resource?.resourceType === 'PaymentReconciliation') {
                paymentRecon.push(e.resource)
                paymentRef[e.resource.id] =
                  e.resource as R4.IPaymentReconciliation
              }
              if (e.resource?.resourceType === 'Invoice') {
                invoice[e.resource.id] = e.resource as R4.IInvoice
              }
            }
          }
        })
      }
      allServiceRequest.forEach((item) => {
        if (item.status === 'completed') {
          if (item.occurrencePeriod && item.occurrencePeriod.start) {
            if (
              moment(item.occurrencePeriod.start).format('YYYY-MM-DD') >=
              moment(date).format('YYYY-MM-DD')
            ) {
              let ipdId = getIdentifierValueBySystem(
                item.identifier ?? [],
                'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
              )
              if (ipdId?.length === 0) {
                ipdId = getIdentifierValueBySystem(
                  item.identifier ?? [],
                  'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                )
              }
              for (const key1 in paymentRef) {
                if (key1) {
                  const paymentInfo: R4.IPaymentReconciliation = paymentRef[
                    key1
                  ] as R4.IPaymentReconciliation
                  if (
                    paymentInfo.outcome &&
                    paymentInfo.outcome === 'complete'
                  ) {
                    if (paymentInfo.detail && paymentInfo.detail[0].date) {
                      let pid = getIdentifierValueBySystem(
                        paymentInfo.identifier ?? [],
                        'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                      )
                      if (pid?.length === 0) {
                        pid = getIdentifierValueBySystem(
                          paymentInfo.identifier ?? [],
                          'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                        )
                      }
                      if (pid === ipdId) {
                        if (item.code && item.code.coding) {
                          if (item.code.coding[0].code === '281685003') {
                            if (paymentInfo.paymentAmount) {
                              const collectionAmount: number =
                                paymentInfo.paymentAmount
                                  ? paymentInfo.paymentAmount.value
                                    ? paymentInfo.paymentAmount.value
                                    : 0
                                  : 0
                              ipdAmount += collectionAmount
                            }
                          }
                          if (item.code.coding[0].code === '304903009') {
                            if (paymentInfo.paymentAmount) {
                              const collectionAmount: number =
                                paymentInfo.paymentAmount
                                  ? paymentInfo.paymentAmount.value
                                    ? paymentInfo.paymentAmount.value
                                    : 0
                                  : 0
                              dayCareAmount += collectionAmount
                            }
                          }

                          if (item.code.coding[0].code === '33022008') {
                            if (paymentInfo.paymentAmount) {
                              const collectionAmount: number =
                                paymentInfo.paymentAmount
                                  ? paymentInfo.paymentAmount.value
                                    ? paymentInfo.paymentAmount.value
                                    : 0
                                  : 0
                              consultAmount += collectionAmount
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      })

      const totalValue: number = ipdAmount + dayCareAmount + consultAmount
      if (ipdAmount > 0) {
        const ipdData: IPD = {
          name: 'IPD',
          amount: ipdAmount,
          percentage: Math.round((ipdAmount / totalValue) * 100),
        }
        ipdGraph.push(ipdData)
      }
      if (dayCareAmount > 0) {
        const dayCareData: IPD = {
          name: 'Day Care',
          amount: dayCareAmount,
          percentage: Math.round((dayCareAmount / totalValue) * 100),
        }
        ipdGraph.push(dayCareData)
      }
      if (consultAmount > 0) {
        const consultData: IPD = {
          name: 'Consultation',
          amount: consultAmount,
          percentage: Math.round((consultAmount / totalValue) * 100),
        }
        ipdGraph.push(consultData)
      }
    }
  } catch (error) {
    console.error(error)
  }

  return ipdGraph
}

export function getDataForPaymentTypeWise(
  responseBundle: R4.IBundle,
  date: Date
): GraphType[] {
  const paymentRef: any = {}
  const invoice: any = {}
  let ipdAmount: number = 0
  let dayCareAmount: number = 0
  let gpayAmount: number = 0
  let phonePe: number = 0
  let paytm: number = 0
  let amazonPay: number = 0
  let rupay: number = 0

  const ipdGraph: GraphType[] = []
  try {
    if (responseBundle.entry) {
      const allServiceRequest: R4.IServiceRequest[] = []
      const paymentRecon: R4.IPaymentReconciliation[] = []
      if (responseBundle.entry && responseBundle.entry.length > 0) {
        responseBundle.entry.forEach((e) => {
          if (e.resource?.resourceType === 'ServiceRequest') {
            allServiceRequest.push(e.resource)
          }
          if (e.resource) {
            if (e.resource.id) {
              if (e.resource?.resourceType === 'PaymentReconciliation') {
                paymentRecon.push(e.resource)
                paymentRef[e.resource.id] =
                  e.resource as R4.IPaymentReconciliation
              }
              if (e.resource?.resourceType === 'Invoice') {
                invoice[e.resource.id] = e.resource as R4.IInvoice
              }
            }
          }
        })
      }

      for (const key1 in paymentRef) {
        if (key1) {
          const paymentInfo: R4.IPaymentReconciliation = paymentRef[
            key1
          ] as R4.IPaymentReconciliation
          if (paymentInfo.outcome && paymentInfo.outcome === 'complete') {
            if (
              paymentInfo.detail &&
              paymentInfo.detail[0].type &&
              paymentInfo.detail[0].type.coding
            ) {
              if (paymentInfo.detail[0].date) {
                if (paymentInfo.detail[0].type.coding[0].code === 'cash') {
                  if (paymentInfo.paymentAmount) {
                    const collectionAmount: number = paymentInfo.paymentAmount
                      ? paymentInfo.paymentAmount.value
                        ? paymentInfo.paymentAmount.value
                        : 0
                      : 0
                    ipdAmount += collectionAmount
                  }
                } else if (
                  paymentInfo.detail[0].type.coding[0].code === 'google-pay'
                ) {
                  if (paymentInfo.paymentAmount) {
                    const collectionAmount: number = paymentInfo.paymentAmount
                      ? paymentInfo.paymentAmount.value
                        ? paymentInfo.paymentAmount.value
                        : 0
                      : 0
                    gpayAmount += collectionAmount
                  }
                } else if (
                  paymentInfo.detail[0].type.coding[0].code === 'phonepe'
                ) {
                  if (paymentInfo.paymentAmount) {
                    const collectionAmount: number = paymentInfo.paymentAmount
                      ? paymentInfo.paymentAmount.value
                        ? paymentInfo.paymentAmount.value
                        : 0
                      : 0
                    phonePe += collectionAmount
                  }
                } else if (
                  paymentInfo.detail[0].type.coding[0].code === 'paytm'
                ) {
                  if (paymentInfo.paymentAmount) {
                    const collectionAmount: number = paymentInfo.paymentAmount
                      ? paymentInfo.paymentAmount.value
                        ? paymentInfo.paymentAmount.value
                        : 0
                      : 0
                    paytm += collectionAmount
                  }
                } else if (
                  paymentInfo.detail[0].type.coding[0].code === 'amazon-pay'
                ) {
                  if (paymentInfo.paymentAmount) {
                    const collectionAmount: number = paymentInfo.paymentAmount
                      ? paymentInfo.paymentAmount.value
                        ? paymentInfo.paymentAmount.value
                        : 0
                      : 0
                    amazonPay += collectionAmount
                  }
                } else if (
                  paymentInfo.detail[0].type.coding[0].code === 'rupay'
                ) {
                  if (paymentInfo.paymentAmount) {
                    const collectionAmount: number = paymentInfo.paymentAmount
                      ? paymentInfo.paymentAmount.value
                        ? paymentInfo.paymentAmount.value
                        : 0
                      : 0
                    rupay += collectionAmount
                  }
                } else if (paymentInfo.paymentAmount) {
                  const collectionAmount: number = paymentInfo.paymentAmount
                    ? paymentInfo.paymentAmount.value
                      ? paymentInfo.paymentAmount.value
                      : 0
                    : 0
                  dayCareAmount += collectionAmount
                }
              }
            }
          }
        }
      }

      const totalValue: number =
        ipdAmount +
        dayCareAmount +
        gpayAmount +
        phonePe +
        amazonPay +
        paytm +
        rupay

      if (ipdAmount > 0) {
        const ipdData: IPD = {
          name: 'Cash',
          amount: ipdAmount,
          percentage: Math.round((ipdAmount / totalValue) * 100),
        }
        ipdGraph.push(ipdData)
      }

      if (gpayAmount > 0) {
        const gpayData: IPD = {
          name: 'Google Pay',
          amount: gpayAmount,
          percentage: Math.round((gpayAmount / totalValue) * 100),
        }
        ipdGraph.push(gpayData)
      }
      if (phonePe > 0) {
        const phonePeData: IPD = {
          name: 'PhonePe',
          amount: phonePe,
          percentage: Math.round((phonePe / totalValue) * 100),
        }
        ipdGraph.push(phonePeData)
      }
      if (paytm > 0) {
        const paytmData: IPD = {
          name: 'Paytm',
          amount: paytm,
          percentage: Math.round((paytm / totalValue) * 100),
        }
        ipdGraph.push(paytmData)
      }
      if (amazonPay > 0) {
        const amazonPayData: IPD = {
          name: 'Amazon Pay',
          amount: amazonPay,
          percentage: Math.round((amazonPay / totalValue) * 100),
        }
        ipdGraph.push(amazonPayData)
      }
      if (rupay > 0) {
        const rupayData: IPD = {
          name: 'Rupay',
          amount: rupay,
          percentage: Math.round((rupay / totalValue) * 100),
        }
        ipdGraph.push(rupayData)
      }
      if (dayCareAmount > 0) {
        const dayCareData: IPD = {
          name: 'Online',
          amount: dayCareAmount,
          percentage: Math.round((dayCareAmount / totalValue) * 100),
        }
        ipdGraph.push(dayCareData)
      }
    }
  } catch (error) {
    console.error(error)
  }
  return ipdGraph
}

export function gettableDataForDashboard(
  responseBundle: R4.IBundle,
  date: Date
): DashboardData[] {
  const paymentRef: any = {}
  const invoice: any = {}
  const patient: any = {}

  const ipdGraph: DashboardData[] = []
  try {
    if (responseBundle.entry) {
      const allServiceRequest: R4.IServiceRequest[] = []
      const paymentRecon: R4.IPaymentReconciliation[] = []
      if (responseBundle.entry && responseBundle.entry.length > 0) {
        responseBundle.entry.forEach((e) => {
          if (e.resource?.resourceType === 'ServiceRequest') {
            allServiceRequest.push(e.resource)
          }
          if (e.resource) {
            if (e.resource.id) {
              if (e.resource?.resourceType === 'PaymentReconciliation') {
                paymentRecon.push(e.resource)
                paymentRef[e.resource.id] =
                  e.resource as R4.IPaymentReconciliation
              }
              if (e.resource?.resourceType === 'Invoice') {
                invoice[e.resource.id] = e.resource as R4.IInvoice
              }
              if (e.resource?.resourceType === 'Patient') {
                patient[e.resource.id] = e.resource as R4.IPatient
              }
            }
          }
        })
      }

      for (const key1 in paymentRef) {
        if (key1) {
          const paymentInfo: R4.IPaymentReconciliation = paymentRef[
            key1
          ] as R4.IPaymentReconciliation
          if (paymentInfo.detail && paymentInfo.detail[0].date) {
            for (const key in invoice) {
              if (key) {
                const invoiceInfo: R4.IInvoice = invoice[key] as R4.IInvoice
                const paymentRefData = getValueRefValueExtensionsOfUrl(
                  invoiceInfo.extension,
                  'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-payment-reconciliation-ext'
                )
                if (paymentRefData) {
                  if (paymentInfo.id === paymentRefData.split('/')[1]) {
                    if (invoiceInfo.subject) {
                      const patientId: string | undefined =
                        invoiceInfo.subject.reference?.split('/')[1]
                      ipdGraph.push({
                        invoiceData: invoiceInfo,
                        patientData: patient[patientId ?? ''],
                        paymentInfo,
                      })
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  } catch (error) {
    console.error(error)
  }
  return ipdGraph
}

export function getDataForTable(responseBundle: DashboardData[]): TableData[] {
  const tabData: TableData[] = []

  for (let i = 0; i < responseBundle.length; i++) {
    const invoiceId = getIdentifierValueBySystem(
      responseBundle[i].invoiceData.identifier ?? [],
      'http://wellopathy.com/fhir/india/core/sid/invoiceId'
    )

    let ipdId = getIdentifierValueBySystem(
      responseBundle[i].paymentInfo.identifier ?? [],
      'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
    )
    if (ipdId?.length === 0) {
      ipdId = getIdentifierValueBySystem(
        responseBundle[i].paymentInfo.identifier ?? [],
        'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
      )
    }

    let dateData: string = ''
    let date: Date = new Date()
    let dateString: string = ''
    if (responseBundle[i].paymentInfo.detail) {
      const paymentData: R4.IPaymentReconciliation_Detail[] =
        responseBundle[i].paymentInfo.detail ?? []

      if (paymentData.length > 0) {
        dateData = moment(paymentData[0].date).format('DD-MM-YYYY')
        date = moment(moment(paymentData[0].date).format('YYYY-MM-DD')).toDate()
        dateString = paymentData[0].date ?? moment().toString()
      }
    }

    let name: string = ''

    if (getMiddleNameOfPatient(responseBundle[i].patientData).length > 0)
      name = `${getNameOfPatient(responseBundle[i].patientData) ?? ''} ${
        getMiddleNameOfPatient(responseBundle[i].patientData) ?? ''
      } ${getLastNameOfPatient(responseBundle[i].patientData) ?? ''}`
    else
      name = `${getNameOfPatient(responseBundle[i].patientData) ?? ''} ${
        getLastNameOfPatient(responseBundle[i].patientData) ?? ''
      }`

    tabData.push({
      invoiceId: responseBundle[i].invoiceData.id ?? '',
      invoiceNo: invoiceId ?? '',
      ipdOpdNo: ipdId ?? '',
      patientName: name.replace('/n', ''),
      billingDate: dateData,
      billAmount: responseBundle[i].invoiceData.totalNet
        ? responseBundle[i].invoiceData.totalNet?.value?.toFixed(2) ?? '0'
        : '0',
      billingDateStr: dateString,
      billDateDateType: date,
    })
  }
  tabData.sort((a, b) => moment(a.billingDateStr).diff(b.billingDateStr))
  const bmiChartSorted = tabData.sort((a, b) => {
    const da = new Date(a.billingDateStr)
    const db = new Date(b.billingDateStr)
    return Number(da) - Number(db)
  })
  return bmiChartSorted
}

export function getDataForMonthWiseGraph(
  responseBundle: R4.IBundle,
  date: Date
): DateWiseGraphData[] {
  const paymentRef: any = {}
  const invoice: any = {}
  const paymentRecon: R4.IPaymentReconciliation[] = []

  const dateWiseOrders: DateWiseAppointments[] = []
  const dateWisePlans: DateWiseGraphData[] = []
  try {
    if (responseBundle.entry) {
      const allServiceRequest: R4.IServiceRequest[] = []

      if (responseBundle.entry && responseBundle.entry.length > 0) {
        responseBundle.entry.forEach((e) => {
          if (e.resource?.resourceType === 'ServiceRequest') {
            allServiceRequest.push(e.resource)
          }
          if (e.resource) {
            if (e.resource.id) {
              if (e.resource?.resourceType === 'PaymentReconciliation') {
                paymentRecon.push(e.resource)
                paymentRef[e.resource.id] =
                  e.resource as R4.IPaymentReconciliation
              }
              if (e.resource?.resourceType === 'Invoice') {
                invoice[e.resource.id] = e.resource as R4.IInvoice
              }
            }
          }
        })
      }
      allServiceRequest.forEach((item) => {
        if (item.occurrencePeriod && item.occurrencePeriod.start) {
          const dateDet = moment(item.occurrencePeriod.start).format('MMM')
          if (
            moment(item.occurrencePeriod.start).format('YYYY-MM-DD') >=
            moment(date).format('YYYY-MM-DD')
          ) {
            const index = dateWiseOrders.findIndex((s) => s.date === dateDet)
            if (index < 0) {
              dateWiseOrders.push({
                date: dateDet,
                orders: [item],
              })
            } else {
              dateWiseOrders[index].orders.push(item)
            }
          }
        }
      })
    }

    dateWiseOrders.forEach((e) => {
      const index = dateWisePlans.findIndex((eDate) => eDate.date === e.date)
      let amount: number = 0
      let active: number = 0
      let completed: number = 0
      if (index < 0) {
        amount = 0
        active = 0
        completed = 0

        for (const key1 in paymentRef) {
          if (key1) {
            const paymentInfo: R4.IPaymentReconciliation = paymentRef[
              key1
            ] as R4.IPaymentReconciliation

            if (paymentInfo.detail && paymentInfo.detail[0].date) {
              if (e.date === moment(paymentInfo.detail[0].date).format('MMM')) {
                if (paymentInfo.paymentAmount) {
                  const collectionAmount: number = paymentInfo.paymentAmount
                    ? paymentInfo.paymentAmount.value
                      ? paymentInfo.paymentAmount.value
                      : 0
                    : 0
                  amount += collectionAmount
                  completed += 1
                }
              }
            }
          }
        }

        dateWisePlans.push({
          fullDate: moment(e.date).toDate(),
          date: e.date,
          TotalDischarge: completed,
          NoOfAppointments: e.orders.length,
          collection: amount,
        })
      } else {
      }
    })
  } catch (error) {
    console.error(error)
  }

  return dateWisePlans
}

export function getDataForPayment(
  responseBundle: R4.IBundle,
  date: Date
): DateWiseGraphData[] {
  const paymentRef: any = {}
  const invoice: any = {}

  const dateWisePayment: DateWisePayments[] = []
  const dateWisePlans: DateWiseGraphData[] = []
  const paymentRecon: R4.IPaymentReconciliation[] = []
  try {
    if (responseBundle.entry) {
      const allServiceRequest: R4.IServiceRequest[] = []

      if (responseBundle.entry && responseBundle.entry.length > 0) {
        responseBundle.entry.forEach((e) => {
          if (e.resource?.resourceType === 'ServiceRequest') {
            allServiceRequest.push(e.resource)
          }
          if (e.resource) {
            if (e.resource.id) {
              if (e.resource?.resourceType === 'PaymentReconciliation') {
                paymentRecon.push(e.resource)
                paymentRef[e.resource.id] =
                  e.resource as R4.IPaymentReconciliation
              }
              if (e.resource?.resourceType === 'Invoice') {
                invoice[e.resource.id] = e.resource as R4.IInvoice
              }
            }
          }
        })
      }
      paymentRecon.forEach((item) => {
        // let amount: number = 0
        // let active: number = 0
        // let completed: number = 0
        if (item.detail) {
          let dateData: string = ''

          const paymentData: R4.IPaymentReconciliation_Detail[] =
            item.detail ?? []
          if (paymentData.length > 0) {
            dateData = moment(paymentData[0].date).format('YYYY-MM-DD')

            const index = dateWisePayment.findIndex((s) => s.date === dateData)
            if (index < 0) {
              dateWisePayment.push({
                date: dateData,
                orders: [item],
              })
            } else {
              dateWisePayment[index].orders.push(item)
            }
          }
        }
      })
    }

    dateWisePayment.forEach((e) => {
      const index = dateWisePlans.findIndex((eDate) => eDate.date === e.date)
      let amount: number = 0
      let active: number = 0
      let completed: number = 0
      if (index < 0) {
        amount = 0
        active = 0
        completed = 0
        for (let i = 0; i < e.orders.length; i++) {
          const paymentInfo: R4.IPaymentReconciliation = e.orders[
            i
          ] as R4.IPaymentReconciliation
          if (paymentInfo.detail && paymentInfo.detail[0].date) {
            if (e.date === paymentInfo.detail[0].date) {
              for (const key in invoice) {
                if (key) {
                  const invoiceInfo: R4.IInvoice = invoice[key] as R4.IInvoice
                  const paymentRefData = getValueRefValueExtensionsOfUrl(
                    invoiceInfo.extension,
                    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-payment-reconciliation-ext'
                  )
                  if (paymentRefData) {
                    if (paymentInfo.id === paymentRefData.split('/')[1]) {
                      const collectionAmount: number = invoiceInfo.totalNet
                        ? invoiceInfo.totalNet.value
                          ? invoiceInfo.totalNet.value
                          : 0
                        : 0
                      amount += collectionAmount
                      completed += 1
                    }
                  }
                }
              }
            }
          }
        }
        if (amount > 0) {
          dateWisePlans.push({
            fullDate: moment(e.date).toDate(),
            date: moment(e.date).format('Do MMM'),
            TotalDischarge: completed,
            NoOfAppointments: e.orders.length,
            collection: amount,
          })
        }
      } else {
      }
    })
  } catch (error) {
    console.error(error)
  }

  return dateWisePlans
}

export function getDataForPaymentMonthWise(
  responseBundle: R4.IBundle,
  date: Date
): DateWiseGraphData[] {
  const paymentRef: any = {}
  const invoice: any = {}

  const dateWisePayment: MonthWisePayments[] = []
  const dateWisePlans: DateWiseGraphData[] = []
  const paymentRecon: R4.IPaymentReconciliation[] = []
  try {
    if (responseBundle.entry) {
      const allServiceRequest: R4.IServiceRequest[] = []

      if (responseBundle.entry && responseBundle.entry.length > 0) {
        responseBundle.entry.forEach((e) => {
          if (e.resource?.resourceType === 'ServiceRequest') {
            allServiceRequest.push(e.resource)
          }
          if (e.resource) {
            if (e.resource.id) {
              if (e.resource?.resourceType === 'PaymentReconciliation') {
                paymentRecon.push(e.resource)
                paymentRef[e.resource.id] =
                  e.resource as R4.IPaymentReconciliation
              }
              if (e.resource?.resourceType === 'Invoice') {
                invoice[e.resource.id] = e.resource as R4.IInvoice
              }
            }
          }
        })
      }
      paymentRecon.forEach((item) => {
        // let amount: number = 0
        // let active: number = 0
        // let completed: number = 0
        if (item.detail) {
          let dateData: string = ''
          let monthData: string = ''
          const paymentData: R4.IPaymentReconciliation_Detail[] =
            item.detail ?? []
          if (paymentData.length > 0) {
            dateData = moment(paymentData[0].date).format('YYYY-MM-DD')
            if (
              dateData >= moment(date).startOf('month').format('YYYY-MM-DD')
            ) {
              monthData = moment(paymentData[0].date).format('MMM')
              const index = dateWisePayment.findIndex(
                (s) => s.date === monthData
              )
              if (index < 0) {
                dateWisePayment.push({
                  fullDate: moment(paymentData[0].date).toDate(),
                  date: monthData,
                  orders: [item],
                })
              } else {
                dateWisePayment[index].orders.push(item)
              }
            }
          }
        }
      })
    }
    for (let i = 0; i < dateWisePayment.length; i++) {
      let totalAmount: number = 0
      for (let j = 0; j < dateWisePayment[i].orders.length; j++) {
        const paymentInfo: R4.IPaymentReconciliation = dateWisePayment[i]
          .orders[j] as R4.IPaymentReconciliation

        for (const key in invoice) {
          if (key) {
            const invoiceInfo: R4.IInvoice = invoice[key] as R4.IInvoice
            const paymentRefData = getValueRefValueExtensionsOfUrl(
              invoiceInfo.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-payment-reconciliation-ext'
            )
            if (paymentRefData) {
              if (paymentInfo.id === paymentRefData.split('/')[1]) {
                const collectionAmount: number = invoiceInfo.totalNet
                  ? invoiceInfo.totalNet.value
                    ? invoiceInfo.totalNet.value
                    : 0
                  : 0
                totalAmount += collectionAmount
              }
            }
          }
        }
      }

      if (totalAmount > 0) {
        dateWisePlans.push({
          fullDate: dateWisePayment[i].fullDate,
          date: dateWisePayment[i].date,
          TotalDischarge: dateWisePayment[i].orders.length,
          NoOfAppointments: dateWisePayment[i].orders.length,
          collection: totalAmount,
        })
      }
    }
  } catch (error) {
    console.error(error)
  }

  return dateWisePlans
}

export function getDataForServiceRequest(
  responseBundle: R4.IBundle,
  date: Date
): ServiceDataForGraph {
  const paymentRef: any = {}
  const invoice: any = {}

  const dataRequest: ServiceDataForGraph = {
    ipdData: [],
    daycareData: [],
    opd: [],
  }
  const ipdData: string[] = []
  const dayCare: string[] = []
  const opd: string[] = []
  try {
    if (responseBundle.entry) {
      const allServiceRequest: R4.IServiceRequest[] = []
      const paymentRecon: R4.IPaymentReconciliation[] = []
      if (responseBundle.entry && responseBundle.entry.length > 0) {
        responseBundle.entry.forEach((e) => {
          if (e.resource?.resourceType === 'ServiceRequest') {
            allServiceRequest.push(e.resource)
          }
          if (e.resource) {
            if (e.resource.id) {
              if (e.resource?.resourceType === 'PaymentReconciliation') {
                paymentRecon.push(e.resource)
                paymentRef[e.resource.id] =
                  e.resource as R4.IPaymentReconciliation
              }
              if (e.resource?.resourceType === 'Invoice') {
                invoice[e.resource.id] = e.resource as R4.IInvoice
              }
            }
          }
        })
      }
      allServiceRequest.forEach((item) => {
        if (item.status === 'completed') {
          if (item.occurrencePeriod && item.occurrencePeriod.start) {
            if (item.code && item.code.coding) {
              if (item.code.coding[0].code === '281685003') {
                const ipdId = getIdentifierValueBySystem(
                  item.identifier ?? [],
                  'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                )
                ipdData.push(ipdId ?? '')
              }
            }

            if (item.code && item.code.coding) {
              if (item.code.coding[0].code === '304903009') {
                const dareId = getIdentifierValueBySystem(
                  item.identifier ?? [],
                  'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                )

                dayCare.push(dareId ?? '')
              }
            }

            if (item.code && item.code.coding) {
              if (item.code.coding[0].code === '33022008') {
                const opdId = getIdentifierValueBySystem(
                  item.identifier ?? [],
                  'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                )

                opd.push(opdId ?? '')
              }
            }
          }
        }
      })
    }
  } catch (error) {
    console.error(error)
  }
  dataRequest.ipdData = ipdData
  dataRequest.daycareData = dayCare
  dataRequest.opd = opd
  return dataRequest
}

export function getAmountTotal(responseBundle: R4.IBundle): number {
  const paymentRef: any = {}
  let totalAmount: number = 0
  try {
    if (responseBundle.entry) {
      const allServiceRequest: R4.IServiceRequest[] = []
      const paymentRecon: R4.IPaymentReconciliation[] = []
      if (responseBundle.entry && responseBundle.entry.length > 0) {
        responseBundle.entry.forEach((e) => {
          if (e.resource) {
            if (e.resource.id) {
              if (e.resource?.resourceType === 'PaymentReconciliation') {
                paymentRecon.push(e.resource)
                paymentRef[e.resource.id] =
                  e.resource as R4.IPaymentReconciliation
              }
            }
          }
        })
      }
      for (const key1 in paymentRef) {
        if (key1) {
          const paymentInfo: R4.IPaymentReconciliation = paymentRef[
            key1
          ] as R4.IPaymentReconciliation

          if (paymentInfo.detail && paymentInfo.detail[0].date) {
            if (paymentInfo.paymentAmount) {
              const collectionAmount: number = paymentInfo.paymentAmount
                ? paymentInfo.paymentAmount.value
                  ? paymentInfo.paymentAmount.value
                  : 0
                : 0
              totalAmount += collectionAmount
            }
          }
        }
      }
    }
  } catch (error) {
    console.error(error)
  }

  return totalAmount
}

export function getAmountTotalForAdmin(responseBundle: R4.IBundle): number {
  const paymentRef: any = {}
  let totalAmount: number = 0
  try {
    if (responseBundle.entry) {
      const allServiceRequest: R4.IServiceRequest[] = []
      const paymentRecon: R4.IPaymentReconciliation[] = []
      if (responseBundle.entry && responseBundle.entry.length > 0) {
        responseBundle.entry.forEach((e) => {
          if (e.resource) {
            if (e.resource.id) {
              if (e.resource?.resourceType === 'PaymentReconciliation') {
                paymentRecon.push(e.resource)
                paymentRef[e.resource.id] =
                  e.resource as R4.IPaymentReconciliation
              }
            }
          }
        })
      }
      for (const key1 in paymentRef) {
        if (key1) {
          const paymentInfo: R4.IPaymentReconciliation = paymentRef[
            key1
          ] as R4.IPaymentReconciliation

          if (paymentInfo.paymentAmount) {
            const collectionAmount: number = paymentInfo.paymentAmount
              ? paymentInfo.paymentAmount.value
                ? paymentInfo.paymentAmount.value
                : 0
              : 0
            totalAmount += collectionAmount
          }
        }
      }
    }
  } catch (error) {
    console.error(error)
  }

  return parseFloat(totalAmount.toFixed(2))
}

export function getAmountTotalUnitWise(responseBundle: R4.IBundle): number {
  const paymentRef: any = {}
  const invoice: any = {}
  let totalAmount: number = 0
  try {
    if (responseBundle.entry) {
      const allServiceRequest: R4.IServiceRequest[] = []
      const paymentRecon: R4.IPaymentReconciliation[] = []
      if (responseBundle.entry && responseBundle.entry.length > 0) {
        responseBundle.entry.forEach((e) => {
          if (e.resource) {
            if (e.resource.id) {
              if (e.resource?.resourceType === 'PaymentReconciliation') {
                paymentRecon.push(e.resource)
                paymentRef[e.resource.id] =
                  e.resource as R4.IPaymentReconciliation
              }
              if (e.resource?.resourceType === 'Invoice') {
                invoice[e.resource.id] = e.resource as R4.IInvoice
              }
            }
          }
        })
      }
      for (const key1 in paymentRef) {
        if (key1) {
          const paymentInfo: R4.IPaymentReconciliation = paymentRef[
            key1
          ] as R4.IPaymentReconciliation
          for (const key in invoice) {
            if (key) {
              const invoiceInfo: R4.IInvoice = invoice[key] as R4.IInvoice
              const paymentRefData = getValueRefValueExtensionsOfUrl(
                invoiceInfo.extension,
                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-payment-reconciliation-ext'
              )
              if (paymentRefData) {
                if (paymentInfo.id === paymentRefData.split('/')[1]) {
                  const collectionAmount: number = invoiceInfo.totalNet
                    ? invoiceInfo.totalNet.value
                      ? invoiceInfo.totalNet.value
                      : 0
                    : 0
                  totalAmount += collectionAmount
                }
              }
            }
          }
        }
      }
    }
  } catch (error) {
    console.error(error)
  }

  return parseFloat(totalAmount.toFixed(2))
}
