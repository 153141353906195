import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, makeStyles, Typography, Button } from '@material-ui/core'
import { of } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { CarePlanSubscriptionPlan } from 'models/subscriptions/carePlanSubscription/carePlanSubscriptionPlan'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { requestCPGRecommendationForSubscribedPatientData } from 'redux/subscription/cpgRecommendations/cpgRecommendationsSlice'
import { requestForSubscriptionInterestCapture } from 'redux/subscription/patientSubscriptionForUnitSlice/patientSubscriptionForUnitSlice'
import {
  getOfferingSubscriptionsOfCurrentUnit,
  getPlanIdFromTasks,
} from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import VisitAppointmentContextProvider from 'views/providers/visitContextProvider'
import { CdsRecommendationsForAppointment } from '../cds_recommendations_list'
import {
  SubscriptionOfferingsPopUp,
  SubscriptionOfferingsPopUpProps,
} from '../subscriptions/subscriptionOfferingPopUp'

interface RecommendationWrapperProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  carePlanIdentifier?: string
}

const useStyles = makeStyles((theme) => ({
  titleText: {
    color: `#333333`,
    fontFamily: 'Open Sans',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '25px',

    textAlign: 'left',
    textTtransform: 'capitalize',
  },
  wrapper: {
    /*  background: '#FDFFCD',
    border: '0.5px solid #A7B50F', */
    borderRadius: '4px',
  },
  textSubtitle: {
    fontFamily: 'Open Sans',
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '13.62px',
  },
}))

export const RecommendationWrapper: React.FC<RecommendationWrapperProps> = ({
  fhirAppointmentDetails,
  carePlanIdentifier,
}: RecommendationWrapperProps) => {
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [selectedPlan, setSelectedPlan] =
    useState<CarePlanSubscriptionPlan | undefined>()

  const patientSubscriptionForUnitSlice = useSelector(
    (state: RootState) => state.patientSubscriptionForUnitSlice
  )

  const [offeringSubscriptionsList, setOfferingSubscriptionsList] =
    useState<CarePlanSubscriptionPlan[]>()

  const [offeringPopUp, setOfferingPopUp] =
    useState<SubscriptionOfferingsPopUpProps>({
      open: false,
    })

  function fetchCPGLists() {
    setUpdateStatus({ requesting: true })
    getOfferingSubscriptionsOfCurrentUnit().then((e) => {
      if (e !== false) {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setOfferingSubscriptionsList(e)
        console.log('---------changes-----------------', e)
        setSelectedPlan(e[0])
      } else {
        setUpdateStatus({
          requesting: false,
          requestError: false,
        })
      }
    })
  }

  useEffect(() => {
    console.log(
      'patientSubscriptionForUnitSlice',
      patientSubscriptionForUnitSlice
    )
    if (
      patientSubscriptionForUnitSlice.subscriptionStatus === 'interested' &&
      fhirAppointmentDetails.patient &&
      patientSubscriptionForUnitSlice.tasks
    ) {
      console.log('---------requesting details-----------------')
      if (offeringSubscriptionsList && offeringSubscriptionsList?.length > 0) {
        const newOfferList = [...(offeringSubscriptionsList ?? [])]
        newOfferList.filter(
          (e) =>
            e.cpgId !==
            patientSubscriptionForUnitSlice.tasks![0].planDefinition!.id
        )
        setOfferingSubscriptionsList(newOfferList)
      }

      dispatch(
        requestCPGRecommendationForSubscribedPatientData({
          selectedPatient: fhirAppointmentDetails.patient,
          planDefinition: [
            patientSubscriptionForUnitSlice.tasks![0].planDefinition!,
          ],
        })
      )
    }
  }, [patientSubscriptionForUnitSlice])

  function requestCpgRecommendations(plan: R4.IPlanDefinition) {
    if (selectedPlan) {
      dispatch(
        requestCPGRecommendationForSubscribedPatientData({
          selectedPatient: fhirAppointmentDetails.patient,
          planDefinition: [selectedPlan.rawCpg],
        })
      )
    }
  }

  const dispatch = useDispatch()

  const classes = useStyles()

  useEffect(() => {
    if (
      patientSubscriptionForUnitSlice.subscriptionStatus === 'not-offered' ||
      patientSubscriptionForUnitSlice.subscriptionStatus === 'interested'
    )
      fetchCPGLists()
  }, [])
  if (
    patientSubscriptionForUnitSlice.subscriptionStatus === 'not-offered' ||
    patientSubscriptionForUnitSlice.subscriptionStatus === 'interested'
  ) {
    return (
      <VisitAppointmentContextProvider
        patient={fhirAppointmentDetails.patient}
        encounter={fhirAppointmentDetails.encounter}
        visitReference={fhirAppointmentDetails}
      >
        <Box
          display='flex'
          flexDirection='row'
          className={classes.wrapper}
          key={fhirAppointmentDetails.appointment.id}
        >
          {updateStatus.requesting && (
            <Box
              flexDirection='row'
              padding={0.5}
              flexGrow
              width='100%'
              justifyContent='center'
              alignItems='center'
              alignContent='center'
            >
              <Typography className={classes.titleText}>Loading...</Typography>
            </Box>
          )}

          {updateStatus.requestSuccess &&
            offeringSubscriptionsList &&
            offeringSubscriptionsList.length > 0 && (
              <Box width='100%' height='100%'>
                <Box
                  flexDirection='row'
                  padding={0.5}
                  flexGrow
                  width='100%'
                  justifyContent='center'
                  alignItems='center'
                  alignContent='center'
                >
                  {patientSubscriptionForUnitSlice.subscriptionStatus ===
                    'interested' && (
                    <Typography className={classes.titleText}>
                      Patient has shown interest in the subscription. But
                      subscription is not yet confirmed.
                    </Typography>
                  )}
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      setOfferingPopUp({
                        open: true,
                        onClose: () => {
                          setOfferingPopUp({ open: false })
                        },
                        onSelectedPlanChanged: (plan) => {
                          setSelectedPlan(plan)
                        },

                        onPatientShowedInterest: (plan, duration) => {
                          console.log('---------plan-----------------', plan)
                          console.log(
                            '---------duration-----------------',
                            duration
                          )
                          dispatch(
                            requestForSubscriptionInterestCapture(
                              fhirAppointmentDetails.patient.id!,
                              fhirAppointmentDetails.encounter!.id!,
                              `${plan.rawCpg.resourceType}/${plan.rawCpg.id}`,
                              `${fhirAppointmentDetails.appointment.resourceType}/${fhirAppointmentDetails.appointment.id}`,
                              `${duration.resourceType}/${duration.id}`,
                              patientSubscriptionForUnitSlice
                            )
                          )
                        },
                        onDismissed: () => {},
                        offersList:
                          patientSubscriptionForUnitSlice.tasks !== undefined
                            ? offeringSubscriptionsList.filter((e) =>
                                getPlanIdFromTasks(
                                  patientSubscriptionForUnitSlice.tasks ?? []
                                ).includes(e.cpgId)
                              )
                            : offeringSubscriptionsList,
                        displaySubscribeButton:
                          patientSubscriptionForUnitSlice.subscriptionStatus !==
                          'interested',
                      })
                    }}
                  >
                    Show subscription offer
                  </Button>
                </Box>
              </Box>
            )}

          {/*  <Box display='flex' flexDirection='column' paddingY={1} paddingX={2}>
          <Box display='flex' flexDirection='row'>
            <Typography className={classes.titleText}>
              Care Plan Recommendations
            </Typography>
          </Box>
          <CdsRecommendationsForAppointment
            fhirPatientDetail={fhirAppointmentDetails}
            patient={fhirAppointmentDetails.patient}
          />
        </Box> */}

          {offeringPopUp.open && (
            <SubscriptionOfferingsPopUp {...offeringPopUp} />
          )}
        </Box>
      </VisitAppointmentContextProvider>
    )
  }

  /*  if (
    !patientSubscriptionForUnitSlice.requesting &&
    patientSubscriptionForUnitSlice.subscriptionStatus === 'interested'
  ) {
    return (
      <Box display='flex' flexDirection='row'>
        <Typography variant='body1' color='initial'>
          Patient has shown interest in the subscription. But subscription is
          not yet confirmed.
        </Typography>
      </Box>
    )
  } */

  if (patientSubscriptionForUnitSlice.requesting) {
    return (
      <Box display='flex' flexDirection='row'>
        <Typography variant='body1' color='initial'>
          Checking subscription status...
        </Typography>
      </Box>
    )
  }

  return <Box />
}
