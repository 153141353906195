import { R4 } from '@ahryman40k/ts-fhir-types'
import { Typography } from '@material-ui/core'
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab'
import { kPrimaryMain } from 'configs/styles/muiThemes'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  getShortTimeWithDate,
  getShortTimeWithDateForMedicine,
} from 'utils/dateUtil'
import { getOrderStatusAlongWithAgent } from 'utils/fhirResoureHelpers/labOrderHelpers'

interface Props {
  orderEvent: string
  salesOrder: any
  isStartEvent?: boolean
  isEndEvent?: boolean
}

export const MedOrderTimeLineEvent: React.FC<Props> = ({
  orderEvent,
  salesOrder,
  isStartEvent = false,
  isEndEvent = false,
}: Props) => {
  const { t } = useTranslation()

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography color='initial' variant='body2'>
          {orderEvent}
        </Typography>
      </TimelineOppositeContent>
      {orderEvent.includes('Delivered') && <TimelineDot color='primary' />}
      {orderEvent.includes('Aborted') && (
        <TimelineDot
          style={{
            backgroundColor: 'red',
          }}
        />
      )}
      {!orderEvent.includes('Delivered') && !orderEvent.includes('Aborted') && (
        <TimelineSeparator>
          <TimelineDot
            color={orderEvent !== 'Cancelled' ? 'primary' : 'secondary'}
          />

          {orderEvent !== 'Cancelled' && (
            <TimelineConnector
              style={{
                backgroundColor: isEndEvent ? undefined : kPrimaryMain,
              }}
            />
          )}
        </TimelineSeparator>
      )}
      <TimelineContent>
        <Typography
          variant='body2'
          color={
            orderEvent !== 'Cancelled' && !orderEvent.includes('Aborted')
              ? 'initial'
              : 'error'
          }
        >
          {getShortTimeWithDateForMedicine(salesOrder.created_time ?? '')}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  )
}
