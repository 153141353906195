import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import { getCommunicationRequestFromBundle } from 'utils/fhirResoureHelpers/communicationRequestHelper'
import { logger } from 'utils/logger'
import { InstructionsListStatus } from './instructionsListStatus'

const initialState: InstructionsListStatus = {
  fetchingImpressions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  updatedImpressions: false,
  updatingImpressions: false,
  errorWhileSearchingImpressions: false,
  errorWhileUpdatingImpressions: false,
}

const instructionsListSlice = createSlice({
  name: 'instructionsListSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<InstructionsListStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingImpressions = action.payload.fetchingImpressions
      state.resultsAvailable = action.payload.resultsAvailable
      state.lanDiagnosticRequests = action.payload.lanDiagnosticRequests
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingImpressions =
        action.payload.errorWhileUpdatingImpressions
      state.updatedImpressions = action.payload.updatedImpressions
      state.updatingImpressions = action.payload.updatingImpressions
      state.errorWhileSearchingImpressions =
        action.payload.errorWhileSearchingImpressions
    },
  },
})

export const getInstructionsOfAppointment =
  (
    fhirAppointmentDetails: FhirAppointmentDetail,
    followUp?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: InstructionsListStatus = { ...initialState }
    state.fetchingImpressions = true
    dispatch(instructionsListSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      let searchParameters: any = {
        'encounter.appointment': fhirAppointmentDetails.appointment.id,
        '_include:iterate': 'ServiceRequest:instantiates-canonical',
        status: 'active',
      }

      if (
        followUp &&
        getAppointmentIdForFollowUp(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        ).length > 0
      ) {
        searchParameters = {
          'encounter.appointment': getAppointmentIdForFollowUp(
            fhirAppointmentDetails.appointment.supportingInformation ?? []
          ),
          '_include:iterate': 'ServiceRequest:instantiates-canonical',
          status: 'active',
        }
      }

      let response: any = await fhirClient.doGetResourceForAppointment(
        '/CommunicationRequest',
        fhirAppointmentDetails.appointment.id!,
        searchParameters
      )

      if (
        followUp &&
        getAppointmentIdForFollowUp(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        ).length > 0
      ) {
        response = await fhirClient.doGetResourceForAppointment(
          '/CommunicationRequest',
          getAppointmentIdForFollowUp(
            fhirAppointmentDetails.appointment.supportingInformation ?? []
          ),
          searchParameters
        )
      }
      logger.info(
        '-----------------------------CE-----REQ----------------------------------------------',
        response
      )
      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingImpressions = true
        state.fetchingImpressions = false

        dispatch(instructionsListSlice.actions.updatedStatus(state))
      } else {
        const conditionResponse: R4.IBundle = resp.right
        logger.info('CommunicationRequest resp', conditionResponse.entry)
        if (conditionResponse?.total && conditionResponse?.total > 0) {
          const clinicalImpressions: R4.ICommunicationRequest[] =
            getCommunicationRequestFromBundle(conditionResponse)

          if (clinicalImpressions && clinicalImpressions.length > 0) {
            state.resultsAvailable = true
            state.fetchingImpressions = false
            state.lanDiagnosticRequests = clinicalImpressions

            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingImpressions = false
            dispatch(instructionsListSlice.actions.updatedStatus(state))
          }
        } else {
          const errorSearchDoctor: InstructionsListStatus = {
            ...initialState,
          }
          errorSearchDoctor.noResultsAvailable = true
          dispatch(
            instructionsListSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      const errorSearchDoctor: InstructionsListStatus = {
        ...initialState,
      }
      errorSearchDoctor.errorReason = 'Error while fetching assessment details'
      errorSearchDoctor.errorWhileSearchingImpressions = false
      dispatch(instructionsListSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }
export default instructionsListSlice.reducer
