/* eslint-disable react/no-array-index-key */
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListSubheader,
  Radio,
  RadioGroup,
  Typography,
  Tooltip,
  Collapse,
  Link,
} from '@material-ui/core'
import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestAddictionsHistoryOfPatient } from 'redux/patientMedicalHistory/addictionsHistory/addictionsHistorySlice'
import { requestHabitsHistoryOfPatient } from 'redux/patientMedicalHistory//habitsHistory/habitsHistorySlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import {
  HistoryObjectiveIndicative,
  SOAPIndicativeElement,
} from 'wello-web-components'
import { getTimeAgo } from 'utils/dateUtil'
import {
  getNotesFromAllergy,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { requestOccupationHistoryOfPatient } from 'redux/fhirMedicalResources/ipd/occupation/occupationSearchSlice'
import moment from 'moment'
import { HistoryObjectiveIndicativeComp } from 'views/components/LeftMenu/HistoryObjectiveIndicative'
import {
  getCodeOfSystemCodings,
  getColorBasedOnNumber,
} from 'utils/fhirResourcesHelper'
import { GroupedOccupation } from 'models/groupedOccupations'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { updateObs } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { StatementOccupationTableTile } from 'views/components/consultation/history/OPD/statementOccupationTabular'
import { OccupationTabularFormat } from './occupationTabularFormat'

interface HabitsProp {
  occupationList: GroupedOccupation[]
}

export const OccupationalHistory: React.FC<HabitsProp> = ({
  occupationList,
}: HabitsProp) => {
  const { t } = useTranslation(['common'])
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)

  const dispatch = useDispatch()
  const occupationSearchSlice = useSelector(
    (state: RootState) => state.occupationSearchSlice
  )
  const [groupVitals, setGroupVitals] = useState<GroupedOccupation[]>([])
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (occupationList.length > 0) {
      updateVitals(occupationList)
    }
  }, [])

  function updateVitals(vitalList: GroupedOccupation[]) {
    const results: GroupedOccupation[] = []
    for (let i = 0; i < vitalList.length; i++) {
      results.push({
        date: vitalList[i].date,
        occupation: vitalList[i].occupation,
        checked: true,
      })
    }
    setGroupVitals(results)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: GroupedOccupation[] = [...groupVitals]
    values[index].checked = rate
    setGroupVitals(values)
  }
  return (
    <Box
      flexGrow
      width='100%'
      flexDirection='column'
      display='flex'
      style={{ padding: 0, margin: 0 }}
    >
      <Box flexGrow width='100%' display='flex' flexDirection='row'>
        <Box flexDirection='row' display='flex' flexGrow width='100%'>
          <Box py={1}>
            <Typography variant='subtitle1'> Occupational History</Typography>
          </Box>{' '}
          {/* {isMedicalServiceProvider() && status !== 'completed' && !splitView && (
              <IconButton
                style={{ padding: '4px' }}
                onClick={() => {
                  setOpenAddDialogue(true)
                }}
              >
                <Add style={{ height: '14px' }} color='primary' />
              </IconButton>
            )} */}
        </Box>
      </Box>

      {occupationList.length === 0 && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            No data available
          </Typography>
        </Box>
      )}

      {occupationList.length > 0 && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Box display='flex' flexDirection='column' width='100%' flexGrow>
            {groupVitals.map((val, index: number) => (
              <Box py={1} key={val.date ?? ''}>
                <Box
                  paddingX={1}
                  borderRadius={2}
                  style={{
                    backgroundColor: 'lightGrey',
                  }}
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height={40}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow={1}
                    alignItems='center'
                  >
                    <Typography
                      variant='subtitle1'
                      style={{
                        color: 'black',

                        fontWeight: 'bold',
                      }}
                    >
                      Recorded on{' '}
                      {moment(val.date).format('Do MMMM YYYY hh:mm A')}
                    </Typography>
                  </Box>
                  <Box
                    justifyContent='flex-end'
                    display='flex'
                    flexGrow={1}
                    alignItems='center'
                  >
                    <Box px={1}>
                      <Tooltip title=''>
                        <IconButton
                          aria-label='collapse_order_type'
                          size='small'
                          onClick={() => {
                            handleCollapseForPanel1(!val.checked, index)
                          }}
                        >
                          {val.checked && <ArrowDropUpOutlined />}
                          {!val.checked && <ArrowDropDownOutlined />}
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
                <Collapse
                  in={val.checked}
                  style={{
                    width: '100%',
                  }}
                >
                  <OccupationTabularFormat occupations={val.occupation} />
                </Collapse>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}
