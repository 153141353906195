import React from 'react'
import { Resizable } from 're-resizable'

import PIP from './pip'

interface ICardProperties {
  children: any
  handleClose?: () => void
  onResizeStop?: (height: number, width: number) => void
}

export const ResizablePIP: React.FC<ICardProperties> = ({
  children,
  handleClose,
  onResizeStop,
}: ICardProperties) => <PIP handleClose={handleClose}>{children}</PIP>
