import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { BpCollection } from 'models/bpData'
import { DayCareRatesData } from 'models/dayCareRatesData'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { getSortedObservation } from 'utils/appointment_handle/vitals_util'
import { getDietPlansPlanFromBundle } from 'utils/fhirResoureHelpers/ipdDietHelper'
import { getObserVationFfromEncounter } from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { getTreatmentPlanFromBundle } from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { logger } from 'utils/logger'
import { DayCareRateSearchStatus } from './dayCareRateSearchStatus'

const initialState: DayCareRateSearchStatus = {
  searchingCharges: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingCharges: false,
}

const dayCareRateTableSearchSlice = createSlice({
  name: 'dayCareRateTableSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<DayCareRateSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingCharges = action.payload.searchingCharges
      state.resultsAvailable = action.payload.resultsAvailable
      state.dayCareCharges = action.payload.dayCareCharges
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingCharges =
        action.payload.errorWhileSearchingCharges
    },
  },
})

export const getAlldayCareCharges =
  (data: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: DayCareRateSearchStatus = {
      searchingCharges: true,
      errorWhileSearchingCharges: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(dayCareRateTableSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doGetResource(
        `/ChargeItemDefinition?url=http://wellopathy.com/ChargeItemDefinition/dayCare`
      )

      const resp: R4.IBundle = response as R4.IBundle

      const responses = resp.entry?.map(
        (e) => e.resource as any as R4.IChargeItemDefinition
      )

      const dayCareChargesArray: DayCareRatesData[] =
        getAlldayCareChargesArray(responses)

      state.resultsAvailable = true
      state.searchingCharges = false
      state.dayCareCharges = dayCareChargesArray
      state.noResultsAvailable = false
      state.errorReason = undefined
      state.errorWhileSearchingCharges = false
      dispatch(dayCareRateTableSearchSlice.actions.updatedStatus(state))
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorSearchRates: DayCareRateSearchStatus = {
        searchingCharges: false,
        errorWhileSearchingCharges: true,
        resultsAvailable: false,
        errorReason: 'Error while searching Daycare rates',
      }
      dispatch(
        dayCareRateTableSearchSlice.actions.updatedStatus(errorSearchRates)
      )
    }
  }

export function getAlldayCareChargesArray(responses: any): DayCareRatesData[] {
  const allChargeDetails: DayCareRatesData[] = []
  const oneChargeDetail: DayCareRatesData = {}
  let nameString: string = ''

  responses.map((e: any) => {
    nameString = e.code?.coding ? e.code?.coding[0].code ?? '' : ''

    if (nameString === '304903009') oneChargeDetail.name = 'Daycare'

    oneChargeDetail.price = e.propertyGroup[0].priceComponent[0].amount.value
    oneChargeDetail.description = e.description ?? ''
    oneChargeDetail.dayCareDetailsWithPrice = e

    allChargeDetails.push({
      name: oneChargeDetail.name,
      price: oneChargeDetail.price,
      description: oneChargeDetail.description ?? '',
      dayCareDetailsWithPrice: oneChargeDetail.dayCareDetailsWithPrice,
    })
  })

  return allChargeDetails
}

export function getDaycareRoomRateBundleUpdate(
  ipdRoomData: DayCareRatesData
): R4.IBundle | undefined {
  if (ipdRoomData.dayCareDetailsWithPrice) {
    const oldUpdatedRoomCharge: R4.IChargeItemDefinition = {
      ...ipdRoomData.dayCareDetailsWithPrice,
    }
    const oldUpdatedRoomDataMatchString: string = `W/${JSON.stringify(
      oldUpdatedRoomCharge.meta?.versionId ?? ' '
    )}`

    oldUpdatedRoomCharge.description = ipdRoomData.description

    const iMoney: R4.IMoney = {}
    iMoney.value = ipdRoomData.price
    iMoney.currency = 'INR'
    const iComponent: R4.IChargeItemDefinition_PriceComponent = {}
    iComponent.amount = iMoney
    iComponent.type = 'base'
    const iPrice: R4.IChargeItemDefinition_PropertyGroup = {}
    iPrice.priceComponent = [iComponent]
    oldUpdatedRoomCharge.propertyGroup = [iPrice]

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedRoomCharge.resourceType}/${oldUpdatedRoomCharge.id}`,
          request: {
            ifNoneExist: oldUpdatedRoomDataMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedRoomCharge.resourceType}/${oldUpdatedRoomCharge.id}`,
          },
          resource: oldUpdatedRoomCharge,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

export default dayCareRateTableSearchSlice.reducer
