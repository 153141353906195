import { R4 } from '@ahryman40k/ts-fhir-types'
import { TherapyDetail } from 'models/therapyDetail'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import moment from 'moment'
import {
  getCodeOfSystemFromCodableConceptList,
  getDefaultCodeOfSystemFromCodableConcept,
  getExtensionValueOfUrl,
} from 'utils/fhirResourcesHelper'

export function getTherapiesListFromBundle(
  responseSlots: R4.IBundle
): TherapyDetail[] {
  const convertedAppointments: TherapyDetail[] = []
  const healthcareServices: any = {}
  const codableConcepts: any = {}

  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'HealthcareService':
                  healthcareServices[value.resource.id] =
                    value.resource as R4.IHealthcareService
                  break
                case 'ChargeItemDefinition':
                  codableConcepts[value.resource.id] =
                    value.resource as R4.IChargeItemDefinition
                  break

                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in healthcareServices) {
          if (key) {
            const currentAppointment: R4.IHealthcareService =
              healthcareServices[key] as R4.IHealthcareService

            const patientId: string | undefined = getExtensionValueOfUrl(
              currentAppointment.extension,
              'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
            )?.valueReference?.reference?.split('/')[1]
            let isChargeable = false
            let baseAmount
            if (
              currentAppointment.characteristic &&
              currentAppointment.characteristic.length > 0
            ) {
              const val = getCodeOfSystemFromCodableConceptList(
                currentAppointment.characteristic,
                'http://hl7.org/fhir/chargeitem-status'
              )

              if (val && val.code) {
                if (val.code === 'billable') {
                  isChargeable = true
                }
              }

              const currentChargeDef = codableConcepts[
                patientId ?? ''
              ] as R4.IChargeItemDefinition
              if (
                currentChargeDef.propertyGroup &&
                currentChargeDef.propertyGroup.length > 0
              ) {
                if (
                  currentChargeDef.propertyGroup[0].priceComponent &&
                  currentChargeDef.propertyGroup[0].priceComponent.length > 0
                ) {
                  const resIndex =
                    currentChargeDef.propertyGroup[0].priceComponent.findIndex(
                      (e) => e.type == 'base'
                    ) ?? 0
                  if (resIndex >= 0) {
                    baseAmount =
                      currentChargeDef.propertyGroup[0].priceComponent[resIndex]
                        .amount!.value
                  }
                }
              }
            }
            convertedAppointments.push({
              id: currentAppointment.id!,
              healtcareService: healthcareServices[key],
              chargeItemDefinition: codableConcepts[patientId ?? ''],
              displayName: currentAppointment.name ?? 'NA',
              isChargeable,
              baseAmount,
            })
          }
        }
      }
    }
  }

  return convertedAppointments.sort((a, b) =>
    a.displayName! > b.displayName!
      ? 1
      : b.displayName! > a.displayName!
      ? -1
      : 0
  )
}

export function getTherapiesForSelectedTime(
  baseTherapies: TreatmentPlanData[],
  date: string,
  startTime: string
): string[] {
  const filterdArray = [...baseTherapies].filter(
    (e) =>
      moment(e.date).format('DD-MM-YYYY') ===
        moment(date).format('DD-MM-YYYY') &&
      e.startTime === startTime &&
      (e.treatmentPlan?.status === 'active' ||
        e.treatmentPlan?.status === 'completed')
  )

  const res: string[] = []
  filterdArray.forEach((e) => {
    const code = getDefaultCodeOfSystemFromCodableConcept(
      e.treatmentPlan!.code!
    )
    if (code) {
      res.push(code)
    }
  })

  return res
}
