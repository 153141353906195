/* eslint-disable react/jsx-key */
import { Box, Button } from '@material-ui/core'
import { PractitionerWithRate } from 'models/practitionerWithRate'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestForRateUpdate } from 'redux/practitioner/medicalProviderManagement/medicalServiceProviderManagementSlice'
import { RootState } from 'redux/rootReducer'
import { WelloTextField } from 'wello-web-components'

interface Props {
  practDetails: PractitionerWithRate
}

export const VideoTile: React.FC<Props> = ({ practDetails }: Props) => {
  const medicalServiceProviderManagementSlice = useSelector(
    (state: RootState) => state.medicalServiceProviderManagementSlice
  )
  const { t } = useTranslation()
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [price, SetPrice] = React.useState<number>(0)
  const [edit, setEdit] = React.useState<boolean>(false)

  useEffect(() => {
    SetPrice(practDetails.videoRate)
  }, [practDetails.videoRate])

  function handleSubmit() {
    let hasErrorsInData: boolean = false

    if (!price || price < 1 || price > 2000 || Number.isNaN(price)) {
      hasErrorsInData = true
    }

    if (!hasErrorsInData) {
      dispatch(requestForRateUpdate(practDetails, price, 'Video'))
    }
    setHasErrors(hasErrorsInData)
  }

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      flexGrow={1}
      height='100%'
    >
      {practDetails && (
        <Box display='flex' flexDirection='column'>
          <Box
            display='flex'
            flexGrow={1}
            alignItems='flex-end'
            justifyContent='center'
            width='35%'
          >
            <WelloTextField
              title='RATE (IN ₹)'
              textProps={{
                id: `rateId`,
                value: price,
                type: 'number',
                inputProps: {
                  max: 2000,
                  // min:0
                },
                error: hasErrors && (price <= 1 || price > 2000),
                helperText:
                  hasErrors && (price <= 1 || price > 2000)
                    ? 'Rate between 1 to 2000'
                    : '',
                onChange: (changeCommission) => {
                  SetPrice(parseInt(changeCommission.target.value, 10))
                  setEdit(true)
                },
              }}
            />
          </Box>
          <Box width='100%' display='flex' flexDirection='row'>
            <Box py={1}>
              <Button
                onClick={() => {
                  handleSubmit()
                }}
                variant='contained'
                disabled={
                  medicalServiceProviderManagementSlice.updatingPartnerDetail ||
                  practDetails.status !== 'Active' ||
                  edit === false
                }
                disableElevation
                color='primary'
              >
                {t('labelCommon:Save')}
              </Button>
            </Box>
            <Box py={1}>
              <Button
                variant='contained'
                color='primary'
                disabled={edit === false}
                onClick={() => {
                  SetPrice(practDetails.videoRate)
                  setEdit(false)
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}
