import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { requestForWellnessAppointmentCountBoth } from 'redux/welloAdmin/wellnessAppointmentBasedOnStatus/wellnessAppointmentBasedonStatusSlice'
import SimpleBar from 'simplebar-react'
import { AppointmentCountTileCount } from './appointmentDetailCount'

interface Props {
  id: string
  open: boolean
  type: string
  onClose: () => void
}
export const DetailsComponent: React.FC<Props> = ({
  id,
  open,
  onClose,
  type,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const appointmentWellnessCountPerUnitDetailsSlice = useSelector(
    (state: RootState) => state.appointmentWellnessCountPerUnitDetailsSlice
  )

  const ipdCountWpSlice = useSelector(
    (state: RootState) => state.ipdCountWpSlice
  )

  function resetForm() {}

  useEffect(() => {
    if (type.length > 0 && type === 'wellness_center') {
      dispatch(requestForWellnessAppointmentCountBoth(id))
    }
  }, [dispatch, id, type])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetForm()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>
            {' '}
            {`${
              type === 'wellness_center'
                ? 'OPD Appointment Details'
                : type === 'lab'
                ? 'Order Details'
                : 'IPD Appointment Details'
            }`}{' '}
          </Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
                resetForm()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        <Box display='flex' flexGrow={1} flexDirection='column' width='100%'>
          {appointmentWellnessCountPerUnitDetailsSlice.searchingAppointments && (
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
            >
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            </Box>
          )}

          {appointmentWellnessCountPerUnitDetailsSlice.errorWhileSearchingAppointments && (
            <Box
              //   display='flex'
              //   flexDirection='column'
              justifyContent='center'
              width='100%'
              height='100%'
              alignContent='center'
              paddingLeft={50}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Grid container justifyContent='center'>
                    <Typography
                      variant='subtitle1'
                      color='error'
                      align='center'
                    >
                      Error while searching. Please try again
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}

          {appointmentWellnessCountPerUnitDetailsSlice.resultsAvailable && (
            <SimpleBar
              style={{
                height: '100%',
                width: '100%',
              }}
            >
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height='100%'
              >
                <Box display='flex' flexGrow={1} flexDirection='column'>
                  <AppointmentCountTileCount
                    activeCount={
                      appointmentWellnessCountPerUnitDetailsSlice.activeCount
                    }
                    completedCount={
                      appointmentWellnessCountPerUnitDetailsSlice.completedCount
                    }
                    cancelled={
                      appointmentWellnessCountPerUnitDetailsSlice.cancelled
                    }
                    type={type}
                  />
                </Box>
              </Box>
            </SimpleBar>
          )}
        </Box>
      </DialogContent>
      {/* <DialogActions>
        <Box paddingRight={2}>
          <Button
            onClick={() => {
              onClose()
            }}
            variant='outlined'
            disableElevation
          >
            Ok
          </Button>
        </Box>
      </DialogActions> */}
    </Dialog>
  )
}
