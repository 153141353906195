import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography } from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { getIdentifierValueOfUrlType } from 'utils/fhirResourcesHelper'
import {
  getAvailableBedsForRoomParsed,
  getAvailableRoomsForRoomTypesParsed,
} from 'utils/roomsHelpers/roomSelectionHelpers'
import { WelloLoadingIndicator } from 'wello-web-components'
import { WelloSelect } from '../LeftMenu/WelloSelect'

interface Props {
  id: number
  roomTypeCode: string
  bedsToBeOmitted?: string[]
  onBedSelected: (selectedId: string, selectedRoom?: R4.ILocation) => void
}
export const RoomAndBedSelector: React.FC<Props> = ({
  id,
  roomTypeCode,
  bedsToBeOmitted,
  onBedSelected: onPriceFetched,
}: Props) => {
  const dispatch = useDispatch()

  const [fetchingRooms, setFetchingRoomsStatus] =
    React.useState<NetworkRequestStatus>({
      initial: true,
      requesting: true,
    })

  const [fetchingBeds, setFetchingBedsStatus] =
    React.useState<NetworkRequestStatus>({
      initial: true,
    })

  const roomNumberSearchSlice = useSelector(
    (state: RootState) => state.roomNoSearchSlice
  )

  function fetchAvailableRooms() {
    setFetchingRoomsStatus({
      requesting: true,
    })

    getAvailableRoomsForRoomTypesParsed(roomTypeCode)
      .then((e) => {
        setAvailableRoms(e)
        setFetchingRoomsStatus({
          requesting: false,
          requestSuccess: true,
        })
      })
      .catch((e) => {
        setAvailableRoms([])
        setFetchingRoomsStatus({
          requesting: false,
          requestError: true,
        })
      })
  }

  function fetchAvailableBeds(roomId: string) {
    setFetchingBedsStatus({
      requesting: true,
    })

    getAvailableBedsForRoomParsed(roomId)
      .then((e) => {
        if (bedsToBeOmitted && bedsToBeOmitted.length > 0) {
          setAvailableBeds(
            e.filter((bed) => !bedsToBeOmitted.includes(bed.id ?? ''))
          )
        } else {
          setAvailableBeds(e)
        }

        setFetchingBedsStatus({
          requesting: false,
          requestSuccess: true,
        })
      })
      .catch((e) => {
        setAvailableBeds([])
        setFetchingBedsStatus({
          requesting: false,
          requestError: true,
        })
      })
  }

  const [roomNo, setSelectedRoomNo] = React.useState<string>()
  const [rooms, setAvailableRoms] = React.useState<R4.ILocation[]>([])
  const [beds, setAvailableBeds] = React.useState<R4.ILocation[]>([])

  useEffect(() => {
    if (roomTypeCode.length > 0) {
      fetchAvailableRooms()
    }
  }, [])

  useEffect(() => {
    if (roomNo && roomNo.length > 0) {
      fetchAvailableBeds(roomNo)
    }
  }, [roomNo])

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
        </div>
      )}
    >
      <Box
        display='flex'
        flexGrow={1}
        alignItems='flex-end'
        justifyContent='center'
        width='100%'
      >
        {fetchingRooms.requesting && (
          <WelloLoadingIndicator message='Fetching Rooms' />
        )}

        {fetchingRooms.requestSuccess && rooms && rooms.length > 0 && (
          <WelloSelect
            id={`${id}ipd_room_number`}
            label='Room Number'
            availableOptions={rooms
              .map((e) => ({
                name:
                  getIdentifierValueOfUrlType(
                    e.identifier ?? [],
                    'SNO',
                    'http://terminology.hl7.org/CodeSystem/v2-0203'
                  ) ?? '',
                id: e.id,
              }))
              .sort((a, b) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase())
              )}
            required={true}
            type='Select Room'
            displayField='name'
            valueField='id'
            onSelectionChanged={(value: any) => {
              setSelectedRoomNo(value.id)
            }}
          />
        )}

        {fetchingRooms.requestSuccess && rooms && rooms.length === 0 && (
          <Typography
            variant='body1'
            color='error'
            style={{
              paddingTop: 2,
            }}
          >
            No room available for the selected Room Type
          </Typography>
        )}

        {fetchingBeds.requestSuccess && beds && beds.length === 0 && (
          <Typography
            variant='body1'
            color='error'
            style={{
              paddingTop: 2,
            }}
          >
            No beds available for the selected Room
          </Typography>
        )}

        {fetchingBeds.requesting && (
          <WelloLoadingIndicator message='Fetching Beds' />
        )}

        {fetchingBeds.requestSuccess && beds && beds.length > 0 && (
          <Box p={1} />
        )}
        {fetchingBeds.requestSuccess && beds && beds.length > 0 && (
          <WelloSelect
            id={`${id}ipd_bed_number`}
            label='Bed Number'
            availableOptions={beds
              .map((e) => ({
                name:
                  getIdentifierValueOfUrlType(
                    e.identifier ?? [],
                    'SNO',
                    'http://terminology.hl7.org/CodeSystem/v2-0203'
                  ) ?? '',
                id: e.id,
              }))
              .sort((a, b) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase())
              )}
            required={true}
            type='Select Bed'
            displayField='name'
            valueField='id'
            onSelectionChanged={(value: any) => {
              onPriceFetched(value.id, value)
            }}
          />
        )}
      </Box>
    </ErrorBoundary>
  )
}
