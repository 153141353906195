import { Box } from '@material-ui/core'
import React from 'react'
import { isUnitAdmin, isWpAdmin } from 'services/userDetailsService'
import { CPGsListPageForOUAdmin } from './cpgsListPageForOUAdmin'
import { CPGsListPageForWPAdmin } from './cpgsListPageForWPAdmin'
import { CPSubscriptionPlanListPageForWPAdmin } from './cpSubscriptionPlanListPageForWPAdmin'

interface Props {
  isReadonly?: boolean
}

export const CPGListPage: React.FC<Props> = ({ isReadonly: split }: Props) => {
  if (isWpAdmin()) {
    return <CPGsListPageForWPAdmin isReadonly={split} />
  }

  if (isUnitAdmin()) {
    return <CPGsListPageForOUAdmin isReadonly={split} />
  }

  return (
    <Box display='flex' flexDirection='column' width='100%' height='100%    '>
      Not Available
    </Box>
  )
}
