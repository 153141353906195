import { Calendar } from '@mantine/dates'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { kPrimaryLight, kPrimaryMain } from 'configs/styles/muiThemes'
import { DoctorBase } from 'lib/openApi'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { FhirSlotOnTime } from 'models/fhirSlotListOnTime'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  requestForAgentSlots,
  resetSlotSelectionStatus,
} from 'redux/lab/order/agentSlotSelection/agentSlotSearchSlice'
import { RootState } from 'redux/rootReducer'
import SimpleBar from 'simplebar-react'
import { getCompleteDateString, getTomorrowDate, isToday } from 'utils/dateUtil'
import { ProviderSelectorNew } from 'views/components/selectors/providers/providerSelectorNew'
import CustomizedDividers from 'views/components/toggle_selector'
import { WelloLoadingIndicator } from 'wello-web-components'
import { CombinedAgentSlots } from './combined_agent_slots'

interface Props {
  open: boolean
  onSlotSelected?: (selectedSlot?: FhirSlotDetail) => void
  onCancelled?: () => void
  practitionerWithRole?: PractitionerWithRole[]
}

export const AgentSlotAdvancedSelector: React.FC<Props> = ({
  open = false,
  onSlotSelected,
  onCancelled,
  practitionerWithRole,
}: Props) => {
  const agentSlotSearchSlice = useSelector(
    (state: RootState) => state.agentSlotSearchSlice
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedPartOfDay, setSelectedPartOfDay] = useState<string>('any')
  const [selectedDatePart, setSelectedDatePart] = useState<string>('asap')
  const selectedDoctors = useRef<PractitionerWithRole[]>(
    practitionerWithRole ?? []
  )
  const [selectedSlot, setSelectedSlot] = useState<FhirSlotDetail | undefined>()
  if (practitionerWithRole) {
    selectedDoctors.current = practitionerWithRole
  }

  useEffect(() => {
    requestSearchForSlots()
  }, [selectedDate])

  function onDoctorsSelectionChanged(selectedValues: PractitionerWithRole[]) {
    selectedDoctors.current = selectedValues
    requestSearchForSlots()
  }

  function requestSearchForSlots() {
    setSelectedSlot(undefined)
    if (onSlotSelected) {
      onSlotSelected(undefined)
    }
    dispatch(resetSlotSelectionStatus())
    dispatch(requestForAgentSlots(selectedDoctors.current ?? [], selectedDate))
  }

  return (
    <Dialog
      open={open}
      maxWidth='md'
      fullWidth
      onClose={() => {
        if (onCancelled) {
          onCancelled()
        }
      }}
      aria-labelledby='slot_adv_select_dialog'
    >
      <DialogTitle id='slot_adv_select_dialog'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>{t('labelCommon:select_slot_adv_title')}</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                if (onCancelled) {
                  onCancelled()
                }
              }}
            />
          </Box>
        </Box>{' '}
      </DialogTitle>
      <DialogContent>
        <Box
          width='100%'
          display='flex'
          flexDirection='column'
          overflow='auto'
          style={{ overflow: 'auto', height: '78vh' }}
        >
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='space-between'
            px={2}
          >
            <Box display='flex' flexDirection='column'>
              <CustomizedDividers
                size='small'
                menuLabel={t('labelCommon:appointment_date')}
                options={[
                  {
                    displayText: t('labelCommon:asap'),
                    value: 'asap',
                  },
                  {
                    displayText: t('labelCommon:tomorrow'),
                    value: 'tomo',
                  },
                  {
                    displayText: t('labelCommon:custom'),
                    value: 'custom',
                  },
                ]}
                onSelectionChanged={(selectedValue) => {
                  setSelectedDatePart(selectedValue)
                  if (selectedValue === 'asap') {
                    setSelectedDate(new Date())
                  }
                  if (selectedValue === 'tomo') {
                    setSelectedDate(getTomorrowDate())
                  }
                }}
                preSelectedValue={selectedDatePart}
                id='time_grp'
              />
            </Box>

            <Box display='flex' flexDirection='column'>
              {' '}
              <CustomizedDividers
                size='small'
                menuLabel={t('labelCommon:time_slot')}
                options={[
                  {
                    displayText: t('labelCommon:any'),
                    value: 'any',
                  },
                  {
                    displayText: t('labelCommon:morning'),
                    value: 'morning',
                  },
                  {
                    displayText: t('labelCommon:afternoon'),
                    value: 'afternoon',
                  },
                  {
                    displayText: t('labelCommon:evening'),
                    value: 'evening',
                  },
                ]}
                onSelectionChanged={(selectedValue) => {
                  setSelectedPartOfDay(selectedValue)
                }}
                preSelectedValue={selectedPartOfDay}
                id='day_grp'
              />
            </Box>
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='space-between'
            paddingTop={2}
          >
            <Divider
              style={{
                width: '100%',
              }}
            />
          </Box>
          <Box
            width='100%'
            display='flex'
            flexDirection='row'
            overflow='auto'
            style={{ overflow: 'auto', height: '78vh' }}
          >
            <Box
              width={2 / 5}
              style={{ overflow: 'auto', height: '100%', maxWidth: '280px' }}
              flexGrow
            >
              <SimpleBar
                style={{
                  height: '100%',

                  padding: '4px',
                }}
              >
                <Box
                  paddingRight={1}
                  paddingY={0}
                  flexDirection='column'
                  display='flex'
                  height='100%'
                >
                  {selectedDatePart === 'custom' && (
                    <Box width='100%'>
                      <Calendar
                        size='xs'
                        style={{
                          aspectRatio: '1/1',
                        }}
                        value={selectedDate}
                        onChange={(e: any) => {
                          setSelectedDate(e)
                        }}
                        id='customDate'
                        minDate={new Date()}
                        dayStyle={(date, modifier) => {
                          if (isToday(date)) {
                            return {
                              aspectRatio: '1',
                              backgroundColor: kPrimaryLight,
                              borderRadius: '50%',
                            }
                          }
                          return { aspectRatio: '1' }
                        }}
                        styles={{
                          cell: {
                            aspectRatio: '1',
                          },
                          day: {
                            aspectRatio: '1 !important',
                          },
                          weekday: {
                            color: kPrimaryMain,
                          },
                          selected: {
                            borderRadius: '50%',
                          },
                          weekend: {
                            color: 'black',
                          },
                          label: {
                            fontWeight: 'bold',
                            color: kPrimaryMain,
                          },
                        }}
                      />
                    </Box>
                  )}

                  <Box paddingY={0.5}>
                    <ProviderSelectorNew
                      preSelectedPractitionerRoles={practitionerWithRole}
                      onProviderSelectionChanged={(
                        selectedValues: DoctorBase[]
                      ) => {}}
                      onPractitionerSelectionChanged={(
                        selectedPractitioners: PractitionerWithRole[]
                      ) => {
                        onDoctorsSelectionChanged(selectedPractitioners)
                      }}
                      doctorListId='appointmentDoctor'
                      providerType='lab-agent'
                    />
                  </Box>
                </Box>
              </SimpleBar>
            </Box>
            <Box
              flexDirection='column'
              display='flex'
              bgcolor='background.default'
              width='1px'
              style={{
                height: '100%',
                maxHeight: '100%',
              }}
            >
              <Box bgcolor='grey.500' height='100%' width='1px' />
            </Box>
            <Box
              flexGrow={1}
              flexDirection='column'
              style={{ overflow: 'auto', maxHeight: '100%' }}
            >
              <Box
                display='flex'
                alignContent='center'
                justifyContent='center'
                overflow='auto'
                height='100%'
              >
                {agentSlotSearchSlice.searching && (
                  <WelloLoadingIndicator message='Searching' />
                )}

                {agentSlotSearchSlice.noSelection && (
                  <Typography
                    variant='h6'
                    align='center'
                    style={{ alignSelf: 'center' }}
                  >
                    Select Agents to Begin
                  </Typography>
                )}

                {agentSlotSearchSlice.noResultsAvailable && (
                  <Typography
                    variant='h6'
                    align='center'
                    style={{ alignSelf: 'center' }}
                  >
                    {t('labelCommon:no_slots')}
                  </Typography>
                )}

                {agentSlotSearchSlice.resultsAvailable && (
                  <SimpleBar
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <Box
                      display='flex'
                      alignContent='center'
                      justifyContent='center'
                      overflow='auto'
                      height='100%'
                    >
                      <List
                        component='nav'
                        disablePadding={true}
                        style={{ flexGrow: 1 }}
                      >
                        {agentSlotSearchSlice.slotList?.map(
                          (dayWiseSlot, index: number) => (
                            <Box
                              p={1}
                              id='slots'
                              key={`key_day_wise_slots_${dayWiseSlot.date}`}
                            >
                              <Box paddingX={0.5}>
                                <Typography
                                  variant='subtitle2'
                                  style={{ fontWeight: 'bolder' }}
                                >
                                  {getCompleteDateString(
                                    dayWiseSlot.date ?? ''
                                  )}
                                </Typography>
                              </Box>
                              <List
                                style={{
                                  paddingTop: -4,
                                  paddingBottom: -4,
                                  marginTop: -4,
                                  marginBottom: -4,
                                }}
                                id={`start_slot${index}`}
                              >
                                {(dayWiseSlot.morningSlots?.length ?? -1) > 0 &&
                                  (selectedPartOfDay === 'any' ||
                                    selectedPartOfDay === 'morning') && (
                                    <ListItem
                                      style={{ padding: 0, cursor: 'pointer' }}
                                      id={`start_slot_item${index}`}
                                    >
                                      <List
                                        style={{ flexGrow: 1 }}
                                        id={`start_slot_item_inner${index}`}
                                      >
                                        <ListItem
                                          style={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 4,
                                            marginTop: 0,
                                            marginBottom: 0,
                                            cursor: 'pointer',
                                          }}
                                          id={`start_slot_item_inner_item${index}`}
                                        >
                                          <Typography
                                            variant='subtitle1'
                                            color='primary'
                                            style={{ fontWeight: 'bolder' }}
                                          >
                                            {t('labelCommon:morning')}
                                          </Typography>
                                        </ListItem>
                                        {dayWiseSlot.morningSlots?.map(
                                          (slot: FhirSlotOnTime) => (
                                            <Box
                                              key={`agent_slot${
                                                slot.slots[0].slot.id ?? ''
                                              }`}
                                              onClick={() => {
                                                setSelectedSlot(slot.slots[0])
                                              }}
                                              py={0.5}
                                              id={`start_slot_morning_box${index}`}
                                            >
                                              <CombinedAgentSlots
                                                slotDetails={slot}
                                                isSelected={
                                                  selectedSlot?.slot.id ===
                                                  slot.slots[0].slot.id
                                                }
                                              />
                                            </Box>
                                          )
                                        )}
                                      </List>
                                    </ListItem>
                                  )}
                                {(dayWiseSlot.afterNoonSlots?.length ?? -1) >
                                  0 &&
                                  (selectedPartOfDay === 'any' ||
                                    selectedPartOfDay === 'afternoon') && (
                                    <ListItem
                                      style={{ padding: 0 }}
                                      id={`start_slot_after${index}`}
                                    >
                                      <List
                                        style={{ flexGrow: 1 }}
                                        id={`start_slot_after_inner${index}`}
                                      >
                                        <ListItem
                                          style={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 4,
                                            marginTop: 0,
                                            marginBottom: 0,
                                          }}
                                          id={`start_slot_after_inner_item${index}`}
                                        >
                                          <Typography
                                            variant='subtitle1'
                                            color='primary'
                                            style={{ fontWeight: 'bolder' }}
                                          >
                                            {t('labelCommon:afternoon')}
                                          </Typography>
                                        </ListItem>
                                        {dayWiseSlot.afterNoonSlots?.map(
                                          (slot: FhirSlotOnTime) => (
                                            <Box
                                              key={`agent_slot${
                                                slot.slots[0].slot.id ?? ''
                                              }`}
                                              onClick={() => {
                                                setSelectedSlot(slot.slots[0])
                                              }}
                                              py={0.5}
                                              id={`start_slot_after_box${index}`}
                                            >
                                              <CombinedAgentSlots
                                                slotDetails={slot}
                                                isSelected={
                                                  selectedSlot?.slot.id ===
                                                  slot.slots[0].slot.id
                                                }
                                              />
                                            </Box>
                                          )
                                        )}
                                      </List>
                                    </ListItem>
                                  )}
                                {(dayWiseSlot.eveningSlots?.length ?? -1) > 0 &&
                                  (selectedPartOfDay === 'any' ||
                                    selectedPartOfDay === 'evening') && (
                                    <ListItem
                                      style={{ padding: 0 }}
                                      id={`start_slot_even${index}`}
                                    >
                                      <List
                                        style={{ flexGrow: 1 }}
                                        id={`start_slot_even_inner${index}`}
                                      >
                                        <ListItem
                                          style={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 4,
                                            marginTop: 0,
                                            marginBottom: 0,
                                          }}
                                          id={`start_slot_even_inner_itemList${index}`}
                                        >
                                          <Typography
                                            variant='subtitle1'
                                            color='primary'
                                            style={{ fontWeight: 'bolder' }}
                                          >
                                            {t('labelCommon:evening')}
                                          </Typography>
                                        </ListItem>
                                        {dayWiseSlot.eveningSlots?.map(
                                          (slot: FhirSlotOnTime) => (
                                            <Box
                                              key={`agent_slot${
                                                slot.slots[0].slot.id ?? ''
                                              }`}
                                              onClick={() => {
                                                setSelectedSlot(slot.slots[0])
                                              }}
                                              py={0.5}
                                              id={`start_slot_even_box${index}`}
                                            >
                                              <CombinedAgentSlots
                                                slotDetails={slot}
                                                isSelected={
                                                  selectedSlot?.slot.id ===
                                                  slot.slots[0].slot.id
                                                }
                                              />
                                            </Box>
                                          )
                                        )}
                                      </List>
                                    </ListItem>
                                  )}
                              </List>
                            </Box>
                          )
                        )}
                      </List>
                    </Box>
                  </SimpleBar>
                )}
              </Box>
            </Box>
          </Box>
          <Divider
            style={{
              width: '100%',
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation={true}
          variant='contained'
          disabled={selectedSlot === undefined}
          onClick={() => {
            if (onSlotSelected) {
              onSlotSelected(selectedSlot)
            }
          }}
          color='primary'
          id='select_slot'
        >
          {t('labelCommon:select_time_slot')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
