import { R4 } from '@ahryman40k/ts-fhir-types'

export const HigherFunctionObservationType: R4.ICoding[] = [
  {
    system: 'http://snomed.info/sct',
    code: '52751000',
    display: 'Hemiplegic Gait',
  },
  {
    system: 'http://snomed.info/sct',
    code: '1184611008',
    display: 'Diplegic Gait',
  },
  {
    system: 'http://snomed.info/sct',
    code: '250024000',
    display: 'Neuropathic Gait',
  },
  {
    system: 'http://snomed.info/sct',
    code: '25136009',
    display: 'Ataxic Gait',
  },
  {
    system: 'http://snomed.info/sct',
    code: '250036006',
    display: 'Parkinsonian Gait',
  },
  {
    system: 'http://snomed.info/sct',
    code: '250027007',
    display: 'Sensory Gait',
  },
  {
    system: 'http://snomed.info/sct',
    code: '250020009',
    display: 'Myopathic Gait',
  },
  {
    system: 'http://snomed.info/sct',
    code: '22090007',
    display: 'Scissoring Gait',
  },
  {
    system: 'http://snomed.info/sct',
    code: '398218008',
    display: 'Propulsive Gait',
  },
  {
    system: 'http://snomed.info/sct',
    code: '40192003',
    display: 'Trendelenburg Gait',
  },
  {
    system: 'http://snomed.info/sct',
    code: '6950007',
    display: 'Dyspraxia',
  },
  {
    system: 'http://snomed.info/sct',
    code: '271706000',
    display: 'Waddling Gait',
  },
  {
    system: 'http://snomed.info/sct',
    code: '27253007',
    display: 'Steppage Gait',
  },
  {
    system: 'http://snomed.info/sct',
    code: '8117002',
    display: 'Gait Normal',
  },
  {
    system: 'http://snomed.info/sct',
    code: '162293002',
    display: 'Speech Normal',
  },
  {
    system: 'http://snomed.info/sct',
    code: '74227009',
    display: 'Apraxic Aphonia',
  },
  {
    system: 'http://snomed.info/sct',
    code: '39423001',
    display: 'Stuttering',
  },
  {
    system: 'http://snomed.info/sct',
    code: '8011004',
    display: 'Dysarthria',
  },
  {
    system: 'http://snomed.info/sct',
    code: '69577009',
    display: 'Lisping',
  },
  {
    system: 'http://snomed.info/sct',
    code: '29003001',
    display: 'Spastic Dysphonia',
  },
  {
    system: 'http://snomed.info/sct',
    code: '37732008',
    display: 'Cluttering',
  },
  {
    system: 'http://snomed.info/sct',
    code: '71959007',
    display: 'Selective Mutism',
  },
  {
    system: 'http://snomed.info/sct',
    code: '87486003',
    display: 'Aphasia',
  },
  {
    system: 'http://snomed.info/sct',
    code: '229721007',
    display: 'Speech Delay',
  },
  {
    system: 'http://snomed.info/sct',
    code: '17326005',
    display: 'Normal Mental Condition',
  },
  {
    system: 'http://snomed.info/sct',
    code: '271782001',
    display: 'Mental Status, Drowsy',
  },
  {
    system: 'http://snomed.info/sct',
    code: '419284004',
    display: 'Altered mental status',
  },
  {
    system: 'http://snomed.info/sct',
    code: '89458003',
    display: 'Mental Status, Stupor',
  },
  {
    system: 'http://snomed.info/sct',
    code: '274659008',
    display: 'Semicoma',
  },
  {
    system: 'http://snomed.info/sct',
    code: '418107008',
    display: 'Unconscious',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-higher-function-finding-cs',
    code: 'other-higher-function-finding',
    display: 'Other',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
