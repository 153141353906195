import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core'
import { FhirClinicalImpressionDetail } from 'models/fhirClinicalImpression'
import { UmlResponse } from 'models/umlResponse'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import {
  getDischargeDiagnosisOfPTOfEncounter,
  resetState,
  updateDischargeDiagnosisDetailsOfPT,
} from 'redux/fhirMedicalResources/ipd/addDischargeDiagnosisOfPT/addDischargeDiagnosisOfPTSlice'
import { RootState } from 'redux/rootReducer'
import {
  hasAllergies,
  hasConditions,
  hasSameSummary,
} from 'utils/fhirResoureHelpers/clinicalImpressionHelper'
import { logger } from 'utils/logger'

interface DischargeDiagnosisForPTProps {
  encounterId: string
  patientId: string
}

export const DischargeDiagnosisForPT: React.FC<DischargeDiagnosisForPTProps> =
  ({ encounterId, patientId }: DischargeDiagnosisForPTProps) => {
    const [summary, setSummary] = React.useState<string>()
    const [fetchedImp, setFetchedImp] =
      React.useState<FhirClinicalImpressionDetail>()

    const { t } = useTranslation(['common'])
    const dispatch = useDispatch()

    const selecteDiagnosedConditions = useRef<R4.ICoding[]>()
    const selecteDiagnosedAllergies = useRef<R4.ICoding[]>()
    const preSelectedSurgery = useRef<UmlResponse[]>()
    //   const [preSelectedSurgery, setPreSelectedSurgery] = useState<UmlResponse[]>(
    //     []
    //   )

    /*  const [findings, setFindings] = useState<R4.IClinicalImpression_Finding[]>([]) */
    const assessmentDetailsSlice = useSelector(
      (state: RootState) => state.addDischargeDiagnosisOfPTSlice
    )

    const [isActive, setIsActive] = useState<boolean>(false)

    function updateDetails(
      condition: string,
      operation: string,
      deletedData?: R4.ICoding
    ) {
      if (selecteDiagnosedConditions.current !== undefined) {
        if (
          !hasSameSummary(
            summary,
            assessmentDetailsSlice.clinicalImpressions?.clinicalImpression
          ) ||
          !hasAllergies(
            selecteDiagnosedAllergies.current ?? [],
            assessmentDetailsSlice.clinicalImpressions!
          ) ||
          !hasConditions(
            selecteDiagnosedConditions.current ?? [],
            assessmentDetailsSlice.clinicalImpressions!
          )
        ) {
          dispatch(
            updateDischargeDiagnosisDetailsOfPT(
              condition,
              operation,
              encounterId,
              patientId,
              summary ?? '',
              [],
              selecteDiagnosedConditions.current,
              selecteDiagnosedAllergies.current ?? [],
              deletedData
            )
          )
        } else {
          dispatch(
            updateDischargeDiagnosisDetailsOfPT(
              condition,
              operation,
              encounterId,
              patientId,
              summary ?? '',
              [],
              selecteDiagnosedConditions.current,
              selecteDiagnosedAllergies.current ?? [],
              deletedData
            )
          )
        }
      } else {
        dispatch(
          updateDischargeDiagnosisDetailsOfPT(
            condition,
            operation,
            encounterId,
            patientId,
            summary ?? '',
            [],
            selecteDiagnosedConditions.current,
            selecteDiagnosedAllergies.current ?? [],
            deletedData
          )
        )
      }
    }

    //   useEffect(() => {
    //     if (selecteDiagnosedConditions.current !== undefined) {
    //       updateDetails()
    //     }
    //   }, [summary, diagnosedConditions, diagnosedAllergies])

    useEffect(() => {
      dispatch(getDischargeDiagnosisOfPTOfEncounter(encounterId))
    }, [])

    useEffect(() => {
      if (assessmentDetailsSlice.updatedImpressions) {
        dispatch(getDischargeDiagnosisOfPTOfEncounter(encounterId))
        dispatch(resetState())
      }
      if (assessmentDetailsSlice.resultsAvailable) {
        if (assessmentDetailsSlice.clinicalImpressions) {
          updateAssessmentDetails(assessmentDetailsSlice.clinicalImpressions)
          dispatch(resetState())
        }
      }
    }, [dispatch, assessmentDetailsSlice])

    const updateAssessmentDetails = (
      condition: FhirClinicalImpressionDetail
    ) => {
      setFetchedImp(condition)

      logger.info('FhirAssessment Details,', condition)
      if (condition.clinicalImpression.summary) {
        setSummary(condition.clinicalImpression.summary)
      }
    }

    return (
      <Box
        style={{ paddingLeft: '4px', paddingBottom: '24px' }}
        display='flex'
        flexDirection='row'
      >
        <Box width='100%'>
          <Grid container direction='column' style={{ width: '100%' }}>
            <Grid item style={{ width: '100%' }}>
              <ReactVisibilitySensor
                offset={{
                  top: 100,
                  bottom: 30,
                }}
              >
                <Grid
                  container
                  direction='column'
                  id='visit_summary'
                  style={{ width: '100%' }}
                >
                  <Grid item style={{ width: '100%' }}>
                    <Box display='flex' py={1} width='100%'>
                      <Box
                        width='100%'
                        display='flex'
                        alignItems='center'
                        flexDirection='row'
                        bgcolor='#999999'
                        minHeight={45}
                      >
                        <Box p={2} width='100%'>
                          <Typography
                            variant='body1'
                            style={{ color: 'white', fontWeight: 600 }}
                          >
                            Discharge Notes
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  {assessmentDetailsSlice.updatingImpressions && (
                    <Grid item xs={8}>
                      <LinearProgress />
                    </Grid>
                  )}

                  <Grid item xs={8}>
                    <TextField
                      id='consult_assessment_summary_tf'
                      value={summary}
                      fullWidth
                      multiline={true}
                      variant='outlined'
                      InputProps={{
                        color: 'primary',

                        endAdornment: (
                          <InputAdornment position='end'>
                            {' '}
                            <Button
                              variant='contained'
                              color='primary'
                              id='pt_save'
                              disabled={
                                !summary ||
                                summary.trim().length === 0 ||
                                (fetchedImp &&
                                  hasSameSummary(
                                    summary.trim(),
                                    fetchedImp.clinicalImpression
                                  )) ||
                                assessmentDetailsSlice.updatingImpressions
                              }
                              onClick={() => {
                                updateDetails('summary', 'add')
                              }}
                            >
                              {t('labelCommon:done')}
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) => {
                        setSummary(event.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
              </ReactVisibilitySensor>
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  }
