/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { doctorSchedule } from '../models/doctorSchedule';
import type { doctorScheduleCancel } from '../models/doctorScheduleCancel';
import type { ErrorResponse } from '../models/ErrorResponse';
import type { SearchDoctorSchedulesResponse } from '../models/SearchDoctorSchedulesResponse';
import type { SearchDoctorSlotsResponse } from '../models/SearchDoctorSlotsResponse';
import type { SearchDoctorSlotsTenantResponse } from '../models/SearchDoctorSlotsTenantResponse';
import { request as __request } from '../core/request';

export class DoctorScheduleService {

    /**
     * add doctorSchedule
     * @returns any OK
     * @returns ErrorResponse default error response
     * @throws ApiError
     */
    public static async bulkDoctorSchedule({
tid,
requestBody,
}: {
/** Tenant Id **/
tid: string,
requestBody: Array<doctorSchedule>,
}): Promise<any | ErrorResponse> {
        const result = await __request({
            method: 'POST',
            path: `/clinical/tenant/${tid}/doctorSchedule`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * cancel doctorSchedule
     * @returns any OK
     * @returns ErrorResponse default error response
     * @throws ApiError
     */
    public static async cancelDoctorSchedule({
tid,
requestBody,
}: {
/** Tenant Id **/
tid: string,
requestBody: doctorScheduleCancel,
}): Promise<any | ErrorResponse> {
        const result = await __request({
            method: 'POST',
            path: `/clinical/tenant/${tid}/cancelDoctorSchedule`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * search doctor Slot
     * @returns SearchDoctorSlotsTenantResponse OK
     * @returns ErrorResponse default error response
     * @throws ApiError
     */
    public static async searchDoctorSlot({
tid,
doctorId,
doctorIdArr,
eqdate,
fromDate,
toDate,
freeSlotsOnly,
}: {
/** Tenant Id **/
tid: string,
/** Id of doctor **/
doctorId?: string,
/** Id of doctor **/
doctorIdArr?: Array<string>,
/** date **/
eqdate?: string,
/** From date **/
fromDate?: string,
/** To date **/
toDate?: string,
freeSlotsOnly?: boolean,
}): Promise<SearchDoctorSlotsTenantResponse | ErrorResponse> {
        const result = await __request({
            method: 'GET',
            path: `/clinical/tenant/${tid}/searchDoctorSlot`,
            query: {
                'doctorId': doctorId,
                'doctorIdArr': doctorIdArr,
                'eqdate': eqdate,
                'fromDate': fromDate,
                'toDate': toDate,
                'freeSlotsOnly': freeSlotsOnly,
            },
        });
        return result.body;
    }

    /**
     * search doctor Slot
     * @returns SearchDoctorSlotsResponse OK
     * @returns ErrorResponse default error response
     * @throws ApiError
     */
    public static async aggregateSearchDoctorSlot({
doctorId,
tid,
eqdate,
fromDate,
toDate,
freeSlotsOnly,
}: {
/** Id of doctor **/
doctorId: string,
/** Tenant Ids **/
tid?: string,
/** date **/
eqdate?: string,
/** From date **/
fromDate?: string,
/** To date **/
toDate?: string,
freeSlotsOnly?: boolean,
}): Promise<SearchDoctorSlotsResponse | ErrorResponse> {
        const result = await __request({
            method: 'GET',
            path: `/clinical/doctorSchedule/op/searchDoctorSlot`,
            query: {
                'doctorId': doctorId,
                'tid': tid,
                'eqdate': eqdate,
                'fromDate': fromDate,
                'toDate': toDate,
                'freeSlotsOnly': freeSlotsOnly,
            },
        });
        return result.body;
    }

    /**
     * search doctor schedules
     * @returns SearchDoctorSchedulesResponse OK
     * @returns ErrorResponse default error response
     * @throws ApiError
     */
    public static async searchDoctorSchedule({
tid,
doctorId,
eqdate,
fromDate,
toDate,
}: {
/** Tenant Id **/
tid: string,
/** Id of doctor **/
doctorId?: string,
/** date **/
eqdate?: string,
/** From date **/
fromDate?: string,
/** To date **/
toDate?: string,
}): Promise<SearchDoctorSchedulesResponse | ErrorResponse> {
        const result = await __request({
            method: 'GET',
            path: `/clinical/tenant/${tid}/searchDoctorSchedule`,
            query: {
                'doctorId': doctorId,
                'eqdate': eqdate,
                'fromDate': fromDate,
                'toDate': toDate,
            },
        });
        return result.body;
    }

    /**
     * get doctor Schedule
     * @returns doctorSchedule OK
     * @returns ErrorResponse default error response
     * @throws ApiError
     */
    public static async getDoctorScheduleById({
tid,
id = 'abc123',
}: {
/** Tenant Id **/
tid: string,
/** Id of entity **/
id?: string,
}): Promise<doctorSchedule | ErrorResponse> {
        const result = await __request({
            method: 'GET',
            path: `/clinical/tenant/${tid}/doctorSchedule/${id}`,
        });
        return result.body;
    }

}