import { R4 } from '@ahryman40k/ts-fhir-types'
import { AxiosResponse } from 'axios'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import QueryString from 'qs'
import { getErrorResponse } from 'utils/apiClientHelper'
import { logger } from 'utils/logger'
import { ApiClient } from './apiService'

export class ValueSetSearchClient extends ApiClient {
  constructor() {
    const finalUrl = `${process.env.REACT_APP_MASTER_BASE_URL_ValueSet ?? ''}/`
    super(finalUrl)
  }

  public async doCreateFHIRResourceRequest(
    path: string,
    body: any
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        path,
        body,
        {
          headers: {},
        }
      )
      if (response.status === 201) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceForMasterDataForValuset(
    path: string,
    searchParameters?: {}
  ) {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        baseURL: process.env.REACT_APP_MASTER_BASE_URL_ValueSet,
        params: searchParameters,
      })

      return response.data
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doUpdateFHIRResourceRequest(
    path: string,
    body: R4.IResource,
    currentVersion: string
  ): Promise<any | FHIRErrorResponses> {
    try {
      logger.info('Updating')
      logger.info(body)
      const response: AxiosResponse = await this.axiosInstance.put(path, body, {
        headers: {
          'If-Match': `W/${JSON.stringify(currentVersion)}`,
        },
      })
      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doCreateFHIRTransaction(
    path: string,
    body: any
  ): Promise<R4.IBundle | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        path,
        body,
        {
          headers: {},
        }
      )
      if (response.status === 201 || response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResource(
    path: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceCrossPartition(
    path: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        headers: {
          'x-partition-name': '*',
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doReportGetResource(
    path: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        headers: {
          category: 'TREAT',
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceForMasterData(path: string, searchParameters?: {}) {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        baseURL: process.env.REACT_APP_MASTER_BASE_URL,
        params: searchParameters,
      })

      return response.data
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceIncludeAndIncludeIterate(
    path: string,

    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        paramsSerializer(pars) {
          return QueryString.stringify(pars, { arrayFormat: 'repeat' })
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doDeleteResource(
    path: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.delete(path, {
        params: searchParameters,
        // baseURL: process.env.REACT_APP_FHIR_BASE_URL,
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doCreateFHIRUploadResourceRequest(
    path: string,
    body: any,
    header: string
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        path,
        body,
        {
          headers: {
            'Content-Type': `${header}`,
          },
        }
      )
      if (response.status === 201) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doCreateFHIRFinishResourceRequest(
    path: string,
    body: any,
    header: string
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        path,
        body,
        {
          headers: {
            'Content-Type': `${header}`,
          },
        }
      )
      if (response.status === 201) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceForAppointment(
    path: string,
    appointmentId: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        headers: {
          'x-appointment-id': `Appointment/${appointmentId}`,
          category:
            'http://terminology.hl7.org/ValueSet/v3-PurposeOfUse#HMARKT',
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceForAppointmentWithIncludeIterate(
    path: string,
    appointmentId: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        paramsSerializer(pars) {
          return QueryString.stringify(pars, { arrayFormat: 'repeat' })
        },
        headers: {
          'x-appointment-id': `Appointment/${appointmentId}`,
          category:
            'http://terminology.hl7.org/ValueSet/v3-PurposeOfUse#HMARKT',
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
    path: string,
    appointmentId: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        paramsSerializer(pars) {
          return QueryString.stringify(pars, { arrayFormat: 'repeat' })
        },
        headers: {
          'x-appointment-id': `Appointment/${appointmentId}`,
          category:
            'http://terminology.hl7.org/ValueSet/v3-PurposeOfUse#HMARKT',
          'X-Partition-Name': '*',
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }
}
