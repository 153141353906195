import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { UnitMemberRelation } from 'models/unitMemberrelation'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getUserDetails,
} from 'services/userDetailsService'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  getCombinedRoles,
  getPractitionerObject,
  getPractitionerRoleObject,
  getPractitionerRoleOfPractitionerForSpecificUnit,
  getTaskObjectForProviderInvitation,
  UnitMemberDetailsCombined,
} from 'utils/fhirResoureHelpers/invitationHelpers'
import { logger } from 'utils/logger'
import { UnitActorInvitationSetupType } from './unitActorInvitationSetupType'

const initialState: UnitActorInvitationSetupType = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const unitActorInvitationSetupSlice = createSlice({
  name: 'unitAdminInvitations',
  initialState,
  reducers: {
    sendingUnitAdminInvitations(
      state,
      action: PayloadAction<UnitActorInvitationSetupType>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
    },

    sentUnitAdminInvitations(
      state,
      action: PayloadAction<UnitActorInvitationSetupType>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.patient = action.payload.patient
    },

    errorWhileSendingUnitAdminInvitations(
      state,
      action: PayloadAction<UnitActorInvitationSetupType>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetState(state, action: PayloadAction<UnitActorInvitationSetupType>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.patient = action.payload.patient
    },
  },
})

export const sendUnitActorBindingInvitations =
  (unitDetails: UnitMemberRelation[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: UnitActorInvitationSetupType = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(
      unitActorInvitationSetupSlice.actions.sendingUnitAdminInvitations(
        addingCreatePersonState
      )
    )
    try {
      const requestBody: R4.IBundle | undefined = await getTransactionObject(
        unitDetails
      )
      if (requestBody) {
        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response: any | FHIRErrorResponses =
          await fhirApi.doCreateFHIRTransaction('', requestBody)
        logger.info('Response ', response)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          const successCreatePersonState: UnitActorInvitationSetupType = {
            adding: false,
            additionSuccessful: true,
            error: false,
            errorMessage: '',
          }
          dispatch(showSuccessAlert('Invitation sent successfully'))
          dispatch(
            unitActorInvitationSetupSlice.actions.sentUnitAdminInvitations(
              successCreatePersonState
            )
          )
          return
        }
      }
      const errorCreatePersonState: UnitActorInvitationSetupType = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while sending invitation',
      }
      dispatch(
        unitActorInvitationSetupSlice.actions.sentUnitAdminInvitations(
          errorCreatePersonState
        )
      )
      return
    } catch (error: any) {
      logger.info('error', error)
      let message: string = 'Error while sending invitations. Please try later'
      const errorData: string = error.message
      if (errorData.includes('412'))
        message = 'Either email or phone already exist'
      const errorCreatePersonState: UnitActorInvitationSetupType = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: message,
      }
      dispatch(
        unitActorInvitationSetupSlice.actions.errorWhileSendingUnitAdminInvitations(
          errorCreatePersonState
        )
      )
    }
  }

export const resetUnitAdminAdditionReducerState =
  () => (dispatch: AppDispatch) => {
    dispatch(unitActorInvitationSetupSlice.actions.resetState(initialState))
  }

export default unitActorInvitationSetupSlice.reducer

async function getTransactionObject(
  input: UnitMemberRelation[]
): Promise<R4.IBundle | undefined> {
  const mainOrganization: R4.IOrganization | undefined =
    getUserDetails()?.mainOrganization
  const currentUserPractRole: R4.IPractitionerRole | undefined =
    getUserDetails()?.practitionerRole
  if (
    mainOrganization &&
    mainOrganization.id &&
    currentUserPractRole &&
    currentUserPractRole.id
  ) {
    const entries: R4.IBundle_Entry[] = []
    const roleMergedData: UnitMemberDetailsCombined[] = getCombinedRoles(input)
    // Check for existing practitioner role with for email and

    await Promise.all(
      roleMergedData.map(async (e) => {
        const practId: string = e.existingUserPractitionerId ?? e.id
        let existingPractRole: R4.IPractitionerRole | undefined
        if (e.existingUserPractitionerId) {
          existingPractRole =
            await getPractitionerRoleOfPractitionerForSpecificUnit(
              e.unitOrganization.id!,
              e.existingUserPractitionerId
            )
        }
        const practRoleId: string =
          existingPractRole !== undefined
            ? existingPractRole.id!
            : getUniqueTempId()
        // get common records and create single practitioner role for it

        const taskObject: R4.ITask[] = getTaskObjectForProviderInvitation(
          'unit',
          e.practitionerEmail,
          e.practitionerPhoneNumber,
          {
            type: 'PractitionerRole',
            reference: `${'PractitionerRole/'}${practRoleId}`,
          },
          {
            type: 'PractitionerRole',
            id: currentUserPractRole.id,
            reference: `${'PractitionerRole/'}${currentUserPractRole.id}`,
          },
          e.roles?.some((t) => t.code === 'unit-admin'),
          e.roles?.some((t) => t.code === 'org-admin')
        )

        const practObject: R4.IPractitioner = e.isSelf
          ? getCurrentUserPractitionerDetails()
          : getPractitionerObject(
              practId,
              e.practitionerName,
              e.practitionerEmail,
              e.practitionerPhoneNumber,
              mainOrganization
            )

        const practRoleObject: R4.IPractitionerRole =
          existingPractRole === undefined
            ? getPractitionerRoleObject(
                practId,
                practRoleId,
                e.roles,
                e.practitionerName,
                e.practitionerEmail,
                e.practitionerPhoneNumber,
                e.unitOrganization
              )
            : {
                ...existingPractRole,
                code: [
                  ...(existingPractRole.code ?? []),
                  ...e.roles.map(
                    (role) =>
                      ({
                        coding: role,
                      } as R4.ICodeableConcept)
                  ),
                ],
              }
        const practEntry: R4.IBundle_Entry = {
          request: {
            url: practObject.resourceType,
            method: R4.Bundle_RequestMethodKind._post,
          },
          resource: practObject,
        }
        const practRoleEntry: R4.IBundle_Entry = {
          request: {
            url:
              existingPractRole === undefined
                ? practRoleObject.resourceType
                : `${practRoleObject.resourceType}/${practRoleObject.id}`,
            method:
              existingPractRole === undefined
                ? R4.Bundle_RequestMethodKind._post
                : R4.Bundle_RequestMethodKind._put,
          },
          resource: practRoleObject,
        }
        taskObject.forEach((task) => {
          const taskEntry: R4.IBundle_Entry = {
            request: {
              url: task.resourceType,
              method: R4.Bundle_RequestMethodKind._post,
            },
            resource: task,
          }
          entries.push(taskEntry)
        })
        /* const taskEntry: R4.IBundle_Entry = {
              request: {
                url: taskObject.resourceType,
                method: R4.Bundle_RequestMethodKind._post,
              },
              resource: taskObject,
            } */
        if (!e.existingUserPractitionerId) {
          entries.push(practEntry)
        }

        entries.push(practRoleEntry)
      })
    )

    const bundleObject: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: entries,
    }

    return bundleObject
  }
  return undefined
}
