import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Tab, Tabs } from '@material-ui/core'
import { kBackgroundDefault } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirLabOrderFullDetail } from 'models/fhirLabOrderFullDetails'
import { MedicalEvent, MedicalEventType } from 'models/medical_event'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { requestAvailableAppointmentsOfPatient } from 'redux/patientMedicalHistory/patientMedicalTimeLine/patientMedicalTimeLineSlice'
import { RootState } from 'redux/rootReducer'
import SimpleBar from 'simplebar-react'
import SocketContext from 'views/contexts/socketMessageContext'
import { WelloLoadingIndicator } from 'wello-web-components'
import { PatientMedicalEventDetail } from './medical_events_detail'
import { PatientMedicalEventTile } from './patient_medical_event_tile'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ overflow: 'auto', height: '100%' }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

interface MedicationsListProps {
  patient: R4.IPatient
  fhirPatientDetail: FhirAppointmentDetail
}

export const PatientEventsList: React.FC<MedicationsListProps> = ({
  patient,
  fhirPatientDetail,
}: MedicationsListProps) => {
  const initialFetch = useRef(true)

  const [value, setValue] = React.useState(0)

  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const [selectedMedicalEvent, setSelectedMedicalEvent] =
    useState<MedicalEvent>()

  const patientMedicalTimeLineSlice = useSelector(
    (state: RootState) => state.patientMedicalTimeLineSlice
  )

  const { eventData } = useContext(SocketContext)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedMedicalEvent(undefined)
    setValue(newValue)
  }

  useEffect(() => {
    const convertedData = eventData as any
    console.log(JSON.stringify(convertedData))
    console.log(convertedData)
    if (convertedData !== null) {
      dispatch(
        requestAvailableAppointmentsOfPatient(patient, fhirPatientDetail)
      )
      if (convertedData.infoMessages && convertedData.infoMessages.length > 0) {
        dispatch(showSuccessAlert(convertedData.infoMessages[0]))
      }
      if (
        convertedData.errorMessages &&
        convertedData.errorMessages.length > 0
      ) {
        dispatch(showErrorAlert(convertedData.errorMessages[0]))
      }
    }
  }, [eventData])

  useEffect(() => {
    dispatch(requestAvailableAppointmentsOfPatient(patient, fhirPatientDetail))
  }, [dispatch, patient])

  return (
    <Box
      display='flex'
      flexDirection='column'
      overflow='auto'
      style={{ overflow: 'auto' }}
    >
      <Box
        display='flex'
        flexDirection='row'
        alignContent='space-between'
        bgcolor={kBackgroundDefault}
      >
        <Box display='flex' flexDirection='row'>
          <Tabs
            value={value}
            indicatorColor='primary'
            textColor='primary'
            onChange={handleChange}
            variant='scrollable'
            scrollButtons='auto'
            aria-label='disabled tabs example'
          >
            <Tab label={t('labelCommon:all_records_label')} />
            <Tab label={t('labelCommon:doctor_visits_label')} />
            <Tab label={t('labelCommon:lab_works_label')} />
            <Tab label={t('labelCommon:documents_label')} />
            {/* <Tab label={t('tcam_label')} />
             */}
          </Tabs>
        </Box>

        <Box
          display='flex'
          flexDirection='row'
          flexGrow={1}
          justifyContent='flex-end'
          alignContent='center'
          alignItems='center'
          p={0.25}
        />
        {/* <GroupBySelector />
          <SortBySelector />
          <TextField
            id='txt_search_patient_history'
            label='Search'
            variant='outlined'
            size='small'
          />
        </Box> */}
      </Box>
      <Box style={{ overflow: 'auto' }}>
        <TabPanel value={value} index={0}>
          <Box
            display='flex'
            flexDirection='row'
            flexGrow={1}
            p={1}
            overflow='auto'
            style={{ overflow: 'auto' }}
          >
            <Box
              display='flex'
              flexDirection='column'
              overflow='auto'
              style={{ overflow: 'auto', height: '500px' }}
              width={selectedMedicalEvent === undefined ? '100%' : '25%'}
            >
              <SimpleBar
                style={{
                  height: '100%',
                  overflowX: 'hidden',
                }}
              >
                {patientMedicalTimeLineSlice.searchingAppointments && (
                  <WelloLoadingIndicator />
                )}
                {patientMedicalTimeLineSlice.resultsAvailable &&
                  patientMedicalTimeLineSlice.availableAppointments &&
                  patientMedicalTimeLineSlice.availableAppointments.map((e) => (
                    <Box
                      display='flex'
                      flexDirection='row'
                      flexGrow={1}
                      justifyContent='flex-end'
                      alignContent='center'
                      alignItems='center'
                      py={0.25}
                      key={e.endTime?.toISOString()}
                    >
                      {e.eventType !== MedicalEventType.LAB_REPORT && (
                        <PatientMedicalEventTile
                          medicalEvent={e}
                          isSelected={e.id === selectedMedicalEvent?.id}
                          dense={selectedMedicalEvent !== undefined}
                          onTap={() => {
                            if (e.id === selectedMedicalEvent?.id) {
                              setSelectedMedicalEvent(undefined)
                            } else {
                              setSelectedMedicalEvent(e)
                            }
                          }}
                        />
                      )}
                      {e.eventType === MedicalEventType.LAB_REPORT && (
                        <Box
                          display='flex'
                          flexDirection='row'
                          justifyContent='flex-start'
                          width='100%'
                        >
                          {(e.eventDetails as FhirLabOrderFullDetail)
                            .testAndResults.length > 0 && (
                            <PatientMedicalEventTile
                              medicalEvent={e}
                              isSelected={e.id === selectedMedicalEvent?.id}
                              dense={selectedMedicalEvent !== undefined}
                              onTap={() => {
                                if (e.id === selectedMedicalEvent?.id) {
                                  setSelectedMedicalEvent(undefined)
                                } else {
                                  setSelectedMedicalEvent(e)
                                }
                              }}
                            />
                          )}
                        </Box>
                      )}
                    </Box>
                  ))}
              </SimpleBar>
            </Box>

            {selectedMedicalEvent && (
              <Box display='flex' flexDirection='row' width='75%' py={0.25}>
                <PatientMedicalEventDetail
                  medicalEvent={selectedMedicalEvent}
                />
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box
            display='flex'
            flexDirection='row'
            flexGrow={1}
            p={1}
            overflow='auto'
            style={{ overflow: 'auto' }}
          >
            <Box
              display='flex'
              flexDirection='column'
              overflow='auto'
              style={{ overflow: 'auto', height: '500px' }}
              width={selectedMedicalEvent === undefined ? '100%' : '25%'}
            >
              <SimpleBar
                style={{
                  height: '100%',
                  overflowX: 'hidden',
                }}
              >
                {patientMedicalTimeLineSlice.searchingAppointments && (
                  <WelloLoadingIndicator />
                )}
                {patientMedicalTimeLineSlice.resultsAvailable &&
                  patientMedicalTimeLineSlice.availableAppointments &&
                  [...patientMedicalTimeLineSlice.availableAppointments]
                    .filter(
                      (e) => e.eventType === MedicalEventType.DOCTOR_VISIT
                    )
                    .map((e) => (
                      <Box
                        display='flex'
                        flexDirection='row'
                        flexGrow={1}
                        justifyContent='flex-end'
                        alignContent='center'
                        alignItems='center'
                        py={0.25}
                        key={e.endTime?.toISOString()}
                      >
                        <PatientMedicalEventTile
                          medicalEvent={e}
                          isSelected={e.id === selectedMedicalEvent?.id}
                          dense={selectedMedicalEvent !== undefined}
                          onTap={() => {
                            if (e.id === selectedMedicalEvent?.id) {
                              setSelectedMedicalEvent(undefined)
                            } else {
                              setSelectedMedicalEvent(e)
                            }
                          }}
                        />
                      </Box>
                    ))}
              </SimpleBar>
            </Box>

            {selectedMedicalEvent && (
              <Box display='flex' flexDirection='row' width='75%' py={0.25}>
                <PatientMedicalEventDetail
                  medicalEvent={selectedMedicalEvent}
                />
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box
            display='flex'
            flexDirection='row'
            flexGrow={1}
            p={1}
            overflow='auto'
            style={{ overflow: 'auto' }}
          >
            <Box
              display='flex'
              flexDirection='column'
              overflow='auto'
              style={{ overflow: 'auto', height: '500px' }}
              width={selectedMedicalEvent === undefined ? '100%' : '25%'}
            >
              <SimpleBar
                style={{
                  height: '100%',
                  overflowX: 'hidden',
                }}
              >
                {patientMedicalTimeLineSlice.searchingAppointments && (
                  <WelloLoadingIndicator />
                )}
                {patientMedicalTimeLineSlice.resultsAvailable &&
                  patientMedicalTimeLineSlice.availableAppointments &&
                  [...patientMedicalTimeLineSlice.availableAppointments]
                    .filter((e) => e.eventType === MedicalEventType.LAB_REPORT)
                    .map((e) => (
                      <Box
                        display='flex'
                        flexDirection='row'
                        flexGrow={1}
                        justifyContent='flex-end'
                        alignContent='center'
                        alignItems='center'
                        py={0.25}
                        key={e.endTime?.toISOString()}
                      >
                        <PatientMedicalEventTile
                          medicalEvent={e}
                          isSelected={e.id === selectedMedicalEvent?.id}
                          dense={selectedMedicalEvent !== undefined}
                          onTap={() => {
                            if (e.id === selectedMedicalEvent?.id) {
                              setSelectedMedicalEvent(undefined)
                            } else {
                              setSelectedMedicalEvent(e)
                            }
                          }}
                        />
                      </Box>
                    ))}
              </SimpleBar>
            </Box>

            {selectedMedicalEvent && (
              <Box display='flex' flexDirection='row' width='75%' py={0.25}>
                <PatientMedicalEventDetail
                  medicalEvent={selectedMedicalEvent}
                />
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box
            display='flex'
            flexDirection='row'
            flexGrow={1}
            p={1}
            overflow='auto'
            style={{ overflow: 'auto' }}
          >
            <Box
              display='flex'
              flexDirection='column'
              overflow='auto'
              style={{ overflow: 'auto', height: '500px' }}
              width={selectedMedicalEvent === undefined ? '100%' : '25%'}
            >
              <SimpleBar
                style={{
                  height: '100%',
                  overflowX: 'hidden',
                }}
              >
                {patientMedicalTimeLineSlice.searchingAppointments && (
                  <WelloLoadingIndicator />
                )}
                {patientMedicalTimeLineSlice.resultsAvailable &&
                  patientMedicalTimeLineSlice.availableAppointments &&
                  [...patientMedicalTimeLineSlice.availableAppointments]
                    .filter(
                      (e) => e.eventType === MedicalEventType.DOCUMENT_REFERENCE
                    )
                    .map((e) => (
                      <Box
                        display='flex'
                        flexDirection='row'
                        flexGrow={1}
                        justifyContent='flex-end'
                        alignContent='center'
                        alignItems='center'
                        py={0.25}
                        key={e.endTime?.toISOString()}
                      >
                        <PatientMedicalEventTile
                          medicalEvent={e}
                          isSelected={e.id === selectedMedicalEvent?.id}
                          dense={selectedMedicalEvent !== undefined}
                          onTap={() => {
                            if (e.id === selectedMedicalEvent?.id) {
                              setSelectedMedicalEvent(undefined)
                            } else {
                              setSelectedMedicalEvent(e)
                            }
                          }}
                        />
                      </Box>
                    ))}
              </SimpleBar>
            </Box>

            {selectedMedicalEvent && (
              <Box display='flex' flexDirection='row' width='75%' py={0.25}>
                <PatientMedicalEventDetail
                  medicalEvent={selectedMedicalEvent}
                />
              </Box>
            )}
          </Box>
        </TabPanel>
      </Box>
    </Box>
  )
}
