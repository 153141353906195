/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface PatientProps {
  patient: R4.IPatient
  ipdData?: FhirActiveIPDDetailsForMedicalRole
}

export const SelfDeclaration: React.FC<PatientProps> = ({
  patient,
  ipdData,
}: PatientProps) => {
  const { t } = useTranslation(['common'])

  return (
    <>
      <Box width='100%' flexDirection='column' display='flex'>
        <Box width='100%' display='flex' flexDirection='column'>
          <Box flexDirection='row' display='flex' flexGrow width='100%'>
            <Box py={1}>
              <Typography
                variant='subtitle2'
                style={{ color: '#4D4D4D', textTransform: 'uppercase' }}
              >
                {' '}
                Self Declaration
              </Typography>
            </Box>{' '}
          </Box>
          <Box>
            {ipdData && ipdData.questionResponse && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                width='100%'
                border={4}
                style={{
                  boxShadow: '0px 0px 4px #ccc',
                  margin: '2px 0px',
                  borderRadius: '1px',
                  borderColor: '#F8F8F8',
                  width: '100%',
                  height: 'auto',
                  backgroundColor: '#F8F8F8',
                }}
              >
                <Grid container direction='row' spacing={1}>
                  {ipdData.questionResponse.item && (
                    <Box
                      display='flex'
                      width='100%'
                      flexDirection='row'
                      justifyContent='flex-start'
                      style={{ padding: 0, flexWrap: 'wrap' }}
                    >
                      {ipdData.questionResponse.item.map(
                        (
                          responseItem: R4.IQuestionnaireResponse_Item,
                          index: number
                        ) => (
                          <Box width='100%'>
                            {responseItem.linkId === '1.1' &&
                              responseItem.item && (
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  {responseItem.item.map((qIn, qindex) => (
                                    <Box
                                      key={qIn.linkId}
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Grid
                                        container
                                        direction='row'
                                        id='vitals'
                                        style={{
                                          backgroundColor:
                                            qindex % 2
                                              ? 'white'
                                              : kDialogueBackground,
                                        }}
                                      >
                                        <Grid item xs={6}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={1}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {qIn.text}
                                                </Typography>
                                              </Box>
                                              <Box width='100%'>
                                                <Divider
                                                  orientation='horizontal'
                                                  flexItem
                                                  style={{
                                                    height: '2px',
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              flexGrow={1}
                                              justifyContent='flex-end'
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  height: '30px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>

                                        <Grid item xs={6}>
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            flexGrow={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={1}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {qIn.answer
                                                    ? qIn.answer[0].valueCoding
                                                        ?.display ?? ''
                                                    : ''}
                                                </Typography>
                                              </Box>
                                              <Box width='100%'>
                                                <Divider
                                                  orientation='horizontal'
                                                  flexItem
                                                  style={{
                                                    height: '2px',
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                            <Box
                                              display='flex'
                                              flexGrow={1}
                                              justifyContent='flex-end'
                                            >
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  ))}
                                </Box>
                              )}

                            {responseItem.linkId === '3' &&
                              responseItem.item &&
                              responseItem.item.map((qIn, qindex) => (
                                <Box
                                  key={qIn.linkId}
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  <Grid
                                    container
                                    direction='row'
                                    id='vitals'
                                    style={{
                                      backgroundColor:
                                        qindex % 2
                                          ? 'white'
                                          : kDialogueBackground,
                                    }}
                                  >
                                    <Grid item xs={6}>
                                      <Box
                                        display='flex'
                                        flexDirection='row'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          display='flex'
                                          flexDirection='column'
                                          width='100%'
                                          flexGrow={1}
                                        >
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                          >
                                            <Typography
                                              variant='subtitle2'
                                              color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,

                                                fontWeight: 600,
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              {qIn.text}
                                            </Typography>
                                          </Box>
                                        </Box>
                                        <Box
                                          display='flex'
                                          flexGrow={1}
                                          justifyContent='flex-end'
                                        >
                                          <Divider
                                            orientation='vertical'
                                            flexItem
                                            style={{
                                              height: '30px',

                                              width: '2px',
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    </Grid>

                                    <Grid item xs={6}>
                                      <Box
                                        display='flex'
                                        flexDirection='row'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          display='flex'
                                          flexDirection='column'
                                          width='100%'
                                          flexGrow={1}
                                        >
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                          >
                                            <Typography
                                              variant='subtitle2'
                                              color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,

                                                fontWeight: 600,
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              {qIn.answer
                                                ? qIn.answer[0].valueCoding
                                                    ?.display ?? ''
                                                : ''}
                                            </Typography>
                                          </Box>
                                        </Box>
                                        <Box
                                          display='flex'
                                          flexGrow={1}
                                          justifyContent='flex-end'
                                        >
                                          <Divider
                                            orientation='vertical'
                                            flexItem
                                            style={{
                                              height: '30px',

                                              width: '2px',
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    </Grid>
                                    {/* <Box px={1} width='30%'>
                                          <Typography
                                            variant='body1'
                                            color='primary'
                                          >
                                            {qIn.text}
                                          </Typography>
                                        </Box> */}
                                    {/* <Box px={6}>
                                      <Typography
                                        variant='body1'
                                        color='initial'
                                      >
                                        {qIn.answer
                                          ? qIn.answer[0].valueCoding
                                              ?.display ?? ''
                                          : ''}
                                      </Typography>
                                    </Box> */}
                                  </Grid>
                                </Box>
                              ))}
                          </Box>
                        )
                      )}
                    </Box>
                  )}
                </Grid>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
