import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { requestForTreatmentForAyurveda } from '../treatment/viewAyurvedaTreatment/ayurvedaTreatmentSearchSlice'
import { requestForTreatment } from '../treatment/viewTreatment/treatmentSearchSlice'
import { DeleteTherapyStatus } from './deleteTherapyStatus'

const initialState: DeleteTherapyStatus = {
  adding: false,
  updateSuccessful: false,
  error: false,
  errorMessage: '',
}

const deleteTreatmentSlice = createSlice({
  name: 'deleteTreatmentSlice',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<DeleteTherapyStatus>) {
      state.adding = action.payload.adding
      state.updateSuccessful = action.payload.updateSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetProdcedure(state, action: PayloadAction<DeleteTherapyStatus>) {
      state.adding = initialState.adding
      state.updateSuccessful = initialState.updateSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
    },
  },
})

export const deleteTherapy =
  (
    treatMentRequest: R4.IServiceRequest,
    fhirAppointmentDetail: FhirActiveIPDDetailsForMedicalRole,
    type?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: DeleteTherapyStatus = {
      adding: true,
      updateSuccessful: true,
      error: false,
    }
    dispatch(deleteTreatmentSlice.actions.updateAddVitalsStatus(addingState))

    try {
      const bundleObject: R4.IBundle =
        createBundleRequestForUpdate(treatMentRequest)

      const appointmentType = fhirAppointmentDetail.mainServiceRequest.code
        ? fhirAppointmentDetail.mainServiceRequest.code.coding
          ? fhirAppointmentDetail.mainServiceRequest.code.coding[0].code ?? ''
          : ''
        : ''
      const ipdDayCare: boolean = appointmentType === '304903009'
      const consultation: boolean = appointmentType === '33022008'

      const resource: any = {
        isOpdConsult: consultation,
        isOpdDayCare: ipdDayCare,
        actionBody: bundleObject,
      }

      const enRolClient: EnrolCient = new EnrolCient()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        `ipd/${fhirAppointmentDetail.mainServiceRequest.id}/addIpdCarePlanDetails`,
        resource
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (response.status === 'Updated Care Plans for IPD') {
        addingState = {
          adding: false,
          updateSuccessful: true,
          error: false,
          errorMessage: '',
        }
        dispatch(showSuccessAlert('Treatment Plan deleted Successfully'))
        if (type === undefined) {
          dispatch(
            requestForTreatment(fhirAppointmentDetail.mainServiceRequest.id!)
          )
        } else {
          requestForTreatmentForAyurveda(
            fhirAppointmentDetail.mainServiceRequest.id!
          )
        }

        dispatch(
          deleteTreatmentSlice.actions.updateAddVitalsStatus(addingState)
        )
      } else {
        const errorCreatePersonState: DeleteTherapyStatus = {
          adding: false,
          updateSuccessful: false,
          error: true,
          errorMessage: 'Error while creating patient',
        }
        dispatch(
          deleteTreatmentSlice.actions.updateAddVitalsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: DeleteTherapyStatus = {
        adding: false,
        updateSuccessful: false,
        error: true,
        errorMessage: 'error while adding vitals',
      }
      dispatch(
        deleteTreatmentSlice.actions.updateAddVitalsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetDeleteState = () => (dispatch: AppDispatch) => {
  dispatch(deleteTreatmentSlice.actions.resetProdcedure(initialState))
}

function createBundleRequestForUpdate(
  treatMent: R4.IServiceRequest
): R4.IBundle {
  const Practitioner: R4.IPractitioner = getCurrentUserPractitionerDetails()
  const PractitionerRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()

  const oldUpdatedServiceRequest: R4.IServiceRequest = {
    ...treatMent,
  }
  const oldAppointmentMatchString: string = `W/${JSON.stringify(
    oldUpdatedServiceRequest.meta?.versionId ?? ' '
  )}`
  oldUpdatedServiceRequest.status = 'unknown'
  oldUpdatedServiceRequest.doNotPerform = true
  //   oldUpdatedServiceRequest.reasonCode = {[ coding: notes,]}
  oldUpdatedServiceRequest.performer = [
    {
      display: getNameFromHumanName(Practitioner.name ?? []),
      id: PractitionerRole.id,
      reference: `${PractitionerRole.resourceType}/${PractitionerRole.id}`,
      type: PractitionerRole.resourceType,
    },
  ]

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `${oldUpdatedServiceRequest.resourceType}/${oldUpdatedServiceRequest.id}`,
        request: {
          ifMatch: oldAppointmentMatchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `${oldUpdatedServiceRequest.resourceType}/${oldUpdatedServiceRequest.id}`,
        },
        resource: oldUpdatedServiceRequest,
      },
    ],
  }

  return requestBundle
}

export default deleteTreatmentSlice.reducer
