/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { UmlResponse } from 'models/umlResponse'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addAllergiesDetails,
  resetAddAllergiesState,
} from 'redux/fhirMedicalResources/ipd/addAllergy/addAllergySliceIPD'
import {
  addHabits,
  resetAddHabitState,
} from 'redux/fhirMedicalResources/ipd/addHabits/habitAddSlice'
import { RootState } from 'redux/rootReducer'
import {
  breathe,
  energyLevels,
  excersiseCode,
  intensity,
  listOfHabbits,
  others,
  sleepPattern,
  teaCount,
} from 'utils/constants'
import { validateOthers } from 'utils/patientHelper/patientEditHelper'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloSelectFHIRCodingForSleep } from 'views/components/LeftMenu/WelloSelectFhirCodingHabbit'
import { WelloSelectFHIRCodingWithoutSort } from 'views/components/LeftMenu/WelloSelectFhirCodingWithoutSort'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import {
  RadioRaisedButton,
  WelloLoadingIndicator,
  WelloTextField,
} from 'wello-web-components'
import { AllergyMasterChosser } from '../subjective/allergyMasterChosser'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  onClose: () => void
  onHabbitAdded: () => void
}
export const AddHabits: React.FC<Props> = ({
  open,
  onClose,
  onHabbitAdded,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '45%',
    },
  }))
  const classes = useStyles()

  const [excerciseDuration, setExcersiseDuration] = useState<R4.ICoding>()
  const [excersiseIntensity, setExcersiseIntencity] = useState<R4.ICoding>()
  const [energyLevel, setEnergylevel] = useState<R4.ICoding>()
  const [breatePattern, setBreathePattern] = useState<R4.ICoding>()
  const [other, setOther] = useState<boolean>(false)
  const [otherData, setOtherData] = useState<R4.ICoding>()
  const [sleep, setSleep] = useState<R4.ICoding>()
  const [cofee, setCofee] = useState<R4.ICoding>()
  const [tea, setTea] = useState<R4.ICoding>()
  const [sleepDay, setSleepDay] = useState<R4.ICoding>()

  const dispatch = useDispatch()

  const addHabitSliceIPD = useSelector(
    (state: RootState) => state.addHabitSliceIPD
  )

  function isOtherSelected(): boolean {
    if (other === true && !otherData) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (addHabitSliceIPD.additionSuccessful) {
      onHabbitAdded()
      dispatch(resetAddHabitState())
      setExcersiseDuration(undefined)
      setExcersiseIntencity(undefined)
      setEnergylevel(undefined)
      setBreathePattern(undefined)
      setSleep(undefined)
      setSleepDay(undefined)
      setCofee(undefined)
      setTea(undefined)
      setOther(false)
      setOtherData(undefined)
    }
  }, [dispatch, addHabitSliceIPD, onHabbitAdded])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        dispatch(resetAddHabitState())
        setExcersiseDuration(undefined)
        setExcersiseIntencity(undefined)
        setEnergylevel(undefined)
        setBreathePattern(undefined)
        setSleep(undefined)
        setCofee(undefined)
        setOther(false)
        setTea(undefined)
        setOtherData(undefined)
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Add Habits</DialogTitle>
      <DialogContent dividers={true}>
        {addHabitSliceIPD.adding && (
          <WelloLoadingIndicator message='Adding details' />
        )}

        {!addHabitSliceIPD.adding && (
          <Box marginX={2}>
            <Grid container direction='column' spacing={1} wrap='nowrap'>
              <Grid item>
                {listOfHabbits.map((val) => (
                  <Box display='flex' flexDirection='column' width='100%'>
                    {val.code === '74009-2' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title={val.display ?? ''} />
                        </Box>
                        <Box width='40%'>
                          <WelloSelectFHIRCoding
                            availableCodings={excersiseCode}
                            onChanged={(type) => {
                              setExcersiseDuration(type)
                            }}
                            id='hab_10'
                            textFieldProps={{
                              id: 'hab_10',
                              size: 'small',
                              fullWidth: true,
                            }}
                          />
                        </Box>
                      </Box>
                    )}

                    {val.code === '10000-2' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title='How many cups of coffee do you drink per day' />
                        </Box>
                        <Box width='40%'>
                          <WelloSelectFHIRCoding
                            availableCodings={teaCount}
                            onChanged={(type) => {
                              setCofee(type)
                            }}
                            id='hab_11'
                            textFieldProps={{
                              size: 'small',
                              id: 'hab_11',

                              fullWidth: true,
                            }}
                          />
                        </Box>
                      </Box>
                    )}

                    {val.code === '74008-4' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title={val.display ?? ''} />
                        </Box>
                        <Box width='40%'>
                          <WelloSelectFHIRCodingWithoutSort
                            availableCodings={intensity}
                            onChanged={(type) => {
                              setExcersiseIntencity(type)
                            }}
                            id='hab_12'
                            textFieldProps={{
                              size: 'small',
                              id: 'hab_12',
                              fullWidth: true,
                            }}
                          />
                        </Box>
                      </Box>
                    )}

                    {val.code === '65515-9' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title={val.display ?? ''} />
                        </Box>
                        <Box width='40%'>
                          <WelloSelectFHIRCoding
                            availableCodings={energyLevels}
                            id='hab_13'
                            onChanged={(type) => {
                              setEnergylevel(type)
                            }}
                            textFieldProps={{
                              size: 'small',
                              id: 'hab_13',
                              fullWidth: true,
                            }}
                          />
                        </Box>
                      </Box>
                    )}

                    {val.code === '28148-5' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title={val.display ?? ''} />
                        </Box>
                        <Box width='40%'>
                          <WelloSelectFHIRCoding
                            availableCodings={breathe}
                            id='hab_14'
                            onChanged={(type) => {
                              setBreathePattern(type)
                            }}
                            textFieldProps={{
                              size: 'small',
                              id: 'hab_14',
                              fullWidth: true,
                            }}
                          />
                        </Box>
                      </Box>
                    )}

                    {val.code === '10000-1' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title='How many cups of tea do you drink per day' />
                        </Box>
                        <Box width='40%'>
                          <WelloSelectFHIRCoding
                            availableCodings={teaCount}
                            id='hab_15'
                            onChanged={(type) => {
                              setTea(type)
                            }}
                            textFieldProps={{
                              size: 'small',
                              id: 'hab_15',
                              fullWidth: true,
                            }}
                          />
                        </Box>
                      </Box>
                    )}

                    {val.code === '65968-0' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title={val.display ?? ''} />
                        </Box>
                        <Box width='40%'>
                          <WelloSelectFHIRCodingForSleep
                            availableCodings={sleepPattern}
                            id='hab_16'
                            onChanged={(type) => {
                              setSleep(type)
                            }}
                            textFieldProps={{
                              size: 'small',
                              id: 'hab_16',
                              fullWidth: true,
                            }}
                          />
                        </Box>
                      </Box>
                    )}

                    {val.code === '65561-3' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title={val.display ?? ''} />
                        </Box>
                        <Box width='40%'>
                          <WelloSelectFHIRCodingForSleep
                            id='hab_17'
                            availableCodings={sleepPattern}
                            onChanged={(type) => {
                              setSleepDay(type)
                            }}
                            textFieldProps={{
                              size: 'small',
                              id: 'hab_17',
                              fullWidth: true,
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                ))}
                {/* <Box
                  display='flex'
                  width='100%'
                  flexDirection='row'
                  paddingTop={0.5}
                >
                  <Box width='60%'>
                    <WelloFormItemLabel title='Any Other Habit' />
                  </Box>
                  <Box width='40%'>
                    <WelloTextFieldWithoutTitle
                      textProps={{
                        id: `actor_other`,
                        type: 'text',
                        error:
                          validateOthers(
                            otherData ? otherData!.display ?? '' : '',
                            false
                          ).length > 0,
                        helperText:
                          validateOthers(
                            otherData ? otherData!.display ?? '' : '',
                            false
                          ).length > 0
                            ? `${validateOthers(
                                otherData ? otherData!.display ?? '' : '',
                                false
                              )}`
                            : '',
                        onChange: (changeData) => {
                          setOtherData({
                            code: 'other-habit',
                            system:
                              'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-habit-cs',
                            display: changeData.target.value,
                          })
                        },
                      }}
                    />
                  </Box>
                </Box> */}
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box display='flex' flexDirection='row' paddingRight={3.5}>
          <Button
            onClick={() => {
              onClose()
              setExcersiseDuration(undefined)
              setExcersiseIntencity(undefined)
              setEnergylevel(undefined)
              setBreathePattern(undefined)
              setSleep(undefined)
              setSleepDay(undefined)
              setCofee(undefined)
              setTea(undefined)
              setOther(false)
              setOtherData(undefined)
            }}
            variant='outlined'
            disabled={addHabitSliceIPD.adding}
            id='hab_20'
            disableElevation
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            onClick={() => {
              dispatch(
                addHabits(
                  fhirAppointmentDetails,
                  excerciseDuration,
                  excersiseIntensity,
                  energyLevel,
                  breatePattern,
                  sleep,
                  cofee,
                  tea,
                  sleepDay,
                  otherData
                )
              )
            }}
            id='hab_21'
            variant='contained'
            color='primary'
            disableElevation
            disabled={
              (excerciseDuration === undefined &&
                excersiseIntensity === undefined &&
                energyLevel === undefined &&
                breatePattern === undefined &&
                sleep === undefined &&
                cofee === undefined &&
                sleepDay === undefined &&
                tea === undefined) ||
              addHabitSliceIPD.adding ||
              isOtherSelected() ||
              validateOthers(otherData ? otherData!.display ?? '' : '', false)
                .length > 0
            }
          >
            {t('labelCommon:add')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
