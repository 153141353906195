import { Tooltip } from '@material-ui/core'
import { BodyPartState } from 'models/bodyPartState'
import React from 'react'
import style from '../index.module.css'
import { bodyPost } from './bodySVG'

interface Props {
  selectionItems: BodyPartState[]
  handleClickBody: any
  tooltip: any
}
const BodyAnt = ({ selectionItems, handleClickBody, tooltip }: Props) => {
  function getCurrentDetails(itemId: string) {
    const index = selectionItems.findIndex((e) => e.code === itemId)

    if (index > -1) {
      switch (selectionItems[index].state) {
        case 'high':
          return style.partSelectedHigh
        case 'low':
          return style.partSelectedLow

        case 'moderate':
          return style.partSelectedModerate

        default:
          return style.part
      }
    }
    return style.part
  }

  return (
    <div className={style.human_try}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 375.42 832.97'>
        <g id='Calque_2' data-name='Calque 2'>
          <g id='body_face'>
            {bodyPost.map((membre, index) => (
              <Tooltip key={`${membre}`} title={tooltip ? membre.name : ''}>
                <path
                  className={getCurrentDetails(membre.id)}
                  onClick={() => handleClickBody(membre)}
                  id={membre.id}
                  d={membre.d}
                  // fill={"#b3b3b3"}
                />
              </Tooltip>
            ))}
          </g>
        </g>
      </svg>
    </div>
  )
}

export default BodyAnt
