import { R4 } from '@ahryman40k/ts-fhir-types'

export const SubscriberRelations: R4.ICoding[] = [
  {
    system: 'http://terminology.hl7.org/CodeSystem/subscriber-relationship',
    code: 'child',
    display: 'Child',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/subscriber-relationship',
    code: 'parent',
    display: 'Parent',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/subscriber-relationship',
    code: 'spouse',
    display: 'Spouse',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/subscriber-relationship',
    code: 'other',
    display: 'Other',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
