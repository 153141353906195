/* eslint-disable no-void */
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { cloneDeep } from 'lodash'
import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { getDeliveryCharge } from '../../../../../../utils/medicineHelper/cart-apis'
import { cartTotalAmount } from '../../../../../../models/medicine-delivery/cart/cart-models'
import { cartState } from '../../../../../../models/medicine-delivery/cart/cart-states'

export const DeliveryCharge: React.FC = () => {
  const [cart, setCart] = useRecoilState<any>(cartState)
  useEffect(() => {
    if (!cart || cart.items.length === 0) {
      return
    }
    const total = cartTotalAmount(cart)
    void (async () => {
      const response = await getDeliveryCharge({ amount: total, weight: 1000 })
      const clone = cloneDeep(cart)
      clone.delivery = response.welloDeliveryChargeFee
      setCart(clone)
    })()
  }, [])

  if (!cart || cart.items.length === 0) {
    return <></>
  }
  return (
    <ListItem>
      <ListItemText>
        {' '}
        <Typography variant='subtitle2'>Delivery Charge</Typography>
      </ListItemText>
      <ListItemSecondaryAction>
        <Typography variant='subtitle2'>
          {' '}
          &#x20b9; {cart.delivery.toFixed(2)}
        </Typography>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
