/* eslint-disable no-await-in-loop */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { IHealthcareService } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import {
  GroupedReferralService,
  LabOfferingDetail,
  LabReferralService,
  LabReferralServiceGrouped,
} from 'models/labOfferDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { LabOfferingSearchStatus } from 'redux/lab/offerings/labOfferingSearchHandler/labOfferingSearchStatusTypes'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import {
  getValueNameValueExtensionsOfUrl,
  getValueRefValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import {
  getHvServiceForReferralList,
  getLabOfferingDetailsFromBundle,
} from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { logger } from 'utils/logger'
import { getLoincCodeFromPlanDef } from 'utils/patientHelper/cds_helper'

const initialState: LabOfferingSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const referralLabOfferingSliceMultiple = createSlice({
  name: 'referralLabOfferingSliceMultiple',
  initialState,
  reducers: {
    searchingDoctorDetails(
      state,
      action: PayloadAction<LabOfferingSearchStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false

      state.labOfferings = undefined
      state.healthCareService = undefined
      state.groupedServices = undefined
    },

    searchResults(state, action: PayloadAction<LabOfferingSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.healthCareService = action.payload.healthCareService
      state.labOfferings = action.payload.labOfferings
      state.groupedServices = action.payload.groupedServices
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<LabOfferingSearchStatus>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.healthCareService = undefined
      state.labOfferings = undefined
      state.groupedServices = undefined
    },

    errorWhileSearching(state, action: PayloadAction<LabOfferingSearchStatus>) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.healthCareService = undefined
      state.labOfferings = undefined
      state.groupedServices = undefined
    },

    resetLabOffering(state, action: PayloadAction<LabOfferingSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.healthCareService = undefined
      state.labOfferings = undefined
      state.groupedServices = undefined
    },
  },
})

export const searchLabOfferingsMultiple =
  (
    preAddedLabTes: R4.IPlanDefinition[],
    appointmentId: string,
    searchString?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: LabOfferingSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      referralLabOfferingSliceMultiple.actions.searchingDoctorDetails(state)
    )
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _include: '*',
        status: 'active',
        _count: 100,
        _total: 'accurate',
      }

      if (searchString) searchParameters.identifier = searchString

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/PlanDefinition',
        appointmentId,
        searchParameters
      )
      if (response.entry) {
        if (response.entry.length > 0) {
          let finalService: LabReferralService[] = []
          const labOfferingList: LabOfferingDetail[] =
            getLabOfferingDetailsFromBundle(response, []) ?? []
          for (let i = 0; i < labOfferingList.length; i++) {
            const unitArr: R4.IHealthcareService[] =
              await getHealthCareServices(
                labOfferingList[i].planDefinition,
                appointmentId
              )

            if (unitArr.length > 0) {
              finalService = getHvServiceForReferralList(
                labOfferingList,
                unitArr
              )
            }
          }

          if (finalService.length > 0) {
            finalService.sort((a, b) =>
              (a.name ? (a.name.length > 0 ? a.name : '') : '').localeCompare(
                b.name ? b.name : ''
              )
            )

            console.log(finalService)
            const finalGrouped: GroupedReferralService[] = []
            for (let i = 0; i < preAddedLabTes.length; i++) {
              const hvServiceArray: LabReferralServiceGrouped[] = []
              for (let j = 0; j < finalService.length; j++) {
                if (
                  getLoincCodeFromPlanDef(preAddedLabTes[i]) ===
                  getLoincCodeFromPlanDef(finalService[j].planDefinition)
                ) {
                  const providedByDetails =
                    finalService[j].healthService.providedBy
                  if (
                    providedByDetails &&
                    providedByDetails.reference &&
                    providedByDetails.reference.split('/').length > 0
                  ) {
                    hvServiceArray.push({
                      name: finalService[j].name,
                      healthService: finalService[j].healthService,
                    })
                  }
                }
              }
              if (hvServiceArray.length > 0) {
                hvServiceArray.push({
                  name: 'No Preference',
                  healthService: {
                    resourceType: 'HealthcareService',
                    name: 'No Preference',
                  },
                })
              }

              finalGrouped.push({
                planDef: preAddedLabTes[i],
                healthService: hvServiceArray.sort((a, b) =>
                  (a.name
                    ? a.name.length > 0
                      ? a.name
                      : ''
                    : ''
                  ).localeCompare(b.name ? b.name : '')
                ),
              })
            }
            console.log(
              finalGrouped.sort((a, b) =>
                (a.planDef.title
                  ? a.planDef.title.length > 0
                    ? a.planDef.title
                    : ''
                  : ''
                ).localeCompare(b.planDef.title ? b.planDef.title : '')
              )
            )

            state.labOfferings = labOfferingList
            state.groupedServices = finalGrouped
            state.healthCareService = finalService
            state.resultsAvailable = true
            state.searching = false
            state.error = false
            dispatch(
              referralLabOfferingSliceMultiple.actions.searchResults(state)
            )
            return
          }
          const finalGroupedExtra: GroupedReferralService[] = []
          for (let i = 0; i < preAddedLabTes.length; i++) {
            finalGroupedExtra.push({
              planDef: preAddedLabTes[i],
              healthService: [],
            })
          }
          console.log(
            finalGroupedExtra.sort((a, b) =>
              (a.planDef.title
                ? a.planDef.title.length > 0
                  ? a.planDef.title
                  : ''
                : ''
              ).localeCompare(b.planDef.title ? b.planDef.title : '')
            )
          )

          state.resultsAvailable = true
          state.searching = false
          state.error = false
          state.groupedServices = finalGroupedExtra
          state.noResultsAvailable = false
          dispatch(
            referralLabOfferingSliceMultiple.actions.noDataFoundForSearch(state)
          )
          return
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(
          referralLabOfferingSliceMultiple.actions.noDataFoundForSearch(state)
        )
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = false
      state.noResultsAvailable = true
      dispatch(
        referralLabOfferingSliceMultiple.actions.noDataFoundForSearch(state)
      )
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(
        referralLabOfferingSliceMultiple.actions.errorWhileSearching(state)
      )
    }
  }

export async function getHealthCareServices(
  plan: R4.IPlanDefinition,
  appointmentId: string
): Promise<R4.IHealthcareService[]> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const unitArr: R4.IHealthcareService[] = []
  const unitId: string = getValueRefValueExtensionsOfUrl(
    plan.extension,
    'http://wellopathy.com/fhir/india/core/StructureDefinition/PlanDefinitionManagingOrganization'
  )!
  const response: any = await fhirClient.doGetResourceForAppointment(
    `HealthcareService?service-type=266753000&active=true`,
    appointmentId
  )
  if (response.entry) {
    if (response.entry.length > 0) {
      for (let i = 0; i < response.entry.length; i++) {
        unitArr.push(response.entry[i].resource as R4.IHealthcareService)
      }
    }
  }
  //   const unique = unitArr.filter(
  //     (obj, index) => index === unitArr.findIndex((o) => obj.name === o.name)
  //   )

  const unique = unitArr.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.active === value.active)
  )

  return unitArr
}

export const resetReferralOfferingSearch = () => (dispatch: AppDispatch) => {
  dispatch(
    referralLabOfferingSliceMultiple.actions.resetLabOffering(initialState)
  )
}

export function getPractitionerRoleObject(item: R4.IPractitionerRole) {
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    name: item.practitioner?.display ?? '',
    gender: '',
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: true,
  }
  return val
}

export default referralLabOfferingSliceMultiple.reducer
