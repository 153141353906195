/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Grid, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { PurposeOfUse } from 'models/purposeOfUse'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-vis/dist/style.css'
import { ProprioceptionObservationType } from 'utils/constants/observations/proprioception_observation_codes'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { getStringCodesFromObservations } from 'utils/fhirResoureHelpers/observationHelpers'
import { AddObservationWithNotesForEncounter } from '../observationManagement/addObservationWithNotes'
import { ViewObservations } from '../observationManagement/viewObservations'

interface CharacterRateRespirationExaminationListPTProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  isReadOnly?: boolean
}

export const ProprioceptionExaminationListPT: React.FC<CharacterRateRespirationExaminationListPTProps> =
  ({
    fhirAppointmentDetails,
    isReadOnly = false,
  }: CharacterRateRespirationExaminationListPTProps) => {
    const { t } = useTranslation(['common'])
    const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())
    const [preselectedObservations, setPreSelectedObservations] = useState<
      R4.IObservation[]
    >([])

    return (
      <Grid container direction='column' style={{ padding: 0, margin: 0 }}>
        <Grid container direction='row' style={{ padding: 0, margin: 0 }}>
          <Box display='flex' alignItems='center' flexDirection='row'>
            <Box p={1}>
              <Typography variant='h6' color='primary'>
                {' '}
                Proprioception
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid style={{ padding: '8px' }}>
          <ViewObservations
            patient={fhirAppointmentDetails.patient}
            purposeOfUse={PurposeOfUse.IPDAppointment}
            category='299936005'
            encounterId={fhirAppointmentDetails.mainEncounter!.id!}
            status='completed'
            fetchAgain={fetcAgain ?? 'new'}
            allowEditing={!isReadOnly}
            onObservationFetched={(res) => {
              setPreSelectedObservations(res)
            }}
            id='prop_view'
          />
        </Grid>
        {!isReadOnly && (
          <Grid style={{ padding: '8px' }}>
            <AddObservationWithNotesForEncounter
              onObservationAdded={() => {
                setFetchAgain(getUniqueTempId())
              }}
              searchOptions={ProprioceptionObservationType}
              encounterId={fhirAppointmentDetails.mainEncounter!.id!}
              patient={fhirAppointmentDetails.patient}
              preSelectedCodes={getStringCodesFromObservations(
                preselectedObservations
              )}
              otherCode='other-proprioception-finding'
              observationCategory={[
                {
                  coding: [
                    {
                      system: 'http://snomed.info/sct',
                      code: '299936005',
                      display: 'Proprioception',
                    },
                  ],
                },
              ]}
              id='prop_add'
            />
          </Grid>
        )}
      </Grid>
    )
  }
