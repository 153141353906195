/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core'
import React from 'react'
// import cancelIcon from '../../assets/images/icons/appointment_cancel_icon.svg'
// import rescheduleIcon from '../../assets/images/icons/appointment_reschedule_icon.svg'
// import download from '../../assets/images/icons/download.png'

export interface Props {
  patientName: string
  practitionerName: string
  practitionerSpecialties: string
  timeStartAndEnd: string
  duration: string
  patientPic: string
  showCancelOption?: boolean
  showRescheduleOption?: boolean
  showDownloadInvoice?: boolean
  showStartButton?: boolean
  showMenuOptions?: boolean
  showViewProfile?: boolean
  appointmentType?: string
  onViewProfileClicked: () => void
  onCancelClicked?: () => void
  onRescheduleClicked?: () => void
  onMenuClicked?: () => void
  onCloseClicked?: () => void
  onDownloadInvoiceClicked?: () => void
  primaryButtonName?: string
  paymentStatus: string
}
export const AppointmentActionPopUp: React.FC<Props> = ({
  patientName,
  practitionerName,
  practitionerSpecialties,
  timeStartAndEnd,
  duration,
  patientPic,
  appointmentType,
  showCancelOption = true,
  showRescheduleOption = true,
  showDownloadInvoice = true,
  showStartButton = true,
  showMenuOptions = true,
  showViewProfile = true,
  onViewProfileClicked,
  onCancelClicked,
  onRescheduleClicked,
  onMenuClicked,
  onCloseClicked,
  onDownloadInvoiceClicked,
  primaryButtonName,
  paymentStatus,
}: Props) => (
  <Box maxWidth={480} minWidth={340}>
    <Paper style={{ backgroundColor: '#EDEDED' }}>
      <Box display='flex' flexDirection='column' padding={1}>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={1}>
            {showCancelOption && (
              <Tooltip title='Re-Schedule' aria-label='Re-Schedule'>
                <IconButton onClick={onRescheduleClicked}>
                  <img
                    src={`${process.env.PUBLIC_URL}/appointment_reschedule_icon.svg`}
                    alt='reschedule'
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>

          <Box>
            {showRescheduleOption && (
              <Tooltip title='Cancel' aria-label='Cancel'>
                <IconButton onClick={onCancelClicked}>
                  <img
                    src={`${process.env.PUBLIC_URL}/appointment_cancel_icon.svg`}
                    alt='cancel'
                    style={{
                      filter:
                        'invert(32%) sepia(77%) saturate(2827%) hue-rotate(342deg) brightness(102%) contrast(105%)',
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Box py={0.5}>
            {showDownloadInvoice && (
              <Tooltip title='Download Invoice' aria-label='Download Invoice'>
                <IconButton onClick={onDownloadInvoiceClicked}>
                  <img
                    src={`${process.env.PUBLIC_URL}/download.png`}
                    alt='Download Invoice'
                    height='50%'
                    width='50%'
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {/* <Box>
                <Tooltip title='Close' aria-label='close'>
                  <IconButton onClick={onCloseClicked}>
                    <Close></Close>
                  </IconButton>
                </Tooltip>
              </Box> */}
        </Box>
        <Box display='flex' flexDirection='row' paddingX={1}>
          <Box>
            <Avatar alt={patientName} src={patientPic} />
          </Box>
          <Box flexGrow={1} paddingX={2}>
            <Box display='flex' flexDirection='column'>
              <Box>
                <Typography variant='h5'>{patientName}</Typography>
              </Box>
              <Box display='flex' flexDirection='row' paddingY={0.25}>
                <Typography variant='subtitle2' style={{ opacity: '0.7' }}>
                  {practitionerName}
                </Typography>
                <Typography variant='subtitle2' style={{ opacity: '0.7' }}>
                  {'\u00A0' + ' \u2022 ' + '\u00A0'}
                </Typography>
                <Typography variant='subtitle2' style={{ opacity: '0.7' }}>
                  {practitionerSpecialties}
                </Typography>
              </Box>

              <Box display='flex' flexDirection='row' paddingY={0.25}>
                {appointmentType && (
                  <Typography variant='subtitle2' style={{ opacity: '0.5' }}>
                    {appointmentType ?? ''} {'\u00A0' + ' \u2022 ' + '\u00A0'}
                  </Typography>
                )}
                <Typography variant='subtitle2' style={{ opacity: '0.5' }}>
                  {`${timeStartAndEnd}  `}{' '}
                </Typography>
                {'\u00A0'}
                <Typography variant='subtitle2' style={{ opacity: '0.5' }}>
                  ({duration})
                </Typography>
              </Box>
              <Box display='flex' flexDirection='row' paddingY={0.25}>
                <Typography variant='subtitle2' color='initial'>
                  Status :{' '}
                </Typography>
                <Box paddingLeft={0.5}>
                  <Typography variant='subtitle2' color='primary'>
                    {' '}
                    {`  ${paymentStatus}  `}{' '}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
          paddingX={2}
          paddingBottom={1}
        >
          {showViewProfile && (
            <Button
              variant='contained'
              color='primary'
              onClick={onViewProfileClicked}
              disabled={paymentStatus === 'Pending' || showStartButton}
            >
              {primaryButtonName ?? 'View Profile'}
            </Button>
          )}
        </Box>
      </Box>
    </Paper>
  </Box>
)
