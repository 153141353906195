/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  kDialogueBackground,
  settingsBackgroundColor,
} from 'configs/styles/muiThemes'
import { Package } from 'models/package'
import { PartnerLabTest } from 'models/partnerLabTestPrice'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetCatalogueSearchStatus,
  searchingCatalogueDetails,
} from 'redux/lab/packageSearch/packageSearchSlice'
import { deletingCatalog } from 'redux/lab/Test/addTestCatalougeSlice'
import { RootState } from 'redux/rootReducer'
import { titleCase } from 'utils/fhirResourcesHelper'
import { getGenderList } from 'utils/labHelpers/packageHelper'
import { getPackageBundleDelete } from 'utils/labHelpers/uploadHelper'
import { ConfirmationDialog } from 'views/components/common/confirmationDialog'
import { AddPackageHandler } from './addPackage'
import { EditPackage } from './editPackage'
import { PackageDataTable } from './packageDataTable'

interface Props {
  partnerLabLocation?: R4.ILocation
}

export const PackageList: React.FC<Props> = ({ partnerLabLocation }: Props) => {
  const packageSearchSlice = useSelector(
    (state: RootState) => state.packageSearchSlice
  )
  const dispatch = useDispatch()
  const [partnerLabTest, setPartnerLabTest] = useState<PartnerLabTest[]>([])
  const [showPopup, setShowPopup] = React.useState<boolean>(false)
  const [openPackage, setOpenPackage] = useState(false)
  const [deletePackage, setDeletePackage] = useState(false)
  const showEditPopUp = useRef<boolean>(false)
  const [packageDetail, setPackageDetail] = React.useState<Package>()

  useEffect(() => {
    dispatch(searchingCatalogueDetails())

    return () => {}
  }, [])

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      flexGrow={1}
      height='100%'
      bgcolor={kDialogueBackground}
    >
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        bgcolor={kDialogueBackground}
        justifyContent='flex-end'
        paddingRight={2}
        paddingTop={4.5}
      >
        <Box width='20%'>
          <TextField
            variant='outlined'
            onChange={(event) => {
              dispatch(searchingCatalogueDetails(event.target.value))
            }}
            size='small'
            fullWidth
            autoFocus={true}
            placeholder='Search By Package Name'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box paddingLeft={1}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              dispatch(resetCatalogueSearchStatus())
              setOpenPackage(true)
            }}
            style={{ height: 36 }}
          >
            Add Packages
          </Button>
        </Box>
      </Box>
      <Box
        display='flex'
        flexGrow={1}
        flexDirection='column'
        justifyContent='center'
        alignContent='center'
        overflow='auto'
      >
        {packageSearchSlice.searching && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
          >
            <CircularProgress size={25} />
          </Box>
        )}
        {packageSearchSlice.error && (
          <Typography>
            {' '}
            {packageSearchSlice.errorMessage ?? 'Error while searching'}
          </Typography>
        )}
        {packageSearchSlice.noResultsAvailable && (
          <Box px={1}>
            <Typography> No packages found</Typography>
          </Box>
        )}
      </Box>
      {packageSearchSlice.resultsAvailable && packageSearchSlice.packageList && (
        <Box>
          {packageSearchSlice.packageList.length === 0 && (
            <Box px={1}>
              <Typography> No Packages found</Typography>
            </Box>
          )}

          {packageSearchSlice.packageList.length > 0 && (
            <Box>
              <PackageDataTable
                usersData={packageSearchSlice.packageList}
                onPackageEdit={(dataSetFinal: Package) => {
                  setPackageDetail(dataSetFinal)
                  setShowPopup(true)
                }}
                onDelete={(dataSetFinal: Package) => {
                  setDeletePackage(true)
                  setPackageDetail(dataSetFinal)
                }}
              />
            </Box>
          )}
        </Box>
      )}
      {deletePackage && (
        <ConfirmationDialog
          open={deletePackage}
          displayText='Package will be deleted permanently. Do you want to continue?'
          title='Delete Package'
          doneButtonText='Remove'
          cancelButtonText='Back'
          onClose={() => {
            setDeletePackage(false)
          }}
          onDiscard={() => {
            if (packageDetail) {
              const bundleData = getPackageBundleDelete(packageDetail)

              if (bundleData) {
                dispatch(deletingCatalog(bundleData, '', 'Package'))
              }
            }

            setDeletePackage(false)
          }}
        />
      )}
      <AddPackageHandler
        open={openPackage}
        onContinueClick={() => {
          setOpenPackage(false)
        }}
        onBackClick={() => {
          setOpenPackage(false)
        }}
      />
      {showPopup && (
        <EditPackage
          open={showPopup}
          onDiscard={() => {
            setShowPopup(false)
          }}
          onClose={() => {
            setShowPopup(false)
          }}
          packageDetails={packageDetail}
        />
      )}
    </Box>
  )
}
