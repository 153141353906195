/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios from 'axios'
import { cloneDeep } from 'lodash'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitDetails,
} from 'services/userDetailsService'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { getAccessToken } from 'utils/authHelpers'
import { getVendorPartId } from 'utils/routes_helper'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { delay, sampleBillingAddress, sampleShippingAddress } from '../samples'
import { Address } from '../../models/medicine-delivery/cart/cart-models'
import {
  PaymentRecordRequest,
  PaymentRecordResponse,
  VerifyPaymentRequest,
} from '../../models/medicine-delivery/cart/payment-cart'
import { SalesOrderRequest } from '../../models/medicine-delivery/cart/sales-order-request'
import { SalesOrderResponse } from '../../models/medicine-delivery/cart/sales-order-response'
import { AddressResponse } from '../../models/medicine-delivery/cart/address_response'
import { ContactRequest } from '../../models/medicine-delivery/cart/contact-request'

const ccWorkflowsUrl = process.env.REACT_APP_CC_WORKFLOW_URL

export const vendorId: string | null = getVendorPartId()

const token = getAccessToken() != null ? getAccessToken() : ''
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
}

export const getAddressesSample = async () => {
  await delay()
  const sampleAddress = [sampleShippingAddress, sampleBillingAddress]
  return sampleAddress
}

export const getAddresses = async (id: string, type: string) => {
  if (type === 'clinic') {
    try {
      const url = `${
        process.env.REACT_APP_FHIR_BASE_URL ?? ''
      }/${vendorId}/Organization/${getCurrentUserUnitDetails().id ?? ''}`
      const response = await axios.get<AddressResponse>(url, config)
      if (response.status !== 200) {
        // TODO;
        console.log('API error', response)
        return
      }
      console.log(response)
      const { data } = response
      const addr = data?.address[0] || undefined
      const addressLine1 = addr ? addr.line[0] : ''
      const billing: Address = {
        addressLine1,
        addressLine2: '',
        city: '',
        country: '',
        name: getCurrentUserPractitionerDetails()
          ? getNameFromHumanName(getCurrentUserPractitionerDetails().name ?? [])
          : '',
        pinCode: '',
        state: '',
        isDefault: false,
        type: 'billing',
      }
      const shipping = cloneDeep(billing)
      shipping.isDefault = true
      shipping.type = 'shipping'
      return [shipping, billing]
    } catch (error) {
      console.error(error)
    }
  } else if (type === 'patient') {
    try {
      const url = `${
        process.env.REACT_APP_FHIR_BASE_URL ?? ''
      }/${vendorId}/Patient/${id}`
      const response = await axios.get<AddressResponse>(url, config)
      if (response.status !== 200) {
        // TODO;
        console.log('API error', response)
        return
      }
      console.log(response)
      const { data } = response
      const addr = data?.address[0] || undefined
      const addressLine1 = addr ? addr.line[0] : ''
      const billing: Address = {
        addressLine1,
        addressLine2: '',
        city: '',
        country: '',
        name: `${data.name[0].given[0]} ${data.name[0].family}`,
        pinCode: '',
        state: '',
        isDefault: false,
        type: 'billing',
      }
      const shipping = cloneDeep(billing)
      shipping.isDefault = true
      shipping.type = 'shipping'
      return [shipping, billing]
    } catch (error) {
      console.error(error)
    }
  }

  return []
}

export const isContactExist = async (id: string) => {
  try {
    // id = "1315841000000034012";
    const url = `${ccWorkflowsUrl}inventory/v1/contacts?notes=${id}`
    const response = await axios.get<{ contacts: any[] }>(url, config)
    if (response.status !== 200) {
      console.log('API error', response)
      return false
    }
    const { data } = response
    if (data.contacts.length > 0) {
      return data.contacts[0].contact_id
    }
    return ''
  } catch (error) {
    console.log('Error happened')
    return ''
  }
}

// Sample patientIds: 1227751(used), 48847, 238101, 4239307, 14567, 4852381, 102002, 5462417, 6172740
// sample doctorIds: 13307, 201135, 860781, 961100 ,170706
export const createContact = async (data: ContactRequest) => {
  try {
    const url = `${ccWorkflowsUrl}inventory/v1/contacts`
    const response = await axios.post(url, data, config)
    if (response.status !== 201) {
      console.error('1. createContact')
      return ''
    }
    return response.data.contact.contact_id
  } catch (error) {
    console.error('2.createContact: ', error)
  }
  return ''
}

export const createSalesOrder = async (data: SalesOrderRequest) => {
  try {
    const url = `${ccWorkflowsUrl}inventory/v1/salesorders`
    const response = await axios.post<SalesOrderResponse>(url, data, config)
    // if (response.status !== 201) {
    //   console.error("1. createSalesOrder");
    //   return undefined;
    // }
    return response.data
  } catch (error) {
    console.error('2. createSalesOrder', error)
  }
  return undefined
}

export const createPaymentRecord = async (record: PaymentRecordRequest) => {
  try {
    const url = `${ccWorkflowsUrl}inventory/v1/payment/gateway`
    const response = await axios.post<PaymentRecordResponse>(
      url,
      record,
      config
    )
    // if (response.status !== 200) {
    //   console.error("1. createSalesOrder");
    //   return undefined;
    // }
    return response.data
  } catch (error) {
    console.error('2. createPaymentRecord', error)
  }
  return undefined
}

export const verifyPayment = async (record: VerifyPaymentRequest) => {
  try {
    const url = `${ccWorkflowsUrl}inventory/v1/payment/verify`
    const response = await axios.post<SalesOrderResponse>(url, record, config)
    // if (response.status !== 200) {
    //   console.error("1. verifyPayment");
    //   return undefined;
    // }
    return response.data
  } catch (error) {
    console.error('2. verifyPayment', error)
  }
  return undefined
}

export const getDeliveryCharge = async (data: {
  amount: number
  weight: number
}) => {
  try {
    const url = `${ccWorkflowsUrl}inventory/v1/payment/deliveryCharges`
    const response = await axios.post<{ welloDeliveryChargeFee: number }>(
      url,
      data,
      config
    )
    // if (response.status !== 201) {
    //   console.error("1. verifyPayment");
    //   return undefined;
    // }
    return response.data
  } catch (error) {
    console.error('2. getDeliveryCharge', error)
  }
  return { welloDeliveryChargeFee: 50 }
}

export const sendPaymentLinkToPaitent = async (
  record: PaymentRecordRequest
) => {
  try {
    const url = `${ccWorkflowsUrl}inventory/v1/payment/link`
    const response = await axios.post<PaymentRecordResponse>(
      url,
      record,
      config
    )
    // if (response.status !== 200) {
    //   console.error("1. createSalesOrder");
    //   return undefined;
    // }
    return response.data
  } catch (error) {
    console.error('2. sendPaymentLinkToPaitent', error)
  }
  return undefined
}

export const getAddressesForDisplay = async (id: string) => {
  try {
    const url = `${
      process.env.REACT_APP_FHIR_BASE_URL ?? ''
    }/${vendorId}/Patient/${id}`
    const response = await axios.get<AddressResponse>(url, config)
    if (response.status !== 200) {
      return ''
    }
    console.log(response)
    const { data } = response
    const addr = data?.address[0] || undefined
    const addressLine1 = addr ? addr.line[0] : ''
    return addressLine1
  } catch (error) {
    console.error(error)
  }
}

export const getPatientForDisplay = async (id: string) => {
  try {
    const url = `${
      process.env.REACT_APP_FHIR_BASE_URL ?? ''
    }/${vendorId}/Patient/${id}`
    const response = await axios.get<R4.IPatient>(url, config)
    if (response.status !== 200) {
      return undefined
    }
    console.log(response)
    const { data } = response

    return data
  } catch (error) {
    return undefined
  }
}
