import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Avatar,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { resetState } from 'redux/patient/addPatient/addPatientSlice'
import {
  resetPatientSearchPatient,
  searchPatients,
} from 'redux/patient/patientSearch/patientSearchSlice'
import { RootState } from 'redux/rootReducer'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getAgeOfPatientData,
  getCodeOfSystemFromCodableConceptList,
  getLastNameOfPatient,
  getNameOfPatient,
  getProfilePicPatient,
} from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { isNumber } from 'utils/stringUtils'
import { getTelecomOfPatient } from 'wello-web-components'
import { AddNewPatient } from '../add_new_patient'
import Popup from '../popup'

interface Props {
  id: string
  onNewPatientButtonClicked?: () => void
  onPatientSelected: (selectedSlot: R4.IPatient) => void
}

export const SadhakSearchByText: React.FC<Props> = ({
  id,
  onPatientSelected,
  onNewPatientButtonClicked,
}: Props) => {
  const [value, setValue] = React.useState<R4.IPatient>()
  const [open, setOpen] = React.useState(false)
  const patientsSearchState = useSelector(
    (state: RootState) => state.patientSearchSlice
  )
  const { t } = useTranslation()
  const [openAddPatientPopup, setOpenAddPatientPopup] = useState(false)
  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<R4.IOrganization>(getCurrentUserUnitDetails())
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      currentOrganizationDetails.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''
  const dispatch = useDispatch()

  return (
    <>
      <Autocomplete
        id={`${id}sadhaka_search`}
        open={open}
        loading={patientsSearchState.searching}
        value={value}
        clearOnEscape
        fullWidth
        includeInputInList
        filterOptions={(options, params) => {
          const filtered = options
          filtered.unshift({
            resourceType: 'Patient',
            id: 'new',
            name: [
              {
                use: R4.HumanNameUseKind._official,
                given: [''],
              },
              {
                use: R4.HumanNameUseKind._usual,
                family: '',
                given: [''],
              },
            ],
          })

          return filtered
        }}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setTimeout(() => {})
          } else if (newValue) {
            if (newValue.id === 'new') {
              if (onNewPatientButtonClicked) {
                setOpenAddPatientPopup(true)
              }
            } else {
              setOpen(false)
              if (onPatientSelected) {
                onPatientSelected(newValue)
              }
              setValue(newValue)
            }
          }
        }}
        options={patientsSearchState.patientList ?? []}
        getOptionLabel={(option) => {
          if (option) {
            return getNameOfPatient(option) ?? ''
          }
          return ''
        }}
        selectOnFocus
        clearOnBlur
        onInputChange={(event, newInputValue) => {
          if (value) {
            if (getNameOfPatient(value) !== newInputValue) {
              setOpen(true)

              if (isNumber(newInputValue)) {
                dispatch(searchPatients(newInputValue, ''))
              } else {
                dispatch(searchPatients('', newInputValue))
              }
            } else {
              setOpen(false)
            }
          } else {
            setOpen(true)
            if (newInputValue.length > 0) {
              if (isNumber(newInputValue)) {
                dispatch(searchPatients(newInputValue, ''))
              } else {
                dispatch(searchPatients('', newInputValue))
              }
            }
          }
        }}
        renderOption={(option: R4.IPatient) => {
          if (option.id === 'new') {
            return (
              <Box p={0.5} style={{ flexGrow: 1 }}>
                <Typography color='primary' id={`${id}sadhaka_add_new`}>
                  + Add Sadhaka
                </Typography>
              </Box>
            )
          }
          if (option) {
            return (
              <List
                style={{ flexGrow: 1, padding: 0 }}
                id={`${id}sadhaka_list`}
              >
                <ListItem
                  style={{ flexGrow: 1, padding: 0 }}
                  component='div'
                  id={`sadkhka_card_${option.id}`}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={getNameOfPatient(option) ?? ''}
                      src={getProfilePicPatient(option) ?? ''}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    color='primary'
                    primary={
                      <>
                        <Typography variant='h6' color='primary'>
                          {getNameOfPatient(option) ?? ''}{' '}
                          {getLastNameOfPatient(option)}
                        </Typography>
                      </>
                    }
                    secondary={
                      <Typography
                        component='span'
                        variant='subtitle1'
                        color='textPrimary'
                      >
                        {getTelecomOfPatient(option)}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <ListItemText
                      primary={
                        <>
                          <Typography variant='subtitle1' color='primary'>
                            {getAgeOfPatientData(option)}
                          </Typography>
                        </>
                      }
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            )
          }
          return <div />
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id={`${id}sadhaka_search_text`}
            variant='outlined'
            placeholder='Start Typing Sadkha name or full 10 digit phone number'
            style={{ backgroundColor: 'white', borderRadius: 4 }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {patientsSearchState.searching ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <Popup
        title='Add Sadhaka'
        openPopup={openAddPatientPopup}
        setOpenPopup={setOpenAddPatientPopup}
      >
        {loggedInUnitType === 'Collection Center' && (
          <AddNewPatient
            isLab={true}
            isAppointment={false}
            popup={true}
            onPatientCreated={(
              createdPatient?: R4.IPatient,
              patientId?: string
            ) => {
              dispatch(showSuccessAlert('Sadhka added successfully'))
              logger.info('Created Patient, ', createdPatient)
              if (onPatientSelected && createdPatient) {
                if (patientId) {
                  const oldPatient: R4.IPatient = {
                    ...createdPatient,
                  }
                  const patId: string = patientId.split('/')[1]
                  oldPatient.id = patId
                  onPatientSelected(oldPatient)
                }
              }

              setOpenAddPatientPopup(false)
              setOpen(false)
              /* if (onPatientSelected && createdPatient) {
                    onPatientSelected(createdPatient);
                  } */
            }}
            onError={() => {
              dispatch(
                showErrorAlert('Error while adding patient. Please try later')
              )
              dispatch(resetState())
              dispatch(resetPatientSearchPatient())
              setOpenAddPatientPopup(false)
            }}
            onCancelClicked={() => {
              dispatch(resetState())
              dispatch(resetPatientSearchPatient())
              setOpenAddPatientPopup(false)
            }}
          />
        )}

        {loggedInUnitType === 'Lab' && (
          <AddNewPatient
            isLab={true}
            isAppointment={false}
            popup={true}
            onPatientCreated={(
              createdPatient?: R4.IPatient,
              patientId?: string
            ) => {
              dispatch(showSuccessAlert('Sadhka added successfully'))
              logger.info('Created Patient, ', createdPatient)
              if (onPatientSelected && createdPatient) {
                if (patientId) {
                  const oldPatient: R4.IPatient = {
                    ...createdPatient,
                  }
                  const patId: string = patientId.split('/')[1]
                  oldPatient.id = patId
                  onPatientSelected(oldPatient)
                }
              }

              setOpenAddPatientPopup(false)
              setOpen(false)
              /* if (onPatientSelected && createdPatient) {
            onPatientSelected(createdPatient);
          } */
            }}
            onError={() => {
              dispatch(
                showErrorAlert('Error while adding patient. Please try later')
              )
              dispatch(resetState())
              dispatch(resetPatientSearchPatient())
              setOpenAddPatientPopup(false)
            }}
            onCancelClicked={() => {
              dispatch(resetState())
              dispatch(resetPatientSearchPatient())
              setOpenAddPatientPopup(false)
            }}
          />
        )}

        {loggedInUnitType === 'Clinic' && (
          <AddNewPatient
            isLab={false}
            isAppointment={false}
            popup={true}
            onPatientCreated={(
              createdPatient?: R4.IPatient,
              patientId?: string
            ) => {
              dispatch(showSuccessAlert('Sadhka added successfully'))
              logger.info('Created Patient, ', createdPatient)
              if (onPatientSelected && createdPatient) {
                if (patientId) {
                  const oldPatient: R4.IPatient = {
                    ...createdPatient,
                  }
                  const patId: string = patientId.split('/')[1]
                  oldPatient.id = patId
                  onPatientSelected(oldPatient)
                }
              }

              setOpenAddPatientPopup(false)
              setOpen(false)
              /* if (onPatientSelected && createdPatient) {
              onPatientSelected(createdPatient);
            } */
            }}
            onError={() => {
              dispatch(
                showErrorAlert('Error while adding sadhka. Please try later')
              )
              dispatch(resetState())
              setOpenAddPatientPopup(false)
            }}
            onCancelClicked={() => {
              dispatch(resetState())
              setOpenAddPatientPopup(false)
            }}
          />
        )}

        {loggedInUnitType === 'Wellness Center' && (
          <AddNewPatient
            isLab={false}
            isAppointment={true}
            popup={true}
            onPatientCreated={(
              createdPatient?: R4.IPatient,
              patientId?: string
            ) => {
              dispatch(showSuccessAlert('Sadhka added successfully'))
              logger.info('Created Patient, ', createdPatient)
              if (onPatientSelected && createdPatient) {
                if (patientId) {
                  const oldPatient: R4.IPatient = {
                    ...createdPatient,
                  }
                  const patId: string = patientId.split('/')[1]
                  oldPatient.id = patId
                  onPatientSelected(oldPatient)
                }
              }

              setOpenAddPatientPopup(false)
              setOpen(false)
              /* if (onPatientSelected && createdPatient) {
              onPatientSelected(createdPatient);
            } */
            }}
            onError={() => {
              dispatch(
                showErrorAlert('Error while adding sadhka. Please try later')
              )
              dispatch(resetState())
              setOpenAddPatientPopup(false)
            }}
            onCancelClicked={() => {
              dispatch(resetState())
              setOpenAddPatientPopup(false)
            }}
          />
        )}
      </Popup>
    </>
  )
}
