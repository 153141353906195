/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/react-in-jsx-scope */
import { Box } from '@material-ui/core'
import { CohortDataStage, ListItem } from 'models/chartProps'
import React, { useEffect, useState } from 'react'

type VisibleStageSelectProps = {
  label: string
  filterMap: any
  visibleStages: string[]
  handleStageFilterChange: any
  filters: any
}
export const DynamicFilterSelect: React.FC<VisibleStageSelectProps> = ({
  label,
  filterMap,
  visibleStages,
  handleStageFilterChange,
  filters,
}: VisibleStageSelectProps) => {
  console.log('455')

  return (
    <Box width='100%' display='flex' flexDirection='column'>
      <label htmlFor='filters'>{label}</label>
      <Box width='100%' display='flex' flexDirection='row' paddingRight={1}>
        {filterMap && visibleStages
          ? visibleStages.map((key: string, i: number) => {
              const value: ListItem[] | undefined = filterMap[key]
              return value ? (
                <Box
                  display='flex'
                  flexDirection='column'
                  width={key === 'treatment' ? '60%' : '25%'}
                  paddingRight={1}
                >
                  <select
                    multiple
                    name={key}
                    onChange={handleStageFilterChange}
                    value={filters[key]}
                    key={i}
                    title={`${i} ${key}`}
                  >
                    {value.map((item: ListItem) => (
                      <option value={String(item.value)} key={item.index}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </Box>
              ) : (
                ''
              )
            })
          : ''}
      </Box>
    </Box>
  )
}
