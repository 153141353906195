import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { kDialogueBackground, kPrimaryMain } from 'configs/styles/muiThemes'
import CloseIcon from '@mui/icons-material/Close'
import _ from 'lodash'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import {
  WelloAyurvedaMedication,
  WelloMedication,
} from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import {
  getTimeOfDayDisplayForStatement,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import { isPastDate } from 'utils/fhirResoureHelpers/ipdDietHelper'
import {
  isFutureDate,
  isFutureDateForComplete,
} from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import OverflowTypography from '../consultation/plan/ayurveda/overflowTypography'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from '../common/deleteConfirmationDialog'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '4px',
      margin: '0px',
    },
  },
})

interface MedicationsListProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  medicationsList: WelloMedication[]
  onRepeat: (medication: WelloMedication) => void
  onDelete: (medication: WelloMedication) => void
  onEditClicked: (medication: WelloMedication) => void

  displayStartEndDates?: boolean
  checked?: boolean
  split?: boolean
}

export const AyurvedaMedicationsListIPD: React.FC<MedicationsListProps> = ({
  fhirAppointmentDetails,
  medicationsList,
  onRepeat,
  onDelete,
  onEditClicked,
  displayStartEndDates = false,
  checked,
  split,
}: MedicationsListProps) => {
  const classes = useStyles()
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  const checkOutDate = moment(
    fhirAppointmentDetails.mainServiceRequest.occurrencePeriod?.end
  ).format('YYYY-MM-DD')

  return (
    <TableContainer
      style={{
        width: '100%',
        margin: '0px',
        padding: '0px',
      }}
    >
      <Table className={classes.table} aria-label='simple table'>
        <TableHead
          style={{
            backgroundColor: kDialogueBackground,
          }}
        >
          <TableRow
            style={{
              fontWeight: 'bold',
            }}
          >
            <TableCell width={20}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Oushadhi (Medication)
              </Typography>
            </TableCell>
            <TableCell
              width={8}
              style={{
                minWidth: '50px',
              }}
            >
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Dose
              </Typography>
            </TableCell>
            <TableCell width={20}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Route of Administration
              </Typography>
            </TableCell>
            <TableCell width={20}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Time of Day
              </Typography>
            </TableCell>
            <TableCell width={20}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Oushadhakala
              </Typography>
            </TableCell>
            <TableCell width={10}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Anupana
              </Typography>
            </TableCell>
            <TableCell width={8}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Dose
              </Typography>
            </TableCell>
            {displayStartEndDates && (
              <TableCell width={8}>
                <Typography
                  variant='subtitle2'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Start from
                </Typography>
              </TableCell>
            )}
            {displayStartEndDates && (
              <TableCell width={8}>
                <Typography
                  variant='subtitle2'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Till
                </Typography>
              </TableCell>
            )}
            <TableCell width={12}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Additional Notes
              </Typography>
            </TableCell>
            <TableCell width={12}>
              <Typography>{}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {medicationsList.map(
            (row: WelloAyurvedaMedication, index: number) => (
              <TableRow
                key={row.id ?? row.medicationCode?.code ?? row.code ?? ''}
                style={{
                  backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f5',
                }}
              >
                <TableCell width={20}>
                  <Typography
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      fontWeight: 600,
                      lineHeight: 'normal',
                      textTransform: 'capitalize',
                      maxWidth: '100px',
                      minWidth: '100px',
                    }}
                  >
                    {_.capitalize(
                      row.medicationCode
                        ? row.medicationCode?.display ?? row.code ?? ''
                        : ''
                    )}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,

                      fontWeight: 600,
                      lineHeight: 'normal',
                    }}
                  >
                    {row.dosageQuantity ? row.dosageQuantity?.toFixed(0) : ''}{' '}
                    {getUnitOfDosageFromRequest(row.dosageQuantityType ?? '')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,

                      fontWeight: 600,
                      lineHeight: 'normal',
                      textTransform: 'capitalize',
                    }}
                  >
                    {row.administrationCode
                      ? getUnitOfRouteRequest(row.administrationCode)
                      : ''}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      width: '85px',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      textTransform: 'capitalize',
                      wordWrap: 'break-word',
                    }}
                  >
                    {getTimeOfDayDisplayForStatement(
                      row.timeOfDayCode ?? []
                    ).join(', ') ?? ''}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,

                      fontWeight: 600,
                      lineHeight: 'normal',
                      textTransform: 'capitalize',
                    }}
                  >
                    {_.capitalize(
                      row.aushadhaKalaCode
                        ? row.aushadhaKalaCode.display ?? ''
                        : ''
                    )}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,

                      fontWeight: 600,
                      lineHeight: 'normal',
                      textTransform: 'capitalize',
                    }}
                  >
                    {_.capitalize(
                      row.anupanaCode ? row.anupanaCode.display ?? '' : ''
                    )}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,

                      fontWeight: 600,
                      lineHeight: 'normal',
                    }}
                  >
                    {row.anupanaDosageQuantity
                      ? row.anupanaDosageQuantity?.toFixed(0)
                      : ''}{' '}
                    {getUnitOfDosageFromRequest(
                      row.anupanaDosageQuantityType ?? ''
                    )}
                  </Typography>
                </TableCell>
                {displayStartEndDates && (
                  <TableCell>
                    <Typography
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        width: '65px',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      {`${moment(row.startFrom).format('DD-MM-YYYY')}`}
                    </Typography>
                  </TableCell>
                )}
                {displayStartEndDates && (
                  <TableCell size='medium' width='50px'>
                    <Typography
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        width: '65px',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      {`${moment(row.till).format('DD-MM-YYYY')}`}
                    </Typography>
                  </TableCell>
                )}
                <TableCell>
                  <OverflowTypography
                    text={row.notes ?? ''}
                    typographyProps={{
                      variant: 'body2',
                      color: 'initial',
                    }}
                  />
                </TableCell>
                <TableCell
                  width={12}
                  style={{
                    maxWidth: '100px',
                    minWidth: '100px',
                  }}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    justifyContent='flex-start'
                    alignContent='center'
                    alignItems='center'
                  >
                    {isPastDate(moment(row.date).format('DD-MM-YYYY')) ===
                      false &&
                      moment(row.date).format('YYYY-MM-DD') < checkOutDate && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              size='small'
                              color='primary'
                              onChange={(eve, check) => {
                                if (eve.target.checked === true) {
                                  onRepeat(row)
                                }
                              }}
                              value={checked}
                              disabled={split}
                              id={`repeat${index}`}
                            />
                          }
                          label={
                            <Typography variant='subtitle2'>Repeat</Typography>
                          }
                          labelPlacement='end'
                        />
                      )}
                    {isFutureDateForComplete(
                      moment(row.date).format('YYYY-MM-DD')
                    ) === false &&
                      split === undefined && (
                        <Tooltip
                          title='Delete Medication'
                          id='del_medication_tooltip'
                        >
                          <CloseIcon
                            fontSize='small'
                            // color='primary'
                            style={{
                              cursor: 'pointer',
                              padding: 0,
                              color: kPrimaryMain,
                            }}
                            id={`del${index}`}
                            onClick={() => {
                              setShowDeleteConfirmation({
                                open: true,
                                onContinueActionClicked: () => {
                                  setShowDeleteConfirmation({
                                    open: false,
                                  })

                                  onDelete(row)

                                  // dispatch(
                                  //   deleteTherapy(
                                  //     treatmentData.treatmentPlan,
                                  //     fhirAppointmentDetails
                                  //   )
                                  // )
                                },
                                onCancelActionClicked: () => {
                                  setShowDeleteConfirmation({
                                    open: false,
                                  })
                                },
                                displayText:
                                  'Are you sure you want to delete this record?',
                                continueActionButtonText: 'Delete',
                                cancelActionButtonText: 'Cancel',
                              })
                            }}
                          />
                        </Tooltip>
                      )}
                    {isFutureDateForComplete(
                      moment(row.date).format('YYYY-MM-DD')
                    ) === false &&
                      split === undefined && (
                        <Box paddingLeft={2}>
                          <Tooltip
                            title='Edit Medication'
                            id='manage_medication_tooltip'
                          >
                            <IconButton
                              aria-label='btn_ord_cancel'
                              color='primary'
                              id='manage_medication_ipd'
                              style={{ padding: 0 }}
                              onClick={() => {
                                onEditClicked(row)
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/editVector.png`}
                                alt='view'
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                  </Box>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
      {showDeleteConfirmation.open && (
        <ConfirmationDialog {...showDeleteConfirmation} />
      )}
    </TableContainer>
  )
}
