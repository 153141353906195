import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedCondition, GroupedConditionData } from 'models/groupedCondition'
import { PurposeOfUse } from 'models/purposeOfUse'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { isOrgAdmin } from 'services/userDetailsService'
import {
  getEmailOfPatient,
  getIdentifierValueBySystem,
  getTelecomFromContactPoints,
} from 'utils/fhirResourcesHelper'
import {
  getExpandedAppointmentFromBundle,
  getGroupedConditions,
  getGroupedConditionsForHistory,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getPatientIdentifiersForSearch } from 'utils/fhirResoureHelpers/patientHelpers'
import { logger } from 'utils/logger'
import { MedicalConditionHistoryStatus } from './symptomProfileSearch'

const initialState: MedicalConditionHistoryStatus = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingConditions: false,
}

const symptomProfileSearchSlice = createSlice({
  name: 'symptomProfileSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<MedicalConditionHistoryStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableConditions = action.payload.availableConditions
      state.groupedConditions = action.payload.groupedConditions
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingConditions =
        action.payload.errorWhileSearchingConditions
      state.dateWiseGroupped = action.payload.dateWiseGroupped
    },
  },
})

export const requestSymptomOfPatient =
  (
    selectedPatient: R4.IPatient,
    purposeOfUse: PurposeOfUse,
    split: boolean,
    appointmentId?: string,
    encounterId?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: MedicalConditionHistoryStatus = {
      searchingConditions: true,
      errorWhileSearchingConditions: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(symptomProfileSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const endDate = `${moment(
        moment(moment(moment()).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      )
        .subtract(90, 'days')
        .toISOString()}`

      const currentDate = moment(moment().format('DD-MM-YYYY'), 'DD-MM-YYYY')
      const convertedEndDate = moment(endDate, 'DD-MM-YYYY')
      const dateForQuery = `${moment(
        moment(moment(moment()).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      )
        .subtract(90, 'days')
        .format('YYYY-MM-DD')}`

      const diffInDays = currentDate.diff(convertedEndDate, 'days')
      let diffInMonths = currentDate.diff(convertedEndDate, 'months')
      const diffInYears = currentDate.diff(convertedEndDate, 'years')

      if (diffInMonths === 2) {
        diffInMonths += 1
      }
      const searchParameters: any = {
        _count: 500,
        _sort: 'onset-age',
        category: 'C0439655',
      }

      if (getPatientIdentifiersForSearch(selectedPatient).length > 0) {
        searchParameters['patient:Patient.identifier'] =
          getPatientIdentifiersForSearch(selectedPatient)
      }

      let response: any
      if (purposeOfUse === PurposeOfUse.OPDAppointment && !split) {
        response =
          await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
            `/Condition`,
            appointmentId!,
            searchParameters
          )
      } else if (!split) {
        response = await fhirClient.doGetResourceForAppointment(
          `/Condition`,
          '',
          searchParameters
        )
      } else if (isOrgAdmin()) {
        response = await fhirClient.doGetResourceForAppointment(
          `/Condition`,
          '',
          searchParameters
        )
      } else if (purposeOfUse === PurposeOfUse.OPDAppointment && split) {
        response =
          await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
            `/Condition`,
            appointmentId!,
            searchParameters
          )
      } else {
        response =
          await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
            `/Condition`,
            '',
            searchParameters
          )
      }

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingConditions = true
        state.searchingConditions = false

        dispatch(symptomProfileSearchSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp.right
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          let finalData: GroupedCondition[] = []
          if (purposeOfUse === PurposeOfUse.OPDAppointment) {
            finalData = getGroupedConditionsForHistory(
              appointmentResponse.entry?.map(
                (item) => item.resource as R4.ICondition
              ) ?? [],
              encounterId
            )
          } else {
            finalData = getGroupedConditions(
              appointmentResponse.entry?.map(
                (item) => item.resource as R4.ICondition
              ) ?? []
            )
          }

          const finalGroupedData: GroupedConditionData[] = []
          if (finalData.length > 0) {
            for (let i = 0; i < finalData.length; i++) {
              if (finalGroupedData.length === 0) {
                if (finalData[i].recordedDate) {
                  finalGroupedData.push({
                    date: finalData[i].recordedDate!,
                    condition: [finalData[i].condition],
                  })
                }
              } else {
                const indexData = finalGroupedData.findIndex((x) =>
                  moment(x.date).isSame(finalData[i].recordedDate)
                )
                if (indexData > -1) {
                  finalGroupedData[indexData].condition.push(
                    finalData[i].condition
                  )
                } else {
                  finalGroupedData.push({
                    date: finalData[i].recordedDate!,
                    condition: [finalData[i].condition],
                  })
                }
              }

              //   finalGroupedData[indexData].condition.push(finalData[i].condition)
            }
          }

          finalGroupedData.sort((a, b) => moment(b.date).diff(a.date))
          state.dateWiseGroupped = finalGroupedData
          state.resultsAvailable = true
          state.searchingConditions = false
          state.groupedConditions = finalData
          state.availableConditions = appointmentResponse.entry?.map(
            (item) => item.resource as R4.ICondition
          )
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingConditions = false
          dispatch(symptomProfileSearchSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: MedicalConditionHistoryStatus = {
            searchingConditions: false,
            errorWhileSearchingConditions: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            symptomProfileSearchSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: MedicalConditionHistoryStatus = {
        searchingConditions: false,
        errorWhileSearchingConditions: true,
        resultsAvailable: false,
        errorReason: 'Error fetching details',
      }
      dispatch(
        symptomProfileSearchSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export default symptomProfileSearchSlice.reducer
