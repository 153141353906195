import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { CloseSharp } from '@material-ui/icons'
import React from 'react'
import {
  getLastNameOfPatient,
  getNameOfPatient,
  getProfilePicPatient,
} from 'utils/fhirResourcesHelper'
import {
  //   getProfilePicPatient,
  getTelecomOfPatient,
} from 'wello-web-components'
import { MembershipCheckOfPatient } from '../administration/membership/memberShipCheckerOfPatient'
import { GenderAndAgeTile } from './genderAndAgeTile'

interface Props {
  patient?: R4.IPatient
  hideAge?: boolean
  isSelected?: boolean
  onSelected?: (selectedOptions: string) => void
  displayDelete?: boolean
  onDelete?: () => void
  onSubscriptionDetailsFetched: ({
    isSubscribed,
    coverage,
  }: {
    isSubscribed: boolean
    coverage?: R4.ICoverage
  }) => void
}

export const PatientSmallTile: React.FC<Props> = ({
  patient,
  hideAge = true,
  isSelected,
  onSelected,
  displayDelete,
  onDelete,
  onSubscriptionDetailsFetched,
}: Props) => (
  <Box width='100%'>
    {patient && (
      <Card
        elevation={0}
        style={{
          cursor: 'pointer',
          padding: 0,
          margin: 0,
        }}
      >
        <CardContent
          style={{
            cursor: 'pointer',
            padding: '2px',
            margin: 0,
          }}
        >
          <List id='patientList_455' style={{ flexGrow: 1, padding: 0 }}>
            <ListItem style={{ flexGrow: 1, padding: 0 }}>
              <ListItemAvatar>
                <Avatar
                  alt={getNameOfPatient(patient) ?? ''}
                  src={getProfilePicPatient(patient) ?? ''}
                />
              </ListItemAvatar>
              <ListItemText
                color='primary'
                primary={
                  <>
                    <Box width='75%' display='flex' flexDirection='row'>
                      <Typography
                        variant='body1'
                        color='primary'
                        style={{
                          fontWeight: 'bolder',
                          wordWrap: 'break-word',
                        }}
                      >
                        {`${getNameOfPatient(patient) ?? ''} ${
                          getLastNameOfPatient(patient) ?? ''
                        }`}
                      </Typography>

                      <MembershipCheckOfPatient
                        patientResource={patient}
                        display={true}
                        onSubscriptionDetailsFetched={({
                          isSubscribed,
                          coverage,
                        }: {
                          isSubscribed: boolean
                          coverage?: R4.ICoverage | undefined
                        }) => {
                          onSubscriptionDetailsFetched({
                            isSubscribed,
                            coverage,
                          })
                        }}
                      />
                    </Box>
                  </>
                }
                secondary={
                  <Box display='flex' flexDirection='column'>
                    <Typography
                      component='span'
                      variant='subtitle2'
                      color='textPrimary'
                    >
                      {getTelecomOfPatient(patient)}
                    </Typography>

                    <Typography variant='subtitle2' color='primary'>
                      {isSelected ? 'Primary Contact' : ''}
                    </Typography>
                  </Box>
                }
              />
              <ListItemSecondaryAction>
                {!hideAge && (
                  <ListItemText
                    primary={
                      <GenderAndAgeTile patient={patient} status={false} />
                    }
                  />
                )}
                {displayDelete && (
                  <IconButton aria-label='remove patient' onClick={onDelete}>
                    <CloseSharp />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    )}
  </Box>
)
