import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { requestForSchedules } from 'redux/schedule/searchSchedule/SearchProviderSchedule'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { ScheduleDetail } from 'models/fhirScheduleDetail'
import { getScheduleBundle } from 'utils/fhirResoureHelpers/fhirWorkingHourHelper'

import { ScheduleAddStatus } from './scheduleAddStatusTypes'

const initialState: ScheduleAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addAvailabilitySlice = createSlice({
  name: 'addSchedule',
  initialState,
  reducers: {
    addingScheduleDetails(state, action: PayloadAction<ScheduleAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
    },

    scheduleDetailsAdded(state, action: PayloadAction<ScheduleAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
    },

    errorInAddingScheduleDetails(
      state,
      action: PayloadAction<ScheduleAddStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetScheduleDetails(state, action: PayloadAction<ScheduleAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },
  },
})

export const addScheduleDetails =
  (
    locationDetails: R4.ILocation,
    scheduleList: any,
    selectedSchedules: ScheduleDetail,
    selectedDoctor: PractitionerWithRole,
    type: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreateScheduleState: ScheduleAddStatus = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(
      addAvailabilitySlice.actions.addingScheduleDetails(
        addingCreateScheduleState
      )
    )
    try {
      const scheduleDetails = getScheduleBundle(
        locationDetails,
        scheduleList,
        selectedSchedules,
        selectedDoctor,
        type
      )
      logger.info(scheduleDetails)
      let response: any | undefined = ''
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      response = await fhirClient.doCreateFHIRTransaction('/', scheduleDetails)
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        logger.info('Added schedule')
        logger.info(' Added Schedule dispatching')
        const successCreateScheduleState: ScheduleAddStatus = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        dispatch(requestForSchedules(selectedDoctor, new Date()))
        dispatch(
          showSuccessAlert(
            `Working hours Created Successfully for ${selectedDoctor.name}`
          )
        )
        return
        //  dispatch(
        //    addAvailabilitySlice.actions.scheduleDetailsAdded(successCreateScheduleState)
        //     );
      }
      logger.info('Not Added Schedule')

      return
    } catch (error) {
      const errorCreateScheduleState: ScheduleAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error ',
      }
      dispatch(
        addAvailabilitySlice.actions.errorInAddingScheduleDetails(
          errorCreateScheduleState
        )
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(addAvailabilitySlice.actions.resetScheduleDetails(initialState))
}

export default addAvailabilitySlice.reducer
