import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@material-ui/core'
import _ from 'lodash'
import {
  getMedicationCategoryUrlFromCode,
  medicationMaps,
} from 'models/AyurvedaUrlCategory'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import {
  WelloAyurvedaMedication,
  WelloMedication,
} from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { RootState } from 'redux/rootReducer'
import {
  getEndTime,
  getRouteOfAdminCoding,
  getStartTime,
  getUnitOfDosageCoding,
} from 'utils/appointment_handle/medications_util'
import { oralUnits, routesOfAdministration } from 'utils/constants'
import { getAddedDate, getTomorrowDate } from 'utils/dateUtil'
import { validateOthers } from 'utils/patientHelper/patientEditHelper'
import {
  addNewAyurvedaMedicationsDetails,
  editMedication,
  editMedicationAyurvedaOPD,
} from 'utils/sopaNotes/plan/ayurvedaMedicationHelpers'
import { ValueSetSelectSingleDisplaySinge } from 'views/components/common/SingleSelectFromDesination'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import { ValueSetSelectSingleDisplayCombined } from 'views/components/common/ValueSetSelectSingleDisplayCombined'
import { AyurvedaMedicationsTempList } from 'views/components/consultation/plan/ayurveda/ayurvedaMedicationsTempList'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloSelect } from 'views/components/LeftMenu/WelloSelect'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { TabbedMultiSelector } from 'views/components/toggle_multiSelector'
import CustomizedDividers from 'views/components/toggle_selector'
import { WelloTextField } from 'wello-web-components'
import anupanas from '../../../../assets/constants/common-anupanas.json'

// import { MedicationTile } from './medication_tile'

interface Props {
  open: boolean
  preselectedMedication: WelloMedication
  fhirAppointmentDetails: FhirAppointmentDetail
  onClose: () => void
  onMedicalConditionAdded: () => void
}
export const EditMedicationAyurvedaOPD: React.FC<Props> = ({
  open,
  preselectedMedication,
  onClose,
  onMedicalConditionAdded,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common', 'medication', 'dosage'])
  const [addedMedications, setAddedMedications] = useState<
    WelloAyurvedaMedication[]
  >([])
  const dispatch = useDispatch()
  const [defaultSubcategoryUrl, setDefaultSubcategoryUrl] = useState<string>()
  const [defaultMedicineCategoryUrl, setDefaultMedicineCategoryUrl] =
    useState<string>(medicationMaps.all_url)

  const [selectedDatePart, setSelectedDatePart] = useState<string>(
    getStartTime(
      preselectedMedication.startFrom
        ? moment(preselectedMedication.startFrom).toDate()
        : new Date()
    )
  )
  const [selectedWeekPart, setSelectedWeekPart] = useState<string>(
    getEndTime(
      preselectedMedication.startFrom
        ? moment(preselectedMedication.startFrom).toDate()
        : new Date(),
      preselectedMedication.till
        ? moment(preselectedMedication.till).toDate()
        : new Date()
    )
  )

  const [addAnother, setAddAnother] = React.useState<boolean>(false)
  const [formState, setFormState] = useState(_.random(0, 109090).toFixed(2))

  const [anchorEl, setAnchorEl] = React.useState(null)

  const [addMore, setAddMore] = useState<boolean>(false)
  const openMenu = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [other, setOther] = useState<boolean>(false)
  console.log(preselectedMedication)

  const [currentMedicationTile, setCurrentMedicationTile] =
    useState<WelloAyurvedaMedication>({
      id: preselectedMedication.medicationRequest
        ? preselectedMedication.medicationRequest.id ?? ''
        : '',
      otherDataText: '',
      base: preselectedMedication.base,
      administrationCode: preselectedMedication.administrationCode
        ? getRouteOfAdminCoding(preselectedMedication.administrationCode ?? '')!
            .code ?? '26643006'
        : '26643006',
      date: preselectedMedication.date,
      medicationDate: preselectedMedication.medicationDate,
      medicationRequest: preselectedMedication.medicationRequest,

      medicationCode: preselectedMedication.medicationCode
        ? preselectedMedication.medicationCode
        : undefined,
      startFrom: preselectedMedication.startFrom
        ? moment(preselectedMedication.startFrom).toDate()
        : new Date(),
      till: preselectedMedication.startFrom
        ? moment(preselectedMedication.till).toDate()
        : getAddedDate(1),
      mealCode: preselectedMedication.mealCode
        ? preselectedMedication.mealCode.toLowerCase()
        : 'before',
      timeOfDayCode: preselectedMedication.timeOfDayCode
        ? preselectedMedication.timeOfDayCode
        : ['morning'],
      dosageQuantity: preselectedMedication.dosageQuantity
        ? preselectedMedication.dosageQuantity
        : undefined,
      timeCodes: preselectedMedication.timeCodes,
      administrationCoding: preselectedMedication.administrationCode
        ? getRouteOfAdminCoding(preselectedMedication.administrationCode ?? '')
        : undefined,
      dosageQuantityTypeCoding: preselectedMedication.dosageQuantityType
        ? getUnitOfDosageCoding(preselectedMedication.dosageQuantityType ?? '')
        : undefined,
      dosageQuantityType: preselectedMedication.dosageQuantityType
        ? preselectedMedication.dosageQuantityType
        : undefined,
      anupanaCode: preselectedMedication.anupanaCode
        ? preselectedMedication.anupanaCode
        : undefined,
      aushadhaKalaCode: preselectedMedication.aushadhaKalaCode
        ? preselectedMedication.aushadhaKalaCode
        : undefined,
      anupanaDosageQuantity: preselectedMedication.anupanaDosageQuantity
        ? preselectedMedication.anupanaDosageQuantity
        : undefined,
      anupanaDosageQuantityType: preselectedMedication.anupanaDosageQuantityType
        ? preselectedMedication.anupanaDosageQuantityType
        : undefined,
      anupanaDosageQuantityTypeCoding:
        preselectedMedication.anupanaDosageQuantityType
          ? getUnitOfDosageCoding(
              preselectedMedication.anupanaDosageQuantityType ?? ''
            )
          : undefined,
      notes: preselectedMedication.notes
        ? preselectedMedication.notes
        : undefined,
    })
  const addMedicationsSlice = useSelector(
    (state: RootState) => state.addMedicationsSlice
  )

  function resetForm() {
    setCurrentMedicationTile({
      otherDataText: '',
      administrationCode: '26643006',
      startFrom: new Date(),
      till: getAddedDate(0),
      mealCode: 'before',
      timeOfDayCode: ['morning'],
    })
    setDefaultSubcategoryUrl(undefined)
    setDefaultMedicineCategoryUrl(medicationMaps.all_url)
    setFormState(_.random(0, 109090).toFixed(2))
    setAddMore(false)
    setSelectedDatePart('today')
  }

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  function addCurrentDetails(pushToSave?: boolean) {
    const tempMedication: WelloAyurvedaMedication[] = [...addedMedications]
    const newMedication: WelloAyurvedaMedication = {
      ...currentMedicationTile,
      // notes: additionalNotes,
    }

    tempMedication.push(newMedication)
    setAddedMedications(tempMedication)
    console.log(tempMedication)
    resetForm()

    if (pushToSave) {
      //   postMedicationDetails(tempMedication)
    }
  }

  function postMedicationDetails() {
    const tempMedication: WelloAyurvedaMedication[] = [...addedMedications]
    const newMedication: WelloAyurvedaMedication = {
      ...currentMedicationTile,
      // notes: additionalNotes,
    }

    tempMedication.push(newMedication)
    setAddedMedications(tempMedication)

    setUpdateStatus({ requesting: true })

    editMedicationAyurvedaOPD(
      fhirAppointmentDetails.encounter?.subject!,
      {
        reference: `Encounter/${fhirAppointmentDetails.encounter!.id}`,
      },
      tempMedication,
      undefined,
      fhirAppointmentDetails
    ).then((e) => {
      if (e) {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Medication added successfully'))
        resetForm()
        setAddedMedications([])
        onMedicalConditionAdded()
        onClose()
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(
          showErrorAlert(
            'Error while adding Medication. Please try again later'
          )
        )
      }
    })
  }

  function isOtherSelected(): boolean {
    if (other === true && !currentMedicationTile.otherDataText) {
      return true
    }
    return false
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setAddedMedications([])
        resetForm()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>
        Edit Oushadhi (Medication)
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#ededed',
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0,
        }}
      >
        <Box
          marginY={1}
          display='flex'
          flexDirection='column'
          width='100%'
          px={1}
          key={formState}
        >
          <Box display='flex' flexDirection='row' width='100%' px={1}>
            {/* <Box px={1} width='15%'>
              <ValueSetSelectSingle
                title='Category'
                url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurvedic-medicine-types-vs'
                id='med_category'
                fuzzySearch={false}
                alwaysShowAllOptions={true}
                preSelectedOptions={currentMedicationTile.medicationCode}
                disabled={false}
                onOptionSelected={(selectedKriya) => {
                  if (selectedKriya) {
                    const res = getMedicationCategoryUrlFromCode(
                      selectedKriya.code ?? ''
                    )
                    if (res) {
                      if (res.leaf_node) {
                        setDefaultSubcategoryUrl(undefined)
                        setDefaultMedicineCategoryUrl(res.category_url)
                      } else {
                        setDefaultSubcategoryUrl(res.category_url)
                      }
                    } else {
                      setDefaultSubcategoryUrl(undefined)
                      setDefaultMedicineCategoryUrl(medicationMaps.all_url)
                    }
                  }
                }}
                helperText={undefined}
              />
            </Box> */}
            {/* 
            <Box px={1} width='15%'>
              <ValueSetSelectSingle
                key={`${defaultSubcategoryUrl}sub_category`}
                title='Sub Category'
                url={`http://wellopathy.com/fhir/india/core/ValueSet/${defaultSubcategoryUrl}`}
                id='med_sub_category'
                fuzzySearch={true}
                alwaysShowAllOptions={true}
                disabled={_.isEmpty(defaultSubcategoryUrl)}
                onOptionSelected={(selectedCategory) => {
                  if (selectedCategory) {
                    const res = getMedicationCategoryUrlFromCode(
                      selectedCategory.code ?? ''
                    )
                    if (res) {
                      setDefaultMedicineCategoryUrl(res?.category_url)
                      return
                    }
                  }

                  setDefaultMedicineCategoryUrl(medicationMaps.all_url)
                }}
                helperText={undefined}
              />
            </Box> */}

            <Box px={1} width='30%' flexGrow={1} display='flex'>
              <ValueSetSelectSingle
                key={`${defaultMedicineCategoryUrl}medicines`}
                title='Select Medication'
                url={`http://wellopathy.com/fhir/india/core/ValueSet/${defaultMedicineCategoryUrl}`}
                id='kriyas'
                fuzzySearch={true}
                preSelectedOptions={currentMedicationTile.medicationCode}
                disabled={false}
                onOptionSelected={(selectedKriya) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    medicationCode: selectedKriya,
                  })
                }}
                helperText={undefined}
              />
            </Box>
            <Box px={1} width='30%'>
              <ValueSetSelectSingleDisplaySinge
                key='wellopathy-relationship-vs_medicines_routes'
                title='Route Of Administration'
                url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-route-of-administration-vs'
                id='route_type'
                fuzzySearch={true}
                alwaysShowAllOptions={true}
                disabled={false}
                onOptionSelected={(conditionsData: R4.ICoding | undefined) => {
                  if (conditionsData) {
                    if (conditionsData.code === '6064005') {
                      setCurrentMedicationTile({
                        ...currentMedicationTile,
                        administrationCode: conditionsData.code,
                        dosageQuantity: undefined,
                        aushadhaKalaCode: undefined,
                        anupanaCode: undefined,
                      })
                    } else {
                      setCurrentMedicationTile({
                        ...currentMedicationTile,
                        administrationCode: conditionsData.code,
                      })
                    }
                  }
                }}
                helperText={undefined}
                preSelectedOptions={
                  currentMedicationTile.administrationCoding ?? {
                    system: 'http://snomed.info/sct',
                    code: '26643006',
                    display: 'Oral (by mouth) ',
                  }
                }
                placeHolder='Route Of Administration'
              />
              {/* <WelloSelectFHIRCoding
                title='Route Of Administration'
                availableCodings={routesOfAdministration}
                id='route_type'
                onChanged={(e) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    administrationCode: e.code ?? '',
                  })
                }}
                preSelectedCoding={{
                  system: 'http://snomed.info/sct',
                  code: '26643006',
                  display: 'Oral',
                }}
                textFieldProps={{
                  size: 'small',
                  fullWidth: true,
                }}
              /> */}
            </Box>

            <Box px={1} width='15%'>
              <WelloTextField
                title={t('labelDosage:resourceName')}
                textProps={{
                  id: `med_dosage`,
                  placeholder: 'Dosage',
                  error:
                    currentMedicationTile.dosageQuantity! <= 0 ||
                    currentMedicationTile.dosageQuantity! >= 10000 ||
                    Number.isNaN(currentMedicationTile.dosageQuantity!),
                  type: 'number',
                  disabled: currentMedicationTile.dosageQuantityType
                    ? currentMedicationTile.dosageQuantityType === '422135004'
                    : currentMedicationTile.administrationCode
                    ? currentMedicationTile.administrationCode === '6064005'
                    : false,
                  value: currentMedicationTile.dosageQuantity,

                  // value: tests.scheduleSummary,
                  helperText:
                    currentMedicationTile.dosageQuantity! <= 0 ||
                    Number.isNaN(currentMedicationTile.dosageQuantity!)
                      ? `${t('labelCommon:invlaid_dosages')}`
                      : '',
                  inputProps: {
                    min: 0,
                    max: 10000,
                    step: 0.1,
                  },

                  //   InputProps: {
                  //     endAdornment: (
                  //       <InputAdornment position='end'>
                  //         {getSuffixForDosage(currentMedicationTile.administrationCode)}
                  //       </InputAdornment>
                  //     ),
                  //   },
                  //   value: tests.testPrice,
                  onChange: (changeData) => {
                    if (changeData) {
                      setCurrentMedicationTile({
                        ...currentMedicationTile,
                        dosageQuantity: parseFloat(
                          parseFloat(changeData.target.value).toFixed(1)
                        ),
                      })
                      /* setSelectedDosage(
                            parseFloat(
                              parseFloat(changeData.target.value).toFixed(1)
                            )
                          ) */
                    }
                  },
                }}
              />
            </Box>

            <Box width='25%' px={1}>
              <ValueSetSelectSingleDisplaySinge
                key='wellopathy-relationship-vs_medicines'
                title='Unit'
                url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-dosage-ucum-units-vs'
                id='med_units'
                fuzzySearch={true}
                disabled={false}
                alwaysShowAllOptions={true}
                onOptionSelected={(conditionsData: R4.ICoding | undefined) => {
                  if (conditionsData) {
                    if (conditionsData.code === '422135004') {
                      setCurrentMedicationTile({
                        ...currentMedicationTile,
                        dosageQuantityType: conditionsData.code,
                        dosageQuantity: undefined,
                        aushadhaKalaCode: undefined,
                        anupanaCode: undefined,
                        anupanaDosageQuantity: undefined,
                      })
                    } else {
                      setCurrentMedicationTile({
                        ...currentMedicationTile,
                        dosageQuantityType: conditionsData.code,
                      })
                    }
                  }
                }}
                helperText={undefined}
                placeHolder='Unit'
                preSelectedOptions={
                  currentMedicationTile.dosageQuantityTypeCoding
                }
              />

              {/* <WelloSelect
                label='Unit'
                availableOptions={oralUnits}
                required={true}
                id='med_units'
                type='Choose Unit'
                displayField='display'
                valueField='code'
                onSelectionChanged={(value: any) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    dosageQuantityType: value.code,
                  })

                  // setDosageType(value.code)
                }}
              /> */}
            </Box>
          </Box>
          <Box display='flex' flexDirection='row' width='100%' px={1}>
            <Box display='flex' flexDirection='row' width='30%' px={1}>
              <ValueSetSelectSingleDisplayCombined
                title='Oushadhakala'
                url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurveda-aushadha-kala-vs'
                id='med_oushadhakala'
                fuzzySearch={true}
                preSelectedOptions={currentMedicationTile.aushadhaKalaCode}
                disabled={
                  currentMedicationTile.dosageQuantityType
                    ? currentMedicationTile.dosageQuantityType === '422135004'
                    : currentMedicationTile.administrationCode
                    ? currentMedicationTile.administrationCode === '6064005'
                    : false
                }
                onOptionSelected={(selectedKriya) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    aushadhaKalaCode: selectedKriya,
                  })
                }}
                helperText={undefined}
              />
              {/* <WelloSelectFHIRCoding
                title='Route Of Administration'
                availableCodings={routesOfAdministration}
                id='route_type'
                onChanged={(e) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    administrationCode: e.code ?? '',
                  })
                }}
                preSelectedCoding={{
                  system: 'http://snomed.info/sct',
                  code: '26643006',
                  display: 'Oral',
                }}
                textFieldProps={{
                  size: 'small',
                  fullWidth: true,
                }}
              /> */}
            </Box>

            {/* <Box px={1} width='20%'>
              <ValueSetSelectSingle
                title='Oushadhakala'
                url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurveda-aushadha-kala-vs'
                id='med_oushadhakala'
                fuzzySearch={true}
                preSelectedOptions={currentMedicationTile.medicationCode}
                disabled={false}
                onOptionSelected={(selectedKriya) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    aushadhaKalaCode: selectedKriya,
                  })
                }}
                helperText={undefined}
              />
            </Box> */}

            <Box px={1} width='30%'>
              <Box display='flex' flexDirection='column' width='100%'>
                <WelloSelectFHIRCoding
                  title='Anupana'
                  onChanged={(selectedKriya) => {
                    setCurrentMedicationTile({
                      ...currentMedicationTile,
                      otherDataText: '',
                      anupanaCode: selectedKriya,
                    })
                  }}
                  preSelectedCoding={currentMedicationTile.anupanaCode}
                  disabled={
                    currentMedicationTile.dosageQuantityType
                      ? currentMedicationTile.dosageQuantityType === '422135004'
                      : currentMedicationTile.administrationCode
                      ? currentMedicationTile.administrationCode === '6064005'
                      : false
                  }
                  availableCodings={anupanas}
                  id='med_anupana'
                  textFieldProps={{
                    size: 'small',
                    fullWidth: true,
                  }}
                />
                {currentMedicationTile.anupanaCode &&
                  currentMedicationTile.anupanaCode.code! === 'C0205394' && (
                    <Box display='flex' width='100%' flexDirection='column'>
                      <WelloFormItemLabel title='Other Anupana' />
                      <WelloTextFieldWithoutTitle
                        textProps={{
                          id: `med_anyOther`,
                          type: 'text',
                          multiline: true,
                          error:
                            validateOthers(
                              currentMedicationTile.anupanaCode.display!,
                              true
                            ).length > 0,
                          disabled: currentMedicationTile.dosageQuantityType
                            ? currentMedicationTile.dosageQuantityType ===
                              '422135004'
                            : currentMedicationTile.administrationCode
                            ? currentMedicationTile.administrationCode ===
                              '6064005'
                            : false,

                          helperText:
                            validateOthers(
                              currentMedicationTile.anupanaCode.display!,
                              true
                            ).length > 0
                              ? `${validateOthers(
                                  currentMedicationTile.anupanaCode.display!,
                                  true
                                )}`
                              : '',
                          onChange: (changeData) => {
                            setCurrentMedicationTile({
                              ...currentMedicationTile,
                              otherDataText: changeData.target.value,
                              anupanaCode: {
                                code: 'C0205394',
                                system:
                                  'http://terminology.hl7.org/CodeSystem/umls',
                                display: changeData.target.value,
                              },
                            })
                          },
                        }}
                      />
                    </Box>
                  )}
              </Box>
            </Box>

            <Box px={1} width='15%'>
              <WelloTextField
                title={t('labelDosage:resourceName')}
                textProps={{
                  id: `anupana_dosage`,
                  placeholder: 'Dosage',
                  error:
                    currentMedicationTile.anupanaDosageQuantity! <= 0 ||
                    currentMedicationTile.anupanaDosageQuantity! >= 10000 ||
                    Number.isNaN(currentMedicationTile.anupanaDosageQuantity!),
                  type: 'number',
                  disabled: currentMedicationTile.dosageQuantityType
                    ? currentMedicationTile.dosageQuantityType === '422135004'
                    : currentMedicationTile.administrationCode
                    ? currentMedicationTile.administrationCode === '6064005'
                    : false,
                  value: currentMedicationTile.anupanaDosageQuantity,
                  helperText:
                    currentMedicationTile.anupanaDosageQuantity! <= 0 ||
                    Number.isNaN(currentMedicationTile.anupanaDosageQuantity!)
                      ? `${t('labelCommon:invlaid_dosages')}`
                      : '',
                  inputProps: {
                    min: 0,
                    max: 10000,
                    step: 0.1,
                  },

                  //   InputProps: {
                  //     endAdornment: (
                  //       <InputAdornment position='end'>
                  //         {getSuffixForDosage(currentMedicationTile.administrationCode)}
                  //       </InputAdornment>
                  //     ),
                  //   },
                  //   value: tests.testPrice,
                  onChange: (changeData) => {
                    if (changeData) {
                      setCurrentMedicationTile({
                        ...currentMedicationTile,
                        anupanaDosageQuantity: parseFloat(
                          parseFloat(changeData.target.value).toFixed(1)
                        ),
                      })
                      /* setSelectedDosage(
                            parseFloat(
                              parseFloat(changeData.target.value).toFixed(1)
                            )
                          ) */
                    }
                  },
                }}
              />
            </Box>

            <Box width='25%' px={1}>
              <ValueSetSelectSingleDisplaySinge
                key='wellopathy-relationship-vs_medicines'
                title='Unit'
                url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-dosage-ucum-units-vs'
                id='anupana_units'
                fuzzySearch={true}
                disabled={
                  currentMedicationTile.dosageQuantityType
                    ? currentMedicationTile.dosageQuantityType === '422135004'
                    : currentMedicationTile.administrationCode
                    ? currentMedicationTile.administrationCode === '6064005'
                    : false
                }
                alwaysShowAllOptions={false}
                onOptionSelected={(conditionsData: R4.ICoding | undefined) => {
                  if (conditionsData) {
                    setCurrentMedicationTile({
                      ...currentMedicationTile,
                      anupanaDosageQuantityType: conditionsData.code,
                    })
                  }
                }}
                helperText={undefined}
                placeHolder='Unit'
                preSelectedOptions={
                  currentMedicationTile.anupanaDosageQuantityTypeCoding
                }
              />
              {/* <WelloSelect
                label='Unit'
                availableOptions={oralUnits}
                required={true}
                id='anupana_units'
                type='Choose Unit'
                displayField='display'
                valueField='code'
                onSelectionChanged={(value: any) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    anupanaDosageQuantityType: value.code,
                  })

                 
                }}
              /> */}
            </Box>
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='space-between'
            px={1}
          >
            <Box display='flex' px={1} width='33%'>
              <TabbedMultiSelector
                size='small'
                preSelectedValues={
                  preselectedMedication.timeOfDayCode ?? ['morning']
                }
                menuLabel='Time of The Day'
                options={[
                  {
                    displayText: `${t('labelCommon:morning')}`,
                    value: 'morning',
                  },
                  {
                    displayText: `${t('labelCommon:afternoon')}`,
                    value: 'afternoon',
                  },
                  {
                    displayText: `${t('labelCommon:Evening')}`,
                    value: 'evening',
                  },
                  {
                    displayText: `${t('labelCommon:night')}`,
                    value: 'night',
                  },
                ]}
                onSelectionChanged={(selectedValue) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    timeOfDayCode: selectedValue,
                  })
                  // setSelectedTimeOfDay(selectedValue)
                }}
              />{' '}
            </Box>
            <Box px={1} width='33%'>
              <CustomizedDividers
                key='_date_part'
                size='small'
                menuLabel={t('labelCommon:start From')}
                options={[
                  {
                    displayText: `${t('labelCommon:today')}`,
                    value: 'today',
                  },
                  {
                    displayText: `${t('labelCommon:tomorrow')}`,
                    value: 'tomo',
                  },
                  {
                    displayText: `${t('labelCommon:custom')}`,
                    value: 'custom',
                  },
                ]}
                onSelectionChanged={(selectedValue) => {
                  setSelectedDatePart(selectedValue)
                  if (selectedValue === 'today') {
                    setCurrentMedicationTile({
                      ...currentMedicationTile,
                      startFrom: new Date(),
                      till: getAddedDate(2),
                    })
                  }
                  if (selectedValue === 'tomo') {
                    setCurrentMedicationTile({
                      ...currentMedicationTile,
                      startFrom: getTomorrowDate(),
                      till: getAddedDate(3),
                    })
                  }
                }}
                preSelectedValue={selectedDatePart}
              />

              {selectedDatePart === 'custom' && (
                <TextField
                  key='custom'
                  variant='outlined'
                  style={{
                    marginTop: '16px',
                  }}
                  size='small'
                  value={
                    currentMedicationTile.startFrom
                      ? moment(currentMedicationTile.startFrom).format(
                          'YYYY-MM-DD'
                        )
                      : moment().format('YYYY-MM-DD')
                  }
                  onChange={(val) => {
                    let tillDateLocal = currentMedicationTile.till

                    // setStartDate(new Date(val.target.value))

                    if (selectedWeekPart === '3d') {
                      if (
                        moment(new Date(val.target.value)).format(
                          'YYYY-MM-DD'
                        ) === moment().format('YYYY-MM-DD')
                      ) {
                        tillDateLocal = moment(
                          moment(new Date(val.target.value)).format(
                            'YYYY-MM-DD'
                          )
                        )
                          .add(2, 'days')
                          .toDate()
                      } else {
                        tillDateLocal = moment(
                          moment(new Date(val.target.value)).format(
                            'YYYY-MM-DD'
                          )
                        )
                          .add(2, 'days')
                          .toDate()
                      }
                    }

                    if (selectedWeekPart === '3d') {
                      if (
                        moment(new Date(val.target.value)).format(
                          'YYYY-MM-DD'
                        ) === moment().format('YYYY-MM-DD')
                      ) {
                        tillDateLocal = moment(
                          moment(new Date(val.target.value)).format(
                            'YYYY-MM-DD'
                          )
                        )
                          .add(2, 'days')
                          .toDate()
                      } else {
                        tillDateLocal = moment(
                          moment(new Date(val.target.value)).format(
                            'YYYY-MM-DD'
                          )
                        )
                          .add(2, 'days')
                          .toDate()
                      }
                    }

                    if (selectedWeekPart === '1W') {
                      if (
                        moment(new Date(val.target.value)).format(
                          'YYYY-MM-DD'
                        ) === moment().format('YYYY-MM-DD')
                      ) {
                        tillDateLocal = moment(
                          moment(new Date(val.target.value)).format(
                            'YYYY-MM-DD'
                          )
                        )
                          .add(6, 'days')
                          .toDate()
                      } else {
                        tillDateLocal = moment(
                          moment(new Date(val.target.value)).format(
                            'YYYY-MM-DD'
                          )
                        )
                          .add(6, 'days')
                          .toDate()
                      }
                    }
                    if (selectedWeekPart === '2W') {
                      if (
                        moment(new Date(val.target.value)).format(
                          'YYYY-MM-DD'
                        ) === moment().format('YYYY-MM-DD')
                      ) {
                        tillDateLocal = moment(
                          moment(new Date(val.target.value)).format(
                            'YYYY-MM-DD'
                          )
                        )
                          .add(13, 'days')
                          .toDate()
                      } else {
                        tillDateLocal = moment(
                          moment(new Date(val.target.value)).format(
                            'YYYY-MM-DD'
                          )
                        )
                          .add(13, 'days')
                          .toDate()
                      }
                    }
                    setCurrentMedicationTile({
                      ...currentMedicationTile,
                      startFrom: new Date(val.target.value),
                      till: tillDateLocal,
                    })
                  }}
                  type='date'
                  inputProps={{
                    min: moment.utc(new Date()).local().format('YYYY-MM-DD'),
                  }}
                />
              )}
            </Box>

            <Box px={1} width='33%'>
              <CustomizedDividers
                key='week_part'
                size='small'
                menuLabel={t('labelCommon:till')}
                options={[
                  {
                    displayText: `${t('labelCommon:3_Days')}`,
                    value: '3d',
                  },
                  {
                    displayText: `${t('labelCommon:1_Week')}`,
                    value: '1W',
                  },
                  {
                    displayText: `2 Weeks`,
                    value: '2W',
                  },
                  {
                    displayText: `${t('labelCommon:custom')}`,
                    value: 'custom',
                  },
                ]}
                onSelectionChanged={(selectedValue) => {
                  let tillDateLocal = currentMedicationTile.till
                  setSelectedWeekPart(selectedValue)
                  if (selectedValue === '3d') {
                    if (
                      moment(currentMedicationTile.startFrom).format(
                        'YYYY-MM-DD'
                      ) === moment().format('YYYY-MM-DD')
                    ) {
                      if (selectedDatePart !== 'custom') {
                        tillDateLocal = getAddedDate(2)
                      }
                    } else if (selectedDatePart !== 'custom') {
                      tillDateLocal = getAddedDate(3)
                    }
                  }
                  if (selectedValue === '1W') {
                    if (
                      moment(currentMedicationTile.startFrom).format(
                        'YYYY-MM-DD'
                      ) === moment().format('YYYY-MM-DD')
                    ) {
                      if (selectedDatePart === 'custom') {
                        tillDateLocal = moment(
                          moment(currentMedicationTile.startFrom).format(
                            'YYYY-MM-DD'
                          )
                        )
                          .add(6, 'days')
                          .toDate()
                      } else {
                        tillDateLocal = getAddedDate(6)
                      }
                    } else if (selectedDatePart === 'custom') {
                      tillDateLocal = moment(
                        moment(currentMedicationTile.startFrom).format(
                          'YYYY-MM-DD'
                        )
                      )
                        .add(6, 'days')
                        .toDate()
                    } else {
                      tillDateLocal = getAddedDate(7)
                    }
                  }
                  if (selectedValue === '2W') {
                    if (
                      moment(currentMedicationTile.startFrom).format(
                        'YYYY-MM-DD'
                      ) === moment().format('YYYY-MM-DD')
                    ) {
                      if (selectedDatePart === 'custom') {
                        tillDateLocal = moment(
                          moment(currentMedicationTile.startFrom).format(
                            'YYYY-MM-DD'
                          )
                        )
                          .add(13, 'days')
                          .toDate()
                      } else {
                        tillDateLocal = getAddedDate(13)
                      }
                    } else if (selectedDatePart === 'custom') {
                      tillDateLocal = moment(
                        moment(currentMedicationTile.startFrom).format(
                          'YYYY-MM-DD'
                        )
                      )
                        .add(13, 'days')
                        .toDate()
                    } else {
                      tillDateLocal = getAddedDate(14)
                    }
                  }
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    till: tillDateLocal,
                  })
                }}
                preSelectedValue={selectedWeekPart}
              />

              {selectedWeekPart === 'custom' && (
                <TextField
                  key='week_custom'
                  variant='outlined'
                  style={{
                    marginTop: '16px',
                  }}
                  value={
                    currentMedicationTile.startFrom
                      ? moment(currentMedicationTile.till).format('YYYY-MM-DD')
                      : moment().format('YYYY-MM-DD')
                  }
                  id='custom_week'
                  size='small'
                  onChange={(val) => {
                    setCurrentMedicationTile({
                      ...currentMedicationTile,
                      till: new Date(val.target.value),
                    })

                    // setTillDate(new Date(val.target.value))
                  }}
                  type='date'
                  inputProps={{
                    min: moment
                      .utc(currentMedicationTile.startFrom!)
                      .local()
                      .format('YYYY-MM-DD'),
                  }}
                />
              )}
            </Box>
          </Box>

          <Box display='flex' flexDirection='column' marginLeft={1}>
            {addedMedications.length > 0 && (
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                px={1}
                py={2}
                pl={1}
              >
                <AyurvedaMedicationsTempList
                  medicationsList={addedMedications}
                />
              </Box>
            )}
          </Box>
          <Box display='flex' flexDirection='row' width='100%' px={1}>
            <Box
              display='flex'
              width='100%'
              flexDirection='column'
              flexGrow={1}
              paddingLeft={1}
            >
              <WelloFormItemLabel title='Additional Notes' />

              <TextField
                size='small'
                fullWidth
                multiline
                type='string'
                value={currentMedicationTile.notes}
                variant='outlined'
                onChange={(event) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    notes: event.target.value,
                  })
                  // setAdditionalNotes(event.target.value)
                }}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Box
          display='flex'
          flexDirection='row'
          py={1}
          paddingRight={1.5}
          paddingLeft={1}
          flexGrow={1}
          alignItems='center'
          justifyContent='flex-end'
        >
          {updateStatus.requesting && (
            <Box
              flexDirection='row'
              display='flex'
              alignSelf='center'
              justifyContent='center'
            >
              <CircularProgress size={25} />
            </Box>
          )}
          {/* <Box flexGrow={1} alignSelf='center' paddingX={1}>
              <FormControlLabel
                value='end'
                control={
                  <Checkbox
                    inputProps={{
                      id: 'add_another',
                    }}
                    color='primary'
                    checked={addAnother}
                    onChange={(eve, checked) => {
                      setAddAnother(checked)
                    }}
                  />
                }
                label={t('labelCommon:add_another_medication')}
                labelPlacement='end'
              />
            </Box> */}
          <Box display='flex' flexDirection='row'>
            <Box paddingX={1}>
              <Button
                onClick={() => {
                  resetForm()
                  setAddedMedications([])
                  onClose()
                }}
                disabled={updateStatus.requesting}
                variant='outlined'
                disableElevation
                id='cancel'
              >
                {t('labelCommon:cancel')}
              </Button>
            </Box>

            <Box paddingX={1}>
              <Button
                onClick={() => {
                  if (
                    currentMedicationTile.medicationCode !== undefined &&
                    currentMedicationTile.dosageQuantity !== undefined &&
                    currentMedicationTile.dosageQuantityType !== undefined
                  ) {
                    postMedicationDetails()
                  }
                }}
                variant='contained'
                color='primary'
                disableElevation
                id='save'
                disabled={
                  currentMedicationTile.medicationCode === undefined ||
                  currentMedicationTile.dosageQuantity === undefined ||
                  currentMedicationTile.dosageQuantity! <= 0 ||
                  currentMedicationTile.dosageQuantityType === undefined ||
                  (currentMedicationTile.timeOfDayCode ?? []).length === 0 ||
                  isOtherSelected() ||
                  addMedicationsSlice.adding ||
                  validateOthers(currentMedicationTile.otherDataText!, false)
                    .length > 0
                }
              >
                {t('labelCommon:Save')}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
