import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Button, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { diagnosticReportDownLoad } from 'redux/lab/diagnosticReportViewerSlice/diagnosticReportViewerSlice'
import {
  getDisplayOfSystemFromCodableConcept,
  getLabTestNames,
} from 'utils/fhirResourcesHelper'
import { getObservationContent } from 'utils/fhirResoureHelpers/observationHelpers'
import { DiagnosticReportPopup } from 'views/components/common/reportViewer/diaognisticReportViewer'

interface IProps {
  diagnosticReport: R4.IDiagnosticReport
  observations: R4.IObservation[]
}

export const MedicalEventLabTestReading: React.FC<IProps> = ({
  diagnosticReport,
  observations,
}: IProps) => {
  const dispatch = useDispatch()
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [documentRef, setDocumentRef] = useState<R4.IDocumentReference>()
  const [documentReport, setDocumentReport] = useState<R4.IDiagnosticReport>()

  return (
    <Box width='100%' display='flex' flexDirection='row'>
      <Box
        width='41%'
        display='flex'
        flexDirection='column'
        justifyContent='center'
      >
        <Typography variant='subtitle1' color='initial'>
          {getLabTestNames(diagnosticReport.code) ?? ''}{' '}
        </Typography>
      </Box>

      <Box display='flex' flexDirection='column' flexGrow width='39%'>
        {observations &&
          observations.map((e) => (
            <Box
              display='flex'
              flexDirection='row'
              key={(diagnosticReport.id ?? '') + (e.id ?? '')}
            >
              <Typography variant='subtitle2' color='initial'>
                {' '}
                {getDisplayOfSystemFromCodableConcept(e.code) ?? ''}{' '}
              </Typography>
              {' -- '}
              <Typography variant='subtitle2' color='initial'>
                {' '}
                {getObservationContent(e) ?? ''}{' '}
              </Typography>
            </Box>
          ))}
      </Box>

      <Box
        display='flex'
        width='20%'
        flexDirection='row-reverse'
        flexGrow
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <Button
          onClick={(e) => {
            setDocumentReport(diagnosticReport)
            setShowPreview(true)
            // dispatch(diagnosticReportDownLoad(diagnosticReport.id ?? ''))
          }}
          color='primary'
        >
          View Report
        </Button>
      </Box>

      {showPreview && documentReport && (
        <DiagnosticReportPopup
          documentRef={documentReport!}
          showPreview={showPreview}
          onCloseClicked={() => {
            setDocumentRef(undefined)
            setShowPreview(false)
          }}
        />
      )}
    </Box>
  )
}
