import { PractitionerWithRole } from 'models/practitionerWithRole'
import {
  getCurrentUserMainOrganizationDetails,
  isOrgAdmin,
  isOrgUser,
} from 'services/userDetailsService'

export function getPractitionersListFourUser(
  inputPractitionerList?: PractitionerWithRole[]
) {
  if (inputPractitionerList) {
    if (isOrgAdmin()) {
      const currentOrgId = getCurrentUserMainOrganizationDetails()?.id
      return inputPractitionerList.filter(
        (practitioner: PractitionerWithRole) =>
          practitioner.roleObject?.organization?.reference?.split('/')[1] ===
          currentOrgId
      )
    }
    return inputPractitionerList ?? []
  }

  return []
}
