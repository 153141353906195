import { R4 } from '@ahryman40k/ts-fhir-types'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { logger } from 'utils/logger'
import { SelectedFile } from 'views/components/LeftMenu/WelloFilePicker'
import { FHIRApiClient } from './fhirApiServices'
import { dataURItoBlob, encodeBlobToBase4 } from './fileServices'

export async function uploadFileDetails(
  file: R4.IAttachment,
  fileStoringPath: string,
  resourceType: string,
  baseResourceId: string,
  encodingNotRequired?: boolean
): Promise<boolean> {
  const fhirApiClient: FHIRApiClient = new FHIRApiClient()

  let contentData = file.data
  if (file.data?.startsWith('data')) {
    contentData = await encodeBlobToBase4(await dataURItoBlob(file.data!))
  }
  const response: any | FHIRErrorResponses =
    await fhirApiClient.doCreateFHIRUploadResourceRequest(
      `/${resourceType}/${baseResourceId}/$binary-access-write?path=${fileStoringPath}`,
      contentData,
      file.contentType ?? ''
    )

  if (response.status) {
    return false
  }
  return true
}

export async function downloadDetails(
  file: R4.IAttachment,
  fileStoringPath: string,
  resourceType: string,
  baseResourceId: string
): Promise<boolean> {
  const fhirApiClient: FHIRApiClient = new FHIRApiClient()

  let contentData = file.data
  if (file.data?.startsWith('data')) {
    contentData = await encodeBlobToBase4(await dataURItoBlob(file.data!))
  }
  const response: any | FHIRErrorResponses =
    await fhirApiClient.doCreateFHIRUploadResourceRequest(
      `/${resourceType}/${baseResourceId}/$binary-access-write?path=${fileStoringPath}`,
      contentData,
      file.contentType ?? ''
    )

  if (response.status) {
    return false
  }
  return true
}

export async function getContentOfResource(
  resourceType: string,
  resourceId: string,
  fileType: string,
  accessPath: string
): Promise<string | undefined> {
  try {
    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const response: any = await fhirClient.doGetResource(
      `/${resourceType}/${resourceId}/$binary-access-read?path=${accessPath}`
    )
    const byteCharacters = atob(response)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    const file = new Blob([byteArray], { type: `${fileType};base64` })

    const fileURL = window.URL.createObjectURL(file)

    return fileURL
  } catch (error) {
    console.error(error)
  }
  return undefined
}

export async function getRelatedPersonId(email: string): Promise<string> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const response: any = await fhirClient.doGetResource(
    `/RelatedPerson?email=${email}`
  )
  const id: string = ''
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const patientResponses: R4.IBundle = relatedFhirDecodeRes.right
    if (patientResponses.total) {
      if (patientResponses.total > 0) {
        if (patientResponses.entry) {
          const patientArray: R4.IRelatedPerson[] = patientResponses.entry.map(
            (item) => item.resource as R4.IRelatedPerson
          )
          sessionStorage.setItem('relatedId', JSON.stringify(patientArray[0]))

          //   if (patientArray.length > 1) {
          //     id = patientArray[0].id!

          //     sessionStorage.setItem('relatedId', patientArray[0].id ?? '')
          //   }
        }
      } else {
        return ''
      }
    }
  } else {
    return ''
  }

  return id
}
