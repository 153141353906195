import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { getExpandedAppointmentFromBundle } from 'utils/common/patientDataTableHelper'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import { UmlClient } from 'services/umlsClient'
import { getUserCurrentRole } from 'services/userDetailsService'
import { ValueSetSearchClient } from 'services/valueSetSearchSlice'
import { RelationSearchStatus } from './familyHistorySearchStatus'

const initialState: RelationSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const relationShipSearchSlice = createSlice({
  name: 'relationShipSearchSlice',
  initialState,
  reducers: {
    searchingCondition(state, action: PayloadAction<RelationSearchStatus>) {},

    searchResults(state, action: PayloadAction<RelationSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.relationShip = action.payload.relationShip
    },

    noDataFoundForSearch(state, action: PayloadAction<RelationSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.relationShip = action.payload.relationShip
    },

    errorWhileSearching(state, action: PayloadAction<RelationSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.relationShip = action.payload.relationShip
    },
    resetState(state, action: PayloadAction<RelationSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.relationShip = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: RelationSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      relationShip: undefined,
    }
    dispatch(relationShipSearchSlice.actions.resetState(state))
  }

export const searchFamilyValueSet =
  (searchString: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: RelationSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      relationShipSearchSlice.actions.errorWhileSearching(errorSearchPatient)
    )
    try {
      const fhirClient: ValueSetSearchClient = new ValueSetSearchClient()

      const response: any = await fhirClient.doGetResource(
        `/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/relatedperson-relationshiptype&filter=${searchString}`
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IValueSet> =
        R4.RTTI_ValueSet.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const valueSets: R4.IValueSet = relatedFhirDecodeRes.right

        const searchPatientResult: RelationSearchStatus = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          relationShip: valueSets.expansion
            ? valueSets.expansion.contains
              ? valueSets.expansion.contains.sort((a, b) =>
                  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
                )
              : []
            : [],
        }
        dispatch(
          relationShipSearchSlice.actions.searchResults(searchPatientResult)
        )
        return
      }

      const errorWhileSearchPatient: RelationSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        relationShipSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: RelationSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        relationShipSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export const resetFamilyHistory = () => (dispatch: AppDispatch) => {
  dispatch(relationShipSearchSlice.actions.resetState(initialState))
}

export default relationShipSearchSlice.reducer
