import { Box, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPDChiefComplaint } from './subjective/IPDchiefComplaintsDetails'
import { MedicalAndHealthHistoryOfPatient } from './subjective/history_details'
import { ReviewOfSystem } from './subjective/review_of_systoms'

interface SubjectivePartOfConsultationProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
}

export const SubjectivePartOfConsultation: React.FC<SubjectivePartOfConsultationProps> =
  ({ fhirAppointmentDetails }: SubjectivePartOfConsultationProps) => {
    const [t] = useTranslation(['en', 'hi', 'labelCommon'])
    const [addButton, showAddButton] = useState<boolean>(false)

    return (
      <Box flexDirection='column' width='101%' py={1}>
        <Box display='flex' width='100%' py={1}>
          <Typography
            style={{
              fontFamily: 'Open Sans',
              fontSize: 18,
              fontWeight: 700,
              lineHeight: 'normal',
            }}
            color='primary'
          >
            {' '}
            Subjective
          </Typography>
        </Box>
        <Box display='flex' width='80%'>
          <IPDChiefComplaint
            fhirAppointmentDetails={fhirAppointmentDetails}
            status={
              fhirAppointmentDetails.mainTask.businessStatus!.coding
                ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                    .code === 'initiate-discharge' ||
                  fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                    .code === 'discharged' ||
                  fhirAppointmentDetails.mainServiceRequest.status! ===
                    'completed'
                  ? 'completed'
                  : ''
                : ''
            }
            splitview={false}
          />
        </Box>
        <Box display='flex' width='80%'>
          <MedicalAndHealthHistoryOfPatient
            fhirAppointmentDetails={fhirAppointmentDetails}
          />
        </Box>
      </Box>
    )
  }
