import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  getCodeOfSystemFromCodableConceptList,
  getDisplayOfSystemFromCodableConcept,
} from 'utils/fhirResourcesHelper'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getTimeOfDayDisplayForStatement,
  getTimeOfDayDisplayMeal,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import {
  getAddressFromServiceRequest,
  getTestNameFromOrderDetails,
} from 'utils/fhirResoureHelpers/referralHelpers'
import { getCommunicationRequestPayloadString } from 'utils/fhirResoureHelpers/communicationRequestHelper'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { Symptoms } from 'models/symptoms'
import { ShowIngredients } from './ShowIngrenient'

interface MedicationTileProps {
  instructions: Symptoms[]
  onDeleteClicked?: () => {}
  onEditCLicked?: () => {}
  prescription?: boolean
}

export const SymptomsList: React.FC<MedicationTileProps> = ({
  instructions,
  onDeleteClicked,
  onEditCLicked,
  prescription,
}: MedicationTileProps) => {
  const [openIngredient, setOpenIngredient] = useState(false)
  const [selectedMedication, setSelectedMedication] = useState<R4.ICoding>()
  const [selectedRxNorm, setSelectedRxNorm] = useState<string>()
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()

  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      //   border={4}
      //   style={{
      //     boxShadow: '0px 0px 4px #ccc',
      //     margin: '2px 0px',
      //     borderRadius: '1px',
      //     borderColor: '#F8F8F8',
      //     width: '100%',
      //     height: 'auto',
      //     backgroundColor: '#F8F8F8',
      //   }}
    >
      <Grid container direction='column' spacing={1}>
        {/* <Grid container direction='row' id='vitals'>
          <Grid item xs={12} style={{ backgroundColor: kDialogueBackground }}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Symptoms
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
       

        {/* ------------------------------------------------------------------------------- */}
        {instructions.map((e, index: number) => (
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            flexGrow={1}
            key={`${e}`}
          >
            <Grid
              container
              direction='row'
              id='vitals'
              //   style={{
              //     backgroundColor:
              //       index % 2 === 0
              //         ? 'white'
              //         : prescription
              //         ? 'white'
              //         : kDialogueBackground,
              //   }}
            >
              <Grid item xs={12}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      px={1}
                      flexDirection='row'
                    >
                      <Box paddingRight={0.5}>
                        <Typography
                          key={`${e.mainSymptoms}`}
                          // display='inline'
                          // dangerouslySetInnerHTML={{
                          //   __html: getCommunicationRequestPayloadString(e) ?? '',
                          // }}
                          onClick={() => {
                            setFullText(e.mainSymptoms)
                            setShowText(true)
                          }}
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          {`${index + 1}. `}
                        </Typography>
                      </Box>
                      <Typography
                        key={`${e.mainSymptoms}`}
                        // display='inline'
                        // dangerouslySetInnerHTML={{
                        //   __html: getCommunicationRequestPayloadString(e) ?? '',
                        // }}
                        onClick={() => {
                          setFullText(e.mainSymptoms)
                          setShowText(true)
                        }}
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                          wordWrap: 'break-word',
                          whiteSpace: 'pre-line',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {e.mainSymptoms}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingTop={1} px={0.3}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                      color: 'black',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            {/* <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid> */}

            {e.associatedSymptoms.map((e1, index1: number) => (
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
                key={`${e1}`}
              >
                <Grid
                  container
                  direction='row'
                  id='vitals'
                  //   style={{
                  //     backgroundColor:
                  //       index % 2 === 0
                  //         ? 'white'
                  //         : prescription
                  //         ? 'white'
                  //         : kDialogueBackground,
                  //   }}
                >
                  <Grid item xs={12}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          flexDirection='row'
                          px={1}
                        >
                          <Box paddingRight={0.5}>
                            <Typography
                              key={`${e.mainSymptoms}`}
                              // display='inline'
                              // dangerouslySetInnerHTML={{
                              //   __html: getCommunicationRequestPayloadString(e) ?? '',
                              // }}
                              onClick={() => {
                                setFullText(e.mainSymptoms)
                                setShowText(true)
                              }}
                              color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              {`${index + 2}. `}
                            </Typography>
                          </Box>
                          <Typography
                            key={`${e1}`}
                            // display='inline'
                            // dangerouslySetInnerHTML={{
                            //   __html: getCommunicationRequestPayloadString(e) ?? '',
                            // }}
                            onClick={() => {
                              setFullText(e.mainSymptoms)
                              setShowText(true)
                            }}
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                              wordWrap: 'break-word',
                              whiteSpace: 'pre-line',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {e1}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container direction='row' id='vitals'>
                  <Grid item xs={12}>
                    <Box width='100%' px={0.3} paddingTop={1}>
                      <Divider
                        orientation='horizontal'
                        flexItem
                        style={{
                          height: '2px',
                          color: 'black',
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* <Grid container direction='row' id='vitals'>
                  <Grid item xs={12}>
                    <Box width='100%' paddingLeft={0.2}>
                      <Divider
                        orientation='horizontal'
                        flexItem
                        style={{
                          height: '2px',
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid> */}
              </Box>
            ))}
          </Box>
        ))}
      </Grid>
      <NoteDialog
        id='notesTile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        dialogText='Instruction'
        notesData={fullText!}
      />
    </Box>
  )
}
