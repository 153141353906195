import { Typography, Box, Grid, Divider } from '@material-ui/core'
import { cloneDeep } from 'lodash'
import React, { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'
import {
  CartItem as Item,
  cartItemDoctorMargin,
  cartItemPrice,
  cartItemPriceForPatient,
} from '../../../../../../models/medicine-delivery/cart/cart-models'
import {
  cartOrderForCachedState,
  cartState,
} from '../../../../../../models/medicine-delivery/cart/cart-states'
import { StyledTableCell, StyledTableRow } from '../styled-components'

interface Props {
  item: Item
}

export const CartOrderItemSummaryForPatient: React.FC<Props> = ({
  item,
}: Props) => {
  const [cart, setCart] = useRecoilState(cartState)
  const orderFor = useRecoilValue(cartOrderForCachedState)
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })
  const product = item.product
  const sku = product.sku
  const data = cartItemPriceForPatient(item)
  const { height } = useWindowDimensions()

  if (!sku) {
    alert(`Size of ${product.title} is undefined`)
    return <></>
  }

  const onDelete = () => {
    setShowDeleteConfirmation({
      open: true,
      onContinueActionClicked: () => {
        setShowDeleteConfirmation({ open: false })
        const clone: any = cloneDeep(cart)
        // Remove this this item from collection
        const filtered = clone.items.filter(
          (i: any) => i.product.sku.id !== sku.id
        )
        clone.items = [...filtered]
        setCart(clone)
      },
      onCancelActionClicked: () => {
        setShowDeleteConfirmation({ open: false })
      },
      displayText: 'Are you sure you want to delete this record?',
      continueActionButtonText: 'Delete',
      cancelActionButtonText: 'Cancel',
    })
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      py={0.5}
    >
      <Box display='flex' flexDirection='row' width='100%'>
        <Box display='flex' flexDirection='row' width='95%'>
          <Grid container direction='row' spacing={1}>
            {/* ------------------------------------------------------------------------------- */}

            <Box
              display='flex'
              flexDirection='column'
              width='100%'
              flexGrow={1}
            >
              <Grid container direction='row' id='vitals'>
                <Grid item xs={3}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box display='flex' flexDirection='column' width='100%'>
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          height={30}
                          px={1.5}
                          paddingTop={1.5}
                        >
                          <Typography
                            variant='subtitle2'
                            // color='initial'
                          >
                            {product.title}-{sku.sku}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box display='flex' flexDirection='row' width='100%'>
                    <Box
                      justifyContent='center'
                      display='flex'
                      height={30}
                      flexDirection='column'
                      paddingTop={1.5}
                      px={2}
                    >
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                      >
                        {sku.price}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={1}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    justifyContent='center'
                    height={30}
                    paddingTop={1.5}
                    paddingLeft={3}
                  >
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                    >
                      {item.quantity}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    justifyContent='center'
                    height={30}
                    px={3}
                    paddingTop={1.5}
                  >
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                    >
                      {cartItemDoctorMargin(item).toFixed(2)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    justifyContent='center'
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      px={1}
                      paddingTop={1.5}
                    >
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                      >
                        {item.customerDiscount}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    justifyContent='center'
                    height={30}
                    paddingTop={1.5}
                    paddingLeft={10}
                  >
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                    >
                      {data}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
        <Box justifyContent='center' paddingLeft={4}>
          <Typography
            variant='h5'
            color='primary'
            onClick={() => onDelete()}
            style={{ fontWeight: 'normal', cursor: 'pointer' }}
          >
            x
          </Typography>
        </Box>
      </Box>
      {showDeleteConfirmation.open && (
        <ConfirmationDialog {...showDeleteConfirmation} id='delete' />
      )}
    </Box>
  )
}
