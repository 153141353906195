import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import {
  addObservationDetails,
  getObservationResourceForUpdate,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { SingleSelectWithTextSearch } from 'views/components/LeftMenu/SingleSelectWithTextSearch'
import { WelloFormItemLabel } from 'wello-web-components'

const useStyles = makeStyles((theme) => ({
  noteTf: {
    minHeight: 50,
  },
}))

interface Props {
  patient: R4.IPatient
  encounterId: string
  onObservationAdded: () => void
  observationCategory: R4.ICodeableConcept[]
  searchOptions: R4.ICoding[]
  searchLabel?: string
  searchTitle?: string
  notesTitle?: string
  notesFiledPlaceholder?: string
  preSelectedCodes?: string[]
  otherCode?: string
  id: string
}
export const AddObservationWithNotesForEncounter: React.FC<Props> = ({
  onObservationAdded,
  encounterId,
  patient,
  observationCategory,
  searchOptions,
  searchLabel,
  searchTitle,
  notesTitle,
  notesFiledPlaceholder,
  preSelectedCodes,
  otherCode,
  id,
}) => {
  const { t } = useTranslation(['common', 'condition'])

  const [addingStatus, setAddingStatus] = useState<NetworkRequestStatus>({
    initial: true,
  })
  const dispatch = useDispatch()
  const [selectedCondition, setSelectedCondition] = useState<R4.ICoding>()
  const [additionalNotes, setAdditionalNotes] = useState<string>()
  const [otherDetails, setOtherDetails] = useState<string>()
  const classes = useStyles()
  function updateNutritionOrder() {
    if (selectedCondition) {
      setAddingStatus({
        requesting: true,
      })

      addObservationDetails(
        getObservationResourceForUpdate(
          patient.id!,
          encounterId,
          observationCategory,
          {
            coding: [
              selectedCondition.code === otherCode
                ? { ...selectedCondition, display: otherDetails }
                : selectedCondition,
            ],
          },
          additionalNotes
        )
      ).then((e) => {
        if (e) {
          setAddingStatus({
            requestSuccess: true,
          })
          resetDetails()
        } else {
          setAddingStatus({
            requestError: true,
          })
          resetDetails()
        }
      })
    }
  }

  function resetDetails() {
    setAdditionalNotes('')
    setOtherDetails(undefined)
    setSelectedCondition(undefined)
  }

  useEffect(() => {
    if (addingStatus.requestSuccess) {
      resetDetails()
      onObservationAdded()
    }
  }, [addingStatus])

  return (
    <Grid container direction='column' spacing={0}>
      <Grid>
        <Typography variant='subtitle1' color='initial'>
          Add New Finding
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction='column' spacing={0}>
          <Grid item>
            <Box
              display='flex'
              width='100%'
              maxWidth='400px'
              flexDirection='column'
              flexGrow={1}
            >
              <WelloFormItemLabel title={searchTitle ?? 'Select Findings'} />
              <SingleSelectWithTextSearch
                id={`select_${id}`}
                availableOptions={searchOptions.filter(
                  (e) =>
                    !(preSelectedCodes ?? [])
                      .filter((code) => code !== otherCode)
                      .includes(e.code ?? '')
                )}
                detachLabel={true}
                displayField='display'
                isInEditMode={true}
                labelString={searchLabel ?? 'Select Findings'}
                onAddClicked={(selectedValues) => {
                  setSelectedCondition(selectedValues as R4.ICoding)
                }}
                placeHolderString={searchLabel ?? 'Select Findings'}
                valuesField='code'
                selectedValue={selectedCondition ?? {}}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {selectedCondition &&
        selectedCondition.code &&
        otherCode &&
        selectedCondition.code === otherCode && (
          <Grid item>
            <Grid container direction='column'>
              <Grid item>
                <WelloFormItemLabel title='Other Finding name' />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  multiline
                  id={`${id}other_finding`}
                  placeholder='Enter Name'
                  disabled={addingStatus.requesting}
                  value={otherDetails}
                  variant='outlined'
                  onChange={(event) => {
                    if (event.target.value.length <= 100) {
                      setOtherDetails(event.target.value)
                    } else {
                      dispatch(
                        showErrorAlert(
                          'Finding name can not be more than 100 letters'
                        )
                      )
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      <Grid item>
        <Grid container direction='column'>
          <Grid item>
            <WelloFormItemLabel title={notesTitle ?? 'Additional Notes'} />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              multiline
              id={`${id}Notes`}
              placeholder={notesFiledPlaceholder ?? 'Add Notes'}
              disabled={addingStatus.requesting}
              value={additionalNotes}
              InputProps={{ classes: { input: classes.noteTf } }}
              variant='outlined'
              onChange={(event) => {
                setAdditionalNotes(event.target.value)
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {addingStatus.requesting && <CircularProgress size={25} />}
        {!addingStatus.requesting && (
          <Button
            onClick={() => {
              if (selectedCondition !== undefined) {
                updateNutritionOrder()
              }
            }}
            id={`${id}button`}
            variant='contained'
            color='primary'
            disableElevation
            disabled={
              selectedCondition === undefined ||
              (selectedCondition.code === otherCode &&
                (otherDetails === undefined ||
                  otherDetails.trim().length === 0))
            }
          >
            {t('labelCommon:add')}
          </Button>
        )}
      </Grid>
    </Grid>
  )
}
