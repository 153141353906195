/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  Switch,
  TextField,
  Typography,
  LinearProgress,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { UmlResponse } from 'models/umlResponse'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addAllergiesDetails,
  resetAddAllergiesState,
} from 'redux/fhirMedicalResources/ipd/addAllergy/addAllergySliceIPD'
import {
  addAddictions,
  resetAddictions,
} from 'redux/fhirMedicalResources/ipd/addictions/addictionsAddSlice'
import { RootState } from 'redux/rootReducer'
import {
  addictions,
  alcoholCount,
  breathe,
  drugCount,
  energyLevels,
  excersiseCode,
  genderCoding,
  intensity,
  listOfHabbits,
  multipleAllowed,
  obsData,
  occupations,
  sleepPattern,
  smokeCounts,
  teaCount,
  tobacoCount,
} from 'utils/constants'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import {
  RadioRaisedButton,
  //   WelloFormItemLabel,
  WelloLoadingIndicator,
  WelloTextField,
} from 'wello-web-components'
import { ClearOutlined } from '@material-ui/icons'
import {
  addOccupation,
  resetOccupationDetailsAdd,
} from 'redux/fhirMedicalResources/ipd/addOccupation/occupationAddSlice'
import { MensurationMasterSelector } from 'views/components/consultation/subjective/mensurationMasterSelector'
import {
  addMenstrualAdd,
  resetMesturationDetailsAdd,
} from 'redux/fhirMedicalResources/ipd/addMensuration/AddMensurationSlice'
import { ObecityMasterSelecor } from 'views/components/consultation/subjective/obecityMasterSelector'
import {
  addObsData,
  resetObsAdd,
} from 'redux/fhirMedicalResources/ipd/addObs/addObsSliceData'
import { ObsDataTableResource } from 'models/labObsData'
import { searchObservationsMaster } from 'redux/lab/observationFinder/masterObsSearchSlice'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { WelloSelectFHIRCodingWithoutTitle } from 'views/components/LeftMenu/WelloSelectGenderAddLabTest'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { LabObservationSelector } from '../orderCreationManagement/labObservationSelector'

interface Props {
  open: boolean
  existingCoding?: R4.ICoding[]
  onClose: () => void
  onNewRowAdded: (dataSet: ObsDataTableResource) => void
  tableData: ObsDataTableResource[]
  testName: string
}
export const AddNewLabTest: React.FC<Props> = ({
  open,
  existingCoding,
  onClose,
  onNewRowAdded,
  tableData,
  testName,
}) => {
  const { t } = useTranslation(['common', 'allergyIntolerance'])

  const [labTest, setLabtest] = useState<ObsDataTableResource>()

  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': labTest ? '60%' : '30%',
    },
  }))

  const classes = useStyles()

  function resetDetails() {
    setLabtest(undefined)
  }

  const [codeList, setCodeList] = useState<R4.ICoding[]>([])

  const [error, setError] = useState<string>()

  const dispatch = useDispatch()

  const masterObsFinderSlice = useSelector(
    (state: RootState) => state.masterObsFinderSlice
  )

  function isEnabled(): boolean | undefined {
    const arr: string[] = []

    if (error && error.length > 0) {
      arr.push('1')
    }
    if (labTest === undefined) {
      arr.push('2')
    }

    if (labTest) {
      if (labTest.obsName.length === 0) {
        arr.push('2')
      }
      if (labTest.unitCode.length === 0) {
        arr.push('3')
      }
    }

    if (arr.length > 0) return true
    return false
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetDetails()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>
        Add New Lab Test Details
      </DialogTitle>
      <DialogContent dividers={true}>
        <Box marginY={0.5} display='flex' flexDirection='column' width='100%'>
          <Box
            display='flex'
            flexDirection='column'
            width={labTest ? '50%' : '100%'}
          >
            <WelloFormItemLabel title='Search Test Name' />
            <LabObservationSelector
              existingDataLength={0}
              onSelectionChanges={(e: ObsDataTableResource) => {
                const nextCoding = tableData.filter(
                  (d) => d.obsCode! === e.obsCode!
                )
                if (nextCoding.length > 0) {
                  setError(`Lab Test already exists in ${testName}.`)
                } else {
                  setLabtest({
                    obsInitial: e.obsInitial,
                    obsName: e.obsName,
                    obsCode: e.obsCode,
                    permittedDataType: e.permittedDataType,
                    multipleResultsAllowed: e.multipleResultsAllowed,
                    unitCode: e.unitCode,
                    decimalPrecision: e.decimalPrecision,
                    high1: e.high1,
                    low1: e.low1,
                    highCode1: e.highCode1,
                    lowCode1: e.lowCode1,
                    gender1: e.gender1,
                    ageHigh1: e.ageHigh1,
                    ageLow1: e.ageLow1,
                    ageHighUnit1: e.ageHighUnit1,
                    ageLowUnit1: e.ageLowUnit1,
                    high2: e.high2,
                    low2: e.low2,
                    highCode2: e.highCode2,
                    lowCode2: e.lowCode2,
                    gender2: e.gender2,
                    ageHigh2: e.ageHigh2,
                    ageLow2: e.ageLow2,
                    ageHighUnit2: e.ageHighUnit2,
                    ageLowUnit2: e.ageLowUnit2,
                    active: true,
                    id: tableData.length,
                    interpretation: e.interpretation,
                    notes: e.notes,
                    comment: e.comment,
                  })
                  setError(undefined)
                }
              }}
              onType={() => {
                setLabtest(undefined)
              }}
              disabled={false}
            />
            {error && (
              <Box py={0.2}>
                <Typography variant='subtitle2' color='error'>
                  {error}
                </Typography>
              </Box>
            )}
          </Box>
          <Box marginY={0.5} display='flex' flexDirection='row' width='100%'>
            {labTest && (
              <Box display='flex' flexDirection='column' width='100%'>
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box width='51.1%' paddingRight={1} flexDirection='column'>
                    <Box py={1} px={0.25} display='flex' flexDirection='row'>
                      <Typography variant='subtitle2'>Test name</Typography>
                    </Box>
                    <WelloTextFieldWithoutTitle
                      textProps={{
                        id: `test_name`,
                        type: 'text',
                        placeholder: 'Test Name',
                        value: labTest.obsName,
                        error: labTest.obsName.length > 50,

                        onChange: (changeInstructions) => {
                          setLabtest({
                            ...labTest,
                            obsName: changeInstructions.target.value,
                          })
                        },
                      }}
                    />
                  </Box>

                  <Box width='20%' paddingRight={1} flexDirection='column'>
                    <Box py={1} px={0.25} display='flex' flexDirection='row'>
                      <Typography variant='subtitle2' color='initial'>
                        SI Unit
                      </Typography>
                    </Box>
                    <WelloTextFieldWithoutTitle
                      textProps={{
                        id: `test_name`,
                        placeholder: 'SI Unit',
                        value: labTest.unitCode,

                        disabled: true,

                        onChange: (changeInstructions) => {},
                      }}
                    />
                  </Box>
                </Box>

                <Box
                  paddingTop={1}
                  display='flex'
                  flexDirection='row'
                  width='100%'
                >
                  <Box width='20%' paddingRight={1} flexDirection='column'>
                    <Box py={1} px={0.25} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: '#111111',
                        }}
                      >
                        Range1 Low
                      </Typography>
                    </Box>
                    <WelloTextFieldWithoutTitle
                      textProps={{
                        id: `test_name`,
                        type: 'number',
                        placeholder: 'Low',
                        value: labTest.low1,
                        error:
                          labTest.low1! < 0 && labTest.low1! > labTest.high1!,

                        onChange: (changeInstructions) => {
                          setLabtest({
                            ...labTest,
                            low1: parseFloat(
                              parseFloat(
                                changeInstructions.target.value
                              ).toFixed(1)
                            ),
                            lowCode1: labTest.unitCode,
                          })
                        },
                      }}
                    />
                  </Box>
                  <Box width='20%' paddingRight={1} flexDirection='column'>
                    <Box py={1} px={0.25} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: '#111111',
                        }}
                      >
                        Range1 High
                      </Typography>
                    </Box>
                    <WelloTextFieldWithoutTitle
                      //   title='Range1 High'
                      textProps={{
                        id: `test_name`,
                        type: 'number',
                        placeholder: 'High',
                        value: labTest.high1,
                        error:
                          labTest.high1! < 0 && labTest.high1! < labTest.low1!,

                        onChange: (changeInstructions) => {
                          setLabtest({
                            ...labTest,
                            high1: parseFloat(
                              parseFloat(
                                changeInstructions.target.value
                              ).toFixed(1)
                            ),
                            highCode1: labTest.unitCode,
                          })
                        },
                      }}
                    />
                  </Box>

                  <Box
                    display='flex'
                    flexDirection='column'
                    width='20%'
                    paddingRight={1}
                  >
                    <Box py={1} px={0.25} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: '#111111',
                        }}
                      >
                        Range1 Gender
                      </Typography>
                    </Box>
                    <WelloSelectFHIRCodingWithoutTitle
                      availableCodings={genderCoding}
                      id='route_type'
                      role='Select Gender'
                      onChanged={(e) => {
                        setLabtest({
                          ...labTest,
                          gender1: e.code ?? '',
                        })
                      }}
                      preSelectedCoding={{
                        code: labTest.gender1,
                        display: labTest.gender1,
                      }}
                      textFieldProps={{
                        size: 'small',
                        fullWidth: true,
                      }}
                    />
                  </Box>
                  <Box width='20%' paddingRight={1} flexDirection='column'>
                    <Box py={1} px={0.25} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: '#111111',
                        }}
                      >
                        Range1 Age Low (in Yrs)
                      </Typography>
                    </Box>
                    <WelloTextFieldWithoutTitle
                      textProps={{
                        id: `test_name`,
                        type: 'number',
                        placeholder: 'Low',
                        value: labTest.ageLow1,
                        error:
                          (labTest.ageLow1! < 0 || labTest.ageLow1! > 100) &&
                          labTest.ageLow1! > labTest.ageHigh1!,

                        onChange: (changeInstructions) => {
                          setLabtest({
                            ...labTest,
                            ageLow1: parseFloat(
                              parseFloat(
                                changeInstructions.target.value
                              ).toFixed(1)
                            ),
                            ageLowUnit1: 'a',
                          })
                        },
                      }}
                    />
                  </Box>

                  <Box width='20%' paddingRight={1} flexDirection='column'>
                    <Box py={1} px={0.25} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: '#111111',
                        }}
                      >
                        Range1 Age High (in Yrs)
                      </Typography>
                    </Box>
                    <WelloTextFieldWithoutTitle
                      textProps={{
                        id: `test_name`,
                        type: 'number',
                        placeholder: 'High',
                        value: labTest.ageHigh1,
                        error:
                          (labTest.ageHigh1! < 0 || labTest.ageHigh1! > 120) &&
                          labTest.ageHigh1! < labTest.ageLow1!,

                        onChange: (changeInstructions) => {
                          setLabtest({
                            ...labTest,
                            ageHigh1: parseFloat(
                              parseFloat(
                                changeInstructions.target.value
                              ).toFixed(1)
                            ),
                            ageHighUnit1: 'a',
                          })
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box paddingTop={1} display='flex' flexDirection='row'>
                  <Box width='20%' paddingRight={1} flexDirection='column'>
                    <Box py={1} px={0.25} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: 'black',
                          opacity: 0.8,
                        }}
                      >
                        Range2 Low
                      </Typography>
                    </Box>
                    <WelloTextFieldWithoutTitle
                      //   title='Range2 Low'
                      textProps={{
                        id: `test_name`,
                        type: 'number',
                        placeholder: 'Low',
                        value: labTest.low2,
                        error:
                          labTest.low2! < 0 && labTest.low2! > labTest.high2!,

                        onChange: (changeInstructions) => {
                          setLabtest({
                            ...labTest,
                            low2: parseFloat(
                              parseFloat(
                                changeInstructions.target.value
                              ).toFixed(1)
                            ),
                            lowCode2: labTest.unitCode,
                          })
                        },
                      }}
                    />
                  </Box>
                  <Box width='20%' paddingRight={1} flexDirection='column'>
                    <Box py={1} px={0.25} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: 'black',
                          opacity: 0.8,
                        }}
                      >
                        Range2 High
                      </Typography>
                    </Box>
                    <WelloTextFieldWithoutTitle
                      textProps={{
                        id: `test_name`,
                        type: 'number',
                        placeholder: 'High',
                        value: labTest.high2,
                        error:
                          labTest.high2! < 0 && labTest.high2! < labTest.low2!,

                        onChange: (changeInstructions) => {
                          setLabtest({
                            ...labTest,
                            high2: parseFloat(
                              parseFloat(
                                changeInstructions.target.value
                              ).toFixed(1)
                            ),
                            highCode2: labTest.unitCode,
                          })
                        },
                      }}
                    />
                  </Box>

                  <Box
                    display='flex'
                    flexDirection='column'
                    width='20%'
                    paddingRight={1}
                  >
                    <Box py={1} px={0.25} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: 'black',
                          opacity: 0.8,
                        }}
                      >
                        Range2 Gender
                      </Typography>
                    </Box>
                    <WelloSelectFHIRCodingWithoutTitle
                      availableCodings={genderCoding}
                      id='route_type'
                      role='Select Gender'
                      onChanged={(e) => {
                        setLabtest({
                          ...labTest,
                          gender2: e.code ?? '',
                        })
                      }}
                      preSelectedCoding={{
                        code: labTest.gender2 ?? 'All',
                        display: labTest.gender2 ?? 'All',
                      }}
                      textFieldProps={{
                        size: 'small',
                        fullWidth: true,
                      }}
                    />
                  </Box>

                  <Box width='20%' paddingRight={1} flexDirection='column'>
                    <Box py={1} px={0.25} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: 'black',
                          opacity: 0.8,
                        }}
                      >
                        Range2 Age Low (in Yrs)
                      </Typography>
                    </Box>
                    <WelloTextFieldWithoutTitle
                      textProps={{
                        id: `test_name`,
                        type: 'number',
                        placeholder: 'Low',
                        value: labTest.ageLow2,
                        error:
                          labTest.ageLow2! < 0 &&
                          labTest.ageLow2! > labTest.ageHigh2!,

                        onChange: (changeInstructions) => {
                          setLabtest({
                            ...labTest,
                            ageLow2: parseFloat(
                              parseFloat(
                                changeInstructions.target.value
                              ).toFixed(1)
                            ),
                            ageLowUnit2: 'a',
                          })
                        },
                      }}
                    />
                  </Box>

                  <Box width='20%' paddingRight={1} flexDirection='column'>
                    <Box py={1} px={0.25} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: 'black',
                          opacity: 0.8,
                        }}
                      >
                        Range2 Age High (in Yrs)
                      </Typography>
                    </Box>
                    <WelloTextFieldWithoutTitle
                      textProps={{
                        id: `test_name`,
                        type: 'number',
                        placeholder: 'High',
                        value: labTest.ageHigh2,
                        error:
                          labTest.ageHigh2! < 0 &&
                          labTest.ageHigh2! < labTest.ageLow2!,

                        onChange: (changeInstructions) => {
                          setLabtest({
                            ...labTest,
                            ageHigh2: parseFloat(
                              parseFloat(
                                changeInstructions.target.value
                              ).toFixed(1)
                            ),
                            ageHighUnit2: 'a',
                          })
                        },
                      }}
                    />
                  </Box>
                </Box>

                <Box paddingTop={1} display='flex' flexDirection='row'>
                  <Box width='30%' paddingRight={1} flexDirection='column'>
                    <Box py={1} px={0.25} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: 'black',
                          opacity: 0.8,
                        }}
                      >
                        Interpretation
                      </Typography>
                    </Box>
                    <WelloTextFieldWithoutTitle
                      //   title='Range2 Low'
                      textProps={{
                        id: `test_name`,
                        type: 'string',
                        placeholder: 'Interpretation',
                        value: labTest.interpretation,
                        error: labTest.interpretation.length > 200,
                        minRows: 1,
                        multiline: true,
                        maxRows: 4,

                        onChange: (changeInstructions) => {
                          setLabtest({
                            ...labTest,
                            interpretation: changeInstructions.target.value,
                          })
                        },
                      }}
                    />
                  </Box>
                  <Box width='30%' paddingRight={1} flexDirection='column'>
                    <Box py={1} px={0.25} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: 'black',
                          opacity: 0.8,
                        }}
                      >
                        Notes
                      </Typography>
                    </Box>
                    <WelloTextFieldWithoutTitle
                      textProps={{
                        id: `test_name`,
                        type: 'string',
                        placeholder: 'Notes',
                        value: labTest.notes,
                        error: labTest.notes.length > 200,
                        minRows: 1,
                        multiline: true,
                        maxRows: 4,

                        onChange: (changeInstructions) => {
                          setLabtest({
                            ...labTest,
                            notes: changeInstructions.target.value,
                          })
                        },
                      }}
                    />
                  </Box>

                  <Box
                    display='flex'
                    flexDirection='column'
                    width='30%'
                    paddingRight={1}
                  >
                    <Box py={1} px={0.25} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: 'black',
                          opacity: 0.8,
                        }}
                      >
                        Comments
                      </Typography>
                    </Box>
                    <WelloTextFieldWithoutTitle
                      textProps={{
                        id: `test_name`,
                        type: 'string',
                        placeholder: 'Comments',
                        value: labTest.comment,
                        error: labTest.comment.length > 200,
                        minRows: 1,
                        multiline: true,
                        maxRows: 4,

                        onChange: (changeInstructions) => {
                          setLabtest({
                            ...labTest,
                            comment: changeInstructions.target.value,
                          })
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box paddingRight={labTest ? 2.7 : 2}>
          <Button
            onClick={() => {
              resetDetails()
              onClose()
            }}
            variant='outlined'
            id='obs_6'
            disableElevation
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            variant='contained'
            color='primary'
            id='obs_7'
            disabled={isEnabled()}
            disableElevation
            onClick={() => {
              if (labTest) {
                onNewRowAdded(labTest)
                resetDetails()
              }
            }}
          >
            {t('labelCommon:add')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
