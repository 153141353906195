/* eslint-disable @typescript-eslint/no-shadow */
import { Box } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import {
  GoogleMap,
  LoadScript,
  Autocomplete,
  Marker,
} from '@react-google-maps/api'

interface IMarker {
  address: string
  latitude: number
  longitude: number
  settingPage: boolean
  mapLatlog: {
    lat: number
    lng: number
  }
  width?: string
}

export const MapComp: React.FC<IMarker> = ({
  address,
  latitude,
  longitude,
  settingPage,
  mapLatlog,
  width,
}) => {
  const containerStyle = {
    width: width ? `${width}px` : `600px`,
    height: '200px',
  }
  const center = {
    lat: mapLatlog.lat,
    lng: mapLatlog.lng,
  }
  return (
    <Box paddingLeft={settingPage ? 0.5 : 11}>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
        <Marker position={center} />
        <></>
      </GoogleMap>
    </Box>
  )
}
