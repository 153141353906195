/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { requestForAccessment } from 'redux/ipd/assesmentmanagement/assessmentSearch/assessmentSearchSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { getValueCoDeFromValueCodeable } from 'utils/fhirResourcesHelper'
import { getNotesData } from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { NoteDialog } from '../LeftMenu/TextDialog'

interface AssessmentCaptureProps {
  patient: R4.IPatient
  serviceReq: R4.IServiceRequest
}

export const AssessmentTile: React.FC<AssessmentCaptureProps> = ({
  patient,
  serviceReq,
}: AssessmentCaptureProps) => {
  //   const checkinDate = moment(
  //     fhirAppointmentDetails.mainServiceRequest.occurrencePeriod?.start
  //   ).format('YYYY-MM-DD')
  //   const checkOutDate = moment(
  //     fhirAppointmentDetails.mainServiceRequest.occurrencePeriod?.end
  //   ).format('YYYY-MM-DD')

  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const [summary, setSummary] = React.useState<string>()

  const [fullText, setFullText] = useState<string>('')
  const [showText, setShowText] = useState<boolean>(false)

  const addAssesmentSliceForIpd = useSelector(
    (state: RootState) => state.addAssesmentSliceForIpd
  )

  const assessmentSearchSliceIPD = useSelector(
    (state: RootState) => state.assessmentSearchSliceIPD
  )

  function resetValues() {
    setSummary(undefined)
    setFullText('')
    setShowText(false)
  }

  useEffect(() => {
    dispatch(requestForAccessment(patient, serviceReq.id!))
  }, [dispatch])

  return (
    <Box style={{ paddingLeft: '4px' }} display='flex' flexDirection='row'>
      <Box width='100%'>
        <Grid container direction='column'>
          <Grid item>
            <ReactVisibilitySensor
              onChange={(isVisible) => {
                if (isVisible) {
                  dispatch(setSelectedSection({ selectedSection: 'diagnosis' }))
                }
              }}
            >
              <Grid container direction='column'>
                <Grid item id='diagnosis'>
                  <Grid
                    container
                    direction='row'
                    style={{ padding: 0, margin: 0 }}
                  >
                    <Box py={1}>
                      {getValueCoDeFromValueCodeable(
                        serviceReq.extension ?? [],
                        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                      ).length > 0 &&
                        getValueCoDeFromValueCodeable(
                          serviceReq.extension ?? [],
                          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                        ) === '1259939000' && (
                          <Typography
                            variant='subtitle2'
                            style={{
                              color: '#4D4D4D',
                              textTransform: 'uppercase',
                            }}
                          >
                            {' '}
                            Final Diagnosis
                          </Typography>
                        )}

                      {(getValueCoDeFromValueCodeable(
                        serviceReq.extension ?? [],
                        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                      ).length === 0 ||
                        getValueCoDeFromValueCodeable(
                          serviceReq.extension ?? [],
                          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                        ) === '439809005') && (
                        <Typography
                          variant='subtitle2'
                          style={{
                            color: '#4D4D4D',
                            textTransform: 'uppercase',
                          }}
                        >
                          {' '}
                          Final Diagnosis & Treatment
                        </Typography>
                      )}
                    </Box>{' '}
                  </Grid>
                </Grid>
                {assessmentSearchSliceIPD.resultsAvailable &&
                  assessmentSearchSliceIPD.vitalsList &&
                  assessmentSearchSliceIPD.vitalsList.length > 0 && (
                    <Grid item>
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-start'
                        width='100%'
                        border={4}
                        style={{
                          boxShadow: '0px 0px 4px #ccc',
                          margin: '2px 0px',
                          borderRadius: '1px',
                          borderColor: '#F8F8F8',
                          width: '100%',
                          height: 'auto',
                          backgroundColor: '#F8F8F8',
                        }}
                      >
                        <Grid container direction='row' spacing={1}>
                          <Grid
                            container
                            direction='row'
                            id='vitals'
                            style={{
                              backgroundColor: kDialogueBackground,
                            }}
                          >
                            <Grid item xs={3}>
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      justifyContent='flex-start'
                                      display='flex'
                                      flexGrow
                                      height={30}
                                      alignItems='center'
                                      px={1}
                                    >
                                      <Typography
                                        variant='subtitle2'
                                        // color='initial'
                                        style={{
                                          fontFamily: 'Open Sans',
                                          fontSize: 12,
                                          color: 'black',

                                          fontWeight: 'bold',
                                          lineHeight: 'normal',
                                        }}
                                      >
                                        Date
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box
                                    display='flex'
                                    flexGrow={1}
                                    justifyContent='flex-end'
                                  >
                                    <Divider
                                      orientation='vertical'
                                      flexItem
                                      style={{
                                        height: '30px',

                                        width: '2px',
                                      }}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={9}>
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      justifyContent='flex-start'
                                      display='flex'
                                      flexGrow
                                      height={30}
                                      alignItems='center'
                                      px={1}
                                    >
                                      {getValueCoDeFromValueCodeable(
                                        serviceReq.extension ?? [],
                                        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                                      ).length > 0 &&
                                        getValueCoDeFromValueCodeable(
                                          serviceReq.extension ?? [],
                                          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                                        ) === '1259939000' && (
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            {' '}
                                            Final Diagnosis
                                          </Typography>
                                        )}

                                      {(getValueCoDeFromValueCodeable(
                                        serviceReq.extension ?? [],
                                        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                                      ).length === 0 ||
                                        getValueCoDeFromValueCodeable(
                                          serviceReq.extension ?? [],
                                          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
                                        ) === '439809005') && (
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          {' '}
                                          Final Diagnosis & Treatment
                                        </Typography>
                                      )}
                                      {/* <Typography
                                        variant='subtitle2'
                                        // color='initial'
                                        style={{
                                          fontFamily: 'Open Sans',
                                          fontSize: 12,
                                          color: 'black',

                                          fontWeight: 'bold',
                                          lineHeight: 'normal',
                                        }}
                                      >
                                        Diagnosis & Treatment
                                      </Typography> */}
                                    </Box>
                                  </Box>
                                  <Box
                                    display='flex'
                                    flexGrow={1}
                                    justifyContent='flex-end'
                                  >
                                    <Divider
                                      orientation='vertical'
                                      flexItem
                                      style={{
                                        height: '30px',

                                        width: '2px',
                                      }}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  )}
                {assessmentSearchSliceIPD.searchingConditions && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      align='center'
                    >
                      <CircularProgress size={25} />
                    </Typography>
                  </Box>
                )}

                {assessmentSearchSliceIPD.errorWhileSearchingProcedures && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      align='center'
                    >
                      Some Error has occured
                    </Typography>
                  </Box>
                )}

                {assessmentSearchSliceIPD.noResultsAvailable && (
                  <Box display='flex' flexDirection='column' width='100%'>
                    <Typography
                      variant='subtitle2'
                      style={{
                        fontWeight: 400,
                      }}
                    >
                      No data available
                    </Typography>
                  </Box>
                )}

                {assessmentSearchSliceIPD.resultsAvailable &&
                  assessmentSearchSliceIPD.vitalsList &&
                  assessmentSearchSliceIPD.vitalsList.length === 0 && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='100%'
                      alignContent='center'
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          fontWeight: 400,
                        }}
                      >
                        No data available
                      </Typography>
                    </Box>
                  )}

                {assessmentSearchSliceIPD.resultsAvailable &&
                  assessmentSearchSliceIPD.vitalsList &&
                  assessmentSearchSliceIPD.vitalsList.length > 0 && (
                    <Grid item>
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-start'
                        width='100%'
                        border={4}
                        style={{
                          boxShadow: '0px 0px 4px #ccc',
                          margin: '2px 0px',
                          borderRadius: '1px',
                          borderColor: '#F8F8F8',
                          width: '100%',
                          height: 'auto',
                          backgroundColor: '#F8F8F8',
                        }}
                      >
                        <Grid container direction='row' spacing={1}>
                          {assessmentSearchSliceIPD.vitalsList.map(
                            (
                              assessmentData: R4.IObservation,
                              index: number
                            ) => (
                              <Box width='100%'>
                                <Grid
                                  container
                                  direction='row'
                                  id='vitals'
                                  style={{
                                    backgroundColor:
                                      index % 2 ? kDialogueBackground : 'white',
                                  }}
                                >
                                  <Grid item xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,

                                              fontWeight: 600,
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            {' '}
                                            {moment(
                                              assessmentData.issued
                                            ).format('DD-MM-YYYY')}
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        flexGrow={1}
                                        justifyContent='flex-end'
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>

                                  <Grid item xs={9}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='body2'
                                            color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,

                                              fontWeight: 600,
                                              lineHeight: 'normal',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                              if (
                                                getNotesData(
                                                  assessmentData.valueString ??
                                                    ''
                                                ).length > 60
                                              ) {
                                                setFullText(
                                                  getNotesData(
                                                    assessmentData.valueString ??
                                                      ''
                                                  )
                                                )
                                                setShowText(true)
                                              }
                                            }}
                                          >
                                            {getNotesData(
                                              assessmentData.valueString ?? ''
                                            ).slice(0, 60)}
                                            {getNotesData(
                                              assessmentData.valueString ?? ''
                                            ).length > 60
                                              ? '...'
                                              : null}
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        flexGrow={1}
                                        justifyContent='flex-end'
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            )
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  )}
              </Grid>
            </ReactVisibilitySensor>
          </Grid>
        </Grid>
      </Box>
      <NoteDialog
        id='treatment_notes_tile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
        dialogText='Notes'
      />
    </Box>
  )
}
