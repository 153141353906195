import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedWelloMedication } from 'models/groupedWelloMedication'
import { PurposeOfUse } from 'models/purposeOfUse'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getWelloMedicationsFromBundleResponses,
  getWelloMedicationsFromBundleResponsesForHistory,
  getWelloMedicationsFromBundleResponsesFromStatement,
} from 'utils/appointment_handle/medications_util'
import {
  getEmailOfPatient,
  getIdentifierValueBySystem,
} from 'utils/fhirResourcesHelper'
import { getPatientIdentifiersForSearch } from 'utils/fhirResoureHelpers/patientHelpers'
import { logger } from 'utils/logger'
import { MedicationHistoryStatusType } from './medicationHistoryStatusType'

const initialState: MedicationHistoryStatusType = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAllergies: false,
}

const medicationHistorySearchSlice = createSlice({
  name: 'medicationHistorySearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<MedicationHistoryStatusType>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.medicationList = action.payload.medicationList
      state.medications = action.payload.medications
      state.groupedMedications = action.payload.groupedMedications
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingAllergies =
        action.payload.errorWhileSearchingAllergies
    },
  },
})

export const requestMedicationHistoryOfPatient =
  (
    selectedPatient: R4.IPatient,
    purposeOfUse: PurposeOfUse,
    split: boolean,
    appointmentId?: string,
    encounterId?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: MedicationHistoryStatusType = {
      searchingConditions: true,
      errorWhileSearchingAllergies: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(medicationHistorySearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {}

      if (getPatientIdentifiersForSearch(selectedPatient).length > 0) {
        searchParameters['patient:Patient.identifier'] =
          getPatientIdentifiersForSearch(selectedPatient)
      }
      let response: any

      const date = `${moment(
        moment(moment(moment()).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      ).toISOString()}`

      const endDate = `${moment(
        moment(moment(moment()).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      )
        .subtract(90, 'days')
        .toISOString()}`
      if (purposeOfUse === PurposeOfUse.OPDAppointment) {
        const requestingAppointmentRef =
          sessionStorage.getItem('appointmentReference') ?? ''
        let requestingAppointment: string = ''
        if (requestingAppointmentRef) {
          requestingAppointment = requestingAppointmentRef.split('/')[1]
        }
        response =
          await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
            '/MedicationStatement?_count=500',
            appointmentId!,
            searchParameters
          )
      } else if (purposeOfUse === PurposeOfUse.IPDAppointment) {
        if (split) {
          response = await fhirClient.doGetResourceForAppointment(
            `/MedicationStatement?_count=500`,
            '',
            searchParameters
          )
        } else {
          response = await fhirClient.doGetResourceForAppointment(
            `/MedicationStatement?_count=500`,
            '',
            searchParameters
          )
        }
      }

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        console.log(resp.left)
        state.errorWhileSearchingAllergies = true
        state.searchingConditions = false

        dispatch(medicationHistorySearchSlice.actions.updatedStatus(state))
      } else {
        const proceduresResponse: R4.IBundle = resp.right
        if (proceduresResponse?.total && proceduresResponse?.total > 0) {
          const clinicalImpressions: WelloMedication[] =
            getWelloMedicationsFromBundleResponsesFromStatement(
              proceduresResponse
            )

          const allergiesData: R4.IMedicationStatement[] =
            proceduresResponse.entry?.map(
              (item) => item.resource as R4.IMedicationStatement
            ) ?? []
          const medicationRequest = await getMedication(
            selectedPatient,
            appointmentId,
            encounterId
          )

          const array3 = clinicalImpressions.concat(medicationRequest)

          const finalGroupedData: GroupedWelloMedication[] = _.chain(array3)
            // Group the elements of Array based on `color` property
            .groupBy('date')
            // `key` is group's name (color), `value` is the array of objects
            .map((value, key) => ({
              date: key,
              medications: value,
              ayurvedaMedication: [],
            }))
            .value()

          finalGroupedData.sort((a, b) => moment(b.date).diff(a.date))

          state.resultsAvailable = true
          state.searchingConditions = false
          state.medicationList = allergiesData
          state.medications = clinicalImpressions
          state.groupedMedications = finalGroupedData
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingAllergies = false
          dispatch(medicationHistorySearchSlice.actions.updatedStatus(state))
        } else {
          const medicationRequest = await getMedication(
            selectedPatient,
            appointmentId,
            encounterId
          )

          if (medicationRequest.length > 0) {
            const finalGroupedData: GroupedWelloMedication[] = _.chain(
              medicationRequest
            )

              // Group the elements of Array based on `color` property
              .groupBy('date')
              // `key` is group's name (color), `value` is the array of objects
              .map((value, key) => ({
                date: key,
                medications: value,
                ayurvedaMedication: [],
              }))
              .value()

            finalGroupedData.sort((a, b) => moment(b.date).diff(a.date))

            state.resultsAvailable = true
            state.searchingConditions = false
            state.medicationList = []
            state.medications = []
            state.groupedMedications = finalGroupedData
            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingAllergies = false
            dispatch(medicationHistorySearchSlice.actions.updatedStatus(state))
          } else {
            const errorSearchDoctor: MedicationHistoryStatusType = {
              searchingConditions: false,
              errorWhileSearchingAllergies: false,
              resultsAvailable: false,
              noResultsAvailable: true,
            }
            dispatch(
              medicationHistorySearchSlice.actions.updatedStatus(
                errorSearchDoctor
              )
            )
          }
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: MedicationHistoryStatusType = {
        searchingConditions: false,
        errorWhileSearchingAllergies: true,
        resultsAvailable: false,
        errorReason: 'Error while searching current medication',
      }
      dispatch(
        medicationHistorySearchSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

async function getMedication(
  selectedPatient: R4.IPatient,
  appointmentId?: string,
  encounterId?: string
): Promise<WelloMedication[]> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    _sort: '_lastUpdated',
  }
  let medicationData: WelloMedication[] = []
  const response: any =
    await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
      `/MedicationRequest?subject=Patient/${selectedPatient.id!}&status=active`,
      appointmentId!,
      searchParameters
    )
  if (response?.total && response?.total > 0) {
    medicationData = getWelloMedicationsFromBundleResponsesForHistory(
      response,
      encounterId
    )
  }
  logger.info('Org  Response')
  logger.info(response)

  return medicationData
}
export default medicationHistorySearchSlice.reducer
