import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  ContactPointUseKind,
  HumanNameUseKind,
  IdentifierUseKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { SadhkaResponse } from 'models/sadkahResponse'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { sleep } from 'utils/dateUtil'
import { getAppointmentSuccessfulMessageIPD } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { logger } from 'utils/logger'
import { IpdSadhkaFinsh } from './ipdSadhkaFinishStatus'

const initialState: IpdSadhkaFinsh = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const ipdFinsihSadhka = createSlice({
  name: 'ipdFinsihSadhka',
  initialState,
  reducers: {
    addingPatientDetails(state, action: PayloadAction<IpdSadhkaFinsh>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
    },

    patientDetailsAdded(state, action: PayloadAction<IpdSadhkaFinsh>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
    },

    errorInAddingPatientDetails(state, action: PayloadAction<IpdSadhkaFinsh>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetPatientDetails(state, action: PayloadAction<IpdSadhkaFinsh>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },
  },
})

export const finshIpdOnboardingForSadhka =
  (
    patientId: string,
    serviceId: string,
    questionResponse: R4.IQuestionnaireResponse_Item[],
    q1: R4.IQuestionnaireResponse_Item,
    q2: R4.IQuestionnaireResponse_Item,
    q3: R4.IQuestionnaireResponse_Item,
    q4: R4.IQuestionnaireResponse_Item,
    mainQ2: R4.IQuestionnaireResponse_Item[],
    q9: R4.IQuestionnaireResponse_Item,
    mainQ1: R4.IQuestionnaireResponse_Item[],
    country2: R4.IQuestionnaireResponse_Item[],
    country3: R4.IQuestionnaireResponse_Item[],

    q5?: R4.IQuestionnaireResponse_Item,
    q6?: R4.IQuestionnaireResponse_Item,
    q7?: R4.IQuestionnaireResponse_Item,
    q10?: R4.IQuestionnaireResponse_Item,
    q11?: R4.IQuestionnaireResponse_Item,
    q12?: R4.IQuestionnaireResponse_Item,
    q13?: R4.IQuestionnaireResponse_Item,
    q14?: R4.IQuestionnaireResponse_Item,
    q15?: R4.IQuestionnaireResponse_Item,

    selectedPatient?: R4.IPatient
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: IpdSadhkaFinsh = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(
      ipdFinsihSadhka.actions.addingPatientDetails(addingCreatePersonState)
    )

    try {
      const practRole: R4.IPractitionerRole =
        getCurrentUserPractitionerRoleDetails()

      const getQuestionResponse: R4.IQuestionnaireResponse =
        getQuestionResponseFormat(
          serviceId,
          questionResponse,
          q1,
          q2,
          q3,
          q4,
          mainQ2,
          q9,
          mainQ1,
          country2,
          country3,
          q5,
          q6,
          q7,
          q10,
          q11,
          q12,
          q13,
          q14,
          q15
        )

      const bundleData: R4.IBundle = getTransactionBundle(
        patientId,
        serviceId,
        getQuestionResponse,
        selectedPatient
      )

      logger.info('patientDetails')

      const resource: any = {
        actionType: 'questionnaireUpdate',
        actionBody: {
          bundle: bundleData,
          isOpdBased: false,
        },
      }
      const serviceReqId: string[] = serviceId.split('/')

      //   const fhirClient: FHIRApiClient = new FHIRApiClient()
      const enRolClient: EnrolCient = new EnrolCient()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        `ipd/${serviceReqId[1]}/updatePreAdmitAction`,
        resource
      )
      const columnData: string[] = []
      columnData.push(serviceReqId[1])

      let sadhakModelData: SadhkaResponse[] = []

      const res = sessionStorage.getItem('sadhakList')
      if (res !== null) sadhakModelData = JSON.parse(res)
      const filteredData: SadhkaResponse[] = sadhakModelData.filter(
        (d) => d.serviceId !== serviceId
      )
      sessionStorage.removeItem('sadhakList')
      sessionStorage.setItem('sadhakList', JSON.stringify(filteredData))

      const resData = sessionStorage.getItem('selecttedNames')
      if (resData === null) {
        sessionStorage.setItem('selecttedNames', JSON.stringify(columnData))
      } else {
        let existingData: string[] = []
        existingData = JSON.parse(resData)
        const finalArr: string[] = [...existingData, ...columnData]
        columnData.concat(existingData)
        sessionStorage.removeItem('selecttedNames')
        sessionStorage.setItem('selecttedNames', JSON.stringify(finalArr))
      }

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)

      if (response.status === 'Updated Questionnaire details') {
        if (selectedPatient) {
          dispatch(
            showSuccessAlert(
              getAppointmentSuccessfulMessageIPD(selectedPatient)
            )
          )
        }
        const successCreatePersonState: IpdSadhkaFinsh = {
          adding: false,
          additionSuccessful: true,

          error: false,
          errorMessage: '',
        }
        dispatch(
          ipdFinsihSadhka.actions.patientDetailsAdded(successCreatePersonState)
        )

        return
      }

      const errorCreatePersonState: IpdSadhkaFinsh = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while register patient',
      }
      dispatch(
        ipdFinsihSadhka.actions.patientDetailsAdded(errorCreatePersonState)
      )

      return
    } catch (error) {
      const errorCreatePersonState: IpdSadhkaFinsh = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error While Adding Patient',
      }
      dispatch(
        ipdFinsihSadhka.actions.errorInAddingPatientDetails(
          errorCreatePersonState
        )
      )
    }
  }

function getQuestionResponseFormat(
  serviceId: string,
  questionResponse: R4.IQuestionnaireResponse_Item[],
  q1: R4.IQuestionnaireResponse_Item,
  q2: R4.IQuestionnaireResponse_Item,
  q3: R4.IQuestionnaireResponse_Item,
  q4: R4.IQuestionnaireResponse_Item,
  mainQ2: R4.IQuestionnaireResponse_Item[],
  q9: R4.IQuestionnaireResponse_Item,
  mainQ1: R4.IQuestionnaireResponse_Item[],
  country2: R4.IQuestionnaireResponse_Item[],
  country3: R4.IQuestionnaireResponse_Item[],
  q5?: R4.IQuestionnaireResponse_Item,
  q6?: R4.IQuestionnaireResponse_Item,
  q7?: R4.IQuestionnaireResponse_Item,
  q10?: R4.IQuestionnaireResponse_Item,
  q11?: R4.IQuestionnaireResponse_Item,
  q12?: R4.IQuestionnaireResponse_Item,
  q13?: R4.IQuestionnaireResponse_Item,
  q14?: R4.IQuestionnaireResponse_Item,
  q15?: R4.IQuestionnaireResponse_Item
): R4.IQuestionnaireResponse {
  const topAns: R4.IQuestionnaireResponse_Item[] = []
  const topAn1: R4.IQuestionnaireResponse_Item[] = []
  const topAn2: R4.IQuestionnaireResponse_Item[] = []
  const topAn3: R4.IQuestionnaireResponse_Item[] = []
  const topAn4: R4.IQuestionnaireResponse_Item[] = []
  const topAn5: R4.IQuestionnaireResponse_Item[] = []
  topAns.push(q1)
  topAns.push(q2)
  topAns.push(q3)
  topAns.push(q4)
  topAn2.push(q9)
  questionResponse[0].item = topAns
  if (q5 && q6 && q7) {
    topAn1.push(q6)
    topAn1.push(q5)
    topAn1.push(q7)
  }

  if (q10 && q11 && q12) {
    topAn1.push(q10)
    topAn1.push(q11)
    topAn1.push(q12)
  }
  if (q13 && q14 && q15) {
    topAn1.push(q13)
    topAn1.push(q14)
    topAn1.push(q15)
  }
  if (topAn1.length > 1) {
    mainQ1[0].item = topAn1
  }

  mainQ2[0].item = topAn2
  let topItem: R4.IQuestionnaireResponse_Item[] = []
  if (topAn1.length > 1) {
    topItem = questionResponse.concat(mainQ1, mainQ2)
  } else {
    topItem = questionResponse.concat(mainQ2)
  }

  const allitem: R4.IQuestionnaireResponse_Item[] = []

  const resp: R4.IQuestionnaireResponse = {
    resourceType: 'QuestionnaireResponse',
    status: R4.QuestionnaireResponseStatusKind._completed,
    basedOn: [
      {
        reference: `${serviceId}`,
      },
    ],
    subject: {
      reference: serviceId,
    },
    item: topItem,
    // source: {
    //   reference: 'Questionnaire/2224202',
    // },
  }

  return resp
}

function getTransactionBundle(
  patientId: string,
  serviceId: string,
  questionResponse: R4.IQuestionnaireResponse,
  selectedPatient?: R4.IPatient
): R4.IBundle {
  const patientConsent: R4.IConsent = {
    resourceType: 'Consent',

    status: R4.ConsentStatusKind._active,
    scope: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/consentscope',
          code: 'treatment',
          display: 'Treatment',
        },
      ],
    },
    category: [
      {
        coding: [
          {
            system: 'http://loinc.org',
            code: '57017-6',
            display: 'Privacy policy Organization Document',
          },
        ],
      },
    ],
    patient: {
      reference: `Patient/${patientId}`,
    },
    dateTime: '2021-09-20T08:24:03+00:00',
    policyRule: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
          code: 'OPTIN',
        },
      ],
    },
    provision: {
      period: {
        start: '2021-09-20T08:24:03+00:00',
        end: '2022-09-20T08:24:03+00:00',
      },
      provision: [
        {
          type: R4.Consent_ProvisionTypeKind._deny,
          action: [
            {
              coding: [
                {
                  system: 'http://terminology.hl7.org/CodeSystem/consentaction',
                  code: 'access',
                },
              ],
            },
          ],
          securityLabel: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/v3-Confidentiality',
              code: 'R',
            },
          ],
          purpose: [
            {
              system: 'http://terminology.hl7.org/ValueSet/v3-PurposeOfUse',
              code: 'HMARKT',
            },
            {
              system: 'http://terminology.hl7.org/ValueSet/v3-PurposeOfUse',
              code: 'TREAT',
            },
          ],
          class: [
            {
              system: 'http://hl7.org/fhir/resource-types',
              code: 'Observation',
            },
            {
              system: 'http://hl7.org/fhir/resource-types',
              code: 'DiagnosticReport',
            },
            {
              system: 'http://hl7.org/fhir/resource-types',
              code: 'Condition',
            },
            {
              system: 'http://hl7.org/fhir/resource-types',
              code: 'MedicationStatement',
            },
            {
              system: 'http://hl7.org/fhir/resource-types',
              code: 'Encounter',
            },
            {
              system: 'http://hl7.org/fhir/resource-types',
              code: 'Immunization',
            },
            {
              system: 'http://hl7.org/fhir/resource-types',
              code: 'ClinicalImpression',
            },
            {
              system: 'http://hl7.org/fhir/resource-types',
              code: 'Procedure',
            },
            {
              system: 'http://hl7.org/fhir/resource-types',
              code: 'AllergyIntolerance',
            },
          ],
        },
      ],
    },
  }

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `${questionResponse.resourceType}/`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: questionResponse.resourceType,
        },
        resource: questionResponse,
      },
      {
        fullUrl: `${patientConsent.resourceType}/`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: patientConsent.resourceType,
        },
        resource: patientConsent,
      },
    ],
  }

  return requestBundle
}

export function getFhirRelatedObjectHSForm(
  patientId: string,
  serviceId: string,
  name?: string,
  phone?: string,
  email?: string,
  idType?: string,
  idnumber?: string,
  dob?: string,
  gender?: string
): R4.IRelatedPerson | undefined {
  if (name) {
    const relatedPat: R4.IRelatedPerson = {
      resourceType: 'RelatedPerson',
      patient: {
        reference: `Patient/${patientId}`,
      },
    }
    relatedPat.text = { id: `${serviceId}`, div: `${serviceId}` }
    if (phone) {
      const phoneContact: R4.IContactPoint = {}
      phoneContact.use = ContactPointUseKind._mobile
      phoneContact.system = R4.ContactPointSystemKind._phone
      phoneContact.rank = 1
      phoneContact.value = phone
      if (relatedPat.telecom == null) {
        relatedPat.telecom = [phoneContact]
      } else {
        relatedPat.telecom.push(phoneContact)
      }
    }

    if (email) {
      const emailContact: R4.IContactPoint = {}
      emailContact.use = ContactPointUseKind._home
      emailContact.system = R4.ContactPointSystemKind._email
      emailContact.rank = 1
      emailContact.value = email
      if (relatedPat.telecom == null) {
        relatedPat.telecom = [emailContact]
      } else {
        relatedPat.telecom.push(emailContact)
      }
    }

    const dateRes: E.Either<Errors, string> = R4.RTTI_date.decode(dob)
    if (dateRes._tag === 'Right') {
      relatedPat.birthDate = dateRes.right
    } else {
      throw Error('Invalid date format')
    }

    const identifier: R4.IIdentifier = {}
    identifier.use = IdentifierUseKind._usual
    identifier.system = 'mrn-tenant1'
    identifier.value = idnumber || ''
    if (relatedPat.identifier == null) {
      relatedPat.identifier = [identifier]
    } else {
      relatedPat.identifier.push(identifier)
    }

    const humanName: R4.IHumanName = {}
    const nameData: string[] = []
    if (name && name.length > 0) {
      nameData.push(name)
      humanName.given = [name]
    }
    humanName.use = HumanNameUseKind._official
    if (relatedPat.name == null) {
      relatedPat.name = [humanName]
    } else {
      relatedPat.name.push(humanName)
    }

    const relationShipCodeableList: React.SetStateAction<
      R4.ICodeableConcept[]
    > = []
    const relationShipCodeable: R4.ICodeableConcept = {}
    const relationShipCodingList: React.SetStateAction<R4.ICoding[]> = []
    const fatherRelation: R4.ICoding = {
      system: 'http://terminology.hl7.org/CodeSystem/v2-0131',
      code: 'CP',
      display: 'Contact Person',
    }
    relationShipCodingList.push(fatherRelation)
    relationShipCodeable.coding = relationShipCodingList
    relationShipCodeableList.push(relationShipCodeable)
    relatedPat.relationship = relationShipCodeableList

    return relatedPat
  }

  return undefined
}

export const resetStateForIPdFinishSadhka = () => (dispatch: AppDispatch) => {
  dispatch(ipdFinsihSadhka.actions.resetPatientDetails(initialState))
}

export default ipdFinsihSadhka.reducer
