import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'
import { WelloFormItemLabel } from './WelloFormItemLabel'

export interface IProps {
  label: string
  type?: any
  error?: null | undefined
  onSelectionChanged?: any
  availableOptions: any[]
  required: boolean
  preSelectedOption?: any
  displayField?: string
  valueField?: string
  width?: number
  id?: string
}

export const WelloSelect: React.FC<IProps> = ({
  label,
  type,
  error,
  onSelectionChanged,
  availableOptions,
  required,
  preSelectedOption,
  displayField,
  valueField,
  width,
  id,
}: IProps) => {
  const [selectedValue, setSelectedValue] = useState(
    preSelectedOption
      ? valueField
        ? preSelectedOption[valueField]
        : valueField
      : ''
  )
  return (
    <Grid container direction='column' justify='center' alignContent='stretch'>
      <Grid item xs={12}>
        <FormControl
          variant='outlined'
          {...(error && { error: true })}
          fullWidth
          size='small'
        >
          {label.length > 0 ? <WelloFormItemLabel title={label} /> : ''}

          <Select
            autoWidth={true}
            labelId='demo-simple-select-outlined-label'
            id={`${id}div` ?? 'select_sum'}
            variant='outlined'
            displayEmpty
            inputProps={{
              id: id || 'selection_field',
            }}
            value={selectedValue}
            style={{
              color: useTheme().palette.text.primary,
              maxWidth: 480,
              // height: 38,
              minWidth: width,
              backgroundColor: useTheme().palette.common.white,
            }}
            onChange={(event) => {
              event.stopPropagation()
              setSelectedValue(event.target.value)
              const changedValue = valueField
                ? availableOptions.find(
                    (item) => item[valueField] === event.target.value
                  )
                : event.target.value
              onSelectionChanged(changedValue)
            }}
          >
            <MenuItem value='' dense disabled>
              {type}
            </MenuItem>
            {availableOptions.map((option: any) => (
              <MenuItem
                id={valueField ? option[valueField] : option}
                dense
                key={valueField ? option[valueField] : option}
                value={valueField ? option[valueField] : option}
              >
                <Typography variant='subtitle2'>
                  {displayField ? option[displayField] : option}
                </Typography>
              </MenuItem>
            ))}
          </Select>

          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      </Grid>
    </Grid>
  )
}
