import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { PatientDataWithAppointment } from 'models/patientDataWithAppointment'
import { PatientPreAppointmentDetails } from 'models/patientPreAppointmentDetails'
import { PatientPreAppointmentPostModel } from 'models/patientPreAppointmentPostModel'
import { PreAppointmentDetails } from 'models/preAppointmentDetails'
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { CcClinet } from 'services/Cclinet'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getUserDetails } from 'services/userDetailsService'
import { getPatientPreAppointmentResourceObject } from 'utils/fhirResoureHelpers/invitationHelpers'
import { logger } from 'utils/logger'
import { AddPatientFormStatus } from './addPatientPreAppointmentStatus'

const initialState: AddPatientFormStatus = {
  sending: false,
  sentSuccessfully: false,
  error: false,
  errorMessage: '',
  patient: undefined,
}

const addPatientPreAppointmentFormSlice = createSlice({
  name: 'addPatientPreAppointmentFormSlice',
  initialState,
  reducers: {
    updateStatus(state, action: PayloadAction<AddPatientFormStatus>) {
      state.sending = action.payload.sending
      state.sentSuccessfully = action.payload.sentSuccessfully
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.patient = action.payload.patient
    },
  },
})

export const addPatientForm =
  (patientDetails: PatientPreAppointmentDetails[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: AddPatientFormStatus = {
      sending: true,
      sentSuccessfully: false,
      error: false,
    }
    dispatch(
      addPatientPreAppointmentFormSlice.actions.updateStatus(
        addingCreatePersonState
      )
    )

    try {
      const requestBody = getTransactionObjectPreAppointment(patientDetails)

      const enRolClient: CcClinet = new CcClinet()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        'enrolment/pre-appt/patientForm',
        requestBody[0]
      )

      const successCreatePersonState: AddPatientFormStatus = {
        sending: false,
        sentSuccessfully: true,
        error: true,
        errorMessage: '',
      }
      dispatch(
        addPatientPreAppointmentFormSlice.actions.updateStatus(
          successCreatePersonState
        )
      )
      dispatch(showSuccessAlert('Invitation sent successfully'))
      dispatch(
        addPatientPreAppointmentFormSlice.actions.updateStatus(initialState)
      )
      return
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorCreatePersonState: AddPatientFormStatus = {
        sending: false,
        sentSuccessfully: false,
        error: true,
        errorMessage: 'Error while adding patient data',
      }
      dispatch(showErrorAlert('Error while adding Patient'))
      dispatch(
        addPatientPreAppointmentFormSlice.actions.updateStatus(
          errorCreatePersonState
        )
      )
    }
  }

function getTransactionObjectPreAppointment(
  input: PatientPreAppointmentDetails[]
): any {
  const entries: PatientPreAppointmentPostModel[] = []
  input.forEach((e) => {
    const patientObject: R4.IPatient = getPatientPreAppointmentResourceObject(
      e.id,
      e.patientFirstName,
      e.patientLastName,
      e.patientEmail,
      e.patientPhoneNumber
    )

    const patientEntry: any = {
      patient: patientObject,
      appointmentType: e.patientAppointmentType,
      checkinDate: e.patientCheckInDate,
    }

    entries.push(patientEntry)
  })

  return entries
}

export const resetUpdateUserState = () => (dispatch: AppDispatch) => {
  dispatch(addPatientPreAppointmentFormSlice.actions.updateStatus(initialState))
}

export default addPatientPreAppointmentFormSlice.reducer
