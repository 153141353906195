/* eslint-disable @typescript-eslint/no-unused-expressions */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { BpCollection } from 'models/bpData'
import { Diet } from 'models/diet'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { getSortedObservation } from 'utils/appointment_handle/vitals_util'
import {
  getDietPlansPlanFromBundle,
  getDietPlansPlanFromBundleForAyurVeda,
} from 'utils/fhirResoureHelpers/ipdDietHelper'
import { getObserVationFfromEncounter } from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { getTreatmentPlanFromBundle } from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { logger } from 'utils/logger'
import { DietSearchStatus } from './dietSearchStatus'

const initialState: DietSearchStatus = {
  searchingTreatment: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingTreatment: false,
}

const dietSearchSlice = createSlice({
  name: 'dietSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<DietSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingTreatment = action.payload.searchingTreatment
      state.resultsAvailable = action.payload.resultsAvailable
      state.dateWiseDietList = action.payload.dateWiseDietList
      state.dietList = action.payload.dietList
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingTreatment =
        action.payload.errorWhileSearchingTreatment
    },
  },
})

export const requestDietDetails =
  (
    appointmentId: string,
    orderDetails: R4.IServiceRequest,
    encounterId: string,
    isAyurveda?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: DietSearchStatus = {
      searchingTreatment: true,
      errorWhileSearchingTreatment: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(dietSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: MasterFhirClient = new MasterFhirClient()
      const searchParameters: any = {
        encounter: `Encounter/${appointmentId}`,
        _count: 500,
      }

      const response: any = await fhirClient.doGetResourceForAppointmentIPD(
        `/NutritionOrder?status=active,completed`,
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)

      if (resp._tag === 'Left') {
        state.errorWhileSearchingTreatment = true
        state.searchingTreatment = false

        dispatch(dietSearchSlice.actions.updatedStatus(state))
      } else {
        const proceduresResponse: R4.IBundle = resp.right

        const dietDataList: R4.INutritionOrder[] =
          proceduresResponse.entry?.map(
            (item) => item.resource as R4.INutritionOrder
          ) ?? []
        let vitalLisData: Diet[] = []
        if (isAyurveda) {
          vitalLisData = getDietPlansPlanFromBundleForAyurVeda(
            proceduresResponse,
            orderDetails,
            encounterId
          )
        } else {
          vitalLisData = getDietPlansPlanFromBundle(
            proceduresResponse,
            orderDetails,
            encounterId
          )
        }

        console.log(vitalLisData)
        state.resultsAvailable = true
        state.searchingTreatment = false
        state.dateWiseDietList = vitalLisData
        state.dietList = dietDataList
        state.noResultsAvailable = false
        state.errorReason = undefined
        state.errorWhileSearchingTreatment = false
        dispatch(dietSearchSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: DietSearchStatus = {
        searchingTreatment: false,
        errorWhileSearchingTreatment: true,
        resultsAvailable: false,
        errorReason: 'Error while searching Treatment Plan',
      }
      dispatch(dietSearchSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

async function updateEnrolment(bpCollection: BpCollection): Promise<string> {
  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `/enrolment/update-bp`,
      bpCollection
    )
    return response.BPCategory
  } catch (error) {
    return ''
  }
}

async function updateAppointment(): Promise<string> {
  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const enRolClient: EnrolCient = new EnrolCient()
  //   const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
  //     `/enrolment/update-bp`,
  //     bpCollection
  //   )

  return 'test'
}

async function getServiceRequestId(patientId: string): Promise<string> {
  logger.info('Patient invitation body')
  let serviceRequestId: string = ''
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirClient.doGetResource(
    `/ServiceRequest??code=chronic-care-enrolment&subject=${patientId}`
  )
  logger.info('Patient invitation Response')
  logger.info(response)
  const respDecoded: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (respDecoded._tag === 'Right') {
    logger.info('Response Patient un decoded', respDecoded.right)
    const responseBundel: R4.IBundle = respDecoded.right
    if (responseBundel.entry) {
      const entryObj = responseBundel.entry?.[0].resource as R4.IServiceRequest
      serviceRequestId =
        entryObj && entryObj.status === 'active' ? entryObj.id ?? ' ' : ''
      return serviceRequestId
    }
    return serviceRequestId
  }

  return serviceRequestId
}

export default dietSearchSlice.reducer
