/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-void */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  IconButton,
} from '@material-ui/core'
import React, { FormEvent, useEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import Tooltip from '@material-ui/core/Tooltip'

import {
  selectedProductIdState,
  viewState,
} from 'models/medicine-delivery/product-states'
import { getProductDetails } from 'utils/medicineHelper/product-apis'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { PatientSearchByText } from 'views/components/patients/patientSearchByText'
import { PatientTileSplit } from 'views/components/patients/patientTileSplit'
import { WelloSelectUnit } from 'views/components/LeftMenu/welloSelectUnit'
import { OrderTab } from 'utils/constants'
import { AddressTile } from 'views/components/patients/addressTile'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  cartDoctorIdState,
  cartOrderForCachedState,
  cartPatientIdState,
  cartPatientObjectState,
  cartState,
  orderForObject,
} from '../../../../../../models/medicine-delivery/cart/cart-states'

interface Props {
  patient?: R4.IPatient
  doctor?: R4.IPractitioner
  doctorRole?: R4.IPractitionerRole
}

export const CartOrderTile: React.FC<Props> = ({
  patient,
  doctor,
  doctorRole,
}: Props) => {
  const [orderFor, setOrderFor] = useRecoilState(cartOrderForCachedState)
  const [medicineTitle, setMedicineTitle] = useState('')
  const setSelectedProductId = useSetRecoilState(selectedProductIdState)
  const setView = useSetRecoilState(viewState)
  const setCartPatientId = useSetRecoilState(cartPatientIdState)
  const [cartPatient, setCartPatient] = useRecoilState(cartPatientObjectState)
  const [orderForData, setOrderForData] = useRecoilState(orderForObject)
  const [cart, setCart] = useRecoilState<any>(cartState)

  const setCartDoctorId = useSetRecoilState(cartDoctorIdState)
  const [selectedPatient, setSelectedPatient] =
    useState<R4.IPatient | undefined>(patient)

  return (
    <Box width='100%' display='flex' flexDirection='row'>
      <Box width='80%'>
        <Grid container>
          <Grid item xs={12}>
            <Box width='100%' display='flex' flexDirection='row'>
              {orderFor === 'patient' && (
                <Box width='40%' display='flex' flexDirection='column'>
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                  >
                    <Box py={0.6} px={0.25} display='flex' flexDirection='row'>
                      <Typography variant='subtitle2'>
                        {cartPatient ? 'Selected Patient' : 'Select Patient'}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexDirection='column'>
                    {cartPatient && (
                      <Box display='flex' flexDirection='column' width='100%'>
                        <PatientTileSplit patient={cartPatient} />
                      </Box>
                    )}
                  </Box>
                </Box>
              )}

              {orderFor === 'clinic' && (
                <Box
                  width='30%'
                  display='flex'
                  flexDirection='column'
                  paddingTop={3.3}
                >
                  {getCurrentUserUnitDetails().address &&
                    getCurrentUserUnitDetails().address!.length > 0 && (
                      <AddressTile
                        addresses={getCurrentUserUnitDetails().address![0]}
                        isMedicine={true}
                      />
                    )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        width='20%'
        paddingTop={3}
        display='flex'
        flexDirection='row'
        justifyContent='flex-end'
        paddingRight={1}
      >
        <Box
          width='30%'
          display='flex'
          flexDirection='column'
          onClick={() => {
            setView('cart')
          }}
          style={{ cursor: 'pointer' }}
        >
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='flex-start'
            width='50%'
            py={0.2}
          >
            <Tooltip title='Cart'>
              <IconButton
                aria-label='btn_ord_reschedule'
                color='primary'
                style={{ padding: 0 }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/Medicine_Cart.ico`}
                  alt='ipd'
                  height='80%'
                  width='70%'
                />
              </IconButton>
            </Tooltip>
          </Box>
          <Box paddingLeft={0.4} height='100%'>
            <Box
              width='50%'
              style={{ backgroundColor: 'gray' }}
              height={orderFor === 'clinic' ? '90%' : '50%'}
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
            >
              {cart !== undefined && (
                <Typography variant='subtitle2' style={{ color: 'white' }}>
                  {cart && cart.items.length > 0 ? cart.items.length : 0}
                </Typography>
              )}
              {cart === undefined && (
                <Typography variant='subtitle2' style={{ color: 'white' }}>
                  0
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
