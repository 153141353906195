import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  getCodeOfSystemCodings,
  getCodeOfSystemFromCodableConceptList,
  getColorBasedOnNumber,
  getDefaultCodeOfSystemFromCodableConcept,
} from 'utils/fhirResourcesHelper'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getTimeOfDayDisplayForStatement,
  getTimeOfDayDisplayMeal,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import {
  getObservationCodeText,
  getObservationContent,
  getObservationContentUnit,
  getObservationContentValue,
  getObservationContentValueDiaComp,
  getObservationContentValueSysComp,
  getObsinArrangedOrder,
} from 'utils/fhirResoureHelpers/observationHelpers'
import {
  getNotesFromAllergy,
  getNotesString,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { kDialogueBackground } from 'configs/styles/muiThemes'

interface ExaminationTileProps {
  obsData: R4.IObservation[]
}

export const VitalsDataTile: React.FC<ExaminationTileProps> = ({
  obsData,
}: ExaminationTileProps) => {
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''
  const [fullText, setFullText] = React.useState<string>()
  const [showText, setShowText] = React.useState<boolean>(false)
  const displayData = getObsinArrangedOrder(obsData)
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      //   border={1}
      borderTop={2}
      borderRight={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Grid container direction='column' spacing={1}>
        {/* ------------------------------------------------------------------------------- */}

        <Box display='flex' flexDirection='column' width='100%' flexGrow={1}>
          <Grid container direction='column' id='vitals'>
            {/* <Grid container direction='row' id='vitals'>
                <Grid item xs={12}>
                  <Box width='100%' paddingLeft={0.2}>
                    <Divider
                      orientation='horizontal'
                      flexItem
                      style={{
                        height: '2px',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid> */}
            <Grid item xs={12}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                      style={{ backgroundColor: 'white' }}
                    >
                      {displayData.bodyTemp &&
                        getObservationContentValue(displayData.bodyTemp) >
                          0 && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{
                                // textTransform: 'capitalize',
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color:
                                  getObservationContentValue(
                                    displayData.bodyTemp
                                  ) > 99 ||
                                  getObservationContentValue(
                                    displayData.bodyTemp
                                  ) < 96
                                    ? 'red'
                                    : '#4d4d4d',

                                fontWeight: 600,
                                lineHeight: 'normal',
                              }}
                            >
                              {getObservationContentValue(displayData.bodyTemp)}
                            </Typography>
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{
                                // textTransform: 'capitalize',
                                fontFamily: 'Open Sans',
                                fontSize: 12,

                                fontWeight: 600,
                                lineHeight: 'normal',
                              }}
                            >
                              {getObservationContentUnit(displayData.bodyTemp)}
                            </Typography>
                          </Box>
                        )}
                    </Box>

                    <Grid container direction='row' id='vitals'>
                      <Grid item xs={12}>
                        <Box width='100%' paddingLeft={0.2}>
                          <Divider
                            orientation='horizontal'
                            flexItem
                            style={{
                              height: '2px',
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                      style={{
                        backgroundColor: kDialogueBackground,
                      }}
                    >
                      {displayData.pulseRate &&
                        getObservationContentValue(displayData.pulseRate) >
                          0 && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{
                                // textTransform: 'capitalize',
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color:
                                  getObservationContentValue(
                                    displayData.pulseRate
                                  ) > 100 ||
                                  getObservationContentValue(
                                    displayData.pulseRate
                                  ) < 60
                                    ? 'red'
                                    : '#4d4d4d',

                                fontWeight: 600,
                                lineHeight: 'normal',
                              }}
                            >
                              {getObservationContentValue(
                                displayData.pulseRate
                              )}{' '}
                            </Typography>
                            <Box paddingLeft={0.3}>
                              <Typography
                                variant='subtitle2'
                                color='initial'
                                style={{
                                  // textTransform: 'capitalize',
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,

                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                }}
                              >
                                {getObservationContentUnit(
                                  displayData.pulseRate
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                    </Box>
                    <Grid container direction='row' id='vitals'>
                      <Grid item xs={12}>
                        <Box width='100%' paddingLeft={0.2}>
                          <Divider
                            orientation='horizontal'
                            flexItem
                            style={{
                              height: '2px',
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                      style={{ backgroundColor: 'white' }}
                    >
                      {displayData.height &&
                        getObservationContentValue(displayData.height) > 0 && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{
                                // textTransform: 'capitalize',
                                fontFamily: 'Open Sans',
                                fontSize: 12,

                                fontWeight: 600,
                                lineHeight: 'normal',
                              }}
                            >
                              {getObservationContent(displayData.height)}{' '}
                            </Typography>
                          </Box>
                        )}
                    </Box>
                    <Grid container direction='row' id='vitals'>
                      <Grid item xs={12}>
                        <Box width='100%' paddingLeft={0.2}>
                          <Divider
                            orientation='horizontal'
                            flexItem
                            style={{
                              height: '2px',
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                      style={{
                        backgroundColor: kDialogueBackground,
                      }}
                    >
                      {displayData.weight &&
                        getObservationContentValue(displayData.weight) > 0 && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{
                                // textTransform: 'capitalize',
                                fontFamily: 'Open Sans',
                                fontSize: 12,

                                fontWeight: 600,
                                lineHeight: 'normal',
                              }}
                            >
                              {getObservationContent(displayData.weight)}{' '}
                            </Typography>
                          </Box>
                        )}
                    </Box>
                    <Grid container direction='row' id='vitals'>
                      <Grid item xs={12}>
                        <Box width='100%' paddingLeft={0.2}>
                          <Divider
                            orientation='horizontal'
                            flexItem
                            style={{
                              height: '2px',
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                      style={{ backgroundColor: 'white' }}
                    >
                      {displayData.rrRate &&
                        getObservationContentValue(displayData.rrRate) > 0 && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{
                                // textTransform: 'capitalize',
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color:
                                  getObservationContentValue(
                                    displayData.rrRate
                                  ) > 20 ||
                                  getObservationContentValue(
                                    displayData.rrRate
                                  ) < 12
                                    ? 'red'
                                    : '#4d4d4d',

                                fontWeight: 600,
                                lineHeight: 'normal',
                              }}
                            >
                              {getObservationContentValue(displayData.rrRate)}{' '}
                            </Typography>
                            <Box paddingLeft={0.3}>
                              <Typography
                                variant='subtitle2'
                                color='initial'
                                style={{
                                  // textTransform: 'capitalize',
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,

                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                }}
                              >
                                {getObservationContentUnit(displayData.rrRate)}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                    </Box>
                    <Grid container direction='row' id='vitals'>
                      <Grid item xs={12}>
                        <Box width='100%' paddingLeft={0.2}>
                          <Divider
                            orientation='horizontal'
                            flexItem
                            style={{
                              height: '2px',
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                      style={{
                        backgroundColor: kDialogueBackground,
                      }}
                    >
                      {displayData.sysBp &&
                        getObservationContentValue(displayData.sysBp) > 0 && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{
                                // textTransform: 'capitalize',
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color:
                                  getObservationContentValueSysComp(
                                    displayData.sysBp
                                  ) > 139 ||
                                  getObservationContentValueSysComp(
                                    displayData.sysBp
                                  ) < 90
                                    ? 'red'
                                    : '#4d4d4d',

                                fontWeight: 600,
                                lineHeight: 'normal',
                              }}
                            >
                              {getObservationContentValueSysComp(
                                displayData.sysBp
                              )}{' '}
                            </Typography>
                            <Box paddingLeft={0.3}>
                              <Typography
                                variant='subtitle2'
                                color='initial'
                                style={{
                                  // textTransform: 'capitalize',
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,

                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                }}
                              >
                                /
                              </Typography>
                            </Box>
                            <Box paddingLeft={0.3}>
                              <Typography
                                variant='subtitle2'
                                color='initial'
                                style={{
                                  // textTransform: 'capitalize',
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color:
                                    getObservationContentValueDiaComp(
                                      displayData.sysBp
                                    ) > 89 ||
                                    getObservationContentValueDiaComp(
                                      displayData.sysBp
                                    ) < 60
                                      ? 'red'
                                      : '#7d6e6e',

                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                }}
                              >
                                {getObservationContentValueDiaComp(
                                  displayData.sysBp
                                )}{' '}
                              </Typography>
                            </Box>
                            <Box paddingLeft={0.3}>
                              <Typography
                                variant='subtitle2'
                                color='initial'
                                style={{
                                  // textTransform: 'capitalize',
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,

                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                }}
                              >
                                {getObservationContentUnit(displayData.sysBp)}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                    </Box>
                    <Grid container direction='row' id='vitals'>
                      <Grid item xs={12}>
                        <Box width='100%' paddingLeft={0.2}>
                          <Divider
                            orientation='horizontal'
                            flexItem
                            style={{
                              height: '2px',
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                      style={{ backgroundColor: 'white' }}
                    >
                      {displayData.bmi &&
                        getObservationContentValue(displayData.bmi) > 0 && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              style={{
                                // textTransform: 'capitalize',
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color:
                                  getObservationContentValue(displayData.bmi) >
                                    24.9 ||
                                  getObservationContentValue(displayData.bmi) <
                                    18.5
                                    ? 'red'
                                    : '#4d4d4d',

                                fontWeight: 600,
                                lineHeight: 'normal',
                              }}
                            >
                              {getObservationContentValue(displayData.bmi)}{' '}
                            </Typography>
                            <Box paddingLeft={0.3}>
                              <Typography
                                variant='subtitle2'
                                color='initial'
                                style={{
                                  // textTransform: 'capitalize',
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,

                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                }}
                              >
                                {getObservationContentUnit(displayData.bmi)}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                    </Box>
                    <Grid container direction='row' id='vitals'>
                      <Grid item xs={12}>
                        <Box width='100%' paddingLeft={0.2}>
                          <Divider
                            orientation='horizontal'
                            flexItem
                            style={{
                              height: '2px',
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                {/* <Box display='flex' width='2%' paddingTop={0.1}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',
  
                          width: '2px',
                        }}
                      />
                    </Box> */}
              </Box>
            </Grid>
          </Grid>

          {/* <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2} paddingTop={0.5}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid> */}
        </Box>
      </Grid>
      {showText && (
        <NoteDialog
          id='notesTile'
          open={showText}
          onClose={() => {
            setFullText('')
            setShowText(false)
          }}
          notesData={fullText!}
          dialogText='Remarks'
        />
      )}
    </Box>
  )
}
