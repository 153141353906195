import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  faFilePdf,
  faFileWord,
  faImage,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core'
import moment from 'moment'
import React, { useState } from 'react'
import { DocumentReferencePopUp } from './documentRefernamceViewer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    medium: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    big: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  })
)

interface IProps {
  documentRef: R4.IDocumentReference
  size?: 'small' | 'medium' | 'large' | 'big'
}

export const DocumentReferenceTile: React.FC<IProps> = ({
  documentRef,
  size = 'small',
}: IProps) => {
  const kPrimaryColor: string = useTheme().palette.primary.main
  const classes = useStyles()
  const type = documentRef.content[0].attachment.contentType!

  const uploadedDate = documentRef.meta?.lastUpdated
  const uplodedBy = ''
  const fileName = documentRef.content[0].attachment.title ?? 'NA'
  const [showPreview, setShowPreview] = useState<boolean>(false)

  function getIconSize() {
    switch (size) {
      case 'small':
        return '1x'

      case 'medium':
        return '2x'

      case 'large':
        return '3x'
      case 'big':
        return '5x'

      default:
        return '2x'
    }
  }

  return (
    <Box
      display='flex'
      flexDirection='row'
      m={1}
      p={0.5}
      alignContent='center'
      alignItems='center'
      alignSelf='center'
      borderRadius={8}
      borderColor='grey.300'
      border={1}
    >
      <Box
        p={0.25}
        style={{
          cursor: 'pointer',
        }}
        onClick={() => {
          setShowPreview(true)
        }}
      >
        {type.toLowerCase().includes('image') && (
          <FontAwesomeIcon
            id={`${documentRef.id}_selected_icon`}
            icon={faImage}
            size={getIconSize()}
            color={kPrimaryColor}
            style={{
              color: kPrimaryColor,
            }}
          />
        )}

        {type.toLowerCase().includes('pdf') && (
          <FontAwesomeIcon
            id={`${documentRef.id}_selected_icon`}
            icon={faFilePdf}
            size={getIconSize()}
            color={kPrimaryColor}
            style={{
              color: kPrimaryColor,
            }}
          />
        )}

        {type.toLowerCase().includes('doc') && (
          <FontAwesomeIcon
            id={`${documentRef.id}_selected_icon`}
            icon={faFileWord}
            size={getIconSize()}
            color={kPrimaryColor}
            style={{
              color: kPrimaryColor,
            }}
          />
        )}
      </Box>
      <Box display='flex' flexDirection='column' p={0.25} maxWidth='35'>
        {fileName && (
          <Typography variant='subtitle2' color='initial'>
            {fileName}
          </Typography>
        )}

        {uploadedDate && (
          <Typography variant='body2' color='initial'>
            Uploaded : {moment(uploadedDate).format('DD/MMM/YYYY')}
          </Typography>
        )}
      </Box>
      {showPreview && (
        <DocumentReferencePopUp
          documentRef={documentRef}
          showPreview={showPreview}
          onCloseClicked={() => {
            setShowPreview(false)
          }}
        />
      )}
    </Box>
  )
}
