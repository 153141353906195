import { R4 } from '@ahryman40k/ts-fhir-types'

export const PartnerOrderStatusTypes: R4.ICoding[] = [
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status',
    code: 'scheduled',
    display: 'Scheduled',
  },

  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status',
    code: 'processing_samples',
    display: 'Processing Samples',
  },

  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status',
    code: 'reports_delivered',
    display: 'Reports Delivered',
  },
]

export const orderStatusTypes: R4.ICoding[] = [
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status',
    code: 'pickup_in_progress',
    display: 'Pickup in Progress',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status',
    code: 'sample_received',
    display: 'Sample Received',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status',
    code: 'rejected',
    display: 'Sample(s) Rejected',
  },

  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status',
    code: 'processing_samples',
    display: 'Processing Samples',
  },
]

export const onSiteOrderStatusTypes: R4.ICoding[] = [
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status',
    code: 'scheduled',
    display: 'Scheduled',
  },

  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status',
    code: 'sample_colleccted',
    display: 'Sample Collected',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status',
    code: 'rejected',
    display: 'Sample(s) Rejected',
  },

  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status',
    code: 'processing_samples',
    display: 'Processing Samples',
  },
]

export const reportReady: R4.ICoding[] = [
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-service-business-status-cs',
    code: 'reports_ready',
    display: 'Reports Ready',
  },
]
