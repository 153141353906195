import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
  onClose: () => void
  onYes: () => void
  notesData: string
  dialogText?: string
}
export const ConfirmationDialogMessage: React.FC<Props> = ({
  open,
  onClose,
  onYes,
  notesData,

  dialogText,
}) => {
  const { t } = useTranslation()

  function resetForm() {}

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetForm()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>{dialogText ?? 'Notes'}</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
                resetForm()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        <Box display='flex' flexDirection='row'>
          <Typography variant='body1' color='initial'>
            {notesData}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
          }}
          variant='outlined'
          disableElevation
        >
          No
        </Button>
        <Button
          onClick={() => {
            onYes()
          }}
          variant='outlined'
          disableElevation
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}
