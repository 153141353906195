import {
  Box,
  CircularProgress,
  Typography,
  Tooltip,
  IconButton,
  Collapse,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@material-ui/icons'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedWelloMedication } from 'models/groupedWelloMedication'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getMedicationsOfIPD } from 'redux/clinic/ipdmanagement/medicationListIPD/medicationsListSliceIPD'
import { getMedicationsOfAppointment } from 'redux/consultation/medicationsListSlice/medicationsListSlice'
import { RootState } from 'redux/rootReducer'
import { AddedMedicationsList } from 'views/components/consultation/assessment/medicationsListTable'
import moment from 'moment'
import {
  WelloAyurvedaMedication,
  WelloMedication,
} from 'models/welloMedication'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { showSuccessAlert, showErrorAlert } from 'redux/alertHandler/alertSlice'
import {
  addNewAyurvedaMedicationsDetails,
  deleteAyurVedaMedicationDetails,
} from 'utils/sopaNotes/plan/ayurvedaMedicationHelpers'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { isPastDate } from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { MedicationTile } from './medication_tile'
import { AddedMedicationsListIPD } from './addedMedicationIPD'
import { AddAyurvedaMedicationsHandlerIPD } from '../plan/AddAyurvedaMedicationIPD'
import { EditAyurvedaMedicationsHandlerIPD } from './editMedicationList'

interface MedicationsListProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  split?: boolean
}

export const AyurVedaMedicationsList: React.FC<MedicationsListProps> = ({
  fhirAppointmentDetails,
  split,
}: MedicationsListProps) => {
  const initialFetch = useRef(true)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const medicationsListSlice = useSelector(
    (state: RootState) => state.medicationsListSliceIPD
  )
  const [groupVitals, setGroupVitals] = useState<GroupedWelloMedication[]>([])
  const [existingMedication, setExistingMedication] =
    useState<WelloMedication>()

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const checkOutDate = moment(
    fhirAppointmentDetails.mainServiceRequest.occurrencePeriod?.end
  ).format('YYYY-MM-DD')

  const [loading, setLoading] = useState<boolean>(false)

  function deleteMedicationDetails(tempMedication: WelloAyurvedaMedication) {
    setUpdateStatus({ requesting: true })
    const medication: WelloAyurvedaMedication[] = []
    const oldMedication: R4.IMedicationRequest = {
      ...tempMedication.medicationRequest!,
    }
    oldMedication.status = 'cancelled'
    setLoading(true)

    deleteAyurVedaMedicationDetails(
      fhirAppointmentDetails.mainEncounter?.subject!,
      {
        reference: `Encounter/${fhirAppointmentDetails.mainEncounter!.id}`,
      },
      oldMedication,
      'IPD',
      fhirAppointmentDetails
    ).then((e) => {
      if (e) {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(showSuccessAlert('Medication deleted successfully'))
        dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while deleting Medication. Please try again later'
          )
        )
      }
    })
  }

  function postMedicationDetails(tempMedication: WelloAyurvedaMedication) {
    setUpdateStatus({ requesting: true })
    const medication: WelloAyurvedaMedication[] = []
    const oldMedication: WelloAyurvedaMedication = {
      ...tempMedication,
    }
    setLoading(true)
    oldMedication.startFrom = moment(tempMedication.startFrom!, 'DD-MM-YYYY')
      .add(1, 'days')
      .toDate()
    oldMedication.till = moment(tempMedication.startFrom!, 'DD-MM-YYYY')
      .add(1, 'days')
      .toDate()
    medication.push(oldMedication)
    addNewAyurvedaMedicationsDetails(
      fhirAppointmentDetails.mainEncounter?.subject!,
      {
        reference: `Encounter/${fhirAppointmentDetails.mainEncounter!.id}`,
      },
      medication,
      'IPD',
      fhirAppointmentDetails
    ).then((e) => {
      if (e) {
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Medication added successfully'))
        dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while adding Medication. Please try again later'
          )
        )
      }
    })
  }

  function onBulkRepeat(tempMedication: WelloAyurvedaMedication[]) {
    setUpdateStatus({ requesting: true })
    const medication: WelloAyurvedaMedication[] = []

    setLoading(true)
    for (let i = 0; i < tempMedication.length; i++) {
      const oldMedication: WelloAyurvedaMedication = {
        ...tempMedication[i],
      }
      oldMedication.startFrom = moment(
        tempMedication[i].startFrom!,
        'DD-MM-YYYY'
      )
        .add(1, 'days')
        .toDate()
      oldMedication.till = moment(tempMedication[i].startFrom!, 'DD-MM-YYYY')
        .add(1, 'days')
        .toDate()
      medication.push(oldMedication)
    }

    addNewAyurvedaMedicationsDetails(
      fhirAppointmentDetails.mainEncounter?.subject!,
      {
        reference: `Encounter/${fhirAppointmentDetails.mainEncounter!.id}`,
      },
      medication,
      'IPD',
      fhirAppointmentDetails
    ).then((e) => {
      if (e) {
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Medication added successfully'))
        dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while adding Medication. Please try again later'
          )
        )
      }
    })
  }

  useEffect(() => {
    dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
  }, [dispatch, fhirAppointmentDetails])

  useEffect(() => {
    if (
      medicationsListSlice.resultsAvailable &&
      medicationsListSlice.groupedMedications
    ) {
      updateMedicationList(medicationsListSlice.groupedMedications)
    }
  }, [medicationsListSlice])

  function updateMedicationList(procedureList: GroupedWelloMedication[]) {
    const results: GroupedWelloMedication[] = []
    for (let i = 0; i < procedureList.length; i++) {
      const dateData = moment(
        new Date(`${procedureList[i].date}T00:00`)
      ).format('Do MMMM, YYYY')
      console.log(dateData)
      results.push({
        date: dateData,
        medications: procedureList[i].medications,
        collapse: true,
        dateForCheck: moment(
          new Date(`${procedureList[i].date}T00:00`)
        ).toISOString(),
      })
    }
    setGroupVitals(results.sort((a, b) => moment(b.date).diff(a.date)))
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: GroupedWelloMedication[] = [...groupVitals]
    values[index].collapse = rate
    setGroupVitals(values)
  }

  return (
    <Box
      marginX={split === undefined ? 2 : 0}
      marginY={1}
      display='flex'
      flexDirection='column'
      width='100%'
    >
      {(medicationsListSlice.updatingImpressions ||
        medicationsListSlice.fetchingImpressions ||
        loading) && <CircularProgress size={15} />}

      {medicationsListSlice.noResultsAvailable && split === undefined && (
        <Box marginY={1} display='flex' flexDirection='column' width='100%'>
          <Typography variant='subtitle2'>
            &nbsp; {t('labelCommon:noMedication')}
          </Typography>
        </Box>
      )}
      {medicationsListSlice.noResultsAvailable && split && (
        <Box display='flex' flexDirection='column' width='100%'>
          <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
            {t('labelCommon:noMedication')}
          </Typography>
        </Box>
      )}
      {/* {medicationsListSlice.medications &&
        medicationsListSlice.medications.length > 0 && (
          <Box marginY={1} display='flex' flexDirection='column' width='100%'>
            <AddedMedicationsList
              medication={medicationsListSlice.medications}
              key='1'
              displayTypeOfMedication={false}
              displayStartEndDates={true}
            />
          </Box>
        )} */}

      {loading === false && (
        <Box display='flex' flexDirection='column' width='100%'>
          {groupVitals.map((e, index: number) => (
            <Box py={1} key={e.date ?? ''}>
              <Box
                paddingX={1}
                borderRadius={2}
                style={{
                  backgroundColor: 'lightGrey',
                }}
                display='flex'
                flexDirection='row'
                width='100%'
                height={40}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow={1}
                  alignItems='center'
                >
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: 'black',

                      fontWeight: 'bold',
                    }}
                  >
                    Prescribed For {e.date}
                  </Typography>
                </Box>
                <Box
                  justifyContent='flex-end'
                  display='flex'
                  flexGrow={1}
                  alignItems='center'
                >
                  <Box paddingRight={1.8}>
                    {moment(e.dateForCheck!).format('YYYY-MM-DD') >=
                      moment().format('YYYY-MM-DD') &&
                      moment(e.dateForCheck!).format('YYYY-MM-DD') <
                        checkOutDate &&
                      split === undefined && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              size='small'
                              color='primary'
                              onChange={(eve, checked) => {
                                const medicationData = [...groupVitals]
                                medicationData[index].checked =
                                  eve.target.checked
                                setGroupVitals(medicationData)

                                if (eve.target.checked === true) {
                                  onBulkRepeat(e.medications)
                                }
                              }}
                              id={`repeat${index}`}
                            />
                          }
                          label={
                            <Typography variant='subtitle2'>
                              Repeat All
                            </Typography>
                          }
                          labelPlacement='end'
                        />
                      )}
                  </Box>

                  <Box px={1}>
                    <Tooltip title=''>
                      <IconButton
                        aria-label='collapse_order_type'
                        size='small'
                        onClick={() => {
                          handleCollapseForPanel1(!e.collapse, index)
                        }}
                      >
                        {e.collapse && <ArrowDropUpOutlined />}
                        {!e.collapse && <ArrowDropDownOutlined />}
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
              <Collapse
                in={e.collapse}
                style={{
                  width: '100%',
                }}
              >
                <AddedMedicationsListIPD
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  medication={e.medications}
                  displayTypeOfMedication={true}
                  onRepeat={(medicationRepeat: WelloMedication) => {
                    postMedicationDetails(medicationRepeat)
                  }}
                  onDeleteClicked={(medicationRepeat: WelloMedication) => {
                    deleteMedicationDetails(medicationRepeat)
                  }}
                  onEditClicked={(medicationRepeat: WelloMedication) => {
                    setExistingMedication(medicationRepeat)
                  }}
                  checked={e.checked ? e.checked : undefined}
                  split={split}
                />
              </Collapse>
            </Box>
          ))}
        </Box>
      )}
      {existingMedication && (
        <EditAyurvedaMedicationsHandlerIPD
          open={existingMedication !== undefined}
          fhirAppointmentDetails={fhirAppointmentDetails}
          onClose={() => {
            dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
            setExistingMedication(undefined)
          }}
          onMedicalConditionAdded={() => {
            dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
            setExistingMedication(undefined)
          }}
          preselectedMedication={existingMedication!}
        />
      )}
    </Box>
  )
}
