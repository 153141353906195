import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { PartnerLab } from 'models/partnerLab'
import { getPractitionersWithRoleObject } from 'utils/fhirResoureHelpers/rateHelper'
import {
  getIdentifierValueBySystem,
  getTelecomOfPractitioner,
  getEmailOfPractitionerRole,
} from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { PractitionerWithRate } from 'models/practitionerWithRate'
import { MedicalProviderManagementStatus } from './medicalProviderManagementStatus'
import { searchMedicalProvidersForRate } from '../medicalProviderSearchForRate/medicalProviderSearchSliceForRate'

const initialState: MedicalProviderManagementStatus = {
  fetchingPartnerDetails: false,
  partnerDetailsAvailable: false,
  noResultsAvailable: false,
  errorWhileFetchingPartnerDetail: false,
  partnerDetailsUpdated: false,
  updatingPartnerDetail: false,
}

const medicalServiceProviderManagementSlice = createSlice({
  name: 'medicalServiceProviderManagementSlice',
  initialState,

  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<MedicalProviderManagementStatus>
    ) {
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingPartnerDetails = action.payload.fetchingPartnerDetails
      state.partnerDetailsAvailable = action.payload.partnerDetailsAvailable
      state.practDetails = action.payload.practDetails
      state.errorWhileFetchingPartnerDetail =
        action.payload.errorWhileFetchingPartnerDetail
      state.partnerDetailsUpdated = action.payload.partnerDetailsUpdated
      state.updatingPartnerDetail = action.payload.updatingPartnerDetail
    },
  },
})

export const resetUpdateStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const currentState = { ...initialState }
    dispatch(
      medicalServiceProviderManagementSlice.actions.updatedStatus(currentState)
    )
  }

export const fetchMedicalProviderDetails =
  (practId: String): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: MedicalProviderManagementStatus = { ...initialState }
    state.fetchingPartnerDetails = true
    dispatch(medicalServiceProviderManagementSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _include: 'PractitionerRole:practitioner',
        _id: `${practId}`,
      }

      const response: any = await fhirClient.doGetResource(
        `/PractitionerRole?&_include=PractitionerRole:service&_include:iterate=HealthcareService:healthcare-service-billing`,
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)

      if (resp._tag === 'Left') {
        if (response?.total && response?.total > 0) {
          const practitionerWithRoleList: PractitionerWithRate[] = []
          logger.info(':Practitioner length')
          const currentState = { ...initialState }
          currentState.partnerDetailsAvailable = true
          currentState.practDetails = practitionerWithRoleList[0]
          dispatch(
            medicalServiceProviderManagementSlice.actions.updatedStatus(
              currentState
            )
          )
        } else {
          const currentState = { ...initialState }
          currentState.noResultsAvailable = true

          dispatch(
            medicalServiceProviderManagementSlice.actions.updatedStatus(
              currentState
            )
          )
        }
      } else {
        const practitionerRolesResponses: R4.IBundle = resp.right
        if (
          practitionerRolesResponses?.total &&
          practitionerRolesResponses?.total > 0
        ) {
          const practitionerWithRoleList: PractitionerWithRate[] =
            getPractitionersWithRoleObject(practitionerRolesResponses) ?? []
          logger.info(':Practitioner length')
          const currentState = { ...initialState }
          currentState.partnerDetailsAvailable = true
          currentState.practDetails = practitionerWithRoleList[0]
          dispatch(
            medicalServiceProviderManagementSlice.actions.updatedStatus(
              currentState
            )
          )
        } else {
          const currentState = { ...initialState }
          currentState.noResultsAvailable = true

          dispatch(
            medicalServiceProviderManagementSlice.actions.updatedStatus(
              currentState
            )
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)

      const currentState = { ...initialState }
      currentState.errorWhileFetchingPartnerDetail = true

      dispatch(
        medicalServiceProviderManagementSlice.actions.updatedStatus(
          currentState
        )
      )
    }
  }

export const requestForRateUpdate =
  (
    practDetails: PractitionerWithRate,
    price: number,
    type: string,
    followups?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: MedicalProviderManagementStatus = { ...initialState }
    state.updatingPartnerDetail = true
    dispatch(medicalServiceProviderManagementSlice.actions.updatedStatus(state))
    try {
      if (practDetails) {
        const practBundle: R4.IBundle | undefined = getPractDetailsBundleObject(
          practDetails,
          price,
          type,
          followups
        )
        if (practBundle) {
          const fhirClient: FHIRApiClient = new FHIRApiClient()
          const response: any = await fhirClient.doCreateFHIRTransaction(
            '',
            practBundle
          )
          const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
            R4.RTTI_Bundle.decode(response)
          if (relatedFhirDecodeRes._tag === 'Right') {
            state.updatingPartnerDetail = false
            state.partnerDetailsUpdated = true
            if (followups) {
              dispatch(showSuccessAlert(`Follow Ups days Updated Successfully`))
            } else {
              dispatch(showSuccessAlert(`${type} Rate Updated Successfully`))
            }

            dispatch(
              fetchMedicalProviderDetails(practDetails.roleObject.id ?? '')
            )
            dispatch(searchMedicalProvidersForRate())
            dispatch(
              medicalServiceProviderManagementSlice.actions.updatedStatus(state)
            )
            return
          }
          state.updatingPartnerDetail = false
          state.errorWhileFetchingPartnerDetail = true
          dispatch(
            showErrorAlert('Error while cancelling order. Please try later')
          )
          dispatch(
            medicalServiceProviderManagementSlice.actions.updatedStatus(state)
          )
          return
        }
      }
    } catch (error) {
      logger.error(error)
      const currentState = { ...initialState }
      currentState.errorWhileFetchingPartnerDetail = true

      dispatch(
        medicalServiceProviderManagementSlice.actions.updatedStatus(
          currentState
        )
      )
    }
  }

function getPractDetailsBundleObject(
  practDetails: PractitionerWithRate,
  price: number,
  rateType: string,
  followups?: boolean
): R4.IBundle | undefined {
  if (
    practDetails.inPerson &&
    rateType === 'In Person' &&
    followups === undefined
  ) {
    const matchString: string = `W/${JSON.stringify(
      practDetails.inPerson.meta?.versionId ?? ''
    )}`
    const chargeItemDef: R4.IChargeItemDefinition | undefined = {
      ...practDetails.inPerson,
    }
    const iMoney: R4.IMoney = {}
    iMoney.value = price
    iMoney.currency = 'INR'
    const iComponent: R4.IChargeItemDefinition_PriceComponent = {}
    iComponent.amount = iMoney
    iComponent.type = 'base'
    const iPrice: R4.IChargeItemDefinition_PropertyGroup = {}
    iPrice.priceComponent = [iComponent]
    chargeItemDef.propertyGroup = [iPrice]
    if (practDetails.inPerson.extension) {
      chargeItemDef.extension = practDetails.inPerson.extension
    }
    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${chargeItemDef.resourceType}/${chargeItemDef.id}`,
          request: {
            ifMatch: matchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${chargeItemDef.resourceType}/${chargeItemDef.id}`,
          },
          resource: chargeItemDef,
        },
      ],
    }
    return requestBundle
  }

  if (practDetails.inPerson && rateType === 'In Person' && followups) {
    const matchString: string = `W/${JSON.stringify(
      practDetails.inPerson.meta?.versionId ?? ''
    )}`
    const chargeItemDef: R4.IChargeItemDefinition | undefined = {
      ...practDetails.inPerson,
    }
    chargeItemDef.propertyGroup = chargeItemDef.propertyGroup
    chargeItemDef.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext',
        valueInteger: price,
      },
    ]

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${chargeItemDef.resourceType}/${chargeItemDef.id}`,
          request: {
            ifMatch: matchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${chargeItemDef.resourceType}/${chargeItemDef.id}`,
          },
          resource: chargeItemDef,
        },
      ],
    }
    return requestBundle
  }

  if (practDetails.video && rateType === 'Phone') {
    const matchString: string = `W/${JSON.stringify(
      practDetails.video.meta?.versionId ?? ''
    )}`
    const chargeItemDef: R4.IChargeItemDefinition | undefined = {
      ...practDetails.video,
    }
    const iMoney: R4.IMoney = {}
    iMoney.value = price
    iMoney.currency = 'INR'
    const iComponent: R4.IChargeItemDefinition_PriceComponent = {}
    iComponent.amount = iMoney
    iComponent.type = 'base'
    const iPrice: R4.IChargeItemDefinition_PropertyGroup = {}
    iPrice.priceComponent = [iComponent]
    chargeItemDef.propertyGroup = [iPrice]

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${chargeItemDef.resourceType}/${chargeItemDef.id}`,
          request: {
            ifMatch: matchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${chargeItemDef.resourceType}/${chargeItemDef.id}`,
          },
          resource: chargeItemDef,
        },
      ],
    }
    return requestBundle
  }

  if (practDetails.phone && rateType === 'Video') {
    const matchString: string = `W/${JSON.stringify(
      practDetails.phone.meta?.versionId ?? ''
    )}`
    const chargeItemDef: R4.IChargeItemDefinition | undefined = {
      ...practDetails.phone,
    }
    const iMoney: R4.IMoney = {}
    iMoney.value = price
    iMoney.currency = 'INR'
    const iComponent: R4.IChargeItemDefinition_PriceComponent = {}
    iComponent.amount = iMoney
    iComponent.type = 'base'
    const iPrice: R4.IChargeItemDefinition_PropertyGroup = {}
    iPrice.priceComponent = [iComponent]
    chargeItemDef.propertyGroup = [iPrice]

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${chargeItemDef.resourceType}/${chargeItemDef.id}`,
          request: {
            ifMatch: matchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${chargeItemDef.resourceType}/${chargeItemDef.id}`,
          },
          resource: chargeItemDef,
        },
      ],
    }
    return requestBundle
  }
  return undefined
}

export default medicalServiceProviderManagementSlice.reducer
