import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitDetails,
} from 'services/userDetailsService'
import { searchingConvFee } from '../convinienceFeeSearchSlice'

import { AddConvFeeStatus } from './addingConvFeeAddStatus'

const initialState: AddConvFeeStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addConvenienceFeeSlice = createSlice({
  name: 'addConvenienceFeeSlice',
  initialState,
  reducers: {
    updateAddConditionStatus(state, action: PayloadAction<AddConvFeeStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetAllergiesDetails(state, action: PayloadAction<AddConvFeeStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
    },
  },
})

export const addConvFee =
  (
    unitType: R4.ICoding,
    serviceType: R4.ICoding,
    paymentType: string,
    amount: number,
    amountInPercentage: number
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AddConvFeeStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(
      addConvenienceFeeSlice.actions.updateAddConditionStatus(addingState)
    )
    try {
      const unitOrg = getCurrentUserUnitDetails()
      const practRole: R4.IPractitionerRole =
        getCurrentUserPractitionerRoleDetails()
      const inviteData: R4.IChargeItemDefinition = {
        resourceType: 'ChargeItemDefinition',
        status: R4.ChargeItemDefinitionStatusKind._active,
        title: 'Convenience Fee',
        jurisdiction: [
          {
            coding: [
              {
                system: 'urn:iso:std:iso:3166',
                code: 'IN',
                display: 'INDIA',
              },
            ],
          },
        ],
        extension: [
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/ChargeItemDefinitionManagingOrganization',
            valueReference: {
              id: unitOrg.id,
              reference: `Organization/${unitOrg.id}`,
              type: 'Organization',
              display: unitOrg.name,
            },
          },
        ],
        code: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-billing-codes-cs',
              code: 'convenience-fee',
              display: 'Convenience Fee',
            },
          ],
        },
        useContext: [
          {
            code: {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-usage-context-type-cs',
              code: 'unit-type',
              display: unitType.display,
            },
          },
          {
            code: {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-usage-context-type-cs',
              code: 'service-type',
              display: serviceType.display,
            },
          },
        ],
      }
      if (paymentType === 'amount') {
        inviteData.propertyGroup = [
          {
            priceComponent: [
              {
                type: 'base',
                amount: {
                  value: amount,
                  currency: 'INR',
                },
              },
            ],
          },
        ]
      } else {
        inviteData.propertyGroup = [
          {
            priceComponent: [
              {
                type: 'base',
                code: {
                  coding: [
                    {
                      system: 'http://unitsofmeasure.org',
                      code: '%',
                      display: 'percent',
                    },
                  ],
                },
                factor: parseInt((amountInPercentage / 100).toFixed(2), 10),
              },
            ],
          },
        ]
      }

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRResourceRequest(
        '/ChargeItemDefinition',
        inviteData
      )

      const respDecoded: E.Either<Errors, R4.ITask> =
        R4.RTTI_Task.decode(response)
      if (respDecoded._tag === 'Right') {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        dispatch(showSuccessAlert('Convenience Fee added successfully'))
        dispatch(searchingConvFee())

        dispatch(
          addConvenienceFeeSlice.actions.updateAddConditionStatus(addingState)
        )
      } else {
        const errorCreatePersonState: AddConvFeeStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while adding',
        }
        dispatch(
          addConvenienceFeeSlice.actions.updateAddConditionStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: AddConvFeeStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while adding',
      }
      dispatch(
        addConvenienceFeeSlice.actions.updateAddConditionStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetConvenienceFeeAdd = () => (dispatch: AppDispatch) => {
  dispatch(addConvenienceFeeSlice.actions.resetAllergiesDetails(initialState))
}

export default addConvenienceFeeSlice.reducer
