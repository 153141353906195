import { R4 } from '@ahryman40k/ts-fhir-types'
import { IAttachment } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetUpdatePatientStateMinimal,
  updatePatientData,
} from 'redux/patient/editpatientMinimal/editPatientMinimalSlice'
import { RootState } from 'redux/rootReducer'
import { getIdentifierValueBySystemTypeCoding } from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { WelloLoadingIndicator } from 'wello-web-components'
import { EditPatientWithMinimalData } from '../patients/editPatientMinimal'
import { ErrorDialog } from './errorDialog'

export interface Props {
  onPatientCreated?: (patientDetail?: R4.IPatient, patientId?: string) => void
  onError?: (message: string) => void
  isMinimal?: boolean
  onCancelClicked: () => void
  popup: any
  patientDetail?: R4.IPatient
  relatedPersonDetail?: R4.IRelatedPerson
}
export const EditPatientForPreAppointment: React.FC<Props> = ({
  onPatientCreated,
  onError,
  onCancelClicked,
  isMinimal = false,
  popup = false,
  patientDetail,
  relatedPersonDetail,
}: Props) => {
  const editPatientWithMinalState = useSelector(
    (state: RootState) => state.editPatientMinimalSlice
  )

  const dispatch = useDispatch()
  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['common', 'patient'])
  const [patientId, setPatientId] = React.useState<string>()
  const [showError, setShowError] = React.useState<boolean>(false)
  const [errorText, setErrorText] = React.useState<string>()

  useEffect(() => {
    // i18n.changeLanguage(language ?? '')
    if (editPatientWithMinalState.updatedSuccessfully) {
      if (onPatientCreated) {
        dispatch(resetUpdatePatientStateMinimal())
        if (editPatientWithMinalState.patient) {
          logger.info('Added Patient', editPatientWithMinalState.patient)
        } else {
          logger.info('Patient details not available')
        }

        onPatientCreated(
          editPatientWithMinalState.patient,
          editPatientWithMinalState.patient?.id ?? ''
        )
      }
    }
    if (editPatientWithMinalState.error) {
      //   dispatch(showErrorAlert(addPatientState.errorMessage ?? ''))
      setErrorText(
        editPatientWithMinalState.errorMessage ??
          'Something went wrong, Please try again later'
      )
      setShowError(true)

      dispatch(resetUpdatePatientStateMinimal())
      //   if (onError) {
      //     onError(addPatientState.errorMessage ?? '')
      //     dispatch(resetState())
      //   }
    }
  }, [editPatientWithMinalState, onPatientCreated, onError, dispatch])
  return (
    <div>
      {editPatientWithMinalState.updating && (
        <Grid container spacing={2} alignItems='center' wrap='nowrap'>
          <Grid item xs={12}>
            <WelloLoadingIndicator message='Saving Patient' />
          </Grid>
        </Grid>
      )}

      <EditPatientWithMinimalData
        preAddedPatient={patientDetail}
        isFullDetailsEditRequired={
          !getIdentifierValueBySystemTypeCoding(
            patientDetail!.identifier ?? [],
            'address-proof'
          )!
        }
        preAddedRelatedPatient={relatedPersonDetail}
        formLabels={{
          addressLabel: t('labelPatient:attr_labels.Patient.address'),
          emailLabel: t('labelCommon:email_address'),
          fatherNameLabel: t('labelCommon:fathers_name_label'),
          genderLabel: t('labelPatient:attr_labels.Patient.gender'),
          husbandNameLabel: t('labelCommon:husbands_name_label'),
          lNameLabel: t('labelCommon:last_name_label'),
          identityLabel: '',
          mNameLabel: t('labelCommon:middle_name_label'),
          maritalStatusLabel: t(
            'labelPatient:attr_labels.Patient.maritalStatus'
          ),
          nameLabel: t('labelCommon:first_name_label'),
          nationalityLabel: t('labelCommon:nationality_label'),
          occupationLabel: t('labelCommon:occupation_label'),
          phoneNumberLabel: t('labelCommon:phone_number_label'),
          photoLabel: t('labelPatient:attr_labels.Patient.photo'),
          referenceIdLabel: t('labelCommon:placeHolder_reference'),
          addressTypeLabel: t('labelCommon:placeHolder_addressType'),
          doblabelForKey: t('labelCommon:doblabelForKey'),
        }}
        placeholderLables={{
          addressLabel: t('labelCommon:placeHolderAddress'),
          emailLabel: t('labelCommon:placeHolder_email_address'),
          fatherNameLabel: t('labelCommon:placeHolder_fathers_name_label'),
          genderLabel: t('labelCommon:placeHolder_gender'),
          husbandNameLabel: t('labelCommon:placeHolder_husbands_name_label'),
          lNameLabel: t('labelCommon:placeHolder_last_name_label'),
          identityLabel: '',
          mNameLabel: t('labelCommon:placeHolder_middle_name_label'),
          maritalStatusLabel: t('labelCommon:placeHolder_maritalStatus'),
          nameLabel: t('labelCommon:placeHolder_first_name_label'),
          nationalityLabel: t('labelCommon:placeHolder_nationality_label'),
          occupationLabel: t('labelCommon:placeHolder_occupation_label'),
          phoneNumberLabel: t('labelCommon:placeHolder_phone_number_label'),
          photoLabel: t('labelPatient:placeHolder_photo'),
          addPatient: t('labelCommon:placeHolder_addPatient'),
          addPhoto: t('labelCommon:placeHolder_addPhoto'),
          doblabel: t('labelCommon:placeHolder_doblabel'),
          referenceIdLabel: t('labelCommon:placeHolder_reference'),
          addressTypeLabel: t('labelCommon:placeHolder_addressType'),
        }}
        resetButtonText={t('labelCommon:cancel')}
        submitButtonText={t('labelCommon:placeHolder_addPatient')}
        count={1}
        id={123}
        popupSate={popup}
        onClickToCancel={() => {
          onCancelClicked()
        }}
        onValidSubmit={(
          patientDetails: R4.IPatient,
          preAddedPatient: R4.IPatient,
          relatedPerson?: R4.IRelatedPerson,
          relatedPersonHs?: R4.IRelatedPerson,
          occupation?: string,
          nationality?: string,
          addressProofFront?: IAttachment,
          addressProofBack?: IAttachment,

          both?: IAttachment,
          referred?: string,
          relation?: R4.ICoding,
          isPrimary?: boolean,
          passportStartDate?: string,
          expiryDate?: string,
          passportIssueCity?: string,
          passportIssueCountry?: string
        ) => {
          dispatch(
            updatePatientData(
              patientDetails,
              preAddedPatient,
              relatedPerson,
              relatedPersonHs,
              nationality,
              occupation,
              both,
              addressProofFront,
              addressProofBack,
              passportStartDate,
              expiryDate,
              passportIssueCity,
              passportIssueCountry
            )
          )
        }}
      />

      <ErrorDialog
        open={showError}
        onClose={() => {
          setShowError(false)
        }}
        notesData={errorText!}
      />
    </div>
  )
}
