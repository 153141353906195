import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TcamServiceMapBase } from 'lib/openApi'
import { AppDispatch, AppThunk } from 'redux/store'
import { ServiceAddStatus } from './addServiceStatusTypes'

const initialState: ServiceAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const tcamServiceSlice = createSlice({
  name: 'addPractitioner',
  initialState,
  reducers: {
    addingServiceDetails(state, action: PayloadAction<ServiceAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
    },

    serviceDetailsAdded(state, action: PayloadAction<ServiceAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.tcamService = action.payload.tcamService
    },

    errorInAddingServiceDetails(
      state,
      action: PayloadAction<ServiceAddStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetServiceDetails(state, action: PayloadAction<ServiceAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.tcamService = action.payload.tcamService
    },
  },
})

export const addTcamServiceDetails =
  (tcamServiceDetails: TcamServiceMapBase): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreateServiceState: ServiceAddStatus = {
      adding: false,
      additionSuccessful: false,
      error: false,
    }
    dispatch(
      tcamServiceSlice.actions.addingServiceDetails(addingCreateServiceState)
    )

    try {
      /* const oidcUser: OIDCUser | null = getOIDCUserObject()
    if (oidcUser != null) {
      tcamServiceDetails.tid = oidcUser.wellopathy.tenantId
      const response:
        | TcamServiceMap
        | ErrorResponse = await TcamProfileService.createTcamServiceMap({
        requestBody: tcamServiceDetails,
      })

      const successCreateTcamServiceState: ServiceAddStatus = {
        adding: false,
        additionSuccessful: true,
        error: false,
        errorMessage: '',
        tcamService: tcamServiceDetails,
      }
      dispatch(
        tcamServiceSlice.actions.serviceDetailsAdded(
          successCreateTcamServiceState
        )
      )
    } else {
      // TODO Log out user
    } */
      // todo dfasdfasdf
    } catch (error) {
      const errorCreatePersonState: ServiceAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Service already exists',
      }
      dispatch(
        tcamServiceSlice.actions.errorInAddingServiceDetails(
          errorCreatePersonState
        )
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(tcamServiceSlice.actions.resetServiceDetails(initialState))
}

export default tcamServiceSlice.reducer
