import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Divider } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestCdsRecommendationsOfPatient } from 'redux/patientMedicalHistory/cds_recommendations/cdsRecommendationsSlice'
import { CardsEntity } from 'redux/patientMedicalHistory/cds_recommendations/cdsResponse'
import { RootState } from 'redux/rootReducer'
import { WelloLoadingIndicator } from 'wello-web-components'
import { CdsCardSummary } from './cdsCardDetailsDisply'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ overflow: 'auto', height: '100%' }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

interface MedicationsListProps {
  patient: R4.IPatient
  fhirPatientDetail: FhirActiveIPDDetailsForMedicalRole
}

export const CdsRecommendationsForAppointmentIPD: React.FC<MedicationsListProps> =
  ({ patient, fhirPatientDetail }: MedicationsListProps) => {
    const { t } = useTranslation(['common'])
    const dispatch = useDispatch()
    const [selectedCard, setSelectedCard] = useState<CardsEntity>()

    const patientMedicalTimeLineSlice = useSelector(
      (state: RootState) => state.cdsRecommendationsSlice
    )

    useEffect(() => {
      dispatch(
        requestCdsRecommendationsOfPatient(patient, 'IPD', undefined, undefined)
      )
    }, [dispatch, patient, fhirPatientDetail])

    useEffect(() => {
      if (patientMedicalTimeLineSlice.resultsAvailable === true) {
        if (
          selectedCard === undefined &&
          patientMedicalTimeLineSlice.availableAppointments &&
          patientMedicalTimeLineSlice.availableAppointments.cards
        ) {
          setSelectedCard(
            patientMedicalTimeLineSlice.availableAppointments.cards[0]
          )
        }
      }
    }, [patientMedicalTimeLineSlice])

    return (
      <Box
        display='flex'
        flexDirection='column'
        overflow='auto'
        style={{ overflow: 'auto' }}
        width='100%'
        alignContent='center'
        minHeight='300px'
      >
        {patientMedicalTimeLineSlice.searchingAppointments && (
          <Box
            display='flex'
            flexDirection='row'
            overflow='auto'
            alignContent='center'
            alignItems='center'
            justifyContent='center'
            style={{ overflow: 'auto' }}
            width='100%'
            minHeight='300px'
          >
            <WelloLoadingIndicator />
          </Box>
        )}

        {patientMedicalTimeLineSlice.errorWhileSearchingAppointments &&
          patientMedicalTimeLineSlice.errorReason && (
            <Box>
              {' '}
              {JSON.stringify(patientMedicalTimeLineSlice.errorReason)}
            </Box>
          )}

        {patientMedicalTimeLineSlice.resultsAvailable &&
          patientMedicalTimeLineSlice.availableAppointments &&
          patientMedicalTimeLineSlice.availableAppointments.cards && (
            <Box
              display='flex'
              flexDirection='column'
              overflow='auto'
              alignContent='center'
              flexGrow={1}
            >
              {patientMedicalTimeLineSlice.availableAppointments.cards.map(
                (card, index) => (
                  <Box
                    display='flex'
                    flexDirection='column'
                    overflow='auto'
                    paddingTop={1}
                    key={`${card.summary}_details_tab`}
                  >
                    <CdsCardSummary
                      cdsCard={card}
                      fhirPatientDetail={fhirPatientDetail}
                      patient={patient}
                      mainIndex={index}
                    />
                    <Divider />
                  </Box>
                )
              )}
            </Box>
          )}

        <Box display='flex' flexDirection='row' overflow='auto'>
          {/* <Box p={1}>
            <EbmButton fhirAppointmentDetails={fhirPatientDetail} />
          </Box> */}

          {/* <Box p={1}>
            <CohartButton fhirAppointmentDetails={fhirPatientDetail} />
          </Box> */}
          {/* <Box p={1}>
                                <CohortTableReport fhirAppointmentDetails={fhirPatientDetail} />
                            </Box>
                            <Box p={1}>
                                <BratAnnoTation fhirAppointmentDetails={fhirPatientDetail} />
                            </Box> */}
        </Box>
      </Box>
    )
  }
