import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Divider,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirClinicalImpressionDetail } from 'models/fhirClinicalImpression'
import { UmlResponse } from 'models/umlResponse'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { showWarningAlert } from 'redux/alertHandler/alertSlice'
import { updateAppointmentStatus } from 'redux/appointments/appointmentViewHandler/appointmentHandlerSlice'
import { requestForButtonEnabled } from 'redux/carePlanSubcription/carePlanSubscribeSlice'
import {
  getClinicalImpressionsOfAppointment,
  resetState,
  updateClinicalImpressions,
} from 'redux/consultation/assessmentDetailsSlice/assessmentDetailsSlice'
import { requestAssessmentMedicalConditions } from 'redux/consultation/assessmentMedicalCondition/medicalAssessmentSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { getHyperTensionSubscriptionsOfPatient } from 'redux/patient/patientsSubscriptions/patientSubscriptionsSlice'
import { RootState } from 'redux/rootReducer'
import { isAyurvedaDoctor, isDoctor } from 'services/userDetailsService'
import { isDuplicateInList } from 'utils/appointment_handle/assessementUtils'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultCodingOfSystemFromCodableConceptList,
} from 'utils/fhirResourcesHelper'
import {
  hasAllergies,
  hasConditions,
  hasSameSummary,
} from 'utils/fhirResoureHelpers/clinicalImpressionHelper'
import { logger } from 'utils/logger'
import { PhysicalExamination } from 'views/components/referralHistory.tsx/assessmentHistory/physicalExamination'
import { SOAPNumberedElement } from 'wello-web-components'
import { ObesityObjective } from '../objective/obesityObjective'
import { ConditionTile } from '../objective/tiles/conditionTile'
import { AllergyMasterSelector } from '../subjective/allergyMasterSelector'
import { ConditionMasterSelector } from '../subjective/conditionMasterSelector'
import { DoshaVikruthiAssessment } from './ayurvedaAssessment/doshaVikruthiAssessment'
import { DushyaAssessment } from './ayurvedaAssessment/dushyaAssessment'
import { MentalStatusAssessment } from './ayurvedaAssessment/mentalStatusAssessment'
import { PrakrithiAssessment } from './ayurvedaAssessment/prakrithiAssessment'
import { MedicalConditionsForAssessment } from './medicalConditions'
import { ObesityAssessment } from './obesityAccessment'
import { ObesitySkinAssessment } from './obesitySkin'

import { SystemicExaminationList } from './systemicExamination/systemicExaminationList'

interface AssessmentCaptureProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
}

export const AssessmentCaptureDetails: React.FC<AssessmentCaptureProps> = ({
  fhirAppointmentDetails,
  split,
}: AssessmentCaptureProps) => {
  const [summary, setSummary] = React.useState<string>()

  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const [diagnosedConditions, setDiagnosedConditions] = useState<
    R4.ICodeableConcept[]
  >([])

  const [allergiesData, setAllergiesData] = useState<R4.IAllergyIntolerance[]>(
    []
  )

  const selecteDiagnosedConditions = useRef<R4.ICodeableConcept[]>()
  const selecteDiagnosedAllergies = useRef<R4.ICoding[]>()

  const preSelectedSurgery = useRef<UmlResponse[]>()
  //   const [preSelectedSurgery, setPreSelectedSurgery] = useState<UmlResponse[]>(
  //     []
  //   )
  const preSelectedAllergyData = useRef<UmlResponse[]>()
  //   const [preSelectedSurgery, setPreSelectedSurgery] = useState<UmlResponse[]>(
  //     []
  //   )
  const [preSelectedAllergy, setPreSelectedAllergy] = useState<UmlResponse[]>(
    []
  )
  const [diagnosedAllergies, setDiagnosedAllergies] = useState<R4.ICoding[]>([])
  /*  const [findings, setFindings] = useState<R4.IClinicalImpression_Finding[]>([]) */
  const assessmentDetailsSlice = useSelector(
    (state: RootState) => state.assessmentDetailsSlice
  )
  const patientSubscriptionsSlice = useSelector(
    (state: RootState) => state.patientSubscriptionsSlice
  )

  const carePlanSubscriptionSlice = useSelector(
    (state: RootState) => state.carePlanSubscriptionSlice
  )
  const [isActive, setIsActive] = useState<boolean>(false)

  const [conditionPreDefined, setConditionPreDefined] =
    useState<FhirClinicalImpressionDetail>()

  function updateDetails(
    conditon: string,
    operation: string,
    deletedData?: R4.ICoding
  ) {
    if (selecteDiagnosedConditions.current !== undefined) {
      if (
        !hasSameSummary(
          summary,
          assessmentDetailsSlice.clinicalImpressions?.clinicalImpression
        ) ||
        !hasAllergies(
          selecteDiagnosedAllergies.current ?? [],
          assessmentDetailsSlice.clinicalImpressions!
        ) ||
        !hasConditions(
          selecteDiagnosedConditions.current ?? [],
          assessmentDetailsSlice.clinicalImpressions!
        )
      ) {
        dispatch(
          updateClinicalImpressions(
            conditon,
            operation,
            fhirAppointmentDetails,
            summary ?? '',
            [],
            selecteDiagnosedConditions.current,
            selecteDiagnosedAllergies.current ?? [],
            deletedData,
            allergiesData
          )
        )
      } else {
        dispatch(
          updateClinicalImpressions(
            conditon,
            operation,
            fhirAppointmentDetails,
            summary ?? '',
            [],
            selecteDiagnosedConditions.current,
            selecteDiagnosedAllergies.current ?? [],
            deletedData,
            allergiesData
          )
        )
      }
    } else {
      dispatch(
        updateClinicalImpressions(
          conditon,
          operation,
          fhirAppointmentDetails,
          summary ?? '',
          [],
          selecteDiagnosedConditions.current,
          selecteDiagnosedAllergies.current ?? [],
          deletedData,
          allergiesData
        )
      )
    }
  }

  //   useEffect(() => {
  //     if (selecteDiagnosedConditions.current !== undefined) {
  //       updateDetails()
  //     }
  //   }, [summary, diagnosedConditions, diagnosedAllergies])

  useEffect(() => {
    if (assessmentDetailsSlice.updatedImpressions) {
      dispatch(updateAppointmentStatus(fhirAppointmentDetails.appointment.id!))
      dispatch(resetState())
    } else {
      dispatch(getClinicalImpressionsOfAppointment(fhirAppointmentDetails))
      dispatch(
        requestAssessmentMedicalConditions(
          fhirAppointmentDetails.patient,
          fhirAppointmentDetails.appointment.id
        )
      )
      dispatch(resetState())
    }
  }, [dispatch, fhirAppointmentDetails])

  useEffect(() => {
    dispatch(requestForButtonEnabled(fhirAppointmentDetails.patient))
    if (assessmentDetailsSlice.updatedImpressions) {
      dispatch(getClinicalImpressionsOfAppointment(fhirAppointmentDetails))
      dispatch(
        requestAssessmentMedicalConditions(
          fhirAppointmentDetails.patient,
          fhirAppointmentDetails.appointment.id
        )
      )
    }
    if (assessmentDetailsSlice.resultsAvailable) {
      if (assessmentDetailsSlice.clinicalImpressions) {
        updateAssessmentDetails(assessmentDetailsSlice.clinicalImpressions)
        dispatch(resetState())
      }
    }
  }, [assessmentDetailsSlice])

  const updateAssessmentDetails = (condition: FhirClinicalImpressionDetail) => {
    setConditionPreDefined(condition)
    const conditonDataArr: UmlResponse[] = []
    const allergyDataArr: UmlResponse[] = []
    logger.info('FhirAssessment Details,', condition)
    if (condition.clinicalImpression.summary) {
      setSummary(condition.clinicalImpression.summary)
    }
    setAllergiesData(condition.allergies ?? [])
    if (
      condition.diagnosedAllergyCodes &&
      condition.diagnosedAllergyCodes.length > 0
    ) {
      for (let i = 0; i < condition.diagnosedAllergyCodes.length; i++) {
        const allergyData: UmlResponse = {
          cui: condition.diagnosedAllergyCodes[i].code ?? '',
          snomed_ct: condition.diagnosedAllergyCodes[i].code ?? '',
          display: condition.diagnosedAllergyCodes[i].display ?? '',
          label: condition.diagnosedAllergyCodes[i].display ?? '',
        }
        allergyDataArr.push(allergyData)
      }
      selecteDiagnosedAllergies.current = condition.diagnosedAllergyCodes
      preSelectedAllergyData.current = allergyDataArr
      setPreSelectedAllergy(allergyDataArr)
      setDiagnosedAllergies(condition.diagnosedAllergyCodes)
    } else {
      setDiagnosedAllergies([])
    }

    if (
      condition.diagnosedConditionCodes &&
      condition.diagnosedConditionCodes.length > 0
    ) {
      for (let i = 0; i < condition.diagnosedConditionCodes.length; i++) {
        const conditonData: UmlResponse = {
          cui: condition.diagnosedConditionCodes[i].code ?? '',
          snomed_ct: condition.diagnosedConditionCodes[i].code ?? '',
          display: condition.diagnosedConditionCodes[i].display ?? '',
          label: condition.diagnosedConditionCodes[i].display ?? '',
        }
        conditonDataArr.push(conditonData)
      }

      selecteDiagnosedConditions.current = condition.diagnosedConditionCodes

      preSelectedSurgery.current = conditonDataArr
      setDiagnosedConditions(condition.diagnosedConditionCodes)
    } else {
      setDiagnosedConditions([])
    }
  }

  useEffect(() => {
    dispatch(
      getHyperTensionSubscriptionsOfPatient(fhirAppointmentDetails.patient)
    )
  }, [dispatch])

  return (
    <Box
      style={{ paddingLeft: '4px' }}
      display='flex'
      flexDirection='row'
      width='100%'
    >
      <Grid
        container
        spacing={0}
        direction='column'
        style={{
          padding: '0px',
          margin: '0px',
        }}
      >
        <Grid
          item
          style={{
            padding: '0px',
            margin: '0px',
          }}
        >
          {' '}
          <Box
            display='flex'
            py={split ? 0.5 : 2.5}
            flexDirection='column'
            width='100%'
            px={0.5}
          >
            <Box
              borderRadius={2}
              style={{
                backgroundColor: 'lightGrey',
              }}
              display='flex'
              flexDirection='row'
              width='100%'
              height={40}
              px={2}
            >
              <Box
                justifyContent='flex-start'
                display='flex'
                flexGrow={1}
                alignItems='center'
              >
                <Typography
                  variant='subtitle1'
                  style={{
                    color: 'black',

                    fontWeight: 'bold',
                  }}
                >
                  Signs on Examination
                </Typography>
              </Box>
            </Box>
            <Box display='flex' py={split ? 0.5 : 2.5}>
              <PhysicalExamination
                fhirAppointmentDetails={fhirAppointmentDetails}
                split={split}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xl>
          <ReactVisibilitySensor
            offset={{
              top: 100,
              bottom: 30,
            }}
            onChange={(isVisible) => {
              if (isVisible) {
                dispatch(
                  setSelectedSection({
                    selectedSection: 'systemic_examination',
                  })
                )
              }
            }}
          >
            <Box
              display='flex'
              py={split ? 0.5 : 2.5}
              flexDirection='column'
              width='100%'
            >
              <Box
                borderRadius={2}
                style={{
                  backgroundColor: 'lightGrey',
                }}
                display='flex'
                flexDirection='row'
                width='100%'
                height={40}
                px={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow={1}
                  alignItems='center'
                >
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: 'black',

                      fontWeight: 'bold',
                    }}
                  >
                    Systemic Examination
                  </Typography>
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                paddingTop={split ? 0.5 : 1.5}
              >
                <SystemicExaminationList
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  isReadOnly={false}
                />
              </Box>
            </Box>
          </ReactVisibilitySensor>
        </Grid>

        <Grid
          item
          style={{
            padding: '0px',
            margin: '0px',
          }}
        >
          <ReactVisibilitySensor
            offset={{
              top: 100,
              bottom: 30,
            }}
            onChange={(isVisible) => {
              if (isVisible) {
                dispatch(
                  setSelectedSection({
                    selectedSection: 'systemic_examination',
                  })
                )
              }
            }}
          >
            <Box
              display='flex'
              flexDirection='column'
              width={split ? '100%' : '100%'}
            >
              <Box display='flex' py={split ? 0.5 : 2.5}>
                <ObesityAssessment
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  isReadOnly={split}
                />
              </Box>
            </Box>
          </ReactVisibilitySensor>
        </Grid>

        <Grid
          item
          style={{
            padding: '0px',
            margin: '0px',
          }}
        >
          <ReactVisibilitySensor
            offset={{
              top: 100,
              bottom: 30,
            }}
            onChange={(isVisible) => {
              if (isVisible) {
                dispatch(
                  setSelectedSection({
                    selectedSection: 'systemic_examination',
                  })
                )
              }
            }}
          >
            <Box
              display='flex'
              flexDirection='column'
              width={split ? '100%' : '100%'}
            >
              <Box display='flex' py={split ? 0.5 : 2.5}>
                <ObesitySkinAssessment
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  isReadOnly={split}
                />
              </Box>
            </Box>
          </ReactVisibilitySensor>
        </Grid>

        {isAyurvedaDoctor() && (
          <Grid
            item
            style={{
              padding: '0px',
              margin: '0px',
            }}
          >
            <ReactVisibilitySensor
              offset={{
                top: 100,
                bottom: 30,
              }}
              onChange={(isVisible) => {
                if (isVisible) {
                  dispatch(
                    setSelectedSection({
                      selectedSection: 'systemic_examination',
                    })
                  )
                }
              }}
            >
              <Box
                display='flex'
                flexDirection='column'
                width={split ? '100%' : '100%'}
              >
                <Box display='flex' py={split ? 0.5 : 2.5}>
                  <PrakrithiAssessment
                    fhirAppointmentDetails={fhirAppointmentDetails}
                    isReadOnly={split}
                  />
                </Box>
              </Box>
            </ReactVisibilitySensor>
          </Grid>
        )}

        {isAyurvedaDoctor() && (
          <Grid
            item
            style={{
              padding: '0px',
              margin: '0px',
            }}
          >
            <ReactVisibilitySensor
              offset={{
                top: 100,
                bottom: 30,
              }}
              onChange={(isVisible) => {
                if (isVisible) {
                  dispatch(
                    setSelectedSection({
                      selectedSection: 'systemic_examination',
                    })
                  )
                }
              }}
            >
              <Box
                display='flex'
                flexDirection='column'
                width={split ? '100%' : '100%'}
              >
                <Box display='flex' py={split ? 0.5 : 2.5}>
                  <MentalStatusAssessment
                    fhirAppointmentDetails={fhirAppointmentDetails}
                    isReadOnly={split}
                  />
                </Box>
              </Box>
            </ReactVisibilitySensor>
          </Grid>
        )}

        {isAyurvedaDoctor() && (
          <Grid
            item
            style={{
              padding: '0px',
              margin: '0px',
            }}
          >
            <ReactVisibilitySensor
              offset={{
                top: 100,
                bottom: 30,
              }}
              onChange={(isVisible) => {
                if (isVisible) {
                  dispatch(
                    setSelectedSection({
                      selectedSection: 'systemic_examination',
                    })
                  )
                }
              }}
            >
              <Box
                display='flex'
                flexDirection='column'
                width={split ? '100%' : '100%'}
              >
                <Box display='flex' py={split ? 0.5 : 2.5}>
                  <DoshaVikruthiAssessment
                    fhirAppointmentDetails={fhirAppointmentDetails}
                    isReadOnly={true}
                  />
                </Box>
              </Box>
            </ReactVisibilitySensor>
          </Grid>
        )}

        {isAyurvedaDoctor() && (
          <Grid
            item
            style={{
              padding: '0px',
              margin: '0px',
            }}
          >
            <ReactVisibilitySensor
              offset={{
                top: 100,
                bottom: 30,
              }}
              onChange={(isVisible) => {
                if (isVisible) {
                  dispatch(
                    setSelectedSection({
                      selectedSection: 'systemic_examination',
                    })
                  )
                }
              }}
            >
              <Box
                display='flex'
                flexDirection='column'
                width={split ? '100%' : '100%'}
              >
                <Box display='flex' py={split ? 0.5 : 2.5}>
                  <DushyaAssessment
                    fhirAppointmentDetails={fhirAppointmentDetails}
                    isReadOnly={true}
                  />
                </Box>
              </Box>
            </ReactVisibilitySensor>
          </Grid>
        )}

        <Grid item xl>
          <ReactVisibilitySensor
            offset={{
              top: 100,
              bottom: 30,
            }}
            onChange={(isVisible) => {
              if (isVisible) {
                dispatch(
                  setSelectedSection({ selectedSection: 'visit_summary' })
                )
              }
            }}
          >
            <Grid container spacing={1} direction='column' id='visit_summary'>
              <Grid item xs={12}>
                <Box display='flex' py={split ? 0.5 : 2.5}>
                  <Box flexGrow={1}>
                    <Box
                      borderRadius={2}
                      style={{
                        backgroundColor: 'lightGrey',
                      }}
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      height={40}
                      px={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow={1}
                        alignItems='center'
                      >
                        <Typography
                          variant='subtitle1'
                          style={{
                            color: 'black',

                            fontWeight: 'bold',
                          }}
                        >
                          {isAyurvedaDoctor()
                            ? 'Provisional Diagnosis'
                            : t('labelCommon:Patient Summary')}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {(assessmentDetailsSlice.updatingImpressions ||
                    assessmentDetailsSlice.fetchingImpressions) && (
                    <CircularProgress size={15} />
                  )}
                </Box>
              </Grid>
              <Grid item xs={split ? 12 : 10}>
                {split && (
                  <Typography
                    variant='subtitle2'
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    {summary === undefined || summary.trim().length === 0
                      ? 'No data available'
                      : ''}
                  </Typography>
                )}

                {!split && (
                  <TextField
                    id='consult_assessment_summary_tf'
                    value={summary}
                    fullWidth
                    multiline={true}
                    disabled={split}
                    variant='outlined'
                    InputProps={{
                      color: 'primary',

                      endAdornment: (
                        <InputAdornment position='end'>
                          {' '}
                          <Button
                            variant='contained'
                            color='primary'
                            disabled={
                              !summary ||
                              summary.trim().length === 0 ||
                              (conditionPreDefined &&
                                hasSameSummary(
                                  summary.trim(),
                                  conditionPreDefined.clinicalImpression
                                )) ||
                              assessmentDetailsSlice.updatingImpressions
                            }
                            id='done'
                            onClick={() => {
                              updateDetails('summary', 'add')
                            }}
                          >
                            {t('labelCommon:done')}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => {
                      setSummary(event.target.value)
                    }}
                  />
                )}
                {split && summary && (
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-start'
                    width='100%'
                    borderLeft={2}
                    borderRight={2}
                    style={{
                      boxShadow: '0px 0px 4px #ccc',
                      margin: '2px 0px',
                      borderRadius: '1px',
                      borderColor: '#F8F8F8',
                      width: '100%',
                      height: 'auto',
                      backgroundColor: '#F8F8F8',
                    }}
                  >
                    <Grid container direction='row'>
                      {/* ------------------------------------------------------------------------------- */}

                      <Grid item xs={12}>
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          height={30}
                          alignItems='center'
                          px={1}
                        >
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {summary}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Grid>
            </Grid>
          </ReactVisibilitySensor>
        </Grid>

        {/* {carePlanSubscriptionSlice.noResultsAvailable && (
            <Grid item>
              <Typography> Medical condition history not available</Typography>
            </Grid>
          )}
          {carePlanSubscriptionSlice.resultsAvailable &&
            carePlanSubscriptionSlice.subscribed &&
            patientSubscriptionsSlice.resultsAvailable &&
            (patientSubscriptionsSlice.hCarePlans === undefined ||
              patientSubscriptionsSlice.hCarePlans?.length === 0) && (
              <Grid item>
                <Box p={1} bgcolor='#FDFFCD' width='20%'>
                  <SubscribeToHypertensionCarePlan
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                </Box>
              </Grid>
            )} */}
        <Grid item>
          <ReactVisibilitySensor
            onChange={(isVisible) => {
              if (isVisible) {
                dispatch(setSelectedSection({ selectedSection: 'diagnosis' }))
              }
            }}
          >
            <Grid container direction='column'>
              <Grid item id='diagnosis'>
                <Grid
                  container
                  direction='row'
                  style={{ padding: 0, margin: 0 }}
                >
                  {assessmentDetailsSlice.resultsAvailable &&
                    assessmentDetailsSlice.clinicalImpressions && (
                      <Box py={1}>
                        <Typography variant='h5' color='primary'>
                          {' '}
                          {t('labelCommon:diagnosis')}{' '}
                        </Typography>
                      </Box>
                    )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {!split && (
                  <Box display='flex' py={split ? 0.5 : 2.5}>
                    <Box flexGrow={1}>
                      <Box
                        borderRadius={2}
                        style={{
                          backgroundColor: 'lightGrey',
                        }}
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        height={40}
                        px={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow={1}
                          alignItems='center'
                        >
                          <Typography
                            variant='subtitle1'
                            style={{
                              color: 'black',

                              fontWeight: 'bold',
                            }}
                          >
                            Select Condition
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <Box width='100%'>
                  {preSelectedSurgery.current && !split && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='start'
                      alignContent='start'
                      alignItems='start'
                      justifyItems='start'
                      width='83%'
                    >
                      <ConditionMasterSelector
                        disabled={false}
                        selectOther={isActive}
                        onSelectionChanges={(conditions: UmlResponse[]) => {
                          logger.info(conditions)
                          logger.info(conditions)
                          const conditonValArr: R4.ICodeableConcept[] = []
                          for (let i = 0; i < conditions.length; i++) {
                            const conditonVal: R4.ICodeableConcept = {
                              coding: [
                                {
                                  code: conditions[i].snomed_ct,
                                  system: 'http://snomed.info/sct',
                                  display: conditions[i].display,
                                },
                                {
                                  code: conditions[i].cui.toUpperCase(),
                                  system:
                                    'http://terminology.hl7.org/CodeSystem/umls',
                                  display: conditions[i].display.trim(),
                                },
                              ],
                              text: conditions[i].label,
                            }
                            const remainData = isDuplicateInList(
                              diagnosedConditions ?? [],
                              conditonVal
                            )
                            if (remainData) {
                              //   dispatch(
                              //     showWarningAlert('Condition is already added')
                              //   )
                            } else {
                              conditonValArr.push(conditonVal)
                            }
                          }

                          if (diagnosedConditions.length > 0) {
                            const condArr =
                              conditonValArr.concat(diagnosedConditions)
                            selecteDiagnosedConditions.current = conditonValArr
                          } else {
                            selecteDiagnosedConditions.current = conditonValArr
                          }
                          updateDetails('condition', 'add')
                        }}
                        onDelete={(conditions: UmlResponse) => {
                          logger.info(conditions)
                          logger.info(conditions)
                          const conditonValArr: R4.ICoding[] = []
                          const conditonVal: R4.ICodeableConcept = {
                            coding: [
                              {
                                code: conditions.snomed_ct,
                                system: 'http://snomed.info/sct',
                                display: conditions.display,
                              },
                              {
                                code: conditions.cui.toUpperCase(),
                                system:
                                  'http://terminology.hl7.org/CodeSystem/umls',
                                display: conditions.display.trim(),
                              },
                            ],
                            text: conditions.label,
                          }
                          conditonValArr.push(conditonVal)
                          if (selecteDiagnosedConditions.current) {
                            selecteDiagnosedConditions.current =
                              selecteDiagnosedConditions.current.filter(
                                (ref) =>
                                  getDefaultCodeOfSystemFromCodableConcept(
                                    ref
                                  ) !== conditions.cui
                              )
                          }

                          updateDetails('condition', 'delete', conditonVal)
                        }}
                        preSelectedComplaints={preSelectedSurgery.current}
                      />
                    </Box>
                  )}

                  {!preSelectedSurgery.current && !split && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='start'
                      alignContent='start'
                      alignItems='start'
                      justifyItems='start'
                      width='83%'
                    >
                      <ConditionMasterSelector
                        disabled={false}
                        selectOther={isActive}
                        onSelectionChanges={(conditions: UmlResponse[]) => {
                          logger.info(conditions)
                          logger.info(conditions)
                          const conditonValArr: R4.ICodeableConcept[] = []
                          for (let i = 0; i < conditions.length; i++) {
                            const conditonVal: R4.ICodeableConcept = {
                              coding: [
                                {
                                  code: conditions[i].snomed_ct,
                                  system: 'http://snomed.info/sct',
                                  display: conditions[i].display,
                                },
                                {
                                  code: conditions[i].cui.toUpperCase(),
                                  system:
                                    'http://terminology.hl7.org/CodeSystem/umls',
                                  display: conditions[i].display.trim(),
                                },
                              ],
                              text: conditions[i].label,
                            }
                            const remainData = isDuplicateInList(
                              diagnosedConditions,
                              conditonVal
                            )
                            if (remainData) {
                              dispatch(
                                showWarningAlert('Condition is already added')
                              )
                            } else {
                              conditonValArr.push(conditonVal)
                            }
                          }

                          if (diagnosedConditions.length > 0) {
                            const condArr =
                              conditonValArr.concat(diagnosedConditions)
                            selecteDiagnosedConditions.current = condArr
                          } else {
                            selecteDiagnosedConditions.current = conditonValArr
                          }
                          updateDetails('condition', 'add')
                        }}
                        onDelete={(conditions: UmlResponse) => {
                          logger.info(conditions)
                          logger.info(conditions)
                          const conditonValArr: R4.ICoding[] = []
                          const conditonVal: R4.ICoding = {
                            code: conditions.cui,
                            display: conditions.label,
                          }
                          conditonValArr.push(conditonVal)

                          updateDetails('condition', 'delete', conditonVal)
                        }}
                        preSelectedComplaints={preSelectedSurgery.current}
                      />
                    </Box>
                  )}
                  {/* 
                  {diagnosedConditions && diagnosedConditions.length > 0 && (
                    <Box
                      flexGrow
                      width='100%'
                      display='flex'
                      flexDirection='column'
                      paddingY={1}
                    >
                      <Box
                        paddingX={1}
                        borderRadius={2}
                        style={{
                          backgroundColor: 'lightGrey',
                        }}
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        height={40}
                        paddingY={1}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow={1}
                          alignItems='center'
                        >
                          <Typography
                            variant='subtitle1'
                            style={{
                              color: 'black',

                              fontWeight: 'bold',
                            }}
                          >
                            Condition
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        {diagnosedConditions && diagnosedConditions.length > 0 && (
                          <Box>
                            <ConditionTile obsData={diagnosedConditions} />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )} */}
                </Box>
                <Box width={split ? '100%' : '83%'}>
                  <MedicalConditionsForAssessment
                    patient={fhirAppointmentDetails.patient}
                    requestingAppointmentId={
                      fhirAppointmentDetails.appointment.id
                    }
                  />
                </Box>
              </Grid>

              {/* {!diagnosedConditions && <Box>test</Box>} */}
            </Grid>
          </ReactVisibilitySensor>
        </Grid>
      </Grid>
    </Box>
  )
}
