import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Doctor, DoctorProfileService, ErrorResponse } from 'lib/openApi'
import { OIDCUser } from 'models/oidcUser'
import { AppDispatch, AppThunk } from 'redux/store'
import { getOIDCUserObject } from 'utils/authHelpers'
import { PractitionerAddStatus } from './practitionerAddStatusTypes'

const initialState: PractitionerAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const practitionerSlice = createSlice({
  name: 'addPractitioner',
  initialState,
  reducers: {
    addingPractitionerDetails(
      state,
      action: PayloadAction<PractitionerAddStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
    },

    practitionerDetailsAdded(
      state,
      action: PayloadAction<PractitionerAddStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.practitioner = action.payload.practitioner
    },

    errorInAddingPractitionerDetails(
      state,
      action: PayloadAction<PractitionerAddStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetPractitionerDetails(
      state,
      action: PayloadAction<PractitionerAddStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.practitioner = action.payload.practitioner
    },
  },
})

export const addPractitionerDetails = (
  practitionerDetails: Doctor
): AppThunk => async (dispatch: AppDispatch) => {
  const addingCreatePractitionerState: PractitionerAddStatus = {
    adding: false,
    additionSuccessful: false,
    error: false,
  }
  dispatch(
    practitionerSlice.actions.addingPractitionerDetails(
      addingCreatePractitionerState
    )
  )

  try {
    const oidcUser: OIDCUser | null = getOIDCUserObject()
    if (oidcUser != null) {
      practitionerDetails.tid = oidcUser.wellopathy.tenantId
      const response:
        | Doctor
        | ErrorResponse = await DoctorProfileService.createDoctorProfile({
        tid: oidcUser.wellopathy.tenantId,
        requestBody: practitionerDetails,
      })

      const successCreatePersonState: PractitionerAddStatus = {
        adding: false,
        additionSuccessful: true,
        error: false,
        errorMessage: '',
        practitioner: practitionerDetails,
      }
      dispatch(
        practitionerSlice.actions.practitionerDetailsAdded(
          successCreatePersonState
        )
      )
    } else {
      // TODO Log out user
    }
  } catch (error) {
    const errorCreatePersonState: PractitionerAddStatus = {
      adding: false,
      additionSuccessful: false,
      error: true,
      errorMessage: 'Practitioner already exists',
    }
    dispatch(
      practitionerSlice.actions.errorInAddingPractitionerDetails(
        errorCreatePersonState
      )
    )
  }
}

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(practitionerSlice.actions.resetPractitionerDetails(initialState))
}

export default practitionerSlice.reducer
