import { R4 } from '@ahryman40k/ts-fhir-types'

export const HearingObservationType: R4.ICoding[] = [
  {
    system: 'http://snomed.info/sct',
    code: '162339002',
    display: 'Normal',
  },
  {
    system: 'http://snomed.info/sct',
    code: '15188001',
    display: 'Impaired',
  },
  {
    system: 'http://snomed.info/sct',
    code: '285055002',
    display: 'Hearing Aid',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-hearing-finding-cs',
    code: 'other-hearing-finding',
    display: 'Other',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
