import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DoctorBase,
  DoctorScheduleService,
  ErrorResponse,
  SearchDoctorSlotsTenantResponse,
} from 'lib/openApi'
import { OIDCUser } from 'models/oidcUser'
import { AppDispatch, AppThunk } from 'redux/store'
import { getOIDCUserObject } from 'utils/authHelpers'
import { logger } from 'utils/logger'
import { getDateWiseSlots } from 'utils/slotHelper'
import { SearchProviderSlotSearchStatus } from './searchProviderSlotSearchStatus'

const initialState: SearchProviderSlotSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
  noSelection: true,
}

const providerSlotSearchSlice = createSlice({
  name: 'providerSlotSearch',
  initialState,
  reducers: {
    searchingAvailableSlots(
      state,
      action: PayloadAction<SearchProviderSlotSearchStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.slotList = undefined
      state.noSelection = false
    },
    selectDoctor(state, action: PayloadAction<SearchProviderSlotSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.slotList = undefined
      state.noSelection = true
    },

    searchResults(
      state,
      action: PayloadAction<SearchProviderSlotSearchStatus>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.slotList = action.payload.slotList
      state.noSelection = false
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<SearchProviderSlotSearchStatus>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.slotList = undefined
      state.noSelection = false
    },

    errorWhileSearching(
      state,
      action: PayloadAction<SearchProviderSlotSearchStatus>
    ) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.slotList = undefined
      state.noSelection = false
    },

    resetState(state, action: PayloadAction<SearchProviderSlotSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.slotList = undefined
      state.noSelection = true
    },
  },
})

export const resetSlotSelectionStatus = (): AppThunk => async (
  dispatch: AppDispatch
) => {
  const state: SearchProviderSlotSearchStatus = {
    error: false,
    noResultsAvailable: false,
    resultsAvailable: false,
    searching: false,
    noSelection: false,
    errorMessage: undefined,
    slotList: undefined,
  }
  dispatch(providerSlotSearchSlice.actions.resetState(state))
}

export const searchForProviderSlots = (
  selectedDoctors: DoctorBase[],
  selectedDate?: Date,
  selectedTimeOfDay?: string,
  selectedAppointmentType?: string
): AppThunk => async (dispatch: AppDispatch) => {
  if (selectedDoctors?.length === 0) {
    const state: SearchProviderSlotSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      noSelection: true,
    }
    dispatch(providerSlotSearchSlice.actions.selectDoctor(state))
  } else {
    const state: SearchProviderSlotSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
      noSelection: false,
    }
    dispatch(providerSlotSearchSlice.actions.searchingAvailableSlots(state))
    try {
      const oidcUser: OIDCUser | null = getOIDCUserObject()
      const drIds: string[] = selectedDoctors.map((item) => item.id ?? '')
      if (oidcUser != null) {
        const response:
          | SearchDoctorSlotsTenantResponse
          | ErrorResponse = await DoctorScheduleService.searchDoctorSlot({
          tid: oidcUser.wellopathy.tenantId,
          doctorIdArr: drIds,
        })

        const errorResp: ErrorResponse = response as ErrorResponse
        if (errorResp.code) {
          state.error = true
          state.searching = false
          state.resultsAvailable = false

          if (errorResp.code === '401') {
            state.errorMessage =
              'Yor are not allowed to access this. Please check permissions'
          }
          if (errorResp.code?.includes('500')) {
            state.errorMessage = 'Server is down. Please try later'
          }
          dispatch(providerSlotSearchSlice.actions.noDataFoundForSearch(state))
        } else {
          const successResp: SearchDoctorSlotsTenantResponse = response as SearchDoctorSlotsTenantResponse
          if (successResp && successResp.length > 0) {
            const filteredResp = successResp
            const dateWiseSlots: any[] = getDateWiseSlots(filteredResp)

            state.slotList = dateWiseSlots

            dispatch(providerSlotSearchSlice.actions.searchResults(state))
          } else {
            const errorSearchDoctor: SearchProviderSlotSearchStatus = {
              error: false,
              noResultsAvailable: true,
              resultsAvailable: false,
              searching: false,
            }
            dispatch(
              providerSlotSearchSlice.actions.noDataFoundForSearch(
                errorSearchDoctor
              )
            )
          }
        }
      } else {
        // TODO Log out user
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: SearchProviderSlotSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        providerSlotSearchSlice.actions.errorWhileSearching(errorSearchDoctor)
      )
    }
  }
}

export default providerSlotSearchSlice.reducer
