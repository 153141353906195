import { Box } from '@material-ui/core'
import React from 'react'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitLocationDetails,
  getPractitionerRoleObject,
  getUserCurrentRole,
} from 'services/userDetailsService'
import { getCustomDays, getWorkingHours } from 'utils/fhirResourcesHelper'
import { ScheduleSetting } from './ScheduleSetting'

export const MedicalProviderProfileDetails: React.FC = () => {
  const [schedules, setSchedules] = React.useState<any>([])
  const [existingSchedules, setExistingSchedules] = React.useState<any>([])

  const practitionerRole = getCurrentUserPractitionerRoleDetails()
  const practitioner = getCurrentUserPractitionerDetails()
  const docData = getPractitionerRoleObject(practitionerRole, practitioner)
  const role = getUserCurrentRole()
  const locationDetails = getCurrentUserUnitLocationDetails()
  const businessHours = getWorkingHours(locationDetails.hoursOfOperation ?? [])
  const dayType = getCustomDays(locationDetails.hoursOfOperation ?? [])
  const hiddenDays: number[] = []
  const weekDays: string[] = []
  if (Array.isArray(dayType) && dayType.length) {
    dayType.forEach((item) => {
      if (item.includes('S') || item.includes('Su')) {
        weekDays.push('1')
      }
    })
  }
  if (Array.isArray(dayType) && dayType.length) {
    const wedData = dayType.filter((dayName: string) => dayName === 'W')
    if (wedData.length === 0) hiddenDays.push(3)
    const tueSdayData = dayType.filter((dayName: string) => dayName === 'T')
    if (tueSdayData.length === 0) hiddenDays.push(2)
    const thData = dayType.filter((dayName: string) => dayName === 'Th')
    if (thData.length === 0) hiddenDays.push(4)
    const fData = dayType.filter((dayName: string) => dayName === 'F')
    if (fData.length === 0) hiddenDays.push(5)
    const sData = dayType.filter((dayName: string) => dayName === 'S')
    if (sData.length === 0) hiddenDays.push(6)
    const suData = dayType.filter((dayName: string) => dayName === 'Su')
    if (suData.length === 0) hiddenDays.push(0)
    const mData = dayType.filter((dayName: string) => dayName === 'M')
    if (mData.length === 0) hiddenDays.push(1)
  }

  return (
    <Box
      style={{
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
        maxHeight: '100%',
      }}
    >
      <ScheduleSetting
        loggedInDoc={docData}
        role={role}
        practitionerRole={practitionerRole}
        practitioner={practitioner}
        businessHours={businessHours}
        shoWFullWeek={weekDays.length >= 1}
        hiddenDays={hiddenDays}
        onDone={() => {}}
      />
    </Box>
  )
}
