import { Box } from '@material-ui/core'
import { Timeline } from '@material-ui/lab'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import React from 'react'
import { OrderTimeLineEvent } from './orderTimeLineEvent'

interface Props {
  labOrderDetail: FhirLabOrderDetail
}

export const OrderTimeLine: React.FC<Props> = ({ labOrderDetail }: Props) => (
  <Box display='flex' flexDirection='row' justifyContent='center'>
    <Timeline>
      {labOrderDetail.statuses?.map((e, index) => (
        <Box
          key={`time_event_key_${e.id ?? ''}`}
          justifyContent='flex-start'
          flexGrow
        >
          {e.activity && e.activity.text !== 'Created' && (
            <OrderTimeLineEvent
              key={`time_event_key_${e.id ?? ''}`}
              orderEvent={e}
              isEndEvent={index === labOrderDetail.statuses?.length! - 1}
            />
          )}
        </Box>
      ))}
    </Timeline>
  </Box>
)
