import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  OutlinedInput,
  Paper,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { availablePaymentTypesRemovedOnline } from 'utils/constants'
import { WelloSelect } from 'views/components/LeftMenu/WelloSelect'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { PatientTile } from 'views/components/patients/pateintTile'
import { Controls, WelloFormItemLabel } from 'wello-web-components'
import appCancelReasons from '../../../../assets/constants/cancel_reasons.json'
import { OrderAppointmentDetails } from './orderAppointmentDetails'

interface Props {
  orderDetail: FhirLabOrderDetail
  isReschedulingChargeApplicable: boolean
  preSelectedCancelReason: string
  preSelectedInformCheck: boolean
  onInformCheckChanged: (val: boolean) => void
  onCancelReasonChanged: (val: R4.ICoding) => void
  onPatientNoteTextChanged: (val: string) => void
  patientNoteText: string
  id: string
}

export const OrderCancelConfirmation: React.FC<Props> = ({
  orderDetail,
  preSelectedCancelReason,
  preSelectedInformCheck,
  onInformCheckChanged,
  onCancelReasonChanged,
  isReschedulingChargeApplicable,
  onPatientNoteTextChanged,
  patientNoteText,
  id,
}: Props) => {
  const [note, setNote] = useState('')
  const { t } = useTranslation()
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Box width='100%' id={id}>
        <Grid container>
          <Grid item xs={12}>
            <Box marginX={2} paddingX={1}>
              <Grid container justify='center' direction='column'>
                <Grid item>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <WelloFormItemLabel
                      title={t('labelCommon:appointment_details')}
                    />

                    <OrderAppointmentDetails
                      labOrderDetail={orderDetail}
                      showAddress={true}
                    />
                  </Box>
                </Grid>

                {orderDetail.patient && (
                  <Grid item>
                    <Box paddingY={1}>
                      <WelloFormItemLabel
                        title={t('labelCommon:patient_label')}
                      />
                      <Box>
                        <PatientTile patient={orderDetail.patient} />
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>

          {isReschedulingChargeApplicable && (
            <Grid item style={{ flexGrow: 1 }}>
              <Box
                display='flex'
                flexWrap='wrap'
                alignContent='flex-start'
                justifyContent='flex-start'
                p={1}
                height='100%'
              >
                <Box
                  justifyContent='flex-start'
                  width='100%'
                  height='100 %'
                  marginTop={1}
                  flexDirection='column'
                  style={{ borderRadius: 16 }}
                >
                  <Paper style={{ borderRadius: 8 }} elevation={0}>
                    <Grid container direction='column' justify='center'>
                      <Grid item>
                        <Box display='flex' p={1}>
                          <Box p={1} flexGrow={1}>
                            <Typography variant='h5'>
                              {t('labelCommon:order_summary')}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box
                          display='flex'
                          paddingX={1}
                          bgcolor='background.paper'
                        >
                          <Box p={1} flexGrow={1}>
                            <Typography variant='subtitle1'>
                              {t('labelCommon:cancel_fee')}
                            </Typography>
                          </Box>
                          <Box p={1}>
                            <Typography variant='subtitle1'>
                              ₹10
                              {/* {selectedSlot.doctorProfile?.consultationPrice?.toFixed(
                              2
                            )} */}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Box flex flexDirection='row'>
                        <Divider />
                      </Box>
                      <Grid item>
                        <Box display='flex' paddingX={1}>
                          <Box p={1} flexGrow={1}>
                            <Typography variant='h4' color='primary'>
                              {t('labelCommon:total')}
                            </Typography>
                          </Box>
                          <Box p={1}>
                            <Typography variant='h4' color='primary'>
                              ₹10
                              {/* {selectedSlot.doctorProfile?.consultationPrice?.toFixed(
                              2
                            )} */}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              </Box>
            </Grid>
          )}

          {isReschedulingChargeApplicable && (
            <Grid item xs={12}>
              <Box marginX={2} paddingX={1}>
                <Grid xs={4}>
                  <Box paddingY={1} paddingRight={2} flexShrink>
                    <WelloSelect
                      label={t('labelCommon:payment_type')}
                      availableOptions={availablePaymentTypesRemovedOnline}
                      required={true}
                      type='Payment Type'
                      displayField='title'
                      valueField='id'
                      id='cancel_payment_type'
                      preSelectedOption={availablePaymentTypesRemovedOnline[0]}
                      onSelectionChanged={(value: any) => {}}
                    />
                  </Box>
                </Grid>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box marginX={2} paddingX={1}>
              <Grid
                container
                alignContent='center'
                alignItems='flex-end'
                style={{ padding: 0 }}
              >
                <Grid xs={8}>
                  <Box paddingY={1} paddingRight={2}>
                    <WelloSelectFHIRCoding
                      id='cancel_order_reason'
                      availableCodings={appCancelReasons}
                      title={t('labelCommon:reason')}
                      onChanged={(e) => {
                        onCancelReasonChanged(e as R4.ICoding)
                      }}
                      textFieldProps={{
                        required: true,
                      }}
                      preSelectedCoding={appCancelReasons.find(
                        (e) => e.code === preSelectedCancelReason
                      )}
                      displayDoneLabel={true}
                    />
                  </Box>
                </Grid>
                {isReschedulingChargeApplicable && (
                  <Grid xs={7}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={preSelectedInformCheck}
                          onChange={(r, checked) => {
                            onInformCheckChanged(checked)
                          }}
                          id='cancel_applicable'
                          color='primary'
                        />
                      }
                      /*   style={{ color: useTheme().palette.primary.main }}
                      color={useTheme().palette.primary.light} */
                      label={t('labelCommon:msgforcalc')}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs>
            {isReschedulingChargeApplicable && (
              <Box marginX={2} paddingX={1}>
                <Grid item>
                  <Alert
                    severity='warning'
                    variant='outlined'
                    onClose={() => {}}
                  >
                    {t('labelCommon:cancellation_deduction')}
                  </Alert>
                </Grid>
              </Box>
            )}
            <Box marginX={2} paddingX={1}>
              <Grid item>
                <Box paddingY={1}>
                  <WelloFormItemLabel
                    title={t('labelCommon:note_for_patient')}
                  />
                  <OutlinedInput
                    fullWidth
                    id='cancel_applicable_notes'
                    placeholder={t('labelCommon:add_note_patient')}
                    style={{ backgroundColor: 'white' }}
                    value={patientNoteText}
                    onChange={(e) => {
                      onPatientNoteTextChanged(e.target.value)
                    }}
                  />
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MuiPickersUtilsProvider>
  )
}
