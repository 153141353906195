import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { getExpandedAppointmentFromBundle } from 'utils/common/patientDataTableHelper'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import { UmlClient } from 'services/umlsClient'
import { SurgerySearchStatus } from './surgerySearchStatusType'

const initialState: SurgerySearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const surgerySearchSlice = createSlice({
  name: 'surgery',
  initialState,
  reducers: {
    searchingSurgery(state, action: PayloadAction<SurgerySearchStatus>) {},

    searchResults(state, action: PayloadAction<SurgerySearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
    },

    noDataFoundForSearch(state, action: PayloadAction<SurgerySearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
    },

    errorWhileSearching(state, action: PayloadAction<SurgerySearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
    },
    resetState(state, action: PayloadAction<SurgerySearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.surgeryList = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: any) => {
    const state: SurgerySearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      surgeryList: undefined,
    }
    dispatch(surgerySearchSlice.actions.resetState(state))
  }

export const searchSurgiries =
  (searchString: string): AppThunk =>
  async (dispatch: any) => {
    const errorSearchPatient: SurgerySearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(surgerySearchSlice.actions.errorWhileSearching(errorSearchPatient))
    try {
      const fhirClient: UmlClient = new UmlClient()
      const response: any = await fhirClient.doGetResource(
        `umls/Search?Path=surgeries&Match=${searchString}`
      )
      if (response.length > 0) {
        const searchPatientResult: SurgerySearchStatus = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          surgeryList: response,
          totalCount: response.total,
        }
        dispatch(surgerySearchSlice.actions.searchResults(searchPatientResult))
        return
      }

      const noSearchResults: SurgerySearchStatus = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(surgerySearchSlice.actions.noDataFoundForSearch(noSearchResults))
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: SurgerySearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        surgerySearchSlice.actions.errorWhileSearching(errorWhileSearchPatient)
      )
    }
  }

export const resetState = () => (dispatch: any) => {
  dispatch(surgerySearchSlice.actions.resetState(initialState))
}

export default surgerySearchSlice.reducer
