import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { Room } from '@material-ui/icons'
import React from 'react'
import { getAddress } from 'utils/fhirResourcesHelper'

interface Props {
  partnerLabLocation?: R4.ILocation
}

export const ParterLabTile: React.FC<Props> = ({
  partnerLabLocation,
}: Props) => (
  <Box width='100%' px={1}>
    {partnerLabLocation && (
      <List id='patientList_455' style={{ padding: 0 }}>
        <ListItem style={{ padding: 0 }}>
          <ListItemText
            color='primary'
            primary={
              <>
                <Box display='flex' paddingLeft={0.3}>
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    style={{ fontWeight: 'bolder' }}
                  >
                    {`${partnerLabLocation.name ?? ''} `}
                  </Typography>
                </Box>
              </>
            }
            secondary={
              <>
                <Box display='flex' paddingTop={1} paddingLeft={0.3}>
                  <Typography
                    component='span'
                    variant='subtitle2'
                    color='textPrimary'
                  >
                    Contact Number :{' '}
                    {partnerLabLocation.telecom
                      ? partnerLabLocation.telecom[0].value
                      : ''}
                  </Typography>
                </Box>
                <Box display='flex' paddingTop={1}>
                  <Divider
                    orientation='horizontal'
                    variant='fullWidth'
                    flexItem
                    style={{ width: '100%', height: '1px' }}
                    color='primary'
                  />
                </Box>

                {partnerLabLocation.address && (
                  <Box display='flex' flexDirection='column' width='100%'>
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Room fontSize='small' color='primary' />{' '}
                      <Typography color='primary' variant='subtitle1'>
                        Address
                      </Typography>
                    </Box>
                    <Box display='flex' flexDirection='row' width='100%' p={1}>
                      <Typography>
                        {getAddress([partnerLabLocation.address])}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </>
            }
          />
        </ListItem>
      </List>
    )}
    {/* </CardContent>
    </Card> */}
  </Box>
)
