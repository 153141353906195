import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Package } from 'models/package'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetCatalogueSearchStatus,
  searchingCatalogueDetails,
} from 'redux/lab/packageSearch/packageSearchSlice'
import {
  creatingCatalog,
  resetPackageState,
} from 'redux/lab/Test/addTestCatalougeSlice'
import { RootState } from 'redux/rootReducer'
import { LabCodeValueSet } from 'utils/constants/lab_test'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { displayData, getPacakageBundle } from 'utils/labHelpers/uploadHelper'
import { logger } from 'utils/logger'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { WelloTextField } from 'wello-web-components'
import { TestSearchSelector } from '../orderCreationManagement/packageLabOfferingSelector'
import { TagOfferingSelector } from './tagsSelector'

const useStyles = makeStyles((theme) => ({
  overrides: {
    MuiOutlinedInput: {
      root: {
        backgroundColor: 'white',
      },
      input: {
        backgroundColor: 'white',
      },
      //   inputAdornedStart:{
      //       paddingLeft:8
      //   }
    },
  },
  dialogCustomizedWidth: {
    'max-width': '55%',
  },
}))

interface Props {
  open: boolean
  onBackClick: () => void
  onContinueClick: () => void
}

export const AddPackageHandler: React.FC<Props> = ({
  open,
  onBackClick,
  onContinueClick,
}) => {
  const { t } = useTranslation()
  const packageSearchSlice = useSelector(
    (state: RootState) => state.packageSearchSlice
  )
  const [hasErrors, setHasErrors] = React.useState<boolean>(false)
  const dispatch = useDispatch()
  const [showDisplay, setShowDisplay] = React.useState<boolean>(false)
  const classes = useStyles()
  const creatCatalogSlice = useSelector(
    (state: RootState) => state.createTestSlice
  )

  const display = displayData(LabCodeValueSet)
  const [testError, setTestEroor] = React.useState<string>('')
  const [genderError, setGenderError] = React.useState<string>('')
  const [tagError, setTagError] = React.useState<string>('')

  const [packageData, setPackageData] = React.useState<Package>({
    id: getUniqueTempId(),
    packageName: '',
    packagePrice: 0,
    minAge: 0,
    maxAge: 1,
    gender: {
      Male: false,
      Female: false,
      Others: false,
    },
    cohortInfo: '',
    tags: [],
    listOfTest: [],
    status: 'active',
    testList: '',
  })

  function handleNameChange(name: string) {
    setPackageData({ ...packageData, packageName: name })
  }

  function handlePriceChange(price: number) {
    setPackageData({ ...packageData, packagePrice: price })
  }
  function handleMinAgeChange(minAgeChange: number) {
    setPackageData({ ...packageData, minAge: minAgeChange })
  }

  function handleMaxAgeChange(maxAgeChange: number) {
    setPackageData({ ...packageData, maxAge: maxAgeChange })
  }

  function handleSubmit() {
    let hasErrorsInData: boolean = false
    let genderErrorData: boolean = false
    let hasPriceErrorInData: boolean = false
    let hasMinErrorData: boolean = false
    let hasMaxErrorData: boolean = false
    let testNamesErrorData: boolean = false
    let tagNameErrorData: boolean = false
    if (
      packageData.packageName.length === 0 ||
      packageData.packageName.length > 100 ||
      !packageData.packageName.trim()
    ) {
      hasErrorsInData = true
    } else {
      hasErrorsInData = false
    }

    if (packageData.tags.length === 0) {
      tagNameErrorData = true
      setTagError('Please select at least one Tag')
    } else {
      tagNameErrorData = false
      setTagError('')
    }
    if (packageData.listOfTest.length < 2) {
      testNamesErrorData = true
      setTestEroor('Please select at least two Tests')
    } else {
      testNamesErrorData = false
      setTestEroor('')
    }

    if (
      packageData.gender.Male === false &&
      packageData.gender.Female === false &&
      packageData.gender.Others === false
    ) {
      genderErrorData = true
      setGenderError('Please select at least one Gender')
    } else {
      genderErrorData = false
      setGenderError('')
    }

    if (
      packageData.minAge < 0 ||
      packageData.minAge > 120 ||
      Number.isNaN(packageData.minAge)
    ) {
      hasMinErrorData = true
    } else {
      hasMinErrorData = false
    }

    if (
      packageData.packagePrice <= 0 ||
      packageData.packagePrice > 7000 ||
      Number.isNaN(packageData.packagePrice)
    ) {
      hasPriceErrorInData = true
    } else {
      hasPriceErrorInData = false
    }

    if (
      packageData.maxAge <= 0 ||
      packageData.maxAge > 120 ||
      Number.isNaN(packageData.maxAge) ||
      packageData.maxAge <= packageData.minAge
    ) {
      hasMaxErrorData = true
    } else {
      hasMaxErrorData = false
    }
    if (
      !hasErrorsInData &&
      !hasMaxErrorData &&
      !hasMinErrorData &&
      !hasPriceErrorInData &&
      !tagNameErrorData &&
      !genderErrorData &&
      !testNamesErrorData
    ) {
      const bundleData = getPacakageBundle(packageData)
      if (bundleData) {
        logger.info(bundleData)
        dispatch(creatingCatalog(bundleData, '', 'package'))
        //    onClose()
      }
    }
  }

  function isDisabled(): boolean {
    if (
      packageSearchSlice.resultsAvailable &&
      packageSearchSlice.packageList &&
      packageSearchSlice.packageList.length > 0
    ) {
      return true
    }

    return false
  }

  function isButtonDisabled(): boolean {
    if (
      packageData.maxAge <= 0 ||
      packageData.maxAge > 120 ||
      Number.isNaN(packageData.maxAge) ||
      packageData.maxAge <= packageData.minAge ||
      packageData.minAge < 0 ||
      packageData.minAge > 120 ||
      Number.isNaN(packageData.minAge) ||
      (packageData.gender.Male === false &&
        packageData.gender.Female === false &&
        packageData.gender.Others === false) ||
      packageData.listOfTest.length < 2 ||
      packageData.tags.length === 0 ||
      packageData.packageName.length === 0 ||
      packageData.packageName.length > 100 ||
      !packageData.packageName.trim()
    ) {
      return true
    }
    return false
  }

  function resetDetails() {
    setHasErrors(false)
    setPackageData({
      id: getUniqueTempId(),
      packageName: '',
      packagePrice: 0,
      minAge: 0,
      maxAge: 1,
      gender: {
        Male: false,
        Female: false,
        Others: false,
      },
      cohortInfo: '',
      tags: [],
      listOfTest: [],
      status: 'active',
      testList: '',
    })
  }

  function handleMaleChange(checked: boolean) {
    setPackageData({
      ...packageData,
      gender: {
        Male: checked,
        Female: packageData.gender.Female,
        Others: packageData.gender.Others,
      },
    })
    if (checked) setGenderError('')
    if (
      !checked &&
      packageData.gender.Male === false &&
      packageData.gender.Female === false &&
      packageData.gender.Others === false
    )
      setGenderError('Please select at least one Gender')
  }

  function handleFemaleChange(checked: boolean) {
    setPackageData({
      ...packageData,
      gender: {
        Male: packageData.gender.Male,
        Female: checked,
        Others: packageData.gender.Others,
      },
    })
    if (checked) setGenderError('')
    if (
      !checked &&
      packageData.gender.Male === false &&
      packageData.gender.Female === false &&
      packageData.gender.Others === false
    )
      setGenderError('Please select at least one Gender')
  }

  function handleOthersChange(checked: boolean) {
    setPackageData({
      ...packageData,
      gender: {
        Male: packageData.gender.Male,
        Female: packageData.gender.Female,
        Others: checked,
      },
    })
    if (checked) setGenderError('')
    if (
      !checked &&
      packageData.gender.Male === false &&
      packageData.gender.Female === false &&
      packageData.gender.Others === false
    )
      setGenderError('Please select at least one Gender')
  }

  useEffect(() => {
    if (creatCatalogSlice.additionSuccessful) {
      onBackClick()
      dispatch(resetPackageState())
      resetDetails()
    }
    return () => {}
  }, [creatCatalogSlice.additionSuccessful, onBackClick, dispatch])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onBackClick()

        resetDetails()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'> Add Package </DialogTitle>
      <DialogContent>
        <Box flexDirection='column' display='flex'>
          <Box display='flex' flexDirection='column' px={3}>
            {creatCatalogSlice.error && (
              <Box display='flex'>
                <Alert severity='error'>
                  {creatCatalogSlice.errorMessage ??
                    'Error while adding package. Please try later'}
                </Alert>
              </Box>
            )}
            {creatCatalogSlice.additionSuccessful && (
              <Box display='flex'>
                <Alert severity='success'>
                  {creatCatalogSlice.errorMessage ??
                    'Package Added Successfully'}
                </Alert>
              </Box>
            )}
            <Box
              display='flex'
              flexDirection='row'
              alignItems='flex-start'
              width='100%'
            >
              <Box
                px={1}
                py={1}
                width='50%'
                flexDirection='column'
                alignItems='flex-start'
              >
                <WelloFormItemLabel title='Package Name' />
                <WelloTextFieldWithoutTitle
                  //   title='Package Name'
                  textProps={{
                    id: `actor_email_unit`,
                    value: packageData.packageName,
                    placeholder: 'Package Name',
                    error:
                      hasErrors ||
                      packageData.packageName.length === 0 ||
                      packageData.packageName.length > 100 ||
                      !packageData.packageName.trim(),
                    type: 'text',

                    onChange: (changeName) => {
                      handleNameChange(changeName.target.value)
                    },
                    onBlur: (changeName) => {
                      if (changeName.target.value.length > 0) {
                        dispatch(
                          searchingCatalogueDetails(
                            changeName.target.value,
                            true
                          )
                        )
                      }
                    },
                  }}
                />
                {packageSearchSlice.searching && (
                  <Box
                    flexDirection='row'
                    display='flex'
                    alignSelf='center'
                    justifyContent='center'
                  >
                    <CircularProgress size={25} />
                  </Box>
                )}
                {packageSearchSlice.resultsAvailable &&
                  packageSearchSlice.packageList &&
                  packageData.packageName.length > 0 && (
                    <Box>
                      {packageSearchSlice.packageList.length > 0 && (
                        <Box px={1}>
                          <Typography color='error' variant='subtitle2'>
                            {' '}
                            Package name already exists{' '}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  )}
              </Box>

              <Box px={1} py={1} width='20%' flexDirection='column'>
                <WelloFormItemLabel title='Package Price' />
                <WelloTextFieldWithoutTitle
                  //   title='Package Cost'
                  textProps={{
                    id: `actor_email_unit`,
                    className: classes.overrides,
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Box>
                            {' '}
                            <Typography variant='subtitle2' align='center'>
                              {' '}
                              ₹{' '}
                            </Typography>
                          </Box>
                        </InputAdornment>
                      ),
                    },
                    inputProps: {
                      max: 7000,
                      step: 0.1,
                    },
                    value: packageData.packagePrice,
                    placeholder: 'Price',
                    error:
                      packageData.packagePrice <= 0 ||
                      packageData.packagePrice > 7000 ||
                      Number.isNaN(packageData.packagePrice),
                    type: 'number',

                    //   value: tests.testPrice,
                    onChange: (changePrice) => {
                      handlePriceChange(parseInt(changePrice.target.value, 10))
                    },
                  }}
                />
              </Box>

              <Box px={1} py={1} width='15%' flexDirection='column'>
                <WelloFormItemLabel title='Minimum Age' />
                <WelloTextFieldWithoutTitle
                  textProps={{
                    id: `actor_email_unit`,
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Box
                            display='flex'
                            flexDirection='row'
                            alignItems='flex-start'
                          >
                            {' '}
                            <Typography variant='subtitle2' align='center'>
                              {' '}
                              Yrs
                            </Typography>
                          </Box>
                        </InputAdornment>
                      ),
                    },
                    inputProps: {
                      max: 120,
                      min: 0,
                    },
                    value: packageData.minAge,
                    placeholder: 'Minimum Age',
                    error:
                      packageData.minAge < 0 ||
                      packageData.minAge > 120 ||
                      Number.isNaN(packageData.minAge),
                    type: 'number',

                    //   value: tests.testPrice,
                    onChange: (changePrice) => {
                      handleMinAgeChange(parseInt(changePrice.target.value, 10))
                    },
                  }}
                />
              </Box>

              <Box px={1} py={1} width='15%' flex-flexDirection='column'>
                <WelloFormItemLabel title='Maximum Age' />
                <WelloTextFieldWithoutTitle
                  //   title='Maximum Age'
                  textProps={{
                    id: `actor_email_unit`,
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Box
                            display='flex'
                            flexDirection='row'
                            alignItems='flex-start'
                          >
                            {' '}
                            <Typography variant='subtitle2' align='center'>
                              {' '}
                              Yrs
                            </Typography>
                          </Box>
                        </InputAdornment>
                      ),
                    },
                    inputProps: {
                      max: 120,
                      min: 1,
                    },
                    value: packageData.maxAge,
                    placeholder: 'Maximum Age',
                    error:
                      packageData.maxAge <= 0 ||
                      packageData.maxAge > 120 ||
                      packageData.maxAge < packageData.minAge ||
                      Number.isNaN(packageData.maxAge),
                    type: 'number',

                    //   value: tests.testPrice,
                    onChange: (changePrice) => {
                      handleMaxAgeChange(parseInt(changePrice.target.value, 10))
                    },
                  }}
                />
              </Box>
            </Box>

            {/* <Box
              display='flex'
              flexDirection='row'
              alignItems='flex-start'
              width='100%'
            >
              <Box p={2} width='50%'>
                <WelloTextField
                  title='MINIMUM AGE'
                  textProps={{
                    id: `actor_email_unit`,
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Box
                            display='flex'
                            flexDirection='row'
                            alignItems='flex-start'
                          >
                            {' '}
                            <Typography variant='subtitle2' align='center'>
                              {' '}
                              In Years
                            </Typography>
                            <Box paddingLeft={3}>
                              <img
                                src={`${process.env.PUBLIC_URL}/dropDown.png`}
                                alt='n'
                              />
                            </Box>
                          </Box>
                        </InputAdornment>
                      ),
                    },
                    inputProps: {
                      max: 120,
                      min: 0,
                    },
                    value: packageData.minAge,
                    placeholder: 'Minimum Age',
                    error:
                      packageData.minAge < 0 ||
                      packageData.minAge > 120 ||
                      Number.isNaN(packageData.minAge),
                    type: 'number',
                    helperText:
                      packageData.minAge < 0 ||
                      packageData.minAge > 120 ||
                      Number.isNaN(packageData.minAge)
                        ? 'Minimum Age Should be between 0 to 120'
                        : '',
                    //   value: tests.testPrice,
                    onChange: (changePrice) => {
                      handleMinAgeChange(parseInt(changePrice.target.value, 10))
                    },
                  }}
                />
              </Box>

              <Box p={2} width='50%'>
                <WelloTextField
                  title='MAXIMUM AGE'
                  textProps={{
                    id: `actor_email_unit`,
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Box
                            display='flex'
                            flexDirection='row'
                            alignItems='flex-start'
                          >
                            {' '}
                            <Typography variant='subtitle2' align='center'>
                              {' '}
                              In Years
                            </Typography>
                            <Box paddingLeft={3}>
                              <img
                                src={`${process.env.PUBLIC_URL}/dropDown.png`}
                                alt='n'
                              />
                            </Box>
                          </Box>
                        </InputAdornment>
                      ),
                    },
                    inputProps: {
                      max: 120,
                      min: 1,
                    },
                    value: packageData.maxAge,
                    placeholder: 'Maximum Age',
                    error:
                      packageData.maxAge <= 0 ||
                      packageData.maxAge > 120 ||
                      packageData.maxAge < packageData.minAge ||
                      Number.isNaN(packageData.maxAge),
                    type: 'number',
                    helperText:
                      packageData.maxAge <= 0 ||
                      packageData.maxAge > 120 ||
                      Number.isNaN(packageData.maxAge)
                        ? 'Maximum Age should between between 1 to 120'
                        : packageData.maxAge < packageData.minAge
                        ? 'Maximum Age should be greater than minimum age'
                        : '',
                    //   value: tests.testPrice,
                    onChange: (changePrice) => {
                      handleMaxAgeChange(parseInt(changePrice.target.value, 10))
                    },
                  }}
                />
              </Box>
            </Box> */}

            <Box
              display='flex'
              flexDirection='row'
              alignItems='flex-start'
              width='100%'
            >
              <Box px={1} width='50%'>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                  >
                    <WelloFormItemLabel title='Tag' />
                  </Box>
                  <Box display='flex' flexDirection='row' width='100%'>
                    <TagOfferingSelector
                      onSelectionChanges={(e) => {
                        setPackageData({
                          ...packageData,
                          tags: e,
                        })
                        setTagError('')
                      }}
                      disabled={false}
                    />
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    paddingX={2}
                  >
                    <Typography
                      color='secondary'
                      style={{ fontSize: 12, fontWeight: 400 }}
                    >
                      {tagError}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                width='50%'
                paddingTop={1.4}
              >
                <Box display='flex' flexDirection='row' paddingX={1.4}>
                  <Typography variant='subtitle2'>Gender</Typography>
                </Box>

                <Box
                  display='flex'
                  flexDirection='row'
                  alignItems='flex-start'
                  width='100%'
                  px={0.2}
                >
                  <Box py={2} px={1.5}>
                    <FormControlLabel
                      label='Male'
                      style={{
                        padding: '0px',
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                      control={
                        <Checkbox
                          checked={packageData.gender.Male}
                          onChange={(e) => handleMaleChange(e.target.checked)}
                          name='male'
                          color='primary'
                          size='small'
                          style={{ padding: '0px', paddingLeft: '8px' }}
                        />
                      }
                    />
                  </Box>

                  <Box py={2} px={1.5}>
                    <FormControlLabel
                      label='Female'
                      style={{ padding: '0px', fontSize: 12, fontWeight: 600 }}
                      control={
                        <Checkbox
                          checked={packageData.gender.Female}
                          onChange={(e) => handleFemaleChange(e.target.checked)}
                          name='female'
                          color='primary'
                          size='small'
                          style={{ padding: '0px', paddingLeft: '8px' }}
                        />
                      }
                    />
                  </Box>

                  <Box py={2} px={1.5}>
                    <FormControlLabel
                      label='Others'
                      style={{ padding: '0px', fontSize: 12, fontWeight: 600 }}
                      control={
                        <Checkbox
                          checked={packageData.gender.Others}
                          onChange={(e) => handleOthersChange(e.target.checked)}
                          name='others'
                          color='primary'
                          size='small'
                          style={{ padding: '0px', paddingLeft: '8px' }}
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  paddingX={3}
                >
                  <Typography
                    color='secondary'
                    style={{ fontSize: 12, fontWeight: 400 }}
                  >
                    {genderError}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              display='flex'
              flexDirection='row'
              alignItems='flex-start'
              width='100%'
            >
              <Box px={1} paddingBottom={2} width='100%'>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                  >
                    <WelloFormItemLabel title='Select Tests' />
                  </Box>
                  <Box display='flex' flexDirection='row' width='100%'>
                    <TestSearchSelector
                      onSelectionChanges={(e) => {
                        setPackageData({
                          ...packageData,
                          listOfTest: e,
                        })
                        // setTestEroor('')
                      }}
                      disabled={false}
                    />
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    paddingX={2}
                  >
                    <Typography
                      color='secondary'
                      style={{ fontSize: 12, fontWeight: 400 }}
                    >
                      {testError}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Divider style={{ border: 1 }} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          paddingRight={2.8}
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
        >
          {creatCatalogSlice.adding && <CircularProgress />}
          <Button
            //   color='primary'
            onClick={() => {
              resetDetails()
              setHasErrors(false)
              dispatch(searchingCatalogueDetails())
              onBackClick()
            }}
            variant='outlined'
            disabled={creatCatalogSlice.adding}
            disableElevation
            size='small'
          >
            Cancel
          </Button>
          <Box paddingLeft={0.5}>
            <Button
              variant='contained'
              size='small'
              color='primary'
              disabled={
                isButtonDisabled() ||
                isDisabled() ||
                packageSearchSlice.searching
              }
              onClick={() => {
                handleSubmit()
              }}
            >
              Add Package
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
