/* eslint-disable no-dupe-keys */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirClinicalImpressionDetail } from 'models/fhirClinicalImpression'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getClinicaImpressionDetailsFromBundleResponse } from 'utils/fhirResoureHelpers/clinicalImpressionHelper'
import { logger } from 'utils/logger'
import { ClinicalImpressionFetchStatus } from './clinicalImpressionsStatus'

const initialState: ClinicalImpressionFetchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const clinicalImpressionSearchSlice = createSlice({
  name: 'practitionerSlotSearch',
  initialState,
  reducers: {
    searchingAvailableSlots(
      state,
      action: PayloadAction<ClinicalImpressionFetchStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.clinicalImpressionList = undefined
    },
    selectDoctor(state, action: PayloadAction<ClinicalImpressionFetchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.clinicalImpressionList = undefined
    },

    searchResults(state, action: PayloadAction<ClinicalImpressionFetchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.clinicalImpressionList = action.payload.clinicalImpressionList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<ClinicalImpressionFetchStatus>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.clinicalImpressionList = undefined
    },

    errorWhileSearching(
      state,
      action: PayloadAction<ClinicalImpressionFetchStatus>
    ) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.clinicalImpressionList = undefined
    },

    resetState(state, action: PayloadAction<ClinicalImpressionFetchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.clinicalImpressionList = undefined
    },
  },
})

export const resetSlotSelectionStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: ClinicalImpressionFetchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,

      errorMessage: undefined,
      clinicalImpressionList: undefined,
    }
    dispatch(clinicalImpressionSearchSlice.actions.resetState(state))
  }

export const requestForClinicalImpressions =
  (patientId: string, appointmentId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: ClinicalImpressionFetchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      clinicalImpressionSearchSlice.actions.searchingAvailableSlots(state)
    )
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        '_include:iterate': 'ClinicalImpression:assessor',
        _include: 'ClinicalImpression:subject',
        subject: `Patient/${patientId}`,
        _sort: '-date',
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/ClinicalImpression',
        appointmentId,
        searchParameters
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const clinicalImpressionsResponse: R4.IBundle =
          relatedFhirDecodeRes.right
        const categorizedCIs: FhirClinicalImpressionDetail[] =
          getClinicaImpressionDetailsFromBundleResponse(
            clinicalImpressionsResponse
          )

        if (categorizedCIs.length > 0) {
          state.error = false
          state.searching = false
          state.resultsAvailable = true
          state.clinicalImpressionList = categorizedCIs
          dispatch(clinicalImpressionSearchSlice.actions.searchResults(state))
        } else {
          const errorSearchDoctor: ClinicalImpressionFetchStatus = {
            error: false,
            noResultsAvailable: true,
            resultsAvailable: false,
            searching: false,
          }
          dispatch(
            clinicalImpressionSearchSlice.actions.noDataFoundForSearch(
              errorSearchDoctor
            )
          )
        }
      } else {
        state.error = true
        state.searching = false
        state.resultsAvailable = false

        dispatch(
          clinicalImpressionSearchSlice.actions.errorWhileSearching(state)
        )
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: ClinicalImpressionFetchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        clinicalImpressionSearchSlice.actions.errorWhileSearching(
          errorSearchDoctor
        )
      )
    }
  }

export default clinicalImpressionSearchSlice.reducer
