import { List, ListSubheader } from '@material-ui/core'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import moment from 'moment'
import React from 'react'
import { getCompleteDateStringWithOutDay } from 'utils/dateUtil'
import { getDateWiseOrders } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { LabOrderTile } from 'views/components/lab/orderList/order_tile'

interface Props {
  preselectedOrderId?: string
  labOrders?: FhirLabOrderDetail[]
  onLabOrderSelected?: (selectedOrder: FhirLabOrderDetail) => void
  onDownloadClicked: (selectedOrder: FhirLabOrderDetail) => void
  onUploadReport: (selectedOrder: FhirLabOrderDetail) => void
  onReschedule: (selectedOrder: FhirLabOrderDetail) => void
  onCancel: (selectedOrder: FhirLabOrderDetail) => void
  onEnterLabResults: (selectedOrder: FhirLabOrderDetail) => void
  dense?: boolean
}

export const LabOrderListView: React.FC<Props> = ({
  preselectedOrderId,
  labOrders,
  onLabOrderSelected,
  onDownloadClicked,
  onUploadReport,
  onReschedule,
  onCancel,
  onEnterLabResults,

  dense = false,
}: Props) => (
  <List
    subheader={<li style={{ padding: 0, width: '100%' }} />}
    style={{ padding: 0, width: '100%', overflow: 'auto', height: '100%' }}
  >
    {getDateWiseOrders(labOrders ?? []).map((e) => (
      <li key={`section-${e.date ?? ''}`} style={{ padding: 0, width: '100%' }}>
        <ul style={{ padding: 0 }}>
          <ListSubheader
            style={{
              color: '#333333',
              fontWeight: 600,
              paddingLeft: 12,
              width: '100%',
              backgroundColor: '#ececec',
            }}
          >
            {getCompleteDateStringWithOutDay(e.date ?? '')}
          </ListSubheader>
          {e.orders?.map((item, index) => (
            <LabOrderTile
              index={`${index}${moment(e.date).format('DD-MM-YYYY')}`}
              labOrderDetail={item}
              isOdd={index % 2 === 0}
              dense={dense}
              isSelected={
                preselectedOrderId !== undefined &&
                item.serviceRequest.id === preselectedOrderId
              }
              onDownloadClicked={() => {
                if (onDownloadClicked) {
                  onDownloadClicked(item)
                }
              }}
              onTap={() => {
                if (onLabOrderSelected) {
                  onLabOrderSelected(item)
                }
              }}
              onUpload={() => {
                if (onUploadReport) {
                  onUploadReport(item)
                }
              }}
              onReschedule={() => {
                if (onReschedule) {
                  onReschedule(item)
                }
              }}
              onCancel={() => {
                if (onCancel) {
                  onCancel(item)
                }
              }}
              onEnterLabResults={() => {
                if (onEnterLabResults) {
                  onEnterLabResults(item)
                }
              }}
              key={`lab_item_key${item.serviceRequest.id}` ?? ''}
            />
          ))}
        </ul>
      </li>
    ))}
  </List>
)
