import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { titleCase } from 'utils/fhirResourcesHelper'

interface WelloFormItemLabelProps {
  title: string
  displayDoneLabel?: boolean
  id?: string
  case?: string
}

export const WelloFormItemLabel: React.FunctionComponent<WelloFormItemLabelProps> =
  (props: WelloFormItemLabelProps) => (
    <Box py={1} px={0.25} display='flex' flexDirection='row'>
      {props.case === undefined && (
        <Typography variant='subtitle2' id={props.id ?? 'none'}>
          {titleCase(props.title)}
        </Typography>
      )}
      {props.case !== undefined && (
        <Typography variant='subtitle2' id={props.id ?? 'none'}>
          {props.title}
        </Typography>
      )}

      {props.displayDoneLabel && (
        <Box px={0.25}>
          <FontAwesomeIcon icon={faCheckCircle} color='#0091ff' />
        </Box>
      )}
    </Box>
  )
