import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import React from 'react'

interface Props {
  primaryContactDetails?: R4.IPractitionerRole
}

export const PrimaryContactTile: React.FC<Props> = ({
  primaryContactDetails,
}: Props) => (
  <Box width='80%' px={1}>
    {primaryContactDetails && (
      <List id='patientList_455' style={{ padding: 0 }}>
        <ListItem style={{ padding: 0 }}>
          <ListItemText
            color='primary'
            primary={
              <>
                <Typography
                  variant='subtitle1'
                  color='primary'
                  style={{ fontWeight: 'bolder' }}
                >
                  {`${primaryContactDetails.practitioner?.display ?? ''} `}
                </Typography>
              </>
            }
            secondary={
              <>
                <Box display='flex' paddingTop={1}>
                  <Typography
                    component='span'
                    variant='subtitle2'
                    color='textPrimary'
                  >
                    Contact Number :{' '}
                    {primaryContactDetails.telecom
                      ? primaryContactDetails.telecom[0].value
                      : ''}
                  </Typography>
                </Box>

                <Box display='flex' paddingTop={1}>
                  <Typography
                    component='span'
                    variant='subtitle2'
                    color='textPrimary'
                  >
                    Email Address :{' '}
                    {primaryContactDetails.telecom
                      ? primaryContactDetails.telecom[1].value
                      : ''}
                  </Typography>
                </Box>
              </>
            }
          />

          {/* <ListItemSecondaryAction>
                {hideAge && (
                  <ListItemText
                    primary={<GenderAndAgeTile patient={patient} />}
                  />
                )}
              </ListItemSecondaryAction> */}
        </ListItem>
      </List>
    )}
    {/* </CardContent>
    </Card> */}
  </Box>
)
