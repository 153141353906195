import { Box, Chip, TextField, Typography } from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import React, { useState } from 'react'
import { WelloFormItemLabel } from './WelloFormItemLabel'

export interface WelloMultiSelectAutoCompleteProps {
  id: string
  availableOptions: any[]
  valuesField: string
  displayField: string
  title: string
  displayDoneLabel?: boolean
  preSelectedOption?: any
  singularWorld?: string
  pluralWord?: string
  onOptionSelected: (selectedOption: any) => void
  error?: boolean
  helperText?: string
  disabled: boolean
}

export const WelloMultiSelectAutoComplete: React.FC<WelloMultiSelectAutoCompleteProps> =
  ({
    preSelectedOption,
    availableOptions,
    title,
    valuesField,
    displayField,
    displayDoneLabel,
    id,
    onOptionSelected,
    singularWorld,
    pluralWord,
    error,
    helperText,
    disabled,
  }: WelloMultiSelectAutoCompleteProps) => {
    const [selectedItem, setSelectedItem] = useState<any[]>(
      preSelectedOption ?? []
    )

    function getSelectedLabel(count: number): string {
      if (count === 0) {
        return 'Select '
      }
      if (count > 0) {
        if (count === 1) {
          return `${count} ${singularWorld ?? 'item'} selected`
        }

        return `${count} ${pluralWord ?? 'items'} selected`
      }
      return 'Select '
    }

    return (
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <WelloFormItemLabel title={title} displayDoneLabel={displayDoneLabel} />
        <Box>
          <Autocomplete
            limitTags={0}
            multiple={true}
            disableClearable
            renderTags={() => <div />}
            id={id}
            options={availableOptions}
            disabled={disabled}
            defaultValue={preSelectedOption}
            ChipProps={{
              deleteIcon: (
                <ClearOutlined
                  style={{
                    height: '15px',
                    color: 'white',
                  }}
                />
              ),
              style: {
                backgroundColor: kPrimaryLight,
                borderRadius: '4px',
              },
            }}
            freeSolo
            value={selectedItem}
            filterSelectedOptions
            getOptionLabel={(option) => ''}
            onChange={(event, value, reason) => {
              onOptionSelected(value)
              setSelectedItem(value)
            }}
            renderOption={(option, { inputValue }) => {
              const matches = match(option[displayField], inputValue)
              const parts = parse(option[displayField], matches)
              return (
                <Typography
                  id={`${id}_ option_${option[displayField]}`}
                  variant='subtitle2'
                >
                  {parts.map((part, index) => (
                    <span
                      key={part.text}
                      style={{
                        backgroundColor: part.highlight
                          ? kPrimaryLight
                          : undefined,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Typography>
              )
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id={`${id}_ edit`}
                placeholder={getSelectedLabel(selectedItem.length)}
                fullWidth
                inputProps={{ ...params.inputProps, maxLength: '256' }}
                variant='outlined'
                size='small'
                error={error}
                helperText={helperText}
              />
            )}
          />
        </Box>

        {selectedItem && selectedItem.length >= 0 && (
          <Box display='flex' flexDirection='row' flexWrap='wrap' p={0.5}>
            {selectedItem.map((item, index: number) => (
              <Box
                key={item[valuesField]}
                p={0.5}
                minWidth='20%'
                maxWidth='30%'
                px={1}
                display='flex'
                alignContent='flex-end'
                justifyContent='space-between'
                flexDirection='row'
              >
                <Chip
                  label={item.display}
                  id={`del${index}`}
                  size='small'
                  onDelete={() => {
                    const newValue = selectedItem.filter(
                      (entry) => entry[valuesField] !== item[valuesField]
                    )
                    onOptionSelected(newValue)
                    setSelectedItem(newValue)
                  }}
                />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    )
  }
