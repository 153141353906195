import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { addInstructions } from 'redux/consultation/recommendation_additions/instruction_addition/instrutionAdditionSlice'
import { RootState } from 'redux/rootReducer'
import { getCarePlanIdFromTaskOutput } from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'

const useStyles = makeStyles((theme) => ({
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))

interface AddInstructionsButtonProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  carePanBundle: R4.ICommunicationRequest[]
  carePlanIdentifier?: string
}

export const AddInstructionsButton: React.FC<AddInstructionsButtonProps> = ({
  fhirAppointmentDetails,
  carePanBundle,
  carePlanIdentifier,
}: AddInstructionsButtonProps) => {
  const instructionAdditionSlice = useSelector(
    (state: RootState) => state.instructionAdditionSlice
  )

  const subscriptionStatus = useSelector(
    (state: RootState) => state.patientSubscriptionForUnitSlice
  )
  const classes = useStyles()
  const dispatch = useDispatch()

  return (
    <Box>
      <Box flexDirection='row'>
        {instructionAdditionSlice.addingInstruction && (
          <Box height={25} width={25}>
            <CircularProgress size={20} />
          </Box>
        )}

        {instructionAdditionSlice.errorWhileAdding && (
          <Box flexDirection='row'>
            <Button
              variant='text'
              color='primary'
              id='care_plan_ins_retry'
              className={classes.buttonText}
              onClick={(e) => {
                if (subscriptionStatus.tasks) {
                  const carePlanId = getCarePlanIdFromTaskOutput(
                    subscriptionStatus.tasks[0].task
                  )

                  if (carePlanId)
                    dispatch(
                      addInstructions(
                        fhirAppointmentDetails.patient,
                        carePanBundle,
                        fhirAppointmentDetails,
                        carePlanIdentifier,
                        carePlanId
                      )
                    )
                }
                e.stopPropagation()
              }}
            >
              Retry
            </Button>

            {/*  <Button
              variant='text'
              color='secondary'
              className={classes.rejectButtonText}
            >
              Reject
            </Button> */}
          </Box>
        )}
        {instructionAdditionSlice.additionSuccessful && (
          <Box flexDirection='row' px={2}>
            <Typography variant='subtitle1' color='initial'>
              Added
            </Typography>
          </Box>
        )}
        {!instructionAdditionSlice.additionSuccessful &&
          !instructionAdditionSlice.errorWhileAdding &&
          !instructionAdditionSlice.addingInstruction && (
            <Box flexDirection='row'>
              <Button
                variant='text'
                color='primary'
                id='care_plan_ins_add'
                className={classes.buttonText}
                onClick={(e) => {
                  if (
                    subscriptionStatus.subscriptionStatus === 'interested' ||
                    subscriptionStatus.subscriptionStatus === 'active'
                  ) {
                    if (subscriptionStatus.tasks) {
                      const carePlanId = getCarePlanIdFromTaskOutput(
                        subscriptionStatus.tasks[0].task
                      )

                      if (carePlanId)
                        dispatch(
                          addInstructions(
                            fhirAppointmentDetails.patient,
                            carePanBundle,
                            fhirAppointmentDetails,
                            carePlanIdentifier,
                            carePlanId
                          )
                        )
                    }
                  } else {
                    dispatch(
                      showErrorAlert(
                        'Please subscribe to the plan to add instructions'
                      )
                    )
                  }
                  e.stopPropagation()
                }}
              >
                Add
              </Button>

              {/*  <Button
                variant='text'
                color='secondary'
                className={classes.rejectButtonText}
              >
                Reject
              </Button> */}
            </Box>
          )}
      </Box>
    </Box>
  )
}
