import { R4 } from '@ahryman40k/ts-fhir-types'

export const Languages: R4.ICoding[] = [
  {
    code: 'en',
    display: 'English',
  },
  {
    code: 'hi',
    display: 'Hindi',
  },
  {
    code: 'kn',
    display: 'Kannada',
  },
  {
    code: 'ta',
    display: 'Tamil',
  },
  {
    code: 'te',
    display: 'Telugu',
  },
  {
    code: 'bh',
    display: 'Bihari',
  },
  {
    code: 'or',
    display: 'Oriya',
  },
  {
    code: 'bn',
    display: 'Bengali',
  },
  {
    code: 'ch',
    display: 'Chhattisgarhi',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
