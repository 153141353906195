import MaterialTable, { MTableToolbar } from '@material-table/core'
import {
  Box,
  makeStyles,
  TablePagination,
  Typography,
  Button,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import {
  kBackgroundDefault,
  kDialogueBackground,
} from 'configs/styles/muiThemes'
import { ConvenienceFee } from 'models/convinienceFeeDetails'
import { LabReferralDetails } from 'models/labReferralDetails'
// import { kDialogueBackground } from 'configs/styles/muiThemes'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isCurrentUnitIsLab } from 'services/userDetailsService'
import useWindowDimensions from 'utils/hooks/useDimensionHook'

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      //   padding: 4,
    },
  },
})

interface ReferralDataTableProperties {
  usersData: ConvenienceFee[]
  onReferralSelected?: (task: ConvenienceFee) => void
  displayOnlyName?: boolean
  selectedReferral?: ConvenienceFee
}

export const ReferralFeeDataTable: React.FC<ReferralDataTableProperties> = ({
  usersData,
  onReferralSelected,
  displayOnlyName,
  selectedReferral,
}: ReferralDataTableProperties) => {
  const [data, setData] = useState([...usersData])

  const classes = useStyles()

  const { height, width } = useWindowDimensions()
  const { t } = useTranslation()

  const tableRef = React.useRef()

  const paginationHeight = 56
  //   console.log(convertedData)

  const handleDarkModeChange = (event: any, rowData: any) => {
    if (onReferralSelected) onReferralSelected(rowData)
  }

  const columns = [
    {
      title: (
        <Box display='flex' flexDirection='row' justifyContent='flex-start'>
          <Typography
            variant='h4'
            style={{
              fontSize: 14,
              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Unit Type
          </Typography>
        </Box>
      ),
      field: 'untTypeArray',

      width: '20%',
      render: (rowData: ConvenienceFee) => (
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
          paddingLeft={0.4}
        >
          <Typography variant='subtitle2' color='initial'>
            {rowData.untTypeArray ? rowData.untTypeArray : 'All'}
            {/* {_.capitalize(rowData.status)} */}
          </Typography>
          {/* )} */}
        </Box>
      ),
    },
    {
      title: (
        <Box display='flex' flexDirection='row' justifyContent='flex-start'>
          <Typography
            variant='h4'
            style={{
              fontSize: 14,
              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Service Type
          </Typography>
        </Box>
      ),
      field: 'serviceString',

      width: '20%',
      render: (rowData: ConvenienceFee) => (
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
          paddingLeft={0.4}
        >
          <Typography variant='subtitle2' color='initial'>
            {rowData.serviceString ? rowData.serviceString : 'All'}
          </Typography>
          {/* )} */}
        </Box>
      ),
    },
    {
      title: (
        <Box display='flex' flexDirection='row' justifyContent='flex-start'>
          <Typography
            variant='h4'
            style={{
              fontSize: 14,
              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Percentage
          </Typography>
        </Box>
      ),
      field: 'name',
      width: '20%',

      render: (rowData: ConvenienceFee) => (
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
          paddingLeft={0.4}
        >
          <Typography variant='subtitle2' color='initial' noWrap>
            {rowData.amountInPercentage
              ? `${rowData.amountInPercentage} %`
              : ''}
          </Typography>
        </Box>
      ),
    },

    {
      title: (
        <Box display='flex' flexDirection='row' justifyContent='flex-start'>
          <Typography
            variant='h4'
            style={{
              fontSize: 14,
              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Amount
          </Typography>
        </Box>
      ),
      field: 'amount',
      width: '20%',

      render: (rowData: ConvenienceFee) => (
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
          paddingLeft={0.4}
        >
          <Typography variant='subtitle2' color='initial' noWrap>
            {rowData.amount ? `${rowData.amount} ` : ''}
          </Typography>
        </Box>
      ),
    },
    {
      title: (
        <Box display='flex' flexDirection='row' justifyContent='flex-start' />
      ),

      width: '5%',
      render: (rowData: ConvenienceFee) => (
        <Box display='flex' flexDirection='row' justifyContent='flex-start' />
      ),
    },

    // {
    //   title: <Box />,
    //   field: 'status',
    //   width: '5%',
    //   render: (rowData: ConvenienceFee) => (
    //     <Box display='flex' justifyContent='flex-end'>
    //       <LaunchOutlined fontSize='small' />
    //     </Box>
    //   ),
    //   filtering: false,
    // },
  ]

  return (
    <Box width='100%'>
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: 'white',
                minHeight: '0px',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),

          //   Actions: (props) => (
          //     <Button
          //       onClick={(event) => {
          //         if (onReferralSelected) onReferralSelected(props.data)
          //       }}
          //       color='primary'
          //       variant='contained'
          //       style={{ textTransform: 'none' }}
          //       size='small'
          //       disabled={props.data.status === 'completed'}
          //     >
          //       Accept Invite
          //     </Button>
          //   ),

          //   Pagination: (props) => (
          //     <div
          //       className={classes.toolbarWrapper}
          //       style={{
          //         backgroundColor: 'white',
          //         display: 'flex',
          //         justifyContent: 'left',
          //         // padding: '0px',
          //       }}
          //     >
          //       <TablePagination
          //         {...props}
          //         style={{
          //           height: '100px',
          //         }}
          //         SelectProps={{
          //           style: {
          //             fontSize: 14,
          //             minHeight: '5px',
          //           },
          //         }}
          //       />
          //     </div>
          //   ),
        }}
        columns={columns}
        data={data}
        actions={[
          (rowData) => ({
            icon: () => (
              <Tooltip title='Edit Fee' id='manage_medication_tooltip'>
                <IconButton
                  aria-label='btn_ord_cancel'
                  color='primary'
                  id='manage_medication_ipd'
                  style={{ padding: 0 }}
                  onClick={() => {
                    // onEditClicked(row)
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/editVector.png`}
                    alt='view'
                  />
                </IconButton>
              </Tooltip>
            ),

            onClick: handleDarkModeChange,
          }),
        ]}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        onRowClick={(event, rowData) => {
          if (rowData && onReferralSelected) onReferralSelected(rowData)
        }}
        tableRef={tableRef}
        options={{
          searchFieldStyle: { padding: 0 },
          toolbar: false,
          search: false,
          showTitle: false,
          paging: false,
          searchFieldVariant: 'outlined',
          filtering: false,
          //   pageSize: 10,
          sorting: true,
          //   pageSizeOptions: [10, 20, 30],
          //   columnsButton: true,
          maxBodyHeight: `${height - 140}px`,
          minBodyHeight: `${height - 140}px`,

          //   tableLayout: 'auto',
          thirdSortClick: true,

          headerStyle: {
            backgroundColor: 'white',
            color: '#FFF',
            width: 100,
            position: 'sticky',
            top: 0,
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          rowStyle: (_data: any, index: number, _level: number) => ({
            backgroundColor: selectedReferral
              ? selectedReferral.id === (_data as LabReferralDetails).id
                ? kDialogueBackground
                : 'white'
              : 'white',
            borderBottom: kBackgroundDefault,
          }),
        }}
      />
    </Box>
  )
}
