import { R4 } from '@ahryman40k/ts-fhir-types'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import { SelectedFile } from 'views/components/LeftMenu/WelloFilePicker'

export function getDocumentBundle(
  type: string,
  files: any,
  labOrderDetails?: FhirLabOrderDetail
): R4.IBundle {
  const bundleArray: any = []
  const untiData = getCurrentUserUnitReference()!
  for (let i = 0; i < files.length; i++) {
    const documentObj: R4.IDocumentReference = {
      resourceType: 'DocumentReference',
      authenticator: {
        reference: untiData.reference,
      },
      status: R4.DocumentReferenceStatusKind._current,
      type: {
        coding: [
          {
            system: 'http://loinc.org',
            code: '11502-2',
            display: 'Lab Report',
          },
        ],
      },
      identifier: [
        {
          system: 'fileName',
          value: `${files[i].name}`,
        },
      ],
      content: [
        {
          attachment: {
            contentType: `${type}`,
          },
        },
      ],
    }
    if (labOrderDetails) {
      const refArray: R4.IReference[] = []
      const orderRef: R4.IReference = {
        reference: `ServiceRequest/${labOrderDetails.serviceRequest.id ?? ''}`,
        display: 'ServiceRequest',
      }
      refArray.push(orderRef)
      documentObj.context = {
        related: refArray,
      }
      const patientRef: R4.IReference = {
        reference: `Patient/${labOrderDetails.patient.id ?? ''}`,
        display: 'Patient',
      }
      documentObj.subject = patientRef

      const emcounterRefArray: R4.IReference[] = []
      const encounterRef: R4.IReference | undefined =
        labOrderDetails.serviceRequest.encounter
      if (encounterRef) {
        emcounterRefArray.push(encounterRef)
        documentObj.context.encounter = emcounterRefArray
      }
    }

    const iRequest: R4.IBundle_Request = {}
    iRequest.method = R4.Bundle_RequestMethodKind._post
    iRequest.url = 'DocumentReference'
    const bundleEntry: R4.IBundle_Entry = {
      fullUrl: `urn:uuid:documentReference${i}`,
      request: iRequest,
      resource: documentObj,
    }
    bundleArray.push(bundleEntry)
  }
  const documentBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: bundleArray,
  }
  return documentBundle
}

export function getDocumentReferenceBundleForEncounter(
  files: SelectedFile[],
  encounterId: string,
  patientId: string
): R4.IBundle {
  const bundleArray: any = []
  const untiData = getCurrentUserUnitReference()!
  const practRole = getCurrentUserPractitionerRoleDetails()
  for (let i = 0; i < files.length; i++) {
    const documentObj: R4.IDocumentReference = {
      resourceType: 'DocumentReference',
      meta: {
        profile: [
          'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndDocumentReferenceBase',
        ],
      },

      status: R4.DocumentReferenceStatusKind._current,
      subject: {
        reference: `Patient/${patientId}`,
      },
      authenticator: {
        reference: `${practRole.resourceType}/${practRole.id}`,
      },
      type: {
        coding: [
          {
            system: 'http://loinc.org',
            code: '11502-2',
            display: 'Lab Report',
          },
        ],
      },
      identifier: [
        {
          system: 'fileName',
          value: `${files[i].name}`,
        },
      ],
      content: [
        {
          attachment: {
            contentType: `${files[i].type}`,
            title: `${files[i].name}`,
          },
        },
      ],
      context: {
        encounter: [
          {
            reference: `Encounter/${encounterId}`,
          },
        ],
      },
    }
    const iRequest: R4.IBundle_Request = {}
    iRequest.method = R4.Bundle_RequestMethodKind._post
    iRequest.url = 'DocumentReference'
    const bundleEntry: R4.IBundle_Entry = {
      fullUrl: `urn:uuid:documentReference${i}`,
      request: iRequest,
      resource: documentObj,
    }
    bundleArray.push(bundleEntry)
  }
  const documentBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: bundleArray,
  }
  return documentBundle
}
