import { R4 } from '@ahryman40k/ts-fhir-types'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import * as _ from 'lodash'
import { FhirClinicalImpressionDetail } from 'models/fhirClinicalImpression'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import moment from 'moment'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { getDateTime } from 'utils/dateUtil'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'

export function getClinicaImpressionDetailsFromBundleResponse(
  responseCIs: R4.IBundle
): FhirClinicalImpressionDetail[] {
  const convertedCIs: FhirClinicalImpressionDetail[] = []
  const clinicalImpressions: any = {}
  const encounters: any = {}
  const patients: any = {}
  const practitioner: any = {}
  const practitionerRole: any = {}
  const diagnosedCondition: any = {}
  const diagnosedAllergy: any = {}
  if (responseCIs.total) {
    if (responseCIs.total > 0) {
      if (responseCIs.entry) {
        const entries: R4.IBundle_Entry[] = responseCIs.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ClinicalImpression':
                  clinicalImpressions[value.resource.id] =
                    value.resource as R4.IClinicalImpression
                  break
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Practitioner':
                  practitioner[value.resource.id] =
                    value.resource as R4.IPractitioner
                  break
                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break
                case 'PractitionerRole':
                  practitionerRole[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break
                case 'Condition':
                  diagnosedCondition[value.resource.id] =
                    value.resource as R4.ICondition
                  break
                case 'AllergyIntolerance':
                  diagnosedAllergy[value.resource.id] =
                    value.resource as R4.IAllergyIntolerance
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in clinicalImpressions) {
          if (key) {
            const currentSlot: R4.IClinicalImpression = clinicalImpressions[
              key
            ] as R4.IClinicalImpression
            const encounterId: string | undefined =
              currentSlot.encounter?.reference?.split('/')[1]

            const encounter: R4.ISchedule | undefined =
              encounters[encounterId ?? '']
            const practitionerRoleId: string | undefined =
              currentSlot.assessor?.reference?.split('/')[1]
            const patientId: string | undefined =
              currentSlot.subject?.reference?.split('/')[1]
            const practitionerId: string | undefined = encounter?.actor
              ?.find((val) => val.reference?.includes('PractitionerRole/'))
              ?.reference?.split('/')[1]

            const conditionCodes: R4.ICoding[] = []
            const conditionResouces: R4.ICondition[] = []
            const allergyResource: R4.IAllergyIntolerance[] = []

            const allergyCodes: R4.ICoding[] = []
            if (currentSlot.problem) {
              const conditions: R4.IReference[] = currentSlot.problem?.map(
                (r) => r
              )
              const allergies: R4.IReference[] = currentSlot.problem?.map(
                (r) => r
              )
              conditions.filter((ref) => ref.reference?.includes('Condition'))
              allergies.filter((ref) =>
                ref.reference?.includes('AllergyIntolerance')
              )
              const conditionIds: string[] = conditions.map(
                (f) => f.reference?.split('/')[1] ?? ''
              )

              const allergyIds: string[] = allergies.map(
                (f) => f.reference?.split('/')[1] ?? ''
              )

              conditionIds.forEach((id) => {
                const conditionD: R4.ICondition = diagnosedCondition[
                  id
                ] as R4.ICondition
                if (conditionD) {
                  const verificationStatus: R4.ICoding[] =
                    conditionD.verificationStatus?.coding ?? []
                  if (verificationStatus.length > 0) {
                    if (
                      verificationStatus[0].code &&
                      verificationStatus[0].code !== 'refuted'
                    ) {
                      const code: R4.ICoding | undefined =
                        conditionD?.code?.coding?.[0]
                      if (code) {
                        conditionResouces.push(
                          diagnosedCondition[id] as R4.ICondition
                        )
                        conditionCodes.push(code)
                      }
                    }
                  }
                }
              })
              allergyIds.forEach((id) => {
                const alergyT: R4.IAllergyIntolerance = diagnosedAllergy[
                  id
                ] as R4.IAllergyIntolerance
                if (alergyT) {
                  const verificationStatus: R4.ICoding[] =
                    alergyT.verificationStatus?.coding ?? []
                  if (verificationStatus.length > 0) {
                    if (
                      verificationStatus[0].code &&
                      verificationStatus[0].code !== 'refuted'
                    ) {
                      const code: R4.ICoding | undefined = (
                        diagnosedAllergy[id] as R4.IAllergyIntolerance
                      )?.code?.coding?.[0]
                      if (code) {
                        allergyResource.push(
                          diagnosedAllergy[id] as R4.IAllergyIntolerance
                        )
                        allergyCodes.push(code)
                      }
                    }
                  }
                }
              })
            }

            convertedCIs.push({
              clinicalImpression: currentSlot,
              encounter: encounters[encounterId ?? ''],
              patient: patients[patientId ?? ''],
              diagnosedConditionCodes: conditionCodes,
              diagnosedAllergyCodes: allergyCodes,

              allergies: allergyResource,
              diagnosedConditions: conditionResouces,
              practitionerDetail: {
                practitioner: practitioner[practitionerId ?? ''],
                practitionerRole: practitionerRole[practitionerRoleId ?? ''],
              },
            })
          }
        }
      }
    }
  }

  convertedCIs.sort(
    (a: FhirClinicalImpressionDetail, b: FhirClinicalImpressionDetail) =>
      getDateTime(b.clinicalImpression.date).getTime() -
      getDateTime(a.clinicalImpression.date).getTime()
  )
  return convertedCIs
}

export function hasSameSummary(
  currentSummary?: string,
  clinicalImpression?: R4.IClinicalImpression
): boolean {
  if (clinicalImpression && clinicalImpression.summary === currentSummary) {
    return true
  }

  return false
}

export function hasSameCodings(
  conditions: R4.ICoding[],
  clinicalImpression: R4.ICoding[]
): boolean {
  /* let res: boolean = true
   res = clinicalImpression.some((e: R4.ICoding) => {
    const index = conditions.findIndex((condition) => condition.code === e.code)
    if (index === -1) {
      return false
    }
    return true
  }) */
  const ress: R4.ICoding[] = _.differenceWith(
    conditions,
    clinicalImpression,
    _.isEqual
  )

  if (ress != undefined) {
    if (ress.length > 0) {
      return false
    }
  }
  return true
}

export function hasAllergies(
  allergies: R4.ICoding[],
  clinicalImpression: FhirClinicalImpressionDetail
): boolean {
  if (clinicalImpression.diagnosedAllergyCodes != null) {
    return hasSameCodings(allergies, clinicalImpression.diagnosedAllergyCodes)
  }

  return false
}

export function hasConditions(
  allergies: R4.ICodeableConcept[],
  clinicalImpression: FhirClinicalImpressionDetail
): boolean {
  if (clinicalImpression.diagnosedConditionCodes != null) {
    let currentConditions: R4.ICoding[] = []

    allergies.forEach((e) => {
      currentConditions = [...currentConditions, ...(e.coding ?? [])]
    })
    return hasSameCodings(
      currentConditions,
      clinicalImpression.diagnosedConditionCodes
    )
  }

  return false
}

export async function getClinicalImpressionDetailsOfSpecificCategoryOfEncounter(
  encounterId: string,
  identifier: string
): Promise<R4.IBundle | FHIRErrorResponses> {
  const searchParameters: any = {
    encounter: encounterId,
    identifier,
    _include: 'ClinicalImpression:problem',
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const response: any = await fhirClient.doGetResourceForAppointment(
    '/ClinicalImpression',
    '',
    searchParameters
  )

  if (response.type === 'FHIRErrorResponses') {
    return response
  }

  const bundleRes: R4.IBundle = response as R4.IBundle

  if (bundleRes.entry && bundleRes.entry.length > 0) {
    return bundleRes
  }

  return {
    data: '',
    status: 500,
    displayText: 'Error in precessing request',
  } as FHIRErrorResponses
}

export async function getParsedClinicalImpressionOfSpecificCategoryOfEncounter(
  encounterId: string,
  identifier: string
): Promise<FhirClinicalImpressionDetail | FHIRErrorResponses> {
  const response: any =
    await getClinicalImpressionDetailsOfSpecificCategoryOfEncounter(
      encounterId,
      identifier
    )

  if (response.type === 'FHIRErrorResponses') {
    return response
  }
  const clinicalImpressions: FhirClinicalImpressionDetail[] =
    getClinicaImpressionDetailsFromBundleResponse(response as R4.IBundle)

  if (clinicalImpressions.length > 0) return clinicalImpressions[0]

  return {
    data: '',
    status: 200,
    displayText: 'noDetails',
  } as FHIRErrorResponses
}

export async function removeConditionDetailsFromClinicalImpression(
  condition: R4.ICondition,
  clinicalImp: R4.IClinicalImpression
): Promise<boolean | FHIRErrorResponses> {
  try {
    const fhirApi: FHIRApiClient = new FHIRApiClient()
    const response: any = await fhirApi.doCreateFHIRTransaction(
      '',
      getBundleForUpdate(condition, clinicalImp)
    )

    if (response.entry) {
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        return true
      }
      return false
    }
    return response as FHIRErrorResponses
  } catch (error) {
    logger.error(error)
    return {
      data: error,
      status: 500,
      displayText: 'Error',
    } as FHIRErrorResponses
  } finally {
    logger.error('infinaly')
  }
}

export function getBundleForUpdate(
  condition: R4.ICondition,
  clinicalImp: R4.IClinicalImpression
) {
  const modifiedSlot: R4.ICondition = {
    ...condition,
    verificationStatus: {
      text: 'Refuted',
      coding: [
        {
          code: 'refuted',
          display: 'Confirmed',
          system: 'http://terminology.hl7.org/CodeSystem/condition-ver-status',
        },
      ],
    },
  }

  const matchString: string = `W/${JSON.stringify(
    modifiedSlot.meta?.versionId ?? ' '
  )}`
  let problemRefs: R4.IReference[] = []
  if (clinicalImp.problem) {
    problemRefs = [...clinicalImp.problem].filter(
      (e) => !e.reference!.includes(condition.id ?? '')
    )
  }
  const modifiedClinicalImpression: R4.IClinicalImpression = {
    ...clinicalImp,
    problem: problemRefs,
  }

  const matchCIString: string = `W/${JSON.stringify(
    clinicalImp.meta?.versionId ?? ' '
  )}`

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `Condition/${modifiedSlot.id}`,
        request: {
          ifMatch: matchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `Condition/${modifiedSlot.id}`,
        },
        resource: modifiedSlot,
      },
      {
        fullUrl: `ClinicalImpression/${modifiedClinicalImpression.id}`,
        request: {
          ifMatch: matchCIString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `ClinicalImpression/${modifiedClinicalImpression.id}`,
        },
        resource: modifiedClinicalImpression,
      },
    ],
  }

  return requestBundle
}

export async function addConditionDetailsToClinicalImpression(
  diagnosedConditions: R4.ICoding[],
  clinicalImp: R4.IClinicalImpression,
  categoryCoding: R4.ICoding,
  patientId: string,
  encounterId: string
): Promise<boolean | FHIRErrorResponses> {
  try {
    const fhirApi: FHIRApiClient = new FHIRApiClient()
    const response: any = await fhirApi.doCreateFHIRTransaction(
      '',
      createBundleObjectForAddingConditionToClinicalImpression(
        diagnosedConditions,
        clinicalImp,
        categoryCoding,
        patientId,
        encounterId
      )
    )

    if (response.entry) {
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        return true
      }
      return false
    }
    return response as FHIRErrorResponses
  } catch (error) {
    logger.error(error)
    return {
      data: error,
      status: 500,
      displayText: 'Error',
    } as FHIRErrorResponses
  } finally {
    logger.error('infinaly')
  }
}

export async function addNotesDetailsToClinicalImpression(
  notes: string,
  clinicalImp: R4.IClinicalImpression,
  categoryCoding: R4.ICoding,
  encounterId: string
): Promise<boolean | FHIRErrorResponses> {
  try {
    const fhirApi: FHIRApiClient = new FHIRApiClient()
    const response: any = await fhirApi.doCreateFHIRTransaction(
      '',
      createBundleObjectForAddingNotesToClinicalImpression(
        notes,
        clinicalImp,
        categoryCoding,

        encounterId
      )
    )

    if (response.entry) {
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        return true
      }
      return false
    }
    return response as FHIRErrorResponses
  } catch (error) {
    logger.error(error)
    return {
      data: error,
      status: 500,
      displayText: 'Error',
    } as FHIRErrorResponses
  } finally {
    logger.error('infinaly')
  }
}

export async function addConditionDetailsAndClinicalImpression(
  diagnosedConditions: R4.ICoding[],
  categoryCoding: R4.ICoding,
  patientId: string,
  encounterId: string
): Promise<boolean | FHIRErrorResponses> {
  try {
    const fhirApi: FHIRApiClient = new FHIRApiClient()
    const response: any = await fhirApi.doCreateFHIRTransaction(
      '',
      createBundleObjectForAddingConditionAndClinicalImpression(
        diagnosedConditions,

        categoryCoding,
        patientId,
        encounterId
      )
    )

    if (response.entry) {
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        return true
      }
      return false
    }
    return response as FHIRErrorResponses
  } catch (error) {
    logger.error(error)
    return {
      data: error,
      status: 500,
      displayText: 'Error',
    } as FHIRErrorResponses
  } finally {
    logger.error('infinaly')
  }
}

function createBundleObjectForAddingConditionToClinicalImpression(
  diagnosedConditions: R4.ICoding[],
  clinicalImp: R4.IClinicalImpression,
  categoryCoding: R4.ICoding,
  patientId: string,
  encounterId: string
): R4.IBundle | undefined {
  const practitionerDetail = getCurrentUserPractitionerDetails()
  const practitionerRoleDetail = getCurrentUserPractitionerRoleDetails()
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }
  const matchStringClinicalImpression: string = `ClinicalImpression?encounter=${encounterId}`
  if (diagnosedConditions && diagnosedConditions.length > 0) {
    const diagnosedConditionsForImp = diagnosedConditions.map((finding) => {
      const randomNum: number = getRandomInt(999999)
      const conditionFullUrl = `urn:uuid:${randomNum.toString()}`
      const newVal: R4.IClinicalImpression_Finding = {
        itemReference: {
          reference: `${conditionFullUrl}`,
          type: 'Condition',
        },
      }

      const entry: R4.IBundle_Entry = {
        fullUrl: conditionFullUrl,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: 'Condition',
        },
        resource: {
          resourceType: 'Condition',
          id: conditionFullUrl,
          meta: {
            profile: [
              'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndConditionSystemExamination',
            ],
          },
          subject: {
            id: patientId,
            reference: `Patient/${patientId}`,
            type: 'Patient',
          },
          code: { coding: [finding] },
          encounter: {
            reference: `Encounter/${encounterId}`,
            type: 'Encounter',
          },
          recorder: {
            display: getNameFromHumanName(practitionerDetail.name ?? []),
            id: practitionerRoleDetail.id,
            reference: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
            type: practitionerRoleDetail.resourceType,
          },
          asserter: {
            display: getNameFromHumanName(practitionerDetail.name ?? []),
            id: practitionerRoleDetail.id,
            reference: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
            type: practitionerRoleDetail.resourceType,
          },
          verificationStatus: {
            text: 'Confirmed',
            coding: [
              {
                code: 'Confirmed',
                display: 'Confirmed',
                system:
                  'http://terminology.hl7.org/CodeSystem/condition-ver-status',
              },
            ],
          },
          recordedDate: new Date().toISOString(),

          category: [
            {
              coding: [categoryCoding],
            },
          ],
        },
      }
      requestBundle.entry?.push(entry)
      return newVal
    })

    const allFindings = [
      ...(clinicalImp.finding ?? []),
      ...diagnosedConditionsForImp,
    ]

    const allProblems = [
      ...(clinicalImp.problem ?? []),
      ...diagnosedConditionsForImp.map((e) => e.itemReference!),
    ]
    const cis: R4.IClinicalImpression = {
      ...clinicalImp,
      finding: allFindings ?? [],
      problem: allProblems ?? [],
    }

    const cisEntry: R4.IBundle_Entry = {
      fullUrl: 'urn:uuid:1232323232325',
      request: {
        url: matchStringClinicalImpression,
        method: R4.Bundle_RequestMethodKind._put,
      },
      resource: cis,
    }
    requestBundle.entry?.push(cisEntry)

    return requestBundle
  }
  return undefined
}

function createBundleObjectForAddingNotesToClinicalImpression(
  notes: string,
  clinicalImp: R4.IClinicalImpression,
  categoryCoding: R4.ICoding,
  encounterId: string
): R4.IBundle | undefined {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }
  const matchStringClinicalImpression: string = `ClinicalImpression?encounter=${encounterId}`
  const cis: R4.IClinicalImpression = {
    ...clinicalImp,
    summary: notes,
  }

  const cisEntry: R4.IBundle_Entry = {
    fullUrl: 'urn:uuid:1232323232325',
    request: {
      url: matchStringClinicalImpression,
      method: R4.Bundle_RequestMethodKind._put,
    },
    resource: cis,
  }
  requestBundle.entry?.push(cisEntry)

  return requestBundle
}

function createBundleObjectForAddingConditionAndClinicalImpression(
  diagnosedConditions: R4.ICoding[],
  categoryCoding: R4.ICoding,
  patientId: string,
  encounterId: string
): R4.IBundle | undefined {
  const practitionerDetail = getCurrentUserPractitionerDetails()
  const practitionerRoleDetail = getCurrentUserPractitionerRoleDetails()
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }
  const matchStringClinicalImpression: string = `ClinicalImpression?encounter=${encounterId}`
  if (diagnosedConditions && diagnosedConditions.length > 0) {
    const diagnosedConditionsForImp = diagnosedConditions.map((finding) => {
      const randomNum: number = getRandomInt(999999)
      const conditionFullUrl = `urn:uuid:${randomNum.toString()}`
      const newVal: R4.IClinicalImpression_Finding = {
        itemReference: {
          reference: `${conditionFullUrl}`,
          type: 'Condition',
        },
      }

      const entry: R4.IBundle_Entry = {
        fullUrl: conditionFullUrl,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: 'Condition',
        },
        resource: {
          resourceType: 'Condition',
          meta: {
            profile: [
              'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndConditionSystemExamination',
            ],
          },
          subject: {
            id: patientId,
            reference: `Patient/${patientId}`,
            type: 'Patient',
          },
          code: { coding: [finding] },
          encounter: {
            reference: `Encounter/${encounterId}`,
            type: 'Encounter',
          },
          recorder: {
            display: getNameFromHumanName(practitionerDetail.name ?? []),
            id: practitionerRoleDetail.id,
            reference: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
            type: practitionerRoleDetail.resourceType,
          },
          asserter: {
            display: getNameFromHumanName(practitionerDetail.name ?? []),
            id: practitionerRoleDetail.id,
            reference: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
            type: practitionerRoleDetail.resourceType,
          },
          verificationStatus: {
            text: 'Confirmed',
            coding: [
              {
                code: 'Confirmed',
                display: 'Confirmed',
                system:
                  'http://terminology.hl7.org/CodeSystem/condition-ver-status',
              },
            ],
          },
          recordedDate: new Date().toISOString(),

          category: [
            {
              coding: [categoryCoding],
            },
          ],
        },
      }
      requestBundle.entry?.push(entry)
      return newVal
    })

    const allProblems = [
      ...diagnosedConditionsForImp.map((e) => e.itemReference!),
    ]

    const cis: R4.IClinicalImpression = {
      resourceType: 'ClinicalImpression',
      meta: {
        profile: [
          'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndClinicalImpressionSystemExamination',
        ],
      },

      status: 'in-progress',
      identifier: [
        {
          system: 'http://snomed.info/sct',
          value: categoryCoding.code,
        },
      ],
      code: {
        coding: [categoryCoding],
      },
      encounter: {
        reference: `Encounter/${encounterId}`,
        type: 'Encounter',
      },
      assessor: {
        reference: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
        id: practitionerRoleDetail.id,
        type: practitionerRoleDetail.resourceType,
      },
      subject: {
        reference: `Patient/${patientId}`,
        id: patientId,
        type: 'Patient',
      },
      problem: allProblems,
      finding: diagnosedConditionsForImp ?? [],

      date: moment(new Date()).format(),
    }

    const cisEntry: R4.IBundle_Entry = {
      fullUrl: 'urn:uuid:1232323232325',
      request: {
        url: matchStringClinicalImpression,
        method: R4.Bundle_RequestMethodKind._put,
      },
      resource: cis,
    }
    requestBundle.entry?.push(cisEntry)

    return requestBundle
  }
  return undefined
}
function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max))
}
