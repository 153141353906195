import { Divider } from '@mantine/core'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { AccountDetails, LabReferralDetails } from 'models/labReferralDetails'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchAccountList } from 'redux/administration/accounts/accountSearchSlice'
import {
  acceptInvite,
  resetUpdateInviteState,
} from 'redux/administration/referralSetup/acceptInvite/labReferralAcceptInviteSlice'
import { searchingReferrals } from 'redux/administration/referralSetup/referralSearch/labReferralSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  isCurrentUnitIsLab,
  isCurrentUnitIsWellonessCenter,
} from 'services/userDetailsService'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { AccountDataTable } from './accountDataTable'
import { AddAccount } from './addNewAccount'
// import { AddReferralsToForm } from './addNewReferrals'
// import { ReferralDataTable } from './referralDataTable'
// import { AddMembershipForm } from './addMembershipForm'
// import { MembershipDetailsPage } from './memberdhipDetails'
// import { MembershipDataTable } from './membershipDataTable'

// import EditTherapy from './editTherapy';

export interface MembershipSearchProps {
  onTap?: () => void
  onAdd?: () => void
}

interface EditAccountState {
  openEditOption: boolean
  accountDetailsData?: AccountDetails
}

export const AccountDetailsPage: React.FC<MembershipSearchProps> = ({
  onTap,
  onAdd,
}) => {
  const accountSearchSlice = useSelector(
    (state: RootState) => state.accountSearchSlice
  )

  const [selectedMembership, setselectedMembership] =
    useState<LabReferralDetails>()
  const dispatch = useDispatch()
  const { height, width } = useWindowDimensions()

  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  const [openPending, setOpenPending] = useState<EditAccountState>({
    openEditOption: false,
    accountDetailsData: undefined,
  })

  useEffect(() => {
    if (onAdd) onAdd()
    dispatch(SearchAccountList())
  }, [dispatch])

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      flexGrow={1}
      height='100%'
      bgcolor={kDialogueBackground}
    >
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        bgcolor={kDialogueBackground}
        justifyContent='flex-end'
        paddingRight={1}
        paddingTop={6}
      >
        <Box
          display='flex'
          flexGrow={1}
          flexDirection='column'
          width='100%'
          height='100%'
          justifyContent='center'
          alignContent='center'
          paddingTop={1}
          style={{ overflow: 'auto', maxHeight: '100%' }}
        >
          <Divider />
          {accountSearchSlice.searching && (
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
              height='100%'
              width='100%'
            >
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            </Box>
          )}
          <Box
            display='flex'
            flexGrow={4}
            flexDirection='column'
            justifyContent='center'
            alignContent='center'
            overflow='auto'
            style={{
              backgroundColor: '#ececec',
            }}
          >
            {accountSearchSlice.noResultsAvailable && (
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='flex-end'
                py={1.5}
              >
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  disabled={accountSearchSlice.resultsAvailable}
                  onClick={() => {
                    setShowPopup(true)
                  }}
                >
                  Add Account Details
                </Button>
              </Box>
            )}
            {accountSearchSlice.noResultsAvailable && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='100%'
                height={height - 200}
              >
                <Typography variant='subtitle1' color='initial' align='center'>
                  No account data available
                </Typography>
              </Box>
            )}
            {accountSearchSlice.error && (
              <Box
                //   display='flex'
                //   flexDirection='column'
                justifyContent='center'
                width='100%'
                height='100%'
                alignContent='center'
                paddingLeft={50}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container justifyContent='center'>
                      <Typography
                        variant='subtitle1'
                        color='error'
                        align='center'
                      >
                        Error while searching referrals.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            {accountSearchSlice.resultsAvailable && (
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height='100%'
                overflow='auto'
                // flexGrow={1}
              >
                <Box
                  display='flex'
                  flexGrow={1}
                  height='100%'
                  overflow='hidden'
                  //  paddingTop={0.2}
                >
                  <AccountDataTable
                    accountList={accountSearchSlice.accountDetailsList ?? []}
                    onAccountSelected={(selectedAppointmentData) => {
                      setOpenPending({
                        openEditOption: true,
                        accountDetailsData: selectedAppointmentData,
                      })
                    }}
                  />
                </Box>

                {/* {selectedMembership && (
                  <Box
                    display='flex'
                    flexGrow={1}
                    overflow='auto'
                    width='70%'
                    bgcolor='white'
                  >
                    <MembershipDetailsPage
                      onCloseClicked={() => {
                        setselectedMembership(undefined)
                      }}
                      usersData={selectedMembership}
                    />
                  </Box>
                )} */}
              </Box>
            )}
          </Box>

          {showPopup && (
            <AddAccount
              open={showPopup}
              onBackClick={() => {
                setShowPopup(false)
                dispatch(SearchAccountList())
              }}
              onContinueClick={() => {
                setShowPopup(false)
                dispatch(SearchAccountList())
              }}
            />
          )}

          {openPending.openEditOption && openPending.accountDetailsData && (
            <AddAccount
              open={openPending.openEditOption}
              onBackClick={() => {
                setOpenPending({
                  openEditOption: true,
                  accountDetailsData: undefined,
                })
                dispatch(SearchAccountList())
              }}
              onContinueClick={() => {
                setOpenPending({
                  openEditOption: false,
                  accountDetailsData: undefined,
                })
                dispatch(SearchAccountList())
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
