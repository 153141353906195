import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchPractitionerWithUnits } from 'redux/administration/units/unitsWithPractioner/PractitionerWithUnitSearchSlice'
import { RootState } from 'redux/rootReducer'
import { resetAppointmentForUnit } from 'redux/welloAdmin/appointmentCount/appointmentCountPerUnit'
import { searchForMainOrg } from 'redux/welloAdmin/mainOrg/mainOrgSearchSlice'
import { resetRevenueForUnit } from 'redux/welloAdmin/revenue/paymentSearchSlice'
import { resetUnitSearchCount } from 'redux/welloAdmin/unitCount/unitCountSearchSlice'
import { resetPatientSearchPatientForCount } from 'redux/welloAdmin/user/patientCount/patientCountSliceForUnit'
import { resetStateForWelloAdminPract } from 'redux/welloAdmin/user/practitionerSearchSliceAcrossUnit'
import { resetWellnessAppointmentForUnit } from 'redux/welloAdmin/wellnessAppointmentCount/wellnessAppointmentCountPerUnit'
import { getCurrentUserMainOrganizationDetails } from 'services/userDetailsService'
import { wpAdminOptionsMain } from 'utils/constants'
import { WelloLoadingIndicator } from 'wello-web-components'
import { MainOrgSplit } from '../../welloAdmin/userDetails/mainOrgSplit'
import { UnitDataTile } from './unitDataTile'
import { UnitHeaderTile } from './unitHeader'

export interface IUnitAdmin {
  children?: React.ReactNode
}

export const UnitsOfOrganization: React.FC = () => {
  const unitsOfOrganizationSlice = useSelector(
    (state: RootState) => state.practitionerWithUnitSearchSlice
  )

  const welloUnitsSearchSlice = useSelector(
    (state: RootState) => state.welloAdminOrgSlice
  )
  const dispatch = useDispatch()
  const [type, setType] = useState('ipd')
  const [selectedOrg, setSelectedOrg] = useState<R4.IOrganization>()
  const [selecedUnitList, setSelectedUnitList] = useState<R4.IOrganization[]>(
    []
  )
  const [mainOrg, setMainOrg] = useState<string[]>([])
  const [selectedType, setSelectedType] = useState<string>(
    wpAdminOptionsMain[0].value
  )

  useEffect(() => {
    dispatch(searchForMainOrg())
    // dispatch(requestIPDAppCountForToday(selectedDate))
  }, [])

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      <Box>
        {welloUnitsSearchSlice.searching && (
          <WelloLoadingIndicator message='Searching' />
        )}
        {welloUnitsSearchSlice.resultsAvailable &&
          welloUnitsSearchSlice.unitsList && (
            <Box display='flex' flexDirection='column' py={1} width='100%'>
              <MainOrgSplit
                org={getCurrentUserMainOrganizationDetails()}
                mainOrgId={welloUnitsSearchSlice.listOfOrgs ?? []}
                type={type}
                onTypeSelected={(id: string) => {
                  setSelectedType(id)
                }}
                onCloseClicked={() => {
                  dispatch(resetStateForWelloAdminPract())
                  dispatch(resetPatientSearchPatientForCount())
                  dispatch(resetRevenueForUnit())
                  dispatch(resetWellnessAppointmentForUnit())
                  dispatch(resetAppointmentForUnit())
                  dispatch(resetUnitSearchCount())
                  setSelectedOrg(undefined)
                  setMainOrg([])
                }}
                unitList={
                  welloUnitsSearchSlice.unitsList
                    ? welloUnitsSearchSlice.unitsList[0].unitList ?? []
                    : []
                }
              />
            </Box>
          )}
        {unitsOfOrganizationSlice.noResultsAvailable && (
          <Typography>Add Units</Typography>
        )}

        {unitsOfOrganizationSlice.error && (
          <Typography>
            {' '}
            {unitsOfOrganizationSlice.errorMessage ??
              'Error while searching Units . Pleas try again'}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
