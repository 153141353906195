/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { faFileSignature } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
  IconButton,
} from '@material-ui/core'
import { kPrimaryMain } from 'configs/styles/muiThemes'
import { CloseOutlined, DeleteForeverOutlined, Edit } from '@material-ui/icons'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { PurposeOfUse } from 'models/purposeOfUse'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiVideo } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import 'react-vis/dist/style.css'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { data64 } from 'services/fileServices'
import { HigherFunctionObservationType } from 'utils/constants/observations/higher_function_observation_codes'
import { SensationObservationCodes } from 'utils/constants/observations/sensation_observation_codes'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  getHasDoctorSign,
  taskHasDoctorSign,
  taskHasPatientSign,
  updateSignatures,
  getHasPatientSign,
  getEncodedData,
  getUpdatedTaskDetails,
} from 'utils/fhirResoureHelpers/invoice_helper'
import { WelloFormItemLabel } from 'wello-web-components'
import { WebCamCapturePhoto } from '../administration/actors/micro-components/webcam_capture'
import { SelectedFile, WelloFilePicker } from '../LeftMenu/WelloFilePicker'

interface SignaturesOfIPDProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  isReadOnly?: boolean
}

export const SignaturesOfIPD: React.FC<SignaturesOfIPDProps> = ({
  fhirAppointmentDetails,
  isReadOnly = false,
}: SignaturesOfIPDProps) => {
  const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
    initial: true,
  })
  const dispatch = useDispatch()

  const [openWebCam, setOpenWebCam] = useState<boolean>(false)

  const [callBackForCapturePhoto, setCallBackForCapturePhoto] = React.useState(
    () => (imageName: any, imageDataType: any, imageUri: any) => {}
  )
  // eslint-disable-next-line no-restricted-globals
  const isNotSecure = location.protocol !== 'https:'
  const [mainTask, setMainTask] = useState<R4.ITask>(
    fhirAppointmentDetails.mainTask
  )

  const [updatingStatus, setUpdatingStatus] = useState<NetworkRequestStatus>({
    initial: true,
  })

  function updateSignature() {
    setUpdatingStatus({
      requesting: true,
    })

    updateSignatures(
      mainTask,
      selectedPatientSignature,
      selectedDoctorSignature
    )
      .then((e) => {
        if ('resourceType' in e!) {
          setUpdatingStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(showSuccessAlert('Signature details updated'))
          setMainTask(e)
          setSelectedDoctorSignature(undefined)
          setSelectedPatientSignature(undefined)
        } else {
          setUpdatingStatus({
            requesting: false,
            requestError: true,
          })
          dispatch(showErrorAlert('Error while updating signatures'))
        }
      })
      .catch((e) => {
        setUpdatingStatus({
          requesting: false,
          requestError: true,
        })
        dispatch(showErrorAlert('Error while updating signatures'))
      })
  }

  function getTaskDetails() {
    setFetchingStatus({
      requesting: true,
    })

    getUpdatedTaskDetails(mainTask.id ?? '')
      .then((e) => {
        if ('resourceType' in e!) {
          setFetchingStatus({
            requesting: false,
            requestSuccess: true,
          })

          setMainTask(e)
          setSelectedDoctorSignature(undefined)
          setSelectedPatientSignature(undefined)
        } else {
          setFetchingStatus({
            requesting: false,
            requestError: true,
          })
          dispatch(showErrorAlert('Error while fetching signatures'))
        }
      })
      .catch((e) => {
        setFetchingStatus({
          requesting: false,
          requestError: true,
        })
        dispatch(showErrorAlert('Error while fetching signatures'))
      })
  }

  const { t } = useTranslation(['common'])

  const [selectedDoctorSignature, setSelectedDoctorSignature] =
    useState<SelectedFile>()
  const [selectedPatientSignature, setSelectedPatientSignature] =
    useState<SelectedFile>()

  useEffect(() => {
    getTaskDetails()
  }, [])

  return (
    <Grid
      container
      direction='column'
      style={{ padding: 0, margin: 0 }}
      spacing={1}
    >
      <Grid item direction='row' style={{ padding: 0, margin: 0 }}>
        <Box
          sx={{ width: '100%' }}
          display='flex'
          alignItems='center'
          flexDirection='row'
          bgcolor='#999999'
          minHeight={40}
        >
          <Box p={1}>
            <Typography
              id='signature_label'
              variant='body1'
              style={{ color: 'white', fontWeight: 600 }}
            >
              {' '}
              Signature
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} style={{ padding: '8px' }}>
        {(updatingStatus.requesting || fetchingStatus.requesting) && (
          <CircularProgress size={20} />
        )}
        {!updatingStatus.requesting && !fetchingStatus.requesting && (
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='space-between'
          >
            <Box display='flex' flexDirection='column'>
              {!taskHasDoctorSign(mainTask) &&
                (selectedDoctorSignature === undefined ||
                  selectedDoctorSignature.path === undefined) && (
                  <Box
                    display='flex'
                    flexDirection='row'
                    style={{ cursor: 'pointer' }}
                    alignItems='center'
                    id='sign1'
                    onClick={() => {
                      setCallBackForCapturePhoto(
                        () => (imageName: any, dataType: any, image: any) => {
                          setSelectedDoctorSignature({
                            path: image,
                            type: dataType,
                            local: false,
                            name: `ipd_doc_sign${fhirAppointmentDetails.mainServiceRequest.id}`,
                          })
                        }
                      )
                      setOpenWebCam(true)
                    }}
                  >
                    <Box>
                      <h5>
                        {' '}
                        <FiVideo id='upload1' />{' '}
                      </h5>
                    </Box>
                    <Box p={1}>
                      <Typography variant='subtitle2' color='primary'>
                        Capture Doctor Signature
                      </Typography>
                    </Box>
                  </Box>
                )}

              {!taskHasDoctorSign(mainTask) &&
                selectedDoctorSignature &&
                selectedDoctorSignature.path && (
                  <Box display='flex' flexDirection='column'>
                    <IconButton
                      aria-label='edit'
                      id='sign2'
                      onClick={() => {
                        setCallBackForCapturePhoto(
                          () => (imageName: any, dataType: any, image: any) => {
                            setSelectedDoctorSignature({
                              path: image,
                              type: dataType,
                              local: false,
                              name: `ipd_doc_sign${fhirAppointmentDetails.mainServiceRequest.id}`,
                            })
                          }
                        )
                        setOpenWebCam(true)
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <img
                      src={selectedDoctorSignature.path as string}
                      alt='doc_sign'
                      height='90px/'
                      width='180px'
                    />
                  </Box>
                )}

              {taskHasDoctorSign(mainTask) && (
                <img
                  src={getHasDoctorSign(mainTask)}
                  alt='doc_sign'
                  height='90px/'
                  width='180px'
                />
              )}

              <WelloFormItemLabel title='Doctor Signature' />
            </Box>

            <Box display='flex' flexDirection='column'>
              {!taskHasPatientSign(mainTask) &&
                (selectedPatientSignature === undefined ||
                  selectedPatientSignature.path === undefined) && (
                  <Box
                    display='flex'
                    id='sign3'
                    flexDirection='row'
                    style={{ cursor: 'pointer' }}
                    alignItems='center'
                    onClick={() => {
                      setCallBackForCapturePhoto(
                        () => (imageName: any, dataType: any, image: any) => {
                          setSelectedPatientSignature({
                            path: image,
                            type: dataType,
                            local: false,
                            name: `ipd_patient_sign${fhirAppointmentDetails.mainServiceRequest.id}`,
                          })
                        }
                      )
                      setOpenWebCam(true)
                    }}
                  >
                    <Box>
                      <h5>
                        {' '}
                        <FiVideo id='upload2' />{' '}
                      </h5>
                    </Box>
                    <Box p={1}>
                      <Typography variant='subtitle2' color='primary'>
                        Capture Patient Signature
                      </Typography>
                    </Box>
                  </Box>
                )}

              {!taskHasPatientSign(mainTask) &&
                selectedPatientSignature &&
                selectedPatientSignature.path && (
                  <Box display='flex' flexDirection='column'>
                    <IconButton
                      aria-label='edit'
                      id='sign4'
                      onClick={() => {
                        setCallBackForCapturePhoto(
                          () => (imageName: any, dataType: any, image: any) => {
                            setSelectedPatientSignature({
                              path: image,
                              type: dataType,
                              local: false,
                              name: `ipd_patient_sign${fhirAppointmentDetails.mainServiceRequest.id}`,
                            })
                          }
                        )
                        setOpenWebCam(true)
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <img
                      id='img2'
                      src={selectedPatientSignature.path as string}
                      alt='doc_sign'
                      height='90px/'
                    />
                  </Box>
                )}
              {taskHasPatientSign(mainTask) && (
                <img
                  src={getHasPatientSign(mainTask)}
                  id='patient_sign_img'
                  alt='patient_sign'
                  height='90px/'
                />
              )}

              <WelloFormItemLabel title='Patient Signature' />
            </Box>
          </Box>
        )}
      </Grid>
      <Grid item direction='row' style={{ padding: 0, margin: 0 }}>
        <Box display='flex' flexDirection='row' width='100%'>
          {(selectedPatientSignature || selectedDoctorSignature) && (
            <Button
              color='default'
              variant='contained'
              id='patient_save'
              onClick={() => {
                updateSignature()
              }}
            >
              Save
            </Button>
          )}
        </Box>
      </Grid>
      {openWebCam && (
        <WebCamCapturePhoto
          id='webcam4'
          open={openWebCam}
          onBackClick={() => {
            setOpenWebCam(false)
          }}
          cropAspectRatio={16 / 9}
          onContinueClick={(imgSrc) => {
            const data = imgSrc
              .toString()
              .split(',')[0]
              .replace('data:', '')
              .replace(';base64', '')
            callBackForCapturePhoto(
              undefined,
              imgSrc!.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)![0]!,
              imgSrc
            )
            /* values.imgName = imgSrc.toString().split(',')[1]
              values.profilePhotoMimeType = data
              values.photoUri = imgSrc */
          }}
        />
      )}
    </Grid>
  )
}
