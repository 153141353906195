import { Gender, Tag } from "models/package"

export function getGenderList(genderData: Gender): string {
    const usageData:string[]=[]
    if(genderData.Male)
    usageData.push('Male') 
    if(genderData.Female)
    usageData.push(' Female') 
    if(genderData.Others)
    usageData.push(' Others') 
    
    return usageData.toString()
  }