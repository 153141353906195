import { Box, Card, CardContent, Divider } from '@material-ui/core'
import { LabOfferingDetail } from 'models/labOfferDetail'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { getTotalAmountOfLabOfferings } from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { ItemOrderSummary } from './itemOrdersummary'
import { TotalAmount } from './totalAmount'

interface Props {
  planDefinitions?: LabOfferingDetail[]
  showTitle?: boolean
}
export const OrderAmountSummaryTile: React.FC<Props> = ({
  planDefinitions,
  showTitle = true,
}: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
        </div>
      )}
    >
      <Card
        elevation={0}
        style={{
          width: '100%',
        }}
      >
        <CardContent
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 3,
          }}
        >
          <Box display='flex' flexDirection='column' width='100%'>
            {/* {showTitle && (
              <Box display='flex' flexDirection='row' width='100%'>
                <Typography variant='h6' color='initial'>
                  {t('labelCommon:order_summary')}
                </Typography>
              </Box>
            )} */}
            {planDefinitions && planDefinitions.length > 0 && (
              <Box display='flex' flexDirection='row ' width='100%'>
                <Box display='flex' flexDirection='column' width='100%'>
                  {planDefinitions?.map(
                    (eachOffering) =>
                      eachOffering.chargeItem && (
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          alignContent='flex-end'
                          justifyContent='space-between'
                          id={`ord_test_item_${eachOffering.id}`}
                          key={`ord_test_item_key_${eachOffering.id}`}
                          px={1}
                        >
                          <ItemOrderSummary
                            chargeItemDefinition={eachOffering.chargeItem}
                            itemName={eachOffering.planDefinition.title ?? ''}
                          />
                        </Box>
                      )
                  )}

                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    paddingTop={1}
                    paddingBottom={1}
                  >
                    <Divider
                      orientation='horizontal'
                      color='#6B6B6B'
                      variant='fullWidth'
                      style={{
                        backgroundColor: 'black',
                        color: '#6B6B6B',
                        width: '100%',
                      }}
                    />
                  </Box>

                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    paddingTop={0.5}
                    paddingBottom={1}
                    px={1}
                  >
                    <TotalAmount
                      currency='INR'
                      totalAmount={getTotalAmountOfLabOfferings(
                        planDefinitions
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            )}

            {/* {!planDefinitions ||
              (planDefinitions.length === 0 && (
                <Box display='flex' flexDirection='row'>
                  <Typography variant='body1' color='primary'>
                    {t('labelCommon:select_test_package')}
                  </Typography>
                </Box>
              ))} */}
          </Box>
        </CardContent>
      </Card>
    </ErrorBoundary>
  )
}
