import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  LinearProgress,
} from '@material-ui/core'

import { FhirClinicalImpressionDetail } from 'models/fhirClinicalImpression'
import { UmlResponse } from 'models/umlResponse'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import {
  getOtherSystemDiagnosisOfAppointment,
  resetState,
  updateOtherSystemDiagnosis,
} from 'redux/fhirMedicalResources/systems/addOtherSystemsDiagnosis/addOtherSystemDiagnosisSlice'
import { RootState } from 'redux/rootReducer'
import {
  hasAllergies,
  hasConditions,
  hasSameSummary,
} from 'utils/fhirResoureHelpers/clinicalImpressionHelper'
import { logger } from 'utils/logger'

interface OtherSystemDiagnosisProps {
  encounterId: string
  patientId: string
  isAyurveda?: boolean
}

export const OtherSystemDiagnosis: React.FC<OtherSystemDiagnosisProps> = ({
  encounterId,
  patientId,
  isAyurveda,
}: OtherSystemDiagnosisProps) => {
  const [summary, setSummary] = React.useState<string>()
  const [fetchedImp, setFetchedImp] =
    React.useState<FhirClinicalImpressionDetail>()
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const [diagnosedConditions, setDiagnosedConditions] = useState<R4.ICoding[]>(
    []
  )

  const selecteDiagnosedConditions = useRef<R4.ICoding[]>()
  const selecteDiagnosedAllergies = useRef<R4.ICoding[]>()
  const preSelectedSurgery = useRef<R4.ICoding[]>()

  const assessmentDetailsSlice = useSelector(
    (state: RootState) => state.addOtherSystemDiagnosisSlice
  )

  const [isActive, setIsActive] = useState<boolean>(false)

  function updateDetails(
    condition: string,
    operation: string,
    deletedData?: R4.ICoding
  ) {
    if (selecteDiagnosedConditions.current !== undefined) {
      if (
        !hasSameSummary(
          summary,
          assessmentDetailsSlice.clinicalImpressions?.clinicalImpression
        ) ||
        !hasAllergies(
          selecteDiagnosedAllergies.current ?? [],
          assessmentDetailsSlice.clinicalImpressions!
        ) ||
        !hasConditions(
          selecteDiagnosedConditions.current ?? [],
          assessmentDetailsSlice.clinicalImpressions!
        )
      ) {
        dispatch(
          updateOtherSystemDiagnosis(
            condition,
            operation,
            encounterId,
            patientId,
            summary ?? '',
            [],
            selecteDiagnosedConditions.current,
            selecteDiagnosedAllergies.current ?? [],
            deletedData
          )
        )
      } else {
        dispatch(
          updateOtherSystemDiagnosis(
            condition,
            operation,
            encounterId,
            patientId,
            summary ?? '',
            [],
            selecteDiagnosedConditions.current,
            selecteDiagnosedAllergies.current ?? [],
            deletedData
          )
        )
      }
    } else {
      dispatch(
        updateOtherSystemDiagnosis(
          condition,
          operation,
          encounterId,
          patientId,
          summary ?? '',
          [],
          selecteDiagnosedConditions.current,
          selecteDiagnosedAllergies.current ?? [],
          deletedData
        )
      )
    }
  }

  useEffect(() => {
    dispatch(getOtherSystemDiagnosisOfAppointment(encounterId))
  }, [])

  useEffect(() => {
    if (assessmentDetailsSlice.updatedImpressions) {
      dispatch(getOtherSystemDiagnosisOfAppointment(encounterId))
      dispatch(resetState())
    }
    if (assessmentDetailsSlice.resultsAvailable) {
      if (assessmentDetailsSlice.clinicalImpressions) {
        updateAssessmentDetails(assessmentDetailsSlice.clinicalImpressions)
        dispatch(resetState())
      }
    }
  }, [dispatch, assessmentDetailsSlice])

  const updateAssessmentDetails = (condition: FhirClinicalImpressionDetail) => {
    const conditonDataArr: UmlResponse[] = []
    const allergyDataArr: UmlResponse[] = []
    setFetchedImp(condition)

    logger.info('FhirAssessment Details,', condition)
    if (condition.clinicalImpression.summary) {
      setSummary(condition.clinicalImpression.summary)
    }

    if (
      condition.diagnosedConditionCodes &&
      condition.diagnosedConditionCodes.length > 0
    ) {
      for (let i = 0; i < condition.diagnosedConditionCodes.length; i++) {
        const conditonData: UmlResponse = {
          cui: condition.diagnosedConditionCodes[i].code ?? '',
          snomed_ct: condition.diagnosedConditionCodes[i].code ?? '',
          display: condition.diagnosedConditionCodes[i].display ?? '',
          label: condition.diagnosedConditionCodes[i].display ?? '',
        }
        conditonDataArr.push(conditonData)
      }

      selecteDiagnosedConditions.current = condition.diagnosedConditionCodes
      preSelectedSurgery.current = conditonDataArr
      setDiagnosedConditions(condition.diagnosedConditionCodes)
    } else {
      setDiagnosedConditions([])
    }
  }

  return (
    <Box
      style={{ paddingLeft: '2px', paddingBottom: '24px' }}
      display='flex'
      flexDirection='row'
    >
      <Box width='100%'>
        <Grid container direction='column' style={{ width: '100%' }}>
          <Grid item style={{ width: '100%' }}>
            <ReactVisibilitySensor
              offset={{
                top: 100,
                bottom: 30,
              }}
            >
              <Grid
                container
                direction='column'
                id='visit_summary'
                style={{ width: '100%' }}
              >
                <Grid item style={{ width: '100%' }}>
                  <Box display='flex' py={1} width='100%'>
                    <Box
                      width='100%'
                      display='flex'
                      alignItems='center'
                      flexDirection='row'
                      bgcolor='#999999'
                      minHeight={45}
                    >
                      <Box p={2} width='100%'>
                        <Typography
                          variant='body1'
                          style={{ color: 'white', fontWeight: 600 }}
                        >
                          Other Findings
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                {assessmentDetailsSlice.updatingImpressions && (
                  <Grid item xs={8}>
                    <LinearProgress />
                  </Grid>
                )}

                <Grid item xs={isAyurveda ? 12 : 8}>
                  <Box paddingBottom={0.5}>
                    <Typography variant='subtitle2'>Notes</Typography>
                  </Box>
                  <Box width={isAyurveda ? '93%' : '100%'}>
                    <TextField
                      id='consult_assessment_summary_tf'
                      value={summary}
                      fullWidth
                      multiline={true}
                      variant='outlined'
                      minRows={4.5}
                      maxRows={6}
                      InputProps={{
                        color: 'primary',

                        endAdornment: (
                          <InputAdornment position='end'>
                            {' '}
                            <Button
                              variant='contained'
                              color='primary'
                              disabled={
                                !summary ||
                                summary.trim().length === 0 ||
                                (fetchedImp &&
                                  hasSameSummary(
                                    summary.trim(),
                                    fetchedImp.clinicalImpression
                                  )) ||
                                assessmentDetailsSlice.updatingImpressions
                              }
                              onClick={() => {
                                updateDetails('summary', 'add')
                              }}
                            >
                              {t('labelCommon:done')}
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) => {
                        setSummary(event.target.value)
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </ReactVisibilitySensor>
          </Grid>

          {/* {carePlanSubscriptionSlice.noResultsAvailable && (
            <Grid item>
              <Typography> Medical condition history not available</Typography>
            </Grid>
          )}
          {carePlanSubscriptionSlice.resultsAvailable &&
            carePlanSubscriptionSlice.subscribed &&
            patientSubscriptionsSlice.resultsAvailable &&
            (patientSubscriptionsSlice.hCarePlans === undefined ||
              patientSubscriptionsSlice.hCarePlans?.length === 0) && (
              <Grid item>
                <Box p={1} bgcolor='#FDFFCD' width='20%'>
                  <SubscribeToHypertensionCarePlan
                    fhirAppointmentDetails={fhirAppointmentDetails}
                  />
                </Box>
              </Grid>
            )} */}

          <Grid item>
            <Grid container direction='column'>
              <Grid item id='diagnosis'>
                <Grid
                  container
                  direction='row'
                  style={{ padding: 0, margin: 0 }}
                >
                  {assessmentDetailsSlice.resultsAvailable &&
                    assessmentDetailsSlice.clinicalImpressions && (
                      <Box py={1}>
                        <Typography variant='h5' color='primary'>
                          {' '}
                          {t('labelCommon:diagnosis')}{' '}
                        </Typography>
                      </Box>
                    )}
                </Grid>
              </Grid>

              {/* {diagnosedAllergies && diagnosedAllergies.length > 0 && (
                  <Box>
                    {diagnosedAllergies.map((allergy, index) => (
                      <Box key={`key_allergy_${allergy.id ?? allergy.display}`}>
                        <SOAPNumberedElement
                          title={allergy.display ?? ''}
                          onlyDetails={false}
                          details='Allergy'
                          itemNumber={(index + 1).toString()}
                          isEditable={false}
                        />
                      </Box>
                    ))}
                  </Box>
                )} */}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* <Box display='flex' flexDirection='column' width='97%' p={2}>
        <PlanDetials fhirAppointmentDetails={fhirAppointmentDetails} />
      </Box> */}
    </Box>
  )
}
