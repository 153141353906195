import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Grid,
  Divider,
  Tooltip,
  Link,
  Collapse,
  Button,
} from '@material-ui/core'
import AodIcon from '@mui/icons-material/Aod'

import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedCondition, GroupedConditionData } from 'models/groupedCondition'
import { PurposeOfUse } from 'models/purposeOfUse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestConditionHistoryOfPatient } from 'redux/patientMedicalHistory/medicalConditionHistory/medicalHistoryConditionsSlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getAgeOf, getTimeAgo } from 'utils/dateUtil'
import {
  getNotesFromAllergy,
  getNotesString,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { SOAPIndicativeElement } from 'wello-web-components'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { updateMedicalConditions } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getValueRefValueExtensionsOfUrl } from 'utils/fhirResourcesHelper'
import { requestAssessmentMedicalConditions } from 'redux/consultation/assessmentMedicalCondition/medicalAssessmentSlice'
import OverflowTypography from 'views/components/consultation/plan/ayurveda/overflowTypography'
import { PatientSummaryTile } from './patientSummaryHistoryTile'

interface MedicalConditionsProps {
  conditionsData: R4.IClinicalImpression[]
}

export const PatientSummaryHistory: React.FC<MedicalConditionsProps> = ({
  conditionsData,
}: MedicalConditionsProps) => {
  const { t } = useTranslation(['common'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const dispatch = useDispatch()
  const medicalHistorySlice = useSelector(
    (state: RootState) => state.medicalAssessmentConditionSlice
  )

  const [fullText, setFullText] = React.useState<string>()
  const [showText, setShowText] = React.useState<boolean>(false)

  const [fullTextForNotes, setFullTextForNotes] = React.useState<string>()
  const [showTextNotes, setShowTextNotes] = React.useState<boolean>(false)

  const [groupVitals, setGroupVitals] = useState<GroupedConditionData[]>([])
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  //   useEffect(() => {
  //     if (conditionsData.length > 0) {
  //       updateConditions(conditionsData)
  //     }
  //   }, [])

  function updateConditions(conditionList: GroupedConditionData[]) {
    const results: GroupedConditionData[] = []
    for (let i = 0; i < conditionList.length; i++) {
      results.push({
        date: conditionList[i].date,
        condition: conditionList[i].condition,
        checked: true,
      })
    }
    setGroupVitals(results)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: GroupedConditionData[] = [...groupVitals]
    values[index].checked = rate
    setGroupVitals(values)
  }
  return (
    <>
      <Box
        flexGrow
        width='100%'
        flexDirection='column'
        display='flex'
        style={{ padding: 0, margin: 0 }}
      >
        <Box
          flexGrow
          width='100%'
          display='flex'
          flexDirection='row'
          onMouseEnter={() => showAddButton(true)}
          onMouseLeave={() => showAddButton(false)}
        >
          <Box display='flex' flexDirection='row' width='100%'>
            <Box py={1}>
              <Typography variant='h6' style={{ fontWeight: 'bolder' }}>
                {' '}
                Provisional Diagnosis
              </Typography>
            </Box>
          </Box>
        </Box>

        {conditionsData.length === 0 && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Typography
              variant='subtitle2'
              style={{
                fontWeight: 400,
              }}
            >
              No data available
            </Typography>
          </Box>
        )}

        {conditionsData.length > 0 && (
          <Grid item style={{ padding: 0, margin: 0 }} xs={12}>
            <Box flexGrow width='100%' display='flex' flexDirection='column'>
              <PatientSummaryTile instructions={conditionsData} />
            </Box>
          </Grid>
        )}
      </Box>

      <NoteDialog
        id='notesTile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
        dialogText='Condition'
      />

      <NoteDialog
        id='notesTile1'
        open={showTextNotes}
        onClose={() => {
          setFullTextForNotes('')
          setShowTextNotes(false)
        }}
        notesData={fullTextForNotes!}
        dialogText='Additional Notes'
      />
    </>
  )
}
