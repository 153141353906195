/* eslint-disable consistent-return */
/* eslint-disable react-hooks/rules-of-hooks */
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import NotificationsIcon from '@material-ui/icons/Notifications'
import SettingsIcon from '@material-ui/icons/Settings'
import { UnitServices } from 'models/units/unitServices'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RootState } from 'redux/rootReducer'
import { useDispatch, useSelector } from 'react-redux'
import UnpublishedIcon from '@mui/icons-material/Unpublished'

import {
  getUnitType,
  isAgent,
  isMedicalServiceProvider,
  isPartnerLab,
  isTherapist,
  isDietician,
  isGre,
  isOrgAdmin,
  isUnitAdmin,
  isWpAdmin,
  isDoctor,
  getCurrentUserUnitDetails,
  isCurrentUnitIsClinic,
  isCurrentUnitIsWellonessCenter,
  getUnitDetailsFromServer,
  getCurrentUserMainOrganizationDetails,
  getOrgDetailsFromServer,
  isAyurvedaDoctor,
} from 'services/userDetailsService'
import { TabDetail } from 'views/components/appBars/AppBarTabs'
import { dispatch } from 'd3'
import { searchUnits } from 'redux/administration/units/unitManagement/unitsListsOfOrganization/unitsOfOrganizationSlice'
import { R4 } from '@ahryman40k/ts-fhir-types'

let commonTabs: TabDetail[] = [
  {
    imgSrc: <SettingsIcon color='primary' />,
    name: 'Settings',
    count: 0,
    position: 'right',
    countDisplayType: 'badge',
    displayCount: false,
    displayName: true,
    id: 'Settings',
  },
]

export function getAppBars(): TabDetail[] {
  const unitsOfOrganizationSlice = useSelector(
    (state: RootState) => state.unitsOfOrganizationSlice.unitServices
  )

  const finalData: string[] = []

  function fetchInvoice() {
    if (
      commonTabs.filter((org: any) => org.name === 'IPD').length === 0 &&
      isTherapist() === false &&
      isCurrentUnitIsClinic()
    ) {
      getUnitDetailsFromServer(getCurrentUserUnitDetails().id ?? '')
        .then((e) => {
          if (e.length > 0) {
            let data: R4.ICoding[] = []
            if (e[0].systemOfMedicine && e[0].systemOfMedicine.length > 0) {
              data = e[0].systemOfMedicine.filter(
                (org: R4.ICoding) => org.code === '1259939000'
              )
            }

            if (isDoctor() || isAyurvedaDoctor()) {
              commonTabs.push({
                imgSrc: (
                  <img
                    src={`${process.env.PUBLIC_URL}/cohort.png`}
                    alt='referral'
                    height='70%'
                    width='70%'
                  />
                ),
                name: 'Cohorts',
                count: 0,
                position: 'center',
                countDisplayType: 'text',
                displayCount: false,
                displayName: true,
                id: 'Cohort',
              })
            }

            if (e[0].ipdService && data.length > 0) {
              //   commonTabs.push({
              //     imgSrc: (
              //       <img
              //         src={`${process.env.PUBLIC_URL}/medicine_new.png`}
              //         alt='ipd'
              //         height='60%'
              //         width='60%'
              //       />
              //     ),
              //     name: 'Order History',
              //     count: 0,
              //     position: 'center',
              //     countDisplayType: 'text',
              //     displayCount: true,
              //     displayName: true,
              //     id: 'Order History',
              //   })
              commonTabs.push({
                imgSrc: (
                  <img src={`${process.env.PUBLIC_URL}/ipd.png`} alt='ipd' />
                ),
                name: 'IPD',
                count: 0,
                position: 'center',
                countDisplayType: 'text',
                displayCount: true,
                displayName: true,
                id: 'IPD',
              })
              //   if (isDoctor() || isAyurvedaDoctor()) {
              //     commonTabs.push({
              //       imgSrc: (
              //         <img
              //           src={`${process.env.PUBLIC_URL}/cohort.png`}
              //           alt='referral'
              //           height='70%'
              //           width='70%'
              //         />
              //       ),
              //       name: 'Cohorts',
              //       count: 0,
              //       position: 'center',
              //       countDisplayType: 'text',
              //       displayCount: false,
              //       displayName: true,
              //       id: 'Cohort',
              //     })
              //   }

              finalData.push('1')
            }
          }
        })
        .catch((e) => {})
    }
  }

  let unitServicesData: boolean = false

  function fetchOrgAdminService(): boolean {
    let data: boolean = false
    if (isOrgAdmin()) {
      getOrgDetailsFromServer(getCurrentUserMainOrganizationDetails().id ?? '')
        .then((e) => {
          if (e.length > 0) {
            console.log(e)
            if (e[0].opdService) {
              const commonTabsNew: TabDetail[] = [
                {
                  imgSrc: (
                    <img src={`${process.env.PUBLIC_URL}/ipd.png`} alt='ipd' />
                  ),
                  name: 'Appointments',
                  position: 'center',
                  countDisplayType: 'text',
                  displayCount: false,
                  displayName: true,
                  id: 'appointments',
                },
                {
                  imgSrc: <SettingsIcon color='primary' />,
                  name: 'Settings',
                  count: 0,
                  position: 'right',
                  countDisplayType: 'badge',
                  displayCount: false,
                  displayName: true,
                  id: 'Settings',
                },
              ]
              commonTabs = commonTabsNew
              data = true
            } else {
              const commonTabsNew: TabDetail[] = [
                {
                  imgSrc: (
                    <img
                      src={`${process.env.PUBLIC_URL}/finance.png`}
                      alt='patients'
                    />
                  ),
                  name: 'Finance',
                  position: 'center',
                  countDisplayType: 'text',
                  displayCount: false,
                  displayName: true,
                  id: 'finance',
                },
                {
                  imgSrc: (
                    <img src={`${process.env.PUBLIC_URL}/ipd.png`} alt='ipd' />
                  ),
                  name: 'Admissions',
                  position: 'center',
                  countDisplayType: 'text',
                  displayCount: false,
                  displayName: true,
                  id: 'admissions',
                },
                {
                  imgSrc: <SettingsIcon color='primary' />,
                  name: 'Settings',
                  count: 0,
                  position: 'right',
                  countDisplayType: 'badge',
                  displayCount: false,
                  displayName: true,
                  id: 'Settings',
                },
              ]
              commonTabs = commonTabsNew
            }
          } else {
            const commonTabsNew: TabDetail[] = [
              {
                imgSrc: (
                  <img
                    src={`${process.env.PUBLIC_URL}/finance.png`}
                    alt='patients'
                  />
                ),
                name: 'Finance',
                position: 'center',
                countDisplayType: 'text',
                displayCount: false,
                displayName: true,
                id: 'finance',
              },
              {
                imgSrc: (
                  <img src={`${process.env.PUBLIC_URL}/ipd.png`} alt='ipd' />
                ),
                name: 'Admissions',
                position: 'center',
                countDisplayType: 'text',
                displayCount: false,
                displayName: true,
                id: 'admissions',
              },
              {
                imgSrc: <SettingsIcon color='primary' />,
                name: 'Settings',
                count: 0,
                position: 'right',
                countDisplayType: 'badge',
                displayCount: false,
                displayName: true,
                id: 'Settings',
              },
            ]
            commonTabs = commonTabsNew
          }
        })
        .catch((e) => false)
    }
    return data
  }
  if (
    (isCurrentUnitIsClinic() || isCurrentUnitIsWellonessCenter()) &&
    process.env.REACT_APP_NAME === 'WelloDR'
  ) {
    const unitServices = fetchInvoice()
  }

  if (isOrgAdmin()) {
    unitServicesData = fetchOrgAdminService()
  }

  //   useEffect(() => {
  //     // i18n.changeLanguage(language ?? '')

  //     fetchInvoice()
  //   }, [])

  const { t, i18n } = useTranslation(['common', 'patient'])
  let unitType: String =
    isPartnerLab() && process.env.REACT_APP_NAME === 'WelloLAB'
      ? 'partner-lab'
      : getUnitType()
  if (process.env.REACT_APP_NAME === 'WelloLAB' && getUnitType() === 'lab') {
    unitType = 'lab'
  } else if (
    process.env.REACT_APP_NAME === 'WelloDR' &&
    getUnitType() === 'clinic'
  ) {
    unitType = 'clinic'
  } else if (
    process.env.REACT_APP_NAME === 'WelloDR' &&
    getUnitType() === 'wellness_center'
  ) {
    unitType = 'wellness_center'
  } else if (getUnitType().length === 0 && (isOrgAdmin() || isWpAdmin())) {
    unitType = 'admin'
  } else {
    commonTabs = [
      {
        imgSrc: '',
        name: 'Unauthorized',
        count: 0,
        position: 'center',
        countDisplayType: 'badge',
        displayCount: false,
        displayName: true,
        id: 'Unauthorized',
      },
    ]
    unitType = ''
  }
  const roleType = isAgent()
  if (roleType === true && process.env.REACT_APP_NAME === 'WelloLAB')
    unitType = 'Agent'
  const roleTypeClinic = isTherapist()
  if (
    roleTypeClinic === true &&
    isCurrentUnitIsWellonessCenter() &&
    process.env.REACT_APP_NAME === 'WelloDR'
  ) {
    unitType = 'Therapist'
  } else if (
    roleTypeClinic === true &&
    isCurrentUnitIsClinic() &&
    process.env.REACT_APP_NAME === 'WelloDR'
  ) {
    unitType = 'Clinic Therapist'
  }

  if (
    isDietician() &&
    isCurrentUnitIsWellonessCenter() &&
    process.env.REACT_APP_NAME === 'WelloDR'
  ) {
    unitType = 'Dietitian'
  } else if (
    isDietician() &&
    isCurrentUnitIsClinic() &&
    process.env.REACT_APP_NAME === 'WelloDR'
  ) {
    unitType = 'Clinic Dietitian'
  }

  if (isDietician()) if (roleType === true) unitType = 'Agent'
  const docrole = isMedicalServiceProvider()
  //   if (docrole && process.env.REACT_APP_NAME === 'WelloDR') unitType = 'clinic'
  if (process.env.REACT_APP_NAME === 'WelloLAB' && docrole === false) {
    unitType = 'lab'
  }

  const res: TabDetail[] = [
    {
      imgSrc: <img src={`${process.env.PUBLIC_URL}/ipd.png`} alt='ipd' />,
      name: 'IPD',
      count: 0,
      position: 'center',
      countDisplayType: 'text',
      displayCount: true,
      displayName: true,
      id: 'IPD',
    },
    {
      imgSrc: (
        <img src={`${process.env.PUBLIC_URL}/dayCare.png`} alt='patients' />
      ),
      name: 'Day Care',
      count: 0,
      position: 'center',
      countDisplayType: 'text',
      displayCount: true,
      displayName: true,
      id: 'OPD',
    },

    {
      imgSrc: <img src={`${process.env.PUBLIC_URL}/opd.png`} alt='patients' />,
      name: 'OPD',
      count: 0,
      position: 'center',
      countDisplayType: 'text',
      displayCount: true,
      displayName: true,
      id: 'OPDC',
    },
    {
      imgSrc: (
        <img src={`${process.env.PUBLIC_URL}/patients.png`} alt='patients' />
      ),
      name: t('labelCommon:patients'),
      count: 0,
      position: 'center',
      countDisplayType: 'text',
      displayCount: true,
      displayName: true,
      id: 'Patients',
    },
    ...commonTabs,
  ]

  if (isOrgAdmin() && unitType === 'admin') {
    return [
      //   {
      //     imgSrc: (
      //       <img
      //         src={`${process.env.PUBLIC_URL}/Organisation.ico`}
      //         alt='ipd'
      //         height='70%'
      //         width='70%'
      //       />
      //     ),
      //     name: 'Organizations',
      //     position: 'center',
      //     countDisplayType: 'text',
      //     displayCount: true,
      //     displayName: true,
      //     id: 'org',
      //   },
      //   {
      //     imgSrc: (
      //       <img
      //         src={`${process.env.PUBLIC_URL}/Clinic.ico`}
      //         alt='ipd'
      //         height='70%'
      //         width='70%'
      //       />
      //     ),
      //     name: 'Clinic',
      //     position: 'center',
      //     countDisplayType: 'text',
      //     displayCount: true,
      //     displayName: true,
      //     id: 'clinic',
      //   },
      //   {
      //     imgSrc: (
      //       <img
      //         src={`${process.env.PUBLIC_URL}/Lab_Icon.ico`}
      //         alt='ipd'
      //         height='70%'
      //         width='70%'
      //       />
      //     ),
      //     name: 'Lab',
      //     position: 'center',
      //     countDisplayType: 'text',
      //     displayCount: true,
      //     displayName: true,
      //     id: 'lab',
      //   },
      //   {
      //     imgSrc: (
      //       <img src={`${process.env.PUBLIC_URL}/finance.png`} alt='patients' />
      //     ),
      //     name: 'Finance',
      //     position: 'center',
      //     countDisplayType: 'text',
      //     displayCount: false,
      //     displayName: true,
      //     id: 'finance',
      //   },
      //   {
      //     imgSrc: <img src={`${process.env.PUBLIC_URL}/ipd.png`} alt='ipd' />,
      //     name: 'Admissions',
      //     position: 'center',
      //     countDisplayType: 'text',
      //     displayCount: false,
      //     displayName: true,
      //     id: 'admissions',
      //   },

      ...commonTabs,
    ]
  }

  if (isWpAdmin() && unitType === 'admin') {
    return [
      //   {
      //     imgSrc: (
      //       <img
      //         src={`${process.env.PUBLIC_URL}/Organisation.ico`}
      //         alt='ipd'
      //         height='70%'
      //         width='70%'
      //       />
      //     ),
      //     name: 'Organizations',
      //     position: 'center',
      //     countDisplayType: 'text',
      //     displayCount: true,
      //     displayName: true,
      //     id: 'org',
      //   },
      //   {
      //     imgSrc: (
      //       <img
      //         src={`${process.env.PUBLIC_URL}/Clinic.ico`}
      //         alt='ipd'
      //         height='70%'
      //         width='70%'
      //       />
      //     ),
      //     name: 'Clinic',
      //     position: 'center',
      //     countDisplayType: 'text',
      //     displayCount: true,
      //     displayName: true,
      //     id: 'clinic',
      //   },
      //   {
      //     imgSrc: (
      //       <img
      //         src={`${process.env.PUBLIC_URL}/Lab_Icon.ico`}
      //         alt='ipd'
      //         height='70%'
      //         width='70%'
      //       />
      //     ),
      //     name: 'Lab',
      //     position: 'center',
      //     countDisplayType: 'text',
      //     displayCount: true,
      //     displayName: true,
      //     id: 'lab',
      //   },
      //   {
      //     imgSrc: (
      //       <img src={`${process.env.PUBLIC_URL}/finance.png`} alt='patients' />
      //     ),
      //     name: 'Finance',
      //     position: 'center',
      //     countDisplayType: 'text',
      //     displayCount: false,
      //     displayName: true,
      //     id: 'finance',
      //   },
      //   {
      //     imgSrc: <img src={`${process.env.PUBLIC_URL}/ipd.png`} alt='ipd' />,
      //     name: 'Admissions',
      //     position: 'center',
      //     countDisplayType: 'text',
      //     displayCount: false,
      //     displayName: true,
      //     id: 'admissions',
      //   },

      {
        imgSrc: (
          <img
            src={`${process.env.PUBLIC_URL}/WelloAdmin_Dashboard.ico`}
            alt='ipd'
            height='70%'
            width='70%'
          />
        ),
        name: 'Dashboard',
        position: 'center',
        countDisplayType: 'text',
        displayCount: false,
        displayName: true,
        id: 'dashboard',
      },

      ...commonTabs,
    ]
  }

  if (isWpAdmin() && process.env.REACT_APP_NAME === 'WelloAdmin') {
    return [
      {
        imgSrc: (
          <img
            src={`${process.env.PUBLIC_URL}/WelloAdmin_Dashboard.ico`}
            alt='ipd'
            height='70%'
            width='70%'
          />
        ),
        name: 'Dashboard',
        position: 'center',
        countDisplayType: 'text',
        displayCount: false,
        displayName: true,
        id: 'dashboard',
      },

      ...commonTabs,
    ]
  }

  switch (unitType) {
    case 'wellness_center': {
      if (isGre() && process.env.REACT_APP_NAME === 'WelloDR') {
        return [
          ...res,

          {
            imgSrc: (
              <img
                src={`${process.env.PUBLIC_URL}/referral.png`}
                alt='referral'
              />
            ),
            name: 'Referrals',
            count: 0,
            position: 'center',
            countDisplayType: 'text',
            displayCount: true,
            displayName: true,
            id: 'Referrals',
          },
          {
            imgSrc: (
              <img
                src={`${process.env.PUBLIC_URL}/pre_appointment.png`}
                alt='patients'
              />
            ),
            name: 'Pre-Appointment',
            count: 0,
            position: 'center',
            countDisplayType: 'text',
            displayCount: true,
            displayName: true,
            id: 'Pre-Appointment',
          },
        ]
      }

      if (isDoctor() && process.env.REACT_APP_NAME === 'WelloDR') {
        return [
          ...res,

          {
            imgSrc: (
              <img
                src={`${process.env.PUBLIC_URL}/referral.png`}
                alt='referral'
              />
            ),
            name: 'Referrals',
            count: 0,
            position: 'center',
            countDisplayType: 'text',
            displayCount: true,
            displayName: true,
            id: 'Referrals',
          },

          {
            imgSrc: (
              <img
                src={`${process.env.PUBLIC_URL}/cohort.png`}
                alt='referral'
                height='70%'
                width='70%'
              />
            ),
            name: 'Cohorts',
            count: 0,
            position: 'center',
            countDisplayType: 'text',
            displayCount: false,
            displayName: true,
            id: 'Cohort',
          },
        ]
      }

      if (isUnitAdmin() && process.env.REACT_APP_NAME === 'WelloDR') {
        return [
          ...res,
          {
            imgSrc: (
              <img
                src={`${process.env.PUBLIC_URL}/finance.png`}
                alt='patients'
              />
            ),
            name: 'Finance',
            position: 'center',
            countDisplayType: 'text',
            displayCount: false,
            displayName: true,
            id: 'finance',
          },
        ]
      }

      return [
        ...res,
        {
          imgSrc: (
            <img
              src={`${process.env.PUBLIC_URL}/referral.png`}
              alt='referral'
            />
          ),
          name: 'Referrals',
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: true,
          displayName: true,
          id: 'Referrals',
        },
      ]
    }

    case 'Clinic Therapist':
      return [
        {
          imgSrc: <img src={`${process.env.PUBLIC_URL}/ipd.png`} alt='ipd' />,
          name: 'IPD',
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: true,
          displayName: true,
          id: 'IPD',
        },

        ...commonTabs,
      ]

    case 'Therapist':
      return [
        {
          imgSrc: <img src={`${process.env.PUBLIC_URL}/ipd.png`} alt='ipd' />,
          name: 'IPD',
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: true,
          displayName: true,
          id: 'IPD',
        },
        {
          imgSrc: (
            <img src={`${process.env.PUBLIC_URL}/dayCare.png`} alt='patients' />
          ),
          name: 'Day Care',
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: true,
          displayName: true,
          id: 'OPD',
        },

        {
          imgSrc: (
            <img src={`${process.env.PUBLIC_URL}/opd.png`} alt='patients' />
          ),
          name: 'OPD',
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: true,
          displayName: true,
          id: 'OPDC',
        },
        ...commonTabs,
      ]

    case 'Clinic Dietitian':
      return [
        {
          imgSrc: <img src={`${process.env.PUBLIC_URL}/ipd.png`} alt='ipd' />,
          name: 'IPD',
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: false,
          displayName: true,
          id: 'IPD',
        },

        ...commonTabs,
      ]

    case 'Dietitian':
      return [
        {
          imgSrc: <img src={`${process.env.PUBLIC_URL}/ipd.png`} alt='ipd' />,
          name: 'IPD',
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: false,
          displayName: true,
          id: 'IPD',
        },
        {
          imgSrc: (
            <img src={`${process.env.PUBLIC_URL}/dayCare.png`} alt='patients' />
          ),
          name: 'Day Care',
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: false,
          displayName: true,
          id: 'OPD',
        },

        ...commonTabs,
      ]

    case 'lab':
      return [
        {
          imgSrc: (
            <img
              src={`${process.env.PUBLIC_URL}/lab_result.png`}
              alt='lab_results'
            />
          ),
          name: t('labelCommon:Lab Orders'),
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: true,
          displayName: true,
        },
        {
          imgSrc: (
            <img
              src={`${process.env.PUBLIC_URL}/patients.png`}
              alt='patients'
            />
          ),

          name: t('labelCommon:patients'),
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: true,
          displayName: true,
        },
        {
          imgSrc: (
            <img
              src={`${process.env.PUBLIC_URL}/referral.png`}
              alt='referral'
            />
          ),
          name: 'Lab Referrals',
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: true,
          displayName: true,
          id: 'lab_referrals',
        },
        ...commonTabs,
      ]

    case 'partner-lab':
      return [
        {
          imgSrc: (
            <img
              src={`${process.env.PUBLIC_URL}/lab_result.png`}
              alt='lab_results'
            />
          ),
          name: t('labelCommon:Lab Orders'),
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: true,
          displayName: true,
        },
        ...commonTabs,
      ]

    case 'clinic':
      return [
        {
          imgSrc: (
            <img
              src={`${process.env.PUBLIC_URL}/Appointment.ico`}
              alt='ipd'
              height='80%'
              width='80%'
            />
          ),
          name: 'Appointment',
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: true,
          displayName: true,
          id: 'Appointment',
        },
        {
          imgSrc: (
            <img
              src={`${process.env.PUBLIC_URL}/medicine_new.png`}
              alt='ipd'
              height='60%'
              width='60%'
            />
          ),
          name: 'Medicine',
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: false,
          displayName: true,
          id: 'Medicine',
        },

        {
          imgSrc: (
            <img
              src={`${process.env.PUBLIC_URL}/Medicine_Cart.ico`}
              alt='ipd'
              height='80%'
              width='80%'
            />
          ),
          name: 'Medicine Orders',
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: true,
          displayName: true,
          id: 'Order History',
        },

        {
          imgSrc: (
            <img
              src={`${process.env.PUBLIC_URL}/patients.png`}
              alt='patients'
            />
          ),
          name: t('labelCommon:patients'),
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: true,
          displayName: true,
          id: 'Patients',
        },
        {
          imgSrc: (
            <img
              src={`${process.env.PUBLIC_URL}/cps.ico`}
              alt='cps'
              height='80%'
              width='80%'
            />
          ),
          name: 'Care Plan',
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: false,
          displayName: true,
          id: 'Care Plan',
        },

        ...commonTabs,
      ]

    case 'Agent':
      return [
        {
          imgSrc: (
            <img
              src={`${process.env.PUBLIC_URL}/lab_result.png`}
              alt='lab_results'
            />
          ),
          name: t('labelCommon:Lab Orders'),
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: true,
          displayName: true,
        },
        ...commonTabs,
      ]

    case 'collection_center':
      return [
        {
          imgSrc: (
            <img
              src={`${process.env.PUBLIC_URL}/lab_result.png`}
              alt='lab_results'
            />
          ),
          name: t('labelCommon:Lab Orders'),
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: true,
          displayName: true,
        },
        {
          imgSrc: (
            <img
              src={`${process.env.PUBLIC_URL}/lab_result.png`}
              alt='lab_results'
            />
          ),
          name: t('labelCommon:patients'),
          count: 0,
          position: 'center',
          countDisplayType: 'text',
          displayCount: true,
          displayName: true,
        },
        ...commonTabs,
      ]
    default:
      return [...commonTabs]
  }
}
