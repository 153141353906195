import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { requestCancellationAppointment } from 'redux/appointments/cancelAppointmentManager/cancelAppointmentManagerSlice'
import { resetRescheduleAppointmentState } from 'redux/appointments/rescheduleAppointmentManager/reschedulingAppointmentManagerSlice'
import { resetSlotSelectionStatus } from 'redux/appointments/slotSelectionHandler/practitionerSlotSearchSlice'
import { RootState } from 'redux/rootReducer'
import { CancelConfirmation } from './cancelAppointmentConfirmation'

interface Props {
  open: boolean
  onClose: () => void
  onAppointmentCreated: (
    createdAppointment: R4.IAppointment | undefined
  ) => void
  currentAppointment: FhirAppointmentDetail
}
export const CancelAppointmentHandler: React.FC<Props> = ({
  open,
  onClose,
  onAppointmentCreated,
  currentAppointment,
}) => {
  const appointmentManagerSlice = useSelector(
    (state: RootState) => state.cancelAppointmentManagerSlice
  )

  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '50%',
    },
  }))

  const classes = useStyles()
  const [note, setNote] = useState('')
  const [checkPaymentInfo, setCheckPaymentInfo] = useState(false)
  const [selectedCancellationReason, setCancelReason] = useState<R4.ICoding>()
  const dispatch = useDispatch()
  useEffect(() => {
    if (appointmentManagerSlice.appointmentRescheduledSuccessfully) {
      if (onAppointmentCreated) {
        onAppointmentCreated(
          appointmentManagerSlice.rescheduledAppointment ?? undefined
        )
      }
    }
  }, [appointmentManagerSlice, onAppointmentCreated])

  function requestForAppointment() {
    if (selectedCancellationReason) {
      dispatch(
        requestCancellationAppointment(
          currentAppointment,
          selectedCancellationReason,
          note
        )
      )
    }
  }
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Cancel Appointment</DialogTitle>
      <DialogContent dividers={true}>
        {currentAppointment.slot && (
          <CancelConfirmation
            selectedServiceType={
              currentAppointment.appointment.serviceType
                ? currentAppointment.appointment.serviceType[0].coding
                  ? currentAppointment.appointment.serviceType[0].coding[0]
                      .code ?? ''
                  : ''
                : ''
            }
            selectedSlot={{
              slot: currentAppointment.slot,
              practitioner: currentAppointment.practitionerDetail.practitioner,
              practitionerRole:
                currentAppointment.practitionerDetail.practitionerRole,
            }}
            selectedPatient={currentAppointment.patient}
            isReschedulingChargeApplicable={true}
            onPatientNoteTextChanged={(val) => {
              setNote(val)
            }}
            patientNoteText={note}
            onCancelReasonChanged={(reason) => {
              setCancelReason(reason)
            }}
            onInformCheckChanged={(check) => {
              setCheckPaymentInfo(check)
            }}
            preSelectedCancelReason={selectedCancellationReason}
            preSelectedInformCheck={checkPaymentInfo}
          />
        )}
      </DialogContent>
      <DialogActions
        style={{ background: useTheme().palette.background.default }}
      >
        <Button
          onClick={() => {
            dispatch(resetRescheduleAppointmentState())
            dispatch(resetSlotSelectionStatus())
            onClose()
          }}
          variant='outlined'
          color='primary'
          disableElevation
          id='cancel'
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            requestForAppointment()
          }}
          variant='contained'
          color='primary'
          disableElevation
          disabled={!(selectedCancellationReason != null)}
          id='cancel_appointment'
        >
          Cancel Appointment
        </Button>
      </DialogActions>
    </Dialog>
  )
}
