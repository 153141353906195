/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import 'react-vis/dist/style.css'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { getAccessToken } from 'utils/authHelpers'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  addObservationDetailsForSys,
  deleteObservationDetails,
  getAddStatusForOCA,
  getObservationResourceForPostForSystemicExam,
  getObservationsOfSpecificCodeInEncounter,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { getVendorPartId } from 'utils/routes_helper'
import { OCARenderer } from 'wello-web-components'

interface CardioVascularExaminationsListProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  isReadOnly?: boolean
}

export const PrakrithiAssessment: React.FC<CardioVascularExaminationsListProps> =
  ({
    fhirAppointmentDetails,
    isReadOnly = false,
  }: CardioVascularExaminationsListProps) => {
    const { t } = useTranslation(['common'])
    const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())
    const [observations, setObservations] = useState<R4.IObservation[]>([])
    const dispatch = useDispatch()
    const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
      initial: true,
    })

    function getObservationDetails() {
      setFetchingStatus({
        requesting: true,
      })

      getObservationsOfSpecificCodeInEncounter({
        encounterId: fhirAppointmentDetails.encounter!.id!,
        patient: fhirAppointmentDetails.patient,
        code: 'C0005886',
      })
        .then((e) => {
          if (e) {
            setObservations(e as R4.IObservation[])
            setFetchingStatus({
              requestError: false,
              requesting: false,
              requestSuccess: true,
            })
          } else {
            setFetchingStatus({
              requestError: false,
            })
          }
        })
        .catch((e) => {
          setFetchingStatus({
            requestError: false,
          })
        })
    }
    useEffect(() => {
      getObservationDetails()
    }, [])

    return (
      <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
        <Box display='flex' flexGrow={1}>
          <Box
            borderRadius={2}
            style={{
              backgroundColor: 'lightGrey',
            }}
            display='flex'
            flexDirection='row'
            width='100%'
            height={40}
            px={1}
          >
            <Box
              justifyContent='flex-start'
              display='flex'
              flexGrow={1}
              alignItems='center'
            >
              <Typography
                variant='subtitle1'
                style={{
                  color: 'black',

                  fontWeight: 'bold',
                }}
              >
                Prakriti
              </Typography>
            </Box>
          </Box>
        </Box>
        {fetchingStatus.requesting && (
          <Box p={1.5}>
            <CircularProgress size={20} color='primary' />
          </Box>
        )}
        {fetchingStatus.requestError && (
          <Typography variant='body1' color='error'>
            {t('common:errorWhileFetchingData')}
          </Typography>
        )}
        {fetchingStatus.requestSuccess && (
          <Box
            sx={{ width: '100%' }}
            display='flex'
            flexDirection='column'
            paddingTop={isReadOnly ? 0.5 : 1.5}
            paddingLeft={isReadOnly ? 0.5 : 1}
          >
            <OCARenderer
              OCAFormProviderBaseURL={`${
                process.env.REACT_APP_CC_WORKFLOW_URL
              }${getVendorPartId()}/ocaForms/forms`}
              OCAFormProviderHeader={{
                Authorization: `Bearer ${getAccessToken()}`,

                'Cache-Control': 'no-cache',
              }}
              formSection='soap'
              formCategory='assessment'
              formName='Deha Prakruti Assessment_OCA'
              isViewOnly={isReadOnly}
              showAdded={getAddStatusForOCA(
                observations,
                'C0005886',
                fhirAppointmentDetails.encounter
                  ? fhirAppointmentDetails.encounter.id!
                  : ''
              )}
              onSubmit={(
                observation: R4.IObservation,
                onSubmitSuccess?: (data: any) => void
              ) => {
                const updatedObservation =
                  getObservationResourceForPostForSystemicExam(
                    fhirAppointmentDetails.patient.id!,
                    fhirAppointmentDetails.encounter!.id!,
                    [],
                    observation
                  )

                addObservationDetailsForSys(updatedObservation).then((e) => {
                  if (e === false) {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  } else if (
                    (e as R4.IObservation).resourceType === 'Observation'
                  ) {
                    if (onSubmitSuccess) {
                      onSubmitSuccess(e)
                    }
                    getObservationDetails()
                    dispatch(
                      showSuccessAlert(
                        'Observation details updated successfully'
                      )
                    )
                  } else {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  }
                })
              }}
              onEdit={(
                observation: R4.IObservation,
                onSubmitSuccess?: (data: any) => void
              ) => {
                const updatedObservation =
                  getObservationResourceForPostForSystemicExam(
                    fhirAppointmentDetails.patient.id!,
                    fhirAppointmentDetails.encounter!.id!,
                    [],
                    observation,
                    false
                  )

                addObservationDetailsForSys(updatedObservation).then((e) => {
                  if (e === false) {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  } else if (
                    (e as R4.IObservation).resourceType === 'Observation'
                  ) {
                    if (onSubmitSuccess) {
                      onSubmitSuccess(e)
                    }
                    getObservationDetails()

                    dispatch(
                      showSuccessAlert(
                        'Observation details updated successfully'
                      )
                    )
                  } else {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  }
                })
              }}
              onDelete={(
                observation: R4.IObservation,
                onSuccess?: ((data: any) => void) | undefined,
                onError?: ((data: any) => void) | undefined
              ) => {
                deleteObservationDetails(observation).then((e) => {
                  if (e) {
                    if (onSuccess) onSuccess({})
                  } else if (onError) onError({})
                })
              }}
              existingObservations={observations}
            />
          </Box>
        )}
      </Box>
    )
  }
