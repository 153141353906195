import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Card, CardContent, Typography } from '@material-ui/core'
import { Room } from '@material-ui/icons'
import * as _ from 'lodash'
import React from 'react'
import { getAddress, getAddressKindByCode } from 'utils/fhirResourcesHelper'

interface Props {
  addresses?: R4.IAddress
  onTap?: () => void
  isMedicine?: boolean
}

export const AddressTile: React.FC<Props> = ({
  addresses,
  onTap,
  isMedicine,
}: Props) => (
  <Card
    elevation={0}
    style={{
      width: '100%',
      margin: 0,
    }}
  >
    <CardContent style={{ padding: 3 }}>
      {addresses && (
        <Box display='flex' flexDirection='column' width='100%'>
          {isMedicine === undefined && (
            <Box display='flex' flexDirection='row' width='100%'>
              <Room fontSize='small' color='primary' />{' '}
              <Typography
                color='primary'
                variant='subtitle1'
                style={{ fontWeight: 'bolder', color: 'black' }}
              >
                {_.capitalize(
                  getAddressKindByCode(addresses?.use?.toString() ?? '')
                )}
              </Typography>
            </Box>
          )}

          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            p={isMedicine ? 0.9 : 1}
          >
            <Typography variant='subtitle2'>
              {getAddress([addresses])}
            </Typography>
          </Box>
        </Box>
      )}
    </CardContent>
  </Card>
)
