import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@material-ui/core'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderCancelConfirmation } from './orderCancelConfirmation'

interface Props {
  open: boolean
  onClose: () => void
  onCancelConfirmed: (cancelReason: R4.ICoding, note: string) => void
  orderDetail: FhirLabOrderDetail
}
export const OrderCancellationHandler: React.FC<Props> = ({
  open,
  onClose,
  onCancelConfirmed,
  orderDetail,
}) => {
  const [note, setNote] = useState('')
  const [checkPaymentInfo, setCheckPaymentInfo] = useState(false)
  const [selectedCancellationReason, setCancelReason] = useState<R4.ICoding>()
  const { t } = useTranslation()
  function requestForAppointment() {
    if (selectedCancellationReason) {
      onCancelConfirmed(selectedCancellationReason, note)
    }
  }
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        {' '}
        {t('labelCommon:cancel_order')}
      </DialogTitle>
      <DialogContent dividers={true}>
        <OrderCancelConfirmation
          orderDetail={orderDetail}
          isReschedulingChargeApplicable={false}
          onPatientNoteTextChanged={(val) => {
            setNote(val)
          }}
          patientNoteText={note}
          onCancelReasonChanged={(reason) => {
            setCancelReason(reason)
          }}
          onInformCheckChanged={(check) => {
            setCheckPaymentInfo(check)
          }}
          preSelectedCancelReason={selectedCancellationReason?.code ?? ''}
          preSelectedInformCheck={checkPaymentInfo}
          id='order_cancel_body'
        />
      </DialogContent>
      <DialogActions
        style={{ background: useTheme().palette.background.default }}
      >
        <Button
          onClick={() => {
            onClose()
          }}
          variant='outlined'
          color='primary'
          disableElevation
          id='cancel'
        >
          {t('labelCommon:cancel')}
        </Button>
        <Button
          onClick={() => {
            requestForAppointment()
          }}
          variant='contained'
          color='primary'
          disableElevation
          disabled={!(selectedCancellationReason != null)}
          id='cancel_order_save'
        >
          {t('labelCommon:continue_cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
