import { R4 } from '@ahryman40k/ts-fhir-types'
import { PlanDefinitionStatusKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { CatalogueData } from 'models/catalogueData'
import { EditCatalogueDetailsModel } from 'models/editCatalogueDataModel'
import { FhirCatalougeDetails } from 'models/fhirCatalougeDetails'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import { getExpandedCatalogueFromBundle } from 'utils/common/catalogueDataConverter'
import {
  addExtensionToExtensionsOfUrl,
  getExtensionValueOfUrl,
  removeExtensionFromExtensions,
} from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { EditCatalogueRowDataProps } from 'views/components/lab/catalougeList/editCatalogueData'
import { searchObservations } from '../observationFinder/observationFinderSlice'
import { LabTestSearchStatus } from './labTestSearchStatus'

const initialState: LabTestSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const labTestSearchSlice = createSlice({
  name: 'labTestSearchSlice',
  initialState,
  reducers: {
    searchingCatalogueDetails(
      state,
      action: PayloadAction<LabTestSearchStatus>
    ) {},

    searchResults(state, action: PayloadAction<LabTestSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.catalogueListView = action.payload.catalogueListView
    },

    noDataFoundForSearch(state, action: PayloadAction<LabTestSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.catalogueListView = action.payload.catalogueListView
    },

    errorWhileSearching(state, action: PayloadAction<LabTestSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.catalogueListView = action.payload.catalogueListView
    },
    resetState(state, action: PayloadAction<LabTestSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.catalogueListView = undefined
    },
  },
})

export const resetCatalogueSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: LabTestSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      catalogueListView: undefined,
    }
    dispatch(labTestSearchSlice.actions.resetState(state))
  }

export const resetCatalogueSearchStatusForAdd =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: LabTestSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      catalogueListView: undefined,
    }
    dispatch(labTestSearchSlice.actions.resetState(state))
  }

export const searchingCatalogueDetailsLabTestForAdd =
  (planDef?: R4.IPlanDefinition, code?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: LabTestSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(labTestSearchSlice.actions.errorWhileSearching(errorSearchPatient))
    try {
      const unitDetails = getCurrentUserUnitReference()!
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _count: 100,
        _total: 'accurate',
        status: 'active',
      }
      //   searchParameters._sort = 'name'
      if (code) searchParameters.identifier = code

      const response: any = await fhirClient.doGetResource(
        `/PlanDefinition?plan-def-organization=Organization/${unitDetails.id}`,
        searchParameters
      )

      //   const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
      //     R4.RTTI_Bundle.decode(response)

      //   const catalogueResponses: R4.IBundle = relatedFhirDecodeRes.right

      if (response.entry) {
        if (response.entry.length > 0) {
          const searchCatalogueResult: LabTestSearchStatus = {
            error: false,
            noResultsAvailable: false,
            resultsAvailable: true,
            searching: false,
            catalogueListView: [],

            totalCount: response.entry.length,
          }
          dispatch(
            labTestSearchSlice.actions.searchResults(searchCatalogueResult)
          )
          return
        }
      }

      if (planDef) {
        dispatch(searchObservations(planDef))
      }

      const noSearchResults: LabTestSearchStatus = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(labTestSearchSlice.actions.noDataFoundForSearch(noSearchResults))
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchCatalogue: LabTestSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        labTestSearchSlice.actions.errorWhileSearching(
          errorWhileSearchCatalogue
        )
      )
    }
  }

export default labTestSearchSlice.reducer
