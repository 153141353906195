import { AxiosResponse } from 'axios'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'

export const getErrorResponse = (
  response: AxiosResponse
): FHIRErrorResponses => {
  const errorResponse: FHIRErrorResponses = {
    data: response.data,
    status: response.status,
  }

  switch (true) {
    case errorResponse.status === 400:
      errorResponse.displayText =
        'Some problem while contacting server . Please try later.'
      break
    case errorResponse.status === 401:
      errorResponse.displayText = 'Looks like you need to login again'
      break
    case errorResponse.status === 403:
      errorResponse.displayText =
        'Looks like you need authorized to use this service'
      break
    case errorResponse.status === 404:
      errorResponse.displayText =
        'Invalid request url. Contact help for more details'
      break
    case errorResponse.status > 404 && errorResponse.status < 500:
      errorResponse.displayText = 'Some problem in server.Contact help'
      break
    case errorResponse.status === 500:
      errorResponse.displayText = 'Some problem in server . Please try later.'
      break
    case errorResponse.status === 502:
      errorResponse.displayText = 'Gateway error. Please try refreshing.'
      break
    case errorResponse.status === 503:
      errorResponse.displayText = 'Service is not available right now.'
      break
    case errorResponse.status === 504:
      errorResponse.displayText = 'Request timed out try again later.'
      break
    case errorResponse.status > 504:
      errorResponse.displayText = 'Some problem in server.Contact help'
      break
    default:
      errorResponse.displayText = 'Some problem in request.Contact help'
  }
  return errorResponse
}
