import { Box } from '@material-ui/core'
import _ from 'lodash'
import { CarePlan } from 'models/carePlan'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import {
  requestDietPlanDetails,
  resetDietSearchState,
} from 'redux/consultation/dietPlan/existingDietPlanSearch/existingDietPlanSearchSlice'
import { getImagingRequestsOfAppointment } from 'redux/consultation/imaging_diagnostics_requests_list/imagingDiagnosticsListSlice'
import { getInstructionsOfAppointment } from 'redux/consultation/instructions_list_slice/instructionsListSlice'
import { getLabRequestsOfAppointment } from 'redux/consultation/lab_diagnostics_requests_list/labDiagnosticsListSlice'
import { getMedicationsOfAppointment } from 'redux/consultation/medicationsListSlice/medicationsListSlice'
import { getWellnessServiceRequestsOfAppointment } from 'redux/consultation/wellness_referrals_list/wellnessReferralsListSlice'
import { fetchFollowUpAppointments } from 'redux/fhirMedicalResources/followUpAppointmentFetcher/followUpAppointmentSearchSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserPractitionerRoleDetails,
  isAyurvedaDoctor,
} from 'services/userDetailsService'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { CreateAppointmentHandler } from 'views/components/appointments/createAppointmentManager'
import { DietPlanManagementOfEncounter } from 'views/components/dietplan/addDietPlan'
import { AddMedicationsHandler } from '../assessment/addMedication'
import { AddWellnessReferrals } from '../assessment/addWellnessReferrals'
import { MedicationsList } from '../assessment/medicationsList'
import { AddCustomInstructions } from './addCustomInstruction'
import { AddImagingDiagnosticsTests } from './addImagingDiagnosticOrders'
import { AddDiagnosticsTests } from './add_diagnostic_orders'
import { AddKriyasForEncounters } from './ayurveda/addKriyasForEncounter'
import { AddAyurvedaMedicationsHandler } from './ayurveda/addMedicationForAyurveda'
import {
  AddPathyaApathya,
  AddPathyaApathyaProps,
} from './ayurveda/addPathyaAndApathya'
import { KriyasListForEncounter } from './ayurveda/kriyasListForEncounter'
import { PathyaApathyaListForEncounter } from './ayurveda/pathyaApathyaList'
import { RecommendationWrapper } from './cdsRecommendations/cds_reccomendation_wrapper'
import { RecommendationWrapperForSub } from './cdsRecommendations/cds_reccomendation_wrapper_new'
import { DietPlanList } from './dietPlanList'
import { FullWidthNextStepsOfConsultation } from './fullWidthnextSteps'
import { HalfWidthNextStepsOfConsultation } from './halfWidthNextSteps'
import { ImagingDiagnosticsList } from './imagingDiagnosticsList'
import { CarePlanInstructionList } from './instructionsList'
import { LabDiagnosticRequestsList } from './labDiagnosticList'
import { NextStepPlan } from './nextStepPlan'
import { NextStepMultipleSubPlan } from './nextStepPlanMultipleSubPlan'
import { PatientFolloWUpAppointments } from './pateintFollowUpAppointments'
import { WeeklyDietPlanList } from './weeklyDietPlanList'
import { WellnessReferralsList } from './wellnessRefferalsList'

interface NextStepsOfConsultationProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
}

export const NextStepsOfConsultation: React.FC<NextStepsOfConsultationProps> =
  ({ fhirAppointmentDetails, split }: NextStepsOfConsultationProps) => {
    const { t } = useTranslation(['common', 'mediction'])
    const [addButton, showAddButton] = useState<boolean>(false)
    const [show, setShow] = useState<boolean>(false)
    const [showCohorts, setShowCohorts] = useState<boolean>(false)
    const plans: CarePlan[] = [
      {
        planName: 'Hypertension Care Plan',
        planDescription: '',
      },
    ]

    const existingWeeklyDietPlanSearchSlice = useSelector(
      (state: RootState) => state.existingWeeklyDietPlanSearchSlice
    )

    const [openAddMedicationDialogue, setOpenAddMedicationDialogue] =
      useState<boolean>(false)
    const [openAddWellReferrals, setOpenAddWellReferrals] =
      useState<boolean>(false)
    const [openDietPlan, setOpenDietPlan] = useState<boolean>(false)
    const [openKriyas, setOpenKriyas] = useState<boolean>(false)
    const [openAddInstruction, setAddInstruction] = useState<boolean>(false)
    const [openAddDiagnosticDetails, setOpenAddDiagnosticDetails] =
      useState<boolean>(false)
    const [
      openAddImagingDiagnosticDetails,
      setOpenAddImagingDiagnosticDetails,
    ] = useState<boolean>(false)
    const dispatch = useDispatch()
    const [openCreateAppointmentPopup, setOpenCreateAppointmentPopup] =
      useState(false)

    const [addPathyaApathyProps, setAddPathyaApathyProps] =
      useState<AddPathyaApathyaProps>({
        encounterReference: {
          reference: `Encounter/${fhirAppointmentDetails.encounter!.id}`,
        },
        patientReference: {
          reference: `Patient/${fhirAppointmentDetails.patient.id}`,
        },
        onClose: () => {},
        onLabDiagnosticsAdded: () => {},
        open: false,
      })

    const [aharaUpdateState, setAharaUpdateState] = useState<string>(
      _.random(0, 10000).toString()
    )
    const [viharaUpdateState, setViharaUpdateState] = useState<string>(
      _.random(0, 10000).toString()
    )
    const [kriyaListState, setKriyaListState] = useState<string>(
      _.random(0, 10000).toString()
    )
    const [ayurvedaMedicationState, setAyurvedaMedicationState] =
      useState<string>(_.random(0, 10000).toString())

    useEffect(() => {
      dispatch(resetDietSearchState())
    }, [dispatch])

    return (
      <ReactVisibilitySensor
        offset={{
          top: 100,
          bottom: 30,
        }}
        onChange={(isVisible) => {
          if (isVisible) {
            dispatch(setSelectedSection({ selectedSection: 'next_steps' }))
          }
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='row' width='100%' px={2}>
            <Box display='flex' flexDirection='column' width='50%'>
              <HalfWidthNextStepsOfConsultation
                fhirAppointmentDetails={fhirAppointmentDetails}
                split={split}
              />
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              width='50%'
              pl={0.5}
              pt={0.5}
            >
              <RecommendationWrapperForSub
                fhirAppointmentDetails={fhirAppointmentDetails}
              />
            </Box>
          </Box>
          <Box display='flex' flexDirection='row' width='100%' px={2}>
            <FullWidthNextStepsOfConsultation
              fhirAppointmentDetails={fhirAppointmentDetails}
              split={split}
            />
          </Box>
        </Box>
      </ReactVisibilitySensor>
    )
  }
