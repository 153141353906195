import { R4 } from '@ahryman40k/ts-fhir-types'
import { PartnerLabTest } from 'models/partnerLabTestPrice'
import {
  getStringValueExtensionsOfUrl,
  getValueRefValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'

export function getExpandedPartnerLabTestFromBundle(
  responseTaskBundle: R4.IBundle,
  locationId: string
): PartnerLabTest[] {
  const convertedPartnerLabTestData: PartnerLabTest[] = []
  const planDefinition: any = {}
  const chargeItemDefinition: any = {}

  if (responseTaskBundle.total) {
    if (responseTaskBundle.total > 0) {
      if (responseTaskBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseTaskBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'PlanDefinition':
                  planDefinition[value.resource.id] =
                    value.resource as R4.IPlanDefinition
                  break
                case 'ChargeItemDefinition':
                  chargeItemDefinition[value.resource.id] =
                    value.resource as R4.IChargeItemDefinition
                  break

                default:
                  break
              }
            }
          }
        })
        for (const key in planDefinition) {
          if (key) {
            const planDefResource: R4.IPlanDefinition = planDefinition[
              key
            ] as R4.IPlanDefinition

            const ext = planDefResource.extension
            const chargeOefExt = getValueRefValueExtensionsOfUrl(
              ext,
              'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
            )

            const locId = getValueRefValueExtensionsOfUrl(
              ext,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/PartnerLabRef'
            )
            if (locId !== undefined) {
              const extValue = locId.split('/')
              if (extValue[1].includes(locationId)) {
                let price: number = 0
                let commission: number = 0

                for (const key1 in chargeItemDefinition) {
                  if (key1) {
                    const charge: R4.IChargeItemDefinition =
                      chargeItemDefinition[key1] as R4.IChargeItemDefinition
                    if (chargeOefExt?.split('/')[1].includes(charge.id ?? '')) {
                      if (charge.propertyGroup) {
                        if (charge.propertyGroup[0].priceComponent) {
                          price =
                            charge.propertyGroup[0].priceComponent[0].amount
                              ?.value ?? 0

                          if (
                            charge.propertyGroup[0].priceComponent.length > 1
                          ) {
                            commission =
                              charge.propertyGroup[0].priceComponent[1]
                                .factor ?? 0
                          }
                        }
                      }
                    }
                  }
                }
                const commissionString = (commission * 100).toString()
                let priceId: string = ''
                const chargeId = getValueRefValueExtensionsOfUrl(
                  ext,
                  'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
                )
                if (chargeId) priceId = chargeId.split('/')[1]
                convertedPartnerLabTestData.push({
                  testName: planDefResource.title ?? '',
                  partnerLabPrice: price,
                  commissionRate: `${commissionString} %`,
                  id: planDefResource.id ?? '',
                  scheduleSummary:
                    getStringValueExtensionsOfUrl(
                      ext,
                      'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ScheduleSummary'
                    ) ?? '',
                  description: '',
                  presetInstructions:
                    getStringValueExtensionsOfUrl(
                      ext,
                      'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/LimitationSummary'
                    ) ?? '',
                  reportDisplayName: planDefResource.name ?? '',
                  chargeItemDefinition: chargeItemDefinition[priceId],
                  planDefinition: planDefResource,
                })
              }
            }
          }
        }
      }
    }
  }
  return convertedPartnerLabTestData
}
