import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Button, CircularProgress, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import _ from 'lodash'
import {} from 'models/chiefComplaintData'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { resetState } from 'redux/umls/otherConditionsSearch/otherConditionsSearchSlice'
import { logger } from 'utils/logger'
import { getValueSetsOfType } from 'utils/valusetsHelpers'

interface LabOfferingSelectorProps {
  onSelectionChanges?: (selectedValues: R4.ICoding) => void
  onDelete?: (selectedValue: R4.ICoding) => void
  doctorListId?: string
  preSelectedComplaints?: R4.ICoding[]
  preSelectAll?: boolean
  disabled: boolean
  selectOther: boolean
  valueSetType: string
  otherOptionCode?: R4.ICoding
}

export const SelectCodingsFromValueSet: React.FC<LabOfferingSelectorProps> = ({
  onSelectionChanges,
  onDelete,
  doctorListId,
  preSelectedComplaints = [],
  preSelectAll = false,
  disabled,
  selectOther,
  otherOptionCode,
  valueSetType,
}: LabOfferingSelectorProps) => {
  const [observations, setObservations] = useState<R4.ICoding[]>([])
  const [addingStatus, setAddingStatus] = useState<NetworkRequestStatus>({
    initial: true,
  })

  function getValueSetsInEncounter(searchString: string) {
    setAddingStatus({
      requesting: true,
    })

    getValueSetsOfType(valueSetType, searchString)
      .then((e) => {
        if (e) {
          const preSelectedCodes = preSelectedComplaints.map(
            (preCode) => preCode.code ?? ''
          )
          setObservations(
            (e as R4.ICoding[]).filter(
              (code) => !preSelectedCodes.includes(code.code ?? '')
            )
          )
          setAddingStatus({
            requestSuccess: true,
          })
        } else {
          setAddingStatus({
            requestError: true,
          })
        }
      })
      .catch((e) => {
        setAddingStatus({
          requestError: true,
        })
      })
  }

  const { t } = useTranslation(['common'])

  const [showOtherText, setShowOtherText] = useState<boolean>(false)
  const [otherText, setOtherText] = useState<string>()
  const [selectedCoding, setSelectedCoding] = useState<R4.ICoding>()

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching surgeryList list</div>
          <pre>{error.message}</pre>
          <Button
            onClick={() => {
              // dispatch(searchConditions(text, selectOther))
            }}
          >
            Try again
          </Button>
        </div>
      )}
    >
      <Box width='100%'>
        <Box width='100%'>
          <Autocomplete
            id='tf_search_tests'
            multiple={false}
            onOpen={() => {}}
            disablePortal={true}
            value={selectedCoding}
            style={{
              borderRadius: '4px',
            }}
            fullWidth
            size='small'
            getOptionSelected={(option, value) => option.code === value.code}
            disabled={disabled || showOtherText}
            getOptionLabel={(option) => _.capitalize(option.display ?? '')}
            options={observations}
            loading={addingStatus.requesting}
            autoComplete
            includeInputInList
            onChange={(e, changedValue, reason, detail) => {
              logger.info(changedValue)
              if (reason === 'remove-option') {
                if (detail) {
                  if (onDelete) onDelete(detail.option)
                }
              } else if (onSelectionChanges) {
                if (
                  otherOptionCode &&
                  changedValue &&
                  changedValue.code === otherOptionCode.code
                ) {
                  setShowOtherText(true)
                } else if (changedValue) {
                  onSelectionChanges(changedValue)
                }
              }
            }}
            ChipProps={{
              style: {
                backgroundColor: kPrimaryLight,
                borderRadius: '4px',
              },
            }}
            renderOption={(option, { inputValue }) => {
              const matches = match(
                _.capitalize(option.display ?? ''),
                inputValue
              )
              const parts = parse(_.capitalize(option.display ?? ''), matches)
              return (
                <div id={`lab_test_opt_${option.code}`}>
                  {parts.map((part, index) => (
                    <span
                      key={part.text}
                      style={{
                        backgroundColor: part.highlight
                          ? kPrimaryLight
                          : undefined,
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              )
            }}
            filterOptions={(x) => x}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('labelCommon:type_atleast_3_chars')}
                variant='outlined'
                onChange={(e) => {
                  if (e.target.value.length > 2) {
                    getValueSetsInEncounter(e.target.value)
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {addingStatus.requesting ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
        {showOtherText && (
          <Box py={1} width='100%' display='flex'>
            <TextField
              placeholder='Other Finding Text'
              variant='outlined'
              size='small'
              value={otherText}
              fullWidth
              onChange={(e) => {
                setOtherText(e.target.value)
              }}
              InputProps={{
                endAdornment: (
                  <>
                    <Button
                      onClick={() => {
                        if (onSelectionChanges)
                          onSelectionChanges({
                            ...otherOptionCode,
                            display: otherText?.trim(),
                          })
                        setOtherText(undefined)
                        setSelectedCoding(undefined)
                      }}
                      variant='contained'
                      color='primary'
                      disabled={
                        otherText === undefined || otherText.trim().length === 0
                      }
                    >
                      Add
                    </Button>
                  </>
                ),
              }}
            />
            <Button
              onClick={() => {
                setOtherText(undefined)
                setShowOtherText(false)
                resetState()
              }}
              variant='contained'
              color='primary'
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    </ErrorBoundary>
  )
}
