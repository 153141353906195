/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/react-in-jsx-scope */
import { Box, TextField } from '@material-ui/core'

import { Autocomplete } from '@material-ui/lab'
import { ClearOutlined } from '@material-ui/icons'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { CohortDataStage, ListItem } from 'models/chartProps'
import React, { useEffect, useState } from 'react'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { getFilterLabel } from 'utils/cohort/cohortUtils'

type VisibleStageSelectProps = {
  label: string
  filterMap: any
  handleStageFilterChange: any
  filters: any
  visibleStages: string[]
}
export const CustomDynamicFilter: React.FC<VisibleStageSelectProps> = ({
  label,
  filterMap,
  handleStageFilterChange,
  filters,
  visibleStages,
}: VisibleStageSelectProps) => {
  console.log(filterMap)
  console.log(visibleStages)
  console.log(filters)

  return (
    <Box width='100%' justifyContent='center' flexDirection='column'>
      <Box width='100%' display='flex' flexDirection='row'>
        {filterMap && visibleStages
          ? visibleStages.map((key: string, i: number) => {
              const listData: ListItem[] | undefined = filterMap[key]
              console.log(listData)

              const finalData: ListItem[] = []
              const newData = filters[key]
              console.log(newData)
              if (listData) {
                for (let di = 0; di < listData.length; di++) {
                  if (newData !== undefined) {
                    for (let k = 0; k < newData.length; k++) {
                      if (newData[k] === listData[di].value) {
                        finalData.push({
                          value: listData[di].value,
                          label: listData[di].label,
                          index: listData[di].index,
                        })
                      }
                    }
                  }
                }
              }

              return listData ? (
                <Box
                  display='flex'
                  flexDirection='column'
                  width='25%'
                  paddingRight={1}
                >
                  <WelloFormItemLabel title={getFilterLabel(key)} />
                  <Autocomplete
                    style={{
                      borderRadius: '4px',
                    }}
                    fullWidth
                    size='small'
                    // id='visibleStages'
                    multiple
                    id={key}
                    role={key}
                    disableClearable
                    getOptionSelected={(option, valData) =>
                      String(option.value) === valData.value
                    }
                    getOptionLabel={(option) => option.label}
                    options={listData}
                    value={finalData}
                    limitTags={3}
                    onChange={(e, changedValue, reason) => {
                      handleStageFilterChange(changedValue, key)

                      // handleInputChangeForOccupation(
                      //   'occupation',
                      //   changedValue.title
                      // )
                    }}
                    autoComplete
                    ChipProps={{
                      deleteIcon: (
                        <ClearOutlined
                          style={{
                            height: '15px',
                            color: 'white',
                          }}
                        />
                      ),
                      style: {
                        backgroundColor: kPrimaryLight,
                        borderRadius: '4px',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder='Select Filters'
                        fullWidth
                        variant='outlined'
                        id='stage_search'
                        size='small'
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                  />
                  {/* <select
                    multiple
                    name={key}
                    onChange={handleStageFilterChange}
                    value={filters[key]}
                  >
                    {value.map((item: ListItem) => (
                      <option value={String(item.value)} key={item.index}>
                        {item.label}
                      </option>
                    ))}
                  </select> */}
                </Box>
              ) : (
                ''
              )
            })
          : ''}
      </Box>
    </Box>
  )
}
