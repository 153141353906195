/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography, Divider, Grid } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { ToxFinalScore } from 'models/fhirLabOrderDetails'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import ContentShimmer from 'react-content-shimmer'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import 'react-vis/dist/style.css'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { isMedicalServiceProvider } from 'services/userDetailsService'
import { getAccessToken } from 'utils/authHelpers'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  addObservationDetailsForSys,
  deleteObservationDetails,
  fetchScore,
  fetchScoreFinal,
  getObservationResourceForPostForSystemicExam,
  getObservationsOfSpecificCategoryInEncounter,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { getVendorPartId } from 'utils/routes_helper'

import { DigestiveTract } from './digestive_tract'
import { ToxScoreEars } from './TOX Score-Ears'
import { ToxScoreEmotions } from './toxScoreEmotions'
import { ToxScoreEnergy } from './toxScoreEnergy'
import { ToxScoreEyes } from './toxScoreEyes'
import { ToxScoreFinal } from './toxScoreFinal'
import { ToxScoreHead } from './toxScoreHead'
import { ToxScoreHeart } from './toxScoreHeart'
import { ToxScoreJointMuscle } from './toxScoreJointMusle'
import { ToxScoreLungs } from './toxScoreLungs'
import { ToxScoreMind } from './toxScoreMind'
import { ToxScoreMouthThroat } from './toxScoreMouth&Threat'
import { ToxScoreNose } from './toxScoreNose'
import { ToxScoreOthers } from './toxScoreOthers'
import { ToxScoreSkin } from './toxScoreSkin'
import { ToxScoreWeight } from './toxScoreWeight'

interface AyurvedaHistoryAndSubjectiveProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  status: string
  split: boolean
}

export const ToxScore: React.FC<AyurvedaHistoryAndSubjectiveProps> = ({
  fhirAppointmentDetails,
  status,
  split,
}: AyurvedaHistoryAndSubjectiveProps) => {
  const { t } = useTranslation(['common'])
  const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())
  const [observations, setObservations] = useState<R4.IObservation[]>([])

  const [total, setTotal] = useState<ToxFinalScore>()
  const [scoreFetchingStatus, setScoreFetchingStatus] =
    useState<NetworkRequestStatus>({
      initial: true,
    })

  function getScoreDetails() {
    setScoreFetchingStatus({ requesting: true })
    setTotal(undefined)
    fetchScoreFinal(fhirAppointmentDetails.patient, 'wp_tox_score', '')
      .then((e) => {
        setTotal(e as ToxFinalScore)
        setScoreFetchingStatus({
          requestSuccess: true,
        })
      })
      .catch((e) => {
        setScoreFetchingStatus({
          requestError: true,
        })
      })
  }

  useEffect(() => {
    getScoreDetails()
  }, [])

  return (
    <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
      <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <DigestiveTract
          fhirAppointmentDetails={fhirAppointmentDetails}
          status=''
          splitView={split}
          onDataAdded={() => {
            getScoreDetails()
          }}
        />
      </Box>
      <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <ToxScoreMind
          fhirAppointmentDetails={fhirAppointmentDetails}
          status=''
          splitView={split}
          onDataAdded={() => {
            getScoreDetails()
          }}
        />
      </Box>
      <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <ToxScoreHeart
          fhirAppointmentDetails={fhirAppointmentDetails}
          status=''
          splitView={split}
          onDataAdded={() => {
            getScoreDetails()
          }}
        />
      </Box>
      <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <ToxScoreHead
          fhirAppointmentDetails={fhirAppointmentDetails}
          status=''
          splitView={split}
          onDataAdded={() => {
            getScoreDetails()
          }}
        />
      </Box>
      <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <ToxScoreJointMuscle
          fhirAppointmentDetails={fhirAppointmentDetails}
          status=''
          splitView={split}
          onDataAdded={() => {
            getScoreDetails()
          }}
        />
      </Box>
      <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <ToxScoreEnergy
          fhirAppointmentDetails={fhirAppointmentDetails}
          status=''
          splitView={split}
          onDataAdded={() => {
            getScoreDetails()
          }}
        />
      </Box>
      <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <ToxScoreLungs
          fhirAppointmentDetails={fhirAppointmentDetails}
          status=''
          splitView={split}
          onDataAdded={() => {
            getScoreDetails()
          }}
        />
      </Box>
      <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <ToxScoreEyes
          fhirAppointmentDetails={fhirAppointmentDetails}
          status=''
          splitView={split}
          onDataAdded={() => {
            getScoreDetails()
          }}
        />
      </Box>
      <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <ToxScoreEmotions
          fhirAppointmentDetails={fhirAppointmentDetails}
          status=''
          splitView={split}
          onDataAdded={() => {
            getScoreDetails()
          }}
        />
      </Box>

      <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <ToxScoreEars
          fhirAppointmentDetails={fhirAppointmentDetails}
          status=''
          splitView={split}
          onDataAdded={() => {
            getScoreDetails()
          }}
        />
      </Box>

      <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <ToxScoreMouthThroat
          fhirAppointmentDetails={fhirAppointmentDetails}
          status=''
          splitView={split}
          onDataAdded={() => {
            getScoreDetails()
          }}
        />
      </Box>
      <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <ToxScoreSkin
          fhirAppointmentDetails={fhirAppointmentDetails}
          status=''
          splitView={split}
          onDataAdded={() => {
            getScoreDetails()
          }}
        />
      </Box>

      <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <ToxScoreNose
          fhirAppointmentDetails={fhirAppointmentDetails}
          status=''
          splitView={split}
          onDataAdded={() => {
            getScoreDetails()
          }}
        />
      </Box>
      <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <ToxScoreWeight
          fhirAppointmentDetails={fhirAppointmentDetails}
          status=''
          splitView={split}
          onDataAdded={() => {
            getScoreDetails()
          }}
        />
      </Box>

      <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <ToxScoreOthers
          fhirAppointmentDetails={fhirAppointmentDetails}
          status=''
          splitView={split}
          onDataAdded={() => {
            getScoreDetails()
          }}
        />
      </Box>
      {total && (total.toxFinalScore.length > 0 || total.toxGrade.length > 0) && (
        <Box display='flex' flexDirection='row' width='100%' flexGrow>
          <ToxScoreFinal
            scoreFinal={total ? total.toxFinalScore : ''}
            grade={total ? total.toxGrade : ''}
          />
        </Box>
      )}
      {/* <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <ToxScoreOthers
          fhirAppointmentDetails={fhirAppointmentDetails}
          status=''
          splitView={split}
          onDataAdded={() => {
            getScoreDetails()
          }}
        />
      </Box> */}
    </Box>
  )
}
