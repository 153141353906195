/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-void */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-misused-promises */

import {
  Button,
  Grid,
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Divider,
  CircularProgress,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import useRazorpay from 'react-razorpay'
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil'
import moment from 'moment'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { useDispatch } from 'react-redux'
import {
  getFirstNameFromHumanName,
  getLastNameFromHumanName,
  getNameFromHumanName,
} from 'utils/fhirResourcesHelper'
import { getCurrentUserPractitionerDetails } from 'services/userDetailsService'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { getVendorPartId } from 'utils/routes_helper'
import _ from 'lodash'
import { updateMedicineOrderCount } from 'redux/MedicineOrderHistory/medicineOrderCount/medicineOrderCountSlice'
import {
  selectedProductIdState,
  viewState,
} from '../../../models/medicine-delivery/product-states'
import { StyledTableCell } from '../styled-components'
import {
  createContact,
  createPaymentRecord,
  createSalesOrder as createSaleOrder,
  isContactExist,
  sendPaymentLinkToPaitent,
  vendorId,
  verifyPayment,
} from '../../../utils/medicineHelper/cart-apis'
import {
  Cart,
  cartDoctorTotalMargin,
  cartTotalAmount,
  cartTotalAmountClinic,
  finalDiscount,
  finalDiscountForClinic,
  finalDiscountForSalesOrder,
} from '../../../models/medicine-delivery/cart/cart-models'
import { CartOrderItemSummary } from './cart-order-item-summary'
import { CartPaymentSummary } from './cart-payment-summary'
import {
  cartCachedState,
  cartState,
  cartOrderForCachedState,
  cartDoctorIdState,
  cartPatientIdState,
  cartPatientObjectState,
  orderForObject,
} from '../../../models/medicine-delivery/cart/cart-states'
import {
  PaymentRecordRequest,
  PaymentRecordResponse,
  VerifyPaymentRequest,
} from '../../../models/medicine-delivery/cart/payment_record'
import { SalesOrderRequest } from '../../../models/medicine-delivery/cart/sales-order-request'
import { ContactRequest } from '../../../models/medicine-delivery/cart/contact-request'
import { CardHeaderSummaryTile } from './cartHeaderSummaryTile'
import { CardHeaderSummaryTilePatient } from './cart-header-summaryForPatient'
import { CartOrderItemSummaryForPatient } from './cart-order-item-summary-patient'

export const CartSummary: React.FC = () => {
  const resetCart = useResetRecoilState(cartCachedState)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [cart, setCart] = useRecoilState<any>(cartState)
  const [cartPatientId, setCartPatientId] = useRecoilState(cartPatientIdState)
  const [cartPatient, setCartPatient] = useRecoilState(cartPatientObjectState)
  const [orderForData, setOrderForData] = useRecoilState(orderForObject)

  const { height } = useWindowDimensions()
  const cartDoctorId = useRecoilValue(cartDoctorIdState)
  //   const orderFor = useRecoilValue<any>(cartOrderForCachedState)
  const [orderFor, setOrderFor] = useRecoilState(cartOrderForCachedState)
  const [selectedProductId, setSelectedProductId] = useRecoilState(
    selectedProductIdState
  )

  const [view, setView] = useRecoilState(viewState)
  const [payment, setPayment] = useState<'doctor' | 'patient'>()
  const [verifyPaymentRequest, setVerifyPaymentRequest] =
    useState<VerifyPaymentRequest>()
  const [Razorpay] = useRazorpay()

  let salesOrderId: string | undefined

  useEffect(() => {
    if (!verifyPaymentRequest) {
      return
    }
    void (async () => {
      // 5) After successfull payment, verify payment with server.

      dispatch(showSuccessAlert('Order Placed Successfully'))
      dispatch(updateMedicineOrderCount(moment().format('YYYY-MM-DD')))

      const saleOrderResponse = await verifyPayment(verifyPaymentRequest)
      if (!saleOrderResponse) {
        setLoading(false)
        dispatch(showErrorAlert('Failed to verify payment'))

        setVerifyPaymentRequest(undefined)
        return
      }
      // window.localStorage.removeItem("wellopathy-cart");
      setLoading(false)
      setCart({ items: [], delivery: 0 } as Cart)
      console.log('Order processed')
      setVerifyPaymentRequest(undefined)
      setSelectedProductId(undefined)
      setView('productDetails')
      setOrderFor('clinic')
      setCartPatient(undefined)
      setCartPatientId(undefined)
      setOrderForData({
        code: 'clinic',
        display: 'Order For Clinic',
        system: '',
      })
      setPayment('doctor')
    })()
  }, [verifyPaymentRequest])

  if (view !== 'cartSummary') {
    return <></>
  }

  if (cartPatientId === undefined && cartDoctorId === undefined) {
    return <>Please set patientId and doctorId in cart-order-for component</>
  }

  if (!cart || cart.items.length === 0) {
    return (
      <Grid container style={{ height: 200 }}>
        <Grid item xs={12}>
          <Typography
            variant='h4'
            style={{ paddingTop: 100, textAlign: 'center' }}
          >
            Cart is empty
          </Typography>
        </Grid>
      </Grid>
    )
  }

  /*= ==================== RazorPay=================== */
  const handlePayment = (order: PaymentRecordResponse) => {
    const options = {
      key: 'rzp_test_etGN5GMblDqHS9', // Enter the Key ID generated from the Dashboard
      amount: order.amount_due.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: order.currency,
      name: 'Wellopathy',
      description: 'Test Transaction',
      // image: "https://example.com/your_logo",
      order_id: order.id, // This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler(response: {
        razorpay_payment_id: string
        razorpay_order_id: string
        razorpay_signature: string
      }) {
        const verifyRequest = {
          rzpOrderId: response.razorpay_order_id,
          rzpPaymentId: response.razorpay_payment_id,
          rzpPaySignId: response.razorpay_signature,
          salesOrderId,
        } as VerifyPaymentRequest
        setVerifyPaymentRequest(verifyRequest)
      },
      prefill: {
        name: '',
        email: 'youremail@example.com',
        contact: '9999999999',
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#3399cc',
      },
    }

    const rzp1 = new Razorpay(options)

    rzp1.on(
      'payment.failed',
      (response: {
        error: {
          code: any
          description: any
          source: any
          step: any
          reason: any
          metadata: { order_id: any; payment_id: any }
        }
      }) => {
        setLoading(false)
        dispatch(showErrorAlert('Failed to verify payment'))
      }
    )

    rzp1.open()
  }
  /*= ==================== end Razorpay ============== */

  const getSalesOrderRequest = (id: string, doctorSalesId: string) => {
    let total = 0
    let discountTotal = 0
    if (orderFor === 'clinic' && payment === 'doctor') {
      total = cartTotalAmountClinic(cart)
      discountTotal = finalDiscountForClinic(cart)
    } else if (orderFor === 'patient' && payment === 'doctor') {
      total = _.round(cartTotalAmount(cart), 2)
      discountTotal = _.round(finalDiscountForSalesOrder(cart), 2)
    } else if (orderFor === 'patient') {
      total = cartTotalAmount(cart)
      discountTotal = _.round(finalDiscountForSalesOrder(cart), 2)
    }

    return {
      zcrm_potential_id: '',
      customer_name:
        orderFor === 'clinic'
          ? getNameFromHumanName(getCurrentUserPractitionerDetails().name ?? [])
          : cartPatient
          ? `${getNameFromHumanName(cartPatient.name ?? [])}`
          : '',
      customer_id: orderFor === 'clinic' ? id : id,
      email: '',
      delivery_date: '',
      company_name: '',
      color_code: '',
      current_sub_status_id: '',
      current_sub_status: 'draft',
      pickup_location_id: '',
      reference_number: doctorSalesId,
      date: new Date().toISOString().split('T')[0],
      shipment_date: '',
      shipment_days: '',
      due_by_days: '',
      due_in_days: '',
      currency_id: '1315841000000000064',
      source: 'Client',
      currency_code: 'INR',
      total,
      bcy_total: total,
      total_invoiced_amount: total,
      created_time: moment().toISOString(),
      last_modified_time: moment().toISOString(),
      is_emailed: false,
      quantity: 0,
      quantity_invoiced: 0,
      quantity_packed: 0,
      quantity_shipped: 0,
      order_status: 'draft',
      invoiced_status: '',
      paid_status: '',
      shipped_status: '',
      status: 'draft',
      is_drop_shipment: false,
      is_backorder: false,
      is_manually_fulfilled: false,
      sales_channel_formatted: 'Direct Sales',
      salesperson_name: '',
      has_attachment: false,
      balance: total,
      delivery_method: '',
      delivery_method_id: '',
      has_discount: true,
      discount_type: 'entity_level',
      discount: discountTotal,
      discount_amount: discountTotal,
      shipping_charge: cart.delivery,
      line_items: cart.items.map((i: any) => ({
        item_id: i.product.sku.id,
        name: i.product.title,
        sku: i.product.sku.sku,
        description: i.product.description,
        rate: i.product.sku.price,
        quantity: i.quantity,
        unit: 'qty',
      })),
    } as SalesOrderRequest
  }

  const createPatientIfRequired = async (patientId: string | undefined) => {
    if (!patientId) {
      return { status: false, message: 'Please provide valid patientId.' }
    }
    const isPatientExists = await isContactExist(patientId)
    if (isPatientExists) {
      return {
        status: true,
        message: 'Patient already exists',
        id: isPatientExists,
      }
    }
    // 1.b

    const contact = {
      notes: cartPatient ? cartPatient.id ?? '' : '',
      contact_name: cartPatient
        ? `${getNameFromHumanName(cartPatient.name ?? [])}`
        : '',
      customer_name: cartPatient
        ? `${getNameFromHumanName(cartPatient.name ?? [])}`
        : '',
      vendor_name: getVendorPartId(),
      company_name: '',
      website: '',
      language_code: 'en',
      language_code_formatted: 'English',
      contact_type: 'customer',
      contact_type_formatted: 'Customer',
      status: 'active',
      customer_sub_type: 'individual',
      source: 'user',
      is_linked_with_zohocrm: false,
      payment_terms: 0,
      payment_terms_label: 'Due on Receipt',
      currency_id: '1315841000000000064',
      twitter: '',
      facebook: '',
      currency_code: 'INR',
      outstanding_receivable_amount: 0,
      outstanding_receivable_amount_bcy: 0,
      outstanding_payable_amount: 0,
      outstanding_payable_amount_bcy: 0,
      unused_credits_receivable_amount: 0,
      unused_credits_receivable_amount_bcy: 0,
      unused_credits_payable_amount: 0,
      unused_credits_payable_amount_bcy: 0,
      first_name: cartPatient
        ? `${getFirstNameFromHumanName(cartPatient.name ?? [])}`
        : '',
      last_name: cartPatient
        ? `${getLastNameFromHumanName(cartPatient.name ?? [])}`
        : '',
      email: '',
      phone: '',
      mobile: '',
      portal_status: 'disabled',
      created_time: moment().toISOString(),
      created_time_formatted: moment().format('DD/MM/YYYY'),
      last_modified_time: moment().toISOString(),
      last_modified_time_formatted: moment().format('DD/MM/YYYY'),
      custom_fields: [],
      custom_field_hash: {},
      ach_supported: false,
      has_attachment: false,
    } as ContactRequest
    const isCreated = await createContact(contact)
    if (isCreated.length === 0) {
      // TODO: toast error
      console.error('Error creating contact. Please try later')
      return {
        status: false,
        message: 'Error creating patient record. Please try later',
        id: '',
      }
    }
    return {
      status: true,
      message: 'Patient created successfully.',
      id: isCreated,
    }
  }

  const createDoctorIfRequired = async (doctorId: string | undefined) => {
    if (!doctorId) {
      return {
        status: false,
        message: 'Please provide valid doctorId.',
        id: '',
      }
    }
    const isDoctorExists = await isContactExist(doctorId)
    if (isDoctorExists.length > 0) {
      return {
        status: true,
        message: 'Please provide valid doctorId.',
        id: isDoctorExists,
      }
    }
    // 1.b

    const contact = {
      notes: doctorId,
      contact_name: `${getNameFromHumanName(
        getCurrentUserPractitionerDetails().name ?? []
      )}`,
      customer_name: getNameFromHumanName(
        getCurrentUserPractitionerDetails().name ?? []
      ),
      vendor_name: getVendorPartId(),
      company_name: '',
      website: '',
      language_code: 'en',
      language_code_formatted: 'English',
      contact_type: 'customer',
      contact_type_formatted: 'Customer',
      status: 'active',
      customer_sub_type: 'individual',
      source: 'user',
      is_linked_with_zohocrm: false,
      payment_terms: 0,
      payment_terms_label: 'Due on Receipt',
      currency_id: '1315841000000000064',
      twitter: '',
      facebook: '',
      currency_code: 'INR',
      outstanding_receivable_amount: 0,
      outstanding_receivable_amount_bcy: 0,
      outstanding_payable_amount: 0,
      outstanding_payable_amount_bcy: 0,
      unused_credits_receivable_amount: 0,
      unused_credits_receivable_amount_bcy: 0,
      unused_credits_payable_amount: 0,
      unused_credits_payable_amount_bcy: 0,
      first_name: getFirstNameFromHumanName(
        getCurrentUserPractitionerDetails().name ?? []
      ),
      last_name: getLastNameFromHumanName(
        getCurrentUserPractitionerDetails().name ?? []
      ),
      email: '',
      phone: '',
      mobile: '',
      portal_status: 'disabled',
      created_time: moment().toISOString(),
      created_time_formatted: moment().format('DD/MM/YYYY'),
      last_modified_time: moment().toISOString(),
      last_modified_time_formatted: moment().format('DD/MM/YYYY'),
      custom_fields: [],
      custom_field_hash: {},
      ach_supported: false,
      has_attachment: false,
    } as ContactRequest
    const isCreated = await createContact(contact)
    if (isCreated.length === 0) {
      // TODO: toast error
      console.error('Error creating contact. Please try later')
      return {
        status: false,
        message: 'Error creating patient record. Please try later',
        id: '',
      }
    }
    return {
      status: true,
      message: 'Patient created successfully.',
      id: isCreated,
    }
  }

  /**
   * Following are the steps:
   * 1) Check if contact exists, for now its hardcoded for id 1542 patient.
   *    a) If Yes, continue,
   *    b) If no, create contact in zoho via product-proxy api.
   * 2) Create sales order
   * 3) Create payment record in server using salesOrderId.  https://dev.apis.wellopathy.com/apis/cc-workflows/inventory/v1/payment/gateway
   * 4) Make razorpay payment
   * 5) After successfull payment, verify payment with server.
   * 6) post callback
   * Sample patientIds: 1227751(used), 48847, 238101, 4239307, 14567, 4852381, 102002, 5462417, 6172740
   * sample doctorIds: 13307(used), 201135(used), 860781, 961100 ,170706
   */
  const onPlaceOrder = async () => {
    setLoading(true)
    let docId = ''
    let docSalesId = ''
    if (orderFor === 'patient' && payment === 'patient') {
      const patientResult = await createPatientIfRequired(cartPatientId)
      if (patientResult.status) {
        docId = patientResult.id ?? ''
      }
      const doctorResult = await createDoctorIfRequired(cartDoctorId)
      if (doctorResult.status) {
        docId = doctorResult.id ?? ''
        docSalesId = doctorResult.id ?? ''
      }
    }

    if (
      (orderFor === 'patient' && payment === 'doctor') ||
      orderFor === 'clinic'
    ) {
      const doctorResult = await createDoctorIfRequired(cartDoctorId)
      if (doctorResult.status) {
        docId = doctorResult.id ?? ''
        docSalesId = doctorResult.id ?? ''
      }
    }

    // console.log(patientResult)

    // 2 Create sales order
    const order = getSalesOrderRequest(docId, docSalesId)
    const orderResponse = await createSaleOrder(order)
    if (!orderResponse) {
      console.error('Unable to create sales order')
      setLoading(false)
      dispatch(showErrorAlert('Unable to create sales order'))
      return
    }

    salesOrderId = orderResponse.salesorder.salesorder_id

    // 3 Create Payment record
    const paymentRecordRequest = {
      patientId: cartPatientId || '',
      vendorLabel: vendorId,
      salesOrderId: orderResponse.salesorder.salesorder_id,
    } as PaymentRecordRequest
    const paymentRecord = await createPaymentRecord(paymentRecordRequest)
    if (!paymentRecord) {
      setLoading(false)
      console.error('Failed to create payment record')
      return
    }
    handlePayment(paymentRecord)
  }

  const onSentPaymentLink = async () => {
    setLoading(true)
    // 1 Create patient and doctor, if required.
    let docId = ''
    let docIdForSales = ''
    if (orderFor === 'patient' && payment === 'patient') {
      const patientResult = await createPatientIfRequired(cartPatientId)
      if (patientResult.status) {
        docId = patientResult.id ?? ''
      }
      const doctorResult = await createDoctorIfRequired(cartDoctorId)
      if (doctorResult.status) {
        docIdForSales = doctorResult.id ?? ''
      }
    }

    if (
      (orderFor === 'patient' && payment === 'doctor') ||
      orderFor === 'clinic'
    ) {
      const doctorResult = await createDoctorIfRequired(cartDoctorId)
      if (doctorResult.status) {
        docId = doctorResult.id ?? ''
        docIdForSales = doctorResult.id ?? ''
      }
    }

    // 2 Create sales order
    const order = getSalesOrderRequest(docId, docIdForSales)
    const orderResponse = await createSaleOrder(order)
    if (!orderResponse) {
      console.error('Unable to create sales order')
      setLoading(false)
      dispatch(showErrorAlert('Unable to create sales order'))
   
      return
    }

    salesOrderId = orderResponse.salesorder.salesorder_id

    // 3 Create Payment record
    const paymentRecordRequest = {
      patientId: cartPatientId || '',
      vendorLabel: vendorId,
      salesOrderId: orderResponse.salesorder.salesorder_id,
    } as PaymentRecordRequest
    const paymentRecord = await createPaymentRecord(paymentRecordRequest)
    if (!paymentRecord) {
      console.error('Failed to create payment record')
      return
    }

    // 4 Send Payment link to patient.
    const paymentResult = await sendPaymentLinkToPaitent(paymentRecordRequest)

    dispatch(showSuccessAlert('Payment Link Sent Successfully'))
    dispatch(updateMedicineOrderCount(moment().format('YYYY-MM-DD')))
    if (!paymentResult) {
      setLoading(false)
      dispatch(showErrorAlert('Unable to send payment link'))

      return
    }

    setCart({ items: [], delivery: 0 } as Cart)
    setLoading(false)
    console.log('Order processed')
    setVerifyPaymentRequest(undefined)
    setSelectedProductId(undefined)
    setView('productDetails')
    setOrderFor('clinic')
    setCartPatient(undefined)
    setCartPatientId(undefined)
    setOrderForData({
      code: 'clinic',
      display: 'Order For Clinic',
      system: '',
    })
    setPayment('doctor')
  }

  return (
    <Box
      width='99%'
      paddingTop={2}
      style={{ backgroundColor: 'white' }}
      px={2}
      height='100%'
    >
      <Box width='100%' display='flex' flexDirection='row'>
        <Box width='65%' display='flex' flexDirection='column'>
          <Box paddingBottom={3} paddingLeft={1.3}>
            <Typography variant='h6' style={{ fontWeight: 'bold' }}>
              Order Summary
            </Typography>
          </Box>
          {orderFor === 'clinic' ? (
            <Box width='95%' paddingLeft={1.3}>
              {' '}
              <CardHeaderSummaryTile />
            </Box>
          ) : (
            <Box width='100%' paddingLeft={1.4}>
              <CardHeaderSummaryTilePatient />
            </Box>
          )}
          <Box minHeight={`${height - 700}px`} maxHeight={`${height - 700}px`}>
            {cart.items.map((i: any, index: number) => (
              <Box key={i.product.title} paddingLeft={0.7}>
                {orderFor === 'clinic' && (
                  <CartOrderItemSummary key={index} item={i} />
                )}
                {orderFor === 'patient' && (
                  <CartOrderItemSummaryForPatient key={index} item={i} />
                )}
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          width='5%'
          display='flex'
          flexDirection='row'
          justifyContent='center'
        >
          <Box
            display='flex'
            justifyContent='flex-start'
            px={1}
            paddingTop={1}
            width='5%'
          >
            <Divider
              orientation='vertical'
              variant='fullWidth'
              flexItem
              style={{
                width: '1px',
              }}
            />
          </Box>
        </Box>
        <Box width='30%' display='flex' flexDirection='column'>
          <Box>
            <Typography variant='h6' style={{ fontWeight: 'bold' }}>
              Payment Summary
            </Typography>
          </Box>
          <Box py={1}>
            <CartPaymentSummary />
          </Box>
        </Box>
      </Box>
      <Box width='100%' display='flex' flexDirection='row'>
        <Box width='65%' display='flex' flexDirection='column'>
          <Box
            display='flex'
            width='100%'
            flexDirection='row'
            justifyContent='flex-end'
            paddingRight={3}
            py={1}
          />
        </Box>
        <Box width='35%' display='flex' flexDirection='column' paddingLeft={7}>
          {orderFor === 'clinic' && (
            <Typography variant='subtitle1'>Choose Payment Method</Typography>
          )}

          {orderFor === 'patient' && cartPatientId && (
            <Typography variant='subtitle1'>Choose Payment Method</Typography>
          )}

          {orderFor === 'clinic' && (
            <FormControl>
              <RadioGroup
                id='add_unit_address_choice_rad_group'
                row
                aria-label='graphType'
                value={payment}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if ((event.target as HTMLInputElement).value === 'doctor')
                    setPayment('doctor')
                  else setPayment('patient')
                }}
              >
                <Box px={1.5} display='flex' flexDirection='column'>
                  <FormControlLabel
                    value='doctor'
                    control={<Radio color='primary' disabled={loading} />}
                    checked={payment === 'doctor'}
                    label={
                      <Typography variant='subtitle2'>Pay Online</Typography>
                    }
                  />
                  <FormControlLabel
                    value='patient'
                    control={<Radio color='primary' />}
                    checked={payment === 'patient'}
                    disabled={true}
                    label={
                      <Typography variant='subtitle2'>On Credit</Typography>
                    }
                  />
                </Box>
              </RadioGroup>
            </FormControl>
          )}

          {cartPatientId && orderFor === 'patient' && (
            <FormControl>
              <RadioGroup
                id='add_unit_address_choice_rad_group'
                row
                aria-label='graphType'
                value={payment}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if ((event.target as HTMLInputElement).value === 'doctor')
                    setPayment('doctor')
                  else setPayment('patient')
                }}
              >
                <Box px={1.5} display='flex' flexDirection='column'>
                  <FormControlLabel
                    value='doctor'
                    control={<Radio color='primary' />}
                    disabled={loading}
                    checked={payment === 'doctor'}
                    label={
                      <Typography variant='subtitle2'>
                        {' '}
                        Pay on behalf of your patient
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value='patient'
                    control={<Radio color='primary' />}
                    checked={payment === 'patient'}
                    disabled={true}
                    label={
                      <Typography variant='subtitle2'>
                        {' '}
                        Send payment link to your patient
                      </Typography>
                    }
                  />
                </Box>
              </RadioGroup>
            </FormControl>
          )}
        </Box>
      </Box>

      <Box
        width='100%'
        display='flex'
        flexDirection='row'
        paddingTop={3}
        paddingBottom={1}
      >
        <Box width='65%' display='flex' flexDirection='column'>
          <Box
            display='flex'
            width='100%'
            flexDirection='row'
            justifyContent='flex-start'
            paddingTop={2}
            paddingLeft={1.1}
          >
            <Box width='50%' display='flex' flexDirection='row'>
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={() => {
                  setView('cart')
                  setSelectedProductId(undefined)
                }}
              >
                Back
              </Button>
            </Box>

            <Box
              display='flex'
              width='50%'
              flexDirection='row'
              justifyContent='flex-end'
              paddingRight={3}
            >
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={() => {
                  setView('productDetails')
                  setSelectedProductId(undefined)
                }}
              >
                Add More Items
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          width='35%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
          paddingTop={2}
          paddingBottom={1}
        >
          {payment === 'patient' && cartPatientId && (
            <Box
              width='100%'
              display='flex'
              flexDirection='row'
              justifyContent='flex-end'
            >
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={() => onSentPaymentLink()}
                disabled={cartPatient === undefined || loading}
              >
                Proceed
              </Button>
              {loading && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                >
                  <CircularProgress size={20} />
                </Box>
              )}
            </Box>
          )}
          {payment === 'doctor' && orderFor === 'clinic' && (
            <Box
              width='100%'
              display='flex'
              flexDirection='row'
              justifyContent='flex-end'
            >
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={() => onPlaceOrder()}
                disabled={loading}
              >
                Proceed
              </Button>
              {loading && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                >
                  <CircularProgress size={20} />
                </Box>
              )}
            </Box>
          )}

          {payment === 'doctor' && orderFor === 'patient' && cartPatientId && (
            <Box
              width='100%'
              display='flex'
              flexDirection='row'
              justifyContent='flex-end'
            >
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={() => onPlaceOrder()}
                disabled={loading}
              >
                Proceed
              </Button>
              {loading && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                >
                  <CircularProgress size={20} />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
