/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-destructuring */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  ContactPointUseKind,
  HumanNameUseKind,
  TaskIntentKind,
  TaskStatusKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { MemberRelation } from 'models/Memberrelation'
import { UnitMemberRelation } from 'models/unitMemberrelation'
import moment from 'moment'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserUnitDetails,
  isOrgAdmin,
} from 'services/userDetailsService'
import { getVendorPartId } from 'utils/routes_helper'
import { getUniqueTempId } from './idHelpers'
import { getOrganizationType } from './organizationHelpers'

export function getTaskObjectForProviderInvitation(
  unitType: 'unit' | 'org',
  email: string,
  phoneNumber: string,
  forReference: R4.IReference,
  requesterRef: R4.IReference,
  requireUnitDetailCompletion: boolean,
  requireOrgDetailCompletion: boolean,
  isAuthenticationRequired?: boolean
): R4.ITask[] {
  const tasks: R4.ITask[] = []
  const mainTaskId: string = getUniqueTempId()
  const groupId: string = getUniqueTempId()
  const task: R4.ITask = {
    id: mainTaskId,
    resourceType: 'Task',
    identifier: [
      { value: unitType === 'unit' ? 'unit-prole-invite' : 'org-prole-invite' },
    ],
    groupIdentifier: {
      value: groupId,
    },
    authoredOn: moment(new Date()).format(),
    priority: 'asap',
    input: [
      {
        type: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
              code: 'authenticator',
            },
          ],
        },
        valueIdentifier: {
          system: 'email',
          value: email,
        },
      },
      {
        type: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
              code: 'authenticator',
            },
          ],
        },
        valueIdentifier: { system: 'phone', value: phoneNumber },
      },
      {
        type: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
              code: 'destination-url',
            },
          ],
        },
        valueUrl: `${
          process.env.REACT_APP_REDIRECT_URL + getVendorPartId()!
        }/login`,
      },
    ],
    owner: forReference,
    status: R4.TaskStatusKind._requested,
    intent: R4.TaskIntentKind._unknown,
    restriction: {
      repetitions: 5,
      period: {
        start: moment(new Date()).format(),
        end: moment(new Date()).add(5, 'd').format(),
      },
    },

    requester: requesterRef,
    code: {
      coding: [
        {
          system: 'http://wellopathy.com/fhir/india/core/CodeSystem/task-code',
          code: 'user-invitation',
        },
      ],
    },
  }
  tasks.push(task)

  const profileCompletionTask: R4.ITask = {
    resourceType: 'Task',
    identifier: [{ value: 'unit-prole-invite' }],
    groupIdentifier: {
      value: groupId,
    },
    priority: 'asap',
    partOf: [
      {
        reference: `${task.resourceType}/${task.id}`,
      },
    ],
    authoredOn: moment(new Date()).format(),
    input: [
      {
        type: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
              code: 'authenticator',
            },
          ],
        },
        valueIdentifier: {
          system: 'email',
          value: email,
        },
      },
      {
        type: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
              code: 'authenticator',
            },
          ],
        },
        valueIdentifier: { system: 'phone', value: phoneNumber },
      },
      {
        type: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
              code: 'destination-url',
            },
          ],
        },
        valueUrl: `${
          process.env.REACT_APP_REDIRECT_URL + getVendorPartId()!
        }/login`,
      },
    ],
    status: R4.TaskStatusKind._requested,
    intent: R4.TaskIntentKind._unknown,
    restriction: {
      repetitions: 5,
      period: {
        start: moment(new Date()).format(),
        end: moment(new Date()).add(5, 'd').format(),
      },
    },
    owner: forReference,

    requester: requesterRef,
    code: {
      coding: [
        {
          system: 'http://wellopathy.com/fhir/india/core/CodeSystem/task-code',
          code: 'profile-completion',
        },
      ],
    },
  }
  tasks.push(profileCompletionTask)

  if (requireOrgDetailCompletion) {
    const orgCompletion: R4.ITask = {
      resourceType: 'Task',
      identifier: [{ value: 'unit-prole-invite' }],
      groupIdentifier: {
        value: groupId,
      },
      partOf: [
        {
          reference: `${task.resourceType}/${task.id}`,
        },
      ],
      priority: 'asap',
      authoredOn: moment(new Date()).format(),
      input: [
        {
          type: {
            coding: [
              {
                system:
                  'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
                code: 'authenticator',
              },
            ],
          },
          valueIdentifier: {
            system: 'email',
            value: email,
          },
        },
        {
          type: {
            coding: [
              {
                system:
                  'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
                code: 'authenticator',
              },
            ],
          },
          valueIdentifier: { system: 'phone', value: phoneNumber },
        },
      ],
      status: R4.TaskStatusKind._requested,
      intent: R4.TaskIntentKind._unknown,
      restriction: {
        repetitions: 5,
        period: {
          start: moment(new Date()).format(),
          end: moment(new Date()).add(5, 'd').format(),
        },
      },
      owner: forReference,

      requester: requesterRef,
      code: {
        coding: [
          {
            system:
              'http://wellopathy.com/fhir/india/core/CodeSystem/task-code',
            code: 'org-profile-completion',
          },
        ],
      },
    }
    tasks.push(orgCompletion)
  }

  if (requireUnitDetailCompletion) {
    const unitCompletion: R4.ITask = {
      resourceType: 'Task',
      identifier: [{ value: 'unit-prole-invite' }],
      groupIdentifier: {
        value: groupId,
      },
      priority: 'asap',
      partOf: [
        {
          reference: `${task.resourceType}/${task.id}`,
        },
      ],
      authoredOn: moment(new Date()).format(),
      input: [
        {
          type: {
            coding: [
              {
                system:
                  'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
                code: 'authenticator',
              },
            ],
          },
          valueIdentifier: {
            system: 'email',
            value: email,
          },
        },
        {
          type: {
            coding: [
              {
                system:
                  'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
                code: 'authenticator',
              },
            ],
          },
          valueIdentifier: { system: 'phone', value: phoneNumber },
        },
      ],
      status: R4.TaskStatusKind._requested,
      intent: R4.TaskIntentKind._unknown,
      restriction: {
        repetitions: 5,
        period: {
          start: moment(new Date()).format(),
          end: moment(new Date()).add(5, 'd').format(),
        },
      },
      owner: forReference,

      requester: requesterRef,
      code: {
        coding: [
          {
            system:
              'http://wellopathy.com/fhir/india/core/CodeSystem/task-code',
            code: 'unit-profile-completion',
          },
        ],
      },
    }
    tasks.push(unitCompletion)
  }

  return tasks
}

export function getRandomColor() {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export function getPractitionerObject(
  practId: string,
  name: string,
  email: string,
  phoneNumber: string,
  mainOrganization: R4.IOrganization,

  preFix?: string
) {
  const humanName: R4.IHumanName = {}

  let firstName: string | undefined = name
  let lastName: string | undefined
  let finalName: string = ''
  let preFixData: string = ''
  if (name.includes(' ')) {
    firstName = name.split(' ').slice(0, -1).join(' ')
    lastName = name.split(' ').slice(1).join(' ')
  }
  if (name.includes(' ')) {
    if (
      name.includes('Dr.') ||
      name.includes('Dr') ||
      name.trim().includes('Dr,')
    ) {
      if (name.includes('Dr.')) {
        finalName = name.replace('Dr. ', '').trim()
        preFixData = 'Dr'
      } else if (name.includes('Dr,')) {
        finalName = name.replace('Dr,', '').trim()
        preFixData = 'Dr,'
      } else if (name.includes('Dr')) {
        finalName = name.replace('Dr ', '').trim()
        preFixData = 'Dr'
      }
    } else if (
      name.toLowerCase().includes('vd.') ||
      name.toLowerCase().includes('vd') ||
      name.toLowerCase().trim().includes('vd,')
    ) {
      if (name.includes('Vd.')) {
        finalName = name.replace('Vd. ', '').trim()
        preFixData = 'Vd.'
      } else if (name.includes('Vd,')) {
        finalName = name.replace('Vd,', '').trim()
        preFixData = 'Vd,'
      } else if (name.includes('Vd')) {
        finalName = name.replace('Vd ', '').trim()
        preFixData = 'Vd'
      }
    } else {
      finalName = name.trim()
    }
    const nameData = finalName.trim().split(' ')
    if (nameData.length > 2) {
      humanName.given = [nameData[0]]
      humanName.given.push(nameData[1])
      humanName.family = nameData[2]
    }

    if (nameData.length === 2) {
      humanName.given = [nameData[0]]
      humanName.family = nameData[1]
    }
    if (nameData.length === 1) {
      humanName.given = [nameData[0]]
    }
  } else {
    humanName.given = [name]
  }
  humanName.prefix = preFixData.length > 0 ? [preFixData] : undefined

  const practitioner: R4.IPractitioner = {
    resourceType: 'Practitioner',
    active: true,
    id: practId,
    identifier: [
      {
        system: 'email',
        value: email,
      },
      {
        system: 'phone',
        value: phoneNumber,
      },
    ],
    extension: [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PractitionerManagingOrganization',
        valueReference: {
          id: mainOrganization.id,
          type: mainOrganization.resourceType,
          display: mainOrganization.name,
          reference: `${mainOrganization.resourceType}/${mainOrganization.id}`,
        },
      },
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/AssignedColor',
        valueString: getRandomColor(),
      },
    ],

    telecom: [
      { rank: 1, system: R4.ContactPointSystemKind._phone, value: phoneNumber },
      { rank: 2, system: R4.ContactPointSystemKind._email, value: email },
      { rank: 2, system: R4.ContactPointSystemKind._sms, value: phoneNumber },
    ],
  }
  practitioner.name = [humanName]

  return practitioner
}

export function getPatientResourceObject(
  patientId: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string
) {
  const humanName: R4.IHumanName = {}

  humanName.given = [firstName]
  humanName.family = lastName

  const patient: R4.IPatient = {
    resourceType: 'Patient',
    active: true,
    id: patientId,

    telecom: [
      { rank: 1, system: R4.ContactPointSystemKind._phone, value: phoneNumber },
      { rank: 2, system: R4.ContactPointSystemKind._email, value: email },
    ],
  }
  patient.name = [humanName]

  return patient
}

export function getPatientPreAppointmentResourceObject(
  patientId: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string
) {
  const humanName: R4.IHumanName = {}

  humanName.given = [firstName]
  humanName.family = lastName
  humanName.use = HumanNameUseKind._official

  const patient: R4.IPatient = {
    resourceType: 'Patient',
    id: patientId,

    telecom: [
      {
        use: ContactPointUseKind._mobile,
        system: R4.ContactPointSystemKind._phone,
        rank: 1,
        value: phoneNumber,
      },
    ],
  }
  patient.name = [humanName]

  return patient
}

export function getPractitionerRoleObject(
  practId: string,
  practRoleId: string,
  role: R4.ICoding[],
  name: string,
  email: string,
  phoneNumber: string,
  unitOrganization: R4.IOrganization
) {
  const practitioner: R4.IPractitionerRole = {
    resourceType: 'PractitionerRole',
    id: practRoleId,
    active: true,
    identifier: [
      {
        system: 'email',
        value: email,
      },
      {
        system: 'phone',
        value: phoneNumber,
      },
    ],
    code: role.map(
      (e) =>
        ({
          coding: e,
        } as R4.ICodeableConcept)
    ),
    practitioner: {
      type: 'Practitioner',
      display: name,
      reference: `Practitioner/${practId}`,
    },
    organization: {
      id: unitOrganization.id,
      type: unitOrganization.resourceType,
      display: unitOrganization.name,
      reference: `${unitOrganization.resourceType}/${unitOrganization.id}`,
    },

    telecom: [
      { rank: 1, system: R4.ContactPointSystemKind._phone, value: phoneNumber },
      { rank: 2, system: R4.ContactPointSystemKind._email, value: email },
    ],
  }

  /* Removed name with location Search for invite not getting sent */

  if (
    getOrganizationType(unitOrganization) === 'vendor-unit' &&
    unitOrganization.meta &&
    unitOrganization.meta.tag &&
    unitOrganization.meta.tag.length > 0
  ) {
    practitioner.location = [
      {
        reference: `Location?near:missing=false&organization=${
          unitOrganization.resourceType
        }/${unitOrganization.id}&name=${encodeURI(
          unitOrganization.name!.trim()
        )}`,
      },
    ]
  } else if (
    getOrganizationType(unitOrganization) === 'vendor-unit' &&
    unitOrganization.meta &&
    unitOrganization.meta.tag === undefined
  ) {
    practitioner.location = [
      {
        reference: `Location?near:missing=true&organization=${
          unitOrganization.resourceType
        }/${unitOrganization.id}&name=${encodeURI(
          unitOrganization.name!.trim()
        )}`,
      },
    ]
  }

  return practitioner
}

export function getInputIdentifierValueBySystemOfInvitation(
  task: R4.ITask,
  system: string
): string | undefined {
  if (task && task.input && task.input.length > 0) {
    const emailInput = task.input.find(
      (e) => e.valueIdentifier?.system === system
    )
    if (emailInput) {
      return emailInput.valueIdentifier?.value
    }
  }

  return undefined
}

export function getTaskObjectForConsumerInvitation(
  email: string,
  phoneNumber: string,
  requesterRef: R4.IReference,
  patientResourceId: string,
  isProfileCompletionRequired?: boolean
): R4.ITask[] {
  const tasks: R4.ITask[] = []
  const mainTaskId: string = getUniqueTempId()
  const groupId: string = getUniqueTempId()
  const ownerRef: R4.IReference = {
    id: patientResourceId,
    reference: `Patient/${patientResourceId}`,
  }

  const task: R4.ITask = {
    id: mainTaskId,
    resourceType: 'Task',
    identifier: [
      {
        value: 'wp-pat-invite',
      },
    ],
    groupIdentifier: {
      value: groupId,
    },
    priority: 'asap',
    authoredOn: moment(new Date()).format(),
    input: [
      {
        type: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
              code: 'authenticator',
            },
          ],
        },

        valueIdentifier: {
          system: 'email',
          value: email,
        },
      },
      {
        type: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
              code: 'authenticator',
            },
          ],
        },
        valueIdentifier: {
          system: 'phone',
          value: phoneNumber,
        },
      },
      {
        type: {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
              code: 'destination-url',
            },
          ],
        },
        valueUrl: 'https://dev.consumers.wellopathy.com/',
      },
    ],
    status: TaskStatusKind._requested,
    intent: TaskIntentKind._unknown,
    restriction: {
      repetitions: 5,
      period: {
        start: moment(new Date()).format(),
        end: moment(new Date()).add(5, 'd').format(),
      },
    },
    owner: ownerRef,

    requester: requesterRef,
    code: {
      coding: [
        {
          system: 'http://wellopathy.com/fhir/india/core/CodeSystem/task-code',
          code: 'user-invitation',
        },
      ],
    },
  }
  tasks.push(task)

  if (isProfileCompletionRequired) {
    const profileCompletionTask: R4.ITask = {
      resourceType: 'Task',
      groupIdentifier: {
        value: groupId,
      },
      priority: 'asap',
      partOf: [
        {
          reference: `${task.resourceType}/${task.id}`,
        },
      ],
      authoredOn: moment(new Date()).format(),
      input: [
        {
          type: {
            coding: [
              {
                system:
                  'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
                code: 'authenticator',
              },
            ],
          },
          valueIdentifier: {
            system: 'email',
            value: email,
          },
        },
        {
          type: {
            coding: [
              {
                system:
                  'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
                code: 'authenticator',
              },
            ],
          },
          valueIdentifier: { system: 'phone', value: phoneNumber },
        },
        {
          type: {
            coding: [
              {
                system:
                  'http://wellopathy.com/fhir/india/core/CodeSystem/task-data',
                code: 'destination-url',
              },
            ],
          },
          valueUrl: process.env.REACT_APP_WELLOHUM_URL,
        },
      ],
      status: R4.TaskStatusKind._requested,
      intent: R4.TaskIntentKind._unknown,
      restriction: {
        repetitions: 5,
        period: {
          start: moment(new Date()).format(),
          end: moment(new Date()).add(5, 'd').format(),
        },
      },
      owner: ownerRef,

      requester: requesterRef,
      code: {
        coding: [
          {
            system:
              'http://wellopathy.com/fhir/india/core/CodeSystem/task-code',
            code: 'profile-completion',
          },
        ],
      },
    }
    tasks.push(profileCompletionTask)
  }

  return tasks
}

export async function getPractitionerRoleOfPractitionerForSpecificUnit(
  unitId: string,
  practitionerId: string
): Promise<R4.IPractitionerRole | undefined> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    practitioner: `Practitioner/${practitionerId}`,
    organization: `Organization/${unitId}`,
    active: 'true',
  }
  const response: any = await fhirClient.doGetResource(
    '/PractitionerRole',
    searchParameters
  )
  if (response.type === 'FHIRErrorResponses') {
    return undefined
  }
  const bundleRes: R4.IBundle = response as R4.IBundle
  if (bundleRes.entry && bundleRes.entry.length > 0) {
    const res = bundleRes.entry[0].resource as R4.IPractitionerRole
    if (res) {
      return res
    }
  }

  return undefined
}

export interface UnitMemberDetailsCombined {
  id: string
  unitOrganization: R4.IOrganization
  roles: R4.ICoding[]
  practitionerName: string
  practitionerEmail: string
  practitionerPhoneNumber: string
  existingUserPractitionerId?: string
  isSelf?: boolean
}

export function getCombinedRoles(unitRelations: UnitMemberRelation[]) {
  const returnValue: UnitMemberDetailsCombined[] = []

  unitRelations.forEach((e) => {
    const index = returnValue.findIndex((originalVal) => {
      if (originalVal.unitOrganization?.id === e.unitOrganization?.id) {
        if (e.existingPractitionerId && e.existingPractitionerId.length > 0) {
          if (
            e.existingPractitionerId === originalVal.existingUserPractitionerId
          ) {
            return true
          }
        }
        if (
          originalVal.practitionerEmail.trim().toLowerCase() ===
            e.practitionerEmail.trim().toLowerCase() ||
          originalVal.practitionerPhoneNumber.trim() ===
            e.practitionerPhoneNumber.trim()
        ) {
          return true
        }
      }
      return false
    })
    if (index > -1) {
      returnValue[index].roles.push(e.roleType!)
    } else {
      returnValue.push({
        id: e.id,
        practitionerEmail: e.practitionerEmail,
        practitionerName: e.practitionerName,
        practitionerPhoneNumber: e.practitionerPhoneNumber,
        roles: [e.roleType!],
        unitOrganization: e.unitOrganization,
        existingUserPractitionerId: e.existingPractitionerId,
        isSelf: e.isSelf,
      })
    }
  })

  return returnValue
}

export function getCombinedRolesForUnit(unitRelations: MemberRelation[]) {
  const returnValue: UnitMemberDetailsCombined[] = []

  const currentOrganization: R4.IOrganization = isOrgAdmin()
    ? getCurrentUserMainOrganizationDetails()
    : getCurrentUserUnitDetails()
  unitRelations.forEach((e) => {
    const index = returnValue.findIndex((originalVal) => {
      if (originalVal.unitOrganization?.id === currentOrganization?.id) {
        if (e.existingPractitionerId && e.existingPractitionerId.length > 0) {
          if (
            e.existingPractitionerId === originalVal.existingUserPractitionerId
          ) {
            return true
          }
        }
        if (
          originalVal.practitionerEmail.trim() === e.practitionerEmail.trim() ||
          originalVal.practitionerPhoneNumber.trim() ===
            e.practitionerPhoneNumber.trim()
        ) {
          return true
        }
      }
      return false
    })
    if (index > -1) {
      returnValue[index].roles.push(e.roleType!)
    } else {
      returnValue.push({
        id: e.id,
        practitionerEmail: e.practitionerEmail,
        practitionerName: e.practitionerName,
        practitionerPhoneNumber: e.practitionerPhoneNumber,
        roles: [e.roleType!],
        unitOrganization: currentOrganization,
        existingUserPractitionerId: e.existingPractitionerId,
        isSelf: e.isSelf,
      })
    }
  })

  return returnValue
}
