import { Box, MenuItem, TextField, TextFieldProps } from '@material-ui/core'
import { SadhkaResponse } from 'models/sadkahResponse'
import React, { useState } from 'react'
import { WelloFormItemLabel } from 'wello-web-components'

interface WelloSelectCodingProps {
  id: string
  title: string
  textFieldProps: TextFieldProps
  availableCodings: SadhkaResponse[]
  onChanged: (selectedCoding: SadhkaResponse | undefined) => void
  preSelectedCoding?: SadhkaResponse
  displayDoneLabel?: boolean
  type: string
  selectedColumn: boolean
}

export const WelloSelectFHIRSadhka: React.FunctionComponent<WelloSelectCodingProps> =
  ({
    id,
    title,
    textFieldProps,
    availableCodings,
    onChanged,
    preSelectedCoding,
    displayDoneLabel,
    type,
    selectedColumn,
  }: WelloSelectCodingProps) => {
    const options: SadhkaResponse[] = [
      { serviceId: '', patientName: 'Select ....' },
      ...availableCodings,
    ]
    const [item, setItem] = useState<SadhkaResponse>(
      preSelectedCoding ?? { serviceId: '', patientName: 'Select Test Name' }
    )
    // if (item.code === 'processing_samples') selectedColumn?.push('rejected')
    return (
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <WelloFormItemLabel title={title} displayDoneLabel={displayDoneLabel} />
        <Box>
          <TextField
            id={`${id}text_search`}
            variant='outlined'
            fullWidth
            size='small'
            select
            SelectProps={{
              displayEmpty: true,
            }}
            value={item?.serviceId}
            // label={item?.code === '' ? 'Select' : ''}
            onChange={(val) => {
              const selectedItem: SadhkaResponse | undefined =
                availableCodings.find(
                  (tests) => tests.serviceId === val.target.value
                )
              if (selectedItem) {
                setItem(selectedItem)
                onChanged(selectedItem)
              }
            }}
            {...textFieldProps}
          >
            {options.map((option) => (
              <MenuItem
                id={option.serviceId}
                key={option.serviceId}
                value={option.serviceId}
                selected={item?.serviceId === option.serviceId}
                disabled={selectedColumn}
              >
                {option.patientName}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
    )
  }
