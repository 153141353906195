import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { resetCancelState } from 'redux/ipd/cancelTherapy/cancelTherapySlice'
import {
  deleteTherapy,
  resetDeleteState,
} from 'redux/ipd/deleteTherapy/deleteTherapySlice'
import { resetProdcedureState } from 'redux/ipd/procedure/addProcedureIpdSlice'
import { RootState } from 'redux/rootReducer'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'
import { PatientTile } from 'views/components/patients/pateintTile'
import { WelloFormItemLabel } from 'wello-web-components'
import { TherapyDataDisplay } from './therapyDataFile'
// import { RestrictionsOfSelectedOfferings } from '../common/restriction_detail'
// import { AgentSlotForOrder } from './agent_slot_selector'
// import { LabOfferingSelector } from './labTestSearch'
// import { PatientAddressSelector } from './patient_address_selector'

interface Props {
  open: boolean
  onClose: () => void
  onLabOrderCreated: () => void
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  treatmentPlanData?: TreatmentPlanData
}
export const DeleteTherapy: React.FC<Props> = ({
  open,
  onClose,
  onLabOrderCreated,
  fhirAppointmentDetails,
  treatmentPlanData,
}) => {
  const addProcedureDetailsSlice = useSelector(
    (state: RootState) => state.addProcedureDetailsSlice
  )

  const deleteTreatmentSlice = useSelector(
    (state: RootState) => state.deleteTreatmentSlice
  )

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  useEffect(() => {
    if (deleteTreatmentSlice.updateSuccessful) {
      dispatch(resetDeleteState())
      onLabOrderCreated()
    }
  }, [deleteTreatmentSlice, onLabOrderCreated])

  // eslint-disable-next-line consistent-return
  function isEnableCreateButton(): boolean {
    return true
  }

  function resetForm() {
    setShowDeleteConfirmation({
      open: false,
    })
    dispatch(resetCancelState())
    dispatch(resetProdcedureState())
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetForm()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Delete Therapy</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
                resetForm()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='column'>
            <Box display='flex' flexDirection='column' width='100%'>
              <WelloFormItemLabel title='Patient' />
              <PatientTile patient={fhirAppointmentDetails.patient} />
            </Box>

            {treatmentPlanData && (
              <Box display='flex' flexDirection='column' width='100%'>
                <TherapyDataDisplay
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  treatmentPlanData={treatmentPlanData}
                />
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {addProcedureDetailsSlice.adding && <CircularProgress size={30} />}
        <Button
          onClick={() => {
            onClose()
            resetForm()
          }}
          id='cancel'
          variant='outlined'
          style={{ color: ' #979797;' }}
          disableElevation
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setShowDeleteConfirmation({
              open: true,
              onContinueActionClicked: () => {
                setShowDeleteConfirmation({
                  open: false,
                })
                if (treatmentPlanData && treatmentPlanData.treatmentPlan) {
                  dispatch(
                    deleteTherapy(
                      treatmentPlanData.treatmentPlan,
                      fhirAppointmentDetails
                    )
                  )
                }
              },
              onCancelActionClicked: () => {
                setShowDeleteConfirmation({
                  open: false,
                })
              },
              displayText: 'Are you sure you want to delete this record?',
              continueActionButtonText: 'Delete',
              cancelActionButtonText: 'Cancel',
            })
          }}
          id='deleteTherapy'
          variant='contained'
          color='primary'
          disableElevation
        >
          Delete
        </Button>
        {showDeleteConfirmation.open && (
          <ConfirmationDialog {...showDeleteConfirmation} id='delete_confirm' />
        )}
      </DialogActions>
    </Dialog>
  )
}
