import { EventApi } from '@fullcalendar/react'
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { removeScheduleDetails } from 'redux/schedule/deleteSchedule/removeSchedule'
import { requestForSlots } from 'redux/schedule/deleteSchedule/searchSlotByScudleId'
import { getDeleteBundle } from 'utils/fhirResoureHelpers/fhirScheduleDeleteHelper'
import cancelIcon from '../../../assets/icons/appointment_cancel_icon.svg'
import rescheduleIcon from '../../../assets/icons/appointment_reschedule_icon.svg'
import { ConfirmationDialog } from '../common/confirmationDialog'
import { EditScheduleTile } from './EditScheduleTile'

interface AppointmentTileProps {
  scheduleDetail: EventApi
  date: Date
  id: string
  selectedDoctor: PractitionerWithRole
  dateData: Date
  selectedRepeat: string
  personName: string
  onCancelClicked?: (id: any, status: boolean) => void
  onEditClicked?: (
    type: any,
    selectedDate: any,
    eventDate: any,
    scheduleData: any,
    occurrence: any,
    scheduleDate: any,
    repeat: boolean,
    repeatData: any,
    weekData: any,
    repeatLabel: string,
    customLabel: string
  ) => void
}

export const ScheduleTile: React.FC<AppointmentTileProps> = ({
  scheduleDetail,
  date,
  id,
  selectedDoctor,
  dateData,
  onCancelClicked,
  selectedRepeat,
  onEditClicked,
  personName,
}: AppointmentTileProps) => {
  const removeScheduleDetailsSlice = useSelector(
    (state: RootState) => state.removeScheduleSlice
  )
  const searchSlots = useSelector(
    (state: RootState) => state.SearchSlotsByScheduleSlice
  )

  const dispatch = useDispatch()
  const primColor: string = useTheme().palette.primary.main
  const [hover, SetHover] = React.useState(false)
  const [openPopup, SetOpenPopup] = React.useState(false)
  const [deletePopup, SetDeletePopup] = React.useState(false)
  const [occurData, SetOccurData] = React.useState<any>(0)

  function getSlot(scheduleDetailOfSlot: any) {
    dispatch(requestForSlots(scheduleDetailOfSlot))
  }
  useEffect(() => {}, [])

  return (
    <Box
      height='inherit'
      id={id}
      style={{
        flexGrow: 1,
        borderRadius: 0,
        backgroundColor: primColor,
        minHeight: 30,
      }}
      onMouseOver={(e) => {
        if (moment(scheduleDetail.start).isSameOrBefore(new Date()) === false) {
          SetHover(true)
        }
      }}
      onMouseLeave={(e) => {
        SetHover(false)
      }}
    >
      <Box display='flex' height='100%' style={{ position: 'relative' }}>
        <Box>
          <Grid container direction='column'>
            <Grid item>
              <Box paddingX={1} paddingTop={0.5}>
                <Typography variant='subtitle2' style={{ color: '#fefefe' }}>
                  {`${moment(scheduleDetail.start).format(
                    'hh:mm A'
                  )}  -  ${moment(scheduleDetail.end).format('hh:mm A')}` ?? ''}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box paddingX={1}>
                <Typography
                  style={{
                    color: '#fefefe',
                    opacity: '0.7',
                    fontSize: 10,
                    fontFamily: 'Open Sans',
                    fontWeight: 'normal',
                  }}
                >
                  {scheduleDetail.title !== undefined
                    ? scheduleDetail.title
                    : ''}
                </Typography>
              </Box>
            </Grid>

            <Grid item>
              <Box paddingX={1}>
                <Typography
                  style={{
                    color: '#fefefe',
                    opacity: '0.7',
                    fontSize: 10,
                    fontFamily: 'Open Sans',
                    fontWeight: 'normal',
                  }}
                >
                  {scheduleDetail.extendedProps.repeat !== undefined
                    ? scheduleDetail.extendedProps.repeat
                    : ''}
                </Typography>
              </Box>
            </Grid>

            <Grid item>
              <Box paddingX={1}>
                <Typography
                  style={{
                    color: '#fefefe',
                    opacity: '0.7',
                    fontSize: 10,
                    fontFamily: 'Open Sans',
                    fontWeight: 'normal',
                  }}
                >
                  {scheduleDetail.extendedProps.versionId === undefined
                    ? ''
                    : ''}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {hover === true ? (
          <span style={{ position: 'absolute', bottom: -10, right: -10 }}>
            {' '}
            <Tooltip title='Delete' aria-label='Cancel'>
              <IconButton
                onClick={(e) => {
                  SetDeletePopup(true)
                }}
              >
                <img
                  src={cancelIcon}
                  id='cancel'
                  alt='cancel'
                  height={15}
                  style={{
                    filter: 'brightness(10) invert(20)',
                  }}
                />
              </IconButton>
            </Tooltip>
          </span>
        ) : (
          <span />
        )}
        {hover === true ? (
          <span style={{ position: 'absolute', top: -3, right: -11 }}>
            {' '}
            <IconButton
              style={{ height: 15, marginLeft: 20 }}
              onClick={(e) => {
                const repeatData = scheduleDetail.extendedProps.repeat
                if (repeatData.includes('Month')) {
                  SetOccurData(1)
                }

                SetOpenPopup(true)
              }}
            >
              <img
                src={rescheduleIcon}
                id='edit'
                alt='Edit'
                height={15}
                style={{
                  filter: 'brightness(100%) invert(20) ',
                }}
              />
            </IconButton>
            {/* </Tooltip> */}
          </span>
        ) : (
          <span />
        )}
      </Box>
      {openPopup === true && (
        <EditScheduleTile
          open={openPopup}
          type={scheduleDetail.title.split('.')}
          day={date}
          repeat={scheduleDetail.extendedProps.repeat}
          repeatString={scheduleDetail.extendedProps.repeatLabel}
          occurTimes={scheduleDetail.extendedProps.repeatTimes}
          repeatOn={scheduleDetail.extendedProps.repeatOn}
          defaultValue={occurData}
          personName={personName}
          onClose={() => {
            SetOpenPopup(false)
          }}
          onDiscard={(
            type: string[],
            selectedDate: Date,
            eventDate: any,
            occurrence: any,
            repeatData: any,
            range: any,
            repeatLabel: string,
            customLabel: string
          ) => {
            if (onEditClicked)
              onEditClicked(
                type,
                selectedDate,
                eventDate,
                scheduleDetail,
                occurrence,
                scheduleDetail.start,
                true,
                repeatData,
                range,
                repeatLabel,
                customLabel
              )
            SetOpenPopup(false)
          }}
        />
      )}

      {deletePopup === true && (
        <ConfirmationDialog
          open={deletePopup}
          displayText='Working hours will be deleted permanently. Do you want to continue?'
          title='Delete Schedule'
          doneButtonText='Remove'
          cancelButtonText='Back'
          onClose={() => {
            SetDeletePopup(false)
          }}
          onDiscard={() => {
            if (scheduleDetail.id !== undefined) {
              if (scheduleDetail.extendedProps.versionId === undefined) {
                scheduleDetail.remove()
              } else {
                scheduleDetail.setStart(
                  moment(scheduleDetail.start).add(0, 'days').toDate()
                )
                getSlot(scheduleDetail)
                const slots = searchSlots.slots ?? []
                if (slots.length === 0) {
                  const scheduleBundle = getDeleteBundle(
                    scheduleDetail.id,
                    scheduleDetail.extendedProps.data
                  )
                  dispatch(
                    removeScheduleDetails(
                      scheduleBundle,
                      selectedDoctor,
                      true,
                      dateData
                    )
                  )

                  scheduleDetail.remove()
                  if (onCancelClicked) {
                    onCancelClicked(
                      scheduleDetail.id,
                      removeScheduleDetailsSlice.deletionSuccessful
                    )
                  }
                } else {
                  dispatch(
                    'Working hours can not be deleted as appointment exists'
                  )
                }
              }
            }
            SetDeletePopup(false)
          }}
        />
      )}
    </Box>
  )
}
