import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  TaskIntentKind,
  TaskStatusKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { leftTask } from 'fp-ts/lib/TaskThese'
import _ from 'lodash'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { getCurrentUserDetails } from 'redux/administration/userSetup/userProfileCompletionSlice/userProfileCompletionSlice'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleRef,
} from 'services/userDetailsService'
import { getCarePlanOfPatientWithCurrentPractitioner } from 'utils/careplan_utils/careplan_utils'
import {
  getNotesFromAllergy,
  getNotesString,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { KriyaDetail } from 'views/components/consultation/plan/ayurveda/addKriyasForEncounter'

export async function addKriyaDetails(prescriptionsBundle: R4.IBundle) {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateFHIRTransaction('', prescriptionsBundle)

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  return true
}

export async function addNewKriyaDetails(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  kriyaDetails: KriyaDetail[]
) {
  const patientId = patientReference.reference?.split('/')[1]
  const carePlanOfPatient = await getCarePlanOfPatientWithCurrentPractitioner(
    patientId!
  )

  let result = false
  try {
    const svs = kriyaDetails.map((e) =>
      getServiceRequestForKriya(
        patientReference,
        encounterId,
        e,
        carePlanOfPatient
      )
    )

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: svs.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: sv.resourceType,
        },
      })),
    }

    result = await addKriyaDetails(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function editKriyaDetails(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  kriyaDetails: KriyaDetail[]
) {
  const patientId = patientReference.reference?.split('/')[1]
  const carePlanOfPatient = await getCarePlanOfPatientWithCurrentPractitioner(
    patientId!
  )

  let result = false
  try {
    const svs = kriyaDetails.map((e) =>
      getServiceRequestForKriya(
        patientReference,
        encounterId,
        e,
        carePlanOfPatient
      )
    )

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: svs.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${
          kriyaDetails[0].rawKriyaDetail!
            ? kriyaDetails[0].rawKriyaDetail!.id ?? ''
            : ''
        }`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${
            kriyaDetails[0].rawKriyaDetail!
              ? kriyaDetails[0].rawKriyaDetail!.id ?? ''
              : ''
          }`,
        },
      })),
    }

    result = await addKriyaDetails(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

// adding changes related to care plan dev
export function getServiceRequestForKriya(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  kriyaDetails: KriyaDetail,
  carePlan?: R4.ICarePlan
): R4.IServiceRequest {
  if (!kriyaDetails.kriya) {
    throw new Error('Kriya details not found')
  }

  let basedOn: R4.IReference[] | undefined
  if (carePlan) {
    basedOn = [
      {
        reference: `CarePlan/${carePlan.id}`,
      },
    ]
  }
  const serviceRequest: R4.IServiceRequest = {
    resourceType: 'ServiceRequest',
    subject: patientReference,
    status: 'active',
    priority: kriyaDetails.priority ?? 'routine',
    intent: 'proposal',
    basedOn,
    category: [
      {
        text: 'Kriya',
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/umls',
            display: 'Kriya',
            code: 'C0025122',
          },
          {
            system: 'http://snomed.info/sct',
            display: 'Kriya',
            code: '452091000124101',
          },
        ],
      },
    ],

    code: {
      coding: [kriyaDetails.kriya],
    },
    encounter: encounterId,
    authoredOn: new Date().toISOString(),

    requester: getCurrentUserPractitionerRoleRef(),
  }

  const pract: R4.IPractitioner = getCurrentUserPractitionerDetails()
  if (!_.isEmpty(kriyaDetails.remarks)) {
    const noteText: R4.IAnnotation = {
      authorReference: {
        reference: `${pract.resourceType}/${pract.id}`,
      },
      text: kriyaDetails.remarks,
    }
    serviceRequest.note = [noteText]
  }

  if (kriyaDetails.medications && kriyaDetails.medications.length > 0) {
    serviceRequest.orderDetail = kriyaDetails.medications.map((medication) => ({
      text: medication.display,
      coding: [medication],
    }))
  }

  return serviceRequest
}

export async function getKriyaListForTheEncounter(body: {
  encounterId: string
  appointmentId: string
}): Promise<KriyaDetail[] | boolean> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doGetResourceForAppointment(
      `/ServiceRequest`,
      body.appointmentId,
      {
        encounter: `Encounter/${body.encounterId}`,
        category: 'C0025122',
        intent: 'proposal,directive,order',

        _count: 500,
      }
    )

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  const resp: R4.IBundle = response
  if (resp.entry && resp.entry.length === 0) {
    return false
  }
  const taskObject = resp.entry?.map(
    (entry) => entry.resource as R4.IServiceRequest
  )

  let returnResult: KriyaDetail[] = []
  if (taskObject && taskObject.length > 0) {
    returnResult = taskObject.map((task) => {
      const res: KriyaDetail = {
        recordedDate: task.authoredOn ?? '',
        kriya: task.code?.coding?.[0]!,
        medications: task.orderDetail?.map((e) => e.coding?.[0]!) ?? [],
        remarks: getNotesString(task.note),
        rawKriyaDetail: task,
        priority: task.priority,
      }

      return res
    })
  }

  console.log('--------------------returnResult----------------', returnResult)
  return returnResult
}
