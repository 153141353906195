import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getTasksResource } from 'utils/appointment_handle/cds_recommendations_util'
import {
  getCarePlanByIdCurrentState,
  getCarePlanOfPatient,
  hasPlanOfferedInOrg,
} from 'utils/careplan_utils/careplan_utils'
import {
  getTaskResourcesFromWeekLongDietPlans,
  getWeekLongDietPlanFromTasks,
} from 'utils/careplan_utils/weeklyDietPlanUtils'
import { sleep } from 'utils/dateUtil'
import { logger } from 'utils/logger'
import { WeeklyDietPlanAdditionStatus as DietsAdditionStatus } from './dietsAdditionState'

const initialState: DietsAdditionStatus = {
  addingInstruction: false,
  additionSuccessful: false,
  noResultsAvailable: false,
  errorWhileAdding: false,
}

const dietsAdditionSlice = createSlice({
  name: 'dietsAdditionSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<DietsAdditionStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.addingInstruction = action.payload.addingInstruction
      state.additionSuccessful = action.payload.additionSuccessful
      state.successMessage = action.payload.successMessage
      state.errorReason = action.payload.errorReason
      state.errorWhileAdding = action.payload.errorWhileAdding
    },
  },
})

export const addWeeklyDietPlansRecommendations =
  (
    patient: R4.IPatient,
    dietTasks: R4.ITask[],
    encounterId: string,
    carePlanUrl?: string,
    carePlanId?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: DietsAdditionStatus = {
      ...initialState,
    }
    state.addingInstruction = true
    dispatch(dietsAdditionSlice.actions.updatedStatus(state))
    try {
      const hasPlanDef = true
      const updatedTasks: R4.ITask[] = getTaskResourcesFromWeekLongDietPlans(
        getWeekLongDietPlanFromTasks(dietTasks),
        patient,
        encounterId
      )
      if (hasPlanDef) {
        const carePlanOfPatient: R4.ICarePlan | undefined =
          carePlanId !== undefined
            ? await getCarePlanByIdCurrentState(carePlanId)
            : await getCarePlanOfPatient(patient.id!, carePlanUrl ?? '', true)
        if (carePlanOfPatient) {
          const response: AxiosResponse | undefined = await addTasksApiCalls(
            updatedTasks,
            patient,
            encounterId,
            carePlanOfPatient!.id!
          )
          console.log('.........DietPlan responses............', response)
          if (response) {
            let isSuccess: boolean = false
            if (response.status === 200 || response.status === 201) {
              isSuccess = true
            }

            if (isSuccess) {
              state.addingInstruction = false
              state.additionSuccessful = true
              state.errorWhileAdding = false
              state.successMessage =
                'Diet plan have been updated in care portal.'
              state.errorReason = undefined
              dispatch(dietsAdditionSlice.actions.updatedStatus(state))
              dispatch(showSuccessAlert(state.successMessage!))
            }
          } else {
            state.addingInstruction = false
            state.additionSuccessful = false
            state.errorWhileAdding = true
            state.successMessage = undefined
            state.errorReason =
              'Error while adding Diet Plan details. Try later'
            dispatch<any>(showErrorAlert(state.errorReason!))
            dispatch(dietsAdditionSlice.actions.updatedStatus(state))
          }
        } else {
          state.addingInstruction = false
          state.additionSuccessful = false
          state.errorWhileAdding = true
          state.successMessage = undefined
          state.errorReason = 'User is not subscribed to Care Plan'
          dispatch<any>(showErrorAlert(state.errorReason!))
          dispatch(dietsAdditionSlice.actions.updatedStatus(state))
        }
      } else {
        const bundle: R4.IBundle = {
          resourceType: 'Bundle',
          type: R4.BundleTypeKind._transaction,
          entry: dietTasks.map(
            (e) =>
              ({
                request: {
                  method: R4.Bundle_RequestMethodKind._post,
                  url: 'Task',
                },
                resource: getTasksResource(
                  e,
                  encounterId ?? '',
                  patient.id!,
                  carePlanUrl
                ),
              } as R4.IBundle_Entry)
          ),
        }

        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response = await fhirApi.doCreateFHIRTransaction('', bundle)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          state.addingInstruction = false
          state.additionSuccessful = true
          state.errorWhileAdding = false
          state.successMessage = 'Diet plan have been updated in care portal.'
          state.errorReason = undefined
          dispatch(dietsAdditionSlice.actions.updatedStatus(state))
          dispatch(showSuccessAlert(state.successMessage!))
        } else {
          state.addingInstruction = false
          state.additionSuccessful = false
          state.errorWhileAdding = true
          state.successMessage = undefined
          state.errorReason = 'Error while adding Diet tasks. Try later'
          dispatch(showErrorAlert(state.errorReason!))
          dispatch(dietsAdditionSlice.actions.updatedStatus(state))
        }
      }
    } catch (error) {
      console.error(error)
      const resState: DietsAdditionStatus = {
        addingInstruction: false,
        errorWhileAdding: true,
        additionSuccessful: false,
        noResultsAvailable: false,
        errorReason: (error as AxiosError).response?.data,
      }
      dispatch<any>(showErrorAlert(state.errorReason!))
      dispatch(dietsAdditionSlice.actions.updatedStatus(resState))
    }
  }

export const resetDietPlanAdditionState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: DietsAdditionStatus = {
      ...initialState,
    }

    dispatch(dietsAdditionSlice.actions.updatedStatus(state))
  }

export const addTasksApiCalls = async (
  kriyaDetails: R4.ITask[],
  patient: R4.IPatient,
  encounterId: string,
  careplanId: string
): Promise<AxiosResponse | undefined> => {
  try {
    const body: any = {
      patientId: patient.id,
      carePlanId: careplanId,
      encounterId: encounterId!,
      dietTasks: kriyaDetails,
    }
    const enRolClient: EnrolCient = new EnrolCient()

    const responses: AxiosResponse =
      await enRolClient.doCreateEnrolmentFlowRequestWithRawResponse(
        `/carePlan/${careplanId}/weekly-diet`,
        body
      )

    logger.info('...........success............')
    logger.info(responses)
    return responses
  } catch (error) {
    logger.info('_______error________________')
    logger.info(error)
  }
  return undefined
}

export default dietsAdditionSlice.reducer
