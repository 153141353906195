import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  List,
  makeStyles,
  Paper,
  TextField,
  Typography,
  Link,
  Card,
} from '@material-ui/core'
import { kPrimaryMain } from 'configs/styles/muiThemes'
import ErrorIcon from '@material-ui/icons/Error'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getUnitType, isOrgAdmin, isWpAdmin } from 'services/userDetailsService'
import { getLogOutUrl } from 'utils/authHelpers'
import { getVendorPartId } from 'utils/routes_helper'

import '../../../App.css'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

export interface IUnitAdmin {
  msg: string
}

export const ErrorPage: React.FC<IUnitAdmin> = ({ msg }) => {
  const dispatch = useDispatch()
  const vendorId: string | null = getVendorPartId()

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height='100%'
      width='100%'
      style={{
        background:
          'radial-gradient(50% 50% at 50% 50%, #FFFFFF 41.67%, #F3F9FE 100%)',
      }}
    >
      <Box display='flex' justifyContent='center' alignItems='center'>
        <Card>
          <Box
            display='flex'
            flexDirection='row'
            paddingY={2}
            minHeight='342px'
            justifyContent='center'
            alignContent='center'
          >
            <Box
              display='flex'
              flexDirection='column'
              alignContent='center'
              justifyContent='center'
              alignItems='center'
              justifyItems='center'
              minWidth='380px'
            >
              <img
                src={`${process.env.PUBLIC_URL}/logo_with_steam.png`}
                alt='delete'
                height='170px'
                width='255.88px'
              />

              <Box paddingLeft={1.8}>
                <Typography
                  variant='subtitle1'
                  color='initial'
                  align='center'
                  style={{
                    color: '#53B447',
                  }}
                >
                  {process.env.REACT_APP_NAME === 'WelloLAB'
                    ? 'Efficient patient engagements, phlebotomist operations'
                    : process.env.REACT_APP_NAME === 'WelloDR'
                    ? 'Superior personalized care, world class evidence'
                    : 'Smartly monitor, manage, control overall operations'}
                </Typography>
              </Box>
            </Box>
            <Divider
              orientation='vertical'
              flexItem
              style={{
                marginLeft: 16,
                marginRight: 16,
                backgroundColor: kPrimaryMain,
              }}
            />{' '}
            <Box
              display='flex'
              flexDirection='column'
              alignContent='center'
              justifyContent='center'
              alignItems='center'
              justifyItems='center'
              minWidth='380px'
              //   paddingTop={10}
            >
              {/* <FontAwesomeIcon
                icon={faExclamationCircle}
                color={kPrimaryMain}
              /> */}
              <img
                src={`${process.env.PUBLIC_URL}/errorPage.png`}
                alt='delete'
                height='70px'
                width='70.88px'
              />
              <Box paddingTop={1} paddingBottom={2} px={1.8}>
                <Typography variant='subtitle1' color='error' align='center'>
                  {msg}
                </Typography>
              </Box>

              {/* <Box paddingTop={2}>
                <Button
                  variant='contained'
                  size='small'
                  fullWidth
                  color='primary'
                  onClick={() => {
                    sessionStorage.clear()
                    localStorage.clear()
                    window.location.href = getLogOutUrl()
                  }}
                >
                  Sign in as different user
                </Button>
              </Box> */}
            </Box>
          </Box>
        </Card>
      </Box>
      {/* <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box
            display='flex'
            width='100%'
            flexDirection='column'
            justifyContent='center'
            height='100%'
          >
            <Box
              display='flex'
              width='100%'
              flexDirection='row'
              justifyContent='center'
            >
              <img src={`${process.env.PUBLIC_URL}/404.png`} alt='404' />
            </Box>

            <Box
              display='flex'
              width='100%'
              flexDirection='row'
              justifyContent='center'
            >
              <Typography
                variant='subtitle2'
                color='primary'
                id='addictions_add_button_link'
                component={Link}
                style={{ fontSize: 13, cursor: 'pointer' }}
                onClick={() => {
                  if (getUnitType() === 'lab') {
                    if (vendorId !== null) {
                      window.location.href = `https://qa.lab.wellopathy.com/${vendorId}/dashboard`
                    } else {
                      window.location.href = `https://qa.lab.wellopathy.com`
                    }
                  }

                  if (getUnitType() === 'clinic') {
                    if (vendorId !== null) {
                      window.location.href = `https://qa.clinic.wellopathy.com/${vendorId}/dashboard`
                    } else {
                      window.location.href = `https://qa.clinic.wellopathy.com/`
                    }
                  }
                  if (isOrgAdmin() || isWpAdmin()) {
                    if (vendorId !== null) {
                      window.location.href = `https://qa.admin.wellopathy.com`
                    } else {
                      window.location.href = `https://qa.clinic.wellopathy.com/`
                    }
                  }
                }}
              >
                {' '}
                Go to authorized page
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper> */}
    </Box>
  )
}
