import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core'
import { kBackgroundPaper, kDialogueBackground } from 'configs/styles/muiThemes'
import _ from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getKriyaNameFromKriyaDetail } from 'utils/patientHelper/cds_helper'
import { getServiceRequestForKriya } from 'utils/sopaNotes/plan/kriyaHelpers'
import { AddedKriyasList } from '../../assessment/addedKriyasList'
import { KriyaDetail } from '../ayurveda/addKriyasForEncounter'
import { KriyaDetailTile } from '../ayurveda/kriyaDetailTile'
import { KriyaDetailsCaptureForm } from '../ayurveda/kriyaEnterForm'

interface Props {
  open: boolean
  encounterId: string
  patient: R4.IPatient
  preAddedMedications: R4.IServiceRequest[]
  onClose: () => void
  onKriyasAddClicked: (medications: R4.IServiceRequest[]) => void
  isAddingKriyas?: boolean
}
export const AddKriyaFromCPGHandler: React.FC<Props> = ({
  open,
  onClose,
  onKriyasAddClicked,
  preAddedMedications,
  encounterId,
  patient,
  isAddingKriyas = false,
}) => {
  const { t } = useTranslation(['common', 'medication', 'dosage'])
  const [addedMedications, setAddedMedications] = useState<KriyaDetail[]>([])

  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '60%',
      overflow: 'visible',
      borderRadius: '10px',
    },
  }))
  const classes = useStyles()

  const theme = useTheme()

  const [currentMedication, setCurrentMedication] = useState<KriyaDetail>({})

  const [currentMedicationDetails, setCurrentMedicationDetails] =
    useState<KriyaDetail>({
      rawKriyaDetail: preAddedMedications[0],
    })

  const [currentIndex, setCurrentIndex] = useState(0)

  function addCurrentDetails(pushToSave?: boolean) {
    const tempKriya: KriyaDetail[] = [...addedMedications]
    const newKriya: KriyaDetail = {
      ...currentMedication,
    }
    tempKriya.push(newKriya)
    Promise.resolve().then(() => {
      setAddedMedications(tempKriya)
      setCurrentIndex(currentIndex + 1)
      if (currentIndex + 1 < preAddedMedications.length) {
        setCurrentMedicationDetails({
          rawKriyaDetail: preAddedMedications[currentIndex + 1],
        })
      }
      if (pushToSave) {
        onKriyasAddClicked(
          tempKriya.map((e) =>
            getServiceRequestForKriya(
              {
                reference: `Patient/${patient.id}`,
              },
              {
                reference: `Encounter/${encounterId}`,
              },
              e
            )
          )
        )
      }
    })
  }

  function isValidMedicationDetails() {
    if (currentMedication) {
      if (currentMedication.kriya !== undefined) {
        return true
      }
      return false
    }
    return false
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setAddedMedications([])
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
      aria-labelledby='responsive-dialog-title'
      BackdropProps={{}}
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle
        id='scroll-dialog-title'
        style={{
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
        }}
      >
        Add Kriya (Procedure)
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          overflowY: 'auto',
          margin: 0,
          overflowX: 'hidden',
          scrollBehavior: 'smooth',
        }}
      >
        <Box display='flex' flexDirection='column'>
          <Box display='flex' width='100%'>
            <KriyaDetailsCaptureForm
              existingKriyaDetails={currentMedicationDetails}
              key={
                currentMedicationDetails.rawKriyaDetail?.id ??
                getKriyaNameFromKriyaDetail(currentMedicationDetails) ??
                currentIndex
              }
              onChanged={(e) => {
                setCurrentMedication(e)
              }}
            />
          </Box>
          {addedMedications.length > 0 && (
            <Box display='flex' flexDirection='row' width='100%' px={3}>
              <Box
                marginY={1}
                display='flex'
                flexDirection='column'
                width='100%'
              >
                <Typography
                  variant='subtitle1'
                  color='initial'
                  style={{ padding: '4px' }}
                >
                  Currently Added Kriyas
                </Typography>

                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  my={1}

                  //   key={e.id}
                >
                  <AddedKriyasList kriyasList={addedMedications} />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          p={1}
          flexGrow={1}
          alignItems='end'
          alignContent='end'
          justifyContent='end'
          width='100%'
        >
          <Box display='flex' flexDirection='row'>
            {isAddingKriyas && (
              <Box paddingX={1}>
                <CircularProgress size={20} />
              </Box>
            )}
            <Box paddingX={1}>
              <Button
                onClick={() => {
                  // resetForm()
                  onClose()
                  setAddedMedications([])
                }}
                variant='outlined'
                disableElevation
                disabled={isAddingKriyas}
                id='cancel'
              >
                {t('labelCommon:cancel')}
              </Button>
            </Box>
            <Box paddingX={1}>
              <Button
                onClick={() => {
                  if (currentIndex + 1 < preAddedMedications.length) {
                    addCurrentDetails()
                  } else {
                    addCurrentDetails(true)
                  }
                }}
                id='another'
                variant='contained'
                color='primary'
                disableElevation
                disabled={!isValidMedicationDetails() || isAddingKriyas}
              >
                {currentIndex + 1 < preAddedMedications.length
                  ? 'Add and Fill Next'
                  : 'Finish'}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
