import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListSubheader,
  Radio,
  RadioGroup,
  Typography,
  Tooltip,
  Link,
  Collapse,
  useTheme,
} from '@material-ui/core'
import {
  Add,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { ChiefComplaintData } from 'models/chiefComplaintData'
import { ChiefComplaintsData } from 'models/chiefComplaintsData'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedOccupation } from 'models/groupedOccupations'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import IPDchiefComplaintSlice from 'redux/consultation/IpdChiefCompaints/IPDchiefComplaintSlice'
import { requestAddictionsHistoryOfPatient } from 'redux/patientMedicalHistory/addictionsHistory/addictionsHistorySlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import {
  getStringDays,
  getStringWeeks,
  getStringMonth,
  getStringYear,
} from 'utils/fhirResourcesHelper'
import {
  getNotesString,
  getNotesFromAllergy,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import {
  getDateWiseVitals,
  getDateWiseVitalsForOthers,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getObservationCodeText,
  getObservationContent,
  getObservationValueCodeableConceptDisplayByComponent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import OverflowTypography from 'views/components/consultation/plan/ayurveda/overflowTypography'
import { AddAddiction } from 'views/components/ipdconusltation/addition/addAddiction'
import { HistoryObjectiveIndicativeComp } from 'views/components/LeftMenu/HistoryObjectiveIndicative'
import { VitalElement } from 'views/components/LeftMenu/VitalElement'
import { SOAPIndicativeElement, titleCase } from 'wello-web-components'
import { AddictionTileTabular } from './addictionTabularTile'

interface AddictionsProps {
  chiefDataArrayData: ChiefComplaintsData[]
}

export const ChiefComplaintsHistory: React.FC<AddictionsProps> = ({
  chiefDataArrayData,
}: AddictionsProps) => {
  const { t } = useTranslation(['common'])
  const theme = useTheme()

  const [chiefDataArrayDataFinal, setChiefDataArrayDataFinal] = useState<
    ChiefComplaintsData[]
  >([])

  function handleCollapse(rate: boolean, index: number) {
    const values: ChiefComplaintsData[] = [...chiefDataArrayDataFinal]
    values[index].collapsed = rate
    setChiefDataArrayDataFinal(values)
  }

  useEffect(() => {
    if (chiefDataArrayData.length > 0) {
      updateConditions(chiefDataArrayData)
    }
  }, [])

  //   useEffect(() => {
  //     setChiefDataArrayDataFinal(chiefDataArrayData)
  //   }, [])

  function updateConditions(conditionList: ChiefComplaintsData[]) {
    const results: ChiefComplaintsData[] = []
    for (let i = 0; i < conditionList.length; i++) {
      results.push({
        id: conditionList[i].id,
        preselectedComplaints: conditionList[i].preselectedComplaints,
        preSelectedLocation: conditionList[i].preSelectedLocation,
        preSelectedDuration: conditionList[i].preSelectedDuration,
        selectedCondition: conditionList[i].selectedCondition,
        selectedSeverity: conditionList[i].selectedSeverity,
        selectedLocation: conditionList[i].selectedLocation,
        preselectedVal: conditionList[i].preselectedVal,
        preSelectedUnitType: conditionList[i].preSelectedUnitType,

        preselectedSymptoms: conditionList[i].preselectedSymptoms,
        duration: conditionList[i].duration,
        character: conditionList[i].character,
        alleviating: conditionList[i].alleviating,
        radiation: conditionList[i].radiation,
        temporal: conditionList[i].temporal,
        lastUpdated: conditionList[i].lastUpdated,
        selectedDate: conditionList[i].selectedDate,
        itemNumber: conditionList[i].itemNumber,
        collapsed: conditionList[i].collapsed,
        notes: conditionList[i].notes,
        notesForIPD: conditionList[i].notesForIPD,
        patientFeeling: conditionList[i].patientFeeling,
        category: conditionList[i].category,
        evidenceData: conditionList[i].evidenceData,
        selectedEvidence: conditionList[i].selectedEvidence,
        unitValForDays: conditionList[i].unitValForDays,
        unitValForWeek: conditionList[i].unitValForWeek,
        unitValForMonth: conditionList[i].unitValForMonth,
        unitValForYear: conditionList[i].unitValForYear,
        unitTypeDays: conditionList[i].unitTypeDays,
        associatedCondition: conditionList[i].associatedCondition,
        painChecked: conditionList[i].painChecked,
        associatedChecked: conditionList[i].associatedChecked,
        patientWording: conditionList[i].patientWording,
        painDuration: conditionList[i].painDuration,
        notesData: conditionList[i].notesData,
      })
    }
    setChiefDataArrayDataFinal(results)
  }

  return (
    <Box
      flexGrow
      width='100%'
      flexDirection='column'
      display='flex'
      style={{ padding: 0, margin: 0 }}
    >
      <Box flexGrow width='100%' display='flex' flexDirection='row'>
        <Box flexDirection='row' display='flex' flexGrow width='100%'>
          <Box py={1}>
            <Typography variant='subtitle1'>Chief Complaints</Typography>
          </Box>{' '}
        </Box>
      </Box>

      {chiefDataArrayData.length === 0 && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            {' '}
            No data available
          </Typography>
        </Box>
      )}

      {chiefDataArrayDataFinal.length > 0 && (
        <Box>
          {chiefDataArrayDataFinal.map((val, index: number) => (
            <Box key={`med_cond${val.id}`} width='100%' py={1}>
              <Box>
                <Box
                  paddingX={1}
                  borderRadius={2}
                  style={{
                    backgroundColor: 'lightGrey',
                  }}
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height={40}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow={1}
                    alignItems='center'
                  >
                    <Typography
                      variant='subtitle1'
                      style={{
                        color: 'black',

                        fontWeight: 'bold',
                      }}
                    >
                      Recorded on {val.lastUpdated}
                    </Typography>
                  </Box>
                  <Box
                    justifyContent='flex-end'
                    display='flex'
                    flexGrow={1}
                    alignItems='center'
                  >
                    <Box px={1}>
                      <Tooltip title='' id='chief_id_41'>
                        <IconButton
                          aria-label='collapse_order_type'
                          size='small'
                          id='chief_id_42'
                          onClick={() => {
                            handleCollapse(!val.collapsed, index)
                          }}
                        >
                          {val.collapsed && (
                            <ArrowDropUpOutlined id='chief_id_43' />
                          )}
                          {!val.collapsed && (
                            <ArrowDropDownOutlined id='chief_id_44' />
                          )}
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
                <Collapse
                  in={val.collapsed}
                  style={{
                    width: '100%',
                  }}
                >
                  <Box
                    border={2}
                    style={{
                      boxShadow: '0px 0px 4px #ccc',
                      margin: '4px 0px',
                      borderRadius: '4px',
                      borderColor: '#F8F8F8',
                      width: '100%',
                      height: 'auto',
                      backgroundColor: '#F8F8F8',
                    }}
                  >
                    <Grid container>
                      <Grid item xs={3}>
                        <Box
                          display='flex'
                          flexDirection='Column'
                          width='100%'
                          style={{
                            backgroundColor: '#edf2fa',
                          }}
                        >
                          <Box display='flex' flexDirection='row' width='100%'>
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={1}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Chief Complaints
                                </Typography>
                              </Box>

                              <Divider
                                orientation='horizontal'
                                flexItem
                                style={{
                                  height: '2px',
                                }}
                              />
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={2.5}
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Mapped Symptoms
                                </Typography>
                              </Box>

                              <Divider
                                orientation='horizontal'
                                flexItem
                                style={{
                                  height: '2px',
                                }}
                              />
                              {val.associatedCondition &&
                                val.associatedCondition.length > 0 && (
                                  <Box display='flex' flexDirection='column'>
                                    {val.associatedCondition.map(
                                      (valCondition, index1: number) => (
                                        <Box
                                          key={`med_cond${val.id}`}
                                          width='100%'
                                        >
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                          >
                                            <Typography
                                              variant='subtitle2'
                                              // color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,
                                                color: 'black',

                                                fontWeight: 'bold',
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              Associated Complaints
                                            </Typography>
                                          </Box>

                                          <Divider
                                            orientation='horizontal'
                                            flexItem
                                            style={{
                                              height: '2px',
                                            }}
                                          />
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={2.5}
                                            style={{
                                              backgroundColor:
                                                kDialogueBackground,
                                            }}
                                          >
                                            <Typography
                                              variant='subtitle2'
                                              // color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,
                                                color: 'black',

                                                fontWeight: 'bold',
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              Mapped Symptoms
                                            </Typography>
                                          </Box>

                                          <Divider
                                            orientation='horizontal'
                                            flexItem
                                            style={{
                                              height: '2px',
                                            }}
                                          />
                                        </Box>
                                      )
                                    )}
                                  </Box>
                                )}

                              {val.selectedLocation &&
                                val.selectedLocation.length > 0 && (
                                  <Box display='flex' flexDirection='column'>
                                    <Box
                                      justifyContent='flex-start'
                                      display='flex'
                                      flexGrow
                                      height={30}
                                      alignItems='center'
                                      px={1}
                                    >
                                      <Typography
                                        variant='subtitle2'
                                        // color='initial'
                                        style={{
                                          fontFamily: 'Open Sans',
                                          fontSize: 12,
                                          color: 'black',

                                          fontWeight: 'bold',
                                          lineHeight: 'normal',
                                        }}
                                      >
                                        Pain Profile : Pain Area ?
                                      </Typography>
                                    </Box>

                                    <Divider
                                      orientation='horizontal'
                                      flexItem
                                      style={{
                                        height: '2px',
                                      }}
                                    />
                                  </Box>
                                )}
                              {val.character.length > 0 && (
                                <Box display='flex' flexDirection='column'>
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                    style={{
                                      backgroundColor: kDialogueBackground,
                                    }}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Describe what the patient is experiencing?
                                    </Typography>
                                  </Box>

                                  <Divider
                                    orientation='horizontal'
                                    flexItem
                                    style={{
                                      height: '2px',
                                    }}
                                  />
                                </Box>
                              )}
                              {val.alleviating.length > 0 && (
                                <Box display='flex' flexDirection='column'>
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    alignItems='center'
                                    height={40}
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Alleviating/Aggravating factors: What
                                      makes it better and worse?
                                    </Typography>
                                  </Box>

                                  <Divider
                                    orientation='horizontal'
                                    flexItem
                                    style={{
                                      height: '2px',
                                    }}
                                  />
                                </Box>
                              )}

                              {val.radiation.length > 0 && (
                                <Box display='flex' flexDirection='column'>
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    alignItems='center'
                                    height={40}
                                    px={1}
                                    style={{
                                      backgroundColor: kDialogueBackground,
                                    }}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      {t('labelCommon:radiations')}
                                    </Typography>
                                  </Box>

                                  <Divider
                                    orientation='horizontal'
                                    flexItem
                                    style={{
                                      height: '2px',
                                    }}
                                  />
                                </Box>
                              )}

                              {val.evidenceData && val.evidenceData.length > 0 && (
                                <Box display='flex' flexDirection='column'>
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    alignItems='center'
                                    minHeight={30}
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Associated Symptoms
                                    </Typography>
                                  </Box>

                                  <Divider
                                    orientation='horizontal'
                                    flexItem
                                    style={{
                                      height: '2px',
                                    }}
                                  />
                                </Box>
                              )}
                              {val.temporal.length > 0 && (
                                <Box display='flex' flexDirection='column'>
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={40}
                                    alignItems='center'
                                    px={1}
                                    style={{
                                      backgroundColor:
                                        val.evidenceData &&
                                        val.evidenceData.length > 0
                                          ? kDialogueBackground
                                          : '#edf2fa',
                                    }}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      {t('labelCommon:pattern')}
                                    </Typography>
                                  </Box>

                                  <Divider
                                    orientation='horizontal'
                                    flexItem
                                    style={{
                                      height: '2px',
                                    }}
                                  />
                                </Box>
                              )}
                              {val.selectedSeverity && (
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  style={{
                                    backgroundColor:
                                      val.temporal.length > 0 &&
                                      val.notes === undefined
                                        ? val.evidenceData &&
                                          val.evidenceData.length > 0
                                          ? '#edf2fa'
                                          : kDialogueBackground
                                        : '#edf2fa',
                                  }}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      {t(
                                        'labelCondition:attr_labels.Condition.severity'
                                      )}{' '}
                                    </Typography>
                                  </Box>
                                  {val.notes && (
                                    <Divider
                                      orientation='horizontal'
                                      flexItem
                                      style={{
                                        height: '2px',
                                      }}
                                    />
                                  )}
                                </Box>
                              )}
                              {val.notes && (
                                <Box display='flex' flexDirection='column'>
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                    style={{
                                      backgroundColor: kDialogueBackground,
                                    }}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Additional Notes
                                    </Typography>
                                  </Box>

                                  {/* <Divider
                                            orientation='horizontal'
                                            flexItem
                                            style={{
                                              height: '2px',
                                            }}
                                          /> */}
                                </Box>
                              )}
                            </Box>

                            <Box
                              display='flex'
                              flexGrow={1}
                              justifyContent='flex-end'
                            >
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  height: 'auto',
                                  color: 'red',
                                  width: '2px',
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={9}
                        style={{
                          backgroundColor: '#edf2fa',
                        }}
                      >
                        <Box display='flex' flexDirection='Column' width='100%'>
                          <Box display='flex' flexDirection='row' width='100%'>
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              {/*     Main Start  */}
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={1}
                              >
                                {val.patientFeeling && (
                                  <OverflowTypography
                                    text={val.patientFeeling.text ?? ''}
                                    typographyProps={{
                                      variant: 'subtitle2',
                                      color: 'initial',
                                    }}
                                  />
                                )}
                              </Box>

                              <Divider
                                orientation='horizontal'
                                flexItem
                                style={{
                                  height: '2px',
                                }}
                              />
                              {/*     Main end  */}

                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                  width='40%'
                                >
                                  {val.selectedCondition &&
                                    val.selectedCondition.display && (
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow
                                        overflow='auto'
                                      >
                                        <Box>
                                          <Typography
                                            variant='subtitle2'
                                            color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,

                                              fontWeight: 600,
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            {titleCase(
                                              val.selectedCondition.display
                                            )}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    )}
                                </Box>
                                <Box display='flex' width='2%'>
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '30px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                  width='29%'
                                >
                                  <Typography
                                    variant='subtitle2'
                                    //   color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Duration / Start Date
                                  </Typography>
                                </Box>
                                <Box display='flex' width='2%'>
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '30px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                  width='27%'
                                >
                                  {val.selectedDate && (
                                    <Typography
                                      variant='subtitle2'
                                      color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,

                                        fontWeight: 600,
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      {moment(val.selectedDate).format(
                                        'Do MMMM, YYYY'
                                      )}
                                    </Typography>
                                  )}
                                  {val.unitValForDays > 0 && (
                                    <Typography
                                      variant='subtitle2'
                                      color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,

                                        fontWeight: 600,
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      {val.unitValForDays}{' '}
                                      {getStringDays(val.unitValForDays)}
                                    </Typography>
                                  )}
                                  {val.unitValForWeek > 0 && (
                                    <Typography
                                      variant='subtitle2'
                                      color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,

                                        fontWeight: 600,
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      {val.unitValForWeek}{' '}
                                      {getStringWeeks(val.unitValForWeek)}
                                    </Typography>
                                  )}
                                  {val.unitValForMonth > 0 && (
                                    <Typography
                                      variant='subtitle2'
                                      color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,

                                        fontWeight: 600,
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      {val.unitValForMonth}{' '}
                                      {getStringMonth(val.unitValForMonth)}
                                    </Typography>
                                  )}
                                  {val.unitValForYear > 0 && (
                                    <Typography
                                      variant='subtitle2'
                                      color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,

                                        fontWeight: 600,
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      {val.unitValForYear}{' '}
                                      {getStringYear(val.unitValForYear)}
                                    </Typography>
                                  )}
                                </Box>
                              </Box>

                              <Divider
                                orientation='horizontal'
                                flexItem
                                style={{
                                  height: '2px',
                                }}
                              />

                              {/*     Associated Start  */}
                              {val.associatedCondition &&
                                val.associatedCondition.length > 0 && (
                                  <Box display='flex' flexDirection='column'>
                                    {val.associatedCondition.map(
                                      (valCondition, index1: number) => (
                                        <Box
                                          key={`med_cond${val.id}`}
                                          width='100%'
                                        >
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                          >
                                            {valCondition.patientWording && (
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                                flexGrow
                                              >
                                                <OverflowTypography
                                                  text={
                                                    valCondition.patientWording ??
                                                    ''
                                                  }
                                                  typographyProps={{
                                                    variant: 'subtitle2',
                                                    color: 'initial',
                                                  }}
                                                />
                                              </Box>
                                            )}
                                          </Box>

                                          <Divider
                                            orientation='horizontal'
                                            flexItem
                                            style={{
                                              height: '2px',
                                            }}
                                          />
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            width='100%'
                                            style={{
                                              backgroundColor:
                                                kDialogueBackground,
                                            }}
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={30}
                                              alignItems='center'
                                              px={1}
                                              width='40%'
                                            >
                                              {valCondition.cui &&
                                                valCondition.cui.display && (
                                                  <Box
                                                    display='flex'
                                                    flexDirection='column'
                                                    width='100%'
                                                    flexGrow
                                                    overflow='auto'
                                                  >
                                                    <Box>
                                                      <Typography
                                                        variant='subtitle2'
                                                        color='initial'
                                                        style={{
                                                          fontFamily:
                                                            'Open Sans',
                                                          fontSize: 12,

                                                          fontWeight: 600,
                                                          lineHeight: 'normal',
                                                        }}
                                                      >
                                                        {titleCase(
                                                          valCondition.cui
                                                            .display
                                                        )}
                                                      </Typography>
                                                    </Box>
                                                  </Box>
                                                )}
                                            </Box>
                                            <Box display='flex' width='2%'>
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  height: '30px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={30}
                                              alignItems='center'
                                              px={1}
                                              width='29%'
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                //   color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,
                                                  color: 'black',
                                                  fontWeight: 'bold',
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                Duration / Start Date
                                              </Typography>
                                            </Box>
                                            <Box display='flex' width='2%'>
                                              <Divider
                                                orientation='vertical'
                                                flexItem
                                                style={{
                                                  height: '30px',

                                                  width: '2px',
                                                }}
                                              />
                                            </Box>
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={30}
                                              alignItems='center'
                                              px={1}
                                              width='27%'
                                            >
                                              {valCondition.cui &&
                                                valCondition.typeData ===
                                                  'custom' &&
                                                valCondition.date && (
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                    style={{
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 12,

                                                      fontWeight: 600,
                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    {moment(
                                                      valCondition.date
                                                    ).format('Do MMMM, YYYY')}
                                                  </Typography>
                                                )}
                                              {valCondition.days > 0 && (
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {valCondition.days}{' '}
                                                  {getStringDays(
                                                    valCondition.days
                                                  )}
                                                </Typography>
                                              )}
                                              {valCondition.weeks > 0 && (
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {valCondition.weeks}{' '}
                                                  {getStringWeeks(
                                                    valCondition.weeks
                                                  )}
                                                </Typography>
                                              )}
                                              {valCondition.months > 0 && (
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {valCondition.months}{' '}
                                                  {getStringMonth(
                                                    valCondition.months
                                                  )}
                                                </Typography>
                                              )}
                                              {valCondition.year > 0 && (
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {valCondition.year}{' '}
                                                  {getStringYear(
                                                    valCondition.year
                                                  )}
                                                </Typography>
                                              )}
                                            </Box>
                                          </Box>
                                          <Divider
                                            orientation='horizontal'
                                            flexItem
                                            style={{
                                              height: '2px',
                                            }}
                                          />
                                          {/*     Associated end  */}
                                        </Box>
                                      )
                                    )}
                                  </Box>
                                )}

                              {val.selectedLocation &&
                                val.selectedLocation.length > 0 && (
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                  >
                                    <Box
                                      justifyContent='flex-start'
                                      display='flex'
                                      flexGrow
                                      height={30}
                                      alignItems='center'
                                      px={1}
                                      width='40%'
                                    >
                                      {val.selectedLocation &&
                                        val.selectedLocation.length > 0 && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                            width='100%'
                                            flexGrow
                                            overflow='auto'
                                          >
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {titleCase(
                                                  val.selectedLocation
                                                    ?.map(
                                                      (e) => e.display ?? ''
                                                    )
                                                    .join(', ')
                                                )}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        )}
                                    </Box>
                                    <Box display='flex' width='2%'>
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                    <Box
                                      justifyContent='flex-start'
                                      display='flex'
                                      flexGrow
                                      height={30}
                                      alignItems='center'
                                      px={1}
                                      width='29%'
                                    >
                                      <Typography
                                        variant='subtitle2'
                                        style={{
                                          fontFamily: 'Open Sans',
                                          fontSize: 12,
                                          color: 'black',

                                          fontWeight: 'bold',
                                          lineHeight: 'normal',
                                        }}
                                      >
                                        Onset Duration
                                      </Typography>
                                    </Box>
                                    <Box display='flex' width='2%'>
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                    <Box
                                      justifyContent='flex-start'
                                      display='flex'
                                      flexGrow
                                      height={30}
                                      alignItems='center'
                                      px={1}
                                      width='27%'
                                    >
                                      <Typography
                                        variant='subtitle2'
                                        color='initial'
                                        style={{
                                          fontFamily: 'Open Sans',
                                          fontSize: 12,

                                          fontWeight: 600,
                                          lineHeight: 'normal',
                                        }}
                                      >
                                        {val.duration}
                                      </Typography>
                                    </Box>
                                  </Box>
                                )}
                              {val.selectedLocation &&
                                val.selectedLocation.length > 0 && (
                                  <Divider
                                    orientation='horizontal'
                                    flexItem
                                    style={{
                                      height: '2px',
                                    }}
                                  />
                                )}
                              {val.character.length > 0 && (
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                  style={{
                                    backgroundColor: kDialogueBackground,
                                  }}
                                >
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    width='100%'
                                    flexGrow
                                  >
                                    {val.character.length > 0 && (
                                      <OverflowTypography
                                        text={titleCase(val.character)}
                                        typographyProps={{
                                          variant: 'subtitle2',
                                          color: 'initial',
                                        }}
                                      />
                                    )}
                                    {val.character.length === 0 && (
                                      <Typography
                                        variant='subtitle2'
                                        color='initial'
                                        style={{
                                          fontFamily: 'Open Sans',
                                          fontSize: 12,

                                          fontWeight: 600,
                                          lineHeight: 'normal',
                                        }}
                                      >
                                        None
                                      </Typography>
                                    )}
                                  </Box>
                                </Box>
                              )}
                              {val.character.length > 0 && (
                                <Divider
                                  orientation='horizontal'
                                  flexItem
                                  style={{
                                    height: '2px',
                                  }}
                                />
                              )}
                              {val.alleviating.length > 0 && (
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={40}
                                  alignItems='center'
                                  px={1}
                                >
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    width='100%'
                                    flexGrow
                                  >
                                    {val.alleviating.length === 0 && (
                                      <Typography
                                        variant='subtitle2'
                                        color='initial'
                                        style={{
                                          fontFamily: 'Open Sans',
                                          fontSize: 12,

                                          fontWeight: 600,
                                          lineHeight: 'normal',
                                        }}
                                      >
                                        {t('labelCommon:nofactorsFound')}
                                      </Typography>
                                    )}

                                    {val.alleviating.length > 0 && (
                                      <OverflowTypography
                                        text={titleCase(val.alleviating)}
                                        typographyProps={{
                                          variant: 'subtitle2',
                                          color: 'initial',
                                        }}
                                      />
                                    )}
                                  </Box>
                                </Box>
                              )}
                              {val.alleviating.length > 0 && (
                                <Divider
                                  orientation='horizontal'
                                  flexItem
                                  style={{
                                    height: '2px',
                                  }}
                                />
                              )}
                              {val.radiation.length > 0 && (
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexDirection='column'
                                  flexGrow
                                  style={{
                                    backgroundColor: kDialogueBackground,
                                  }}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={40}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow
                                    >
                                      {val.radiation.length === 0 && (
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,

                                            fontWeight: 600,
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          None
                                        </Typography>
                                      )}
                                      {val.radiation.length > 0 && (
                                        <OverflowTypography
                                          text={titleCase(val.radiation)}
                                          typographyProps={{
                                            variant: 'subtitle2',
                                            color: 'initial',
                                          }}
                                        />
                                      )}
                                    </Box>
                                  </Box>

                                  <Divider
                                    orientation='horizontal'
                                    flexItem
                                    style={{
                                      height: '2px',
                                    }}
                                  />
                                </Box>
                              )}
                              {val.evidenceData && val.evidenceData.length > 0 && (
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexDirection='column'
                                  flexGrow
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    minHeight={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow
                                    >
                                      <Typography
                                        variant='subtitle2'
                                        color='initial'
                                        style={{
                                          fontFamily: 'Open Sans',
                                          fontSize: 12,

                                          fontWeight: 600,
                                          lineHeight: 'normal',
                                        }}
                                      >
                                        {titleCase(
                                          val.evidenceData
                                            ?.map((e) =>
                                              titleCase(e.synonyms ?? '')
                                            )
                                            .join(', ')
                                        )}
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Divider
                                    orientation='horizontal'
                                    flexItem
                                    style={{
                                      height: '2px',
                                    }}
                                  />
                                </Box>
                              )}
                              {val.temporal.length > 0 && (
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexDirection='column'
                                  flexGrow
                                  style={{
                                    backgroundColor:
                                      val.evidenceData &&
                                      val.evidenceData.length > 0
                                        ? kDialogueBackground
                                        : '#edf2fa',
                                  }}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={40}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow
                                    >
                                      {val.temporal.length > 0 && (
                                        <OverflowTypography
                                          text={titleCase(val.temporal)}
                                          typographyProps={{
                                            variant: 'subtitle2',
                                            color: 'initial',
                                          }}
                                        />
                                      )}
                                      {val.temporal.length === 0 && (
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,

                                            fontWeight: 600,
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          None
                                        </Typography>
                                      )}
                                    </Box>
                                  </Box>

                                  <Divider
                                    orientation='horizontal'
                                    flexItem
                                    style={{
                                      height: '2px',
                                    }}
                                  />
                                </Box>
                              )}
                              {val.selectedSeverity && (
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  style={{
                                    backgroundColor:
                                      val.temporal.length > 0 &&
                                      val.notes === undefined
                                        ? val.evidenceData &&
                                          val.evidenceData.length > 0
                                          ? '#edf2fa'
                                          : kDialogueBackground
                                        : '#edf2fa',
                                  }}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow
                                    >
                                      <Typography
                                        variant='subtitle2'
                                        color='initial'
                                        style={{
                                          fontFamily: 'Open Sans',
                                          fontSize: 12,

                                          fontWeight: 600,
                                          lineHeight: 'normal',
                                        }}
                                      >
                                        {titleCase(
                                          val.selectedSeverity.display ?? 'None'
                                        )}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  {val.notes && (
                                    <Divider
                                      orientation='horizontal'
                                      flexItem
                                      style={{
                                        height: '2px',
                                      }}
                                    />
                                  )}
                                </Box>
                              )}
                              {val.notes && (
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                  style={{
                                    backgroundColor: kDialogueBackground,
                                  }}
                                >
                                  {val.notes && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow
                                    >
                                      <OverflowTypography
                                        text={val.notes.text ?? ''}
                                        typographyProps={{
                                          variant: 'subtitle2',
                                          color: 'initial',
                                        }}
                                      />
                                    </Box>
                                  )}
                                </Box>
                              )}

                              {/* <Divider
                                        orientation='horizontal'
                                        flexItem
                                        style={{
                                          height: '2px',
                                        }}
                                      /> */}
                            </Box>
                            {/* <Box
                                      display='flex'
                                      flexGrow={1}
                                      justifyContent='flex-end'
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: 'auto',
                                          color: 'red',
                                          width: '2px',
                                        }}
                                      />
                                    </Box> */}
                          </Box>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          backgroundColor: '#edf2fa',
                        }}
                      >
                        {val.doshaObservation &&
                          ((val.doshaObservation.component &&
                            val.doshaObservation.component.length > 0) ||
                            getNotesString(
                              val.doshaObservation?.note ?? []
                            )) && (
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                            >
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                height={30}
                                alignItems='center'
                                alignContent='center'
                                borderRadius={2}
                                style={{
                                  backgroundColor: 'lightGrey',
                                }}
                                px={1}
                              >
                                <Typography
                                  variant='subtitle1'
                                  color='initial'
                                  style={{
                                    color: 'black',

                                    fontWeight: 'bold',
                                  }}
                                >
                                  {' '}
                                  Dosha
                                </Typography>
                              </Box>
                              <Divider
                                orientation='horizontal'
                                flexItem
                                style={{
                                  height: '2px',
                                }}
                              />
                              <Box
                                display='flex'
                                flexDirection='row'
                                height={30}
                                alignItems='center'
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Grid
                                  container
                                  direction='row'
                                  style={{
                                    height: '100%',
                                    margin: '0px',
                                    padding: '0px',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Vata
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation: val.doshaObservation,
                                              componentCode: 'ITA-2.1.1.1',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          {' '}
                                          Pitta
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',

                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation: val.doshaObservation,
                                              componentCode: 'ITA-2.1.2.1',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                height='30px'
                              >
                                <Grid
                                  container
                                  direction='row'
                                  style={{
                                    height: '100%',
                                    margin: '0px',

                                    padding: '0px',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Kapha
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation: val.doshaObservation,
                                              componentCode: 'ITA-2.1.3.1',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Remarks
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',

                                          paddingLeft: '8px',
                                        }}
                                      >
                                        {getNotesFromAllergy(
                                          val.doshaObservation?.note ?? []
                                        )?.map((eData) => (
                                          <Box
                                            key={eData}
                                            width='100%'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                          >
                                            <OverflowTypography
                                              text={eData ?? ''}
                                              typographyProps={{
                                                variant: 'body1',
                                                color: 'initial',
                                              }}
                                            />
                                          </Box>
                                        ))}
                                        {/* <Typography
                                                    variant='body1'
                                                    color='initial'
                                                  >
                                                    {getNotesFromAllergy(
                                                      val.doshaObservation
                                                        ?.note ?? []
                                                    ) ?? ''}
                                                  </Typography> */}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          backgroundColor: '#edf2fa',
                        }}
                      >
                        {val.dushyaObservation &&
                          ((val.dushyaObservation.component &&
                            val.dushyaObservation.component.length > 0) ||
                            getNotesString(
                              val.dushyaObservation?.note ?? []
                            )) && (
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                            >
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                height={30}
                                alignItems='center'
                                alignContent='center'
                                borderRadius={2}
                                style={{
                                  backgroundColor: 'lightGrey',
                                }}
                                px={1}
                              >
                                <Typography
                                  variant='subtitle1'
                                  color='initial'
                                  style={{
                                    color: 'black',

                                    fontWeight: 'bold',
                                  }}
                                >
                                  {' '}
                                  Dushya
                                </Typography>
                              </Box>
                              <Divider
                                orientation='horizontal'
                                flexItem
                                style={{
                                  height: '2px',
                                }}
                              />
                              <Box
                                display='flex'
                                flexDirection='row'
                                height={30}
                                alignItems='center'
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Grid
                                  container
                                  direction='row'
                                  style={{
                                    height: '100%',
                                    margin: '0px',
                                    padding: '0px',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Rasa
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation:
                                                val.dushyaObservation,
                                              componentCode: 'ITA-2.2.4',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          {' '}
                                          Rakta
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',

                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation:
                                                val.dushyaObservation,
                                              componentCode: 'ITA-2.2.5',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box
                                display='flex'
                                flexDirection='row'
                                height={30}
                                alignItems='center'
                              >
                                <Grid
                                  container
                                  direction='row'
                                  style={{
                                    height: '100%',
                                    margin: '0px',
                                    padding: '0px',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Mamsa
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation:
                                                val.dushyaObservation,
                                              componentCode: 'ITA-2.2.8',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Meda
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',

                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation:
                                                val.dushyaObservation,
                                              componentCode: 'ITA-2.2.9',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                              <Box
                                display='flex'
                                flexDirection='row'
                                height={30}
                                alignItems='center'
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Grid
                                  container
                                  direction='row'
                                  style={{
                                    height: '100%',
                                    margin: '0px',
                                    padding: '0px',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Asthi
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation:
                                                val.dushyaObservation,
                                              componentCode: 'ITA-2.2.10',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Majja
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',

                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation:
                                                val.dushyaObservation,
                                              componentCode: 'ITA-2.2.16',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                height='30px'
                              >
                                <Grid
                                  container
                                  direction='row'
                                  style={{
                                    height: '100%',
                                    margin: '0px',

                                    padding: '0px',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Shukra
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation:
                                                val.dushyaObservation,
                                              componentCode: 'ITA-2.2.19',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Remarks
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',

                                          paddingLeft: '8px',
                                        }}
                                      >
                                        {getNotesFromAllergy(
                                          val.dushyaObservation?.note ?? []
                                        )?.map((eData) => (
                                          <Box
                                            key={eData}
                                            width='100%'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                          >
                                            <OverflowTypography
                                              text={eData ?? ''}
                                              typographyProps={{
                                                variant: 'body1',
                                                color: 'initial',
                                              }}
                                            />
                                          </Box>
                                        ))}
                                        {/* <Typography
                                                    variant='body1'
                                                    color='initial'
                                                  >
                                                    {getNotesFromAllergy(
                                                      val.dushyaObservation
                                                        ?.note ?? []
                                                    ) ?? ''}
                                                  </Typography> */}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          )}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          backgroundColor: '#edf2fa',
                        }}
                      >
                        {val.dushyaObservation &&
                          ((val.dushyaObservation.component &&
                            val.dushyaObservation.component.length > 0) ||
                            getNotesString(
                              val.dushyaObservation?.note ?? []
                            )) && (
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                            >
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                height={30}
                                alignItems='center'
                                alignContent='center'
                                borderRadius={2}
                                style={{
                                  backgroundColor: 'lightGrey',
                                }}
                                px={1}
                              >
                                <Typography
                                  variant='subtitle1'
                                  color='initial'
                                  style={{
                                    color: 'black',

                                    fontWeight: 'bold',
                                  }}
                                >
                                  {' '}
                                  Dushya
                                </Typography>
                              </Box>
                              <Divider
                                orientation='horizontal'
                                flexItem
                                style={{
                                  height: '2px',
                                }}
                              />
                              <Box
                                display='flex'
                                flexDirection='row'
                                height={30}
                                alignItems='center'
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Grid
                                  container
                                  direction='row'
                                  style={{
                                    height: '100%',
                                    margin: '0px',
                                    padding: '0px',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Rasa
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation:
                                                val.dushyaObservation,
                                              componentCode: 'ITA-2.2.4',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          {' '}
                                          Rakta
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',

                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation:
                                                val.dushyaObservation,
                                              componentCode: 'ITA-2.2.5',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box
                                display='flex'
                                flexDirection='row'
                                height={30}
                                alignItems='center'
                              >
                                <Grid
                                  container
                                  direction='row'
                                  style={{
                                    height: '100%',
                                    margin: '0px',
                                    padding: '0px',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Mamsa
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation:
                                                val.dushyaObservation,
                                              componentCode: 'ITA-2.2.8',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Meda
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',

                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation:
                                                val.dushyaObservation,
                                              componentCode: 'ITA-2.2.9',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                              <Box
                                display='flex'
                                flexDirection='row'
                                height={30}
                                alignItems='center'
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Grid
                                  container
                                  direction='row'
                                  style={{
                                    height: '100%',
                                    margin: '0px',
                                    padding: '0px',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Asthi
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation:
                                                val.dushyaObservation,
                                              componentCode: 'ITA-2.2.10',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Majja
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',

                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation:
                                                val.dushyaObservation,
                                              componentCode: 'ITA-2.2.16',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                height='30px'
                              >
                                <Grid
                                  container
                                  direction='row'
                                  style={{
                                    height: '100%',
                                    margin: '0px',

                                    padding: '0px',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Shukra
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation:
                                                val.dushyaObservation,
                                              componentCode: 'ITA-2.2.19',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Remarks
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',

                                          paddingLeft: '8px',
                                        }}
                                      >
                                        {getNotesFromAllergy(
                                          val.dushyaObservation?.note ?? []
                                        )?.map((eData) => (
                                          <Box
                                            key={eData}
                                            width='100%'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                          >
                                            <OverflowTypography
                                              text={eData ?? ''}
                                              typographyProps={{
                                                variant: 'body1',
                                                color: 'initial',
                                              }}
                                            />
                                          </Box>
                                        ))}
                                        {/* <Typography
                                                    variant='body1'
                                                    color='initial'
                                                  >
                                                    {getNotesFromAllergy(
                                                      val.dushyaObservation
                                                        ?.note ?? []
                                                    ) ?? ''}
                                                  </Typography> */}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          backgroundColor: '#edf2fa',
                        }}
                      >
                        {val.malaObservation &&
                          ((val.malaObservation.component &&
                            val.malaObservation.component.length > 0) ||
                            getNotesString(
                              val.malaObservation?.note ?? []
                            )) && (
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                            >
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                height={30}
                                alignItems='center'
                                alignContent='center'
                                borderRadius={2}
                                style={{
                                  backgroundColor: 'lightGrey',
                                }}
                                px={1}
                              >
                                <Typography
                                  variant='subtitle1'
                                  color='initial'
                                  style={{
                                    color: 'black',

                                    fontWeight: 'bold',
                                  }}
                                >
                                  Mala
                                </Typography>
                              </Box>
                              <Divider
                                orientation='horizontal'
                                flexItem
                                style={{
                                  height: '2px',
                                }}
                              />
                              <Box
                                display='flex'
                                flexDirection='row'
                                height={30}
                                alignItems='center'
                                style={{
                                  backgroundColor: kDialogueBackground,
                                }}
                              >
                                <Grid
                                  container
                                  direction='row'
                                  style={{
                                    height: '100%',
                                    margin: '0px',
                                    padding: '0px',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Mutra
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation: val.malaObservation,
                                              componentCode: 'ITA-2.4.4',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Purisha
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',

                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation: val.malaObservation,
                                              componentCode: 'ITA-2.4.3',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                height='30px'
                              >
                                <Grid
                                  container
                                  direction='row'
                                  style={{
                                    height: '100%',
                                    margin: '0px',

                                    padding: '0px',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Sweda
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='body2'
                                          color='initial'
                                        >
                                          {getObservationValueCodeableConceptDisplayByComponent(
                                            {
                                              observation: val.malaObservation,
                                              componentCode: 'ITA-2.4.5',
                                            }
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      height: '100%',
                                      margin: '0px',

                                      padding: '0px',
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction='row'
                                      style={{
                                        height: '100%',
                                        margin: '0px',

                                        padding: '0px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',
                                          borderRight: `2px solid ${theme.palette.divider}`,
                                          paddingLeft: '8px',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          Remarks
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          height: '100%',
                                          margin: '0px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          padding: '0px',

                                          paddingLeft: '8px',
                                        }}
                                      >
                                        {getNotesFromAllergy(
                                          val.malaObservation?.note ?? []
                                        )?.map((eData) => (
                                          <Box
                                            key={eData}
                                            width='100%'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                          >
                                            <OverflowTypography
                                              text={eData ?? ''}
                                              typographyProps={{
                                                variant: 'body1',
                                                color: 'initial',
                                              }}
                                            />
                                          </Box>
                                        ))}
                                        {/* <Typography
                                                    variant='body1'
                                                    color='initial'
                                                  >
                                                    {getNotesFromAllergy(
                                                      val.malaObservation
                                                        ?.note ?? []
                                                    ) ?? ''}
                                                  </Typography> */}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          )}
                      </Grid>
                    </Grid>
                  </Box>
                </Collapse>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}
