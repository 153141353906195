import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListSubheader,
  Radio,
  RadioGroup,
  Typography,
  Tooltip,
  Link,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedOccupation } from 'models/groupedOccupations'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { requestGeneralExaminationOfPatient } from 'redux/consultation/generalExamination/generalExaminationSlice'
import {
  requestVitalDetailsOfPatient,
  resetVitalSearchState,
} from 'redux/consultation/vitalsDetailsSlice/vitalsDetailSlice'
import { resetAddGeneralExaminationState } from 'redux/fhirMedicalResources/addGeneralExam/addGeneralExamSlice'
import { resetAddVitalsState } from 'redux/fhirMedicalResources/addVital/addVitalSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import {
  getNotesFromAllergy,
  getNotesString,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import {
  getDateWiseVitals,
  getDateWiseVitalsForGeneralExam,
  getDateWiseVitalsForOthers,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { logger } from 'utils/logger'
import { GeneralExaminationTile } from 'views/components/consultation/objective/tiles/generalExaminationTile'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { VitalElement } from 'views/components/LeftMenu/VitalElement'
import { SOAPIndicativeElement } from 'wello-web-components'
import { GeneralExaminationTileHistory } from './generalExamTileHistory'

interface VitalsProp {
  generalExamList: GroupedOccupation[]
}

export const GeneralExaminationHistory: React.FC<VitalsProp> = ({
  generalExamList,
}: VitalsProp) => {
  const { t } = useTranslation(['common'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)

  const dispatch = useDispatch()
  const vitalsDetailsSlice = useSelector(
    (state: RootState) => state.generalExaminationSearchSlice
  )
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()

  return (
    <ReactVisibilitySensor
      offset={{
        top: 100,
        bottom: 30,
      }}
      onChange={(isVisible) => {
        logger.info('Inside diagnostic')
        if (isVisible) {
          dispatch(setSelectedSection({ selectedSection: 'vitals' }))
        }
      }}
    >
      <Grid container direction='column' style={{ flexWrap: 'nowrap' }}>
        <Grid item>
          <Grid container direction='row' id='vitals'>
            <Box py={1}>
              <Typography variant='h6'> General Examination </Typography>
            </Box>{' '}
            {/* {!split && (
                <IconButton
                  style={{ padding: '4px' }}
                  onClick={() => {
                    setOpenAddDialogue(true)
                  }}
                >
                  <Add style={{ height: '16px' }} color='primary' />
                </IconButton>
              )} */}
          </Grid>

          {generalExamList.length === 0 && (
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                No data available
              </Typography>
            </Grid>
          )}

          {generalExamList.length > 0 && (
            <Grid item style={{ padding: 0, margin: 0 }} xs={12}>
              <Box flexGrow width='100%' display='flex' flexDirection='column'>
                {generalExamList.map((val) => (
                  <Box py={1} key={val.date ?? ''}>
                    <Box
                      paddingX={1}
                      borderRadius={2}
                      style={{
                        backgroundColor: 'lightGrey',
                      }}
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      height={40}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow={1}
                        alignItems='center'
                      >
                        <Typography
                          variant='subtitle1'
                          style={{
                            color: 'black',

                            fontWeight: 'bold',
                          }}
                        >
                          Recorded on{' '}
                          {moment(val.date).format('Do MMM YYYY hh:mm A')}
                        </Typography>
                      </Box>
                    </Box>
                    <Grid
                      container
                      direction='row'
                      id='vitals'
                      style={{
                        backgroundColor: kDialogueBackground,
                      }}
                    >
                      <Grid item xs={3}>
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                              px={1}
                            >
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                Parameter Name
                              </Typography>
                            </Box>
                          </Box>
                          <Box display='flex' width='2%' paddingTop={0.1}>
                            <Divider
                              orientation='vertical'
                              flexItem
                              style={{
                                height: '30px',

                                width: '2px',
                                //   backgroundColor: 'white',
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                              px={1}
                            >
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                Value
                              </Typography>
                            </Box>
                          </Box>
                          <Box display='flex' width='2%' paddingTop={0.1}>
                            <Divider
                              orientation='vertical'
                              flexItem
                              style={{
                                height: '30px',

                                width: '2px',
                                //   backgroundColor: 'white',
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item key={1} xs={7}>
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                              px={1}
                            >
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',

                                  fontWeight: 'bold',
                                  lineHeight: 'normal',
                                }}
                              >
                                Remarks
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <GeneralExaminationTileHistory obsData={val.occupation} />
                  </Box>
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
        {showText && (
          <NoteDialog
            id='notesTile'
            open={showText}
            onClose={() => {
              setFullText('')
              setShowText(false)
            }}
            notesData={fullText!}
            dialogText='Remarks'
          />
        )}
      </Grid>
    </ReactVisibilitySensor>
  )
}
