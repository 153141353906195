import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import React from 'react'

interface Props {
  start: string
  payment: R4.IPaymentReconciliation
  patient: R4.IPatient
}

export const RefundTile: React.FC<Props> = ({
  start,
  payment,
  patient,
}: Props) => (
  <Box width='100%'>
    <Card
      elevation={0}
      style={{
        padding: 0,
        margin: 0,
      }}
    >
      <CardContent
        style={{
          padding: 8,
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <List
          id={`option_slot_${start}` ?? ''}
          style={{ flexGrow: 1, padding: 0 }}
        >
          <ListItem style={{ flexGrow: 1, padding: 0 }}>
            <ListItemText
              color='primary'
              primary={
                <Box py={0.25}>
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    style={{
                      fontWeight: 'bolder',
                      color: 'black',
                      wordWrap: 'break-word',
                    }}
                  >
                    Refund (
                    {payment.outcome === 'queued' ? 'In Progress' : 'Completed'}
                    )
                    {/* {`${getStartAndEndTimeWithDateInStringForOrder(
                      slot.start ?? ''
                    )}`} */}
                  </Typography>
                </Box>
              }
              secondary={
                <Box display='flex' flexDirection='column'>
                  <Box flex='flex' flexDirection='row' py={0.25}>
                    <Typography
                      component='span'
                      variant='body2'
                      color='textPrimary'
                    >
                      {`Amount:   ₹ ${payment.paymentAmount.value}`}
                    </Typography>
                  </Box>

                  {/* <Box flex='flex' flexDirection='row' py={0.25}>
                    <Link
                      component='button'
                      variant='body2'
                      onClick={() => {
                        console.info("I'm a button.")
                      }}
                    >
                      Change Agent
                    </Link>
                  </Box> */}
                </Box>
              }
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  </Box>
)
