import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { Package } from 'models/package'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import { getPackageData } from 'utils/common/catalogueDataConverter'
import { logger } from 'utils/logger'
import { PackageSearchStatus } from './packageSearchStatusTypes'

const initialState: PackageSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const packageSearchSlice = createSlice({
  name: 'packageSearchSlice',
  initialState,
  reducers: {
    searchingCatalogueDetails(
      state,
      action: PayloadAction<PackageSearchStatus>
    ) {},

    searchResults(state, action: PayloadAction<PackageSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.packageList = action.payload.packageList
    },

    noDataFoundForSearch(state, action: PayloadAction<PackageSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.packageList = action.payload.packageList
    },

    errorWhileSearching(state, action: PayloadAction<PackageSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.packageList = action.payload.packageList
    },
    resetState(state, action: PayloadAction<PackageSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.packageList = undefined
    },
  },
})

export const resetCatalogueSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: PackageSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      packageList: undefined,
    }
    dispatch(packageSearchSlice.actions.resetState(state))
  }

export const searchingCatalogueDetails =
  (searchString?: string, addPackage?: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: PackageSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(packageSearchSlice.actions.errorWhileSearching(errorSearchPatient))
    try {
      const unitDetails = getCurrentUserUnitReference()!
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _count: 100,
        _total: 'accurate',
        _include: '*',
        type: 'panel',
        status: 'active',
      }
      if (searchString) searchParameters.name = searchString

      const response: any = await fhirClient.doGetResource(
        `/PlanDefinition?plan-def-organization=Organization/${unitDetails.id}`,
        searchParameters
      )

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const catalogueResponses: R4.IBundle = relatedFhirDecodeRes.right
        if (catalogueResponses.total) {
          if (catalogueResponses.total > 0) {
            if (catalogueResponses.entry) {
              const fhirPackageData: Package[] = getPackageData(
                catalogueResponses,
                searchString ?? '',
                addPackage ?? false
              )

              const searchCatalogueResult: PackageSearchStatus = {
                error: false,
                noResultsAvailable: false,
                resultsAvailable: true,
                searching: false,
                packageList: fhirPackageData,

                totalCount: catalogueResponses.total,
              }
              dispatch(
                packageSearchSlice.actions.searchResults(searchCatalogueResult)
              )
              return
            }
          }
        }
        const noSearchResults: PackageSearchStatus = {
          error: false,
          noResultsAvailable: true,
          resultsAvailable: false,
          searching: false,
        }
        dispatch(
          packageSearchSlice.actions.noDataFoundForSearch(noSearchResults)
        )
        return
      }
      const errorWhileSearchCatalogue: PackageSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error while searching',
      }
      dispatch(
        packageSearchSlice.actions.errorWhileSearching(
          errorWhileSearchCatalogue
        )
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchCatalogue: PackageSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        packageSearchSlice.actions.errorWhileSearching(
          errorWhileSearchCatalogue
        )
      )
    }
  }

export default packageSearchSlice.reducer
