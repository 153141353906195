import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getLabOfferingDetailsFromBundle } from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { logger } from 'utils/logger'
import { MasterDataSearchStatus } from './masterDataSearchStatusType'

const initialState: MasterDataSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const masterDataSearchSlice = createSlice({
  name: 'masterDataSearchSlice',
  initialState,
  reducers: {
    searchingDoctorDetails(
      state,
      action: PayloadAction<MasterDataSearchStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false

      state.labOfferings = undefined
    },

    searchResults(state, action: PayloadAction<MasterDataSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true

      state.labOfferings = action.payload.labOfferings
    },

    noDataFoundForSearch(state, action: PayloadAction<MasterDataSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false

      state.labOfferings = undefined
    },

    errorWhileSearching(state, action: PayloadAction<MasterDataSearchStatus>) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false

      state.labOfferings = undefined
    },
    resetState(state, action: PayloadAction<MasterDataSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.labOfferings = undefined
      state.total = undefined
    },
  },
})

export const searchLabOfferings =
  (
    offSet: number,
    plaData: R4.IPlanDefinition[],
    searchString?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: MasterDataSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(masterDataSearchSlice.actions.searchingDoctorDetails(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        status: 'active',
        _count: 100,
      }
      //   if (searchString) searchParameters.title = searchString
      const response: any = await fhirClient.doGetResourceForMasterData(
        `/PlanDefinition?title:contains=${searchString ?? ''}`,
        searchParameters
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const labOfferingsResponse: R4.IBundle = relatedFhirDecodeRes.right
        if (labOfferingsResponse.total) {
          if (labOfferingsResponse.total > 0) {
            if (labOfferingsResponse.entry) {
              const practitionerWithRoleList: LabOfferingDetail[] =
                getLabOfferingDetailsFromBundle(
                  labOfferingsResponse,
                  plaData
                ) ?? []

              const plandefArry: R4.IPlanDefinition[] =
                practitionerWithRoleList.map(
                  (item) => item.planDefinition as R4.IPlanDefinition
                )

              //   const appendedArry = plaData.concat(plandefArry)

              const uniqueChars: R4.IPlanDefinition[] = []

              plandefArry.forEach((c) => {
                if (!uniqueChars.includes(c)) {
                  uniqueChars.push(c)
                }
              })
              uniqueChars.sort((a, b) =>
                (a.title ?? '') > (b.title ?? '')
                  ? 1
                  : (b.title ?? '') > (a.title ?? '')
                  ? -1
                  : 0
              )
              //   appendedArry.sort((a, b) => (a.title ? a.title:'').localeCompare((b.title ? b.title:'')))
              state.labOfferings = uniqueChars
              state.total = labOfferingsResponse.total
              state.resultsAvailable = true
              state.searching = false
              state.error = false
              dispatch(masterDataSearchSlice.actions.searchResults(state))
              return
            }
            state.resultsAvailable = false
            state.searching = false
            state.error = false
            state.noResultsAvailable = true
            dispatch(masterDataSearchSlice.actions.noDataFoundForSearch(state))
            return
          }
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(masterDataSearchSlice.actions.noDataFoundForSearch(state))
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(masterDataSearchSlice.actions.errorWhileSearching(state))
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(masterDataSearchSlice.actions.errorWhileSearching(state))
    }
  }

export const resetCatalogueSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: MasterDataSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      labOfferings: undefined,
      total: undefined,
    }
    dispatch(masterDataSearchSlice.actions.resetState(state))
  }

export function getPractitionerRoleObject(item: R4.IPractitionerRole) {
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    gender: '',
    name: item.practitioner?.display ?? '',
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: false,
  }
  return val
}

export default masterDataSearchSlice.reducer
