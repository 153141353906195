import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { requestForSchedules } from 'redux/schedule/searchSchedule/SearchProviderSchedule'
import { ScheduleRemoveStatus } from './removeScheduleStatusTypes'

const initialState: ScheduleRemoveStatus = {
  removing: false,
  deletionSuccessful: false,
  error: false,
  errorMessage: '',
}

const removeScheduleSlice = createSlice({
  name: 'Remove Schedule',
  initialState,
  reducers: {
    removingScheduleDetails(
      state,
      action: PayloadAction<ScheduleRemoveStatus>
    ) {
      state.removing = action.payload.removing
      state.deletionSuccessful = action.payload.deletionSuccessful
      state.error = action.payload.error
    },

    scheduleDetailsRemoved(state, action: PayloadAction<ScheduleRemoveStatus>) {
      state.removing = action.payload.removing
      state.deletionSuccessful = action.payload.deletionSuccessful
      state.error = action.payload.error
    },

    errorInRemovingScheduleDetails(
      state,
      action: PayloadAction<ScheduleRemoveStatus>
    ) {
      state.removing = action.payload.removing
      state.deletionSuccessful = action.payload.deletionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetScheduleDetails(state, action: PayloadAction<ScheduleRemoveStatus>) {
      state.removing = action.payload.removing
      state.deletionSuccessful = action.payload.deletionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },
  },
})

export const removeScheduleDetails =
  (
    scheduleBundle: R4.IBundle,
    selectedDoctor: any,
    scheduleTile: boolean,
    date: Date
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const deleteScheduleState: ScheduleRemoveStatus = {
      removing: true,
      deletionSuccessful: true,
      error: false,
    }
    dispatch(
      removeScheduleSlice.actions.removingScheduleDetails(deleteScheduleState)
    )
    try {
      let response: any | undefined
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      // eslint-disable-next-line prefer-const
      response = await fhirClient.doCreateFHIRTransaction('', scheduleBundle)
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        logger.info('Deleted schedule')
        logger.info(' Deleted Schedule dispatching')
        logger.info(response)
        const successRemoveState: ScheduleRemoveStatus = {
          removing: true,
          deletionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        if (scheduleTile === true) {
          dispatch(showSuccessAlert('Working hours deleted successfully'))
        }
        dispatch(requestForSchedules(selectedDoctor, date))
        dispatch(
          removeScheduleSlice.actions.scheduleDetailsRemoved(successRemoveState)
        )
        return
      }
      logger.info('Not Added Schedule')

      //   let errorCreatePersonState: ScheduleAddStatus = {
      //      adding: false,
      //      additionSuccessful: false,
      //      error: true,
      //      errorMessage: "Error while creating patient",
      //      schedule: scheduleResponse,
      //  };
      //   dispatch(
      //    addAvailabilitySlice.actions.scheduleDetailsAdded(errorCreatePersonState)
      //    );
      return
    } catch (error) {
      const errorCreateScheduleState: ScheduleRemoveStatus = {
        removing: false,
        deletionSuccessful: false,
        error: true,
        errorMessage: 'Error',
      }
      dispatch(
        removeScheduleSlice.actions.errorInRemovingScheduleDetails(
          errorCreateScheduleState
        )
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(removeScheduleSlice.actions.resetScheduleDetails(initialState))
}

async function getSlots(scheduleId: any): Promise<string[]> {
  const convertedSlots: string[] = []
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    schedule: `Schedule/${scheduleId}`,
  }
  const searchResponse: any = await fhirClient.doGetResource(
    '/Slot',
    searchParameters
  )
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(searchResponse)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const slotsResponse: R4.IBundle = relatedFhirDecodeRes.right
    if (slotsResponse.entry) {
      for (let i = 0; i < slotsResponse.entry.length; i++) {
        const slots: string = slotsResponse.entry[i].resource?.id || ''
        convertedSlots.push(slots)
      }
    }
  }

  return convertedSlots
}

export default removeScheduleSlice.reducer
