import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core'
import React from 'react'
import {
  getIdentifierValueBySystem,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getProfilePicPatient,
} from 'utils/fhirResourcesHelper'
import {
  //   getProfilePicPatient,
  getTelecomOfPatient,
} from 'wello-web-components'
import { GenderAndAgeTile } from './genderAndAgeTile'

interface Props {
  patient?: R4.IPatient
  hideAge?: boolean
  isSelected?: boolean
  onSelected?: (selectedOptions: string) => void
}

export const PatientTileSplit: React.FC<Props> = ({
  patient,
  hideAge = true,
  isSelected,
  onSelected,
}: Props) => (
  <Box width='100%'>
    {patient && (
      <Card
        elevation={0}
        style={{
          cursor: 'pointer',
        }}
        onClick={() => {
          if (patient && onSelected) {
            onSelected(patient.id ?? '')
          }
        }}
      >
        <CardContent style={{ padding: 4 }}>
          <List id='patientList_455' style={{ flexGrow: 1, padding: 0 }}>
            <ListItem style={{ flexGrow: 1, padding: 0 }}>
              <ListItemAvatar>
                <Avatar
                  alt={getNameOfPatient(patient) ?? ''}
                  src={getProfilePicPatient(patient) ?? ''}
                />
              </ListItemAvatar>
              <ListItemText
                color='primary'
                primary={
                  <>
                    <Box width='75%' id='patientName'>
                      <Typography
                        variant='subtitle1'
                        color='primary'
                        style={{
                          fontWeight: 'bolder',
                          color: 'black',
                          wordWrap: 'break-word',
                        }}
                        id='patientNameAnchor'
                      >
                        {`${getNameOfPatient(patient) ?? ''} ${
                          getMiddleNameOfPatient(patient) ?? ''
                        } ${getLastNameOfPatient(patient) ?? ''}`}
                      </Typography>
                    </Box>
                  </>
                }
                secondary={
                  <Box display='flex' flexDirection='column'>
                    <Typography
                      component='span'
                      variant='subtitle2'
                      color='textPrimary'
                    >
                      {getTelecomOfPatient(patient)}
                    </Typography>
                    {getIdentifierValueBySystem(
                      patient.identifier ?? [],
                      'labReference'
                    ) && (
                      <Typography variant='subtitle2' color='textPrimary'>
                        {`Ref. ${getIdentifierValueBySystem(
                          patient.identifier ?? [],
                          'labReference'
                        )}
                      `}
                      </Typography>
                    )}
                    <Typography variant='subtitle2' color='primary'>
                      {isSelected ? 'Primary Contact' : ''}
                    </Typography>
                  </Box>
                }
              />
              <ListItemSecondaryAction>
                {hideAge && (
                  <ListItemText
                    primary={
                      <GenderAndAgeTile patient={patient} status={false} />
                    }
                  />
                )}
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    )}
  </Box>
)
