/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-void */
import {
  Button,
  FormControl,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  Box,
  CircularProgress,
  Divider,
  TextField,
} from '@material-ui/core'
import { cloneDeep } from 'lodash'
import React, { useEffect, useState } from 'react'

import { useRecoilState, useRecoilValue } from 'recoil'

import { NumericFormat } from 'react-number-format'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { isAyurvedaDoctor, isOnlyDoctor } from 'services/userDetailsService'
import {
  cartOrderForCachedState,
  cartPatientObjectState,
  cartSkippedState,
  cartState,
} from '../../../../../../models/medicine-delivery/cart/cart-states'
import { getProductDetails } from '../../../../../../utils/medicineHelper/product-apis'
import {
  ProductDetail,
  Sku,
} from '../../../../../../models/medicine-delivery/product-models'
import { ProductSkus } from './product-skus'
import {
  appState,
  selectedProductIdState,
  selectSkippedState,
  viewState,
} from '../../../../../../models/medicine-delivery/product-states'
import { CartItem } from '../../../../../../models/medicine-delivery/cart/cart-models'
import { CartOrderFor } from '../cart_plan/cart-order-for'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
)

interface Props {
  onAddToCartCallback?: (product: ProductDetail) => void
  onSkip?: () => void
  onPatientCallBack?: (patient: R4.IPatient) => void
  doctorDetails: R4.IPractitioner
  isSoapNotes?: boolean
  medicationNames?: string[]
}

export const ProductDetails: React.FC<Props> = ({
  onAddToCartCallback,
  onSkip,
  onPatientCallBack,
  doctorDetails,
  isSoapNotes,
  medicationNames,
}: Props) => {
  //   const selectedProductId = useRecoilValue(selectedProductIdState)
  const [selectedProductId, setSelectedProductId] = useRecoilState<any>(
    selectedProductIdState
  )
  const [cart, setCart] = useRecoilState<any>(cartState)
  const [detail, setDetail] = useState<ProductDetail | undefined>() // useRecoilState(selectedProductDetailsState);
  const [loading, setLoading] = useRecoilState<any>(appState)
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [view, setView] = useRecoilState(viewState)
  const [quantity, setQuantity] = useState<any>(0)
  const [sku, setSku] = useState<Sku | undefined>()

  const [skippedStateData, setSkipedSateData] =
    useRecoilState<any>(selectSkippedState)

  const [skipped, setSkipped] = useRecoilState<string[]>(cartSkippedState)

  const classes = useStyles()
  const [orderFor, setOrderFor] = useRecoilState(cartOrderForCachedState)

  const [cartPatient, setCartPatient] = useRecoilState(cartPatientObjectState)

  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentIndexData, setCurrentIndexData] = useState(0)

  const [patientId, setPatientId] = useState<string>()

  useEffect(() => {
    if (medicationNames) {
      if (medicationNames.length === 1) {
        setSelectedProductId(medicationNames[currentIndex])
      } else if (
        medicationNames.length > 1 ||
        currentIndex + 1 < medicationNames.length
      ) {
        setDetail(undefined)
        setSelectedProductId(medicationNames[currentIndex])
      }
    }

    if (!selectedProductId) {
      if (
        (medicationNames && medicationNames.length === cart.items.length) ||
        (medicationNames && medicationNames.length === currentIndex)
      ) {
        setView('cart')
      }

      //   setDetail(undefined)
      return
    }
    void (async () => {
      setLoadingData(true)
      setLoading({ state: 'processing', message: 'fetching product details' })
      const details = await getProductDetails(selectedProductId)

      if (details) {
        setDetail(details)
        setLoadingData(false)
        setQuantity(0)
        setSku(undefined)

        setView('productDetails')
        setLoading({
          state: 'completed',
          message: 'Product details fetched successfully',
        })
      } else {
        setLoadingData(false)
        setQuantity(0)
        setSku(undefined)
        setView('productDetails')

        setLoading({
          state: 'completed',
          message: 'Product details fetched successfully',
        })
      }
    })()
  }, [selectedProductId])

  if (view !== 'productDetails') {
    return <></>
  }

  const onAddToCart = (product: ProductDetail) => {
    setCurrentIndex(currentIndex + 1)
    if (!sku || !quantity) {
      alert('Please select and sku')
      return
    }
    const cartItem = {
      customerDiscount: 0,
      product: {
        category: cloneDeep(product.category),
        description: product.description,
        name: product.name,
        sku,
        title: product.title,
        image: product.image,
      },
      quantity,
    } as CartItem
    const clone = cloneDeep(cart)
    // Remove this this item from collection
    const filtered = clone.items.filter((i: any) => i.product.sku.id !== sku.id)
    clone.items = [...filtered, cartItem]
    setCart(clone)
    setSelectedProductId(undefined)
    // if (skippedStateData) {
    //   setCurrentIndexData(currentIndexData + 1)
    // }
    onAddToCartCallback && onAddToCartCallback(product)
  }

  const onSkipData = () => {
    setCurrentIndex(currentIndex + 1)
    setSelectedProductId(undefined)
    if (onSkip) onSkip()
  }

  const renderDetail = () => {
    if (loading.state === 'processing') {
      return (
        <Box
          paddingLeft={3}
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='center'
        >
          <CircularProgress />
        </Box>
      )
    }

    // if (
    //   loading.state === 'completed' &&
    //   selectedProductId &&
    //   detail === undefined &&
    //   loading === false
    // ) {
    //   return (
    //     <Box width='100%' padding={0}>
    //       {isOnlyDoctor() && (
    //         <img
    //           src={`${process.env.PUBLIC_URL}/allopathy.png`}
    //           alt='ipd'
    //           height='90%'
    //         />
    //       )}

    //       {isAyurvedaDoctor() && (
    //         <img
    //           src={`${process.env.PUBLIC_URL}/emptyPage.png`}
    //           alt='ipd'
    //           height='90%'
    //         />
    //       )}
    //     </Box>
    //   )
    // }

    return (
      <Box
        width='100%'
        height='70%'
        style={{ backgroundColor: 'white', overflow: 'hidden' }}
        display='flex'
        flexDirection='row'
        paddingLeft={1}
      >
        {detail && (
          <Box width='100%' display='flex' flexDirection='row'>
            <Grid item xs={3}>
              <Box
                width='100%'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                paddingTop={10}
              >
                <Box
                  width='80%'
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-start'
                  height='100%'
                >
                  <Box
                    width='100%'
                    border={5}
                    borderRadius={1}
                    display='flex'
                    flexDirection='row'
                    justifyContent='center'
                    borderColor='#ececec'
                    p={1}
                  >
                    <img
                      src={detail.image}
                      width={200}
                      height={200}
                      alt='detail.title'
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid container item xs={8}>
              <Grid item xs={12}>
                <Box width='100%' paddingTop={4}>
                  <Typography variant='subtitle1'>
                    {detail.title.toUpperCase()}
                  </Typography>
                </Box>
                <Typography variant='subtitle2'>
                  {detail.category.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box width='90%' display='flex' flexDirection='column'>
                  <Box paddingTop={1} paddingBottom={1.5} width='100%'>
                    <Divider />
                  </Box>
                  <Typography variant='body2'> {detail.description}</Typography>
                  <Box paddingTop={1.5} paddingBottom={1.5} width='100%'>
                    <Divider />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box width='90%'>
                  <ProductSkus
                    selectedSku={sku}
                    detail={detail}
                    onChangeSkuCallback={setSku}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box width='100%' display='flex' flexDirection='column' py={1}>
                  <Box
                    width='25%'
                    border={2}
                    borderRadius={1}
                    display='flex'
                    flexDirection='column'
                    paddingRight={1}
                    paddingLeft={1}
                    paddingBottom={1}
                    borderColor='#ececec'
                  >
                    <WelloFormItemLabel title='Quantity' />
                    <TextField
                      size='small'
                      variant='outlined'
                      type='number'
                      placeholder='Quantity'
                      disabled={sku === undefined}
                      inputProps={{
                        step: '1',
                      }}
                      value={quantity}
                      onKeyPress={(e) => {
                        if (e.key === '.') {
                          e.preventDefault()
                        }
                      }}
                      onChange={(e) => {
                        const newValue = Number(e.target.value)
                        if (newValue <= 0) {
                          showErrorAlert('Quantity cannot be <= 0')
                        } else if (newValue > 0 && newValue <= 1000) {
                          setQuantity(Number(newValue ?? 0))
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box py={2}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => onAddToCart(detail)}
                    disabled={!sku || quantity === 0}
                  >
                    Add to Cart
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {loading.state === 'completed' &&
          detail === undefined &&
          loadingData === false && (
            <Box width='100%' display='flex' flexDirection='column'>
              <img
                src={`${process.env.PUBLIC_URL}/NotFound.jpg`}
                alt='ipd'
                height='100%'
              />

              <Grid container item xs={8}>
                <Grid item xs={6}>
                  <Box py={2}>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => onSkipData()}
                    >
                      Skip
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
      </Box>
    )
  }

  return (
    <Box width='100%'>
      <Grid container>
        <Grid item xs={12}>
          <CartOrderFor
            patientId={patientId ?? ''}
            doctorId={doctorDetails ? doctorDetails.id ?? '' : ''}
            doctor={doctorDetails}
            onPatientCallBack={(patient: R4.IPatient) => {
              setPatientId(patient.id ?? '')
              if (onPatientCallBack) onPatientCallBack(patient)
            }}
            isSoapNotes={isSoapNotes}
          />
        </Grid>
        {renderDetail()}
      </Grid>
    </Box>
  )
}
