import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  QuestionnaireStatusKind,
  Questionnaire_ItemTypeKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import _ from 'lodash'
import ImageFilter9 from 'material-ui/svg-icons/image/filter-9'
import { Settlement } from 'models/convinienceFeeDetails'
import { DietTiming } from 'models/dietSetting/dietTImeSettings'
import { MembershipDetails } from 'models/membershipDetails'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetSettlementSearch,
  SearchingSettlement,
} from 'redux/administration/payment-settings/settlement/settlementSearchSlice'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import {
  requestDietTiming,
  resetSearchDietSetting,
} from 'redux/appointments/dietTimeSearch/dietTimeSearchSlice'
import {
  requestQuestionForDoctor,
  resetSearchQuestion,
} from 'redux/appointments/questionSearch/questuionSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  isOnlyUnitAdmin,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import {
  addDietTimings,
  addQuestion,
  addSettlement,
  updateDietTimingsForDoctor,
  updateQuestion,
  updateSettlement,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import { ValueSetSelectMultipleWithSwitchForDietSetting } from 'views/components/common/MultipleDietTimeSettings'

// import EditTherapy from './editTherapy';

export interface MembershipSearchProps {
  onTap?: () => void
  onAdd?: () => void
}

export const SettlementFile: React.FC<MembershipSearchProps> = ({
  onTap,
  onAdd,
}) => {
  const settlementSearchSlice = useSelector(
    (state: RootState) => state.settlementSearchSlice
  )

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  const [selectedMembership, setselectedMembership] =
    useState<MembershipDetails>()
  const dispatch = useDispatch()

  const [showPopup, setShowPopup] = React.useState<boolean>(false)
  const [data, setData] = React.useState<Settlement>()

  const [codingData, setCodingData] = React.useState<DietTiming[]>([])
  const [existingQuestionData, setExistingQuestionData] =
    React.useState<R4.IList>()

  function updDateSettlementData(finalRes: R4.IBasic) {
    setUpdateStatus({ requesting: true })

    const oldList: R4.IBasic = {
      ...finalRes,
    }
    setLoading(true)
    if (data && data.settleMentType) {
      oldList.extension = [
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-business-settlement-ext',
          valueString: data.settleMentType,
        },
      ]
    }

    updateSettlement(oldList).then((e) => {
      if (e) {
        setLoading(false)
        setShowPopup(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Settlement details updated successfully'))
        dispatch(resetSettlementSearch())
        dispatch(SearchingSettlement())
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while updating diet timing details. Please try again later'
          )
        )
      }
    })
  }

  function addDietTime(codingArray: DietTiming[]) {
    setUpdateStatus({ requesting: true })
    const practRef: R4.IReference = {
      reference: `Practitioner/${getCurrentUserPractitionerDetails().id}`,
    }
    const oldProcedure: R4.IBasic = {
      resourceType: 'Basic',

      code: {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/basic-resource-type',
            code: 'predetreq',
            display: 'Pre-determination Request',
          },
        ],
      },
      author: {
        reference: `PractitionerRole/${
          getCurrentUserPractitionerRoleDetails().id
        }`,
      },
      subject: {
        reference: `Organization/${getCurrentUserMainOrganizationDetails().id}`,
      },
    }
    if (data && data.settleMentType) {
      oldProcedure.extension = [
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-business-settlement-ext',
          valueString: data.settleMentType,
        },
      ]
    }
    setLoading(true)

    addSettlement(oldProcedure).then((e) => {
      if (e) {
        setLoading(false)
        setShowPopup(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(
          showSuccessAlert('Payment settlement details added successfully')
        )
        dispatch(resetSettlementSearch())
        dispatch(SearchingSettlement())
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while adding diet details. Please try again later'
          )
        )
      }
    })
  }

  function checkSaveButton() {
    const trueData: string[] = []
    const falseData: string[] = []
    const dataNew: string[] = []
    if (data === undefined) {
      dataNew.push('1')
    }
    if (data) {
      if (data.settleMentType === undefined) {
        dataNew.push('1')
      }
    }

    if (settlementSearchSlice.referralList) {
      if (data) {
        if (
          data.settleMentType ===
          settlementSearchSlice.referralList.settleMentType
        ) {
          dataNew.push('1')
        }
      }
    }
    if (dataNew.length > 0) {
      return true
    }
    return false
  }

  //   function checkSaveButton() {
  //     console.log(codingData)
  //     const trueData: string[] = []
  //     const falseData: string[] = []
  //     const data: string[] = []
  //     if (codingData.length === 0) {
  //       data.push('1')
  //     }
  //     if (codingData.length > 0) {
  //       for (let i = 0; i < codingData.length; i++) {
  //         if (codingData[i].time === undefined && codingData[i].active === true) {
  //           data.push('1')
  //         } else {
  //           const codingDataVal: R4.ICoding | undefined = codingData[i].time
  //           if (codingDataVal && codingData[i].active === true) {
  //             const timeData = codingDataVal.display ?? ''
  //             if (timeData.length === 0) {
  //               data.push('1')
  //             }
  //           }
  //         }
  //         if (dietTImeSearchSlice.prSelectedCoding) {
  //           if (codingData[i].isEdit === true) {
  //             trueData.push('1')
  //           } else {
  //             falseData.push('1')
  //           }
  //         }
  //       }
  //     }

  //     if (trueData.length === 0) {
  //       data.push('1')
  //     }

  //     return data.length
  //   }

  useEffect(() => {
    dispatch(SearchingSettlement())
  }, [dispatch])

  useEffect(() => {
    if (
      settlementSearchSlice.resultsAvailable &&
      settlementSearchSlice.referralList
    )
      setData(settlementSearchSlice.referralList)
  }, [settlementSearchSlice])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        width: '100%',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          width: '100%',
        }}
      >
        <Box display='flex' flexDirection='column' width='100%'>
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            bgcolor={kDialogueBackground}
            py={4}
          >
            <Box
              width='100%'
              height={35}
              px={1}
              display='flex'
              flexDirection='column'
              justifyContent='center'
              style={{ backgroundColor: 'grey' }}
            >
              <Typography
                variant='h6'
                color='primary'
                style={{
                  marginTop: 8,
                  marginBottom: 8,
                  fontFamily: 'Open Sans',

                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Settlement
              </Typography>
            </Box>
          </Box>

          {settlementSearchSlice.searching && (
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
              height='100%'
              width='100%'
            >
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            </Box>
          )}
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignContent='center'
            overflow='auto'
            style={{
              backgroundColor: '#ececec',
            }}
            paddingLeft={1.5}
          >
            {settlementSearchSlice.noResultsAvailable && (
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                height={300}
                style={{ backgroundColor: 'white' }}
                px={1}

                // flexGrow={1}
              >
                <FormControl>
                  <RadioGroup
                    id='add_unit_address_choice_rad_group'
                    row
                    aria-label='graphType'
                    value={data ? data.settleMentType : ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setData({
                        ...data,
                        settleMentType: (event.target as HTMLInputElement)
                          .value,
                      })
                    }}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      py={2}
                    >
                      <FormControlLabel
                        value='single'
                        control={<Radio color='primary' />}
                        label={
                          <Typography variant='subtitle2'>
                            Weekly on every Monday
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value='multiple'
                        control={<Radio color='primary' />}
                        label={
                          <Typography variant='subtitle2'>
                            Biweekly on Monday
                          </Typography>
                        }
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>

                {data &&
                  data.settleMentType &&
                  data.settleMentType.length > 0 &&
                  loading === false && (
                    <Box
                      width='5%'
                      paddingLeft={0.5}
                      display='flex'
                      flexDirection='column'
                      justifyContent='flex-end'
                      height='100%'
                      paddingBottom={4}
                    >
                      <Button
                        variant='contained'
                        color='primary'
                        disabled={checkSaveButton()}
                        onClick={() => {
                          addDietTime(codingData)
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                  )}
              </Box>
            )}
            {settlementSearchSlice.errorMessage && (
              <Box
                //   display='flex'
                //   flexDirection='column'
                justifyContent='center'
                width='100%'
                height='100%'
                alignContent='center'
                paddingLeft={50}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container justifyContent='center'>
                      <Typography
                        variant='subtitle1'
                        color='error'
                        align='center'
                      >
                        Error while searching settlement settings.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            {settlementSearchSlice.referralList && data && (
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                height={300}
                style={{ backgroundColor: 'white' }}
                px={1}

                // flexGrow={1}
              >
                <FormControl>
                  <RadioGroup
                    id='add_unit_address_choice_rad_group'
                    row
                    aria-label='graphType'
                    value={data ? data.settleMentType : ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setData({
                        ...data,
                        settleMentType: (event.target as HTMLInputElement)
                          .value,
                      })
                    }}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      py={2}
                    >
                      <FormControlLabel
                        value='single'
                        control={<Radio color='primary' />}
                        label={
                          <Typography variant='subtitle2'>
                            Weekly on every Monday
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value='multiple'
                        control={<Radio color='primary' />}
                        label={
                          <Typography variant='subtitle2'>
                            Biweekly on Monday
                          </Typography>
                        }
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
                <Box
                  width='20%'
                  paddingLeft={0.5}
                  display='flex'
                  flexDirection='row'
                  paddingTop={20}
                >
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={checkSaveButton()}
                    onClick={() => {
                      if (data && data.settlementRes) {
                        updDateSettlementData(data.settlementRes)
                      }
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      dispatch(resetSettlementSearch())
                      dispatch(SearchingSettlement())
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    </div>
  )
}
