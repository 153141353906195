import { R4 } from '@ahryman40k/ts-fhir-types'
import axios, { AxiosResponse, CancelToken } from 'axios'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import QueryString from 'qs'
import { getErrorResponse } from 'utils/apiClientHelper'
import { logger } from 'utils/logger'
import { getVendorPartId } from 'utils/routes_helper'
import { ApiClient } from './apiService'
import { ApiClinetWithMasterToken } from './apiServiceWithoutToken'

export class FHIRDefaultApiClient extends ApiClinetWithMasterToken {
  constructor() {
    const vendorId: string | null = getVendorPartId()
    const finalUrl = `${process.env.REACT_APP_DEFAULT_URL ?? ''}/`
    super(finalUrl)
  }

  public async doCreateFHIRResourceRequest(
    path: string,
    body: any
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        path,
        body,
        {
          headers: {},
        }
      )
      if (response.status === 201) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceReport(
    path: string,
    searchParameters?: {},
    controller?: CancelToken
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        cancelToken: controller,
        headers: {
          category:
            'http://terminology.hl7.org/ValueSet/v3-PurposeOfUse#HMARKT',
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doCreateFHIRTransactionForUpdateDefault(
    path: string,
    body: any
  ): Promise<R4.IBundle | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        path,
        body,
        {
          headers: {},
        }
      )
      if (response.status === 201 || response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doUpdateFHIRResourceRequest(
    path: string,
    body: R4.IResource,
    currentVersion: string
  ): Promise<any | FHIRErrorResponses> {
    try {
      logger.info('Updating')
      logger.info(body)
      const response: AxiosResponse = await this.axiosInstance.put(path, body, {
        headers: {
          'If-Match': `W/${JSON.stringify(currentVersion)}`,
        },
      })
      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doCreateFHIRTransaction(
    path: string,
    body: any
  ): Promise<R4.IBundle | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        path,
        body,
        {
          headers: {},
        }
      )
      if (response.status === 201 || response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doPostFHIRForFileDownLoad(
    path: string,
    body: any
  ): Promise<Blob | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        path,
        body,
        {
          responseType: 'arraybuffer',
          headers: { Accept: 'application/pdf' },
        }
      )
      if (response.status === 201 || response.status === 200) {
        return new Blob([response.data], {
          type: response.headers!['content-type'],
        })
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResource(
    path: string,
    searchParameters?: {},
    controller?: CancelToken
  ): Promise<any | FHIRErrorResponses | undefined> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        cancelToken: controller,
        headers: {
          category:
            'http://terminology.hl7.org/ValueSet/v3-PurposeOfUse#HMARKT',
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        logger.error(error)
        throw error
      }
    }
    return undefined
  }

  public async doGetFileResource(
    path: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        headers: {
          responseType: 'arrayBuffer',
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceCrossPartition(
    path: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        headers: {
          //   'x-partition-name': '*',
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doReportGetResource(
    path: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        headers: {
          category: 'TREAT',
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceForMasterData(path: string, searchParameters?: {}) {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        baseURL: process.env.REACT_APP_MASTER_BASE_URL,
        params: searchParameters,
      })

      return response.data
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceForMasterDataForValuset(
    path: string,
    searchParameters?: {}
  ) {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        baseURL: process.env.REACT_APP_MASTER_BASE_URL_ValueSet,
        params: searchParameters,
      })

      return response.data
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceIncludeAndIncludeIterate(
    path: string,
    searchParameters?: {},
    controller?: CancelToken
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        paramsSerializer(pars) {
          return QueryString.stringify(pars, { arrayFormat: 'repeat' })
        },
        cancelToken: controller,
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doDeleteResource(
    path: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.delete(path, {
        params: searchParameters,
        // baseURL: process.env.REACT_APP_FHIR_BASE_URL,
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doCreateFHIRUploadResourceRequest(
    path: string,
    body: any,
    header: string
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        path,
        body,
        {
          headers: {
            'Content-Type': `${header}`,
          },
        }
      )
      if (response.status === 201) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doCreateFHIRFinishResourceRequest(
    path: string,
    body: any,
    header: string
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        path,
        body,
        {
          headers: {
            'Content-Type': `${header}`,
          },
        }
      )
      if (response.status === 201) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceForAppointment(
    path: string,
    appointmentId: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        headers: {
          'x-appointment-id': `Appointment/${appointmentId}`,
          category:
            'http://terminology.hl7.org/ValueSet/v3-PurposeOfUse#HMARKT',
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceForAppointmentIPD(
    path: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        headers: {
          category:
            'http://terminology.hl7.org/ValueSet/v3-PurposeOfUse#HMARKT',
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceForAppointmentCrossSearch(
    path: string,
    appointmentId: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        headers: {
          category:
            'http://terminology.hl7.org/ValueSet/v3-PurposeOfUse#HMARKT',
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceForAppointmentCrossSearchWithoutAppointment(
    path: string,
    appointmentId: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        headers: {
          category:
            'http://terminology.hl7.org/ValueSet/v3-PurposeOfUse#HMARKT',
          'X-Partition-Name': '*',
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceForAppointmentWithIncludeIterate(
    path: string,
    appointmentId: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        paramsSerializer(pars) {
          return QueryString.stringify(pars, { arrayFormat: 'repeat' })
        },
        headers: {
          'x-appointment-id': `Appointment/${appointmentId}`,
          category:
            'http://terminology.hl7.org/ValueSet/v3-PurposeOfUse#HMARKT',
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceForAppointmentWithIncludeIterate1(
    path: string,
    appointmentReference: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        paramsSerializer(pars) {
          return QueryString.stringify(pars, { arrayFormat: 'repeat' })
        },
        headers: {
          'x-appointment-id': appointmentReference,
          category:
            'http://terminology.hl7.org/ValueSet/v3-PurposeOfUse#HMARKT',
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
    path: string,
    appointmentId: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        paramsSerializer(pars) {
          return QueryString.stringify(pars, { arrayFormat: 'repeat' })
        },
        headers: {
          'x-appointment-id': appointmentId.toLowerCase().includes('/')
            ? appointmentId
            : `Appointment/${appointmentId}`,
          category:
            'http://terminology.hl7.org/ValueSet/v3-PurposeOfUse#HMARKT',
          'X-Partition-Name': '*',
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResourceForAppointmentWithIncludeIterateCrossPlatformForReport(
    path: string,
    appointmentId: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
        paramsSerializer(pars) {
          return QueryString.stringify(pars, { arrayFormat: 'repeat' })
        },
        headers: {
          'x-appointment-id': `Appointment/${appointmentId}`,
          category:
            'http://terminology.hl7.org/ValueSet/v3-PurposeOfUse#HMARKT',
        },
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }
}

export const cancelTokenStore = new Map()
