/* eslint-disable @typescript-eslint/no-unused-vars */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { responsiveFontSizes } from '@material-ui/core'
import { Allopathy } from 'models/allopathtModel'
import { Ayush } from 'models/ayushDrugModel'
import { Chart } from 'models/bpMeasurements'
import { DrugModel } from 'models/drugModel'
import {
  ConnectedLines,
  GraphDataForOutCome,
  InterventionData,
  InterVentionGroupData,
  InterVentions,
  OutComes,
  Series,
} from 'models/evidenceGraph/interventions'
import { DiscreteColorLegend } from 'react-vis'
import { GraphDataSet } from 'models/graphDataSet'
import moment from 'moment'
import { cToF } from 'utils/appointment_handle/vitals_util'
import { SankeyData } from 'models/sankeyData'
import { datas } from 'utils/constants'
import { SankeyNode } from 'models/sankeyNode'
import { SummaryDataWithCount } from 'models/cohortReportMultiSeriesData'

function randomRgbColor() {
  const r = randomInteger(255)
  const g = randomInteger(255)
  const b = randomInteger(255)
  return [r, g, b]
}

function randomInteger(max: number) {
  return Math.floor(Math.random() * (max + 1))
}

function randomHexColor() {
  const [r, g, b] = randomRgbColor()

  const hr = r.toString(16).padStart(2, '0')
  const hg = g.toString(16).padStart(2, '0')
  const hbc = b.toString(16).padStart(2, '0')
  return `#${hr}${hg}${hbc}`
}

export function getGraphData(response: any): GraphDataSet {
  const bpChart: Chart[] = []
  const spChart: Chart[] = []
  const bmChart: Chart[] = []
  const hbChart: Chart[] = []
  const carePlanModel: Chart[] = []
  const drugdDataModel: DrugModel[] = []
  const drugEraDataModel: DrugModel[] = []
  const AllopathyDrugdDataModel: DrugModel[] = []
  let allopathyDiff: number = 0
  let ayushDiff: number = 0
  let drugEraCount: number = 0

  const otherMeasure = response.measurements.sort((a: any, b: any) => {
    const da = new Date(a.xDate)
    const db = new Date(b.xDate)
    return Number(da) - Number(db)
  })

  otherMeasure.forEach((data: any) => {
    if (data.seriesName === 'Diastolic blood pressure') {
      const chart = {
        x: moment(data.xDate).format('YYYY-MM-DD'),
        y: data.yValue,
      }
      bpChart.push(chart)
    }
    if (data.seriesName === 'Systolic blood pressure') {
      const chart = {
        x: moment(data.xDate).format('YYYY-MM-DD'),
        y: data.yValue,
      }
      spChart.push(chart)
    }
    if (data.seriesName === 'Body mass index (BMI) [Ratio]') {
      const chart = {
        x: moment(data.xDate).format('YYYY-MM-DD'),
        y: data.yValue,
      }
      bmChart.push(chart)
    }
    if (data.seriesName === 'Hemoglobin A1c/Hemoglobin.total in Blood') {
      const chart = {
        x: moment(data.xDate).format('YYYY-MM-DD'),
        y: data.yValue,
      }
      hbChart.push(chart)
    }
  })
  if (response.plans.length > 0) {
    const carePlanData: Chart = {
      x: moment(response.plans[0].startDate).format('YYYY-MM-DD'),
      y: 0,
    }
    carePlanModel.push(carePlanData)
    bpChart.push(carePlanData)
    bmChart.push(carePlanData)
    hbChart.push(carePlanData)
  }
  const sortedBp = bpChart.sort((a, b) => {
    const da = new Date(a.x)
    const db = new Date(b.x)
    return Number(da) - Number(db)
  })

  const convertedBp: Chart[] = []
  sortedBp.forEach((data: Chart) => {
    const bpChartData: Chart = {
      x: moment(data.x, 'YYYY-MM-DD').format('DD-MM-YYYY'),
      y: data.y,
    }
    convertedBp.push(bpChartData)
  })

  const spChartSorted = spChart.sort((a, b) => {
    const da = new Date(a.x)
    const db = new Date(b.x)
    return Number(da) - Number(db)
  })
  const convertedSpChart: Chart[] = []

  spChartSorted.forEach((data: Chart) => {
    const spChartData: Chart = {
      x: moment(data.x, 'YYYY-MM-DD').format('DD-MM-YYYY'),
      y: data.y,
    }
    convertedSpChart.push(spChartData)
  })

  const bmiChartSorted = bmChart.sort((a, b) => {
    const da = new Date(a.x)
    const db = new Date(b.x)
    return Number(da) - Number(db)
  })

  const bmiConverted: Chart[] = []

  bmiChartSorted.forEach((data: Chart) => {
    const bmiChartData: Chart = {
      x: moment(data.x, 'YYYY-MM-DD').format('DD-MM-YYYY'),
      y: data.y,
    }
    bmiConverted.push(bmiChartData)
  })

  const hb1cChartSorted = hbChart.sort((a, b) => {
    const da = new Date(a.x)
    const db = new Date(b.x)
    return Number(da) - Number(db)
  })

  const hb1cConverted: Chart[] = []

  hb1cChartSorted.forEach((data: Chart) => {
    const hbchartData: Chart = {
      x: moment(data.x, 'YYYY-MM-DD').format('DD-MM-YYYY'),
      y: data.y,
    }
    hb1cConverted.push(hbchartData)
  })

  if (response.drugEra.length > 0) {
    const drugEra = response.drugEra.sort((a: any, b: any) => {
      const da = new Date(a.drugEraStartDate)
      const db = new Date(b.drugEraStartDate)
      return Number(da) - Number(db)
    })
    const drugEraStartDate = moment(
      moment(drugEra[0].drugEraStartDate).format('DD-MM-YYYY'),
      'DD-MM-YYYY'
    )

    const eraPlanStartDate = moment(
      moment(
        response.plans.length > 0 ? response.plans[0].startDate : ''
      ).format('DD-MM-YYYY'),
      'DD-MM-YYYY'
    )
    drugEraCount = eraPlanStartDate.diff(drugEraStartDate, 'days')

    drugEra.forEach((data: any, index: number) => {
      const alopathyChart: Chart[] = []
      const actualStart = moment(
        moment(data.drugEraStartDate).format('DD-MM-YYYY'),
        'DD-MM-YYYY'
      )
      const actualEnd = moment(
        moment(data.drugEraEndDate).format('DD-MM-YYYY'),
        'DD-MM-YYYY'
      )

      const chart = {
        x: index === 0 ? 0 : actualStart.diff(drugEraStartDate, 'days'),
        y: index,
        z: data.drugName,
        startDate: data.drugEraStartDate,
      }
      const chart1 = {
        x: actualEnd.diff(drugEraStartDate, 'days'),
        y: index,
        z: data.drugName,
        startDate: data.drugEraEndDate,
      }
      alopathyChart.push(chart)
      alopathyChart.push(chart1)
      const drugDataChart: DrugModel = {
        drugs: alopathyChart,
        slNo: index,
        color: randomHexColor(),
      }
      drugEraDataModel.push(drugDataChart)
    })
  }
  if (response.drugExp.length > 0) {
    const measure = response.drugExp.sort((a: Allopathy, b: Allopathy) => {
      const da = new Date(a.drugExposureStartDate)
      const db = new Date(b.drugExposureStartDate)
      return Number(da) - Number(db)
    })
    const ayush: Ayush[] = measure.filter((d: any) =>
      d.drugGroup.includes('AYUSH drugs')
    )
    const ayushForDrug: Ayush = {
      drugExposureStartDate:
        response.plans.length > 0 ? response.plans[0].startDate : new Date(),
      drugExposureEndDate: '',
      drugName: '',
      drugCode: response.plans[0].name,
      drugVocabulary: '',
      drugGroup: '',
    }
    ayush.push(ayushForDrug)
    const ayushSorted = ayush.sort((a, b) => {
      const da = new Date(a.drugExposureStartDate)
      const db = new Date(b.drugExposureStartDate)
      return Number(da) - Number(db)
    })
    const allopathyData: Allopathy[] = measure.filter((d: any) =>
      d.ancestorVocabulary?.includes('ATC')
    )
    const carePlanDataforDrug: Allopathy = {
      drugExposureStartDate:
        response.plans.length > 0 ? response.plans[0].startDate : '',
      drugExposureEndDate: '',
      drugName: '',
      drugCode: response.plans.length > 0 ? response.plans[0].name : '',
      drugVocabulary: '',
      drugGroup: '',
      ancestorName: '',
      ancestorCode: '',
      ancestorVocabulary: '',
    }
    allopathyData.push(carePlanDataforDrug)
    const allopathySorted = allopathyData.sort((a, b) => {
      const da = new Date(a.drugExposureStartDate)
      const db = new Date(b.drugExposureStartDate)
      return Number(da) - Number(db)
    })
    const allopathyStartDate = moment(
      moment(allopathySorted[0].drugExposureStartDate).format('DD-MM-YYYY'),
      'DD-MM-YYYY'
    )
    const allopathyPlanStartDate = moment(
      moment(
        response.plans.length > 0 ? response.plans[0].startDate : ''
      ).format('DD-MM-YYYY'),
      'DD-MM-YYYY'
    )
    allopathyDiff = allopathyPlanStartDate.diff(allopathyStartDate, 'days')

    allopathySorted.forEach((data, index) => {
      const alopathyChart: Chart[] = []
      const actualStart = moment(
        moment(data.drugExposureStartDate).format('DD-MM-YYYY'),
        'DD-MM-YYYY'
      )

      const chart = {
        x: index === 0 ? 0 : actualStart.diff(allopathyStartDate, 'days'),
        y:
          data.drugCode.trim() === 'Wellopathy Platinum Subscription(Allopathy)'
            ? -1
            : index,
        z: data.drugName,
        startDate: data.drugExposureStartDate,
      }
      alopathyChart.push(chart)
      if (data.drugExposureEndDate.length > 0) {
        const actualEnd = moment(
          moment(data.drugExposureEndDate).format('DD-MM-YYYY'),
          'DD-MM-YYYY'
        )
        const chart1 = {
          x: actualEnd.diff(allopathyStartDate, 'days'),
          y: index,
          z: data.drugName,
          startDate: data.drugExposureEndDate,
        }
        alopathyChart.push(chart1)
      }
      const drugDataChartAlloPathy: DrugModel = {
        drugs: alopathyChart,
        slNo: index,
        color: randomHexColor(),
      }
      AllopathyDrugdDataModel.push(drugDataChartAlloPathy)
    })

    const startDate = moment(
      moment(ayushSorted[0].drugExposureStartDate).format('DD-MM-YYYY'),
      'DD-MM-YYYY'
    )

    ayushDiff = allopathyPlanStartDate.diff(startDate, 'days')

    ayushSorted.forEach((data, index) => {
      const alopathyChart: Chart[] = []
      const actualStart = moment(
        moment(data.drugExposureStartDate).format('DD-MM-YYYY'),
        'DD-MM-YYYY'
      )

      const chart = {
        x: index === 0 ? 0 : actualStart.diff(startDate, 'days'),
        y:
          data.drugCode.trim() === 'Wellopathy Platinum Subscription(Allopathy)'
            ? -1
            : index,
        z: data.drugName,
        startDate: data.drugExposureStartDate,
      }
      alopathyChart.push(chart)
      if (data.drugExposureEndDate.length > 0) {
        const actualEnd = moment(
          moment(data.drugExposureEndDate).format('DD-MM-YYYY'),
          'DD-MM-YYYY'
        )
        const chart1 = {
          x: actualEnd.diff(startDate, 'days'),
          y: index,
          z: data.drugName,
          startDate: data.drugExposureEndDate,
        }
        alopathyChart.push(chart1)
      }

      const drugDataChart: DrugModel = {
        drugs: alopathyChart,
        slNo: index,
        color: randomHexColor(),
      }
      drugdDataModel.push(drugDataChart)
    })
  }

  const convertedGraphData: GraphDataSet = {
    dp: convertedBp,
    sp: convertedSpChart,
    bmi: bmiConverted,
    hb: hb1cConverted,
    drugAllopathy: AllopathyDrugdDataModel,
    drugAyush: drugdDataModel,
    drugEraData: drugEraDataModel,
    planName: response.plans.length > 0 ? response.plans[0].name : '',
    startDate: moment(
      response.plans.length > 0 ? response.plans[0].startDate : ''
    ).format('DD-MM-YYYY'),
    endDate: moment(
      response.plans.length > 0 ? response.plans[0].endDate : ''
    ).format('DD-MM-YYYY'),
    planDateDiffAlloPathy: allopathyDiff,
    planDateDiffForAyush: ayushDiff,
    drugEraCount,
  }

  return convertedGraphData
}

export function getInterVention(response: any): any {
  const interVentions: string[] = []
  let uniqueArray: string[] = []
  for (let i = 0; i < response.result.metadata.features.length; i++) {
    const labelData = response.result.metadata.features[i].labels.filter(
      (d: any) => d.value.includes('interventions')
    )
    if (labelData.length > 0) {
      interVentions.push(response.result.metadata.features[i].feature_id)
    }
  }
  if (interVentions.length > 0)
    uniqueArray = interVentions.filter(
      (val: any, ind: any, arr: any) => arr.indexOf(val) === ind
    )
  return uniqueArray
}

// export function getOutCome(response: any): any {
//   const interVentions: string[] = []
//   for (let i = 0; i < graphResponseData.metadata.features.length; i++) {
//     const labelData = graphResponseData.metadata.features[i].labels.filter(
//       (d: any) => d.value.includes('outcomes')
//     )
//     if (labelData.length > 0) {
//       interVentions.push(graphResponseData.metadata.features[i].feature_id)
//     }
//   }
//   return interVentions
// }

export function getInterVentionSData(
  interVentionsData: string[],
  response: any
): InterVentions[] {
  const finalInterVentionData: InterVentions[] = []
  if (interVentionsData.length > 0) {
    for (let i = 0; i < interVentionsData.length; i++) {
      for (let j = 0; j < response.result.data.length; j++) {
        if (interVentionsData[i] === response.result.data[j].feature_id) {
          const data: string | null =
            response.result.data[j].valueCodeableConcept!.coding[0].display ??
            ''

          if (data !== null) {
            if (response.result.data[j].effectivePeriod) {
              const interventionObjWithStart: InterVentions = {
                groupId: interVentionsData[i],
                id: j,
                showLine: interVentionsData[i] === 'visit_service_req',
                item:
                  data === 'inpatient encounter'
                    ? 'IPD Visit'
                    : data === 'tlc_procedure'
                    ? 'Therapies'
                    : data,
                effectivePeriod:
                  response.result.data[j].effectivePeriod ?? undefined,
                color: randomHexColor(),
                fill: randomHexColor(),
              }
              finalInterVentionData.push(interventionObjWithStart)
            } else if (
              response.result.data[j].effectiveDateTime ||
              response.result.data[j].effectiveDateTime !== null
            ) {
              const interventionObj: InterVentions = {
                groupId: interVentionsData[i],
                item: data,
                showLine: interVentionsData[i] === 'visit_service_req',
                id: j,
                effectiveDateTime:
                  response.result.data[j].effectiveDateTime ?? '',
                color: randomHexColor(),
                fill: randomHexColor(),
              }
              finalInterVentionData.push(interventionObj)
            }
          }
        }
      }
    }
  }

  return finalInterVentionData
}

export function getInterVentionStringArr(
  interVentionsData: string[],
  response: any
): InterVentionGroupData[] {
  const finalInterVentionData: InterVentionGroupData[] = []
  if (interVentionsData.length > 0) {
    for (let i = 0; i < interVentionsData.length; i++) {
      for (let j = 0; j < response.result.data.length; j++) {
        if (interVentionsData[i] === response.result.data[j].feature_id) {
          const data: string | null =
            response.result.data[j].valueCodeableConcept!.coding[0].display ??
            ''

          if (data !== null) {
            if (response.result.data[j].effectivePeriod) {
              if (
                interVentionsData[i] !== 'tlc_therapy_service_req' &&
                interVentionsData[i] !== 'visit'
              ) {
                const interventionObjWithStart: InterVentionGroupData = {
                  groupId:
                    interVentionsData[i] === 'tlc_procedure'
                      ? 'Therapies'
                      : interVentionsData[i],
                  label:
                    interVentionsData[i] === 'tlc_procedure'
                      ? 'Therapies'
                      : interVentionsData[i] === 'visit_service_req'
                      ? 'Visit'
                      : interVentionsData[i],
                  id: j,
                  item:
                    data === 'inpatient encounter'
                      ? 'inpatient encounter'
                      : data,
                }
                finalInterVentionData.push(interventionObjWithStart)
              }
            } else if (
              response.result.data[j].effectiveDateTime ||
              response.result.data[j].effectiveDateTime !== null
            ) {
              if (
                interVentionsData[i] !== 'tlc_therapy_service_req' &&
                interVentionsData[i] !== 'visit_service_req'
              ) {
                const interventionObj: InterVentionGroupData = {
                  groupId: interVentionsData[i],
                  label: interVentionsData[i],
                  item: data,
                  id: j,
                }
                finalInterVentionData.push(interventionObj)
              }
            }
          }
        }
      }
    }
  }
  const resArr: InterVentionGroupData[] = []
  finalInterVentionData.filter((item: InterVentionGroupData) => {
    const i = resArr.findIndex((x) => x.item === item.item)
    if (i <= -1) {
      resArr.push(item)
    }
    return null
  })
  //    => (a.item! > b.item! ? 1 : b.item! > a.item! ? -1 : 0))
  //   )
  return resArr.sort((a, b) =>
    a.item! > b.item! ? 1 : b.item! > a.item! ? -1 : 0
  )
}

export function getSelectedInterVentionData(
  selectedData: InterVentionGroupData[],
  filteredData: InterventionData[]
): InterVentions[] {
  const finalOutCome: InterVentions[] = []

  for (let j = 0; j < selectedData.length; j++) {
    const labelData: InterventionData[] = filteredData.filter(
      (d: InterventionData) =>
        d.valueCodeableConcept &&
        d.valueCodeableConcept.coding &&
        d.valueCodeableConcept.coding.length > 0 &&
        d.valueCodeableConcept.coding[0].display === selectedData[j].item
    )
    if (labelData.length > 0) {
      for (let i = 0; i < labelData.length; i++) {
        if (
          labelData[i].effectivePeriod &&
          labelData[i].effectivePeriod !== null
        ) {
          const outComeObj: InterVentions = {
            id: i,
            groupId: labelData[i].feature_id,
            item: labelData[i].valueCodeableConcept.coding[0].display,
            effectivePeriod: labelData[i].effectivePeriod,
          }
          finalOutCome.push(outComeObj)
        } else {
          const outComeObjForDateTIme: InterVentions = {
            id: i,
            groupId: labelData[i].feature_id,
            item: labelData[i].valueCodeableConcept.coding[0].display,
            effectiveDateTime: labelData[i].effectiveDateTime,
          }
        }
      }
    }
  }

  return finalOutCome
}

export function getSelectedOutComeData(
  selectedOutCome: OutComes,
  filteredData: InterventionData[]
): OutComes[] {
  const finalOutCome: OutComes[] = []
  const labelData: InterventionData[] = filteredData.filter(
    (d: InterventionData) => d.feature_id.includes(selectedOutCome.groupId)
  )
  if (labelData.length > 0) {
    for (let i = 0; i < labelData.length; i++) {
      if (
        labelData[i].effectiveDateTime &&
        labelData[i].effectiveDateTime !== null
      ) {
        const outComeObj: OutComes = {
          groupId: selectedOutCome.groupId,
          type: selectedOutCome.type,
          effectiveDateTime: labelData[i].effectiveDateTime ?? '',
          unit: labelData[i].valueQuantity
            ? labelData[i].valueQuantity?.value
            : 0,
          unitType: labelData[i].valueQuantity
            ? labelData[i].valueQuantity?.unit
            : '',
        }
        finalOutCome.push(outComeObj)
      }
    }
  }
  return finalOutCome
}

export function getSelectedOutComeDataCheck(
  selectedOutCome: OutComes,
  filteredData: InterventionData[]
): boolean {
  const finalOutCome: OutComes[] = []
  const labelData: InterventionData[] = filteredData.filter(
    (d: InterventionData) => d.feature_id.includes(selectedOutCome.groupId)
  )
  if (labelData.length > 0) {
    for (let i = 0; i < labelData.length; i++) {
      if (
        labelData[i].effectiveDateTime &&
        labelData[i].effectiveDateTime !== null
      ) {
        const outComeObj: OutComes = {
          groupId: selectedOutCome.groupId,
          type: selectedOutCome.type,
          effectiveDateTime: labelData[i].effectiveDateTime ?? '',
          unit: labelData[i].valueQuantity
            ? labelData[i].valueQuantity?.value
            : 0,
          unitType: labelData[i].valueQuantity
            ? labelData[i].valueQuantity?.unit
            : '',
        }
        finalOutCome.push(outComeObj)
      }
    }
  }
  return finalOutCome.length > 0
}

export function getUnitData(
  outComeData: OutComes[],
  dateString: string
): number {
  if (outComeData.length > 0 && dateString.length > 0) {
    const outData: OutComes[] = outComeData.filter(
      (d: OutComes) =>
        moment(d.effectiveDateTime).format('YYYY-MM-DD') ===
        moment(dateString).format('YYYY-MM-DD')
    )
    if (outData.length > 0) {
      return Math.round(cToF(outData[0].unit ?? 0))
    }
  }
  return 0
}

export function getUnitType(
  outComeData: OutComes[],
  dateString: string
): string {
  if (outComeData.length > 0 && dateString.length > 0) {
    const outData: OutComes[] = outComeData.filter(
      (d: OutComes) =>
        moment(d.effectiveDateTime).format('YYYY-MM-DD') ===
        moment(dateString).format('YYYY-MM-DD')
    )
    if (outData && outData.length > 0) return outData[0].unitType ?? ''
    return ''
  }
  return ''
}

export function getTherapyData(
  outComeData: InterVentions[],
  dateString: string
): string {
  const data: string[] = []
  if (outComeData.length > 0 && dateString.length > 0) {
    const outData: InterVentions[] = outComeData.filter(
      (d: InterVentions) =>
        moment(d.effectivePeriod?.start).format('YYYY-MM-DD') ===
          moment(dateString).format('YYYY-MM-DD') &&
        d.groupId !== 'visit_service_req'
    )
    if (outData.length > 0) {
      for (let i = 0; i < outData.length; i++) {
        data.push(outData[i].item)
      }
      if (data.length > 1) {
        const uniqueArrayData: string[] = data.filter(
          (c, index) => data.indexOf(c) === index
        )
        if (uniqueArrayData.length > 1) {
          return uniqueArrayData.join(',')
        }
        if (uniqueArrayData.length === 0) {
          return ''
        }

        return uniqueArrayData[0]
      }

      return data[0]
    }
    return ''
  }
  return ''
}

export function getTherapyDataArr(
  outComeData: InterVentions[],
  dateString: string
): string[] {
  const data: string[] = []
  if (outComeData.length > 0 && dateString.length > 0) {
    const outData: InterVentions[] = outComeData.filter(
      (d: InterVentions) =>
        moment(d.effectivePeriod?.start).format('YYYY-MM-DD') ===
          moment(dateString).format('YYYY-MM-DD') &&
        d.groupId !== 'visit_service_req'
    )
    if (outData.length > 0) {
      for (let i = 0; i < outData.length; i++) {
        data.push(outData[i].item)
      }
      if (data.length > 1) {
        const uniqueArrayData: string[] = data.filter(
          (c, index) => data.indexOf(c) === index
        )
        if (uniqueArrayData.length > 1) {
          return uniqueArrayData
        }
        if (uniqueArrayData.length === 0) {
          return []
        }

        return uniqueArrayData
      }

      return data
    }
    return []
  }
  return []
}

export function getVisitStart(
  outComeData: InterVentions[],
  dateString: string
): string {
  if (outComeData.length > 0 && dateString.length > 0) {
    const outData: InterVentions[] = outComeData.filter(
      (d: InterVentions) =>
        moment(d.effectivePeriod?.start).format('YYYY-MM-DD') ===
          moment(dateString).format('YYYY-MM-DD') &&
        d.groupId === 'visit_service_req'
    )
    if (outData.length > 0) {
      return 'Inpatient Encounter'
    }
    return ''
  }
  return ''
}

export function getVisitEnd(
  outComeData: InterVentions[],
  dateString: string
): string {
  if (outComeData.length > 0 && dateString.length > 0) {
    const outData: InterVentions[] = outComeData.filter(
      (d: InterVentions) =>
        moment(d.effectivePeriod?.start).format('YYYY-MM-DD') ===
          moment(dateString).format('YYYY-MM-DD') &&
        d.groupId === 'visit_service_req'
    )
    if (outData.length > 0) {
      return 'Inpatient Encounter'
    }
    return ''
  }
  return ''
}

export function getChartData(
  interventions: InterVentions[],
  outComeData: OutComes[]
): GraphDataForOutCome[] {
  const groupDataArrayForGraph: GraphDataForOutCome[] = []
  const finalChartArr: GraphDataForOutCome[] = []
  if (interventions.length > 0) {
    for (let i = 0; i < interventions.length; i++) {
      if (interventions[i].effectivePeriod) {
        if (interventions[i].groupId === 'visit_service_req') {
          const groupDataForObjStart: GraphDataForOutCome = {
            date: interventions[i].effectivePeriod?.start ?? '',
            intervention: interventions[i].item,
            index: i + 5,
            line: !interventions[i].showLine,
            displayDataForGraph: {
              unit: getUnitData(
                outComeData,
                interventions[i].effectivePeriod?.start ?? ''
              ),
              unitType: getUnitType(
                outComeData,
                interventions[i].effectivePeriod?.start ?? ''
              ),
              visitData: getVisitStart(
                interventions,
                interventions[i].effectivePeriod?.start ?? ''
              ),
              therapyData: '',
              therapyArr: [],
              type: 'visit start',
            },
          }
          groupDataArrayForGraph.push(groupDataForObjStart)

          const groupDataForObjEnd: GraphDataForOutCome = {
            date: interventions[i].effectivePeriod?.end ?? '',
            intervention: interventions[i].item,
            index: i + 5,
            line: true,
            displayDataForGraph: {
              unit: getUnitData(
                outComeData,
                interventions[i].effectivePeriod?.start ?? ''
              ),
              unitType: getUnitType(
                outComeData,
                interventions[i].effectivePeriod?.start ?? ''
              ),
              visitData: getVisitEnd(
                interventions,
                interventions[i].effectivePeriod?.end ?? ''
              ),
              therapyData: '',
              therapyArr: [],
              type: 'visit end',
            },
          }
          groupDataArrayForGraph.push(groupDataForObjEnd)
        } else {
          const groupDataForObjStart: GraphDataForOutCome = {
            date: interventions[i].effectivePeriod?.start ?? '',
            intervention: interventions[i].item,
            indexForOther: i + 2,
            line: true,
            displayDataForGraph: {
              unit: getUnitData(
                outComeData,
                interventions[i].effectivePeriod?.start ?? ''
              ),
              unitType: getUnitType(
                outComeData,
                interventions[i].effectivePeriod?.start ?? ''
              ),
              visitData: getVisitStart(
                interventions,
                interventions[i].effectivePeriod?.start ?? ''
              ),
              therapyData: getTherapyData(
                interventions,
                interventions[i].effectivePeriod?.start ?? ''
              ),
              therapyArr: getTherapyDataArr(
                interventions,
                interventions[i].effectivePeriod?.start ?? ''
              ),
              type: 'therapy',
            },
          }
          groupDataArrayForGraph.push(groupDataForObjStart)

          const groupDataForObjEnd: GraphDataForOutCome = {
            date: interventions[i].effectivePeriod?.end ?? '',
            intervention: interventions[i].item,
            indexForOther: i + 2,
            line: !interventions[i].showLine,
            displayDataForGraph: {
              unit: getUnitData(
                outComeData,
                interventions[i].effectivePeriod?.start ?? ''
              ),
              unitType: getUnitType(
                outComeData,
                interventions[i].effectivePeriod?.start ?? ''
              ),
              visitData: getVisitEnd(
                interventions,
                interventions[i].effectivePeriod?.start ?? ''
              ),
              therapyData: getTherapyData(
                interventions,
                interventions[i].effectivePeriod?.start ?? ''
              ),
              therapyArr: getTherapyDataArr(
                interventions,
                interventions[i].effectivePeriod?.start ?? ''
              ),
              type: 'therapy',
            },
          }
          groupDataArrayForGraph.push(groupDataForObjEnd)
        }
      } else if (interventions[i].effectiveDateTime) {
        const groupDataWithEffectiveDate: GraphDataForOutCome = {
          date: interventions[i].effectiveDateTime ?? '',
          intervention: interventions[i].item,
          indexForOther: i + 2,
          line: false,
          displayDataForGraph: {
            unit: getUnitData(
              outComeData,
              interventions[i].effectiveDateTime ?? ''
            ),
            unitType: getUnitType(
              outComeData,
              interventions[i].effectiveDateTime ?? ''
            ),
            visitData: getVisitEnd(
              interventions,
              interventions[i].effectiveDateTime ?? ''
            ),
            therapyData: getTherapyData(
              interventions,
              interventions[i].effectiveDateTime ?? ''
            ),
            therapyArr: getTherapyDataArr(
              interventions,
              interventions[i].effectiveDateTime ?? ''
            ),
            type: '',
          },
        }
        groupDataArrayForGraph.push(groupDataWithEffectiveDate)
      }
    }

    if (outComeData.length > 0) {
      for (let j = 0; j < outComeData.length; j++) {
        if (outComeData[j].effectiveDateTime) {
          const groupDataWithOutCome: GraphDataForOutCome = {
            date: outComeData[j].effectiveDateTime ?? '',
            unit: Math.round(outComeData[j].unit ?? 0),
            line: false,
            unitType: outComeData[j].unitType ?? '',
            displayDataForGraph: {
              unit: Math.round(cToF(outComeData[j].unit ?? 0)),
              unitType: outComeData[j].unitType ?? '',
              visitData: getVisitEnd(
                interventions,
                outComeData[j].effectiveDateTime ?? ''
              ),
              therapyData: getTherapyData(
                interventions,
                outComeData[j].effectiveDateTime ?? ''
              ),
              therapyArr: getTherapyDataArr(
                interventions,
                outComeData[j].effectiveDateTime ?? ''
              ),
              type: 'vitals',
            },
          }
          groupDataArrayForGraph.push(groupDataWithOutCome)
        }
      }
    }
  }
  /* sorting date wise */
  groupDataArrayForGraph.sort((a, b) =>
    (a.date ? a.date : '') < (b.date ? b.date : '')
      ? -1
      : (a.date ?? '') > (b.date ?? '')
      ? 1
      : 0
  )
  /* Formatting date */
  if (groupDataArrayForGraph.length > 0) {
    for (let k = 0; k < groupDataArrayForGraph.length; k++) {
      const groupDataArrayForObj: GraphDataForOutCome = {
        date: moment(groupDataArrayForGraph[k].date).format('DD-MM-YYYY'),
        intervention: groupDataArrayForGraph[k].intervention,
        unit: groupDataArrayForGraph[k].unit
          ? groupDataArrayForGraph[k].unitType &&
            groupDataArrayForGraph[k].unitType === 'C'
            ? cToF(groupDataArrayForGraph[k].unit ?? 0)
            : groupDataArrayForGraph[k].unit
          : undefined,
        unitType: groupDataArrayForGraph[k].unitType
          ? groupDataArrayForGraph[k].unitType
          : '',
        index: groupDataArrayForGraph[k].index
          ? groupDataArrayForGraph[k].index
          : undefined,
        indexForOther: groupDataArrayForGraph[k].indexForOther
          ? groupDataArrayForGraph[k].indexForOther
          : undefined,
        line: groupDataArrayForGraph[k].line,
        displayDataForGraph: groupDataArrayForGraph[k].displayDataForGraph,
      }
      finalChartArr.push(groupDataArrayForObj)
    }
  }

  return finalChartArr
}

// export function getChartDataForApex(
//   interventions: InterVentions[],
//   outComeData: OutComes[]
// ): GraphDataForOutCome[] {
//   const groupDataArrayForGraph: GraphDataForOutCome[] = []
//   const finalChartArr: GraphDataForOutCome[] = []
//   if (interventions.length > 0) {
//     for (let i = 0; i < interventions.length; i++) {
//       if (interventions[i].effectivePeriod) {
//         const seriesData: Series = {
//           type: 'line',
//           name: interventions[i].item,
//           data: [i + 10, i + 15],
//         }
//       } else if (interventions[i].effectiveDateTime) {
//         const seriesData: Series = {
//           type: 'line',
//           name: interventions[i].item,
//           data: [i + 10, i + 15],
//         }
//         groupDataArrayForGraph.push(groupDataWithEffectiveDate)
//       }
//     }

//     if (outComeData.length > 0) {
//       for (let j = 0; j < outComeData.length; j++) {
//         if (outComeData[j].effectiveDateTime) {
//           const groupDataWithOutCome: GraphDataForOutCome = {
//             date: outComeData[j].effectiveDateTime ?? '',
//             unit: outComeData[j].unit ?? 0,
//             intervention: '',
//             index: 0,
//           }
//           groupDataArrayForGraph.push(groupDataWithOutCome)
//         }
//       }
//     }
//   }
//   /* sorting date wise */
//   groupDataArrayForGraph.sort((a, b) =>
//     (a.date ? a.date : '') < (b.date ? b.date : '')
//       ? -1
//       : (a.date ?? '') > (b.date ?? '')
//       ? 1
//       : 0
//   )
//   /* Formatting date */
//   if (groupDataArrayForGraph.length > 0) {
//     for (let k = 0; k < groupDataArrayForGraph.length; k++) {
//       const groupDataArrayForObj: GraphDataForOutCome = {
//         date: moment(groupDataArrayForGraph[k].date).format('YYYY-MM-DD'),
//         intervention: groupDataArrayForGraph[k].intervention,
//         unit: groupDataArrayForGraph[k].unit,
//         index: groupDataArrayForGraph[k].index,
//       }
//       finalChartArr.push(groupDataArrayForObj)
//     }
//   }

//   return finalChartArr
// }

export function getConnectNullData(
  startDate: string,
  endDate: string
): boolean {
  let result: boolean = false
  if (startDate.length > 0 && endDate.length > 0) {
    const a = moment(startDate)
    const b = moment(endDate)
    const diffInDays = a.diff(b, 'days')
    if (diffInDays < 1) {
      result = true
    }
    return result
  }
  return result
}

export function getSankeyChartData(personCount: number): SankeyData {
  const data: SankeyData = { nodes: [], links: [] } as SankeyData
  const chartData: any = datas.results

  Object.keys(chartData.path_codes).map((key: any, i: number) => {
    let index = 0
    const cohortsData = chartData.path_codes[key].cohorts

    cohortsData.map((c: any, ii: number) => {
      const node: SankeyNode = {
        id: key,
        name: c,
        value: c,
        index,
      }
      data.nodes.push(node)
      // eslint-disable-next-line no-plusplus
      index++
    })

    chartData.pathway_groups[0].path_pairs.map(
      (entry: any, indexData: number) => {
        if (entry.personCount > personCount) {
          data.links.push({
            source: entry.source,
            target: entry.target,
            value: entry.personCount,
            index: indexData,
          })
        }
      }
    )
  })
  return data
}

export function getYAxisData(
  graphSeries: SummaryDataWithCount[],
  analysisName: string
): ApexYAxis[] {
  const configuredYaxis: ApexYAxis[] = []

  let barCount: number = 0
  let lineCount: number = 0

  for (let i = 0; i < graphSeries.length; i++) {
    if (graphSeries[i].type === 'bar') {
      const axisOption: ApexYAxis = {
        seriesName: 'test',
        show: barCount === 0,
        labels: {
          show: true,
          formatter(val) {
            return val.toFixed(0)
          },
        },

        title: {
          text: `Patient Count`,

          style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-yaxis-title',
          },
        },
        crosshairs: {
          show: true,
        },
        axisBorder: {
          show: true,
          width: 2,
        },
        min: 0,
        max: 250,
        tickAmount: 10,
      }

      configuredYaxis.push(axisOption)
      barCount += 1
    }
  }
  for (let i = 0; i < graphSeries.length; i++) {
    if (graphSeries[i].type === 'line') {
      const axisOption1: ApexYAxis = {
        seriesName: graphSeries[i].name,
        opposite: true,
        show: lineCount === 0,
        labels: {
          show: true,
        },
        title: {
          text: `${analysisName} in mmHg`,

          style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-yaxis-title',
          },
        },
        crosshairs: {
          show: true,
        },
        axisBorder: {
          show: true,
          width: 2,
        },
        min: 0,
        max: 250,

        tickAmount: 10,
      }

      configuredYaxis.push(axisOption1)
      lineCount += 1
    }
  }

  return configuredYaxis
}

export function getDataLabelSeries(
  graphSeries: SummaryDataWithCount[],
  analysisName: string
): number[] {
  const configuredYaxis: number[] = []

  for (let i = 0; i < graphSeries.length; i++) {
    if (graphSeries[i].type === 'bar') {
      configuredYaxis.push(i)
    }
  }

  return configuredYaxis
}

export function getToolTipEnabledSeries(
  graphSeries: SummaryDataWithCount[],
  analysisName: string
): number[] {
  const configuredYaxis: number[] = []

  for (let i = 0; i < graphSeries.length; i++) {
    if (graphSeries[i].type === 'bar') {
      configuredYaxis.push(i)
    }
  }

  return configuredYaxis
}
