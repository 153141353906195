/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Grid, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { PurposeOfUse } from 'models/purposeOfUse'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-vis/dist/style.css'
import { MotorFunctionObservationCodes } from 'utils/constants/observations/motor_observation_types'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { getStringCodesFromObservations } from 'utils/fhirResoureHelpers/observationHelpers'
import { AddObservationWithNotesForEncounter } from './observationManagement/addObservationWithNotes'
import { ViewObservations } from './observationManagement/viewObservations'

interface MotorExaminationForPTProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  isReadOnly?: boolean
}

export const MotorExaminationsListPT: React.FC<MotorExaminationForPTProps> = ({
  fhirAppointmentDetails,
  isReadOnly = false,
}: MotorExaminationForPTProps) => {
  const { t } = useTranslation(['common'])
  const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())
  const [preselectedObservations, setPreSelectedObservations] = useState<
    R4.IObservation[]
  >([])

  return (
    <Grid container direction='column' style={{ padding: 0, margin: 0 }}>
      <Grid container direction='row' style={{ padding: 0, margin: 0 }}>
        <Box
          sx={{ width: '100%' }}
          display='flex'
          alignItems='center'
          flexDirection='row'
          bgcolor='#999999'
          minHeight={40}
        >
          <Box p={2}>
            <Typography
              variant='body1'
              style={{ color: 'white', fontWeight: 600 }}
              id='motor_label'
            >
              {' '}
              Motor
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} style={{ padding: '8px' }}>
        <ViewObservations
          patient={fhirAppointmentDetails.patient}
          purposeOfUse={PurposeOfUse.IPDAppointment}
          category='284367004'
          encounterId={fhirAppointmentDetails.mainEncounter!.id!}
          status='completed'
          fetchAgain={fetcAgain ?? 'new'}
          allowEditing={!isReadOnly}
          onObservationFetched={(res) => {
            setPreSelectedObservations(res)
          }}
          id='motor'
        />
      </Grid>
      {!isReadOnly && (
        <Grid item xs={6} style={{ padding: '8px' }}>
          <AddObservationWithNotesForEncounter
            onObservationAdded={() => {
              setFetchAgain(getUniqueTempId())
            }}
            searchOptions={MotorFunctionObservationCodes}
            encounterId={fhirAppointmentDetails.mainEncounter!.id!}
            patient={fhirAppointmentDetails.patient}
            preSelectedCodes={getStringCodesFromObservations(
              preselectedObservations
            )}
            otherCode='other-motor-finding'
            observationCategory={[
              {
                coding: [
                  {
                    display: '',
                    code: '284367004',
                    system: 'http://snomed.info/sct',
                  },
                ],
              },
            ]}
            id='motor'
          />
        </Grid>
      )}
    </Grid>
  )
}
