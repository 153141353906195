import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Doctor, DoctorProfileService, ErrorResponse } from 'lib/openApi'
import * as E from 'fp-ts/lib/Either'
import { OIDCUser } from 'models/oidcUser'
import {
  showSuccessAlert,
  showWarningAlert,
} from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getOIDCUserObject } from 'utils/authHelpers'
import { Errors } from 'io-ts'
import { PractitionerUpdateStatus } from './practitionerEditStatusTypes'

const initialState: PractitionerUpdateStatus = {
  updating: false,
  updateSuccessful: false,
  error: false,
  errorMessage: '',
}

const practitionerEditSlice = createSlice({
  name: 'editPractioner',
  initialState,
  reducers: {
    updatingPractitionerDetails(
      state,
      action: PayloadAction<PractitionerUpdateStatus>
    ) {
      state.updating = action.payload.updating
      state.updateSuccessful = action.payload.updateSuccessful
      state.error = action.payload.error
    },

    practitionerDetailsAdded(
      state,
      action: PayloadAction<PractitionerUpdateStatus>
    ) {
      state.updating = action.payload.updating
      state.updateSuccessful = action.payload.updateSuccessful
      state.error = action.payload.error
    },

    errorInAddingPractitionerDetails(
      state,
      action: PayloadAction<PractitionerUpdateStatus>
    ) {
      state.updating = action.payload.updating
      state.updateSuccessful = action.payload.updateSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetPractitionerDetails(
      state,
      action: PayloadAction<PractitionerUpdateStatus>
    ) {
      state.updating = action.payload.updating
      state.updateSuccessful = action.payload.updateSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },
  },
})

export const updatePractitionerDetails =
  (
    practitionerRoleDetails: R4.IPractitionerRole,
    practitioner: R4.IPractitioner
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePractitionerState: PractitionerUpdateStatus = {
      updating: true,
      updateSuccessful: false,
      error: false,
    }
    dispatch(
      practitionerEditSlice.actions.updatingPractitionerDetails(
        addingCreatePractitionerState
      )
    )

    try {
      const practionerRole: R4.IPractitionerRole = {
        ...practitionerRoleDetails,
      }
      const oldPractioner: R4.IPractitioner = {
        ...practitioner,
      }
      if (practionerRole.active === true) {
        practionerRole.active = false
        oldPractioner.active = false
      } else {
        practionerRole.active = true
        oldPractioner.active = true
      }

      const roleMatchString: string = `W/${JSON.stringify(
        practionerRole.meta?.versionId ?? ' '
      )}`
      const doctorMatchString: string = `W/${JSON.stringify(
        oldPractioner.meta?.versionId ?? ' '
      )}`

      const requestBundle: R4.IBundle = {
        resourceType: 'Bundle',
        type: R4.BundleTypeKind._transaction,
        entry: [
          {
            fullUrl: `${practionerRole.resourceType}/${practionerRole.id}`,
            request: {
              ifMatch: roleMatchString,
              method: R4.Bundle_RequestMethodKind._put,
              url: `${practionerRole.resourceType}/${practionerRole.id}`,
            },
            resource: practionerRole,
          },

          {
            fullUrl: `${oldPractioner.resourceType}/${oldPractioner.id}`,
            request: {
              ifMatch: doctorMatchString,
              method: R4.Bundle_RequestMethodKind._put,
              url: `${oldPractioner.resourceType}/${oldPractioner.id}`,
            },
            resource: oldPractioner,
          },
        ],
      }

      const fhirApi: FHIRApiClient = new FHIRApiClient()
      const response = await fhirApi.doCreateFHIRTransaction('', requestBundle)

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        dispatch(showSuccessAlert('User updated successfully'))
        const successCreatePersonState: PractitionerUpdateStatus = {
          updating: false,
          updateSuccessful: true,
          error: false,
          errorMessage: '',
        }
        dispatch(
          practitionerEditSlice.actions.updatingPractitionerDetails(
            successCreatePersonState
          )
        )
        return
      }
      const errorCreatePersonState: PractitionerUpdateStatus = {
        updating: false,
        updateSuccessful: false,
        error: true,
        errorMessage: 'Error while updating User',
      }
      dispatch(
        practitionerEditSlice.actions.errorInAddingPractitionerDetails(
          errorCreatePersonState
        )
      )

      dispatch(showWarningAlert('Error while updating user. Please try later'))
      return
    } catch (error) {
      const errorCreatePersonState: PractitionerUpdateStatus = {
        updating: false,
        updateSuccessful: false,
        error: true,
        errorMessage: 'Something went wrong',
      }
      dispatch(
        practitionerEditSlice.actions.errorInAddingPractitionerDetails(
          errorCreatePersonState
        )
      )
    }
  }

export const resetPractionerEditState = () => (dispatch: AppDispatch) => {
  dispatch(practitionerEditSlice.actions.resetPractitionerDetails(initialState))
}

export default practitionerEditSlice.reducer
