/* eslint-disable no-octal */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-key */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import {
  GraphDataForOutCome,
  InterVentionGroupData,
  InterVentions,
  OutComes,
} from 'models/evidenceGraph/interventions'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DiscreteColorLegend } from 'react-vis'
import 'react-vis/dist/style.css'
// import {
//   Crosshair,
//   DiscreteColorLegend,
//   Hint,
//   LineMarkSeries,
//   XAxis,
//   XYPlot,
//   YAxis,
// } from 'react-vis'
import {
  ComposedChart,
  Label,
  Line,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { searchingOutComes } from 'redux/outcomegraph/patientOutComeSearchSlice'
import { RootState } from 'redux/rootReducer'
import { outComes } from 'utils/constants'
import { getMeasurementLabel, titleCase } from 'utils/fhirResourcesHelper'
import {
  getChartData,
  getSelectedInterVentionData,
  getSelectedOutComeData,
} from 'utils/graphData_handle/graphDataHelper'
import { WelloFormItemLabel } from 'wello-web-components'

interface Props {
  open: boolean
  patientId: string
  onClose: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogCustomizedWidth: {
    'max-width': '80%',
  },
}))
export const PatientDefinedGraph: React.FC<Props> = ({
  open,
  patientId,
  onClose,
}) => {
  const outComeHandler = useSelector(
    (state: RootState) => state.patientOutcomeSearchSlice
  )
  const dispatch = useDispatch()

  const [intervention, setIntervention] = useState<InterVentions[]>([])
  const [outComeData, setOutComeData] = useState<OutComes[]>([])
  const [outComeString, setOutComeString] = useState<string>()
  const [chartData, setChartData] = useState<GraphDataForOutCome[]>([])
  const [graphDataAvailable, setGraphDataAvilable] = useState<boolean>(false)
  const classes = useStyles()

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active) {
      return (
        <Box
          display='flex'
          flexDirection='column'
          style={{ backgroundColor: '#FDFFABB2' }}
          width={
            payload && payload.length > 0 && payload[0].payload
              ? payload[0].payload.displayDataForGraph.therapyArr.length > 2
                ? '60%'
                : '100%'
              : '100%'
          }
        >
          <Typography
            variant='subtitle1'
            color='textSecondary'
            style={{ color: 'green', fontSize: 10 }}
          >
            {`${label}`}
          </Typography>
          {/* <Typography
            variant='subtitle1'
            style={{ color: 'green', fontSize: 10 }}
          >
            Total Appointments : {`${payload?.[0].payload.NoOfAppointments}`}
          </Typography> */}
          {payload && payload.length > 0 && payload[0].payload && (
            <Box>
              {payload?.[0].payload.unit &&
                payload?.[0].payload.unit > 0 &&
                payload?.[0].payload.unitType === 'C' && (
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    style={{ color: 'green', fontSize: 10 }}
                  >
                    {`${payload?.[0].payload.unit} F`}
                  </Typography>
                )}

              {payload?.[0].payload.unit &&
                payload?.[0].payload.unit > 0 &&
                payload?.[0].payload.unitType !== 'C' && (
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    style={{ color: 'green', fontSize: 10 }}
                  >
                    {`${payload?.[0].payload.unit} ${payload?.[0].payload.unitType}`}
                  </Typography>
                )}

              {payload?.[0].payload.unit === undefined &&
                payload?.[0].payload.unit > 0 && (
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    style={{ color: 'green', fontSize: 10 }}
                  >
                    {`${payload?.[0].payload.displayDataForGraph.unit} ${payload?.[0].payload.displayDataForGraph.unitType}`}
                  </Typography>
                )}

              {payload?.[0].payload.unit === undefined &&
                payload?.[0].payload.displayDataForGraph.unit > 0 &&
                payload?.[0].payload.displayDataForGraph.unitType !== 'C' && (
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    style={{ color: 'green', fontSize: 10 }}
                  >
                    {`${payload?.[0].payload.displayDataForGraph.unit} ${payload?.[0].payload.displayDataForGraph.unitType}`}
                  </Typography>
                )}
              {payload?.[0].payload.unit === undefined &&
                payload?.[0].payload.displayDataForGraph.unit > 0 &&
                payload?.[0].payload.displayDataForGraph.unitType === 'C' && (
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    style={{ color: 'green', fontSize: 10 }}
                  >
                    {`${payload?.[0].payload.displayDataForGraph.unit} F`}
                  </Typography>
                )}

              <Typography
                variant='subtitle1'
                color='inherit'
                style={{
                  wordWrap: 'break-word',
                  whiteSpace: 'pre-line',
                  textOverflow: 'ellipsis',

                  color: 'green',
                  fontSize: 10,
                }}
              >
                {payload?.[0].payload.unit === undefined && (
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    style={{ color: 'green', fontSize: 10 }}
                  >
                    {`${payload?.[0].payload.displayDataForGraph.visitData}`}
                  </Typography>
                )}
              </Typography>
              <Box
                width={
                  payload?.[0].payload.displayDataForGraph.therapyArr.length > 2
                    ? '100%'
                    : '100%'
                }
              >
                <Typography
                  variant='subtitle1'
                  color='inherit'
                  style={{
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-line',
                    textOverflow: 'ellipsis',

                    color: 'green',
                    fontSize: 10,
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    style={{ color: 'green', fontSize: 10 }}
                  >
                    {`${payload?.[0].payload.displayDataForGraph.therapyData}`}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )
    }

    return null
  }

  function randomInteger(max: number) {
    return Math.floor(Math.random() * (max + 1))
  }

  function randomRgbColor() {
    const r = randomInteger(255)
    const g = randomInteger(255)
    const b = randomInteger(255)
    return [r, g, b]
  }

  function randomHexColor() {
    const [r, g, b] = randomRgbColor()

    const hr = r.toString(16).padStart(2, '0')
    const hg = g.toString(16).padStart(2, '0')
    const hbc = b.toString(16).padStart(2, '0')
    return `#${hr}${hg}${hbc}`
  }

  useEffect(() => {
    if (patientId.length > 0) {
      dispatch(searchingOutComes(patientId, '', ''))
    }
  }, [])

  function resetForm() {
    setIntervention([])
    setChartData([])
    setGraphDataAvilable(false)
    setOutComeData([])
    // dispatch(resetOutComeGraph())
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetForm()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth

      //   style={{ width: 800 }}
    >
      <DialogTitle id='scroll-dialog-title'>
        {' '}
        Evidence-based analysis
        {/* {t('labelCommon:graph')} */}
      </DialogTitle>
      <DialogContent dividers={true}>
        <Box display='flex' flexDirection='column' width='100%'>
          {outComeHandler.searching && (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              width='100%'
              height='100%'
              alignContent='center'
            >
              <CircularProgress size={25} />
            </Box>
          )}

          {outComeHandler.noResultsAvailable && (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              width='100%'
              height='100%'
              alignContent='center'
            >
              <Typography variant='subtitle1' color='initial' align='center'>
                No data available
              </Typography>
            </Box>
          )}

          {outComeHandler.error && (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              width='100%'
              height='100%'
              alignContent='center'
            >
              <Typography variant='subtitle1' color='error' align='center'>
                Something went wrong
              </Typography>
            </Box>
          )}
          {outComeHandler.resultsAvailable &&
            outComeHandler.resultsAvailable &&
            outComeHandler.graphList &&
            outComeHandler.interVentionStringList && (
              <Box display='flex' flexDirection='row' width='100%'>
                <Box
                  py={1}
                  width='50%'
                  display='flex'
                  flexDirection='column'
                  paddingLeft={1}
                >
                  <WelloFormItemLabel title='Interventions' />
                  <Autocomplete
                    style={{
                      borderRadius: '4px',
                    }}
                    limitTags={2}
                    fullWidth
                    size='small'
                    multiple={true}
                    id='free-solo-2-demo'
                    disableClearable
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) => titleCase(option.item ?? '')}
                    options={outComeHandler.interVentionStringList
                      .map((option) => {
                        const firstLetter = titleCase(option.label)
                        return {
                          firstLetter: /[0-9]/.test(firstLetter)
                            ? '0-9'
                            : firstLetter,
                          ...option,
                        }
                      })
                      .sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                    groupBy={(option) => option.firstLetter}
                    onChange={(
                      e,
                      changedValue: InterVentionGroupData[],
                      reason
                    ) => {
                      setChartData([])

                      setIntervention(
                        getSelectedInterVentionData(
                          changedValue,
                          outComeHandler.outComeList ?? []
                        )
                      )
                    }}
                    autoComplete
                    ChipProps={{
                      deleteIcon: (
                        <ClearOutlined
                          style={{
                            height: '15px',
                            color: 'white',
                          }}
                        />
                      ),
                      style: {
                        backgroundColor: kPrimaryLight,
                        borderRadius: '4px',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder='Select Interventions'
                        fullWidth
                        variant='outlined'
                        size='small'
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                  />
                </Box>
                <Box
                  py={1}
                  width='20%'
                  display='flex'
                  flexDirection='column'
                  px={1}
                >
                  <WelloFormItemLabel title='Outcome' />
                  <Autocomplete
                    style={{
                      borderRadius: '4px',
                    }}
                    limitTags={2}
                    fullWidth
                    size='small'
                    multiple={false}
                    id='free-solo-2-demo'
                    disableClearable
                    getOptionSelected={(option, value) =>
                      option.type === value.type
                    }
                    getOptionLabel={(option) => option.type ?? ''}
                    options={outComes}
                    onChange={(e, changedValue, reason) => {
                      setOutComeString(changedValue.groupId)
                      setChartData([])
                      //   setGraphDataAvilable(
                      //     getSelectedOutComeDataCheck(
                      //       changedValue,
                      //       outComeHandler.outComeList ?? []
                      //     )
                      //   )
                      setOutComeData(
                        getSelectedOutComeData(
                          changedValue,
                          outComeHandler.outComeList ?? []
                        )
                      )
                    }}
                    autoComplete
                    ChipProps={{
                      deleteIcon: (
                        <ClearOutlined
                          style={{
                            height: '15px',
                            color: 'white',
                          }}
                        />
                      ),
                      style: {
                        backgroundColor: kPrimaryLight,
                        borderRadius: '4px',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder='Select Outcome'
                        fullWidth
                        variant='outlined'
                        size='small'
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                  />
                </Box>
                <Box height='10%' paddingTop={5.5}>
                  <Button
                    onClick={() => {
                      setChartData(getChartData(intervention, outComeData))
                    }}
                    color='primary'
                    size='small'
                    disabled={
                      intervention.length === 0 ||
                      outComeString === undefined ||
                      (outComeString !== undefined && outComeData.length === 0)
                    }
                    variant='contained'
                    disableElevation
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            )}

          {intervention.length > 0 &&
            outComeString !== undefined &&
            outComeData.length === 0 && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='100%'
                height='100%'
                alignContent='center'
              >
                <Typography variant='subtitle1' color='initial' align='center'>
                  No evidence data available
                </Typography>
              </Box>
            )}

          {chartData.length > 0 && (
            <Box py={2} display='flex' flexDirection='column'>
              <Box paddingLeft={4}>
                <DiscreteColorLegend
                  orientation='horizontal'
                  items={[
                    {
                      title: 'Outcome',

                      color: 'blue',
                    },
                    {
                      title: 'Visit Start',

                      color: '#8884d8',
                    },
                    {
                      title: 'Visit End',

                      color: '#c7522a',
                    },

                    {
                      title: 'Therapy',

                      color: 'green',
                    },
                  ]}
                />
              </Box>
              {/* <ReactApexChart
                options={{
                  chart: {
                    height: 350,
                    type: 'line',
                    stacked: false,
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    width: [1, 1, 4],
                  },
                  title: {
                    text: 'XYZ - Stock Analysis (2009 - 2016)',
                    align: 'left',
                    offsetX: 110,
                  },
                  tooltip: {
                    enabled: true,
                  },
                  xaxis: {
                    categories: [
                      2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016,
                    ],
                  },
                  yaxis: [
                    {
                      axisTicks: {
                        show: true,
                      },
                      axisBorder: {
                        show: true,
                        color: '#008FFB',
                      },
                      labels: {
                        style: {
                          colors: '#008FFB',
                        },
                      },
                      title: {
                        text: 'Income (thousand crores)',
                        style: {
                          color: '#008FFB',
                        },
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    {
                      seriesName: 'Income',
                      opposite: true,
                      axisTicks: {
                        show: true,
                      },
                      axisBorder: {
                        show: true,
                        color: '#00E396',
                      },
                      labels: {
                        style: {
                          colors: '#00E396',
                        },
                      },
                      title: {
                        text: 'Operating Cashflow (thousand crores)',
                        style: {
                          color: '#00E396',
                        },
                      },
                    },
                    {
                      seriesName: 'Revenue',
                      opposite: true,
                      axisTicks: {
                        show: true,
                      },
                      axisBorder: {
                        show: true,
                        color: '#FEB019',
                      },
                      labels: {
                        style: {
                          colors: '#FEB019',
                        },
                      },
                      title: {
                        text: 'Revenue (thousand crores)',
                        style: {
                          color: '#FEB019',
                        },
                      },
                    },
                  ],

                  legend: {
                    horizontalAlign: 'left',
                    offsetX: 40,
                  },
                }}
                series={apexData}
                type='line'
                height={350}
              /> */}
              {/* <LineChart
                width={1024}
                height={300}
                data={chartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              > */}

              <ComposedChart
                width={1024}
                height={300}
                data={chartData}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 40,
                  left: 20,
                }}
              >
                ComposedChart
                <XAxis
                  dataKey='date'
                  allowDuplicatedCategory={false}
                  padding={{ left: 10 }}
                  width={30}
                  scale='auto'
                  angle={90}
                  dy={30}
                  dx={5}
                >
                  <Label
                    value='Date'
                    position='insideBottom'
                    offset={-40}
                    style={{
                      textAnchor: 'middle',
                      fill: 'blue',
                    }}
                  />
                </XAxis>
                <YAxis yAxisId='left' tick={false}>
                  <Label
                    angle={270}
                    position='left'
                    style={{
                      textAnchor: 'middle',
                      fill: 'blue',
                    }}
                  >
                    Interventions
                  </Label>
                </YAxis>
                <YAxis yAxisId='right' orientation='right'>
                  <Label
                    angle={90}
                    position='right'
                    style={{
                      textAnchor: 'middle',
                      fill: 'blue',
                    }}
                  >
                    {outComeData && chartData.length > 0
                      ? getMeasurementLabel(outComeData[0].type)
                      : ''}
                  </Label>
                </YAxis>
                <Tooltip
                  content={<CustomTooltip />}
                  // cursor={false}

                  cursor={false}
                  //   position={{ y: 170 - toolTipYPosition }}
                  //   offset={-90}
                  allowEscapeViewBox={{ x: true, y: true }}
                />
                {/* <Area
                  type='monotone'
                  dataKey='index'
                  fill='#8884d8'
                  stroke='#8884d8'
                /> */}
                {/* <Line
                  yAxisId='left'
                  name='Visit'
                  type='linear'
                  dataKey='index'
                  stroke='#c7522a'
                  strokeDasharray='5 5'
                  dot={{
                    stroke: '#c7522a',
                    strokeWidth: 2,
                    strokeDasharray: 2,
                  }}
                  // hide={item.intervention !== 'IPD Visit'}

                  connectNulls={true}
                /> */}
                {chartData.map((item: GraphDataForOutCome, index: any) => (
                  <ReferenceLine
                    x={item.index ? item.date : undefined}
                    stroke={
                      item.index
                        ? item.displayDataForGraph.type === 'visit end'
                          ? '#c7522a'
                          : '#8884d8'
                        : ''
                    }
                    alwaysShow={true}
                    yAxisId='left'
                  >
                    <Label position='top'>
                      {item.index ? item.displayDataForGraph.type : ''}
                    </Label>
                  </ReferenceLine>
                ))}
                {chartData.map((item: GraphDataForOutCome, index: any) => (
                  <Line
                    key={`line_${item.indexForOther}`}
                    yAxisId='left'
                    type='linear'
                    name='Therapies'
                    dataKey='indexForOther'
                    // hide={item.intervention !== 'IPD Visit'}
                    stroke='none'
                    strokeWidth={2}
                    dot={{
                      r: 2,
                      strokeDasharray: '',
                    }}
                    fill='green'
                  />
                ))}
                <Line
                  yAxisId='right'
                  type='linear'
                  name='Outcome'
                  dataKey='unit'
                  stroke='blue'
                  strokeWidth={2}
                  connectNulls
                />
              </ComposedChart>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box paddingLeft={2}>
          <Button
            onClick={() => {
              resetForm()
            }}
            variant='outlined'
            disableElevation
          >
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
