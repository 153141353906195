import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'

import { AppDispatch, AppThunk } from 'redux/store'

import { logger } from 'utils/logger'

import { FHIRWithMasterApiClient } from 'services/FHIRClientWithMaster'
import {
  getExtensionValueDataFromValueCoding,
  getExtensionValueDataFromValueText,
  getExtensionValueDataFromValueTiming,
} from 'utils/fhirResourcesHelper'
import { DietTiming } from 'models/dietSetting/dietTImeSettings'
import moment from 'moment'
import { DietSearchStatus } from './dietSearchStatus'

const initialState: DietSearchStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
}

const dietTImeSearchSlice = createSlice({
  name: 'dietTImeSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<DietSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.question = action.payload.question
      state.prSelectedCoding = action.payload.prSelectedCoding
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
    resetState(state, action: PayloadAction<DietSearchStatus>) {
      state.errorReason = undefined

      state.searchingAppointments = false
      state.noResultsAvailable = false

      state.question = undefined
      state.prSelectedCoding = undefined
      state.resultsAvailable = false

      state.errorWhileSearchingAppointments = false
    },
  },
})

export const resetSearchDietSetting =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: DietSearchStatus = {
      errorReason: undefined,
      searchingAppointments: false,
      noResultsAvailable: false,
      question: undefined,
      prSelectedCoding: undefined,
      resultsAvailable: false,
      errorWhileSearchingAppointments: false,
    }
    dispatch(dietTImeSearchSlice.actions.resetState(state))
  }

export const requestDietTiming =
  (doctorId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: DietSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(dietTImeSearchSlice.actions.updatedStatus(state))
    try {
      const fhirApi: FHIRWithMasterApiClient = new FHIRWithMasterApiClient()
      const response: any = await fhirApi.doGetResource(
        `List?code=272106006&source=Practitioner/${doctorId}`
      )
      logger.info('ChargeItemDefinition Fetching')
      logger.info(response)

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp) {
        if (response.entry && response?.entry.length > 0) {
          const questionData: R4.IList = response.entry[0].resource as R4.IList

          const codingArray: DietTiming[] = []
          if (
            questionData &&
            questionData.entry &&
            questionData.entry.length > 0
          ) {
            for (let i = 0; i < questionData.entry.length; i++) {
              if (questionData.entry[i].extension) {
                const extensionData = questionData.entry[i].extension

                const time = getExtensionValueDataFromValueTiming(
                  extensionData,
                  'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-day-part-time-mapping-ext'
                )
                const momentObj = moment(
                  moment().format('DD-MM-YY') + time,
                  'YYYY-MM-DDLT'
                )

                const codeData: R4.ICoding[] =
                  getExtensionValueDataFromValueCoding(
                    extensionData,
                    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-day-part-time-mapping-ext'
                  )

                let activeData = questionData.entry[i].deleted
                if (activeData !== undefined) {
                  activeData = questionData.entry[i].deleted
                } else {
                  activeData = time.length > 0
                }

                const dietTImeData: DietTiming = {
                  dietTime:
                    codeData.length > 0 ? codeData[0].display ?? '' : '',
                  time: {
                    system: 'http://hl7.org/fhir/event-timing',
                    code: codeData.length > 0 ? codeData[0].code ?? '' : '',
                    display:
                      time.length > 0 ? moment(momentObj).format('hh:mm') : '',
                  },
                  displayLabel: getExtensionValueDataFromValueText(
                    extensionData,
                    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-day-part-time-mapping-ext'
                  ),
                  active: activeData ?? false,
                  timeOfTheDay: time.length > 0 ? time : '',
                  isEdit: false,
                }
                codingArray.push(dietTImeData)
              }
            }

            state.resultsAvailable = true
            state.searchingAppointments = false
            state.question = response.entry[0].resource as R4.IList
            state.noResultsAvailable = false
            state.errorReason = undefined
            state.prSelectedCoding = codingArray
            state.errorWhileSearchingAppointments = false
            dispatch(dietTImeSearchSlice.actions.updatedStatus(state))
          } else {
            const errorSearchDoctor: DietSearchStatus = {
              searchingAppointments: false,
              errorWhileSearchingAppointments: false,
              resultsAvailable: false,
              noResultsAvailable: true,
            }
            dispatch(
              dietTImeSearchSlice.actions.updatedStatus(errorSearchDoctor)
            )
          }
        } else {
          const responseData: any = await fhirApi.doGetResource(
            `List?code=272106006&source:missing=true`
          )
          logger.info('ChargeItemDefinition Fetching')
          logger.info(responseData)
          const respData: E.Either<Errors, R4.IBundle> =
            R4.RTTI_Bundle.decode(responseData)
          if (respData) {
            if (responseData.entry && responseData?.entry.length > 0) {
              const questionData: R4.IList = responseData.entry[0]
                .resource as R4.IList

              const codingArray: DietTiming[] = []
              if (
                questionData &&
                questionData.entry &&
                questionData.entry.length > 0
              ) {
                for (let i = 0; i < questionData.entry.length; i++) {
                  if (questionData.entry[i].extension) {
                    const extensionData = questionData.entry[i].extension

                    const time = getExtensionValueDataFromValueTiming(
                      extensionData,
                      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-day-part-time-mapping-ext'
                    )
                    const momentObj = moment(
                      moment().format('DD-MM-YY') + time,
                      'YYYY-MM-DDLT'
                    )

                    const codeData: R4.ICoding[] =
                      getExtensionValueDataFromValueCoding(
                        extensionData,
                        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-day-part-time-mapping-ext'
                      )

                    let activeData = questionData.entry[i].deleted
                    if (activeData !== undefined) {
                      activeData = questionData.entry[i].deleted
                    } else {
                      activeData = time.length > 0
                    }

                    const dietTImeData: DietTiming = {
                      dietTime:
                        codeData.length > 0 ? codeData[0].display ?? '' : '',
                      time: {
                        system: 'http://hl7.org/fhir/event-timing',
                        code: codeData.length > 0 ? codeData[0].code ?? '' : '',
                        display:
                          time.length > 0
                            ? moment(momentObj).format('hh:mm')
                            : '',
                      },
                      displayLabel: getExtensionValueDataFromValueText(
                        extensionData,
                        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-day-part-time-mapping-ext'
                      ),
                      active: activeData ?? false,
                      timeOfTheDay: time.length > 0 ? time : '',
                      isEdit: false,
                    }
                    codingArray.push(dietTImeData)
                  }
                }
                console.log(codingArray)

                state.resultsAvailable = true
                state.searchingAppointments = false
                state.question = responseData.entry[0].resource as R4.IList
                state.noResultsAvailable = false
                state.errorReason = undefined
                state.prSelectedCoding = codingArray
                state.errorWhileSearchingAppointments = false
                dispatch(dietTImeSearchSlice.actions.updatedStatus(state))
              } else {
                const errorSearchDoctor: DietSearchStatus = {
                  searchingAppointments: false,
                  errorWhileSearchingAppointments: false,
                  resultsAvailable: false,
                  noResultsAvailable: true,
                }
                dispatch(
                  dietTImeSearchSlice.actions.updatedStatus(errorSearchDoctor)
                )
              }
            } else {
              const errorSearchDoctor: DietSearchStatus = {
                searchingAppointments: false,
                errorWhileSearchingAppointments: false,
                resultsAvailable: false,
                noResultsAvailable: true,
              }
              dispatch(
                dietTImeSearchSlice.actions.updatedStatus(errorSearchDoctor)
              )
            }
          }
        }
      } /* */
    } catch (error) {
      logger.error(error)
      //   const errorSearchDoctor: AppointmentSearchStatus = {
      //     searchingAppointments: false,
      //     errorWhileSearchingAppointments: true,
      //     resultsAvailable: false,
      //     errorReason: 'searching',
      //   }
      //   dispatch(appointmentSearchSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default dietTImeSearchSlice.reducer
