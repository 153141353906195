import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  getCodeOfSystemCodings,
  getCodeOfSystemFromCodableConceptList,
  getColorBasedOnNumber,
  getIdentifierValueBySystemTypeDAataVal,
} from 'utils/fhirResourcesHelper'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getTimeOfDayDisplayForStatement,
  getTimeOfDayDisplayMeal,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import {
  getNotesFromAllergy,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { DocumentReferencePopUp } from 'views/components/common/fhirResources/documentRefernamceViewer'
import { ReportReferencePopUp } from 'views/components/common/reportViewer/reportFrameViewer'

interface OccupationTileProps {
  reports: R4.IDocumentReference[]
  type: string
  appointmentId: string
}

export const ReportTileForAppointment: React.FC<OccupationTileProps> = ({
  reports,
  type,
  appointmentId,
}: OccupationTileProps) => {
  const kPrimaryColor: string = useTheme().palette.primary.main
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [documentRef, setDocumentRef] = useState<R4.IDocumentReference>()
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Grid container direction='row' spacing={1}>
        {/* ------------------------------------------------------------------------------- */}
        {reports.map((e) => (
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            flexGrow={1}
            key={e.id}
          >
            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor: kDialogueBackground,
              }}
            >
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: 'black',

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          File name
                        </Typography>
                      </Box>
                    </Box>
                    <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Generated On
                      </Typography>
                    </Box>
                    {/* <Box display='flex' width='2%' paddingTop={0.2}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',
  
                            width: '2px',
                          }}
                        />
                      </Box> */}

                    {/* <Divider
                        orientation='horizontal'
                        flexItem
                        style={{
                          height: '2px',
                        }}
                      /> */}
                  </Box>
                  {/* <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',
                        }}
                      />
                    </Box> */}
                </Box>
              </Grid>
            </Grid>
            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor: 'white',
              }}
            >
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setDocumentRef(e)
                          setShowPreview(true)
                        }}
                      >
                        <FontAwesomeIcon
                          id={`${e.id}_selected_icon`}
                          icon={faFilePdf}
                          size='1x'
                          color={kPrimaryColor}
                          style={{
                            color: kPrimaryColor,
                            paddingTop: 1,
                          }}
                        />
                        <Box paddingLeft={0.5}>
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            style={{
                              color: 'black',
                              fontWeight: 'bold',
                              fontSize: 12,
                            }}
                          >
                            {getIdentifierValueBySystemTypeDAataVal(
                              e.identifier ?? [],
                              'fileName'
                            ).length > 0
                              ? getIdentifierValueBySystemTypeDAataVal(
                                  e.identifier ?? [],
                                  'fileName'
                                )
                              : e.content && e.content.length > 0
                              ? e.content[0].attachment
                                ? e.content[0].attachment.title ?? ''
                                : ''
                              : ''}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography variant='body2' color='initial'>
                        {e.meta!.lastUpdated
                          ? moment(e.meta!.lastUpdated).format('DD-MM-YYYY')
                          : ''}
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.2}>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',
    
                            width: '2px',
                          }}
                        />
                      </Box> */}
                </Box>
              </Grid>
            </Grid>
            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Grid>

      {showPreview && (
        <ReportReferencePopUp
          documentRef={documentRef!}
          appointmentId={appointmentId}
          showPreview={showPreview}
          onCloseClicked={() => {
            setDocumentRef(undefined)
            setShowPreview(false)
          }}
        />
      )}
    </Box>
  )
}
