/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable consistent-return */
import { Box } from '@material-ui/core'
import { AnyARecord } from 'dns'
import {
  CohortChartProps,
  CohortControlsProps,
  CohortDataStage,
  MapDataProps,
} from 'models/chartProps'
import { SankeyData } from 'models/sankeyData'
import { SankeyLink } from 'models/sankeyLinks'
import { SankeyNode } from 'models/sankeyNode'
import React, { useEffect, useState } from 'react'
import { getFilterLabel } from 'utils/cohort/cohortUtils'

type PersonSliderProps = {
  label: string
  id: string
  name: string
  baseMin: number
  min: number
  max: number
  step?: number
  value: number
  onChange: any
}

export const PersonSlider: React.FC<PersonSliderProps> = ({
  label,
  id,
  name,
  baseMin,
  min,
  max,
  step,
  value,
  onChange,
}: PersonSliderProps) => {
  console.log('455')
  return (
    <Box>
      <label htmlFor={name}>{label}</label>
      <input
        type='range'
        id={id}
        name={name}
        min={baseMin}
        max={max || 1000}
        step={step || 1}
        value={value}
        onChange={onChange}
      />
    </Box>
  )
}
