import Paper from '@material-ui/core/Paper'
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  withStyles,
  Box,
  Typography,
} from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { kSelectedTabBackground } from 'configs/styles/muiThemes'
import React from 'react'

export interface ToggleOption {
  value: string
  displayText: string
}

export interface ToggleOptionProps {
  options: ToggleOption[]
  preSelectedValue?: string
  onSelectionChanged: (option: string) => void
  menuLabel?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: `1px solid ${theme.palette.divider}`,
    },
    divider: {
      margin: theme.spacing(1, 0.5),
    },
  })
)

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup)

export default function CustomizedDividers(props: ToggleOptionProps) {
  const [value, setValue] = React.useState(
    props.preSelectedValue ?? props.options[0].value
  )

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      props.onSelectionChanged(newAlignment)
      setValue(newAlignment)
    }
  }

  const classes = useStyles()

  const defaultBackgrundColor = useTheme().palette.background.default
  const paperBackGroundColor = useTheme().palette.background.paper

  return (
    <div>
      <Box>
        <Typography
          variant='subtitle2'
          style={{
            textTransform: 'uppercase',
          }}
          id={props.menuLabel ?? ''}
        >
          &nbsp; {props.menuLabel}
        </Typography>
      </Box>
      <Paper elevation={0} className={classes.paper}>
        <StyledToggleButtonGroup
          size='small'
          value={value}
          exclusive
          onChange={handleAlignment}
          aria-label='text alignment'
          style={{
            backgroundColor: defaultBackgrundColor,
            margin: '0px',
            padding: '0px',
          }}
          id={`button_key${value}`}
        >
          {props.options.map((option, index: number) => (
            <ToggleButton
              id={`button_key${option.value}`}
              key={`tg_button_key${option.value}`}
              value={option.value}
              aria-label='left aligned'
              style={{
                background:
                  option.value === value
                    ? paperBackGroundColor
                    : defaultBackgrundColor,
              }}
            >
              <Typography
                variant='body2'
                style={{
                  color: kSelectedTabBackground,
                  marginTop: 1,
                  textAlign: 'center',
                }}
              >
                {option.displayText}
              </Typography>
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  )
}
