import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'

import { LabReferralService } from 'models/labOfferDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  resetReferralOfferingSearch,
  searchLabOfferings,
} from 'redux/administration/referralSetup/labTestSearch/referralLabOfferingSearchSlice'
import {
  requestLabOrder,
  resetLabDiagnosticCreationState,
} from 'redux/consultation/createLabDiagnosticsSlice/createLabDiagnosticsSlice'
import { RootState } from 'redux/rootReducer'
import { MasterDataSelecter } from 'views/components/lab/catalougeSetup/masterDataSelector'
import { LabReferralSelector } from 'views/components/lab/orderCreationManagement/labReferralSelector'
import { getIdentifierValueBySystem } from 'utils/fhirResourcesHelper'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import {
  addLabTestReferralDetailsForIPD,
  resetAddTestReferralsState,
} from 'redux/administration/referralSetup/addLabReferralIPD/addLabTestReferralsSliceIPD'
import { resetCatalogueSearchStatus } from 'redux/lab/masterDataSearch/masterDataSearchSlice'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  onClose: () => void
  onLabDiagnosticsAdded: () => void
}
export const AddLabReferralLabTests: React.FC<Props> = ({
  open,
  onClose,
  onLabDiagnosticsAdded,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common'])
  const [selectedPlanningDefs, setSelectedPlanDefs] = useState<
    R4.IPlanDefinition[]
  >([])

  const [service, setServices] = useState<LabReferralService>()
  const [addAnother, setAddAnother] = React.useState<boolean>(true)
  const [serviceArray, setServicesArray] = useState<LabReferralService[]>([])
  const [planDefArray, setPlanDefArray] = useState<R4.IPlanDefinition[]>([])
  const [addMore, setAddMore] = useState<boolean>(false)

  const dispatch = useDispatch()

  const createLabDiagnosticsSlice = useSelector(
    (state: RootState) => state.createLabDiagnosticsSlice
  )

  const addLabTestReferralsSlice = useSelector(
    (state: RootState) => state.addLabTestReferralsSliceIPD
  )

  const referralLabOfferingSlice = useSelector(
    (state: RootState) => state.referralLabOfferingSlice
  )

  useEffect(() => {
    if (addLabTestReferralsSlice.additionSuccessful) {
      setSelectedPlanDefs([])
      resetForm()
      onLabDiagnosticsAdded()
      dispatch(resetAddTestReferralsState())
    }
  }, [dispatch, addLabTestReferralsSlice, onLabDiagnosticsAdded])

  useEffect(() => {
    if (
      referralLabOfferingSlice.resultsAvailable &&
      referralLabOfferingSlice.healthCareService
    ) {
      setServices(referralLabOfferingSlice.healthCareService[0])
    }
    if (
      referralLabOfferingSlice.noResultsAvailable &&
      referralLabOfferingSlice.healthCareService
    ) {
      setServices(referralLabOfferingSlice.healthCareService[0])
    }
  }, [dispatch, referralLabOfferingSlice])

  function resetForm() {
    dispatch(resetLabDiagnosticCreationState())
    dispatch(resetReferralOfferingSearch())

    setServices(undefined)
    setAddMore(false)
  }

  function addCurrentDetails(pushToSave?: boolean) {
    const tempServiceArray: LabReferralService[] = [...serviceArray]
    if (service !== undefined) tempServiceArray.push(service!)
    Promise.resolve().then(() => {
      setServicesArray(tempServiceArray)
      console.log(tempServiceArray)

      resetForm()
    })
    if (pushToSave) {
      dispatch(
        addLabTestReferralDetailsForIPD(
          fhirAppointmentDetails,
          tempServiceArray,
          selectedPlanningDefs,
          ''
        )
      )
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetForm()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Add Diagnostic Test</DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#ededed',
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0,
        }}
      >
        <Box marginX={2} marginY={1} display='flex' flexDirection='column'>
          <Box marginY={1} display='flex' flexDirection='column'>
            <WelloFormItemLabel title='Select Test(s)/Panel(s)' />
            {addMore && (
              <MasterDataSelecter
                disabled={false}
                onSelectionChanges={(plans) => {
                  const temp: R4.IPlanDefinition[] = [...selectedPlanningDefs]
                  temp.push(plans)

                  dispatch(
                    searchLabOfferings(
                      '',
                      getIdentifierValueBySystem(
                        plans.identifier ?? [],
                        'http://loinc.org'
                      )
                    )
                  )

                  setSelectedPlanDefs(temp)
                }}
              />
            )}
            {!addMore && (
              <MasterDataSelecter
                disabled={false}
                onSelectionChanges={(plans) => {
                  const temp: R4.IPlanDefinition[] = [...selectedPlanningDefs]
                  temp.push(plans)

                  dispatch(
                    searchLabOfferings(
                      '',
                      getIdentifierValueBySystem(
                        plans.identifier ?? [],
                        'http://loinc.org'
                      )
                    )
                  )

                  setSelectedPlanDefs(temp)
                }}
              />
            )}
          </Box>
          {referralLabOfferingSlice.resultsAvailable &&
            referralLabOfferingSlice.healthCareService && (
              <Box marginY={1} display='flex' flexDirection='column'>
                <WelloFormItemLabel title='Select Lab' />
                <LabReferralSelector
                  data={referralLabOfferingSlice.healthCareService ?? []}
                  onSelectionChanges={(e: any) => {
                    setServices(e)
                  }}
                  preSelectedOrdersRoles={
                    referralLabOfferingSlice.healthCareService.length > 0
                      ? referralLabOfferingSlice.healthCareService[0]
                      : undefined
                  }
                  disabled={false}
                />
              </Box>
            )}

          <Box marginX={2} marginY={1} display='flex' flexDirection='column'>
            {referralLabOfferingSlice.searching && (
              <Box flexGrow width='100%' display='flex' flexDirection='row'>
                <CircularProgress size={15} />
              </Box>
            )}
            {referralLabOfferingSlice.error && (
              <Typography>
                {' '}
                {referralLabOfferingSlice.errorMessage ??
                  'Error while searching'}
              </Typography>
            )}
            {referralLabOfferingSlice.noResultsAvailable && (
              <Typography> No labs available</Typography>
            )}
          </Box>
          {/* {serviceArray.length > 0 && (
            <Box display='flex' flexDirection='row' width='100%'>
              <Box
                marginX={2}
                marginY={1}
                display='flex'
                flexDirection='column'
                width='100%'
              >
                <Typography
                  variant='subtitle1'
                  color='initial'
                  style={{ padding: '4px' }}
                >
                  Currently Added Medications
                </Typography>
                {addedMedications.map((e, index: number) => (
                  <Box py={1} key={e.medicationCode?.code ?? ''}>
                    <MedicationRequestTile
                      medication={e}
                      color={index % 2 === 0 ? 'white' : kDialogueBackground}
                      index={index}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          )} */}
        </Box>
      </DialogContent>
      <DialogActions>
        {addLabTestReferralsSlice.adding && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
          >
            <CircularProgress size={25} />
          </Box>
        )}
        <Box flexGrow={1} alignSelf='center' paddingX={1}>
          <FormControlLabel
            value='end'
            control={
              <Checkbox
                inputProps={{
                  id: 'add_another',
                }}
                color='primary'
                checked={addAnother}
                onChange={(eve, checked) => {
                  setAddAnother(checked)
                }}
              />
            }
            label='Add another test/panel'
            labelPlacement='end'
          />
        </Box>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Box display='flex' flexDirection='row'>
            <Box paddingX={1}>
              <Button
                onClick={() => {
                  resetForm()
                  onClose()
                }}
                variant='outlined'
                disableElevation
                id='cancel'
              >
                {t('labelCommon:cancel')}
              </Button>
            </Box>
            {addAnother && (
              <Box paddingX={1}>
                <Button
                  onClick={() => {
                    dispatch(resetCatalogueSearchStatus())
                    setAddMore(true)
                    setServices(undefined)
                    addCurrentDetails()
                  }}
                  id='another'
                  variant='contained'
                  color='primary'
                  disableElevation
                  disabled={
                    addLabTestReferralsSlice.adding ||
                    selectedPlanningDefs.length === 0
                  }
                >
                  {t('labelCommon:add_more_new')}
                </Button>
              </Box>
            )}

            <Box paddingX={1}>
              <Button
                onClick={() => {
                  addCurrentDetails(true)
                }}
                variant='contained'
                color='primary'
                disableElevation
                disabled={
                  addLabTestReferralsSlice.adding &&
                  selectedPlanningDefs.length === 0
                }
                id='save'
              >
                {t('labelCommon:Save')}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
