import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { getCodesOfValueSetFromTerminologyService } from 'utils/valusetsHelpers'
import match from 'autosuggest-highlight/match'
import { Box } from '@material-ui/core'
import _ from 'lodash'
import { WelloFormItemLabel } from '../LeftMenu/WelloFormItemLabel'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

export interface ValueSetSearchProps {
  id: string
  url: string
  title: string
  preSelectedOptions?: R4.ICoding
  preEnteredText?: string
  onOptionSelected: (selectedOption?: R4.ICoding) => void
  helperText?: string
  disabled: boolean
  fuzzySearch?: boolean
  alwaysShowAllOptions?: boolean
  isAyurveda?: boolean
  placeHolder?: string
}

export const ValueSetSelectSingle: React.FC<ValueSetSearchProps> = ({
  id,
  url,
  title,
  preSelectedOptions,
  preEnteredText,
  onOptionSelected,
  helperText,
  disabled,
  fuzzySearch = false,
  alwaysShowAllOptions = false,
  isAyurveda,
  placeHolder,
}) => {
  const classes = useStyles()
  const [value, setValue] = React.useState<R4.ICoding | null>(
    preSelectedOptions ?? null
  )
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<R4.ICoding[]>([])
  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = React.useState<boolean>(!_.isEmpty(preEnteredText))

  function isSameAsSelectedOption(option: string) {
    if (value && value.code === option) {
      return true
    }
    return false
  }

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: R4.ICoding[]) => void
        ) => {
          setLoading(true)
          if (alwaysShowAllOptions) {
            if (options.length === 0) {
              getCodesOfValueSetFromTerminologyService({
                valueSetUrl: url,
                fuzzySearch,
                searchString: '',
              }).then((response) => {
                setLoading(false)
                setOptions(response)
              })
            } else {
              setLoading(false)
              callback(options)
            }
          } else {
            getCodesOfValueSetFromTerminologyService({
              valueSetUrl: url,
              fuzzySearch,
              searchString: request.input,
            }).then((response) => {
              setLoading(false)
              callback(response)
            })
          }
        },
        200
      ),
    []
  )

  React.useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results?: R4.ICoding[]) => {
      if (active) {
        let newOptions = [] as R4.ICoding[]

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  React.useEffect(() => {
    if (!_.isEmpty(preEnteredText)) {
      setInputValue(preEnteredText ?? ' ')
      setLoading(true)
    }
  }, [])

  return (
    <Box display='flex' flexDirection='column' flexGrow={isAyurveda ? 0 : 1}>
      {title.length > 0 && <WelloFormItemLabel title={title} />}

      <Autocomplete
        id={id}
        getOptionLabel={(option) =>
          typeof option === 'string'
            ? option
            : _.capitalize(option.display) ?? ''
        }
        open={open}
        onOpen={() => {
          setInputValue(inputValue.length === 0 ? ' ' : inputValue)

          setOpen(true)
        }}
        autoFocus={!_.isEmpty(preEnteredText)}
        onClose={() => {
          setOpen(false)
        }}
        filterOptions={(x) => x}
        options={options}
        disabled={disabled}
        autoComplete
        loading={loading}
        getOptionSelected={(option, val) => option.code === val.code}
        filterSelectedOptions={!alwaysShowAllOptions}
        disableClearable={true}
        inputValue={inputValue}
        value={value ?? undefined}
        onChange={(event: any, newValue: R4.ICoding | null) => {
          setOptions(newValue ? [newValue, ...options] : options)
          setValue(newValue)
          onOptionSelected(newValue === null ? undefined : newValue)
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id={`${id}_ edit`}
            variant='outlined'
            placeholder={placeHolder ?? ''}
            fullWidth
            inputProps={{ ...params.inputProps, maxLength: '256' }}
            size='small'
            helperText={helperText}
          />
        )}
        renderOption={(option) => {
          const matches = match(_.capitalize(option.display ?? ''), inputValue)
          const parts = parse(_.capitalize(option.display ?? ''), matches)

          return (
            <Grid container alignItems='center'>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={part.text}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </Grid>
            </Grid>
          )
        }}
      />
    </Box>
  )
}
