import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import _ from 'lodash'
import React from 'react'
import { getPriorityCodeDisplayFromCode } from 'utils/appointment_handle/medications_util'
import { KriyaDetail } from '../plan/ayurveda/addKriyasForEncounter'
import OverflowTypography from '../plan/ayurveda/overflowTypography'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      //   border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '4px',
      margin: '0px',
    },
  },
})

interface KriyaListProps {
  kriyasList: KriyaDetail[]
}

export const AddedKriyasListForPrescription: React.FC<KriyaListProps> = ({
  kriyasList,
}: KriyaListProps) => {
  const classes = useStyles()

  return (
    <TableContainer
      style={{
        width: '100%',
        maxWidth: '100%',
        margin: '0px',
        padding: '0px',
      }}
    >
      <Table
        className={classes.table}
        aria-label='simple table'
        style={{
          tableLayout: 'fixed',
        }}
      >
        <TableHead>
          <TableRow
            style={{
              fontWeight: 'bold',
            }}
          >
            <TableCell width={25} style={{ borderBottom: 'none' }}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Kriya
              </Typography>
            </TableCell>
            <TableCell width={35} style={{ borderBottom: 'none' }}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Medicine
              </Typography>
            </TableCell>
            <TableCell width={10} style={{ borderBottom: 'none' }}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Priority
              </Typography>
            </TableCell>

            <TableCell width={30} style={{ borderBottom: 'none' }}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Additional Notes
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {kriyasList.map((row: KriyaDetail, index) => (
            <TableRow key={row.kriya?.code ?? ''}>
              <TableCell style={{ borderBottom: 'none' }}>
                <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  {_.capitalize(row.kriya?.display ?? '')}
                </Typography>
              </TableCell>
              <TableCell style={{ borderBottom: 'none' }}>
                <Box display='flex'>
                  <OverflowTypography
                    text={(row.medications ?? [])
                      .map((med) => med.display)
                      .join(', ')}
                    typographyProps={{
                      variant: 'body2',
                      color: 'initial',
                    }}
                  />
                </Box>
              </TableCell>
              <TableCell style={{ borderBottom: 'none' }}>
                <Typography color='initial' variant='body2'>
                  {row.priority
                    ? getPriorityCodeDisplayFromCode(row.priority)
                    : ''}
                </Typography>
              </TableCell>

              <TableCell style={{ borderBottom: 'none' }}>
                <OverflowTypography
                  text={row.remarks ?? ''}
                  typographyProps={{
                    variant: 'body2',
                    color: 'initial',
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
