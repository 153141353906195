import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

interface Props {
  currency: string
  totalAmount: number
}
export const TotalAmount: React.FC<Props> = ({
  currency,
  totalAmount,
}: Props) => {
  const { t } = useTranslation()

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
        </div>
      )}
    >
      <Box display='flex' flexDirection='row' width='100%'>
        <Box display='flex' flexDirection='column' flexGrow={1}>
          <Typography variant='h6' color='initial'>
            Total
          </Typography>
        </Box>

        <Box display='flex' flexDirection='column'>
          <Box display='flex' flexDirection='row' flexGrow={1}>
            <Typography
              variant='h6'
              color='initial'
              style={{ paddingRight: 1 }}
            >
              ₹{' '}
            </Typography>
            <Typography variant='h6' color='initial' style={{ paddingLeft: 2 }}>
              {totalAmount.toFixed(2)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </ErrorBoundary>
  )
}
