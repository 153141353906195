import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { LabReferralService } from 'models/labOfferDetail'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { addAndGetEncounterDetailsOfAppointment } from 'utils/appointment_handle/cds_recommendations_util'
import { createBundleObjectForRequests } from 'utils/appointment_handle/medications_util'
import { getEncounterObjectForAppointment } from 'utils/fhirResoureHelpers/appointmentHelpers'
import {
  createBundleObjectForLabReferral,
  createBundleObjectForLabReferralForNoReferral,
} from 'utils/fhirResoureHelpers/referralHelpers'
import { getLoincCodeFromPlanDef } from 'utils/patientHelper/cds_helper'
import { MedicationAddStatus as AddWellnessReferralsStatusTypes } from './addLabTestReferralsStatusTypes'

const initialState: AddWellnessReferralsStatusTypes = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addLabTestReferralSlice = createSlice({
  name: 'addLabTestReferralSlice',
  initialState,
  reducers: {
    updateAddMedicationsStatus(
      state,
      action: PayloadAction<AddWellnessReferralsStatusTypes>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.allergy = action.payload.allergy
    },

    resetMedicationsDetails(
      state,
      action: PayloadAction<AddWellnessReferralsStatusTypes>
    ) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.allergy = initialState.allergy
    },
  },
})

export const addLabTestReferralDetails =
  (
    appointment: FhirAppointmentDetail,
    labReferrals: LabReferralService[],
    selectedPlans: R4.IPlanDefinition[],
    extraInfo?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AddWellnessReferralsStatusTypes = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(
      addLabTestReferralSlice.actions.updateAddMedicationsStatus(addingState)
    )
    const workflowClient = new EnrolCient()

    let encounterData: R4.IEncounter | undefined = appointment.encounter
    if (encounterData === undefined) {
      encounterData = await addAndGetEncounterDetailsOfAppointment(appointment)
    }
    const encounter: R4.IEncounter =
      getEncounterObjectForAppointment(appointment)

    try {
      const bodyReferrals: any[] = []
      let finalServiceData: LabReferralService[] = []
      const plans: R4.IPlanDefinition[] = []
      if (labReferrals.length > 0) {
        const data = labReferrals.filter(
          (d: LabReferralService) => d.healthService.id === undefined
        )
        const dataWithHv = labReferrals.filter(
          (d: LabReferralService) => d.healthService.id !== undefined
        )
        finalServiceData = dataWithHv
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            plans.push(data[i].planDefinition)
          }
        }
      }

      for (let i = 0; i < selectedPlans.length; i++) {
        if (labReferrals.length > 0) {
          for (let j = 0; j < labReferrals.length; j++) {
            if (
              getLoincCodeFromPlanDef(labReferrals[j].planDefinition) !==
              getLoincCodeFromPlanDef(selectedPlans[i])
            )
              plans.push(selectedPlans[i])
          }
        } else {
          plans.push(selectedPlans[i])
        }
      }

      const uniquePlans = plans.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => getLoincCodeFromPlanDef(t) === getLoincCodeFromPlanDef(value)
          )
      )

      if (uniquePlans.length > 0) {
        const bundleObject: R4.IBundle =
          createBundleObjectForLabReferralForNoReferral(
            appointment,
            encounterData!,
            uniquePlans,
            ''
          )

        const fhirClient: FHIRApiClient = new FHIRApiClient()
        const response = await fhirClient.doCreateFHIRTransaction(
          '',
          bundleObject
        )
        if (finalServiceData.length === 0) {
          const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
            R4.RTTI_Bundle.decode(response)
          if (relatedFhirDecodeRes._tag === 'Right') {
            if (relatedFhirDecodeRes.right) {
              addingState = {
                adding: false,
                additionSuccessful: true,
                error: false,
                errorMessage: '',
              }
              dispatch(
                addLabTestReferralSlice.actions.updateAddMedicationsStatus(
                  addingState
                )
              )
            } else {
              const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
                adding: false,
                additionSuccessful: false,
                error: true,
                errorMessage: 'Error while  details',
              }
              dispatch(
                addLabTestReferralSlice.actions.updateAddMedicationsStatus(
                  errorCreatePersonState
                )
              )
              return
            }
          } else {
            const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
              adding: false,
              additionSuccessful: false,
              error: true,
              errorMessage: 'Error while adding  details',
            }
            dispatch(
              addLabTestReferralSlice.actions.updateAddMedicationsStatus(
                errorCreatePersonState
              )
            )
            return
          }
        }
      }
      if (finalServiceData.length > 0) {
        const hvService: string[] = []
        for (let i = 0; i < finalServiceData.length; i++) {
          if (finalServiceData[i].healthService.id !== undefined) {
            hvService.push(finalServiceData[i].healthService.id ?? '')
          }
        }

        const uniqueArray = _.uniq(hvService)

        for (let i = 0; i < uniqueArray.length; i++) {
          const labTestArray: any[] = []
          for (let j = 0; j < finalServiceData.length; j++) {
            if (uniqueArray[i] === finalServiceData[j].healthService.id) {
              labTestArray.push({
                system: 'http://loinc.org',
                code: getLoincCodeFromPlanDef(
                  finalServiceData[j].planDefinition
                ),
                display: finalServiceData[j].planDefinition.title ?? '',
              })
            }
          }
          const body = {
            referralServiceId: uniqueArray[i],
            encounterId: encounterData ? encounterData.id! : '',
            labTests: labTestArray,
          }
          bodyReferrals.push(body)
        }

        const res = await workflowClient.doCreateEnrolmentFlowRequest(
          'referral/lab-referral',
          {
            patientId: appointment.patient.id,
            labReferralDetails: bodyReferrals,
          }
        )

        if (res[0].entry && res[0].entry.length > 0) {
          const bundleObject: R4.IBundle = createBundleObjectForLabReferral(
            appointment,

            encounterData!,
            finalServiceData,
            ''
          )

          const fhirClient: FHIRApiClient = new FHIRApiClient()
          const response = await fhirClient.doCreateFHIRTransaction(
            '',
            bundleObject
          )
          const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
            R4.RTTI_Bundle.decode(response)

          if (relatedFhirDecodeRes._tag === 'Right') {
            if (relatedFhirDecodeRes.right) {
              addingState = {
                adding: false,
                additionSuccessful: true,
                error: false,
                errorMessage: '',
              }
              dispatch(
                addLabTestReferralSlice.actions.updateAddMedicationsStatus(
                  addingState
                )
              )
            } else {
              const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
                adding: false,
                additionSuccessful: false,
                error: true,
                errorMessage: 'Error while referral details',
              }
              dispatch(
                addLabTestReferralSlice.actions.updateAddMedicationsStatus(
                  errorCreatePersonState
                )
              )
              return
            }
          } else {
            const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
              adding: false,
              additionSuccessful: false,
              error: true,
              errorMessage: 'Error while adding referral details',
            }
            dispatch(
              addLabTestReferralSlice.actions.updateAddMedicationsStatus(
                errorCreatePersonState
              )
            )
            return
          }
        } else {
          const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
            adding: false,
            additionSuccessful: false,
            error: true,
            errorMessage:
              'Error while creating referral details in other organizationß',
          }
          dispatch(
            addLabTestReferralSlice.actions.updateAddMedicationsStatus(
              errorCreatePersonState
            )
          )
          return
        }
      }
    } catch (error) {
      const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding',
      }
      dispatch(
        addLabTestReferralSlice.actions.updateAddMedicationsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const addCDSMedicationsDetails =
  (
    appointment: FhirAppointmentDetail,
    medications: R4.IMedicationRequest[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AddWellnessReferralsStatusTypes = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(
      addLabTestReferralSlice.actions.updateAddMedicationsStatus(addingState)
    )
    const encounter: R4.IEncounter =
      getEncounterObjectForAppointment(appointment)

    try {
      const bundleObject: R4.IBundle = createBundleObjectForRequests(
        appointment,
        encounter,
        medications
      )

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        bundleObject
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)

      if (relatedFhirDecodeRes._tag === 'Right') {
        if (relatedFhirDecodeRes.right) {
          addingState = {
            adding: false,
            additionSuccessful: true,

            error: false,
            errorMessage: '',
          }
          dispatch(
            addLabTestReferralSlice.actions.updateAddMedicationsStatus(
              addingState
            )
          )
          dispatch(showSuccessAlert('Medications added successfully'))
        } else {
          const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
            adding: false,
            additionSuccessful: false,
            error: true,
            errorMessage: 'Error while allergy details',
          }
          dispatch(
            addLabTestReferralSlice.actions.updateAddMedicationsStatus(
              errorCreatePersonState
            )
          )
          return
        }
      } else {
        const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while adding medications',
        }
        dispatch(
          addLabTestReferralSlice.actions.updateAddMedicationsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: AddWellnessReferralsStatusTypes = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding',
      }
      dispatch(
        addLabTestReferralSlice.actions.updateAddMedicationsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetAddTestReferralsState = () => (dispatch: AppDispatch) => {
  dispatch(
    addLabTestReferralSlice.actions.resetMedicationsDetails(initialState)
  )
}

export default addLabTestReferralSlice.reducer
