import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { textMain } from 'configs/styles/muiThemes'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CardsEntity } from 'redux/patientMedicalHistory/cds_recommendations/cdsResponse'
import { RootState } from 'redux/rootReducer'
import { getWelloMedicationFromMedicationRequest } from 'utils/appointment_handle/medications_util'
import { getIdOfCardEntry } from 'utils/careplan_utils/careplan_utils'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getDisplayOfSystemFromCodableConcept,
  getSpecializationsList,
} from 'utils/fhirResourcesHelper'
import {
  getAddressingConditions,
  getAddressingConditionsFromCarePlan,
  getAppointments,
  getCarePlanDescriptions,
  getCarePlans,
  getErrors,
  getGoalFromEntries,
  getInstructions,
  getJustificationOfPlanDef,
  getLabTestsOfType,
  getLoincCodeFromPlanDef,
  getMedicationOfTypeInCard,
  getMedications,
  getRepeatedAppointments,
  getServiceRequests,
  getTestsOfTypeInCard,
  getTestsToBePerformed,
  hasError,
  hasLabTestsOfType,
  isEssential,
} from 'utils/patientHelper/cds_helper'
import { AddCareTeamFollowUpsButtonIPD } from 'views/components/ipdconusltation/plan/cdsRecomendations/add_care_follow_ups_buttonIPD'
import { AddInstructionsButtonIPD } from 'views/components/ipdconusltation/plan/cdsRecomendations/add_instructions_buttonIPD'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))
interface CdsCardDetailsProps {
  patient: R4.IPatient
  fhirPatientDetail: FhirActiveIPDDetailsForMedicalRole
  cdsCard: CardsEntity
  mainIndex: string
}

export const CdsCardDetailsIPD: React.FC<CdsCardDetailsProps> = ({
  patient,
  fhirPatientDetail,
  cdsCard: card,
  mainIndex,
}: CdsCardDetailsProps) => {
  const initialFetch = useRef(true)

  const [value, setValue] = React.useState(0)

  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const [selectedTestsToOrder, setSelectedTestsToOrder] = useState<string[]>(
    getTestsOfTypeInCard(card, 'essential').map(
      (e) => getLoincCodeFromPlanDef(e)!
    )
  )

  const [selectedMedications, setSelectedMedications] = useState<string[]>(
    getMedicationOfTypeInCard(card).map(
      (e) => getDisplayOfSystemFromCodableConcept(e.medicationCodeableConcept)!
    )
  )

  const patientMedicalTimeLineSlice = useSelector(
    (state: RootState) => state.cdsRecommendationsSlice
  )

  function onSelectedTestsChanged(changedTest: string) {
    let temp: string[] = [...selectedTestsToOrder]
    const res = temp.findIndex((e) => e === changedTest)
    if (res >= 0) {
      temp = temp.filter((e) => e !== changedTest)
    } else {
      temp.push(changedTest)
    }

    setSelectedTestsToOrder(temp)
  }

  function onSelectedMedicationsChanged(changedTest: string) {
    let temp: string[] = [...selectedMedications]
    const res = temp.findIndex((e) => e === changedTest)
    if (res >= 0) {
      temp = temp.filter((e) => e !== changedTest)
    } else {
      temp.push(changedTest)
    }

    setSelectedMedications(temp)
  }

  return (
    <Box display='flex' flexDirection='column' py={0.25} overflow='auto'>
      {hasError(card) && (
        <Box display='flex' flexDirection='column' py={0.25} overflow='auto'>
          <Typography variant='subtitle1' color='initial'>
            Error while fetching recommendations.
          </Typography>
          <List aria-label='error'>
            {getErrors(card)?.map((error) => (
              <ListItem key={value} disableGutters>
                <ListItemText primary={error} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      {!hasError(card) &&
        card.suggestions &&
        card.suggestions?.map((each) => (
          <Box
            display='flex'
            flexDirection='column'
            key={`${each.label}_label`}
            py={0.25}
            pl={2}
            overflow='auto'
          >
            {/* <Typography variant="subtitle2" color="initial">
                            {getIdOfCardEntry(card) ?? 'Id not available'}
                        </Typography> */}
            {/* <Typography variant='subtitle1' color='initial'>
                          {each.label}
                        </Typography> */}
            {each.actions && each.actions.length > 0 && (
              <Box
                display='flex'
                flexDirection='column'
                key={`${each.label}_each_label`}
                py={0.25}
                overflow='auto'
              >
                {getGoalFromEntries(
                  (each.actions[0].resource as R4.IBundle).entry ?? []
                ).length > 0 && (
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel1a-content'
                      id={`goal-${mainIndex}`}
                      expandIcon={<ExpandMore />}
                    >
                      <Typography className={classes.titleText}>
                        Goal
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        display='inline'
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {getGoalFromEntries(
                          (each.actions[0].resource as R4.IBundle).entry ?? []
                        ).length > 0 &&
                          getGoalFromEntries(
                            (each.actions[0].resource as R4.IBundle).entry ?? []
                          ).map((app) => (
                            <Typography
                              className={classes.subTitleText}
                              key={app.id}
                            >
                              {app.description.text ?? ''}
                            </Typography>
                          ))}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}

                {getAddressingConditions(
                  (each.actions[0].resource as R4.IBundle).entry ?? []
                ).length > 0 && (
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel1a-content'
                      id={`Conditions-${mainIndex}`}
                      expandIcon={<ExpandMore />}
                    >
                      <Typography className={classes.titleText}>
                        Conditions
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {getCarePlans(
                        (each.actions[0].resource as R4.IBundle).entry ?? []
                      ).length > 0 &&
                        getCarePlans(
                          (each.actions[0].resource as R4.IBundle).entry ?? []
                        ).map((app) => (
                          <Box key={app.title ?? app.id}>
                            {getAddressingConditionsFromCarePlan(app).length >
                              0 &&
                              getAddressingConditionsFromCarePlan(app).map(
                                (cond) => (
                                  <Typography
                                    className={classes.subTitleText}
                                    key={getDisplayOfSystemFromCodableConcept(
                                      cond.code
                                    )}
                                  >
                                    {getDisplayOfSystemFromCodableConcept(
                                      cond.code
                                    )}
                                  </Typography>
                                )
                              )}
                          </Box>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                )}

                {getAppointments(
                  (each.actions[0].resource as R4.IBundle).entry ?? []
                ).length > 0 && (
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel1a-content'
                      id={`ref-${mainIndex}`}
                      expandIcon={<ExpandMore />}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        alignContent='center'
                        alignItems='center'
                        key={each.label}
                        width='100%'
                        overflow='auto'
                      >
                        <Typography className={classes.titleText}>
                          Specialist Referral
                        </Typography>
                        {/* <AddReferralsButton
                          fhirAppointmentDetails={fhirPatientDetail}
                          carePlanIdentifier={getIdOfCardEntry(card)}
                          appointments={getAppointments(
                            (each.actions![0].resource as R4.IBundle).entry ??
                              []
                          ).map(
                            (e) =>
                              getDefaultCodingOfSystemFromCodableConceptList(
                                e.specialty!
                              )!
                          )}
                        /> */}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {getAppointments(
                        (each.actions[0].resource as R4.IBundle).entry ?? []
                      ).length > 0 &&
                        getAppointments(
                          (each.actions[0].resource as R4.IBundle).entry ?? []
                        ).map((app) => (
                          <Typography
                            className={classes.subTitleText}
                            key={getSpecializationsList(app.specialty ?? [])}
                          >
                            {getSpecializationsList(app.specialty ?? [])}
                          </Typography>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                )}

                {getTestsToBePerformed(
                  (each.actions[0].resource as R4.IBundle).entry ?? []
                ).length > 0 && (
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel1a-content'
                      id={`labtest-${mainIndex}`}
                      expandIcon={<ExpandMore />}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        alignContent='center'
                        alignItems='center'
                        key={each.label}
                        width='100%'
                        overflow='auto'
                      >
                        <Typography className={classes.titleText}>
                          Lab Tests
                        </Typography>
                        {/* <AddLabTestsButton
                          fhirAppointmentDetails={fhirPatientDetail}
                          carePlanIdentifier={getIdOfCardEntry(card)}
                          tests={getTestsToBePerformed(
                            (each!.actions![0].resource as R4.IBundle).entry ??
                              []
                          ).filter((e) =>
                            selectedTestsToOrder.includes(
                              getLoincCodeFromPlanDef(e) ?? ''
                            )
                          )}
                        /> */}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {getTestsToBePerformed(
                        (each.actions[0].resource as R4.IBundle).entry ?? []
                      ).length > 0 && (
                        <Box display='flex' flexDirection='row' width='100%'>
                          {hasLabTestsOfType(
                            getTestsToBePerformed(
                              (each.actions[0].resource as R4.IBundle).entry ??
                                []
                            ),
                            'http://hl7.org/fhir/uv/order-catalog/CodeSystem/laboratory-service-definition-type',
                            'test'
                          ) && (
                            <Box
                              display='flex'
                              flexDirection='column'
                              flexGrow='1'
                              minWidth='50%'
                            >
                              <Typography variant='h6' color='initial'>
                                Tests
                              </Typography>
                              {getLabTestsOfType(
                                getTestsToBePerformed(
                                  (each.actions[0].resource as R4.IBundle)
                                    .entry ?? []
                                ),
                                'http://hl7.org/fhir/uv/order-catalog/CodeSystem/laboratory-service-definition-type',
                                'test'
                              )!.map((eachTest: R4.IPlanDefinition, index) => (
                                <ListItem
                                  key={`${
                                    eachTest.name ?? `${index}test`
                                  }list_item`}
                                  id={`${index}test`}
                                  onChange={(e) => {
                                    onSelectedTestsChanged(
                                      getLoincCodeFromPlanDef(eachTest) ?? ''
                                    )
                                  }}
                                  dense
                                  style={{
                                    padding: '0px',
                                    paddingLeft: '2px',
                                    margin: '0px',
                                  }}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      edge='start'
                                      size='small'
                                      checked={
                                        selectedTestsToOrder.findIndex(
                                          (e) =>
                                            e ===
                                            getLoincCodeFromPlanDef(eachTest)
                                        ) !== -1
                                      }
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        'aria-labelledby': `${eachTest.name}list_item_id`,
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={`${eachTest.name}list_item_id`}
                                    primary={eachTest.title ?? ''}
                                    primaryTypographyProps={{
                                      variant: 'subtitle1',
                                      style: {
                                        color: isEssential(eachTest)
                                          ? textMain
                                          : '#4d4d4d',
                                      },
                                    }}
                                    secondary={
                                      <Typography
                                        className={classes.subTitleText}
                                        display='inline'
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            getJustificationOfPlanDef(
                                              eachTest
                                            ) ?? '',
                                        }}
                                      />
                                    }
                                  />
                                </ListItem>
                              ))}
                            </Box>
                          )}

                          {hasLabTestsOfType(
                            getTestsToBePerformed(
                              (each.actions[0].resource as R4.IBundle).entry ??
                                []
                            ),
                            'http://hl7.org/fhir/uv/order-catalog/CodeSystem/laboratory-service-definition-type',
                            'panel'
                          ) && (
                            <Box
                              display='flex'
                              flexDirection='column'
                              flexGrow='1'
                              minWidth='50%'
                            >
                              <Typography variant='h6' color='initial'>
                                Panels
                              </Typography>
                              {getLabTestsOfType(
                                getTestsToBePerformed(
                                  (each.actions[0].resource as R4.IBundle)
                                    .entry ?? []
                                ),
                                'http://hl7.org/fhir/uv/order-catalog/CodeSystem/laboratory-service-definition-type',
                                'panel'
                              )!.map((eachTest: R4.IPlanDefinition, index) => (
                                <ListItem
                                  key={`${
                                    eachTest.name ?? `${index}test`
                                  }list_item`}
                                  onChange={(e) => {
                                    onSelectedTestsChanged(
                                      getLoincCodeFromPlanDef(eachTest) ?? ''
                                    )
                                  }}
                                  dense
                                  style={{
                                    padding: '0px',
                                    paddingLeft: '2px',
                                    margin: '0px',
                                  }}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      edge='start'
                                      size='small'
                                      checked={
                                        selectedTestsToOrder.findIndex(
                                          (e) =>
                                            e ===
                                            getLoincCodeFromPlanDef(eachTest)
                                        ) !== -1
                                      }
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        id: `${eachTest.name}list_item_id`,
                                        'aria-labelledby': `${eachTest.name}list_item_id`,
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={`${eachTest.name}list_item_id`}
                                    primary={eachTest.title ?? ''}
                                    primaryTypographyProps={{
                                      variant: 'subtitle1',
                                      style: {
                                        color: isEssential(eachTest)
                                          ? textMain
                                          : '#4d4d4d',
                                      },
                                    }}
                                    secondary={
                                      <Typography
                                        className={classes.subTitleText}
                                        display='inline'
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            getJustificationOfPlanDef(
                                              eachTest
                                            ) ?? '',
                                        }}
                                      />
                                    }
                                  />
                                </ListItem>
                              ))}
                            </Box>
                          )}
                        </Box>
                      )}
                      {/*   {getTestsToBePerformed(
                                                    (each.actions[0].resource as R4.IBundle).entry ?? []
                                                ).length > 0 && (
                                                        <List>
                                                            {getTestsToBePerformed(
                                                                (each.actions[0].resource as R4.IBundle).entry ?? []
                                                            ).map((eachTest: R4.IPlanDefinition, index) => (
                                                                <ListItem
                                                                    key={`${eachTest.name ?? `${index}test`
                                                                        }list_item`}
                                                                    onChange={(e) => {
                                                                        onSelectedTestsChanged(
                                                                            getLoincCodeFromPlanDef(eachTest) ?? ''
                                                                        )
                                                                    }}
                                                                    dense
                                                                    style={{
                                                                        padding: '0px',
                                                                        paddingLeft: '2px',
                                                                        margin: '0px',
                                                                    }}
                                                                >
                                                                    <ListItemIcon>
                                                                        <Checkbox
                                                                            edge='start'
                                                                            size='small'
                                                                            checked={
                                                                                selectedTestsToOrder.findIndex(
                                                                                    (e) =>
                                                                                        e === getLoincCodeFromPlanDef(eachTest)
                                                                                ) !== -1
                                                                            }
                                                                            tabIndex={-1}
                                                                            disableRipple
                                                                            inputProps={{
                                                                                'aria-labelledby': `${eachTest.name}list_item_id`,
                                                                            }}
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        id={`${eachTest.name}list_item_id`}
                                                                        primary={eachTest.title ?? ''}
                                                                        primaryTypographyProps={{
                                                                            variant: 'subtitle1',
                                                                            style: {
                                                                                color: isEssential(eachTest)
                                                                                    ? textMain
                                                                                    : '#4d4d4d',
                                                                            },
                                                                        }}
                                                                        secondary={
                                                                            <Typography
                                                                                className={classes.subTitleText}
                                                                                display='inline'
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html:
                                                                                        getJustificationOfPlanDef(eachTest) ??
                                                                                        '',
                                                                                }}
                                                                            />
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    )} */}
                    </AccordionDetails>
                  </Accordion>
                )}

                {getMedications(
                  (each.actions[0].resource as R4.IBundle).entry ?? []
                ).length > 0 && (
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel1a-content'
                      id={`medication-${mainIndex}`}
                      expandIcon={<ExpandMore />}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        alignContent='center'
                        alignItems='center'
                        key={each.label}
                        width='100%'
                        overflow='auto'
                      >
                        <Typography className={classes.titleText}>
                          Medications
                        </Typography>
                        {/* <AddMedicationRecommendationButton
                          fhirAppointmentDetails={fhirPatientDetail}
                          carePlanIdentifier={getIdOfCardEntry(card)}
                          medications={getMedications(
                            (each.actions![0].resource as R4.IBundle).entry ??
                              []
                          ).filter((e) =>
                            selectedMedications.includes(
                              getDisplayOfSystemFromCodableConcept(
                                e.medicationCodeableConcept
                              )!
                            )
                          )}
                        /> */}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {getMedications(
                        (each.actions[0].resource as R4.IBundle).entry ?? []
                      ).length > 0 && (
                        <List>
                          {getMedications(
                            (each.actions[0].resource as R4.IBundle).entry ?? []
                          ).map((eachTest: R4.IMedicationRequest) => (
                            <ListItem
                              key={`${getDisplayOfSystemFromCodableConcept(
                                eachTest.medicationCodeableConcept
                              )}list_item`}
                              onChange={(e) => {
                                onSelectedMedicationsChanged(
                                  getDisplayOfSystemFromCodableConcept(
                                    eachTest.medicationCodeableConcept
                                  ) ?? ''
                                )
                              }}
                              dense
                              style={{
                                padding: '0px',
                                paddingLeft: '2px',
                                margin: '0px',
                              }}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge='start'
                                  size='small'
                                  checked={
                                    selectedMedications.findIndex(
                                      (e) =>
                                        e ===
                                        getDisplayOfSystemFromCodableConcept(
                                          eachTest.medicationCodeableConcept
                                        )
                                    ) !== -1
                                  }
                                  disabled
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{
                                    'aria-labelledby': `${getDisplayOfSystemFromCodableConcept(
                                      eachTest.medicationCodeableConcept
                                    )}list_item_id`,
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                id={`${getDefaultCodeOfSystemFromCodableConcept(
                                  eachTest.medicationCodeableConcept
                                )}list_item_id`}
                                primary={
                                  getDisplayOfSystemFromCodableConcept(
                                    eachTest.medicationCodeableConcept
                                  ) ??
                                  getDefaultCodeOfSystemFromCodableConcept(
                                    eachTest.medicationCodeableConcept
                                  ) ??
                                  ''
                                }
                                primaryTypographyProps={{
                                  variant: 'subtitle1',
                                  style: {
                                    color: isEssential(eachTest)
                                      ? textMain
                                      : '#4d4d4d',
                                  },
                                }}
                                secondary={
                                  getWelloMedicationFromMedicationRequest(
                                    eachTest
                                  ).textForm ?? ''
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}
                {getCarePlanDescriptions(
                  (each.actions[0].resource as R4.IBundle).entry ?? []
                ).length > 1 && (
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel1a-content'
                      id={`careTeam-${mainIndex}`}
                      expandIcon={<ExpandMore />}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        alignContent='center'
                        alignItems='center'
                        key={each.label}
                        width='100%'
                        overflow='auto'
                      >
                        <Typography className={classes.titleText}>
                          Care Team Follow Ups
                        </Typography>
                        <Box flexDirection='row'>
                          <AddCareTeamFollowUpsButtonIPD
                            carePanBundle={
                              each.actions?.[0].resource as R4.IBundle
                            }
                            fhirAppointmentDetails={fhirPatientDetail}
                            carePlanIdentifier={getIdOfCardEntry(card)}
                          />
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {getCarePlanDescriptions(
                        (each.actions[0].resource as R4.IBundle).entry ?? []
                      ).length > 0 &&
                        getCarePlans(
                          (each.actions[0].resource as R4.IBundle).entry ?? []
                        ).map((app) => (
                          <Typography
                            className={classes.subTitleText}
                            style={{
                              whiteSpace: 'pre-line',
                            }}
                            key={app.id}
                          >
                            {app.description ?? ''}
                          </Typography>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                )}
                {getInstructions(
                  (each.actions[0].resource as R4.IBundle).entry ?? []
                ).length > 0 && (
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel1a-content'
                      id='ins-header'
                      expandIcon={<ExpandMore />}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        alignContent='center'
                        alignItems='center'
                        key={each.label}
                        width='100%'
                        overflow='auto'
                      >
                        <Typography className={classes.titleText}>
                          Instructions
                        </Typography>
                        <AddInstructionsButtonIPD
                          carePanBundle={getInstructions(
                            (each.actions?.[0].resource as R4.IBundle).entry ??
                              []
                          )}
                          carePlanIdentifier={getIdOfCardEntry(card)}
                          fhirAppointmentDetails={fhirPatientDetail}
                        />
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {getInstructions(
                        (each.actions[0].resource as R4.IBundle).entry ?? []
                      ).length > 0 &&
                        getInstructions(
                          (each.actions[0].resource as R4.IBundle).entry ?? []
                        ).map((app) => (
                          <Typography
                            className={classes.subTitleText}
                            display='inline'
                            style={{
                              whiteSpace: 'pre-line',
                            }}
                            key={app.id}
                            dangerouslySetInnerHTML={{
                              __html: app.payload?.[0].contentString ?? '',
                            }}
                          />
                        ))}
                    </AccordionDetails>
                  </Accordion>
                )}

                {(getServiceRequests(
                  (each.actions[0].resource as R4.IBundle).entry ?? []
                ).length > 0 ||
                  getRepeatedAppointments(
                    (each.actions[0].resource as R4.IBundle).entry ?? []
                  ).length > 0) && (
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel1a-content'
                      id='carePlan-header'
                      expandIcon={<ExpandMore />}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        alignContent='center'
                        alignItems='center'
                        key={each.label}
                        width='100%'
                        overflow='auto'
                      >
                        <Typography className={classes.titleText}>
                          CarePlan Activities
                        </Typography>
                        {/* <Box flexDirection='row'>
                          <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => {
                              dispatch(
                                addInstructions(
                                  fhirPatientDetail.patient,
                                  getInstructions(
                                    (each.actions?.[0].resource as R4.IBundle)
                                      .entry ?? []
                                  ),
                                  fhirPatientDetail,
                                  ''
                                )
                              )
                            }}
                          >
                            Add
                          </Button>

                          <Button variant='text' color='secondary'>
                            Reject
                          </Button>
                        </Box> */}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List aria-label='error'>
                        {getServiceRequests(
                          (each.actions[0].resource as R4.IBundle).entry ?? []
                        ).length > 0 &&
                          getServiceRequests(
                            (each.actions[0].resource as R4.IBundle).entry ?? []
                          ).map((app) => (
                            <ListItem key={value} disableGutters>
                              <ListItemText
                                primary={app.description ?? ''}
                                className={classes.subTitleText}
                              />
                            </ListItem>
                          ))}

                        {getRepeatedAppointments(
                          (each.actions[0].resource as R4.IBundle).entry ?? []
                        ).length > 0 &&
                          getRepeatedAppointments(
                            (each.actions[0].resource as R4.IBundle).entry ?? []
                          ).map((app) => (
                            <ListItem key={value} disableGutters>
                              <ListItemText primary={app.description ?? ''} />
                            </ListItem>
                          ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}

                {card.detail && card.detail.length > 0 && (
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel1a-content'
                      id={`summary-${mainIndex}`}
                      expandIcon={<ExpandMore />}
                    >
                      <Typography className={classes.titleText}>
                        Summary
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        display='inline'
                        style={{ whiteSpace: 'pre-line' }}
                        className={classes.subTitleText}
                      >
                        {card.detail}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Box>
            )}
          </Box>
        ))}
    </Box>
  )
}
