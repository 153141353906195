import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Divider, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestCdsRecommendationsOfPatient } from 'redux/patientMedicalHistory/cds_recommendations/cdsRecommendationsSlice'
import { CardsEntity } from 'redux/patientMedicalHistory/cds_recommendations/cdsResponse'
import { RootState } from 'redux/rootReducer'
import { CPGRecommendations } from 'redux/subscription/cpgRecommendations/cpgRecommendationsState'
import { WelloLoadingIndicator } from 'wello-web-components'
import { BratAnnoTation } from './annotation_button'
import { CdsCardSummary } from './cdsCardDetailsDisply'
import { CohartButton } from './cohart_button'
import { CohortTableReport } from './cohort_table_button'
import { EbmButton } from './ebmButton'

interface MedicationsListProps {
  patient: R4.IPatient
  fhirPatientDetail: FhirAppointmentDetail
  onViewDetailsClicked?: (card: CPGRecommendations) => void
}

export const CdsRecommendationsForAppointmentForSub: React.FC<MedicationsListProps> =
  ({
    patient,
    fhirPatientDetail,
    onViewDetailsClicked,
  }: MedicationsListProps) => {
    const { t } = useTranslation(['common'])
    const dispatch = useDispatch()
    const [selectedCard, setSelectedCard] = useState<CardsEntity>()
    const [availablePlans, setAvailablePlans] = useState<CPGRecommendations[]>()
    const [allCards, setAllCards] = useState<CardsEntity[]>()

    const patientMedicalTimeLineSlice = useSelector(
      (state: RootState) => state.cpgRecommendationsSlice
    )

    /* useEffect(() => {
      dispatch(
        requestCdsRecommendationsOfPatient(
          patient,
          'OPD',
          fhirPatientDetail,
          false
        )
      )
    }, [dispatch, patient, fhirPatientDetail]) */

    useEffect(() => {
      if (patientMedicalTimeLineSlice.resultsAvailable === true) {
        if (
          selectedCard === undefined &&
          patientMedicalTimeLineSlice.recommendationsList &&
          patientMedicalTimeLineSlice.recommendationsList.length > 0
        ) {
          let cards: CardsEntity[] = []
          patientMedicalTimeLineSlice.recommendationsList.forEach((card) => {
            if (card.recommendation?.cards) {
              cards = cards.concat(...card.recommendation?.cards)
            }
          })
          setAllCards(cards)
          setAvailablePlans(patientMedicalTimeLineSlice.recommendationsList)
          setSelectedCard(cards[0])
        }
      }
    }, [patientMedicalTimeLineSlice.recommendationsList])

    return (
      <Box
        display='flex'
        flexDirection='column'
        overflow='auto'
        style={{ overflow: 'auto' }}
        width='100%'
        alignContent='center'
        minHeight='300px'
      >
        {patientMedicalTimeLineSlice.fetchingRecommendations && (
          <Box
            display='flex'
            flexDirection='row'
            overflow='auto'
            alignContent='center'
            alignItems='center'
            justifyContent='center'
            style={{ overflow: 'auto' }}
            width='100%'
            minHeight='300px'
          >
            <WelloLoadingIndicator />
          </Box>
        )}

        {patientMedicalTimeLineSlice.errorWhileRequesting &&
          patientMedicalTimeLineSlice.errorReason && (
            <Box>
              {' '}
              {JSON.stringify(patientMedicalTimeLineSlice.errorReason)}
            </Box>
          )}
        {patientMedicalTimeLineSlice.resultsAvailable &&
          allCards &&
          allCards.length === 0 && (
            <Box
              display='flex'
              flexDirection='column'
              overflow='auto'
              alignContent='center'
              justifyContent='center'
              alignItems='center'
              justifyItems='center'
              flexGrow={1}
              width='100%'
            >
              <Typography variant='h6' color='initial'>
                No recommendations
              </Typography>
            </Box>
          )}

        {patientMedicalTimeLineSlice.resultsAvailable &&
          allCards &&
          allCards.length > 0 &&
          availablePlans &&
          availablePlans.length > 0 && (
            <Box
              display='flex'
              flexDirection='column'
              overflow='auto'
              alignContent='center'
              flexGrow={1}
            >
              {availablePlans.map((card, index) => (
                <Box
                  display='flex'
                  flexDirection='column'
                  overflow='auto'
                  paddingTop={1}
                  key={`${card.cpgUrlIdentifier}_details_tab`}
                >
                  {index === 0 && <Divider />}
                  {card.errorResponse &&
                    card.errorResponse?.cards &&
                    card.errorResponse?.cards.length > 0 && (
                      <CdsCardSummary
                        cdsCard={card.errorResponse?.cards[0]}
                        fhirPatientDetail={fhirPatientDetail}
                        patient={patient}
                        index={index}
                      />
                    )}

                  {card.recommendation?.cards &&
                    card.recommendation?.cards.length > 0 && (
                      <CdsCardSummary
                        cdsCard={card.recommendation?.cards[0]}
                        fhirPatientDetail={fhirPatientDetail}
                        patient={patient}
                        index={index}
                        onViewDetailsClicked={
                          onViewDetailsClicked
                            ? (e) => {
                                if (onViewDetailsClicked) {
                                  onViewDetailsClicked(card)
                                }
                              }
                            : undefined
                        }
                      />
                    )}
                  {index !== availablePlans.length - 1 && <Divider />}
                </Box>
              ))}
            </Box>
          )}

        {/* {patientMedicalTimeLineSlice.resultsAvailable &&
          patientMedicalTimeLineSlice.availableAppointments &&
          patientMedicalTimeLineSlice.availableAppointments.cards && (
            <Box display='flex' flexDirection='row' overflow='auto'>
              <Box p={1}>
                <EbmButton fhirAppointmentDetails={fhirPatientDetail} />
              </Box>

              <Box p={1}>
                <CohartButton fhirAppointmentDetails={fhirPatientDetail} />
              </Box>
            </Box>
          )} */}
      </Box>
    )
  }
