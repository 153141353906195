import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { rules } from 'utils/constants'

interface Props {
  open: boolean
  data: false
  onAgree: (chk: boolean) => void
  onClose: (chk: boolean) => void
}
export const ConsentDialogReferral: React.FC<Props> = ({
  open,
  data,
  onAgree,
  onClose,
}) => {
  const { t } = useTranslation()

  //   function resetForm() {
  //     setChecked(false)
  //   }
  //   const [checked, setChecked] = useState<boolean>(data)

  //   const handleChange = (event: any) => {
  //     setChecked(event.target.checked)
  //   }

  //   useEffect(() => {
  //     setChecked(data)
  //   }, [])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose(false)
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='lg'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Consent</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose(false)
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        <Box display='flex' flexDirection='row' width='100%'>
          <Box>
            <Typography
              variant='body1'
              display='inline'
              dangerouslySetInnerHTML={{
                __html: rules ?? '',
              }}
              role='text_consent'
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onAgree(true)
          }}
          variant='outlined'
          disableElevation
          role='done_consent'
        >
          I Agree
        </Button>

        <Button
          onClick={() => {
            onClose(false)
          }}
          variant='outlined'
          disableElevation
          role='done_consent'
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
