import { Box, MenuItem, TextField, TextFieldProps } from '@material-ui/core'
import React, { useState } from 'react'
import { WelloFormItemLabel } from 'wello-web-components'

const occupations = [
  { id: 'govtOfficer', title: 'Govt. Officer' },
  { id: 'Politician', title: 'Politician' },
  { id: 'Self Employed', title: 'Self Employed' },
  { id: 'businessman', title: 'Businessman' },
  { id: 'Doctor', title: 'Doctor' },
  { id: 'Hospitality service', title: 'Hospitality service' },
  { id: 'Pvt Sector Executive', title: 'Pvt Sector Executive' },
  { id: 'Chartered Accountant', title: 'Chartered Accountant' },
  { id: 'Finance Professional', title: 'Finance Professional' },
  { id: 'Housewife', title: 'Housewife' },
  { id: 'Student', title: 'Student' },
].sort((a, b) => (a.title! > b.title! ? 1 : b.title! > a.title! ? -1 : 0))

interface WelloOccupationProps {
  title: string
  textFieldProps: TextFieldProps
  onChanged: (selectedOccupation: any) => void
  preSelectedOccupation?: any
  displayDoneLabel?: boolean
}

export const WelloOccupationSelector: React.FunctionComponent<WelloOccupationProps> =
  (props: WelloOccupationProps) => {
    const [selectedOccupation, setSelectedOccupation] = useState<any>(
      props.preSelectedOccupation ?? { id: '', title: 'Select' }
    )
    if (props.textFieldProps.value) {
      const data: string = props.textFieldProps.value as string

      if (data.length > 0) {
        const selectedOccu: any = occupations.find(
          (occupation) => occupation.id === data
        )
        if (selectedOccu === undefined) {
          props.textFieldProps.value = 'other'
        }
      }
    }

    return (
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <WelloFormItemLabel
          title={props.title}
          displayDoneLabel={props.displayDoneLabel}
        />
        <Box>
          <TextField
            variant='outlined'
            fullWidth
            size='small'
            required={false}
            select
            value={selectedOccupation?.id}
            label={selectedOccupation?.id === '' ? 'Select' : ''}
            onChange={(val) => {
              const selectedOccu: any = occupations.find(
                (occupation) => occupation.id === val.target.value
              )
              setSelectedOccupation(selectedOccu ?? { id: '', title: 'Select' })
              props.onChanged(selectedOccu)
            }}
            {...props.textFieldProps}
          >
            {occupations.map((option) => (
              <MenuItem
                id={option.id}
                key={option.id}
                value={option.id}
                selected={selectedOccupation?.id === option.id}
                disabled={option?.id === ''}
              >
                {option.title}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
    )
  }
