import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getInstructionsOfIPD } from 'redux/ipd/instructions_list_slice_ipd/instructionsListSliceIPD'
import { addInstructionsForIPD } from 'redux/ipd/recommendation_additionsIPD/instruction_addition_ipd/instrutionAdditionSliceIPD'
import { RootState } from 'redux/rootReducer'

const useStyles = makeStyles((theme) => ({
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))

interface AddInstructionsButtonProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  carePanBundle: R4.ICommunicationRequest[]
  carePlanIdentifier?: string
}

export const AddInstructionsButtonIPD: React.FC<AddInstructionsButtonProps> = ({
  fhirAppointmentDetails,
  carePanBundle,
  carePlanIdentifier,
}: AddInstructionsButtonProps) => {
  const instructionAdditionSlice = useSelector(
    (state: RootState) => state.instructionAdditionSliceIPD
  )
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    if (instructionAdditionSlice.additionSuccessful) {
      dispatch(getInstructionsOfIPD(fhirAppointmentDetails))
    }
  }, [instructionAdditionSlice.additionSuccessful])

  return (
    <Box>
      <Box flexDirection='row'>
        {instructionAdditionSlice.addingInstruction && (
          <Box height={25} width={25}>
            <CircularProgress size={20} />
          </Box>
        )}

        {instructionAdditionSlice.errorWhileAdding && (
          <Box flexDirection='row'>
            <Button
              variant='text'
              color='primary'
              id='care_plan_ins2'
              className={classes.buttonText}
              onClick={(e) => {
                dispatch(
                  addInstructionsForIPD(
                    fhirAppointmentDetails.patient,
                    carePanBundle,
                    fhirAppointmentDetails,
                    carePlanIdentifier
                  )
                )
                e.stopPropagation()
              }}
            >
              Retry
            </Button>

            {/*  <Button
              variant='text'
              color='secondary'
              className={classes.rejectButtonText}
            >
              Reject
            </Button> */}
          </Box>
        )}
        {/* {instructionAdditionSlice.additionSuccessful && (
          <Box flexDirection='row' px={2}>
            <Typography variant='subtitle1' color='initial'>
              Added
            </Typography>
          </Box>
        )} */}
        {!instructionAdditionSlice.additionSuccessful &&
          !instructionAdditionSlice.errorWhileAdding &&
          !instructionAdditionSlice.addingInstruction && (
            <Box flexDirection='row'>
              <Button
                variant='text'
                color='primary'
                id='care_plan_ins4'
                className={classes.buttonText}
                onClick={(e) => {
                  dispatch(
                    addInstructionsForIPD(
                      fhirAppointmentDetails.patient,
                      carePanBundle,
                      fhirAppointmentDetails,
                      carePlanIdentifier
                    )
                  )
                  e.stopPropagation()
                }}
              >
                Add
              </Button>

              {/*  <Button
                variant='text'
                color='secondary'
                className={classes.rejectButtonText}
              >
                Reject
              </Button> */}
            </Box>
          )}
      </Box>
    </Box>
  )
}
