import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { CloseOutlined } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import {
  requestForAppointmentCount,
  resetAppointmentForUnit,
} from 'redux/welloAdmin/appointmentCount/appointmentCountPerUnit'
import {
  requestForPaymentTotalForUnit,
  resetRevenueForUnit,
} from 'redux/welloAdmin/revenue/paymentSearchSlice'
import {
  resetPatientSearchPatientForCount,
  searchPatientCount,
} from 'redux/welloAdmin/user/patientCount/patientCountSliceForUnit'
import {
  resetStateForWelloAdminPract,
  searchPractitionersForUnit,
} from 'redux/welloAdmin/user/practitionerSearchSliceAcrossUnit'
import {
  requestForWellnessAppointmentCount,
  resetWellnessAppointmentForUnit,
} from 'redux/welloAdmin/wellnessAppointmentCount/wellnessAppointmentCountPerUnit'
import SimpleBar from 'simplebar-react'
import { wpAdminOptions, wpAdminOptionsLAb } from 'utils/constants'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import CustomizedDividers from 'views/components/toggle_selector'
import { AppointmentCountTile } from './appointmentCountTile'
import { ClinicAppointmentTile } from './clinicAppointmentTile'
import { ClinicUserList } from './clinicUserList'
import { LabUserDetails } from './labUserDetails'
import { PatientCountTile } from './patinetCountTile'
import { RevenueTile } from './paymentAmountTile'

interface Props {
  org: R4.IOrganization
  mainOrgId: string
  type?: string
  isOdd?: boolean
  onCloseClicked: () => void
  onTypeSelected: (type: string) => void
  dense?: boolean
  date?: Date
}

export const ClinicSplit: React.FC<Props> = ({
  org,
  mainOrgId,
  type,
  isOdd = false,
  onCloseClicked,
  onTypeSelected,
  dense = false,
  date,
}: Props) => {
  const practitionerSearchForWelloAdminSlice = useSelector(
    (state: RootState) => state.practitionerSearchForWelloAdminSlice
  )

  const patientSearchCountSlice = useSelector(
    (state: RootState) => state.patientSearchCountSlice
  )

  const appointmentCountPerUnitSlice = useSelector(
    (state: RootState) => state.appointmentCountPerUnitSlice
  )

  const totalPaymentUnitWiseSlice = useSelector(
    (state: RootState) => state.totalPaymentUnitWiseSlice
  )

  const appointmentWellnessCountPerUnitSlice = useSelector(
    (state: RootState) => state.appointmentWellnessCountPerUnitSlice
  )

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [optionId, setOptionId] = useState<string>(wpAdminOptions[0].value)
  const typeData =
    getCodeOfSystemFromCodableConceptList(
      org.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.code ?? ''

  useEffect(() => {
    if (optionId === 'users')
      dispatch(searchPractitionersForUnit(`Organization/${org.id ?? ''}`))
    if (optionId === 'patient')
      dispatch(searchPatientCount(`Organization/${org.id ?? ''}`, mainOrgId))
    if (optionId === 'appointments' && typeData === 'clinic')
      dispatch(
        requestForAppointmentCount(
          `Organization/${org.id ?? ''}`,
          getCodeOfSystemFromCodableConceptList(
            org.type ?? [],
            'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
          )?.code ?? ''
        )
      )
    if (optionId === 'appointments' && typeData === 'wellness_center')
      dispatch(
        requestForWellnessAppointmentCount(`Organization/${org.id ?? ''}`)
      )
    if (optionId === 'revenue')
      dispatch(
        requestForPaymentTotalForUnit(
          `Organization/${org.id ?? ''}`,
          getCodeOfSystemFromCodableConceptList(
            org.type ?? [],
            'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
          )?.code ?? ''
        )
      )

    return () => {}
  }, [])

  return (
    <Box width='100%' px={1}>
      <Card
        elevation={0}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 4,
          backgroundColor: isOdd ? '#FFFFFFAF' : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 1,
          }}
        >
          <Box display='flex' flexDirection='column' width='100%'>
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              alignContent='center'
              alignItems='center'
            >
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                justifyContent='flex-start'
                paddingLeft={1}
                paddingTop={1.5}
              >
                {/* <WelloFormItemLabel title='Select Type' /> */}
                <CustomizedDividers
                  onSelectionChanged={(id) => {
                    setOptionId(id)
                    if (id === 'patient') {
                      dispatch(resetWellnessAppointmentForUnit())
                      dispatch(resetStateForWelloAdminPract())
                      dispatch(resetAppointmentForUnit())
                      dispatch(resetRevenueForUnit())
                      dispatch(
                        searchPatientCount(
                          `Organization/${org.id ?? ''}`,
                          mainOrgId
                        )
                      )
                    }
                    if (id === 'users') {
                      dispatch(resetWellnessAppointmentForUnit())
                      dispatch(resetPatientSearchPatientForCount())
                      dispatch(resetAppointmentForUnit())
                      dispatch(resetRevenueForUnit())
                      dispatch(
                        searchPractitionersForUnit(
                          `Organization/${org.id ?? ''}`
                        )
                      )
                    }
                    if (id === 'appointments') {
                      dispatch(resetStateForWelloAdminPract())
                      dispatch(resetPatientSearchPatientForCount())
                      dispatch(resetRevenueForUnit())
                      if (typeData && typeData === 'clinic') {
                        dispatch(resetWellnessAppointmentForUnit())
                        dispatch(
                          requestForAppointmentCount(
                            `Organization/${org.id ?? ''}`,
                            getCodeOfSystemFromCodableConceptList(
                              org.type ?? [],
                              'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
                            )?.code ?? ''
                          )
                        )
                      } else {
                        dispatch(resetAppointmentForUnit())
                        dispatch(
                          requestForWellnessAppointmentCount(
                            `Organization/${org.id ?? ''}`
                          )
                        )
                      }
                    }

                    if (id === 'orders') {
                      dispatch(resetStateForWelloAdminPract())
                      dispatch(resetPatientSearchPatientForCount())
                      dispatch(resetRevenueForUnit())
                      if (typeData && typeData === 'lab') {
                        dispatch(resetWellnessAppointmentForUnit())
                        dispatch(
                          requestForAppointmentCount(
                            `Organization/${org.id ?? ''}`,
                            getCodeOfSystemFromCodableConceptList(
                              org.type ?? [],
                              'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
                            )?.code ?? ''
                          )
                        )
                      }
                      //   } else {
                      //     dispatch(resetAppointmentForUnit())
                      //     dispatch(
                      //       requestForWellnessAppointmentCount(org.id ?? '')
                      //     )
                      //   }
                    }

                    if (id === 'revenue') {
                      dispatch(resetWellnessAppointmentForUnit())
                      dispatch(resetStateForWelloAdminPract())
                      dispatch(resetPatientSearchPatientForCount())
                      dispatch(resetAppointmentForUnit())
                      dispatch(
                        requestForPaymentTotalForUnit(
                          org.id ?? '',
                          getCodeOfSystemFromCodableConceptList(
                            org.type ?? [],
                            'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
                          )?.code ?? ''
                        )
                      )
                    }
                    onTypeSelected(id)
                  }}
                  options={
                    type && type === 'Lab' ? wpAdminOptionsLAb : wpAdminOptions
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                justifyContent='flex-end'
              >
                <Tooltip title='Close'>
                  <IconButton
                    aria-label='btn_ord_detail_menu'
                    onClick={onCloseClicked}
                  >
                    <CloseOutlined color='primary' />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>

            {practitionerSearchForWelloAdminSlice.searching && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            )}

            {/* {practitionerSearchForWelloAdminSlice.noResultsAvailable && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <Typography variant='subtitle1' color='initial' align='center'>
                  No data available
                </Typography>
              </Box>
            )} */}

            {patientSearchCountSlice.searching && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            )}

            {/* {patientSearchCountSlice.noResultsAvailable && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <Typography variant='subtitle1' color='initial' align='center'>
                  No data available
                </Typography>
              </Box>
            )} */}

            {appointmentCountPerUnitSlice.searchingAppointments && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            )}
            {/* {appointmentCountPerUnitSlice.noResultsAvailable && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <Typography variant='subtitle1' color='initial' align='center'>
                  No data available
                </Typography>
              </Box>
            )} */}

            {totalPaymentUnitWiseSlice.searchingAppointments &&
              optionId === 'revenue' && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              )}
            {/* 
            {totalPaymentUnitWiseSlice.noResultsAvailable && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <Typography variant='subtitle1' color='initial' align='center'>
                  No data available
                </Typography>
              </Box>
            )} */}

            {/* {optionId === 'patient' &&
              patientSearchCountSlice.noResultsAvailable && (
                <Box
                  borderColor='lightGrey'
                  width='100%'
                  display='flex'
                  flexDirection='column'
                >
                  <PatientCountTile
                    patientCount={patientSearchCountSlice.totalCount ?? 0}
                  />
                </Box>
              )} */}

            {/* {optionId === 'revenue' &&
              totalPaymentUnitWiseSlice.noResultsAvailable && (
                <Box
                  borderColor='lightGrey'
                  width='100%'
                  display='flex'
                  flexDirection='column'
                >
                  <RevenueTile
                    ipdCount={totalPaymentUnitWiseSlice.totalAmount ?? 0}
                  />
                </Box>
              )} */}

            {appointmentWellnessCountPerUnitSlice.searchingAppointments &&
              optionId === 'appointments' && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              )}
            {/* 
            {appointmentWellnessCountPerUnitSlice.noResultsAvailable && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <Typography variant='subtitle1' color='initial' align='center'>
                  No data available
                </Typography>
              </Box>
            )} */}

            <Box display='flex' flexDirection='column'>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                height='100%'
                overflow='auto'
              >
                <SimpleBar
                  style={{
                    height: '100%',
                    width: '100%',
                    overflowX: 'hidden',
                    padding: '8px',
                  }}
                >
                  {optionId === 'appointments' &&
                    typeData === 'clinic' &&
                    appointmentCountPerUnitSlice.noResultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <AppointmentCountTile
                          ipdCount={appointmentCountPerUnitSlice.count ?? 0}
                          type='clinic'
                          unitId={org.id ?? ''}
                        />
                      </Box>
                    )}

                  {optionId === 'orders' &&
                    typeData === 'lab' &&
                    appointmentCountPerUnitSlice.noResultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <AppointmentCountTile
                          ipdCount={appointmentCountPerUnitSlice.count ?? 0}
                          type='lab'
                          unitId={org.id ?? ''}
                        />
                      </Box>
                    )}

                  {optionId === 'appointments' &&
                    typeData === 'wellness_center' &&
                    appointmentWellnessCountPerUnitSlice.noResultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <AppointmentCountTile
                          ipdCount={
                            appointmentWellnessCountPerUnitSlice.count ?? 0
                          }
                          type='wellness_center'
                          unitId={org.id ?? ''}
                        />
                      </Box>
                    )}

                  {optionId === 'users' &&
                    (typeData === 'clinic' || typeData === 'wellness_center') &&
                    practitionerSearchForWelloAdminSlice.noResultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <ClinicUserList
                          unitId={org.id ?? ''}
                          unitName={org.name ?? ''}
                          type={typeData}
                          docCount={
                            practitionerSearchForWelloAdminSlice.doctorCount ??
                            0
                          }
                          consultingDoctor={
                            practitionerSearchForWelloAdminSlice.consultingDoctorCount ??
                            0
                          }
                          ayurvedaDoctorCount={
                            practitionerSearchForWelloAdminSlice.ayurvedaDoctorCount ??
                            0
                          }
                          unitAdminCount={
                            practitionerSearchForWelloAdminSlice.unitAdminCount ??
                            0
                          }
                          grmCount={
                            practitionerSearchForWelloAdminSlice.grmCount ?? 0
                          }
                          therapistCount={
                            practitionerSearchForWelloAdminSlice.therapistCount ??
                            0
                          }
                          radiologistCount={
                            practitionerSearchForWelloAdminSlice.radiologistCount ??
                            0
                          }
                          agentCount={
                            practitionerSearchForWelloAdminSlice.agentCount ?? 0
                          }
                        />
                      </Box>
                    )}

                  {optionId === 'users' &&
                    typeData === 'lab' &&
                    practitionerSearchForWelloAdminSlice.noResultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <LabUserDetails
                          unitId={org.id ?? ''}
                          unitName={org.name ?? ''}
                          type={typeData}
                          docCount={
                            practitionerSearchForWelloAdminSlice.doctorCount ??
                            0
                          }
                          consultingDoctor={
                            practitionerSearchForWelloAdminSlice.consultingDoctorCount ??
                            0
                          }
                          ayurvedaDoctorCount={
                            practitionerSearchForWelloAdminSlice.ayurvedaDoctorCount ??
                            0
                          }
                          unitAdminCount={
                            practitionerSearchForWelloAdminSlice.unitAdminCount ??
                            0
                          }
                          grmCount={
                            practitionerSearchForWelloAdminSlice.grmCount ?? 0
                          }
                          therapistCount={
                            practitionerSearchForWelloAdminSlice.therapistCount ??
                            0
                          }
                          radiologistCount={
                            practitionerSearchForWelloAdminSlice.radiologistCount ??
                            0
                          }
                          agentCount={
                            practitionerSearchForWelloAdminSlice.agentCount ?? 0
                          }
                        />
                      </Box>
                    )}
                  {optionId === 'revenue' &&
                    totalPaymentUnitWiseSlice.noResultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <RevenueTile
                          ipdCount={totalPaymentUnitWiseSlice.totalAmount ?? 0}
                        />
                      </Box>
                    )}

                  {optionId === 'patient' &&
                    patientSearchCountSlice.noResultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <PatientCountTile
                          patientCount={patientSearchCountSlice.totalCount ?? 0}
                          malePatientCount={
                            patientSearchCountSlice.maleCount ?? 0
                          }
                          femalePatientCount={
                            patientSearchCountSlice.femaleCount ?? 0
                          }
                        />
                      </Box>
                    )}

                  {optionId === 'users' &&
                    (typeData === 'clinic' || typeData === 'wellness_center') &&
                    practitionerSearchForWelloAdminSlice.resultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <ClinicUserList
                          unitId={org.id ?? ''}
                          unitName={org.name ?? ''}
                          type={typeData}
                          docCount={
                            practitionerSearchForWelloAdminSlice.doctorCount ??
                            0
                          }
                          consultingDoctor={
                            practitionerSearchForWelloAdminSlice.consultingDoctorCount ??
                            0
                          }
                          ayurvedaDoctorCount={
                            practitionerSearchForWelloAdminSlice.ayurvedaDoctorCount ??
                            0
                          }
                          unitAdminCount={
                            practitionerSearchForWelloAdminSlice.unitAdminCount ??
                            0
                          }
                          grmCount={
                            practitionerSearchForWelloAdminSlice.grmCount ?? 0
                          }
                          therapistCount={
                            practitionerSearchForWelloAdminSlice.therapistCount ??
                            0
                          }
                          radiologistCount={
                            practitionerSearchForWelloAdminSlice.radiologistCount ??
                            0
                          }
                          agentCount={
                            practitionerSearchForWelloAdminSlice.agentCount ?? 0
                          }
                        />
                      </Box>
                    )}

                  {optionId === 'users' &&
                    typeData === 'lab' &&
                    practitionerSearchForWelloAdminSlice.resultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <LabUserDetails
                          unitId={org.id ?? ''}
                          unitName={org.name ?? ''}
                          type={typeData}
                          docCount={
                            practitionerSearchForWelloAdminSlice.doctorCount ??
                            0
                          }
                          consultingDoctor={
                            practitionerSearchForWelloAdminSlice.consultingDoctorCount ??
                            0
                          }
                          ayurvedaDoctorCount={
                            practitionerSearchForWelloAdminSlice.ayurvedaDoctorCount ??
                            0
                          }
                          unitAdminCount={
                            practitionerSearchForWelloAdminSlice.unitAdminCount ??
                            0
                          }
                          grmCount={
                            practitionerSearchForWelloAdminSlice.grmCount ?? 0
                          }
                          therapistCount={
                            practitionerSearchForWelloAdminSlice.therapistCount ??
                            0
                          }
                          radiologistCount={
                            practitionerSearchForWelloAdminSlice.radiologistCount ??
                            0
                          }
                          agentCount={
                            practitionerSearchForWelloAdminSlice.agentCount ?? 0
                          }
                        />
                      </Box>
                    )}

                  {optionId === 'patient' &&
                    patientSearchCountSlice.resultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <PatientCountTile
                          patientCount={patientSearchCountSlice.totalCount ?? 0}
                          malePatientCount={
                            patientSearchCountSlice.maleCount ?? 0
                          }
                          femalePatientCount={
                            patientSearchCountSlice.femaleCount ?? 0
                          }
                        />
                      </Box>
                    )}

                  {optionId === 'appointments' &&
                    typeData === 'clinic' &&
                    appointmentCountPerUnitSlice.resultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <ClinicAppointmentTile
                          ipdCount={appointmentCountPerUnitSlice.count}
                          type='clinic'
                          unitId={org.id ?? ''}
                          dayCareCount={
                            appointmentCountPerUnitSlice.dayCareCount ?? 0
                          }
                          opdCount={appointmentCountPerUnitSlice.opdCount ?? 0}
                        />
                      </Box>
                    )}

                  {optionId === 'orders' &&
                    typeData === 'lab' &&
                    appointmentCountPerUnitSlice.resultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <AppointmentCountTile
                          ipdCount={appointmentCountPerUnitSlice.count}
                          type='lab'
                          unitId={org.id ?? ''}
                        />
                      </Box>
                    )}

                  {optionId === 'appointments' &&
                    typeData === 'wellness_center' &&
                    appointmentWellnessCountPerUnitSlice.resultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <AppointmentCountTile
                          ipdCount={appointmentWellnessCountPerUnitSlice.count}
                          type='wellness_center'
                          unitId={org.id ?? ''}
                        />
                      </Box>
                    )}

                  {optionId === 'revenue' &&
                    typeData === 'lab' &&
                    appointmentCountPerUnitSlice.noResultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <RevenueTile
                          ipdCount={totalPaymentUnitWiseSlice.totalAmount ?? 0}
                        />
                      </Box>
                    )}

                  {optionId === 'revenue' &&
                    typeData !== 'lab' &&
                    totalPaymentUnitWiseSlice.resultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <RevenueTile
                          ipdCount={totalPaymentUnitWiseSlice.totalAmount ?? 0}
                        />
                      </Box>
                    )}

                  {optionId === 'revenue' &&
                    typeData === 'lab' &&
                    totalPaymentUnitWiseSlice.resultsAvailable && (
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <RevenueTile
                          ipdCount={totalPaymentUnitWiseSlice.totalAmount ?? 0}
                        />
                      </Box>
                    )}
                </SimpleBar>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
