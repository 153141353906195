import {
  Box,
  Grid,
  Typography,
  useTheme,
  Tooltip,
  IconButton,
  Link,
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { Add, Done } from '@material-ui/icons'
import React from 'react'

export interface SubPlanProps {
  subPlanName: string

  bottomWidget?: React.ReactNode
}

interface NextStepsOfConsultationProps {
  planName: string
  subPlans: SubPlanProps[]
}

export const NextStepMultipleSubPlanSplit: React.FC<NextStepsOfConsultationProps> =
  ({ planName, subPlans }: NextStepsOfConsultationProps) => {
    const primary = useTheme().palette.primary.main

    return (
      <Box
        display='flex'
        borderColor='lightGrey'
        width='100%'
        flexDirection='column'
        flexGrow
      >
        <Box display='flex' flexDirection='row' width='100%' flexGrow>
          <Grid container style={{ padding: '0px', margin: '0px' }}>
            <Grid item xs={4} style={{ alignSelf: 'center' }}>
              <Typography
                variant='subtitle2'
                style={{ color: '#4D4D4D', textTransform: 'uppercase' }}
              >
                {planName}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {subPlans.map((subPlan) => (
          <SubPlan {...subPlan} key={subPlan.subPlanName} />
        ))}
      </Box>
    )
  }

const SubPlan: React.FC<SubPlanProps> = (props: SubPlanProps) => {
  const primary = useTheme().palette.primary.main

  return (
    <Box display='flex' flexDirection='column' width='100%' flexGrow>
      <Grid container style={{ padding: '0px', margin: '0px' }}>
        <Grid item xs={4} style={{ alignSelf: 'flex-start' }}>
          <Box paddingLeft={1}>
            <Typography variant='subtitle2' id='planName_label'>
              {props.subPlanName}
            </Typography>
          </Box>
        </Grid>

        {/* {props.subString && props.subString.length > 0 && (
          <Grid item xs={8} style={{ alignSelf: 'center' }}>
            <Typography variant='subtitle1' id='sub+planName_label'>
              {props.subString}
            </Typography>
          </Grid>
        )} */}
      </Grid>

      {props.bottomWidget && (
        <Box display='flex' flexDirection='row' width='100%' flexGrow>
          {props.bottomWidget}
        </Box>
      )}
    </Box>
  )
}
