import { EvidenceBaseData } from 'models/evidenceBaseData'
import moment from 'moment'
import formatContactPoint from 'wello-fhir-l10n/dist/in/en/formatoverlay-partials/ContactPoint-format.json'

// const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const mailReg =
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/

export function validateEmailForLab(
  regxData: any,
  email: string,
  isMandatory: boolean
): string {
  const obj = JSON.stringify(
    formatContactPoint.attr_formats['ContactPoint.value.email']
  )

  const validEmail = new RegExp(obj)
  let res = isMandatory ? 'Email Address is required' : ''
  if (email && email.length > 0) {
    const isValid = mailReg.test(email)
    if (!isValid) {
      res = 'Email is not valid'
    } else if (email.length > 320) {
      res = 'Email should not be more than 320 characters'
    } else {
      res = ''
    }
  }
  return res
}

export function validateLRNumber(
  lrNumber: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Lab Reference Id is required' : ''
  if (lrNumber && lrNumber.length > 30) {
    res = 'Lab Refereence should be maximum of 30 characters'
  }
  if (lrNumber && lrNumber.length > 0 && lrNumber.length <= 30) {
    if (!lrNumber.trim()) {
      res = 'Lab Reference Id is required'
    } else {
      res = ''
    }
  }
  return res
}

export function validateDob(dob: string, isMandatory: boolean): string {
  let res = isMandatory ? 'DOB is required' : ''
  if (dob && dob.length > 0) {
    if (dob > moment().format('YYYY-MM-DD')) {
      res = 'DOB should be till current date'
    } else if (dob < '1871-01-01') {
      res = 'DOB should be on or after 01-01-1871'
    } else {
      res = ''
    }
  }
  return res
}

export function validateName(name: string, isMandatory: boolean): string {
  let res = isMandatory ? 'First Name is required' : ''
  if (name && name.length > 30) {
    res = 'First Name should be maximum of 30 characters'
  }
  if (name && name.length > 0 && name.length <= 30) {
    if (!name.trim()) {
      res = 'First Name is required'
    } else {
      res = ''
    }
  }
  return res
}

export function validateLastName(
  lastName: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Last Name is required' : ''
  if (lastName && lastName.length > 100) {
    res = 'Last Name should be maximum of 100 characters'
  }
  if (lastName && lastName.length > 0 && lastName.length <= 100) {
    if (!lastName.trim()) {
      res = 'Last Name is required'
    } else {
      res = ''
    }
  }
  return res
}

export function validateOtherData(
  otherData: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Value is required' : ''
  if (otherData && otherData.length > 50) {
    res = 'Value should be maximum of 50 characters'
  }
  if (otherData && otherData.length > 0 && otherData.length <= 50) {
    res = ''
  }
  return res
}

export function validateAddress(address: string, isMandatory: boolean): string {
  let res = isMandatory ? `Address is required` : ''
  if (address && address.length > 1024) {
    res = 'Address should be maximum of 1024 characters'
  }
  if (address && address.length > 0 && address.length <= 1024) {
    res = ''
  }
  return res
}

export function validateTemporaryAddress(
  address: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? `Temporary Address is required` : ''
  if (address && address.length > 1024) {
    res = 'Temporary Address should be maximum of 1024 characters'
  }
  if (address && address.length > 0 && address.length <= 1024) {
    res = ''
  }
  return res
}

export function validatePermanentAddress(
  address: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? `Permanent Address is required` : ''
  if (address && address.length > 1024) {
    res = 'Permanent Address should be maximum of 1024 characters'
  }
  if (address && address.length > 0 && address.length <= 1024) {
    res = ''
  }
  return res
}

export function validateFathersName(
  fatherName: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? `Father's Name is required` : ''
  if (fatherName && fatherName.length > 0 && fatherName.length <= 100) {
    res = ''
  }
  if (fatherName && fatherName.length > 100) {
    res = `Father's Name should be maximum of 100 characters`
  }
  return res
}

export function validateOthers(
  fatherName: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? `Other is required` : ''
  if (fatherName && fatherName.length > 0 && fatherName.length <= 100) {
    res = ''
  }
  if (fatherName && fatherName.length > 100) {
    res = `Other should be maximum of 100 characters`
  }
  return res
}

export function getEvidenceData(xs: EvidenceBaseData[], key: string) {
  return xs.reduce((rv: any, x: any) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}
