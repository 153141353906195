/* eslint-disable react/display-name */
import MaterialTable, {
  MTableBodyRow,
  MTableToolbar,
} from '@material-table/core'
import {
  Box,
  Button,
  InputAdornment,
  makeStyles,
  Paper,
  TablePagination,
  TextField,
  Typography,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import { PatientDataWithAppointment } from 'models/patientDataWithAppointment'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import femaleIcon from '../../../assets/icons/f.png'
import maleIcon from '../../../assets/icons/m.png'
import { getTableDataFromFhir } from '../../../utils/common/patientDataConverter'
import { PatientProfile } from './patientProfile'

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 4,
      paddingRight: 0,
    },
    '& .MuiTablePagination-root': {
      backGroundColor: 'red',
    },
  },
})

interface ICardProperties {
  patientData: FhirPatientDetail[]
  onPatientSelected: (selectedPatient: PatientDataWithAppointment) => void
}

export const PatientDataTable: React.FC<ICardProperties> = ({
  patientData,
  onPatientSelected,
}: ICardProperties) => {
  const classes = useStyles()

  const convertedPatientData = getTableDataFromFhir(patientData)

  const resendInviteSlice = useSelector(
    (state: RootState) => state.resendInviteSlice
  )
  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['common', 'patient'])
  const dispatch = useDispatch()
  //   i18n.changeLanguage(language ?? 'en')
  const [data, setData] = useState([...convertedPatientData])
  const scheduleData = useRef<boolean>(true)
  const occuptionData = useRef<boolean>(true)
  const emailData = useRef<boolean>(true)
  const addressData = useRef<boolean>(true)
  const fathersNameData = useRef<boolean>(true)
  const husbandData = useRef<boolean>(true)

  const [nameFilter, setNameFilter] = useState('')
  const [nameFocus, setNameFocus] = useState(true)
  const [ageFilter, setAgeFilter] = useState('')
  const [ageFocus, setAgeFocus] = useState(false)
  const [genderFilter, setGenderFilter] = useState('')
  const [genderFocus, setGenderFocus] = useState(false)
  const [phoneFilter, setPhoneFilter] = useState('')
  const [phoneFocus, setPhoneFocus] = useState(false)
  const [emailFilter, setEmailFilter] = useState('')
  const [emailFocus, setEmailFocus] = useState(false)
  const [lRFilter, setLRFilter] = useState('')
  const [lRFocus, setLrFocus] = useState(false)
  const [addressFilter, setAddressFilter] = useState('')
  const [addressFocus, setAddressFocus] = useState(false)
  const [fatherFocus, setFatherFocus] = useState(false)
  const [fatherFilter, setFatherFilter] = useState('')

  const [husbandFocus, setHusbandFocus] = useState(false)
  const [husbandFilter, setHusbandFilter] = useState('')
  const [selectedRowId, setSelectedRowId] = React.useState('')
  const [selectedRow, setSelectedRow] = React.useState(false)

  const [nationalityFocus, setNationalityFocus] = useState(false)
  const [nationalityFilter, setHationalityFilter] = useState('')

  const [maritalStatusFocus, setMaritalStatusFocus] = useState(false)
  const [maritalStatusFilter, setMaritalStatusFilter] = useState('')

  const [occupationFocus, setOccupationFocus] = useState(false)
  const [occupationFilter, setOccupationFilter] = useState('')

  const visaData = useRef<boolean>(true)
  const [visaFocus, setVisaFocus] = useState(false)
  const [visaFilter, setVisaFilter] = useState('')

  const [createdFocus, setCreatedFocus] = useState(false)
  const [createdFilter, setCreatedFilter] = useState('')

  const filterVisaNo = (value: any, filed: string) => {
    visaData.current = false
    setVisaFocus(true)
    if (value) {
      const filteredData = convertedPatientData.filter((d) =>
        d.visaNo.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedPatientData])
    }
    setVisaFilter(value)
    setAgeFocus(false)
    setOccupationFocus(false)
    setNationalityFocus(false)
    setGenderFocus(false)
    setMaritalStatusFocus(false)
    setPhoneFocus(false)
    setEmailFocus(false)
    setLrFocus(false)
    setFatherFocus(false)
    setAddressFocus(false)
    setHusbandFocus(false)
    setNameFocus(false)
    setCreatedFocus(false)
  }

  const filterCreatedDate = (value: any, filed: string) => {
    setCreatedFocus(true)
    if (value) {
      const filteredData = convertedPatientData.filter((d) =>
        d.createdDate.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedPatientData])
    }
    setVisaFocus(false)
    setCreatedFilter(value)
    setAgeFocus(false)
    setOccupationFocus(false)
    setNationalityFocus(false)
    setGenderFocus(false)
    setMaritalStatusFocus(false)
    setPhoneFocus(false)
    setEmailFocus(false)
    setLrFocus(false)
    setFatherFocus(false)
    setAddressFocus(false)
    setHusbandFocus(false)
    setNameFocus(false)
  }

  const filterOccupation = (value: any, filed: string) => {
    occuptionData.current = false
    setOccupationFocus(true)
    if (value) {
      const filteredData = convertedPatientData.filter((d) =>
        d.occupation.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedPatientData])
    }
    setCreatedFocus(false)
    setVisaFocus(false)
    setOccupationFilter(value)
    setAgeFocus(false)
    setNationalityFocus(false)
    setGenderFocus(false)
    setMaritalStatusFocus(false)
    setPhoneFocus(false)
    setEmailFocus(false)
    setLrFocus(false)
    setFatherFocus(false)
    setAddressFocus(false)
    setHusbandFocus(false)
    setNameFocus(false)
  }

  const filterName = (value: any, filed: string) => {
    setNameFocus(true)
    if (value) {
      const filteredData = convertedPatientData.filter((d) =>
        d.patientName
          .replace(/\s+/g, '')
          .toLowerCase()
          .includes(value.replace(/\s+/g, '').toLowerCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedPatientData])
    }
    setVisaFocus(false)
    setCreatedFocus(false)
    setNameFilter(value)
    setOccupationFocus(false)
    setMaritalStatusFocus(false)
    setAgeFocus(false)
    setNationalityFocus(false)
    setGenderFocus(false)
    setPhoneFocus(false)
    setEmailFocus(false)
    setLrFocus(false)
    setFatherFocus(false)
    setAddressFocus(false)
    setHusbandFocus(false)
  }

  const filterMaritalStatus = (value: any, filed: string) => {
    setMaritalStatusFocus(true)
    if (value) {
      const filteredData = convertedPatientData.filter((d) =>
        d.maritalStaTus.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedPatientData])
    }
    setVisaFocus(false)
    setCreatedFocus(false)
    setMaritalStatusFilter(value)
    setOccupationFocus(false)
    setAgeFocus(false)
    setNationalityFocus(false)
    setGenderFocus(false)
    setPhoneFocus(false)
    setEmailFocus(false)
    setLrFocus(false)
    setFatherFocus(false)
    setAddressFocus(false)
    setHusbandFocus(false)
  }

  const filterNationality = (value: any, filed: string) => {
    setNationalityFocus(true)
    scheduleData.current = false
    if (value) {
      const filteredData = convertedPatientData.filter((d) =>
        d.nationality.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedPatientData])
    }
    setVisaFocus(false)
    setCreatedFocus(false)
    setHationalityFilter(value)
    setOccupationFocus(false)
    setNameFocus(false)
    setAgeFocus(false)
    setGenderFocus(false)
    setPhoneFocus(false)
    setMaritalStatusFocus(false)
    setEmailFocus(false)
    setLrFocus(false)
    setFatherFocus(false)
    setAddressFocus(false)
    setHusbandFocus(false)
  }

  const filterGender = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedPatientData.filter((d) =>
        d.gender.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )
      filteredData.sort((a, b) =>
        a.gender.toLowerCase().localeCompare(b.gender.toLowerCase())
      )
      filteredData.reverse()
      setData(filteredData)
    } else {
      setData([...convertedPatientData])
    }
    setVisaFocus(false)
    setCreatedFocus(false)
    setGenderFilter(value)
    setNationalityFocus(false)
    setMaritalStatusFocus(false)
    setOccupationFocus(false)
    setAgeFocus(false)
    setNameFocus(false)
    setGenderFocus(true)
    setPhoneFocus(false)
    setEmailFocus(false)
    setLrFocus(false)
    setFatherFocus(false)
    setAddressFocus(false)
    setHusbandFocus(false)
  }

  const filterPhone = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedPatientData.filter((d) =>
        d.phoneNumber.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedPatientData])
    }
    setVisaFocus(false)
    setCreatedFocus(false)
    setAgeFocus(false)
    setNationalityFocus(false)
    setOccupationFocus(false)
    setMaritalStatusFocus(false)
    setNameFocus(false)
    setGenderFocus(false)
    setPhoneFocus(true)
    setEmailFocus(false)
    setLrFocus(false)
    setFatherFocus(false)
    setAddressFocus(false)
    setHusbandFocus(false)
    setPhoneFilter(value)
  }

  const filterEmail = (value: any, filed: string) => {
    emailData.current = false
    if (value) {
      const filteredData = convertedPatientData.filter((d) =>
        d.emailAddress.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )

      setData(filteredData)
    } else {
      setData([...convertedPatientData])
    }
    setVisaFocus(false)
    setAgeFocus(false)
    setOccupationFocus(false)
    setMaritalStatusFocus(false)
    setNationalityFocus(false)
    setNameFocus(false)
    setGenderFocus(false)
    setCreatedFocus(false)
    setPhoneFocus(false)
    setEmailFocus(true)
    setLrFocus(false)
    setFatherFocus(false)
    setAddressFocus(false)
    setHusbandFocus(false)
    setEmailFilter(value)
  }

  const filterAge = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedPatientData.filter((d) =>
        d.ageString.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )

      filteredData.sort((a, b) =>
        a.ageData > b.ageData ? 1 : b.ageData > a.ageData ? -1 : 0
      )
      setData(filteredData)
    } else {
      setData([...convertedPatientData])
    }
    setVisaFocus(false)
    setAgeFilter(value)
    setAgeFocus(true)
    setCreatedFocus(false)
    setOccupationFocus(false)
    setMaritalStatusFocus(false)
    setNationalityFocus(false)
    setNameFocus(false)
    setGenderFocus(false)
    setPhoneFocus(false)
    setEmailFocus(false)
    setLrFocus(false)
    setFatherFocus(false)
    setHusbandFocus(false)
    setAddressFocus(false)
  }
  const filterLr = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedPatientData.filter((d) =>
        d.labReference?.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )
      filteredData.sort((a, b) =>
        (a.labReference ? a.labReference : '')
          .toLowerCase()
          .localeCompare((b.labReference ? b.labReference : '').toLowerCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedPatientData])
    }
    setLRFilter(value)
    setVisaFocus(false)
    setOccupationFocus(false)
    setMaritalStatusFocus(false)
    setAgeFocus(false)
    setCreatedFocus(false)
    setNationalityFocus(false)
    setNameFocus(false)
    setGenderFocus(false)
    setPhoneFocus(false)
    setEmailFocus(false)
    setFatherFocus(false)
    setHusbandFocus(false)
    setLrFocus(true)
    setAddressFocus(false)
  }

  const filterAddress = (value: any, filed: string) => {
    addressData.current = false
    if (value) {
      const filteredData = convertedPatientData.filter((d) =>
        d.address.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedPatientData])
    }
    setVisaFocus(false)
    setAddressFilter(value)
    setAgeFocus(false)
    setMaritalStatusFocus(false)
    setOccupationFocus(false)
    setCreatedFocus(false)
    setNationalityFocus(false)
    setNameFocus(false)
    setGenderFocus(false)
    setPhoneFocus(false)
    setEmailFocus(false)
    setLrFocus(false)
    setHusbandFocus(false)
    setFatherFocus(false)
    setAddressFocus(true)
  }

  const filterFather = (value: any, filed: string) => {
    fathersNameData.current = false
    if (value) {
      const filteredData = convertedPatientData.filter((d) =>
        d.fathersName.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedPatientData])
    }
    setVisaFocus(false)
    setFatherFilter(value)
    setAgeFocus(false)
    setMaritalStatusFocus(false)
    setOccupationFocus(false)
    setNationalityFocus(false)
    setNameFocus(false)
    setCreatedFocus(false)
    setGenderFocus(false)
    setPhoneFocus(false)
    setEmailFocus(false)
    setLrFocus(false)
    setHusbandFocus(false)
    setAddressFocus(false)
    setFatherFocus(true)
  }

  const filterHusband = (value: any, filed: string) => {
    husbandData.current = false
    if (value) {
      const filteredData = convertedPatientData.filter((d) =>
        d.husbandName.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedPatientData])
    }
    setVisaFocus(false)
    setHusbandFilter(value)
    setAgeFocus(false)
    setOccupationFocus(false)
    setMaritalStatusFocus(false)
    setNationalityFocus(false)
    setNameFocus(false)
    setCreatedFocus(false)
    setGenderFocus(false)
    setPhoneFocus(false)
    setEmailFocus(false)
    setLrFocus(false)
    setHusbandFocus(true)
    setAddressFocus(false)
    setFatherFocus(false)
  }

  const columns = [
    {
      title: (
        <Box minWidth='150px' id='list1'>
          <Typography variant='h4' style={{ fontSize: 14 }} color='primary'>
            {t('labelPatient:attr_labels.Patient.name')}
          </Typography>
        </Box>
      ),
      field: 'patientName',
      filterPlaceholder: t('labelPatient:attr_labels.Patient.name'),
      hiddenByColumnsButton: false,
      cellStyle: {
        width: 150,
        maxWidth: 150,
      },
      customSort: (a: any, b: any) =>
        a.patientName.localeCompare(b.patientName, 'en', {
          sensitivity: 'base',
        }),
      render: (rowData: any) => (
        <Box id='list2'>
          <Typography
            variant='subtitle2'
            color='initial'
            style={{ wordWrap: 'break-word' }}
          >
            {rowData.patientName.split('\n').map((i: any, key: any) => (
              <div key={i}>{i}</div>
            ))}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box maxWidth={200}>
          <TextField
            variant='outlined'
            size='small'
            id='list3'
            autoFocus={nameFocus}
            placeholder={t('labelPatient:attr_labels.Patient.name')}
            value={nameFilter}
            onChange={(e) => filterName(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box minWidth='50px' id='list4'>
          <Typography variant='h4' style={{ fontSize: 14 }} color='primary'>
            {t('labelCommon:age')}
          </Typography>
        </Box>
      ),
      field: 'ageString',
      filterPlaceholder: t('labelCommon:age'),
      customSort: (a: any, b: any) =>
        a.ageData > b.ageData ? 1 : b.ageData > a.ageData ? -1 : 0,
      cellStyle: {
        width: 50,
        maxWidth: 100,
      },
      render: (rowData: any) => (
        <Box id='list6'>
          <Typography variant='subtitle2' color='initial'>
            {rowData.ageString}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box width={100}>
          <TextField
            id='list5'
            variant='outlined'
            size='small'
            autoFocus={ageFocus}
            placeholder={t('labelCommon:age')}
            value={ageFilter}
            onChange={(e) => filterAge(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box minWidth='50px' id='list7'>
          <Typography variant='h4' style={{ fontSize: 14 }} color='primary'>
            {t('labelPatient:attr_labels.Patient.gender')}
          </Typography>
        </Box>
      ),
      filterPlaceholder: t('labelPatient:attr_labels.Patient.gender'),
      field: 'gender',
      customSort: (a: any, b: any) =>
        a.gender > b.gender ? 1 : b.gender > a.gender ? -1 : 0,
      cellStyle: {
        width: 50,
        maxWidth: 100,
      },
      render: (rowData: any) => (
        <Box paddingY={1} id='list8'>
          {rowData.gender === 'male' && (
            <Box display='flex' alignItems='center'>
              <img src={maleIcon} alt='' style={{ height: 20 }} />
              <Typography variant='subtitle2' gutterBottom color='initial'>
                &nbsp; Male
              </Typography>
            </Box>
          )}
          {rowData.gender === 'female' && (
            <Box display='flex' alignItems='center'>
              <img src={femaleIcon} alt='' style={{ height: 20 }} />
              <Typography variant='subtitle2' gutterBottom color='initial'>
                &nbsp; Female
              </Typography>
            </Box>
          )}
          {rowData.gender === 'other' && (
            <Box display='flex' alignItems='center'>
              <Typography variant='subtitle2' gutterBottom color='initial'>
                &nbsp; Other
              </Typography>
            </Box>
          )}
          {rowData.gender === 'unknown' && (
            <Box display='flex' alignItems='center'>
              <Typography variant='subtitle2' gutterBottom color='initial'>
                &nbsp; Unknown
              </Typography>
            </Box>
          )}
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box width={100}>
          <TextField
            variant='outlined'
            size='small'
            id='list9'
            autoFocus={genderFocus}
            placeholder={t('labelPatient:attr_labels.Patient.gender')}
            value={genderFilter}
            onChange={(e) =>
              filterGender(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box minWidth='50px' id='list10'>
          <Typography variant='h4' style={{ fontSize: 14 }} color='primary'>
            {t('labelPatient:attr_labels.Patient.maritalStatus')}
          </Typography>
        </Box>
      ),
      filterPlaceholder: 'Marital Status ',
      field: 'maritalStaTus',
      customSort: (a: any, b: any) =>
        a.maritalStaTus > b.maritalStaTus
          ? 1
          : b.maritalStaTus > a.maritalStaTus
          ? -1
          : 0,
      cellStyle: {
        width: 150,
        maxWidth: 100,
      },
      render: (rowData: any) => (
        <Box paddingY={1} id='list11'>
          {rowData.maritalStaTus === 'Single' && (
            <Box display='flex' alignItems='center'>
              <Typography variant='subtitle2' color='initial'>
                Single
              </Typography>
            </Box>
          )}
          {rowData.maritalStaTus === 'Married' && (
            <Typography variant='subtitle2' color='initial'>
              {rowData.maritalStaTus}
            </Typography>
          )}
          {rowData.maritalStaTus === 'Divorced' && (
            <Typography variant='subtitle2' color='initial'>
              {rowData.maritalStaTus}
            </Typography>
          )}

          {rowData.maritalStaTus === 'Widowed' && (
            <Typography variant='subtitle2' color='initial'>
              {rowData.maritalStaTus}
            </Typography>
          )}
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box width={150}>
          <TextField
            variant='outlined'
            size='small'
            id='list12'
            autoFocus={maritalStatusFocus}
            placeholder={t('labelPatient:attr_labels.Patient.maritalStatus')}
            value={maritalStatusFilter}
            onChange={(e) =>
              filterMaritalStatus(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box id='list13'>
          <Typography variant='h4' style={{ fontSize: 14 }} color='primary'>
            {t('labelCommon:phone_number_label')}
          </Typography>
        </Box>
      ),
      filterPlaceholder: 'Phone Number ',
      field: 'phoneNumber',
      customSort: (a: any, b: any) =>
        a.phoneNumber > b.phoneNumber
          ? 1
          : b.phoneNumber > a.phoneNumber
          ? -1
          : 0,
      render: (rowData: any) => (
        <Box paddingY={1} id='list14'>
          <Typography variant='subtitle2' color='initial'>
            {rowData.phoneNumber}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box maxWidth={250}>
          <TextField
            variant='outlined'
            size='small'
            id='list15'
            autoFocus={phoneFocus}
            placeholder={t('labelCommon:phone_number_label')}
            value={phoneFilter}
            onChange={(e) => filterPhone(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box id='list16'>
          <Typography variant='h4' style={{ fontSize: 14 }} color='primary'>
            {t('labelCommon:email_address')}
          </Typography>
        </Box>
      ),
      filterPlaceholder: 'Email ',
      field: 'emailAddress',
      hidden: emailData.current,
      customSort: (a: any, b: any) =>
        a.emailAddress.localeCompare(b.emailAddress, 'en', {
          sensitivity: 'base',
        }),
      render: (rowData: any) => (
        <Box paddingY={1} id='list17'>
          <Typography noWrap variant='subtitle2' color='initial'>
            {rowData.emailAddress}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box width='105%'>
          <TextField
            variant='outlined'
            size='small'
            id='list18'
            autoFocus={emailFocus}
            placeholder={t('labelCommon:email_address')}
            value={emailFilter}
            onChange={(e) => filterEmail(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box id='list19'>
          <Typography variant='h4' style={{ fontSize: 14 }} color='primary'>
            {t('labelCommon:nationality_label')}
          </Typography>
        </Box>
      ),
      filterPlaceholder: 'Nationality ',
      field: 'nationality',
      hidden: scheduleData.current,
      customSort: (a: any, b: any) =>
        a.nationality > b.nationality
          ? 1
          : b.nationality > a.nationality
          ? -1
          : 0,
      render: (rowData: any) => (
        <Box paddingY={1} id='list20'>
          <Typography noWrap variant='subtitle2' color='initial'>
            {rowData.nationality === 'india' || rowData.nationality === 'India'
              ? 'India'
              : rowData.nationality}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box width='105%'>
          <TextField
            variant='outlined'
            size='small'
            id='list121'
            autoFocus={nationalityFocus}
            placeholder={t('labelCommon:nationality_label')}
            value={nationalityFilter}
            onChange={(e) =>
              filterNationality(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box id='list23'>
          <Typography variant='h4' style={{ fontSize: 14 }} color='primary'>
            {t('labelCommon:occupation_label')}
          </Typography>
        </Box>
      ),
      filterPlaceholder: 'Occupation ',
      customSort: (a: any, b: any) =>
        a.occupation > b.occupation ? 1 : b.occupation > a.occupation ? -1 : 0,
      field: 'occupation',
      hidden: occuptionData.current,
      render: (rowData: any) => (
        <Box paddingY={1} id='list124'>
          <Typography variant='subtitle2' color='initial'>
            {rowData.occupation}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box width='105%'>
          <TextField
            variant='outlined'
            size='small'
            id='list25'
            autoFocus={occupationFocus}
            placeholder={t('labelCommon:occupation_label')}
            value={occupationFilter}
            onChange={(e) =>
              filterOccupation(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    // {
    //   title: (
    //     <Box id='list26'>
    //       <Typography variant='h4' style={{ fontSize: 14 }} color='primary'>
    //         Created Date
    //       </Typography>
    //     </Box>
    //   ),
    //   filterPlaceholder: 'Created Date ',
    //   field: 'createdDate',
    //   customSort: (a: any, b: any) =>
    //     a.createdDate.localeCompare(b.createdDate),
    //   render: (rowData: any) => (
    //     <Box paddingY={1} id='list27'>
    //       <Typography variant='subtitle2' color='initial'>
    //         {rowData.createdDate !== 'undefined' ? rowData.createdDate : ''}
    //       </Typography>
    //     </Box>
    //   ),
    //   filterComponent: (props: any) => (
    //     <Box width='105%'>
    //       <TextField
    //         variant='outlined'
    //         size='small'
    //         id='list28'
    //         autoFocus={createdFocus}
    //         placeholder='Created Date'
    //         value={createdFilter}
    //         onChange={(e) =>
    //           filterCreatedDate(e.target.value, props.columnDef.field)
    //         }
    //         InputProps={{
    //           startAdornment: (
    //             <InputAdornment position='start'>
    //               <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
    //             </InputAdornment>
    //           ),
    //         }}
    //       />
    //     </Box>
    //   ),
    // },

    {
      title: (
        <Box id='list26'>
          <Typography variant='h4' style={{ fontSize: 14 }} color='primary'>
            Reference ID
          </Typography>
        </Box>
      ),
      filterPlaceholder: 'Reference Id ',
      field: 'labReference',
      customSort: (a: any, b: any) =>
        a.labReference.localeCompare(b.labReference),
      render: (rowData: any) => (
        <Box paddingY={1} id='list27'>
          <Typography variant='subtitle2' color='initial'>
            {rowData.labReference !== 'undefined' ? rowData.labReference : ''}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box width='105%'>
          <TextField
            variant='outlined'
            size='small'
            id='list28'
            autoFocus={lRFocus}
            placeholder={t('labelCommon:placeHolder_reference')}
            value={lRFilter}
            onChange={(e) => filterLr(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box id='list29'>
          <Typography variant='h4' style={{ fontSize: 14 }} color='primary'>
            {t('labelPatient:attr_labels.Patient.address')}
          </Typography>
        </Box>
      ),
      filterPlaceholder: 'Address ',
      customSort: (a: any, b: any) => a.address.localeCompare(b.address),
      field: 'address',
      hidden: addressData.current,
      cellStyle: {
        width: 20,
        maxWidth: 100,
      },
      render: (rowData: any) => (
        <Box p={1} id='list30'>
          <Typography variant='subtitle2' color='initial' noWrap>
            {rowData.address}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box width='105%'>
          <TextField
            variant='outlined'
            size='small'
            id='list31'
            autoFocus={addressFocus}
            placeholder={t('labelPatient:attr_labels.Patient.address')}
            value={addressFilter}
            onChange={(e) =>
              filterAddress(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box id='list31'>
          <Typography variant='h4' style={{ fontSize: 14 }} color='primary'>
            {t('labelCommon:fathers_name_label')}
          </Typography>
        </Box>
      ),
      filterPlaceholder: `Father's Name `,
      field: 'fathersName',
      hidden: fathersNameData.current,
      customSort: (a: any, b: any) =>
        a.fathersName.trim().localeCompare(b.fathersName.trim()),
      cellStyle: {
        width: 20,
        maxWidth: 100,
      },
      render: (rowData: any) => (
        <Box p={1} id='list32'>
          <Typography
            variant='subtitle2'
            color='initial'
            style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
          >
            {rowData.fathersName}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box width='105%'>
          <TextField
            variant='outlined'
            size='small'
            id='list35'
            autoFocus={fatherFocus}
            placeholder={t('labelCommon:fathers_name_label')}
            value={fatherFilter}
            onChange={(e) =>
              filterFather(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography variant='h4' style={{ fontSize: 14 }} color='primary'>
            Husband's Name
          </Typography>
        </Box>
      ),
      filterPlaceholder: `Husband's Name `,
      field: 'husbandName',
      hidden: husbandData.current,
      customSort: (a: any, b: any) =>
        a.husbandName.trim().localeCompare(b.husbandName.trim()),
      cellStyle: {
        width: 20,
        maxWidth: 100,
      },
      render: (rowData: any) => (
        <Box p={1} id='list36'>
          <Typography variant='subtitle2' color='initial' noWrap>
            {rowData.husbandName}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box width='105%'>
          <TextField
            variant='outlined'
            size='small'
            id='list36'
            autoFocus={husbandFocus}
            placeholder={t('labelCommon:husbands_name_label')}
            value={husbandFilter}
            onChange={(e) =>
              filterHusband(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box>
          <Typography variant='h4' style={{ fontSize: 14 }} color='primary'>
            Visa Number
          </Typography>
        </Box>
      ),
      filterPlaceholder: 'Visa Number',
      field: 'visaNo',
      hidden: visaData.current,
      customSort: (a: any, b: any) =>
        a.visaNo.trim().localeCompare(b.visaNo.trim()),
      cellStyle: {
        width: 20,
        maxWidth: 100,
      },
      render: (rowData: any) => (
        <Box p={1} id='list37'>
          <Typography variant='subtitle2' color='initial' noWrap>
            {rowData.visaNo}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box width='105%'>
          <TextField
            variant='outlined'
            size='small'
            id='list37'
            autoFocus={visaFocus}
            placeholder='Visa Number'
            value={visaFilter}
            onChange={(e) =>
              filterVisaNo(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
  ]
  //   convertedPatientData.sort(
  //     (x, y) => +new Date(x.lastUpdatedDate) - +new Date(y.lastUpdatedDate)
  //   )

  const [showProfile, setShowProfile] = useState<boolean>(false)
  const [profileData, setProfileData] =
    useState<PatientDataWithAppointment | undefined>()
  const { height, width } = useWindowDimensions()
  const tableRef = React.useRef()

  return (
    <Box
      style={{ overflow: 'none', width: `${width}px` }}
      paddingLeft={1.5}
      id='list38'
    >
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                color: '#333333',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
          Action: (props) => (
            <Button
              onClick={(event) => {}}
              color='primary'
              variant='contained'
              style={{ textTransform: 'none' }}
              size='small'
            >
              Resend Invite
            </Button>
          ),

          Container: (props) => <Paper {...props} elevation={0} />,

          Pagination: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                display: 'flex',
                justifyContent: 'left',
                padding: '0px',
                height: '60px',
                color: '#333333',
              }}
            >
              <TablePagination
                {...props}
                //   labelRowsPerPage={<div style={{ fontSize: 14 }}>{props.labelRowsPerPage} rows</div>}
                //   labelDisplayedRows={row => <div style={{ fontSize: 14 }}>{props.labelDisplayedRows(row)}</div>}
                SelectProps={{
                  style: {
                    fontSize: 14,
                    backgroundColor: '#ececec',
                  },
                }}
              />
            </div>
          ),
          Row: (props) => <MTableBodyRow id='list_body' {...props} />,
        }}
        columns={columns}
        data={data}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        onRowClick={(event, rowData) => {
          i18n.changeLanguage(language ?? 'en')
          if (rowData) onPatientSelected(rowData)
          setSelectedRow(true)
          setSelectedRowId(rowData?.id ?? '')
        }}
        tableRef={tableRef}
        options={{
          showTitle: false,
          padding: 'dense',
          search: false,
          //   searchFieldVariant: 'outlined',
          filtering: true,
          thirdSortClick: false,
          pageSize: 6,
          pageSizeOptions: [6, 10, 20, 30],
          columnsButton: true,
          maxBodyHeight: `${height - 190}px`,
          minBodyHeight: `${height - 260}px`,
          toolbarButtonAlignment: 'right',
          keepSortDirectionOnColumnSwitch: true,
          sorting: true,
          headerStyle: {
            backgroundColor: '#ececec',
            color: '#333333',
            width: 110,
            position: 'sticky',
            top: 0,
            height: 40,
          },

          filterRowStyle: {
            position: 'sticky',
            zIndex: 5 /* optionally */,
            top: 40,
            backgroundColor: '#ececec',
            color: '#333333',
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          //   actionsColumnIndex: -1,
          //   searchFieldAlignment: 'left',
          rowStyle: (_data: any, index: number, _level: number) =>
            index % 2
              ? selectedRow && _data.id === selectedRowId
                ? { backgroundColor: '#9E9DDC', height: 50 }
                : { backgroundColor: kDialogueBackground }
              : selectedRow && _data.id === selectedRowId
              ? { backgroundColor: '#9E9DDC', height: 50 }
              : {},
          //   toolbar: false,
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />

      {showProfile === true && (
        <PatientProfile
          open={showProfile}
          displayText='Working hours will be deleted permanently. Do you want to continue?'
          title='Patient Profile'
          doneButtonText='Done'
          cancelButtonText='Back'
          onClose={() => {
            setShowProfile(false)
          }}
          onDiscard={() => {
            setShowProfile(false)
          }}
          patientData={profileData}
        />
      )}
    </Box>
  )
}
