import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { ConvenienceFee } from 'models/convinienceFeeDetails'
import { LabReferralDetails } from 'models/labReferralDetails'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'

import {
  getAmountForReferralFee,
  getAmountForReferralFeeInPercent,
} from 'utils/fhirResoureHelpers/referralHelpers'
import { logger } from 'utils/logger'
import { ConvenienceSearchStatus } from './convinienceSearchStatus'
import { ReferralFeeSearchStatus } from './referralSearchStatus'

const initialState: ReferralFeeSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const referralSearchSliceSetting = createSlice({
  name: 'referralSearchSliceSetting',
  initialState,
  reducers: {
    searchingCFee(state, action: PayloadAction<ReferralFeeSearchStatus>) {},

    searchResults(state, action: PayloadAction<ReferralFeeSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable

      state.referralList = action.payload.referralList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<ReferralFeeSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable

      state.referralList = action.payload.referralList
    },

    errorWhileSearching(state, action: PayloadAction<ReferralFeeSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable

      state.referralList = action.payload.referralList
    },
  },
})

export const SearchingReferrals = (): AppThunk => async (dispatch: any) => {
  const errorFetchingUnits: ReferralFeeSearchStatus = {
    error: false,
    noResultsAvailable: false,
    resultsAvailable: false,
    searching: true,
  }
  dispatch(
    referralSearchSliceSetting.actions.errorWhileSearching(errorFetchingUnits)
  )
  try {
    let searchParameter = {}
    searchParameter = {
      _count: 500,
    }
    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const response = await fhirClient.doGetResource(
      `/ChargeItemDefinition?charge-defn-code=referral-fee`,
      searchParameter
    )

    const orgResponse: R4.IBundle = response
    if (orgResponse.total) {
      if (orgResponse.total > 0) {
        if (orgResponse.entry) {
          const rFeeArray: R4.IChargeItemDefinition[] = orgResponse.entry.map(
            (item) => item.resource as R4.IChargeItemDefinition
          )

          const referralFeeArray: ConvenienceFee[] = []
          //   const patientContext =
          //     rFeeArray[0].useContext && rFeeArray[0].useContext.length > 0
          //       ? rFeeArray[0].useContext.filter(
          //           (e) => e.code && e.code.code === 'allowed-patient-limit'
          //         )
          //       : []

          const eventTimeCotext =
            rFeeArray[0].useContext && rFeeArray[0].useContext.length > 0
              ? rFeeArray[0].useContext.filter(
                  (e) => e.code && e.code.code === 'event-timing'
                )
              : []

          const specialtyCodingCotext =
            rFeeArray[0].useContext && rFeeArray[0].useContext.length > 0
              ? rFeeArray[0].useContext.filter(
                  (e) => e.code && e.code.code === 'speciality'
                )
              : []

          const serviceTypeContext =
            rFeeArray[0].useContext && rFeeArray[0].useContext.length > 0
              ? rFeeArray[0].useContext.filter(
                  (e) => e.code && e.code.code === 'service-type'
                )
              : []

          const unitTypeContext =
            rFeeArray[0].useContext && rFeeArray[0].useContext.length > 0
              ? rFeeArray[0].useContext.filter(
                  (e) => e.code && e.code.code === 'unit-type'
                )
              : []

          referralFeeArray.push({
            id: rFeeArray[0].id ?? '',
            amount: getAmountForReferralFeeInPercent(
              rFeeArray[0].propertyGroup ?? []
            ),
            percentage: true,
            specialtyCoding: [],
            eventTime: [],
            cFee: rFeeArray[0],
            serviceType: undefined,
            unitType: undefined,
            patientCount: 1,
          })

          const fetchUnitListResult: ConvenienceSearchStatus = {
            error: false,
            noResultsAvailable: false,
            resultsAvailable: true,
            searching: false,

            referralList: referralFeeArray,
          }
          dispatch(
            referralSearchSliceSetting.actions.searchResults(
              fetchUnitListResult
            )
          )
          return
        }
      }
    }
    const noSearchResults: ConvenienceSearchStatus = {
      error: false,
      noResultsAvailable: true,
      resultsAvailable: false,
      searching: false,
    }
    dispatch(
      referralSearchSliceSetting.actions.noDataFoundForSearch(noSearchResults)
    )
    return
  } catch (error) {
    logger.error(error)
    const errorWhileSearchPatient: ConvenienceSearchStatus = {
      error: true,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: 'Error',
    }
    dispatch(
      referralSearchSliceSetting.actions.errorWhileSearching(
        errorWhileSearchPatient
      )
    )
  }
}

export default referralSearchSliceSetting.reducer
