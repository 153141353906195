import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography, useTheme } from '@material-ui/core'
import Done from '@material-ui/icons/Done'
import { kPrimaryMain } from 'configs/styles/muiThemes'
import React from 'react'
import { getBenefitDetails } from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import { getDefaultCodeOfSystemFromCodableConcept } from 'utils/fhirResourcesHelper'

export interface PlanBenefitDetailProps {
  offer: R4.IPlanDefinition_Action
}
export const PlanBenefitDetail: React.FC<PlanBenefitDetailProps> = ({
  offer,
}) => {
  const theme = useTheme()

  return (
    <Box
      display='flex'
      flexDirection='row'
      width='100%'
      alignContent='center'
      alignItems='center'
      alignSelf='center'
      justifyContent='center'
    >
      {(getDefaultCodeOfSystemFromCodableConcept(offer.type) ===
        'communication' ||
        getDefaultCodeOfSystemFromCodableConcept(offer.type) ===
          'additional-service' ||
        getDefaultCodeOfSystemFromCodableConcept(offer.type) ===
          'appointment') && (
        <Done style={{ color: kPrimaryMain }} fontSize='small' />
      )}

      {getDefaultCodeOfSystemFromCodableConcept(offer.type) === 'order' && (
        <Box>
          <Typography>{getBenefitDetails(offer)}</Typography>
        </Box>
      )}
      {getDefaultCodeOfSystemFromCodableConcept(offer.type) ===
        'appointment' && (
        <Box>
          <Typography>{getBenefitDetails(offer)}</Typography>
        </Box>
      )}
    </Box>
  )
}
