import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  getBuiltVal,
  getCodeOfSystemFromCodableConcept,
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultDisplayOfSystemFromCodableConcept,
  getDisplayOfSystemFromCodableConceptForAppointment,
  getNourishVal,
  getPallorData,
} from 'utils/fhirResourcesHelper'
import { getNotesString } from 'utils/fhirResoureHelpers/allergyHelpers'

export function cToF(celsius: number): number {
  const cTemp = celsius
  const cToFahr = (cTemp * 9) / 5 + 32
  return cToFahr
}

export function fToC(fahrenheit: number): number {
  const fTemp = fahrenheit
  const fToCel = ((fTemp - 32) * 5) / 9
  return fToCel
}

export function getSortedObservation(
  observations: R4.IObservation[]
): R4.IObservation[] {
  const sortedObservation: R4.IObservation[] = []

  const indexOfBodyTemp = observations.findIndex(
    (e) => getDefaultCodeOfSystemFromCodableConcept(e.code) === '8310-5'
  )
  if (indexOfBodyTemp > -1) {
    sortedObservation.push(observations[indexOfBodyTemp])
  }
  const indexOfPulseRate = observations.findIndex(
    (e) => getDefaultCodeOfSystemFromCodableConcept(e.code) === '8867-4'
  )
  if (indexOfPulseRate > -1) {
    sortedObservation.push(observations[indexOfPulseRate])
  }
  const indexOfBP = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('85354-9')
  )
  if (indexOfBP > -1) {
    sortedObservation.push(observations[indexOfBP])
  }

  if (sortedObservation.length > 0) {
    const remainingObservations = [...observations].filter(
      (e) => !sortedObservation.map((obs) => obs.id ?? '').includes(e.id ?? '')
    )
    return [...sortedObservation, ...remainingObservations]
  }
  return observations
}

export function getSortedObservationForIpd(
  observations: R4.IObservation[]
): R4.IObservation[] {
  const sortedObservation: R4.IObservation[] = []

  const indexOfBodyTemp = observations.findIndex(
    (e) => getDefaultCodeOfSystemFromCodableConcept(e.code) === '8310-5'
  )
  if (indexOfBodyTemp > -1) {
    sortedObservation.push(observations[indexOfBodyTemp])
  }
  const indexOfPulseRate = observations.findIndex(
    (e) => getDefaultCodeOfSystemFromCodableConcept(e.code) === '8867-4'
  )

  if (indexOfPulseRate > -1) {
    sortedObservation.push(observations[indexOfPulseRate])
  }

  //   const indexOfHeight = observations.findIndex(
  //     (e) =>
  //       getDefaultCodeOfSystemFromCodableConcept(e.code, 'http://loinc.org') ===
  //       '29463-7'
  //   )

  const indexOfHeight = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('8302-2')
  )

  if (indexOfHeight > -1) {
    sortedObservation.push(observations[indexOfHeight])
  }

  const indexOfWeight = observations.findIndex(
    (e) => getDefaultCodeOfSystemFromCodableConcept(e.code) === '29463-7'
  )

  if (indexOfWeight > -1) {
    sortedObservation.push(observations[indexOfWeight])
  }

  const indexOfRR = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('9279-1')
  )

  //   const indexOfRR = observations.findIndex(
  //     (e) =>
  //       getDefaultCodeOfSystemFromCodableConcept(e.code, 'https://loinc.org') ===
  //       '9279-1'
  //   )

  if (indexOfRR > -1) {
    sortedObservation.push(observations[indexOfRR])
  }

  const indexOfBP = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('85354-9')
  )

  if (indexOfBP > -1) {
    sortedObservation.push(observations[indexOfBP])
  }

  const indexOfBMI = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('39156-5')
  )

  if (indexOfBMI > -1) {
    sortedObservation.push(observations[indexOfBMI])
  }

  //   if (sortedObservation.length > 0) {
  //     const remainingObservations = [...observations].filter(
  //       (e) => !sortedObservation.map((obs) => obs.id ?? '').includes(e.id ?? '')
  //     )
  //     return [...sortedObservation, ...remainingObservations]
  //   }
  return sortedObservation
}

export function getSortedObservationForHabit(
  observations: R4.IObservation[]
): R4.IObservation[] {
  const sortedObservation: R4.IObservation[] = []

  const indexOfBodyTemp = observations.findIndex(
    (e) => getDefaultCodeOfSystemFromCodableConcept(e.code) === '74009-2'
  )
  if (indexOfBodyTemp > -1) {
    sortedObservation.push(observations[indexOfBodyTemp])
  }
  const indexOfPulseRate = observations.findIndex(
    (e) => getDefaultCodeOfSystemFromCodableConcept(e.code) === '10000-2'
  )

  if (indexOfPulseRate > -1) {
    sortedObservation.push(observations[indexOfPulseRate])
  }

  //   const indexOfHeight = observations.findIndex(
  //     (e) =>
  //       getDefaultCodeOfSystemFromCodableConcept(e.code, 'http://loinc.org') ===
  //       '29463-7'
  //   )

  const indexOfHeight = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('74008-4')
  )

  if (indexOfHeight > -1) {
    sortedObservation.push(observations[indexOfHeight])
  }

  const indexOfWeight = observations.findIndex(
    (e) => getDefaultCodeOfSystemFromCodableConcept(e.code) === '65515-9'
  )

  if (indexOfWeight > -1) {
    sortedObservation.push(observations[indexOfWeight])
  }

  const indexOfRR = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('28148-5')
  )

  //   const indexOfRR = observations.findIndex(
  //     (e) =>
  //       getDefaultCodeOfSystemFromCodableConcept(e.code, 'https://loinc.org') ===
  //       '9279-1'
  //   )

  if (indexOfRR > -1) {
    sortedObservation.push(observations[indexOfRR])
  }

  const indexOfBP = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('10000-1')
  )

  if (indexOfBP > -1) {
    sortedObservation.push(observations[indexOfBP])
  }

  const indexOfBMI = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('65968-0')
  )

  if (indexOfBMI > -1) {
    sortedObservation.push(observations[indexOfBMI])
  }

  const indexOfOther = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('65561-3')
  )

  if (indexOfOther > -1) {
    sortedObservation.push(observations[indexOfOther])
  }
  const indexOfOtherData = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('any-other')
  )

  if (indexOfOtherData > -1) {
    sortedObservation.push(observations[indexOfOtherData])
  }

  //   if (sortedObservation.length > 0) {
  //     const remainingObservations = [...observations].filter(
  //       (e) => !sortedObservation.map((obs) => obs.id ?? '').includes(e.id ?? '')
  //     )
  //     return [...sortedObservation, ...remainingObservations]
  //   }
  return sortedObservation
}

export function getSortedObservationForAddLab(
  observations: R4.IObservation[]
): R4.IObservation[] {
  const sortedObservation: R4.IObservation[] = []

  const indexOfBodyTemp = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code, 'http://loinc.org') ===
      '1558-6'
  )
  if (indexOfBodyTemp > -1) {
    sortedObservation.push(observations[indexOfBodyTemp])
  }
  const indexOfPulseRate = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code, 'http://loinc.org') ===
      '4548-4'
  )

  if (indexOfPulseRate > -1) {
    sortedObservation.push(observations[indexOfPulseRate])
  }

  const indexOfHeight = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('718-7')
  )
  if (indexOfHeight > -1 && observations[indexOfHeight].code.text === 'Test') {
    sortedObservation.push(observations[indexOfHeight])
  }

  const indexOfWeight = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code, 'http://loinc.org') ===
      '1521-4'
  )

  if (indexOfWeight > -1) {
    sortedObservation.push(observations[indexOfWeight])
  }

  const indexOfRR = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('1986-9')
  )

  if (indexOfRR > -1) {
    sortedObservation.push(observations[indexOfRR])
  }

  const indexOfBP = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2160-0')
  )

  if (indexOfBP > -1) {
    sortedObservation.push(observations[indexOfBP])
  }

  const indexOfBMI = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('3091-6')
  )

  if (indexOfBMI > -1) {
    sortedObservation.push(observations[indexOfBMI])
  }

  return sortedObservation
}

export function getSortedObservationForGeneralExam(
  observations: R4.IObservation[]
): R4.IObservation[] {
  const sortedObservation: R4.IObservation[] = []

  const indexOfBuilt = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(
        e.code,
        'http://snomed.info/sct'
      ) === '165184000'
  )

  if (indexOfBuilt > -1) {
    sortedObservation.push(observations[indexOfBuilt])
  }
  const indexOfNourish = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(
        e.code,
        'http://snomed.info/sct'
      ) === '1759002'
  )

  if (indexOfNourish > -1) {
    sortedObservation.push(observations[indexOfNourish])
  }

  const indexOfPollar = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(
        e.code,
        'http://snomed.info/sct'
      ) === '1237486008'
  )

  if (indexOfPollar > -1) {
    sortedObservation.push(observations[indexOfPollar])
  }

  const indexOfIct = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(
        e.code,
        'http://snomed.info/sct'
      ) === '18165001'
  )

  if (indexOfIct > -1) {
    sortedObservation.push(observations[indexOfIct])
  }

  const indexOfCyn = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('3415004')
  )

  if (indexOfCyn > -1) {
    sortedObservation.push(observations[indexOfCyn])
  }

  const indexOfClubbing = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('4373005')
  )

  if (indexOfClubbing > -1) {
    sortedObservation.push(observations[indexOfClubbing])
  }

  const indexOfLyn = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('30746006')
  )

  if (indexOfLyn > -1) {
    sortedObservation.push(observations[indexOfLyn])
  }

  return sortedObservation
}

export const getObServationArrangeUrine = (
  observations: R4.IObservation[]
): R4.IObservation[] => {
  const obServationArrData: R4.IObservation[] = []

  const indexOfBodyTemp = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('5778-6')
  )
  if (indexOfBodyTemp > -1) {
    obServationArrData.push(observations[indexOfBodyTemp])
  }

  const index2 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('5767-9')
  )
  if (index2 > -1) {
    obServationArrData.push(observations[index2])
  }

  const index3 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('32167-9')
  )
  if (index3 > -1) {
    obServationArrData.push(observations[index3])
  }

  const index4 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('34533-0')
  )
  if (index4 > -1) {
    obServationArrData.push(observations[index4])
  }

  const index4Missing = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('5811-5')
  )
  if (index4Missing > -1) {
    obServationArrData.push(observations[index4Missing])
  }

  const index5Missing = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('5803-2')
  )
  if (index5Missing > -1) {
    obServationArrData.push(observations[index5Missing])
  }

  const index5 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('20454-5')
  )

  if (index5 > -1) {
    obServationArrData.push(observations[index5])
  }
  const index6 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('25428-4') &&
      e.code.text &&
      e.code.text.includes('URINE')
  )
  if (index6 > -1) {
    obServationArrData.push(observations[index6])
  }
  const index7 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2514-8') &&
      e.code.text &&
      e.code.text.includes('URINE')
  )
  if (index7 > -1) {
    obServationArrData.push(observations[index7])
  }

  const index8 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('5794-3') &&
      e.code.text &&
      e.code.text.includes('URINE')
  )
  if (index8 > -1) {
    obServationArrData.push(observations[index8])
  }

  const index9 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('5802-4') &&
      e.code.text &&
      e.code.text.includes('URINE')
  )
  if (index9 > -1) {
    obServationArrData.push(observations[index9])
  }
  const index10 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('5799-2') &&
      e.code.text &&
      e.code.text.includes('URINE')
  )
  if (index10 > -1) {
    obServationArrData.push(observations[index10])
  }

  const index11 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('5770-3') &&
      e.code.text &&
      e.code.text.includes('URINE')
  )
  if (index11 > -1) {
    obServationArrData.push(observations[index11])
  }
  const index12 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('20405-7') &&
      e.code.text &&
      e.code.text.includes('URINE')
  )
  if (index12 > -1) {
    obServationArrData.push(observations[index12])
  }
  const index13 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('5809-9') &&
      e.code.text &&
      e.code.text.includes('URINE')
  )
  if (index13 > -1) {
    obServationArrData.push(observations[index13])
  }
  //   const index14 = observations.findIndex(
  //     (e) => e.preferredReportName === 'Urobilinogen'
  //   )
  //   if (index14 > -1) {
  //     obServationArrData.push(observations[index14])
  //   }
  //   const index15 = observations.findIndex(
  //     (e) => e.preferredReportName === 'Reducing substances'
  //   )
  //   if (index15 > -1) {
  //     obServationArrData.push(observations[index15])
  //   }
  return obServationArrData
}

export function getSortedObservationForAddLabForPanel(
  observations: R4.IObservation[]
): R4.IObservation[] {
  const sortedObservation: R4.IObservation[] = []

  const indexOfBodyTemp = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code, 'http://loinc.org') ===
      '1558-6'
  )
  if (indexOfBodyTemp > -1) {
    sortedObservation.push(observations[indexOfBodyTemp])
  }
  const indexOfPulseRate = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code, 'http://loinc.org') ===
      '4548-4'
  )

  if (indexOfPulseRate > -1) {
    sortedObservation.push(observations[indexOfPulseRate])
  }

  const indexOfWeight = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code, 'http://loinc.org') ===
      '1521-4'
  )

  if (indexOfWeight > -1) {
    sortedObservation.push(observations[indexOfWeight])
  }

  const indexOfRR = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('1986-9')
  )

  //   const indexOfRR = observations.findIndex(
  //     (e) =>
  //       getDefaultCodeOfSystemFromCodableConcept(e.code, 'https://loinc.org') ===
  //       '9279-1'
  //   )

  const indexOfBMI = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('3091-6')
  )

  if (indexOfBMI > -1) {
    sortedObservation.push(observations[indexOfBMI])
  }

  const p199 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('706-2') &&
      e.code.text &&
      e.code.text.includes('H')
  )
  if (p199 > -1) {
    sortedObservation.push(observations[p199])
  }
  const p1 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('704-7')
  )
  if (p1 > -1) {
    sortedObservation.push(observations[p1])
  }

  const p2 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2823-3') &&
      e.code.text !== 'Serum Lytes'
  )
  if (p2 > -1) {
    sortedObservation.push(observations[p2])
  }

  const p3 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('713-8')
  )
  if (p3 > -1) {
    sortedObservation.push(observations[p3])
  }
  const p4 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('711-2')
  )
  if (p4 > -1) {
    sortedObservation.push(observations[p4])
  }

  const p5 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('4544-3')
  )
  if (p5 > -1) {
    sortedObservation.push(observations[p5])
  }

  const p6 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('718-7') &&
      e.code.text !== 'Test'
  )

  if (p6 > -1 && observations[p6].code.text !== 'Test') {
    sortedObservation.push(observations[p6])
  }

  const p7 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('736-9') &&
      e.code.text &&
      e.code.text.includes('H')
  )
  if (p7 > -1) {
    sortedObservation.push(observations[p7])
  }

  const p8 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('731-0')
  )
  if (p8 > -1) {
    sortedObservation.push(observations[p8])
  }
  const p9 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('785-6')
  )
  if (p9 > -1) {
    sortedObservation.push(observations[p9])
  }

  const p10 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('786-4')
  )
  if (p10 > -1) {
    sortedObservation.push(observations[p10])
  }

  const p11 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('787-2')
  )
  if (p11 > -1) {
    sortedObservation.push(observations[p11])
  }

  const p12 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('5905-5')
  )
  if (p12 > -1) {
    sortedObservation.push(observations[p12])
  }

  const p13 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('742-7')
  )
  if (p13 > -1) {
    sortedObservation.push(observations[p13])
  }

  const p15 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('770-8') &&
      e.code.text &&
      e.code.text.includes('H')
  )
  if (p15 > -1) {
    sortedObservation.push(observations[p15])
  }

  const p16 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('751-8')
  )
  if (p16 > -1) {
    sortedObservation.push(observations[p16])
  }

  const p14 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('58413-6')
  )
  if (p14 > -1) {
    sortedObservation.push(observations[p14])
  }
  const p17 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('777-3')
  )
  if (p17 > -1) {
    sortedObservation.push(observations[p17])
  }

  const p18 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('789-8')
  )
  if (p18 > -1) {
    sortedObservation.push(observations[p18])
  }

  const p19 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('788-0')
  )
  if (p19 > -1) {
    sortedObservation.push(observations[p19])
  }

  const p20 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('6690-2')
  )
  if (p20 > -1) {
    sortedObservation.push(observations[p20])
  }

  const p21 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('71490-7')
  )
  if (p21 > -1) {
    sortedObservation.push(observations[p21])
  }

  const p22 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('71491-5')
  )
  if (p22 > -1) {
    sortedObservation.push(observations[p22])
  }

  const pnew = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2085-9')
  )
  if (pnew > -1) {
    sortedObservation.push(observations[pnew])
  }

  const p23 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('13457-7')
  )
  if (p23 > -1) {
    sortedObservation.push(observations[p23])
  }

  const p24 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('11054-4')
  )
  if (p24 > -1) {
    sortedObservation.push(observations[p24])
  }

  const p25 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2093-3')
  )
  if (p25 > -1) {
    sortedObservation.push(observations[p25])
  }

  const p26 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('I9830-1')
  )
  if (p26 > -1) {
    sortedObservation.push(observations[p26])
  }

  const p27 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2571-8')
  )
  if (p27 > -1) {
    sortedObservation.push(observations[p27])
  }

  const p28 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('13458-5')
  )
  if (p28 > -1) {
    sortedObservation.push(observations[p28])
  }

  const p29 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('5767-9')
  )
  if (p29 > -1) {
    sortedObservation.push(observations[p29])
  }

  const p30 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('25428-4')
  )
  if (p30 > -1) {
    sortedObservation.push(observations[p30])
  }

  const p31 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('5794-3')
  )
  if (p31 > -1) {
    sortedObservation.push(observations[p31])
  }

  const p32 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2514-8')
  )
  if (p32 > -1) {
    sortedObservation.push(observations[p32])
  }

  const p33 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('5799-2')
  )
  if (p33 > -1) {
    sortedObservation.push(observations[p33])
  }

  const p34 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('20454-5')
  )
  if (p34 > -1) {
    sortedObservation.push(observations[p34])
  }

  const p35 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('5809-9')
  )
  if (p35 > -1) {
    sortedObservation.push(observations[p35])
  }

  const p36 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('5811-5')
  )
  if (p36 > -1) {
    sortedObservation.push(observations[p36])
  }

  const p37 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('5778-6')
  )
  if (p37 > -1) {
    sortedObservation.push(observations[p37])
  }

  const p38 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('5803-2')
  )
  if (p38 > -1) {
    sortedObservation.push(observations[p38])
  }

  const p39 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2345-7')
  )
  if (p39 > -1) {
    sortedObservation.push(observations[p39])
  }
  const p40 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('3094-0')
  )
  if (p40 > -1) {
    sortedObservation.push(observations[p40])
  }

  const p41 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2160-0') &&
      e.code.text === 'RFT'
  )
  if (p41 > -1) {
    sortedObservation.push(observations[p41])
  }

  const p42 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('98979-8')
  )
  if (p42 > -1) {
    sortedObservation.push(observations[p42])
  }
  const p43 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('3097-3')
  )
  if (p43 > -1) {
    sortedObservation.push(observations[p43])
  }
  const p44 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2951-2') &&
      e.code.text !== 'Serum Lytes'
  )
  if (p44 > -1) {
    sortedObservation.push(observations[p44])
  }
  const p45 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('736-9') &&
      e.code.text &&
      e.code.text.includes('H') === false
  )
  if (p45 > -1) {
    sortedObservation.push(observations[p45])
  }
  const p46 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2075-0') &&
      e.code.text !== 'Serum Lytes'
  )
  if (p46 > -1) {
    sortedObservation.push(observations[p46])
  }
  const p47 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2028-9') &&
      e.code.text !== 'Serum Lytes'
  )
  if (p47 > -1) {
    sortedObservation.push(observations[p47])
  }
  const p48 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('17861-6')
  )
  if (p48 > -1) {
    sortedObservation.push(observations[p48])
  }
  const p49 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2777-1')
  )
  if (p49 > -1) {
    sortedObservation.push(observations[p49])
  }

  const lftPanel1 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('1759-0') &&
      e.code.text === 'LFT'
  )

  if (lftPanel1 > -1) {
    sortedObservation.push(observations[lftPanel1])
  }

  const lftPanel2 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('1968-7') &&
      e.code.text === 'LFT'
  )

  if (lftPanel2 > -1) {
    sortedObservation.push(observations[lftPanel2])
  }

  const lftPanel5 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2324-2') &&
      e.code.text === 'LFT'
  )

  if (lftPanel5 > -1) {
    sortedObservation.push(observations[lftPanel5])
  }

  const lftPanel3 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2336-6') &&
      e.code.text === 'LFT'
  )

  const lftPanel6 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('1975-2') &&
      e.code.text === 'LFT'
  )

  if (lftPanel6 > -1) {
    sortedObservation.push(observations[lftPanel6])
  }

  if (lftPanel3 > -1) {
    sortedObservation.push(observations[lftPanel3])
  }

  const lftPanel4 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('1971-1') &&
      e.code.text === 'LFT'
  )

  if (lftPanel4 > -1) {
    sortedObservation.push(observations[lftPanel4])
  }

  const p50 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('1751-7') &&
      e.code.text === 'LFT'
  )

  if (p50 > -1) {
    sortedObservation.push(observations[p50])
  }

  const p51 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2885-2')
  )
  if (p51 > -1) {
    sortedObservation.push(observations[p51])
  }
  const p52 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('1751-7') &&
      e.code.text === 'RFT'
  )

  if (p52 > -1) {
    sortedObservation.push(observations[p52])
  }

  const rftPanel = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('3084-1') &&
      e.code.text === 'RFT'
  )

  if (rftPanel > -1) {
    sortedObservation.push(observations[rftPanel])
  }

  const p53 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('1968-7') &&
      e.code.text !== 'LFT'
  )
  if (p53 > -1) {
    sortedObservation.push(observations[p53])
  }
  const p54 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('6768-6')
  )
  if (p54 > -1) {
    sortedObservation.push(observations[p54])
  }
  const p55 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('1920-8')
  )
  if (p55 > -1) {
    sortedObservation.push(observations[p55])
  }
  const p56 = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('1742-6')
  )
  if (p56 > -1) {
    sortedObservation.push(observations[p56])
  }
  const p57 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('3024-7') &&
      e.code.text === 'TFT'
  )

  if (p57 > -1) {
    sortedObservation.push(observations[p57])
  }

  const p58 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('3051-0') &&
      e.code.text === 'TFT'
  )

  if (p58 > -1) {
    sortedObservation.push(observations[p58])
  }

  const p59 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('11580-8') &&
      e.code.text === 'TFT'
  )

  if (p59 > -1) {
    sortedObservation.push(observations[p59])
  }

  const p60 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('3053-6') &&
      e.code.text === 'TFT'
  )

  if (p60 > -1) {
    sortedObservation.push(observations[p60])
  }

  const p61 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('3026-2') &&
      e.code.text === 'TFT'
  )

  if (p61 > -1) {
    sortedObservation.push(observations[p61])
  }

  const Homa1 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('47214-2') &&
      e.code.text === 'HOMA'
  )
  if (Homa1 > -1) {
    sortedObservation.push(observations[Homa1])
  }
  const Homa2 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('27873-9') &&
      e.code.text === 'HOMA'
  )
  if (Homa2 > -1) {
    sortedObservation.push(observations[Homa2])
  }

  const dexa1 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('41982-0') &&
      e.code.text === 'DEXA'
  )
  if (dexa1 > -1) {
    sortedObservation.push(observations[dexa1])
  }

  const dexa2 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('91557-9') &&
      e.code.text === 'DEXA'
  )
  if (dexa2 > -1) {
    sortedObservation.push(observations[dexa2])
  }

  const dexa3 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('73707-2') &&
      e.code.text === 'DEXA'
  )
  if (dexa3 > -1) {
    sortedObservation.push(observations[dexa3])
  }

  const dexa4 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('38268-9') &&
      e.code.text === 'DEXA'
  )
  if (dexa4 > -1) {
    sortedObservation.push(observations[dexa4])
  }
  const dexa5 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('69429-9') &&
      e.code.text === 'DEXA'
  )
  if (dexa5 > -1) {
    sortedObservation.push(observations[dexa5])
  }

  const dexa6 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('73964-9') &&
      e.code.text === 'DEXA'
  )
  if (dexa6 > -1) {
    sortedObservation.push(observations[dexa6])
  }

  const serumLytes1 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2028-9') &&
      e.code.text === 'Serum Lytes'
  )
  if (serumLytes1 > -1) {
    sortedObservation.push(observations[serumLytes1])
  }

  const serumLytes2 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2075-0') &&
      e.code.text === 'Serum Lytes'
  )
  if (serumLytes2 > -1) {
    sortedObservation.push(observations[serumLytes2])
  }
  const serumLytes3 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2823-3') &&
      e.code.text === 'Serum Lytes'
  )
  if (serumLytes3 > -1) {
    sortedObservation.push(observations[serumLytes3])
  }
  const serumLytes4 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('2951-2') &&
      e.code.text === 'Serum Lytes'
  )
  if (serumLytes4 > -1) {
    sortedObservation.push(observations[serumLytes4])
  }

  const serumLytes5 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('1963-8') &&
      e.code.text === 'Serum Lytes'
  )
  if (serumLytes5 > -1) {
    sortedObservation.push(observations[serumLytes5])
  }

  const serumLytes6 = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('33037-3') &&
      e.code.text === 'Serum Lytes'
  )
  if (serumLytes6 > -1) {
    sortedObservation.push(observations[serumLytes6])
  }

  return sortedObservation.sort((a, b) =>
    a.code!.coding![0].display!.toLowerCase() >
    b.code!.coding![0].display!.toLowerCase()
      ? 1
      : b.code!.coding![0].display!.toLowerCase() >
        a.code!.coding![0].display!.toLowerCase()
      ? -1
      : 0
  )
}

export function getSortedOrderStatus(
  observations: R4.IProvenance[]
): R4.IProvenance[] {
  const sortedObservation: R4.IProvenance[] = []

  const scheduled = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.activity)?.includes('scheduled')
  )

  if (scheduled > -1) {
    sortedObservation.push(observations[scheduled])
  }

  const cancelled = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.activity)?.includes('cancelled')
  )

  if (cancelled > -1) {
    sortedObservation.push(observations[cancelled])
  }

  const rescheduled = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.activity)?.includes(
      'rescheduled'
    )
  )

  if (rescheduled > -1) {
    sortedObservation.push(observations[rescheduled])
  }
  const pickUpProgress = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.activity) ===
      'pickup_in_progress'
  )

  //   const indexOfHeight = observations.findIndex(
  //     (e) =>
  //       getDefaultCodeOfSystemFromCodableConcept(e.code, 'http://loinc.org') ===
  //       '29463-7'
  //   )

  const indexOfHeight = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.activity)?.includes(
      'agent_started'
    )
  )

  if (indexOfHeight > -1) {
    sortedObservation.push(observations[indexOfHeight])
  }

  const indexOfWeight = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(
        e.activity,
        'http://wellopathy.com/fhir/india/core/CodeSystem/trip-business-status'
      ) === 'reached_location'
  )

  if (indexOfWeight > -1) {
    sortedObservation.push(observations[indexOfWeight])
  }

  if (pickUpProgress > -1) {
    sortedObservation.push(observations[pickUpProgress])
  }
  const pickUpAborted = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(e.activity) === 'pickup_aborted'
  )

  if (pickUpAborted > -1) {
    sortedObservation.push(observations[pickUpAborted])
  }

  const indexOfRR = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.activity)?.includes(
      'sample_collection_started'
    )
  )

  //   const indexOfRR = observations.findIndex(
  //     (e) =>
  //       getDefaultCodeOfSystemFromCodableConcept(e.code, 'https://loinc.org') ===
  //       '9279-1'
  //   )

  if (indexOfRR > -1) {
    sortedObservation.push(observations[indexOfRR])
  }

  const indexOfBP = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.activity)?.includes(
      'sample_collection_complete'
    )
  )

  if (indexOfBP > -1) {
    sortedObservation.push(observations[indexOfBP])
  }

  const indexOfBMI = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.activity)?.includes(
      'sample_handover_to_lab'
    )
  )

  if (indexOfBMI > -1) {
    sortedObservation.push(observations[indexOfBMI])
  }

  const sampleIndex = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.activity)?.includes(
      'sample_received'
    )
  )
  if (sampleIndex > -1) {
    sortedObservation.push(observations[sampleIndex])
  }

  const sampleCollected = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.activity)?.includes(
      'sample_colleccted'
    )
  )
  if (sampleCollected > -1) {
    sortedObservation.push(observations[sampleCollected])
  }

  const rejected = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.activity)?.includes('rejected')
  )

  if (rejected > -1) {
    sortedObservation.push(observations[rejected])
  }

  const indexOfOther = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.activity)?.includes(
      'processing_samples'
    )
  )

  if (indexOfOther > -1) {
    sortedObservation.push(observations[indexOfOther])
  }
  const indexOfOtherData = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.activity)?.includes(
      'processed_sample'
    )
  )

  if (indexOfOtherData > -1) {
    sortedObservation.push(observations[indexOfOtherData])
  }

  const reportReady = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.activity)?.includes(
      'report_ready'
    )
  )

  if (reportReady > -1) {
    sortedObservation.push(observations[reportReady])
  }

  if (indexOfOtherData > -1) {
    sortedObservation.push(observations[indexOfOtherData])
  }

  const reportCompleted = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.activity)?.includes(
      'reports_delivered'
    )
  )

  if (reportCompleted > -1) {
    sortedObservation.push(observations[reportCompleted])
  }

  return sortedObservation
}

export function getPreBuiltData(observations: R4.IObservation[]): string {
  let built: string = ''
  const indexOfBuilt = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(
        e.code,
        'http://snomed.info/sct'
      ) === '165184000'
  )

  if (indexOfBuilt > -1)
    built =
      getDefaultDisplayOfSystemFromCodableConcept(
        observations[indexOfBuilt].valueCodeableConcept
      ) ?? ''
  built = getBuiltVal(built)
  return built
}

export function getNourishData(observations: R4.IObservation[]): string {
  let built: string = ''
  const indexOfNourish = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(
        e.code,
        'http://snomed.info/sct'
      ) === '1759002'
  )

  if (indexOfNourish > -1)
    built =
      getDefaultDisplayOfSystemFromCodableConcept(
        observations[indexOfNourish].valueCodeableConcept
      ) ?? ''
  built = getNourishVal(built)
  return built
}

export function getPallor(observations: R4.IObservation[]): string {
  let pallor: string = ''
  const indexOfPallor = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(
        e.code,
        'http://snomed.info/sct'
      ) === '1237486008'
  )

  if (indexOfPallor > -1)
    pallor =
      getDefaultDisplayOfSystemFromCodableConcept(
        observations[indexOfPallor].valueCodeableConcept
      ) ?? ''
  pallor = getPallorData(pallor)
  return pallor
}

export function getPallorRemarks(observations: R4.IObservation[]): string {
  let note: string = ''
  const indexOfPallor = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(
        e.code,
        'http://snomed.info/sct'
      ) === '1237486008'
  )

  if (observations[indexOfPallor].note) {
    note = getNotesString(observations[indexOfPallor].note)
  }

  return note
}

export function getIct(observations: R4.IObservation[]): string {
  let pallor: string = ''
  const indexOfPallor = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(
        e.code,
        'http://snomed.info/sct'
      ) === '18165001'
  )

  if (indexOfPallor > -1)
    pallor =
      getDefaultDisplayOfSystemFromCodableConcept(
        observations[indexOfPallor].valueCodeableConcept
      ) ?? ''
  pallor = getPallorData(pallor)
  return pallor
}

export function getIctRemarks(observations: R4.IObservation[]): string {
  let pallor: string = ''
  const indexOfPallor = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(
        e.code,
        'http://snomed.info/sct'
      ) === '18165001'
  )

  if (observations[indexOfPallor].note)
    pallor = getNotesString(observations[indexOfPallor].note)
  return pallor
}

export function getCyn(observations: R4.IObservation[]): string {
  let pallor: string = ''
  const indexOfPallor = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(
        e.code,
        'http://snomed.info/sct'
      ) === '3415004'
  )

  if (indexOfPallor > -1)
    pallor =
      getDefaultDisplayOfSystemFromCodableConcept(
        observations[indexOfPallor].valueCodeableConcept
      ) ?? ''
  pallor = getPallorData(pallor)
  return pallor
}

export function getCynRemarks(observations: R4.IObservation[]): string {
  let pallor: string = ''
  const indexOfPallor = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(
        e.code,
        'http://snomed.info/sct'
      ) === '3415004'
  )
  if (observations[indexOfPallor]) {
    if (observations[indexOfPallor].note)
      pallor = getNotesString(observations[indexOfPallor].note)
  }

  return pallor
}

export function getClubbing(observations: R4.IObservation[]): string {
  let pallor: string = ''
  const indexOfPallor = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(
        e.code,
        'http://snomed.info/sct'
      ) === '4373005'
  )

  if (indexOfPallor > -1)
    pallor =
      getDefaultDisplayOfSystemFromCodableConcept(
        observations[indexOfPallor].valueCodeableConcept
      ) ?? ''
  pallor = getPallorData(pallor)
  return pallor
}

export function getClubbingRemarks(observations: R4.IObservation[]): string {
  let pallor: string = ''
  const indexOfPallor = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(
        e.code,
        'http://snomed.info/sct'
      ) === '4373005'
  )

  if (observations[indexOfPallor]) {
    if (observations[indexOfPallor].note)
      pallor = getNotesString(observations[indexOfPallor].note)
  }
  return pallor
}

export function getLymp(observations: R4.IObservation[]): string {
  let pallor: string = ''
  const indexOfPallor = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(
        e.code,
        'http://snomed.info/sct'
      ) === '30746006'
  )

  if (indexOfPallor > -1)
    pallor =
      getDefaultDisplayOfSystemFromCodableConcept(
        observations[indexOfPallor].valueCodeableConcept
      ) ?? ''
  pallor = getPallorData(pallor)
  return pallor
}

export function getLympRemarks(observations: R4.IObservation[]): string {
  let pallor: string = ''
  const indexOfPallor = observations.findIndex(
    (e) =>
      getDefaultCodeOfSystemFromCodableConcept(
        e.code,
        'http://snomed.info/sct'
      ) === '30746006'
  )
  if (observations[indexOfPallor]) {
    if (observations[indexOfPallor].note)
      pallor = getNotesString(observations[indexOfPallor].note)
  }
  return pallor
}
