import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@material-ui/core'
import _ from 'lodash'
import {
  getMedicationCategoryUrlFromCode,
  medicationMaps,
} from 'models/AyurvedaUrlCategory'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { WelloAyurvedaMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { RootState } from 'redux/rootReducer'
import { oralUnits, routesOfAdministration } from 'utils/constants'
import { getAddedDate, getTomorrowDate } from 'utils/dateUtil'
import { validateOthers } from 'utils/patientHelper/patientEditHelper'
import { addNewAyurvedaMedicationsDetails } from 'utils/sopaNotes/plan/ayurvedaMedicationHelpers'
import { ValueSetSelectSingleDisplaySinge } from 'views/components/common/SingleSelectFromDesination'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import { ValueSetSelectSingleDisplayCombined } from 'views/components/common/ValueSetSelectSingleDisplayCombined'
import { AyurvedaMedicationsTempList } from 'views/components/consultation/plan/ayurveda/ayurvedaMedicationsTempList'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloSelect } from 'views/components/LeftMenu/WelloSelect'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { TabbedMultiSelector } from 'views/components/toggle_multiSelector'
import CustomizedDividers from 'views/components/toggle_selector'
import { WelloTextField } from 'wello-web-components'
import anupanas from '../../../../assets/constants/common-anupanas.json'

// import { MedicationTile } from './medication_tile'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  onClose: () => void
  onMedicalConditionAdded: () => void
}
export const AddAyurvedaMedicationsHandlerIPD: React.FC<Props> = ({
  open,
  onClose,
  onMedicalConditionAdded,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common', 'medication', 'dosage'])
  const [addedMedications, setAddedMedications] = useState<
    WelloAyurvedaMedication[]
  >([])
  const dispatch = useDispatch()
  const [defaultSubcategoryUrl, setDefaultSubcategoryUrl] = useState<string>()
  const [defaultMedicineCategoryUrl, setDefaultMedicineCategoryUrl] =
    useState<string>(medicationMaps.all_url)

  const [selectedDatePart, setSelectedDatePart] = useState<string>('today')
  const [selectedWeekPart, setSelectedWeekPart] = useState<string>('3d')

  const [addAnother, setAddAnother] = React.useState<boolean>(true)
  const [formState, setFormState] = useState(_.random(0, 109090).toFixed(2))

  const [anchorEl, setAnchorEl] = React.useState(null)

  const [addMore, setAddMore] = useState<boolean>(false)
  const openMenu = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [other, setOther] = useState<boolean>(false)

  const [currentMedicationTile, setCurrentMedicationTile] =
    useState<WelloAyurvedaMedication>({
      otherDataText: '',
      administrationCode: '26643006',
      startFrom: new Date(),
      till: getAddedDate(0),
      mealCode: 'before',
      timeOfDayCode: ['morning'],
    })
  const addMedicationsSlice = useSelector(
    (state: RootState) => state.addMedicationsSlice
  )

  function resetForm() {
    setCurrentMedicationTile({
      otherDataText: '',
      administrationCode: '26643006',
      startFrom: new Date(),
      till: getAddedDate(0),
      mealCode: 'before',
      timeOfDayCode: ['morning'],
    })
    setDefaultSubcategoryUrl(undefined)
    setDefaultMedicineCategoryUrl(medicationMaps.all_url)
    setFormState(_.random(0, 109090).toFixed(2))
    setAddMore(false)
    setSelectedDatePart('today')
  }

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  function addCurrentDetails(pushToSave?: boolean) {
    const tempMedication: WelloAyurvedaMedication[] = [...addedMedications]
    const newMedication: WelloAyurvedaMedication = {
      ...currentMedicationTile,
      id: '',
      base: 'no-base',
      medicationDate: new Date(),
      // notes: additionalNotes,
    }

    tempMedication.push(newMedication)
    setAddedMedications(tempMedication)
    resetForm()

    if (pushToSave) {
      postMedicationDetails(tempMedication)
    }
  }

  function postMedicationDetails(tempMedication: WelloAyurvedaMedication[]) {
    setUpdateStatus({ requesting: true })

    addNewAyurvedaMedicationsDetails(
      fhirAppointmentDetails.mainEncounter?.subject!,
      {
        reference: `Encounter/${fhirAppointmentDetails.mainEncounter!.id}`,
      },
      tempMedication,
      'IPD',
      fhirAppointmentDetails
    ).then((e) => {
      if (e) {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Medication added successfully'))
        setAddedMedications([])
        onMedicalConditionAdded()
        onClose()
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(
          showErrorAlert(
            'Error while adding Medication. Please try again later'
          )
        )
      }
    })
  }

  function isOtherSelected(): boolean {
    if (other === true && !currentMedicationTile.otherDataText) {
      return true
    }
    return false
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setAddedMedications([])
        resetForm()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Oushadhi (Medication)</DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#ededed',
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0,
        }}
      >
        <Box
          marginY={1}
          display='flex'
          flexDirection='column'
          width='100%'
          px={1}
          key={formState}
        >
          <Box display='flex' flexDirection='row' width='100%' px={1}>
            <Box px={1} width='15%'>
              <ValueSetSelectSingle
                title='Category'
                url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurvedic-medicine-types-vs'
                id='med_category'
                fuzzySearch={false}
                alwaysShowAllOptions={true}
                preSelectedOptions={currentMedicationTile.medicationCode}
                disabled={false}
                onOptionSelected={(selectedKriya) => {
                  if (selectedKriya) {
                    const res = getMedicationCategoryUrlFromCode(
                      selectedKriya.code ?? ''
                    )
                    if (res) {
                      if (res.leaf_node) {
                        setDefaultSubcategoryUrl(undefined)
                        setDefaultMedicineCategoryUrl(res.category_url)
                      } else {
                        setDefaultSubcategoryUrl(res.category_url)
                      }
                    } else {
                      setDefaultSubcategoryUrl(undefined)
                      setDefaultMedicineCategoryUrl(medicationMaps.all_url)
                    }
                  }
                }}
                helperText={undefined}
              />
            </Box>

            <Box px={1} width='15%'>
              <ValueSetSelectSingle
                key={`${defaultSubcategoryUrl}sub_category`}
                title='Sub Category'
                url={`http://wellopathy.com/fhir/india/core/ValueSet/${defaultSubcategoryUrl}`}
                id='med_sub_category'
                fuzzySearch={true}
                alwaysShowAllOptions={true}
                disabled={_.isEmpty(defaultSubcategoryUrl)}
                onOptionSelected={(selectedCategory) => {
                  if (selectedCategory) {
                    const res = getMedicationCategoryUrlFromCode(
                      selectedCategory.code ?? ''
                    )
                    if (res) {
                      setDefaultMedicineCategoryUrl(res?.category_url)
                      return
                    }
                  }

                  setDefaultMedicineCategoryUrl(medicationMaps.all_url)
                }}
                helperText={undefined}
              />
            </Box>

            <Box px={1} width='35%' flexGrow={1} display='flex'>
              <ValueSetSelectSingle
                key={`${defaultMedicineCategoryUrl}medicines`}
                title='Select Medication'
                url={`http://wellopathy.com/fhir/india/core/ValueSet/${defaultMedicineCategoryUrl}`}
                id='kriyas'
                fuzzySearch={true}
                preSelectedOptions={currentMedicationTile.medicationCode}
                disabled={false}
                onOptionSelected={(selectedKriya) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    medicationCode: selectedKriya,
                  })
                }}
                helperText={undefined}
              />
            </Box>

            <Box px={1} width='15%'>
              <Box display='flex' flexDirection='column' width='100%'>
                <WelloFormItemLabel title={t('labelDosage:resourceName')} />
                <WelloTextFieldWithoutTitle
                  textProps={{
                    id: `med_dosage`,
                    placeholder: 'Dosage',
                    error:
                      currentMedicationTile.dosageQuantity! <= 0 ||
                      currentMedicationTile.dosageQuantity! >= 10000 ||
                      Number.isNaN(currentMedicationTile.dosageQuantity!),
                    type: 'number',
                    // value: tests.scheduleSummary,
                    helperText:
                      currentMedicationTile.dosageQuantity! <= 0 ||
                      Number.isNaN(currentMedicationTile.dosageQuantity!)
                        ? `${t('labelCommon:invlaid_dosages')}`
                        : '',
                    inputProps: {
                      min: 0,
                      max: 10000,
                      step: 0.1,
                    },

                    //   InputProps: {
                    //     endAdornment: (
                    //       <InputAdornment position='end'>
                    //         {getSuffixForDosage(currentMedicationTile.administrationCode)}
                    //       </InputAdornment>
                    //     ),
                    //   },
                    //   value: tests.testPrice,
                    onChange: (changeData) => {
                      if (changeData) {
                        setCurrentMedicationTile({
                          ...currentMedicationTile,
                          dosageQuantity: parseFloat(
                            parseFloat(changeData.target.value).toFixed(1)
                          ),
                        })
                        /* setSelectedDosage(
                              parseFloat(
                                parseFloat(changeData.target.value).toFixed(1)
                              )
                            ) */
                      }
                    },
                  }}
                />
              </Box>
            </Box>

            <Box width='15%' px={1}>
              <ValueSetSelectSingleDisplaySinge
                key='wellopathy-relationship-vs_medicines'
                title='Unit'
                url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-dosage-ucum-units-vs'
                id='med_units'
                fuzzySearch={true}
                disabled={false}
                onOptionSelected={(conditionsData: R4.ICoding | undefined) => {
                  if (conditionsData) {
                    setCurrentMedicationTile({
                      ...currentMedicationTile,
                      dosageQuantityType: conditionsData.code,
                    })
                  }
                }}
                helperText={undefined}
                placeHolder='Unit'
              />
              {/* <WelloSelect
                label='Unit'
                availableOptions={oralUnits}
                required={true}
                id='med_units'
                type='Choose Unit'
                displayField='display'
                valueField='code'
                onSelectionChanged={(value: any) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    dosageQuantityType: value.code,
                  })

                  // setDosageType(value.code)
                }}
              /> */}
            </Box>
          </Box>
          <Box display='flex' flexDirection='row' width='100%' px={1}>
            <Box display='flex' flexDirection='row' width='30%' px={1}>
              <ValueSetSelectSingleDisplaySinge
                key='wellopathy-relationship-vs_medicines_routes'
                title='Route Of Administration'
                url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-route-of-administration-vs'
                id='route_type'
                fuzzySearch={true}
                alwaysShowAllOptions={true}
                disabled={false}
                onOptionSelected={(conditionsData: R4.ICoding | undefined) => {
                  if (conditionsData) {
                    setCurrentMedicationTile({
                      ...currentMedicationTile,
                      administrationCode: conditionsData.code,
                    })
                  }
                }}
                helperText={undefined}
                preSelectedOptions={{
                  system: 'http://snomed.info/sct',
                  code: '26643006',
                  display: 'Oral (by mouth) ',
                }}
                placeHolder='Route Of Administration'
              />
            </Box>

            <Box px={1} width='20%'>
              <ValueSetSelectSingleDisplayCombined
                title='Oushadhakala'
                url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurveda-aushadha-kala-vs'
                id='med_oushadhakala'
                fuzzySearch={true}
                preSelectedOptions={currentMedicationTile.medicationCode}
                disabled={false}
                onOptionSelected={(selectedKriya) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    aushadhaKalaCode: selectedKriya,
                  })
                }}
                helperText={undefined}
              />
            </Box>

            <Box px={1} width='20%'>
              <WelloSelectFHIRCoding
                title='Anupana'
                onChanged={(selectedKriya) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    anupanaCode: selectedKriya,
                  })
                }}
                availableCodings={anupanas}
                id='med_anupana'
                textFieldProps={{
                  size: 'small',
                  fullWidth: true,
                }}
              />
              {/*   <ValueSetSelectSingle
                  title='Anupana'
                  url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurveda-common-anupanas-vs'
                  id='med_anupana'
                  fuzzySearch={true}
                  preSelectedOptions={currentMedicationTile.medicationCode}
                  disabled={false}
                  onOptionSelected={(selectedKriya) => {
                    setCurrentMedicationTile({
                      ...currentMedicationTile,
                      anupanaCode: selectedKriya,
                    })
                  }}
                  helperText={undefined}
                /> */}
            </Box>

            <Box px={1} width='15%'>
              <Box display='flex' flexDirection='column' width='100%'>
                <WelloFormItemLabel title={t('labelDosage:resourceName')} />
                <WelloTextFieldWithoutTitle
                  textProps={{
                    id: `anupana_dosage`,
                    placeholder: 'Dosage',
                    error:
                      currentMedicationTile.anupanaDosageQuantity! <= 0 ||
                      currentMedicationTile.anupanaDosageQuantity! >= 10000 ||
                      Number.isNaN(
                        currentMedicationTile.anupanaDosageQuantity!
                      ),
                    type: 'number',
                    // value: tests.scheduleSummary,
                    helperText:
                      currentMedicationTile.anupanaDosageQuantity! <= 0 ||
                      Number.isNaN(currentMedicationTile.anupanaDosageQuantity!)
                        ? `${t('labelCommon:invlaid_dosages')}`
                        : '',
                    inputProps: {
                      min: 0,
                      max: 10000,
                      step: 0.1,
                    },

                    //   InputProps: {
                    //     endAdornment: (
                    //       <InputAdornment position='end'>
                    //         {getSuffixForDosage(currentMedicationTile.administrationCode)}
                    //       </InputAdornment>
                    //     ),
                    //   },
                    //   value: tests.testPrice,
                    onChange: (changeData) => {
                      if (changeData) {
                        setCurrentMedicationTile({
                          ...currentMedicationTile,
                          anupanaDosageQuantity: parseFloat(
                            parseFloat(changeData.target.value).toFixed(1)
                          ),
                        })
                        /* setSelectedDosage(
                              parseFloat(
                                parseFloat(changeData.target.value).toFixed(1)
                              )
                            ) */
                      }
                    },
                  }}
                />
              </Box>
            </Box>

            <Box width='15%' px={1}>
              <WelloSelect
                label='Unit'
                availableOptions={oralUnits}
                required={true}
                id='anupana_units'
                type='Choose Unit'
                displayField='display'
                valueField='code'
                onSelectionChanged={(value: any) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    anupanaDosageQuantityType: value.code,
                  })

                  // setDosageType(value.code)
                }}
              />
            </Box>
          </Box>
          <Box display='flex' flexDirection='row' width='100%' px={1}>
            <Box display='flex' px={1} width='33%'>
              {addMore && (
                <TabbedMultiSelector
                  size='small'
                  preSelectedValues={['morning']}
                  menuLabel={t(
                    'labelDosage:attr_labels.Dosage.timing'
                  ).toLowerCase()}
                  options={[
                    {
                      displayText: `${t('labelCommon:morning')}`,
                      value: 'morning',
                    },
                    {
                      displayText: `${t('labelCommon:afternoon')}`,
                      value: 'afternoon',
                    },
                    {
                      displayText: `${t('labelCommon:Evening')}`,
                      value: 'evening',
                    },
                    {
                      displayText: `${t('labelCommon:night')}`,
                      value: 'night',
                    },
                  ]}
                  onSelectionChanged={(selectedValue) => {
                    setCurrentMedicationTile({
                      ...currentMedicationTile,
                      timeOfDayCode: selectedValue,
                    })
                    // setSelectedTimeOfDay(selectedValue)
                  }}
                />
              )}
              {addMore === false && (
                <TabbedMultiSelector
                  size='small'
                  preSelectedValues={['morning']}
                  menuLabel={t(
                    'labelDosage:attr_labels.Dosage.timing'
                  ).toLowerCase()}
                  options={[
                    {
                      displayText: `${t('labelCommon:morning')}`,
                      value: 'morning',
                    },
                    {
                      displayText: `${t('labelCommon:afternoon')}`,
                      value: 'afternoon',
                    },
                    {
                      displayText: `${t('labelCommon:Evening')}`,
                      value: 'evening',
                    },
                    {
                      displayText: `${t('labelCommon:night')}`,
                      value: 'night',
                    },
                  ]}
                  onSelectionChanged={(selectedValue) => {
                    setCurrentMedicationTile({
                      ...currentMedicationTile,
                      timeOfDayCode: selectedValue,
                    })
                    // setSelectedTimeOfDay(selectedValue)
                  }}
                />
              )}
            </Box>
            <Box px={1} width='33%'>
              {addMore === false && (
                <CustomizedDividers
                  key='_date_part'
                  size='small'
                  menuLabel='When'
                  options={[
                    {
                      displayText: `${t('labelCommon:today')}`,
                      value: 'today',
                    },
                    {
                      displayText: `${t('labelCommon:tomorrow')}`,
                      value: 'tomo',
                    },
                  ]}
                  onSelectionChanged={(selectedValue) => {
                    setSelectedDatePart(selectedValue)
                    if (selectedValue === 'today') {
                      setCurrentMedicationTile({
                        ...currentMedicationTile,
                        startFrom: new Date(),
                        till: getAddedDate(0),
                      })
                    }
                    if (selectedValue === 'tomo') {
                      setCurrentMedicationTile({
                        ...currentMedicationTile,
                        startFrom: getTomorrowDate(),
                        till: getAddedDate(1),
                      })
                    }
                  }}
                  preSelectedValue={selectedDatePart}
                />
              )}
              {addMore && (
                <CustomizedDividers
                  key='_date_part'
                  size='small'
                  menuLabel='When'
                  options={[
                    {
                      displayText: `${t('labelCommon:today')}`,
                      value: 'today',
                    },
                    {
                      displayText: `${t('labelCommon:tomorrow')}`,
                      value: 'tomo',
                    },
                  ]}
                  onSelectionChanged={(selectedValue) => {
                    setSelectedDatePart(selectedValue)
                    if (selectedValue === 'today') {
                      setCurrentMedicationTile({
                        ...currentMedicationTile,
                        startFrom: new Date(),
                        till: getAddedDate(0),
                      })
                    }
                    if (selectedValue === 'tomo') {
                      setCurrentMedicationTile({
                        ...currentMedicationTile,
                        startFrom: getTomorrowDate(),
                        till: getAddedDate(1),
                      })
                    }
                  }}
                  preSelectedValue={selectedDatePart}
                />
              )}
            </Box>
          </Box>

          <Box display='flex' flexDirection='column' marginLeft={1}>
            {addedMedications.length > 0 && (
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                px={1}
                py={2}
                pl={1}
              >
                <AyurvedaMedicationsTempList
                  medicationsList={addedMedications}
                />
              </Box>
            )}
          </Box>
          <Box display='flex' flexDirection='row' width='100%' px={1}>
            <Box
              display='flex'
              width='100%'
              flexDirection='column'
              flexGrow={1}
              paddingLeft={1}
              paddingRight={1.5}
            >
              <WelloFormItemLabel title='Additional Notes' />

              <TextField
                size='small'
                fullWidth
                id='txt_ayurveda_additional_notes'
                multiline
                type='string'
                value={currentMedicationTile.notes}
                variant='outlined'
                onChange={(event) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    notes: event.target.value,
                  })
                  // setAdditionalNotes(event.target.value)
                }}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Box
          display='flex'
          flexDirection='row'
          py={1}
          paddingRight={1.5}
          paddingLeft={1}
          flexGrow={1}
          alignItems='center'
        >
          {updateStatus.requesting && (
            <Box
              flexDirection='row'
              display='flex'
              alignSelf='center'
              justifyContent='center'
            >
              <CircularProgress size={25} />
            </Box>
          )}
          <Box flexGrow={1} alignSelf='center' paddingX={1}>
            <FormControlLabel
              value='end'
              control={
                <Checkbox
                  inputProps={{
                    id: 'add_another',
                  }}
                  disabled={updateStatus.requesting}
                  color='primary'
                  checked={addAnother}
                  onChange={(eve, checked) => {
                    setAddAnother(checked)
                  }}
                />
              }
              label={t('labelCommon:add_another_medication')}
              labelPlacement='end'
            />
          </Box>
          <Box display='flex' flexDirection='row'>
            <Box paddingX={1}>
              <Button
                onClick={() => {
                  resetForm()
                  setAddedMedications([])
                  onClose()
                }}
                disabled={updateStatus.requesting}
                variant='outlined'
                disableElevation
                id='cancel'
              >
                {t('labelCommon:cancel')}
              </Button>
            </Box>
            {addAnother && (
              <Box paddingX={1}>
                <Button
                  onClick={() => {
                    setAddMore(true)
                    setOther(false)
                    // setOtherData(undefined)
                    setSelectedWeekPart('3d')
                    // setSelectedMeal('before')
                    // setTillDate(getAddedDate(2))
                    // setAdditionalNotes(undefined)
                    addCurrentDetails()
                  }}
                  id='another'
                  variant='contained'
                  color='primary'
                  disableElevation
                  disabled={
                    ((currentMedicationTile.medicationCode === undefined ||
                      currentMedicationTile.dosageQuantity === undefined ||
                      currentMedicationTile.dosageQuantity! <= 0 ||
                      currentMedicationTile.dosageQuantityType === undefined ||
                      (currentMedicationTile.timeOfDayCode ?? []).length ===
                        0 ||
                      isOtherSelected() ||
                      addMedicationsSlice.adding ||
                      validateOthers(
                        currentMedicationTile.otherDataText!,
                        false
                      ).length > 0) &&
                      addedMedications.length === 0) ||
                    updateStatus.requesting
                  }
                >
                  {t('labelCommon:add_more_new')}
                </Button>
              </Box>
            )}

            <Box paddingX={1}>
              <Button
                onClick={() => {
                  if (
                    currentMedicationTile.medicationCode !== undefined &&
                    currentMedicationTile.dosageQuantity !== undefined &&
                    currentMedicationTile.dosageQuantityType !== undefined
                  ) {
                    addCurrentDetails(true)
                  } else if (addedMedications.length > 0) {
                    postMedicationDetails(addedMedications)
                  }
                }}
                variant='contained'
                color='primary'
                disableElevation
                id='save'
                disabled={
                  ((currentMedicationTile.medicationCode === undefined ||
                    currentMedicationTile.dosageQuantity === undefined ||
                    currentMedicationTile.dosageQuantity! <= 0 ||
                    currentMedicationTile.dosageQuantityType === undefined ||
                    (currentMedicationTile.timeOfDayCode ?? []).length === 0 ||
                    isOtherSelected() ||
                    addMedicationsSlice.adding ||
                    validateOthers(currentMedicationTile.otherDataText!, false)
                      .length > 0) &&
                    addedMedications.length === 0) ||
                  updateStatus.requesting
                }
              >
                {t('labelCommon:Save')}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
