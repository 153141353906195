import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FhirTherapyListWithChargeDef } from 'models/fhirTherapyList'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getTherapyListWithChargeItem,
  getTherapyListWithChargeItemForOPD,
} from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { logger } from 'utils/logger'
import { TherapyCatalogPriceSearchStatus } from './therapySearchPriceStatus'

const initialState: TherapyCatalogPriceSearchStatus = {
  searchingTreatment: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingTreatment: false,
}

const threapyCatalogSearchPriceSlice = createSlice({
  name: 'threapyCatalogSearchPriceSlice',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<TherapyCatalogPriceSearchStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingTreatment = action.payload.searchingTreatment
      state.resultsAvailable = action.payload.resultsAvailable
      state.therapyList = action.payload.therapyList
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingTreatment =
        action.payload.errorWhileSearchingTreatment
    },
  },
})

export const getAllTherapiesWithPrice =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: TherapyCatalogPriceSearchStatus = {
      searchingTreatment: true,
      errorWhileSearchingTreatment: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(threapyCatalogSearchPriceSlice.actions.updatedStatus(state))
    try {
      const searchParameter = {
        organization: `Organization/${getCurrentUserUnitDetails().id!}`,
        _count: 1000,
        active: true,
      }
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doGetResource(
        `/HealthcareService?service-category=300,301,302,303,387854002,44868003,229224000&_count=200&_include=HealthcareService:healthcare-service-billing`,
        searchParameter
      )

      if (response._tag === 'Left') {
        const errorSearchDoctor: TherapyCatalogPriceSearchStatus = {
          searchingTreatment: false,
          errorWhileSearchingTreatment: true,
          resultsAvailable: false,
          errorReason: 'Error while searching Treatment Plan',
        }
        dispatch(
          threapyCatalogSearchPriceSlice.actions.updatedStatus(
            errorSearchDoctor
          )
        )

        dispatch(threapyCatalogSearchPriceSlice.actions.updatedStatus(state))
      } else {
        const resp: R4.IBundle = response as R4.IBundle

        const therapyDataList: FhirTherapyListWithChargeDef[] =
          getTherapyListWithChargeItemForOPD(resp)

        therapyDataList.sort((a, b) =>
          a.name! > b.name! ? 1 : b.name! > a.name! ? -1 : 0
        )

        state.resultsAvailable = true
        state.searchingTreatment = false
        state.therapyList = therapyDataList
        state.noResultsAvailable = false
        state.errorReason = undefined
        state.errorWhileSearchingTreatment = false
        dispatch(threapyCatalogSearchPriceSlice.actions.updatedStatus(state))
      }
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorSearchDoctor: TherapyCatalogPriceSearchStatus = {
        searchingTreatment: false,
        errorWhileSearchingTreatment: true,
        resultsAvailable: false,
        errorReason: 'Error while searching Treatment Plan',
      }
      dispatch(
        threapyCatalogSearchPriceSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export default threapyCatalogSearchPriceSlice.reducer
