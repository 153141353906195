import { Grid, Typography, Box } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirReferralInsightDetails } from 'models/fhirPrescriptionDetails'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isAyurvedaDoctor } from 'services/userDetailsService'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import { AddedMedicationsList } from '../consultation/assessment/medicationsListTable'
import { DietHistoryForReferral } from './assessmentHistory/planHistory/dietChartHistory'
import { InstructionHistory } from './assessmentHistory/planHistory/instructionHistory'
import { KriyaHistory } from './assessmentHistory/planHistory/KriyaHistory'
import { PathyaApathya } from './assessmentHistory/planHistory/pathyaApathya'
import { ReferredImaging } from './assessmentHistory/planHistory/referralImaging'
import { ReferredLabTest } from './assessmentHistory/planHistory/referredLabTest'
import { Vihara } from './assessmentHistory/planHistory/vihara'

import { GeneralExaminationHistory } from './objectiveHistory.tsx/generalExamHistory'
import { LabResultsHistory } from './objectiveHistory.tsx/LabTestHistory'
import { AddedMedicationsListForHistory } from './objectiveHistory.tsx/medicinePlanForHistory'
import { ObesityObjectiveHistory } from './objectiveHistory.tsx/obesityObjectiveHistory'
import { VitalsHistory } from './objectiveHistory.tsx/vitalHistory'

interface ObjectivePartOfConsultationProps {
  referralDetails: FhirReferralInsightDetails
}

export const PlanHistory: React.FC<ObjectivePartOfConsultationProps> = ({
  referralDetails,
}: ObjectivePartOfConsultationProps) => {
  const { t } = useTranslation(['common'])
  const [addButton, showAddButton] = useState<boolean>(false)

  return (
    <Grid
      container
      spacing={1}
      direction='column'
      style={{ flexWrap: 'nowrap' }}
    >
      <Grid item xs={12}>
        {' '}
        <Box display='flex' width='100%'>
          <ReferredLabTest
            labTest={
              referralDetails ? referralDetails.referredLabTest ?? [] : []
            }
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        {' '}
        <Box display='flex' width='100%'>
          <ReferredImaging
            labTest={
              referralDetails ? referralDetails.imagingStudies ?? [] : []
            }
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        {' '}
        <Box display='flex' width='100%'>
          <KriyaHistory
            kriyas={referralDetails ? referralDetails.kriya ?? [] : []}
            readonly={true}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        {' '}
        <Box display='flex' width='100%'>
          <InstructionHistory
            instructions={
              referralDetails ? referralDetails.instructions ?? [] : []
            }
          />
        </Box>
      </Grid>
      {referralDetails.medication && referralDetails.medication.length > 0 && (
        <Grid item xs={12}>
          {' '}
          <Box display='flex' width='100%'>
            <AddedMedicationsListForHistory
              medication={
                referralDetails ? referralDetails.medication ?? [] : []
              }
              appointmentId=''
              key='1'
              displayTypeOfMedication={false}
              displayStartEndDates={true}
              orderAllowed={false}
            />
          </Box>
        </Grid>
      )}

      {referralDetails.dietChart && referralDetails.dietChart.length > 0 && (
        <Grid item xs={12}>
          {' '}
          <Box display='flex' width='100%'>
            <DietHistoryForReferral
              dietData={referralDetails ? referralDetails.dietChart ?? [] : []}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  )
}
