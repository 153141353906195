import {
  Avatar,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React from 'react'
import {
  getProfilePicPractitioner,
  getTelecomOfPractitioner,
} from 'utils/fhirResourcesHelper'

interface Props {
  practitoner?: PractitionerWithRole
}

export const AgentTile: React.FC<Props> = ({ practitoner }: Props) => (
  <Box width='100%'>
    <Card elevation={0}>
      <CardContent>
        {practitoner && (
          <List id='patientList_455' style={{ flexGrow: 1, padding: 0 }}>
            <ListItem style={{ flexGrow: 1, padding: 0 }}>
              <ListItemAvatar>
                <Avatar
                  alt={practitoner.name}
                  src={
                    getProfilePicPractitioner(practitoner.practitionerObject) ??
                    ''
                  }
                />
              </ListItemAvatar>
              <ListItemText
                color='primary'
                primary={
                  <>
                    <Typography
                      variant='subtitle1'
                      color='primary'
                      style={{ fontWeight: 'bolder' }}
                    >
                      {practitoner.name}
                    </Typography>
                  </>
                }
                secondary={
                  <Box display='flex' flexDirection='column'>
                    <Typography
                      component='span'
                      variant='subtitle2'
                      color='textPrimary'
                    >
                      {getTelecomOfPractitioner(practitoner.roleObject)}
                    </Typography>
                  </Box>
                }
              />
            </ListItem>
          </List>
        )}
      </CardContent>
    </Card>
  </Box>
)
