/* eslint-disable react/display-name */
import MaterialTable, { Column, MTableToolbar } from '@material-table/core'
import {
  Box,
  InputAdornment,
  makeStyles,
  Paper,
  TablePagination,
  TextField,
  Typography,
  useTheme,
  Button,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { kDialogueBackground, kPrimaryMain } from 'configs/styles/muiThemes'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import AddIcon from '@mui/icons-material/Add'
import { CatalogueData } from 'models/catalogueData'
import { FhirCatalougeDetails } from 'models/fhirCatalougeDetails'
import { ObsDataTableResource } from 'models/labObsData'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { searchObservations } from 'redux/lab/observationFinder/observationFinderSlice'
import { getTableDataFromFhir } from 'utils/common/catalogueDataConverter'
import { titleCase } from 'utils/fhirResourcesHelper'

import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { Check } from '@material-ui/icons'
import { getTableData } from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { EditCatalogueRowData } from './editCatalogueData'

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
})

interface ICardProperties {
  operation: string
  testData: ObsDataTableResource[]
  onBulkEdit: (dataSet: ObsDataTableResource[]) => void
  onBulkStarted: (isOpen?: boolean) => void
  onClickAdd: () => void
  showAdd?: boolean
}

export const ObservationDataTable: React.FC<ICardProperties> = ({
  operation,
  testData,
  onBulkEdit,
  onBulkStarted,
  onClickAdd,
  showAdd,
}: ICardProperties) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const convertedData = testData
  const { height, width } = useWindowDimensions()

  const [data, setData] = useState([...testData])

  const dispatch = useDispatch()
  const [catalogueRowData, setCatalogueRowData] =
    React.useState<CatalogueData>()

  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  const columns: Column<ObsDataTableResource>[] = [
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              color: 'black',
              fontSize: 12,
              opacity: 0.8,
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            {'Test Name '}
          </Typography>
        </Box>
      ),
      field: 'obsName',
      editComponent: (props: {
        value: string
        onChange: (arg0: string) => void
      }) => (
        <Box width='100%'>
          <TextField
            placeholder='Report Name'
            fullWidth
            style={{
              width: 300,
            }}
            size='small'
            variant='outlined'
            value={props.value}
            onChange={(e) => {
              props.onChange(e.target.value)
            }}
            InputProps={{
              inputProps: {
                maxLength: 50,
              },
            }}
            error={
              props.value.length > 50 ||
              props.value.length === 0 ||
              props.value === undefined
            }
          />
        </Box>
        // <input
        //   type='text'
        //   value={props.value}
        //   onChange={(e) => props.onChange(e.target.value)}
        // />
      ),

      // a.testName > b.testName ? 1 : b.testName > a.testName ? -1 : 0,
      filterPlaceholder: 'Test Name ',

      hiddenByColumnsButton: false,
      render: (rowData: any) => (
        <Box display='flex' alignItems='center'>
          {/* <Box paddingRight={1}>
            <Avatar alt='Profile' src={rowData.profilePic} />
          </Box> */}
          <Typography
            variant='subtitle2'
            style={{
              color: 'black',
              opacity: 0.8,
            }}
            noWrap
          >
            {titleCase(rowData.obsName)}
          </Typography>
        </Box>
      ),
      width: 300,
    },

    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              color: 'black',
              opacity: 0.8,
              fontSize: 12,
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            {'Type '}
          </Typography>
        </Box>
      ),
      field: 'permittedDataType',

      editable: 'never',
      filterPlaceholder: 'Type ',

      hiddenByColumnsButton: false,
      render: (rowData: any) => (
        <Box display='flex' alignItems='center'>
          {/* <Box paddingRight={1}>
              <Avatar alt='Profile' src={rowData.profilePic} />
            </Box> */}
          <Typography
            variant='subtitle2'
            style={{
              color: 'black',
              opacity: 0.8,
            }}
            noWrap
          >
            {titleCase(rowData.permittedDataType)}
          </Typography>
        </Box>
      ),
      lookup: {
        Quantity: 'Quantity',
        String: 'String',
      },
      width: 100,
    },

    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              color: 'black',
              opacity: 0.8,
              fontSize: 12,
              fontWeight: 'bold',
              lineHeight: 'normal',
              width: '40px',
            }}
            color='primary'
          >
            SI Unit
          </Typography>
        </Box>
      ),
      field: 'unitCode',
      editable: 'never',
      lookup: {
        'mg/dL': 'mg/dL',
        'ng/mL': 'ng/mL',
        '{ratio}': '{ratio}',
        'g/dL': 'g/dL',
        'x10E3/u': 'x10E3/u',
        'x10E6/uL': 'x10E6/uL',
        '%': '%',
        'Thou/ul': 'Thou/ul',
        fL: 'fL',
        pg: 'pg',
      },

      render: (rowData: any) => (
        <Box paddingY={1}>
          <Typography
            variant='subtitle2'
            style={{
              color: 'black',
              opacity: 0.8,
            }}
          >
            {rowData.unitCode}
          </Typography>
        </Box>
      ),
      width: 50,
    },

    {
      title: (
        <Box display='flex' justifyContent='flex-start'>
          <Typography
            variant='subtitle1'
            style={{
              color: 'black',
              opacity: 0.8,
              fontSize: 12,
              fontWeight: 'bold',
              lineHeight: 'normal',
              width: '130px',
            }}
            color='primary'
          >
            Decimal Precision
          </Typography>
        </Box>
      ),
      field: 'decimalPrecision',
      render: (rowData: any) => (
        <Box paddingY={1} display='flex' justifyContent='center' flexGrow>
          <Box display='flex' alignItems='center'>
            <Typography
              variant='subtitle2'
              style={{ color: 'black', opacity: 0.8 }}
            >
              {rowData.decimalPrecision}
            </Typography>
          </Box>
        </Box>
      ),
      width: 100,
      editComponent: (props: {
        value: string | number | readonly string[] | undefined
        onChange: (arg0: string) => void
      }) => (
        <Box width='100%' justifyContent='flex-start'>
          <TextField
            placeholder='Decimal'
            fullWidth
            style={{
              width: 100,
            }}
            InputProps={{
              inputProps: {
                max: 2,
                min: 0,
              },
            }}
            type='number'
            size='small'
            variant='outlined'
            value={props.value}
            onChange={(e) => {
              props.onChange(e.target.value)
            }}
          />
        </Box>
      ),

      type: 'numeric',
    },

    {
      title: (
        <Box display='flex' justifyContent='flex-start'>
          <Typography
            variant='subtitle1'
            style={{
              color: '#111111',
              fontSize: 12,
              fontWeight: 'bold',
              lineHeight: 'normal',
              width: '75px',
            }}
            color='primary'
          >
            Range1 Low
          </Typography>
        </Box>
      ),
      field: 'low1',
      type: 'numeric',

      render: (rowData: any) => (
        <Box
          paddingY={1}
          display='flex'
          justifyContent='center'
          //   paddingLeft={2.5}
        >
          {rowData.lowCode1 && rowData.lowCode1.length > 0 && (
            <Typography
              variant='subtitle2'
              color='initial'
              style={{
                color: '#111111',
              }}
            >
              {rowData.low1}
            </Typography>
          )}
        </Box>
      ),
      width: 300,

      editComponent: (props: {
        rowData: ObsDataTableResource
        value: number
        onChange: (arg0: string) => void
      }) => (
        <Box width='100%' justifyContent='flex-start'>
          <TextField
            placeholder='Low'
            fullWidth
            style={{
              width: 100,
              background: 'red',
            }}
            InputProps={{
              inputProps: {
                max: props.rowData.high1
                  ? props.rowData.high1
                  : props.rowData.unitCode === '%'
                  ? 100
                  : 5,
                min: 0,
              },
            }}
            onKeyDown={(e) => {
              if (
                e.key === 'e' ||
                e.key === 'E' ||
                e.key === '-' ||
                e.key === '+'
              ) {
                e.preventDefault()
              }
            }}
            error={
              (props.rowData.high1! > 0 &&
                (props.value! < 0 ||
                  Number.isNaN(props.value!) ||
                  props.value! > props.rowData.high1!)) ??
              5
            }
            type='number'
            size='small'
            variant='outlined'
            value={props.rowData.lowCode1!.length > 0 ? props.value : undefined}
            onChange={(e) => {
              props.onChange(e.target.value)
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box paddingY={1} display='flex' justifyContent='flex-start'>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 12,
              fontWeight: 'bold',
              lineHeight: 'normal',
              color: '#111111',
              width: '80px',
            }}
            color='primary'
          >
            {'Range1 High  '}
          </Typography>
        </Box>
      ),
      field: 'high1',
      type: 'numeric',
      render: (rowData: any) => (
        <Box
          paddingY={1}
          display='flex'
          justifyContent='center'
          flexGrow
          //   paddingLeft={2.5}
        >
          {rowData.highCode1 && rowData.highCode1.length > 0 && (
            <Box display='flex' alignItems='center'>
              <Typography
                variant='subtitle2'
                color='initial'
                style={{
                  color: '#111111',
                }}
              >
                {rowData.high1}
              </Typography>
            </Box>
          )}
        </Box>
      ),
      editComponent: (props: {
        rowData: ObsDataTableResource
        value: string | number | readonly string[] | undefined
        onChange: (arg0: string) => void
      }) => (
        <Box width='100%'>
          <TextField
            placeholder='High'
            fullWidth
            style={{
              width: 100,
            }}
            size='small'
            type='number'
            variant='outlined'
            value={
              props.rowData.highCode1!.length > 0 ? props.value : undefined
            }
            InputProps={{
              inputProps: {
                max: props.rowData.high1
                  ? props.rowData.high1
                  : props.rowData.unitCode === '%'
                  ? 100
                  : 200,
                min: props.rowData.low1 ? props.rowData.low1 : 0,
              },
            }}
            onKeyDown={(e) => {
              if (
                e.key === 'e' ||
                e.key === 'E' ||
                e.key === '-' ||
                e.key === '+'
              ) {
                e.preventDefault()
              }
            }}
            error={
              (props.rowData.high1! < 0 &&
                (props.value! < 0 ||
                  Number.isNaN(props.value!) ||
                  props.value! < props.rowData.low1!)) ??
              200
            }
            onChange={(e) => {
              props.onChange(e.target.value)
            }}
          />
        </Box>
      ),
      width: 300,
    },

    {
      title: (
        <Box display='flex' justifyContent='flex-start' flexGrow>
          <Typography
            variant='subtitle1'
            style={{
              color: '#111111',
              fontSize: 12,
              fontWeight: 'bold',
              lineHeight: 'normal',
              width: '100px',
            }}
            color='primary'
          >
            Range1 Gender
          </Typography>
        </Box>
      ),
      field: 'gender1',
      lookup: {
        All: 'All',
        male: 'Male',
        female: 'Female',
      },
      render: (rowData: any) => (
        <Box paddingY={1} display='flex' justifyContent='center' flexGrow>
          <Box display='flex' alignItems='center'>
            {rowData.gender1 && rowData.gender1.length > 0 && (
              <Typography
                variant='subtitle2'
                color='initial'
                style={{
                  color: '#111111',
                }}
              >
                {titleCase(rowData.gender1)}
              </Typography>
            )}
          </Box>
        </Box>
      ),
      width: 100,
    },

    {
      title: (
        <Box display='flex' justifyContent='flex-start' flexGrow>
          <Typography
            variant='subtitle1'
            style={{
              color: '#111111',
              fontSize: 12,
              fontWeight: 'bold',
              lineHeight: 'normal',
              width: '150px',
            }}
            color='primary'
          >
            Range1 Age Low (in Yrs)
          </Typography>
        </Box>
      ),
      field: 'ageLow1',
      render: (rowData: ObsDataTableResource) => (
        <Box paddingY={1} display='flex' justifyContent='center' flexGrow>
          <Box display='flex' alignItems='center'>
            <Typography
              variant='subtitle2'
              color='initial'
              style={{ color: '#111111' }}
            >
              {rowData.ageLow1 === 0 ? 'All' : rowData.ageLow1}
            </Typography>
          </Box>
        </Box>
      ),
      editComponent: (props: {
        rowData: ObsDataTableResource
        value: number
        onChange: (arg0: string) => void
      }) => (
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='center'
          flexGrow
        >
          <TextField
            placeholder='Age Low'
            fullWidth
            style={{
              width: 100,
            }}
            size='small'
            variant='outlined'
            value={props.rowData.ageLow1 === 0 ? 'All' : props.rowData.ageLow1}
            InputProps={{
              inputProps: {
                max: props.rowData.ageHigh1 ? props.rowData.ageHigh1 : 100,
                min: 0,
              },
            }}
            onKeyDown={(e) => {
              if (
                e.key === 'e' ||
                e.key === 'E' ||
                e.key === '-' ||
                e.key === '+'
              ) {
                e.preventDefault()
              }
            }}
            error={
              (props.rowData.ageHigh1! > 0 &&
                (props.value! < 0 ||
                  Number.isNaN(props.value!) ||
                  props.value! > props.rowData.ageHigh1!)) ??
              100
            }
            onChange={(e) => {
              const onlyNums = e.target.value.replace(/[^0-9]/g, '')
              if (onlyNums.length < 10) {
                props.onChange(onlyNums)
              } else if (onlyNums.length === 10) {
                const number = onlyNums.replace(
                  /(\d{3})(\d{3})(\d{4})/,
                  '($1) $2-$3'
                )
                props.onChange(number)
              }
            }}
          />
        </Box>
      ),
      type: 'numeric',
      width: 150,
    },

    {
      title: (
        <Box display='flex' justifyContent='flex-start' flexGrow>
          <Typography
            variant='subtitle1'
            style={{
              color: '#111111',
              fontSize: 12,
              fontWeight: 'bold',
              lineHeight: 'normal',
              width: '150px',
            }}
            color='primary'
          >
            Range1 Age High (in Yrs)
          </Typography>
        </Box>
      ),
      field: 'ageHigh1',
      render: (rowData: any) => (
        <Box paddingY={1} display='flex' justifyContent='center' flexGrow>
          <Box display='flex' alignItems='center'>
            <Typography
              variant='subtitle2'
              color='initial'
              style={{ color: '#111111' }}
            >
              {rowData.ageHigh1 === 0 ? 'All' : rowData.ageHigh1}
            </Typography>

            {/* {rowData.ageHigh1 === 0 && rowData.ageHighUnit1.length > 0 && (
              <Typography
                variant='subtitle2'
                color='initial'
                style={{ color: '#111111' }}
              >
                All
              </Typography>
            )} */}
          </Box>
        </Box>
      ),
      editComponent: (props: {
        rowData: ObsDataTableResource
        value: number
        onChange: (arg0: string) => void
      }) => (
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='center'
          flexGrow
        >
          <TextField
            placeholder='Age High'
            fullWidth
            style={{
              width: 100,
            }}
            InputProps={{
              inputProps: {
                max: props.rowData.ageHigh1 ? props.rowData.ageHigh1 : 100,
                min: props.rowData.ageLow1 ? props.rowData.ageLow1 : 0,
              },
            }}
            onKeyDown={(e) => {
              if (
                e.key === 'e' ||
                e.key === 'E' ||
                e.key === '-' ||
                e.key === '+'
              ) {
                e.preventDefault()
              }
            }}
            error={
              (props.rowData.ageHigh1! < 0 &&
                (props.value! < 0 ||
                  Number.isNaN(props.value!) ||
                  props.value! < props.rowData.ageLow1!)) ??
              200
            }
            size='small'
            variant='outlined'
            value={
              props.rowData.ageHigh1 === 0 ? 'All' : props.rowData.ageHigh1
            }
            onChange={(e) => {
              const onlyNums = e.target.value.replace(/[^0-9]/g, '')
              if (onlyNums.length < 10) {
                props.onChange(onlyNums)
              } else if (onlyNums.length === 10) {
                const number = onlyNums.replace(
                  /(\d{3})(\d{3})(\d{4})/,
                  '($1) $2-$3'
                )
                props.onChange(number)
              }
            }}
          />
        </Box>
      ),
      type: 'numeric',
      width: 150,
    },

    {
      title: (
        <Box display='flex' justifyContent='flex-start'>
          <Typography
            variant='subtitle1'
            style={{
              color: 'black',
              opacity: 0.8,
              fontSize: 12,
              fontWeight: 'bold',
              lineHeight: 'normal',
              width: '75px',
            }}
            color='primary'
          >
            Range2 Low
          </Typography>
        </Box>
      ),
      field: 'low2',
      render: (rowData: any) => (
        <Box paddingY={1} display='flex' justifyContent='center' flexGrow>
          <Box display='flex' alignItems='center'>
            <Typography
              variant='subtitle2'
              color='initial'
              style={{
                color: 'black',
                opacity: 0.8,
              }}
            >
              {rowData.low2 && rowData.low2 === 0 ? 'All' : rowData.low2}
            </Typography>
          </Box>
        </Box>
      ),
      type: 'numeric',
      editComponent: (props: {
        rowData: ObsDataTableResource
        value: number
        onChange: (arg0: string) => void
      }) => (
        <Box width='100%'>
          <TextField
            placeholder='Low'
            fullWidth
            style={{
              width: 100,
            }}
            InputProps={{
              inputProps: {
                max: props.rowData.high2
                  ? props.rowData.high2
                  : props.rowData.unitCode === '%'
                  ? 100
                  : 5,
                min: 0,
              },
            }}
            onKeyDown={(e) => {
              if (
                e.key === 'e' ||
                e.key === 'E' ||
                e.key === '-' ||
                e.key === '+'
              ) {
                e.preventDefault()
              }
            }}
            error={
              (props.rowData.high2! > 0 &&
                (props.value! < 0 ||
                  Number.isNaN(props.value!) ||
                  props.value! > props.rowData.high2!)) ??
              5
            }
            type='number'
            size='small'
            variant='outlined'
            value={props.value === 0 ? 'All' : props.value}
            onChange={(e) => {
              props.onChange(e.target.value)
            }}
          />
        </Box>
      ),
      width: 300,
    },

    {
      title: (
        <Box display='flex' justifyContent='center' flexGrow>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 12,
              fontWeight: 'bold',
              lineHeight: 'normal',
              color: 'black',
              opacity: 0.8,

              width: '80px',
            }}
            color='primary'
          >
            {'Range2 High '}
          </Typography>
        </Box>
      ),
      editComponent: (props: {
        rowData: ObsDataTableResource
        value: number
        onChange: (arg0: string) => void
      }) => (
        <Box width='100%'>
          <TextField
            placeholder='High'
            fullWidth
            style={{
              width: 100,
            }}
            size='small'
            type='number'
            variant='outlined'
            value={props.value}
            InputProps={{
              inputProps: {
                max: props.rowData.high2
                  ? props.rowData.high2
                  : props.rowData.unitCode === '%'
                  ? 100
                  : 200,
                min: props.rowData.low2 ? props.rowData.low2 : 0,
              },
            }}
            onKeyDown={(e) => {
              if (
                e.key === 'e' ||
                e.key === 'E' ||
                e.key === '-' ||
                e.key === '+'
              ) {
                e.preventDefault()
              }
            }}
            error={
              (props.rowData.high2! < 0 &&
                (props.value! < 0 ||
                  Number.isNaN(props.value!) ||
                  props.value! < props.rowData.low2!)) ??
              200
            }
            onChange={(e) => {
              props.onChange(e.target.value)
            }}
          />
        </Box>
      ),
      field: 'high2',
      render: (rowData: any) => (
        <Box paddingY={1} display='flex' justifyContent='center' flexGrow>
          <Box display='flex' alignItems='center'>
            <Typography
              variant='subtitle2'
              color='initial'
              style={{
                color: 'black',
                opacity: 0.8,
              }}
            >
              {rowData.high2 && rowData.high2 === 0 ? 'All' : rowData.high2}
            </Typography>
          </Box>
        </Box>
      ),
      type: 'numeric',
      width: 300,
    },

    {
      title: (
        <Box display='flex' justifyContent='flex-start' flexGrow>
          <Typography
            variant='subtitle1'
            style={{
              color: 'black',
              opacity: 0.8,
              fontSize: 12,
              fontWeight: 'bold',
              lineHeight: 'normal',
              width: '100px',
            }}
            color='primary'
          >
            Range2 Gender
          </Typography>
        </Box>
      ),
      field: 'gender2',
      render: (rowData: any) => (
        <Box paddingY={1} display='flex' justifyContent='center' flexGrow>
          <Box display='flex' alignItems='center'>
            {rowData.gender2 && rowData.gender2.length > 0 && (
              <Typography
                variant='subtitle2'
                color='initial'
                style={{
                  color: 'black',
                  opacity: 0.8,
                }}
              >
                {titleCase(rowData.gender2)}
              </Typography>
            )}
          </Box>
        </Box>
      ),
      lookup: {
        All: 'All',
        male: 'Male',
        female: 'Female',
      },
      width: 100,
    },

    {
      title: (
        <Box display='flex' justifyContent='center' flexGrow>
          <Typography
            variant='subtitle1'
            style={{
              color: 'black',
              opacity: 0.8,
              fontSize: 12,
              fontWeight: 'bold',
              lineHeight: 'normal',
              width: '150px',
            }}
            color='primary'
          >
            Range2 Age Low (in Yrs)
          </Typography>
        </Box>
      ),
      field: 'ageLow2',
      render: (rowData: any) => (
        <Box paddingY={1} display='flex' justifyContent='center' flexGrow>
          <Box display='flex' alignItems='center'>
            <Typography
              variant='subtitle2'
              color='initial'
              style={{
                color: 'black',
                opacity: 0.8,
              }}
            >
              {rowData.ageLow2
                ? rowData.ageLow2 === 0
                  ? 'All'
                  : rowData.ageLow2
                : 'All'}
            </Typography>

            {rowData.ageLowUnit2 && rowData.ageLowUnit2.length === 0 && (
              <Typography
                variant='subtitle2'
                color='initial'
                style={{
                  color: 'black',
                  opacity: 0.8,
                }}
              >
                All
              </Typography>
            )}
          </Box>
        </Box>
      ),
      editComponent: (props: {
        rowData: ObsDataTableResource
        value: number
        onChange: (arg0: string) => void
      }) => (
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='center'
          flexGrow
        >
          <TextField
            placeholder='Age Low'
            fullWidth
            style={{
              width: 100,
            }}
            size='small'
            // type='number'
            variant='outlined'
            value={
              props.rowData.ageLow2
                ? props.rowData.ageLow2 === 0
                  ? 'All'
                  : props.rowData.ageLow2
                : 'All'
            }
            InputProps={{
              inputProps: {
                max: props.rowData.ageHigh2 ? props.rowData.ageHigh2 : 100,
                min: 0,
              },
            }}
            onKeyDown={(e) => {
              if (
                e.key === 'e' ||
                e.key === 'E' ||
                e.key === '-' ||
                e.key === '+'
              ) {
                e.preventDefault()
              }
            }}
            error={
              (props.rowData.ageHigh2! > 0 &&
                (props.value! < 0 ||
                  Number.isNaN(props.value!) ||
                  props.value! > props.rowData.ageHigh2!)) ??
              100
            }
            onChange={(e) => {
              const onlyNums = e.target.value.replace(/[^0-9]/g, '')
              if (onlyNums.length < 10) {
                props.onChange(onlyNums)
              } else if (onlyNums.length === 10) {
                const number = onlyNums.replace(
                  /(\d{3})(\d{3})(\d{4})/,
                  '($1) $2-$3'
                )
                props.onChange(number)
              }
              props.rowData.ageLowUnit1 = 'a'
            }}
          />
        </Box>
      ),
      type: 'numeric',
      width: 150,
    },

    {
      title: (
        <Box display='flex' justifyContent='center' flexGrow>
          <Typography
            variant='subtitle1'
            style={{
              color: 'black',
              opacity: 0.8,
              fontSize: 12,
              fontWeight: 'bold',
              lineHeight: 'normal',
              width: '150px',
            }}
            color='primary'
          >
            Range2 Age High (in Yrs)
          </Typography>
        </Box>
      ),
      editComponent: (props: {
        rowData: ObsDataTableResource
        value: number
        onChange: (arg0: string) => void
      }) => (
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='center'
          flexGrow
        >
          <TextField
            placeholder='Age High'
            fullWidth
            style={{
              width: 100,
            }}
            InputProps={{
              inputProps: {
                max: props.rowData.ageHigh2 ? props.rowData.ageHigh2 : 100,
                min: props.rowData.ageLow2 ? props.rowData.ageLow2 : 0,
              },
            }}
            onKeyDown={(e) => {
              if (
                e.key === 'e' ||
                e.key === 'E' ||
                e.key === '-' ||
                e.key === '+'
              ) {
                e.preventDefault()
              }
            }}
            error={
              (props.rowData.ageHigh2! < 0 &&
                (props.value! < 0 ||
                  Number.isNaN(props.value!) ||
                  props.value! < props.rowData.ageLow2!)) ??
              200
            }
            size='small'
            variant='outlined'
            value={
              props.rowData.ageHigh2
                ? props.rowData.ageHigh2 === 0
                  ? 'All'
                  : props.rowData.ageHigh2
                : 'All'
            }
            onChange={(e) => {
              const onlyNums = e.target.value.replace(/[^0-9]/g, '')
              if (onlyNums.length < 10) {
                props.onChange(onlyNums)
              } else if (onlyNums.length === 10) {
                const number = onlyNums.replace(
                  /(\d{3})(\d{3})(\d{4})/,
                  '($1) $2-$3'
                )
                props.onChange(number)
              }
              props.rowData.ageLowUnit1 = 'a'
            }}
          />
        </Box>
      ),
      field: 'ageHigh2',
      type: 'numeric',
      render: (rowData: any) => (
        <Box paddingY={1} display='flex' justifyContent='center' flexGrow>
          <Box display='flex' alignItems='center'>
            {}

            <Typography
              variant='subtitle2'
              color='initial'
              style={{
                color: 'black',
                opacity: 0.8,
              }}
            >
              {rowData.ageHigh2
                ? rowData.ageHigh2 === 0
                  ? 'All'
                  : rowData.ageHigh2
                : 'All'}
            </Typography>
          </Box>
        </Box>
      ),
      width: 100,
    },

    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              color: 'black',
              fontSize: 12,
              opacity: 0.8,
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Interpretation
          </Typography>
        </Box>
      ),
      field: 'interpretation',
      editComponent: (props: {
        value: string
        onChange: (arg0: string) => void
      }) => (
        <Box width='100%'>
          <TextField
            placeholder='Interpretation'
            fullWidth
            multiline={true}
            maxRows={4}
            minRows={1}
            style={{
              width: 300,
            }}
            size='small'
            variant='outlined'
            value={props.value}
            onChange={(e) => {
              props.onChange(e.target.value)
            }}
            InputProps={{
              inputProps: {
                maxLength: 200,
              },
            }}
            error={props.value.length > 200}
          />
        </Box>
        // <input
        //   type='text'
        //   value={props.value}
        //   onChange={(e) => props.onChange(e.target.value)}
        // />
      ),

      // a.testName > b.testName ? 1 : b.testName > a.testName ? -1 : 0,
      filterPlaceholder: 'Interpretation',

      hiddenByColumnsButton: false,
      render: (rowData: any) => (
        <Box display='flex' alignItems='center'>
          {/* <Box paddingRight={1}>
              <Avatar alt='Profile' src={rowData.profilePic} />
            </Box> */}
          <Typography
            variant='subtitle2'
            style={{
              color: 'black',
              opacity: 0.8,
            }}
            noWrap
          >
            {rowData.interpretation}
          </Typography>
        </Box>
      ),
      width: 300,
    },

    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              color: 'black',
              fontSize: 12,
              opacity: 0.8,
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Notes
          </Typography>
        </Box>
      ),
      field: 'notes',
      editComponent: (props: {
        value: string
        onChange: (arg0: string) => void
      }) => (
        <Box width='100%'>
          <TextField
            placeholder='Notes'
            fullWidth
            style={{
              width: 300,
            }}
            multiline={true}
            maxRows={4}
            minRows={1}
            size='small'
            variant='outlined'
            value={props.value}
            onChange={(e) => {
              props.onChange(e.target.value)
            }}
            InputProps={{
              inputProps: {
                maxLength: 200,
              },
            }}
            error={props.value.length > 200}
          />
        </Box>
        // <input
        //   type='text'
        //   value={props.value}
        //   onChange={(e) => props.onChange(e.target.value)}
        // />
      ),

      // a.testName > b.testName ? 1 : b.testName > a.testName ? -1 : 0,
      filterPlaceholder: 'Notes',

      hiddenByColumnsButton: false,
      render: (rowData: any) => (
        <Box display='flex' alignItems='center'>
          {/* <Box paddingRight={1}>
                <Avatar alt='Profile' src={rowData.profilePic} />
              </Box> */}
          <Typography
            variant='subtitle2'
            style={{
              color: 'black',
              opacity: 0.8,
            }}
            noWrap
          >
            {rowData.notes}
          </Typography>
        </Box>
      ),
      width: 300,
    },

    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              color: 'black',
              fontSize: 12,
              opacity: 0.8,
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Comments
          </Typography>
        </Box>
      ),
      field: 'comment',
      editComponent: (props: {
        value: string
        onChange: (arg0: string) => void
      }) => (
        <Box width='100%'>
          <TextField
            placeholder='Comments'
            fullWidth
            style={{
              width: 300,
            }}
            size='small'
            multiline={true}
            maxRows={4}
            minRows={1}
            variant='outlined'
            value={props.value}
            onChange={(e) => {
              props.onChange(e.target.value)
            }}
            InputProps={{
              inputProps: {
                maxLength: 200,
              },
            }}
            error={props.value.length > 200}
          />
        </Box>
        // <input
        //   type='text'
        //   value={props.value}
        //   onChange={(e) => props.onChange(e.target.value)}
        // />
      ),

      // a.testName > b.testName ? 1 : b.testName > a.testName ? -1 : 0,
      filterPlaceholder: 'Comments',

      hiddenByColumnsButton: false,
      render: (rowData: any) => (
        <Box display='flex' alignItems='center'>
          {/* <Box paddingRight={1}>
                  <Avatar alt='Profile' src={rowData.profilePic} />
                </Box> */}
          <Typography
            variant='subtitle2'
            style={{
              color: 'black',
              opacity: 0.8,
            }}
            noWrap
          >
            {rowData.comment}
          </Typography>
        </Box>
      ),
      width: 300,
    },
  ]

  return (
    <Box>
      <MaterialTable
        icons={{
          // Add: () => <EditIcon style={{ color: "blue" }} />,
          Edit: React.forwardRef((props, ref) => (
            <EditIcon {...props} ref={ref} style={{ color: kPrimaryMain }} />
          )),
          Check: React.forwardRef((props, ref) => (
            <CheckIcon {...props} ref={ref} style={{ color: kPrimaryMain }} />
          )),
        }}
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                // height: '50px',
                padding: 0,
                margin: 0,
                height: '18px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {showAdd && (
                <Tooltip title='Add New Test'>
                  <IconButton
                    aria-label='btn_view_billing'
                    color='inherit'
                    style={{ padding: 0 }}
                    onClick={() => {
                      onClickAdd()
                    }}
                  >
                    <AddIcon style={{ color: kPrimaryMain }} />
                  </IconButton>
                </Tooltip>
              )}

              <MTableToolbar {...props} />
            </div>
          ),
          Container: (props) => <Paper {...props} elevation={0} />,
          Pagination: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                display: 'flex',
                justifyContent: 'left',
                padding: '0px',
              }}
            >
              <TablePagination
                {...props}
                labelRowsSelect={<div style={{ fontSize: 14 }}> rows</div>}
                // labelRowsSelect={row => <div style={{ fontSize: 14 }}>{row.</div>}
                SelectProps={{
                  style: {
                    fontSize: 14,
                    backgroundColor: useTheme().palette.background.default,
                  },
                }}
              />
            </div>
          ),
        }}
        // actions={[
        //   {
        //     icon: () => (
        //       <Button variant='contained' size='small' color='primary'>
        //         Admit
        //       </Button>
        //     ),
        //     tooltip: 'Admit',
        //     onClick: (event, rowData) => {
        //       //   onAppointmentSelected(rowData as PreAppointmentDetails)
        //     },
        //     position: 'row',
        //   },
        // ]}
        columns={columns}
        data={data}
        editable={{
          //   onBulkUpdate: (changes) =>
          //     new Promise((resolve, reject) => {
          //       const dataUpdate = [...data]
          //       setTimeout(() => {
          //         setData(getUpdatedData(data, changes))
          //         resolve()
          //       }, 1000)
          //     }),
          onBulkUpdate: (selectedRows) =>
            new Promise((resolve, reject) => {
              const rows = Object.values(selectedRows)
              const updatedRows = [...data]
              let index: number = 0

              rows.map((emp) => {
                index = emp.oldData.id
                updatedRows[index] = emp.newData
              })
              setData(updatedRows)
              setTimeout(() => {
                onBulkEdit(updatedRows)
                if (operation === 'add') {
                  dispatch(showSuccessAlert('Records marked for insertion'))
                } else {
                  dispatch(showSuccessAlert('Records marked for edit'))
                }

                resolve(undefined)
              }, 200)
            }),

          //   onRowAdd: (newData) =>
          //     new Promise((resolve, reject) => {
          //       setTimeout(() => {
          //         setData([...data, newData])

          //         resolve(undefined)
          //       }, 1000)
          //     }),
          onRowDelete: (selectedRow) =>
            new Promise((resolve, reject) => {
              const index = selectedRow.obsCode

              const plannedArray: ObsDataTableResource[] = []
              for (let i = 0; i < data.length; i++) {
                if (data[i].obsCode !== selectedRow.obsCode) {
                  plannedArray.push(data[i])
                }
              }
              const finalArray: ObsDataTableResource[] =
                getTableData(plannedArray)

              //   const planArray = data.filter((e) => e.obsCode !== index)

              setTimeout(() => {
                onBulkEdit(finalArray)
                setData(finalArray)

                dispatch(showSuccessAlert('Record deleted successfully'))
                resolve(undefined)
              }, 10)
            }),

          //   onRowDelete: (oldData) =>
          //     Promise.resolve().then(async () => {
          //       const da = await deleteObservationDef(oldData)
          //       dispatch(searchObservations())
          //     }),
        }}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        onRowClick={(event, rowData) => {
          setShowPopup(true)
        }}
        onBulkEditOpen={(isOpen: boolean) => {
          if (isOpen === true) {
            onBulkStarted(isOpen)
          } else {
            onBulkStarted(false)
          }
        }}
        options={{
          showTitle: false,
          toolbar: true,
          search: false,
          padding: 'dense',
          searchFieldVariant: 'outlined',
          filtering: false,
          paging: false,
          pageSize: data.length,

          columnsButton: false,
          overflowY: data.length > 12 ? 'scroll' : 'hidden',
          maxBodyHeight: `${height - 250}px`,

          thirdSortClick: false,
          sorting: false,
          headerStyle: {
            backgroundColor: '#ececec',
            color: '#FFF',
            width: 100,
            position: 'sticky',
            top: 0,
            height: 40,
          },
          filterRowStyle: {
            position: 'sticky',
            zIndex: 5 /* optionally */,
            top: 40,
            backgroundColor: '#ececec',
            color: '#333333',
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          //   actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          rowStyle: (_data: any, index: number, _level: number) =>
            index % 2 ? { backgroundColor: kDialogueBackground } : {},

          //   toolbar: false,
        }}
        localization={{
          //   pagination: {
          //     labelDisplayedRows: '{from}-{to} of {count}',
          //   },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />

      {/* {showPopup && (
        <Box
          display='flex'
          flexGrow={4}
          overflow='auto'
          minWidth={`${width - 460}px`}
          height='100%'
          style={{ backgroundColor: '#ececec' }}
        >
          <EditCatalogueRowData
            rowDetails={catalogueRowData as CatalogueData}
            open={showPopup}
            onCancelClick={() => {
              setShowPopup(false)
            }}
          />
        </Box>
      )} */}
    </Box>
  )
}
