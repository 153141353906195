import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { SplitPaymentDetails } from 'models/splitPayment/splitPaymentDetails'
import moment from 'moment'
import React from 'react'
import { PaymentTypeCodes } from 'utils/constants/payment_types'
import { getSymbolForCurrency } from 'utils/fhirResoureHelpers/currency_helper'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloFormItemLabel } from './WelloFormItemLabel'

interface Props {
  id: string
  open: boolean
  onAdd: (refundDetails: SplitPaymentDetails[]) => void
  onClose: () => void
  refundAmount: number
}
export const RefundDialog: React.FC<Props> = ({
  id,
  open,
  onAdd,
  onClose,
  refundAmount,
}) => {
  const [refund, setRefund] = React.useState<SplitPaymentDetails[]>([
    {
      id: 0,
      Date: moment().toDate(),
      amount: refundAmount,
      isDelete: false,
    },
  ])

  function handlePaymentReferenceForRefund(e: string, index: number) {
    const values: SplitPaymentDetails[] = [...refund]
    values[index].paymentReference = e
    setRefund(values)
  }

  function handlePaymentTypeChangeForRefund(e: R4.ICoding, index: number) {
    const values: SplitPaymentDetails[] = [...refund]
    values[index].paymentType = e
    setRefund(values)
  }

  function resetForm() {
    setRefund([
      {
        id: 0,
        Date: moment().toDate(),
        amount: 0,
        isDelete: false,
      },
    ])
  }

  function isRefundValid() {
    if (refundAmount > 0) {
      for (let i = 0; i < refund.length; i++) {
        if (refund[i].paymentReference && refund[i].paymentType) {
          return true
        }
      }
    } else {
      return false
    }

    return false
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetForm()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Refund Information</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                resetForm()
                onClose()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        <Box display='flex' flexDirection='column'>
          {refund.map((payments: SplitPaymentDetails, index: number) => (
            <Box
              display='flex'
              flexDirection='column'
              width='100%'
              py={0.5}
              key={payments.id}
            >
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                // justifyContent='space-between'
                py={1}
              >
                <Box display='flex' flexDirection='row'>
                  <Box minWidth='30px' px={0.5}>
                    <WelloFormItemLabel title='Date' />
                  </Box>
                  <TextField
                    size='small'
                    variant='outlined'
                    type='date'
                    defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                    disabled={payments.Date !== undefined}
                    onChange={(e) => {
                      e.preventDefault()
                    }}
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='row'
                  width={payments.isDelete ? 200 : 200}
                >
                  <Box minWidth='30px' px={0.5}>
                    <WelloFormItemLabel title='Type' />
                  </Box>

                  <WelloSelectFHIRCoding
                    availableCodings={PaymentTypeCodes}
                    id='billing_type'
                    onChanged={(e) => {
                      handlePaymentTypeChangeForRefund(e, index)
                    }}
                    textFieldProps={{
                      disabled: false,
                    }}
                    preSelectedCoding={payments.paymentType}
                  />
                </Box>

                <Box
                  display='flex'
                  flexDirection='row'
                  width={payments.isDelete ? 200 : 200}
                >
                  <Box minWidth='70px' px={0.5} paddingRight={2}>
                    <WelloFormItemLabel title='Amount' />
                  </Box>
                  <TextField
                    size='small'
                    variant='outlined'
                    // onFocus={(e) => {
                    //   setDiscountInFocus(true)
                    // }}
                    // onBlur={(e) => {
                    //   setDiscountInFocus(false)
                    // }}

                    InputProps={{
                      type: 'tel',
                      disabled: true,
                      inputProps: {
                        style: { textAlign: 'right' },
                      },
                      startAdornment: getSymbolForCurrency(''),
                    }}
                    value={Number(refundAmount.toFixed(2))}
                    onChange={(e) => {}}
                    disabled={true}
                  />
                </Box>

                <Box
                  display='flex'
                  flexDirection='row'
                  width={payments.isDelete ? 300 : 300}
                >
                  <Box minWidth='70px' paddingLeft={0.5} paddingRight={1}>
                    <WelloFormItemLabel title='Reference' />
                  </Box>
                  <TextField
                    size='small'
                    variant='outlined'
                    type='string'
                    value={payments.paymentReference ?? ''}
                    defaultValue={payments.paymentReference ?? ''}
                    inputProps={{ maxLength: 30 }}
                    onChange={(e) => {
                      if (e.target.value.length <= 30) {
                        const regex = /^[0-9a-zA-Z(\-)]+$/
                        if (
                          e.target.value.match(regex) ||
                          e.target.value === ''
                        ) {
                          handlePaymentReferenceForRefund(
                            e.target.value.trim(),
                            index
                          )
                        }
                      }
                    }}
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onAdd(refund)
          }}
          disabled={isRefundValid() === false}
          variant='outlined'
          disableElevation
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
