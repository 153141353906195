import {
  Box,
  LinearProgress,
  List,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core'
import { ToggleButtonGroup } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { requestForIncomingReferralsListsForLab } from 'redux/administration/referralSetup/fetchReferralList/incomingReferralsSliceForLabOrders'
import { RootState } from 'redux/rootReducer'
import { useWindowSize } from 'rooks'
import { TabbedMultiSelector } from 'views/components/toggle_multiSelector'
import { ToggleOption } from 'views/components/toggle_selector'
import '../../../../../App.css'
import { ReferralTile } from './referralTile'
import { ReferralHeaderTile } from './refrrralHeaderTile'

const therapyOptions = [
  {
    id: 'active',
    display: 'Scheduled',
  },
  {
    id: 'completed',
    display: 'Completed',
  },
  {
    id: 'revoked',
    display: 'Cancelled',
  },
]
export interface PageProps {}
const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  grouped: {
    padding: theme.spacing(0.5),

    border: 'none',
    '&.Mui-selected': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
    },
  },
}))(ToggleButtonGroup)

export const ReferralOrdersHomePage: React.FC<PageProps> = ({}: PageProps) => {
  const selectedDateQ = useSelector(
    (state: RootState) => state.therapiesListSlice.selectedDate
  )
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] =
    useState<Date | undefined>(selectedDateQ)

  const appointmentSearchSlice = useSelector(
    (state: RootState) => state.incomingReferralsSliceForLab
  )
  const [selectedIpdStatuses, setSelectedIPDStatuses] = useState<string[]>(
    appointmentSearchSlice.selectedStatuses
  )
  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  const { innerWidth } = useWindowSize()

  const statusMenu: ToggleOption[] = therapyOptions.map((e) => ({
    value: e.id,
    displayText: e.display,
  }))

  useEffect(() => {
    dispatch(
      requestForIncomingReferralsListsForLab(
        selectedIpdStatuses,
        undefined,
        undefined,
        undefined
      )
    )
  }, [dispatch, selectedDate, selectedIpdStatuses])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        borderRadius: 8,
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            px={1}
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            <Box
              flexDirection='row'
              width='100%'
              display='flex'
              py={1}
              justifyContent='center'
              alignContent='center'
              alignItems='center'
            >
              <Typography variant='h6' color='initial'>
                Referrals
              </Typography>
            </Box>
            <Box
              flexDirection={innerWidth! < 800 ? 'column' : 'row'}
              width='100%'
              display='flex'
              py={0.5}
              justifyContent={
                innerWidth! < 800 ? 'flex-start' : 'space-between'
              }
            >
              <Box
                display='flex'
                flexDirection='row'
                alignContent='center'
                alignItems='center'
                py={innerWidth! < 800 ? 0.5 : 0}
              >
                <Box>
                  <Box width='200px'>
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      style={{
                        paddingRight: '8px',
                        paddingLeft: 1.2,
                      }}
                    >
                      Status :
                    </Typography>
                  </Box>
                  <TabbedMultiSelector
                    onSelectionChanged={(value) => {
                      if (value !== null) {
                        setSelectedIPDStatuses(value)
                      }
                    }}
                    options={statusMenu}
                    preSelectedValues={[therapyOptions[0].id]}
                  />
                </Box>
              </Box>
            </Box>
            {appointmentSearchSlice.dateWiseAppointments && (
              <Box>
                <ReferralHeaderTile />
              </Box>
            )}
            <Box flexDirection='row' width='100%' display='flex'>
              {appointmentSearchSlice.searchingAppointments && (
                <LinearProgress
                  style={{
                    height: '4px',
                    width: '100%',
                  }}
                />
              )}
            </Box>
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='Start'
              alignContent='center'
              overflow='auto'
              style={{
                backgroundColor: '#00000005',
              }}
            >
              {appointmentSearchSlice.noResultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    align='center'
                  >
                    No Referral available
                  </Typography>
                </Box>
              )}

              {appointmentSearchSlice.errorWhileSearchingOrders && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography variant='subtitle1' color='error' align='center'>
                    Error while searching referrals
                  </Typography>
                </Box>
              )}

              {appointmentSearchSlice.dateWiseAppointments && (
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height='100%'
                  overflow='auto'
                >
                  <Box
                    display='flex'
                    overflow='auto'
                    height='100%'
                    flexGrow={2}
                  >
                    <List
                      id='scrollableDiv'
                      subheader={<li style={{ padding: 0, width: '100%' }} />}
                      style={{
                        padding: 0,
                        width: '100%',
                        overflow: 'auto',
                        height: '100%',
                      }}
                    >
                      <InfiniteScroll
                        dataLength={appointmentSearchSlice.recordsCount ?? 0}
                        scrollThreshold='300px'
                        next={() => {
                          dispatch(
                            requestForIncomingReferralsListsForLab(
                              selectedIpdStatuses,
                              appointmentSearchSlice.pageState,
                              appointmentSearchSlice.dateWiseAppointments
                                ?.length,
                              appointmentSearchSlice.dateWiseAppointments
                            )
                          )
                        }}
                        hasMore={
                          (appointmentSearchSlice.dateWiseAppointments
                            ?.length ?? 0) <
                          (appointmentSearchSlice.recordsCount ?? -1)
                        }
                        loader=''
                        endMessage={
                          appointmentSearchSlice.searchingAppointments ? (
                            <p />
                          ) : (
                            <p style={{ textAlign: 'center' }} />
                          )
                        }
                        scrollableTarget='scrollableDiv'
                      >
                        {appointmentSearchSlice.dateWiseAppointments!.map(
                          (e, index: number) => (
                            <ReferralTile
                              ipdAppointmentDetails={e}
                              index={index}
                              onManageClicked={() => {}}
                              key={e.id}
                              onDataUpdated={() => {
                                dispatch(
                                  requestForIncomingReferralsListsForLab(
                                    selectedIpdStatuses,
                                    undefined,
                                    undefined,
                                    undefined
                                  )
                                )
                              }}
                            />
                          )
                        )}
                      </InfiniteScroll>
                    </List>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </div>
  )
}
