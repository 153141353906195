import { Grid, Typography, Box } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirReferralInsightDetails } from 'models/fhirPrescriptionDetails'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isAyurvedaDoctor } from 'services/userDetailsService'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import { AssessmentConditionForHistory } from './assessmentHistory/assessmentCondition'
import { PatientSummaryHistory } from './assessmentHistory/patientSummaryHistory'
import { RootCauseAssessment } from './assessmentHistory/rootCauseAssessment'
import { SkinAssessmentHistory } from './assessmentHistory/skinCauseAssessment'

import { GeneralExaminationHistory } from './objectiveHistory.tsx/generalExamHistory'
import { LabResultsHistory } from './objectiveHistory.tsx/LabTestHistory'
import { ObesityObjectiveHistory } from './objectiveHistory.tsx/obesityObjectiveHistory'
import { VitalsHistory } from './objectiveHistory.tsx/vitalHistory'

interface ObjectivePartOfConsultationProps {
  referralDetails?: FhirReferralInsightDetails
}

export const ReferralAssessmentHistory: React.FC<ObjectivePartOfConsultationProps> =
  ({ referralDetails }: ObjectivePartOfConsultationProps) => {
    const { t } = useTranslation(['common'])
    const [addButton, showAddButton] = useState<boolean>(false)

    return (
      <Grid
        container
        spacing={1}
        direction='column'
        style={{ flexWrap: 'nowrap' }}
      >
        <Grid item xs={12}>
          {' '}
          <Box display='flex' width='100%'>
            <RootCauseAssessment
              rootCauseList={
                referralDetails ? referralDetails.rootCause ?? [] : []
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          {' '}
          <Box display='flex' width='100%'>
            <SkinAssessmentHistory
              skinObsList={
                referralDetails ? referralDetails.skinIssues ?? [] : []
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          {' '}
          <Box display='flex' width='90%'>
            <PatientSummaryHistory
              conditionsData={
                referralDetails ? referralDetails.patientSummary ?? [] : []
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          {' '}
          <Box display='flex' width='90%'>
            <AssessmentConditionForHistory
              conditionsData={
                referralDetails ? referralDetails.assessmentCondition ?? [] : []
              }
            />
          </Box>
        </Grid>
      </Grid>
    )
  }
