import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  errorList: string[]
}

const useStyles = makeStyles((theme) => ({
  test: {
    border: '1px',
    backgroundColor: 'white',
  },
}))
export const UploadErrors: React.FC<Props> = ({ errorList }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div>
      <Box
        flexDirection='column'
        display='flex'
        justifyContent='flex-start'
        alignItems='flex-start'
        className={classes.test}
        p={1}
      >
        {errorList && errorList.length > 0 && (
          <Box paddingY={1}>
            <Typography variant='body2' color='textPrimary'>
              {t(
                '  The following rows had issues and may not have been imported properly.'
              )}
            </Typography>
          </Box>
        )}
        {errorList &&
          errorList.map((item: any) => (
            <>
              <Box display='flex' flexDirection='row'>
                <Box p={0.1}>
                  <img
                    src={`${process.env.PUBLIC_URL}/Alerterror.png}`}
                    alt='test'
                    height={12.5}
                    width={12}
                  />
                </Box>

                <Typography
                  variant='subtitle2'
                  color='secondary'
                  style={{ paddingLeft: 3 }}
                >
                  {item}
                </Typography>
              </Box>
            </>
          ))}
        {errorList && errorList.length > 0 && (
          <Box paddingY={1}>
            <Typography variant='body2' color='textPrimary'>
              {t(' You can re-upload your file after making corrections.')}
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  )
}
