/* eslint-disable no-empty */
import axios, { AxiosInstance } from 'axios'

export class ApiClientWithoutAuth {
  axiosInstance: AxiosInstance

  constructor(baseUrl: string) {
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      timeout: 90000,
    })

    this.axiosInstance.interceptors.request.use((config) => config)

    this.axiosInstance.interceptors.response.use((param) => ({
      ...param,
    }))
  }
}
