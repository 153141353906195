import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirLabDiagnosticRequest } from 'models/fhirLabDiagnosticRequest'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import {
  getLabDiagnosticRequestFromBundle,
  getTestsFromDiagnostics,
  getTestsFromTasks,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import { logger } from 'utils/logger'
import { LabDiagnosticsListSliceStatus } from './labDiagnosticsListStatus'

const initialState: LabDiagnosticsListSliceStatus = {
  fetchingImpressions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  updatedImpressions: false,
  updatingImpressions: false,
  errorWhileSearchingImpressions: false,
  errorWhileUpdatingImpressions: false,
}

const labDiagnosticsListSliceIPD = createSlice({
  name: 'labDiagnosticsListSliceIPD',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<LabDiagnosticsListSliceStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingImpressions = action.payload.fetchingImpressions
      state.resultsAvailable = action.payload.resultsAvailable
      state.lanDiagnosticRequests = action.payload.lanDiagnosticRequests
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingImpressions =
        action.payload.errorWhileUpdatingImpressions
      state.tasks = action.payload.tasks
      state.updatedImpressions = action.payload.updatedImpressions
      state.updatingImpressions = action.payload.updatingImpressions
      state.diagnosticTestsLoincCodes = action.payload.diagnosticTestsLoincCodes
      state.errorWhileSearchingImpressions =
        action.payload.errorWhileSearchingImpressions
      state.serviceRequest = action.payload.serviceRequest
    },
  },
})

export const getLabRequestsOfAppointmentForIPD =
  (fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: LabDiagnosticsListSliceStatus = { ...initialState }
    state.fetchingImpressions = true
    dispatch(labDiagnosticsListSliceIPD.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        encounter: fhirAppointmentDetails.mainEncounter?.id!,
        '_include:iterate': 'ServiceRequest:instantiates-canonical',
        category: '108252007',
        intent: 'proposal',
        _count: 100,
      }

      //   const taskSearchParameters: any = {
      //     'focus:ActivityDefinition.title': 'Task Lab Order',
      //     encounter: `Encounter/${fhirAppointmentDetails.encounter?.id}` ?? '',
      //     'status:': 'requested',
      //   }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/ServiceRequest',
        '',
        searchParameters
      )

      //   const taskResponse: any = await fhirClient.doGetResource(
      //     '/Task',
      //     taskSearchParameters
      //   )
      //   logger.info(
      //     '-----------------------------SE-----REQ----------------------------------------------',
      //     response
      //   )
      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)

      //   const taskResp: E.Either<Errors, R4.IBundle> =
      //     R4.RTTI_Bundle.decode(taskResponse)

      const conditionResponse: R4.IBundle = response
      const task: R4.ITask[] = []
      // if (taskResp._tag === 'Right') {
      //   task = (taskResp.right.entry ?? []).map((e) => e.resource as R4.ITask)
      // }
      if (
        (conditionResponse?.total && conditionResponse?.total > 0) ||
        (task && task.length > 0)
      ) {
        let clinicalImpressions: FhirLabDiagnosticRequest[] = []
        const serviceRequestData: R4.IServiceRequest[] = []

        if (
          conditionResponse &&
          conditionResponse.entry &&
          conditionResponse.entry.length > 0
        ) {
          for (let i = 0; i < conditionResponse.entry.length; i++) {
            serviceRequestData.push(
              conditionResponse.entry[i].resource as R4.IServiceRequest
            )
          }
          clinicalImpressions =
            getLabDiagnosticRequestFromBundle(conditionResponse)
        }
        console.log(serviceRequestData)
        if (
          (clinicalImpressions && clinicalImpressions.length > 0) ||
          (task && task.length > 0) ||
          (serviceRequestData && serviceRequestData.length > 0)
        ) {
          state.resultsAvailable = true
          state.fetchingImpressions = false
          state.lanDiagnosticRequests = clinicalImpressions
          state.tasks = task
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingImpressions = false
          state.serviceRequest = serviceRequestData.filter(
            (service: R4.IServiceRequest) =>
              service.patientInstruction &&
              service.patientInstruction.length > 0
          )
          state.diagnosticTestsLoincCodes = [
            ...getTestsFromDiagnostics(clinicalImpressions),
            ...getTestsFromTasks(task),
          ]
          dispatch(labDiagnosticsListSliceIPD.actions.updatedStatus(state))
        }
      } else {
        const errorSearchDoctor: LabDiagnosticsListSliceStatus = {
          ...initialState,
        }
        errorSearchDoctor.noResultsAvailable = true
        dispatch(
          labDiagnosticsListSliceIPD.actions.updatedStatus(errorSearchDoctor)
        )
      }
    } catch (error) {
      const errorSearchDoctor: LabDiagnosticsListSliceStatus = {
        ...initialState,
      }
      errorSearchDoctor.errorReason = 'Error while fetching assessment details'
      errorSearchDoctor.errorWhileSearchingImpressions = false
      dispatch(
        labDiagnosticsListSliceIPD.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }
export default labDiagnosticsListSliceIPD.reducer
