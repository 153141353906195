import { Box, TextField, TextFieldProps } from '@material-ui/core'
import React from 'react'

interface WelloTextFieldProps {
  textProps: TextFieldProps
  displayDoneLabel?: boolean
}

export const WelloTextFieldWithoutTitle: React.FunctionComponent<WelloTextFieldProps> =
  (props: WelloTextFieldProps) => (
    <Box display='flex' flexDirection='column' flexGrow={1}>
      <Box>
        <TextField
          variant='outlined'
          fullWidth
          size='small'
          {...props.textProps}
        />
      </Box>
    </Box>
  )
