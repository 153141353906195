import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import React from 'react'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getCodeOfSystemFromCodableConceptList,
  getCommaSeparatedCondition,
  getFamilyCondition,
} from 'utils/fhirResourcesHelper'
import {
  getNotesFromAllergy,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { getRelationFromFamilyResource } from 'utils/fhirResoureHelpers/observationHelpers'

interface OccupationTileProps {
  occupations: R4.IFamilyMemberHistory[]
}

export const FamilyHistoryTabularTileForRef: React.FC<OccupationTileProps> = ({
  occupations,
}: OccupationTileProps) => {
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Grid container direction='column' spacing={1}>
        <Grid
          container
          direction='row'
          id='vitals'
          style={{
            backgroundColor: kDialogueBackground,
          }}
        >
          <Grid item xs={3}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Relationship
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' width='2%' paddingTop={0.2}>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '30px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Condition
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' width='2%' paddingTop={0.2}>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '30px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Additional notes
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container direction='row' id='vitals'>
          <Grid item xs={12}>
            <Box width='100%' paddingLeft={0.2}>
              <Divider
                orientation='horizontal'
                flexItem
                style={{
                  height: '2px',
                }}
              />
            </Box>
          </Grid>
        </Grid>

        {/* ------------------------------------------------------------------------------- */}
        {occupations.map((e, index: number) => (
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            flexGrow={1}
            key={e.id}
          >
            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor:
                  index % 2 === 0 ? '#EDF2FA' : kDialogueBackground,
              }}
            >
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {getRelationFromFamilyResource(e)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' width='2%' paddingTop={0.2}>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {e.condition ? getFamilyCondition(e.condition) : ''}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' width='2%' paddingTop={0.2}>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                    >
                      {hasNotes(e.note) && (
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow
                          overflow='auto'
                        >
                          {getNotesFromAllergy(e.note)?.map((eData) => (
                            <Box
                              key={eData}
                              width='100%'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                            >
                              {hasSpaces(eData) && (
                                <Typography
                                  variant='subtitle2'
                                  color='initial'
                                  style={{
                                    wordWrap: 'break-word',
                                    whiteSpace: 'pre-line',
                                    textOverflow: 'ellipsis',
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,

                                    fontWeight: 600,
                                    lineHeight: 'normal',
                                  }}
                                >
                                  {eData.split('\n').map((i: any, key: any) => (
                                    <div key={i}>{i}</div>
                                  ))}
                                </Typography>
                              )}
                              {hasSpaces(eData) === false && (
                                <Typography
                                  variant='subtitle2'
                                  color='initial'
                                  style={{
                                    wordWrap: 'break-word',
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,

                                    fontWeight: 600,
                                    lineHeight: 'normal',
                                  }}
                                >
                                  <div
                                    style={{
                                      overflowWrap: 'break-word',
                                    }}
                                  >
                                    {eData}
                                  </div>
                                </Typography>
                              )}
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Grid>
    </Box>
  )
}
