import { R4 } from '@ahryman40k/ts-fhir-types'

export const MotorFunctionObservationCodes: R4.ICoding[] = [
  {
    system: 'http://snomed.info/sct',
    code: '285436008',
    display: 'Able to perform oral motor functions',
  },
  {
    system: 'http://snomed.info/sct',
    code: '285439001',
    display: 'Does not perform oral motor functions',
  },
  {
    system: 'http://snomed.info/sct',
    code: '285437004',
    display: 'Unable to perform oral motor functions',
  },
  {
    system: 'http://snomed.info/sct',
    code: '285440004',
    display: 'Difficulty performing oral motor functions',
  },
  {
    system: 'http://snomed.info/sct',
    code: '228159000',
    display: 'Fine motor disability',
  },
  {
    system: 'http://snomed.info/sct',
    code: '228147004',
    display: 'Gross motor impairment',
  },
  {
    system: 'http://snomed.info/sct',
    code: '404937000',
    display: 'Narrow base of support',
  },
  {
    system: 'http://snomed.info/sct',
    code: '404936009',
    display: 'Wide base of support',
  },
  {
    system: 'http://snomed.info/sct',
    code: '298331001',
    display: 'Normal motor function',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-motor-finding-cs',
    code: 'other-motor-finding',
    display: 'Other',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
