import { AppBar, Box, IconButton, Tooltip, useTheme } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import { CurrentLoggedInUser } from 'models/currentUserDetails'
import { UnitServices } from 'models/units/unitServices'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestForIncomingLabReferralsCount } from 'redux/administration/referralSetup/fetchReferralCount/labReferralsCountSlice'
import { searchUnits } from 'redux/administration/units/unitManagement/unitsListsOfOrganization/unitsOfOrganizationSlice'
import { requestAppointmentsCountForToday } from 'redux/appointments/appointmentCount/appointmentCountSlice'
import { setRoleAndUnit } from 'redux/authChecker/authSlice'
import { requestForIncomingReferralsCount } from 'redux/clinic/ipdmanagement/incomingReferralsCount/incomingReferralsCountSlice'
import { requestIPDAppCountForToday } from 'redux/clinic/ipdmanagement/ipdCount/ipdCountSearchSlice'
import { resetSearchForIPD } from 'redux/clinic/ipdmanagement/ipdSearch/ipdSearchSlice'
import { requestOPDAppCountForTodayConsult } from 'redux/clinic/opdManagement/opdConsultManagement/opdConsultCount/opdConsultCountSlice'
import { requestOPDAppCountForToday } from 'redux/clinic/opdManagement/opdCountSlice'
import { resetSearchForOPDConsultation } from 'redux/clinic/opdManagement/opdSearch/opdSearchSlice'
import { resetLabOffering } from 'redux/lab/offerings/labOfferingSearchHandler/labOfferingSearchSlice'
import { requestOrdersCountForTodayAgent } from 'redux/lab/order/orderCount/orderCountForAgent'
import { requestOrdersCountForToday } from 'redux/lab/order/orderCount/orderCountSlice'
import { updateMedicineOrderCount } from 'redux/MedicineOrderHistory/medicineOrderCount/medicineOrderCountSlice'
import { updatePatientCount } from 'redux/patient/patientCount/patientCountSlice'
import { RootState } from 'redux/rootReducer'
import { updatingScheduleDetails } from 'redux/schedule/UpdateScheuleHandler/updateSchedule'
import { searchForMainOrgCount } from 'redux/welloAdmin/mainOrg/mainOrgCount/mainOrgCountSearchSlice'
import { searchLabCount } from 'redux/welloAdmin/unit/labCount/labCountSearchSlice'
import { searchUnitCountForClinic } from 'redux/welloAdmin/unit/unitCountForHeader/unitCountSearchWelloAdmin'
import {
  getCurrentUserUnitDetails,
  isAgent,
  isCurrentUnitIsClinic,
  isCurrentUnitIsCollectionCenter,
  isCurrentUnitIsLab,
  isCurrentUnitIsWellonessCenter,
  isDoctor,
  isOrgAdmin,
  isTherapist,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getAppBars } from 'utils/appBarHelpers'
import { getLogOutUrl } from 'utils/authHelpers'
import { getBulkScheduleUpdateBundle } from 'utils/fhirResoureHelpers/fhirScheduleDeleteHelper'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from '../common/deleteConfirmationDialog'
import { AccountDropDown } from '../LeftMenu/AccountDropDown'
import { CancelSchedule } from '../schedule/CancelSchedule'
import { AppBarTabs, TabDetail } from './AppBarTabs'

interface WelloClinicAppBarProps {
  onTabChange?: (selectedTab: string) => void
  selected?: string
  showHomeButton?: boolean
  showSettingsButton?: boolean
  schedules?: any
  existingSchedules?: any
  userData?: CurrentLoggedInUser
}
export const WelloClinicAppBar: React.FC<WelloClinicAppBarProps> = ({
  selected,
  onTabChange,
  showHomeButton,
  showSettingsButton,
  schedules,
  existingSchedules,
  userData,
}: WelloClinicAppBarProps) => {
  const dispatch = useDispatch()

  const patientsSearchState = useSelector(
    (state: RootState) => state.patientCountSlice
  )

  const mainOrgCountSlice = useSelector(
    (state: RootState) => state.mainOrgCountSlice
  )
  const labCountSearchSlice = useSelector(
    (state: RootState) => state.labCountSearchSlice
  )

  const welloUnitsCountSearchSlice = useSelector(
    (state: RootState) => state.welloUnitsCountSearchSlice
  )

  const authState = useSelector((state: RootState) => state.authCheck)
  const ipdCountSlice = useSelector((state: RootState) => state.ipdCount)
  const incomingReferralsCountSlice = useSelector(
    (state: RootState) => state.incomingReferralsCountSlice
  )
  const labReferralCount = useSelector(
    (state: RootState) => state.labReferralsCountSlice
  )
  const opdCountSlice = useSelector((state: RootState) => state.opdCount)
  const appointmentSearchSlice = useSelector(
    (state: RootState) => state.preAppointmentSearchSlice
  )
  const opdCountConsultSlice = useSelector(
    (state: RootState) => state.opdCountConsultSlice
  )
  const appointmentCountSlice = useSelector(
    (state: RootState) => state.appointmentCountSlice
  )

  const orderCountSlice = useSelector((state: RootState) => state.orderCount)
  const orderCountForAgentSlice = useSelector(
    (state: RootState) => state.orderCountForAgent
  )

  const unitsOfOrganizationSlice = useSelector(
    (state: RootState) => state.unitsOfOrganizationSlice.unitServices
  )

  const medicineOrderCountSlice = useSelector(
    (state: RootState) => state.medicineOrderCountSlice
  )

  const { t, i18n } = useTranslation(['common', 'patient'])
  const language = sessionStorage.getItem('lang')

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
      id: '1',
    })

  useEffect(() => {
    i18n.changeLanguage(language ?? '')

    updateCounts()
  }, [])

  function updateCounts() {
    dispatch(updatePatientCount())
    if (isCurrentUnitIsClinic()) {
      dispatch(searchUnits(getCurrentUserUnitDetails().id ?? ''))
    }

    if (isCurrentUnitIsWellonessCenter()) {
      dispatch(requestOPDAppCountForToday())
      dispatch(requestIPDAppCountForToday())
      dispatch(requestOPDAppCountForTodayConsult())
      dispatch(requestForIncomingReferralsCount())
    }
    if (isCurrentUnitIsClinic()) {
      dispatch(requestAppointmentsCountForToday())
      dispatch(requestIPDAppCountForToday())
    }
    if (isCurrentUnitIsLab() || isCurrentUnitIsCollectionCenter()) {
      if (!isAgent()) {
        dispatch(updateMedicineOrderCount())
        dispatch(requestForIncomingLabReferralsCount())
        dispatch(requestOrdersCountForToday())
      }
      if (isAgent()) {
        dispatch(requestOrdersCountForTodayAgent())
      }
      if (isOrgAdmin()) {
        dispatch(searchForMainOrgCount())
        dispatch(searchUnitCountForClinic())
        dispatch(searchLabCount())
      }
    }
  }
  let ids: string[] = []
  let tabMenu: TabDetail[] = []
  if (getAppBars().length > 0) {
    ids = getAppBars().map(({ name }) => name)
    tabMenu = getAppBars().filter(
      ({ name }, index) => !ids.includes(name, index + 1)
    )
  }

  if (
    isCurrentUnitIsClinic() &&
    (isDoctor() || (isCurrentUnitIsClinic() && isUnitAdmin()))
  ) {
    tabMenu.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    )
  }

  const [selectMenu, setSelectedMenu] = React.useState(false)
  if (schedules && schedules.length > 0) setSelectedMenu(true)

  for (let i = 0; i < tabMenu.length; i++) {
    if (tabMenu[i].name === 'Patients' || tabMenu[i].name === 'मरीजों')
      tabMenu[i].count = patientsSearchState.count
    if (tabMenu[i].name === 'Day Care') tabMenu[i].count = opdCountSlice.count
    if (tabMenu[i].name === 'OPD') tabMenu[i].count = opdCountConsultSlice.count
    if (
      tabMenu[i].name === 'Appointments' ||
      tabMenu[i].name === 'नियुक्तियाँ' ||
      tabMenu[i].name.toLowerCase().trim() === 'appointment'
    )
      tabMenu[i].count = appointmentCountSlice.count

    if (tabMenu[i].name === 'Lab Referrals')
      tabMenu[i].count = labReferralCount.count

    if (tabMenu[i].name === 'Organizations')
      tabMenu[i].count = mainOrgCountSlice.count
    if (tabMenu[i].name === 'Clinic')
      tabMenu[i].count = welloUnitsCountSearchSlice.unitsList
        ? welloUnitsCountSearchSlice.unitsList.length
        : 0

    if (tabMenu[i].name === 'Lab')
      tabMenu[i].count = labCountSearchSlice.unitsList
        ? labCountSearchSlice.unitsList.length
        : 0

    if (tabMenu[i].name === 'Referrals')
      tabMenu[i].count = incomingReferralsCountSlice.count

    if (tabMenu[i].name === 'Medicine Orders')
      tabMenu[i].count = medicineOrderCountSlice.count

    if (tabMenu[i].name === 'Care Plan')
      tabMenu[i].count = medicineOrderCountSlice.count

    if (tabMenu[i].name === 'IPD') tabMenu[i].count = ipdCountSlice.count
    if (
      tabMenu[i].name === 'Lab Orders' ||
      tabMenu[i].name === 'प्रयोगशाला आदेश'
    ) {
      if (isAgent()) {
        tabMenu[i].count = orderCountForAgentSlice.count
      } else {
        tabMenu[i].count = orderCountSlice.count
      }
    }
    if (tabMenu[i].name === 'Pre-Appointment') {
      tabMenu[i].count = (
        appointmentSearchSlice.availableAppointments ?? []
      ).length
    }
  }

  const [selectedTab, setSelectedTab] = React.useState(
    selected ?? tabMenu[0].name
  )

  // const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  // const [anchorEl, setAnchorEl] = React.useState();

  const loggedInUser: CurrentLoggedInUser | undefined = userData

  const handleChangeTab = (newSelectedTab: string) => {
    if (onTabChange) {
      dispatch(resetLabOffering())
      dispatch(resetSearchForOPDConsultation())
      dispatch(resetSearchForIPD())
      onTabChange(newSelectedTab)
    }
    setSelectedTab(newSelectedTab)
  }

  const logoutUser = () => {
    sessionStorage.clear()
    localStorage.clear()
    window.location.href = getLogOutUrl()
  }

  return (
    <div>
      <AppBar
        position='fixed'
        style={{
          background: useTheme().palette.background.default,
          height: '60px',
        }}
        elevation={0}
      >
        <Box display='flex' flexDirection='row' px={3}>
          <Box display='flex' alignItems='center' justifyContent='center'>
            {loggedInUser && (
              <AccountDropDown
                user={loggedInUser}
                onNewRoleSelected={(value) => {
                  if (value) {
                    dispatch(setRoleAndUnit(value, authState))
                  }
                }}
                onLogOutPressed={() => {
                  setShowDeleteConfirmation({
                    open: true,

                    onContinueActionClicked: () => {
                      setShowDeleteConfirmation({ open: false })
                      logoutUser()
                    },
                    onCancelActionClicked: () => {
                      setShowDeleteConfirmation({ open: false })
                    },
                    title: 'Log Out',
                    displayText: 'Are you sure you want to Log Out?',
                    continueActionButtonText: 'Yes',
                    cancelActionButtonText: 'No',
                  })
                }}
              />
            )}
          </Box>
          <Box
            display='flex'
            flexGrow={1}
            flexDirection='row'
            paddingTop='16px'
          >
            {tabMenu && tabMenu.length > 0 ? (
              <AppBarTabs
                preSelectedTab={t('labelCommon:' + `${selectedTab}`)}
                onTabChange={(e: any) => handleChangeTab(e)}
                menu={tabMenu}
                totalCount={tabMenu.length}
              />
            ) : (
              <div style={{ flexGrow: 1 }} />
            )}
            {/*     {showHomeButton && (
              <Tooltip title='Home'>
                <IconButton
                  aria-label='Home'
                  onClick={() => {
                    if (schedules) {
                      if (schedules.length > 0) setSelectedMenu(true)
                      // else navigateToPath('/dashboard')
                    }
                  }}
                >
                  <CalendarTodayIcon color='primary' />
                  
                </IconButton>
              </Tooltip>
            )} */}

            {showSettingsButton && (
              <Tooltip title='Settings'>
                <IconButton aria-label='Settings'>
                  <SettingsIcon
                    color='primary'
                    id='settings'
                    onClick={() => {
                      // navigateToPath('/settings')
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </AppBar>
      {selectMenu === true && (
        <CancelSchedule
          open={selectMenu}
          onClose={() => {
            setSelectedMenu(false)
          }}
          onDiscard={() => {
            schedules = []
            setSelectedMenu(false)
            // navigateToPath('/dashboard')
            if (existingSchedules) {
              if (existingSchedules.length > 0) {
                const scheduleBundle =
                  getBulkScheduleUpdateBundle(existingSchedules)
                dispatch(updatingScheduleDetails(scheduleBundle))
              }
            }
            existingSchedules = []
          }}
        />
      )}
      {showDeleteConfirmation.open && (
        <ConfirmationDialog {...showDeleteConfirmation} id='1' />
      )}
    </div>
  )
}
