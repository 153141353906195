import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  ContactPointUseKind,
  HumanNameUseKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors, number, string } from 'io-ts'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { PatientPreAppointmentBulkUploadHelper } from 'models/patientDataHelper'
import { PatientPreAppointmentDetails } from 'models/patientPreAppointmentDetails'
import { PatientPreAppointmentPostModel } from 'models/patientPreAppointmentPostModel'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { CcClinet } from 'services/Cclinet'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getPatientPreAppointmentResourceObject } from 'utils/fhirResoureHelpers/invitationHelpers'
import { logger } from 'utils/logger'
import { BulkPatientAddSuccess } from './bulkPatientAddSuccess'

const initialState: BulkPatientAddSuccess = {
  uploading: false,
  uploadingSuccessful: false,
  error: false,
  errorMessage: '',
  successCount: 0,
  updateCount: 0,
}

const bulkPatientAddSlice = createSlice({
  name: 'bulkAddPatient',
  initialState,
  reducers: {
    uploadingData(state, action: PayloadAction<BulkPatientAddSuccess>) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
    },

    DataUploaded(state, action: PayloadAction<BulkPatientAddSuccess>) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
    },

    errorInUploadingData(state, action: PayloadAction<BulkPatientAddSuccess>) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetUpload(state, action: PayloadAction<BulkPatientAddSuccess>) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },
  },
})

export function getPatientPreAppointmentBulkResourceObject(
  firstName: string,
  lastName: string,
  phoneNumber: string
) {
  const humanName: R4.IHumanName = {}

  humanName.given = [firstName]
  humanName.family = lastName
  humanName.use = HumanNameUseKind._official

  const patient: R4.IPatient = {
    resourceType: 'Patient',

    telecom: [
      {
        use: ContactPointUseKind._mobile,
        system: R4.ContactPointSystemKind._phone,
        rank: 1,
        value: phoneNumber,
      },
    ],
  }
  patient.name = [humanName]

  return patient
}

function getTransactionObjectForBulkPreAppointment(
  input: PatientPreAppointmentBulkUploadHelper[]
): any {
  const entries: PatientPreAppointmentPostModel[] = []
  input.forEach((e) => {
    const patientObject: R4.IPatient =
      getPatientPreAppointmentBulkResourceObject(e.name, e.lastName, e.phone)

    const patientEntry: any = {
      patient: patientObject,
      appointmentType: e.appointmentType,
      checkinDate: e.appointmentDate,
    }

    entries.push(patientEntry)
  })

  return entries
}

export const uploadingPreAppointmentPatientData =
  (patientData: any): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingDocumentUploadedState: BulkPatientAddSuccess = {
      uploading: true,
      uploadingSuccessful: false,
      error: false,
      successCount: 10,
      updateCount: 0,
    }
    dispatch(
      bulkPatientAddSlice.actions.uploadingData(addingDocumentUploadedState)
    )
    try {
      logger.info('documentObj')
      const insertCount: string[] = []
      const updateCount: string[] = []
      logger.info(patientData)

      const requestBody = getTransactionObjectForBulkPreAppointment(patientData)

      const enRolClient: CcClinet = new CcClinet()

      // const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      //   'enrolment/pre-appt/patientForm',
      //   requestBody[0]
      // )

      interface ErrorResponse {
        rowNo: number
        status: string
      }

      const respObj: ErrorResponse = {
        rowNo: 0,
        status: '',
      }
      const resMap: ErrorResponse[] = []
      const responseArray: any = []
      await Promise.allSettled(
        requestBody.map(async (e: any, index: any) => {
          const res = await enRolClient.doCreateEnrolmentFlowRequest(
            'enrolment/pre-appt/patientForm',
            e
          )

          responseArray.push(res)
        })
      )

      if (responseArray.length > 0) {
        const successUploadingDocument: BulkPatientAddSuccess = {
          uploading: false,
          uploadingSuccessful: true,
          error: false,
          errorMessage: '',
          successCount: 0,
          updateCount: updateCount.length,
        }
        dispatch(
          bulkPatientAddSlice.actions.DataUploaded(successUploadingDocument)
        )
        dispatch(showSuccessAlert(`Uploaded successfully`))
      } else {
        const errorDocuploadedState: BulkPatientAddSuccess = {
          uploading: false,
          uploadingSuccessful: false,
          error: true,
          errorMessage:
            'Either name and Phone number already exists. Check the data',
          successCount: 0,
          updateCount: 0,
        }
        dispatch(
          bulkPatientAddSlice.actions.errorInUploadingData(
            errorDocuploadedState
          )
        )
        dispatch(
          showErrorAlert(
            `Either name and Phone number already exists. Check the data`
          )
        )
        return
      }
      return
    } catch (error) {
      logger.info('error')
      const errorDocuploadedState: BulkPatientAddSuccess = {
        uploading: false,
        uploadingSuccessful: false,
        error: true,
        errorMessage: 'Error while creating patient',
        successCount: 0,
        updateCount: 0,
      }
      dispatch(
        bulkPatientAddSlice.actions.errorInUploadingData(errorDocuploadedState)
      )
    }
  }

export const resetBulkPatientAdd = () => (dispatch: AppDispatch) => {
  dispatch(bulkPatientAddSlice.actions.resetUpload(initialState))
}

export default bulkPatientAddSlice.reducer
