import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { getExpandedAppointmentFromBundle } from 'utils/common/patientDataTableHelper'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import { ReportDisplay } from 'models/reportDisplay'
import { getDiagnosticReportsHelper } from 'utils/fhirResoureHelpers/reportHelper'
import { FhirReportApiClient } from 'services/fhirReportApiClient'
import { ReportSearchStatus } from './reportSearchStatus'

const initialState: ReportSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const reportSearchSlice = createSlice({
  name: 'reportSearchSlice',
  initialState,
  reducers: {
    searchingPatientDetails(
      state,
      action: PayloadAction<ReportSearchStatus>
    ) {},

    searchResults(state, action: PayloadAction<ReportSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.reportDisplay = action.payload.reportDisplay
    },

    noDataFoundForSearch(state, action: PayloadAction<ReportSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.reportDisplay = action.payload.reportDisplay
    },

    errorWhileSearching(state, action: PayloadAction<ReportSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.reportDisplay = action.payload.reportDisplay
    },
    resetState(state, action: PayloadAction<ReportSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.reportDisplay = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: ReportSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      reportDisplay: undefined,
    }
    dispatch(reportSearchSlice.actions.resetState(state))
  }

export const searchReports = (): AppThunk => async (dispatch: AppDispatch) => {
  const errorSearchPatient: ReportSearchStatus = {
    error: false,
    noResultsAvailable: false,
    resultsAvailable: false,
    searching: true,
  }
  dispatch(reportSearchSlice.actions.errorWhileSearching(errorSearchPatient))
  try {
    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const searchParameters: any = {
      active: true,
    }

    const response: any = await fhirClient.doReportGetResource(
      '/DiagnosticReport?_count=100&_include=DiagnosticReport:patient&_include=DiagnosticReport:Practitioner'
    )

    const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
      R4.RTTI_Bundle.decode(response)
    if (relatedFhirDecodeRes._tag === 'Right') {
      // const patientResponses: R4.IBundle = relatedFhirDecodeRes.right
      if (response.total) {
        if (response.total > 0) {
          if (response.entry) {
            const reports: ReportDisplay[] =
              getDiagnosticReportsHelper(response) ?? []

            const searchPatientResult: ReportSearchStatus = {
              error: false,
              noResultsAvailable: false,
              resultsAvailable: true,
              searching: false,
              reportDisplay: reports,
            }
            dispatch(
              reportSearchSlice.actions.searchResults(searchPatientResult)
            )
            return
          }
        }
      }
      const noSearchResults: ReportSearchStatus = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(reportSearchSlice.actions.noDataFoundForSearch(noSearchResults))
      return
    }
    const errorWhileSearchPatient: ReportSearchStatus = {
      error: true,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: 'Error while searching',
    }
    dispatch(
      reportSearchSlice.actions.errorWhileSearching(errorWhileSearchPatient)
    )
    return
  } catch (error) {
    logger.error(error)
    const errorWhileSearchPatient: ReportSearchStatus = {
      error: true,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: 'Error',
    }
    dispatch(
      reportSearchSlice.actions.errorWhileSearching(errorWhileSearchPatient)
    )
  }
}

export default reportSearchSlice.reducer
