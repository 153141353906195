import { R4 } from '@ahryman40k/ts-fhir-types'
import moment from 'moment'
import {
  getAgeOfAttendantInYears,
  getAgeOfPatient,
  getPassportEndDate,
  getPassportStartDate,
} from './fhirResourcesHelper'

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const MOBILE_REGEX =
  /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[3456789]\d{9}|(\d[ -]?){10}\d$/
const testEmailRegs =
  /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/i

const mailReg =
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
export function validateEmail(email: string, isMandatory: boolean): string {
  let res = isMandatory ? 'Email Address is required' : ''
  if (email && email.length > 0) {
    const isValid = mailReg.test(email)
    if (!isValid) {
      res = 'Email is not valid'
    } else if (email.length > 320) {
      res = 'Email should not be more than 320 characters'
    } else {
      res = ''
    }
  }

  return res
}

export function validateEmailForLab(
  email: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Email Address is required' : ''
  if (email && email.length > 0) {
    const isValid = mailReg.test(email)
    if (!isValid) {
      res = 'Email is not valid'
    } else if (email.length > 320) {
      res = 'Email should not be more than 320 characters'
    } else {
      res = ''
    }
  }

  return res
}

export function validateAttenDantDob(dob: string): string {
  let res = ''
  if (dob && dob.length > 0) {
    const ageData: number = getAgeOfAttendantInYears(dob)
    if (ageData < 8) {
      res = 'Must be greater than 8'
    } else if (ageData > 80) {
      res = 'Must be less than equal to 80'
    } else {
      res = ''
    }
  }

  return res
}

export function validateMobileNumber(
  mobileNumber: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Phone Number is required' : ''
  if (mobileNumber && mobileNumber.length > 0) {
    const isValid = MOBILE_REGEX.test(mobileNumber)
    if (!isValid) {
      res = 'Phone Number is not valid'
    } else {
      res = ''
    }
  }

  return res
}

export function validateMobileNumberForAdd(
  mobileNumber: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Phone Number is required' : ''
  if (mobileNumber && mobileNumber.length > 0) {
    const isValid = MOBILE_REGEX.test(mobileNumber)
    if (!isValid) {
      res = 'Phone Number is not valid'
    } else if (mobileNumber.length > 10) {
      res = 'Phone Number should be of 10 digits'
    } else {
      res = ''
    }
  }

  return res
}

export function validateGender(gender: string, isMandatory: boolean): string {
  let res = isMandatory ? 'Gender is required' : ''
  if (gender && gender.length > 0) {
    res = ''
  }
  return res
}

export function validateLRNumber(
  lrNumber: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Lab Reference Id is required' : ''
  if (lrNumber && lrNumber.length > 30) {
    res = 'Lab Refereence should be maximum of 30 characters'
  }
  if (lrNumber && lrNumber.length > 0 && lrNumber.length <= 30) {
    if (!lrNumber.trim()) {
      res = 'Lab Reference Id is required'
    } else {
      res = ''
    }
  }
  return res
}

export function validateDob(dob: string, isMandatory: boolean): string {
  let res = isMandatory ? 'Invalid Date' : ''
  if (dob && dob.length > 0) {
    if (dob > moment().format('YYYY-MM-DD')) {
      res = 'DOB should be till current date'
    } else if (dob < '1871-01-01') {
      res = 'DOB should be on or after 01-01-1871'
    } else if (getAgeOfAttendantInYears(dob) < 12) {
      res = 'Must be at least 12 years old'
    } else if (getAgeOfAttendantInYears(dob) > 80) {
      res = 'Must be less than equal to 80 Years old'
    } else {
      res = ''
    }
  }
  return res
}

export function validateDobForPractioner(
  dob: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Invalid Date' : ''
  if (dob && dob.length > 0) {
    if (dob > moment().format('YYYY-MM-DD')) {
      res = 'DOB should be till current date'
    } else if (dob < moment().subtract(90, 'years').format('YYYY-MM-DD')) {
      res = `DOB should be on or after ${moment()
        .subtract(90, 'years')
        .format('DD-MM-YYYY')}`
    } else if (getAgeOfAttendantInYears(dob) < 18) {
      res = 'Must be at least 18 years old'
    } else if (dob === 'Invalid Date') {
      res = 'Invalid Date'
    } else {
      res = ''
    }
  }
  return res
}

export function validateDobForAttendant(
  dob: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'DOB is required' : ''
  if (dob && dob.length > 0) {
    if (dob > moment().format('YYYY-MM-DD')) {
      res = 'DOB should be till current date'
    } else if (dob < '1871-01-01') {
      res = 'DOB should be on or after 01-01-1871'
    } else if (getAgeOfAttendantInYears(dob) < 8) {
      res = 'Must be at least 8 years old'
    } else {
      res = ''
    }
  }
  return res
}

export function validateName(name: string, isMandatory: boolean): string {
  let res = isMandatory ? 'First Name is required' : ''
  if (name && name.length > 100) {
    res = 'First Name should be maximum of 100 characters'
  }

  if (name && name.length > 0 && name.length <= 100) {
    if (!name.trim()) {
      res = 'First Name is required'
    } else {
      res = ''
    }
  }
  return res
}

export function validateCity(name: string, isMandatory: boolean): string {
  let res = isMandatory ? 'City is required' : ''

  if (name && name.length > 0 && name.length <= 100) {
    if (!name.trim()) {
      res = 'City is required'
    } else {
      res = ''
    }
  }
  return res
}

export function validateMiddleName(
  middleName: string,
  isMandatory: boolean
): string {
  let res = ''
  if (middleName && middleName.length > 100) {
    res = 'Middle Name should be maximum of 100 characters'
  }
  if (middleName && middleName.length > 0 && middleName.length <= 100) {
    res = ''
  }
  return res
}

export function validateOtherData(
  otherData: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Value is required' : ''
  if (otherData && otherData.length > 50) {
    res = 'Value should be maximum of 50 characters'
  }
  if (otherData && otherData.length > 0 && otherData.length <= 50) {
    res = ''
  }
  return res
}

export function validateLastName(
  lastName: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Last Name is required' : ''
  if (lastName && lastName.length > 100) {
    res = 'Last Name should be maximum of 100 characters'
  }
  if (lastName && lastName.length > 0 && lastName.length <= 100) {
    if (!lastName.trim()) {
      res = 'Last Name is required'
    } else {
      res = ''
    }
  }
  return res
}

export function validateAddress(address: string, isMandatory: boolean): string {
  let res = isMandatory ? `Address is required` : ''
  if (address && address.length > 1024) {
    res = 'Address should be maximum of 1024 characters'
  }
  if (address && address.length > 0 && address.length <= 1024) {
    res = ''
  }
  return res
}

export function validateTemporaryAddress(
  address: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? `Temporary Address is required` : ''
  if (address && address.length > 1024) {
    res = 'Temporary Address should be maximum of 1024 characters'
  }
  if (address && address.length > 0 && address.length <= 1024) {
    res = ''
  }
  return res
}

export function validateVisaNumber(
  address: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? `Visa number is required` : ''
  if (address && address.length > 20) {
    res = 'Visa Number should be maximum of 20 characters'
  }
  if (address && address.length > 0 && address.length <= 20) {
    res = ''
  }
  return res
}

export function validateVisaType(
  address: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? `Visa Type is required` : ''
  if (address && address.length > 0) {
    res = ''
  }
  return res
}

export function validatePermanentAddress(
  address: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? `Permanent Address is required` : ''
  if (address && address.length > 1024) {
    res = 'Permanent Address should be maximum of 1024 characters'
  }
  if (address && address.length > 0 && address.length <= 1024) {
    res = ''
  }
  return res
}

export function validateFathersName(
  fatherName: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? `Father's Name is required` : ''
  if (fatherName && fatherName.length > 0 && fatherName.length <= 300) {
    res = ''
  }
  if (fatherName && fatherName.length > 300) {
    res = `Father's Name should be maximum of 300 characters`
  }
  return res
}

export function validateHusbandsName(
  fatherName: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? `Husband's Name is required` : ''
  if (fatherName && fatherName.length > 0 && fatherName.length <= 300) {
    res = ''
  }
  if (fatherName && fatherName.length > 300) {
    res = `Husband's Name should be maximum of 300 characters`
  }
  return res
}

export function validateNationality(
  nationality: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? `Nationality is required` : ''
  if (nationality && nationality.length > 0) {
    res = ''
  }
  return res
}

// export function validateOccupation(
//   occupation: any,
//   isMandatory: boolean
// ): string {
//   let res = isMandatory ? `Nationality is required` : ''
//   if (occupation && nationality.length > 0) {
//     res = ''
//   }
//   return res
// }

export function validateMaritalStatus(
  maritalStatus: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? `Marital Status is required` : ''
  if (maritalStatus && maritalStatus.length > 0) {
    res = ''
  }
  return res
}

export function validateProfilePic(
  profilePic: string,
  isMandatory: boolean,
  isAppointment: boolean
): string {
  let res = isMandatory ? 'Photo is required' : ''
  if (isAppointment === true) {
    res = 'Photo is required'
  } else {
    res = ''
  }
  if (profilePic && profilePic.length > 0) {
    res = ''
  }

  return res
}

export function validateDoctorName(
  dooctorId: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Doctor Name is required' : ''
  if (dooctorId && dooctorId.length > 0) {
    res = ''
  }
  return res
}

export function validateStartDate(
  startDate: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Start Date is required' : ''
  if (startDate && startDate.length > 0) {
    res = ''
  }
  return res
}

export function validateEndDate(endDate: string, isMandatory: boolean): string {
  let res = isMandatory ? 'End Date is required' : ''
  if (endDate && endDate.length > 0) {
    res = ''
  }
  return res
}

export function validateSlotDuration(
  duration: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Slot Duration is required' : ''
  if (duration && duration.length > 0) {
    res = ''
  }
  return res
}

export function validateStartTime(
  startTime: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Start Time is required' : ''
  if (startTime && startTime.length > 0) {
    res = ''
  }
  return res
}

export function validateEndTime(endTime: string, isMandatory: boolean): string {
  let res = isMandatory ? 'End Time is required' : ''
  if (endTime && endTime.length > 0) {
    res = ''
  }
  return res
}

export function validateTimeLimit(
  endTime: string,
  startTime: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'End Time is required' : ''
  if (endTime && startTime) {
    if (moment(endTime, 'HH.mm').isBefore(moment(startTime, 'HH:mm'))) {
      res = 'End Time Should be > Start Time'
    } else {
      res = ''
    }
  }
  return res
}

export function validateRegistration(
  regNo: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Registration Number is required' : ''
  if (regNo && regNo.length > 0) {
    res = ''
  }
  return res
}

export function validateConsultingFee(
  consultationPrice: number,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Consultation Fee is required' : ''
  if (consultationPrice === 0) res = 'Consultation Fee should be > 0'
  if (consultationPrice > 0) {
    res = ''
  }
  return res
}

export function splDataValidate(spl: string[], isMandatory: boolean): string {
  let res = isMandatory ? 'Specialization is Required' : ''
  if (spl && spl.length === 0) {
    res = 'Specialization is Required'
  }
  if (spl && spl.length > 0) {
    res = ''
  }
  return res
}

export function languageValidate(
  languages: string[],
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Language is Required' : ''
  if (languages && languages.length === 0) {
    res = 'Language is Required'
  }
  if (languages && languages.length > 0) {
    res = ''
  }
  return res
}

export function validateProfileDescription(
  desc: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Profile description is required' : ''
  if (desc.length === 0) {
    res = 'Profile description is required'
  } else {
    res = ''
  }
  if (desc.length > 256) {
    res = 'Profile description should not be > 256 '
  } else {
    res = ''
  }

  return res
}

export function qualificationValidate(
  qualification: string[],
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Medical Qualification is Required' : ''
  if (qualification && qualification.length === 0) {
    res = 'Medical Qualification is Required'
  }
  if (qualification && qualification.length > 0) {
    res = ''
  }
  return res
}

export function validateCheckoutDate(
  checkinDate: string,
  checkoutDate: string,
  isMandatory: boolean
): string {
  const maxDate = moment().add(90, 'd').format('YYYY-MM-DD')
  let res = ''

  if (checkoutDate && checkoutDate.length > 0) {
    if (checkoutDate === 'Invalid date') {
      res = 'Invalid Date'
    } else if (checkoutDate < checkinDate) {
      res = 'Check out date should be >= Admission date'
    } else if (checkoutDate > maxDate) {
      res = `Check out date should not be > ${moment()
        .add(90, 'd')
        .format('DD-MM-YYYY')} `
    } else {
      res = ''
    }
  }
  return res
}

export function valiDateReportAdmissionDate(
  startDate: string,
  endDate: string,
  isMandatory: boolean
): string {
  const maxDate = moment().format('YYYY-MM-DD')
  let res = ''

  if (startDate && startDate.length > 0) {
    if (startDate === 'Invalid date') {
      res = 'Invalid Date'
    } else if (startDate > maxDate) {
      res = `Start date should not be >= ${moment().format('DD-MM-YYYY')} `
    } else {
      res = ''
    }
  }
  return res
}

export function validateAdmissionEndDate(
  startDate: string,
  endDate: string,
  isMandatory: boolean
): string {
  const maxDate = moment().format('YYYY-MM-DD')
  let res = ''

  if (endDate && endDate.length > 0) {
    if (endDate === 'Invalid date') {
      res = 'Invalid Date'
    } else if (endDate > maxDate) {
      res = `End date should not be > ${moment().format('DD-MM-YYYY')} `
    } else if (endDate < startDate) {
      if (startDate > maxDate) {
        res = ''
      } else {
        res = `End date should  be <= ${moment(startDate).format('DD-MM-YYYY')}`
      }
    } else {
      res = ''
    }
  }
  return res
}

export function valiDateFinanceStartMonth(
  startDate: string,
  endDate: string,
  isMandatory: boolean
): string {
  const maxDate = moment().format('YYYY-MM-DD')
  let res = ''

  if (startDate && startDate.length > 0) {
    if (startDate === 'Invalid date') {
      res = 'Invalid Date'
    } else if (startDate > endDate) {
      if (moment(startDate).format('MMM') === moment(endDate).format('MMM'))
        res = ''
      else {
        res = ``
      }
    }
  }
  return res
}

export function valiDateFinanceStartMonthForMessage(
  startDate: string,
  endDate: string,
  isMandatory: boolean
): string {
  const maxDate = moment().format('YYYY-MM-DD')
  let res = ''

  if (startDate && startDate.length > 0) {
    if (startDate === 'Invalid date') {
      res = 'Invalid Date'
    } else if (startDate > endDate) {
      if (moment(startDate).format('MMM') === moment(endDate).format('MMM'))
        res = ''
      else {
        res = `Start month should not be  > ${moment(endDate).format('MMMM')}`
      }
    }
  }
  return res
}

export function validateFinanceEndMonth(
  startDate: string,
  endDate: string,
  isMandatory: boolean
): string {
  const maxDate = moment().format('YYYY-MM-DD')
  let res = ''

  if (endDate && endDate.length > 0) {
    if (endDate === 'Invalid date') {
      res = 'Invalid Date'
    } else if (endDate > maxDate) {
      res = `End Month should not be > ${moment().format('MMM')} `
    } else if (endDate < startDate) {
      if (startDate > maxDate) {
        res = ''
      } else {
        res = ''
      }
    } else {
      res = ''
    }
  }
  return res
}

export function validateCheckoutDateForChiefComplaints(
  checkinDate: string,
  patientBirthDate: string,
  isMandatory: boolean
): string {
  const maxDate = moment().format('YYYY-MM-DD')
  let res = ''

  if (checkinDate && checkinDate.length > 0) {
    if (checkinDate === 'Invalid date') {
      res = 'Invalid Date'
    } else if (checkinDate < patientBirthDate) {
      res = 'Date should not be less than patient age'
    } else if (patientBirthDate <= checkinDate) {
      res = ''
    } else if (checkinDate > maxDate) {
      res = 'Date should be till current date'
    } else {
      res = ''
    }
  }
  return res
}

export function validateDateForPassportIssueDate(
  passportStartDate: string,
  passportEndDate: string,
  isMandatory: boolean,
  dob?: string
): string {
  const maxDate = moment().format('YYYY-MM-DD')
  let res = isMandatory && passportStartDate.length === 0 ? 'Invalid Date' : ''
  if (passportStartDate && passportStartDate.length > 0) {
    if (passportStartDate === 'Invalid date') {
      res = 'Invalid Date'
    } else if (passportStartDate > maxDate) {
      res = 'Passport issue date should be till current date'
    } else if (dob) {
      if (passportStartDate < dob)
        res = 'Passport issue date should be greater than Date of birth'
    } else {
      res = ''
    }
  }
  return res
}

export function validateDateForPassportValidTill(
  checkinDate: string,
  issueDate: string,
  isMandatory: boolean
): string {
  const maxDate = moment().add(20, 'years').endOf('day').format('YYYY-MM-DD')

  const expiryDate = moment(checkinDate).format('YYYY-MM-DD')
  let res = isMandatory ? 'Passport expiry date is Required' : ''
  if (expiryDate && expiryDate.length > 0) {
    if (expiryDate === 'Invalid date') {
      res = 'Invalid Date'
    } else if (expiryDate < issueDate) {
      res = 'Expiry Date should be after issue date'
    } else if (expiryDate > maxDate) {
      res = `Expiry Date should be before ${moment()
        .add(10, 'years')
        .endOf('day')
        .format('DD-MM-YYYY')}`
    } else if (expiryDate < moment().format('YYYY-MM-DD')) {
      res = `Passport Expiry date should be greater then ${moment().format(
        'DD-MM-YYYY'
      )} `
    } else {
      res = ''
    }
  }
  return res
}

export function validateDateForPassportValidError(
  expiryDate: string,
  issueDate: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Passport expiry date is Required' : ''
  if (expiryDate && expiryDate.length > 0) {
    if (expiryDate === 'Invalid date') {
      res = 'Invalid Date'
    } else if (expiryDate < issueDate) {
      res = 'Expiry Date should be after issue date'
    } else if (expiryDate < moment().format('YYYY-MM-DD')) {
      res = `Passport has been expired, Please update expiry date in edit profile`
    } else {
      res = ''
    }
  }
  return res
}

export function visaStartMinDate(patient: R4.IPatient): Date {
  const passportStartDate: Date = getPassportStartDate(
    patient.extension,
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
  )

  return passportStartDate
}

export function visaStartMaxDate(patient: R4.IPatient): Date {
  const passportStartDate: Date = getPassportEndDate(
    patient.extension,
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
  )

  return passportStartDate
}

export function visaStartDateValidation(
  patient: R4.IPatient,
  isMandatory: boolean,
  visaStartDate?: Date
): string {
  const maxDate = moment().format('YYYY-MM-DD')

  const passportStartDate: string = moment(
    getPassportStartDate(
      patient.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
    )
  ).format('YYYY-MM-DD')

  const passportendDate: string = moment(
    getPassportEndDate(
      patient.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
    )
  ).format('YYYY-MM-DD')
  let res = isMandatory ? 'Invalid date' : ''
  if (visaStartDate) {
    const visaStartDateData: string = moment(visaStartDate).format('YYYY-MM-DD')
    if (visaStartDateData && visaStartDateData.length > 0) {
      if (visaStartDateData === 'Invalid date') {
        res = 'Invalid Date'
      } else if (visaStartDateData > maxDate) {
        res = 'Date should be till current date'
      } else if (visaStartDateData < passportStartDate) {
        res = 'Date should be after passport start date'
      } else if (visaStartDateData > passportendDate) {
        res = 'Date should be before passport expiry date'
      } else {
        res = ''
      }
    }
  }

  return res
}

export function visaExpiryDateValidate(
  patient: R4.IPatient,
  isMandatory: boolean,
  visaStartDate?: Date,
  visaExpiryDate?: Date
): string {
  const maxDate = moment().format('YYYY-MM-DD')

  const passportStartDate: string = moment(
    getPassportStartDate(
      patient.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
    )
  ).format('YYYY-MM-DD')

  const passportendDate: string = moment(
    getPassportEndDate(
      patient.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
    )
  ).format('YYYY-MM-DD')
  let res = isMandatory ? 'Invalid date' : ''
  if (visaExpiryDate) {
    const visaExpiryDateData: string =
      moment(visaExpiryDate).format('YYYY-MM-DD')
    if (visaExpiryDateData && visaExpiryDateData.length > 0) {
      if (visaExpiryDateData === 'Invalid date') {
        res = 'Invalid Date'
      } else if (visaExpiryDateData < passportStartDate) {
        res = 'Expiry Date should be after Visa Issue Date'
      } else if (
        visaStartDate &&
        visaExpiryDateData < moment(visaStartDate).format('YYYY-MM-DD')
      ) {
        res = 'Expiry Date should be after Visa Issue Date'
      } else {
        res = ''
      }
    }
  }

  return res
}

export function visaArrivalDateValidation(
  isMandatory: boolean,
  visaStartDate?: Date,
  visaExpiryDate?: Date,
  arrivalDate?: Date
): string {
  const maxDate = moment().format('YYYY-MM-DD')

  let res = isMandatory ? 'Invalid date' : ''
  if (arrivalDate) {
    const arrivalDateData: string = moment(arrivalDate).format('YYYY-MM-DD')
    if (arrivalDateData && arrivalDateData.length > 0) {
      if (arrivalDateData === 'Invalid date') {
        res = 'Invalid Date'
      } else if (arrivalDateData > maxDate) {
        res = 'Arrival Date should be till current date'
      } else if (
        visaStartDate &&
        arrivalDateData < moment(visaStartDate).format('YYYY-MM-DD')
      ) {
        res = 'Arrival Date should be till visa issue date'
      } else {
        res = ''
      }
    }
  }

  return res
}

export function addressValidate(
  languages: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Address is Required' : ''
  if (languages && languages.length === 0) {
    res = 'Address is Required'
  }
  if (languages && languages.length > 0) {
    res = ''
  }
  return res
}

export function visaCityValidate(
  languages: string,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Visa Issuing city is Required' : ''
  if (languages && languages.length === 0) {
    res = 'Visa Issuing city is Required'
  }
  if (languages && languages.length > 0) {
    res = ''
  }
  return res
}

export function visaFormForPatientDateValidation(
  passportStartDate: string,
  passportEndDate: string,
  isMandatory: boolean,
  visaStartDateData: string
): string {
  const maxDate = moment().format('YYYY-MM-DD')

  //   const passportStartDate: string = moment(
  //     getPassportStartDate(
  //       patient.extension,
  //       'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
  //     )
  //   ).format('YYYY-MM-DD')

  //   const passportendDate: string = moment(
  //     getPassportEndDate(
  //       patient.extension,
  //       'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
  //     )
  //   ).format('YYYY-MM-DD')
  let res = isMandatory ? 'Invalid date' : ''

  if (visaStartDateData && visaStartDateData.length > 0) {
    if (visaStartDateData === 'Invalid date') {
      res = 'Invalid Date'
    } else if (visaStartDateData > maxDate) {
      res = 'Date should be till current date'
    } else if (visaStartDateData < passportStartDate) {
      res = 'Date should be after passport start date'
    } else if (visaStartDateData > passportEndDate) {
      res = 'Date should be before passport expiry date'
    } else {
      res = ''
    }
  }

  return res
}

export function visaExpiryDateValidateForNewPatient(
  passportStartDate: string,
  isMandatory: boolean,
  visaStartDate: string,
  visaExpiryDate: string
): string {
  const maxDate = moment().format('YYYY-MM-DD')

  let res = isMandatory ? 'Invalid date' : ''
  if (visaExpiryDate) {
    const visaExpiryDateData: string = visaExpiryDate
    if (visaExpiryDateData && visaExpiryDateData.length > 0) {
      if (visaExpiryDateData === 'Invalid date') {
        res = 'Invalid Date'
      } else if (visaExpiryDateData < passportStartDate) {
        res = 'Expiry Date should be after Visa Issue Date'
      } else if (visaStartDate && visaExpiryDateData < visaStartDate) {
        res = 'Expiry Date should be after Visa Issue Date'
      } else {
        res = ''
      }
    }
  }

  return res
}
