import { R4 } from '@ahryman40k/ts-fhir-types'
import { CapabilityStatement_SearchParamTypeKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  useTheme,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedOccupation } from 'models/groupedOccupations'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestVitalDetailsOfPatient } from 'redux/consultation/vitalsDetailsSlice/vitalsDetailSlice'
import {
  addGeneralExam,
  resetAddGeneralExaminationState,
} from 'redux/fhirMedicalResources/addGeneralExam/addGeneralExamSlice'
import {
  addVitalDetails,
  resetAddVitalsState,
} from 'redux/fhirMedicalResources/addVital/addVitalSlice'
import { RootState } from 'redux/rootReducer'
import {
  cToF,
  fToC,
  getClubbing,
  getClubbingRemarks,
  getCyn,
  getCynRemarks,
  getIct,
  getIctRemarks,
  getLymp,
  getLympRemarks,
  getNourishData,
  getPallor,
  getPallorRemarks,
  getPreBuiltData,
} from 'utils/appointment_handle/vitals_util'
import {
  RadioRaisedButton,
  WelloFormItemLabel,
  WelloLoadingPage,
} from 'wello-web-components'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  preSelectedGeneralExam?: GroupedOccupation
  onClose: () => void
  onVitalAdded: (createdCondition: R4.IBundle | undefined) => void
}
export const AddGeneralExamination: React.FC<Props> = ({
  open,
  onClose,
  onVitalAdded,
  fhirAppointmentDetails,
  preSelectedGeneralExam,
}) => {
  const { t } = useTranslation(['common', 'observation'])
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '35%',
    },
  }))
  const classes = useStyles()
  const [builtChanged, setBuiltChanged] = useState<boolean>(false)
  const [builtType, setBuiltType] = useState<string | undefined>(
    preSelectedGeneralExam
      ? getPreBuiltData(
          preSelectedGeneralExam ? preSelectedGeneralExam.occupation : []
        )
      : 'normal'
  )

  const [nourishment, setNourishment] = useState<string | undefined>(
    preSelectedGeneralExam
      ? getNourishData(
          preSelectedGeneralExam ? preSelectedGeneralExam.occupation : []
        )
      : 'wellNourished'
  )
  const [nmentChanged, setNmemetChanged] = useState<boolean>(false)

  const [pallor, setPallor] = useState<string | undefined>(
    preSelectedGeneralExam
      ? getPallor(
          preSelectedGeneralExam ? preSelectedGeneralExam.occupation : []
        )
      : 'absent'
  )
  const [pallorChanged, setPallorChanged] = useState<boolean>(false)
  const [pallorRemarks, setPallorRemarks] = useState<string>(
    preSelectedGeneralExam
      ? getPallorRemarks(
          preSelectedGeneralExam ? preSelectedGeneralExam.occupation : []
        )
      : ''
  )
  const [ict, setIct] = useState<string | undefined>(
    preSelectedGeneralExam
      ? getIct(preSelectedGeneralExam ? preSelectedGeneralExam.occupation : [])
      : 'absent'
  )
  const [ictChanged, setIctChanged] = useState<boolean>(false)
  const [ictRemarks, setIctRemarks] = useState<string>(
    preSelectedGeneralExam
      ? getIctRemarks(
          preSelectedGeneralExam ? preSelectedGeneralExam.occupation : []
        )
      : ''
  )
  const [cyn, setCyn] = useState<string | undefined>(
    preSelectedGeneralExam
      ? getCyn(preSelectedGeneralExam ? preSelectedGeneralExam.occupation : [])
      : 'absent'
  )
  const [cynChanged, setCynChanged] = useState<boolean>(false)
  const [cynRemarks, setCynRemarks] = useState<string>(
    preSelectedGeneralExam
      ? getCynRemarks(
          preSelectedGeneralExam ? preSelectedGeneralExam.occupation : []
        )
      : ''
  )
  const [clubbing, setClubbing] = useState<string | undefined>(
    preSelectedGeneralExam
      ? getClubbing(
          preSelectedGeneralExam ? preSelectedGeneralExam.occupation : []
        )
      : 'absent'
  )

  const [clubbingChanged, setClubbingChanged] = useState<boolean>(false)
  const [clubbingRemarks, setClubbingRemarks] = useState<string>(
    preSelectedGeneralExam
      ? getClubbingRemarks(
          preSelectedGeneralExam ? preSelectedGeneralExam.occupation : []
        )
      : ''
  )
  const [lymp, setLymp] = useState<string | undefined>(
    preSelectedGeneralExam
      ? getLymp(preSelectedGeneralExam ? preSelectedGeneralExam.occupation : [])
      : 'absent'
  )
  const [lympChanged, setLympChnaged] = useState<boolean>(false)
  const [lympRemarks, setLympRemarks] = useState<string>(
    preSelectedGeneralExam
      ? getLympRemarks(
          preSelectedGeneralExam ? preSelectedGeneralExam.occupation : []
        )
      : ''
  )

  const dispatch = useDispatch()

  const addVitalSlice = useSelector(
    (state: RootState) => state.addGeneralExaminationSlice
  )

  function resetDetails() {
    setBuiltType(undefined)
    setBuiltChanged(false)
    setNmemetChanged(false)
    setNourishment(undefined)
    setPallor(undefined)
    setPallorChanged(false)
    setIctChanged(false)
    setClubbingChanged(false)
    setLympChnaged(false)
    setCynChanged(false)
  }

  function checkForPollar() {
    if (pallor === 'present' && pallorRemarks.length > 256) {
      return true
    }
    return false
  }
  //   function isChange() {
  //     if (
  //       builtChanged ||
  //       nmentChanged ||
  //       pallorChanged ||
  //       lympChanged ||
  //       cynChanged ||
  //       ictChanged ||
  //       clubbingChanged
  //     ) {
  //       return false
  //     }
  //     return true
  //   }
  function checkIct() {
    if (ict === 'present' && ictRemarks.length > 256) {
      return true
    }
    return false
  }
  function checkCyn() {
    if (cyn === 'present' && cynRemarks.length > 256) {
      return true
    }
    return false
  }
  function checkClub() {
    if (clubbing === 'present' && clubbingRemarks.length > 256) {
      return true
    }
    return false
  }
  function checkLymp() {
    if (lymp === 'present' && lympRemarks.length > 256) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (addVitalSlice.additionSuccessful) {
      onVitalAdded(addVitalSlice.addedVitalsBundle)
      resetDetails()
    }
  }, [addVitalSlice.additionSuccessful, addVitalSlice, onVitalAdded])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetDetails()
        // dispatch(resetAddVitalsState())
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>
        Add General Examination
      </DialogTitle>
      <DialogContent dividers={true}>
        {addVitalSlice.adding && <WelloLoadingPage title='Adding details' />}

        <Box
          marginLeft={2}
          marginY={1}
          display='flex'
          justifyContent='flex-start'
          flexDirection='column'
        >
          <Box
            width='100%'
            flexDirection='row'
            display='flex'
            justifyContent='flex-start'
          >
            <Box width='40%' paddingTop={0.5}>
              <Typography variant='h6'>Built</Typography>
            </Box>
            <Box width='60%' flexDirection='row'>
              <FormControl>
                <FormLabel id='demo-row-radio-buttons-group-label'>
                  {/* <Typography
                      variant='subtitle1'
                      color='textPrimary'
                      style={{ paddingLeft: 6 }}
                    >
                      Time Period
                    </Typography> */}
                </FormLabel>
                <RadioGroup
                  id='gen_5'
                  row
                  aria-label='graphType'
                  value={builtType}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setBuiltType((event.target as HTMLInputElement).value)

                    setBuiltChanged(true)
                  }}
                >
                  <Box
                    paddingLeft={1.5}
                    width='100%'
                    display='flex'
                    flexDirection='row'
                  >
                    <Box display='flex' flexDirection='column'>
                      <Box display='flex' flexDirection='row'>
                        <FormControlLabel
                          value='normal'
                          control={<Radio color='primary' id='gen_6' />}
                          label={
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              noWrap
                            >
                              Normal Built
                            </Typography>
                          }
                        />
                        <Box paddingLeft={2.8}>
                          <FormControlLabel
                            value='obese'
                            control={<Radio color='primary' id='gen_7' />}
                            label={
                              <Typography
                                variant='subtitle2'
                                color='initial'
                                noWrap
                              >
                                Obese
                              </Typography>
                            }
                          />
                        </Box>
                      </Box>
                      <FormControlLabel
                        value='emicated'
                        control={<Radio color='primary' id='gen_8' />}
                        label={
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            noWrap
                          >
                            Emaciated
                          </Typography>
                        }
                      />
                    </Box>
                  </Box>
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          <Box
            width='100%'
            flexDirection='row'
            display='flex'
            justifyContent='flex-start'
            paddingTop={1}
          >
            <Box width='40%' paddingTop={0.5}>
              <Typography variant='h6'>Nourishment</Typography>
            </Box>
            <Box width='60%' flexDirection='row'>
              <FormControl>
                <RadioGroup
                  id='gen_9'
                  row
                  aria-label='graphType'
                  value={nourishment}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNourishment((event.target as HTMLInputElement).value)
                    setNmemetChanged(true)
                  }}
                >
                  <Box
                    paddingLeft={1.5}
                    width='100%'
                    display='flex'
                    flexDirection='row'
                  >
                    <FormControlLabel
                      value='wellNourished'
                      control={<Radio color='primary' id='gen_10' />}
                      label={
                        <Typography variant='subtitle2' color='initial' noWrap>
                          Well Nourished
                        </Typography>
                      }
                    />
                    <Box paddingLeft={0.8}>
                      <FormControlLabel
                        value='malnourished'
                        control={<Radio color='primary' id='gen_11' />}
                        label={
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            noWrap
                          >
                            Malnourished
                          </Typography>
                        }
                      />
                    </Box>
                  </Box>
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>

          <Box
            width='100%'
            flexDirection='row'
            display='flex'
            justifyContent='flex-start'
            paddingTop={1}
          >
            <Box width='40%' paddingTop={0.5}>
              <Typography variant='h6'>Pallor</Typography>
            </Box>
            <Box width='60%' flexDirection='row'>
              <Box display='flex' flexDirection='column'>
                <FormControl>
                  <RadioGroup
                    id='gen_12'
                    row
                    aria-label='graphType'
                    value={pallor}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setPallor((event.target as HTMLInputElement).value)
                      setPallorChanged(true)
                      if (
                        (event.target as HTMLInputElement).value === 'absent'
                      ) {
                        setPallorRemarks('')
                      }
                    }}
                  >
                    <Box px={1.5} display='flex' flexDirection='row'>
                      <FormControlLabel
                        value='present'
                        control={<Radio color='primary' id='gen_13' />}
                        label={
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            noWrap
                          >
                            Present
                          </Typography>
                        }
                      />
                      <Box paddingLeft={6.5}>
                        <FormControlLabel
                          value='absent'
                          control={<Radio color='primary' id='gen_14' />}
                          label={
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              noWrap
                            >
                              Absent
                            </Typography>
                          }
                        />
                      </Box>
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Box>
          {pallor === 'present' && (
            <Box
              width='100%'
              flexDirection='row'
              display='flex'
              justifyContent='flex-start'
            >
              <Box width='40%' paddingTop={2}>
                <Typography variant='h6'>Remarks</Typography>
              </Box>
              <Box width='60%' flexDirection='row'>
                <Box display='flex' flexDirection='column'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    paddingLeft={1.5}
                    width='87%'
                  >
                    <TextField
                      variant='outlined'
                      fullWidth
                      type='string'
                      id='gen_15'
                      value={pallorRemarks}
                      multiline={true}
                      maxRows={4}
                      minRows={1}
                      onChange={(event) => {
                        setPallorRemarks(event.target.value)
                      }}
                      error={pallor && pallorRemarks.length > 256}
                      helperText={
                        pallor && pallorRemarks.length > 256
                          ? `Remarks must not exceed 256 characters`
                          : ''
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          <Box
            width='100%'
            flexDirection='row'
            display='flex'
            justifyContent='flex-start'
            paddingTop={1}
          >
            <Box width='40%' paddingTop={0.5}>
              <Typography variant='h6'>Icterus</Typography>
            </Box>
            <Box width='60%' flexDirection='row'>
              <Box display='flex' flexDirection='column'>
                <FormControl>
                  <RadioGroup
                    id='gen_16'
                    row
                    aria-label='graphType'
                    value={ict}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setIctChanged(true)
                      setIct((event.target as HTMLInputElement).value)
                      if (
                        (event.target as HTMLInputElement).value === 'absent'
                      ) {
                        setIctRemarks('')
                      }
                    }}
                  >
                    <Box px={1.5} display='flex' flexDirection='row'>
                      <FormControlLabel
                        value='present'
                        control={<Radio color='primary' id='gen_17' />}
                        label={
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            noWrap
                          >
                            Present
                          </Typography>
                        }
                      />
                      <Box paddingLeft={6.5}>
                        <FormControlLabel
                          value='absent'
                          control={<Radio color='primary' id='gen_18' />}
                          label={
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              noWrap
                            >
                              Absent
                            </Typography>
                          }
                        />
                      </Box>
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Box>

          {ict === 'present' && (
            <Box
              width='100%'
              flexDirection='row'
              display='flex'
              justifyContent='flex-start'
            >
              <Box width='40%' paddingTop={2}>
                <Typography variant='h6'>Remarks</Typography>
              </Box>
              <Box width='60%' flexDirection='row'>
                <Box display='flex' flexDirection='column'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    paddingLeft={1.5}
                    width='87%'
                  >
                    <TextField
                      variant='outlined'
                      fullWidth
                      type='string'
                      id='gen_19'
                      value={ictRemarks}
                      onChange={(event) => {
                        setIctRemarks(event.target.value)
                      }}
                      multiline={true}
                      maxRows={4}
                      minRows={1}
                      error={ict && ictRemarks.length > 256}
                      helperText={
                        ict && ictRemarks.length > 256
                          ? `Remarks must not exceed 256 characters`
                          : ''
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          <Box
            width='100%'
            flexDirection='row'
            display='flex'
            justifyContent='flex-start'
            paddingTop={1}
          >
            <Box width='40%' paddingTop={0.5}>
              <Typography variant='h6'>Cyanosis</Typography>
            </Box>
            <Box width='60%' flexDirection='row'>
              <Box display='flex' flexDirection='column'>
                <FormControl>
                  <RadioGroup
                    id='add_unit_address_choice_rad_group'
                    row
                    aria-label='graphType'
                    value={cyn}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCynChanged(true)
                      setCyn((event.target as HTMLInputElement).value)
                      if (
                        (event.target as HTMLInputElement).value === 'absent'
                      ) {
                        setCynRemarks('')
                      }
                    }}
                  >
                    <Box px={1.5} display='flex' flexDirection='row'>
                      <FormControlLabel
                        value='present'
                        control={<Radio color='primary' id='gen_20' />}
                        label={
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            noWrap
                          >
                            Present
                          </Typography>
                        }
                      />
                      <Box paddingLeft={6.5}>
                        <FormControlLabel
                          value='absent'
                          control={<Radio color='primary' id='gen_21' />}
                          label={
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              noWrap
                            >
                              Absent
                            </Typography>
                          }
                        />
                      </Box>
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Box>

          {cyn === 'present' && (
            <Box
              width='100%'
              flexDirection='row'
              display='flex'
              justifyContent='flex-start'
            >
              <Box width='40%' paddingTop={2}>
                <Typography variant='h6'>Remarks</Typography>
              </Box>
              <Box width='60%' flexDirection='row'>
                <Box display='flex' flexDirection='column'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    paddingLeft={1.5}
                    width='87%'
                  >
                    <TextField
                      variant='outlined'
                      fullWidth
                      type='string'
                      id='gen_23'
                      value={cynRemarks}
                      onChange={(event) => {
                        setCynRemarks(event.target.value)
                      }}
                      multiline={true}
                      maxRows={4}
                      minRows={1}
                      error={cyn && cynRemarks.length > 256}
                      helperText={
                        cyn && cynRemarks.length > 256
                          ? `Remarks must not exceed 256 characters`
                          : ''
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          <Box
            width='100%'
            flexDirection='row'
            display='flex'
            justifyContent='flex-start'
            paddingTop={1}
          >
            <Box width='40%' paddingTop={0.5}>
              <Typography variant='h6'>Clubbing</Typography>
            </Box>
            <Box width='60%' flexDirection='row'>
              <Box display='flex' flexDirection='column'>
                <FormControl>
                  <RadioGroup
                    id='gen_24'
                    row
                    aria-label='graphType'
                    value={clubbing}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setClubbingChanged(true)
                      setClubbing((event.target as HTMLInputElement).value)
                      if (
                        (event.target as HTMLInputElement).value === 'absent'
                      ) {
                        setClubbingRemarks('')
                      }
                    }}
                  >
                    <Box px={1.3} display='flex' flexDirection='row'>
                      <FormControlLabel
                        value='present'
                        control={<Radio color='primary' id='gen_25' />}
                        label={
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            noWrap
                          >
                            Present
                          </Typography>
                        }
                      />
                      <Box paddingLeft={6.7}>
                        <FormControlLabel
                          value='absent'
                          control={<Radio color='primary' id='gen_26' />}
                          label={
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              noWrap
                            >
                              Absent
                            </Typography>
                          }
                        />
                      </Box>
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Box>
          {clubbing === 'present' && (
            <Box
              width='100%'
              flexDirection='row'
              display='flex'
              justifyContent='flex-start'
            >
              <Box width='40%' paddingTop={2}>
                <Typography variant='h6'>Remarks</Typography>
              </Box>
              <Box width='60%' flexDirection='row'>
                <Box display='flex' flexDirection='column'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    paddingLeft={1.5}
                    width='87%'
                  >
                    <TextField
                      variant='outlined'
                      fullWidth
                      id='gen_27'
                      type='string'
                      value={clubbingRemarks}
                      onChange={(event) => {
                        setClubbingRemarks(event.target.value)
                      }}
                      multiline={true}
                      maxRows={4}
                      minRows={1}
                      error={clubbing && clubbingRemarks.length > 256}
                      helperText={
                        clubbing && clubbingRemarks.length > 256
                          ? `Remarks must not exceed 256 characters`
                          : ''
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          <Box
            width='100%'
            flexDirection='row'
            display='flex'
            justifyContent='flex-start'
            paddingTop={1}
          >
            <Box width='40%' paddingTop={0.5}>
              <Typography variant='h6'>Lymphadenopathy</Typography>
            </Box>
            <Box width='60%' flexDirection='row'>
              <Box display='flex' flexDirection='column'>
                <FormControl>
                  <RadioGroup
                    id='gen_29'
                    row
                    aria-label='graphType'
                    value={lymp}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setLympChnaged(true)
                      setLymp((event.target as HTMLInputElement).value)
                      if (
                        (event.target as HTMLInputElement).value === 'absent'
                      ) {
                        setLympRemarks('')
                      }
                    }}
                  >
                    <Box px={1.3} display='flex' flexDirection='row'>
                      <FormControlLabel
                        value='present'
                        control={<Radio color='primary' id='gen_30' />}
                        label={
                          <Typography
                            variant='subtitle2'
                            color='initial'
                            noWrap
                          >
                            Present
                          </Typography>
                        }
                      />
                      <Box paddingLeft={6.7}>
                        <FormControlLabel
                          value='absent'
                          control={<Radio color='primary' id='gen_31' />}
                          label={
                            <Typography
                              variant='subtitle2'
                              color='initial'
                              noWrap
                            >
                              Absent
                            </Typography>
                          }
                        />
                      </Box>
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Box>
          {lymp === 'present' && (
            <Box
              width='100%'
              flexDirection='row'
              display='flex'
              justifyContent='flex-start'
            >
              <Box width='40%' paddingTop={2}>
                <Typography variant='h6'>Remarks</Typography>
              </Box>
              <Box width='60%' flexDirection='row'>
                <Box display='flex' flexDirection='column'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    paddingLeft={1.5}
                    width='87%'
                  >
                    <TextField
                      variant='outlined'
                      fullWidth
                      id='gen_32'
                      type='string'
                      value={lympRemarks}
                      onChange={(event) => {
                        setLympRemarks(event.target.value)
                      }}
                      multiline={true}
                      maxRows={4}
                      minRows={1}
                      error={lymp && lympRemarks.length > 256}
                      helperText={
                        lymp && lympRemarks.length > 256
                          ? `Remarks must not exceed 256 characters`
                          : ''
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          width='100%'
          flexDirection='row'
          display='flex'
          justifyContent='flex-end'
          paddingRight={4.7}
        >
          {addVitalSlice.adding && (
            <Box
              flexDirection='row'
              display='flex'
              alignSelf='center'
              justifyContent='center'
            >
              <CircularProgress size={25} />
            </Box>
          )}
          <Button
            onClick={() => {
              onClose()
              resetDetails()
            }}
            id='gen_33'
            variant='outlined'
            disableElevation
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            id='gen_34'
            onClick={() => {
              dispatch(
                addGeneralExam(
                  fhirAppointmentDetails,
                  builtChanged,
                  builtType,
                  nmentChanged === true ? true : undefined,
                  nourishment,
                  pallorChanged === true ? true : undefined,
                  pallor,
                  pallorRemarks,
                  ictChanged === true ? true : undefined,
                  ict,
                  ictRemarks,
                  cynChanged === true ? true : undefined,
                  cyn,
                  cynRemarks,
                  clubbingChanged === true ? true : undefined,
                  clubbing,
                  clubbingRemarks,
                  lympChanged === true ? true : undefined,
                  lymp,
                  lympRemarks
                )
              )
            }}
            variant='contained'
            color='primary'
            disabled={
              checkForPollar() ||
              checkIct() ||
              checkCyn() ||
              checkClub() ||
              checkLymp()
            }
            disableElevation
          >
            {t('labelCommon:add')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
