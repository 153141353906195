import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { BpCollection } from 'models/bpData'
import { IpdRoomRatesData } from 'models/ipdRoomRatesData'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { getSortedObservation } from 'utils/appointment_handle/vitals_util'
import { getDietPlansPlanFromBundle } from 'utils/fhirResoureHelpers/ipdDietHelper'
import { getObserVationFfromEncounter } from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { getTreatmentPlanFromBundle } from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { logger } from 'utils/logger'
import { IpdRoomRateSearchStatus } from './ipdRoomRateSearchStatus'

const initialState: IpdRoomRateSearchStatus = {
  searchingCharges: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingCharges: false,
}

const ipdRoomRateTableSearchSlice = createSlice({
  name: 'ipdRoomRateTableSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<IpdRoomRateSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingCharges = action.payload.searchingCharges
      state.resultsAvailable = action.payload.resultsAvailable
      state.ipdRoomCharges = action.payload.ipdRoomCharges
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingCharges =
        action.payload.errorWhileSearchingCharges
    },
  },
})

export const getAllIpdRoomCharges =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: IpdRoomRateSearchStatus = {
      searchingCharges: true,
      errorWhileSearchingCharges: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(ipdRoomRateTableSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doGetResource(
        `ChargeItemDefinition?url=http://wellopathy.com/ChargeItemDefinition/suite,http://wellopathy.com/ChargeItemDefinition/premium,http://wellopathy.com/ChargeItemDefinition/sharing`
      )

      const resp: R4.IBundle = response as R4.IBundle

      const responses = resp.entry?.map(
        (e) => e.resource as any as R4.IChargeItemDefinition
      )

      const ipdRoomChargesArray: IpdRoomRatesData[] =
        getAllIpdRoomChargesArray(responses)

      state.resultsAvailable = true
      state.searchingCharges = false
      state.ipdRoomCharges = ipdRoomChargesArray
      state.noResultsAvailable = false
      state.errorReason = undefined
      state.errorWhileSearchingCharges = false
      dispatch(ipdRoomRateTableSearchSlice.actions.updatedStatus(state))
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorSearchRates: IpdRoomRateSearchStatus = {
        searchingCharges: false,
        errorWhileSearchingCharges: true,
        resultsAvailable: false,
        errorReason: 'Error while searching IPD rates',
      }
      dispatch(
        ipdRoomRateTableSearchSlice.actions.updatedStatus(errorSearchRates)
      )
    }
  }

export function getAllIpdRoomChargesArray(responses: any): IpdRoomRatesData[] {
  const allChargeDetails: IpdRoomRatesData[] = []
  const oneChargeDetail: IpdRoomRatesData = {}

  responses.map((e: any) => {
    if (e.url === 'http://wellopathy.com/ChargeItemDefinition/suite')
      oneChargeDetail.name = 'Suite'
    else if (e.url === 'http://wellopathy.com/ChargeItemDefinition/premium')
      oneChargeDetail.name = 'Premium'
    else if (e.url === 'http://wellopathy.com/ChargeItemDefinition/sharing')
      oneChargeDetail.name = 'Sharing'

    oneChargeDetail.price = e.propertyGroup[0].priceComponent[0].amount.value
    oneChargeDetail.description = e.description ?? ''
    oneChargeDetail.roomDetailsWithPrice = e

    allChargeDetails.push({
      name: oneChargeDetail.name,
      price: oneChargeDetail.price,
      description: oneChargeDetail.description ?? '',
      roomDetailsWithPrice: oneChargeDetail.roomDetailsWithPrice,
    })
  })

  return allChargeDetails
}

export function getIpdRoomRateBundleUpdate(
  ipdRoomData: IpdRoomRatesData
): R4.IBundle | undefined {
  if (ipdRoomData.roomDetailsWithPrice) {
    const oldUpdatedRoomCharge: R4.IChargeItemDefinition = {
      ...ipdRoomData.roomDetailsWithPrice,
    }
    const oldUpdatedRoomDataMatchString: string = `W/${JSON.stringify(
      oldUpdatedRoomCharge.meta?.versionId ?? ' '
    )}`

    oldUpdatedRoomCharge.description = ipdRoomData.description

    const iMoney: R4.IMoney = {}
    iMoney.value = ipdRoomData.price
    iMoney.currency = 'INR'
    const iComponent: R4.IChargeItemDefinition_PriceComponent = {}
    iComponent.amount = iMoney
    iComponent.type = 'base'
    const iPrice: R4.IChargeItemDefinition_PropertyGroup = {}
    iPrice.priceComponent = [iComponent]
    oldUpdatedRoomCharge.propertyGroup = [iPrice]

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `${oldUpdatedRoomCharge.resourceType}/${oldUpdatedRoomCharge.id}`,
          request: {
            ifNoneExist: oldUpdatedRoomDataMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${oldUpdatedRoomCharge.resourceType}/${oldUpdatedRoomCharge.id}`,
          },
          resource: oldUpdatedRoomCharge,
        },
      ],
    }

    return requestBundle
  }

  return undefined
}

export default ipdRoomRateTableSearchSlice.reducer
