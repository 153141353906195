import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OIDCUser } from 'models/oidcUser'
import { AppDispatch, AppThunk } from 'redux/store'
import { SpecialtiesApiClient } from 'services/specialtiesApiService'
import { getOIDCUserObject } from 'utils/authHelpers'
import { logger } from 'utils/logger'
import { RoleSearchStatus } from './roleSearchStatus'

const initialState: RoleSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const roleSearchSlice = createSlice({
  name: 'roleSearchSlice',
  initialState,
  reducers: {
    searchingRole(state, action: PayloadAction<RoleSearchStatus>) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.roleList = undefined
    },

    searchResults(state, action: PayloadAction<RoleSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.roleList = action.payload.roleList
    },

    noDataFoundForSearch(state, action: PayloadAction<RoleSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.roleList = undefined
    },

    errorWhileSearching(state, action: PayloadAction<RoleSearchStatus>) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.roleList = undefined
    },

    resetState(state, action: PayloadAction<RoleSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.roleList = undefined
    },
  },
})

export const resetRoleSearchState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: RoleSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      roleList: undefined,
    }
    dispatch(roleSearchSlice.actions.resetState(state))
  }

export const searchRoles =
  (name: string, systemOfMedicine: R4.ICoding[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: RoleSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(roleSearchSlice.actions.searchingRole(state))
    try {
      const oidcUser: OIDCUser | null = getOIDCUserObject()
      const apiService: SpecialtiesApiClient = new SpecialtiesApiClient()
      if (oidcUser != null) {
        const response: R4.ICoding[] | undefined = await apiService.doGetRoles(
          name,
          systemOfMedicine
        )
        console.log(response)
        if (response) {
          if (response.length > 0) {
            state.roleList = response
            dispatch(roleSearchSlice.actions.searchResults(state))
          } else {
            const errorSearchDoctor: RoleSearchStatus = {
              error: false,
              noResultsAvailable: true,
              resultsAvailable: false,
              searching: false,
            }
            dispatch(
              roleSearchSlice.actions.noDataFoundForSearch(errorSearchDoctor)
            )
          }
        } else {
          dispatch(roleSearchSlice.actions.noDataFoundForSearch(state))
        }
      } else {
        console.log('null')
      }
    } catch (error) {
      console.log(error)
      logger.error(error)
      const errorSearchDoctor: RoleSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(roleSearchSlice.actions.errorWhileSearching(errorSearchDoctor))
    }
  }

export default roleSearchSlice.reducer
