import { Box, Typography } from '@material-ui/core'
import {
  kBackgroundDefault,
  kBackgroundPaper,
  kPrimaryDark,
} from 'configs/styles/muiThemes'
import React, { useState } from 'react'
import { CarePlansOfferingListOfUnit } from './carePlanOfferings'
import { OutSideOrgCareTeamMembersPage } from './OutSideOrgCareTeamMemberPage'

interface TabDetail {
  code: string
  label: string
  enabled: boolean
}

interface Props {}

export const CareTeamsForCarePlansComponents: React.FC<Props> = ({}: Props) => {
  const defaultTabs = [
    {
      code: 'care-plan-offering',
      label: 'Care Plan Offering',
      enabled: true,
    },
    {
      code: 'invite-care-team-member',
      label: 'Invite Care Team Member',
      enabled: true,
    },
  ]

  const [tabs, setTabs] = useState<TabDetail[]>(defaultTabs)
  const [selectedTab, setSelectedTab] = useState<string>(defaultTabs[0].code)

  return (
    <Box display='flex' flexDirection='column' width='100%' height='100%'>
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        px={2}
        py={0.5}
        bgcolor={kBackgroundDefault}
      >
        <Typography variant='subtitle2' color='initial' style={{}}>
          Care Teams
        </Typography>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        height='100%'
        px={2}
        bgcolor={kBackgroundPaper}
      >
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          pt={1}
          borderBottom={1}
          borderColor={kBackgroundDefault}
        >
          {tabs.map((tab) => (
            <Box
              key={tab.code}
              display='flex'
              flexDirection='row'
              height='25px'
              pr={2}
              style={{
                cursor: tab.enabled ? 'pointer' : 'not-allowed',
              }}
              onClick={() => {
                if (tab.enabled) {
                  setSelectedTab(tab.code)
                }
              }}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                alignContent='center'
                alignItems='center'
                width='100%'
                justifyItems='center'
              >
                <Typography
                  variant='subtitle2'
                  color={selectedTab === tab.code ? 'primary' : 'initial'}
                  style={{
                    textTransform: 'capitalize',
                  }}
                >
                  {tab.label}
                </Typography>
                <Box
                  width='25px'
                  height='2px'
                  style={{
                    borderRadius: '2px',

                    backgroundColor:
                      tab.code === selectedTab ? kPrimaryDark : 'transparent',
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>

        {selectedTab === 'care-plan-offering' && (
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            height='100%'
            bgcolor={kBackgroundPaper}
          >
            <CarePlansOfferingListOfUnit />
          </Box>
        )}
        {selectedTab === 'invite-care-team-member' && (
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            height='100%'
            bgcolor={kBackgroundPaper}
          >
            <OutSideOrgCareTeamMembersPage />
          </Box>
        )}
      </Box>
    </Box>
  )
}
