import {
  Box,
  Button,
  CircularProgress,
  ListItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import {} from 'models/chiefComplaintData'
import { MedicationData } from 'models/medicationData'
import { MedicationGroupedData } from 'models/medicationGrouppedData'
import { UmlResponse } from 'models/umlResponse'
import React, { useEffect, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import {
  searchMedicationsIdnain,
  resetState,
} from 'redux/umls/medication/indianMedication/indianMedicationSearchSlice'

import { logger } from 'utils/logger'

interface LabOfferingSelectorProps {
  onSelectionChanges?: (selectedValues: MedicationGroupedData) => void
  doctorListId?: string
  preSelectedComplaints?: MedicationGroupedData
  preSelectAll?: boolean
  disabled: boolean
  selectOther: boolean
  preSearch?: string
}

export const IndianMedicationMasterSelector: React.FC<LabOfferingSelectorProps> =
  ({
    onSelectionChanges,
    doctorListId,
    preSelectedComplaints,
    preSelectAll = false,
    disabled,
    selectOther,
    preSearch,
  }: LabOfferingSelectorProps) => {
    const indianMedicationSearchSlice = useSelector(
      (state: RootState) => state.indianMedicationSearchSlice
    )
    const [addedMedications, setAddedMedications] =
      useState<MedicationGroupedData>()
    const dispatch = useDispatch()
    const { t } = useTranslation(['common', 'medication'])
    const [text, setText] = useState<string>(preSearch ?? '')
    const [open, SetOpen] = useState<boolean>(false)
    const offSet = useRef<number>(1)

    function loadMoreResults(pageNumber: number) {
      dispatch(
        searchMedicationsIdnain(
          pageNumber,
          text,
          selectOther,
          undefined,
          indianMedicationSearchSlice.medicationList ?? []
        )
      )
    }

    // useEffect(() => {
    //   if (preSearch) {
    //     dispatch(searchMedicationsIdnain(preSearch, selectOther))
    //   }
    // }, [dispatch])

    // useEffect(() => {
    //   dispatch(resetState())
    // }, [dispatch])
    return (
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <div role='alert'>
            <div>Error while fetching medication list</div>
            <pre>{error.message}</pre>
            <Button
              onClick={() => {
                searchMedicationsIdnain(
                  offSet.current,
                  text,
                  selectOther,
                  undefined,
                  indianMedicationSearchSlice.medicationList ?? []
                )
              }}
            >
              Try again
            </Button>
          </div>
        )}
      >
        <Box width='100%'>
          <Box width='100%'>
            <Autocomplete
              id='med_statement'
              ListboxProps={{
                onScroll: (event: React.SyntheticEvent) => {
                  const listboxNode = event.currentTarget

                  if (
                    listboxNode.scrollTop + listboxNode.clientHeight ===
                    listboxNode.scrollHeight
                  ) {
                    if (
                      indianMedicationSearchSlice.medicationList &&
                      indianMedicationSearchSlice.medicationList.length >= 10
                    ) {
                      offSet.current += 10
                      loadMoreResults(offSet.current)
                    }
                  }
                },
                style: {
                  maxHeight: 400,
                },
              }}
              multiple={false}
              onClose={() => {
                dispatch(resetState())
              }}
              style={{
                borderRadius: '4px',
              }}
              disableClearable
              fullWidth
              //   inputValue={text}
              size='small'
              getOptionSelected={(option, value) => option.id === value.id}
              disabled={disabled}
              value={addedMedications}
              defaultValue={preSelectedComplaints}
              getOptionLabel={(option) => option.name!}
              options={indianMedicationSearchSlice.medicationList ?? []}
              loading={indianMedicationSearchSlice.searching}
              autoComplete
              onChange={(e, changedValue, reason) => {
                if (onSelectionChanges) {
                  setAddedMedications(changedValue)
                  onSelectionChanges(changedValue)
                }
              }}
              ChipProps={{
                deleteIcon: (
                  <ClearOutlined
                    style={{
                      height: '15px',
                      color: 'white',
                    }}
                  />
                ),
                style: {
                  backgroundColor: kPrimaryLight,
                  borderRadius: '4px',
                },
              }}
              renderOption={(option, { inputValue }) => {
                const matches = match(option.name!, inputValue)
                const parts = parse(option.name!, matches)
                return (
                  <ListItem id={`lab_med_opt_${option.id}`}>
                    <Box width='100%'>
                      {parts.map((part, index) => (
                        <span
                          key={part.text}
                          id={option.id}
                          style={{
                            backgroundColor: part.highlight
                              ? kPrimaryLight
                              : undefined,
                            fontWeight: part.highlight ? 700 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </Box>
                  </ListItem>
                )
              }}
              filterOptions={(x) => x}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id='med_34'
                  variant='outlined'
                  placeholder={t('labelCommon:type_atleast_3_chars')}
                  onChange={(e) => {
                    if (e.target.value.length > 2) {
                      setAddedMedications(undefined)
                      dispatch(
                        searchMedicationsIdnain(1, e.target.value, selectOther)
                      )
                      setText(e.target.value)
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {indianMedicationSearchSlice.searching ? (
                          <CircularProgress color='inherit' size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Box>

          {indianMedicationSearchSlice.error && (
            <Typography>
              {' '}
              {indianMedicationSearchSlice.errorMessage ??
                'Error while searching'}
            </Typography>
          )}
          {indianMedicationSearchSlice.noResultsAvailable && (
            <Typography> No Results available</Typography>
          )}
        </Box>
      </ErrorBoundary>
    )
  }
