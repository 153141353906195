import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  makeStyles,
  Typography,
  withStyles,
} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { CloseOutlined } from '@material-ui/icons'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchOrderDetailsForPartner,
  requestForUpdatePartnerLabOrder,
} from 'redux/lab/order/orderManagementSlice/partnerLabManagementService'
import { uploadingDocument } from 'redux/lab/upload/uploadReportSlice'
import { RootState } from 'redux/rootReducer'
import { PartnerOrderStatusTypes } from 'utils/constants/order_status'
import { isPartnerLabStatusChangeable } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { OrderAmountSummaryTile } from 'views/components/generalOrder/ordarAmountSummary'
import { WelloSelectFHIRTest } from 'views/components/lab/common/WelloSelectFHIRTest'
import { UploadTile } from 'views/components/lab/Report/uploadTile'
import { PatientTile } from 'views/components/patients/pateintTile'
import { WelloFormItemLabel, WelloTextField } from 'wello-web-components'
import {
  fileTypeReport,
  validReportType,
} from '../../../../utils/constants/labConstants'

interface Props {
  locationId: string
  orderDetail: FhirLabOrderDetail
  isOdd?: boolean
  onCloseClicked: () => void
  dense?: boolean
}

const useStyles = makeStyles((theme) => ({
  test: {
    border: '1px',
    backgroundColor: 'white',
  },
  root: {
    width: 100,
  },
}))

const StyledLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 5,
  },
})(LinearProgress)

export const PartnerLabOrderDetails: React.FC<Props> = ({
  locationId,
  orderDetail,
  isOdd = false,
  onCloseClicked,
  dense = false,
}: Props) => {
  const partnerLabManagementService = useSelector(
    (state: RootState) => state.partnerLabManagementService
  )
  const uploadReportSlice = useSelector(
    (state: RootState) => state.uploadReportSlice
  )
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [status, setStatus] = useState<any>()
  const [selectedFiles, setSelectedFiles] = React.useState<any>()
  const [fileNames, setSelectedFileNames] = React.useState<string[]>([])
  const [labOrderDetail, setLabOrderDetail] =
    useState<FhirLabOrderDetail>(orderDetail)
  const [docReferences, setDocReferences] = useState<string[]>([])
  const [reason, setReason] = useState<string>()
  const fileList: string[] = []
  const [uploadOrDownloadCount, setUploadOrDownloadCount] = React.useState(5)
  const [columns, setColumns] = useState<string[]>([])
  const arr: string[] = []

  useEffect(() => {
    if (selectedFiles) {
      dispatch(uploadingDocument(selectedFiles))
    }
  }, [dispatch, selectedFiles])

  useEffect(() => {
    if (uploadReportSlice.uploadingSuccessful) {
      if (uploadReportSlice.references)
        setDocReferences(uploadReportSlice.references)
    }
    return () => {}
  }, [uploadReportSlice.uploadingSuccessful])

  React.useEffect(() => {
    const timer = setInterval(() => {
      setUploadOrDownloadCount((beforeValue) =>
        beforeValue >= 100 ? 0 : beforeValue + 5
      )
    }, 800)
    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    dispatch(
      fetchOrderDetailsForPartner(
        labOrderDetail.serviceRequest.id ?? '',
        locationId
      )
    )
    return () => {}
  }, [])

  useEffect(() => {
    if (partnerLabManagementService.orderDetailsAvailable) {
      if (partnerLabManagementService.orderDetail) {
        if (partnerLabManagementService.orderDetail) {
          if (partnerLabManagementService.orderDetail.statuses) {
            for (
              let i = 0;
              i < partnerLabManagementService.orderDetail.statuses.length;
              i++
            ) {
              const data =
                partnerLabManagementService.orderDetail.statuses[i].activity
                  ?.coding ?? []
              arr.push(data[0].code ?? '')
            }
          }
          setColumns(arr)
          setLabOrderDetail(partnerLabManagementService.orderDetail)
        }
      }
    }
    return () => {}
  }, [partnerLabManagementService.orderDetailsAvailable])

  return (
    <Box width='100%' px={1}>
      <Card
        elevation={0}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 4,
          backgroundColor: isOdd ? '#FFFFFFAF' : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 1,
          }}
        >
          <Box display='flex' flexDirection='column' width='100%'>
            {partnerLabManagementService.fetchingOrderDetails && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            )}

            {partnerLabManagementService.orderDetailsAvailable && (
              <Box>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  justifyContent='space-between'
                  alignContent='center'
                  alignItems='center'
                >
                  <Typography variant='h6' color='initial'>
                    {`Order #${labOrderDetail!.serviceRequest.id ?? ''}`}
                  </Typography>
                  <Box display='flex' flexDirection='row'>
                    <Tooltip title={t('close') ?? 'Re-Schedule'}>
                      <IconButton
                        aria-label='btn_ord_detail_menu'
                        onClick={onCloseClicked}
                      >
                        <CloseOutlined color='primary' />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>

                <Divider
                  orientation='horizontal'
                  variant='fullWidth'
                  flexItem
                  style={{ width: '100%', height: '2px' }}
                  color='primary'
                />
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' flexDirection='column' width='50%'>
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box display='flex' flexDirection='column' width='100%'>
                        {labOrderDetail.partnerLabTask && (
                          <Box>
                            {labOrderDetail.partnerLabTask.status && (
                              <Box>
                                {labOrderDetail.partnerLabTask.status ===
                                  'in-progress' && (
                                  <Box>
                                    <WelloFormItemLabel title='Upload Reports' />
                                    <Box
                                      border={1}
                                      borderRadius={4}
                                      borderColor='lightGrey'
                                    >
                                      <UploadTile
                                        fileType={fileTypeReport}
                                        fileValidationType={validReportType}
                                        onFileChange={(files: any) => {
                                          for (
                                            let i = 0;
                                            i < files.length;
                                            i++
                                          ) {
                                            fileList.push(files[i].name)
                                          }
                                          setSelectedFileNames(fileList)
                                          setSelectedFiles(files)
                                        }}
                                      />
                                    </Box>
                                    {selectedFiles && (
                                      <Box py={1} px={1} width='60%'>
                                        {uploadReportSlice.uploadingSuccessful && (
                                          <Box>
                                            <Typography
                                              variant='subtitle1'
                                              color='initial'
                                            >
                                              {fileNames.length} reports has
                                              been uploaded
                                            </Typography>
                                          </Box>
                                        )}

                                        <Box py={1}>
                                          {fileNames.map((item: any) => (
                                            <Box
                                              p={0.2}
                                              key={`${item}`}
                                              border={1}
                                              borderRadius={4}
                                              borderColor='blue'
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                color='primary'
                                              >
                                                {item}
                                              </Typography>
                                            </Box>
                                          ))}
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                )}
                              </Box>
                            )}
                          </Box>
                        )}
                        <Box
                          flexDirection='column'
                          display='flex'
                          justifyContent='flex-start'
                          alignItems='flex-start'
                          className={classes.test}
                          p={1}
                        >
                          {uploadReportSlice.uploading && (
                            <Box>
                              <Box paddingY={0.5}>
                                <Typography variant='body1' color='primary'>
                                  {t(
                                    ' Please wait while report(s) are getting uploaded.'
                                  )}
                                </Typography>
                              </Box>

                              <div className={classes.root}>
                                <Box display='flex' alignItems='center'>
                                  <Box minWidth={30}>
                                    <Typography
                                      variant='body2'
                                      color='primary'
                                    >{`${Math.round(
                                      uploadOrDownloadCount
                                    )}%`}</Typography>
                                  </Box>
                                  <Box width='100%' mr={0.8}>
                                    <StyledLinearProgress
                                      variant='determinate'
                                      value={uploadOrDownloadCount}
                                    />
                                  </Box>
                                </Box>
                              </div>
                            </Box>
                          )}

                          {uploadReportSlice.uploading === false &&
                            uploadReportSlice.error === true && (
                              <Box display='flex' flexDirection='row'>
                                <Box p={0.5}>
                                  <img
                                    src={`${process.env.PUBLIC_URL}/Alerterror.png}`}
                                    alt='test'
                                    height={12.5}
                                    width={12}
                                  />
                                </Box>
                                <Box paddingY={0.5}>
                                  <Typography variant='body1' color='secondary'>
                                    {t(
                                      ' Something went wrong while uploading. '
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                        </Box>
                      </Box>
                    </Box>
                    {/* {labOrderDetail.homeServiceTask && ( */}
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box display='flex' flexDirection='column' width='100%'>
                        <Box py={1}>
                          {labOrderDetail.partnerLabTask && (
                            <Box>
                              {labOrderDetail.partnerLabTask.businessStatus
                                ?.coding &&
                                isPartnerLabStatusChangeable(
                                  labOrderDetail
                                ) && (
                                  <WelloSelectFHIRTest
                                    title='CHANGE STATUS'
                                    type={t('Select Test ')}
                                    selectedColumn={columns}
                                    preSelectedCoding={
                                      labOrderDetail.partnerLabTask
                                        .businessStatus.coding[0]
                                    }
                                    availableCodings={PartnerOrderStatusTypes}
                                    onChanged={(e) => {
                                      if (e) {
                                        setStatus(e)
                                      }
                                    }}
                                    textFieldProps={{
                                      size: 'small',
                                      placeholder: t('Select Test'),
                                      required: true,
                                    }}
                                  />
                                )}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    {/* )} */}
                    {labOrderDetail.partnerLabTask && (
                      <Box>
                        {labOrderDetail.partnerLabTask.businessStatus?.coding &&
                          isPartnerLabStatusChangeable(labOrderDetail) && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <WelloTextField
                                  title='REASON FOR STATUS CHANGE(Optional)'
                                  textProps={{
                                    id: `cancelReason${t}`,
                                    placeholder: 'Reason',
                                    type: 'text',
                                    rows: 4,
                                    rowsMax: 6,
                                    multiline: true,
                                    onChange: (changeDescription) => {
                                      setReason(changeDescription.target.value)
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          )}
                      </Box>
                    )}
                    {labOrderDetail.partnerLabTask && (
                      <Box>
                        {labOrderDetail.partnerLabTask.businessStatus?.coding &&
                          isPartnerLabStatusChangeable(labOrderDetail) && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='20%'
                              py={1}
                            >
                              <Button
                                onClick={() => {
                                  dispatch(
                                    requestForUpdatePartnerLabOrder(
                                      labOrderDetail,
                                      status,
                                      locationId,
                                      reason,
                                      docReferences
                                    )
                                  )
                                  onCloseClicked()
                                }}
                                variant='contained'
                                color='primary'
                                disableElevation
                                disabled={
                                  labOrderDetail.partnerLabTask?.status ===
                                  'completed'
                                }
                              >
                                Update
                              </Button>
                            </Box>
                          )}
                      </Box>
                    )}
                  </Box>
                  <Box
                    width='60%'
                    display='flex'
                    flexDirection='column'
                    px={2}
                    alignContent='center'
                  >
                    <Box display='flex' flexDirection='column' width='100%'>
                      <WelloFormItemLabel title='Patient Information' />
                      <Box border={1} borderRadius={4} borderColor='lightGrey'>
                        <PatientTile patient={labOrderDetail.patient} />
                      </Box>
                    </Box>

                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      py={1}
                    >
                      <WelloFormItemLabel title='Order Status' />
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        // marginY={1}
                        border={1}
                        borderRadius={4}
                        borderColor='lightGrey'
                      >
                        <OrderAmountSummaryTile
                          planDefinitions={labOrderDetail.tests}
                          showTitle={false}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
