/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slider,
  TextField,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { WelloOccupation } from 'models/WelloConditions'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addOccupationOPD,
  resetOccupationDetailsAdd,
} from 'redux/fhirMedicalResources/opd/addOccupation/occupationAddSlice'
import { RootState } from 'redux/rootReducer'
import { occupations, range } from 'utils/constants'
import { getColorBasedOnNumber } from 'utils/fhirResourcesHelper'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloLoadingIndicator } from 'wello-web-components'
import { OccupationTileForDisplay } from './occupationTile'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  onClose: () => void
  onAddictionAdded: () => void
}
export const AddoccupationOPD: React.FC<Props> = ({
  open,
  onClose,
  onAddictionAdded,
  fhirAppointmentDetails,
}) => {
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '40%',
    },
  }))
  const classes = useStyles()
  const [occupation, setOccupation] = useState<R4.ICoding[]>([])
  const [currentCondition, setCurrentCondition] = useState<WelloOccupation>({
    id: _.random(1, 10000000).toString(),
    phyHealth: 0,
    mentalHealth: 0,
  })
  const [addAnother, setAddAnother] = React.useState<boolean>(true)
  const [occupationsData, setOccupationData] =
    useState<R4.ICoding[]>(occupations)
  const [additionalNotes, setAdditionalNotes] = useState<string>()
  const [value, setValue] = React.useState<number>(0)
  const [physicalVal, setPhysicalVal] = React.useState<number>(0)

  const handleChange = (event: any, newValue: number | number[]) => {
    setCurrentCondition({
      ...currentCondition,
      mentalHealth: newValue as number,
    })
    setValue(newValue as number)
  }

  const [addedConditions, setAddedConditions] = useState<WelloOccupation[]>([])

  const handleChangeForPhysical = (event: any, newValue: number | number[]) => {
    setCurrentCondition({
      ...currentCondition,
      phyHealth: newValue as number,
    })
    setPhysicalVal(newValue as number)
  }

  //   function valuetext(value: number) {
  //     return `${value}°C`
  //   }

  const [text, setText] = useState<string>('')

  const dispatch = useDispatch()

  function addCurrentDetails(pushToSave?: boolean) {
    const tempMedication: WelloOccupation[] = [...addedConditions]
    const newMedication: WelloOccupation = {
      ...currentCondition,
    }

    tempMedication.push(newMedication)

    Promise.resolve().then(() => {
      setAddedConditions(tempMedication)
      resetDetails()
    })

    if (pushToSave) {
      dispatch(
        addOccupationOPD(
          fhirAppointmentDetails,
          tempMedication,
          additionalNotes
        )
      )
    }
  }

  function resetDetails() {
    setCurrentCondition({
      id: _.random(1, 10000000).toString(),
      condition: undefined,
      phyHealth: 0,
      mentalHealth: 0,
    })
  }

  const occupationalAddSlice = useSelector(
    (state: RootState) => state.occupationalAddSliceOPD
  )

  useEffect(() => {
    if (occupationalAddSlice.additionSuccessful) {
      onAddictionAdded()
      setAddedConditions([])
      dispatch(resetOccupationDetailsAdd())
      setOccupation([])
      setAdditionalNotes(undefined)
    }
  }, [dispatch, occupationalAddSlice, onAddictionAdded])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setOccupation([])
        setAddedConditions([])
        setAdditionalNotes(undefined)
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>
        Add Occupational Details
      </DialogTitle>
      <DialogContent dividers={true}>
        {occupationalAddSlice.adding && (
          <WelloLoadingIndicator message='Adding details' />
        )}

        {!occupationalAddSlice.adding && (
          <Box
            marginX={2}
            marginY={1}
            id={currentCondition.id}
            display='flex'
            flexDirection='column'
          >
            <Grid container direction='column' spacing={1}>
              <Grid item>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    id={currentCondition.id}
                  >
                    {addAnother && (
                      <ValueSetSelectSingle
                        key='occupation_Search'
                        title='Select Occupation'
                        url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-occupation-vs'
                        id='occupation_search'
                        fuzzySearch={true}
                        // alwaysShowAllOptions={true}
                        disabled={false}
                        onOptionSelected={(selectedKriya) => {
                          // setOccupation(changedValue)
                          if (selectedKriya) {
                            setCurrentCondition({
                              ...currentCondition,
                              condition: selectedKriya,
                            })
                          }
                        }}
                        helperText={undefined}
                        placeHolder='Search Occupation'
                      />
                      //   <Autocomplete
                      //     style={{
                      //       borderRadius: '4px',
                      //     }}
                      //     fullWidth
                      //     size='small'
                      //     id='occupation_search'
                      //     disableClearable
                      //     getOptionSelected={(option, valueData) =>
                      //       option.display === valueData.display
                      //     }
                      //     getOptionLabel={(option: R4.ICoding) =>
                      //       option.display ?? ''
                      //     }
                      //     options={occupationsData}
                      //     onChange={(e, changedValue, reason) => {
                      //       // setOccupation(changedValue)
                      //       setCurrentCondition({
                      //         ...currentCondition,
                      //         condition: changedValue,
                      //       })
                      //     }}
                      //     autoComplete
                      //     ChipProps={{
                      //       deleteIcon: (
                      //         <ClearOutlined
                      //           style={{
                      //             height: '15px',
                      //             color: 'white',
                      //           }}
                      //         />
                      //       ),
                      //       style: {
                      //         backgroundColor: kPrimaryLight,
                      //         borderRadius: '4px',
                      //       },
                      //     }}
                      //     renderInput={(params) => (
                      //       <TextField
                      //         placeholder='Select Occupation'
                      //         {...params}
                      //         fullWidth
                      //         id='occupation_search_text'
                      //         variant='outlined'
                      //         size='small'
                      //         InputProps={{
                      //           ...params.InputProps,
                      //           type: 'search',
                      //         }}
                      //       />
                      //     )}
                      //   />
                    )}
                    {addAnother === false && (
                      <ValueSetSelectSingle
                        key={`${currentCondition.id}false`}
                        title='Select Medication'
                        url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-occupation-vs'
                        id='occupation_search'
                        fuzzySearch={true}
                        alwaysShowAllOptions={true}
                        disabled={false}
                        onOptionSelected={(selectedKriya) => {
                          // setOccupation(changedValue)
                          if (selectedKriya) {
                            setCurrentCondition({
                              ...currentCondition,
                              condition: selectedKriya,
                            })
                          }
                        }}
                        helperText={undefined}
                      />
                      //   <Autocomplete
                      //     style={{
                      //       borderRadius: '4px',
                      //     }}
                      //     fullWidth
                      //     size='small'
                      //     id='occupation_search'
                      //     disableClearable
                      //     getOptionSelected={(option, valueData) =>
                      //       option.display === valueData.display
                      //     }
                      //     getOptionLabel={(option: R4.ICoding) =>
                      //       option.display ?? ''
                      //     }
                      //     options={occupationsData}
                      //     onChange={(e, changedValue, reason) => {
                      //       // setOccupation(changedValue)
                      //       setCurrentCondition({
                      //         ...currentCondition,
                      //         condition: changedValue,
                      //       })
                      //     }}
                      //     autoComplete
                      //     ChipProps={{
                      //       deleteIcon: (
                      //         <ClearOutlined
                      //           style={{
                      //             height: '15px',
                      //             color: 'white',
                      //           }}
                      //         />
                      //       ),
                      //       style: {
                      //         backgroundColor: kPrimaryLight,
                      //         borderRadius: '4px',
                      //       },
                      //     }}
                      //     renderInput={(params) => (
                      //       <TextField
                      //         placeholder='Select Occupation'
                      //         {...params}
                      //         fullWidth
                      //         id='occupation_search_text'
                      //         variant='outlined'
                      //         size='small'
                      //         InputProps={{
                      //           ...params.InputProps,
                      //           type: 'search',
                      //         }}
                      //       />
                      //     )}
                      //   />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Grid container direction='column'>
                  <Grid item>
                    <WelloFormItemLabel title='Mental stress level' />
                  </Grid>
                  <Box width='100%' px={1}>
                    <Slider
                      defaultValue={10}
                      step={10}
                      id='mental_stress'
                      marks={range}
                      style={getColorBasedOnNumber(
                        10,
                        100,
                        currentCondition.mentalHealth
                      )}
                      value={currentCondition.mentalHealth}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction='column'>
                  <Grid item>
                    <WelloFormItemLabel title='Physical stress level' />
                  </Grid>
                  <Box width='100%' px={1}>
                    <Slider
                      defaultValue={10}
                      step={10}
                      id='physical_stress'
                      marks={range}
                      style={getColorBasedOnNumber(
                        10,
                        100,
                        currentCondition.phyHealth
                      )}
                      value={currentCondition.phyHealth}
                      onChange={handleChangeForPhysical}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction='column'>
                  <Grid item>
                    <WelloFormItemLabel title='Additional Notes' />
                  </Grid>
                  <Grid item style={{ paddingBottom: '16px' }}>
                    <TextField
                      size='small'
                      fullWidth
                      multiline
                      id='occ_6'
                      type='number'
                      value={currentCondition.notes}
                      variant='outlined'
                      onChange={(event) => {
                        setCurrentCondition({
                          ...currentCondition,
                          notes: event.target.value,
                        })
                        setAdditionalNotes(event.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {addedConditions && addedConditions.length > 0 && (
              <OccupationTileForDisplay referrals={addedConditions} />
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box display='flex' flexDirection='row' paddingRight={4}>
          <Button
            onClick={() => {
              onClose()
              setOccupation([])
              setAdditionalNotes(undefined)
              setAddedConditions([])
            }}
            variant='outlined'
            disabled={occupationalAddSlice.adding}
            disableElevation
            id='occ_cancel'
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            onClick={() => {
              if (addAnother === true) {
                setAddAnother(false)
              } else {
                setAddAnother(true)
              }
              addCurrentDetails()
            }}
            variant='contained'
            color='primary'
            id='add_more'
            disableElevation
            disabled={currentCondition.condition === undefined}
          >
            Add More
          </Button>
          <Button
            onClick={() => {
              if (currentCondition.condition !== undefined) {
                addCurrentDetails(true)
              } else if (addedConditions.length > 0) {
                dispatch(
                  addOccupationOPD(
                    fhirAppointmentDetails,
                    addedConditions,
                    additionalNotes
                  )
                )
              }
            }}
            variant='contained'
            color='primary'
            id='occ_add'
            disableElevation
            disabled={
              (currentCondition.condition === undefined &&
                addedConditions.length === 0) ||
              occupationalAddSlice.adding
            }
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
