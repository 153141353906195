import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { requestWeeklyDietPlanDetails } from 'redux/consultation/dietPlan/existingWeeklyDietPlanSearch/existingWeeklyDietPlanSearchSlice'
import {
  addWeeklyDietPlansRecommendations,
  resetDietPlanAdditionState,
} from 'redux/consultation/recommendation_additions/diets_addition_slice/dietsAdditionSlice'

import { RootState } from 'redux/rootReducer'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { getCarePlanIdFromTaskOutput } from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import { getDisplayOfSystemFromCodableConcept } from 'utils/fhirResourcesHelper'
import { AddKriyaFromCPGHandler } from './add_kriya_pop_up'

const useStyles = makeStyles((theme) => ({
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))

interface AddWeeklyDietRecommendationProps {
  encounterId: string
  patient: R4.IPatient
  dietTasks: R4.ITask[]
  carePlanIdentifier?: string
}

export const AddWeeklyDietTaskRecommendationButton: React.FC<AddWeeklyDietRecommendationProps> =
  ({
    encounterId,
    patient,
    dietTasks,
    carePlanIdentifier,
  }: AddWeeklyDietRecommendationProps) => {
    const dietsAdditionSlice = useSelector(
      (state: RootState) => state.dietsAdditionSlice
    )

    console.log(dietTasks)
    const classes = useStyles()
    const dispatch = useDispatch()

    const [medStored, SetMedStored] = useState<R4.ITask[]>()

    const subscriptionStatus = useSelector(
      (state: RootState) => state.patientSubscriptionForUnitSlice
    )
    useEffect(() => {
      if (dietsAdditionSlice.additionSuccessful) {
        console.log('----added kriyas----', medStored)
      }
      dispatch(
        requestWeeklyDietPlanDetails({
          patientId: patient.id!,
          encounterId,
          practitionerRoleId: getCurrentUserPractitionerRoleDetails().id!,
          showPlanSuggestedBySameDoctor: true,
        })
      )
    }, [dietsAdditionSlice.additionSuccessful])

    useEffect(() => {
      if (dietsAdditionSlice.errorWhileAdding) {
        dispatch(resetDietPlanAdditionState())
      }
    }, [dietsAdditionSlice.errorWhileAdding])
    return (
      <Box>
        <Box flexDirection='row'>
          {dietsAdditionSlice.addingInstruction && (
            <Box height={25} width={25}>
              <CircularProgress size={20} />
            </Box>
          )}

          {dietsAdditionSlice.errorWhileAdding && medStored && (
            <Box flexDirection='row'>
              <Button
                variant='text'
                color='primary'
                id='med_retry'
                className={classes.buttonText}
                onClick={(e) => {
                  if (subscriptionStatus.tasks) {
                    const carePlanId = getCarePlanIdFromTaskOutput(
                      subscriptionStatus.tasks[0].task
                    )

                    if (carePlanId)
                      dispatch(
                        addWeeklyDietPlansRecommendations(
                          patient,
                          medStored,
                          encounterId,
                          carePlanIdentifier,
                          carePlanId
                        )
                      )
                  }
                  e.stopPropagation()
                }}
              >
                Retry
              </Button>

              {/* <Button
                variant='text'
                color='secondary'
                className={classes.rejectButtonText}
              >
                Reject
              </Button> */}
            </Box>
          )}
          {dietsAdditionSlice.additionSuccessful && (
            <Box flexDirection='row' px={2}>
              <Typography variant='subtitle1' color='initial'>
                Added
              </Typography>
            </Box>
          )}
          {!dietsAdditionSlice.additionSuccessful &&
            !dietsAdditionSlice.errorWhileAdding &&
            !dietsAdditionSlice.addingInstruction && (
              <Box flexDirection='row'>
                <Button
                  variant='text'
                  color='primary'
                  className={classes.buttonText}
                  disabled={dietTasks === undefined || dietTasks.length === 0}
                  onClick={(e) => {
                    if (
                      subscriptionStatus.subscriptionStatus === 'interested' ||
                      subscriptionStatus.subscriptionStatus === 'active'
                    ) {
                      console.log('----selected tasks----', dietTasks)
                      if (subscriptionStatus.tasks) {
                        const carePlanId = getCarePlanIdFromTaskOutput(
                          subscriptionStatus.tasks[0].task
                        )

                        if (carePlanId)
                          dispatch(
                            addWeeklyDietPlansRecommendations(
                              patient,
                              dietTasks,
                              encounterId,
                              carePlanIdentifier,
                              carePlanId
                            )
                          )
                      }
                      e.stopPropagation()
                    } else {
                      dispatch(
                        showErrorAlert(
                          'Please subscribe to the plan to add weekly diet plan'
                        )
                      )
                    }
                    e.stopPropagation()
                  }}
                  id='diet_finalize'
                >
                  Add
                </Button>

                {/*   <Button
                  variant='text'
                  color='secondary'
                  className={classes.rejectButtonText}
                >
                  Reject
                </Button> */}
              </Box>
            )}
        </Box>
      </Box>
    )
  }
