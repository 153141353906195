import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import {
  DoctorBase,
  DoctorProfileService,
  ErrorResponse,
  SearchDocResponse,
} from 'lib/openApi'
import { OIDCUser } from 'models/oidcUser'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitDetails,
  getRoleCount,
  getUserCurrentRole,
  isMedicalServiceProvider,
  isOrgAdmin,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getOIDCUserObject } from 'utils/authHelpers'
import { getDefaultCodeOfSystemFromCodableConcept } from 'utils/fhirResourcesHelper'
import {
  getPractitionersWithRoleObject,
  getPractitionersWithUnitDetails,
} from 'utils/fhirResoureHelpers/practitioner_and_role_helpers'
import { logger } from 'utils/logger'
import { PractitionerSearchStatus } from './practitionerDetailsStatus'

const initialState: PractitionerSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const unitPractitionerDetailsSlice = createSlice({
  name: 'unitPractitionerDetailsSlice',
  initialState,
  reducers: {
    searchingDoctorDetails(
      state,
      action: PayloadAction<PractitionerSearchStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false

      state.errorMessage = ''
      state.resultsAvailable = false
      state.doctorRoleList = undefined
    },

    searchResults(state, action: PayloadAction<PractitionerSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false

      state.errorMessage = ''
      state.resultsAvailable = true
      state.doctorRoleList = action.payload.doctorRoleList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<PractitionerSearchStatus>
    ) {
      state.error = false
      state.searching = false

      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.doctorRoleList = undefined
    },

    errorWhileSearching(
      state,
      action: PayloadAction<PractitionerSearchStatus>
    ) {
      state.error = true
      state.searching = false

      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.doctorRoleList = undefined
    },

    resetPractDetails(state, action: PayloadAction<PractitionerSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false

      state.errorMessage = ''
      state.resultsAvailable = false
      state.doctorRoleList = undefined
    },
  },
})

export const searchPractitionersDetailsWithUnit =
  (unitId: string, role: string, mainOrgs?: string[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PractitionerSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(unitPractitionerDetailsSlice.actions.searchingDoctorDetails(state))
    try {
      const searchParameters: any = {
        _count: 50,
      }
      const fhirClient: MasterFhirClient = new MasterFhirClient()
      let response: any
      if (mainOrgs === undefined) {
        response =
          await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
            `PractitionerRole?_include=PractitionerRole:practitioner&organization=Organization/${unitId}&role=${role}`,
            searchParameters
          )
      } else {
        response =
          await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
            `PractitionerRole?_include=PractitionerRole:practitioner&organization=${mainOrgs}&role=${role}`,
            searchParameters
          )
      }

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const practitionerRolesResponses: R4.IBundle =
          relatedFhirDecodeRes.right
        if (practitionerRolesResponses.total) {
          if (practitionerRolesResponses.total > 0) {
            if (practitionerRolesResponses.entry) {
              const practitionerWithRoleList: PractitionerWithRole[] =
                getPractitionersWithUnitDetails(practitionerRolesResponses) ??
                []
              state.doctorRoleList = practitionerWithRoleList
              state.searching = false
              state.error = false
              dispatch(
                unitPractitionerDetailsSlice.actions.searchResults(state)
              )
              return
            }
            state.resultsAvailable = false
            state.searching = false
            state.error = false
            state.noResultsAvailable = true
            dispatch(
              unitPractitionerDetailsSlice.actions.noDataFoundForSearch(state)
            )
            return
          }
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(
          unitPractitionerDetailsSlice.actions.noDataFoundForSearch(state)
        )
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(unitPractitionerDetailsSlice.actions.errorWhileSearching(state))
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(unitPractitionerDetailsSlice.actions.errorWhileSearching(state))
    }
  }

export function getPractitionerRoleObject(item: R4.IPractitionerRole) {
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    gender: '',
    name: item.practitioner?.display ?? '',
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: false,
  }
  return val
}

export const resetStateForWelloAdminPractDetails =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: PractitionerSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      doctorRoleList: undefined,
    }
    dispatch(unitPractitionerDetailsSlice.actions.resetPractDetails(state))
  }

export default unitPractitionerDetailsSlice.reducer
