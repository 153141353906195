import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CohortDisplayData } from 'models/cohortDisplayData'
import { CohortDisplayWithCount } from 'models/cohortDisplayWithCount'
import { Filters } from 'models/filters'
import { AppDispatch, AppThunk } from 'redux/store'
import { CohortClient } from 'services/cohortServiceClient'
import { OutComeClient } from 'services/outComeClient'
import {
  getAnalysisFilter,
  getCohortData,
  getCohortDataForSinglePercentile,
  getCohortDataWithGrouping,
  getCohortFilter,
  getStrataName,
  getCohortDataForFilterForSinglePercentileForMultiGraph,
} from 'utils/cohort/cohortUtils'
import { pilotDataResponse } from 'utils/constants'
import { logger } from 'utils/logger'
import { CohortSearchStatus } from './cohortSearchDataStatus'

const initialState: CohortSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const cohortOptionsSlice = createSlice({
  name: 'cohorts',
  initialState,
  reducers: {
    searchingCohorts(state, action: PayloadAction<CohortSearchStatus>) {},

    searchResults(state, action: PayloadAction<CohortSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.cohortOptions = action.payload.cohortOptions
      state.analysisFilter = action.payload.analysisFilter
      state.cohortFilter = action.payload.cohortFilter
      state.strataFilter = action.payload.strataFilter
      state.fullDataSet = action.payload.fullDataSet
    },

    noDataFoundForSearch(state, action: PayloadAction<CohortSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.cohortOptions = action.payload.cohortOptions
      state.analysisFilter = action.payload.analysisFilter
      state.cohortFilter = action.payload.cohortFilter
      state.strataFilter = action.payload.strataFilter
      state.fullDataSet = action.payload.fullDataSet
    },

    errorWhileSearching(state, action: PayloadAction<CohortSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.cohortOptions = action.payload.cohortOptions
      state.analysisFilter = action.payload.analysisFilter
      state.cohortFilter = action.payload.cohortFilter
      state.strataFilter = action.payload.strataFilter
      state.fullDataSet = action.payload.fullDataSet
    },
    resetState(state, action: PayloadAction<CohortSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.cohortOptions = undefined
      state.analysisFilter = undefined
      state.cohortFilter = undefined
      state.strataFilter = undefined
      state.fullDataSet = undefined
    },
  },
})

export const resetCohortSearch =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: CohortSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      cohortOptions: undefined,
    }
    dispatch(cohortOptionsSlice.actions.resetState(state))
  }

export const searchingCohorts =
  (displayType: string, percentile: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: CohortSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(cohortOptionsSlice.actions.errorWhileSearching(errorSearchPatient))
    try {
      const fhirClient: OutComeClient = new OutComeClient()
      const response: any = await fhirClient.doGetResource(
        `/cohort/interventions_and_outcomes`
      )
      if (response.analysis_ids.length > 1) {
        const analysisFilter: Filters[] = getAnalysisFilter(response)
        // const cohortData: CohortDisplayData[] = getCohortData(
        //   pilotDataResponse.result,
        //   analysisFilter
        // )
        const strataFilter: Filters[] = getStrataName(response)

        const fillteredData: string[] = [strataFilter[3].id]

        const analyseFilter: string[] = []
        for (let i = 0; i < analysisFilter.length; i++) {
          analyseFilter.push(analysisFilter[i].id)
        }
        const singleLineBar: CohortDisplayWithCount[] =
          getCohortDataForFilterForSinglePercentileForMultiGraph(
            response.result,
            analyseFilter,
            analysisFilter,
            fillteredData,
            strataFilter,
            percentile
          )
        console.log(singleLineBar)
        const cohortFilter: Filters[] = getCohortFilter(response.result)

        const searchPatientResult: CohortSearchStatus = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          cohortOptions: singleLineBar,
          analysisFilter,
          strataFilter,
          cohortFilter,
          fullDataSet: response.result,
        }
        dispatch(cohortOptionsSlice.actions.searchResults(searchPatientResult))
        return
      }

      const noSearchResults: CohortSearchStatus = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(cohortOptionsSlice.actions.noDataFoundForSearch(noSearchResults))
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: CohortSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        cohortOptionsSlice.actions.errorWhileSearching(errorWhileSearchPatient)
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(cohortOptionsSlice.actions.resetState(initialState))
}

export default cohortOptionsSlice.reducer
