export const PROFILE_SELF_DESCRIPTION_URL =
  'http://wellopathy.com/fhir/india/core/ExtUrl/profileDescription'
export const QUALIFICATION_SPECIALIZATION_URL =
  'http://wellopathy.com/fhir/india/core/qualificationSpecialization'

export const ORG_LOGO_SPECIALIZATION_URL =
  'http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo'

export const ORG_LOCATION_SLOT_DURATION =
  'http://wellopathy.com/fhir/india/core/ExtUrl/locationSlotDuration'
