import { Typography } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  withStyles,
} from '@material-ui/core/styles'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { kSelectedTabBackground } from 'configs/styles/muiThemes'
import React from 'react'
import { useDispatch } from 'react-redux'
import { updatingScheduleDetails } from 'redux/schedule/UpdateScheuleHandler/updateSchedule'
import { getBulkScheduleUpdateBundle } from 'utils/fhirResoureHelpers/fhirScheduleDeleteHelper'
import { CancelSchedule } from './schedule/CancelSchedule'

export interface ToggleOption {
  value: string
  displayText: string
}

export interface ToggleOptionProps {
  options: ToggleOption[]
  preSelectedValue?: string
  onSelectionChanged: (option: string) => void
  schedules: any
  existingSchedules: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: `1px solid ${theme.palette.divider}`,
    },
    divider: {
      margin: theme.spacing(1, 0.5),
    },
  })
)

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup)

export default function CustomizedDividers(props: ToggleOptionProps) {
  const [value, setValue] = React.useState(
    props.preSelectedValue ?? props.options[0].value
  )
  const [selectMenu, setSelectedMenu] = React.useState(false)
  const [selectedText, setSelectedText] = React.useState('')
  const dispatch = useDispatch()

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setSelectedText(newAlignment)
      if (props.schedules.length > 0) {
        setSelectedMenu(true)
      } else {
        props.onSelectionChanged(newAlignment)
        setValue(newAlignment)
      }
    }
  }

  const classes = useStyles()

  const defaultBackgrundColor = useTheme().palette.background.default
  const paperBackGroundColor = useTheme().palette.background.paper

  return (
    <div>
      <Paper elevation={0} className={classes.paper}>
        <StyledToggleButtonGroup
          size='small'
          value={value}
          exclusive
          onChange={handleAlignment}
          aria-label='text alignment'
          style={{
            backgroundColor: defaultBackgrundColor,
            margin: '0px',
            padding: '0px',
          }}
          id={value}
        >
          {props.options.map((option) => (
            <ToggleButton
              id={value}
              key={`tg_button_key${option.value}`}
              value={option.value}
              aria-label='left aligned'
              style={{
                background:
                  option.value === value
                    ? paperBackGroundColor
                    : defaultBackgrundColor,
              }}
            >
              <Typography
                variant='subtitle2'
                style={{
                  color: kSelectedTabBackground,
                }}
              >
                {option.displayText}
              </Typography>
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </Paper>
      {selectMenu === true && (
        <CancelSchedule
          open={selectMenu}
          onClose={() => {
            setSelectedMenu(false)
          }}
          onDiscard={() => {
            props.onSelectionChanged(selectedText)
            setValue(selectedText)
            setSelectedText(selectedText)
            setSelectedMenu(false)
            if (props.existingSchedules.length > 0) {
              const scheduleBundle = getBulkScheduleUpdateBundle(
                props.existingSchedules
              )
              dispatch(updatingScheduleDetails(scheduleBundle))
            }
          }}
        />
      )}
    </div>
  )
}
