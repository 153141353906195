import {
  Box,
  Grid,
  Typography,
  useTheme,
  Tooltip,
  IconButton,
  Link,
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { Add, Done } from '@material-ui/icons'
import React from 'react'

export interface SubPlanProps {
  subPlanName: string
  subString: string
  onClicked?: () => void

  isEditAllowed?: boolean
  bottomWidget?: React.ReactNode
  isAddDisplay?: boolean
}

interface NextStepsOfConsultationProps {
  planName: string
  subPlans: SubPlanProps[]
}

export const NextStepMultipleSubPlanForSplit: React.FC<NextStepsOfConsultationProps> =
  ({ planName, subPlans }: NextStepsOfConsultationProps) => {
    const primary = useTheme().palette.primary.main

    return (
      <Box
        display='flex'
        borderColor='lightGrey'
        width='100%'
        flexDirection='column'
        flexGrow
      >
        <Box display='flex' flexDirection='row' width='100%' flexGrow py={1}>
          <Grid container style={{ padding: '0px', margin: '0px' }}>
            <Grid item xs={4} style={{ alignSelf: 'center' }}>
              <Typography color='initial' variant='h6' id='planName_label'>
                {planName}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {subPlans.map((subPlan) => (
          <SubPlan {...subPlan} key={subPlan.subPlanName} />
        ))}
      </Box>
    )
  }

const SubPlan: React.FC<SubPlanProps> = (props: SubPlanProps) => {
  const primary = useTheme().palette.primary.main

  return (
    <Box display='flex' flexDirection='column' width='100%' flexGrow>
      <Grid container style={{ padding: '0px', margin: '0px' }}>
        {!props.isAddDisplay && (
          <Grid item xs={4} style={{ alignSelf: 'flex-start' }}>
            <Box paddingLeft={1}>
              <Typography variant='subtitle1' id='planName_label'>
                {props.subPlanName}
              </Typography>
            </Box>
          </Grid>
        )}
        {props.subString && props.subString.length > 0 && (
          <Grid item xs={8} style={{ alignSelf: 'center' }}>
            <Typography variant='subtitle1' id='sub+planName_label'>
              {props.subString}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Box
        display='flex'
        flexDirection='row'
        flexGrow
        justifyContent='flex-start'
        paddingTop={0.5}
        paddingBottom={1}
        p={1}
        style={{ alignSelf: 'flex-start' }}
      >
        {props.isAddDisplay && (
          <Tooltip title='Add' aria-label='add'>
            <IconButton
              aria-label={`btn_add_${props.subPlanName}`}
              color='primary'
              id={`${props.subPlanName}_icon`}
              onClick={props.onClicked}
              disabled={!props.isEditAllowed}
              style={{ padding: 0 }}
            >
              <AddCircleIcon
                style={{ height: '16px', padding: 0 }}
                color='primary'
              />
              <Typography
                variant='subtitle2'
                color='primary'
                component={Link}
                id={`${props.subPlanName}_link`}
                style={{ fontSize: 13 }}
              >
                {' '}
                {props.subPlanName}
              </Typography>
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {props.bottomWidget && (
        <Box display='flex' flexDirection='row' width='100%' flexGrow>
          {props.bottomWidget}
        </Box>
      )}
    </Box>
  )
}
