import { Box, TextField, Typography, TextFieldProps } from '@material-ui/core'

import React from 'react'
import { WelloFormItemLabel } from './WelloFormItemLabel'

interface WelloEmailFieldProps {
  title: string
  textProps: TextFieldProps
}

export const WelloEmail: React.FunctionComponent<WelloEmailFieldProps> = (
  props: WelloEmailFieldProps
) => (
  <Box display='flex' flexDirection='column' flexGrow={1}>
    <WelloFormItemLabel title={props.title} />
    <Box>
      <TextField
        variant='outlined'
        size='small'
        fullWidth
        type='email'
        {...props.textProps}
      />
    </Box>
  </Box>
)
