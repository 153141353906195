import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Card, CardContent, Typography } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import {
  getIdentifierValueBySystem,
  getPassportEndDate,
  getPassportStartDate,
} from 'utils/fhirResourcesHelper'

interface Props {
  patient: R4.IPatient
}

export const PassportTile: React.FC<Props> = ({ patient }: Props) => (
  <Box width='100%'>
    {patient && (
      <Card
        elevation={0}
        style={{
          cursor: 'pointer',
        }}
      >
        <CardContent
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 4,
            paddingRight: 4,
          }}
        >
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            paddingBottom={1}
          >
            <Box display='flex' flexDirection='row' paddingTop={2} width='30%'>
              <Box p={0.5} width='40%'>
                <Typography
                  variant='subtitle2'
                  style={{
                    fontSize: 10,
                    textTransform: 'uppercase',
                  }}
                >
                  Issue Date
                </Typography>
              </Box>
              <Box
                boxShadow={3}
                borderRadius={4}
                height={25}
                width='50%'
                paddingLeft={1}
              >
                <Typography
                  variant='subtitle2'
                  color='primary'
                  style={{
                    textTransform: 'uppercase',
                    paddingTop: 3,
                  }}
                >
                  {moment(
                    getPassportStartDate(
                      patient.extension,
                      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
                    )
                  ).format('DD-MM-YYYY')}
                </Typography>
              </Box>
            </Box>

            <Box display='flex' flexDirection='row' paddingTop={2} width='30%'>
              <Box p={0.5} width='45%'>
                <Typography
                  variant='subtitle2'
                  style={{
                    fontSize: 10,
                    textTransform: 'uppercase',
                  }}
                >
                  Expiry Date
                </Typography>
              </Box>
              <Box
                boxShadow={3}
                borderRadius={4}
                height={25}
                width='50%'
                px={1}
              >
                <Typography
                  variant='subtitle2'
                  color='primary'
                  style={{
                    textTransform: 'uppercase',
                    paddingTop: 3,
                  }}
                >
                  {moment(
                    getPassportEndDate(
                      patient.extension,
                      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
                    )
                  ).format('DD-MM-YYYY')}
                </Typography>
              </Box>
            </Box>

            <Box display='flex' flexDirection='row' paddingTop={2} width='40%'>
              <Box p={0.5} width='52%'>
                <Typography
                  variant='subtitle2'
                  style={{
                    fontSize: 10,
                    textTransform: 'uppercase',
                  }}
                >
                  Passport Number
                </Typography>
              </Box>
              <Box
                boxShadow={3}
                borderRadius={4}
                height={25}
                minWidth='38%'
                maxWidth='48%'
                px={1}
              >
                <Typography
                  variant='subtitle2'
                  color='primary'
                  style={{
                    textTransform: 'uppercase',
                    paddingTop: 3,
                  }}
                >
                  {
                    getIdentifierValueBySystem(
                      patient.identifier ?? [],
                      'address-proof'
                    )!
                  }
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* </Grid>
          </Grid> */}
        </CardContent>
      </Card>
    )}
  </Box>
)
