import { R4 } from '@ahryman40k/ts-fhir-types'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { logger } from 'utils/logger'

export async function getAvailableRoomsForRoomType(
  roomType: string
): Promise<R4.IBundle | FHIRErrorResponses> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const organization: R4.IOrganization = getCurrentUserUnitDetails()
  const searchParameters: any = {
    organization: organization.id ?? '',
    _count: 200,
  }
  const response: any = await fhirClient.doGetResource(
    `/Location?name:contains=${roomType}&location-physical-type=ro&operational-status=U`,
    searchParameters
  )
  if (response.type === 'FHIRErrorResponses') {
    return response
  }
  const bundleRes: R4.IBundle = response as R4.IBundle
  if (bundleRes.entry && bundleRes.entry.length > 0) {
    return bundleRes
  }

  return {
    status: 400,
    data: {},
    displayText: 'Rooms List is not available',
  }
}

export async function getAvailableRoomsForRoomTypesParsed(
  roomType: string
): Promise<R4.ILocation[]> {
  const resBundle: R4.IBundle | FHIRErrorResponses =
    await getAvailableRoomsForRoomType(roomType)

  try {
    const res: R4.IBundle = resBundle as R4.IBundle
    if (res.entry && res.entry.length > 0) {
      const resLocations: R4.ILocation[] = res.entry.map(
        (e) => e.resource as R4.ILocation
      )
      return resLocations
    }
  } catch (error) {
    logger.error(error)
  }
  return []
}

export async function getAvailableBedsForRoom(
  room: string,
  isAllBeds?: boolean
): Promise<R4.IBundle | FHIRErrorResponses> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  let searchParameters: any = {
    partof: `Location/${room}`,
    'location-physical-type': 'bd',
  }
  if (isAllBeds !== true) {
    searchParameters = {
      partof: `Location/${room}`,
      'location-physical-type': 'bd',
      'operational-status': 'U',
    }
  }

  const response: any = await fhirClient.doGetResource(
    `/Location`,
    searchParameters
  )
  if (response.type === 'FHIRErrorResponses') {
    return response
  }
  const bundleRes: R4.IBundle = response as R4.IBundle
  if (bundleRes.entry && bundleRes.entry.length > 0) {
    return bundleRes
  }

  return {
    status: 400,
    data: {},
    displayText: 'Rooms List is not available',
  }
}

export async function getAvailableBedsForRoomParsed(
  roomId: string,
  isAll?: boolean
): Promise<R4.ILocation[]> {
  const resBundle: R4.IBundle | FHIRErrorResponses =
    await getAvailableBedsForRoom(roomId, isAll)

  try {
    const res: R4.IBundle = resBundle as R4.IBundle
    if (res.entry && res.entry.length > 0) {
      const resLocations: R4.ILocation[] = res.entry.map(
        (e) => e.resource as R4.ILocation
      )
      return resLocations
    }
  } catch (error) {
    logger.error(error)
  }
  return []
}
