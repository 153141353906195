import {
  Box,
  CircularProgress,
  Typography,
  useTheme,
  IconButton,
} from '@material-ui/core'
import { Visibility } from '@material-ui/icons'

import { CPGForTable } from 'models/administration/cpgDetailsForTable'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  getCPSubscriptionsListForWPAdmin,
  getCPSubscriptionsOrgAndUnitAdmin,
  offerSubscriptionInUnit,
  removeOfferSubscriptionInUnit,
} from 'utils/admisnistration/cpg/cpgListHelper'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'
import { CPGListTable } from './cpgListTable'
import { SubscriptionOfferingsDetails } from './cpSubscriptionDetails'

interface Props {
  isReadonly?: boolean
}

export const CPSubscriptionPlanListPageForOUAdmin: React.FC<Props> = ({
  isReadonly: split,
}: Props) => {
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [updateCPsStatus, setUpdateCPsStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [showOfferConfirmation, setShowOfferConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  const [showOfferingDetails, setShowOfferingDetails] = useState<{
    open: boolean
    cpg?: CPGForTable
  }>({
    open: false,
  })

  const [showOfferDeleteConfirmation, setShowOfferDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  const [cpsList, setCPSList] = useState<CPGForTable[]>()

  function fetchCPGLists() {
    setUpdateStatus({ requesting: true })
    getCPSubscriptionsOrgAndUnitAdmin().then((e) => {
      if (e !== false) {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        console.log('----------------updated cpg list----------------', e)
        setCPSList(e as CPGForTable[])
      } else {
        setUpdateStatus({
          requesting: false,
          requestError: false,
        })
      }
    })
  }

  function offerCPS(rawCPG: CPGForTable) {
    setUpdateCPsStatus({ requesting: true })
    setCPSList([])
    offerSubscriptionInUnit(rawCPG.rawCpg.id!).then((e) => {
      if (e !== false) {
        setUpdateCPsStatus({
          requesting: false,
          requestSuccess: true,
        })
        fetchCPGLists()
      } else {
        setUpdateCPsStatus({
          requesting: false,
          requestError: false,
        })

        fetchCPGLists()
      }
    })
  }

  function removeCPS(rawCPG: CPGForTable) {
    setUpdateCPsStatus({ requesting: true })
    setCPSList([])
    removeOfferSubscriptionInUnit(
      rawCPG.offeredCpgPlanDefinition?.id ?? ''
    ).then((e) => {
      if (e !== false) {
        setUpdateCPsStatus({
          requesting: false,
          requestSuccess: true,
        })
        fetchCPGLists()
      } else {
        setUpdateCPsStatus({
          requesting: false,
          requestError: false,
        })

        fetchCPGLists()
      }
    })
  }

  useEffect(() => {
    fetchCPGLists()
  }, [])

  return (
    <Box
      display='flex'
      flexDirection='column'
      width='100%'
      height='100%'
      alignContent='center'
      justifyContent='center'
      alignItems='center'
      alignSelf='center'
    >
      {updateStatus.requesting && <CircularProgress size={15} />}
      {updateStatus.requestError && (
        <Box
          marginY={1}
          display='flex'
          flexDirection='column'
          width='100%'
          alignContent='center'
          justifyContent='center'
          alignItems='center'
          alignSelf='center'
        >
          <Typography variant='subtitle2' color='error'>
            {' '}
            Error while fetching CPG Details
          </Typography>
        </Box>
      )}
      {updateStatus.requestSuccess &&
        cpsList &&
        cpsList.length > 0 &&
        !updateCPsStatus.requesting && (
          <Box width='100%' display='flex' flexDirection='column' height='100%'>
            <CPGListTable
              key={cpsList.map((e) => e.rawCpg.id).join('')}
              medicationsList={cpsList}
              onOptForOfferClick={(cpg: CPGForTable) => {
                setShowOfferConfirmation({
                  open: true,
                  onContinueActionClicked: () => {
                    setShowOfferConfirmation({ open: false })
                    offerCPS(cpg)
                  },
                  onCancelActionClicked: () => {
                    setShowOfferConfirmation({ open: false })
                  },
                  displayText: `Are you sure you want to offer ${cpg.cpgName} ?`,
                  continueActionButtonText: 'Offer',
                  cancelActionButtonText: 'Cancel',
                  title: 'Offer CPG',
                })
              }}
              onRemoveFromOfferClick={(cpg: CPGForTable) => {
                setShowOfferDeleteConfirmation({
                  open: true,
                  onContinueActionClicked: () => {
                    setShowOfferDeleteConfirmation({ open: false })
                    removeCPS(cpg)
                  },
                  onCancelActionClicked: () => {
                    setShowOfferDeleteConfirmation({ open: false })
                  },
                  displayText: `Are you sure you want to remove ${cpg.cpgName} from the offerings?`,
                  continueActionButtonText: 'Yes',
                  cancelActionButtonText: 'No',
                  title: 'Confirmation',
                })
              }}
              onActionClick={(cpg: CPGForTable) => {
                setShowOfferingDetails({
                  open: true,
                  cpg,
                })
              }}
              actionComponent={
                <Box display='flex' flexDirection='row'>
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    justifyContent='center'
                    marginRight={2}
                  >
                    <IconButton onClick={() => {}} color='primary'>
                      <Visibility />
                    </IconButton>
                  </Box>
                </Box>
              }
            />
          </Box>
        )}

      {updateCPsStatus.requesting && (
        <Box
          width='100%'
          display='flex'
          flexDirection='column'
          alignContent='center'
          justifyContent='center'
          alignItems='center'
        >
          <Typography variant='subtitle2' color='primary'>
            {' '}
            Updating CPG Offering details
          </Typography>
        </Box>
      )}

      {updateStatus.requestSuccess &&
        cpsList &&
        cpsList.length === 0 &&
        !updateCPsStatus.requesting && (
          <Box
            width='100%'
            display='flex'
            flexDirection='column'
            alignContent='center'
            justifyContent='center'
            alignItems='center'
          >
            <Typography variant='subtitle2' color='error'>
              {' '}
              No Care Plans available
            </Typography>
          </Box>
        )}

      {showOfferConfirmation.open && (
        <ConfirmationDialog
          {...showOfferConfirmation}
          id='pop_up_offer_confirmation'
        />
      )}

      {showOfferDeleteConfirmation.open && (
        <ConfirmationDialog
          {...showOfferDeleteConfirmation}
          id='pop_up_remove_confirmation'
        />
      )}

      {showOfferingDetails.open && (
        <SubscriptionOfferingsDetails
          open={showOfferingDetails.open}
          offer={showOfferingDetails.cpg}
          key={showOfferingDetails.cpg?.cpgName ?? ''}
          onClose={() => {
            setShowOfferingDetails({ open: false })
          }}
        />
      )}
    </Box>
  )
}
