import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import {
  getDietDetailsReport,
  getTreatmentDetailsReport,
} from 'utils/fhirResoureHelpers/reportHelper'

export interface ReportSelectionDialogProps {
  open: boolean
  onContinueActionClicked?: () => void
  onCancelActionClicked?: () => void
  displayText?: string
  title?: string
  continueActionButtonText?: string
  cancelActionButtonText?: string
}

export const ReportSelectionDialog: React.FC<ReportSelectionDialogProps> = ({
  open,
  onContinueActionClicked,
  onCancelActionClicked,
  displayText = 'Continue Delete',
  title = 'Generate Reports',
  continueActionButtonText: doneButtonText = 'Generate',
  cancelActionButtonText: cancelButtonText = 'Cancel',
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedReportType, setSelectedReportType] =
    useState<'treatment' | 'diet'>()
  const [selectedGenderType, setSelectedGenderType] =
    useState<'male' | 'female'>()
  const [selectedDate, setselectedDate] = useState<'today' | 'tomo'>()

  const [generatingStatus, setGeneratingStatus] =
    useState<NetworkRequestStatus>({
      initial: true,
    })

  function disableGenerateButton() {
    if (selectedReportType === undefined) {
      return true
    }

    if (selectedDate === undefined) {
      return true
    }

    if (
      selectedReportType === 'treatment' &&
      selectedGenderType === undefined
    ) {
      return true
    }
    return false
  }

  function handleInvoiceGenerationButton() {
    setGeneratingStatus({
      requesting: true,
    })

    if (
      selectedReportType === 'treatment' &&
      selectedGenderType &&
      selectedDate
    ) {
      getTreatmentDetailsReport(
        selectedGenderType === 'male' ? 'male' : 'female',
        selectedDate === 'today'
          ? moment(new Date()).format('YYYY-MM-DD')
          : moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
      ).then((e) => {
        if (e) {
          setGeneratingStatus({
            requesting: false,
            requestSuccess: true,
          })
        } else {
          setGeneratingStatus({
            requesting: false,
            requestError: true,
          })
          dispatch(showErrorAlert('Treatment Report is not available'))
        }
      })
    }
    if (selectedReportType === 'diet' && selectedDate) {
      getDietDetailsReport(
        selectedDate === 'today'
          ? moment(new Date()).format('YYYY-MM-DD')
          : moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
      ).then((e) => {
        if (e) {
          setGeneratingStatus({
            requesting: false,
            requestSuccess: true,
          })
        } else {
          setGeneratingStatus({
            requesting: false,
            requestError: true,
          })
          dispatch(showErrorAlert('Diet Report is not available'))
        }
      })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (onCancelActionClicked) onCancelActionClicked()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>{t(`${title}`)}</DialogTitle>
      <DialogContent dividers={true}>
        <Box
          display='flex'
          flexDirection='column'
          alignContent='start'
          alignItems='start'
          justifyContent='center'
        >
          <Box display='flex' flexDirection='row' alignContent='start'>
            <FormControl component='fieldset'>
              <FormLabel id='demo-controlled-radio-buttons-group'>
                <Typography variant='subtitle1' color='initial'>
                  Select Report Type
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-label='position'
                name='position'
                onChange={(e) => {
                  setSelectedReportType(e.target.value as 'treatment' | 'diet')
                }}
              >
                <FormControlLabel
                  value='treatment'
                  style={{ width: '80px' }}
                  disabled={generatingStatus.requesting}
                  control={<Radio color='primary' />}
                  label={<Typography variant='body1'>Treatment</Typography>}
                  labelPlacement='bottom'
                />
                <FormControlLabel
                  value='diet'
                  style={{ width: '80px' }}
                  disabled={generatingStatus.requesting}
                  control={<Radio color='primary' />}
                  label={<Typography variant='body1'>Diet</Typography>}
                  labelPlacement='bottom'
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box display='flex' flexDirection='row' pt={2} alignContent='start'>
            {' '}
            <FormControl component='fieldset'>
              <FormLabel id='demo-controlled-radio-buttons-group'>
                <Typography variant='subtitle1' color='initial'>
                  Select Day
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-label='position'
                name='position'
                onChange={(e) => {
                  setselectedDate(e.target.value as 'today' | 'tomo')
                }}
              >
                <FormControlLabel
                  value='today'
                  style={{ width: '80px' }}
                  disabled={generatingStatus.requesting}
                  control={
                    <Radio
                      color='primary'
                      disabled={generatingStatus.requesting}
                    />
                  }
                  label={<Typography variant='body1'>Today</Typography>}
                  labelPlacement='bottom'
                />
                <FormControlLabel
                  value='tomo'
                  style={{ width: '80px' }}
                  disabled={generatingStatus.requesting}
                  control={<Radio color='primary' />}
                  label={<Typography variant='body1'>Tomorrow</Typography>}
                  labelPlacement='bottom'
                />
              </RadioGroup>
            </FormControl>
          </Box>

          {selectedReportType === 'treatment' && (
            <Box display='flex' flexDirection='row' pt={2} alignContent='start'>
              <FormControl component='fieldset'>
                <FormLabel id='demo-controlled-radio-buttons-group'>
                  <Typography variant='subtitle1' color='initial'>
                    Select Gender
                  </Typography>
                </FormLabel>
                <RadioGroup
                  row
                  aria-label='position'
                  name='position'
                  onChange={(e) => {
                    setSelectedGenderType(e.target.value as 'male' | 'female')
                  }}
                >
                  <FormControlLabel
                    value='male'
                    control={<Radio color='primary' />}
                    label={<Typography variant='body1'>Male</Typography>}
                    labelPlacement='bottom'
                  />
                  <FormControlLabel
                    value='female'
                    control={<Radio color='primary' />}
                    label={<Typography variant='body1'>Female</Typography>}
                    labelPlacement='bottom'
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {generatingStatus.requesting && <CircularProgress size={20} />}
        {!generatingStatus.requesting && (
          <Button
            onClick={() => {
              if (onCancelActionClicked) onCancelActionClicked()
            }}
            variant='outlined'
            disableElevation
          >
            <Typography variant='button'>{t(`${cancelButtonText}`)}</Typography>
          </Button>
        )}
        &nbsp;&nbsp;
        {!generatingStatus.requesting && (
          <Button
            onClick={() => {
              handleInvoiceGenerationButton()
            }}
            variant='contained'
            color='primary'
            disabled={disableGenerateButton()}
          >
            <Typography variant='button'>{t(`${doneButtonText}`)}</Typography>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
