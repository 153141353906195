import { R4 } from '@ahryman40k/ts-fhir-types'

import { SubscriptionInterest } from 'models/subscriptionInterestDetails'
import { isTermsAndConditionAccepted } from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import {
  getAgeOfPatient,
  getDefaultCodingOfSystemFromCodableConceptList,
  getFullNameFromHumanName,
  getGenderOfPatient,
  getNameOfPatient,
  titleCase,
} from 'utils/fhirResourcesHelper'

export function getSubscriptionInterestDetailsFromBundleBundle(
  bundle: R4.IBundle
): SubscriptionInterest[] {
  const subscriptionInterestDetails: SubscriptionInterest[] = []
  if (bundle.entry && bundle.entry.length > 0) {
    console.log('---------bundle.entry.length---------', bundle.entry.length)
    bundle.entry.forEach((entry: R4.IBundle_Entry) => {
      if (entry.resource?.resourceType === 'Task') {
        const task: R4.ITask = entry.resource as R4.ITask
        console.log('---------task---------', task)
        let patient: R4.IPatient | undefined
        let practitioner: R4.IPractitioner | undefined
        let practitionerRole: R4.IPractitionerRole | undefined
        let planDefinition: R4.IPlanDefinition | undefined

        if (task.owner && task.owner.reference) {
          patient = getPatientFromReference(task.owner.reference, bundle)
          console.log('---------patient---------', patient)
        }

        if (task.requester && task.requester.reference) {
          practitionerRole = getPractitionerRoleFromReference(
            task.requester.reference,
            bundle
          )
          console.log('---------practitionerRole---------', practitionerRole)
        }

        if (task.focus && task.focus.reference) {
          planDefinition = getPlanDefinitionFromReference(
            task.focus.reference,
            bundle
          )
          console.log('---------planDefinition---------', planDefinition)
        }

        const subscriptionInterestDetail: SubscriptionInterest = {
          task,
          patient,
          requestedPractitionerRole: practitionerRole,
          requestedPractitioner: practitioner,
          planDefinition,
          id: task.id ?? '',
          patientName: getFullNameFromHumanName(patient?.name ?? []),
          status: task.status?.toString() ?? '',
          statusDisplay: getSubscriptionStatusDisplay(task),
          patientAge: getAgeOfPatient(patient!),
          patientGender: titleCase(getGenderOfPatient(patient!)).charAt(0),
          subscriptionName: planDefinition?.title ?? '',
          startedDate: task.authoredOn?.toString() ?? '',
          subscriptionId: getSubscriptionId(task) ?? '',
        }
        subscriptionInterestDetails.push(subscriptionInterestDetail)
      }
    })
  }
  return subscriptionInterestDetails
}

export function getPatientFromReference(
  reference: string,
  bundle?: R4.IBundle
): R4.IPatient | undefined {
  const patientId: string = reference.split('/')[1]
  console.log('---------patientId---------', patientId)
  if (bundle && bundle.entry && bundle.entry.length > 0) {
    const patientEntry: R4.IBundle_Entry | undefined = bundle.entry.find(
      (entry: R4.IBundle_Entry) =>
        entry.resource?.resourceType === 'Patient' &&
        entry.resource?.id === patientId
    )
    if (patientEntry && patientEntry.resource) {
      return patientEntry.resource as R4.IPatient
    }
  }
  return undefined
}

/// getPractitionerFromReference
export function getPractitionerFromReference(
  reference: string,
  bundle?: R4.IBundle
): R4.IPractitioner | undefined {
  const practitionerId: string = reference.split('/')[1]
  if (bundle && bundle.entry && bundle.entry.length > 0) {
    const practitionerEntry: R4.IBundle_Entry | undefined = bundle.entry.find(
      (entry: R4.IBundle_Entry) =>
        entry.resource?.resourceType === 'Practitioner' &&
        entry.resource?.id === practitionerId
    )
    if (practitionerEntry && practitionerEntry.resource) {
      return practitionerEntry.resource as R4.IPractitioner
    }
  }
  return undefined
}

/// getPractitionerRoleFromReference
export function getPractitionerRoleFromReference(
  reference: string,
  bundle?: R4.IBundle
): R4.IPractitionerRole | undefined {
  const practitionerRoleId: string = reference.split('/')[1]
  if (bundle && bundle.entry && bundle.entry.length > 0) {
    const practitionerRoleEntry: R4.IBundle_Entry | undefined =
      bundle.entry.find(
        (entry: R4.IBundle_Entry) =>
          entry.resource?.resourceType === 'PractitionerRole' &&
          entry.resource?.id === practitionerRoleId
      )
    if (practitionerRoleEntry && practitionerRoleEntry.resource) {
      return practitionerRoleEntry.resource as R4.IPractitionerRole
    }
  }
  return undefined
}

/// getPlanDefinitionFromReference
export function getPlanDefinitionFromReference(
  reference: string,
  bundle?: R4.IBundle
): R4.IPlanDefinition | undefined {
  const planDefinitionId: string = reference.split('/')[1]
  if (bundle && bundle.entry && bundle.entry.length > 0) {
    const planDefinitionEntry: R4.IBundle_Entry | undefined = bundle.entry.find(
      (entry: R4.IBundle_Entry) =>
        entry.resource?.resourceType === 'PlanDefinition' &&
        entry.resource?.id === planDefinitionId
    )
    if (planDefinitionEntry && planDefinitionEntry.resource) {
      return planDefinitionEntry.resource as R4.IPlanDefinition
    }
  }
  return undefined
}

function getSubscriptionStatusDisplay(task: R4.ITask): string {
  if (task.status) {
    switch (task.status) {
      case 'draft':
        return 'Draft'
      case 'requested': {
        if (isTermsAndConditionAccepted(task)) {
          return 'Ready for trial'
        }
        if (isTermsAndConditionAccepted(task) === false) {
          return 'T&C Rejected'
        }

        return 'Request Pending'
      }

      case 'accepted':
        return 'Active'
      case 'cancelled':
        return 'Inactive'
      case 'completed':
        return 'Completed'
      case 'entered-in-error':
        return 'Entered in Error'
      default:
        return ''
    }
  }
  return ''
}

/// getSubscriptionId from task identifier
export function getSubscriptionId(task: R4.ITask): string | undefined {
  /*  if (task.identifier && task.identifier.length > 0) {
    const identifier: R4.IIdentifier = task.identifier[0]
    if (identifier.system && identifier.system === 'https://fhir.cerner.com/identifier/cerner-task-id') {
      return identifier.value
    }
  } */
  return task.id
}
