/* eslint-disable no-void */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
  withStyles,
  InputAdornment,
  TextField,
  Divider,
} from '@material-ui/core'
import {
  cartDoctorIdState,
  cartOrderForCachedState,
  cartPatientIdState,
  cartPatientObjectState,
  orderForObject,
} from 'models/medicine-delivery/cart/cart-states'

import { ProductDetail } from 'models/medicine-delivery/product-models'
import {
  addressState,
  viewState,
} from 'models/medicine-delivery/product-states'

import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  RecoilRoot,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'

import {
  getCurrentUserPractitionerRoleDetailsWithPractitioner,
  isGre,
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import { logger } from 'utils/logger'
import { getAddresses } from 'utils/medicineHelper/cart-apis'
import { getVendorPartId } from 'utils/routes_helper'
import { AppointmentGridView } from 'views/components/clinic_home_page/appointmentGridView'
import { AddPatientData } from 'views/components/common/add_patient_data'
import { CartAddresses } from './cart/cart-addresses'
import { CartDetails } from './cart/cart-details'
import { CartSummary } from './cart/cart-order-summary'
import { CartOrderTile } from './cart/cart-order-tile'
import { ProductDetails } from './products/product-details'

import { Products } from './products/products'

export interface IUnitAdmin {
  doctorDetails: R4.IPractitioner
  key: string
  patientDetails?: R4.IPatient
  isFromSoapNotes?: boolean
  medicationNames?: string[]
}

export const ProductPage: React.FC<IUnitAdmin> = ({
  doctorDetails,
  key,
  patientDetails,
  isFromSoapNotes,
  medicationNames,
}: IUnitAdmin) => {
  console.log('1')
  const dispatch = useDispatch()

  const [patientId, setPatientId] = useState<string | undefined>(
    patientDetails ? patientDetails.id ?? undefined : undefined
  )

  const [cartAddress, setCartAddress] = useRecoilState(addressState)
  const [cartPatient, setCartPatient] = useRecoilState(cartPatientObjectState)
  const [orderForData, setOrderForData] = useRecoilState(orderForObject)
  const [view, setView] = useRecoilState(viewState)
  const [orderFor, setOrderFor] = useRecoilState(cartOrderForCachedState)
  const setCartPatientId = useSetRecoilState(cartPatientIdState)

  useEffect(() => {
    if (patientDetails) {
      setCartPatient(patientDetails)
      setOrderFor('patient')
      setCartPatientId(patientDetails.id ?? undefined)
      setOrderForData({
        system: '',
        code: 'patient',
        display: 'Order for Patient',
      })
      void (async () => {
        if (orderFor === 'patient' && cartPatient) {
          const addresses = await getAddresses(cartPatient.id ?? '', 'patient')
          setCartAddress(addresses ?? [])
        } else {
          const addresses = await getAddresses('', orderFor)
          setCartAddress(addresses ?? [])
        }

        // const data = await getAddressesSample();
      })()
    } else {
      void (async () => {
        const addresses = await getAddresses('', 'clinic')
        setCartAddress(addresses ?? [])

        // const data = await getAddressesSample();
      })()
    }
  }, [])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        borderRadius: 8,

        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
          paddingTop={2}
        >
          <Box width='20%' paddingLeft={0.7}>
            <Box
              style={{ overflow: 'auto', height: '100%', maxWidth: '290px' }}
            >
              <SimpleBar
                style={{
                  height: '100%',
                  overflowX: 'hidden',
                }}
              >
                <Products />
              </SimpleBar>
            </Box>
            <Box display='flex' justifyContent='flex-start'>
              <Divider
                orientation='vertical'
                variant='fullWidth'
                flexItem
                style={{ height: '100%' }}
                color='primary'
              />
            </Box>
          </Box>

          <Box width='80%' style={{ overflow: 'hidden' }}>
            <Box>
              <ProductDetails
                doctorDetails={doctorDetails}
                onPatientCallBack={(patient: R4.IPatient) => {
                  setPatientId(patient.id ?? '')
                }}
                onAddToCartCallback={(product: ProductDetail) => {
                  dispatch(showSuccessAlert('Added Successfully'))
                }}
                isSoapNotes={isFromSoapNotes}
                medicationNames={medicationNames}
              />
            </Box>
            {view !== 'productDetails' && (
              <Box width='100%'>
                <CartOrderTile
                  patientId={cartPatient ? cartPatient.id ?? '' : ''}
                  doctorId={doctorDetails ? doctorDetails.id ?? '' : ''}
                  doctor={doctorDetails}
                  onPatientCallBack={(patient: R4.IPatient) => {
                    setCartAddress([])
                    setPatientId(patient.id ?? '')
                  }}
                  isSoapNotes={isFromSoapNotes}
                />
              </Box>
            )}

            <Box
              width='100%'
              paddingTop={view === 'cartSummary' ? 0 : 1}
              display='flex'
              flexDirection='column'
            >
              <CartDetails />

              <CartAddresses patientId={patientId ?? ''} />
              <CartSummary />
            </Box>
          </Box>
        </Box>
      </Paper>
    </div>
  )
}
