import MaterialTable, { MTableToolbar } from '@material-table/core'
import { Box, makeStyles, TablePagination, Typography } from '@material-ui/core'
import { LaunchOutlined, ViewCarousel } from '@material-ui/icons'
import {
  kBackgroundDefault,
  kDialogueBackground,
} from 'configs/styles/muiThemes'
import _ from 'lodash'
import { MembershipDetails } from 'models/membershipDetails'
import moment from 'moment'
// import { kDialogueBackground } from 'configs/styles/muiThemes'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useWindowDimensions from 'utils/hooks/useDimensionHook'

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      //   padding: 4,
    },
  },
})

interface MembershipDataTableProperties {
  usersData: MembershipDetails[]
  onMembershipSelected?: (membership: MembershipDetails) => void
  displayOnlyName?: boolean
  selectedMembership?: MembershipDetails
}

export const MembershipDataTable: React.FC<MembershipDataTableProperties> = ({
  usersData,
  onMembershipSelected,
  displayOnlyName,
  selectedMembership,
}: MembershipDataTableProperties) => {
  const [nameFocus, setNameFocus] = useState(true)
  const [categoryFocus, setCategoryFocus] = useState(true)
  const [durationFocus, setDurationFocus] = useState(true)
  const [priceFocus, setPriceFocus] = useState(true)
  const [billableFocus, setBillableFocus] = useState(true)
  const [statusFocus, setStatusFocus] = useState(true)

  const [nameFilter, setNameFilter] = useState('')
  const [categoryFilter, setCategoryFilter] = useState('')

  const [durationFilter, setDurationFilter] = useState('')
  const [billableFilter, setBillableFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('')

  const [data, setData] = useState([...usersData])

  const filterName = (value: any, filed: string) => {
    setNameFocus(true)
    if (value) {
      const filteredData = usersData.filter((d) =>
        (d?.memberName ?? '')
          .replace(/\s+/g, '')
          .toLowerCase()
          .includes(value.replace(/\s+/g, '').toLowerCase())
      )
      setData(filteredData)
    } else {
      setData([...usersData])
    }
    setNameFilter(value)
    setCategoryFocus(false)
    setDurationFocus(false)
    setPriceFocus(false)
    setBillableFocus(false)
    setStatusFocus(false)
  }

  const filterCategory = (value: any, filed: string) => {
    setCategoryFocus(true)
    if (value) {
      const filteredData = usersData.filter((d) =>
        (d?.planName ?? '')
          .toLocaleUpperCase()
          .includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...usersData])
    }
    setCategoryFilter(value)
    setNameFocus(false)
    setCategoryFocus(false)
    setDurationFocus(false)
    setPriceFocus(false)
    setBillableFocus(false)
    setStatusFocus(false)
  }

  const filterStatus = (value: any, filed: string) => {
    setStatusFocus(true)
    if (value) {
      const filteredData = usersData.filter((d) =>
        (d?.status ?? '')
          .toLocaleUpperCase()
          .includes((value ?? '').toLocaleUpperCase())
      )
      filteredData.sort((a, b) =>
        (a?.status ?? '')
          .toLowerCase()
          .localeCompare((b?.status ?? '').toLowerCase())
      )
      filteredData.reverse()
      setData(filteredData)
    } else {
      setData([...usersData])
    }
    setStatusFilter(value)
    setNameFocus(false)
    setCategoryFocus(false)
    setDurationFocus(false)
    setBillableFocus(false)
    setPriceFocus(false)
  }

  const classes = useStyles()

  const { height, width } = useWindowDimensions()
  const { t } = useTranslation()

  const tableRef = React.useRef()

  const paginationHeight = 56
  //

  const mainColumn = [
    {
      title: (
        <Box minWidth='200px'>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
          >
            Member Name
          </Typography>
        </Box>
      ),
      field: 'memberName',
      width: '20%',

      render: (rowData: MembershipDetails) => (
        <Box
          display='flex'
          alignItems='start'
          flexDirection='column'
          alignContent='start'
        >
          <Typography variant='subtitle2' noWrap>
            {rowData.memberName}
          </Typography>
          {displayOnlyName && (
            <Typography variant='body1' noWrap>
              {rowData.memberShipPlanId}
            </Typography>
          )}
          {displayOnlyName && (
            <Typography variant='body2' noWrap>
              {_.capitalize(rowData.status)}
            </Typography>
          )}
        </Box>
      ),
    },
  ]

  const columns = [
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
          >
            Member ID
          </Typography>
        </Box>
      ),
      field: 'memberShipPlanId',
      width: '20%',

      render: (rowData: MembershipDetails) => (
        <Box>
          <Typography variant='subtitle2' noWrap>
            &nbsp;&nbsp;{rowData.memberShipPlanId}
          </Typography>
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography variant='subtitle1' color='primary' noWrap>
            &nbsp;&nbsp;&nbsp;Plan Type
          </Typography>
        </Box>
      ),
      field: 'planName',

      width: '15%',
      render: (rowData: MembershipDetails) => (
        <Box>
          <Typography variant='subtitle2' color='textPrimary'>
            {rowData.planName}
          </Typography>
          {/* )} */}
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            color='primary'
            style={{ fontSize: 14 }}
          >
            Subscription Date
          </Typography>
        </Box>
      ),
      width: '30%',
      field: 'startDate',
      render: (rowData: MembershipDetails) => (
        <Box>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            {moment(rowData.startDate).format('DD MMM YYYY')}
          </Typography>
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            color='primary'
            style={{ fontSize: 14 }}
          >
            Reference
          </Typography>
        </Box>
      ),
      width: '20%',
      field: 'reference',
    },
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
          >
            Credits
          </Typography>
        </Box>
      ),
      field: 'remainingCredits',
      width: '15%',
      render: (rowData: MembershipDetails) => (
        <Box>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            {`${rowData.remainingCredits}/${rowData.totalCredits}`}
          </Typography>
        </Box>
      ),
    },

    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
          >
            Status
          </Typography>
        </Box>
      ),
      field: 'status',
      width: '10%',
      render: (rowData: MembershipDetails) => (
        <Typography variant='subtitle2' color='textPrimary' noWrap>
          {_.capitalize(rowData.status)}
        </Typography>
      ),
    },
    {
      title: <Box />,
      field: 'status',
      width: '5%',
      render: (rowData: MembershipDetails) => (
        <Box display='flex' justifyContent='flex-end'>
          <LaunchOutlined fontSize='small' />
        </Box>
      ),
      filtering: false,
    },
  ]

  return (
    <Box width='100%'>
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: 'white',
                minHeight: '0px',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),

          Pagination: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'left',
                // padding: '0px',
              }}
            >
              <TablePagination
                {...props}
                style={{
                  height: '100px',
                }}
                SelectProps={{
                  style: {
                    fontSize: 14,
                    minHeight: '5px',
                  },
                }}
              />
            </div>
          ),
        }}
        columns={displayOnlyName ? mainColumn : [...mainColumn, ...columns]}
        data={data}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        onRowClick={(event, rowData) => {
          if (rowData && onMembershipSelected) onMembershipSelected(rowData)
        }}
        tableRef={tableRef}
        options={{
          searchFieldStyle: { padding: 0 },
          toolbar: false,
          search: false,
          showTitle: false,
          searchFieldVariant: 'outlined',
          filtering: false,
          pageSize: 10,
          sorting: true,
          pageSizeOptions: [10, 20, 30],
          //   columnsButton: true,
          maxBodyHeight: `${height - 155}px`,
          minBodyHeight: `${height - 175}px`,

          tableLayout: 'auto',
          thirdSortClick: true,

          headerStyle: {
            backgroundColor: 'white',
            color: '#FFF',
            width: 100,
            position: 'sticky',
            top: 0,
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          rowStyle: (_data: any, index: number, _level: number) => ({
            backgroundColor: selectedMembership
              ? selectedMembership.coverageRaw.id ===
                (_data as MembershipDetails).coverageRaw.id
                ? kDialogueBackground
                : 'white'
              : 'white',
            borderBottom: kBackgroundDefault,
          }),
        }}
      />
    </Box>
  )
}
