import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import {
  getDiscountAmount,
  getTotalAmountFoRIpdWithoutDiscount,
} from 'utils/fhirResoureHelpers/ipdHelper'

interface Props {
  id: string
  currency: string
  totalAmount: number
  days: number
  discount: number
}
export const IPDTotalAmount: React.FC<Props> = ({
  id,
  currency,
  totalAmount,
  days,
  discount,
}: Props) => {
  const { t } = useTranslation()

  const finalAmount = getTotalAmountFoRIpdWithoutDiscount(
    totalAmount,
    days,
    discount
  )
  const discountedPrice = getDiscountAmount(totalAmount, days, discount)

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
        </div>
      )}
    >
      <Box display='flex' flexDirection='column' width='100%'>
        <Box display='flex' flexDirection='row' width='100%'>
          <Box display='flex' flexDirection='column' flexGrow={1}>
            <Typography
              variant='h6'
              color='primary'
              id={`${id}_text_total_price`}
            >
              Total Price
            </Typography>
          </Box>

          <Box display='flex' flexDirection='column'>
            <Box display='flex' flexDirection='row' flexGrow={1}>
              <Typography
                variant='h6'
                color='primary'
                style={{ paddingRight: 2 }}
              >
                {t(currency)}
              </Typography>
              <Typography
                variant='h6'
                color='primary'
                style={{ paddingLeft: 2 }}
                id={`${id}_text_final_price`}
              >
                {finalAmount.toFixed(2)}
              </Typography>
            </Box>
          </Box>
        </Box>
        {discount > 0 && (
          <Box display='flex' flexDirection='row' width='100%'>
            <Box display='flex' flexDirection='column' flexGrow={1}>
              <Typography
                variant='h6'
                color='primary'
                id={`${id}_text_discount_label`}
              >
                Discount
              </Typography>
            </Box>

            <Box display='flex' flexDirection='column'>
              <Box display='flex' flexDirection='row' flexGrow={1}>
                <Typography
                  variant='h6'
                  color='primary'
                  style={{ paddingRight: 1 }}
                >
                  {t(currency)}
                </Typography>
                <Typography
                  variant='h6'
                  color='primary'
                  style={{ paddingLeft: 2 }}
                  id={`${id}_text_discount_price`}
                >
                  {discountedPrice.toFixed(2)}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </ErrorBoundary>
  )
}
