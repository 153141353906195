import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { getPriceComponentFromChangeItem } from 'utils/fhirResoureHelpers/planDefinitionHelper'

interface Props {
  itemName: String
  chargeItemDefinition: R4.IChargeItemDefinition
}
export const ItemOrderSummary: React.FC<Props> = ({
  itemName,
  chargeItemDefinition,
}: Props) => {
  const { t } = useTranslation()
  const priceComponent: R4.IChargeItemDefinition_PriceComponent | undefined =
    getPriceComponentFromChangeItem(chargeItemDefinition)

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
        </div>
      )}
    >
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        width='100%'
      >
        <Box
          display='flex'
          flexDirection='column'
          flexGrow={1}
          maxWidth='400px'
        >
          <Typography variant='body2' color='initial'>
            {itemName}
          </Typography>
        </Box>

        <Box display='flex' flexDirection='row'>
          <Typography
            variant='body2'
            color='initial'
            style={{ paddingRight: 2.5 }}
          >
            ₹{' '}
          </Typography>
          <Typography
            variant='body2'
            color='initial'
            style={{ paddingRight: 3 }}
          >
            {priceComponent?.amount?.value?.toFixed(2)}
          </Typography>
        </Box>
      </Box>
    </ErrorBoundary>
  )
}
