/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable consistent-return */
import { Box } from '@material-ui/core'
import { ChartProps } from 'models/chartProps'
import React, {
  createRef,
  RefObject,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { BaseSankeyChart } from './baseShankeyChart'
import { ErrorMessage } from './errorMessage'

export const SankeyChart: React.FC<ChartProps> = ({
  data,
  width,
  height,
  min,
  colorSet,
  nodeAlign,
  nodeWidth,
  accessibleTooltip,
  fontSize,
}: ChartProps) => {
  const svgRef: RefObject<any> | null = createRef()

  const [widthPart, setWidthPart] = useState<number>(0)
  const [heightPart, setHeightPart] = useState<number>(0)

  const measureSVG = () => {
    const { widthData, heightData } = svgRef.current.getBoundingClientRect()
    setWidthPart(widthData)
    setHeightPart(heightData)
  }

  useEffect(() => {
    if (svgRef && !width && !height) {
      measureSVG()
    } else if (svgRef && width && height) {
      setWidthPart(width)
      setHeightPart(height)
    } else {
      setWidthPart(800)
      setHeightPart(600)
    }
  }, [])

  useLayoutEffect(() => {
    if (!svgRef) return
    window.addEventListener('resize', measureSVG)
    return () => {
      try {
        window.removeEventListener('resize', measureSVG)
      } catch (e: any) {
        console.warn(`Could not remove resize EventListener: ${e.message}`)
      }
    }
  }, [svgRef])

  return (
    <div
      className='chart'
      style={{ fontSize: fontSize ? `${fontSize}pt` : '9pt' }}
    >
      <Box display='flex' flexDirection='column'>
        {accessibleTooltip === false ? (
          <div
            id='tooltip'
            className='tooltip'
            style={{ position: 'absolute', display: 'block' }}
          />
        ) : (
          ''
        )}
        <svg width={width} height={height} ref={svgRef}>
          {data !== null ? (
            <BaseSankeyChart
              data={data}
              width={widthPart}
              height={heightPart}
              nodeWidth={nodeWidth}
              accessibleTooltip={accessibleTooltip}
            />
          ) : (
            <ErrorMessage message='Error reading chart data.' svg={true} />
          )}
        </svg>
      </Box>
    </div>
  )
}
