import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Grid,
  Divider,
  Tooltip,
  Link,
  Collapse,
  Button,
} from '@material-ui/core'
import { Timeline } from '@material-ui/lab'

import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
} from '@material-ui/icons'

import React, { useEffect, useState } from 'react'
import { MedOrderTimeLineEvent } from './medOrderTimelinEvent'

interface AllergiesProp {
  salesOrder: any
  orderStatus: string[]
}

export const OrderTrackTile: React.FC<AllergiesProp> = ({
  salesOrder,
  orderStatus,
}: AllergiesProp) => {
  const [checked, setChecked] = React.useState<boolean>(false)

  function handleCollapseForPanel1(rate: boolean) {
    setChecked(rate)
  }
  return (
    <Box
      flexGrow
      width='100%'
      flexDirection='column'
      display='flex'
      style={{ padding: 0, margin: 0 }}
    >
      <Box flexGrow width='100%' display='flex' flexDirection='row'>
        <Box display='flex' flexDirection='column' width='100%' flexGrow>
          <Box width='100%' py={1}>
            <Box
              paddingX={1}
              borderRadius={2}
              style={{
                backgroundColor: 'lightGrey',
              }}
              display='flex'
              flexDirection='row'
              width='100%'
              height={40}
            >
              <Box
                justifyContent='flex-start'
                display='flex'
                flexGrow={1}
                alignItems='center'
              >
                <Typography
                  variant='subtitle1'
                  style={{
                    color: 'black',

                    fontWeight: 'bold',
                  }}
                >
                  Track Order
                </Typography>
              </Box>
              <Box
                justifyContent='flex-end'
                display='flex'
                flexGrow={1}
                alignItems='center'
              >
                <Box px={1}>
                  <Tooltip title=''>
                    <IconButton
                      aria-label='collapse_order_type'
                      size='small'
                      onClick={() => {
                        handleCollapseForPanel1(!checked)
                      }}
                    >
                      {checked && <ArrowDropUpOutlined />}
                      {checked === false && <ArrowDropDownOutlined />}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <Collapse
              in={checked}
              style={{
                width: '100%',
              }}
            >
              <Box display='flex' flexDirection='row' justifyContent='center'>
                <Timeline>
                  {orderStatus.map((e, index) => (
                    <Box
                      key={`time_event_key_${e ?? ''}`}
                      justifyContent='flex-start'
                      flexGrow
                    >
                      <MedOrderTimeLineEvent
                        key={`time_event_key_${e ?? ''}`}
                        orderEvent={e}
                        salesOrder={salesOrder}
                        isEndEvent={index === orderStatus.length! - 1}
                      />
                    </Box>
                  ))}
                </Timeline>
              </Box>
            </Collapse>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
