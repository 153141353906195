/* eslint-disable no-alert */
/* eslint-disable radix */
/* eslint-disable react/jsx-key */
import MomentUtils from '@date-io/moment'
import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  makeStyles,
  Radio,
  RadioGroup as MuiRadioGroup,
  Typography,
  useTheme,
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import React, { useState } from 'react'
import { RadioOptions } from '../../../models/radioOptions'

const useStyles = makeStyles((theme) => ({
  checked: {
    '&, & + $label': {
      color: useTheme().palette.primary.main,
    },
  },
  label: {
    color: '#100e7b',
    fontSize: 14,
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
  },
}))

export interface IProps {
  name: string
  label?: string
  value?: string
  onChange?: any
  items: RadioOptions[]
  preSelectedOption: any
  onDateChange?: (dateSelected: Date) => void
  onOccurrenceChange?: (occur: string | number) => void
  onNeverChange?: () => void
  date: Date
  occur: number | string
  max: number | string
}

export const RepeatOptions: React.FC<IProps> = ({
  name,
  label,
  value,
  onChange,
  items,
  preSelectedOption,
  onDateChange,
  onOccurrenceChange,
  onNeverChange,
  date,
  occur,
  max,
}: IProps) => {
  const classes = useStyles()

  const [showDatePicker, showSetDatePicker] = useState(false)
  const [selectedDate, setSelectedDate] = useState(date)
  const [selectedOccur, setSelectedOccur] = useState<string | number>(occur)
  const [dateDisabled, setDateDisabled] = useState(
    preSelectedOption.id !== 'On'
  )
  const [occurDisabled, setOccurDisabled] = useState(
    preSelectedOption.id !== 'After'
  )
  const [errorText, setErrorText] = useState<string>('')

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <FormControl>
        <Typography
          variant='subtitle2'
          style={{
            textTransform: 'uppercase',
          }}
        >
          {label} &nbsp;
        </Typography>
        <MuiRadioGroup
          name={name}
          value={value}
          onChange={onChange}
          defaultValue={preSelectedOption.id}
        >
          {items.map((item: any) => (
            <Box flexDirection='row' display='flex'>
              <FormControlLabel
                classes={{
                  label: classes.label,
                }}
                key={item.id}
                id={name}
                value={item.id}
                onClick={() => {
                  if (item.id === 'On') {
                    if (onDateChange) {
                      onDateChange(moment(selectedDate).toDate() ?? new Date())
                    }
                    setDateDisabled(false)
                  } else setDateDisabled(true)

                  if (item.id === 'After') {
                    if (onOccurrenceChange) onOccurrenceChange(selectedOccur)
                    setOccurDisabled(false)
                  } else setOccurDisabled(true)
                  if (item.id === 'Never') {
                    if (onNeverChange) onNeverChange()
                  }
                }}
                control={
                  <Radio
                    disableRipple
                    color='primary'
                    size='small'
                    id={item.id}
                    classes={{
                      checked: classes.checked,
                    }}
                  />
                }
                label={item.title}
              />
              <Box
                width='70%'
                display='flex'
                flexDirection='column'
                padding={0.5}
              >
                <Box paddingLeft={4}>
                  {item.title === 'On' ? (
                    <TextField
                      id='DatePicker'
                      variant='outlined'
                      label=''
                      fullWidth
                      size='small'
                      value={moment(selectedDate).format('DD MMM YYYY')}
                      onClick={() => {
                        if (dateDisabled === false) showSetDatePicker(true)
                      }}
                      disabled={dateDisabled}
                    />
                  ) : (
                    <span />
                  )}
                </Box>
                <Box paddingLeft={2.5} width='93%'>
                  {item.title === 'After' ? (
                    <TextField
                      id='Occurrences'
                      variant='outlined'
                      type='number'
                      label=''
                      size='small'
                      color='primary'
                      defaultValue={selectedOccur}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'>
                            occurrences
                          </InputAdornment>
                        ),
                        style: {
                          width: 150,
                          //   opacity: 1,
                        },
                        inputProps: {
                          min: 1,
                          max,
                        },

                        // classes,
                      }}
                      onKeyDown={(evt) =>
                        evt.key === 'e' && evt.preventDefault()
                      }
                      value={selectedOccur}
                      onChange={(e: any) => {
                        if (
                          parseInt(e.target.value) > 0 &&
                          parseInt(e.target.value) <= max &&
                          e.target.value !== 'NaN'
                        ) {
                          setSelectedOccur(e.target.value)
                          setErrorText('')
                          if (onOccurrenceChange)
                            onOccurrenceChange(e.target.value)
                        } else {
                          alert(`Occurrence should be in between 1-${max}`)
                        }
                      }}
                      disabled={occurDisabled}
                      helperText={errorText}
                    />
                  ) : (
                    <span />
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </MuiRadioGroup>
      </FormControl>
      <DatePicker
        hidden={true}
        margin='none'
        size='small'
        allowKeyboardControl={true}
        disablePast={true}
        initialFocusedDate={selectedDate}
        style={{
          height: 0,
          width: 0,
          padding: 0,
          margin: 0,
          visibility: 'collapse',
        }}
        onClose={() => {
          showSetDatePicker(false)
        }}
        value={selectedDate}
        open={showDatePicker}
        onAbort={() => {
          showSetDatePicker(false)

          // onDateChanged(selectedDate);
        }}
        onChange={(val) => {
          setSelectedDate(val !== null ? moment(val).toDate() : new Date())
          if (onDateChange) {
            onDateChange(moment(val).toDate() ?? new Date())
          }
          showSetDatePicker(false)
        }}
      />
    </MuiPickersUtilsProvider>
  )
}
