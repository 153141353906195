/* eslint-disable no-irregular-whitespace */
import { Box, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import 'react-vis/dist/style.css'
import { ObservationOCAForViewOnly } from './doshaVikruthiAssemment/doshaAssessmentFromHistory'

interface CardioVascularExaminationsListProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  isReadOnly?: boolean
}

export const DushyaAssessment: React.FC<CardioVascularExaminationsListProps> =
  ({
    fhirAppointmentDetails,
    isReadOnly = false,
  }: CardioVascularExaminationsListProps) => {
    const { t } = useTranslation(['common'])

    const dispatch = useDispatch()

    return (
      <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
        <Box display='flex' flexGrow={1}>
          <Box
            borderRadius={2}
            style={{
              backgroundColor: 'lightGrey',
            }}
            display='flex'
            flexDirection='row'
            width='100%'
            height={40}
            px={1}
          >
            <Box
              justifyContent='flex-start'
              display='flex'
              flexGrow={1}
              alignItems='center'
            >
              <Typography
                variant='subtitle1'
                style={{
                  color: 'black',

                  fontWeight: 'bold',
                }}
              >
                Dushya Assessment
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='column' width='100%' px={1}>
            <Box width='100%'>
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='subjective'
                formName='Interpretation Dushya from Subjective Dushya Status_OCA'
                observationCode='C0439655'
                categoryCode='C4045976'
                isReadOnly={isReadOnly}
              />
            </Box>
            <Box width='100%'>
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='objective'
                categoryCode='C0582103'
                formName='Ayurvedic Examination Dhatu Vikriti Evalution Dhatu Strotas Dhatuvaha Sroto Dushti_OCA'
                observationCode='C1333618'
                isReadOnly={isReadOnly}
              />
            </Box>
            <Box width='100%'>
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='objective'
                formName='Ayurvedic Examination Dhatu Vikruti Evaluation Dhatu Vaishamya Dushya Status_OCA'
                observationCode='C0478171'
                categoryCode='C0582103'
                isReadOnly={isReadOnly}
              />
            </Box>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' width='100%'>
            <Box
              borderRadius={2}
              display='flex'
              flexDirection='row'
              width='100%'
              px={1}
            >
              <Box
                justifyContent='flex-start'
                display='flex'
                flexGrow={1}
                alignItems='center'
              >
                <Typography
                  variant='subtitle1'
                  style={{
                    color: 'black',

                    fontWeight: 'bold',
                  }}
                >
                  Mala
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display='flex' flexDirection='column' width='100%' px={1}>
            <Box width='100%'>
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='subjective'
                formName='Personal Information Shakrud_OCA'
                observationCode='C0015733'
                categoryCode='C4045976'
                isReadOnly={isReadOnly}
              />
            </Box>
            <Box width='100%'>
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='subjective'
                formName='Personal Information Mutra (Urine)_OCA'
                observationCode='C0429837'
                categoryCode='C4045976'
                isReadOnly={isReadOnly}
              />
            </Box>
            <Box width='100%'>
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='objective'
                formName='Ayurvedic Examination Ashtasthana Mutram (General)_OCA'
                observationCode='C0042036'
                categoryCode='C0582103'
                isReadOnly={isReadOnly}
              />
            </Box>
            <Box width='100%'>
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='objective'
                formName='Ayurvedic Examination Ashtasthana Mutram Mutravaha Srotas_OCA'
                observationCode='C1508753'
                categoryCode='C0582103'
                isReadOnly={isReadOnly}
              />
            </Box>
            <Box width='100%'>
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='objective'
                formName='Ayurvedic Examination Ashtasthana Malam (General)_OCA'
                observationCode='C0426737'
                categoryCode='C0582103'
                isReadOnly={isReadOnly}
              />
            </Box>
            <Box width='100%'>
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='objective'
                formName='Ayurvedic Examination_Ashtasthana_Mala_General_4.1.3.1_OCA'
                categoryCode='C0582103'
                observationCode='C0021851'
                isReadOnly={isReadOnly}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
