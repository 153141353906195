import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { LabReferralDetails } from 'models/labReferralDetails'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserUnitDetails,
  getUserDetails,
  isCurrentUnitIsClinic,
  isCurrentUnitIsWellonessCenter,
} from 'services/userDetailsService'
import { logger } from 'utils/logger'
import { LabReferralSearchStatusTypeStatus } from './labReferralSearchStatusTypes'

const initialState: LabReferralSearchStatusTypeStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const referralSearchSlice = createSlice({
  name: 'referralSearchSlice',
  initialState,
  reducers: {
    searchingPatientDetails(
      state,
      action: PayloadAction<LabReferralSearchStatusTypeStatus>
    ) {},

    searchResults(
      state,
      action: PayloadAction<LabReferralSearchStatusTypeStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.unitsList = action.payload.unitsList
      state.referralList = action.payload.referralList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<LabReferralSearchStatusTypeStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.unitsList = action.payload.unitsList
      state.referralList = action.payload.referralList
    },

    errorWhileSearching(
      state,
      action: PayloadAction<LabReferralSearchStatusTypeStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.unitsList = action.payload.unitsList
      state.referralList = action.payload.referralList
    },
  },
})

export const searchingReferrals = (): AppThunk => async (dispatch: any) => {
  const errorFetchingUnits: LabReferralSearchStatusTypeStatus = {
    error: false,
    noResultsAvailable: false,
    resultsAvailable: false,
    searching: true,
  }
  dispatch(referralSearchSlice.actions.errorWhileSearching(errorFetchingUnits))
  try {
    let searchParameter = {}
    searchParameter = {
      _count: 500,
    }
    let response: any
    const unitDetails: R4.IOrganization = getCurrentUserUnitDetails()
    const fhirClient: FHIRApiClient = new FHIRApiClient()
    if (isCurrentUnitIsClinic() || isCurrentUnitIsWellonessCenter()) {
      response = await fhirClient.doGetResource(
        `/Task?owner=Organization/${unitDetails.id}&code=partner-lab-invitation`,
        searchParameter
      )
    } else {
      response = await fhirClient.doGetResource(
        `/Task?subject=Organization/${unitDetails.id}&code=partner-lab-invitation`,
        searchParameter
      )
    }

    const orgResponse: R4.IBundle = response
    if (orgResponse.total) {
      if (orgResponse.total > 0) {
        if (orgResponse.entry) {
          const taskArray: R4.ITask[] = orgResponse.entry.map(
            (item) => item.resource as R4.ITask
          )
          const referralArray: LabReferralDetails[] = []
          for (let i = 0; i < taskArray.length; i++) {
            referralArray.push({
              name: taskArray[i].for ? taskArray[i].for?.display ?? '' : '',
              status: taskArray[i].status ?? '',
              date: taskArray[i].authoredOn
                ? moment(taskArray[i].authoredOn).format('DD/MM/YYYY')
                : '',
              id: taskArray[i].id ?? '',
              clinicName: taskArray[i].owner
                ? taskArray[i].owner?.display ?? ''
                : '',
              task: taskArray[i],
            })
          }

          const fetchUnitListResult: LabReferralSearchStatusTypeStatus = {
            error: false,
            noResultsAvailable: false,
            resultsAvailable: true,
            searching: false,
            unitsList: taskArray,
            referralList: referralArray,
          }
          dispatch(
            referralSearchSlice.actions.searchResults(fetchUnitListResult)
          )
          return
        }
      }
    }
    const noSearchResults: LabReferralSearchStatusTypeStatus = {
      error: false,
      noResultsAvailable: true,
      resultsAvailable: false,
      searching: false,
    }
    dispatch(referralSearchSlice.actions.noDataFoundForSearch(noSearchResults))
    return
  } catch (error) {
    logger.error(error)
    const errorWhileSearchPatient: LabReferralSearchStatusTypeStatus = {
      error: true,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: 'Error',
    }
    dispatch(
      referralSearchSlice.actions.errorWhileSearching(errorWhileSearchPatient)
    )
  }
}

export default referralSearchSlice.reducer
