import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import moment from 'moment'
import React from 'react'
import {
  getDayFullNameFromDayCode,
  getDisplayTextOfTimeOfDayFromCode,
} from 'utils/careplan_utils/weeklyDietPlanUtils'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '4px',
      margin: '0px',
      width: '12.25%',
      maxWidth: '12.25%',
      overflow: 'ellipse',
    },
  },
})

interface WeeklyDietPlanProps {
  split?: boolean
  tableData: {
    tableHeader: string[]
    tableBody: string[][]
  }
  displayRecordedDate?: boolean
  startDateStr?: string
  endDateStr?: string
}

export const WeeklyDietPlanViewFromTasks: React.FC<WeeklyDietPlanProps> = ({
  split,
  tableData,
  displayRecordedDate = true,
  startDateStr,
  endDateStr,
}: WeeklyDietPlanProps) => {
  const classes = useStyles()
  return (
    <Box
      display='flex'
      flexDirection='column'
      width='100%'
      maxWidth='100%'
      flexGrow={0}
    >
      {startDateStr && endDateStr && (
        <Box
          paddingX={1}
          borderRadius={2}
          style={{
            backgroundColor: 'lightGrey',
          }}
          display='flex'
          flexDirection='row'
          width='100%'
          height={40}
        >
          <Box
            justifyContent='flex-end'
            display='flex'
            flexDirection='row'
            flexGrow={1}
            alignItems='center'
          >
            <Box
              px={1}
              border={2}
              borderTop={0}
              borderBottom={0}
              borderColor='white'
            >
              {' '}
              <Typography variant='subtitle2' color='initial'>
                Start Date
              </Typography>
            </Box>
            <Box px={1}>
              {' '}
              <Typography variant='body2' color='initial'>
                {moment(startDateStr).format('DD-MM-YYYY')}
              </Typography>
            </Box>
            <Box
              px={1}
              border={2}
              borderTop={0}
              borderBottom={0}
              borderColor='white'
            >
              {' '}
              <Typography variant='subtitle2' color='initial'>
                Till Date
              </Typography>
            </Box>
            <Box px={1}>
              {' '}
              <Typography variant='body2' color='initial'>
                {moment(endDateStr).format('DD-MM-YYYY')}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      <TableContainer
        component={Paper}
        style={{
          width: '100%',
          margin: '0px',
          padding: '0px',
        }}
      >
        <Table className={classes.table} aria-label='simple table'>
          <TableHead
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <TableRow
              style={{
                fontWeight: 'bold',
              }}
            >
              {tableData.tableHeader.map((eachHeader, cellIndex) => {
                if (cellIndex === 0) {
                  return (
                    <TableCell key='day_of_week'>
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 14,
                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        Day of Week
                      </Typography>
                    </TableCell>
                  )
                }
                return (
                  <TableCell key={eachHeader}>
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {getDisplayTextOfTimeOfDayFromCode(eachHeader)}
                    </Typography>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.tableBody.map((day, index) => (
              <TableRow key={day.length > 0 ? day[0] : 'abc'}>
                {day.map((eachDay, cellIndex) => {
                  if (cellIndex === 0) {
                    return (
                      <TableCell
                        key={eachDay}
                        style={{
                          backgroundColor: '#f5f5f5',
                        }}
                      >
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 14,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          {getDayFullNameFromDayCode(eachDay)}
                        </Typography>
                      </TableCell>
                    )
                  }
                  return (
                    <TableCell key={eachDay}>
                      <Typography
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {eachDay}
                      </Typography>
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
