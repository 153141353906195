import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import React, { useState } from 'react'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'

interface PatientProps {
  activeCount: number
  completedCount: number
  cancelled: number
  type: string
}

export const AppointmentCountTileCount: React.FC<PatientProps> = ({
  activeCount,
  completedCount,
  cancelled,
  type,
}: PatientProps) => {
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  const [role, setRole] = useState<string>()
  const [open, setOpen] = useState<boolean>(false)

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Grid container direction='row' spacing={1}>
        {/* ------------------------------------------------------------------------------- */}

        <Box
          display='flex'
          flexDirection='column'
          width='100%'
          flexGrow={1}
          border={3}
          style={{
            borderColor: '#F8F8F8',
          }}
        >
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <Grid item xs={6}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {type === 'clinic'
                          ? 'Active'
                          : type === 'lab'
                          ? 'Active'
                          : 'Active'}{' '}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {activeCount}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: 'white',
            }}
          >
            <Grid item xs={6}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {type === 'clinic'
                        ? 'Discharged'
                        : type === 'lab'
                        ? 'Completed'
                        : 'Completed'}{' '}
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {completedCount}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid> */}
          {(type === 'lab' || type === 'wellness_center') && (
            <Box display='flex' flexDirection='column' flexGrow>
              <Grid
                container
                direction='row'
                id='vitals'
                style={{
                  backgroundColor: kDialogueBackground,
                }}
              >
                <Grid item xs={6}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Typography
                          variant='subtitle2'
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',

                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          Cancelled
                        </Typography>
                      </Box>
                    </Box>
                    <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Typography
                          variant='subtitle2'
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',

                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          {cancelled}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display='flex'
                      width='2%'
                      justifyContent='flex-end'
                      flexGrow
                    >
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Grid container direction='row' id='vitals'>
                <Grid item xs={12}>
                  <Box width='100%' paddingLeft={0.2}>
                    <Divider
                      orientation='horizontal'
                      flexItem
                      style={{
                        height: '2px',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Grid>
    </Box>
  )
}
