import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { kBackgroundPaper, kDialogueBackground } from 'configs/styles/muiThemes'
import { Diet } from 'models/diet'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedOccupation } from 'models/groupedOccupations'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'

import { requestGeneralExaminationOfPatient } from 'redux/consultation/generalExamination/generalExaminationSlice'
import {
  requestVitalDetailsOfPatient,
  resetVitalSearchState,
} from 'redux/consultation/vitalsDetailsSlice/vitalsDetailSlice'
import { resetAddGeneralExaminationState } from 'redux/fhirMedicalResources/addGeneralExam/addGeneralExamSlice'
import { resetAddVitalsState } from 'redux/fhirMedicalResources/addVital/addVitalSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import {
  getNotesFromAllergy,
  getNotesString,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { getCatalogDetailsFromIds } from 'utils/fhirResoureHelpers/catalogHelper'
import {
  getIdsOfSelectedDetails,
  getNamesOfOrders,
} from 'utils/fhirResoureHelpers/ipdDietHelper'
import {
  getDateWiseVitals,
  getDateWiseVitalsForGeneralExam,
  getDateWiseVitalsForOthers,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { logger } from 'utils/logger'
import { PathyaApathyaDetails } from 'utils/sopaNotes/plan/pathyaApathyaHelpers'
import { InstructionsTableTile } from 'views/components/consultation/assessment/instructionsTabularTile'
import { GeneralExaminationTile } from 'views/components/consultation/objective/tiles/generalExaminationTile'
import { KriyaDetail } from 'views/components/consultation/plan/ayurveda/addKriyasForEncounter'
import { KriyaDetailTile } from 'views/components/consultation/plan/ayurveda/kriyaDetailTile'
import OverflowTypography from 'views/components/consultation/plan/ayurveda/overflowTypography'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { VitalElement } from 'views/components/LeftMenu/VitalElement'
import { SOAPIndicativeElement } from 'wello-web-components'
import { InstructionsTableTileForHistory } from './instructionsHistoryTile'
import { ReferralLabTestTile } from './labTestTabularFormat'

interface VitalsProp {
  dietData: Diet[]
}

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '4px',
      margin: '0px',
      minWidth: '10%',
      maxWidth: '12%',
      overflow: 'ellipse',
    },
  },
})
export const DietHistoryForReferral: React.FC<VitalsProp> = ({
  dietData,
}: VitalsProp) => {
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()
  const classes = useStyles()

  const addDietSlice = useSelector(
    (state: RootState) => state.nutritionCatalogueValueSetSlice
  )

  return (
    <ReactVisibilitySensor
      offset={{
        top: 100,
        bottom: 30,
      }}
      onChange={(isVisible) => {
        logger.info('Inside diagnostic')
        // if (isVisible) {
        //   dispatch(setSelectedSection({ selectedSection: 'vitals' }))
        // }
      }}
    >
      <Grid container direction='column' style={{ flexWrap: 'nowrap' }}>
        <Grid item>
          <Grid container direction='row' id='vitals'>
            <Box py={1}>
              <Typography variant='h6'>Diet </Typography>
            </Box>{' '}
          </Grid>

          {dietData.length === 0 && (
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                No diet data available
              </Typography>
            </Grid>
          )}
          <Box width='100%'>
            <TableContainer
              component={Paper}
              style={{
                width: '100%',
                margin: '0px',
                padding: '0px',
              }}
            >
              <Table className={classes.table} aria-label='simple table'>
                <TableHead
                  style={{
                    backgroundColor: kDialogueBackground,
                  }}
                >
                  <TableRow
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    <TableCell>
                      {' '}
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Date
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Early Morning
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Breakfast
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Mid Morning
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Lunch
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Snacks
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Dinner
                      </Typography>
                    </TableCell>

                    <TableCell>
                      {' '}
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Bed Time
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dietData.map((row) => (
                    <TableRow key={row.date}>
                      <TableCell>
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          {moment(row.date).format('DD-MM-YYYY')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          {getNamesOfOrders(row.six ?? [])}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          {getNamesOfOrders(row.eight ?? [])}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          {getNamesOfOrders(row.twelve ?? [])}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          {getNamesOfOrders(row.two ?? [])}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          {getNamesOfOrders(row.five ?? [])}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          {getNamesOfOrders(row.seven ?? [])}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          {getNamesOfOrders(row.nine ?? [])}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        {showText && (
          <NoteDialog
            id='notesTile'
            open={showText}
            onClose={() => {
              setFullText('')
              setShowText(false)
            }}
            notesData={fullText!}
            dialogText='Remarks'
          />
        )}
      </Grid>
    </ReactVisibilitySensor>
  )
}
