import { Grid, Typography, Box } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NextStepsOfConsultationIPD } from '../plan/nextStepsIPD'
import { NextStepsOfConsultationIPDAyurVeda } from '../plan/nextStepsIPDAyurveda'

interface PlanPartOfConsultationProp {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  split?: boolean
}

export const PlanPartOfConsultationIPDAyurveda: React.FC<PlanPartOfConsultationProp> =
  ({ fhirAppointmentDetails, split }: PlanPartOfConsultationProp) => {
    const { t } = useTranslation(['common'])
    const [addButton, showAddButton] = useState<boolean>(false)

    return (
      <Grid container spacing={1} direction='column'>
        <Grid>
          <NextStepsOfConsultationIPDAyurVeda
            fhirAppointmentDetails={fhirAppointmentDetails}
            split={split}
          />
        </Grid>
      </Grid>
    )
  }
