import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  TextField,
  Typography,
  Theme,
  makeStyles,
} from '@material-ui/core'
import { Diet } from 'models/diet'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { requestDietPlanDetails } from 'redux/consultation/dietPlan/existingDietPlanSearch/existingDietPlanSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  getAddedDate,
  getAddedDateWithCustomStartDate,
  getOnlyDate,
  getTomorrowDate,
} from 'utils/dateUtil'
import {
  getCatalogForTimeOfDay,
  getCatalogName,
} from 'utils/fhirResoureHelpers/catalogHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  getBedTime,
  getBedTimeForPullDown,
  getDinnerTime,
  getDinnerTimeForPullDown,
  getEarlyBreakFastTime,
  getEarlyBreakFastTimeForPullDown,
  getEarlyMorningTime,
  getEarlyMorningTimeForPullDown,
  getLunchTime,
  getLunchTimeForPullDown,
  getMidMorningTime,
  getMidMorningTimeForPullDown,
  getSnackTime,
  getSnackTimeForPullDown,
} from 'utils/fhirResoureHelpers/ipdDietHelper'
import { getOrgDetailsAlongWithServices } from 'utils/fhirResoureHelpers/organizationHelpers'
import CustomizedDividers from 'views/components/toggle_selector'
import { WelloTextField } from 'wello-web-components'
import { WelloFormItemLabel } from '../LeftMenu/WelloFormItemLabel'
import { WelloTextFieldWithoutTitle } from '../LeftMenu/WelloTextFieldWitoutTitle'
import { DietSelector } from './dietSelector'

interface AssessmentCaptureProps {
  encounterID?: string
  practitionerRoleId?: string
  split?: boolean
  patientID: string
  mode?: 'new' | 'edit' | 'view'
  onDietChanged: (
    changedDietPlan: Diet[],
    startDate?: Date,
    endDate?: Date,
    allowedFoods?: string,
    avoidingFoods?: string
  ) => void
  onDateFetched?: (startDate?: string, endDate?: string) => void
}

export const DietPlanCardForDoctor: React.FC<AssessmentCaptureProps> = ({
  encounterID,
  practitionerRoleId,
  split,
  patientID,
  mode = 'edit',
  onDietChanged,
  onDateFetched,
}: AssessmentCaptureProps) => {
  const { t } = useTranslation(['common'])

  const dispatch = useDispatch()
  const addDietSlice = useSelector((state: RootState) => state.addDietSlice)
  const dietListSlice = useSelector(
    (state: RootState) => state.nutritionCatalogueValueSetSlice
  )
  const getDietDetails = useSelector(
    (state: RootState) => state.existingDietPlanSearchSlice
  )

  const dietTimeSearchSlice = useSelector(
    (state: RootState) => state.dietTImeSearchSlice
  )

  const [selectedDatePart, setSelectedDatePart] = useState<string>('today')
  const [selectedWeekPart, setSelectedWeekPart] = useState<string>('1W')
  const [startDate, setStartDate] = useState<Date>(getOnlyDate(new Date()))
  const [tillDate, setTillDate] = useState<Date>(getAddedDate(7))
  const [exisingStartDate, setExistingStartDate] = useState<string>(
    moment().toISOString()
  )
  const [existingEndDate, setExistingEndDate] = useState<string>()
  const [dietChart, setDietChart] = useState<Diet[]>([
    {
      id: getUniqueTempId(),
      date: new Date().toUTCString(),
      eight: [],
      five: [],
      seven: [],
      six: [],
      twelve: [],
      two: [],
      nine: [],
    },
  ])

  const [preferredFoods, setPreferredFoods] = useState<string | undefined>()
  const [foodsToAvoid, setFoodsToAvoid] = useState<string | undefined>()

  function updateCurrentSelection(
    catalogType: R4.ICatalogEntry[],
    dietDate: number,
    timeIndex: string
  ) {
    const selectedDiet: Diet = { ...dietChart[dietDate] }
    switch (timeIndex) {
      case getEarlyMorningTimeForPullDown(
        dietTimeSearchSlice.prSelectedCoding ?? []
      ):
        selectedDiet.six = (selectedDiet.six ?? []).map((ord) =>
          updateNutrition(catalogType, ord)
        )
        break
      case getEarlyBreakFastTimeForPullDown(
        dietTimeSearchSlice.prSelectedCoding ?? []
      ):
        selectedDiet.eight = [
          updateNutrition(catalogType, selectedDiet.eight![0]),
        ]
        break
      case getMidMorningTimeForPullDown(
        dietTimeSearchSlice.prSelectedCoding ?? []
      ):
        selectedDiet.twelve = (selectedDiet.twelve ?? []).map((ord) =>
          updateNutrition(catalogType, ord)
        )
        break
      case getLunchTimeForPullDown(dietTimeSearchSlice.prSelectedCoding ?? []):
        selectedDiet.two = (selectedDiet.two ?? []).map((ord) =>
          updateNutrition(catalogType, ord)
        )
        break
      case getSnackTimeForPullDown(dietTimeSearchSlice.prSelectedCoding ?? []):
        selectedDiet.five = (selectedDiet.five ?? []).map((ord) =>
          updateNutrition(catalogType, ord)
        )
        break
      case getDinnerTimeForPullDown(dietTimeSearchSlice.prSelectedCoding ?? []):
        selectedDiet.seven = (selectedDiet.seven ?? []).map((ord) =>
          updateNutrition(catalogType, ord)
        )
        break
      case getBedTimeForPullDown(dietTimeSearchSlice.prSelectedCoding ?? []):
        selectedDiet.nine = (selectedDiet.nine ?? []).map((ord) =>
          updateNutrition(catalogType, ord)
        )
        break
      default:
        break
    }

    const newDietChart = [...dietChart]
    newDietChart[dietDate] = selectedDiet
    if (onDietChanged)
      onDietChanged(
        newDietChart,
        startDate,
        tillDate,
        preferredFoods,
        foodsToAvoid
      )
    setDietChart(newDietChart)
  }

  function updateNutritionCompletionStatus(
    nutritionOrder: R4.INutritionOrder[],
    dietDate: number,
    timeIndex: string
  ) {
    const selectedDiet: Diet = { ...dietChart[dietDate] }
    switch (timeIndex) {
      case getEarlyMorningTimeForPullDown(
        dietTimeSearchSlice.prSelectedCoding ?? []
      ):
        selectedDiet.six = nutritionOrder
        break
      case getEarlyBreakFastTimeForPullDown(
        dietTimeSearchSlice.prSelectedCoding ?? []
      ):
        selectedDiet.eight = nutritionOrder
        break
      case getMidMorningTimeForPullDown(
        dietTimeSearchSlice.prSelectedCoding ?? []
      ):
        selectedDiet.twelve = nutritionOrder
        break
      case getLunchTimeForPullDown(dietTimeSearchSlice.prSelectedCoding ?? []):
        selectedDiet.two = nutritionOrder
        break
      case getSnackTimeForPullDown(dietTimeSearchSlice.prSelectedCoding ?? []):
        selectedDiet.five = nutritionOrder
        break
      case getDinnerTimeForPullDown(dietTimeSearchSlice.prSelectedCoding ?? []):
        selectedDiet.seven = nutritionOrder
        break
      case getBedTimeForPullDown(dietTimeSearchSlice.prSelectedCoding ?? []):
        selectedDiet.nine = nutritionOrder
        break
      default:
        break
    }

    const newDietChart = [...dietChart]
    newDietChart[dietDate] = selectedDiet
    if (onDietChanged)
      onDietChanged(
        newDietChart,
        startDate,
        tillDate,
        preferredFoods,
        foodsToAvoid
      )
    setDietChart(newDietChart)
  }

  function updateNutrition(
    catalogType: R4.ICatalogEntry[],
    nutritionResource: R4.INutritionOrder
  ) {
    let nutritionOrder: R4.INutritionOrder = { ...nutritionResource }
    nutritionOrder.meta = {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndNutritionOrderNutritionProduct',
      ],
    }

    const extList: R4.IExtension[] = []
    console.log('-----------catalogType-----------', catalogType)
    if (catalogType.length === 0) {
      console.log('catalogType.length === 0')

      nutritionOrder = {
        ...nutritionOrder,
        oralDiet: {
          ...nutritionOrder.oralDiet,
          extension: [
            {
              url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-intake-ext',
              extension: [],
            },
            {
              url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-repetition-type-ext',
              valueCoding: {
                system: 'http://snomed.info/sct',
                code: '261010008',
                display: 'Fixed',
              },
            },
          ],
        },
      }
      console.log(
        '------------updated nutritionOrder----------------',
        nutritionOrder
      )
    } else {
      catalogType.forEach((e) => {
        extList.push({
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-product-ext',
          valueReference: {
            reference: `CatalogEntry/${e.id}`,
            display: getCatalogName(e),
          },
        })
      })

      nutritionOrder = {
        ...nutritionOrder,
        oralDiet: {
          ...nutritionOrder.oralDiet,
          extension: [
            {
              url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-intake-ext',
              extension: extList,
            },
            {
              url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-repetition-type-ext',
              valueCoding: {
                system: 'http://snomed.info/sct',
                code: '261010008',
                display: 'Fixed',
              },
            },
          ],
        },
      }
    }
    return nutritionOrder
  }

  useEffect(() => {
    dispatch(
      requestDietPlanDetails({
        dietTime: dietTimeSearchSlice.prSelectedCoding ?? [],
        patientId: patientID,
        encounterId: encounterID,
        practitionerRoleId,
        showPlanSuggestedBySameDoctor: true,
        split,
      })
    )
  }, [])

  useEffect(() => {
    if (getDietDetails.resultsAvailable) {
      console.log(getDietDetails.dateWiseDietListFixed)
      if (getDietDetails.dateWiseDietListFixed)
        setDietChart(getDietDetails.dateWiseDietListFixed)
      setFoodsToAvoid(getDietDetails.itemsToAvoid)
      setPreferredFoods(getDietDetails.preferredFoods)
      if (
        getDietDetails.startDate &&
        getDietDetails.isFixedDietAvailable === true
      ) {
        const diffStart = moment(getDietDetails.startDate)
          .endOf('day')
          .diff(moment().endOf('day'), 'day')

        if (diffStart === 0) {
          setSelectedDatePart('today')
        } else if (diffStart === 1) {
          setSelectedDatePart('tomo')
        } else {
          setSelectedDatePart('custom')
        }
        setExistingStartDate(
          moment(getDietDetails.startDate).startOf('day').toISOString()
        )
        setStartDate(moment(getDietDetails.startDate).startOf('day').toDate())
      }

      if (
        getDietDetails.endDate &&
        getDietDetails.isFixedDietAvailable === true
      ) {
        const diffEnd = moment(getDietDetails.endDate)
          .endOf('day')
          .diff(moment(getDietDetails.startDate).endOf('day'), 'day')

        if (diffEnd === 7) {
          setSelectedWeekPart('1W')
        } else if (diffEnd === 14) {
          setSelectedWeekPart('2W')
        } else if (diffEnd === 30) {
          setSelectedWeekPart('1m')
        } else {
          setSelectedWeekPart('custom')
        }
        setExistingEndDate(
          moment(getDietDetails.endDate).startOf('day').toISOString()
        )
        setTillDate(moment(getDietDetails.endDate).startOf('day').toDate())
      }
    }
    if (getDietDetails.startDate && getDietDetails.endDate) {
      if (onDateFetched)
        onDateFetched(
          moment(getDietDetails.startDate).startOf('day').toISOString(),
          moment(getDietDetails.endDate).startOf('day').toISOString()
        )
    }
  }, [getDietDetails.resultsAvailable])

  return (
    <Box
      style={{ paddingLeft: '2px' }}
      display='flex'
      flexDirection='column'
      width='100%'
    >
      {addDietSlice.adding && <LinearProgress />}
      {getDietDetails.searchingTreatment && (
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          alignContent='center'
          alignItems='center'
          justifyContent='center'
          justifyItems='center'
        >
          {' '}
          <CircularProgress size={28} />
        </Box>
      )}
      {mode === 'view' &&
        getDietDetails.resultsAvailable &&
        !getDietDetails.isPreExistingPlanAvailable && (
          <Box width='100%' display='flex' flexDirection='row' px={1}>
            <Typography
              variant='subtitle2'
              style={{
                fontWeight: 400,
              }}
            >
              {split ? 'No data available' : ''}
            </Typography>
          </Box>
        )}
      {getDietDetails.resultsAvailable &&
        (mode === 'edit' || getDietDetails.isPreExistingPlanAvailable) && (
          <Box display='flex' flexDirection='column' width='100%'>
            <Box width='100%' display='flex' flexDirection='row' px={1}>
              <Grid container direction='column'>
                <Grid item>
                  <Grid item id='diet_for_encounter'>
                    <Grid item xl style={{ padding: 0, margin: 0 }}>
                      <Grid
                        container
                        direction='row'
                        style={{ padding: 0, margin: 0 }}
                      >
                        <Box
                          width='100%'
                          display='flex'
                          flexDirection='row'
                          alignItems='center'
                          justifyItems='center'
                          justifyContent='space-between'
                        >
                          <Box
                            paddingTop={2}
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                            width='18%'
                            px={1}
                          >
                            <Typography
                              variant='body1'
                              color='initial'
                              style={{ fontWeight: 600, textAlign: 'center' }}
                            >
                              {' '}
                              {getEarlyMorningTime(
                                dietTimeSearchSlice.prSelectedCoding ?? []
                              )}
                            </Typography>
                          </Box>

                          <Box
                            paddingTop={2}
                            width='18%'
                            px={1}
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Typography
                              variant='body1'
                              color='initial'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              {getEarlyBreakFastTime(
                                dietTimeSearchSlice.prSelectedCoding ?? []
                              )}
                            </Typography>
                          </Box>

                          <Box
                            paddingTop={2}
                            width='18%'
                            px={1}
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Typography
                              variant='body1'
                              color='initial'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              {getMidMorningTime(
                                dietTimeSearchSlice.prSelectedCoding ?? []
                              )}
                            </Typography>
                          </Box>

                          <Box
                            paddingTop={2}
                            width='18%'
                            px={1}
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Typography
                              variant='body1'
                              color='initial'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              {getLunchTime(
                                dietTimeSearchSlice.prSelectedCoding ?? []
                              )}
                            </Typography>
                          </Box>
                          <Box
                            paddingTop={2}
                            width='18%'
                            px={1}
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Typography
                              variant='body1'
                              color='initial'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              {getSnackTime(
                                dietTimeSearchSlice.prSelectedCoding ?? []
                              )}
                            </Typography>
                          </Box>
                          <Box
                            paddingTop={2}
                            width='18%'
                            px={1}
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Typography
                              variant='body1'
                              color='initial'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              {getDinnerTime(
                                dietTimeSearchSlice.prSelectedCoding ?? []
                              )}
                            </Typography>
                          </Box>

                          <Box
                            paddingTop={2}
                            width='18%'
                            px={1}
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Typography
                              variant='body1'
                              color='initial'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              {getBedTime(
                                dietTimeSearchSlice.prSelectedCoding ?? []
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid item xl style={{ padding: 0, margin: 0 }}>
                      <Box display='flex' flexDirection='column' width='100%'>
                        {dietChart.map((dietRow: Diet, index: number) => (
                          <Box
                            display='flex'
                            flexDirection='row'
                            alignItems='flex-start'
                            justifyContent='space-between'
                            key={`diet_plan_details_${dietRow.id}`}
                          >
                            <Box px={1} width='18%' py={0.5}>
                              <DietSelector
                                nutritionOrders={dietRow.six ?? []}
                                onSelectionChanged={(a, b) => {
                                  updateCurrentSelection(a, index, b)
                                }}
                                searchType='MORN.early'
                                timeOfDay={getEarlyMorningTimeForPullDown(
                                  dietTimeSearchSlice.prSelectedCoding ?? []
                                )}
                                disableSelection={mode === 'view'}
                                onNutritionIntakeUpdated={(e) => {
                                  updateNutritionCompletionStatus(
                                    e,
                                    index,
                                    getEarlyMorningTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  )
                                }}
                                showProcessing={
                                  dietListSlice.searchingTreatment
                                }
                                availableOptions={getCatalogForTimeOfDay(
                                  dietListSlice.catalogueEntries ?? [],
                                  'MORN.early',
                                  true
                                )}
                              />
                            </Box>

                            <Box px={1} width='18%' py={0.5}>
                              <DietSelector
                                nutritionOrders={dietRow.eight ?? []}
                                onSelectionChanged={(a, b) => {
                                  updateCurrentSelection(a, index, b)
                                }}
                                searchType='MORN'
                                timeOfDay={getEarlyBreakFastTimeForPullDown(
                                  dietTimeSearchSlice.prSelectedCoding ?? []
                                )}
                                disableSelection={mode === 'view'}
                                onNutritionIntakeUpdated={(e) => {
                                  updateNutritionCompletionStatus(
                                    e,
                                    index,
                                    getEarlyBreakFastTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  )
                                }}
                                showProcessing={
                                  dietListSlice.searchingTreatment
                                }
                                availableOptions={getCatalogForTimeOfDay(
                                  dietListSlice.catalogueEntries ?? [],
                                  'MORN',
                                  true
                                )}
                              />
                            </Box>
                            <Box px={1} width='18%' py={0.5}>
                              <DietSelector
                                nutritionOrders={dietRow.twelve ?? []}
                                onSelectionChanged={(a, b) => {
                                  updateCurrentSelection(a, index, b)
                                }}
                                searchType='MORN.late'
                                timeOfDay={getMidMorningTimeForPullDown(
                                  dietTimeSearchSlice.prSelectedCoding ?? []
                                )}
                                disableSelection={mode === 'view'}
                                onNutritionIntakeUpdated={(e) => {
                                  updateNutritionCompletionStatus(
                                    e,
                                    index,
                                    getMidMorningTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  )
                                }}
                                showProcessing={
                                  dietListSlice.searchingTreatment
                                }
                                availableOptions={getCatalogForTimeOfDay(
                                  dietListSlice.catalogueEntries ?? [],
                                  'MORN.late',
                                  true
                                )}
                              />
                            </Box>
                            <Box px={1} width='18%' py={0.5}>
                              <DietSelector
                                nutritionOrders={dietRow.two ?? []}
                                onSelectionChanged={(a, b) => {
                                  updateCurrentSelection(a, index, b)
                                }}
                                searchType='NOON'
                                timeOfDay={getLunchTimeForPullDown(
                                  dietTimeSearchSlice.prSelectedCoding ?? []
                                )}
                                disableSelection={mode === 'view'}
                                onNutritionIntakeUpdated={(e) => {
                                  updateNutritionCompletionStatus(
                                    e,
                                    index,
                                    getLunchTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  )
                                }}
                                showProcessing={
                                  dietListSlice.searchingTreatment
                                }
                                availableOptions={getCatalogForTimeOfDay(
                                  dietListSlice.catalogueEntries ?? [],
                                  'NOON',
                                  true
                                )}
                              />
                            </Box>
                            <Box px={1} width='18%' py={0.5}>
                              <DietSelector
                                nutritionOrders={dietRow.five ?? []}
                                onSelectionChanged={(a, b) => {
                                  updateCurrentSelection(a, index, b)
                                }}
                                searchType='EVE'
                                timeOfDay={getSnackTimeForPullDown(
                                  dietTimeSearchSlice.prSelectedCoding ?? []
                                )}
                                disableSelection={mode === 'view'}
                                onNutritionIntakeUpdated={(e) => {
                                  updateNutritionCompletionStatus(
                                    e,
                                    index,
                                    getSnackTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  )
                                }}
                                showProcessing={
                                  dietListSlice.searchingTreatment
                                }
                                availableOptions={getCatalogForTimeOfDay(
                                  dietListSlice.catalogueEntries ?? [],
                                  'EVE',
                                  true
                                )}
                              />
                            </Box>
                            <Box px={1} width='18%' py={0.5}>
                              <DietSelector
                                nutritionOrders={dietRow.seven ?? []}
                                onSelectionChanged={(a, b) => {
                                  updateCurrentSelection(a, index, b)
                                }}
                                searchType='NIGHT'
                                timeOfDay={getDinnerTimeForPullDown(
                                  dietTimeSearchSlice.prSelectedCoding ?? []
                                )}
                                disableSelection={mode === 'view'}
                                onNutritionIntakeUpdated={(e) => {
                                  updateNutritionCompletionStatus(
                                    e,
                                    index,
                                    getDinnerTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  )
                                }}
                                showProcessing={
                                  dietListSlice.searchingTreatment
                                }
                                availableOptions={getCatalogForTimeOfDay(
                                  dietListSlice.catalogueEntries ?? [],
                                  'NIGHT',
                                  true
                                )}
                              />
                            </Box>

                            <Box px={1} width='18%' py={0.5}>
                              <DietSelector
                                nutritionOrders={dietRow.nine ?? []}
                                onSelectionChanged={(a, b) => {
                                  updateCurrentSelection(a, index, b)
                                }}
                                searchType='PCV'
                                timeOfDay={getBedTimeForPullDown(
                                  dietTimeSearchSlice.prSelectedCoding ?? []
                                )}
                                disableSelection={mode === 'view'}
                                onNutritionIntakeUpdated={(e) => {
                                  updateNutritionCompletionStatus(
                                    e,
                                    index,
                                    getBedTimeForPullDown(
                                      dietTimeSearchSlice.prSelectedCoding ?? []
                                    )
                                  )
                                }}
                                showProcessing={
                                  dietListSlice.searchingTreatment
                                }
                                availableOptions={getCatalogForTimeOfDay(
                                  dietListSlice.catalogueEntries ?? [],
                                  'PCV',
                                  true
                                )}
                              />
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box display='flex' flexDirection='row' width='100%'>
              <Box
                display='flex'
                flexDirection='row'
                width={mode === 'view' ? '18%' : '55%'}
                alignItems='center'
              >
                {mode !== 'view' && (
                  <Box
                    paddingLeft={2}
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-start'
                    paddingTop={0.2}
                  >
                    <CustomizedDividers
                      key={selectedDatePart}
                      size='medium'
                      menuLabel={t('labelCommon:start From')}
                      options={[
                        {
                          displayText: `${t('labelCommon:today')}`,
                          value: 'today',
                        },
                        {
                          displayText: `${t('labelCommon:tomorrow')}`,
                          value: 'tomo',
                        },
                        {
                          displayText: `${t('labelCommon:custom')}`,
                          value: 'custom',
                        },
                      ]}
                      onSelectionChanged={(selectedValue) => {
                        let newDate = new Date()
                        let newEndDate = new Date()
                        if (selectedValue === 'today') {
                          newDate = new Date()
                        }
                        if (selectedValue === 'tomo') {
                          newDate = getTomorrowDate()
                        }

                        if (selectedWeekPart === '1m') {
                          newEndDate = getAddedDateWithCustomStartDate(
                            newDate,
                            30
                          )
                        }
                        if (selectedWeekPart === '1W') {
                          newEndDate = getAddedDateWithCustomStartDate(
                            newDate,
                            7
                          )
                        }
                        if (selectedWeekPart === '2W') {
                          newEndDate = getAddedDateWithCustomStartDate(
                            newDate,
                            14
                          )
                        }
                        setSelectedDatePart(selectedValue)
                        setStartDate(newDate)
                        if (selectedWeekPart !== 'custom') {
                          setTillDate(newEndDate)
                        }
                        onDietChanged(
                          dietChart,
                          newDate,
                          newEndDate,
                          preferredFoods,
                          foodsToAvoid
                        )
                      }}
                      preSelectedValue={selectedDatePart}
                    />
                  </Box>
                )}

                {/* {mode !== 'view' && (
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignContent='center'
                    alignItems='center'
                  >
                    <WelloFormItemLabel title='Selected Start Date' />{' '}
                    <Typography variant='body1' color='initial'>
                      {getOnlyDateInString(startDate)}
                    </Typography>
                  </Box>
                )}

                {mode !== 'view' && (
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignContent='center'
                    alignItems='center'
                  >
                    <WelloFormItemLabel title='Saved Start Date' />{' '}
                    <Typography variant='body1' color='initial'>
                      {getOnlyDateInString(
                        moment(getDietDetails.startDate).toDate()
                      )}{' '}
                    </Typography>
                  </Box>
                )} */}

                {mode === 'view' && <WelloFormItemLabel title='Start Date' />}

                {(selectedDatePart === 'custom' || mode === 'view') && (
                  <Box
                    paddingLeft={11.5}
                    width='90%'
                    display='flex'
                    flexDirection='row'
                    paddingTop={3.9}
                    justifyContent='start'
                  >
                    <TextField
                      variant='outlined'
                      value={
                        mode === 'view'
                          ? moment.utc(startDate).local().format('DD-MM-YYYY')
                          : moment.utc(startDate).local().format('YYYY-MM-DD')
                      }
                      size='small'
                      onChange={(val) => {
                        if (moment(val.target.value).isValid()) {
                          const newDate = new Date(val.target.value)
                          let newEndDate = new Date()
                          if (selectedWeekPart === '1m') {
                            newEndDate = getAddedDateWithCustomStartDate(
                              newDate,
                              30
                            )
                          }
                          if (selectedWeekPart === '1W') {
                            newEndDate = getAddedDateWithCustomStartDate(
                              newDate,
                              7
                            )
                          }
                          if (selectedWeekPart === '2W') {
                            newEndDate = getAddedDateWithCustomStartDate(
                              newDate,
                              14
                            )
                          }
                          setStartDate(newDate)
                          if (selectedWeekPart !== 'custom') {
                            setTillDate(newEndDate)
                          }
                          onDietChanged(
                            dietChart,
                            newDate,
                            newEndDate,
                            preferredFoods,
                            foodsToAvoid
                          )
                        }
                      }}
                      disabled={mode === 'view'}
                      type={mode === 'view' ? undefined : 'date'}
                      onBlur={(e) => {
                        if (
                          !moment(e.target.value).isValid() ||
                          moment(e.target.value).isBefore(
                            moment(new Date()).startOf('day')
                          ) ||
                          moment(e.target.value).isAfter(tillDate)
                        ) {
                          setStartDate(new Date())
                          const newDate = new Date(e.target.value)
                          let newEndDate = new Date()
                          if (selectedWeekPart === '1m') {
                            newEndDate = getAddedDateWithCustomStartDate(
                              newDate,
                              30
                            )
                          }
                          if (selectedWeekPart === '1W') {
                            newEndDate = getAddedDateWithCustomStartDate(
                              newDate,
                              7
                            )
                          }
                          if (selectedWeekPart === '2W') {
                            newEndDate = getAddedDateWithCustomStartDate(
                              newDate,
                              14
                            )
                          }
                          setStartDate(newDate)
                          if (selectedWeekPart !== 'custom') {
                            setTillDate(newEndDate)
                          }
                          onDietChanged(
                            dietChart,
                            newDate,
                            newEndDate,
                            preferredFoods,
                            foodsToAvoid
                          )
                          dispatch(
                            showErrorAlert(
                              'Start date is invalid or out of range. Changed to default date',
                              undefined,
                              'Invalid Start Date',
                              true
                            )
                          )
                        }
                      }}
                      inputProps={{
                        min: moment
                          .utc(new Date())
                          .local()
                          .format('YYYY-MM-DD'),
                        style:
                          mode === 'view'
                            ? {
                                paddingRight: '2px',
                                textAlign: 'center',

                                paddingLeft: '2px',
                              }
                            : undefined,
                      }}
                      style={{}}
                    />
                  </Box>
                )}
              </Box>

              <Box paddingRight={3} width='45%'>
                <Box display='flex' flexDirection='column' width='100%'>
                  <WelloFormItemLabel title='Preferred items' />
                  <WelloTextFieldWithoutTitle
                    key='diet_preferred_items'
                    textProps={{
                      id: 'txt_diet_preferred_items',
                      value: preferredFoods,
                      size: 'small',
                      disabled: mode === 'view',
                      multiline: true,
                      inputProps: { maxLength: 256 },
                      onChange: (a) => {
                        setPreferredFoods(a.target.value)
                        onDietChanged(
                          dietChart,
                          startDate,
                          tillDate,
                          a.target.value,
                          foodsToAvoid
                        )
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>

            <Box display='flex' flexDirection='row' width='100%' px={1}>
              <Box
                display='flex'
                flexDirection='row'
                width={mode === 'view' ? '18%' : '55%'}
                alignItems={mode === 'view' ? 'center' : 'start'}
                px={1}
              >
                {mode !== 'view' && (
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='end'
                    pr={2}
                  >
                    <CustomizedDividers
                      key={selectedWeekPart}
                      size='medium'
                      menuLabel={t('labelCommon:till')}
                      options={[
                        {
                          displayText: `${t('labelCommon:1_Week')}`,
                          value: '1W',
                        },
                        {
                          displayText: `2 Weeks`,
                          value: '2W',
                        },
                        {
                          displayText: `1 Month`,
                          value: '1m',
                        },
                        {
                          displayText: `${t('labelCommon:custom')}`,
                          value: 'custom',
                        },
                      ]}
                      onSelectionChanged={(selectedValue) => {
                        setSelectedWeekPart(selectedValue)
                        if (selectedValue === '1m') {
                          setTillDate(
                            getAddedDateWithCustomStartDate(startDate, 30)
                          )
                          onDietChanged(
                            dietChart,
                            startDate,
                            getAddedDateWithCustomStartDate(startDate, 30),
                            preferredFoods,
                            foodsToAvoid
                          )
                        }
                        if (selectedValue === '1W') {
                          setTillDate(
                            getAddedDateWithCustomStartDate(startDate, 7)
                          )
                          onDietChanged(
                            dietChart,
                            startDate,
                            getAddedDateWithCustomStartDate(startDate, 7),
                            preferredFoods,
                            foodsToAvoid
                          )
                        }
                        if (selectedValue === '2W') {
                          setTillDate(
                            getAddedDateWithCustomStartDate(startDate, 14)
                          )
                          onDietChanged(
                            dietChart,
                            startDate,
                            getAddedDateWithCustomStartDate(startDate, 14),
                            preferredFoods,
                            foodsToAvoid
                          )
                        }

                        if (selectedValue === 'custom') {
                          setTillDate(
                            getAddedDateWithCustomStartDate(startDate, 7)
                          )
                          onDietChanged(
                            dietChart,
                            startDate,
                            getAddedDateWithCustomStartDate(startDate, 7),
                            preferredFoods,
                            foodsToAvoid
                          )
                        }
                      }}
                      preSelectedValue={selectedWeekPart}
                    />
                  </Box>
                )}
                {mode === 'view' && <WelloFormItemLabel title='Till Date' />}

                {/* {mode !== 'view' && (
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignContent='center'
                    alignItems='center'
                  >
                    <WelloFormItemLabel title='Selected Till Date' />{' '}
                    <Typography variant='body1' color='initial'>
                      {getOnlyDateInString(tillDate)}
                    </Typography>
                  </Box>
                )}

                {mode !== 'view' && (
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignContent='center'
                    alignItems='center'
                  >
                    <WelloFormItemLabel title='Saved Till Date' />{' '}
                    <Typography variant='body1' color='initial'>
                      {getOnlyDateInString(
                        moment(getDietDetails.endDate).toDate()
                      )}{' '}
                    </Typography>
                  </Box>
                )} */}
                {(selectedWeekPart === 'custom' || mode === 'view') && (
                  <Box
                    paddingTop={3.9}
                    width={mode === 'view' ? '85%' : undefined}
                    display='flex'
                    flexDirection='row'
                    justifyContent='end'
                    pr={mode === 'view' ? 0.5 : 2}
                  >
                    <TextField
                      variant='outlined'
                      disabled={mode === 'view'}
                      value={
                        mode === 'view'
                          ? moment.utc(tillDate).local().format('DD-MM-YYYY')
                          : moment.utc(tillDate).local().format('YYYY-MM-DD')
                      }
                      size='small'
                      onBlur={(e) => {
                        if (
                          !moment(e.target.value).isValid() ||
                          moment(e.target.value).isBefore(
                            moment(startDate).startOf('day') ||
                              moment(e.target.value).isBefore(startDate)
                          )
                        ) {
                          setTillDate(
                            getAddedDateWithCustomStartDate(startDate, 7)
                          )
                          onDietChanged(
                            dietChart,
                            startDate,
                            new Date(e.target.value),
                            preferredFoods,
                            foodsToAvoid
                          )
                          dispatch(
                            showErrorAlert(
                              'Till date is invalid or out of range. Changed to default date',
                              undefined,
                              'Invalid Till Date',
                              true
                            )
                          )
                        }
                      }}
                      onChange={(val) => {
                        if (
                          moment(val.target.value).isValid() &&
                          moment(val.target.value).isAfter(
                            moment(startDate).startOf('day')
                          )
                        ) {
                          setTillDate(new Date(val.target.value))
                          onDietChanged(
                            dietChart,
                            startDate,
                            new Date(val.target.value),
                            preferredFoods,
                            foodsToAvoid
                          )
                        }
                      }}
                      type={mode === 'view' ? undefined : 'date'}
                      inputProps={{
                        min: moment.utc(startDate).local().format('YYYY-MM-DD'),
                        style:
                          mode === 'view'
                            ? {
                                paddingRight: '2px',
                                textAlign: 'center',

                                paddingLeft: '2px',
                              }
                            : undefined,
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Box paddingRight={1} width='45%'>
                <Box display='flex' flexDirection='column' width='100%'>
                  <WelloFormItemLabel title='Items to avoid' />
                  <WelloTextFieldWithoutTitle
                    textProps={{
                      id: 'txt_diet_avoiding_items',
                      value: foodsToAvoid,
                      disabled: mode === 'view',
                      multiline: true,
                      size: 'small',
                      inputProps: { maxLength: 256 },
                      onChange: (a) => {
                        setFoodsToAvoid(a.target.value)
                        onDietChanged(
                          dietChart,
                          startDate,
                          tillDate,
                          preferredFoods,
                          a.target.value
                        )
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
    </Box>
  )
}
