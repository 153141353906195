import { R4 } from '@ahryman40k/ts-fhir-types'
import { Calendar } from '@mantine/dates'
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { kPrimaryDark, kPrimaryMain } from 'configs/styles/muiThemes'
import { PreAppointmentDetails } from 'models/preAppointmentDetails'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { sendBulkPatientInvitations } from 'redux/administration/patientPreAppointment/patientPreAppointmentInvitationSetupSlice'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { requestForDateWisePreAppointmentLists } from 'redux/clinic/preAppointmentManagement/preAppointmentSearch/preAppointmentSearchSlice'
import { resetState } from 'redux/patient/addPatient/addPatientSlice'
import { resetPatientSearchPatient } from 'redux/patient/patientSearch/patientSearchSlice'
import { RootState } from 'redux/rootReducer'
import { isTherapist } from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import { isToday } from 'utils/dateUtil'
import {
  getFhirPatientResourceFromPreAppointmentDetails,
  getPatientFromPreAppointment,
} from 'utils/fhirResoureHelpers/preAppointmentHelper'
import { AddNewPatient } from 'views/components/add_new_patient'
import { CreateIpdAppointmentHandlerPreAppointment } from 'views/components/ipdAppointment/createIpdAppointmentManagementPreAppointment'
import { AddPatientsPreAppointmentHandler } from 'views/components/patients/addPatientDetailsPreAppointment'
import { PatientChecker } from 'views/components/patients/patientChecker'
import { PatientPreAppointmentSetUpBulkUpload } from 'views/components/preAppointment/bulkUpload/patientPreAppointmentSetUp'
import { PreAppointmentTable } from './preAppointmentTable'

export const PreAppointmentLandingPage: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] = useState<Date | undefined>()

  const [showCheckPatient, setShowCheckPatient] = useState<boolean>(false)
  const [showCreateIpd, setShowCreateIpd] = useState<boolean>(false)
  const [showAddPatient, setShowAddPatient] = useState<boolean>(false)

  const [preAppointmentDetails, setPreAppointmentDetails] =
    useState<PreAppointmentDetails>()

  const [createdPatientDetails, setCreatedPatient] = useState<R4.IPatient>()

  const [name, setName] = useState<string>('')

  const [invitePatients, setInvitePatients] = useState<BulkInviteModel[]>([])

  const [openPatientBulkUpload, setOpenPatientBulkUpload] = useState(false)

  const [openAddPatientForm, setOpenAddPatientForm] = useState(false)

  const appointmentSearchSlice = useSelector(
    (state: RootState) => state.preAppointmentSearchSlice
  )

  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  interface BulkInviteModel {
    patientId: string
    patientPhone: string
  }

  const addPatientPreAppointmentFormSlice = useSelector(
    (state: RootState) => state.addPatientPreAppointmentFormSlice
  )

  useEffect(() => {
    dispatch(requestForDateWisePreAppointmentLists(name, selectedDate))
  }, [dispatch, selectedDate])

  useEffect(() => {
    if (addPatientPreAppointmentFormSlice.sentSuccessfully) {
      dispatch(requestForDateWisePreAppointmentLists(name, selectedDate))
    }
  }, [addPatientPreAppointmentFormSlice.sentSuccessfully])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        borderRadius: 8,
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box
            display='flex'
            width='22%'
            maxWidth='22%'
            style={{ overflow: 'auto', height: '100%', minWidth: '290px' }}
          >
            <SimpleBar
              style={{
                height: '100%',
                width: '100%',
                overflowX: 'hidden',
                padding: '8px',
              }}
            >
              <Box px={1} width='100%'>
                <Box width='100%'>
                  <Calendar
                    size='xs'
                    style={{
                      aspectRatio: '1/1',
                    }}
                    value={selectedDate}
                    onChange={(e) => {
                      setSelectedDate(e)
                      setInvitePatients([])
                    }}
                    // onMonthChange={setSelectedDate}
                    dayStyle={(date, modifier) => {
                      if (isToday(date)) {
                        return {
                          aspectRatio: '1',
                          borderBottom: `1px solid ${kPrimaryDark}`,
                          bottom: '20%',
                        }
                      }
                      return { aspectRatio: '1' }
                    }}
                    styles={{
                      cell: {
                        aspectRatio: '1',
                      },
                      day: {
                        aspectRatio: '1 !important',
                      },
                      weekend: {
                        color: 'black',
                      },
                      weekday: {
                        color: kPrimaryMain,
                      },
                      selected: {
                        borderRadius: '50%',
                      },
                      label: {
                        fontWeight: 'bold',
                        color: kPrimaryMain,
                      },
                    }}
                    initialMonth={new Date()}
                    minDate={new Date(2020, 0, 1)}
                    maxDate={new Date(2025, 11, 31)}
                  />
                </Box>
              </Box>
            </SimpleBar>
          </Box>
          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            maxWidth='88%'
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            <Box flexDirection='row' width='100%' display='flex' p={1}>
              <TextField
                variant='outlined'
                onChange={(event) => {
                  dispatch(
                    requestForDateWisePreAppointmentLists(
                      event.target.value,
                      selectedDate
                    )
                  )
                  setName(event.target.value)
                }}
                size='small'
                autoFocus={true}
                placeholder='Search By Patient Name'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img
                        src={`${process.env.PUBLIC_URL}/search.png`}
                        alt='s'
                      />
                    </InputAdornment>
                  ),
                }}
              />

              {appointmentSearchSlice.searchingAppointments && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                  px={1}
                >
                  <CircularProgress size={25} />
                </Box>
              )}
              {!isTherapist() && (
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-end'
                  alignContent='flex-end'
                  flexGrow='1'
                >
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={invitePatients.length === 0}
                    onClick={() => {
                      dispatch(sendBulkPatientInvitations(invitePatients))
                    }}
                  >
                    Invite
                  </Button>

                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      setOpenPatientBulkUpload(true)
                    }}
                  >
                    Bulk Upload
                  </Button>

                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      setOpenAddPatientForm(true)
                    }}
                  >
                    Invite New Patient
                  </Button>
                </Box>
              )}
            </Box>
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='Start'
              alignContent='center'
              overflow='auto'
              style={{
                backgroundColor: '#00000005',
              }}
            >
              {appointmentSearchSlice.searchingAppointments && (
                <LinearProgress />
              )}
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                overflow='auto'
                p={2}
              >
                <Typography variant='h6' color='primary'>
                  Pre Appointments
                </Typography>
              </Box>
              {appointmentSearchSlice.errorWhileSearchingOrders && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography variant='subtitle1' color='error' align='center'>
                    Error while searching IPD Appointments
                  </Typography>
                </Box>
              )}

              {appointmentSearchSlice.noResultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography variant='subtitle1' align='center'>
                    Add Pre Appointments
                  </Typography>
                </Box>
              )}

              {appointmentSearchSlice.availableAppointments && (
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height='100%'
                  overflow='auto'
                >
                  <PreAppointmentTable
                    key='pre_appointment_table'
                    onSelected={(rows) => {
                      setInvitePatients(
                        rows.map((member) => ({
                          patientId: member.patientId,
                          patientPhone: member.patientPhone,
                        }))
                      )
                    }}
                    onAppointmentSelected={(appointment) => {
                      setPreAppointmentDetails(appointment)
                      setShowCheckPatient(true)
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box />
          </Box>
        </Box>
      </Paper>

      {openAddPatientForm && (
        <AddPatientsPreAppointmentHandler
          open={openAddPatientForm}
          onBackClick={() => {
            dispatch(requestForDateWisePreAppointmentLists(name, selectedDate))
            setOpenAddPatientForm(false)
          }}
          onContinueClick={() => {
            dispatch(requestForDateWisePreAppointmentLists(name, selectedDate))
            setOpenAddPatientForm(false)
          }}
        />
      )}

      {openPatientBulkUpload && (
        <PatientPreAppointmentSetUpBulkUpload
          open={openPatientBulkUpload}
          onBackClick={() => {
            dispatch(requestForDateWisePreAppointmentLists(name, selectedDate))
            setOpenPatientBulkUpload(false)
          }}
          onContinueClick={() => {
            dispatch(requestForDateWisePreAppointmentLists(name, selectedDate))
            setOpenPatientBulkUpload(false)
          }}
        />
      )}

      {showAddPatient && preAppointmentDetails && (
        <AddNewPatient
          isLab={false}
          isAppointment={true}
          popup={showAddPatient}
          isMinimal={false}
          patientDetail={getPatientFromPreAppointment(preAppointmentDetails)}
          relatedPersonDetail={
            preAppointmentDetails.relatedPersonResource
              ? preAppointmentDetails.relatedPersonResource[0]
              : undefined
          }
          onPatientCreated={(
            createdPatient?: R4.IPatient,
            patientId?: string
          ) => {
            dispatch(showSuccessAlert('Patient added successfully'))
            if (createdPatient) {
              if (patientId) {
                const oldPatient: R4.IPatient = {
                  ...createdPatient,
                }
                const patId: string = patientId.split('/')[1]
                oldPatient.id = patId
                setCreatedPatient(oldPatient)
                setShowAddPatient(false)
                setShowCreateIpd(true)
              }
            }
          }}
          onError={() => {
            dispatch(
              showErrorAlert('Error while adding patient. Please try later')
            )
            dispatch(resetState())
            dispatch(resetPatientSearchPatient())
            setShowAddPatient(false)
          }}
          onCancelClicked={() => {
            dispatch(resetState())
            dispatch(resetPatientSearchPatient())
            setShowAddPatient(false)
          }}
        />
      )}

      {showCheckPatient && (
        <PatientChecker
          id='patient_checker'
          onClose={() => {
            setShowCheckPatient(false)
          }}
          onNewPatientCreated={() => {
            setShowCheckPatient(false)
            setShowAddPatient(true)
          }}
          onNoPatientsAvailable={() => {
            setShowCheckPatient(false)
            setShowAddPatient(true)
          }}
          onPatientSelected={(e) => {
            setShowCheckPatient(false)
            setCreatedPatient(e)
            setShowCreateIpd(true)
          }}
          open={showCheckPatient}
          patientData={getFhirPatientResourceFromPreAppointmentDetails(
            preAppointmentDetails
          )}
        />
      )}

      {showCreateIpd && (
        <CreateIpdAppointmentHandlerPreAppointment
          open={showCreateIpd}
          preselectedPatient={createdPatientDetails}
          id={2}
          onLabOrderCreated={(orderId: string, paymentType: string) => {
            if (paymentType === 'online') {
              // setDisplayId(orderId)
              setShowCreateIpd(false)
            } else {
              setShowCreateIpd(false)
            }
            // if (onDataUpdated) {
            //   onDataUpdated()
            // }
          }}
          onClose={() => {
            setShowCreateIpd(false)
          }}
        />
      )}
    </div>
  )
}
