import { Dialog, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    maxWidth: 1325,
    maxHeight: 'auto',
    backgroundColor: '#ffffff',
    variant: 'outlined',
  },
  dialogTitle: {
    paddingRight: '0px',
  },
}))

export default function Popup(props: any) {
  const { title, children, openPopup, setOpenPopup } = props
  const classes = useStyles()

  const handleClose = () => {
    setOpenPopup(false)
  }

  return (
    <Dialog
      open={openPopup}
      classes={{ paper: classes.paperContainer }}
      onClose={handleClose}
      disableBackdropClick
    >
      {children}
    </Dialog>
  )
}
