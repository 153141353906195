import { R4 } from '@ahryman40k/ts-fhir-types'

export const PatientAddressTypes: R4.ICoding[] = [
  {
    system: '"http://hl7.org/fhir/ValueSet/address-use',
    code: 'home',
    display: 'Home',
  },
  {
    system: '"http://hl7.org/fhir/ValueSet/address-use',
    code: 'work',
    display: 'Work',
  },
  {
    system: '"http://hl7.org/fhir/ValueSet/address-use',
    code: 'temp',
    display: 'Temporary',
  },
  {
    system: '"http://hl7.org/fhir/ValueSet/address-use',
    code: 'old',
    display: 'Old / Incorrect',
  },
  {
    system: '"http://hl7.org/fhir/ValueSet/address-use',
    code: 'billing',
    display: 'Billing',
  },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/pat-address-type',
    code: 'oth',
    display: 'Other',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)

export const PatientAddressTypesWithoutHome: R4.ICoding[] = [
  {
    system: '"http://hl7.org/fhir/ValueSet/address-use',
    code: 'work',
    display: 'Work',
  },
  {
    system: '"http://hl7.org/fhir/ValueSet/address-use',
    code: 'temp',
    display: 'Temporary',
  },
  {
    system: '"http://hl7.org/fhir/ValueSet/address-use',
    code: 'old',
    display: 'Old / Incorrect',
  },
  {
    system: '"http://hl7.org/fhir/ValueSet/address-use',
    code: 'billing',
    display: 'Billing',
  },
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/pat-address-type',
    code: 'oth',
    display: 'Other',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
