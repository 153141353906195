import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import {
  getExpandedAppointmentFromBundle,
  getRelativesData,
} from 'utils/common/patientDataTableHelper'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import {
  getGenderOfPatient,
  getLastNameOfPatient,
  getNameOfPatient,
  getValueRefValueExtensionsOfUrlForBasic,
} from 'utils/fhirResourcesHelper'
import axios, { CancelToken, CancelTokenSource } from 'axios'
import { MasterFhirClient } from 'services/masterFhirService'
import { GroupPatient } from 'models/groupPatient'
import { isOrgAdmin } from 'services/userDetailsService'
import { PatientSearchStatus } from './patinetCountStatus'

const initialState: PatientSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const patientSearchCountSlice = createSlice({
  name: 'patientSearchCountSlice',
  initialState,
  reducers: {
    searchingPatientDetails(
      state,
      action: PayloadAction<PatientSearchStatus>
    ) {},

    searchResults(state, action: PayloadAction<PatientSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.totalCount = action.payload.totalCount
      state.maleCount = action.payload.maleCount
      state.femaleCount = action.payload.femaleCount
    },

    noDataFoundForSearch(state, action: PayloadAction<PatientSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.totalCount = action.payload.totalCount
      state.maleCount = action.payload.maleCount
      state.femaleCount = action.payload.femaleCount
    },

    errorWhileSearching(state, action: PayloadAction<PatientSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.totalCount = action.payload.totalCount
      state.maleCount = action.payload.maleCount
      state.femaleCount = action.payload.femaleCount
    },
    resetStatePatientCount(state, action: PayloadAction<PatientSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.totalCount = undefined
      state.maleCount = undefined
      state.femaleCount = undefined
    },
  },
})

export const resetPatientSearchPatientForCount =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: PatientSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
    }
    dispatch(patientSearchCountSlice.actions.resetStatePatientCount(state))
  }

export const searchPatientCount =
  (unitId: string, mainOrg: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: PatientSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      patientSearchCountSlice.actions.errorWhileSearching(errorSearchPatient)
    )

    try {
      const searchParameters: any = {
        _count: 500,
        active: true,
      }
      if (mainOrg.length === 0) {
        searchParameters.organization = unitId
      } else {
        searchParameters.organization = mainOrg
      }

      const fhirClient: MasterFhirClient = new MasterFhirClient()
      let response: any
      if (isOrgAdmin()) {
        if (mainOrg.length === 0) {
          response = await fhirClient.doGetResource(
            `Patient?organization=${unitId}&_count=500`,
            ''
          )
        } else {
          response = await fhirClient.doGetResource(
            `Patient?organization=${mainOrg}&_count=500`,
            ''
          )
        }
      } else {
        response =
          await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
            `Patient`,
            '',
            searchParameters
          )
      }

      if (response !== undefined) {
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        let count: number = 0
        const patientResponses: R4.IBundle = response
        if (patientResponses.total) {
          if (patientResponses.total > 0) {
            if (mainOrg.length > 0) {
              if (patientResponses.entry) {
                const patientArray: R4.IPatient[] = patientResponses.entry.map(
                  (item) => item.resource as R4.IPatient
                )
                const malePatientArray: R4.IPatient[] = []
                const femalePatientArray: R4.IPatient[] = []
                const newArr: R4.IPatient[] = []

                patientArray.forEach((patient) => {
                  if (
                    getValueRefValueExtensionsOfUrlForBasic(
                      patient.extension ?? [],
                      'http://wellopathy.com/fhir/india/core/StructureDefinition/ManagingOrganizationUnit'
                    ) === unitId.split('/')[1]
                  ) {
                    newArr.push(patient)
                  }
                })
                newArr.forEach((patient) => {
                  if (
                    getGenderOfPatient(patient) === 'male' ||
                    getGenderOfPatient(patient).length === 0 ||
                    getGenderOfPatient(patient) === 'other'
                  ) {
                    malePatientArray.push(patient)
                  }
                  if (getGenderOfPatient(patient) === 'female') {
                    femalePatientArray.push(patient)
                  }
                })
                count = newArr.length

                const searchPatientResult: PatientSearchStatus = {
                  error: false,
                  noResultsAvailable: false,
                  resultsAvailable: true,
                  searching: false,
                  maleCount: malePatientArray.length,
                  femaleCount: femalePatientArray.length,
                  totalCount: count,
                }
                dispatch(
                  patientSearchCountSlice.actions.searchResults(
                    searchPatientResult
                  )
                )
                return
              }
            } else {
              const malePatientArray: R4.IPatient[] = []
              const femalePatientArray: R4.IPatient[] = []
              if (patientResponses.entry) {
                const patientArray: R4.IPatient[] = patientResponses.entry.map(
                  (item) => item.resource as R4.IPatient
                )

                patientArray.forEach((patient) => {
                  if (
                    getGenderOfPatient(patient) === 'male' ||
                    getGenderOfPatient(patient).length === 0 ||
                    getGenderOfPatient(patient) === 'other'
                  ) {
                    malePatientArray.push(patient)
                  }
                  if (getGenderOfPatient(patient) === 'female') {
                    femalePatientArray.push(patient)
                  }
                  //   if (patient.gender === undefined) {
                  //     malePatientArray.push(patient)
                  //   }
                })
              }

              count = patientResponses.total
              const searchPatientResult: PatientSearchStatus = {
                error: false,
                noResultsAvailable: false,
                resultsAvailable: true,
                searching: false,
                maleCount: malePatientArray.length,
                femaleCount: femalePatientArray.length,
                totalCount: count,
              }
              dispatch(
                patientSearchCountSlice.actions.searchResults(
                  searchPatientResult
                )
              )
              return
            }
          }
        }
        const noSearchResults: PatientSearchStatus = {
          error: false,
          noResultsAvailable: true,
          resultsAvailable: false,
          searching: false,
        }
        dispatch(
          patientSearchCountSlice.actions.noDataFoundForSearch(noSearchResults)
        )
        return

        // const errorWhileSearchPatient: PatientSearchStatus = {
        //   error: true,
        //   noResultsAvailable: false,
        //   resultsAvailable: false,
        //   searching: false,
        //   errorMessage: 'Error while searching',
        // }
        // dispatch(
        //   patientSearchSlice.actions.errorWhileSearching(
        //     errorWhileSearchPatient
        //   )
        // )
        // return
      }
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: PatientSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        patientSearchCountSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export default patientSearchCountSlice.reducer
