/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Typography,
  Divider,
  Grid,
  CircularProgress,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import ContentShimmer from 'react-content-shimmer'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import 'react-vis/dist/style.css'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import {
  isAyurvedaDoctor,
  isMedicalServiceProvider,
} from 'services/userDetailsService'
import { getAccessToken } from 'utils/authHelpers'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  addObservationDetailsForSys,
  deleteObservationDetails,
  fetchScore,
  getObservationResourceForPostForSystemicExam,
  getObservationsOfSpecificCategoryInEncounter,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { getVendorPartId } from 'utils/routes_helper'
import { OCARenderer } from 'wello-web-components'

interface AyurvedaHistoryAndSubjectiveProps {
  waistToHeight: string
  waistToHip: string
  bmi: string
}

export const ObesityCalculation: React.FC<AyurvedaHistoryAndSubjectiveProps> =
  ({ waistToHeight, waistToHip, bmi }: AyurvedaHistoryAndSubjectiveProps) => {
    const { t } = useTranslation(['common'])
    const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())
    const [observations, setObservations] = useState<R4.IObservation[]>([])
    const dispatch = useDispatch()
    const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
      initial: true,
    })

    const [total, setTotal] = useState<string>()
    const [scoreFetchingStatus, setScoreFetchingStatus] =
      useState<NetworkRequestStatus>({
        initial: true,
      })

    return (
      <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
        {/* {isAyurvedaDoctor() && (
          <Box width='100%' py={0.5}>
            <Typography
              variant='subtitle1'
              // color='initial'
            >
              Anthropometric Calculation
            </Typography>
          </Box>
        )} */}
        <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
            width='100%'
            border={4}
            style={{
              boxShadow: '0px 0px 4px #ccc',
              margin: '2px 0px',
              borderRadius: '1px',
              borderColor: '#F8F8F8',
              width: '100%',
              height: 'auto',
              backgroundColor: '#F8F8F8',
            }}
          >
            <Grid container direction='column' spacing={1}>
              <Grid
                container
                direction='row'
                id='vitals'
                style={{
                  backgroundColor: 'white',
                }}
              >
                <Grid item xs={3}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        minHeight={30}
                        maxHeight={100}
                        alignItems='center'
                        px={1}
                      >
                        <Typography
                          variant='subtitle2'
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          Waist to Height Ratio
                        </Typography>
                      </Box>
                    </Box>
                    <Box display='flex' width='2%'>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          minHeight: '30px',
                          maxHeight: '100px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        minHeight={30}
                        maxHeight={100}
                        alignItems='center'
                        px={1}
                      >
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 500,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          {waistToHeight}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container direction='row' id='vitals'>
                <Grid item xs={12}>
                  <Box width='100%' paddingLeft={0.2}>
                    <Divider
                      orientation='horizontal'
                      flexItem
                      style={{
                        height: '2px',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                direction='row'
                id='vitals'
                style={{
                  backgroundColor: kDialogueBackground,
                }}
              >
                <Grid item xs={3}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        minHeight={30}
                        maxHeight={100}
                        alignItems='center'
                        px={1}
                      >
                        <Typography
                          variant='subtitle2'
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          Waist to Hip Ratio
                        </Typography>
                      </Box>
                    </Box>
                    <Box display='flex' width='2%'>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          minHeight: '30px',
                          maxHeight: '100px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        minHeight={30}
                        maxHeight={100}
                        alignItems='center'
                        px={1}
                      >
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 500,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          {waistToHip}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Grid container direction='row' id='vitals'>
                <Grid item xs={12}>
                  <Box width='100%' paddingLeft={0.2}>
                    <Divider
                      orientation='horizontal'
                      flexItem
                      style={{
                        height: '2px',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                direction='row'
                id='vitals'
                style={{
                  backgroundColor: 'white',
                }}
              >
                <Grid item xs={3}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        minHeight={30}
                        maxHeight={100}
                        alignItems='center'
                        px={1}
                      >
                        <Typography
                          variant='subtitle2'
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 'bold',
                            lineHeight: 'normal',
                          }}
                        >
                          Body Mass Index
                        </Typography>
                      </Box>
                    </Box>
                    <Box display='flex' width='2%'>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          minHeight: '30px',
                          maxHeight: '100px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        minHeight={30}
                        maxHeight={100}
                        alignItems='center'
                        px={1}
                      >
                        {bmi.length > 0 && (
                          <Typography
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 500,
                              lineHeight: 'normal',
                            }}
                          >
                            {bmi} kg/m2
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              {/* ------------------------------------------------------------------------------- */}
            </Grid>
          </Box>
        </Box>
      </Box>
    )
  }
