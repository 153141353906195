/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { kPrimaryLight, kPrimaryMain } from 'configs/styles/muiThemes'
import PendingIcon from '@mui/icons-material/Pending'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import {
  getPaymentUpdate,
  getPreviousAppointmentCount,
  getReports,
} from 'redux/appointments/appointmentViewer/appointmentSearchSlice'
import { isAyurvedaDoctor, isDoctor } from 'services/userDetailsService'
import {
  getAddressOfPatient,
  getAddressOfPatientForOPDAppointment,
  getAppointmentIdForFollowUp,
  getDisplayOfSystemFromCodableConceptForAppointment,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getProfilePicPatient,
} from 'utils/fhirResourcesHelper'
import { hasSpaces } from 'utils/fhirResoureHelpers/allergyHelpers'
import {
  getAppointmentPaymentDetails,
  getAppointmentStatusText,
  getAppointmentTime,
  getPaymentStatusForAppointment,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import SocketContext from 'views/contexts/socketMessageContext'
import { GenderAndAgeTileWithoutBack } from '../patients/patient_profile/genderAgeTilleWithoutBackGround'

interface Props {
  appointmentDetails: FhirAppointmentDetail
  isOdd?: boolean
  onTap?: () => void
  onView?: () => void
  onViewReport?: (appointmentData: FhirAppointmentDetail) => void
  onPendingAppointment?: (appointmentData: FhirAppointmentDetail) => void
  onCancelClicked: () => void
  onRescheduleClicked: () => void
  onDownloadClicked: () => void
  dense?: boolean
  isSelected?: boolean
  index: string
}

export const AppointmentListDetailTile: React.FC<Props> = ({
  appointmentDetails,
  isOdd = false,
  onTap,
  onView,
  onPendingAppointment,
  onViewReport,
  onCancelClicked,
  onRescheduleClicked,
  onDownloadClicked,
  dense = false,
  isSelected,
  index,
}: Props) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false)
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [countUpdateStatus, setCountUpdateStatus] =
    useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })

  const [paymentStatus, setPaymentStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [loadingCount, setLoadingCount] = useState<boolean>(false)

  const [appointmentData, setAppointmentData] =
    useState<FhirAppointmentDetail>(appointmentDetails)

  const [appointmentDataForEncounter, setAppointmentDataForEncounter] =
    useState<FhirAppointmentDetail>(appointmentDetails)

  const [paymentDetails, setPaymentDetails] =
    useState<FhirAppointmentDetail>(appointmentDetails)

  const { eventData } = useContext(SocketContext)

  function getReportsData(appointmentDetailsData: FhirAppointmentDetail) {
    setUpdateStatus({ requesting: true })

    setLoading(true)

    getReports(appointmentDetailsData)
      .then((e: FhirAppointmentDetail) => {
        setLoading(false)
        if (e) {
          setAppointmentData(e)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while fetching reports. Please try again later'
            )
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  function getPaymentStatus(appointmentDetailsData: FhirAppointmentDetail) {
    setPaymentStatus({ requesting: true })

    setLoading(true)

    getPaymentUpdate(appointmentDetailsData)
      .then((e: FhirAppointmentDetail) => {
        setLoading(false)
        if (e) {
          setPaymentDetails(e)
          setPaymentStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setPaymentStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while fetching payment update. Please try again later'
            )
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        setPaymentStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  function requestAppointmentForPolling() {
    if (appointmentDetails.paymentReconcilation && dense === false) {
      if (
        appointmentDetails.paymentReconcilation.outcome &&
        appointmentDetails.paymentReconcilation.outcome !== 'complete'
      ) {
        getPaymentStatus(appointmentDetails)
      }
    }

    getReportsData(appointmentDetails)
  }

  useEffect(() => {
    const intervalId = setInterval(requestAppointmentForPolling, 10000)
    return () => clearInterval(intervalId)
  }, [requestAppointmentForPolling])

  useEffect(() => {
    const convertedData = eventData as any

    console.log(JSON.stringify(convertedData))
    console.log(convertedData)
    if (convertedData !== null) {
      getReportsData(appointmentDetails)
      if (
        appointmentDetails.paymentReconcilation.outcome &&
        appointmentDetails.paymentReconcilation.outcome !== 'complete'
      ) {
        getPaymentStatus(appointmentDetails)
      }
      if (convertedData.infoMessages && convertedData.infoMessages.length > 0) {
        dispatch(showSuccessAlert(convertedData.infoMessages[0]))
      }
      if (
        convertedData.errorMessages &&
        convertedData.errorMessages.length > 0
      ) {
        dispatch(showErrorAlert(convertedData.errorMessages[0]))
      }
    }
  }, [eventData])

  function getPreviousAppointmentData(
    appointmentDetailsData: FhirAppointmentDetail
  ) {
    setCountUpdateStatus({ requesting: true })

    setLoadingCount(true)

    getPreviousAppointmentCount(appointmentDetailsData)
      .then((e: FhirAppointmentDetail) => {
        setLoadingCount(false)
        if (e) {
          setAppointmentDataForEncounter(e)
          setCountUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setCountUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoadingCount(false)
          dispatch(
            showErrorAlert(
              'Error while fetching pending appointments. Please try again later'
            )
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setLoadingCount(false)
        setCountUpdateStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  useEffect(() => {
    getPaymentStatus(appointmentDetails)
    getReportsData(appointmentDetails)
    getPreviousAppointmentData(appointmentDetails)
  }, [appointmentDetails])

  return (
    <Box width='100%'>
      <Card
        elevation={isOdd ? 0 : 1}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 0,
          backgroundColor: isSelected
            ? kPrimaryLight
            : isOdd
            ? '#FFFFFFAF'
            : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 0,
          }}
        >
          {dense && (
            <Box display='flex' flexDirection='row' width='100%'>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                px={1}
              >
                <Avatar
                  alt={getNameOfPatient(appointmentDetails.patient) ?? ''}
                  src={getProfilePicPatient(appointmentDetails.patient) ?? ''}
                />
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                flexGrow={1}
                id='patient_name'
              >
                {getMiddleNameOfPatient(appointmentDetails.patient).length >
                  0 && (
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    style={{
                      textTransform: 'capitalize',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    {getNameOfPatient(appointmentDetails.patient)}{' '}
                    {getMiddleNameOfPatient(appointmentDetails.patient) ?? ''}{' '}
                    {getLastNameOfPatient(appointmentDetails.patient)}
                  </Typography>
                )}

                {getMiddleNameOfPatient(appointmentDetails.patient).length ===
                  0 && (
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    style={{
                      textTransform: 'capitalize',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    {getNameOfPatient(appointmentDetails.patient)}{' '}
                    {getMiddleNameOfPatient(appointmentDetails.patient) ?? ''}{' '}
                    {getLastNameOfPatient(appointmentDetails.patient)}
                  </Typography>
                )}
                <Typography
                  variant='body2'
                  color='initial'
                  style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
                >
                  {getAddressOfPatient(
                    appointmentDetails.patient.address ?? []
                  )}
                </Typography>

                <Typography variant='body2' color='initial'>
                  {getAppointmentTime(appointmentDetails)}
                </Typography>
                <Typography variant='subtitle1' color='initial'>
                  {getDisplayOfSystemFromCodableConceptForAppointment(
                    appointmentDetails.appointment.serviceType?.[0]
                  ) ?? '  In-Person'}
                </Typography>
              </Box>
            </Box>
          )}
          {dense === false && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              alignContent='center'
              p={0.5}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='15%'
              >
                <Box paddingLeft={0.1}>
                  <Typography variant='body2' color='initial'>
                    Appointment at
                  </Typography>
                </Box>
                <Typography variant='body2' color='initial'>
                  {getAppointmentTime(appointmentDetails)}
                </Typography>
                <Box paddingLeft={0.1}>
                  {getAppointmentIdForFollowUp(
                    appointmentDetails.appointment.supportingInformation ?? []
                  ).length > 0 && (
                    <Typography variant='subtitle1' color='initial'>
                      {`${
                        getDisplayOfSystemFromCodableConceptForAppointment(
                          appointmentDetails.appointment.serviceType?.[0]
                        ) ?? '  In-Person'
                      } (Follow Up)`}
                    </Typography>
                  )}
                </Box>

                {/* <Typography
                  variant='body2'
                  color='initial'
                  style={{ paddingLeft: 1 }}
                >
                  {appointmentDetails.appointment.comment}
                </Typography> */}
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
              >
                <Avatar
                  alt={getNameOfPatient(appointmentDetails.patient) ?? ''}
                  src={getProfilePicPatient(appointmentDetails.patient) ?? ''}
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignContent='flex-start'
                width='20%'
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='start'
                    px={1}
                    id={`patient${index}`}
                    paddingTop={1}
                  >
                    {getMiddleNameOfPatient(appointmentDetails.patient).length >
                      0 && (
                      <Typography
                        variant='subtitle1'
                        color='initial'
                        style={{
                          textTransform: 'capitalize',
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {getNameOfPatient(appointmentDetails.patient)}{' '}
                        {getMiddleNameOfPatient(appointmentDetails.patient) ??
                          ''}{' '}
                        {getLastNameOfPatient(appointmentDetails.patient)}
                      </Typography>
                    )}

                    {getMiddleNameOfPatient(appointmentDetails.patient)
                      .length === 0 && (
                      <Typography
                        variant='subtitle1'
                        color='initial'
                        style={{
                          textTransform: 'capitalize',
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {getNameOfPatient(appointmentDetails.patient)}{' '}
                        {getMiddleNameOfPatient(appointmentDetails.patient) ??
                          ''}{' '}
                        {getLastNameOfPatient(appointmentDetails.patient)}
                      </Typography>
                    )}
                  </Box>

                  <Box
                    display='flex'
                    flexDirection='column'
                    width='45%'
                    justifyContent='flex-start'
                    px={1}
                  >
                    <GenderAndAgeTileWithoutBack
                      patient={appointmentDetails.patient}
                      status={false}
                    />
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-start'
                    px={1}
                  >
                    <Typography
                      variant='body2'
                      color='initial'
                      style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
                    >
                      {getAddressOfPatientForOPDAppointment(
                        appointmentDetails.patient.address ?? []
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {paymentLoading === false && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='8%'
                  paddingLeft={3}
                >
                  {paymentLoading && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      px={1}
                    >
                      <CircularProgress size={20} />
                    </Box>
                  )}
                  <Typography
                    variant='subtitle2'
                    color='initial'
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    {paymentDetails.paymentReconcilation &&
                    paymentDetails.paymentReconcilation.outcome === 'complete'
                      ? 'Paid'
                      : 'Pending'}
                  </Typography>
                </Box>
              )}
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='35%'
                paddingLeft={4}
              >
                {appointmentDetails.appointment.comment &&
                  appointmentDetails.appointment.comment.trim().length > 0 && (
                    <Typography variant='subtitle1' color='initial'>
                      Reason for visit
                    </Typography>
                  )}
                {appointmentDetails.appointment.comment &&
                  appointmentDetails.appointment.comment.trim().length > 0 && (
                    <Box py={0.5} width='50%'>
                      {hasSpaces(appointmentDetails.appointment.comment) && (
                        <Typography
                          variant='subtitle2'
                          color='initial'
                          style={{
                            wordWrap: 'break-word',
                            whiteSpace: 'pre-line',
                            textOverflow: 'ellipsis',
                            paddingLeft: '2px',
                          }}
                        >
                          {appointmentDetails.appointment.comment
                            .split('\n')
                            .map((i: any, key: any) => (
                              <div key={i}>{i}</div>
                            ))}
                        </Typography>
                      )}
                      {hasSpaces(appointmentDetails.appointment.comment) ===
                        false && (
                        <Typography
                          variant='subtitle2'
                          color='initial'
                          style={{
                            wordWrap: 'break-word',
                            paddingLeft: '2px',
                          }}
                        >
                          <div
                            style={{
                              inlineSize: 500,
                              overflowWrap: 'break-word',
                            }}
                          >
                            {appointmentDetails.appointment.comment}
                          </div>
                        </Typography>
                      )}
                    </Box>
                  )}
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='8%'
              >
                {appointmentDetails.encounter && (
                  <Typography variant='subtitle1' color='initial'>
                    {appointmentDetails.appointment.status === 'fulfilled'
                      ? 'Completed'
                      : 'Started'}
                  </Typography>
                )}
                {appointmentDetails.encounter === undefined && (
                  <Typography
                    variant='subtitle1'
                    color={
                      appointmentDetails.appointment.status === 'fulfilled' ||
                      appointmentDetails.appointment.status === 'booked'
                        ? 'initial'
                        : 'error'
                    }
                  >
                    {getAppointmentStatusText(appointmentDetails)}
                  </Typography>
                )}
              </Box>
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='center'
                width='12%'
              >
                {/* {loading && (isDoctor() || isAyurvedaDoctor()) && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                  >
                    <CircularProgress size={20} />
                  </Box>
                )} */}
                {(appointmentData.encounter ||
                  getAppointmentIdForFollowUp(
                    appointmentData.appointment.supportingInformation ?? []
                  ).length > 0 ||
                  appointmentData.appointment.basedOn) &&
                  appointmentData.reports &&
                  appointmentData.reports.length > 0 &&
                  (isDoctor() || isAyurvedaDoctor()) && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='25%'
                      paddingTop={0.5}
                    >
                      <Tooltip title='View Report' id='view'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          onClick={() => {
                            if (onViewReport) onViewReport(appointmentData)
                          }}
                          id={`view_report_icon${index}`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/Lab_Report.ico`}
                            alt='view'
                            id='view_img'
                            height={19}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}

                {(appointmentData.encounter ||
                  getAppointmentIdForFollowUp(
                    appointmentData.appointment.supportingInformation ?? []
                  ).length > 0 ||
                  appointmentData.appointment.basedOn) &&
                  appointmentData.diagnosticReport &&
                  appointmentData.diagnosticReport.length > 0 && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='25%'
                      paddingTop={0.5}
                    >
                      <Tooltip title='View Report' id='view'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          onClick={() => {
                            if (onViewReport) onViewReport(appointmentData)
                          }}
                          id={`view_report_icon${index}`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/Lab_Report.ico`}
                            alt='view'
                            id='view_img'
                            height={19}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}

                {appointmentDetails.appointment.status &&
                  getAppointmentStatusText(appointmentDetails) ===
                    'Completed' && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='25%'
                    >
                      <Tooltip title='View OPD Details' id='view'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          onClick={() => {
                            if (onTap) onTap()
                          }}
                          id={`view_icon${index}`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                            id={`view_img${index}`}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                {appointmentDetails.appointment.status &&
                  getAppointmentStatusText(appointmentDetails) === 'Booked' &&
                  appointmentDetails.enabled && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='30%'
                    >
                      {loadingCount && (
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          px={1}
                        >
                          <CircularProgress size={20} />
                        </Box>
                      )}

                      {appointmentDataForEncounter.notFound && (
                        <Tooltip
                          title='Start/Continue Appointment'
                          id={`start${index}`}
                        >
                          <IconButton
                            aria-label='btn_ord_reschedule'
                            color='primary'
                            onClick={() => {
                              if (onView) onView()
                            }}
                            id={`start_appointment${index}`}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/editIcon.png`}
                              alt='view'
                              id={`start_img${index}`}
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                      {appointmentDataForEncounter.encounterCount &&
                        appointmentDataForEncounter.encounterCount > 0 && (
                          <Tooltip
                            title='Pending Appointments'
                            id={`pending${index}`}
                          >
                            <IconButton
                              aria-label='btn_ord_reschedule'
                              color='primary'
                              onClick={() => {
                                if (onPendingAppointment)
                                  onPendingAppointment(
                                    appointmentDataForEncounter
                                  )
                              }}
                              id={`pending_items${index}`}
                            >
                              <PendingIcon
                                style={{ color: kPrimaryMain }}
                                id={`pending_img${index}`}
                              />
                              {/* <img
                              src={`${process.env.PUBLIC_URL}/editIcon.png`}
                              alt='view'
                              id={`start_img${index}`}
                            /> */}
                            </IconButton>
                          </Tooltip>
                        )}
                    </Box>
                  )}

                {(getAppointmentPaymentDetails(paymentDetails) ||
                  getPaymentStatusForAppointment(appointmentDetails)) && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='30%'
                    paddingTop={0.3}
                  >
                    <Tooltip title='Invoice' id={`billing${index}`}>
                      <IconButton
                        aria-label='btn_ord_reschedule'
                        color='primary'
                        onClick={() => {
                          if (onDownloadClicked) onDownloadClicked()
                        }}
                        id={`invoice_appointment${index}`}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/bill.ico`}
                          alt='view'
                          id='start_img'
                          height={19}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
                {moment(appointmentDetails.start).isAfter(moment(new Date())) &&
                  appointmentDetails.appointment.status &&
                  appointmentDetails.encounter === undefined &&
                  getAppointmentStatusText(appointmentDetails) === 'Booked' && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='30%'
                    >
                      <Tooltip title='Re-Schedule' aria-label='Re-Schedule'>
                        <IconButton
                          onClick={() => {
                            if (onRescheduleClicked) onRescheduleClicked()
                          }}
                          id={`reschedule_appointment${index}`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/reschedule.ico`}
                            alt='reschedule'
                            height={23}
                            // height='110%'
                            // width='110%'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                {moment(appointmentDetails.start).isAfter(moment(new Date())) &&
                  appointmentDetails.appointment.status &&
                  getAppointmentStatusText(appointmentDetails) === 'Booked' &&
                  appointmentDetails.encounter === undefined && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='30%'
                    >
                      <Tooltip title='Cancel' aria-label='Cancel'>
                        <IconButton
                          onClick={() => {
                            if (onCancelClicked) onCancelClicked()
                          }}
                          id={`cancel_appointment${index}`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/cancel_ico.ico`}
                            alt='cancel'
                            height={23}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}
