import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { addNewPathyaApathyBundle } from 'utils/sopaNotes/plan/pathyaApathyaHelpers'

export interface AddPathyaApathyaProps {
  open: boolean
  patientReference: R4.IReference
  encounterReference: R4.IReference
  pathyaApathyaTypeCode?: R4.ICoding
  onClose: () => void
  onLabDiagnosticsAdded: () => void
}
export const AddPathyaApathya: React.FC<AddPathyaApathyaProps> = ({
  open,
  onClose,
  onLabDiagnosticsAdded,
  pathyaApathyaTypeCode,
  patientReference,
  encounterReference,
}) => {
  const { t } = useTranslation(['common'])

  const [apathya, setApathya] = useState<string>()
  const [pathya, setPathya] = useState<string>()

  function resetForm() {
    Promise.resolve().then(() => {
      setApathya(undefined)
      setPathya(undefined)
    })
  }

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const dispatch = useDispatch()

  function addCurrentDetails() {
    if (!_.isEmpty(pathya) || !_.isEmpty(apathya)) {
      setUpdateStatus({ requesting: true })
      addNewPathyaApathyBundle(
        patientReference,
        encounterReference,
        pathyaApathyaTypeCode!,
        pathya,
        apathya
      ).then((e) => {
        if (e) {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(
            showSuccessAlert('Pathya Apathya details added successfully')
          )

          onLabDiagnosticsAdded()

          onClose()
        } else {
          setUpdateStatus({
            requesting: false,
            requestError: true,
          })
          dispatch(
            showErrorAlert(
              'Error while adding Pathya Apathya. Please try again later'
            )
          )
        }
      })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetForm()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>{`Add Pathya Apathya for ${
        pathyaApathyaTypeCode?.display ?? ''
      }`}</DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#ededed',
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0,
        }}
      >
        <Box marginX={3} marginY={1} display='flex' flexDirection='column'>
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            py={0.5}
            justifyItems='center'
            alignItems='center'
          >
            <Box width='20%' display='flex' maxWidth='100px'>
              <Typography variant='subtitle2' color='initial'>
                Pathya
              </Typography>
            </Box>

            <Box flexGrow={1}>
              <TextField
                id='txt_pathya_notes'
                variant='outlined'
                size='small'
                fullWidth
                multiline
                style={{ backgroundColor: '#fff' }}
                value={pathya}
                onChange={(e) => {
                  setPathya(e.target.value)
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            py={0.5}
            justifyItems='center'
            alignItems='center'
          >
            <Box width='20%' display='flex' maxWidth='100px'>
              <Typography variant='subtitle2' color='initial'>
                Apathya
              </Typography>
            </Box>

            <Box flexGrow={1}>
              <TextField
                id='txt_apathya_notes'
                variant='outlined'
                size='small'
                style={{ backgroundColor: '#fff' }}
                fullWidth
                multiline
                value={apathya}
                onChange={(e) => {
                  setApathya(e.target.value)
                }}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          display='flex'
          flexDirection='row'
          flexGrow={1}
          px={1}
          justifyContent='flex-end'
          width='100%'
        >
          {updateStatus.requesting && (
            <Box
              flexDirection='row'
              display='flex'
              alignSelf='center'
              justifyContent='center'
            >
              <CircularProgress size={25} />
            </Box>
          )}

          <Box paddingX={1}>
            <Button
              onClick={() => {
                resetForm()
                onClose()
              }}
              variant='outlined'
              id='cancel'
              disableElevation
            >
              {t('labelCommon:cancel')}
            </Button>
          </Box>

          <Box paddingX={1}>
            <Button
              onClick={() => {
                addCurrentDetails()
              }}
              variant='contained'
              color='primary'
              id='save'
              disableElevation
              disabled={
                (_.isEmpty(pathya) && _.isEmpty(apathya)) ||
                updateStatus.requesting
              }
            >
              {t('labelCommon:Save')}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
