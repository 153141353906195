import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, MenuItem, TextField, TextFieldProps } from '@material-ui/core'
import { ArrowDropDownSharp } from '@material-ui/icons'
import React, { useState } from 'react'
import { WelloFormItemLabel } from 'wello-web-components'

interface WelloSelectCodingProps {
  title?: string
  id?: string
  textFieldProps: TextFieldProps
  availableCodings: R4.ICoding[]
  onChanged: (selectedCoding: R4.ICoding) => void
  preSelectedCoding?: R4.ICoding
  displayDoneLabel?: boolean
}

export const WelloSelectFhirCodingForTherapy: React.FunctionComponent<WelloSelectCodingProps> =
  ({
    title,
    id,
    textFieldProps,
    availableCodings,
    onChanged,
    preSelectedCoding,
    displayDoneLabel,
  }: WelloSelectCodingProps) => {
    const options: R4.ICoding[] = [
      { code: '', display: 'Select' },
      ...availableCodings.sort((first, second) => {
        if ((first.display ?? '') < (second.display ?? '')) {
          return -1
        }
        if ((first.display ?? '') > (second.display ?? '')) {
          return 1
        }
        return 0
      }),
    ]
    const [item, setItem] = useState<R4.ICoding>(
      preSelectedCoding ?? { code: '', display: 'Select' }
    )
    return (
      <Box display='flex' flexDirection='column' flexGrow={1}>
        {title && (
          <WelloFormItemLabel
            title={title}
            displayDoneLabel={displayDoneLabel}
          />
        )}
        <Box>
          <TextField
            variant='outlined'
            fullWidth
            size='small'
            select
            InputProps={{
              id: id ?? '1',
            }}
            id={id ?? ''}
            SelectProps={{
              IconComponent: ArrowDropDownSharp,
              id: id ?? '',
            }}
            value={item?.code}
            label={item?.code === '' ? 'Select' : ''}
            onChange={(val) => {
              const selectedItem: R4.ICoding | undefined =
                availableCodings.find(
                  (gender) => gender.code === val.target.value
                )
              if (selectedItem) {
                setItem(selectedItem)
                onChanged(selectedItem)
              }
            }}
            {...textFieldProps}
          >
            {options.map((option) => (
              <MenuItem
                style={{ maxHeight: availableCodings.length <= 4 ? 18 : 200 }}
                id={option.code}
                key={option.code}
                value={option.code}
                selected={item?.code === option.code}
                disabled={option?.code === ''}
              >
                {option.display}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
    )
  }
