import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CurrentLoggedInUser, RoleDetails } from 'models/currentUserDetails'
import { getCurrentUserDetails } from 'redux/administration/userSetup/userProfileCompletionSlice/userProfileCompletionSlice'
import reschedulingAppointmentManagerSlice from 'redux/appointments/rescheduleAppointmentManager/reschedulingAppointmentManagerSlice'
import { AppThunk } from 'redux/store'
import { AuthApiClient } from 'services/authApiService'
import {
  getUserDetails,
  updateUserDetailsFromServer,
} from 'services/userDetailsService'
import * as urlencode from 'urlencode'
import { logger } from 'utils/logger'
import { getVendorPartId } from 'utils/routes_helper'
import { getUrlPathParameter } from 'utils/urlHelper'
import {
  isAuthenticated,
  storeToken,
  storeUserDetails,
  storeUserInfo,
} from '../../utils/authHelpers'
import { AuthType } from './authTypes'

const initialState: AuthType = {
  checking: true,
  authenticated: false,
  authorized: false,
  userDetails: getUserDetails(),
}

const redirectUrl: string = urlencode.encode(
  process.env.REACT_APP_REDIRECT_URL ?? ''
)

const authSlice = createSlice({
  name: 'authCheck',
  initialState,
  reducers: {
    updateAuthenticationState(state, action: PayloadAction<AuthType>) {
      state.checking = action.payload.checking
      state.authenticated = action.payload.authenticated
      state.authorized = action.payload.authorized
      state.userDetails = action.payload.userDetails
      state.error = action.payload.error
      state.noOrganization = action.payload.noOrganization
      state.displayRoleSelection = action.payload.displayRoleSelection
      state.errorMessage = action.payload.errorMessage
    },
  },
})

export const checkAuthentication = (): AppThunk => async (dispatch) => {
  const acv: AuthType = {
    checking: true,
    authenticated: false,
    authorized: false,
    error: false,
  }
  dispatch(authSlice.actions.updateAuthenticationState(acv))
  const authToken: string | null = isAuthenticated()
  if (authToken != null) {
    const currentlyStoredDetails = getUserDetails()
    if (currentlyStoredDetails?.practitionerRole) {
      // window.location.href = `${getVendorPartId()}/dashboard`
      const authState: AuthType = {
        checking: false,
        authenticated: true,
        authorized: true,
        error: false,
        userDetails: currentlyStoredDetails,
        displayRoleSelection: false,
      }
      dispatch(authSlice.actions.updateAuthenticationState(authState))
    } else {
      const authState: AuthType = {
        checking: false,
        authenticated: true,
        authorized: true,
        error: false,
        userDetails: currentlyStoredDetails,
        displayRoleSelection: true,
      }
      dispatch(authSlice.actions.updateAuthenticationState(authState))
    }
  } else {
    const authState: AuthType = {
      checking: false,
      authenticated: false,
      authorized: false,
      error: false,
      noOrganization: true,
    }
    dispatch(authSlice.actions.updateAuthenticationState(authState))
  }
}

export const checkAuthorization =
  (url: string): AppThunk =>
  async (dispatch) => {
    logger.error(url)
    const params = new URLSearchParams(url)

    if (params != null) {
      window.history.pushState('object or string', 'Title', `/`)
      if (!isAuthenticated()) {
        const authState: AuthType = {
          checking: true,
          authenticated: false,
          authorized: false,
          error: false,
        }
        dispatch(authSlice.actions.updateAuthenticationState(authState))
        const code = getUrlPathParameter(url, 'code')
        if (code != null) {
          const verifier: string | null = localStorage.getItem('verifier')
          const state: string | null = localStorage.getItem('state')
          try {
            const auth: AuthApiClient = new AuthApiClient()
            const clientId: string =
              process.env.REACT_APP_NAME === 'WelloLAB'
                ? 'wello_lab_web'
                : process.env.REACT_APP_NAME === 'WelloDR'
                ? 'wello_clinic_web'
                : 'wello_admin_web'
            const data: any = await auth.doPostFormRequest(
              '/oidc/token',
              `client_id=${clientId}&grant_type=authorization_code&code=${code}&code_verifier=${verifier}&redirect_uri=${redirectUrl}&resource=https%3A%2F%2Fapi.wellopathy.com%2Fwellostack`
            )

            storeToken(data.id_token, data.access_token)
            const userData = await auth.doGetRequest('/oidc/userinfo')
            storeUserInfo({ ...userData })
            const res: boolean = await updateUserDetailsFromServer(true)

            if (res) {
              const currentUser = getUserDetails()
              if (currentUser) {
                if (
                  currentUser.availableRoles &&
                  currentUser.availableRoles.length > 1
                ) {
                  const successState: AuthType = {
                    checking: false,
                    authenticated: true,
                    authorized: true,
                    error: false,
                    userDetails: {
                      ...currentUser,
                      practitionerRole: undefined,
                      locationDetails: undefined,
                      unitOrganization: undefined,
                    },
                    displayRoleSelection: true,
                  }
                  dispatch(
                    authSlice.actions.updateAuthenticationState(successState)
                  )
                } else {
                  const successState: AuthType = {
                    checking: false,
                    authenticated: true,
                    authorized: true,
                    error: false,
                    userDetails: currentUser,
                    displayRoleSelection: false,
                  }
                  dispatch(getCurrentUserDetails())

                  dispatch(
                    authSlice.actions.updateAuthenticationState(successState)
                  )
                }

                return
              }
            }

            const errorState: AuthType = {
              checking: false,
              authenticated: false,
              authorized: false,
              error: true,
              displayRoleSelection: false,
            }

            dispatch(authSlice.actions.updateAuthenticationState(errorState))
          } catch (error) {
            const errorState: AuthType = {
              checking: false,
              authenticated: false,
              authorized: false,
              error: true,
              displayRoleSelection: false,
            }
            dispatch(authSlice.actions.updateAuthenticationState(errorState))
          }
        } else {
          const error = getUrlPathParameter(url, 'error')

          const errorMessage = getUrlPathParameter(url, 'error_description')

          const errorState: AuthType = {
            checking: false,
            authenticated: false,
            authorized: false,
            error: true,
            displayRoleSelection: false,
            errorMessage: errorMessage != null ? errorMessage : undefined,
          }
          dispatch(authSlice.actions.updateAuthenticationState(errorState))
        }
      } else {
        const errorState: AuthType = {
          checking: false,
          authenticated: false,
          authorized: false,
          error: true,
          displayRoleSelection: false,
        }
        dispatch(authSlice.actions.updateAuthenticationState(errorState))
      }
    } else {
      const errorState: AuthType = {
        checking: false,
        authenticated: false,
        authorized: false,
        error: true,
        displayRoleSelection: false,
      }
      dispatch(authSlice.actions.updateAuthenticationState(errorState))
    }
  }

export const setRoleAndUnit =
  (selectedRoleDetails: RoleDetails, authStatus: AuthType): AppThunk =>
  async (dispatch) => {
    const authState: AuthType = {
      ...authStatus,
      checking: true,
    }
    dispatch(authSlice.actions.updateAuthenticationState(authState))

    const currentlyStoredDetails = getUserDetails()
    const newDetails: CurrentLoggedInUser = {
      ...currentlyStoredDetails!,
      practitionerRole: selectedRoleDetails.practitionerRole,
      locationDetails: selectedRoleDetails.locationDetails,
      unitOrganization: selectedRoleDetails.unitOrganization,
    }
    if (currentlyStoredDetails) {
      const res = await storeUserDetails(newDetails)
      if (res) {
        const successState: AuthType = {
          checking: false,
          authenticated: true,
          authorized: true,
          error: false,
          userDetails: newDetails,
          displayRoleSelection: false,
        }
        dispatch(getCurrentUserDetails())
        window.location.href = '/'
        dispatch(authSlice.actions.updateAuthenticationState(successState))
        return
      }
    }
    const errorState: AuthType = {
      checking: false,
      authenticated: false,
      authorized: false,
      error: true,
      displayRoleSelection: false,
    }

    dispatch(authSlice.actions.updateAuthenticationState(errorState))
  }
export default authSlice.reducer
