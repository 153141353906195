/* eslint-disable react/display-name */
import MaterialTable, { MTableToolbar } from '@material-table/core'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  Switch,
  TablePagination,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import { TherapyDataUpdate } from 'models/therapyDataUpdate'
// import { kDialogueBackground } from 'configs/styles/muiThemes'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  activateCatalog,
  deletingCatalog,
} from 'redux/valueSet/therapyList/addTherapyCatalougeSlice'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import {
  getTherapyBundleActivate,
  getTherapyBundleDelete,
} from 'utils/labHelpers/uploadHelper'
import { ConfirmationDialog } from 'views/components/common/confirmationDialog'
import { EditTherapy } from './editTherapy'

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      //   padding: 4,
    },
  },
})

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: '#9E9DDC',
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)

interface TherapyTableProperties {
  usersData: TherapyDataUpdate[]
}

export const TherapyDataTable: React.FC<TherapyTableProperties> = ({
  usersData,
}: TherapyTableProperties) => {
  const [nameFocus, setNameFocus] = useState(true)
  const [categoryFocus, setCategoryFocus] = useState(true)
  const [durationFocus, setDurationFocus] = useState(true)
  const [priceFocus, setPriceFocus] = useState(true)
  const [billableFocus, setBillableFocus] = useState(true)
  const [statusFocus, setStatusFocus] = useState(true)

  const [nameFilter, setNameFilter] = useState('')
  const [categoryFilter, setCategoryFilter] = useState('')
  const [priceFilter, setPriceFilter] = useState('')
  const [durationFilter, setDurationFilter] = useState('')
  const [billableFilter, setBillableFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('')

  const [data, setData] = useState([...usersData])

  const filterName = (value: any, filed: string) => {
    setNameFocus(true)
    if (value) {
      const filteredData = usersData.filter((d) =>
        (d?.name ?? '')
          .replace(/\s+/g, '')
          .toLowerCase()
          .includes(value.replace(/\s+/g, '').toLowerCase())
      )
      setData(filteredData)
    } else {
      setData([...usersData])
    }
    setNameFilter(value)
    setCategoryFocus(false)
    setDurationFocus(false)
    setPriceFocus(false)
    setBillableFocus(false)
    setStatusFocus(false)
  }

  const filterCategory = (value: any, filed: string) => {
    setCategoryFocus(true)
    if (value) {
      const filteredData = usersData.filter((d) =>
        (d?.category ?? '')
          .toLocaleUpperCase()
          .includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...usersData])
    }
    setCategoryFilter(value)
    setNameFocus(false)
    setCategoryFocus(false)
    setDurationFocus(false)
    setPriceFocus(false)
    setBillableFocus(false)
    setStatusFocus(false)
  }

  const filterDuration = (value: any, filed: string) => {
    setDurationFocus(true)
    if (value) {
      const filteredData = usersData.filter((d) =>
        (d?.duration ?? '')
          .toString()
          .toLocaleUpperCase()
          .includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...usersData])
    }
    setDurationFilter(value)
    setNameFocus(false)
    setCategoryFocus(false)
    setPriceFocus(false)
    setBillableFocus(false)
    setStatusFocus(false)
  }

  const filterPrice = (value: any, filed: string) => {
    setPriceFocus(true)
    if (value) {
      const filteredData = usersData.filter((d) =>
        (d?.price ?? '')
          .toString()
          .toLocaleUpperCase()
          .includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...usersData])
    }
    setPriceFilter(value)
    setNameFocus(false)
    setCategoryFocus(false)
    setDurationFocus(false)
    setBillableFocus(false)
    setStatusFocus(false)
  }

  const filterBillable = (value: any, filed: string) => {
    setBillableFocus(true)
    if (value) {
      const filteredData = usersData.filter((d) =>
        (d?.billable ?? '')
          .toLocaleUpperCase()
          .includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...usersData])
    }
    setBillableFilter(value)
    setNameFocus(false)
    setCategoryFocus(false)
    setDurationFocus(false)
    setPriceFocus(false)
    setStatusFocus(false)
  }

  const filterStatus = (value: any, filed: string) => {
    setStatusFocus(true)
    if (value) {
      const filteredData = usersData.filter((d) =>
        (d?.status ?? '')
          .toLocaleUpperCase()
          .includes(value.toLocaleUpperCase())
      )
      filteredData.sort((a, b) =>
        (a?.status ?? '')
          .toLowerCase()
          .localeCompare((b?.status ?? '').toLowerCase())
      )
      filteredData.reverse()
      setData(filteredData)
    } else {
      setData([...usersData])
    }
    setStatusFilter(value)
    setNameFocus(false)
    setCategoryFocus(false)
    setDurationFocus(false)
    setBillableFocus(false)
    setPriceFocus(false)
  }

  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  const [deleteTherapy, setDeleteTherapy] = useState(false)

  const [therapyDetail, setTherapyDetail] = React.useState<TherapyDataUpdate>()

  const [toActive, setToActive] = useState(false)

  const [status, setStatus] = useState(true)

  const [inactive, setInactive] = useState(false)

  const dispatch = useDispatch()

  const classes = useStyles()
  const convertedData = usersData
  const { height, width } = useWindowDimensions()
  const { t } = useTranslation()

  const tableRef = React.useRef()

  //

  const columns = [
    {
      title: (
        <Box minWidth='150px' minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('labelCommon:Therapy')}
          </Typography>
        </Box>
      ),
      field: 'name',
      width: '20%',
      customSort: (a: any, b: any) =>
        a.name.localeCompare(b.name, 'en', {
          sensitivity: 'base',
        }),
      render: (rowData: any) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <Typography variant='subtitle2' noWrap>
            &nbsp;&nbsp;{rowData.name}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box maxWidth={200}>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={nameFocus}
            placeholder={t('labelCommon:Therapy')}
            value={nameFilter}
            onChange={(e) => filterName(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography variant='subtitle1' color='primary' noWrap>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('labelCommon:Category')}
          </Typography>
        </Box>
      ),
      field: 'category',
      width: '20%',
      cellStyle: {
        width: 150,
        maxWidth: 175,
      },
      render: (rowData: any) => (
        <Box>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            &nbsp;&nbsp;&nbsp;&nbsp;{rowData.category}
          </Typography>
          {/* )} */}
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box maxWidth={200}>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={categoryFocus}
            placeholder={t('labelCommon:Category')}
            value={categoryFilter}
            onChange={(e) =>
              filterCategory(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box minWidth='80px' minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            color='primary'
            style={{ fontSize: 14 }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Duration&nbsp;(mins)
          </Typography>
        </Box>
      ),
      field: 'duration',
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {rowData.duration}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box maxWidth={200}>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={durationFocus}
            placeholder={t('labelCommon:Duration')}
            value={durationFilter}
            onChange={(e) =>
              filterDuration(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box minWidth='70px' minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Billing
          </Typography>
        </Box>
      ),
      field: 'billing',
      width: '20%',
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Box paddingLeft={0.5} display='flex' alignItems='center'>
            <Typography variant='subtitle2' color='textPrimary' noWrap>
              &nbsp;&nbsp;&nbsp;{rowData.billableCode.display}
            </Typography>
          </Box>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box maxWidth={200}>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={billableFocus}
            placeholder={t('labelCommon:Billable')}
            value={billableFilter}
            onChange={(e) =>
              filterBillable(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box minWidth='60px' minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{t('labelCommon:Price')}&nbsp;(&#x20b9;)
          </Typography>
        </Box>
      ),
      field: 'price',
      width: '10%',
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Box paddingLeft={0.5} display='flex' alignItems='center'>
            <Typography variant='subtitle2' color='textPrimary' noWrap>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{rowData.price}
            </Typography>
          </Box>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box maxWidth={200}>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={priceFocus}
            placeholder={t('labelCommon: Price')}
            value={priceFilter}
            onChange={(e) => filterPrice(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box minWidth='60px' minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Status
          </Typography>
        </Box>
      ),
      field: 'status',
      width: '10%',
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Box paddingLeft={0.5} display='flex' alignItems='center'>
            <Typography variant='subtitle2' color='textPrimary' noWrap>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{rowData.status}
            </Typography>
          </Box>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box maxWidth={200}>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={statusFocus}
            placeholder={t('labelCommon: Status')}
            value={statusFilter}
            onChange={(e) =>
              filterStatus(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: <Box minWidth='180px' minHeight='15px' maxHeight='15px' />,
      field: 'status',
      // width: '70%',
      render: (rowData: any) => (
        <Box
          display='flex'
          flexDirection='column'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Grid container>
            <Grid item sm={6}>
              <Box display='flex' justifyContent='flex-end' p={2}>
                <Box mr={2} mt={1}>
                  {rowData.active == true && (
                    <CreateIcon
                      fontSize='small'
                      color='primary'
                      onClick={() => {
                        setTherapyDetail(rowData)
                        setShowPopup(true)
                      }}
                    />
                  )}
                </Box>
                <Box mr={2} mt={1}>
                  {/* <DeleteIcon fontSize="small" color="primary"
                    onClick={() => {
                    if(rowData.status == 'active')
                      setDeleteSubstance(true)
                    setSubstanceDetail(rowData)
                    }}
                /> */}
                  {rowData.active == true && (
                    <AntSwitch
                      checked={status}
                      onChange={(event) => {
                        setDeleteTherapy(true)
                        setTherapyDetail(rowData)
                      }}
                      name='Active'
                    />
                  )}
                  {rowData.active == false && (
                    <AntSwitch
                      checked={inactive}
                      onChange={(event) => {
                        setToActive(true)
                        setTherapyDetail(rowData)
                      }}
                      name='InActive'
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ),
      filtering: false,
    },
  ]

  return (
    <Box
      style={{ overflow: 'hidden', width: '100%', display: 'flex' }}
      paddingLeft={1.5}
    >
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                color: '#333333',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),

          Container: (props) => <Paper {...props} elevation={0} />,

          Pagination: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                display: 'flex',
                justifyContent: 'left',
                padding: '0px',
                height: '60px',
                color: '#333333',
              }}
            >
              <TablePagination
                {...props}
                // rowsPerPageOptions={[10, 20, 40, 60, 80]}
                SelectProps={{
                  style: {
                    fontSize: 14,
                    backgroundColor: '#ececec',
                  },
                }}
              />
            </div>
          ),
        }}
        columns={columns}
        data={data}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        onRowClick={(event, rowData) => {
          //   i18n.changeLanguage(language ?? 'en')
          //   if (rowData) onPatientSelected(rowData)
          //   setSelectedRow(true)
          //   setSelectedRowId(rowData?.id ?? '')
        }}
        tableRef={tableRef}
        options={{
          showTitle: false,
          search: false,
          padding: 'dense',
          //   searchFieldVariant: 'outlined',
          filtering: true,
          thirdSortClick: false,
          pageSize: 10,
          pageSizeOptions: [10, 20, 40, 60, 80, 110],
          //   columnsButton: true,
          maxBodyHeight: `${height - 190}px`,
          minBodyHeight: `${height - 260}px`,
          toolbarButtonAlignment: 'right',
          keepSortDirectionOnColumnSwitch: true,
          sorting: true,
          headerStyle: {
            backgroundColor: '#ececec',
            color: '#333333',
            width: '100%',
            position: 'sticky',
            top: 0,
            height: 40,
          },

          filterRowStyle: {
            position: 'sticky',
            zIndex: 5,
            top: 40,
            backgroundColor: '#ececec',
            color: '#333333',
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          //   actionsColumnIndex: -1,
          //   search: true,
          //   searchFieldAlignment: 'left',
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />

      {showPopup && (
        <EditTherapy
          selectedValue={therapyDetail as TherapyDataUpdate}
          open={showPopup}
          onCancelClick={() => {
            setShowPopup(false)
          }}
        />
      )}

      {/* 
    {deleteTherapy && <DeleteTherapy
        open={deletePopup}
        onCancelClick={() => {
            setDeletePopup(false)
            }}
    />} */}

      <ConfirmationDialog
        open={deleteTherapy}
        displayText='Therapy will be Inactivated. Do you want to continue?'
        title='Inactivate Therapy'
        doneButtonText='Inactivate'
        cancelButtonText='Back'
        onClose={() => {
          setDeleteTherapy(false)
        }}
        onDiscard={() => {
          if (therapyDetail) {
            const bundleData = getTherapyBundleDelete(therapyDetail)

            if (bundleData) {
              dispatch(deletingCatalog(bundleData, '', 'Therapy'))
            }
          }

          setDeleteTherapy(false)
        }}
      />

      <ConfirmationDialog
        open={toActive}
        displayText='Therapy will be Activated. Do you want to continue?'
        title='Activate Therapy'
        doneButtonText='Activate'
        cancelButtonText='Back'
        onClose={() => {
          setToActive(false)
        }}
        onDiscard={() => {
          if (therapyDetail) {
            const bundleData = getTherapyBundleActivate(therapyDetail)

            if (bundleData) {
              dispatch(activateCatalog(bundleData, '', 'Therapy'))
            }
          }

          setToActive(false)
        }}
      />
    </Box>
  )
}
