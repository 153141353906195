/* eslint-disable react/display-name */
import MaterialTable, { MTableToolbar } from '@material-table/core'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  Switch,
  TablePagination,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import { SubstanceDataUpdate } from 'models/substanceDataUpdate'
// import { kDialogueBackground } from 'configs/styles/muiThemes'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  activateCatalog,
  deletingCatalog,
} from 'redux/valueSet/substanceList/addSubstanceCatalougeSlice'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import {
  getSubstanceBundleActivate,
  getSubstanceBundleDelete,
} from 'utils/labHelpers/uploadHelper'
import { ConfirmationDialog } from 'views/components/common/confirmationDialog'
import { EditSubstance } from './editSubstance'

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      padding: 4,
    },
  },
})

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: '#9E9DDC',
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)

interface ICardProperties {
  usersData: SubstanceDataUpdate[]
}

export const SubstanceDataTable: React.FC<ICardProperties> = ({
  usersData,
}: ICardProperties) => {
  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  const [deletePopup, setDeletePopup] = React.useState<boolean>(false)

  const [substanceDetail, setSubstanceDetail] =
    React.useState<SubstanceDataUpdate>()

  const [deleteSubstance, setDeleteSubstance] = useState(false)

  const [toActive, setToActive] = useState(false)

  const [status, setStatus] = useState(true)
  const [inactive, setInactive] = useState(false)

  const dispatch = useDispatch()

  const classes = useStyles()
  const convertedData = usersData
  const [subsTanceData, setSubsTanceData] = useState([...convertedData])

  const { height, width } = useWindowDimensions()
  const { t } = useTranslation()

  function formatString(str: string) {
    return str
      .replace(/(\B)[^ ]*/g, (match: string) => match.toLowerCase())
      .replace(/^[^ ]/g, (match: string) => match.toUpperCase())
  }

  const [nameFocus, setNameFocus] = useState(true)
  const [unitFocus, setUnitFocus] = useState(true)
  const [priceFocus, setPriceFocus] = useState(true)
  const [statusFocus, setStatusFocus] = useState(true)

  const [nameFilter, setNameFilter] = useState('')
  const [unitFilter, setUnitFilter] = useState('')
  const [priceFilter, setPriceFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('')

  const [data, setData] = useState([...usersData])

  const filterName = (value: any, filed: string) => {
    setNameFocus(true)
    if (value) {
      const filteredData = convertedData.filter((d) =>
        (d?.name ?? '')
          .replace(/\s+/g, '')
          .toLowerCase()
          .includes(value.replace(/\s+/g, '').toLowerCase())
      )
      setData(filteredData)
    } else {
      setData([...usersData])
    }
    setNameFilter(value)
    setUnitFocus(false)
    setPriceFocus(false)
    setStatusFocus(false)
  }

  const filterUnit = (value: any, filed: string) => {
    setUnitFocus(true)
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.unit!.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setUnitFilter(value)
    setNameFocus(false)
    setPriceFocus(false)
    setStatusFocus(false)
  }

  const filterPrice = (value: any, filed: string) => {
    setPriceFocus(true)
    if (value) {
      const filteredData = usersData.filter((d) =>
        (d?.price ?? '')
          .toString()
          .toLocaleUpperCase()
          .includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...usersData])
    }
    setPriceFilter(value)
    setNameFocus(false)
    setUnitFocus(false)
    setStatusFocus(false)
  }

  const filterStatus = (value: any, filed: string) => {
    setStatusFocus(true)
    if (value) {
      const filteredData = usersData.filter((d) =>
        (d?.status ?? '')
          .toLocaleUpperCase()
          .includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...usersData])
    }
    setStatusFilter(value)
    setNameFocus(false)
    setUnitFocus(false)
    setPriceFocus(false)
  }

  const tableRef = React.useRef()

  const columns = [
    {
      title: (
        <Box minWidth='150px' minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {t('labelCommon:Consumable')}
          </Typography>
        </Box>
      ),
      field: 'name',
      customSort: (a: any, b: any) =>
        a.name.localeCompare(b.name, 'en', {
          sensitivity: 'base',
        }),
      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (rowData: any) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <Typography variant='subtitle2' noWrap>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {rowData.name.charAt(0) + rowData.name.substring(1).toLowerCase()}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box maxWidth={200}>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={nameFocus}
            placeholder={t('labelCommon:Substance')}
            value={nameFilter}
            onChange={(e) => filterName(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography variant='subtitle1' color='primary' noWrap>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('labelCommon:Price')}
            &nbsp;(&#x20b9;)
          </Typography>
        </Box>
      ),
      field: 'price',
      cellStyle: {
        width: 150,
        maxWidth: 175,
      },
      render: (rowData: any) => (
        <Box>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {rowData.price}
          </Typography>
          {/* )} */}
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box maxWidth={200}>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={priceFocus}
            placeholder={t('labelCommon: Price')}
            value={priceFilter}
            onChange={(e) => filterPrice(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography variant='subtitle1' color='primary' noWrap>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('labelCommon:Unit')}
          </Typography>
        </Box>
      ),
      field: 'unit',
      cellStyle: {
        width: 150,
        maxWidth: 175,
      },
      customSort: (a: any, b: any) =>
        a.unit.trim().localeCompare(b.unit.trim()),
      render: (rowData: any) => (
        <Box>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{rowData.unit}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box maxWidth={200}>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={unitFocus}
            placeholder={t('labelCommon:Unit')}
            value={unitFilter}
            onChange={(e) => filterUnit(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box minWidth='150px' minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {t('labelCommon:Status')}
          </Typography>
        </Box>
      ),
      field: 'status',
      customSort: (a: any, b: any) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
      cellStyle: {
        width: 250,
        maxWidth: 250,
      },
      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (rowData: any) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <Typography variant='subtitle2' noWrap>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {formatString(rowData.status)}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box maxWidth={200}>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={statusFocus}
            placeholder={t('labelCommon: Status')}
            value={statusFilter}
            onChange={(e) =>
              filterStatus(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: <Box minWidth='180px' minHeight='15px' maxHeight='15px' />,
      field: 'status',
      render: (rowData: any) => (
        <Box
          display='flex'
          flexDirection='column'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Grid container>
            <Grid item md={6}>
              <Box display='flex' justifyContent='flex-end' p={2}>
                <Box mr={2} mt={1}>
                  {rowData.status === 'active' && (
                    <CreateIcon
                      fontSize='small'
                      color='primary'
                      onClick={() => {
                        setSubstanceDetail(rowData)
                        setShowPopup(true)
                      }}
                    />
                  )}
                </Box>
                <Box mr={2} mt={1}>
                  {/* <DeleteIcon fontSize="small" color="primary"
                    onClick={() => {
                    if(rowData.status == 'active')
                      setDeleteSubstance(true)
                    setSubstanceDetail(rowData)
                    }}
                /> */}
                  {rowData.status === 'active' && (
                    <AntSwitch
                      checked={status}
                      onChange={(event) => {
                        setDeleteSubstance(true)
                        setSubstanceDetail(rowData)
                      }}
                      name='Active'
                    />
                  )}
                  {rowData.status === 'inactive' && (
                    <AntSwitch
                      checked={inactive}
                      onChange={(event) => {
                        setToActive(true)
                        setSubstanceDetail(rowData)
                      }}
                      name='InActive'
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ),
      filtering: false,
    },
  ]

  return (
    <Box
      style={{ overflow: 'none', width: `${width - 224}px` }}
      paddingLeft={1.5}
    >
      {/* <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                minHeight: '0px',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
          Container: (props) => <Paper {...props} elevation={0} />,
          Pagination: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                display: 'flex',
                justifyContent: 'left',
                padding: '0px',
                height: '60px',
                color: '#333333',
              }}
            >
              <TablePagination
                {...props}
                // labelRowsSelect={<div style={{ fontSize: 14 }}> rows</div>}
                SelectProps={{
                  style: {
                    fontSize: 14,
                    backgroundColor: '#ececec',
                  },
                }}
              />
            </div>
          ),
        }}
        columns={columns}
        data={data}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        onRowClick={(event, rowData:any) => {
            // <EditTherapy data={rowData}/>
          }}
          tableRef={tableRef}
          options={{
            search: true,
            showTitle: false,
            toolbar: false,
            padding: 'dense',
            searchFieldVariant: 'outlined',
            filtering: true,
            thirdSortClick: false,
            pageSize: 10,
            pageSizeOptions: [10, 20, 40, 50],
            columnsButton: true,
            maxBodyHeight: `${height - 190}px`,
            minBodyHeight: `${height - 260}px`,
            toolbarButtonAlignment: 'right',
            keepSortDirectionOnColumnSwitch: true,
            sorting: true,
            headerStyle: {
              backgroundColor: '#ececec',
              color: '#333333',
              width: 110,
              position: 'sticky',
              top: 0,
              height: 40,
            },
  
            filterRowStyle: {
              position: 'sticky',
              zIndex: 5,
              top: 40,
              backgroundColor: '#ececec',
              color: '#333333',
            },
            selectionProps: (_rowData: any) => ({
              color: 'primary',
            }),
              actionsColumnIndex: -1,
            // search: true,
            searchFieldAlignment: 'left',
          }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      /> */}

      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                color: '#333333',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
          //   Action: (props) => (
          //     <Button
          //       onClick={(event) => }
          //       color='primary'
          //       variant='contained'
          //       style={{ textTransform: 'none' }}
          //       size='small'
          //     >
          //       Resend Invite
          //     </Button>
          //   ),

          Container: (props) => <Paper {...props} elevation={0} />,

          Pagination: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                display: 'flex',
                justifyContent: 'left',
                padding: '0px',
                height: '60px',
                color: '#333333',
              }}
            >
              <TablePagination
                {...props}
                //   labelRowsPerPage={<div style={{ fontSize: 14 }}>{props.labelRowsPerPage} rows</div>}
                //   labelDisplayedRows={row => <div style={{ fontSize: 14 }}>{props.labelDisplayedRows(row)}</div>}
                SelectProps={{
                  style: {
                    fontSize: 14,
                    backgroundColor: '#ececec',
                  },
                }}
              />
            </div>
          ),
        }}
        columns={columns}
        data={data}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        onRowClick={(event, rowData) => {
          //   i18n.changeLanguage(language ?? 'en')
          //   if (rowData) onPatientSelected(rowData)
          //   setSelectedRow(true)
          //   setSelectedRowId(rowData?.id ?? '')
        }}
        tableRef={tableRef}
        options={{
          showTitle: false,
          padding: 'dense',
          search: false,
          filtering: true,
          thirdSortClick: false,
          pageSize: 10,
          pageSizeOptions: [10, 20, 40, 60, 80, 110],
          //   columnsButton: true,
          maxBodyHeight: `${height - 190}px`,
          minBodyHeight: `${height - 260}px`,
          toolbarButtonAlignment: 'right',
          keepSortDirectionOnColumnSwitch: true,
          sorting: true,
          headerStyle: {
            backgroundColor: '#ececec',
            color: '#333333',
            width: 110,
            position: 'sticky',
            top: 0,
            height: 40,
          },

          filterRowStyle: {
            position: 'sticky',
            zIndex: 5,
            top: 40,
            backgroundColor: '#ececec',
            color: '#333333',
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          //   actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />

      {showPopup && (
        <EditSubstance
          selectedValue={substanceDetail as SubstanceDataUpdate}
          open={showPopup}
          onCancelClick={() => {
            setShowPopup(false)
          }}
        />
      )}

      {/* {deletePopup && <DeleteSubstance
        open={deletePopup}
        onCancelClick={() => {
            setDeletePopup(false)
            }}
    />} */}

      <ConfirmationDialog
        open={deleteSubstance}
        displayText='Consumable will be Inactivated. Do you want to continue?'
        title='Inactivate Consumable'
        doneButtonText='Inactivate'
        cancelButtonText='Back'
        onClose={() => {
          setDeleteSubstance(false)
        }}
        onDiscard={() => {
          if (substanceDetail) {
            const bundleData = getSubstanceBundleDelete(substanceDetail)

            if (bundleData) {
              dispatch(deletingCatalog(bundleData, '', 'Substance'))
            }
          }
          setDeleteSubstance(false)
        }}
      />

      <ConfirmationDialog
        open={toActive}
        displayText='Consumable will be Activated. Do you want to continue?'
        title='Activate Consumable'
        doneButtonText='Activate'
        cancelButtonText='Back'
        onClose={() => {
          setToActive(false)
        }}
        onDiscard={() => {
          if (substanceDetail) {
            const bundleData = getSubstanceBundleActivate(substanceDetail)

            if (bundleData) {
              dispatch(activateCatalog(bundleData, '', 'Substance'))
            }
          }

          setToActive(false)
        }}
      />
    </Box>
  )
}
