import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { DateWiseIPDAppointments } from 'models/dateSeparatedIPDAppointments'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import {
  getCurrentUserUnitReference,
  isTherapist,
} from 'services/userDetailsService'
import {
  getDateWiseIPD,
  getExpandedServiceRequestFromBundleForIpd,
} from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { logger } from 'utils/logger'
import { OPDConsultationAppointmentSearchStatus } from './opdConsultAppointmentSearchStatus'

const initialState: OPDConsultationAppointmentSearchStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingOrders: false,
  selectedStatuses: ['active'],
  selectedDate: isTherapist() ? new Date() : undefined,
}

let currentSelectedDate: Date | undefined

const opdConsultppointmentSearchSlice = createSlice({
  name: 'opdConsultAppointmentSearchSlice',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<OPDConsultationAppointmentSearchStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableAppointments = action.payload.availableAppointments
      state.errorWhileSearchingOrders = action.payload.errorWhileSearchingOrders
      state.selectedStatuses = action.payload.selectedStatuses
      state.selectedDate = action.payload.selectedDate
      state.dateWiseAppointments = action.payload.dateWiseAppointments
      state.pageState = action.payload.pageState
      state.recordsCount = action.payload.recordsCount
    },
  },
})

export const requestForOPDAppointmentLists =
  (
    selectedDate?: Date,
    selectedStatus?: string[],
    pageState?: string,
    offSet?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    currentSelectedDate = selectedDate

    const state: OPDConsultationAppointmentSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      selectedStatuses: selectedStatus ?? [],
      selectedDate,
    }
    dispatch(opdConsultppointmentSearchSlice.actions.updatedStatus(state))
    try {
      currentSelectedDate?.setSeconds(new Date().getSeconds())
      const enRolClient: EnrolCient = new EnrolCient()
      const searchParams: any = {
        perPageCount: 30,
        type: 'opd',
      }
      if (selectedDate) {
        const date = `${moment
          .utc(
            selectedDate || moment.utc(new Date()).subtract(30, 'days').toDate()
          )
          .local()
          .format('YYYY-MM-DD')}`
        searchParams.startDate = date
      }

      if (pageState && offSet) {
        searchParams.stateId = pageState
        searchParams.offSetNumber = offSet
      }

      if (selectedStatus && selectedStatus.length > 0) {
        searchParams.status = selectedStatus.join(',')
      }

      const response: any = await enRolClient.doGetResource(`ipd`, searchParams)

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingOrders = true
        state.searchingAppointments = false

        dispatch(opdConsultppointmentSearchSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp.right
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          const fhirAppointments: FhirClinicIpdDetails[] =
            getExpandedServiceRequestFromBundleForIpd(appointmentResponse)

          fhirAppointments.sort((a, b) => moment(b.start).diff(a.start))

          state.pageState = appointmentResponse.id
          state.recordsCount = appointmentResponse.total
          state.resultsAvailable = true
          state.searchingAppointments = false
          state.availableAppointments = fhirAppointments
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingOrders = false
          dispatch(opdConsultppointmentSearchSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: OPDConsultationAppointmentSearchStatus = {
            searchingAppointments: false,
            errorWhileSearchingOrders: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            selectedStatuses: selectedStatus ?? [],
          }
          dispatch(
            opdConsultppointmentSearchSlice.actions.updatedStatus(
              errorSearchDoctor
            )
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: OPDConsultationAppointmentSearchStatus = {
        searchingAppointments: false,
        errorWhileSearchingOrders: true,
        resultsAvailable: false,
        errorReason: 'Error while fetching appointments',
        selectedStatuses: selectedStatus ?? [],
      }
      dispatch(
        opdConsultppointmentSearchSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export const requestForDateWiseOPDAppointmentListsForConsulting =
  (
    name: string,
    selectedDate?: Date,
    selectedStatus?: string[],
    pageState?: string,
    offSet?: number,
    existingDateWiseData?: DateWiseIPDAppointments[],
    existingDataRawData?: FhirClinicIpdDetails[],
    recordsCount?: number
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    currentSelectedDate = selectedDate

    const state: OPDConsultationAppointmentSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      selectedStatuses: selectedStatus ?? [],
      selectedDate,
      availableAppointments: existingDataRawData,
      dateWiseAppointments: existingDateWiseData,
      pageState,
      recordsCount,
    }
    dispatch(opdConsultppointmentSearchSlice.actions.updatedStatus(state))
    try {
      const draftData: string[] = ['draft']
      const unitId = getCurrentUserUnitReference()?.reference?.split('/')[1]
      currentSelectedDate?.setSeconds(new Date().getSeconds())
      const enRolClient: EnrolCient = new EnrolCient()
      const searchParams: any = {
        perPageCount: 10,
        unitId,
        type: 'opd',
        patientName: name,
      }
      if (selectedDate) {
        const date = `${moment
          .utc(
            selectedDate || moment.utc(new Date()).subtract(30, 'days').toDate()
          )
          .local()
          .format('YYYY-MM-DD')}`
        searchParams.startDate = date
      }

      if (pageState && offSet) {
        searchParams.stateId = pageState
        searchParams.offSetNumber = offSet
      }

      //   if (selectedStatus && selectedStatus.length > 0) {
      //     if (
      //       selectedStatus.includes('draft') === false &&
      //       selectedStatus.includes('completed') === false
      //     ) {
      //       selectedStatus = selectedStatus.concat(draftData)
      //     }
      //     searchParams.status = selectedStatus.join(',')
      //   }

      if (selectedStatus && selectedStatus.length > 0) {
        searchParams.status = selectedStatus.join(',')
      }
      const response: any = await enRolClient.doGetResource(`ipd`, searchParams)

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (response === undefined) {
        console.error('---------error 1111------------')
        // console.error(resp.left)
        state.errorWhileSearchingOrders = true
        state.searchingAppointments = false

        dispatch(opdConsultppointmentSearchSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = response as R4.IBundle
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          const fhirAppointments: FhirClinicIpdDetails[] =
            getExpandedServiceRequestFromBundleForIpd(appointmentResponse)
          fhirAppointments.sort((n1, n2) => n1.ipdNumber - n2.ipdNumber)

          fhirAppointments.sort((a, b) => moment(b.start).diff(a.start))

          state.pageState = appointmentResponse.id
          state.recordsCount = appointmentResponse.total
          state.resultsAvailable = true
          state.searchingAppointments = false
          state.availableAppointments = [
            ...(existingDataRawData ?? []),
            ...fhirAppointments,
          ]
          state.dateWiseAppointments = getDateWiseIPD(
            fhirAppointments,
            existingDateWiseData ?? []
          )
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingOrders = false
          dispatch(opdConsultppointmentSearchSlice.actions.updatedStatus(state))
        } else {
          console.error('---------error ---- ------------')
          const errorSearchDoctor: OPDConsultationAppointmentSearchStatus = {
            searchingAppointments: false,
            errorWhileSearchingOrders: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            selectedStatuses: selectedStatus ?? [],
            selectedDate,
            availableAppointments: existingDataRawData,
            dateWiseAppointments: existingDateWiseData,
            pageState,
            recordsCount,
          }
          dispatch(
            opdConsultppointmentSearchSlice.actions.updatedStatus(
              errorSearchDoctor
            )
          )
        }
      } /* */
    } catch (error) {
      console.error('---------error------------')
      console.error(error)
      const errorSearchDoctor: OPDConsultationAppointmentSearchStatus = {
        searchingAppointments: false,
        errorWhileSearchingOrders: true,
        resultsAvailable: false,
        errorReason: 'Error while fetching appointments',
        selectedStatuses: selectedStatus ?? [],
        selectedDate,
        availableAppointments: existingDataRawData,
        dateWiseAppointments: existingDateWiseData,
        pageState,
        recordsCount,
      }
      dispatch(
        opdConsultppointmentSearchSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export default opdConsultppointmentSearchSlice.reducer
