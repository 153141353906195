import {
  Box,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { resetIPDCountWp } from 'redux/welloAdmin/ipcCountWp/ipdCountWpSlice'
import { resetWellnessAppointmentForUnitCountDetail } from 'redux/welloAdmin/wellnessAppointmentBasedOnStatus/wellnessAppointmentBasedonStatusSlice'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { DetailsComponent } from './detailsComponent'
import { IPDDetailsCount } from './ipdDetailsCount'

interface PatientProps {
  ipdCount: number
  type: string
  unitId: string
  newCount?: number
  opdCount?: number
  dayCareCount?: number
  mainOrg?: string
}

export const AppointmentCountTile: React.FC<PatientProps> = ({
  ipdCount,
  type,
  unitId,
  newCount,
  opdCount,
  dayCareCount,
  mainOrg,
}: PatientProps) => {
  const dispatch = useDispatch()
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  const [role, setRole] = useState<string>()
  const [open, setOpen] = useState<boolean>(false)

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Grid container direction='row' spacing={1}>
        {/* ------------------------------------------------------------------------------- */}

        <Box display='flex' flexDirection='column' width='100%' flexGrow={1}>
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <Grid item xs={3}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        {type === 'clinic' ||
                        type === 'org' ||
                        type === 'wellness_center'
                          ? 'Appointment Details'
                          : 'Order Details'}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      Count
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: 'white',
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {type === 'clinic'
                        ? 'Total IPD Appointments'
                        : type === 'lab'
                        ? 'Total Lab Orders'
                        : 'Total OPD  Appointments'}{' '}
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    height={30}
                    alignItems='center'
                    px={1}
                    width='50%'
                  >
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {ipdCount}
                    </Typography>
                  </Box>
                  {ipdCount > 0 && mainOrg === undefined && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      height={30}
                      width='50%'
                    >
                      <Tooltip title='View Details' id='ipd_8'>
                        <IconButton
                          aria-label='btn_ord_reschedule'
                          color='primary'
                          id='ipd_9'
                          // disabled={ipdDetails.serviceRequest.status === 'draft'}
                          onClick={() => {
                            setOpen(true)
                            setRole(type)
                          }}
                        >
                          <img
                            id='ipd_10'
                            src={`${process.env.PUBLIC_URL}/view.ico`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                <Box
                  display='flex'
                  width='2%'
                  justifyContent='flex-end'
                  flexGrow
                >
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {type === 'org' && (
            <Box>
              <Grid
                container
                direction='row'
                id='vitals'
                style={{
                  backgroundColor: 'white',
                }}
              >
                <Grid item xs={3}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Typography
                          variant='subtitle2'
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          Total IPD Count
                        </Typography>
                      </Box>
                    </Box>
                    <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        height={30}
                        alignItems='center'
                        px={1}
                      >
                        <Typography
                          variant='subtitle2'
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          {newCount}
                        </Typography>
                      </Box>
                      {/* {newCount && newCount > 0 && (
                        <Box
                          display='flex'
                          flexDirection='row'
                          justifyContent='flex-end'
                          height={30}
                        >
                          <Tooltip title='View Details' id='ipd_8'>
                            <IconButton
                              aria-label='btn_ord_reschedule'
                              color='primary'
                              id='ipd_9'
                              // disabled={ipdDetails.serviceRequest.status === 'draft'}
                              onClick={() => {
                                setOpen(true)
                                setRole(type)
                              }}
                            >
                              <img
                                id='ipd_10'
                                src={`${process.env.PUBLIC_URL}/view.ico`}
                                alt='view'
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )} */}
                    </Box>
                    <Box
                      display='flex'
                      width='2%'
                      justifyContent='flex-end'
                      flexGrow
                    >
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Grid container direction='row' id='vitals'>
                <Grid item xs={12}>
                  <Box width='100%' paddingLeft={0.2}>
                    <Divider
                      orientation='horizontal'
                      flexItem
                      style={{
                        height: '2px',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Grid>
      <DetailsComponent
        id={unitId}
        open={open}
        type={role ?? ''}
        onClose={() => {
          if (type === 'wellness_center') {
            dispatch(resetWellnessAppointmentForUnitCountDetail())
          } else {
            dispatch(resetIPDCountWp())
          }

          setRole(undefined)
          setOpen(false)
        }}
      />
      {type === 'wellness_center' && (
        <DetailsComponent
          id={unitId}
          open={open}
          type={role ?? ''}
          onClose={() => {
            if (type === 'wellness_center') {
              dispatch(resetWellnessAppointmentForUnitCountDetail())
            }

            setRole(undefined)
            setOpen(false)
          }}
        />
      )}

      {type !== 'wellness_center' && (
        <IPDDetailsCount
          id={unitId}
          open={open}
          code=''
          type={role ?? ''}
          onClose={() => {
            dispatch(resetIPDCountWp())

            setRole(undefined)
            setOpen(false)
          }}
        />
      )}
    </Box>
  )
}
