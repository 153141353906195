/* eslint-disable react/display-name */
import { R4 } from '@ahryman40k/ts-fhir-types'
import MaterialTable, { MTableToolbar } from '@material-table/core'
import {
  Box,
  InputAdornment,
  makeStyles,
  Paper,
  TablePagination,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { WelloUnits } from 'models/welloUnit'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { AddPartnerLabHandler } from 'views/components/administration/actors/add_partner_lab'

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      // padding: 4,
    },
  },
})

interface ICardProperties {
  orgData: WelloUnits[]
  onOrgSelected?: (
    org: R4.IOrganization,
    orgArray: string[],
    unitList: R4.IOrganization[]
  ) => void
}

export const MainOrgDataTable: React.FC<ICardProperties> = ({
  orgData,
  onOrgSelected,
}: ICardProperties) => {
  const classes = useStyles()
  const convertedData = orgData
  const [data, setData] = useState([...convertedData])
  const { height, width } = useWindowDimensions()
  const [selectedRowId, setSelectedRowId] = React.useState('')
  const [selectedRow, setSelectedRow] = React.useState(false)
  const [openPartnerLabPopup, setOpenPartnerLabPopup] = useState(false)
  const { t } = useTranslation()
  const [nameFilter, setNameFilter] = useState('')
  const [nameFocus, setNameFocus] = useState(true)
  const [addressFilter, setAddressFilter] = useState('')
  const [addressFocus, setAddressFocus] = useState(false)
  const [phoneFilter, setPhoneFilter] = useState('')
  const [phoneFocus, setPhoneFocus] = useState(false)
  const [emailFilter, setEmailFilter] = useState('')
  const [emailFocus, setEmailFocus] = useState(false)
  const [ageFilter, setAgeFilter] = useState('')
  const [ageFocus, setAgeFocus] = useState(false)
  const [statusFilter, setStatusFilter] = useState('')
  const [statusFocus, setStatusFocus] = useState(false)

  const handleDarkModeChange = () => {
    // setPreferDarkMode(!preferDarkMode)
    // localStorage.setItem('_tableDarkMode', !preferDarkMode)
  }

  const filterName = (value: any, filed: string) => {
    setNameFocus(true)
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.orgName
          .replace(/\s+/g, '')
          .toLowerCase()
          .includes(value.replace(/\s+/g, '').toLowerCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setNameFilter(value)
    setAddressFilter('')
    setAddressFocus(false)
    setPhoneFocus(false)
    setEmailFocus(false)
    setAgeFocus(false)
    setStatusFocus(false)
  }

  const filterAddress = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.address.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setAddressFilter(value)
    setNameFocus(false)
    setAddressFocus(true)
    setPhoneFocus(false)
    setPhoneFocus(false)
    setEmailFocus(false)
    setAgeFocus(false)
    setStatusFocus(false)
  }

  const filterPhone = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.phone.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setNameFocus(false)
    setPhoneFocus(true)
    setAddressFocus(false)
    setPhoneFilter(value)
    setEmailFocus(false)
    setAgeFocus(false)
    setStatusFocus(false)
  }

  const filterEmail = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.email.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )

      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setNameFocus(false)
    setPhoneFocus(false)
    setEmailFocus(true)
    setAddressFocus(false)
    setEmailFilter(value)
    setAgeFocus(false)
    setStatusFocus(false)
  }

  const filterAge = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.onboardDate.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )

      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setAgeFilter(value)
    setAgeFocus(true)
    setNameFocus(false)

    setPhoneFocus(false)
    setEmailFocus(false)
    setStatusFocus(false)
    setAddressFocus(false)
  }

  const filterStatus = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.status.toLocaleUpperCase().includes(value.toLocaleUpperCase())
      )

      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setNameFocus(false)
    setPhoneFocus(false)
    setEmailFocus(true)
    setAddressFocus(false)
    setStatusFilter(value)
    setAgeFocus(false)
    setStatusFocus(true)
  }

  const columns = [
    {
      title: (
        <Box minWidth='150px' minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Name
          </Typography>
        </Box>
      ),
      field: 'orgName',
      customSort: (a: any, b: any) =>
        a.orgName > b.orgName ? 1 : b.orgName > a.orgName ? -1 : 0,

      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (rowData: any) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <Typography variant='subtitle2' noWrap>
            {rowData.orgName}
          </Typography>
        </Box>
      ),
      width: 250,
      filterComponent: (props: any) => (
        <Box maxWidth={200}>
          <TextField
            variant='outlined'
            size='small'
            id='list3'
            autoFocus={nameFocus}
            placeholder='Name'
            value={nameFilter}
            onChange={(e) => filterName(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            color='primary'
            style={{
              fontSize: 14,
              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            noWrap
          >
            Address
          </Typography>
        </Box>
      ),
      field: 'address',
      //   cellStyle: {
      //     width: 250,
      //     maxWidth: 250,
      //   },
      render: (rowData: any) => (
        <Box>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            {rowData.address}
          </Typography>
        </Box>
      ),
      width: 250,
      filterComponent: (props: any) => (
        <Box width='105%'>
          <TextField
            variant='outlined'
            size='small'
            id='list31'
            autoFocus={addressFocus}
            placeholder={t('labelPatient:attr_labels.Patient.address')}
            value={addressFilter}
            onChange={(e) =>
              filterAddress(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
            noWrap
          >
            Phone No.
          </Typography>
        </Box>
      ),
      field: 'phone',

      render: (rowData: any) => (
        <Box paddingY={1}>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            {rowData.phone}
          </Typography>
        </Box>
      ),
      width: 250,
      filterComponent: (props: any) => (
        <Box maxWidth={250}>
          <TextField
            variant='outlined'
            size='small'
            id='list15'
            autoFocus={phoneFocus}
            placeholder={t('labelCommon:phone_number_label')}
            value={phoneFilter}
            onChange={(e) => filterPhone(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
            noWrap
          >
            Email
          </Typography>
        </Box>
      ),
      field: 'email',

      render: (rowData: any) => (
        <Box paddingY={1}>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            {rowData.email}
          </Typography>
        </Box>
      ),
      width: 250,
      filterComponent: (props: any) => (
        <Box width='105%'>
          <TextField
            variant='outlined'
            size='small'
            id='list18'
            autoFocus={emailFocus}
            placeholder={t('labelCommon:email_address')}
            value={emailFilter}
            onChange={(e) => filterEmail(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Onboarding Date
          </Typography>
        </Box>
      ),
      field: 'onboardDate',
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Box paddingLeft={0.5} display='flex' alignItems='center'>
            <Typography variant='subtitle2' color='textPrimary' noWrap>
              {rowData.onboardDate}
            </Typography>
          </Box>
        </Box>
      ),
      width: 250,
      filterComponent: (props: any) => (
        <Box>
          <TextField
            id='list5'
            variant='outlined'
            size='small'
            autoFocus={ageFocus}
            placeholder='Onboarding Date'
            value={ageFilter}
            onChange={(e) => filterAge(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            &nbsp;{t('labelCommon:Status')}
          </Typography>
        </Box>
      ),
      field: 'status',
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Box paddingLeft={0.5} display='flex' alignItems='center'>
            <Typography variant='subtitle2' color='textPrimary' noWrap>
              {rowData.status}
            </Typography>
          </Box>
        </Box>
      ),
      width: 150,
      filterComponent: (props: any) => (
        <Box>
          <TextField
            variant='outlined'
            size='small'
            id='list18'
            autoFocus={statusFocus}
            placeholder='Status'
            value={statusFilter}
            onChange={(e) =>
              filterStatus(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
  ]

  return (
    <Box
      style={{ overflow: 'none', width: `${width}px`, height: `${height}px` }}
      id='list38'
    >
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                minHeight: '0px',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
          Container: (props) => <Paper {...props} elevation={0} />,
          Pagination: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                display: 'flex',
                justifyContent: 'left',
                // padding: '0px',
              }}
            >
              <TablePagination
                {...props}
                labelRowsSelect={<div style={{ fontSize: 14 }}> rows</div>}
                SelectProps={{
                  style: {
                    fontSize: 14,
                    minHeight: '5px',
                    backgroundColor: useTheme().palette.background.default,
                  },
                }}
              />
            </div>
          ),
        }}
        // actions={[
        //     {
        //       icon: () => <Button
        //       variant='contained'
        //       color='primary'
        //       onClick={() => {
        //         setOpenPartnerLabPopup(true)
        //       }}
        //     >
        //       {t('add_partner_lab')}
        //     </Button>,
        //      position: "toolbar",
        //       tooltip: "Toggle light/dark mode",
        //       onClick: handleDarkModeChange,
        //       isFreeAction: true
        //     }
        //   ]}
        columns={columns}
        data={data}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        onRowClick={(event, rowData) => {
          if (rowData && onOrgSelected) {
            onOrgSelected(
              rowData.orgDetails,
              rowData.unitReference ?? [],
              rowData.unitList ?? []
            )
            setNameFocus(false)
            setSelectedRowId(rowData ? rowData.orgName ?? '' : '')
          }
        }}
        options={{
          searchFieldStyle: { padding: 4 },
          toolbar: false,
          search: false,
          showTitle: false,
          padding: 'dense',
          searchFieldVariant: 'outlined',
          filtering: true,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30],
          //   columnsButton: true,
          //   maxBodyHeight: `${height - 190}px`,
          //   minBodyHeight: `${height - 260}px`,
          tableLayout: 'auto',
          sorting: true,
          thirdSortClick: false,
          headerStyle: {
            backgroundColor: useTheme().palette.background.default,
            color: '#FFF',
            width: 100,
            minHeight: '15px',
            maxHeight: '15px',
            position: 'sticky',
            top: 0,
            // height: 20,
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          rowStyle: (_data: any, index: number, _level: number) =>
            index % 2
              ? selectedRow && _data.orgName === selectedRowId
                ? { backgroundColor: '#9E9DDC', height: 50 }
                : { backgroundColor: kDialogueBackground }
              : selectedRow && _data.orgName === selectedRowId
              ? { backgroundColor: '#9E9DDC', height: 50 }
              : {},
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />
      {openPartnerLabPopup === true && (
        <AddPartnerLabHandler
          open={openPartnerLabPopup}
          onContinueClick={() => {
            setOpenPartnerLabPopup(false)
          }}
          onBackClick={() => {
            setOpenPartnerLabPopup(false)
          }}
        />
      )}
    </Box>
  )
}
