import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'

import { getScheduleFromSlotsRelations } from 'utils/fhirResoureHelpers/fhirScheduleHelper'
import { logger } from 'utils/logger'
import { ScheduleDetail } from 'models/fhirScheduleDetail'
import moment from 'moment'
import { SearchProviderScheduleStatus } from './SearchProviderScheduleStatus'

const initialState: SearchProviderScheduleStatus = {
  searchingSchedules: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingSchedules: false,
}

const SearchProviderScheduleSlice = createSlice({
  name: 'practitionerScheduleSearch',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<SearchProviderScheduleStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingSchedules = action.payload.searchingSchedules
      state.resultsAvailable = action.payload.resultsAvailable
      state.scheduleList = action.payload.scheduleList
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingSchedules =
        action.payload.errorWhileSearchingSchedules
    },
  },
})

export const requestForSchedules =
  (selectedDoctor: any,selectedDate:Date): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: SearchProviderScheduleStatus = {
      searchingSchedules: true,
      errorWhileSearchingSchedules: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(SearchProviderScheduleSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const dateString=moment(selectedDate).toISOString().trim()
      const oneWeek = moment(moment(selectedDate).add(30, 'days')).toISOString().trim()
      const searchParameters: any = {
        actor: selectedDoctor.roleObject.practitioner?.reference,
        _count: 1000,
        active: true,
        date: `ge${dateString}`
      
      }
      const response: any = await fhirClient.doGetResource(
        `/Schedule?date=le${oneWeek}`,
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingSchedules = true
        state.searchingSchedules = false
        dispatch(SearchProviderScheduleSlice.actions.updatedStatus(state))
      } else {
        const scheduleResponse: R4.IBundle = resp.right
        if (scheduleResponse?.total && scheduleResponse?.total > 0) {
          const categorizedSlots: ScheduleDetail[] =
            getScheduleFromSlotsRelations(scheduleResponse)

          state.resultsAvailable = true
          state.searchingSchedules = false
          state.scheduleList = categorizedSlots
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingSchedules = false
          dispatch(SearchProviderScheduleSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: SearchProviderScheduleStatus = {
            searchingSchedules: false,
            errorWhileSearchingSchedules: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            SearchProviderScheduleSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: SearchProviderScheduleStatus = {
        searchingSchedules: false,
        errorWhileSearchingSchedules: true,
        resultsAvailable: false,
        errorReason: 'Error while searching schedule',
      }
      dispatch(
        SearchProviderScheduleSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export const resetSchdeule = (): AppThunk => async (dispatch: AppDispatch) => {
  const state: SearchProviderScheduleStatus = {
    searchingSchedules: false,
    resultsAvailable: true,
    scheduleList: undefined,
    noResultsAvailable: false,
    errorWhileSearchingSchedules: false,
  }
  dispatch(SearchProviderScheduleSlice.actions.updatedStatus(state))
}

export default SearchProviderScheduleSlice.reducer
