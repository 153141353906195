import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, CircularProgress, Typography, Grid } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getInstructionsOfAppointment } from 'redux/consultation/instructions_list_slice/instructionsListSlice'
import { getSpecialistReferralsOfAppointment } from 'redux/consultation/speacialists_referrals_list_slice/specialistReferralsListSlice'
import { RootState } from 'redux/rootReducer'
import { getCommunicationRequestPayloadString } from 'utils/fhirResoureHelpers/communicationRequestHelper'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { InstructionsTableTile } from '../assessment/instructionsTabularTile'
import { SpecialistRequestTile } from '../assessment/SpecilistReferralRequestTile'

interface Props {
  fhirAppointmentDetails: FhirAppointmentDetail
  followUp?: boolean
}

export const SpecialistReferralList: React.FC<Props> = ({
  fhirAppointmentDetails,
  followUp,
}: Props) => {
  const initialFetch = useRef(true)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()

  const specialistReferralsListSlice = useSelector(
    (state: RootState) => state.specialistReferralsListSlice
  )

  useEffect(() => {
    dispatch(getSpecialistReferralsOfAppointment(fhirAppointmentDetails))
  }, [dispatch, fhirAppointmentDetails])

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {(specialistReferralsListSlice.updatingImpressions ||
        specialistReferralsListSlice.fetchingImpressions) && (
        <CircularProgress size={15} />
      )}
      {/* {instructionsListSlice.noResultsAvailable && (
        <Box py={1} display='flex' flexDirection='column' width='100%'>
          <Typography variant='subtitle2'>&nbsp; No data available</Typography>
        </Box>
      )} */}
      {specialistReferralsListSlice.lanDiagnosticRequests &&
        specialistReferralsListSlice.lanDiagnosticRequests.length > 0 && (
          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box display='flex' flexDirection='column' width='100%'>
                <SpecialistRequestTile
                  instructions={
                    specialistReferralsListSlice.lanDiagnosticRequests
                  }
                />
              </Box>
            </Grid>
          </Grid>
        )}

      <NoteDialog
        id='notesTile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        dialogText='Instruction'
        notesData={fullText!}
      />
    </Box>
  )
}
