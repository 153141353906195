import { R4 } from '@ahryman40k/ts-fhir-types'
import { doctorAppointmentView, DoctorBase } from 'lib/openApi'
import { DateWiseOrders } from 'models/dateSeparatedOrders'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { LabOfferingDetail } from 'models/labOfferDetail'
import moment from 'moment'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import {
  getCompleteDateStringWithOutDay,
  geTimeInString,
  getStartAndEndTimeInString,
} from 'utils/dateUtil'
import { getNameOfPatient } from 'wello-web-components'
import {
  getCodeOfSystemFromCodableConcept,
  getNameFromHumanName,
  getValueRefValueExtensionsOfUrl,
  isMarried,
} from '../fhirResourcesHelper'
import { getUniqueTempId } from './idHelpers'
import { getLabOfferingDetailsFromBundle } from './planDefinitionHelper'

export function getDateWiseOrders(
  availableSlots: FhirLabOrderDetail[]
): DateWiseOrders[] {
  const dateWiseSlots: DateWiseOrders[] = []

  availableSlots.forEach((item) => {
    if (item.start) {
      const date = moment(item.start).format('YYYY-MM-DD')
      const index = dateWiseSlots.findIndex(
        (s) => moment(s.date).format('YYYY-MM-DD') === date
      )
      if (index < 0) {
        const dateWiseSlot: DateWiseOrders = {
          date: item.start,
          orders: [item],
        }
        dateWiseSlots.push(dateWiseSlot)
      } else if (dateWiseSlots[index]?.orders) {
        dateWiseSlots[index].orders?.push(item)
      } else {
        dateWiseSlots[index].orders = [item]
      }
    }
  })

  return dateWiseSlots
}

export function isMorningSlot(slotTime: string): boolean {
  const currentHour = moment(slotTime).format('HH') as unknown as number

  if (currentHour >= 0 && currentHour < 12) {
    return true
  }
  return false
}
export function isAfterNoonSlot(slotTime: string): boolean {
  const currentHour = moment(slotTime).format('HH') as unknown as number

  if (currentHour >= 12 && currentHour < 18) {
    return true
  }
  return false
}

export function isEvening(slotTime: string): boolean {
  const currentHour = moment(slotTime).format('HH') as unknown as number

  if (currentHour >= 18 && currentHour < 24) {
    return true
  }
  return false
}

export function getOrderCreationSuccessfulMessage(
  patient: R4.IPatient
): string {
  const message: string = `Order created for ${getNameOfPatient(patient)}`

  return message
}

export function getAppointmentSuccessfulMessage(
  patient: R4.IPatient,
  selectedDoctor: R4.IPractitioner | undefined,
  selectedSlot: R4.ISlot
): string {
  const message: string = `Appointment created for ${getNameOfPatient(
    patient
  )} with ${getNameFromHumanName(
    selectedDoctor?.name ?? []
  )} on ${dateToFromNowDaily(selectedSlot.start?.toString() ?? '')} at ${moment(
    selectedSlot.start ?? ''
  ).format('HH:mm a')}`

  return message
}
export function getAppointmentCancelMessage(
  appointmentDetails: FhirAppointmentDetail
): string {
  const message: string = `Appointment cancelled for ${getNameOfPatient(
    appointmentDetails.patient
  )} with ${getNameFromHumanName(
    appointmentDetails.practitionerDetail.practitioner.name ?? []
  )} scheduled on ${dateToFromNowDaily(
    appointmentDetails.appointment.start?.toString() ?? ''
  )} at ${moment(appointmentDetails.appointment.start ?? '').format('HH:mm a')}`

  return message
}
function dateToFromNowDaily(myDate: string) {
  // get from-now for this date
  const fromNow = moment(myDate).fromNow()

  // ensure the date is displayed with today and yesterday
  return moment(myDate).calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: '[Last] dddd',
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    // when the date is further away, use from-now functionality
    sameElse() {
      return `[${fromNow}]`
    },
  })
}

export function filterAppointmentsAsPerDoctorSelection(
  selectedDoctors: DoctorBase[],
  appointments: doctorAppointmentView[]
): doctorAppointmentView[] {
  if (selectedDoctors) {
    if (selectedDoctors.length > 0) {
      const doctorIds: string[] = selectedDoctors.map((doc) => doc?.id ?? '')

      const filterOutAppointments: doctorAppointmentView[] =
        appointments.filter((item, index, arr) =>
          doctorIds.includes(item.doctorId ?? 'null')
        )

      return filterOutAppointments
    }
  }
  return appointments
}

export function getExpandedServiceRequestFromBundlePartner(
  responseSlots: R4.IBundle,
  locationId: string
): FhirLabOrderDetail[] {
  const convertedAppointments: FhirLabOrderDetail[] = []
  const serviceRequests: any = {}
  const slots: any = {}
  const tasks: any = {}
  const homeCollectionTask: any = {}
  const partnerLabTask: any = {}
  const practitioners: any = {}
  const patients: any = {}
  const practitionerRoles: any = {}
  const planDefinitions: any = {}
  const provenances: R4.IProvenance[] = []

  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ServiceRequest':
                  serviceRequests[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                case 'Slot':
                  slots[value.resource.id] = value.resource as R4.ISlot
                  break
                case 'Practitioner':
                  practitioners[value.resource.id] =
                    value.resource as R4.IPractitioner
                  break
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break
                case 'Task':
                  if (value.resource.status !== R4.TaskStatusKind._cancelled) {
                    if (value.resource?.partOf === undefined) {
                      tasks[
                        value.resource.focus?.reference?.split('/')[1] ?? ''
                      ] = value.resource as R4.ITask
                    } else if (value.resource?.code?.coding) {
                      if (
                        value.resource?.code?.coding[0].code?.includes(
                          'parnerLab-test-order'
                        )
                      ) {
                        partnerLabTask[
                          value.resource.focus?.reference?.split('/')[1] ?? ''
                        ] = value.resource as R4.ITask
                      } else {
                        homeCollectionTask[
                          value.resource.focus?.reference?.split('/')[1] ?? ''
                        ] = value.resource as R4.ITask
                      }
                    }
                  }

                  break
                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break

                case 'Provenance':
                  provenances.push(value.resource as R4.IProvenance)
                  break
                case 'PlanDefinition':
                  planDefinitions[value.resource.id] =
                    value.resource as R4.IPlanDefinition
                  break
                default:
                  break
              }
            }
          }
        })

        const labOfferings: LabOfferingDetail[] =
          getLabOfferingDetailsFromBundle(responseSlots, []) ?? []
        labOfferings.forEach((e) => {
          planDefinitions[e.id] = e
        })

        for (const key in serviceRequests) {
          if (key) {
            const currentAppointment: R4.IServiceRequest = serviceRequests[
              key
            ] as R4.IServiceRequest

            let practitionerId: string | undefined

            let practitionerRoleId: string | undefined

            let practitionerRoleIdAdmin: string | undefined

            const patientId: string | undefined =
              currentAppointment?.subject.reference?.split('/')[1]
            const currentPlanDefinitions: LabOfferingDetail[] | undefined = (
              serviceRequests[key] as R4.IServiceRequest
            ).instantiatesCanonical?.map((e) => {
              const id: string = e.split('/')[1]

              return planDefinitions[id] as LabOfferingDetail
            })

            let startTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            let endTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            const serviceType: R4.ICoding | undefined =
              getCodeOfSystemFromCodableConcept(
                (serviceRequests[key] as R4.IServiceRequest).code ?? {},
                'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type'
              )

            if (serviceType?.code === 'home-sample-collection') {
              startTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.start ?? ''
              endTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.end ?? ''
              const owner1 = homeCollectionTask[key] as R4.ITask
              if (owner1) {
                practitionerRoleId =
                  (homeCollectionTask[key] as R4.ITask).owner?.reference?.split(
                    '/'
                  )[1] ?? undefined
              }
              const owner2 = tasks[key] as R4.ITask

              if (owner2) {
                practitionerRoleIdAdmin = (
                  tasks[key] as R4.ITask
                ).owner?.reference?.split('/')[1]
              }

              if (practitionerRoleId) {
                const practRole: R4.IPractitionerRole = practitionerRoles[
                  practitionerRoleId
                ] as R4.IPractitionerRole
                if (practRole) {
                  practitionerId = (
                    practitionerRoles[
                      practitionerRoleId
                    ] as R4.IPractitionerRole
                  ).practitioner?.reference?.split('/')[1]
                }
              }
            }

            const checkArray: boolean[] = []

            if (currentPlanDefinitions) {
              for (let i = 0; i < currentPlanDefinitions.length; i++) {
                const locId = getValueRefValueExtensionsOfUrl(
                  currentPlanDefinitions[i].planDefinition.extension,
                  'http://wellopathy.com/fhir/india/core/StructureDefinition/PartnerLabRef'
                )
                if (locId) {
                  if (locId.includes(locationId)) checkArray.push(true)
                  else checkArray.push(false)
                }
              }
            }
            let status: R4.IProvenance[] = []
            if (partnerLabTask[key]) {
              status = provenances.filter((e) => {
                const res = e.target.findIndex((ref) => {
                  if (ref.reference)
                    return (
                      ref.reference.split('/')[1] === partnerLabTask[key].id
                    )

                  return false
                })
                return res > -1
              })
              status.sort((a, b) =>
                moment(a.occurredDateTime).diff(b.occurredDateTime)
              )
            }

            if (checkArray.length > 0 && checkArray.includes(true)) {
              convertedAppointments.push({
                start: startTime,
                end: endTime,
                performerDetail: {
                  practitioner: practitioners[practitionerId ?? ''],
                  practitionerRole: practitionerRoles[practitionerRoleId ?? ''],
                },
                homeServiceTask: homeCollectionTask[key],
                task: tasks[key],
                partnerLabTask: partnerLabTask[key],
                serviceRequest: serviceRequests[key],
                patient: patients[patientId ?? ''],
                tests: currentPlanDefinitions,
                statuses: status,
                oldSlotRef: '',
              })
            }
          }
        }
      }
    }
  }

  return convertedAppointments
}

export function getUserDetailAndAppointmentDetail(
  responseSlots: FhirAppointmentDetail
): string {
  let text: string = ''
  if (responseSlots.patient.maritalStatus) {
    text = isMarried(responseSlots.patient.maritalStatus)
      ? 'Married, '
      : 'Single, '
  }
  text = `${text}Appointment ,${moment(responseSlots.start).format(
    'h:mm a'
  )} - ${moment(responseSlots.end).format('h:mm a')}`
  return text
}

export function getOrderTypeCode(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: R4.ICoding | undefined = getCodeOfSystemFromCodableConcept(
    labOrderDetails.serviceRequest.code ?? {},
    'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type'
  )
  return code?.code
}

export function getOrderTypeDisplayCode(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: R4.ICoding | undefined = getCodeOfSystemFromCodableConcept(
    labOrderDetails.serviceRequest.code ?? {},
    'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type'
  )
  return code?.display
}

export function getOrderTime(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: string | undefined = getOrderTypeCode(labOrderDetails)
  if (code === 'onsite-sample-collection') {
    return geTimeInString(
      labOrderDetails.serviceRequest.occurrenceDateTime ?? ''
    )
  }
  if (code === 'home-sample-collection') {
    return getStartAndEndTimeInString(
      labOrderDetails.serviceRequest.occurrencePeriod?.start ?? '',
      labOrderDetails.serviceRequest.occurrencePeriod?.end ?? ''
    )
  }
  return 'onsite-sample-collection'
}

export function getOrderStartTime(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: string | undefined = getOrderTypeCode(labOrderDetails)
  if (code === 'onsite-sample-collection') {
    return geTimeInString(
      labOrderDetails.serviceRequest.occurrenceDateTime ?? ''
    )
  }
  if (code === 'home-sample-collection') {
    return geTimeInString(
      labOrderDetails.serviceRequest.occurrencePeriod?.start ?? ''
    )
  }
  return 'onsite-sample-collection'
}

export function getOrderStartDate(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: string | undefined = getOrderTypeCode(labOrderDetails)
  if (code === 'onsite-sample-collection') {
    return getCompleteDateStringWithOutDay(
      labOrderDetails.serviceRequest.occurrenceDateTime ?? ''
    )
  }
  if (code === 'home-sample-collection') {
    return getCompleteDateStringWithOutDay(
      labOrderDetails.serviceRequest.occurrencePeriod?.start ?? ''
    )
  }
  return 'onsite-sample-collection'
}

export function getOrderDate(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: string | undefined = getOrderTypeCode(labOrderDetails)
  if (code === 'onsite-sample-collection') {
    return getCompleteDateStringWithOutDay(
      labOrderDetails.serviceRequest.occurrenceDateTime ?? ''
    )
  }
  if (code === 'home-sample-collection') {
    return getStartAndEndTimeInString(
      labOrderDetails.serviceRequest.occurrencePeriod?.start ?? '',
      labOrderDetails.serviceRequest.occurrencePeriod?.end ?? ''
    )
  }
  return 'onsite-sample-collection'
}

export function getTestsOfOrder(
  labOrderDetail: FhirLabOrderDetail
): string | undefined {
  return labOrderDetail.tests
    ?.map((e) => e?.planDefinition.title ?? e?.planDefinition.name)
    .join(',')
}

export function isLabOrderEditable(
  labOrderDetail: FhirLabOrderDetail
): boolean {
  if (labOrderDetail.serviceRequest.status === 'active') {
    const code: string | undefined = getOrderTypeCode(labOrderDetail)

    if (code === 'onsite-sample-collection') {
      return true
    }

    if (
      moment(labOrderDetail.serviceRequest.occurrencePeriod?.start).isAfter(
        new Date()
      )
    ) {
      return true
    }
  }

  return false
}

export function isHomeOrder(labOrderDetail: FhirLabOrderDetail): boolean {
  if (labOrderDetail.serviceRequest.status === 'active') {
    const code: string | undefined = getOrderTypeCode(labOrderDetail)

    if (code === 'home-sample-collection') {
      return true
    }
  }

  return false
}

export function getInputIdentifierValueBySystemOfInvitation(
  task: R4.ITask,
  system: string
): string | undefined {
  if (task && task.input && task.input.length > 0) {
    const emailInput = task.input.find(
      (e) => e.valueIdentifier?.system === system
    )
    if (emailInput) {
      return emailInput.valueIdentifier?.value
    }
  }

  return undefined
}

export function getInputIdentifierValueByCodemOfInvitation(
  task: R4.ITask,
  code: string
): R4.ITask_Input | undefined {
  if (task && task.input && task.input.length > 0) {
    const emailInput = task.input.find((e) => e.type?.coding?.[0].code === code)
    if (emailInput) {
      return emailInput
    }
  }

  return undefined
}

export function getProvenanceObjectForOrder(
  orderDetail: FhirLabOrderDetail,
  status: R4.ICoding
) {
  const currentUserDetails: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const mainProvenanceId: string = getUniqueTempId()
  const statusProvenance: R4.IProvenance = {
    id: mainProvenanceId,
    resourceType: 'Provenance',
    occurredDateTime: new Date().toISOString(),

    activity: {
      text: status.display ?? '',
      coding: [status],
    },
    agent: [
      {
        type: {
          coding: [
            {
              code: 'enterer',
              display: 'Enterer',
              system:
                'http://terminology.hl7.org/CodeSystem/provenance-participant-type',
            },
          ],
        },
        who: {
          id: currentUserDetails.id,
          reference:
            `${currentUserDetails.resourceType}/${currentUserDetails.id}` ?? '',
        },
      },
    ],
    target: [
      {
        id: orderDetail.serviceRequest.id,
        reference:
          `${orderDetail.serviceRequest.resourceType}/${orderDetail.serviceRequest.id}` ??
          '',
      },
      {
        id: orderDetail.task?.id,
        reference:
          `${orderDetail.task?.resourceType}/${orderDetail.task?.id}` ?? '',
      },
    ],
  }

  return statusProvenance
}

export function getProvenanceObjectForTask(task: R4.ITask, status: R4.ICoding) {
  const currentUserDetails: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const mainProvenanceId: string = getUniqueTempId()
  const statusProvenance: R4.IProvenance = {
    id: mainProvenanceId,
    resourceType: 'Provenance',
    occurredDateTime: new Date().toISOString(),

    activity: {
      text: status.display ?? '',
      coding: [status],
    },
    agent: [
      {
        type: {
          coding: [
            {
              code: 'enterer',
              display: 'Enterer',
              system:
                'http://terminology.hl7.org/CodeSystem/provenance-participant-type',
            },
          ],
        },
        who: {
          id: currentUserDetails.id,
          reference:
            `${currentUserDetails.resourceType}/${currentUserDetails.id}` ?? '',
        },
      },
    ],
    target: [
      {
        id: task.id,
        reference: `${task.resourceType}/${task.id}` ?? '',
      },
    ],
  }

  return statusProvenance
}
