/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { DaysInSingleLetter } from 'utils/constants/calender_constants'
import { Typography, Box } from '@material-ui/core'
import { EachDay } from './EachDay'

interface TabProps {
  preSelectedTab: string[]
  menuLabel?: string
  onSelectionChanges: (selectedType: string[]) => void
}

export const DaySelector: React.FC<TabProps> = ({
  preSelectedTab,
  menuLabel,
  onSelectionChanges,
}: TabProps) => {
  const [selectedTab, setSelectedTab] = React.useState<string[]>(preSelectedTab)

  const handleChange = (selectedTabData: string) => {
    if (selectedTab.length >= 1) {
      if (selectedTab.includes(selectedTabData)) {
        const temp: string[] = selectedTab.filter((e) => e !== selectedTabData)
        onSelectionChanges(temp)
        setSelectedTab(temp)
      } else {
        const temp: string[] = [...selectedTab, selectedTabData]
        onSelectionChanges(temp)
        setSelectedTab([...selectedTab, selectedTabData])
      }
    } else {
      const temp: string[] = [selectedTabData]
      onSelectionChanges(temp)
      setSelectedTab([selectedTabData])
    }
  }

  /*  useEffect(() => {
    onSelectionChanges(selectedTab)
  }, [selectedTab, onSelectionChanges]) */

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' flexDirection='row' p={1} justifyContent='center'>
        <Typography variant='h6'>{menuLabel}</Typography>
      </Box>
      <Grid container direction='row'>
        {DaysInSingleLetter.map((value) => (
          <Grid item key={value}>
            <EachDay
              textTitle={value}
              isSelected={selectedTab.some((el: string) => el === value)}
              onClick={() => handleChange(value)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
