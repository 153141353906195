import { R4 } from '@ahryman40k/ts-fhir-types'

export const LabOrderTaskCodes: R4.ICoding[] = [
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/task-code',
    code: 'lab-test-order',
    display: 'Lab Test Order',
  },
]

export const PartnerLabOrderTaskCodes: R4.ICoding[] = [
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/task-code',
    code: 'parnerLab-test-order',
    display: 'Partner Lab Test Order',
  },
]

export const LabOrderHomeCollectionTaskCodes: R4.ICoding[] = [
  {
    system: 'http://wellopathy.com/fhir/india/core/CodeSystem/task-code',
    code: 'home-sample-collection',
    display: 'Home Sample Collection',
  },
]
