import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import _ from 'lodash'
import React from 'react'
import { getPriorityCodeDisplayFromCode } from 'utils/appointment_handle/medications_util'
import { KriyaDetail } from '../plan/ayurveda/addKriyasForEncounter'
import OverflowTypography from '../plan/ayurveda/overflowTypography'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '4px',
      margin: '0px',
    },
  },
})

interface KriyaListProps {
  kriyasList: KriyaDetail[]
  onEditClicked?: (medication: KriyaDetail) => void
  isReadonly?: boolean
}

export const AddedKriyasList: React.FC<KriyaListProps> = ({
  kriyasList,
  onEditClicked,
  isReadonly,
}: KriyaListProps) => {
  const classes = useStyles()

  return (
    <TableContainer
      style={{
        width: '100%',
        maxWidth: '100%',
        margin: '0px',
        padding: '0px',
      }}
    >
      <Table
        className={classes.table}
        aria-label='simple table'
        style={{
          tableLayout: 'fixed',
        }}
      >
        <TableHead
          style={{
            backgroundColor: kDialogueBackground,
          }}
        >
          <TableRow
            style={{
              fontWeight: 'bold',
            }}
          >
            <TableCell width={25}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Kriya
              </Typography>
            </TableCell>
            <TableCell width={35}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Medicine
              </Typography>
            </TableCell>
            <TableCell width={10}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Priority
              </Typography>
            </TableCell>

            <TableCell width={30}>
              <Typography
                variant='subtitle2'
                // color='initial'
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 12,
                  color: 'black',

                  fontWeight: 'bold',
                  lineHeight: 'normal',
                }}
              >
                Additional Notes
              </Typography>
            </TableCell>
            {isReadonly === undefined && (
              <TableCell width={4}>
                {/* <Typography
                  variant='subtitle2'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Additional Notes
                </Typography> */}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {kriyasList.map((row: KriyaDetail, index) => (
            <TableRow
              key={row.kriya?.code ?? ''}
              style={{
                backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f5',
              }}
            >
              <TableCell>
                <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  {_.capitalize(row.kriya?.display ?? '')}
                </Typography>
              </TableCell>
              <TableCell>
                <Box display='flex'>
                  <OverflowTypography
                    text={(row.medications ?? [])
                      .map((med) => med.display)
                      .join(', ')}
                    typographyProps={{
                      variant: 'body2',
                      color: 'initial',
                    }}
                  />
                </Box>
              </TableCell>
              <TableCell>
                <Typography color='initial' variant='body2'>
                  {row.priority
                    ? getPriorityCodeDisplayFromCode(row.priority)
                    : ''}
                </Typography>
              </TableCell>

              <TableCell>
                <OverflowTypography
                  text={row.remarks ?? ''}
                  typographyProps={{
                    variant: 'body2',
                    color: 'initial',
                  }}
                />
              </TableCell>
              {isReadonly === undefined && (
                <TableCell width={4}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    justifyContent='flex-start'
                    alignContent='center'
                    alignItems='center'
                  >
                    <Box paddingLeft={2}>
                      <Tooltip
                        title='Edit Kriya'
                        id='manage_medication_tooltip'
                      >
                        <IconButton
                          aria-label='btn_ord_cancel'
                          color='primary'
                          id='manage_medication_ipd'
                          style={{ padding: 0 }}
                          onClick={() => {
                            if (onEditClicked) onEditClicked(row)
                          }}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/editVector.png`}
                            alt='view'
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
