import { Box, Grid, Typography, useTheme } from '@material-ui/core'
import { Add, Done } from '@material-ui/icons'
import React from 'react'

interface NextStepsOfConsultationProps {
  planName: string
  subString: string
  onClicked?: () => void
  count?: number
  isEditAllowed?: boolean
  bottomWidget?: React.ReactNode
}

export const NextFollowup: React.FC<NextStepsOfConsultationProps> = ({
  planName,
  subString,
  count = 0,
  onClicked,
  isEditAllowed = true,
  bottomWidget,
}: NextStepsOfConsultationProps) => {
  const primary = useTheme().palette.primary.main
  const isSelected: boolean = count > 0

  return (
    <Box display='flex' width='100%' flexDirection='column' flexGrow>
      <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <Grid container style={{ padding: '0px', margin: '0px' }}>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>{planName}</Typography>
          </Grid>
        </Grid>
      </Box>

      {bottomWidget && (
        <Box display='flex' flexDirection='row' width='100%'>
          {bottomWidget}
        </Box>
      )}
    </Box>
  )
}
