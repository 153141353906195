import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  Theme,
  makeStyles,
} from '@material-ui/core'
import {
  DatePicker,
  DateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { UmlResponse } from 'models/umlResponse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addHistoryProcedureDetails,
  resetAddConditionState,
} from 'redux/fhirMedicalResources/addProcedure/addProcedureSlice'
import { RootState } from 'redux/rootReducer'
import {
  durationData,
  durationDataForChiefComplaints,
  durationDataForChiefComplaintsWithOne,
  durationDataWithOne,
} from 'utils/constants'
import {
  getAgeInDays,
  getAgeInMonths,
  getAgeInWeeks,
  getAgeInYears,
  getAgeOfPatientForChecking,
} from 'utils/fhirResourcesHelper'
import { validateCheckoutDateForChiefComplaints } from 'utils/formValidators'
import { WelloLoadingPage, WelloTextField } from 'wello-web-components'
import { WelloSelectUnit } from 'views/components/LeftMenu/welloSelectUnit'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloCondition } from 'models/WelloConditions'
import _ from 'lodash'
import { PurposeOfUse } from 'models/purposeOfUse'
import { getCurrentUserPractitionerDetails } from 'services/userDetailsService'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { updateProcedureDetail } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { SurgeryMasterSelector } from '../subjective/surgeryMasterSelector'

interface Props {
  open: boolean
  existingCondition: WelloCondition
  purposeOfUse: PurposeOfUse
  onClose: () => void
  patient: R4.IPatient
  onMedicalConditionAdded: (createdCondition: R4.IProcedure | undefined) => void
  appointmentId?: string
}
export const EditProcedure: React.FC<Props> = ({
  open,
  onClose,
  purposeOfUse,
  onMedicalConditionAdded,
  existingCondition,
  patient,
  appointmentId,
}) => {
  const { t } = useTranslation(['common', 'procedure'])
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '35%',
    },
  }))
  const [loading, setLoading] = useState<boolean>(false)
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const classes = useStyles()
  const [currentCondition, setCurrentCondition] = useState<WelloCondition>({
    id: existingCondition.id,
    selectedType: existingCondition.selectedType ?? 'days'.toLowerCase(),
    days: existingCondition.days,
    month: existingCondition.month,
    year: existingCondition.year,
    week: existingCondition.week,
    selectedDate: existingCondition.selectedDate,
    condition: existingCondition.condition,
    existingCondition: existingCondition.existingCondition,
    isActive: existingCondition.isActive,
    notes: existingCondition.notes,
  })

  const [addedConditions, setAddedConditions] = useState<WelloCondition[]>([])

  function handleMonths(rate: number) {
    setCurrentCondition({
      ...currentCondition,
      month: rate,
    })
  }
  function handleYear(rate: number) {
    setCurrentCondition({
      ...currentCondition,
      year: rate,
    })
  }

  function handleDays(rate: number) {
    setCurrentCondition({
      ...currentCondition,
      days: rate,
    })
  }

  function handleWeeks(rate: number) {
    setCurrentCondition({
      ...currentCondition,
      week: rate,
    })
  }

  const handleDateChange = (date: MaterialUiPickersDate) => {
    setCurrentCondition({
      ...currentCondition,
      selectedDate: date !== null ? moment(date).toDate() : new Date(),
    })
  }
  const [additionalNotes, setAdditionalNotes] = useState<string>()
  const dispatch = useDispatch()

  const historyProcedureSlice = useSelector(
    (state: RootState) => state.historyProcedureSlice
  )

  function isEnableCreateButton(): boolean | undefined {
    let result: boolean = false
    if (currentCondition.condition === undefined) {
      result = false
    }

    if (currentCondition.selectedType) {
      if (
        currentCondition.selectedType === 'days' &&
        currentCondition.days &&
        (currentCondition.days < 1 ||
          currentCondition.days > getAgeInDays(patient))
      ) {
        result = true
      }
      if (
        currentCondition.selectedType === 'months' &&
        currentCondition.month &&
        (currentCondition.month < 1 ||
          currentCondition.month > getAgeInMonths(patient))
      ) {
        result = true
      }
      if (
        currentCondition.selectedType === 'years' &&
        currentCondition.year &&
        (currentCondition.year < 1 ||
          currentCondition.year > getAgeInYears(patient))
      ) {
        result = true
      }
      if (
        currentCondition.selectedType === 'weeks' &&
        currentCondition.week &&
        (currentCondition.week < 1 ||
          currentCondition.week > getAgeInWeeks(patient))
      ) {
        result = true
      }
      if (
        currentCondition.selectedType === 'custom' &&
        currentCondition.selectedDate &&
        validateCheckoutDateForChiefComplaints(
          moment(currentCondition.selectedDate).format('YYYY-MM-DD'),
          getAgeOfPatientForChecking(patient),
          false
        ).length > 0
      ) {
        result = true
      } else {
        result = false
      }
    }

    return result
  }

  function updateConditions() {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.IProcedure = {
      ...currentCondition.existingProcedure!,
    }
    oldProcedure.code = {
      coding: [currentCondition.condition!],
      text: currentCondition.condition!.display,
    }

    if (
      currentCondition.selectedType &&
      currentCondition.selectedType!.length > 0
    ) {
      if (
        currentCondition.selectedType === 'days' ||
        currentCondition.selectedType === 'day'
      ) {
        if (currentCondition.days && currentCondition.days! > 0) {
          oldProcedure.performedDateTime = undefined
          oldProcedure.performedAge = {
            system: 'http://unitsofmeasure.org',
            unit: 'd',
            code: 'd',
            value: currentCondition.days,
          }
        }
      } else if (
        currentCondition.selectedType === 'months' ||
        currentCondition.selectedType === 'month'
      ) {
        oldProcedure.performedDateTime = undefined
        oldProcedure.performedAge = {
          system: 'http://unitsofmeasure.org',
          unit: 'mo',
          code: 'mo',
          value: currentCondition.month!,
        }
      } else if (
        currentCondition.selectedType === 'weeks' ||
        currentCondition.selectedType === 'week'
      ) {
        oldProcedure.performedDateTime = undefined
        oldProcedure.performedAge = {
          system: 'http://unitsofmeasure.org',
          unit: 'wk',
          code: 'wk',
          value: currentCondition.week!,
        }
      } else if (
        currentCondition.selectedType === 'years' ||
        currentCondition.selectedType === 'day'
      ) {
        oldProcedure.performedDateTime = undefined
        oldProcedure.performedAge = {
          system: 'http://unitsofmeasure.org',
          unit: 'a',
          code: 'a',
          value: currentCondition.year!,
        }
      } else if (currentCondition.selectedType === 'custom') {
        oldProcedure.performedAge = undefined
        if (currentCondition.selectedDate)
          oldProcedure.performedDateTime =
            currentCondition.selectedDate!.toISOString()
      }
    }
    if (currentCondition.notes && currentCondition.notes!.length > 0) {
      oldProcedure.note = [
        {
          authorReference: {
            reference: `${getCurrentUserPractitionerDetails().resourceType}/${
              getCurrentUserPractitionerDetails().id
            }`,
          },
          text: currentCondition.notes,
          time: new Date().toISOString(),
        },
      ]
    } else {
      oldProcedure.note = undefined
    }
    setLoading(true)

    updateProcedureDetail(oldProcedure, appointmentId!).then((e) => {
      if (e) {
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Procedure details updated successfully'))
        onMedicalConditionAdded(oldProcedure)
        // dispatch(
        //   requestConditionHistoryOfPatient(
        //     patient,
        //     purposeOfUse,
        //     splitview,
        //     requestingAppointmentId,
        //     encounterId
        //   )
        // )
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while updating Condition details. Please try again later'
          )
        )
      }
    })
  }

  useEffect(() => {
    setCurrentCondition({
      id: existingCondition.id,
      selectedType: existingCondition.selectedType ?? 'days'.toLowerCase(),
      days: existingCondition.days,
      month: existingCondition.month,
      year: existingCondition.year,
      week: existingCondition.week,
      selectedDate: existingCondition.selectedDate,
      condition: existingCondition.condition,
      existingProcedure: existingCondition.existingProcedure,
      isActive: existingCondition.isActive,
      notes: existingCondition.notes,
    })
  }, [existingCondition])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setAddedConditions([])
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Update Surgery Details</DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#FFFFFF93',
          padding: 0,

          margin: 0,
        }}
      >
        {historyProcedureSlice.adding && (
          <WelloLoadingPage title='Adding details' />
        )}

        {!historyProcedureSlice.adding && (
          <Box
            px={2}
            py={2}
            key={currentCondition.id}
            display='flex'
            flexDirection='column'
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid container direction='column' wrap='nowrap' spacing={1}>
                <Grid item>
                  <Grid container direction='column' spacing={1} wrap='nowrap'>
                    <Box
                      display='flex'
                      width='100%'
                      flexDirection='column'
                      px={1}
                    >
                      <Box px={0.3} paddingBottom={0.3}>
                        <Typography variant='subtitle2'>
                          {' '}
                          {t('labelCommon:select_surgeries')}{' '}
                        </Typography>
                      </Box>

                      <Box width='100%' paddingRight={0.5}>
                        <SurgeryMasterSelector
                          onSelectionChanges={(e: UmlResponse) => {
                            if (e.cui.length > 0) {
                              const conditonVal: R4.ICoding = {
                                system:
                                  'http://terminology.hl7.org/CodeSystem/umls',
                                code: e.cui,
                                display: e.display,
                              }
                              setCurrentCondition({
                                ...currentCondition,
                                condition: conditonVal,
                              })
                              // setSelectedCondition(conditonVal)
                            } else {
                              setCurrentCondition({
                                ...currentCondition,
                                condition: undefined,
                              })
                            }
                          }}
                          preSelectedComplaints={{
                            cui: currentCondition.condition
                              ? currentCondition.condition.code ?? ''
                              : '',
                            display: currentCondition.condition
                              ? currentCondition.condition.display ?? ''
                              : '',
                            label: currentCondition.condition
                              ? currentCondition.condition.display ?? ''
                              : '',
                            snomed_ct:
                              'http://terminology.hl7.org/CodeSystem/umls',
                          }}
                          disabled={false}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction='row' wrap='nowrap'>
                    <Grid
                      item
                      xs={
                        currentCondition.selectedType &&
                        currentCondition.selectedType === 'days'
                          ? 8
                          : currentCondition.selectedType &&
                            currentCondition.selectedType === 'custom'
                          ? 6
                          : 7
                      }
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        paddingTop={1.4}
                      >
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          paddingLeft={0.5}
                        >
                          <WelloFormItemLabel
                            title={t(
                              'labelProcedure:attr_labels.Procedure.performedDateTime'
                            )}
                          />{' '}
                          {currentCondition.selectedType &&
                            (currentCondition.selectedType === 'days' ||
                              currentCondition.selectedType === 'day') && (
                              <Box width='68%' paddingLeft={1}>
                                <WelloSelectUnit
                                  title=''
                                  duration='Select Duration Unit'
                                  availableCodings={
                                    currentCondition.days! === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChanged={(type) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                  }}
                                  textFieldProps={{
                                    size: 'small',
                                    fullWidth: true,
                                    id: 'proc_6',
                                  }}
                                  preSelectedCoding={
                                    currentCondition.days === 1 ||
                                    currentCondition.week! === 1
                                      ? durationDataWithOne[0]
                                      : durationData[0]
                                  }
                                />
                              </Box>
                            )}
                          {currentCondition.selectedType &&
                            (currentCondition.selectedType === 'week' ||
                              currentCondition.selectedType === 'weeks') && (
                              <Box width='67.5%' paddingLeft={1}>
                                <WelloSelectUnit
                                  title=''
                                  duration='Select Duration Unit'
                                  availableCodings={
                                    currentCondition.week! === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChanged={(type) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                  }}
                                  textFieldProps={{
                                    size: 'small',
                                    fullWidth: true,
                                    id: 'proc_7',
                                  }}
                                  preSelectedCoding={
                                    currentCondition.week! === 1
                                      ? durationDataWithOne[1]
                                      : durationData[1]
                                  }
                                />
                              </Box>
                            )}
                          {currentCondition.selectedType &&
                            (currentCondition.selectedType === 'months' ||
                              currentCondition.selectedType === 'month') && (
                              <Box width='67.5%' paddingLeft={1}>
                                <WelloSelectUnit
                                  title=''
                                  duration='Select Duration Unit'
                                  availableCodings={
                                    currentCondition.month! === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChanged={(type) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                  }}
                                  textFieldProps={{
                                    size: 'small',
                                    fullWidth: true,
                                    id: 'proc_8',
                                  }}
                                  preSelectedCoding={
                                    currentCondition.month! === 1
                                      ? durationDataWithOne[2]
                                      : durationData[2]
                                  }
                                />
                              </Box>
                            )}
                          {currentCondition.selectedType &&
                            (currentCondition.selectedType === 'years' ||
                              currentCondition.selectedType === 'year') && (
                              <Box width='67.5%' paddingLeft={1}>
                                <WelloSelectUnit
                                  title=''
                                  duration='Select Duration Unit'
                                  availableCodings={
                                    currentCondition.year! === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChanged={(type) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                  }}
                                  textFieldProps={{
                                    size: 'small',
                                    fullWidth: true,
                                    id: 'proc_9',
                                  }}
                                  preSelectedCoding={
                                    currentCondition.year! === 1
                                      ? durationDataWithOne[3]
                                      : durationData[3]
                                  }
                                />
                              </Box>
                            )}
                          {currentCondition.selectedType &&
                            currentCondition.selectedType === 'custom' && (
                              <Box width='65%' paddingLeft={1}>
                                <WelloSelectUnit
                                  title=''
                                  duration='Select Duration Unit'
                                  availableCodings={
                                    currentCondition.year === 1 &&
                                    currentCondition.month === 1 &&
                                    currentCondition.week === 1 &&
                                    currentCondition.days === 1
                                      ? durationDataForChiefComplaintsWithOne
                                      : durationDataForChiefComplaints
                                  }
                                  onChanged={(type) => {
                                    if (
                                      type.display &&
                                      type.display.length > 0
                                    ) {
                                      if (
                                        type.display === 'Days' ||
                                        type.display === 'Day'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'days',
                                        })
                                      else if (
                                        type.display === 'Months' ||
                                        type.display === 'Month'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'months',
                                        })
                                      else if (
                                        type.display === 'Weeks' ||
                                        type.display === 'Week'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'weeks',
                                        })
                                      else if (
                                        type.display === 'Years' ||
                                        type.display === 'Year'
                                      )
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'years',
                                        })
                                      else
                                        setCurrentCondition({
                                          ...currentCondition,
                                          selectedType: 'custom',
                                        })
                                    }
                                    // setDuration(type.display ?? '')
                                  }}
                                  textFieldProps={{
                                    size: 'small',
                                    fullWidth: true,
                                    id: 'proc_10',
                                  }}
                                  preSelectedCoding={
                                    durationDataForChiefComplaints[4]
                                  }
                                />
                              </Box>
                            )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        paddingTop={1.4}
                      >
                        {currentCondition.selectedType &&
                          (currentCondition.selectedType === 'days' ||
                            currentCondition.selectedType === 'day') && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              paddingLeft={1}
                            >
                              <WelloFormItemLabel title='Enter Days' />{' '}
                              <Box width='64%' paddingLeft={1}>
                                <WelloTextFieldWithoutTitle
                                  textProps={{
                                    id: 'proc_11',
                                    value: currentCondition.days!,
                                    type: 'number',
                                    inputProps: {
                                      max: 365,
                                      min: 1,
                                    },
                                    error:
                                      currentCondition.days! < 1 ||
                                      currentCondition.days! >
                                        getAgeInDays(patient) ||
                                      Number.isNaN(currentCondition.days!),
                                    helperText:
                                      currentCondition.days! < 1 ||
                                      currentCondition.days! >
                                        getAgeInDays(patient) ||
                                      Number.isNaN(currentCondition.days)
                                        ? `Days should be in between 1 to ${getAgeInDays(
                                            patient
                                          )}`
                                        : '',
                                    onChange: (rate) => {
                                      handleDays(
                                        parseInt(rate.target.value, 10)
                                      )
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          )}

                        {currentCondition.selectedType &&
                          (currentCondition.selectedType === 'weeks' ||
                            currentCondition.selectedType === 'week') && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              paddingLeft={1}
                            >
                              <WelloFormItemLabel title='Enter Weeks' />{' '}
                              <Box width='62%' paddingLeft={1}>
                                <WelloTextFieldWithoutTitle
                                  textProps={{
                                    id: 'proc_12',
                                    value: currentCondition.week!,
                                    type: 'number',
                                    inputProps: {
                                      max: 52,
                                      min: 1,
                                    },
                                    error:
                                      currentCondition.week! < 1 ||
                                      currentCondition.week! >
                                        getAgeInWeeks(patient) ||
                                      Number.isNaN(currentCondition.week!),
                                    helperText:
                                      currentCondition.week! < 1 ||
                                      currentCondition.week! > 52 ||
                                      Number.isNaN(currentCondition.week!)
                                        ? `Weeks should be in between 1 to ${getAgeInWeeks(
                                            patient
                                          )}`
                                        : '',
                                    onChange: (rate) => {
                                      handleWeeks(
                                        parseInt(rate.target.value, 10)
                                      )
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          )}

                        {currentCondition.selectedType &&
                          (currentCondition.selectedType === 'months' ||
                            currentCondition.selectedType === 'month') && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              paddingLeft={1}
                            >
                              <WelloFormItemLabel title='Enter Months' />{' '}
                              <Box width='60%' paddingLeft={1}>
                                <WelloTextFieldWithoutTitle
                                  textProps={{
                                    id: 'proc_13',
                                    value: currentCondition.month!,
                                    type: 'number',
                                    inputProps: {
                                      max: 12,
                                      min: 1,
                                    },
                                    error:
                                      currentCondition.month! < 1 ||
                                      currentCondition.month! >
                                        getAgeInMonths(patient) ||
                                      Number.isNaN(currentCondition.month!),
                                    helperText:
                                      currentCondition.month! < 1 ||
                                      currentCondition.month! >
                                        getAgeInMonths(patient) ||
                                      Number.isNaN(currentCondition.month!)
                                        ? `Months should be in between 1 to ${getAgeInMonths(
                                            patient
                                          )}`
                                        : '',
                                    onChange: (rate) => {
                                      handleMonths(
                                        parseInt(rate.target.value, 10)
                                      )
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          )}

                        {currentCondition.selectedType &&
                          (currentCondition.selectedType === 'year' ||
                            currentCondition.selectedType === 'years') && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                              paddingLeft={1}
                            >
                              <WelloFormItemLabel title='Enter Years' />{' '}
                              <Box width='65.5%' paddingLeft={1}>
                                <WelloTextFieldWithoutTitle
                                  textProps={{
                                    id: 'proc_14',
                                    value: currentCondition.year!,
                                    type: 'number',
                                    inputProps: {
                                      max: 12,
                                      min: 1,
                                    },
                                    error:
                                      currentCondition.year! < 1 ||
                                      currentCondition.year! >
                                        getAgeInYears(patient) ||
                                      Number.isNaN(currentCondition.year!),
                                    helperText:
                                      currentCondition.year! < 1 ||
                                      currentCondition.year! >
                                        getAgeInYears(patient) ||
                                      Number.isNaN(currentCondition.year!)
                                        ? `Years should be in between 1 to ${getAgeInYears(
                                            patient
                                          )}`
                                        : '',
                                    onChange: (rate) => {
                                      handleYear(
                                        parseInt(rate.target.value, 10)
                                      )
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          )}

                        {currentCondition.selectedType &&
                          currentCondition.selectedType === 'custom' && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              paddingLeft={1}
                            >
                              <MuiPickersUtilsProvider utils={MomentUtils}>
                                <WelloFormItemLabel title='Choose Date' />
                                <Box width='65.5%' paddingLeft={1}>
                                  <KeyboardDatePicker
                                    clearable
                                    id='date'
                                    maxDate={moment(
                                      currentCondition.selectedDate &&
                                        currentCondition.selectedDate
                                    )}
                                    minDate={moment(
                                      getAgeOfPatientForChecking(patient)
                                    )}
                                    value={
                                      currentCondition.selectedDate
                                        ? currentCondition.selectedDate
                                        : new Date()
                                    }
                                    format='DD-MM-YYYY'
                                    onChange={handleDateChange}
                                    error={
                                      validateCheckoutDateForChiefComplaints(
                                        moment(
                                          currentCondition.selectedDate!
                                        ).format('YYYY-MM-DD'),
                                        getAgeOfPatientForChecking(patient),
                                        false
                                      ).length > 0
                                    }
                                    helperText={validateCheckoutDateForChiefComplaints(
                                      moment(
                                        currentCondition.selectedDate!
                                      ).format('YYYY-MM-DD'),
                                      getAgeOfPatientForChecking(patient),
                                      false
                                    )}
                                    size='small'
                                    inputVariant='outlined'
                                  />
                                </Box>
                              </MuiPickersUtilsProvider>
                            </Box>
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    paddingRight={1}
                    paddingLeft={0.5}
                  >
                    <WelloFormItemLabel title='Additional Notes' />

                    <TextField
                      size='small'
                      fullWidth
                      multiline
                      id='proc_15'
                      type='number'
                      value={currentCondition.notes}
                      variant='outlined'
                      onChange={(event) => {
                        setCurrentCondition({
                          ...currentCondition,
                          notes: event.target.value,
                        })
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          justifyContent='flex-end'
          paddingRight={2}
        >
          <Button
            onClick={() => {
              onClose()

              setAddedConditions([])
            }}
            variant='outlined'
            id='proc_16'
            disableElevation
            disabled={historyProcedureSlice.adding}
          >
            {t('labelCommon:cancel')}
          </Button>

          <Button
            onClick={() => {
              updateConditions()
            }}
            variant='contained'
            color='primary'
            disableElevation
            id='proc_17'
            disabled={loading === true || isEnableCreateButton()}
          >
            Update
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
