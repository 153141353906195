import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { truncate } from 'lodash'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { RoomPrice } from 'models/roomPrice'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserUnitDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import {
  getRoomNumber,
  getRoomPriceFromBundle,
} from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { getExpandedServiceRequestFromBundle } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { logger } from 'utils/logger'
import { RoomNoSearchStatus } from './roomSearchStatus'

const initialState: RoomNoSearchStatus = {
  searchingPrice: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingPrice: false,
}

const roomNoSearchSlice = createSlice({
  name: 'roomNoSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<RoomNoSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingPrice = action.payload.searchingPrice
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorReason = action.payload.errorReason
      state.roomNumbers = action.payload.roomNumbers
      state.errorWhileSearchingPrice = action.payload.errorWhileSearchingPrice
    },
  },
})

export const requestForRoomNumber =
  (roomType: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: RoomNoSearchStatus = {
      searchingPrice: true,
      errorWhileSearchingPrice: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(roomNoSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const organization: R4.IOrganization = getCurrentUserUnitDetails()
      const searchParameters: any = {
        organization: organization.id ?? '',
        _count: 200,
      }

      const response: any =
        await fhirClient.doGetResourceIncludeAndIncludeIterate(
          `/Location?name:contains=${roomType}&&location-physical-type=ro`,
          searchParameters
        )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingPrice = true
        state.searchingPrice = false

        dispatch(roomNoSearchSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp.right
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          const rommNumbers: R4.ICoding[] = getRoomNumber(appointmentResponse)
          logger.info('Orders length')

          state.resultsAvailable = true
          state.searchingPrice = false
          state.roomNumbers = rommNumbers
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingPrice = false
          dispatch(roomNoSearchSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: RoomNoSearchStatus = {
            searchingPrice: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            errorWhileSearchingPrice: false,
          }
          dispatch(roomNoSearchSlice.actions.updatedStatus(errorSearchDoctor))
        }
      } /* */
    } catch (error) {
      const errorSearchDoctor: RoomNoSearchStatus = {
        searchingPrice: false,
        resultsAvailable: false,
        errorWhileSearchingPrice: true,
        errorReason: 'Error while fetching Room No',
      }
      dispatch(roomNoSearchSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default roomNoSearchSlice.reducer
