import {
  Box,
  Button,
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core'
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@material-ui/icons'
import { LabOfferingDetail } from 'models/labOfferDetail'
import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { searchLabOfferingsforPartnerLab } from 'redux/lab/offerings/labOfferingSearchHandler/labOfferingSearchSlice'
import { RootState } from 'redux/rootReducer'
import { WelloFormItemLabel, WelloLoadingIndicator } from 'wello-web-components'

interface Props {
  onLabTestsChanged?: (selectedValues: LabOfferingDetail[]) => void
  locationId: string
}
export const LabSelectorPartnerLab: React.FC<Props> = ({
  onLabTestsChanged,
  locationId,
}: Props) => {
  const practitionerSearchState = useSelector(
    (state: RootState) => state.labOfferingSearchSlice
  )
  const dispatch = useDispatch()
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const { t } = useTranslation()

  function handleChecked(id: string) {
    if (selectedIds.includes(id)) {
      const tempIds: string[] = selectedIds.filter((item) => item !== id)
      updatePractitioners(tempIds)
    } else {
      const tempIds: string[] = [...selectedIds]
      tempIds.push(id)
      updatePractitioners(tempIds)
    }
  }
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)

  function updatePractitioners(filteredIds: string[]) {
    if (onLabTestsChanged) {
      const selectedDoctors: LabOfferingDetail[] = [
        ...(practitionerSearchState.labOfferings ?? []),
      ].filter((val) => filteredIds.includes(val.id))
      onLabTestsChanged(selectedDoctors)
    }

    setSelectedIds(filteredIds)
  }

  useEffect(() => {
    dispatch(searchLabOfferingsforPartnerLab(locationId))
  }, [dispatch])
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
          <Button
            onClick={() => {
              dispatch(searchLabOfferingsforPartnerLab(locationId))
            }}
          >
            Try again
          </Button>
        </div>
      )}
    >
      <Box display='flex' flexDirection='column' overflow='auto'>
        <Box
          display='flex'
          flexDirection='row'
          p={1}
          justifyContent='space-between'
          alignContent='center'
          alignItems='center'
        >
          <WelloFormItemLabel title={t('test_name')} />
          <IconButton
            aria-label='collapse_lab_offerings'
            size='small'
            onClick={() => {
              setIsCollapsed(!isCollapsed)
            }}
          >
            {isCollapsed && <ArrowDropUpOutlined />}
            {!isCollapsed && <ArrowDropDownOutlined />}
          </IconButton>
        </Box>
        <Collapse
          in={isCollapsed}
          style={{ padding: 0, margin: 0, overflow: 'auto' }}
        >
          <Box display='flex' flexDirection='row'>
            <TextField
              id='tf_search_lab_offerings'
              label='Search'
              size='small'
              fullWidth
              variant='outlined'
              onChange={(e) => {
                dispatch(
                  searchLabOfferingsforPartnerLab(
                    locationId,
                    e.target.value ?? ''
                  )
                )
              }}
            />
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            overflow='auto'
            minHeight='200px'
            maxHeight='250px'
            style={{
              width: '100%',
            }}
          >
            {' '}
            {practitionerSearchState.searching && (
              <WelloLoadingIndicator message='Searching doctors' />
            )}
            {practitionerSearchState.error && (
              <Typography>
                {' '}
                {practitionerSearchState.errorMessage ??
                  'Error while searching'}
              </Typography>
            )}
            {practitionerSearchState.noResultsAvailable && (
              <Typography> No Results available</Typography>
            )}
            {practitionerSearchState.resultsAvailable &&
              practitionerSearchState.labOfferings && (
                <List
                  style={{
                    width: '100%',
                    overflow: 'auto',
                  }}
                >
                  {practitionerSearchState.labOfferings.map(
                    (value: LabOfferingDetail) => {
                      const labelId = `checkbox-list-label-${value.id}`

                      return (
                        <ListItem
                          key={value.id}
                          role={undefined}
                          dense
                          style={{
                            width: '100%',
                            marginTop: 0,
                            marginBottom: 0,
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                          button
                          onClick={() => {
                            handleChecked(value.id)
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge='start'
                              checked={selectedIds.indexOf(value.id) !== -1}
                              tabIndex={-1}
                              size='small'
                              disableRipple
                              color='primary'
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={value.planDefinition.title ?? value.name}
                            primaryTypographyProps={{
                              variant: 'subtitle1',
                              color:
                                selectedIds.indexOf(value.id) !== -1
                                  ? 'primary'
                                  : undefined,
                            }}
                          />
                        </ListItem>
                      )
                    }
                  )}
                </List>
              )}
          </Box>
        </Collapse>
      </Box>
    </ErrorBoundary>
  )
}
