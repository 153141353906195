import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserUnitReference,
  getUserDetails,
} from 'services/userDetailsService'
import {
  getExpandedPartnerLabFromBundle,
  getExpandedPartnerLabFromBundleCount,
} from 'utils/collectionCenterHelper/partnerLabDataConverter'
import { getNumberValueExtensionsOfUrl } from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { FhirPartnerLabDetails } from '../../../models/fhirPartnerLabDetails'
import { PartnerLabSearchStatus } from './partnerLabSearchStatus'

const initialState: PartnerLabSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

let currentSelectedStatusTypes: string[] | undefined
let currentSelectedDoctors: PractitionerWithRole[]

const partnerLabOnboardInvitationsSlice = createSlice({
  name: 'partnerLabOnboardInvitationsSlice',
  initialState,
  reducers: {
    searchingInvitations(
      state,
      action: PayloadAction<PartnerLabSearchStatus>
    ) {},

    searchResults(state, action: PayloadAction<PartnerLabSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.invitations = action.payload.invitations
      state.partnerLabDetails = action.payload.partnerLabDetails
    },

    noDataFoundForSearch(state, action: PayloadAction<PartnerLabSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.invitations = action.payload.invitations
      state.partnerLabDetails = action.payload.partnerLabDetails
    },

    errorWhileSearching(state, action: PayloadAction<PartnerLabSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.invitations = action.payload.invitations
      state.partnerLabDetails = action.payload.partnerLabDetails
    },
  },
})

export const searchInvitations =
  (
    selectedDoctors: PractitionerWithRole[],
    selectedStatusTypes?: string[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorFetchingUnits: PartnerLabSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      partnerLabOnboardInvitationsSlice.actions.errorWhileSearching(
        errorFetchingUnits
      )
    )
    try {
      const currentUserRole: R4.IPractitionerRole | undefined =
        getUserDetails()?.practitionerRole
      currentSelectedStatusTypes = selectedStatusTypes
      const searchParameters: any = {
        requester: `${currentUserRole?.resourceType}/${
          currentUserRole?.id ?? ''
        }`,
        code: 'user-invitation',
        status:
          currentSelectedStatusTypes && currentSelectedStatusTypes.length > 0
            ? currentSelectedStatusTypes.join(',')
            : '',
        _include: '*',
      }
      currentSelectedDoctors = selectedDoctors
      if (currentSelectedDoctors && currentSelectedDoctors.length > 0) {
        let doctorsList: string = ''
        currentSelectedDoctors.forEach((item) => {
          doctorsList = `${currentUserRole?.resourceType}/${item.roleObject.id}`
        })
        searchParameters.owner = doctorsList
      }

      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const response: any = await fhirClient.doGetResource(
        '/Task',
        searchParameters
      )

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const orgResponse: R4.IBundle = relatedFhirDecodeRes.right
        if (orgResponse.total) {
          if (orgResponse.total > 0) {
            if (orgResponse.entry) {
              const orgArray: R4.ITask[] = orgResponse.entry.map(
                (item) => item.resource as R4.ITask
              )
              let fhirPartnerLabDataNew: FhirPartnerLabDetails[] = []
              const fhirPartnerLabData: FhirPartnerLabDetails[] =
                getExpandedPartnerLabFromBundle(orgResponse)

              if (fhirPartnerLabData.length > 0) {
                for (let i = 0; i < fhirPartnerLabData.length; i++) {
                  if (fhirPartnerLabData[i].locationId) {
                    // eslint-disable-next-line no-await-in-loop
                    const fhirPartnerLab = await updatePractitionerDetails(
                      fhirPartnerLabData[i]
                    )
                    fhirPartnerLabDataNew.push(fhirPartnerLab)
                  } else {
                    fhirPartnerLabDataNew.push(fhirPartnerLabData[i])
                  }
                }
              }
              if (fhirPartnerLabDataNew.length === 0)
                fhirPartnerLabDataNew = fhirPartnerLabData
              const fetchUnitListResult: PartnerLabSearchStatus = {
                error: false,
                noResultsAvailable: false,
                resultsAvailable: true,
                searching: false,
                invitations: orgArray,
                totalCount: orgResponse.total,
                partnerLabDetails: fhirPartnerLabDataNew,
              }
              dispatch(
                partnerLabOnboardInvitationsSlice.actions.searchResults(
                  fetchUnitListResult
                )
              )
              return
            }
          }
        }
        const noSearchResults: PartnerLabSearchStatus = {
          error: false,
          noResultsAvailable: true,
          resultsAvailable: false,
          searching: false,
        }
        dispatch(
          partnerLabOnboardInvitationsSlice.actions.noDataFoundForSearch(
            noSearchResults
          )
        )
        return
      }
      const errorWhileSearchPatient: PartnerLabSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error while searching',
      }
      dispatch(
        partnerLabOnboardInvitationsSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: PartnerLabSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        partnerLabOnboardInvitationsSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

async function updatePractitionerDetails(
  fhirPractDetail: FhirPartnerLabDetails
): Promise<FhirPartnerLabDetails> {
  let fhirDetail: FhirPartnerLabDetails = fhirPractDetail
  const unitDetails = getCurrentUserUnitReference()!
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirApi.doGetResource(
    `/PlanDefinition?partner-lab=${fhirPractDetail.locationId}&_revinclude:iterate=ServiceRequest:instantiates-canonical&plan-def-organization=Organization/${unitDetails.id}&status=active&_include=*`
  )
  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const orgResponse: R4.IBundle = relatedFhirDecodeRes.right
    if (orgResponse.total) {
      if (orgResponse.total > 0) {
        if (orgResponse.entry) {
          fhirDetail.testCount = orgResponse.total
          fhirDetail = getExpandedPartnerLabFromBundleCount(
            orgResponse,
            fhirPractDetail
          )
        }
      }
    } else {
      const LocResponse: any = await fhirApi.doGetResource(
        `/${fhirPractDetail.locationId}`
      )
      const relatedLocFhirDecodeRes: E.Either<Errors, R4.ILocation> =
        R4.RTTI_Location.decode(LocResponse)
      if (relatedLocFhirDecodeRes._tag === 'Right') {
        fhirDetail.commission = `${
          LocResponse.extension
            ? getNumberValueExtensionsOfUrl(
                LocResponse.extension,
                'http://wellopathy.com/fhir/india/core/CodeSystem/commission'
              )
            : 0
        } %`
        fhirDetail.commission_Data = LocResponse.extension
          ? getNumberValueExtensionsOfUrl(
              LocResponse.extension,
              'http://wellopathy.com/fhir/india/core/CodeSystem/commission'
            )
          : 0
      }
    }
  }
  return fhirDetail
}

export default partnerLabOnboardInvitationsSlice.reducer
