import { DefaultValue, atom, selector } from 'recoil'
import { orderBy, cloneDeep } from 'lodash'
import { getCurrentUserPractitionerDetails } from 'services/userDetailsService'
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Cart, CartItem } from './cart-models'

export const cartPatientIdState = atom<string | undefined>({
  key: 'cartPatientIdStateKey',
  default: undefined,
})

export const cartSkippedState = atom<string[]>({
  key: 'cartSkippedStateKey',
  default: [],
})

export const cartPatientObjectState = atom<R4.IPatient | undefined>({
  key: 'cartObjectKey',
  default: undefined,
})

export const orderForObject = atom<R4.ICoding | undefined>({
  key: 'orderForObjectKey',
  default: {
    code: 'clinic',
    display: 'Order For Clinic',
    system: '',
  },
})

export const cartDoctorIdState = atom<string | undefined>({
  key: 'cartDoctorIdStateKey',
  default: getCurrentUserPractitionerDetails().id ?? '',
})

export const cartAppointmentState = atom<string | undefined>({
  key: 'cartAppointmentState',
  default: undefined,
})

export const cartOrderForCachedState = atom<'clinic' | 'patient'>({
  key: 'cartOrderForCachedStateKey',
  default: 'clinic',
})

export const cartCachedState = atom<Cart | undefined>({
  key: 'cartCachedStateKey',
  default: undefined,
})

// Fetch data from localstorage or atom
const cartDataState = selector({
  key: 'cartDataStateKey',
  get: ({ get }) => {
    const cart = get(cartCachedState)
    if (cart && cart.items.length > 0) {
      return cart
    }
    try {
      const data = window.localStorage.getItem('wellopathy-cart')
      if (!data || data.trim() === '') {
        return undefined
      }
      const items = JSON.parse(data) as CartItem[]
      return { items, delivery: 0 } as Cart
    } catch (error) {
      console.error('Error deserializing cart items')
    }
    return undefined
  },
  set: ({ set }, newCart: any) => {
    if (!newCart || newCart instanceof DefaultValue) {
      console.log('c: clearing cart')
      window.localStorage.removeItem('wellopathy-cart')
      return
    }
    // Sort the items when writing
    const sorted = orderBy(newCart.items, (i) => i.product.sku.sku)
    newCart.items = sorted
    window.localStorage.setItem(
      'wellopathy-cart',
      JSON.stringify(newCart.items)
    )
    set(cartCachedState, newCart)
  },
})

// sort the data and return.
export const cartState = selector({
  key: 'cartStateKey',
  get: ({ get }) => {
    const cart = get(cartDataState)
    if (!cart) {
      return { items: [], delivery: 0 } as Cart
    }
    return cart
  },
  set: ({ set }, newItems) => {
    set(cartDataState, newItems)
  },
})
