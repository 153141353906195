/* eslint-disable @typescript-eslint/no-shadow */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { getExpandedAppointmentFromBundle } from 'utils/common/patientDataTableHelper'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import { UmlClient } from 'services/umlsClient'
import { getUserCurrentRole } from 'services/userDetailsService'
import { UmlResponse } from 'models/umlResponse'
import { ConditionSearchStatus } from './conditionSearchStatusTypes'

const initialState: ConditionSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const conditionSearchSlice = createSlice({
  name: 'condition',
  initialState,
  reducers: {
    searchingCondition(state, action: PayloadAction<ConditionSearchStatus>) {},

    searchResults(state, action: PayloadAction<ConditionSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
    },

    noDataFoundForSearch(state, action: PayloadAction<ConditionSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
    },

    errorWhileSearching(state, action: PayloadAction<ConditionSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
    },
    resetState(state, action: PayloadAction<ConditionSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.surgeryList = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: ConditionSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      surgeryList: undefined,
    }
    dispatch(conditionSearchSlice.actions.resetState(state))
  }

export const searchConditions =
  (
    searchString: string,
    selectOther: boolean,
    preSelectedComplaints?: UmlResponse[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: ConditionSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      conditionSearchSlice.actions.errorWhileSearching(errorSearchPatient)
    )
    try {
      const fhirClient: UmlClient = new UmlClient()
      const currentRole = getUserCurrentRole() ?? []
      let response: any = await fhirClient.doGetResource(
        `umls/Search?Path=conditions&Match=${searchString}`
      )
      const surData: UmlResponse[] = []
      if (response.length > 0) {
        if (preSelectedComplaints && preSelectedComplaints.length > 0) {
          for (let i = 0; i < preSelectedComplaints.length; i++) {
            for (let j = 0; j < response.length; j++) {
              if (preSelectedComplaints[i].cui !== response[j].snomed_ct) {
                surData.push(response[j])
              }
            }
          }

          const searchPatientResult: ConditionSearchStatus = {
            error: false,
            noResultsAvailable: false,
            resultsAvailable: true,
            searching: false,
            surgeryList: surData,
            totalCount: surData.length,
          }
          dispatch(
            conditionSearchSlice.actions.searchResults(searchPatientResult)
          )
          return
        }
        const searchPatientResultCount: ConditionSearchStatus = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          surgeryList: response,
          totalCount: response.total,
        }
        dispatch(
          conditionSearchSlice.actions.searchResults(searchPatientResultCount)
        )
        return
      }

      if (response.length === 0 && currentRole.includes('ayurveda')) {
        response = await fhirClient.doGetResource(
          `/umls/AyurvedaMatcherCondition?Path=conditions&Match=${searchString}`
        )

        const searchPatientResultDatanew: ConditionSearchStatus = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          surgeryList: response,
          totalCount: response.total,
        }
        dispatch(
          conditionSearchSlice.actions.searchResults(searchPatientResultDatanew)
        )
        return
      }

      const noSearchResults: ConditionSearchStatus = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(
        conditionSearchSlice.actions.noDataFoundForSearch(noSearchResults)
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: ConditionSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        conditionSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(conditionSearchSlice.actions.resetState(initialState))
}

export default conditionSearchSlice.reducer
