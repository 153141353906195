import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { searchPractitionersDetailsWithUnit } from 'redux/welloAdmin/UnitUserDetails/unitPractitionerDetails'
import SimpleBar from 'simplebar-react'
import { OrgUserDataTable } from './orgUsersDataTable'

interface Props {
  id: string
  name: string
  open: boolean
  role: string
  roleNameData: string
  onClose: () => void
  mainOrgIds?: string[]
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogCustomizedWidth: {
    'max-width': '75%',
  },
}))
export const UnitUserDetails: React.FC<Props> = ({
  id,
  name,
  open,
  onClose,
  roleNameData,
  role,
  mainOrgIds,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const unitPractitionerDetailsSlice = useSelector(
    (state: RootState) => state.unitPractitionerDetailsSlice
  )
  const classes = useStyles()
  function resetForm() {}

  useEffect(() => {
    if (role.length > 0) {
      dispatch(searchPractitionersDetailsWithUnit(id, role, mainOrgIds))
    }
  }, [dispatch, id, role])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetForm()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>{`${roleNameData} Details of ${name}`}</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
                resetForm()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
          padding: 0,
        }}
      >
        <Box display='flex' flexGrow={1} flexDirection='column' width='100%'>
          {unitPractitionerDetailsSlice.searching && (
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
            >
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            </Box>
          )}
          {unitPractitionerDetailsSlice.error && (
            <Box
              //   display='flex'
              //   flexDirection='column'
              justifyContent='center'
              width='100%'
              height='100%'
              alignContent='center'
              paddingLeft={50}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Grid container justifyContent='center'>
                    <Typography
                      variant='subtitle1'
                      color='error'
                      align='center'
                    >
                      Error while searching. Please try again
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}

          {unitPractitionerDetailsSlice.resultsAvailable &&
            unitPractitionerDetailsSlice.doctorRoleList && (
              <SimpleBar
                style={{
                  height: '100%',
                  width: '100%',
                }}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height='100%'
                  paddingBottom={2}
                >
                  <OrgUserDataTable
                    usersData={
                      unitPractitionerDetailsSlice.doctorRoleList ?? []
                    }
                  />
                  {/* <OrgLIst /> */}
                  {/* {unitPractitionerDetailsSlice.doctorRoleList!.map(
                      (e, index) => (
                        <Box display='flex' flexDirection='column' key={e.id}>
                          <UserList docwithRole={e} isOdd={index % 2 === 0} />
                        </Box>
                      )
                    )} */}
                </Box>
              </SimpleBar>
            )}
        </Box>
      </DialogContent>
      {/* <DialogActions>
        <Box paddingRight={2}>
          <Button
            onClick={() => {
              onClose()
            }}
            variant='outlined'
            disableElevation
          >
            Ok
          </Button>
        </Box>
      </DialogActions> */}
    </Dialog>
  )
}
