import { R4 } from '@ahryman40k/ts-fhir-types'

export const VisionObservationCodes: R4.ICoding[] = [
  {
    system: 'http://snomed.info/sct',
    code: '45089002',
    display: 'Normal',
  },
  {
    system: 'http://snomed.info/sct',
    code: '7973008',
    display: 'Glasses',
  },
  {
    system: 'http://snomed.info/sct',
    code: '397540003',
    display: 'Impaired',
  },
  {
    system: 'http://snomed.info/sct',
    code: '24982008',
    display: 'Diplopia',
  },
  {
    system: 'http://snomed.info/sct',
    code: '77674003',
    display: 'Hemianopia',
  },
  {
    system: 'http://snomed.info/sct',
    code: '34063005',
    display: 'Homonymous Hemianopia',
  },
  {
    system: 'http://snomed.info/sct',
    code: '563001',
    display: 'Nystagmus',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-vision-finding-cs',
    code: 'other-vision-finding',
    display: 'Other',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
