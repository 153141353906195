import { R4 } from '@ahryman40k/ts-fhir-types'
import { PreAppointmentDetails } from 'models/preAppointmentDetails'
import { getNameOfPatient } from 'utils/fhirResourcesHelper'

export function getExpandedPreAppointmentDetailsFromResponse(
  response: any
): any {
  const convertedAppointments: PreAppointmentDetails[] = []
  let recordId: string = ''
  let name: string = ''
  let phone: string = ''
  let checkInDate: any = {}

  if (response.total) {
    if (response.total > 0) {
      response.map((e: any) => {
        recordId = e.id
        checkInDate = e.checkInDate
        name = getNameOfPatient(e.patient)
        phone = e.phone

        convertedAppointments.push({
          patientId: recordId,
          patientName: name,
          patientPhone: phone,
          patientCheckInDate: checkInDate,
          patientResource: e.patient,
          isSelected: false,
        })

        return convertedAppointments
      })
    }
  }
}

export function getFhirPatientResourceFromPreAppointmentDetails(
  response: any
): any {
  return response.patientResource
}

export function getPatientFromPreAppointment(
  preAppointmentDetails: PreAppointmentDetails
): R4.IPatient | undefined {
  return preAppointmentDetails.patientResource
}
