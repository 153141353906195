/* eslint-disable react/display-name */
import MaterialTable, { MTableToolbar } from '@material-table/core'
import { ExportCsv, ExportPdf } from '@material-table/exporters'

import {
  Box,
  Button,
  makeStyles,
  Paper,
  TablePagination,
  Typography,
  useTheme,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { TableData } from 'models/adminGraph/tableData'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getInvoiceDetailsInPdf } from 'utils/fhirResoureHelpers/invoice_helper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { AddPartnerLabHandler } from 'views/components/administration/actors/add_partner_lab'

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      padding: 4,
    },
  },
})

interface ICardProperties {
  usersData: TableData[]
  onUserSelected?: (selectedDetail: PractitionerWithRole) => void
}

export const InvoiceDataDisplay: React.FC<ICardProperties> = ({
  usersData,
  onUserSelected,
}: ICardProperties) => {
  const classes = useStyles()
  const convertedData = usersData
  const { height, width } = useWindowDimensions()
  const [generatingStatus, setGeneratingStatus] =
    useState<NetworkRequestStatus>({
      initial: true,
    })
  const [selectedRowId, setSelectedRowId] = React.useState('')
  const [selectedRow, setSelectedRow] = React.useState(false)
  const [openPartnerLabPopup, setOpenPartnerLabPopup] = useState(false)
  const { t } = useTranslation()

  function handleInvoiceGenerationButton(invoiceId: string) {
    setGeneratingStatus({
      requesting: true,
    })

    getInvoiceDetailsInPdf(invoiceId).then((e) => {
      if (e) {
        setGeneratingStatus({
          requesting: false,
          requestSuccess: true,
        })
      } else {
        setGeneratingStatus({
          requesting: false,
          requestError: true,
        })
      }
    })
  }

  const handleDarkModeChange = () => {
    // setPreferDarkMode(!preferDarkMode)
    // localStorage.setItem('_tableDarkMode', !preferDarkMode)
  }

  const columnsData = [
    {
      title: 'Invoice No.',
      field: 'invoiceNo',
      // `name` field is hidden in table
      // but not in Columns Button
      hidden: true,
    },
    {
      title: 'IPD/OPD No.',
      field: 'ipdOpdNo',
    },
    {
      title: 'Patient Name',
      field: 'patientName',
    },
    {
      title: 'Billing Date',
      field: 'billingDate',
    },
    {
      title: 'Billing Amount',
      field: 'billAmount',
    },
  ]

  const columns = [
    {
      title: (
        <Box minWidth='100px' minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
          >
            Invoice No.
          </Typography>
        </Box>
      ),
      field: 'invoiceNo',
      customSort: (a: any, b: any) =>
        a.invoiceNo > b.invoiceNo ? 1 : b.invoiceNo > a.invoiceNo ? -1 : 0,
      hiddenByColumnsButton: false,
      cellStyle: {
        width: 10,
        maxWidth: 175,
      },

      render: (rowData: any) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <Typography variant='subtitle2' noWrap>
            {rowData.invoiceNo}
          </Typography>
        </Box>
      ),
    },

    {
      title: (
        <Box>
          <Typography variant='subtitle1' color='primary' noWrap>
            IPD/OPD No.
          </Typography>
        </Box>
      ),
      field: 'ipdOpdNo',
      customSort: (a: any, b: any) =>
        a.ipdOpdNo > b.ipdOpdNo ? 1 : b.ipdOpdNo > a.ipdOpdNo ? -1 : 0,
      cellStyle: {
        width: 150,
        maxWidth: 175,
      },
      render: (rowData: any) => (
        <Box>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            {rowData.ipdOpdNo}
          </Typography>
        </Box>
      ),
    },

    {
      title: (
        <Box minWidth='150px'>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
            noWrap
          >
            Patient Name
          </Typography>
        </Box>
      ),

      field: 'patientName',
      customSort: (a: any, b: any) =>
        a.patientName.localeCompare(b.patientName, 'en', {
          sensitivity: 'base',
        }),
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Typography variant='subtitle2' color='textPrimary'>
            {rowData.patientName.replace('\n', '')}
          </Typography>
        </Box>
      ),
    },
    {
      title: (
        <Box minWidth='150px' minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
          >
            &nbsp;Billing Date
          </Typography>
        </Box>
      ),

      field: 'billDateDateType',
      //   customSort: (a: any, b: any) =>
      //   a.patientName.localeCompare(b.patientName, 'en', {
      //     sensitivity: 'base',
      //   }),
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Box paddingLeft={0.5} display='flex' alignItems='center'>
            <Typography variant='subtitle2' color='textPrimary' noWrap>
              {moment(rowData.billDateDateType).format('DD-MM-YYYY')}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
            noWrap
          >
            Bill Amount
          </Typography>
        </Box>
      ),

      field: 'billAmount',
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            &#x20b9; {rowData.billAmount}
          </Typography>
        </Box>
      ),
    },
  ]

  return (
    <Box style={{ overflow: 'none', width: `${width}px` }} paddingLeft={1.5}>
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                minHeight: '0px',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
          Container: (props) => <Paper {...props} elevation={0} />,
          Pagination: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                display: 'flex',
                justifyContent: 'left',
                // padding: '0px',
              }}
            >
              <TablePagination
                {...props}
                labelRowsSelect={<div style={{ fontSize: 14 }}> rows</div>}
                SelectProps={{
                  style: {
                    fontSize: 14,
                    minHeight: '5px',
                    backgroundColor: useTheme().palette.background.default,
                  },
                }}
              />
            </div>
          ),
        }}
        actions={[
          {
            icon: () => (
              <Button variant='contained' color='primary'>
                Download
              </Button>
            ),
            onClick: (event, rowData: any) => {
              handleInvoiceGenerationButton(rowData.invoiceId)
            },
          },
        ]}
        columns={columns}
        data={convertedData}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        options={{
          searchFieldStyle: { padding: 4 },

          exportMenu: [
            {
              label: 'Export PDF',
              exportFunc: (cols, datas) =>
                ExportPdf(
                  columnsData,
                  datas,
                  'HealthVille Patient Invoice Report'
                ),
            },
            {
              label: 'Export CSV',
              exportFunc: (cols, datas) =>
                ExportCsv(
                  columnsData,
                  datas,
                  'HealthVille Patient Invoice Report'
                ),
            },
          ],
          search: false,
          showTitle: false,
          padding: 'dense',
          searchFieldVariant: 'outlined',
          filtering: false,
          pageSize: 20,
          pageSizeOptions: [20, 30, 40, 50, 100],
          //   columnsButton: true,
          maxBodyHeight: `180px`,
          minBodyHeight: `180px`,
          tableLayout: 'auto',
          keepSortDirectionOnColumnSwitch: true,
          sorting: true,
          thirdSortClick: false,
          headerStyle: {
            backgroundColor: '#FFF',
            color: '#FFF',
            width: 100,
            // minHeight: '15px',
            // maxHeight: '15px',
            position: 'sticky',
            top: 0,
            // height: 20,
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          rowStyle: (_data: any, index: number, _level: number) =>
            index % 2
              ? selectedRow && _data.id === selectedRowId
                ? { backgroundColor: '#9E9DDC', height: 50 }
                : { backgroundColor: kDialogueBackground }
              : selectedRow && _data.id === selectedRowId
              ? { backgroundColor: '#9E9DDC', height: 50 }
              : {},
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: 'Actions',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />
    </Box>
  )
}
