import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OIDCUser } from 'models/oidcUser'
import { AppDispatch, AppThunk } from 'redux/store'
import { SpecialtiesApiClient } from 'services/specialtiesApiService'
import { getOIDCUserObject } from 'utils/authHelpers'
import { logger } from 'utils/logger'
import { SpecialtiesSearchStatus as SpecialtySearchStatus } from './specialtiesSearchStatusTypes'

const initialState: SpecialtySearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const specialtiesSearchSlice = createSlice({
  name: 'specialtySearch',
  initialState,
  reducers: {
    searchingSpecialty(state, action: PayloadAction<SpecialtySearchStatus>) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.specializationList = undefined
    },

    searchResults(state, action: PayloadAction<SpecialtySearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.specializationList = action.payload.specializationList
    },

    noDataFoundForSearch(state, action: PayloadAction<SpecialtySearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.specializationList = undefined
    },

    errorWhileSearching(state, action: PayloadAction<SpecialtySearchStatus>) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.specializationList = undefined
    },
  },
})

export const searchSpecialties =
  (name: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: SpecialtySearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(specialtiesSearchSlice.actions.searchingSpecialty(state))
    try {
      const oidcUser: OIDCUser | null = getOIDCUserObject()
      const apiService: SpecialtiesApiClient = new SpecialtiesApiClient()
      if (oidcUser != null) {
        const response: R4.ICoding[] | undefined =
          await apiService.doGetSpecialties()

        if (response) {
          if (response.length > 0) {
            state.specializationList = response
            dispatch(specialtiesSearchSlice.actions.searchResults(state))
          } else {
            const errorSearchDoctor: SpecialtySearchStatus = {
              error: false,
              noResultsAvailable: true,
              resultsAvailable: false,
              searching: false,
            }
            dispatch(
              specialtiesSearchSlice.actions.noDataFoundForSearch(
                errorSearchDoctor
              )
            )
          }
        } else {
          dispatch(specialtiesSearchSlice.actions.noDataFoundForSearch(state))
        }
      } else {
        // TODO Log out user
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: SpecialtySearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        specialtiesSearchSlice.actions.errorWhileSearching(errorSearchDoctor)
      )
    }
  }

export default specialtiesSearchSlice.reducer
