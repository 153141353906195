import {
  Box,
  Icon,
  IconButton,
  Link,
  makeStyles,
  Typography,
  Tooltip,
} from '@material-ui/core'
import { CarePlan } from 'models/carePlan'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { isOrgAdmin } from 'services/userDetailsService'
import cancelIcon from '../../../../assets/icons/insert_chart.svg'

import { ShowGraph } from './graph'
import { PatientDefinedGraph } from './PatientDefinedGraph'
import { RefinedGraph } from './refinedGraph'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#007AFF',
  },
}))

interface EbmButtonProps {
  patientId: string
}

export const PatientEvidenceResult: React.FC<EbmButtonProps> = ({
  patientId,
}: EbmButtonProps) => {
  const [show, setShow] = useState<boolean>(false)
  const plans: CarePlan[] = [
    {
      planName: 'Hypertension Care Plan',
      planDescription: '',
    },
  ]

  const [value, setValue] = useState('eviDenceBase')
  const classes = useStyles()
  const dispatch = useDispatch()

  return (
    <ReactVisibilitySensor
      onChange={(isVisible) => {
        if (isVisible) {
          dispatch(setSelectedSection({ selectedSection: 'next_steps' }))
        }
      }}
    >
      <Box paddingTop={0.5}>
        <Box
          onClick={() => {
            setShow(true)
          }}
        >
          <Tooltip title='Evidence Based Analysis'>
            <IconButton
              aria-label='btn_ord_cancel'
              color='primary'
              style={{ padding: 0 }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/Evidence_graph.ico`}
                alt='delete'
                height={30}
              />

              {/* <Icon color='primary'>
                  <img src={cancelIcon} alt='ebm_icon' />
                </Icon> */}
            </IconButton>
          </Tooltip>
        </Box>

        <PatientDefinedGraph
          open={show}
          patientId={patientId}
          onClose={() => {
            setShow(false)
          }}
        />
      </Box>
    </ReactVisibilitySensor>
  )
}
