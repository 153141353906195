import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  ContactPointUseKind,
  HumanNameUseKind,
  IdentifierUseKind,
  PatientGenderKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { ErrorResponse } from 'lib/openApi'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { PatientProfile } from 'models/patientProfile'
import moment from 'moment'
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { uploadFileDetails } from 'services/fileApiService'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitDetails,
} from 'services/userDetailsService'
import {
  getPatientProfile,
  getPatientProfileFinal,
} from 'utils/common/patientDataConverter'
import { getProfileData } from 'utils/common/patientDataTableHelper'
import { nationalityValueSet } from 'utils/constants'
import { AddressProofType } from 'utils/constants/proof_types'
import { sleep } from 'utils/dateUtil'
import {
  getIdentifierValueBySystemType,
  getIdentifierValueBySystemTypeDAata,
  getIdentifierValueBySystemTypeDAataVal,
  getTelecomOfPatient,
  getValueAttachmentFromExtension,
  getValueAttachmentFromExtensionData,
} from 'utils/fhirResourcesHelper'
import { getTaskObjectForConsumerInvitation } from 'utils/fhirResoureHelpers/invitationHelpers'
import { getFhirCodIngFromStringCode } from 'utils/formHelper'
import { logger } from 'utils/logger'
import { SelectedFile } from 'views/components/LeftMenu/WelloFilePicker'
import { getEmailOfPatient } from 'wello-web-components'
import { updatePatientCount } from '../patientCount/patientCountSlice'
import { searchPatients } from '../patientSearch/patientSearchSlice'
import { PatientAddStatus } from './patientAddStatusTypes'

const initialState: PatientAddStatus = {
  fetchingPatientDetails: false,
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
  updating: false,
  updated: false,
  resultsAvailable: false,
}

const patientSlice = createSlice({
  name: 'addPatient',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<PatientAddStatus>) {
      state.fetchingPatientDetails = action.payload.fetchingPatientDetails
      state.resultsAvailable = action.payload.resultsAvailable
      state.error = action.payload.error
      state.patientProfile = action.payload.patientProfile
      state.patientId = action.payload.patientId
    },
    addingPatientDetails(state, action: PayloadAction<PatientAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
    },

    updatingPatientDetails(state, action: PayloadAction<PatientAddStatus>) {
      state.updating = action.payload.updating
      state.updated = action.payload.updated
      state.error = action.payload.error
      state.patient = action.payload.patient
    },

    patientDetailsAdded(state, action: PayloadAction<PatientAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.patient = action.payload.patient
      state.patientId = action.payload.patientId
    },

    patientDetailsUpdated(state, action: PayloadAction<PatientAddStatus>) {
      state.updating = action.payload.updating
      state.updated = action.payload.updated
      state.error = action.payload.error
      state.patient = action.payload.patient
      state.version = action.payload.version
    },

    errorInAddingPatientDetails(
      state,
      action: PayloadAction<PatientAddStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetPatientDetails(state, action: PayloadAction<PatientAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.patient = action.payload.patient
    },
  },
})

export const fetchPatientDetails =
  (patientId: String, status: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PatientAddStatus = { ...initialState }
    state.fetchingPatientDetails = true
    dispatch(patientSlice.actions.updatedStatus(state))

    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const response: any = await fhirClient.doGetResource(
        `/Patient/${patientId}`
      )

      const resp: E.Either<Errors, R4.IPatient> =
        R4.RTTI_Patient.decode(response)
      if (response === undefined) {
        const currentState = { ...initialState }
        currentState.error = true
        dispatch(patientSlice.actions.updatedStatus(currentState))
      } else {
        const patientResponse: R4.IPatient = response as R4.IPatient
        const statusData = await getTaskStatus(patientResponse)
        let patProfile = getPatientProfile(patientResponse, statusData)
        patProfile = await getRelatives(patProfile)
        const date = await getPatientCreatedDate(patProfile.id)
        patProfile = getPatientProfileFinal(patProfile, date)
        const currentState = { ...initialState }
        currentState.resultsAvailable = true
        currentState.patientProfile = patProfile
        dispatch(patientSlice.actions.updatedStatus(currentState))
      } /* */
    } catch (error) {
      logger.error(error)

      const currentState = { ...initialState }
      currentState.error = true
      currentState.errorMessage = 'Error while fetching orders'

      dispatch(patientSlice.actions.updatedStatus(currentState))
    }
  }

export const addPatientDetails =
  (
    patientDetails: R4.IPatient,
    relatedPersonDetails?: R4.IRelatedPerson,
    relatedPersonHS?: R4.IRelatedPerson,
    nationality?: string,
    occupation?: string,
    addressProofBoth?: R4.IAttachment,
    addressProofFront?: R4.IAttachment,
    addressProofBack?: R4.IAttachment,
    referred?: string,
    relationship?: R4.ICoding,
    isPrimary?: boolean,
    passportStartDate?: string,
    expiryDate?: string,
    passportIssueCity?: string,
    passportIssueCountry?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: PatientAddStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
      updating: false,
      updated: false,
      fetchingPatientDetails: false,
      resultsAvailable: false,
    }
    dispatch(patientSlice.actions.addingPatientDetails(addingCreatePersonState))

    try {
      const practRole: R4.IPractitionerRole =
        getCurrentUserPractitionerRoleDetails()
      let addressFrontImageData: any
      let adressBackImageData: any

      patientDetails.extension = patientDetails.extension ?? []

      if (nationality) {
        const filteredData = nationalityValueSet.filter(
          (d) => d.code!.trim() === nationality.trim()!
        )
        const nationalityExt: R4.IExtension = {}
        nationalityExt.url =
          'http://hl7.org/fhir/StructureDefinition/patient-nationality'
        nationalityExt.extension = [
          {
            url: 'http://wellopathy.com/fhir/india/core/CodeSystem/country',
            valueCodeableConcept: {
              text:
                nationality === 'IN' ? 'india' : filteredData[0].display ?? '',
              coding: filteredData.length > 0 ? filteredData : [],
            },
          },
        ]
        patientDetails.extension.push(nationalityExt)
        if (nationality !== 'IN') {
          if (passportStartDate && expiryDate) {
            const passportExtStart: R4.IExtension = {}

            passportExtStart.url =
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
            passportExtStart.valuePeriod = {
              start: moment(passportStartDate).toISOString(),
              end: moment(expiryDate).toISOString(),
            }

            patientDetails.extension.push(passportExtStart)
          }

          if (passportIssueCity && passportIssueCountry) {
            const passportExtCountry: R4.IExtension = {}
            const filteredDataForCountry = nationalityValueSet.filter(
              (d) => d.display! === passportIssueCountry!
            )
            passportExtCountry.url =
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-issued-country-ext'
            passportExtCountry.valueCodeableConcept = {
              text: passportIssueCity,
              coding:
                filteredDataForCountry.length > 0 ? filteredDataForCountry : [],
            }

            patientDetails.extension.push(passportExtCountry)
          }

          //   if (expiryDate) {
          //     const passportExtExpire: R4.IExtension = {}
          //     passportExtExpire.url =
          //       'http://wellopathy.com/fhir/india/core/CodeSystem/passport-end'
          //     passportExtExpire.valueString = moment(expiryDate).toISOString()
          //     patientDetails.extension.push(passportExtExpire)
          //   }
        }
      }

      if (occupation) {
        const occupationExt: R4.IExtension = {}
        occupationExt.url =
          'http://wellopathy.com/fhir/india/core/CodeSystem/patient-occupation'
        occupationExt.extension = [
          {
            url: 'http://wellopathy.com/fhir/india/core/CodeSystem/occupation',
            valueCodeableConcept: {
              text: occupation,
            },
          },
        ]
        patientDetails.extension.push(occupationExt)
      }

      if (addressProofFront) {
        const addressProofFrontExt: R4.IExtension = {}
        addressProofFrontExt.url =
          'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front'
        addressProofFrontExt.valueAttachment = {
          contentType: addressProofFront.contentType,
          title: addressProofFront.title,
        }
        patientDetails.extension.push(addressProofFrontExt)
      }

      if (addressProofBoth) {
        const addressProofBothExt: R4.IExtension = {}

        addressProofBothExt.url =
          'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
        addressProofBothExt.valueAttachment = {
          contentType: addressProofBoth.contentType,
          title: addressProofBoth.title,
        }
        patientDetails.extension.push(addressProofBothExt)
      }

      if (addressProofBack) {
        const addressProofFrontExt: R4.IExtension = {}
        addressProofFrontExt.url =
          'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-back'
        addressProofFrontExt.valueAttachment = {
          contentType: addressProofBack.contentType,
          title: addressProofBack.title,
        }
        patientDetails.extension.push(addressProofFrontExt)
      }

      if (occupation) {
        const occupationExt: R4.IExtension = {}
        occupationExt.url =
          'http://wellopathy.com/fhir/india/core/CodeSystem/patient-occupation'
        occupationExt.extension = [
          {
            url: 'http://wellopathy.com/fhir/india/core/CodeSystem/occupation',
            valueCodeableConcept: {
              text: occupation,
            },
          },
        ]
        patientDetails.extension.push(occupationExt)
      }

      const unitOrg = getCurrentUserUnitDetails()
      const unitExtension: R4.IExtension = {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/ManagingOrganizationUnit',
        valueReference: {
          id: unitOrg.id,
          reference: `Organization/${unitOrg.id}`,
          display: unitOrg.name,
        },
      }

      patientDetails.extension.push(unitExtension)

      const mainOrganization: R4.IOrganization =
        getCurrentUserMainOrganizationDetails()

      patientDetails.managingOrganization = {
        id: mainOrganization.id ?? '',
        display: mainOrganization.name,
        reference: `${mainOrganization.resourceType}/${mainOrganization.id}`,
      }

      logger.info('patientDetails')

      logger.info(patientDetails)

      const patientBundle: R4.IBundle = getPatientBundle(
        patientDetails,
        relatedPersonDetails,
        relatedPersonHS
      )

      const resource = {
        patient: patientBundle,
        practitionerRoleId: practRole.id ?? '',
        relatedPatientId: referred || '',
        isShared: !!referred,
        primaryPatientId:
          isPrimary && isPrimary === true ? referred || '' : null,
        relation: relationship ? [relationship] : [],
      }

      //   const fhirClient: FHIRApiClient = new FHIRApiClient()
      const enRolClient: EnrolCient = new EnrolCient()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        '/enrolment/create-patient',
        resource
      )
      /* const response: any = {} */
      console.log(response)
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const orderResponse: R4.IBundle = relatedFhirDecodeRes.right
        let patientId: string = ''
        dispatch(showSuccessAlert('Patient Added Successfully'))

        if (orderResponse.entry) {
          for (let i = 0; i < orderResponse.entry.length; i++) {
            const entryResponse = orderResponse.entry[i].response
            if (entryResponse) {
              if (entryResponse.location) {
                if (entryResponse.location.includes('Patient')) {
                  patientId = entryResponse.location.replace('/_history/1', '')
                }
              }
            }
          }
        }

        await sleep(2000)
        const patientFetched: boolean = await getActualPatient(
          patientId.split('/')[1]
        )
        if (patientFetched === true) {
          try {
            if (addressProofFront) {
              await uploadFileDetails(
                addressProofFront,
                "Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front').value.as(Attachment)",
                'Patient',
                patientId.split('/')[1]
              )
            }

            if (addressProofBoth) {
              await uploadFileDetails(
                addressProofBoth,
                "Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both').value.as(Attachment)",
                'Patient',
                patientId.split('/')[1]
              )
            }
            if (addressProofBack) {
              await uploadFileDetails(
                addressProofBack,
                "Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-back').value.as(Attachment)",
                'Patient',
                patientId.split('/')[1]
              )
            }
          } catch (error) {
            logger.info(error)
            dispatch(showErrorAlert('Error in uploading proofs'))
          }
        }

        const successCreatePersonState: PatientAddStatus = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
          patientId,
          updating: false,
          updated: false,
          patient: patientDetails,
          fetchingPatientDetails: false,
          resultsAvailable: false,
        }
        dispatch(
          patientSlice.actions.patientDetailsAdded(successCreatePersonState)
        )
        dispatch(updatePatientCount())
        return
      }
      const errorCreatePersonState: PatientAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Something went wrong, Please try again later',
        updating: false,
        updated: false,
        fetchingPatientDetails: false,
        resultsAvailable: false,
      }
      dispatch(
        patientSlice.actions.errorInAddingPatientDetails(errorCreatePersonState)
      )
      return
    } catch (error) {
      const err = error as AxiosError
      console.log(err)
      const errorMsg = 'Something went wrong, Please try again later'

      //   if (err.response?.data.statusCode === 500) {
      //     errorMsg = 'Something went wrong, Please try again later'
      //   } else if (err.response?.data.statusCode === 413) {
      //     errorMsg =
      //       'Captured profile photo is too large. Please reduce the crop size and try again'
      //   } else if (err.response?.data.statusCode === 400) {
      //     errorMsg = 'Something went wrong, Please try again later'
      //   } else {
      //     errorMsg = err.response?.data.error
      //   }
      //   if (errorMsg.length === 0) {
      //     errorMsg = 'Something went wrong, Please try again later'
      //   }

      const errorCreatePersonState: PatientAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: errorMsg,
        updating: false,
        updated: false,
        fetchingPatientDetails: false,
        resultsAvailable: false,
      }
      dispatch(
        patientSlice.actions.errorInAddingPatientDetails(errorCreatePersonState)
      )
    }
  }

export const updatePatient =
  (
    oldPatient: R4.IPatient,
    newPatientDetails: PatientProfile,
    oldFatherData?: R4.IRelatedPerson,
    oldHusbandData?: R4.IRelatedPerson
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: PatientAddStatus = {
      adding: false,
      additionSuccessful: false,
      error: false,
      updating: true,
      updated: false,
      fetchingPatientDetails: false,
      resultsAvailable: false,
    }
    dispatch(
      patientSlice.actions.updatingPatientDetails(addingCreatePersonState)
    )

    try {
      const transObj: R4.IBundle = getUpdatedBundleObjet(
        oldPatient,
        newPatientDetails,
        oldFatherData,
        oldHusbandData
      )

      logger.info('patientDetails')

      logger.info(oldPatient)
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doCreateFHIRTransaction(
        '',
        transObj
      )
      const respDecoded: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      //   let etagId: string = ''
      if (respDecoded._tag === 'Right') {
        const orderResponse: R4.IBundle = respDecoded.right
        if (orderResponse.entry) {
          for (let i = 0; i < orderResponse.entry.length; i++) {
            const entryResponse = orderResponse.entry[i].response
            if (entryResponse) {
              if (entryResponse.location) {
                if (entryResponse.location.includes('Patient')) {
                  sessionStorage.setItem('version', entryResponse.etag ?? '')
                }
              }
            }
          }
        }
        logger.info('Response Patient un decoded', respDecoded.right)
        const successCreatePersonState: PatientAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: false,
          errorMessage: '',
          updating: false,
          updated: true,
          fetchingPatientDetails: false,
          resultsAvailable: false,
        }
        dispatch(
          patientSlice.actions.patientDetailsUpdated(successCreatePersonState)
        )
        dispatch(showSuccessAlert('Patient Edited Successfully'))
        // dispatch(fetchPatientDetails(oldPatient.id ?? '', true))
        dispatch(searchPatients('', ''))

        return
      }

      const errorCreatePersonState: PatientAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while creating patient',
        updating: false,
        updated: false,
        fetchingPatientDetails: false,
        resultsAvailable: false,
      }
      dispatch(
        patientSlice.actions.patientDetailsUpdated(errorCreatePersonState)
      )
      return
    } catch (error) {
      const errorCreatePersonState: PatientAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error',
        updating: false,
        updated: false,
        fetchingPatientDetails: false,
        resultsAvailable: false,
      }
      dispatch(
        patientSlice.actions.errorInAddingPatientDetails(errorCreatePersonState)
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(patientSlice.actions.resetPatientDetails(initialState))
}

async function getRelatives(
  patientData: PatientProfile
): Promise<PatientProfile> {
  let patDetails: PatientProfile = patientData
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirApi.doGetResource(
    `/RelatedPerson?patient=${patDetails.id}`
  )
  logger.info('Related Person Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const orgResponse: R4.IBundle = relatedFhirDecodeRes.right
    if (orgResponse.total) {
      if (orgResponse.total > 0) {
        if (orgResponse.entry) {
          patDetails = getProfileData(orgResponse, patDetails)
        }
      }
    }
  }
  return patDetails
}

async function getTaskStatus(patientData: R4.IPatient): Promise<boolean> {
  const checkStatus: string[] = []
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirApi.doGetResource(
    `/Task?patient=Patient/${patientData.id}&code=user-invitation`
  )
  logger.info('Related Person Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const orgResponse: R4.IBundle = relatedFhirDecodeRes.right

    if (orgResponse.entry) {
      const taskData: R4.ITask[] =
        orgResponse.entry?.map((item) => item.resource as R4.ITask) ?? []
      if (taskData.length > 0) {
        for (let i = 0; i < taskData.length; i++) {
          if (taskData[i].status && taskData[i].status === 'completed') {
            checkStatus.push('1')
          }
        }
      }
    }
  }
  return checkStatus.length > 0
}

export async function getPatientCreatedDate(
  patientId: string
): Promise<string> {
  let date: string = ''
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirApi.doGetResource(
    `/Patient/${patientId}/_history/1`
  )
  logger.info('Related Person Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IPatient> =
    R4.RTTI_Patient.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const patResponse: R4.IPatient = relatedFhirDecodeRes.right
    if (patResponse) {
      if (patResponse.meta && patResponse.meta.lastUpdated) {
        date = moment(patResponse.meta.lastUpdated).format('DD-MM-YYYY')
      }
    }
  }
  return date
}

export async function getActualPatient(patientId: string): Promise<boolean> {
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirApi.doGetResource(`/Patient/${patientId}`)
  logger.info('Related Person Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IPatient> =
    R4.RTTI_Patient.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const patResponse: R4.IPatient = relatedFhirDecodeRes.right
    if (patResponse) {
      if (patResponse.meta && patResponse.meta.lastUpdated) {
        return true
      }
    }
  }
  return false
}

function getPatientBundle(
  patientDetails: R4.IPatient,
  relatedPersonDetails?: R4.IRelatedPerson,
  relatedPersonHS?: R4.IRelatedPerson
): R4.IBundle {
  const seqNo = (Math.floor(Math.random() * 10000) + 10000)
    .toString()
    .substring(1)
  const bundleObject: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `urn:uuid:patient${seqNo}`,
        id: `urn:uuid:patient${seqNo}`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: `Patient`,
        },
        resource: patientDetails,
      },
    ],
  }

  if (relatedPersonDetails) {
    const relatedPersonDetailsData: R4.IRelatedPerson = {
      ...relatedPersonDetails,
    }
    relatedPersonDetailsData.patient = {
      reference: `urn:uuid:patient${seqNo}`,
    }
    bundleObject.entry?.push({
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: `RelatedPerson`,
      },
      resource: relatedPersonDetailsData,
    })
  }

  if (relatedPersonHS) {
    const relatedPersonDetailHsData: R4.IRelatedPerson = {
      ...relatedPersonHS,
    }
    relatedPersonDetailHsData.patient = {
      reference: `urn:uuid:patient${seqNo}`,
    }
    bundleObject.entry?.push({
      fullUrl: `RelatedPerson`,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: `RelatedPerson`,
      },
      resource: relatedPersonDetailHsData,
    })
  }

  return bundleObject
}

async function sendConsumerInvitationForApp(
  patientDetails: R4.IPatient,
  patientId: string
): Promise<boolean> {
  const requestBody: R4.IBundle | undefined = getTransactionObject(
    patientDetails,
    patientId
  )
  if (requestBody) {
    logger.info('Patient invitation body')
    logger.info(requestBody)
    const fhirApi: FHIRApiClient = new FHIRApiClient()
    const response: any | FHIRErrorResponses =
      await fhirApi.doCreateFHIRTransaction('', requestBody)
    logger.info('Patient invitation Response')
    logger.info(response)
    const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
      R4.RTTI_Bundle.decode(response)
    if (relatedFhirDecodeRes._tag === 'Right') {
      return true
    }
  }

  return false
}

function getTransactionObject(
  patientDetails: R4.IPatient,
  patientId: string
): R4.IBundle | undefined {
  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const entries: R4.IBundle_Entry[] = []

  const taskObject: R4.ITask[] = getTaskObjectForConsumerInvitation(
    getEmailOfPatient(patientDetails) ?? '',
    getTelecomOfPatient(patientDetails, R4.ContactPointSystemKind._phone) ?? '',
    {
      type: 'PractitionerRole',
      reference: `${'PractitionerRole/'}${practRole.id ?? ''}`,
    },
    patientId,
    false
  )

  taskObject.forEach((task) => {
    const taskEntry: R4.IBundle_Entry = {
      request: {
        url: task.resourceType,
        method: R4.Bundle_RequestMethodKind._post,
      },
      resource: task,
    }
    entries.push(taskEntry)
  })

  const bundleObject: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: entries,
  }
  return bundleObject
}

function getUpdatedBundleObjet(
  patientDetails: R4.IPatient,
  patientProfile: PatientProfile,
  oldFatherData?: R4.IRelatedPerson,
  oldHusbandData?: R4.IRelatedPerson
): R4.IBundle {
  const oldPatient: R4.IPatient = {
    ...patientDetails,
  }
  const pat: R4.IPatient = {
    resourceType: 'Patient',
  }
  pat.extension = []
  if (patientProfile.nationality.length >= 0) {
    const nationalityExt: R4.IExtension = {}
    nationalityExt.url =
      'http://hl7.org/fhir/StructureDefinition/patient-nationality'
    nationalityExt.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/CodeSystem/country',
        valueCodeableConcept: {
          text: 'india',
        },
      },
    ]
    pat.extension.push(nationalityExt)
  }
  if (patientProfile.occupation) {
    const occupationExt: R4.IExtension = {}
    occupationExt.url =
      'http://wellopathy.com/fhir/india/core/CodeSystem/patient-occupation'
    occupationExt.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/CodeSystem/occupation',
        valueCodeableConcept: {
          text:
            patientProfile.occupation === 'other'
              ? patientProfile.occuptionOtherData
              : patientProfile.occupation,
        },
      },
    ]
    pat.extension.push(occupationExt)
  }

  const unitOrg = getCurrentUserUnitDetails()
  const unitExtension: R4.IExtension = {
    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/ManagingOrganizationUnit',
    valueReference: {
      id: unitOrg.id,
      reference: `Organization/${unitOrg.id}`,
      display: unitOrg.name,
    },
  }

  pat.extension.push(unitExtension)

  pat.id = oldPatient.id

  let vData: string = ''

  const versionData = sessionStorage.getItem('version')

  if (versionData !== null) vData = JSON.stringify(versionData)

  const patMatchString: string =
    versionData === null
      ? `W/${JSON.stringify(oldPatient?.meta?.versionId ?? ' ')}`
      : `W/${vData}`

  if (
    getIdentifierValueBySystemType(
      oldPatient.identifier ?? [],
      'address-proof'
    )!.length > 0 &&
    getValueAttachmentFromExtension(
      oldPatient.extension,
      'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
    ) === '0'
  ) {
    const addressProofFront: R4.IAttachment | undefined =
      getValueAttachmentFromExtensionData(
        oldPatient.extension,
        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front'
      )

    const addressProofBack: R4.IAttachment | undefined =
      getValueAttachmentFromExtensionData(
        oldPatient.extension,
        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-back'
      )

    const addressProofFrontExt: R4.IExtension = {}
    if (addressProofFront !== undefined) {
      addressProofFrontExt.url =
        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front'
      addressProofFrontExt.valueAttachment = {
        contentType: addressProofFront.contentType,
        title: addressProofFront.title,
        _data: {
          extension: [
            {
              url: 'http://hapifhir.io/fhir/StructureDefinition/externalized-binary-id',
              valueString: addressProofFront._data
                ? addressProofFront._data.extension
                  ? addressProofFront._data.extension[0].valueString ?? ''
                  : ''
                : '',
            },
          ],
        },
        size: addressProofFront.size,
      }
      pat.extension.push(addressProofFrontExt)
    }

    if (addressProofBack !== undefined) {
      const addressProofBackExt: R4.IExtension = {}
      addressProofBackExt.url =
        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-back'
      addressProofBackExt.valueAttachment = {
        contentType: addressProofBack.contentType,
        title: addressProofBack.title,
        _data: {
          extension: [
            {
              url: 'http://hapifhir.io/fhir/StructureDefinition/externalized-binary-id',
              valueString: addressProofBack._data
                ? addressProofBack._data.extension
                  ? addressProofBack._data.extension[0].valueString ?? ''
                  : ''
                : '',
            },
          ],
        },
        size: addressProofBack.size,
      }
      pat.extension.push(addressProofBackExt)
    }
  }

  if (
    getIdentifierValueBySystemType(
      oldPatient.identifier ?? [],
      'address-proof'
    )!.length > 0 &&
    getValueAttachmentFromExtension(
      oldPatient.extension,
      'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
    ) === '1'
  ) {
    const addressProofBoth: R4.IAttachment | undefined =
      getValueAttachmentFromExtensionData(
        oldPatient.extension,
        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
      )

    if (addressProofBoth) {
      const addressProofBothExt: R4.IExtension = {}

      addressProofBothExt.url =
        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
      addressProofBothExt.valueAttachment = {
        contentType: addressProofBoth.contentType,
        title: addressProofBoth.title,
        _data: {
          extension: [
            {
              url: 'http://hapifhir.io/fhir/StructureDefinition/externalized-binary-id',
              valueString: addressProofBoth._data
                ? addressProofBoth._data.extension
                  ? addressProofBoth._data.extension[0].valueString ?? ''
                  : ''
                : '',
            },
          ],
        },
        size: addressProofBoth.size,
      }
      pat.extension.push(addressProofBothExt)
    }
  }

  const identifier: R4.IIdentifier = {}
  identifier.use = IdentifierUseKind._usual
  identifier.system = 'mrn-tenant1'
  identifier.value = oldPatient.identifier ? oldPatient.identifier[0].value : ''
  if (pat.identifier == null) {
    pat.identifier = [identifier]
  } else {
    pat.identifier.push(identifier)
  }
  const phoneIdentifier: R4.IIdentifier = {}
  phoneIdentifier.value = `${patientProfile.phone}`
  phoneIdentifier.system = 'phone'
  pat.identifier.push(phoneIdentifier)
  if (patientProfile.email.length > 0) {
    const emailIdentifier: R4.IIdentifier = {}
    emailIdentifier.value = `${patientProfile.email}`
    emailIdentifier.system = 'email'
    pat.identifier.push(emailIdentifier)
  }

  if (patientProfile.labReference.length > 0) {
    const labRefIdentifier: R4.IIdentifier = {}
    labRefIdentifier.value = `${patientProfile.labReference}`
    labRefIdentifier.system = 'labReference'
    pat.identifier.push(labRefIdentifier)
  }

  const addProofIdentifir: R4.ICodeableConcept | undefined =
    getIdentifierValueBySystemTypeDAata(
      oldPatient.identifier ?? [],
      'address-proof'
    )
  if (addProofIdentifir) {
    const addressIdentifier: R4.IIdentifier = {}
    addressIdentifier.type = {
      coding: addProofIdentifir.coding!,
    }
    addressIdentifier.use = IdentifierUseKind._usual
    addressIdentifier.system = 'address-proof'
    addressIdentifier.value = getIdentifierValueBySystemTypeDAataVal(
      oldPatient.identifier ?? [],
      'address-proof'
    )
    if (pat.identifier == null) {
      pat.identifier = [addressIdentifier]
    } else {
      pat.identifier.push(addressIdentifier)
    }
  }

  const humanName: R4.IHumanName = {}
  humanName.given = [patientProfile.name]
  if (patientProfile.middleName) {
    humanName.given.push(patientProfile.middleName)
  }

  humanName.family = patientProfile.lastName
  humanName.use = HumanNameUseKind._official
  if (pat.name == null) {
    pat.name = [humanName]
  } else {
    pat.name.push(humanName)
  }

  const phoneContact: R4.IContactPoint = {}
  phoneContact.use = ContactPointUseKind._mobile
  phoneContact.system = R4.ContactPointSystemKind._phone
  phoneContact.rank = 1
  phoneContact.value = patientProfile.phone

  if (pat.telecom == null) {
    pat.telecom = [phoneContact]
  } else {
    pat.telecom.push(phoneContact)
  }

  const emailContact: R4.IContactPoint = {}
  emailContact.use = ContactPointUseKind._home
  emailContact.system = R4.ContactPointSystemKind._email
  emailContact.rank = 1
  emailContact.value = patientProfile.email

  if (pat.telecom == null) {
    pat.telecom = [emailContact]
  } else {
    pat.telecom.push(emailContact)
  }
  const dateRes: E.Either<Errors, string> = R4.RTTI_date.decode(
    patientProfile.dob
  )
  if (dateRes._tag === 'Right') {
    pat.birthDate = dateRes.right
  } else {
    throw Error('Invalid date format')
  }
  const photo: R4.IAttachment = {}
  if (patientProfile.imgName.length > 0) {
    photo.data = patientProfile.imgName
    photo.contentType = patientProfile.type
  } else {
    photo.data = oldPatient.photo ? oldPatient.photo[0].data : ''
    photo.contentType = oldPatient.photo ? oldPatient.photo[0].contentType : ''
  }

  if (pat.photo == null) {
    pat.photo = [photo]
  } else {
    pat.photo.push(photo)
  }
  if (patientProfile.address.length > 0) {
    const address: R4.IAddress = {}
    const add: string[] = []
    const patAddress: R4.IAddress[] = []
    add.push(patientProfile.address)
    address.line = add
    if (patientProfile.addressType === 'home')
      address.use = oldPatient.address ? R4.AddressUseKind._home : undefined
    else address.use = oldPatient.address ? R4.AddressUseKind._work : undefined
    address.type = R4.AddressTypeKind._both
    patAddress.push(address)
    pat.address = patAddress
  }

  pat.gender =
    patientProfile.gender === PatientGenderKind._male
      ? PatientGenderKind._male
      : patientProfile.gender === PatientGenderKind._female
      ? PatientGenderKind._female
      : patientProfile.gender === PatientGenderKind._other
      ? PatientGenderKind._other
      : PatientGenderKind._unknown
  pat.deceasedBoolean = false
  pat.active = true

  const maritalCodeableList: React.SetStateAction<R4.ICodeableConcept[]> = []
  const divorceConcept: R4.ICodeableConcept = {}
  const marriedConcept: R4.ICodeableConcept = {}
  const unMarriedConcept: R4.ICodeableConcept = {}
  const widowedConcept: R4.ICodeableConcept = {}
  const maritalStatus: R4.ICodeableConcept = {}

  const maritalCodingList: React.SetStateAction<R4.ICoding[]> = []
  const divorceCoding: R4.ICoding = {
    system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
    code: 'D',
    display: 'Divorced',
  }
  const marriedCoding: R4.ICoding = {
    system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
    code: 'M',
    display: 'Married',
  }
  const unMarriedCoding: R4.ICoding = {
    system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
    code: 'U',
    display: 'unmarried',
  }
  const widowCoding: R4.ICoding = {
    system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
    code: 'W',
    display: 'Widowed',
  }
  maritalCodingList.push(divorceCoding)
  divorceConcept.coding = maritalCodingList

  maritalCodingList.push(marriedCoding)
  marriedConcept.coding = maritalCodingList

  maritalCodingList.push(unMarriedCoding)
  unMarriedConcept.coding = maritalCodingList

  maritalCodingList.push(widowCoding)
  widowedConcept.coding = maritalCodingList

  maritalCodeableList.push(divorceConcept)
  maritalCodeableList.push(marriedConcept)
  maritalCodeableList.push(unMarriedConcept)
  maritalCodeableList.push(widowedConcept)

  for (let i = 0; i < maritalCodeableList.length; i++) {
    const codableList = maritalCodeableList[i].coding ?? []
    for (let j = 0; j < codableList.length; j++) {
      if (codableList[j].code === patientProfile.maritalStaTus) {
        maritalStatus.coding = [codableList[j]]
        pat.maritalStatus = maritalStatus
      }
    }
  }

  pat.managingOrganization = oldPatient.managingOrganization

  const bundleObject: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `Patient/${oldPatient.id}`,
        request: {
          ifMatch: patMatchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `Patient/${oldPatient.id}`,
        },
        resource: pat,
      },
    ],
  }

  if (oldFatherData) {
    const fathersData: R4.IRelatedPerson = { ...oldFatherData }
    const fatherMatchString: string = `W/${JSON.stringify(
      oldFatherData?.meta?.versionId ?? ' '
    )}`
    const relatedPat: R4.IRelatedPerson = {
      resourceType: 'RelatedPerson',
      patient: {
        reference: fathersData.patient.reference,
      },
    }
    relatedPat.identifier = fathersData.identifier

    const humanNameFather: R4.IHumanName = {}
    const nameData: string[] = []
    nameData.push(patientProfile.fathersName)
    humanNameFather.given = [patientProfile.fathersName]
    humanNameFather.use = HumanNameUseKind._official
    if (relatedPat.name == null) {
      relatedPat.name = [humanNameFather]
    } else {
      relatedPat.name.push(humanNameFather)
    }

    const relationShipCodeableList: React.SetStateAction<
      R4.ICodeableConcept[]
    > = []
    const relationShipCodeable: R4.ICodeableConcept = {}
    const relationShipCodingList: React.SetStateAction<R4.ICoding[]> = []
    const fatherRelation: R4.ICoding = {
      system: 'http://hl7.org/fhir/ValueSet/relatedperson-relationshiptype',
      code: 'FTH',
      display: 'father',
    }
    relationShipCodingList.push(fatherRelation)
    relationShipCodeable.coding = relationShipCodingList
    relationShipCodeableList.push(relationShipCodeable)
    relatedPat.relationship = relationShipCodeableList

    bundleObject.entry?.push({
      fullUrl: `RelatedPerson/${oldFatherData.id}`,
      request: {
        ifMatch: fatherMatchString,
        method: R4.Bundle_RequestMethodKind._put,
        url: `RelatedPerson/${oldFatherData.id}`,
      },
      resource: relatedPat,
    })
  }

  if (oldHusbandData) {
    const husbandData: R4.IRelatedPerson = { ...oldHusbandData }

    const husbandMatchString: string = `W/${JSON.stringify(
      oldHusbandData?.meta?.versionId ?? ' '
    )}`

    const relatedPatHus: R4.IRelatedPerson = {
      resourceType: 'RelatedPerson',
      patient: {
        reference: oldHusbandData.patient.reference,
      },
    }
    relatedPatHus.identifier = oldHusbandData.identifier

    const humanNameHusband: R4.IHumanName = {}
    const nameData: string[] = []
    nameData.push(patientProfile.husbandName)
    humanNameHusband.given = [patientProfile.husbandName]
    humanNameHusband.use = HumanNameUseKind._official
    if (relatedPatHus.name == null) {
      relatedPatHus.name = [humanNameHusband]
    } else {
      relatedPatHus.name.push(humanNameHusband)
    }

    const relationShipCodeableList: React.SetStateAction<
      R4.ICodeableConcept[]
    > = []
    const relationShipCodeable: R4.ICodeableConcept = {}
    const relationShipCodingList: React.SetStateAction<R4.ICoding[]> = []
    const fatherRelation: R4.ICoding = {
      system: 'http://hl7.org/fhir/ValueSet/relatedperson-relationshiptype',
      code: 'HUSB',
      display: 'husband',
    }
    relationShipCodingList.push(fatherRelation)
    relationShipCodeable.coding = relationShipCodingList
    relationShipCodeableList.push(relationShipCodeable)
    relatedPatHus.relationship = relationShipCodeableList
    bundleObject.entry?.push({
      fullUrl: `RelatedPerson/${oldHusbandData.id}`,
      request: {
        ifMatch: husbandMatchString,
        method: R4.Bundle_RequestMethodKind._put,
        url: `RelatedPerson/${oldHusbandData.id}`,
      },
      resource: relatedPatHus,
    })
  }
  return bundleObject
}

export default patientSlice.reducer
