import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import {
  FhirLabDiagnosticRequest,
  FhirLabDiagnosticRequestReferrals,
} from 'models/fhirLabDiagnosticRequest'
import { type } from 'os'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getAppointmentIdForFollowUp,
  getLoinCodeForReport,
} from 'utils/fhirResourcesHelper'
import {
  getLabDiagnosticRequestFromBundle,
  getTestsFromDiagnostics,
  getTestsFromTasks,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import { logger } from 'utils/logger'
import { LabDiagnosticsListSliceStatus } from './labDiagnosticsListStatus'

const initialState: LabDiagnosticsListSliceStatus = {
  fetchingImpressions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  updatedImpressions: false,
  updatingImpressions: false,
  errorWhileSearchingImpressions: false,
  errorWhileUpdatingImpressions: false,
}

const labDiagnosticsListSlice = createSlice({
  name: 'labDiagnosticsListSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<LabDiagnosticsListSliceStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingImpressions = action.payload.fetchingImpressions
      state.resultsAvailable = action.payload.resultsAvailable
      state.lanDiagnosticRequests = action.payload.lanDiagnosticRequests
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingImpressions =
        action.payload.errorWhileUpdatingImpressions
      state.tasks = action.payload.tasks
      state.updatedImpressions = action.payload.updatedImpressions
      state.updatingImpressions = action.payload.updatingImpressions
      state.diagnosticTestsLoincCodes = action.payload.diagnosticTestsLoincCodes
      state.errorWhileSearchingImpressions =
        action.payload.errorWhileSearchingImpressions
      state.serviceRequest = action.payload.serviceRequest
    },
  },
})

export const getLabRequestsOfAppointment =
  (
    fhirAppointmentDetails: FhirAppointmentDetail,
    followUp?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: LabDiagnosticsListSliceStatus = { ...initialState }
    state.fetchingImpressions = true
    dispatch(labDiagnosticsListSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      let searchParameters: any = {
        'encounter.appointment': fhirAppointmentDetails.appointment.id,
        '_include:iterate': 'ServiceRequest:instantiates-canonical',
        category: '108252007',
        intent: 'proposal',
      }

      if (
        followUp &&
        fhirAppointmentDetails.appointment.supportingInformation
      ) {
        searchParameters = {
          'encounter.appointment': getAppointmentIdForFollowUp(
            fhirAppointmentDetails.appointment.supportingInformation ?? []
          ),
          '_include:iterate': 'ServiceRequest:instantiates-canonical',
          category: '108252007',
          intent: 'proposal',
        }
      }

      //   const taskSearchParameters: any = {
      //     'focus:ActivityDefinition.title': 'Task Lab Order',
      //     encounter: `Encounter/${fhirAppointmentDetails.encounter?.id}` ?? '',
      //     'status:': 'requested',
      //   }

      let response: any = await fhirClient.doGetResourceForAppointment(
        '/ServiceRequest',
        fhirAppointmentDetails.appointment.id!,
        searchParameters
      )

      if (
        followUp &&
        getAppointmentIdForFollowUp(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        ).length > 0
      ) {
        response = await fhirClient.doGetResourceForAppointment(
          '/ServiceRequest',
          getAppointmentIdForFollowUp(
            fhirAppointmentDetails.appointment.supportingInformation ?? []
          ),
          searchParameters
        )
      }

      const conditionResponse: R4.IBundle = response
      const task: R4.ITask[] = []
      const referralWithData: FhirLabDiagnosticRequestReferrals[] = []

      if (conditionResponse?.total && conditionResponse?.total > 0) {
        const serviceRequestData: R4.IServiceRequest[] = []

        if (
          conditionResponse &&
          conditionResponse.entry &&
          conditionResponse.entry.length > 0
        ) {
          for (let i = 0; i < conditionResponse.entry.length; i++) {
            serviceRequestData.push(
              conditionResponse.entry[i].resource as R4.IServiceRequest
            )
          }
        }
        if (serviceRequestData && serviceRequestData.length > 0) {
          for (let i = 0; i < serviceRequestData.length; i++) {
            if (serviceRequestData[i].patientInstruction) {
              referralWithData.push({
                serviceRequest: serviceRequestData[i],
                docReports: undefined,
                docRefs: undefined,
              })
            }
          }

          const referralWithDataFinal: FhirLabDiagnosticRequestReferrals[] = []
          await Promise.all(
            referralWithData.map(async (e) => {
              const data = await getReports(fhirAppointmentDetails, e)
              referralWithDataFinal.push(data)
            })
          )

          console.log(referralWithDataFinal)
          state.resultsAvailable = true
          state.fetchingImpressions = false
          state.tasks = task
          state.lanDiagnosticRequests = referralWithDataFinal
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingImpressions = false
          state.serviceRequest = serviceRequestData
            .filter(
              (service: R4.IServiceRequest) => service.patientInstruction!
            )
            .sort((a, b) =>
              a
                .patientInstruction!.toLowerCase()
                .localeCompare(b.patientInstruction!.toLowerCase())
            )

          dispatch(labDiagnosticsListSlice.actions.updatedStatus(state))
        }
      } else {
        const errorSearchDoctor: LabDiagnosticsListSliceStatus = {
          ...initialState,
        }
        errorSearchDoctor.noResultsAvailable = true
        dispatch(
          labDiagnosticsListSlice.actions.updatedStatus(errorSearchDoctor)
        )
      }
    } catch (error) {
      const errorSearchDoctor: LabDiagnosticsListSliceStatus = {
        ...initialState,
      }
      errorSearchDoctor.errorReason = 'Error while fetching assessment details'
      errorSearchDoctor.errorWhileSearchingImpressions = false
      dispatch(labDiagnosticsListSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export async function getReports(
  appointment: FhirAppointmentDetail,
  referralData: FhirLabDiagnosticRequestReferrals
): Promise<FhirLabDiagnosticRequestReferrals> {
  const appointmentData: FhirLabDiagnosticRequestReferrals = {
    ...referralData,
  }
  const reportsFiles: R4.IDocumentReference[] = []
  const diagnosticReport: R4.IDiagnosticReport[] = []

  const fhirClient: FHIRApiClient = new FHIRApiClient()

  if (appointment.encounter) {
    const searchParameters: any = {
      encounter: appointment.encounter ? appointment.encounter.id ?? '' : '',
      _sort: '-_lastUpdated',
    }
    let response: any

    response =
      await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformForReport(
        '/DiagnosticReport?category:missing=true&based-on:missing=false',
        appointment.appointment.id!,
        searchParameters
      )

    logger.info('Org  Response')
    logger.info(response)

    if (response && response.entry && response.entry.length > 0) {
      for (let i = 0; i < response.entry.length; i++) {
        diagnosticReport.push(
          response.entry[i].resource as R4.IDiagnosticReport
        )
      }
      if (diagnosticReport.length > 0) {
        for (let i = 0; i < diagnosticReport.length; i++) {
          if (diagnosticReport[i].code) {
            const typeData = diagnosticReport[i].code.coding ?? []
            if (typeData.length > 0) {
              if (referralData.serviceRequest.orderDetail) {
                const details = referralData.serviceRequest.orderDetail
                console.log(details)
                if (details && details.length > 0) {
                  const final: string[] = []
                  for (let k = 0; k < details.length; k++) {
                    const codeData = details[k].coding ?? []
                    if (codeData.length > 0) {
                      for (let j = 0; j < typeData.length; j++) {
                        if (typeData[j].code === codeData[0].code) {
                          final.push('1')
                        }
                      }
                    }
                  }

                  if (final.length > 0) {
                    appointmentData.docReports = diagnosticReport[i]
                  }
                }
              }
            }
          }
        }
      }
    } else {
      response =
        await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
          '/DocumentReference?type=11502-2&status=current',
          appointment.appointment.id!,
          searchParameters
        )

      if (response && response.entry && response.entry.length > 0) {
        for (let i = 0; i < response.entry.length; i++) {
          reportsFiles.push(response.entry[i].resource as R4.IDocumentReference)
        }

        if (reportsFiles.length > 0) {
          for (let i = 0; i < reportsFiles.length; i++) {
            if (reportsFiles[i].type) {
              const typeData = reportsFiles[i].type
              if (typeData && typeData.coding && typeData.coding.length > 0) {
                if (referralData.serviceRequest.orderDetail) {
                  const details = referralData.serviceRequest.orderDetail
                  const loinCode = getLoinCodeForReport(
                    reportsFiles[i].extension ?? [],
                    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-prescribed-lab-test-ext'
                  )
                  if (details && details.length > 0) {
                    const codingData = details[0].coding
                    if (codingData && codingData.length > 0) {
                      if (loinCode.length > 0) {
                        for (let j = 0; j < loinCode.length; j++) {
                          if (codingData[0].code === loinCode[j]) {
                            if (appointmentData.docRefs === undefined) {
                              appointmentData.docRefs = reportsFiles[i]
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    if (
      appointmentData.serviceRequest.contained === undefined &&
      appointmentData.docRefs === undefined
    ) {
      response =
        await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
          '/DocumentReference?type=11502-2&status=current',
          appointment.appointment.id!,
          searchParameters
        )

      if (response && response.entry && response.entry.length > 0) {
        for (let i = 0; i < response.entry.length; i++) {
          reportsFiles.push(response.entry[i].resource as R4.IDocumentReference)
        }

        if (reportsFiles.length > 0) {
          for (let i = 0; i < reportsFiles.length; i++) {
            if (reportsFiles[i].type) {
              const typeData = reportsFiles[i].type
              if (typeData && typeData.coding && typeData.coding.length > 0) {
                if (referralData.serviceRequest.orderDetail) {
                  const details = referralData.serviceRequest.orderDetail
                  const loinCode = getLoinCodeForReport(
                    reportsFiles[i].extension ?? [],
                    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-prescribed-lab-test-ext'
                  )
                  if (details && details.length > 0) {
                    const codingData = details[0].coding
                    if (codingData && codingData.length > 0) {
                      if (loinCode.length > 0) {
                        for (let j = 0; j < loinCode.length; j++) {
                          if (codingData[0].code === loinCode[j]) {
                            if (appointmentData.docRefs === undefined) {
                              appointmentData.docRefs = reportsFiles[i]
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //   if (reportsFiles.length > 0) {
  //     appointmentData.reports = reportsFiles
  //   }

  //   if (diagnosticReport.length > 0) {
  //     appointmentData.diagnosticReport = diagnosticReport.sort((a, b) =>
  //       a.code &&
  //       a.code.coding &&
  //       a.code.coding[0]!.display! > b.code &&
  //       b.code.coding &&
  //       b.code.coding[0]!.display!
  //         ? 1
  //         : b.code &&
  //           b.code.coding &&
  //           b.code.coding[0]!.display! > a.code &&
  //           a.code.coding &&
  //           a.code.coding[0]!.display!
  //         ? -1
  //         : 0
  //     )
  //   }

  return appointmentData
}
export default labDiagnosticsListSlice.reducer
