import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Errors } from 'io-ts'
import * as E from 'fp-ts/lib/Either'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { getObserVationFfromObserVationDateWiseForGraph } from 'utils/fhirResoureHelpers/ipdObservationHelper'

import { GraphDataSet } from 'models/graphDataSet'
import {
  getEmailOfPatient,
  getIdentifierValueBySystem,
} from 'utils/fhirResourcesHelper'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { RealTimeGraphSearchStatus } from 'redux/realTimegraph/graphRealTimeStatusType'

const initialState: RealTimeGraphSearchStatus = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingProcedures: false,
}

const realTimeGraphSearchSlice = createSlice({
  name: 'realTimeGraphSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<RealTimeGraphSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.graphList = action.payload.graphList
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
    },
  },
})

export const searchingRealGraphData =
  (patientId: string, patient: R4.IPatient, appointmentId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: RealTimeGraphSearchStatus = {
      searchingConditions: true,
      errorWhileSearchingProcedures: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(realTimeGraphSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const searchParameters: any = {
        'patient:Patient.identifier': `address-proof|${getIdentifierValueBySystem(
          patient.identifier ?? [],
          'address-proof'
        )!},http://wellopathy.com/fhir/india/core/id/offset-phone|${getIdentifierValueBySystem(
          patient.identifier ?? [],
          'http://wellopathy.com/fhir/india/core/id/offset-phone'
        )!},email|${getEmailOfPatient(patient, ContactPointSystemKind._email)}`,
        category: 'vital-signs',
        _count: 500,
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/Observation',
        appointmentId,
        searchParameters
      )
      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingProcedures = true
        state.searchingConditions = false

        dispatch(realTimeGraphSearchSlice.actions.updatedStatus(state))
      } else {
        const proceduresResponse: R4.IBundle = resp.right
        if (proceduresResponse?.total && proceduresResponse?.total > 0) {
          const habList: R4.IObservation[] =
            proceduresResponse.entry?.map(
              (item) => item.resource as R4.IObservation
            ) ?? []

          habList.sort((a, b) =>
            (a.issued ? a.issued : '') < (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )

          const vitalLisDataFinal: GraphDataSet =
            getObserVationFfromObserVationDateWiseForGraph(habList)

          if (vitalLisDataFinal) {
            state.resultsAvailable = true
            state.searchingConditions = false
            state.graphList = vitalLisDataFinal
            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingProcedures = false
            dispatch(realTimeGraphSearchSlice.actions.updatedStatus(state))
          } else {
            const errorSearchDoctor: RealTimeGraphSearchStatus = {
              searchingConditions: false,
              errorWhileSearchingProcedures: false,
              resultsAvailable: false,
              noResultsAvailable: true,
            }
            dispatch(
              realTimeGraphSearchSlice.actions.updatedStatus(errorSearchDoctor)
            )
          }
        } else {
          const errorSearchDoctor: RealTimeGraphSearchStatus = {
            searchingConditions: false,
            errorWhileSearchingProcedures: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            realTimeGraphSearchSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: RealTimeGraphSearchStatus = {
        searchingConditions: false,
        errorWhileSearchingProcedures: true,
        resultsAvailable: false,
        errorReason: 'searching',
      }
      dispatch(
        realTimeGraphSearchSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export default realTimeGraphSearchSlice.reducer
