/* eslint-disable no-await-in-loop */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateWiseGraphData } from 'models/adminGraph/dateWiseGraphData'
import { GraphType } from 'models/adminGraph/graphType'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import { getDateWiseDietPlansFromBundle } from 'utils/fhirResoureHelpers/ipdDietHelper'
import {
  getDataForDateWiseGraph,
  getDataForMonthWiseGraph,
  getDataForPaymentMonthWise,
  getDataForTypeWise,
} from 'utils/fhirResoureHelpers/orgAdminGraphHelper'
import { logger } from 'utils/logger'
import { dateOptionsList } from 'wello-web-components/dist/components/LayoutComponent/PersonComponent/WelloDoctorAvailability'
import { MonthWiseGraphStatus } from './monthWiseGraphStatus'

const initialState: MonthWiseGraphStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
}

const monthWiseCollectionSlice = createSlice({
  name: 'monthWiseCollectionSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<MonthWiseGraphStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorReason = action.payload.errorReason
      state.collectionData = action.payload.collectionData
      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
    resetMonthState(state, action: PayloadAction<MonthWiseGraphStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorReason = action.payload.errorReason
      state.collectionData = action.payload.collectionData
      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const resetMonthState = () => (dispatch: AppDispatch) => {
  dispatch(monthWiseCollectionSlice.actions.resetMonthState(initialState))
}

export const requestForMonthWiseCollectionGraph =
  (date: Date, endDate?: Date, type?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: MonthWiseGraphStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(monthWiseCollectionSlice.actions.updatedStatus(state))
    try {
      const fhirClient: MasterFhirClient = new MasterFhirClient()
      const endDateData =
        type && endDate
          ? moment(endDate).endOf('month').format('YYYY-MM-DD')
          : moment(endDate || date)
              .endOf('month')
              .format('YYYY-MM-DD')

      const searchParameters: any = {
        _count: 500,
        outcome: 'complete',
      }
      const response: any = await fhirClient.doGetResource(
        `/PaymentReconciliation?payment-reconciliation-date=ge${moment(date)
          .startOf('month')
          .format(
            'YYYY-MM-DD'
          )}&payment-reconciliation-date=le${endDateData}&_sort=payment-reconciliation-date&_revinclude=Invoice:invoice-paymentRef`,
        searchParameters
      )

      const resp: R4.IBundle = response as R4.IBundle
      if (!resp) {
        state.errorWhileSearchingAppointments = true
        state.searchingAppointments = false

        dispatch(monthWiseCollectionSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp

        const resultData: DateWiseGraphData[] = getDataForPaymentMonthWise(
          appointmentResponse,
          date
        )
        const finalGraphData: DateWiseGraphData[] = []
        if (resultData.length > 0) {
          for (let i = 0; i < resultData.length; i++) {
            const finalData: DateWiseGraphData =
              await getTotalNumbeOfAppointmentForDate(resultData[i])
            finalGraphData.push(finalData)
          }
          if (finalGraphData.length > 0) {
            state.resultsAvailable = true
            state.searchingAppointments = false
            state.noResultsAvailable = false
            state.errorReason = undefined
            state.collectionData = finalGraphData

            state.errorWhileSearchingAppointments = false
            dispatch(monthWiseCollectionSlice.actions.updatedStatus(state))
          } else {
            const errorSearchDoctor: MonthWiseGraphStatus = {
              searchingAppointments: false,
              errorWhileSearchingAppointments: false,
              resultsAvailable: false,
              noResultsAvailable: true,
            }
            dispatch(
              monthWiseCollectionSlice.actions.updatedStatus(errorSearchDoctor)
            )
          }
        } else {
          const errorSearchDoctor: MonthWiseGraphStatus = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            monthWiseCollectionSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: MonthWiseGraphStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
      }
      dispatch(
        monthWiseCollectionSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export async function getTotalNumbeOfAppointmentForDate(
  graphData: DateWiseGraphData
): Promise<DateWiseGraphData> {
  let carePlan: R4.ICommunicationRequest | undefined

  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const graphWithIPD: DateWiseGraphData = {
    fullDate: graphData.fullDate,
    date: graphData.date,
    TotalDischarge: graphData.TotalDischarge,
    NoOfAppointments: 0,
    collection: graphData.collection,
  }

  const searchParameters: any = {
    _count: 500,
    status: 'active',
    occurrence: `ge${moment(graphData.fullDate)
      .startOf('month')
      .utcOffset('+05:30')
      .toISOString()}`,
  }
  const response: any = await fhirClient.doGetResource(
    `/ServiceRequest?code=281685003,33022008,304903009&_sort=occurrence&occurrence=le${moment(
      graphData.fullDate
    )
      .endOf('month')
      .utcOffset('+05:30')
      .toISOString()}`,
    searchParameters
  )

  const responseBundle: R4.IBundle = response as R4.IBundle
  if (responseBundle.total) {
    graphWithIPD.NoOfAppointments = responseBundle.total
  }

  return graphWithIPD
}

export default monthWiseCollectionSlice.reducer
