import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  getCodeOfSystemCodings,
  getCodeOfSystemFromCodableConceptList,
  getColorBasedOnNumber,
  getValueCoDeFromValueCodeableForSoapNotes,
  getValueRefValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import AodIcon from '@mui/icons-material/Aod'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getTimeOfDayDisplayForStatement,
  getTimeOfDayDisplayMeal,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import {
  getNotesFromAllergy,
  hasAddedBySameDoctor,
  hasAddedObsBySameDoc,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import OverflowTypography from '../../plan/ayurveda/overflowTypography'

interface OccupationTileProps {
  occupations: R4.IObservation[]
  split: boolean
  status: string
  appointmentId?: string
  onOccupationAccepted?: (obs: R4.IObservation) => void
  onOccupationRejected?: (obs: R4.IObservation) => void
  onEditClicked?: (obs: R4.IObservation) => void
  id: string
}

export const StatementOccupationTableTile: React.FC<OccupationTileProps> = ({
  occupations,
  split,
  status,
  appointmentId,
  onOccupationAccepted,
  onOccupationRejected,
  onEditClicked,
  id,
}: OccupationTileProps) => {
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
      id={`${id}Data`}
    >
      <Grid container direction='column' spacing={1} id={`${id}DataColumn`}>
        <Grid
          container
          direction='row'
          style={{
            backgroundColor: kDialogueBackground,
          }}
          id={`${id}DataRow`}
        >
          <Grid item xs={3}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Occupation
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' width='2%' justifyContent='flex-end' flexGrow>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    minHeight: '30px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Mental Stress Level
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' width='2%' justifyContent='flex-end' flexGrow>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '30px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Physical Stress Level
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' width='2%' justifyContent='flex-end' flexGrow>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '30px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Additional Notes
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container direction='row' id='vitals'>
          <Grid item xs={12}>
            <Box width='100%' paddingLeft={0.2}>
              <Divider
                orientation='horizontal'
                flexItem
                style={{
                  height: '2px',
                }}
              />
            </Box>
          </Grid>
        </Grid>

        {/* ------------------------------------------------------------------------------- */}
        {occupations.map((e, index: number) => (
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            flexGrow={1}
            key={e.id}
            id={`${id}DataRow${index}`}
            style={{
              backgroundColor: index % 2 === 0 ? 'white' : kDialogueBackground,
            }}
          >
            <Grid container direction='row' id='vitals'>
              <Grid item xs={3} id={`${id}DataRowOccupation${index}`}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      {e.meta &&
                        e.meta.security &&
                        e.meta.security[0].code === 'PATRPT' && (
                          <Box paddingRight={1}>
                            <Tooltip title=''>
                              <IconButton
                                aria-label='collapse_order_type'
                                size='small'
                              >
                                <AodIcon color='primary' />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}

                      <OverflowTypography
                        text={getObservationContent(e)}
                        typographyProps={{
                          variant: 'subtitle2',
                          color: 'initial',
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    width='2%'
                    justifyContent='flex-end'
                    flexGrow
                  >
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2} id={`${id}DataRowStressData${index}`}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      {e.component &&
                        e.component[0].code &&
                        getCodeOfSystemCodings(
                          e.component[0].code.coding ?? [],
                          'http://snomed.info/sct'
                        ) && (
                          <Box display='flex' flexDirection='row' width='100%'>
                            <Box paddingLeft={0.5} paddingRight={0.4}>
                              <Typography
                                variant='subtitle2'
                                style={getColorBasedOnNumber(
                                  0,
                                  10,
                                  e.component[0].valueInteger ?? 0
                                )}
                              >
                                {e.component[0].valueInteger}
                              </Typography>
                            </Box>
                            <Box width='50%'>
                              <Typography
                                variant='subtitle2'
                                color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,

                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                }}
                              >
                                / 10
                              </Typography>
                            </Box>
                          </Box>
                        )}
                    </Box>

                    {/* <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  /> */}
                  </Box>
                  <Box
                    display='flex'
                    width='2%'
                    justifyContent='flex-end'
                    flexGrow
                  >
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={2} id={`${id}DataRowStressDataNew${index}`}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      {e.component &&
                        e.component[1].code &&
                        getCodeOfSystemCodings(
                          e.component[1].code.coding ?? [],
                          'http://snomed.info/sct'
                        ) && (
                          <Box display='flex' flexDirection='row' width='100%'>
                            <Box paddingLeft={0.5} paddingRight={0.4}>
                              <Typography
                                variant='subtitle2'
                                style={getColorBasedOnNumber(
                                  0,
                                  10,
                                  e.component[1].valueInteger ?? 0
                                )}
                              >
                                {e.component[1].valueInteger}
                              </Typography>
                            </Box>
                            <Box width='50%'>
                              <Typography
                                variant='subtitle2'
                                color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,

                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                }}
                              >
                                / 10
                              </Typography>
                            </Box>
                          </Box>
                        )}
                    </Box>

                    {/* <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  /> */}
                  </Box>
                  <Box
                    display='flex'
                    width='2%'
                    justifyContent='flex-end'
                    flexGrow
                  >
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        minHeight: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={5} id={`${id}DataRowStressNotes${index}`}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                      width='100%'
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='70%'
                        paddingLeft={1}
                        paddingTop={1}
                      >
                        {hasNotes(e.note) && (
                          <Box display='flex' flexDirection='row' width='100%'>
                            {getNotesFromAllergy(e.note)!.map((eData) => (
                              <Box
                                key={eData}
                                width='100%'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                              >
                                {eData && (
                                  <OverflowTypography
                                    text={eData ?? ''}
                                    typographyProps={{
                                      variant: 'subtitle2',
                                      color: 'initial',
                                    }}
                                  />
                                )}
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Box>
                      {e.meta &&
                        e.meta.security &&
                        e.meta.security[0].code === 'PATRPT' &&
                        getValueRefValueExtensionsOfUrl(
                          e.extension ?? [],
                          'http://hl7.org/fhir/StructureDefinition/event-partOf'
                        )!.split('/')[1] === appointmentId! &&
                        getValueCoDeFromValueCodeableForSoapNotes(
                          e.extension ?? [],
                          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-verification-status-ext'
                        ) === 'unconfirmed' && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='25%'
                            paddingRight={1}
                          >
                            <Button
                              onClick={() => {
                                if (onOccupationAccepted) {
                                  onOccupationAccepted(e)
                                }
                              }}
                              size='small'
                              id='accept'
                              disableElevation
                              variant='contained'
                              color='primary'
                            >
                              Accept
                            </Button>
                            <Button
                              onClick={() => {
                                if (onOccupationRejected) {
                                  onOccupationRejected(e)
                                }
                              }}
                              size='small'
                              variant='outlined'
                              id='reject'
                              disableElevation
                            >
                              Reject
                            </Button>
                          </Box>
                        )}
                    </Box>
                  </Box>

                  {hasAddedObsBySameDoc(e) &&
                    split === false &&
                    getValueRefValueExtensionsOfUrl(
                      e.extension ?? [],
                      'http://hl7.org/fhir/StructureDefinition/event-partOf'
                    )!.split('/')[1] === appointmentId! && (
                      <Box
                        display='flex'
                        flexDirection='column'
                        paddingLeft={1}
                        paddingTop={1}
                        paddingRight={4}
                      >
                        <Tooltip title='Edit' id='chief_tool_edit'>
                          <IconButton
                            aria-label='btn_ord_cancel'
                            color='primary'
                            id='occupation_tool_edit_button'
                            style={{ padding: 0 }}
                            onClick={() => {
                              if (onEditClicked) onEditClicked(e)
                            }}
                          >
                            <img
                              id='chief_tool_edit_img'
                              src={`${process.env.PUBLIC_URL}/editVector.png`}
                              alt='Edit'
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                </Box>
              </Grid>
            </Grid>
            <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            {/* <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid> */}
          </Box>
        ))}
      </Grid>
    </Box>
  )
}
