import { Box, CircularProgress, Typography, useTheme } from '@material-ui/core'
import { CPGForTable } from 'models/administration/cpgDetailsForTable'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getCPSubscriptionsListForWPAdmin } from 'utils/admisnistration/cpg/cpgListHelper'
import { CPGListTable } from './cpgListTable'

interface Props {
  isReadonly?: boolean
}

export const CPSubscriptionPlanListPageForWPAdmin: React.FC<Props> = ({
  isReadonly: split,
}: Props) => {
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [pathyaApathyaList, setPathyaApathyaList] = useState<CPGForTable[]>()

  function fetchCPGLists() {
    setUpdateStatus({ requesting: true })
    getCPSubscriptionsListForWPAdmin().then((e) => {
      if (e !== false) {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setPathyaApathyaList(e as CPGForTable[])
      } else {
        setUpdateStatus({
          requesting: false,
          requestError: false,
        })
      }
    })
  }

  useEffect(() => {
    fetchCPGLists()
  }, [])

  return (
    <Box display='flex' flexDirection='column' width='100%' height='100%'>
      {updateStatus.requesting && <CircularProgress size={15} />}
      {updateStatus.requestError && (
        <Box marginY={1} display='flex' flexDirection='column' width='100%'>
          <Typography variant='subtitle2' color='error'>
            {' '}
            Error while fetching CPG Details
          </Typography>
        </Box>
      )}
      {updateStatus.requestSuccess &&
        pathyaApathyaList &&
        pathyaApathyaList.length > 0 && (
          <Box
            width='100%'
            display='flex'
            flexDirection='column'
            height='100%'
            pt={2}
          >
            <CPGListTable medicationsList={pathyaApathyaList} />
          </Box>
        )}

      {updateStatus.requestSuccess &&
        pathyaApathyaList &&
        pathyaApathyaList.length === 0 && (
          <Box width='100%' display='flex' flexDirection='column'>
            <Typography variant='subtitle2' color='error'>
              {' '}
              No Care Plans available
            </Typography>
          </Box>
        )}
    </Box>
  )
}
