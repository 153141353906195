import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getUserDetails } from 'services/userDetailsService'
import { logger } from 'utils/logger'
import { PendingTasksCheckerStatus } from './pendingTasksCheckerTypes'

const initialState: PendingTasksCheckerStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const pendingTasksCheckerSlice = createSlice({
  name: 'pendingTasksCheckerSlice',
  initialState,
  reducers: {
    fetchingPendingTasksDetails(
      state,
      action: PayloadAction<PendingTasksCheckerStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.tasksList = action.payload.tasksList
      state.totalCount = action.payload.totalCount
      state.tasksResources = action.payload.tasksResources
    },

    fetchedTasksList(state, action: PayloadAction<PendingTasksCheckerStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.tasksList = action.payload.tasksList
      state.totalCount = action.payload.totalCount
      state.tasksResources = action.payload.tasksResources
    },

    noPendingTasks(state, action: PayloadAction<PendingTasksCheckerStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.tasksList = action.payload.tasksList
      state.totalCount = action.payload.totalCount
      state.tasksResources = action.payload.tasksResources
    },

    errorWhileFetchingTasks(
      state,
      action: PayloadAction<PendingTasksCheckerStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.tasksList = action.payload.tasksList
      state.totalCount = action.payload.totalCount
      state.tasksResources = action.payload.tasksResources
    },
  },
})

export const fetchPendingTasksDetailsOfCurrentUser =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const fetchingTasks: PendingTasksCheckerStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }

    dispatch(
      pendingTasksCheckerSlice.actions.errorWhileFetchingTasks(fetchingTasks)
    )
    try {
      const currentPractitionerRole: R4.IPractitionerRole | undefined =
        getUserDetails()?.practitionerRole

      let searchParameters = {}
      if (currentPractitionerRole) {
        searchParameters = {
          status: 'requested',

          owner: `${currentPractitionerRole.resourceType}/${currentPractitionerRole.id}`,
        }
      }

      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const response: any = await fhirClient.doGetResource(
        '/Task',
        searchParameters
      )

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const orgResponse: R4.IBundle = relatedFhirDecodeRes.right
        if (orgResponse.total) {
          if (orgResponse.total > 0) {
            if (orgResponse.entry) {
              const orgArray: R4.ITask[] = orgResponse.entry.map(
                (item) => item.resource as R4.ITask
              )
              const taskLists: string[] = orgArray.map(
                (e) => e.code?.coding?.[0].code ?? ''
              )
              const filteredTasks: string[] = taskLists.filter((e) =>
                [
                  'unit-profile-completion',
                  'profile-completion',
                  'org-profile-completion',
                ].includes(e)
              )
              if (filteredTasks.length > 0) {
                const fetchUnitListResult: PendingTasksCheckerStatus = {
                  error: false,
                  noResultsAvailable: false,
                  resultsAvailable: true,
                  searching: false,
                  tasksList: filteredTasks,
                  tasksResources: orgArray,
                  totalCount: filteredTasks.length,
                }
                dispatch(
                  pendingTasksCheckerSlice.actions.fetchedTasksList(
                    fetchUnitListResult
                  )
                )
                return
              }
            }
          }
        }
        const noSearchResults: PendingTasksCheckerStatus = {
          error: false,
          noResultsAvailable: true,
          resultsAvailable: false,
          searching: false,
        }
        dispatch(
          pendingTasksCheckerSlice.actions.noPendingTasks(noSearchResults)
        )
        return
      }
      const errorWhileSearchPatient: PendingTasksCheckerStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error while searching',
      }
      dispatch(
        pendingTasksCheckerSlice.actions.errorWhileFetchingTasks(
          errorWhileSearchPatient
        )
      )
      return
    } catch (error) {
      logger.error(error)

      const errorWhileSearchPatient: PendingTasksCheckerStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        pendingTasksCheckerSlice.actions.errorWhileFetchingTasks(
          errorWhileSearchPatient
        )
      )
    }
  }

export default pendingTasksCheckerSlice.reducer
