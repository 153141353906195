import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import {
  addKriyasRecommendations,
  resetKriyaAdditionState,
} from 'redux/consultation/recommendation_additions/kriyas_addition_slice/kriyasAdditionSlice'
import { RootState } from 'redux/rootReducer'
import { getCarePlanIdFromTaskOutput } from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import { getDisplayOfSystemFromCodableConcept } from 'utils/fhirResourcesHelper'
import { AddKriyaFromCPGHandler } from './add_kriya_pop_up'

const useStyles = makeStyles((theme) => ({
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))

interface KriyaForPopUp {
  open: boolean
  medications?: R4.IServiceRequest[]
}
interface AddMedicationRecommendationProps {
  encounterId: string
  patient: R4.IPatient
  medications: R4.IServiceRequest[]
  carePlanIdentifier?: string
}

export const AddKriyaRecommendationButton: React.FC<AddMedicationRecommendationProps> =
  ({
    encounterId,
    patient,
    medications,
    carePlanIdentifier,
  }: AddMedicationRecommendationProps) => {
    const medicationAdditionSlice = useSelector(
      (state: RootState) => state.kriyasAdditionSlice
    )

    console.log(medications)
    const classes = useStyles()
    const dispatch = useDispatch()
    const [openAddMedicationDialogue, setOpenAddMedicationDialogue] =
      useState<KriyaForPopUp>({
        open: false,
      })
    const [medStored, SetMedStored] = useState<R4.IServiceRequest[]>()

    const subscriptionStatus = useSelector(
      (state: RootState) => state.patientSubscriptionForUnitSlice
    )
    useEffect(() => {
      if (medicationAdditionSlice.additionSuccessful) {
        setOpenAddMedicationDialogue({
          open: false,
        })
      }
    }, [medicationAdditionSlice.additionSuccessful])

    useEffect(() => {
      if (medicationAdditionSlice.errorWhileAdding) {
        dispatch(resetKriyaAdditionState())
      }
    }, [medicationAdditionSlice.errorWhileAdding])
    return (
      <Box>
        <Box flexDirection='row'>
          {medicationAdditionSlice.addingInstruction && (
            <Box height={25} width={25}>
              <CircularProgress size={20} />
            </Box>
          )}

          {medicationAdditionSlice.errorWhileAdding && medStored && (
            <Box flexDirection='row'>
              <Button
                variant='text'
                color='primary'
                id='med_retry'
                className={classes.buttonText}
                onClick={(e) => {
                  if (subscriptionStatus.tasks) {
                    const carePlanId = getCarePlanIdFromTaskOutput(
                      subscriptionStatus.tasks[0].task
                    )

                    if (carePlanId)
                      dispatch(
                        addKriyasRecommendations(
                          patient,
                          medStored,
                          encounterId,
                          carePlanIdentifier,
                          carePlanId
                        )
                      )
                  }
                  e.stopPropagation()
                }}
              >
                Retry
              </Button>

              {/* <Button
                variant='text'
                color='secondary'
                className={classes.rejectButtonText}
              >
                Reject
              </Button> */}
            </Box>
          )}
          {medicationAdditionSlice.additionSuccessful && (
            <Box flexDirection='row' px={2}>
              <Typography variant='subtitle1' color='initial'>
                Added
              </Typography>
            </Box>
          )}
          {!medicationAdditionSlice.additionSuccessful &&
            !medicationAdditionSlice.errorWhileAdding &&
            !medicationAdditionSlice.addingInstruction && (
              <Box flexDirection='row'>
                <Button
                  variant='text'
                  color='primary'
                  className={classes.buttonText}
                  disabled={medications.length === 0}
                  onClick={(e) => {
                    if (
                      subscriptionStatus.subscriptionStatus === 'interested' ||
                      subscriptionStatus.subscriptionStatus === 'active'
                    ) {
                      console.log('----selected kriyas----', medications)
                      setOpenAddMedicationDialogue({
                        open: true,
                        medications,
                      })
                    } else {
                      dispatch(
                        showErrorAlert(
                          'Please subscribe to the plan to add kriyas'
                        )
                      )
                    }
                    e.stopPropagation()
                  }}
                  id='med_finalize'
                >
                  Finalize & Add
                </Button>

                {/*   <Button
                  variant='text'
                  color='secondary'
                  className={classes.rejectButtonText}
                >
                  Reject
                </Button> */}
              </Box>
            )}
        </Box>

        {openAddMedicationDialogue.open && (
          <AddKriyaFromCPGHandler
            open={openAddMedicationDialogue.open}
            encounterId={encounterId}
            patient={patient}
            isAddingKriyas={medicationAdditionSlice.addingInstruction}
            preAddedMedications={(
              openAddMedicationDialogue.medications ?? []
            ).sort((a, b) =>
              (
                getDisplayOfSystemFromCodableConcept(a.code) ?? ''
              ).localeCompare(
                getDisplayOfSystemFromCodableConcept(b.code) ?? ''
              )
            )}
            onClose={() => {
              setOpenAddMedicationDialogue({
                open: false,
              })
            }}
            onKriyasAddClicked={(e) => {
              if (subscriptionStatus.tasks) {
                const carePlanId = getCarePlanIdFromTaskOutput(
                  subscriptionStatus.tasks[0].task
                )

                if (carePlanId)
                  dispatch(
                    addKriyasRecommendations(
                      patient,
                      e,
                      encounterId,
                      carePlanIdentifier,
                      carePlanId
                    )
                  )
              }

              /* setOpenAddMedicationDialogue({
                open: false,
              })
              SetMedStored(e) */
              /*  dispatch(
                addMedicationRecommendations(
                  fhirAppointmentDetails.patient,
                  e,
                  fhirAppointmentDetails,
                  carePlanIdentifier
                )
              ) */
            }}
          />
        )}
      </Box>
    )
  }
