import { R4 } from '@ahryman40k/ts-fhir-types'
import { CatalogueData } from 'models/catalogueData'
import { FhirCatalougeDetails } from 'models/fhirCatalougeDetails'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { Gender, Package, Tag } from 'models/package'
import {
  getCodeOfSystemCodings,
  getExtensionValueOfUrl,
  getStringValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import { getGenderList } from 'utils/labHelpers/packageHelper'

export function getExpandedCatalogueFromBundle(
  responseCatalogues: R4.IBundle
): FhirCatalougeDetails[] {
  const convertedCatalogueData: FhirCatalougeDetails[] = []
  const planDefinitions: any = {}
  const chargeItems: any = {}

  if (responseCatalogues.total) {
    if (responseCatalogues.total > 0) {
      if (responseCatalogues.entry) {
        const entries: R4.IBundle_Entry[] = responseCatalogues.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'PlanDefinition':
                  planDefinitions[value.resource.id] =
                    value.resource as R4.IPlanDefinition
                  break
                case 'ChargeItemDefinition':
                  chargeItems[value.resource.id] =
                    value.resource as R4.IChargeItemDefinition
                  break

                default:
                  break
              }
            }
          }
        })
        for (const key in planDefinitions) {
          if (key) {
            const planDeff: R4.IPlanDefinition = planDefinitions[
              key
            ] as R4.IPlanDefinition
            let price: number = 0
            let currency: string = ''
            const chargeDefExt = getExtensionValueOfUrl(
              planDeff.extension,
              'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
            )

            const extVal = chargeDefExt
              ? chargeDefExt.valueReference ?? undefined
              : undefined

            const data = extVal !== undefined ? JSON.stringify(extVal) : ''

            const refId =
              data.length > 0 ? data.split('/')[1].replace('"}', '') : ''
            let chargeItemRef: R4.IChargeItemDefinition | undefined
            for (const key1 in chargeItems) {
              if (key1) {
                const charge: R4.IChargeItemDefinition = chargeItems[
                  key1
                ] as R4.IChargeItemDefinition
                const chargeID: string | undefined = charge.id ?? ''
                if (chargeID === refId) {
                  chargeItemRef = charge
                  if (charge.propertyGroup) {
                    if (charge.propertyGroup[0].priceComponent) {
                      price =
                        charge.propertyGroup[0].priceComponent[0].amount
                          ?.value ?? 0
                      currency =
                        charge.propertyGroup[0].priceComponent[0].amount
                          ?.currency ?? ''
                    }
                  }
                }
              }
            }
            if (price > 0)
              if (planDeff.subjectReference === undefined) {
                convertedCatalogueData.push({
                  planDefinition: planDeff,
                  price,
                  currency,
                  chargeItemDefinition: chargeItemRef!,
                })
              }
          }
        }
      }
    }
  }
  return convertedCatalogueData
}

export function getTableDataFromFhir(
  catalogData: FhirCatalougeDetails[]
): CatalogueData[] {
  const convertedPlanDefinitionData: CatalogueData[] = []
  for (let i = 0; i < catalogData.length; i++) {
    const val: CatalogueData = {
      id: catalogData[i].planDefinition.id ?? '',
      testName: catalogData[i].planDefinition.title ?? ''.toUpperCase(),
      type: catalogData[i].planDefinition.type
        ? getCodeOfSystemCodings(
            catalogData[i].planDefinition.type!.coding ?? [],
            'http://hl7.org/fhir/uv/order-catalog/CodeSystem/laboratory-service-definition-type'
          )!.code ?? ''
        : '',
      special_Instructions:
        getStringValueExtensionsOfUrl(
          catalogData[i].planDefinition.extension,
          'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/LimitationSummary'
        ) ?? '',
      price: catalogData[i].price,
      testDesc: catalogData[i].planDefinition.description ?? '',
      fhriPlanDef: catalogData[i].planDefinition,
      schedule:
        getStringValueExtensionsOfUrl(
          catalogData[i].planDefinition.extension,
          'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ScheduleSummary'
        ) ?? '',
      currency: catalogData[i].currency,
    }
    convertedPlanDefinitionData.push(val)
  }
  //   convertedPlanDefinitionData.sort((a, b) => a.testName < b.testName ? - 1 : Number(a.testName > b.testName))

  return convertedPlanDefinitionData.sort((a, b) =>
    a.testName.toLowerCase() < b.testName.toLowerCase()
      ? -1
      : Number(a.testName.toLowerCase() > b.testName.toLowerCase())
  )
}

export function getPackageData(
  responseCatalogues: R4.IBundle,
  name: string,
  addPackage: boolean
): Package[] {
  const convertedCatalogueData: Package[] = []
  const planDefinitions: any = {}
  const chargeItems: any = {}
  const groups: any = {}

  if (responseCatalogues.total) {
    if (responseCatalogues.total > 0) {
      if (responseCatalogues.entry) {
        const entries: R4.IBundle_Entry[] = responseCatalogues.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'PlanDefinition':
                  planDefinitions[value.resource.id] =
                    value.resource as R4.IPlanDefinition
                  break
                case 'ChargeItemDefinition':
                  chargeItems[value.resource.id] =
                    value.resource as R4.IChargeItemDefinition
                  break

                case 'Group':
                  groups[value.resource.id] = value.resource as R4.IGroup
                  break

                default:
                  break
              }
            }
          }
        })
        for (const key in planDefinitions) {
          if (key) {
            const planDeff: R4.IPlanDefinition = planDefinitions[
              key
            ] as R4.IPlanDefinition
            if (planDeff.status && planDeff.status === 'active') {
              let groupRef = ''
              if (planDeff.subjectReference)
                groupRef =
                  planDeff.subjectReference.reference?.split('/')[1] ?? ''
              let price: number = 0
              let groupVersion: string = ''
              let charrgeItemVersion: string = ''
              let currency: string = ''
              const tag: Tag[] = []
              const genderData: Gender = {
                Female: false,
                Male: false,
                Others: false,
              }
              const listOfTest: LabOfferingDetail[] = []
              let minAge: number = 0
              let maxAge: number = 0

              const chargeDefExt = getExtensionValueOfUrl(
                planDeff.extension,
                'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
              )

              const extVal = chargeDefExt
                ? chargeDefExt.valueReference ?? undefined
                : undefined

              const data = extVal !== undefined ? JSON.stringify(extVal) : ''

              const refId =
                data.length > 0 ? data.split('/')[1].replace('"}', '') : ''

              if (planDeff.subjectReference) {
                if (planDeff.usage) {
                  const usgae = planDeff.usage.split(',')
                  for (let k = 0; k < usgae.length; k++) {
                    const tagData: Tag = {
                      name: usgae[k],
                    }
                    tag.push(tagData)
                  }
                }
                for (const key1 in chargeItems) {
                  if (key1) {
                    const charge: R4.IChargeItemDefinition = chargeItems[
                      key1
                    ] as R4.IChargeItemDefinition

                    const chargeID: string | undefined = charge.id ?? ''
                    if (refId) {
                      if (chargeID === refId) {
                        charrgeItemVersion = `W/${JSON.stringify(
                          charge.meta?.versionId ?? ' '
                        )}`
                        if (charge.propertyGroup) {
                          if (charge.propertyGroup[0].priceComponent) {
                            price =
                              charge.propertyGroup[0].priceComponent[0].amount
                                ?.value ?? 0
                            currency =
                              charge.propertyGroup[0].priceComponent[0].amount
                                ?.currency ?? ''
                          }
                        }
                      }
                    }
                  }
                }

                for (const key2 in planDefinitions) {
                  if (key2) {
                    const planDefInternal: R4.IPlanDefinition = planDefinitions[
                      key2
                    ] as R4.IPlanDefinition

                    if (planDeff.action) {
                      const actionData = planDeff.action[0]
                      if (actionData.action) {
                        for (let i = 0; i < actionData.action.length; i++) {
                          if (actionData.action[i].subjectReference) {
                            const reference =
                              actionData.action[i].subjectReference?.reference
                            if (reference) {
                              const planId = reference.split('/')[1]
                              if (planId === planDefInternal.id) {
                                const dataPart: LabOfferingDetail = {
                                  id: planDefInternal.id,
                                  name: planDefInternal.title ?? '',
                                  planDefinition: planDefInternal,
                                }
                                listOfTest.push(dataPart)
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                for (const key3 in groups) {
                  if (key3) {
                    const group: R4.IGroup = groups[key3] as R4.IGroup

                    if (groupRef === group.id) {
                      groupVersion = `W/${JSON.stringify(
                        group.meta?.versionId ?? ' '
                      )}`
                      if (group.characteristic) {
                        for (let j = 0; j < group.characteristic.length; j++) {
                          if (group.characteristic[j].code) {
                            if (group.characteristic[j].code.text) {
                              if (
                                group.characteristic[j].code.text === 'Gender'
                              ) {
                                if (
                                  group.characteristic[j].valueCodeableConcept
                                ) {
                                  const gender =
                                    group.characteristic[j].valueCodeableConcept
                                      ?.text
                                  if (gender === 'Male') genderData.Male = true
                                  if (gender === 'Female')
                                    genderData.Female = true
                                  if (gender === 'Others')
                                    genderData.Others = true
                                }
                              }

                              if (group.characteristic[j].code.text === 'Age') {
                                if (group.characteristic[j].valueRange) {
                                  if (group.characteristic[j].valueRange?.low) {
                                    minAge =
                                      group.characteristic[j].valueRange?.low
                                        ?.value ?? 0
                                  }

                                  if (
                                    group.characteristic[j].valueRange?.high
                                  ) {
                                    maxAge =
                                      group.characteristic[j].valueRange?.high
                                        ?.value ?? 0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                listOfTest.sort((a, b) =>
                  (a.name ?? '') > (b.name ?? '')
                    ? 1
                    : (b.name ?? '') > (a.name ?? '')
                    ? -1
                    : 0
                )
                if (addPackage) {
                  const planName = planDeff.title ?? ''
                  if (planName === name) {
                    convertedCatalogueData.push({
                      id: planDeff.id ?? ''.toUpperCase(),
                      packageName: planDeff.title ?? '',
                      packagePrice: price,
                      minAge,
                      maxAge,
                      gender: genderData,
                      cohortInfo: `${getGenderList(
                        genderData
                      )} , age between ${minAge} to ${maxAge} years old`,
                      tags: tag,
                      groupVersion,
                      chargeItemVersion: charrgeItemVersion,
                      package: planDeff,
                      listOfTest,
                      testList: listOfTest.join(', '),
                      status:
                        planDeff.status && planDeff.status === 'active'
                          ? 'Published'
                          : 'Draft',
                    })
                  }
                } else {
                  convertedCatalogueData.push({
                    id: planDeff.id ?? ''.toUpperCase(),
                    packageName: planDeff.title ?? '',
                    packagePrice: price,
                    minAge,
                    maxAge,
                    gender: genderData,
                    cohortInfo: `${getGenderList(
                      genderData
                    )} , age between ${minAge} to ${maxAge} years old`,
                    tags: tag,
                    groupVersion,
                    chargeItemVersion: charrgeItemVersion,
                    package: planDeff,
                    listOfTest,
                    testList: listOfTest
                      ?.map((e: any) => e?.planDefinition.title ?? '')
                      .join(', '),
                    status:
                      planDeff.status && planDeff.status === 'active'
                        ? 'Published'
                        : 'Draft',
                  })
                }
              }
            }
          }
        }
      }
    }
  }
  convertedCatalogueData.sort((a, b) =>
    (a.packageName ? a.packageName : '').localeCompare(
      b.packageName ? b.packageName : ''
    )
  )
  return convertedCatalogueData
}

export function getMemberShipPlanAmount(membership: FhirCatalougeDetails) {
  let amount = 0
  if (membership.chargeItemDefinition) {
    if (membership.chargeItemDefinition.propertyGroup) {
      const baseProperty = membership.chargeItemDefinition.propertyGroup.find(
        (property) => {
          if (property.priceComponent && property.priceComponent.length > 0) {
            property.priceComponent.find((price) => {
              if (price.type && price.type.length > 0) {
                if (price.type === 'base') {
                  amount = price.amount?.value ?? 0
                  return true
                }
              }
              return false
            })
          }
          return false
        }
      )
    }
  }
  return amount
}
export function getMemberShipPlanAmountCurrency(
  membership: FhirCatalougeDetails
) {
  let amount
  if (membership.chargeItemDefinition) {
    if (membership.chargeItemDefinition.propertyGroup) {
      const baseProperty = membership.chargeItemDefinition.propertyGroup.find(
        (property) => {
          if (property.priceComponent && property.priceComponent.length > 0) {
            property.priceComponent.find((price) => {
              if (price.type && price.type.length > 0) {
                if (price.type === 'base') {
                  amount = price.amount?.currency
                  return true
                }
              }
              return false
            })
          }
          return false
        }
      )
    }
  }
  return amount
}

export function getMemberShipPlanCredit(membership: FhirCatalougeDetails) {
  let amount = 0
  if (membership.chargeItemDefinition) {
    if (membership.chargeItemDefinition.propertyGroup) {
      const baseProperty = membership.chargeItemDefinition.propertyGroup.find(
        (property) => {
          if (property.priceComponent && property.priceComponent.length > 0) {
            property.priceComponent.find((price) => {
              if (price.type && price.type.length > 0) {
                if (price.type === 'informational') {
                  amount = price.amount?.value ?? 0
                  return true
                }
              }
              return false
            })
          }
          return false
        }
      )
    }
  }
  return amount
}
