import { R4 } from '@ahryman40k/ts-fhir-types'

export function getDeleteBundle(scheduleId: any, Schedule: any): R4.IBundle {
  const schedule: R4.ISchedule = {
    resourceType: 'Schedule',
    actor: Schedule.actor,
  }
  schedule.active = false
  schedule.comment = Schedule.comment
  schedule.planningHorizon = Schedule.planningHorizon
  schedule.planningHorizon = Schedule.planningHorizon
  schedule.serviceType = Schedule.serviceType
  schedule.serviceCategory = Schedule.serviceCategory
  schedule.id = Schedule.id
  const scheduleMatchString: string = `W/${JSON.stringify(
    Schedule.meta?.versionId
  )}`
  const scheduleBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `Schedule/${Schedule.id}`,
        request: {
          ifMatch: scheduleMatchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `${Schedule.resourceType}/${Schedule.id}`,
        },

        resource: schedule,
      },
    ],
  }
  return scheduleBundle
}

export function getDeleteScheduleBundle(scheduleId: any): R4.IBundle {
  const bundleArray: any = []
  const iScheduleRequest: R4.IBundle_Request = {}
  iScheduleRequest.method = R4.Bundle_RequestMethodKind._delete
  iScheduleRequest.url = `Schedule/${scheduleId}`
  const bundleScheduleEntry: R4.IBundle_Entry = {
    request: iScheduleRequest,
  }
  bundleArray.push(bundleScheduleEntry)

  const scheduleBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: bundleArray,
  }
  return scheduleBundle
}

export function getBulkScheduleBundle(Schedules: any): R4.IBundle {
  const bundleArray: any = []
  for (let i = 0; i < Schedules.length; i++) {
    const schedule: R4.ISchedule = {
      resourceType: 'Schedule',
      actor: Schedules[i].actor,
    }
    schedule.active = false
    schedule.comment = Schedules[i].comment
    schedule.planningHorizon = Schedules[i].planningHorizon
    schedule.planningHorizon = Schedules[i].planningHorizon
    schedule.serviceType = Schedules[i].serviceType
    schedule.serviceCategory = Schedules[i].serviceCategory
    schedule.id = Schedules[i].id

    const scheduleMatchString: string = `W/${JSON.stringify(
      Schedules[i].meta?.versionId ?? ' '
    )}`

    const iRequest: R4.IBundle_Request = {}
    iRequest.ifMatch = scheduleMatchString

    iRequest.method = R4.Bundle_RequestMethodKind._put
    iRequest.url = `${Schedules[i].resourceType}/${Schedules[i].id}`
    const bundleEntry: R4.IBundle_Entry = {
      fullUrl: `Schedule/${Schedules[i].id}`,
      request: iRequest,
      resource: schedule,
    }
    bundleArray.push(bundleEntry)
  }
  const scheduleBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: bundleArray,
  }
  return scheduleBundle
}

export function getBulkScheduleUpdateBundle(Schedules: any): R4.IBundle {
  const bundleArray: any = []
  for (let i = 0; i < Schedules.length; i++) {
    const schedule: R4.ISchedule = {
      resourceType: 'Schedule',
      actor: Schedules[i].actor,
    }
    schedule.active = true
    schedule.comment = Schedules[i].comment
    schedule.planningHorizon = Schedules[i].planningHorizon
    schedule.planningHorizon = Schedules[i].planningHorizon
    schedule.serviceType = Schedules[i].serviceType
    schedule.serviceCategory = Schedules[i].serviceCategory
    schedule.id = Schedules[i].id
    // const version = Schedules[i].meta?.versionId + 1
    // eslint-disable-next-line radix
    const num = parseInt(Schedules[i].meta?.versionId)
    const versionId = num + 1
    const scheduleMatchString: string = `W/${JSON.stringify(
      versionId.toString() ?? ' '
    )}`
    const iRequest: R4.IBundle_Request = {}
    iRequest.ifMatch = scheduleMatchString
    iRequest.method = R4.Bundle_RequestMethodKind._put
    iRequest.url = `${Schedules[i].resourceType}/${Schedules[i].id}`
    const bundleEntry: R4.IBundle_Entry = {
      fullUrl: `Schedule/${Schedules[i].id}`,
      request: iRequest,
      resource: schedule,
    }
    bundleArray.push(bundleEntry)
  }
  const scheduleBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: bundleArray,
  }
  return scheduleBundle
}
