import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Checkbox, Typography } from '@material-ui/core'
import React from 'react'

interface Props {
  activity: R4.ICarePlan_Detail
  isSelected: boolean
  index?: number
  disableOption?: boolean
  onUpdated?: () => void
  onSelectionChanged: () => void
}

export const CDSPlanActivityTile: React.FC<Props> = ({
  activity: addresses,
  isSelected,
  index,
  onSelectionChanged,
  disableOption,
  onUpdated,
}: Props) => (
  <Box
    display='flex'
    flexDirection='row'
    width='100%'
    alignContent='center'
    justifyContent='center'
    alignItems='center'
    justifyItems='center'
  >
    <Box>
      {!disableOption && (
        <Checkbox
          edge='start'
          size='small'
          checked={isSelected}
          onChange={() => {
            onSelectionChanged()
          }}
          id={`cp_activities_chk_${index}`}
          tabIndex={-1}
          disableRipple
          inputProps={{
            'aria-labelledby': `${addresses.description ?? ''}list_item_id`,
          }}
          color='primary'
        />
      )}
    </Box>
    <Box
      display='flex'
      flexGrow={1}
      py={0.5}
      onClick={() => {
        onSelectionChanged()
      }}
      style={{
        cursor: 'pointer',
      }}
    >
      <Typography
        variant='body1'
        color='initial'
        id={`cp_activities_txt_${index}`}
      >
        {`${addresses.description ?? ''}`}
      </Typography>
    </Box>
  </Box>
)
