/* eslint-disable no-lone-blocks */
/* eslint-disable guard-for-in */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { ReportDisplay } from 'models/reportDisplay'
import { FHIRApiClient } from 'services/fhirApiServices'
import print from 'print-js'
import {
  getAgeOfPatient,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
} from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { getCurrentUserOrgDetails } from 'redux/administration/orgSetUp/orgProfileCompletionSlice/orgProfileCompletionSlice'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import moment from 'moment'

export const getDiagnosticReportsHelper = (
  inputBundle: R4.IBundle
): ReportDisplay[] | undefined => {
  logger.info(
    '-------------------Inside distillation function------------------------'
  )
  const reportDisplay: ReportDisplay[] = []
  const diagnosticReport: any = {}
  const patient: any = {}
  const practitioners: Map<string, R4.IPractitioner> = new Map<
    string,
    R4.IPractitioner
  >()

  if (inputBundle.total) {
    if (inputBundle.total > 0) {
      if (inputBundle.entry) {
        const entries: R4.IBundle_Entry[] = inputBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'DiagnosticReport':
                  diagnosticReport[value.resource.id] =
                    value.resource as R4.IDiagnosticReport
                  break
                case 'Patient':
                  patient[value.resource.id] = value.resource as R4.IPatient
                  logger.info('patient', patient)
                  break

                default:
                  break
              }
            }
          }
        })
        logger.info(
          '-------------------Inside distillation function middle------------------------'
        )
        if (diagnosticReport) {
          for (const key in diagnosticReport) {
            if (key) {
              const currentReport: R4.IDiagnosticReport = diagnosticReport[key]
              let reportName: string = ''
              if (currentReport.basedOn === undefined) {
                if (currentReport.code.coding) {
                  const codingData = currentReport.code.coding[0]
                  if (codingData) {
                    reportName = codingData.display ?? ''
                  }
                }

                const patientId =
                  currentReport.subject?.reference?.split('/')[1] ?? ''
                const patientData: R4.IPatient = patient[patientId ?? '']

                reportDisplay.push({
                  id: currentReport.id ?? '',
                  age: getAgeOfPatient(patientData),
                  fullName: ` ${getNameOfPatient(
                    patientData
                  )} ${getMiddleNameOfPatient(
                    patientData
                  )} ${getLastNameOfPatient(patientData)}`,
                  reportLink: reportName,
                  testName: reportName,
                  diagnosticReport: currentReport,
                  patient: patientData,
                })
              }
            }
          }

          return reportDisplay
        }
      }
    }
  }
  logger.info(
    '-------------------Inside distillation function error------------------------'
  )
  return undefined
}

export async function getTreatmentDetailsReport(
  gender: string,
  forDate: string
) {
  const enRolClient: FHIRApiClient = new FHIRApiClient()
  try {
    const response: any = await enRolClient.doCreateFHIRTransaction(
      `/$generate-treatment-card`,
      {
        resourceType: 'Parameters',
        parameter: [
          {
            name: 'start-date',
            valueString: moment(forDate).startOf('day').utc().format(),
          },
          {
            name: 'end-date',
            valueString: moment(forDate).endOf('day').utc().format(),
          },
          {
            name: 'unit-org-reference',
            valueString: getCurrentUserUnitReference()?.reference,
          },
          {
            name: 'gender',
            valueString: gender,
          },
        ],
      }
    )
    let data: string = ''
    if (response.parameter) {
      const dataIndex = (response.parameter as any[]).findIndex(
        (e) => e.name === 'treatment-card'
      )

      if (dataIndex > -1) {
        data = response.parameter[dataIndex].valueString ?? ''
      }
    }

    const byteCharacters = atob(data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], { type: 'application/pdf;base64' })
    const fileURL = URL.createObjectURL(file)
    print({
      printable: fileURL,
      type: 'pdf',
      showModal: true,
    })
    return true
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function getDietDetailsReport(forDate: string) {
  const enRolClient: FHIRApiClient = new FHIRApiClient()
  try {
    const response: any = await enRolClient.doCreateFHIRTransaction(
      `/$generate-diet-card`,
      {
        resourceType: 'Parameters',
        parameter: [
          {
            name: 'start-date',
            valueString: moment(forDate).startOf('day').utc().format(),
          },
          {
            name: 'end-date',
            valueString: moment(forDate).endOf('day').utc().format(),
          },
          {
            name: 'unit-org-reference',
            valueString: getCurrentUserUnitReference()?.reference,
          },
        ],
      }
    )
    let data: string = ''
    if (response.parameter) {
      const dataIndex = (response.parameter as any[]).findIndex(
        (e) => e.name === 'diet-card'
      )

      if (dataIndex > -1) {
        data = response.parameter[dataIndex].valueString ?? ''
      }
    }

    const byteCharacters = atob(data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], { type: 'application/pdf;base64' })
    const fileURL = URL.createObjectURL(file)
    print({
      printable: fileURL,
      type: 'pdf',
      showModal: true,
    })
    return true
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function getDischargeDetailsReport(id: string) {
  const enRolClient: FHIRApiClient = new FHIRApiClient()
  try {
    const response: any = await enRolClient.doCreateFHIRTransaction(
      `/$generate-discharge-summary`,
      {
        resourceType: 'Parameters',
        parameter: [
          {
            name: 'service-request-ref',
            valueString: `ServiceRequest/${id}`,
          },
        ],
      }
    )
    let data: string = ''
    if (response.parameter) {
      const dataIndex = (response.parameter as any[]).findIndex(
        (e) => e.name === 'discharge-summary'
      )

      if (dataIndex > -1) {
        data = response.parameter[dataIndex].valueString ?? ''
      }
    }

    const byteCharacters = atob(data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], { type: 'application/pdf;base64' })
    const fileURL = URL.createObjectURL(file)
    print({
      printable: fileURL,
      type: 'pdf',
      showModal: true,
    })
    return true
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function getDietReportOfVisit(serviceRequestId: string) {
  const enRolClient: FHIRApiClient = new FHIRApiClient()
  try {
    const response: any = await enRolClient.doCreateFHIRTransaction(
      `/$generate-diet-report`,
      {
        resourceType: 'Parameters',
        parameter: [
          {
            name: 'service-request-ref',
            valueString: `ServiceRequest/${serviceRequestId}`,
          },
        ],
      }
    )
    let data: string = ''
    if (response.parameter) {
      const dataIndex = (response.parameter as any[]).findIndex(
        (e) => e.name === 'patient_diet_report'
      )

      if (dataIndex > -1) {
        data = response.parameter[dataIndex].valueString ?? ''
      }
    }

    const byteCharacters = atob(data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], { type: 'application/pdf;base64' })
    const fileURL = URL.createObjectURL(file)
    print({
      printable: fileURL,
      type: 'pdf',
      showModal: true,
    })
    return true
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function getTreatmentReportOfVisit(serviceRequestId: string) {
  const enRolClient: FHIRApiClient = new FHIRApiClient()
  try {
    const response: any = await enRolClient.doCreateFHIRTransaction(
      `/$generate-treatment-report`,
      {
        resourceType: 'Parameters',
        parameter: [
          {
            name: 'service-request-ref',
            valueString: `ServiceRequest/${serviceRequestId}`,
          },
        ],
      }
    )
    let data: string = ''
    if (response.parameter) {
      const dataIndex = (response.parameter as any[]).findIndex(
        (e) => e.name === 'patient_treatment_report'
      )

      if (dataIndex > -1) {
        data = response.parameter[dataIndex].valueString ?? ''
      }
    }

    const byteCharacters = atob(data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], { type: 'application/pdf;base64' })
    const fileURL = URL.createObjectURL(file)
    print({
      printable: fileURL,
      type: 'pdf',
      showModal: true,
    })
    return true
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function getPoliceReportOfVisit(serviceRequestId: string) {
  const enRolClient: FHIRApiClient = new FHIRApiClient()
  try {
    const response: any = await enRolClient.doPostFHIRForFileDownLoad(
      `/$generate-police-verification-report`,
      {
        resourceType: 'Parameters',
        parameter: [
          {
            name: 'service-request-ref',
            valueString: `ServiceRequest/${serviceRequestId}`,
          },
        ],
      }
    )
    const file = response as Blob
    const fileURL = URL.createObjectURL(file)
    const newWindow = window.open(fileURL, '_blank')
    setTimeout(() => {
      newWindow!.document.title = 'my new title'
    }, 1000)
    return true
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}
