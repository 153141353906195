import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { truncate } from 'lodash'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { RoomPrice } from 'models/roomPrice'
import { RoomWithCategory } from 'models/units/roomWithCategory'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserUnitDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import {
  getRoomNumber,
  getRoomPriceDataFromBundle,
  getRoomPriceFromBundle,
} from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { getExpandedServiceRequestFromBundle } from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getParsedRoomsFromBundle,
  getRoomType,
} from 'utils/fhirResoureHelpers/roomsHelper'
import { logger } from 'utils/logger'
import { RoomListsStatus } from './roomsListStatus'

const initialState: RoomListsStatus = {
  searchingPrice: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingPrice: false,
}

const roomListSlice = createSlice({
  name: 'roomListSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<RoomListsStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingPrice = action.payload.searchingPrice
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorReason = action.payload.errorReason
      state.roomsList = action.payload.roomsList
      state.errorWhileSearchingPrice = action.payload.errorWhileSearchingPrice
    },
  },
})

export const fetchRoomsOfCurrentUnit =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: RoomListsStatus = {
      searchingPrice: true,
      errorWhileSearchingPrice: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(roomListSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const organization: R4.IOrganization = getCurrentUserUnitDetails()
      const searchParameters: any = {
        organization: organization.id ?? '',
      }

      const response: any =
        await fhirClient.doGetResourceIncludeAndIncludeIterate(
          `/Location?location-physical-type=ro`,
          searchParameters
        )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingPrice = true
        state.searchingPrice = false

        dispatch(roomListSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp.right
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          const roomListWithPrice: RoomWithCategory[] = []
          const roomListData: RoomWithCategory[] =
            getParsedRoomsFromBundle(appointmentResponse)
          console.log(roomListData)
          await Promise.all(
            roomListData.map(async (e) => {
              const data = await getRoomPrice(e)
              roomListWithPrice.push(data)
            })
          )
          console.log(roomListWithPrice)

          state.resultsAvailable = true
          state.searchingPrice = false
          state.roomsList = roomListWithPrice
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingPrice = false
          dispatch(roomListSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: RoomListsStatus = {
            searchingPrice: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            errorWhileSearchingPrice: false,
          }
          dispatch(roomListSlice.actions.updatedStatus(errorSearchDoctor))
        }
      } /* */
    } catch (error) {
      const errorSearchDoctor: RoomListsStatus = {
        searchingPrice: false,
        resultsAvailable: false,
        errorWhileSearchingPrice: true,
        errorReason: 'Error while fetching Room No',
      }
      dispatch(roomListSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

async function getRoomPrice(
  roomData: RoomWithCategory
): Promise<RoomWithCategory> {
  const roomDetails: RoomWithCategory = roomData
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const searchParameters: any = {
    url: `http://wellopathy.com/ChargeItemDefinition/${getRoomType(
      roomData.category
    )}`,
    status: 'active',
  }

  const response: any = await fhirClient.doGetResourceIncludeAndIncludeIterate(
    '/ChargeItemDefinition',
    searchParameters
  )
  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const orgResponse: R4.IBundle = relatedFhirDecodeRes.right
    if (orgResponse.total) {
      if (orgResponse.total > 0) {
        if (orgResponse.entry) {
          const priceData: number = getRoomPriceDataFromBundle(orgResponse)
          roomDetails.price = priceData
        }
      }
    }
  }
  return roomDetails
}

export default roomListSlice.reducer
