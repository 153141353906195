import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { PatientPreAppointmentDetails } from 'models/patientPreAppointmentDetails'
import { PatientPreAppointmentPostModel } from 'models/patientPreAppointmentPostModel'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getPatientPreAppointmentResourceObject } from 'utils/fhirResoureHelpers/invitationHelpers'
import { logger } from 'utils/logger'
import { BulkPatientAddSuccess } from './bulkPatientAddSuccess'

const initialState: BulkPatientAddSuccess = {
  uploading: false,
  uploadingSuccessful: false,
  error: false,
  errorMessage: '',
  successCount:0,
  updateCount:0
}

const bulkPatientAddSlice = createSlice({
  name: 'bulkAddPatient',
  initialState,
  reducers: {
    uploadingData(state, action: PayloadAction<BulkPatientAddSuccess>) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
    },

    DataUploaded(state, action: PayloadAction<BulkPatientAddSuccess>) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
    },

    errorInUploadingData(state, action: PayloadAction<BulkPatientAddSuccess>) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetUpload(state, action: PayloadAction<BulkPatientAddSuccess>) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },
  },
})

export const uploadingData =
  (patientData: R4.IBundle): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingDocumentUploadedState: BulkPatientAddSuccess = {
      uploading: true,
      uploadingSuccessful: false,
      error: false,
      successCount:10,
      updateCount:0
    }
    dispatch(
      bulkPatientAddSlice.actions.uploadingData(addingDocumentUploadedState)
    )
    try {
      logger.info('documentObj')
      const insertCount :string[]=[];
      const updateCount:string[]=[];
      logger.info(patientData)
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doCreateFHIRTransaction(
        '/',
        patientData
      )
      const respDecoded: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (respDecoded._tag === 'Right') {
        logger.info('Response  decoded', respDecoded.right)
        logger.info('Added schedule')
        logger.info(' Added Schedule dispatching')
        logger.info('Response Data ', response)
        const patientResponses: R4.IBundle = respDecoded.right  

        const successUploadingDocument: BulkPatientAddSuccess = {
          uploading: false,
          uploadingSuccessful: true,
          error: false,
          errorMessage: '',
          successCount:patientResponses.entry?.length ?? 0,
          updateCount:updateCount.length
        }
        dispatch(
          bulkPatientAddSlice.actions.DataUploaded(successUploadingDocument)
        )
      } else {
        const errorUploadingDocument: BulkPatientAddSuccess = {
          uploading: false,
          uploadingSuccessful: false,
          error: true,
          errorMessage: 'Error while creating patient',
          successCount:0,
          updateCount:0
          //   patient: patientResponse,
        }
        dispatch(
          bulkPatientAddSlice.actions.errorInUploadingData(
            errorUploadingDocument
          )
        )
      }
      return
    } catch (error) {
      logger.info('error')
      const errorDocuploadedState: BulkPatientAddSuccess = {
        uploading: false,
        uploadingSuccessful: false,
        error: true,
        errorMessage: 'Error while creating patient',
        successCount:0,
        updateCount:0
      }
      dispatch(
        bulkPatientAddSlice.actions.errorInUploadingData(errorDocuploadedState)
      )
    }
  }


  function getTransactionObjectPreAppointment(input: PatientPreAppointmentDetails[]): any {
  
    const entries: PatientPreAppointmentPostModel[] = []
    input.forEach((e) => {

      const patientObject: R4.IPatient = getPatientPreAppointmentResourceObject(e.id,
        e.patientFirstName,
        e.patientLastName,
        e.patientEmail,
        e.patientPhoneNumber)


      const patientEntry: any = {
        patient: patientObject,
        appointmentType: e.patientAppointmentType,
        checkinDate: e.patientCheckInDate
      }

      entries.push(patientEntry)
    })
    
    return entries
  }



export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(bulkPatientAddSlice.actions.resetUpload(initialState))
}

export default bulkPatientAddSlice.reducer
