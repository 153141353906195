import { R4 } from '@ahryman40k/ts-fhir-types'
import { ORG_LOGO_SPECIALIZATION_URL } from 'utils/constants/fhir_vs_ext_constants'
import { getExtensionValueOfUrl } from 'utils/fhirResourcesHelper'

/* export function getLocationObjectForSetUp(organizationRes: R4.IOrganization) {
  const location: R4.ILocation = {
    resourceType: 'Location',
    name: organizationRes.name,
    id: getUniqueTempId(),
    managingOrganization: {
      id: organizationRes.id,
      reference: `${organizationRes.resourceType}/${organizationRes.id}`,
    },
    mode: R4.LocationModeKind._instance,
    status: R4.LocationStatusKind._active,
  }
  return location
} */

export function hasLogoDetails(extensions: R4.IExtension[]): boolean {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(
    extensions,
    ORG_LOGO_SPECIALIZATION_URL
  )
  if (ext) {
    if (ext.valueAttachment) {
      if (ext.valueAttachment.url && ext.valueAttachment.url.length > 0) {
        return true
      }
    }
  }
  return false
}

export function getLogoDetails(
  extensions: R4.IExtension[]
): R4.IAttachment | undefined {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(
    extensions,
    ORG_LOGO_SPECIALIZATION_URL
  )
  if (ext) {
    if (ext.valueAttachment) {
      if (ext.valueAttachment.url && ext.valueAttachment.url.length > 0) {
        return ext.valueAttachment
      }
    }
  }
  return undefined
}

export function isClinic(organization: R4.IOrganization): boolean {
  let res: boolean = false
  if (organization) {
    if (organization.type && organization.type.length > 0) {
      organization.type.forEach((element) => {
        if (element.coding && element.coding.length > 0) {
          element.coding.forEach((item) => {
            if (item.code?.toLowerCase() === 'clinic') {
              res = true
            }
            if (item.code?.toLowerCase() === 'wellness_center') {
              res = true
            }
          })
        }
      })
    }
  }

  return res
}

export function isValidCodeableConcent(
  codeConcept: R4.ICodeableConcept | undefined
): boolean {
  if (codeConcept) {
    if (codeConcept.coding && codeConcept.coding.length > 0) {
      if (codeConcept.coding[0].code && codeConcept.coding[0].code.length > 0) {
        return true
      }
    }
  }

  return false
}
