import React from 'react'
import VisitAppointmentContext, {
  VisitAppointmentContextData,
} from 'views/contexts/visitContextDetails'

function VisitAppointmentContextProvider({
  children,
  patient,
  encounter,
  visitReference,
}: React.PropsWithChildren<VisitAppointmentContextData>) {
  return (
    <VisitAppointmentContext.Provider
      value={{ patient, encounter, visitReference }}
    >
      {children}
    </VisitAppointmentContext.Provider>
  )
}

export default VisitAppointmentContextProvider
