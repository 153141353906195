import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListSubheader,
  Radio,
  RadioGroup,
  Typography,
  Tooltip,
  Collapse,
  Link,
} from '@material-ui/core'
import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { DateWiseVitals } from 'models/dateWiseVitals'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { requestVitalDetailsOfPatient } from 'redux/consultation/vitalsDetailsSlice/vitalsDetailSlice'
import { resetAddVitalsState } from 'redux/ipd/addVitalIpd/addVitalSlice'
import { requestVitalDetailsOfPatientIPD } from 'redux/ipd/vitalsDetailsIpd/vitalsDetailSliceIpd'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isPhysiotherapist,
  isUnitAdmin,
} from 'services/userDetailsService'
import {
  getDateWiseVitals,
  getTimeWiseVitals,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { logger } from 'utils/logger'
import { EmptyTile } from 'views/components/consultation/objective/tiles/emptyTile'
import { VitalsDataTile } from 'views/components/consultation/objective/tiles/vitalDataTile'
import { VitalsTile } from 'views/components/consultation/objective/tiles/vitalsTile'
import { VitalElement } from 'views/components/LeftMenu/VitalElement'
import { SOAPIndicativeElement } from 'wello-web-components'
import { AddVital } from '../addition/addVitals'

interface VitalsProp {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  status: string
}

export const Vitals: React.FC<VitalsProp> = ({
  fhirAppointmentDetails,
  status,
}: VitalsProp) => {
  const { t } = useTranslation(['common'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const [appointmentButton, setAppointmentButtopn] = useState<string>('')
  const ans: string = ''

  const dispatch = useDispatch()
  const vitalsDetailsSliceIpd = useSelector(
    (state: RootState) => state.vitalsDetailsSliceIpd
  )
  const [groupVitals, setGroupVitals] = useState<DateWiseVitals[]>([])
  useEffect(() => {
    if (fhirAppointmentDetails) {
      dispatch(
        requestVitalDetailsOfPatientIPD(
          fhirAppointmentDetails.patient,
          fhirAppointmentDetails.mainServiceRequest.id!
        )
      )
    }
  }, [dispatch, fhirAppointmentDetails])

  useEffect(() => {
    if (
      vitalsDetailsSliceIpd.resultsAvailable &&
      vitalsDetailsSliceIpd.dateWiseVitals
    ) {
      updateVitals(vitalsDetailsSliceIpd.dateWiseVitals)
    }
  }, [vitalsDetailsSliceIpd])

  function updateVitals(vitalList: DateWiseVitals[]) {
    const results: DateWiseVitals[] = []
    for (let i = 0; i < vitalList.length; i++) {
      results.push({
        date: vitalList[i].date,
        vitals: vitalList[i].vitals,
        checked: true,
      })
    }
    setGroupVitals(results)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: DateWiseVitals[] = [...groupVitals]
    values[index].checked = rate
    setGroupVitals(values)
  }

  return (
    <ReactVisibilitySensor
      offset={{
        top: 100,
        bottom: 30,
      }}
      onChange={(isVisible) => {
        logger.info('Inside diagnostic')
        if (isVisible) {
          dispatch(setSelectedSection({ selectedSection: 'vitals' }))
        }
      }}
    >
      <Grid container direction='column' style={{ flexWrap: 'nowrap' }}>
        <Grid
          item
          //   onMouseEnter={() => showAddButton(true)}
          //   onMouseLeave={() => showAddButton(false)}
        >
          <Grid container direction='row' id='vitals'>
            <Box py={1}>
              <Typography variant='h6'> {t('labelCommon:Vitals')} </Typography>
            </Box>{' '}
            {/* {!isUnitAdmin() && !isPhysiotherapist() && (
              <Box>
                {status !== 'completed' && (
                  <IconButton
                    style={{ padding: '4px' }}
                    onClick={() => {
                      setOpenAddDialogue(true)
                    }}
                  >
                    <Add style={{ height: '16px' }} color='primary' />
                  </IconButton>
                )}
              </Box>
            )} */}
          </Grid>
          {!isUnitAdmin() && !isPhysiotherapist() && status !== 'completed' && (
            <Box
              display='flex'
              flexGrow
              flexDirection='row'
              justifyContent='flex-start'
              paddingBottom={1}
            >
              <Tooltip title='' id='obj_1'>
                <IconButton
                  aria-label='btn_ord_cancel'
                  color='primary'
                  onClick={() => {
                    setOpenAddDialogue(true)
                  }}
                  style={{ padding: 0 }}
                >
                  <AddCircleIcon
                    style={{ height: '16px', padding: 0 }}
                    color='primary'
                    id='obj_2'
                  />{' '}
                  <Typography
                    variant='subtitle2'
                    color='primary'
                    component={Link}
                    style={{ fontSize: 13 }}
                    id='obj_3'
                  >
                    {' '}
                    {t('labelCommon:Vitals')}
                  </Typography>
                </IconButton>
              </Tooltip>
            </Box>
          )}

          {vitalsDetailsSliceIpd.searchingConditions && (
            <Grid item>
              <CircularProgress size={15} />
            </Grid>
          )}
          {vitalsDetailsSliceIpd.noResultsAvailable && (
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                {' '}
                {isUnitAdmin() || isPhysiotherapist() || status === 'completed'
                  ? 'No data available'
                  : ''}
              </Typography>
            </Grid>
          )}
          {vitalsDetailsSliceIpd.errorWhileSearchingProcedures && (
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                {vitalsDetailsSliceIpd.errorReason ??
                  'Error while fetching Vitals'}
              </Typography>
            </Grid>
          )}
          {vitalsDetailsSliceIpd.resultsAvailable &&
            vitalsDetailsSliceIpd.dateWiseVitals && (
              <Grid item style={{ padding: 0, margin: 0 }} xs={12}>
                <Box
                  flexGrow
                  width='100%'
                  display='flex'
                  flexDirection='column'
                >
                  {groupVitals.map((e, index: number) => (
                    <Box
                      flexGrow
                      width='100%'
                      display='flex'
                      flexDirection='column'
                      key={`1${e.date}`}
                    >
                      <Box
                        paddingX={1}
                        borderRadius={2}
                        style={{
                          backgroundColor: 'lightGrey',
                        }}
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        height={40}
                        paddingY={1}
                      >
                        <Grid item xs={3}>
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow={1}
                            alignItems='center'
                          >
                            <Typography
                              variant='subtitle1'
                              style={{
                                color: 'black',

                                fontWeight: 'bold',
                              }}
                            >
                              Recorded on {moment(e.date).format('Do MMM YYYY')}
                            </Typography>
                          </Box>
                        </Grid>

                        {getTimeWiseVitals(e.vitals ?? []).map(
                          (e1, indexData: number) => (
                            <Grid item xs={3} key={e1.time ?? ''}>
                              <Grid container direction='row'>
                                <Grid item xs={8}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    height={30}
                                    //   paddingY={1}
                                  >
                                    <Box
                                      justifyContent='flex-start'
                                      display='flex'
                                      alignItems='center'
                                      paddingLeft={
                                        indexData === 0
                                          ? 0.5
                                          : indexData === 1
                                          ? 2
                                          : indexData === 2
                                          ? 3.8
                                          : 0
                                      }
                                    >
                                      <Typography
                                        variant='subtitle2'
                                        // color='initial'
                                        style={{
                                          fontFamily: 'Open Sans',
                                          fontSize: 12,
                                          color: 'black',

                                          fontWeight: 'bold',
                                          lineHeight: 'normal',
                                        }}
                                      >
                                        {`${e1.time} ${e1.timeFormat}`}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        )}

                        <Box
                          justifyContent='flex-end'
                          display='flex'
                          flexGrow={1}
                          alignItems='center'
                        >
                          <Box px={1}>
                            <Tooltip title=''>
                              <IconButton
                                aria-label='collapse_order_type'
                                size='small'
                                onClick={() => {
                                  handleCollapseForPanel1(!e.checked, index)
                                }}
                              >
                                {e.checked && <ArrowDropUpOutlined />}
                                {!e.checked && <ArrowDropDownOutlined />}
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                      <Collapse
                        in={e.checked}
                        style={{
                          width: '100%',
                        }}
                      >
                        <Box
                          flexGrow
                          width='100%'
                          display='flex'
                          flexDirection='column'
                        >
                          <Grid
                            container
                            direction='row'
                            style={{
                              backgroundColor: kDialogueBackground,
                            }}
                          >
                            <Grid item xs={3}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Parameter Name
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  justifyContent='flex-end'
                                  flexGrow
                                  paddingRight={0.5}
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '30px',

                                      width: '2px',
                                      //   backgroundColor: 'white',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            {getTimeWiseVitals(e.vitals ?? []).map((e1) => (
                              <Grid item xs={3} key={e1.time ?? ''}>
                                <Grid container direction='row'>
                                  <Grid item xs={8}>
                                    <Box
                                      paddingX={0.5}
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      height={30}
                                      //   paddingY={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow={1}
                                        alignItems='center'
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          // color='initial'
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 12,
                                            color: 'black',

                                            fontWeight: 'bold',
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          Value
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>

                          <Box
                            flexGrow
                            width='100%'
                            display='flex'
                            flexDirection='row'
                          >
                            <Grid container direction='row'>
                              <Grid item xs={3}>
                                <Box
                                  flexGrow
                                  width='100%'
                                  display='flex'
                                  flexDirection='column'
                                  paddingRight={0.5}
                                >
                                  <VitalsTile />
                                </Box>
                              </Grid>
                              {getTimeWiseVitals(e.vitals ?? []).map((e1) => (
                                <Grid
                                  item
                                  xs={
                                    getTimeWiseVitals(e.vitals ?? []).length ===
                                    1
                                      ? 9
                                      : 3
                                  }
                                  key={e1.time ?? ''}
                                >
                                  <Box
                                    flexGrow
                                    width='100%'
                                    display='flex'
                                    flexDirection='column'
                                  >
                                    <Box>
                                      <VitalsDataTile obsData={e1.vitals} />
                                    </Box>
                                  </Box>
                                </Grid>
                              ))}
                              {getTimeWiseVitals(e.vitals ?? []).length ===
                                2 && (
                                <Grid item xs={3}>
                                  <Box
                                    flexGrow
                                    width='100%'
                                    display='flex'
                                    flexDirection='column'
                                    paddingRight={0.5}
                                    paddingTop={0.1}
                                  >
                                    <EmptyTile />
                                  </Box>
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        </Box>
                      </Collapse>
                    </Box>
                  ))}
                </Box>
              </Grid>
            )}
        </Grid>
        {vitalsDetailsSliceIpd.resultsAvailable &&
          vitalsDetailsSliceIpd.showAppointment === true && (
            <Box py={0.5}>
              <FormControl>
                <FormLabel id='demo-row-radio-buttons-group-label'>
                  <Typography variant='subtitle1' color='textPrimary'>
                    Recommendation
                  </Typography>
                </FormLabel>
                <RadioGroup
                  id='add_unit_address_choice_rad_group'
                  row
                  aria-label='drug-Type'
                  value={appointmentButton}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAppointmentButtopn(
                      (event.target as HTMLInputElement).value
                    )
                  }}
                >
                  <Box px={1.5}>
                    <FormControlLabel
                      value='5days'
                      control={<Radio />}
                      label='Book an appointment after 5 days'
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </Box>
          )}
        {openAddDialogue && (
          <AddVital
            open={openAddDialogue}
            fhirAppointmentDetails={fhirAppointmentDetails}
            onVitalAdded={(condition) => {
              /*  dispatch(
                requestVitalDetailsOfPatientIPD(
                  fhirAppointmentDetails.patient,
                  fhirAppointmentDetails.mainServiceRequest.id!
                )
              ) */
              setOpenAddDialogue(false)

              dispatch(resetAddVitalsState())
            }}
            onClose={() => {
              setOpenAddDialogue(false)
            }}
          />
        )}
      </Grid>
    </ReactVisibilitySensor>
  )
}
