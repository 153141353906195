import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { CcClinet } from 'services/Cclinet'
import { FHIRApiClient } from 'services/fhirApiServices'
import { InventoryClient } from 'services/inventoryService'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import { getExpandedServiceRequestFromBundle } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { getExpandedServiceRequestFromBundlePartner } from 'utils/fhirResoureHelpers/partnerLabOrderHelper'
import { logger } from 'utils/logger'
import { OrderSearchStatus } from './medicineOrderSearchStatus'

const initialState: OrderSearchStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingOrders: false,
}

let currentSelectedDate: Date | undefined

const medicineOrderSearchSlice = createSlice({
  name: 'medicineOrderSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<OrderSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableOrders = action.payload.availableOrders
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingOrders = action.payload.errorWhileSearchingOrders
    },
  },
})

export const requestForMedicineOrders =
  (selectedDate?: Date, patientName?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    currentSelectedDate = selectedDate

    const state: OrderSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(medicineOrderSearchSlice.actions.updatedStatus(state))
    try {
      currentSelectedDate?.setSeconds(new Date().getSeconds())
      const fhirClient: InventoryClient = new InventoryClient()

      //   const contactId = getContactDetails()
      const contactId = await getContactDetailsById(
        getCurrentUserPractitionerDetails().id ?? ''
      )

      let orders: any[] = []
      if (contactId.length > 0) {
        orders = await getSalesOrders(
          contactId,
          moment(currentSelectedDate).format('YYYY-MM-DD')
        )
      }

      if (orders.length > 0) {
        state.resultsAvailable = true
        state.searchingAppointments = false
        state.availableOrders = orders
        state.noResultsAvailable = false
        state.errorReason = undefined
        state.errorWhileSearchingOrders = false
        dispatch(medicineOrderSearchSlice.actions.updatedStatus(state))
      } else {
        const errorSearchDoctor: OrderSearchStatus = {
          searchingAppointments: false,
          errorWhileSearchingOrders: false,
          resultsAvailable: false,
          noResultsAvailable: true,
        }
        dispatch(
          medicineOrderSearchSlice.actions.updatedStatus(errorSearchDoctor)
        )
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: OrderSearchStatus = {
        searchingAppointments: false,
        errorWhileSearchingOrders: true,
        resultsAvailable: false,
        errorReason: 'Error while fetching orders',
      }
      dispatch(
        medicineOrderSearchSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export const isContactExist = async (id: string) => {
  try {
    const fhirClient: InventoryClient = new InventoryClient()

    const response: any = await fhirClient.doGetResource(
      `/inventory/v1/contacts?notes=${id}`
    )
    console.log(response)

    const { data } = response
    if (data.contacts.length > 0) {
      return data.contacts[0].contact_id
    }
    return ''
  } catch (error) {
    console.log('Error happened')
    return ''
  }
}

export const getContactDetailsById = async (id: string) => {
  try {
    const fhirClient: InventoryClient = new InventoryClient()

    const response: any = await fhirClient.doGetResource(
      `/inventory/v1/contacts?notes=${id}`
    )

    if (response.contacts.length > 0) {
      return response.contacts[0].contact_id
    }
    return ''
  } catch (error) {
    console.log(error)
    return ''
  }
}

export async function getSalesOrders(id: string, date: string): Promise<any[]> {
  const fhirClient: InventoryClient = new InventoryClient()
  const searchParameters: any = {
    date: 0,
    active: true,
    _total: 'accurate',
  }

  const response: any = await fhirClient.doGetResource(
    `/inventory/v1/salesorders?date=${moment(currentSelectedDate).format(
      'YYYY-MM-DD'
    )}&reference_number=${id}`
  )

  if (response) {
    if (response.salesorders.length > 0) {
      return response.salesorders
    }
  }
  return []
}

export default medicineOrderSearchSlice.reducer
