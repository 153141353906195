import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'

import { LabReferralService } from 'models/labOfferDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { WelloMedication } from 'models/welloMedication'
import { ProductPage } from 'views/medicine/productPage'
import { getCurrentUserPractitionerDetails } from 'services/userDetailsService'
import { getMedicationNames } from 'utils/appointment_handle/medications_util'
import { CloseOutlined } from '@material-ui/icons'
import { Cart } from 'models/medicine-delivery/cart/cart-models'
import {
  cartAppointmentState,
  cartDoctorIdState,
  cartOrderForCachedState,
  cartPatientIdState,
  cartPatientObjectState,
  cartState,
} from 'models/medicine-delivery/cart/cart-states'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  selectedProductIdState,
  viewState,
} from 'models/medicine-delivery/product-states'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'
import { MedicinePage } from '../plan/medicine_order/medicinePage'

interface Props {
  open: boolean
  appointmentId: string
  medications: WelloMedication[]
  patientDetails?: R4.IPatient
  onClose?: () => void
  onMedicineOrdered?: () => void
}
export const OrderMedicine: React.FC<Props> = ({
  open,
  appointmentId,
  onClose,
  medications,
  patientDetails,
  onMedicineOrdered,
}) => {
  const { t } = useTranslation(['common'])
  const [cart, setCart] = useRecoilState<any>(cartState)
  const [cartPatientId, setCartPatientId] = useRecoilState(cartPatientIdState)
  const [cartPatient, setCartPatient] = useRecoilState(cartPatientObjectState)
  const cartDoctorId = useRecoilValue(cartDoctorIdState)
  //   const orderFor = useRecoilValue<any>(cartOrderForCachedState)
  const [orderFor, setOrderFor] = useRecoilState(cartOrderForCachedState)
  const [appointmentState, setAppointmentState] =
    useRecoilState(cartAppointmentState)
  const [selectedProductId, setSelectedProductId] = useRecoilState(
    selectedProductIdState
  )
  const setCartDoctorId = useSetRecoilState(cartDoctorIdState)

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  const [view, setView] = useRecoilState(viewState)
  const [payment, setPayment] = useState<'doctor' | 'patient'>()

  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '85%',
    },
    dialogCustomizedWidthFinal: {
      'max-width': '40%',
    },
  }))
  const classes = useStyles()

  useEffect(() => {
    if (appointmentState === undefined) {
      setAppointmentState(appointmentId)
      setCartDoctorId(getCurrentUserPractitionerDetails().id ?? '')
      setCart({ items: [], delivery: 0 } as Cart)
      setSelectedProductId(undefined)
      setView('productDetails')
      setOrderFor('clinic')
      setCartPatient(undefined)
      setCartPatientId(undefined)

      setPayment('doctor')
    } else if (appointmentState !== appointmentId) {
      setAppointmentState(appointmentId)
      setCartDoctorId(undefined)

      setCart({ items: [], delivery: 0 } as Cart)
      setSelectedProductId(undefined)
      setView('productDetails')
      setOrderFor('clinic')
      setCartPatient(undefined)
      setCartPatientId(undefined)

      setPayment('doctor')
    }
  }, [])

  return (
    <Dialog
      open={open}
      onClose={() => {
        setCart({ items: [], delivery: 0 } as Cart)
        if (onClose) onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{
        paperFullWidth: classes.dialogCustomizedWidth,
      }}
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        {' '}
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Order Medicine</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                if (cart.items.length === 0) {
                  setShowDeleteConfirmation({
                    open: true,
                    onContinueActionClicked: () => {
                      setShowDeleteConfirmation({ open: false })
                      setCart({ items: [], delivery: 0 } as Cart)
                      setView('productDetails')
                      setOrderFor('clinic')
                      setCartPatient(undefined)
                      setCartPatientId(undefined)
                      setAppointmentState(appointmentId)

                      setPayment('doctor')
                      if (onClose) onClose()
                    },
                    onCancelActionClicked: () => {
                      setShowDeleteConfirmation({ open: false })
                    },
                    displayText: 'Are you sure you want to close?',
                    continueActionButtonText: 'Close',
                    cancelActionButtonText: 'Cancel',
                  })
                }
                if (cart.items.length > 0) {
                  setShowDeleteConfirmation({
                    open: true,
                    onContinueActionClicked: () => {
                      setShowDeleteConfirmation({ open: false })
                      setCart({ items: [], delivery: 0 } as Cart)
                      setView('productDetails')
                      setOrderFor('clinic')
                      setCartPatient(undefined)
                      setCartPatientId(undefined)
                      setAppointmentState(appointmentId)

                      setPayment('doctor')
                      if (onClose) onClose()
                    },
                    onCancelActionClicked: () => {
                      setShowDeleteConfirmation({ open: false })
                    },
                    displayText: 'Are you sure you want to clear cart items?',
                    continueActionButtonText: 'Clear',
                    cancelActionButtonText: 'Cancel',
                  })
                }
              }}
              id='close_order'
            />
          </Box>
          {showDeleteConfirmation.open && (
            <ConfirmationDialog
              {...showDeleteConfirmation}
              id='delete'
              title={cart.items.length === 0 ? 'Warning' : 'Clear'}
            />
          )}
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#ededed',
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0,
        }}
      >
        <Box marginY={1} display='flex' flexDirection='column'>
          <MedicinePage
            key='ipd'
            doctorDetails={getCurrentUserPractitionerDetails()}
            patientDetails={patientDetails}
            isFromSoapNotes={true}
            medicationNames={getMedicationNames(medications)}
            onMedicineOrdered={() => {
              setAppointmentState(appointmentId)

              setCart({ items: [], delivery: 0 } as Cart)
              setSelectedProductId(undefined)
              setView('productDetails')
              setOrderFor('clinic')
              setCartPatient(undefined)
              setCartPatientId(undefined)
              setPayment('doctor')
              if (onMedicineOrdered) onMedicineOrdered()
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
