import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TherapyCategoryMap } from 'models/customMap'
import { TherapyDetail } from 'models/therapyDetail'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { TherapiesCategoryAndUrl } from 'utils/constants/therapiesNameAndUrls'
import { getTherapiesListFromBundle } from 'utils/fhirResoureHelpers/therapiesDetailHelper'
import { logger } from 'utils/logger'
import { NutritionCatalogueStatus as TherapiesCatalogueStatus } from './therapiesCatalogueStatus'

const initialState: TherapiesCatalogueStatus = {
  fetchingTherapies: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingTreatment: false,
}

const therapiesCatalogueSlice = createSlice({
  name: 'therapiesCatalogueSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<TherapiesCatalogueStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingTherapies = action.payload.fetchingTherapies
      state.resultsAvailable = action.payload.resultsAvailable
      state.catalogueEntries = action.payload.catalogueEntries
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingTreatment =
        action.payload.errorWhileSearchingTreatment
    },
  },
})

export const updateSessionTherapiesList =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: TherapiesCatalogueStatus = {
      fetchingTherapies: true,
      errorWhileSearchingTreatment: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(therapiesCatalogueSlice.actions.updatedStatus(state))
    try {
      const resMap: TherapyCategoryMap = {}
      await Promise.allSettled(
        TherapiesCategoryAndUrl.map(async (e) => {
          const res = await getTherapiesByUrl(
            e.url,
            getCurrentUserUnitDetails().id!
          )
          resMap[e.name] = res
          return {
            name: e.name,
            val: res,
          }
        })
      )

      state.resultsAvailable = true
      state.fetchingTherapies = false
      state.catalogueEntries = resMap
      state.noResultsAvailable = false
      state.errorReason = undefined
      state.errorWhileSearchingTreatment = false
      dispatch(therapiesCatalogueSlice.actions.updatedStatus(state))
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorSearchDoctor: TherapiesCatalogueStatus = {
        fetchingTherapies: false,
        errorWhileSearchingTreatment: true,
        resultsAvailable: false,
        errorReason: 'Error while searching Treatment Plan',
      }
      dispatch(therapiesCatalogueSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

async function getTherapiesByUrl(
  url: string,
  unitId: string
): Promise<TherapyDetail[]> {
  const searchParameter = {
    organization: `Organization/${unitId}`,
    _count: 1000,
    active: true,
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirClient.doGetResourceCrossPartition(
    url,
    searchParameter
  )

  const resp: R4.IBundle = response as R4.IBundle

  const therapisList = getTherapiesListFromBundle(resp)

  return therapisList
}

export default therapiesCatalogueSlice.reducer
