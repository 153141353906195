import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { searchingCatalogueDetailsLabTest } from 'redux/lab/catalougeSearch/catalougeSearchSlice'
import { RootState } from 'redux/rootReducer'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { WelloLoadingIndicator } from 'wello-web-components'
import { CatalogueDataTable } from './catalougeDataTable'

export interface IUnitAdmin {
  children?: React.ReactNode
}

export const CatalogueSearch: React.FC = () => {
  const catalogueSearchState = useSelector(
    (state: RootState) => state.catalougeSearchSlice
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<R4.IOrganization>(getCurrentUserUnitDetails())
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      currentOrganizationDetails.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  useEffect(() => {
    dispatch(searchingCatalogueDetailsLabTest())
    // dispatch(resetPatientSearchStatus())
  }, [dispatch])

  return (
    <Box width='100%' display='flex' flexDirection='column'>
      <Grid style={{ overflow: 'auto', maxHeight: '100%' }}>
        {catalogueSearchState.searching && (
          <Grid>
            <WelloLoadingIndicator message={t('labelCommon:searching')} />
          </Grid>
        )}

        {catalogueSearchState.resultsAvailable && (
          <Box width='100%' flexGrow={1} paddingTop={1}>
            {(loggedInUnitType === 'Lab' ||
              loggedInUnitType === 'Collection Center') && (
              <CatalogueDataTable
                testData={
                  catalogueSearchState.catalogueListView
                    ? catalogueSearchState.catalogueListView
                    : []
                }
              />
            )}
          </Box>
        )}
        {catalogueSearchState.noResultsAvailable && (
          <Typography> No Test/Package Data Available</Typography>
        )}

        {catalogueSearchState.error && (
          <Typography>
            {' '}
            {catalogueSearchState.errorMessage ??
              'Error while searching Test/Package . Pleas try again'}
          </Typography>
        )}
      </Grid>
    </Box>
  )
}
