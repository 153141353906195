export const console = ((oldCons: any) => {
  const drop: boolean = process.env.NODE_ENV === 'production'
  const newCons: any = { ...oldCons }
  window.console = oldCons
  if (drop) {
    oldCons.log(
      '%c%s',
      'color : red; font-size: 14px; padding: 6px',
      'CAUTION: This space is only for developers. Please do not copy-paste any scripts here.'
    )
    newCons.log = () => {}
    newCons.warn = () => {}
  }
  return newCons
})(window.console)

window.console = console
