import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import {
  requestForAppointmentCount,
  resetAppointmentForUnit,
} from 'redux/welloAdmin/appointmentCount/appointmentCountPerUnit'
import {
  requestForPaymentTotalForUnit,
  resetRevenueForUnit,
} from 'redux/welloAdmin/revenue/paymentSearchSlice'
import { searchUnitsForWelloAdmin } from 'redux/welloAdmin/unit/unitSearchWelloAdmin'
import {
  resetPatientSearchPatientForCount,
  searchPatientCount,
} from 'redux/welloAdmin/user/patientCount/patientCountSliceForUnit'
import {
  resetStateForWelloAdminPract,
  searchPractitionersForUnit,
} from 'redux/welloAdmin/user/practitionerSearchSliceAcrossUnit'
import {
  requestForWellnessAppointmentCount,
  resetWellnessAppointmentForUnit,
} from 'redux/welloAdmin/wellnessAppointmentCount/wellnessAppointmentCountPerUnit'
import { useWindowSize } from 'rooks'
import SimpleBar from 'simplebar-react'
import { wpAdminOptions } from 'utils/constants'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { desktopScreenWidth } from 'utils/screen_utils'
import { ClinicSplit } from './clinicSplit'
import { ClinicListDataTable } from './microComponent/clinicListDataTable'

// import { ReportDataTable } from './reportDataTable'
// import { AddTherapy } from './addTherapy';

export interface IUnitAdmin {
  orgType: string
  onTap?: () => void
  onAdd?: () => void
}

export const WpLabLandingPage: React.FC<IUnitAdmin> = ({
  orgType,
  onTap,
  onAdd,
}) => {
  const welloUnitsSearchSlice = useSelector(
    (state: RootState) => state.welloUnitsSearchSlice
  )

  const { innerWidth, innerHeight, outerHeight, outerWidth } = useWindowSize()

  const [name, setName] = React.useState<string>('')
  const [phone, setPhone] = React.useState<string>('')
  const [type, setType] = useState('ipd')

  const dispatch = useDispatch()

  const { width } = useWindowDimensions()
  const [selectedOrg, setSelectedOrg] = useState<R4.IOrganization>()
  const [mainOrg, setMainOrg] = useState<string>()
  const [selectedType, setSelectedType] = useState<string>(
    wpAdminOptions[0].value
  )

  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  useEffect(() => {
    dispatch(searchUnitsForWelloAdmin('lab'))
    // dispatch(requestIPDAppCountForToday(selectedDate))
  }, [])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            {welloUnitsSearchSlice.searching && (
              <Box
                display='flex'
                flexGrow={1}
                flexDirection='column'
                justifyContent='center'
                alignContent='center'
                overflow='auto'
              >
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              </Box>
            )}

            <Box
              display='flex'
              flexGrow={4}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
              style={{
                backgroundColor: '#ececec',
              }}
            >
              {welloUnitsSearchSlice.noResultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    align='center'
                  >
                    No data available
                  </Typography>
                </Box>
              )}
              {welloUnitsSearchSlice.error && (
                <Box
                  //   display='flex'
                  //   flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                  paddingLeft={50}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container justifyContent='center'>
                        <Typography
                          variant='subtitle1'
                          color='error'
                          align='center'
                        >
                          Error while searching. Please try again
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}

              {welloUnitsSearchSlice.resultsAvailable && (
                <SimpleBar
                  style={{
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    height='100%'
                  >
                    <Box
                      display='flex'
                      flexGrow={1}
                      overflow='auto'
                      height='100%'
                      //  paddingTop={0.2}
                    >
                      {/* <OrgLIst /> */}

                      <ClinicListDataTable
                        orgData={welloUnitsSearchSlice.unitsList ?? []}
                        onOrgSelected={(
                          orgDetailsData: R4.IOrganization,
                          mainOrgId: string
                        ) => {
                          setSelectedOrg(orgDetailsData)
                          const typeData =
                            getCodeOfSystemFromCodableConceptList(
                              orgDetailsData.type ?? [],
                              'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
                            )?.code ?? ''
                          setMainOrg(mainOrgId)
                          if (selectedType === 'users')
                            dispatch(resetPatientSearchPatientForCount())
                          dispatch(resetAppointmentForUnit())
                          dispatch(resetWellnessAppointmentForUnit())
                          dispatch(resetRevenueForUnit())
                          dispatch(
                            searchPractitionersForUnit(
                              `Organization/${orgDetailsData.id ?? ''}`
                            )
                          )
                          if (selectedType === 'patient') {
                            dispatch(resetStateForWelloAdminPract())
                            dispatch(resetAppointmentForUnit())
                            dispatch(resetWellnessAppointmentForUnit())
                            dispatch(resetRevenueForUnit())
                            dispatch(
                              searchPatientCount(
                                `Organization/${orgDetailsData.id ?? ''}`,
                                mainOrgId ?? ''
                              )
                            )
                          }
                          if (selectedType === 'appointments') {
                            dispatch(resetStateForWelloAdminPract())
                            dispatch(resetPatientSearchPatientForCount())
                            dispatch(resetRevenueForUnit())
                            if (typeData && typeData === 'clinic') {
                              dispatch(resetWellnessAppointmentForUnit())
                              dispatch(
                                requestForAppointmentCount(
                                  `Organization/${orgDetailsData.id ?? ''}`,
                                  getCodeOfSystemFromCodableConceptList(
                                    orgDetailsData.type ?? [],
                                    'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
                                  )?.code ?? ''
                                )
                              )
                            } else {
                              dispatch(resetAppointmentForUnit())
                              dispatch(
                                requestForWellnessAppointmentCount(
                                  `Organization/${orgDetailsData.id ?? ''}`
                                )
                              )
                            }
                          }

                          if (selectedType === 'orders') {
                            dispatch(resetStateForWelloAdminPract())
                            dispatch(resetPatientSearchPatientForCount())
                            dispatch(resetRevenueForUnit())
                            if (typeData && typeData === 'lab') {
                              dispatch(resetWellnessAppointmentForUnit())
                              dispatch(
                                requestForAppointmentCount(
                                  `Organization/${orgDetailsData.id ?? ''}`,
                                  getCodeOfSystemFromCodableConceptList(
                                    orgDetailsData.type ?? [],
                                    'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
                                  )?.code ?? ''
                                )
                              )
                            }
                          }
                          if (selectedType === 'revenue') {
                            dispatch(resetStateForWelloAdminPract())
                            dispatch(resetWellnessAppointmentForUnit())
                            dispatch(resetPatientSearchPatientForCount())
                            dispatch(resetAppointmentForUnit())
                            dispatch(
                              requestForPaymentTotalForUnit(
                                `Organization/${orgDetailsData.id ?? ''}`,
                                getCodeOfSystemFromCodableConceptList(
                                  orgDetailsData.type ?? [],
                                  'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
                                )?.code ?? ''
                              )
                            )
                          }
                        }}
                      />
                    </Box>
                    {selectedOrg && innerWidth! > desktopScreenWidth && (
                      <Box
                        display='flex'
                        flexGrow={4}
                        overflow='auto'
                        minWidth={`${width - 250}px`}
                        height='100%'
                        flexDirection='column'
                      >
                        <ClinicSplit
                          org={selectedOrg}
                          mainOrgId={mainOrg ?? ''}
                          type={orgType}
                          onTypeSelected={(id: string) => {
                            setSelectedType(id)
                          }}
                          onCloseClicked={() => {
                            dispatch(resetStateForWelloAdminPract())
                            dispatch(resetPatientSearchPatientForCount())
                            dispatch(resetRevenueForUnit())
                            dispatch(resetWellnessAppointmentForUnit())
                            dispatch(resetAppointmentForUnit())
                            setSelectedOrg(undefined)
                            setMainOrg(undefined)
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </SimpleBar>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </div>
  )
}
