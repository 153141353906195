import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import moment from 'moment'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { repeatRadioList } from 'utils/constants'
import { logger } from 'utils/logger'
import { RepeatOptions } from '../LeftMenu/RepeatOptions'
import { WelloSelect } from '../LeftMenu/WelloSelect'
import { TabbedMultiSelector, TabOption } from '../toggle_multiSelector'
import { RepeatSettings } from './RepeatSettings'

interface Props {
  open: boolean
  onClose: () => void
  onDiscard: (
    selectedAppointmentTypes: string[],
    selectedDate: any,
    eventDate: any,
    occurrence: any,
    repeat: any,
    week: any,
    repeatLabel: string,
    customRepeat: string
  ) => void
  type: any
  day: any
  repeat: string
  repeatString: string
  occurTimes: string
  repeatOn: Date
  defaultValue: number
  personName: string
}

const menu: TabOption[] = [
  { value: 'In-Person', displayText: 'In-Person' },
  { value: 'Phone', displayText: 'Phone' },
  { value: 'Video', displayText: 'Video' },
]

export const EditScheduleTile: React.FC<Props> = ({
  open,
  onClose,
  onDiscard,
  type,
  day,
  repeat,
  repeatString,
  occurTimes,
  repeatOn,
  defaultValue,
  personName,
}) => {
  const data: any = []
  const weekNumber = moment(day).week()
  const schedulerList = [
    {
      id: `${moment(day).toDate()}`,
      title: `Every ${moment(day).format('dddd')}`,
    },
    { id: `daily`, title: `Daily` },
    { id: `no`, title: `No Repeat` },
    { id: `custom`, title: `Custom` },
  ]

  if (type.length === 1) data.push(type[0].trim())
  if (type.length === 2) {
    data.push(type[0].trim())
    data.push(type[1].trim())
  }
  if (type.length === 3) {
    data.push(type[0].trim())
    data.push(type[1].trim())
    data.push(type[2].trim())
  }
  const { t } = useTranslation()

  const [selectedTab, setSelectedTab] = React.useState<string[]>(data)
  const [appointmentType1, setAppointmentType1] = React.useState<any[]>(data)
  const [selectedDate, setSelectedDate] = React.useState<any>()
  const [eventDate, setEventDate] = React.useState<any[]>()
  const [occurrence, setOccurrence] = React.useState<any>()
  const [dropDownOptions, setDropDownOptions] =
    React.useState<any[]>(schedulerList)
  const [repeatLabel, setRepeatLabel] = React.useState<string>('')
  const [customRepeat, setCustomRepeat] = React.useState(false)
  const [customLabel, setCustomLabel] = React.useState<string>('')
  const [repeatData, setRepeatData] = React.useState<any>()
  const [range, setRange] = React.useState<any>()
  const [showOption, setShowOption] = React.useState(
    repeat.trim() !== 'Does not repeat'
  )
  const [occurData, setOccurData] = React.useState<any>(
    occurTimes === undefined ? 12 : occurTimes
  )

  const [repeatOnData, setRepeatOnData] = React.useState<any>(
    repeatOn === undefined ? day : repeatOn
  )

  //   let repeatOnData = repeatOn === undefined ? day : repeatOn
  //   let occurData = occurTimes === undefined ? 12 : occurTimes

  const [occurValue, setOccurValue] = React.useState<any>()
  const [repeatOnValue, setRepeatOnValue] = React.useState<any>()
  const customChange = useRef<boolean>(false)
  const serverChange = useRef<boolean>(false)
  //   const [customChange, setCustomChange] =(false)
  const [selected, setSelected] = React.useState<any>(
    repeatString === undefined ? '' : repeatString.trim()
  )

  const [customString, setCustomString] = React.useState<string>(repeat)
  const [custom, setCustom] = React.useState<boolean>()
  let preSelectedDate = getPreConfiguredDate(day, false)

  function getPreConfiguredDate(date: Date, isChange: boolean): any {
    if (
      repeat.includes('every day') ||
      repeat.includes('Repeats every 2 days') ||
      repeat.includes('every 2 days') ||
      repeat.includes('every 3 days') ||
      repeat.includes('every 4 days') ||
      repeat.includes('every 5 days') ||
      repeat.includes('every 6 days')
    ) {
      serverChange.current = true
      return dropDownOptions[3]
    }

    if (repeat.includes('every day')) {
      return dropDownOptions[1]
    }
    if (moment(day).isSame(moment(date)) && repeat.includes('everyday')) {
      return dropDownOptions[1]
    }
    if (
      moment(day).isSame(moment(date)) &&
      repeat.trim() === 'Does not repeat'
    ) {
      return dropDownOptions[2]
    }

    if (moment(day).isSame(moment(date)) && repeat.trim() !== 'everyday') {
      return dropDownOptions[0]
    }
    if (
      moment(day).isAfter(moment(date)) ||
      moment(day).isBefore(moment(date))
    ) {
      if (isChange === false) return dropDownOptions[3]
      return dropDownOptions[4]
    }
    setRepeatOnData(moment(day).add(30, 'days').toDate())
    return dropDownOptions[2]
  }
  const daysOfWeek = ['M', 'T', 'W', 'Th', 'F', 'S', 'Su']

  function customSort(a: string, b: string) {
    return daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b) // basic sort function that compares the indexes of the two days
  }
  function onDateChanged(selectedDateDef: Date, isChange: boolean) {
    preSelectedDate = getPreConfiguredDate(selectedDateDef, isChange)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          onClose()
        }}
        aria-labelledby='responsive-dialog-title'
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle id='scroll-dialog-title'>
          {t('Working Hours Settings')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={3} justify='flex-start' direction='column'>
            <Grid item>
              {personName === 'doctor' && (
                <Box paddingX={6}>
                  <TabbedMultiSelector
                    menuLabel={t('Appointment Types')}
                    onSelectionChanged={(value: any) => {
                      setSelectedTab(value)
                      setAppointmentType1(selectedTab)
                      logger.info(selectedTab)
                    }}
                    options={menu}
                    preSelectedValues={selectedTab}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xl={12}>
              <Box paddingLeft={6} width='50%'>
                {customChange.current === false && (
                  <WelloSelect
                    key={preSelectedDate.id}
                    label={t('REPEATS')}
                    availableOptions={dropDownOptions}
                    required={true}
                    type='Repeat'
                    displayField='title'
                    valueField='id'
                    width={200}
                    preSelectedOption={preSelectedDate}
                    onSelectionChanged={(value: any) => {
                      if (value.id.includes('daily')) {
                        setRepeatOnValue(moment(day).add(30, 'days').toDate())
                        setRepeatOnData(moment(day).add(30, 'days').toDate())
                        setOccurData(30)
                      }
                      if (value.id === 'no') setShowOption(false)
                      else setShowOption(true)
                      if (value.id === 'custom') {
                        setCustomRepeat(true)
                      } else {
                        onDateChanged(value.id, false)
                        setEventDate(value.id)
                        setRepeatData(undefined)
                        setRange(undefined)
                      }
                    }}
                  />
                )}

                {customChange.current === true && (
                  <WelloSelect
                    key={preSelectedDate.id}
                    label={t('REPEATS')}
                    availableOptions={dropDownOptions}
                    required={true}
                    type='Repeat'
                    displayField='title'
                    valueField='id'
                    width={200}
                    preSelectedOption={preSelectedDate}
                    onSelectionChanged={(value: any) => {
                      setCustom(false)

                      if (value.id === 'no') setShowOption(false)
                      else setShowOption(true)
                      if (value.id === 'custom') {
                        setCustomRepeat(true)
                      } else {
                        onDateChanged(value.id, false)
                        setEventDate(value.id)
                        setRepeatData(undefined)
                        setRange(undefined)
                      }
                    }}
                  />
                )}
              </Box>
              {custom === true && (
                <Box display='flex' flexDirection='row' alignItems='center'>
                  <Box paddingLeft={6.7}>
                    <Typography variant='body2'>{customString}</Typography>
                  </Box>
                  <Box justifyContent=''>
                    <Button
                      variant='text'
                      onClick={() => {
                        setCustomRepeat(true)
                        customChange.current = false
                      }}
                    >
                      Change
                    </Button>
                  </Box>
                </Box>
              )}
              {serverChange.current === true && (
                <Box display='flex' flexDirection='row' alignItems='center'>
                  <Box paddingLeft={6.7}>
                    <Typography variant='body2'>{customString}</Typography>
                  </Box>
                  <Box justifyContent=''>
                    <Button
                      variant='text'
                      onClick={() => {
                        setCustomRepeat(true)
                        customChange.current = false
                      }}
                    >
                      Change
                    </Button>
                  </Box>
                </Box>
              )}
            </Grid>

            <Grid item xl={12}>
              <Box paddingLeft={7}>
                {showOption === true && customChange.current === false && (
                  <RepeatOptions
                    name='ends'
                    label={t('Ends')}
                    items={repeatRadioList}
                    preSelectedOption={
                      occurTimes === undefined && repeatOn === undefined
                        ? repeatRadioList[0]
                        : repeatOn === undefined
                        ? repeatRadioList[2]
                        : repeatRadioList[1]
                    }
                    onDateChange={(date: Date) => {
                      setSelectedDate(date)
                      setRepeatOnValue(date)
                      setRepeatOnData(date)
                      setOccurrence(undefined)
                    }}
                    onOccurrenceChange={(occur: any) => {
                      setOccurrence(occur)
                      setSelectedDate(undefined)
                    }}
                    onNeverChange={() => {
                      setOccurrence(undefined)
                      setSelectedDate(undefined)
                    }}
                    date={repeatOnData}
                    occur={customChange.current === false ? occurData : 13}
                    max={occurData}
                  />
                )}

                {showOption === true && customChange.current === true && (
                  <RepeatOptions
                    name='ends'
                    label={t('Ends')}
                    items={repeatRadioList}
                    preSelectedOption={
                      occurTimes === undefined && repeatOn === undefined
                        ? repeatRadioList[0]
                        : repeatOn === undefined
                        ? repeatRadioList[2]
                        : repeatRadioList[1]
                    }
                    onDateChange={(date: Date) => {
                      setSelectedDate(date)
                      setOccurrence(undefined)
                    }}
                    onOccurrenceChange={(occur: any) => {
                      setOccurrence(occur)
                      setSelectedDate(undefined)
                    }}
                    onNeverChange={() => {
                      setOccurrence(undefined)
                      setSelectedDate(undefined)
                    }}
                    date={repeatOnValue}
                    occur={occurValue}
                    max={occurValue}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose()
            }}
            variant='outlined'
            disableElevation
          >
            <Typography variant='button'>{t('Cancel')}</Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            onClick={() => {
              onDiscard(
                appointmentType1,
                selectedDate,
                eventDate,
                occurrence,
                repeatData,
                range,
                repeatLabel,
                customLabel
              )
            }}
            variant='contained'
            color='primary'
          >
            <Typography variant='button'>{t('Done')}</Typography>
          </Button>
        </DialogActions>
      </Dialog>
      {customRepeat === true && (
        <RepeatSettings
          open={customRepeat}
          day={day}
          repeat={repeat}
          repeatDays={selected}
          customString={customString}
          customLabel={customLabel}
          repeatValData={repeatData}
          onClose={() => {
            preSelectedDate = getPreConfiguredDate(day, false)
            setCustomRepeat(false)
            setRepeatData(undefined)
            setRange(undefined)
          }}
          onDiscard={(
            customDay: string[],
            repeatValue: number,
            week: string,
            repeatOnString: any,
            isCustom: boolean
          ) => {
            setCustom(isCustom)
            repeatOnString.sort()
            if (week === 'Weekly') {
              setOccurValue(4)
              setRepeatOnValue(moment(day).add(30, 'days').toDate())
            } else if (week === 'Day') {
              setOccurValue(30)
              setRepeatOnValue(moment(day).add(30, 'days').toDate())
            } else {
              setOccurValue(1)
              setRepeatOnValue(moment(day).add(0, 'days').toDate())
            }
            setCustomLabel(repeatOnString)
            setRepeatData(repeatValue)
            setRange(week)
            const weekData: Date[] = []
            const selectedDates: Date[] = []
            customDay.sort(customSort)
            if (week !== 'Day') {
              for (let i = 0; i < customDay.length; i++) {
                if (customDay[i] === 'W') {
                  weekData.push(
                    moment().day('WednesDay').week(weekNumber).toDate()
                  )
                  selectedDates.push(
                    moment().day('WednesDay').week(weekNumber).toDate()
                  )
                }
                if (customDay[i] === 'S') {
                  weekData.push(
                    moment().day('Saturday').week(weekNumber).toDate()
                  )
                  selectedDates.push(
                    moment().day('Saturday').week(weekNumber).toDate()
                  )
                }

                if (customDay[i] === 'Su') {
                  weekData.push(
                    moment().day('Sunday').week(weekNumber).toDate()
                  )
                  selectedDates.push(
                    moment().day('Sunday').week(weekNumber).toDate()
                  )
                }

                if (customDay[i] === 'M') {
                  weekData.push(
                    moment().day('Monday').week(weekNumber).toDate()
                  )
                  selectedDates.push(
                    moment().day('Monday').week(weekNumber).toDate()
                  )
                }

                if (customDay[i] === 'T') {
                  weekData.push(
                    moment().day('Tuesday').week(weekNumber).toDate()
                  )
                  selectedDates.push(
                    moment().day('Tuesday').week(weekNumber).toDate()
                  )
                }

                if (customDay[i] === 'Th') {
                  weekData.push(
                    moment().day('Thursday').week(weekNumber).toDate()
                  )
                  selectedDates.push(
                    moment().day('Thursday').week(weekNumber).toDate()
                  )
                }

                if (customDay[i] === 'F') {
                  selectedDates.push(
                    moment().day('Friday').week(weekNumber).toDate()
                  )
                  weekData.push(
                    moment().day('Friday').week(weekNumber).toDate()
                  )
                }
              }
            }
            let appendString = ' '
            if (week === 'Weekly' && repeatOnString !== 'All') {
              if (repeatValue === 1) {
                appendString = 'Every week '
              } else {
                appendString = `Every ${repeatValue} weeks on `
              }
            } else if (
              repeatOnString.includes('All') === false &&
              week === 'Month'
            ) {
              appendString = `Every Month ${repeatOnString.join(', ')}`
            } else if (repeatOnString.includes('All') && week === 'Month') {
              appendString = `Every Month `
            } else if (repeatOnString.includes('All') && week === 'Day') {
              if (repeatValue === 1) {
                appendString = `Repeats everyday`
              } else {
                appendString = `Repeats every ${repeatValue} days`
              }
            }

            let dateString = ''
            const labelString: string[] = []
            for (let j = 0; j < weekData.length; j++) {
              //   appendString += `${`${moment(weekData[j]).format('dddd')}, `}`
              labelString.push(`${`${moment(weekData[j]).format('dddd')}`}`)
            }
            for (let k = 0; k < selectedDates.length; k++) {
              dateString += `${`${moment(selectedDates[k]).toDate()}, `}`
            }
            schedulerList.push({
              id: `${dateString}`,
              title: (appendString += ` ${labelString.join(', ')}`),
            })
            const repeatNum = repeatValue > 1 ? repeatValue : ''
            // setRepeatLabel(`Every  ${labelString.join(', ')}`)
            if (week === 'Weekly') {
              if (repeatValue === 1) {
                setCustomString(`Every week on ${labelString.join(', ')}`)
                setRepeatLabel(`Every week on ${labelString.join(', ')}`)
              } else {
                setCustomString(
                  `Every ${repeatNum} weeks on ${labelString.join(', ')}`
                )
                setRepeatLabel(
                  `Every ${repeatNum} weeks on ${labelString.join(', ')}`
                )
              }
            } else if (week === 'Day') {
              if (repeatValue === 1) {
                setCustomString(`Repeats everyday`)
              } else {
                setCustomString(`Repeats every ${repeatValue} days `)
              }
            } else {
              repeatOnString.includes('All')
                ? setCustomString(`Every Month ${labelString.join(', ')}`)
                : setCustomString(
                    `Every Month ${repeatOnString.join(
                      ', '
                    )} ${labelString.join(', ')}`
                  )
              repeatOnString.includes('All')
                ? setRepeatLabel(`Every Month ${labelString.join(', ')}`)
                : setRepeatLabel(`${labelString.join(', ')}`)
            }
            setEventDate(selectedDates)
            setDropDownOptions(schedulerList)
            setSelected(`Every ${labelString.join(', ')}`)
            setCustomRepeat(false)
            customChange.current = true
          }}
        />
      )}
    </div>
  )
}
