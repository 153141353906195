import { Box, Typography } from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React from 'react'

interface MedicationTileProps {
  medication: WelloMedication
  onDeleteClicked?: () => {}
  onEditCLicked?: () => {}
}

export const MedicationTileTimeLine: React.FC<MedicationTileProps> = ({
  medication,
  onDeleteClicked,
  onEditCLicked,
}: MedicationTileProps) => (
  <Box display='flex' flexDirection='row'>
    <Typography color='initial' style={{ padding: '4px' }}>
      {medication.medicationCode!.display ??
        medication.medicationCode!.code ??
        'Name'}
    </Typography>

    <Typography color='initial' style={{ padding: '4px' }}>
      {` (${medication.dosageQuantity} ${medication.dosageQuantityType} )` +
        ' '}
    </Typography>

    <Typography color='initial' style={{ padding: '4px' }}>
      {` From  ${moment(medication.startFrom).format('DD MMM')} ` + ' '}
    </Typography>

    <Typography color='initial' style={{ padding: '4px' }}>
      {` Till  ${moment(medication.till).format('DD MMM ')} ` + ' '}
    </Typography>
  </Box>
)
