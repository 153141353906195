import { R4 } from '@ahryman40k/ts-fhir-types'

import {
  Box,
  IconButton,
  makeStyles,
  Switch,
  Tab,
  Tabs,
  withStyles,
  Typography,
  Chip,
  Divider,
  Button,
} from '@material-ui/core'
import {
  Close,
  ClosedCaptionOutlined,
  CloseOutlined,
  CloseRounded,
  CloseSharp,
  Email,
  Phone,
} from '@material-ui/icons'
import {
  kBackGround,
  kBackgroundPaper,
  kDialogueBackground,
  kPrimaryDark,
  kSelectedTabBackground,
} from 'configs/styles/muiThemes'

import { MembershipDetails } from 'models/membershipDetails'
// import { kDialogueBackground } from 'configs/styles/muiThemes'
import React from 'react'
import { getTelecomFromContactPoints } from 'utils/fhirResourcesHelper'
import { TabPanel } from 'views/components/LeftMenu/tabPanel'
import { MembershipPlanTransactions } from './membershipPlanTransactions'
import { MembershipSummaryPage } from './membershipSummary'

const useStyles = makeStyles({
  bigIndicator: {
    width: '10px',
  },
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      //   padding: 4,
    },
  },
})

interface MembershipDetailsProperties {
  usersData: MembershipDetails
  onCloseClicked: () => void
}

export const MembershipDetailsPage: React.FC<MembershipDetailsProperties> = ({
  usersData,
  onCloseClicked,
}: MembershipDetailsProperties) => {
  const classes = useStyles()

  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      overflow='auto'
      width='100%'
      height='100%'
      bgcolor={kBackgroundPaper}
    >
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignContent='start'
        alignItems='start'
        width='100%'
        px={2}
        pt={2}
      >
        <Box
          display='flex'
          flexDirection='column'
          overflow='auto'
          width='100%'
          bgcolor={kBackgroundPaper}
        >
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            width='100%'
          >
            <Box
              display='flex'
              flexDirection='column'
              overflow='auto'
              width='100%'
            >
              <Box
                display='flex'
                flexDirection='row'
                alignContent='center'
                alignItems='center'
              >
                <Typography variant='h5' color='initial'>
                  {usersData.memberName}
                </Typography>
                <Chip
                  label={usersData.planName}
                  color='primary'
                  size='small'
                  style={{
                    marginLeft: 8,
                    marginRight: 8,
                    padding: 0,
                    fontSize: 10,
                  }}
                />
              </Box>
              <Box display='flex' flexDirection='row' py={0.25}>
                <Typography variant='subtitle1' color='initial'>
                  {usersData.memberShipPlanId}
                </Typography>
              </Box>
              <Box display='flex' flexDirection='row'>
                <Box
                  display='flex'
                  flexDirection='row'
                  alignContent='center'
                  alignItems='center'
                >
                  <Phone
                    fontSize='small'
                    style={{
                      fontSize: 14,
                    }}
                  />
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    style={{ paddingLeft: 4 }}
                  >
                    {` ${getTelecomFromContactPoints(
                      usersData.mainMember.telecom ?? [],
                      R4.ContactPointSystemKind._phone
                    )}   `}
                  </Typography>
                </Box>

                {getTelecomFromContactPoints(
                  usersData.mainMember.telecom ?? [],
                  R4.ContactPointSystemKind._email
                ) && (
                  <Box
                    display='flex'
                    flexDirection='row'
                    px={1}
                    alignContent='center'
                    alignItems='center'
                  >
                    <Typography variant='body1' color='initial'>
                      <Email
                        fontSize='small'
                        style={{
                          fontSize: 14,
                        }}
                      />
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      style={{ paddingLeft: 4 }}
                    >
                      {getTelecomFromContactPoints(
                        usersData.mainMember.telecom ?? [],
                        R4.ContactPointSystemKind._email
                      )}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            width='100%'
          >
            <Tabs
              value={value}
              indicatorColor='primary'
              textColor='primary'
              onChange={handleChange}
              variant='scrollable'
              scrollButtons='auto'
              classes={{ indicator: classes.bigIndicator }}
            >
              <Tab key='details' label='Details' />
              <Tab key='transactions' label='Transactions' />
            </Tabs>
          </Box>
        </Box>
        {/* <Button
          variant='contained'
          color='primary'
          size='small'
          style={{
            marginLeft: 8,
            marginRight: 4,
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          size='small'
          style={{
            marginLeft: 4,
            marginRight: 8,
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          Renew
        </Button>
 */}
        <IconButton
          aria-label='close membership details'
          size='small'
          style={{
            padding: 2,
            margin: 0,
            borderRadius: 4,
            border: `1px solid ${kPrimaryDark}`,
          }}
          onClick={() => {
            onCloseClicked()
          }}
        >
          <Close fontSize='small' color='primary' />
        </IconButton>
      </Box>
      <Divider />
      <Box
        style={{
          overflow: 'auto',
        }}
        width='100%'
        height='100%'
        bgcolor={kDialogueBackground}
      >
        {value === 0 && (
          <TabPanel value={value} index={0} key='details'>
            <MembershipSummaryPage
              usersData={usersData}
              key={` summary_${usersData.coverageRaw.id ?? ''}`}
            />
          </TabPanel>
        )}
        {value === 1 && (
          <TabPanel value={value} index={1} key='transactions'>
            <MembershipPlanTransactions
              usersData={usersData.coverageRaw}
              key={` transactions_${usersData.coverageRaw.id ?? ''}`}
            />
          </TabPanel>
        )}
      </Box>
    </Box>
  )
}
