import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import {
  getPatientDetailsByContactId,
  getPaymentMessage,
} from 'utils/appointment_handle/medications_util'
import { getCompleteDateStringForOrderLandingPage } from 'utils/dateUtil'
import {
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getProfilePicPatient,
  getTelecomOfPatient,
} from 'utils/fhirResourcesHelper'
import {
  getOrderDate,
  getOrderStartDate,
} from 'utils/fhirResoureHelpers/ipdHelper'
import {
  getOrderFinalStatusText,
  getOrderTime,
  getOrderTypeDisplayCode,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import { GenderAgeTile } from 'views/components/patients/genderAgeTile'
import { GenderAndAgeTileWithoutBack } from 'views/components/patients/patient_profile/genderAgeTilleWithoutBackGround'

interface Props {
  labOrderDetail: any
  isOdd?: boolean
  onTap?: (patientData?: R4.IPatient) => void
  dense?: boolean
  isSelected?: boolean
}

export const MedicineOrderTile: React.FC<Props> = ({
  labOrderDetail,
  isOdd = false,

  onTap,

  dense = false,
  isSelected = false,
}: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [patientData, setPatientData] = useState<R4.IPatient>()

  const [loading, setLoading] = useState<boolean>(false)
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  function getPatientDetails(contactId: string) {
    setUpdateStatus({ requesting: true })

    setLoading(true)

    getPatientDetailsByContactId(contactId)
      .then((e: R4.IPatient | undefined) => {
        setLoading(false)
        if (e) {
          setPatientData(e)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          //   dispatch(
          //     showErrorAlert(
          //       'Error while fetching patient details. Please try again later'
          //     )
          //   )
        }
      })
      .catch((e) => {
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  useEffect(() => {
    // i18n.changeLanguage(language ?? '')

    getPatientDetails(labOrderDetail.customer_id)
  }, [])

  return (
    <Box width='100%'>
      <Card
        elevation={isOdd ? 0 : 1}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 0,
          backgroundColor: isSelected
            ? kPrimaryLight
            : isOdd
            ? '#FFFFFFAF'
            : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 0,
          }}
        >
          {dense && (
            <Box display='flex' flexDirection='row' width='100%' height={30}>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='50%'
              >
                <Typography
                  variant='subtitle2'
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',

                    lineHeight: 'normal',
                  }}
                >
                  Date :{' '}
                  {getCompleteDateStringForOrderLandingPage(
                    labOrderDetail.created_time
                  )}
                </Typography>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='50%'
              >
                <Typography
                  variant='subtitle2'
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',

                    lineHeight: 'normal',
                  }}
                >
                  Order ID : {labOrderDetail.salesorder_id}
                </Typography>
              </Box>
            </Box>
          )}
          {!dense && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              alignContent='center'
              p={0.5}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignContent='flex-start'
                width='35%'
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-start'
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-start'
                    width='40%'
                    py={1.5}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='start'
                      px={1}
                    >
                      <Typography
                        variant='subtitle1'
                        color='primary'
                        style={{
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        Date :{' '}
                      </Typography>
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='start'
                      paddingTop={0.2}
                    >
                      <Typography variant='subtitle2' color='initial'>
                        {getCompleteDateStringForOrderLandingPage(
                          labOrderDetail.created_time
                        )}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='5%'
                  >
                    {loading && (
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                      >
                        <CircularProgress size={20} />
                      </Box>
                    )}
                  </Box>
                  {loading === false && patientData && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                    >
                      <Avatar
                        alt={getNameOfPatient(patientData) ?? ''}
                        src={getProfilePicPatient(patientData) ?? ''}
                      />
                    </Box>
                  )}
                  {loading === false && patientData && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      alignContent='flex-start'
                      width='50%'
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-start'
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='start'
                          px={1}
                          paddingTop={1}
                        >
                          {getMiddleNameOfPatient(patientData).length > 0 && (
                            <Typography
                              variant='subtitle1'
                              color='initial'
                              style={{
                                textTransform: 'capitalize',
                                color: 'black',
                                fontWeight: 'bold',
                              }}
                            >
                              {getNameOfPatient(patientData)}{' '}
                              {getMiddleNameOfPatient(patientData) ?? ''}{' '}
                              {getLastNameOfPatient(patientData)}
                            </Typography>
                          )}

                          {getMiddleNameOfPatient(patientData).length === 0 && (
                            <Typography
                              variant='subtitle1'
                              color='initial'
                              style={{
                                textTransform: 'capitalize',
                                color: 'black',
                                fontWeight: 'bold',
                              }}
                            >
                              {getNameOfPatient(patientData)}{' '}
                              {getMiddleNameOfPatient(patientData) ?? ''}{' '}
                              {getLastNameOfPatient(patientData)}
                            </Typography>
                          )}
                        </Box>

                        <Box
                          display='flex'
                          flexDirection='column'
                          width='45%'
                          justifyContent='flex-start'
                          px={1}
                        >
                          <GenderAndAgeTileWithoutBack
                            patient={patientData}
                            status={false}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='25%'
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-start'
                >
                  <Box paddingRight={1}>
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                    >
                      Order ID :
                    </Typography>
                  </Box>
                  <Typography variant='subtitle2' color='initial'>
                    {`${labOrderDetail.salesorder_id}`}
                  </Typography>
                </Box>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='20%'
                paddingX={1}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-start'
                >
                  <Box paddingRight={1}>
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                    >
                      Total Payment :
                    </Typography>
                  </Box>
                  <Typography variant='subtitle2' color='initial'>
                    &#x20b9; {`${labOrderDetail.total.toFixed(2)}`}
                  </Typography>
                </Box>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='10%'
              >
                <Typography variant='subtitle2' color='initial'>
                  {getPaymentMessage(labOrderDetail.status)}
                </Typography>
              </Box>

              <Box
                display='flex'
                flexDirection='row'
                justifyContent='center'
                width='10%'
                px={2}
              >
                {loading && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}
                {loading === false && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='30%'
                  >
                    <Tooltip title='View' id='view'>
                      <IconButton
                        aria-label='btn_ord_reschedule'
                        color='primary'
                        onClick={() => {
                          if (onTap) onTap(patientData)
                        }}
                        id='order_view_icon'
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/view.ico`}
                          alt='view'
                          id='view_img'
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}
