import { Box, CircularProgress, Grid, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getInstructionsOfIPD } from 'redux/ipd/instructions_list_slice_ipd/instructionsListSliceIPD'
import { RootState } from 'redux/rootReducer'
import { InstructionsTableTile } from 'views/components/consultation/assessment/instructionsTabularTile'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'

interface Props {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
}

export const CarePlanInstructionListIPD: React.FC<Props> = ({
  fhirAppointmentDetails,
}: Props) => {
  const initialFetch = useRef(true)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()

  const instructionsListSlice = useSelector(
    (state: RootState) => state.instructionsListSliceIPD
  )

  useEffect(() => {
    dispatch(getInstructionsOfIPD(fhirAppointmentDetails))
  }, [dispatch, fhirAppointmentDetails])

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {(instructionsListSlice.updatingImpressions ||
        instructionsListSlice.fetchingImpressions) && (
        <CircularProgress size={15} />
      )}
      {instructionsListSlice.noResultsAvailable && (
        <Box marginY={1} display='flex' flexDirection='column' width='100%'>
          <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
            No data available
          </Typography>
        </Box>
      )}
      {instructionsListSlice.lanDiagnosticRequests &&
        instructionsListSlice.lanDiagnosticRequests.length > 0 && (
          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box display='flex' flexDirection='column' width='100%'>
                <InstructionsTableTile
                  instructions={instructionsListSlice.lanDiagnosticRequests}
                />
              </Box>
            </Grid>
          </Grid>
        )}
      <NoteDialog
        id='notesTile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        dialogText='Instruction'
        notesData={fullText!}
      />
    </Box>
  )
}
