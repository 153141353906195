/* eslint-disable no-irregular-whitespace */
import { Box, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import 'react-vis/dist/style.css'
import { ObservationOCAForViewOnly } from './doshaVikruthiAssemment/doshaAssessmentFromHistory'

interface CardioVascularExaminationsListProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  isReadOnly?: boolean
}

export const DoshaVikruthiAssessment: React.FC<CardioVascularExaminationsListProps> =
  ({
    fhirAppointmentDetails,
    isReadOnly = false,
  }: CardioVascularExaminationsListProps) => {
    const { t } = useTranslation(['common'])

    const dispatch = useDispatch()

    return (
      <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
        <Box display='flex' flexGrow={1}>
          <Box
            borderRadius={2}
            style={{
              backgroundColor: 'lightGrey',
            }}
            display='flex'
            flexDirection='row'
            width='100%'
            height={40}
            px={1}
          >
            <Box
              justifyContent='flex-start'
              display='flex'
              flexGrow={1}
              alignItems='center'
            >
              <Typography
                variant='subtitle1'
                style={{
                  color: 'black',

                  fontWeight: 'bold',
                }}
              >
                Dosha Vikruthi Assessment
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' width='100%'>
            <Box
              borderRadius={2}
              display='flex'
              flexDirection='row'
              width='100%'
              px={1}
            >
              <Box
                justifyContent='flex-start'
                display='flex'
                flexGrow={1}
                alignItems='center'
              >
                <Typography
                  variant='subtitle1'
                  style={{
                    color: 'black',

                    fontWeight: 'bold',
                  }}
                >
                  Subjective
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display='flex' flexDirection='column' width='100%' px={1}>
            <Box width='100%'>
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='subjective'
                formName='Interpretation Dosha from History Dosha Status_OCA'
                observationCode='C0262926'
                categoryCode='C4045976'
                isReadOnly={isReadOnly}
              />
            </Box>
            <Box width='100%'>
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='subjective'
                formName='Interpretation Dosha from Personal Information Dosha Status_OCA'
                observationCode='C0424945'
                categoryCode='C4045976'
                isReadOnly={isReadOnly}
              />
            </Box>
            <Box width='100%'>
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='subjective'
                formName='Interpretation_Dosha_From Environmental Status_Dosha Status_5.1.3.1_OCA'
                observationCode='C0014406'
                categoryCode='C4045976'
                isReadOnly={isReadOnly}
              />
            </Box>
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' width='100%'>
            <Box
              borderRadius={2}
              display='flex'
              flexDirection='row'
              width='100%'
              px={1}
            >
              <Box
                justifyContent='flex-start'
                display='flex'
                flexGrow={1}
                alignItems='center'
              >
                <Typography
                  variant='subtitle1'
                  style={{
                    color: 'black',

                    fontWeight: 'bold',
                  }}
                >
                  Objective
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display='flex' flexDirection='column' width='100%' px={1}>
            <Box width='100%'>
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='objective'
                formName='Dosha Vikruti Evaluation from General Examination & Vitals Dosha Status_OCA'
                observationCode='C0438106'
                categoryCode='C0582103'
                isReadOnly={isReadOnly}
              />
            </Box>

            <Box width='100%'>
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='objective'
                formName='Dosha Vikruti Evaluation from Sthanas Dosha Status_OCA'
                observationCode='C2983609'
                categoryCode='C0582103'
                isReadOnly={isReadOnly}
              />
            </Box>
            <Box width='100%'>
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='objective'
                formName='Dosha Vikruti Evaluation from  Srotas Dosha Status_OCA'
                observationCode='C0687028'
                categoryCode='C0582103'
                isReadOnly={isReadOnly}
              />
            </Box>
            <Box width='100%'>
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='objective'
                formName='Dosha Vikruti Evaluation from Lab Investigations Dosha Status_OCA'
                observationCode='C2711906'
                categoryCode='C0582103'
                isReadOnly={isReadOnly}
              />
            </Box>
            <Box width='100%'>
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='objective'
                formName='Dosha Vikruti Evaluation from Other Sources Dosha Status_OCA'
                observationCode='C3846540'
                categoryCode='C0582103'
                isReadOnly={isReadOnly}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
