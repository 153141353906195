import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import {
  requestForPaymentByUnit,
  resetRevenueByUnit,
} from 'redux/welloAdmin/unitRevenue/paymentByUnitSearchSlice'
import { getUnitTypeForWp } from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { WelloSelectOrgs } from 'views/components/LeftMenu/welloSelectOrgs'
import { RevenueTile } from './paymentAmountTile'

interface Props {
  id: string
  open: boolean
  type: string
  onClose: () => void
  unitList: R4.IOrganization[]
}
export const OrgPaymentDetails: React.FC<Props> = ({
  id,
  open,
  onClose,
  type,
  unitList,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const totalPaymentUnitWiseSlice = useSelector(
    (state: RootState) => state.paymentSearchByUnit
  )
  const [orgData, setOrgData] = useState<R4.IOrganization>()

  const ipdCountWpSlice = useSelector(
    (state: RootState) => state.ipdCountWpSlice
  )

  function resetForm() {}

  //   useEffect(() => {
  //     if (type.length > 0 && type === 'wellness_center') {
  //       dispatch(requestForWellnessAppointmentCountBoth(id))
  //     }
  //   }, [dispatch, id, type])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetForm()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Payment Details</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
                resetForm()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        <Box display='flex' flexGrow={1} flexDirection='column' width='100%'>
          <WelloSelectOrgs
            label={t('labelCommon:unit_label')}
            availableOptions={unitList.filter(
              (org: R4.IOrganization) =>
                getUnitTypeForWp(org) === 'wellness_center' ||
                getUnitTypeForWp(org) === 'clinic' ||
                getUnitTypeForWp(org) === 'lab'
            )}
            required={true}
            type='Select unit'
            displayField='name'
            valueField='id'
            onSelectionChanged={(value: any) => {
              setOrgData(value as R4.IOrganization)
              dispatch(resetRevenueByUnit())
              dispatch(
                requestForPaymentByUnit(
                  value.id ?? '',
                  getCodeOfSystemFromCodableConceptList(
                    value.type ?? [],
                    'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
                  )?.code ?? ''
                )
              )
            }}
          />
          {totalPaymentUnitWiseSlice.searchingAppointments && (
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
              paddingTop={2}
            >
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            </Box>
          )}

          {totalPaymentUnitWiseSlice.noResultsAvailable && (
            <SimpleBar
              style={{
                height: '100%',
                width: '100%',
              }}
            >
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height='100%'
              >
                <Box display='flex' flexGrow={1} flexDirection='column' py={1}>
                  <Box
                    paddingBottom={0.5}
                    paddingLeft={0.5}
                    display='flex'
                    flexDirection='row'
                  >
                    <Typography variant='subtitle2'>
                      {`Revenue details for `}
                    </Typography>
                    <Box paddingLeft={0.3} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          textTransform: 'uppercase',
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {` ${orgData ? orgData.name ?? '' : ''}`}
                      </Typography>
                    </Box>
                  </Box>
                  <RevenueTile
                    ipdCount={totalPaymentUnitWiseSlice.totalAmount ?? 0}
                  />
                </Box>
              </Box>
            </SimpleBar>
          )}

          {totalPaymentUnitWiseSlice.errorWhileSearchingAppointments && (
            <Box
              //   display='flex'
              //   flexDirection='column'
              justifyContent='center'
              width='100%'
              height='100%'
              alignContent='center'
              paddingLeft={50}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Grid container justifyContent='center'>
                    <Typography
                      variant='subtitle1'
                      color='error'
                      align='center'
                    >
                      Error while searching. Please try again
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}

          {totalPaymentUnitWiseSlice.resultsAvailable && (
            <SimpleBar
              style={{
                height: '100%',
                width: '100%',
              }}
            >
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height='100%'
              >
                <Box display='flex' flexGrow={1} flexDirection='column' py={1}>
                  <Box
                    paddingBottom={0.5}
                    paddingLeft={0.5}
                    display='flex'
                    flexDirection='row'
                  >
                    <Typography variant='subtitle2'>
                      {`Revenue details for `}
                    </Typography>
                    <Box paddingLeft={0.3} display='flex' flexDirection='row'>
                      <Typography
                        variant='subtitle2'
                        style={{
                          textTransform: 'uppercase',
                          color: 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {` ${orgData ? orgData.name ?? '' : ''}`}
                      </Typography>
                    </Box>
                  </Box>
                  <RevenueTile
                    ipdCount={totalPaymentUnitWiseSlice.totalAmount ?? 0}
                  />
                </Box>
              </Box>
            </SimpleBar>
          )}
        </Box>
      </DialogContent>
      {/* <DialogActions>
        <Box paddingRight={2}>
          <Button
            onClick={() => {
              onClose()
            }}
            variant='outlined'
            disableElevation
          >
            Ok
          </Button>
        </Box>
      </DialogActions> */}
    </Dialog>
  )
}
