import FullCalendar, { EventApi, EventContentArg } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'

import timeGridPlugin from '@fullcalendar/timegrid'
import {
  Box,
  Button,
  Collapse,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { Alert } from '@material-ui/lab'
import { kBackgroundPaper } from 'configs/styles/muiThemes'
import { PractitionerWorkHour } from 'models/businessHoursHelper'
import { ScheduleDetail } from 'models/fhirScheduleDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { removeScheduleDetails } from 'redux/schedule/deleteSchedule/removeSchedule'
import {
  requestForSchedules,
  resetSchdeule,
} from 'redux/schedule/searchSchedule/SearchProviderSchedule'
import { displayoptionList } from 'utils/constants'
import { getBulkScheduleBundle } from 'utils/fhirResoureHelpers/fhirScheduleDeleteHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { logger } from 'utils/logger'
import { WelloSelect } from '../LeftMenu/WelloSelect'
import CustomizedDividers, { ToggleOption } from '../repeat_Selector'
import { TabbedMultiSelector, TabOption } from '../toggle_multiSelector'
import { ScheduleTile } from './Scheduletile'

export interface ICalendar {
  onCalendarSelectionChanged?: (
    selectedValues: EventApi[],
    existingSchedules: EventApi[]
  ) => void
  onSelectAppointmentType?: (appointmentType: string) => void
  schedules?: ScheduleDetail[]
  editable: boolean
  selectedDoctor: PractitionerWithRole
  discard: boolean
  scheduleExist: boolean
  id?: string
  saved: boolean
  businessHoursData: PractitionerWorkHour[]
  businessHours: PractitionerWorkHour
  shoWFullWeek: boolean
  role?: string[]
  hiddenDays: number[]
}

const useStyles = makeStyles((theme: any) => ({
  fc: {
    '& .fc .fc-timegrid-slots': {
      position: 'relative',
      zIndex: 1,
      lineHeight: 0,
    },

    '& .fc .fc-col-header-cell-cushion': {
      color: 'black',
    },

    '& .fc .fc-timegrid-slot-label': {
      verticalAlign: 'top',
      backgroundColor: theme.palette.background.paper,
    },

    '& .fc .fc-scroller-harness-liquid': {
      // height: '64vh'
    },

    '& .fc .fc-timegrid-slot': {
      height: 80,
      lineHeight: 1,
    },
    '& .fc .fc-day-future': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .fc .fc-day-past': {
      backgroundColor: useTheme().palette.grey[200],
      opacity: 0.6,
    },
    '& .fc .fc-day-today': {
      backgroundColor: 'theme.palette.primary',
    },

    '&  .fc .fc-highlight': {
      backgroundColor: useTheme().palette.primary.main,
      background: 'none',
    },
    '& .fc .fc-scrollgrid-sync-inner': {
      backgroundColor: 'theme.palette.primary',
    },
    '& .fc-direction-ltr .fc-timegrid-col-events': {
      margin: 1,
    },
    '& .fc-media-screen .fc-timegrid-event': {},
  },
  fcFalse: {
    '& .fc ..fc-timegrid-slot': {},
    '& .fc .fc-scroller-harness-liquid': {
      // height: '70vh'
    },
    '& .fc .fc-view-harness': {
      // height: '72vh',
      // maxHeight: '72vh'
    },
    '& .fc .fc-col-header-cell-cushion': {
      color: 'black',
    },

    // "& .fc  fc-timegrid-event-harness": {

    // },

    '& .fc-direction-ltr .fc-timegrid-col-events': {
      margin: 0,
    },

    '& .fc-media-screen .fc-timegrid-event': {},

    '& .fc .fc-timegrid-slot-label': {
      verticalAlign: 'top',
      backgroundColor: theme.palette.background.paper,
    },

    '& .fc .fc-timegrid-slot': {
      height: 80,
      lineHeight: 1,
    },
    '& .fc .fc-day-future': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .fc .fc-day-past': {
      backgroundColor: useTheme().palette.grey[200],
      opacity: 0.6,
    },
    '& .fc .fc-day-today': {
      backgroundColor: 'theme.palette.primary',
    },

    '&  .fc .fc-highlight': {
      backgroundColor: useTheme().palette.primary.main,
      background: 'none',
    },
    '& .fc .fc-scrollgrid-sync-inner': {
      backgroundColor: 'theme.palette.primary',
    },
  },
}))

const appType = ['In-Person', 'Video']

const menu: TabOption[] = [
  { value: 'In-Person', displayText: 'In-Person' },
  //   { value: 'Phone', displayText: 'Phone' },
  { value: 'Video', displayText: 'Video' },
]

const repeats: ToggleOption[] = [
  { value: 'Yes', displayText: 'Yes' },
  { value: 'No', displayText: 'No' },
]

function getPreConfiguredDate(date: Date): any {
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)

  const currentDate = new Date()
  currentDate.setHours(0)
  currentDate.setMinutes(0)
  currentDate.setSeconds(0)
  currentDate.setMilliseconds(0)
  const daysDiff: number = moment(date).diff(moment(currentDate), 'day')
  if (daysDiff === 0) {
    return displayoptionList[0]
  }
  if (daysDiff <= 7 && daysDiff > 0) {
    return displayoptionList[1]
  }
  return displayoptionList[2]
}
export const Calendar: React.FC<ICalendar> = ({
  onCalendarSelectionChanged,
  onSelectAppointmentType,
  schedules,
  editable,
  selectedDoctor,
  discard,
  scheduleExist,
  id,
  saved,
  businessHoursData,
  businessHours,
  shoWFullWeek,
  role,
  hiddenDays,
}: ICalendar) => {
  const calendarRef:
    | string
    | ((instance: FullCalendar | null) => void)
    | React.RefObject<FullCalendar>
    | null
    | undefined = React.createRef()
  const dispatch = useDispatch()
  const [appointments, SetAppointments] = React.useState<EventApi[]>([])
  const [selectedRepeat, setSelectedRepeat] = React.useState(repeats[1].value)
  const [type1, setType] = React.useState<string[]>([menu[0].value])
  const [appointmentType, setAppointmentType] = React.useState<string[]>([
    appType[0],
  ])
  const { t } = useTranslation()
  const [displayWarning, setDisplayWarning] = React.useState<boolean>(true)

  const [deletedScheules, setDeletedSchedules] = React.useState<any[]>([])
  const availableOptions = [appType[0]]
  const [selectedDate, setSelectedDate] = React.useState(new Date())
  const [repeatData, setRepeat] = React.useState(false)
  const dragEnable = useRef<boolean>()
  const [overlap, setOverLap] = React.useState(false)
  const { height } = useWindowDimensions()
  const [preSelectedDate, setPreSelectedDate] = React.useState(
    getPreConfiguredDate(selectedDate)
  )
  let personName: string = ''
  let savedData = saved
  const SearchProviderScheduleSlice = useSelector(
    (state: RootState) => state.SearchProviderScheduleSlice
  )
  if (selectedDoctor.roleObject.code !== undefined) {
    if (selectedDoctor.roleObject.code[0].coding !== undefined)
      personName = selectedDoctor.roleObject.code[0]?.coding[0].code ?? ''
  }

  const requestSearchSchedule = (date: Date) => {
    dispatch(requestForSchedules(selectedDoctor, date))
  }

  useEffect(() => {
    if (calendarRef?.current != null) {
      const calendarApi = calendarRef?.current.getApi()
      calendarApi.refetchEvents()
    }
  }, [appointments, calendarRef])

  useEffect(() => {
    if (calendarRef?.current != null) {
      const calendarApi = calendarRef?.current.getApi()
      dispatch(
        requestForSchedules(selectedDoctor, moment().startOf('week').toDate())
      )
    }
  }, [dispatch, selectedDoctor])

  const eventData = SearchProviderScheduleSlice.scheduleList ?? []
  //  if(eventData.length === 0)
  //  {
  //     dispatch(requestForSchedules(selectedDoctor))
  //  }
  const classes = useStyles()
  let val: string
  if (role && (role.includes('doctor') || role.includes('ayurveda'))) {
    if (eventData.length === 0 && editable === false) {
      val = `${height - 258}px`
    } else if (eventData.length !== 0 && editable === false) {
      val = `${height - 202}px`
    } else {
      val = `${height - 202}px`
    }
  } else if (eventData.length === 0 && editable === false) {
    val = `${height - 320}px`
  } else if (eventData.length !== 0 && editable === false) {
    val = `${height - 275}px`
  } else {
    val = `${height - 302}px`
  }

  const setEventsWithOnlyType = (scheduleData: EventApi, type: string[]) => {
    scheduleData.setStart(moment(scheduleData.start).add(0, 'days').toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `${scheduleData.extendedProps.repeat}`,
      repeatLabel: `Every ${moment(scheduleData.start).format('dddd')}`,
    }
    for (let i = 0; i <= 30; i += 7) {
      const ms = moment()
      if (ms.isSameOrAfter(scheduleData.start)) {
        const eventData30 = {
          id: `t1${Math.random().toString()}`,
          title: type.sort().join(' . '),
          start: moment(scheduleData.start).add(i, 'days').toDate(),
          end: moment(scheduleData.end).add(i, 'days').toDate(),
          extendedProps: evenProp,
          version: '',
          groupId: `recurId${groupId}`,
        }

        scheduleData.remove()
        if (calendarApi) {
          calendarApi.addEvent(eventData30)
          if (onCalendarSelectionChanged) {
            onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
          }
        }
      }
    }
  }

  const setEventsWithOnlyTypeData = (scheduleData: any, type: string[]) => {
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `${scheduleData.extendedProps.repeat}`,
      repeatLabel: `Every ${moment(scheduleData.start).format('dddd')}`,
    }
    const ms = moment()
    if (ms.isSameOrBefore(scheduleData.start)) {
      const eventData31 = {
        id: `t1${Math.random().toString()}`,
        title: type.sort().join(' . '),
        start: scheduleData.start,
        end: scheduleData.end,
        extendedProps: evenProp,
        version: '',
        groupId: `recurId${groupId}`,
      }

      scheduleData.remove()
      if (calendarApi) {
        calendarApi.addEvent(eventData31)
        if (onCalendarSelectionChanged) {
          onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
        }
      }
    }
  }

  const setEventTypeToRepeat = (scheduleData: any, type: string[]) => {
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const evenProp = {
      title: type.sort().join(' . '),
      repeat: 'Does not repeat',
      repeatLabel: `Every ${moment(scheduleData.start).format('dddd')} `,
    }
    const ms = moment()
    if (ms.isSameOrBefore(scheduleData.start)) {
      const eventData32 = {
        id: `t1${Math.random().toString()}`,
        title: type.sort().join(' . '),
        start: scheduleData.start,
        end: scheduleData.end,
        extendedProps: evenProp,
        version: '',
        groupId: `recurId${groupId}`,
      }

      scheduleData.remove()
      if (calendarApi) {
        calendarApi.addEvent(eventData32)
        if (onCalendarSelectionChanged) {
          onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
        }
      }
    }
  }

  const setEventsWithOnlyDate = (
    type: string[],
    date: Date,
    scheduleData: EventApi
  ) => {
    scheduleData.setStart(moment(scheduleData.start).add(0, 'days').toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats every ${moment(scheduleData.start).format(
        'dddd'
      )} until ${moment(date).format('DD/MMM/YYYY')}`,
      repeatOn: date,
    }
    const dateDiff = moment(date).diff(moment(scheduleData.start), 'days')
    for (let i = 0; i <= dateDiff; i += 7) {
      if (calendarApi) {
        const listEvent = calendarApi.getEvents()

        listEvent.forEach((event) => {
          const isSame = moment(moment(event.end)).isSame(
            moment(moment(scheduleData.end).add(i, 'days'))
          )
          const newEndDate = moment(scheduleData.end).add(i, 'days')
          const newStartDate = moment(scheduleData.start).add(i, 'days')
          const startDateBefor = moment(newEndDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const startDateAfter = moment(newStartDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const existingEvents: any = []
          if (
            startDateBefor === true ||
            startDateAfter === true ||
            isSame === true
          ) {
            if (event.extendedProps.versionId === undefined) {
              event.remove()
            } else {
              existingEvents.push(event.extendedProps.data)
              const scheduleBundle = getBulkScheduleBundle(existingEvents)
              calendarApi.refetchEvents()
              dispatch(
                removeScheduleDetails(
                  scheduleBundle,
                  selectedDoctor,
                  false,
                  calendarApi.getDate()
                )
              )
              dispatch(resetSchdeule())
              requestSearchSchedule(calendarApi.getDate())

              setDeletedSchedules([])
              if (onCalendarSelectionChanged) {
                onCalendarSelectionChanged(
                  calendarApi.getEvents(),
                  existingEvents
                )
              }
            }
          }
        })
      }
      const ms = moment()
      if (
        ms.isSameOrBefore(moment(scheduleData.start).add(i, 'days').toDate())
      ) {
        const eventData33 = {
          id: `t1${Math.random().toString()}`,
          title: type.sort().join(' . '),
          start: moment(scheduleData.start).add(i, 'days').toDate(),
          end: moment(scheduleData.end).add(i, 'days').toDate(),
          extendedProps: evenProp,
          version: '',
          groupId: `recurId${groupId}`,
        }

        if (calendarApi) {
          calendarApi.addEvent(eventData33)
          if (onCalendarSelectionChanged) {
            onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
          }
        }
      }
    }
  }

  /* Default Repat */
  const setEventsWithDefaultRepeat = (
    type: string[],
    date: Date,
    scheduleData: EventApi
  ) => {
    scheduleData.setStart(moment(scheduleData.start).add(0, 'days').toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const dateData = moment(date).add(30, 'days').toDate()
    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats every ${moment(scheduleData.start).format(
        'dddd'
      )} ${moment(dateData).format('DD/MMM/YY')}`,
      repeatLabel: `Every ${moment(scheduleData.start).format('dddd')} `,
    }

    const dateDiff = moment(dateData).diff(moment(scheduleData.start), 'days')
    for (let i = 0; i <= dateDiff; i += 7) {
      if (calendarApi) {
        const listEvent = calendarApi.getEvents()
        listEvent.forEach((event) => {
          const isSame = moment(moment(event.end)).isSame(
            moment(moment(scheduleData.end).add(i, 'days'))
          )
          const newEndDate = moment(scheduleData.end).add(i, 'days')
          const newStartDate = moment(scheduleData.start).add(i, 'days')
          const startDateBefor = moment(newEndDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const startDateAfter = moment(newStartDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const existingEvents: EventApi[] = []
          if (
            startDateBefor === true ||
            startDateAfter === true ||
            isSame === true
          ) {
            if (event.extendedProps.versionId === undefined) {
              event.remove()
            } else {
              existingEvents.push(event.extendedProps.data)
              const scheduleBundle = getBulkScheduleBundle(existingEvents)
              calendarApi.refetchEvents()
              dispatch(
                removeScheduleDetails(
                  scheduleBundle,
                  selectedDoctor,
                  false,
                  calendarApi.getDate()
                )
              )
              dispatch(resetSchdeule())
              requestSearchSchedule(calendarApi.getDate())

              setDeletedSchedules([])
              if (onCalendarSelectionChanged) {
                onCalendarSelectionChanged(
                  calendarApi.getEvents(),
                  existingEvents
                )
              }
            }
          }
        })
      }
      const ms = moment()
      if (
        ms.isSameOrBefore(moment(scheduleData.start).add(i, 'days').toDate())
      ) {
        const eventData34 = {
          id: `t1${Math.random().toString()}`,
          title: type.sort().join(' . '),
          start: moment(scheduleData.start).add(i, 'days').toDate(),
          end: moment(scheduleData.end).add(i, 'days').toDate(),
          extendedProps: evenProp,
          version: '',
          groupId: `recurId${groupId}`,
        }

        if (calendarApi) {
          calendarApi.addEvent(eventData34)
          if (onCalendarSelectionChanged) {
            onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
          }
        }
      }
    }
  }

  const setEventsWithChangeCustomOnlyWeekly = (
    scheduleData: EventApi,
    type: string[],
    range: any,
    dateRange: string
  ) => {
    scheduleData.setStart(moment(scheduleData.start).add(0, 'days').toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const repeatDays = 7 * parseInt(dateRange, 10)
    const dateData = moment(scheduleData.start).add(30, 'days').toDate()
    let repData = ''

    if (dateRange === '2') {
      repData = `${dateRange}nd`
    }
    if (dateRange === '3') {
      repData = `${dateRange}rd`
    }
    if (dateRange === '4') {
      repData = `${dateRange}th`
    }
    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats every ${repData} ${moment(scheduleData.start).format(
        'dddd'
      )} ${moment(dateData).format('DD/MMM/YY')}`,
    }
    const dateDiff = moment(dateData).diff(moment(scheduleData.start), 'days')
    for (let i = 0; i <= dateDiff; i += repeatDays) {
      const ms = moment()
      if (
        ms.isSameOrBefore(moment(scheduleData.start).add(i, 'days').toDate())
      ) {
        const eventData35 = {
          id: `t1${Math.random().toString()}`,
          title: type.sort().join(' . '),
          start: moment(scheduleData.start).add(i, 'days').toDate(),
          end: moment(scheduleData.end).add(i, 'days').toDate(),
          extendedProps: evenProp,
          version: '',
          groupId: `recurId${groupId}`,
        }
        scheduleData.remove()
        if (calendarApi) {
          calendarApi.addEvent(eventData35)
          if (onCalendarSelectionChanged) {
            onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
          }
        }
      }
    }
  }

  /* Event with no repeat and custom daily */
  const setEventsWithChangeCustomOnlyDaily = (
    scheduleData: EventApi,
    type: string[],
    range: any,
    dateRange: string
  ) => {
    scheduleData.setStart(moment(scheduleData.start).add(0, 'days').toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const repeatDays = 1 * parseInt(dateRange, 10)
    const dateData = moment(scheduleData.start).add(30, 'days').toDate()
    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats everyday ${moment(dateData).format('DD/MMM/YY')}`,
    }
    const dateDiff = moment(dateData).diff(moment(scheduleData.start), 'days')
    for (let i = 0; i <= dateDiff; i += repeatDays) {
      const ms = moment()
      if (
        ms.isSameOrBefore(moment(scheduleData.start).add(i, 'days').toDate())
      ) {
        const eventData36 = {
          id: `t1${Math.random().toString()}`,
          title: type.sort().join(' . '),
          start: moment(scheduleData.start).add(i, 'days').toDate(),
          end: moment(scheduleData.end).add(i, 'days').toDate(),
          extendedProps: evenProp,
          version: '',
          groupId: `recurId${groupId}`,
        }
        scheduleData.remove()
        if (calendarApi) {
          calendarApi.addEvent(eventData36)
          if (onCalendarSelectionChanged) {
            onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
          }
        }
      }
    }
  }

  /* Event with no repeat and custom daily */
  const setEventsWithChangeCustomOnlyMonthly = (
    scheduleData: EventApi,
    type: string[],
    range: any,
    dateRange: string
  ) => {
    scheduleData.setStart(moment(scheduleData.start).add(0, 'days').toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const repeatDays = 30 * parseInt(dateRange, 10)
    const dateData = moment(scheduleData.start).add(30, 'days').toDate()
    let repData = ''

    if (dateRange === '2') {
      repData = `${dateRange}nd`
    }
    if (dateRange === '3') {
      repData = `${dateRange}rd`
    }
    if (dateRange === '4') {
      repData = `${dateRange}th`
    }

    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats every ${repData} month till ${moment(dateData).format(
        'DD/MMM/YY'
      )}`,
    }
    const dateDiff = moment(dateData).diff(moment(scheduleData.start), 'days')
    for (let i = 0; i <= dateDiff; i += repeatDays) {
      const ms = moment()
      if (
        ms.isSameOrBefore(moment(scheduleData.start).add(i, 'days').toDate())
      ) {
        const eventData37 = {
          id: `t1${Math.random().toString()}`,
          title: type.sort().join(' . '),
          start: moment(scheduleData.start).add(i, 'days').toDate(),
          end: moment(scheduleData.end).add(i, 'days').toDate(),
          extendedProps: evenProp,
          version: '',
          groupId: `recurId${groupId}`,
        }
        scheduleData.remove()
        if (calendarApi) {
          calendarApi.addEvent(eventData37)
          if (onCalendarSelectionChanged) {
            onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
          }
        }
      }
    }
  }

  const getEventWithRepeatChange = (
    type: string[],
    date: Date,
    scheduleData: EventApi
  ) => {
    scheduleData.setStart(moment(scheduleData.start).add(0, 'days').toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const dateDiff = moment(date).diff(moment(scheduleData.start), 'days')
    for (let i = 0; i <= dateDiff; i += 1) {
      if (calendarApi) {
        const listEvent = calendarApi.getEvents()
        listEvent.forEach((event) => {
          const isSame = moment(moment(event.end)).isSame(
            moment(moment(scheduleData.end).add(i, 'days'))
          )
          const newEndDate = moment(scheduleData.end).add(i, 'days')
          const newStartDate = moment(scheduleData.start).add(i, 'days')
          const startDateBefor = moment(newEndDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const startDateAfter = moment(newStartDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const existingEvents: any = []
          if (
            startDateBefor === true ||
            startDateAfter === true ||
            isSame === true
          ) {
            if (event.extendedProps.versionId === undefined) {
              event.remove()
            } else {
              existingEvents.push(event.extendedProps.data)
              const scheduleBundle = getBulkScheduleBundle(existingEvents)
              calendarApi.refetchEvents()
              dispatch(
                removeScheduleDetails(
                  scheduleBundle,
                  selectedDoctor,
                  false,
                  calendarApi.getDate()
                )
              )
              dispatch(resetSchdeule())
              requestSearchSchedule(calendarApi.getDate())

              setDeletedSchedules([])
              if (onCalendarSelectionChanged) {
                onCalendarSelectionChanged(
                  calendarApi.getEvents(),
                  existingEvents
                )
              }
            }
          }
        })
      }
      const ms = moment()
      if (
        ms.isSameOrBefore(moment(scheduleData.start).add(i, 'days').toDate())
      ) {
        const evenProp = {
          title: type.sort().join(' . '),
          repeat: `Repeats everyday till  ${moment(date).format('DD/MMM/YY')}`,
          repeatLabel: `Every ${moment(scheduleData.start)
            .add(i, 'days')
            .format('dddd')} `,
        }
        const eventData38 = {
          id: `t1${Math.random().toString()}`,
          title: type.sort().join(' . '),
          start: moment(scheduleData.start).add(i, 'days').toDate(),
          end: moment(scheduleData.end).add(i, 'days').toDate(),
          extendedProps: evenProp,
          version: '',
          groupId: `recurId${groupId}`,
        }

        if (calendarApi) {
          calendarApi.addEvent(eventData38)
          if (onCalendarSelectionChanged) {
            onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
          }
        }
      }
    }
  }

  const getEventWithRepeatChangeDate = (
    type: string[],
    date: Date,
    scheduleData: EventApi,
    eventDate: Date,
    repeat: string,
    dateData: any,
    repeatLabel: string
  ) => {
    const date1 = moment(eventDate).format('YYYY-MM-DD')
    const time = moment(scheduleData.start).format('HH:mm')
    const endTIme = moment(scheduleData.end).format('HH:mm')
    const timeAndDate = moment(`${date1} ${time}`)
    const endDate = moment(`${date1} ${endTIme}`)
    const noSchedule = false
    const groupId = eventDate
    const calendarApi = calendarRef?.current?.getApi()
    let repData = ''

    if (repeat === '2') {
      repData = `${repeat}nd`
    }
    if (repeat === '3') {
      repData = `${repeat}rd`
    }
    if (repeat === '4') {
      repData = `${repeat}th`
    }

    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `${repeatLabel}`,
      repeatLabel: `${repeatLabel}`,
    }
    const dateDiff = moment(dateData).diff(moment(eventDate), 'days')
    const repeatDays = 7 * parseInt(repeat, 10)
    const duplicate: any = []
    for (let i = 0; i <= dateDiff; i += repeatDays) {
      if (calendarApi) {
        const listEvent = calendarApi.getEvents()
        listEvent.forEach((event) => {
          const isSame = moment(moment(event.end)).isSame(
            moment(endDate).add(i, 'days')
          )
          const newEndDate = moment(endDate).add(i, 'days')
          const newStartDate = moment(timeAndDate).add(i, 'days')
          const startDateBefor = moment(newEndDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const startDateAfter = moment(newStartDate).isBetween(
            moment(event.start),
            moment(event.end)
          )

          if (
            startDateBefor === true ||
            startDateAfter === true ||
            isSame === true
          ) {
            duplicate.push(event)
          }
        })
      }
    }

    for (let i = 0; i <= dateDiff; i += repeatDays) {
      if (calendarApi) {
        const listEvent = calendarApi.getEvents()
        listEvent.forEach((event) => {
          const isSame = moment(moment(event.end)).isSame(
            moment(endDate).add(i, 'days')
          )
          const newEndDate = moment(endDate).add(i, 'days')
          const newStartDate = moment(timeAndDate).add(i, 'days')
          const startDateBefor = moment(newEndDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const startDateAfter = moment(newStartDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const existingEvents: any = []
          if (
            startDateBefor === true ||
            startDateAfter === true ||
            isSame === true
          ) {
            if (event.extendedProps.versionId === undefined) {
              event.remove()
            } else {
              existingEvents.push(event.extendedProps.data)
              const scheduleBundle = getBulkScheduleBundle(existingEvents)
              calendarApi.refetchEvents()
              dispatch(
                removeScheduleDetails(
                  scheduleBundle,
                  selectedDoctor,
                  false,
                  calendarApi.getDate()
                )
              )
              dispatch(resetSchdeule())
              requestSearchSchedule(calendarApi.getDate())

              setDeletedSchedules([])
              if (onCalendarSelectionChanged) {
                onCalendarSelectionChanged(
                  calendarApi.getEvents(),
                  existingEvents
                )
              }
            }
          }
        })
      }
      const ms = moment()
      if (ms.isSameOrBefore(moment(timeAndDate).add(i, 'days').toDate())) {
        const eventData40 = {
          id: `t1${Math.random().toString()}`,
          title: type.sort().join(' . '),
          start: moment(timeAndDate).add(i, 'days').toDate(),
          end: moment(endDate).add(i, 'days').toDate(),
          extendedProps: evenProp,
          version: '',
          groupId: `recurId`,
          constraint: 'businessHours',
        }
        scheduleData.remove()
        if (calendarApi) {
          calendarApi.addEvent(eventData40)
          if (onCalendarSelectionChanged) {
            onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
          }
        }
      }
    }
  }

  /* with occurance */
  const getEventWithRepeatChangeDateWithOccurance = (
    type: string[],
    date: Date,
    scheduleData: EventApi,
    eventDate: Date,
    repeat: string,
    dateData: Date,
    repeatLabel: string
  ) => {
    const date1 = moment(eventDate).format('YYYY-MM-DD')
    const time = moment(scheduleData.start).format('HH:mm')
    const endTIme = moment(scheduleData.end).format('HH:mm')
    const timeAndDate = moment(`${date1} ${time}`)
    const endDate = moment(`${date1} ${endTIme}`)

    const groupId = eventDate
    const calendarApi = calendarRef?.current?.getApi()
    let repData = ''

    if (repeat === '2') {
      repData = `${repeat}nd`
    }
    if (repeat === '3') {
      repData = `${repeat}rd`
    }
    if (repeat === '4') {
      repData = `${repeat}th`
    }

    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `${repeatLabel} till ${moment(dateData).format('DD/MMM/YYYY')} `,
      repeatLabel: `${repeatLabel}`,
    }
    const dateDiff = moment(dateData).diff(moment(eventDate), 'days')
    const repeatDays = 7 * parseInt(repeat, 10)

    for (let i = 0; i <= dateDiff; i += repeatDays) {
      if (calendarApi) {
        const listEvent = calendarApi.getEvents()
        listEvent.forEach((event) => {
          const isSame = moment(moment(event.end)).isSame(
            moment(endDate).add(i, 'days')
          )
          const newEndDate = moment(endDate).add(i, 'days')
          const newStartDate = moment(timeAndDate).add(i, 'days')
          const startDateBefor = moment(newEndDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const startDateAfter = moment(newStartDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const existingEvents: any = []
          if (
            startDateBefor === true ||
            startDateAfter === true ||
            isSame === true
          ) {
            if (event.extendedProps.versionId === undefined) {
              event.remove()
            } else {
              existingEvents.push(event.extendedProps.data)
              const scheduleBundle = getBulkScheduleBundle(existingEvents)
              calendarApi.refetchEvents()
              dispatch(
                removeScheduleDetails(
                  scheduleBundle,
                  selectedDoctor,
                  false,
                  calendarApi.getDate()
                )
              )
              dispatch(resetSchdeule())
              requestSearchSchedule(calendarApi.getDate())

              setDeletedSchedules([])
              if (onCalendarSelectionChanged) {
                onCalendarSelectionChanged(
                  calendarApi.getEvents(),
                  existingEvents
                )
              }
            }
          }
        })
      }
      const eventData41 = {
        id: `t1${Math.random().toString()}`,
        title: type.sort().join(' . '),
        start: moment(timeAndDate).add(i, 'days').toDate(),
        end: moment(endDate).add(i, 'days').toDate(),
        extendedProps: evenProp,
        version: '',
        groupId: `recurId`,
        constraint: 'businessHours',
      }
      scheduleData.remove()
      if (calendarApi) {
        calendarApi.addEvent(eventData41)
        if (onCalendarSelectionChanged) {
          onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
        }
      }
    }
  }

  const getEventWithRepeatChangeMonth = (
    type: string[],
    date: Date,
    scheduleData: EventApi,
    eventDate: Date,
    repeat: string,
    repeatLabel: string
  ) => {
    const date1 = moment(eventDate).format('YYYY-MM-DD')
    const time = moment(scheduleData.start).format('HH:mm')
    const endTIme = moment(scheduleData.end).format('HH:mm')
    const timeAndDate = moment(`${date1} ${time}`)
    const endDate = moment(`${date1} ${endTIme}`)
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const repeatDays = 30 * parseInt(repeat, 10)
    const dateData = moment(scheduleData.start).add(repeatDays, 'days').toDate()
    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats ${repeatLabel}`,
      repeatLabel: `${repeatLabel}`,
    }

    const dateDiff = moment(dateData).diff(moment(scheduleData.start), 'days')
    const curDate = new Date()
    for (let i = 0; i <= dateDiff; i += 7) {
      if (calendarApi) {
        const listEvent = calendarApi.getEvents()
        listEvent.forEach((event) => {
          const isSame = moment(moment(event.end)).isSame(
            moment(endDate).add(i, 'days')
          )
          const newEndDate = moment(endDate).add(i, 'days')
          const newStartDate = moment(timeAndDate).add(i, 'days')
          const startDateBefor = moment(newEndDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const startDateAfter = moment(newStartDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const existingEvents: any = []
          if (
            startDateBefor === true ||
            startDateAfter === true ||
            isSame === true
          ) {
            if (event.extendedProps.versionId === undefined) {
              event.remove()
            } else {
              existingEvents.push(event.extendedProps.data)
              const scheduleBundle = getBulkScheduleBundle(existingEvents)
              calendarApi.refetchEvents()
              dispatch(
                removeScheduleDetails(
                  scheduleBundle,
                  selectedDoctor,
                  false,
                  calendarApi.getDate()
                )
              )
              dispatch(resetSchdeule())
              requestSearchSchedule(calendarApi.getDate())

              setDeletedSchedules([])
              if (onCalendarSelectionChanged) {
                onCalendarSelectionChanged(
                  calendarApi.getEvents(),
                  existingEvents
                )
              }
            }
          }
        })
      }
      if (
        moment(curDate).isSame(
          moment(timeAndDate).add(i, 'days').toDate(),
          'months'
        )
      ) {
        const eventData42 = {
          id: `t1${Math.random().toString()}`,
          title: type.sort().join(' . '),
          start: moment(timeAndDate).add(i, 'days').toDate(),
          end: moment(endDate).add(i, 'days').toDate(),
          extendedProps: evenProp,
          version: '',
          groupId: `recurId${groupId}`,
        }

        if (calendarApi) {
          calendarApi.addEvent(eventData42)
          if (onCalendarSelectionChanged) {
            onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
          }
        }
      }
    }
  }

  const getEventWithRepeatChangeMonthWithRepeatsOn = (
    type: string[],
    date: Date,
    scheduleData: EventApi,
    eventDate: Date,
    repeat: string,
    repeatLabel: string,
    customLabel: any
  ) => {
    const repeatDays = moment(eventDate).daysInMonth() * parseInt(repeat, 10)
    const startDate = moment(eventDate).clone().startOf('month').toDate()
    const weekNumber = moment(startDate).week()
    const dayName = moment(eventDate).format('dddd')
    const curDate = new Date()
    const dateArray: any = []

    for (let i = 0; i < repeatDays / 7; i++) {
      const formedDate = moment()
        .day(dayName)
        .week(weekNumber + i)
        .toDate()
      if (moment(eventDate).isSame(formedDate, 'months')) {
        dateArray.push(formedDate)
      }
    }
    if (
      Math.floor(repeatDays % 7) === 3 &&
      moment(moment().endOf('month').startOf('isoWeeks')).format('dddd') ===
        dayName
    ) {
      dateArray.push(moment().endOf('month').startOf('isoWeeks').toDate())
    }
    const eventArray: any = []
    for (let j = 0; j < customLabel.length; j++) {
      if (customLabel[j] === '1st') eventArray.push(dateArray[0])
      else if (customLabel[j] === '2nd') eventArray.push(dateArray[1])
      else if (customLabel[j] === '3rd') eventArray.push(dateArray[2])
      else if (customLabel[j] === '4th') eventArray.push(dateArray[3])
      else if (customLabel[j] === '5th') eventArray.push(dateArray[4])
    }
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats Every Month ${customLabel.join(
        ', '
      )} ${repeatLabel.replace('Every', '')}`,
      repeatLabel: `${repeatLabel}`,
    }
    for (let i = 0; i < eventArray.length; i++) {
      if (eventArray[i] !== undefined) {
        const date1 = moment(eventArray[i]).format('YYYY-MM-DD')
        const time = moment(scheduleData.start).format('HH:mm')
        const endTIme = moment(scheduleData.end).format('HH:mm')
        const timeAndDate = moment(`${date1} ${time}`)
        const endDate = moment(`${date1} ${endTIme}`)
        const repEventData = {
          id: `t1${Math.random().toString()}`,
          title: type.sort().join(' . '),
          start: moment(timeAndDate).toDate(),
          end: moment(endDate).toDate(),
          extendedProps: evenProp,
          version: '',
          groupId: `recurId${groupId}`,
        }

        if (calendarApi) {
          calendarApi.addEvent(repEventData)
          if (onCalendarSelectionChanged) {
            onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
          }
        }
      }
    }
  }

  const getEventWithRepeatEndChangeMonth = (
    type: any,
    date: Date,
    scheduleData: EventApi,
    eventDate: Date,
    repeat: string,
    repeatLabel: string
  ) => {
    const date1 = moment(eventDate).format('YYYY-MM-DD')
    const time = moment(scheduleData.start).format('HH:mm')
    const endTIme = moment(scheduleData.end).format('HH:mm')
    const timeAndDate = moment(`${date1} ${time}`)
    const endDate = moment(`${date1} ${endTIme}`)
    // scheduleData.setStart(moment(timeAndDate).toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const repeatDays = 30 * parseInt(repeat, 10)
    const dateData = moment(scheduleData.start).add(repeatDays, 'days').toDate()
    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats Weekly ${repeatLabel} till ${moment(date).format(
        'DD/MMM/YYYY'
      )} `,
      repeatLabel: `${repeatLabel}`,
    }
    const dateDiff = moment(date).diff(moment(scheduleData.start), 'days')
    for (let i = 0; i <= dateDiff; i += 7) {
      const eventDataMonth = {
        id: `t1${Math.random().toString()}`,
        title: type.sort().join(' . '),
        start: moment(timeAndDate).add(i, 'days').toDate(),
        end: moment(endDate).add(i, 'days').toDate(),
        extendedProps: evenProp,
        version: '',
        groupId: `recurId${groupId}`,
      }

      if (calendarApi) {
        calendarApi.addEvent(eventDataMonth)
        if (onCalendarSelectionChanged) {
          onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
        }
      }
    }
  }

  const getEventWithRepeatEndChangeMonthNoEventChange = (
    type: string[],
    date: Date,
    scheduleData: EventApi,
    eventDate: any,
    repeat: string
  ) => {
    const date1 = moment(scheduleData.start).format('YYYY-MM-DD')
    const time = moment(scheduleData.start).format('HH:mm')
    const endTIme = moment(scheduleData.end).format('HH:mm')
    const timeAndDate = moment(`${date1} ${time}`)
    const endDate = moment(`${date1} ${endTIme}`)
    scheduleData.remove()
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const repeatDays = 30 * parseInt(repeat, 10)
    const dateData = moment(scheduleData.start).add(repeatDays, 'days').toDate()
    scheduleData.remove()
    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats monthly till ${moment(date).format('DD/MMM/YYYY')} `,
    }
    const dateDiff = moment(date).diff(moment(timeAndDate), 'days')

    for (let i = 0; i <= dateDiff; i += repeatDays) {
      const eventDataNoChange = {
        id: `t1${Math.random().toString()}`,
        title: type.sort().join(' . '),
        start: moment(timeAndDate).add(i, 'days').toDate(),
        end: moment(endDate).add(i, 'days').toDate(),

        extendedProps: evenProp,
        version: '',
        groupId: `recurId${groupId}`,
      }

      if (calendarApi) {
        calendarApi.addEvent(eventDataNoChange)
        if (onCalendarSelectionChanged) {
          onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
        }
      }
    }
  }

  const getEventWithRepeatChangeMonthWithSameDate = (
    type: string[],
    date: Date,
    scheduleData: EventApi,
    eventDate: any,
    repeat: string
  ) => {
    const date1 = moment(scheduleData.start).format('YYYY-MM-DD')
    const time = moment(scheduleData.start).format('HH:mm')
    const endTIme = moment(scheduleData.end).format('HH:mm')
    const timeAndDate = moment(`${date1} ${time}`)
    const endDate = moment(`${date1} ${endTIme}`)
    scheduleData.remove()
    const groupId = timeAndDate
    const calendarApi = calendarRef?.current?.getApi()
    const repeatDays = 30 * parseInt(repeat, 10)
    const dateData = moment(timeAndDate).add(repeatDays, 'days').toDate()

    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats monthly till ${moment(dateData).format('DD/MMM/YYYY')} `,
    }
    const dateDiff = moment(dateData).diff(moment(timeAndDate), 'days')

    for (let i = 0; i <= dateDiff; i += repeatDays) {
      if (
        moment(timeAndDate).add(i, 'days').toDate().getDay() === 0 ||
        moment(timeAndDate).add(i, 'days').toDate().getDay() === 6
      ) {
        const eventData1 = {
          id: `t1${Math.random().toString()}`,
          title: type.sort().join(' . '),
          start: moment(timeAndDate).add(i, 'days').toDate(),
          end: moment(endDate).add(i, 'days').toDate(),
          extendedProps: evenProp,
          version: '',
          groupId: `recurId${groupId}`,
        }

        if (calendarApi) {
          calendarApi.addEvent(eventData1)
          if (onCalendarSelectionChanged) {
            onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
          }
        }
      }
    }
  }

  const getEventWithRepeatEndsOnDate = (
    type: string[],
    date: Date,
    scheduleData: EventApi,
    eventDate: Date,
    repeat: string,
    dateData: any,
    repeatLabel: string
  ) => {
    const date1 = moment(eventDate).format('YYYY-MM-DD')
    const time = moment(scheduleData.start).format('HH:mm')
    const endTIme = moment(scheduleData.end).format('HH:mm')
    const timeAndDate = moment(`${date1} ${time}`)
    const endDate = moment(`${date1} ${endTIme}`)
    // scheduleData.setStart(moment(timeAndDate).toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    // const dateData = moment(scheduleData.start).add(30,'days').toDate()
    let repData = ''

    if (repeat === '2') {
      repData = `${repeat}nd`
    }
    if (repeat === '3') {
      repData = `${repeat}rd`
    }
    if (repeat === '4') {
      repData = `${repeat}th`
    }

    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats ${repData} ${repeatLabel} till ${moment(date).format(
        'DD/MMM/YYYY'
      )} `,
      repeatLabel: `${repeatLabel}`,
    }
    const repeatDays = 7 * parseInt(repeat, 10)
    for (let i = 0; i <= dateData; i += repeatDays) {
      if (calendarApi) {
        const listEvent = calendarApi.getEvents()
        listEvent.forEach((event) => {
          const isSame = moment(moment(event.end)).isSame(
            moment(endDate).add(i, 'days')
          )
          const newEndDate = moment(endDate).add(i, 'days')
          const newStartDate = moment(timeAndDate).add(i, 'days')
          const startDateBefor = moment(newEndDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const startDateAfter = moment(newStartDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const existingEvents: any = []
          if (
            startDateBefor === true ||
            startDateAfter === true ||
            isSame === true
          ) {
            if (event.extendedProps.versionId === undefined) {
              event.remove()
            } else {
              existingEvents.push(event.extendedProps.data)
              const scheduleBundle = getBulkScheduleBundle(existingEvents)
              calendarApi.refetchEvents()
              dispatch(
                removeScheduleDetails(
                  scheduleBundle,
                  selectedDoctor,
                  false,
                  calendarApi.getDate()
                )
              )
              dispatch(resetSchdeule())
              requestSearchSchedule(calendarApi.getDate())

              setDeletedSchedules([])
              if (onCalendarSelectionChanged) {
                onCalendarSelectionChanged(
                  calendarApi.getEvents(),
                  existingEvents
                )
              }
            }
          }
        })
      }
      const eventDat2 = {
        id: `t1${Math.random().toString()}`,
        title: type.sort().join(' . '),
        start: moment(timeAndDate).add(i, 'days').toDate(),
        end: moment(endDate).add(i, 'days').toDate(),
        extendedProps: evenProp,
        version: '',
        groupId: `recurId${groupId}`,
      }

      if (calendarApi) {
        calendarApi.addEvent(eventDat2)
        if (onCalendarSelectionChanged) {
          onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
        }
      }
    }
  }

  /* Custom Setting Default Week View */
  const getEventWithRepeatEndsOnDateDefault = (
    type: string[],
    date: Date,
    scheduleData: EventApi,
    eventDate: any,
    repeat: string
  ) => {
    const date1 = moment(scheduleData.start).format('YYYY-MM-DD')
    const time = moment(scheduleData.start).format('HH:mm')
    const endTIme = moment(scheduleData.end).format('HH:mm')
    const timeAndDate = moment(`${date1} ${time}`)
    const endDate = moment(`${date1} ${endTIme}`)
    scheduleData.setStart(moment(timeAndDate).toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    // const dateData = moment(scheduleData.start).add(30,'days').toDate()
    let repData = ''

    if (repeat === '2') {
      repData = `${repeat}nd`
    }
    if (repeat === '3') {
      repData = `${repeat}rd`
    }
    if (repeat === '4') {
      repData = `${repeat}th`
    }

    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats every ${repData} ${moment(scheduleData.start).format(
        'dddd'
      )} till ${moment(date).format('DD/MMM/YYYY')} `,
    }
    const dateDiff = moment(date).diff(moment(scheduleData.start), 'days')
    const repeatDays = 7 * parseInt(repeat, 10)
    for (let i = 0; i <= dateDiff; i += repeatDays) {
      const eventData3 = {
        id: `t1${Math.random().toString()}`,
        title: type.sort().join(' . '),
        start: moment(scheduleData.start).add(i, 'days').toDate(),
        end: moment(endDate).add(i, 'days').toDate(),
        extendedProps: evenProp,
        version: '',
        groupId: `recurId${groupId}`,
      }

      if (calendarApi) {
        calendarApi.addEvent(eventData3)
        if (onCalendarSelectionChanged) {
          onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
        }
      }
    }
  }

  const getEventWithRepeatChangeDateDaily = (
    type: string[],
    date: Date,
    scheduleData: EventApi,
    eventDate: Date,
    repeat: string
  ) => {
    const date1 = moment(eventDate).format('YYYY-MM-DD')
    const time = moment(scheduleData.start).format('HH:mm')
    const endTIme = moment(scheduleData.end).format('HH:mm')
    const endDate = moment(`${date1} ${endTIme}`)
    scheduleData.setStart(moment(scheduleData.start).add(0, 'days').toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const dateData = moment(scheduleData.start).add(30, 'days').toDate()
    let repData = ''

    if (repeat === '2') {
      repData = `2 days`
    }
    if (repeat === '1') {
      repData = `day`
    }
    if (repeat === '3') {
      repData = `3 days`
    }
    if (repeat === '4') {
      repData = `4 days`
    }
    const dateDiff = moment(dateData).diff(moment(scheduleData.start), 'days')
    const repeatDays = 1 * parseInt(repeat, 10)
    for (let i = 0; i <= dateDiff; i += repeatDays) {
      if (calendarApi) {
        const listEvent = calendarApi.getEvents()
        listEvent.forEach((event) => {
          const isSame = moment(moment(event.end)).isSame(
            moment(moment(endDate).add(i, 'days'))
          )
          const newEndDate = moment(endDate).add(i, 'days')
          const newStartDate = moment(scheduleData.start).add(i, 'days')
          const startDateBefor = moment(newEndDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const startDateAfter = moment(newStartDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const existingEvents: any = []
          if (
            startDateBefor === true ||
            startDateAfter === true ||
            isSame === true
          ) {
            if (event.extendedProps.versionId === undefined) {
              event.remove()
            } else {
              existingEvents.push(event.extendedProps.data)
              const scheduleBundle = getBulkScheduleBundle(existingEvents)
              calendarApi.refetchEvents()
              dispatch(
                removeScheduleDetails(
                  scheduleBundle,
                  selectedDoctor,
                  false,
                  calendarApi.getDate()
                )
              )
              dispatch(resetSchdeule())
              requestSearchSchedule(calendarApi.getDate())

              setDeletedSchedules([])
              if (onCalendarSelectionChanged) {
                onCalendarSelectionChanged(
                  calendarApi.getEvents(),
                  existingEvents
                )
              }
            }
          }
        })
      }
      const evenProp = {
        title: type.sort().join(' . '),
        repeat: `Repeats every ${repData} until ${moment(dateData).format(
          'DD/MMM/YYYY'
        )} `,
        repeatLabel: `Repeats every ${repData} until ${moment(dateData).format(
          'DD/MMM/YYYY'
        )} `,
      }
      const day = moment(scheduleData.start).add(i, 'days').toDate()
      const dayName = moment(day).format('ddd')
      if (
        dayName.includes('Sat') === false ||
        dayName.includes('Sun') === false
      ) {
        const eventData4 = {
          id: `t1${Math.random().toString()}`,
          title: type.sort().join(' . '),
          start: moment(scheduleData.start).add(i, 'days').toDate(),
          end: moment(scheduleData.end).add(i, 'days').toDate(),
          extendedProps: evenProp,
          version: '',
          groupId: `recurId${groupId}`,
        }

        if (calendarApi) {
          calendarApi.addEvent(eventData4)
          if (onCalendarSelectionChanged) {
            onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
          }
        }
      }
    }
  }

  /* No Event Chage with Custom settings */
  const getEventWithRepeatChangeDateDailyCustom = (
    type: string[],
    date: Date,
    scheduleData: EventApi,
    eventDate: any,
    repeat: string
  ) => {
    const date1 = moment(scheduleData.start).format('YYYY-MM-DD')
    const time = moment(scheduleData.start).format('HH:mm')
    const endTIme = moment(scheduleData.end).format('HH:mm')
    const timeAndDate = moment(`${date1} ${time}`)
    const endDate = moment(`${date1} ${endTIme}`)
    scheduleData.setStart(moment(timeAndDate).toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const dateData = moment(scheduleData.start).add(30, 'days').toDate()
    let repData = ''

    if (repeat === '2') {
      repData = `${repeat}nd`
    }
    if (repeat === '3') {
      repData = `${repeat}rd`
    }
    if (repeat === '4') {
      repData = `${repeat}th`
    }

    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats every ${repData} day till ${moment(dateData).format(
        'DD/MMM/YYYY'
      )} `,
    }
    const dateDiff = moment(dateData).diff(moment(scheduleData.start), 'days')
    const repeatDays = 1 * parseInt(repeat, 10)
    for (let i = 0; i <= dateDiff; i += repeatDays) {
      const eventData5 = {
        id: `t1${Math.random().toString()}`,
        title: type.sort().join(' . '),
        start: moment(scheduleData.start).add(i, 'days').toDate(),
        end: moment(endDate).add(i, 'days').toDate(),
        extendedProps: evenProp,
        version: '',
        groupId: `recurId${groupId}`,
      }

      if (calendarApi) {
        calendarApi.addEvent(eventData5)
        if (onCalendarSelectionChanged) {
          onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
        }
      }
    }
  }

  const getEventWithRepeatEndsOnDateDaily = (
    type: string[],
    date: Date,
    scheduleData: EventApi,
    eventDate: Date,
    repeat: string
  ) => {
    const date1 = moment(eventDate).format('YYYY-MM-DD')
    const time = moment(scheduleData.start).format('HH:mm')
    const endTIme = moment(scheduleData.end).format('HH:mm')
    const timeAndDate = moment(`${date1} ${time}`)
    const endDate = moment(`${date1} ${endTIme}`)
    scheduleData.setStart(moment(scheduleData.start).add(0, 'days').toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const dateData = moment(scheduleData.start).add(30, 'days').toDate()
    let repData = ''

    if (repeat === '2') {
      repData = `${repeat}nd`
    }
    if (repeat === '3') {
      repData = `${repeat}rd`
    }
    if (repeat === '4') {
      repData = `${repeat}th`
    }

    const dateDiff = moment(date).diff(moment(scheduleData.start), 'days')
    const repeatDays = 1 * parseInt(repeat, 10)
    for (let i = 0; i <= dateDiff; i += repeatDays) {
      if (calendarApi) {
        const listEvent = calendarApi.getEvents()
        listEvent.forEach((event) => {
          const isSame = moment(moment(event.end)).isSame(
            moment(moment(scheduleData.end).add(i, 'days'))
          )
          const newEndDate = moment(scheduleData.end).add(i, 'days')
          const newStartDate = moment(scheduleData.start).add(i, 'days')
          const startDateBefor = moment(newEndDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const startDateAfter = moment(newStartDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const existingEvents: any = []
          if (
            startDateBefor === true ||
            startDateAfter === true ||
            isSame === true
          ) {
            if (event.extendedProps.versionId === undefined) {
              event.remove()
            } else {
              existingEvents.push(event.extendedProps.data)
              const scheduleBundle = getBulkScheduleBundle(existingEvents)
              calendarApi.refetchEvents()
              dispatch(
                removeScheduleDetails(
                  scheduleBundle,
                  selectedDoctor,
                  false,
                  calendarApi.getDate()
                )
              )
              dispatch(resetSchdeule())
              requestSearchSchedule(calendarApi.getDate())

              setDeletedSchedules([])
              if (onCalendarSelectionChanged) {
                onCalendarSelectionChanged(
                  calendarApi.getEvents(),
                  existingEvents
                )
              }
            }
          }
        })
      }
      const evenProp = {
        title: type.sort().join(' . '),
        repeat: `Repeats every ${repData} day till ${moment(date).format(
          'DD/MMM/YYYY'
        )} `,
        repeatLabel: `Every ${moment(scheduleData.start)
          .add(i, 'days')
          .format('dddd')} `,
      }
      const eventData6 = {
        id: `t1${Math.random().toString()}`,
        title: type.sort().join(' . '),
        start: moment(scheduleData.start).add(i, 'days').toDate(),
        end: moment(scheduleData.end).add(i, 'days').toDate(),
        extendedProps: evenProp,
        version: '',
        groupId: `recurId${groupId}`,
      }

      if (calendarApi) {
        calendarApi.addEvent(eventData6)
        if (onCalendarSelectionChanged) {
          onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
        }
      }
    }
  }

  /* default day custom with ends ON */

  const getEventWithRepeatEndsOnDateDefaultDay = (
    type: string[],
    date: Date,
    scheduleData: EventApi,
    eventDate: any,
    repeat: string
  ) => {
    const date1 = moment(scheduleData.start).format('YYYY-MM-DD')
    const time = moment(scheduleData.start).format('HH:mm')
    const endTIme = moment(scheduleData.end).format('HH:mm')
    const timeAndDate = moment(`${date1} ${time}`)
    const endDate = moment(`${date1} ${endTIme}`)
    scheduleData.setStart(moment(timeAndDate).toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const dateData = moment(scheduleData.start).add(30, 'days').toDate()
    let repData = ''

    if (repeat === '2') {
      repData = `${repeat}nd`
    }
    if (repeat === '3') {
      repData = `${repeat}rd`
    }
    if (repeat === '4') {
      repData = `${repeat}th`
    }

    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats every ${repData} day till ${moment(date).format(
        'DD/MMM/YYYY'
      )} `,
    }
    const dateDiff = moment(date).diff(moment(scheduleData.start), 'days')

    const repeatDays = 1 * parseInt(repeat, 10)
    for (let i = 0; i <= dateDiff; i += repeatDays) {
      const eventData7 = {
        id: `t1${Math.random().toString()}`,
        title: type.sort().join(' . '),
        start: moment(scheduleData.start).add(i, 'days').toDate(),
        end: moment(endDate).add(i, 'days').toDate(),
        extendedProps: evenProp,
        version: '',
        groupId: `recurId${groupId}`,
      }

      if (calendarApi) {
        calendarApi.addEvent(eventData7)
        if (onCalendarSelectionChanged) {
          onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
        }
      }
    }
  }

  const getEventWithRepeatChangeDaily = (
    type: string[],
    date: Date,
    scheduleData: EventApi,
    eventDate: any
  ) => {
    scheduleData.setStart(moment(scheduleData.start).add(0, 'days').toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const dateData = moment(scheduleData.start).add(30, 'days').toDate()

    const dateDiff = moment(dateData).diff(moment(scheduleData.start), 'days')
    for (let i = 0; i <= dateDiff; i += 1) {
      if (calendarApi) {
        const listEvent = calendarApi.getEvents()
        listEvent.forEach((event) => {
          const isSame = moment(moment(event.end)).isSame(
            moment(moment(scheduleData.end).add(i, 'days'))
          )
          const newEndDate = moment(scheduleData.end).add(i, 'days')
          const newStartDate = moment(scheduleData.start).add(i, 'days')
          const startDateBefor = moment(newEndDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const startDateAfter = moment(newStartDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const existingEvents: any = []
          if (
            startDateBefor === true ||
            startDateAfter === true ||
            isSame === true
          ) {
            if (event.extendedProps.versionId === undefined) {
              event.remove()
            } else {
              existingEvents.push(event.extendedProps.data)
              const scheduleBundle = getBulkScheduleBundle(existingEvents)
              calendarApi.refetchEvents()
              dispatch(
                removeScheduleDetails(
                  scheduleBundle,
                  selectedDoctor,
                  false,
                  calendarApi.getDate()
                )
              )
              dispatch(resetSchdeule())
              requestSearchSchedule(calendarApi.getDate())

              setDeletedSchedules([])
              if (onCalendarSelectionChanged) {
                onCalendarSelectionChanged(
                  calendarApi.getEvents(),
                  existingEvents
                )
              }
            }
          }
        })
      }
      let isOk: boolean = false
      const dateFinalData = moment(scheduleData.start).add(i, 'days')
      for (let k = 0; k < businessHoursData.length; k++) {
        const dayName = moment()
          .day(businessHoursData[k].daysOfWeek[0])
          .format('dddd')
        const eventDayName = moment(scheduleData.start)
          .add(i, 'days')
          .format('dddd')
        const evDate = moment(scheduleData.start)
          .add(i, 'days')
          .format('YYYY-MM-DD')
        if (dayName.includes(eventDayName)) {
          const start = new Date(`${evDate} ${businessHoursData[k].startTime}`)
          const startFinal = moment(start)
          const end = new Date(`${evDate} ${businessHoursData[k].endTime}`)
          const endFinal = moment(end)
          if (dateFinalData.isBetween(startFinal, endFinal)) {
            isOk = true
          } else {
            isOk = false
          }
        }
      }
      if (isOk) {
        const evenProp = {
          title: type.sort().join(' . '),
          repeat: `Repeats everyday till  ${moment(dateData).format(
            'DD/MMM/YY'
          )}`,
          repeatLabel: `Every ${moment(scheduleData.start)
            .add(i, 'days')
            .format('dddd')} `,
        }
        const eventData8 = {
          id: `t1${Math.random().toString()}`,
          title: type.sort().join(' . '),
          start: moment(scheduleData.start).add(i, 'days').toDate(),
          end: moment(scheduleData.end).add(i, 'days').toDate(),
          extendedProps: evenProp,
          version: '',
          groupId: `recurId${groupId}`,
        }

        if (calendarApi) {
          calendarApi.addEvent(eventData8)
          if (onCalendarSelectionChanged) {
            onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
          }
        }
      }
    }
  }

  const getEventWithRepeatChangeDailyOccurrence = (
    type: any,
    date: Date,
    scheduleData: EventApi,
    eventDate: any,
    occur: number,
    scheduleDate: any
  ) => {
    scheduleData.setStart(moment(scheduleData.start).add(0, 'days').toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const occurData = 1 * occur
    const dateData = moment(scheduleData.start)
      .add(occurData - 1, 'days')
      .toDate()
    for (let i = 0; i < occur; i += 1) {
      if (calendarApi) {
        const listEvent = calendarApi.getEvents()
        listEvent.forEach((event) => {
          const isSame = moment(moment(event.end)).isSame(
            moment(moment(scheduleData.end).add(i, 'days'))
          )
          const newEndDate = moment(scheduleData.end).add(i, 'days')
          const newStartDate = moment(scheduleData.start).add(i, 'days')
          const startDateBefor = moment(newEndDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const startDateAfter = moment(newStartDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const existingEvents: any = []
          if (
            startDateBefor === true ||
            startDateAfter === true ||
            isSame === true
          ) {
            if (event.extendedProps.versionId === undefined) {
              event.remove()
            } else {
              existingEvents.push(event.extendedProps.data)
              const scheduleBundle = getBulkScheduleBundle(existingEvents)
              calendarApi.refetchEvents()
              dispatch(
                removeScheduleDetails(
                  scheduleBundle,
                  selectedDoctor,
                  false,
                  calendarApi.getDate()
                )
              )
              dispatch(resetSchdeule())
              requestSearchSchedule(calendarApi.getDate())

              setDeletedSchedules([])
              if (onCalendarSelectionChanged) {
                onCalendarSelectionChanged(
                  calendarApi.getEvents(),
                  existingEvents
                )
              }
            }
          }
        })
      }
      const evenProp = {
        title: type.sort().join(' . '),
        repeat: `Repeats everyday till  ${moment(dateData).format(
          'DD/MMM/YY'
        )}`,
        repeatLabel: `Every ${moment(scheduleData.start)
          .add(i, 'days')
          .format('dddd')} `,
      }
      const eventData9 = {
        id: `t1${Math.random().toString()}`,
        title: type.sort().join(' . '),
        start: moment(scheduleData.start).add(i, 'days').toDate(),
        end: moment(scheduleData.end).add(i, 'days').toDate(),
        extendedProps: evenProp,
        version: '',
        groupId: `recurId${groupId}`,
      }

      if (calendarApi) {
        calendarApi.addEvent(eventData9)
        if (onCalendarSelectionChanged) {
          onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
        }
      }
    }
  }

  const getEventWithRepeatChangeDailyDateRange = (
    type: string[],
    date: Date,
    scheduleData: EventApi,
    eventDate: Date,
    occur: number,
    scheduleDate: any,
    dateRange: string
  ) => {
    const date1 = moment(eventDate).format('YYYY-MM-DD')
    const time = moment(scheduleData.start).format('HH:mm')
    const endTIme = moment(scheduleData.end).format('HH:mm')
    const timeAndDate = moment(`${date1} ${time}`)
    const endDate = moment(`${date1} ${endTIme}`)
    scheduleData.setStart(moment(scheduleData.start).add(0, 'days').toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    let repData = ''

    if (dateRange === '2') {
      repData = `${dateRange}nd`
    }
    if (dateRange === '3') {
      repData = `${dateRange}rd`
    }
    if (dateRange === '4') {
      repData = `${dateRange}th`
    }
    if (dateRange === '5') {
      repData = `${dateRange}th`
    }
    if (dateRange === '6') {
      repData = `${dateRange}th`
    }

    const labelData =
      repData.length === 0 ? 'everyday' : `every ${repData} day `

    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats ${labelData}, ${occur} times`,
    }
    const reps = parseInt(dateRange, 10) * occur
    const range = parseInt(dateRange, 10)
    for (let i = 0; i < reps; i += range) {
      if (calendarApi) {
        const listEvent = calendarApi.getEvents()
        listEvent.forEach((event) => {
          const isSame = moment(moment(event.end)).isSame(
            moment(scheduleData.end).add(i, 'days')
          )
          const newEndDate = moment(scheduleData.end).add(i, 'days')
          const newStartDate = moment(scheduleData.start).add(i, 'days')
          const startDateBefor = moment(newEndDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const startDateAfter = moment(newStartDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const existingEvents: any = []
          if (
            startDateBefor === true ||
            startDateAfter === true ||
            isSame === true
          ) {
            if (event.extendedProps.versionId === undefined) {
              event.remove()
            } else {
              existingEvents.push(event.extendedProps.data)
              const scheduleBundle = getBulkScheduleBundle(existingEvents)
              calendarApi.refetchEvents()
              dispatch(
                removeScheduleDetails(
                  scheduleBundle,
                  selectedDoctor,
                  false,
                  calendarApi.getDate()
                )
              )
              dispatch(resetSchdeule())
              requestSearchSchedule(calendarApi.getDate())

              setDeletedSchedules([])
              if (onCalendarSelectionChanged) {
                onCalendarSelectionChanged(
                  calendarApi.getEvents(),
                  existingEvents
                )
              }
            }
          }
        })
      }
      const eventData10 = {
        id: `t1${Math.random().toString()}`,
        title: type.sort().join(' . '),
        start: moment(scheduleData.start).add(i, 'days').toDate(),
        end: moment(scheduleData.end).add(i, 'days').toDate(),
        extendedProps: evenProp,
        version: '',
        groupId: `recurId${groupId}`,
      }

      if (calendarApi) {
        calendarApi.addEvent(eventData10)
        if (onCalendarSelectionChanged) {
          onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
        }
      }
    }
  }

  /* Custom Day No change */
  const getEventWithRepeatChangeDailyDateRangeNoChange = (
    type: string[],
    date: Date,
    scheduleData: EventApi,
    eventDate: any,
    occur: number,
    scheduleDate: any,
    dateRange: string
  ) => {
    const date1 = moment(scheduleData.start).format('YYYY-MM-DD')
    const time = moment(scheduleData.start).format('HH:mm')
    const endTIme = moment(scheduleData.end).format('HH:mm')
    const timeAndDate = moment(`${date1} ${time}`)
    const endDate = moment(`${date1} ${endTIme}`)
    scheduleData.setStart(moment(timeAndDate).toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()

    let repData = ''

    if (dateRange === '2') {
      repData = `${dateRange}nd`
    }
    if (dateRange === '3') {
      repData = `${dateRange}rd`
    }
    if (dateRange === '4') {
      repData = `${dateRange}th`
    }

    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats every ${repData} day`,
    }
    const reps = parseInt(dateRange, 10) * occur
    const range = parseInt(dateRange, 10)
    for (let i = 0; i < reps; i += range) {
      const eventData11 = {
        id: `t1${Math.random().toString()}`,
        title: type.sort().join(' . '),
        start: moment(timeAndDate).add(i, 'days').toDate(),
        end: moment(endDate).add(i, 'days').toDate(),
        extendedProps: evenProp,
        version: '',
        groupId: `recurId${groupId}`,
      }

      if (calendarApi) {
        calendarApi.addEvent(eventData11)
        if (onCalendarSelectionChanged) {
          onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
        }
      }
    }
  }

  /* Custom Weekly change */
  const getEventWithRepeatChangeWeekDefault = (
    type: string[],
    occur: number,
    scheduleData: EventApi,
    eventDate: any,
    dateRange: number
  ) => {
    scheduleData.setStart(moment(scheduleData.start).add(0, 'days').toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const reps = 7 * occur
    const range = 7 * dateRange
    const dateData = moment(scheduleData.end).add(reps, 'days').toDate()
    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats every ${moment(scheduleData.start).format(
        'dddd'
      )} till ${moment(dateData).format('DD/MMM/YY')}`,
    }

    for (let i = 0; i < reps; i += range) {
      const eventData12 = {
        id: `t1${Math.random().toString()}`,
        title: type.sort().join(' . '),
        start: moment(scheduleData.start).add(i, 'days').toDate(),
        end: moment(scheduleData.end).add(i, 'days').toDate(),
        extendedProps: evenProp,
        version: '',
        groupId: `recurId${groupId}`,
      }

      if (calendarApi) {
        calendarApi.addEvent(eventData12)
        if (onCalendarSelectionChanged) {
          onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
        }
      }
    }
  }

  const getEventWithRepeatChangeWeeklyOccurrence = (
    type: string[],
    date: Date,
    scheduleData: EventApi,
    eventDate: any,
    occur: number,
    scheduleDate: any
  ) => {
    scheduleData.setStart(moment(scheduleData.start).add(0, 'days').toDate())
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const diff = 7 * occur
    const dateData = moment(scheduleData.end)
      .add(diff - 1, 'days')
      .toDate()
    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats weekly on ${moment(scheduleData.start).format(
        'dddd'
      )}, ${occur} times `,
      repeatTimes: occur,
    }

    for (let i = 0; i < diff; i += 7) {
      if (calendarApi) {
        const listEvent = calendarApi.getEvents()
        listEvent.forEach((event) => {
          const isSame = moment(moment(event.end)).isSame(
            moment(moment(scheduleData.end).add(i, 'days'))
          )
          const newEndDate = moment(scheduleData.end).add(i, 'days')
          const newStartDate = moment(scheduleData.start).add(i, 'days')
          const startDateBefor = moment(newEndDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const startDateAfter = moment(newStartDate).isBetween(
            moment(event.start),
            moment(event.end)
          )
          const existingEvents: any = []
          if (
            startDateBefor === true ||
            startDateAfter === true ||
            isSame === true
          ) {
            if (event.extendedProps.versionId === undefined) {
              event.remove()
            } else {
              existingEvents.push(event.extendedProps.data)
              const scheduleBundle = getBulkScheduleBundle(existingEvents)
              calendarApi.refetchEvents()
              dispatch(
                removeScheduleDetails(
                  scheduleBundle,
                  selectedDoctor,
                  false,
                  calendarApi.getDate()
                )
              )
              dispatch(resetSchdeule())
              requestSearchSchedule(calendarApi.getDate())

              setDeletedSchedules([])
              if (onCalendarSelectionChanged) {
                onCalendarSelectionChanged(
                  calendarApi.getEvents(),
                  existingEvents
                )
              }
            }
          }
        })
      }
      const eventData13 = {
        id: `t1${Math.random().toString()}`,
        title: type.sort().join(' . '),
        start: moment(scheduleData.start).add(i, 'days').toDate(),
        end: moment(scheduleData.end).add(i, 'days').toDate(),
        extendedProps: evenProp,
        version: '',
        groupId: `recurId${groupId}`,
      }

      if (calendarApi) {
        calendarApi.addEvent(eventData13)
        if (onCalendarSelectionChanged) {
          onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
        }
      }
    }
  }
  /* Monthly With Occurance & Date change custom setting  */
  const getEventWithRepeatEndChangeMonthWithOccur = (
    type: string[],
    occurrence: any,
    scheduleData: EventApi,
    eventDate: Date,
    repeat: any,
    dateData: Date,
    repeatLabel: string
  ) => {
    const date1 = moment(eventDate).format('YYYY-MM-DD')
    const time = moment(scheduleData.start).format('HH:mm')
    const endTIme = moment(scheduleData.end).format('HH:mm')
    const timeAndDate = moment(`${date1} ${time}`)
    const endDate = moment(`${date1} ${endTIme}`)
    // scheduleData.setStart(moment(timeAndDate).toDate());
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const dateDiff = moment(dateData).diff(moment(eventDate), 'days')
    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats ${repeatLabel} till ${moment(dateData).format(
        'DD/MMM/YYYY'
      )} `,
      repeatLabel: `${repeatLabel}`,
    }
    for (let i = 0; i <= dateDiff; i += 7) {
      const eventData14 = {
        id: `t1${Math.random().toString()}`,
        title: type.sort().join(' . '),
        start: moment(timeAndDate).add(i, 'days').toDate(),
        end: moment(endDate).add(i, 'days').toDate(),
        extendedProps: evenProp,
        version: '',
        groupId: `recurId${groupId}`,
      }

      if (calendarApi) {
        calendarApi.addEvent(eventData14)
        if (onCalendarSelectionChanged) {
          onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
        }
      }
    }
  }

  /* Monthly With Occurance & No Date change custom setting  */

  const getEventWithRepeatEndChangeMonthWithOccurNoChnage = (
    type: string[],
    occurrence: number,
    scheduleData: EventApi,
    eventDate: any,
    repeat: number
  ) => {
    const date1 = moment(scheduleData.start).format('YYYY-MM-DD')
    const time = moment(scheduleData.start).format('HH:mm')
    const endTIme = moment(scheduleData.end).format('HH:mm')
    const timeAndDate = moment(`${date1} ${time}`)
    const endDate = moment(`${date1} ${endTIme}`)
    // scheduleData.setStart(moment(timeAndDate).toDate());
    const groupId = scheduleData.start
    const calendarApi = calendarRef?.current?.getApi()
    const repeatDays = 30 * occurrence

    const diff = 30 * repeat
    const dateData = moment(timeAndDate).add(repeatDays, 'days').toDate()

    const evenProp = {
      title: type.sort().join(' . '),
      repeat: `Repeats monthly till ${moment(dateData).format('DD/MMM/YYYY')} `,
    }
    const existingEvents: any = []
    if (calendarRef?.current != null) {
      const calendarApi1 = calendarRef?.current.getApi()

      if (scheduleData.extendedProps.versionId === undefined) {
        scheduleData.remove()
      } else {
        existingEvents.push(scheduleData.extendedProps.data)
      }
      const scheduleBundle = getBulkScheduleBundle(existingEvents)
      dispatch(
        removeScheduleDetails(
          scheduleBundle,
          selectedDoctor,
          false,
          calendarApi1.getDate()
        )
      )
      dispatch(resetSchdeule())
      requestSearchSchedule(calendarApi1.getDate())
      setDeletedSchedules(existingEvents)
      if (onCalendarSelectionChanged) {
        calendarApi1.refetchEvents()
        onCalendarSelectionChanged(calendarApi1.getEvents(), existingEvents)
      }
    }
    for (let i = 0; i < repeatDays; i += diff) {
      const eventData15 = {
        id: `new${Math.random().toString()}`,
        title: type.sort().join(' . '),
        start: moment(timeAndDate).add(i, 'days').toDate(),
        end: moment(endDate).add(i, 'days').toDate(),
        extendedProps: evenProp,
        version: '',
        groupId: `recurId${groupId}`,
      }

      if (calendarApi) {
        calendarApi.addEvent(eventData15)
        if (onCalendarSelectionChanged) {
          onCalendarSelectionChanged(calendarApi.getEvents(), deletedScheules)
        }
      }
    }
  }
  if (calendarRef?.current != null) {
    const calendarApi = calendarRef?.current.getApi()
    if (calendarApi) {
      calendarApi.refetchEvents()
    }
  }

  return (
    <div>
      {eventData.length === 0 &&
        editable === false &&
        deletedScheules.length === 0 && (
          <Collapse in={displayWarning}>
            <Alert
              onClose={() => {
                setDisplayWarning(false)
              }}
              severity='warning'
              style={{ backgroundColor: kBackgroundPaper, opacity: 0.8 }}
            >
              <Typography variant='body1' style={{ paddingLeft: 1.5 }}>
                {t('labelCommon:No working hours exist for')}{' '}
                {selectedDoctor.name}
              </Typography>
            </Alert>
          </Collapse>
        )}
      <Box id='calendarView' style={{ overflow: 'none' }}>
        <Grid
          container
          direction='row'
          alignContent='space-between'
          justify='space-between'
        >
          {personName !== 'lab-agent' && (
            <Grid item>
              {editable === false ? (
                <Box
                  paddingLeft={5}
                  paddingTop={eventData.length === 0 ? 3 : 3}
                  style={{ overflow: 'none' }}
                >
                  <Typography component='span' variant='subtitle1'>
                    {t('labelCommon:working_hours')}
                  </Typography>
                </Box>
              ) : (
                <Box paddingLeft={5} paddingTop={1.5}>
                  {/* <CustomizedDividers
                  onSelectionChanged={(value) => {
                    setSelectedRepeat(value)
                  }}
                  menuLabel='REPEATS'
                  options={repeats}
                  preSelectedValue={selectedRepeat}
                /> */}

                  <TabbedMultiSelector
                    menuLabel={t('labelCommon:appointment_types')}
                    onSelectionChanged={(value) => {
                      setAppointmentType(value)
                      setType(value)
                      logger.info(type1)
                    }}
                    options={menu}
                    preSelectedValues={type1}
                  />
                </Box>
              )}
            </Grid>
          )}
          <Grid item>
            {/* {personName === 'doctor' ||
              (personName === 'ayurveda' && ( */}
            <Grid container direction='column' justify='flex-end'>
              <Grid item xs>
                {editable === false ? (
                  <Box paddingTop={1} />
                ) : (
                  <Box paddingTop={3}>
                    <CustomizedDividers
                      onSelectionChanged={(value) => {
                        setSelectedRepeat(value)
                      }}
                      menuLabel={t('labelCommon:repeat')}
                      options={repeats}
                      preSelectedValue={selectedRepeat}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
            {/* ))} */}
          </Grid>

          <Grid item xs={5}>
            <Grid container direction='row' justify='flex-end'>
              <Grid item>
                <Box
                  paddingTop={editable === false ? 2.5 : 4.5}
                  paddingRight={2}
                >
                  <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    onClick={() => {
                      if (calendarRef?.current != null) {
                        const calendarApi = calendarRef?.current.getApi()
                        calendarApi.gotoDate(new Date())

                        setPreSelectedDate(getPreConfiguredDate(new Date()))
                      }
                    }}
                    id='today'
                  >
                    <Typography variant='button'>
                      {t('labelCommon:today')}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <Box paddingTop={editable === false ? 2 : 0}>
                  <WelloSelect
                    key={preSelectedDate.id}
                    label={editable === false ? '' : t('labelCommon:viewing')}
                    availableOptions={displayoptionList}
                    required={true}
                    type='Week'
                    displayField='title'
                    valueField='id'
                    id='days'
                    preSelectedOption={preSelectedDate}
                    onSelectionChanged={(value: any) => {
                      if (calendarRef?.current != null) {
                        const calendarApi = calendarRef?.current.getApi()
                        setPreSelectedDate(
                          getPreConfiguredDate(moment(value.id).toDate())
                        )
                        calendarApi.addEvent(appointments)
                        calendarApi.gotoDate(value.id)
                        calendarApi.changeView('timeGridWeek')
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box
                  paddingTop={editable === false ? 3 : 5}
                  paddingLeft={1}
                  id='back'
                >
                  <ArrowBackIcon
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (calendarRef?.current != null) {
                        const calendarApi = calendarRef?.current.getApi()
                        calendarApi.addEvent(appointments)
                        calendarApi.prev()
                        const currentWeek = moment(new Date()).week()
                        const compareWeek = moment(calendarApi.getDate()).week()
                        const daysDiff: number = currentWeek - compareWeek
                        if (daysDiff === 0) {
                          setPreSelectedDate(
                            getPreConfiguredDate(new Date()).id
                          )
                        } else {
                          setPreSelectedDate(
                            getPreConfiguredDate(calendarApi.getDate())
                          )
                        }
                        requestSearchSchedule(calendarApi.getDate())
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box
                  paddingTop={editable === false ? 3 : 5}
                  paddingRight={1}
                  id='forward'
                >
                  <ArrowForwardIcon
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (calendarRef?.current != null) {
                        const calendarApi = calendarRef?.current.getApi()
                        calendarApi.addEvent(appointments)
                        calendarApi.next()
                        const currentWeek = moment(new Date()).week()
                        const compareWeek = moment(calendarApi.getDate()).week()
                        const daysDiff: number = currentWeek - compareWeek
                        if (daysDiff === 0) {
                          setPreSelectedDate(getPreConfiguredDate(new Date()))
                        } else {
                          setPreSelectedDate(
                            getPreConfiguredDate(calendarApi.getDate())
                          )
                        }
                        requestSearchSchedule(calendarApi.getDate())
                      }
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <div
        className={editable === false ? classes.fc : classes.fcFalse}
        style={{ overflow: 'auto', maxHeight: '100%' }}
        id='calendar'
      >
        <FullCalendar
          ref={calendarRef}
          headerToolbar={{
            left: '',
            right: '',
          }}
          height={val}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            momentPlugin,
          ]}
          initialView='timeGridWeek'
          editable={true}
          weekends={shoWFullWeek}
          selectOverlap={false}
          selectable={editable}
          selectAllow={(select) => {
            const ms = moment()
            return ms.isSameOrBefore(select.start)
          }}
          loading={(isLoading) => {}}
          events={eventData.map((item: any) => ({
            id: item.id,
            start: item.startTime,
            end: item.endTime,
            title: item.title,
            versionId: item.versionId,
            groupId:
              item.repeat === 'Does not repeat'
                ? Math.random().toString()
                : item.repeat === undefined
                ? ''
                : item.repeat,
            extendedProps: {
              versionId: item.versionId,
              repeat: item.repeat,
              data: item.schedule,
              repeatLabel: item.repeat,
              repeatTimes: item.occur,
              repeatOn: item.date,
              slot: item.slotData,
            },
          }))}
          select={(select) => {
            saved = false
            let listEvents: EventApi[] = []
            const calendarApi = calendarRef?.current?.getApi()
            if (calendarApi) {
              listEvents = calendarApi.getEvents()
            }

            if (selectedRepeat === 'No') {
              const eventCopy = {
                title: appointmentType.join(' . '),
                repeat: 'Does not repeat',
                repeatLabel: `Every ${moment(select.start).format('dddd')}`,
              }
              const event = {
                id: `new${Math.random().toString()}`,
                title: appointmentType.sort().join(' . '),
                start: select.start,
                end: select.end,
                extendedProps: eventCopy,
                version: '',
              }

              if (calendarApi) {
                calendarApi.addEvent(event)
                calendarApi.refetchEvents()
                calendarApi.unselect()
                SetAppointments(calendarApi.getEvents())
                if (onCalendarSelectionChanged) {
                  onCalendarSelectionChanged(
                    calendarApi.getEvents(),
                    deletedScheules
                  )
                }
              }
              if (calendarApi) {
                dispatch(
                  requestForSchedules(selectedDoctor, calendarApi.getDate())
                )
              }
            } else {
              const answer = window.confirm(
                `Working hours will be updated. Do you want to continue?`
              )
              if (answer) {
                dispatch(resetSchdeule())
                const groupId = select.start
                const date = moment(select.start).add(30, 'days').toDate()
                const dateDiff = moment(date).diff(moment(select.start), 'days')
                const evenProp = {
                  title: appointmentType.join(' . '),
                  repeat: `Repeats every  ${moment(select.start).format(
                    'dddd'
                  )} till ${moment(date).format('DD/MMM/YY')}`,
                  repeatLabel: `Every ${moment(select.start).format(
                    'dddd'
                  )} till ${moment(date).format('DD/MMM/YY')}`,
                }
                for (let i = 0; i < dateDiff; i += 7) {
                  const eventDataOccur = {
                    id: `new${Math.random().toString()}`,
                    title: appointmentType.sort().join(' . '),
                    start: moment(select.start).add(i, 'days').toDate(),
                    end: moment(select.end).add(i, 'days').toDate(),
                    extendedProps: evenProp,
                    version: '',
                    groupId: `recurId${groupId}`,
                  }

                  if (calendarApi) {
                    listEvents.forEach((event: EventApi) => {
                      const isSame = moment(moment(event.end)).isSame(
                        moment(select.end).add(i, 'days')
                      )
                      const newEndDate = moment(select.end).add(i, 'days')
                      const newStartDate = moment(select.start).add(i, 'days')
                      const startDateBefor = moment(newEndDate).isBetween(
                        moment(event.start),
                        moment(event.end)
                      )
                      const isStartSame = moment(moment(event.start)).isSame(
                        moment(select.start).add(i, 'days')
                      )
                      const startDateAfter = moment(newStartDate).isBetween(
                        moment(event.start),
                        moment(event.end)
                      )
                      const existingEvents: EventApi[] = []
                      if (
                        startDateBefor === true ||
                        startDateAfter === true ||
                        isSame === true ||
                        isStartSame === true
                      ) {
                        if (event.extendedProps.versionId === undefined) {
                          event.remove()
                        } else {
                          existingEvents.push(event.extendedProps.data)
                          const scheduleBundle =
                            getBulkScheduleBundle(existingEvents)
                          calendarApi.refetchEvents()
                          dispatch(
                            removeScheduleDetails(
                              scheduleBundle,
                              selectedDoctor,
                              false,
                              calendarApi.getDate()
                            )
                          )
                          dispatch(resetSchdeule())
                          requestSearchSchedule(calendarApi.getDate())
                          dispatch(resetSchdeule())

                          setDeletedSchedules([])
                          if (onCalendarSelectionChanged) {
                            onCalendarSelectionChanged(
                              calendarApi.getEvents(),
                              existingEvents
                            )
                          }
                        }
                      }
                    })
                  }
                  if (calendarApi) {
                    calendarApi.addEvent(eventDataOccur)
                    calendarApi.unselect()
                    calendarApi.refetchEvents()
                    //   SetAppointments(calendarApi.getEvents())
                    if (onCalendarSelectionChanged) {
                      onCalendarSelectionChanged(
                        calendarApi.getEvents(),
                        deletedScheules
                      )
                    }
                  }
                }
                if (calendarApi) {
                  dispatch(
                    requestForSchedules(selectedDoctor, calendarApi.getDate())
                  )
                  dispatch(resetSchdeule())
                }
              }
            }
          }}
          scrollTime={moment(new Date()).format('HH:mm')}
          eventContent={(eventInfo: EventContentArg) => {
            if (moment(eventInfo.event.start).isSameOrBefore(moment())) {
              eventInfo.isDraggable = true
              eventInfo.isSelected = false
              eventInfo.isEndResizable = true
              eventInfo.isStartResizable = true
              eventInfo.isStartResizable = true
              // eslint-disable-next-line no-empty
              if (saved === true) {
                logger.info('true')
              }
              return (
                <ScheduleTile
                  scheduleDetail={eventInfo.event}
                  id={eventInfo.event.id}
                  date={
                    eventInfo.event.start !== null
                      ? eventInfo.event.start
                      : new Date()
                  }
                  selectedDoctor={selectedDoctor}
                  dateData={
                    calendarRef.current !== null
                      ? calendarRef.current.getApi().getDate()
                      : new Date()
                  }
                  selectedRepeat={selectedRepeat}
                  personName={personName}
                  onCancelClicked={(scheduleId: string, status: boolean) => {
                    if (calendarRef?.current != null) {
                      const calendarApi = calendarRef?.current.getApi()
                      requestSearchSchedule(calendarApi.getDate())
                      if (onCalendarSelectionChanged) {
                        onCalendarSelectionChanged(
                          calendarApi.getEvents(),
                          deletedScheules
                        )
                      }
                    }
                  }}
                  onEditClicked={(
                    type: string[],
                    selectedDateEdit: any,
                    eventDate: any,
                    scheduleData: any,
                    scheduleDate: any,
                    occurrence: any,
                    repeat: boolean,
                    repeatDataTile: any,
                    range: any,
                    repeatLabel: string,
                    customRepeat: string
                  ) => {
                    if (calendarRef?.current != null) {
                      const calendarApi = calendarRef?.current.getApi()
                      if (selectedDateEdit && eventDate === undefined) {
                        setEventsWithOnlyDate(type, selectedDate, scheduleData)
                        dispatch(
                          requestForSchedules(
                            selectedDoctor,
                            calendarApi.getDate()
                          )
                        )
                      }
                      if (selectedDate && eventDate === 'daily') {
                        getEventWithRepeatChange(
                          type,
                          selectedDate,
                          scheduleData
                        )
                        dispatch(
                          requestForSchedules(
                            selectedDoctor,
                            calendarApi.getDate()
                          )
                        )
                      }

                      if (selectedDate === undefined && eventDate === 'daily') {
                        getEventWithRepeatChangeDaily(
                          type,
                          selectedDate,
                          scheduleData,
                          eventDate
                        )
                        dispatch(
                          requestForSchedules(
                            selectedDoctor,
                            calendarApi.getDate()
                          )
                        )
                      }

                      if (occurrence !== undefined && eventDate === 'daily') {
                        getEventWithRepeatChangeDailyOccurrence(
                          type,
                          selectedDate,
                          scheduleData,
                          eventDate,
                          occurrence,
                          scheduleDate
                        )
                        dispatch(
                          requestForSchedules(
                            selectedDoctor,
                            calendarApi.getDate()
                          )
                        )
                      }
                    }
                  }}
                />
              )
            }
            eventInfo.isDraggable = true
            eventInfo.isSelected = true
            eventInfo.isEndResizable = true
            eventInfo.isStartResizable = true
            eventInfo.isStartResizable = true
            if (savedData === true) {
              if (calendarRef?.current != null) {
                savedData = false
                const calendarApi = calendarRef?.current.getApi()
                const listEvent = calendarApi.getEvents()
                listEvent.forEach((event) => {
                  if (event.id.includes('new') || event.id.includes('t1')) {
                    event.remove()
                  }
                })
              }
              if (
                eventInfo.event.id.includes('new') ||
                eventInfo.event.id.includes('t1')
              ) {
                eventInfo.event.remove()
              }
            }
            return (
              <ScheduleTile
                scheduleDetail={eventInfo.event}
                id={eventInfo.event.id}
                date={
                  eventInfo.event.start !== null
                    ? eventInfo.event.start
                    : new Date()
                }
                selectedDoctor={selectedDoctor}
                dateData={
                  calendarRef.current !== null
                    ? calendarRef.current.getApi().getDate()
                    : new Date()
                }
                selectedRepeat={selectedRepeat}
                personName={personName}
                onCancelClicked={(id1: any, status: boolean) => {
                  if (calendarRef?.current != null) {
                    savedData = false
                    const calendarApi = calendarRef?.current.getApi()
                    if (status === true) {
                      requestSearchSchedule(calendarApi.getDate())

                      if (onCalendarSelectionChanged) {
                        onCalendarSelectionChanged(
                          calendarApi.getEvents(),
                          deletedScheules
                        )
                      }
                    }
                  }
                }}
                onEditClicked={(
                  type: any,
                  selectedDateUsed: Date,
                  eventDate: any,
                  scheduleData: any,
                  occurrence: any,
                  scheduleDate: any,
                  repeat: boolean,
                  dataRange: any,
                  range: any,
                  repeatLabel: string,
                  customRepeat: string
                ) => {
                  dragEnable.current = true
                  savedData = false
                  setType(type)
                  setAppointmentType(type)
                  if (calendarRef?.current != null) {
                    savedData = false
                    const calendarApi = calendarRef?.current.getApi()
                    dispatch(
                      requestForSchedules(selectedDoctor, calendarApi.getDate())
                    )
                    calendarApi.refetchEvents()
                  }
                  if (
                    selectedDateUsed === undefined &&
                    eventDate === undefined &&
                    type !== undefined &&
                    range === undefined &&
                    occurrence === undefined &&
                    scheduleData.extendedProps.repeat === 'Does not repeat'
                  ) {
                    scheduleData.setStart(
                      moment(scheduleData.start).add(0, 'days').toDate()
                    )
                    setEventsWithOnlyTypeData(scheduleData, type)
                  }

                  if (eventDate === 'no') {
                    scheduleData.setStart(
                      moment(scheduleData.start).add(0, 'days').toDate()
                    )

                    setRepeat(repeat)
                    setEventTypeToRepeat(scheduleData, type)
                  }

                  if (
                    selectedDateUsed === undefined &&
                    eventDate === undefined &&
                    scheduleData.extendedProps.repeat !== 'Does not repeat' &&
                    range === undefined &&
                    occurrence === undefined
                  ) {
                    if (!repeat) {
                      setRepeat(repeat)
                      setEventsWithOnlyType(scheduleData, type)
                    }
                  }

                  if (
                    selectedDateUsed === undefined &&
                    eventDate === undefined &&
                    scheduleData.extendedProps.repeat === 'Does not repeat' &&
                    range === 'Weekly' &&
                    occurrence === undefined
                  ) {
                    setRepeat(repeat)
                    setEventsWithChangeCustomOnlyWeekly(
                      scheduleData,
                      type,
                      range,
                      dataRange
                    )
                  }

                  if (
                    selectedDateUsed === undefined &&
                    eventDate === undefined &&
                    scheduleData.extendedProps.repeat !== 'Does not repeat' &&
                    range === 'Weekly' &&
                    occurrence === undefined
                  ) {
                    setRepeat(repeat)
                    setEventsWithChangeCustomOnlyWeekly(
                      scheduleData,
                      type,
                      range,
                      dataRange
                    )
                  }

                  if (
                    selectedDateUsed === undefined &&
                    eventDate === undefined &&
                    scheduleData.extendedProps.repeat === 'Does not repeat' &&
                    range === 'Day' &&
                    occurrence === undefined
                  ) {
                    setRepeat(repeat)
                    setEventsWithChangeCustomOnlyDaily(
                      scheduleData,
                      type,
                      range,
                      dataRange
                    )
                  }

                  if (
                    selectedDateUsed === undefined &&
                    eventDate === undefined &&
                    scheduleData.extendedProps.repeat === 'Does not repeat' &&
                    range === 'Month' &&
                    occurrence === undefined
                  ) {
                    setRepeat(repeat)
                    setEventsWithChangeCustomOnlyMonthly(
                      scheduleData,
                      type,
                      range,
                      dataRange
                    )
                  }

                  if (
                    selectedDateUsed === undefined &&
                    eventDate === undefined &&
                    scheduleData.extendedProps.repeat !== 'Does not repeat' &&
                    range === 'Month' &&
                    occurrence === undefined
                  ) {
                    setRepeat(repeat)
                    setEventsWithChangeCustomOnlyMonthly(
                      scheduleData,
                      type,
                      range,
                      dataRange
                    )
                  }

                  if (
                    selectedDateUsed &&
                    eventDate === undefined &&
                    range === undefined
                  ) {
                    setRepeat(repeat)
                    if (
                      scheduleData.extendedProps.repeat.includes('everyday')
                    ) {
                      getEventWithRepeatChange(
                        type,
                        selectedDateUsed,
                        scheduleData
                      )
                    } else if (
                      scheduleData.extendedProps.repeat !== 'Does not repeat'
                    ) {
                      setEventsWithOnlyDate(
                        type,
                        selectedDateUsed,
                        scheduleData
                      )
                    }
                  }

                  if (
                    selectedDateUsed &&
                    eventDate !== undefined &&
                    range === undefined &&
                    eventDate !== 'daily'
                  ) {
                    const answer = window.confirm(
                      `Working hours will be updated. Do you want to continue?`
                    )

                    if (answer) {
                      setRepeat(repeat)
                      setEventsWithOnlyDate(
                        type,
                        selectedDateUsed,
                        scheduleData
                      )
                    }
                  }

                  if (
                    eventDate !== undefined &&
                    eventDate !== 'no' &&
                    range === undefined &&
                    eventDate !== 'daily' &&
                    selectedDateUsed === undefined &&
                    occurrence === undefined
                  ) {
                    const answer = window.confirm(
                      `Working hours will be updated. Do you want to continue?`
                    )
                    if (answer) {
                      setRepeat(repeat)
                      setEventsWithDefaultRepeat(type, eventDate, scheduleData)
                    }
                  }

                  if (selectedDateUsed && eventDate === 'daily') {
                    const answer = window.confirm(
                      `Working hours will be updated. Do you want to continue?`
                    )

                    if (answer) {
                      setRepeat(repeat)
                      getEventWithRepeatChange(
                        type,
                        selectedDateUsed,
                        scheduleData
                      )
                    }
                  }

                  if (
                    selectedDateUsed === undefined &&
                    eventDate === 'daily' &&
                    occurrence === undefined
                  ) {
                    const answer = window.confirm(
                      `Working hours will be updated. Do you want to continue?`
                    )
                    if (answer) {
                      //   scheduleData.setStart(
                      //     moment(scheduleData.start).add(0, 'days').toDate()
                      //   )
                      setRepeat(repeat)
                      getEventWithRepeatChangeDaily(
                        type,
                        selectedDateUsed,
                        scheduleData,
                        eventDate
                      )
                    }
                  }

                  if (occurrence !== undefined && eventDate === 'daily') {
                    const answer = window.confirm(
                      `Working hours will be updated. Do you want to continue?`
                    )

                    if (answer) {
                      setRepeat(repeat)
                      getEventWithRepeatChangeDailyOccurrence(
                        type,
                        selectedDateUsed,
                        scheduleData,
                        eventDate,
                        occurrence,
                        scheduleDate
                      )
                    }
                  }

                  if (
                    occurrence !== undefined &&
                    eventDate !== 'daily' &&
                    eventDate !== undefined &&
                    dataRange !== undefined &&
                    range === 'Day'
                  ) {
                    const answer = window.confirm(
                      `Working hours will be updated. Do you want to continue?`
                    )
                    if (answer) {
                      setRepeat(repeat)
                      getEventWithRepeatChangeDailyDateRange(
                        type,
                        selectedDateUsed,
                        scheduleData,
                        eventDate,
                        occurrence,
                        scheduleDate,
                        dataRange
                      )
                    }
                  }

                  if (
                    occurrence !== undefined &&
                    eventDate !== 'daily' &&
                    eventDate === undefined &&
                    dataRange !== undefined &&
                    range === 'Day'
                  ) {
                    setRepeat(repeat)
                    getEventWithRepeatChangeDailyDateRangeNoChange(
                      type,
                      selectedDateUsed,
                      scheduleData,
                      eventDate,
                      occurrence,
                      scheduleDate,
                      dataRange
                    )
                  }

                  if (
                    occurrence === undefined &&
                    eventDate === undefined &&
                    selectedDateUsed === undefined &&
                    dataRange !== undefined &&
                    scheduleData.extendedProps.repeat !== 'Does not repeat' &&
                    range === 'Day'
                  ) {
                    setRepeat(repeat)
                    getEventWithRepeatChangeDateDailyCustom(
                      type,
                      selectedDateUsed,
                      scheduleData,
                      eventDate,
                      dataRange
                    )
                    // getEventWithRepeatChangeDailyDateRange(
                    //   type,
                    //   selectedDate,
                    //   scheduleData,
                    //   eventDate,
                    //   occurrence,
                    //   scheduleDate,
                    //   dataRange
                    // )
                  }

                  if (
                    selectedDateUsed === undefined &&
                    eventDate !== 'daily' &&
                    eventDate !== undefined &&
                    dataRange !== undefined &&
                    range === 'Weekly' &&
                    occurrence === undefined
                  ) {
                    setRepeat(repeat)
                    const answer = window.confirm(
                      `Working hours will be updated. Do you want to continue?`
                    )
                    if (answer) {
                      scheduleData.setStart(
                        moment(scheduleData.start).add(0, 'days').toDate()
                      )

                      for (let i = 0; i < eventDate.length; i++) {
                        const dateData = moment(eventDate[i])
                          .add(30, 'days')
                          .toDate()
                        getEventWithRepeatChangeDate(
                          type,
                          selectedDateUsed,
                          scheduleData,
                          eventDate[i],
                          dataRange,
                          dateData,
                          repeatLabel
                        )
                      }
                    }
                  }

                  if (
                    selectedDateUsed === undefined &&
                    eventDate !== 'daily' &&
                    eventDate !== undefined &&
                    dataRange !== undefined &&
                    range === 'Weekly' &&
                    occurrence !== undefined
                  ) {
                    const answer = window.confirm(
                      `Working hours will be updated. Do you want to continue?`
                    )
                    if (answer) {
                      setRepeat(repeat)
                      scheduleData.setStart(
                        moment(scheduleData.start).add(0, 'days').toDate()
                      )
                      for (let i = 0; i < eventDate.length; i++) {
                        const dateData = moment(eventDate[i])
                          .add(occurrence * 7 - 1, 'days')
                          .toDate()
                        getEventWithRepeatChangeDateWithOccurance(
                          type,
                          selectedDateUsed,
                          scheduleData,
                          eventDate[i],
                          dataRange,
                          dateData,
                          repeatLabel
                        )
                      }
                    }
                  }

                  if (
                    selectedDateUsed === undefined &&
                    occurrence !== undefined &&
                    eventDate === undefined &&
                    dataRange !== undefined &&
                    range === 'Weekly'
                  ) {
                    setRepeat(repeat)
                    getEventWithRepeatChangeWeekDefault(
                      type,
                      occurrence,
                      scheduleData,
                      eventDate,
                      dataRange
                    )
                  }

                  if (
                    selectedDateUsed === undefined &&
                    occurrence === undefined &&
                    eventDate !== 'daily' &&
                    eventDate !== 'no' &&
                    eventDate !== undefined &&
                    dataRange !== undefined &&
                    range === 'Month'
                    // scheduleData.extendedProps.repeat === 'Does not repeat'
                  ) {
                    const answer = window.confirm(
                      `Working hours will be updated. Do you want to continue?`
                    )
                    if (answer) {
                      scheduleData.setStart(
                        moment(scheduleData.start).add(0, 'days').toDate()
                      )
                      if (customRepeat[0] === 'All') {
                        for (let i = 0; i < eventDate.length; i++) {
                          getEventWithRepeatChangeMonth(
                            type,
                            selectedDateUsed,
                            scheduleData,
                            eventDate[i],
                            dataRange,
                            repeatLabel
                          )
                        }
                      } else {
                        for (let i = 0; i < eventDate.length; i++) {
                          getEventWithRepeatChangeMonthWithRepeatsOn(
                            type,
                            selectedDateUsed,
                            scheduleData,
                            eventDate[i],
                            dataRange,
                            repeatLabel,
                            customRepeat
                          )
                        }
                      }
                    }
                  }

                  if (
                    selectedDateUsed !== undefined &&
                    eventDate !== 'daily' &&
                    eventDate !== 'no' &&
                    eventDate !== undefined &&
                    dataRange !== undefined &&
                    range === 'Month'
                  ) {
                    scheduleData.setStart(
                      moment(scheduleData.start).add(0, 'days').toDate()
                    )
                    setRepeat(repeat)
                    for (let i = 0; i < eventDate.length; i++) {
                      getEventWithRepeatEndChangeMonth(
                        type,
                        selectedDateUsed,
                        scheduleData,
                        eventDate[i],
                        dataRange,
                        repeatLabel
                      )
                    }
                  }

                  if (
                    selectedDateUsed !== undefined &&
                    eventDate !== 'daily' &&
                    eventDate !== 'no' &&
                    eventDate === undefined &&
                    dataRange !== undefined &&
                    range === 'Month'
                  ) {
                    setRepeat(repeat)
                    getEventWithRepeatEndChangeMonthNoEventChange(
                      type,
                      selectedDateUsed,
                      scheduleData,
                      eventDate,
                      dataRange
                    )
                  }

                  if (
                    occurrence !== undefined &&
                    eventDate !== 'daily' &&
                    eventDate !== 'no' &&
                    eventDate !== undefined &&
                    dataRange !== undefined &&
                    range === 'Month'
                  ) {
                    setRepeat(repeat)
                    scheduleData.setStart(
                      moment(scheduleData.start).add(0, 'days').toDate()
                    )
                    for (let i = 0; i < eventDate.length; i++) {
                      const dateData = moment(eventDate[i])
                        .add(occurrence * 7 - 1, 'days')
                        .toDate()
                      getEventWithRepeatEndChangeMonthWithOccur(
                        type,
                        occurrence,
                        scheduleData,
                        eventDate[i],
                        dataRange,
                        dateData,
                        repeatLabel
                      )
                    }
                  }

                  if (
                    occurrence !== undefined &&
                    eventDate !== 'daily' &&
                    eventDate !== 'no' &&
                    eventDate === undefined &&
                    dataRange !== undefined &&
                    range === 'Month'
                  ) {
                    setRepeat(repeat)
                    getEventWithRepeatEndChangeMonthWithOccurNoChnage(
                      type,
                      occurrence,
                      scheduleData,
                      eventDate,
                      dataRange
                    )
                  }

                  if (
                    occurrence === undefined &&
                    selectedDate === undefined &&
                    eventDate !== 'daily' &&
                    eventDate === 'no' &&
                    eventDate === undefined &&
                    dataRange !== undefined &&
                    range === 'Month'
                  ) {
                    setRepeat(repeat)
                    getEventWithRepeatChangeMonthWithSameDate(
                      type,
                      selectedDateUsed,
                      scheduleData,
                      eventDate,
                      dataRange
                    )
                  }

                  if (
                    selectedDateUsed !== undefined &&
                    eventDate !== 'daily' &&
                    eventDate !== undefined &&
                    dataRange !== undefined &&
                    range === 'Weekly'
                  ) {
                    const answer = window.confirm(
                      `Working hours will be updated. Do you want to continue?`
                    )
                    if (answer) {
                      scheduleData.setStart(
                        moment(scheduleData.start).add(0, 'days').toDate()
                      )
                      setRepeat(repeat)
                      for (let i = 0; i < eventDate.length; i++) {
                        const dateData = moment(selectedDateUsed).diff(
                          moment(eventDate[i]),
                          'days'
                        )
                        getEventWithRepeatEndsOnDate(
                          type,
                          selectedDateUsed,
                          scheduleData,
                          eventDate[i],
                          dataRange,
                          dateData,
                          repeatLabel
                        )
                      }
                    }
                  }

                  if (
                    selectedDateUsed !== undefined &&
                    eventDate === undefined &&
                    dataRange !== undefined &&
                    range === 'Weekly'
                  ) {
                    setRepeat(repeat)
                    getEventWithRepeatEndsOnDateDefault(
                      type,
                      selectedDateUsed,
                      scheduleData,
                      eventDate,
                      dataRange
                    )
                  }

                  if (
                    selectedDateUsed !== undefined &&
                    eventDate === undefined &&
                    dataRange !== undefined &&
                    range === 'Day'
                  ) {
                    setRepeat(repeat)
                    getEventWithRepeatEndsOnDateDefaultDay(
                      type,
                      selectedDateUsed,
                      scheduleData,
                      eventDate,
                      dataRange
                    )
                  }

                  if (
                    selectedDateUsed !== undefined &&
                    eventDate !== 'daily' &&
                    eventDate !== undefined &&
                    dataRange !== undefined &&
                    range === 'Day'
                  ) {
                    const answer = window.confirm(
                      `Working hours will be updated. Do you want to continue?`
                    )
                    if (answer) {
                      setRepeat(repeat)
                      getEventWithRepeatEndsOnDateDaily(
                        type,
                        selectedDateUsed,
                        scheduleData,
                        eventDate,
                        dataRange
                      )
                    }
                  }

                  if (
                    selectedDateUsed === undefined &&
                    occurrence === undefined &&
                    eventDate !== 'daily' &&
                    eventDate !== undefined &&
                    dataRange !== undefined &&
                    range === 'Day'
                  ) {
                    const answer = window.confirm(
                      `Working hours will be updated. Do you want to continue?`
                    )
                    if (answer) {
                      setRepeat(repeat)
                      if (dataRange === 1) {
                        getEventWithRepeatChangeDaily(
                          type,
                          selectedDateUsed,
                          scheduleData,
                          eventDate
                        )
                      } else {
                        getEventWithRepeatChangeDateDaily(
                          type,
                          selectedDateUsed,
                          scheduleData,
                          eventDate,
                          dataRange
                        )
                      }
                    }
                  }

                  if (
                    occurrence !== undefined &&
                    eventDate !== 'daily' &&
                    dataRange === undefined &&
                    range === undefined
                  ) {
                    if (
                      scheduleData.extendedProps.repeat.includes('everyday') &&
                      eventDate === undefined
                    ) {
                      setRepeat(repeat)
                      getEventWithRepeatChangeDailyOccurrence(
                        type,
                        selectedDateUsed,
                        scheduleData,
                        eventDate,
                        occurrence,
                        scheduleDate
                      )
                    } else {
                      const answer = window.confirm(
                        `Working hours will be updated. Do you want to continue?`
                      )
                      if (answer) {
                        setRepeat(repeat)
                        getEventWithRepeatChangeWeeklyOccurrence(
                          type,
                          selectedDateUsed,
                          scheduleData,
                          eventDate,
                          occurrence,
                          scheduleDate
                        )
                      }
                    }
                  }
                }}
              />
            )
          }}
          snapDuration='00:30'
          selectMirror={true}
          slotDuration='00:60'
          allDaySlot={false}
          slotLabelFormat={['h A']}
          businessHours={businessHours}
          hiddenDays={hiddenDays}
          // timeZone= 'local'
          selectConstraint='businessHours'
          eventBackgroundColor='transparent'
          eventColor='transparent'
          eventBorderColor='transparent'
          eventConstraint='businessHours'
          eventOverlap={overlap}
          //   slotEventOverlap={false}
          nowIndicator
          titleFormat='dddd, MMMM D, YYYY'
          dayHeaderFormat='ddd D/M'
          //   dayMaxEvents={true}
          initialDate={new Date()}
          //   fixedMirrorParent={document.body}
          eventDragStart={(eventChangeArg) => {
            setOverLap(true)
            dragEnable.current = false
          }}
          eventDragStop={(eventChangeArg) => {
            dragEnable.current = true
            setRepeat(false)
          }}
          eventDrop={(eventChangeArg) => {
            if (calendarRef?.current != null) {
              const calendarApi = calendarRef?.current.getApi()
              calendarApi.refetchEvents()
              const existingEvents: any = []
              const delEvents: any = []

              const answer = window.confirm(
                `Working hours will be updated permanently. Do you want to continue?`
              )
              if (answer === false) {
                dragEnable.current = false
                eventChangeArg.revert()
              }
              if (answer) {
                const listEvent = calendarApi.getEvents()
                dragEnable.current = true
                existingEvents.push(eventChangeArg.event)
                if (eventChangeArg.relatedEvents) {
                  eventChangeArg.relatedEvents.forEach((relEvent) => {
                    existingEvents.push(relEvent)
                  })
                }

                listEvent.forEach((event) => {
                  for (let i = 0; i < existingEvents.length; i++) {
                    if (existingEvents[i].id !== event.id) {
                      const isSame = moment(existingEvents[i].end).isSame(
                        moment(event.end)
                      )
                      const newEndDate = moment(existingEvents[i].end)

                      const startDateBefor = moment(event.end).isBetween(
                        moment(existingEvents[i].start),
                        moment(existingEvents[i].end)
                      )

                      const startDateAfter = moment(event.start).isBetween(
                        moment(existingEvents[i].start),
                        moment(existingEvents[i].end)
                      )
                      if (isSame || startDateBefor || startDateAfter) {
                        // eslint-disable-next-line no-alert

                        if (
                          eventChangeArg.oldEvent.extendedProps.versionId ===
                          undefined
                        ) {
                          event.remove()
                        } else {
                          delEvents.push(
                            eventChangeArg.oldEvent.extendedProps.data
                          )
                          delEvents.push(event.extendedProps.data)
                          const scheduleBundle =
                            getBulkScheduleBundle(delEvents)
                          calendarApi.refetchEvents()
                          dispatch(
                            removeScheduleDetails(
                              scheduleBundle,
                              selectedDoctor,
                              false,
                              calendarApi.getDate()
                            )
                          )
                          dispatch(resetSchdeule())
                          requestSearchSchedule(calendarApi.getDate())
                          setDeletedSchedules(existingEvents)
                          if (onCalendarSelectionChanged) {
                            onCalendarSelectionChanged(
                              calendarApi.getEvents(),
                              existingEvents
                            )
                          }
                        }
                      }
                    }
                  }
                })

                for (let i = 0; i < existingEvents.length; i++) {
                  let repeatDataDrop = 'Does not repeat'
                  if (
                    existingEvents[i].extendedProps.repeat !== 'Does not repeat'
                  )
                    repeatDataDrop = `Repeats every  ${moment(
                      existingEvents[i].start
                    ).format('dddd')} `
                  const evenProp = {
                    title: existingEvents[i].title,
                    repeat: repeatDataDrop,
                    repeatLabel: existingEvents[i].extendedProps.repeatLabel,
                  }
                  const eventData50 = {
                    id: `new${Math.random().toString()}`,
                    title: existingEvents[i].title,
                    start:
                      existingEvents[i].start === null
                        ? new Date()
                        : existingEvents[i].start,
                    end:
                      existingEvents[i].end === null
                        ? new Date()
                        : existingEvents[i].end,
                    extendedProps: evenProp,
                    version: '',
                    groupId: `recurId`,
                  }
                  listEvent.forEach((event) => {
                    const isSame = moment(eventData50.end).isSame(
                      moment(event.end)
                    )
                    if (isSame) event.remove()
                  })
                  calendarApi.addEvent(eventData50)
                  if (onCalendarSelectionChanged) {
                    onCalendarSelectionChanged(
                      calendarApi.getEvents(),
                      deletedScheules
                    )
                  }
                }
                dispatch(
                  requestForSchedules(selectedDoctor, calendarApi.getDate())
                )
              }
            }

            setRepeat(false)
          }}
          eventResize={(eventChangeArg) => {
            const existingEvents: any = []
            const newEvents: any = []
            newEvents.push(eventChangeArg.event)

            if (calendarRef?.current != null) {
              const calendarApi = calendarRef?.current.getApi()
              calendarApi.refetchEvents()
              const listEvent = calendarApi.getEvents()

              if (
                eventChangeArg.oldEvent.extendedProps.versionId === undefined
              ) {
                eventChangeArg.oldEvent.remove()

                for (let i = 0; i < eventChangeArg.relatedEvents.length; i++) {
                  newEvents.push(eventChangeArg.relatedEvents[i])
                  eventChangeArg.relatedEvents[i].remove()
                }
              } else {
                existingEvents.push(eventChangeArg.oldEvent.extendedProps.data)

                eventChangeArg.oldEvent.remove()
                for (let j = 0; j < eventChangeArg.relatedEvents.length; j++) {
                  existingEvents.push(
                    eventChangeArg.relatedEvents[j].extendedProps.data
                  )
                  eventChangeArg.relatedEvents[j].remove()
                }
                const scheduleBundle = getBulkScheduleBundle(existingEvents)

                dispatch(
                  removeScheduleDetails(
                    scheduleBundle,
                    selectedDoctor,
                    false,
                    calendarApi.getDate()
                  )
                )
                dispatch(resetSchdeule())
                requestSearchSchedule(calendarApi.getDate())
              }

              for (let x = 0; x < existingEvents.length; x++) {
                listEvent.forEach((event) => {
                  if (event.id === existingEvents[x].id) {
                    event.remove()
                  }
                })
              }
              setDeletedSchedules(existingEvents)
              if (onCalendarSelectionChanged) {
                calendarApi.refetchEvents()
                onCalendarSelectionChanged(
                  calendarApi.getEvents(),
                  existingEvents
                )
              }
              for (let i = 0; i < newEvents.length; i++) {
                let repeatDataResize = 'Does not repeat'
                if (newEvents[i].extendedProps.repeat !== 'Does not repeat')
                  repeatDataResize = `Repeats every  ${moment(
                    newEvents[i].start
                  ).format('dddd')} `
                const evenProp = {
                  title: newEvents[i].title,
                  repeat: newEvents[i].extendedProps.repeat,
                  repeatLabel: newEvents[i].extendedProps.repeatLabel,
                }
                const eventData60 = {
                  id: `new${Math.random().toString()}`,
                  title: newEvents[i].title,
                  start:
                    newEvents[i].start === null
                      ? new Date()
                      : newEvents[i].start,
                  end:
                    newEvents[i].end === null ? new Date() : newEvents[i].end,
                  extendedProps: evenProp,
                  version: '',
                  groupId: `recurId`,
                }
                listEvent.forEach((event) => {
                  const isSame = moment(eventData60.end).isSame(
                    moment(event.end)
                  )
                  if (isSame) event.remove()
                })
                calendarApi.addEvent(eventData60)
                if (onCalendarSelectionChanged) {
                  onCalendarSelectionChanged(
                    calendarApi.getEvents(),
                    deletedScheules
                  )
                }
              }
            }
          }}
          eventChange={(eventChangeArg) => {
            if (dragEnable.current === true) {
              const existingEvents: any = []
              if (calendarRef?.current != null) {
                const calendarApi = calendarRef?.current.getApi()
                calendarApi.refetchEvents()
                const listEvent = calendarApi.getEvents()

                if (
                  eventChangeArg.oldEvent.extendedProps.versionId === undefined
                ) {
                  eventChangeArg.oldEvent.remove()

                  for (
                    let i = 0;
                    i < eventChangeArg.relatedEvents.length;
                    i++
                  ) {
                    eventChangeArg.relatedEvents[i].remove()
                  }
                } else {
                  existingEvents.push(
                    eventChangeArg.oldEvent.extendedProps.data
                  )

                  //   calendarApi.refetchEvents()
                  eventChangeArg.oldEvent.remove()
                  for (
                    let j = 0;
                    j < eventChangeArg.relatedEvents.length;
                    j++
                  ) {
                    existingEvents.push(
                      eventChangeArg.relatedEvents[j].extendedProps.data
                    )
                    eventChangeArg.relatedEvents[j].remove()
                  }
                  const scheduleBundle = getBulkScheduleBundle(existingEvents)

                  dispatch(
                    removeScheduleDetails(
                      scheduleBundle,
                      selectedDoctor,
                      false,
                      calendarApi.getDate()
                    )
                  )
                  dispatch(resetSchdeule())
                  requestSearchSchedule(calendarApi.getDate())
                }

                for (let x = 0; x < existingEvents.length; x++) {
                  listEvent.forEach((event) => {
                    if (event.id === existingEvents[x].id) {
                      event.remove()
                    }
                  })
                }
                const events = calendarApi.getEvents()
                setDeletedSchedules(existingEvents)
                if (onCalendarSelectionChanged) {
                  calendarApi.refetchEvents()
                  onCalendarSelectionChanged(
                    calendarApi.getEvents(),
                    existingEvents
                  )
                }
              }
            }
          }}
        />
      </div>
    </div>
  )
}
