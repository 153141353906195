/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable consistent-return */
import { Box } from '@material-ui/core'
import { AnyARecord } from 'dns'
import {
  CohortChartProps,
  CohortControlsProps,
  CohortDataStage,
  MapDataProps,
} from 'models/chartProps'
import { SankeyData } from 'models/sankeyData'
import { SankeyLink } from 'models/sankeyLinks'
import { SankeyNode } from 'models/sankeyNode'
import React, { useEffect, useState } from 'react'
import { getFilterLabel } from 'utils/cohort/cohortUtils'

import { DynamicFilterSelect } from './controlComponent/dynamicFilterSelect'
import { FontSize } from './controlComponent/fonSize'
import { InclusiveFilter } from './controlComponent/inclusiveFilter'
import { PersonSlider } from './controlComponent/perSonSlider'
import { VisibleStageSelect } from './controlComponent/visibleStageSelect'
import { CustomVisibleStage } from './customVisibleStageList'
import { ErrorMessage } from './errorMessage'
import { SankeyChart } from './shankeyChart'

export const CohortControls: React.FC<CohortControlsProps> = ({
  max,
  min,
  baseMin,
  handleSetMin,
  handleStageChange,
  visibleStages,
  stages,
  handleStageFilterChange,
  filterMap,
  inclusiveFilter,
  handleInclusiveFilter,
  filters,
  handleFontDecrease,
  handleFontIncrease,
}: CohortControlsProps) => {
  console.log(Object.keys(stages))
  return (
    <div className='controls'>
      <Box display='flex' flexDirection='column' width='100%'>
        <Box display='flex' flexDirection='column' width='20%'>
          <PersonSlider
            label='Min Person Count'
            id='person'
            name='person'
            baseMin={baseMin}
            min={min}
            max={max || 1000}
            step={1}
            value={min}
            onChange={handleSetMin}
          />
        </Box>
        <Box display='flex' flexDirection='row' paddingTop={1} width='100%'>
          <Box
            display='flex'
            flexDirection='column'
            paddingRight={1}
            width='15%'
          >
            <VisibleStageSelect
              label='Filterable Visible Stages'
              name='stages'
              stages={stages}
              visibleStages={visibleStages}
              handleStageChange={handleStageChange}
              //   selectProps={{
              //     inputProps: {
              //       style: {
              //         backgroundColor: 'red',
              //       },
              //     },
              //     style: {
              //       width: '100%',
              //       overflow: 'auto',
              //       backgroundColor: 'transparent',
              //     },
              //   }}
            />
          </Box>
        </Box>
        <Box display='flex' flexDirection='column'>
          <InclusiveFilter
            label='Exclusion Filter'
            onChange={handleInclusiveFilter}
            value={inclusiveFilter}
          />
        </Box>
        <Box display='flex' flexDirection='column' width='100%'>
          <DynamicFilterSelect
            label='Filters'
            handleStageFilterChange={handleStageFilterChange}
            filterMap={filterMap}
            filters={filters}
            visibleStages={visibleStages}
          />
        </Box>
        <Box display='flex' flexDirection='row' width='100%' py={0.5}>
          <FontSize
            label='Font Size'
            handleFontIncrease={handleFontIncrease}
            handleFontDecrease={handleFontDecrease}
          />
        </Box>
      </Box>
    </div>
  )
}
