import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { BpCollection } from 'models/bpData'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { getSortedObservation } from 'utils/appointment_handle/vitals_util'
import { getCatalogName } from 'utils/fhirResoureHelpers/catalogHelper'
import { getDietPlansPlanFromBundle } from 'utils/fhirResoureHelpers/ipdDietHelper'
import { getObserVationFfromEncounter } from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { getTreatmentPlanFromBundle } from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { logger } from 'utils/logger'
import { NutritionCatalogueStatus } from './nutrtionCatalogueStatus'

const initialState: NutritionCatalogueStatus = {
  searchingTreatment: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingTreatment: false,
}

const nutritionCatalogueDataSearchSlice = createSlice({
  name: 'nutritionCatalogueDataSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<NutritionCatalogueStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingTreatment = action.payload.searchingTreatment
      state.resultsAvailable = action.payload.resultsAvailable
      state.catalogueEntries = action.payload.catalogueEntries
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingTreatment =
        action.payload.errorWhileSearchingTreatment
    },
  },
})

export const getAllNutritionValueSets =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: NutritionCatalogueStatus = {
      searchingTreatment: true,
      errorWhileSearchingTreatment: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(nutritionCatalogueDataSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doGetResourceCrossPartition(
        `/CatalogEntry?_count=1000`
      )

      const resp: R4.IBundle = response as R4.IBundle

      const responses = resp.entry
        ?.map((e) => e.resource as any as R4.ICatalogEntry)
        .sort((a, b) => getCatalogName(b).localeCompare(getCatalogName(a)))

      state.resultsAvailable = true
      state.searchingTreatment = false
      state.catalogueEntries = responses
      state.noResultsAvailable = false
      state.errorReason = undefined
      state.errorWhileSearchingTreatment = false
      dispatch(nutritionCatalogueDataSearchSlice.actions.updatedStatus(state))
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorSearchDoctor: NutritionCatalogueStatus = {
        searchingTreatment: false,
        errorWhileSearchingTreatment: true,
        resultsAvailable: false,
        errorReason: 'Error while searching Treatment Plan',
      }
      dispatch(
        nutritionCatalogueDataSearchSlice.actions.updatedStatus(
          errorSearchDoctor
        )
      )
    }
  }

export default nutritionCatalogueDataSearchSlice.reducer
