import { Box, Typography, makeStyles, Theme } from '@material-ui/core'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { JitsiMeeting } from '@jitsi/react-sdk'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { User } from 'models/userDetails'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { startVideoAppointment } from 'redux/appointments/videocallHandler/videoCallHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { WelloLoadingIndicator } from 'wello-web-components'
import Paper, { PaperProps } from '@mui/material/Paper'
import Draggable from 'react-draggable'
import { ResizablePIP } from 'views/components/common/resizable-pip'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'

interface Props {
  onEndMeeting: () => void
  onConfrenseJoined: () => void
  OnParticipantJoined: () => void
  OnParticipantLeft: () => void
  appointmentDetails: FhirAppointmentDetail

  width?: number
  height?: number
}

export const VideoCallComponent: React.FC<Props> = ({
  onEndMeeting,
  onConfrenseJoined,
  OnParticipantJoined,
  OnParticipantLeft,
  appointmentDetails,
  width = 700,
  height = 700,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '30%',
    },
  }))
  const classes = useStyles()
  const videoCallHandlerSlice = useSelector(
    (state: RootState) => state.videoCallHandlerSlice
  )
  const [appointmentData, setAppointmentData] =
    useState<FhirAppointmentDetail>(appointmentDetails)
  const userDetails: User = {
    Name: 'Ramesh',
  }

  const videoData = {
    room: '',
    token: '',
  }
  const domain: string = 'dev.meet.wellopathy.com'

  const toolbar: string[] = [
    'camera',
    'chat',
    'closedcaptions',
    'desktop',
    'download',
    'embedmeeting',
    'etherpad',
    'feedback',
    'filmstrip',
    'fullscreen',
    'hangup',
    'help',
    'invite',
    'livestreaming',
    'microphone',
    'mute-everyone',
    'mute-video-everyone',
    'participants-pane',
    'profile',
    'raisehand',
    'security',
    'select-background',
    'settings',
    'shareaudio',
    'sharedvideo',
    'shortcuts',
    'stats',
    'tileview',
    'toggle-camera',
    'videoquality',
    '__end',
  ]

  const endMeeting = () => {
    onEndMeeting()
  }
  const OnConfJoined = () => {
    onConfrenseJoined()
  }
  const OnPatientJoined = () => {
    OnParticipantJoined()
  }
  const OnPatientLeft = () => {
    OnParticipantLeft()
  }

  useEffect(() => {
    dispatch(startVideoAppointment(appointmentDetails))
    return () => {}
  }, [])

  useEffect(() => {
    if (videoCallHandlerSlice.appointmentFetched) {
      if (videoCallHandlerSlice.appointmentDetails) {
        videoData.room = videoCallHandlerSlice.roomId
        videoData.token = videoCallHandlerSlice.token
      }
    }
    return () => {}
  }, [videoCallHandlerSlice.appointmentFetched])

  const [compHeight, setcompHeight] = useState(height)

  const [compWidth, setcompWidth] = useState(width)

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  return (
    <ResizablePIP
      key='resize-pop'
      onResizeStop={(h, w) => {
        setcompHeight(h)
        setcompWidth(w)
      }}
      handleClose={() => {
        setShowDeleteConfirmation({
          open: true,
          title: 'End Call',
          displayText: 'Are you sure you want to end the call?',
          onContinueActionClicked: () => {
            setShowDeleteConfirmation({ open: false })
            endMeeting()
          },
          cancelActionButtonText: 'No',
          continueActionButtonText: 'End Call',
          onCancelActionClicked: () => {
            setShowDeleteConfirmation({ open: false })
          },
        })
      }}
    >
      <Box borderRadius={1}>
        {videoCallHandlerSlice.fetchingAppointmentDetails && (
          <WelloLoadingIndicator message='Loading...' />
        )}

        {videoCallHandlerSlice.appointmentFetched &&
          videoCallHandlerSlice.token.length > 0 && (
            <JitsiMeeting
              domain={domain}
              roomName={videoCallHandlerSlice.roomId}
              jwt={videoCallHandlerSlice.token}
              spinner={WelloLoadingIndicator}
              configOverwrite={{
                startWithAudioMuted: true,
                defaultLanguage: 'en',
                disableModeratorIndicator: true,
                startScreenSharing: true,
                enableEmailInStats: false,
                enableClosePage: false,
                hideConferenceSubject: true,
                disableReactions: true,

                gravatar: {
                  disabled: true,
                },

                toolbarButtons: [
                  'camera',
                  'chat',
                  'fullscreen',
                  'desktop',
                  'hangup',
                  'help',
                  'profile',
                  'settings',
                  'settings',
                  'tileview',
                  'microphone',
                  'toggle-camera',
                  'videoquality',
                ],

                doNotStoreRoom: false,
                prejoinConfig: {
                  enabled: false,
                },
                analytics: {
                  disabled: true,
                },
              }}
              interfaceConfigOverwrite={{
                DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                HIDE_INVITE_MORE_HEADER: true,
                SHOW_BRAND_WATERMARK: false,
                SHOW_JITSI_WATERMARK: false,
              }}
              onReadyToClose={endMeeting}
              onApiReady={(externalApi: any) => {
                // here you can attach custom event listeners to the Jitsi Meet External API
                // you can also store it locally to execute commands
              }}
              getIFrameRef={(iframeRef) => {
                iframeRef.style.height = `${(compHeight - 60).toFixed(0)}px`
                iframeRef.style.width = `${compWidth.toFixed(0)}px`
              }}
            />
            /*  <JitsiClient
              Domain={domain}
              RoomName={videoCallHandlerSlice.roomId}
              Role=''
              Token={videoCallHandlerSlice.token}
              User={userDetails}
              Toolbar={toolbar}
              VideoConferenceLeft={endMeeting}
              VideoConferenceJoined={OnConfJoined}
              ParticipantJoined={OnPatientJoined}
              ParticipantLeft={OnPatientLeft}
              Width={`${compHeight.toFixed(0)}px`}
              Height={`${compWidth.toFixed(0)}px`}
            /> */
          )}

        {videoCallHandlerSlice.appointmentFetched &&
          videoCallHandlerSlice.token.length === 0 && (
            <Typography>Video can not be started</Typography>
          )}
      </Box>

      <ConfirmationDialog {...showDeleteConfirmation} />
    </ResizablePIP>
  )
}
