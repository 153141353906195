import { orange, red } from '@material-ui/core/colors'
import React from 'react'

const LinearGradient = () => (
  <svg height='16px'>
    <defs>
      <linearGradient id='spam_color'>
        <stop offset='0.05' stopColor='#A9A2E8' />
        <stop offset='1' stopColor='#2A4AB5' />
      </linearGradient>
      <linearGradient id='spam_hover'>
        <stop offset='0.05' stopColor='#DCDCDC' />
        <stop offset='1' stopColor='#A3A2A2' />
      </linearGradient>
      <linearGradient id='spam_low'>
        <stop offset='0.05' stopColor={orange[200]} />
        <stop offset='1' stopColor={orange[300]} />
      </linearGradient>
      <linearGradient id='spam_moderate'>
        <stop offset='0.05' stopColor={orange[600]} />
        <stop offset='1' stopColor={orange[700]} />
      </linearGradient>
      <linearGradient id='spam_high'>
        <stop offset='0.05' stopColor={red[400]} />
        <stop offset='1' stopColor={red[500]} />
      </linearGradient>
    </defs>
  </svg>
)

export default LinearGradient
