import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'

export interface IProps {
  label: string
  type?: any
  error?: null | undefined
  onSelectionChanged?: any
  availableOptions: any[]
  required: boolean
  preSelectedOption?: any
  displayField?: string
  valueField?: string
  width?: number
  id?: string
}

export const WelloSelectOrgs: React.FC<IProps> = ({
  label,
  type,
  error,
  onSelectionChanged,
  availableOptions,
  required,
  preSelectedOption,
  displayField,
  valueField,
  width,
  id,
}: IProps) => {
  const [selectedValue, setSelectedValue] = useState(
    preSelectedOption
      ? valueField
        ? preSelectedOption[valueField]
        : valueField
      : ''
  )
  return (
    <Box display='flex' flexDirection='column' flexGrow={1}>
      <Box p={0.5}>
        <Typography
          variant='subtitle2'
          style={{
            textTransform: 'uppercase',
          }}
        >
          {label}
        </Typography>
      </Box>
      <FormControl size='small'>
        <Select
          autoWidth={true}
          labelId='demo-simple-select-outlined-label'
          id={id || 'select_duration'}
          variant='outlined'
          displayEmpty
          inputProps={{
            id: id || 'selection_field',
          }}
          value={selectedValue}
          style={{
            color: useTheme().palette.text.primary,
            height: 38,
            backgroundColor: useTheme().palette.common.white,
          }}
          onChange={(event) => {
            event.stopPropagation()
            setSelectedValue(event.target.value)
            const changedValue = valueField
              ? availableOptions.find(
                  (item) => item[valueField] === event.target.value
                )
              : event.target.value
            onSelectionChanged(changedValue)
          }}
        >
          <MenuItem value='' dense disabled>
            {type}
          </MenuItem>
          {availableOptions.map((option: any) => (
            <MenuItem
              id={valueField ? option[valueField] : option}
              dense
              key={valueField ? option[valueField] : option}
              value={valueField ? option[valueField] : option}
            >
              {displayField ? option[displayField] : option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
