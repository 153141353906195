import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from 'redux/store'
import { CohortClient } from 'services/cohortServiceClient'
import { getCohortTableData } from 'utils/cohort/cohortUtils'
import { logger } from 'utils/logger'
import { getEvidenceData } from 'utils/patientHelper/patientEditHelper'
import { CohortDataTableSearchStatus } from './cohortDataTableSearchStatus'

const initialState: CohortDataTableSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const cohortDataTableSearchSlice = createSlice({
  name: 'cohortDataTable',
  initialState,
  reducers: {
    searchingCohortsForTable(
      state,
      action: PayloadAction<CohortDataTableSearchStatus>
    ) {},

    searchResults(state, action: PayloadAction<CohortDataTableSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.cohortTableData = action.payload.cohortTableData
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<CohortDataTableSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.cohortTableData = action.payload.cohortTableData
    },

    errorWhileSearching(
      state,
      action: PayloadAction<CohortDataTableSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.cohortTableData = action.payload.cohortTableData
    },
    resetState(state, action: PayloadAction<CohortDataTableSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.cohortTableData = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: CohortDataTableSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      cohortTableData: undefined,
    }
    dispatch(cohortDataTableSearchSlice.actions.resetState(state))
  }

export const searchingCohortsForTable =
  (type: string, cohortId: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: CohortDataTableSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      cohortDataTableSearchSlice.actions.errorWhileSearching(errorSearchPatient)
    )
    try {
      const fhirClient: CohortClient = new CohortClient()
      const response: any = await fhirClient.doGetResource(`/cc/results/bpm`)

      if (response.reports.length > 0) {
        const cohortData = getCohortTableData(response, type, cohortId)

        const searchPatientResult: CohortDataTableSearchStatus = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          cohortTableData: cohortData,
        }
        dispatch(
          cohortDataTableSearchSlice.actions.searchResults(searchPatientResult)
        )
        return
      }

      const noSearchResults: CohortDataTableSearchStatus = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(
        cohortDataTableSearchSlice.actions.noDataFoundForSearch(noSearchResults)
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: CohortDataTableSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        cohortDataTableSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(cohortDataTableSearchSlice.actions.resetState(initialState))
}

export default cohortDataTableSearchSlice.reducer
