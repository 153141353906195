import { Box, Button, ListItem, TextField, Typography } from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import {
  LabReferralService,
  LabReferralServiceGrouped,
} from 'models/labOfferDetail'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { searchLabOfferings } from 'redux/lab/offerings/labOfferingSearchHandler/labOfferingSearchSlice'
import { logger } from 'utils/logger'

interface LabOfferingSelectorProps {
  data: LabReferralServiceGrouped[]
  onSelectionChanges?: (selectedValues: LabReferralServiceGrouped) => void
  doctorListId?: string
  preSelectedOrdersRoles?: LabReferralServiceGrouped
  preSelectAll?: boolean
  disabled: boolean
}

export const LabReferralSelectorForCarePlan: React.FC<LabOfferingSelectorProps> =
  ({
    data,
    onSelectionChanges,
    doctorListId,
    preSelectedOrdersRoles,
    preSelectAll = false,
    disabled,
  }: LabOfferingSelectorProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    return (
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <div role='alert'>
            <div>Error while fetching provider list</div>
            <pre>{error.message}</pre>
            <Button
              onClick={() => {
                dispatch(searchLabOfferings())
              }}
            >
              Try again
            </Button>
          </div>
        )}
      >
        <Box width='100%'>
          <Box width='100%'>
            <Autocomplete
              id='lab_select'
              onOpen={() => {
                // setOpen(true)
              }}
              onClose={() => {
                // setOpen(false)
              }}
              style={{
                borderRadius: '4px',
              }}
              disableClearable
              fullWidth
              size='small'
              getOptionSelected={(option, value) => option.name === value.name}
              disabled={disabled}
              defaultValue={preSelectedOrdersRoles}
              getOptionLabel={(option) => option.name}
              options={data}
              autoComplete
              includeInputInList
              filterSelectedOptions
              onChange={(e, changedValue, reason) => {
                logger.info(changedValue)
                console.log(changedValue)
                if (onSelectionChanges) onSelectionChanges(changedValue)
              }}
              ChipProps={{
                deleteIcon: (
                  <ClearOutlined
                    style={{
                      height: '15px',
                      color: 'white',
                    }}
                  />
                ),
                style: {
                  backgroundColor: kPrimaryLight,
                  borderRadius: '4px',
                },
              }}
              renderOption={(option, { inputValue }) => {
                const matches = match(option.name ?? '', inputValue)
                const parts = parse(option.name ?? '', matches)
                return (
                  <ListItem id={`lab_opt_${option.name}`}>
                    <div>
                      {parts.map((part, index) => (
                        <span
                          key={part.text}
                          style={{
                            backgroundColor: part.highlight
                              ? kPrimaryLight
                              : undefined,
                            fontWeight: part.highlight ? 700 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                  </ListItem>
                )
              }}
              filterOptions={(x) => x}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id='lab_search'
                  placeholder={t('labelCommon:labTest_search_placeholder')}
                  variant='outlined'
                  onChange={(e) => {
                    dispatch(searchLabOfferings(e.target.value))
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Box>

          {/* {labOfferingSearchSlice.error && (
          <Typography>
            {' '}
            {labOfferingSearchSlice.errorMessage ?? 'Error while searching'}
          </Typography>
        )} */}
          {data.length === 0 && <Typography> No Results available</Typography>}
        </Box>
      </ErrorBoundary>
    )
  }
