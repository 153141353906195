import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import React, { useState } from 'react'

interface IProps {
  imageSrc: string
  height: number
  width: number
  showPreview: boolean
  onCloseClicked: () => void
}

interface ImgState {
  height: number
  width: number
}

export const ImageViewer: React.FC<IProps> = ({
  imageSrc,
  height,
  width,
  showPreview,
  onCloseClicked,
}: IProps) => {
  const [file, setFile] = useState<string>()
  const [openImageState, setOpenImageState] = useState<ImgState>({
    height,
    width,
  })

  return (
    <Dialog
      maxWidth='md'
      open={showPreview}
      onClose={() => {
        onCloseClicked()
      }}
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Preview</Box>
          <Box>
            <Tooltip title='Zoom In'>
              <ZoomInIcon
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  if (openImageState.height < 250 && openImageState.width < 340)
                    setOpenImageState({
                      height: openImageState.height + 40,
                      width: openImageState.width + 40,
                    })
                }}
              />
            </Tooltip>

            <Tooltip title='Zoom Out'>
              <ZoomOutIcon
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  if (openImageState.height > 210 && openImageState.width > 300)
                    setOpenImageState({
                      height: openImageState.height - 40,
                      width: openImageState.width - 40,
                    })
                }}
              />
            </Tooltip>
            <Tooltip title='Close'>
              <CloseOutlined
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  onCloseClicked()
                }}
              />
            </Tooltip>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          display='flex'
          justifyContent='center'
          alignContent='center'
          minWidth='300px'
          minHeight='210px'
        >
          <img
            src={imageSrc}
            alt='img'
            height={`${openImageState.height}px`}
            width={`${openImageState.width}px`}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
