/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { kDialogueBackground, kPrimaryMain } from 'configs/styles/muiThemes'
import { OperationalHoursOfDay } from 'models/operationalHoursOfDay'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { searchInvitations } from 'redux/administration/userSetup/userOnBoardInvitations/userOnboardInvitationSlice'
import { RootState } from 'redux/rootReducer'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import {
  getCurrentUserPractitionerRoleDetails,
  isOrgAdmin,
} from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import { GetDefaultBusinessTimingsFromLocation } from 'utils/fhirResoureHelpers/calender_helper'
import { UserTileWithRole } from 'views/pages/general/userTile'
import { fetchUser } from 'redux/practitioner/practitionerSearchHandler/PractitionerManagement'
import { UserTitleWithoutDetail } from 'views/pages/general/userTileWithoutDetail'

export interface UnitDetailProps {
  unitOrgDetail: R4.IOrganization
  unitLocationDetail: R4.ILocation
  onUpdated?: () => void
}

export const ProfilleDetails: React.FC<UnitDetailProps> = (
  props: UnitDetailProps
) => {
  const practitionerSearchSlice = useSelector(
    (state: RootState) => state.practitionerManagement
  )
  const practRoleData = getCurrentUserPractitionerRoleDetails()
  const [userDetails, setUserDetails] = useState<PractitionerWithRole>()
  const [showAddUsers, setShowAddUsers] = useState<boolean>(false)
  const [showUpdateTime, setShowUpdateTime] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [businessHours, setBusinessHours] = useState<OperationalHoursOfDay[]>(
    GetDefaultBusinessTimingsFromLocation(props.unitLocationDetail) ?? []
  )

  useEffect(() => {
    dispatch(fetchUser(practRoleData.id ?? ''))
    return () => {}
  }, [])

  useEffect(() => {
    if (practitionerSearchSlice.resultsAvailable) {
      if (practitionerSearchSlice.userDetails)
        setUserDetails(practitionerSearchSlice.userDetails)
    }
    return () => {}
  }, [practitionerSearchSlice.resultsAvailable])

  console.log('userDetails', props.unitOrgDetail)

  return (
    <Box
      display='flex'
      flexDirection='column'
      flexGrow={1}
      width='100%'
      height='100%'
    >
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        bgcolor={kDialogueBackground}
      >
        <Grid
          container
          direction='row'
          alignContent='space-between'
          justify='space-between'
        >
          <Grid item>
            <Grid container direction='column' justify='flex-start'>
              <Grid item xs>
                <Box p={3}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {/* <Box display='flex' paddingX={2} paddingY={1}>
                      <Avatar alt={props.unitOrgDetail.name} src='test.jpg' />
                    </Box> */}

                    <Grid item xs>
                      <Box display='flex' flexDirection='Column' height='50%'>
                        <Box paddingX={0.5}>
                          <Typography variant='subtitle1' color='primary'>
                            CONTACT INFORMATION
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        display='flex'
        flexDirection='row'
        height='100%'
        width='100%'
        p={1}
        style={{
          backgroundColor: '#FFFFFFAF',
        }}
      >
        <Grid
          container
          direction='row'
          alignContent='space-between'
          justify='space-between'
        >
          <Grid item xs={5}>
            <Box display='flex' flexDirection='row' justifyContent='center'>
              {practitionerSearchSlice.searching && (
                <CircularProgress size={25} />
              )}
              {practitionerSearchSlice.resultsAvailable && (
                <Box width='100%'>
                  <UserTitleWithoutDetail
                    userDetails={practitionerSearchSlice.userDetails}
                    role={[]}
                    onDone={() => {
                      // window.location.reload()
                      if (props.onUpdated) {
                        props.onUpdated()
                      }
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
