import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@material-ui/core'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React from 'react'
import { getSpecializationsList } from 'utils/fhirResourcesHelper'

interface UserTileProps {
  practitionerWithRole: PractitionerWithRole
  isSelected?: boolean
  onClick?: () => void
}

export const UserTile: React.FC<UserTileProps> = ({
  practitionerWithRole,
  isSelected = false,
  onClick,
}: UserTileProps) => (
  <Box
    onClick={onClick}
    style={{
      cursor: 'pointer',
    }}
  >
    <ListItem
      style={{
        flexGrow: 1,
        padding: useTheme().spacing(2),
        backgroundColor: isSelected ? kPrimaryLight : 'transparent',
      }}
      ContainerComponent='div'
      divider
    >
      <ListItemAvatar>
        <Avatar alt={practitionerWithRole.name} src='test.jpg' />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant='subtitle1' color='primary'>
            {practitionerWithRole.name}
          </Typography>
        }
        secondary={
          <Typography component='span' variant='subtitle2'>
            {getSpecializationsList(
              practitionerWithRole.roleObject?.specialty ?? []
            )}
          </Typography>
        }
      />
    </ListItem>
  </Box>
)
