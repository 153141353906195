import {
  Box,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Divider,
  ListSubheader,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  Tooltip,
  Collapse,
  Link,
} from '@material-ui/core'

import {
  DeleteOutlineOutlined,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  Close,
  Add,
  ExpandMore,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedLabResults } from 'models/groupedLabResults'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { resetAddLabTestSlice } from 'redux/consultation/addlabTest/addLabTestIPD/addLabTestSlice'
import { getLabReportsForTheAppointmentForFollowup } from 'redux/consultation/followupReports/followUpReportsSearchSlice'
import { requestLabTestDetailsDetailsOfPatient } from 'redux/consultation/labTestOPD/labDetailSlice'
import { getLabReportsForTheAppointment } from 'redux/consultation/reports/reportsSearchSlice'
import { requestVitalDetailsOfPatient } from 'redux/consultation/vitalsDetailsSlice/vitalsDetailSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isPhysiotherapist,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getDateWiseVitals } from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { logger } from 'utils/logger'
import { AddLabTest } from 'views/components/ipdconusltation/objective/addLabTestResult'
import { VitalElement } from 'views/components/LeftMenu/VitalElement'
import { ReportTileForAppointmentDiagnosticReport } from '../history/OPD/diaognisticReportTile'
import { ReportTileForAppointment } from '../history/OPD/reportTileForAppointment'
import { AddLabTestOPD } from './AddLabTest'
import { LabResultTile } from './tiles/labResultTile'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))

interface VitalsProp {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
}

export const FollowupLabReports: React.FC<VitalsProp> = ({
  fhirAppointmentDetails,
  split,
}: VitalsProp) => {
  const { t } = useTranslation(['common'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)

  const classes = useStyles()
  const dispatch = useDispatch()
  const reportsSearchSlice = useSelector(
    (state: RootState) => state.reportsSearchSliceForFollowUp
  )

  useEffect(() => {
    if (
      fhirAppointmentDetails.encounter ||
      fhirAppointmentDetails.appointment.basedOn
    )
      dispatch(
        getLabReportsForTheAppointmentForFollowup(fhirAppointmentDetails, true)
      )
  }, [dispatch, fhirAppointmentDetails])

  return (
    <ReactVisibilitySensor
      offset={{
        bottom: 30,
      }}
      onChange={(isVisible) => {
        logger.info('Inside diagnostic')
        if (isVisible) {
          dispatch(setSelectedSection({ selectedSection: 'vitals' }))
        }
      }}
    >
      <Grid container direction='column' style={{ flexWrap: 'nowrap' }}>
        <Grid item>
          <Grid container direction='row' id='vitals'>
            <Box py={1}>
              <Typography variant='h6'>
                {' '}
                Previous Appointment Lab Reports
              </Typography>
            </Box>{' '}
          </Grid>

          {reportsSearchSlice.fetchingImpressions && (
            <Grid item>
              <CircularProgress size={15} />
            </Grid>
          )}
          {reportsSearchSlice.noResultsAvailable && (
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                {' '}
                {!isUnitAdmin() || !split ? '' : 'No data available'}
              </Typography>
            </Grid>
          )}
          {reportsSearchSlice.errorWhileSearchingImpressions && (
            <Grid item>
              <Typography
                variant='subtitle2'
                color='error'
                style={{
                  fontWeight: 400,
                }}
              >
                Error while fetching Lab Reports
              </Typography>
            </Grid>
          )}
          {reportsSearchSlice.reports && reportsSearchSlice.reports.length > 0 && (
            <Grid item>
              <ReportTileForAppointment
                reports={reportsSearchSlice.reports}
                type='Report'
                appointmentId=''
              />
            </Grid>
          )}

          {reportsSearchSlice.diagnosticReport &&
            reportsSearchSlice.diagnosticReport.length > 0 && (
              <Grid item>
                <ReportTileForAppointmentDiagnosticReport
                  reports={reportsSearchSlice.diagnosticReport}
                  type='Report'
                />
              </Grid>
            )}
        </Grid>
      </Grid>
    </ReactVisibilitySensor>
  )
}
