import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointUseKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { PartnerLab } from 'models/partnerLab'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getEmailOfPractitionerRole,
  getIdentifierValueBySystem,
  getTelecomOfPractitioner,
} from 'utils/fhirResourcesHelper'
import { getPractitionersWithRoleObject } from 'utils/fhirResoureHelpers/practitioner_and_role_helpers'
import { logger } from 'utils/logger'
import { PartnerLabManagementStatus } from './partnerLabManagementStatus'
import { searchInvitations } from './Search/partnerLabSearchSlice'

const initialState: PartnerLabManagementStatus = {
  fetchingPartnerDetails: false,
  partnerDetailsAvailable: false,
  noResultsAvailable: false,
  errorWhileFetchingPartnerDetail: false,
  partnerDetailsUpdated: false,
  updatingPartnerDetail: false,
}

const partnerLabManagementSlice = createSlice({
  name: 'partnerLabManagementSlice',
  initialState,

  reducers: {
    updatedStatus(state, action: PayloadAction<PartnerLabManagementStatus>) {
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingPartnerDetails = action.payload.fetchingPartnerDetails
      state.partnerDetailsAvailable = action.payload.partnerDetailsAvailable
      state.partnerDetails = action.payload.partnerDetails
      state.errorWhileFetchingPartnerDetail =
        action.payload.errorWhileFetchingPartnerDetail
      state.partnerDetailsUpdated = action.payload.partnerDetailsUpdated
      state.updatingPartnerDetail = action.payload.updatingPartnerDetail
    },
  },
})

export const resetUpdateStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const currentState = { ...initialState }
    dispatch(partnerLabManagementSlice.actions.updatedStatus(currentState))
  }

export const fetchPartnerLabDetails =
  (partnerId: String): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PartnerLabManagementStatus = { ...initialState }
    state.fetchingPartnerDetails = true
    dispatch(partnerLabManagementSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _id: partnerId,
        _include: 'PractitionerRole:location',
      }
      const response: any = await fhirClient.doGetResource(
        `/PractitionerRole`,
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)

      if (resp._tag === 'Left') {
        if (response?.total && response?.total > 0) {
          const practitionerWithRoleList: PractitionerWithRole[] =
            getPractitionersWithRoleObject(response) ?? []
          logger.info(':Practitioner length')
          const currentState = { ...initialState }
          currentState.partnerDetailsAvailable = true
          currentState.partnerDetails = practitionerWithRoleList[0]
          dispatch(
            partnerLabManagementSlice.actions.updatedStatus(currentState)
          )
        } else {
          const currentState = { ...initialState }
          currentState.noResultsAvailable = true

          dispatch(
            partnerLabManagementSlice.actions.updatedStatus(currentState)
          )
        }
      } else {
        const practitionerRolesResponses: R4.IBundle = resp.right
        if (
          practitionerRolesResponses?.total &&
          practitionerRolesResponses?.total > 0
        ) {
          const practitionerWithRoleList: PractitionerWithRole[] =
            getPractitionersWithRoleObject(practitionerRolesResponses) ?? []
          logger.info(':Practitioner length')
          const currentState = { ...initialState }
          currentState.partnerDetailsAvailable = true
          currentState.partnerDetails = practitionerWithRoleList[0]
          dispatch(
            partnerLabManagementSlice.actions.updatedStatus(currentState)
          )
        } else {
          const currentState = { ...initialState }
          currentState.noResultsAvailable = true

          dispatch(
            partnerLabManagementSlice.actions.updatedStatus(currentState)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)

      const currentState = { ...initialState }
      currentState.errorWhileFetchingPartnerDetail = true

      dispatch(partnerLabManagementSlice.actions.updatedStatus(currentState))
    }
  }

export const requestForUpdatePartnerLab =
  (
    newLocation: PartnerLab,
    practitionerRole?: R4.IPractitionerRole,
    existingLocation?: R4.ILocation
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PartnerLabManagementStatus = { ...initialState }
    state.updatingPartnerDetail = true
    dispatch(partnerLabManagementSlice.actions.updatedStatus(state))
    try {
      if (practitionerRole) {
        const locationObj: R4.IBundle | undefined =
          getPartnerLabRequestTransactionObject(
            newLocation,
            practitionerRole,
            existingLocation
          )
        if (locationObj) {
          const fhirClient: FHIRApiClient = new FHIRApiClient()
          const response: any = await fhirClient.doCreateFHIRTransaction(
            '',
            locationObj
          )
          const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
            R4.RTTI_Bundle.decode(response)
          if (relatedFhirDecodeRes._tag === 'Right') {
            state.updatingPartnerDetail = false
            state.partnerDetailsUpdated = true
            dispatch(searchInvitations([]))
            dispatch(showSuccessAlert('Partner Lab Updated Successfully'))
            dispatch(fetchPartnerLabDetails(practitionerRole?.id ?? ''))
            dispatch(partnerLabManagementSlice.actions.updatedStatus(state))
            return
          }
          state.updatingPartnerDetail = false
          state.errorWhileFetchingPartnerDetail = true
          dispatch(
            showErrorAlert('Error while cancelling order. Please try later')
          )
          dispatch(partnerLabManagementSlice.actions.updatedStatus(state))
          return
        }
      }
    } catch (error) {
      logger.error(error)
      const currentState = { ...initialState }
      currentState.errorWhileFetchingPartnerDetail = true

      dispatch(partnerLabManagementSlice.actions.updatedStatus(currentState))
    }
  }

async function updatePractitionerDetails(
  PractitionerRole: R4.IPractitionerRole,
  Location: R4.ILocation
): Promise<boolean> {
  if (PractitionerRole) {
    const practitionerRole: R4.IPractitionerRole = {
      resourceType: 'PractitionerRole',
      id: PractitionerRole.id,
      identifier: [
        {
          system: 'email',
          value: getIdentifierValueBySystem(
            PractitionerRole.identifier ?? [],
            'email'
          ),
        },
        {
          system: 'phone',
          value: getIdentifierValueBySystem(
            PractitionerRole.identifier ?? [],
            'phone'
          ),
        },
      ],
      active: true,
      practitioner: {
        reference: `${PractitionerRole.practitioner?.reference}`,
        type: 'Practitioner',
        display: `${PractitionerRole.practitioner?.display}`,
      },
      organization: {
        id: `${PractitionerRole.organization?.id}`,
        reference: `${PractitionerRole.organization?.reference}`,
        type: 'Organization',
        display: `${PractitionerRole.organization?.display}`,
      },
      code: [
        {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/pr-roles',
              code: 'partner-lab',
              display: 'Partner Lab',
            },
          ],
        },
      ],
      location: [
        {
          reference: PractitionerRole.location
            ? PractitionerRole.location[0].reference
            : '',
        },
        {
          reference: `Location/${Location.id}`,
        },
      ],
    }

    const phoneContact: R4.IContactPoint = {}
    phoneContact.use = ContactPointUseKind._mobile
    phoneContact.system = R4.ContactPointSystemKind._phone
    phoneContact.rank = 1
    phoneContact.value = getTelecomOfPractitioner(
      PractitionerRole,
      R4.ContactPointSystemKind._phone ?? ''
    )

    if (practitionerRole.telecom == null) {
      practitionerRole.telecom = [phoneContact]
    } else {
      practitionerRole.telecom.push(phoneContact)
    }

    const emailContact: R4.IContactPoint = {}
    emailContact.use = ContactPointUseKind._home
    emailContact.system = R4.ContactPointSystemKind._email
    emailContact.rank = 1
    emailContact.value = getEmailOfPractitionerRole(
      PractitionerRole,
      R4.ContactPointSystemKind._email ?? ''
    )

    if (practitionerRole.telecom == null) {
      practitionerRole.telecom = [emailContact]
    } else {
      practitionerRole.telecom.push(emailContact)
    }
    logger.info('Partner Lab Update')
    logger.info(PractitionerRole)
    const fhirApi: FHIRApiClient = new FHIRApiClient()
    const response: any = await fhirApi.doUpdateFHIRResourceRequest(
      `/PractitionerRole/${PractitionerRole.id}` ?? '',
      practitionerRole,
      PractitionerRole?.meta?.versionId ?? ''
    )
    logger.info('Practitioner Update Role Response')
    logger.info(response)
    const relatedFhirDecodeRes: E.Either<Errors, R4.IPractitionerRole> =
      R4.RTTI_PractitionerRole.decode(response)
    if (relatedFhirDecodeRes._tag === 'Right') {
      return true
    }
  }

  return false
}

function getPartnerLabRequestTransactionObject(
  newLocation: PartnerLab,
  practitionerRole: R4.IPractitionerRole,
  existingLocation?: R4.ILocation
): R4.IBundle | undefined {
  const matchString: string = `W/${JSON.stringify(
    existingLocation?.meta?.versionId ?? ' '
  )}`

  const org: R4.IOrganization = getCurrentUserUnitDetails()
  const location: R4.ILocation = {
    resourceType: 'Location',
    id: existingLocation ? existingLocation.id : newLocation.id,
    name: newLocation.labName,
    mode: R4.LocationModeKind._instance,
    status: R4.LocationStatusKind._active,
    description: 'PartnerLab',
  }
  const phoneContact: R4.IContactPoint = {}
  phoneContact.use = ContactPointUseKind._mobile
  phoneContact.system = R4.ContactPointSystemKind._phone
  phoneContact.rank = 1
  phoneContact.value = newLocation.labPhoneNumber

  if (location.telecom == null) {
    location.telecom = [phoneContact]
  } else {
    location.telecom.push(phoneContact)
  }

  const commissionExt: R4.IExtension = {
    url: 'http://wellopathy.com/fhir/india/core/CodeSystem/commission',
    valueInteger: newLocation.commission,
  }

  location.extension = []
  location.extension.push(commissionExt)

  if (newLocation.labAddress.length > 0) {
    const address: R4.IAddress = {}
    const add: string[] = []
    let patAddress: R4.IAddress = {}
    add.push(newLocation.labAddress)
    address.line = add
    address.use = R4.AddressUseKind._home
    address.type = R4.AddressTypeKind._both
    patAddress = address
    location.address = patAddress
  }

  const practRole: R4.IPractitionerRole = {
    ...practitionerRole,
  }
  const practMatchString: string = `W/${JSON.stringify(
    practRole?.meta?.versionId ?? ' '
  )}`
  practRole.location = [
    {
      reference: practRole.location ? practRole.location[0].reference : '',
    },
    {
      reference: `Location/${location.id}`,
    },
  ]

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `Location/${location.id}`,
        request: {
          ifMatch: matchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `Location/${location.id}`,
        },
        resource: location,
      },
      {
        fullUrl: `PractitionerRole/${practRole.id}`,
        request: {
          ifMatch: practMatchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `PractitionerRole/${practRole.id}`,
        },
        resource: practRole,
      },
    ],
  }
  return requestBundle
}

export default partnerLabManagementSlice.reducer
