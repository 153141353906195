import { Button, Grid, Typography, Box, Divider } from '@material-ui/core'
import React, { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { cloneDeep } from 'lodash'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'
import useWindowDimensions from 'utils/hooks/useDimensionHook'

import {
  selectedProductIdState,
  selectSkippedState,
  viewState,
} from 'models/medicine-delivery/product-states'
import { CartItemsForClinic } from './cart-items-for-clinic'
import { CartItemsForPatient } from './cart-items-for-patient'
import { CartPaymentSummary } from './cart-payment-summary'
import {
  cartOrderForCachedState,
  cartSkippedState,
  cartState,
} from '../../../../../../models/medicine-delivery/cart/cart-states'
import { CartItem } from '../../../../../../models/medicine-delivery/cart/cart-models'
import { CartOrderTile } from './cart-order-tile'

export const CartDetails: React.FC = () => {
  const [cart, setCart] = useRecoilState<any>(cartState)
  const [view, setView] = useRecoilState(viewState)
  const [selectedProductId, setSelectedProductId] = useRecoilState(
    selectedProductIdState
  )

  const { height } = useWindowDimensions()

  const [skipped, setSkipped] = useRecoilState<string[]>(cartSkippedState)

  const orderFor = useRecoilValue(cartOrderForCachedState)
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  const [skippedStateData, setSkippedData] =
    useRecoilState<any>(selectSkippedState)

  if (view !== 'cart') {
    return <></>
  }

  if (cart.items.length === 0) {
    return (
      <Box
        width='99%'
        paddingTop={2}
        style={{ backgroundColor: 'white', overflow: 'hidden' }}
        paddingLeft={1}
        display='flex'
        flexDirection='column'
      >
        <Grid container style={{ height: 200 }}>
          <Grid item xs={12}>
            <Typography
              variant='h4'
              style={{ paddingTop: 100, textAlign: 'center' }}
            >
              Cart is empty
            </Typography>
          </Grid>
        </Grid>
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
        >
          <Box width='70%' display='flex' flexDirection='column'>
            {skipped.length > 0 && (
              <Box
                display='flex'
                width='100%'
                flexDirection='row'
                justifyContent='flex-start'
                paddingRight={3}
                py={1}
              >
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    setView('productDetails')
                    setSelectedProductId(undefined)
                    setSkippedData(true)
                  }}
                >
                  Add Skipped Items
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    )
  }

  const onChangeCustomerDiscount = (item: CartItem, value: string) => {
    const { sku } = item.product
    const discount = Number(value)
    const clone: any = cloneDeep(cart)
    // Remove this this item from collection
    const filtered = clone.items.filter((i: any) => i.product.sku.id !== sku.id)
    const cloneItem = cloneDeep(item)
    cloneItem.customerDiscount = discount
    clone.items = [...filtered, cloneItem]
    setCart(clone)
  }

  const onDelete = (item: CartItem) => {
    setShowDeleteConfirmation({
      open: true,
      onContinueActionClicked: () => {
        setShowDeleteConfirmation({ open: false })
        const { sku } = item.product
        const clone: any = cloneDeep(cart)
        // Remove this this item from collection
        const filtered = clone.items.filter(
          (i: any) => i.product.sku.id !== sku.id
        )
        clone.items = [...filtered]
        setCart(clone)
      },
      onCancelActionClicked: () => {
        setShowDeleteConfirmation({ open: false })
      },
      displayText: 'Are you sure you want to delete this record?',
      continueActionButtonText: 'Delete',
      cancelActionButtonText: 'Cancel',
    })
  }

  const onUpdateQuantity = (item: CartItem, quantity: number) => {
    const { sku } = item.product
    const newQuantity = item.quantity + quantity
    if (newQuantity <= 0) {
      // cannot decrease quantity below or equal to zero
      return
    }
    const clone: any = cloneDeep(cart)
    // Remove this this item from collection
    const filtered = clone.items.filter((i: any) => i.product.sku.id !== sku.id)
    const cloneItem = cloneDeep(item)
    cloneItem.quantity += quantity
    clone.items = [...filtered, cloneItem]
    setCart(clone)
  }

  return (
    <Box
      width='99%'
      paddingTop={2}
      style={{ backgroundColor: 'white', overflow: 'hidden' }}
      paddingLeft={1}
      display='flex'
      flexDirection='column'
    >
      <Box width='100%' display='flex' flexDirection='row'>
        <Box width='70%' display='flex' flexDirection='column'>
          <Box>
            <Typography variant='h6' style={{ fontWeight: 'bold' }}>
              My Cart
            </Typography>
          </Box>
          {orderFor === 'clinic' ? (
            <Box>
              <CartItemsForClinic
                onDelete={onDelete}
                onUpdateQuantity={onUpdateQuantity}
              />
            </Box>
          ) : (
            <CartItemsForPatient
              onDelete={onDelete}
              onUpdateQuantity={onUpdateQuantity}
              onChangeCustomerDiscount={onChangeCustomerDiscount}
            />
          )}
        </Box>
        <Box
          width='5%'
          display='flex'
          flexDirection='row'
          justifyContent='center'
        >
          <Box
            display='flex'
            justifyContent='flex-start'
            px={1}
            paddingTop={1}
            width='5%'
          >
            <Divider
              orientation='vertical'
              variant='fullWidth'
              flexItem
              style={{
                width: '1px',
              }}
            />
          </Box>
        </Box>
        <Box
          width={orderFor === 'clinic' ? '20%' : '22%'}
          display='flex'
          flexDirection='column'
        >
          <Box>
            <Typography variant='h6' style={{ fontWeight: 'bold' }}>
              Payment Summary
            </Typography>
          </Box>
          <Box paddingTop={2.5}>
            <CartPaymentSummary />
          </Box>
          <Box
            display='flex'
            width='100%'
            flexDirection='row'
            justifyContent='flex-end'
            paddingBottom={1}
            paddingTop={10}
          >
            <Button
              variant='contained'
              color='primary'
              onClick={() => setView('address')}
            >
              Proceed
            </Button>
          </Box>
        </Box>
      </Box>
      {/* <Box width='100%' display='flex' flexDirection='row'>
        <Box width='70%' display='flex' flexDirection='column'>
          {skipped.length > 0 && (
            <Box
              display='flex'
              width='100%'
              flexDirection='row'
              justifyContent='flex-end'
              paddingRight={3}
              py={1}
            >
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  setView('productDetails')
                  setSelectedProductId(undefined)
                }}
              >
                Add Skipped Items
              </Button>
            </Box>
          )}
        </Box>
        <Box width='25%' display='flex' flexDirection='column'>
          <Box
            display='flex'
            width='100%'
            flexDirection='row'
            justifyContent='flex-end'
            paddingBottom={1}
          >
            <Button
              variant='contained'
              color='primary'
              onClick={() => setView('address')}
            >
              Proceed
            </Button>
          </Box>
        </Box>
      </Box> */}
      {showDeleteConfirmation.open && (
        <ConfirmationDialog {...showDeleteConfirmation} id='delete' />
      )}
    </Box>
  )
}
