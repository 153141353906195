import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { logger } from 'utils/logger'
import { PatientPreAppointmentSetupType } from './patientPreAppointmentSetupType'

const initialState: PatientPreAppointmentSetupType = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const patientInvitationSetupSlice = createSlice({
  name: 'patientInvitations',
  initialState,
  reducers: {
    updateState(state, action: PayloadAction<PatientPreAppointmentSetupType>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },
  },
})

export const sendPatientInvitations =
  (patientId: string, patientPhone: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: PatientPreAppointmentSetupType = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(
      patientInvitationSetupSlice.actions.updateState(addingCreatePersonState)
    )
    try {
      if (patientId) {
        const enRolClient: EnrolCient = new EnrolCient()

        const response: AxiosResponse =
          await enRolClient.doCreateEnrolmentFlowRequest(
            `enrolment/pre-appt/patientForm/${patientId}/${patientPhone}/sendLink`,
            ''
          )

        logger.info('Response ', response)

        if (response) {
          const successCreatePersonState: PatientPreAppointmentSetupType = {
            adding: false,
            additionSuccessful: true,
            error: false,
            errorMessage: '',
          }
          dispatch(
            patientInvitationSetupSlice.actions.updateState(
              successCreatePersonState
            )
          )
          dispatch(showSuccessAlert('Invitation sent successfully'))
          return
        }
      }
      const errorCreatePersonState: PatientPreAppointmentSetupType = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Something went wrong, Please try again later',
      }
      dispatch(
        patientInvitationSetupSlice.actions.updateState(errorCreatePersonState)
      )
      return
    } catch (error: any) {
      let message: string = 'Error while sending invitations. Please try later'
      const errorData: string = error.message
      if (errorData.includes('400'))
        message = 'Either email or phone already exist'
      logger.info('error', error)
      const errorCreatePersonState: PatientPreAppointmentSetupType = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: message,
      }
      dispatch(
        patientInvitationSetupSlice.actions.updateState(errorCreatePersonState)
      )
      dispatch(showErrorAlert('Either email or phone already exist'))
    }
  }

interface BulkInviteModel {
  patientId: string
  patientPhone: string
}

export const sendBulkPatientInvitations =
  (bulkPhoneNumbers: BulkInviteModel[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: PatientPreAppointmentSetupType = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(
      patientInvitationSetupSlice.actions.updateState(addingCreatePersonState)
    )
    try {
      if (bulkPhoneNumbers) {
        const enRolClient: EnrolCient = new EnrolCient()

        await Promise.allSettled(
          bulkPhoneNumbers.map(async (e: any, index: any) => {
            const response = await enRolClient.doCreateEnrolmentFlowRequest(
              `enrolment/pre-appt/patientForm/${e.patientId}/${e.patientPhone}/sendLink`,
              ''
            )
          })
        )

        const successCreatePersonState: PatientPreAppointmentSetupType = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        dispatch(
          patientInvitationSetupSlice.actions.updateState(
            successCreatePersonState
          )
        )
        dispatch(showSuccessAlert('Invitation sent successfully'))
        return
      }

      const errorCreatePersonState: PatientPreAppointmentSetupType = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Something went wrong, Please try again later',
      }
      dispatch(
        patientInvitationSetupSlice.actions.updateState(errorCreatePersonState)
      )
      return
    } catch (error: any) {
      let message: string = 'Error while sending invitations. Please try later'
      const errorData: string = error.message
      if (errorData.includes('400'))
        message = 'Error while sending invitations. Please try later'
      logger.info('error', error)
      const errorCreatePersonState: PatientPreAppointmentSetupType = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: message,
      }
      dispatch(
        patientInvitationSetupSlice.actions.updateState(errorCreatePersonState)
      )
      dispatch(
        showErrorAlert('Error while sending invitations. Please try later')
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(patientInvitationSetupSlice.actions.updateState(initialState))
}

export default patientInvitationSetupSlice.reducer
