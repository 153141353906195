import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Divider,
  Typography,
  InputAdornment,
} from '@material-ui/core'
import { AppointmentRate, PractQuestions } from 'models/appointmentRate'
import { Conditions, ConditionsForOnboarding } from 'models/conditions'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  isAyurvedaDoctor,
  isAyurvedaDoctorForOnboarding,
  isDietitianForOnboarding,
  isPTDoctorForO,
  isTherapistForOnboarding,
  isUserDoctor,
} from 'services/userDetailsService'
import { arrayYears } from 'utils/constants/calender_constants'
import { EduOrganizations } from 'utils/constants/edu_organizations'
import {
  PROFILE_SELF_DESCRIPTION_URL,
  QUALIFICATION_SPECIALIZATION_URL,
} from 'utils/constants/fhir_vs_ext_constants'
import { Languages } from 'utils/constants/langauages'
import {
  NurseQualifications,
  Qualifications,
} from 'utils/constants/qualifications'
import {
  NurseSpecializations,
  Specializations,
} from 'utils/constants/spcializations'
import {
  addExtensionToExtensionsOfUrl,
  getCodeOfSystemCodings,
  getCodeOfSystemFromCodableConceptList,
  getDefaultCodeOfSystemFromCodableConceptList,
  getStringValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { logger } from 'utils/logger'
import { ValueSetSelectSingleWithCheckbox } from 'views/components/common/singleValueSetWithCheckbox'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloMultiSelectAutoComplete } from 'views/components/LeftMenu/WelloMultiSelectAutoComplete'
import { WelloSelect } from 'views/components/LeftMenu/WelloSelect'
import { WelloSelectAutoComplete } from 'views/components/LeftMenu/WelloSelectAutoComplete'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { WelloTextField } from 'wello-web-components'

interface ClinicalExperienceDetailsProps {
  practitioner: R4.IPractitioner
  practitionerRole: R4.IPractitionerRole
  question: PractQuestions
  chargeItemDef: AppointmentRate
  hasError: boolean
  onSplChange: (spl: R4.ICodeableConcept[]) => void
  onProfileDescriptionChange: (desc: string) => void
}

export const ClinicalExperienceDetailsForm: React.FC<ClinicalExperienceDetailsProps> =
  (props: ClinicalExperienceDetailsProps) => {
    const { t } = useTranslation()
    const [qualification, setQualifications] = useState<
      R4.IPractitioner_Qualification[]
    >(
      props.practitioner.qualification ?? [
        { id: getUniqueTempId() } as R4.IPractitioner_Qualification,
      ]
    )

    const [service, setService] = useState<AppointmentRate>(props.chargeItemDef)
    const [spl, setSpl] = useState<R4.ICodeableConcept[]>(
      props.practitionerRole.specialty ?? []
    )
    const [language, setLanguage] = useState<R4.ICodeableConcept[]>(
      props.practitioner.communication ?? []
    )
    const [selectedConditionForCheckBox, setSelectedConditionForCheckBox] =
      useState<Conditions[]>(props.question.conditions ?? [])
    const [desc, setDesc] = useState<string>('')
    const [inPerson, setInPerson] = useState<number>(
      props.chargeItemDef.inPerson
    )
    const [video, setVideo] = useState<number>(props.chargeItemDef.video)
    const [followUp, setFollowUp] = useState<number>(
      props.chargeItemDef.followUp
    )
    const handleChange = (event: any, condition: R4.ICoding, index: number) => {
      const values: Conditions[] = [...selectedConditionForCheckBox]

      if (event.target.checked === true) {
        const finalData = values.filter(
          (d: ConditionsForOnboarding) =>
            d.data && d.data.code === condition.code
        )
        if (finalData.length === 0) {
          values.push({
            checked: true,
            data: condition,
          })
        }
      } else {
        const indexData = values.findIndex(
          (x) => x.data && x.data.code === condition.code
        )
        if (indexData > -1) {
          // only splice array when item is found
          values.splice(indexData, 1) // 2nd parameter means remove one item only
        }
      }

      setSelectedConditionForCheckBox(values)
    }

    useEffect(() => {
      props.practitioner.qualification = qualification
      //   props.chargeItemDef = service
    }, [qualification, props.practitioner])

    useEffect(() => {
      props.practitionerRole.specialty = spl
    }, [spl, props.practitionerRole])

    useEffect(() => {
      props.question.conditions = selectedConditionForCheckBox
    }, [selectedConditionForCheckBox, props.question])

    useEffect(() => {
      props.practitioner.communication = language
    }, [language, props.practitioner])

    useEffect(() => {
      const selfExt: R4.IExtension = {
        url: PROFILE_SELF_DESCRIPTION_URL,
        valueString: desc,
      }

      props.practitioner.extension = addExtensionToExtensionsOfUrl(
        props.practitioner.extension,
        selfExt
      )
    }, [props.practitioner, desc])

    useEffect(() => {
      props.practitioner.communication = language
    }, [language, props.practitioner])

    useEffect(() => {
      props.chargeItemDef.video = video
    }, [props.chargeItemDef.video, video])

    useEffect(() => {
      props.chargeItemDef.followUp = followUp
    }, [props.chargeItemDef.followUp, followUp])

    useEffect(() => {
      props.chargeItemDef.inPerson = inPerson
    }, [props.chargeItemDef.inPerson, inPerson])

    // useEffect(() => {
    //   props.chargeItemDef.phone = audio
    // }, [props.chargeItemDef.phone, audio])

    const handleAddMore = () => {
      const values: R4.IPractitioner_Qualification[] = [...qualification]
      values.push({ id: getUniqueTempId() } as R4.IPractitioner_Qualification)
      setQualifications(values)
    }

    function handleQualificationSelection(index: number, coding: R4.ICoding) {
      const values: R4.IPractitioner_Qualification[] = [...qualification]
      values[index].code = {
        coding: [coding],
      }
      setQualifications(values)
    }

    function handleYearSelectionSelection(index: number, year: string) {
      const values: R4.IPractitioner_Qualification[] = [...qualification]
      values[index].period = {
        end: year,
      }
      setQualifications(values)
    }
    function handleOrganizationSelectionSelection(
      index: number,
      reference: R4.IReference
    ) {
      const values: R4.IPractitioner_Qualification[] = [...qualification]
      values[index].issuer = reference
      setQualifications(values)
    }

    function handleOrganizationSpecialization(
      index: number,
      coding: R4.ICoding
    ) {
      const values: R4.IPractitioner_Qualification[] = [...qualification]
      values[index].extension = [
        {
          valueCoding: coding,
          url: QUALIFICATION_SPECIALIZATION_URL,
        },
      ]
      setQualifications(values)
    }

    function handleOrganizationRegistrationNumber(
      index: number,
      change: string
    ) {
      const values: R4.IPractitioner_Qualification[] = [...qualification]
      if (change && change.length > 0) {
        values[index].identifier = [
          {
            system: '',
            value: change,
          },
        ]
      } else {
        values[index].identifier = undefined
      }
      setQualifications(values)
    }

    function handleLanguageChanges(languageCodes: R4.ICoding[]) {
      if (languageCodes) {
        const languageCodeableConcepts: R4.ICodeableConcept[] =
          languageCodes.map((e) => {
            const oneLanguage: R4.ICodeableConcept = {
              coding: [e],
            }
            return oneLanguage
          })
        setLanguage(languageCodeableConcepts)
        // props.practitioner.communication = languageCodeableConcepts
      } else {
        // props.practitioner.communication = undefined
      }
    }

    function handleInPersonRate(rate: number) {
      props.chargeItemDef.inPerson = rate
      setInPerson(rate)
      setService({ ...service, inPerson: rate })
    }

    function handleVideoRate(rate: number) {
      props.chargeItemDef.video = rate
      setVideo(rate)
      setService({ ...service, video: rate })
    }

    function handleFollowUp(rate: number) {
      props.chargeItemDef.followUp = rate
      setFollowUp(rate)
      setService({ ...service, followUp: rate })
    }

    // function handlePhoneRate(rate: number) {
    //   props.chargeItemDef.phone = rate
    //   setAudio(rate)
    //   setService({ ...service, phone: rate })
    // }

    function handleSpecialtyChanges(languageCodes: R4.ICoding[]) {
      if (languageCodes) {
        const languageCodeableConcepts: R4.ICodeableConcept[] =
          languageCodes.map((e) => {
            const oneLanguage: R4.ICodeableConcept = {
              coding: [e],
            }
            return oneLanguage
          })

        setSpl(languageCodeableConcepts)
        props.onSplChange(languageCodeableConcepts)
        // props.practitionerRole.specialty = languageCodeableConcepts
      } else {
        // props.practitionerRole.specialty = undefined
      }
    }

    return (
      <Box display='flex' flexDirection='column'>
        <Box display='flex' flexDirection='row' justifyContent='center'>
          <Typography variant='h6'>
            {' '}
            {t('labelCommon:your_qualification')}
          </Typography>
        </Box>
        <Box display='flex' flexDirection='column' py={2}>
          {qualification.map(
            (org: R4.IPractitioner_Qualification, index: number) => (
              <Box
                display='flex'
                flexDirection='column'
                alignItems='flex-start'
                key={`unit_actor_small_details_${org.id}`}
                width='100%'
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  alignItems='flex-start'
                  key={`unit_actor_small_details_${org.id}row`}
                  width='100%'
                >
                  <Box p={0.5} width='60%' paddingRight={1}>
                    <WelloSelectFHIRCoding
                      title={t('labelCommon:qualification_label')}
                      id='qualifications'
                      availableCodings={
                        isUserDoctor(props.practitionerRole) ||
                        isAyurvedaDoctorForOnboarding(props.practitionerRole) ||
                        isPTDoctorForO(props.practitionerRole) ||
                        isTherapistForOnboarding(props.practitionerRole) ||
                        isDietitianForOnboarding(props.practitionerRole)
                          ? Qualifications
                          : NurseQualifications
                      }
                      onChanged={(e) => {
                        if (e) {
                          handleQualificationSelection(index, e)
                        }
                      }}
                      textFieldProps={{
                        error: props.hasError && org.code === undefined,
                        helperText:
                          props.hasError && org.code === undefined
                            ? 'Qualification is required'
                            : '',
                      }}
                      preSelectedCoding={org.code}
                    />
                  </Box>
                  <Box py={0.5} width='20%' px={1}>
                    <Box display='flex' flexDirection='column' width='100%'>
                      <WelloFormItemLabel title='Registration No' />

                      <WelloTextFieldWithoutTitle
                        textProps={{
                          id: `medical_qualification_reg_num_${index}`,
                          error: props.hasError && org.identifier === undefined,
                          helperText:
                            props.hasError && org.identifier === undefined
                              ? 'Registration number is required'
                              : '',

                          onChange: (e) => {
                            handleOrganizationRegistrationNumber(
                              index,
                              e.target.value
                            )
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box flexGrow={1} py={0.5} width='20%' paddingLeft={1}>
                    <WelloSelect
                      label='Year'
                      availableOptions={arrayYears}
                      required={true}
                      type='Select Year'
                      displayField='value'
                      valueField='key'
                      error={
                        props.hasError && org.period === undefined
                          ? null
                          : undefined
                      }
                      onSelectionChanged={(value: any) => {
                        handleYearSelectionSelection(index, value.key)
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  display='flex'
                  flexDirection='row'
                  alignItems='flex-start'
                  key={`unit_actor_small_details_${org.id}row1`}
                  width='100%'
                >
                  <Box p={0.5} width='60%' paddingRight={1}>
                    <WelloSelectAutoComplete
                      title='Medical College'
                      availableOptions={EduOrganizations}
                      displayField='name'
                      id={`medical_qualification_reg_num_${index}`}
                      valuesField='id'
                      error={props.hasError && org.issuer === undefined}
                      helperText={
                        props.hasError && org.issuer === undefined
                          ? 'Medical Council is required'
                          : ''
                      }
                      onOptionSelected={(selectedOrganization) => {
                        const selectedOpt: R4.IOrganization =
                          selectedOrganization as R4.IOrganization
                        logger.info(selectedOpt)
                        handleOrganizationSelectionSelection(index, {
                          id: selectedOpt.id,
                          display: selectedOpt.name,
                          reference: `${selectedOpt.resourceType}/${selectedOpt.id}`,
                        })
                      }}
                      disabled={false}
                    />
                  </Box>
                  <Box py={0.5} width='40%' paddingLeft={1}>
                    <WelloSelectAutoComplete
                      title={t('labelCommon:specialization')}
                      availableOptions={
                        isUserDoctor(props.practitionerRole) ||
                        isAyurvedaDoctorForOnboarding(props.practitionerRole) ||
                        isPTDoctorForO(props.practitionerRole) ||
                        isTherapistForOnboarding(props.practitionerRole) ||
                        isDietitianForOnboarding(props.practitionerRole)
                          ? Specializations
                          : NurseSpecializations
                      }
                      displayField='display'
                      id={`medical_qualification_specialization_${index}`}
                      valuesField='code'
                      onOptionSelected={(selectedOrganization) => {
                        logger.info(
                          'selectedOrganization',
                          selectedOrganization
                        )
                        handleOrganizationSpecialization(
                          index,
                          selectedOrganization
                        )
                      }}
                      disabled={false}
                    />
                  </Box>
                </Box>
              </Box>
            )
          )}
        </Box>
        <Box display='flex' alignItems='center' py={0.5} flexDirection='row'>
          <Box>
            <Button variant='text' onClick={handleAddMore}>
              <Typography variant='subtitle2' color='primary'>
                {t('labelCommon:add_another_qualification')}
              </Typography>
            </Button>
          </Box>
          <Box flexGrow={1} paddingLeft={2}>
            {' '}
            <Divider />{' '}
          </Box>
        </Box>
        <Box display='flex' flexDirection='row' width='100%'>
          <Box
            display='flex'
            flexDirection='column'
            width='50%'
            paddingRight={1}
          >
            <WelloMultiSelectAutoComplete
              title='Job Titles'
              availableOptions={Specializations}
              displayField='display'
              id='job_titles_'
              valuesField='code'
              pluralWord='job title'
              disabled={false}
              singularWorld='job titles'
              onOptionSelected={(selectedOrganization) => {
                logger.info('selectedOrganization', selectedOrganization)
                handleSpecialtyChanges(selectedOrganization as R4.ICoding[])
              }}
              error={
                props.hasError &&
                (props.practitionerRole.specialty === undefined ||
                  props.practitionerRole.specialty?.length === 0) &&
                spl.length === 0
              }
              helperText={
                props.hasError &&
                (props.practitionerRole.specialty === undefined ||
                  props.practitionerRole.specialty?.length === 0) &&
                spl.length === 0
                  ? 'Job Title(s) are required'
                  : ''
              }
            />
            <Box display='flex' flexDirection='row'>
              <WelloMultiSelectAutoComplete
                title={t('labelCommon:languages_title')}
                availableOptions={Languages}
                displayField='display'
                id='unit_actor_languages'
                valuesField='code'
                pluralWord='language'
                singularWorld='languages'
                error={
                  props.hasError &&
                  (props.practitioner.communication === undefined ||
                    props.practitioner.communication?.length === 0) &&
                  language.length === 0
                }
                helperText={
                  props.hasError &&
                  (props.practitioner.communication === undefined ||
                    props.practitioner.communication?.length === 0) &&
                  language.length === 0
                    ? 'Languages are required'
                    : ''
                }
                disabled={false}
                onOptionSelected={(selectedOrganization) => {
                  handleLanguageChanges(selectedOrganization as R4.ICoding[])
                }}
              />
            </Box>
          </Box>

          {(isUserDoctor(props.practitionerRole) ||
            isAyurvedaDoctorForOnboarding(props.practitionerRole) ||
            isPTDoctorForO(props.practitionerRole) ||
            isTherapistForOnboarding(props.practitionerRole) ||
            isDietitianForOnboarding(props.practitionerRole)) && (
            <Box display='flex' flexDirection='row' width='50%' paddingLeft={1}>
              <Box display='flex' flexDirection='column' width='100%'>
                <WelloFormItemLabel title='Description' />

                <WelloTextFieldWithoutTitle
                  textProps={{
                    id: 'public_description',
                    rows: 5.3,
                    rowsMax: 6,
                    multiline: true,
                    error: props.hasError && desc.length === 0,
                    helperText:
                      props.hasError && desc.length === 0
                        ? 'Profile Description is required'
                        : '',

                    onChange: (e) => {
                      setDesc(e.target.value)
                      props.onProfileDescriptionChange(e.target.value)
                      const selfExt: R4.IExtension = {
                        url: PROFILE_SELF_DESCRIPTION_URL,
                        valueString: e.target.value,
                      }

                      props.practitioner.extension =
                        addExtensionToExtensionsOfUrl(
                          props.practitioner.extension,
                          selfExt
                        )
                    },
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
        {(isUserDoctor(props.practitionerRole) ||
          isAyurvedaDoctorForOnboarding(props.practitionerRole) ||
          isPTDoctorForO(props.practitionerRole)) && (
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='flex-start'
            p={0.5}
            width='100%'
          >
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='flex-start'
              width='20%'
              py={0.5}
            >
              <Typography variant='subtitle1' style={{ color: 'black' }}>
                {' '}
                Appointment Rate{' '}
              </Typography>
            </Box>

            <Box
              display='flex'
              flexDirection='row'
              justifyContent='flex-start'
              width='80%'
            >
              <Box px={1} width='25%'>
                <Box display='flex' flexDirection='column' width='100%'>
                  <WelloFormItemLabel title='In-Person' />

                  <WelloTextFieldWithoutTitle
                    textProps={{
                      id: `test`,
                      type: 'number',
                      inputProps: {
                        max: 1000,
                        min: 1,
                      },
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Box>
                              {' '}
                              <Typography variant='subtitle2' align='center'>
                                {' '}
                                ₹{' '}
                              </Typography>
                            </Box>
                          </InputAdornment>
                        ),
                      },
                      error:
                        props.hasError &&
                        (props.chargeItemDef.inPerson <= 1 ||
                          props.chargeItemDef.inPerson > 1000 ||
                          Number.isNaN(props.chargeItemDef.inPerson)) &&
                        (inPerson < 1 || inPerson > 1000),
                      helperText:
                        props.hasError &&
                        (props.chargeItemDef.inPerson <= 1 ||
                          props.chargeItemDef.inPerson > 1000 ||
                          Number.isNaN(props.chargeItemDef.inPerson)) &&
                        (inPerson < 1 || inPerson > 1000)
                          ? 'In-Person Appointment Rate between 1 to 1000'
                          : '',
                      onChange: (rate) => {
                        handleInPersonRate(parseInt(rate.target.value, 10))
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box width='25%' px={1}>
                <Box display='flex' flexDirection='column' width='100%'>
                  <WelloFormItemLabel title='Video' />

                  <WelloTextFieldWithoutTitle
                    textProps={{
                      id: ``,
                      //   value: lab.commission,
                      type: 'number',
                      inputProps: {
                        max: 100,
                        // min:0
                      },
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Box>
                              {' '}
                              <Typography variant='subtitle2' align='center'>
                                {' '}
                                ₹{' '}
                              </Typography>
                            </Box>
                          </InputAdornment>
                        ),
                      },
                      error:
                        props.hasError &&
                        (props.chargeItemDef.video <= 1 ||
                          props.chargeItemDef.video > 1000 ||
                          Number.isNaN(props.chargeItemDef.video)) &&
                        (video < 1 || video > 1000),
                      helperText:
                        props.hasError &&
                        (props.chargeItemDef.video <= 1 ||
                          props.chargeItemDef.video > 1000 ||
                          Number.isNaN(props.chargeItemDef.video)) &&
                        (video < 1 || video > 1000)
                          ? 'Video Appointment Rate between 1 to 1000'
                          : '',
                      onChange: (rate) => {
                        handleVideoRate(parseInt(rate.target.value, 10))
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box width='25%' paddingLeft={1} />
              <Box width='25%' paddingLeft={1}>
                <Box display='flex' flexDirection='column' width='100%'>
                  <WelloFormItemLabel title='Follow Up (in days)' />

                  <WelloTextFieldWithoutTitle
                    textProps={{
                      id: ``,
                      //   value: lab.commission,
                      type: 'number',
                      inputProps: {
                        max: 10,
                        min: 1,
                      },
                      error:
                        props.hasError &&
                        (props.chargeItemDef.followUp <= 1 ||
                          props.chargeItemDef.followUp > 10 ||
                          Number.isNaN(props.chargeItemDef.followUp)) &&
                        (followUp < 1 || followUp > 10),
                      helperText:
                        props.hasError &&
                        (props.chargeItemDef.followUp <= 1 ||
                          props.chargeItemDef.followUp > 10 ||
                          Number.isNaN(props.chargeItemDef.followUp)) &&
                        (followUp < 1 || followUp > 10)
                          ? 'Follow ups days between 1 to 10'
                          : '',
                      onChange: (rate) => {
                        handleFollowUp(parseInt(rate.target.value, 10))
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        <Box width='100%' flexDirection='row' display='flex'>
          <ValueSetSelectSingleWithCheckbox
            title='Pre-Appointment Questionnaire'
            url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-pre-appointment-question-section-vs'
            id='question'
            fuzzySearch={true}
            //   disabled={
            //     (org.systemOfMedicine &&
            //       org.systemOfMedicine.length === 0) ||
            //     checkForAllopathy(org) === true
            //   }
            disabled={false}
            onOptionSelected={(
              event: any,
              qindex: number,
              selectedKriya?: R4.ICoding
            ) => {
              if (selectedKriya) {
                handleChange(event, selectedKriya, qindex)
              }
            }}
            helperText={undefined}
            error={
              isUserDoctor(props.practitionerRole)
                ? selectedConditionForCheckBox.length === 0
                : undefined
            }
          />
        </Box>
      </Box>
    )
  }
