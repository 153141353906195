import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirAppointmentFullDetail } from 'models/fhirAppointmentFullDetail'
import { FhirLabOrderFullDetail } from 'models/fhirLabOrderFullDetails'
import { MedicalEvent, MedicalEventType } from 'models/medical_event'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getDateTime } from 'utils/dateUtil'
import {
  getDisplayOfSystemFromCodableConcept,
  getEmailOfPatient,
  getIdentifierValueBySystem,
  getNameFromHumanName,
  getProfilePicDoctor,
  getSpecializationsList,
} from 'utils/fhirResourcesHelper'
import {
  getExpandedAppointmentFromBundle,
  getExpandedAppointmentFullDetailFromBundle,
  getMedicationsStrings,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getLabOrderFullDetail } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { logger } from 'utils/logger'
import { PatientMedicalTimeLineStatus } from './patientMedicalTimeLineStatus'

const initialState: PatientMedicalTimeLineStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
}

const patientMedicalTimeLineSlice = createSlice({
  name: 'patientMedicalTimeLineSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<PatientMedicalTimeLineStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableAppointments = action.payload.availableAppointments
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const requestAvailableAppointmentsOfPatient =
  (
    selectedPatient: R4.IPatient,
    fhirAppointment: FhirAppointmentDetail
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PatientMedicalTimeLineStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(patientMedicalTimeLineSlice.actions.updatedStatus(state))
    try {
      const statusType: string = 'fulfilled'

      const appointmentSearchParameters: any = {
        '_include:iterate': 'Appointment:actor',
        _include: 'Appointment:slot',
        _count: 500,
        'status:': statusType,
        'actor:Patient.identifier': `address-proof|${getIdentifierValueBySystem(
          selectedPatient.identifier ?? [],
          'address-proof'
        )!},http://wellopathy.com/fhir/india/core/id/offset-phone|${getIdentifierValueBySystem(
          selectedPatient.identifier ?? [],
          'http://wellopathy.com/fhir/india/core/id/offset-phone'
        )!},email|${getEmailOfPatient(
          selectedPatient,
          ContactPointSystemKind._email
        )}`,
        '_revinclude:iterate': [
          'Encounter:appointment',
          'Condition:encounter',
          'Observation:encounter',
          'ClinicalImpression:encounter',
          'AllergyIntolerance:allergyIntolerance-encounter',
          'MedicationRequest:encounter',
        ],
      }

      const serviceRequestParameters: any = {
        '_include:iterate': [
          'ServiceRequest:instantiates-canonical',
          'DiagnosticReport:result',
        ],
        'status:': 'completed',
        _count: 500,
        'subject:Patient.identifier': `address-proof|${getIdentifierValueBySystem(
          selectedPatient.identifier ?? [],
          'address-proof'
        )!},http://wellopathy.com/fhir/india/core/id/offset-phone|${getIdentifierValueBySystem(
          selectedPatient.identifier ?? [],
          'http://wellopathy.com/fhir/india/core/id/offset-phone'
        )!},email|${getEmailOfPatient(
          selectedPatient,
          ContactPointSystemKind._email
        )}`,
        '_revinclude:iterate': 'DiagnosticReport:based-on',
      }

      const documentReferencesParameters: any = {
        'subject:Patient.identifier': `address-proof|${getIdentifierValueBySystem(
          selectedPatient.identifier ?? [],
          'address-proof'
        )!},http://wellopathy.com/fhir/india/core/id/offset-phone|${getIdentifierValueBySystem(
          selectedPatient.identifier ?? [],
          'http://wellopathy.com/fhir/india/core/id/offset-phone'
        )!},email|${getEmailOfPatient(
          selectedPatient,
          ContactPointSystemKind._email
        )}`,
        _count: 500,
        _sort: '-date',
      }

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const appointmentResponse: any =
        await fhirClient.doGetResourceForAppointmentWithIncludeIterate(
          '/Appointment',
          fhirAppointment.appointment.id!,
          appointmentSearchParameters
        )

      const labResResponse: any =
        await fhirClient.doGetResourceForAppointmentWithIncludeIterate(
          '/ServiceRequest',
          fhirAppointment.appointment.id!,
          serviceRequestParameters
        )

      const docResponses: any =
        await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
          '/DocumentReference?status=current',
          fhirAppointment.appointment.id!,
          documentReferencesParameters
        )

      let medicalEvents: MedicalEvent[] = []
      try {
        const resp: R4.IBundle = appointmentResponse
        /*  const resp: E.Either<Errors, R4.IBundle> =
           R4.RTTI_Bundle.decode(appointmentResponse) */

        const appResp: R4.IBundle = resp
        if (appResp.total && appResp?.total > 0) {
          const fhirAppointments: FhirAppointmentFullDetail[] =
            getExpandedAppointmentFullDetailFromBundle(appResp)

          const appointments: MedicalEvent[] = fhirAppointments
            .filter((e) => e.start !== undefined)
            .map((e) => ({
              id: e.appointment.id ?? '',
              eventDetails: e,
              eventType: MedicalEventType.DOCTOR_VISIT,
              startTime: getDateTime(e.start),
              endTime: getDateTime(e.end),

              summary: e.clinicalImpression?.summary ?? '',
              performerName: `${getNameFromHumanName(
                e.practitionerDetail.practitioner.name ?? []
              )}`,
              performerProfilePic: getProfilePicDoctor(
                e.practitionerDetail.practitioner
              ),
              performerSpecialization: getSpecializationsList(
                e.practitionerDetail.practitionerRole.specialty ?? []
              ),
              secondaryTitle: 'Medication',
              secondaryContent: getMedicationsStrings(e),
            }))

          medicalEvents = [...appointments]
        }
      } catch (error) {
        logger.error(error)
      } finally {
        logger.warn('Inside finally')
      }

      const labResp: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(labResResponse)
      if (labResp._tag === 'Right') {
        const labResponseBundle: R4.IBundle = labResp.right
        if (labResponseBundle.total && labResponseBundle?.total > 0) {
          const fhirLabOrders: FhirLabOrderFullDetail[] =
            getLabOrderFullDetail(labResponseBundle)
          fhirLabOrders.sort((a, b) => moment(a.start).diff(b.start))
          const labOrders: MedicalEvent[] = fhirLabOrders
            .filter((e) => moment(e.start).isValid() && moment(e.end).isValid())
            .map((e) => ({
              id: e.serviceRequest.id ?? '',
              eventDetails: e,
              eventType: MedicalEventType.LAB_REPORT,
              startTime: getDateTime(e.start),
            }))
          medicalEvents = [...medicalEvents, ...labOrders]
        }
      }

      try {
        const docResponseBundle: R4.IBundle = docResponses as R4.IBundle

        if (docResponseBundle.total && docResponseBundle?.total > 0) {
          const docs: R4.IDocumentReference[] = docResponseBundle.entry!.map(
            (e) => e.resource as R4.IDocumentReference
          )

          console.log(
            '-------------docs length-------------------',
            docs.length
          )

          docs.sort((a, b) => moment(a.date).diff(b.date))
          const docsEvents: MedicalEvent[] = docs
            .filter((e) => e.date !== undefined)
            .map((e) => ({
              id: e.id ?? '',
              eventDetails: e,
              tileText: getDisplayOfSystemFromCodableConcept(e.type ?? {}),
              eventType: MedicalEventType.DOCUMENT_REFERENCE,
              startTime: getDateTime(e.date),
              secondaryTitle: e.description,
            }))

          medicalEvents = [...medicalEvents, ...docsEvents]
        }
      } catch (error) {
        logger.error(error)
      } finally {
        logger.info(medicalEvents)
      }

      if (medicalEvents.length > 0) {
        medicalEvents.sort((a, b) => moment(b.startTime).diff(a.startTime))
        state.resultsAvailable = true
        state.searchingAppointments = false
        state.availableAppointments = medicalEvents
        state.noResultsAvailable = false
        state.errorReason = undefined
        state.errorWhileSearchingAppointments = false
        dispatch(patientMedicalTimeLineSlice.actions.updatedStatus(state))
      } else {
        const errorSearchDoctor: PatientMedicalTimeLineStatus = {
          searchingAppointments: false,
          errorWhileSearchingAppointments: false,
          resultsAvailable: false,
          noResultsAvailable: true,
        }
        dispatch(
          patientMedicalTimeLineSlice.actions.updatedStatus(errorSearchDoctor)
        )
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: PatientMedicalTimeLineStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
      }
      dispatch(
        patientMedicalTimeLineSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export default patientMedicalTimeLineSlice.reducer
