import {
  Box,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React from 'react'
import { DietCardForAyurveda } from '../diet/dietCardAyurveda'

interface DietProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  status: string
}

export const AyurvedaDietPlan: React.FC<DietProps> = ({
  fhirAppointmentDetails,
  status,
}: DietProps) => {
  const primary = useTheme().palette.primary.main

  return (
    <Box
      display='flex'
      borderRadius={4}
      borderColor='lightGrey'
      border={1}
      p={1}
      width='100%'
      flexDirection='column'
      flexGrow
    >
      <Box paddingBottom={2}>
        <Typography variant='subtitle1'>Diet</Typography>
      </Box>
      <DietCardForAyurveda
        fhirAppointmentDetails={fhirAppointmentDetails}
        status={status}
      />
    </Box>
  )
}
