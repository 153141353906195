import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  IChargeItemDefinition,
  IPlanDefinition,
} from '@ahryman40k/ts-fhir-types/lib/R4'

import _ from 'lodash'
import { CPGForTable } from 'models/administration/cpgDetailsForTable'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleRef,
  getCurrentUserUnitDetails,
} from 'services/userDetailsService'
import { KriyaDetail } from 'views/components/consultation/plan/ayurveda/addKriyasForEncounter'

export async function addKriyaDetails(prescriptionsBundle: R4.IBundle) {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateFHIRTransaction('', prescriptionsBundle)

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  return true
}

export async function addNewKriyaDetails(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  kriyaDetails: KriyaDetail[]
) {
  let result = false
  try {
    const svs = kriyaDetails.map((e) =>
      getServiceRequestForKriya(patientReference, encounterId, e)
    )

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: svs.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: sv.resourceType,
        },
      })),
    }

    result = await addKriyaDetails(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export function getServiceRequestForKriya(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  kriyaDetails: KriyaDetail
): R4.IServiceRequest {
  if (!kriyaDetails.kriya) {
    throw new Error('Kriya details not found')
  }
  const serviceRequest: R4.IServiceRequest = {
    resourceType: 'ServiceRequest',
    subject: patientReference,
    status: 'active',
    priority: kriyaDetails.priority ?? 'routine',
    intent: 'proposal',

    category: [
      {
        text: 'Kriya',
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/umls',
            display: 'Kriya',
            code: 'C0025122',
          },
          {
            system: 'http://snomed.info/sct',
            display: 'Kriya',
            code: '452091000124101',
          },
        ],
      },
    ],

    code: {
      coding: [kriyaDetails.kriya],
    },
    encounter: encounterId,
    authoredOn: new Date().toISOString(),

    requester: getCurrentUserPractitionerRoleRef(),
  }
  const pract: R4.IPractitioner = getCurrentUserPractitionerDetails()
  if (!_.isEmpty(kriyaDetails.remarks)) {
    const noteText: R4.IAnnotation = {
      authorReference: {
        reference: `${pract.resourceType}/${pract.id}`,
      },
      text: kriyaDetails.remarks,
    }
    serviceRequest.note = [noteText]
  }

  if (kriyaDetails.medications && kriyaDetails.medications.length > 0) {
    serviceRequest.orderDetail = kriyaDetails.medications.map((medication) => ({
      text: medication.display,
      coding: [medication],
    }))
  }

  return serviceRequest
}

export async function getCPSubscriptionsListForWPAdmin(): Promise<
  CPGForTable[] | boolean
> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses = await fhirClient.doGetResource(
    `/PlanDefinition`,
    {
      type: 'cpg-subscription-definition',
    }
  )

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  const resp: R4.IBundle = response
  if (resp.entry && resp.entry.length === 0) {
    return false
  }
  const taskObject = resp.entry
    ?.filter((entry) => entry.resource?.resourceType === 'PlanDefinition')
    .map((entry) => entry.resource as R4.IPlanDefinition)

  let returnResult: CPGForTable[] = []
  if (taskObject && taskObject.length > 0) {
    returnResult = taskObject.map((task) => {
      const res: CPGForTable = {
        cpgId: task.id ?? '',
        cpgName: task.title ?? '',
        rawCpg: task,
        cpgDescription: task.description ?? '',
        cpgGoal: getGoalDescription(task.goal ?? []),
        offeringPlans: getChargeItemDefinitionFromBundle(resp, task.id ?? ''),
      }

      return res
    })
  }

  return returnResult
}

export async function getCPSubscriptionsOrgAndUnitAdmin(): Promise<
  CPGForTable[] | boolean
> {
  const fhirClient: EnrolCient = new EnrolCient()
  const response: any | FHIRErrorResponses = await fhirClient.doGetResource(
    `/cpg-management/getAvailableCPGs`
  )

  const resp: R4.IBundle = response
  if (resp.entry && resp.entry.length === 0) {
    return false
  }
  const taskObject = resp.entry
    ?.filter((entry) => entry.resource?.resourceType === 'PlanDefinition')
    .map((entry) => entry.resource as R4.IPlanDefinition)

  let returnResult: CPGForTable[] = []
  if (taskObject && taskObject.length > 0) {
    returnResult = taskObject.map((task) => {
      const res: CPGForTable = {
        cpgId: task.id ?? '',
        cpgName: task.title ?? '',
        rawCpg: task,
        cpgDescription: task.description ?? '',
        cpgGoal: getGoalDescription(task.goal ?? []),
        offeringPlans: getChargeItemDefinitionFromBundle(resp, task.id ?? ''),
      }

      return res
    })

    const offeredCPS = await getCPSubscriptionsOfferedByUnit()

    if (offeredCPS && offeredCPS.length > 0) {
      offeredCPS.forEach((offeredCP) => {
        if (offeredCP.name !== undefined) {
          const index = returnResult.findIndex(
            (cpg) => cpg.rawCpg.name === offeredCP.name
          )
          console.log('--------------------index----------------', index)
          console.log(
            '--------------------offeredCP----------------',
            offeredCP.name
          )

          if (index !== -1) {
            returnResult[index].isOffering = true
            returnResult[index].offeredCpgPlanDefinition = offeredCP
            console.log(
              '--------------------returnResult----------------',
              returnResult
            )
          }
        }
      })
    }
  }

  return returnResult
}

export async function offerSubscriptionInUnit(
  mainPartCPGID: string
): Promise<boolean> {
  const fhirClient: EnrolCient = new EnrolCient()
  const unitId = getCurrentUserUnitDetails()?.id
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateEnrolmentFlowRequest(
      `/cpg-management/subscribeToCPG`,
      {
        mainPartitionSubscriptionId: mainPartCPGID,
        unitId,
      }
    )

  const resp: R4.IBundle = response
  if (resp.entry && resp.entry.length === 0) {
    return false
  }

  return true
}

export async function removeOfferSubscriptionInUnit(
  subscribedPlanDefId: string
): Promise<boolean> {
  const fhirClient: EnrolCient = new EnrolCient()
  const unitId = getCurrentUserUnitDetails()?.id
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateEnrolmentFlowRequest(
      `/cpg-management/unsubscribeToCPG?unitId=${unitId}&planId=${subscribedPlanDefId}`,
      {}
    )

  const resp: R4.IBundle = response
  if (resp.entry && resp.entry.length === 0) {
    return false
  }

  return true
}

export async function getCPSubscriptionsOfferedByUnit(): Promise<
  R4.IPlanDefinition[]
> {
  const unitId = getCurrentUserUnitDetails()?.id
  const fhirClient: EnrolCient = new EnrolCient()
  const response: any | FHIRErrorResponses = await fhirClient.doGetResource(
    `/cpg-management/getSubscribedCPGs`,
    {
      unitId,
    }
  )

  const resp: R4.IBundle = response
  if (resp.entry && resp.entry.length === 0) {
    return []
  }

  const taskObject: R4.IPlanDefinition[] = []

  resp.entry?.forEach((entry) => {
    if (entry.resource?.resourceType === 'PlanDefinition') {
      taskObject.push(entry.resource as R4.IPlanDefinition)
    }
  })

  if (taskObject && taskObject.length > 0) {
    return taskObject
  }

  return []
}

export async function getCPGListForWPAdmin(): Promise<CPGForTable[] | boolean> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses = await fhirClient.doGetResource(
    `/PlanDefinition`,
    {
      type: 'clinical-protocol',
      _revinclude: 'ChargeItemDefinition:context-type-reference',
    }
  )

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  const resp: R4.IBundle = response
  if (resp.entry && resp.entry.length === 0) {
    return false
  }
  const taskObject = resp.entry
    ?.filter((entry) => entry.resource?.resourceType === 'PlanDefinition')
    .map((entry) => entry.resource as R4.IPlanDefinition)

  let returnResult: CPGForTable[] = []
  if (taskObject && taskObject.length > 0) {
    returnResult = taskObject.map((task) => {
      const res: CPGForTable = {
        cpgId: task.id ?? '',
        cpgName: task.title ?? '',
        rawCpg: task,
        cpgDescription: task.description ?? '',
        cpgGoal: getGoalDescription(task.goal ?? []),
        offeringPlans: getChargeItemDefinitionFromBundle(resp, task.id ?? ''),
      }

      return res
    })
  }

  return returnResult
}

/// get goal description from CPG in string format
export function getGoalDescription(goal: R4.IPlanDefinition_Goal[]) {
  let goalDescription: string[] = []
  if (goal && goal.length > 0) {
    goalDescription = goal.map((eachGoal) => {
      const goalDesc: string = eachGoal.description
        ? eachGoal.description.text ?? ''
        : ''
      return goalDesc
    })
  }

  return goalDescription.filter((eachGoal) => eachGoal !== '').join(', ')
}

/// get charge item definition for plan definition from bundle
export function getChargeItemDefinitionFromBundle(
  bundle: R4.IBundle,
  inputPlanDefId: string
): R4.IChargeItemDefinition[] {
  const chargeItemDefinitions: R4.IChargeItemDefinition[] = []
  if (bundle.entry && bundle.entry.length > 0) {
    bundle.entry.forEach((entry) => {
      if (
        entry.resource?.resourceType === 'ChargeItemDefinition' &&
        entry.resource?.id
      ) {
        if (
          entry.resource?.useContext &&
          entry.resource?.useContext.length > 0
        ) {
          const planDefUsageContext = entry.resource?.useContext.find(
            (usageContext) => usageContext.code.code === 'program'
          )

          if (
            planDefUsageContext &&
            planDefUsageContext.valueReference &&
            planDefUsageContext.valueReference.reference
          ) {
            const planDefId =
              planDefUsageContext.valueReference.reference.split('/')[1]

            if (planDefId === inputPlanDefId) {
              chargeItemDefinitions.push(
                entry.resource as R4.IChargeItemDefinition
              )
            }
          }
        }
      }
    })
  }
  console.log(
    '--------------------chargeItemDefinitions----------------',
    chargeItemDefinitions
  )
  return chargeItemDefinitions
}

export async function getAvailableDurationForPlanDef(
  planDef: string
): Promise<IChargeItemDefinition[] | boolean> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses = await fhirClient.doGetResource(
    `/ChargeItemDefinition`,
    {
      'context-type-reference': `PlanDefinition/${planDef}`,
    }
  )

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  const resp: R4.IBundle = response
  if (resp.entry && resp.entry.length === 0) {
    return false
  }
  const taskObject = resp.entry
    ?.filter((entry) => entry.resource?.resourceType === 'ChargeItemDefinition')
    .map((entry) => entry.resource as R4.IChargeItemDefinition)

  return taskObject ?? []
}

/// get charge item definition from list based on id
export function getChargeItemDefinitionFromList(
  chargeItemDefinitions: IChargeItemDefinition[],
  id: string
): IChargeItemDefinition | undefined {
  const resIndex = chargeItemDefinitions.findIndex(
    (eachChargeItemDef) => eachChargeItemDef.id === id
  )
  if (resIndex > -1) {
    return chargeItemDefinitions[resIndex]
  }
  return undefined
}

export function isPremiumOffer(offering: CPGForTable) {
  if (offering.cpgName.toLowerCase().includes('premium')) {
    return true
  }
  return false
}

export async function getOfferingCPSubscriptionsOrgAndUnitAdmin(): Promise<
  CPGForTable[] | boolean
> {
  const fhirClient: EnrolCient = new EnrolCient()

  let returnResult: CPGForTable[] = []
  const offeredCPS: IPlanDefinition[] = await getCPSubscriptionsOfferedByUnit()

  if (offeredCPS && offeredCPS.length > 0) {
    returnResult = offeredCPS.map((task) => {
      const res: CPGForTable = {
        cpgId: task.id ?? '',
        cpgName: task.title ?? '',
        rawCpg: task,
        cpgDescription: task.description ?? '',
        cpgGoal: getGoalDescription(task.goal ?? []),
      }

      return res
    })
  }

  return returnResult
}
