import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  ITask,
  TaskIntentKind,
  TaskStatusKind,
  Timing_RepeatDurationUnitKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import moment from 'moment'

import {
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultCodeOfSystemFromCodableConceptList,
  getDefaultDisplayOfSystemFromCodableConcept,
} from 'utils/fhirResourcesHelper'

/// interface for day of week wise time of day based diet plan
export interface DayOfWeekWiseTimeOfDayBasedDietPlan {
  dayOfWeek: string
  timesOfWiseDiets: TimeOfDayWiseDietList[]
}

export interface TimeOfDayWiseDietList {
  timeOfDay: string
  dietItems: R4.ICodeableConcept[]
}

/// get day of week from task resource
export const getDayOfWeek = (task: ITask) => {
  if (task.input && task.input.length > 0) {
    const dayOfWeekInputIndex = task.input.findIndex((input) => {
      const code = getDefaultCodeOfSystemFromCodableConcept(input.type)

      return code === 'C0578574'
    })

    const dayOfWeekInput = task.input[dayOfWeekInputIndex]

    if (
      dayOfWeekInput &&
      dayOfWeekInput.valueTiming &&
      dayOfWeekInput.valueTiming.repeat
    ) {
      return dayOfWeekInput.valueTiming.repeat?.dayOfWeek
    }
  }
  return undefined
}

/// get time of day form task resource input
export const getTimeOfDay = (task: ITask) => {
  const timeOfDayInput = task.input?.find(
    (input) =>
      getDefaultCodeOfSystemFromCodableConcept(input.type) === 'C0578574'
  )

  if (
    timeOfDayInput &&
    timeOfDayInput.valueTiming &&
    timeOfDayInput.valueTiming.repeat
  ) {
    return timeOfDayInput.valueTiming.repeat.timeOfDay
  }
  return undefined
}

/// get diet from task resource input with code 'C1261371'

export const getDiet = (task: ITask) => {
  const dietInput = task.input?.find(
    (input) =>
      getDefaultCodeOfSystemFromCodableConcept(input.type) === 'C1261371'
  )

  if (dietInput && dietInput.valueCodeableConcept) {
    return dietInput.valueCodeableConcept
  }
  return undefined
}

/// get diet from task resource input with code 'C1261371'

export const getMultipleDietInSameTask = (task: ITask) => {
  const dietInput = task.input?.filter(
    (input) =>
      getDefaultCodeOfSystemFromCodableConcept(input.type) === 'C1261371'
  )

  if (dietInput && dietInput.length > 0) {
    return dietInput.map((diet) => diet.valueCodeableConcept)
  }
  return undefined
}

/// get day of week wise time of day  based diet plan from task resource
export const getDayOfWeekWiseTimeOfDayBasedDietPlan = (task: ITask) => {
  const dayOfWeek = getDayOfWeek(task)
  const timeOfDay = getTimeOfDay(task)
  const diet = getDiet(task)
  if (dayOfWeek && timeOfDay && diet) {
    return {
      dayOfWeek,
      timeOfDay,
      diet,
    }
  }
  return undefined
}

/// get week long diet plan from task resources
export function getWeekLongDietPlanFromTasks(
  tasks: ITask[]
): DayOfWeekWiseTimeOfDayBasedDietPlan[] {
  const weekLongDietPlan: DayOfWeekWiseTimeOfDayBasedDietPlan[] = []
  tasks.forEach((task) => {
    const dayOfWeekWiseTimeOfDayBasedDietPlan =
      getDayOfWeekWiseTimeOfDayBasedDietPlan(task)

    if (dayOfWeekWiseTimeOfDayBasedDietPlan) {
      const { dayOfWeek } = dayOfWeekWiseTimeOfDayBasedDietPlan
      const { timeOfDay } = dayOfWeekWiseTimeOfDayBasedDietPlan
      const { diet } = dayOfWeekWiseTimeOfDayBasedDietPlan

      dayOfWeek.forEach((day) => {
        const dayOfWeekWiseTimeOfDayBasedDietPlanIndex =
          weekLongDietPlan.findIndex((dietPlan) => dietPlan.dayOfWeek === day)
        /// if day of week is not present in week long diet plan
        if (dayOfWeekWiseTimeOfDayBasedDietPlanIndex === -1) {
          const currentDaytimeOfDayWiseDietList: TimeOfDayWiseDietList[] = []
          timeOfDay.forEach((time) => {
            currentDaytimeOfDayWiseDietList.push({
              timeOfDay: time,
              dietItems: [diet],
            })
          })

          const currentDayOfWeekDietPlan: DayOfWeekWiseTimeOfDayBasedDietPlan =
            {
              dayOfWeek: day,
              timesOfWiseDiets: currentDaytimeOfDayWiseDietList,
            }
          weekLongDietPlan.push(currentDayOfWeekDietPlan)
        } else {
          /// if day of week is present in week long diet plan
          const currentDayOfWeekDietPlan =
            weekLongDietPlan[dayOfWeekWiseTimeOfDayBasedDietPlanIndex]

          timeOfDay.forEach((time) => {
            const timeOfDayWiseDietListIndex =
              currentDayOfWeekDietPlan.timesOfWiseDiets.findIndex(
                (timeOfDayWiseDietList) =>
                  timeOfDayWiseDietList.timeOfDay === time
              )
            /// if time of day is not present in current day of week diet plan
            if (timeOfDayWiseDietListIndex === -1) {
              currentDayOfWeekDietPlan.timesOfWiseDiets.push({
                timeOfDay: time,
                dietItems: [diet],
              })
            } else {
              /// if time of day is present in current day of week diet plan
              currentDayOfWeekDietPlan.timesOfWiseDiets[
                timeOfDayWiseDietListIndex
              ].dietItems.push(diet)
            }
          })
        }
      })
    }
  })
  console.log('------weekLongDietPlan-------', weekLongDietPlan)

  return weekLongDietPlan
}

/// get distinct time of day  from week long diet plans. sort by time of day
export function getDistinctTimeOfDayFromWeekLongDietPlans(
  weekLongDietPlans: DayOfWeekWiseTimeOfDayBasedDietPlan[]
) {
  const distinctTimeOfDay: string[] = []
  weekLongDietPlans.forEach((dietPlan) => {
    dietPlan.timesOfWiseDiets.forEach((timeOfDayWiseDietList) => {
      const { timeOfDay } = timeOfDayWiseDietList
      if (!distinctTimeOfDay.includes(timeOfDay)) {
        distinctTimeOfDay.push(timeOfDay)
      }
    })
  })
  distinctTimeOfDay.sort()
  return distinctTimeOfDay
}

/// get table data for week long diet plan
export function getTableDataForWeekLongDietPlan(
  weekLongDietPlans: DayOfWeekWiseTimeOfDayBasedDietPlan[]
) {
  const tableHeader: string[] = ['Day of Week']
  const distinctTimeOfDay =
    getDistinctTimeOfDayFromWeekLongDietPlans(weekLongDietPlans)
  distinctTimeOfDay.forEach((timeOfDay) => {
    tableHeader.push(timeOfDay)
  })
  const tableData: string[][] = []
  weekLongDietPlans.forEach((dietPlan) => {
    const { dayOfWeek } = dietPlan
    const row: string[] = [dayOfWeek]
    distinctTimeOfDay.forEach((timeOfDay) => {
      const timeOfDayWiseDietListIndex = dietPlan.timesOfWiseDiets.findIndex(
        (timeOfDayWiseDietList) => timeOfDayWiseDietList.timeOfDay === timeOfDay
      )
      if (timeOfDayWiseDietListIndex === -1) {
        row.push('')
      } else {
        const { dietItems } =
          dietPlan.timesOfWiseDiets[timeOfDayWiseDietListIndex]
        const dietItemsString = dietItems
          .map(
            (dietItem) =>
              dietItem.text ??
              getDefaultDisplayOfSystemFromCodableConcept(dietItem)
          )
          .join(', ')
        row.push(dietItemsString)
      }
    })
    tableData.push(row)
  })

  return { tableHeader, tableBody: sortByDayOfWeek(tableData) }
}

/// get display text of time of day from time
export function getDisplayTextOfTimeOfDayFromCode(code: string) {
  /* switch (code) {
    case '06:30':
    case '06:30:00':
      return 'Usha Pana'
    case '08:00':
    case '08:00:00':
      return 'Breakfast'
    case '10:30':
    case '10:30:00':
      return 'Morning Snack'
    case '13:00':
    case '13:00:00':
      return 'Lunch'
    case '17:00':
    case '17:00:00':
      return 'Evening Snack'
    case '19:30':
    case '19:30:00':
      return 'Dinner'
    default:
      return code
  } */
  return moment(`2015-01-16T${code}`).format('h:mm A')
}

/// get day full name from day code
export function getDayFullNameFromDayCode(code: string) {
  switch (code) {
    case 'mon':
      return 'Monday'
    case 'tue':
      return 'Tuesday'
    case 'wed':
      return 'Wednesday'
    case 'thu':
      return 'Thursday'
    case 'fri':
      return 'Friday'
    case 'sat':
      return 'Saturday'
    case 'sun':
      return 'Sunday'
    default:
      return ''
  }
}

/// get task resources from week long diet plans and patient with encounter id
export function getTaskResourcesFromWeekLongDietPlans(
  weekLongDietPlans: DayOfWeekWiseTimeOfDayBasedDietPlan[],
  patient: R4.IPatient,
  encounterId: string
) {
  const taskResources: ITask[] = []

  weekLongDietPlans.forEach((dietPlan) => {
    const { dayOfWeek } = dietPlan
    const { timesOfWiseDiets } = dietPlan
    timesOfWiseDiets.forEach((timeOfDayWiseDietList) => {
      const { timeOfDay } = timeOfDayWiseDietList
      const { dietItems } = timeOfDayWiseDietList
      const itemsTaskInput: R4.ITask_Input[] = dietItems.map((dietItem) => {
        const input: R4.ITask_Input = {
          type: {
            coding: [
              {
                system: 'http://terminology.hl7.org/CodeSystem/umls',
                code: 'C1261371',
                display: 'Dietary Intake Prescribed',
              },
            ],
          },
          valueCodeableConcept: dietItem,
        }
        return input
      })

      const task: ITask = {
        resourceType: 'Task',
        status: TaskStatusKind._draft,
        intent: TaskIntentKind._proposal,
        code: {
          coding: [
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0012159',
              display: 'Diet Therapy',
            },
          ],
        },
        focus: {
          reference: `Patient/${patient.id}`,
        },
        for: {
          reference: `Encounter/${encounterId}`,
        },
        authoredOn: new Date().toISOString(),
        input: [
          {
            type: {
              coding: [
                {
                  system: 'http://terminology.hl7.org/CodeSystem/umls',
                  code: 'C0578574',
                },
              ],
            },
            valueTiming: {
              repeat: {
                dayOfWeek: [dayOfWeek],
                timeOfDay: [`${timeOfDay}`],
                duration: 1,
                durationUnit: Timing_RepeatDurationUnitKind._wk,
              },
            },
          },
          ...itemsTaskInput,
        ],
      }
      taskResources.push(task)
    })
  })
  return taskResources
}

/// get diet tasks form bundle
export function getWeeklyDietPlanTaskFromBundle(bundle: R4.IBundle) {
  const dietPlanList: R4.ITask[] = []
  bundle.entry?.forEach((entry) => {
    if (entry.resource?.resourceType === 'Task') {
      const task: R4.ITask = entry.resource as R4.ITask
      dietPlanList.push(task)
    }
  })
  return dietPlanList
}

/// get latest encounter id from task list based on task authored date
export function getLatestEncounterIdFromTaskList(tasks: R4.ITask[]) {
  let latestEncounterId = ''

  /// tasks descening sort by authored date
  tasks.sort((a, b) => {
    const dateA = new Date(a.authoredOn ?? '')
    const dateB = new Date(b.authoredOn ?? '')
    return dateB.getTime() - dateA.getTime()
  })

  /// encounter id of first task
  const firstTask = tasks[0]
  if (firstTask.encounter?.reference) {
    latestEncounterId = firstTask.encounter?.reference?.split('/')[1] ?? ''
  }

  return latestEncounterId
}

/// get tasks of same encounter id from task list
export function getTasksOfSameEncounterId(
  tasks: R4.ITask[],
  encounterId: string
) {
  const tasksOfSameEncounterId: R4.ITask[] = []
  tasks.forEach((task) => {
    if (task.encounter?.reference) {
      const taskEncounterId = task.encounter?.reference?.split('/')[1] ?? ''
      if (taskEncounterId === encounterId) {
        tasksOfSameEncounterId.push(task)
      }
    }
  })
  return tasksOfSameEncounterId
}

/// get day of week wise time of day  based diet plan from task resource
export function getDayOfWeekWiseTimeOfDayBasedDietPlanWithMultipleDetails(
  task: ITask
):
  | {
      dayOfWeek: string[]
      timeOfDay: string[]
      diet: (R4.ICodeableConcept | undefined)[]
    }
  | undefined {
  const dayOfWeek = getDayOfWeek(task)
  const timeOfDay = getTimeOfDay(task)
  const diet = getMultipleDietInSameTask(task)
  if (dayOfWeek && timeOfDay && diet) {
    return {
      dayOfWeek,
      timeOfDay,
      diet,
    }
  }
  return undefined
}

/// get week long diet plan from task resources
export function getWeekLongDietPlanFromTasksWithMultipleDiets(
  tasks: ITask[]
): DayOfWeekWiseTimeOfDayBasedDietPlan[] {
  const weekLongDietPlan: DayOfWeekWiseTimeOfDayBasedDietPlan[] = []
  tasks.forEach((task) => {
    const dayOfWeekWiseTimeOfDayBasedDietPlan =
      getDayOfWeekWiseTimeOfDayBasedDietPlanWithMultipleDetails(task)

    if (dayOfWeekWiseTimeOfDayBasedDietPlan) {
      const { dayOfWeek } = dayOfWeekWiseTimeOfDayBasedDietPlan
      const { timeOfDay } = dayOfWeekWiseTimeOfDayBasedDietPlan
      const dietList = dayOfWeekWiseTimeOfDayBasedDietPlan.diet

      const diet: R4.ICodeableConcept[] = []
      dietList.forEach((dietItem) => {
        if (dietItem) {
          diet.push(dietItem)
        }
      })

      dayOfWeek.forEach((day) => {
        const dayOfWeekWiseTimeOfDayBasedDietPlanIndex =
          weekLongDietPlan.findIndex((dietPlan) => dietPlan.dayOfWeek === day)
        /// if day of week is not present in week long diet plan
        if (dayOfWeekWiseTimeOfDayBasedDietPlanIndex === -1) {
          const currentDaytimeOfDayWiseDietList: TimeOfDayWiseDietList[] = []
          timeOfDay.forEach((time) => {
            currentDaytimeOfDayWiseDietList.push({
              timeOfDay: time,
              dietItems: [...(diet ?? [])],
            })
          })

          const currentDayOfWeekDietPlan: DayOfWeekWiseTimeOfDayBasedDietPlan =
            {
              dayOfWeek: day,
              timesOfWiseDiets: currentDaytimeOfDayWiseDietList,
            }
          weekLongDietPlan.push(currentDayOfWeekDietPlan)
        } else {
          /// if day of week is present in week long diet plan
          const currentDayOfWeekDietPlan =
            weekLongDietPlan[dayOfWeekWiseTimeOfDayBasedDietPlanIndex]

          timeOfDay.forEach((time) => {
            const timeOfDayWiseDietListIndex =
              currentDayOfWeekDietPlan.timesOfWiseDiets.findIndex(
                (timeOfDayWiseDietList) =>
                  timeOfDayWiseDietList.timeOfDay === time
              )
            /// if time of day is not present in current day of week diet plan
            if (timeOfDayWiseDietListIndex === -1) {
              currentDayOfWeekDietPlan.timesOfWiseDiets.push({
                timeOfDay: time,
                dietItems: [...diet],
              })
            } else {
              /// if time of day is present in current day of week diet plan
              diet.forEach((dietItem) => {
                currentDayOfWeekDietPlan.timesOfWiseDiets[
                  timeOfDayWiseDietListIndex
                ].dietItems.push(dietItem)
              })
            }
          })
        }
      })
    }
  })
  console.log('------weekLongDietPlan-------', weekLongDietPlan)

  return weekLongDietPlan
}

/// get end date from task. efefctive period end date

export function getEndDate(task: R4.ITask[]) {
  if (task.length === 0) return undefined
  const latestTask = task[0]
  return latestTask.executionPeriod?.end
}

export function getStartDate(task: R4.ITask[]) {
  if (task.length === 0) return undefined
  const latestTask = task[0]
  return latestTask.executionPeriod?.start
}

export function getCreatedDate(task: R4.ITask[]) {
  if (task.length === 0) return undefined
  const latestTask = task[0]
  return latestTask.authoredOn
}

export function sortByDayOfWeek(list: string[][]) {
  const sorter: any = {
    // "sunday": 0, // << if sunday is first day of week
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
    sun: 7,
  }
  const sortedList = list.sort((a, b) => {
    const day1 = a[0].toLowerCase()
    const day2 = b[0].toLowerCase()
    return sorter[day1] - sorter[day2]
  })
  return sortedList
}
