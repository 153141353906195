import {
  Box,
  Button,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControl,
} from '@material-ui/core'
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@material-ui/icons'
import Checkbox from '@mui/material/Checkbox'
import { Filters } from 'models/filters'
import React, { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { WelloFormItemLabel } from 'wello-web-components'

interface Props {
  name: string
  filterOptions: Filters[]
  onSelectionChanged?: (selectedValues: string[]) => void
  preSelectedFilters?: string[]
}
export const CohortFilters: React.FC<Props> = ({
  name,
  filterOptions,
  onSelectionChanged,
  preSelectedFilters,
}: Props) => {
  const { t } = useTranslation()

  const [selectedIds, setSelectedIds] = useState<string[]>(
    preSelectedFilters ?? ['']
  )
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)

  function handleChecked(id: string) {
    if (id === 'any') {
      if (selectedIds.length === 2) {
        updateDetails([])
      } else {
        updateDetails([...filterOptions.map((e) => e.id)])
      }
    } else if (selectedIds.includes(id)) {
      const tempIds: string[] = selectedIds.filter((item) => item !== id)

      if (tempIds.length > 0) {
        updateDetails(tempIds.filter((item) => item !== 'any'))
      }
    } else {
      const tempIds: string[] = [...selectedIds]

      tempIds.push(id)
      updateDetails(tempIds)
    }
  }

  function updateDetails(selection: string[]) {
    setSelectedIds(selection)
    if (selection.length > 0) {
      if (onSelectionChanged) {
        onSelectionChanged(selection.filter((item) => item !== 'any'))
      }
    }
  }

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
          <Button
            onClick={() => {
              // Todo
            }}
          >
            Try again
          </Button>
        </div>
      )}
    >
      <Box display='flex' flexDirection='column'>
        <Box
          display='flex'
          flexDirection='row'
          px={1}
          justifyContent='space-between'
          alignContent='center'
          alignItems='center'
        >
          <WelloFormItemLabel title={name} />
          <IconButton
            aria-label='collapse_order_type'
            size='small'
            onClick={() => {
              setIsCollapsed(!isCollapsed)
            }}
          >
            {isCollapsed && <ArrowDropUpOutlined />}
            {!isCollapsed && <ArrowDropDownOutlined />}
          </IconButton>
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          style={{
            width: '100%',
          }}
        >
          <Collapse
            in={isCollapsed}
            style={{
              width: '100%',
            }}
          >
            <List
              style={{
                width: '100%',
                marginTop: 0,
                marginBottom: 0,
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              {filterOptions.map((value) => {
                const labelId = `checkbox-list-label-${value.id}`

                return (
                  <ListItem
                    key={value.id}
                    role={undefined}
                    dense
                    style={{
                      width: '100%',
                      marginTop: 0,
                      marginBottom: 0,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                    button
                    onClick={() => {
                      handleChecked(value.id)
                    }}
                  >
                    <ListItemIcon>
                      {value.color.length > 0 && (
                        <FormControl>
                          <Checkbox
                            edge='start'
                            size='small'
                            checked={selectedIds.indexOf(value.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            style={{
                              color: value.color,
                            }}
                          />
                        </FormControl>
                      )}

                      {value.color.length === 0 && (
                        <FormControl>
                          <Checkbox
                            edge='start'
                            size='small'
                            checked={selectedIds.indexOf(value.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            color='primary'
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </FormControl>
                      )}
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={value.name}
                      primaryTypographyProps={{
                        variant: 'body1',
                        color:
                          selectedIds.indexOf(value.id) !== -1
                            ? 'primary'
                            : undefined,
                      }}
                    />
                  </ListItem>
                )
              })}
            </List>
          </Collapse>
        </Box>
      </Box>
    </ErrorBoundary>
  )
}
