/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable consistent-return */
import { Box, Typography } from '@material-ui/core'

import {
  CohortChartProps,
  CohortDataStage,
  CohortPathPair,
  MapDataProps,
} from 'models/chartProps'
import { SankeyData } from 'models/sankeyData'
import { SankeyLink } from 'models/sankeyLinks'
import { SankeyNode } from 'models/sankeyNode'
import React, { useEffect, useState, Suspense } from 'react'
import { buildCohortMap, filterByStageMap } from 'utils/cohort/cohortUtils'
import '../../../../../App.css'
import { CohortControls } from './cohortControls'
import { CustomControls } from './customControls'
import { ErrorMessage } from './errorMessage'
import { SankeyChart } from './shankeyChart'

type IncludeProps = {
  personCount: number
  source: string
  target: string
  min: number
  allFilters: string[]
  codeCohortMap: any
}

export const CohortChartReportDiet: React.FC<CohortChartProps> = ({
  data,
  title,
  width,
  height,
  min,
  max,
  baseMin,
  colorSet,
  nodeAlign,
  nodeWidth,
  showMinCount,
  activeFilter,
  filter,
  accessibleTooltip,
  visibleStages,
  filters,
  exclusiveFilter,
  customControls,
}: CohortChartProps) => {
  const chartData: any = data
  const [chartDataFinal, setChartDataFinal] = useState<SankeyData>({
    nodes: [],
    links: [],
  })
  const [minData, setMinData] = useState<number>(min)

  const [selected, setSelected] = useState<any>(filter || {})
  const [multiFilter, setMultiFilter] = useState<string[]>(filters ?? [])
  const [visibleStagesFilter, setVisibleStagesFilter] = useState<string[]>(
    visibleStages ?? []
  )

  const initialExclusiveFilter =
    exclusiveFilter === true ? true : exclusiveFilter === undefined

  const [inclusiveFilterData, setInclusiveFilterData] = useState<boolean>(
    exclusiveFilter || initialExclusiveFilter
  )

  const [filterMap, setFilterMap] = useState<object>({})
  //   const [inclusiveFilterData, setInclusiveFilterData] = useState<boolean>(
  //     exclusiveFilter || true
  //   )

  const [cohortCodeMap, setCohortCodeMap] = useState<object>({})
  const [fontSize, setFontSize] = useState<number>(12)

  const findCommonItems = (arr1: string[], arr2: string[]): string[] => {
    const set1: any = new Set(arr1)
    const set2 = new Set(arr2)
    const commonItems: string[] = [...set1].filter((item) => set2.has(item))
    return commonItems
  }

  const hasMatches = (arr1: string[], arr2: string[]): boolean => {
    const matches: string[] = findCommonItems(arr1, arr2)
    return matches.length > 0
  }

  const shouldInclude = (props: IncludeProps): boolean => {
    if (props.personCount >= props.min) {
      const match1 = hasMatches(
        props.codeCohortMap[props.source],
        props.allFilters
      )
      const match2 = hasMatches(
        props.codeCohortMap[props.target],
        props.allFilters
      )
      // console.debug("compare",
      //   (match1.length > 0), (match2.length > 0));
      if (!match1 && !match2) {
        // if (props.exclusiveFilter) {
        return true
        // } else {
        // return false;
        // }
      }
      // if (props.exclusiveFilter) {
      return false
      // } else {
      // return true;
      // }
    }
    return false
  }

  const mapData = (props: MapDataProps) => {
    // transform
    const d: SankeyData = { nodes: [], links: [] }
    const personMap: any = {}
    const totalMap: any = {}
    const path_codes: any = {}
    const path_pairs: CohortPathPair[] = []
    const stages: any = props.chartData.stages
    const keys: string[] = Object.keys(stages)
    const codeCohortMap: any = {}
    const fullCohortList: string[] = []
    let index: number = 0
    let i: number = 0
    let ii: number = 0
    let ll: number = 0
    let l: number = keys.length
    let key: string
    let stage: any

    // Building of the data in a easier structure
    // console.debug("filters to map", props.filters);

    index = 0
    let s: CohortDataStage
    for (i = 0; i < l; i++) {
      key = keys[i]
      ll = stages[key].length
      stage = stages[key]
      for (ii = 0; ii < ll; ii++) {
        s = stage[ii]
        if (s) {
          path_codes[s.code] = {
            cohorts: s.cohorts,
            index,
          }
          codeCohortMap[s.code] = s.cohorts
          index++

          // add to all if inclusive filter
          if (!props.inclusiveFilter) {
            let f = 0
            for (f = 0; f < s.cohorts.length; f++) {
              if (fullCohortList.indexOf(s.cohorts[f]) === -1) {
                fullCohortList.push(s.cohorts[f])
              }
            }
          }
        }
      }
    }

    // console.debug("fullCohortList", fullCohortList);
    // console.debug("codeCohortMap", codeCohortMap);

    // this is where create filters
    let fi: number = 0
    let filterCat: string[]
    const filterKeys = Object.keys(props.filters)
    const allFilters: string[] = []

    for (fi = 0; fi < filterKeys.length; fi++) {
      filterCat = props.filters[filterKeys[fi]]
      allFilters.push(...filterCat)
    }

    // only diff if inclusive filter
    let difference: string[] = []
    if (!props.inclusiveFilter) {
      difference = fullCohortList.filter((item) => !allFilters.includes(item))
      // console.debug("allfilters diff", difference);
    }
    // console.debug("allfilters", allFilters);
    // console.debug("props", props);

    const pathways = props.chartData.pathway_groups[0].pathways

    // console.debug("Filters considered", allFilters);
    // console.debug("All pathways", pathways);

    l = pathways.length
    let pathway: any = null
    let pathLinks: CohortPathPair[] = []
    let include: boolean = true

    for (i = 0; i < l; i++) {
      pathway = pathways[i]
      ll = pathway.path.length
      ;(pathLinks = []), (include = true)

      for (ii = 0; ii < ll; ii++) {
        const entry: CohortPathPair = {
          source: pathway.path[ii],
          target: pathway.path[ii + 1],
          personCount: pathway.personCount,
        }
        const includeProps: IncludeProps = {
          source: entry.source,
          target: entry.target,
          personCount: entry.personCount,
          min: props.min,
          allFilters: props.inclusiveFilter ? allFilters : difference,
          codeCohortMap,
        }

        if (include && shouldInclude(includeProps)) {
          if (ii + 1 < ll) {
            pathLinks.push(entry)
          }
          if (totalMap[pathway.path[ii]]) {
            totalMap[pathway.path[ii]] += pathway.personCount
              ? Number(pathway.personCount)
              : 0
          } else {
            totalMap[pathway.path[ii]] = pathway.personCount
              ? Number(pathway.personCount)
              : 0
          }
        } else {
          include = false
        }
      }
      if (include) {
        path_pairs.push(...pathLinks)
      }
    }
    // console.debug("totalMap", totalMap);

    // reset index
    index = 0
    path_pairs.map((entry: CohortPathPair, ikey: number) => {
      d.links.push({
        source: entry.source,
        target: entry.target,
        value: entry.personCount,
        index: ikey,
      } as SankeyLink)
      personMap[entry.source] = entry.personCount
    })

    Object.keys(path_codes).map((key1: any) => {
      const cohorts: [] = path_codes[key1].cohorts
      const node: SankeyNode = {
        id: key1,
        name: String(cohorts),
        value: personMap[key1] ? personMap[key1] : -1,
        index,
        tooltip: totalMap[key1]
          ? `${totalMap[key1]} : ${cohorts.toString()}`
          : ':',
      }
      d.nodes.push(node)
      index++
    })

    setChartDataFinal(d)
  }

  // start up
  useEffect(() => {
    const filtersData: any = {}
    const keys: string[] = Object.keys(chartData.stages)
    keys.map((key: any) => {
      filtersData[key] = selected[key] ? selected[key] : []
    })
    const props: MapDataProps = {
      chartData,
      min: minData,
      setData: data,
      visibleStages: visibleStagesFilter,
      filters: filtersData,
      inclusiveFilter: inclusiveFilterData,
      cohortCodeMap,
    }
    buildCohortMap({
      visibleStages: visibleStagesFilter,
      stages: chartData.stages,
      selected: filtersData,
    })
      .then((map: any) => {
        setCohortCodeMap(map.cohortCodeMap)
        setFilterMap(map.cohortStageMap)
        setSelected(filtersData)
      })
      .then(() => {
        mapData(props)
      })
  }, [])

  useEffect(() => {
    const props: MapDataProps = {
      chartData,
      min: minData,
      setData: data,
      visibleStages: visibleStagesFilter,
      filters: selected,
      inclusiveFilter: inclusiveFilterData,
      cohortCodeMap,
    }

    mapData(props)
  }, [minData, inclusiveFilterData])

  useEffect(() => {
    buildCohortMap({
      visibleStages: visibleStagesFilter,
      stages: chartData.stages,
      selected,
    })
      .then((map: any) => {
        setFilterMap(map.cohortStageMap)
        setCohortCodeMap(map.cohortCodeMap)
      })
      .then(() => {
        const props: MapDataProps = {
          chartData,
          min: minData,
          setData: data,
          visibleStages: visibleStagesFilter,
          filters: visibleStagesFilter.length === 0 ? {} : selected,
          inclusiveFilter: inclusiveFilterData,
          cohortCodeMap,
        }
        mapData(props)
      })
  }, [visibleStagesFilter])

  //   const handleSetMin = (event: any, newValue: number | number[]) => {
  //     setChartDataFinal({
  //       nodes: [],
  //       links: [],
  //     })
  //     setMinData(newValue as number)
  //     mapData(filterVal)
  //   }

  const handleSetMinCustom = (e: any, newValue: number | number[]) => {
    const m: number = newValue as number
    setMinData(m)
  }

  const handleSetMin = (e: any) => {
    const m: number = e.target.value
    setMinData(m)
  }

  const handleStageChange = (e: any) => {
    const options: any[] = e.target.options
    const value: string[] = []
    const l = options.length
    let i = 0
    for (i = 0; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value)
      }
    }
    setVisibleStagesFilter(value)
  }

  const handleStageChangeCustom = (e: string[]) => {
    setVisibleStagesFilter(e)
  }

  const handleStageFilterChange = (e: any) => {
    const options: any[] = e.target.options
    const value: string[] = []
    const l = options.length

    let i = 0
    for (i = 0; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value)
      }
    }

    const change: any = {}
    change[e.target.name] = value

    const filtersData = Object.assign(selected, change)
    setSelected(filtersData)

    const propsData: MapDataProps = {
      chartData,
      min: minData,
      setData: data,
      visibleStages: visibleStagesFilter,
      filters: selected,
      inclusiveFilter: inclusiveFilterData,
      cohortCodeMap,
    }
    mapData(propsData)
  }

  const handleFontIncrease = (e: any) => {
    if (fontSize < 20) {
      setFontSize(fontSize + 1)
    }
  }

  const handleFontDecrease = (e: any) => {
    if (fontSize > 9) {
      setFontSize(fontSize - 1)
    }
  }

  const handleStageFilterChangeForCustom = (dataVal: any, e: any) => {
    const value: string[] = []
    for (let i = 0; i < dataVal.length; i++) {
      value.push(dataVal[i].value)
    }

    const change: any = {}
    change[e] = value

    const filtersData = Object.assign(selected, change)
    setSelected(filtersData)

    const propsData: MapDataProps = {
      chartData,
      min: minData,
      setData: data,
      visibleStages: visibleStagesFilter,
      filters: selected,
      inclusiveFilter: inclusiveFilterData,
      cohortCodeMap,
    }
    mapData(propsData)
  }

  const handleInclusiveFilter = (e: any) => {
    const i: boolean = !inclusiveFilterData
    setInclusiveFilterData(i)
    // const props: MapDataProps = {
    //   chartData,
    //   min: minData,
    //   setData: data,
    //   visibleStages: visibleStagesFilter,
    //   filters: selected,
    //   inclusiveFilter: i,
    //   cohortCodeMap,
    // }
    // mapData(props)
  }

  const Loading = () => <div className='loading'>Loading...</div>

  return (
    <div className='cohort'>
      {customControls === false && (
        <Box flexDirection='column'>
          {title ? <h1>{title}</h1> : ''}
          {showMinCount ? <h2>(Min count: {minData})</h2> : ''}
        </Box>
      )}

      {customControls && (
        <Box flexDirection='column'>
          {title ? <Typography variant='subtitle1'>{title}</Typography> : ''}
          {showMinCount ? (
            <Typography variant='subtitle2'>(Min count: {minData})</Typography>
          ) : (
            ''
          )}
        </Box>
      )}

      {activeFilter &&
      chartDataFinal &&
      chartDataFinal.nodes.length > 0 &&
      customControls === false ? (
        <Box paddingTop={1} display='flex' flexDirection='column'>
          <CohortControls
            max={max}
            min={minData}
            baseMin={baseMin || 0}
            handleSetMin={handleSetMin}
            handleStageChange={handleStageChange}
            visibleStages={visibleStagesFilter}
            stages={chartData.stages}
            handleStageFilterChange={handleStageFilterChange}
            filterMap={filterMap}
            filters={selected}
            handleInclusiveFilter={handleInclusiveFilter}
            inclusiveFilter={inclusiveFilterData}
            handleFontDecrease={handleFontDecrease}
            handleFontIncrease={handleFontIncrease}
          />
        </Box>
      ) : (
        ''
      )}

      {customControls && chartDataFinal && chartDataFinal.nodes.length > 0 ? (
        <Suspense fallback={Loading}>
          <CustomControls
            max={max}
            min={minData}
            baseMin={baseMin || 0}
            handleSetMin={handleSetMinCustom}
            handleStageChange={handleStageChangeCustom}
            visibleStages={visibleStagesFilter}
            stages={chartData.stages}
            handleStageFilterChange={handleStageFilterChangeForCustom}
            filterMap={filterMap}
            filters={selected}
            handleInclusiveFilter={handleInclusiveFilter}
            inclusiveFilter={inclusiveFilterData}
            handleFontDecrease={handleFontDecrease}
            handleFontIncrease={handleFontIncrease}
          />
        </Suspense>
      ) : (
        ''
      )}
      <br />
      {chartDataFinal && chartDataFinal.nodes.length > 0 ? (
        <SankeyChart
          colorSet={colorSet}
          data={chartDataFinal}
          width={width}
          height={height}
          nodeAlign={nodeAlign}
          nodeWidth={nodeWidth}
          accessibleTooltip={accessibleTooltip}
          fontSize={fontSize}
        />
      ) : (
        <ErrorMessage message='' svg={false} />
      )}
    </div>
  )
}
