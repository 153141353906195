import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { getExpandedAppointmentFromBundle } from 'utils/common/patientDataTableHelper'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import { UmlClient } from 'services/umlsClient'
import {
  getCurrentUserPractitionerDetails,
  getUserCurrentRole,
} from 'services/userDetailsService'
import { ChiefComplaintSearchStatus } from './chiefComplaintStatusTypes'

const initialState: ChiefComplaintSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const chiefComplaintSeachSlice = createSlice({
  name: 'chiefComplaint',
  initialState,
  reducers: {
    searchingChiefComplaints(
      state,
      action: PayloadAction<ChiefComplaintSearchStatus>
    ) {},

    searchResults(state, action: PayloadAction<ChiefComplaintSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.chiefComplaintList = action.payload.chiefComplaintList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<ChiefComplaintSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.chiefComplaintList = action.payload.chiefComplaintList
    },

    errorWhileSearching(
      state,
      action: PayloadAction<ChiefComplaintSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.chiefComplaintList = action.payload.chiefComplaintList
    },
    resetState(state, action: PayloadAction<ChiefComplaintSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.chiefComplaintList = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: ChiefComplaintSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      chiefComplaintList: undefined,
    }
    dispatch(chiefComplaintSeachSlice.actions.resetState(state))
  }

export const searchChiefComplaints =
  (searchString: string, selectOther: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: ChiefComplaintSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      chiefComplaintSeachSlice.actions.errorWhileSearching(errorSearchPatient)
    )
    try {
      const fhirClient: UmlClient = new UmlClient()
      const currentRole = getUserCurrentRole() ?? []

      let response: any = await fhirClient.doGetResource(
        `umls/SymptomsMatcher?Path=symptoms&Match=${searchString}`
      )
      if (response.length > 0) {
        const searchPatientResult: ChiefComplaintSearchStatus = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          chiefComplaintList: response,
          totalCount: response.total,
        }
        dispatch(
          chiefComplaintSeachSlice.actions.searchResults(searchPatientResult)
        )
        return
      }

      if (response.length === 0 && currentRole.includes('ayurveda')) {
        response = await fhirClient.doGetResource(
          `/umls/AyurvedaMatcher?Path=chiefComplaints&Match=${searchString}`
        )

        const searchPatientResultData: ChiefComplaintSearchStatus = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          chiefComplaintList: response,
          totalCount: response.total,
        }
        dispatch(
          chiefComplaintSeachSlice.actions.searchResults(
            searchPatientResultData
          )
        )
        return
      }

      const noSearchResults: ChiefComplaintSearchStatus = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(
        chiefComplaintSeachSlice.actions.noDataFoundForSearch(noSearchResults)
      )

      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: ChiefComplaintSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        chiefComplaintSeachSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(chiefComplaintSeachSlice.actions.resetState(initialState))
}

export default chiefComplaintSeachSlice.reducer
