import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from 'redux/store'
import { AlertActionType, AlertType } from './alertActionTypes'

const initialState: AlertActionType = {
  alertMessage: '',
  showAlert: false,
  alertType: AlertType.None,
}

const alertSlice = createSlice({
  name: 'alertSlice',
  initialState,
  reducers: {
    showAlert(state, action: PayloadAction<AlertActionType>) {
      state.showAlert = true
      state.alertMessage = action.payload.alertMessage
      state.alertType = action.payload.alertType
      state.alertActionLink = action.payload.alertActionLink
      state.alertTitle = action.payload.alertTitle
      state.disableAutoHide = action.payload.disableAutoHide
    },
    clearAlert(state, action: PayloadAction<AlertActionType>) {
      state.showAlert = false
      state.alertMessage = ''
      state.alertType = AlertType.None
      state.alertActionLink = ''
      state.alertTitle = action.payload.alertTitle
      state.disableAutoHide = action.payload.disableAutoHide
    },
  },
})

export const showSuccessAlert =
  (alertMessage: string, link?: string) => (dispatch: AppDispatch) => {
    const alertType: AlertActionType = {
      alertMessage,
      alertType: AlertType.SuccessAlert,
      showAlert: true,
      alertActionLink: link ?? '',
    }
    dispatch(alertSlice.actions.showAlert(alertType))
  }

export const showErrorAlert =
  (
    alertMessage: string,
    link?: string,
    alertTitle?: string,
    disableAutoHide?: boolean
  ) =>
  (dispatch: AppDispatch) => {
    const alertType: AlertActionType = {
      alertMessage,
      alertType: AlertType.ErrorAlert,
      showAlert: true,
      alertActionLink: link ?? '',
      alertTitle,
      disableAutoHide,
    }
    dispatch(alertSlice.actions.showAlert(alertType))
  }
export const showWarningAlert =
  (alertMessage: string, link?: string) => (dispatch: AppDispatch) => {
    const alertType: AlertActionType = {
      alertMessage,
      alertType: AlertType.WarningAlert,
      showAlert: true,
      alertActionLink: link ?? '',
    }
    dispatch(alertSlice.actions.showAlert(alertType))
  }
export const clearAlert = () => (dispatch: AppDispatch) => {
  const alertType: AlertActionType = {
    alertMessage: '',
    alertType: AlertType.SuccessAlert,
    showAlert: false,
    clearAlert: true,
  }
  dispatch(alertSlice.actions.clearAlert(alertType))
}
export default alertSlice.reducer
