import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { WelloMedication } from 'models/welloMedication'
import React, { useState } from 'react'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getTimeOfDayDisplayForStatement,
  getTimeOfDayDisplayMeal,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'

interface MedicationTileProps {
  medication: WelloMedication[]
  onDeleteClicked?: () => {}
  onEditCLicked?: () => {}
}

export const StatementMedicationTableTileForHistory: React.FC<MedicationTileProps> =
  ({ medication, onDeleteClicked, onEditCLicked }: MedicationTileProps) => {
    const [openIngredient, setOpenIngredient] = useState(false)
    const [selectedMedication, setSelectedMedication] = useState<R4.ICoding>()
    const [selectedRxNorm, setSelectedRxNorm] = useState<string>()

    const loggedInUnitType =
      getCodeOfSystemFromCodableConceptList(
        getCurrentUserUnitDetails().type ?? [],
        'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
      )?.display ?? ''

    return (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='flex-start'
        width='100%'
        border={4}
        style={{
          border: '2px solid rgba(0, 0, 0, 0.12)',

          width: '100%',
          height: 'auto',
        }}
      >
        <Grid
          container
          direction='column'
          spacing={1}
          style={{ margin: '0px', width: '100%' }}
        >
          <Grid
            container
            direction='row'
            id='vitals'
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    minHeight={30}
                    maxHeight={100}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      Medication
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' width='2%' paddingTop={0.2}>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      minHeight: '30px',
                      maxHeight: '100px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    minHeight={30}
                    maxHeight={100}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      Dose
                    </Typography>
                  </Box>

                  {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                </Box>
                <Box display='flex' width='2%' paddingTop={0.2}>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      minHeight: '30px',
                      maxHeight: '100px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    minHeight={30}
                    maxHeight={100}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      Route of Administration
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' width='2%' paddingTop={0.2}>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      minHeight: '30px',
                      maxHeight: '100px',

                      width: '2px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow
                    minHeight={30}
                    maxHeight={100}
                    alignItems='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle2'
                      // color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        color: 'black',

                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                    >
                      Time of Day
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* ------------------------------------------------------------------------------- */}
          {medication.map((e, index: number) => (
            <Box
              display='flex'
              flexDirection='column'
              width='100%'
              flexGrow={1}
              key={e.id}
            >
              <Grid
                container
                direction='row'
                id='vitals'
                style={{
                  backgroundColor:
                    index % 2 === 0 ? '#edf2fa' : kDialogueBackground,
                }}
              >
                <Grid item xs={3}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        minHeight={30}
                        maxHeight={100}
                        alignItems='center'
                        px={1}
                      >
                        {e.medication && e.medication.code && (
                          <Typography
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                              textTransform: 'capitalize',
                            }}
                          >
                            {e.medication.code.text ?? ''}
                          </Typography>
                        )}

                        {e.name && e.name.length > 0 && (
                          <Typography
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                              textTransform: 'capitalize',
                            }}
                          >
                            {e.name}
                          </Typography>
                        )}

                        {e.medicationCode && (
                          <Typography
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                              textTransform: 'capitalize',
                            }}
                          >
                            {e.medicationCode.display}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box display='flex' width='2%' paddingTop={0.1}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          minHeight: '30px',
                          maxHeight: '100px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        minHeight={30}
                        maxHeight={100}
                        alignItems='center'
                        px={1}
                      >
                        {e.dosageQuantity && e.dosageQuantityType && (
                          <Typography
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {`${e.dosageQuantity} ${getUnitOfDosageFromRequest(
                              e.dosageQuantityType
                            )}`}
                          </Typography>
                        )}
                      </Box>

                      {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                    </Box>
                    <Box display='flex' width='2%' paddingTop={0.1}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          minHeight: '30px',
                          maxHeight: '100px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        minHeight={30}
                        maxHeight={100}
                        alignItems='center'
                        px={1}
                      >
                        {e.administrationCode && (
                          <Box display='flex' flexGrow flexDirection='row'>
                            <Typography
                              color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,

                                fontWeight: 600,
                                lineHeight: 'normal',
                              }}
                            >
                              {getUnitOfRouteRequest(e.administrationCode)}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Box display='flex' width='2%' paddingTop={0.1}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          minHeight: '30px',
                          maxHeight: '100px',

                          width: '2px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow
                        minHeight={30}
                        maxHeight={100}
                        alignItems='center'
                        px={1}
                      >
                        {e.timeOfDayCode && e.mealCode && (
                          <Typography
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,

                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {getTimeOfDayDisplayMeal(e.mealCode) === 'Anytime'
                              ? `${getTimeOfDayDisplayForStatement(
                                  e.timeOfDayCode
                                ).join(', ')}`
                              : `${getTimeOfDayDisplayForStatement(
                                  e.timeOfDayCode
                                ).join(', ')}  (${
                                  getTimeOfDayDisplayMeal(e.mealCode) ===
                                  'Anytime'
                                    ? ''
                                    : getTimeOfDayDisplayMeal(e.mealCode)
                                } ${
                                  getTimeOfDayDisplayMeal(e.mealCode) ===
                                  'Anytime'
                                    ? ''
                                    : 'Meal'
                                })`}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {/* <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid> */}
            </Box>
          ))}
          {/* <Grid item xs={6}>
            <Box>
              {medication.timeOfDayCode && (
                <Box display='flex' width='50%' flexGrow flexDirection='row'>
                  <Typography variant='body2' color='initial'>
                    {getTimeOfDayDisplayForStatement(
                      medication.timeOfDayCode
                    ).join(', ')}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid> */}
          {/* <Grid item xs={3}>
            <Box px={2}>
              {medication.mealCode && (
                <Box display='flex' flexGrow flexDirection='row'>
                  <Typography variant='body2' color='initial'>
                    Meal : {getTimeOfDayDisplayMeal(medication.mealCode)}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid> */}
          {/* <Grid item xs={3}>
            <Box px={2}>
              {medication.administrationCode && (
                <Box display='flex' flexGrow flexDirection='row'>
                  <Typography variant='body2' color='initial'>
                    {getUnitOfRouteRequest(medication.administrationCode)}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid> */}
          {/* </Grid> */}
          {/* <Grid container direction='row' id='vitals'>
          <Grid item xs={6}>
            <Box>
              {medication.date && (
                <Box display='flex' flexGrow flexDirection='row'>
                  <Typography variant='subtitle2' color='initial'>
                    Recorded on {moment(medication.date).format('DD-MM-YYYY')}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box px={2}>
               {medication.textForm && (
                  <Box display='flex' width='50%' flexGrow flexDirection='row'>
                    <Typography variant='body2' color='initial'>
                      {medication.textForm}
                    </Typography>
                  </Box>
                )} 
            </Box>
          </Grid>
        </Grid> */}
        </Grid>
      </Box>
    )
  }
