import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'
import { WelloUploadSelect } from '../../LeftMenu/WelloUploadSelect'

interface Props {
  columnsData: any[]
  onSelectionChanged: any
  selectedData: string[]
}

const useStyles = makeStyles((theme) => ({
  test: {
    border: '1px',
    backgroundColor: 'white',
  },
}))
const columnsNames = [
  { id: `labRefId`, title: 'Lab Reference Id' },
  {
    id: 'name',
    title: 'Patient Name',
  },
  { id: `phone`, title: 'Phone' },
  { id: `dob`, title: 'Date of Birth' },
  { id: `gender`, title: 'Gender' },
  { id: `email`, title: 'Email' },
  { id: `address`, title: 'Address' },
]
export const PatientColumnMapping: React.FC<Props> = ({
  columnsData,
  onSelectionChanged,
  selectedData,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const preSelectColumn = ''
  const columnData: string[] = []
  const [selectedColumn, setSelectedColumn] = React.useState<string[]>([])
  //   useEffect(() => {}, [selectedColumn])

  return (
    // <Box
    //   width='100%'
    //   overflow='auto'
    //   style={{ overflow: 'auto', height: '78vh' }}
    // >
    <Box>
      <Box paddingLeft={2}>
        {' '}
        <Typography variant='subtitle2' color='textPrimary'>
          Fields available for bulk upload mapping:
        </Typography>
      </Box>

      <Box p={2}>
        <Grid container spacing={1} alignItems='center' justify='flex-start'>
          <Grid item>
            <Box flexDirection='row' display='flex'>
              <Box
                border={1}
                borderColor='primary.main'
                p={0.7}
                borderRadius={2}
                height={30}
              >
                <Typography variant='body2' color='primary'>
                  {t('Lab Reference Id')}
                </Typography>
              </Box>
              <Box bgcolor='primary.main' width={20} justifyContent='center'>
                <Typography
                  variant='body1'
                  style={{
                    textAlign: 'center',
                    marginTop: 10,
                    color: 'white',
                  }}
                  // color='textSecondary'
                >
                  *
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item>
            <Box flexDirection='row' display='flex' height={30}>
              <Box
                border={1}
                borderColor='primary.main'
                p={0.7}
                borderRadius={2}
              >
                <Typography variant='body2' color='primary'>
                  {t('Patient Name')}
                </Typography>
              </Box>
              <Box
                bgcolor='primary.main'
                width={20}
                justifyContent='center'
                borderRadius={1}
              >
                <Typography
                  variant='body1'
                  style={{
                    textAlign: 'center',
                    marginTop: 10,
                    color: 'white',
                  }}
                  // color='textSecondary'
                >
                  *
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item>
            <Box flexDirection='row' display='flex'>
              <Box
                border={1}
                borderColor='primary.main'
                p={0.7}
                borderRadius={2}
                height={30}
              >
                <Typography variant='body2' color='primary'>
                  {t('Phone Number')}
                </Typography>
              </Box>
              <Box bgcolor='primary.main' width={20} justifyContent='center'>
                <Typography
                  variant='body1'
                  style={{
                    textAlign: 'center',
                    marginTop: 10,
                    color: 'white',
                  }}
                  // color='textSecondary'
                >
                  *
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item>
            <Box flexDirection='row' display='flex'>
              <Box
                border={1}
                borderColor='primary.main'
                p={0.7}
                borderRadius={2}
                height={30}
              >
                <Typography variant='body2' color='primary'>
                  {t('DoB')}
                </Typography>
              </Box>
              <Box bgcolor='primary.main' width={20} justifyContent='center'>
                <Typography
                  variant='body1'
                  style={{
                    textAlign: 'center',
                    marginTop: 10,
                    color: 'white',
                  }}
                  // color='textSecondary'
                >
                  *
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item>
            <Box flexDirection='row' display='flex'>
              <Box
                border={1}
                borderColor='primary.main'
                p={0.7}
                borderRadius={2}
                height={30}
              >
                <Typography variant='body2' color='primary'>
                  {t('Gender')}
                </Typography>
              </Box>
              <Box bgcolor='primary.main' width={20} justifyContent='center'>
                <Typography
                  variant='body1'
                  style={{
                    textAlign: 'center',
                    marginTop: 10,
                    color: 'white',
                  }}
                  // color='textSecondary'
                >
                  *
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item>
            <Box flexDirection='row' display='flex'>
              <Box
                border={1}
                p={0.7}
                borderColor='primary.main'
                borderRadius={2}
                height={30}
              >
                <Typography variant='body2' color='primary'>
                  {t('Email')}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item>
            <Box flexDirection='row' display='flex'>
              <Box
                border={1}
                p={0.7}
                borderColor='primary.main'
                borderRadius={2}
                height={30}
              >
                <Typography variant='body2' color='primary'>
                  {t('Address')}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2} alignItems='flex-start'>
        {columnsData &&
          columnsData.map((item: any) => (
            <Grid item xs={3} key={`${item.column}`}>
              <Box p={1.3} justifyContent='flex-start' width='100%'>
                <Paper
                  style={{
                    backgroundColor: 'white',
                    height: 281,
                    // maxWidth: 280,
                    // overflow: 'auto',
                  }}
                >
                  <Box
                    flexDirection='column'
                    display='flex'
                    justifyContent='flex-start'
                  >
                    <Box paddingX={2} width={1}>
                      <WelloUploadSelect
                        // key={preSelectColumn.id}
                        label='Select Field'
                        id='setColumns'
                        availableOptions={columnsNames}
                        required={true}
                        type='Select ...'
                        displayField='title'
                        valueField='id'
                        preSelectedOption={preSelectColumn}
                        selectedColumn={selectedData}
                        onSelectionChanged={(value: any) => {
                          columnData.push(value.id)
                          setSelectedColumn(columnData)
                          onSelectionChanged(value.id)
                        }}
                      />
                    </Box>
                    <Box paddingX={2.7} paddingY={0.5}>
                      <Typography
                        variant='h6'
                        color='primary'
                        // color='textSecondary'
                      >
                        {item.ColumnName}
                      </Typography>
                    </Box>
                    <Box paddingX={2.7}>
                      <Divider />
                    </Box>
                    <Box height={150} overflow='auto'>
                      <SimpleBar
                        style={{
                          height: '100%',

                          padding: '2px',
                        }}
                      >
                        {item.Data &&
                          item.Data.map((data: any, i: any) => (
                            <Box paddingX={2.8} paddingY={0.2} key={`${item}`}>
                              <Typography variant='body2' color='primary'>
                                {data}
                              </Typography>
                            </Box>
                          ))}
                      </SimpleBar>
                    </Box>
                    <Box
                      paddingX={2.8}
                      paddingTop={1}
                      paddingBottom={1}
                      key={`${item.moreData}`}
                    >
                      <Typography
                        variant='body2'
                        color='textPrimary'
                        style={{ cursor: 'cursor' }}
                      >
                        {item.moreData > 0 ? `+ ${item.moreData} more` : ''}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Grid>
          ))}
      </Grid>
    </Box>
    // </Box>
  )
}
