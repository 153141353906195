import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  getCodeOfSystemCodings,
  getCodeOfSystemFromCodableConceptList,
  getColorBasedOnNumber,
} from 'utils/fhirResourcesHelper'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getTimeOfDayDisplayForStatement,
  getTimeOfDayDisplayMeal,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import {
  getNotesFromAllergy,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { kDialogueBackground } from 'configs/styles/muiThemes'

interface OccupationTileProps {
  obsData?: R4.IObservation[]
  type?: string
}

export const AdmissionDashboardHeader: React.FC<OccupationTileProps> = ({
  obsData,
  type,
}: OccupationTileProps) => {
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      height={100}
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        height={100}
        justifyContent='flex-start'
        alignItems='center'
      >
        <Box
          justifyContent='flex-start'
          alignContent='center'
          display='flex'
          flexGrow
          height={100}
          alignItems='center'
          paddingTop={6}
          px={2}
          width='20%'
        >
          <Typography
            variant='subtitle2'
            style={{
              textTransform: 'capitalize',
              fontFamily: 'Open Sans',

              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            Admission
          </Typography>
        </Box>

        <Box
          justifyContent='flex-start'
          display='flex'
          flexGrow
          height={100}
          paddingTop={6}
          alignItems='center'
          width='30%'
          px={3}
        >
          <Typography
            variant='subtitle2'
            style={{
              fontFamily: 'Open Sans',

              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            IPD/OPD/Room No.
          </Typography>
        </Box>

        {/* <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignContent='flex-start'
                width='20%'
                px={2}
              >
                <Box display='flex' flexDirection='column' width='50%'>
                  <Typography variant='body2' color='initial'>
                    {usersData.gender}
                  </Typography>
                </Box>
              </Box> */}

        <Box
          justifyContent='flex-start'
          display='flex'
          flexGrow
          height={100}
          paddingTop={6}
          alignItems='center'
          width='20%'
        >
          <Typography
            variant='subtitle2'
            style={{
              textTransform: 'capitalize',
              fontFamily: 'Open Sans',

              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            Patient Details
          </Typography>
        </Box>

        <Box
          justifyContent='flex-start'
          display='flex'
          flexGrow
          height={100}
          paddingTop={6}
          alignItems='center'
          width='20%'
          px={1.5}
        >
          <Typography
            variant='subtitle2'
            color='initial'
            style={{
              textTransform: 'capitalize',
              fontFamily: 'Open Sans',

              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            Address
          </Typography>
        </Box>

        <Box
          justifyContent='flex-start'
          display='flex'
          flexGrow
          height={100}
          alignItems='center'
          width='10%'
          paddingTop={6}
        >
          <Box display='flex' flexDirection='column' width='50%'>
            <Typography
              variant='subtitle2'
              style={{
                textTransform: 'capitalize',
                fontFamily: 'Open Sans',

                color: 'black',

                fontWeight: 'bold',
                lineHeight: 'normal',
              }}
            >
              Amount
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
