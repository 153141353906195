import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'

import { Errors } from 'io-ts'
import _ from 'lodash'
import { LabObsData } from 'models/labObsData'
import { ObsServiceData } from 'models/obsServiceData'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getObServationArrangedData,
  getObServationData,
  getObServationDisplayData,
} from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { logger } from 'utils/logger'
import { ObservationFinderStatus } from './observationFinderStatus'

const initialState: ObservationFinderStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const diagnosticObsFinderSlice = createSlice({
  name: 'diagnosticObsFinderSlice',
  initialState,
  reducers: {
    searchingDoctorDetails(
      state,
      action: PayloadAction<ObservationFinderStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.activityDef = undefined
      state.observations = undefined
      state.observationDef = undefined
      state.finalLabObsData = undefined
      state.testData = undefined
      state.lftData = undefined
      state.lipidData = undefined
      state.rftData = undefined
      state.tftData = undefined
      state.urineData = undefined
      state.homa = undefined
      state.dexa = undefined
      state.serumElectraLytes = undefined
    },

    searchResults(state, action: PayloadAction<ObservationFinderStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.activityDef = action.payload.activityDef
      state.observations = action.payload.observations
      state.observationDef = action.payload.observationDef
      state.finalLabObsData = action.payload.finalLabObsData
      state.testData = action.payload.testData
      state.lftData = action.payload.lftData
      state.lipidData = action.payload.lipidData
      state.rftData = action.payload.rftData
      state.tftData = action.payload.tftData
      state.urineData = action.payload.urineData
      state.homa = action.payload.homa
      state.dexa = action.payload.dexa
      state.serumElectraLytes = action.payload.serumElectraLytes
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<ObservationFinderStatus>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.activityDef = undefined
      state.observations = undefined
      state.finalLabObsData = undefined
      state.testData = undefined
      state.lftData = undefined
      state.lipidData = undefined
      state.rftData = undefined
      state.tftData = undefined
      state.urineData = undefined
      state.homa = undefined
      state.dexa = undefined
      state.serumElectraLytes = undefined
    },

    errorWhileSearching(state, action: PayloadAction<ObservationFinderStatus>) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.activityDef = undefined
      state.observations = undefined
      state.finalLabObsData = undefined
      state.testData = undefined
      state.lftData = undefined
      state.lipidData = undefined
      state.rftData = undefined
      state.tftData = undefined
      state.urineData = undefined
      state.homa = undefined
      state.dexa = undefined
      state.serumElectraLytes = undefined
    },
    resetState(state, action: PayloadAction<ObservationFinderStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.observations = undefined
      state.activityDef = undefined
      state.finalLabObsData = undefined
      state.testData = undefined
      state.lftData = undefined
      state.lipidData = undefined
      state.rftData = undefined
      state.tftData = undefined
      state.urineData = undefined
      state.homa = undefined
      state.dexa = undefined
      state.serumElectraLytes = undefined
    },
  },
})

export const searchObservationsForAddLabTest =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: ObservationFinderStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(diagnosticObsFinderSlice.actions.searchingDoctorDetails(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const searchParameters: any = {
        status: 'active',
      }

      const response: any = await fhirClient.doGetResourceForMasterData(
        '/PlanDefinition?identifier=24331-1,24325-3,57021-8,24362-6,90224-7,24357-6,47214-2,38268-9,24326-1&status=active',
        searchParameters
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const labOfferingsResponse: R4.IBundle = relatedFhirDecodeRes.right
        if (labOfferingsResponse.total) {
          if (labOfferingsResponse.total > 0) {
            if (labOfferingsResponse.entry) {
              const planArray: R4.IPlanDefinition[] = labOfferingsResponse.entry
                .filter((e) => e.resource?.resourceType === 'PlanDefinition')
                .map((e) => e.resource as R4.IPlanDefinition)
              const newPlanArray: R4.IPlanDefinition[] = []
              for (let i = 0; i < planArray.length; i++) {
                if (planArray[i].extension !== undefined) {
                  newPlanArray.push(planArray[i])
                } else {
                  newPlanArray.push(planArray[i])
                }
              }
              const cbsPanel: ObsServiceData[] = []
              const lftPanel: ObsServiceData[] = []
              const rftPanel: ObsServiceData[] = []
              const lipidPanel: ObsServiceData[] = []
              const thPanel: ObsServiceData[] = []
              const urinePanel: ObsServiceData[] = []
              const homaPanel: ObsServiceData[] = []
              const dexaPanel: ObsServiceData[] = []
              const serumPanel: ObsServiceData[] = []
              newPlanArray.sort((a, b) =>
                a.title! > b.title! ? 1 : b.title! > a.title! ? -1 : 0
              )

              await Promise.all(
                newPlanArray.map(async (e) => {
                  const dataObs = await getObsDefinitions(e)
                  console.log('---------testData------------', dataObs)
                  if (e.title === 'Complete Blood Counts - CBC') {
                    cbsPanel.push({
                      name: e.title ?? '',
                      obsDef: dataObs,
                    })
                  }
                  if (e.title === 'Lipid Profile') {
                    lipidPanel.push({
                      name: e.title ?? '',
                      obsDef: dataObs,
                    })
                  }
                  if (e.title === 'Liver Function Test') {
                    lftPanel.push({
                      name: e.title ?? '',
                      obsDef: dataObs,
                    })
                  }
                  if (e.title === 'Renal Function Test') {
                    rftPanel.push({
                      name: e.title ?? '',
                      obsDef: dataObs,
                    })
                  }

                  if (e.title === 'Thyroid Function Test') {
                    thPanel.push({
                      name: e.title ?? '',
                      obsDef: dataObs,
                    })
                  }

                  if (e.title === 'Urinalysis complete panel') {
                    urinePanel.push({
                      name: e.title ?? '',
                      obsDef: dataObs,
                    })
                  }
                  if (e.title === 'HOMA IR') {
                    homaPanel.push({
                      name: e.title ?? '',
                      obsDef: dataObs,
                    })
                  }

                  if (e.title === 'Whole Body Dexa Scan') {
                    dexaPanel.push({
                      name: e.title ?? '',
                      obsDef: dataObs,
                    })
                  }
                  if (e.title === 'Serum Electrolytes') {
                    serumPanel.push({
                      name: e.title ?? '',
                      obsDef: dataObs,
                    })
                  }
                })
              )
              console.log('---------testData------------', cbsPanel)

              state.testData = cbsPanel
                .sort((a, b) =>
                  a.name! > b.name! ? 1 : b.name! > a.name! ? -1 : 0
                )
                .filter((e) => _.isEmpty(e.obsDef) === false)

              console.log('---------testData------------', state.testData)
              state.lipidData = lipidPanel
              state.rftData = rftPanel
              state.lftData = lftPanel
              state.tftData = thPanel
              state.urineData = urinePanel
              state.serumElectraLytes = serumPanel
              state.homa = homaPanel
              state.dexa = dexaPanel
              state.resultsAvailable = true
              state.searching = false
              state.error = false
              dispatch(diagnosticObsFinderSlice.actions.searchResults(state))
              return
            }
            state.resultsAvailable = false
            state.searching = false
            state.error = false
            state.noResultsAvailable = true
            dispatch(
              diagnosticObsFinderSlice.actions.noDataFoundForSearch(state)
            )
            return
          }
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(diagnosticObsFinderSlice.actions.noDataFoundForSearch(state))
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(diagnosticObsFinderSlice.actions.errorWhileSearching(state))
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(diagnosticObsFinderSlice.actions.errorWhileSearching(state))
    }
  }

export const resetObservationState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: ObservationFinderStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      observations: undefined,
    }
    dispatch(diagnosticObsFinderSlice.actions.resetState(state))
  }

async function getObsDefinitions(
  PlanDefinition: R4.IPlanDefinition
): Promise<R4.IObservationDefinition[]> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const activityParmeter: string[] = []
  let obsDef: R4.IObservationDefinition[] = []
  if (PlanDefinition) {
    if (PlanDefinition.action) {
      const actionData = PlanDefinition.action[0]
      if (actionData.action) {
        for (let i = 0; i < actionData.action.length; i++) {
          if (actionData.action[i].definitionCanonical) {
            const reference = actionData.action[i].definitionCanonical
            if (reference) {
              const activeId = reference.split(
                'http://wellopathy.com/ActivityDefinition/'
              )[1]

              activityParmeter.push(activeId)
            }
          }
        }
      }
    }
  }

  const searchParameters: any = {
    status: 'active',
    _count: 50,
  }
  const response: any = await fhirClient.doGetResourceForMasterData(
    `/ActivityDefinition?activitydef-code=${activityParmeter.toString()}&_include=ActivityDefinition:observationResultRequirement`,
    searchParameters
  )
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)

  if (relatedFhirDecodeRes._tag === 'Right') {
    const labOfferingsResponse: R4.IBundle = relatedFhirDecodeRes.right
    if (labOfferingsResponse.total) {
      if (labOfferingsResponse.total > 0) {
        if (labOfferingsResponse.entry) {
          obsDef = getObServationData(labOfferingsResponse)
        }
      }
    }
  }
  if (PlanDefinition.title === 'Urinalysis complete panel') {
    return getObServationArrangedData(obsDef)
  }
  return obsDef.sort((a, b) =>
    a.preferredReportName!.toLowerCase() > b.preferredReportName!.toLowerCase()
      ? 1
      : b.preferredReportName!.toLowerCase() >
        a.preferredReportName!.toLowerCase()
      ? -1
      : 0
  )
}
export function getPractitionerRoleObject(item: R4.IPractitionerRole) {
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    name: item.practitioner?.display ?? '',
    gender: '',
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: false,
  }
  return val
}

export default diagnosticObsFinderSlice.reducer
