import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  id: string
  open: boolean
  onClose: () => void
  notesData: string
  patient?: R4.IPatient
  dialogText?: string
}
export const WarningDialog: React.FC<Props> = ({
  id,
  open,
  onClose,
  notesData,
  patient,
  dialogText,
}) => {
  const { t } = useTranslation()

  function resetForm() {}

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetForm()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>{dialogText ?? 'Notes'}</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
                resetForm()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        <Box display='flex' flexDirection='row'>
          {notesData && (
            <Typography
              variant='body1'
              color='initial'
              style={{
                wordWrap: 'break-word',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
              }}
            >
              {notesData.split('\n').map((i: any, key: any) => (
                <div key={i}>{i}</div>
              ))}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
          }}
          variant='outlined'
          disableElevation
          id='ok'
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
