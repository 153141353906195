import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  useTheme,
} from '@material-ui/core'
import TextField from '@mui/material/TextField'
import { kBackgroundPaper, kDialogueBackground } from 'configs/styles/muiThemes'
import _ from 'lodash'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { addNewKriyaDetails } from 'utils/sopaNotes/plan/kriyaHelpers'
import { ValueSetSelectMultiple } from 'views/components/common/MultiSelectFromValueSetUrl'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { KriyaDetailTile } from './kriyaDetailTile'

export interface KriyaDetail {
  kriya?: R4.ICoding
  medications?: R4.ICoding[]
  remarks?: string
  recordedDate?: string
  priority?: string
  rawKriyaDetail?: R4.IServiceRequest
}
interface Props {
  open: boolean
  patientReference: R4.IReference
  encounterReference: R4.IReference
  onClose: () => void
  onLabDiagnosticsAdded: () => void
}
export const AddKriyasForEncounters: React.FC<Props> = ({
  open,
  onClose,
  onLabDiagnosticsAdded,
  patientReference,
  encounterReference,
}) => {
  const { t } = useTranslation(['common'])
  const theme = useTheme()

  const [currentKriya, setCurrentKriya] = useState<KriyaDetail>({})

  const [addAnother, setAddAnother] = React.useState<boolean>(true)

  const [kriyas, setKriyas] = useState<KriyaDetail[]>([])

  function resetForm() {
    setCurrentKriya({
      kriya: undefined,
      medications: undefined,
      remarks: undefined,
      recordedDate: undefined,
    })
    setformState(_.random(0, 1000).toString())
  }

  const [formState, setformState] = useState(_.random(0, 1000).toString())

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const dispatch = useDispatch()

  function addKriyaToKriyasList(pushToSave?: boolean) {
    if (currentKriya.kriya && currentKriya.kriya.code) {
      const tempKriyas = [...kriyas]

      const kriya: KriyaDetail = {
        ...currentKriya,
      }
      tempKriyas.push(kriya)
      resetForm()
      setKriyas(tempKriyas)

      if (pushToSave) {
        addCurrentDetails(tempKriyas)
      }
    }
  }

  function addCurrentDetails(kriyasToUpload: KriyaDetail[]) {
    if (kriyasToUpload && kriyasToUpload.length > 0) {
      setUpdateStatus({ requesting: true })
      addNewKriyaDetails(
        patientReference,
        encounterReference,
        kriyasToUpload
      ).then((e) => {
        if (e) {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(showSuccessAlert('Kriya/Kriyas added successfully'))

          onClose()
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(
            showErrorAlert('Error while adding Kriya. Please try again later')
          )
        }
      })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetForm()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Add Kriyas (Procedure)</DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#ededed',
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0,
        }}
      >
        <Box
          marginX={3}
          display='flex'
          flexDirection='column'
          marginTop={1}
          marginBottom={2}
        >
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='space-between'
          >
            <Box width='49%' key={`${formState}kriya_search`}>
              <ValueSetSelectSingle
                title='Select Kriya (Procedure)'
                url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurveda-treatments-vs'
                id='kriyas'
                fuzzySearch={true}
                preSelectedOptions={currentKriya.kriya}
                disabled={false}
                onOptionSelected={(selectedKriya) => {
                  setCurrentKriya({
                    ...currentKriya,
                    kriya: selectedKriya,
                  })
                }}
                helperText={undefined}
              />
            </Box>

            <Box width='49%' key={`${formState}medicine_search`}>
              <ValueSetSelectMultiple
                title='Select Medicines'
                url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurvedic-med-vs'
                id='medicines'
                disabled={false}
                fuzzySearch={true}
                pluralWord='Medicines'
                singularWorld='Medicine'
                onOptionSelected={(selectedMedicines) => {
                  console.log(
                    '------------selectedMedicines---------------',
                    selectedMedicines
                  )
                  setCurrentKriya({
                    ...currentKriya,
                    medications: selectedMedicines,
                  })
                }}
                helperText={undefined}
              />
            </Box>
          </Box>
          <Box display='flex' flexDirection='row'>
            <Box display='flex' flexDirection='column' width='100%'>
              <WelloFormItemLabel title='Additional Notes' />
              <TextField
                id='txt_kriya_notes'
                variant='outlined'
                size='small'
                fullWidth
                multiline
                value={currentKriya.remarks ?? ''}
                onChange={(e) => {
                  setCurrentKriya({
                    ...currentKriya,
                    remarks: e.target.value,
                  })
                }}
              />
            </Box>
          </Box>
          {kriyas.length > 0 && (
            <Box display='flex' flexDirection='row'>
              <Box display='flex' flexDirection='column' width='100%'>
                <WelloFormItemLabel title='Selected Kriyas' />
                {kriyas.map((kriya, index) => (
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    style={{
                      border: `1px solid ${theme.palette.divider}`,
                    }}
                    bgcolor={
                      index % 2 === 1 ? kDialogueBackground : kBackgroundPaper
                    }
                    key={_.snakeCase(
                      kriya!.kriya!.code ?? kriya!.kriya!.display ?? ''
                    )}
                  >
                    <KriyaDetailTile kriyaDetail={kriya} />
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Box
          display='flex'
          flexDirection='row'
          flexGrow={1}
          px={1}
          alignItems='center'
          width='100%'
        >
          {updateStatus.requesting && (
            <Box
              flexDirection='row'
              display='flex'
              alignSelf='center'
              justifyContent='center'
            >
              <CircularProgress size={25} />
            </Box>
          )}
          <Box flexGrow={1} alignSelf='center' paddingX={1}>
            <FormControlLabel
              value='end'
              control={
                <Checkbox
                  inputProps={{
                    id: 'add_another',
                  }}
                  color='primary'
                  checked={addAnother}
                  onChange={(eve, checked) => {
                    setAddAnother(checked)
                  }}
                />
              }
              label={t('labelCommon:Add another Kriya (Procedure)')}
              labelPlacement='end'
            />
          </Box>
          <Box display='flex' flexDirection='row'>
            <Box paddingX={1}>
              <Button
                onClick={() => {
                  resetForm()
                  onClose()
                }}
                variant='outlined'
                id='cancel'
                disableElevation
              >
                {t('labelCommon:cancel')}
              </Button>
            </Box>

            {addAnother && (
              <Box paddingX={1}>
                <Button
                  onClick={() => {
                    addKriyaToKriyasList()
                  }}
                  id='another'
                  variant='contained'
                  color='primary'
                  disableElevation
                  disabled={currentKriya.kriya === undefined}
                >
                  {t('labelCommon:add_more_new')}
                </Button>
              </Box>
            )}

            <Box paddingX={1}>
              <Button
                onClick={() => {
                  if (currentKriya.kriya === undefined) {
                    addCurrentDetails(kriyas)
                  } else {
                    addKriyaToKriyasList(true)
                  }
                }}
                variant='contained'
                color='primary'
                id='save'
                disableElevation
                disabled={
                  (currentKriya.kriya === undefined && kriyas.length === 0) ||
                  updateStatus.requesting
                }
              >
                {t('labelCommon:Save')}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
