import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  ListItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { ObsDataTableResource } from 'models/labObsData'
import { LabOfferingDetail } from 'models/labOfferDetail'
import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { searchObservationsMaster } from 'redux/lab/observationFinder/masterObsSearchSlice'
import { searchLabOfferings } from 'redux/lab/offerings/labOfferingSearchHandler/labOfferingSearchSlice'
import { RootState } from 'redux/rootReducer'
import { logger } from 'utils/logger'

interface LabOfferingSelectorProps {
  existingDataLength: number
  onSelectionChanges?: (selectedValues: ObsDataTableResource) => void
  onType?: () => void
  doctorListId?: string
  preSelectedOrdersRoles?: ObsDataTableResource
  preSelectAll?: boolean
  disabled: boolean
  preSelectedText?: string
}

export const LabObservationSelector: React.FC<LabOfferingSelectorProps> = ({
  existingDataLength,
  onSelectionChanges,
  onType,
  doctorListId,
  preSelectedOrdersRoles,
  preSelectAll = false,
  disabled,
  preSelectedText,
}: LabOfferingSelectorProps) => {
  const masterObsFinderSlice = useSelector(
    (state: RootState) => state.masterObsFinderSlice
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(searchObservationsMaster(existingDataLength))
  }, [dispatch])
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
          <Button
            onClick={() => {
              dispatch(searchObservationsMaster(existingDataLength))
            }}
          >
            Try again
          </Button>
        </div>
      )}
    >
      <Box width='100%'>
        <Box width='100%'>
          <Autocomplete
            id='tf_search_tests'
            multiple={false}
            onOpen={() => {
              // setOpen(true)
            }}
            onClose={() => {
              // setOpen(false)
            }}
            style={{
              borderRadius: '4px',
            }}
            disableClearable
            fullWidth
            size='small'
            getOptionSelected={(option, value) =>
              option.obsName === value.obsName
            }
            disabled={disabled}
            getOptionLabel={(option) => option.obsName ?? ''}
            options={masterObsFinderSlice.codeList ?? []}
            loading={masterObsFinderSlice.searching}
            autoComplete
            includeInputInList
            filterSelectedOptions
            onChange={(e, changedValue, reason) => {
              logger.info(changedValue)
              if (onSelectionChanges) onSelectionChanges(changedValue ?? '')
            }}
            ChipProps={{
              deleteIcon: (
                <ClearOutlined
                  style={{
                    height: '15px',
                    color: 'white',
                  }}
                />
              ),
              style: {
                backgroundColor: kPrimaryLight,
                borderRadius: '4px',
              },
            }}
            renderOption={(option, { inputValue }) => {
              const matches = match(option.obsName ?? '', inputValue)
              const parts = parse(option.obsName ?? '', matches)
              return (
                <ListItem id={`lab_test_opt_${option.obsCode}`}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={part.text}
                        style={{
                          backgroundColor: part.highlight
                            ? kPrimaryLight
                            : undefined,
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </ListItem>
              )
            }}
            filterOptions={(x) => x}
            renderInput={(params) => (
              <TextField
                {...params}
                size='small'
                placeholder={t('labelCommon:labTest_search_placeholder')}
                variant='outlined'
                onChange={(e) => {
                  dispatch(
                    searchObservationsMaster(existingDataLength, e.target.value)
                  )
                  if (onType) {
                    onType()
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {masterObsFinderSlice.searching ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>

        {masterObsFinderSlice.error && (
          <Typography>
            {' '}
            {masterObsFinderSlice.errorMessage ?? 'Error while searching'}
          </Typography>
        )}
        {masterObsFinderSlice.noResultsAvailable && (
          <Typography> No Results available</Typography>
        )}
      </Box>
    </ErrorBoundary>
  )
}
