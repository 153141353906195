import { R4 } from '@ahryman40k/ts-fhir-types'
import { OperationalHoursOfDay } from 'models/operationalHoursOfDay'
import { getUniqueTempId } from './idHelpers'

export const preSelectedDays: string[] = ['M', 'T', 'W', 'Th', 'F', 'Sa', 'S']
export const preSelectedDaysNew: string[] = ['Mon', 'Tue', 'W', 'Th']
export const defaultStartTime: string = '00:00'
export const defaultEndTime: string = '23:59'

export function GetFhirBusinessTimings(
  input: OperationalHoursOfDay[]
): R4.ILocation_HoursOfOperation[] {
  input = input.filter((e) => e.available)
  let fhirWeekTimings: R4.ILocation_HoursOfOperation[] = []
  input.forEach((e) => {
    const values: R4.ILocation_HoursOfOperation[] = e.timings.map(
      (eachTiming) => {
        const eachFhirTiming: R4.ILocation_HoursOfOperation = {
          daysOfWeek: [e.dayOfWeek],
          openingTime: eachTiming.availableStartTime,
          closingTime: eachTiming.availableEndTime,
          allDay: e.allDayOpen,
        }
        return eachFhirTiming
      }
    )

    fhirWeekTimings = [...fhirWeekTimings, ...values]
  })
  return fhirWeekTimings
}

export function GetDefaultBusinessTimings(): OperationalHoursOfDay[] {
  const defaultOperationalHours: OperationalHoursOfDay[] = [
    {
      dayOfWeek: R4.HealthcareService_AvailableTimeDaysOfWeekKind._mon,
      available: true,
      allDayOpen: false,

      timings: [
        {
          id: getUniqueTempId(),
          availableStartTime: '09:00',
          availableEndTime: '15:00',
        },
      ],
    },
    {
      dayOfWeek: R4.HealthcareService_AvailableTimeDaysOfWeekKind._tue,
      available: true,
      allDayOpen: false,

      timings: [
        {
          id: getUniqueTempId(),
          availableStartTime: '09:00',
          availableEndTime: '15:00',
        },
      ],
    },
    {
      dayOfWeek: R4.HealthcareService_AvailableTimeDaysOfWeekKind._wed,
      available: true,
      allDayOpen: false,
      timings: [
        {
          id: getUniqueTempId(),
          availableStartTime: '09:00',
          availableEndTime: '15:00',
        },
      ],
    },

    {
      dayOfWeek: R4.HealthcareService_AvailableTimeDaysOfWeekKind._thu,
      available: true,
      allDayOpen: false,
      timings: [
        {
          id: getUniqueTempId(),
          availableStartTime: '09:00',
          availableEndTime: '15:00',
        },
      ],
    },
    {
      dayOfWeek: R4.HealthcareService_AvailableTimeDaysOfWeekKind._fri,
      available: true,
      allDayOpen: false,
      timings: [
        {
          id: getUniqueTempId(),
          availableStartTime: '09:00',
          availableEndTime: '15:00',
        },
      ],
    },
    {
      dayOfWeek: R4.HealthcareService_AvailableTimeDaysOfWeekKind._sat,
      available: true,
      allDayOpen: false,
      timings: [
        {
          id: getUniqueTempId(),
          availableStartTime: '09:00',
          availableEndTime: '15:00',
        },
      ],
    },
    {
      dayOfWeek: R4.HealthcareService_AvailableTimeDaysOfWeekKind._sun,
      available: true,
      allDayOpen: false,
      timings: [
        {
          id: getUniqueTempId(),
          availableStartTime: '09:00',
          availableEndTime: '15:00',
        },
      ],
    },
  ]

  return defaultOperationalHours
}

export function GetDefaultBusinessTimingsWithNoTimings(): OperationalHoursOfDay[] {
  const defaultOperationalHours: OperationalHoursOfDay[] = [
    {
      dayOfWeek: R4.HealthcareService_AvailableTimeDaysOfWeekKind._sun,
      available: false,
      allDayOpen: false,
      timings: [],
    },
    {
      dayOfWeek: R4.HealthcareService_AvailableTimeDaysOfWeekKind._mon,
      available: false,
      allDayOpen: false,

      timings: [],
    },
    {
      dayOfWeek: R4.HealthcareService_AvailableTimeDaysOfWeekKind._tue,
      available: false,
      allDayOpen: false,

      timings: [],
    },
    {
      dayOfWeek: R4.HealthcareService_AvailableTimeDaysOfWeekKind._wed,
      available: false,
      allDayOpen: false,
      timings: [],
    },

    {
      dayOfWeek: R4.HealthcareService_AvailableTimeDaysOfWeekKind._thu,
      available: false,
      allDayOpen: false,
      timings: [],
    },
    {
      dayOfWeek: R4.HealthcareService_AvailableTimeDaysOfWeekKind._fri,
      available: false,
      allDayOpen: false,
      timings: [],
    },
    {
      dayOfWeek: R4.HealthcareService_AvailableTimeDaysOfWeekKind._sat,
      available: false,
      allDayOpen: false,
      timings: [],
    },
  ]

  return defaultOperationalHours
}

export function GetDefaultBusinessTimingsFromLocation(
  location?: R4.ILocation
): OperationalHoursOfDay[] | undefined {
  if (
    location &&
    location.hoursOfOperation &&
    location.hoursOfOperation.length > 0
  ) {
    const defaultOperationalHours: OperationalHoursOfDay[] =
      GetDefaultBusinessTimingsWithNoTimings()

    location.hoursOfOperation.forEach((e) => {
      const op: OperationalHoursOfDay | undefined =
        defaultOperationalHours.find(
          (item) => item.dayOfWeek === e.daysOfWeek?.[0]
        )
      if (op) {
        op.allDayOpen = e.allDay ?? false
        op.available = true
        if (e.openingTime && e.closingTime)
          op.timings.push({
            availableStartTime: e.openingTime,
            availableEndTime: e.closingTime,
            id: e.id ?? getUniqueTempId(),
          })
      }
    })

    return defaultOperationalHours
  }
  return undefined
}

export function GetTitleKeyForFhirDay(
  day: R4.HealthcareService_AvailableTimeDaysOfWeekKind
): string {
  switch (day) {
    case R4.HealthcareService_AvailableTimeDaysOfWeekKind._sun:
      return 'Sunday'
    case R4.HealthcareService_AvailableTimeDaysOfWeekKind._mon:
      return 'Monday'
    case R4.HealthcareService_AvailableTimeDaysOfWeekKind._tue:
      return 'Tuesday'
    case R4.HealthcareService_AvailableTimeDaysOfWeekKind._wed:
      return 'Wednesday'
    case R4.HealthcareService_AvailableTimeDaysOfWeekKind._thu:
      return 'Thursday'
    case R4.HealthcareService_AvailableTimeDaysOfWeekKind._fri:
      return 'Friday'
    case R4.HealthcareService_AvailableTimeDaysOfWeekKind._sat:
      return 'Saturday'

    default:
      return 'not_available'
  }
}

export function GetSingleLetterForFhirDay(
  day: R4.HealthcareService_AvailableTimeDaysOfWeekKind
): string {
  switch (day) {
    case R4.HealthcareService_AvailableTimeDaysOfWeekKind._sun:
      return 'S'
    case R4.HealthcareService_AvailableTimeDaysOfWeekKind._mon:
      return 'M'
    case R4.HealthcareService_AvailableTimeDaysOfWeekKind._tue:
      return 'T'
    case R4.HealthcareService_AvailableTimeDaysOfWeekKind._wed:
      return 'W'
    case R4.HealthcareService_AvailableTimeDaysOfWeekKind._thu:
      return 'Th'
    case R4.HealthcareService_AvailableTimeDaysOfWeekKind._fri:
      return 'F'
    case R4.HealthcareService_AvailableTimeDaysOfWeekKind._sat:
      return 'Sa'

    default:
      return 'not_available'
  }
}

export function updateDaySelection(
  selectedDetails: string[],
  businessDates: OperationalHoursOfDay[]
): OperationalHoursOfDay[] {
  const changeValues: OperationalHoursOfDay[] = [...businessDates]

  changeValues.forEach((e) => {
    if (e.dayOfWeek === R4.HealthcareService_AvailableTimeDaysOfWeekKind._mon) {
      if (selectedDetails.includes('M')) {
        e.available = true
        if (e.timings.length === 0) {
          e.timings = [
            {
              id: getUniqueTempId(),
              availableStartTime: '09:00',
              availableEndTime: '15:00',
            },
          ]
        }
      } else {
        e.available = false
        e.allDayOpen = false
      }
    }
    if (e.dayOfWeek === R4.HealthcareService_AvailableTimeDaysOfWeekKind._tue) {
      if (selectedDetails.includes('T')) {
        e.available = true
        if (e.timings.length === 0) {
          e.timings = [
            {
              id: getUniqueTempId(),
              availableStartTime: '09:00',
              availableEndTime: '15:00',
            },
          ]
        }
      } else {
        e.available = false
        e.allDayOpen = false
      }
    }
    if (e.dayOfWeek === R4.HealthcareService_AvailableTimeDaysOfWeekKind._wed) {
      if (selectedDetails.includes('W')) {
        e.available = true
        if (e.timings.length === 0) {
          e.timings = [
            {
              id: getUniqueTempId(),
              availableStartTime: '09:00',
              availableEndTime: '15:00',
            },
          ]
        }
      } else {
        e.available = false
        e.allDayOpen = false
      }
    }
    if (e.dayOfWeek === R4.HealthcareService_AvailableTimeDaysOfWeekKind._thu) {
      if (selectedDetails.includes('Th')) {
        e.available = true
        if (e.timings.length === 0) {
          e.timings = [
            {
              id: getUniqueTempId(),
              availableStartTime: '09:00',
              availableEndTime: '15:00',
            },
          ]
        }
      } else {
        e.available = false
        e.allDayOpen = false
      }
    }
    if (e.dayOfWeek === R4.HealthcareService_AvailableTimeDaysOfWeekKind._fri) {
      if (selectedDetails.includes('F')) {
        e.available = true
        if (e.timings.length === 0) {
          e.timings = [
            {
              id: getUniqueTempId(),
              availableStartTime: '09:00',
              availableEndTime: '15:00',
            },
          ]
        }
      } else {
        e.available = false
        e.allDayOpen = false
      }
    }
    if (e.dayOfWeek === R4.HealthcareService_AvailableTimeDaysOfWeekKind._sat) {
      if (selectedDetails.includes('Sa')) {
        e.available = true
        if (e.timings.length === 0) {
          e.timings = [
            {
              id: getUniqueTempId(),
              availableStartTime: '09:00',
              availableEndTime: '15:00',
            },
          ]
        }
      } else {
        e.available = false
        e.allDayOpen = false
      }
    }
    if (e.dayOfWeek === R4.HealthcareService_AvailableTimeDaysOfWeekKind._sun) {
      if (selectedDetails.includes('S')) {
        e.available = true
        if (e.timings.length === 0) {
          e.timings = [
            {
              id: getUniqueTempId(),
              availableStartTime: '09:00',
              availableEndTime: '15:00',
            },
          ]
        }
      } else {
        e.available = false
        e.allDayOpen = false
      }
    }
  })

  return changeValues
}

export function updateDaySelectionSettings(
  selectedDetails: string,
  switchState: boolean,
  businessDates: OperationalHoursOfDay[]
): OperationalHoursOfDay[] {
  const changeValues: OperationalHoursOfDay[] = [...businessDates]
  changeValues.forEach((e) => {
    if (e.dayOfWeek === R4.HealthcareService_AvailableTimeDaysOfWeekKind._sat) {
      if (e.dayOfWeek.includes(selectedDetails) && e.available === false) {
        e.available = true
        if (e.timings.length === 0) {
          e.timings = [
            {
              id: getUniqueTempId(),
              availableStartTime: '09:00',
              availableEndTime: '15:00',
            },
          ]
        }
      } else {
        e.available = false
        e.allDayOpen = false
      }
    }
    if (e.dayOfWeek === R4.HealthcareService_AvailableTimeDaysOfWeekKind._tue) {
      if (selectedDetails.includes('T')) {
        e.available = true
        if (e.timings.length === 0) {
          e.timings = [
            {
              id: getUniqueTempId(),
              availableStartTime: '09:00',
              availableEndTime: '15:00',
            },
          ]
        }
      } else {
        e.available = false
        e.allDayOpen = false
      }
    }
    if (e.dayOfWeek === R4.HealthcareService_AvailableTimeDaysOfWeekKind._mon) {
      if (selectedDetails.includes('M')) {
        e.available = true
        if (e.timings.length === 0) {
          e.timings = [
            {
              id: getUniqueTempId(),
              availableStartTime: '09:00',
              availableEndTime: '15:00',
            },
          ]
        }
      } else {
        e.available = false
        e.allDayOpen = false
      }
    }
    if (e.dayOfWeek === R4.HealthcareService_AvailableTimeDaysOfWeekKind._wed) {
      if (selectedDetails.includes('W')) {
        e.available = true
        if (e.timings.length === 0) {
          e.timings = [
            {
              id: getUniqueTempId(),
              availableStartTime: '09:00',
              availableEndTime: '15:00',
            },
          ]
        }
      } else {
        e.available = false
        e.allDayOpen = false
      }
    }
    if (e.dayOfWeek === R4.HealthcareService_AvailableTimeDaysOfWeekKind._thu) {
      if (selectedDetails.includes('Th')) {
        e.available = true
        if (e.timings.length === 0) {
          e.timings = [
            {
              id: getUniqueTempId(),
              availableStartTime: '09:00',
              availableEndTime: '15:00',
            },
          ]
        }
      } else {
        e.available = false
        e.allDayOpen = false
      }
    }
    if (e.dayOfWeek === R4.HealthcareService_AvailableTimeDaysOfWeekKind._fri) {
      if (selectedDetails.includes('F')) {
        e.available = true
        if (e.timings.length === 0) {
          e.timings = [
            {
              id: getUniqueTempId(),
              availableStartTime: '09:00',
              availableEndTime: '15:00',
            },
          ]
        }
      } else {
        e.available = false
        e.allDayOpen = false
      }
    }
    if (e.dayOfWeek === R4.HealthcareService_AvailableTimeDaysOfWeekKind._sat) {
      if (selectedDetails.includes('Sa')) {
        e.available = true
        if (e.timings.length === 0) {
          e.timings = [
            {
              id: getUniqueTempId(),
              availableStartTime: '09:00',
              availableEndTime: '15:00',
            },
          ]
        }
      } else {
        e.available = false
        e.allDayOpen = false
      }
    }
    if (e.dayOfWeek === R4.HealthcareService_AvailableTimeDaysOfWeekKind._sun) {
      if (selectedDetails.includes('S')) {
        e.available = true
        if (e.timings.length === 0) {
          e.timings = [
            {
              id: getUniqueTempId(),
              availableStartTime: '09:00',
              availableEndTime: '15:00',
            },
          ]
        }
      } else {
        e.available = false
        e.allDayOpen = false
      }
    }
  })

  return changeValues
}

export function getNumberFromAlphabet(day: string): number {
  switch (day) {
    case 'A' || 'a':
      return 1
    case 'B' || 'b':
      return 2
    case 'C' || 'c':
      return 3
    case 'D' || 'd':
      return 4
    case 'E' || 'e':
      return 5
    case 'F' || 'f':
      return 6
    case 'G' || 'g':
      return 7
    case 'H' || 'h':
      return 8
    case 'I' || 'i':
      return 9
    case 'J' || 'j':
      return 10
    case 'K' || 'k':
      return 11
    case 'L' || 'l':
      return 12
    case 'M' || 'm':
      return 13
    case 'N' || 'n':
      return 14
    case 'O' || 'o':
      return 15
    case 'P' || 'p':
      return 16
    case 'Q' || 'q':
      return 17
    case 'R' || 'r':
      return 18
    case 'S' || 's':
      return 19
    case 'T' || 't':
      return 20
    case 'U' || 'u':
      return 21
    case 'V' || 'v':
      return 22
    case 'W' || 'w':
      return 23
    case 'X' || 'x':
      return 24
    case 'Y' || 'y':
      return 25
    case 'Z' || 'z':
      return 26

    default:
      return 0
  }
}
