import { SocketEventData } from 'models/socketEventData'
import { createContext } from 'react'
import { Socket } from 'socket.io-client'

interface SocketContextType {
  socket: Socket | null
  eventData: SocketEventData | null
}

const SocketContext = createContext<SocketContextType>({
  socket: null,
  eventData: null,
})

export default SocketContext
