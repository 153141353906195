import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { TherapyDataUpdate } from 'models/therapyDataUpdate'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { updateCatalog } from 'redux/valueSet/therapyList/addTherapyCatalougeSlice'
import { getTherapyBundleUpdate } from 'utils/labHelpers/uploadHelper'
import { WelloTextField } from 'wello-web-components'

export interface EditTherapyProps {
  open: boolean
  onCancelClick: () => void
  selectedValue: TherapyDataUpdate
}

export interface DeleteTherapyProps {
  open: boolean
  onCancelClick: () => void
}

export function EditTherapy(props: EditTherapyProps) {
  const { open, selectedValue, onCancelClick: onClose } = props

  const therapyDetails = selectedValue

  const dispatch = useDispatch()

  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  const handleClose = React.useCallback(() => {
    onClose()
  }, [onClose])

  const [therapyData, setTherapyData] = React.useState<TherapyDataUpdate>({
    name: therapyDetails?.name,
    price: therapyDetails?.price ?? 0,
    duration: therapyDetails?.duration ?? 0,
    therapy: therapyDetails?.therapy,
    category: therapyDetails?.category,
    billableCode: therapyDetails?.billableCode,
  })

  function handleNameChange(nameTherapy: string) {
    setTherapyData({ ...therapyData, name: nameTherapy })
  }

  // function handleCategoryChange(categoryTherapy: R4.ICoding ) {
  //     setTherapyData({ ...therapyData, category: categoryTherapy })
  // }

  function handlePriceChange(priceTherapy: number) {
    setTherapyData({ ...therapyData, price: priceTherapy })
  }

  function handleDurationChange(durationTherapy: number) {
    setTherapyData({ ...therapyData, duration: durationTherapy })
  }

  function handleBillingChange(billingTherapy: string) {
    let codeValue: R4.ICoding | undefined = {}

    setTherapyData({ ...therapyData, billable: billingTherapy })
    if (billingTherapy === 'billable') {
      codeValue = {
        system: 'http://hl7.org/fhir/chargeitem-status',
        code: 'billable',
        display: 'Billable',
      }
    } else {
      codeValue = {
        system: 'http://hl7.org/fhir/chargeitem-status',
        code: 'not-billable',
        display: 'Not billable',
      }
    }

    const temp = codeValue
    setTherapyData({ ...therapyData, billableCode: temp })
  }

  function handleSubmit() {
    let hasPriceErrorInData: boolean = false
    let hasDurationErrorInData: boolean = false

    if (
      therapyData.price <= 0 ||
      therapyData.price > 10000 ||
      Number.isNaN(therapyData.price)
    ) {
      hasPriceErrorInData = true
    } else {
      hasPriceErrorInData = false
    }

    if (
      therapyData.duration <= 0 ||
      therapyData.duration > 120 ||
      Number.isNaN(therapyData.duration)
    ) {
      hasDurationErrorInData = true
    } else {
      hasDurationErrorInData = false
    }

    if (!hasPriceErrorInData && !hasDurationErrorInData) {
      const bundleData = getTherapyBundleUpdate(therapyData)

      if (bundleData) {
        dispatch(updateCatalog(bundleData, '', 'Therapy'))
      }
    }
  }

  return (
    <Dialog
      open={open}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
      onClose={() => {
        onClose()
      }}
      //   sx={{ '& .MuiBackdrop-root': { backgroundColor: 'transparent' } }}
      PaperProps={{
        style: {
          backgroundColor: '#ECECEC',
          //    background: useTheme().palette.background.default,
          boxShadow: 'none',
        },
      }}
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:Update Therapy')}
      </DialogTitle>
      <DialogContent>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          width='100%'
        >
          <Box display='flex' px={2} py={1} width='100%'>
            <WelloTextField
              title='Therapy Name'
              textProps={{
                id: 'Therapy Name',
                value: selectedValue?.name,
                disabled: true,
                placeholder: 'Therapy name',
                onChange: (changeName) => {
                  handleNameChange(changeName.target.value)
                },
              }}
            />
          </Box>
          <Box display='flex' px={2} py={1} width='100%'>
            <WelloTextField
              title='Category'
              textProps={{
                inputProps: {
                  max: 120,
                },
                value: selectedValue?.category,
                disabled: true,
                placeholder: 'Category',
                onChange: (changeCategory) => {
                  // handleCategoryChange(changeCategory.target.value)
                },
              }}
            />
          </Box>
          <Box
            display='flex'
            px={2}
            py={1}
            width='100%'
            className='duration of therapy'
          >
            <WelloTextField
              title='Duration (in mins)'
              textProps={{
                id: 'Duration of the Therapy',
                inputProps: {
                  max: 120,
                  maxLength: 3,
                },
                error:
                  therapyData?.duration <= 0 ||
                  therapyData?.duration > 120 ||
                  Number.isNaN(therapyData.duration),
                helperText:
                  therapyData?.duration <= 0 || therapyData.duration > 120
                    ? 'Duration should be between 1 to 120'
                    : '',
                defaultValue: therapyData.duration,
                type: 'number',
                placeholder: 'Duration',
                onChange: (changeDuration) => {
                  handleDurationChange(
                    parseInt(changeDuration.target.value, 10)
                  )
                },
              }}
            />
          </Box>
          <Box display='flex' px={2} py={1} width='100%'>
            <WelloTextField
              title='Price'
              textProps={{
                id: 'Price of the Therapy',
                InputProps: {
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Box>
                        {' '}
                        <Typography variant='subtitle2' align='center'>
                          {' '}
                          &#x20b9;
                        </Typography>
                      </Box>
                    </InputAdornment>
                  ),
                },
                inputProps: {
                  max: 10000,
                  maxLength: 5,
                },
                error:
                  therapyData?.price <= 0 ||
                  therapyData?.price > 10000 ||
                  Number.isNaN(therapyData.price),
                helperText:
                  therapyData?.price <= 0 || therapyData.price > 10000
                    ? 'Price should be between 1 to 10000'
                    : '',
                defaultValue: therapyData.price,
                type: 'number',
                placeholder: 'Price',
                onChange: (changePrice) => {
                  handlePriceChange(parseInt(changePrice.target.value, 10))
                },
              }}
            />
          </Box>
          <Box display='flex' px={2} py={1} width='100%'>
            <FormControl>
              <FormLabel id='demo-row-radio-buttons-group-label'>
                <Typography
                  variant='subtitle1'
                  color='textPrimary'
                  style={{ textTransform: 'capitalize' }}
                >
                  Billing
                </Typography>
              </FormLabel>
              <RadioGroup
                id='therapy_billing_button'
                row
                aria-label='drug-Type'
                defaultValue={selectedValue?.billableCode?.code}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleBillingChange((event.target as HTMLInputElement).value)
                }}
              >
                <FormControlLabel
                  value='billable'
                  control={<Radio color='primary' />}
                  label='Billable'
                />
                <FormControlLabel
                  value='not-billable'
                  control={<Radio color='primary' />}
                  label='Not billable'
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#ECECEC' }}>
        <Box>
          <Button onClick={handleClose} variant='text' disableElevation>
            Close
          </Button>
          <Button
            variant='contained'
            disableElevation
            onClick={() => {
              handleSubmit()
            }}
            id='Therapy Update'
          >
            Update
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export function DeleteTherapy(props: DeleteTherapyProps) {
  const { open, onCancelClick: onClose } = props

  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  const handleClose = React.useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Dialog
      open={open}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
      onClose={() => {
        onClose()
      }}
      PaperProps={{
        style: {
          backgroundColor: '#ECECEC',
          boxShadow: 'none',
        },
      }}
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:Delete Therapy')}
      </DialogTitle>
      <DialogContent>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          width='100%'
        >
          <Box display='flex' px={2} py={1} width='100%'>
            <Typography>
              Are you sure you want to delete the Therapy?
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#ECECEC' }}>
        <Box>
          <Button onClick={handleClose} variant='text' disableElevation>
            Close
          </Button>
          <Button variant='contained' disableElevation>
            Delete
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
