import {
  GroupTypeKind,
  IBundle,
  IBundle_Entry,
  ICoding,
  IGroup,
  IGroup_Member,
  IHealthcareService,
  IOrganization,
  IPlanDefinition,
  IPractitioner,
  IPractitionerRole,
  ITask,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import axios, { CancelTokenSource } from 'axios'
import { iteratee } from 'lodash'
import { CareTeamMemberDetail } from 'models/administration/CareTeamMemberDetails'
import { IncomingCrossFunctionInvitation } from 'models/administration/IncomingCrossFunctionInvitaion'
import { InvitedMemberForCareFunction } from 'models/administration/InvitedMemberDetail'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { CrossOrgEnabledPractitioner } from 'models/practRoleResponse'
import { EnrolCient } from 'services/EnrrolmentClient'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserUnitDetails,
  getRolesAsStringFromPractRole,
} from 'services/userDetailsService'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultCodeOfSystemFromCodableConceptList,
  getExtensionValueOfUrl,
  getFullNameFromHumanName,
  getSpecializationsList,
  getSpecializationsString,
  titleCase,
} from 'utils/fhirResourcesHelper'
import { DoctorWithCareTeamRole } from 'views/components/administration/crossFunctionalCare/careTeam/inviteCareTeamMember/inviteCareTeamMemberPopUp'

export async function getCrossFunctionalStatusForUnit(
  unitId?: string
): Promise<IHealthcareService | boolean> {
  const unitIdToBeSearched = unitId ?? getCurrentUserUnitDetails()?.id

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses = await fhirClient.doGetResource(
    `/HealthcareService`,
    {
      'service-category': 'LP193697-2',
      'service-type': '711069006',
      organization: unitIdToBeSearched,
    }
  )

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  const resp: IBundle = response
  if (resp.entry && resp.entry.length === 0) {
    return false
  }
  const taskObject = resp.entry
    ?.filter((entry) => entry.resource?.resourceType === 'HealthcareService')
    .map((entry) => entry.resource as IHealthcareService)

  let returnResult: IHealthcareService | undefined
  if (taskObject && taskObject.length > 0) {
    returnResult = taskObject[0]
  }

  return returnResult ?? false
}

export async function enableSearchingOfCrossFunctional(): Promise<boolean> {
  const fhirClient: EnrolCient = new EnrolCient()
  const unitIdToBeSearched = getCurrentUserUnitDetails()?.id
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateEnrolmentFlowRequest(
      `/cross-org-services/enable-cross-org-service`,
      {
        serviceCategory: {
          coding: [
            {
              system: 'http://loinc.org',
              code: 'LP193697-2',
              display: 'Episode of care',
            },
          ],
        },
        serviceType: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '711069006',
              display: 'Coordination of care plan',
            },
          ],
        },

        unitId: unitIdToBeSearched,
        enable: true,
      }
    )

  const resp = response
  if (resp.type === 'FHIRErrorResponses') {
    return false
  }

  return true
}

export async function hideSearchingOfCrossFunctional(): Promise<boolean> {
  const fhirClient: EnrolCient = new EnrolCient()
  const unitIdToBeSearched = getCurrentUserUnitDetails()?.id
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateEnrolmentFlowRequest(
      `/cross-org-services/enable-cross-org-service`,
      {
        serviceCategory: {
          coding: [
            {
              system: 'http://loinc.org',
              code: 'LP193697-2',
              display: 'Episode of care',
            },
          ],
        },
        serviceType: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '711069006',
              display: 'Coordination of care plan',
            },
          ],
        },

        unitId: unitIdToBeSearched,
        enable: false,
      }
    )

  const resp = response
  if (resp.type === 'FHIRErrorResponses') {
    return false
  }

  return true
}

/// function to check if practitioner role has healthcare service
export function getCrossFunctionalStatusForPractitionerRole(
  practRole: IPractitionerRole,
  hcsId: string
): boolean {
  if (practRole.healthcareService) {
    const hcs = practRole.healthcareService
    const hcsIds = hcs.map((e) => e.reference?.split('/')[1] ?? '')
    if (hcsIds.includes(hcsId)) {
      return true
    }
  }
  return false
}

export async function updateCareStatusOfSelectedPractitioners(bodyPractitionerPreferences: {
  hcsId: string
  practitionerListWithInterest: {
    practitionerRoleId: string
    interest: boolean
  }[]
}): Promise<boolean> {
  const fhirClient: EnrolCient = new EnrolCient()

  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateEnrolmentFlowRequest(
      `/cross-org-services/add-provider-to-cross-org-service`,
      {
        crossOrgServiceId: bodyPractitionerPreferences.hcsId,
        updates: bodyPractitionerPreferences.practitionerListWithInterest.map(
          (e) => ({
            practitionerRoleId: e.practitionerRoleId,
            operation: e.interest ? 'include' : 'exclude',
          })
        ),
      }
    )

  const resp = response
  if (resp.type === 'FHIRErrorResponses') {
    return false
  }

  return true
}

export async function getAvailableProvidersForCareTeam(body: {
  practitionerName?: string
  organizationName?: string
  unitName?: string
}): Promise<CrossOrgEnabledPractitioner[] | boolean> {
  axios.CancelToken.source()
  if (cancelTokenStore.has('patientSearchControlToken')) {
    const controlTokenForSearch: CancelTokenSource = cancelTokenStore.get(
      'crossProviderSearchControlToken'
    )
    controlTokenForSearch.cancel('new param added')
    cancelTokenStore.delete('crossProviderSearchControlToken')
  }
  cancelTokenStore.set(
    'crossProviderSearchControlToken',
    axios.CancelToken.source()
  )
  const fhirClient: EnrolCient = new EnrolCient()
  const response: any | FHIRErrorResponses = await fhirClient.doGetResource(
    `/cross-org-services/get-cross-org-service-providers`,
    {
      category: 'LP193697-2',
      code: '711069006',

      practitionerName: body.practitionerName,
      organizationName: body.organizationName,
      unitName: body.unitName,
    },
    (
      cancelTokenStore.get(
        'crossProviderSearchControlToken'
      ) as CancelTokenSource
    ).token
  )

  const resp = response
  if (resp.type === 'FHIRErrorResponses') {
    return false
  }

  return response
}

export async function inviteDoctorForCareTeam(
  bodyPractitionerPreferences: DoctorWithCareTeamRole[]
): Promise<boolean> {
  const fhirClient: EnrolCient = new EnrolCient()

  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateEnrolmentFlowRequest(
      `/cross-org-services/invite-providers-to-cross-org-service`,
      {
        invitingProviders: bodyPractitionerPreferences.map((e) => ({
          invitingPractitionerOrgTenantId: e.doctor.organizationTenantId,
          invitingPractitionerRoleId: e.doctor.practitionerRoleId,
          rolesOffering: e.careTeamRole ?? [],
        })),
      }
    )

  const resp = response
  if (resp.type === 'FHIRErrorResponses') {
    return false
  }

  return true
}

export async function getInvitedMembersForTaskOfUnit(
  unitId?: string
): Promise<InvitedMemberForCareFunction[] | boolean> {
  const unitIdToBeSearched = unitId ?? getCurrentUserUnitDetails()?.id

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses = await fhirClient.doGetResource(
    `/Task`,
    {
      code: 'care-coordination-invitation-outgoing',
      'requester:PractitionerRole.organization': unitIdToBeSearched,
    }
  )

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  const resp: IBundle = response
  if (resp.entry && resp.entry.length === 0) {
    return false
  }
  const taskObject = resp.entry
    ?.filter((entry) => entry.resource?.resourceType === 'Task')
    .map((entry) => entry.resource as ITask)

  let returnResult: ITask[] | undefined
  if (taskObject && taskObject.length > 0) {
    returnResult = taskObject
  }
  const converetedList = returnResult?.map((e) =>
    convertTaskToCareTeamInvitedMember(e)
  )
  return converetedList ?? false
}

export function convertTaskToCareTeamInvitedMember(
  task: ITask
): InvitedMemberForCareFunction {
  const careTeamInvitedMember: InvitedMemberForCareFunction = {
    memberName: getFullNameFromHumanName(
      getPractitionerFromContainedTask(task)?.name ?? []
    ),
    memberUnit: getUnitFromContainedResources(task)?.name ?? '',
    memberOrganizations:
      getOrganizationFromContainedResources(task)?.name ?? '',
    memberSpecialty: getSpecializationsList(
      getPractitionerRoleFromContainedTask(task)?.specialty ?? []
    ),
    offeredRoles: titleCase(
      (getOfferedRoleFromTaskInput(task) ?? [])
        .map((e) => e.display ?? e.code ?? '')
        .join(', ') ?? ''
    ),
    invitationStatus: getStringStatusFromTaskStatus(task) ?? '',
    rawTask: task,
  }
  return careTeamInvitedMember
}

function getPractitionerRoleFromContainedTask(task: ITask) {
  if (task.contained && task.contained.length > 0) {
    const practitionerRole = task.contained.find(
      (e) => e.resourceType === 'PractitionerRole'
    ) as IPractitionerRole
    return practitionerRole
  }
  return undefined
}

function getPractitionerFromContainedTask(task: ITask) {
  if (task.contained && task.contained.length > 0) {
    const practitionerRole = task.contained.find(
      (e) => e.resourceType === 'Practitioner'
    ) as IPractitioner
    return practitionerRole
  }
  return undefined
}

function getUnitFromContainedResources(task: ITask) {
  if (task.contained && task.contained.length > 0) {
    const organization = task.contained.find(
      (e) =>
        e.resourceType === 'Organization' &&
        getDefaultCodeOfSystemFromCodableConceptList(
          e.type ?? [],
          'http://wellopathy.com/fhir/india/core/CodeSystem/org-type'
        ) === 'vendor-unit'
    ) as IOrganization
    return organization
  }
  return undefined
}

function getOrganizationFromContainedResources(task: ITask) {
  if (task.contained && task.contained.length > 0) {
    const organization = task.contained.find(
      (e) =>
        e.resourceType === 'Organization' &&
        getDefaultCodeOfSystemFromCodableConceptList(
          e.type ?? [],
          'http://wellopathy.com/fhir/india/core/CodeSystem/org-type'
        ) === 'vendor-org'
    ) as IOrganization
    return organization
  }
  return undefined
}

function getOfferedRoleFromTaskInput(task: ITask) {
  if (task.input && task.input.length > 0) {
    const offeredRole = task.input.find(
      (e) => e.type?.coding?.[0].code === 'offering-roles'
    )
    return offeredRole?.valueCodeableConcept?.coding
  }
  return undefined
}

function getStringStatusFromTaskStatus(task: ITask) {
  switch (task.status) {
    case 'draft':
      return 'Draft'
    case 'requested':
      return 'Pending'
    case 'received':
      return 'Received'
    case 'accepted':
      return 'Accepted'
    case 'rejected':
      return 'Rejected'
    case 'ready':
      return 'Ready'
    case 'cancelled':
      return 'Cancelled'
    case 'in-progress':
      return 'In Progress'
    case 'on-hold':
      return 'On Hold'
    case 'failed':
      return 'Failed'
    case 'completed':
      return 'Completed'
    case 'entered-in-error':
      return 'Entered In Error'
    default:
      return 'Unknown'
  }
  return undefined
}

export async function getCrossFunctionInvitationsOfUnit(
  unitId?: string
): Promise<IncomingCrossFunctionInvitation[] | boolean> {
  const unitIdToBeSearched = unitId ?? getCurrentUserUnitDetails()?.id

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses = await fhirClient.doGetResource(
    `/Task?_include:iterate=Task:*`,
    {
      code: 'care-coordination-invitation-incoming',
      'focus:PractitionerRole.organization': unitIdToBeSearched,
    }
  )

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  const resp: IBundle = response
  if (resp.entry && resp.entry.length === 0) {
    return false
  }
  const taskObject = resp.entry
    ?.filter((entry) => entry.resource?.resourceType === 'Task')
    .map((entry) => entry.resource as ITask)

  let returnResult: ITask[] | undefined
  if (taskObject && taskObject.length > 0) {
    returnResult = taskObject
  }
  const converetedList = returnResult?.map((e) =>
    convertTaskToIncomingInvitation(e, resp)
  )
  return converetedList ?? false
}

export function convertTaskToIncomingInvitation(
  task: ITask,
  bundle: IBundle
): IncomingCrossFunctionInvitation {
  const careTeamInvitedMember: IncomingCrossFunctionInvitation = {
    invitedMemberName: getFullNameFromHumanName(
      getFocusPractitionerForTaskFromBundle(task, bundle)?.name ?? []
    ),
    invitingMemberUnit: getUnitFromContainedResources(task)?.name ?? '',
    invitingMemberOrganization:
      getOrganizationFromContainedResources(task)?.name ?? '',
    memberSpecialty: getSpecializationsList(
      getPractitionerRoleFromContainedTask(task)?.specialty ?? []
    ),
    offeredRoles: (getOfferedRoleFromTaskInput(task) ?? [])
      .map((e) => e.display ?? '')
      .join(', '),
    invitationStatus: getStringStatusFromTaskStatus(task) ?? '',
    rawTask: task,
  }
  return careTeamInvitedMember
}

function getFocusPractitionerForTaskFromBundle(task: ITask, bundle: IBundle) {
  const practitionerRole = bundle.entry?.find(
    (e) =>
      e.resource?.resourceType === 'PractitionerRole' &&
      e.resource?.id === task.focus?.reference?.split('/')[1]
  )?.resource as IPractitionerRole

  console.log('-----------p-role----------------', practitionerRole)

  const practitioner = bundle.entry?.find(
    (e) =>
      e.resource?.resourceType === 'Practitioner' &&
      e.resource?.id === practitionerRole.practitioner?.reference?.split('/')[1]
  )?.resource as IPractitioner

  console.log('-----------practitioner----------------', practitioner)
  return practitioner
}

export async function updateInvitationResponseForInvitation(
  bodyPractitionerPreferences: IncomingCrossFunctionInvitation,
  invitationResponse: boolean,
  responseReason?: string
): Promise<boolean> {
  const fhirClient: EnrolCient = new EnrolCient()

  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateEnrolmentFlowRequest(
      `/cross-org-services/cross-org-service-invitation-response`,
      {
        invitationId: bodyPractitionerPreferences.rawTask.id,
        response: invitationResponse,
        responseReason,
      }
    )

  const resp = response
  if (resp.type === 'FHIRErrorResponses') {
    return false
  }

  return true
}

export async function getStandByCareTeamOfCarePlanOffer(
  planDefinition: IPlanDefinition
): Promise<IBundle | boolean> {
  const unitIdToBeSearched = getCurrentUserUnitDetails()?.id

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doGetResourceIncludeAndIncludeIterate(`/Group`, {
      identifier: planDefinition.name,
      type: 'practitioner',
      'managing-entity': `Organization/${unitIdToBeSearched}`,
      '_include:iterate': [
        'Group:member',
        'PractitionerRole:organization',
        'Organization:partof',
      ],
    })

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  const resp: IBundle = response
  if (resp.entry && resp.entry.length === 0) {
    return false
  }
  return resp
}

export const getCareTeamDetailsFromBundle = (
  inputBundle: IBundle
): CareTeamMemberDetail[] => {
  console.log(
    '-------------------Inside distillation function------------------------'
  )
  const groupResource: IGroup[] = []
  const practListWithRole: CareTeamMemberDetail[] = []
  const practitionerRoles: any = {}
  const practitioners: Map<string, IPractitioner> = new Map<
    string,
    IPractitioner
  >()

  const organizations: Map<string, IOrganization> = new Map<
    string,
    IOrganization
  >()

  if (inputBundle.total) {
    if (inputBundle.total > 0) {
      if (inputBundle.entry) {
        const entries: IBundle_Entry[] = inputBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Group':
                  groupResource.push(value.resource as IGroup)
                  break
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as IPractitionerRole
                  break
                case 'Practitioner':
                  practitioners.set(
                    value.resource.id,
                    value.resource as IPractitioner
                  )

                  break

                case 'Organization':
                  organizations.set(
                    value.resource.id,
                    value.resource as IOrganization
                  )

                  break

                default:
                  break
              }
            }
          }
        })
        console.log(
          '-------------------Inside distillation function middle------------------------'
        )

        if (groupResource.length > 0) {
          groupResource.forEach((group) => {
            group.member?.forEach((member) => {
              if (
                member.entity?.reference
                  ?.split('/')
                  .includes('PractitionerRole')
              ) {
                const practRole = practitionerRoles[
                  member.entity?.reference?.split('/')[1]
                ] as IPractitionerRole
                const practId =
                  practRole?.practitioner?.reference?.split('/')[1]
                const currentPract: IPractitioner | undefined =
                  practitioners.get(practId ?? '')

                const unitId = (
                  practRole as IPractitionerRole
                )?.organization?.reference?.split('/')[1]
                const unitOrg: IOrganization | undefined = organizations.get(
                  unitId ?? ''
                )
                const unitName = unitOrg?.name ?? ''

                const careTeamRoleExt = getExtensionValueOfUrl(
                  member.extension ?? [],
                  'http://wellopathy.com/care-team-role-codes'
                )
                const careTeamRoles =
                  careTeamRoleExt?.valueCodeableConcept?.coding ?? []

                const careTeamMember: CareTeamMemberDetail = {
                  memberId: practRole.id ?? '',
                  memberName: getFullNameFromHumanName(
                    currentPract?.name ?? []
                  ),
                  offeredRoles: careTeamRoles,
                  roleString: getRolesAsStringFromPractRole(practRole) ?? '',
                  role: practRole.code,
                  speciality: getSpecializationsList(practRole.specialty ?? []),
                  rawPractitioner: currentPract,
                  rawPractitionerRole: practRole,
                  rawOrganization: undefined,
                  unitName,
                }
                practListWithRole.push(careTeamMember)
              }
            })
          })
          return practListWithRole
        }
      }
    }
  }
  console.log(
    '-------------------Inside end distillation function error------------------------'
  )
  return []
}

export async function addStandByGroupToSelectedCarePlan(
  planDefinition: IPlanDefinition
): Promise<IGroup> {
  const currentUnit = getCurrentUserUnitDetails()
  const group: IGroup = {
    resourceType: 'Group',
    type: GroupTypeKind._practitioner,
    actual: true,
    name: `Stand by Care Team for ${planDefinition.title}`,
    identifier: [
      {
        system: 'http://wellopathy.com/care-paln-definition-id',
        value: planDefinition.name,
      },
    ],
    managingEntity: {
      id: currentUnit?.id,
      display: currentUnit?.name,
      type: 'Organization',
      reference: `Organization/${currentUnit?.id}`,
    },

    member: [],
  }

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateFHIRResourceRequest(
      `/${group.resourceType}`,
      group
    )

  return response as IGroup
}

export async function getAvailableCareMembers(
  existingMembers: CareTeamMemberDetail[]
): Promise<CareTeamMemberDetail[] | boolean> {
  const unitIdToBeSearched = getCurrentUserUnitDetails()?.id

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doGetResourceIncludeAndIncludeIterate(
      `/PractitionerRole`,
      {
        organization: `Organization/${unitIdToBeSearched}`,
        active: true,
        _include: [
          'PractitionerRole:practitioner',
          'PractitionerRole:organization',
        ],
        _count: 500,
        'practitioner:Practitioner._tag': 'profile_details_completed',
      }
    )

  const convetedRespOfPract = getAvailableTeamDetailsFromBundle(response)
  const convetedRespOfCrossCare = (await getCrossOrgTeamMembersOfUnit()) ?? []
  const totalMems = [...convetedRespOfPract, ...convetedRespOfCrossCare]

  const existingMembersIds = existingMembers.map((e) => e.memberId)
  const filteredMembers = totalMems.filter(
    (e) => !existingMembersIds.includes(e.memberId)
  )
  return filteredMembers
}

export const getAvailableTeamDetailsFromBundle = (
  inputBundle: IBundle
): CareTeamMemberDetail[] => {
  console.log(
    '-------------------Inside distillation function------------------------'
  )

  const practListWithRole: CareTeamMemberDetail[] = []
  const practitionerRoles: any = {}
  const practitioners: Map<string, IPractitioner> = new Map<
    string,
    IPractitioner
  >()

  const organizations: Map<string, IOrganization> = new Map<
    string,
    IOrganization
  >()

  if (inputBundle.total) {
    if (inputBundle.total > 0) {
      if (inputBundle.entry) {
        const entries: IBundle_Entry[] = inputBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as IPractitionerRole
                  break
                case 'Practitioner':
                  practitioners.set(
                    value.resource.id,
                    value.resource as IPractitioner
                  )

                  break

                case 'Organization':
                  organizations.set(
                    value.resource.id,
                    value.resource as IOrganization
                  )

                  break

                default:
                  break
              }
            }
          }
        })
        console.log(
          '-------------------Inside distillation function middle------------------------'
        )
        if (practitionerRoles) {
          for (const key in practitionerRoles) {
            if (key) {
              const practRole = practitionerRoles[key] as IPractitionerRole
              const practId = practRole?.practitioner?.reference?.split('/')[1]
              const currentPract: IPractitioner | undefined = practitioners.get(
                practId ?? ''
              )

              const unitId = (
                practRole as IPractitionerRole
              )?.organization?.reference?.split('/')[1]
              const unitOrg: IOrganization | undefined = organizations.get(
                unitId ?? ''
              )
              const unitName = unitOrg?.name ?? ''

              const careTeamMember: CareTeamMemberDetail = {
                memberId: practRole.id ?? '',
                memberName: getFullNameFromHumanName(currentPract?.name ?? []),
                offeredRoles: [],
                roleString: getRolesAsStringFromPractRole(practRole) ?? '',
                role: practRole.code,
                speciality: getSpecializationsList(practRole.specialty ?? []),
                rawPractitioner: currentPract,
                rawPractitionerRole: practRole,
                rawOrganization: undefined,
                unitName,
              }
              practListWithRole.push(careTeamMember)
            }
          }

          return practListWithRole
        }
      }
    }
  }
  console.log(
    '-------------------Inside end distillation function error------------------------'
  )
  return []
}

export async function getCrossOrgTeamMembersOfUnit() {
  const unitIdToBeSearched = getCurrentUserUnitDetails()?.id
  try {
    const fhirClient: FHIRApiClient = new FHIRApiClient()

    const crossCareResponse: any | FHIRErrorResponses =
      await fhirClient.doGetResourceIncludeAndIncludeIterate(
        `/PractitionerRole`,
        {
          'managing-unit-practrole': `Organization/${unitIdToBeSearched}`,
          _active: true,
          _include: [
            'PractitionerRole:practitioner',
            'PractitionerRole:organization',
          ],
          _count: 500,
        }
      )
    return getAvailableTeamDetailsFromBundle(crossCareResponse)
  } catch (error) {
    return undefined
  }
}

export async function addPersonsToStandByCareTeam(
  group: IGroup,
  personsWithInterest: { persons: CareTeamMemberDetail; interest: ICoding[] }[]
): Promise<boolean> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const careTeamMember: IGroup_Member[] = group.member ?? []

  personsWithInterest.forEach((person) => {
    const member: IGroup_Member = {
      entity: {
        reference: `PractitionerRole/${person.persons.rawPractitionerRole?.id}`,
      },
      extension: [
        {
          url: 'http://wellopathy.com/care-team-role-codes',
          valueCodeableConcept: {
            coding: person.interest,
          },
        },
      ],
    }
    careTeamMember.push(member)
    const practMember: IGroup_Member = {
      entity: {
        reference: `Practitioner/${person.persons.rawPractitioner?.id}`,
      },
      extension: [
        {
          url: 'http://wellopathy.com/care-team-role-codes',
          valueCodeableConcept: {
            coding: person.interest,
          },
        },
      ],
    }
    careTeamMember.push(practMember)
  })

  const response: any | FHIRErrorResponses =
    await fhirClient.doUpdateFHIRResourceRequest(
      `/${group.resourceType}/${group.id}`,
      { ...group, member: careTeamMember } as IGroup,
      group.meta?.versionId ?? ''
    )

  return response
}
