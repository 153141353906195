import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { getAllNutritionCatalogValueSets } from 'redux/valueSet/nutrition_catalogue/dietTableSearchSlice'
import { getAllNutritionValueSets } from 'redux/valueSet/nutrition_catalogue/nutrtionCatalogueSlice'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { getNameFromHumanName } from 'utils/fhirResourcesHelper'
import { getFhirTimingKinds } from 'utils/fhirResoureHelpers/catalogHelper'

import {
  AddMembershipDetailsStatus,
  MembershipCreateBody,
} from './addMembershipDetailsTypes'

const initialState: AddMembershipDetailsStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addMembershipDetailsSlice = createSlice({
  name: 'addMembershipDetailsSlice',
  initialState,
  reducers: {
    updateAddConditionStatus(
      state,
      action: PayloadAction<AddMembershipDetailsStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.allergy = action.payload.allergy
    },

    resetAllergiesDetails(
      state,
      action: PayloadAction<AddMembershipDetailsStatus>
    ) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.allergy = initialState.allergy
    },
  },
})

export const addMembershipDetails =
  (membershipCreateBody: MembershipCreateBody): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AddMembershipDetailsStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(
      addMembershipDetailsSlice.actions.updateAddConditionStatus(addingState)
    )
    try {
      const fhirClient: EnrolCient = new EnrolCient()
      const response: any = await fhirClient.doCreateEnrolmentFlowRequest(
        '/membership/create-new',
        membershipCreateBody
      )

      const respDecoded: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (respDecoded._tag === 'Right') {
        const patientResponse: R4.IBundle = respDecoded.right
        addingState = {
          adding: false,
          additionSuccessful: true,
          allergy: patientResponse,
          error: false,
          errorMessage: '',
        }
        dispatch(showSuccessAlert('Membership details created successfully'))

        dispatch(
          addMembershipDetailsSlice.actions.updateAddConditionStatus(
            addingState
          )
        )
      } else {
        const errorCreatePersonState: AddMembershipDetailsStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while adding membership details',
        }
        dispatch(
          addMembershipDetailsSlice.actions.updateAddConditionStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: AddMembershipDetailsStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while adding',
      }
      dispatch(
        addMembershipDetailsSlice.actions.updateAddConditionStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetAddmembershipState = () => (dispatch: AppDispatch) => {
  dispatch(
    addMembershipDetailsSlice.actions.resetAllergiesDetails(initialState)
  )
}

export default addMembershipDetailsSlice.reducer
