import {
  Avatar,
  Box,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { DataGrid, GridColDef } from '@material-ui/data-grid'
import { MoreVert } from '@material-ui/icons'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import React from 'react'
import {
  getAgeOfPatientData,
  getGenderOfPatient,
  getNameOfPatient,
  getProfilePicPatient,
  getTelecomOfPatient,
} from 'utils/fhirResourcesHelper'
import {
  getOrderFinalStatusText,
  getOrderTime,
  getOrderTypeDisplayCode,
  getTestsOfOrder,
} from 'utils/fhirResoureHelpers/labOrderHelpers'

export interface LabOrderDetailsForGrid {
  id: string
  Age: string
  Gender: string
  PatientName: string
  ProfilePic: string
  fhirOrderDetails: FhirLabOrderDetail
  PhoneNumber: string
  orderTime: string
  orderType: string
  tests: string
  status: string
}

const useStyles = makeStyles({
  root: {
    '& .column-style': {
      backgroundColor: '#ffffff',
    },
    display: 'flex',
  },

  root1: {
    '& .column-style1': {
      backgroundColor: 'red',
    },
  },
})

const columns: GridColDef[] = [
  {
    field: 'PatientName',

    width: 200,
    type: 'string',
    renderCell: (cellValues) => (
      <Box display='flex' alignItems='center' p={1}>
        <Box paddingRight={1}>
          <Avatar alt='Profile' src={cellValues.row.ProfilePic} />
        </Box>
        <Typography
          variant='subtitle2'
          color='initial'
          style={{
            fontFamily: 'Open Sans',
            fontSize: 12,

            fontWeight: 600,
            lineHeight: 'normal',
          }}
        >
          {cellValues.value}
        </Typography>
      </Box>
    ),
    renderHeader: () => (
      <Typography
        variant='subtitle2'
        // color='initial'
        style={{
          fontFamily: 'Open Sans',
          fontSize: 12,
          color: 'black',

          fontWeight: 'bold',
          lineHeight: 'normal',
        }}
      >
        {'Patient Name '}
      </Typography>
    ),
  },
  {
    field: 'orderTime',
    type: 'string',
    headerAlign: 'left',
    editable: false,

    width: 200,
    renderCell: (cellValues) => (
      <Box p={1}>
        <Typography
          variant='body1'
          color='initial'
          style={{
            wordWrap: 'break-word',
            whiteSpace: 'pre-line',
            fontFamily: 'Open Sans',
            fontSize: 12,

            fontWeight: 600,
            lineHeight: 'normal',
          }}
        >
          {cellValues.value}
        </Typography>
      </Box>
    ),
    renderHeader: () => (
      <Typography
        variant='subtitle2'
        // color='initial'
        style={{
          fontFamily: 'Open Sans',
          fontSize: 12,
          color: 'black',

          fontWeight: 'bold',
          lineHeight: 'normal',
        }}
      >
        {'Order Date and Time '}
      </Typography>
    ),
  },

  {
    field: 'orderType',
    type: 'string',
    headerAlign: 'left',
    editable: false,
    sortable: true,
    width: 160,
    renderCell: (cellValues) => (
      <Box p={1}>
        <Typography
          variant='subtitle2'
          color='initial'
          style={{
            fontFamily: 'Open Sans',
            fontSize: 12,

            fontWeight: 600,
            lineHeight: 'normal',
          }}
        >
          {cellValues.value}
        </Typography>
      </Box>
    ),
    renderHeader: () => (
      <Typography
        variant='subtitle2'
        // color='initial'
        style={{
          fontFamily: 'Open Sans',
          fontSize: 12,
          color: 'black',

          fontWeight: 'bold',
          lineHeight: 'normal',
        }}
      >
        Order Type
      </Typography>
    ),
  },
  {
    field: 'Age',
    type: 'string',
    headerAlign: 'left',
    editable: false,
    width: 80,
    sortable: true,
    renderCell: (cellValues) => (
      <Box paddingLeft={1}>
        {cellValues.value !== undefined && cellValues.value !== null && (
          <Typography
            variant='body1'
            color='initial'
            style={{
              wordWrap: 'break-word',
              whiteSpace: 'pre-line',
              fontFamily: 'Open Sans',
              fontSize: 12,

              fontWeight: 600,
              lineHeight: 'normal',
            }}
          >
            {cellValues.value}
          </Typography>
        )}
      </Box>
    ),
    renderHeader: () => (
      <Box>
        <Typography
          variant='subtitle2'
          // color='initial'
          style={{
            fontFamily: 'Open Sans',
            fontSize: 12,
            color: 'black',

            fontWeight: 'bold',
            lineHeight: 'normal',
          }}
        >
          Age
        </Typography>
      </Box>
    ),
  },
  {
    field: 'Gender',
    width: 100,
    editable: false,
    renderCell: (cellValues) => (
      <Box p={1}>
        {cellValues.value === 'male' && (
          <Box display='flex' alignItems='center'>
            <Typography
              variant='subtitle2'
              color='initial'
              style={{
                fontFamily: 'Open Sans',
                fontSize: 12,

                fontWeight: 600,
                lineHeight: 'normal',
              }}
              gutterBottom
            >
              &nbsp; M
            </Typography>
          </Box>
        )}
        {cellValues.value === 'female' && (
          <Box display='flex' alignItems='center'>
            <Typography
              variant='subtitle2'
              color='initial'
              style={{
                fontFamily: 'Open Sans',
                fontSize: 12,

                fontWeight: 600,
                lineHeight: 'normal',
              }}
              gutterBottom
            >
              &nbsp; F
            </Typography>
          </Box>
        )}
        {cellValues.value === 'other' && (
          <Box display='flex' alignItems='center'>
            <Typography
              variant='subtitle2'
              color='initial'
              style={{
                fontFamily: 'Open Sans',
                fontSize: 12,

                fontWeight: 600,
                lineHeight: 'normal',
              }}
              gutterBottom
            >
              &nbsp; O
            </Typography>
          </Box>
        )}
        {cellValues.value === 'unknown' && (
          <Box display='flex' alignItems='center'>
            <Typography
              variant='subtitle2'
              color='initial'
              style={{
                fontFamily: 'Open Sans',
                fontSize: 12,

                fontWeight: 600,
                lineHeight: 'normal',
              }}
              gutterBottom
            >
              &nbsp; U
            </Typography>
          </Box>
        )}
      </Box>
    ),
    renderHeader: () => (
      <Typography
        variant='subtitle2'
        // color='initial'
        style={{
          fontFamily: 'Open Sans',
          fontSize: 12,
          color: 'black',

          fontWeight: 'bold',
          lineHeight: 'normal',
        }}
      >
        {'Gender '}
      </Typography>
    ),
  },

  {
    field: 'tests',
    type: 'string',
    editable: false,
    headerAlign: 'left',
    width: 280,
    renderCell: (cellValues) => (
      <Box p={1}>
        <Typography
          variant='body1'
          style={{
            wordWrap: 'break-word',
            whiteSpace: 'pre-line',
            fontFamily: 'Open Sans',
            fontSize: 12,

            fontWeight: 600,
            lineHeight: 'normal',
          }}
        >
          {cellValues.value}
        </Typography>
      </Box>
    ),
    renderHeader: () => (
      <Typography
        variant='subtitle2'
        // color='initial'
        style={{
          fontFamily: 'Open Sans',
          fontSize: 12,
          color: 'black',

          fontWeight: 'bold',
          lineHeight: 'normal',
        }}
      >
        {'Tests / Packages '}
      </Typography>
    ),
  },
  {
    field: 'status',
    type: 'string',
    headerAlign: 'left',
    editable: false,
    width: 150,
    renderCell: (cellValues) => (
      <Box p={1}>
        <Typography
          variant='body1'
          style={{
            wordWrap: 'break-word',
            whiteSpace: 'pre-line',
            fontFamily: 'Open Sans',
            fontSize: 12,

            fontWeight: 600,
            lineHeight: 'normal',
          }}
          color={
            cellValues.value === 'Scheduled' || cellValues.value === 'completed'
              ? 'initial'
              : 'error'
          }
        >
          {cellValues.value}
        </Typography>
      </Box>
    ),
    renderHeader: () => (
      <Typography
        variant='subtitle2'
        // color='initial'
        style={{
          fontFamily: 'Open Sans',
          fontSize: 12,
          color: 'black',

          fontWeight: 'bold',
          lineHeight: 'normal',
        }}
      >
        Status
      </Typography>
    ),
  },
  {
    field: 'PhoneNumber',
    type: 'string',
    headerAlign: 'right',
    flex: 1,
    align: 'right',
    filterable: false,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    description: 'Options',
    editable: false,
    renderCell: (cellValues) => (
      <Box p={1} display='flex' flexDirection='row' justifyContent='flex-end'>
        <IconButton aria-label='option_order'>
          <MoreVert style={{ color: 'black' }} />
        </IconButton>
      </Box>
    ),
    renderHeader: () => <Typography variant='subtitle1' color='primary' />,
  },
]
/* const sortModel = [
  {
    field: 'PatientName',
    sort: 'asc' as GridSortDirection,
  },
] */
interface Props {
  preselectedOrderId?: string
  labOrders?: FhirLabOrderDetail[]
  onLabOrderSelected?: (selectedOrder: FhirLabOrderDetail) => void
}

export const LabOrderTabularView: React.FC<Props> = ({
  preselectedOrderId,
  labOrders,
  onLabOrderSelected,
}: Props) => {
  const classes = useStyles()
  const labOrderDetails: FhirLabOrderDetail[] = labOrders ?? []
  const [pageSize, setPageSize] = React.useState<number>(10)

  const convertedPatientData: LabOrderDetailsForGrid[] = labOrderDetails.map(
    (eachOrder) => {
      const val: LabOrderDetailsForGrid = {
        id: eachOrder?.serviceRequest.id ?? '',
        fhirOrderDetails: eachOrder,
        orderTime: getOrderTime(eachOrder) ?? '',
        orderType: getOrderTypeDisplayCode(eachOrder) ?? '',
        status: getOrderFinalStatusText(eachOrder),
        tests: getTestsOfOrder(eachOrder) ?? '',
        PatientName: getNameOfPatient(eachOrder.patient) ?? '',
        Age: getAgeOfPatientData(eachOrder.patient),
        Gender: getGenderOfPatient(eachOrder.patient),

        PhoneNumber: getTelecomOfPatient(eachOrder.patient),
        ProfilePic: getProfilePicPatient(eachOrder.patient),
      }
      return val
    }
  )

  const handlePageSizeChange = (params: any) => {
    setPageSize(params.pageSize)
  }

  const handleFilterModelChange = (params: any) => {}

  return (
    <Box style={{ flexGrow: 1 }} className={classes.root}>
      <DataGrid
        rows={convertedPatientData}
        density='standard'
        columns={columns}
        pageSize={pageSize}
        showColumnRightBorder={false}
        autoHeight
        scrollbarSize={1}
        getRowClassName={(params) => `column-style`}
        rowsPerPageOptions={[5, 10]}
        // disableMultipleSelection
        isCellEditable={(e) => false}
        showCellRightBorder={false}
        onPageSizeChange={handlePageSizeChange}
        onFilterModelChange={handleFilterModelChange}
        hideFooterRowCount={false}
        hideFooterSelectedRowCount={true}
      />
    </Box>
  )
}
