import { List, ListSubheader } from '@material-ui/core'
import {
  FhirAppointmentDetail,
  FhirPendingAppointmentDetails,
} from 'models/fhirAppointmentDetail'
import React from 'react'
import { getCompleteDateStringWithOutDay } from 'utils/dateUtil'
import {
  getDateWiseAppointments,
  getDateWisePendingAppointment,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import { AppointmentListDetailTile } from './appointmentListDetailTile'
import { PendingListDetailsTile } from './pendingListDetailsTile'

interface Props {
  doctorAppointments?: FhirPendingAppointmentDetails[]
  onAppointmentSelected?: (
    selectedAppointMent: FhirPendingAppointmentDetails
  ) => void

  dense?: boolean
}

export const FhirPendingListView: React.FC<Props> = ({
  doctorAppointments,
  onAppointmentSelected,
  dense = false,
}: Props) => (
  <List
    subheader={<li style={{ padding: 0, width: '100%' }} />}
    style={{ padding: 0, width: '100%', overflow: 'auto', height: '100%' }}
  >
    {dense}
    {getDateWisePendingAppointment(doctorAppointments ?? []).map((e) => (
      <li key={`section-${e.date ?? ''}`} style={{ padding: 0, width: '100%' }}>
        <ul style={{ padding: 0 }}>
          <ListSubheader
            style={{
              color: '#333333',
              fontWeight: 600,
              paddingLeft: 12,
              width: '100%',
            }}
          >
            {getCompleteDateStringWithOutDay(e.date ?? '')}
            {dense}
          </ListSubheader>
          {e.appointments?.map((item, index) => (
            <PendingListDetailsTile
              appointmentDetails={item}
              isOdd={index % 2 === 0}
              dense={dense}
              onTap={() => {
                if (onAppointmentSelected) {
                  onAppointmentSelected(item)
                }
              }}
              key={`lab_item_key${item.appointment.id}` ?? ''}
              index={index}
            />
          ))}
        </ul>
      </li>
    ))}
  </List>
)
