import { R4 } from '@ahryman40k/ts-fhir-types'

export const ProprioceptionObservationType: R4.ICoding[] = [
  {
    system: 'http://snomed.info/sct',
    code: '299943004',
    display: 'Normal',
  },
  {
    system: 'http://snomed.info/sct',
    code: '299942009',
    display: 'Abnormal',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-proprioception-finding-cs',
    code: 'other-proprioception-finding',
    display: 'Other',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
