import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { WelloUnits } from 'models/welloUnit'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getUserDetails,
  isOrgAdmin,
  isUnitAdmin,
} from 'services/userDetailsService'
import {
  getAddress,
  getCodeOfSystemFromCodableConceptList,
  getTelecomFromContactPoints,
} from 'utils/fhirResourcesHelper'
import { getWelloUnitsWithMainOrg } from 'utils/fhirResoureHelpers/organizationHelpers'
import { logger } from 'utils/logger'
import { SearchUnitStatus } from './unitsSearchStatus'

const initialState: SearchUnitStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const welloUnitsSearchSlice = createSlice({
  name: 'welloUnitsSearchSlice',
  initialState,
  reducers: {
    searchingPatientDetails(state, action: PayloadAction<SearchUnitStatus>) {},

    searchResults(state, action: PayloadAction<SearchUnitStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.unitsList = action.payload.unitsList
    },

    noDataFoundForSearch(state, action: PayloadAction<SearchUnitStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.unitsList = action.payload.unitsList
    },

    errorWhileSearching(state, action: PayloadAction<SearchUnitStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.unitsList = action.payload.unitsList
    },
  },
})

export const searchUnitsForWelloAdmin =
  (type: string): AppThunk =>
  async (dispatch: any) => {
    const errorFetchingUnits: SearchUnitStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      welloUnitsSearchSlice.actions.errorWhileSearching(errorFetchingUnits)
    )
    try {
      const mainOrganization: R4.IOrganization | undefined =
        getUserDetails()?.mainOrganization
      const searchParametes = {}
      //   if (mainOrganization) {
      //     searchParametes = {
      //       partof: `${mainOrganization?.resourceType}/${
      //         mainOrganization.id ?? ''
      //       }`,
      //       _count: 200,
      //     }
      //   }

      const fhirClient: MasterFhirClient = new MasterFhirClient()

      let response: any

      if (isOrgAdmin() || isUnitAdmin()) {
        response = await fhirClient.doGetResource(
          `/Organization?_count=300&type=${type}&_include=Organization:partof&_tag:not=mirror-resource`,
          ''
        )
      } else {
        response =
          await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
            `/Organization?_count=300&type=${type}&_include=Organization:partof&_tag:not=mirror-resource`,
            '',
            searchParametes
          )
      }

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const orgResponse: R4.IBundle = relatedFhirDecodeRes.right
        if (orgResponse.total) {
          if (orgResponse.total > 0) {
            if (orgResponse.entry) {
              const orgArray: R4.IOrganization[] = orgResponse.entry.map(
                (item) => item.resource as R4.IOrganization
              )
              const mainOrganizationArr: R4.IOrganization[] = orgArray.filter(
                (org: R4.IOrganization) => org.partOf === undefined
              )

              const unitOrgArray: R4.IOrganization[] = orgArray.filter(
                (org: R4.IOrganization) => org.partOf !== undefined
              )
              unitOrgArray.sort((a, b) =>
                (a.name ?? '')
                  .toLowerCase()
                  .localeCompare((b.name ?? '').toLowerCase())
              )

              const unitsArrData: WelloUnits[] = []

              await Promise.all(
                orgArray.map(async (e) => {
                  const data = await getOrgOnbardedDate(e)
                  unitsArrData.push(data)
                })
              )

              unitsArrData.sort((a, b) =>
                (a.orgDetails.name ?? '')
                  .toLowerCase()
                  .localeCompare((b.orgDetails.name ?? '').toLowerCase())
              )
              const unitsArrDataWithMainOrg: WelloUnits[] =
                getWelloUnitsWithMainOrg(mainOrganizationArr, unitsArrData)

              const fetchUnitListResult: SearchUnitStatus = {
                error: false,
                noResultsAvailable: false,
                resultsAvailable: true,
                searching: false,
                unitsList: unitsArrDataWithMainOrg,
              }
              dispatch(
                welloUnitsSearchSlice.actions.searchResults(fetchUnitListResult)
              )
              return
            }
          }
        }
        const noSearchResults: SearchUnitStatus = {
          error: false,
          noResultsAvailable: true,
          resultsAvailable: false,
          searching: false,
        }
        dispatch(
          welloUnitsSearchSlice.actions.noDataFoundForSearch(noSearchResults)
        )
        return
      }
      const errorWhileSearchPatient: SearchUnitStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error while searching',
      }
      dispatch(
        welloUnitsSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: SearchUnitStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        welloUnitsSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

async function getOrgOnbardedDate(org: R4.IOrganization): Promise<WelloUnits> {
  let date: string = ''
  const fhirClient: MasterFhirClient = new MasterFhirClient()
  let response: any
  if (isOrgAdmin() || isUnitAdmin()) {
    response = await fhirClient.doGetResource(
      `/Organization/${org.id}/_history/1`,
      ''
    )
  } else {
    response =
      await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
        `/Organization/${org.id}/_history/1`,
        ''
      )
  }

  logger.info('Org  Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IOrganization> =
    R4.RTTI_Organization.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const patResponse: R4.IOrganization = relatedFhirDecodeRes.right
    if (patResponse) {
      if (patResponse.meta && patResponse.meta.lastUpdated) {
        date = moment(patResponse.meta.lastUpdated).format('DD-MM-YYYY')
      }
    }
  }
  const unitData: WelloUnits = {
    orgDetails: org,
    onboardDate: date,
    orgName: org.name ?? '',
    address: getAddress(org.address).trim() ?? '',
    phone: org.contact
      ? getTelecomFromContactPoints(
          org.contact[0].telecom ?? [],
          ContactPointSystemKind._phone
        ) ?? ''
      : '',
    email: org.contact
      ? getTelecomFromContactPoints(
          org.contact[0].telecom ?? [],
          ContactPointSystemKind._email
        ) ?? ''
      : '',
    type:
      getCodeOfSystemFromCodableConceptList(
        org.type ?? [],
        'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
      )?.display ?? '',
    status: org.active && org.active === true ? 'Enabled' : 'Disabled',
  }
  return unitData
}

export default welloUnitsSearchSlice.reducer
