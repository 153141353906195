import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getNameFromHumanName,
  getNameOfPatient,
} from 'utils/fhirResourcesHelper'
import { getAppointmentSuccessfulMessage } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { logger } from 'utils/logger'
import { requestAppointmentsCountForToday } from '../appointmentCount/appointmentCountSlice'
import {
  RescheduleActions,
  RescheduleAppointmentManagementStatus,
} from './reschedulingAppointmentManagerStatus'

const initialState: RescheduleAppointmentManagementStatus = {
  currentAction: RescheduleActions.SlotSelection,
  enableContinueButton: false,
  creatingAppointment: false,
  errorWhileCreatingAppointment: false,
  appointmentCreatedSuccessfully: false,
}

const reschedulingAppointmentManagerSlice = createSlice({
  name: 'reschedulingAppointmentManager',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<RescheduleAppointmentManagementStatus>
    ) {
      state.currentAction = action.payload.currentAction
      state.enableContinueButton = action.payload.enableContinueButton
      state.selectedPatient = action.payload.selectedPatient
      state.selectedSlot = action.payload.selectedSlot
      state.errorReason = action.payload.errorReason
      state.errorWhileCreatingAppointment =
        action.payload.errorWhileCreatingAppointment
      state.creatingAppointment = action.payload.creatingAppointment
      state.createdAppointment = action.payload.createdAppointment
      state.appointmentCreatedSuccessfully =
        action.payload.appointmentCreatedSuccessfully
    },
  },
})

export const onSlotSelected =
  (
    activeState: RescheduleAppointmentManagementStatus,
    selectedSlot: FhirSlotDetail
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: RescheduleAppointmentManagementStatus = {
      currentAction: RescheduleActions.SlotSelection,
      enableContinueButton: true,
      selectedSlot,
      selectedPatient: activeState.selectedPatient,
      creatingAppointment: false,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
    }
    dispatch(reschedulingAppointmentManagerSlice.actions.updatedStatus(state))
  }

export const onPatientSelected =
  (
    activeState: RescheduleAppointmentManagementStatus,
    selectedPatient: R4.IPatient
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: RescheduleAppointmentManagementStatus = {
      currentAction: RescheduleActions.Confirmation,
      enableContinueButton: true,
      selectedPatient,
      selectedSlot: activeState.selectedSlot,
      creatingAppointment: false,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
    }
    dispatch(reschedulingAppointmentManagerSlice.actions.updatedStatus(state))
  }

export const continueButtonClicked =
  (activeState: RescheduleAppointmentManagementStatus): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (activeState.currentAction === RescheduleActions.SlotSelection) {
      const state: RescheduleAppointmentManagementStatus = {
        currentAction: RescheduleActions.Confirmation,
        enableContinueButton: false,
        selectedPatient: activeState.selectedPatient,
        selectedSlot: activeState.selectedSlot,
        creatingAppointment: false,
        errorWhileCreatingAppointment: false,
        appointmentCreatedSuccessfully: false,
      }
      dispatch(reschedulingAppointmentManagerSlice.actions.updatedStatus(state))
    }
  }
export const disableButtonClicked =
  (activeState: RescheduleAppointmentManagementStatus): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (activeState.currentAction === RescheduleActions.SlotSelection) {
      const state: RescheduleAppointmentManagementStatus = {
        currentAction: activeState.currentAction,
        enableContinueButton: false,
        selectedPatient: activeState.selectedPatient,
        selectedSlot: activeState.selectedSlot,
        creatingAppointment: activeState.creatingAppointment,
        errorWhileCreatingAppointment:
          activeState.errorWhileCreatingAppointment,
        appointmentCreatedSuccessfully:
          activeState.appointmentCreatedSuccessfully,
      }
      dispatch(reschedulingAppointmentManagerSlice.actions.updatedStatus(state))
    }
  }

export const resetRescheduleAppointmentState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: RescheduleAppointmentManagementStatus = {
      currentAction: RescheduleActions.SlotSelection,
      enableContinueButton: false,
      selectedPatient: undefined,
      selectedSlot: undefined,
      creatingAppointment: false,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
    }
    dispatch(reschedulingAppointmentManagerSlice.actions.updatedStatus(state))
  }

export const resetAppointmentStateForModifySlot =
  (
    activeState: RescheduleAppointmentManagementStatus,
    preselectedPatient?: R4.IPatient
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: RescheduleAppointmentManagementStatus = {
      currentAction: RescheduleActions.SlotSelection,
      enableContinueButton: false,
      selectedPatient: undefined,
      selectedSlot: undefined,
      creatingAppointment: false,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
    }
    dispatch(reschedulingAppointmentManagerSlice.actions.updatedStatus(state))
  }

export const requestReschedulingAppointment =
  (
    selectedNewDoctorSlot: FhirSlotDetail,
    selectedPatient: R4.IPatient,
    oldAppointmentDetails: FhirAppointmentDetail,
    selectedServiceType: string,
    isPrePaid: boolean,

    rescheduleReason: R4.ICoding,
    errorReason?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: RescheduleAppointmentManagementStatus = {
      currentAction: RescheduleActions.Confirmation,
      enableContinueButton: false,
      creatingAppointment: true,
      errorWhileCreatingAppointment: false,
      appointmentCreatedSuccessfully: false,
    }
    dispatch(reschedulingAppointmentManagerSlice.actions.updatedStatus(state))
    try {
      const currentSlotState: R4.ISlot | undefined = await getSlotCurrentState(
        selectedNewDoctorSlot.slot
      )
      if (currentSlotState?.status === R4.SlotStatusKind._free) {
        const bundleObject: R4.IBundle = getAppointmentTransactionObject(
          currentSlotState,
          selectedPatient,
          selectedServiceType,
          selectedNewDoctorSlot,

          oldAppointmentDetails,
          rescheduleReason,
          errorReason
        )
        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response = await fhirApi.doCreateFHIRTransaction('', bundleObject)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          state.appointmentCreatedSuccessfully = true
          state.creatingAppointment = false

          dispatch(
            showSuccessAlert(
              getAppointmentSuccessfulMessage(
                selectedPatient,
                selectedNewDoctorSlot.practitioner,
                currentSlotState
              )
            )
          )
          dispatch(requestAppointmentsCountForToday())
          dispatch(
            reschedulingAppointmentManagerSlice.actions.updatedStatus(state)
          )
        }
      } else {
        // slot is occupied
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: RescheduleAppointmentManagementStatus = {
        currentAction: RescheduleActions.Confirmation,
        enableContinueButton: false,
        creatingAppointment: false,
        errorWhileCreatingAppointment: true,
        appointmentCreatedSuccessfully: false,
        errorReason: 'Error',
      }
      dispatch(
        reschedulingAppointmentManagerSlice.actions.updatedStatus(
          errorSearchDoctor
        )
      )
    }
  }

async function getSlotCurrentState(
  slot: R4.ISlot
): Promise<R4.ISlot | undefined> {
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response = await fhirApi.doGetResource(`/Slot/${slot.id}`)
  const relatedFhirDecodeRes: E.Either<Errors, R4.ISlot> =
    R4.RTTI_Slot.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const slotResponse: R4.ISlot = relatedFhirDecodeRes.right
    return slotResponse
  }
  return undefined
}

function getAppointmentTransactionObject(
  currentSlot: R4.ISlot,
  selectedPatient: R4.IPatient,
  selectedServiceType: string,
  selectedDoctorSlot: FhirSlotDetail,
  oldAppointmentDetails: FhirAppointmentDetail,
  rescheduleReason: R4.ICoding,
  errorReason?: string
): R4.IBundle {
  let appointmentType: string = ''
  const serviceTypeCodeable: R4.ICodeableConcept = {}
  const serviceTypeCodingList: React.SetStateAction<R4.ICoding[]> = []
  const serviceTypeList: React.SetStateAction<R4.ICodeableConcept[]> = []

  if (selectedServiceType === '556') {
    appointmentType = 'In-Person'
    const serviceTypeInPerson: R4.ICoding = {
      system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
      code: '556',
      display: 'Walk-in Centre /Non-Emergency',
    }
    serviceTypeCodingList.push(serviceTypeInPerson)
    serviceTypeCodeable.coding = serviceTypeCodingList
    serviceTypeList.push(serviceTypeCodeable)
  }

  if (selectedServiceType === '538') {
    const serviceTypeInPerson: R4.ICoding = {
      code: '538',
      display: 'Telephone Counselling',
      system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
    }
    serviceTypeCodingList.push(serviceTypeInPerson)
    serviceTypeCodeable.coding = serviceTypeCodingList
    serviceTypeList.push(serviceTypeCodeable)
    appointmentType = 'Phone'
  }

  if (selectedServiceType === 'video-counselling') {
    appointmentType = 'Video'
    const serviceTypeVideo: R4.ICoding = {
      system: 'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
      code: 'video-counselling',
      display: 'Video Counselling',
    }
    serviceTypeCodingList.push(serviceTypeVideo)
    serviceTypeCodeable.coding = serviceTypeCodingList
    serviceTypeList.push(serviceTypeCodeable)
  }
  const appointmentObject: R4.IAppointment = {
    resourceType: 'Appointment',
    slot: [
      {
        reference: `${currentSlot.resourceType}/${currentSlot.id}`,
        id: currentSlot.id,
        type: currentSlot.resourceType,
      },
    ],

    start: selectedDoctorSlot.slot.start,
    end: selectedDoctorSlot.slot.end,
    status: R4.AppointmentStatusKind._booked,
    specialty: selectedDoctorSlot.slot.specialty,
    serviceType: serviceTypeList,

    participant: [
      {
        required: R4.Appointment_ParticipantRequiredKind._required,
        status: R4.Appointment_ParticipantStatusKind._accepted,
        actor: {
          display: getNameOfPatient(selectedPatient),
          reference: `${selectedPatient.resourceType}/${selectedPatient.id}`,
          id: selectedPatient.id,
          type: selectedPatient.resourceType,
        },
      },
      {
        required: R4.Appointment_ParticipantRequiredKind._required,
        status: R4.Appointment_ParticipantStatusKind._accepted,
        actor: {
          display: getNameFromHumanName(
            selectedDoctorSlot.practitioner?.name ?? []
          ),
          reference: `${selectedDoctorSlot.practitioner?.resourceType}/${selectedDoctorSlot.practitioner?.id}`,
          id: selectedDoctorSlot.practitioner?.id,
          type: selectedDoctorSlot.practitioner?.resourceType,
        },
      },
      {
        required: R4.Appointment_ParticipantRequiredKind._required,
        status: R4.Appointment_ParticipantStatusKind._accepted,
        actor: {
          reference: `${selectedDoctorSlot.practitionerRole?.resourceType}/${selectedDoctorSlot.practitionerRole?.id}`,
          id: selectedDoctorSlot.practitionerRole?.id,
          type: selectedDoctorSlot.practitionerRole?.resourceType,
        },
      },
    ],
  }

  const modifiedSlot: R4.ISlot = currentSlot
  modifiedSlot.status = R4.SlotStatusKind._busy
  const matchString: string = `W/${JSON.stringify(
    modifiedSlot.meta?.versionId ?? ' '
  )}`
  const oldUpdatedAppointment: R4.IAppointment = {
    ...oldAppointmentDetails.appointment,
  }
  if (oldUpdatedAppointment.basedOn) {
    appointmentObject.basedOn = oldUpdatedAppointment.basedOn
    appointmentObject.comment = oldUpdatedAppointment.comment
  }
  const oldAppointmentMatchString: string = `W/${JSON.stringify(
    oldUpdatedAppointment.meta?.versionId ?? ' '
  )}`
  oldUpdatedAppointment.status = R4.AppointmentStatusKind._cancelled
  oldUpdatedAppointment.cancelationReason = {
    coding: [rescheduleReason],
  }
  if (errorReason) {
    oldUpdatedAppointment.patientInstruction = errorReason
  }
  let modifiedOldSlot: R4.ISlot = {
    resourceType: 'Slot',
    schedule: {},
  }
  if (oldAppointmentDetails.slot) {
    modifiedOldSlot = { ...oldAppointmentDetails.slot }
    modifiedOldSlot.status = R4.SlotStatusKind._free
  }
  const matchOldSlotString: string = `W/${JSON.stringify(
    modifiedOldSlot.meta?.versionId ?? ' '
  )}`

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `Appointment/${oldUpdatedAppointment.id}`,
        request: {
          ifMatch: oldAppointmentMatchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `${oldUpdatedAppointment.resourceType}/${oldUpdatedAppointment.id}`,
        },
        resource: oldUpdatedAppointment,
      },
      {
        fullUrl: `Slot/${modifiedOldSlot.id}`,
        request: {
          ifMatch: matchOldSlotString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `Slot/${modifiedOldSlot.id}`,
        },
        resource: modifiedOldSlot,
      },
      {
        fullUrl: `Slot/${currentSlot.id}`,
        request: {
          ifMatch: matchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `Slot/${currentSlot.id}`,
        },
        resource: modifiedSlot,
      },
      {
        fullUrl: 'Appointment',
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: 'Appointment',
        },
        resource: appointmentObject,
      },
    ],
  }

  return requestBundle
}

export default reschedulingAppointmentManagerSlice.reducer
