import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Button, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { logger } from 'utils/logger'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { AddressAddition } from 'views/components/patients/addressAddition'
import { AddressListSelector } from 'views/components/patients/addressListDisplay'
import { AddressTile } from 'views/components/patients/addressTile'

interface PatientAddressSelectorProps {
  patient: R4.IPatient
  preSelectedAddress: R4.IAddress
  onAddressSelected: (selectedAddress: R4.IAddress) => void
  onChangeAddress: () => void
}

export const PatientAddressSelector: React.FC<PatientAddressSelectorProps> = ({
  patient,
  preSelectedAddress,
  onAddressSelected,
  onChangeAddress,
}: PatientAddressSelectorProps) => {
  const dispatch = useDispatch()
  console.log(preSelectedAddress)
  const [isAddressEditMode, setIsAddressEditMode] = useState<boolean>(false)
  const [addressAdditionMode, setAddressAdditionMode] = useState<boolean>(false)
  const [selectedAddress, setSelectedAddress] =
    useState<R4.IAddress>(preSelectedAddress)
  const [allAddress, setAllAddress] = useState<R4.IAddress[]>(
    patient.address ?? []
  )
  const { t } = useTranslation()

  logger.info(selectedAddress)
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
          <Button
            onClick={() => {
              //   dispatch(searchLabOfferings())
            }}
          >
            Try again
          </Button>
        </div>
      )}
    >
      <Box display='flex' flexDirection='column' width='100%' my={1}>
        {isAddressEditMode === false && addressAdditionMode === false && (
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
          >
            <WelloFormItemLabel title='Sample Collection Address' />
            {!isAddressEditMode && (
              <Button
                id='but_lab_ord_cre_modify_pat'
                variant='text'
                color='primary'
                onClick={(e) => {
                  setIsAddressEditMode(true)
                  onChangeAddress()
                }}
              >
                Change
              </Button>
            )}
          </Box>
        )}
        {addressAdditionMode && (
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            width='100%'
          >
            <AddressAddition
              patient={patient}
              onAdditionSuccess={(e, changedPatient: R4.IPatient) => {
                setAllAddress(changedPatient.address ?? [])
                onAddressSelected(e)
                setSelectedAddress(e)
                setAddressAdditionMode(false)
              }}
              onCancelPressed={() => {
                setAddressAdditionMode(false)
                onAddressSelected(preSelectedAddress)
              }}
            />
          </Box>
        )}
        {!addressAdditionMode && (
          <Box display='flex' flexDirection='row' width='100%'>
            {selectedAddress && !isAddressEditMode && (
              <AddressTile addresses={selectedAddress} />
            )}

            {selectedAddress && isAddressEditMode && (
              <Box display='flex' flexDirection='column' width='100%'>
                <WelloFormItemLabel title='Select Address' />
                <AddressListSelector
                  addresses={allAddress}
                  onAddNewClicked={() => {
                    setAddressAdditionMode(true)
                  }}
                  onAddressSelected={(e) => {
                    if (!e.use) {
                      setAddressAdditionMode(true)
                      setIsAddressEditMode(false)
                      return
                    }
                    setSelectedAddress(e)
                    onAddressSelected(e)
                    setIsAddressEditMode(false)
                  }}
                  onClosed={() => {}}
                />
              </Box>
            )}

            {!selectedAddress && (
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
              >
                {!selectedAddress &&
                  (patient.address === undefined ||
                    patient.address.length === 0) && (
                    <Box display='flex' flexDirection='column'>
                      <Box display='flex' flexDirection='row'>
                        <Alert
                          variant='outlined'
                          severity='info'
                          style={{
                            padding: '2px',
                            margin: '4px',
                          }}
                        >
                          No Address is available for selected patient.
                        </Alert>
                      </Box>

                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='center'
                      >
                        <Button
                          variant='outlined'
                          color='primary'
                          onClick={() => {
                            setAddressAdditionMode(true)
                          }}
                        >
                          <Typography variant='body1' color='initial'>
                            Add Address
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </ErrorBoundary>
  )
}
