import { R4 } from '@ahryman40k/ts-fhir-types'
import { getIdentifierBySystemType } from 'utils/fhirResourcesHelper'

export function getPatientIdentifiersForSearch(patient: R4.IPatient) {
  let searchparams: string[] = []

  const offsetIdentifiers = getIdentifierBySystemType(
    patient.identifier ?? [],
    'http://wellopathy.com/fhir/india/core/id/offset-phone'
  )
  if (
    offsetIdentifiers &&
    offsetIdentifiers.value &&
    offsetIdentifiers.value.length > 0
  ) {
    let currentSearch = ''
    if (offsetIdentifiers.value) {
      if (offsetIdentifiers.system) {
        currentSearch = `${offsetIdentifiers.system}|`
      }
      currentSearch += offsetIdentifiers.value
    }
    searchparams.push(currentSearch)

    return searchparams.join(',')
  }
  const identifiers = [...(patient.identifier ?? [])]
    .filter((e) => e.value !== undefined && e.value?.length > 0)
    .filter((e) => e.system?.toLowerCase() !== 'mrn-tenant1')
    .filter(
      (e) =>
        e.system !==
        'http://wellopathy.com/fhir/india/core/sid/global-identifier'
    )
  if (identifiers.length > 0) {
    searchparams = identifiers.map((e) => {
      let currentSearch = ''
      if (e.value) {
        if (e.system) {
          currentSearch = `${e.system}|`
        }
        currentSearch += e.value
      }
      return currentSearch
    })
  }
  return searchparams.join(',')
}

export function getOnlyPhoneNumber(
  phoneNumberStr: string,
  countryCode: string = '+91'
): string {
  if (phoneNumberStr.startsWith(countryCode)) {
    // Remove the country code if it exists
    return phoneNumberStr.substring(countryCode.length)
  }
  return phoneNumberStr
}
