import axios, { AxiosInstance } from 'axios'
import { getAccessToken } from 'utils/authHelpers'

export class ApiClinetWithMasterToken {
  axiosInstance: AxiosInstance

  constructor(baseUrl: string) {
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      timeout: 90000,
    })

    this.axiosInstance.interceptors.request.use((config) => {
      if (getAccessToken() != null) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJraWQiOiI4SjhIMzZpalhudTFmUm5ZMkktMWlXbkFSNlRXWDVOMzIzc1hENTNVZElZIiwiYWxnIjoiUlMyNTYifQ.eyJfX2NsaWVudF9pZCI6InNlcnZpY2Uvd3NlIiwianRpIjoiRnpxci14dGtIZFlvd1liT3NFdmdYIiwic3ViIjoiSkx5UjN4eDVzZGhxSzd0QmdZNW83IiwiaWF0IjoxNTg2OTQxMTkyLCJleHAiOjE3OTk5OTQ3OTIsInNjb3BlIjoib3BlbmlkIGZoaXJVc2VyIGVtYWlsIHJvbGVzIG9mZmxpbmVfYWNjZXNzIiwiaXNzIjoiaHR0cHM6Ly9kZXYtYXV0aC53ZWxsb3BhdGh5LmNvbSIsImF1ZCI6InBsYXRmb3JtIiwiYXpwIjoibGFiYWRtaW53ZWIifQ.oJcOS3cxp41VMEWqSt3pHfw0Ig5089-VLDyc9pjz7uPQyCGNVxSrvKBNTVJ_yTibJSVOod5DipY0UjHd2VTYP6TdrvwMBbThXzkUQNmIwkkT14bPts920lgmepSkF71g2307rhEXmCdmrgHRzxG0htK2Bt3dTPdSD4JwLZfhLPWNpl7ZEQJOg73KHFcGxaPwe3tORrWZ9OgfEJOeCS5q5mj3e6nEKKU-TBt_TV_GqEgOANBjRg56NiPX8Ezt7FpLchxq21ONcGPUAfdOreZEQ9CWZq6nECngyHb9mxm3v0Jp3K7CCKOP5K1qIqsz5LtiRJDzynjPpMYykekRmkFPrA`,
          'Cache-Control': 'no-cache',
        }
      }

      return config
    })

    this.axiosInstance.interceptors.response.use((param) => ({
      ...param,
    }))
  }
}
