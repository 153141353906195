import { R4 } from '@ahryman40k/ts-fhir-types'
import ActionEuroSymbol from 'material-ui/svg-icons/action/euro-symbol'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import moment from 'moment'
import {
  ActionsEntity,
  CardsEntity,
  CDSResponse,
  SuggestionsEntity,
} from 'redux/patientMedicalHistory/cds_recommendations/cdsResponse'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserPractitionerRoleRef } from 'services/userDetailsService'
import {
  getDefaultCodeOfSystemFromCodableConceptList,
  getDisplayOfSystemFromCodableConcept,
  getIdentifierValueBySystem,
} from 'utils/fhirResourcesHelper'
import { getCarePlans } from 'utils/patientHelper/cds_helper'

export async function getCarePlanOfPatient(
  patientId: String,
  planDefinitionUrl: String,
  requestCareCreation?: boolean
): Promise<R4.ICarePlan | undefined> {
  let carePlan: R4.ICarePlan | undefined

  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const searchParameters: any = {
    patient: `Patient/${patientId}`,
    'instantiates-canonical:PlanDefinition.name': planDefinitionUrl,
    _sort: '-_lastUpdated',
  }

  const response: any = await fhirClient.doGetResource(
    '/CarePlan',
    searchParameters
  )

  const responseBundle: R4.IBundle = response as R4.IBundle
  if (responseBundle.entry && responseBundle.entry.length > 0) {
    carePlan = responseBundle.entry[0].resource as R4.ICarePlan
  }

  /* if (carePlan === undefined && requestCareCreation) {
    const enRolClient: EnrolCient = new EnrolCient()

    const subResponse = await enRolClient.doCreateEnrolmentFlowRequest(
      'careplan/request-booking',
      {
        patientId,
        carePlan: planDefinitionUrl,
        fromDate: `${moment(moment(new Date()).format('YYYY-MM-DD'))
          .startOf('day')
          .toISOString()}`,
        endDate: `${moment(
          moment(new Date()).add(50, 'day').format('YYYY-MM-DD')
        )
          .startOf('day')
          .toISOString()}`,
      }
    )

    const resultId = getIdOfFirstOccurrenceOfResource(
      subResponse as R4.IBundle,
      'CarePlan'
    )

    if (resultId) carePlan = await getCarePlanByIdCurrentState(resultId)
  } */

  return carePlan
}

export async function getCarePlanOfPatientWithCurrentPractitioner(
  patientId: String
): Promise<R4.ICarePlan | undefined> {
  let carePlan: R4.ICarePlan | undefined

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const practRoleRef = getCurrentUserPractitionerRoleRef()
  console.log('practRoleRef', practRoleRef?.reference?.split('/')[1])

  const searchParameters: any = {
    status: 'active',
    patient: `Patient/${patientId}`,
    'care-team:CareTeam.participant': practRoleRef?.reference?.split('/')[1],
  }

  const response: any = await fhirClient.doGetResource(
    '/CarePlan',
    searchParameters
  )

  const responseBundle: R4.IBundle = response as R4.IBundle
  if (responseBundle.entry && responseBundle.entry.length > 0) {
    carePlan = responseBundle.entry[0].resource as R4.ICarePlan
  }

  return carePlan
}

export async function getCarePlanByIdCurrentState(
  carePlanId: string
): Promise<R4.ICarePlan> {
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response = await fhirApi.doGetResource(`/CarePlan/${carePlanId}`)
  const relatedFhirDecodeRes = response as R4.ICarePlan
  return relatedFhirDecodeRes
}
export function mergeAndGetSingleCard(
  inputCdsResponse: CDSResponse
): CardsEntity | undefined {
  let entity: CardsEntity | undefined

  if (inputCdsResponse.cards && inputCdsResponse.cards?.length > 0) {
    entity = { ...inputCdsResponse.cards[0] }
    if (inputCdsResponse.cards.length > 1) {
      const remainingCars: CardsEntity[] = [...inputCdsResponse.cards].slice(1)
      remainingCars.forEach((eachCard) => {
        if (eachCard.suggestions && eachCard.suggestions.length > 0) {
          eachCard.suggestions.forEach((eachSuggestion) =>
            mergeSuggestions(entity!, eachSuggestion)
          )
        }
      })
    }
  }

  return entity
}

function mergeSuggestions(
  baseEntity: CardsEntity,
  incomingSuggestion: SuggestionsEntity
) {
  if (baseEntity.suggestions && baseEntity.suggestions.length > 0) {
    if (incomingSuggestion.actions && incomingSuggestion.actions.length > 0) {
      incomingSuggestion.actions.forEach((eachAction) => {
        mergeActions(baseEntity, eachAction)
      })
    }
  } else {
    baseEntity.suggestions = [incomingSuggestion]
  }
}

function mergeActions(baseEntity: CardsEntity, incomingAction: ActionsEntity) {
  if (baseEntity.suggestions && baseEntity.suggestions.length > 0) {
    if (
      baseEntity.suggestions[0].actions &&
      baseEntity.suggestions[0].actions.length > 0
    ) {
      mergeActionDetails(baseEntity.suggestions[0].actions[0], incomingAction)
    } else {
      baseEntity.suggestions[0].actions = [incomingAction]
    }
  }
}

function mergeActionDetails(
  baseEntityAction: ActionsEntity,
  incomingAction: ActionsEntity
) {
  if (baseEntityAction.resource) {
    const baseBundle: R4.IBundle = baseEntityAction.resource as R4.IBundle
    const incomingBundle: R4.IBundle = incomingAction.resource as R4.IBundle
    mergeActionBundles(baseBundle, incomingBundle)
  } else {
    baseEntityAction.resource = incomingAction.resource
    baseEntityAction.type = incomingAction.type
    baseEntityAction.description = incomingAction.description
  }
}

function mergeActionBundles(
  baseActionBundle: R4.IBundle,
  incomingActionBundle: R4.IBundle
) {
  if (baseActionBundle.entry && baseActionBundle.entry.length > 0) {
    if (incomingActionBundle.entry && incomingActionBundle.entry.length > 0) {
      incomingActionBundle.entry.forEach((e) => {
        switch (e.resource?.resourceType) {
          case 'CarePlan': {
            const indexOfBaseCarePlan = baseActionBundle.entry?.findIndex(
              (eachRes) => eachRes.resource?.resourceType === 'CarePlan'
            )
            if (indexOfBaseCarePlan && indexOfBaseCarePlan >= 0) {
              mergeActionEntryCarePlanEntity(
                baseActionBundle.entry![indexOfBaseCarePlan]
                  .resource as R4.ICarePlan,
                e.resource
              )
            } else {
              baseActionBundle.entry?.push(e)
            }

            break
          }

          case 'PlanDefinition': {
            const indexOfBaseCarePlan = baseActionBundle.entry?.findIndex(
              (eachRes) => eachRes.resource?.resourceType === 'PlanDefinition'
            )
            if (indexOfBaseCarePlan && indexOfBaseCarePlan >= 0) {
              mergeActionEntryPlanDefinitionEntity(
                baseActionBundle.entry![indexOfBaseCarePlan]
                  .resource as R4.IPlanDefinition,
                e.resource
              )
            } else {
              baseActionBundle.entry?.push(e)
            }

            break
          }

          case 'RequestGroup': {
            const indexOfBaseCarePlan = baseActionBundle.entry?.findIndex(
              (eachRes) => eachRes.resource?.resourceType === 'RequestGroup'
            )
            if (indexOfBaseCarePlan && indexOfBaseCarePlan >= 0) {
              mergeActionEntryRequestGroupEntity(
                baseActionBundle.entry![indexOfBaseCarePlan]
                  .resource as R4.IRequestGroup,
                e.resource
              )
            } else {
              baseActionBundle.entry?.push(e)
            }

            break
          }

          default:
            break
        }
      })
    }
  } else {
    baseActionBundle.entry = incomingActionBundle.entry
  }
}
function mergeActionEntryCarePlanEntity(
  baseCarePlanEntity: R4.ICarePlan,
  inputCarePlan: R4.ICarePlan
) {
  if (baseCarePlanEntity.contained && baseCarePlanEntity.contained.length > 0) {
    if (inputCarePlan.contained && inputCarePlan.contained.length > 0) {
      inputCarePlan.contained.forEach((eachRes) => {
        const res: R4.IBundle_Entry = eachRes as R4.IBundle_Entry
        switch (res.resource?.resourceType) {
          case 'Goal': {
            const indexOfRes = (
              baseCarePlanEntity.contained as R4.IBundle_Entry[]
            ).findIndex((eachReq) => {
              if (eachReq.resource?.resourceType === 'Goal') {
                return (
                  (eachReq.resource?.description?.text ?? '').length <
                  ((res.resource as R4.IGoal).description?.text ?? '').length
                )
              }
              return false
            })

            if (indexOfRes > -1) {
              const indexOfGoal = baseCarePlanEntity.contained?.findIndex(
                (e) => (e as R4.IBundle_Entry).resource?.resourceType === 'Goal'
              )

              ;(baseCarePlanEntity.contained![
                indexOfGoal!
              ] as R4.IBundle_Entry) = res
            }
            break
          }

          default:
            break
        }
      })
    }
  } else {
    baseCarePlanEntity.contained = inputCarePlan.contained
  }
}

function mergeActionEntryRequestGroupEntity(
  baseCarePlanEntity: R4.IRequestGroup,
  inputCarePlan: R4.IRequestGroup
) {
  if (baseCarePlanEntity.contained && baseCarePlanEntity.contained.length > 0) {
    if (inputCarePlan.contained && inputCarePlan.contained.length > 0) {
      inputCarePlan.contained.forEach((eachRes) => {
        const res: R4.IBundle_Entry = eachRes as R4.IBundle_Entry
        switch (res.resource?.resourceType) {
          case 'MedicationRequest': {
            const indexOfRes = (
              baseCarePlanEntity.contained as R4.IBundle_Entry[]
            ).findIndex((eachReq) => {
              if (eachReq.resource?.resourceType === 'MedicationRequest') {
                return (
                  getDisplayOfSystemFromCodableConcept(
                    eachReq.resource?.medicationCodeableConcept
                  ) ===
                  getDisplayOfSystemFromCodableConcept(
                    (res.resource as R4.IMedicationRequest)
                      .medicationCodeableConcept
                  )
                )
              }
              return false
            })

            if (indexOfRes === undefined || indexOfRes < 0) {
              ;(baseCarePlanEntity.contained as R4.IBundle_Entry[]).push(res)
            }
            break
          }
          case 'Appointment': {
            const indexOfRes = (
              baseCarePlanEntity.contained as R4.IBundle_Entry[]
            ).findIndex((eachReq) => {
              if (eachReq.resource?.resourceType === 'Appointment') {
                return (
                  getDefaultCodeOfSystemFromCodableConceptList(
                    eachReq.resource?.specialty ?? []
                  ) ===
                  getDefaultCodeOfSystemFromCodableConceptList(
                    (res.resource as R4.IAppointment).specialty ?? []
                  )
                )
              }
              return false
            })

            if (indexOfRes === undefined || indexOfRes < 0) {
              ;(baseCarePlanEntity.contained as R4.IBundle_Entry[]).push(res)
            }
            break
          }
          case 'PlanDefinition': {
            const indexOfRes = (
              baseCarePlanEntity.contained as R4.IBundle_Entry[]
            ).findIndex((eachReq) => {
              if (eachReq.resource?.resourceType === 'PlanDefinition') {
                return (
                  eachReq.resource?.identifier?.[0].value ===
                  (res.resource as R4.IPlanDefinition).identifier?.[0].value
                )
              }
              return false
            })
            if (indexOfRes === undefined || indexOfRes < 0) {
              ;(baseCarePlanEntity.contained as R4.IBundle_Entry[]).push(res)
            }
            break
          }

          default:
            ;(baseCarePlanEntity.contained as R4.IBundle_Entry[]).push(res)
            break
        }
      })
    }
  } else {
    baseCarePlanEntity.contained = inputCarePlan.contained
  }
}

function mergeActionEntryPlanDefinitionEntity(
  baseCarePlanEntity: R4.IPlanDefinition,
  inputCarePlan: R4.IPlanDefinition
) {}

export function getCommunicationResourceForFollowUp(
  appointmentDetails: FhirAppointmentDetail,
  payLoad: string,
  encounterId?: string
): R4.ICommunicationRequest {
  const output: R4.ICommunicationRequest = {
    resourceType: 'CommunicationRequest',
    payload: [
      {
        contentString: payLoad,
      },
    ],
    subject: {
      reference: `${
        appointmentDetails.patient.resourceType
      }/${appointmentDetails.patient.id!}`,
    },
    encounter: encounterId
      ? {
          reference: `Encounter/${encounterId}`,
        }
      : undefined,
    category: [
      {
        coding: [
          {
            code: 'instruction',
            display: 'Instruction',
            system: 'http://hl7.org/fhir/ValueSet/communication-category',
          },
        ],
        text: 'Instruction',
      },
    ],

    sender: {
      reference: `${
        appointmentDetails.practitionerDetail.practitionerRole.resourceType
      }/${appointmentDetails.practitionerDetail.practitionerRole.id!}`,
    },
    requester: {
      reference: `${
        appointmentDetails.practitionerDetail.practitionerRole.resourceType
      }/${appointmentDetails.practitionerDetail.practitionerRole.id!}`,
    },
  }

  return output
}

export function getIdOfCardEntry(cardEntry: CardsEntity): string | undefined {
  if (cardEntry.suggestions && cardEntry.suggestions.length > 0) {
    for (let index = 0; index < cardEntry.suggestions.length; index++) {
      const element = cardEntry.suggestions[index]
      if (element.actions && element.actions.length > 0) {
        for (let j = 0; j < element.actions?.length; j++) {
          const action = element.actions[j]
          const carePlans = getCarePlans(
            (action.resource as R4.IBundle).entry ?? []
          )

          let identifier: string | undefined
          for (let l = 0; l < carePlans.length; l++) {
            const cp = carePlans[l]

            identifier = getIdentifierValueBySystem(
              cp.identifier ?? [],
              'http://wellopathy.com/care-paln-definition-id'
            )
            if (identifier) {
              return identifier
            }
          }
        }
      }
    }
  }
  return undefined
}

export async function hasPlanOfferedInOrg(planUrl: string) {
  console.log('-----Plan def url.........', planUrl)
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    name: planUrl,
    status: 'active',
  }
  const response: any = await fhirClient.doGetResource(
    '/PlanDefinition',
    searchParameters
  )

  const bundle: R4.IBundle = response as R4.IBundle

  if (bundle.entry && bundle.entry.length > 0) {
    return true
  }

  return false
}

export function getCommunicationResourceForFollowUpForIPD(
  appointmentDetails: FhirActiveIPDDetailsForMedicalRole,
  payLoad: string,
  encounterId?: string
): R4.ICommunicationRequest {
  const output: R4.ICommunicationRequest = {
    resourceType: 'CommunicationRequest',
    payload: [
      {
        contentString: payLoad,
      },
    ],
    subject: {
      reference: `${
        appointmentDetails.patient.resourceType
      }/${appointmentDetails.patient.id!}`,
    },
    encounter: encounterId
      ? {
          reference: `Encounter/${encounterId}`,
        }
      : undefined,
    category: [
      {
        coding: [
          {
            code: 'instruction',
            display: 'Instruction',
            system: 'http://hl7.org/fhir/ValueSet/communication-category',
          },
        ],
        text: 'Instruction',
      },
    ],

    sender: {
      reference: `${
        appointmentDetails.practitionerDetail.practitionerRole.resourceType
      }/${appointmentDetails.practitionerDetail.practitionerRole.id!}`,
    },
    requester: {
      reference: `${
        appointmentDetails.practitionerDetail.practitionerRole.resourceType
      }/${appointmentDetails.practitionerDetail.practitionerRole.id!}`,
    },
  }

  return output
}
