/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { settingsBackgroundColor } from 'configs/styles/muiThemes'
import { PartnerLabTest } from 'models/partnerLabTestPrice'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchingTests } from 'redux/collectionCenter/SearchPartnerLabTest/partnerLabTestSearchSlice'
import { deletingCatalog } from 'redux/lab/Test/addTestCatalougeSlice'
import { RootState } from 'redux/rootReducer'
import { getPackageBundleTest } from 'utils/labHelpers/uploadHelper'
import { ConfirmationDialog } from '../common/confirmationDialog'
import { AddPartnerLabTest } from './addPartnerLabTest'
import { EditTest } from './editTest'

interface Props {
  partnerLabLocation?: R4.ILocation
}

export const PartnerLabTestListView: React.FC<Props> = ({
  partnerLabLocation,
}: Props) => {
  const partnerLabTestSearchSlice = useSelector(
    (state: RootState) => state.partnerLabTestSearchSlice
  )
  const dispatch = useDispatch()
  const [partnerLabTest, setPartnerLabTest] = useState<PartnerLabTest[]>([])
  const [showPopup, setShowPopup] = React.useState<boolean>(false)
  const [showAddPopUp, setShowAddPopup] = React.useState<boolean>(false)
  const showEditPopUp = useRef<boolean>(false)
  const [partnerTest, setPartnerTest] = React.useState<PartnerLabTest>()
  const [deletePackage, setDeletePackage] = useState(false)

  useEffect(() => {
    dispatch(searchingTests(partnerLabLocation?.id ?? '', ''))
    showEditPopUp.current = false
    return () => {}
  }, [])

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      flexGrow={1}
      height='100%'
    >
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        bgcolor={settingsBackgroundColor}
      >
        <Grid
          container
          direction='row'
          alignContent='space-between'
          justify='space-between'
        >
          <Grid item>
            <Grid container direction='column' justify='flex-start'>
              <Grid item xs>
                <Box p={1}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box display='flex' paddingY={1}>
                      <TextField
                        variant='outlined'
                        onChange={(event) => {
                          dispatch(
                            searchingTests(
                              partnerLabLocation?.id ?? '',
                              event.target.value
                            )
                          )
                          //  setSearchTextSearch(event.target.value)
                        }}
                        size='small'
                        autoFocus={true}
                        placeholder='Search By Test Name'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <img
                                src={`${process.env.PUBLIC_URL}/search.png`}
                                alt='s'
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              direction='column'
              alignContent='space-between'
              justify='space-between'
            >
              <Grid item xs style={{ marginTop: 17 }} />
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            alignItems='center'
            style={{
              alignSelf: 'center',
            }}
          >
            <Box
              display='flex'
              width='100%'
              flexDirection='row'
              justifyContent='flex-end'
              paddingRight={1}
            >
              <Tooltip title='Add Test'>
                <IconButton
                  aria-label='btn_ord_cancel'
                  color='primary'
                  onClick={() => {
                    setShowAddPopup(true)
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/labAdd.png`} alt='add' />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        display='flex'
        flexGrow={1}
        flexDirection='column'
        justifyContent='center'
        alignContent='center'
        overflow='auto'
        paddingTop={1}
      >
        {partnerLabTestSearchSlice.searching && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
          >
            <CircularProgress size={25} />
          </Box>
        )}
        {partnerLabTestSearchSlice.error && (
          <Typography>
            {' '}
            {partnerLabTestSearchSlice.errorMessage ?? 'Error while searching'}
          </Typography>
        )}
        {partnerLabTestSearchSlice.noResultsAvailable &&
          partnerLabTestSearchSlice.partnerLabTestDetails?.length === 0 && (
            <Box px={1}>
              <Typography> No Test(s) found</Typography>
            </Box>
          )}
      </Box>
      {partnerLabTestSearchSlice.resultsAvailable &&
        partnerLabTestSearchSlice.partnerLabTestDetails && (
          <Box>
            {partnerLabTestSearchSlice.partnerLabTestDetails.length === 0 && (
              <Box px={1}>
                <Typography color='initial' align='center' variant='subtitle1'>
                  {' '}
                  No Test(s) available
                </Typography>
              </Box>
            )}
            {partnerLabTestSearchSlice.partnerLabTestDetails.map(
              (item, index) => (
                <Box bgcolor={index % 2 === 0 ? undefined : '#F9F9F9'} py={1}>
                  <Grid
                    container
                    direction='row'
                    alignContent='space-between'
                    justify='space-between'
                  >
                    <Grid item>
                      <Grid container direction='column' justify='flex-start'>
                        <Grid item xl>
                          <Box
                            display='flex'
                            width='100%'
                            flexDirection='column'
                            justifyContent='flex-start'
                            p={1}
                          >
                            <Box>
                              <Typography color='primary' variant='subtitle1'>
                                {item.testName}
                              </Typography>
                            </Box>
                            <Box py={0.3}>
                              <Typography
                                color='textSecondary'
                                variant='subtitle2'
                              >
                                Partner Lab Rate : {item.partnerLabPrice}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        direction='column'
                        alignContent='space-between'
                        justify='space-between'
                      >
                        <Grid item xs style={{ marginTop: 17 }} />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      alignItems='center'
                      style={{
                        alignSelf: 'center',
                      }}
                    >
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='row'
                        justifyContent='flex-end'
                      >
                        <Box paddingTop={1.2}>
                          <Tooltip title='Edit'>
                            <IconButton
                              aria-label='btn_ord_reschedule'
                              color='primary'
                              onClick={() => {
                                setShowPopup(true)
                                // setShowEditPopUp(true)
                                setPartnerTest(item)
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/editIcon.png`}
                                alt='edit'
                                height={20}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Box paddingTop={1.2}>
                          <Tooltip title='Delete'>
                            <IconButton
                              aria-label='btn_ord_cancel'
                              color='primary'
                              onClick={() => {
                                setDeletePackage(true)
                                setPartnerTest(item)
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/remove.png`}
                                alt='remove'
                                height={20}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )
            )}
          </Box>
        )}

      <AddPartnerLabTest
        open={showAddPopUp}
        onDiscard={() => {
          setShowAddPopup(false)
        }}
        onClose={() => {
          setShowAddPopup(false)
        }}
        locationId={partnerLabLocation?.id ?? ''}
        errors={false}
      />

      <ConfirmationDialog
        open={deletePackage}
        displayText='Test will be deleted permanently. Do you want to continue?'
        title='Delete Test'
        doneButtonText='Remove'
        cancelButtonText='Back'
        onClose={() => {
          setDeletePackage(false)
        }}
        onDiscard={() => {
          if (partnerTest) {
            const bundleData = getPackageBundleTest(partnerTest)

            if (bundleData) {
              dispatch(
                deletingCatalog(
                  bundleData,
                  partnerLabLocation?.id ?? '',
                  'Test'
                )
              )
            }
          }

          setDeletePackage(false)
        }}
      />

      <EditTest
        open={showPopup}
        onDiscard={() => {
          setShowPopup(false)
        }}
        onClose={() => {
          setShowPopup(false)
        }}
        locationId={partnerLabLocation?.id ?? ''}
        partnerLabTest={partnerTest}
      />
    </Box>
  )
}
