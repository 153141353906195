/// get specific cpg selection context type sections

import { CPGSelections } from 'views/contexts/cpgContextSelection'

export function getSelectionContextTypeSections({
  cpgIdentifier,
  selectionData,
  selectionCategoryType,
}: {
  cpgIdentifier: string
  selectionData: CPGSelections[]
  selectionCategoryType: string
}): string[] {
  const cpgSelection = selectionData.find(
    (selection) => selection.cpgIdentifier === cpgIdentifier
  )
  if (!cpgSelection) return []

  const selectionContextTypeSections = cpgSelection.selectedData.find(
    (data) => data.type === selectionCategoryType
  )

  if (!selectionContextTypeSections) return []

  return selectionContextTypeSections.selectedItemsIdentifiers ?? []
}
