import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { Errors } from 'io-ts'
import * as E from 'fp-ts/lib/Either'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { getInstructionsOfAppointment } from 'redux/consultation/instructions_list_slice/instructionsListSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  addAndGetEncounterIdOfAppointment,
  getCommunicationRequest,
} from 'utils/appointment_handle/cds_recommendations_util'
import {
  getCarePlanOfPatient,
  getCommunicationResourceForFollowUp,
  hasPlanOfferedInOrg,
} from 'utils/careplan_utils/careplan_utils'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { logger } from 'utils/logger'
import { getVendorPartId } from 'utils/routes_helper'
import {
  getCommunicationResourceCheck,
  getCommunicationResourceCheckIPD,
} from 'utils/instructionUtils/instructionUtils'
import { sleep } from 'utils/dateUtil'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { getInstructionsOfIPD } from 'redux/ipd/instructions_list_slice_ipd/instructionsListSliceIPD'
import { InstructionAdditionStatus } from './instructionAdditionState'

const initialState: InstructionAdditionStatus = {
  addingInstruction: false,
  additionSuccessful: false,
  noResultsAvailable: false,
  errorWhileAdding: false,
}

const instructionAdditionSliceIPD = createSlice({
  name: 'instructionAdditionSliceIPD',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<InstructionAdditionStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.addingInstruction = action.payload.addingInstruction
      state.additionSuccessful = action.payload.additionSuccessful
      state.successMessage = action.payload.successMessage
      state.errorReason = action.payload.errorReason
      state.errorWhileAdding = action.payload.errorWhileAdding
    },

    resetAddVitalsDetails(
      state,
      action: PayloadAction<InstructionAdditionStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.addingInstruction = action.payload.addingInstruction
      state.additionSuccessful = action.payload.additionSuccessful
      state.successMessage = action.payload.successMessage
      state.errorReason = action.payload.errorReason
      state.errorWhileAdding = action.payload.errorWhileAdding
    },
  },
})

export const resetAddS = () => (dispatch: AppDispatch) => {
  dispatch(
    instructionAdditionSliceIPD.actions.resetAddVitalsDetails(initialState)
  )
}

export const addInstructionsForIPD =
  (
    patient: R4.IPatient,
    communications: R4.ICommunicationRequest[],
    appointment: FhirActiveIPDDetailsForMedicalRole,
    carePlanUrl?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: InstructionAdditionStatus = {
      ...initialState,
    }
    state.addingInstruction = true
    dispatch(instructionAdditionSliceIPD.actions.updatedStatus(state))
    try {
      let hasPlanDef = false
      if (carePlanUrl) hasPlanDef = await hasPlanOfferedInOrg(carePlanUrl)
      if (hasPlanDef) {
        const carePlanOfPatient: R4.ICarePlan | undefined =
          await getCarePlanOfPatient(patient.id!, carePlanUrl ?? '', true)
        if (carePlanOfPatient) {
          const encounterId: string | undefined = appointment.mainEncounter?.id
          const requestCommunication: R4.ICommunicationRequest | undefined =
            await getCommunicationResourceCheckIPD(
              carePlanOfPatient!.id!,
              appointment.mainServiceRequest.id ?? ''
            )
          if (requestCommunication) {
            const oldCommunicationRequest: R4.ICommunicationRequest = {
              ...requestCommunication,
            }

            oldCommunicationRequest.payload = communications[0].payload
            const fhirClient: FHIRApiClient = new FHIRApiClient()

            const response: any = await fhirClient.doUpdateFHIRResourceRequest(
              `/CommunicationRequest/${oldCommunicationRequest.id}` ?? '',
              oldCommunicationRequest,
              oldCommunicationRequest.meta?.versionId ?? ''
            )
            const res: R4.ICommunicationRequest =
              response as R4.ICommunicationRequest

            state.addingInstruction = false
            state.additionSuccessful = false
            state.errorWhileAdding = false
            state.successMessage =
              'Instructions have been updated in care portal.'
            state.errorReason = undefined
            dispatch(
              showSuccessAlert('Instructions have been updated in care portal.')
            )
            dispatch(instructionAdditionSliceIPD.actions.updatedStatus(state))
          } else {
            const responses: AxiosResponse[] | undefined =
              await addInstructionsApiCallsForIPD(
                communications,
                appointment.patient,
                appointment,
                carePlanOfPatient!.id!,
                encounterId!
              )
            if (responses) {
              let isSucess: boolean = true
              responses.forEach((e) => {
                if (e.status !== 200 && e.status !== 201) {
                  isSucess = false
                }
              })
              await sleep(5000)
              if (isSucess) {
                state.addingInstruction = false
                state.additionSuccessful = true
                state.errorWhileAdding = false
                state.successMessage =
                  'Instructions have been updated in care portal.'
                state.errorReason = undefined
                dispatch(
                  showSuccessAlert(
                    'Instructions have been updated in care portal.'
                  )
                )

                setTimeout(() => {
                  dispatch(getInstructionsOfIPD(appointment))
                }, 800)
                dispatch(
                  instructionAdditionSliceIPD.actions.updatedStatus(state)
                )
              } else {
                let isPartial: boolean = false
                responses.forEach((e) => {
                  if (e.status === 200 || e.status === 201) {
                    isPartial = true
                  }
                })

                if (isPartial) {
                  state.addingInstruction = false
                  state.additionSuccessful = false
                  state.errorWhileAdding = true
                  state.successMessage = undefined
                  state.errorReason =
                    'Added few of the Instructions. Check Plan sections'
                  dispatch(
                    showSuccessAlert(
                      'Added few of the Instructions. Check Plan sections'
                    )
                  )
                  dispatch(
                    instructionAdditionSliceIPD.actions.updatedStatus(
                      initialState
                    )
                  )
                  setTimeout(() => {
                    dispatch(getInstructionsOfIPD(appointment))
                  }, 800)
                } else {
                  state.addingInstruction = false
                  state.additionSuccessful = false
                  state.errorWhileAdding = true
                  state.successMessage = undefined
                  state.errorReason =
                    'Error while adding Instructions. Try later'
                  dispatch(
                    instructionAdditionSliceIPD.actions.updatedStatus(state)
                  )
                }
              }
            }
          }
        } else {
          state.addingInstruction = false
          state.additionSuccessful = false
          state.errorWhileAdding = true
          state.successMessage = undefined
          state.errorReason = 'User is not subscribed to Care Plan'
          dispatch(instructionAdditionSliceIPD.actions.updatedStatus(state))
        }
      } else {
        const encounterId: string | undefined = appointment.mainEncounter?.id

        const bundle: R4.IBundle = {
          resourceType: 'Bundle',
          type: R4.BundleTypeKind._transaction,
          entry: communications.map(
            (e) =>
              ({
                request: {
                  method: R4.Bundle_RequestMethodKind._post,
                  url: 'CommunicationRequest',
                },
                resource: getCommunicationRequest(
                  e,
                  encounterId ?? '',
                  patient.id ?? ''
                ),
              } as R4.IBundle_Entry)
          ),
        }

        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response = await fhirApi.doCreateFHIRTransaction('', bundle)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          state.addingInstruction = false
          state.additionSuccessful = true
          state.errorWhileAdding = false
          state.successMessage =
            'Instructions have been updated in care portal.'
          state.errorReason = undefined
          dispatch(
            showSuccessAlert('Instructions have been updated in care portal.')
          )
          dispatch(
            instructionAdditionSliceIPD.actions.updatedStatus(initialState)
          )
        } else {
          state.addingInstruction = false
          state.additionSuccessful = false
          state.errorWhileAdding = true
          state.successMessage = undefined
          state.errorReason = 'Error while adding '
          dispatch(instructionAdditionSliceIPD.actions.updatedStatus(state))
        }

        setTimeout(() => {
          dispatch(getInstructionsOfIPD(appointment))
        }, 800)
      }
    } catch (error) {
      const resState: InstructionAdditionStatus = {
        addingInstruction: false,
        errorWhileAdding: true,
        additionSuccessful: false,
        noResultsAvailable: false,
        errorReason: (error as AxiosError).response?.data,
      }
      dispatch(instructionAdditionSliceIPD.actions.updatedStatus(resState))
    }
  }

export const addInstructionsApiCallsForIPD = async (
  communicationRequests: R4.ICommunicationRequest[],
  patient: R4.IPatient,
  appointment: FhirActiveIPDDetailsForMedicalRole,
  careplanId: string,
  encounterId: string
): Promise<AxiosResponse[] | undefined> => {
  try {
    const body: any = {
      appointmentId: appointment.mainServiceRequest.id,
      patientId: patient.id,
      carePlanId: careplanId,
      encounterId,
    }

    const enRolClient: EnrolCient = new EnrolCient()

    const instance = axios.create({
      baseURL: process.env.REACT_APP_CC_WORKFLOW_URL,
      timeout: 15000,
    })
    const responses: any[] = []
    communicationRequests.forEach(async (e) => {
      body.communicationRequest = e

      const resp: AxiosResponse =
        await enRolClient.doCreateEnrolmentFlowRequest('instruction/', body)

      responses.push(resp)
    })

    return responses
  } catch (error) {
    logger.info('_______error________________')
    logger.info(error)
  }
  return undefined
}

export default instructionAdditionSliceIPD.reducer
