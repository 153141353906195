import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Close, StarBorder } from '@material-ui/icons'
import Done from '@material-ui/icons/Done'
import { kBackgroundPaper } from 'configs/styles/muiThemes'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
// import { kDialogueBackground } from 'configs/styles/muiThemes'
import React, { useState } from 'react'
import {
  getActiveMembershipDetailsOfPatient,
  getMemberPlanId,
  getMemberShipAvailableCredit,
} from 'utils/membershipUtils'

const useStyles = makeStyles({
  bigIndicator: {
    width: '10px',
  },
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      //   padding: 4,
    },
  },
})

interface MembershipDetailsProperties {
  patientResource: R4.IPatient
  display: boolean
  onSubscriptionDetailsFetched: ({
    isSubscribed,
    coverage,
  }: {
    isSubscribed: boolean
    coverage?: R4.ICoverage
  }) => void
}

export const MembershipCheckOfPatient: React.FC<MembershipDetailsProperties> =
  ({
    patientResource,
    display,
    onSubscriptionDetailsFetched,
  }: MembershipDetailsProperties) => {
    const classes = useStyles()

    const [coverage, setCoverage] = React.useState<R4.ICoverage>()
    const [isSubscriptionActive, setIsSubscriptionActive] =
      React.useState(false)

    const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })

    function fetchMembersOfPlan() {
      if (patientResource?.id) {
        setFetchingStatus({
          requesting: true,
        })

        getActiveMembershipDetailsOfPatient(patientResource?.id)
          .then((e) => {
            if ('data' in e) {
              const err = e as FHIRErrorResponses
              if (err.status === 1200) {
                setIsSubscriptionActive(false)
                onSubscriptionDetailsFetched({ isSubscribed: false })
                setFetchingStatus({
                  requesting: false,
                  requestSuccess: true,
                })
              } else {
                setFetchingStatus({
                  requesting: false,
                  requestError: true,
                  errorDescription: err.displayText ?? '',
                })
              }
            } else {
              setFetchingStatus({
                requesting: false,
                requestSuccess: true,
              })

              setCoverage(e)
              setIsSubscriptionActive(true)
              onSubscriptionDetailsFetched({ isSubscribed: true, coverage: e })
            }
          })
          .catch((eep) => {
            setFetchingStatus({
              requesting: false,
              requestError: true,
            })
          })
      }
    }

    React.useEffect(() => {
      fetchMembersOfPlan()
    }, [patientResource])

    return (
      <Box px={1}>
        {fetchingStatus.requesting && <CircularProgress size={15} />}
        {fetchingStatus.requestError && (
          <Typography color='error'>{` `}</Typography>
        )}
        {fetchingStatus.requestSuccess && (
          <Box>
            {isSubscriptionActive && coverage && display && (
              <Tooltip
                title={`# ${getMemberPlanId(
                  coverage
                )}  :  ${getMemberShipAvailableCredit(coverage)}`}
              >
                <StarBorder fontSize='small' />
              </Tooltip>
            )}
          </Box>
        )}
      </Box>
    )
  }
