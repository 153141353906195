import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  requestASAPAgentSlot,
  resetSlotSelectionStatus,
} from 'redux/lab/order/agentSlotSelection/agentSlotSearchSlice'
import { RootState } from 'redux/rootReducer'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { AgentSlotTile } from './agent_slot'
import { AgentSlotAdvancedSelector } from './agent_slot_advanced_filter'

interface Props {
  onSlotChanged: (newSlot?: FhirSlotDetail) => void
  onChangeClicked?: () => void
}

export const AgentSlotForOrder: React.FC<Props> = ({
  onSlotChanged,
  onChangeClicked,
}: Props) => {
  const [selectedSlot, setSelectedSlot] = useState<FhirSlotDetail>()
  const [displayAdvancedFilterOption, setDisplayAdvancedFilterOption] =
    useState<boolean>(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedDoctors = useRef<PractitionerWithRole[] | undefined>()

  const agentSlotSearchSlice = useSelector(
    (state: RootState) => state.agentSlotSearchSlice
  )

  useEffect(() => {
    dispatch(resetSlotSelectionStatus())
    dispatch(requestASAPAgentSlot())
  }, [])

  useEffect(() => {
    if (agentSlotSearchSlice.singleSlotAvailable) {
      setSelectedSlot(agentSlotSearchSlice.singleSlot)
      onSlotChanged(agentSlotSearchSlice.singleSlot)
      if (agentSlotSearchSlice.singleSlot)
        selectedDoctors.current =
          agentSlotSearchSlice.singleSlot.practitionerWithRole ?? []
    }
  }, [agentSlotSearchSlice.singleSlotAvailable])

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
          <Button onClick={() => {}}>Try again</Button>
        </div>
      )}
    >
      <Box display='flex' flexDirection='column' width='100%' my={1}>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <WelloFormItemLabel title={t('labelCommon:select_time_slot')} />

          <Button
            id='slot_modify_agent'
            variant='text'
            color='primary'
            onClick={(e) => {
              setDisplayAdvancedFilterOption(true)
            }}
          >
            Change
          </Button>
        </Box>

        <Box display='flex' flexDirection='row' width='100%'>
          {agentSlotSearchSlice.searching && <CircularProgress />}
          {selectedSlot && <AgentSlotTile slotDetails={selectedSlot} />}
          {agentSlotSearchSlice.error && (
            <Typography variant='subtitle1' color='error'>
              {agentSlotSearchSlice.errorMessage ?? 'Error while fetching slot'}
            </Typography>
          )}
        </Box>
        <AgentSlotAdvancedSelector
          open={displayAdvancedFilterOption}
          onCancelled={() => {
            setDisplayAdvancedFilterOption(false)
          }}
          onSlotSelected={(slot) => {
            if (slot) {
              setSelectedSlot(slot)
              onSlotChanged(slot)
              setDisplayAdvancedFilterOption(false)
            }
          }}
          practitionerWithRole={selectedDoctors.current}
        />
      </Box>
    </ErrorBoundary>
  )
}
