/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import TextField from '@mui/material/TextField'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import FocusWithin from 'react-focus-within'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import 'react-vis/dist/style.css'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  addObservationDetailsForSys,
  getDateCategorizedObservations,
  getMentalStatusPrakruti,
  getMentalStatusVikruti,
  getObservationResourceForMentalStatus,
  getObservationResourceForPostForSystemicExam,
  getObservationsOfSpecificCodeInEncounter,
} from 'utils/fhirResoureHelpers/observationHelpers'

interface CardioVascularExaminationsListProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  isReadOnly?: boolean
}

export const MentalStatusAssessment: React.FC<CardioVascularExaminationsListProps> =
  ({
    fhirAppointmentDetails,
    isReadOnly = false,
  }: CardioVascularExaminationsListProps) => {
    const { t } = useTranslation(['common'])
    const [isDirty, setDirty] = useState<boolean>()
    const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())
    const [adding, setAdding] = useState<string>(getUniqueTempId())
    const [observations, setObservations] = useState<R4.IObservation[]>([])
    const dispatch = useDispatch()
    const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
      initial: true,
    })
    const [addingStatus, setAddingStatus] = useState<NetworkRequestStatus>({
      initial: true,
    })

    function onSubmit() {
      if (!isDirty) return
      const observation = getObservationResourceForMentalStatus({
        prakriti,
        vikriti,
      })

      if (observation) {
        setAddingStatus({
          requesting: true,
        })
        const updatedObservation = getObservationResourceForPostForSystemicExam(
          fhirAppointmentDetails.patient.id!,
          fhirAppointmentDetails.encounter!.id!,
          [],
          observation
        )

        addObservationDetailsForSys(updatedObservation).then((e) => {
          if (e === false) {
            dispatch(showErrorAlert('Error while updating observation details'))
          } else if ((e as R4.IObservation).resourceType === 'Observation') {
            dispatch(
              showSuccessAlert('Observation details updated successfully')
            )
            setAddingStatus({
              requesting: false,
              errorDescription: '',
              requestError: false,
              requestSuccess: true,
            })
            setDirty(false)
            setFetchAgain(getUniqueTempId())
            getObservationDetails()
          } else {
            dispatch(showErrorAlert('Error while updating observation details'))
            setAddingStatus({
              requesting: false,
              errorDescription: '',
              requestError: true,
              requestSuccess: false,
            })
          }
        })
      }
    }

    const [prakriti, setPrakriti] = useState<string>('')
    const [vikriti, setVikriti] = useState<string>('')

    function getObservationDetails() {
      setFetchingStatus({
        requesting: true,
      })

      getObservationsOfSpecificCodeInEncounter({
        encounterId: fhirAppointmentDetails.encounter!.id!,
        patient: fhirAppointmentDetails.patient,
        code: 'C0424578',
      })
        .then((e) => {
          if (e) {
            setObservations(e as R4.IObservation[])
            setFetchingStatus({
              requestError: false,
              requesting: false,
              requestSuccess: true,
            })
          } else {
            setFetchingStatus({
              requestError: false,
            })
          }
        })
        .catch((e) => {
          setFetchingStatus({
            requestError: false,
          })
        })
    }
    useEffect(() => {
      getObservationDetails()
    }, [])

    return (
      <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
        <Box display='flex' flexGrow={1}>
          <Box
            borderRadius={2}
            style={{
              backgroundColor: 'lightGrey',
            }}
            display='flex'
            flexDirection='row'
            width='100%'
            height={40}
            px={1}
          >
            <Box
              justifyContent='flex-start'
              display='flex'
              flexGrow={1}
              alignItems='center'
            >
              <Typography
                variant='subtitle1'
                style={{
                  color: 'black',

                  fontWeight: 'bold',
                }}
              >
                Manasika Pariksha (Mental Status)
              </Typography>
            </Box>
          </Box>
        </Box>
        {fetchingStatus.requesting && (
          <Box p={1.5}>
            <CircularProgress size={20} color='primary' />
          </Box>
        )}
        {fetchingStatus.requestError && (
          <Typography variant='body1' color='error'>
            {t('common:errorWhileFetchingData')}
          </Typography>
        )}
        {isReadOnly === false && (
          <Box
            sx={{ width: '100%' }}
            display='flex'
            flexDirection='column'
            paddingTop={isReadOnly ? 0.5 : 1.5}
            paddingLeft={isReadOnly ? 0.5 : 1}
          >
            <FocusWithin
              onBlur={() => {
                onSubmit()
              }}
            >
              {({ focusProps, isFocused }) => (
                <div
                  {...focusProps}
                  style={{
                    width: '100%',
                  }}
                >
                  <Grid container direction='column' spacing={1}>
                    <Grid
                      container
                      direction='row'
                      style={{
                        backgroundColor: '#F8F8F8',
                        border: '1px solid #ccc',
                      }}
                    >
                      <Grid item xs={6}>
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                          height='100%'
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                            justifyContent='center'
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              alignItems='center'
                              px={1}
                            >
                              <Typography
                                color='initial'
                                variant='subtitle1'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,

                                  fontWeight: 'bolder',
                                  lineHeight: 'normal',
                                }}
                              >
                                Prakriti
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            display='flex'
                            width='2%'
                            paddingTop={0.1}
                            height='100%'
                          >
                            <Divider
                              orientation='vertical'
                              flexItem
                              style={{
                                height: '100%',
                                width: '2px',
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              alignItems='center'
                              p={1}
                            >
                              <TextField
                                id='txt_area_mental_status_prakriti'
                                label=''
                                fullWidth
                                variant='outlined'
                                size='small'
                                multiline={true}
                                value={prakriti}
                                onChange={(e) => {
                                  setDirty(true)
                                  setPrakriti(e.target.value)
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction='row'
                      style={{
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        borderTop: 'none',
                      }}
                    >
                      <Grid item xs={6}>
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                          height='100%'
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                            height='100%'
                            justifyContent='center'
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              alignItems='center'
                              px={1}
                            >
                              <Typography
                                color='initial'
                                variant='subtitle1'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,

                                  fontWeight: 'bolder',
                                  lineHeight: 'normal',
                                }}
                              >
                                Vikruti
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            display='flex'
                            width='2%'
                            paddingTop={0.1}
                            height='100%'
                          >
                            <Divider
                              orientation='vertical'
                              flexItem
                              style={{
                                height: '100%',
                                width: '2px',
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              alignItems='center'
                              p={1}
                            >
                              <TextField
                                id='txt_area_mental_status_vikriti'
                                label=''
                                fullWidth
                                variant='outlined'
                                size='small'
                                multiline={true}
                                value={vikriti}
                                onChange={(e) => {
                                  setDirty(true)
                                  setVikriti(e.target.value)
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              )}
            </FocusWithin>
          </Box>
        )}
        {fetchingStatus.requestSuccess && (
          <Box
            sx={{ width: '100%' }}
            display='flex'
            flexDirection='column'
            paddingTop={isReadOnly ? 0.5 : 1.5}
            paddingLeft={isReadOnly ? 0.5 : 1}
          >
            {observations.length > 0 &&
              Object.entries(getDateCategorizedObservations(observations)).map(
                ([date, eachObservations]) => (
                  <Box key={date} display='flex' flexDirection='column'>
                    <Box
                      paddingLeft={0}
                      borderRadius={2}
                      style={{
                        backgroundColor: 'lightGrey',
                      }}
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      height={40}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow={1}
                        alignItems='center'
                        px={1}
                      >
                        <Typography
                          variant='subtitle1'
                          style={{
                            color: 'black',

                            fontWeight: 'bold',
                          }}
                        >
                          Recorded on{' '}
                          {moment(date).format('Do MMMM YYYY hh:mm A')}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      display='flex'
                      flexDirection='column'
                      paddingX='4px'
                      paddingTop='4px'
                    >
                      {eachObservations.map((observation, index) => (
                        <Grid
                          container
                          direction='column'
                          spacing={1}
                          key={observation.id}
                        >
                          <Grid
                            container
                            direction='row'
                            style={{
                              backgroundColor: 'white',
                              border: '1px solid #ccc',
                              height: 40,
                            }}
                          >
                            <Grid item xs={6}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                                height='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                  justifyContent='center'
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      color='initial'
                                      variant='subtitle1'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,

                                        fontWeight: 'bolder',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Prakriti
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  paddingTop={0.1}
                                  height='100%'
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '100%',
                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={5}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    alignItems='center'
                                    p={1}
                                  >
                                    <Typography variant='body1' color='initial'>
                                      {getMentalStatusPrakruti(observation) ??
                                        ''}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction='row'
                            style={{
                              backgroundColor: '#F8F8F8',
                              border: '1px solid #ccc',
                              height: 40,
                            }}
                          >
                            <Grid item xs={6}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                                height='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                  justifyContent='center'
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      color='initial'
                                      variant='subtitle1'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,

                                        fontWeight: 'bolder',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Vikriti
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  paddingTop={0.1}
                                  height='100%'
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '100%',
                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={5}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    alignItems='center'
                                    p={1}
                                  >
                                    <Typography variant='body1' color='initial'>
                                      {getMentalStatusVikruti(observation) ??
                                        ''}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Box>
                  </Box>
                )
              )}
          </Box>
        )}
      </Box>
    )
  }
