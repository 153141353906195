/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable consistent-return */
import { Box } from '@material-ui/core'
import { AnyARecord } from 'dns'
import {
  CarePlanCohortControlsProps,
  CohortChartProps,
  CohortControlsProps,
  CohortDataStage,
  MapDataProps,
} from 'models/chartProps'
import { SankeyData } from 'models/sankeyData'
import { SankeyLink } from 'models/sankeyLinks'
import { SankeyNode } from 'models/sankeyNode'
import React, { useEffect, useState } from 'react'
import { getFilterLabel } from 'utils/cohort/cohortUtils'
import { CustomDynamicFilterForCarePlan } from './carePlanCustomDynamicFilter'
import { CustomDynamicFilter } from './customDynamicFilter'
import { CustomFontSize } from './customFontSize'
import { CustomPersonSlider } from './customMinFilter'

export const CustomControlsForCarePlan: React.FC<CarePlanCohortControlsProps> =
  ({
    max,
    min,
    baseMin,
    handleSetMin,
    handleFontDecrease,
    handleFontIncrease,
    visibleStages,
    stages,
    handleStageFilterChange,
    filterMap,
    filters,
  }: CarePlanCohortControlsProps) => {
    console.log('d')
    console.log(filterMap)
    return (
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        justifyContent='center'
      >
        <Box
          display='flex'
          flexDirection='row'
          width='15%'
          justifyContent='flex-start'
          paddingLeft={1}
          paddingRight={2}
        >
          <CustomPersonSlider
            label='Min Person Count'
            id='person'
            name='person'
            baseMin={baseMin}
            min={min}
            max={max || 1000}
            step={1}
            value={min}
            onChange={handleSetMin}
          />
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          width='15%'
          justifyContent='flex-start'
          px={2}
        >
          <CustomFontSize
            label='Font Size'
            handleFontDecrease={handleFontDecrease}
            handleFontIncrease={handleFontIncrease}
          />
        </Box>
        <Box display='flex' width='70%'>
          <CustomDynamicFilterForCarePlan
            label='Filters'
            handleStageFilterChange={handleStageFilterChange}
            filterMap={filterMap}
            filters={filters}
            visibleStages={visibleStages}
          />
        </Box>
      </Box>
    )
  }
