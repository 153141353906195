import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, MenuItem, TextField, TextFieldProps } from '@material-ui/core'
import React, { useState } from 'react'
import { WelloFormItemLabel } from 'wello-web-components'

interface WelloSelectCodingProps {
  title: string
  textFieldProps: TextFieldProps
  availableCodings: R4.ICoding[]
  onChanged: (selectedCoding: R4.ICoding | undefined) => void
  preSelectedCoding?: R4.ICoding
  displayDoneLabel?: boolean
  type: string
  selectedColumn?: any[]
  id?: string
}

export const WelloSelectFHIRTest: React.FunctionComponent<WelloSelectCodingProps> =
  ({
    title,
    textFieldProps,
    availableCodings,
    onChanged,
    preSelectedCoding,
    displayDoneLabel,
    type,
    selectedColumn,
    id,
  }: WelloSelectCodingProps) => {
    const options: R4.ICoding[] = [
      { code: '', display: 'Select ....' },
      ...availableCodings,
    ]
    const [item, setItem] = useState<R4.ICoding>(
      preSelectedCoding ?? { code: '', display: 'Select Test Name' }
    )
    if (item.code === 'processing_samples') selectedColumn?.push('rejected')
    return (
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <WelloFormItemLabel title={title} displayDoneLabel={displayDoneLabel} />
        <Box>
          <TextField
            variant='outlined'
            fullWidth
            size='small'
            select
            SelectProps={{
              displayEmpty: true,
            }}
            id={`${id}div` ?? 'select_sum'}
            InputProps={{
              id: id ?? 'select_sum',
            }}
            value={item?.code}
            // label={item?.code === '' ? 'Select' : ''}
            onChange={(val) => {
              const selectedItem: R4.ICoding | undefined =
                availableCodings.find(
                  (tests) => tests.code === val.target.value
                )
              if (selectedItem) {
                setItem(selectedItem)
                onChanged(selectedItem)
              }
            }}
            {...textFieldProps}
          >
            {options.map((option) => (
              <MenuItem
                id={option.code}
                key={option.code}
                value={option.code}
                selected={item?.code === option.code}
                disabled={
                  option?.code === '' ||
                  (selectedColumn && selectedColumn.includes(option.code))
                }
              >
                {option.display}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
    )
  }
