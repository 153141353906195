import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from 'redux/store'
import { OIDCResult } from './oidcResult'

const initialState: OIDCResult = { checking: true, authorized: false }
const oidcSlice = createSlice({
  name: 'oidcSlice',
  initialState,
  reducers: {
    checkingAuthorization(state) {
      state.checking = true
    },
    authorizationSuccessful(state) {
      state.checking = false
      state.authorized = true
    },
    authorizationFailure(state) {
      state.checking = false
      state.authorized = false
    },
  },
})

export const checkAuthorization = (url: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(oidcSlice.actions.checkingAuthorization)

  /* const urlParameters: URLSearchParams | null = getUrlPathParameters(url);

  if (urlParameters != null) {
    if (!isAuthenticated()) {
      let code: string | null = urlParameters.get("code");
      let verifier: string | null = urlParameters.get("verifier");
      try {
        const auth: AuthApiClient = new AuthApiClient();
        let data: any = await auth.doPostFormRequest(
          "/token",
          `client_id=labadminweb&grant_type=authorization_code&code=${code}&code_verifier=${verifier}&redirect_uri=https%3A%2F%2Fwellolabdev.web.app%2F&resource=https%3A%2F%2Fapi.wellopathy.com%2Fwellostack`
        );
        logger.debug(data);
        storeToken(data.id_token, data.access_token);
        let userData: any = await auth.doGetRequest("/userinfo");
        logger.debug(userData);
        storeUserInfo(userData);
        dispatch(oidcSlice.actions.authorizationSuccessful);
      } catch (error) {
        dispatch(oidcSlice.actions.authorizationFailure);
      }
    }
  } */
}

export default oidcSlice.reducer
