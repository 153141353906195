/* eslint-disable no-irregular-whitespace */
import { Box } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-vis/dist/style.css'
import { AutonomicExaminationsListPT } from './autonomicExamination'
import { BodyPartsPain } from './bodyPartsPainExamination'
import { HigherFunctionExaminationsListPT } from './higherFunctionExamination'
import { MotorExaminationsListPT } from './motorExamination'
import { ReflectionsExaminationsListPT } from './reflectionsExamination'
import { RespirationExaminationsListPT } from './respirationExamination'
import { SensoryOrgansExaminationsListPT } from './sensoryExamination'

interface PhysicalExaminationOfPTListProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  isReadOnly?: boolean
}

export const PhysicalExaminationOfPTList: React.FC<PhysicalExaminationOfPTListProps> =
  ({
    fhirAppointmentDetails,
    isReadOnly = false,
  }: PhysicalExaminationOfPTListProps) => {
    const { t } = useTranslation(['common'])
    const [addButton, showAddButton] = useState<boolean>(false)

    return (
      <Box display='flex' flexDirection='column'>
        <HigherFunctionExaminationsListPT
          fhirAppointmentDetails={fhirAppointmentDetails}
          isReadOnly={isReadOnly}
        />
        <MotorExaminationsListPT
          fhirAppointmentDetails={fhirAppointmentDetails}
          isReadOnly={isReadOnly}
        />
        <RespirationExaminationsListPT
          fhirAppointmentDetails={fhirAppointmentDetails}
          isReadOnly={isReadOnly}
        />
        <ReflectionsExaminationsListPT
          fhirAppointmentDetails={fhirAppointmentDetails}
          isReadOnly={isReadOnly}
        />
        <SensoryOrgansExaminationsListPT
          fhirAppointmentDetails={fhirAppointmentDetails}
          isReadOnly={isReadOnly}
        />
        <AutonomicExaminationsListPT
          fhirAppointmentDetails={fhirAppointmentDetails}
          isReadOnly={isReadOnly}
        />
        <BodyPartsPain
          fhirAppointmentDetails={fhirAppointmentDetails}
          isReadOnly={isReadOnly}
        />
      </Box>
    )
  }
