import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from 'redux/store'
import { getCarePlanOfPatient } from 'utils/careplan_utils/careplan_utils'
import { logger } from 'utils/logger'
import { PatientSubscriptionsState } from './patientSubscriptionsStatus'

const initialState: PatientSubscriptionsState = {
  fetching: false,

  error: false,
  errorMessage: '',
  resultsAvailable: false,
}

const patientSubscriptionsSlice = createSlice({
  name: 'patientSubscriptionsSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<PatientSubscriptionsState>) {
      state.fetching = action.payload.fetching
      state.hCarePlans = action.payload.hCarePlans
      state.error = action.payload.error
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
    },
  },
})

export const getHyperTensionSubscriptionsOfPatient =
  (selectedPatient: R4.IPatient): AppThunk =>
  async (dispatch: AppDispatch) => {
    let state: PatientSubscriptionsState = {
      fetching: true,

      error: false,
      errorMessage: '',
      resultsAvailable: false,
    }
    dispatch(patientSubscriptionsSlice.actions.updatedStatus(state))
    try {
      const carePlanOfPatient: R4.ICarePlan | undefined =
        await getCarePlanOfPatient(
          selectedPatient.id!,

          'https://wellopathy.com/PlanDefinition/hypertensionplatinumsubscription'
        )

      if (carePlanOfPatient) {
        state = {
          fetching: false,

          error: false,
          errorMessage: '',
          resultsAvailable: true,
          hCarePlans: [carePlanOfPatient],
        }
      } else {
        state = {
          fetching: false,

          error: false,
          errorMessage: '',
          resultsAvailable: true,
          hCarePlans: undefined,
        }
      }

      dispatch(patientSubscriptionsSlice.actions.updatedStatus(state))
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: PatientSubscriptionsState = {
        fetching: false,

        error: false,
        errorMessage: 'Error while sending request',
        resultsAvailable: false,
      }
      dispatch(
        patientSubscriptionsSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export default patientSubscriptionsSlice.reducer
