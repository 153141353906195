/* eslint-disable import/no-named-as-default */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Typography,
  useTheme,
  makeStyles,
  Theme,
  InputAdornment,
  TextField,
  FormControlLabel,
  RadioGroup,
  LinearProgress,
  Radio,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { Alert, Autocomplete } from '@material-ui/lab'
import { ClearOutlined } from '@material-ui/icons'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { ObsDataTableResource, ObsList } from 'models/labObsData'
import { Test } from 'models/Test'
import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { resetCatalogueSearchStatus } from 'redux/lab/masterDataSearch/masterDataSearchSlice'
import observationFinderSlice, {
  resetObservationState,
  searchObservations,
} from 'redux/lab/observationFinder/observationFinderSlice'
import {
  creatingCatalog,
  resetPackageState,
} from 'redux/lab/Test/addTestCatalougeSlice'
import { RootState } from 'redux/rootReducer'
import { getCurrentUserUnitLocationDetails } from 'services/userDetailsService'
import { LabCodeValueSet } from 'utils/constants/lab_test'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { logger } from 'utils/logger'
import { WelloGenderSelector, WelloTextField } from 'wello-web-components'
import excel from 'xlsx'
import { allowedDataType, multipleAllowed } from 'utils/constants'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { UploadTabs } from 'views/components/LeftMenu/uploadTabs'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'

import { getIdentifierValueBySystem } from 'utils/fhirResourcesHelper'
import {
  searchingCatalogueDetailsLabTestForAdd,
  resetCatalogueSearchStatusForAdd,
} from 'redux/lab/catalogDisplaySearch/labTestSearchSlice'
import {
  displayData,
  getCatalogBundle,
} from '../../../../utils/labHelpers/uploadHelper'
import { WelloTabs } from '../../LeftMenu/WelloTabs'
import { DropZone } from '../common/dropZone'
import { UploadErrors } from '../common/uploadErrors'
import { ColumnMapping } from './columnMapping'
import { MasterDataSelecter } from './masterDataSelector'
import { UploadSuccess } from './uploadSuccess'
import { WelloSelectObs } from './welloAutoCompleteForObs'
import { ObservationDataTable } from '../catalougeList/observationDataTable'
import { AddNewLabTest } from './AddNewLabTest'

const menu = ['Test/Panel Header', 'Test/Panel Details']
const validTypes = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
]
const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-excel.sheet.macroEnabled.12'
const requiredColumns = ['Test Name', 'Price', 'Schedule Summary']

interface Props {
  open: boolean
  onClose: () => void
  onDiscard: () => void
}

export const Upload: React.FC<Props> = ({ open, onClose, onDiscard }) => {
  const [selectedTab, setSelectedTab] = React.useState(menu[0])
  const [selectedFiles, setSelectedFiles] = React.useState<any>()
  const [errors, setErrors] = React.useState<any>()
  const [showNextPage, setShowNextPage] = React.useState<boolean>(false)
  const [showContinue, setShowContinue] = React.useState<boolean>(false)
  const [showDisplay, setShowDisplay] = React.useState<boolean>(false)

  const [selectedLabOfferings, setSelectedLabOfferings] =
    React.useState<R4.IPlanDefinition>()
  const colData: string[] = []
  const { t } = useTranslation()
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': selectedTab === menu[0] ? '50%' : '100%',
      //   'min-height': selectedTab === menu[0] ? '45%' : '100%',
    },
  }))

  const classes = useStyles()

  const [hasErrors, setHasErrors] = React.useState<boolean>(false)
  const dispatch = useDispatch()

  const creatCatalogSlice = useSelector(
    (state: RootState) => state.createTestSlice
  )

  const observtionSearchSlice = useSelector(
    (state: RootState) => state.observtionSearchSlice
  )

  const catalogueSearchState = useSelector(
    (state: RootState) => state.labTestSearchSlice
  )

  const [bulk, setBulk] = React.useState<boolean>()
  const [dataSet, setDataSet] = React.useState<ObsDataTableResource[]>([])

  const [tests, setTests] = React.useState<Test>({
    id: getUniqueTempId(),
    testName: '',
    testPrice: 0,
    scheduleSummary: '',
    description: '',
    presetInstructions: '',
    reportDisplayName: '',
    code: [],
    planDef: undefined,
  })

  const checkData: string[] = []

  const handleChangeTab = (selected: string) => {
    setSelectedTab(selected)
  }

  function handleNameChange(planDef: R4.IPlanDefinition) {
    const codesData: string[] = []
    const values: Test = tests
    values.testName = planDef.title ?? ''
    values.planDef = planDef
    setSelectedLabOfferings(planDef)
    if (planDef.action) {
      for (let i = 0; i < planDef.action.length; i++) {
        const actionData = planDef.action[i].action ?? []
        for (let j = 0; j < actionData.length; j++) {
          const codes =
            actionData[i].definitionCanonical?.split(
              'http://wellopathy.com/ActivityDefinition/'
            )[1] ?? ''
          if (codes.length > 0) codesData.push(codes)
        }
      }
    }

    values.code = codesData
    setShowDisplay(false)
    setTests(values)
    if (planDef) {
      dispatch(
        searchingCatalogueDetailsLabTestForAdd(
          planDef,
          getIdentifierValueBySystem(
            planDef.identifier ?? [],
            'http://loinc.org'
          )
        )
      )
    }
  }

  //   const handlePriceChange = useCallback((event: any) => {
  //     setTests({ ...tests, testPrice: parseInt(event.target.value, 10) })
  //   }, [])

  function handlePriceChange(event: any) {
    setTests({ ...tests, testPrice: parseInt(event.target.value, 10) })
  }

  function handleChangeInstructions(splIns: string) {
    setTests({ ...tests, presetInstructions: splIns })
  }

  function handleDescriptionChange(description: string) {
    setTests({ ...tests, description })
  }

  function handleScheduleSummaryChange(summary: string) {
    setTests({ ...tests, scheduleSummary: summary })
  }

  function handleReportNameChange(reportName: string) {
    setTests({ ...tests, reportDisplayName: reportName })
  }

  function handleSubmit() {
    let hasErrorsInData: boolean = false
    let hasPriceErrorInData: boolean = false
    let hasSummaryErrorData: boolean = false
    let reportErrorData: boolean = false
    if (tests.testName.length === 0 || !tests.scheduleSummary.trim()) {
      setShowDisplay(true)
      hasErrorsInData = true
    } else {
      setShowDisplay(false)
      hasErrorsInData = false
    }
    if (tests.reportDisplayName.length === 0) {
      reportErrorData = true
    } else {
      reportErrorData = false
    }
    if (
      tests.testPrice <= 0 ||
      tests.testPrice > 100000 ||
      Number.isNaN(tests.testPrice)
    ) {
      hasPriceErrorInData = true
    } else {
      hasPriceErrorInData = false
    }

    if (tests.scheduleSummary.length === 0 || !tests.scheduleSummary.trim()) {
      hasSummaryErrorData = true
    } else {
      hasSummaryErrorData = false
    }
    if (
      !hasErrorsInData &&
      !hasPriceErrorInData &&
      !hasSummaryErrorData &&
      !reportErrorData
    ) {
      const bundleData = getCatalogBundle(
        tests,
        LabCodeValueSet,
        '',
        dataSet,
        undefined,
        observtionSearchSlice.resultsAvailable &&
          observtionSearchSlice.finalLabObsData
          ? observtionSearchSlice.finalLabObsData
          : undefined
      )
      logger.info(bundleData)
      console.log(bundleData)
      dispatch(creatingCatalog(bundleData, '', ''))
      dispatch(resetObservationState())
    }
    // if (hasErrorsInData) setHasErrors(hasErrorsInData)
    // else if (hasPriceErrorInData) setHasErrors(hasPriceErrorInData)
    // else if (hasSummaryErrorData) setHasErrors(hasSummaryErrorData)
    // else if (reportErrorData) setHasErrors(reportErrorData)
  }

  function resetDetails() {
    setTests({
      id: getUniqueTempId(),
      testName: '',
      testPrice: 0,
      scheduleSummary: '',
      description: '',
      presetInstructions: '',
      reportDisplayName: '',
      code: [],
    })
    setDataSet([])
    dispatch(searchingCatalogueDetailsLabTestForAdd())
    dispatch(resetObservationState())
    setTests({
      id: getUniqueTempId(),
      testName: '',
      testPrice: 0,
      scheduleSummary: '',
      description: '',
      presetInstructions: '',
      reportDisplayName: '',
      code: [],
    })
  }

  function errorChecking(): boolean {
    const errorList: string[] = []

    if (tests.planDef === undefined) {
      errorList.push('1')
    }
    if (tests.planDef && catalogueSearchState.resultsAvailable) {
      errorList.push('2')
    }
    if (tests.testName.length === 0) {
      errorList.push('2')
    }
    if (
      tests.scheduleSummary.trim().length === 0 ||
      tests.scheduleSummary === undefined
    ) {
      errorList.push('2')
    }
    if (
      tests.reportDisplayName.trim().length === 0 ||
      tests.reportDisplayName === undefined
    ) {
      errorList.push('2')
    }

    if (
      tests.reportDisplayName.trim().length === 0 ||
      tests.reportDisplayName === undefined
    ) {
      errorList.push('2')
    }

    if (
      tests.testPrice <= 0 ||
      tests.testPrice > 100000 ||
      Number.isNaN(tests.testPrice)
    ) {
      errorList.push('2')
    }

    if (
      tests.presetInstructions.trim().length > 250 ||
      tests.presetInstructions === undefined
    ) {
      errorList.push('2')
    }
    if (errorList.length > 0) return true

    return false
  }

  useEffect(() => {
    dispatch(searchingCatalogueDetailsLabTestForAdd())
    if (creatCatalogSlice.additionSuccessful) {
      onClose()
      resetDetails()

      dispatch(resetPackageState())
      dispatch(resetObservationState)
    }

    return () => {}
  }, [creatCatalogSlice.additionSuccessful, onClose, dispatch])

  useEffect(() => {
    if (
      observtionSearchSlice.resultsAvailable &&
      observtionSearchSlice.finalObsList
    ) {
      setDataSet(observtionSearchSlice.finalObsList)
    }
    return () => {}
  }, [observtionSearchSlice.resultsAvailable, dispatch])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()

        resetDetails()
        setSelectedFiles(undefined)
        setErrors(undefined)

        setShowNextPage(false)
        setShowContinue(false)
      }}
      aria-labelledby='responsive-dialog-title'
      //   maxWidth={selectedTab === menu[0] ? 'lg' : 'md'}
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick
      PaperProps={{
        style: {
          backdropFilter: 'blur(4px)',
        },
      }}
    >
      <DialogTitle id='scroll-dialog-title'> Add Test/Panel</DialogTitle>
      <DialogContent>
        <Box flexDirection='column' display='flex'>
          <Paper
            style={{ backgroundColor: '#F1F1F1' }}
            variant='outlined'
            square
          >
            <Box width='100%'>
              {selectedTab === menu[0] && (
                <UploadTabs
                  preSelectedTab={selectedTab}
                  onTabChange={(e) => handleChangeTab(e)}
                  menu={menu}
                  disabled={errorChecking()}
                />
              )}

              {selectedTab === menu[1] && (
                <UploadTabs
                  preSelectedTab={selectedTab}
                  onTabChange={(e) => handleChangeTab(e)}
                  menu={menu}
                  disabled={errorChecking()}
                />
              )}
            </Box>
          </Paper>
          {selectedTab === menu[0] && (
            <Box display='flex' flexDirection='column'>
              {creatCatalogSlice.additionSuccessful && (
                <Box display='flex'>
                  <Alert severity='success'>
                    {creatCatalogSlice.errorMessage ??
                      'Invitation sent Successfully'}
                  </Alert>
                </Box>
              )}

              <Box
                display='flex'
                flexDirection='row'
                justifyContent='flex-start'
                px={1.5}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  width='75%'
                  //   paddingTop={1.6}
                  paddingRight={1}
                >
                  <WelloFormItemLabel title='Select Test/Panel' />
                  <Box>
                    <MasterDataSelecter
                      onSelectionChanges={(e: R4.IPlanDefinition) => {
                        dispatch(resetCatalogueSearchStatusForAdd())
                        dispatch(resetObservationState)
                        dispatch(resetPackageState())
                        handleNameChange(e)
                      }}
                      disabled={false}
                      preselectedDefinition={tests.planDef}
                    />
                  </Box>
                  {catalogueSearchState.resultsAvailable && tests.planDef && (
                    <Box display='flex' paddingTop={0.5}>
                      <Alert severity='error'>{`${tests.testName} already exists`}</Alert>
                    </Box>
                  )}
                  {showDisplay && !selectedLabOfferings && (
                    <Box justifyContent='center' px={2}>
                      <Typography
                        color='error'
                        style={{
                          fontSize: 12,
                        }}
                      >
                        Test Name is required
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Box px={1} width='25%'>
                  <Box display='flex' flexDirection='column'>
                    <WelloFormItemLabel title='Price' />
                    <WelloTextFieldWithoutTitle
                      //   title='price'
                      textProps={{
                        id: `actor_email_unit`,
                        value: tests.testPrice,
                        inputProps: {
                          max: 100000,
                          min: 1,
                          step: 0.1,
                        },
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Box>
                                {' '}
                                <Typography variant='subtitle2' align='center'>
                                  {' '}
                                  ₹{' '}
                                </Typography>
                              </Box>
                            </InputAdornment>
                          ),
                        },
                        placeholder: 'Price',
                        error:
                          hasErrors ||
                          tests.testPrice <= 0 ||
                          tests.testPrice > 100000 ||
                          Number.isNaN(tests.testPrice),
                        type: 'number',

                        onChange: (changePrice) => {
                          handlePriceChange(changePrice)
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='row'
                alignItems='flex-start'
                paddingRight={1}
              >
                <Box p={1.5} width='50%' flexDirection='column'>
                  <WelloFormItemLabel title='Description' />
                  <WelloTextFieldWithoutTitle
                    // title='Description'
                    textProps={{
                      id: `testDescription${t}`,
                      value: tests.description,
                      placeholder: 'Description',
                      type: 'text',
                      rows: 4,
                      rowsMax: 6,
                      multiline: true,
                      onChange: (changeDescription) => {
                        handleDescriptionChange(changeDescription.target.value)
                      },
                    }}
                  />
                </Box>
                <Box p={1.5} width='50%' flexDirection='column'>
                  <WelloFormItemLabel title='Pretest Instructions' />
                  <WelloTextFieldWithoutTitle
                    textProps={{
                      id: `splInstructions${t}`,
                      type: 'text',
                      value: tests.presetInstructions,
                      placeholder: 'Pretest Instructions',
                      rows: 4,
                      rowsMax: 6,
                      multiline: true,
                      error: hasErrors && tests.presetInstructions.length > 250,
                      //   helperText:
                      //     hasErrors && tests.presetInstructions.length > 250
                      //       ? 'Pretest Instructions should be less than 250 chars'
                      //       : '',
                      onChange: (changeInstructions) => {
                        handleChangeInstructions(
                          changeInstructions.target.value
                        )
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='row'
                alignItems='flex-start'
                px={1.5}
                paddingBottom={1}
              >
                <Box width='50%' paddingRight={2} flexDirection='column'>
                  <WelloFormItemLabel title='Report Display Name' />
                  <WelloTextFieldWithoutTitle
                    // title='Report Display Name'
                    textProps={{
                      id: `test_reportDisplayName${t}`,
                      placeholder: 'Report Display Name',
                      value: tests.reportDisplayName,
                      error:
                        (hasErrors && tests.reportDisplayName.length === 0) ||
                        !tests.reportDisplayName.trim(),
                      type: 'text',

                      //   value: tests.testPrice,
                      onChange: (changeSummary) => {
                        handleReportNameChange(changeSummary.target.value)
                      },
                    }}
                  />
                </Box>
                <Box width='50%' px={1}>
                  <WelloFormItemLabel title='Schedule Summary' />
                  <WelloTextFieldWithoutTitle
                    // title='Schedule Summary'
                    textProps={{
                      id: `test_scheduleSummary${t}`,
                      value: tests.scheduleSummary,
                      placeholder: 'Schedule Summary',
                      error:
                        (hasErrors && tests.scheduleSummary.length === 0) ||
                        !tests.scheduleSummary.trim(),
                      type: 'text',
                      //   helperText:
                      //     (hasErrors && tests.scheduleSummary.length === 0) ||
                      //     !tests.scheduleSummary.trim()
                      //       ? 'Schedule Summary is required'
                      //       : '',
                      //   value: tests.testPrice,
                      onChange: (changeSummary) => {
                        handleScheduleSummaryChange(changeSummary.target.value)
                      },
                    }}
                  />
                </Box>
              </Box>
              {tests.testName.length > 0 && (
                <Box>
                  <Box flexDirection='row' width='100%' display='flex' py={1}>
                    {observtionSearchSlice.searching && (
                      <LinearProgress
                        style={{
                          height: '4px',
                          width: '100%',
                        }}
                      />
                    )}
                  </Box>

                  {observtionSearchSlice.noResultsAvailable && (
                    <Box>No Parameters Available</Box>
                  )}

                  {observtionSearchSlice.error && (
                    <Box>Something went Wrong</Box>
                  )}
                </Box>
              )}
            </Box>
          )}
          {dataSet.length > 0 && selectedTab === menu[1] && (
            <Box
              display='flex'
              flexDirection='column'
              alignItems='flex-start'
              width='100%'
              px={1.5}
            >
              <Box
                py={0.5}
                width='100%'
                display='flex'
                flexDirection='row'
                justifyContent='center'
              >
                {' '}
                <Typography
                  variant='subtitle1'
                  color='textPrimary'
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  Test Parameters for{' '}
                  {tests.planDef ? tests.planDef.title ?? '' : ''}
                </Typography>
              </Box>

              {showContinue === false && (
                <Box display='flex' flexDirection='column' width='100%'>
                  <ObservationDataTable
                    operation='add'
                    testData={dataSet}
                    onBulkEdit={(dataSetFinal: ObsDataTableResource[]) => {
                      setDataSet(dataSetFinal)
                      setBulk(false)
                    }}
                    onBulkStarted={(isStarted?: boolean) => {
                      if (isStarted) {
                        if (isStarted === true) {
                          setBulk(true)
                        } else {
                          setBulk(false)
                        }
                      } else {
                        setBulk(false)
                      }
                    }}
                    onClickAdd={() => {
                      setShowContinue(true)
                    }}
                    showAdd={true}
                  />
                </Box>
              )}

              {showContinue === true && (
                <Box display='flex' flexDirection='column' width='100%'>
                  <ObservationDataTable
                    operation='add'
                    testData={dataSet}
                    onBulkEdit={(dataSetFinal: ObsDataTableResource[]) => {
                      setDataSet(dataSetFinal)
                      setBulk(false)
                    }}
                    onBulkStarted={(isStarted?: boolean) => {
                      if (isStarted) {
                        if (isStarted === true) {
                          setBulk(true)
                        } else {
                          setBulk(false)
                        }
                      } else {
                        setBulk(false)
                      }
                    }}
                    onClickAdd={() => {
                      setShowContinue(true)
                    }}
                    showAdd={true}
                  />
                </Box>
              )}
              {showContinue && (
                <AddNewLabTest
                  open={showContinue}
                  onNewRowAdded={(dataSetNew: ObsDataTableResource) => {
                    setDataSet([...dataSet, dataSetNew])
                    setShowContinue(false)
                    dispatch(showSuccessAlert('Test added successfully'))
                  }}
                  onClose={() => {
                    setShowContinue(false)
                  }}
                  tableData={dataSet}
                  testName={tests.planDef ? tests.planDef.title ?? '' : ''}
                />
              )}
            </Box>
          )}

          {dataSet.length === 0 && selectedTab === menu[1] && (
            <Box
              display='flex'
              flexDirection='column'
              alignItems='flex-start'
              width='100%'
              px={1.5}
            >
              <Box
                py={0.5}
                px={1}
                width='100%'
                display='flex'
                flexDirection='row'
                justifyContent='flex-start'
              >
                {' '}
                <Typography
                  variant='subtitle1'
                  color='textPrimary'
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  No Test Selected.Please select a Test
                </Typography>
              </Box>
            </Box>
          )}
          <Box flexGrow={1}>
            {' '}
            <Divider />{' '}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          paddingRight={1.1}
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
        >
          {creatCatalogSlice.adding && <CircularProgress />}
          <Button
            //   color='primary'
            onClick={() => {
              dispatch(resetCatalogueSearchStatus())
              onClose()
              setSelectedTab(menu[0])
              setDataSet([])
              dispatch(resetObservationState())
              resetDetails()
              dispatch(resetPackageState())

              setErrors(undefined)
            }}
            style={{ height: 26.5 }}
            variant='outlined'
            disabled={creatCatalogSlice.adding}
            disableElevation
            size='small'
          >
            Cancel
          </Button>

          {dataSet.length > 0 && selectedTab === menu[1] && (
            <Box paddingLeft={0.5}>
              <Button
                variant='contained'
                size='small'
                color='primary'
                disabled={bulk}
                onClick={() => {
                  setSelectedTab('Test/Panel Header')
                }}
              >
                Back
              </Button>
            </Box>
          )}

          {selectedTab === menu[0] && (
            <Box paddingLeft={0.5}>
              <Button
                variant='contained'
                size='small'
                color='primary'
                disabled={
                  errorChecking() || catalogueSearchState.resultsAvailable
                }
                onClick={() => {
                  setSelectedTab('Test/Panel Details')
                  //   handleChangeTab('Test/Panel Details')
                }}
              >
                Next
              </Button>
            </Box>
          )}

          {dataSet.length > 0 && selectedTab === menu[1] && (
            <Box paddingLeft={0.5}>
              <Button
                variant='contained'
                size='small'
                color='primary'
                disabled={bulk}
                //   disabled={tests.testName.length === 0}
                onClick={() => {
                  handleSubmit()
                  dispatch(resetCatalogueSearchStatus())

                  setErrors(undefined)

                  // onDiscard()
                }}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  )
}
