import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { getExpandedAppointmentFromBundle } from 'utils/common/patientDataTableHelper'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import { UmlClient } from 'services/umlsClient'
import { LabTestSearchStatus } from './labTestSearchStatusTypes'

const initialState: LabTestSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const labTestearchSlice = createSlice({
  name: 'labTest',
  initialState,
  reducers: {
    searchingLabTest(
      state,
      action: PayloadAction<LabTestSearchStatus>
    ) {},

    searchResults(state, action: PayloadAction<LabTestSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList =
        action.payload.surgeryList
    },

    noDataFoundForSearch(state, action: PayloadAction<LabTestSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList =
        action.payload.surgeryList
    },

    errorWhileSearching(state, action: PayloadAction<LabTestSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList =
        action.payload.surgeryList
    },
    resetState(state, action: PayloadAction<LabTestSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.surgeryList = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: LabTestSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      surgeryList: undefined,
    }
    dispatch(labTestearchSlice.actions.resetState(state))
  }

export const searchLabTest =
  (searchString:string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: LabTestSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(labTestearchSlice.actions.errorWhileSearching(errorSearchPatient))
    try {   
      const fhirClient: UmlClient = new UmlClient()
      const response: any = await fhirClient.doGetResource(
        `umls/Search?Path=labTest&Match=${searchString}`
      )
              if(response.length >0)
              {
                const searchPatientResult: LabTestSearchStatus = {
                    error: false,
                    noResultsAvailable: false,
                    resultsAvailable: true,
                    searching: false,
                    surgeryList: response,
                    totalCount: response.total,
                  }
                  dispatch(
                    labTestearchSlice.actions.searchResults(searchPatientResult)
                  )
                  return
              }
              
                const noSearchResults: LabTestSearchStatus = {
                    error: false,
                    noResultsAvailable: true,
                    resultsAvailable: false,
                    searching: false,
                  }
                  dispatch(
                    labTestearchSlice.actions.noDataFoundForSearch(noSearchResults)
                  )
                  return
              
          
        
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: LabTestSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        labTestearchSlice.actions.errorWhileSearching(errorWhileSearchPatient)
      )
    }
  }

  export const resetState = () => (dispatch: AppDispatch) => {
    dispatch(labTestearchSlice.actions.resetState(initialState))
  }



export default labTestearchSlice.reducer

