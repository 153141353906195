/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestForTreatment } from 'redux/ipd/treatment/viewTreatment/treatmentSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  getNotes,
  getOrderStatusText,
} from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { NoteDialog } from '../LeftMenu/TextDialog'

interface VitalsProp {
  patient: R4.IPatient
  ipdDetails: R4.IServiceRequest
  isPT: boolean
}

export const TreatmentTile: React.FC<VitalsProp> = ({
  patient,
  ipdDetails,
  isPT,
}: VitalsProp) => {
  const { t } = useTranslation(['common'])

  const dispatch = useDispatch()
  const treatmentSearchSlice = useSelector(
    (state: RootState) => state.treatmentSearchSlice
  )
  const [fullText, setFullText] = useState<string>('')
  const [showText, setShowText] = useState<boolean>(false)

  useEffect(() => {
    if (ipdDetails && patient) {
      dispatch(requestForTreatment(ipdDetails.id!, 'details'))
    }
  }, [dispatch, ipdDetails, patient])

  return (
    <Box>
      <Grid container direction='column' style={{ flexWrap: 'nowrap' }}>
        <Grid item>
          <Grid container direction='row' id='vitals'>
            <Box py={1}>
              <Typography
                variant='subtitle2'
                style={{ color: '#4D4D4D', textTransform: 'uppercase' }}
              >
                {' '}
                Treatment Therapy
              </Typography>
            </Box>{' '}
          </Grid>
        </Grid>
        {treatmentSearchSlice.resultsAvailable &&
          treatmentSearchSlice.treatmentList &&
          treatmentSearchSlice.treatmentList.length > 0 && (
            <Grid item>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                width='100%'
                border={4}
                style={{
                  boxShadow: '0px 0px 4px #ccc',
                  margin: '2px 0px',
                  borderRadius: '1px',
                  borderColor: '#F8F8F8',
                  width: '100%',
                  height: 'auto',
                  backgroundColor: '#F8F8F8',
                }}
              >
                <Grid container direction='row' spacing={1}>
                  <Grid
                    container
                    direction='row'
                    id='vitals'
                    style={{
                      backgroundColor: kDialogueBackground,
                    }}
                  >
                    <Grid item xs={1}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={30}
                            alignItems='center'
                            px={0.5}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 10,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Date
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display='flex'
                          width='2%'
                          justifyContent='flex-end'
                          flexGrow
                        >
                          <Divider
                            orientation='vertical'
                            flexItem
                            style={{
                              height: '30px',

                              width: '2px',
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={30}
                            alignItems='center'
                            px={0.5}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 10,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Therapy
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display='flex'
                          width='2%'
                          justifyContent='flex-end'
                          flexGrow
                        >
                          <Divider
                            orientation='vertical'
                            flexItem
                            style={{
                              height: '30px',

                              width: '2px',
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={30}
                            alignItems='center'
                            px={0.5}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 10,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Start Time
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display='flex'
                          width='2%'
                          justifyContent='flex-end'
                          flexGrow
                        >
                          <Divider
                            orientation='vertical'
                            flexItem
                            style={{
                              height: '30px',

                              width: '2px',
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={30}
                            alignItems='center'
                            px={0.5}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',
                                fontWeight: 'bold',

                                lineHeight: 'normal',
                              }}
                            >
                              End Time
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display='flex'
                          width='2%'
                          justifyContent='flex-end'
                          flexGrow
                        >
                          <Divider
                            orientation='vertical'
                            flexItem
                            style={{
                              height: '30px',

                              width: '2px',
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={30}
                            alignItems='center'
                            px={0.5}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 10,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Actual Start
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display='flex'
                          width='2%'
                          justifyContent='flex-end'
                          flexGrow
                        >
                          <Divider
                            orientation='vertical'
                            flexItem
                            style={{
                              height: '30px',

                              width: '2px',
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={30}
                            alignItems='center'
                            px={0.5}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 10,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Actual End
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display='flex'
                          width='2%'
                          justifyContent='flex-end'
                          flexGrow
                        >
                          <Divider
                            orientation='vertical'
                            flexItem
                            style={{
                              height: '30px',

                              width: '2px',
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={30}
                            alignItems='center'
                            px={0.5}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 10,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Notes
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display='flex'
                          width='2%'
                          justifyContent='flex-end'
                          flexGrow
                        >
                          <Divider
                            orientation='vertical'
                            flexItem
                            style={{
                              height: '30px',

                              width: '2px',
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={30}
                            alignItems='center'
                            px={0.5}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 10,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Therapist
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display='flex'
                          width='2%'
                          justifyContent='flex-end'
                          flexGrow
                        >
                          <Divider
                            orientation='vertical'
                            flexItem
                            style={{
                              height: '30px',

                              width: '2px',
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={1}>
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={30}
                            alignItems='center'
                            px={0.5}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 10,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Status
                            </Typography>
                          </Box>
                        </Box>
                        {/* <Box
                          display='flex'
                          width='2%'
                          justifyContent='flex-end'
                          flexGrow
                        >
                          <Divider
                            orientation='vertical'
                            flexItem
                            style={{
                              height: '30px',

                              width: '2px',
                            }}
                          />
                        </Box> */}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        {treatmentSearchSlice.searchingTreatment && (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            width='100%'
            height='100%'
            alignContent='center'
          >
            <Typography variant='subtitle1' color='initial' align='center'>
              <CircularProgress size={25} />
            </Typography>
          </Box>
        )}

        {treatmentSearchSlice.errorWhileSearchingTreatment && (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            width='100%'
            height='100%'
            alignContent='center'
          >
            <Typography variant='subtitle1' color='initial' align='center'>
              Some Error has occured
            </Typography>
          </Box>
        )}

        {treatmentSearchSlice.noResultsAvailable && (
          <Box display='flex' flexDirection='column' width='100%' height='100%'>
            <Typography
              variant='subtitle2'
              style={{
                fontWeight: 400,
              }}
            >
              No data available
            </Typography>
          </Box>
        )}

        {treatmentSearchSlice.resultsAvailable &&
          treatmentSearchSlice.treatmentList &&
          treatmentSearchSlice.treatmentList.length === 0 && (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              width='100%'
              height='100%'
              alignContent='center'
            >
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                No data available
              </Typography>
            </Box>
          )}
        {treatmentSearchSlice.resultsAvailable &&
          treatmentSearchSlice.treatmentList &&
          treatmentSearchSlice.treatmentList.length > 0 && (
            <Grid item xl>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                width='100%'
                border={4}
                style={{
                  boxShadow: '0px 0px 4px #ccc',
                  margin: '2px 0px',
                  borderRadius: '1px',
                  borderColor: '#F8F8F8',
                  width: '100%',
                  height: 'auto',
                  backgroundColor: '#F8F8F8',
                }}
              >
                <Grid container direction='row' spacing={1}>
                  {treatmentSearchSlice.treatmentList.map(
                    (treatmentData: TreatmentPlanData, index: number) => (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        // style={{
                        //   backgroundColor:
                        //     index % 2 ? kDialogueBackground : 'white',
                        // }}
                      >
                        {treatmentData.treatmentPlan &&
                          treatmentData.treatmentPlan.status !== 'unknown' && (
                            <Box
                              width='100%'
                              //   style={{
                              //     backgroundColor:
                              //       index % 2 ? kDialogueBackground : 'white',
                              //   }}
                            >
                              <Grid
                                container
                                direction='row'
                                id='vitals'
                                style={{
                                  backgroundColor:
                                    index % 2 ? kDialogueBackground : 'white',
                                }}
                              >
                                <Grid item xs={1}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={0.5}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                          style={{
                                            cursor: 'pointer',
                                            fontFamily: 'Open Sans',
                                            fontSize: 10,

                                            lineHeight: 'normal',
                                          }}
                                        >
                                          {moment(treatmentData.date).format(
                                            'DD-MM-YYYY'
                                          )}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      justifyContent='flex-end'
                                      flexGrow
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={2}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={0.5}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                          style={{
                                            cursor: 'pointer',
                                            fontFamily: 'Open Sans',
                                            fontSize: 10,

                                            lineHeight: 'normal',
                                          }}
                                        >
                                          {treatmentData.therapy}{' '}
                                          {treatmentData.treatmentType
                                            ? `- ${treatmentData.treatmentType}`
                                            : ''}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      justifyContent='flex-end'
                                      flexGrow
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid item xs={1}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={0.5}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                          style={{
                                            cursor: 'pointer',
                                            fontFamily: 'Open Sans',
                                            fontSize: 10,

                                            lineHeight: 'normal',
                                          }}
                                        >
                                          {treatmentData.startTime}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      justifyContent='flex-end'
                                      flexGrow
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid item xs={1}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={0.5}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                          style={{
                                            cursor: 'pointer',
                                            fontFamily: 'Open Sans',
                                            fontSize: 10,

                                            lineHeight: 'normal',
                                          }}
                                        >
                                          {treatmentData.endTime}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      justifyContent='flex-end'
                                      flexGrow
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid item xs={1}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={0.5}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                          style={{
                                            paddingLeft: 2,
                                            cursor: 'pointer',
                                            fontFamily: 'Open Sans',
                                            fontSize: 10,

                                            lineHeight: 'normal',
                                          }}
                                        >
                                          {treatmentData.actualStart &&
                                          treatmentData.actualStart !==
                                            'Invalid Date'
                                            ? treatmentData.actualStart
                                            : ''}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      justifyContent='flex-end'
                                      flexGrow
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid item xs={1}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={0.5}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                          style={{
                                            paddingLeft: 2,
                                            cursor: 'pointer',
                                            fontFamily: 'Open Sans',
                                            fontSize: 10,

                                            lineHeight: 'normal',
                                          }}
                                        >
                                          {treatmentData.actualEnd &&
                                          treatmentData.actualEnd !==
                                            'Invalid Date'
                                            ? treatmentData.actualEnd
                                            : ''}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      justifyContent='flex-end'
                                      flexGrow
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={2}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={0.5}
                                      >
                                        {treatmentData.treatmentPlan &&
                                          treatmentData.treatmentPlan.status ===
                                            'revoked' && (
                                            <Typography
                                              variant='subtitle2'
                                              color='initial'
                                              style={{
                                                cursor: 'pointer',
                                                fontFamily: 'Open Sans',
                                                fontSize: 10,

                                                lineHeight: 'normal',
                                              }}
                                            >
                                              {' '}
                                              {treatmentData.treatmentPlan.note
                                                ? treatmentData.treatmentPlan
                                                    .note[0].text ?? ''
                                                : treatmentData.treatmentPlan
                                                    .reasonCode
                                                ? treatmentData.treatmentPlan
                                                    .reasonCode[0]
                                                  ? treatmentData.treatmentPlan
                                                      .reasonCode[0].coding
                                                    ? treatmentData
                                                        .treatmentPlan
                                                        .reasonCode[0].coding[0]
                                                      ? treatmentData
                                                          .treatmentPlan
                                                          .reasonCode[0]
                                                          .coding[0].display ??
                                                        ''
                                                      : ''
                                                    : ''
                                                  : ''
                                                : ''}
                                            </Typography>
                                          )}

                                        {treatmentData.procedure && (
                                          <Box>
                                            <Typography
                                              variant='body2'
                                              color='initial'
                                              style={{
                                                cursor: 'pointer',
                                                fontFamily: 'Open Sans',
                                                fontSize: 10,

                                                lineHeight: 'normal',
                                              }}
                                              onClick={() => {
                                                if (
                                                  getNotes(
                                                    treatmentData.procedure!
                                                  ).length > 60
                                                ) {
                                                  setFullText(
                                                    getNotes(
                                                      treatmentData.procedure!
                                                    )
                                                  )
                                                  setShowText(true)
                                                }
                                              }}
                                            >
                                              {getNotes(
                                                treatmentData.procedure!
                                              ).slice(0, 60)}
                                              {getNotes(
                                                treatmentData.procedure!
                                              ).length > 60
                                                ? '...'
                                                : null}
                                            </Typography>

                                            {/* <Typography
                                            variant='body1'
                                            color='initial'
                                            style={{
                                              paddingLeft: 2,
                                              cursor: 'pointer',
                                            }}
                                            //   component=''
                                            onClick={() => {
                                              if (
                                                getNotes(
                                                  treatmentData.procedure
                                                ).length > 60
                                              ) {
                                                setFullText(
                                                  ipdAppointmentDetails.notes
                                                )
                                                setShowText(true)
                                              }
                                            }}
                                          >
                                            {ipdAppointmentDetails.notes.slice(
                                              0,
                                              60
                                            )}
                                          </Typography> */}
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      justifyContent='flex-end'
                                      flexGrow
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid item xs={2}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        px={0.5}
                                      >
                                        {treatmentData.treatmentPlan &&
                                          treatmentData.treatmentPlan.status ===
                                            'revoked' && (
                                            <Typography
                                              variant='subtitle2'
                                              color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 10,

                                                fontWeight: 600,
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              {treatmentData.treatmentPlan
                                                .performer
                                                ? treatmentData.treatmentPlan
                                                    .performer[0].display ?? ''
                                                : ''}
                                            </Typography>
                                          )}
                                        {treatmentData.procedure && (
                                          <Typography
                                            variant='subtitle1'
                                            color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 10,

                                              fontWeight: 600,
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            {' '}
                                            {treatmentData.procedure.performer
                                              ?.map(
                                                (e) => e.actor.display ?? ''
                                              )
                                              .join(', ')}
                                          </Typography>
                                        )}
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      justifyContent='flex-end'
                                      flexGrow
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid item xs={1}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={30}
                                        alignItems='center'
                                        paddingLeft={0.4}
                                        paddingRight={0.7}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          color={
                                            (treatmentData.treatmentPlan &&
                                              treatmentData.treatmentPlan
                                                .status === 'active') ||
                                            (treatmentData.treatmentPlan &&
                                              treatmentData.treatmentPlan
                                                .status === 'completed')
                                              ? 'primary'
                                              : 'error'
                                          }
                                          style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 10,

                                            fontWeight: 600,
                                            lineHeight: 'normal',
                                          }}
                                        >
                                          {treatmentData.procedure ===
                                            undefined && (
                                            <Typography
                                              variant='body2'
                                              color={
                                                (treatmentData.treatmentPlan &&
                                                  treatmentData.treatmentPlan
                                                    .status === 'active') ||
                                                (treatmentData.treatmentPlan &&
                                                  treatmentData.treatmentPlan
                                                    .status === 'completed') ||
                                                treatmentData.procedure
                                                  ? 'primary'
                                                  : 'error'
                                              }
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 10,

                                                fontWeight: 600,
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              {' '}
                                              {treatmentData.treatmentPlan &&
                                                treatmentData.procedure ===
                                                  undefined &&
                                                getOrderStatusText(
                                                  treatmentData.treatmentPlan
                                                )}
                                            </Typography>
                                          )}

                                          {treatmentData.procedure && (
                                            <Typography
                                              variant='body2'
                                              color='primary'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 10,

                                                fontWeight: 600,
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              {' '}
                                              Completed
                                            </Typography>
                                          )}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display='flex'
                                      width='2%'
                                      justifyContent='flex-end'
                                      flexGrow
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: '30px',

                                          width: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container direction='row' id='vitals'>
                                <Grid item xs={12}>
                                  <Box width='100%' paddingLeft={0.2}>
                                    <Divider
                                      orientation='horizontal'
                                      flexItem
                                      style={{
                                        height: '2px',
                                      }}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                      </Box>
                    )
                  )}
                </Grid>
              </Box>
            </Grid>
          )}
      </Grid>

      <NoteDialog
        id='treatment_notes_tile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
        dialogText='Notes'
      />
    </Box>
  )
}
