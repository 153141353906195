import { Box, Button, Typography } from '@material-ui/core'

import React, { useState } from 'react'

export interface RadioButtonProps {
  selectedValue: any
  availableOptions: any[]
  onValueChanged: (selectedValues: any) => void
  valuesField: string
  displayField: string
  allowChanging: boolean
  preSelected?: string
}

export const RadioRaisedButton: React.FC<RadioButtonProps> = ({
  selectedValue,
  availableOptions,
  allowChanging,
  onValueChanged,
  valuesField,
  displayField,
  preSelected,
}: RadioButtonProps) => {
  const [editMode, setEditMode] = useState<boolean>(allowChanging)
  const [selectedItem, setSelectedItem] = useState<any>(
    selectedValue || undefined
  )
  console.log(selectedItem)

  return (
    <Box display='flex' flexDirection='row' m={0}>
      {availableOptions.map((val, index) => (
        <Box
          key={`rad_button${val[valuesField]}`}
          paddingX={index === 0 ? 0 : 1}
          paddingRight={index === 0 ? 0 : 0}
          id={`rad_button${val[valuesField]}`}
        >
          <span
            style={{
              cursor: 'not-allowed',
              pointerEvents: allowChanging ? 'inherit' : 'none',
            }}
          >
            <Button
              disableElevation={false}
              disableFocusRipple={!allowChanging}
              disableRipple={!allowChanging}
              disableTouchRipple={!allowChanging}
              id={`${index}_${val[valuesField]}`}
              variant='contained'
              onClick={() => {
                if (allowChanging) {
                  setSelectedItem(val)
                  onValueChanged(val)
                }
              }}
              color={
                selectedItem
                  ? val[valuesField] === selectedItem[valuesField]
                    ? 'primary'
                    : 'default'
                  : 'default'
              }
            >
              <Typography variant='subtitle2'>{val[displayField]}</Typography>
            </Button>
          </span>
        </Box>
      ))}
    </Box>
  )
}
