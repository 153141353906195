import { R4 } from '@ahryman40k/ts-fhir-types'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirClinicTherapyBasic } from 'models/fhirClinicTherapyBasic'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import moment from 'moment'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { WelloClinicAPIClient } from 'services/welloclinicAPIService'
import { sleep } from 'utils/dateUtil'
import {
  getNameFromHumanName,
  getNameOfPatient,
} from 'utils/fhirResourcesHelper'
import { getExpandedIPDAppointmentFromBundle } from './ipdAppointmentHelper'
import { getTreatmentPlanFromBundle } from './ipdTreatmentHelper'

export async function getVisitDetailsForTherapist(
  id: string
): Promise<
  FhirActiveIPDDetailsForMedicalRole | FHIRErrorResponses | undefined
> {
  try {
    const fhirClient: WelloClinicAPIClient = new WelloClinicAPIClient()

    const response: any = await fhirClient.doGetRequest(`/ipd/${id}`, {})

    if (response.type === 'FHIRErrorResponses') {
      return response
    }
    const fhirAppointments: FhirActiveIPDDetailsForMedicalRole[] =
      getExpandedIPDAppointmentFromBundle(response)

    return fhirAppointments[0]
  } catch (error) {}

  return undefined
}

export async function getTherapiesDetails(
  id: string
): Promise<TreatmentPlanData | FHIRErrorResponses | undefined> {
  try {
    const fhirClient: MasterFhirClient = new MasterFhirClient()
    const searchParameters = {
      _id: id,
      _include: 'ServiceRequest:encounter',
      _revinclude: 'Procedure:based-on',
    }

    const response: any = await fhirClient.doGetResourceForAppointment(
      '/ServiceRequest',
      '',
      searchParameters
    )

    if (response.type === 'FHIRErrorResponses') {
      return response
    }

    return getTreatmentPlanFromBundle(response as R4.IBundle, '')[0]
  } catch (error) {}

  return undefined
}

export function getTherapiesPlanFromBasic(
  basic: FhirClinicTherapyBasic,
  encounter: R4.IEncounter
): TreatmentPlanData {
  const bundleEntry: R4.IBundle_Entry[] = [
    {
      resource: basic.therapy,
    },
    {
      resource: encounter,
    },
  ]

  if (basic.procedure) {
    bundleEntry.push({
      resource: basic.procedure,
    })
  }
  const bundle: R4.IBundle = {
    resourceType: 'Bundle',
    total: 2,
    entry: bundleEntry,
  }

  return getTreatmentPlanFromBundle(bundle, '')[0]
}

export async function updateTherapyPerformDetails(
  appointment: FhirActiveIPDDetailsForMedicalRole,
  treatMent: TreatmentPlanData,
  selectedPractWithRole: PractitionerWithRole[]
): Promise<boolean | FHIRErrorResponses | undefined> {
  try {
    const bundleObject: R4.IBundle = createBundleObjectForObservations(
      appointment,
      treatMent,
      undefined,
      undefined,
      undefined,
      selectedPractWithRole
    )

    const resource: any = {
      eventType: 'vitals',
      eventBody: bundleObject,
    }

    const enRolClient: EnrolCient = new EnrolCient()
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `ipd/${appointment.mainServiceRequest.id}/addIpdEventDetails`,
      resource
    )

    if (response.status === 'VitalsDetails Of Patient added') {
      await sleep(5000)
      return true
    }

    return false
  } catch (error) {}

  return undefined
}

export async function cancelTherapy(
  fhirAppointmentDetail: FhirActiveIPDDetailsForMedicalRole,
  treatMentRequest: TreatmentPlanData,
  selectedCancellationReason: R4.ICoding
): Promise<boolean | FHIRErrorResponses | undefined> {
  try {
    const bundleObject: R4.IBundle = createBundleRequestForCancel(
      treatMentRequest.treatmentPlan!,
      selectedCancellationReason
    )

    const appointmentType = fhirAppointmentDetail.mainServiceRequest.code
      ? fhirAppointmentDetail.mainServiceRequest.code.coding
        ? fhirAppointmentDetail.mainServiceRequest.code.coding[0].code ?? ''
        : ''
      : ''
    const ipdDayCare: boolean = appointmentType === '304903009'
    const consultation: boolean = appointmentType === '33022008'

    const resource: any = {
      isOpdConsult: consultation,
      isOpdDayCare: ipdDayCare,
      actionType: 'cancel',
      actionBody: bundleObject,
    }

    const enRolClient: EnrolCient = new EnrolCient()
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `ipd/${fhirAppointmentDetail.mainServiceRequest.id}/addIpdCarePlanDetails`,
      resource
    )

    if (response.status === 'Updated Care Plans for IPD') {
      await sleep(5000)
      return true
    }

    return false
  } catch (error) {}

  return undefined
}

function createBundleObjectForObservations(
  appointment: FhirActiveIPDDetailsForMedicalRole,
  treatment: TreatmentPlanData,
  startTime?: string,
  endTime?: string,
  notes?: string,
  selectedPractWithRole?: PractitionerWithRole[]
): R4.IBundle {
  const oldUpdatedServiceRequest: R4.IServiceRequest = {
    ...treatment.treatmentPlan!,
  }
  const oldAppointmentMatchString: string = `W/${JSON.stringify(
    oldUpdatedServiceRequest.meta?.versionId ?? ' '
  )}`
  oldUpdatedServiceRequest.status = 'completed'

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `${oldUpdatedServiceRequest.resourceType}/${oldUpdatedServiceRequest.id}`,
        request: {
          ifMatch: oldAppointmentMatchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `${oldUpdatedServiceRequest.resourceType}/${oldUpdatedServiceRequest.id}`,
        },
        resource: { ...oldUpdatedServiceRequest, status: 'completed' },
      },
    ],
  }

  const practitioner: R4.IPractitioner = getCurrentUserPractitionerDetails()
  const practitionerRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()

  const codeData: R4.ICoding = {
    code: '386053000',
    display: 'Evaluation procedure',
    system: 'http://snomed.info/sct',
  }

  let startTimeData: string = treatment.treatmentPlan
    ? treatment.treatmentPlan.occurrencePeriod?.start ?? ''
    : ''
  let endTimeData: string = treatment.treatmentPlan
    ? treatment.treatmentPlan.occurrencePeriod?.end ?? ''
    : ''
  if (startTime) {
    const newDate = new Date(
      `${moment(treatment.date).format('YYYY-MM-DD')}T${startTime ?? ''}`
    )

    const isoStartDateTIme = newDate.toISOString()
    startTimeData = isoStartDateTIme
  }

  if (endTime) {
    const newDateEnd = new Date(
      `${moment(treatment.date).format('YYYY-MM-DD')}T${endTime}`
    )
    const isoEndDateTIme = newDateEnd.toISOString()
    endTimeData = isoEndDateTIme
  }

  const performerDetails: R4.IProcedure_Performer[] = []

  if (selectedPractWithRole) {
    for (let i = 0; i < selectedPractWithRole.length; i++) {
      const performers: R4.IProcedure_Performer = {
        actor: {
          display: selectedPractWithRole[i].name,
          id: selectedPractWithRole[i].practRoleId ?? '',
          reference: selectedPractWithRole[i].roleObject
            ? `${selectedPractWithRole[i].roleObject.resourceType}/${selectedPractWithRole[i].roleObject.id}`
            : '',
          type: selectedPractWithRole[i].roleObject
            ? selectedPractWithRole[i].roleObject.resourceType
            : '',
        },
      }
      performerDetails.push(performers)
    }
    // const performersCurrent: R4.IProcedure_Performer = {
    //   actor: {
    //     display: getNameFromHumanName(practitioner.name ?? []),
    //     id: practitionerRole.id,
    //     reference: `${practitionerRole.resourceType}/${practitionerRole.id}`,
    //     type: practitionerRole.resourceType,
    //   },
    // }
    // performerDetails.push(performersCurrent)
  } else {
    const performers: R4.IProcedure_Performer = {
      actor: {
        display: getNameFromHumanName(practitioner.name ?? []),
        id: practitionerRole.id,
        reference: `${practitionerRole.resourceType}/${practitionerRole.id}`,
        type: practitionerRole.resourceType,
      },
    }
    performerDetails.push(performers)
  }

  const condition: R4.IProcedure = {
    resourceType: 'Procedure',
    subject: {
      display: getNameOfPatient(appointment.patient),
      id: appointment.patient.id,
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      type: appointment.patient.resourceType,
    },
    basedOn: [
      {
        reference: `${appointment.mainServiceRequest.resourceType}/${appointment.mainServiceRequest.id}`,
      },
      {
        reference: `${treatment.treatmentPlan?.resourceType}/${treatment.treatmentPlan?.id}`,
      },
    ],
    performedPeriod: {
      start: startTimeData,
      end: endTimeData,
    },
    code: {
      coding: [
        treatment.treatmentPlan
          ? treatment.treatmentPlan.code
            ? treatment.treatmentPlan.code.coding
              ? treatment.treatmentPlan.code.coding[0]
              : codeData
            : codeData
          : codeData,
      ],
      text: treatment.treatmentPlan
        ? treatment.treatmentPlan.code
          ? treatment.treatmentPlan.code.coding
            ? treatment.treatmentPlan.code.coding[0].display ?? ''
            : ''
          : ''
        : '',
    },

    recorder: {
      display: getNameFromHumanName(practitioner.name ?? []),
      id: practitionerRole.id,
      reference: `${practitionerRole.resourceType}/${practitionerRole.id}`,
      type: practitionerRole.resourceType,
    },
    performer: performerDetails,
    note: [
      {
        text: notes ?? '',
      },
    ],

    category: {
      coding: [
        {
          code: '1000',
          display: 'TLC Procedure',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-procedure-category-cs',
        },
      ],
    },
  }

  requestBundle.entry?.push({
    fullUrl: `${condition.resourceType}/`,
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: condition.resourceType,
    },
    resource: condition,
  })

  return requestBundle
}

function createBundleRequestForCancel(
  treatMent: R4.IServiceRequest,
  notes: R4.ICoding
): R4.IBundle {
  const Practitioner: R4.IPractitioner = getCurrentUserPractitionerDetails()
  const PractitionerRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()

  const oldUpdatedServiceRequest: R4.IServiceRequest = {
    ...treatMent,
  }
  const oldAppointmentMatchString: string = `W/${JSON.stringify(
    oldUpdatedServiceRequest.meta?.versionId ?? ' '
  )}`
  oldUpdatedServiceRequest.status = 'revoked'
  oldUpdatedServiceRequest.doNotPerform = true
  oldUpdatedServiceRequest.reasonCode = [
    {
      coding: [notes],
    },
  ]

  if (oldUpdatedServiceRequest.performer) {
    oldUpdatedServiceRequest.performer.push({
      display: getNameFromHumanName(Practitioner.name ?? []),
      id: PractitionerRole.id,
      reference: `${PractitionerRole.resourceType}/${PractitionerRole.id}`,
      type: PractitionerRole.resourceType,
    })
  }
  //   oldUpdatedServiceRequest.performer = [
  //     {
  //       display: getNameFromHumanName(Practitioner.name ?? []),
  //       id: PractitionerRole.id,
  //       reference: `${PractitionerRole.resourceType}/${PractitionerRole.id}`,
  //       type: PractitionerRole.resourceType,
  //     },
  //   ]

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `${oldUpdatedServiceRequest.resourceType}/${oldUpdatedServiceRequest.id}`,
        request: {
          ifMatch: oldAppointmentMatchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `${oldUpdatedServiceRequest.resourceType}/${oldUpdatedServiceRequest.id}`,
        },
        resource: oldUpdatedServiceRequest,
      },
    ],
  }

  return requestBundle
}
