import { R4 } from '@ahryman40k/ts-fhir-types'
import { List, ListSubheader } from '@material-ui/core'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import React from 'react'
import { getCompleteDateStringWithOutDay } from 'utils/dateUtil'
import { getDateWiseOrders } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { LabOrderTile } from 'views/components/lab/orderList/order_tile'
import { MedicineOrderTile } from './medicineOrderTile'

interface Props {
  labOrders?: any[]
  onLabOrderSelected?: (selectedOrder: any, patientData?: R4.IPatient) => void
  dense?: boolean
}

export const MedicineOrderListView: React.FC<Props> = ({
  labOrders,
  onLabOrderSelected,

  dense = false,
}: Props) => (
  <List
    subheader={<li style={{ padding: 0, width: '100%' }} />}
    style={{ padding: 0, width: '100%', overflow: 'auto', height: '100%' }}
  >
    {labOrders?.map((item, index) => (
      <li
        key={`section-${item.salesorder_id ?? ''}`}
        style={{ padding: 0, width: '100%' }}
      >
        <ul style={{ padding: 0 }}>
          <MedicineOrderTile
            labOrderDetail={item}
            isOdd={index % 2 === 0}
            dense={dense}
            isSelected={false}
            // onDownloadClicked={() => {
            //   if (onDownloadClicked) {
            //     onDownloadClicked(item)
            //   }
            // }}
            onTap={(patient?: R4.IPatient) => {
              if (onLabOrderSelected) {
                onLabOrderSelected(item, patient)
              }
            }}
            // onUpload={() => {
            //   if (onUploadReport) {
            //     onUploadReport(item)
            //   }
            // }}
            key={`lab_item_key${item.salesorder_id}` ?? ''}
          />
        </ul>
      </li>
    ))}
  </List>
)
