/* eslint-disable react/jsx-key */
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { resetProdcedureState } from 'redux/ipd/procedure/addProcedureIpdSlice'
import { requestForTreatment } from 'redux/ipd/treatment/viewTreatment/treatmentSearchSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import {
  getNotes,
  getOrderStatusText,
} from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { CancelTherapy } from './cancelTherapy'
import { TherapyPerform } from './therapyDetails'

interface AssessmentCaptureProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
}

export const TreatmentCardForTherapist: React.FC<AssessmentCaptureProps> = ({
  fhirAppointmentDetails,
}: AssessmentCaptureProps) => {
  const checkinDate = moment(
    fhirAppointmentDetails.mainServiceRequest.occurrencePeriod?.start
  ).format('YYYY-MM-DD')
  const checkOutDate = moment(
    fhirAppointmentDetails.mainServiceRequest.occurrencePeriod?.end
  ).format('YYYY-MM-DD')

  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()

  const addProcedureDetailsSlice = useSelector(
    (state: RootState) => state.addProcedureDetailsSlice
  )

  const treatmentSearchSlice = useSelector(
    (state: RootState) => state.treatmentSearchSlice
  )

  const [fullText, setFullText] = useState<string>('')
  const [showText, setShowText] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [showTherapyDetails, setShowTherapyDetails] = useState<boolean>(false)
  const [showCancelTherapyDetails, setShowCancelTherapyDetails] =
    useState<boolean>(false)
  const [plan, setPlan] = useState<TreatmentPlanData>()
  const selectedPlan = useRef<TreatmentPlanData>()

  useEffect(() => {
    dispatch(requestForTreatment(fhirAppointmentDetails.mainServiceRequest.id!))
  }, [dispatch])

  return (
    <Box display='flex' flexDirection='row' sx={{ borderRadius: 16 }}>
      <Box width='100%'>
        <Grid container spacing={2} direction='column'>
          <Grid item>
            <ReactVisibilitySensor
              onChange={(isVisible) => {
                if (isVisible) {
                  dispatch(setSelectedSection({ selectedSection: 'diagnosis' }))
                }
              }}
            >
              <Grid container direction='column'>
                <Grid item xl>
                  <Box height='100%' py={1} borderRadius={4} px={1}>
                    <Grid
                      container
                      direction='row'
                      alignContent='space-between'
                      justify='space-between'
                    >
                      <Grid container direction='row' justify='flex-start'>
                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            width='100%'
                            flexDirection='row'
                            justifyContent='flex-start'
                          >
                            <Typography
                              variant='body1'
                              color='primary'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              Date
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <Box
                            display='flex'
                            width='100%'
                            flexDirection='row'
                            justifyContent='flex-start'
                          >
                            <Typography
                              variant='body1'
                              color='primary'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              Therapy
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            width='100%'
                            flexDirection='row'
                            justifyContent='flex-start'
                          >
                            <Typography
                              variant='body1'
                              color='primary'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              Start Time
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            width='100%'
                            flexDirection='row'
                            justifyContent='flex-start'
                          >
                            <Typography
                              variant='body1'
                              color='primary'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              End Time
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            width='100%'
                            flexDirection='row'
                            justifyContent='flex-start'
                          >
                            <Typography
                              variant='body1'
                              color='primary'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              Actual Start
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            width='100%'
                            flexDirection='row'
                            justifyContent='flex-start'
                          >
                            <Typography
                              variant='body1'
                              color='primary'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              Actual End
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={2}>
                          <Box
                            display='flex'
                            width='100%'
                            flexDirection='row'
                            justifyContent='flex-start'
                          >
                            <Typography
                              variant='body1'
                              color='primary'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              Notes
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            width='100%'
                            flexDirection='row'
                            justifyContent='flex-start'
                          >
                            <Typography
                              variant='body1'
                              color='primary'
                              style={{ fontWeight: 600 }}
                            >
                              Performed By
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            width='100%'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Typography
                              variant='body1'
                              color='primary'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              Status
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            width='100%'
                            flexDirection='row'
                            justifyContent='flex-start'
                          >
                            <Typography
                              variant='body1'
                              color='primary'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                {treatmentSearchSlice.searchingTreatment && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                    px={1}
                  >
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      align='center'
                    >
                      <CircularProgress size={25} />
                    </Typography>
                  </Box>
                )}

                {treatmentSearchSlice.errorWhileSearchingTreatment && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      align='center'
                    >
                      Some Error has occured
                    </Typography>
                  </Box>
                )}

                {treatmentSearchSlice.noResultsAvailable && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      align='center'
                    >
                      No Treatment Plan available
                    </Typography>
                  </Box>
                )}

                {treatmentSearchSlice.resultsAvailable &&
                  treatmentSearchSlice.treatmentList &&
                  treatmentSearchSlice.treatmentList.length === 0 && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='100%'
                      height='100%'
                      alignContent='center'
                      px={1}
                    >
                      <Typography
                        variant='subtitle1'
                        color='initial'
                        align='center'
                      >
                        No Treatment Plan available
                      </Typography>
                    </Box>
                  )}

                {treatmentSearchSlice.resultsAvailable &&
                  treatmentSearchSlice.treatmentList &&
                  treatmentSearchSlice.treatmentList.length > 0 && (
                    <Grid item xl>
                      <Box height='100%' px={1}>
                        <Grid
                          container
                          direction='row'
                          alignContent='space-between'
                          justify='space-between'
                        >
                          {treatmentSearchSlice.treatmentList.map(
                            (
                              treatmentData: TreatmentPlanData,
                              index: number
                            ) => (
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                bgcolor={
                                  index % 2 === 0 ? kPrimaryLight : undefined
                                }
                              >
                                {treatmentData.treatmentPlan &&
                                  treatmentData.treatmentPlan.status !==
                                    'unknown' && (
                                    <Box
                                      width='100%'
                                      bgcolor={
                                        index % 2 === 0
                                          ? kPrimaryLight
                                          : undefined
                                      }
                                    >
                                      <Grid
                                        container
                                        direction='row'
                                        justify='flex-start'
                                      >
                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            width='100%'
                                            flexDirection='row'
                                            justifyContent='flex-start'
                                            py={2}
                                            px={0.5}
                                          >
                                            {' '}
                                            <Typography
                                              variant='body2'
                                              color='initial'
                                            >
                                              {' '}
                                              {moment(
                                                treatmentData.date
                                              ).format('DD-MM-YYYY')}
                                            </Typography>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Box
                                            display='flex'
                                            width='100%'
                                            flexDirection='row'
                                            justifyContent='flex-start'
                                            py={2}
                                          >
                                            <Typography
                                              variant='body2'
                                              color='initial'
                                            >
                                              {' '}
                                              {treatmentData.therapy}{' '}
                                              {treatmentData.treatmentType
                                                ? `- ${treatmentData.treatmentType}`
                                                : ''}
                                            </Typography>
                                          </Box>
                                        </Grid>

                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            width='100%'
                                            px={1}
                                            flexDirection='row'
                                            justifyContent='flex-start'
                                            paddingLeft={0.1}
                                            py={2}
                                          >
                                            <Typography
                                              variant='body2'
                                              color='initial'
                                            >
                                              {' '}
                                              {treatmentData.startTime}
                                            </Typography>
                                          </Box>
                                        </Grid>

                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            width='100%'
                                            flexDirection='row'
                                            justifyContent='flex-start'
                                            paddingLeft={0.1}
                                            py={2}
                                          >
                                            <Typography
                                              variant='body2'
                                              color='initial'
                                            >
                                              {' '}
                                              {treatmentData.endTime}
                                            </Typography>
                                          </Box>
                                        </Grid>

                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            width='100%'
                                            flexDirection='row'
                                            justifyContent='flex-start'
                                            py={2}
                                          >
                                            <Typography
                                              variant='body2'
                                              color='initial'
                                            >
                                              {' '}
                                              {treatmentData.actualStart &&
                                              treatmentData.actualStart !==
                                                'Invalid Date'
                                                ? treatmentData.actualStart
                                                : ''}
                                            </Typography>
                                          </Box>
                                        </Grid>

                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            width='100%'
                                            flexDirection='row'
                                            justifyContent='flex-start'
                                            py={2}
                                          >
                                            <Typography
                                              variant='body2'
                                              color='initial'
                                            >
                                              {' '}
                                              {treatmentData.actualEnd &&
                                              treatmentData.actualEnd !==
                                                'Invalid Date'
                                                ? treatmentData.actualEnd
                                                : ''}
                                            </Typography>
                                          </Box>
                                        </Grid>

                                        <Grid item xs={2}>
                                          <Box
                                            display='flex'
                                            width='100%'
                                            flexDirection='row'
                                            justifyContent='flex-start'
                                            py={2}
                                          >
                                            {treatmentData.treatmentPlan &&
                                              treatmentData.treatmentPlan
                                                .status !== 'revoked' && (
                                                <Typography
                                                  variant='body2'
                                                  color='initial'
                                                >
                                                  {' '}
                                                  {treatmentData.treatmentPlan
                                                    .note
                                                    ? treatmentData
                                                        .treatmentPlan.note[0]
                                                        .text ?? ''
                                                    : ''}
                                                </Typography>
                                              )}
                                            {treatmentData.treatmentPlan &&
                                              treatmentData.treatmentPlan
                                                .status === 'revoked' && (
                                                <Typography
                                                  variant='body2'
                                                  color='initial'
                                                >
                                                  {' '}
                                                  {treatmentData.treatmentPlan
                                                    .note
                                                    ? treatmentData
                                                        .treatmentPlan.note[0]
                                                        .text ?? ''
                                                    : treatmentData
                                                        .treatmentPlan
                                                        .reasonCode
                                                    ? treatmentData
                                                        .treatmentPlan
                                                        .reasonCode[0]
                                                      ? treatmentData
                                                          .treatmentPlan
                                                          .reasonCode[0].coding
                                                        ? treatmentData
                                                            .treatmentPlan
                                                            .reasonCode[0]
                                                            .coding[0]
                                                          ? treatmentData
                                                              .treatmentPlan
                                                              .reasonCode[0]
                                                              .coding[0]
                                                              .display ?? ''
                                                          : ''
                                                        : ''
                                                      : ''
                                                    : ''}
                                                </Typography>
                                              )}

                                            {treatmentData.procedure && (
                                              <Box>
                                                <Typography
                                                  variant='body2'
                                                  color='initial'
                                                  style={{
                                                    paddingLeft: 2,
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={() => {
                                                    if (
                                                      getNotes(
                                                        treatmentData.procedure!
                                                      ).length > 60
                                                    ) {
                                                      setFullText(
                                                        getNotes(
                                                          treatmentData.procedure!
                                                        )
                                                      )
                                                      setShowText(true)
                                                    }
                                                  }}
                                                >
                                                  {getNotes(
                                                    treatmentData.procedure!
                                                  ).slice(0, 60)}
                                                  {getNotes(
                                                    treatmentData.procedure!
                                                  ).length > 60
                                                    ? '...'
                                                    : null}
                                                </Typography>

                                                {/* <Typography
                                            variant='body1'
                                            color='initial'
                                            style={{
                                              paddingLeft: 2,
                                              cursor: 'pointer',
                                            }}
                                            //   component=''
                                            onClick={() => {
                                              if (
                                                getNotes(
                                                  treatmentData.procedure
                                                ).length > 60
                                              ) {
                                                setFullText(
                                                  ipdAppointmentDetails.notes
                                                )
                                                setShowText(true)
                                              }
                                            }}
                                          >
                                            {ipdAppointmentDetails.notes.slice(
                                              0,
                                              60
                                            )}
                                          </Typography> */}
                                              </Box>
                                            )}
                                          </Box>
                                        </Grid>

                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            width='100%'
                                            flexDirection='row'
                                            justifyContent='flex-start'
                                            py={2}
                                          >
                                            {treatmentData.treatmentPlan &&
                                              treatmentData.treatmentPlan
                                                .status === 'revoked' && (
                                                <Typography
                                                  variant='body2'
                                                  color='initial'
                                                  style={{ fontWeight: 'bold' }}
                                                >
                                                  {' '}
                                                  {treatmentData.treatmentPlan
                                                    .performer
                                                    ? treatmentData
                                                        .treatmentPlan
                                                        .performer[0].display ??
                                                      ''
                                                    : ''}
                                                </Typography>
                                              )}
                                            {treatmentData.procedure && (
                                              <Typography
                                                variant='body2'
                                                color='initial'
                                                style={{ fontWeight: 'bold' }}
                                              >
                                                {' '}
                                                {treatmentData.procedure.performer
                                                  ?.map(
                                                    (e) => e.actor.display ?? ''
                                                  )
                                                  .join(', ')}
                                              </Typography>
                                            )}
                                          </Box>
                                        </Grid>

                                        <Grid item xs={1}>
                                          <Box
                                            display='flex'
                                            width='100%'
                                            flexDirection='row'
                                            justifyContent='center'
                                            py={2}
                                          >
                                            <Typography
                                              variant='body2'
                                              color={
                                                (treatmentData.treatmentPlan &&
                                                  treatmentData.treatmentPlan
                                                    .status === 'active') ||
                                                (treatmentData.treatmentPlan &&
                                                  treatmentData.treatmentPlan
                                                    .status === 'completed')
                                                  ? 'primary'
                                                  : 'error'
                                              }
                                            >
                                              {treatmentData.procedure ===
                                                undefined && (
                                                <Typography
                                                  variant='body2'
                                                  color={
                                                    (treatmentData.treatmentPlan &&
                                                      treatmentData
                                                        .treatmentPlan
                                                        .status === 'active') ||
                                                    (treatmentData.treatmentPlan &&
                                                      treatmentData
                                                        .treatmentPlan
                                                        .status ===
                                                        'completed') ||
                                                    treatmentData.procedure
                                                      ? 'primary'
                                                      : 'error'
                                                  }
                                                >
                                                  {' '}
                                                  {treatmentData.treatmentPlan &&
                                                    treatmentData.procedure ===
                                                      undefined &&
                                                    getOrderStatusText(
                                                      treatmentData.treatmentPlan
                                                    )}
                                                </Typography>
                                              )}

                                              {treatmentData.procedure && (
                                                <Typography
                                                  variant='body2'
                                                  color='primary'
                                                >
                                                  {' '}
                                                  Completed
                                                </Typography>
                                              )}
                                            </Typography>
                                          </Box>
                                        </Grid>
                                        {treatmentData.procedure ===
                                          undefined &&
                                          treatmentData.treatmentPlan &&
                                          treatmentData.treatmentPlan.status !==
                                            'revoked' &&
                                          fhirAppointmentDetails
                                            .mainServiceRequest.status !==
                                            'completed' && (
                                            <Grid item xs={1}>
                                              <Box
                                                display='flex'
                                                width='100%'
                                                flexDirection='row'
                                                justifyContent='center'
                                              >
                                                <Button
                                                  id='fade-button'
                                                  aria-controls={
                                                    open
                                                      ? 'fade-menu'
                                                      : undefined
                                                  }
                                                  aria-haspopup='true'
                                                  aria-expanded={
                                                    open ? 'true' : undefined
                                                  }
                                                  onClick={(event: any) => {
                                                    setAnchorEl(
                                                      event.currentTarget
                                                    )
                                                    //   selectedPlan.current = undefined
                                                  }}
                                                >
                                                  <IconButton
                                                    color='primary'
                                                    id='long-button'
                                                    onClick={() => {
                                                      selectedPlan.current =
                                                        treatmentData
                                                      setPlan(treatmentData)
                                                    }}
                                                  >
                                                    <MoreVert />
                                                  </IconButton>
                                                </Button>
                                                <Menu
                                                  id='fade-menu'
                                                  MenuListProps={{
                                                    'aria-labelledby':
                                                      'fade-button',
                                                  }}
                                                  anchorEl={anchorEl}
                                                  open={open}
                                                  onClose={handleClose}
                                                  TransitionComponent={Fade}
                                                >
                                                  <MenuItem
                                                    onClick={() => {
                                                      setShowTherapyDetails(
                                                        true
                                                      )
                                                      setAnchorEl(null)
                                                    }}
                                                  >
                                                    Mark as Complete
                                                  </MenuItem>
                                                  <MenuItem
                                                    onClick={() => {
                                                      setShowCancelTherapyDetails(
                                                        true
                                                      )
                                                      setAnchorEl(null)
                                                    }}
                                                  >
                                                    Cancel Therapy
                                                  </MenuItem>
                                                </Menu>
                                              </Box>
                                            </Grid>
                                          )}
                                      </Grid>
                                    </Box>
                                  )}
                              </Box>
                            )
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  )}
              </Grid>
            </ReactVisibilitySensor>
          </Grid>
        </Grid>
      </Box>
      {plan && (
        <TherapyPerform
          open={showTherapyDetails}
          onLabOrderCreated={() => {
            selectedPlan.current = undefined
            setAnchorEl(null)
            dispatch(resetProdcedureState())
            setPlan(undefined)
            setShowTherapyDetails(false)
          }}
          onClose={() => {
            setAnchorEl(null)
            selectedPlan.current = undefined
            setShowTherapyDetails(false)
            setPlan(undefined)
          }}
          fhirAppointmentDetails={fhirAppointmentDetails}
          treatmentPlanData={plan!}
        />
      )}

      <NoteDialog
        id='treatment_notes_therapist'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
        dialogText='Notes'
      />

      <CancelTherapy
        open={showCancelTherapyDetails}
        onLabOrderCreated={() => {
          setAnchorEl(null)
          dispatch(
            requestForTreatment(fhirAppointmentDetails.mainServiceRequest.id!)
          )
          setPlan(undefined)

          setShowCancelTherapyDetails(false)
        }}
        onClose={() => {
          setShowCancelTherapyDetails(false)
          setAnchorEl(null)
          selectedPlan.current = undefined

          setPlan(undefined)
        }}
        fhirAppointmentDetails={fhirAppointmentDetails}
        treatmentPlanData={plan}
      />
    </Box>
  )
}
