import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Chip, CircularProgress, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import _, { debounce } from 'lodash'
import React, { useCallback } from 'react'
import { titleCase } from 'utils/fhirResourcesHelper'
import { getValueSetLabelWithCoding } from 'utils/fhirResoureHelpers/organizationHelpers'
import { getCodesOfValueSetFromTerminologyService } from 'utils/valusetsHelpers'
import { WelloFormItemLabel } from '../LeftMenu/WelloFormItemLabel'

export interface ValueSetSearchProps {
  id: string
  url: string
  title: string
  error?: boolean
  preSelectedOptions?: R4.ICoding[]
  onOptionSelected: (selectedOption: R4.ICoding[]) => void
  helperText?: string
  disabled: boolean
  singularWorld?: string
  pluralWord?: string
  fuzzySearch?: boolean
  displaySelectedOptions?: boolean
  hideTitle?: boolean
}

export const ValueSetSelectMultiple: React.FC<ValueSetSearchProps> = ({
  id,
  url,
  title,
  preSelectedOptions,
  onOptionSelected,
  helperText,
  disabled,
  singularWorld,
  pluralWord,
  fuzzySearch = false,
  displaySelectedOptions = true,
  hideTitle = false,
  error,
}) => {
  const [value, setValue] = React.useState<R4.ICoding[]>(
    preSelectedOptions ?? []
  )
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<R4.ICoding[]>([])
  const [loading, setLoading] = React.useState(false)

  function isSameAsSelectedOption(option: string) {
    if (value && value.length > 0) {
      const codes = value.map((v) => v.code)

      return codes.includes(option)
    }
    return false
  }

  function getSelectedLabel(count: number): string {
    if (count === 0) {
      return `Select ${title}s`
    }
    if (count > 0) {
      if (count === 1) {
        return `${count} ${singularWorld ?? 'item'} selected`
      }

      return `${count} ${pluralWord ?? 'items'} selected`
    }
    return `Select ${title}s`
  }

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      getCodesOfValueSetFromTerminologyService({
        valueSetUrl: url,
        fuzzySearch,
        searchString: inputText,
      }).then((response) => {
        callback(response)
      })
    }, 1000),
    []
  )

  React.useEffect(() => {
    setLoading(true)
    loadOptions(inputValue, (response: R4.ICoding[]) => {
      setLoading(false)
      setOptions(response)
    })
  }, [inputValue])

  return (
    <Box display='flex' flexDirection='column' flexGrow={1}>
      {!hideTitle && (
        <Box py={1} px={0.25} display='flex' flexDirection='row'>
          <Typography variant='subtitle2'>{title}</Typography>
        </Box>
      )}
      <Autocomplete
        id={id}
        limitTags={0}
        renderTags={() => <div />}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : titleCase(option.display ?? '')
        }
        filterOptions={(x) => x}
        options={options}
        disabled={disabled}
        multiple={true}
        disableClearable={true}
        loading={loading}
        noOptionsText='No options'
        loadingText='Searching...'
        autoComplete
        getOptionSelected={(option, val) => option.code === val.code}
        filterSelectedOptions
        value={value}
        onChange={(event: any, newValue: R4.ICoding[] | null) => {
          setValue(newValue ?? [])
          onOptionSelected(newValue ?? [])
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id={`${id}_ edit`}
            variant='outlined'
            fullWidth
            value={inputValue}
            error={!!error}
            placeholder={getSelectedLabel(value.length)}
            InputProps={{
              ...params.InputProps,
              inputProps: { ...params.inputProps, maxLength: '256' },
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color='primary' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            size='small'
            helperText={helperText}
          />
        )}
        renderOption={(option) => {
          const matches = match(titleCase(option.display ?? ''), inputValue)
          const parts = parse(titleCase(option.display ?? ''), matches)

          return (
            <Grid
              container
              alignItems='center'
              style={{
                backgroundColor: isSameAsSelectedOption(option.code ?? '')
                  ? 'red'
                  : undefined,
              }}
            >
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={part.text}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      backgroundColor: isSameAsSelectedOption(option.code ?? '')
                        ? 'red'
                        : undefined,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </Grid>
            </Grid>
          )
        }}
      />
      {value && value.length >= 0 && displaySelectedOptions && (
        <Box display='flex' flexDirection='row' p={0.5} flexWrap='wrap'>
          {value.map((item, index: number) => (
            <Chip
              key={item.code}
              label={titleCase(item.display ?? '')}
              id={`del${index}`}
              size='small'
              style={{ margin: 2 }}
              onDelete={() => {
                const newValue = value.filter(
                  (entry) => entry.code !== item.code
                )
                onOptionSelected(newValue)
                setValue([...newValue])
              }}
            />
          ))}
        </Box>
      )}
    </Box>
  )
}
