/* eslint-disable react/jsx-key */
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { searchMedicalProviders } from 'redux/practitioner/medicalProvidersSearchHandler/medicalProviderSearchSlice'
import { RootState } from 'redux/rootReducer'
import { TherapistListView } from './therapistListView'
// import { OrderListView } from './orderView'

interface Props {
  onPractionerSelected: (practionerList: PractitionerWithRole[]) => void
}

export const TherapistSearch: React.FC<Props> = ({
  onPractionerSelected,
}: Props) => {
  const medicalProvidersSearchSlice = useSelector(
    (state: RootState) => state.medicalProvidersSearchSlice
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (medicalProvidersSearchSlice.resultsAvailable === false) {
      dispatch(searchMedicalProviders('', '', '106296000'))
    }
  }, [dispatch])

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      flexGrow={1}
      height='100%'
    >
      <Box
        display='flex'
        flexGrow={1}
        flexDirection='column'
        justifyContent='center'
        alignContent='center'
        //   paddingTop={1}
      >
        {medicalProvidersSearchSlice.error && (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            width='100%'
            height='100%'
            alignContent='center'
          >
            <Typography variant='subtitle1' color='error' align='center'>
              Error while searching Therapist
            </Typography>
          </Box>
        )}

        {medicalProvidersSearchSlice.searching && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
          >
            <CircularProgress size={25} />
          </Box>
        )}
      </Box>

      {medicalProvidersSearchSlice.resultsAvailable &&
        medicalProvidersSearchSlice.doctorRoleList && (
          <Box>
            {medicalProvidersSearchSlice.doctorRoleList.length > 0 && (
              <Box
                display='flex'
                flexDirection='column'
                flexGrow={1}
                width='100%'
                flexWrap='wrap'
                justifyContent='center'
              >
                <TherapistListView
                  practitionerList={
                    medicalProvidersSearchSlice.doctorRoleList ?? []
                  }
                  onPractitionerSelected={(
                    practionerList: PractitionerWithRole[]
                  ) => {
                    onPractionerSelected(practionerList)
                  }}
                />
              </Box>
            )}
          </Box>
        )}
    </Box>
  )
}
