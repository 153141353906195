import React from 'react'
import { TextField, FormControl, Box } from '@material-ui/core'
import { makeStyles, ThemeProvider, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import dotIcon from '../../../assets/images/dot_icon.png'

const EMAIL_REGEX =
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/

export interface IProps {
  name: string
  label?: string
  type?: 'text' | 'email'
  placeholder?: string
  value?: any
  error?: null | undefined
  onChange?: any
  required: boolean
  size?: 'small' | 'medium' | 'large'
  width?: number
}

export const WelloEmailInput: React.FC<IProps> = ({
  name,
  label,
  type = 'text',
  value,
  error,
  onChange,
  placeholder,
  size,
  width,
  required = false,
}: IProps) => (
  <FormControl {...(error && { error: true })} fullWidth>
    <Box py={1} px={0.25} display='flex' flexDirection='row'>
      <Typography
        style={{
          color: useTheme().palette.text.primary,
        }}
        variant='subtitle2'
      >
        &nbsp;&nbsp;{label} &nbsp;
      </Typography>
    </Box>
    <TextField
      hintText='For example, abcd@gmail.com'
      id={name}
      underlineShow={false}
      variant='outlined'
      name={name}
      value={value}
      fullWidth
      color={useTheme().palette.primary.main}
      type={type}
      size={size}
      required={required}
      // className={classes.inputBoxStyle}
      placeholder={placeholder}
      // InputProps={{ classes: { input: classes.inputBoxStyle,width:10  }}}
      InputProps={{
        style: {
          borderRadius: 6,
          maxWidth: 480,
          width,
          // height: 39,
          padding: '1px',
          color: useTheme().palette.text.primary,

          spellcheck: true,
          backgroundColor: useTheme().palette.common.white,
          '&::placeholder': {
            color: useTheme().palette.text.primary,
            fontSize: 13,
          },
          '&::input': {
            height: 2,
          },
        },
      }}
      onChange={onChange}
      {...(error && { error: true, helperText: error })}
    />
  </FormControl>
)
