import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core'
import { FhirClinicalImpressionDetail } from 'models/fhirClinicalImpression'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { PurposeOfUse } from 'models/purposeOfUse'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactVisibilitySensor from 'react-visibility-sensor'
import {
  addConditionDetailsAndClinicalImpression,
  addConditionDetailsToClinicalImpression,
  addNotesDetailsToClinicalImpression,
  hasSameSummary,
} from 'utils/fhirResoureHelpers/clinicalImpressionHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { logger } from 'utils/logger'
import { SelectCodingsFromValueSet } from 'views/components/common/codingSelectorFromValueSet'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { ViewClinicalImpressionDetails } from './systems/viewSystemDetails'

interface SystemDiagnosisManagementProps {
  encounterId: string
  patientId: string
  categoryCoding: R4.ICoding
  valueSetCatCode: string
  title: string
  otherCoding?: R4.ICoding
  splitView: boolean
}

export const SystemDiagnosisManagement: React.FC<SystemDiagnosisManagementProps> =
  ({
    encounterId,
    patientId,
    categoryCoding,
    valueSetCatCode,
    title,
    otherCoding,
    splitView,
  }: SystemDiagnosisManagementProps) => {
    const [summary, setSummary] = React.useState<string>()
    const [fetchedImp, setFetchedImp] =
      React.useState<FhirClinicalImpressionDetail>()
    const [addingStatus, setAddingStatus] = useState<NetworkRequestStatus>({
      initial: true,
    })
    const { t } = useTranslation(['common'])
    const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())

    const [diagnosedConditions, setDiagnosedConditions] = useState<
      R4.ICoding[]
    >([])

    const [isActive, setIsActive] = useState<boolean>(false)
    const [showText, setShowText] = useState<boolean>(false)
    const [fullText, setFullText] = useState<string>('')

    function addConditionDetails(conditionCoding: R4.ICoding) {
      if (fetchedImp && fetchedImp.clinicalImpression) {
        setAddingStatus({
          requesting: true,
        })

        addConditionDetailsToClinicalImpression(
          [conditionCoding],

          fetchedImp.clinicalImpression,
          categoryCoding,
          patientId,

          encounterId
        )
          .then((e) => {
            if (e) {
              setAddingStatus({
                requestSuccess: true,
              })
              setFetchAgain(getUniqueTempId())
            } else {
              setAddingStatus({
                requestError: true,
              })
            }
          })
          .catch((e) => {
            setAddingStatus({
              requestError: true,
            })
          })
      } else {
        setAddingStatus({
          requesting: true,
        })

        addConditionDetailsAndClinicalImpression(
          [conditionCoding],

          categoryCoding,
          patientId,

          encounterId
        )
          .then((e) => {
            if (e) {
              setAddingStatus({
                requestSuccess: true,
              })
              setFetchAgain(getUniqueTempId())
            } else {
              setAddingStatus({
                requestError: true,
              })
            }
          })
          .catch((e) => {
            setAddingStatus({
              requestError: true,
            })
          })
      }
    }

    function addNotesDetails() {
      if (fetchedImp && fetchedImp.clinicalImpression) {
        setAddingStatus({
          requesting: true,
        })

        addNotesDetailsToClinicalImpression(
          summary ?? '',
          fetchedImp.clinicalImpression,
          categoryCoding,
          encounterId
        )
          .then((e) => {
            if (e) {
              setAddingStatus({
                requestSuccess: true,
              })
              setFetchAgain(getUniqueTempId())
            } else {
              setAddingStatus({
                requestError: true,
              })
            }
          })
          .catch((e) => {
            setAddingStatus({
              requestError: true,
            })
          })
      }
    }

    const updateAssessmentDetails = (
      condition: FhirClinicalImpressionDetail
    ) => {
      if (condition.clinicalImpression) {
        setFetchedImp(condition)
        logger.info('FhirAssessment Details,', condition)
        if (condition.clinicalImpression.summary) {
          setSummary(condition.clinicalImpression.summary)
        }

        setDiagnosedConditions([])
      }
    }

    return (
      <Box
        style={{
          paddingLeft: '4px',
          paddingBottom: splitView ? '5px' : '24px',
        }}
        display='flex'
        flexDirection='row'
        width='100%'
      >
        <Box width='100%' display='flex' flexDirection='column'>
          {addingStatus.requesting && (
            <Box>
              <LinearProgress />
            </Box>
          )}
          <Box width='100%'>
            <ReactVisibilitySensor
              offset={{
                top: 100,
                bottom: 30,
              }}
            >
              <Box
                width='100%'
                display='flex'
                flexDirection='column'
                id='visit_summary'
              >
                <Box width='100%' display='flex' flexDirection='row' py={1}>
                  {!splitView && (
                    <Box
                      width='100%'
                      display='flex'
                      alignItems='center'
                      flexDirection='row'
                      bgcolor='#999999'
                      minHeight={45}
                    >
                      <Box p={2} width='100%'>
                        <Typography
                          variant='body1'
                          style={{ color: 'white', fontWeight: 600 }}
                        >
                          {title}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
                {splitView && (
                  <Box width='100%' py={1}>
                    <Typography
                      variant='subtitle2'
                      style={{ color: '#4D4D4D', textTransform: 'uppercase' }}
                    >
                      {title}
                    </Typography>
                  </Box>
                )}

                <Box width='100%' display='flex' flexDirection='row'>
                  <Box width='100%' display='flex' flexDirection='column'>
                    <Box
                      display='flex'
                      width='70%'
                      maxWidth='600px'
                      flexGrow
                      flexDirection='row'
                    >
                      <ViewClinicalImpressionDetails
                        category={categoryCoding.code ?? ''}
                        encounterId={encounterId}
                        fetchAgain={fetcAgain}
                        patientId={patientId}
                        categoryLabel={splitView ? '' : 'Added Findings'}
                        purposeOfUse={PurposeOfUse.IPDAppointment}
                        status=''
                        allowEditing={!addingStatus.requesting}
                        splitView={splitView}
                        showNotAvailable={splitView}
                        onObservationFetched={(e) => {
                          updateAssessmentDetails(e)
                        }}
                      />
                    </Box>
                    {!addingStatus.requesting && (
                      <Box display='flex'>
                        {!splitView && (
                          <Box flexGrow={1} py={0.5}>
                            <Typography variant='subtitle2'>
                              {`Add ${title} Findings`}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                    {!addingStatus.requesting && (
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='start'
                        alignContent='start'
                        alignItems='start'
                        justifyItems='start'
                        maxWidth='600px'
                      >
                        {!splitView && (
                          <SelectCodingsFromValueSet
                            valueSetType={valueSetCatCode}
                            disabled={addingStatus.requesting ?? false}
                            selectOther={isActive}
                            otherOptionCode={otherCoding}
                            onSelectionChanges={(conditions: R4.ICoding) => {
                              logger.info(conditions)
                              logger.info(conditions)
                              addConditionDetails(conditions)
                            }}
                            onDelete={(conditions: R4.ICoding) => {}}
                            preSelectedComplaints={
                              fetchedImp
                                ? fetchedImp.diagnosedConditionCodes?.filter(
                                    (e) => e.code !== otherCoding?.code
                                  )
                                : []
                            }
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box width='100%' display='flex' flexDirection='row'>
                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='column'
                    maxWidth='600px'
                  >
                    {splitView && summary && (
                      <Box paddingBottom={0.5}>
                        <Typography variant='subtitle2'>Notes</Typography>
                      </Box>
                    )}
                    {!splitView && (
                      <Box paddingBottom={0.5}>
                        <Typography variant='subtitle2'>Notes</Typography>
                      </Box>
                    )}
                    {!splitView && (
                      <TextField
                        id='consult_assessment_summary_tf'
                        value={summary}
                        fullWidth
                        multiline={true}
                        variant='outlined'
                        InputProps={{
                          color: 'primary',

                          endAdornment: (
                            <InputAdornment position='end'>
                              {' '}
                              <Button
                                variant='contained'
                                color='primary'
                                disabled={
                                  !summary ||
                                  summary.trim().length === 0 ||
                                  (fetchedImp &&
                                    hasSameSummary(
                                      summary.trim(),
                                      fetchedImp.clinicalImpression
                                    )) ||
                                  addingStatus.requesting
                                }
                                onClick={() => {
                                  addNotesDetails()
                                }}
                              >
                                {t('labelCommon:done')}
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                        onChange={(event) => {
                          setSummary(event.target.value)
                        }}
                      />
                    )}
                    {splitView && (
                      <Box>
                        <Typography
                          variant='body2'
                          color='initial'
                          style={{
                            paddingLeft: 2,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (summary) {
                              if (summary.length > 60) {
                                setFullText(summary)
                                setShowText(true)
                              }
                            }
                          }}
                        >
                          {summary ? summary.slice(0, 60) : ''}

                          {summary ? (summary.length > 60 ? '...' : null) : ''}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>

                <NoteDialog
                  id='treatment_notes_tile'
                  open={showText}
                  onClose={() => {
                    setFullText('')
                    setShowText(false)
                  }}
                  notesData={fullText!}
                  dialogText='Notes'
                />
              </Box>
            </ReactVisibilitySensor>
          </Box>
        </Box>
      </Box>
    )
  }
