import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { getAgeOfPatientData } from 'utils/fhirResourcesHelper'

interface Props {
  patient?: R4.IPatient
  hideAge?: boolean
  status?: boolean
}

export const GenderAndAgeTile: React.FC<Props> = ({
  patient,
  hideAge = true,
  status,
}: Props) => (
  <Box>
    <Paper>
      {status && patient && patient.gender && (
        <Box my={1} mx={1} py={0.5}>
          {patient.gender && (
            <Box>
              {patient.gender === R4.PatientGenderKind._male ? (
                <Typography
                  component='span'
                  variant='subtitle2'
                  style={{
                    color: '#00000042',
                  }}
                >
                  {' '}
                  M /
                </Typography>
              ) : (
                <Typography
                  component='span'
                  variant='subtitle2'
                  style={{
                    color: '#00000042',
                  }}
                >
                  {' '}
                  F /
                </Typography>
              )}
            </Box>
          )}
          {patient && (
            <Typography
              component='span'
              variant='subtitle2'
              style={{
                color: '#00000042',
              }}
            >
              {` ${getAgeOfPatientData(patient)}`}
            </Typography>
          )}
        </Box>
      )}
      {status === false && patient && patient.gender && (
        <Box
          my={1}
          mx={1}
          py={0.5}
          width='100%'
          display='flex'
          flexDirection='row'
        >
          {patient.gender && (
            <Box>
              {patient.gender === R4.PatientGenderKind._male ? (
                <Typography
                  component='span'
                  variant='subtitle2'
                  color='initial'
                >
                  {' '}
                  M /
                </Typography>
              ) : (
                <Typography
                  component='span'
                  variant='subtitle2'
                  color='initial'
                >
                  {' '}
                  F /
                </Typography>
              )}
            </Box>
          )}
          {patient && (
            <Box paddingY={0.1} paddingX={0.2}>
              <Typography component='span' variant='subtitle2' color='initial'>
                {' '}
                {` ${getAgeOfPatientData(patient)}`}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Paper>
  </Box>
)
