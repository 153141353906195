import { Box, Card, CardContent, Typography } from '@material-ui/core'
import { kPrimaryMain } from 'configs/styles/muiThemes'
import { FhirSlotOnTime } from 'models/fhirSlotListOnTime'
import React from 'react'
import { getStartAndEndTimeInString } from 'utils/dateUtil'

interface Props {
  slotDetails: FhirSlotOnTime
  onTap?: () => void
  onChangeClicked?: () => void
  isSelected?: boolean
}

export const CombinedAgentSlots: React.FC<Props> = ({
  slotDetails,
  isSelected = false,
  onChangeClicked,
  onTap,
}: Props) => (
  <Box width='100%'>
    <Card
      elevation={0}
      style={{
        padding: 0,
        margin: 0,
        backgroundColor: isSelected ? kPrimaryMain : undefined,
      }}
    >
      <CardContent
        style={{
          padding: 8,
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          justifyContent='space-between'
        >
          <Typography
            variant='subtitle1'
            style={{
              color: isSelected ? 'white' : kPrimaryMain,
            }}
          >
            {' '}
            {getStartAndEndTimeInString(
              slotDetails.slots[0].slot.start ?? '',
              slotDetails.slots[0].slot.end ?? ''
            ) ?? ''}
          </Typography>

          <Typography
            variant='body1'
            style={{
              color: isSelected ? 'white' : kPrimaryMain,
            }}
          >
            {slotDetails.slots.length === 1
              ? `${slotDetails.slots.length.toString()} Agent available`
              : `${slotDetails.slots.length.toString()} Agents available`}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  </Box>
)
