import axios, { AxiosInstance } from 'axios'
import { getAccessToken } from 'utils/authHelpers'

export class ApiClient {
  axiosInstance: AxiosInstance

  constructor(baseUrl: string) {
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      timeout: 90000,
    })

    this.axiosInstance.interceptors.request.use((config) => {
      if (getAccessToken() != null) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${getAccessToken()}`,

          'Cache-Control': 'no-cache',
        }
      }

      return config
    })

    this.axiosInstance.interceptors.response.use((param) => ({
      ...param,
    }))
  }
}
