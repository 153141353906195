import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { LabReferralDetails } from 'models/labReferralDetails'
import {
  showSuccessAlert,
  showWarningAlert,
} from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserUnitDetails,
} from 'services/userDetailsService'
import { getIdentifierValueBySystemTypeDAataVal } from 'utils/fhirResourcesHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'

import { logger } from 'utils/logger'
import { searchingReferrals } from '../referralSearch/labReferralSearchSlice'
import { LabReferralAcceptInviteUpdateStatus } from './labReferralAcceptInviteUpdateStatus'

const initialState: LabReferralAcceptInviteUpdateStatus = {
  updating: false,
  updatedSuccessfully: false,
  error: false,
  errorMessage: '',
}

const labReferralAcceptInviteSlice = createSlice({
  name: 'labReferralAcceptInviteSlice',
  initialState,
  reducers: {
    updateStatus(
      state,
      action: PayloadAction<LabReferralAcceptInviteUpdateStatus>
    ) {
      state.updating = action.payload.updating
      state.updatedSuccessfully = action.payload.updatedSuccessfully
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },
  },
})

export const acceptInvite =
  (referralDetails: LabReferralDetails): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: LabReferralAcceptInviteUpdateStatus = {
      updating: true,
      updatedSuccessfully: false,
      error: false,
    }
    dispatch(
      labReferralAcceptInviteSlice.actions.updateStatus(addingCreatePersonState)
    )

    try {
      const transObj: R4.IBundle = getUpdatedBundleObjet(referralDetails)
      logger.info(referralDetails)
      const fhirClient: MasterFhirClient = new MasterFhirClient()
      const response: any = await fhirClient.doCreateFHIRTransaction(
        '',
        transObj
      )
      const respDecoded: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (respDecoded._tag === 'Right') {
        dispatch(showSuccessAlert('Referral invite accepted successfully'))
        const successCreatePersonState: LabReferralAcceptInviteUpdateStatus = {
          updating: false,
          updatedSuccessfully: true,
          error: false,
          errorMessage: '',
        }
        dispatch(searchingReferrals())
        dispatch(
          labReferralAcceptInviteSlice.actions.updateStatus(
            successCreatePersonState
          )
        )
        return
      }
      logger.info('Error while accepting invite')

      logger.info(respDecoded.left)

      const errorCreatePersonState: LabReferralAcceptInviteUpdateStatus = {
        updating: false,
        updatedSuccessfully: false,
        error: true,
        errorMessage: 'Error while accepting invite',
      }
      dispatch(
        labReferralAcceptInviteSlice.actions.updateStatus(
          errorCreatePersonState
        )
      )

      dispatch(
        showWarningAlert('Error while adding new address. Please try later')
      )
      return
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorCreatePersonState: LabReferralAcceptInviteUpdateStatus = {
        updating: false,
        updatedSuccessfully: false,
        error: true,
        errorMessage: 'Error while uploading patient data',
      }
      dispatch(
        labReferralAcceptInviteSlice.actions.updateStatus(
          errorCreatePersonState
        )
      )
    }
  }

function getUpdatedBundleObjet(
  referralDetails: LabReferralDetails
): R4.IBundle {
  const oldTask: R4.ITask = {
    ...referralDetails.task,
  }

  oldTask.status = R4.TaskStatusKind._completed
  let vData: string = ''
  const versionData = sessionStorage.getItem('version')
  if (versionData !== null) vData = JSON.stringify(versionData)
  const patMatchString: string =
    versionData === null
      ? `W/${JSON.stringify(oldTask?.meta?.versionId ?? ' ')}`
      : `W/${vData}`

  const bundleObject: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `Task/${oldTask.id}`,
        request: {
          ifMatch: patMatchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `Task/${oldTask.id}`,
        },
        resource: oldTask,
      },
    ],
  }

  const requestId: string = getUniqueTempId()

  const unitDetails: R4.IOrganization = getCurrentUserUnitDetails()
  const mainOrganization: R4.IOrganization =
    getCurrentUserMainOrganizationDetails()

  const healthCareService: R4.IHealthcareService = {
    resourceType: 'HealthcareService',
    id: `${requestId}`,
    contained: [
      {
        resourceType: 'Organization',
        id: `${unitDetails.id}`,
        identifier: [
          {
            system: 'http://wellopathy.com/fhir/india/core/Id/tenant-id',
            value: getIdentifierValueBySystemTypeDAataVal(
              mainOrganization.identifier ?? [],
              'http://wellopathy.com/fhir/india/core/Id/tenant-id'
            ),
          },
        ],
        type: unitDetails.type,
        active: true,
        name: unitDetails.name ?? '',
        address: unitDetails.address,
        partOf: unitDetails.partOf,
        contact: unitDetails.contact,
      },
    ],
    active: true,
    providedBy: {
      reference: `Organization/${unitDetails.id}`,
      type: 'Organization',
      display: unitDetails.name ?? '',
    },
    category: [
      {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '108252007',
            display: 'Laboratory procedure',
          },
        ],
      },
    ],
    type: [
      {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '266753000',
            display: 'Laboratory Tests',
          },
        ],
      },
    ],
    name: 'Laboratory procedure',
    appointmentRequired: true,
  }
  bundleObject.entry?.push({
    fullUrl: 'HealthcareService',
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: healthCareService.resourceType,
    },
    resource: healthCareService,
  })
  const orgAffiliation: R4.IOrganizationAffiliation = {
    resourceType: 'OrganizationAffiliation',
    active: true,
    organization: oldTask.for,
    participatingOrganization: oldTask.owner,
    code: [
      {
        coding: [
          {
            system: 'http://hl7.org/fhir/organization-role',
            code: 'diagnostics',
            display: 'Diagnostics',
          },
        ],
      },
    ],
    healthcareService: [
      {
        reference: `${healthCareService.resourceType}/${requestId}`,
      },
    ],
  }

  bundleObject.entry?.push({
    fullUrl: 'OrganizationAffiliation',
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: orgAffiliation.resourceType,
    },
    resource: orgAffiliation,
  })

  return bundleObject
}

export const resetUpdateInviteState = () => (dispatch: AppDispatch) => {
  dispatch(labReferralAcceptInviteSlice.actions.updateStatus(initialState))
}

export default labReferralAcceptInviteSlice.reducer
