import { Category, CategoryOption } from 'models/consultationNavBarOption'
import options from '../../assets/constants/appointment_navbar_options.json'

const currentOptions: Category[] = options as Category[]

export function getNavOptionsId(): string[] {
  let idList: string[] = []
  currentOptions.forEach((opt) => {
    const res: CategoryOption[] = opt.category_options.filter(
      (val) => val.is_enabled
    )
    idList = idList.concat(res.map((item) => item.option_link))
  })
  return idList
}
