/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import ContentShimmer from 'react-content-shimmer'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import 'react-vis/dist/style.css'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { getAccessToken } from 'utils/authHelpers'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  addObservationDetailsForSys,
  deleteObservationDetails,
  getObservationResourceForPostForSystemicExam,
  getObservationsOfSpecificCategoryInEncounter,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { getVendorPartId } from 'utils/routes_helper'
import { OCARenderer } from 'wello-web-components'

interface CardioVascularExaminationsListProps {
  rootCauseList: R4.IObservation[]
}

export const RootCauseAssessment: React.FC<CardioVascularExaminationsListProps> =
  ({ rootCauseList }: CardioVascularExaminationsListProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation(['common'])
    const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())
    const [observations, setObservations] = useState<R4.IObservation[]>([])

    const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
      initial: true,
      requesting: true,
    })

    return (
      <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
        <OCARenderer
          OCAFormProviderBaseURL={`${
            process.env.REACT_APP_CC_WORKFLOW_URL
          }${getVendorPartId()}/ocaForms/forms`}
          OCAFormProviderHeader={{
            Authorization: `Bearer ${getAccessToken()}`,

            'Cache-Control': 'no-cache',
          }}
          onSubmit={(
            observation: R4.IObservation,
            onSubmitSuccess?: (data: any) => void
          ) => {}}
          onDelete={(
            observation: R4.IObservation,
            onSuccess?: ((data: any) => void) | undefined,
            onError?: ((data: any) => void) | undefined
          ) => {
            deleteObservationDetails(observation).then((e) => {
              if (e) {
                dispatch(
                  showSuccessAlert('Observation details deleted successfully')
                )
                //   getObservationDetails()
                if (onSuccess) onSuccess({})
              } else if (onError) onError({})
            })
          }}
          formName='Obesity Root Cause Assessment_OCA'
          formSection='soap'
          formCategory='assessment'
          displayAsPopup={true}
          isViewOnly={true}
          existingObservations={rootCauseList}
        />
      </Box>
    )
  }
