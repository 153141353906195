import { Box, Typography } from '@material-ui/core'
import { FhirAppointmentFullDetail } from 'models/fhirAppointmentFullDetail'
import React from 'react'
import { getAssementConditions } from 'utils/fhirResoureHelpers/appointmentHelpers'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { SOAPIndicativeElement } from 'wello-web-components'
import { MedicalEventContent } from './medical_event_content'
import { MedicationTileTimeLine } from './medication_tile_time_line'

interface IProps {
  medicalEvent: FhirAppointmentFullDetail
}

export const AppointmentMedicalEventDetail: React.FC<IProps> = ({
  medicalEvent,
}: IProps) => (
  <Box width='100%' height='100%' flexDirection='column'>
    {medicalEvent.observations && medicalEvent.observations.length > 0 && (
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='flex-start'
        py={2}
      >
        <Typography variant='subtitle1' color='primary'>
          Objective
        </Typography>
      </Box>
    )}

    {medicalEvent.observations && medicalEvent.observations.length > 0 && (
      <MedicalEventContent
        title='Vitals'
        subTitle={
          <Box display='flex' flexDirection='column'>
            {medicalEvent.observations?.map((e) => (
              <Box key={`${e.id}_key`}>
                <SOAPIndicativeElement
                  description={getObservationContent(e)}
                  title={getObservationCodeText(e)}
                  showIndicativeBars={false}
                  type='vitals'
                  showAlarmingPrefixIcon={false}
                />
              </Box>
            ))}
          </Box>
        }
      />
    )}

    {medicalEvent.clinicalImpression?.summary && (
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='flex-start'
        py={2}
      >
        <Typography variant='subtitle1' color='primary'>
          Assessment
        </Typography>
      </Box>
    )}

    {medicalEvent.clinicalImpression?.summary && (
      <MedicalEventContent
        title='Summary'
        subTitle={
          <Box px={1}>
            <Typography variant='body1' color='initial'>
              {medicalEvent.clinicalImpression?.summary}
            </Typography>
          </Box>
        }
      />
    )}

    {getAssementConditions(medicalEvent) &&
      getAssementConditions(medicalEvent).length > 0 && (
        <MedicalEventContent
          title='Problem & Cases'
          subTitle={
            <Box px={1}>
              {' '}
              <Typography variant='body1' color='initial'>
                {getAssementConditions(medicalEvent).join(', ')}
              </Typography>
            </Box>
          }
        />
      )}

    {medicalEvent.medications && medicalEvent.medications.length > 0 && (
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='flex-start'
        py={2}
      >
        <Typography variant='subtitle1' color='primary'>
          Plan
        </Typography>
      </Box>
    )}

    {medicalEvent.medications && medicalEvent.medications.length > 0 && (
      <MedicalEventContent
        title='Medications'
        subTitle={
          <Box display='flex' flexDirection='column' px={0.5}>
            {medicalEvent.medications.map((e) => (
              <MedicationTileTimeLine
                medication={e}
                key={e.medicationCode?.code ?? ''}
              />
            ))}
          </Box>
        }
      />
    )}
  </Box>
)
