import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirCatalougeDetails } from 'models/fhirCatalougeDetails'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import { getExpandedCatalogueFromBundle } from 'utils/common/catalogueDataConverter'
import { logger } from 'utils/logger'
import { AvailableMembershipTypesStatus } from './availableMembershipTypesStatus'

const initialState: AvailableMembershipTypesStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
  catalogueListView: [],
}
const availableMembershipTypesSlice = createSlice({
  name: 'availableMembershipTypesSlice',
  initialState,
  reducers: {
    searchingCatalogueDetails(
      state,
      action: PayloadAction<AvailableMembershipTypesStatus>
    ) {},

    searchResults(
      state,
      action: PayloadAction<AvailableMembershipTypesStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.catalogueListView = action.payload.catalogueListView
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<AvailableMembershipTypesStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.catalogueListView = action.payload.catalogueListView
    },

    errorWhileSearching(
      state,
      action: PayloadAction<AvailableMembershipTypesStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.catalogueListView = action.payload.catalogueListView
    },
    resetState(state, action: PayloadAction<AvailableMembershipTypesStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.catalogueListView = undefined
    },
  },
})

export const resetCatalogueSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: AvailableMembershipTypesStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      catalogueListView: undefined,
    }
    dispatch(availableMembershipTypesSlice.actions.resetState(state))
  }

export const searchAvailablePlanDetails =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const errorSearchPatient: AvailableMembershipTypesStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      availableMembershipTypesSlice.actions.errorWhileSearching(
        errorSearchPatient
      )
    )
    try {
      const unitDetails = getCurrentUserUnitReference()!
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _count: 100,
        _total: 'accurate',
        _include: '*',
        type: 'membership-plan',
        status: 'active',
      }
      //   searchParameters._sort = 'name'

      const response: any = await fhirClient.doGetResource(
        `/PlanDefinition`,
        searchParameters
      )

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const catalogueResponses: R4.IBundle = relatedFhirDecodeRes.right
        if (catalogueResponses.total) {
          if (catalogueResponses.total > 0) {
            if (catalogueResponses.entry) {
              const fhirCatalogueData: FhirCatalougeDetails[] =
                getExpandedCatalogueFromBundle(catalogueResponses)
              const searchCatalogueResult: AvailableMembershipTypesStatus = {
                error: false,
                noResultsAvailable: false,
                resultsAvailable: true,
                searching: false,
                catalogueListView: fhirCatalogueData,

                totalCount: catalogueResponses.total,
              }
              dispatch(
                availableMembershipTypesSlice.actions.searchResults(
                  searchCatalogueResult
                )
              )
              return
            }
          }
        }
        const noSearchResults: AvailableMembershipTypesStatus = {
          error: false,
          noResultsAvailable: true,
          resultsAvailable: false,
          searching: false,
        }
        dispatch(
          availableMembershipTypesSlice.actions.noDataFoundForSearch(
            noSearchResults
          )
        )
        return
      }
      const errorWhileSearchCatalogue: AvailableMembershipTypesStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error while searching',
      }
      dispatch(
        availableMembershipTypesSlice.actions.errorWhileSearching(
          errorWhileSearchCatalogue
        )
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchCatalogue: AvailableMembershipTypesStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        availableMembershipTypesSlice.actions.errorWhileSearching(
          errorWhileSearchCatalogue
        )
      )
    }
  }

export default availableMembershipTypesSlice.reducer
