import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { getExpandedAppointmentFromBundle } from 'utils/common/patientDataTableHelper'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import { UmlClient } from 'services/umlsClient'
import { getUserCurrentRole } from 'services/userDetailsService'
import { MedicationSearchStatus } from './medicationSearchStatusType'

const initialState: MedicationSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const medicationSearchSlice = createSlice({
  name: 'medication',
  initialState,
  reducers: {
    searchingMedication(
      state,
      action: PayloadAction<MedicationSearchStatus>
    ) {},

    searchResults(state, action: PayloadAction<MedicationSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
      state.medicationList = action.payload.medicationList
    },

    noDataFoundForSearch(state, action: PayloadAction<MedicationSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
      state.medicationList = action.payload.medicationList
    },

    errorWhileSearching(state, action: PayloadAction<MedicationSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
      state.medicationList = action.payload.medicationList
    },
    resetState(state, action: PayloadAction<MedicationSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.surgeryList = undefined
      state.medicationList = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: any) => {
    const state: MedicationSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      surgeryList: undefined,
    }
    dispatch(medicationSearchSlice.actions.resetState(state))
  }

export const searchMedications =
  (searchString: string, selectOther: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: MedicationSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      medicationSearchSlice.actions.errorWhileSearching(errorSearchPatient)
    )
    try {
      const fhirClient: UmlClient = new UmlClient()
      const response: any = await fhirClient.doGetResource(
        `umls/drugMatcher?Path=medication&Match=${searchString}`
      )
      if (response.length > 0) {
        const data: any = []
        // response.forEach((e: any) => {
        //   if (e.code === undefined || e.code.coding === undefined) {
        //     logger.info('qualification code')
        //     result = false
        //   }
        //   if (e.identifier === undefined || e.identifier.length === 0) {
        //     logger.info('qualification identifier')
        //     result = false
        //   }
        //   if (e.issuer === undefined || e.issuer.reference === undefined) {
        //     logger.info('qualification issuer')
        //     result = false
        //   }
        //   if (e.period === undefined || e.period.end === undefined) {
        //     logger.info('qualification period')
        //     result = false
        //   }
        //   if (e.extension === undefined || e.extension.length === 0) {
        //     logger.info('qualification  modifierExtension')
        //     result = false
        //   }
        // })
        const searchPatientResult: MedicationSearchStatus = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          surgeryList: response,
          medicationList: response,
          totalCount: response.total,
        }
        dispatch(
          medicationSearchSlice.actions.searchResults(searchPatientResult)
        )
        return
      }
      const currentRole = getUserCurrentRole()

      const noSearchResults: MedicationSearchStatus = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(
        medicationSearchSlice.actions.noDataFoundForSearch(noSearchResults)
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: MedicationSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        medicationSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export const resetState = () => (dispatch: any) => {
  dispatch(medicationSearchSlice.actions.resetState(initialState))
}

export default medicationSearchSlice.reducer
