/* eslint-disable react/jsx-key */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core'
import { DeleteOutlineOutlined } from '@material-ui/icons'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { ConsumableData } from 'models/consumableData'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { SubModel } from 'models/subModel'
import { SubstanceData } from 'models/substanceData'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import {
  deleteSubstance,
  resetDeleteSubstanceState,
} from 'redux/ipd/procedure/deleteSubstance/deleteSubstanceSlice'
import {
  addSubstanceDetails,
  resetAddSubstancesState,
} from 'redux/ipd/procedure/substance/substanceAddSlice'
import { requestForSubstance } from 'redux/ipd/viewSubstance/substanceSearchSliceFinal'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { isUnitAdmin } from 'services/userDetailsService'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { isPastOrFutureDate } from 'utils/fhirResoureHelpers/ipdHelper'
import { isPastDate } from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'
import { WelloSelectSubstance } from 'views/components/LeftMenu/WelloSelectSubstance'
import { WelloTextField } from 'wello-web-components'

// import { TreatmentDataTable } from './treatmentDataTable'

interface AssessmentCaptureProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  status: string
}

export const SubstanceCard: React.FC<AssessmentCaptureProps> = ({
  fhirAppointmentDetails,
  status,
}: AssessmentCaptureProps) => {
  const checkinDate = moment(
    fhirAppointmentDetails.mainServiceRequest.occurrencePeriod?.start
  ).format('YYYY-MM-DD')
  const checkOutDate = moment(
    fhirAppointmentDetails.mainServiceRequest.occurrencePeriod?.end
  ).format('YYYY-MM-DD')

  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()

  const addSubstanceSlice = useSelector(
    (state: RootState) => state.addSubstanceSlice
  )

  const substanceSearchSliceFinal = useSelector(
    (state: RootState) => state.substanceSearchSliceFinal
  )

  const deleteSubstanceSlice = useSelector(
    (state: RootState) => state.deleteSubstanceSlice
  )
  let subModelData: SubModel[] = []

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  const res = sessionStorage.getItem('sub')
  if (res !== null) subModelData = JSON.parse(res)

  const [sub, setSub] = useState<SubstanceData[]>([
    {
      id: '',
      date: moment(moment()).format('YYYY-MM-DD'),
      unit: 1,
    },
  ])

  function handleDateChange(date: any, index: number) {
    const values: SubstanceData[] = [...sub]
    values[index].date = date.target.value
    setSub(values)
  }

  function handleChangeUnit(event: any, index: number) {
    const values: SubstanceData[] = [...sub]
    values[index].unit = parseInt(event.target.value, 10)
    setSub(values)
  }

  function handleAfterNoonTherapy(th: SubModel, index: number) {
    const values: SubstanceData[] = [...sub]

    values[index].substances = th.name
    values[index].substanceId = th.id
    setSub(values)
  }

  function resetValues() {
    setSub([
      {
        id: getUniqueTempId(),
        date: moment(moment()).format('YYYY-MM-DD'),
        unit: 1,
      },
    ])
  }

  useEffect(() => {
    if (addSubstanceSlice.additionSuccessful) {
      resetValues()
      dispatch(
        requestForSubstance(fhirAppointmentDetails.mainServiceRequest.id!)
      )
      dispatch(resetAddSubstancesState())
    }
    if (deleteSubstanceSlice.updateSuccessful) {
      dispatch(resetDeleteSubstanceState())
    }
  }, [dispatch, addSubstanceSlice, resetValues, deleteSubstanceSlice])

  useEffect(() => {
    dispatch(requestForSubstance(fhirAppointmentDetails.mainServiceRequest.id!))
  }, [dispatch])

  return (
    <Box style={{ paddingLeft: '4px' }} display='flex' flexDirection='row'>
      <Box width='100%'>
        <Grid container direction='column'>
          <Grid item>
            <ReactVisibilitySensor
              onChange={(isVisible) => {
                if (isVisible) {
                  dispatch(setSelectedSection({ selectedSection: 'diagnosis' }))
                }
              }}
            >
              <Grid container direction='column'>
                <Grid item id='diagnosis'>
                  <Grid
                    container
                    direction='row'
                    style={{ padding: 0, margin: 0 }}
                  >
                    <Box
                      sx={{ width: '100%' }}
                      display='flex'
                      alignItems='center'
                      flexDirection='row'
                      bgcolor='#999999'
                      minHeight={45}
                    >
                      <Box p={2}>
                        <Typography
                          variant='body1'
                          style={{ color: 'white', fontWeight: 600 }}
                          id='consumable'
                        >
                          {' '}
                          Consumable
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xl>
                  {deleteSubstanceSlice.adding && (
                    <Box flexGrow={1} minWidth='400px'>
                      <LinearProgress />
                    </Box>
                  )}
                  <Box height='100%' py={1}>
                    <Grid
                      container
                      direction='row'
                      alignContent='space-between'
                      justify='space-between'
                    >
                      <Grid container direction='row' justify='flex-start'>
                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            width='100%'
                            flexDirection='row'
                            justifyContent='flex-start'
                          >
                            <Typography
                              variant='body1'
                              color='primary'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              Date
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <Box
                            display='flex'
                            width='100%'
                            flexDirection='row'
                            justifyContent='flex-start'
                          >
                            <Typography
                              variant='body1'
                              color='primary'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              Name
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            width='100%'
                            flexDirection='row'
                            justifyContent='flex-start'
                          >
                            <Typography
                              variant='body1'
                              color='primary'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              Unit
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            width='100%'
                            flexDirection='row'
                            justifyContent='flex-start'
                          >
                            <Typography
                              variant='body1'
                              color='primary'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              Price
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1}>
                          <Box
                            display='flex'
                            width='100%'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Typography
                              variant='body1'
                              color='primary'
                              style={{ fontWeight: 600 }}
                            >
                              {' '}
                              Action
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                {substanceSearchSliceFinal.searchingTreatment && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      align='center'
                    >
                      <CircularProgress size={25} />
                    </Typography>
                  </Box>
                )}

                {substanceSearchSliceFinal.errorWhileSearchingTreatment && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='error'
                      align='center'
                    >
                      Some Error has occured
                    </Typography>
                  </Box>
                )}

                {substanceSearchSliceFinal.noResultsAvailable && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='initial'
                      align='center'
                      id='add_consume'
                    >
                      Add Consumable
                    </Typography>
                  </Box>
                )}

                {substanceSearchSliceFinal.resultsAvailable &&
                  substanceSearchSliceFinal.treatmentList &&
                  substanceSearchSliceFinal.treatmentList.length === 0 && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='100%'
                      height='100%'
                      alignContent='center'
                    >
                      <Typography
                        variant='subtitle1'
                        color='initial'
                        align='center'
                      >
                        {isUnitAdmin() ||
                        fhirAppointmentDetails.mainServiceRequest.status ===
                          'completed'
                          ? 'No data available'
                          : 'Add Consumable'}
                      </Typography>
                    </Box>
                  )}

                {substanceSearchSliceFinal.resultsAvailable &&
                  substanceSearchSliceFinal.treatmentList &&
                  substanceSearchSliceFinal.treatmentList.length > 0 && (
                    <Grid item xl>
                      <Box height='100%'>
                        <Grid
                          container
                          direction='row'
                          alignContent='space-between'
                          justify='space-between'
                        >
                          {substanceSearchSliceFinal.treatmentList.map(
                            (treatmentData: ConsumableData, index: number) => (
                              <Box
                                width='100%'
                                bgcolor={
                                  index % 2 === 0 ? kPrimaryLight : undefined
                                }
                              >
                                <Grid
                                  container
                                  direction='row'
                                  justify='flex-start'
                                >
                                  <Grid item xs={1}>
                                    <Box
                                      display='flex'
                                      width='100%'
                                      flexDirection='row'
                                      justifyContent='flex-start'
                                      py={1}
                                      px={0.5}
                                    >
                                      {' '}
                                      <Typography
                                        variant='body2'
                                        color='primary'
                                      >
                                        {' '}
                                        {treatmentData.date}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Box
                                      display='flex'
                                      width='100%'
                                      flexDirection='row'
                                      justifyContent='flex-start'
                                      py={1}
                                    >
                                      <Typography
                                        variant='body2'
                                        color='initial'
                                      >
                                        {' '}
                                        {treatmentData.substance
                                          ? treatmentData.substance
                                              .description ?? ''
                                          : ''}
                                      </Typography>
                                    </Box>
                                  </Grid>

                                  <Grid item xs={1}>
                                    <Box
                                      display='flex'
                                      width='100%'
                                      px={1}
                                      flexDirection='row'
                                      justifyContent='flex-start'
                                      paddingLeft={0.1}
                                      py={1}
                                    >
                                      <Typography
                                        variant='body2'
                                        color='initial'
                                      >
                                        {treatmentData.unit}
                                      </Typography>
                                    </Box>
                                  </Grid>

                                  <Grid item xs={1}>
                                    <Box
                                      display='flex'
                                      width='100%'
                                      flexDirection='row'
                                      justifyContent='flex-start'
                                      paddingLeft={0.1}
                                      py={1}
                                    >
                                      <Typography
                                        variant='body2'
                                        color='initial'
                                      >
                                        Rs. {treatmentData.payment}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box
                                      display='flex'
                                      width='100%'
                                      flexDirection='row'
                                      justifyContent='center'
                                      py={1}
                                    >
                                      {!isUnitAdmin() &&
                                        fhirAppointmentDetails
                                          .mainServiceRequest.status !==
                                          'completed' &&
                                        isPastOrFutureDate(
                                          treatmentData.date
                                        ) && (
                                          <DeleteOutlineOutlined
                                            fontSize='small'
                                            style={{
                                              cursor: 'pointer',
                                            }}
                                            id='del_ico'
                                            onClick={() => {
                                              setShowDeleteConfirmation({
                                                open: true,
                                                title: 'Delete',
                                                onContinueActionClicked: () => {
                                                  setShowDeleteConfirmation({
                                                    open: false,
                                                  })
                                                  if (treatmentData.substance) {
                                                    dispatch(
                                                      deleteSubstance(
                                                        treatmentData.task,
                                                        fhirAppointmentDetails
                                                      )
                                                    )
                                                  }
                                                },
                                                onCancelActionClicked: () => {
                                                  setShowDeleteConfirmation({
                                                    open: false,
                                                  })
                                                },
                                                displayText:
                                                  'Are you sure you want to delete this record?',
                                                continueActionButtonText: 'Yes',
                                                cancelActionButtonText: 'No',
                                              })
                                            }}
                                          />
                                        )}
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            )
                          )}
                        </Grid>
                      </Box>
                      {showDeleteConfirmation.open && (
                        <ConfirmationDialog
                          {...showDeleteConfirmation}
                          id='del_subs'
                        />
                      )}
                    </Grid>
                  )}
                {!isUnitAdmin() && status !== 'completed' && (
                  <Grid item xs={12}>
                    <Box display='flex' flexDirection='column' width='100%'>
                      {sub.map((treatmentRow: SubstanceData, index: number) => (
                        <Box
                          display='flex'
                          flexDirection='column'
                          alignItems='flex-start'
                          key={`unit_actor_small_details_${treatmentRow.id}`}
                          width='100%'
                        >
                          <Box
                            display='flex'
                            flexDirection='row'
                            alignItems='flex-start'
                            key={`unit_actor_small_details_${treatmentRow.id}`}
                            width='100%'
                          >
                            <Box px={0.5} width='20%'>
                              <WelloTextField
                                title='Date'
                                textProps={{
                                  id: `${index}date`,
                                  value: treatmentRow.date,
                                  inputProps: {
                                    min: isPastDate(checkinDate)
                                      ? moment().format('YYYY-MM-DD')
                                      : checkinDate,
                                    max: checkOutDate,
                                  },
                                  onKeyDown: (e) => {
                                    e.preventDefault()
                                  },

                                  type: 'date',
                                  onChange: (changeData) => {
                                    handleDateChange(changeData, index)
                                  },
                                }}
                              />
                            </Box>
                            <Box px={4} width='30%'>
                              <WelloSelectSubstance
                                title='Consumable'
                                availableCodings={subModelData}
                                onChanged={(e) => {
                                  if (e) {
                                    handleAfterNoonTherapy(e, index)
                                  }
                                }}
                                textFieldProps={{
                                  size: 'small',
                                  id: `select_${index}`,
                                }}
                                // preSelectedCoding={subModelData[0]}
                              />
                            </Box>

                            <Box px={4} width='20%' paddingTop={2}>
                              <Grid
                                item
                                xs={5}
                                style={{
                                  alignSelf: 'center',
                                }}
                              >
                                <Typography
                                  variant='subtitle2'
                                  style={{
                                    textTransform: 'uppercase',
                                  }}
                                >
                                  Unit
                                </Typography>
                              </Grid>
                              <TextField
                                variant='outlined'
                                fullWidth
                                type='number'
                                size='small'
                                value={treatmentRow.unit}
                                onChange={(event) => {
                                  handleChangeUnit(event, index)
                                }}
                                id={`qty_${index}`}
                                error={
                                  treatmentRow.unit < 1 ||
                                  treatmentRow.unit > 20 ||
                                  Number.isNaN(treatmentRow.unit)
                                }
                                helperText={
                                  treatmentRow.unit < 1 ||
                                  treatmentRow.unit > 20 ||
                                  Number.isNaN(treatmentRow.unit)
                                    ? `Unit should be in between 1 to 20`
                                    : ''
                                }
                                InputProps={{
                                  color: 'primary',

                                  inputProps: {
                                    max: 20,
                                    min: 1,
                                  },
                                }}
                              />
                            </Box>
                          </Box>

                          <Box
                            display='flex'
                            alignItems='center'
                            flexDirection='row'
                            paddingTop={1}
                          >
                            {addSubstanceSlice.adding && (
                              <CircularProgress size={25} />
                            )}

                            <Box>
                              <Button
                                variant='contained'
                                size='small'
                                color='primary'
                                id='add_subs'
                                disabled={
                                  !sub[0].substances ||
                                  sub[0].unit < 1 ||
                                  sub[0].unit > 20 ||
                                  Number.isNaN(sub[0].unit)
                                }
                                onClick={() => {
                                  dispatch(
                                    addSubstanceDetails(
                                      sub,
                                      fhirAppointmentDetails
                                    )
                                  )
                                }}
                              >
                                <Typography variant='subtitle2'>
                                  {' '}
                                  Save
                                </Typography>
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </ReactVisibilitySensor>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
