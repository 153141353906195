import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Link,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { CloseOutlined } from '@material-ui/icons'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { downloadingDocument } from 'redux/lab/download/downloadInvoice'
import {
  fetchOrderDetails,
  requestCancellationOfOrder,
  requestRescheduleOfOrder,
  requestUpdateStatusOfOrder,
} from 'redux/lab/order/orderManagementSlice/orderManagementSlice'
import { fetchMedicineOrderDetails } from 'redux/MedicineOrderHistory/medicineOrderDetails/medicineOrderDetailsSlice'
import { RootState } from 'redux/rootReducer'
import {
  onSiteOrderStatusTypes,
  orderStatusTypes,
} from 'utils/constants/order_status'
import {
  getOrderFinalStatusText,
  isHomeOrder,
  isLabOrderEditable,
  isLabOrderStatusChangeable,
  isOnsiteOrder,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import { OrderAmountSummaryTile } from 'views/components/generalOrder/ordarAmountSummary'
import { WelloSelectFHIRTest } from 'views/components/lab/common/WelloSelectFHIRTest'
import { PatientTileSplit } from 'views/components/patients/patientTileSplit'
import { WelloFormItemLabel, WelloLoadingIndicator } from 'wello-web-components'
import { DeliveryAddressTile } from './deliveryAddressTile'
import { ItemOrderDetails } from './itemDetails'
import { LogisticDetails } from './logisticDetails'
import { OrderTrackTile } from './orderTrackTile'
import { PatientMedTile } from './patientMedTile'
import { PaymentTileOrder } from './paymentTileForOrder'

interface Props {
  orderDetail: any
  patientData?: R4.IPatient
  isOdd?: boolean
  onCloseClicked: () => void
  dense?: boolean
}

export const MedOrderDetails: React.FC<Props> = ({
  orderDetail,
  patientData,
  isOdd = false,
  onCloseClicked,
  dense = false,
}: Props) => {
  const orderManagementSlice = useSelector(
    (state: RootState) => state.medicineOrderDetailsSlice
  )
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [status, setStatus] = useState<any>()
  const [reason, setReason] = useState<string>()
  const [columns, setColumns] = useState<string[]>([])
  const [showCancelHandler, setShowCancelHandler] = useState<boolean>(false)
  const [showRescheduleHandler, setShowRescheduleHandler] =
    useState<boolean>(false)
  const [labOrderDetail, setLabOrderDetail] = useState<any>(orderDetail)

  const arr: string[] = ['Order Placed']

  useEffect(() => {
    dispatch(fetchMedicineOrderDetails(labOrderDetail.salesorder_id))
    return () => {}
  }, [])

  useEffect(() => {
    if (orderManagementSlice.orderDetailsAvailable) {
      if (orderManagementSlice.orderDetail) {
        console.log()
        setLabOrderDetail(orderManagementSlice.orderDetail)
      }
    }
    return () => {}
  }, [orderManagementSlice.orderDetailsAvailable])

  return (
    <Box width='100%' px={1}>
      <Card
        elevation={0}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 4,
          backgroundColor: isOdd ? '#FFFFFFAF' : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 1,
          }}
        >
          <Box display='flex' flexDirection='column' width='100%'>
            {orderManagementSlice.fetchingOrderDetails && (
              <Box display='flex' flexDirection='row' width='100%'>
                <CircularProgress />
              </Box>
            )}

            {orderManagementSlice.orderDetailsAvailable &&
              orderManagementSlice.orderDetail && (
                <Box display='display' flexDirection='column' width='100%'>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    paddingTop={1}
                  >
                    <Box width='50%' display='flex' flexDirection='row'>
                      <Box>
                        <Typography variant='subtitle1'>Order ID : </Typography>
                      </Box>
                      <Box paddingTop={0.3} paddingLeft={0.2}>
                        <Typography variant='subtitle2'>
                          {orderManagementSlice.orderDetail.salesorder_id}
                        </Typography>
                      </Box>
                    </Box>
                    <Box width='50%' display='flex' flexDirection='row'>
                      {/* <Box width='50%'>
                        <Typography
                          variant='subtitle1'
                          color='primary'
                          style={{ cursor: 'pointer' }}
                        >
                          Download Invoice{' '}
                        </Typography>
                      </Box> */}

                      {/* <Box
                        width='45%'
                        display='flex'
                        flexDirection='row'
                        justifyContent='center'
                      >
                        <Typography
                          variant='subtitle1'
                          color='primary'
                          style={{ cursor: 'pointer' }}
                        >
                          Reorder{' '}
                        </Typography>
                      </Box> */}
                    </Box>
                    <Box
                      width='5%'
                      display='flex'
                      flexDirection='row'
                      justifyContent='center'
                    >
                      <Tooltip
                        title={t('close') ?? 'Re-Schedule'}
                        style={{ paddingTop: 0 }}
                      >
                        <IconButton
                          aria-label='btn_ord_detail_menu'
                          onClick={onCloseClicked}
                          id='close_details'
                        >
                          <CloseOutlined color='primary' />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>

                  <Box width='100%' display='flex' flexDirection='row' py={1}>
                    <OrderTrackTile
                      salesOrder={orderManagementSlice.orderDetail}
                      orderStatus={arr}
                    />
                  </Box>
                  {patientData && (
                    <Box
                      width='100%'
                      display='flex'
                      flexDirection='row'
                      paddingBottom={1}
                    >
                      <PatientMedTile patient={patientData} />
                    </Box>
                  )}

                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='row'
                    paddingBottom={1}
                  >
                    <DeliveryAddressTile
                      salesOrder={orderManagementSlice.orderDetail}
                    />
                  </Box>

                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='row'
                    paddingBottom={1}
                  >
                    <LogisticDetails
                      salesOrderId={orderManagementSlice.orderDetail}
                    />
                  </Box>

                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='row'
                    paddingBottom={1}
                  >
                    <PaymentTileOrder
                      salesOrder={orderManagementSlice.orderDetail}
                    />
                  </Box>

                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='row'
                    paddingBottom={1}
                  >
                    <ItemOrderDetails
                      salesOrder={orderManagementSlice.orderDetail}
                    />
                  </Box>
                </Box>
              )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
