/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { PurposeOfUse } from 'models/purposeOfUse'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import 'react-vis/dist/style.css'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { getAccessToken } from 'utils/authHelpers'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  addObservationDetailsForSys,
  deleteObservationDetails,
  getObservationResourceForPostForSystemicExam,
  getObservationsOfSpecificCategoryInEncounter,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { getVendorPartId } from 'utils/routes_helper'
import { OCARenderer } from 'wello-web-components'

interface CardioVascularExaminationsListProps {
  purposeOfUseType: PurposeOfUse
  encounterId: string
  patient: R4.IPatient
  isReadOnly?: boolean
}

export const CNSExaminationsListOfEncounter: React.FC<CardioVascularExaminationsListProps> =
  ({
    purposeOfUseType,
    encounterId,
    patient,
    isReadOnly = false,
  }: CardioVascularExaminationsListProps) => {
    const { t } = useTranslation(['common'])
    const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())
    const [observations, setObservations] = useState<R4.IObservation[]>([])
    const dispatch = useDispatch()
    const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
      initial: true,
    })

    function getObservationDetails() {
      setFetchingStatus({
        requesting: true,
      })

      getObservationsOfSpecificCategoryInEncounter({
        encounterId,
        patient,
        category: 'C3714787',
      })
        .then((e) => {
          if (e) {
            setFetchingStatus({
              requestSuccess: true,
            })
            setObservations(e as R4.IObservation[])
          } else {
            setFetchingStatus({
              requestError: true,
            })
          }
        })
        .catch((e) => {
          setFetchingStatus({
            requestError: true,
          })
        })
    }
    useEffect(() => {
      getObservationDetails()
    }, [])

    return (
      <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
        {fetchingStatus.requesting && <CircularProgress size={20} />}
        {fetchingStatus.requestError && (
          <Typography variant='body1' color='error'>
            {t('common:errorWhileFetchingData')}
          </Typography>
        )}
        {fetchingStatus.requestSuccess && (
          <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
            <OCARenderer
              OCAFormProviderBaseURL={`${
                process.env.REACT_APP_CC_WORKFLOW_URL
              }${getVendorPartId()}/ocaForms/forms`}
              OCAFormProviderHeader={{
                Authorization: `Bearer ${getAccessToken()}`,

                'Cache-Control': 'no-cache',
              }}
              formSection='soap'
              formCategory='cns'
              onSubmit={(
                observation: R4.IObservation,
                onSubmitSuccess?: (data: any) => void
              ) => {
                const updatedObservation =
                  getObservationResourceForPostForSystemicExam(
                    patient.id!,
                    encounterId,
                    [
                      {
                        coding: [
                          {
                            code: 'C3714787',
                            display: 'Central Nervous System',
                            system:
                              'http://terminology.hl7.org/CodeSystem/umls/',
                          },
                        ],
                      },
                    ],
                    observation
                  )

                addObservationDetailsForSys(updatedObservation).then((e) => {
                  if (e === false) {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  } else if (
                    (e as R4.IObservation).resourceType === 'Observation'
                  ) {
                    if (onSubmitSuccess) {
                      onSubmitSuccess(e)
                    }

                    dispatch(
                      showSuccessAlert(
                        'Observation details updated successfully'
                      )
                    )
                  } else {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  }
                })
              }}
              onDelete={(
                observation: R4.IObservation,
                onSuccess?: ((data: any) => void) | undefined,
                onError?: ((data: any) => void) | undefined
              ) => {
                deleteObservationDetails(observation).then((e) => {
                  if (e) {
                    if (onSuccess) onSuccess({})
                  } else if (onError) onError({})
                })
              }}
              existingObservations={observations}
            />
          </Box>
        )}
      </Box>
    )
  }
