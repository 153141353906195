import {
  Box,
  Button,
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core'
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@material-ui/icons'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { WelloFormItemLabel, WelloLoadingIndicator } from 'wello-web-components'
import { searchMedicalProviders } from '../../../../redux/practitioner/medicalProvidersSearchHandler/medicalProviderSearchSlice'

interface Props {
  onProviderSelectionChanged?: (selectedValues: PractitionerWithRole[]) => void
  onPractitionerSelectionChanged?: (
    selectedValues: PractitionerWithRole[]
  ) => void
  doctorListId?: string
  preSelectedPractitionerRoles?: PractitionerWithRole[]
  preSelectAll?: boolean
  providerType?: string
  doctorId?: string
}
export const ProviderSelectorNew: React.FC<Props> = ({
  onProviderSelectionChanged,
  onPractitionerSelectionChanged,
  doctorListId,
  preSelectedPractitionerRoles,
  preSelectAll = false,
  providerType,
  doctorId,
}: Props) => {
  const practitionerSearchState = useSelector(
    (state: RootState) => state.medicalProvidersSearchSlice
  )
  const dispatch = useDispatch()
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const { t } = useTranslation()

  function handleChecked(id: string) {
    if (selectedIds.includes(id)) {
      const tempIds: string[] = selectedIds.filter((item) => item !== id)
      updatePractitioners(tempIds)
    } else {
      const tempIds: string[] = [...selectedIds]
      tempIds.push(id)
      updatePractitioners(tempIds)
    }
  }
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)

  function updatePractitioners(filteredIds: string[]) {
    if (onPractitionerSelectionChanged) {
      const selectedDoctors: PractitionerWithRole[] = [
        ...(practitionerSearchState.doctorRoleList ?? []),
      ].filter((val) => filteredIds.includes(val.id))
      onPractitionerSelectionChanged(selectedDoctors)
    }

    setSelectedIds(filteredIds)
  }

  useEffect(() => {
    if (preSelectedPractitionerRoles) {
      const tempIds: string[] = []
      for (let i = 0; i < preSelectedPractitionerRoles.length; i++) {
        tempIds.push(preSelectedPractitionerRoles[i].id)
      }
      setSelectedIds(tempIds)
    }
    dispatch(searchMedicalProviders('', undefined, providerType))
  }, [dispatch])
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role='alert'>
          <div>Error while fetching provider list</div>
          <pre>{error.message}</pre>
          <Button
            onClick={() => {
              dispatch(searchMedicalProviders())
            }}
          >
            Try again
          </Button>
        </div>
      )}
    >
      <Box display='flex' flexDirection='column'>
        <Box
          display='flex'
          flexDirection='row'
          p={1}
          justifyContent='space-between'
          alignContent='center'
          alignItems='center'
        >
          <WelloFormItemLabel title={t('labelCommon:agents')} />
          <IconButton
            aria-label='collapse_order_type'
            size='small'
            onClick={() => {
              setIsCollapsed(!isCollapsed)
            }}
          >
            {isCollapsed && <ArrowDropUpOutlined />}
            {!isCollapsed && <ArrowDropDownOutlined />}
          </IconButton>
        </Box>
        <Collapse in={isCollapsed} style={{ padding: 0, margin: 0 }}>
          <Box display='flex' flexDirection='row'>
            <TextField
              id='tf_search_provider'
              label={t('labelCommon:search')}
              size='small'
              fullWidth
              variant='outlined'
              onChange={(e) => {
                dispatch(
                  searchMedicalProviders('', e.target.value ?? '', providerType)
                )
              }}
            />
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            style={{
              width: '100%',
            }}
          >
            {' '}
            {practitionerSearchState.searching && (
              <WelloLoadingIndicator message='Searching providers' />
            )}
            {practitionerSearchState.error && (
              <Typography>
                {' '}
                {practitionerSearchState.errorMessage ??
                  t('labelCommon:error_search')}
              </Typography>
            )}
            {practitionerSearchState.noResultsAvailable && (
              <Typography> No Results available</Typography>
            )}
            {practitionerSearchState.resultsAvailable &&
              practitionerSearchState.doctorRoleList && (
                <List
                  style={{
                    width: '100%',
                  }}
                >
                  {practitionerSearchState.doctorRoleList.map(
                    (value, index: number) => {
                      const labelId = `checkbox-list-label-${value.id}`

                      return (
                        <ListItem
                          key={value.id}
                          role={undefined}
                          dense
                          style={{
                            width: '100%',
                            marginTop: 0,
                            marginBottom: 0,
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                          button
                          onClick={() => {
                            handleChecked(value.id)
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge='start'
                              checked={selectedIds.indexOf(value.id) !== -1}
                              tabIndex={-1}
                              size='small'
                              id={`check${index}`}
                              disableRipple
                              color='primary'
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={value.name}
                            primaryTypographyProps={{
                              variant: 'subtitle1',
                              color:
                                selectedIds.indexOf(value.id) !== -1
                                  ? 'primary'
                                  : undefined,
                            }}
                          />
                        </ListItem>
                      )
                    }
                  )}
                </List>
              )}
          </Box>
        </Collapse>
      </Box>
    </ErrorBoundary>
  )
}
