import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { getCodeOfValueSetFromTerminologyServiceByCode } from 'utils/valusetsHelpers'
import { WelloFormItemLabel } from '../LeftMenu/WelloFormItemLabel'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

export interface ValueSetSearchProps {
  id: string
  url?: string
  searchCode: R4.ICoding
  title?: string
  onOptionSelected: (selectedOption?: R4.ICoding) => void
  onNoOptionAvailable: () => void
  placeHolder?: string
}

export const CodeFromExistingValueSet: React.FC<ValueSetSearchProps> = ({
  id,
  url,
  searchCode,
  title,
  onOptionSelected,
  onNoOptionAvailable,
  placeHolder,
}) => {
  const classes = useStyles()
  const [value, setValue] = React.useState<R4.ICoding | undefined>()

  const [loading, setLoading] = React.useState(false)

  function getCodeFromValueSet() {
    console.log('-------------searchCode-------------', searchCode)
    setLoading(true)

    getCodeOfValueSetFromTerminologyServiceByCode({
      code: searchCode?.code ?? '',
      system: searchCode?.system ?? '',
      specificValueSetUrl: url,
    })
      .then((res) => {
        if (res) {
          setValue(res)
          setLoading(false)
          onOptionSelected(res)
        } else {
          setLoading(false)
          onNoOptionAvailable()
        }
      })
      .catch((err) => {
        setLoading(false)
        onNoOptionAvailable()
      })
  }

  React.useEffect(() => {
    if (searchCode !== undefined) {
      getCodeFromValueSet()
    }
  }, [])

  return (
    <Box display='flex' flexDirection='column' flexGrow={1} id={id}>
      {!loading && value && (
        <Box display='flex' flexDirection='column'>
          <Box display='flex' flexDirection='row'>
            <WelloFormItemLabel title={title ?? 'Selected Code'} />
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            py={0.75}
            px={0.5}
            style={{
              border: '1px solid #ccc',
              borderRadius: '4px',
              backgroundColor: '#ffffff',
            }}
          >
            <Typography
              variant='subtitle1'
              color='initial'
              style={{ padding: '2px' }}
            >
              {value.display ?? ''}
            </Typography>
          </Box>
        </Box>
      )}

      {loading && (
        <Box display='flex' flexDirection='column'>
          <Box display='flex' flexDirection='row'>
            <WelloFormItemLabel title='Selected Kriya (Procedure)' />
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            py={0.75}
            px={0.5}
            style={{
              border: '1px solid #ccc',
              borderRadius: '4px',
              backgroundColor: '#ffffff',
            }}
          >
            <Typography
              variant='subtitle1'
              color='initial'
              style={{ padding: '2px' }}
            >
              {`Fetching official code for ${searchCode.display} from value set...`}
            </Typography>
          </Box>
        </Box>
      )}

      {!loading && value === undefined && (
        <Box display='flex' flexDirection='column'>
          <Box display='flex' flexDirection='row'>
            <WelloFormItemLabel title='Selected Kriya (Procedure)' />
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            py={0.75}
            px={0.5}
            style={{
              border: '1px solid #ccc',
              borderRadius: '4px',
              backgroundColor: '#ffffff',
            }}
          >
            <Typography
              variant='subtitle1'
              color='initial'
              style={{ padding: '2px' }}
            >
              {`Official code is not available for ${searchCode.display ?? ''}`}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  )
}
