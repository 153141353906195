import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getCodeOfSystemFromCodableConceptList,
  getDisplayOfSystemFromCodableConcept,
} from 'utils/fhirResourcesHelper'
import { getTestNameFromOrderDetails } from 'utils/fhirResoureHelpers/referralHelpers'

interface MedicationTileProps {
  referrals: R4.IServiceRequest[]
  type: string
  onDeleteClicked?: () => {}
  onEditCLicked?: () => {}
  prescription?: boolean
}

export const LabTestPrescription: React.FC<MedicationTileProps> = ({
  referrals,
  type,
  onDeleteClicked,
  onEditCLicked,
  prescription,
}: MedicationTileProps) => {
  const [openIngredient, setOpenIngredient] = useState(false)
  const [selectedMedication, setSelectedMedication] = useState<R4.ICoding>()
  const [selectedRxNorm, setSelectedRxNorm] = useState<string>()

  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
    >
      <Grid container direction='column' spacing={1}>
        <Grid container direction='row' id='vitals'>
          <Grid item xs={3}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    {type === 'imaging' ? 'Imaging Test' : 'Test Name'}
                  </Typography>
                </Box>
              </Box>
              {/* <Box display='flex' width='2%' paddingTop={0.2}>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '30px',

                    width: '2px',
                  }}
                />
              </Box> */}
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    {type === 'imaging' ? 'Imaging Diagnostic Lab' : 'Lab Name'}
                  </Typography>
                </Box>

                {/* <Divider
                      orientation='horizontal'
                      flexItem
                      style={{
                        height: '2px',
                      }}
                    /> */}
              </Box>
              {/* <Box display='flex' width='2%' paddingTop={0.2}>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',
  
                      width: '2px',
                    }}
                  />
                </Box> */}
            </Box>
          </Grid>
        </Grid>
        {/* <Grid container direction='row' id='vitals'>
          <Grid item xs={12}>
            <Box width='100%' paddingLeft={0.2}>
              <Divider
                orientation='horizontal'
                flexItem
                style={{
                  height: '2px',
                }}
              />
            </Box>
          </Grid>
        </Grid> */}

        {/* ------------------------------------------------------------------------------- */}
        {referrals.map((e, index: number) => (
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            flexGrow={1}
            key={e.id}
          >
            <Grid
              container
              direction='row'
              id='vitals'
              //   style={{
              //     backgroundColor:
              //       index % 2
              //         ? prescription
              //           ? 'white'
              //           : kDialogueBackground
              //         : 'white',
              //   }}
            >
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      alignItems='center'
                      px={1}
                    >
                      {type === 'referrals' && (
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          {e.patientInstruction ??
                            getTestNameFromOrderDetails(e)}
                        </Typography>
                      )}
                      {type === 'imaging' && (
                        <Typography
                          color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,

                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          {getDisplayOfSystemFromCodableConcept(e.code)}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.1}>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box> */}
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {e.performer ? e.performer[0].display ?? '' : ''}
                      </Typography>
                    </Box>

                    {/* <Divider
                      orientation='horizontal'
                      flexItem
                      style={{
                        height: '2px',
                      }}
                    /> */}
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.1}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',
  
                          width: '2px',
                        }}
                      />
                    </Box> */}
                </Box>
              </Grid>
            </Grid>
            {/* <Grid container direction='row' id='vitals'>
              <Grid item xs={12}>
                <Box width='100%' paddingLeft={0.2}>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    style={{
                      height: '2px',
                    }}
                  />
                </Box>
              </Grid>
            </Grid> */}
          </Box>
        ))}
      </Grid>
    </Box>
  )
}
