import { R4 } from '@ahryman40k/ts-fhir-types'

export const PaymentTypeCodes: R4.ICoding[] = [
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-payment-method-cs',
    code: 'cash',
    display: 'Cash',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-payment-method-cs',
    code: 'dd',
    display: 'DD',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-payment-method-cs',
    code: 'neft',
    display: 'NEFT',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-payment-method-cs',
    code: 'rtgs',
    display: 'RTGS',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-payment-method-cs',
    code: 'pos',
    display: 'POS',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-payment-method-cs',
    code: 'google-pay',
    display: 'Google Pay',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-payment-method-cs',
    code: 'phonepe',
    display: 'PhonePe',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-payment-method-cs',
    code: 'paytm',
    display: 'Paytm',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-payment-method-cs',
    code: 'amazon-pay',
    display: 'Amazon Pay',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-payment-method-cs',
    code: 'imps',
    display: 'IMPS',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-payment-method-cs',
    code: 'rupay',
    display: 'RuPay',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
