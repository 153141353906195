import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import {
  DoctorBase,
  DoctorProfileService,
  ErrorResponse,
  SearchDocResponse,
} from 'lib/openApi'
import { OIDCUser } from 'models/oidcUser'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitDetails,
  getUserCurrentRole,
  isMedicalServiceProvider,
  isOrgAdmin,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getOIDCUserObject } from 'utils/authHelpers'
import { getPractitionersWithRoleObject } from 'utils/fhirResoureHelpers/practitioner_and_role_helpers'
import { logger } from 'utils/logger'
import { PractitionerSearchStatus } from './practioinerSearchStatusTypes'

const initialState: PractitionerSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
  userDetailsUpdating: false,
  userDetailsUpdated: false,
}

const practitionerSearchSlice = createSlice({
  name: 'practitionerSearch',
  initialState,
  reducers: {
    searchingDoctorDetails(
      state,
      action: PayloadAction<PractitionerSearchStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.userDetailsUpdated = false
      state.userDetailsUpdating = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.doctorList = undefined
      state.doctorRoleList = undefined
      state.userDetails = undefined
    },

    searchResults(state, action: PayloadAction<PractitionerSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.userDetailsUpdated = false
      state.userDetailsUpdating = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.doctorList = action.payload.doctorList
      state.doctorRoleList = action.payload.doctorRoleList
      state.userDetails = action.payload.userDetails
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<PractitionerSearchStatus>
    ) {
      state.error = false
      state.searching = false
      state.userDetailsUpdated = false
      state.userDetailsUpdating = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.doctorList = undefined
      state.doctorRoleList = undefined
      state.userDetails = undefined
    },

    errorWhileSearching(
      state,
      action: PayloadAction<PractitionerSearchStatus>
    ) {
      state.error = true
      state.searching = false
      state.userDetailsUpdated = false
      state.userDetailsUpdating = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.doctorList = undefined
      state.doctorRoleList = undefined
      state.userDetails = undefined
    },
  },
})

export const searchPractitioners =
  (name?: string, roles?: R4.ICoding[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PractitionerSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
      userDetailsUpdated: false,
      userDetailsUpdating: false,
    }
    dispatch(practitionerSearchSlice.actions.searchingDoctorDetails(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _include: 'PractitionerRole:practitioner',
        _count: 100,
      }

      let selectedRoles: string | undefined

      if (
        !isUnitAdmin() &&
        !isOrgAdmin() &&
        !(getUserCurrentRole() ?? []).includes('receptionist')
      ) {
        searchParameters._id = getCurrentUserPractitionerRoleDetails().id
      }
      if (
        isUnitAdmin() &&
        !isOrgAdmin() &&
        !(getUserCurrentRole() ?? []).includes('receptionist')
      ) {
        searchParameters.organization = `Organization/${
          getCurrentUserUnitDetails().id
        }`
      }

      if (selectedRoles) {
        searchParameters.role = selectedRoles
      }
      if (name) {
        searchParameters['practitioner.name'] = name
      }

      const response: any = await fhirClient.doGetResource(
        '/PractitionerRole?_include=PractitionerRole:service&_include:iterate=HealthcareService:healthcare-service-billing',
        searchParameters
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const practitionerRolesResponses: R4.IBundle =
          relatedFhirDecodeRes.right
        if (practitionerRolesResponses.total) {
          if (practitionerRolesResponses.total > 0) {
            if (practitionerRolesResponses.entry) {
              const practitionerWithRoleList: PractitionerWithRole[] =
                getPractitionersWithRoleObject(practitionerRolesResponses) ?? []

              state.doctorRoleList = practitionerWithRoleList
              state.resultsAvailable = true
              state.searching = false
              state.error = false
              dispatch(practitionerSearchSlice.actions.searchResults(state))
              return
            }
            state.resultsAvailable = false
            state.searching = false
            state.error = false
            state.noResultsAvailable = true
            dispatch(
              practitionerSearchSlice.actions.noDataFoundForSearch(state)
            )
            return
          }
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(practitionerSearchSlice.actions.noDataFoundForSearch(state))
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(practitionerSearchSlice.actions.errorWhileSearching(state))
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(practitionerSearchSlice.actions.errorWhileSearching(state))
    }
  }

export const fetchUser =
  (id: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PractitionerSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
      userDetailsUpdated: false,
      userDetailsUpdating: false,
    }
    dispatch(practitionerSearchSlice.actions.searchingDoctorDetails(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _id: id,
        _include: 'PractitionerRole:practitioner',
      }

      searchParameters.organization = `Organization/${
        getCurrentUserUnitDetails().id
      }`

      const response: any = await fhirClient.doGetResource(
        '/PractitionerRole',
        searchParameters
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const practitionerRolesResponses: R4.IBundle =
          relatedFhirDecodeRes.right
        if (practitionerRolesResponses.total) {
          if (practitionerRolesResponses.total > 0) {
            if (practitionerRolesResponses.entry) {
              const practitionerWithRoleList: PractitionerWithRole[] =
                getPractitionersWithRoleObject(practitionerRolesResponses) ?? []
              state.userDetails = practitionerWithRoleList[0]
              state.resultsAvailable = true
              state.searching = false
              state.error = false
              dispatch(practitionerSearchSlice.actions.searchResults(state))
              return
            }
            state.resultsAvailable = false
            state.searching = false
            state.error = false
            state.noResultsAvailable = true
            dispatch(
              practitionerSearchSlice.actions.noDataFoundForSearch(state)
            )
            return
          }
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(practitionerSearchSlice.actions.noDataFoundForSearch(state))
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(practitionerSearchSlice.actions.errorWhileSearching(state))
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(practitionerSearchSlice.actions.errorWhileSearching(state))
    }
  }

export const searchProviders =
  (phoneNumber: string, name: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PractitionerSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
      userDetailsUpdated: false,
      userDetailsUpdating: false,
    }
    dispatch(practitionerSearchSlice.actions.searchingDoctorDetails(state))
    try {
      const oidcUser: OIDCUser | null = getOIDCUserObject()
      if (oidcUser != null) {
        const response: SearchDocResponse | ErrorResponse =
          await DoctorProfileService.searchDoctorProfile({
            tid: oidcUser.wellopathy.tenantId,
            limit: 500,
          })

        const errorResp: ErrorResponse = response as ErrorResponse
        if (errorResp.code) {
          if (errorResp.code === '401') {
            state.errorMessage =
              'Yor are not allowed to access this. Please check permissions'
          }
          if (errorResp.code?.includes('500')) {
            state.errorMessage = 'Server is down. Please try later'
          }
          dispatch(practitionerSearchSlice.actions.noDataFoundForSearch(state))
        } else {
          const successResp: SearchDocResponse = response as SearchDocResponse

          if (successResp.entry) {
            const doctorList: DoctorBase[] = successResp.entry
            state.doctorList = doctorList

            dispatch(practitionerSearchSlice.actions.searchResults(state))
          } else {
            const errorSearchDoctor: PractitionerSearchStatus = {
              error: false,
              noResultsAvailable: true,
              resultsAvailable: false,
              searching: false,
              userDetailsUpdated: false,
              userDetailsUpdating: false,
            }
            dispatch(
              practitionerSearchSlice.actions.noDataFoundForSearch(
                errorSearchDoctor
              )
            )
          }
        }
      } else {
        // TODO Log out user
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: PractitionerSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
        userDetailsUpdated: false,
        userDetailsUpdating: false,
      }
      dispatch(
        practitionerSearchSlice.actions.errorWhileSearching(errorSearchDoctor)
      )
    }
  }

export function getPractitionerRoleObject(item: R4.IPractitionerRole) {
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    gender: '',
    name: item.practitioner?.display ?? '',
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: false,
  }
  return val
}

export default practitionerSearchSlice.reducer
