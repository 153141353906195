import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Grid,
  Divider,
  Tooltip,
  Link,
  Collapse,
  Button,
} from '@material-ui/core'
import AodIcon from '@mui/icons-material/Aod'

import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedCondition, GroupedConditionData } from 'models/groupedCondition'
import { PurposeOfUse } from 'models/purposeOfUse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestConditionHistoryOfPatient } from 'redux/patientMedicalHistory/medicalConditionHistory/medicalHistoryConditionsSlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getAgeOf, getTimeAgo } from 'utils/dateUtil'
import {
  getNotesFromAllergy,
  getNotesString,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { SOAPIndicativeElement } from 'wello-web-components'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { updateMedicalConditions } from 'utils/fhirResoureHelpers/appointmentHelpers'
import {
  getValueCodingFromExtension,
  getValueRefValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import { requestAssessmentMedicalConditions } from 'redux/consultation/assessmentMedicalCondition/medicalAssessmentSlice'
import OverflowTypography from '../plan/ayurveda/overflowTypography'
import { AddMedicalConditionHandler } from '../addition/addMedicalConditions'

interface MedicalConditionsProps {
  patient: R4.IPatient
  requestingAppointmentId?: string
}

export const MedicalConditionsForAssessment: React.FC<MedicalConditionsProps> =
  ({ patient, requestingAppointmentId }: MedicalConditionsProps) => {
    const { t } = useTranslation(['common'])
    const [addButton, showAddButton] = useState<boolean>(false)
    const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
    const dispatch = useDispatch()
    const medicalHistorySlice = useSelector(
      (state: RootState) => state.medicalAssessmentConditionSlice
    )

    const [fullText, setFullText] = React.useState<string>()
    const [showText, setShowText] = React.useState<boolean>(false)

    const [fullTextForNotes, setFullTextForNotes] = React.useState<string>()
    const [showTextNotes, setShowTextNotes] = React.useState<boolean>(false)

    const [groupVitals, setGroupVitals] = useState<GroupedConditionData[]>([])
    const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
      dispatch(
        requestAssessmentMedicalConditions(patient, requestingAppointmentId)
      )
    }, [dispatch, patient])

    useEffect(() => {
      if (
        medicalHistorySlice.resultsAvailable &&
        medicalHistorySlice.groupedDateWiseConditions
      ) {
        updateConditions(medicalHistorySlice.groupedDateWiseConditions)
      }
    }, [medicalHistorySlice])

    function updateConditions(conditionList: GroupedConditionData[]) {
      const results: GroupedConditionData[] = []
      for (let i = 0; i < conditionList.length; i++) {
        results.push({
          date: conditionList[i].date,
          condition: conditionList[i].condition,
          checked: true,
        })
      }
      setGroupVitals(results)
    }

    function handleCollapseForPanel1(rate: boolean, index: number) {
      const values: GroupedConditionData[] = [...groupVitals]
      values[index].checked = rate
      setGroupVitals(values)
    }
    return (
      <>
        <Box
          flexGrow
          width='100%'
          flexDirection='column'
          display='flex'
          style={{ padding: 0, margin: 0 }}
        >
          <Box
            flexGrow
            width='100%'
            display='flex'
            flexDirection='row'
            onMouseEnter={() => showAddButton(true)}
            onMouseLeave={() => showAddButton(false)}
          >
            <Box flexDirection='row' display='flex' flexGrow width='100%'>
              {/* {isMedicalServiceProvider() &&
              status !== 'completed' &&
              !splitview && (
                <IconButton
                  style={{ padding: '4px' }}
                  onClick={() => {
                    setOpenAddDialogue(true)
                  }}
                >
                  <Add style={{ height: '14px' }} color='primary' />
                </IconButton>
              )} */}
            </Box>
          </Box>

          {medicalHistorySlice.searchingConditions && (
            <Box flexGrow width='100%' display='flex' flexDirection='row'>
              <CircularProgress size={15} />
            </Box>
          )}
          {medicalHistorySlice.noResultsAvailable && (
            <Box flexGrow width='100%' display='flex' flexDirection='row'>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                {' '}
                No previous active conditions
              </Typography>
            </Box>
          )}
          {medicalHistorySlice.errorWhileSearchingConditions && (
            <Box flexGrow width='100%' display='flex' flexDirection='row'>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                {medicalHistorySlice.errorReason ??
                  'Error while fetching conditions'}
              </Typography>
            </Box>
          )}
          {medicalHistorySlice.resultsAvailable &&
            medicalHistorySlice.availableConditions && (
              <Box flexGrow width='100%' display='flex' flexDirection='row'>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  flexGrow
                >
                  {groupVitals.map((val, index: number) => (
                    <Box key={`med_cond${val.date}`} width='100%' py={1}>
                      <Box
                        paddingX={1}
                        borderRadius={2}
                        style={{
                          backgroundColor: 'lightGrey',
                        }}
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        height={40}
                      >
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow={1}
                          alignItems='center'
                        >
                          <Typography
                            variant='subtitle1'
                            style={{
                              color: 'black',

                              fontWeight: 'bold',
                            }}
                          >
                            Recorded on{' '}
                            {moment(val.date!).format('Do MMMM YYYY hh:mm A')}
                          </Typography>
                        </Box>
                        <Box
                          justifyContent='flex-end'
                          display='flex'
                          flexGrow={1}
                          alignItems='center'
                        >
                          <Box px={1}>
                            <Tooltip title=''>
                              <IconButton
                                aria-label='collapse_order_type'
                                size='small'
                                onClick={() => {
                                  handleCollapseForPanel1(!val.checked, index)
                                }}
                              >
                                {val.checked && <ArrowDropUpOutlined />}
                                {!val.checked && <ArrowDropDownOutlined />}
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                      <Collapse
                        in={val.checked}
                        style={{
                          width: '100%',
                        }}
                      >
                        <Box
                          border={4}
                          style={{
                            boxShadow: '0px 0px 4px #ccc',
                            margin: '3px 0px',
                            borderRadius: '4px',
                            borderColor: '#F8F8F8',
                            width: '100%',
                            height: 'auto',
                            backgroundColor: '#F8F8F8',
                          }}
                          key={`med_cond${val.date}`}
                        >
                          <Grid container direction='column' spacing={1}>
                            <Grid
                              container
                              direction='row'
                              id='vitals'
                              style={{
                                backgroundColor: 'white',
                              }}
                            >
                              <Grid item xs={10}>
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      justifyContent='flex-start'
                                      display='flex'
                                      flexGrow
                                      minHeight={30}
                                      alignItems='center'
                                      px={1}
                                      paddingBottom={1}
                                    >
                                      <Typography
                                        variant='subtitle2'
                                        // color='initial'
                                        style={{
                                          fontFamily: 'Open Sans',
                                          fontSize: 12,
                                          color: 'black',

                                          fontWeight: 'bold',
                                          lineHeight: 'normal',
                                        }}
                                      >
                                        Condition
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box
                                    display='flex'
                                    width='2%'
                                    justifyContent='flex-end'
                                  >
                                    <Divider
                                      orientation='vertical'
                                      flexItem
                                      style={{
                                        height: '30px',

                                        width: '2px',
                                      }}
                                    />
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={2}>
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      justifyContent='flex-start'
                                      display='flex'
                                      flexGrow
                                      minHeight={30}
                                      alignItems='center'
                                      px={1}
                                      paddingBottom={1}
                                    >
                                      <Typography
                                        variant='subtitle2'
                                        // color='initial'
                                        style={{
                                          fontFamily: 'Open Sans',
                                          fontSize: 12,
                                          color: 'black',

                                          fontWeight: 'bold',
                                          lineHeight: 'normal',
                                        }}
                                      >
                                        Current Status
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>

                            <Grid container direction='row' id='vitals'>
                              <Grid item xs={12}>
                                <Box width='100%' paddingLeft={0.2}>
                                  <Divider
                                    orientation='horizontal'
                                    flexItem
                                    style={{
                                      height: '2px',
                                    }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          {val.condition.map((valData, indexFinal: number) => (
                            <Box
                              display='flex'
                              flexDirection='column'
                              key={valData.id}
                            >
                              <Grid container direction='column' spacing={1}>
                                <Grid
                                  container
                                  direction='row'
                                  id='vitals'
                                  style={{
                                    backgroundColor:
                                      indexFinal % 2
                                        ? 'white'
                                        : kDialogueBackground,
                                  }}
                                >
                                  <Grid item xs={10}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={40}
                                          alignItems='center'
                                          px={1}
                                        >
                                          {valData.code &&
                                            valData.code!.coding &&
                                            valData.code!.coding.length > 0 &&
                                            valData.code!.coding![0]
                                              .display && (
                                              <OverflowTypography
                                                text={
                                                  valData.code!.coding![0]
                                                    .display!
                                                }
                                                typographyProps={{
                                                  variant: 'subtitle2',
                                                  color: 'initial',
                                                }}
                                              />
                                            )}
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        justifyContent='flex-end'
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '40px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>

                                  <Grid item xs={2}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={40}
                                          alignItems='center'
                                          px={1}
                                        >
                                          {/* {getValueCodingFromExtension(
                                            valData.extension ?? [],
                                            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
                                          ) !== undefined && (
                                            <OverflowTypography
                                              text={
                                                getValueCodingFromExtension(
                                                  valData.extension ?? [],
                                                  'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
                                                )!.display ?? ''
                                              }
                                              typographyProps={{
                                                variant: 'subtitle2',
                                                color: 'initial',
                                              }}
                                            />
                                          )} */}

                                          {valData.clinicalStatus &&
                                            valData.clinicalStatus.coding &&
                                            valData.clinicalStatus.coding
                                              .length > 0 &&
                                            getValueCodingFromExtension(
                                              valData.extension ?? [],
                                              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
                                            ) !== undefined && (
                                              <OverflowTypography
                                                text={`${
                                                  valData.clinicalStatus
                                                    .coding[0].code === 'active'
                                                    ? 'Active'
                                                    : 'Inactive'
                                                } (${
                                                  getValueCodingFromExtension(
                                                    valData.extension ?? [],
                                                    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
                                                  )!.display ?? ''
                                                })`}
                                                typographyProps={{
                                                  variant: 'subtitle2',
                                                  color: 'initial',
                                                }}
                                              />
                                            )}
                                          {valData.clinicalStatus &&
                                            valData.clinicalStatus.coding &&
                                            valData.clinicalStatus.coding
                                              .length > 0 &&
                                            getValueCodingFromExtension(
                                              valData.extension ?? [],
                                              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
                                            ) === undefined && (
                                              <OverflowTypography
                                                text={`${
                                                  valData.clinicalStatus
                                                    .coding[0].code === 'active'
                                                    ? 'Active'
                                                    : 'Inactive'
                                                }`}
                                                typographyProps={{
                                                  variant: 'subtitle2',
                                                  color: 'initial',
                                                }}
                                              />
                                            )}
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Grid container direction='row' id='vitals'>
                                  <Grid item xs={12}>
                                    <Box width='100%' paddingLeft={0.2}>
                                      <Divider
                                        orientation='horizontal'
                                        flexItem
                                        style={{
                                          height: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                        </Box>
                      </Collapse>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
        </Box>

        <NoteDialog
          id='notesTile'
          open={showText}
          onClose={() => {
            setFullText('')
            setShowText(false)
          }}
          notesData={fullText!}
          dialogText='Condition'
        />

        <NoteDialog
          id='notesTile1'
          open={showTextNotes}
          onClose={() => {
            setFullTextForNotes('')
            setShowTextNotes(false)
          }}
          notesData={fullTextForNotes!}
          dialogText='Additional Notes'
        />
      </>
    )
  }
