/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core'
import { BodyPartState } from 'models/bodyPartState'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { PurposeOfUse } from 'models/purposeOfUse'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-vis/dist/style.css'
import { getDefaultCodeOfSystemFromCodableConcept } from 'utils/fhirResourcesHelper'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  addObservationDetails,
  getBodyPartStateFromObservations,
  getObservationResourceAddBodyPain,
  getUpdateObservationResourceForBodyPainUpdate,
  updateObservationDetails,
} from 'utils/fhirResoureHelpers/observationHelpers'
import BodyMap from './bodyMap'
import { ViewObservationsOfBodyParts } from './observationManagement/viewObservationOfBodyParts'

interface HigherFunctionExaminationForPTProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  isReadOnly?: boolean
}

export const BodyPartsPain: React.FC<HigherFunctionExaminationForPTProps> = ({
  fhirAppointmentDetails,
  isReadOnly = false,
}: HigherFunctionExaminationForPTProps) => {
  const [addingStatus, setAddingStatus] = useState<NetworkRequestStatus>({
    initial: true,
  })
  const { t } = useTranslation(['common'])
  const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())
  const [updateAgain, setUpdateAgain] = useState<string>(getUniqueTempId())
  const [baseObservations, setBaseObservations] = useState<R4.IObservation[]>()

  function addObservationDetailsOfBodyPain(selectedBodyPart: BodyPartState) {
    if (baseObservations) {
      if (selectedBodyPart.state) {
        setAddingStatus({
          requesting: true,
        })

        const index = baseObservations?.findIndex(
          (e) =>
            getDefaultCodeOfSystemFromCodableConcept(e.bodySite) ===
            selectedBodyPart.code
        )

        if (index > -1) {
          updateObservationDetails(
            getUpdateObservationResourceForBodyPainUpdate(
              baseObservations[index],
              selectedBodyPart.state
            )
          ).then((e) => {
            if (e) {
              setAddingStatus({
                requestSuccess: true,
              })
              setFetchAgain(getUniqueTempId())
            } else {
              setAddingStatus({
                requestError: true,
              })
            }
          })
        } else {
          addObservationDetails(
            getObservationResourceAddBodyPain(
              fhirAppointmentDetails.patient.id!,
              fhirAppointmentDetails.mainEncounter!.id!,
              [
                {
                  coding: [
                    {
                      system: 'http://snomed.info/sct',
                      code: '364626008',
                      display: 'Site of Pain',
                    },
                  ],
                },
              ],
              {
                coding: [
                  {
                    system: 'http://snomed.info/sct',
                    code: '22253000',
                    display: 'Pain',
                  },
                ],
              },
              {
                coding: [
                  {
                    system: 'http://wellopathy/pain-site',
                    code: selectedBodyPart.code,
                    display: selectedBodyPart.partName,
                  },
                ],
              },
              selectedBodyPart.state
            )
          ).then((e) => {
            if (e) {
              setAddingStatus({
                requestSuccess: true,
              })
              setFetchAgain(getUniqueTempId())
            } else {
              setAddingStatus({
                requestError: true,
              })
            }
          })
        }
      }
    }
  }

  return (
    <Grid container direction='column' style={{ padding: 0, margin: 0 }}>
      <Grid container direction='row' style={{ padding: 0, margin: 0 }}>
        <Box
          sx={{ width: '100%' }}
          display='flex'
          alignItems='center'
          flexDirection='row'
          bgcolor='#999999'
          minHeight={40}
        >
          <Box p={1}>
            <Typography
              variant='body1'
              style={{ color: 'white', fontWeight: 600 }}
            >
              {' '}
              Body Site of Pain
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid container style={{ padding: 0, margin: 0 }}>
        <Grid item xs={6} style={{ padding: 2 }}>
          {!isReadOnly && (
            <Typography variant='subtitle1' style={{ padding: 4 }}>
              Select body part to indicate pain
            </Typography>
          )}
          {baseObservations === undefined && <CircularProgress />}
          {baseObservations && (
            <BodyMap
              key={updateAgain}
              onSlectionChanges={(e) => {
                addObservationDetailsOfBodyPain(e)
              }}
              preAddedDetails={getBodyPartStateFromObservations(
                baseObservations
              )}
              isReadOnly={isReadOnly || addingStatus.requesting}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <Box paddingY={1}>
            <ViewObservationsOfBodyParts
              patient={fhirAppointmentDetails.patient}
              purposeOfUse={PurposeOfUse.IPDAppointment}
              category='364626008'
              encounterId={fhirAppointmentDetails.mainEncounter!.id!}
              status='completed'
              categoryLabel='Added pain details'
              fetchAgain={fetcAgain ?? 'new'}
              allowEditing={!isReadOnly}
              onObservationFetched={(res) => {
                setBaseObservations(res)
                setUpdateAgain(getUniqueTempId())
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
