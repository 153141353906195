import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  getAppointmentIdForFollowUp,
  getCodeOfSystemCodings,
  getCodeOfSystemFromCodableConceptList,
  getColorBasedOnNumber,
  getValueCodingFromExtension,
  removeExtensionFromExtensions,
} from 'utils/fhirResourcesHelper'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getTimeOfDayDisplayForStatement,
  getTimeOfDayDisplayMeal,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import {
  getNotesFromAllergy,
  getNotesString,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { statusCodings } from 'utils/constants'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'

import { showSuccessAlert, showErrorAlert } from 'redux/alertHandler/alertSlice'
import { updateConditionDetailsFollowup } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { useDispatch } from 'react-redux'

interface ExaminationTileProps {
  obsData: R4.ICondition[]
  fhirAppointmentDetails: FhirAppointmentDetail
  onMedicalConditionAdded: () => void
  split: boolean
}

export const ConditionTileForFollowUp: React.FC<ExaminationTileProps> = ({
  obsData,
  fhirAppointmentDetails,
  onMedicalConditionAdded,
  split,
}: ExaminationTileProps) => {
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)

  function updateConditionsForStatus(
    condition: R4.ICondition,
    statusCoding: R4.ICoding
  ) {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.ICondition = {
      ...condition,
    }

    if (oldProcedure.extension) {
      const codingData = getValueCodingFromExtension(
        oldProcedure.extension ?? [],
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
      )
      if (codingData) {
        oldProcedure.extension =
          removeExtensionFromExtensions(
            oldProcedure.extension,
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
          ) ?? []
      }

      oldProcedure.extension = [
        ...oldProcedure.extension,
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext',
          valueCoding: statusCoding,
        },
      ]
    } else {
      oldProcedure.extension = [
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext',
          valueCoding: statusCoding,
        },
      ]
    }
    setLoading(true)

    updateConditionDetailsFollowup(
      oldProcedure,
      getAppointmentIdForFollowUp(
        fhirAppointmentDetails.appointment.supportingInformation ?? []
      )
    ).then((e) => {
      if (e) {
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(
          showSuccessAlert('Condition status details updated successfully')
        )
        onMedicalConditionAdded()
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while updating Condition. Please try again later'
          )
        )
      }
    })
  }
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Grid container direction='column'>
        <Box
          border={4}
          style={{
            boxShadow: '0px 0px 4px #ccc',
            margin: '3px 0px',
            borderRadius: '4px',
            borderColor: '#F8F8F8',
            width: '100%',
            height: 'auto',
            backgroundColor: '#F8F8F8',
          }}
        >
          <Grid container direction='column' spacing={1}>
            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor: 'white',
              }}
            >
              <Grid item xs={10}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      minHeight={30}
                      alignItems='center'
                      px={1}
                      paddingBottom={1}
                    >
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Condition
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' width='2%' justifyContent='flex-end'>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      minHeight={30}
                      alignItems='center'
                      px={1}
                      paddingBottom={1}
                    >
                      <Typography
                        variant='subtitle2'
                        // color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Current Status
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {obsData.map((e, index: number) => (
          <Grid
            item
            xs={12}
            key={e.id}
            style={{
              backgroundColor: index % 2 === 0 ? kDialogueBackground : 'white',
            }}
          >
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='70%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={40}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,

                      fontWeight: 600,
                      lineHeight: 'normal',
                    }}
                  >
                    {e.code
                      ? e.code.coding && e.code.coding.length > 0
                        ? e.code.coding[0].display ?? ''
                        : ''
                      : ''}
                  </Typography>
                </Box>
              </Box>
              <Box
                display='flex'
                width='2%'
                justifyContent='flex-end'
                flexGrow
                alignItems='center'
              >
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '40px',

                    width: '2px',
                  }}
                />
              </Box>

              <Box
                display='flex'
                flexDirection='row'
                width='16.6%'
                paddingRight={0.2}
                paddingLeft={1}
                py={0.2}
              >
                <WelloSelectFHIRCoding
                  id='edit'
                  availableCodings={statusCodings}
                  onChanged={(type) => {
                    updateConditionsForStatus(e, type)
                  }}
                  textFieldProps={{
                    size: 'small',
                    fullWidth: true,
                  }}
                  preSelectedCoding={getValueCodingFromExtension(
                    e.extension ?? [],
                    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
                  )}
                  disabled={split}
                />
              </Box>
              {/* <Box
                display='flex'
                width='2%'
                justifyContent='flex-end'
                flexGrow
                alignItems='center'
              >
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '40px',

                    width: '2px',
                  }}
                />
              </Box> */}
            </Box>

            <Divider
              orientation='horizontal'
              flexItem
              style={{
                height: '1px',
              }}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Divider
            orientation='horizontal'
            flexItem
            style={{
              height: '1px',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
