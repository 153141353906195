import { CircularProgress } from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import { useTranslation } from 'react-i18next'
import { getContentOfResource } from 'services/fileServices'

export interface AttachmentImageViewer {
  audioPath: string
  resourceId: string
  decodingRequired: boolean
  resourceType: string
  contentType: string
}

export const AudioAttachment: React.FC<AttachmentImageViewer> = ({
  audioPath,
  resourceId,
  decodingRequired,
  resourceType,
  contentType,
}) => {
  const { t } = useTranslation()
  const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: true,
  })
  const [imgSrc, setImgSrc] = useState<string>()

  function fetchAudioData() {
    setFetchingStatus({
      requesting: true,
    })

    getContentOfResource(
      resourceType,
      '',
      resourceId,
      contentType,
      audioPath,
      decodingRequired
    )
      .then((src) => {
        setImgSrc(src)
        setFetchingStatus({
          requesting: false,
          requestSuccess: true,
        })
      })
      .catch((e) => {
        setFetchingStatus({
          requesting: false,
          requestError: true,
        })
      })
  }

  useEffect(() => {
    fetchAudioData()
  }, [])

  return (
    <>
      {fetchingStatus.requesting && <CircularProgress size={20} />}
      {fetchingStatus.requestSuccess && imgSrc && (
        <AudioPlayer
          volume={0.8}
          progressUpdateInterval={100}
          src={imgSrc}
          loop={false}
          customProgressBarSection={[
            RHAP_UI.CURRENT_TIME,
            // eslint-disable-next-line react/jsx-key
            <div>/</div>,
            RHAP_UI.DURATION,
            RHAP_UI.PROGRESS_BAR,
            RHAP_UI.VOLUME,
          ]}
          showJumpControls={false}
          customAdditionalControls={[]}
          customVolumeControls={[]}
        />
      )}
    </>
  )
}
