import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Grid,
  Divider,
  Tooltip,
  Link,
  Collapse,
  Button,
} from '@material-ui/core'
import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
} from '@material-ui/icons'
import AodIcon from '@mui/icons-material/Aod'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedAllergy } from 'models/groupedAlergy'
import { PurposeOfUse } from 'models/purposeOfUse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestAllergiesHistoryOfPatient } from 'redux/patientMedicalHistory/allergyHistory/allergiesHistorySlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getAgeOf, getTimeAgo } from 'utils/dateUtil'
import {
  getNotesFromAllergy,
  getNotesString,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { SOAPIndicativeElement } from 'wello-web-components'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { updateAllergyDetails } from 'utils/fhirResoureHelpers/appointmentHelpers'
import OverflowTypography from 'views/components/consultation/plan/ayurveda/overflowTypography'

interface AllergiesProp {
  allergies: GroupedAllergy[]
}

export const AllergiesHistory: React.FC<AllergiesProp> = ({
  allergies,
}: AllergiesProp) => {
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const dispatch = useDispatch()
  const allergiesHistorySlice = useSelector(
    (state: RootState) => state.allergiesHistorySlice
  )
  const [fullText, setFullText] = React.useState<string>()
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullTextForNotes, setFullTextForNotes] = React.useState<string>()
  const [showTextNotes, setShowTextNotes] = React.useState<boolean>(false)
  const [groupVitals, setGroupVitals] = useState<GroupedAllergy[]>([])
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  function getCategory(category: string): string {
    let cat: string = ''
    if (category)
      switch (category) {
        case 'food':
          cat = 'Food'
          break
        case 'medication':
          cat = 'Medication'
          break
        case 'environment':
          cat = 'Environment'
          break
        case 'biologic':
          cat = 'Biologic'
          break
        default:
          break
      }

    return cat
  }

  useEffect(() => {
    if (allergies.length > 0) {
      updateAlergyList(allergies)
    }
  }, [allergiesHistorySlice])

  function updateAlergyList(procedureList: GroupedAllergy[]) {
    const results: GroupedAllergy[] = []
    for (let i = 0; i < procedureList.length; i++) {
      results.push({
        condition: procedureList[i].condition,
        checked: true,
      })
    }
    setGroupVitals(results)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: GroupedAllergy[] = [...groupVitals]
    values[index].checked = rate
    setGroupVitals(values)
  }
  return (
    <Box
      flexGrow
      width='100%'
      flexDirection='column'
      display='flex'
      style={{ padding: 0, margin: 0 }}
    >
      <Box
        flexGrow
        width='100%'
        display='flex'
        flexDirection='row'
        onMouseEnter={() => showAddButton(true)}
        onMouseLeave={() => showAddButton(false)}
      >
        <Box flexDirection='row' display='flex' flexGrow width='100%'>
          <Box py={1}>
            <Typography variant='subtitle1'>
              {' '}
              {t('labelCommon:Allergies')}{' '}
            </Typography>
          </Box>{' '}
          {/* {isMedicalServiceProvider() && status !== 'completed' && !splitView && (
            <IconButton
              style={{ padding: '4px' }}
              onClick={() => {
                setOpenAddDialogue(true)
              }}
            >
              <Add style={{ height: '14px' }} color='primary' />
            </IconButton>
          )} */}
        </Box>
      </Box>

      {allergies.length === 0 && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            {' '}
            No data available
          </Typography>
        </Box>
      )}

      {allergies.length > 0 && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Box display='flex' flexDirection='column' width='100%' flexGrow>
            {groupVitals.map((val, index: number) =>
              val.condition.code ? (
                <Box key={`med_cond${val.condition.id}`} width='100%' py={1}>
                  <Box
                    paddingX={1}
                    borderRadius={2}
                    style={{
                      backgroundColor: 'lightGrey',
                    }}
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    height={40}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow={1}
                      alignItems='center'
                    >
                      <Typography
                        variant='subtitle1'
                        style={{
                          color: 'black',

                          fontWeight: 'bold',
                        }}
                      >
                        Recorded on{' '}
                        {moment(val.condition.recordedDate).format(
                          'Do MMMM YYYY hh:mm A'
                        )}
                      </Typography>
                    </Box>
                    <Box
                      justifyContent='flex-end'
                      display='flex'
                      flexGrow={1}
                      alignItems='center'
                    >
                      <Box px={1}>
                        <Tooltip title=''>
                          <IconButton
                            aria-label='collapse_order_type'
                            size='small'
                            onClick={() => {
                              handleCollapseForPanel1(!val.checked, index)
                            }}
                          >
                            {val.checked && <ArrowDropUpOutlined />}
                            {!val.checked && <ArrowDropDownOutlined />}
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                  <Collapse
                    in={val.checked}
                    style={{
                      width: '100%',
                    }}
                  >
                    <Box
                      border={4}
                      style={{
                        boxShadow: '0px 0px 4px #ccc',
                        margin: '3px 0px',
                        borderRadius: '4px',
                        borderColor: '#F8F8F8',
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#F8F8F8',
                      }}
                      key={`med_cond${val.condition.id}`}
                    >
                      <Grid container direction='column' spacing={1}>
                        <Grid
                          container
                          direction='row'
                          id='vitals'
                          style={{
                            backgroundColor: kDialogueBackground,
                          }}
                        >
                          <Grid item xs={3}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Allergy
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    height: '30px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={2}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Onset Duration
                                  </Typography>
                                </Box>

                                {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    height: '30px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={1}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Status
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    height: '30px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={2}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Category
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    height: '30px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Additional Notes
                                  </Typography>
                                </Box>
                              </Box>
                              {/* <Box display='flex' width='2%' paddingTop={0.2}>
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    height: '30px',

                                    width: '2px',
                                  }}
                                />
                              </Box> */}
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          id='vitals'
                          style={{
                            backgroundColor: '#EDF2FA',
                          }}
                        >
                          <Grid item xs={12}>
                            <Box width='100%' paddingLeft={0.2}>
                              <Divider
                                orientation='horizontal'
                                flexItem
                                style={{
                                  height: '2px',
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          id='vitals'
                          style={{
                            backgroundColor: '#EDF2FA',
                          }}
                        >
                          <Grid item xs={3}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                >
                                  {val.condition.meta &&
                                    val.condition.meta.security &&
                                    val.condition.meta.security[0].code ===
                                      'PATRPT' && (
                                      <Box paddingRight={1}>
                                        <Tooltip title=''>
                                          <IconButton
                                            aria-label='collapse_order_type'
                                            size='small'
                                          >
                                            <AodIcon color='primary' />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    )}
                                  {val.condition.code &&
                                    val.condition.code!.coding &&
                                    val.condition.code!.coding.length > 0 &&
                                    val.condition.code!.coding![0].display && (
                                      <OverflowTypography
                                        text={
                                          val.condition.code!.coding![0]
                                            .display!
                                        }
                                        typographyProps={{
                                          variant: 'subtitle2',
                                          color: 'initial',
                                        }}
                                      />
                                    )}
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    height: '30px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={2}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                >
                                  <Typography
                                    color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 12,

                                      fontWeight: 600,
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    {val.condition.onsetDateTime
                                      ? val.condition.clinicalStatus
                                          ?.coding?.[0].code === 'active'
                                        ? `Since ${moment(
                                            val.condition.onsetDateTime ??
                                              new Date()
                                          ).format('DD-MM-YYYY')}`
                                        : `Since ${moment(
                                            val.condition.onsetDateTime ??
                                              new Date()
                                          ).format('DD-MM-YYYY')}`
                                      : val.condition.clinicalStatus
                                          ?.coding?.[0].code === 'active'
                                      ? `Since  ${getAgeOf(
                                          val.condition.onsetAge
                                        )}`
                                      : `${getAgeOf(
                                          val.condition.onsetAge
                                        )} ago`}
                                    {/* {val.condition.onsetDateTime
                                        ? `${moment(
                                            val.condition.onsetDateTime ??
                                              new Date()
                                          ).format('DD-MM-YYYY')}`
                                        : `Since  ${getAgeOf(
                                            val.condition.onsetAge
                                          )}`} */}
                                  </Typography>
                                </Box>

                                {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    height: '30px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={1}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                >
                                  {val.condition.clinicalStatus?.coding?.[0]
                                    .code && (
                                    <Typography
                                      color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,

                                        fontWeight: 600,
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      {val.condition.clinicalStatus?.coding?.[0]
                                        .code === 'active'
                                        ? 'Active'
                                        : 'Inactive'}
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    height: '30px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={2}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                >
                                  {val.condition.category && (
                                    <Typography
                                      color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,

                                        fontWeight: 600,
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      {getCategory(val.condition.category[0])}
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    height: '30px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                paddingLeft={1}
                                paddingTop={1}
                              >
                                {hasNotes(val.condition.note) && (
                                  <OverflowTypography
                                    text={getNotesString(val.condition.note)}
                                    typographyProps={{
                                      variant: 'subtitle2',
                                      color: 'initial',
                                    }}
                                  />
                                )}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Collapse>
                </Box>
              ) : (
                <div key={val.condition.id} />
              )
            )}
          </Box>
        </Box>
      )}

      <NoteDialog
        id='notesTile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
        dialogText='Allergy'
      />
      <NoteDialog
        id='notesTile1'
        open={showTextNotes}
        onClose={() => {
          setFullTextForNotes('')
          setShowTextNotes(false)
        }}
        notesData={fullTextForNotes!}
        dialogText='Additional Notes'
      />
    </Box>
  )
}
