/* eslint-disable no-irregular-whitespace */
import { Box, Grid, Typography } from '@material-ui/core'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-vis/dist/style.css'
import { isPhysiotherapist } from 'services/userDetailsService'
import { PhysicalExaminationOfPTList } from './physicalExamination/physicalExaminationsList'
import { TherapiesCardOfIPDForPT } from './treatment/therapiesCardForIPDForPT'
import { TreatmentCard } from './treatment/treatmentCard'

interface AssessmentDetailsScreenForPTProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  isReadOnly?: boolean
}

export const AssessmentDetailsScreenForPT: React.FC<AssessmentDetailsScreenForPTProps> =
  ({
    fhirAppointmentDetails,
    isReadOnly = false,
  }: AssessmentDetailsScreenForPTProps) => {
    const { t } = useTranslation(['common'])
    const [addButton, showAddButton] = useState<boolean>(false)

    return (
      <Grid container direction='column'>
        <Grid item>
          <Typography variant='h5' color='primary' id='therapiesMenu'>
            {' '}
            Therapies
          </Typography>
        </Grid>
        <Grid>
          <Box>
            <TherapiesCardOfIPDForPT
              fhirAppointmentDetails={fhirAppointmentDetails}
              isReadonly={isReadOnly}
              status={
                fhirAppointmentDetails.mainTask.businessStatus!.coding
                  ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'initiate-discharge' ||
                    fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'discharged' ||
                    fhirAppointmentDetails.mainServiceRequest.status! ===
                      'completed'
                    ? 'completed'
                    : ''
                  : ''
              }
              id='therapies'
            />
          </Box>
        </Grid>
        <Grid item>
          <Typography variant='h5' color='primary' id='examinationMenu'>
            {' '}
            Physical Examination
          </Typography>
        </Grid>
        <Grid item>
          <PhysicalExaminationOfPTList
            fhirAppointmentDetails={fhirAppointmentDetails}
          />
        </Grid>
      </Grid>
    )
  }
