import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import { FhirPartnerLabDetails } from 'models/fhirPartnerLabDetails'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPartnerLabDetails } from 'redux/collectionCenter/partnerLabManagementSlice'
import { searchInvitations } from 'redux/collectionCenter/Search/partnerLabSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserPractitionerRoleDetailsWithPractitioner,
  getCurrentUserUnitDetails,
  isMedicalServiceProvider,
} from 'services/userDetailsService'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { PartnerLabDetails } from './partnerLabDetails'
import { PartnerLabDataTable } from './partnerLabList/partnerLabDataTable'

export interface IUnitAdmin {
  onTap: () => void
}

export const PartnerLabSearch: React.FC<IUnitAdmin> = ({ onTap }) => {
  const partnerLabOnboardInvitationsSlice = useSelector(
    (state: RootState) => state.partnerLabOnboardInvitationsSlice
  )
  const dispatch = useDispatch()
  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<R4.IOrganization>(getCurrentUserUnitDetails())
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      currentOrganizationDetails.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''
  const currentPractWithRole: PractitionerWithRole | undefined =
    getCurrentUserPractitionerRoleDetailsWithPractitioner()
  const [selectedLabTypes, setSelectedLabTypes] = useState<string[]>([
    'completed',
    'requested',
  ])
  const selectedDoctors = useRef<PractitionerWithRole[] | undefined>()
  const [selectedTests, setSelectedTests] = useState<LabOfferingDetail[]>([])
  const [selectedPartnerLabDetails, setSelectedPartnerLabDetails] =
    useState<FhirPartnerLabDetails>()
  const { width } = useWindowDimensions()
  const [selectedPractitioners, setSelectedPractitioners] = useState<
    PractitionerWithRole[]
  >(
    isMedicalServiceProvider() && currentPractWithRole
      ? [currentPractWithRole]
      : []
  )

  useEffect(() => {
    dispatch(searchInvitations(selectedPractitioners ?? [], selectedLabTypes))
  }, [dispatch, selectedLabTypes, selectedPractitioners])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          {/* <Box
            display='flex'
            width='15%'
            style={{ overflow: 'auto', height: '100%', minWidth: '250px' }}
          >
                          <SimpleBar
              style={{
                height: '100%',
                width: '100%',
                overflowX: 'hidden',
                padding: '8px',
              }}
            >
                  <Box px={1} width='100%'>
                  <LabTypeSelection
                  onSelectionChanged={(changedTypes) => {
                    setSelectedLabTypes(changedTypes)
                  }}
                />
            <Box paddingY={0.5}>
                  <PartnerLabSelector
                    preSelectedPractitionerRoles={selectedPractitioners}
                    onProviderSelectionChanged={(
                      selectedValues: DoctorBase[]
                    ) => {}}
                    onPractitionerSelectionChanged={(
                        selectedPractitionerss: PractitionerWithRole[]
                      ) => {                          
                        setSelectedPractitioners(selectedPractitionerss)
                          selectedDoctors.current = selectedPractitionerss
                      }}
                    doctorListId='appointmentDoctor'
                    providerType='partnerLab'
                  />
                </Box>
                <LabTestSelector
                  onLabTestsChanged={(changedTests) => {
                    setSelectedTests(changedTests)
                  }}
                />
            </Box>
        </SimpleBar>
          </Box> */}

          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
            >
              {partnerLabOnboardInvitationsSlice.searching && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>
            <Box
              display='flex'
              flexGrow={4}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
              style={{
                backgroundColor: '#ececec',
              }}
            >
              {partnerLabOnboardInvitationsSlice.noResultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    align='center'
                  >
                    No Partner Lab Data Available.
                  </Typography>
                </Box>
              )}
              {partnerLabOnboardInvitationsSlice.error && (
                <Box
                  //   display='flex'
                  //   flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                  paddingLeft={50}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container justifyContent='center'>
                        <Typography
                          variant='subtitle1'
                          color='error'
                          align='center'
                        >
                          Error while searching Partner Lab . Please try again
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {partnerLabOnboardInvitationsSlice.resultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height='100%'
                  overflow='auto'
                  // flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexGrow={1}
                    overflow='auto'
                    height='100%'
                    //  paddingTop={0.2}
                  >
                    <PartnerLabDataTable
                      partnerLabData={
                        partnerLabOnboardInvitationsSlice.partnerLabDetails
                          ? partnerLabOnboardInvitationsSlice.partnerLabDetails
                          : []
                      }
                      onPartnerLabSelected={(
                        partnerLab: FhirPartnerLabDetails
                      ) => {
                        setSelectedPartnerLabDetails(partnerLab)
                        dispatch(fetchPartnerLabDetails(partnerLab.id ?? ''))
                        onTap()
                      }}
                    />
                  </Box>
                  {selectedPartnerLabDetails && (
                    <Box
                      display='flex'
                      flexGrow={4}
                      overflow='auto'
                      minWidth={`${width - 460}px`}
                      height='100%'
                      style={{ backgroundColor: '#ececec' }}
                    >
                      <PartnerLabDetails
                        partnerLabDetailsData={selectedPartnerLabDetails}
                        onCloseClicked={() => {
                          setSelectedPartnerLabDetails(undefined)
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </div>
  )
}
