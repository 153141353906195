import { R4 } from '@ahryman40k/ts-fhir-types'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import {
  BundleTypeKind,
  ContactPointUseKind,
  HumanNameUseKind,
  IdentifierUseKind,
  PatientGenderKind,
  SlotStatusKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { truncate } from 'fs'
import moment from 'moment'
import _, { values } from 'lodash'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserUnitDetails,
} from 'services/userDetailsService'
import { logger } from './logger'
import {
  AddressProofType,
  AddressProofTypeForForeign,
} from './constants/proof_types'
import {
  getIdentifierValueBySystem,
  getIdentifierValueBySystemTypeDAata,
  getIdentifierValueBySystemTypeDAataVal,
  titleCase,
} from './fhirResourcesHelper'
import { nationalityValueSet, visaTypes } from './constants'

export function getFhirPatientObjectFromForm(
  formData: any
): R4.IPatient | undefined {
  if (formData) {
    const pat: R4.IPatient = {
      resourceType: 'Patient',
    }
    const profileTag: R4.ICoding = {
      system:
        'http://wellopathy.com/fhir/india/core/vs/profileCompletionIndicators',
      code: 'profile_details_completed',
    }
    const meta: R4.IMeta = {
      tag: [profileTag],
    }
    pat.meta = meta

    const identifier: R4.IIdentifier = {}
    identifier.use = IdentifierUseKind._usual
    identifier.system = 'mrn-tenant1'
    identifier.value = `P${Math.random().toString(36).substring(2)}`
    if (pat.identifier == null) {
      pat.identifier = [identifier]
    } else {
      pat.identifier.push(identifier)
    }

    const addressIdentifier: R4.IIdentifier = {}
    addressIdentifier.type = {
      coding: [
        getFhirCodIngFromStringCode(
          formData.nationality !== 'IN'
            ? AddressProofTypeForForeign
            : AddressProofType,
          formData.nationality !== 'IN'
            ? 'passport'
            : formData.addressProofTypeCode
        )!,
      ],
    }
    addressIdentifier.use = IdentifierUseKind._usual
    addressIdentifier.system = 'address-proof'
    addressIdentifier.value = formData.addressProofId
    if (pat.identifier == null) {
      pat.identifier = [addressIdentifier]
    } else {
      pat.identifier.push(addressIdentifier)
    }
    // const phoneIdentifier: R4.IIdentifier = {}
    // phoneIdentifier.value = `+91${formData.phone}`
    // phoneIdentifier.system = 'phone'
    // pat.identifier.push(phoneIdentifier)
    // if (formData.email) {
    //   const emailIdentifier: R4.IIdentifier = {}
    //   emailIdentifier.value = `${formData.email}`
    //   emailIdentifier.system = 'email'
    //   pat.identifier.push(emailIdentifier)
    // }
    if (formData.lrNumber) {
      const labRefIdentifier: R4.IIdentifier = {}
      labRefIdentifier.value = `${formData.lrNumber}`
      labRefIdentifier.system = 'labReference'
      pat.identifier.push(labRefIdentifier)
    }

    const humanName: R4.IHumanName = {}
    humanName.given = [_.capitalize(formData.name.trim())]
    if (formData.middleName) {
      humanName.given.push(_.capitalize(formData.middleName.trim()))
    }
    humanName.family = _.capitalize(formData.lastName.trim())
    humanName.use = HumanNameUseKind._official
    if (pat.name == null) {
      pat.name = [humanName]
    } else {
      pat.name.push(humanName)
    }

    const phoneContact: R4.IContactPoint = {}
    phoneContact.use = ContactPointUseKind._mobile
    phoneContact.system = R4.ContactPointSystemKind._phone
    phoneContact.rank = 1
    phoneContact.value = `+91${formData.phone}`
    if (pat.telecom == null) {
      pat.telecom = [phoneContact]
    } else {
      pat.telecom.push(phoneContact)
    }
    if (formData.email && formData.email.length > 0) {
      const emailContact: R4.IContactPoint = {}
      emailContact.use = ContactPointUseKind._home
      emailContact.system = R4.ContactPointSystemKind._email
      emailContact.rank = 1
      emailContact.value = formData.email

      if (pat.telecom == null) {
        pat.telecom = [emailContact]
      } else {
        pat.telecom.push(emailContact)
      }
    }

    const dateRes: E.Either<Errors, string> = R4.RTTI_date.decode(formData.dob)
    if (dateRes._tag === 'Right') {
      pat.birthDate = dateRes.right
    } else {
      throw Error('Invalid date format')
    }
    const photo: R4.IAttachment = {}
    photo.data = formData.imgName
    photo.contentType = formData.profilePhotoMimeType
    if (pat.photo == null) {
      pat.photo = [photo]
    } else {
      pat.photo.push(photo)
    }
    const patAddress: R4.IAddress[] = []

    if (formData.address.length > 0) {
      const address: R4.IAddress = {}
      const add: string[] = []

      add.push(formData.address.trim())
      address.line = add
      address.city = formData.city
      if (formData.state && formData.state.length > 0) {
        address.state = formData.state
        address.country = 'India'
      } else {
        address.country = formData.country
      }
      address.use =
        formData.addressType === 'home'
          ? R4.AddressUseKind._home
          : R4.AddressUseKind._work
      address.type = R4.AddressTypeKind._both
      patAddress.push(address)
      pat.address = patAddress
    }

    if (formData.nationality !== 'IN') {
      const address: R4.IAddress = {}
      const add: string[] = []
      if (formData.tempAddress && formData.tempAddress.length > 0) {
        add.push(formData.tempAddress.trim())
        address.line = add
        address.city = formData.tempCity
        address.state = formData.tempState
        address.country = formData.country
        address.use = R4.AddressUseKind._temp
        address.type = R4.AddressTypeKind._both
        patAddress.push(address)
        pat.address = patAddress
      }
    }

    if (formData.nationality !== 'IN') {
      const filteredDataForVisaIssue = nationalityValueSet.filter(
        (d) => d.display! === formData.visaIssueCountry
      )
      const visaType = visaTypes.filter((d) => d.code! === formData.visaType)

      const extension: R4.IExtension[] = [
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-number-ext',
          valueString: formData.visaNo,
        },
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext',
          valuePeriod: {
            start: moment(formData.visaIssueDate).toISOString(),
            end: moment(formData.visaExpiryDate).toISOString(),
          },
        },
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-type-ext',
          valueCodeableConcept: {
            coding: visaType.length > 0 ? visaType : [],
          },
        },
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-issued-country-ext',
          valueCodeableConcept: {
            text: formData.visaIssueCity,
            coding:
              filteredDataForVisaIssue.length > 0
                ? filteredDataForVisaIssue
                : [],
          },
        },
      ]
      pat.extension = extension
    }

    pat.gender =
      formData.gender === PatientGenderKind._male
        ? PatientGenderKind._male
        : formData.gender === PatientGenderKind._female
        ? PatientGenderKind._female
        : formData.gender === PatientGenderKind._other
        ? PatientGenderKind._other
        : PatientGenderKind._unknown
    pat.deceasedBoolean = false
    pat.active = true

    const maritalCodeableList: React.SetStateAction<R4.ICodeableConcept[]> = []
    const divorceConcept: R4.ICodeableConcept = {}
    const marriedConcept: R4.ICodeableConcept = {}
    const unMarriedConcept: R4.ICodeableConcept = {}
    const widowedConcept: R4.ICodeableConcept = {}
    const maritalStatus: R4.ICodeableConcept = {}

    const maritalCodingList: React.SetStateAction<R4.ICoding[]> = []
    const divorceCoding: R4.ICoding = {
      system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
      code: 'D',
      display: 'Divorced',
    }
    const marriedCoding: R4.ICoding = {
      system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
      code: 'M',
      display: 'Married',
    }
    const unMarriedCoding: R4.ICoding = {
      system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
      code: 'U',
      display: 'unmarried',
    }
    const widowCoding: R4.ICoding = {
      system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
      code: 'W',
      display: 'Widowed',
    }
    maritalCodingList.push(divorceCoding)
    divorceConcept.coding = maritalCodingList

    maritalCodingList.push(marriedCoding)
    marriedConcept.coding = maritalCodingList

    maritalCodingList.push(unMarriedCoding)
    unMarriedConcept.coding = maritalCodingList

    maritalCodingList.push(widowCoding)
    widowedConcept.coding = maritalCodingList

    maritalCodeableList.push(divorceConcept)
    maritalCodeableList.push(marriedConcept)
    maritalCodeableList.push(unMarriedConcept)
    maritalCodeableList.push(widowedConcept)

    for (let i = 0; i < maritalCodeableList.length; i++) {
      if (maritalCodeableList[i]!.coding![i]?.code === formData.maritalStatus) {
        maritalStatus.coding = [maritalCodeableList[i]?.coding![i]]
        pat.maritalStatus = maritalStatus
      }
    }

    // const nationalityExtension: R4.IExtension = {}
    // const nationalityCode: R4.ICodeableConcept = {}
    // const indiaCoding: R4.ICoding = {
    //   system: 'http://hl7.org/fhir/StructureDefinition/patient-nationality',
    //   code: 'IN',
    //   display: 'INDIA'
    // }
    // const nationCodingList: React.SetStateAction<R4.ICoding[]> = []
    // nationCodingList.push(indiaCoding)
    // nationalityExtension.url =
    //   'http://hl7.org/fhir/StructureDefinition/patient-nationality'

    // nationalityExtension.valueCodeableConcept = nationCodingList[0]
    // pat.extension.push(nationalityExtension)

    return pat
  }

  return undefined
}

export function getFhirRelatedObjectFromForm(
  formData: any
): R4.IRelatedPerson | undefined {
  if (formData && formData.fathersName.length > 0) {
    const relatedPat: R4.IRelatedPerson = {
      resourceType: 'RelatedPerson',
      patient: {
        reference: 'Patient/example',
      },
    }

    const identifier: R4.IIdentifier = {}
    identifier.use = IdentifierUseKind._usual
    identifier.system = 'mrn-tenant1'
    identifier.value = `related${Math.random().toString(36).substring(2)}`
    if (relatedPat.identifier == null) {
      relatedPat.identifier = [identifier]
    } else {
      relatedPat.identifier.push(identifier)
    }

    const humanName: R4.IHumanName = {}
    const nameData: string[] = []
    if (formData.fathersName) {
      nameData.push(_.capitalize(formData.fathersName))
      humanName.given = [_.capitalize(formData.fathersName)]
    }
    humanName.use = HumanNameUseKind._official
    if (relatedPat.name == null) {
      relatedPat.name = [humanName]
    } else {
      relatedPat.name.push(humanName)
    }

    const relationShipCodeableList: React.SetStateAction<
      R4.ICodeableConcept[]
    > = []
    const relationShipCodeable: R4.ICodeableConcept = {}
    const relationShipCodingList: React.SetStateAction<R4.ICoding[]> = []
    const fatherRelation: R4.ICoding = {
      system: 'http://hl7.org/fhir/ValueSet/relatedperson-relationshiptype',
      code: 'FTH',
      display: 'father',
    }
    relationShipCodingList.push(fatherRelation)
    relationShipCodeable.coding = relationShipCodingList
    relationShipCodeableList.push(relationShipCodeable)
    relatedPat.relationship = relationShipCodeableList

    return relatedPat
  }

  return undefined
}

export function getFhirRelatedObjectHSForm(
  formData: any
): R4.IRelatedPerson | undefined {
  if (formData && formData.husbandName.length > 0) {
    const relatedPat: R4.IRelatedPerson = {
      resourceType: 'RelatedPerson',
      patient: {
        reference: 'Patient/example',
      },
    }

    const identifier: R4.IIdentifier = {}
    identifier.use = IdentifierUseKind._usual
    identifier.system = 'mrn-tenant1'
    identifier.value = `related${Math.random().toString(36).substring(2)}`
    if (relatedPat.identifier == null) {
      relatedPat.identifier = [identifier]
    } else {
      relatedPat.identifier.push(identifier)
    }

    const humanName: R4.IHumanName = {}
    const nameData: string[] = []
    if (formData.husbandName.length > 0) {
      nameData.push(_.capitalize(formData.husbandName))
      humanName.given = [_.capitalize(formData.husbandName)]
    }
    humanName.use = HumanNameUseKind._official
    if (relatedPat.name == null) {
      relatedPat.name = [humanName]
    } else {
      relatedPat.name.push(humanName)
    }

    const relationShipCodeableList: React.SetStateAction<
      R4.ICodeableConcept[]
    > = []
    const relationShipCodeable: R4.ICodeableConcept = {}
    const relationShipCodingList: React.SetStateAction<R4.ICoding[]> = []
    const fatherRelation: R4.ICoding = {
      system: 'http://hl7.org/fhir/ValueSet/relatedperson-relationshiptype',
      code: 'HUSB',
      display: 'husband',
    }
    relationShipCodingList.push(fatherRelation)
    relationShipCodeable.coding = relationShipCodingList
    relationShipCodeableList.push(relationShipCodeable)
    relatedPat.relationship = relationShipCodeableList

    return relatedPat
  }

  return undefined
}

export function getFhirScheduleObjectFromForm(
  formData: any
): R4.ISchedule | undefined {
  if (formData) {
    const schedule: R4.ISchedule = {
      resourceType: 'Schedule',
      actor: [],
    }
    if ('id' in formData) {
      schedule.id = formData.id
    }
    const identifier: R4.IIdentifier = {}
    identifier.use = IdentifierUseKind._usual
    identifier.system = 'mrn-tenant1'
    identifier.value = `Schedule${Math.random().toString(36).substring(2)}`
    if (schedule.identifier == null) {
      schedule.identifier = [identifier]
    } else {
      schedule.identifier.push(identifier)
    }
    const horizon: R4.IPeriod = {}
    horizon.start = moment(
      `${moment().startOf('month').format('YYYY-MM-DD')}T09:00:00Z`
    ).toISOString()
    horizon.end = moment(
      `${moment().endOf('month').format('YYYY-MM-DD')}T21:00:00Z`
    ).toISOString()
    schedule.planningHorizon = horizon
    return schedule
  }

  return undefined
}
export function getDate(formData: any): string[] {
  const dateArray: any = []
  const currentDate = moment().format('YYYY-MM-DD')
  for (let i = 0; i < formData.scheduleData.length; i++) {
    const date1 = moment(formData.scheduleData[i]).format('YYYY-MM-DD')
    const date2 = moment(formData.scheduleData[i + 1]).format('YYYY-MM-DD')
    let startDate = ''
    if (moment(date1).isAfter(formData.scheduleData[date2]) === false) {
      startDate = moment(formData.scheduleData[i]).format('D')
      for (let j = Number(startDate); j < moment().daysInMonth(); j += 7) {
        const date = moment(
          moment(formData.scheduleData[i]).format('YYYY-MM-') +
            j +
            moment(formData.scheduleData[i]).format(' HH:mm:sss')
        )
          .utc()
          .format()

        if (
          moment(
            moment(formData.scheduleData[i]).format(`YYYY-MM-${j}`)
          ).isBefore(currentDate) === true
        ) {
          logger.info('--dome--')
        } else {
          dateArray.push(date)
        }
      }
    }
    if (moment(date1).isAfter(formData.scheduleData[date2]) === true) {
      startDate = moment(formData.scheduleData[i]).format('D')
      for (let j = Number(startDate); j < moment().daysInMonth(); j += 7) {
        const date = moment(
          moment(formData.scheduleData[i]).format('YYYY-MM-') +
            j +
            moment(formData.scheduleData[i]).format(' HH:mm:sss')
        )
          .utc()
          .format()
        dateArray.push(date)
      }
    }
  }
  return dateArray
}

export function getFhirSlotObjectFrom(
  formData: any
): R4.IBundle_Entry[] | undefined {
  const bundleArray: any = []
  const dateArr: string[] = getDate(formData)
  for (let i = 0; i < dateArr.length; i++) {
    const iRequest: R4.IBundle_Request = {}
    iRequest.method = R4.Bundle_RequestMethodKind._post
    iRequest.url = 'Schedule'
    const slot: R4.ISlot = {
      resourceType: 'Slot',
      schedule: {
        reference: 'Schedule/',
      },
    }
    const identifier: R4.IIdentifier = {}
    identifier.use = IdentifierUseKind._usual
    identifier.system = 'mrn-tenant1'
    identifier.value = `Slot${Math.random().toString(36).substring(2)}`
    if (slot.identifier == null) {
      slot.identifier = [identifier]
    } else {
      slot.identifier.push(identifier)
    }
    const serviceCategoryList: React.SetStateAction<R4.ICodeableConcept[]> = []
    const serviceCodeable: R4.ICodeableConcept = {}
    const serviceCodingList: React.SetStateAction<R4.ICoding[]> = []
    const service1: R4.ICoding = {
      code: '17',
      display: 'General Practice',
    }
    serviceCodingList.push(service1)
    serviceCodeable.coding = serviceCodingList
    serviceCategoryList.push(serviceCodeable)
    slot.serviceCategory = serviceCategoryList

    const specialtyList: React.SetStateAction<R4.ICodeableConcept[]> = []
    const specialtyCodeable: R4.ICodeableConcept = {}
    const specialtyCodingList: React.SetStateAction<R4.ICoding[]> = []
    const spl1: R4.ICoding = {
      code: '408450004',
      display: 'Sleep studies',
    }
    const spl2: R4.ICoding = {
      code: '394579002',
      display: 'Cardiology',
    }
    const spl3: R4.ICoding = {
      code: '394802001',
      display: 'General Medicine',
    }
    specialtyCodingList.push(spl1)
    specialtyCodingList.push(spl2)
    specialtyCodingList.push(spl3)
    specialtyCodeable.coding = serviceCodingList
    specialtyList.push(specialtyCodeable)
    slot.specialty = specialtyList

    const appointmentTypeCodeable: R4.ICodeableConcept = {}
    const appointmentTypeCodingList: React.SetStateAction<R4.ICoding[]> = []
    const type: R4.ICoding = {
      code: 'FOLLOWUP',
      system: 'http://terminology.hl7.org/CodeSystem/v2-0276',
    }
    appointmentTypeCodingList.push(type)
    appointmentTypeCodeable.coding = appointmentTypeCodingList
    slot.appointmentType = appointmentTypeCodeable
    slot.status = SlotStatusKind._free
    slot.start = moment(dateArr[i]).toISOString()
    slot.end = moment(
      moment(dateArr[i]).add(1, 'hours').format('YYYY-MM-DDThh:mm:sss')
    ).toISOString()
    const bundleEntry: R4.IBundle_Entry = {
      fullUrl: `urn:uuid:schedule${i}`,
      request: iRequest,
      resource: slot,
    }
    bundleArray.push(bundleEntry)
  }
  return bundleArray
}

export function getFhirBundleObjectFrom(formData: any): string[] | undefined {
  if (formData) {
    return getDate(formData)
  }
  return undefined
}

export function getFhirPractitionerObjectFromForm(
  formData: any
): R4.IPractitioner | undefined {
  if (formData) {
    const pat: R4.IPractitioner = {
      resourceType: 'Practitioner',
    }
    if ('id' in formData) {
      pat.id = formData.id
    }

    const emailIdentifier: R4.IIdentifier = {}
    emailIdentifier.use = IdentifierUseKind._official
    emailIdentifier.system = 'mrn-tenant1'
    emailIdentifier.value = formData.email

    if (pat.identifier == null) {
      pat.identifier = [emailIdentifier]
    } else {
      pat.identifier.push(emailIdentifier)
    }

    const phoneIdentifier: R4.IIdentifier = {}
    phoneIdentifier.use = IdentifierUseKind._secondary
    phoneIdentifier.value = formData.phone
    if (pat.identifier == null) {
      pat.identifier = [phoneIdentifier]
    } else {
      pat.identifier.push(phoneIdentifier)
    }

    const humanName: R4.IHumanName = {}
    humanName.given = [_.capitalize(formData.name)]
    humanName.use = HumanNameUseKind._official
    if (pat.name == null) {
      pat.name = [humanName]
    } else {
      pat.name.push(humanName)
    }
    const phoneContact: R4.IContactPoint = {}
    phoneContact.use = ContactPointUseKind._mobile
    phoneContact.system = R4.ContactPointSystemKind._phone
    phoneContact.rank = 1
    phoneContact.value = formData.phone

    if (pat.telecom == null) {
      pat.telecom = [phoneContact]
    } else {
      pat.telecom.push(phoneContact)
    }

    const emailContact: R4.IContactPoint = {}
    emailContact.use = ContactPointUseKind._home
    emailContact.system = R4.ContactPointSystemKind._email
    emailContact.rank = 1
    emailContact.value = formData.email

    if (pat.telecom == null) {
      pat.telecom = [emailContact]
    } else {
      pat.telecom.push(emailContact)
    }
    return pat
  }

  return undefined
}

export function getFhirPractitionerRoleObjectFromForm(
  formData: any
): R4.IPractitionerRole | undefined {
  if (formData) {
    const patRole: R4.IPractitionerRole = {
      resourceType: 'PractitionerRole',
    }
    if ('id' in formData) {
      patRole.id = formData.id
    }

    const identifier: R4.IIdentifier = {}
    identifier.use = IdentifierUseKind._usual
    identifier.system = 'mrn-tenant1'
    identifier.value = `patRole${Math.random().toString(36).substring(2)}`
    if (patRole.identifier == null) {
      patRole.identifier = [identifier]
    } else {
      patRole.identifier.push(identifier)
    }

    const patRoleList: React.SetStateAction<R4.ICodeableConcept[]> = []
    const roleCodeable: R4.ICodeableConcept = {}
    const roleCodingList: React.SetStateAction<R4.ICoding[]> = []
    const role1: R4.ICoding = {
      code: formData.role,
      display: 'Doctor',
    }
    roleCodingList.push(role1)
    roleCodeable.coding = roleCodingList
    patRoleList.push(roleCodeable)
    patRole.code = patRoleList

    return patRole
  }

  return undefined
}

export function getFhirCodIngFromStringCode(
  codings: R4.ICoding[],
  strCode: string
): R4.ICoding | undefined {
  const res = codings.findIndex((e) => e.code === strCode)

  if (res > -1) {
    return codings[res]
  }
  return undefined
}

export function getPatientResourceFromQuick(
  firstName: string,
  middleName: string,
  lastName: string,
  phone: string
): R4.IPatient {
  const pat: R4.IPatient = {
    resourceType: 'Patient',
  }

  pat.extension = []

  const unitOrg = getCurrentUserUnitDetails()
  const unitExtension: R4.IExtension = {
    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/ManagingOrganizationUnit',
    valueReference: {
      id: unitOrg.id,
      reference: `Organization/${unitOrg.id}`,
      display: unitOrg.name,
    },
  }

  pat.extension.push(unitExtension)

  const mainOrganization: R4.IOrganization =
    getCurrentUserMainOrganizationDetails()

  pat.managingOrganization = {
    id: mainOrganization.id ?? '',
    display: mainOrganization.name,
    reference: `${mainOrganization.resourceType}/${mainOrganization.id}`,
  }

  const identifier: R4.IIdentifier = {}
  identifier.use = IdentifierUseKind._usual
  identifier.system = 'mrn-tenant1'
  identifier.value = `P${Math.random().toString(36).substring(2)}`
  if (pat.identifier == null) {
    pat.identifier = [identifier]
  } else {
    pat.identifier.push(identifier)
  }

  const humanName: R4.IHumanName = {}
  humanName.given = [_.capitalize(firstName)]
  if (middleName) {
    humanName.given.push(_.capitalize(middleName))
  }
  humanName.family = _.capitalize(lastName)
  humanName.use = HumanNameUseKind._official
  if (pat.name == null) {
    pat.name = [humanName]
  } else {
    pat.name.push(humanName)
  }

  //   if (patientProfile.middleName) {
  //     humanName.given.push(patientProfile.middleName)
  //   }
  //   const humanName: R4.IHumanName = {}
  //   humanName.given = [_.capitalize(firstName)]
  //   humanName.family = _.capitalize(lastName)
  //   humanName.use = HumanNameUseKind._official
  //   if (pat.name == null) {
  //     pat.name = [humanName]
  //   } else {
  //     pat.name.push(humanName)
  //   }

  const phoneContact: R4.IContactPoint = {}
  phoneContact.use = ContactPointUseKind._mobile
  phoneContact.system = R4.ContactPointSystemKind._phone
  phoneContact.rank = 1
  phoneContact.value = `${phone}`
  if (pat.telecom == null) {
    pat.telecom = [phoneContact]
  } else {
    pat.telecom.push(phoneContact)
  }

  pat.deceasedBoolean = false
  pat.active = true

  return pat
}

export function getPatientResourceAfterAdd(
  firstName: string,
  middleName: string,
  lastName: string,
  phone: string,
  patientId: string
): R4.IPatient {
  const pat: R4.IPatient = {
    resourceType: 'Patient',
    id: patientId,
  }

  pat.extension = []

  const unitOrg = getCurrentUserUnitDetails()
  const unitExtension: R4.IExtension = {
    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/ManagingOrganizationUnit',
    valueReference: {
      id: unitOrg.id,
      reference: `Organization/${unitOrg.id}`,
      display: unitOrg.name,
    },
  }

  pat.extension.push(unitExtension)

  const mainOrganization: R4.IOrganization =
    getCurrentUserMainOrganizationDetails()

  pat.managingOrganization = {
    id: mainOrganization.id ?? '',
    display: mainOrganization.name,
    reference: `${mainOrganization.resourceType}/${mainOrganization.id}`,
  }

  const identifier: R4.IIdentifier = {}
  identifier.use = IdentifierUseKind._usual
  identifier.system = 'mrn-tenant1'
  identifier.value = `P${Math.random().toString(36).substring(2)}`
  if (pat.identifier == null) {
    pat.identifier = [identifier]
  } else {
    pat.identifier.push(identifier)
  }

  const humanName: R4.IHumanName = {}
  humanName.given = [_.capitalize(firstName)]
  if (middleName) {
    humanName.given.push(_.capitalize(middleName))
  }
  humanName.family = _.capitalize(lastName)
  humanName.use = HumanNameUseKind._official
  if (pat.name == null) {
    pat.name = [humanName]
  } else {
    pat.name.push(humanName)
  }

  //   if (patientProfile.middleName) {
  //     humanName.given.push(patientProfile.middleName)
  //   }
  //   const humanName: R4.IHumanName = {}
  //   humanName.given = [_.capitalize(firstName)]
  //   humanName.family = _.capitalize(lastName)
  //   humanName.use = HumanNameUseKind._official
  //   if (pat.name == null) {
  //     pat.name = [humanName]
  //   } else {
  //     pat.name.push(humanName)
  //   }

  const phoneContact: R4.IContactPoint = {}
  phoneContact.use = ContactPointUseKind._mobile
  phoneContact.system = R4.ContactPointSystemKind._phone
  phoneContact.rank = 1
  phoneContact.value = `${phone}`
  if (pat.telecom == null) {
    pat.telecom = [phoneContact]
  } else {
    pat.telecom.push(phoneContact)
  }

  pat.deceasedBoolean = false
  pat.active = true

  return pat
}

export function getFhirPatientObjectForMinimal(
  formData: any,
  preAddedPatient: R4.IPatient,
  isFullDetailsEditRequired: boolean
): R4.IPatient | undefined {
  if (formData) {
    const pat: R4.IPatient = {
      ...preAddedPatient,
    }

    const patientData: R4.IPatient = {
      resourceType: 'Patient',
    }

    const profileTag: R4.ICoding = {
      system:
        'http://wellopathy.com/fhir/india/core/vs/profileCompletionIndicators',
      code: 'profile_details_completed',
    }
    const meta: R4.IMeta = {
      tag: [profileTag],
    }
    patientData.meta = meta
    patientData.id = pat.id

    const humanName: R4.IHumanName = {}
    humanName.given = [_.capitalize(formData.name.trim())]
    if (formData.middleName) {
      humanName.given.push(_.capitalize(formData.middleName.trim()))
    }
    humanName.family = _.capitalize(formData.lastName.trim())
    humanName.use = HumanNameUseKind._official
    if (patientData.name == null) {
      patientData.name = [humanName]
    } else {
      patientData.name.push(humanName)
    }
    const identifier: R4.IIdentifier = {}
    identifier.use = IdentifierUseKind._usual
    identifier.system = 'mrn-tenant1'
    identifier.value = pat.identifier ? pat.identifier[0].value : ''
    if (patientData.identifier == null) {
      patientData.identifier = [identifier]
    } else {
      patientData.identifier.push(identifier)
    }

    const latestIdentifier: string | undefined = getIdentifierValueBySystem(
      pat.identifier ?? [],
      'http://wellopathy.com/fhir/india/core/id/offset-phone'
    )
    if (latestIdentifier !== undefined) {
      if (latestIdentifier.length > 0) {
        const finalIdentifier: R4.IIdentifier = {}

        finalIdentifier.value = latestIdentifier
        finalIdentifier.system =
          'http://wellopathy.com/fhir/india/core/id/offset-phone'
        patientData.identifier.push(finalIdentifier)
      }
    }

    if (latestIdentifier === undefined || latestIdentifier.length === 0) {
      const phoneIdentifier: R4.IIdentifier = {}

      phoneIdentifier.value = `${formData.phone}`
      phoneIdentifier.system = 'phone'
      patientData.identifier.push(phoneIdentifier)
      if (formData.email.length > 0) {
        const emailIdentifier: R4.IIdentifier = {}
        emailIdentifier.value = `${formData.email}`
        emailIdentifier.system = 'email'
        patientData.identifier.push(emailIdentifier)
      }
    }
    if (formData.labReference) {
      if (formData.labReference.length > 0) {
        const labRefIdentifier: R4.IIdentifier = {}
        labRefIdentifier.value = `${formData.labReference}`
        labRefIdentifier.system = 'labReference'
        patientData.identifier.push(labRefIdentifier)
      }
    }

    const addProofIdentifir: R4.ICodeableConcept | undefined =
      getIdentifierValueBySystemTypeDAata(pat.identifier ?? [], 'address-proof')
    if (addProofIdentifir) {
      const addressIdentifier: R4.IIdentifier = {}
      addressIdentifier.type = {
        coding: addProofIdentifir.coding!,
      }
      addressIdentifier.use = IdentifierUseKind._usual
      addressIdentifier.system = 'address-proof'
      addressIdentifier.value = getIdentifierValueBySystemTypeDAataVal(
        pat.identifier ?? [],
        'address-proof'
      )
      if (patientData.identifier == null) {
        patientData.identifier = [addressIdentifier]
      } else {
        patientData.identifier.push(addressIdentifier)
      }
    } else if (formData.addressProofTypeCode && formData.addressProofId) {
      const addressIdentifier: R4.IIdentifier = {}
      addressIdentifier.type = {
        coding: [
          getFhirCodIngFromStringCode(
            formData.nationality !== 'IN'
              ? AddressProofTypeForForeign
              : AddressProofType,
            formData.nationality !== 'IN'
              ? 'passport'
              : formData.addressProofTypeCode
          )!,
        ],
      }
      addressIdentifier.use = IdentifierUseKind._usual
      addressIdentifier.system = 'address-proof'
      addressIdentifier.value = formData.addressProofId
      if (patientData.identifier == null) {
        patientData.identifier = [addressIdentifier]
      } else {
        patientData.identifier.push(addressIdentifier)
      }
    }
    const phoneContact: R4.IContactPoint = {}
    phoneContact.use = ContactPointUseKind._mobile
    phoneContact.system = R4.ContactPointSystemKind._phone
    phoneContact.rank = 1
    phoneContact.value = `+91${formData.phone}`
    if (patientData.telecom == null) {
      patientData.telecom = [phoneContact]
    } else {
      patientData.telecom.push(phoneContact)
    }
    if (formData.email && formData.email.length > 0) {
      const emailContact: R4.IContactPoint = {}
      emailContact.use = ContactPointUseKind._home
      emailContact.system = R4.ContactPointSystemKind._email
      emailContact.rank = 1
      emailContact.value = formData.email

      if (patientData.telecom == null) {
        patientData.telecom = [emailContact]
      } else {
        patientData.telecom.push(emailContact)
      }
    }
    patientData.deceasedBoolean = false
    patientData.active = true
    const dateRes: E.Either<Errors, string> = R4.RTTI_date.decode(formData.dob)
    if (dateRes._tag === 'Right') {
      patientData.birthDate = dateRes.right
    } else {
      throw Error('Invalid date format')
    }
    if (formData.imgName.length > 0) {
      const photo: R4.IAttachment = {}
      photo.data = formData.imgName
      photo.contentType = formData.profilePhotoMimeType
      if (patientData.photo == null) {
        patientData.photo = [photo]
      } else {
        patientData.photo.push(photo)
      }
    }

    const patAddress: R4.IAddress[] = []

    if (formData.address.length > 0) {
      const address: R4.IAddress = {}
      const add: string[] = []

      add.push(formData.address.trim())
      address.line = add
      address.city = formData.city
      if (formData.state && formData.state.length > 0) {
        address.state = formData.state
        address.country = 'India'
      } else {
        address.country = formData.country
      }
      address.use =
        formData.addressType === 'home'
          ? R4.AddressUseKind._home
          : R4.AddressUseKind._work
      address.type = R4.AddressTypeKind._both
      patAddress.push(address)
      patientData.address = patAddress
    }

    if (formData.nationality !== 'IN') {
      const address: R4.IAddress = {}
      const add: string[] = []
      if (formData.tempAddress && formData.tempAddress.length > 0) {
        add.push(formData.tempAddress.trim())
        address.line = add
        address.city = formData.tempCity
        address.state = formData.tempState
        address.country = formData.country
        address.use = R4.AddressUseKind._temp
        address.type = R4.AddressTypeKind._both
        patAddress.push(address)
        patientData.address = patAddress
      }
    }

    patientData.gender =
      formData.gender === PatientGenderKind._male
        ? PatientGenderKind._male
        : formData.gender === PatientGenderKind._female
        ? PatientGenderKind._female
        : formData.gender === PatientGenderKind._other
        ? PatientGenderKind._other
        : PatientGenderKind._unknown

    const maritalCodeableList: React.SetStateAction<R4.ICodeableConcept[]> = []
    const divorceConcept: R4.ICodeableConcept = {}
    const marriedConcept: R4.ICodeableConcept = {}
    const unMarriedConcept: R4.ICodeableConcept = {}
    const widowedConcept: R4.ICodeableConcept = {}
    const maritalStatus: R4.ICodeableConcept = {}

    const maritalCodingList: React.SetStateAction<R4.ICoding[]> = []
    const divorceCoding: R4.ICoding = {
      system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
      code: 'D',
      display: 'Divorced',
    }
    const marriedCoding: R4.ICoding = {
      system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
      code: 'M',
      display: 'Married',
    }
    const unMarriedCoding: R4.ICoding = {
      system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
      code: 'U',
      display: 'unmarried',
    }
    const widowCoding: R4.ICoding = {
      system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
      code: 'W',
      display: 'Widowed',
    }
    maritalCodingList.push(divorceCoding)
    divorceConcept.coding = maritalCodingList

    maritalCodingList.push(marriedCoding)
    marriedConcept.coding = maritalCodingList

    maritalCodingList.push(unMarriedCoding)
    unMarriedConcept.coding = maritalCodingList

    maritalCodingList.push(widowCoding)
    widowedConcept.coding = maritalCodingList

    maritalCodeableList.push(divorceConcept)
    maritalCodeableList.push(marriedConcept)
    maritalCodeableList.push(unMarriedConcept)
    maritalCodeableList.push(widowedConcept)

    for (let i = 0; i < maritalCodeableList.length; i++) {
      if (maritalCodeableList[i]!.coding![i]?.code === formData.maritalStatus) {
        maritalStatus.coding = [maritalCodeableList[i]?.coding![i]]
        patientData.maritalStatus = maritalStatus
      }
    }

    // const nationalityExtension: R4.IExtension = {}
    // const nationalityCode: R4.ICodeableConcept = {}
    // const indiaCoding: R4.ICoding = {
    //   system: 'http://hl7.org/fhir/StructureDefinition/patient-nationality',
    //   code: 'IN',
    //   display: 'INDIA'
    // }
    // const nationCodingList: React.SetStateAction<R4.ICoding[]> = []
    // nationCodingList.push(indiaCoding)
    // nationalityExtension.url =
    //   'http://hl7.org/fhir/StructureDefinition/patient-nationality'

    // nationalityExtension.valueCodeableConcept = nationCodingList[0]
    // pat.extension.push(nationalityExtension)

    return patientData
  }

  return undefined
}
