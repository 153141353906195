import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { getAccessToken } from 'utils/authHelpers'
import { logger } from 'utils/logger'

export class AuthApiClient {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_AUTH_URL,
    })
    this.axiosInstance.interceptors.request.use((param) => {
      if (getAccessToken() != null) {
        param.headers = {
          Authorization: `Bearer ${getAccessToken()}`,
        }
      }

      return {
        ...param,
      }
    })
    this.axiosInstance.interceptors.response.use((param) => ({
      ...param,
    }))
  }

  public async doPostFormRequest(path: string, body: any) {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        path,
        body,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )

      return response.data
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetRequest(path: string) {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        baseURL: process.env.REACT_APP_AUTH_URL,
      })

      return response.data
    } catch (error) {
      logger.error(error)
      throw error
    }
  }
}
