import { R4 } from '@ahryman40k/ts-fhir-types'
import TimeAgo from 'javascript-time-ago'
import moment from 'moment'

export function getOnlyDate(inputDate: Date): Date {
  return new Date(
    inputDate.getFullYear(),
    inputDate.getMonth(),
    inputDate.getDate(),
    0,
    0,
    0,
    0
  )
}

export function getTomorrowDate(): Date {
  const tomorrow: Date = moment(new Date()).startOf('day').toDate()

  tomorrow.setDate(tomorrow.getDate() + 1)
  return tomorrow
}

export function getAddedDate(addNum: number): Date {
  const tomorrow: Date = moment(new Date()).startOf('day').toDate()

  return moment(tomorrow).startOf('day').add(addNum, 'days').toDate()

  /* tomorrow.setDate(tomorrow.getDate() + addNum)
  return tomorrow */
}

export function getAddedDateWithCustomStartDate(
  startDate: Date,
  addNum: number
): Date {
  const tomorrow: Date = moment(new Date()).startOf('day').toDate()

  return moment(tomorrow).startOf('day').add(addNum, 'days').toDate()

  /* tomorrow.setDate(tomorrow.getDate() + addNum)
    return tomorrow */
}

export function getLocalDateTimeFromISOString(dateString: string): Date {
  return moment(dateString).toDate()
}

export function getHumanReadableDate(dateString: string): string {
  return moment(dateString).format('MMM yyyy')
}

export function getCompleteDateString(dateString: string): string {
  let completeString = ''
  if (
    moment
      .duration(moment(dateString, 'YYYY-MM-DD').diff(moment().startOf('day')))
      .asDays() === 0
  ) {
    completeString = `Today, ${moment(dateString).format('dddd, MMM D yyyy')}`
  } else if (
    moment
      .duration(moment(dateString, 'YYYY-MM-DD').diff(moment().startOf('day')))
      .asDays() === 1
  ) {
    completeString = `Tomorrow, ${moment(dateString).format(
      'dddd, MMM D yyyy'
    )}`
  } else if (
    moment
      .duration(moment(dateString, 'YYYY-MM-DD').diff(moment().startOf('day')))
      .asDays() === -1
  ) {
    completeString = `Yesterday, ${moment(dateString).format(
      'dddd, MMM D yyyy'
    )}`
  } else {
    completeString = moment(dateString).format('dddd, MMM D yyyy')
  }
  return completeString
}

export function getCompleteDateStringWithOutDay(dateString: string): string {
  let completeString = ''
  if (
    moment
      .duration(moment(dateString).startOf('day').diff(moment().startOf('day')))
      .asDays() === 0
  ) {
    completeString = `Today, ${moment(dateString).format('D MMM, dddd ')}`
  } else if (
    moment
      .duration(moment(dateString).diff(moment().startOf('day')))
      .asDays() === 1
  ) {
    completeString = `Tomorrow, ${moment(dateString).format('D MMM, dddd ')}`
  } else if (
    moment
      .duration(moment(dateString).diff(moment().startOf('day')))
      .asDays() === -1
  ) {
    completeString = `Yesterday, ${moment(dateString).format('D MMM, dddd ')}`
  } else {
    completeString = moment(dateString).format('D MMM, dddd ')
  }
  return completeString
}

export function getCompleteDateStringForLabReferral(
  dateString: string
): string {
  return moment(dateString).format('D MMM, dddd ')
}

export function getCompleteDateStringForOrder(dateString: string): string {
  return moment(dateString).format('D MMM, yyyy hh:mm A')
}

export function getCompleteDateStringForOrderLandingPage(
  dateString: string
): string {
  return moment(dateString).format('D MMM, yyyy')
}

export function getDateTime(date?: string): Date {
  return moment(date).toDate()
}

export function getDurationString(start: string, end: string): string {
  return `${moment(end).diff(start, 'minutes')} m`
}

export function getYearsAgo(start: string): string {
  return `${moment(moment()).diff(start, 'years')} years`
}

export function getTimeAgo(start: string): string {
  const timeAgo = new TimeAgo('en-US')

  const time = timeAgo.format(moment(start).toDate(), 'mini').toString()
  return time
  // return moment(moment()).diff(start, "years") + " years";
}

export function getAgeOf(age?: R4.IAge): string {
  if (age) {
    const val: string = getDataInString(age.value, age.code)
    return val
  }
  return ''
  // return moment(moment()).diff(start, "years") + " years";
}
export function getAgeCodeData(age?: R4.IAge): string {
  if (age) {
    const val: string = getDataInCode(age.code)
    return val
  }
  return ''
  // return moment(moment()).diff(start, "years") + " years";
}

export function getAgeCode(age?: R4.IAge): string {
  if (age) {
    const val: string = getDataInString(age.value, age.code)
    return val
  }
  return ''
  // return moment(moment()).diff(start, "years") + " years";
}

export function getDataInString(val?: number, code?: string): string {
  if (val && code) {
    if (val > 1 && code === 'd') {
      return `${val} Days`
    }
    if (val === 1 && code === 'd') {
      return `${val} Day`
    }
    if (val === 1 && code === 'a') {
      return `${val} Year`
    }
    if (val > 1 && code === 'a') {
      return `${val} Years`
    }
    if (val === 1 && code === 'mo') {
      return `${val} Month`
    }
    if (val > 1 && code === 'mo') {
      return `${val} Months`
    }
    if (val === 1 && code === 'wk') {
      return `${val} Week`
    }
    if (val > 1 && code === 'wk') {
      return `${val} Weeks`
    }
  }

  return ''
}

export function getDataInCode(code?: string): string {
  if (code) {
    return code
  }

  return ''
}

export function getStartAndEndTimeInString(start: string, end: string): string {
  return `${moment(start).format('hh:mm A')}-${moment(end).format('hh:mm A')}`
}
export function getStartAndEndTimeInStringWithDate(
  start: string,
  end: string
): string {
  return `  ${moment(start).format('DD MMM dddd hh:mm A')}-${moment(end).format(
    'hh:mm A'
  )}`
}

export function getStartAndEndTimeWithDateInString(
  start: string,
  end: string
): string {
  return `${moment(start).format('hh:mm A')}-${moment(end).format(
    'hh:mm A'
  )} On ${moment(start).format('MMM D, yyyy')}`
}

export function getStartAndEndTimeWithDateInStringForOrder(
  start: string
): string {
  return `${moment(start).format('hh:mm A')} On ${moment(start).format(
    'MMM D, yyyy'
  )}`
}

export function getShortTimeWithDate(time: string): string {
  const data = `${moment(time).format('hh:mm A')} On ${moment(time).format(
    'MMM D, yyyy'
  )}`
  if (data === 'Invalid Date') return ''
  return data
}

export function getShortTimeWithDateForMedicine(time: string): string {
  const data = `${moment(time).format('hh:mm A')} on ${moment(time).format(
    'MMM D, yyyy'
  )}`
  if (data === 'Invalid Date') return ''
  return data
}

export function getDateForOrder(time: string): string {
  return ` ${moment(time).format('MMM D, yyyy')}`
}

export function getDateForOrderForFollowUp(time: string): string {
  return ` ${moment(time).format('MMM D dddd, yyyy')}`
}

export function getStartAndEndTimeInStringWithAM(
  start: string,
  end: string
): string {
  return `${moment(start).format('hh:mm A')}-${moment(end).format('hh:mm A')}`
}

export function geTimeInString(start: string): string {
  return `${moment(start).format('hh:mm A')}`
}

export function geTimeInStringWithDate(start: string): string {
  return `${moment(start).format('DD MMM dddd hh:mm A')}`
}

export function getIpdInTImeWithDate(start: string): string {
  return `${moment(start).format('DD-MM-YYYY hh:mm A')}`
}

export function getIpdInTImeWithDateForReport(start: string): string {
  return `${moment(start).format('DD-MM-YYYY')}`
}

export function isToday(date: Date): boolean {
  const currentDate = new Date()
  currentDate.setHours(0)
  currentDate.setMinutes(0)
  currentDate.setSeconds(0)
  currentDate.setMilliseconds(0)
  const daysDiff: number = moment(date).diff(moment(currentDate), 'day')
  if (daysDiff === 0) {
    return true
  }
  return false
}

export function isSameTime(first: string, next: string): boolean {
  return moment(first).diff(next, 'minutes') === 0
}
export function getCompleteDateInProfileDetailView(start: Date): string {
  let completeString = ''
  completeString = `${moment(start).format('DD MMM, ddd')}`

  return completeString
}

export function getCompleteTimeInProfileDetailView(
  start: Date,
  end?: Date
): string {
  let completeString = ''
  completeString = `${moment(start).format('h:mm a')}`

  if (end) {
    completeString += ` - ${moment(end).format('h:mm a')}`
  }

  return completeString
}

export function getOnlyDateInString(inputDate: Date): string {
  return moment(
    new Date(
      inputDate.getFullYear(),
      inputDate.getMonth(),
      inputDate.getDate(),
      0,
      0,
      0,
      0
    )
  ).format('D MMM, dddd ')
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
