import {
  Avatar,
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import moment from 'moment'
import React from 'react'
import {
  getLastNameOfPatientApp,
  getMiddleNameOfPatient,
  getNameFromHumanName,
  getNameOfPatientForApp,
  getProfilePicPatient,
  getSpecializationsList,
  getStringValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'

interface AppointmentTileProps {
  appointmentDetail: FhirAppointmentDetail
  diff: number
}

export const AppointmentWeekView: React.FC<AppointmentTileProps> = ({
  appointmentDetail,
  diff,
}: AppointmentTileProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const primColor: string = useTheme().palette.primary.main
  const [hover, SetHover] = React.useState(false)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  //   const diff = moment(appointmentDetail.end).diff(
  //     appointmentDetail.start,
  //     'minutes'
  //   )

  const handleClose = () => {
    setAnchorEl(null)
    SetHover(false)
  }
  const open = Boolean(hover)
  const id = open ? 'mouse-over-popover' : undefined

  return (
    <Box display='flex' flexDirection='row'>
      {diff === 10 && (
        <Box
          border={0}
          style={{
            backgroundColor: getStringValueExtensionsOfUrl(
              appointmentDetail.practitionerDetail.practitioner.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/AssignedColor'
            ),
            minHeight: 20,
            maxHeight: 20,
            width: 6,
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
          }}
        >
          <Paper
            id={`app_view_calender_id_${appointmentDetail.appointment.id}`}
          />
        </Box>
      )}

      {diff > 10 && (
        <Box
          border={0}
          style={{
            backgroundColor: getStringValueExtensionsOfUrl(
              appointmentDetail.practitionerDetail.practitioner.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/AssignedColor'
            ),
            minHeight: 40,
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
            width: 6,
          }}
        >
          <Paper
            id={`app_view_calender_id_${appointmentDetail.appointment.id}`}
          />
        </Box>
      )}

      <Box
        style={{
          borderRadius: 0,
          backgroundColor: '#4543D9',
          opacity: '0.7',
          backdropFilter: 'blur(8px)',
        }}
      >
        <Box id={`app_view_calender_id_${appointmentDetail.appointment.id}`}>
          <Box display='flex' p={0.5}>
            <Avatar
              src={getProfilePicPatient(appointmentDetail.patient)}
              style={{ height: 20, width: 20 }}
            />
            {diff <= 20 && (
              <Box flexGrow={1} paddingX={1} height={20} width={127}>
                <Grid container direction='column'>
                  <Grid item>
                    <Typography variant='overline' style={{ color: 'white' }}>
                      {`${getNameOfPatientForApp(appointmentDetail.patient)} ${
                        getMiddleNameOfPatient(appointmentDetail.patient) ?? ''
                      } ${getLastNameOfPatientApp(
                        appointmentDetail.patient
                      )}` ?? ''}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant='overline'
                      style={{
                        color: 'white',
                        opacity: '0.7',
                        mixBlendMode: 'normal',
                      }}
                    >
                      {`${getNameFromHumanName(
                        appointmentDetail.practitionerDetail.practitioner
                          .name ?? []
                      )} ....`}
                    </Typography>
                  </Grid>
                  {/* <Grid item>
                    <Typography
                      variant='overline'
                      style={{
                        color: 'white',
                        opacity: '0.5',
                        mixBlendMode: 'normal',
                      }}
                    >
                      {`${moment(appointmentDetail.start).format(
                        'hh:mm A'
                      )} -${moment(appointmentDetail.end).format(' hh:mm A')}`}
                    </Typography>
                  </Grid>  */}
                </Grid>
              </Box>
            )}
            {diff > 20 && (
              <Box flexGrow={1} paddingX={1} minHeight={40}>
                <Grid container direction='column'>
                  <Grid item>
                    <Typography variant='overline' style={{ color: 'white' }}>
                      {`${getNameOfPatientForApp(
                        appointmentDetail.patient
                      )} ${getLastNameOfPatientApp(
                        appointmentDetail.patient
                      )}` ?? ''}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant='overline'
                      style={{
                        color: 'white',
                        opacity: '0.7',
                        mixBlendMode: 'normal',
                      }}
                    >
                      {`${getNameFromHumanName(
                        appointmentDetail.practitionerDetail.practitioner
                          .name ?? []
                      )} \u25CF ${getSpecializationsList(
                        appointmentDetail.practitionerDetail.practitionerRole
                          .specialty ?? []
                      )} ....`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant='overline'
                      style={{
                        color: 'white',
                        opacity: '0.5',
                        mixBlendMode: 'normal',
                      }}
                    >
                      {`${moment(appointmentDetail.start).format(
                        'hh:mm A'
                      )} -${moment(appointmentDetail.end).format(' hh:mm A')}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant='overline'
                      style={{
                        color: 'white',
                        opacity: '0.5',
                        mixBlendMode: 'normal',
                      }}
                    >
                      {appointmentDetail.appointment.comment ?? ''}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
