import { R4 } from '@ahryman40k/ts-fhir-types'

export const ExcretionSystemConditionCodes: R4.ICoding[] = [
  {
    system: 'http://snomed.info/sct',
    code: '95570007',
    display: 'Kidney stones',
  },
  {
    system: 'http://snomed.info/sct',
    code: '45816000',
    display: 'Pyelonephritis',
  },
  {
    system: 'http://snomed.info/sct',
    code: '733137002',
    display: 'Acture Kidney Failure Stage 1',
  },
  {
    system: 'http://snomed.info/sct',
    code: '733138007',
    display: 'Acture Kidney Failure Stage 2',
  },
  {
    system: 'http://snomed.info/sct',
    code: '733139004',
    display: 'Acture Kidney Failure Stage 3',
  },
  {
    system: 'http://snomed.info/sct',
    code: '36171008',
    display: 'Glomerulonephritis',
  },
  {
    system: 'http://snomed.info/sct',
    code: '44730006',
    display: 'Uremia',
  },
  {
    system: 'http://snomed.info/sct',
    code: '38822007',
    display: 'Cystitis',
  },
  {
    system: 'http://snomed.info/sct',
    code: '15771004',
    display: 'Diabetes Insipidus',
  },
  {
    system: 'http://snomed.info/sct',
    code: '45111007',
    display: 'Normal excretory function',
  },
  {
    system: 'http://snomed.info/sct',
    code: '5033003',
    display: 'Absent excretory function',
  },
  {
    system: 'http://snomed.info/sct',
    code: '70900007',
    display: 'Increased excretory function',
  },
  {
    system: 'http://snomed.info/sct',
    code: '25299008',
    display: 'Abnormal excretory function',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-excretory-system-finding-cs',
    code: 'other-excretory-system-finding',
    display: 'Other',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
