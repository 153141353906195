import { R4 } from '@ahryman40k/ts-fhir-types'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { FhirSlotOnTime } from 'models/fhirSlotListOnTime'
import moment from 'moment'
import {
  getCurrentUserUnitDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import { getDateTime, isSameTime } from 'utils/dateUtil'
import { logger } from 'utils/logger'

export function getSlotsFromSlotsRelations(
  responseSlots: R4.IBundle,
  selectedAppointmentType: string
): FhirSlotDetail[] {
  logger.info(
    '-----------------------Inside Slots Distillation --------------------------'
  )

  logger.info(responseSlots)
  logger.info('responseSlots')
  const convertedSlots: FhirSlotDetail[] = []
  const slots: any = {}
  const schedules: any = {}
  const practitioners: any = {}
  const practitionerRoles: any = {}
  const healthService: any = {}
  const chargeItem: any = {}
  if (responseSlots.entry) {
    if (responseSlots.entry.length > 0) {
      const entries: R4.IBundle_Entry[] = responseSlots.entry
      entries.forEach((value) => {
        if (value.resource) {
          if (value.resource.id) {
            switch (value.resource.resourceType) {
              case 'Slot':
                slots[value.resource.id] = value.resource as R4.ISlot
                break
              case 'Schedule':
                schedules[value.resource.id] = value.resource as R4.ISchedule
                break
              case 'Practitioner':
                practitioners[value.resource.id] =
                  value.resource as R4.IPractitioner
                break
              case 'PractitionerRole':
                practitionerRoles[value.resource.id] =
                  value.resource as R4.IPractitionerRole
                break
              case 'HealthcareService':
                healthService[value.resource.id] =
                  value.resource as R4.IHealthcareService
                break
              case 'ChargeItemDefinition':
                chargeItem[value.resource.id] =
                  value.resource as R4.IChargeItemDefinition
                break
              default:
                break
            }
          }
        }
      })

      logger.info(
        '-----------------------Inside Slots Distillation Catagorised--------------------------'
      )
      logger.info(slots)
      logger.info(schedules)

      logger.info(practitioners)
      logger.info(practitionerRoles)

      for (const key in slots) {
        if (key) {
          let chargeItemCode: string = ''
          const currentSlot: R4.ISlot = slots[key] as R4.ISlot
          const scheduleId: string | undefined =
            currentSlot.schedule?.reference?.split('/')[1]

          const schedule: R4.ISchedule | undefined = schedules[scheduleId ?? '']
          const practitionerId: string | undefined = schedule?.actor
            ?.find((val) => val.reference?.includes('Practitioner/'))
            ?.reference?.split('/')[1]
          const practitionerRoleId: string | undefined = schedule?.actor
            ?.find((val) => val.reference?.includes('PractitionerRole/'))
            ?.reference?.split('/')[1]

          const healthServiceId: string | undefined = schedule?.actor
            ?.find((val) => val.reference?.includes('HealthcareService/'))
            ?.reference?.split('/')[1]
          if (healthServiceId) {
            const healthcareService: R4.IHealthcareService =
              healthService[healthServiceId ?? '']
            if (healthcareService.extension) {
              let healthExtension = ''
              if (selectedAppointmentType === '556')
                healthExtension =
                  healthcareService.extension[0].valueReference?.reference?.split(
                    '/'
                  )[1] ?? ''
              else if (selectedAppointmentType === 'video-counselling')
                healthExtension =
                  healthcareService.extension[1].valueReference?.reference?.split(
                    '/'
                  )[1] ?? ''
              else
                healthExtension =
                  healthcareService.extension[2].valueReference?.reference?.split(
                    '/'
                  )[1] ?? ''

              chargeItemCode = healthExtension
            }
          }

          const practRole: R4.IPractitionerRole =
            practitionerRoles[practitionerRoleId ?? '']
          const practRoleOrgId = practRole.organization?.id ?? ''
          const unitDetails = getCurrentUserUnitDetails()
          if (practRoleOrgId === unitDetails.id) {
            convertedSlots.push({
              slot: slots[key],
              schedule: schedules[slots[key].schedule?.id ?? ''],
              practitioner: practitioners[practitionerId ?? ''],
              practitionerRole: practitionerRoles[practitionerRoleId ?? ''],
              healthCareService: healthService[healthServiceId ?? ''],
              chargeItemDefiniton: chargeItem[chargeItemCode],
            })
          }
        }
      }
    }
  }
  logger.info(
    '-----------------------Inside Slots Distillation Unsorted--------------------------'
  )
  logger.info(convertedSlots)
  convertedSlots.sort(
    (a: FhirSlotDetail, b: FhirSlotDetail) =>
      getDateTime(a.slot.start).getTime() - getDateTime(b.slot.start).getTime()
  )
  logger.info(
    '-----------------------Inside Slots Distillation Sorted--------------------------'
  )
  logger.info(convertedSlots)
  return convertedSlots
}

export function getSlotsFromSlotsRelationsForAgent(
  responseSlots: R4.IBundle
): FhirSlotDetail[] {
  logger.info(
    '-----------------------Inside Slots Distillation --------------------------'
  )
  logger.info(responseSlots)
  logger.info('responseSlots')
  const convertedSlots: FhirSlotDetail[] = []

  const slots: any = {}
  const schedules: any = {}
  const practitioners: any = {}
  const practitionerRoles: any = {}
  const healthService: any = {}
  if (responseSlots.entry) {
    if (responseSlots.entry.length > 0) {
      const entries: R4.IBundle_Entry[] = responseSlots.entry
      entries.forEach((value) => {
        if (value.resource) {
          if (value.resource.id) {
            switch (value.resource.resourceType) {
              case 'Slot':
                slots[value.resource.id] = value.resource as R4.ISlot
                break
              case 'Schedule':
                schedules[value.resource.id] = value.resource as R4.ISchedule
                break
              case 'Practitioner':
                practitioners[value.resource.id] =
                  value.resource as R4.IPractitioner
                break
              case 'PractitionerRole':
                practitionerRoles[value.resource.id] =
                  value.resource as R4.IPractitionerRole
                break
              case 'HealthcareService':
                healthService[value.resource.id] =
                  value.resource as R4.IHealthcareService
                break
              default:
                break
            }
          }
        }
      })

      logger.info(
        '-----------------------Inside Slots Distillation Catagorised--------------------------'
      )
      logger.info(slots)
      logger.info(schedules)

      logger.info(practitioners)
      logger.info(practitionerRoles)

      for (const key in slots) {
        if (key) {
          const currentSlot: R4.ISlot = slots[key] as R4.ISlot
          const scheduleId: string | undefined =
            currentSlot.schedule?.reference?.split('/')[1]

          const schedule: R4.ISchedule | undefined = schedules[scheduleId ?? '']
          const practitionerId: string | undefined = schedule?.actor
            ?.find((val) => val.reference?.includes('Practitioner/'))
            ?.reference?.split('/')[1]
          const practitionerRoleId: string | undefined = schedule?.actor
            ?.find((val) => val.reference?.includes('PractitionerRole/'))
            ?.reference?.split('/')[1]

          const healthServiceId: string | undefined = schedule?.actor
            ?.find((val) => val.reference?.includes('HealthcareService/'))
            ?.reference?.split('/')[1]
          const pract: R4.IPractitionerRole =
            practitionerRoles[practitionerRoleId ?? '']
          const unitDetails = getCurrentUserUnitReference()!
          if (pract.organization) {
            if (pract.organization.id === unitDetails.id) {
              const slotData: R4.ISlot = slots[key]
              convertedSlots.push({
                slot: slots[key],
                schedule: schedules[slots[key].schedule?.id ?? ''],
                practitioner: practitioners[practitionerId ?? ''],
                practitionerRole: practitionerRoles[practitionerRoleId ?? ''],
                healthCareService: healthService[healthServiceId ?? ''],
              })
            }
          }
        }
      }
    }
  }
  logger.info(
    '-----------------------Inside Slots Distillation Unsorted--------------------------'
  )
  logger.info(convertedSlots)
  convertedSlots.sort(
    (a: FhirSlotDetail, b: FhirSlotDetail) =>
      getDateTime(a.slot.start).getTime() - getDateTime(b.slot.start).getTime()
  )
  logger.info(
    '-----------------------Inside Slots Distillation Sorted--------------------------'
  )
  logger.info(convertedSlots)
  return convertedSlots
}

export function getSlotsBasedonTimesFromSlotsRelationsForAgent(
  responseSlots: FhirSlotDetail[]
): FhirSlotOnTime[] {
  const convertedSlots: FhirSlotOnTime[] = []
  responseSlots.forEach((item) => {
    const index = convertedSlots.findIndex((slot) =>
      isSameTime(slot.start, item.slot.start!)
    )
    if (index > -1) {
      if (convertedSlots[index].slots === undefined) {
        convertedSlots[index].slots = []
      }

      convertedSlots[index].slots.push(item)
    } else {
      convertedSlots.push({
        end: item.slot.end!,
        start: item.slot.start!,
        slots: [item],
      })
    }
  })

  logger.info(
    '-----------------------Inside Slots Distillation --------------------------'
  )

  logger.info(
    '-----------------------Inside Slots TIme base Distillation Sorted--------------------------'
  )
  logger.info(convertedSlots)
  return convertedSlots
}

export function getPriceComponentFromChangeItem(
  chargeItem: R4.IChargeItemDefinition,
  type?: String
): R4.IChargeItemDefinition_PriceComponent | undefined {
  if (chargeItem.propertyGroup && chargeItem.propertyGroup.length > 0) {
    let priceComponent: R4.IChargeItemDefinition_PriceComponent | undefined =
      chargeItem.propertyGroup[0].priceComponent?.[0]
    if (type) {
      chargeItem.propertyGroup.map((e) => {
        if (e.priceComponent && e.priceComponent) {
          const p: R4.IChargeItemDefinition_PriceComponent | undefined =
            e.priceComponent.find((price) => price.type === type)
          if (p) {
            priceComponent = p
          }
        }
      })
    }

    if (priceComponent) return priceComponent
  }

  return undefined
}

export function getSlotAmountForAppointment(
  chargeDef?: R4.IChargeItemDefinition
): number {
  let total: number = 0
  if (chargeDef) {
    const priceComponent: R4.IChargeItemDefinition_PriceComponent | undefined =
      getPriceComponentFromChangeItem(chargeDef)
    if (
      priceComponent &&
      priceComponent.amount &&
      priceComponent.amount.value
    ) {
      total += priceComponent.amount.value
    }
  }

  return total
}

export function getDateDiff(
  appointmentDate?: string,
  slotDate?: string
): number {
  let total: number = 0
  if (appointmentDate && slotDate) {
    const date1 = moment(moment(appointmentDate).format('YYYY-MM-DD'))
    const date2 = moment(moment(slotDate).format('YYYY-MM-DD'))
    total = date2.diff(date1, 'days')
    return total
  }

  return total
}
