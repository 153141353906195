import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Alert, Autocomplete } from '@material-ui/lab'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addReferralDetails,
  resetReferralAdd,
} from 'redux/administration/referralSetup/referralInvite/addLabReferralSlice'
import { searchUnits } from 'redux/administration/referralSetup/unitSearch/unitSearchSlice'
import { RootState } from 'redux/rootReducer'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'

interface Props {
  open: boolean
  onBackClick: () => void
  onContinueClick: () => void
}
export const AddReferralsToForm: React.FC<Props> = ({
  open,
  onBackClick: onClose,
  onContinueClick,
}) => {
  const addReferralSlice = useSelector(
    (state: RootState) => state.addLabReferralSlice
  )

  const unitSearchSlice = useSelector(
    (state: RootState) => state.unitSearchSlice
  )

  const dispatch = useDispatch()

  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['en', 'labelCommon'])
  const [org, setOrg] = React.useState<R4.IOrganization>()

  function resetDetails() {
    setOrg(undefined)
  }

  function handleSubmit() {
    dispatch(addReferralDetails(org!))
  }

  useEffect(() => {
    dispatch(searchUnits())

    if (unitSearchSlice.resultsAvailable && unitSearchSlice.unitsList) {
      setOrg(unitSearchSlice.unitsList[0])
    }
    if (addReferralSlice.additionSuccessful) {
      resetDetails()
      dispatch(resetReferralAdd())
      onClose()
    }
    return () => {}
  }, [addReferralSlice.additionSuccessful])

  useEffect(() => {
    if (unitSearchSlice.resultsAvailable && unitSearchSlice.unitsList) {
      setOrg(unitSearchSlice.unitsList[0])
    }

    return () => {}
  }, [unitSearchSlice.resultsAvailable, unitSearchSlice.unitsList])

  return (
    <Dialog
      open={open}
      onClose={() => {
        dispatch(resetReferralAdd())
        resetDetails()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Invite Lab</DialogTitle>
      <DialogContent dividers={true}>
        <Box display='flex' flexDirection='column' width='100%'>
          {addReferralSlice.error && (
            <Box display='flex'>
              <Alert severity='error'>{addReferralSlice.errorMessage}</Alert>
            </Box>
          )}
          {addReferralSlice.additionSuccessful && (
            <Box display='flex'>
              <Alert severity='success'>'Invite sent successfully'</Alert>
            </Box>
          )}
          <Box px={2}>
            {unitSearchSlice.searching && (
              <CircularProgress size={18} color='inherit' />
            )}
          </Box>
          <Box px={2}>
            {unitSearchSlice.noResultsAvailable && (
              <Typography variant='subtitle1' color='initial' align='center'>
                No lab available
              </Typography>
            )}
          </Box>
          <Box display='flex' flexDirection='column' width='100%'>
            <Box display='flex' flexDirection='column' width='100%'>
              <Box display='flex' flexDirection='row' width='100%'>
                <Box display='flex' flexDirection='column' width='100%'>
                  {unitSearchSlice.resultsAvailable &&
                    unitSearchSlice.unitsList && (
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel title='Select Lab' />
                        <Autocomplete
                          style={{
                            borderRadius: '4px',
                          }}
                          fullWidth
                          size='small'
                          id='free-solo-2-demo'
                          disableClearable
                          getOptionSelected={(option, valueData) =>
                            option.name === valueData.name
                          }
                          getOptionLabel={(option: R4.IOrganization) =>
                            option.name ?? ''
                          }
                          options={unitSearchSlice.unitsList ?? []}
                          defaultValue={
                            unitSearchSlice.unitsList &&
                            unitSearchSlice.unitsList.length > 0
                              ? unitSearchSlice.unitsList[0]
                              : undefined
                          }
                          onChange={(e, changedValue, reason) => {
                            setOrg(changedValue)
                          }}
                          autoComplete
                          ChipProps={{
                            deleteIcon: (
                              <ClearOutlined
                                style={{
                                  height: '15px',
                                  color: 'white',
                                }}
                              />
                            ),
                            style: {
                              backgroundColor: kPrimaryLight,
                              borderRadius: '4px',
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              variant='outlined'
                              size='small'
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }}
                            />
                          )}
                        />
                      </Box>
                    )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {addReferralSlice.adding && (
          <CircularProgress color='inherit' size={20} />
        )}
        <Box paddingRight={1.5}>
          <Button
            onClick={() => {
              resetDetails()
              onClose()
            }}
            variant='outlined'
            disabled={addReferralSlice.adding}
            disableElevation
          >
            {t('labelCommon:back')}
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={org === undefined || addReferralSlice.adding}
            onClick={() => {
              handleSubmit()
            }}
          >
            Invite
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
