import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { PartnerLabTest } from 'models/partnerLabTestPrice'
import { Test } from 'models/Test'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetPackageState,
  updateCatalog,
} from 'redux/lab/Test/addTestCatalougeSlice'
import { RootState } from 'redux/rootReducer'
import { LabCodeValueSet } from 'utils/constants/lab_test'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  displayData,
  getCatalogBundleUpdate,
} from 'utils/labHelpers/uploadHelper'
import { logger } from 'utils/logger'
import { WelloTextField } from 'wello-web-components'

interface Props {
  open: boolean
  onClose: () => void
  onDiscard: () => void
  locationId: string
  partnerLabTest?: PartnerLabTest
}

export const EditTest: React.FC<Props> = ({
  open,
  onClose,
  onDiscard,
  locationId,
  partnerLabTest,
}) => {
  const { t } = useTranslation()

  const [hasErrors, setHasErrors] = React.useState<boolean>(false)
  const dispatch = useDispatch()
  const [showDisplay, setShowDisplay] = React.useState<boolean>(false)

  const creatCatalogSlice = useSelector(
    (state: RootState) => state.createTestSlice
  )

  const display = displayData(LabCodeValueSet)
  let tName: string | undefined = ''
  let dataId: string | undefined = ''
  let price: number | undefined = 0
  let tSummary: string | undefined = ''
  let tDescription: string | undefined = ''
  let ins: string | undefined = ''
  let reptDisp: string | undefined = ''

  if (partnerLabTest) {
    tName = partnerLabTest.testName ? partnerLabTest.testName : ''
    price = partnerLabTest.partnerLabPrice ? partnerLabTest.partnerLabPrice : 0
    tSummary = partnerLabTest.scheduleSummary
      ? partnerLabTest.scheduleSummary
      : ''
    tDescription = partnerLabTest.planDefinition.description
      ? partnerLabTest.planDefinition.description
      : ''
    dataId = partnerLabTest.id ? partnerLabTest.id : ''
    ins = partnerLabTest.presetInstructions
      ? partnerLabTest.presetInstructions
      : ''
    reptDisp = partnerLabTest.reportDisplayName
      ? partnerLabTest.reportDisplayName
      : ''
  }

  const [tests, setTests] = React.useState<Test>({
    id: partnerLabTest?.id ?? '',
    testName: partnerLabTest?.testName ?? '',
    testPrice: partnerLabTest?.partnerLabPrice ?? 0,
    scheduleSummary: partnerLabTest?.scheduleSummary ?? '',
    description: partnerLabTest?.description ?? '',
    presetInstructions: partnerLabTest?.presetInstructions ?? '',
    reportDisplayName: partnerLabTest?.reportDisplayName ?? '',
    code: [],
  })

  function handleNameChange(event: any) {
    setTests({ ...tests, testName: event.display })
    setShowDisplay(true)
  }

  function handlePriceChange(tPrice: number) {
    setTests({ ...tests, testPrice: tPrice })
  }

  function handleChangeInstructions(splIns: string) {
    setTests({ ...tests, presetInstructions: splIns })
  }

  function handleDescriptionChange(description: string) {
    setTests({ ...tests, description })
  }

  function handleScheduleSummaryChange(summary: string) {
    setTests({ ...tests, scheduleSummary: summary })
  }

  function handleReportNameChange(reportName: string) {
    setTests({ ...tests, reportDisplayName: reportName })
  }

  function handleSubmit() {
    let hasErrorsInData: boolean = false
    let hasPriceErrorInData: boolean = false
    let reportErrorData: boolean = false

    if (
      tests.reportDisplayName.length === 0 ||
      !tests.reportDisplayName.trim()
    ) {
      reportErrorData = true
    } else {
      reportErrorData = false
    }
    if (
      tests.testPrice <= 0 ||
      tests.testPrice > 7000 ||
      Number.isNaN(tests.testPrice)
    ) {
      hasPriceErrorInData = true
    } else {
      hasPriceErrorInData = false
    }

    if (tests.scheduleSummary.length === 0 || !tests.scheduleSummary.trim()) {
      hasErrorsInData = true
    } else {
      hasErrorsInData = false
    }
    if (!hasErrorsInData && !hasPriceErrorInData && !reportErrorData) {
      const bundleData = getCatalogBundleUpdate(
        tests,
        locationId,
        partnerLabTest
      )
      logger.info(bundleData)
      if (bundleData) {
        dispatch(updateCatalog(bundleData, locationId, 'Test'))
      }
    }
    if (hasErrorsInData) setHasErrors(hasErrorsInData)
    else setHasErrors(hasPriceErrorInData)
  }

  function resetDetails() {
    setTests({
      id: getUniqueTempId(),
      testName: '',
      testPrice: 0,
      scheduleSummary: '',
      description: '',
      presetInstructions: '',
      reportDisplayName: '',
      code: [],
    })
  }

  useEffect(() => {
    setTests({
      id: getUniqueTempId(),
      testName: tName || '',
      testPrice: price || 0,
      scheduleSummary: tSummary || '',
      description: tDescription || '',
      presetInstructions: ins || '',
      reportDisplayName: reptDisp || '',
      code: [],
    })
    if (creatCatalogSlice.updateSuccessful) {
      dispatch(resetPackageState())
      resetDetails()
      onDiscard()
    }
    return () => {}
  }, [creatCatalogSlice.updateSuccessful, onDiscard, dispatch])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()

        resetDetails()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>
        {' '}
        Edit Partner Lab Test{' '}
      </DialogTitle>
      <DialogContent>
        <Box flexDirection='column' display='flex'>
          <Box display='flex' flexDirection='column' px={3}>
            {creatCatalogSlice.error && (
              <Box display='flex'>
                <Alert severity='error'>
                  {creatCatalogSlice.errorMessage ??
                    'Error while sending invitations. Please try later'}
                </Alert>
              </Box>
            )}
            {creatCatalogSlice.additionSuccessful && (
              <Box display='flex'>
                <Alert severity='success'>
                  {creatCatalogSlice.errorMessage ??
                    'Invitation sent Successfully'}
                </Alert>
              </Box>
            )}
            <Box display='flex' flexDirection='row' alignItems='flex-start'>
              <Box p={1.5} width='30%'>
                <Box paddingTop={2}>
                  <WelloTextField
                    title='Selected Test Name'
                    textProps={{
                      id: `name`,
                      disabled: true,
                      value: tests.testName,
                      placeholder: 'Test Name',
                      type: 'text',
                    }}
                  />
                </Box>
              </Box>

              <Box p={1.5} width='25%'>
                <Box display='flex' flexDirection='row' alignItems='flex-start'>
                  <Box flexDirection='column'>
                    <Box py={1.1}>
                      <Typography variant='subtitle2' align='center'>
                        {' '}
                        PRICE
                      </Typography>
                    </Box>
                    <Box
                      borderRadius='15%'
                      style={{ height: 35, width: 38 }}
                      bgcolor='#F1F1F1'
                      justifyContent='center'
                      alignItems='center'
                      paddingTop={1}
                    >
                      <Typography variant='subtitle2' align='center'>
                        {' '}
                        INR
                      </Typography>
                    </Box>
                  </Box>
                  <Box py={2.1}>
                    <WelloTextField
                      title={t('')}
                      textProps={{
                        id: `actor_email_unit`,
                        inputProps: {
                          max: 7000,
                        },
                        value: tests.testPrice,
                        placeholder: 'Price',
                        error:
                          hasErrors ||
                          tests.testPrice <= 0 ||
                          tests.testPrice > 7000 ||
                          Number.isNaN(tests.testPrice),
                        type: 'number',
                        helperText:
                          hasErrors ||
                          tests.testPrice <= 0 ||
                          tests.testPrice > 7000 ||
                          Number.isNaN(tests.testPrice)
                            ? 'Price between 1 to 7000'
                            : '',
                        //   value: tests.testPrice,
                        onChange: (changePrice) => {
                          handlePriceChange(
                            parseFloat(
                              parseFloat(changePrice.target.value).toFixed(2)
                            )
                          )
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box p={1.5} width='30%'>
                <WelloTextField
                  title={t('SCHEDULE SUMMARY')}
                  textProps={{
                    id: `test_scheduleSummary${t}`,
                    placeholder: 'Schedule Summary',
                    error:
                      hasErrors ||
                      tests.scheduleSummary.length === 0 ||
                      !tests.scheduleSummary.trim(),
                    type: 'text',
                    value: tests.scheduleSummary,
                    helperText:
                      hasErrors ||
                      tests.scheduleSummary.length === 0 ||
                      !tests.scheduleSummary.trim()
                        ? 'Schedule Summary is required'
                        : '',
                    //   value: tests.testPrice,
                    onChange: (changeSummary) => {
                      handleScheduleSummaryChange(changeSummary.target.value)
                    },
                  }}
                />
              </Box>
            </Box>
            <Box display='flex' flexDirection='row' alignItems='flex-start'>
              <Box p={1.5} width='42%'>
                <WelloTextField
                  title='Description'
                  textProps={{
                    id: `testDescription${t}`,
                    value: tests.description,
                    placeholder: 'Description',
                    type: 'text',
                    rows: 4,
                    rowsMax: 6,
                    multiline: true,
                    onChange: (changeDescription) => {
                      handleDescriptionChange(changeDescription.target.value)
                    },
                  }}
                />
              </Box>
              <Box p={1.5} width='43%'>
                <WelloTextField
                  title={t('labelCommon:test_restrictions')}
                  textProps={{
                    id: `splInstructions${t}`,
                    type: 'text',
                    placeholder: 'Pretest Instructions',
                    rows: 4,
                    rowsMax: 6,
                    multiline: true,
                    value: tests.presetInstructions,
                    error: hasErrors && tests.presetInstructions.length > 250,
                    helperText:
                      hasErrors && tests.presetInstructions.length > 250
                        ? 'Pretest Instructions should be less than 250 chars'
                        : '',
                    onChange: (changeInstructions) => {
                      handleChangeInstructions(changeInstructions.target.value)
                    },
                  }}
                />
              </Box>
            </Box>
            <Box p={1.5} width='42%'>
              <WelloTextField
                title={t('REPORT DISPLAY NAME')}
                textProps={{
                  id: `test_reportDisplayName${t}`,
                  placeholder: 'Report Display Name',
                  value: tests.reportDisplayName,
                  error:
                    hasErrors ||
                    tests.reportDisplayName.length === 0 ||
                    !tests.reportDisplayName.trim(),
                  type: 'text',
                  helperText:
                    hasErrors ||
                    tests.reportDisplayName.length === 0 ||
                    !tests.reportDisplayName.trim()
                      ? 'Report Display Name is required'
                      : '',
                  //   value: tests.testPrice,
                  onChange: (changeSummary) => {
                    handleReportNameChange(changeSummary.target.value)
                  },
                }}
              />
            </Box>
            {showDisplay && (
              <Box
                display='flex'
                flexDirection='column'
                alignItems='flex-start'
                p={1.5}
                width='100%'
              >
                <Box py={0.5}>
                  {' '}
                  <Typography variant='subtitle2' color='textPrimary'>
                    Test Parameters for {tests.testName}
                  </Typography>
                </Box>
                <Typography variant='body1' color='textSecondary'>
                  [ {display} ]
                </Typography>
              </Box>
            )}
          </Box>

          <Divider style={{ border: 1 }} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box>
          {creatCatalogSlice.adding && <CircularProgress />}
          <Button
            //   color='primary'
            onClick={() => {
              onClose()
            }}
            variant='outlined'
            disabled={creatCatalogSlice.adding}
            disableElevation
            size='small'
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            size='small'
            color='primary'
            //   disabled={tests.testName.length === 0}
            onClick={() => {
              handleSubmit()
            }}
          >
            Update Test
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
