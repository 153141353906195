import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, MenuItem, TextField, TextFieldProps } from '@material-ui/core'
import { ArrowDropDownSharp } from '@material-ui/icons'
import { SubModel } from 'models/subModel'
import React, { useState } from 'react'
import { WelloFormItemLabel } from 'wello-web-components'

interface WelloSelectCodingProps {
  title: string
  textFieldProps: TextFieldProps
  availableCodings: SubModel[]
  onChanged: (selectedCoding: SubModel) => void
  preSelectedCoding?: SubModel
  displayDoneLabel?: boolean
}

export const WelloSelectSubstance: React.FunctionComponent<WelloSelectCodingProps> =
  ({
    title,
    textFieldProps,
    availableCodings,
    onChanged,
    preSelectedCoding,
    displayDoneLabel,
  }: WelloSelectCodingProps) => {
    const options: SubModel[] = [
      { id: '', name: 'Select', unit: '' },
      ...availableCodings,
    ]
    const [item, setItem] = useState<R4.ICoding>(
      preSelectedCoding ?? { id: '', name: 'Select', unit: '' }
    )
    return (
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <WelloFormItemLabel title={title} displayDoneLabel={displayDoneLabel} />
        <Box>
          <TextField
            variant='outlined'
            fullWidth
            size='small'
            select
            id='select_subs'
            SelectProps={{
              IconComponent: ArrowDropDownSharp,
            }}
            InputProps={{
              id: 'subs_input',
            }}
            value={item.id}
            label={item.id === '' ? 'Select' : ''}
            onChange={(val) => {
              const selectedItem: SubModel | undefined = availableCodings.find(
                (gender) => gender.id === val.target.value
              )

              if (selectedItem) {
                setItem(selectedItem)
                onChanged(selectedItem)
              }
            }}
            {...textFieldProps}
          >
            {options.map((option) => (
              <MenuItem
                id={option.id}
                key={option.id}
                value={option.id}
                selected={item.id === option.id}
                disabled={option.id === ''}
              >
                {option.price === undefined && <Box>{option.name}</Box>}
                {option.price && (
                  <Box>
                    {option.name} - {option.unit} - Rs. {option.price}
                  </Box>
                )}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
    )
  }
