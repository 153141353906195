import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { LabAndRadiologyDocumentListSliceStatus } from './labAndRadiologyDocumentListStatus'

const initialState: LabAndRadiologyDocumentListSliceStatus = {
  fetchingImpressions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  updatedImpressions: false,
  updatingImpressions: false,
  errorWhileSearchingImpressions: false,
  errorWhileUpdatingImpressions: false,
}

const labAndRadiologyDocumentListSlice = createSlice({
  name: 'labAndRadiologyDocumentListSlice',
  initialState,
  reducers: {
    updatedStatus(
      state,
      action: PayloadAction<LabAndRadiologyDocumentListSliceStatus>
    ) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingImpressions = action.payload.fetchingImpressions
      state.resultsAvailable = action.payload.resultsAvailable
      state.documentReferences = action.payload.documentReferences
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingImpressions =
        action.payload.errorWhileUpdatingImpressions
      state.updatedImpressions = action.payload.updatedImpressions
      state.updatingImpressions = action.payload.updatingImpressions
      state.errorWhileSearchingImpressions =
        action.payload.errorWhileSearchingImpressions
    },
  },
})

export const getLabAndRadiologyListOfEncounter =
  (encounterId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: LabAndRadiologyDocumentListSliceStatus = { ...initialState }
    state.fetchingImpressions = true
    dispatch(labAndRadiologyDocumentListSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        encounter: encounterId,
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/DocumentReference',
        '',
        searchParameters
      )
      logger.info(
        '-----------------------------DocumentReference-----REQ----------------------------------------------',
        response
      )
      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingImpressions = true
        state.fetchingImpressions = false

        dispatch(labAndRadiologyDocumentListSlice.actions.updatedStatus(state))
      } else {
        const conditionResponse: R4.IBundle = resp.right
        logger.info('DocumentReference resp', conditionResponse.entry)
        if (conditionResponse?.entry && conditionResponse?.entry.length > 0) {
          const clinicalImpressions: R4.IDocumentReference[] =
            conditionResponse?.entry.map(
              (e) => e.resource as R4.IDocumentReference
            )

          if (clinicalImpressions && clinicalImpressions.length > 0) {
            state.resultsAvailable = true
            state.fetchingImpressions = false
            state.documentReferences = clinicalImpressions

            state.noResultsAvailable = false
            state.errorReason = undefined
            state.errorWhileSearchingImpressions = false
            dispatch(
              labAndRadiologyDocumentListSlice.actions.updatedStatus(state)
            )
          }
        } else {
          const errorSearchDoctor: LabAndRadiologyDocumentListSliceStatus = {
            ...initialState,
          }
          errorSearchDoctor.noResultsAvailable = true
          dispatch(
            labAndRadiologyDocumentListSlice.actions.updatedStatus(
              errorSearchDoctor
            )
          )
        }
      } /* */
    } catch (error) {
      const errorSearchDoctor: LabAndRadiologyDocumentListSliceStatus = {
        ...initialState,
      }
      errorSearchDoctor.errorReason = 'Error while fetching assessment details'
      errorSearchDoctor.errorWhileSearchingImpressions = false
      dispatch(
        labAndRadiologyDocumentListSlice.actions.updatedStatus(
          errorSearchDoctor
        )
      )
    }
  }
export default labAndRadiologyDocumentListSlice.reducer
