import { Grid, Typography, Box } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NextStepsOfConsultation } from './plan/nextSteps'

interface PlanPartOfConsultationProp {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
}

export const PlanPartOfConsultation: React.FC<PlanPartOfConsultationProp> = ({
  fhirAppointmentDetails,
  split,
}: PlanPartOfConsultationProp) => {
  const { t } = useTranslation(['common'])
  const [addButton, showAddButton] = useState<boolean>(false)

  return (
    <Grid
      container
      spacing={1}
      direction='column'
      style={{
        maxWidth: '100%',
      }}
    >
      <Grid
        item
        style={{
          maxWidth: '100%',
        }}
      >
        <Box py={2}>
          <Typography
            variant='h5'
            color='primary'
            id='next_steps'
            style={{
              fontSize: 18,
              fontWeight: 700,
            }}
          >
            {fhirAppointmentDetails.diagnosticReport!}
          </Typography>
        </Box>
      </Grid>
      <Grid
        style={{
          maxWidth: '100%',
        }}
      >
        <NextStepsOfConsultation
          fhirAppointmentDetails={fhirAppointmentDetails}
          split={split}
        />
      </Grid>
    </Grid>
  )
}
