import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { CcClinet } from 'services/Cclinet'
import { InventoryClient } from 'services/inventoryService'
import { getCurrentUserPractitionerDetails } from 'services/userDetailsService'

import { logger } from 'utils/logger'
import {
  getContactDetailsById,
  isContactExist,
} from '../medicineListSearch/medicineOrderSearchSlice'
import { PatientCountStatus } from './medicineOrderCountStatusTypes'

const initialState: PatientCountStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
  count: 0,
}

const medicineOrderCountSlice = createSlice({
  name: 'medicineOrderCountSlice',
  initialState,
  reducers: {
    searchingMedicineOrderCount(
      state,
      action: PayloadAction<PatientCountStatus>
    ) {},

    searchResults(state, action: PayloadAction<PatientCountStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.count = action.payload.count
    },

    noDataFoundForSearch(state, action: PayloadAction<PatientCountStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.count = 0
    },

    errorWhileSearching(state, action: PayloadAction<PatientCountStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.count = 0
    },
  },
})

export const updateMedicineOrderCount =
  (date?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: PatientCountStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
      count: 0,
    }
    dispatch(
      medicineOrderCountSlice.actions.errorWhileSearching(errorSearchPatient)
    )
    try {
      const contactId = await getContactDetailsById(
        getCurrentUserPractitionerDetails().id ?? ''
      )

      const finalDate: string = date ?? moment().format('YYYY-MM-DD')
      let count = 0
      if (contactId.length > 0) {
        count = await getSalesOrderCount(contactId, finalDate)
      }

      if (count > 0) {
        const searchPatientResult: PatientCountStatus = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          count,
        }
        dispatch(
          medicineOrderCountSlice.actions.searchResults(searchPatientResult)
        )
        return
      }

      const noSearchResults: PatientCountStatus = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
        count: 0,
      }
      dispatch(
        medicineOrderCountSlice.actions.noDataFoundForSearch(noSearchResults)
      )
      return
    } catch (error) {
      logger.info('error')
      logger.error(error)
      const errorInSearchPatient: PatientCountStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
        count: 0,
      }
      dispatch(
        medicineOrderCountSlice.actions.errorWhileSearching(
          errorInSearchPatient
        )
      )
    }
  }

export async function getSalesOrderCount(
  id: string,
  date?: string
): Promise<number> {
  const fhirClient: InventoryClient = new InventoryClient()
  const searchParameters: any = {
    date: 0,
    active: true,
    _total: 'accurate',
  }

  const response: any = await fhirClient.doGetResource(
    `/inventory/v1/salesorders?reference_number=${id}&date=${date ?? ''}`
  )
  if (response) {
    if (response.salesorders.length > 0) {
      return response.salesorders.length
    }
  }
  return 0
}

export default medicineOrderCountSlice.reducer
