import MaterialTable, { MTableToolbar } from '@material-table/core'
import {
  Box,
  makeStyles,
  Paper,
  TablePagination,
  Typography,
  useTheme,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { IpdRoomRatesData } from 'models/ipdRoomRatesData'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { EditDiscountRate } from './editDiscountRate'

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      padding: 4,
    },
  },
})

export interface DiscountsType {
  day: number
  premium: number
  suite: number
  sharing: number
}

interface IChargesProperties {
  ipdChargesData: IpdRoomRatesData[]
  //   onUserSelected: (selectedDetail: IpdRoomRatesData) => void
}

export const DiscountRateDataTable: React.FC<any> = () => {
  const classes = useStyles()

  const data: DiscountsType[] = [
    { day: 0, premium: 0, suite: 0, sharing: 0 },
    { day: 1, premium: 0, suite: 0, sharing: 0 },
    { day: 2, premium: 0, suite: 0, sharing: 0 },
    { day: 3, premium: 25, suite: 25, sharing: 25 },
    { day: 4, premium: 25, suite: 25, sharing: 25 },
    { day: 5, premium: 30, suite: 30, sharing: 25 },
    { day: 6, premium: 30, suite: 30, sharing: 25 },
  ].sort((n1, n2) => n1.day - n2.day)

  const { height, width } = useWindowDimensions()
  const [selectedRowId, setSelectedRowId] = React.useState('')
  const [selectedRow, setSelectedRow] = React.useState(false)
  const [ipdRateDetail, setIpdRateDetail] = React.useState<IpdRoomRatesData>()
  const [openPartnerLabPopup, setOpenPartnerLabPopup] = useState(false)
  const { t } = useTranslation()
  const [showPopup, setShowPopup] = React.useState<boolean>(false)
  const [discountRate, setDiscountRate] = React.useState<any>()

  const columns = [
    {
      title: (
        <Box minWidth='150px' minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
          >
            {t('labelCommon:Day')}
          </Typography>
        </Box>
      ),
      field: 'day',
      width: '10%',
      //   cellStyle: {
      //     width: 250,
      //     maxWidth: 250,
      //   },
      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (rowData: any) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <Typography variant='subtitle2' noWrap>
            {rowData.day}
          </Typography>
        </Box>
      ),
    },
    {
      title: (
        <Box minWidth='150px' minHeight='15px' maxHeight='15px'>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
          >
            {t('labelCommon:Premium')} (%)
          </Typography>
        </Box>
      ),
      field: 'premium',
      width: '20%',
      //   cellStyle: {
      //     width: 250,
      //     maxWidth: 250,
      //   },
      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (rowData: any) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <Typography variant='subtitle2' noWrap>
            &nbsp;&nbsp;&nbsp;{rowData.premium}
          </Typography>
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography variant='subtitle1' color='primary' noWrap>
            {t('labelCommon:Suite')} (%)
          </Typography>
        </Box>
      ),
      field: 'suite',
      //   cellStyle: {
      //     width: 150,
      //     maxWidth: 175,
      //   },
      width: '20%',
      render: (rowData: any) => (
        <Box>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            &nbsp;&nbsp;&nbsp;{rowData.suite}
          </Typography>
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{ fontSize: 14 }}
            color='primary'
            noWrap
          >
            {t('labelCommon:Sharing')} (%)
          </Typography>
        </Box>
      ),
      field: 'sharing',
      width: '50%',
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            &nbsp;&nbsp;&nbsp;{rowData.sharing}
          </Typography>
        </Box>
      ),
    },
  ]

  return (
    <Box
      style={{ overflow: 'none', width: `${width - 224}px` }}
      paddingLeft={1.5}
    >
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                minHeight: '0px',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
          Container: (props) => <Paper {...props} elevation={0} />,
          Pagination: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                display: 'flex',
                justifyContent: 'left',
                // padding: '0px',
              }}
            >
              <TablePagination
                {...props}
                labelRowsSelect={<div style={{ fontSize: 14 }}> rows</div>}
                SelectProps={{
                  style: {
                    fontSize: 14,
                    minHeight: '5px',
                    backgroundColor: useTheme().palette.background.default,
                  },
                }}
              />
            </div>
          ),
        }}
        columns={columns}
        data={data}
        onRowClick={(event, rowData) => {
          // setDiscountRate(rowData)
          // setShowPopup(true)
        }}
        options={{
          //   searchFieldStyle: { padding: 4 },
          toolbar: false,
          search: false,
          showTitle: false,
          padding: 'dense',
          searchFieldVariant: 'outlined',
          filtering: false,
          pageSize: 10,
          paging: false,
          pageSizeOptions: [10, 20, 30],
          //   columnsButton: true,
          maxBodyHeight: `${height - 169}px`,
          minBodyHeight: `${height - 200}px`,
          tableLayout: 'auto',
          thirdSortClick: false,
          sorting: true,
          headerStyle: {
            backgroundColor: useTheme().palette.background.default,
            color: '#FFF',
            width: 100,
            minHeight: '15px',
            maxHeight: '15px',
            position: 'sticky',
            top: 0,
            // height: 20,
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          rowStyle: (_data: any, index: number, _level: number) =>
            index % 2
              ? selectedRow && _data.id === selectedRowId
                ? { backgroundColor: '#9E9DDC', height: 50 }
                : { backgroundColor: kDialogueBackground }
              : selectedRow && _data.id === selectedRowId
              ? { backgroundColor: '#9E9DDC', height: 50 }
              : {},
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />

      {showPopup && (
        <Box
          display='flex'
          flexGrow={4}
          overflow='auto'
          minWidth={`${width - 460}px`}
          height='100%'
          style={{ backgroundColor: '#ececec' }}
        >
          <EditDiscountRate
            rowDetails={discountRate}
            open={showPopup}
            onCancelClick={() => {
              setShowPopup(false)
            }}
          />
        </Box>
      )}
    </Box>
  )
}
