import { R4 } from '@ahryman40k/ts-fhir-types'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { createContext } from 'react'

export interface VisitAppointmentContextData {
  patient: R4.IPatient | null
  encounter?: R4.IEncounter
  visitReference?: FhirAppointmentDetail | FhirActiveIPDDetailsForMedicalRole
}

const VisitAppointmentContext = createContext<VisitAppointmentContextData>({
  patient: null,
})

export default VisitAppointmentContext
