/* eslint-disable react/jsx-key */
import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestForOrdersForAgent } from 'redux/lab/order/agentOrderSearch/agentOrderSearchSlice'
import { RootState } from 'redux/rootReducer'
import { OrderListView } from './orderView'

interface Props {
  selectedAgent: PractitionerWithRole
  onOrderSelected: (selectedOrder: FhirLabOrderDetail[]) => void
}

export const OrderSearch: React.FC<Props> = ({
  selectedAgent,
  onOrderSelected,
}: Props) => {
  const agentOrderSlice = useSelector(
    (state: RootState) => state.agentOrderSlice
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(requestForOrdersForAgent(selectedAgent, new Date()))
  }, [dispatch])

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      flexGrow={1}
      height='100%'
    >
      <Box display='flex' flexDirection='row' width='100%'>
        <TextField
          onChange={(event) => {
            dispatch(
              requestForOrdersForAgent(
                selectedAgent,
                new Date(),
                event.target.value
              )
            )
          }}
          variant='outlined'
          size='small'
          fullWidth
          autoFocus={true}
          placeholder={t('labelCommon:search')}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box
        display='flex'
        flexGrow={1}
        flexDirection='column'
        justifyContent='center'
        alignContent='center'
        //   paddingTop={1}
      >
        {agentOrderSlice.noResultsAvailable && (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            width='100%'
            height='100%'
            alignContent='center'
          >
            <Typography variant='subtitle1' color='initial' align='center'>
              No Orders Available.
            </Typography>
          </Box>
        )}

        {agentOrderSlice.errorWhileSearchingOrders && (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            width='100%'
            height='100%'
            alignContent='center'
          >
            <Typography variant='subtitle1' color='error' align='center'>
              Error while searching Orders
            </Typography>
          </Box>
        )}

        {agentOrderSlice.searchingAppointments && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
          >
            <CircularProgress size={25} />
          </Box>
        )}
      </Box>

      {agentOrderSlice.resultsAvailable && (
        <Box
          display='flex'
          flexDirection='row'
          flexGrow={1}
          width='100%'
          height='100%'
        >
          <Box display='flex' height='100%' width='100%'>
            <OrderListView
              orderList={agentOrderSlice.availableOrders}
              onOrderSelected={(orders) => {
                onOrderSelected(orders)
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}
