import { Divider } from '@mantine/core'
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Button,
} from '@material-ui/core'
import { ConvenienceFee } from 'models/convinienceFeeDetails'
import { searchingConvFee } from 'redux/administration/payment-settings/convinienceFeeSearchSlice'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { SearchingReferrals } from 'redux/administration/payment-settings/referralSearchSlice'
import { isWpAdmin } from 'services/userDetailsService'
import { ReferralFeeDetails } from './editConvinienceFee'
import { ReferralFeeDataTable } from './referralFeeDataTable'
import { AddReferralAmount } from './addReferral'

export interface MembershipSearchProps {
  onTap?: () => void
  onAdd?: () => void
}

export const ReferralFeeSearch: React.FC<MembershipSearchProps> = ({
  onTap,
  onAdd,
}) => {
  const referralSearchSliceSetting = useSelector(
    (state: RootState) => state.referralSearchSliceSetting
  )
  const { width } = useWindowDimensions()
  const [selectedMembership, setselectedMembership] = useState<ConvenienceFee>()
  const dispatch = useDispatch()

  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  useEffect(() => {
    dispatch(SearchingReferrals())
    // if (acceptInviteSlice.updatedSuccessfully) {
    //   dispatch(resetUpdateInviteState())
    // }
  }, [dispatch])

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',

          height: '100%',
          width: '100%',
        }}
      >
        <Box
          display='flex'
          flexGrow={1}
          flexDirection='column'
          width='100%'
          height='100%'
          justifyContent='center'
          alignContent='center'
          paddingTop={1}
          style={{ maxHeight: '100%' }}
        >
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            justifyContent='space-between'
            alignContent='center'
            alignItems='center'
            overflow='hidden'
            padding={1}

            // flexGrow={1}
          >
            <Typography
              variant='h6'
              color='primary'
              style={{
                marginTop: 8,
                marginBottom: 8,
                fontFamily: 'Open Sans',

                color: 'black',

                fontWeight: 'bold',
                lineHeight: 'normal',
              }}
            >
              Referral Fee
            </Typography>
            {!referralSearchSliceSetting.searching && isWpAdmin() && (
              <Box
                display='flex'
                flexGrow={1}
                flexDirection='row'
                justifyContent='end'
                alignContent='center'
                overflow='auto'
                px={1}
              >
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    setShowPopup(true)
                  }}
                >
                  Add Referral Fee
                </Button>
              </Box>
            )}
          </Box>
          <Divider />
          {referralSearchSliceSetting.searching && (
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
              height='100%'
              width='100%'
            >
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            </Box>
          )}
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignContent='center'
            style={{
              backgroundColor: '#ececec',
            }}
          >
            {referralSearchSliceSetting.noResultsAvailable && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='100%'
                height='100%'
                alignContent='center'
              >
                <Typography variant='subtitle1' color='initial' align='center'>
                  No referral data available
                </Typography>
              </Box>
            )}
            {referralSearchSliceSetting.error && (
              <Box
                //   display='flex'
                //   flexDirection='column'
                justifyContent='center'
                width='100%'
                height='100%'
                alignContent='center'
                paddingLeft={50}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container justifyContent='center'>
                      <Typography
                        variant='subtitle1'
                        color='error'
                        align='center'
                      >
                        Error while searching referrals.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            {referralSearchSliceSetting.resultsAvailable && (
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height='100%'
                overflow='auto'
                // flexGrow={1}
              >
                {' '}
                <Box display='flex' height='80vh' width='100%'>
                  <ReferralFeeDataTable
                    onReferralSelected={(referral: ConvenienceFee) => {
                      //   setselectedMembership(referral)
                    }}
                    displayOnlyName={selectedMembership !== undefined}
                    usersData={
                      referralSearchSliceSetting.referralList
                        ? referralSearchSliceSetting.referralList
                        : []
                    }
                    selectedReferral={selectedMembership}
                  />
                </Box>
                {selectedMembership && <Divider orientation='vertical' />}
                {selectedMembership && (
                  <Box
                    display='flex'
                    flexGrow={4}
                    width='100%'
                    // minWidth={`${width - 460}px`}
                    height='100%'
                    style={{ backgroundColor: '#ececec' }}
                  >
                    <ReferralFeeDetails
                      onCloseClicked={() => {
                        setselectedMembership(undefined)
                      }}
                      usersData={selectedMembership}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>

          {showPopup && (
            <AddReferralAmount
              open={showPopup}
              title='Referral'
              onBackClick={() => {
                setShowPopup(false)
                dispatch(SearchingReferrals())
              }}
              onContinueClick={() => {
                setShowPopup(false)
                dispatch(SearchingReferrals())
              }}
            />
          )}
        </Box>
      </Paper>
    </div>
  )
}
