/* eslint-disable no-dupe-keys */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { DateWiseCombinedSlots } from 'models/dateCombinedSlots'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { isToday } from 'utils/dateUtil'
import { getDateWiseCombinedSlots } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getSlotsFromSlotsRelationsForAgent } from 'utils/fhirResoureHelpers/fhirSlotHelper'
import { logger } from 'utils/logger'
import { AgentSlotSearchStatus } from './agentSlotSearchStatusTypes'

const initialState: AgentSlotSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
  noSelection: true,
  singleSlotAvailable: false,
  errorMessage: '',
  singleSlot: undefined,
  slotList: undefined,
}

const agentSlotSearchSlice = createSlice({
  name: 'agentSlotSearchSlice',
  initialState,
  reducers: {
    updateSlotStatus(state, action: PayloadAction<AgentSlotSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.slotList = action.payload.slotList
      state.noSelection = action.payload.noSelection
      state.singleSlotAvailable = action.payload.singleSlotAvailable
      state.singleSlot = action.payload.singleSlot
    },
    selectDoctor(state, action: PayloadAction<AgentSlotSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.slotList = undefined
      state.noSelection = true
    },

    searchResults(state, action: PayloadAction<AgentSlotSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.slotList = action.payload.slotList
      state.noSelection = false
    },

    noDataFoundForSearch(state, action: PayloadAction<AgentSlotSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.slotList = undefined
      state.noSelection = false
    },

    errorWhileSearching(state, action: PayloadAction<AgentSlotSearchStatus>) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.slotList = undefined
      state.noSelection = false
    },

    resetState(state, action: PayloadAction<AgentSlotSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.slotList = undefined
      state.noSelection = true
    },
  },
})

export const resetSlotSelectionStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: AgentSlotSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      noSelection: false,
      errorMessage: undefined,
      singleSlotAvailable: false,
      slotList: undefined,
    }
    dispatch(agentSlotSearchSlice.actions.resetState(state))
  }

export const requestASAPAgentSlot =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: AgentSlotSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      singleSlotAvailable: false,
      searching: true,
      noSelection: false,
    }
    dispatch(agentSlotSearchSlice.actions.updateSlotStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        '_include:iterate': 'Schedule:actor',
        _include: 'Slot:schedule',
        start: `ge${moment.utc(Date.now()).local().format()}`,
        status: 'free',
        _sort: 'start',
        _total: 'accurate',
        'service-category': '9',
      }
      searchParameters['schedule:Schedule.active'] = true

      const response: any = await fhirClient.doGetResource(
        '/Slot',
        searchParameters
      )
      logger.info('---SLot Response---------')
      logger.info(response)
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const slotsResponse: R4.IBundle = relatedFhirDecodeRes.right
        const categorizedSlots: FhirSlotDetail[] =
          getSlotsFromSlotsRelationsForAgent(slotsResponse)
        logger.info('Agent slot resp')
        logger.info(categorizedSlots)
        if (categorizedSlots.length > 0) {
          const currentState = { ...initialState }
          const practRole: PractitionerWithRole[] = []
          const practWithROle: PractitionerWithRole = {
            id: categorizedSlots[0].practitioner?.id ?? '',
            gender: '',
            practRoleId: categorizedSlots[0].practitionerRole
              ? categorizedSlots[0].practitionerRole.id ?? ''
              : '',
            roleObject: categorizedSlots[0].practitionerRole,
            name: '',
            phone: '',
            primaryContact: '',
            status: '',
            fullName: '',
            color: '',
            enabled: categorizedSlots[0].practitioner
              ? categorizedSlots[0].practitioner.active
                ? categorizedSlots[0].practitioner.active
                : false
              : false,
          }
          practRole.push(practWithROle)
          const slotDataSingle: FhirSlotDetail = {
            slot: categorizedSlots[0].slot,
            schedule: categorizedSlots[0].schedule,
            practitioner: categorizedSlots[0].practitioner,
            practitionerRole: categorizedSlots[0].practitionerRole,
            healthCareService: categorizedSlots[0].healthCareService,
            practitionerWithRole: practRole,
          }
          currentState.singleSlotAvailable = true
          currentState.singleSlot = slotDataSingle
          dispatch(agentSlotSearchSlice.actions.updateSlotStatus(currentState))
        } else {
          const errorSearchDoctor: AgentSlotSearchStatus = {
            error: false,
            noResultsAvailable: true,
            resultsAvailable: false,
            searching: false,
            singleSlotAvailable: false,
          }
          dispatch(
            agentSlotSearchSlice.actions.noDataFoundForSearch(errorSearchDoctor)
          )
        }
      } else {
        state.error = true
        state.searching = false
        state.resultsAvailable = false

        dispatch(agentSlotSearchSlice.actions.errorWhileSearching(state))
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: AgentSlotSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
        singleSlotAvailable: false,
      }
      dispatch(
        agentSlotSearchSlice.actions.errorWhileSearching(errorSearchDoctor)
      )
    }
  }

export const requestForAgentSlots =
  (
    selectedDoctors: PractitionerWithRole[],
    selectedDate?: Date,
    selectedTimeOfDay?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AgentSlotSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
      noSelection: false,
      singleSlotAvailable: false,
    }
    dispatch(agentSlotSearchSlice.actions.updateSlotStatus(state))
    try {
      if (selectedDate && isToday(selectedDate)) {
        selectedDate?.setTime(new Date().getTime())
      }
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        '_include:iterate': 'Schedule:actor',
        _include: 'Slot:schedule',

        start: `ge${moment.utc(selectedDate).local().format()}`,
        status: 'free',
        _sort: 'start',
      }
      if (selectedDoctors && selectedDoctors.length > 0) {
        let doctorsList: string = ''
        selectedDoctors.forEach((item) => {
          doctorsList = `${
            doctorsList + item.roleObject.practitioner?.reference
          },`
        })

        searchParameters['schedule:Schedule.actor'] = doctorsList
      }
      searchParameters['schedule:Schedule.active'] = true
      const response: any = await fhirClient.doGetResource(
        '/Slot',
        searchParameters
      )
      logger.info('---Slot Response----')
      logger.info(response)
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const slotsResponse: R4.IBundle = relatedFhirDecodeRes.right
        const categorizedSlots: FhirSlotDetail[] =
          getSlotsFromSlotsRelationsForAgent(slotsResponse)
        if (categorizedSlots.length > 0) {
          const dateWiseSlots: DateWiseCombinedSlots[] =
            getDateWiseCombinedSlots(categorizedSlots)

          state.resultsAvailable = true
          state.slotList = dateWiseSlots
          dispatch(agentSlotSearchSlice.actions.searchResults(state))
        } else {
          const errorSearchDoctor: AgentSlotSearchStatus = {
            error: false,
            noResultsAvailable: true,
            resultsAvailable: false,
            searching: false,
            singleSlotAvailable: false,
          }
          dispatch(
            agentSlotSearchSlice.actions.noDataFoundForSearch(errorSearchDoctor)
          )
        }
      } else {
        state.error = true
        state.searching = false
        state.resultsAvailable = false

        dispatch(agentSlotSearchSlice.actions.errorWhileSearching(state))
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: AgentSlotSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
        singleSlotAvailable: false,
      }
      dispatch(
        agentSlotSearchSlice.actions.errorWhileSearching(errorSearchDoctor)
      )
    }
  }

export default agentSlotSearchSlice.reducer
