import { Box, CircularProgress, IconButton, Tooltip } from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { getDischargeDetailsReport } from 'utils/fhirResoureHelpers/reportHelper'

export interface DischargeReportButtonProps {
  serviceReqId: string
  disabled?: boolean
  title?: string
}

export const DischargeReportButton: React.FC<DischargeReportButtonProps> = ({
  serviceReqId,
  disabled = false,
  title,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [generatingStatus, setGeneratingStatus] =
    useState<NetworkRequestStatus>({
      initial: true,
    })

  function handleInvoiceGenerationButton() {
    setGeneratingStatus({
      requesting: true,
    })

    if (serviceReqId) {
      getDischargeDetailsReport(serviceReqId).then((e) => {
        if (e) {
          setGeneratingStatus({
            requesting: false,
            requestSuccess: true,
          })
        } else {
          setGeneratingStatus({
            requesting: false,
            requestError: true,
          })
          dispatch(showErrorAlert('Discharge Summary is not available'))
        }
      })
    }
  }

  return (
    <Box px={1} paddingTop={0.5}>
      {generatingStatus.requesting && <CircularProgress size={20} />}
      {!generatingStatus.requesting && (
        <Tooltip title='Discharge Summary'>
          <IconButton
            onClick={() => {
              handleInvoiceGenerationButton()
            }}
            color='primary'
            size='small'
            disabled={disabled}
          >
            <img
              src={`${process.env.PUBLIC_URL}/Discharge_Summary.png`}
              alt='delete'
              height={40}
            />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}
