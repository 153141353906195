import { R4 } from '@ahryman40k/ts-fhir-types'
import { CircularProgress, TextField, Typography } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import { getCatalogName } from 'utils/fhirResoureHelpers/catalogHelper'

export interface CatalogEntrySelectorProps {
  isInEditMode: boolean
  id: string

  onRadioOptionsChanged: (changedValue: R4.ICatalogEntry[]) => void
  selectedValue?: R4.ICatalogEntry
  placeHolderString?: string
  catalogEntryPath?: string
  detachLabel?: boolean
  labelString?: string
  selectedId?: string
}

export const CatalogEntryOfDietSelector: React.FC<CatalogEntrySelectorProps> =
  ({
    selectedValue: selectedValues,
    isInEditMode,
    placeHolderString,
    detachLabel,
    labelString,
    id,

    onRadioOptionsChanged,
    selectedId,
  }: CatalogEntrySelectorProps) => {
    const addDietSlice = useSelector(
      (state: RootState) => state.nutritionCatalogueValueSetSlice
    )
    const [loading, setLoading] = React.useState(
      addDietSlice.searchingTreatment
    )
    const [options, setOptions] = React.useState<R4.ICatalogEntry[]>(
      addDietSlice.searchingTreatment === true
        ? []
        : addDietSlice.catalogueEntries ?? []
    )

    const filter = createFilterOptions<R4.ICatalogEntry>()

    useEffect(() => {
      if (!addDietSlice.searchingTreatment) {
        setLoading(false)
        setOptions(addDietSlice.catalogueEntries ?? [])
      }
    }, [addDietSlice.searchingTreatment])

    return (
      <Autocomplete
        id={id}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => _.capitalize(getCatalogName(option))}
        fullWidth
        size='medium'
        filterOptions={(opts, params) => {
          const filtered = filter(opts, params)

          if (params.inputValue !== '') {
            filtered.push({
              resourceType: 'CatalogEntry',
              referencedItem: {
                display: _.capitalize(params.inputValue.trim()),
              },
              extension: [
                {
                  url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-hv-food-code-ext',
                  valueCodeableConcept: {
                    coding: [
                      {
                        system:
                          'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-type-cs',
                        code: 'other',
                        display: _.capitalize(params.inputValue.trim()),
                      },
                    ],
                    text: _.capitalize(params.inputValue.trim()),
                  },
                },
              ],
            })
          }

          return filtered
        }}
        options={options}
        loading={loading}
        onChange={(e, changedValue) => {
          if (changedValue != null) {
            onRadioOptionsChanged([changedValue])
          }
        }}
        defaultValue={selectedValues}
        renderOption={(option, { selected }) => (
          <>
            {option.id && option.id.length > 0 && (
              <Typography variant='body1' color='initial'>
                {_.capitalize(getCatalogName(option))}
              </Typography>
            )}
            {(option.id === undefined || option.id.length === 0) && (
              <Typography variant='subtitle2' color='initial'>
                {`Add ${_.capitalize(getCatalogName(option))}`}
              </Typography>
            )}
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={labelString}
            variant='outlined'
            size='medium'
            placeholder='Search/Add diet by name'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    )
  }
