import { Box, CircularProgress, Container, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { checkAuthorization } from 'redux/oidcHandler/oidcSlice'
import { RootState } from 'redux/rootReducer'

export const AuthCallbackHandler: React.FC<{}> = () => {
  const authCallbackState = useSelector((state: RootState) => state.oidcSlice)
  useEffect(() => {
    checkAuthorization(window.location.href)
  }, [])
  return (
    <div>
      {authCallbackState.checking ? (
        <div>
          <CircularProgress />
          <Typography>Loading</Typography>
        </div>
      ) : authCallbackState.checking ? (
        <Redirect to='/' />
      ) : (
        <Redirect to='/login' />
      )}
      <Container>
        <CircularProgress />
        <Box />
        <Typography> Verifying user</Typography>
      </Container>
    </div>
  )
}
