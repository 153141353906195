import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getWellnessProviders } from 'redux/administration/referrals/wellnessReferralProviders/wellnessReferralsProvidersSlice'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { resetAddMedicationsState } from 'redux/consultation/addMedications/addMedicationSlice'
import { RootState } from 'redux/rootReducer'
import { getAddedDate, getAddedDateWithCustomStartDate } from 'utils/dateUtil'
import { addNewInstruction } from 'utils/sopaNotes/plan/instructionPlanHelpers'
import CustomizedDividers from 'views/components/toggle_selector'

interface Props {
  open: boolean
  patientReference: R4.IReference
  encounterReference: R4.IReference
  onClose: () => void
  onInstructionAdded: () => void
}
export const AddCustomInstructions: React.FC<Props> = ({
  open,
  onClose,
  onInstructionAdded: onReferralsAdded,
  patientReference,
  encounterReference,
}) => {
  const { t } = useTranslation(['common', 'medication', 'dosage'])

  const [textFields, setTextFields] = useState<string>()
  const [message, setMessage] = useState<string>('')
  const [tillDate, setTillDate] = useState<Date>()
  const [selectedWeekPart, setSelectedWeekPart] = useState<string>('1W')

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const dispatch = useDispatch()

  function resetForm() {
    Promise.resolve().then(() => {
      setTextFields('')
    })
  }

  function addCurrentDetails() {
    if (textFields) {
      setUpdateStatus({ requesting: true })
      addNewInstruction(
        patientReference,
        encounterReference,
        textFields,
        tillDate !== undefined ? tillDate?.toISOString() : undefined
      ).then((e) => {
        if (e) {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(showSuccessAlert('Instruction added successfully'))

          onClose()
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(
            showErrorAlert('Error adding instruction. Please try again later')
          )
        }
      })
    }
  }

  function onTextChanged(text: string) {
    setTextFields(text)
  }

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (!updateStatus.requesting) {
          resetForm()
          onClose()
        }
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Add Instructions</DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#ededed',
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0,
        }}
      >
        <Box display='flex' flexDirection='column' width='100%' py={2}>
          <Box display='flex' flexDirection='row' p={1} width='100%'>
            <Box display='flex' px={2} width='100%'>
              <TextField
                id='instruction_details'
                label=''
                fullWidth
                multiline={true}
                maxRows={6}
                minRows={4}
                variant='outlined'
                disabled={updateStatus.requesting}
                // inputProps={{
                //   maxLength: 256,
                // }}
                placeholder='Additional Instructions'
                onChange={(tex) => {
                  onTextChanged(tex.target.value)
                }}
              />
            </Box>
          </Box>

          {/* <Box
            paddingLeft={2.3}
            display='flex'
            flexDirection='row'
            justifyContent='start'
          >
            <CustomizedDividers
              key={selectedWeekPart}
              size='small'
              menuLabel={t('labelCommon:till')}
              options={[
                {
                  displayText: `${t('labelCommon:1_Week')}`,
                  value: '1W',
                },
                {
                  displayText: `2 Weeks`,
                  value: '2W',
                },
                {
                  displayText: `1 Month`,
                  value: '1m',
                },
                {
                  displayText: `${t('labelCommon:custom')}`,
                  value: 'custom',
                },
              ]}
              onSelectionChanged={(selectedValue) => {
                setSelectedWeekPart(selectedValue)
                if (selectedValue === '1m') {
                  setTillDate(getAddedDateWithCustomStartDate(new Date(), 30))
                }
                if (selectedValue === '1W') {
                  setTillDate(getAddedDateWithCustomStartDate(new Date(), 7))
                }
                if (selectedValue === '2W') {
                  setTillDate(getAddedDateWithCustomStartDate(new Date(), 14))
                }
              }}
              preSelectedValue={selectedWeekPart}
            />
          </Box> */}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          display='flex'
          flexDirection='row'
          py={1}
          justifyContent='flex-end'
          width='100%'
          paddingRight={2}
        >
          {updateStatus.requesting && <CircularProgress size={15} />}
          {!updateStatus.requesting && (
            <Button
              onClick={() => {
                onClose()
              }}
              variant='outlined'
              color='primary'
              id='cancel'
              disableElevation
            >
              {t('labelCommon:Cancel')}
            </Button>
          )}
          <Button
            onClick={() => {
              addCurrentDetails()
            }}
            variant='contained'
            color='primary'
            disableElevation
            id='add_ins'
            disabled={
              updateStatus.requesting ||
              textFields === undefined ||
              textFields.length === 0
            }
          >
            Add
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
