/* eslint-disable no-useless-catch */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource } from 'axios'
import { AppDispatch, AppThunk } from 'redux/store'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import { getGenderOfDoctor } from 'utils/fhirResourcesHelper'
import { getUnitFromProvidedBy } from 'utils/fhirResoureHelpers/referralHelpers'
import { IncomingReferralsListStatus } from './LabReferralsCount'

const initialState: IncomingReferralsListStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
  count: 0,
}

let currentSelectedDate: Date | undefined

const labReferralsCountSlice = createSlice({
  name: 'labReferralsCountSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<IncomingReferralsListStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.count = action.payload.count
      state.errorReason = action.payload.errorReason

      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const requestForIncomingLabReferralsCount =
  (
    selectedStatus?: string[],
    pageState?: string,
    offSet?: number,
    existingDateWiseData?: R4.IServiceRequest[],
    recordsCount?: number,
    gender?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: IncomingReferralsListStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      count: 0,
    }
    dispatch(labReferralsCountSlice.actions.updatedStatus(state))
    try {
      const unitId = getCurrentUserUnitReference()?.reference?.split('/')[1]
      const unitName = getCurrentUserUnitDetails().name ?? ''
      currentSelectedDate?.setSeconds(new Date().getSeconds())
      const searchParams: any = {
        perPageCount: 0,
        unitId,
        status: 'active',
      }

      if (pageState && offSet) {
        searchParams.stateId = pageState
        searchParams.offSetNumber = offSet
      }
      searchParams.gender = getGenderOfDoctor(
        getCurrentUserPractitionerDetails()
      )
      if (selectedStatus && selectedStatus.length > 0) {
        searchParams.status = selectedStatus.join(',')
      }

      const response: any = await getReferralListForLabOrder(
        searchParams.startDate,
        searchParams.status,
        searchParams.perPageCount,
        searchParams.stateId,
        searchParams.offSetNumber,
        searchParams.unitId
      )

      const resp: R4.IBundle = response as R4.IBundle
      if (!resp) {
        state.errorWhileSearchingAppointments = true
        state.searchingAppointments = false

        dispatch(labReferralsCountSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          const refOrders: R4.IServiceRequest[] = []
          const fhirAppointments: R4.IServiceRequest[] =
            appointmentResponse.entry?.map(
              (e) =>
                (e.resource as R4.IServiceRequest) &&
                (e.resource as R4.IServiceRequest)
            ) ?? []
          for (let i = 0; i < fhirAppointments.length; i++) {
            if (fhirAppointments[i].contained) {
              const { contained } = fhirAppointments[i]
              if (contained && contained.length > 1) {
                const org: string | undefined = getUnitFromProvidedBy(
                  fhirAppointments[i]
                )
                if (org) {
                  refOrders.push(fhirAppointments[i])
                }
              }
            }
          }

          state.resultsAvailable = true
          state.searchingAppointments = false
          state.count = refOrders.length
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingAppointments = false
          dispatch(labReferralsCountSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: IncomingReferralsListStatus = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            count: 0,
          }
          dispatch(
            labReferralsCountSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      }
    } catch (error) {
      console.error('---------error------------')
      console.error(error)

      if (error) {
        if ((error as any).message) {
          if ((error as any).message === 'new param added') {
            return
          }
        }
      }
      const errorSearchDoctor: IncomingReferralsListStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
        count: 0,
      }
      dispatch(labReferralsCountSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

async function getReferralListForLabOrder(
  startDate: string,
  status?: string,
  perPageCount?: number,
  stateId?: string,
  pageNumber?: number,
  organizationId?: string
): Promise<R4.IBundle | R4.IOperationOutcome | undefined> {
  axios.CancelToken.source()
  if (cancelTokenStore.has('therapiesSearchControlToken')) {
    const controlTokenForSearch: CancelTokenSource = cancelTokenStore.get(
      'therapiesSearchControlToken'
    )
    controlTokenForSearch.cancel('new param added')
    cancelTokenStore.delete('therapiesSearchControlToken')
  }
  cancelTokenStore.set(
    'therapiesSearchControlToken',
    axios.CancelToken.source()
  )
  try {
    const fhirClient: FHIRApiClient = new FHIRApiClient()

    if (stateId && perPageCount && pageNumber) {
      const searchParameters: any = {
        _getpages: stateId,
        _sort: '-_lastUpdated',
        _getpagesoffset: pageNumber,
        code: 'http://snomed.info/sct|266753000',
        category: '108252007',
        _count: perPageCount,
      }

      const r = await fhirClient.doGetResourceIncludeAndIncludeIterate(
        '/',
        searchParameters,
        (
          cancelTokenStore.get(
            'therapiesSearchControlToken'
          ) as CancelTokenSource
        ).token
      )

      return r
    }

    let searchParameters: any = {}

    if (perPageCount === 0) {
      searchParameters = {
        _count: 300,
        intent: 'proposal',
        code: 'http://snomed.info/sct|266753000',
        category: '108252007',
        _include: ['ServiceRequest:patient'],
        _sort: '-_lastUpdated',
        status: status ?? 'active',
      }
    } else {
      searchParameters = {
        _count: perPageCount ?? 500,
        code: 'http://snomed.info/sct|266753000',
        category: '108252007',
        status: status ?? 'active,cancelled',
        _sort: '-_lastUpdated',
        _include: ['ServiceRequest:patient'],
      }
    }
    searchParameters.performer = `Organization/${
      getCurrentUserUnitDetails().id
    }`

    const r = await fhirClient.doGetResourceIncludeAndIncludeIterate(
      '/ServiceRequest',
      searchParameters
    )

    const completeDate = r

    return completeDate
  } catch (error) {
    throw error
  }

  return undefined
}

export default labReferralsCountSlice.reducer
