import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'

import { AppDispatch, AppThunk } from 'redux/store'

import { InventoryClient } from 'services/inventoryService'
import { logger } from 'utils/logger'
import { MedicineOrderManagementStatus } from './medicineOrderDetailsStatus'

const initialState: MedicineOrderManagementStatus = {
  fetchingOrderDetails: false,
  orderDetailsAvailable: false,
  noResultsAvailable: false,
  errorWhileFetchingOrderDetail: false,
}

const medicineOrderDetailsSlice = createSlice({
  name: 'medicineOrderDetailsSlice',
  initialState,

  reducers: {
    updatedStatus(state, action: PayloadAction<MedicineOrderManagementStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingOrderDetails = action.payload.fetchingOrderDetails
      state.orderDetailsAvailable = action.payload.orderDetailsAvailable
      state.orderDetail = action.payload.orderDetail
      state.errorReason = action.payload.errorReason
      state.errorWhileFetchingOrderDetail =
        action.payload.errorWhileFetchingOrderDetail
    },
  },
})

export const fetchMedicineOrderDetails =
  (orderId: String): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: MedicineOrderManagementStatus = { ...initialState }
    state.fetchingOrderDetails = true
    dispatch(medicineOrderDetailsSlice.actions.updatedStatus(state))
    try {
      const fhirClient: InventoryClient = new InventoryClient()

      const response: any = await fhirClient.doGetResource(
        `/inventory/v1/salesorders/${orderId}`
      )

      // const appointmentResponse: R4.IBundle = resp.right
      if (response) {
        console.log(response.salesorder)
        logger.info('Orders length')
        const currentState = { ...initialState }
        currentState.orderDetailsAvailable = true
        currentState.orderDetail = response.salesorder
        dispatch(medicineOrderDetailsSlice.actions.updatedStatus(currentState))
      } else {
        const currentState = { ...initialState }
        currentState.noResultsAvailable = true

        dispatch(medicineOrderDetailsSlice.actions.updatedStatus(currentState))
      }
    } catch (error) {
      logger.error(error)

      const currentState = { ...initialState }
      currentState.errorWhileFetchingOrderDetail = true
      currentState.errorReason = 'Error while fetching orders'

      dispatch(medicineOrderDetailsSlice.actions.updatedStatus(currentState))
    }
  }

export default medicineOrderDetailsSlice.reducer
