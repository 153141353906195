import { List, ListSubheader } from '@material-ui/core'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import React from 'react'
import { getCompleteDateStringWithOutDay } from 'utils/dateUtil'
import { getDateWiseOrders } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { PartnerLabOrderTile } from 'views/components/lab/orderList/partnerLabOrders/partner_order_tile'

interface Props {
  preselectedOrderId?: string
  percentage: number
  labOrders?: FhirLabOrderDetail[]
  onLabOrderSelected?: (selectedOrder: FhirLabOrderDetail) => void
  dense?: boolean
}

export const PartnerLabListView: React.FC<Props> = ({
  preselectedOrderId,
  percentage,
  labOrders,
  onLabOrderSelected,
  dense = false,
}: Props) => (
  <List
    subheader={<li style={{ padding: 0, width: '100%' }} />}
    style={{ padding: 0, width: '100%', overflow: 'auto', height: '100%' }}
  >
    {getDateWiseOrders(labOrders ?? []).map((e) => (
      <li key={`section-${e.date ?? ''}`} style={{ padding: 0, width: '100%' }}>
        <ul style={{ padding: 0 }}>
          <ListSubheader
            style={{
              color: '#333333',
              fontWeight: 600,
              paddingLeft: 12,
              width: '100%',
            }}
          >
            {getCompleteDateStringWithOutDay(e.date ?? '')}
          </ListSubheader>
          {e.orders?.map((item, index) => (
            <PartnerLabOrderTile
              labOrderDetail={item}
              percentage={percentage}
              isOdd={index % 2 === 0}
              dense={dense}
              isSelected={
                preselectedOrderId !== undefined &&
                item.serviceRequest.id === preselectedOrderId
              }
              onTap={() => {
                if (onLabOrderSelected) {
                  onLabOrderSelected(item)
                }
              }}
              key={`lab_item_key${item.serviceRequest.id}` ?? ''}
            />
          ))}
        </ul>
      </li>
    ))}
  </List>
)
