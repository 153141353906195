import { R4 } from '@ahryman40k/ts-fhir-types'

export const PrimitiveReflexObservationType: R4.ICoding[] = [
  {
    system: 'http://snomed.info/sct',
    code: '246573000',
    display: 'Primary walking reaction',
  },
  {
    system: 'http://snomed.info/sct',
    code: '246578009',
    display: 'Fisting reflex',
  },
  {
    system: 'http://snomed.info/sct',
    code: '366297001',
    display: 'Freezing reflex',
  },
  {
    system: 'http://snomed.info/sct',
    code: '366308004',
    display: "Galant's reflex",
  },
  {
    system: 'http://snomed.info/sct',
    code: '299801007',
    display: 'Glabella reflex',
  },
  {
    system: 'http://snomed.info/sct',
    code: '366132000',
    display: 'Moro reflex',
  },
  {
    system: 'http://snomed.info/sct',
    code: '27598000',
    display: 'Palmar reflex',
  },
  {
    system: 'http://snomed.info/sct',
    code: '366153009',
    display: 'Palmomental reflex',
  },
  {
    system: 'http://snomed.info/sct',
    code: '365874001',
    display: 'Parachute reflex',
  },
  {
    system: 'http://snomed.info/sct',
    code: '363828002',
    display: 'Placing reaction',
  },
  {
    system: 'http://snomed.info/sct',
    code: '366221009',
    display: 'Plantar grasp reflex',
  },
  {
    system: 'http://snomed.info/sct',
    code: '18601007',
    display: 'Rooting reflex',
  },
  {
    system: 'http://snomed.info/sct',
    code: '366176007',
    display: 'Snout reflex',
  },
  {
    system: 'http://snomed.info/sct',
    code: '366210000',
    display: 'Stepping reflex',
  },
  {
    system: 'http://snomed.info/sct',
    code: '366187004',
    display: 'Sucking reflex',
  },
  {
    system: 'http://snomed.info/sct',
    code: '366198003',
    display: 'Swallowing reflex',
  },
  {
    system: 'http://snomed.info/sct',
    code: '366243001',
    display: 'Tonic neck reflex',
  },
  {
    system:
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-primitive-reflex-finding-cs',
    code: 'other-primitive-reflex-finding',
    display: 'Other',
  },
].sort((a, b) =>
  a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
)
