/* eslint-disable no-alert */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { BpCollection } from 'models/bpData'
import { GroupedLabResults } from 'models/groupedLabResults'
import { VitalsData } from 'models/vitalsData'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import {
  getSortedObservation,
  getSortedObservationForAddLab,
  getSortedObservationForAddLabForPanel,
  getSortedObservationForIpd,
} from 'utils/appointment_handle/vitals_util'
import {
  getLabTestData,
  getLatestObservations,
  getObserVationFfromEncounterForDateWise,
  getObserVationFfromObserVationDateWise,
  getObserVationFfromObserVationDateWiseForDexaOPD,
  getObserVationFfromObserVationDateWiseForHomOPD,
  getObserVationFfromObserVationDateWiseForLabPanel2OPD,
  getObserVationFfromObserVationDateWiseForLabPanel3OPD,
  getObserVationFfromObserVationDateWiseForLabPanel4OPD,
  getObserVationFfromObserVationDateWiseForLabPanelLftOPD,
  getObserVationFfromObserVationDateWiseForLabPanelOPD,
  getObserVationFfromObserVationDateWiseForLabPanelRftOPD,
  getObserVationFfromObserVationDateWiseForLabTest,
  getObserVationFfromObserVationDateWiseForSerumLytesOPD,
  getPanel1Data,
  getPanel2Data,
  getPanel3Data,
  getPanel4Data,
  getPanelDexaData,
  getPaneLftData,
  getPanelHomaData,
  getPanelSerumLytesData,
  getPanelTftData,
  getPaneRftData,
} from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { getLabResultsPanel } from 'utils/fhirResoureHelpers/observationHelpers'
import { logger } from 'utils/logger'
import { LabTestDetails } from './labTestDetailStatusType'

const initialState: LabTestDetails = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingProcedures: false,
  showAppointment: false,
}

const labTestDetailsSliceOPD = createSlice({
  name: 'labTestDetailsSliceOPD',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<LabTestDetails>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.vitalsList = action.payload.vitalsList
      state.vitalData = action.payload.vitalData
      state.panel1 = action.payload.panel1
      state.panel2 = action.payload.panel2
      state.panel3 = action.payload.panel3
      state.lft = action.payload.lft
      state.rft = action.payload.rft
      state.panel4 = action.payload.panel4
      state.groupedLabTests = action.payload.groupedLabTests
      state.groupedPanel1 = action.payload.groupedPanel1
      state.groupedPanel2 = action.payload.groupedPanel2
      state.groupedPanel3 = action.payload.groupedPanel3
      state.groupedLft = action.payload.groupedLft
      state.groupedRft = action.payload.groupedRft
      state.groupedTft = action.payload.groupedTft
      state.groupedPanel4 = action.payload.groupedPanel4
      state.groupedHoma = action.payload.groupedHoma
      state.groupedDexa = action.payload.groupedDexa
      state.groupedSerumLytes = action.payload.groupedSerumLytes
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
      state.showAppointment = action.payload.showAppointment
    },
  },
})

export const requestLabTestDetailsDetailsOfPatient =
  (selectedPatient: R4.IPatient, appointmentId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: LabTestDetails = {
      searchingConditions: true,
      errorWhileSearchingProcedures: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      showAppointment: false,
    }
    dispatch(labTestDetailsSliceOPD.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        patient: `Patient/${selectedPatient.id}`,
        'encounter.appointment': appointmentId,
        category: 'laboratory',
        _total: 'accurate',
        _count: 500,
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/Observation?_sort=_lastUpdated',
        appointmentId,
        searchParameters
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingProcedures = true
        state.searchingConditions = false
        state.showAppointment = false

        dispatch(labTestDetailsSliceOPD.actions.updatedStatus(state))
      } else {
        const proceduresResponse: R4.IBundle = resp.right
        if (proceduresResponse?.total && proceduresResponse?.total > 0) {
          const observationData: R4.IObservation[] =
            proceduresResponse.entry?.map(
              (item) => item.resource as R4.IObservation
            ) ?? [].sort()

          observationData.sort((a, b) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )

          const finalObservationData: R4.IObservation[] =
            getLatestObservations(observationData)

          const vitalLisDataFinal: VitalsData[] =
            getObserVationFfromObserVationDateWiseForLabTest(
              getSortedObservationForAddLab(finalObservationData)
            )

          const groupedLabTestResult: GroupedLabResults[] = getLabResultsPanel(
            getLabTestData(finalObservationData),
            'labTest'
          )

          const groupedLabPanelResult: GroupedLabResults[] = getLabResultsPanel(
            getPanel1Data(finalObservationData),
            'panel1'
          )

          const groupedLabPanel2Result: GroupedLabResults[] =
            getLabResultsPanel(getPanel2Data(finalObservationData), 'panel2')

          const groupedLabPanel3Result: GroupedLabResults[] =
            getLabResultsPanel(getPanel3Data(finalObservationData), 'panel3')

          const groupedLftResult: GroupedLabResults[] = getLabResultsPanel(
            getPaneLftData(finalObservationData),
            'lft'
          )
          const groupedRftResult: GroupedLabResults[] = getLabResultsPanel(
            getPaneRftData(finalObservationData),
            'rft'
          )

          const groupedHomaResult: GroupedLabResults[] = getLabResultsPanel(
            getPanelHomaData(finalObservationData),
            'Homa'
          )
          const groupedDexaResult: GroupedLabResults[] = getLabResultsPanel(
            getPanelDexaData(finalObservationData),
            'Dexa'
          )

          const groupedSerumLytesResult: GroupedLabResults[] =
            getLabResultsPanel(
              getPanelSerumLytesData(finalObservationData),
              'Serum Lytes'
            )

          const groupedTFTResult: GroupedLabResults[] = getLabResultsPanel(
            getPanelTftData(finalObservationData),
            'TFT'
          )

          const groupedLabPanel4Result: GroupedLabResults[] =
            getLabResultsPanel(getPanel4Data(finalObservationData), 'urine')

          const panel1: VitalsData[] =
            getObserVationFfromObserVationDateWiseForLabPanelOPD(
              getSortedObservationForAddLabForPanel(finalObservationData)
            )
          const panel12: VitalsData[] =
            getObserVationFfromObserVationDateWiseForLabPanel2OPD(
              getSortedObservationForAddLabForPanel(finalObservationData)
            )
          const panel13: VitalsData[] =
            getObserVationFfromObserVationDateWiseForLabPanel3OPD(
              getSortedObservationForAddLabForPanel(finalObservationData)
            )

          const lft: VitalsData[] =
            getObserVationFfromObserVationDateWiseForLabPanelLftOPD(
              getSortedObservationForAddLabForPanel(finalObservationData)
            )

          const rft: VitalsData[] =
            getObserVationFfromObserVationDateWiseForLabPanelRftOPD(
              getSortedObservationForAddLabForPanel(finalObservationData)
            )

          const panel14: VitalsData[] =
            getObserVationFfromObserVationDateWiseForLabPanel4OPD(
              getSortedObservationForAddLabForPanel(finalObservationData)
            )

          const homa: VitalsData[] =
            getObserVationFfromObserVationDateWiseForHomOPD(
              getSortedObservationForAddLabForPanel(finalObservationData)
            )

          const dexa: VitalsData[] =
            getObserVationFfromObserVationDateWiseForDexaOPD(
              getSortedObservationForAddLabForPanel(finalObservationData)
            )

          const serumLytes: VitalsData[] =
            getObserVationFfromObserVationDateWiseForSerumLytesOPD(
              getSortedObservationForAddLabForPanel(finalObservationData)
            )

          let observation: any = {}
          for (let i = 0; i < observationData.length; i++) {
            if (observationData[i].component !== undefined) {
              observation = observationData[i]
            }
          }
          if (observationData.length > 0) {
            const serviceId: string = await getServiceRequestId(
              selectedPatient.id ?? ''
            )
            if (serviceId.length > 0) {
              if (observation.component !== undefined) {
                const sysComp: R4.IObservation_Component | undefined =
                  observation.component.find(
                    (val: any) => val.code?.coding?.[0].code === '8480-6'
                  )
                const diaComp: R4.IObservation_Component | undefined =
                  observation.component.find(
                    (val: any) => val.code?.coding?.[0].code === '8462-4'
                  )
                const vitalData: BpCollection = {
                  systolicBp: sysComp ? sysComp.valueQuantity?.value ?? 0 : 0,
                  diastolicBp: diaComp ? diaComp.valueQuantity?.value ?? 0 : 0,
                  serviceRequestId: serviceId,
                }
                const status = await updateEnrolment(vitalData)

                state.resultsAvailable = true
                state.searchingConditions = false
                state.vitalsList = getSortedObservationForIpd(
                  proceduresResponse.entry?.map(
                    (item) => item.resource as R4.IObservation
                  ) ?? []
                )
                state.noResultsAvailable = false
                state.errorReason = undefined
                state.vitalData = vitalLisDataFinal
                state.panel1 = panel1
                state.panel2 = panel12
                state.panel3 = panel13
                state.lft = lft
                state.rft = rft
                state.panel4 = panel14
                state.groupedLabTests = groupedLabTestResult
                state.errorWhileSearchingProcedures = false
                state.showAppointment = false
                dispatch(labTestDetailsSliceOPD.actions.updatedStatus(state))
              }
            } else if (
              vitalLisDataFinal.length > 0 ||
              panel1.length > 0 ||
              panel12.length > 0 ||
              panel13.length > 0 ||
              panel14.length > 0 ||
              lft.length > 0 ||
              rft.length > 0 ||
              homa.length > 0 ||
              dexa.length > 0 ||
              serumLytes.length > 0
            ) {
              const vitalLisData: R4.IObservation[] =
                proceduresResponse.entry?.map(
                  (item) => item.resource as R4.IObservation
                ) ?? []
              //
              state.resultsAvailable = true
              state.searchingConditions = false
              state.vitalsList = getSortedObservationForIpd(vitalLisData)
              state.noResultsAvailable = false
              state.vitalData = vitalLisDataFinal
              state.panel1 = panel1
              state.panel2 = panel12
              state.panel3 = panel13
              state.panel4 = panel14
              state.lft = lft
              state.rft = rft
              state.groupedLabTests = groupedLabTestResult
              state.groupedPanel1 = groupedLabPanelResult
              state.groupedPanel2 = groupedLabPanel2Result
              state.groupedPanel3 = groupedLabPanel3Result
              state.groupedLft = groupedLftResult
              state.groupedRft = groupedRftResult
              state.groupedTft = groupedTFTResult
              state.groupedHoma = groupedHomaResult
              state.groupedDexa = groupedDexaResult
              state.groupedSerumLytes = groupedSerumLytesResult
              state.groupedPanel4 = groupedLabPanel4Result
              state.errorReason = undefined
              state.errorWhileSearchingProcedures = false
              dispatch(labTestDetailsSliceOPD.actions.updatedStatus(state))
            } else {
              const errorSearchDoctor: LabTestDetails = {
                searchingConditions: false,
                errorWhileSearchingProcedures: false,
                resultsAvailable: false,
                noResultsAvailable: true,
                showAppointment: false,
              }
              dispatch(
                labTestDetailsSliceOPD.actions.updatedStatus(errorSearchDoctor)
              )
            }
          } else {
            const errorSearchDoctor: LabTestDetails = {
              searchingConditions: false,
              errorWhileSearchingProcedures: false,
              resultsAvailable: false,
              noResultsAvailable: true,
              showAppointment: false,
            }
            dispatch(
              labTestDetailsSliceOPD.actions.updatedStatus(errorSearchDoctor)
            )
          }
        } else {
          const errorSearchDoctor: LabTestDetails = {
            searchingConditions: false,
            errorWhileSearchingProcedures: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            showAppointment: false,
          }
          dispatch(
            labTestDetailsSliceOPD.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: LabTestDetails = {
        searchingConditions: false,
        errorWhileSearchingProcedures: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
        showAppointment: false,
      }
      dispatch(labTestDetailsSliceOPD.actions.updatedStatus(errorSearchDoctor))
    }
  }

async function updateEnrolment(bpCollection: BpCollection): Promise<string> {
  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `/enrolment/update-bp`,
      bpCollection
    )
    return response.BPCategory
  } catch (error) {
    return ''
  }
}

async function updateAppointment(): Promise<string> {
  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const enRolClient: EnrolCient = new EnrolCient()
  //   const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
  //     `/enrolment/update-bp`,
  //     bpCollection
  //   )

  return 'test'
}

async function getServiceRequestId(patientId: string): Promise<string> {
  logger.info('Patient invitation body')
  let serviceRequestId: string = ''
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirClient.doGetResource(
    `/ServiceRequest??code=chronic-care-enrolment&subject=${patientId}`
  )
  logger.info('Patient invitation Response')
  logger.info(response)
  const respDecoded: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (respDecoded._tag === 'Right') {
    logger.info('Response Patient un decoded', respDecoded.right)
    const responseBundel: R4.IBundle = respDecoded.right
    if (responseBundel.entry) {
      const entryObj = responseBundel.entry?.[0].resource as R4.IServiceRequest
      serviceRequestId =
        entryObj && entryObj.status === 'active' ? entryObj.id ?? ' ' : ''
      return serviceRequestId
    }
    return serviceRequestId
  }

  return serviceRequestId
}

export default labTestDetailsSliceOPD.reducer
