/* eslint-disable consistent-return */
/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { CloseOutlined, DeleteOutlineOutlined } from '@material-ui/icons'
import {
  DateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Attendant } from 'models/attendant'
import { Sadhka } from 'models/sadhka'
import { SadhkaResponse } from 'models/sadkahResponse'
import { UnitServices } from 'models/units/unitServices'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiUpload, FiVideo } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import {
  finshIpdOnboarding,
  resetStateForIPdFinish,
} from 'redux/clinic/ipdmanagement/ipdFinish/ipdFinshSlice'
import {
  finshIpdOnboardingForSadhka,
  resetStateForIPdFinishSadhka,
} from 'redux/clinic/ipdmanagement/ipdFinsihSadhka/ipdSadhkaFinishSlice'
import {
  registerIpdPatient,
  resetMainPatientIpdState,
} from 'redux/clinic/ipdmanagement/ipdRegister/ipdRegisterSlice'
import {
  registerIpdSadhka,
  resetAddSadhka,
} from 'redux/clinic/ipdmanagement/IpdSadhkaAdd/ipdSadhkaSlice'
import { requestForDateWiseIpdAppointmentLists } from 'redux/clinic/ipdmanagement/ipdSearch/ipdSearchSlice'
import { resetUpdateUserState } from 'redux/patient/addVisaDetails/addVisaDetailsSlice'
import { RootState } from 'redux/rootReducer'
import {
  answerOptions,
  purposeOfVisit,
  questions,
  roomTypes,
} from 'utils/constants'
import { IdProofType } from 'utils/constants/proof_types'
import {
  getAgeOfAttendantInYears,
  getAgeOfPatientInYears,
  getExtensionValueOfUrlPatient,
  getFileSizeInMB,
} from 'utils/fhirResourcesHelper'
import { getDateDifferceinDays } from 'utils/fhirResoureHelpers/ipdHelper'
import { isCurrentDate } from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import {
  validateAttenDantDob,
  validateCheckoutDate,
  validateDobForAttendant,
} from 'utils/formValidators'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { PatientTile } from 'views/components/patients/pateintTile'
import { PatientSearchByText } from 'views/components/patients/patientSearchByText'
import {
  validateEmail,
  WelloDateInput,
  WelloEmailField,
  WelloFormItemLabel,
  WelloGenderSelector,
  WelloTextField,
} from 'wello-web-components'
import { WebCamCapturePhoto } from '../administration/actors/micro-components/webcam_capture'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from '../common/deleteConfirmationDialog'
import { WelloSelectFHIRSadhka } from '../lab/common/WelloSelectSadhka'
import { NoteDialog } from '../LeftMenu/TextDialog'
import { WelloFilePicker } from '../LeftMenu/WelloFilePicker'
import { WelloPhoneNumberField } from '../LeftMenu/WelloPhoneNumberField'
import { SadhakSearchByText } from '../patients/sadhkaSearchByText'
import { AddVisaDetails } from './addVisaDetails'
import { ConsentDialog } from './consentDialog'
import { IpdPriceSummary } from './ipdPriceSummary'
import { RoomAndBedSelector } from './roomAndBedSelector'

const useStyles = makeStyles((theme) => ({
  datepicker: {
    margin: '2px 0px 2px 0px',
    height: '60px',
    // width: 'fit-content',
    padding: '20px 15px 10px',
    borderBottom: '2px solid blue',
    backgroundColor: '#fff',
    color: '#2c2c2c',
    width: 300,
    fontWeight: 600,
  },
}))

interface Props {
  open: boolean
  id: number
  onClose: () => void
  onLabOrderCreated: (ipdId: string, paymentType: string) => void
  locationId?: string
  partnerLabUser?: R4.IPractitionerRole
  preselectedPatient?: R4.IPatient
  referralId?: string
}
export const CreateIpdAppointmentHandlerPreAppointment: React.FC<Props> = ({
  open,
  id,
  onClose,
  onLabOrderCreated,
  locationId,
  partnerLabUser,
  preselectedPatient,
  referralId,
}) => {
  const ipdRegisterSlice = useSelector(
    (state: RootState) => state.ipdRegisterSlice
  )
  const [openWebCam, setOpenWebCam] = useState<boolean>(false)

  const roomNumberSearchSlice = useSelector(
    (state: RootState) => state.roomNoSearchSlice
  )
  const IpdRegisterSadhkaSlice = useSelector(
    (state: RootState) => state.IpdRegisterSadhkaSlice
  )

  const classes = useStyles()
  const ipdFinsih = useSelector((state: RootState) => state.ipdFinsih)

  const ipdFinsihSadhka = useSelector(
    (state: RootState) => state.ipdFinsihSadhka
  )

  const unitsOfOrganizationSlice = useSelector(
    (state: RootState) => state.unitsOfOrganizationSlice.unitServices
  )

  let unitServices: UnitServices[] = []

  if (unitsOfOrganizationSlice && unitsOfOrganizationSlice.length > 0) {
    unitServices = unitsOfOrganizationSlice
  }

  const [attendant, setAttenDant] = useState<Attendant[]>([
    {
      name: '',
      email: '',
      phone: '',
      gender: '',
      dob: '',
      idProofTypeData: IdProofType[0].code!,
      idProofNumber: '',
      imageFront: '',
      imageFrontBack: '',
      addressProofTypeFrontPath: '',
      addressProofTypeFrontMimeType: '',
      addressProofTypeFrontName: '',
      addressProofTypeBackPath: '',
      addressProofTypeBackMimeType: '',
      addressProofTypeBackFileName: '',
      bedReferenceId: '',
      addressProofTypeBoth: '',
      addressProofTypeBothSize: 0,
      addressProofTypeBothMimeType: '',
      addressProofTypeBothName: '',
      addressProofTypeFrontSize: 0,
      addressProofTypeBackSize: 0,
    },
  ])
  const [sadhakData, setSadhakData] = useState<Sadhka[]>([
    {
      id: 0,
      patient: undefined,
    },
  ])

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const [fullText, setFullText] = useState<string>('')
  const [showText, setShowText] = useState<boolean>(false)

  const [showConditions, setShowConditions] = useState<boolean>(false)
  const [selectedServiceMedicine, setSelectedServiceMedicine] =
    useState<R4.ICoding>()

  const [fullTextForPatient, setFullTextForPatient] = useState<string>('')
  const [showTextForPatient, setShowTextForPatient] = useState<boolean>(false)

  let columns: string[] = []

  const [selectedPatient, setSelectedPatient] =
    useState<R4.IPatient | undefined>(preselectedPatient)
  const [isPatientEditMode, setIsPatientEditMode] = useState<boolean>(
    preselectedPatient === undefined
  )
  const [serviceRequest, setServiceRequest] = useState<string>()
  const [visaDetails, setVisaDetails] = useState<boolean>(false)
  const [visaDataFinal, setVisaDataFinal] = useState<string>()

  const [sadhaFinsh, setSadhakFinish] = useState<SadhkaResponse>()

  const sadhKaRef = useRef<SadhkaResponse>()

  let sadhakModelData: SadhkaResponse[] = []
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  const [value, setValue] = useState('file')
  const [photoType, setPhotoType] = useState('both')

  const res = sessionStorage.getItem('sadhakList')
  if (res !== null) sadhakModelData = JSON.parse(res)

  const [selectedRoomType, setSelectedRoomType] = useState<R4.ICoding>()
  const [selectedCharef, setSelectedChargeDef] =
    useState<R4.IChargeItemDefinition>()
  const [selectedVistPurpose, setSelectedVisitPurpose] = useState<R4.ICoding>(
    purposeOfVisit[0]
  )
  const [count, setCount] = React.useState<number>(0)
  const [addShadka, setAddShadka] = useState<boolean>(false)
  const [discount, setDiscount] = React.useState<number>(0)
  const [roomNo, setRoomNo] = React.useState<string>()
  const [startDate, setStartDate] = React.useState(new Date())
  const [endDate, setEndDate] = React.useState(moment().format('YYYY-MM-DD'))
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [travelDate1, setTravelDate1] = React.useState<string>(
    moment().subtract(2, 'days').format('YYYY-MM-DD')
  )
  const [patientCountry, setPatientCountry] = React.useState<string>('')
  const [travelDate2, setTravelDate2] = React.useState<string>(
    moment().subtract(2, 'days').format('YYYY-MM-DD')
  )
  const [travelDate3, setTravelDate3] = React.useState<string>(
    moment().subtract(2, 'days').format('YYYY-MM-DD')
  )

  const [checkedForForegin, setCheckedForForeign] = useState<boolean>(false)

  const [travelDate4, setTravelDate4] = React.useState<string>(
    moment().subtract(2, 'days').format('YYYY-MM-DD')
  )
  const [travelDate5, setTravelDate5] = React.useState<string>(
    moment().subtract(2, 'days').format('YYYY-MM-DD')
  )

  const [foreign, setForeign] = useState<boolean>(true)
  const [travelDate6, setTravelDate6] = React.useState<string>(
    moment().subtract(2, 'days').format('YYYY-MM-DD')
  )
  const [selectedSadhka, setSelectedSadhka] = React.useState<R4.IPatient[]>([])
  const [questionResponse, setQuestionResponse] = React.useState<
    R4.IQuestionnaireResponse_Item[]
  >([
    {
      linkId: questions.item[0].linkId,
      text: questions.item[0].text,
    },
  ])
  const [questionResponse1, setQuestionResponse1] = React.useState<
    R4.IQuestionnaireResponse_Item[]
  >([])
  const [questionResponse3, setQuestionResponse3] = React.useState<
    R4.IQuestionnaireResponse_Item[]
  >([])
  const [questionResponse4, setQuestionResponse4] = React.useState<
    R4.IQuestionnaireResponse_Item[]
  >([])
  const [questionResponse2, setQuestionResponse2] = React.useState<
    R4.IQuestionnaireResponse_Item[]
  >([
    {
      linkId: '3',
      text: 'I have been in close contact with a confirmed case of COVID 19 in the last 21 days',
    },
  ])

  const [innerQuestionResponse1, setInnerQuestionResponse1] =
    React.useState<R4.IQuestionnaireResponse_Item>({
      linkId: questions.item[0].item[0].linkId,
      text: questions.item[0].item[0].text,
      answer: [
        {
          valueCoding: answerOptions[0],
        },
      ],
    })

  const [innerQuestionResponse2, setInnerQuestionResponse2] =
    React.useState<R4.IQuestionnaireResponse_Item>({
      linkId: questions.item[0].item[1].linkId,
      text: questions.item[0].item[1].text,
      answer: [
        {
          valueCoding: answerOptions[0],
        },
      ],
    })
  const [innerQuestionResponse3, setInnerQuestionResponse3] =
    React.useState<R4.IQuestionnaireResponse_Item>({
      linkId: questions.item[0].item[2].linkId,
      text: questions.item[0].item[2].text,
      answer: [
        {
          valueCoding: answerOptions[0],
        },
      ],
    })
  const [innerQuestionResponse4, setInnerQuestionResponse4] =
    React.useState<R4.IQuestionnaireResponse_Item>({
      linkId: questions.item[0].item[3].linkId,
      text: questions.item[0].item[3].text,
      answer: [
        {
          valueCoding: answerOptions[0],
        },
      ],
    })
  const [innerQuestionResponse5, setInnerQuestionResponse5] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse6, setInnerQuestionResponse6] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse7, setInnerQuestionResponse7] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse8, setInnerQuestionResponse8] =
    React.useState<R4.IQuestionnaireResponse_Item>({
      linkId: '3.1',
      text: 'I have been in close contact with a confirmed case of COVID 19 in the last 21 days',
      answer: [
        {
          valueCoding: answerOptions[0],
        },
      ],
    })
  const [innerQuestionResponse9, setInnerQuestionResponse9] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse10, setInnerQuestionResponse10] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse11, setInnerQuestionResponse11] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse12, setInnerQuestionResponse12] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse13, setInnerQuestionResponse13] =
    React.useState<R4.IQuestionnaireResponse_Item>()
  const [innerQuestionResponse14, setInnerQuestionResponse14] =
    React.useState<R4.IQuestionnaireResponse_Item>()

  const [checked, setChecked] = React.useState(false)

  const [callBackForCapturePhoto, setCallBackForCapturePhoto] = React.useState(
    () => (imageName: any, imageDataType: any, imageUri: any) => {}
  )

  const handleChange = (event: any) => {
    setChecked(event.target.checked)
  }
  function getSelectedBeds() {
    const selectedBeds: string[] = []
    if (attendant && attendant.length > 0)
      attendant.forEach((e) => {
        if (e.bedReferenceId) {
          selectedBeds.push(e.bedReferenceId)
        }
      })
    if (sadhakData && sadhakData.length > 0)
      sadhakData.forEach((e) => {
        if (e.bedId) {
          selectedBeds.push(e.bedId)
        }
      })

    return selectedBeds
  }

  function checkForDuplicateBeds() {
    const selectedBeds: string[] = []
    if (attendant && attendant.length > 0)
      attendant.forEach((e) => {
        if (e.bedReferenceId) {
          selectedBeds.push(e.bedReferenceId)
        }
      })
    if (sadhakData && sadhakData.length > 0)
      sadhakData.forEach((e) => {
        if (e.bedId) {
          selectedBeds.push(e.bedId)
        }
      })
    const set = new Set(selectedBeds)

    // eslint-disable-next-line array-callback-return
    const duplicates = selectedBeds.filter((item) => {
      if (set.has(item)) {
        set.delete(item)
      } else {
        return item
      }
    })
    if (duplicates.length > 0) {
      return true
    }
    return false
  }

  const handleChangeForForegin = (event: any) => {
    setCheckedForForeign(event.target.checked)
    setVisaDetails(true)
  }

  function isSaveSadhkaEnabled(): boolean | undefined {
    const arr: string[] = []
    const arr1: string[] = []
    if (sadhakData.length > 0) {
      for (let i = 0; i < sadhakData.length; i++) {
        if (sadhakData[i].bedId === undefined) {
          arr1.push('12')
        }
        if (sadhakData[i].patient && sadhakData[i].bedId) {
          if (
            getAgeOfPatientInYears(sadhakData[i].patient!) < 12 ||
            getAgeOfPatientInYears(sadhakData[i].patient!) > 80
          ) {
            arr.push('1')
          }
        }
      }
    }
    if (arr.length > 0 || arr1.length > 0) return true
    return false
  }

  function isEnableCreateButton(): boolean | undefined {
    if (
      selectedPatient &&
      selectedRoomType &&
      selectedCharef &&
      roomNo &&
      roomNo.length > 0 &&
      getAgeOfPatientInYears(selectedPatient) >= 12 &&
      getAgeOfPatientInYears(selectedPatient) <= 80 &&
      endDate.length > 0 &&
      validateCheckoutDate(
        moment(startDate).format('YYYY-MM-DD'),
        endDate,
        false
      ).length === 0
    ) {
      return true
    }
    return false
  }

  function isFinsihButtonEnabled(): boolean | undefined {
    if (
      questionResponse.length > 0 &&
      questionResponse2.length > 0 &&
      innerQuestionResponse1 &&
      innerQuestionResponse2 &&
      innerQuestionResponse3 &&
      innerQuestionResponse4 &&
      innerQuestionResponse8 &&
      validateEmail(attendant[0].email, false).length === 0 &&
      validateAttenDantDob(attendant[0].dob).length === 0 &&
      getFileSizeInMB(attendant[0].addressProofTypeBothSize) <= 5 &&
      getFileSizeInMB(attendant[0].addressProofTypeFrontSize) <= 5 &&
      getFileSizeInMB(attendant[0].addressProofTypeBackSize) <= 5 &&
      checked
    ) {
      return true
    }
    return false
  }

  function isAttendantsDataFilled(): boolean {
    if (
      (attendant[0].email.length > 0 ||
        attendant[0].phone.length > 0 ||
        attendant[0].gender.length > 0 ||
        attendant[0].dob.length > 0 ||
        attendant[0].addressProofTypeBoth.length > 0 ||
        attendant[0].addressProofTypeBackPath.length > 0 ||
        attendant[0].addressProofTypeFrontPath.length > 0 ||
        attendant[0].bedReferenceId ||
        attendant[0].idProofNumber.length > 0) &&
      attendant[0].name.length === 0
    ) {
      return true
    }
    return false
  }

  function isAttendantsIdProofFilled(): boolean {
    if (
      (attendant[0].email.length > 0 ||
        attendant[0].phone.length > 0 ||
        attendant[0].gender.length > 0 ||
        attendant[0].dob.length > 0 ||
        attendant[0].bedReferenceId ||
        attendant[0].name.length > 0) &&
      attendant[0].idProofNumber.length === 0
    ) {
      return true
    }
    return false
  }

  function isBackBothImageUploaded(): boolean {
    if (
      (attendant[0].email.length > 0 ||
        attendant[0].phone.length > 0 ||
        attendant[0].gender.length > 0 ||
        attendant[0].dob.length > 0 ||
        attendant[0].name.length > 0 ||
        attendant[0].bedReferenceId ||
        attendant[0].idProofNumber.length > 0) &&
      attendant[0].addressProofTypeBoth.length === 0
    ) {
      return true
    }
    return false
  }

  function isFrontImageUploaded(): boolean {
    if (
      (attendant[0].email.length > 0 ||
        attendant[0].phone.length > 0 ||
        attendant[0].gender.length > 0 ||
        attendant[0].dob.length > 0 ||
        attendant[0].name.length > 0 ||
        attendant[0].addressProofTypeBackPath.length >= 0 ||
        attendant[0].bedReferenceId ||
        attendant[0].idProofNumber.length > 0) &&
      attendant[0].addressProofTypeFrontPath.length === 0
    ) {
      return true
    }
    return false
  }

  function isBackImageUploaded(): boolean {
    if (
      (attendant[0].email.length > 0 ||
        attendant[0].phone.length > 0 ||
        attendant[0].gender.length > 0 ||
        attendant[0].dob.length > 0 ||
        attendant[0].name.length > 0 ||
        attendant[0].addressProofTypeBoth.length === 0 ||
        attendant[0].addressProofTypeFrontPath.length >= 0 ||
        attendant[0].bedReferenceId ||
        attendant[0].idProofNumber.length > 0) &&
      attendant[0].addressProofTypeBackPath.length === 0
    ) {
      return true
    }
    return false
  }

  function isAttendantGenerNotDataFilled(): boolean {
    if (
      (attendant[0].email.length > 0 ||
        attendant[0].phone.length > 0 ||
        attendant[0].name.length > 0 ||
        attendant[0].dob.length > 0 ||
        attendant[0].addressProofTypeBoth.length > 0 ||
        attendant[0].addressProofTypeBackPath.length > 0 ||
        attendant[0].addressProofTypeFrontPath.length > 0 ||
        attendant[0].idProofNumber.length > 0 ||
        attendant[0].bedReferenceId) &&
      attendant[0].gender.length === 0
    ) {
      return true
    }
    return false
  }

  function isRoomDataFilled(): boolean {
    if (
      attendant[0].name.length > 0 &&
      attendant[0].dob.length > 0 &&
      attendant[0].bedReferenceId?.length === 0 &&
      attendant[0].gender.length > 0
    ) {
      return true
    }
    return false
  }

  function isCovidDataFilledForOne(): boolean {
    if (
      ((innerQuestionResponse5 || innerQuestionResponse7) &&
        innerQuestionResponse6 === undefined) ||
      (innerQuestionResponse6 &&
        innerQuestionResponse6.answer &&
        innerQuestionResponse6.answer[0] &&
        innerQuestionResponse6.answer[0].valueString === undefined) ||
      ((innerQuestionResponse5 || innerQuestionResponse7) &&
        innerQuestionResponse6 &&
        innerQuestionResponse6.answer &&
        innerQuestionResponse6.answer[0] &&
        innerQuestionResponse6.answer[0].valueString?.length === 0) ||
      (innerQuestionResponse6 &&
        innerQuestionResponse6.answer &&
        innerQuestionResponse6.answer[0].valueString &&
        innerQuestionResponse5 === undefined) ||
      (innerQuestionResponse6 &&
        innerQuestionResponse6.answer &&
        innerQuestionResponse6.answer[0] &&
        innerQuestionResponse6.answer[0].valueString?.length === 0 &&
        innerQuestionResponse7 &&
        innerQuestionResponse5 === undefined) ||
      (innerQuestionResponse6 &&
        innerQuestionResponse6.answer === undefined &&
        innerQuestionResponse7 &&
        innerQuestionResponse5 === undefined) ||
      (innerQuestionResponse6 === undefined &&
        innerQuestionResponse7 &&
        innerQuestionResponse5 === undefined) ||
      (innerQuestionResponse6 &&
        innerQuestionResponse6.answer &&
        innerQuestionResponse6.answer[0].valueString &&
        innerQuestionResponse7 === undefined) ||
      (innerQuestionResponse6 &&
        innerQuestionResponse6.answer &&
        innerQuestionResponse6.answer[0] &&
        innerQuestionResponse6.answer[0].valueString?.length === 0 &&
        innerQuestionResponse5 &&
        innerQuestionResponse7 === undefined) ||
      (innerQuestionResponse6 &&
        innerQuestionResponse6.answer === undefined &&
        innerQuestionResponse5 &&
        innerQuestionResponse7 === undefined) ||
      (innerQuestionResponse6 === undefined &&
        innerQuestionResponse5 &&
        innerQuestionResponse7 === undefined)
    ) {
      return true
    }
    return false
  }

  function isCovidDataFilledForTwo(): boolean {
    if (
      (innerQuestionResponse9 &&
        innerQuestionResponse9.answer &&
        innerQuestionResponse9.answer[0] &&
        innerQuestionResponse9.answer[0].valueString === undefined) ||
      ((innerQuestionResponse10 || innerQuestionResponse11) &&
        innerQuestionResponse9 &&
        innerQuestionResponse9.answer &&
        innerQuestionResponse9.answer[0] &&
        innerQuestionResponse9.answer[0].valueString?.length === 0) ||
      (innerQuestionResponse9 &&
        innerQuestionResponse9.answer &&
        innerQuestionResponse9.answer[0].valueString &&
        innerQuestionResponse10 === undefined) ||
      (innerQuestionResponse9 &&
        innerQuestionResponse9.answer &&
        innerQuestionResponse9.answer[0] &&
        innerQuestionResponse9.answer[0].valueString?.length === 0 &&
        innerQuestionResponse11 &&
        innerQuestionResponse10 === undefined) ||
      (innerQuestionResponse9 &&
        innerQuestionResponse9.answer === undefined &&
        innerQuestionResponse11 &&
        innerQuestionResponse10 === undefined) ||
      (innerQuestionResponse9 === undefined &&
        innerQuestionResponse11 &&
        innerQuestionResponse10 === undefined) ||
      (innerQuestionResponse9 &&
        innerQuestionResponse9.answer &&
        innerQuestionResponse9.answer[0].valueString &&
        innerQuestionResponse11 === undefined) ||
      (innerQuestionResponse9 &&
        innerQuestionResponse9.answer &&
        innerQuestionResponse9.answer[0] &&
        innerQuestionResponse9.answer[0].valueString?.length === 0 &&
        innerQuestionResponse10 &&
        innerQuestionResponse11 === undefined) ||
      (innerQuestionResponse9 &&
        innerQuestionResponse9.answer === undefined &&
        innerQuestionResponse10 &&
        innerQuestionResponse11 === undefined) ||
      (innerQuestionResponse9 === undefined &&
        innerQuestionResponse10 &&
        innerQuestionResponse11 === undefined) ||
      ((innerQuestionResponse10 || innerQuestionResponse11) &&
        innerQuestionResponse9 === undefined)
    ) {
      return true
    }
    return false
  }

  function isCovidDataFilledForThree(): boolean {
    if (
      (innerQuestionResponse12 &&
        innerQuestionResponse12.answer &&
        innerQuestionResponse12.answer[0] &&
        innerQuestionResponse12.answer[0].valueString === undefined) ||
      ((innerQuestionResponse13 || innerQuestionResponse14) &&
        innerQuestionResponse12 &&
        innerQuestionResponse12.answer &&
        innerQuestionResponse12.answer[0] &&
        innerQuestionResponse12.answer[0].valueString?.length === 0) ||
      (innerQuestionResponse12 &&
        innerQuestionResponse12.answer &&
        innerQuestionResponse12.answer[0].valueString &&
        innerQuestionResponse14 === undefined) ||
      (innerQuestionResponse12 &&
        innerQuestionResponse12.answer &&
        innerQuestionResponse12.answer[0] &&
        innerQuestionResponse12.answer[0].valueString?.length === 0 &&
        innerQuestionResponse13 &&
        innerQuestionResponse14 === undefined) ||
      (innerQuestionResponse12 &&
        innerQuestionResponse12.answer === undefined &&
        innerQuestionResponse13 &&
        innerQuestionResponse14 === undefined) ||
      (innerQuestionResponse12 === undefined &&
        innerQuestionResponse13 &&
        innerQuestionResponse14 === undefined) ||
      (innerQuestionResponse12 &&
        innerQuestionResponse12.answer &&
        innerQuestionResponse12.answer[0] &&
        innerQuestionResponse12.answer[0].valueString?.length === 0 &&
        innerQuestionResponse13 &&
        innerQuestionResponse14 === undefined) ||
      (innerQuestionResponse12 &&
        innerQuestionResponse12.answer === undefined &&
        innerQuestionResponse13 &&
        innerQuestionResponse14 === undefined) ||
      (innerQuestionResponse12 === undefined &&
        innerQuestionResponse13 &&
        innerQuestionResponse14 === undefined) ||
      (innerQuestionResponse12 &&
        innerQuestionResponse12.answer === undefined &&
        innerQuestionResponse13 &&
        innerQuestionResponse14 === undefined) ||
      ((innerQuestionResponse13 || innerQuestionResponse14) &&
        innerQuestionResponse12 === undefined)
    ) {
      return true
    }
    return false
  }

  function isRoomDataNotFilled(): boolean {
    if (
      attendant[0].name.length > 0 &&
      attendant[0].dob.length > 0 &&
      attendant[0].bedReferenceId?.length === 0 &&
      attendant[0].gender.length > 0
    ) {
      return true
    }
    return false
  }

  function isAttendantDOBNotDataFilled(): boolean {
    if (
      (attendant[0].email.length > 0 ||
        attendant[0].phone.length > 0 ||
        attendant[0].name.length > 0 ||
        attendant[0].gender.length > 0 ||
        attendant[0].addressProofTypeBoth.length > 0 ||
        attendant[0].addressProofTypeBackPath.length > 0 ||
        attendant[0].addressProofTypeFrontPath.length > 0 ||
        attendant[0].idProofNumber.length > 0 ||
        attendant[0].bedReferenceId) &&
      attendant[0].dob.length === 0
    ) {
      return true
    }
    return false
  }

  const resData = sessionStorage.getItem('selecttedNames')
  if (resData !== null) columns = JSON.parse(resData)

  useEffect(() => {
    if (ipdRegisterSlice.additionSuccessful) {
      sadhKaRef.current = undefined
      setServiceRequest(ipdRegisterSlice.serviceRequest ?? '')
    }
    if (ipdFinsihSadhka.additionSuccessful) {
      setChecked(false)
      setSadhakFinish(undefined)
      setTravelDate1('')
      setTravelDate2('')
      setTravelDate3('')
      setTravelDate4('')
      setTravelDate5('')
      setTravelDate6('')
      setQuestionResponse([
        {
          linkId: questions.item[0].linkId,
          text: questions.item[0].text,
        },
      ])
      setQuestionResponse1([])
      setQuestionResponse2([
        {
          linkId: '3',
          text: 'I have been in close contact with a confirmed case of COVID 19 in the last 21 days',
        },
      ])
      setInnerQuestionResponse1({
        linkId: questions.item[0].item[0].linkId,
        text: questions.item[0].item[0].text,
        answer: [
          {
            valueCoding: answerOptions[0],
          },
        ],
      })
      setInnerQuestionResponse2({
        linkId: questions.item[0].item[1].linkId,
        text: questions.item[0].item[1].text,
        answer: [
          {
            valueCoding: answerOptions[0],
          },
        ],
      })
      setInnerQuestionResponse3({
        linkId: questions.item[0].item[2].linkId,
        text: questions.item[0].item[2].text,
        answer: [
          {
            valueCoding: answerOptions[0],
          },
        ],
      })
      setInnerQuestionResponse4({
        linkId: questions.item[0].item[3].linkId,
        text: questions.item[0].item[3].text,
        answer: [
          {
            valueCoding: answerOptions[0],
          },
        ],
      })
      setInnerQuestionResponse8({
        linkId: '3.1',
        text: 'I have been in close contact with a confirmed case of COVID 19 in the last 21 days',
        answer: [
          {
            valueCoding: answerOptions[0],
          },
        ],
      })
      setInnerQuestionResponse5(undefined)
      setInnerQuestionResponse6(undefined)
      setInnerQuestionResponse7(undefined)
      setInnerQuestionResponse9(undefined)
      setInnerQuestionResponse10(undefined)
      setInnerQuestionResponse11(undefined)
      setInnerQuestionResponse12(undefined)
      setInnerQuestionResponse13(undefined)
      setInnerQuestionResponse14(undefined)
      //   sadhKaRef.current = undefined
      dispatch(resetStateForIPdFinishSadhka())
      if (sadhakModelData.length === 0) {
        dispatch(resetAddSadhka())
        dispatch(resetMainPatientIpdState())
        dispatch(resetStateForIPdFinish())
        dispatch(requestForDateWiseIpdAppointmentLists(''))
        sessionStorage.removeItem('selecttedNames')
        if (onLabOrderCreated) {
          onLabOrderCreated('1', '')
          resetForm()
        }
      }
    }

    if (ipdFinsih.additionSuccessful && sadhakData[0].patient) {
      setChecked(false)
      sessionStorage.removeItem('relatedId')
      setQuestionResponse([
        {
          linkId: questions.item[0].linkId,
          text: questions.item[0].text,
        },
      ])
      setQuestionResponse1([])
      setQuestionResponse2([
        {
          linkId: '3',
          text: 'I have been in close contact with a confirmed case of COVID 19 in the last 21 days',
        },
      ])
      setTravelDate1(moment().subtract(2, 'days').format('YYYY-MM-DD'))
      setTravelDate2(moment().subtract(2, 'days').format('YYYY-MM-DD'))
      setTravelDate3(moment().subtract(2, 'days').format('YYYY-MM-DD'))
      setTravelDate4(moment().subtract(2, 'days').format('YYYY-MM-DD'))
      setTravelDate5(moment().subtract(2, 'days').format('YYYY-MM-DD'))
      setTravelDate6(moment().subtract(2, 'days').format('YYYY-MM-DD'))
      setInnerQuestionResponse1({
        linkId: questions.item[0].item[0].linkId,
        text: questions.item[0].item[0].text,
        answer: [
          {
            valueCoding: answerOptions[0],
          },
        ],
      })
      setInnerQuestionResponse2({
        linkId: questions.item[0].item[1].linkId,
        text: questions.item[0].item[1].text,
        answer: [
          {
            valueCoding: answerOptions[0],
          },
        ],
      })
      setInnerQuestionResponse3({
        linkId: questions.item[0].item[2].linkId,
        text: questions.item[0].item[2].text,
        answer: [
          {
            valueCoding: answerOptions[0],
          },
        ],
      })
      setInnerQuestionResponse4({
        linkId: questions.item[0].item[3].linkId,
        text: questions.item[0].item[3].text,
        answer: [
          {
            valueCoding: answerOptions[0],
          },
        ],
      })
      setInnerQuestionResponse8({
        linkId: '3.1',
        text: 'I have been in close contact with a confirmed case of COVID 19 in the last 21 days',
        answer: [
          {
            valueCoding: answerOptions[0],
          },
        ],
      })
      setInnerQuestionResponse5(undefined)
      setInnerQuestionResponse6(undefined)
      setInnerQuestionResponse7(undefined)
      setInnerQuestionResponse9(undefined)
      setInnerQuestionResponse10(undefined)
      setInnerQuestionResponse11(undefined)
      setInnerQuestionResponse12(undefined)
      setInnerQuestionResponse13(undefined)
      setInnerQuestionResponse14(undefined)
    }

    if (ipdFinsih.additionSuccessful && !sadhakData[0].patient) {
      setChecked(false)
      sessionStorage.removeItem('relatedId')
      dispatch(resetMainPatientIpdState())
      dispatch(resetStateForIPdFinish())

      if (onLabOrderCreated) {
        onLabOrderCreated('1', '')
        resetForm()
      }
    }
    // resetForm()
  }, [
    ipdFinsih.additionSuccessful,
    ipdFinsihSadhka.additionSuccessful,
    ipdRegisterSlice,
    onLabOrderCreated,
  ])

  function resetForm() {
    setSelectedServiceMedicine(undefined)
    setTravelDate1(moment().subtract(2, 'days').format('YYYY-MM-DD'))
    setTravelDate2(moment().subtract(2, 'days').format('YYYY-MM-DD'))
    setTravelDate3(moment().subtract(2, 'days').format('YYYY-MM-DD'))
    setTravelDate4(moment().subtract(2, 'days').format('YYYY-MM-DD'))
    setTravelDate5(moment().subtract(2, 'days').format('YYYY-MM-DD'))
    setTravelDate6(moment().subtract(2, 'days').format('YYYY-MM-DD'))
    setChecked(false)
    setSadhakData([
      {
        id: 0,
        patient: undefined,
      },
    ])
    setAttenDant([
      {
        name: '',
        email: '',
        phone: '',
        gender: '',
        dob: '',
        idProofTypeData: IdProofType[0].code!,
        idProofNumber: '',
        imageFront: '',
        imageFrontBack: '',
        addressProofTypeFrontPath: '',
        addressProofTypeFrontMimeType: '',
        addressProofTypeFrontName: '',
        addressProofTypeBackPath: '',
        addressProofTypeBackMimeType: '',
        addressProofTypeBackFileName: '',
        bedReferenceId: '',
        addressProofTypeBoth: '',
        addressProofTypeBothSize: 0,
        addressProofTypeBothMimeType: '',
        addressProofTypeBothName: '',
        addressProofTypeFrontSize: 0,
        addressProofTypeBackSize: 0,
      },
    ])
    setSadhakFinish(undefined)
    sadhKaRef.current = undefined
    setIsPatientEditMode(true)
    setSelectedRoomType(undefined)
    setSelectedPatient(undefined)
    setEndDate(moment().format('YYYY-MM-DD'))
    setCount(0)
    setRoomNo(undefined)

    setValue('file')
    setPhotoType('both')
    setStartDate(new Date())
    setDiscount(0)
    setSelectedVisitPurpose(purposeOfVisit[0])
    setSadhakFinish(undefined)
    const res1 = sessionStorage.getItem('sadhakList')
    if (res1 !== null) sessionStorage.removeItem('sadhakList')
  }

  function handleNameChange(nameData: any, index: number) {
    const values: Attendant[] = [...attendant]
    values[index].name = nameData.target.value
    setAttenDant(values)
  }

  function handleProofFront(
    dataType: any,
    imageName: any,
    image: any,
    index: number,
    size: number
  ) {
    const values: Attendant[] = [...attendant]
    values[index].addressProofTypeFrontPath = image
    values[index].addressProofTypeFrontMimeType = dataType
    values[index].addressProofTypeFrontName = imageName
    values[index].addressProofTypeFrontSize = size

    setAttenDant(values)
  }
  function handleProofBoth(
    dataType: any,
    imageName: any,
    image: any,
    index: number,
    size: number
  ) {
    const values: Attendant[] = [...attendant]
    values[index].addressProofTypeBoth = image
    values[index].addressProofTypeBothMimeType = dataType
    values[index].addressProofTypeBothName = imageName
    values[index].addressProofTypeBothSize = size
    setAttenDant(values)
  }

  function handleProofBack(
    dataType: any,
    imageName: any,
    image: any,
    index: number,
    size: number
  ) {
    const values: Attendant[] = [...attendant]

    values[index].addressProofTypeBackPath = image
    values[index].addressProofTypeBackMimeType = dataType
    values[index].addressProofTypeBackFileName = imageName
    values[index].addressProofTypeBackSize = size
    setAttenDant(values)
  }

  function handleProof(nameData: string, index: number) {
    const values: Attendant[] = [...attendant]
    values[index].imageFront = nameData
    setAttenDant(values)
  }

  function handleDob(dobData: any, index: number) {
    const values: Attendant[] = [...attendant]
    values[index].dob = dobData.target.value
    setAttenDant(values)
  }

  function handleGender(dobData: any, index: number) {
    const values: Attendant[] = [...attendant]
    values[index].gender = dobData
    setAttenDant(values)
  }

  function handleAttendentRoom(bedNumber: string, index: number) {
    const values: Attendant[] = [...attendant]
    values[index].bedReferenceId = bedNumber
    setAttenDant(values)
  }

  function handleAttendentRoomType(roomType: string, index: number) {
    const values: Attendant[] = [...attendant]
    values[index].roomType = roomType
    setAttenDant(values)
  }

  function handlePhone(phoneData: any, index: number) {
    const values: Attendant[] = [...attendant]
    values[index].phone = phoneData
    setAttenDant(values)
  }

  function handlePhoneNumberChange(event: any, index: number) {
    const values: Attendant[] = [...attendant]
    values[index].phone = event
    setAttenDant(values)
  }

  function handleSelectedPatient(patient: R4.IPatient, index: number) {
    const values: Sadhka[] = [...sadhakData]
    values[index].patient = patient
    setSadhakData(values)
  }

  function handleSadhakaRoom(bedNumber: string, index: number) {
    const values: Sadhka[] = [...sadhakData]
    values[index].bedId = bedNumber
    setSadhakData(values)
  }

  function handleSadhakaRoomType(roomType: string, index: number) {
    const values: Sadhka[] = [...sadhakData]
    values[index].roomType = roomType
    setSadhakData(values)
  }

  function handleEmail(phoneData: any, index: number) {
    const values: Attendant[] = [...attendant]
    values[index].email = phoneData.target.value
    setAttenDant(values)
  }

  function handleProoFtype(phoneData: string, index: number) {
    const values: Attendant[] = [...attendant]
    values[index].idProofTypeData = phoneData
    setAttenDant(values)
  }

  function handleProofNumber(phoneData: any, index: number) {
    const values: Attendant[] = [...attendant]
    values[index].idProofNumber = phoneData.target.value
    setAttenDant(values)
  }

  const handleRemove = (index: number) => {
    const list = [...sadhakData]
    list.splice(index, 1)
    setSadhakData(list)
  }

  const handlemodify = (index: number) => {
    const values: Sadhka[] = [...sadhakData]
    values[index].patient = undefined
    values[index].bedId = undefined
    values[index].roomType = undefined
    setSadhakData(values)
  }

  const handleAddMore = () => {
    const values: Sadhka[] = [...sadhakData]
    values.push({
      id: 1,
      patient: undefined,
    })
    setSadhakData(values)
  }

  const handleDateChange = (date: MaterialUiPickersDate) => {
    setSelectedDate(date !== null ? moment(date).toDate() : new Date())
    setEndDate(
      date !== null
        ? moment(date).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD')
    )
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetForm()
        dispatch(resetMainPatientIpdState())
        dispatch(resetStateForIPdFinish())
        dispatch(resetStateForIPdFinishSadhka())
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth={selectedRoomType && selectedPatient ? 'md' : 'sm'}
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>IPD Registration</Box>
          <Box>
            <CloseOutlined
              id={`${id}close_icon`}
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                resetForm()
                dispatch(resetMainPatientIpdState())
                dispatch(resetStateForIPdFinish())
                dispatch(resetStateForIPdFinishSadhka())
                onClose()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        {!ipdRegisterSlice.additionSuccessful && (
          <Box display='flex' flexDirection='row'>
            <Box
              display='flex'
              flexDirection='column'
              flexGrow={4}
              maxWidth={width * 0.65}
            >
              <Box display='flex' flexDirection='row'>
                <Box display='flex' flexDirection='column' width='100%'>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <WelloFormItemLabel title='Admission On' />
                    <DateTimePicker
                      id={`${id}check_in_date_picker`}
                      value={startDate}
                      inputVariant='outlined'
                      disablePast={true}
                      size='small'
                      disableFuture={true}
                      initialFocusedDate={new Date()}
                      format='DD-MM-YYYY HH:mm A'
                      onChange={(val) => {
                        val !== null
                          ? setStartDate(moment(val).toDate())
                          : new Date()
                      }}
                    />
                    <WelloFormItemLabel title='Check Out Date' />
                    <KeyboardDatePicker
                      clearable
                      id={`${id}check_out_date_picker`}
                      maxDate={moment().add(90, 'd')}
                      minDate={moment(startDate)}
                      value={selectedDate}
                      format='DD-MM-YYYY'
                      size='small'
                      onChange={handleDateChange}
                      error={
                        validateCheckoutDate(
                          moment(startDate).format('YYYY-MM-DD'),
                          endDate,
                          false
                        ).length > 0
                      }
                      helperText={validateCheckoutDate(
                        moment(startDate).format('YYYY-MM-DD'),
                        endDate,
                        false
                      )}
                      inputVariant='outlined'
                    />
                  </MuiPickersUtilsProvider>

                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                  >
                    <WelloFormItemLabel
                      title={t('labelCommon:select_patient')}
                    />
                    {!isPatientEditMode && referralId === undefined && (
                      <Button
                        id={`${id}but_lab_ord_cre_modify_pat`}
                        variant='text'
                        color='primary'
                        onClick={(e) => {
                          setIsPatientEditMode(true)
                          setSelectedPatient(undefined)
                          setSelectedRoomType(undefined)
                          setSelectedServiceMedicine(undefined)
                          dispatch(resetMainPatientIpdState())
                        }}
                      >
                        Modify
                      </Button>
                    )}
                  </Box>
                  <Box display='flex' flexDirection='column'>
                    <Box display='flex' flexDirection='row'>
                      {isPatientEditMode && (
                        <PatientSearchByText
                          id={2}
                          onNewPatientButtonClicked={() => {}}
                          onPatientSelected={(patient) => {
                            getAgeOfPatientInYears(patient)
                            setSelectedPatient(patient)
                            setIsPatientEditMode(false)
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                  <Box display='flex' flexDirection='row' width='100%'>
                    {!isPatientEditMode && selectedPatient && (
                      <Box display='flex' flexDirection='column' width='100%'>
                        <PatientTile patient={selectedPatient} />

                        {getExtensionValueOfUrlPatient(
                          selectedPatient
                            ? selectedPatient.extension ?? []
                            : [],
                          'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                        ) !== 'IN' &&
                          getExtensionValueOfUrlPatient(
                            selectedPatient
                              ? selectedPatient.extension ?? []
                              : [],
                            'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                          ) !== 'India' &&
                          getExtensionValueOfUrlPatient(
                            selectedPatient
                              ? selectedPatient.extension ?? []
                              : [],
                            'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                          ) !== 'india' &&
                          visaDataFinal === undefined && (
                            <Box py={1} display='flex' flexDirection='row'>
                              <Checkbox
                                edge='start'
                                checked={checkedForForegin}
                                onChange={handleChangeForForegin}
                                tabIndex={-1}
                                size='small'
                                disableRipple
                                color='primary'
                                inputProps={{ 'aria-labelledby': 'consent' }}
                                id={`${id}check_consent`}
                              />
                              <Box py={1.3}>
                                <Typography variant='subtitle2' color='primary'>
                                  Add Visa Details
                                </Typography>
                              </Box>
                            </Box>
                          )}
                      </Box>
                    )}
                  </Box>
                  {ipdRegisterSlice.error && (
                    <Box>
                      <Typography variant='subtitle2' color='error'>
                        {ipdRegisterSlice.patientError
                          ? ipdRegisterSlice.patientError.length > 0
                            ? ipdRegisterSlice.patientError
                            : ''
                          : ''}
                      </Typography>
                    </Box>
                  )}
                  {selectedPatient &&
                    getAgeOfPatientInYears(selectedPatient) < 12 && (
                      <Box>
                        <Typography variant='subtitle2' color='error'>
                          Must be at least 12 years old
                        </Typography>
                      </Box>
                    )}

                  {selectedPatient &&
                    getAgeOfPatientInYears(selectedPatient) > 80 && (
                      <Box>
                        <Typography variant='subtitle2' color='error'>
                          Should not be greater than 80 years old
                        </Typography>
                      </Box>
                    )}
                  <Box
                    display='flex'
                    flexDirection='column'
                    my={2}
                    width='100%'
                  >
                    {selectedPatient && (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <Box display='flex' flexDirection='column' width='100%'>
                          <Box display='flex' flexDirection='row' width='100%'>
                            <WelloSelectFHIRCoding
                              title='Room Type'
                              id={`${id}ipd_room_type`}
                              availableCodings={roomTypes}
                              onChanged={(type) => {
                                setSelectedRoomType(type)
                                setRoomNo(undefined)
                                /*  dispatch(requestForRoomNumber(type.code ?? '')) */
                              }}
                              textFieldProps={{
                                size: 'small',
                                fullWidth: true,
                              }}
                            />
                          </Box>
                          {selectedRoomType && selectedPatient && (
                            <Box
                              id={`id${selectedRoomType.code}`}
                              display='flex'
                              flexDirection='column'
                              width='100%'
                            >
                              <RoomAndBedSelector
                                id={101}
                                key={`id${selectedRoomType.code}`}
                                onBedSelected={(e) => {
                                  setRoomNo(e)
                                }}
                                roomTypeCode={selectedRoomType.code ?? ''}
                              />
                              {ipdRegisterSlice.error && (
                                <Box>
                                  <Typography variant='subtitle2' color='error'>
                                    {ipdRegisterSlice.errorMessage
                                      ? ipdRegisterSlice.errorMessage.length > 0
                                        ? ipdRegisterSlice.errorMessage
                                        : ''
                                      : ''}
                                  </Typography>
                                </Box>
                              )}
                              {/*  <Box
                                display='flex'
                                flexGrow={1}
                                alignItems='flex-end'
                                justifyContent='center'
                                p={0.5}
                                width='100%'
                              >
                                {roomNumberSearchSlice.searchingPrice && (
                                  <WelloLoadingIndicator message='Fetching Rooms' />
                                )}

                                {roomNumberSearchSlice.resultsAvailable &&
                                  roomNumberSearchSlice.roomNumbers && (
                                    <WelloFhirRoomNumber
                                      title='Room Number'
                                      availableCodings={
                                        roomNumberSearchSlice.roomNumbers
                                      }
                                      onChanged={(type) => {
                                        const rommDatta = type.code
                                          ? type.code
                                          : ''
                                        setRoomNo(
                                          rommDatta.length > 0
                                            ? parseInt(rommDatta, 10)
                                            : 0
                                        )
                                      }}
                                      textFieldProps={{
                                        size: 'small',
                                        fullWidth: true,
                                      }}
                                    />
                                  )}
                              </Box> */}

                              <Box
                                display='flex'
                                flexGrow={1}
                                alignItems='flex-end'
                                justifyContent='center'
                                p={0.5}
                                width='40%'
                              >
                                <WelloTextField
                                  title='Discount (%)'
                                  textProps={{
                                    id: `${id}_discount`,
                                    defaultValue: 0,
                                    type: 'number',
                                    inputProps: {
                                      max: 100,
                                      step: 0.25,
                                    },
                                    error:
                                      discount < 0 ||
                                      discount > 100 ||
                                      Number.isNaN(discount),
                                    helperText:
                                      discount < 0 ||
                                      discount > 100 ||
                                      Number.isNaN(discount)
                                        ? 'Discount between 1 to 100'
                                        : '',
                                    onChange: (discounData) => {
                                      setDiscount(
                                        parseFloat(
                                          parseFloat(
                                            discounData.target.value
                                          ).toFixed(2)
                                        )
                                      )
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          )}
                          <Box display='flex' flexDirection='row' width='100%'>
                            <WelloSelectFHIRCoding
                              title='Purpose of visit'
                              //   id='ipd_purpose_of_visit'
                              id={`${id}ipd_purpose_of_visit`}
                              availableCodings={purposeOfVisit}
                              onChanged={(type) => {
                                setSelectedVisitPurpose(type)
                              }}
                              preSelectedCoding={selectedVistPurpose}
                              textFieldProps={{
                                size: 'small',
                                fullWidth: true,
                              }}
                            />
                          </Box>

                          {unitServices && unitServices.length > 0 && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              <WelloSelectFHIRCoding
                                title='System of Medicine'
                                //   id='ipd_purpose_of_visit'
                                id={`${id}systemOfMedicine`}
                                availableCodings={
                                  unitServices[0].systemOfMedicine ?? []
                                }
                                role='System of Medicine'
                                // duration='System of Medicine'
                                onChanged={(type) => {
                                  setSelectedServiceMedicine(type)
                                }}
                                // preSelectedCoding={
                                //   unitServices[0].systemOfMedicine
                                //     ? unitServices[0].systemOfMedicine[0]
                                //     : undefined
                                // }
                                textFieldProps={{
                                  size: 'small',
                                  fullWidth: true,
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>

            {selectedRoomType && selectedPatient && (
              <Box
                display='flex'
                flexDirection='column'
                paddingLeft={2}
                minWidth='400px'
                maxWidth='400px'
              >
                <Box display='flex' flexDirection='row' width='100%' my={2}>
                  <IpdPriceSummary
                    id={`${id}`}
                    rommTypeCode={
                      selectedRoomType ? selectedRoomType.code ?? '' : ''
                    }
                    roomLabel={
                      selectedRoomType ? selectedRoomType.display ?? '' : ''
                    }
                    days={getDateDifferceinDays(
                      moment(startDate).format('DD-MM-YYYY'),
                      moment(endDate).format('DD-MM-YYYY')
                    )}
                    discount={discount}
                    onPriceFetched={(charDef: R4.IChargeItemDefinition) => {
                      setSelectedChargeDef(charDef)
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}

        {ipdRegisterSlice.additionSuccessful && (
          <Box display='flex' flexDirection='row'>
            <Box
              display='flex'
              flexDirection='column'
              flexGrow={4}
              maxWidth={width * 0.65}
            >
              {/* <Box display='flex' flexDirection='row' width='100%' py={1}>
                <Box
                  display='flex'
                  flexGrow={1}
                  justifyContent='center'
                  width='100%'
                  flexDirection='column'
                  px={2}
                >
                  <WelloFormItemLabel title='Height' />
                  <TextField
                    variant='outlined'
                    fullWidth
                    type='number'
                    value={height}
                    onChange={(event) => {
                      setHeight(parseFloat(event.target.value))
                    }}
                    error={height <= 2.5 || height >= 271}
                    helperText={
                      height <= 2.5 || height >= 271
                        ? `Height should be in between 2.5 cm to 271 cm`
                        : ''
                    }
                    InputProps={{
                      inputProps: {
                        max: 271,
                        min: 2.5,
                      },
                      endAdornment: (
                        <InputAdornment position='end'>
                          {' '}
                          <Typography>cm</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box> */}

              <Box
                display='flex'
                flexDirection='column'
                // alignContent='center'
                alignItems='flex-start'
                width='100%'
                px={2}
              >
                {!ipdFinsih.additionSuccessful && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    id='unit_act_basic_det_container'
                    width='100%'
                  >
                    <Box display='flex' flexDirection='row' py={1}>
                      <Typography
                        variant='subtitle1'
                        style={{
                          textTransform: 'uppercase',
                        }}
                        id={`${id}text_attendant`}
                      >
                        Attendant
                      </Typography>
                    </Box>
                    {attendant.map((attendantRow: Attendant, index: number) => (
                      <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='flex-start'
                      >
                        <Box
                          display='flex'
                          flexDirection='row'
                          justifyContent='flex-start'
                          width='100%'
                        >
                          <Box
                            display='flex'
                            flexGrow={1}
                            alignItems='flex-start'
                            justifyContent='center'
                            p={0.5}
                            width='33%'
                          >
                            <WelloTextField
                              title='Full Name'
                              textProps={{
                                required: true,
                                name: 'name',
                                id: `${id}${index}attendant_name`,
                                placeholder: t('labelCommon:first_name_label'),
                                error:
                                  (attendantRow.email.length > 0 ||
                                    attendantRow.phone.length > 0 ||
                                    attendantRow.gender.length > 0 ||
                                    attendantRow.idProofNumber.length > 0 ||
                                    attendantRow.addressProofTypeFrontPath
                                      .length > 0 ||
                                    attendantRow.addressProofTypeBackPath
                                      .length > 0 ||
                                    attendantRow.addressProofTypeBoth.length >
                                      0) &&
                                  attendantRow.name.length === 0,
                                helperText:
                                  (attendantRow.email.length > 0 ||
                                    attendantRow.phone.length > 0 ||
                                    attendantRow.gender.length > 0 ||
                                    attendantRow.idProofNumber.length > 0 ||
                                    attendantRow.addressProofTypeFrontPath
                                      .length > 0 ||
                                    attendantRow.addressProofTypeBackPath
                                      .length > 0 ||
                                    attendantRow.addressProofTypeBoth.length >
                                      0) &&
                                  attendantRow.name.length === 0
                                    ? 'Full Name is required'
                                    : '',
                                onChange: (changeData) => {
                                  handleNameChange(changeData, index)
                                },
                              }}
                            />
                          </Box>

                          <Box
                            display='flex'
                            flexGrow={1}
                            alignItems='flex-start'
                            justifyContent='center'
                            p={0.5}
                            width='33%'
                          >
                            <WelloDateInput
                              title={t('labelCommon:Date of Birth')}
                              textProps={{
                                id: `${id}${index}attendant_dob`,
                                fullWidth: true,
                                required: true,
                                'aria-required': true,
                                name: 'dob',
                                onChange: (changeData) => {
                                  handleDob(changeData, index)
                                },
                                inputProps: {
                                  min: '1871-01-01',
                                  max: moment().format('YYYY-MM-DD'),
                                },
                                error:
                                  validateDobForAttendant(
                                    attendantRow.dob,
                                    false
                                  ).length > 0 ||
                                  getAgeOfAttendantInYears(attendantRow.dob) <
                                    8 ||
                                  getAgeOfAttendantInYears(attendantRow.dob) >
                                    80 ||
                                  ((attendantRow.email.length > 0 ||
                                    attendantRow.phone.length > 0 ||
                                    attendantRow.gender.length > 0 ||
                                    attendantRow.idProofNumber.length > 0 ||
                                    attendantRow.name.length > 0 ||
                                    attendantRow.addressProofTypeFrontPath
                                      .length > 0 ||
                                    attendantRow.addressProofTypeBackPath
                                      .length > 0 ||
                                    attendantRow.addressProofTypeBoth.length >
                                      0) &&
                                    attendantRow.dob.length === 0),
                                helperText:
                                  validateDobForAttendant(
                                    attendantRow.dob,
                                    false
                                  ).length > 0
                                    ? `${validateDobForAttendant(
                                        attendantRow.dob,
                                        false
                                      )}`
                                    : getAgeOfAttendantInYears(
                                        attendantRow.dob
                                      ) < 8 ||
                                      getAgeOfAttendantInYears(
                                        attendantRow.dob
                                      ) > 80
                                    ? 'Must be in between 8 to 80 years old'
                                    : (attendantRow.email.length > 0 ||
                                        attendantRow.phone.length > 0 ||
                                        attendantRow.gender.length > 0 ||
                                        attendantRow.idProofNumber.length > 0 ||
                                        attendantRow.name.length > 0 ||
                                        attendantRow.addressProofTypeFrontPath
                                          .length > 0 ||
                                        attendantRow.addressProofTypeBackPath
                                          .length > 0 ||
                                        attendantRow.addressProofTypeBoth
                                          .length > 0) &&
                                      attendantRow.dob.length === 0
                                    ? 'Date of birth is required'
                                    : '',
                              }}
                            />
                          </Box>

                          <Box
                            display='flex'
                            flexGrow={1}
                            alignItems='flex-start'
                            justifyContent='center'
                            p={0.5}
                            width='35%'
                          >
                            <WelloGenderSelector
                              title={t('labelCommon:GENDER')}
                              textFieldProps={{
                                id: `${id}${index}attendant_gender`,
                                label: 'Select Gender',
                                name: 'gender',
                                error:
                                  (attendantRow.email.length > 0 ||
                                    attendantRow.phone.length > 0 ||
                                    attendantRow.idProofNumber.length > 0 ||
                                    attendantRow.name.length > 0 ||
                                    attendantRow.addressProofTypeFrontPath
                                      .length > 0 ||
                                    attendantRow.addressProofTypeBackPath
                                      .length > 0 ||
                                    attendantRow.addressProofTypeBoth.length >
                                      0) &&
                                  attendantRow.gender.length === 0,
                                helperText:
                                  (attendantRow.email.length > 0 ||
                                    attendantRow.dob.length > 0 ||
                                    attendantRow.phone.length > 0 ||
                                    attendantRow.name.length > 0 ||
                                    attendantRow.idProofNumber.length > 0 ||
                                    attendantRow.addressProofTypeFrontPath
                                      .length > 0 ||
                                    attendantRow.addressProofTypeBackPath
                                      .length > 0 ||
                                    attendantRow.addressProofTypeBoth.length >
                                      0) &&
                                  attendantRow.gender.length === 0
                                    ? 'Gender is required'
                                    : '',
                              }}
                              onChanged={(selectedGender) => {
                                if (selectedGender?.code) {
                                  handleGender(selectedGender?.code, index)
                                }
                              }}
                            />
                          </Box>
                        </Box>

                        <Box display='flex' flexDirection='row' width='100%'>
                          <Box
                            display='flex'
                            flexGrow={1}
                            alignItems='flex-start'
                            justifyContent='center'
                            p={0.5}
                            width='50%'
                          >
                            <WelloPhoneNumberField
                              textProps={{
                                id: `${id}${index}attendant_phone`,
                                countryCodeEditable: false,
                                defaultCountry: 'in',
                                fullWidth: true,
                                name: 'phone',
                                inputMode: 'tel',
                                onlyCountries: ['in'],
                                disableDropdown: false,
                                inputProps: { maxLength: 13 },
                                disabled: false,
                                onChange: (
                                  e:
                                    | React.ChangeEvent<
                                        HTMLInputElement | HTMLTextAreaElement
                                      >
                                    | string
                                ) => {
                                  if (typeof e === 'string') {
                                    handlePhoneNumberChange(e, index)
                                  }
                                },
                                regions: ['asia'],
                                autoFormat: false,
                                size: 'small',
                                variant: 'outlined',
                                required: false,
                              }}
                              title={t('labelCommon:phone_number_label')}
                            />
                          </Box>

                          <Box
                            display='flex'
                            flexGrow={1}
                            alignItems='flex-start'
                            justifyContent='center'
                            p={0.5}
                            width='50%'
                          >
                            <WelloEmailField
                              title={t('labelCommon:email_name_label_full')}
                              textProps={{
                                id: `${id}${index}attendant_email`,
                                fullWidth: true,
                                name: 'email',
                                placeholder: t('labelCommon:email_address'),
                                required: false,
                                onChange: (changeData) => {
                                  handleEmail(changeData, index)
                                },
                                error:
                                  validateEmail(attendantRow.email, false)
                                    .length > 0,
                                helperText:
                                  validateEmail(attendantRow.email, false)
                                    .length > 0
                                    ? `${validateEmail(
                                        attendantRow.email,
                                        false
                                      )}`
                                    : '',
                              }}
                            />
                          </Box>
                        </Box>
                        <Box display='flex' flexDirection='row' width='100%'>
                          {attendantRow.name.length > 0 &&
                            attendantRow.gender.length &&
                            (validateDobForAttendant(attendantRow.dob, false)
                              .length > 0 ||
                              getAgeOfAttendantInYears(attendantRow.dob) >=
                                8) && (
                              <Box width='50%'>
                                <WelloSelectFHIRCoding
                                  id={`${id}room_type`}
                                  title='Room Type'
                                  availableCodings={roomTypes}
                                  onChanged={(type) => {
                                    handleAttendentRoomType(type.code!, index)
                                    /*  dispatch(requestForRoomNumber(type.code ?? '')) */
                                  }}
                                  textFieldProps={{
                                    size: 'small',
                                    fullWidth: true,
                                  }}
                                />
                              </Box>
                            )}
                          {attendantRow.roomType && <Box width={0.5} />}
                          {attendantRow.roomType && (
                            <RoomAndBedSelector
                              id={102}
                              key={`id${attendantRow.roomType}`}
                              onBedSelected={(bedId) => {
                                handleAttendentRoom(bedId, index)
                              }}
                              bedsToBeOmitted={getSelectedBeds()}
                              roomTypeCode={attendantRow.roomType}
                            />
                          )}
                        </Box>
                        {isRoomDataNotFilled() && (
                          <Box py={1}>
                            <Typography
                              variant='subtitle2'
                              color='error'
                              id={`${id}text_error_room_selection_attendant`}
                            >
                              Please select Room Type, Room Number and Bed
                              Number
                            </Typography>
                          </Box>
                        )}

                        <Box
                          display='flex'
                          flexDirection='row'
                          marginBottom={2}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <Box display='flex' flexDirection='row'>
                              <Typography
                                variant='subtitle1'
                                style={{
                                  textTransform: 'uppercase',
                                }}
                                id={`${id}text_attendant_proof`}
                              >
                                {t('labelCommon:identity_proof')}
                              </Typography>
                            </Box>
                            <Box
                              display='flex'
                              flexDirection='row'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexGrow={1}
                                width='50%'
                                p={0.5}
                              >
                                <WelloSelectFHIRCoding
                                  title={t('labelCommon:select_proof_type')}
                                  id={`${id}proof_type_attendant`}
                                  preSelectedCoding={IdProofType[0]}
                                  availableCodings={IdProofType}
                                  onChanged={(val) => {
                                    if (val.code) {
                                      handleProoFtype(val.code, index)
                                    }
                                  }}
                                  textFieldProps={{
                                    fullWidth: true,
                                    size: 'small',
                                    required: true,
                                    name: 'identityProofTypeCode',
                                    // error: !!errors.identityProofTypeCode,
                                    // helperText: errors.identityProofTypeCode,
                                    id: 'unit_actor_id_proof_type',
                                  }}
                                />
                              </Box>
                              <Box
                                display='flex'
                                flexGrow={1}
                                width='50%'
                                p={0.5}
                              >
                                <WelloTextField
                                  title='ID Proof Number'
                                  textProps={{
                                    id: `${id}proof_number_attendant`,
                                    required: true,
                                    name: 'identityProofNumber',

                                    onChange: (changeData) => {
                                      handleProofNumber(changeData, index)
                                    },
                                    error:
                                      (attendantRow.email.length > 0 ||
                                        attendantRow.dob.length > 0 ||
                                        attendantRow.phone.length > 0 ||
                                        attendantRow.name.length > 0 ||
                                        attendantRow.gender.length > 0) &&
                                      attendantRow.idProofNumber.length === 0,
                                    helperText:
                                      (attendantRow.email.length > 0 ||
                                        attendantRow.dob.length > 0 ||
                                        attendantRow.phone.length > 0 ||
                                        attendantRow.name.length > 0 ||
                                        attendantRow.gender.length > 0) &&
                                      attendantRow.idProofNumber.length === 0
                                        ? 'Id Proof is required'
                                        : '',
                                    fullWidth: true,
                                    size: 'small',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>

                        {attendantRow.idProofNumber &&
                          attendantRow.idProofTypeData && (
                            <Box
                              display='flex'
                              flexDirection='column'
                              flexGrow={1}
                            >
                              <Box>
                                <FormControl>
                                  <FormLabel id='demo-row-radio-buttons-group-label'>
                                    <Typography
                                      variant='subtitle1'
                                      color='textPrimary'
                                    >
                                      Capture Type
                                    </Typography>
                                  </FormLabel>
                                  <RadioGroup
                                    id={`${id}rad_grp_attendant`}
                                    row
                                    aria-label='graphType'
                                    value={value}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      attendantRow.addressProofTypeBoth = ''
                                      attendantRow.addressProofTypeBothName = ''
                                      attendantRow.addressProofTypeBothMimeType =
                                        ''
                                      setValue(
                                        (event.target as HTMLInputElement).value
                                      )
                                    }}
                                  >
                                    <Box px={1.5}>
                                      <FormControlLabel
                                        id={`${id}rad_file_upload_attendant`}
                                        value='file'
                                        control={<Radio />}
                                        label='File Upload'
                                      />
                                      <FormControlLabel
                                        id={`${id}rad_camera_attendant`}
                                        value='camera'
                                        control={<Radio />}
                                        label='Camera'
                                      />
                                    </Box>
                                  </RadioGroup>
                                </FormControl>
                              </Box>
                              {value === 'camera' && (
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  flexGrow={1}
                                >
                                  <Box
                                    display='flex'
                                    flexDirection='column '
                                    borderRadius={4}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      justifyContent='center'
                                    >
                                      <WelloFormItemLabel title='Front' />
                                    </Box>
                                    <Box>
                                      {!attendant[index]
                                        .addressProofTypeFrontPath && (
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          style={{ cursor: 'pointer' }}
                                          alignItems='center'
                                          id={`${id}camera_attendant_front`}
                                          onClick={() => {
                                            setCallBackForCapturePhoto(
                                              () =>
                                                (
                                                  imageName: any,
                                                  dataType: any,
                                                  image: any
                                                ) => {
                                                  handleProofFront(
                                                    dataType,
                                                    imageName,
                                                    image,
                                                    index,
                                                    0
                                                  )
                                                }
                                            )
                                            setOpenWebCam(true)
                                          }}
                                        >
                                          <Box>
                                            <h5>
                                              {' '}
                                              <FiVideo />{' '}
                                            </h5>
                                          </Box>
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            <Box p={1}>
                                              <Typography
                                                variant='subtitle2'
                                                color='primary'
                                              >
                                                Capture Front Part
                                              </Typography>
                                            </Box>
                                            {value === 'camera' &&
                                              isFrontImageUploaded() && (
                                                <Box py={1}>
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='error'
                                                  >
                                                    ID Proof front photo is
                                                    required
                                                  </Typography>
                                                </Box>
                                              )}
                                          </Box>
                                        </Box>
                                      )}

                                      {attendant[index]
                                        .addressProofTypeFrontPath && (
                                        <img
                                          src={
                                            attendant[index]
                                              .addressProofTypeFrontPath
                                          }
                                          alt='doc_sign'
                                          height='90px/'
                                          width='180px'
                                        />
                                      )}
                                      {attendant[index]
                                        .addressProofTypeFrontPath && (
                                        <Button
                                          color='primary'
                                          id={`${id}camera_attendant_front_change`}
                                          onClick={(e) => {
                                            setCallBackForCapturePhoto(
                                              () =>
                                                (
                                                  imageName: any,
                                                  dataType: any,
                                                  image: any
                                                ) => {
                                                  handleProofFront(
                                                    dataType,
                                                    imageName,
                                                    image,
                                                    index,
                                                    0
                                                  )
                                                }
                                            )
                                            setOpenWebCam(true)
                                          }}
                                        >
                                          Change
                                        </Button>
                                      )}
                                    </Box>
                                  </Box>
                                  <Box
                                    display='flex'
                                    flexDirection='column '
                                    borderRadius={4}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      justifyContent='center'
                                    >
                                      <WelloFormItemLabel title='Back' />
                                    </Box>
                                    <Box px={2}>
                                      {!attendant[index]
                                        .addressProofTypeBackPath && (
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          style={{ cursor: 'pointer' }}
                                          id={`${id}camera_attendant_back`}
                                          alignItems='center'
                                          onClick={() => {
                                            setCallBackForCapturePhoto(
                                              () =>
                                                (
                                                  imageName: any,
                                                  dataType: any,
                                                  image: any
                                                ) => {
                                                  handleProofBack(
                                                    dataType,
                                                    imageName,
                                                    image,
                                                    index,
                                                    0
                                                  )
                                                }
                                            )
                                            setOpenWebCam(true)
                                          }}
                                        >
                                          <Box>
                                            <h5>
                                              {' '}
                                              <FiVideo />{' '}
                                            </h5>
                                          </Box>
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            <Box p={1}>
                                              <Typography
                                                variant='subtitle2'
                                                color='primary'
                                              >
                                                Capture Back Part
                                              </Typography>
                                            </Box>
                                            {value === 'camera' &&
                                              isBackImageUploaded() && (
                                                <Box py={1}>
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='error'
                                                  >
                                                    ID Proof back photo is
                                                    required
                                                  </Typography>
                                                </Box>
                                              )}
                                          </Box>
                                        </Box>
                                      )}

                                      {attendant[index]
                                        .addressProofTypeBackPath && (
                                        <img
                                          src={
                                            attendant[index]
                                              .addressProofTypeBackPath
                                          }
                                          alt='doc_sign'
                                          height='90px/'
                                          width='180px'
                                        />
                                      )}
                                      {attendant[index]
                                        .addressProofTypeBackPath && (
                                        <Button
                                          color='primary'
                                          id={`${id}camera_attendant_back_change`}
                                          onClick={(e) => {
                                            setCallBackForCapturePhoto(
                                              () =>
                                                (
                                                  imageName: any,
                                                  dataType: any,
                                                  image: any
                                                ) => {
                                                  handleProofBack(
                                                    dataType,
                                                    imageName,
                                                    image,
                                                    index,
                                                    0
                                                  )
                                                }
                                            )
                                            setOpenWebCam(true)
                                          }}
                                        >
                                          Change
                                        </Button>
                                      )}
                                    </Box>
                                  </Box>
                                </Box>
                              )}

                              {value === 'file' && (
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  flexGrow={1}
                                >
                                  <Box>
                                    <FormControl>
                                      <FormLabel id='demo-row-radio-buttons-group-label'>
                                        <Typography
                                          variant='subtitle1'
                                          color='textPrimary'
                                        >
                                          Type
                                        </Typography>
                                      </FormLabel>
                                      <RadioGroup
                                        id={`${id}type_attendant_grp`}
                                        row
                                        aria-label='graphType'
                                        value={photoType}
                                        onChange={(
                                          event: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          setPhotoType(
                                            (event.target as HTMLInputElement)
                                              .value
                                          )
                                          if (
                                            (event.target as HTMLInputElement)
                                              .value === 'both'
                                          ) {
                                            attendantRow.addressProofTypeBackPath =
                                              ''
                                            attendantRow.addressProofTypeFrontPath =
                                              ''
                                            attendantRow.addressProofTypeBackMimeType =
                                              ''
                                            attendantRow.addressProofTypeFrontMimeType =
                                              ''
                                            attendantRow.addressProofTypeBackFileName =
                                              ''
                                            attendantRow.addressProofTypeFrontName =
                                              ''
                                          }
                                        }}
                                      >
                                        <Box px={1.5}>
                                          <FormControlLabel
                                            id={`${id}type_attendant_both`}
                                            value='both'
                                            control={<Radio />}
                                            label='Front & Back Together'
                                          />
                                          <FormControlLabel
                                            id={`${id}type_attendant_seperate`}
                                            value='seperate'
                                            control={<Radio />}
                                            label='Front & Back Separate'
                                          />
                                        </Box>
                                      </RadioGroup>
                                    </FormControl>
                                  </Box>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    flexGrow={1}
                                  >
                                    {photoType === 'both' && (
                                      <Box
                                        display='flex'
                                        flexDirection='column '
                                        borderRadius={4}
                                      >
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          justifyContent='center'
                                        >
                                          <WelloFormItemLabel title='Front & Back' />
                                        </Box>
                                        <Box
                                          display='flex'
                                          flexDirection='column '
                                        >
                                          <WelloFilePicker
                                            fileTypeToPick='any'
                                            id={`${id}type_attendant_both_file_picker`}
                                            name='identityProofImageFront'
                                            onFileChanged={(selectedFile) => {
                                              if (selectedFile) {
                                                if (selectedFile.path) {
                                                  handleProofBoth(
                                                    selectedFile.files
                                                      ? selectedFile.files.type
                                                      : '',
                                                    selectedFile.name ?? '',
                                                    selectedFile.path,
                                                    index,
                                                    selectedFile.files
                                                      ? selectedFile.files.size
                                                      : 0
                                                  )
                                                }
                                              }
                                            }}
                                            size='medium'
                                            showSelectedFileName={true}
                                            allowUpdate={true}
                                            required={false}
                                            labelIcon={
                                              <h5>
                                                <FiUpload />
                                              </h5>
                                            }
                                            labelName={t(
                                              'labelCommon:select_file'
                                            )}
                                          />
                                          {photoType === 'both' &&
                                            isBackBothImageUploaded() && (
                                              <Box>
                                                <Typography
                                                  variant='subtitle2'
                                                  color='error'
                                                >
                                                  ID Proof photo is required
                                                </Typography>
                                              </Box>
                                            )}
                                        </Box>
                                        {getFileSizeInMB(
                                          attendantRow.addressProofTypeBothSize
                                        ) > 5 && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              ID Proof photo size must be of
                                              less than equal to 5 MB
                                            </Typography>
                                          </Box>
                                        )}
                                      </Box>
                                    )}

                                    {/* isBackBothImageUploaded */}
                                    {photoType === 'seperate' && (
                                      <Box
                                        display='flex'
                                        flexDirection='row'
                                        flexGrow={1}
                                      >
                                        <Box
                                          display='flex'
                                          flexDirection='column '
                                          borderRadius={4}
                                        >
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            justifyContent='center'
                                          >
                                            <WelloFormItemLabel title='Front' />
                                          </Box>
                                          <Box>
                                            <WelloFilePicker
                                              fileTypeToPick='any'
                                              id={`${id}type_attendant_front_file_picker`}
                                              name='identityProofImageFront'
                                              onFileChanged={(selectedFile) => {
                                                if (selectedFile) {
                                                  if (selectedFile.path) {
                                                    handleProofFront(
                                                      selectedFile.files
                                                        ? selectedFile.files
                                                            .type
                                                        : '',
                                                      selectedFile.name ?? '',
                                                      selectedFile.path,

                                                      index,
                                                      selectedFile.files
                                                        ? selectedFile.files
                                                            .size
                                                        : 0
                                                    )
                                                  }
                                                }
                                              }}
                                              size='medium'
                                              showSelectedFileName={true}
                                              allowUpdate={true}
                                              required={false}
                                              labelIcon={
                                                <h5>
                                                  <FiUpload />
                                                </h5>
                                              }
                                              labelName={t(
                                                'labelCommon:select_file'
                                              )}
                                            />
                                          </Box>
                                          <Box>
                                            {getFileSizeInMB(
                                              attendantRow.addressProofTypeFrontSize
                                            ) > 5 && (
                                              <Box>
                                                <Typography
                                                  variant='subtitle2'
                                                  color='error'
                                                >
                                                  ID Proof photo size must be of
                                                  less than equal to 5 MB
                                                </Typography>
                                              </Box>
                                            )}

                                            {photoType === 'seperate' &&
                                              isFrontImageUploaded() && (
                                                <Box>
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='error'
                                                  >
                                                    ID Proof front photo is
                                                    required
                                                  </Typography>
                                                </Box>
                                              )}
                                          </Box>
                                        </Box>

                                        <Box
                                          display='flex'
                                          flexDirection='column '
                                          borderRadius={4}
                                        >
                                          <Box
                                            display='flex'
                                            flexDirection='row'
                                            justifyContent='center'
                                          >
                                            <WelloFormItemLabel title='Back' />
                                          </Box>
                                          <Box px={2}>
                                            <WelloFilePicker
                                              fileTypeToPick='any'
                                              id={`${id}type_attendant_back_file_picker`}
                                              name='identityProofImageBack'
                                              onFileChanged={(selectedFile) => {
                                                if (selectedFile) {
                                                  if (selectedFile.path) {
                                                    handleProofBack(
                                                      selectedFile.files
                                                        ? selectedFile.files
                                                            .type
                                                        : '',
                                                      selectedFile.name ?? '',
                                                      selectedFile.path,
                                                      index,
                                                      selectedFile.files
                                                        ? selectedFile.files
                                                            .size
                                                        : 0
                                                    )
                                                  }
                                                }
                                              }}
                                              size='medium'
                                              showSelectedFileName={true}
                                              allowUpdate={true}
                                              required={false}
                                              // error={!!errors.identityProofImageBack}
                                              // helperText={errors.identityProofImageBack}
                                              labelIcon={
                                                <h5>
                                                  <FiUpload />
                                                </h5>
                                              }
                                              labelName={t(
                                                'labelCommon:select_file'
                                              )}
                                            />
                                          </Box>
                                          <Box px={2}>
                                            {getFileSizeInMB(
                                              attendantRow.addressProofTypeBackSize
                                            ) > 5 && (
                                              <Box>
                                                <Typography
                                                  variant='subtitle2'
                                                  color='error'
                                                >
                                                  ID Proof photo size must be of
                                                  less than equal to 5 MB
                                                </Typography>
                                              </Box>
                                            )}

                                            {photoType === 'seperate' &&
                                              isBackImageUploaded() && (
                                                <Box>
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='error'
                                                  >
                                                    ID Proof front photo is
                                                    required
                                                  </Typography>
                                                </Box>
                                              )}
                                          </Box>
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          )}
                      </Box>
                    ))}
                  </Box>
                )}

                {!IpdRegisterSadhkaSlice.additionSuccessful && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    id='unit_act_basic_det_container'
                    width='100%'
                  >
                    <Box display='flex' flexDirection='row' py={1}>
                      <Typography
                        variant='subtitle1'
                        style={{
                          textTransform: 'uppercase',
                        }}
                      >
                        Sadhaka
                      </Typography>
                    </Box>
                    {sadhakData.map((sadhkaRow: Sadhka, index: number) => (
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        alignContent='center'
                        alignItems='center'
                        width='100%'
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          alignItems='flex-start'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='flex-start'
                            width='100%'
                          >
                            <Box
                              display='flex'
                              flexDirection='row'
                              justifyContent='space-between'
                              width='100%'
                            >
                              <WelloFormItemLabel title='Select Sadhaka' />
                              {sadhkaRow.patient && (
                                <Button
                                  id={`${id}${index}but_lab_ord_cre_modify_pat`}
                                  variant='text'
                                  color='primary'
                                  onClick={(e) => {
                                    handlemodify(index)
                                  }}
                                >
                                  Modify
                                </Button>
                              )}
                            </Box>
                            <Box width='100%'>
                              {!sadhkaRow.patient && (
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                  >
                                    <SadhakSearchByText
                                      id={`${id}${index}`}
                                      onNewPatientButtonClicked={() => {}}
                                      onPatientSelected={(patient) => {
                                        if (selectedPatient) {
                                          if (
                                            selectedPatient.id! === patient.id!
                                          ) {
                                            setShowTextForPatient(true)
                                            setFullTextForPatient(
                                              'Already Selected'
                                            )
                                          } else if (sadhakData.length > 0) {
                                            const filteredData =
                                              sadhakData.filter((d) =>
                                                d.patient
                                                  ? d.patient.id! ===
                                                    patient.id!
                                                  : []
                                              )
                                            if (filteredData.length === 2) {
                                              setFullText(
                                                'Sadhaka Already Selected'
                                              )
                                              setShowText(true)
                                            } else {
                                              setShowText(false)
                                            }

                                            if (filteredData.length === 1)
                                              handleSelectedPatient(
                                                patient,
                                                index
                                              )
                                          } else {
                                            setShowTextForPatient(false)
                                            setFullTextForPatient('')
                                            handleSelectedPatient(
                                              patient,
                                              index
                                            )
                                          }
                                        }
                                      }}
                                    />
                                  </Box>
                                </Box>
                              )}
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  <Box width='100%' py={1}>
                                    {sadhkaRow.patient && (
                                      <PatientTile
                                        patient={sadhkaRow.patient}
                                      />
                                    )}
                                    {sadhkaRow.patient &&
                                      getAgeOfPatientInYears(
                                        sadhkaRow.patient
                                      ) < 12 && (
                                        <Box>
                                          <Typography
                                            variant='subtitle2'
                                            color='error'
                                          >
                                            Must be at least 12 years old
                                          </Typography>
                                        </Box>
                                      )}

                                    {sadhkaRow.patient &&
                                      getAgeOfPatientInYears(
                                        sadhkaRow.patient
                                      ) > 80 && (
                                        <Box>
                                          <Typography
                                            variant='subtitle2'
                                            color='error'
                                          >
                                            Should not be greater than 80 years
                                            old
                                          </Typography>
                                        </Box>
                                      )}
                                  </Box>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                  >
                                    {((sadhkaRow.patient &&
                                      getAgeOfPatientInYears(
                                        sadhkaRow.patient
                                      ) >= 12) ||
                                      (sadhkaRow.patient &&
                                        getAgeOfPatientInYears(
                                          sadhkaRow.patient
                                        ) <= 80)) && (
                                      <WelloSelectFHIRCoding
                                        title='Room Type'
                                        id={`${id}room_type_sadkha`}
                                        availableCodings={roomTypes}
                                        onChanged={(type) => {
                                          handleSadhakaRoomType(
                                            type.code!,
                                            index
                                          )
                                          /*  dispatch(requestForRoomNumber(type.code ?? '')) */
                                        }}
                                        textFieldProps={{
                                          size: 'small',
                                          fullWidth: true,
                                        }}
                                      />
                                    )}
                                    {sadhkaRow.roomType && <Box width={0.5} />}
                                    {sadhkaRow.roomType && (
                                      <RoomAndBedSelector
                                        id={103}
                                        key={`id${sadhkaRow.roomType}`}
                                        onBedSelected={(bedId) => {
                                          handleSadhakaRoom(bedId, index)
                                        }}
                                        bedsToBeOmitted={getSelectedBeds()}
                                        roomTypeCode={sadhkaRow.roomType}
                                      />
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            display='flex'
                            alignItems='center'
                            py={2}
                            flexDirection='row'
                          >
                            {sadhakData.length - 1 === index &&
                              sadhakData.length < 4 &&
                              sadhkaRow.patient && (
                                <Box>
                                  <Button
                                    id={`${id}${index}sadhaka_add_more`}
                                    variant='contained'
                                    size='small'
                                    color='primary'
                                    onClick={handleAddMore}
                                  >
                                    <Typography variant='subtitle2'>
                                      {' '}
                                      + Add More
                                    </Typography>
                                  </Button>
                                </Box>
                              )}
                          </Box>
                        </Box>
                        {sadhakData.length !== 1 && (
                          <Box display='flex' justifyContent='center' p={0.5}>
                            <DeleteOutlineOutlined
                              fontSize='medium'
                              id={`${id}${index}sadhaka_delete_button`}
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setShowDeleteConfirmation({
                                  open: true,
                                  onContinueActionClicked: () => {
                                    setShowDeleteConfirmation({
                                      open: false,
                                    })
                                    handleRemove(index)
                                  },
                                  onCancelActionClicked: () => {
                                    setShowDeleteConfirmation({
                                      open: false,
                                    })
                                  },
                                  displayText:
                                    'Are you sure you want to delete this record?',
                                  continueActionButtonText: 'Delete',
                                  cancelActionButtonText: 'Cancel',
                                })
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    ))}
                    {checkForDuplicateBeds() && (
                      <Box py={1}>
                        <Typography
                          variant='subtitle2'
                          color='error'
                          id={`${id}text_error_room_selection_attendant`}
                        >
                          Please select different room number for each sadhaka
                        </Typography>
                      </Box>
                    )}
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='flex-end'
                      width='100%'
                    >
                      <Button
                        variant='contained'
                        size='small'
                        color='primary'
                        id={`${id}sadhaka_save_button`}
                        disabled={
                          !ipdFinsih.additionSuccessful ||
                          isSaveSadhkaEnabled() ||
                          checkForDuplicateBeds() ||
                          IpdRegisterSadhkaSlice.adding
                        }
                        onClick={() => {
                          if (roomNo) {
                            dispatch(
                              registerIpdSadhka(
                                sadhakData,
                                startDate,
                                moment(endDate).toDate(),
                                discount,
                                getDateDifferceinDays(
                                  moment(startDate).format('DD-MM-YYYY'),
                                  moment(endDate).format('DD-MM-YYYY')
                                ),

                                selectedVistPurpose,
                                selectedRoomType,
                                selectedCharef,
                                selectedPatient
                              )
                            )
                          }
                        }}
                      >
                        <Typography variant='subtitle2'>
                          {' '}
                          Save Sadhaka to Proceed
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                )}

                {IpdRegisterSadhkaSlice.additionSuccessful &&
                  IpdRegisterSadhkaSlice.sadkhaFinalData &&
                  IpdRegisterSadhkaSlice.sadkhaFinalData.length > 0 && (
                    <Box width='100%' py={1}>
                      <Box display='flex' flexDirection='row' py={1}>
                        <Typography
                          variant='subtitle1'
                          style={{
                            textTransform: 'uppercase',
                          }}
                        >
                          Sadhaka
                        </Typography>
                      </Box>
                      {columns.length > 0 && (
                        <WelloSelectFHIRSadhka
                          id={`${id}sadkhaSelect`}
                          title='Select Sadhka'
                          type={t('Select Test ')}
                          selectedColumn={!ipdFinsih.additionSuccessful}
                          availableCodings={sadhakModelData}
                          preSelectedCoding={sadhaFinsh}
                          onChanged={(e) => {
                            if (e) {
                              // sadhKaRef.current = e
                              setSadhakFinish(e)
                            }
                          }}
                          textFieldProps={{
                            size: 'small',
                            placeholder: t('Select Sadhaka'),
                            required: true,
                          }}
                        />
                      )}

                      {columns.length === 0 && (
                        <WelloSelectFHIRSadhka
                          id={`${id}sadkhaSelect_no_data`}
                          title='Select Sadhaka'
                          type={t('Select Test ')}
                          selectedColumn={!ipdFinsih.additionSuccessful}
                          availableCodings={sadhakModelData}
                          preSelectedCoding={sadhaFinsh}
                          onChanged={(e) => {
                            if (e) {
                              // sadhKaRef.current = e
                              setSadhakFinish(e)
                            }
                          }}
                          textFieldProps={{
                            size: 'small',
                            placeholder: t('Select Sadhka'),
                            required: true,
                          }}
                        />
                      )}
                    </Box>
                  )}
              </Box>

              <Box display='flex' flexDirection='column' width='100%'>
                {sadhaFinsh && (
                  <Box width='100%'>
                    {questions.item.map((itemData, index) => (
                      <Box key={itemData.linkId}>
                        {itemData.linkId === '1.1' && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <Typography variant='subtitle1' color='primary'>
                              1. {itemData.text}
                            </Typography>

                            {itemData.item.map((qIn, qindex) => (
                              <Box
                                key={qIn.linkId}
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                px={2}
                              >
                                {qIn.type === 'choice' &&
                                  qIn.linkId === '1.1.1' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <WelloSelectFHIRCoding
                                        title={qIn.text}
                                        id={`${id}q2_sadkha`}
                                        availableCodings={answerOptions}
                                        preSelectedCoding={answerOptions[0]}
                                        onChanged={(type) => {
                                          const outerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.linkId,
                                              text: itemData.text,
                                            }
                                          const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                            questionResponse.filter(
                                              (d) => d.linkId === '1.1'
                                            )
                                          if (outerFilter.length === 0) {
                                            setQuestionResponse([outerRes])
                                          }

                                          const ans1: R4.IQuestionnaireResponse_Answer =
                                            {
                                              valueCoding: type,
                                            }

                                          const innerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: qIn.linkId,
                                              text: qIn.text,
                                              answer: [ans1],
                                            }
                                          setInnerQuestionResponse1(innerRes)
                                        }}
                                        textFieldProps={{
                                          size: 'small',
                                          fullWidth: true,
                                        }}
                                      />
                                    </Box>
                                  )}

                                {qIn.type === 'choice' &&
                                  qIn.linkId === '1.1.2' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <WelloSelectFHIRCoding
                                        title={qIn.text}
                                        id={`${id}q1_sadkha`}
                                        preSelectedCoding={answerOptions[0]}
                                        availableCodings={answerOptions}
                                        onChanged={(type) => {
                                          const outerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.linkId,
                                              text: itemData.text,
                                            }
                                          const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                            questionResponse.filter(
                                              (d) => d.linkId === '1.1'
                                            )
                                          if (outerFilter.length === 0) {
                                            setQuestionResponse([outerRes])
                                          }

                                          const ans1: R4.IQuestionnaireResponse_Answer =
                                            {
                                              valueCoding: type,
                                            }

                                          const innerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: qIn.linkId,
                                              text: qIn.text,
                                              answer: [ans1],
                                            }
                                          setInnerQuestionResponse2(innerRes)
                                        }}
                                        textFieldProps={{
                                          size: 'small',
                                          fullWidth: true,
                                        }}
                                      />
                                    </Box>
                                  )}

                                {qIn.type === 'choice' &&
                                  qIn.linkId === '1.1.3' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <WelloSelectFHIRCoding
                                        title={qIn.text}
                                        id={`${id}q3_sadkha`}
                                        availableCodings={answerOptions}
                                        preSelectedCoding={answerOptions[0]}
                                        onChanged={(type) => {
                                          const outerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.linkId,
                                              text: itemData.text,
                                            }
                                          const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                            questionResponse.filter(
                                              (d) => d.linkId === '1.1'
                                            )
                                          if (outerFilter.length === 0) {
                                            setQuestionResponse([outerRes])
                                          }

                                          const ans1: R4.IQuestionnaireResponse_Answer =
                                            {
                                              valueCoding: type,
                                            }

                                          const innerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: qIn.linkId,
                                              text: qIn.text,
                                              answer: [ans1],
                                            }
                                          setInnerQuestionResponse3(innerRes)
                                        }}
                                        textFieldProps={{
                                          size: 'small',
                                          fullWidth: true,
                                        }}
                                      />
                                    </Box>
                                  )}

                                {qIn.type === 'choice' &&
                                  qIn.linkId === '1.1.4' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <WelloSelectFHIRCoding
                                        title={qIn.text}
                                        id={`${id}q4_sadkha`}
                                        availableCodings={answerOptions}
                                        preSelectedCoding={answerOptions[0]}
                                        onChanged={(type) => {
                                          const outerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.linkId,
                                              text: itemData.text,
                                            }
                                          const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                            questionResponse.filter(
                                              (d) => d.linkId === '1.1'
                                            )
                                          if (outerFilter.length === 0) {
                                            setQuestionResponse([outerRes])
                                          }

                                          const ans1: R4.IQuestionnaireResponse_Answer =
                                            {
                                              valueCoding: type,
                                            }

                                          const innerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: qIn.linkId,
                                              text: qIn.text,
                                              answer: [ans1],
                                            }
                                          setInnerQuestionResponse4(innerRes)
                                        }}
                                        textFieldProps={{
                                          size: 'small',
                                          fullWidth: true,
                                        }}
                                      />
                                    </Box>
                                  )}
                              </Box>
                            ))}
                          </Box>
                        )}

                        {itemData.linkId === '2' && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            px={2}
                            py={2}
                          >
                            <Typography variant='subtitle1' color='primary'>
                              2. {itemData.text}
                            </Typography>

                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              py={1}
                            >
                              {itemData.item.map((qIn1, qindex) => (
                                <Box
                                  key={qIn1.linkId}
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                >
                                  {qIn1.linkId === '2.1' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                    >
                                      <WelloTextField
                                        title={qIn1.text}
                                        textProps={{
                                          id: `${id}_sadhka_country`,

                                          type: 'text',
                                          placeholder: 'Country/City',

                                          onChange: (discounData) => {
                                            const outerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: itemData.linkId,
                                                text: itemData.text,
                                              }
                                            const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                              questionResponse1.filter(
                                                (d) => d.linkId === '2'
                                              )
                                            if (outerFilter.length === 0) {
                                              setQuestionResponse1([outerRes])
                                            }

                                            if (
                                              discounData.target.value.length >
                                              0
                                            ) {
                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString:
                                                    discounData.target.value,
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse6(
                                                innerRes
                                              )
                                            } else {
                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString: '',
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse6(
                                                innerRes
                                              )
                                            }
                                          },
                                        }}
                                      />

                                      {(innerQuestionResponse5 ||
                                        innerQuestionResponse7) &&
                                        innerQuestionResponse6 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse6 &&
                                        innerQuestionResponse6.answer &&
                                        innerQuestionResponse6.answer[0] &&
                                        innerQuestionResponse6.answer[0]
                                          .valueString === undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {/* <Box>
                                        <Typography
                                          variant='subtitle2'
                                          color='error'
                                        >
                                          {innerQuestionResponse6
                                            ? innerQuestionResponse6.answer
                                              ? innerQuestionResponse6.answer[0]
                                                ? innerQuestionResponse6
                                                    .answer[0].valueString
                                                  ? innerQuestionResponse6
                                                      .answer[0].valueString
                                                  : innerQuestionResponse6
                                                      .answer[0].valueString
                                                      ?.length
                                                : '2'
                                              : '3'
                                            : '4'}
                                        </Typography>
                                      </Box> */}

                                      {(innerQuestionResponse5 ||
                                        innerQuestionResponse7) &&
                                        innerQuestionResponse6 &&
                                        innerQuestionResponse6.answer &&
                                        innerQuestionResponse6.answer[0] &&
                                        innerQuestionResponse6.answer[0]
                                          .valueString?.length === 0 && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}
                                    </Box>
                                  )}

                                  {qIn1.linkId === '2.2' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      px={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        <WelloTextField
                                          title={qIn1.text}
                                          textProps={{
                                            id: `${id}_sadhka_arrival_date`,
                                            type: 'date',
                                            inputProps: {
                                              max: moment(new Date()).format(
                                                'YYYY-MM-DD'
                                              ),
                                              min: isCurrentDate(travelDate4)
                                                ? moment()
                                                    .subtract(2, 'days')
                                                    .format('YYYY-MM-DD')
                                                : travelDate4,
                                            },
                                            onKeyDown: (e) => {
                                              e.preventDefault()
                                            },
                                            onChange: (changedValue) => {
                                              changedValue.preventDefault()
                                              setTravelDate1(
                                                moment(
                                                  changedValue.target.value
                                                ).format('YYYY-MM-DD')
                                              )

                                              const outerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: itemData.linkId,
                                                  text: itemData.text,
                                                }
                                              const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                                questionResponse1.filter(
                                                  (d) => d.linkId === '2'
                                                )
                                              if (outerFilter.length === 0) {
                                                setQuestionResponse1([outerRes])
                                              }

                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString: moment(
                                                    changedValue.target.value
                                                  ).format('DD-MM-YYYY'),
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse5(
                                                innerRes
                                              )
                                            },
                                          }}
                                        />
                                        {innerQuestionResponse6 &&
                                          innerQuestionResponse6.answer &&
                                          innerQuestionResponse6.answer[0]
                                            .valueString &&
                                          innerQuestionResponse5 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}

                                        {innerQuestionResponse6 &&
                                          innerQuestionResponse6.answer &&
                                          innerQuestionResponse6.answer[0] &&
                                          innerQuestionResponse6.answer[0]
                                            .valueString?.length === 0 &&
                                          innerQuestionResponse7 &&
                                          innerQuestionResponse5 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}

                                        {innerQuestionResponse6 &&
                                          innerQuestionResponse6.answer ===
                                            undefined &&
                                          innerQuestionResponse7 &&
                                          innerQuestionResponse5 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}
                                        {innerQuestionResponse6 === undefined &&
                                          innerQuestionResponse7 &&
                                          innerQuestionResponse5 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}
                                      </Box>
                                    </Box>
                                  )}

                                  {qIn1.linkId === '2.3' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                    >
                                      <WelloTextField
                                        title={qIn1.text}
                                        textProps={{
                                          id: `${id}_sadhka_depart_date`,
                                          type: 'date',
                                          inputProps: {
                                            max: moment(new Date()).format(
                                              'YYYY-MM-DD'
                                            ),
                                            min: travelDate1,
                                          },
                                          onKeyDown: (e) => {
                                            e.preventDefault()
                                          },
                                          onChange: (changedValue) => {
                                            setTravelDate4(
                                              moment(
                                                changedValue.target.value
                                              ).format('YYYY-MM-DD')
                                            )
                                            const outerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: itemData.linkId,
                                                text: itemData.text,
                                              }
                                            const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                              questionResponse1.filter(
                                                (d) => d.linkId === '2'
                                              )
                                            if (outerFilter.length === 0) {
                                              setQuestionResponse1([outerRes])
                                            }

                                            const ans1: R4.IQuestionnaireResponse_Answer =
                                              {
                                                valueString: moment(
                                                  changedValue.target.value
                                                ).format('DD-MM-YYYY'),
                                              }

                                            const innerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: qIn1.linkId,
                                                text: qIn1.text,
                                                answer: [ans1],
                                              }
                                            setInnerQuestionResponse7(innerRes)
                                          },
                                        }}
                                      />
                                      {innerQuestionResponse6 &&
                                        innerQuestionResponse6.answer &&
                                        innerQuestionResponse6.answer[0]
                                          .valueString &&
                                        innerQuestionResponse7 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse6 &&
                                        innerQuestionResponse6.answer &&
                                        innerQuestionResponse6.answer[0] &&
                                        innerQuestionResponse6.answer[0]
                                          .valueString?.length === 0 &&
                                        innerQuestionResponse5 &&
                                        innerQuestionResponse7 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse6 &&
                                        innerQuestionResponse6.answer ===
                                          undefined &&
                                        innerQuestionResponse5 &&
                                        innerQuestionResponse7 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}
                                      {innerQuestionResponse6 === undefined &&
                                        innerQuestionResponse5 &&
                                        innerQuestionResponse7 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}
                                    </Box>
                                  )}
                                </Box>
                              ))}
                            </Box>

                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              {itemData.item.map((qIn1, qindex) => (
                                <Box
                                  key={qIn1.linkId}
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                >
                                  {qIn1.linkId === '2.1' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                    >
                                      <WelloTextField
                                        title={qIn1.text}
                                        textProps={{
                                          id: `${id}_sadhka1_country`,

                                          type: 'text',
                                          placeholder: 'Country/City',

                                          onChange: (discounData) => {
                                            const outerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: itemData.linkId,
                                                text: itemData.text,
                                              }
                                            const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                              questionResponse1.filter(
                                                (d) => d.linkId === '2'
                                              )
                                            if (outerFilter.length === 0) {
                                              setQuestionResponse3([outerRes])
                                            }

                                            if (
                                              discounData.target.value.length >
                                              0
                                            ) {
                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString:
                                                    discounData.target.value,
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse9(
                                                innerRes
                                              )
                                            } else {
                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString: '',
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse9(
                                                innerRes
                                              )
                                            }
                                          },
                                        }}
                                      />
                                      {(innerQuestionResponse10 ||
                                        innerQuestionResponse11) &&
                                        innerQuestionResponse9 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse9 &&
                                        innerQuestionResponse9.answer &&
                                        innerQuestionResponse9.answer[0] &&
                                        innerQuestionResponse9.answer[0]
                                          .valueString === undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {/* <Box>
                                        <Typography
                                          variant='subtitle2'
                                          color='error'
                                        >
                                          {innerQuestionResponse6
                                            ? innerQuestionResponse6.answer
                                              ? innerQuestionResponse6.answer[0]
                                                ? innerQuestionResponse6
                                                    .answer[0].valueString
                                                  ? innerQuestionResponse6
                                                      .answer[0].valueString
                                                  : innerQuestionResponse6
                                                      .answer[0].valueString
                                                      ?.length
                                                : '2'
                                              : '3'
                                            : '4'}
                                        </Typography>
                                      </Box> */}

                                      {(innerQuestionResponse10 ||
                                        innerQuestionResponse11) &&
                                        innerQuestionResponse9 &&
                                        innerQuestionResponse9.answer &&
                                        innerQuestionResponse9.answer[0] &&
                                        innerQuestionResponse9.answer[0]
                                          .valueString?.length === 0 && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}
                                    </Box>
                                  )}

                                  {qIn1.linkId === '2.2' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      px={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        <WelloTextField
                                          title={qIn1.text}
                                          textProps={{
                                            id: `${id}_sadhka1_arrival_date`,
                                            type: 'date',
                                            inputProps: {
                                              max: moment(new Date()).format(
                                                'YYYY-MM-DD'
                                              ),
                                              min: isCurrentDate(travelDate5)
                                                ? moment()
                                                    .subtract(2, 'days')
                                                    .format('YYYY-MM-DD')
                                                : travelDate5,
                                            },
                                            onKeyDown: (e) => {
                                              e.preventDefault()
                                            },
                                            onChange: (changedValue) => {
                                              setTravelDate2(
                                                moment(
                                                  changedValue.target.value
                                                ).format('YYYY-MM-DD')
                                              )
                                              const outerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: itemData.linkId,
                                                  text: itemData.text,
                                                }
                                              const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                                questionResponse1.filter(
                                                  (d) => d.linkId === '2'
                                                )
                                              if (outerFilter.length === 0) {
                                                setQuestionResponse3([outerRes])
                                              }

                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString: moment(
                                                    changedValue.target.value
                                                  ).format('DD-MM-YYYY'),
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse10(
                                                innerRes
                                              )
                                            },
                                          }}
                                        />
                                        {innerQuestionResponse9 &&
                                          innerQuestionResponse9.answer &&
                                          innerQuestionResponse9.answer[0]
                                            .valueString &&
                                          innerQuestionResponse10 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}

                                        {innerQuestionResponse9 &&
                                          innerQuestionResponse9.answer &&
                                          innerQuestionResponse9.answer[0] &&
                                          innerQuestionResponse9.answer[0]
                                            .valueString?.length === 0 &&
                                          innerQuestionResponse11 &&
                                          innerQuestionResponse10 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}

                                        {innerQuestionResponse9 &&
                                          innerQuestionResponse9.answer ===
                                            undefined &&
                                          innerQuestionResponse11 &&
                                          innerQuestionResponse10 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}
                                        {innerQuestionResponse9 === undefined &&
                                          innerQuestionResponse11 &&
                                          innerQuestionResponse10 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}
                                      </Box>
                                    </Box>
                                  )}

                                  {qIn1.linkId === '2.3' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                    >
                                      <WelloTextField
                                        title={qIn1.text}
                                        textProps={{
                                          id: `${id}_sadhka1_depart_date`,
                                          type: 'date',
                                          inputProps: {
                                            max: moment(new Date()).format(
                                              'YYYY-MM-DD'
                                            ),
                                            min: travelDate2,
                                          },
                                          onKeyDown: (e) => {
                                            e.preventDefault()
                                          },
                                          onChange: (changedValue) => {
                                            setTravelDate5(
                                              moment(
                                                changedValue.target.value
                                              ).format('YYYY-MM-DD')
                                            )
                                            const outerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: itemData.linkId,
                                                text: itemData.text,
                                              }
                                            const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                              questionResponse1.filter(
                                                (d) => d.linkId === '2'
                                              )
                                            if (outerFilter.length === 0) {
                                              setQuestionResponse3([outerRes])
                                            }

                                            const ans1: R4.IQuestionnaireResponse_Answer =
                                              {
                                                valueString: moment(
                                                  changedValue.target.value
                                                ).format('DD-MM-YYYY'),
                                              }

                                            const innerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: qIn1.linkId,
                                                text: qIn1.text,
                                                answer: [ans1],
                                              }
                                            setInnerQuestionResponse11(innerRes)
                                          },
                                        }}
                                      />

                                      {innerQuestionResponse9 &&
                                        innerQuestionResponse9.answer &&
                                        innerQuestionResponse9.answer[0]
                                          .valueString &&
                                        innerQuestionResponse11 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse9 &&
                                        innerQuestionResponse9.answer &&
                                        innerQuestionResponse9.answer[0] &&
                                        innerQuestionResponse9.answer[0]
                                          .valueString?.length === 0 &&
                                        innerQuestionResponse10 &&
                                        innerQuestionResponse11 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse9 &&
                                        innerQuestionResponse9.answer ===
                                          undefined &&
                                        innerQuestionResponse10 &&
                                        innerQuestionResponse11 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}
                                      {innerQuestionResponse9 === undefined &&
                                        innerQuestionResponse10 &&
                                        innerQuestionResponse11 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}
                                    </Box>
                                  )}
                                </Box>
                              ))}
                            </Box>

                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              py={1}
                            >
                              {itemData.item.map((qIn1, qindex) => (
                                <Box
                                  key={qIn1.linkId}
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                >
                                  {qIn1.linkId === '2.1' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                    >
                                      <WelloTextField
                                        title={qIn1.text}
                                        textProps={{
                                          id: `${id}_sadhka1_country`,

                                          type: 'text',
                                          placeholder: 'Country/City',

                                          onChange: (discounData) => {
                                            const outerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: itemData.linkId,
                                                text: itemData.text,
                                              }
                                            const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                              questionResponse1.filter(
                                                (d) => d.linkId === '2'
                                              )
                                            if (outerFilter.length === 0) {
                                              setQuestionResponse4([outerRes])
                                            }

                                            if (
                                              discounData.target.value.length >
                                              0
                                            ) {
                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString:
                                                    discounData.target.value,
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse12(
                                                innerRes
                                              )
                                            } else {
                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString: '',
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse12(
                                                innerRes
                                              )
                                            }
                                          },
                                        }}
                                      />
                                      {(innerQuestionResponse13 ||
                                        innerQuestionResponse14) &&
                                        innerQuestionResponse12 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse12 &&
                                        innerQuestionResponse12.answer &&
                                        innerQuestionResponse12.answer[0] &&
                                        innerQuestionResponse12.answer[0]
                                          .valueString === undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {/* <Box>
                                        <Typography
                                          variant='subtitle2'
                                          color='error'
                                        >
                                          {innerQuestionResponse6
                                            ? innerQuestionResponse6.answer
                                              ? innerQuestionResponse6.answer[0]
                                                ? innerQuestionResponse6
                                                    .answer[0].valueString
                                                  ? innerQuestionResponse6
                                                      .answer[0].valueString
                                                  : innerQuestionResponse6
                                                      .answer[0].valueString
                                                      ?.length
                                                : '2'
                                              : '3'
                                            : '4'}
                                        </Typography>
                                      </Box> */}

                                      {(innerQuestionResponse13 ||
                                        innerQuestionResponse14) &&
                                        innerQuestionResponse12 &&
                                        innerQuestionResponse12.answer &&
                                        innerQuestionResponse12.answer[0] &&
                                        innerQuestionResponse12.answer[0]
                                          .valueString?.length === 0 && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}
                                    </Box>
                                  )}

                                  {qIn1.linkId === '2.2' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      px={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        <WelloTextField
                                          title={qIn1.text}
                                          textProps={{
                                            id: `${id}_sadhka2_arrival_date`,
                                            type: 'date',
                                            inputProps: {
                                              max: moment(new Date()).format(
                                                'YYYY-MM-DD'
                                              ),
                                              min: isCurrentDate(travelDate6)
                                                ? moment()
                                                    .subtract(2, 'days')
                                                    .format('YYYY-MM-DD')
                                                : travelDate6,
                                            },
                                            onKeyDown: (e) => {
                                              e.preventDefault()
                                            },
                                            onChange: (changedValue) => {
                                              setTravelDate3(
                                                moment(
                                                  changedValue.target.value
                                                ).format('YYYY-MM-DD')
                                              )
                                              const outerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: itemData.linkId,
                                                  text: itemData.text,
                                                }
                                              const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                                questionResponse1.filter(
                                                  (d) => d.linkId === '2'
                                                )
                                              if (outerFilter.length === 0) {
                                                setQuestionResponse4([outerRes])
                                              }

                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString: moment(
                                                    changedValue.target.value
                                                  ).format('DD-MM-YYYY'),
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse13(
                                                innerRes
                                              )
                                            },
                                          }}
                                        />

                                        {/* {innerQuestionResponse12 &&
                                          innerQuestionResponse12.answer &&
                                          innerQuestionResponse12.answer[0]
                                            .valueString &&
                                          innerQuestionResponse13 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )} */}
                                        {innerQuestionResponse12 &&
                                          innerQuestionResponse12.answer &&
                                          innerQuestionResponse12.answer[0]
                                            .valueString &&
                                          innerQuestionResponse13 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}

                                        {innerQuestionResponse12 &&
                                          innerQuestionResponse12.answer &&
                                          innerQuestionResponse12.answer[0] &&
                                          innerQuestionResponse12.answer[0]
                                            .valueString?.length === 0 &&
                                          innerQuestionResponse14 &&
                                          innerQuestionResponse13 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}

                                        {innerQuestionResponse12 &&
                                          innerQuestionResponse12.answer ===
                                            undefined &&
                                          innerQuestionResponse14 &&
                                          innerQuestionResponse13 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}
                                        {innerQuestionResponse12 ===
                                          undefined &&
                                          innerQuestionResponse14 &&
                                          innerQuestionResponse13 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}
                                      </Box>
                                    </Box>
                                  )}

                                  {qIn1.linkId === '2.3' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                    >
                                      <WelloTextField
                                        title={qIn1.text}
                                        textProps={{
                                          id: `${id}_sadhka2_depart_date`,
                                          type: 'date',
                                          inputProps: {
                                            max: moment(new Date()).format(
                                              'YYYY-MM-DD'
                                            ),
                                            min: travelDate3,
                                          },
                                          onKeyDown: (e) => {
                                            e.preventDefault()
                                          },
                                          onChange: (changedValue) => {
                                            setTravelDate6(
                                              moment(
                                                changedValue.target.value
                                              ).format('YYYY-MM-DD')
                                            )
                                            const outerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: itemData.linkId,
                                                text: itemData.text,
                                              }
                                            const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                              questionResponse1.filter(
                                                (d) => d.linkId === '2'
                                              )
                                            if (outerFilter.length === 0) {
                                              setQuestionResponse4([outerRes])
                                            }

                                            const ans1: R4.IQuestionnaireResponse_Answer =
                                              {
                                                valueString: moment(
                                                  changedValue.target.value
                                                ).format('DD-MM-YYYY'),
                                              }

                                            const innerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: qIn1.linkId,
                                                text: qIn1.text,
                                                answer: [ans1],
                                              }
                                            setInnerQuestionResponse14(innerRes)
                                          },
                                        }}
                                      />
                                      {innerQuestionResponse12 &&
                                        innerQuestionResponse12.answer &&
                                        innerQuestionResponse12.answer[0] &&
                                        innerQuestionResponse12.answer[0]
                                          .valueString?.length === 0 &&
                                        innerQuestionResponse13 &&
                                        innerQuestionResponse14 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse12 &&
                                        innerQuestionResponse12.answer ===
                                          undefined &&
                                        innerQuestionResponse13 &&
                                        innerQuestionResponse14 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}
                                      {innerQuestionResponse12 === undefined &&
                                        innerQuestionResponse13 &&
                                        innerQuestionResponse14 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}
                                      {innerQuestionResponse12 &&
                                        innerQuestionResponse12.answer &&
                                        innerQuestionResponse12.answer[0]
                                          .valueString &&
                                        innerQuestionResponse14 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}
                                    </Box>
                                  )}
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        )}

                        {itemData.linkId === '3' && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            px={2}
                            py={1}
                          >
                            <Typography variant='subtitle1' color='primary'>
                              3. {itemData.text}
                            </Typography>

                            {itemData.item.map((qIn2, qindex) => (
                              <Box
                                key={qIn2.linkId}
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                {qIn2.linkId === '3.1' && (
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                  >
                                    <Box
                                      display='flex'
                                      flexGrow={1}
                                      alignItems='flex-end'
                                      justifyContent='center'
                                      p={0.5}
                                      width='40%'
                                    >
                                      <WelloSelectFHIRCoding
                                        id={`${id}${qindex}sadhakaqforcovid`}
                                        title={qIn2.text}
                                        availableCodings={answerOptions}
                                        preSelectedCoding={answerOptions[0]}
                                        onChanged={(type) => {
                                          const outerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.linkId,
                                              text: itemData.text,
                                            }
                                          const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                            questionResponse2.filter(
                                              (d) => d.linkId === '1.1'
                                            )
                                          if (outerFilter.length === 0) {
                                            setQuestionResponse2([outerRes])
                                          }

                                          const ans1: R4.IQuestionnaireResponse_Answer =
                                            {
                                              valueCoding: type,
                                            }

                                          const innerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: qIn2.linkId,
                                              text: qIn2.text,
                                              answer: [ans1],
                                            }
                                          setInnerQuestionResponse8(innerRes)
                                        }}
                                        textFieldProps={{
                                          size: 'small',
                                          fullWidth: true,
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                )}
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
                {!ipdFinsih.additionSuccessful && (
                  <Box width='100%'>
                    {questions.item.map((itemData, index) => (
                      <Box key={itemData.linkId}>
                        {itemData.linkId === '1.1' && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <Typography variant='subtitle1' color='primary'>
                              1. {itemData.text}
                            </Typography>

                            {itemData.item.map((qIn, qindex) => (
                              <Box
                                key={qIn.linkId}
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                px={2}
                              >
                                {qIn.type === 'choice' &&
                                  qIn.linkId === '1.1.1' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <WelloSelectFHIRCoding
                                        id={`${id}${qindex}patientans1`}
                                        title={qIn.text}
                                        availableCodings={answerOptions}
                                        preSelectedCoding={answerOptions[0]}
                                        onChanged={(type) => {
                                          const outerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.linkId,
                                              text: itemData.text,
                                            }
                                          const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                            questionResponse.filter(
                                              (d) => d.linkId === '1.1'
                                            )
                                          if (outerFilter.length === 0) {
                                            setQuestionResponse([outerRes])
                                          }

                                          const ans1: R4.IQuestionnaireResponse_Answer =
                                            {
                                              valueCoding: type,
                                            }

                                          const innerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: qIn.linkId,
                                              text: qIn.text,
                                              answer: [ans1],
                                            }
                                          setInnerQuestionResponse1(innerRes)
                                        }}
                                        textFieldProps={{
                                          size: 'small',
                                          fullWidth: true,
                                        }}
                                      />
                                    </Box>
                                  )}

                                {qIn.type === 'choice' &&
                                  qIn.linkId === '1.1.2' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <WelloSelectFHIRCoding
                                        title={qIn.text}
                                        id={`${id}${qindex}patientans2`}
                                        availableCodings={answerOptions}
                                        preSelectedCoding={answerOptions[0]}
                                        onChanged={(type) => {
                                          const outerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.linkId,
                                              text: itemData.text,
                                            }
                                          const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                            questionResponse.filter(
                                              (d) => d.linkId === '1.1'
                                            )
                                          if (outerFilter.length === 0) {
                                            setQuestionResponse([outerRes])
                                          }

                                          const ans1: R4.IQuestionnaireResponse_Answer =
                                            {
                                              valueCoding: type,
                                            }

                                          const innerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: qIn.linkId,
                                              text: qIn.text,
                                              answer: [ans1],
                                            }
                                          setInnerQuestionResponse2(innerRes)
                                        }}
                                        textFieldProps={{
                                          size: 'small',
                                          fullWidth: true,
                                        }}
                                      />
                                    </Box>
                                  )}

                                {qIn.type === 'choice' &&
                                  qIn.linkId === '1.1.3' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <WelloSelectFHIRCoding
                                        title={qIn.text}
                                        id={`${id}${qindex}patientans3`}
                                        availableCodings={answerOptions}
                                        preSelectedCoding={answerOptions[0]}
                                        onChanged={(type) => {
                                          const outerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.linkId,
                                              text: itemData.text,
                                            }
                                          const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                            questionResponse.filter(
                                              (d) => d.linkId === '1.1'
                                            )
                                          if (outerFilter.length === 0) {
                                            setQuestionResponse([outerRes])
                                          }

                                          const ans1: R4.IQuestionnaireResponse_Answer =
                                            {
                                              valueCoding: type,
                                            }

                                          const innerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: qIn.linkId,
                                              text: qIn.text,
                                              answer: [ans1],
                                            }
                                          setInnerQuestionResponse3(innerRes)
                                        }}
                                        textFieldProps={{
                                          size: 'small',
                                          fullWidth: true,
                                        }}
                                      />
                                    </Box>
                                  )}

                                {qIn.type === 'choice' &&
                                  qIn.linkId === '1.1.4' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <WelloSelectFHIRCoding
                                        id={`${id}${qindex}patientans4`}
                                        title={qIn.text}
                                        availableCodings={answerOptions}
                                        preSelectedCoding={answerOptions[0]}
                                        onChanged={(type) => {
                                          const outerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.linkId,
                                              text: itemData.text,
                                            }
                                          const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                            questionResponse.filter(
                                              (d) => d.linkId === '1.1'
                                            )
                                          if (outerFilter.length === 0) {
                                            setQuestionResponse([outerRes])
                                          }

                                          const ans1: R4.IQuestionnaireResponse_Answer =
                                            {
                                              valueCoding: type,
                                            }

                                          const innerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: qIn.linkId,
                                              text: qIn.text,
                                              answer: [ans1],
                                            }
                                          setInnerQuestionResponse4(innerRes)
                                        }}
                                        textFieldProps={{
                                          size: 'small',
                                          fullWidth: true,
                                        }}
                                      />
                                    </Box>
                                  )}
                              </Box>
                            ))}
                          </Box>
                        )}

                        {itemData.linkId === '2' && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            px={2}
                            py={2}
                          >
                            <Typography variant='subtitle1' color='primary'>
                              2. {itemData.text}
                            </Typography>

                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              py={1}
                            >
                              {itemData.item.map((qIn1, qindex) => (
                                <Box
                                  key={qIn1.linkId}
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                >
                                  {qIn1.linkId === '2.1' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                    >
                                      <WelloTextField
                                        title={qIn1.text}
                                        textProps={{
                                          id: `${id}patient_country`,

                                          type: 'text',
                                          placeholder: 'Country/City',

                                          onChange: (discounData) => {
                                            const outerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: itemData.linkId,
                                                text: itemData.text,
                                              }
                                            const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                              questionResponse1.filter(
                                                (d) => d.linkId === '2'
                                              )
                                            if (outerFilter.length === 0) {
                                              setQuestionResponse1([outerRes])
                                            }
                                            if (
                                              discounData.target.value.length >
                                              0
                                            ) {
                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString:
                                                    discounData.target.value,
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse6(
                                                innerRes
                                              )
                                            } else {
                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString: '',
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse6(
                                                innerRes
                                              )
                                            }
                                          },
                                        }}
                                      />
                                      {(innerQuestionResponse5 ||
                                        innerQuestionResponse7) &&
                                        innerQuestionResponse6 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse6 &&
                                        innerQuestionResponse6.answer &&
                                        innerQuestionResponse6.answer[0] &&
                                        innerQuestionResponse6.answer[0]
                                          .valueString === undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {/* <Box>
                                        <Typography
                                          variant='subtitle2'
                                          color='error'
                                        >
                                          {innerQuestionResponse6
                                            ? innerQuestionResponse6.answer
                                              ? innerQuestionResponse6.answer[0]
                                                ? innerQuestionResponse6
                                                    .answer[0].valueString
                                                  ? innerQuestionResponse6
                                                      .answer[0].valueString
                                                  : innerQuestionResponse6
                                                      .answer[0].valueString
                                                      ?.length
                                                : '2'
                                              : '3'
                                            : '4'}
                                        </Typography>
                                      </Box> */}

                                      {(innerQuestionResponse5 ||
                                        innerQuestionResponse7) &&
                                        innerQuestionResponse6 &&
                                        innerQuestionResponse6.answer &&
                                        innerQuestionResponse6.answer[0] &&
                                        innerQuestionResponse6.answer[0]
                                          .valueString?.length === 0 && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}
                                    </Box>
                                  )}

                                  {qIn1.linkId === '2.2' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      px={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        <WelloTextField
                                          title={qIn1.text}
                                          textProps={{
                                            id: `${id}patient_arrival_date`,
                                            type: 'date',
                                            inputProps: {
                                              max: moment(new Date()).format(
                                                'YYYY-MM-DD'
                                              ),
                                              min: isCurrentDate(travelDate4)
                                                ? moment()
                                                    .subtract(2, 'days')
                                                    .format('YYYY-MM-DD')
                                                : travelDate4,
                                            },
                                            onKeyDown: (e) => {
                                              e.preventDefault()
                                            },
                                            onChange: (changedValue) => {
                                              changedValue.preventDefault()
                                              setTravelDate1(
                                                moment(
                                                  changedValue.target.value
                                                ).format('YYYY-MM-DD')
                                              )
                                              const outerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: itemData.linkId,
                                                  text: itemData.text,
                                                }
                                              const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                                questionResponse1.filter(
                                                  (d) => d.linkId === '2'
                                                )
                                              if (outerFilter.length === 0) {
                                                setQuestionResponse1([outerRes])
                                              }

                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString: moment(
                                                    changedValue.target.value
                                                  ).format('DD-MM-YYYY'),
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse5(
                                                innerRes
                                              )
                                            },
                                          }}
                                        />
                                        {innerQuestionResponse6 &&
                                          innerQuestionResponse6.answer &&
                                          innerQuestionResponse6.answer[0]
                                            .valueString &&
                                          innerQuestionResponse5 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}

                                        {innerQuestionResponse6 &&
                                          innerQuestionResponse6.answer &&
                                          innerQuestionResponse6.answer[0] &&
                                          innerQuestionResponse6.answer[0]
                                            .valueString?.length === 0 &&
                                          innerQuestionResponse7 &&
                                          innerQuestionResponse5 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}

                                        {innerQuestionResponse6 &&
                                          innerQuestionResponse6.answer ===
                                            undefined &&
                                          innerQuestionResponse7 &&
                                          innerQuestionResponse5 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}
                                        {innerQuestionResponse6 === undefined &&
                                          innerQuestionResponse7 &&
                                          innerQuestionResponse5 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}
                                      </Box>
                                    </Box>
                                  )}

                                  {qIn1.linkId === '2.3' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                    >
                                      <WelloTextField
                                        title={qIn1.text}
                                        textProps={{
                                          id: `${id}patient_depart_date`,
                                          type: 'date',
                                          inputProps: {
                                            max: moment(new Date()).format(
                                              'YYYY-MM-DD'
                                            ),
                                            min: travelDate1,
                                          },
                                          onKeyDown: (e) => {
                                            e.preventDefault()
                                          },
                                          onChange: (changedValue) => {
                                            setTravelDate4(
                                              moment(
                                                changedValue.target.value
                                              ).format('YYYY-MM-DD')
                                            )
                                            const outerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: itemData.linkId,
                                                text: itemData.text,
                                              }
                                            const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                              questionResponse1.filter(
                                                (d) => d.linkId === '2'
                                              )
                                            if (outerFilter.length === 0) {
                                              setQuestionResponse1([outerRes])
                                            }

                                            const ans1: R4.IQuestionnaireResponse_Answer =
                                              {
                                                valueString: moment(
                                                  changedValue.target.value
                                                ).format('DD-MM-YYYY'),
                                              }

                                            const innerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: qIn1.linkId,
                                                text: qIn1.text,
                                                answer: [ans1],
                                              }
                                            setInnerQuestionResponse7(innerRes)
                                          },
                                        }}
                                      />
                                      {innerQuestionResponse6 &&
                                        innerQuestionResponse6.answer &&
                                        innerQuestionResponse6.answer[0]
                                          .valueString &&
                                        innerQuestionResponse7 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse6 &&
                                        innerQuestionResponse6.answer &&
                                        innerQuestionResponse6.answer[0] &&
                                        innerQuestionResponse6.answer[0]
                                          .valueString?.length === 0 &&
                                        innerQuestionResponse5 &&
                                        innerQuestionResponse7 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse6 &&
                                        innerQuestionResponse6.answer ===
                                          undefined &&
                                        innerQuestionResponse5 &&
                                        innerQuestionResponse7 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}
                                      {innerQuestionResponse6 === undefined &&
                                        innerQuestionResponse5 &&
                                        innerQuestionResponse7 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}
                                    </Box>
                                  )}
                                </Box>
                              ))}
                            </Box>

                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              {itemData.item.map((qIn1, qindex) => (
                                <Box
                                  key={qIn1.linkId}
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  {qIn1.linkId === '2.1' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                    >
                                      <WelloTextField
                                        title={qIn1.text}
                                        textProps={{
                                          id: `${id}patient1_country`,

                                          type: 'text',
                                          placeholder: 'Country/City',

                                          onChange: (discounData) => {
                                            const outerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: itemData.linkId,
                                                text: itemData.text,
                                              }
                                            const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                              questionResponse1.filter(
                                                (d) => d.linkId === '2'
                                              )
                                            if (outerFilter.length === 0) {
                                              setQuestionResponse3([outerRes])
                                            }
                                            if (
                                              discounData.target.value.length >
                                              0
                                            ) {
                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString:
                                                    discounData.target.value,
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse9(
                                                innerRes
                                              )
                                            } else {
                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString: '',
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse9(
                                                innerRes
                                              )
                                            }
                                          },
                                        }}
                                      />
                                      {(innerQuestionResponse10 ||
                                        innerQuestionResponse11) &&
                                        innerQuestionResponse9 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse9 &&
                                        innerQuestionResponse9.answer &&
                                        innerQuestionResponse9.answer[0] &&
                                        innerQuestionResponse9.answer[0]
                                          .valueString === undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {/* <Box>
                                        <Typography
                                          variant='subtitle2'
                                          color='error'
                                        >
                                          {innerQuestionResponse6
                                            ? innerQuestionResponse6.answer
                                              ? innerQuestionResponse6.answer[0]
                                                ? innerQuestionResponse6
                                                    .answer[0].valueString
                                                  ? innerQuestionResponse6
                                                      .answer[0].valueString
                                                  : innerQuestionResponse6
                                                      .answer[0].valueString
                                                      ?.length
                                                : '2'
                                              : '3'
                                            : '4'}
                                        </Typography>
                                      </Box> */}

                                      {(innerQuestionResponse10 ||
                                        innerQuestionResponse11) &&
                                        innerQuestionResponse9 &&
                                        innerQuestionResponse9.answer &&
                                        innerQuestionResponse9.answer[0] &&
                                        innerQuestionResponse9.answer[0]
                                          .valueString?.length === 0 && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}
                                    </Box>
                                  )}

                                  {qIn1.linkId === '2.2' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      px={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        <WelloTextField
                                          title={qIn1.text}
                                          textProps={{
                                            id: `${id}patient_arrival_date`,
                                            type: 'date',
                                            inputProps: {
                                              max: moment(new Date()).format(
                                                'YYYY-MM-DD'
                                              ),
                                              min: isCurrentDate(travelDate5)
                                                ? moment()
                                                    .subtract(2, 'days')
                                                    .format('YYYY-MM-DD')
                                                : travelDate5,
                                            },
                                            onKeyDown: (e) => {
                                              e.preventDefault()
                                            },
                                            onChange: (changedValue) => {
                                              setTravelDate2(
                                                moment(
                                                  changedValue.target.value
                                                ).format('YYYY-MM-DD')
                                              )
                                              const outerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: itemData.linkId,
                                                  text: itemData.text,
                                                }
                                              const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                                questionResponse1.filter(
                                                  (d) => d.linkId === '2'
                                                )
                                              if (outerFilter.length === 0) {
                                                setQuestionResponse3([outerRes])
                                              }

                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString: moment(
                                                    changedValue.target.value
                                                  ).format('DD-MM-YYYY'),
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse10(
                                                innerRes
                                              )
                                            },
                                          }}
                                        />

                                        {innerQuestionResponse9 &&
                                          innerQuestionResponse9.answer &&
                                          innerQuestionResponse9.answer[0]
                                            .valueString &&
                                          innerQuestionResponse10 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}

                                        {innerQuestionResponse9 &&
                                          innerQuestionResponse9.answer &&
                                          innerQuestionResponse9.answer[0] &&
                                          innerQuestionResponse9.answer[0]
                                            .valueString?.length === 0 &&
                                          innerQuestionResponse11 &&
                                          innerQuestionResponse10 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}

                                        {innerQuestionResponse9 &&
                                          innerQuestionResponse9.answer ===
                                            undefined &&
                                          innerQuestionResponse11 &&
                                          innerQuestionResponse10 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}
                                        {innerQuestionResponse9 === undefined &&
                                          innerQuestionResponse11 &&
                                          innerQuestionResponse10 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}
                                      </Box>
                                    </Box>
                                  )}

                                  {qIn1.linkId === '2.3' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                    >
                                      <WelloTextField
                                        title={qIn1.text}
                                        textProps={{
                                          id: `${id}patient_depart_date`,
                                          type: 'date',
                                          inputProps: {
                                            max: moment(new Date()).format(
                                              'YYYY-MM-DD'
                                            ),
                                            min: travelDate2,
                                          },
                                          onKeyDown: (e) => {
                                            e.preventDefault()
                                          },
                                          onChange: (changedValue) => {
                                            setTravelDate5(
                                              moment(
                                                changedValue.target.value
                                              ).format('YYYY-MM-DD')
                                            )
                                            const outerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: itemData.linkId,
                                                text: itemData.text,
                                              }
                                            const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                              questionResponse1.filter(
                                                (d) => d.linkId === '2'
                                              )
                                            if (outerFilter.length === 0) {
                                              setQuestionResponse3([outerRes])
                                            }

                                            const ans1: R4.IQuestionnaireResponse_Answer =
                                              {
                                                valueString: moment(
                                                  changedValue.target.value
                                                ).format('DD-MM-YYYY'),
                                              }

                                            const innerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: qIn1.linkId,
                                                text: qIn1.text,
                                                answer: [ans1],
                                              }
                                            setInnerQuestionResponse11(innerRes)
                                          },
                                        }}
                                      />
                                      {innerQuestionResponse9 &&
                                        innerQuestionResponse9.answer &&
                                        innerQuestionResponse9.answer[0]
                                          .valueString &&
                                        innerQuestionResponse11 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse9 &&
                                        innerQuestionResponse9.answer &&
                                        innerQuestionResponse9.answer[0] &&
                                        innerQuestionResponse9.answer[0]
                                          .valueString?.length === 0 &&
                                        innerQuestionResponse10 &&
                                        innerQuestionResponse11 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse9 &&
                                        innerQuestionResponse9.answer ===
                                          undefined &&
                                        innerQuestionResponse10 &&
                                        innerQuestionResponse11 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}
                                      {innerQuestionResponse9 === undefined &&
                                        innerQuestionResponse10 &&
                                        innerQuestionResponse11 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}
                                    </Box>
                                  )}
                                </Box>
                              ))}
                            </Box>

                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              py={1}
                            >
                              {itemData.item.map((qIn1, qindex) => (
                                <Box
                                  key={qIn1.linkId}
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  {qIn1.linkId === '2.1' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                    >
                                      <WelloTextField
                                        title={qIn1.text}
                                        textProps={{
                                          id: `${id}patient2_country`,

                                          type: 'text',
                                          placeholder: 'Country/City',

                                          onChange: (discounData) => {
                                            const outerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: itemData.linkId,
                                                text: itemData.text,
                                              }
                                            const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                              questionResponse1.filter(
                                                (d) => d.linkId === '2'
                                              )
                                            if (outerFilter.length === 0) {
                                              setQuestionResponse4([outerRes])
                                            }

                                            if (
                                              discounData.target.value.length >
                                              0
                                            ) {
                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString:
                                                    discounData.target.value,
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse12(
                                                innerRes
                                              )
                                            } else {
                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString: '',
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse12(
                                                innerRes
                                              )
                                            }
                                          },
                                        }}
                                      />
                                      {(innerQuestionResponse13 ||
                                        innerQuestionResponse14) &&
                                        innerQuestionResponse12 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse12 &&
                                        innerQuestionResponse12.answer &&
                                        innerQuestionResponse12.answer[0] &&
                                        innerQuestionResponse12.answer[0]
                                          .valueString === undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {/* <Box>
                                        <Typography
                                          variant='subtitle2'
                                          color='error'
                                        >
                                          {innerQuestionResponse6
                                            ? innerQuestionResponse6.answer
                                              ? innerQuestionResponse6.answer[0]
                                                ? innerQuestionResponse6
                                                    .answer[0].valueString
                                                  ? innerQuestionResponse6
                                                      .answer[0].valueString
                                                  : innerQuestionResponse6
                                                      .answer[0].valueString
                                                      ?.length
                                                : '2'
                                              : '3'
                                            : '4'}
                                        </Typography>
                                      </Box> */}

                                      {(innerQuestionResponse13 ||
                                        innerQuestionResponse14) &&
                                        innerQuestionResponse12 &&
                                        innerQuestionResponse12.answer &&
                                        innerQuestionResponse12.answer[0] &&
                                        innerQuestionResponse12.answer[0]
                                          .valueString?.length === 0 && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Country is required
                                            </Typography>
                                          </Box>
                                        )}
                                    </Box>
                                  )}

                                  {qIn1.linkId === '2.2' && (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      px={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        <WelloTextField
                                          title={qIn1.text}
                                          textProps={{
                                            id: `${id}patient_arrival_date`,
                                            type: 'date',
                                            inputProps: {
                                              max: moment(new Date()).format(
                                                'YYYY-MM-DD'
                                              ),
                                              min: isCurrentDate(travelDate6)
                                                ? moment()
                                                    .subtract(2, 'days')
                                                    .format('YYYY-MM-DD')
                                                : travelDate6,
                                            },
                                            onKeyDown: (e) => {
                                              e.preventDefault()
                                            },
                                            onChange: (changedValue) => {
                                              setTravelDate3(
                                                moment(
                                                  changedValue.target.value
                                                ).format('YYYY-MM-DD')
                                              )
                                              const outerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: itemData.linkId,
                                                  text: itemData.text,
                                                }
                                              const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                                questionResponse1.filter(
                                                  (d) => d.linkId === '2'
                                                )
                                              if (outerFilter.length === 0) {
                                                setQuestionResponse4([outerRes])
                                              }

                                              const ans1: R4.IQuestionnaireResponse_Answer =
                                                {
                                                  valueString: moment(
                                                    changedValue.target.value
                                                  ).format('DD-MM-YYYY'),
                                                }

                                              const innerRes: R4.IQuestionnaireResponse_Item =
                                                {
                                                  linkId: qIn1.linkId,
                                                  text: qIn1.text,
                                                  answer: [ans1],
                                                }
                                              setInnerQuestionResponse13(
                                                innerRes
                                              )
                                            },
                                          }}
                                        />

                                        {innerQuestionResponse12 &&
                                          innerQuestionResponse12.answer &&
                                          innerQuestionResponse12.answer[0]
                                            .valueString &&
                                          innerQuestionResponse13 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}

                                        {innerQuestionResponse12 &&
                                          innerQuestionResponse12.answer &&
                                          innerQuestionResponse12.answer[0] &&
                                          innerQuestionResponse12.answer[0]
                                            .valueString?.length === 0 &&
                                          innerQuestionResponse14 &&
                                          innerQuestionResponse13 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}

                                        {innerQuestionResponse12 &&
                                          innerQuestionResponse12.answer ===
                                            undefined &&
                                          innerQuestionResponse14 &&
                                          innerQuestionResponse13 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}
                                        {innerQuestionResponse12 ===
                                          undefined &&
                                          innerQuestionResponse14 &&
                                          innerQuestionResponse13 ===
                                            undefined && (
                                            <Box>
                                              <Typography
                                                variant='subtitle2'
                                                color='error'
                                              >
                                                Arrival date is required
                                              </Typography>
                                            </Box>
                                          )}
                                      </Box>
                                    </Box>
                                  )}

                                  {qIn1.linkId === '2.3' && (
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                    >
                                      <WelloTextField
                                        title={qIn1.text}
                                        textProps={{
                                          id: `${id}patient_depart_date`,
                                          type: 'date',
                                          inputProps: {
                                            max: moment(new Date()).format(
                                              'YYYY-MM-DD'
                                            ),
                                            min: travelDate3,
                                          },
                                          onKeyDown: (e) => {
                                            e.preventDefault()
                                          },
                                          onChange: (changedValue) => {
                                            setTravelDate6(
                                              moment(
                                                changedValue.target.value
                                              ).format('YYYY-MM-DD')
                                            )
                                            const outerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: itemData.linkId,
                                                text: itemData.text,
                                              }
                                            const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                              questionResponse1.filter(
                                                (d) => d.linkId === '2'
                                              )
                                            if (outerFilter.length === 0) {
                                              setQuestionResponse4([outerRes])
                                            }

                                            const ans1: R4.IQuestionnaireResponse_Answer =
                                              {
                                                valueString: moment(
                                                  changedValue.target.value
                                                ).format('DD-MM-YYYY'),
                                              }

                                            const innerRes: R4.IQuestionnaireResponse_Item =
                                              {
                                                linkId: qIn1.linkId,
                                                text: qIn1.text,
                                                answer: [ans1],
                                              }
                                            setInnerQuestionResponse14(innerRes)
                                          },
                                        }}
                                      />

                                      {innerQuestionResponse12 &&
                                        innerQuestionResponse12.answer &&
                                        innerQuestionResponse12.answer[0]
                                          .valueString &&
                                        innerQuestionResponse14 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse12 &&
                                        innerQuestionResponse12.answer &&
                                        innerQuestionResponse12.answer[0] &&
                                        innerQuestionResponse12.answer[0]
                                          .valueString?.length === 0 &&
                                        innerQuestionResponse13 &&
                                        innerQuestionResponse14 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}

                                      {innerQuestionResponse12 &&
                                        innerQuestionResponse12.answer ===
                                          undefined &&
                                        innerQuestionResponse13 &&
                                        innerQuestionResponse14 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}
                                      {innerQuestionResponse12 === undefined &&
                                        innerQuestionResponse13 &&
                                        innerQuestionResponse14 ===
                                          undefined && (
                                          <Box>
                                            <Typography
                                              variant='subtitle2'
                                              color='error'
                                            >
                                              Departure date is required
                                            </Typography>
                                          </Box>
                                        )}
                                    </Box>
                                  )}
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        )}

                        {itemData.linkId === '3' && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            px={2}
                            py={1}
                          >
                            <Typography variant='subtitle1' color='primary'>
                              3. {itemData.text}
                            </Typography>

                            {itemData.item.map((qIn2, qindex) => (
                              <Box
                                key={qIn2.linkId}
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                {qIn2.linkId === '3.1' && (
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                  >
                                    <Box
                                      display='flex'
                                      flexGrow={1}
                                      alignItems='flex-end'
                                      justifyContent='center'
                                      p={0.5}
                                      width='40%'
                                    >
                                      <WelloSelectFHIRCoding
                                        title={qIn2.text}
                                        id={`${id}${qindex}patientcovidans`}
                                        availableCodings={answerOptions}
                                        preSelectedCoding={answerOptions[0]}
                                        onChanged={(type) => {
                                          const outerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: itemData.linkId,
                                              text: itemData.text,
                                            }
                                          const outerFilter: R4.IQuestionnaireResponse_Item[] =
                                            questionResponse2.filter(
                                              (d) => d.linkId === '1.1'
                                            )
                                          if (outerFilter.length === 0) {
                                            setQuestionResponse2([outerRes])
                                          }

                                          const ans1: R4.IQuestionnaireResponse_Answer =
                                            {
                                              valueCoding: type,
                                            }

                                          const innerRes: R4.IQuestionnaireResponse_Item =
                                            {
                                              linkId: qIn2.linkId,
                                              text: qIn2.text,
                                              answer: [ans1],
                                            }
                                          setInnerQuestionResponse8(innerRes)
                                        }}
                                        textFieldProps={{
                                          size: 'small',
                                          fullWidth: true,
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                )}
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
                <Box width='50%' px={2}>
                  <Button
                    id={`${id}consent`}
                    variant='contained'
                    size='small'
                    color='primary'
                    disabled={checked}
                    onClick={() => {
                      setShowConditions(true)
                    }}
                  >
                    <Typography variant='subtitle2'>
                      {' '}
                      Consent, Rules & Regulations
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
            <WebCamCapturePhoto
              id={`${id}consent`}
              open={openWebCam}
              cropAspectRatio={16 / 9}
              onBackClick={() => {
                setOpenWebCam(false)
              }}
              onContinueClick={(imgSrc) => {
                const data = imgSrc
                  .toString()
                  .split(',')[0]
                  .replace('data:', '')
                  .replace(';base64', '')
                callBackForCapturePhoto(
                  undefined,
                  imgSrc!.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)![0]!,
                  imgSrc
                )
                /* const data = imgSrc
                                .toString()
                                .split(',')[0]
                                .replace('data:', '')
                                .replace(';base64', '')
                              values.imgName = imgSrc.toString().split(',')[1]
                              values.profilePhotoMimeType = data
                              values.photoUri = imgSrc */
              }}
            />
          </Box>
        )}
        {showDeleteConfirmation.open && (
          <ConfirmationDialog
            {...showDeleteConfirmation}
            id={`${id}confirmDialog`}
          />
        )}

        <ConsentDialog
          id={`${id}`}
          open={showConditions}
          data={false}
          onClose={(checkData: boolean) => {
            setShowConditions(false)
            setChecked(checkData)
          }}
        />

        {selectedPatient && visaDetails === true && (
          <AddVisaDetails
            patient={selectedPatient}
            open={visaDetails}
            onClose={(checkData: boolean) => {
              setVisaDetails(false)
              setCheckedForForeign(false)
            }}
            onVisaDetailsAdded={(visaData?: string) => {
              if (visaData) {
                setVisaDataFinal(visaData)
              }
              dispatch(resetUpdateUserState())
              setCheckedForForeign(false)
              setForeign(false)
              setVisaDetails(false)
            }}
          />
        )}

        <NoteDialog
          id={`${id}notes`}
          open={showText}
          onClose={() => {
            setFullText('')
            setShowText(false)
          }}
          notesData={fullText!}
          dialogText='Message'
        />

        <NoteDialog
          id='603'
          open={showTextForPatient}
          onClose={() => {
            setFullTextForPatient('')
            setShowTextForPatient(false)
          }}
          notesData={fullTextForPatient!}
          patient={selectedPatient!}
          dialogText='Message'
        />
      </DialogContent>

      <DialogActions>
        {(ipdRegisterSlice.adding ||
          ipdFinsih.adding ||
          ipdFinsihSadhka.adding) && <CircularProgress size={30} />}
        {!ipdRegisterSlice.additionSuccessful && (
          <Box paddingRight={selectedPatient && selectedRoomType ? 3.8 : 1.6}>
            <Button
              onClick={() => {
                if (
                  selectedRoomType &&
                  selectedCharef &&
                  selectedPatient &&
                  endDate.length > 0 &&
                  roomNo &&
                  roomNo.length > 0
                ) {
                  dispatch(
                    registerIpdPatient(
                      startDate,
                      moment(endDate).toDate(),
                      discount,
                      getDateDifferceinDays(
                        moment(startDate).format('DD-MM-YYYY'),
                        moment(endDate).format('DD-MM-YYYY')
                      ),
                      roomNo,
                      selectedServiceMedicine!,
                      selectedVistPurpose,
                      selectedRoomType,
                      selectedCharef,
                      selectedPatient,
                      referralId,
                      visaDataFinal
                    )
                  )
                }
              }}
              variant='contained'
              color='primary'
              disableElevation
              disabled={
                !isEnableCreateButton() ||
                selectedServiceMedicine === undefined ||
                discount < 0 ||
                discount > 100 ||
                Number.isNaN(discount) ||
                (foreign &&
                  getExtensionValueOfUrlPatient(
                    selectedPatient ? selectedPatient.extension ?? [] : [],
                    'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                  ) !== 'IN' &&
                  getExtensionValueOfUrlPatient(
                    selectedPatient ? selectedPatient.extension ?? [] : [],
                    'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                  ) !== 'India' &&
                  getExtensionValueOfUrlPatient(
                    selectedPatient ? selectedPatient.extension ?? [] : [],
                    'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                  ) !== 'india')
              }
              id={`${id}continue_btn`}
            >
              Continue
            </Button>
          </Box>
        )}

        {ipdRegisterSlice.additionSuccessful &&
          !ipdFinsih.adding &&
          !ipdFinsih.additionSuccessful && (
            <Button
              onClick={() => {
                if (
                  questionResponse.length > 0 &&
                  questionResponse2.length > 0 &&
                  innerQuestionResponse1 &&
                  innerQuestionResponse2 &&
                  innerQuestionResponse3 &&
                  innerQuestionResponse4 &&
                  innerQuestionResponse8 &&
                  checked
                ) {
                  dispatch(
                    finshIpdOnboarding(
                      value,
                      photoType,
                      attendant,
                      // height,
                      selectedPatient ? selectedPatient.id ?? '' : '',
                      serviceRequest || '',
                      sadhakData,
                      questionResponse,
                      innerQuestionResponse1,
                      innerQuestionResponse2,
                      innerQuestionResponse3,
                      innerQuestionResponse4,
                      questionResponse2,
                      innerQuestionResponse8,
                      questionResponse1,
                      questionResponse3,
                      questionResponse4,
                      innerQuestionResponse5,
                      innerQuestionResponse6,
                      innerQuestionResponse7,

                      innerQuestionResponse9,
                      innerQuestionResponse10,
                      innerQuestionResponse11,

                      innerQuestionResponse12,
                      innerQuestionResponse13,
                      innerQuestionResponse14,

                      selectedPatient,
                      referralId
                    )
                  )
                }
              }}
              variant='contained'
              color='primary'
              disableElevation
              disabled={
                !isFinsihButtonEnabled() ||
                isAttendantsDataFilled() ||
                (photoType === 'both' &&
                  value !== 'camera' &&
                  isBackBothImageUploaded()) ||
                (photoType === 'seperate' && isBackImageUploaded()) ||
                (photoType === 'seperate' && isFrontImageUploaded()) ||
                (value === 'camera' && isFrontImageUploaded()) ||
                (value === 'camera' && isBackImageUploaded()) ||
                isAttendantGenerNotDataFilled() ||
                isAttendantDOBNotDataFilled() ||
                isRoomDataFilled() ||
                isCovidDataFilledForOne() ||
                isCovidDataFilledForTwo() ||
                isAttendantsIdProofFilled() ||
                isCovidDataFilledForThree()
              }
              id={`${id}finish_btn`}
            >
              Finish
            </Button>
          )}

        {ipdFinsih.additionSuccessful && !ipdFinsihSadhka.adding && (
          <Button
            onClick={() => {
              if (
                questionResponse.length > 0 &&
                questionResponse2.length > 0 &&
                innerQuestionResponse1 &&
                innerQuestionResponse2 &&
                innerQuestionResponse3 &&
                innerQuestionResponse4 &&
                innerQuestionResponse8 &&
                checked
              ) {
                dispatch(
                  finshIpdOnboardingForSadhka(
                    sadhaFinsh!.patient!.id ?? '',
                    sadhaFinsh!.serviceId ?? '',
                    questionResponse,
                    innerQuestionResponse1,
                    innerQuestionResponse2,
                    innerQuestionResponse3,
                    innerQuestionResponse4,
                    questionResponse2,
                    innerQuestionResponse8,
                    questionResponse1,
                    questionResponse3,
                    questionResponse4,
                    innerQuestionResponse5,
                    innerQuestionResponse6,
                    innerQuestionResponse7,

                    innerQuestionResponse9,
                    innerQuestionResponse10,
                    innerQuestionResponse11,

                    innerQuestionResponse12,
                    innerQuestionResponse13,
                    innerQuestionResponse14,

                    sadhaFinsh!.patient!
                  )
                )
              }
            }}
            variant='contained'
            color='primary'
            disableElevation
            disabled={
              !sadhaFinsh ||
              !isFinsihButtonEnabled() ||
              isCovidDataFilledForOne() ||
              isCovidDataFilledForTwo() ||
              isCovidDataFilledForThree()
            }
            id={`${id}complete_btn`}
          >
            Complete Registration
          </Button>
        )}
        {/* {ipdFinsihSadhka.additionSuccessful */}
      </DialogActions>
    </Dialog>
  )
}
