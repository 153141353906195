import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getStartAndEndTimeWithDateInStringForOrder } from 'utils/dateUtil'
import {
  getTelecomFromContactPoints,
  getValueCodingStringFromExtension,
} from 'utils/fhirResourcesHelper'

interface Props {
  start: string
  payment: R4.IPaymentReconciliation
  patient: R4.IPatient
}

export const PaymentTile: React.FC<Props> = ({
  start,
  payment,
  patient,
}: Props) => {
  const { t } = useTranslation()
  return (
    <Box width='100%'>
      <Card
        elevation={0}
        style={{
          padding: 0,
          margin: 0,
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <List
            id={`option_slot_${start}` ?? ''}
            style={{ flexGrow: 1, padding: 0 }}
          >
            <ListItem style={{ flexGrow: 1, padding: 0 }}>
              <ListItemText
                color='primary'
                primary={
                  <Box py={0.25}>
                    <Typography
                      variant='subtitle1'
                      color='primary'
                      style={{
                        fontWeight: 'bolder',
                        color: 'black',
                        wordWrap: 'break-word',
                      }}
                    >
                      {getValueCodingStringFromExtension(
                        payment.extension,
                        'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentMode'
                      ) === 'online'
                        ? t('labelCommon:online')
                        : t('labelCommon:cash')}{' '}
                      {t('labelCommon:payment')} (
                      {payment.outcome === 'queued'
                        ? t('labelCommon:in_progress')
                        : t('labelCommon:completed')}
                      )
                    </Typography>
                  </Box>
                }
                secondary={
                  <Box display='flex' flexDirection='column'>
                    {payment.outcome === 'queued' &&
                      getValueCodingStringFromExtension(
                        payment.extension,
                        'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentMode'
                      ) === 'online' && (
                        <Box>
                          <Box flex='flex' flexDirection='row' py={0.25}>
                            <Typography
                              component='span'
                              variant='subtitle2'
                              color='textPrimary'
                            >
                              {t('labelCommon:shared_payment_link')}
                            </Typography>
                          </Box>
                          <Box flex='flex' flexDirection='row' py={0.25}>
                            <Typography
                              component='span'
                              variant='body2'
                              color='textPrimary'
                            >
                              {`Mob: ${getTelecomFromContactPoints(
                                patient?.telecom ?? [],
                                R4.ContactPointSystemKind._phone
                              )}`}
                            </Typography>
                          </Box>

                          <Box flex='flex' flexDirection='row' py={0.25}>
                            <Typography
                              component='span'
                              variant='body2'
                              color='textPrimary'
                            >
                              {`${getStartAndEndTimeWithDateInStringForOrder(
                                start
                              )}`}
                            </Typography>
                          </Box>
                        </Box>
                      )}

                    <Box display='flex' flexDirection='column'>
                      {payment.outcome === 'complete' && (
                        <Box>
                          <Box flex='flex' flexDirection='row' py={0.25}>
                            <Typography
                              component='span'
                              variant='subtitle2'
                              color='textPrimary'
                            >
                              {`${t('labelCommon:transaction_id')}: ${
                                payment.id
                              }`}
                            </Typography>
                          </Box>
                          <Box flex='flex' flexDirection='row'>
                            <Typography
                              component='span'
                              variant='body2'
                              color='textPrimary'
                            >
                              {`${t('labelCommon:transaction_date')}: ${moment(
                                payment.meta?.lastUpdated
                              ).format('DD-MM-YY hh:mm:ss')}`}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Box flex='flex' flexDirection='row' py={0.25}>
                      <Typography
                        component='span'
                        variant='body2'
                        color='textPrimary'
                      >
                        {`${t('labelCommon:amount')} ₹ ${
                          payment.paymentAmount.value
                        }`}
                      </Typography>
                    </Box>

                    {/* <Box flex='flex' flexDirection='row' py={0.25}>
                    <Link
                      component='button'
                      variant='body2'
                      onClick={() => {
                        console.info("I'm a button.")
                      }}
                    >
                      Change Agent
                    </Link>
                  </Box> */}
                  </Box>
                }
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Box>
  )
}
