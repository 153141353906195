import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Grid,
  Divider,
  Tooltip,
  Link,
  Collapse,
  Button,
} from '@material-ui/core'
import AodIcon from '@mui/icons-material/Aod'

import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedCondition, GroupedConditionData } from 'models/groupedCondition'
import { PurposeOfUse } from 'models/purposeOfUse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestConditionHistoryOfPatient } from 'redux/patientMedicalHistory/medicalConditionHistory/medicalHistoryConditionsSlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getAgeOf, getTimeAgo } from 'utils/dateUtil'
import {
  getNotesFromAllergy,
  getNotesString,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { SOAPIndicativeElement } from 'wello-web-components'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { updateMedicalConditions } from 'utils/fhirResoureHelpers/appointmentHelpers'
import {
  getNameFromHumanName,
  getValueRefValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import { requestAssessmentMedicalConditions } from 'redux/consultation/assessmentMedicalCondition/medicalAssessmentSlice'
import OverflowTypography from 'views/components/consultation/plan/ayurveda/overflowTypography'

interface MedicalConditionsProps {
  referralData?: R4.IServiceRequest
  doctor?: R4.IPractitioner
  unit?: R4.IOrganization
}

export const ReferralTile: React.FC<MedicalConditionsProps> = ({
  referralData,
  doctor,
  unit,
}: MedicalConditionsProps) => {
  const [fullText, setFullText] = React.useState<string>()
  const [showText, setShowText] = React.useState<boolean>(false)

  const [fullTextForNotes, setFullTextForNotes] = React.useState<string>()
  const [showTextNotes, setShowTextNotes] = React.useState<boolean>(false)

  const [groupVitals, setGroupVitals] = useState<GroupedConditionData[]>([])
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <Box width='100%' flexDirection='column' display='flex' px={2.5}>
      <Box width='100%' display='flex' flexDirection='row'>
        <Box display='flex' flexDirection='column' width='100%'>
          <Box width='100%'>
            <Box
              border={4}
              style={{
                boxShadow: '0px 0px 4px #ccc',
                margin: '3px 0px',
                borderRadius: '4px',
                borderColor: '#F8F8F8',
                width: '100%',
                height: 'auto',
                backgroundColor: '#F8F8F8',
              }}
            >
              <Grid container direction='column' spacing={1}>
                <Grid
                  container
                  direction='row'
                  id='vitals'
                  style={{
                    backgroundColor: '#EDF2FA',
                  }}
                >
                  <Grid item xs={2}>
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box display='flex' flexDirection='column' width='100%'>
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          minHeight={30}
                          alignItems='center'
                          px={1}
                          paddingBottom={1}
                        >
                          <Typography
                            variant='subtitle2'
                            // color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,
                              color: 'black',

                              fontWeight: 'bold',
                              lineHeight: 'normal',
                            }}
                          >
                            Referred By
                          </Typography>
                        </Box>
                      </Box>
                      <Box display='flex' width='2%' justifyContent='flex-end'>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box display='flex' flexDirection='column' width='100%'>
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          minHeight={30}
                          alignItems='center'
                          px={1}
                          paddingBottom={1}
                        >
                          {doctor && unit && (
                            <Typography
                              color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,

                                fontWeight: 600,
                                lineHeight: 'normal',
                              }}
                            >
                              {getNameFromHumanName(doctor.name ?? [])},{' '}
                              {unit.name ?? ''}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Box display='flex' width='2%' justifyContent='flex-end'>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box display='flex' flexDirection='column' width='100%'>
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          minHeight={30}
                          alignItems='center'
                          px={1}
                          paddingBottom={1}
                        >
                          <Typography
                            color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,
                              color: 'black',
                              fontWeight: 600,
                              lineHeight: 'bold',
                            }}
                          >
                            Referred Date
                          </Typography>
                        </Box>
                      </Box>
                      <Box display='flex' width='2%' justifyContent='flex-end'>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box display='flex' flexDirection='column' width='100%'>
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          minHeight={30}
                          alignItems='center'
                          px={1}
                          paddingBottom={1}
                        >
                          {referralData && referralData.occurrencePeriod && (
                            <Typography
                              color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,

                                fontWeight: 600,
                                lineHeight: 'normal',
                              }}
                            >
                              {moment(
                                referralData.occurrencePeriod.start
                              ).format('Do MMM yyyy')}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container direction='row' id='vitals'>
                  <Grid item xs={12}>
                    <Box width='100%' paddingLeft={0.2}>
                      <Divider
                        orientation='horizontal'
                        flexItem
                        style={{
                          height: '2px',
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction='row'
                  id='vitals'
                  style={{
                    backgroundColor: kDialogueBackground,
                  }}
                >
                  <Grid item xs={2}>
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box display='flex' flexDirection='column' width='100%'>
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          minHeight={30}
                          alignItems='center'
                          px={1}
                          paddingBottom={1}
                        >
                          <Typography
                            variant='subtitle2'
                            // color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,
                              color: 'black',

                              fontWeight: 'bold',
                              lineHeight: 'normal',
                            }}
                          >
                            Referral Note
                          </Typography>
                        </Box>
                      </Box>
                      <Box display='flex' width='2%' justifyContent='flex-end'>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',

                            width: '2px',
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={10}>
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box display='flex' flexDirection='column' width='100%'>
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          minHeight={30}
                          alignItems='center'
                          px={1}
                          paddingBottom={1}
                        >
                          {referralData && hasNotes(referralData.note) && (
                            <OverflowTypography
                              text={getNotesString(referralData.note)}
                              typographyProps={{
                                variant: 'subtitle2',
                                color: 'initial',
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                      {/* <Box display='flex' width='2%' justifyContent='flex-end'>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',

                          width: '2px',
                        }}
                      />
                    </Box> */}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              {/* {val.condition.map((valData, indexFinal: number) => (
                          <Box
                            display='flex'
                            flexDirection='column'
                            key={valData.id}
                          >
                            <Grid container direction='column' spacing={1}>
                              <Grid
                                container
                                direction='row'
                                id='vitals'
                                style={{
                                  backgroundColor:
                                    indexFinal % 2
                                      ? 'white'
                                      : kDialogueBackground,
                                }}
                              >
                                <Grid item xs={12}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    flexGrow={1}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        justifyContent='flex-start'
                                        display='flex'
                                        flexGrow
                                        height={40}
                                        alignItems='center'
                                        px={1}
                                      >
                                        {valData.code &&
                                          valData.code!.coding &&
                                          valData.code!.coding.length > 0 &&
                                          valData.code!.coding![0].display && (
                                            <OverflowTypography
                                              text={
                                                valData.code!.coding![0]
                                                  .display!
                                              }
                                              typographyProps={{
                                                variant: 'subtitle2',
                                                color: 'initial',
                                              }}
                                            />
                                          )}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>

                              <Grid container direction='row' id='vitals'>
                                <Grid item xs={12}>
                                  <Box width='100%' paddingLeft={0.2}>
                                    <Divider
                                      orientation='horizontal'
                                      flexItem
                                      style={{
                                        height: '2px',
                                      }}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        ))} */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
