import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  MenuItem,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core'

import React, { useState } from 'react'
import { WelloFormItemLabel } from './WelloFormItemLabel'

interface WelloGenderSelectorProps {
  title: string
  textFieldProps: TextFieldProps
  onChanged: (selectedGender: R4.ICoding | undefined) => void
  preSelectedGender?: R4.ICoding
  displayDoneLabel?: boolean
  genderListData?: string[]
}

export const WelloGenderSelector: React.FunctionComponent<WelloGenderSelectorProps> =
  (props: WelloGenderSelectorProps) => {
    const genderData: string[] = props.genderListData
      ? props.genderListData
      : []
    const genderList: R4.ICoding[] = [
      { code: '', display: 'Select' },
      {
        code: 'female',
        display: genderData.length > 0 ? genderData[0] : 'Female',
        system: 'http://hl7.org/fhir/ValueSet/administrative-gender',
      },
      {
        code: 'male',
        display: genderData.length > 0 ? genderData[1] : 'Male',
        system: 'http://hl7.org/fhir/ValueSet/administrative-gender',
      },

      {
        code: 'other',
        display: genderData.length > 0 ? genderData[2] : 'Other',
        system: 'http://hl7.org/fhir/ValueSet/administrative-gender',
      },
      {
        code: 'unknown',
        display: genderData.length > 0 ? genderData[3] : 'Unknown',
        system: 'http://hl7.org/fhir/ValueSet/administrative-gender',
      },
    ]
    const [selectedGender, setSelectedGender] = useState<R4.ICoding>(
      props.preSelectedGender ?? { code: '', display: '' }
    )

    return (
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <WelloFormItemLabel title={props.title} />
        <Box>
          <TextField
            variant='outlined'
            fullWidth
            size='small'
            select
            value={selectedGender?.code}
            label={selectedGender?.code === '' ? '' : ''}
            onChange={(val) => {
              const selectedGen: R4.ICoding | undefined = genderList.find(
                (gender) => gender.code === val.target.value
              )
              if (selectedGen) {
                setSelectedGender(selectedGen)
              }

              props.onChanged(selectedGen)
            }}
            {...props.textFieldProps}
          >
            {genderList.map((option) => (
              <MenuItem
                id={option.code}
                key={option.code}
                value={option.code}
                selected={selectedGender?.code === option.code}
                disabled={option?.code === ''}
              >
                <Typography variant='subtitle2'>{option.display}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
    )
  }
