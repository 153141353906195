import { Box, Button as MuiButton, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.4),
    boxShadow: '1',
  },
  label: {
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 600,
  },
}))

export interface IProps {
  text: string
  size: any
  disabled: boolean
  color?: string
  variant?: any
  onClick?: any
  backgroundColor?: string
  [other: string]: any
  type?: string
  id?: string
}

export const WelloButton: React.FC<IProps> = ({
  text,
  size,
  disabled,
  color,
  variant,
  onClick,
  other,
  backgroundColor,
  type,
  id,
}: IProps) => {
  const classes = useStyles()
  return (
    <Box
      boxShadow={3}
      component={MuiButton}
      variant={variant || 'contained' || 'outlined'}
      size={size || 'large' || 'small'}
      disabled={disabled}
      type={type}
      id={id}
      style={{ backgroundColor, color }}
      onClick={onClick}
      {...other}
      classes={{ root: classes.root, label: classes.label }}
      focusRipple={false}
    >
      {text}
    </Box>
  )
}
