import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { PartnerLab } from 'models/partnerLab'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  requestForUpdatePartnerLab,
  resetUpdateStatus,
} from 'redux/collectionCenter/partnerLabManagementSlice'
import { RootState } from 'redux/rootReducer'
import { WelloPhoneNumberField } from 'views/components/LeftMenu/WelloPhoneNumberField'
import {
  getAddress,
  getNumberValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import {
  validateEmail,
  validateMobileNumber,
  WelloTextField,
} from 'wello-web-components'

interface Props {
  open: boolean
  onBackClick: () => void
  onContinueClick: () => void
  location?: R4.ILocation
  practitionerRole?: R4.IPractitionerRole
}
export const EditPartnerLabHandler: React.FC<Props> = ({
  open,
  onBackClick: onClose,
  onContinueClick,
  location,
  practitionerRole,
}) => {
  const partnerLabManagementSlice = useSelector(
    (state: RootState) => state.partnerLabManagementSlice
  )
  const { t } = useTranslation()
  const actorInvitationSetupSlice = useSelector(
    (state: RootState) => state.actorInvitationSetupSlice
  )
  const dispatch = useDispatch()
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const seq = `L${(Math.floor(Math.random() * 10000) + 10000)
    .toString()
    .substring(1)}`
  let lName: string | undefined = ''
  let dataId: string | undefined = ''
  let commission: number | undefined = 0
  let address: string | undefined = ''
  let phoneData: string | undefined = ''

  if (location) {
    lName = location.name ? location.name : ''
    commission = location.extension
      ? getNumberValueExtensionsOfUrl(
          location.extension,
          'http://wellopathy.com/fhir/india/core/CodeSystem/commission'
        )
      : 0
    address = location.address ? getAddress([location.address]) : ''
    phoneData = location.telecom ? location.telecom[0].value : ''
    dataId = location.id
  }

  const [lab, setLab] = useState<PartnerLab>({
    id: seq,
    labName: '',
    commission: 0,
    labPhoneNumber: '',
    labAddress: '',
  })
  function resetDetails() {
    setLab({
      id: dataId || '',
      labName: '',
      commission: 0,
      labPhoneNumber: '',
      labAddress: '',
    })
  }

  function handleNameChange(name: string) {
    setLab({ ...lab, labName: name })
  }

  function handleEmailChange(event: any) {
    setLab({ ...lab, commission: event.target.value })
  }

  function handlePhoneNumberChange(phone: string) {
    setLab({ ...lab, labPhoneNumber: phone })
  }

  function handleAddressChange(event: any) {
    setLab({ ...lab, labAddress: event.target.value })
  }

  function handleSubmit() {
    let hasErrorsInData: boolean = false
    if (!lab.labName || lab.labName?.length === 0) {
      hasErrorsInData = true
    }
    if (
      !lab.commission ||
      lab.commission < 1 ||
      lab.commission > 100 ||
      Number.isNaN(lab.commission)
    ) {
      hasErrorsInData = true
    }
    if (
      !lab.labPhoneNumber ||
      lab.labPhoneNumber?.length === 0 ||
      validateMobileNumber(lab.labPhoneNumber, true).length > 0
    ) {
      hasErrorsInData = true
    }

    if (!lab.labAddress || lab.labAddress?.length === 0) {
      hasErrorsInData = true
    }
    if (!hasErrorsInData) {
      dispatch(requestForUpdatePartnerLab(lab, practitionerRole, location))
    }
    setHasErrors(hasErrorsInData)
  }

  useEffect(() => {
    setLab({
      id: dataId || seq,
      labName: lName || '',
      commission: commission || 0,
      labPhoneNumber: phoneData || '',
      labAddress: address || '',
    })

    if (partnerLabManagementSlice.partnerDetailsUpdated) {
      onClose()
      resetDetails()
      dispatch(resetUpdateStatus())
    }
    return () => {}
  }, [partnerLabManagementSlice.partnerDetailsUpdated, onClose, dispatch])

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetDetails()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:edit_partner_lab_title')}
      </DialogTitle>
      <DialogContent dividers={true}>
        <Box display='flex' flexDirection='column'>
          {partnerLabManagementSlice.errorWhileFetchingPartnerDetail && (
            <Box display='flex'>
              <Alert severity='error'>
                Error while updating. Please try later
              </Alert>
            </Box>
          )}
          {partnerLabManagementSlice.partnerDetailsUpdated && (
            <Box display='flex'>
              <Alert severity='success'>'Updated Successfully'</Alert>
            </Box>
          )}
          {!partnerLabManagementSlice.partnerDetailsUpdated && (
            <Box display='flex' flexDirection='column'>
              <Box display='flex' flexDirection='row' alignItems='flex-start'>
                <Box
                  display='flex'
                  flexGrow={1}
                  alignItems='flex-end'
                  justifyContent='center'
                  p={0.5}
                  width='20%'
                >
                  <WelloTextField
                    title={t('labelCommon:person_name_label')}
                    //   value=lab.labName
                    textProps={{
                      id: `actor_name_unit_${t}`,
                      value: lab.labName,
                      defaultValue: 'test',
                      disabled: partnerLabManagementSlice.updatingPartnerDetail,
                      error:
                        hasErrors &&
                        (!lab.labName ||
                          lab.labName?.length === 0 ||
                          lab.labName?.length > 300),
                      helperText:
                        hasErrors &&
                        (!lab.labName ||
                          lab.labName?.length === 0 ||
                          lab.labName?.length > 300)
                          ? 'Enter valid name'
                          : '',
                      onChange: (changedValue) => {
                        handleNameChange(changedValue.target.value)
                      },
                    }}
                  />
                </Box>
                <Box
                  display='flex'
                  flexGrow={1}
                  alignItems='flex-end'
                  justifyContent='center'
                  p={0.5}
                  width='20%'
                >
                  <WelloTextField
                    title='Commission'
                    textProps={{
                      id: `actor_email_unit`,
                      value: lab.commission,
                      type: 'number',
                      inputProps: {
                        max: 100,
                        // min:0
                      },
                      disabled: partnerLabManagementSlice.updatingPartnerDetail,
                      error:
                        hasErrors &&
                        (lab.commission <= 1 || lab.commission > 100),
                      helperText:
                        hasErrors &&
                        (lab.commission <= 1 || lab.commission > 100)
                          ? 'Commission between 1 to 100'
                          : '',
                      onChange: (changeCommission) => {
                        handleEmailChange(changeCommission)
                      },
                    }}
                  />
                </Box>

                <Box
                  display='flex'
                  flexGrow={1}
                  alignItems='flex-end'
                  justifyContent='center'
                  p={0.5}
                  width='30%'
                >
                  <WelloTextField
                    title={t('labelCommon:lab_Address_label')}
                    textProps={{
                      id: `actor_name_unit_${t}`,
                      value: lab.labAddress,
                      disabled: partnerLabManagementSlice.updatingPartnerDetail,
                      error:
                        hasErrors &&
                        (!lab.labAddress ||
                          lab.labAddress?.length === 0 ||
                          lab.labAddress?.length > 256),
                      helperText:
                        hasErrors &&
                        (!lab.labAddress ||
                          lab.labAddress?.length === 0 ||
                          lab.labAddress?.length > 256)
                          ? 'Enter valid address'
                          : '',
                      onChange: (changedValue) => {
                        handleAddressChange(changedValue)
                      },
                    }}
                  />
                </Box>

                <Box
                  display='flex'
                  flexGrow={1}
                  alignItems='flex-end'
                  justifyContent='center'
                  p={0.5}
                  width='20%'
                >
                  <WelloPhoneNumberField
                    textProps={{
                      id: `actor_phone_unit_${t}`,
                      value: lab.labPhoneNumber,
                      countryCodeEditable: false,
                      defaultCountry: 'in',
                      fullWidth: true,
                      name: t('labelCommon:phone_name_label'),
                      inputMode: 'tel',
                      onlyCountries: ['in'],
                      disableDropdown: false,
                      inputProps: { maxLength: 13 },
                      disabled: partnerLabManagementSlice.updatingPartnerDetail,
                      onChange: (
                        e:
                          | React.ChangeEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >
                          | string
                      ) => {
                        if (typeof e === 'string') {
                          handlePhoneNumberChange(e)
                        }
                      },
                      regions: ['asia'],
                      autoFormat: false,
                      size: 'small',
                      variant: 'outlined',
                      error:
                        hasErrors &&
                        validateMobileNumber(lab.labPhoneNumber, true).length >
                          0,
                      helperText:
                        hasErrors &&
                        validateMobileNumber(lab.labPhoneNumber, true).length >
                          0
                          ? 'Enter valid phone number'
                          : '',

                      required: true,
                    }}
                    title={t('labelCommon:phone_name_label')}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {partnerLabManagementSlice.updatingPartnerDetail && (
          <CircularProgress />
        )}
        <Button
          onClick={() => {
            resetDetails()
            onClose()
          }}
          variant='outlined'
          disabled={partnerLabManagementSlice.updatingPartnerDetail}
          disableElevation
        >
          {t('labelCommon:back')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={partnerLabManagementSlice.updatingPartnerDetail}
          onClick={() => {
            handleSubmit()
          }}
        >
          {t('labelCommon:update_PartnerLab')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
