/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import ContentShimmer from 'react-content-shimmer'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import 'react-vis/dist/style.css'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { getAccessToken } from 'utils/authHelpers'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  addObservationDetailsForSys,
  deleteObservationDetails,
  getAddStatusForOCAForAyurveda,
  getObservationResourceForPostForSystemicExam,
  getObservationsOfSpecificCategoryInEncounter,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { getVendorPartId } from 'utils/routes_helper'
import { OCARenderer } from 'wello-web-components'

interface AyurvedaHistoryAndSubjectiveProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  isReadOnly?: boolean
}

export const AyurvedaHistoryAndSubjective: React.FC<AyurvedaHistoryAndSubjectiveProps> =
  ({
    fhirAppointmentDetails,
    isReadOnly = false,
  }: AyurvedaHistoryAndSubjectiveProps) => {
    const { t } = useTranslation(['common'])
    const [fetcAgain, setFetchAgain] = useState<string>(getUniqueTempId())
    const [observations, setObservations] = useState<R4.IObservation[]>([])
    const dispatch = useDispatch()
    const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
      initial: true,
    })

    function getObservationDetails() {
      setFetchingStatus({
        requesting: true,
      })

      getObservationsOfSpecificCategoryInEncounter({
        encounterId: fhirAppointmentDetails.encounter!.id!,
        patient: fhirAppointmentDetails.patient,
        category: 'C4045976',
        isForHistory: true,
      })
        .then((e) => {
          if (e) {
            setObservations(e as R4.IObservation[])
            setFetchingStatus({
              requestSuccess: true,
            })
          } else {
            setFetchingStatus({
              requestError: true,
            })
          }
        })
        .catch((e) => {
          setFetchingStatus({
            requestError: true,
          })
        })
    }
    useEffect(() => {
      getObservationDetails()
    }, [])

    return (
      <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
        {fetchingStatus.requesting && (
          <ContentShimmer
            rows={3}
            size={{
              width: '100%',
              height: '100',
            }}
          />
        )}
        {fetchingStatus.requestError && (
          <Typography variant='body1' color='error'>
            {t('common:errorWhileFetchingData')}
          </Typography>
        )}
        {fetchingStatus.requestSuccess && (
          <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
            <OCARenderer
              OCAFormProviderBaseURL={`${
                process.env.REACT_APP_CC_WORKFLOW_URL
              }${getVendorPartId()}/ocaForms/forms`}
              OCAFormProviderHeader={{
                Authorization: `Bearer ${getAccessToken()}`,

                'Cache-Control': 'no-cache',
              }}
              isViewOnly={isReadOnly}
              //   showAdded={getAddStatusForOCAForAyurveda(
              //     observations,
              //     '',
              //     fhirAppointmentDetails.encounter
              //       ? fhirAppointmentDetails.encounter.id!
              //       : ''
              //   )}
              onSubmit={(
                observation: R4.IObservation,
                onSubmitSuccess?: (data: any) => void
              ) => {
                const updatedObservation =
                  getObservationResourceForPostForSystemicExam(
                    fhirAppointmentDetails.patient.id!,
                    fhirAppointmentDetails.encounter!.id!,
                    [],
                    observation,
                    true
                  )

                addObservationDetailsForSys(updatedObservation).then((e) => {
                  if (e === false) {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  } else if (
                    (e as R4.IObservation).resourceType === 'Observation'
                  ) {
                    if (onSubmitSuccess) {
                      onSubmitSuccess(e)
                    }

                    dispatch(
                      showSuccessAlert(
                        'Observation details updated successfully'
                      )
                    )
                    getObservationDetails()
                  } else {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  }
                })
              }}
              onDelete={(
                observation: R4.IObservation,
                onSuccess?: ((data: any) => void) | undefined,
                onError?: ((data: any) => void) | undefined
              ) => {}}
              onEdit={(
                observation: R4.IObservation,
                onSubmitSuccess?: (data: any) => void
              ) => {
                const updatedObservation =
                  getObservationResourceForPostForSystemicExam(
                    fhirAppointmentDetails.patient.id!,
                    fhirAppointmentDetails.encounter!.id!,
                    [],
                    observation
                  )

                addObservationDetailsForSys(updatedObservation).then((e) => {
                  if (e === false) {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  } else if (
                    (e as R4.IObservation).resourceType === 'Observation'
                  ) {
                    if (onSubmitSuccess) {
                      onSubmitSuccess(e)
                    }
                    getObservationDetails()
                    // onDataAdded()
                    dispatch(
                      showSuccessAlert(
                        'Observation details updated successfully'
                      )
                    )
                  } else {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  }
                })
              }}
              formSection='soap'
              formCategory='subjective'
              existingObservations={observations}
              displayAsPopup={false}
            />
          </Box>
        )}
      </Box>
    )
  }
