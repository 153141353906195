import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Grid,
  Divider,
  Tooltip,
  Link,
  Collapse,
  Button,
} from '@material-ui/core'
import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
} from '@material-ui/icons'
import { getUnit } from '@mui/material/styles/cssUtils'
import { kDialogueBackground } from 'configs/styles/muiThemes'

import React, { useEffect, useState } from 'react'
import { getUnitData } from 'utils/appointment_handle/medications_util'
import { titleCase } from 'utils/fhirResourcesHelper'

interface AllergiesProp {
  salesOrder: any
}

export const ItemOrderDetails: React.FC<AllergiesProp> = ({
  salesOrder,
}: AllergiesProp) => {
  const [checked, setChecked] = React.useState<boolean>(false)

  function handleCollapseForPanel1(rate: boolean) {
    setChecked(rate)
  }
  return (
    <Box
      flexGrow
      width='100%'
      flexDirection='column'
      display='flex'
      style={{ padding: 0, margin: 0 }}
    >
      <Box flexGrow width='100%' display='flex' flexDirection='row'>
        <Box display='flex' flexDirection='column' width='100%' flexGrow>
          <Box width='100%' py={1}>
            <Box
              paddingX={1}
              borderRadius={2}
              style={{
                backgroundColor: 'lightGrey',
              }}
              display='flex'
              flexDirection='row'
              width='100%'
              height={40}
            >
              <Box
                justifyContent='flex-start'
                display='flex'
                flexGrow={1}
                alignItems='center'
              >
                <Typography
                  variant='subtitle1'
                  style={{
                    color: 'black',

                    fontWeight: 'bold',
                  }}
                >
                  Ordered Items
                </Typography>
              </Box>
              <Box
                justifyContent='flex-end'
                display='flex'
                flexGrow={1}
                alignItems='center'
              >
                <Box px={1}>
                  <Tooltip title=''>
                    <IconButton
                      aria-label='collapse_order_type'
                      size='small'
                      onClick={() => {
                        handleCollapseForPanel1(!checked)
                      }}
                    >
                      {checked && <ArrowDropUpOutlined />}
                      {checked === false && <ArrowDropDownOutlined />}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <Collapse
              in={checked}
              style={{
                width: '100%',
              }}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                width='100%'
                border={4}
                style={{
                  boxShadow: '0px 0px 4px #ccc',
                  margin: '2px 0px',
                  borderRadius: '1px',
                  borderColor: '#F8F8F8',
                  width: '100%',
                  height: 'auto',
                  backgroundColor: '#F8F8F8',
                }}
              >
                <Grid container direction='row' spacing={1}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Grid
                      container
                      direction='row'
                      id='vitals'
                      style={{
                        backgroundColor: kDialogueBackground,
                      }}
                    >
                      <Grid item xs={6}>
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexDirection='column'
                                height={30}
                                px={1}
                                paddingTop={1}
                                paddingBottom={0.5}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  Item
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display='flex'
                              flexGrow={1}
                              justifyContent='flex-end'
                            >
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  height: '30x',

                                  width: '2px',
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box
                          display='flex'
                          flexDirection='column'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexDirection='column'
                                height={30}
                                px={1}
                                paddingTop={1}
                                paddingBottom={0.5}
                              >
                                <Typography
                                  variant='subtitle2'
                                  // color='initial'
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    color: 'black',

                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  SKU
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display='flex'
                              flexGrow={1}
                              justifyContent='flex-end'
                            >
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  height: '30x',

                                  width: '2px',
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          flexGrow={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexDirection='column'
                              height={30}
                              px={1}
                              paddingTop={1}
                              paddingBottom={0.5}
                            >
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,
                                  color: 'black',
                                  fontWeight: 'bold',

                                  lineHeight: 'normal',
                                }}
                              >
                                Quantity
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container direction='row' id='vitals'>
                      <Grid item xs={12}>
                        <Box width='100%' paddingLeft={0.2}>
                          <Divider
                            orientation='horizontal'
                            flexItem
                            style={{
                              height: '2px',
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    {salesOrder.line_items.map((item: any, index: number) => (
                      <Box
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        key={item.line_item_id}
                      >
                        <Grid
                          container
                          direction='row'
                          id='vitals'
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? 'white' : kDialogueBackground,
                          }}
                        >
                          <Grid item xs={6}>
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      color='initial'
                                    >
                                      {item.name}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  flexGrow={1}
                                  justifyContent='flex-end'
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '30px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      color='initial'
                                    >
                                      {item.sku}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  flexGrow={1}
                                  justifyContent='flex-end'
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '30px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={2}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={1}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    color='initial'
                                  >
                                    {`${item.quantity} ${titleCase(
                                      getUnitData(item.unit)
                                    )}`}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid container direction='row' id='vitals'>
                          <Grid item xs={12}>
                            <Box width='100%' paddingLeft={0.2}>
                              <Divider
                                orientation='horizontal'
                                flexItem
                                style={{
                                  height: '2px',
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Box>
            </Collapse>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
