import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Grid,
  Divider,
  Tooltip,
  Link,
  Collapse,
  Button,
} from '@material-ui/core'
import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
} from '@material-ui/icons'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { getAaddressByContactId } from 'utils/appointment_handle/medications_util'
import { getAddress } from 'utils/fhirResourcesHelper'

interface AllergiesProp {
  salesOrder: any
}

export const DeliveryAddressTile: React.FC<AllergiesProp> = ({
  salesOrder,
}: AllergiesProp) => {
  const [checked, setChecked] = React.useState<boolean>(false)

  function handleCollapseForPanel1(rate: boolean) {
    setChecked(rate)
  }

  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [addressText, setAddressText] = useState<string>('')
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  function getAddressAdata(contactId: string) {
    setUpdateStatus({ requesting: true })

    setLoading(true)

    getAaddressByContactId(contactId)
      .then((e: string) => {
        setLoading(false)
        if (e) {
          setAddressText(e)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while fetching reports. Please try again later'
            )
          )
        }
      })
      .catch((e) => {
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  useEffect(() => {
    // i18n.changeLanguage(language ?? '')

    getAddressAdata(salesOrder.customer_id)
  }, [])

  return (
    <Box
      flexGrow
      width='100%'
      flexDirection='column'
      display='flex'
      style={{ padding: 0, margin: 0 }}
    >
      <Box flexGrow width='100%' display='flex' flexDirection='row'>
        <Box display='flex' flexDirection='column' width='100%' flexGrow>
          <Box width='100%' py={1}>
            <Box
              paddingX={1}
              borderRadius={2}
              style={{
                backgroundColor: 'lightGrey',
              }}
              display='flex'
              flexDirection='row'
              width='100%'
              height={40}
            >
              <Box
                justifyContent='flex-start'
                display='flex'
                flexGrow={1}
                alignItems='center'
              >
                <Typography
                  variant='subtitle1'
                  style={{
                    color: 'black',

                    fontWeight: 'bold',
                  }}
                >
                  Delivery Address
                </Typography>
              </Box>
              <Box
                justifyContent='flex-end'
                display='flex'
                flexGrow={1}
                alignItems='center'
              >
                <Box px={1}>
                  <Tooltip title=''>
                    <IconButton
                      aria-label='collapse_order_type'
                      size='small'
                      onClick={() => {
                        handleCollapseForPanel1(!checked)
                      }}
                    >
                      {checked && <ArrowDropUpOutlined />}
                      {checked === false && <ArrowDropDownOutlined />}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <Collapse
              in={checked}
              style={{
                width: '100%',
              }}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                width='100%'
                border={4}
                style={{
                  boxShadow: '0px 0px 4px #ccc',
                  margin: '2px 0px',
                  borderRadius: '1px',
                  borderColor: '#F8F8F8',
                  width: '100%',
                  height: 'auto',
                  backgroundColor: '#F8F8F8',
                }}
              >
                <Grid container direction='row' spacing={1}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Grid
                      container
                      direction='row'
                      id='vitals'
                      style={{
                        backgroundColor: 'white',
                      }}
                    >
                      <Grid item xs={12}>
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexDirection='column'
                          px={1}
                          paddingTop={1}
                          paddingBottom={1}
                        >
                          {loading && (
                            <Box
                              display='flex'
                              flexDirection='column'
                              justifyContent='center'
                            >
                              <CircularProgress size={20} />
                            </Box>
                          )}
                          {addressText.length > 0 && (
                            <Typography variant='subtitle2' color='initial'>
                              {addressText}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Box>
            </Collapse>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
