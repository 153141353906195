/* eslint-disable import/no-named-as-default */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, MenuItem, TextField, TextFieldProps } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ArrowDropDownSharp } from '@material-ui/icons'
import React, { useState } from 'react'
import { UnitServices } from 'models/units/unitServices'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { titleCase } from 'utils/fhirResourcesHelper'
import { WelloFormItemLabel } from './WelloFormItemLabel'

interface WelloSelectCodingProps {
  id?: string
  availableCodings: UnitServices[]
  onChanged: (selectedCoding: UnitServices) => void
  preSelectedCoding?: UnitServices
}

export const WelloSelectUnitWithServices: React.FunctionComponent<WelloSelectCodingProps> =
  ({
    id,
    availableCodings,
    onChanged,
    preSelectedCoding,
  }: WelloSelectCodingProps) => (
    <Box>
      <Autocomplete
        style={{
          borderRadius: '4px',
        }}
        fullWidth
        size='small'
        id='condition_selector'
        getOptionSelected={(option, valueData) =>
          option.orgs.id === valueData.orgs.id
        }
        disableClearable
        getOptionLabel={(option: UnitServices) =>
          titleCase(option.orgs.name ?? '')
        }
        options={availableCodings}
        onChange={(e, changedValue, reason) => {
          if (changedValue !== null) {
            onChanged(changedValue)
          }
        }}
        defaultValue={preSelectedCoding}
        autoComplete
        ChipProps={{
          style: {
            backgroundColor: kPrimaryLight,
            borderRadius: '4px',
          },
        }}
        renderInput={(params) => (
          <TextField
            placeholder='Select Unit'
            {...params}
            fullWidth
            variant='outlined'
            size='small'
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
    </Box>
  )
