import _ from 'lodash'
import { ItemOrderSummary } from 'views/components/generalOrder/itemOrdersummary'
import { Category, Sku } from '../product-models'

/*= ==================== CART ============================ */
export interface CartProduct {
  category: Category
  name: string
  title: string
  description: string
  sku: Sku
  image: string
}

export interface CartItem {
  product: CartProduct
  quantity: number
  customerDiscount: number
}

export interface Cart {
  items: CartItem[]
  delivery: number
}

export const cartItemPrice = (item: CartItem) =>
  Math.abs(
    item.quantity * ((item.product.sku.price || 0) - item.customerDiscount)
  )

export const cartItemPriceForPatient = (item: CartItem) =>
  Math.abs(
    item.quantity * item.product.sku.price -
      (item.quantity * item.product.sku.price * item.customerDiscount) / 100
  )

export const cartItemDiscountInRs = (item: CartItem) => {
  // TODO: this should not happen. SKU should alwasy be present.
  if (!item.product.sku) {
    return 0
  }
  const discount = _.round(
    (item.product.sku.discount * item.product.sku.price) / 100,
    2
  )
  return discount
}

export const cartItemDoctorMarginForDoctor = (item: CartItem) => {
  // TODO: this should not happen. SKU should alwasy be present.
  if (!item.product.sku) {
    return 0
  }
  const discount = _.round(
    (item.product.sku.discount * item.product.sku.price) / 100,
    2
  )
  return discount
}

export const cartItemDoctorMargin = (item: CartItem) => {
  // TODO: this should not happen. SKU should alwasy be present.
  if (!item.product.sku) {
    return 0
  }
  const discount = _.round(
    (item.product.sku.discount * item.product.sku.price) / 100,
    2
  )
  return discount - item.customerDiscount
}

export const cartPrice = (cart: CartItem[]) =>
  cart.length === 0 ? 0 : _.sumBy(cart, (item) => cartItemPrice(item))

export const cartPriceForPatient = (cart: CartItem[]) =>
  cart.length === 0 ? 0 : _.sumBy(cart, (item) => cartItemPriceForPatient(item))

export const cartDoctorMargin = (cart: CartItem[]) =>
  cart.length === 0
    ? 0
    : _.sumBy(cart, (item) => cartItemDoctorMargin(item) * item.quantity)

export const cartTotalMrp = (cart: CartItem[]) =>
  _.sumBy(cart, (item) => item.quantity * (item.product.sku.price || 0))

export const cartCustomerTotalDiscount = (cart: CartItem[]) =>
  _.sumBy(cart, (item) => item.customerDiscount * item.quantity)

export const cartDoctorTotalMargin = (cart: CartItem[]) =>
  _.sumBy(cart, (item) => {
    const marginPerItem = cartItemDoctorMargin(item)
    return marginPerItem * item.quantity
  })

export const finalDiscount = (cart: Cart) => {
  const doctorMargin = cartDoctorTotalMargin(cart.items)
  const discountToPatient = cartCustomerTotalDiscountWithPercentage(cart.items)
  return discountToPatient
}

export const finalDiscountForSalesOrder = (cart: Cart) => {
  const doctorMargin = cartDoctorTotalMargin(cart.items)
  const discountToPatient = cartCustomerTotalDiscountWithPercentage(cart.items)
  return discountToPatient + doctorMargin
}

export const finalDiscountForClinic = (cart: Cart) => {
  const totalDoctorMargin = cartDoctorTotalMargin(cart.items)

  const discountToPatient = cartCustomerTotalDiscount(cart.items)
  if (totalDoctorMargin < 0)
    return (totalDoctorMargin * -1 - discountToPatient) * -1
  return totalDoctorMargin - discountToPatient
}

export const cartCustomerTotalDiscountWithPercentage = (cart: CartItem[]) => {
  const mrp = cartTotalMrp(cart)

  if (cart.length > 0)
    return _.sumBy(cart, (item) => (mrp * item.customerDiscount) / 100)
  return 0
}

export const cartTotalAmount = (cart: Cart) =>
  cartTotalMrp(cart.items) -
  cartDoctorTotalMargin(cart.items) -
  cartCustomerTotalDiscountWithPercentage(cart.items) +
  cart.delivery

// export const cartTotalAmountClinic = (cart: Cart) =>
//   cartTotalMrp(cart.items) - cartDoctorTotalMargin(cart.items) - cart.delivery

export const cartTotalAmountClinic = (cart: Cart) =>
  cartTotalMrp(cart.items) -
  cartDoctorTotalMargin(cart.items) -
  cartCustomerTotalDiscount(cart.items) +
  cart.delivery
/*= ==================== ADDRESS ============================ */
export interface Address {
  name: string
  type: 'shipping' | 'billing'
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  pinCode: string
  country: string
  isDefault: boolean
}
