import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  getCodeOfSystemFromCodableConceptList,
  getDisplayOfSystemFromCodableConcept,
  getValueCodingFromExtension,
  removeExtensionFromExtensions,
  titleCase,
} from 'utils/fhirResourcesHelper'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getTimeOfDayDisplayForStatement,
  getTimeOfDayDisplayMeal,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import { getTestNameFromOrderDetails } from 'utils/fhirResoureHelpers/referralHelpers'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { LabReferralService, LabTabularService } from 'models/labOfferDetail'
import {
  getLabName,
  getLabOrgName,
} from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { WelloSignExam } from 'models/WelloConditions'
import OverflowTypography from 'views/components/consultation/plan/ayurveda/overflowTypography'
import { getDataString } from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { statusCodings } from 'utils/constants'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { useDispatch } from 'react-redux'
import { updateObsDetailsFollowup } from 'utils/fhirResoureHelpers/appointmentHelpers'

interface MedicationTileProps {
  obsData: WelloSignExam[]
  appointmentId: string
  split: boolean
  onEditCLicked: () => void
}

export const PhysicalExaForDisplayTileFollowUp: React.FC<MedicationTileProps> =
  ({ obsData, appointmentId, split, onEditCLicked }: MedicationTileProps) => {
    const loggedInUnitType =
      getCodeOfSystemFromCodableConceptList(
        getCurrentUserUnitDetails().type ?? [],
        'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
      )?.display ?? ''
    const dispatch = useDispatch()
    const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })
    const [loading, setLoading] = useState<boolean>(false)

    function updateConditions(
      condition: R4.IObservation,
      statusCoding: R4.ICoding
    ) {
      setUpdateStatus({ requesting: true })

      const oldProcedure: R4.IObservation = {
        ...condition,
      }

      if (oldProcedure.extension) {
        const codingData = getValueCodingFromExtension(
          oldProcedure.extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
        )
        if (codingData) {
          oldProcedure.extension =
            removeExtensionFromExtensions(
              oldProcedure.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
            ) ?? []
        }
        oldProcedure.extension = [
          ...oldProcedure.extension,
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext',
            valueCoding: statusCoding,
          },
        ]
      } else {
        oldProcedure.extension = [
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext',
            valueCoding: statusCoding,
          },
        ]
      }
      setLoading(true)

      updateObsDetailsFollowup(oldProcedure, appointmentId).then((e) => {
        if (e) {
          setLoading(false)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(
            showSuccessAlert('Sign on Exam status details updated successfully')
          )
          onEditCLicked()
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while updating Sign on Exam. Please try again later'
            )
          )
        }
      })
    }

    return (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='flex-start'
        width='100%'
        border={4}
        style={{
          boxShadow: '0px 0px 4px #ccc',
          margin: '2px 0px',
          borderRadius: '1px',
          borderColor: '#F8F8F8',
          width: '100%',
          height: 'auto',
          backgroundColor: '#F8F8F8',
        }}
      >
        <Grid container direction='column' spacing={1}>
          {/* ------------------------------------------------------------------------------- */}
          {obsData.map((e, index: number) => (
            <Box
              display='flex'
              flexDirection='column'
              width='100%'
              flexGrow={1}
              key={e.id}
            >
              <Grid
                container
                direction='row'
                id='vitals'
                style={{
                  backgroundColor: index % 2 ? kDialogueBackground : 'white',
                }}
              >
                <Grid item xs={12}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box display='flex' flexDirection='column' width='80%'>
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        height={40}
                        alignItems='center'
                        paddingLeft={1}
                        width='100%'
                      >
                        {e.condition && (
                          <OverflowTypography
                            text={`${getDataString(e)}`}
                            typographyProps={{
                              variant: 'subtitle2',
                              color: 'initial',
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box display='flex' width='2%' paddingTop={0.1}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '45px',

                          width: '2px',
                          //   backgroundColor: 'white',
                        }}
                      />
                    </Box>
                    {e.obsData && (
                      <Box width='20%' px={0.2} py={0.5}>
                        <WelloSelectFHIRCoding
                          id='edit'
                          availableCodings={statusCodings}
                          onChanged={(type) => {
                            if (e.obsData) {
                              updateConditions(e.obsData, type)
                            }
                          }}
                          textFieldProps={{
                            size: 'small',
                            fullWidth: true,
                          }}
                          preSelectedCoding={getValueCodingFromExtension(
                            e.obsData.extension ?? [],
                            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
                          )}
                          disabled={split}
                        />
                      </Box>
                    )}

                    {/* <Box display='flex' width='2%' paddingTop={0.1}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',
  
                          width: '2px',
                        }}
                      />
                    </Box> */}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
      </Box>
    )
  }
