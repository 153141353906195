import {
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import * as _ from 'lodash'
import { LabOfferingDetail } from 'models/labOfferDetail'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getRestrictionsFromLabOfferings } from 'utils/fhirResoureHelpers/planDefinitionHelper'

interface Props {
  selectedOfferings: LabOfferingDetail[]
}

export const RestrictionsOfSelectedOfferings: React.FC<Props> = ({
  selectedOfferings,
}: Props) => {
  const { t } = useTranslation()
  return (
    <Box display='flex' flexDirection='row' width='100%'>
      {getRestrictionsFromLabOfferings(selectedOfferings).size > 0 && (
        <Card
          elevation={0}
          style={{
            background: ' #FDFFABCC',
            width: '100%',
          }}
        >
          <CardContent>
            <Box display='flex' flexDirection='column' width='100%'>
              {/* <Box display='flex' flexDirection='row' width='100%'>
                <Typography variant='h6' color='initial'>
                  {t('labelCommon:test_restrictions')}
                </Typography>
              </Box> */}
              <List
                dense
                style={{
                  margin: 0,
                  padding: 0,
                  paddingLeft: '4px',
                }}
              >
                {_.entries(
                  getRestrictionsFromLabOfferings(selectedOfferings)
                ).map((restrict) => (
                  <ListItem
                    key={`key${restrict[0]}`}
                    style={{
                      margin: 0,
                      padding: 0,
                      paddingLeft: '4px',
                    }}
                  >
                    <ListItemText
                      id={`id_${restrict}`}
                      primary={restrict[0]}
                      primaryTypographyProps={{
                        style: {
                          color: 'grey',
                        },
                      }}
                      secondary={restrict[1]}
                      secondaryTypographyProps={{
                        style: {
                          padding: '2px',
                          color: 'black',
                          flexShrink: 1,
                          wordWrap: 'break-word',
                          whiteSpace: 'pre-line',
                        },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </CardContent>
        </Card>
      )}
    </Box>
  )
}
