import { R4 } from '@ahryman40k/ts-fhir-types'
import { CodeProps } from '@mantine/core'
import * as _ from 'lodash'
import { CardsEntity } from 'redux/patientMedicalHistory/cds_recommendations/cdsResponse'
import {
  getCodeOfSystemCodings,
  getCodeOfSystemFromCodableConcept,
  getCodeOfSystemFromCodableConceptArray,
  getCodeOfSystemFromCodableConceptList,
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultDisplayOfSystemFromCodableConcept,
} from 'utils/fhirResourcesHelper'
import { getMedicationsStrings } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { KriyaDetail } from 'views/components/consultation/plan/ayurveda/addKriyasForEncounter'

export function getSpecificTypeResourcesFromBundleEntries(
  entries: R4.IBundle_Entry[],
  resourceType: string
): R4.IBundle_Entry[] {
  return _.filter(entries, (e) => e.resource!.resourceType === resourceType)
}

export function getAppointmentsFromEntries(
  entries: R4.IBundle_Entry[]
): R4.IAppointment[] {
  const appointments: R4.IAppointment[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'Appointment'
  ).map((e) => e.resource as R4.IAppointment)

  return appointments
}

export function getRecommendedPlans(
  entries: R4.IBundle_Entry[]
): R4.IPlanDefinition[] {
  const appointments: R4.IPlanDefinition[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'PlanDefinition'
  ).map((e) => e.resource as R4.IPlanDefinition)

  return appointments
}

export function getGoalFromEntries(entries: R4.IBundle_Entry[]): R4.IGoal[] {
  const carePlans: R4.ICarePlan[] = getCarePlans(entries)

  let appointments: R4.IGoal[] = []

  carePlans.forEach((element) => {
    appointments = [...appointments, ...getGoalsFromCarePlan(element)]
  })

  return appointments
}

export function getTestsToBePerformed(
  entries: R4.IBundle_Entry[],
  type?: string
): R4.IPlanDefinition[] {
  const appointments: R4.IRequestGroup[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'RequestGroup'
  ).map((e) => e.resource as R4.IRequestGroup)

  let tests: R4.IPlanDefinition[] = []

  appointments.forEach((element) => {
    tests = [...tests, ...getTestsFromRequestGroup(element)]
  })
  if (type && type.length > 0) {
    tests = [...tests].filter((e) => isSameType(e, type))
  }

  return tests
}

export function getSuggestedConditionFromEntries(
  entries: R4.IBundle_Entry[]
): R4.ICondition[] {
  const appointments: R4.ICondition[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'Condition'
  ).map((e) => e.resource as R4.ICondition)

  return appointments
}

export function getTestsFromRequestGroup(requestGroup: R4.IRequestGroup) {
  const tests: R4.IPlanDefinition[] = _.filter(
    requestGroup.contained,
    (e) => (e as any).resource.resourceType === 'PlanDefinition'
  ).map((e) => (e as any).resource as R4.IPlanDefinition)

  return tests
}

export function getAppointments(
  entries: R4.IBundle_Entry[]
): R4.IAppointment[] {
  const requestGroups: R4.IRequestGroup[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'RequestGroup'
  ).map((e) => e.resource as R4.IRequestGroup)

  let appointments: R4.IAppointment[] = []

  requestGroups.forEach((element) => {
    appointments = [...appointments, ...getAppointmentFromRequestGroup(element)]
  })

  return appointments
}

export function getInstructionsFromRequestGroup(
  requestGroup: R4.IRequestGroup
): R4.ICommunicationRequest[] {
  const tests: R4.ICommunicationRequest[] = _.filter(
    requestGroup.contained,
    (e) => (e as any).resource.resourceType === 'CommunicationRequest'
  ).map((e) => (e as any).resource as R4.ICommunicationRequest)

  return tests
}

export function getAppointmentFromRequestGroup(
  requestGroup: R4.IRequestGroup
): R4.IAppointment[] {
  const tests: R4.IAppointment[] = _.filter(
    requestGroup.contained,
    (e) => (e as any).resource.resourceType === 'Appointment'
  ).map((e) => (e as any).resource as R4.IAppointment)

  return tests
}

export function getMedicationRequestFromRequestGroup(
  requestGroup: R4.IRequestGroup
): R4.IMedicationRequest[] {
  const tests: R4.IMedicationRequest[] = _.filter(
    requestGroup.contained,
    (e) => (e as any).resource.resourceType === 'MedicationRequest'
  ).map((e) => (e as any).resource as R4.IMedicationRequest)

  return tests
}

export function getServiceRequestFromRequestGroup(
  requestGroup: R4.IRequestGroup
): R4.IServiceRequest[] {
  const tests: R4.IServiceRequest[] = _.filter(
    requestGroup.contained,
    (e) => (e as any).resource.resourceType === 'ServiceRequest'
  ).map((e) => (e as any).resource as R4.IServiceRequest)

  return tests
}
export function getMedications(
  entries: R4.IBundle_Entry[]
): R4.IMedicationRequest[] {
  const requestGroups: R4.IRequestGroup[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'RequestGroup'
  ).map((e) => e.resource as R4.IRequestGroup)

  let appointments: R4.IMedicationRequest[] = []

  requestGroups.forEach((element) => {
    appointments = [
      ...appointments,
      ...getMedicationRequestFromRequestGroup(element),
    ]
  })

  return appointments
}

export function getKriyas(entries: R4.IBundle_Entry[]): R4.IServiceRequest[] {
  console.log('---------Kriyas bundle enties ----------', entries)
  const careplans: R4.ICarePlan[] = getCarePlans(entries)
  let appointments: R4.IServiceRequest[] = []

  careplans.forEach((element) => {
    appointments = [...appointments, ...getKriyasFromCarePlan(element)]
  })

  const kriyas = appointments.filter((e) => {
    if (e.category) {
      const code = getCodeOfSystemFromCodableConceptList(
        e.category,
        'http://snomed.info/sct'
      )
      if (code) {
        return code.code === '452091000124101'
      }
    }
    return false
  })

  return kriyas
}

export interface CategorizedKriyas {
  kriyas: R4.IServiceRequest[]
  category: R4.ICodeableConcept
}

export function getCategorizedKriyas(entries: R4.IBundle_Entry[]) {
  const categorizedKriyas: CategorizedKriyas[] = []
  const kriyas = getKriyas(entries)

  kriyas.forEach((e) => {
    if (e.category && e.category.length > 0) {
      let currentCategory = e.category[0]
      if (e.category.length > 1) {
        const index = e.category.findIndex(
          (c) =>
            getDefaultCodeOfSystemFromCodableConcept(
              c,
              'http://snomed.info/sct'
            ) !== '452091000124101'
        )
        if (index > -1) {
          currentCategory = e.category[index]
        }
      }
      if (categorizedKriyas.length > 0) {
        const index = categorizedKriyas.findIndex((c) => {
          if (
            c.category &&
            getDefaultCodeOfSystemFromCodableConcept(c.category)
          ) {
            return (
              getDefaultCodeOfSystemFromCodableConcept(c.category) ===
              getDefaultCodeOfSystemFromCodableConcept(currentCategory)
            )
          }
          return false
        })
        if (index >= 0) {
          categorizedKriyas[index].kriyas.push(e)
        } else {
          categorizedKriyas.push({
            category: currentCategory,
            kriyas: [e],
          })
        }
      } else {
        categorizedKriyas.push({
          category: currentCategory,
          kriyas: [e],
        })
      }
    }
  })

  console.log('---------categorizedKriyas----------', categorizedKriyas)

  return categorizedKriyas
}

export function getKriyasOrderDetailsFromEntries(kriya: R4.IServiceRequest) {
  let orderDetails
  if (kriya.orderDetail && kriya.orderDetail.length > 0) {
    orderDetails = kriya.orderDetail
      .map((e) => getDefaultDisplayOfSystemFromCodableConcept(e))
      .join(', ')
  }
  return orderDetails
}

export function getInstructions(
  entries: R4.IBundle_Entry[]
): R4.ICommunicationRequest[] {
  const requestGroups: R4.IRequestGroup[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'RequestGroup'
  ).map((e) => e.resource as R4.IRequestGroup)

  let appointments: R4.ICommunicationRequest[] = []

  requestGroups.forEach((element) => {
    appointments = [
      ...appointments,
      ...getInstructionsFromRequestGroup(element),
    ]
  })

  return appointments
}

export function getAddressingConditionsFromCarePlan(
  carePlan: R4.ICarePlan
): R4.ICondition[] {
  const appointments: R4.ICondition[] = _.filter(
    carePlan.contained,
    (e) => (e as any).resource.resourceType === 'Condition'
  ).map((e) => (e as any).resource as R4.ICondition)

  return appointments
}

export function getKriyasFromCarePlan(
  carePlan: R4.ICarePlan
): R4.IServiceRequest[] {
  console.log('---------Kriyas cp----------', carePlan)
  const appointments: R4.IServiceRequest[] = _.filter(
    carePlan.activity ?? [],
    (e) => {
      if (e.detail) {
        if (e.detail.kind) {
          if (e.detail.kind === 'ServiceRequest') {
            return true
          }
        }
      }
      return false
    }
  ).map((e) => ({
    resourceType: 'ServiceRequest',
    ...e.detail,
    subject: carePlan.subject,
  }))

  console.log('---------Kriyas----------', appointments)

  return appointments.filter((e) => e !== undefined)
}

export function getGoalsFromCarePlan(carePlan: R4.ICarePlan): R4.IGoal[] {
  const appointments: R4.IGoal[] = _.filter(
    carePlan.contained,
    (e) => (e as any).resource.resourceType === 'Goal'
  ).map((e) => (e as any).resource as R4.IGoal)

  return appointments
}

export function getCarePlans(entries: R4.IBundle_Entry[]): R4.ICarePlan[] {
  const appointments: R4.ICarePlan[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'CarePlan'
  ).map((e) => (e as any).resource as R4.ICarePlan)

  return appointments
}

export function getCarePlanDescriptions(entries: R4.IBundle_Entry[]): string {
  const appointments: R4.ICarePlan[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'CarePlan'
  ).map((e) => (e as any).resource as R4.ICarePlan)
  let deascriptions: string = ''
  appointments.forEach((e) => {
    if (e.description) deascriptions += e.description
  })

  return deascriptions
}

export function getAddressingConditions(
  entries: R4.IBundle_Entry[]
): R4.ICondition[] {
  const careplans: R4.ICarePlan[] = getCarePlans(entries)
  let conditions: R4.ICondition[] = []

  careplans.forEach((element) => {
    conditions = [
      ...conditions,
      ...getAddressingConditionsFromCarePlan(element),
    ]
  })

  return conditions
}

export function getAddressingConditionCodes(
  entries: R4.IBundle_Entry[]
): R4.ICoding[] {
  const conditions: R4.ICondition[] = getAddressingConditions(entries)

  const codings: R4.ICoding[] = conditions.map((e) => e.code?.coding![0]!) ?? []

  return codings
}

export function hasError(cardEntry: CardsEntity): boolean {
  if (
    cardEntry.extension &&
    cardEntry.extension.errors &&
    cardEntry.extension.errors.length > 0
  ) {
    if (
      cardEntry.extension.errors?.[0] &&
      cardEntry.extension.errors?.[0].length > 0
    ) {
      return true
    }
  }
  return false
}

export function getErrors(cardEntry: CardsEntity): string[] | undefined {
  if (
    cardEntry.extension &&
    cardEntry.extension.errors &&
    cardEntry.extension.errors.length > 0
  ) {
    return cardEntry.extension.errors
  }
  return undefined
}

export function isEssential(resource: any): boolean {
  return isSameType(resource, 'essential')
}

export function isSameType(resource: any, type: string): boolean {
  const res: R4.IResource = resource as R4.IResource
  if (res.meta && res.meta.tag) {
    const ind = res.meta.tag.findIndex(
      (e) =>
        e.system ===
        'http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-hypertension-cc-type'
    )
    if (ind >= 0) {
      return res.meta.tag[ind].code === type
    }
  }
  return false
}

export function getLoincCodeFromPlanDef(
  planDef: R4.IPlanDefinition
): string | undefined {
  if (planDef.identifier) {
    const index = planDef.identifier.findIndex(
      (e) => e.system === 'http://loinc.org'
    )
    if (index >= 0) {
      return planDef.identifier[index].value
    }
  }
  return undefined
}

export function getTestsOfTypeInCard(
  cardEntry: CardsEntity,
  type?: string
): R4.IPlanDefinition[] {
  let tests: R4.IPlanDefinition[] = []

  if (cardEntry.suggestions) {
    cardEntry.suggestions?.forEach((item) => {
      if (item.actions) {
        item.actions.forEach((action) => {
          tests = [
            ...tests,
            ...getTestsToBePerformed(
              (action.resource as R4.IBundle).entry ?? [],
              type
            ),
          ]
        })
      }
    })
  }

  return tests
}

export function hasLabTestsOfType(
  combined: R4.IPlanDefinition[],
  system: string,
  code: string
) {
  if (combined.length > 0) {
    return combined.some((e) => {
      if (e.type) {
        const type = getCodeOfSystemFromCodableConcept(e.type, system)

        return type?.code === code
      }
      return false
    })
  }
  return false
}

export function getLabTestsOfType(
  input: R4.IPlanDefinition[],
  system: string,
  code: string
) {
  const combined = [...input]
  if (combined.length > 0) {
    return combined
      .filter((e) => {
        if (e.type) {
          const type = getCodeOfSystemFromCodableConcept(e.type, system)
          return type?.code === code
        }
        return false
      })
      .sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0)
  }
  return undefined
}

export function getMedicationOfTypeInCard(
  cardEntry: CardsEntity
): R4.IMedicationRequest[] {
  let tests: R4.IMedicationRequest[] = []

  if (cardEntry.suggestions) {
    cardEntry.suggestions?.forEach((item) => {
      if (item.actions) {
        item.actions.forEach((action) => {
          tests = [
            ...tests,
            ...getMedications((action.resource as R4.IBundle).entry ?? []),
          ]
        })
      }
    })
  }

  return tests
}

export function getJustificationOfPlanDef(
  planDef: R4.IPlanDefinition
): string | undefined {
  if (planDef.relatedArtifact && planDef.relatedArtifact?.length > 0) {
    const index = planDef.relatedArtifact.findIndex(
      (e) => e.type === R4.RelatedArtifactTypeKind._justification
    )
    if (index >= 0) {
      return planDef.relatedArtifact[index].display
    }
  }
  return ''
}

export function getServiceRequests(
  entries: R4.IBundle_Entry[]
): R4.ICarePlan_Detail[] {
  const appointments: R4.ICarePlan[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'CarePlan'
  ).map((e) => (e as any).resource as R4.ICarePlan)
  const serviceRequests: R4.ICarePlan_Detail[] = []
  appointments.forEach((e) => {
    if (e.activity && e.activity.length > 0) {
      e.activity.forEach((j) => {
        if (j.detail) {
          if (j.detail.kind === 'ServiceRequest') {
            serviceRequests.push(j.detail)
          }
        }
      })
    }
  })

  return serviceRequests
}

export function getCarePlanActivities(
  entries: R4.IBundle_Entry[]
): R4.ICarePlan_Detail[] {
  const appointments: R4.ICarePlan[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'CarePlan'
  ).map((e) => (e as any).resource as R4.ICarePlan)
  const serviceRequests: R4.ICarePlan_Detail[] = []
  appointments.forEach((e) => {
    if (e.activity && e.activity.length > 0) {
      e.activity.forEach((j) => {
        if (j.detail) {
          serviceRequests.push(j.detail)
        }
      })
    }
  })

  return serviceRequests
}

export function getRepeatedAppointments(
  entries: R4.IBundle_Entry[]
): R4.ICarePlan_Detail[] {
  const appointments: R4.ICarePlan[] = _.filter(
    entries,
    (e) => e.resource!.resourceType === 'CarePlan'
  ).map((e) => (e as any).resource as R4.ICarePlan)
  const serviceRequests: R4.ICarePlan_Detail[] = []
  appointments.forEach((e) => {
    if (e.activity && e.activity.length > 0) {
      e.activity.forEach((j) => {
        if (j.detail) {
          if (j.detail.kind === 'Appointment') {
            serviceRequests.push(j.detail)
          }
        }
      })
    }
  })

  return serviceRequests
}

export function getKriyaNameFromKriyaDetail(kriyaDetails: KriyaDetail) {
  if (kriyaDetails.rawKriyaDetail) {
    if (kriyaDetails.rawKriyaDetail.code) {
      const kriyaName = getDefaultDisplayOfSystemFromCodableConcept(
        kriyaDetails.rawKriyaDetail.code
      )
      return kriyaName
    }
  }

  const kriyaName = getDefaultDisplayOfSystemFromCodableConcept(
    kriyaDetails.kriya
  )
  return kriyaName
}

export function getKriyaNameFormServiceRequest(sv: R4.IServiceRequest) {
  if (sv.code) {
    return getDefaultDisplayOfSystemFromCodableConcept(sv.code)
  }
  return undefined
}
