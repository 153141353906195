import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  isPhysiotherapist,
} from 'services/userDetailsService'
import { sleep } from 'utils/dateUtil'
import {
  getNameFromHumanName,
  getNameOfPatient,
} from 'utils/fhirResourcesHelper'
import { requestForTherapiesForIpd } from '../physioTherapy/viewPhysioTherapiesOfEncounter/viewPhysioTherapiesOfEncounterSlice'
import { requestForTreatment } from '../treatment/viewTreatment/treatmentSearchSlice'
import { ProcedureAddStatus } from './addProcedureStatus'

const initialState: ProcedureAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addProcedureDetailsSlice = createSlice({
  name: 'addProcedureDetailsSlice',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<ProcedureAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addedVitalsBundle = action.payload.addedVitalsBundle
    },

    resetProdcedure(state, action: PayloadAction<ProcedureAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addedVitalsBundle = initialState.addedVitalsBundle
    },
  },
})

export const addProcedueDetailsIPD =
  (
    appointment: FhirActiveIPDDetailsForMedicalRole,

    treatMent: TreatmentPlanData,
    startTime?: string,
    endTime?: string,
    notes?: string,
    selectedPractWithRole?: PractitionerWithRole[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: ProcedureAddStatus = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(
      addProcedureDetailsSlice.actions.updateAddVitalsStatus(addingState)
    )

    try {
      const bundleObject: R4.IBundle = createBundleObjectForObservations(
        appointment,
        treatMent,
        startTime,
        endTime,
        notes,
        selectedPractWithRole
      )

      const resource: any = {
        eventType: 'vitals',
        eventBody: bundleObject,
      }

      const enRolClient: EnrolCient = new EnrolCient()
      const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
        `ipd/${appointment.mainServiceRequest.id}/addIpdEventDetails`,
        resource
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (response.status === 'VitalsDetails Of Patient added') {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        await sleep(5000)
        dispatch(showSuccessAlert('Treatment Plan updated Successfully'))
        if (isPhysiotherapist()) {
          dispatch(
            requestForTherapiesForIpd(appointment.mainServiceRequest.id!)
          )
        }

        dispatch(requestForTreatment(appointment.mainServiceRequest.id!))
        dispatch(
          addProcedureDetailsSlice.actions.updateAddVitalsStatus(addingState)
        )
      } else {
        const errorCreatePersonState: ProcedureAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while creating patient',
        }
        dispatch(
          addProcedureDetailsSlice.actions.updateAddVitalsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: ProcedureAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding vitals',
      }
      dispatch(
        addProcedureDetailsSlice.actions.updateAddVitalsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetProdcedureState = () => (dispatch: AppDispatch) => {
  dispatch(addProcedureDetailsSlice.actions.resetProdcedure(initialState))
}

function createBundleObjectForObservations(
  appointment: FhirActiveIPDDetailsForMedicalRole,
  treatment: TreatmentPlanData,
  startTime?: string,
  endTime?: string,
  notes?: string,
  selectedPractWithRole?: PractitionerWithRole[]
): R4.IBundle {
  const oldUpdatedServiceRequest: R4.IServiceRequest = {
    ...treatment.treatmentPlan!,
  }
  const oldAppointmentMatchString: string = `W/${JSON.stringify(
    oldUpdatedServiceRequest.meta?.versionId ?? ' '
  )}`
  oldUpdatedServiceRequest.status = 'completed'

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `${oldUpdatedServiceRequest.resourceType}/${oldUpdatedServiceRequest.id}`,
        request: {
          ifMatch: oldAppointmentMatchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `${oldUpdatedServiceRequest.resourceType}/${oldUpdatedServiceRequest.id}`,
        },
        resource: { ...oldUpdatedServiceRequest, status: 'completed' },
      },
    ],
  }

  const practitioner: R4.IPractitioner = getCurrentUserPractitionerDetails()
  const practitionerRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()

  const codeData: R4.ICoding = {
    code: '386053000',
    display: 'Evaluation procedure',
    system: 'http://snomed.info/sct',
  }

  let startTimeData: string = treatment.treatmentPlan
    ? treatment.treatmentPlan.occurrencePeriod?.start ?? ''
    : ''
  let endTimeData: string = treatment.treatmentPlan
    ? treatment.treatmentPlan.occurrencePeriod?.end ?? ''
    : ''
  if (startTime) {
    const newDate = new Date(
      `${moment(treatment.date).format('YYYY-MM-DD')}T${startTime ?? ''}`
    )

    const isoStartDateTIme = newDate.toISOString()
    startTimeData = isoStartDateTIme
  }

  if (endTime) {
    const newDateEnd = new Date(
      `${moment(treatment.date).format('YYYY-MM-DD')}T${endTime}`
    )
    const isoEndDateTIme = newDateEnd.toISOString()
    endTimeData = isoEndDateTIme
  }

  const performerDetails: R4.IProcedure_Performer[] = []

  if (selectedPractWithRole) {
    for (let i = 0; i < selectedPractWithRole.length; i++) {
      const performers: R4.IProcedure_Performer = {
        actor: {
          display: selectedPractWithRole[i].name,
          id: selectedPractWithRole[i].practRoleId ?? '',
          reference: selectedPractWithRole[i].roleObject
            ? `${selectedPractWithRole[i].roleObject.resourceType}/${selectedPractWithRole[i].roleObject.id}`
            : '',
          type: selectedPractWithRole[i].roleObject
            ? selectedPractWithRole[i].roleObject.resourceType
            : '',
        },
      }
      performerDetails.push(performers)
    }
    // const performersCurrent: R4.IProcedure_Performer = {
    //   actor: {
    //     display: getNameFromHumanName(practitioner.name ?? []),
    //     id: practitionerRole.id,
    //     reference: `${practitionerRole.resourceType}/${practitionerRole.id}`,
    //     type: practitionerRole.resourceType,
    //   },
    // }
    // performerDetails.push(performersCurrent)
  } else {
    const performers: R4.IProcedure_Performer = {
      actor: {
        display: getNameFromHumanName(practitioner.name ?? []),
        id: practitionerRole.id,
        reference: `${practitionerRole.resourceType}/${practitionerRole.id}`,
        type: practitionerRole.resourceType,
      },
    }
    performerDetails.push(performers)
  }

  const condition: R4.IProcedure = {
    resourceType: 'Procedure',
    subject: {
      display: getNameOfPatient(appointment.patient),
      id: appointment.patient.id,
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      type: appointment.patient.resourceType,
    },
    status: 'completed',
    basedOn: [
      {
        reference: `${appointment.mainServiceRequest.resourceType}/${appointment.mainServiceRequest.id}`,
      },
      {
        reference: `${treatment.treatmentPlan?.resourceType}/${treatment.treatmentPlan?.id}`,
      },
    ],
    performedPeriod: {
      start: startTimeData,
      end: endTimeData,
    },
    code: {
      coding: [
        treatment.treatmentPlan
          ? treatment.treatmentPlan.code
            ? treatment.treatmentPlan.code.coding
              ? treatment.treatmentPlan.code.coding[0]
              : codeData
            : codeData
          : codeData,
      ],
      text: treatment.treatmentPlan
        ? treatment.treatmentPlan.code
          ? treatment.treatmentPlan.code.coding
            ? treatment.treatmentPlan.code.coding[0].display ?? ''
            : ''
          : ''
        : '',
    },

    recorder: {
      display: getNameFromHumanName(practitioner.name ?? []),
      id: practitionerRole.id,
      reference: `${practitionerRole.resourceType}/${practitionerRole.id}`,
      type: practitionerRole.resourceType,
    },
    performer: performerDetails,
    note: [
      {
        text: notes ?? '',
      },
    ],

    category: {
      coding: [
        {
          code: '1000',
          display: 'TLC Procedure',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-procedure-category-cs',
        },
      ],
    },
  }

  requestBundle.entry?.push({
    fullUrl: `${condition.resourceType}/`,
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: condition.resourceType,
    },
    resource: condition,
  })

  return requestBundle
}

export default addProcedureDetailsSlice.reducer
