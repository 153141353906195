import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from 'redux/store'
import { UmlClient } from 'services/umlsClient'
import { getUserCurrentRole } from 'services/userDetailsService'
import { logger } from 'utils/logger'
import { RespiratoryConditionsSearchTypes as CentralNervousConditionsSearchTypes } from './centralNervousConditionsSearchTypes'

const initialState: CentralNervousConditionsSearchTypes = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const centralNervousConditionsSearchSlice = createSlice({
  name: 'centralNervousConditionsSearchSlice',
  initialState,
  reducers: {
    searchingCondition(
      state,
      action: PayloadAction<CentralNervousConditionsSearchTypes>
    ) {},

    searchResults(
      state,
      action: PayloadAction<CentralNervousConditionsSearchTypes>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<CentralNervousConditionsSearchTypes>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
    },

    errorWhileSearching(
      state,
      action: PayloadAction<CentralNervousConditionsSearchTypes>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
    },
    resetState(
      state,
      action: PayloadAction<CentralNervousConditionsSearchTypes>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.surgeryList = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: CentralNervousConditionsSearchTypes = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      surgeryList: undefined,
    }
    dispatch(centralNervousConditionsSearchSlice.actions.resetState(state))
  }

export const searchConditions =
  (searchString: string, selectOther: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: CentralNervousConditionsSearchTypes = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      centralNervousConditionsSearchSlice.actions.errorWhileSearching(
        errorSearchPatient
      )
    )
    try {
      const fhirClient: UmlClient = new UmlClient()
      const currentRole = getUserCurrentRole()
      const response: any = await fhirClient.doGetResource(
        `umls/ValueSetMatcher?Path=centralNervs&Match=${searchString}`
      )
      if (response.length > 0) {
        const searchPatientResult: CentralNervousConditionsSearchTypes = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          surgeryList: response,
          totalCount: response.total,
        }
        dispatch(
          centralNervousConditionsSearchSlice.actions.searchResults(
            searchPatientResult
          )
        )
        return
      }

      const noSearchResults: CentralNervousConditionsSearchTypes = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(
        centralNervousConditionsSearchSlice.actions.noDataFoundForSearch(
          noSearchResults
        )
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: CentralNervousConditionsSearchTypes = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        centralNervousConditionsSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(centralNervousConditionsSearchSlice.actions.resetState(initialState))
}

export default centralNervousConditionsSearchSlice.reducer
