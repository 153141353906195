import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListSubheader,
  Radio,
  RadioGroup,
  Typography,
  Tooltip,
  Collapse,
  Link,
} from '@material-ui/core'
import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedOccupation } from 'models/groupedOccupations'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestHabitsHistoryOfPatient } from 'redux/patientMedicalHistory/habitsHistory/habitsHistorySlice'
import { RootState } from 'redux/rootReducer'
import { isUnitAdmin } from 'services/userDetailsService'
import { getTimeAgo } from 'utils/dateUtil'
import {
  getDateWiseVitals,
  getDateWiseVitalsForOthers,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getObservationCodeText,
  getObservationCodeTextVal,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { HabitStatementTile } from 'views/components/consultation/history/HabitStatementTabularTile'
import { HistoryObjectiveIndicativeComp } from 'views/components/LeftMenu/HistoryObjectiveIndicative'
import { VitalElement } from 'views/components/LeftMenu/VitalElement'
import {
  HistoryObjectiveIndicative,
  SOAPIndicativeElement,
} from 'wello-web-components'
import { AddHabits } from '../addition/addHabits'

interface HabitsProp {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  status: string
  splitview: boolean
}

export const Habits: React.FC<HabitsProp> = ({
  fhirAppointmentDetails,
  status,
  splitview,
}: HabitsProp) => {
  const { t } = useTranslation(['observation', 'common'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const dispatch = useDispatch()
  const habitsHistorySlice = useSelector(
    (state: RootState) => state.habitsHistorySlice
  )
  const [groupVitals, setGroupVitals] = useState<GroupedOccupation[]>([])
  useEffect(() => {
    dispatch(
      requestHabitsHistoryOfPatient(
        '',
        fhirAppointmentDetails.patient,
        splitview
      )
    )
  }, [dispatch, fhirAppointmentDetails])
  useEffect(() => {
    if (
      habitsHistorySlice.resultsAvailable &&
      habitsHistorySlice.groupedHabitData
    ) {
      updateVitals(habitsHistorySlice.groupedHabitData)
    }
  }, [habitsHistorySlice])

  function updateVitals(vitalList: GroupedOccupation[]) {
    const results: GroupedOccupation[] = []
    for (let i = 0; i < vitalList.length; i++) {
      results.push({
        date: vitalList[i].date,
        occupation: vitalList[i].occupation,
        checked: true,
      })
    }
    setGroupVitals(results)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: GroupedOccupation[] = [...groupVitals]
    values[index].checked = rate
    setGroupVitals(values)
  }
  return (
    <Box
      flexGrow
      width='100%'
      flexDirection='column'
      display='flex'
      style={{ padding: 0, margin: 0 }}
    >
      <Box flexGrow width='100%' display='flex' flexDirection='row'>
        <Box flexDirection='row' display='flex' flexGrow width='100%'>
          <Box py={1}>
            <Typography variant='subtitle1'>
              {' '}
              {t('labelCommon:habits')}{' '}
            </Typography>
          </Box>{' '}
          {/* {!isUnitAdmin() && status !== 'completed' && !splitview && (
            <IconButton
              style={{ padding: '4px' }}
              onClick={() => {
                setOpenAddDialogue(true)
              }}
            >
              <Add style={{ height: '14px' }} color='primary' />
            </IconButton>
          )} */}
        </Box>
      </Box>
      {!isUnitAdmin() && status !== 'completed' && !splitview && (
        <Box
          display='flex'
          flexGrow
          flexDirection='row'
          justifyContent='flex-start'
          paddingBottom={1}
        >
          <Tooltip title='' id='hab_4'>
            <IconButton
              aria-label='btn_ord_cancel'
              color='primary'
              id='hab_5'
              onClick={() => {
                setOpenAddDialogue(true)
              }}
              style={{ padding: 0 }}
            >
              <AddCircleIcon
                style={{ height: '16px', padding: 0 }}
                color='primary'
                id='hab_6'
              />{' '}
              <Typography
                variant='subtitle2'
                color='primary'
                component={Link}
                style={{ fontSize: 13 }}
                id='add_habit'
              >
                {' '}
                {t('labelCommon:habits')}{' '}
              </Typography>
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {habitsHistorySlice.searchingConditions && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <CircularProgress size={15} />
        </Box>
      )}
      {habitsHistorySlice.noResultsAvailable && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            {' '}
            {isUnitAdmin() || status === 'completed' || splitview
              ? 'No data available'
              : ''}{' '}
          </Typography>
        </Box>
      )}
      {habitsHistorySlice.errorWhileSearchingProcedures && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            Error while fetching habits
          </Typography>
        </Box>
      )}
      {habitsHistorySlice.resultsAvailable &&
        habitsHistorySlice.groupedHabitData && (
          <Box flexGrow width='100%' display='flex' flexDirection='column'>
            {groupVitals.map((val, index: number) => (
              <Box py={1} key={val.date ?? ''}>
                <Box
                  paddingX={1}
                  borderRadius={2}
                  style={{
                    backgroundColor: 'lightGrey',
                  }}
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height={40}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow={1}
                    alignItems='center'
                  >
                    <Typography
                      variant='subtitle1'
                      style={{
                        color: 'black',

                        fontWeight: 'bold',
                      }}
                    >
                      Recorded on{' '}
                      {moment(val.date).format('Do MMMM YYYY hh:mm A')}
                    </Typography>
                  </Box>
                  <Box
                    justifyContent='flex-end'
                    display='flex'
                    flexGrow={1}
                    alignItems='center'
                  >
                    <Box px={1}>
                      <Tooltip title=''>
                        <IconButton
                          aria-label='collapse_order_type'
                          size='small'
                          onClick={() => {
                            handleCollapseForPanel1(!val.checked, index)
                          }}
                        >
                          {val.checked && <ArrowDropUpOutlined />}
                          {!val.checked && <ArrowDropDownOutlined />}
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>

                <Collapse
                  in={val.checked}
                  style={{
                    width: '100%',
                  }}
                >
                  <HabitStatementTile topObs={val.occupation} id='1' />
                </Collapse>
              </Box>
            ))}
          </Box>
        )}

      <AddHabits
        open={openAddDialogue}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onHabbitAdded={() => {
          setOpenAddDialogue(false)
          dispatch(
            requestHabitsHistoryOfPatient(
              '',
              fhirAppointmentDetails.patient,
              false
            )
          )
        }}
        onClose={() => {
          setOpenAddDialogue(false)
        }}
      />
    </Box>
  )
}
