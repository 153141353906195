import { Box, TextField, Typography, TextFieldProps } from '@material-ui/core'
import moment from 'moment'

import React from 'react'
import { WelloFormItemLabel } from './WelloFormItemLabel'

interface WelloDateInputProps {
  title: string
  textProps: TextFieldProps
  displayDoneLabel?: boolean
}

export const WelloDatePicker: React.FunctionComponent<WelloDateInputProps> = (
  props: WelloDateInputProps
) => (
  <Box display='flex' flexDirection='column' flexGrow={1}>
    <Box py={1} px={0.25} display='flex' flexDirection='row'>
      <Typography variant='subtitle2'>{props.title}</Typography>
    </Box>
    <Box>
      <TextField
        variant='outlined'
        size='small'
        fullWidth
        type='date'
        InputProps={{
          inputProps: {
            min: '1871-01-01',
            max: moment().format('YYYY-MM-DD'),
          },
        }}
        {...props.textProps}
      />
    </Box>
  </Box>
)
