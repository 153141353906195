import { R4 } from '@ahryman40k/ts-fhir-types'
import { ObservationStatusKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { WelloVitals } from 'models/WelloConditions'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showSuccessAlert, showErrorAlert } from 'redux/alertHandler/alertSlice'
import { requestVitalDetailsOfPatient } from 'redux/consultation/vitalsDetailsSlice/vitalsDetailSlice'
import { createBundleObjectForObservationsForGeneralExam } from 'redux/fhirMedicalResources/addGeneralExam/addGeneralExamSlice'
import {
  addVitalDetails,
  createBundleObjectForObservationsVitals,
  resetAddVitalsState,
} from 'redux/fhirMedicalResources/addVital/addVitalSlice'
import { RootState } from 'redux/rootReducer'
import { cToF, fToC } from 'utils/appointment_handle/vitals_util'
import { updateAddictions } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { WelloLoadingPage } from 'wello-web-components'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  onClose: () => void
  onVitalAdded: () => void
  existingVitals: WelloVitals
}
export const EditVitals: React.FC<Props> = ({
  open,
  onClose,
  onVitalAdded,
  fhirAppointmentDetails,
  existingVitals,
}) => {
  const { t } = useTranslation(['common', 'observation'])

  const [temp, setTemp] = useState<number>()
  const [pulseRate, setPulseRate] = useState<number>()
  const [systolicBP, setSystolicBP] = useState<number>()
  const [diastolicBP, setDiastolicBP] = useState<number>()
  const [weight, setWeight] = useState<number>()
  const [height, setHeight] = useState<number>()
  const [rr, setRR] = useState<number>()

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  const [currentCondition, setCurrentCondition] = useState<WelloVitals>({
    id: _.random(1, 10000000).toString(),
    temp: existingVitals.temp,
    pulseRate: existingVitals.pulseRate,
    systolicBP: existingVitals.systolicBP,
    dia: existingVitals.dia,
    height: existingVitals.height,
    weight: existingVitals.weight,
    bmi: existingVitals.bmi,
    rr: existingVitals.rr,
    existingObs: existingVitals.existingObs,
  })

  function updateObsFinal() {
    setUpdateStatus({ requesting: true })
    const oldObsList: R4.IObservation[] = []
    if (
      currentCondition.existingObs &&
      currentCondition.existingObs.length > 0
    ) {
      for (let i = 0; i < currentCondition.existingObs!.length; i++) {
        const oldProcedure: R4.IObservation = {
          ...currentCondition.existingObs![i],
        }
        oldProcedure.status = ObservationStatusKind._enteredInError
        oldObsList.push(oldProcedure)
      }
    }
    console.log(currentCondition)

    const bundleObject: R4.IBundle = createBundleObjectForObservationsVitals(
      fhirAppointmentDetails,
      Number(currentCondition.temp),
      Number(currentCondition.pulseRate),
      Number(currentCondition.systolicBP),
      Number(currentCondition.dia),
      Number(currentCondition.weight),
      Number(currentCondition.height),
      Number(currentCondition.rr)
    )

    for (let i = 0; i < oldObsList.length; i++) {
      bundleObject.entry?.push({
        resource: oldObsList[i],
        fullUrl: `${oldObsList[i].resourceType}/${oldObsList[i].id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${oldObsList[i].resourceType}/${oldObsList[i].id}`,
        },
      })
    }

    setLoading(true)

    updateAddictions(bundleObject, fhirAppointmentDetails.appointment.id!).then(
      (e) => {
        if (e) {
          setLoading(false)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(showSuccessAlert('Vital details updated successfully'))
          onVitalAdded()
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while updating Vital details. Please try again later'
            )
          )
        }
      }
    )
  }

  const dispatch = useDispatch()

  const addVitalSlice = useSelector((state: RootState) => state.addVitalsSlice)

  function resetDetails() {
    setTemp(undefined)
    setPulseRate(undefined)
    setDiastolicBP(undefined)
    setHeight(undefined)
    setSystolicBP(undefined)
    setWeight(undefined)
    setRR(undefined)
  }

  //   useEffect(() => {
  //     if (addVitalSlice.additionSuccessful) {
  //       dispatch(
  //         requestVitalDetailsOfPatient(
  //           fhirAppointmentDetails.patient,
  //           fhirAppointmentDetails.appointment.id!
  //         )
  //       )
  //       resetDetails()
  //       onVitalAdded(addVitalSlice.addedVitalsBundle)
  //     }
  //     resetDetails()
  //   }, [addVitalSlice.additionSuccessful, addVitalSlice, onVitalAdded])

  useEffect(() => {
    setCurrentCondition({
      id: _.random(1, 10000000).toString(),
      temp: existingVitals.temp,
      pulseRate: existingVitals.pulseRate,
      systolicBP: existingVitals.systolicBP,
      dia: existingVitals.dia,
      height: existingVitals.height,
      weight: existingVitals.weight,
      bmi: existingVitals.bmi,
      rr: existingVitals.rr,
      existingObs: existingVitals.existingObs,
    })
  }, [existingVitals])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetDetails()
        // dispatch(resetAddVitalsState())
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Edit Vitals</DialogTitle>
      <DialogContent dividers={true}>
        <Box marginY={1}>
          <Grid container direction='column' spacing={1}>
            <Grid item>
              <Grid container direction='row' spacing={1}>
                <Grid
                  item
                  xs={5}
                  style={{
                    alignSelf: 'center',
                  }}
                />

                <Grid item xs={4}>
                  <Box paddingLeft={6}>
                    <Typography variant='h6'>Value</Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box paddingLeft={6}>
                    <Typography variant='h6'>Unit</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction='row' spacing={1}>
                <Grid
                  item
                  xs={5}
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  <Typography variant='h6'>
                    {t('labelCommon:body_temperature')}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    type='number'
                    id='obj_4'
                    size='small'
                    value={currentCondition.temp}
                    onChange={(event) => {
                      setCurrentCondition({
                        ...currentCondition,
                        temp: parseFloat(
                          parseFloat(event.target.value).toFixed(1)
                        ),
                      })
                    }}
                    error={
                      (currentCondition.temp! < 76 &&
                        currentCondition.temp! > 0) ||
                      currentCondition.temp! > 122
                    }
                    // helperText={
                    //   (temp! < 76 && temp! > 0) ||
                    //   temp! > 122 ||
                    //   Number.isNaN(temp)
                    //     ? `Enter between 76 to 122`
                    //     : ''
                    // }
                    InputProps={{
                      inputProps: {
                        max: 122,
                        min: 76,
                        step: 0.1,
                      },
                      //   endAdornment: (
                      //     <InputAdornment position='end'>
                      //       {' '}
                      //       <Typography>{'\u00B0F'}</Typography>
                      //     </InputAdornment>
                      //   ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box paddingLeft={7} paddingTop={1}>
                    <Typography>{'\u00B0F'}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction='row' spacing={1}>
                <Grid
                  item
                  xs={5}
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  <Typography variant='h6'>
                    {' '}
                    {t('labelCommon:pulse_rate')}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='obj_5'
                    size='small'
                    type='number'
                    value={currentCondition.pulseRate}
                    onChange={(event) => {
                      setCurrentCondition({
                        ...currentCondition,
                        pulseRate: parseInt(event.target.value, 10),
                      })
                    }}
                    error={
                      (currentCondition.pulseRate! < 20 &&
                        currentCondition.pulseRate! > 0) ||
                      currentCondition.pulseRate! > 500
                    }
                    // helperText={
                    //   (pulseRate! < 20 && pulseRate! > 0) ||
                    //   pulseRate! > 500 ||
                    //   Number.isNaN(pulseRate)
                    //     ? `Enter between 20 to 500`
                    //     : ''
                    // }
                    InputProps={{
                      color: 'primary',
                      inputProps: {
                        max: 500,
                        min: 20,
                      },
                      //   endAdornment: (
                      //     <InputAdornment
                      //       position='end'
                      //       style={{
                      //         WebkitAppearance: 'none',
                      //         MozAppearance: 'textfield',
                      //         margin: 0,
                      //       }}
                      //     >
                      //       {' '}
                      //       <Typography>bpm</Typography>
                      //     </InputAdornment>
                      //   ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box paddingLeft={7} paddingTop={1}>
                    <Typography>bpm</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction='row' spacing={1}>
                <Grid
                  item
                  xs={5}
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  <Typography variant='h6'> Height</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    type='number'
                    id='obj_6'
                    size='small'
                    value={currentCondition.height}
                    onChange={(event) => {
                      setCurrentCondition({
                        ...currentCondition,
                        height: parseFloat(
                          parseFloat(event.target.value).toFixed(1)
                        ),
                      })
                    }}
                    error={
                      (currentCondition.height! < 2.5 &&
                        currentCondition.height! > 0) ||
                      currentCondition.height! > 271
                    }
                    // helperText={
                    //   (height! < 2.5 && height! > 0) ||
                    //   height! >= 271 ||
                    //   Number.isNaN(height)
                    //     ? `Enter between 2.5 to 271`
                    //     : ''
                    // }
                    InputProps={{
                      color: 'primary',
                      inputProps: {
                        max: 271,
                        min: 2.5,
                      },
                      //   endAdornment: (
                      //     <InputAdornment
                      //       position='end'
                      //       style={{
                      //         WebkitAppearance: 'none',
                      //         MozAppearance: 'textfield',
                      //         margin: 0,
                      //       }}
                      //     >
                      //       {' '}
                      //       <Typography>cm</Typography>
                      //     </InputAdornment>
                      //   ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box paddingLeft={7} paddingTop={1}>
                    <Typography>cm</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction='row' spacing={1}>
                <Grid
                  item
                  xs={5}
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  <Typography variant='h6'> Weight</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='obj_7'
                    type='number'
                    size='small'
                    value={currentCondition.weight}
                    onChange={(event) => {
                      setCurrentCondition({
                        ...currentCondition,
                        weight: parseFloat(
                          parseFloat(event.target.value).toFixed(1)
                        ),
                      })
                    }}
                    error={
                      (currentCondition.weight! < 1 &&
                        currentCondition.weight! > 0) ||
                      currentCondition.weight! > 300
                    }
                    // helperText={
                    //   (weight! < 1 && weight! > 0) ||
                    //   weight! > 300 ||
                    //   Number.isNaN(weight)
                    //     ? `Enter between 1 to 300`
                    //     : ''
                    // }
                    InputProps={{
                      color: 'primary',
                      inputProps: {
                        max: 300,
                        min: 1,
                        step: 0.1,
                      },

                      //   endAdornment: (
                      //     <InputAdornment
                      //       position='end'
                      //       style={{
                      //         WebkitAppearance: 'none',
                      //         MozAppearance: 'textfield',
                      //         margin: 0,
                      //       }}
                      //     >
                      //       {' '}
                      //       <Typography>kg</Typography>
                      //     </InputAdornment>
                      //   ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box paddingLeft={7} paddingTop={1}>
                    <Typography>kg</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction='row' spacing={1}>
                <Grid
                  item
                  xs={5}
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  <Typography variant='h6'> Respiration Rate</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    type='number'
                    id='obj_8'
                    value={currentCondition.rr}
                    size='small'
                    onChange={(event) => {
                      setCurrentCondition({
                        ...currentCondition,
                        rr: parseInt(event.target.value, 10),
                      })
                    }}
                    error={
                      (currentCondition.rr! < 6 && currentCondition.rr! > 0) ||
                      currentCondition.rr! > 60
                    }
                    // helperText={
                    //   (rr! < 6 && rr! > 0) || rr! > 60 || Number.isNaN(rr)
                    //     ? `Enter between 6 to 60`
                    //     : ''
                    // }
                    InputProps={{
                      color: 'primary',
                      inputProps: {
                        max: 60,
                        min: 12,
                      },
                      //   endAdornment: (
                      //     <InputAdornment
                      //       position='end'
                      //       style={{
                      //         WebkitAppearance: 'none',
                      //         MozAppearance: 'textfield',
                      //         margin: 0,
                      //       }}
                      //     >
                      //       {' '}
                      //       <Typography>per min</Typography>
                      //     </InputAdornment>
                      //   ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box paddingLeft={7} paddingTop={1}>
                    <Typography>per min</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction='row' spacing={1}>
                <Grid
                  item
                  xs={5}
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  <Typography variant='h6'>
                    {t('labelCommon:blood_pressure')}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box display='flex' flexDirection='column'>
                    <Box
                      paddingBottom={1}
                      width='100%'
                      display='flex'
                      flexDirection='column'
                    >
                      <TextField
                        variant='outlined'
                        type='number'
                        value={currentCondition.systolicBP}
                        size='small'
                        id='obj_9'
                        label='Sys'
                        onChange={(event) => {
                          if (Number.isNaN(parseInt(event.target.value, 10))) {
                            setCurrentCondition({
                              ...currentCondition,
                              systolicBP: undefined,
                            })
                          } else {
                            setCurrentCondition({
                              ...currentCondition,
                              systolicBP: parseInt(event.target.value, 10),
                            })
                          }
                        }}
                        error={
                          (currentCondition.systolicBP! < 20 &&
                            currentCondition.systolicBP! > 0) ||
                          currentCondition.systolicBP! > 500
                        }
                        // helperText={
                        //   (systolicBP! < 20 && systolicBP! > 0) ||
                        //   systolicBP! > 500 ||
                        //   Number.isNaN(systolicBP)
                        //     ? `Enter between 20 to 500`
                        //     : ''
                        // }
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box paddingLeft={7} paddingTop={1}>
                    <Typography>mmHg</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction='row' spacing={1}>
                <Grid
                  item
                  xs={5}
                  style={{
                    alignSelf: 'center',
                  }}
                />

                <Grid item xs={4}>
                  <Box display='flex' flexDirection='column'>
                    <TextField
                      variant='outlined'
                      type='number'
                      label='Dia'
                      id='obj_10'
                      size='small'
                      value={currentCondition.dia}
                      onChange={(event) => {
                        if (Number.isNaN(parseInt(event.target.value, 10))) {
                          setCurrentCondition({
                            ...currentCondition,
                            dia: undefined,
                          })
                        } else {
                          setCurrentCondition({
                            ...currentCondition,
                            dia: parseInt(event.target.value, 10),
                          })
                        }
                      }}
                      error={
                        (currentCondition.dia! < 20 &&
                          currentCondition.dia! > 0) ||
                        currentCondition.dia! > 500
                      }
                      //   helperText={
                      //     (diastolicBP! < 20 && diastolicBP! > 0) ||
                      //     diastolicBP! > 500 ||
                      //     Number.isNaN(diastolicBP)
                      //       ? `Enter between 20 to 500`
                      //       : ''
                      //   }
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box paddingLeft={7} paddingTop={1}>
                    <Typography>mmHg</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          width='100%'
          flexDirection='row'
          display='flex'
          justifyContent='flex-end'
          paddingRight={5}
        >
          {addVitalSlice.adding && (
            <Box
              flexDirection='row'
              display='flex'
              alignSelf='center'
              justifyContent='center'
            >
              <CircularProgress size={25} />
            </Box>
          )}
          <Button
            onClick={() => {
              onClose()
              dispatch(resetAddVitalsState())
              resetDetails()
            }}
            variant='outlined'
            disableElevation
            id='obj_11'
            disabled={loading}
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            onClick={() => {
              console.log(isNaN(temp!))
              console.log(isNaN(pulseRate!))
              updateObsFinal()
            }}
            id='obj_12'
            variant='contained'
            color='primary'
            disableElevation
            disabled={
              currentCondition.temp! < 76 ||
              currentCondition.temp! > 122 ||
              currentCondition.pulseRate! < 20 ||
              currentCondition.pulseRate! >= 500 ||
              currentCondition.systolicBP! < 0 ||
              currentCondition.systolicBP! > 500 ||
              currentCondition.dia! < 0 ||
              currentCondition.dia! > 500 ||
              currentCondition.rr! < 6 ||
              currentCondition.rr! > 60 ||
              currentCondition.height! < 2.5 ||
              currentCondition.height! > 271 ||
              currentCondition.weight! < 1 ||
              currentCondition.weight! > 300 ||
              loading
            }
          >
            Update
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
