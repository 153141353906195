import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import { getOrdersForAgent } from 'utils/fhirResoureHelpers/labOrderHelpers'
import { getExpandedServiceRequestFromBundlePartner } from 'utils/fhirResoureHelpers/partnerLabOrderHelper'
import { logger } from 'utils/logger'
import { OrderSearchStatus } from './orderSearchSliceStatus'

const initialState: OrderSearchStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingOrders: false,
}

let currentSelectedDate: Date | undefined
let currentSelectedDoctors: PractitionerWithRole[]
let currentSelectedOrderTypes: string[] | undefined
let currentSelectedTests: LabOfferingDetail[] | undefined

const orderSearchSliceForAgent = createSlice({
  name: 'orderSearchSliceForAgent',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<OrderSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.availableOrders = action.payload.availableOrders
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingOrders = action.payload.errorWhileSearchingOrders
    },
  },
})

export const requestOrdersForTheDateRangeAgentPage =
  (
    selectedDoctors: PractitionerWithRole[],
    selectedDate?: Date,
    selectedOrderTypes?: string[],
    selectedTests?: LabOfferingDetail[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    currentSelectedDate = selectedDate
    currentSelectedDoctors = selectedDoctors
    currentSelectedOrderTypes = selectedOrderTypes
    currentSelectedTests = selectedTests
    const state: OrderSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(orderSearchSliceForAgent.actions.updatedStatus(state))
    try {
      const unitDetails = getCurrentUserUnitReference()
      currentSelectedDate?.setSeconds(new Date().getSeconds())
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const practRole = getCurrentUserPractitionerRoleDetails()
      const searchParameters: any = {
        period: `ge${moment(selectedDate)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ssZ')}`,
        owner: `PractitionerRole/${practRole.id}`,
      }

      const response: any =
        await fhirClient.doGetResourceIncludeAndIncludeIterate(
          `/Task?_include:iterate=Task:part-of&_include:iterate=Task:focus&_include:iterate=Task:subject&_include:iterate=ServiceRequest:instantiates-canonical&_include:iterate=PlanDefinition%3Aservice-billing&_revinclude%3Aiterate=Provenance:target&_include:iterate=ServiceRequest:specimen&_include:iterate=Task:owner&code=home-sample-collection`,
          searchParameters
        )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingOrders = true
        state.searchingAppointments = false

        dispatch(orderSearchSliceForAgent.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp.right
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          const fhirAppointments: FhirLabOrderDetail[] =
            getOrdersForAgent(appointmentResponse)

          fhirAppointments.sort((a, b) => moment(a.start).diff(b.start))
          logger.info(fhirAppointments)
          logger.info('Orders length')

          state.resultsAvailable = true
          state.searchingAppointments = false
          state.availableOrders = fhirAppointments
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingOrders = false
          dispatch(orderSearchSliceForAgent.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: OrderSearchStatus = {
            searchingAppointments: false,
            errorWhileSearchingOrders: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            orderSearchSliceForAgent.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: OrderSearchStatus = {
        searchingAppointments: false,
        errorWhileSearchingOrders: true,
        resultsAvailable: false,
        errorReason: 'Error while fetching orders',
      }
      dispatch(
        orderSearchSliceForAgent.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export const requestOrdersForPartnerLogin =
  (
    selectedDoctors: PractitionerWithRole[],
    locationId: string,
    selectedDate?: Date,
    selectedOrderTypes?: string[],
    selectedTests?: LabOfferingDetail[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    currentSelectedDate = selectedDate
    currentSelectedDoctors = selectedDoctors
    currentSelectedOrderTypes = selectedOrderTypes
    currentSelectedTests = selectedTests
    const state: OrderSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingOrders: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(orderSearchSliceForAgent.actions.updatedStatus(state))
    try {
      currentSelectedDate?.setSeconds(new Date().getSeconds())
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        /* '_include:iterate': 'ServiceRequest:performer', */
        _include: ['ServiceRequest:patient'],
        occurrence: `ge${moment(selectedDate)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ssZ')}`,
        'status:': 'active',
        '_include:iterate': [
          'ServiceRequest:performer',
          'ServiceRequest:instantiates-canonical',
          'PlanDefinition:service-billing',
          'Task:owner',
          'PractitionerRole:practitioner',
        ],
        _revinclude: 'Task:focus',
      }
      if (currentSelectedTests && currentSelectedTests.length > 0) {
        const params: string[] = currentSelectedTests.map(
          (e) => `${e.planDefinition.resourceType}/${e.planDefinition.id}`
        )
        searchParameters['instantiates-canonical'] = params.join(',')
        searchParameters.code = params.join(',')
      }
      if (currentSelectedOrderTypes && currentSelectedOrderTypes.length > 0) {
        searchParameters.code = currentSelectedOrderTypes.join(',')
      }
      if (currentSelectedDoctors && currentSelectedDoctors.length > 0) {
        let doctorsList: string = ''
        currentSelectedDoctors.forEach((item) => {
          doctorsList = `${
            doctorsList + item.roleObject.practitioner?.reference
          },`
        })

        searchParameters.performer = doctorsList
      }

      const response: any =
        await fhirClient.doGetResourceIncludeAndIncludeIterate(
          '/ServiceRequest',
          searchParameters
        )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingOrders = true
        state.searchingAppointments = false

        dispatch(orderSearchSliceForAgent.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp.right
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          const fhirAppointments: FhirLabOrderDetail[] =
            getExpandedServiceRequestFromBundlePartner(
              appointmentResponse,
              locationId
            )
          logger.info(fhirAppointments)
          logger.info('Orders length')

          state.resultsAvailable = true
          state.searchingAppointments = false
          state.availableOrders = fhirAppointments
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingOrders = false
          dispatch(orderSearchSliceForAgent.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: OrderSearchStatus = {
            searchingAppointments: false,
            errorWhileSearchingOrders: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            orderSearchSliceForAgent.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: OrderSearchStatus = {
        searchingAppointments: false,
        errorWhileSearchingOrders: true,
        resultsAvailable: false,
        errorReason: 'Error while fetching orders',
      }
      dispatch(
        orderSearchSliceForAgent.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export default orderSearchSliceForAgent.reducer
