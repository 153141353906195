/* eslint-disable import/no-named-as-default */
import { Box, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useState } from 'react'
import { WelloFormItemLabel } from './WelloFormItemLabel'

export interface WelloSelectAutoCompleteProps {
  id: string
  availableOptions: any[]
  valuesField: string
  displayField: string
  title?: string
  displayDoneLabel?: boolean
  preSelectedOption?: any
  error?: boolean
  helperText?: string
  onOptionSelected: (selectedOption: any) => void
  disabled: boolean
}

export const WelloSelectAutoComplete: React.FC<WelloSelectAutoCompleteProps> =
  ({
    preSelectedOption,
    availableOptions,
    title,
    valuesField,
    displayField,
    displayDoneLabel,
    id,
    onOptionSelected,
    error,
    helperText,
    disabled,
  }: WelloSelectAutoCompleteProps) => {
    const [selectedItem, setSelectedItem] = useState<any>(preSelectedOption)

    function getSelectedValuesText(): string {
      if (selectedItem && selectedItem[displayField]) {
        let returnString = ''
        returnString = selectedItem[displayField]
        returnString = returnString.slice(0, -1)
        return returnString
      }
      return 'Select'
    }
    return (
      <Box display='flex' flexDirection='column' flexGrow={1}>
        {title && (
          <WelloFormItemLabel
            title={title}
            displayDoneLabel={displayDoneLabel}
          />
        )}
        <Box>
          <Autocomplete
            limitTags={2}
            disableClearable
            defaultValue={preSelectedOption}
            id={id}
            disabled={disabled}
            options={availableOptions}
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option[displayField]
            }
            onChange={(event, value, reason) => {
              onOptionSelected(value)
              setSelectedItem(value)
            }}
            renderOption={(option, state) => (
              <Typography
                id={`${id}_ option_${option[displayField]}`}
                variant='subtitle2'
              >
                {option[displayField]}
              </Typography>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                id={`${id}_ edit`}
                label={selectedItem === undefined ? 'Select' : ''}
                variant='outlined'
                size='small'
                error={error}
                helperText={helperText}
                inputProps={{ ...params.inputProps, maxLength: '256' }}
              />
            )}
          />
        </Box>
      </Box>
    )
  }
