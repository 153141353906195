import { R4 } from '@ahryman40k/ts-fhir-types'
import { Questionnaire_ItemTypeKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppointmentRate, PractQuestions } from 'models/appointmentRate'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { FHIRDefaultApiClient } from 'services/fhirDefaultServices'
import { uploadFileDetails } from 'services/fileApiService'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  getUserCurrentRole,
  isCurrentUserDoctor,
  isMedicalServiceProvider,
  isUserDoctor,
  updateUserDetailsFromServer,
} from 'services/userDetailsService'
import { AddressProofType, IdProofType } from 'utils/constants/proof_types'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { logger } from 'utils/logger'
import { UnitAdminRegFormValues } from 'views/components/administration/actors/finish_unit_actor_setup'
import { UserProfileCompletionTypes } from './userProfileCompletionTypes'

const chargeRefInPerson: R4.IReference = {}
const chargeRefVideo: R4.IReference = {}
const chargeRefPhone: R4.IReference = {}
const serviceRef: R4.IReference = {}

const initialState: UserProfileCompletionTypes = {
  currentStep: 'basic_details',
  updatingBasicDetails: false,
  updatedBasicDetails: false,
  updatedRoleSpecificDetails: false,
  updatingRoleSpecificDetails: false,
  practitionerDetail: getCurrentUserPractitionerDetails(),
  practitionerRoleDetails: getCurrentUserPractitionerRoleDetails(),
  error: false,
  errorMessage: '',
}

const userProfileCompletionSlice = createSlice({
  name: 'userProfileCompletionSlice',
  initialState,
  reducers: {
    addingPatientDetails(
      state,
      action: PayloadAction<UserProfileCompletionTypes>
    ) {
      state.updatingBasicDetails = action.payload.updatingBasicDetails
      state.updatedBasicDetails = action.payload.updatedBasicDetails
      state.error = action.payload.error
    },

    patientDetailsAdded(
      state,
      action: PayloadAction<UserProfileCompletionTypes>
    ) {
      state.updatingBasicDetails = action.payload.updatingBasicDetails
      state.updatedBasicDetails = action.payload.updatedBasicDetails
      state.error = action.payload.error
    },

    errorInAddingPatientDetails(
      state,
      action: PayloadAction<UserProfileCompletionTypes>
    ) {
      state.updatingBasicDetails = action.payload.updatingBasicDetails
      state.updatedBasicDetails = action.payload.updatedBasicDetails
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetPatientDetails(
      state,
      action: PayloadAction<UserProfileCompletionTypes>
    ) {
      state.updatingBasicDetails = action.payload.updatingBasicDetails
      state.updatedBasicDetails = action.payload.updatedBasicDetails
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    updateState(state, action: PayloadAction<UserProfileCompletionTypes>) {
      state.updatingBasicDetails = action.payload.updatingBasicDetails
      state.updatedBasicDetails = action.payload.updatedBasicDetails
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.currentStep = action.payload.currentStep
      state.practitionerDetail = action.payload.practitionerDetail
      state.practitionerRoleDetails = action.payload.practitionerRoleDetails
      state.updatedRoleSpecificDetails =
        action.payload.updatedRoleSpecificDetails
      state.updatingRoleSpecificDetails =
        action.payload.updatingRoleSpecificDetails
    },
  },
})
export const getCurrentUserDetails =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const pract: R4.IPractitioner = getCurrentUserPractitionerDetails()
    if (pract.meta) {
      logger.info('Meta', pract.meta)
      if (pract.meta.tag && pract.meta.tag.length > 0) {
        const code: R4.ICoding | undefined = pract.meta.tag.find(
          (e) => e.code === 'profile_details_completed'
        )
        if (code) {
          if (isMedicalServiceProvider()) {
            const addingCreatePersonState: UserProfileCompletionTypes = {
              ...initialState,
              updatedBasicDetails: true,
              updatedRoleSpecificDetails: false,
              currentStep: 'role_specific_details',
              practitionerDetail: getCurrentUserPractitionerDetails(),
              practitionerRoleDetails: getCurrentUserPractitionerRoleDetails(),
            }
            dispatch(
              userProfileCompletionSlice.actions.updateState(
                addingCreatePersonState
              )
            )
            return
          }
          const addingCreatePersonState: UserProfileCompletionTypes = {
            ...initialState,
            updatedBasicDetails: true,
            updatedRoleSpecificDetails: true,
            currentStep: 'role_specific_details',
            practitionerDetail: getCurrentUserPractitionerDetails(),
            practitionerRoleDetails: getCurrentUserPractitionerRoleDetails(),
          }
          dispatch(
            userProfileCompletionSlice.actions.updateState(
              addingCreatePersonState
            )
          )
          return
        }
        logger.info('Din not find any completion details')
      }
    }
    const addingCreatePersonState: UserProfileCompletionTypes = {
      ...initialState,
      practitionerDetail: getCurrentUserPractitionerDetails(),
      practitionerRoleDetails: getCurrentUserPractitionerRoleDetails(),
    }
    dispatch(
      userProfileCompletionSlice.actions.updateState(addingCreatePersonState)
    )
  }

export const updatePractitionerBasicDetails =
  (formValues: UnitAdminRegFormValues, task?: R4.ITask): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: UserProfileCompletionTypes = {
      ...initialState,
      updatingBasicDetails: true,
    }
    dispatch(
      userProfileCompletionSlice.actions.addingPatientDetails(
        addingCreatePersonState
      )
    )

    try {
      const requestBody: R4.IBundle | undefined = await getTransactionObject(
        formValues,
        isMedicalServiceProvider() ? undefined : task
      )

      logger.info('request body', requestBody)
      if (requestBody) {
        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response: any | FHIRErrorResponses =
          await fhirApi.doCreateFHIRTransaction('', requestBody)
        logger.info('Response ', response)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          try {
            const practitionerDetail: R4.IPractitioner = {
              ...getCurrentUserPractitionerDetails(),
            }

            if (
              formValues.identityProofNumber &&
              formValues.identityProofImageFront
            ) {
              let identityProofFront: R4.IAttachment | undefined = {}

              identityProofFront = {
                data: formValues.identityProofImageFront,
                contentType: formValues.identityProofImageFrontType,
                title: formValues.identityProofImageFrontName,
              }

              if (identityProofFront) {
                await uploadFileDetails(
                  identityProofFront,
                  "Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofImageFront').value.as(Attachment)",
                  'Practitioner',
                  practitionerDetail.id ?? ''
                )
              }
            }

            if (
              formValues.identityProofNumber &&
              formValues.identityProofImageBack
            ) {
              let identityProofBack: R4.IAttachment | undefined = {}

              identityProofBack = {
                data: formValues.identityProofImageBack,
                contentType: formValues.identityProofImageBackType,
                title: formValues.identityProofImageBackName,
              }

              if (identityProofBack) {
                await uploadFileDetails(
                  identityProofBack,
                  "Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofback').value.as(Attachment)",
                  'Practitioner',
                  practitionerDetail.id ?? ''
                )
              }
            }

            if (
              formValues.addressProofNumber &&
              formValues.addressProofImageFront
            ) {
              let addressProofFront: R4.IAttachment | undefined = {}

              addressProofFront = {
                data: formValues.addressProofImageFront,
                contentType: formValues.addressProofImageFrontType,
                title: formValues.addressProofImageFrontName,
              }

              if (addressProofFront) {
                await uploadFileDetails(
                  addressProofFront,
                  "Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/addressProofImageFront').value.as(Attachment)",
                  'Practitioner',
                  practitionerDetail.id ?? ''
                )
              }
            }

            if (
              formValues.addressProofNumber &&
              formValues.addressProofImageBack
            ) {
              let addressProofBack: R4.IAttachment | undefined = {}

              addressProofBack = {
                data: formValues.addressProofImageBack,
                contentType: formValues.addressProofImageBack,
                title: formValues.addressProofImageBackName,
              }

              if (addressProofBack) {
                await uploadFileDetails(
                  addressProofBack,
                  "Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/addressProofImageBack').value.as(Attachment)",
                  'Practitioner',
                  practitionerDetail.id ?? ''
                )
              }
            }
          } catch (error) {
            logger.info(error)
            //   dispatch(showErrorAlert('Patient created. Error in uploading proofs'))
          }
          const res: boolean = await updateUserDetailsFromServer()
          if (res) {
            const pract: R4.IPractitioner = getCurrentUserPractitionerDetails()
            const practRole: R4.IPractitionerRole =
              getCurrentUserPractitionerRoleDetails()

            if (isMedicalServiceProvider()) {
              const successCreatePersonState: UserProfileCompletionTypes = {
                ...addingCreatePersonState,
                updatedBasicDetails: true,
                updatingBasicDetails: false,
                error: false,
                errorMessage: '',
                currentStep: 'role_specific_details',
                practitionerDetail: pract,
                practitionerRoleDetails: practRole,
              }
              dispatch(
                userProfileCompletionSlice.actions.updateState(
                  successCreatePersonState
                )
              )
              return
            }
            const successCreatePersonState: UserProfileCompletionTypes = {
              ...addingCreatePersonState,
              updatedBasicDetails: true,
              updatingBasicDetails: false,
              currentStep: 'role_specific_details',
              updatedRoleSpecificDetails: true,
              error: false,
              errorMessage: '',
              practitionerDetail: pract,
              practitionerRoleDetails: practRole,
            }
            dispatch(
              userProfileCompletionSlice.actions.updateState(
                successCreatePersonState
              )
            )
            return
          }
        }
      }
      const errorCreatePersonState: UserProfileCompletionTypes = {
        ...addingCreatePersonState,
        updatedBasicDetails: false,
        updatingBasicDetails: false,
        error: true,
        errorMessage: 'Error while updating details, Please Try later',
      }
      dispatch(
        userProfileCompletionSlice.actions.updateState(errorCreatePersonState)
      )
      return
    } catch (error) {
      logger.info('error')
      logger.info(error)
      const errorCreatePersonState: UserProfileCompletionTypes = {
        ...addingCreatePersonState,
        updatedBasicDetails: false,
        updatingBasicDetails: false,
        error: true,
        errorMessage: 'Error while updating details, Please Try later',
      }
      dispatch(
        userProfileCompletionSlice.actions.updateState(errorCreatePersonState)
      )
    }
  }

export const updatePractitionerRoleDetails =
  (
    practitioner: R4.IPractitioner,
    practitionerRole: R4.IPractitionerRole,
    chargeDefinitions: AppointmentRate,
    practConditions: PractQuestions,
    question: R4.IQuestionnaire,
    task?: R4.ITask
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: UserProfileCompletionTypes = {
      ...initialState,
      updatingBasicDetails: true,
    }
    dispatch(
      userProfileCompletionSlice.actions.addingPatientDetails(
        addingCreatePersonState
      )
    )

    try {
      const requestBody: R4.IBundle | undefined =
        await getRoleCompletionTransactionObject(
          practitioner,
          practitionerRole,
          chargeDefinitions,
          task
        )

      logger.info('request body', requestBody)
      if (requestBody) {
        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response: any | FHIRErrorResponses =
          await fhirApi.doCreateFHIRTransaction('', requestBody)
        logger.info('Response ', response)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          //   if (isUserDoctor(practitionerRole)) {
          //     const oldQuestions: R4.IQuestionnaire = { ...question }
          //     const item: R4.IQuestionnaire_Item[] = []
          //     for (let i = 0; i < practConditions.conditions.length; i++) {
          //       const questionData = {
          //         linkId: `1.${i + 1}`,
          //         text: practConditions.conditions[i].data.display ?? '',
          //         type: Questionnaire_ItemTypeKind._display,
          //         code: [practConditions.conditions[i].data],
          //         required: true,
          //       }
          //       item.push(questionData)
          //     }
          //   }
          if (isUserDoctor(practitionerRole)) {
            const oldQuestions: R4.IQuestionnaire = { ...question }
            const item: R4.IQuestionnaire_Item[] = []
            for (let i = 0; i < practConditions.conditions.length; i++) {
              const questionData = {
                linkId: `1.${i + 1}`,
                text: practConditions.conditions[i].data.display ?? '',
                type: Questionnaire_ItemTypeKind._display,
                code: [
                  {
                    code: practConditions.conditions[i].data.code,
                    system: practConditions.conditions[i].data.system,
                    value: practConditions.conditions[i].data.display,
                  },
                ],
                required: true,
              }
              item.push(questionData)
            }
            oldQuestions.item = item
            const fhirClient: FHIRDefaultApiClient = new FHIRDefaultApiClient()
            const resData: boolean =
              await fhirClient.doCreateFHIRResourceRequest(
                '/Questionnaire',
                oldQuestions
              )
          }

          const res: boolean = await updateUserDetailsFromServer()
          if (res) {
            logger.info('DetailsStored')
            const pract: R4.IPractitioner = getCurrentUserPractitionerDetails()
            const practRole: R4.IPractitionerRole =
              getCurrentUserPractitionerRoleDetails()
            const successCreatePersonState: UserProfileCompletionTypes = {
              ...addingCreatePersonState,
              updatedBasicDetails: true,
              updatingBasicDetails: false,
              currentStep: 'role_specific_details',
              updatedRoleSpecificDetails: true,
              error: false,
              errorMessage: '',
              practitionerRoleDetails: practRole,
              practitionerDetail: pract,
            }
            dispatch(
              userProfileCompletionSlice.actions.updateState(
                successCreatePersonState
              )
            )
            return
          }
          logger.info('DetailsStore error')
        }
      }

      logger.info('Error Decoding error')
      const errorCreatePersonState: UserProfileCompletionTypes = {
        ...addingCreatePersonState,
        updatedBasicDetails: false,
        updatingBasicDetails: false,
        error: true,
        errorMessage: 'Error while updating details, Please Try later',
      }
      dispatch(
        userProfileCompletionSlice.actions.updateState(errorCreatePersonState)
      )
      return
    } catch (error) {
      logger.info('error')
      logger.info(error)
      const errorCreatePersonState: UserProfileCompletionTypes = {
        ...addingCreatePersonState,
        updatedBasicDetails: false,
        updatingBasicDetails: false,
        error: true,
        errorMessage: 'Exception while updating details, Please Try later',
      }
      dispatch(
        userProfileCompletionSlice.actions.updateState(errorCreatePersonState)
      )
    }
  }

export const resetUserCompletionState = () => (dispatch: AppDispatch) => {
  dispatch(userProfileCompletionSlice.actions.updateState(initialState))
}

export const openBasicForm = () => (dispatch: AppDispatch) => {
  const addingCreatePersonState: UserProfileCompletionTypes = {
    ...initialState,
    updatingBasicDetails: true,
  }
  dispatch(
    userProfileCompletionSlice.actions.addingPatientDetails(
      addingCreatePersonState
    )
  )
}

export default userProfileCompletionSlice.reducer

async function getTransactionObject(
  formValues: UnitAdminRegFormValues,
  task?: R4.ITask
): Promise<R4.IBundle | undefined> {
  const practitionerDetail: R4.IPractitioner = {
    ...getCurrentUserPractitionerDetails(),
  }

  logger.info('request body', practitionerDetail)

  if (formValues) {
    const updatedPractitioner: R4.IPractitioner = {
      resourceType: 'Practitioner',
      identifier: practitionerDetail.identifier,
      id: practitionerDetail.id,
      name: [
        {
          prefix: practitionerDetail.name?.[0].prefix,
          given: [
            ...(practitionerDetail.name?.[0].given as string[]),
            formValues.middleName,
          ],
          family: formValues.lastName ?? '',
        },
      ],
      address: [
        {
          line: [formValues.address],
        },
      ],
      telecom: practitionerDetail.telecom,
      birthDate: formValues.dob,
      gender:
        formValues.gender === R4.PractitionerGenderKind._male
          ? R4.PractitionerGenderKind._male
          : formValues.gender === R4.PractitionerGenderKind._female
          ? R4.PractitionerGenderKind._female
          : formValues.gender === R4.PractitionerGenderKind._other
          ? R4.PractitionerGenderKind._other
          : R4.PractitionerGenderKind._unknown,
      photo: [
        {
          url: formValues.profilePic,
        },
      ],
      extension: [...(practitionerDetail.extension ?? ([] as R4.IExtension[]))],
      meta: {
        ...(practitionerDetail.meta ?? ({} as R4.IMeta)),
        tag: [
          {
            code: 'profile_details_completed',
            system:
              'http://wellopathy.com/fhir/india/core/vs/profileCompleitionIndicators',
          },
        ],
      },
    }
    logger.info('request body', updatedPractitioner)
    const entries: R4.IBundle_Entry[] = []

    if (
      formValues.identityProofNumber &&
      formValues.identityProofNumber.trim().length > 0
    ) {
      const identityProofCoding: R4.ICoding =
        IdProofType.find(
          (e: R4.ICoding) => e.code === formValues.identityProofTypeCode
        ) ?? {}

      updatedPractitioner.extension?.push({
        url: 'http://wellopathy.com/fhir/india/core/Code/identityProofType',
        valueCoding: identityProofCoding,
      })
      updatedPractitioner.extension?.push({
        url: 'http://wellopathy.com/fhir/india/core/Code/identityProofValue',
        valueAttachment: {
          url: formValues.identityProofNumber,
        },
      })

      updatedPractitioner.extension?.push({
        url: 'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront',
        valueAttachment: {
          contentType: formValues.identityProofImageFrontType,
          title: formValues.identityProofImageFrontName,
        },
      })

      //   entries.push({
      //     fullUrl: `${identityProofImageFront.resourceType}/`,
      //     request: {
      //       method: R4.Bundle_RequestMethodKind._post,
      //     },
      //     resource: identityProofImageFront,
      //   })
      if (
        formValues.identityProofImageBack &&
        formValues.identityProofImageBack.length > 0
      ) {
        // const identityProofImageBack: R4.IDocumentReference =
        //   await getDocumentReference(
        //     'identityProofImageBack',
        //     formValues.identityProofImageBack,
        //     updatedPractitioner
        //   )

        updatedPractitioner.extension?.push({
          url: 'http://wellopathy.com/fhir/india/core/Code/identityProofback',
          valueAttachment: {
            contentType: formValues.identityProofImageBackType,
            title: formValues.identityProofImageBackName,
          },
        })
      }
    }

    if (
      formValues.addressProofNumber &&
      formValues.addressProofNumber.trim().length > 0
    ) {
      const addressProofCoding: R4.ICoding =
        AddressProofType.find(
          (e) => e.code === formValues.addressProofTypeCode
        ) ?? {}

      updatedPractitioner.extension?.push({
        url: 'http://wellopathy.com/fhir/india/core/Code/addressProofType',
        valueCoding: addressProofCoding,
      })
      updatedPractitioner.extension?.push({
        url: 'http://wellopathy.com/fhir/india/core/Code/addressProofValue',
        valueString: formValues.addressProofNumber,
      })

      updatedPractitioner.extension?.push({
        url: 'http://wellopathy.com/fhir/india/core/Code/addressProofImageFront',
        valueAttachment: {
          contentType: formValues.addressProofImageFrontType,
          title: formValues.addressProofImageFrontName,
        },
      })

      updatedPractitioner.extension?.push({
        url: 'http://wellopathy.com/fhir/india/core/Code/addressProofImageBack',
        valueAttachment: {
          contentType: formValues.addressProofImageBackType,
          title: formValues.addressProofImageBackName,
        },
      })
    }

    const matchPractitionerString: string = `W/${JSON.stringify(
      practitionerDetail.meta?.versionId ?? ' '
    )}`

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        ...entries,
        {
          fullUrl: `Practitioner/${practitionerDetail.id}`,
          request: {
            method: R4.Bundle_RequestMethodKind._put,
            url: `Practitioner/${updatedPractitioner.id}`,
            ifMatch: matchPractitionerString,
          },
          resource: updatedPractitioner,
        },
      ],
    }

    if (task) {
      const modifiedTask: R4.ITask = { ...task }
      modifiedTask.status = R4.TaskStatusKind._completed

      const matchTaskString: string = `W/${JSON.stringify(
        modifiedTask.meta?.versionId ?? ' '
      )}`

      requestBundle.entry?.push({
        fullUrl: `Task/${task.id}`,
        request: {
          ifMatch: matchTaskString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `Task/${task.id}`,
        },
        resource: modifiedTask,
      })
    }
    logger.info('Request bundle', requestBundle)
    return requestBundle
  }

  logger.info('Undefined bundle')

  return undefined
}

async function getDocumentReference(
  type: string,
  image: string,
  pract: R4.IPractitioner
): Promise<R4.IDocumentReference> {
  const docRef: R4.IDocumentReference = {
    id: getUniqueTempId(),
    resourceType: 'DocumentReference',
    subject: {
      type: pract.resourceType,
      id: pract.id,
      reference: `${pract.resourceType}/${pract.id}`,
    },
    type: {
      coding: [
        {
          system: 'http://wellopathy.com/fhir/india/core/Code/userProofs',
          code: type,
        },
      ],
    },
    category: [
      {
        coding: [
          {
            system: 'http://wellopathy.com/fhir/india/core/Code/userProofs',
            code: 'user-proof',
          },
        ],
      },
    ],
    content: [
      {
        attachment: {
          data: image,
        },
      },
    ],
  }
  return docRef
}

const getChargeItem = (rate: number, type: string, followUp: number) => {
  const iRequest: R4.IBundle_Request = {}
  iRequest.method = R4.Bundle_RequestMethodKind._post
  iRequest.url = 'ChargeItemDefinition'
  const urlName = type.replaceAll(' ', '')
  const iMoney: R4.IMoney = {}
  iMoney.value = rate
  iMoney.currency = 'INR'
  const iComponent: R4.IChargeItemDefinition_PriceComponent = {}
  iComponent.amount = iMoney
  iComponent.type = 'base'
  const iPrice: R4.IChargeItemDefinition_PropertyGroup = {}
  iPrice.priceComponent = [iComponent]

  const iChargeItemObj: R4.IChargeItemDefinition = {
    resourceType: 'ChargeItemDefinition',
    description: type,
    publisher: 'Wellopathy Inc.',
    status: R4.ChargeItemDefinitionStatusKind._active,
    url: `http://wellopathy.com/ChargeItemDefinition/${urlName}`,
  }
  iChargeItemObj.propertyGroup = [iPrice]
  let urlString = ''
  if (type === 'in-person') {
    chargeRefInPerson.reference = `urn:uuid:ChargeItemDefinition${1}`
    urlString = `urn:uuid:ChargeItemDefinition${1}`
    iChargeItemObj.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-follow-up-ext',
        valueInteger: followUp,
      },
    ]
  }
  if (type === 'video') {
    chargeRefVideo.reference = `urn:uuid:ChargeItemDefinition${2}`
    urlString = `urn:uuid:ChargeItemDefinition${2}`
  }
  if (type === 'phone') {
    chargeRefPhone.reference = `urn:uuid:ChargeItemDefinition${3}`
    urlString = `urn:uuid:ChargeItemDefinition${3}`
  }

  const bundleEntry: R4.IBundle_Entry = {
    fullUrl: `${urlString}`,
    request: iRequest,
    resource: iChargeItemObj,
  }
  return bundleEntry
}

const getHealthService = (
  practitioner: R4.IPractitioner,
  practitionerRole: R4.IPractitionerRole
) => {
  const iRequest: R4.IBundle_Request = {}
  iRequest.method = R4.Bundle_RequestMethodKind._post
  iRequest.url = 'HealthcareService'
  const iService: R4.IHealthcareService = {
    resourceType: 'HealthcareService',
    type: [
      //   {
      //     coding: [
      //       {
      //         system:
      //           'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
      //         code: '538',
      //         display: 'Telephone Counselling',
      //       },
      //     ],
      //   },
      {
        coding: [
          {
            system:
              'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
            code: '556',
            display: 'Walk-in Centre /Non-Emergency',
          },
        ],
      },
      {
        coding: [
          {
            system:
              'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
            code: 'video-counselling',
            display: 'Video Counselling',
          },
        ],
      },
    ],
    active: true,
    providedBy: {
      id: practitionerRole.organization?.id ?? '',
      reference: `Organization/${practitionerRole.organization?.id}`,
      display: practitionerRole.organization?.display ?? '',
      type: 'Organization',
    },
    location: [
      {
        reference: practitionerRole.location
          ? practitionerRole.location[0].reference
          : '',
      },
    ],
    extension: [
      {
        url: 'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode',
        valueReference: {
          reference: `ChargeItemDefinition/${chargeRefInPerson.reference}`,
        },
      },
      {
        url: 'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode',
        valueReference: {
          reference: `ChargeItemDefinition/${chargeRefVideo.reference}`,
        },
      },
      //   {
      //     url: 'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode',
      //     valueReference: {
      //       reference: `ChargeItemDefinition/${chargeRefPhone.reference}`,
      //     },
      //   },
    ],
  }

  serviceRef.reference = `urn:uuid:HealthcareService`
  const bundleEntry: R4.IBundle_Entry = {
    fullUrl: `urn:uuid:HealthcareService`,
    request: iRequest,
    resource: iService,
  }
  return bundleEntry
}

async function getRoleCompletionTransactionObject(
  practitioner: R4.IPractitioner,
  practitionerRole: R4.IPractitionerRole,
  chargeDefinitions: AppointmentRate,
  task?: R4.ITask
): Promise<R4.IBundle | undefined> {
  const matchPractitionerString: string = `W/${JSON.stringify(
    practitioner.meta?.versionId ?? ' '
  )}`
  const matchPractitionerRoleString: string = `W/${JSON.stringify(
    practitionerRole.meta?.versionId ?? ' '
  )}`

  const bundleEntry: R4.IBundle_Entry[] = []
  const chargeItemForInPerson = getChargeItem(
    chargeDefinitions.inPerson,
    'in-person',
    chargeDefinitions.followUp
  )
  bundleEntry.push(chargeItemForInPerson)
  const chargeItemForVideo = getChargeItem(chargeDefinitions.video, 'video', 0)
  bundleEntry.push(chargeItemForVideo)
  //   const chargeItemForPhone = getChargeItem(chargeDefinitions.phone, 'phone', 0)
  //   bundleEntry.push(chargeItemForPhone)
  const healthService = getHealthService(practitioner, practitionerRole)
  bundleEntry.push(healthService)

  const practRole = { ...practitionerRole }
  practRole.healthcareService = [
    {
      reference: serviceRef.reference ?? '',
    },
  ]
  bundleEntry.push({
    fullUrl: `Practitioner/${practitioner.id}`,
    request: {
      method: R4.Bundle_RequestMethodKind._put,
      url: `Practitioner/${practitioner.id}`,
      ifMatch: matchPractitionerString,
    },
    resource: practitioner,
  })

  bundleEntry.push({
    fullUrl: `PractitionerRole/${practRole.id}`,
    request: {
      method: R4.Bundle_RequestMethodKind._put,
      url: `PractitionerRole/${practRole.id}`,
      ifMatch: matchPractitionerRoleString,
    },
    resource: practRole,
  })

  // const requestBundle: R4.IBundle = {
  //     resourceType: 'Bundle',
  //     type: R4.BundleTypeKind._transaction,
  //     entry: [
  //         {
  //             fullUrl: `Practitioner/${practitioner.id}`,
  //             request: {
  //                 method: R4.Bundle_RequestMethodKind._put,
  //                 url: `Practitioner/${practitioner.id}`,
  //                 ifMatch: matchPractitionerString,
  //             },
  //             resource: practitioner,
  //         },
  //         {
  //             fullUrl: `PractitionerRole/${practitionerRole.id}`,
  //             request: {
  //                 method: R4.Bundle_RequestMethodKind._put,
  //                 url: `PractitionerRole/${practitionerRole.id}`,
  //                 ifMatch: matchPractitionerRoleString,
  //             },
  //             resource: practitionerRole,
  //         },
  //     ],
  // }

  if (task) {
    const modifiedTask: R4.ITask = { ...task }
    modifiedTask.status = R4.TaskStatusKind._completed

    const matchTaskString: string = `W/${JSON.stringify(
      modifiedTask.meta?.versionId ?? ' '
    )}`

    bundleEntry.push({
      fullUrl: `Task/${task.id}`,
      request: {
        ifMatch: matchTaskString,
        method: R4.Bundle_RequestMethodKind._put,
        url: `Task/${task.id}`,
      },
      resource: modifiedTask,
    })
  }

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: bundleEntry,
  }

  return requestBundle
}
