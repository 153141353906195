import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import {
  showSuccessAlert,
  showWarningAlert,
} from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { UpdatePatientStatus } from './updatePatientStatusTypes'

const initialState: UpdatePatientStatus = {
  updating: false,
  updatedSuccessfully: false,
  error: false,
  errorMessage: '',
  patient: undefined,
}

const updatePatientSlice = createSlice({
  name: 'updatePatientSlice',
  initialState,
  reducers: {
    updateStatus(state, action: PayloadAction<UpdatePatientStatus>) {
      state.updating = action.payload.updating
      state.updatedSuccessfully = action.payload.updatedSuccessfully
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.patient = action.payload.patient
    },
  },
})

export const addNewAddressToPatient =
  (inputPatient: R4.IPatient, newAddress: R4.IAddress): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: UpdatePatientStatus = {
      updating: true,
      updatedSuccessfully: false,
      error: false,
    }
    dispatch(updatePatientSlice.actions.updateStatus(addingCreatePersonState))

    try {
      logger.info('patientDetails')

      logger.info(inputPatient)
      const patientDetails: R4.IPatient = { ...inputPatient }

      if (patientDetails.address && patientDetails.address.length > 0) {
        patientDetails.address = [...patientDetails.address, newAddress]
      } else {
        patientDetails.address = [newAddress]
      }
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doUpdateFHIRResourceRequest(
        `/Patient/${patientDetails.id}` ?? '',
        patientDetails,
        patientDetails.meta?.versionId ?? ''
      )
      const respDecoded: E.Either<Errors, R4.IPatient> =
        R4.RTTI_Patient.decode(response)
      if (respDecoded._tag === 'Right') {
        logger.info('Response Patient un decoded', respDecoded.right)
        const patientResponse: R4.IPatient = respDecoded.right
        logger.info('Response Patient ', patientResponse)
        if (patientResponse.id) {
          dispatch(showSuccessAlert('New address added to patient'))
          const successCreatePersonState: UpdatePatientStatus = {
            updating: false,
            updatedSuccessfully: true,
            error: false,
            errorMessage: '',
            patient: patientResponse,
          }
          dispatch(
            updatePatientSlice.actions.updateStatus(successCreatePersonState)
          )
          return
        }
        const errorCreatePersonState: UpdatePatientStatus = {
          updating: false,
          updatedSuccessfully: false,
          error: true,
          errorMessage: 'Error while updating patient',
        }
        dispatch(
          updatePatientSlice.actions.updateStatus(errorCreatePersonState)
        )

        dispatch(
          showWarningAlert('Error while adding new address. Please try later')
        )
        return
      }
      logger.info('Error while updating patient')

      logger.info(respDecoded.left)

      const errorCreatePersonState: UpdatePatientStatus = {
        updating: false,
        updatedSuccessfully: false,
        error: true,
        errorMessage: 'Error while updating patient',
      }
      dispatch(updatePatientSlice.actions.updateStatus(errorCreatePersonState))

      dispatch(
        showWarningAlert('Error while adding new address. Please try later')
      )
      return
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorCreatePersonState: UpdatePatientStatus = {
        updating: false,
        updatedSuccessfully: false,
        error: true,
        errorMessage: 'Error while uploading patient data',
      }
      dispatch(updatePatientSlice.actions.updateStatus(errorCreatePersonState))
    }
  }

export const resetUpdateUserAddressState = () => (dispatch: AppDispatch) => {
  dispatch(updatePatientSlice.actions.updateStatus(initialState))
}

export default updatePatientSlice.reducer
