import { R4 } from '@ahryman40k/ts-fhir-types'
import { boundedNumber } from 'fp-ts/lib/Bounded'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { IpdRegisterStatus } from 'redux/clinic/ipdmanagement/ipdRegister/ipdRegisterAddStatus'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserUnitDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import {
  getExtensionArray,
  getExtensionValueOfUrl,
  getFirstNameOfRelatedPerson,
  getIdentifierValueOfUrlType,
  getInnerExtension,
  getNameOfPatient,
} from 'utils/fhirResourcesHelper'
import { getDisplayOfBedOperationalStatus } from 'utils/fhirResoureHelpers/roomsHelper'
import { logger } from 'utils/logger'
import { sortBy } from 'lodash'

export interface AllDetailsOfRoom {
  bed: R4.ILocation
  ipdRegistration?: string
  patientName: string
  relatedPersonName: string
  bedNo: string
}

export interface BedRelPersonDetails {
  bed: R4.ILocation
}

export async function getAvailableRoomsForRoomType(
  roomType: string
): Promise<R4.IBundle | FHIRErrorResponses> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const organization: R4.IOrganization = getCurrentUserUnitDetails()
  const searchParameters: any = {
    organization: organization.id ?? '',
    _count: 200,
  }

  const response: any = await fhirClient.doGetResource(
    `/Location?name:contains=${roomType}&location-physical-type=ro&operational-status=U`,
    searchParameters
  )
  if (response.type === 'FHIRErrorResponses') {
    return response
  }

  const bundleRes: R4.IBundle = response as R4.IBundle
  if (bundleRes.entry && bundleRes.entry.length > 0) {
    return bundleRes
  }

  return {
    status: 400,
    data: {},
    displayText: 'Rooms List is not available',
  }
}

export async function getAvailableRoomsForRoomTypesParsed(
  roomType: string
): Promise<R4.ILocation[]> {
  const resBundle: R4.IBundle | FHIRErrorResponses =
    await getAvailableRoomsForRoomType(roomType)

  try {
    const res: R4.IBundle = resBundle as R4.IBundle
    if (res.entry && res.entry.length > 0) {
      const resLocations: R4.ILocation[] = res.entry.map(
        (e) => e.resource as R4.ILocation
      )
      return resLocations
    }
  } catch (error) {
    logger.error(error)
  }
  return []
}

async function getNameOfRelatedPerson(relPersonId: any): Promise<string> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const relPersonResp: any = await fhirClient.doGetResource(`${relPersonId}`)

  return relPersonResp.name[0].given[0]
}

function patientOrAttendantLatestOccurrence(
  patientOccur: any,
  attendantOccur: any
): string {
  const d1 = new Date(patientOccur)
  const d2 = new Date(attendantOccur)

  if (d1.getTime() > d2.getTime()) {
    return 'patient'
  }

  return 'attendant'
}

async function getAllSerReq(): Promise<R4.IServiceRequest[]> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const latestActiveSerReqs: any = await fhirClient.doGetResource(
    `ServiceRequest?&status=active&category=http://snomed.info/sct%7C394656005&_sort=-occurrence`
  )

  const responseBundleSerReqs: R4.IBundle = latestActiveSerReqs as R4.IBundle

  const serviceReqsList: R4.IServiceRequest[] =
    responseBundleSerReqs.entry?.map(
      (item) => item.resource as R4.IServiceRequest
    ) ?? []

  return serviceReqsList
}

export async function getAvailableBedsForRoom(
  room: string,
  isAllBeds?: boolean
): Promise<AllDetailsOfRoom[]> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  let searchParameters: any = {
    partof: `Location/${room}`,
    'location-physical-type': 'bd',
  }
  if (isAllBeds !== true) {
    searchParameters = {
      partof: `Location/${room}`,
      'location-physical-type': 'bd',
      'operational-status': 'U',
    }
  }

  const response: any = await fhirClient.doGetResource(
    `/Location`,
    searchParameters
  )

  const resp: R4.IBundle = response as R4.IBundle

  const locResources = resp.entry?.map((e) => e.resource as any as R4.ILocation)

  // bedsOfRoom gives all the child beds which are part of Parent Room
  const bedsOfRoom: any = locResources?.filter(
    (e) => e.partOf?.reference === `Location/${room}`
  )

  const convertedAppointments: AllDetailsOfRoom[] = []

  await Promise.allSettled(
    bedsOfRoom?.map(async (e: R4.ILocation) => {
      const details = await getOccupancyDetailsOfBed(e)
      convertedAppointments.push(details)
    })
  )
  console.log(convertedAppointments)
  return convertedAppointments
}

// convertedAppointments.sort((a: AllDetailsOfRoom, b: AllDetailsOfRoom) =>
//          (
//             getIdentifierValueOfUrlType(
//               a.bed.identifier ?? [],
//               'SNO',
//               'http://terminology.hl7.org/CodeSystem/v2-0203'
//             ) ?? ''
//           )
//             .toLowerCase()
//             .localeCompare(
//               (
//                 getIdentifierValueOfUrlType(
//                   b.bed.identifier ?? [],
//                   'SNO',
//                   'http://terminology.hl7.org/CodeSystem/v2-0203'
//                 ) ?? ''
//               ).toLowerCase()
//             )
//          )

export async function getAvailableBedsForRoomParsed(
  roomId: string,
  isAll?: boolean
): Promise<AllDetailsOfRoom[]> {
  try {
    const resp = await getAvailableBedsForRoom(roomId, isAll)

    resp.sort((a, b) =>
      a.bedNo.toLowerCase().localeCompare(b.bedNo.toLowerCase())
    )
    console.log(resp)
    return resp
  } catch (error) {
    logger.error(error)
  }
  return []
}

async function getOccupancyDetailsOfBed(
  bed: R4.ILocation
): Promise<AllDetailsOfRoom> {
  const bedResource: any = bed
  let ipdRegistrationNo: any = ''
  let patientFirstName: string = ''
  let patientMiddleName: string = ''
  let patientLastName: string = ''
  let patientNameDetail: string | undefined = ''
  let relatedPersonNameDetail: string = ''
  let temp: any = ''
  let bedNumber: string = ''

  bedNumber =
    getIdentifierValueOfUrlType(
      bed.identifier ?? [],
      'SNO',
      'http://terminology.hl7.org/CodeSystem/v2-0203'
    ) ?? ''

  const bedNumberInteger: number = parseInt(bedNumber, 10)

  if (getDisplayOfBedOperationalStatus(bed) === 'Occupied') {
    const fhirClient: FHIRApiClient = new FHIRApiClient()

    const responseData: any = await fhirClient.doGetResource(
      `/ServiceRequest?servicereq-location=Location/${bed.id}&status=active&category=http://snomed.info/sct%7C394656005&_include=ServiceRequest:patient&_sort=-occurrence&_count=1`
    )

    const res: R4.IBundle = responseData as R4.IBundle

    const serRequest = res.entry?.map(
      (item) => item.resource as R4.IServiceRequest
    )

    const patient = res.entry?.map((item) => item.resource as R4.IPatient)

    ipdRegistrationNo = serRequest
      ? serRequest[0].identifier
        ? serRequest[0].identifier[0].value
        : ''
      : ''
    patientFirstName = patient
      ? patient[1].name
        ? patient[1].name[0].given
          ? patient[1].name[0].given[0]
          : ''
        : ''
      : ''
    patientMiddleName = patient
      ? patient[1].name
        ? patient[1].name[0].given
          ? patient[1].name[0].given[1]
          : ''
        : ''
      : ''
    patientLastName = patient
      ? patient[1].name
        ? patient[1].name[0].family ?? ''
        : ''
      : ''

    patientNameDetail = `${patientFirstName}` + ' ' + `${patientLastName}`
    // patientNameDetail = patient ? (patient[1].name ? (patient[1].name[0].given ? patient[1].name[0].given[0] : '') : '') : ''

    const serReqsList: any = await getAllSerReq()
    let relatedPersonId

    for (let i = 0; i < serReqsList.length; i++) {
      if (serReqsList && serReqsList[i]) {
        const extArray: R4.IExtension[] = getExtensionArray(
          serReqsList[i].extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-attendant'
        )

        if (extArray.length > 0) {
          console.log(serReqsList[i].extension)

          const attendantBedId = getInnerExtension(
            serReqsList[i].extension ? serReqsList[i].extension : [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-attendant',
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-attendant-bed'
          )

          relatedPersonId = getInnerExtension(
            serReqsList[i].extension ? serReqsList[i].extension : [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-attendant',
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-attendant-ref'
          )
          const attendantRegistrationNo: any =
            serReqsList[i].identifier[0].value

          if (
            attendantBedId &&
            attendantBedId.length > 0 &&
            relatedPersonId &&
            relatedPersonId.length > 0
          )
            if (`Location/${bed.id}` === attendantBedId) {
              const occurrencePatSerReq = serRequest
                ? serRequest[0].occurrencePeriod?.start
                : ''
              const occurrenceAttendantSerReq =
                serReqsList[i].occurrencePeriod?.start ?? ''

              const latestOccurrence: any = patientOrAttendantLatestOccurrence(
                occurrencePatSerReq,
                occurrenceAttendantSerReq
              )

              if (latestOccurrence === 'attendant') {
                ipdRegistrationNo = attendantRegistrationNo
                patientNameDetail = undefined

                break
              }
            }
        }
      }
    }

    if (patientNameDetail === undefined) {
      relatedPersonNameDetail = await getNameOfRelatedPerson(relatedPersonId)

      temp = relatedPersonNameDetail
    }
  }
  return {
    bed: bedResource,
    ipdRegistration: ipdRegistrationNo,
    patientName: patientNameDetail ?? '',
    relatedPersonName: temp ?? '',
    bedNo: bedNumber,
  }
}
