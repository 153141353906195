import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  doctorSchedule,
  DoctorScheduleService,
  ErrorResponse,
} from 'lib/openApi'
import { OIDCUser } from 'models/oidcUser'
import { AppDispatch, AppThunk } from 'redux/store'
import { getOIDCUserObject } from 'utils/authHelpers'
import { ScheduleAddStatus } from './scheduleAddStatusType'

const initialState: ScheduleAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const scheduleSlice = createSlice({
  name: 'addSchedule',
  initialState,
  reducers: {
    addingScheduleDetails(state, action: PayloadAction<ScheduleAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
    },

    scheduleDetailsAdded(state, action: PayloadAction<ScheduleAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.schedule = action.payload.schedule
    },

    errorInAddingScheduleDetails(
      state,
      action: PayloadAction<ScheduleAddStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetScheduleDetails(state, action: PayloadAction<ScheduleAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.schedule = action.payload.schedule
    },
  },
})

export const addScheduleDetails = (
  schedule: doctorSchedule[]
): AppThunk => async (dispatch: AppDispatch) => {
  const addingCreatePractitionerState: ScheduleAddStatus = {
    adding: false,
    additionSuccessful: false,
    error: false,
  }
  dispatch(
    scheduleSlice.actions.addingScheduleDetails(addingCreatePractitionerState)
  )

  try {
    const oidcUser: OIDCUser | null = getOIDCUserObject()
    let result: any
    if (oidcUser != null) {
      const response:
        | doctorSchedule
        | ErrorResponse = await DoctorScheduleService.bulkDoctorSchedule({
        tid: oidcUser.wellopathy.tenantId,
        requestBody: schedule,
      })

      const successScheduleState: ScheduleAddStatus = {
        adding: false,
        additionSuccessful: true,
        error: false,
        errorMessage: '',
        schedule,
      }
      dispatch(scheduleSlice.actions.scheduleDetailsAdded(successScheduleState))
    } else {
      // TODO Log out user
    }
  } catch (error) {
    const errorCreateScheduleState: ScheduleAddStatus = {
      adding: false,
      additionSuccessful: false,
      error: true,
      errorMessage: 'Schedule already exists',
    }
    dispatch(
      scheduleSlice.actions.errorInAddingScheduleDetails(
        errorCreateScheduleState
      )
    )
  }
}

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(scheduleSlice.actions.resetScheduleDetails(initialState))
}

export default scheduleSlice.reducer
