/* eslint-disable guard-for-in */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { after } from 'lodash'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import {
  AyurvedaTreatmentPlanData,
  TreatmentPlanData,
} from 'models/treatmentPlanData'
import moment from 'moment'
import { WelloClinicAPIClient } from 'services/welloclinicAPIService'
import { getExpandedIPDAppointmentFromBundle } from './ipdAppointmentHelper'

export function getTreatmentPlanFromBundle(
  responseBundle: R4.IBundle,
  details: string
): TreatmentPlanData[] {
  const planData: TreatmentPlanData[] = []
  const serviceRequest: any = {}
  const encounters: any = {}
  const procedures: any = {}
  const observation: any = {}
  const morning: string[] = []
  const midMorning: string[] = []
  const afterNoonTherapy: string[] = []
  const eveningTherapy: string[] = []

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                case 'Procedure':
                  procedures[value.resource.id] =
                    value.resource as R4.IProcedure
                  break
                case 'ServiceRequest':
                  serviceRequest[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in serviceRequest) {
              const currentObServation: R4.IServiceRequest = serviceRequest[
                key1
              ] as R4.IServiceRequest

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]

                if (ref) {
                  if (currentEncounter.id === ref) {
                    const categoryCode = currentObServation.category
                      ? currentObServation.category[0].coding
                        ? currentObServation.category[0].coding
                          ? currentObServation.category[0].coding[0].code ?? ''
                          : ''
                        : ''
                      : ''
                    if (categoryCode === '225365006') {
                      console.log(currentObServation)
                      const dateTime = currentObServation.occurrencePeriod
                        ? currentObServation.occurrencePeriod.start ?? ''
                        : ''

                      const endDateTime = currentObServation.occurrencePeriod
                        ? currentObServation.occurrencePeriod.end ?? ''
                        : ''

                      const date1 = new Date(dateTime)

                      const end = new Date(endDateTime)
                      const date2 = date1
                        .toLocaleDateString()
                        .replace(/\//g, '-')

                      const currentProcedure: R4.IProcedure | undefined =
                        getProcedureData(procedures, currentObServation.id!)

                      const dateTime1 = currentProcedure?.performedPeriod
                        ? currentProcedure.performedPeriod.start ?? ''
                        : ''

                      const endDateTime1 = currentProcedure?.performedPeriod
                        ? currentProcedure.performedPeriod.end ?? ''
                        : ''

                      const date3 = new Date(dateTime1)
                      const end2 = new Date(endDateTime1)
                      if (currentObServation.status !== 'unknown')
                        if (
                          inBetWeenTime(
                            currentObServation.occurrencePeriod
                              ? currentObServation.occurrencePeriod.end
                                ? moment(
                                    currentObServation.occurrencePeriod.end,
                                    ['hh:mm A']
                                  ).format('HH:mm')
                                : '05:00'
                              : '05:000',
                            '04:59',
                            '08:00'
                          )
                        ) {
                          if (currentObServation.occurrencePeriod)
                            morning.push(
                              currentObServation.occurrencePeriod.end ?? ''
                            )
                        }

                      if (
                        inBetWeenTime(
                          currentObServation.occurrencePeriod
                            ? currentObServation.occurrencePeriod.end
                              ? moment(
                                  currentObServation.occurrencePeriod.end,
                                  ['hh:mm A']
                                ).format('HH:mm')
                              : '08:00'
                            : '08:000',
                          '08:00',
                          '12:00'
                        )
                      ) {
                        if (currentObServation.occurrencePeriod)
                          midMorning.push(
                            currentObServation.occurrencePeriod.end ?? ''
                          )
                      }
                      if (
                        inBetWeenTime(
                          currentObServation.occurrencePeriod
                            ? currentObServation.occurrencePeriod.end
                              ? moment(
                                  currentObServation.occurrencePeriod.end,
                                  ['hh:mm A']
                                ).format('HH:mm')
                              : '12:00'
                            : '12:000',
                          '12:00',
                          '18:00'
                        )
                      ) {
                        if (currentObServation.occurrencePeriod)
                          eveningTherapy.push(
                            currentObServation.occurrencePeriod.end ?? ''
                          )
                      }

                      afterNoonTherapy.sort()

                      if (
                        inBetWeenTime(
                          currentObServation.occurrencePeriod
                            ? currentObServation.occurrencePeriod.end
                              ? moment(
                                  currentObServation.occurrencePeriod.end,
                                  ['hh:mm A']
                                ).format('HH:mm')
                              : '18:00'
                            : '18:000',
                          '18:00',
                          '21:00'
                        )
                      ) {
                        if (currentObServation.occurrencePeriod)
                          eveningTherapy.push(
                            currentObServation.occurrencePeriod.end ?? ''
                          )
                      }
                      console.log(currentObServation.occurrencePeriod)
                      planData.push({
                        date: currentObServation.occurrencePeriod
                          ? currentObServation.occurrencePeriod.start ?? ''
                          : '',
                        endData: currentObServation.occurrencePeriod
                          ? currentObServation.occurrencePeriod.end ?? ''
                          : '',
                        startTime: moment(date1).format('hh:mm A'),
                        endTime: moment(end).format('hh:mm A'),
                        actualStart: moment(date3).isValid()
                          ? moment(date3).format('hh:mm A')
                          : '',
                        actualEnd: moment(end2).isValid()
                          ? moment(end2).format('hh:mm A')
                          : '',
                        therapy: currentObServation.code
                          ? currentObServation.code.coding
                            ? currentObServation.code.coding[0].display ?? ''
                            : ''
                          : '',
                        treatmentPlan: currentObServation,
                        treatmentType: currentObServation.code
                          ? currentObServation.code.text ?? ''
                          : '',
                        procedure: currentProcedure,
                        status: currentProcedure
                          ? 'Completed'
                          : currentObServation.status === 'revoked' ||
                            currentObServation.status === 'unknown'
                          ? 'Finished'
                          : 'Active',
                        morningTherapy: morning.sort(),
                        midMorningTherapy: midMorning.sort(),
                        afterNoonTherapy,
                        eveingTherapy: eveningTherapy.sort(),
                      })
                      //   }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  if (details.length > 0) {
    planData.sort((a, b) =>
      (a.date ? a.date : '') < (b.date ? b.date : '')
        ? -1
        : (a.date ?? '') > (b.date ?? '')
        ? 1
        : 0
    )
  } else {
    planData.sort((a, b) =>
      (a.date ? a.date : '') < (b.date ? b.date : '')
        ? -1
        : (a.date ?? '') > (b.date ?? '')
        ? 1
        : 0
    )
  }

  return planData
}

export function getTreatmentPlanFromBundleForAyurveda(
  responseBundle: R4.IBundle,
  details: string
): AyurvedaTreatmentPlanData[] {
  const planData: AyurvedaTreatmentPlanData[] = []
  const serviceRequest: any = {}
  const encounters: any = {}
  const procedures: any = {}
  const observation: any = {}
  const morning: string[] = []
  const midMorning: string[] = []
  const afterNoonTherapy: string[] = []
  const eveningTherapy: string[] = []

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                case 'Procedure':
                  procedures[value.resource.id] =
                    value.resource as R4.IProcedure
                  break
                case 'ServiceRequest':
                  serviceRequest[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in serviceRequest) {
              const currentObServation: R4.IServiceRequest = serviceRequest[
                key1
              ] as R4.IServiceRequest

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]

                if (ref) {
                  if (currentEncounter.id === ref) {
                    const categoryCode = currentObServation.category
                      ? currentObServation.category[0].coding
                        ? currentObServation.category[0].coding
                          ? currentObServation.category[0].coding[0].code ?? ''
                          : ''
                        : ''
                      : ''
                    if (categoryCode === '225365006') {
                      const dateTime = currentObServation.occurrencePeriod
                        ? currentObServation.occurrencePeriod.start ?? ''
                        : ''

                      const endDateTime = currentObServation.occurrencePeriod
                        ? currentObServation.occurrencePeriod.end ?? ''
                        : ''

                      const date1 = new Date(dateTime)
                      const end = new Date(endDateTime)
                      const date2 = date1
                        .toLocaleDateString()
                        .replace(/\//g, '-')

                      const currentProcedure: R4.IProcedure | undefined =
                        getProcedureData(procedures, currentObServation.id!)

                      const dateTime1 = currentProcedure?.performedPeriod
                        ? currentProcedure.performedPeriod.start ?? ''
                        : ''

                      const endDateTime1 = currentProcedure?.performedPeriod
                        ? currentProcedure.performedPeriod.end ?? ''
                        : ''

                      const date3 = new Date(dateTime1)
                      const end2 = new Date(endDateTime1)

                      const startTime = moment(date1, 'DD-MM-YYYY hh:mm:ss')
                      const endTime = moment(end, 'DD-MM-YYYY hh:mm:ss')
                      const minutesDiff = endTime.diff(startTime, 'minutes')

                      if (currentObServation.status !== 'unknown')
                        //   if (currentObServation.status !== 'unknown') {
                        planData.push({
                          date: currentObServation.occurrencePeriod
                            ? currentObServation.occurrencePeriod.start ?? ''
                            : '',
                          endData: currentObServation.occurrencePeriod
                            ? currentObServation.occurrencePeriod.end ?? ''
                            : '',
                          startTime: moment(date1).format('hh:mm A'),
                          endTime: moment(end).format('hh:mm A'),
                          actualStart: moment(date3).isValid()
                            ? moment(date3).format('hh:mm A')
                            : '',
                          actualEnd: moment(end2).isValid()
                            ? moment(end2).format('hh:mm A')
                            : '',
                          therapy: currentObServation.code
                            ? currentObServation.code.coding
                              ? currentObServation.code.coding[0].display ?? ''
                              : ''
                            : '',
                          treatmentPlan: currentObServation,
                          treatmentType: currentObServation.code
                            ? currentObServation.code.text ?? ''
                            : '',
                          procedure: currentProcedure,
                          status: currentProcedure
                            ? 'Completed'
                            : currentObServation.status === 'revoked' ||
                              currentObServation.status === 'unknown'
                            ? 'Finished'
                            : 'Active',
                          duration: `${minutesDiff} Mins`,
                          medication: currentObServation.supportingInfo
                            ? currentObServation.supportingInfo[0].display ?? ''
                            : '',
                        })
                      //   }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  if (details.length > 0) {
    planData.sort((a, b) =>
      (a.date ? a.date : '') < (b.date ? b.date : '')
        ? -1
        : (a.date ?? '') > (b.date ?? '')
        ? 1
        : 0
    )
  } else {
    planData.sort((a, b) =>
      (a.date ? a.date : '') < (b.date ? b.date : '')
        ? -1
        : (a.date ?? '') > (b.date ?? '')
        ? 1
        : 0
    )
  }

  return planData
}

export function getTreatmentPlanFromBundleForPT(
  responseBundle: R4.IBundle,
  splitView: string
): TreatmentPlanData[] {
  const planData: TreatmentPlanData[] = []
  const serviceRequest: any = {}
  const encounters: any = {}
  const procedures: any = {}
  const observation: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                case 'Procedure':
                  procedures[value.resource.id] =
                    value.resource as R4.IProcedure
                  break
                case 'ServiceRequest':
                  serviceRequest[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in serviceRequest) {
              const currentObServation: R4.IServiceRequest = serviceRequest[
                key1
              ] as R4.IServiceRequest

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]

                if (ref) {
                  if (currentEncounter.id === ref) {
                    const categoryCode = currentObServation.category
                      ? currentObServation.category[0].coding
                        ? currentObServation.category[0].coding
                          ? currentObServation.category[0].coding[0].code ?? ''
                          : ''
                        : ''
                      : ''
                    if (
                      //   categoryCode === '225365006' ||
                      categoryCode === '304' ||
                      categoryCode === '305'
                    ) {
                      const dateTime = currentObServation.occurrencePeriod
                        ? currentObServation.occurrencePeriod.start ?? ''
                        : ''

                      const endDateTime = currentObServation.occurrencePeriod
                        ? currentObServation.occurrencePeriod.end ?? ''
                        : ''

                      const date1 = new Date(dateTime)
                      const end = new Date(endDateTime)
                      const date2 = date1
                        .toLocaleDateString()
                        .replace(/\//g, '-')

                      const currentProcedure: R4.IProcedure | undefined =
                        getProcedureData(procedures, currentObServation.id!)

                      const dateTime1 = currentProcedure?.performedPeriod
                        ? currentProcedure.performedPeriod.start ?? ''
                        : ''

                      const endDateTime1 = currentProcedure?.performedPeriod
                        ? currentProcedure.performedPeriod.end ?? ''
                        : ''

                      const date3 = new Date(dateTime1)
                      const end2 = new Date(endDateTime1)
                      if (currentObServation.status !== 'unknown') {
                        planData.push({
                          date: currentObServation.occurrencePeriod
                            ? currentObServation.occurrencePeriod.start ?? ''
                            : '',
                          endData: currentObServation.occurrencePeriod
                            ? currentObServation.occurrencePeriod.end ?? ''
                            : '',
                          startTime: moment(date1).format('hh:mm A'),
                          endTime: moment(end).format('hh:mm A'),
                          actualStart: moment(date3).isValid()
                            ? moment(date3).format('hh:mm A')
                            : '',
                          actualEnd: moment(end2).isValid()
                            ? moment(end2).format('hh:mm A')
                            : '',
                          therapy: currentObServation.code
                            ? currentObServation.code.coding
                              ? currentObServation.code.coding[0].display ?? ''
                              : ''
                            : '',
                          treatmentType: currentObServation.code
                            ? currentObServation.code.text ?? ''
                            : '',
                          treatmentPlan: currentObServation,
                          procedure: currentProcedure,
                          status: currentProcedure
                            ? 'Completed'
                            : currentObServation.status === 'revoked'
                            ? 'Finished'
                            : 'Active',
                          morningTherapy: [],
                          midMorningTherapy: [],
                          afterNoonTherapy: [],
                          eveingTherapy: [],
                        })
                      }

                      //   }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  if (splitView.length === 0) {
    planData.sort((a, b) =>
      (a.date ? a.date : '') < (b.date ? b.date : '')
        ? -1
        : (a.date ?? '') > (b.date ?? '')
        ? 1
        : 0
    )
  } else {
    planData.sort((a, b) =>
      (a.date ? a.date : '') < (b.date ? b.date : '')
        ? -1
        : (a.date ?? '') > (b.date ?? '')
        ? 1
        : 0
    )
  }

  return planData
}

export function getTherapiesPlanFromBundle(
  responseBundle: R4.IBundle
): TreatmentPlanData[] {
  const planData: TreatmentPlanData[] = []
  const serviceRequest: any = {}
  const encounters: any = {}
  const procedures: any = {}
  const observation: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                case 'Procedure':
                  procedures[value.resource.id] =
                    value.resource as R4.IProcedure
                  break
                case 'ServiceRequest':
                  serviceRequest[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in serviceRequest) {
              const currentObServation: R4.IServiceRequest = serviceRequest[
                key1
              ] as R4.IServiceRequest

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]

                if (ref) {
                  if (currentEncounter.id === ref) {
                    const categoryCode = currentObServation.category
                      ? currentObServation.category[0].coding
                        ? currentObServation.category[0].coding
                          ? currentObServation.category[0].coding[0].code ?? ''
                          : ''
                        : ''
                      : ''
                    if (categoryCode === '304' || categoryCode === '305') {
                      const dateTime = currentObServation.occurrencePeriod
                        ? currentObServation.occurrencePeriod.start ?? ''
                        : ''

                      const endDateTime = currentObServation.occurrencePeriod
                        ? currentObServation.occurrencePeriod.end ?? ''
                        : ''

                      const date1 = new Date(dateTime)
                      const end = new Date(endDateTime)
                      const date2 = date1
                        .toLocaleDateString()
                        .replace(/\//g, '-')

                      const currentProcedure: R4.IProcedure | undefined =
                        getProcedureData(procedures, currentObServation.id!)

                      const dateTime1 = currentProcedure?.performedPeriod
                        ? currentProcedure.performedPeriod.start ?? ''
                        : ''

                      const endDateTime1 = currentProcedure?.performedPeriod
                        ? currentProcedure.performedPeriod.end ?? ''
                        : ''

                      const date3 = new Date(dateTime1)
                      const end2 = new Date(endDateTime1)
                      //   if (!isTherapist()) {
                      planData.push({
                        date: currentObServation.occurrencePeriod
                          ? currentObServation.occurrencePeriod.start ?? ''
                          : '',
                        endData: currentObServation.occurrencePeriod
                          ? currentObServation.occurrencePeriod.end ?? ''
                          : '',
                        startTime: moment(date1).format('hh:mm A'),
                        endTime: moment(end).format('hh:mm A'),
                        actualStart: moment(date3).isValid()
                          ? moment(date3).format('hh:mm A')
                          : '',
                        actualEnd: moment(end2).isValid()
                          ? moment(end2).format('hh:mm A')
                          : '',
                        therapy: currentObServation.code
                          ? currentObServation.code.coding
                            ? currentObServation.code.coding[0].display ?? ''
                            : ''
                          : '',
                        treatmentPlan: currentObServation,
                        procedure: currentProcedure,
                        status: currentProcedure
                          ? 'Completed'
                          : currentObServation.status === 'revoked'
                          ? 'Finished'
                          : 'Active',
                        morningTherapy: [],
                        midMorningTherapy: [],
                        afterNoonTherapy: [],
                        eveingTherapy: [],
                      })
                      //   }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return planData
}

export function getProcedureData(
  procedures: any,
  id: string
): R4.IProcedure | undefined {
  for (const key3 in procedures) {
    const currentProcedure: R4.IProcedure = procedures[key3] as R4.IProcedure

    const childServiceReq = currentProcedure.basedOn
      ? currentProcedure.basedOn.length > 0
        ? currentProcedure.basedOn[1].reference
          ? currentProcedure.basedOn[1].reference.split('/')[1]
          : ''
        : ''
      : ''
    if (childServiceReq === id) {
      return currentProcedure
    }
  }
  return undefined
}

export function isPastDate(date: string): boolean {
  const todayDate = moment(
    moment(new Date()).add(2, 'days').format('DD-MM-YYYY'),
    "'DD-MM-YYYY'"
  )
  const pastDate = moment(date, 'DD-MM-YYYY')
  const diff = todayDate.diff(pastDate, 'days')
  if (
    diff === 0 ||
    diff === 1 ||
    diff === 2 ||
    diff === 3 ||
    diff === 4 ||
    diff === 5 ||
    diff === 6 ||
    diff === 7
  ) {
    return false
  }

  return true
}

export function isPastDateForTherapy(date: string): boolean {
  const endDateForCheck = moment(moment().format('YYYY-MM-DD'))
  const duration = moment.duration(endDateForCheck.diff(moment(date)))
  const days = duration.asDays()
  const todayDate = moment(
    moment(new Date()).add(2, 'days').format('DD-MM-YYYY'),
    "'DD-MM-YYYY'"
  )

  const pastDate = moment(date, 'DD-MM-YYYY')
  const diff = todayDate.diff(pastDate, 'days')
  if (
    days === 0 ||
    days === 1 ||
    days === 2 ||
    days === 3 ||
    days === 4 ||
    days === 5
  ) {
    return true
  }

  return false
}

export function isCurrentAnd2mrwDate(date: string): boolean {
  const todayDate = moment(
    moment(new Date()).format('DD-MM-YYYY'),
    "'DD-MM-YYYY'"
  )
  const pastDate = moment(date, 'DD-MM-YYYY')
  const diff = todayDate.diff(pastDate, 'days')

  if (diff === 0 || diff === -1) {
    return false
  }

  return true
}

export function dateCompare(date: string, compareDate: string): boolean {
  const oneDate = moment(moment(compareDate), 'DD-MM-YYYY')
  const dateOne = moment([
    moment(date).format('YYYY'),
    moment(date).format('MM'),
    moment(date).format('DD'),
  ])

  const dateTwo = moment([
    moment(oneDate).format('YYYY'),
    moment(oneDate).format('MM'),
    moment(oneDate).format('DD'),
  ])
  const diff = dateOne.diff(dateTwo, 'days')
  if (diff === 0) {
    return true
  }

  return false
}

export function isCurrentDate(date: string): boolean {
  const todayDate = moment(
    moment(new Date()).format('DD-MM-YYYY'),
    "'DD-MM-YYYY'"
  )
  const pastDate = moment(date).toISOString()

  return todayDate.isSame(pastDate)
}

export function isTimeAfter(date: string): boolean {
  const todayDate = moment(
    moment(new Date()).format('DD-MM-YYYY'),
    "'DD-MM-YYYY'"
  )
  const pastDate = moment(date).toISOString()

  return todayDate.isSame(pastDate)
}

export function isFutureDateTime(date: string): boolean {
  const todayDate = moment(
    moment(new Date()).format('DD-MM-YYYY'),
    "'DD-MM-YYYY'"
  )
  const pastDate = moment(date, 'DD-MM-YYYY')

  if (todayDate.isBefore(pastDate)) {
    return true
  }

  return false
}

export function getNotes(procedure: R4.IProcedure): string {
  const notes: string = procedure.note ? procedure.note[0].text ?? '' : ''
  return notes
}

export function getNotesFromTherapy(procedure: R4.IServiceRequest): string {
  const notes: string = procedure.note ? procedure.note[0].text ?? '' : ''
  return notes
}

export function getNotesData(notesData: string): string {
  const notes: string = notesData
  return notes
}

export function getOrderStatusText(treatmentPlan: R4.IServiceRequest): string {
  const statusName: string = ''
  switch (treatmentPlan.status) {
    case 'active':
      return 'Scheduled'
    case 'revoked':
      return 'Cancelled'
    case 'unknown':
      return 'Deleted'

    default:
      break
  }
  return statusName
}
export function isFutureDate(date: string): boolean {
  const todayDate = moment(
    moment(new Date()).format('DD-MM-YYYY'),
    "'DD-MM-YYYY'"
  )
  const future = moment(date, 'DD-MM-YYYY')

  const dDiff = todayDate.diff(future, 'days')

  if (dDiff > 0) {
    return true
  }
  return false
}

export function isCurrentDateForTherapy(date: string): number {
  const endDateForCheck = moment(moment().format('YYYY-MM-DD'))
  const duration = moment.duration(endDateForCheck.diff(moment(date)))
  const days = duration.asDays()
  return days
}

export function isFutureDateForComplete(date: string): boolean {
  const todayDate = moment().format('YYYY-MM-DD')

  if (date <= todayDate) {
    return true
  }
  return false
}

export function isFutureDateForCancel(date: string): boolean {
  const todayDate = moment(
    moment(new Date()).format('DD-MM-YYYY'),
    "'DD-MM-YYYY'"
  )
  const dateData = moment(date).format('DD-MM-YYYY')
  const future = moment(date, 'DD-MM-YYYY')
  const dDiff = future.diff(todayDate, 'days')
  if (dDiff >= 0) {
    return true
  }
  return false
}

// export function isSameDateTIme(date: string): boolean {
//   const todayDate = moment(
//     moment(new Date()).format('DD-MM-YYYY'),
//     "'DD-MM-YYYY'"
//   )
//   const dateData = moment(date).format('DD-MM-YYYY')
//   const future = moment(dateData, 'DD-MM-YYYY')

//   const dDiff = future.diff(todayDate, 'days'

//   return false
// }

export function isBefore(startTime: string, endTime: string): boolean {
  const todayDaysTime = moment(moment().format('HH:mm:ss'), 'HH:mm:ss')
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const date = moment(endTime, 'h:mm:ss A').format('HH:mm:ss')
  const endData = moment(date, 'HH:mm:ss')
  return endData.isAfter(timeAndDate)
}

export function isBeforeTime(startTime: string): boolean {
  const currentTIme = moment(moment(new Date()).toISOString())
  const laterMomentTime = moment('08:00', 'HH:mm')
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)

  return currentTIme.isBefore(timeAndDate)
}

export function isAfterSame(startTime: string, endTime: string): boolean {
  const currentTIme = moment(moment(new Date()).toISOString())
  const laterMomentTime = moment('08:00', 'HH:mm')
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)

  return currentTIme.isSameOrAfter(timeAndDate)
}

export function isAfter(startTime: string): boolean {
  const currentTIme = moment(moment(new Date()).toISOString())
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  return currentTIme.isSameOrAfter(timeAndDate)
}

export function isAfterTherapyFinal(startTime: string, date: string): boolean {
  const endDateForCheck = moment(moment().format('YYYY-MM-DD'))
  const duration = moment.duration(endDateForCheck.diff(moment(date)))
  const days = duration.asDays()
  if (days === 0) {
    const currentTIme = moment(moment(new Date()).toISOString())
    const currentDate = moment(new Date()).format('YYYY-MM-DD')
    const timeAndDate = moment(`${currentDate} ${startTime}`)
    return currentTIme.isSameOrAfter(timeAndDate)
  }
  return true
}

export function isAfterVital(startTime: string): boolean {
  const currentTIme = moment(moment(new Date()).toISOString())
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  return currentTIme.isSameOrBefore(timeAndDate)
}

export function isBeforeFinal(startTime: string): boolean {
  const currentTIme = moment(moment(new Date()).toISOString())
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  return currentTIme.isSameOrBefore(timeAndDate)
}

export function isSameOrBefore(startTime: string, endTime: string): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const currentTIme = moment(`${currentDate} ${endTime}`)

  return currentTIme.isSameOrBefore(timeAndDate)
}

export function isBeforeTherapy(
  startTime: string,
  starttimeMain: string,
  endTimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  const currentEndTime = moment(`${currentDate} ${endTimeMain}`)
  return timeAndDate.isBetween(currentStartTime, currentEndTime)
}

export function isTherapybetweenForMorning(
  startTime: string,
  starttimeMain: string,
  endTimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  const currentEndTime = moment(`${currentDate} ${endTimeMain}`)

  if (
    timeAndDate.isSameOrAfter(currentStartTime) &&
    timeAndDate.isSameOrBefore(currentEndTime)
  )
    return true
  return false
}

export function isTherapybetweenForMidMoring(
  startTime: string,
  starttimeMain: string,
  endTimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  const currentEndTime = moment(`${currentDate} ${endTimeMain}`)
  if (
    timeAndDate.isSameOrAfter(currentStartTime) &&
    timeAndDate.isSameOrBefore(currentEndTime)
  )
    return true
  return false
}

export function isTherapybetweenForAfterNoon(
  startTime: string,
  starttimeMain: string,
  endTimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  const currentEndTime = moment(`${currentDate} ${endTimeMain}`)

  if (
    timeAndDate.isSameOrAfter(currentStartTime) &&
    timeAndDate.isSameOrBefore(currentEndTime)
  )
    return true
  return false
}

export function isTherapybetweenForEvening(
  startTime: string,
  starttimeMain: string,
  endTimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  const currentEndTime = moment(`${currentDate} ${endTimeMain}`)

  if (
    timeAndDate.isSameOrAfter(currentStartTime) &&
    timeAndDate.isSameOrBefore(currentEndTime)
  )
    return true
  return false
}

export function isSameTherapy(
  startTime: string,
  starttimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  return timeAndDate.isSame(currentStartTime)
}

export function isSameTherapyEnd(startTime: string, endMain: string): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const currentStartTime = moment(`${currentDate} ${endMain}`)

  return timeAndDate.isSame(currentStartTime)
}

export function inBetWeenTime(
  startTime: string,
  starttimeMain: string,
  endTimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  const currentEndTime = moment(`${currentDate} ${endTimeMain}`)

  if (timeAndDate.isBetween(currentStartTime, currentEndTime)) return true
  return false
}

export function isSameOrBeforeAdd(
  startTime: string,
  endTime: string,
  starttimeMain: string,
  endTimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const timeAndDateEnd = moment(`${currentDate} ${endTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  const currentEndTime = moment(`${currentDate} ${endTimeMain}`)

  if (
    timeAndDate.isBefore(currentStartTime) &&
    timeAndDateEnd.isAfter(currentEndTime)
  )
    return true
  return false
}

export function isSameOrBeforeAddEveing(
  startTime: string,
  endTime: string,
  starttimeMain: string,
  endTimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const timeAndDateEnd = moment(`${currentDate} ${endTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  const currentEndTime = moment(`${currentDate} ${endTimeMain}`)

  if (
    timeAndDate.isBefore(currentStartTime) &&
    timeAndDateEnd.isAfter(currentEndTime)
  )
    return true
  return false
}

export function isSameOrBeforeBetweenAdd(
  startTime: string,
  endTime: string,
  starttimeMain: string,
  endTimeMain: string
): boolean {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  const timeAndDate = moment(`${currentDate} ${startTime}`)
  const timeAndDateEnd = moment(`${currentDate} ${endTime}`)
  const currentStartTime = moment(`${currentDate} ${starttimeMain}`)
  const currentEndTime = moment(`${currentDate} ${endTimeMain}`)

  if (
    timeAndDate.isBefore(currentStartTime) &&
    timeAndDateEnd.isBetween(currentStartTime, currentEndTime)
  )
    return true
  return false
}

export function getStatusOfTherapy(sr: R4.IServiceRequest) {
  switch (sr.status) {
    case 'revoked':
      return 'Cancelled'
    case 'completed':
      return 'Completed'

    case 'active':
      return 'Scheduled'

    default:
      return ''
  }
}

export async function getAppointmentDetails(
  serviceRequest: R4.IServiceRequest
): Promise<FhirActiveIPDDetailsForMedicalRole | FHIRErrorResponses> {
  const fhirClient: WelloClinicAPIClient = new WelloClinicAPIClient()

  const response: any = await fhirClient.doGetRequest(
    `/ipd/${serviceRequest.id}`,
    {}
  )
  if (response.type === 'FHIRErrorResponses') {
    return response
  }
  const bundleRes: R4.IBundle = response as R4.IBundle
  if (bundleRes.entry && bundleRes.entry.length > 0) {
    const fhirAppointments: FhirActiveIPDDetailsForMedicalRole[] =
      getExpandedIPDAppointmentFromBundle(response)
    if (fhirAppointments && fhirAppointments.length > 0) {
      return fhirAppointments[0]
    }
  }

  return {
    status: 400,
    data: {},
    displayText: 'Invoice is not available',
  }
}
