import { R4 } from '@ahryman40k/ts-fhir-types'
import { FhirAppointmentFullDetail } from './fhirAppointmentFullDetail'
import { FhirLabOrderFullDetail } from './fhirLabOrderFullDetails'

/* eslint-disable @typescript-eslint/comma-dangle */
export enum MedicalEventType {
  DOCTOR_VISIT = 'doctor_visit',
  LAB_REPORT = 'lab_report',
  DOCUMENT_REFERENCE = 'document_reference',
  OTHER = 'other',
}

export interface MedicalEvent {
  id: string
  eventType: MedicalEventType
  startTime: Date
  endTime?: Date
  performerProfilePic?: string
  performerName?: string
  performerSpecialization?: string
  summary?: string
  secondaryTitle?: string
  secondaryContent?: string[]
  tileText?: String
  eventDetails:
    | FhirAppointmentFullDetail
    | FhirLabOrderFullDetail
    | R4.IDocumentReference
}
