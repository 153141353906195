import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { GroupedOccupation } from 'models/groupedOccupations'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getEmailOfPatient,
  getIdentifierValueBySystem,
} from 'utils/fhirResourcesHelper'
import { getLatestObservations } from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { getPatientIdentifiersForSearch } from 'utils/fhirResoureHelpers/patientHelpers'
import { logger } from 'utils/logger'
import { AppetiteSearchStatus } from './appetiteSearchStatus'

const initialState: AppetiteSearchStatus = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingProcedures: false,
}

const appetiteSearchSlice = createSlice({
  name: 'appetiteSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<AppetiteSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.dietList = action.payload.dietList
      state.groupedObs = action.payload.groupedObs
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
    },
  },
})

export const requestAppetiteHistoryOfPatient =
  (
    appointmentId: string,
    selectedPatient: R4.IPatient,
    split: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: AppetiteSearchStatus = {
      searchingConditions: true,
      errorWhileSearchingProcedures: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(appetiteSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        category: 'social-history',
        _count: 500,
      }

      if (getPatientIdentifiersForSearch(selectedPatient).length > 0) {
        searchParameters['patient:Patient.identifier'] =
          getPatientIdentifiersForSearch(selectedPatient)
      }

      const date = `${moment(
        moment(moment(moment()).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      ).toISOString()}`

      const endDate = `${moment(
        moment(moment(moment()).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      )
        .subtract(90, 'days')
        .toISOString()}`
      let response: any
      if (split) {
        response = await fhirClient.doGetResourceForAppointmentCrossSearch(
          `/Observation?date=gt${endDate}`,
          appointmentId,
          searchParameters
        )
      } else {
        response = await fhirClient.doGetResourceForAppointmentCrossSearch(
          '/Observation',
          appointmentId,
          searchParameters
        )
      }
      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)

      const proceduresResponse: R4.IBundle = response

      if (proceduresResponse?.total && proceduresResponse?.total > 0) {
        const observations: R4.IObservation[] =
          proceduresResponse.entry?.map(
            (item) => item.resource as R4.IObservation
          ) ?? []

        const finalObservations: R4.IObservation[] = []

        for (let i = 0; i < observations.length; i++) {
          const codeData = observations[i].code
            ? observations[i].code.coding![0].code ?? ''
            : ''

          if (codeData === '33911006') {
            finalObservations.push(observations[i])
          }
        }
        if (finalObservations.length > 0) {
          finalObservations.sort((a, b) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )
          const latestObs: R4.IObservation[] =
            getLatestObservations(finalObservations)
          const singleObs: R4.IObservation[] = [latestObs[0]]

          let groupedOccupationData: GroupedOccupation[] = []
          const finalGroupedOccupationData: GroupedOccupation[] = []

          for (let i = 0; i < finalObservations.length; i++) {
            if (finalObservations[i].issued) {
              if (groupedOccupationData.length > 0) {
                for (let j = 0; j < groupedOccupationData.length; j++) {
                  if (
                    moment(finalObservations[i].issued).format('YYYY-MM-DD') ===
                    moment(groupedOccupationData[j].date).format('YYYY-MM-DD')
                  ) {
                    groupedOccupationData[j].occupation.push(
                      finalObservations[i]
                    )
                  } else {
                    groupedOccupationData.push({
                      date: finalObservations[i].issued ?? '',
                      occupation: [finalObservations[i]],
                    })
                    break
                  }
                }
              } else {
                groupedOccupationData.push({
                  date: finalObservations[i].issued ?? '',
                  occupation: [finalObservations[i]],
                })
              }
            }
          }
          groupedOccupationData = groupedOccupationData.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  moment(t.date).format('YYYY-MM-DD') ===
                  moment(value.date).format('YYYY-MM-DD')
              )
          )

          for (let i = 0; i < groupedOccupationData.length; i++) {
            finalGroupedOccupationData.push({
              date: groupedOccupationData[i].date,
              occupation: groupedOccupationData[i].occupation.filter(
                (value, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      getDefaultCodeOfSystemFromCodableConcept(t.code) ===
                      getDefaultCodeOfSystemFromCodableConcept(value.code)
                  )
              ),
            })
          }

          state.resultsAvailable = true
          state.searchingConditions = false
          state.dietList = finalObservations
          state.groupedObs = finalGroupedOccupationData
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.errorWhileSearchingProcedures = false
          dispatch(appetiteSearchSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: AppetiteSearchStatus = {
            searchingConditions: false,
            errorWhileSearchingProcedures: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(appetiteSearchSlice.actions.updatedStatus(errorSearchDoctor))
        }
      } else {
        const errorSearchDoctor: AppetiteSearchStatus = {
          searchingConditions: false,
          errorWhileSearchingProcedures: false,
          resultsAvailable: false,
          noResultsAvailable: true,
        }
        dispatch(appetiteSearchSlice.actions.updatedStatus(errorSearchDoctor))
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: AppetiteSearchStatus = {
        searchingConditions: false,
        errorWhileSearchingProcedures: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
      }
      dispatch(appetiteSearchSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default appetiteSearchSlice.reducer
