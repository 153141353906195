import { Box, CircularProgress, Typography } from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getContentOfResource } from 'services/fileServices'
import { SelectedFile, WelloFilePicker } from '../LeftMenu/WelloFilePicker'

export interface AttachmentImageViewer {
  imagePath: string
  resourceId: string
  decodingRequired: boolean
  resourceType: string
  contentType: string
  onSelectionChanged: (selectedFile: SelectedFile) => void
  update: boolean
  purpose: string
  prescription?: boolean
  name?: string
}

export const LogoViewer: React.FC<AttachmentImageViewer> = ({
  imagePath,
  resourceId,
  decodingRequired,
  resourceType,
  contentType,
  onSelectionChanged,
  update,
  purpose,
  prescription,
  name,
}) => {
  const { t } = useTranslation()
  const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: true,
  })
  const [imgSrc, setImgSrc] = useState<string>()

  function fetchInvoice() {
    setFetchingStatus({
      requesting: true,
    })

    getContentOfResource(
      resourceType,
      '',
      resourceId,
      contentType,
      imagePath,
      decodingRequired
    )
      .then((src) => {
        setImgSrc(src)
        setFetchingStatus({
          requesting: false,
          requestSuccess: true,
        })
      })
      .catch((e) => {
        setFetchingStatus({
          requesting: false,
          requestError: true,
        })
      })
  }

  useEffect(() => {
    fetchInvoice()
  }, [])

  return (
    <>
      {fetchingStatus.requesting && <CircularProgress size={20} />}

      {imgSrc === undefined &&
        fetchingStatus.requesting === false &&
        prescription === undefined && (
          <Box py={1}>
            <WelloFilePicker
              fileTypeToPick='document'
              id='unit_actor_profile_pic'
              labelOrientation='column'
              name='logo'
              labelName={name ?? 'Add Logo'}
              allowUpdate={true}
              displaySelectedPhoto='square'
              inputProps={{}}
              onFileChanged={(selectedField: SelectedFile) => {
                if (selectedField && selectedField.path) {
                  setImgSrc(selectedField.path as string)
                }
                if (selectedField && selectedField.path) {
                  onSelectionChanged(selectedField)
                }
              }}
            />
          </Box>
        )}
      {fetchingStatus.requestSuccess && imgSrc && (
        <WelloFilePicker
          fileTypeToPick='document'
          id={`unit_actor_profile_pic${purpose}`}
          labelOrientation='column'
          name='profilePic'
          allowUpdate={update}
          displaySelectedPhoto='square'
          preSelectedFile={{
            type: contentType.includes('pdf') ? 'document' : 'image',
            path: imgSrc,
            name: contentType.includes('pdf') ? 'pdf' : 'image',
          }}
          inputProps={{}}
          onFileChanged={(selectedField) => {
            if (selectedField && selectedField.path) {
              setImgSrc(selectedField.path as string)
            }
            if (selectedField && selectedField.path) {
              onSelectionChanged(selectedField)
            }
          }}
          required={false}
          prescription={prescription}
        />
      )}
    </>
  )
}
