/* eslint-disable no-void */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { Button, Grid, Box, Typography } from '@material-ui/core'
import {
  cartOrderForCachedState,
  cartPatientObjectState,
} from 'models/medicine-delivery/cart/cart-states'
import { Address } from '../../../models/medicine-delivery/cart/cart-models'
import { getAddresses } from '../../../utils/medicineHelper/cart-apis'
import { CartAddress } from './cart-address'
import {
  addressState,
  viewState,
} from '../../../models/medicine-delivery/product-states'

export interface IUnitAdmin {
  patientId: string
}

export const CartAddresses: React.FC<IUnitAdmin> = ({
  patientId,
}: IUnitAdmin) => {
  const [view, setView] = useRecoilState(viewState)
  const [addresses, setAddresses] = useState<Address[] | undefined>([])
  const [orderFor, setOrderFor] = useRecoilState(cartOrderForCachedState)
  const [isReady, setIsReady] = useState(false)
  const [cartPatient, setCartPatient] = useRecoilState(cartPatientObjectState)
  const [cartAddress, setCartAddress] = useRecoilState(addressState)

  useEffect(() => {
    if (view !== 'address') {
      setAddresses([])
      setIsReady(false)
      return
    }
    void (async () => {
      if (orderFor === 'patient' && cartPatient) {
        const addresses = await getAddresses(cartPatient.id ?? '', orderFor)
        setCartAddress(addresses ?? [])
        setIsReady(true)
      } else {
        const addresses = await getAddresses('', orderFor)
        setCartAddress(addresses ?? [])
        setIsReady(true)
      }

      // const data = await getAddressesSample();
    })()
  }, [view])

  if (view !== 'address' || !cartAddress || cartAddress.length === 0) {
    return <></>
  }

  if (!isReady) {
    return <>Fetching addresses</>
  }

  return (
    <Box
      width='99%'
      height='100%'
      style={{ backgroundColor: 'white' }}
      display='flex'
      flexDirection='column'
      px={1}
    >
      <Box p={1} width='100%' display='flex' flexDirection='column'>
        <Typography variant='h6'>Address</Typography>
      </Box>
      {cartAddress.length > 0 && (
        <Box width='100%' display='flex' flexDirection='row'>
          {cartAddress.map((a) => (
            <Box
              key={a.type}
              width={a.type === 'shipping' ? '50%' : '50%'}
              display='flex'
              flexDirection='row'
            >
              <CartAddress address={a} />
            </Box>
          ))}
        </Box>
      )}
      <Box width='100%' display='flex' flexDirection='row' py={2}>
        <Box
          width='50%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
          paddingLeft={2}
        >
          <Button
            variant='contained'
            color='primary'
            onClick={() => setView('cart')}
          >
            Back
          </Button>
        </Box>
        <Box
          width='50%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
          paddingLeft={1}
        >
          <Button
            variant='contained'
            color='primary'
            onClick={() => setView('cartSummary')}
          >
            Proceed
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
