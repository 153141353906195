import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import {
  kBackgroundDefault,
  kBackgroundPaper,
  kPrimaryDark,
} from 'configs/styles/muiThemes'
import { CareTeamMemberDetail } from 'models/administration/CareTeamMemberDetails'
import { CPGForTable } from 'models/administration/cpgDetailsForTable'
import { CrossOrgEnabledPractitioner } from 'models/practRoleResponse'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AvailableMembersForCareTeam } from './avialableMembersForCareTeam'
import { ExistingCareTeamList } from './standByCareTeamOfCarePlan'

interface TabDetail {
  code: string
  label: string
  enabled: boolean
}

export interface CareTeamOfCarePlanPopUpProps {
  open: boolean
  planName?: string
  planDetails?: CPGForTable
  onClose?: () => void
}
export const CareTeamOfCarePlanPopUp: React.FC<CareTeamOfCarePlanPopUpProps> =
  ({ open, planName, planDetails, onClose }) => {
    const { t } = useTranslation(['common'])

    const defaultTabs = [
      {
        code: 'care-team',
        label: 'Care Team',
        enabled: true,
      },
      {
        code: 'add-care-team-member',
        label: 'Add Care Team Member',
        enabled: false,
      },
    ]

    const [tabs, setTabs] = useState<TabDetail[]>(defaultTabs)
    const [selectedTab, setSelectedTab] = useState<string>(defaultTabs[0].code)
    const [group, setGroup] = useState<R4.IGroup | undefined>()
    const [originalData, setOriginalData] = useState<CareTeamMemberDetail[]>([])

    return (
      <Dialog
        open={open}
        onClose={() => {
          if (onClose) onClose()
        }}
        aria-labelledby='responsive-dialog-title'
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle id='scroll-dialog-title'>
          {`Care Team of ${planName}`}
        </DialogTitle>
        <DialogContent
          dividers={true}
          style={{
            backgroundColor: '#ededed',
            padding: 0,
            overflowWrap: 'normal',
            overscrollBehaviorX: 'none',
            margin: 0,
            minHeight: '300px',
          }}
        >
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            height='100%'
            minHeight='300px'
            px={2}
            bgcolor={kBackgroundPaper}
          >
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              pt={1}
              borderBottom={1}
              borderColor={kBackgroundDefault}
            >
              {tabs.map((tab) => (
                <Box
                  key={tab.code}
                  display='flex'
                  flexDirection='row'
                  height='25px'
                  pr={2}
                  style={{
                    cursor: tab.enabled ? 'pointer' : 'not-allowed',
                  }}
                  onClick={() => {
                    if (tab.enabled) {
                      setSelectedTab(tab.code)
                    }
                  }}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='space-between'
                    alignContent='center'
                    alignItems='center'
                    width='100%'
                    justifyItems='center'
                  >
                    <Typography
                      variant='subtitle2'
                      color={selectedTab === tab.code ? 'primary' : 'initial'}
                      style={{
                        textTransform: 'capitalize',
                      }}
                    >
                      {tab.label}
                    </Typography>
                    <Box
                      width='25px'
                      height='2px'
                      style={{
                        borderRadius: '2px',

                        backgroundColor:
                          tab.code === selectedTab
                            ? kPrimaryDark
                            : 'transparent',
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>

            {selectedTab === 'care-team' && (
              <Box
                display='flex'
                flexDirection='column'
                flexGrow={1}
                width='100%'
                height='100%'
              >
                <ExistingCareTeamList
                  medicationsList={planDetails!}
                  onMembersFetched={(incomingGroup, membersList) => {
                    if (
                      incomingGroup !== false &&
                      incomingGroup !== undefined
                    ) {
                      setTabs([
                        {
                          code: 'care-team',
                          label: 'Care Team',
                          enabled: true,
                        },
                        {
                          code: 'add-care-team-member',
                          label: 'Add Care Team Member',
                          enabled: true,
                        },
                      ])
                      setGroup(incomingGroup)
                      setOriginalData(membersList ?? [])
                    }
                  }}
                  onClear={() => {
                    if (onClose) onClose()
                  }}
                />
              </Box>
            )}
            {selectedTab === 'add-care-team-member' && group && (
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                height='100%'
              >
                <AvailableMembersForCareTeam
                  group={group}
                  existingMembers={originalData}
                  onClear={() => {
                    if (onClose) onClose()
                  }}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    )
  }
