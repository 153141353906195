import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { cancelTokenStore, FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitReference,
  isTherapist,
} from 'services/userDetailsService'
import { getGenderOfDoctor } from 'utils/fhirResourcesHelper'
import { getCountForTherapist } from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { logger } from 'utils/logger'
import { IpdCountStatus } from './appointmentCountStatusPerUnit'

const initialState: IpdCountStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
  count: 0,
  opdCount: 0,
  dayCareCount: 0,
}

const appointmentCountPerUnitSlice = createSlice({
  name: 'appointmentCountPerUnitSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<IpdCountStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.count = action.payload.count
      state.dayCareCount = action.payload.dayCareCount
      state.opdCount = action.payload.opdCount
      state.errorReason = action.payload.errorReason

      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const resetAppointmentForUnit =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: IpdCountStatus = {
      searchingAppointments: false,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      opdCount: 0,
      dayCareCount: 0,
      count: 0,
    }
    dispatch(appointmentCountPerUnitSlice.actions.updatedStatus(state))
  }
export const requestForAppointmentCount =
  (unitId: string, unitType: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: IpdCountStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      count: 0,
      dayCareCount: 0,
      opdCount: 0,
    }
    dispatch(appointmentCountPerUnitSlice.actions.updatedStatus(state))

    try {
      const searchParameters: any = {
        _count: 0,
        _total: 'accurate',
      }
      let response: any
      let opdCount: number = 0
      let dayCareCount: number = 0
      const fhirClient: MasterFhirClient = new MasterFhirClient()
      if (unitType === 'clinic') {
        response =
          await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
            `ServiceRequest?code=281685003&performer=${unitId}&status=active,completed`,
            '',
            searchParameters
          )
        opdCount = await getOPD(unitId)
        dayCareCount = await getDayCare(unitId)
      } else {
        response =
          await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
            `ServiceRequest?code=home-sample-collection,onsite-sample-collection&performer=${unitId}`,
            '',
            searchParameters
          )
      }

      const resp: R4.IBundle = response as R4.IBundle
      if (!resp) {
        state.errorWhileSearchingAppointments = true
        state.searchingAppointments = false
        dispatch(appointmentCountPerUnitSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp
        if (appointmentResponse?.total && appointmentResponse?.total > 0) {
          state.resultsAvailable = true
          state.searchingAppointments = false
          state.count = appointmentResponse.total
          state.opdCount = opdCount
          state.dayCareCount = dayCareCount
          state.errorReason = undefined
          state.errorWhileSearchingAppointments = false
          dispatch(appointmentCountPerUnitSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: IpdCountStatus = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            count: 0,
            opdCount: 0,
            dayCareCount: 0,
          }
          dispatch(
            appointmentCountPerUnitSlice.actions.updatedStatus(
              errorSearchDoctor
            )
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: IpdCountStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
        count: 0,
        opdCount: 0,
        dayCareCount: 0,
      }
      dispatch(
        appointmentCountPerUnitSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

async function getOPD(unitId: string): Promise<number> {
  const searchParameters: any = {
    _count: 0,
    _total: 'accurate',
  }
  const fhirClient: MasterFhirClient = new MasterFhirClient()
  const response =
    await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
      `ServiceRequest?code=33022008&performer=${unitId}}&status=active,completed`,
      '',
      searchParameters
    )
  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const appointmentResponse: R4.IBundle = response as R4.IBundle
  if (appointmentResponse.total) {
    if (appointmentResponse.total > 0) {
      return appointmentResponse.total
    }
  }

  return 0
}

async function getDayCare(unitId: string): Promise<number> {
  const searchParameters: any = {
    _count: 0,
    _total: 'accurate',
  }
  const fhirClient: MasterFhirClient = new MasterFhirClient()
  const response =
    await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
      `ServiceRequest?code=304903009&performer=${unitId}}&status=active,completed`,
      '',
      searchParameters
    )
  logger.info('Practitioner Update Role Response')
  logger.info(response)
  const appointmentResponse: R4.IBundle = response as R4.IBundle
  if (appointmentResponse.total) {
    if (appointmentResponse.total > 0) {
      return appointmentResponse.total
    }
  }

  return 0
}

export default appointmentCountPerUnitSlice.reducer
