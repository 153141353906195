import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core'
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@material-ui/icons'
import moment from 'moment'
import React, { useState } from 'react'
import { AddedKriyasList } from '../../assessment/addedKriyasList'
import { KriyaDetail } from './addKriyasForEncounter'
import OverflowTypography from './overflowTypography'

interface Props {
  kriyaDetail: KriyaDetail
  onEditCLicked?: (medication: KriyaDetail) => void
  isReadonly?: boolean
}

export const KriyaDetailTile: React.FC<Props> = ({
  kriyaDetail,
  onEditCLicked,
  isReadonly,
}: Props) => {
  const theme = useTheme()
  const [collapsed, setCollapsed] = useState(true)

  return (
    <Box width='100%'>
      {kriyaDetail.recordedDate && kriyaDetail.recordedDate.length > 0 && (
        <Box
          paddingX={1}
          borderRadius={2}
          display='flex'
          style={{
            backgroundColor: 'lightGrey',
          }}
          flexDirection='row'
          width='100%'
          height={40}
        >
          <Box
            justifyContent='flex-start'
            display='flex'
            flexGrow={1}
            alignItems='center'
          >
            <Typography
              variant='subtitle1'
              style={{
                color: 'black',

                fontWeight: 'bold',
              }}
            >
              {`Recorded on ${moment(kriyaDetail.recordedDate).format(
                'Do MMMM YYYY hh:mm A'
              )}`}
            </Typography>
          </Box>
          {isReadonly === false && (
            <Box
              justifyContent='flex-end'
              display='flex'
              flexGrow={1}
              alignItems='center'
            >
              <Box px={1}>
                <Tooltip title='' id='chief_tool_del_img'>
                  <IconButton
                    aria-label='collapse_order_type'
                    size='small'
                    onClick={() => {
                      setCollapsed(!collapsed)
                    }}
                  >
                    {collapsed && <ArrowDropUpOutlined />}
                    {!collapsed && <ArrowDropDownOutlined />}
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          )}
        </Box>
      )}
      <Collapse
        in={collapsed}
        style={{
          width: '100%',
        }}
      >
        <Box
          style={{
            width: '100%',
            height: 'auto',
          }}
        >
          <AddedKriyasList
            kriyasList={[kriyaDetail]}
            onEditClicked={(medicationRepeat: KriyaDetail) => {
              if (onEditCLicked) onEditCLicked(medicationRepeat)
            }}
            isReadonly={isReadonly}
          />
        </Box>
      </Collapse>
    </Box>
  )
}
