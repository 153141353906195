import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import {
  LabObsData,
  ObsDataTable,
  ObsDataTableResource,
  ObsList,
} from 'models/labObsData'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getObServationDataTable,
  getObServationDisplayData,
} from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { logger } from 'utils/logger'
import { ObservationFinderStatus } from './observationFinderStatus'

const initialState: ObservationFinderStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const observtionSearchSlice = createSlice({
  name: 'observtionSearchSlice',
  initialState,
  reducers: {
    searchingDoctorDetails(
      state,
      action: PayloadAction<ObservationFinderStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.activityDef = undefined
      state.observations = undefined
      state.observationDef = undefined
      state.finalLabObsData = undefined
      state.finalObsList = undefined
    },

    searchResults(state, action: PayloadAction<ObservationFinderStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.activityDef = action.payload.activityDef
      state.observations = action.payload.observations
      state.observationDef = action.payload.observationDef
      state.finalLabObsData = action.payload.finalLabObsData
      state.finalObsList = action.payload.finalObsList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<ObservationFinderStatus>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.activityDef = undefined
      state.observations = undefined
      state.finalLabObsData = undefined
      state.finalObsList = undefined
    },

    errorWhileSearching(state, action: PayloadAction<ObservationFinderStatus>) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.activityDef = undefined
      state.observations = undefined
      state.finalLabObsData = undefined
      state.finalObsList = undefined
    },
    resetState(state, action: PayloadAction<ObservationFinderStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.observations = undefined
      state.activityDef = undefined
      state.finalLabObsData = undefined
      state.finalObsList = undefined
    },
  },
})

export const searchObservations =
  (PlanDefinition?: R4.IPlanDefinition, editPage?: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: ObservationFinderStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(observtionSearchSlice.actions.searchingDoctorDetails(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const activityParmeter: string[] = []

      if (PlanDefinition) {
        if (PlanDefinition.action) {
          const actionData = PlanDefinition.action[0]
          if (actionData.action) {
            for (let i = 0; i < actionData.action.length; i++) {
              if (actionData.action[i].definitionCanonical) {
                const reference = actionData.action[i].definitionCanonical
                if (reference) {
                  const activeId = reference.split(
                    'http://wellopathy.com/ActivityDefinition/'
                  )[1]
                  activityParmeter.push(activeId)
                }
              }
            }
          }
        }
      }
      const searchParameters: any = {
        status: 'active',
        'activitydef-code': activityParmeter.toString(),
      }
      let response: any
      if (editPage === undefined) {
        response = await fhirClient.doGetResourceForMasterData(
          '/ActivityDefinition?_include=ActivityDefinition:observationResultRequirement',
          searchParameters
        )
      } else {
        response = await fhirClient.doGetResource(
          '/ActivityDefinition?_include=ActivityDefinition:observationResultRequirement',
          searchParameters
        )
      }

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const labOfferingsResponse: R4.IBundle = relatedFhirDecodeRes.right
        if (labOfferingsResponse.total) {
          if (labOfferingsResponse.total > 0) {
            if (labOfferingsResponse.entry) {
              const displayList: LabObsData | undefined =
                getObServationDisplayData(labOfferingsResponse)
              const obsData: ObsDataTableResource[] = getObServationDataTable(
                labOfferingsResponse,
                editPage
              )
              obsData.sort((a, b) =>
                a.obsName.toLowerCase() < b.obsName.toLowerCase()
                  ? -1
                  : Number(a.obsName.toLowerCase() > b.obsName.toLowerCase())
              )

              const finalList: ObsDataTableResource[] = []
              for (let i = 0; i < obsData.length; i++) {
                const data = {
                  obsInitial: obsData[i].obsInitial,
                  obsName: obsData[i].obsName,
                  obsCode: obsData[i].obsCode,
                  permittedDataType: obsData[i].permittedDataType,
                  multipleResultsAllowed: obsData[i].multipleResultsAllowed,
                  unitCode: obsData[i].unitCode,
                  decimalPrecision: obsData[i].decimalPrecision,
                  high1: obsData[i].high1,
                  low1: obsData[i].low1,
                  highCode1: obsData[i].highCode1,
                  lowCode1: obsData[i].lowCode1,
                  gender1: obsData[i].gender1,
                  ageHigh1: obsData[i].ageHigh1,
                  ageLow1: obsData[i].ageLow1,
                  ageHighUnit1: obsData[i].ageHighUnit1,
                  ageLowUnit1: obsData[i].ageLowUnit1,

                  high2: obsData[i].high2,
                  low2: obsData[i].low2,
                  highCode2: obsData[i].highCode2,
                  lowCode2: obsData[i].lowCode2,
                  gender2: obsData[i].gender2,
                  ageHigh2: obsData[i].ageHigh2,
                  ageLow2: obsData[i].ageLow2,
                  ageHighUnit2: obsData[i].ageHighUnit2,
                  ageLowUnit2: obsData[i].ageLowUnit2,
                  active: true,
                  id: i,
                  interpretation: obsData[i].interpretation,
                  notes: obsData[i].notes,
                  comment: obsData[i].comment,
                }
                finalList.push(data)
              }

              state.finalLabObsData = displayList
              state.finalObsList = finalList
              state.resultsAvailable = true
              state.searching = false
              state.error = false
              dispatch(observtionSearchSlice.actions.searchResults(state))
              return
            }
            state.resultsAvailable = false
            state.searching = false
            state.error = false
            state.noResultsAvailable = true
            dispatch(observtionSearchSlice.actions.noDataFoundForSearch(state))
            return
          }
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(observtionSearchSlice.actions.noDataFoundForSearch(state))
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(observtionSearchSlice.actions.errorWhileSearching(state))
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(observtionSearchSlice.actions.errorWhileSearching(state))
    }
  }

export const resetObservationState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: ObservationFinderStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      observations: undefined,
    }
    dispatch(observtionSearchSlice.actions.resetState(state))
  }

export function getPractitionerRoleObject(item: R4.IPractitionerRole) {
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    name: item.practitioner?.display ?? '',
    gender: '',
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: false,
  }
  return val
}

export default observtionSearchSlice.reducer
