/* eslint-disable class-methods-use-this */
import { R4 } from '@ahryman40k/ts-fhir-types'
import axios, { AxiosInstance } from 'axios'
import { getAccessToken } from 'utils/authHelpers'
import {
  OrgUserRole,
  LabUserRole,
  ClinicUserRole,
} from 'utils/constants/user_roles'
import { logger } from 'utils/logger'
import specialities from '../assets/constants/specialities.json'
import clinicRoles from '../assets/constants/wellopathy-clinic-roles-vs.json'
import labRoles from '../assets/constants/wellopathy-lab-roles-vs.json'
import masterRoles from '../assets/constants/wellopathy-master-roles-vs.json'
import allopathyRoles from '../assets/constants/wellopathy-clinic-allopathy-roles-vs.json'
import ayurvedaRoles from '../assets/constants/wellopathy-clinic-ayurveda-roles-vs.json'
import wellnessRoles from '../assets/constants/wellopathy-clinic-naturopathy-roles-vs.json'
import commonRoles from '../assets/constants/wellopathy-clinic-common-roles-vs.json'
import {
  isOrgAdmin,
  isCurrentUnitIsWellonessCenter,
  isCurrentUnitIsClinic,
  isCurrentUnitIsLab,
  isCurrentUnitIsCollectionCenter,
} from './userDetailsService'

export class SpecialtiesApiClient {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: 'https://www.hl7.org/fhir/',
    })
    this.axiosInstance.interceptors.request.use((param) => {
      if (getAccessToken() != null) {
        param.headers = {
          Authorization: `Bearer ${getAccessToken()}`,
        }
      }

      return {
        ...param,
      }
    })
    this.axiosInstance.interceptors.response.use((param) => ({
      ...param,
    }))
  }

  public async doGetSpecialties(): Promise<R4.ICoding[] | undefined> {
    try {
      const language = sessionStorage.getItem('lang')
      const codes: R4.ICoding[] = specialities as R4.ICoding[]
      /* let response: AxiosResponse = await this.axiosInstance.get(
        "valueset-c80-practice-codes.json",
        {
          headers: {
            mode: "cors",
          },
        }
      );

      if (response.status > 199 && response.status < 300) {
        if (response.data) {
          if (response.data.compose) {
            if (response.data.compose.include) {
              if (response.data.compose.include.length > 0) {
                if (response.data.compose.include[0].concept) {
                  return response.data.compose.include[0]
                    .concept as R4.ICodeableConcept[];
                }
              }
            }
          }
        }
      } */
      return codes
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetRoles(
    name: string,
    systemOfMedicine: R4.ICoding[]
  ): Promise<R4.ICoding[] | undefined> {
    try {
      let codes: R4.ICoding[] = []

      if (
        (isCurrentUnitIsWellonessCenter() ||
          isCurrentUnitIsClinic() ||
          name === 'clinic' ||
          name === 'wellness_center') &&
        systemOfMedicine.length > 0
      ) {
        codes = commonRoles as R4.ICoding[]
        for (let i = 0; i < systemOfMedicine.length; i++) {
          if (systemOfMedicine[i].code === 'C0242324') {
            const children = codes.concat(allopathyRoles as R4.ICoding[])
            codes = children
          }
          if (systemOfMedicine[i].code === '1259939000') {
            const children = codes.concat(ayurvedaRoles as R4.ICoding[])
            codes = children
          }

          if (systemOfMedicine[i].code === '439809005') {
            const children = codes.concat(wellnessRoles as R4.ICoding[])
            codes = children
          }
        }
      } else if (
        isCurrentUnitIsLab() ||
        isCurrentUnitIsCollectionCenter() ||
        name.trim() === 'lab' ||
        name.trim() === 'collection_center'
      ) {
        codes = labRoles as R4.ICoding[]
      } else {
        codes = masterRoles as R4.ICoding[]
      }

      /* let response: AxiosResponse = await this.axiosInstance.get(
        "valueset-c80-practice-codes.json",
        {
          headers: {
            mode: "cors",
          },
        }
      );

      if (response.status > 199 && response.status < 300) {
        if (response.data) {
          if (response.data.compose) {
            if (response.data.compose.include) {
              if (response.data.compose.include.length > 0) {
                if (response.data.compose.include[0].concept) {
                  return response.data.compose.include[0]
                    .concept as R4.ICodeableConcept[];
                }
              }
            }
          }
        }
      } */

      return codes
    } catch (error) {
      logger.error(error)
      throw error
    }
  }
}
