import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
  withStyles,
  InputAdornment,
  TextField,
  Divider,
} from '@material-ui/core'
import {
  cartDoctorIdState,
  cartOrderForCachedState,
  cartPatientIdState,
  cartPatientObjectState,
  orderForObject,
} from 'models/medicine-delivery/cart/cart-states'

import { ProductDetail } from 'models/medicine-delivery/product-models'
import { viewState } from 'models/medicine-delivery/product-states'

import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  RecoilRoot,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'

import {
  getCurrentUserPractitionerRoleDetailsWithPractitioner,
  isGre,
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import { logger } from 'utils/logger'
import { getVendorPartId } from 'utils/routes_helper'
import { AppointmentGridView } from 'views/components/clinic_home_page/appointmentGridView'
import { AddPatientData } from 'views/components/common/add_patient_data'
import { CartAddresses } from './cart_plan/cart-addresses'
import { CartDetails } from './cart_plan/cart-details'
import { CartSummary } from './cart_plan/cart-order-summary'
import { CartOrderTile } from './cart_plan/cart-order-tile'
import { ProductDetails } from './products_plan/product-details'
import { Products } from './products_plan/products'

export interface IUnitAdmin {
  doctorDetails: R4.IPractitioner
  key: string
  patientDetails?: R4.IPatient
  isFromSoapNotes?: boolean
  medicationNames?: string[]
  onMedicineOrdered?: () => void
}

export const MedicinePage: React.FC<IUnitAdmin> = ({
  doctorDetails,
  key,
  patientDetails,
  isFromSoapNotes,
  medicationNames,
  onMedicineOrdered,
}: IUnitAdmin) => {
  console.log('1')
  const dispatch = useDispatch()

  const [patientId, setPatientId] = useState<string | undefined>(
    patientDetails ? patientDetails.id ?? undefined : undefined
  )
  const [cartPatient, setCartPatient] = useRecoilState(cartPatientObjectState)
  const [orderForData, setOrderForData] = useRecoilState(orderForObject)
  const [view, setView] = useRecoilState(viewState)
  const [orderFor, setOrderFor] = useRecoilState(cartOrderForCachedState)
  const setCartPatientId = useSetRecoilState(cartPatientIdState)

  useEffect(() => {
    if (patientDetails) {
      setCartPatient(patientDetails)
      setOrderFor('patient')
      setCartPatientId(patientDetails.id ?? undefined)
      setOrderForData({
        system: '',
        code: 'patient',
        display: 'Order for Patient',
      })
    }
  }, [])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        borderRadius: 8,

        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box width='100%' style={{ overflow: 'hidden' }}>
            <Box px={2}>
              <ProductDetails
                doctorDetails={doctorDetails}
                onPatientCallBack={(patient: R4.IPatient) => {
                  setPatientId(patient.id ?? '')
                }}
                onAddToCartCallback={(product: ProductDetail) => {
                  dispatch(showSuccessAlert('Added Successfully'))
                }}
                onSkip={() => {}}
                isSoapNotes={isFromSoapNotes}
                medicationNames={medicationNames}
              />
            </Box>
            {view !== 'productDetails' && (
              <Box width='100%' px={2}>
                <CartOrderTile />
              </Box>
            )}
            <Box
              width='100%'
              paddingTop={1}
              display='flex'
              flexDirection='column'
              paddingLeft={2}
              paddingRight={1}
            >
              <CartDetails />

              <CartAddresses patientId={patientId ?? ''} />
              <CartSummary
                onOrderPlaced={() => {
                  if (onMedicineOrdered) onMedicineOrdered()
                }}
              />
            </Box>
          </Box>
        </Box>
      </Paper>
    </div>
  )
}
