/* eslint-disable react-hooks/exhaustive-deps */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import {
  getPaymentUpdate,
  getPaymentUpdateForLabOrder,
} from 'redux/appointments/appointmentViewer/appointmentSearchSlice'
import { fetchOrderDetails } from 'redux/lab/order/orderManagementSlice/orderManagementSlice'
import { RootState } from 'redux/rootReducer'
import { availablePaymentOptions } from 'utils/constants'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { OrderAmountSummaryTile } from 'views/components/generalOrder/ordarAmountSummary'
import { AddressTile } from 'views/components/patients/addressTile'
import { PatientTile } from 'views/components/patients/pateintTile'
import CustomizedDividers from 'views/components/toggle_selector'
import SocketContext from 'views/contexts/socketMessageContext'
import { WelloFormItemLabel } from 'wello-web-components'
import { RestrictionsOfSelectedOfferings } from '../common/restriction_detail'
import { AgentTileForOrderView } from './agentTile'
import { LabOfferingSelector } from './labTestSearch'
import { PaymentTile } from './paymentTile'

interface Props {
  orderId: string
  open: boolean
  onClose: () => void
  onDone: () => void
}
export const ViewPaymentForOrder: React.FC<Props> = ({
  orderId,
  open,
  onClose,
  onDone,
}) => {
  const orderManagementSlice = useSelector(
    (state: RootState) => state.orderManagementSlice
  )

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const [selectedLabOfferings, setSelectedLabOfferings] =
    useState<LabOfferingDetail[]>()

  const [paymentDetails, setPaymentDetails] = useState<FhirLabOrderDetail>()
  useEffect(() => {
    dispatch(fetchOrderDetails(orderId))
  }, [dispatch, orderId])

  const { eventData } = useContext(SocketContext)

  const [paymentStatus, setPaymentStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  function getPaymentStatus(appointmentDetailsData: FhirLabOrderDetail) {
    setPaymentStatus({ requesting: true })

    setLoading(true)

    getPaymentUpdateForLabOrder(appointmentDetailsData)
      .then((e: FhirLabOrderDetail) => {
        setLoading(false)
        if (e) {
          setPaymentDetails(e)
          setPaymentStatus({
            requesting: false,
            requestSuccess: true,
          })

          // dispatch(showSuccessAlert('Medication deleted successfully'))
          // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
        } else {
          setPaymentStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while fetching payment update. Please try again later'
            )
          )
        }
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        setPaymentStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  function requestAppointmentForPolling() {
    if (
      paymentDetails &&
      paymentDetails.paymentReconilation &&
      paymentDetails.paymentReconilation.outcome &&
      paymentDetails.paymentReconilation.outcome !== 'complete'
    ) {
      getPaymentStatus(paymentDetails)
    }
  }

  useEffect(() => {
    const intervalId = setInterval(requestAppointmentForPolling, 10000)
    return () => clearInterval(intervalId)
  }, [requestAppointmentForPolling])

  useEffect(() => {
    if (
      orderManagementSlice.orderDetailsAvailable &&
      orderManagementSlice.orderDetail
    ) {
      setPaymentDetails(orderManagementSlice.orderDetail)
    }
  }, [orderManagementSlice])

  useEffect(() => {
    const convertedData = eventData as any

    if (convertedData !== null) {
      if (
        paymentDetails &&
        paymentDetails.paymentReconilation &&
        paymentDetails.paymentReconilation.outcome &&
        paymentDetails.paymentReconilation.outcome !== 'complete'
      ) {
        getPaymentStatus(paymentDetails)
      }
      if (convertedData.infoMessages && convertedData.infoMessages.length > 0) {
        dispatch(showSuccessAlert(convertedData.infoMessages[0]))
      }
      if (
        convertedData.errorMessages &&
        convertedData.errorMessages.length > 0
      ) {
        dispatch(showErrorAlert(convertedData.errorMessages[0]))
      }
    }
  }, [eventData])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>{t('labelCommon:new_lab_order')}</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
                // resetForm()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        {orderManagementSlice.orderDetailsAvailable &&
          orderManagementSlice.orderDetail && (
            <Box display='flex' flexDirection='row'>
              <Box
                display='flex'
                flexDirection='column'
                flexGrow={4}
                maxWidth={width * 0.65}
              >
                <Box display='flex' flexDirection='row'>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='space-between'
                    >
                      <WelloFormItemLabel title='Selected Patient' />
                    </Box>
                    <Box display='flex' flexDirection='row'>
                      {orderManagementSlice.orderDetail.patient && (
                        <PatientTile
                          patient={orderManagementSlice.orderDetail.patient}
                        />
                      )}
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='column'
                      my={2}
                      width='100%'
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                      >
                        <WelloFormItemLabel title='Selected Test(S) or package(S)' />
                      </Box>

                      <Box display='flex' flexDirection='row' width='100%'>
                        <LabOfferingSelector
                          onSelectionChanges={(e) => {
                            setSelectedLabOfferings(e)
                          }}
                          disabled={true}
                          preSelectedOrdersRoles={
                            orderManagementSlice.orderDetail.tests
                          }
                        />
                      </Box>

                      <Box display='flex' flexDirection='row' width='100%'>
                        <Box display='flex' flexDirection='column' width='100%'>
                          <Box display='flex' flexDirection='row'>
                            <CustomizedDividers
                              size='small'
                              menuLabel={t('labelCommon:order_type')}
                              options={[
                                {
                                  displayText: 'Home Collection',
                                  value: 'home_collection',
                                },
                                {
                                  displayText: 'Onsite Collection',
                                  value: 'onsite-collection',
                                },
                              ]}
                              onSelectionChanged={(selectedValue) => {
                                // setSampleCollectionPoint(selectedValue)
                              }}
                              preSelectedValue={
                                orderManagementSlice.orderDetail.homeServiceTask
                                  ? 'home_collection'
                                  : 'onsite-collection'
                              }
                            />
                          </Box>

                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            paddingTop={2}
                          >
                            {orderManagementSlice.orderDetail.patient
                              .address && (
                              <AddressTile
                                addresses={
                                  orderManagementSlice.orderDetail.patient
                                    .address[0]
                                }
                              />
                            )}
                          </Box>

                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            py={2}
                          >
                            {orderManagementSlice.orderDetail
                              .homeServiceTask && (
                              <AgentTileForOrderView
                                start={orderManagementSlice.orderDetail.start}
                                end={orderManagementSlice.orderDetail.end ?? ''}
                                practitionerDetails={
                                  orderManagementSlice.orderDetail
                                    .performerDetail
                                }
                              />
                            )}
                          </Box>
                          {paymentDetails &&
                            paymentDetails.paymentReconilation && (
                              <Box
                                display='flex'
                                flexDirection='column'
                                my={2}
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  justifyContent='space-between'
                                >
                                  <WelloFormItemLabel title='Payment Option' />
                                </Box>

                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                >
                                  <PaymentTile
                                    start={
                                      orderManagementSlice.orderDetail.start
                                    }
                                    payment={paymentDetails.paymentReconilation}
                                    patient={
                                      orderManagementSlice.orderDetail.patient
                                    }
                                  />
                                </Box>
                              </Box>
                            )}

                          {/* 
                          <Box display='flex' flexDirection='row' width='100%'>
                            <WelloSelectFHIRCoding
                              title={t('payment_options')}
                              availableCodings={availablePaymentOptions}
                              onChanged={(paymentOption) => {
                                setSelectedPaymentOptions(paymentOption)
                              }}
                              preSelectedCoding={availablePaymentOptions[0]}
                              textFieldProps={{
                                size: 'small',
                                fullWidth: true,
                              }}
                            />
                          </Box> */}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                paddingLeft={2}
                minWidth='400px'
                maxWidth='400px'
              >
                <WelloFormItemLabel title='Price Summary' />
                <Box display='flex' flexDirection='row' width='100%'>
                  <OrderAmountSummaryTile
                    planDefinitions={orderManagementSlice.orderDetail.tests}
                  />
                </Box>

                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  paddingTop={6}
                >
                  <RestrictionsOfSelectedOfferings
                    selectedOfferings={
                      orderManagementSlice.orderDetail.tests ?? []
                    }
                  />
                </Box>
              </Box>
            </Box>
          )}
      </DialogContent>
      <DialogActions>
        {orderManagementSlice.fetchingOrderDetails && (
          <CircularProgress size={30} />
        )}
        {/*  <Button
          onClick={() => {
            onClose()
            resetForm()
          }}
          variant='outlined'
          disableElevation
        >
          Cancel
        </Button> */}
        <Button
          onClick={() => {
            onDone()
          }}
          variant='contained'
          color='primary'
          disableElevation
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
