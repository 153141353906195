import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { showSuccessAlert, showErrorAlert } from 'redux/alertHandler/alertSlice'
import { logger } from 'utils/logger'
import { searchingTests } from 'redux/collectionCenter/SearchPartnerLabTest/partnerLabTestSearchSlice'
import { searchInvitations } from 'redux/collectionCenter/Search/partnerLabSearchSlice'
import { getAlldayCareCharges } from './dayCareSearchSliceForRate'
import { DaycareRoomRateAddStatus } from './DaycareRoomRateAddStatus'

const initialState: DaycareRoomRateAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
  updating: false,
  updateSuccessful: false,
  deleting: false,
  deletionSuccessful: false,
}

const createTestSlice = createSlice({
  name: 'createTestSlice',
  initialState,
  reducers: {
    updatingCatalog(state, action: PayloadAction<DaycareRoomRateAddStatus>) {
      state.updating = action.payload.updating
      state.updateSuccessful = action.payload.updateSuccessful
      state.error = action.payload.error
    },

    catalogUpdated(state, action: PayloadAction<DaycareRoomRateAddStatus>) {
      state.updating = action.payload.updating
      state.updateSuccessful = action.payload.updateSuccessful
      state.error = action.payload.error
    },

    errorInCreateCatalog(
      state,
      action: PayloadAction<DaycareRoomRateAddStatus>
    ) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },
  },
})

export const updateCatalog =
  (catalogBundle: R4.IBundle, locationId: string, type: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const updatingCatalogState: DaycareRoomRateAddStatus = {
      adding: false,
      additionSuccessful: false,
      error: false,
      updating: true,
      updateSuccessful: false,
      deleting: false,
      deletionSuccessful: false,
    }
    dispatch(createTestSlice.actions.updatingCatalog(updatingCatalogState))

    try {
      logger.info(catalogBundle)
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doCreateFHIRTransaction(
        '/',
        catalogBundle
      )
      const respDecoded: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (respDecoded._tag === 'Right') {
        logger.info('Response Document decoded', respDecoded.right)

        const successUpdate: DaycareRoomRateAddStatus = {
          error: false,
          errorMessage: '',
          updating: false,
          updateSuccessful: true,
          deleting: false,
          deletionSuccessful: false,
          adding: false,
          additionSuccessful: false,
        }
        dispatch(showSuccessAlert(`${type} updated successfully`))
        dispatch(getAlldayCareCharges('1'))
        dispatch(createTestSlice.actions.catalogUpdated(successUpdate))
      } else {
        const errorAdding: DaycareRoomRateAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: `Error while updating ${type}`,
          updating: false,
          updateSuccessful: false,
          deleting: false,
          deletionSuccessful: false,
          //   patient: patientResponse,
        }
        dispatch(showErrorAlert(`Error while updating ${type}`))
        dispatch(createTestSlice.actions.errorInCreateCatalog(errorAdding))
        return
      }
    } catch (error) {
      const errorAddState: DaycareRoomRateAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: `Error while updating ${type}`,
        updating: false,
        updateSuccessful: false,
        deleting: false,
        deletionSuccessful: false,
      }
      dispatch(showErrorAlert(`Error while updating ${type}`))
      dispatch(createTestSlice.actions.errorInCreateCatalog(errorAddState))
    }
  }
