/* eslint-disable no-await-in-loop */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateWiseGraphData } from 'models/adminGraph/dateWiseGraphData'
import { GraphType } from 'models/adminGraph/graphType'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import { getDateWiseDietPlansFromBundle } from 'utils/fhirResoureHelpers/ipdDietHelper'
import {
  getAmountTotal,
  getAmountTotalForAdmin,
  getAmountTotalUnitWise,
  getDataForDateWiseGraph,
  getDataForMonthWiseGraph,
  getDataForPaymentMonthWise,
  getDataForTypeWise,
} from 'utils/fhirResoureHelpers/orgAdminGraphHelper'
import { logger } from 'utils/logger'
import { dateOptionsList } from 'wello-web-components/dist/components/LayoutComponent/PersonComponent/WelloDoctorAvailability'
import { PaymentSearchStatus } from './paymentSearchStatus'

const initialState: PaymentSearchStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
}

const totalPaymentUnitWiseSlice = createSlice({
  name: 'totalPaymentUnitWiseSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<PaymentSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorReason = action.payload.errorReason
      state.totalAmount = action.payload.totalAmount
      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
    resetMonthState(state, action: PayloadAction<PaymentSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorReason = action.payload.errorReason
      state.totalAmount = action.payload.totalAmount
      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const resetRevenueForUnit = () => (dispatch: AppDispatch) => {
  dispatch(totalPaymentUnitWiseSlice.actions.resetMonthState(initialState))
}

export const requestForPaymentTotalForUnit =
  (unitId: string, unitType: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PaymentSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(totalPaymentUnitWiseSlice.actions.updatedStatus(state))
    try {
      const fhirClient: MasterFhirClient = new MasterFhirClient()
      let response: any

      const searchParameters: any = {
        _count: 500,
        outcome: 'complete',
      }
      if (unitType === 'clinic') {
        response =
          await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
            `/PaymentReconciliation?_sort=payment-reconciliation-date&_revinclude=Invoice:invoice-paymentRef&payment-issuer=Organization/${unitId}&outcome=complete`,
            '',
            searchParameters
          )
      } else {
        response =
          await fhirClient.doGetResourceForAppointmentCrossSearchWithoutAppointment(
            `/PaymentReconciliation?_sort=payment-reconciliation-date&payment-issuer=${unitId}&outcome=complete`,
            '',
            searchParameters
          )
      }

      const resp: R4.IBundle = response as R4.IBundle
      if (!resp) {
        state.errorWhileSearchingAppointments = true
        state.searchingAppointments = false

        dispatch(totalPaymentUnitWiseSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp

        const resultData =
          unitType === 'clinic'
            ? getAmountTotalUnitWise(appointmentResponse)
            : getAmountTotalForAdmin(appointmentResponse)

        if (resultData > 0) {
          state.resultsAvailable = true
          state.searchingAppointments = false
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.totalAmount = resultData

          state.errorWhileSearchingAppointments = false
          dispatch(totalPaymentUnitWiseSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: PaymentSearchStatus = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            totalPaymentUnitWiseSlice.actions.updatedStatus(errorSearchDoctor)
          )
        }
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: PaymentSearchStatus = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
      }
      dispatch(
        totalPaymentUnitWiseSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export default totalPaymentUnitWiseSlice.reducer
