import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Card, CardContent, Divider } from '@material-ui/core'
import React from 'react'
import { ReportViewerTile } from 'views/components/common/reportViewer/reportViewerTile'

interface Props {
  reports: R4.IDocumentReference[]
}

export const ReportTile: React.FC<Props> = ({ reports }: Props) => (
  <Box width='100%' border={1} borderRadius={4} borderColor='lightGrey'>
    <Card
      elevation={0}
      style={{
        padding: 0,
        margin: 0,
      }}
    >
      <CardContent
        style={{
          padding: 3,
        }}
      >
        {reports.map((doc: R4.IDocumentReference, index: number) => (
          <Box display='flex' flexDirection='column' width='100%' key={doc.id}>
            <ReportViewerTile documentRef={doc} size='medium' />
            {reports.length > 1 && index < reports.length - 1 && (
              <Divider
                orientation='horizontal'
                variant='fullWidth'
                flexItem
                style={{ width: '100%', height: '2px' }}
                color='primary'
              />
            )}
          </Box>
        ))}
      </CardContent>
    </Card>
  </Box>
)
