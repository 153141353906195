import {
  Button,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useState } from 'react'

export interface SingleChoiceTextProps {
  selectedValue: any
  availableOptions: any[]
  isInEditMode: boolean
  onAddClicked: (selectedValue: any) => void
  placeHolderString: string
  valuesField: string
  displayField: string
  detachLabel: boolean
  labelString: string
  addButtonText?: string
  editButtonText?: string
  id: string
}

export const SingleSelectWithTextSearch: React.FC<SingleChoiceTextProps> = ({
  selectedValue: selectedValues,
  availableOptions,
  isInEditMode,
  onAddClicked,
  placeHolderString,
  valuesField,
  detachLabel,
  labelString,
  displayField,
  id,
  addButtonText = 'Add',
  editButtonText = 'Done',
}: SingleChoiceTextProps) => {
  const [editMode, setEditMode] = useState<boolean>(isInEditMode)
  const [selectedItem, setSelectedItem] = useState<any>(selectedValues)

  function getSelectedValuesText(): string {
    if (selectedItem && selectedItem[displayField]) {
      let returnString = ''
      returnString = selectedItem[displayField]
      returnString = returnString.slice(0, -1)
      return returnString
    }
    return placeHolderString
  }
  return (
    <>
      {!editMode && (
        <ListItem ContainerComponent='div' id={`single_select${id}_header`}>
          <ListItemText
            style={{
              padding: 0,
            }}
            primary={detachLabel ? '' : labelString}
            secondary={getSelectedValuesText()}
          />
          <ListItemSecondaryAction>
            <Button
              color='primary'
              onClick={() => {
                setEditMode(true)
              }}
              style={{ textTransform: 'capitalize' }}
            >
              {editButtonText}
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
      )}
      {editMode && (
        <Autocomplete
          limitTags={2}
          disableClearable
          id={`${id}`}
          options={availableOptions}
          size='small'
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : option[displayField]
          }
          onChange={(event, value, reason) => {
            setSelectedItem(value)
            onAddClicked(value)
          }}
          renderOption={(option, state) => (
            <Typography id={`auto_single_complete_id_${option[displayField]}`}>
              {option[displayField]}
            </Typography>
          )}
          value={selectedValues}
          renderInput={(params) => (
            /* params.InputProps.endAdornment = (
              <InputAdornment position='end' style={{ padding: 9 }}>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={!selectedItem[displayField]}
                  onClick={() => {
                    setEditMode(false)
                  }}
                >
                  {' '}
                  {addButtonText}
                </Button>
              </InputAdornment>
            ) */
            <TextField
              {...params}
              variant='outlined'
              style={{
                backgroundColor: 'white',
              }}
              label={detachLabel ? undefined : labelString}
              placeholder={
                detachLabel
                  ? placeHolderString
                  : `${labelString}:${placeHolderString}`
              }
            />
          )}
        />
      )}
    </>
  )
}
