import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { getDocumentReferenceBundleForEncounter } from 'utils/labHelpers/fhirDocReferenceBuncle'
import { logger } from 'utils/logger'
import { SelectedFile } from 'views/components/LeftMenu/WelloFilePicker'
import { DocumentReferenceUploadStatus } from './documentReferenceUploadStatusTypes'

const initialState: DocumentReferenceUploadStatus = {
  uploading: false,
  uploadingSuccessful: false,
  error: false,
  errorMessage: '',
  fetching: false,
  fetchingSuccessful: false,
  documents: [],
  references: [],
}

const uploadSuccess: string[] = []

const uploadDocumentReferenceSlice = createSlice({
  name: 'uploadDocumentReferenceSlice',
  initialState,
  reducers: {
    updateState(state, action: PayloadAction<DocumentReferenceUploadStatus>) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
      state.documents = action.payload.documents
      state.errorMessage = action.payload.errorMessage
      state.fetching = action.payload.fetching
      state.fetchingSuccessful = action.payload.fetchingSuccessful
      state.references = action.payload.references
    },
    uploadingDocument(
      state,
      action: PayloadAction<DocumentReferenceUploadStatus>
    ) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
    },

    documentUploaded(
      state,
      action: PayloadAction<DocumentReferenceUploadStatus>
    ) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
      state.documents = action.payload.documents
      state.references = action.payload.references
    },

    errorInUploadingDocument(
      state,
      action: PayloadAction<DocumentReferenceUploadStatus>
    ) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    resetUpload(state, action: PayloadAction<DocumentReferenceUploadStatus>) {
      state.uploading = action.payload.uploading
      state.uploadingSuccessful = action.payload.uploadingSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.documents = action.payload.documents
    },
  },
})

export const fetchLabDocuments =
  (encounterID: string, patientId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingDocumentUploadedState: DocumentReferenceUploadStatus = {
      ...initialState,
    }
    addingDocumentUploadedState.uploading = true
    dispatch(
      uploadDocumentReferenceSlice.actions.updateState(
        addingDocumentUploadedState
      )
    )

    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doGetResourceForAppointment(
        'DocumentReference',
        '',
        {
          encounter: `Encounter/${encounterID}`,
        }
      )

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        const errorUploadingDocument: DocumentReferenceUploadStatus = {
          uploading: false,
          uploadingSuccessful: false,
          fetching: false,
          fetchingSuccessful: false,
          error: true,
          errorMessage: 'Error while Fetching',
        }
        dispatch(
          uploadDocumentReferenceSlice.actions.updateState(
            errorUploadingDocument
          )
        )
      } else {
        const res: R4.IBundle = resp.right
        if (res.entry && res.entry.length > 0) {
          const docs: R4.IDocumentReference[] = res.entry.map(
            (e) => e as R4.IDocumentReference
          )
        }
      }
    } catch (error) {
      const errorDocuploadedState: DocumentReferenceUploadStatus = {
        uploading: false,
        uploadingSuccessful: false,
        fetching: false,
        fetchingSuccessful: false,
        error: true,
        errorMessage: 'Error while uploading',
      }
      dispatch(
        uploadDocumentReferenceSlice.actions.updateState(errorDocuploadedState)
      )
    }
  }

export const uploadingDocument =
  (files: SelectedFile[], encounterID: string, patientId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingDocumentUploadedState: DocumentReferenceUploadStatus = {
      ...initialState,
    }
    addingDocumentUploadedState.uploading = true
    dispatch(
      uploadDocumentReferenceSlice.actions.updateState(
        addingDocumentUploadedState
      )
    )

    const referencesDoc: string[] = []

    try {
      const docBundle = getDocumentReferenceBundleForEncounter(
        files,
        encounterID,
        patientId
      )

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doCreateFHIRTransaction(
        '/',
        docBundle
      )
      const respDecoded: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (respDecoded._tag === 'Right') {
        logger.info('Response Document decoded', respDecoded.right)
        const documentResponse: R4.IBundle = respDecoded.right
        if (documentResponse.entry) {
          const res = await Promise.all(
            documentResponse.entry.map(async (docRef, i) => {
              const docRefVal: string = docRef.response?.location ?? ''
              const docObj = docRefVal.replace('/_history/1', '')
              referencesDoc.push(docObj)
              const resp = await uploadContent(
                files[i],
                docObj,
                files[i].files!.type
              )
              return resp
            })
          )
          let finalRes: boolean = true
          res.forEach((e) => {
            finalRes = finalRes && e
          })
          if (finalRes) {
            const successUploadingDocument: DocumentReferenceUploadStatus = {
              uploading: false,
              uploadingSuccessful: true,
              error: false,
              errorMessage: '',
              fetching: false,
              fetchingSuccessful: false,
              documents: [],
              references: referencesDoc,
            }
            dispatch(
              uploadDocumentReferenceSlice.actions.updateState(
                successUploadingDocument
              )
            )
            return
          }
          const errorUploadingDocument: DocumentReferenceUploadStatus = {
            uploading: false,
            uploadingSuccessful: false,
            fetching: false,
            fetchingSuccessful: false,
            error: true,
            errorMessage: 'Error while uploading',
          }
          dispatch(
            uploadDocumentReferenceSlice.actions.updateState(
              errorUploadingDocument
            )
          )
          return
        }
        const errorUploadingDocument: DocumentReferenceUploadStatus = {
          uploading: false,
          uploadingSuccessful: false,
          fetching: false,
          fetchingSuccessful: false,
          error: true,
          errorMessage: 'Error while creating resources',
        }
        dispatch(
          uploadDocumentReferenceSlice.actions.updateState(
            errorUploadingDocument
          )
        )
      } else {
        const errorUploadingDocument: DocumentReferenceUploadStatus = {
          uploading: false,
          uploadingSuccessful: false,
          fetching: false,
          fetchingSuccessful: false,
          error: true,
          errorMessage: 'Error while uploading files',
        }
        dispatch(
          uploadDocumentReferenceSlice.actions.updateState(
            errorUploadingDocument
          )
        )
      }

      return
    } catch (error) {
      const errorDocuploadedState: DocumentReferenceUploadStatus = {
        uploading: false,
        uploadingSuccessful: false,
        fetching: false,
        fetchingSuccessful: false,
        error: true,
        errorMessage: 'Error while uploading',
      }
      dispatch(
        uploadDocumentReferenceSlice.actions.updateState(errorDocuploadedState)
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(uploadDocumentReferenceSlice.actions.resetUpload(initialState))
}

async function uploadContent(
  file: SelectedFile,
  documentRef: string,
  type: string
): Promise<boolean> {
  let result: boolean = false
  const reader = new FileReader()
  const rABS = !!reader.readAsArrayBuffer

  const buffer = await file.files!.arrayBuffer()
  const data = new Uint8Array(<ArrayBuffer>buffer)
  const convertedData = Buffer.from(data).toString('base64')
  if (data !== null) {
    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const response: any | FHIRErrorResponses =
      await fhirClient.doCreateFHIRUploadResourceRequest(
        `/${documentRef}/$binary-access-write?path=DocumentReference.content.attachment`,
        convertedData,
        type
      )
    logger.info('Upload with reference')
    logger.info(response.data)
    const respDecoded: E.Either<Errors, R4.IDocumentReference> =
      R4.RTTI_DocumentReference.decode(response.data)
    if (respDecoded._tag === 'Right') {
      result = true
    } else {
    }
  }

  return result
}

export default uploadDocumentReferenceSlice.reducer
