/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { ObservationStatusKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { UmlResponse } from 'models/umlResponse'
import { WelloHabits } from 'models/WelloConditions'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showSuccessAlert, showErrorAlert } from 'redux/alertHandler/alertSlice'
import {
  addAllergiesDetails,
  resetAddAllergiesState,
} from 'redux/fhirMedicalResources/ipd/addAllergy/addAllergySliceIPD'

import {
  addHabitsOPD,
  createBundleObjectForObservationsHabits,
  resetAddHabitState,
} from 'redux/fhirMedicalResources/opd/addHabits/habitAddSlice'
import { RootState } from 'redux/rootReducer'
import {
  breathe,
  energyLevels,
  excersiseCode,
  intensity,
  listOfHabbits,
  others,
  sleepPattern,
  teaCount,
} from 'utils/constants'
import { updateAddictions } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { validateOthers } from 'utils/patientHelper/patientEditHelper'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloSelectFHIRCodingForSleep } from 'views/components/LeftMenu/WelloSelectFhirCodingHabbit'
import { WelloSelectFHIRCodingWithoutSort } from 'views/components/LeftMenu/WelloSelectFhirCodingWithoutSort'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import {
  RadioRaisedButton,
  WelloLoadingIndicator,
  WelloTextField,
} from 'wello-web-components'
import { AllergyMasterChosser } from '../subjective/allergyMasterChosser'

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  onClose: () => void
  onHabbitAdded: () => void
  existingHabits: WelloHabits
}
export const EditHabits: React.FC<Props> = ({
  open,
  onClose,
  onHabbitAdded,
  fhirAppointmentDetails,
  existingHabits,
}) => {
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '45%',
    },
  }))
  const classes = useStyles()

  const [currentCondition, setCurrentCondition] = useState<WelloHabits>({
    id: existingHabits.id,
    existingObs: existingHabits.existingObs,
    coffee: existingHabits.coffee,
    exerciseDuration: existingHabits.exerciseDuration,
    exerciseIntensity: existingHabits.exerciseIntensity,
    tea: existingHabits.tea,
    energyLevel: existingHabits.energyLevel,
    sleepDay: existingHabits.sleepDay,
    sleepNight: existingHabits.sleepNight,
    otherHabit: existingHabits.otherHabit,
  })

  const [excerciseDuration, setExcersiseDuration] = useState<R4.ICoding>()
  const [excersiseIntensity, setExcersiseIntencity] = useState<R4.ICoding>()
  const [energyLevel, setEnergylevel] = useState<R4.ICoding>()
  const [breatePattern, setBreathePattern] = useState<R4.ICoding>()
  const [other, setOther] = useState<boolean>(false)
  const [otherData, setOtherData] = useState<R4.ICoding>()
  const [sleep, setSleep] = useState<R4.ICoding>()
  const [sleepDay, setSleepDay] = useState<R4.ICoding>()
  const [cofee, setCofee] = useState<R4.ICoding>()
  const [tea, setTea] = useState<R4.ICoding>()

  const dispatch = useDispatch()

  const addHabitSliceOPD = useSelector(
    (state: RootState) => state.addHabitSliceOPD
  )

  function isOtherSelected(): boolean {
    if (other === true && !otherData) {
      return true
    }
    return false
  }

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  function updateObsFinal() {
    setUpdateStatus({ requesting: true })
    const oldObsList: R4.IObservation[] = []
    if (
      currentCondition.existingObs &&
      currentCondition.existingObs.length > 0
    ) {
      for (let i = 0; i < currentCondition.existingObs!.length; i++) {
        const oldProcedure: R4.IObservation = {
          ...currentCondition.existingObs![i],
        }
        oldProcedure.status = ObservationStatusKind._enteredInError
        oldObsList.push(oldProcedure)
      }
    }
    console.log(currentCondition)
    const bundleObject: R4.IBundle = createBundleObjectForObservationsHabits(
      fhirAppointmentDetails,
      currentCondition.exerciseDuration,
      currentCondition.exerciseIntensity,
      currentCondition.energyLevel,
      undefined,
      currentCondition.sleepNight,
      currentCondition.sleepDay,
      currentCondition.coffee,
      currentCondition.tea,
      currentCondition.otherHabit
    )

    for (let i = 0; i < oldObsList.length; i++) {
      bundleObject.entry?.push({
        resource: oldObsList[i],
        fullUrl: `${oldObsList[i].resourceType}/${oldObsList[i].id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${oldObsList[i].resourceType}/${oldObsList[i].id}`,
        },
      })
    }

    setLoading(true)

    updateAddictions(bundleObject, fhirAppointmentDetails.appointment.id!).then(
      (e) => {
        if (e) {
          setLoading(false)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(showSuccessAlert('Addiction details updated successfully'))
          onHabbitAdded()
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while updating Addiction details. Please try again later'
            )
          )
        }
      }
    )
  }

  useEffect(() => {
    setCurrentCondition({
      id: existingHabits.id,
      existingObs: existingHabits.existingObs,
      coffee: existingHabits.coffee,
      exerciseDuration: existingHabits.exerciseDuration,
      exerciseIntensity: existingHabits.exerciseIntensity,
      tea: existingHabits.tea,
      energyLevel: existingHabits.energyLevel,
      sleepDay: existingHabits.sleepDay,
      sleepNight: existingHabits.sleepNight,
      otherHabit: existingHabits.otherHabit,
    })
  }, [existingHabits])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        dispatch(resetAddHabitState())
        setExcersiseDuration(undefined)
        setExcersiseIntencity(undefined)
        setEnergylevel(undefined)
        setBreathePattern(undefined)
        setSleep(undefined)
        setCofee(undefined)
        setOther(false)
        setTea(undefined)
        setOtherData(undefined)
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Update Habits</DialogTitle>
      <DialogContent dividers={true}>
        {loading && <WelloLoadingIndicator message='Adding details' />}

        {!loading && (
          <Box marginX={2}>
            <Grid container direction='column' spacing={1} wrap='nowrap'>
              <Grid item>
                {listOfHabbits.map((val) => (
                  <Box display='flex' flexDirection='column' width='100%'>
                    {val.code === '74009-2' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title={val.display ?? ''} />
                        </Box>
                        <Box width='40%'>
                          <WelloSelectFHIRCoding
                            availableCodings={excersiseCode}
                            onChanged={(type) => {
                              setCurrentCondition({
                                ...currentCondition,
                                exerciseDuration: type,
                              })
                              //   setExcersiseDuration(type)
                            }}
                            id='hab_10'
                            textFieldProps={{
                              id: 'hab_10',
                              size: 'small',
                              fullWidth: true,
                            }}
                            preSelectedCoding={
                              currentCondition.exerciseDuration
                            }
                          />
                        </Box>
                      </Box>
                    )}

                    {val.code === '10000-2' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title='How many cups of coffee do you drink per day' />
                        </Box>
                        <Box width='40%'>
                          <WelloSelectFHIRCoding
                            availableCodings={teaCount}
                            onChanged={(type) => {
                              setCurrentCondition({
                                ...currentCondition,
                                coffee: type,
                              })
                            }}
                            id='hab_11'
                            textFieldProps={{
                              size: 'small',
                              id: 'hab_11',

                              fullWidth: true,
                            }}
                            preSelectedCoding={currentCondition.coffee}
                          />
                        </Box>
                      </Box>
                    )}

                    {val.code === '74008-4' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title={val.display ?? ''} />
                        </Box>
                        <Box width='40%'>
                          <WelloSelectFHIRCodingWithoutSort
                            availableCodings={intensity}
                            onChanged={(type) => {
                              setCurrentCondition({
                                ...currentCondition,
                                exerciseIntensity: type,
                              })
                            }}
                            id='hab_12'
                            textFieldProps={{
                              size: 'small',
                              id: 'hab_12',
                              fullWidth: true,
                            }}
                            preSelectedCoding={
                              currentCondition.exerciseIntensity
                            }
                          />
                        </Box>
                      </Box>
                    )}

                    {val.code === '65515-9' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title={val.display ?? ''} />
                        </Box>
                        <Box width='40%'>
                          <WelloSelectFHIRCoding
                            availableCodings={energyLevels}
                            id='hab_13'
                            onChanged={(type) => {
                              setCurrentCondition({
                                ...currentCondition,
                                energyLevel: type,
                              })
                            }}
                            textFieldProps={{
                              size: 'small',
                              id: 'hab_13',
                              fullWidth: true,
                            }}
                            preSelectedCoding={currentCondition.energyLevel}
                          />
                        </Box>
                      </Box>
                    )}

                    {val.code === '28148-5' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title={val.display ?? ''} />
                        </Box>
                        <Box width='40%'>
                          <WelloSelectFHIRCoding
                            availableCodings={breathe}
                            id='hab_14'
                            onChanged={(type) => {
                              setBreathePattern(type)
                            }}
                            textFieldProps={{
                              size: 'small',
                              id: 'hab_14',
                              fullWidth: true,
                            }}
                          />
                        </Box>
                      </Box>
                    )}

                    {val.code === '10000-1' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title='How many cups of tea do you drink per day' />
                        </Box>
                        <Box width='40%'>
                          <WelloSelectFHIRCoding
                            availableCodings={teaCount}
                            id='hab_15'
                            onChanged={(type) => {
                              setTea(type)
                              setCurrentCondition({
                                ...currentCondition,
                                tea: type,
                              })
                            }}
                            textFieldProps={{
                              size: 'small',
                              id: 'hab_15',
                              fullWidth: true,
                            }}
                            preSelectedCoding={currentCondition.tea}
                          />
                        </Box>
                      </Box>
                    )}

                    {val.code === '65968-0' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title={val.display ?? ''} />
                        </Box>
                        <Box width='40%'>
                          <WelloSelectFHIRCodingForSleep
                            availableCodings={sleepPattern}
                            id='hab_16'
                            onChanged={(type) => {
                              setCurrentCondition({
                                ...currentCondition,
                                sleepNight: type,
                              })
                            }}
                            textFieldProps={{
                              size: 'small',
                              id: 'hab_16',
                              fullWidth: true,
                            }}
                            preSelectedCoding={currentCondition.sleepNight}
                          />
                        </Box>
                      </Box>
                    )}

                    {val.code === '65561-3' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        py={0.5}
                      >
                        <Box width='60%'>
                          <WelloFormItemLabel title={val.display ?? ''} />
                        </Box>
                        <Box width='40%'>
                          <WelloSelectFHIRCodingForSleep
                            id='hab_17'
                            availableCodings={sleepPattern}
                            onChanged={(type) => {
                              setCurrentCondition({
                                ...currentCondition,
                                sleepDay: type,
                              })
                            }}
                            textFieldProps={{
                              size: 'small',
                              id: 'hab_17',
                              fullWidth: true,
                            }}
                            preSelectedCoding={currentCondition.sleepDay}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                ))}
                {/* <Box
                  display='flex'
                  width='100%'
                  flexDirection='row'
                  paddingTop={0.5}
                >
                  <Box width='60%'>
                    <WelloFormItemLabel title='Any Other Habit' />
                  </Box>
                  <Box width='40%'>
                    <WelloTextFieldWithoutTitle
                      textProps={{
                        id: `actor_other`,
                        type: 'text',
                        error:
                          validateOthers(
                            otherData ? otherData!.display ?? '' : '',
                            false
                          ).length > 0,
                        helperText:
                          validateOthers(
                            otherData ? otherData!.display ?? '' : '',
                            false
                          ).length > 0
                            ? `${validateOthers(
                                otherData ? otherData!.display ?? '' : '',
                                false
                              )}`
                            : '',
                        onChange: (changeData) => {
                          setOtherData({
                            code: 'other-habit',
                            system:
                              'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-habit-cs',
                            display: changeData.target.value,
                          })
                        },
                      }}
                    />
                  </Box>
                </Box> */}
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box display='flex' flexDirection='row' paddingRight={3.5}>
          <Button
            onClick={() => {
              onClose()
              setExcersiseDuration(undefined)
              setExcersiseIntencity(undefined)
              setEnergylevel(undefined)
              setBreathePattern(undefined)
              setSleep(undefined)
              setCofee(undefined)
              setTea(undefined)
              setSleepDay(undefined)
              setOther(false)
              setOtherData(undefined)
            }}
            id='hab_20'
            variant='outlined'
            disabled={loading}
            disableElevation
          >
            {t('labelCommon:cancel')}
          </Button>
          <Button
            onClick={() => {
              updateObsFinal()
            }}
            variant='contained'
            color='primary'
            id='hab_21'
            disableElevation
            disabled={loading}
          >
            Update
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
