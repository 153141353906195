import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  TaskIntentKind,
  TaskStatusKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import * as E from 'fp-ts/lib/Either'

import { Errors } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import { getEncounterObjectForAppointment } from 'utils/fhirResoureHelpers/appointmentHelpers'

export async function addAndGetEncounterIdOfAppointment(
  appointment: FhirAppointmentDetail
): Promise<string | undefined> {
  const encounter: R4.IEncounter = getEncounterObjectForAppointment(appointment)

  const matchString: string = `${encounter.resourceType}?appointment=${appointment.appointment.resourceType}/${appointment.appointment.id}`

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: 'urn:uuid:1232323232324',
        request: {
          url: matchString,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: encounter,
      },
    ],
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: R4.IBundle | FHIRErrorResponses =
    await fhirClient.doCreateFHIRTransaction('', requestBundle)
  return getEncounterId(response as R4.IBundle)
}

export function getEncounterId(params: R4.IBundle): string | undefined {
  if (params.entry) {
    if (params.entry.length > 0) {
      const res: number = params.entry?.findIndex((val: R4.IBundle_Entry) => {
        if (val.response && val.response.location) {
          return val.response.location.includes('Encounter')
        }
        return false
      })
      if (res >= 0) {
        const resList: string[] =
          params.entry[res].response?.location?.split('/')!
        return resList[1]
      }
    }
  }
  return undefined
}

export async function addAndGetEncounterDetailsOfAppointment(
  appointment: FhirAppointmentDetail
): Promise<R4.IEncounter | undefined> {
  const encounter: R4.IEncounter = getEncounterObjectForAppointment(appointment)

  const matchString: string = `${encounter.resourceType}?appointment=${appointment.appointment.resourceType}/${appointment.appointment.id}`

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: 'urn:uuid:1232323232324',
        request: {
          url: matchString,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: encounter,
      },
    ],
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: R4.IBundle | FHIRErrorResponses =
    await fhirClient.doCreateFHIRTransaction('', requestBundle)
  const id = getEncounterId(response as R4.IBundle)

  const responseEncounterBundle: R4.IEncounter =
    await fhirClient.doGetResourceForAppointment(
      `/Encounter/${id}`,
      appointment.appointment.id!
    )

  return responseEncounterBundle
}

export function getCommunicationRequest(
  communicationRequests: R4.ICommunicationRequest,
  encounterId: string,
  patientId: string,
  planDefinitionUrl?: string
): R4.ICommunicationRequest {
  const outPutMedicationRequest: R4.ICommunicationRequest = {
    ...communicationRequests,
  }

  outPutMedicationRequest.encounter = {
    reference: `Encounter/${encounterId}`,
  }
  if (planDefinitionUrl)
    outPutMedicationRequest.meta = {
      source: planDefinitionUrl,
    }

  outPutMedicationRequest.subject = {
    reference: `Patient/${patientId}`,
  }

  outPutMedicationRequest.status = 'active'
  return outPutMedicationRequest
}

export async function addInstruction(
  medicationRequest: R4.ICommunicationRequest
): Promise<R4.ICommunicationRequest | undefined> {
  try {
    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const response: any = await fhirClient.doCreateFHIRResourceRequest(
      '/CommunicationRequest',
      medicationRequest
    )

    const relatedFhirDecodeRes: E.Either<Errors, R4.ICommunicationRequest> =
      R4.RTTI_CommunicationRequest.decode(response.data)
    if (relatedFhirDecodeRes._tag === 'Right') {
      return relatedFhirDecodeRes.right
    }
  } catch (error) {}

  return undefined
}

export function getMedicationRequest(
  communicationRequests: R4.IMedicationRequest,
  encounterId: string,
  patientId: string,
  planDefinitionUrl?: string
): R4.IMedicationRequest {
  const outPutMedicationRequest: R4.IMedicationRequest = {
    ...communicationRequests,
  }

  outPutMedicationRequest.encounter = {
    reference: `Encounter/${encounterId}`,
  }

  if (planDefinitionUrl) {
    outPutMedicationRequest.meta = {
      source: planDefinitionUrl,
    }
  }

  outPutMedicationRequest.subject = {
    reference: `Patient/${patientId}`,
  }
  outPutMedicationRequest.requester = {
    reference: `PractitionerRole/${getCurrentUserPractitionerRoleDetails().id}`,
  }
  outPutMedicationRequest.status = 'draft'
  outPutMedicationRequest.intent = 'proposal'
  return outPutMedicationRequest
}

export function getServiceRequest(
  communicationRequests: R4.IServiceRequest,
  encounterId: string,
  patientId: string,
  planDefinitionUrl?: string
): R4.IServiceRequest {
  const outPutMedicationRequest: R4.IServiceRequest = {
    ...communicationRequests,
  }

  outPutMedicationRequest.encounter = {
    reference: `Encounter/${encounterId}`,
  }

  if (planDefinitionUrl) {
    outPutMedicationRequest.meta = {
      source: planDefinitionUrl,
    }
  }

  outPutMedicationRequest.subject = {
    reference: `Patient/${patientId}`,
  }
  outPutMedicationRequest.requester = {
    reference: `PractitionerRole/${getCurrentUserPractitionerRoleDetails().id}`,
  }
  outPutMedicationRequest.status = 'draft'
  outPutMedicationRequest.intent = 'proposal'
  return outPutMedicationRequest
}

export function getTasksResource(
  communicationRequests: R4.ITask,
  encounterId: string,
  patientId: string,
  planDefinitionUrl?: string
): R4.ITask {
  const outPutMedicationRequest: R4.ITask = {
    ...communicationRequests,
  }

  outPutMedicationRequest.encounter = {
    reference: `Encounter/${encounterId}`,
  }

  outPutMedicationRequest.for = {
    reference: `Patient/${patientId}`,
  }

  outPutMedicationRequest.owner = {
    reference: `Patient/${patientId}`,
  }

  outPutMedicationRequest.requester = {
    reference: `PractitionerRole/${getCurrentUserPractitionerRoleDetails().id}`,
  }
  outPutMedicationRequest.status = TaskStatusKind._draft
  outPutMedicationRequest.intent = TaskIntentKind._proposal
  return outPutMedicationRequest
}
