import { Divider } from '@mantine/core'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { LabReferralDetails } from 'models/labReferralDetails'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  acceptInvite,
  resetUpdateInviteState,
} from 'redux/administration/referralSetup/acceptInvite/labReferralAcceptInviteSlice'
import { searchingReferrals } from 'redux/administration/referralSetup/referralSearch/labReferralSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  isCurrentUnitIsLab,
  isCurrentUnitIsWellonessCenter,
} from 'services/userDetailsService'
import { AddReferralsToForm } from './addNewReferrals'
import { ReferralDataTable } from './referralDataTable'
// import { AddMembershipForm } from './addMembershipForm'
// import { MembershipDetailsPage } from './memberdhipDetails'
// import { MembershipDataTable } from './membershipDataTable'

// import EditTherapy from './editTherapy';

export interface MembershipSearchProps {
  onTap?: () => void
  onAdd?: () => void
}

export const ReferralSearch: React.FC<MembershipSearchProps> = ({
  onTap,
  onAdd,
}) => {
  const referralSearchSlice = useSelector(
    (state: RootState) => state.referralSearchSlice
  )

  const acceptInviteSlice = useSelector(
    (state: RootState) => state.labReferralAcceptInviteSlice
  )

  const [selectedMembership, setselectedMembership] =
    useState<LabReferralDetails>()
  const dispatch = useDispatch()

  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  useEffect(() => {
    if (onAdd) onAdd()
    dispatch(searchingReferrals())
    if (acceptInviteSlice.updatedSuccessfully) {
      dispatch(resetUpdateInviteState())
    }
  }, [dispatch])

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      flexGrow={1}
      height='100%'
      bgcolor={kDialogueBackground}
    >
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        bgcolor={kDialogueBackground}
        justifyContent='flex-end'
        paddingRight={1}
        paddingTop={6}
      >
        <Box
          display='flex'
          flexGrow={1}
          flexDirection='column'
          width='100%'
          height='100%'
          justifyContent='center'
          alignContent='center'
          paddingTop={1}
          style={{ overflow: 'auto', maxHeight: '100%' }}
        >
          <Divider />
          {referralSearchSlice.searching && (
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
              height='100%'
              width='100%'
            >
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            </Box>
          )}
          <Box
            display='flex'
            flexGrow={4}
            flexDirection='column'
            justifyContent='center'
            alignContent='center'
            overflow='auto'
            style={{
              backgroundColor: '#ececec',
            }}
          >
            {!referralSearchSlice.searching && (
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='flex-end'
                py={1}
              >
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  disabled={
                    isCurrentUnitIsWellonessCenter() || isCurrentUnitIsLab()
                  }
                  onClick={() => {
                    setShowPopup(true)
                  }}
                >
                  Invite New Referral
                </Button>
              </Box>
            )}
            {referralSearchSlice.noResultsAvailable && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='100%'
                height='100%'
                alignContent='center'
              >
                <Typography variant='subtitle1' color='initial' align='center'>
                  No referral data available
                </Typography>
              </Box>
            )}
            {referralSearchSlice.error && (
              <Box
                //   display='flex'
                //   flexDirection='column'
                justifyContent='center'
                width='100%'
                height='100%'
                alignContent='center'
                paddingLeft={50}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container justifyContent='center'>
                      <Typography
                        variant='subtitle1'
                        color='error'
                        align='center'
                      >
                        Error while searching referrals.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            {referralSearchSlice.resultsAvailable && (
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                height='100%'
                overflow='auto'
                // flexGrow={1}
              >
                <Box
                  display='flex'
                  flexGrow={1}
                  height='100%'
                  overflow='hidden'
                  //  paddingTop={0.2}
                >
                  <ReferralDataTable
                    onReferralSelected={(referral: LabReferralDetails) => {
                      dispatch(acceptInvite(referral))
                    }}
                    displayOnlyName={false}
                    usersData={
                      referralSearchSlice.referralList
                        ? referralSearchSlice.referralList
                        : []
                    }
                    selectedReferral={selectedMembership}
                  />
                </Box>
                {selectedMembership && <Divider orientation='vertical' />}
                {/* {selectedMembership && (
                  <Box
                    display='flex'
                    flexGrow={1}
                    overflow='auto'
                    width='70%'
                    bgcolor='white'
                  >
                    <MembershipDetailsPage
                      onCloseClicked={() => {
                        setselectedMembership(undefined)
                      }}
                      usersData={selectedMembership}
                    />
                  </Box>
                )} */}
              </Box>
            )}
          </Box>

          {showPopup && (
            <AddReferralsToForm
              open={showPopup}
              onBackClick={() => {
                setShowPopup(false)
                dispatch(searchingReferrals())
              }}
              onContinueClick={() => {
                setShowPopup(false)
                dispatch(searchingReferrals())
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
