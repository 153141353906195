import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  getCodeOfSystemFromCodableConceptList,
  getDisplayOfSystemFromCodableConcept,
} from 'utils/fhirResourcesHelper'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'

import { getTestNameFromOrderDetails } from 'utils/fhirResoureHelpers/referralHelpers'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirLabDiagnosticRequestReferrals } from 'models/fhirLabDiagnosticRequest'
import { ReportReferencePopUp } from 'views/components/common/reportViewer/reportFrameViewer'
import { DiagnosticReportPopup } from 'views/components/common/reportViewer/diaognisticReportViewer'
import OverflowTypography from '../plan/ayurveda/overflowTypography'

interface MedicationTileProps {
  referrals: FhirLabDiagnosticRequestReferrals[]
  type: string
  onDeleteClicked?: () => {}
  onEditCLicked?: () => {}
  prescription?: boolean
}

export const LabDiagnosticTabularWithReports: React.FC<MedicationTileProps> = ({
  referrals,
  type,
  onDeleteClicked,
  onEditCLicked,
  prescription,
}: MedicationTileProps) => {
  const kPrimaryColor: string = useTheme().palette.primary.main
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [documentRef, setDocumentRef] = useState<R4.IDocumentReference>()
  const [documentReport, setDocumentReport] = useState<R4.IDiagnosticReport>()

  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',
        height: 'auto',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Grid container direction='column' spacing={1}>
        <Grid
          container
          direction='row'
          id='vitals'
          style={{
            backgroundColor: kDialogueBackground,
          }}
        >
          <Grid item xs={6}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box display='flex' flexDirection='column' width='100%'>
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    {type === 'imaging' ? 'Imaging Test' : 'Test Name'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box display='flex' width='2%' paddingTop={0.2} px={0.5}>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '30px',

                    width: '2px',
                  }}
                />
              </Box>
              <Box display='flex' flexDirection='column' width='100%'>
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    {type === 'imaging' ? 'Imaging Diagnostic Lab' : 'Lab Name'}
                  </Typography>
                </Box>

                {/* <Divider
                      orientation='horizontal'
                      flexItem
                      style={{
                        height: '2px',
                      }}
                    /> */}
              </Box>
              <Box display='flex' width='2%' paddingTop={0.2}>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '30px',

                    width: '2px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display='flex' flexDirection='row' width='100%'>
              <Box display='flex' flexDirection='column' width='100%'>
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={30}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Report
                  </Typography>
                </Box>

                {/* <Divider
                      orientation='horizontal'
                      flexItem
                      style={{
                        height: '2px',
                      }}
                    /> */}
              </Box>
              {/* <Box display='flex' width='2%' paddingTop={0.2}>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{
                    height: '30px',

                    width: '2px',
                  }}
                />
              </Box> */}
            </Box>
          </Grid>
        </Grid>
        <Grid container direction='row' id='vitals'>
          <Grid item xs={12}>
            <Box width='100%' paddingLeft={0.2}>
              <Divider
                orientation='horizontal'
                flexItem
                style={{
                  height: '2px',
                }}
              />
            </Box>
          </Grid>
        </Grid>

        {/* ------------------------------------------------------------------------------- */}
        {referrals.map((e, index: number) => (
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            key={e.serviceRequest.id}
          >
            <Grid
              container
              direction='row'
              id='vitals'
              style={{
                backgroundColor:
                  index % 2
                    ? prescription
                      ? 'white'
                      : kDialogueBackground
                    : 'white',
              }}
            >
              <Grid item xs={6}>
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' flexDirection='column' width='100%'>
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      paddingLeft={1}
                    >
                      {type === 'referrals' && (
                        <OverflowTypography
                          text={
                            e.serviceRequest.patientInstruction ??
                            getTestNameFromOrderDetails(e.serviceRequest)
                          }
                          typographyProps={{
                            variant: 'subtitle2',
                            color: 'initial',
                          }}
                        />
                      )}

                      {type === 'imaging' && (
                        <OverflowTypography
                          text={
                            getDisplayOfSystemFromCodableConcept(
                              e.serviceRequest.code
                            ) ?? ''
                          }
                          typographyProps={{
                            variant: 'subtitle2',
                            color: 'initial',
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' width='2%' paddingTop={0.1} px={0.5}>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {e.serviceRequest.performer
                          ? e.serviceRequest.performer[0].display ?? ''
                          : ''}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' width='2%' paddingTop={0.1}>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',

                        width: '2px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                  paddingLeft={2}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={30}
                      alignItems='center'
                      px={1}
                    >
                      {e.docRefs && (
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setDocumentRef(e.docRefs)
                            setShowPreview(true)
                          }}
                        >
                          <FontAwesomeIcon
                            id={`${e.serviceRequest.id}_selected_icon`}
                            icon={faFilePdf}
                            size='1x'
                            color={kPrimaryColor}
                            style={{
                              color: kPrimaryColor,
                            }}
                          />
                        </Box>
                      )}

                      {e.docReports && (
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='100%'
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setDocumentReport(e.docReports)
                            setShowPreview(true)
                          }}
                        >
                          <FontAwesomeIcon
                            id={`${e.serviceRequest.id}_selected_icon`}
                            icon={faFilePdf}
                            size='1x'
                            color={kPrimaryColor}
                            style={{
                              color: kPrimaryColor,
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Grid>
      {showPreview && documentRef && (
        <ReportReferencePopUp
          documentRef={documentRef!}
          appointmentId=''
          showPreview={showPreview}
          onCloseClicked={() => {
            setDocumentRef(undefined)
            setShowPreview(false)
          }}
        />
      )}

      {showPreview && documentReport && (
        <DiagnosticReportPopup
          documentRef={documentReport!}
          showPreview={showPreview}
          onCloseClicked={() => {
            setDocumentRef(undefined)
            setShowPreview(false)
          }}
        />
      )}
    </Box>
  )
}
