import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core'
import { textMain } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { RootState } from 'redux/rootReducer'
import { CardsEntity } from 'redux/subscription/cpgRecommendations/cpgResponse'
import { getWelloMedicationFromMedicationRequest } from 'utils/appointment_handle/medications_util'
import { handleAddCarePlanActivities } from 'utils/careplan_utils/carePlanActivities'
import {
  getCarePlanActivities,
  getIdOfCardEntry,
  getMedications,
  hasError,
  isEssential,
} from 'utils/careplan_utils/cpg_recommendations_utils'
import { getCarePlanIdFromTaskOutput } from 'utils/careplan_utils/subscription_helpers/subscriptionHelper'
import { getSelectionContextTypeSections } from 'utils/contextHelpers/cpgSelectionContextHelpers'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getDisplayOfSystemFromCodableConcept,
} from 'utils/fhirResourcesHelper'
import CPGSelectionContext from 'views/contexts/cpgContextSelection'
import VisitAppointmentContext from 'views/contexts/visitContextDetails'
import { AddMedicationRecommendationButton } from '../add_medication_button'
import { CDSPlanActivityTile } from './carePlanActivities/carePlanActivityTile'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',

    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))
interface CdsCardDetailsProps {
  carePlanIdentifier: string
  cdsCard: CardsEntity
}

export const CarePlanActivitiesOfCPGRecommendation: React.FC<CdsCardDetailsProps> =
  ({ carePlanIdentifier, cdsCard: card }: CdsCardDetailsProps) => {
    const classes = useStyles()

    const dispatch = useDispatch()

    const [updatingStatus, setUpdatingStatus] = useState<NetworkRequestStatus>({
      initial: true,
    })

    const subscriptionStatus = useSelector(
      (state: RootState) => state.patientSubscriptionForUnitSlice
    )
    const visitContext = useContext(VisitAppointmentContext)

    // get selected care plan activities
    function getSelectedCarePlanActivities() {
      const allActivities: R4.ICarePlan_Detail[] = []
      card.suggestions?.forEach((each, headerIndex: number) => {
        if (each.actions && each.actions.length > 0) {
          getCarePlanActivities(
            (each.actions[0].resource as R4.IBundle).entry ?? []
          ).forEach((app, index) => {
            allActivities.push(app)
          })
        }
      })

      return allActivities.filter((e) =>
        selectedTestsToOrder.includes(e.description ?? '')
      )
    }

    function handleUpdateButton() {
      if (visitContext && visitContext.patient && visitContext.patient.id) {
        setUpdatingStatus({
          requesting: true,
        })
        if (subscriptionStatus.tasks) {
          const carePlanId = getCarePlanIdFromTaskOutput(
            subscriptionStatus.tasks[0].task
          )

          handleAddCarePlanActivities(
            visitContext.patient.id!,
            getSelectedCarePlanActivities(),
            visitContext.encounter!.id!,
            visitContext.visitReference! as FhirAppointmentDetail,
            carePlanIdentifier,
            carePlanId
          ).then((e) => {
            const res: string[] = []
            let overAllRes = true
            e.forEach((resp) => {
              overAllRes = overAllRes && resp.response === 'valid'
              if (resp.response === 'error') {
                res.push(resp.errorReason ?? resp.responseData)
              }
            })
            if (overAllRes) {
              setUpdatingStatus({
                requesting: false,
                requestSuccess: true,
              })
              dispatch(
                showSuccessAlert(
                  'Selected Care Plan Activities added successfully'
                )
              )
            } else {
              setUpdatingStatus({
                requesting: false,
                requestSuccess: false,
                requestError: true,
              })
              dispatch(
                showErrorAlert(res.join(','), '', 'Error while adding details')
              )
            }
          })
        } else {
          setUpdatingStatus({
            requesting: false,
            initial: true,
          })
        }
      }
    }

    const labDiagnosticTestCodes = useSelector(
      (state: RootState) =>
        state.labDiagnosticsListSlice.diagnosticTestsLoincCodes
    )
    const cdsRecommendationAddition = useContext(CPGSelectionContext)

    const selectedTestsToOrder = getSelectionContextTypeSections({
      cpgIdentifier: carePlanIdentifier,
      selectionCategoryType: 'care-plan-activities',
      selectionData: cdsRecommendationAddition.selectionData ?? [],
    })

    function onSelectedMedicationsChanged(changedTest: string) {
      cdsRecommendationAddition.addSelection(
        carePlanIdentifier,
        'care-plan-activities',
        changedTest
      )
    }

    return (
      <Box display='flex' flexDirection='column' py={0.25} overflow='auto'>
        {!hasError(card) &&
          card.interceptedSuggestions &&
          card.interceptedSuggestions.has('carePlanActivities') && (
            <Box
              display='flex'
              flexDirection='column'
              key='carePlanActivities_cpg_outer_label'
              py={0.25}
              overflow='auto'
            >
              <Box
                display='flex'
                flexDirection='column'
                key='carePlanActivities_cpg_inner_label'
                py={0.25}
                overflow='auto'
              >
                <Box
                  width='100%'
                  flex='row'
                  id='referrals_list'
                  display='flex'
                  overflow='auto'
                >
                  <List aria-label='error' style={{ width: '100%' }}>
                    {(
                      card.interceptedSuggestions.get(
                        'carePlanActivities'
                      ) as any[]
                    ).map((app, index) => (
                      <ListItem key={app.description ?? ''} disableGutters>
                        <CDSPlanActivityTile
                          isSelected={
                            getSelectionContextTypeSections({
                              cpgIdentifier: carePlanIdentifier,
                              selectionCategoryType: 'care-plan-activities',
                              selectionData:
                                cdsRecommendationAddition.selectionData ?? [],
                            }).findIndex(
                              (e) => e === app.description ?? '-'
                            ) !== -1
                          }
                          onSelectionChanged={() => {
                            if (app.kind === 'Appointment')
                              onSelectedMedicationsChanged(
                                app.description ?? ''
                              )
                          }}
                          disableOption={app.kind !== 'Appointment'}
                          activity={app}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
                <Box
                  width='100%'
                  flexDirection='row-reverse'
                  id='referrals_list'
                  display='flex'
                >
                  <Button
                    variant='text'
                    color='primary'
                    className={classes.buttonText}
                    disabled={
                      updatingStatus.requesting ||
                      selectedTestsToOrder.length === 0 ||
                      updatingStatus.requestSuccess
                    }
                    onClick={(e) => {
                      if (
                        subscriptionStatus.subscriptionStatus ===
                          'interested' ||
                        subscriptionStatus.subscriptionStatus === 'active'
                      ) {
                        handleUpdateButton()
                      } else {
                        dispatch(
                          showErrorAlert(
                            'Please subscribe to the plan to add care plan activities',
                            undefined,
                            undefined,
                            false
                          )
                        )
                      }
                      e.stopPropagation()
                    }}
                  >
                    {updatingStatus.requestSuccess ? 'Added' : 'Finalize & Add'}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
      </Box>
    )
  }
