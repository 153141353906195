import { createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

export type Props = {
  pdf: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    document_ref: {
      pointerEvents: 'none',
    },
  })
)

export const AllPages: React.FC<Props> = (props: Props) => {
  const [numPages, setNumPages] = useState<number>()

  const { pdf } = props
  const classes = useStyles()
  return (
    <Document
      file={pdf}
      onLoadSuccess={(pdfDoc) => {
        setNumPages(pdfDoc.numPages)
      }}
      renderMode='canvas'
      onLoadProgress={(err) => {}}
      onSourceError={(err) => {}}
      onSourceSuccess={() => {}}
      onLoadError={(err) => {}}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page
          scale={1.5}
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          renderTextLayer={false}
          renderMode='canvas'
          className={classes.document_ref}
        />
      ))}
    </Document>
  )
}
