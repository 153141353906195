import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core'
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@material-ui/icons'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  getPathyaApathyForTypeAndEncounter,
  PathyaApathyaDetails,
} from 'utils/sopaNotes/plan/pathyaApathyaHelpers'
import OverflowTypography from './overflowTypography'

interface Props {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  pathyaApathyaTypeCode: R4.ICoding
  updateList: string
  onClick?: () => void
  isReadonly?: boolean
  split?: boolean
}

export const PathyaApathyaListForEncounterForIPD: React.FC<Props> = ({
  fhirAppointmentDetails,
  pathyaApathyaTypeCode,
  updateList,
  onClick,
  isReadonly,
  split,
}: Props) => {
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const theme = useTheme()
  const [collapsed, setCollapsed] = useState(true)

  const [pathyaApathyaList, setPathyaApathyaList] =
    useState<PathyaApathyaDetails[]>()

  function fetchPathyaApathyaDetails() {
    setUpdateStatus({ requesting: true })
    getPathyaApathyForTypeAndEncounter({
      appointmentId: '',
      encounterId: fhirAppointmentDetails.mainEncounter?.id!,
      type: pathyaApathyaTypeCode.code!,
    }).then((e) => {
      if (e !== false) {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setPathyaApathyaList(e as PathyaApathyaDetails[])
      } else {
        setUpdateStatus({
          requesting: false,
          requestError: false,
        })
      }
    })
  }

  useEffect(() => {
    fetchPathyaApathyaDetails()
  }, [updateList])

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {updateStatus.requesting && <CircularProgress size={15} />}
      {updateStatus.requestError && (
        <Box marginY={1} display='flex' flexDirection='column' width='100%'>
          <Typography variant='subtitle2' color='error'>
            {' '}
            Error while fetching details
          </Typography>
        </Box>
      )}
      {updateStatus.requestSuccess &&
        pathyaApathyaList &&
        pathyaApathyaList.length === 0 &&
        split && (
          <Box marginY={1} display='flex' flexDirection='column' width='100%'>
            <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
              No data available
            </Typography>
          </Box>
        )}
      {updateStatus.requestSuccess && pathyaApathyaList && (
        <Box width='100%' display='flex' flexDirection='column'>
          {pathyaApathyaList.map((item) => (
            <Box width='100%' key={item.recordedDate ?? ''}>
              <Box
                paddingX={1}
                borderRadius={2}
                style={{
                  backgroundColor: 'lightGrey',
                }}
                display='flex'
                flexDirection='row'
                width='100%'
                height={40}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow={1}
                  alignItems='center'
                >
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: 'black',

                      fontWeight: 'bold',
                    }}
                  >
                    {`Recorded on ${moment(item.recordedDate).format(
                      'Do MMMM YYYY hh:mm A'
                    )}`}
                  </Typography>
                </Box>
                <Box
                  justifyContent='flex-end'
                  display='flex'
                  flexGrow={1}
                  alignItems='center'
                >
                  <Box px={1}>
                    <Tooltip title='' id='chief_tool_del_img'>
                      <IconButton
                        aria-label='collapse_order_type'
                        size='small'
                        onClick={() => {
                          setCollapsed(!collapsed)
                        }}
                      >
                        {collapsed && <ArrowDropUpOutlined />}
                        {!collapsed && <ArrowDropDownOutlined />}
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
              <Collapse
                in={collapsed}
                style={{
                  width: '100%',
                }}
              >
                <Box
                  border={2}
                  style={{
                    borderRadius: '8px',
                    borderColor: '#F8F8F8',
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#F8F8F8',
                  }}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    height={30}
                    alignItems='center'
                  >
                    <Grid
                      container
                      direction='row'
                      style={{
                        height: '100%',
                        margin: '0px',
                        padding: '0px',
                      }}
                    >
                      <Grid
                        item
                        xs={4}
                        style={{
                          height: '100%',
                          margin: '0px',
                          display: 'flex',
                          alignItems: 'center',
                          padding: '0px',
                          borderRight: `2px solid ${theme.palette.divider}`,
                          paddingLeft: '8px',
                        }}
                      >
                        <Typography variant='subtitle2' color='initial'>
                          Pathya
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        style={{
                          height: '100%',
                          margin: '0px',
                          display: 'flex',
                          alignItems: 'center',
                          padding: '0px',

                          paddingLeft: '8px',
                        }}
                      >
                        <OverflowTypography
                          text={item.pathyaText ?? ''}
                          typographyProps={{
                            variant: 'body2',
                            color: 'initial',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box
                  border={2}
                  style={{
                    borderRadius: '8px',
                    borderColor: '#F8F8F8',
                    width: '100%',
                    height: 'auto',
                  }}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    height={30}
                    alignItems='center'
                    style={{
                      backgroundColor: kDialogueBackground,
                    }}
                  >
                    <Grid
                      container
                      direction='row'
                      style={{
                        height: '100%',
                        margin: '0px',
                        padding: '0px',
                      }}
                    >
                      <Grid
                        item
                        xs={4}
                        style={{
                          height: '100%',
                          margin: '0px',
                          display: 'flex',
                          alignItems: 'center',
                          padding: '0px',
                          borderRight: `2px solid ${theme.palette.divider}`,
                          paddingLeft: '8px',
                        }}
                      >
                        <Typography variant='subtitle2' color='initial'>
                          Apathya
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        style={{
                          height: '100%',
                          margin: '0px',
                          display: 'flex',
                          alignItems: 'center',
                          padding: '0px',

                          paddingLeft: '8px',
                        }}
                      >
                        <OverflowTypography
                          text={item.apathyaText ?? ''}
                          typographyProps={{
                            variant: 'body2',
                            color: 'initial',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Collapse>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}
