/* eslint-disable prefer-destructuring */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import { PatientProfile } from 'models/patientProfile'
import { getNameOfPatient } from 'wello-web-components'
import {
  getFirstNameOfRelatedPerson,
  getLastNameOfRelatedPerson,
  getMiddleNameOfRelatedPerson,
} from '../fhirResourcesHelper'

export function getExpandedAppointmentFromBundle(
  responseSlots: R4.IBundle
): FhirPatientDetail[] {
  const convertedPatientData: FhirPatientDetail[] = []
  const appointments: any = {}
  const patientFinalArr: R4.IPatient[] = []
  const conditions: any = {}
  const basic: any = {}
  const encounters: any = {}
  const practitioners: any = {}
  const patients: any = {}
  const medications: any = {}
  const tasks: any = {}
  const practitionerRoles: any = {}
  const fatherResource: any = {}
  const husbandResource: any = {}
  const anyOtherRelation: any = {}
  const group: any = {}
  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry

        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Appointment':
                  appointments[value.resource.id] =
                    value.resource as R4.IAppointment
                  break
                case 'RelatedPerson':
                  if (value.resource.relationship) {
                    const { coding } = value.resource.relationship[0]
                    if (coding) {
                      const code = coding[0].code
                      if (code === 'FTH') {
                        fatherResource[value.resource.id] =
                          value.resource as R4.IRelatedPerson
                      } else if (code === 'HUSB') {
                        husbandResource[value.resource.id] =
                          value.resource as R4.IRelatedPerson
                      } else {
                        anyOtherRelation[value.resource.id] =
                          value.resource as R4.IRelatedPerson
                      }
                    }
                  }
                  break
                case 'Condition':
                  conditions[value.resource.id] =
                    value.resource as R4.ICondition
                  break

                case 'Group':
                  group[value.resource.id] = value.resource as R4.IGroup
                  break
                case 'Task':
                  tasks[value.resource.id] = value.resource as R4.ITask
                  break
                case 'Practitioner':
                  practitioners[value.resource.id] =
                    value.resource as R4.IPractitioner
                  break
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break
                case 'Encounter':
                  encounters[
                    value.resource.appointment?.[0].reference?.split('/')[1] ??
                      ''
                  ] = value.resource as R4.IEncounter
                  break
                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  patientFinalArr.push(value.resource as R4.IPatient)
                  break
                case 'Medication':
                  medications[value.resource.id] =
                    value.resource as R4.IMedication
                  break

                default:
                  break
              }
            }
          }
        })

        for (const key in patients) {
          if (key) {
            const conditionsData: string[] = []
            const appointmentDate: string = ''
            const patient: R4.IPatient = patients[key] as R4.IPatient
            let isPrimary: boolean = false

            const fatherResourceArray: R4.IRelatedPerson[] = []
            const husbandResourceArray: R4.IRelatedPerson[] = []
            const anyOtherRelationArray: R4.IRelatedPerson[] = []

            for (const husbandKey in husbandResource) {
              if (husbandKey) {
                const relatedPerson: R4.IRelatedPerson = husbandResource[
                  husbandKey
                ] as R4.IRelatedPerson
                if (relatedPerson) {
                  if (relatedPerson.patient.reference) {
                    const ref = relatedPerson.patient.reference.split('/')[1]
                    if (ref === patient.id) {
                      husbandResourceArray.push(relatedPerson)
                    }
                  }
                }
              }
            }

            for (const grKey in group) {
              if (grKey) {
                const groupData: R4.IGroup = group[grKey] as R4.IGroup
                if (groupData) {
                  if (
                    groupData.characteristic &&
                    groupData.characteristic.length > 0
                  ) {
                    const ref = groupData.characteristic[0].valueReference
                      ? groupData.characteristic[0].valueReference.reference
                        ? groupData.characteristic[0].valueReference.reference.split(
                            '/'
                          )[1]
                        : ''
                      : ''
                    if (ref.length > 0) {
                      if (ref === patient.id) {
                        isPrimary = true
                      }
                    }
                  }
                }
              }
            }

            for (const anyKey in anyOtherRelation) {
              if (anyKey) {
                const relatedPersonAny: R4.IRelatedPerson = anyOtherRelation[
                  anyKey
                ] as R4.IRelatedPerson
                if (relatedPersonAny) {
                  if (relatedPersonAny.patient.reference) {
                    const ref = relatedPersonAny.patient.reference.split('/')[1]
                    if (ref === patient.id) {
                      anyOtherRelationArray.push(relatedPersonAny)
                    }
                  }
                }
              }
            }
            const status: boolean = false

            // for (const taskEky in tasks) {
            //   if (taskEky) {
            //     const taskData: R4.ITask = tasks[taskEky] as R4.ITask
            //     if (taskData) {
            //       const statusData: string = taskData.status ?? ''
            //       if (statusData.length > 0 && statusData === 'requested') {
            //         status = true
            //       }
            //     }
            //   }
            // }

            for (const fathersKey in fatherResource) {
              if (fathersKey) {
                const relatedPersonFather: R4.IRelatedPerson = fatherResource[
                  fathersKey
                ] as R4.IRelatedPerson
                if (relatedPersonFather) {
                  if (relatedPersonFather.patient.reference) {
                    const refData =
                      relatedPersonFather.patient.reference.split('/')[1]
                    if (refData === patient.id) {
                      fatherResourceArray.push(relatedPersonFather)
                    }
                  }
                }
              }
            }

            convertedPatientData.push({
              patientName: getNameOfPatient(patient) ?? '',
              status,
              patient,
              appointment: appointmentDate,
              condition: conditionsData.join(' | '),
              relatedPersonFather:
                fatherResourceArray.length > 0
                  ? fatherResourceArray[0]
                  : undefined,
              relatedPersonHusband:
                husbandResourceArray.length > 0
                  ? husbandResourceArray[0]
                  : undefined,
              anyOtherRelation,
              isPrimary,
              patientCreatedDate: '',
            })
          }
        }
      }
    }
  }

  return convertedPatientData
}

export function getRelativesData(
  relativeResponse: R4.IBundle,
  patDetails: FhirPatientDetail
): FhirPatientDetail {
  const convertedPatientData: FhirPatientDetail = patDetails
  const fatherResource: R4.IRelatedPerson[] = []
  const husbandResource: R4.IRelatedPerson[] = []

  if (relativeResponse.total) {
    if (relativeResponse.total > 0) {
      if (relativeResponse.entry) {
        const entries: R4.IBundle_Entry[] = relativeResponse.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'RelatedPerson':
                  if (value.resource.relationship) {
                    const { coding } = value.resource.relationship[0]
                    if (coding) {
                      const code = coding[0].code
                      if (code === 'FTH') {
                        fatherResource.push(value.resource as R4.IRelatedPerson)
                      } else if (code === 'HUSB') {
                        husbandResource.push(
                          value.resource as R4.IRelatedPerson
                        )
                      }
                    }
                  }
                  break
                default:
                  break
              }
            }
          }
        })
        if (fatherResource.length > 0)
          convertedPatientData.relatedPersonFather = fatherResource[0]
        if (husbandResource.length)
          convertedPatientData.relatedPersonHusband = husbandResource[0]
      }
    }
  }
  return convertedPatientData
}

export function getProfileData(
  relativeResponse: R4.IBundle,
  patDetails: PatientProfile
): PatientProfile {
  const convertedPatientData: PatientProfile = patDetails
  const fatherResource: R4.IRelatedPerson[] = []
  const husbandResource: R4.IRelatedPerson[] = []

  if (relativeResponse.total) {
    if (relativeResponse.total > 0) {
      if (relativeResponse.entry) {
        const entries: R4.IBundle_Entry[] = relativeResponse.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'RelatedPerson':
                  if (value.resource.relationship) {
                    const { coding } = value.resource.relationship[0]
                    if (coding) {
                      const code = coding[0].code
                      if (code === 'FTH') {
                        fatherResource.push(value.resource as R4.IRelatedPerson)
                      } else if (code === 'HUSB') {
                        husbandResource.push(
                          value.resource as R4.IRelatedPerson
                        )
                      }
                    }
                  }
                  break
                default:
                  break
              }
            }
          }
        })
        if (fatherResource.length > 0)
          convertedPatientData.fathersName = ` ${getFirstNameOfRelatedPerson(
            fatherResource[0]
          )} ${getMiddleNameOfRelatedPerson(fatherResource[0])} 
          ${getLastNameOfRelatedPerson(fatherResource[0])}`
        if (husbandResource.length)
          convertedPatientData.husbandName = ` ${getFirstNameOfRelatedPerson(
            husbandResource[0]
          )} ${getMiddleNameOfRelatedPerson(husbandResource[0])} 
          ${getLastNameOfRelatedPerson(husbandResource[0])}`
      }
    }
  }
  return convertedPatientData
}
