import { Box, MenuItem, TextField, TextFieldProps } from '@material-ui/core'
import React, { useState } from 'react'
import { WelloFormItemLabel } from 'wello-web-components'

export const stateList = [
  { id: 'Andaman and Nicobar Islands', title: 'Andaman and Nicobar Islands' },
  { id: 'Andhra Pradesh', title: 'Andhra Pradesh' },
  { id: 'Chandigarh', title: 'Chandigarh' },
  { id: 'Assam', title: 'Assam' },
  { id: 'Bihar', title: 'Bihar' },
  { id: 'Arunachal Pradesh', title: 'Arunachal Pradesh' },
  { id: 'Chhattisgarh', title: 'Chhattisgarh' },
  { id: 'Dadra and Nagar Haveli', title: 'Dadra and Nagar Haveli' },
  {
    id: 'Dadra and Nagar Haveli and Daman and Diu',
    title: 'Dadra and Nagar Haveli and Daman and Diu',
  },
  { id: 'Manipur', title: 'Manipur' },
  { id: 'Maharashtra', title: 'Maharashtra' },
  { id: 'Madhya Pradesh', title: 'Madhya Pradesh' },
  { id: 'Lakshadweep', title: 'Lakshadweep' },
  { id: 'Kerala', title: 'Kerala' },
  { id: 'Karnataka', title: 'Karnataka' },
  { id: 'Jharkhand', title: 'Jharkhand' },
  { id: 'Jammu & Kashmir', title: 'Jammu & Kashmir' },
  { id: 'Himachal Pradesh', title: 'Himachal Pradesh' },
  { id: 'Haryana', title: 'Haryana' },
  { id: 'Gujarat', title: 'Gujarat' },
  { id: 'Goa', title: 'Goa' },
  { id: 'Delhi', title: 'Delhi' },
  { id: 'Odisha', title: 'Odisha' },
  { id: 'Nagaland', title: 'Nagaland' },
  { id: 'Mizoram', title: 'Mizoram' },
  { id: 'Meghalaya', title: 'Meghalaya' },
  { id: 'West Bengal', title: 'West Bengal' },
  { id: 'Uttaranchal', title: 'Uttaranchal' },
  { id: 'Uttar Pradesh', title: 'Uttar Pradesh' },
  { id: 'Tripura', title: 'Tripura' },
  { id: 'Tamil Nadu', title: 'Tamil Nadu' },
  { id: 'Sikkim', title: 'Sikkim' },
  { id: 'Rajasthan', title: 'Rajasthan' },
  { id: 'Punjab', title: 'Punjab' },
  { id: 'Uttarakhand', title: 'Uttarakhand' },
  { id: 'Puducherry', title: 'Puducherry' },
].sort((a, b) => (a.title! > b.title! ? 1 : b.title! > a.title! ? -1 : 0))
interface WelloOccupationProps {
  title: string
  textFieldProps: TextFieldProps
  onChanged: (selectedOccupation: any) => void
  preSelectedOccupation?: any
  displayDoneLabel?: boolean
}

export const WelloCountrySelector: React.FunctionComponent<WelloOccupationProps> =
  (props: WelloOccupationProps) => {
    const [selectedOccupation, setSelectedOccupation] = useState<any>(
      props.preSelectedOccupation ?? { id: '', title: 'Select State' }
    )

    return (
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <WelloFormItemLabel
          title={props.title}
          displayDoneLabel={props.displayDoneLabel}
        />
        <Box>
          <TextField
            variant='outlined'
            fullWidth
            size='small'
            required={false}
            select
            value={selectedOccupation?.id}
            label={selectedOccupation?.id === '' ? 'Select State' : ''}
            onChange={(val) => {
              const selectedOccu: any = stateList.find(
                (type) => type.id === val.target.value
              )
              setSelectedOccupation(selectedOccu ?? { id: '', title: 'Select' })
              props.onChanged(selectedOccu)
            }}
            {...props.textFieldProps}
          >
            {stateList.map((option) => (
              <MenuItem
                id={option.id}
                key={option.id}
                value={option.id}
                selected={selectedOccupation?.id === option.id}
                disabled={option?.id === ''}
              >
                {option.title}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
    )
  }
