import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import {
  LabObsData,
  ObsDataTable,
  ObsDataTableResource,
  ObsList,
} from 'models/labObsData'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCodeListFromBundleObs,
  getObServationDataTable,
  getObServationDataTableForNewTest,
  getObServationDisplayData,
} from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { logger } from 'utils/logger'
import { MasterObservationSearchStatus } from './masterObsSearchStatus'

const initialState: MasterObservationSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const masterObsFinderSlice = createSlice({
  name: 'masterObsFinderSlice',
  initialState,
  reducers: {
    searchingDoctorDetails(
      state,
      action: PayloadAction<MasterObservationSearchStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.codeList = undefined
    },

    searchResults(state, action: PayloadAction<MasterObservationSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.codeList = action.payload.codeList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<MasterObservationSearchStatus>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.codeList = undefined
    },

    errorWhileSearching(
      state,
      action: PayloadAction<MasterObservationSearchStatus>
    ) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.codeList = undefined
    },
    resetState(state, action: PayloadAction<MasterObservationSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.codeList = undefined
    },
  },
})

export const searchObservationsMaster =
  (dataLength: number, searchString?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: MasterObservationSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(masterObsFinderSlice.actions.searchingDoctorDetails(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      let response: any
      response = await fhirClient.doGetResource(
        '/ObservationDefinition?_count=300',
        ''
      )

      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const labOfferingsResponse: R4.IBundle = relatedFhirDecodeRes.right
        if (labOfferingsResponse.total) {
          if (labOfferingsResponse.total > 0) {
            if (labOfferingsResponse.entry) {
              const obsData: ObsDataTableResource[] =
                getObServationDataTableForNewTest(
                  labOfferingsResponse,
                  false,
                  searchString
                )

              obsData.sort((a, b) =>
                a.obsName.toLowerCase() < b.obsName.toLowerCase()
                  ? -1
                  : Number(a.obsName.toLowerCase() > b.obsName.toLowerCase())
              )

              let finalList: ObsDataTableResource[] = []
              for (let i = 0; i < obsData.length; i++) {
                if (searchString) {
                  if (
                    obsData[i].obsName
                      .toLowerCase()
                      .includes(searchString.toLowerCase())
                  ) {
                    const data = {
                      obsInitial: obsData[i].obsInitial,
                      obsName: obsData[i].obsName,
                      obsCode: obsData[i].obsCode,
                      permittedDataType: obsData[i].permittedDataType,
                      multipleResultsAllowed: obsData[i].multipleResultsAllowed,
                      unitCode: obsData[i].unitCode,
                      decimalPrecision: obsData[i].decimalPrecision,
                      high1: obsData[i].high1,
                      low1: obsData[i].low1,
                      highCode1: obsData[i].highCode1,
                      lowCode1: obsData[i].lowCode1,
                      gender1: obsData[i].gender1,
                      ageHigh1: obsData[i].ageHigh1,
                      ageLow1: obsData[i].ageLow1,
                      ageHighUnit1: obsData[i].ageHighUnit1,
                      ageLowUnit1: obsData[i].ageLowUnit1,

                      high2: obsData[i].high2,
                      low2: obsData[i].low2,
                      highCode2: obsData[i].highCode2,
                      lowCode2: obsData[i].lowCode2,
                      gender2: obsData[i].gender2,
                      ageHigh2: obsData[i].ageHigh2,
                      ageLow2: obsData[i].ageLow2,
                      ageHighUnit2: obsData[i].ageHighUnit2,
                      ageLowUnit2: obsData[i].ageLowUnit2,
                      active: true,
                      id: i,
                      interpretation: obsData[i].interpretation,
                      notes: obsData[i].notes,
                      comment: obsData[i].comment,
                    }
                    finalList.push(data)
                  }
                }
              }

              if (finalList.length === 0) {
                response = await fhirClient.doGetResourceForMasterData(
                  '/ObservationDefinition?_count=300',
                  ''
                )
                const relatedFhirDecodeResNext: E.Either<Errors, R4.IBundle> =
                  R4.RTTI_Bundle.decode(response)

                if (relatedFhirDecodeResNext._tag === 'Right') {
                  const labOfferingsResponseMaster: R4.IBundle =
                    relatedFhirDecodeResNext.right

                  if (labOfferingsResponseMaster.total) {
                    if (labOfferingsResponseMaster.total > 0) {
                      if (labOfferingsResponseMaster.entry) {
                        const obsDataMaster: ObsDataTableResource[] =
                          getObServationDataTableForNewTest(
                            labOfferingsResponseMaster,
                            false,
                            searchString
                          )

                        obsDataMaster.sort((a, b) =>
                          a.obsName.toLowerCase() < b.obsName.toLowerCase()
                            ? -1
                            : Number(
                                a.obsName.toLowerCase() >
                                  b.obsName.toLowerCase()
                              )
                        )
                        for (let i = 0; i < obsDataMaster.length; i++) {
                          if (searchString) {
                            if (
                              obsDataMaster[i].obsName
                                .toLowerCase()
                                .includes(searchString.toLowerCase())
                            ) {
                              const data = {
                                obsInitial: obsDataMaster[i].obsInitial,
                                obsName: obsDataMaster[i].obsName,
                                obsCode: obsDataMaster[i].obsCode,
                                permittedDataType:
                                  obsDataMaster[i].permittedDataType,
                                multipleResultsAllowed:
                                  obsDataMaster[i].multipleResultsAllowed,
                                unitCode: obsDataMaster[i].unitCode,
                                decimalPrecision:
                                  obsDataMaster[i].decimalPrecision,
                                high1: obsDataMaster[i].high1,
                                low1: obsDataMaster[i].low1,
                                highCode1: obsDataMaster[i].highCode1,
                                lowCode1: obsDataMaster[i].lowCode1,
                                gender1: obsDataMaster[i].gender1,
                                ageHigh1: obsDataMaster[i].ageHigh1,
                                ageLow1: obsDataMaster[i].ageLow1,
                                ageHighUnit1: obsDataMaster[i].ageHighUnit1,
                                ageLowUnit1: obsDataMaster[i].ageLowUnit1,

                                high2: obsDataMaster[i].high2,
                                low2: obsDataMaster[i].low2,
                                highCode2: obsDataMaster[i].highCode2,
                                lowCode2: obsDataMaster[i].lowCode2,
                                gender2: obsDataMaster[i].gender2,
                                ageHigh2: obsDataMaster[i].ageHigh2,
                                ageLow2: obsDataMaster[i].ageLow2,
                                ageHighUnit2: obsDataMaster[i].ageHighUnit2,
                                ageLowUnit2: obsDataMaster[i].ageLowUnit2,
                                active: true,
                                id: i,
                                interpretation: obsData[i].interpretation,
                                notes: obsData[i].notes,
                                comment: obsData[i].comment,
                              }
                              finalList.push(data)
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              finalList = finalList.filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.obsCode === value.obsCode)
              )
              state.codeList = finalList

              state.resultsAvailable = true
              state.searching = false
              state.error = false
              dispatch(masterObsFinderSlice.actions.searchResults(state))
              return
            }
            response = await fhirClient.doGetResourceForMasterData(
              '/ObservationDefinition?_count=300',
              ''
            )
            const relatedFhirDecodeResNext: E.Either<Errors, R4.IBundle> =
              R4.RTTI_Bundle.decode(response)

            if (relatedFhirDecodeResNext._tag === 'Right') {
              const labOfferingsResponseMaster: R4.IBundle =
                relatedFhirDecodeResNext.right
              if (labOfferingsResponseMaster.total) {
                if (labOfferingsResponseMaster.total > 0) {
                  if (labOfferingsResponseMaster.entry) {
                    const obsDataMaster: ObsDataTableResource[] =
                      getObServationDataTableForNewTest(
                        labOfferingsResponseMaster,
                        false,
                        searchString
                      )

                    obsDataMaster.sort((a, b) =>
                      a.obsName.toLowerCase() < b.obsName.toLowerCase()
                        ? -1
                        : Number(
                            a.obsName.toLowerCase() > b.obsName.toLowerCase()
                          )
                    )
                    const finalListData: ObsDataTableResource[] = []
                    for (let i = 0; i < obsDataMaster.length; i++) {
                      if (searchString) {
                        if (
                          obsDataMaster[i].obsName
                            .toLowerCase()
                            .includes(searchString.toLowerCase())
                        ) {
                          const data = {
                            obsInitial: obsDataMaster[i].obsInitial,
                            obsName: obsDataMaster[i].obsName,
                            obsCode: obsDataMaster[i].obsCode,
                            permittedDataType:
                              obsDataMaster[i].permittedDataType,
                            multipleResultsAllowed:
                              obsDataMaster[i].multipleResultsAllowed,
                            unitCode: obsDataMaster[i].unitCode,
                            decimalPrecision: obsDataMaster[i].decimalPrecision,
                            high1: obsDataMaster[i].high1,
                            low1: obsDataMaster[i].low1,
                            highCode1: obsDataMaster[i].highCode1,
                            lowCode1: obsDataMaster[i].lowCode1,
                            gender1: obsDataMaster[i].gender1,
                            ageHigh1: obsDataMaster[i].ageHigh1,
                            ageLow1: obsDataMaster[i].ageLow1,
                            ageHighUnit1: obsDataMaster[i].ageHighUnit1,
                            ageLowUnit1: obsDataMaster[i].ageLowUnit1,

                            high2: obsDataMaster[i].high2,
                            low2: obsDataMaster[i].low2,
                            highCode2: obsDataMaster[i].highCode2,
                            lowCode2: obsDataMaster[i].lowCode2,
                            gender2: obsDataMaster[i].gender2,
                            ageHigh2: obsDataMaster[i].ageHigh2,
                            ageLow2: obsDataMaster[i].ageLow2,
                            ageHighUnit2: obsDataMaster[i].ageHighUnit2,
                            ageLowUnit2: obsDataMaster[i].ageLowUnit2,
                            active: true,
                            id: i,
                            interpretation: obsDataMaster[i].interpretation,
                            notes: obsDataMaster[i].notes,
                            comment: obsDataMaster[i].comment,
                          }
                          finalListData.push(data)
                        }
                      }
                    }

                    state.codeList = finalListData

                    state.resultsAvailable = true
                    state.searching = false
                    state.error = false
                    dispatch(masterObsFinderSlice.actions.searchResults(state))
                    return
                  }
                  state.resultsAvailable = false
                  state.searching = false
                  state.error = false
                  state.noResultsAvailable = true
                  dispatch(
                    masterObsFinderSlice.actions.noDataFoundForSearch(state)
                  )
                  return
                }
              }
            }

            state.resultsAvailable = false
            state.searching = false
            state.error = false
            state.noResultsAvailable = true
            dispatch(masterObsFinderSlice.actions.noDataFoundForSearch(state))
            return
          }
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(masterObsFinderSlice.actions.noDataFoundForSearch(state))
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(masterObsFinderSlice.actions.errorWhileSearching(state))
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(masterObsFinderSlice.actions.errorWhileSearching(state))
    }
  }

export const resetObservationState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: MasterObservationSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      codeList: undefined,
    }
    dispatch(masterObsFinderSlice.actions.resetState(state))
  }

export function getPractitionerRoleObject(item: R4.IPractitionerRole) {
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    name: item.practitioner?.display ?? '',
    gender: '',
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: false,
  }
  return val
}

export default masterObsFinderSlice.reducer
