import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateWiseGraphData } from 'models/adminGraph/dateWiseGraphData'
import { GraphType } from 'models/adminGraph/graphType'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { MasterFhirClient } from 'services/masterFhirService'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import { getDateWiseDietPlansFromBundle } from 'utils/fhirResoureHelpers/ipdDietHelper'
import {
  getDataForDateWiseGraph,
  getDataForPaymentTypeWise,
  getDataForTypeWise,
} from 'utils/fhirResoureHelpers/orgAdminGraphHelper'
import { logger } from 'utils/logger'
import { ObjectivePartOfConsultation } from 'views/components/ipdconusltation/objectiveDetailsOfConsultation'
import { PaymentTypeWiseGraph } from './paytmentwiseGrapgSearchStatus'

const initialState: PaymentTypeWiseGraph = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
}

const paymentTypeWiseCollectionSlice = createSlice({
  name: 'paymentTypeWiseCollectionSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<PaymentTypeWiseGraph>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorReason = action.payload.errorReason
      state.graphType = action.payload.graphType
      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
  },
})

export const requestForPaymentTypeCollectionGraph =
  (date: Date, endDate?: Date, type?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PaymentTypeWiseGraph = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(paymentTypeWiseCollectionSlice.actions.updatedStatus(state))
    try {
      const fhirClient: MasterFhirClient = new MasterFhirClient()

      const endDateData =
        type && endDate
          ? moment(endDate).endOf('day').format('YYYY-MM-DD')
          : moment(endDate || date)
              .endOf('month')
              .format('YYYY-MM-DD')

      const searchParameters: any = {
        _count: 500,
        outcome: 'complete',
      }

      const startDate = type
        ? moment(date).endOf('day').format('YYYY-MM-DD')
        : moment(date).startOf('month').format('YYYY-MM-DD')

      const response: any = await fhirClient.doGetResource(
        `/PaymentReconciliation?payment-reconciliation-date=ge${moment(
          startDate
        ).format(
          'YYYY-MM-DD'
        )}&payment-reconciliation-date=le${endDateData}&_sort=payment-reconciliation-date`,
        searchParameters
      )

      const resp: R4.IBundle = response as R4.IBundle
      if (!resp) {
        state.errorWhileSearchingAppointments = true
        state.searchingAppointments = false

        dispatch(paymentTypeWiseCollectionSlice.actions.updatedStatus(state))
      } else {
        const appointmentResponse: R4.IBundle = resp
        const resultData1: GraphType[] = getDataForPaymentTypeWise(
          appointmentResponse,
          date
        )

        if (resultData1.length > 0) {
          state.resultsAvailable = true
          state.searchingAppointments = false
          state.noResultsAvailable = false
          state.errorReason = undefined
          state.graphType = resultData1

          state.errorWhileSearchingAppointments = false
          dispatch(paymentTypeWiseCollectionSlice.actions.updatedStatus(state))
        } else {
          const errorSearchDoctor: PaymentTypeWiseGraph = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(
            paymentTypeWiseCollectionSlice.actions.updatedStatus(
              errorSearchDoctor
            )
          )
        }
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: PaymentTypeWiseGraph = {
        searchingAppointments: false,
        errorWhileSearchingAppointments: true,
        resultsAvailable: false,
        errorReason: 'Error while creating appointment',
      }
      dispatch(
        paymentTypeWiseCollectionSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

export default paymentTypeWiseCollectionSlice.reducer
