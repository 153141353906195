import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Divider, makeStyles, Typography } from '@material-ui/core'
import { kBackgroundPaper } from 'configs/styles/muiThemes'
import _ from 'lodash'
import { MembershipDetails } from 'models/membershipDetails'
import moment from 'moment'
// import { kDialogueBackground } from 'configs/styles/muiThemes'
import React from 'react'
import {
  getNameFromHumanName,
  getTelecomFromContactPoints,
} from 'utils/fhirResourcesHelper'
import { getSymbolForCurrency } from 'utils/fhirResoureHelpers/currency_helper'
import {
  getMemberShipPaidAmount,
  getMemberShipPaidAmountCurrency,
  getMembershipReferenceId,
} from 'utils/membershipUtils'
import { MembershipPlanBeneficiaries } from './membershipBeneficiaries'

const useStyles = makeStyles({
  bigIndicator: {
    width: '10px',
  },
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      //   padding: 4,
    },
  },
})

interface MembershipSummaryProperties {
  usersData: MembershipDetails
}

export const MembershipSummaryPage: React.FC<MembershipSummaryProperties> = ({
  usersData,
}: MembershipSummaryProperties) => {
  const classes = useStyles()

  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      overflow='auto'
      width='100%'
      height='100%'
      p={2}
    >
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        width='100%'
      >
        <Box display='flex' flexDirection='column' overflow='auto' width='100%'>
          <Typography variant='h6' color='initial'>
            Membership Status
          </Typography>
          <Box
            display='flex'
            flexDirection='column'
            p={2}
            my={1}
            bgcolor={kBackgroundPaper}
            borderRadius={4}
          >
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              width='100%'
              py={1}
            >
              <Box
                display='flex'
                flexDirection='column'
                overflow='auto'
                width='100%'
              >
                <Typography variant='subtitle1' color='initial'>
                  Plan Status
                </Typography>
                <Typography variant='body1' color='initial'>
                  {_.capitalize(usersData.status)}
                </Typography>
              </Box>
            </Box>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              width='100%'
            >
              <Box
                display='flex'
                flexDirection='column'
                overflow='auto'
                width='100%'
                py={1}
              >
                <Typography variant='subtitle1' color='initial'>
                  Subscription Date
                </Typography>
                <Typography variant='body1' color='initial'>
                  {moment(usersData.startDate).format('DD MMM YYYY')}
                </Typography>
              </Box>
            </Box>
            {getMembershipReferenceId(usersData.coverageRaw) && (
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                alignItems='center'
                width='100%'
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  overflow='auto'
                  width='100%'
                  py={1}
                >
                  <Typography variant='subtitle1' color='initial'>
                    Reference
                  </Typography>
                  <Typography variant='body1' color='initial'>
                    {getMembershipReferenceId(usersData.coverageRaw)}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        width='100%'
      >
        <Box display='flex' flexDirection='column' overflow='auto' width='100%'>
          <Typography variant='h6' color='initial'>
            Current Status
          </Typography>
          <Box
            display='flex'
            flexDirection='column'
            my={1}
            bgcolor={kBackgroundPaper}
            borderRadius={4}
            width='100%'
          >
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              width='100%'
              px={2}
              py={1}
            >
              <Typography variant='body1' color='initial'>
                Amount Paid
              </Typography>
              <Typography variant='subtitle1' color='initial'>
                {`${getSymbolForCurrency(
                  getMemberShipPaidAmountCurrency(usersData.coverageRaw) ?? ''
                )} ${getMemberShipPaidAmount(usersData.coverageRaw)}`}
              </Typography>
            </Box>
            <Divider />
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              width='100%'
              px={2}
              py={1}
            >
              <Typography variant='body1' color='initial'>
                Remaining Credits
              </Typography>
              <Typography variant='subtitle1' color='initial'>
                {`${getSymbolForCurrency(
                  getMemberShipPaidAmountCurrency(usersData.coverageRaw) ?? ''
                )} ${usersData.remainingCredits}`}
              </Typography>
            </Box>
            <Divider />
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              width='100%'
              px={2}
              py={1}
            >
              <Typography variant='body1' color='initial'>
                Total Credits
              </Typography>
              <Typography variant='subtitle1' color='initial'>
                {`${getSymbolForCurrency(
                  getMemberShipPaidAmountCurrency(usersData.coverageRaw) ?? ''
                )} ${usersData.totalCredits}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <MembershipPlanBeneficiaries usersData={usersData.coverageRaw} />
    </Box>
  )
}
