import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirClinicalImpressionDetail } from 'models/fhirClinicalImpression'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import {
  getClinicalImpressionsOfAppointment,
  updateClinicalImpressions,
} from 'redux/consultation/assessmentDetailsSlice/assessmentDetailsSlice'
import { getClinicalImpressionsOfAppointmentForFollowUp } from 'redux/consultation/followUpAssesmentDetails/followUpAssessmentDetailsSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import {
  hasAllergies,
  hasConditions,
  hasSameSummary,
} from 'utils/fhirResoureHelpers/clinicalImpressionHelper'
import { logger } from 'utils/logger'
import { PhysicalExamination } from 'views/components/referralHistory.tsx/assessmentHistory/physicalExamination'
import { PhysicalExaminationFollowUp } from 'views/components/referralHistory.tsx/assessmentHistory/planHistory/physicalExamForFollowUp'
import { SOAPNumberedElement } from 'wello-web-components'
import { ConditionTile } from '../objective/tiles/conditionTile'
import { ConditionTileForFollowUp } from '../objective/tiles/conditionTileFollowUp'

interface AssessmentCaptureProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
}

export const AssessDetailsExam: React.FC<AssessmentCaptureProps> = ({
  fhirAppointmentDetails,
  split,
}: AssessmentCaptureProps) => {
  console.log('1455')
  return (
    <div style={{ paddingLeft: '4px', paddingTop: '4px' }}>
      <Grid container spacing={2} direction='column'>
        <Grid
          item
          style={{
            padding: '0px',
            margin: '0px',
          }}
          xs={split ? 12 : 10}
        >
          <Box display='flex' flexDirection='column' width='100%' px={0.5}>
            <Box
              borderRadius={2}
              style={{
                backgroundColor: 'lightGrey',
              }}
              display='flex'
              flexDirection='row'
              width='100%'
              height={40}
              px={2}
            >
              <Box
                justifyContent='flex-start'
                display='flex'
                alignItems='center'
              >
                <Typography
                  variant='subtitle1'
                  style={{
                    color: 'black',

                    fontWeight: 'bold',
                  }}
                >
                  Previous Appointment Signs on Examination
                </Typography>
              </Box>
            </Box>
            <Box display='flex' py={split ? 0.5 : 2.5}>
              <PhysicalExaminationFollowUp
                fhirAppointmentDetails={fhirAppointmentDetails}
                split={split}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* <AddFindingsHandler
        fhirAppointmentDetails={fhirAppointmentDetails}
        open={openAddFindingDialogue}
        onClose={() => {
          setOpenAddFindingDialogue(false)
        }}
        onFindingsAdded={(finding) => {
          // setOpenAddFindingDialogue(false);
          if (finding) {
            setFindings((newFindings) => [...newFindings, finding])
            // handleUpdate();
          }
        }}
      /> */}
    </div>
  )
}
