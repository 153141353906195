/* eslint-disable @typescript-eslint/no-shadow */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { getExpandedAppointmentFromBundle } from 'utils/common/patientDataTableHelper'
import { FhirPatientDetail } from 'models/fhirPatientDetail'
import { UmlClient } from 'services/umlsClient'
import { getUserCurrentRole } from 'services/userDetailsService'
import { UmlResponse } from 'models/umlResponse'
import { OutComeClient } from 'services/outComeClient'
import {
  getInterVention,
  getInterVentionSData,
  getInterVentionStringArr,
} from 'utils/graphData_handle/graphDataHelper'
import {
  InterVentionGroupData,
  InterVentions,
} from 'models/evidenceGraph/interventions'
import moment from 'moment'
import { PatientOutComeGraphSearchStatus } from './patientOutComeGraphSearchStatus'

const initialState: PatientOutComeGraphSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const patientOutcomeSearchSlice = createSlice({
  name: 'outCome',
  initialState,
  reducers: {
    searchingCondition(
      state,
      action: PayloadAction<PatientOutComeGraphSearchStatus>
    ) {},

    searchResults(
      state,
      action: PayloadAction<PatientOutComeGraphSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.graphList = action.payload.graphList
      state.outComeList = action.payload.outComeList
      state.interVentionStringList = action.payload.interVentionStringList
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<PatientOutComeGraphSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.graphList = action.payload.graphList
      state.outComeList = action.payload.outComeList
      state.interVentionStringList = action.payload.interVentionStringList
    },

    errorWhileSearching(
      state,
      action: PayloadAction<PatientOutComeGraphSearchStatus>
    ) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.graphList = action.payload.graphList
      state.outComeList = action.payload.outComeList
      state.interVentionStringList = action.payload.interVentionStringList
    },
    resetState(state, action: PayloadAction<PatientOutComeGraphSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.graphList = undefined
      state.outComeList = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: PatientOutComeGraphSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      graphList: undefined,
      outComeList: undefined,
    }
    dispatch(patientOutcomeSearchSlice.actions.resetState(state))
  }

export const searchingOutComes =
  (patientId: string, startDate: string, endDate: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: PatientOutComeGraphSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      patientOutcomeSearchSlice.actions.errorWhileSearching(errorSearchPatient)
    )
    try {
      const fhirClient: OutComeClient = new OutComeClient()
      const currentRole = getUserCurrentRole() ?? []
      const startDate = moment().startOf('day').toISOString()
      const endDate = moment().subtract(6, 'months').endOf('day').toISOString()

      const response: any = await fhirClient.doGetResource(
        `/patient/interventions_and_outcomes?patient_id=${patientId}&start_date=${endDate}&end_date=${startDate}`
      )

      let interVenArr: string[] = []
      let interventionDropDownArr: InterVentions[] = []
      let stringArr: InterVentionGroupData[] = []
      if (response.result) {
        if (response.result.metadata.features.length > 0) {
          interVenArr = getInterVention(response)
          stringArr = getInterVentionStringArr(interVenArr, response)
          //
          interventionDropDownArr = getInterVentionSData(interVenArr, response)
          if (interventionDropDownArr.length > 0) {
            const searchPatientResult: PatientOutComeGraphSearchStatus = {
              error: false,
              noResultsAvailable: false,
              resultsAvailable: true,
              searching: false,
              graphList: interventionDropDownArr,
              outComeList: response.result.data,
              interVentionStringList: stringArr,
            }
            dispatch(
              patientOutcomeSearchSlice.actions.searchResults(
                searchPatientResult
              )
            )
            return
          }
        }

        const searchPatientResult: PatientOutComeGraphSearchStatus = {
          error: false,
          noResultsAvailable: true,
          resultsAvailable: false,
          searching: false,
          graphList: [],
          outComeList: [],
        }
        dispatch(
          patientOutcomeSearchSlice.actions.searchResults(searchPatientResult)
        )
        return
      }

      const noSearchResults: PatientOutComeGraphSearchStatus = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(
        patientOutcomeSearchSlice.actions.noDataFoundForSearch(noSearchResults)
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: PatientOutComeGraphSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        patientOutcomeSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export const resetOutComeGraph = () => (dispatch: AppDispatch) => {
  dispatch(patientOutcomeSearchSlice.actions.resetState(initialState))
}

export default patientOutcomeSearchSlice.reducer
