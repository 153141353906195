import { Box, Grid, makeStyles, Typography, useTheme } from '@material-ui/core'
import { kPrimaryMain } from 'configs/styles/muiThemes'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  isSelected?: boolean
  textTitle?: string
  onClick?: () => void
}
const useStyles = makeStyles((theme) => ({
  menuStyle: {
    // height: 17,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: useTheme().palette.primary.main,
  },

  disableMenuStyle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: useTheme().palette.primary.main,
  },
}))

export const WelloTab: React.FC<IProps> = ({
  isSelected = true,
  textTitle = 'text',
  onClick,
}: IProps) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation(['common', 'patient'])
  const language = sessionStorage.getItem('lang')

  //   useEffect(() => {
  //     i18n.changeLanguage(language ?? '')
  //   }, [])

  return (
    <Grid
      container
      direction='column'
      justify='flex-end'
      alignItems='center'
      justifyContent='space-between'
      onClick={onClick}
      style={{
        height: '100%',
        borderWidth: 5,
        borderColor: 'blue',
      }}
    >
      <Grid item container direction='column'>
        <Grid
          container
          direction='row'
          alignItems='center'
          alignContent='space-between'
          justifyContent='space-between'
        >
          <Grid item>
            <Box id={textTitle} style={{ cursor: 'pointer' }} paddingY={0.5}>
              <Typography
                style={{
                  textTransform: 'uppercase',
                }}
                variant='subtitle2'
                color={isSelected ? 'primary' : 'textSecondary'}
                // style={{ cursor: 'pointer' }}
              >
                {t('labelCommon:' + `${textTitle}`).includes(
                  'TRANSACTION HISTORY'
                ) && (
                  <Box paddingLeft={2}>
                    {t('labelCommon:' + `${textTitle}`)}
                  </Box>
                )}
                {t('labelCommon:' + `${textTitle}`) !==
                  'TRANSACTION HISTORY' && (
                  <Box px={1}>{t('labelCommon:' + `${textTitle}`)}</Box>
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction='row'
        justify='center'
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid item>
          <Box>
            <div
              style={{
                height: 2,

                width: 30,
                borderRadius: 4,
                backgroundColor: isSelected ? kPrimaryMain : 'transparent',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
