import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Collapse,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { Alert } from '@material-ui/lab'
import { OperationalHoursOfDay } from 'models/operationalHoursOfDay'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ORG_LOCATION_SLOT_DURATION } from 'utils/constants/fhir_vs_ext_constants'
import { addExtensionToExtensionsOfUrl } from 'utils/fhirResourcesHelper'
import {
  defaultEndTime,
  defaultStartTime,
  GetDefaultBusinessTimings,
  GetDefaultBusinessTimingsFromLocation,
  GetFhirBusinessTimings,
  GetSingleLetterForFhirDay,
  preSelectedDays,
  updateDaySelection,
} from 'utils/fhirResoureHelpers/calender_helper'
import { DaySelector } from './microElements/DaySelector'
import { DayBusinessHour } from './microElements/day_business_hour'

interface Props {
  location: R4.ILocation
  isUpdate?: boolean
}

export const AddUnitsTimingDetailsSetUp: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const [openingHours, setOpeningHours] = useState<OperationalHoursOfDay[]>(
    props.isUpdate
      ? GetDefaultBusinessTimingsFromLocation(props.location) ??
          updateDaySelection(preSelectedDays, GetDefaultBusinessTimings())
      : updateDaySelection(preSelectedDays, GetDefaultBusinessTimings())
  )

  const [slotMins, setSlotMins] = useState<string>('20')

  const [displayWarning, setDisplayWarning] = useState<boolean>(true)

  const [commonChoice, setCommonChoice] = useState<OperationalHoursOfDay>({
    allDayOpen: false,
    available: true,
    dayOfWeek: R4.HealthcareService_AvailableTimeDaysOfWeekKind._fri,
    timings: [
      {
        availableEndTime: defaultEndTime,
        availableStartTime: defaultStartTime,
        id: 'sample',
      },
    ],
  })

  function onTimesUpdated(changedTime: OperationalHoursOfDay) {
    const tempValues: OperationalHoursOfDay[] = [...openingHours]
    console.log(changedTime)
    const index = tempValues.findIndex(
      (e) => e.dayOfWeek === changedTime.dayOfWeek
    )
    console.log(tempValues)

    if (index >= 0) {
      console.log(changedTime.timings)
      tempValues[index].timings = changedTime.timings
      tempValues[index].allDayOpen = changedTime.allDayOpen
    }

    setOpeningHours(tempValues)
  }

  function onCommonValuesChanged(changedTime: OperationalHoursOfDay) {
    const tempValues: OperationalHoursOfDay[] = [...openingHours]
    tempValues.forEach((e) => {
      e.allDayOpen = changedTime.allDayOpen
      e.timings = changedTime.timings
    })

    setOpeningHours(tempValues)
  }

  useEffect(() => {
    props.location.hoursOfOperation = GetFhirBusinessTimings(openingHours)
  }, [])

  useEffect(() => {
    props.location.hoursOfOperation = GetFhirBusinessTimings(openingHours)
  }, [openingHours, props.location])

  useEffect(() => {
    const selfExt: R4.IExtension = {
      url: ORG_LOCATION_SLOT_DURATION,
      valueString: slotMins,
    }
    props.location.extension = addExtensionToExtensionsOfUrl(
      props.location.extension,
      selfExt
    )
  }, [slotMins, props.location])

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      <Box
        display='flex'
        flexDirection='row'
        alignContent='center'
        justifyContent='center'
      >
        <Collapse in={displayWarning}>
          <Alert
            variant='outlined'
            severity='info'
            icon={<InfoIcon />}
            onClose={() => {
              setDisplayWarning(false)
            }}
            hidden={true}
          >
            {t('labelCommon:unit_timing_info')}
          </Alert>
        </Collapse>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        alignContent='center'
        justifyContent='center'
      >
        <DaySelector
          onSelectionChanges={(days) => {
            setOpeningHours(updateDaySelection(days, openingHours))
          }}
          preSelectedTab={
            props.isUpdate
              ? props.location.hoursOfOperation?.map((e) =>
                  GetSingleLetterForFhirDay(
                    e
                      .daysOfWeek?.[0] as R4.HealthcareService_AvailableTimeDaysOfWeekKind
                  )
                ) ?? preSelectedDays
              : preSelectedDays
          }
          menuLabel={t('labelCommon:business_days')}
        />
      </Box>

      <Box
        display='flex'
        flexGrow={1}
        flexDirection='row'
        alignContent='center'
        justifyContent='center'
        width='100%'
      >
        <Box display='flex' flexDirection='column' width='100%' px={3}>
          {/* <DayBusinessHour
            selectionOfDay={commonChoice}
            commonControl={true}
            onSelectionChanges={(eles) => {
              onCommonValuesChanged(eles)
            }}
          /> */}
        </Box>
      </Box>
      <Box
        display='flex'
        flexGrow={1}
        flexDirection='row'
        alignContent='center'
        justifyContent='center'
        width='100%'
      >
        <Box display='flex' flexDirection='column' width='100%' px={16} py={2}>
          <Divider />
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        alignContent='center'
        justifyContent='center'
      >
        <Box display='flex' flexDirection='column' width='100%'>
          {openingHours.map((e) => {
            if (e.available) {
              return (
                <DayBusinessHour
                  key={e.dayOfWeek}
                  selectionOfDay={e}
                  onSelectionChanges={(changedDetails) => {
                    onTimesUpdated(changedDetails)
                  }}
                />
              )
            }
            return <div key={e.dayOfWeek} />
          })}
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        alignContent='center'
        justifyContent='center'
        px={16}
      >
        <Box
          display='flex'
          flexDirection='column'
          width='100%'
          alignContent='center'
          justifyContent='center'
        >
          <Box py={0.5}>
            <Typography variant='h6'>Appointment Slot Duration</Typography>
          </Box>
          <Box py={0.5}>
            <Typography variant='body2'>
              The slot duration is used in determining the availability and
              daily slots of your doctors
            </Typography>
          </Box>
          <Box py={0.5}>
            <TextField
              size='small'
              fullWidth={false}
              variant='outlined'
              style={{ maxWidth: '120px' }}
              type='number'
              inputMode='numeric'
              onChange={(e) => {
                setSlotMins(e.target.value)
              }}
              value={slotMins}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>Mins</InputAdornment>
                ),
                inputProps: {
                  min: 5,
                  max: 60,
                },
              }}
              error={parseInt(slotMins, 10) < 5}
              helperText={
                parseInt(slotMins, 10) < 0 || parseInt(slotMins, 10) >= 60
                  ? 'Slot Duration should be in between 5 and 60 '
                  : ''
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
