import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CdsRecommendationsListIPD } from './cds_recommendationListIPD'

interface Props {
  open: boolean
  onClose: () => void
  patientDetails: R4.IPatient
  fhirPatientDetail: FhirActiveIPDDetailsForMedicalRole
  indexOfRecommendation?: number
}
export const CdsRecommendationsPopupIPD: React.FC<Props> = ({
  open,
  onClose,
  patientDetails,
  fhirPatientDetail,
  indexOfRecommendation,
}) => {
  const { t } = useTranslation(['common'])

  function resetForm() {}

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetForm()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>{t('labelCommon:carePlanPopUp')}</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
                resetForm()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
          padding: 0,
        }}
      >
        <CdsRecommendationsListIPD
          patient={patientDetails}
          fhirPatientDetail={fhirPatientDetail}
          preSelectedIndex={indexOfRecommendation}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
            resetForm()
          }}
          id='careplan_cancel'
          variant='outlined'
          disableElevation
        >
          {t('labelCommon:cancel')}
        </Button>
        {/*   <Button
          onClick={() => {}}
          variant='contained'
          color='primary'
          disableElevation
        >
          Create Order
        </Button> */}
      </DialogActions>
    </Dialog>
  )
}
