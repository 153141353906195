/* eslint-disable no-alert */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { ScheduleDetail } from 'models/fhirScheduleDetail'
import moment from 'moment'
import { getDateTime } from 'utils/dateUtil'

export function getScheduleFromSlotsRelations(
  responseSlots: R4.IBundle
): ScheduleDetail[] {
  const convertedSlots: ScheduleDetail[] = []
  const slotData: R4.ISlot[] = []
  const slots: any = {}
  const schedules: any = {}
  const practitioners: any = {}
  const practitionerRoles: any = {}
  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Slot':
                  // slotData.push(value.resource as R4.ISlot)
                  slots[value.resource.id] = value.resource as R4.ISlot
                  break
                case 'Schedule':
                  schedules[value.resource.id] = value.resource as R4.ISchedule
                  //
                  break
                case 'Practitioner':
                  practitioners[value.resource.id] =
                    value.resource as R4.IPractitioner
                  break
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in schedules) {
          if (schedules[key].serviceType !== undefined) {
            const title = []
            const slotForSchdeule: R4.ISlot[] = []
            for (let i = 0; i < schedules[key].serviceType.length; i++) {
              if (schedules[key].serviceType[i].coding[0].code === '556')
                title.push('In-Person')
              if (schedules[key].serviceType[i].coding[0].code === '538')
                title.push('Phone')

              if (
                schedules[key].serviceType[i].coding[0].code ===
                'video-counselling'
              )
                title.push('Video')

              if (
                schedules[key].serviceType[i].coding[0].code ===
                'Specimen-Collection'
              )
                title.push('In-Person')
            }
            if (schedules[key].comment !== undefined) {
              if (schedules[key].comment.includes('times')) {
                const occurString = schedules[key].comment.slice(-9).trim()
                const occurData = occurString.split(' ')
                convertedSlots.push({
                  id: schedules[key].id,
                  versionId: schedules[key].meta.versionId,
                  startTime: schedules[key].planningHorizon.start,
                  endTime: schedules[key].planningHorizon.end,
                  title: title.join(' . '),
                  repeat: schedules[key].comment,
                  schedule: schedules[key],
                  occur: parseInt(occurData[0], 10),
                  slotData: slotForSchdeule,
                })
              } else if (schedules[key].comment.includes('until')) {
                const dateString = schedules[key].comment.slice(-10).trim()
                const tempDate = moment(dateString, 'DD/MM/YYYY')

                convertedSlots.push({
                  id: schedules[key].id,
                  versionId: schedules[key].meta.versionId,
                  startTime: schedules[key].planningHorizon.start,
                  endTime: schedules[key].planningHorizon.end,
                  title: title.join(' . '),
                  repeat: schedules[key].comment,
                  schedule: schedules[key],
                  date: tempDate.toDate(),
                  slotData: slotForSchdeule,
                })
              } else {
                convertedSlots.push({
                  id: schedules[key].id,
                  versionId: schedules[key].meta.versionId,
                  startTime: schedules[key].planningHorizon.start,
                  endTime: schedules[key].planningHorizon.end,
                  title: title.join(' . '),
                  repeat: schedules[key].comment,
                  schedule: schedules[key],
                  slotData: slotForSchdeule,
                })
              }
            }
          }
        }
      }
    }
  }
  convertedSlots.sort(
    (a: ScheduleDetail, b: ScheduleDetail) =>
      getDateTime(a.startTime).getTime() - getDateTime(b.startTime).getTime()
  )
  return convertedSlots
}
