import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { orange, red } from '@material-ui/core/colors'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  lowChecked: {
    color: orange[200],
    '&$checked': {
      color: orange[200],
    },
  },
  mediumChecked: {
    color: orange[600],
    '&$checked': {
      color: orange[600],
    },
  },
  highChecked: {
    color: red[200],
    '&$checked': {
      color: red[200],
    },
  },
  dialogTitle: {
    paddingRight: '0px',
  },
}))

export interface PainLevelSelectorDialogProps {
  open: boolean
  onContinueActionClicked?: (
    value: 'none' | 'low' | 'moderate' | 'high'
  ) => void
  onCancelActionClicked?: () => void
  displayText?: string
  title?: string
  continueActionButtonText?: string
  cancelActionButtonText?: string
  preSelectedPainLevel?: 'none' | 'low' | 'moderate' | 'high'
}

export const PainLevelSelector: React.FC<PainLevelSelectorDialogProps> = ({
  open,
  onContinueActionClicked,
  onCancelActionClicked,
  displayText = 'Continue Delete',
  title = 'Select Pain Level',
  continueActionButtonText: doneButtonText = 'Delete',
  cancelActionButtonText: cancelButtonText = 'Cancel',
  preSelectedPainLevel,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      onClose={() => {
        if (onCancelActionClicked) onCancelActionClicked()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>{`Select pain level of ${t(
        `${title}`
      )}`}</DialogTitle>
      <DialogContent dividers={true}>
        <FormControl component='fieldset'>
          <RadioGroup
            row
            aria-label='position'
            name='position'
            defaultValue={preSelectedPainLevel}
            onChange={(e) => {
              if (onContinueActionClicked)
                onContinueActionClicked(
                  (e.target as HTMLInputElement).value as
                    | 'none'
                    | 'low'
                    | 'moderate'
                    | 'high'
                )
            }}
          >
            <FormControlLabel
              value='none'
              control={<Radio color='primary' />}
              label='No Pain'
              labelPlacement='bottom'
            />
            <FormControlLabel
              value='low'
              control={
                <Radio
                  color='primary'
                  className={classes.lowChecked}
                  id='lowRadio'
                />
              }
              label={
                <Typography
                  variant={
                    preSelectedPainLevel === 'low' ? 'subtitle1' : 'body1'
                  }
                  style={{
                    color: orange[200],
                  }}
                  id='low'
                >
                  Low
                </Typography>
              }
              style={{
                color: 'green',
              }}
              labelPlacement='bottom'
            />
            <FormControlLabel
              value='moderate'
              control={
                <Radio
                  color='primary'
                  className={classes.mediumChecked}
                  id='modRadio'
                />
              }
              label={
                <Typography
                  variant={
                    preSelectedPainLevel === 'high' ? 'subtitle1' : 'body1'
                  }
                  id='mod'
                  style={{
                    color: orange[600],
                  }}
                >
                  Moderate
                </Typography>
              }
              labelPlacement='bottom'
            />
            <FormControlLabel
              value='high'
              control={
                <Radio
                  color='primary'
                  className={classes.highChecked}
                  id='highRadio'
                />
              }
              label={
                <Typography
                  variant={
                    preSelectedPainLevel === 'high' ? 'subtitle1' : 'body1'
                  }
                  style={{
                    color: red[200],
                  }}
                  id='high'
                >
                  High
                </Typography>
              }
              labelPlacement='bottom'
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (onCancelActionClicked) onCancelActionClicked()
          }}
          variant='outlined'
          disableElevation
          id='bodySave'
        >
          <Typography variant='button'>{t(`${cancelButtonText}`)}</Typography>
        </Button>
        &nbsp;&nbsp;
      </DialogActions>
    </Dialog>
  )
}
