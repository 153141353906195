import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import React from 'react'
import {
  getNameFromHumanName,
  getProfilePicPractitioner,
  getTelecomFromContactPoints,
} from 'utils/fhirResourcesHelper'
import {
  getInputIdentifierValueByCodemOfInvitation,
  getOrderTime,
  getOrderTimeWithDate,
  getOrderTypeDisplayCode,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import { AddressTile } from 'views/components/patients/addressTile'

interface Props {
  labOrderDetail: FhirLabOrderDetail
  showAddress: boolean
  showDate?: boolean
  onTap?: () => void
  onChangeClicked?: () => void
}

export const OrderAppointmentDetails: React.FC<Props> = ({
  labOrderDetail,
  showAddress = false,
  showDate = false,
  onChangeClicked,
  onTap,
}: Props) => (
  <Box width='100%' border={1} borderRadius={4} borderColor='lightGrey'>
    <Card
      elevation={0}
      style={{
        padding: 0,
        margin: 0,
      }}
    >
      <CardContent
        style={{
          padding: 3,
        }}
      >
        <List
          id={`lab_order_appointment_${labOrderDetail.serviceRequest.id ?? ''}`}
          style={{ flexGrow: 1, padding: 0 }}
        >
          <ListItem style={{ flexGrow: 1, padding: 0 }}>
            {labOrderDetail.performerDetail?.practitionerRole && (
              <ListItemAvatar>
                <Avatar
                  alt={
                    getNameFromHumanName(
                      labOrderDetail.performerDetail?.practitioner?.name ?? []
                    ) ?? ''
                  }
                  src={
                    getProfilePicPractitioner(
                      labOrderDetail.performerDetail?.practitioner
                    ) ?? ''
                  }
                />
              </ListItemAvatar>
            )}
            <ListItemText
              color='primary'
              primary={
                <Box py={0.25}>
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    style={{ fontWeight: 'bolder', color: 'black' }}
                  >
                    {getOrderTypeDisplayCode(labOrderDetail)}
                  </Typography>
                </Box>
              }
              secondary={
                <Box display='flex' flexDirection='column'>
                  <Box flex='flex' flexDirection='row' py={0.25}>
                    <Typography
                      component='span'
                      variant='subtitle2'
                      color='textPrimary'
                    >
                      {showDate
                        ? getOrderTimeWithDate(labOrderDetail)
                        : getOrderTime(labOrderDetail)}
                    </Typography>
                  </Box>
                  {labOrderDetail.performerDetail?.practitionerRole && (
                    <Box flex='flex' flexDirection='row' py={0.25}>
                      <Typography
                        component='span'
                        variant='subtitle2'
                        color='textPrimary'
                      >
                        {`With ${getNameFromHumanName(
                          labOrderDetail.performerDetail?.practitioner?.name ??
                            []
                        )}` ?? ''}
                      </Typography>
                    </Box>
                  )}
                  {labOrderDetail.performerDetail?.practitionerRole && (
                    <Box flex='flex' flexDirection='row' py={0.25}>
                      <Typography
                        component='span'
                        variant='body2'
                        color='textPrimary'
                      >
                        {`Mob: ${getTelecomFromContactPoints(
                          labOrderDetail.performerDetail?.practitioner
                            ?.telecom ?? [],
                          R4.ContactPointSystemKind._phone
                        )}`}
                      </Typography>
                    </Box>
                  )}
                </Box>
              }
            />
          </ListItem>
        </List>
        {labOrderDetail.homeServiceTask && (
          <Divider
            orientation='horizontal'
            variant='fullWidth'
            flexItem
            style={{ width: '100%', height: '1px' }}
            color='primary'
          />
        )}

        {labOrderDetail.homeServiceTask && (
          <AddressTile
            addresses={
              getInputIdentifierValueByCodemOfInvitation(
                labOrderDetail.homeServiceTask,
                'collection-address'
              )?.valueAddress ?? {}
            }
          />
        )}
      </CardContent>
    </Card>
  </Box>
)
