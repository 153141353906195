/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/react-in-jsx-scope */
import { Box } from '@material-ui/core'
import { CohortDataStage } from 'models/chartProps'
import React, { useState } from 'react'

type VisibleStageSelectProps = {
  label: string
  name: string
  stages: any
  visibleStages: string[]
  handleStageChange: any
}

export const VisibleStageSelect: React.FC<VisibleStageSelectProps> = ({
  label,
  name,
  stages,
  visibleStages,
  handleStageChange,
}: VisibleStageSelectProps) => {
  console.log('455')

  return (
    <Box width='100%' flexDirection='column' display='flex'>
      <label htmlFor={name}>{label}</label>
      <select
        multiple
        name={name}
        onChange={handleStageChange}
        value={visibleStages}
      >
        {Object.keys(stages).map((key: string, i: number) => {
          const value: CohortDataStage = stages[key][0] as CohortDataStage
          console.log(value)
          if (key && value) {
            return (
              <option value={key} key={key}>
                {String(key)}
              </option>
            )
          }
        })}
      </select>
    </Box>
  )
}
