import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPhysiotherapist } from 'redux/management/physiotherapyList/physiotherapyTableSearchSlice'
import { RootState } from 'redux/rootReducer'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { PhysiotherapyDataTable } from './physiotherapyDataTable'
// import { AddTherapy } from './addTherapy';

export interface IUnitAdmin {
  onTap?: () => void
  onAdd?: () => void
}
export const PhysiotherapySearch: React.FC<IUnitAdmin> = ({ onTap, onAdd }) => {
  const physiotherapySearchSlice = useSelector(
    (state: RootState) => state.physiotherapyTableSearchSlice
  )

  const dispatch = useDispatch()

  const { width } = useWindowDimensions()

  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  useEffect(() => {
    if (onAdd) onAdd()
    dispatch(getAllPhysiotherapist())
  }, [dispatch, onAdd])

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
            >
              {physiotherapySearchSlice.searchingTreatment && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>
            <Box
              display='flex'
              flexGrow={4}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
              overflow='auto'
              style={{
                backgroundColor: '#ececec',
              }}
            >
              {physiotherapySearchSlice.noResultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography
                    variant='subtitle1'
                    color='initial'
                    align='center'
                  >
                    No User Data Available.
                  </Typography>
                </Box>
              )}
              {physiotherapySearchSlice.errorWhileSearchingTreatment && (
                <Box
                  //   display='flex'
                  //   flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                  paddingLeft={50}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container justifyContent='center'>
                        <Typography
                          variant='subtitle1'
                          color='error'
                          align='center'
                        >
                          Error while searching Physiotherapies. Please try
                          again
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {physiotherapySearchSlice.resultsAvailable && (
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height='100%'
                  overflow='auto'
                  // flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexGrow={1}
                    overflow='auto'
                    height='100%'
                    //  paddingTop={0.2}
                  >
                    <PhysiotherapyDataTable
                      usersData={
                        physiotherapySearchSlice.therapyListWithPrice
                          ? physiotherapySearchSlice.therapyListWithPrice
                          : []
                      }
                    />
                  </Box>
                </Box>
              )}
            </Box>

            {/* {showPopup && <AddTherapy
              open={showPopup}
              onCancelClick={() => {
              setShowPopup(false)
            }}
        />}          */}
          </Box>
        </Box>
      </Paper>
    </div>
  )
}
