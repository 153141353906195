import MaterialTable, { MTableToolbar } from '@material-table/core'
import {
  Box,
  Button,
  makeStyles,
  TablePagination,
  Typography,
  useTheme,
  Paper,
} from '@material-ui/core'
import {
  kBackgroundDefault,
  kDialogueBackground,
} from 'configs/styles/muiThemes'
import _ from 'lodash'
import { LabReferralDetails } from 'models/labReferralDetails'
// import { kDialogueBackground } from 'configs/styles/muiThemes'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isCurrentUnitIsLab } from 'services/userDetailsService'
import useWindowDimensions from 'utils/hooks/useDimensionHook'

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      //   padding: 4,
    },
  },
})

interface ReferralDataTableProperties {
  usersData: LabReferralDetails[]
  onReferralSelected?: (task: LabReferralDetails) => void
  displayOnlyName?: boolean
  selectedReferral?: LabReferralDetails
}

export const ReferralDataTable: React.FC<ReferralDataTableProperties> = ({
  usersData,
  onReferralSelected,
  displayOnlyName,
  selectedReferral,
}: ReferralDataTableProperties) => {
  const [data, setData] = useState([...usersData])

  const classes = useStyles()
  const [selectedRowId, setSelectedRowId] = React.useState('')
  const [selectedRow, setSelectedRow] = React.useState(false)
  const { height, width } = useWindowDimensions()
  const { t } = useTranslation()

  const tableRef = React.useRef()

  const paginationHeight = 56
  //

  const handleDarkModeChange = (event: any, rowData: any) => {
    if (onReferralSelected) onReferralSelected(rowData)
  }

  const columns = [
    {
      title: (
        <Box display='flex' flexDirection='row' justifyContent='flex-start'>
          <Typography variant='subtitle1' color='primary'>
            Lab Name
          </Typography>
        </Box>
      ),
      field: 'name',
      width: '40%',
      customSort: (a: any, b: any) =>
        a.name > b.packageName ? 1 : b.name > a.name ? -1 : 0,

      render: (rowData: LabReferralDetails) => (
        <Box display='flex' flexDirection='row' justifyContent='flex-start'>
          <Typography variant='subtitle2' color='initial' noWrap>
            {rowData.name}
          </Typography>
        </Box>
      ),
    },
    {
      title: (
        <Box display='flex' flexDirection='row' justifyContent='flex-start'>
          <Typography variant='subtitle1' color='primary'>
            Status
          </Typography>
        </Box>
      ),
      field: 'status',

      width: '20%',
      render: (rowData: LabReferralDetails) => (
        <Box display='flex' flexDirection='row' justifyContent='flex-start'>
          <Typography variant='subtitle2' color='initial'>
            {_.capitalize(rowData.status)}
          </Typography>
          {/* )} */}
        </Box>
      ),
    },
    {
      title: (
        <Box display='flex' flexDirection='row' justifyContent='flex-start'>
          <Typography variant='subtitle1' color='primary'>
            Date
          </Typography>
        </Box>
      ),
      field: 'status',

      width: '20%',
      render: (rowData: LabReferralDetails) => (
        <Box display='flex' flexDirection='row' justifyContent='flex-start'>
          <Typography variant='subtitle2' color='initial'>
            {rowData.date}
          </Typography>
          {/* )} */}
        </Box>
      ),
    },
  ]

  const labColumnsData = [
    {
      title: (
        <Box display='flex' flexDirection='row' justifyContent='flex-start'>
          <Typography variant='subtitle1' color='primary'>
            Referred From
          </Typography>
        </Box>
      ),
      field: 'name',
      width: '25%',

      render: (rowData: LabReferralDetails) => (
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
          paddingLeft={0.2}
        >
          <Typography variant='subtitle2' noWrap>
            {rowData.clinicName}
          </Typography>
        </Box>
      ),
    },
    {
      title: (
        <Box display='flex' flexDirection='row' justifyContent='flex-start'>
          <Typography variant='subtitle1' color='primary'>
            Status
          </Typography>
        </Box>
      ),
      field: 'status',

      width: '25%',
      render: (rowData: LabReferralDetails) => (
        <Box display='flex' flexDirection='row' justifyContent='flex-start'>
          <Typography variant='subtitle2' color='initial'>
            {_.capitalize(rowData.status)}
          </Typography>
          {/* )} */}
        </Box>
      ),
    },
    {
      title: (
        <Box display='flex' flexDirection='row' justifyContent='flex-start'>
          <Typography variant='subtitle1' color='primary'>
            Invite Date
          </Typography>
        </Box>
      ),
      field: 'date',

      width: '25%',
      render: (rowData: LabReferralDetails) => (
        <Box display='flex' flexDirection='row' justifyContent='flex-start'>
          <Typography variant='subtitle2' color='initial'>
            {rowData.date}
          </Typography>
          {/* )} */}
        </Box>
      ),
    },

    {
      title: (
        <Box display='flex' flexDirection='row' justifyContent='flex-start' />
      ),
      field: 'date',

      render: (rowData: LabReferralDetails) => (
        <Box display='flex' flexDirection='row' justifyContent='flex-start' />
      ),
      width: '15%',
    },
  ]

  return (
    <Box width='100%'>
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                minHeight: '0px',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
          Container: (props) => <Paper {...props} elevation={0} />,
          Actions: (props) => (
            <Box width='100%' display='flex' flexDirection='row'>
              <Box>
                {props.data.status !== 'completed' && (
                  <Button
                    onClick={(event) => {
                      if (onReferralSelected) onReferralSelected(props.data)
                    }}
                    color='primary'
                    variant='contained'
                    style={{ textTransform: 'none', height: 30 }}
                    size='small'
                    fullWidth
                    disabled={props.data.status === 'completed'}
                  >
                    Accept Invite
                  </Button>
                )}
                {props.data.status === 'completed' && (
                  <Button
                    color='default'
                    variant='text'
                    style={{ textTransform: 'none', height: 30 }}
                    size='small'
                    fullWidth
                  >
                    Accepted
                  </Button>
                )}
              </Box>
            </Box>
          ),

          Pagination: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                display: 'flex',
                justifyContent: 'left',
                // padding: '0px',
              }}
            >
              <TablePagination
                {...props}
                labelRowsSelect={<div style={{ fontSize: 14 }}> rows</div>}
                SelectProps={{
                  style: {
                    fontSize: 14,
                    minHeight: '5px',
                    backgroundColor: useTheme().palette.background.default,
                  },
                }}
              />
            </div>
          ),
        }}
        actions={
          isCurrentUnitIsLab()
            ? [
                (rowData) => ({
                  icon: () => (
                    <Button
                      color='primary'
                      variant='contained'
                      style={{ textTransform: 'none' }}
                      size='small'
                      disabled={rowData.status === 'completed'}
                    >
                      Accept Invite
                    </Button>
                  ),

                  onClick: handleDarkModeChange,
                  disabled: rowData.status === 'completed',
                }),
              ]
            : []
        }
        columns={isCurrentUnitIsLab() ? labColumnsData : columns}
        data={data}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        onRowClick={(event, rowData) => {
          //   if (rowData) onUserSelected(rowData)
          //   setSelectedRow(true)
          //   setSelectedRowId(rowData?.id ?? '')
        }}
        options={{
          searchFieldStyle: { padding: 4 },
          toolbar: false,
          search: false,
          showTitle: false,
          padding: 'dense',
          searchFieldVariant: 'outlined',
          filtering: false,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30],
          //   columnsButton: true,
          maxBodyHeight: `${height - 169}px`,
          minBodyHeight: `${height - 240}px`,
          tableLayout: 'auto',
          sorting: true,
          thirdSortClick: false,
          headerStyle: {
            backgroundColor: useTheme().palette.background.default,
            color: '#FFF',
            width: 100,
            minHeight: '15px',
            maxHeight: '15px',
            position: 'sticky',
            alignContent: 'center',

            top: 0,
            // height: 20,
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          rowStyle: (_data: any, index: number, _level: number) =>
            index % 2
              ? { backgroundColor: kDialogueBackground, height: 50 }
              : { height: 50 },
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />
    </Box>
  )
}
