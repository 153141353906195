import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  getCodeOfSystemFromCodableConceptList,
  getDisplayOfSystemFromCodableConcept,
} from 'utils/fhirResourcesHelper'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getTimeOfDayDisplayForStatement,
  getTimeOfDayDisplayMeal,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import { getTestNameFromOrderDetails } from 'utils/fhirResoureHelpers/referralHelpers'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { PathyaApathyaDetails } from 'utils/sopaNotes/plan/pathyaApathyaHelpers'
import { ShowIngredients } from './ShowIngrenient'

interface MedicationTileProps {
  referrals: PathyaApathyaDetails[]
  type: string
  onDeleteClicked?: () => {}
  onEditCLicked?: () => {}
  prescription?: boolean
}

export const AaharaPrescription: React.FC<MedicationTileProps> = ({
  referrals,
  type,
  onDeleteClicked,
  onEditCLicked,
  prescription,
}: MedicationTileProps) => {
  const [openIngredient, setOpenIngredient] = useState(false)
  const [selectedMedication, setSelectedMedication] = useState<R4.ICoding>()
  const [selectedRxNorm, setSelectedRxNorm] = useState<string>()

  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
    >
      <Grid container direction='column'>
        <Grid container direction='row' id='vitals'>
          <Grid item xs={3}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={20}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    {type}
                  </Typography>
                </Box>
              </Box>
              {/* <Box display='flex' width='2%' paddingTop={0.2}>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      height: '30px',
  
                      width: '2px',
                    }}
                  />
                </Box> */}
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                flexGrow={1}
              >
                <Box
                  justifyContent='flex-start'
                  display='flex'
                  flexGrow
                  height={20}
                  alignItems='center'
                  px={1}
                >
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Description
                  </Typography>
                </Box>

                {/* <Divider
                        orientation='horizontal'
                        flexItem
                        style={{
                          height: '2px',
                        }}
                      /> */}
              </Box>
              {/* <Box display='flex' width='2%' paddingTop={0.2}>
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{
                        height: '30px',
    
                        width: '2px',
                      }}
                    />
                  </Box> */}
            </Box>
          </Grid>
        </Grid>
        {/* <Grid container direction='row' id='vitals'>
            <Grid item xs={12}>
              <Box width='100%' paddingLeft={0.2}>
                <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                />
              </Box>
            </Grid>
          </Grid> */}

        {/* ------------------------------------------------------------------------------- */}
        {referrals.map((e, index: number) => (
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            flexGrow={1}
            key={e.apathyaText}
          >
            <Grid container direction='row' id='vitals'>
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={20}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        Pathya
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.1}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',
  
                          width: '2px',
                        }}
                      />
                    </Box> */}
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={20}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {e.pathyaText ?? ''}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container direction='row' id='vitals'>
              <Grid item xs={3}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={20}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        Apathya
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box display='flex' width='2%' paddingTop={0.1}>
                      <Divider
                        orientation='vertical'
                        flexItem
                        style={{
                          height: '30px',
  
                          width: '2px',
                        }}
                      />
                    </Box> */}
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  flexGrow={1}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    flexGrow={1}
                  >
                    <Box
                      justifyContent='flex-start'
                      display='flex'
                      flexGrow
                      height={20}
                      alignItems='center'
                      px={1}
                    >
                      <Typography
                        color='initial'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,

                          fontWeight: 600,
                          lineHeight: 'normal',
                        }}
                      >
                        {e.apathyaText ?? ''}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Grid>
    </Box>
  )
}
