import {
  Box,
  Grid,
  Typography,
  useTheme,
  Tooltip,
  IconButton,
  Link,
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { Add, Done } from '@material-ui/icons'
import React from 'react'

interface NextStepsOfConsultationProps {
  planName: string
  subString: string
  onClicked?: () => void
  count?: number
  isEditAllowed?: boolean
  bottomWidget?: React.ReactNode
}

export const NextSplitAyurveda: React.FC<NextStepsOfConsultationProps> = ({
  planName,
  subString,
  count = 0,
  onClicked,
  isEditAllowed = true,
  bottomWidget,
}: NextStepsOfConsultationProps) => {
  const primary = useTheme().palette.grey
  const isSelected: boolean = count > 0

  return (
    <Box
      display='flex'
      //   borderRadius={4}
      borderColor='lightGrey'
      //   border={1}

      width='100%'
      flexDirection='column'
      flexGrow
    >
      <Box display='flex' flexDirection='row' width='100%' flexGrow>
        <Grid container style={{ padding: '0px', margin: '0px' }}>
          {/* <Grid
                item
                xs={1}
                style={{ alignSelf: 'center', padding: '0px', margin: '0px' }}
              >
                {!isSelected && (
                  <Add
                    style={{
                      color: isSelected ? 'white' : primary,
                      height: '12px',
                    }}
                  />
                )}
                {isSelected && (
                  <Done
                    style={{
                      color: isSelected ? 'white' : primary,
                    }}
                  />
                )}
              </Grid> */}
          <Grid item xs={4} style={{ alignSelf: 'center' }}>
            <Typography
              variant='subtitle2'
              style={{ color: '#4D4D4D', textTransform: 'uppercase' }}
            >
              {planName}
            </Typography>
          </Grid>
          {/* {count > 0 && (
              <Grid
                item
                xs={5}
                style={{ alignSelf: 'center', padding: '0px', margin: '0px' }}
              >
                <Typography style={{ color: isSelected ? 'white' : initial }}>
                  {subString}
                </Typography>
              </Grid>
            )} */}
          {count > 0 && (
            <Grid
              item
              xs={2}
              style={{ alignSelf: 'center', padding: '0px', margin: '0px' }}
            >
              <Typography style={{ color: isSelected ? 'white' : 'lightgray' }}>
                Change
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      {/* <Box
          display='flex'
          flexGrow
          flexDirection='row'
          justifyContent='flex-start'
          paddingTop={0.5}
          paddingBottom={1}
          // style={{
          //   backgroundColor: isSelected ? primary : 'white',
          //   cursor: 'pointer',
          // }}
        >
          <Typography
            variant='subtitle2'
            color='initial'
            style={{ fontSize: 13 }}
          >
            {' '}
            {planName}
          </Typography>
        </Box> */}

      {bottomWidget && (
        <Box display='flex' flexDirection='row' width='100%' flexGrow>
          {bottomWidget}
        </Box>
      )}
    </Box>
  )
}
