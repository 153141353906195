import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getNotesFromAllergy } from 'utils/fhirResoureHelpers/allergyHelpers'
import {
  getObservationResourceForUpdateUpdate,
  updateObservationDetails,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { WelloFormItemLabel } from 'wello-web-components'

const useStyles = makeStyles((theme) => ({
  noteTf: {
    minHeight: 50,
  },
}))

export interface UpdateObservationsProps {
  open: boolean
  observation?: R4.IObservation
  onObservationAdded?: () => void
  onClose?: () => void
  observationCategory?: string
}
export const UpdateObservationWithNotesForEncounter: React.FC<UpdateObservationsProps> =
  ({
    open,
    observation,
    onObservationAdded,
    onClose,

    observationCategory,
  }) => {
    const { t } = useTranslation(['common', 'condition'])

    const [addingStatus, setAddingStatus] = useState<NetworkRequestStatus>({
      initial: true,
    })

    const [selectedCondition, setSelectedCondition] = useState<R4.ICoding>()
    const [additionalNotes, setAdditionalNotes] = useState<string>(
      getNotesFromAllergy(observation!.note)?.join('\n') ?? ''
    )
    const classes = useStyles()
    function updateNutritionOrder() {
      setAddingStatus({
        requesting: true,
      })

      updateObservationDetails(
        getObservationResourceForUpdateUpdate(observation!, additionalNotes)
      ).then((e) => {
        if (e) {
          setAddingStatus({
            requestSuccess: true,
          })
          resetDetails()
        } else {
          setAddingStatus({
            requestError: true,
          })
          resetDetails()
        }
      })
    }

    function resetDetails() {
      setAdditionalNotes('')
    }

    useEffect(() => {
      if (addingStatus.requestSuccess) {
        resetDetails()
        if (onObservationAdded) {
          onObservationAdded()
        }
      }
    }, [addingStatus])

    return (
      <Dialog
        open={open}
        onClose={() => {
          if (onClose) onClose()
        }}
        maxWidth='lg'
        aria-labelledby={observationCategory}
      >
        <DialogTitle>Update</DialogTitle>
        <DialogContent>
          <Box width='100%' p={2} minWidth='450px'>
            <Grid container direction='column' spacing={0}>
              <Grid item>
                <Grid container direction='column' spacing={0}>
                  <Grid item>
                    <WelloFormItemLabel title='Finding' />
                    <Typography
                      variant='h6'
                      color='initial'
                      style={{
                        paddingLeft: '8px',
                      }}
                    >
                      {observation!.code!.coding![0].display}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container direction='column'>
                  <Grid item>
                    <WelloFormItemLabel title='Additional Notes' />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      multiline
                      placeholder='Add Notes'
                      disabled={addingStatus.requesting}
                      value={additionalNotes}
                      InputProps={{ classes: { input: classes.noteTf } }}
                      variant='outlined'
                      onChange={(event) => {
                        setAdditionalNotes(event.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {!addingStatus.requesting && (
            <Button
              onClick={() => {
                if (onClose) onClose()
              }}
              color='default'
            >
              Close
            </Button>
          )}
          {addingStatus.requesting && <CircularProgress size={25} />}
          {!addingStatus.requesting && (
            <Button
              variant='contained'
              color='primary'
              disabled={
                getNotesFromAllergy(observation!.note)?.join('\n') ===
                additionalNotes
              }
              onClick={() => {
                updateNutritionOrder()
              }}
            >
              Update
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
