import { R4 } from '@ahryman40k/ts-fhir-types'
import { DietDataModel } from 'models/dietData'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCodeOfSystemFromCodableConcept,
  getDefaultCodeOfSystemFromCodableConcept,
  getDisplayOfSystemFromCodableConcept,
  getExtensionValueOfUrl,
} from 'utils/fhirResourcesHelper'

export function getCatalogName(catalog: R4.ICatalogEntry): string {
  let returnName = ''
  const resCodeableConcept: R4.ICodeableConcept | undefined =
    getCatalogCodableConcept(
      catalog,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-hv-food-code-ext'
    )
  if (resCodeableConcept) {
    const res = getDisplayOfSystemFromCodableConcept(
      resCodeableConcept,
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-type-cs'
    )
    if (res) {
      returnName = res
    }
    returnName = getDisplayOfSystemFromCodableConcept(resCodeableConcept) ?? ''
  }

  const resExtra = getExtensionValueOfUrl(
    catalog.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-quantity-amount-ext'
  )

  if (resExtra && resExtra.valueQuantity) {
    returnName = `${returnName} (${resExtra.valueQuantity.value ?? ''} ${
      resExtra.valueQuantity.unit ?? ''
    })`
  }

  return returnName
}

export function getCatalogNameOrUndefined(catalog: R4.ICatalogEntry) {
  const resCodeableConcept: R4.ICodeableConcept | undefined =
    getCatalogCodableConcept(
      catalog,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-hv-food-code-ext'
    )
  if (resCodeableConcept) {
    const res = getDisplayOfSystemFromCodableConcept(
      resCodeableConcept,
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-type-cs'
    )
    if (res) {
      return res
    }
    return getDisplayOfSystemFromCodableConcept(resCodeableConcept) ?? ''
  }
  return undefined
}

export function getCatalogCode(catalog: R4.ICatalogEntry): string {
  const resCodeableConcept: R4.ICodeableConcept | undefined =
    getCatalogCodableConcept(
      catalog,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-hv-food-code-ext'
    )
  if (resCodeableConcept) {
    const res = getCodeOfSystemFromCodableConcept(
      resCodeableConcept,
      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-type-cs'
    )
    if (res && res.code) {
      return res.code
    }
    return getDefaultCodeOfSystemFromCodableConcept(resCodeableConcept) ?? ''
  }
  return ''
}

export function getCatalogCodableConcept(
  catalog: R4.ICatalogEntry,
  url: string
): R4.ICodeableConcept | undefined {
  if (catalog.extension && catalog.extension.length > 0) {
    const resIndex: number = catalog.extension.findIndex((e) => e.url === url)
    if (resIndex >= 0) {
      return catalog.extension[resIndex].valueCodeableConcept
    }
  }
  return undefined
}

export async function getCatalogEntries(
  type: string
): Promise<R4.ICatalogEntry[]> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirClient.doGetResourceCrossPartition(
    `/CatalogEntry?catalogwhen=${type}`
  )

  const resp: R4.IBundle = response as R4.IBundle

  const responses = resp.entry?.map(
    (e) => e.resource as any as R4.ICatalogEntry
  )
  return responses ?? []
}

export function getCatalogForTimeOfDay(
  baseCatalogue: R4.ICatalogEntry[],
  timeOfDay: string,
  isOrderableOnly?: boolean
) {
  const tempCatalogue: R4.ICatalogEntry[] = [...baseCatalogue]

  const resCatalogue = tempCatalogue.filter((e) => {
    const times = getCatalogTimeOfDay(e)
    if (times !== undefined && times.length > 0) {
      return times.includes(timeOfDay)
    }
    return false
  })
  if (isOrderableOnly) {
    return resCatalogue
      .sort((first, second) => {
        if ((getCatalogName(first) ?? '') < (getCatalogName(second) ?? '')) {
          return -1
        }
        if ((getCatalogName(first) ?? '') > (getCatalogName(second) ?? '')) {
          return 1
        }
        return 0
      })
      .filter((e) => e.orderable === true)
  }
  return resCatalogue.sort((first, second) => {
    if ((getCatalogName(first) ?? '') < (getCatalogName(second) ?? '')) {
      return -1
    }
    if ((getCatalogName(first) ?? '') > (getCatalogName(second) ?? '')) {
      return 1
    }
    return 0
  })
}

export function getCatalogTimeOfDay(
  catalog: R4.ICatalogEntry
): string[] | undefined {
  if (catalog.extension && catalog.extension.length > 0) {
    const extOfDay: R4.IExtension | undefined = getExtensionValueOfUrl(
      catalog.extension ?? [],
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-preferred-intake-time-ext'
    )
    if (extOfDay && extOfDay.valueTiming) {
      return extOfDay.valueTiming.repeat!.when
    }
  }
  return undefined
}

export function getCatalogNameFromId(
  baseCatalogue: R4.ICatalogEntry[],
  id: string
): string {
  const resCatalogue = baseCatalogue.findIndex((e) => e.id === id)
  if (resCatalogue > -1) {
    return getCatalogName(baseCatalogue[resCatalogue])
  }
  return ''
}

export function getCatalogFromId(
  baseCatalogue: R4.ICatalogEntry[],
  id: string
): R4.ICatalogEntry | undefined {
  const resCatalogue = baseCatalogue.findIndex((e) => e.id === id)
  if (resCatalogue > -1) {
    return baseCatalogue[resCatalogue]
  }
  return undefined
}

export function getCatalogDetailsFromIds(
  baseCatalogue: R4.ICatalogEntry[],
  ids: string[]
): string {
  const name: string[] = []
  ids.forEach((e) => {
    name.push(getCatalogNameFromId(baseCatalogue, e))
  })

  return name.join(',')
}

export function getCatalogList(
  baseCatalogue: R4.ICatalogEntry[],
  ids: string[]
): R4.ICatalogEntry[] {
  const name: R4.ICatalogEntry[] = []
  ids.forEach((e) => {
    const res = getCatalogFromId(baseCatalogue, e)
    if (res) name.push(res)
  })

  return name
}

export function getDietDataModel(entry: R4.ICatalogEntry): DietDataModel {
  const foodName = getExtensionValueOfUrl(
    entry.extension,
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-hv-food-code-ext'
  )

  const dietAllDetails: DietDataModel = {
    name: '',
    duration: '',
    allDuration: [],
    timeData: '',
    orderable: false,
  }

  dietAllDetails.name = foodName?.valueCodeableConcept?.text

  const time = getExtensionValueOfUrl(
    entry.extension,
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-preferred-intake-time-ext'
  )

  const allTime: Array<string> = time?.valueTiming?.repeat?.when
    ? time?.valueTiming?.repeat?.when ?? []
    : []

  const mapDurationToCodes: R4.ICoding[] = []

  const allTimings: Array<string> = allTime

  //
  dietAllDetails.durationCodes = mapDurationToCodes

  dietAllDetails.allDuration = allTimings

  dietAllDetails.catalogue = entry

  if (entry.orderable === true) dietAllDetails.status = 'Active'
  else dietAllDetails.status = 'Inactive'

  dietAllDetails.orderable = entry.orderable

  return dietAllDetails
}

export function getFhirTimingKinds(
  timingStrings: string[]
): R4.Timing_RepeatWhenKind[] {
  const dietTimings: R4.Timing_RepeatWhenKind[] = []

  timingStrings.map((e) => {
    if (e === 'MORN.early')
      dietTimings.push(R4.Timing_RepeatWhenKind._mornEarly)
    if (e === 'MORN') dietTimings.push(R4.Timing_RepeatWhenKind._morn)
    if (e === 'MORN.late') dietTimings.push(R4.Timing_RepeatWhenKind._mornLate)
    if (e === 'NOON') dietTimings.push(R4.Timing_RepeatWhenKind._noon)
    if (e === 'EVE') dietTimings.push(R4.Timing_RepeatWhenKind._eve)
    if (e === 'EVE.late') dietTimings.push(R4.Timing_RepeatWhenKind._eveLate)
    if (e === 'NIGHT') dietTimings.push(R4.Timing_RepeatWhenKind._night)
    if (e === 'PCV') dietTimings.push(R4.Timing_RepeatWhenKind._pcv)
  })

  return dietTimings
}
