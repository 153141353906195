/* eslint-disable react/no-array-index-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CardContent,
  CardActions,
  Typography,
  Card,
  Paper,
  CircularProgress,
  Grid,
} from '@material-ui/core'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
  PieChart,
  Pie,
  Cell,
  Label,
} from 'recharts'

import { CloseOutlined } from '@material-ui/icons'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { LabOfferingDetail } from 'models/labOfferDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestForDateWiseCollectionGraph } from 'redux/administration/graph/dateWiseGraph/dateWiseSearchGraphSearchSlice'
import {
  requestLabOrder,
  resetLabOrderCreationState,
} from 'redux/lab/order/createOrderSlice/createOrderSlice'
import { RootState } from 'redux/rootReducer'
import { availablePaymentOptions } from 'utils/constants'
import {
  getAddress,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getTelecomFromContactPoints,
} from 'utils/fhirResourcesHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { OrderAmountSummaryTile } from 'views/components/generalOrder/ordarAmountSummary'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { PatientTile } from 'views/components/patients/pateintTile'
import { PatientSearchByText } from 'views/components/patients/patientSearchByText'
import CustomizedDividers from 'views/components/toggle_selector'
import { WelloFormItemLabel } from 'wello-web-components'
import { GraphType } from 'models/adminGraph/graphType'
import { requestForServiceTypeCollectionGraph } from 'redux/administration/graph/ServiceTypeGraph/serviceWiseCollectionSlice'

interface Props {
  date: Date
}
export const PieGraphChart: React.FC<Props> = ({ date }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const serviceCollectionSearchSlice = useSelector(
    (state: RootState) => state.serviceWiseCollectionSlice
  )
  const colors = ['#8884d8', '#82ca9d', '#FFBB28']
  const data = [
    {
      name: 'IPD',
      amount: 5000,
    },
    {
      name: 'OPD',
      amount: 0,
    },
    {
      name: 'Consultation',
      amount: 0,
    },
  ]

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    return (
      <text
        x={x}
        y={y}
        fill='white'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'
        style={{ padding: percent > 0 ? 0 : 2, fontSize: 10 }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active) {
      return (
        <Box
          display='flex'
          flexDirection='column'
          className='custom-tooltip'
          style={{
            backgroundColor: '#ffff',
            padding: '5px',
            border: '1px solid #cccc',
          }}
        >
          <Typography variant='subtitle1' color='textSecondary'>
            {payload[0].name} : &#x20b9; {payload[0].payload.amount.toFixed(2)}
          </Typography>
          <Typography variant='subtitle1' color='textSecondary'>
            {payload[0].payload.percentage} %
          </Typography>
        </Box>
      )
    }

    return null
  }

  useEffect(() => {
    dispatch(requestForServiceTypeCollectionGraph(date, new Date(), 'range'))
  }, [dispatch])

  return (
    <Card
      elevation={2}
      style={{
        width: '100%',
      }}
    >
      <CardContent>
        <Box display='flex' flexDirection='row'>
          <Box display='flex' flexGrow={1} flexDirection='column' width='100%'>
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
            >
              <Box
                justifyContent='center'
                width='100%'
                height='100%'
                alignContent='center'
                p={0.5}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Typography
                        variant='subtitle1'
                        color='primary'
                        align='center'
                      >
                        Collection by services
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              {serviceCollectionSearchSlice.searchingAppointments && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>
            <Box
              display='flex'
              flexGrow={4}
              flexDirection='column'
              justifyContent='center'
              alignContent='center'
            >
              {serviceCollectionSearchSlice.errorWhileSearchingAppointments && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Typography variant='subtitle1' color='error' align='center'>
                    Error while searching IPD . Please try again
                  </Typography>
                </Box>
              )}
              {serviceCollectionSearchSlice.noResultsAvailable && (
                <Box
                  //   display='flex'
                  //   flexDirection='column'
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container justifyContent='center'>
                        <Typography
                          variant='subtitle1'
                          color='primary'
                          align='center'
                        >
                          No collection data available.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {serviceCollectionSearchSlice.graphType &&
                serviceCollectionSearchSlice.graphType.length > 0 && (
                  <ResponsiveContainer height={250}>
                    <PieChart width={400}>
                      <Pie
                        data={serviceCollectionSearchSlice.graphType ?? []}
                        color='#8884d8'
                        dataKey='percentage'
                        nameKey='name'
                        cx='50%'
                        cy='50%'
                        outerRadius={90}
                        fill='#8884d8'
                        labelLine={false}
                        label={renderCustomizedLabel}
                      >
                        {serviceCollectionSearchSlice.graphType.map(
                          (entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colors[index % colors.length]}
                            />
                          )
                        )}
                      </Pie>
                      {/* <Label content={renderCustomizedLabel} /> */}
                      <Tooltip content={<CustomTooltip />} />
                      <Legend verticalAlign='bottom' align='center' />
                    </PieChart>
                  </ResponsiveContainer>
                )}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
