import { atom } from 'recoil'
import { Address } from './cart/cart-models'
import { AppState, ProductsByCategory } from './product-models'

export const appState = atom({
  key: 'appStateKey',
  default: { state: 'initial', message: 'intiated' } as AppState,
})

export const viewState = atom<
  'productDetails' | 'cart' | 'address' | 'cartSummary'
>({
  key: 'viewStateKey',
  default: 'productDetails',
})

export const addressState = atom<Address[] | []>({
  key: 'viewAddressKey',
  default: [],
})

export const productsState = atom<ProductsByCategory[] | []>({
  key: 'productsStateKey',
  default: [],
})

export const selectedProductIdState = atom<string | undefined>({
  key: 'selectedProductStateKey',
  default: undefined,
})

export const selectSkippedState = atom<boolean | undefined>({
  key: 'selectedSkippedStateKey',
  default: false,
})
