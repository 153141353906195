import { R4 } from '@ahryman40k/ts-fhir-types'

export function isDuplicateInList(
  selectedCodes: R4.ICoding[],
  currentCode: R4.ICoding
): boolean {
  const res: boolean = false
  const index: number = selectedCodes.findIndex(
    (val) => val.code === currentCode.code
  )
  if (index > -1) {
    return true
  }
  return res
}

export function isDuplicateInListForCondition(
  selectedCodes: R4.ICoding[],
  currentCode: string
): boolean {
  const res: boolean = false
  const index: number = selectedCodes.findIndex(
    (val) => val.code === currentCode
  )
  if (index > -1) {
    return true
  }
  return res
}

export function removeDuplicateInList(
  selectedCodes: R4.ICoding[],
  currentCode: R4.ICoding
): boolean {
  const res: boolean = false
  const index: number = selectedCodes.findIndex(
    (val) => val.code === currentCode.code
  )
  if (index > -1) {
    return true
  }
  return res
}

export function isSummaryNotChanged() {}
