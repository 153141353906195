import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { R4 } from '@ahryman40k/ts-fhir-types'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { getPractitionerRoleObject } from 'redux/practitioner/practitionerSearchHandler/practitionerSearchSlice'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { getPractitionersWithRoleObject } from 'utils/fhirResoureHelpers/practitioner_and_role_helpers'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import _, { findIndex } from 'lodash'
import { getExtensionValueOfUrl } from 'utils/fhirResourcesHelper'
import { SlotStatusKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import moment from 'moment'
import { elem } from 'fp-ts/lib/Option'

export async function getOnTheFlyWalkInSlotForDoctor(
  doctorId: string
): Promise<FhirSlotDetail | undefined> {
  const practitionerDetails = await getHSAndPractRoleDetailsByPractRoleId(
    doctorId
  )
  if (practitionerDetails) {
    const scheduleId = getUniqueTempId()
    const slotId = getUniqueTempId()
    const practDetails = getPractitionerDetailsFromBundle(practitionerDetails)
    const hsDetails = getWalkInHSResourceFromBundle(practitionerDetails)
    console.log(hsDetails)
    const slot: R4.ISlot = {
      id: slotId,
      appointmentType: {
        coding: [
          {
            code: 'FOLLOWUP',
            system: 'http://terminology.hl7.org/CodeSystem/v2-0276',
          },
        ],
      },
      end: moment().add(25, 'minute').toDate().toISOString(),
      resourceType: 'Slot',
      schedule: {
        reference: `${'Schedule/'}${scheduleId}`,
      },
      serviceCategory: [
        {
          coding: [
            {
              code: '8',
              display: 'Counselling',
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/service-category',
            },
          ],
        },
      ],
      serviceType: [
        {
          coding: [
            {
              code: '556',
              display: 'Walk-in Centre /Non-Emergency',
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
            },
          ],
        },
      ],
      specialty: practDetails?.roleObject.specialty,
      start: moment().add(10, 'minute').toDate().toISOString(),
      status: SlotStatusKind._busy,
    }
    const scheduleDetails: R4.ISchedule = {
      resourceType: 'Schedule',
      id: scheduleId,
      identifier: [
        {
          use: R4.IdentifierUseKind._usual,
          system: 'mrn-tenant1',
          value: 'Schedulem1aqllsddr',
        },
      ],
      active: true,
      serviceType: [
        {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/service-type',
              code: '556',
              display: 'Walk-in Centre /Non-Emergency',
            },
          ],
        },
      ],
      serviceCategory: [
        {
          coding: [
            {
              system:
                'http://wellopathy.com/fhir/india/core/CodeSystem/service-category',
              code: '8',
              display: 'Counselling',
            },
          ],
        },
      ],
      actor: [
        {
          reference: `${'HealthcareService/'}${hsDetails?.id}`,
        },
        {
          reference: `${'PractitionerRole/'}${practDetails?.practRoleId}`,
        },
        {
          reference: `${'Practitioner/'}${practDetails?.id}`,
          type: 'Practitioner',
          display: practDetails?.name,
        },
      ],

      planningHorizon: {
        start: moment().add(10, 'minute').toDate().toISOString(),
        end: moment().add(25, 'minute').toDate().toISOString(),
      },
      comment: 'Walk-In',
    }
    // const res =
    //   hsDetails?.extension?.[2].valueReference?.reference?.split('/')[1]
    const chargeItemDefiniton =
      getChargeItemDefinitionOfWalkInHS(practitionerDetails)

    return {
      practitionerRole: practDetails?.roleObject!,
      slot,
      schedule: scheduleDetails,
      healthCareService: hsDetails,
      practitioner: practDetails?.practitionerObject!,
      chargeItemDefiniton,
      practitionerWithRole: [practDetails!],
    }
  }

  return undefined
}
function getPractitionerDetailsFromBundle(
  practitionerRolesResponses: R4.IBundle
): PractitionerWithRole | undefined {
  if (practitionerRolesResponses.total) {
    if (practitionerRolesResponses.total > 0) {
      if (practitionerRolesResponses.entry) {
        const practitionerWithRoleList: PractitionerWithRole[] =
          getPractitionersWithRoleObject(practitionerRolesResponses) ?? []
        return practitionerWithRoleList[0]
      }
    }
  }

  return undefined
}

function getWalkInHSResourceFromBundle(
  bundle: R4.IBundle
): R4.IHealthcareService | undefined {
  let result: R4.IHealthcareService | undefined
  if (bundle.entry && bundle.entry.length > 0) {
    bundle.entry.forEach((element) => {
      if (element.resource) {
        if (
          element.resource.resourceType === 'HealthcareService' &&
          element.resource.active
        ) {
          //   if (element.resource.type && element.resource.type.length > 0) {
          //     const codeIndex: number = _.findIndex(
          //       element.resource.type,
          //       (e) => {
          //         const index: number = _.findIndex(
          //           e.coding ?? [],
          //           (coding) => coding.code === '556'
          //         )

          //         if (index > -1) {
          //           return true
          //         }
          //         return false
          //       }
          //     )

          //     if (codeIndex && codeIndex > -1) {
          //       result = element.resource as R4.IHealthcareService
          //     }
          //   }
          if (element.resource.type && element.resource.type.length > 0) {
            for (let i = 0; i < element.resource.type.length; i++) {
              const coding = element.resource.type[i].coding ?? []
              if (coding.length > 0) {
                if (coding[0].code && coding[0].code === '556') {
                  result = element.resource as R4.IHealthcareService
                }
              }
            }
          }
        }
      }
    })
  }
  console.log(result)
  return result
}

function getChargeItemDefinitionOfWalkInHS(
  bundle: R4.IBundle
): R4.IChargeItemDefinition | undefined {
  let result: R4.IChargeItemDefinition | undefined
  if (bundle.entry && bundle.entry.length > 0) {
    const index = bundle.entry.findIndex((element) => {
      if (element.resource) {
        if (element.resource.resourceType === 'ChargeItemDefinition') {
          if (
            element.resource.url ===
            'http://wellopathy.com/ChargeItemDefinition/in-person'
          )
            return true
        }
      }
      return false
    })

    if (index > -1) {
      return bundle.entry[index].resource as R4.IChargeItemDefinition
    }
  }
  return result
}

export async function getHSAndPractRoleDetailsByPractRoleId(
  parctRoleId: string
): Promise<R4.IBundle | undefined> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    _id: parctRoleId,
  }

  searchParameters.organization = `Organization/${
    getCurrentUserUnitDetails().id
  }`

  const response: any = await fhirClient.doGetResource(
    '/PractitionerRole?_include=PractitionerRole:practitioner&_include=PractitionerRole:service&_include:iterate=HealthcareService:healthcare-service-billing',
    searchParameters
  )

  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const practitionerRolesResponses: R4.IBundle = relatedFhirDecodeRes.right
    if (practitionerRolesResponses.total) {
      if (practitionerRolesResponses.total > 0) {
        if (practitionerRolesResponses.entry) {
          return practitionerRolesResponses
        }
      }
    }
  }

  return undefined
}
