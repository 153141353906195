import { Box } from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import React, { ReactNode, useEffect, useState } from 'react'
import { EnrolCient } from 'services/EnrrolmentClient'
import { NotFoundPage } from 'views/pages/clinic/notFoundPage'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { WelloLoadingIndicator } from 'wello-web-components'
import { ErrorPage } from 'views/pages/clinic/errorPage'

export interface Props {
  child: ReactNode
}
export const BrowserTimeChecker: React.FC<Props> = ({ child }: Props) => {
  const { height } = useWindowDimensions()
  const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: true,
    requestSuccess: true,
  })

  function fetchInvoice() {
    setFetchingStatus({
      requesting: true,
    })

    const timeZone = moment.tz.guess()

    const customApiClient = new EnrolCient()

    customApiClient
      .doGetResourceForBrowserTime('/server-time', {
        timeZone,
      })
      .then((res) => {
        try {
          if (res.errorText && res.errorText.length > 0) {
            setFetchingStatus({
              requesting: false,
              requestSuccess: false,
              errorDescription: `We're currently experiencing a service outage. Please contact the administrator`,
              requestError: true,
            })
          } else {
            const time = moment(res)
            const broswerTime = moment()
            if (time.diff(broswerTime, 'hours') > 12) {
              setFetchingStatus({
                requesting: false,
                errorDescription: 'Your browser time is not correct',
                requestSuccess: false,
                requestError: true,
              })
            } else {
              setFetchingStatus({
                requesting: false,
                requestSuccess: true,
                requestError: false,
              })
            }
          }
        } catch (error) {
          setFetchingStatus({
            requesting: false,
            requestSuccess: false,

            errorDescription: `We're currently experiencing a service outage. Please contact the administrator`,
            requestError: true,
          })
        }
      })
  }

  useEffect(() => {
    fetchInvoice()
  }, [])

  return (
    <Box width='100%' height='100%'>
      {fetchingStatus.requestSuccess && child}
      {fetchingStatus.requestError && (
        <Box height={height}>
          {' '}
          <ErrorPage msg={fetchingStatus.errorDescription ?? ''} />
        </Box>
      )}
      {fetchingStatus.requesting && (
        <Box
          width='100%'
          height='100%'
          display='flex'
          justifyContent='center'
          alignContent='center'
        >
          <WelloLoadingIndicator message='Verifying your browser' />
        </Box>
      )}
    </Box>
  )
}
