import { R4 } from '@ahryman40k/ts-fhir-types'

export const OrganizationAddressType: R4.ICoding[] = [
  {
    code: 'BILL',
    display: 'Billing',
  },
  {
    code: 'ADMIN',
    display: 'Administrative',
  },
  {
    code: 'PATINF',
    display: 'Patient',
  },
]

export function getSortedOrganizationTypes(): R4.ICoding[] {
  OrganizationAddressType.sort((a, b) =>
    a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
  )
  return OrganizationAddressType
}
