import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
} from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import excel from 'xlsx'
import { PatientDataHelper } from '../../../../models/patientDataHelper'
import {
  EMAIL_REGEX,
  fileType,
  menu,
  MOBILE_REGEX,
  requiredColumns,
  validTypes,
} from '../../../../utils/constants/labConstants'
import { getFhirPatientBundleFromExcel } from '../../../../utils/labHelpers/patientBulkUploadHelper'
import { WelloTabs } from '../../LeftMenu/WelloTabs'
import { DropZone } from '../common/dropZone'
import { UploadErrors } from '../common/uploadErrors'
// import { UploadSuccess } from './uploadSuccess'
import { PatientColumnMapping } from './patientColumnMapping'
import { PatientDataUploadSuccess } from './patientDataUploadSuccess'

interface Props {
  open: boolean
  onClose: () => void
  onDiscard: () => void
}

export const PatientSetUp: React.FC<Props> = ({ open, onClose, onDiscard }) => {
  const [selectedTab, setSelectedTab] = React.useState(menu[0])
  const [selectedFiles, setSelectedFiles] = React.useState<any>()
  const [errors, setErrors] = React.useState<any>()
  const [columnsData, setColumnData] = React.useState<any>()
  const [dataSet, setDataSet] = React.useState<R4.IBundle>()
  const [showNextPage, setShowNextPage] = React.useState<boolean>(false)
  const [showContinue, setShowContinue] = React.useState<boolean>(false)
  const [show, setShow] = React.useState<boolean>(false)
  const [showDisplay, setShowDisplay] = React.useState<boolean>(false)
  const [fileData, setFileData] = React.useState<any>()
  const colData: string[] = []
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dataArray: any[] = []

  const checkData: string[] = []

  const handleChangeTab = (selected: string) => {
    setSelectedTab(selected)
    setErrors(undefined)
  }

  const validateExcel = (file: any) => {
    setShow(true)
    const errorData: string[] = []
    let columnData: string[] = []
    const name: string[] = []
    const dob: string[] = []
    const gender: string[] = []
    const phoneNo: string[] = []
    const emailId: string[] = []
    const address: string[] = []
    const ref: string[] = []
    const parsedArray: any[] = []

    let jsonObj: any
    const reader = new FileReader()
    const reader1 = new FileReader()
    reader1.onload = (evt) => {
      const test = (evt.target as FileReader).result
      setFileData(test)
    }
    reader1.readAsDataURL(file)

    const rABS = !!reader.readAsArrayBuffer
    reader.onload = (e) => {
      const bstr = (e.target as FileReader).result
      const wb = excel.read(bstr, {
        type: rABS ? 'binary' : 'array',
        bookVBA: true,
      })

      const wb1 = excel.read(bstr, {
        type: 'binary',
      })
      /* Get first worksheet */
      const wsName = wb.SheetNames
      if (wsName.includes('Patients_Data')) {
        for (let i = 0; i < wsName.length; i++) {
          const ws = wb.Sheets[wsName[i]]
          const data = excel.utils.sheet_to_json(ws)

          if (wsName[i] === 'Patients_Data') {
            columnData = excel.utils.sheet_to_json(ws, { header: 1 })
            jsonObj = eval(JSON.stringify(data))
            let k = 2
            const testData: any[] = []
            for (let j = 0; j < jsonObj.length; j++) {
              if (jsonObj[j].LabRef_id === undefined) {
                errorData.push(`LabRef_id is required at column 1 row ${k}`)
              } else {
                ref.push(jsonObj[j].LabRef_id)
              }

              if (jsonObj[j].Patient_Name === undefined) {
                errorData.push(`Patient_Name is required at column 2 row ${k}`)
                //   testData.push(' ')
              } else {
                name.push(jsonObj[j].Patient_Name)
              }

              if (jsonObj[j].Phone_Number === undefined) {
                errorData.push(`Phone_Number is required at column 3 row ${k}`)
              } else if (
                MOBILE_REGEX.test(jsonObj[j].Phone_Number.replace(' ', ''))
              ) {
                const phone = jsonObj[j].Phone_Number.replace(' ', '')
                if (phone.length > 10) {
                  errorData.push(`Phone_Number is invalid at column 3 row ${k}`)
                  phoneNo.push(jsonObj[j].Phone_Number)
                } else {
                  phoneNo.push(jsonObj[j].Phone_Number.replace(' ', ''))
                }
              } else {
                errorData.push(
                  `Phone_Number is invalid at column 3 row row ${k}`
                )
                phoneNo.push(jsonObj[j].Phone_Number.replace(' ', ''))
              }

              if (jsonObj[j].DoB === undefined) {
                errorData.push(`DoB is required at column 4 row ${k}`)
                //   description.push(' ')
              } else {
                const date = new Date(
                  Math.round((jsonObj[j].DoB - 25569) * 86400 * 1000)
                )
                if (moment(date).isValid()) {
                  const converted = moment(date).format('DD-MM-YYYY')
                  dob.push(converted)
                } else {
                  errorData.push(`DoB is invalid at column 4 row ${k}`)
                  dob.push('Invalid Date')
                }
              }
              if (jsonObj[j].Gender === undefined) {
                errorData.push(`Gender is required at column 5 row ${k}`)
                //   preTestInstructions.push(' ')
              } else {
                gender.push(jsonObj[j].Gender)
              }

              if (jsonObj[j].Email === undefined) {
                // errorData.push(`Email is required at column 5 row ${k}`)
                //   testSchedule.push(' ')
              } else if (EMAIL_REGEX.test(jsonObj[j].Email)) {
                emailId.push(jsonObj[j].Email)
              } else {
                errorData.push(`Email is invalid at column 6 row ${k}`)
              }

              if (jsonObj[j].Address === undefined) {
                // errorData.push(`Email is required at column 5 row ${k}`)
                //   testSchedule.push(' ')
              } else {
                address.push(jsonObj[j].Address)
              }

              k += 1
            }
            let refDataToDisPlay: string[] = []
            if (ref.length > 5) {
              refDataToDisPlay = ref.slice(0, 5)
            } else {
              refDataToDisPlay = ref
            }
            const moreRefData = ref.slice(5, ref.length).length

            let addressDataToDisPlay: string[] = []
            if (address.length > 5) {
              addressDataToDisPlay = address.slice(0, 5)
            } else {
              addressDataToDisPlay = address
            }
            const moreAddressData = address.slice(5, address.length).length

            let nameDataToDisPlay: string[] = []
            if (name.length > 5) {
              nameDataToDisPlay = name.slice(0, 5)
            } else {
              nameDataToDisPlay = name
            }
            const moreTestData = name.slice(5, name.length).length

            let dobDisPlay: string[] = []
            if (dob.length > 5) {
              dobDisPlay = dob.slice(0, 5)
            } else {
              dobDisPlay = dob
            }
            const moreDob = dob.slice(5, dob.length).length

            let genderDisPlay: string[] = []
            if (gender.length > 5) {
              genderDisPlay = gender.slice(0, 5)
            } else {
              genderDisPlay = gender
            }
            const moreGender = gender.slice(5, gender.length).length

            let phoneDisPlay: string[] = []
            if (phoneNo.length > 5) {
              phoneDisPlay = phoneNo.slice(0, 5)
            } else {
              phoneDisPlay = phoneNo
            }
            const morePhone = phoneNo.slice(5, phoneNo.length).length

            let emailDisPlay: string[] = []
            if (emailId.length > 5) {
              emailDisPlay = emailId.slice(0, 5)
            } else {
              emailDisPlay = emailId
            }
            const moreEmail = emailId.slice(5, emailId.length).length

            const NameJson = {
              ColumnName: columnData[0][1],
              Data: nameDataToDisPlay,
              moreData: moreTestData,
            }
            const dobJson = {
              ColumnName: columnData[0][3],
              Data: dobDisPlay,
              moreData: moreDob,
            }
            const genderJson = {
              ColumnName: columnData[0][4],
              Data: genderDisPlay,
              moreData: moreGender,
            }
            const phoneJson = {
              ColumnName: columnData[0][2],
              Data: phoneDisPlay,
              moreData: morePhone,
            }
            const emailJson = {
              ColumnName: columnData[0][5],
              Data: emailDisPlay,
              moreData: moreEmail,
            }
            const refJson = {
              ColumnName: columnData[0][0],
              Data: refDataToDisPlay,
              moreData: moreRefData,
            }

            const addressJson = {
              ColumnName: columnData[0][6],
              Data: addressDataToDisPlay,
              moreData: moreAddressData,
            }
            parsedArray.push(refJson)
            parsedArray.push(NameJson)
            parsedArray.push(phoneJson)
            parsedArray.push(dobJson)
            parsedArray.push(genderJson)
            parsedArray.push(emailJson)
            parsedArray.push(addressJson)
            setColumnData(parsedArray)
            if (errorData.length === 0) {
              for (let m = 0; m < jsonObj.length; m++) {
                const dateC = new Date(
                  Math.round((jsonObj[m].DoB - 25569) * 86400 * 1000)
                )
                let finalDate = ''
                if (moment(dateC).isValid()) {
                  finalDate = moment(dateC).format('YYYY-MM-DD')
                }
                const patientData: PatientDataHelper = {
                  name: jsonObj[m].Patient_Name,
                  dob: finalDate,
                  gender: jsonObj[m].Gender.toLowerCase(),
                  phone: jsonObj[m].Phone_Number.replace(' ', ''),
                  email: jsonObj[m].Email,
                  address: jsonObj[m].Address,
                  labRefId: jsonObj[m].LabRef_id,
                }
                testData.push(patientData)
              }
              const patientBundle = getFhirPatientBundleFromExcel(testData)
              setDataSet(patientBundle)
            }
            setShow(false)
            setErrors(errorData)
          }
        }
      } else {
        dispatch(showErrorAlert('Please upload correct format'))
      }
    }

    if (rABS) {
      reader.readAsBinaryString(file)
    } else {
      reader.readAsArrayBuffer(file)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        setShowNextPage(false)
        setSelectedFiles(undefined)
        setErrors(undefined)
        setColumnData(undefined)
        setShowContinue(false)
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth={selectedTab === menu[0] ? 'lg' : 'md'}
      fullWidth
      PaperProps={{
        style: {
          backdropFilter: 'blur(4px)',
        },
      }}
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'> {t('Add Patients')}</DialogTitle>
      <DialogContent>
        <Box flexDirection='column' display='flex'>
          <Paper
            style={{ backgroundColor: '#F1F1F1' }}
            variant='outlined'
            square
          >
            <Box width='100%'>
              <WelloTabs
                preSelectedTab={selectedTab}
                onTabChange={(e) => handleChangeTab(e)}
                menu={menu}
              />
            </Box>
          </Paper>
          <Box padding={2} boxShadow={0}>
            {selectedTab === menu[0] && errors === undefined && (
              <DropZone
                fileType={fileType}
                fileValidationType={validTypes}
                onFileChange={(file: any) => {
                  setSelectedFiles(file)
                }}
                requiredColumns={requiredColumns}
              />
            )}

            {selectedTab === menu[0] && columnsData && showNextPage === false && (
              <PatientColumnMapping
                columnsData={columnsData}
                onSelectionChanged={(value: any) => {
                  colData.push(value)
                  checkData.push(value)
                  if (
                    checkData.includes('name') &&
                    checkData.includes('dob') &&
                    checkData.includes('gender') &&
                    checkData.includes('phone') &&
                    checkData.includes('labRefId')
                  ) {
                    setShowContinue(true)
                  }
                }}
                selectedData={colData}
              />
            )}

            {selectedTab === menu[0] &&
              columnsData &&
              showNextPage &&
              errors.length > 0 && <UploadErrors errorList={errors} />}
            {selectedTab === menu[0] &&
              columnsData &&
              showNextPage &&
              errors.length === 0 && (
                <PatientDataUploadSuccess patientDataBundle={dataSet} />
              )}
          </Box>
          <Divider style={{ border: 1 }} />
        </Box>
      </DialogContent>
      <DialogActions>
        {selectedTab === menu[0] && errors === undefined && (
          <Box>
            <Button
              onClick={() => {
                onClose()
                setShowNextPage(false)
                setSelectedFiles(undefined)
                setErrors(undefined)
                setColumnData(undefined)
                setShowContinue(false)
              }}
              variant='outlined'
              disableElevation
              size='small'
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                validateExcel(selectedFiles)
              }}
              variant='contained'
              color='primary'
              disabled={selectedFiles === undefined}
              size='small'
            >
              Continue
            </Button>
          </Box>
        )}

        {selectedTab === menu[0] && columnsData && showNextPage === false && (
          <Box>
            <Button
              onClick={() => {
                setShowNextPage(false)
                setSelectedFiles(undefined)
                setErrors(undefined)
                setColumnData(undefined)
                setShowContinue(false)
              }}
              variant='outlined'
              //   disabled={actorInvitationSetupSlice.adding}
              disableElevation
              size='small'
            >
              Use a different file
            </Button>
            <Button
              onClick={() => {
                setShowNextPage(true)
              }}
              variant='contained'
              color='primary'
              size='small'
              disabled={showContinue === false}
            >
              Continue
            </Button>
          </Box>
        )}

        {selectedTab === menu[0] && columnsData && showNextPage && (
          <Box>
            <Button
              onClick={() => {
                setShowNextPage(false)
                setSelectedFiles(undefined)
                setErrors(undefined)
                setColumnData(undefined)
                setShowContinue(false)
              }}
              variant='outlined'
              //   disabled={actorInvitationSetupSlice.adding}
              disableElevation
              size='small'
            >
              Upload Another File
            </Button>
            <Button
              onClick={() => {
                setShowNextPage(false)
                setSelectedFiles(undefined)
                setErrors(undefined)
                setColumnData(undefined)
                setShowContinue(false)
                onClose()
              }}
              variant='contained'
              color='primary'
              size='small'
            >
              Done
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  )
}
