import { Box, ListItem, Typography } from '@material-ui/core'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import React from 'react'

export interface NavListItemProps {
  text: string
  onSectionSelected: () => void
  isSelected: boolean
}

export const NavListItem: React.FC<NavListItemProps> = ({
  text,
  onSectionSelected,
  isSelected = false,
}) => (
  <ListItem
    style={{
      paddingLeft: '4px',
      paddingRight: '4px',
      paddingTop: '8px',
      paddingBottom: '8px',
      backgroundColor: isSelected ? kPrimaryLight : 'transparent',
    }}
    id={`soap_nav_item_${text}`}
    key={`soap_nav_item_key_${text}`}
    tabIndex={0}
    onClick={(err) => {
      onSectionSelected()
    }}
  >
    <Box paddingX={2}>
      <Typography
        variant='subtitle2'
        color='primary'
        style={{
          textTransform: 'capitalize',
          letterSpacing: 'normal',
          whiteSpace: 'nowrap',
          justifyContent: 'flex-start',
          cursor: 'pointer',
        }}
      >
        {text}
      </Typography>
    </Box>
  </ListItem>
)
