import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { DoctorPlan } from './doctorPlanScbscriptionStatus'

const initialState: DoctorPlan = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
  resultsAvailable: false,
}

const doctorPlanSubscribeSlice = createSlice({
  name: 'doctorPlanSuscribe',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<DoctorPlan>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.resultsAvailable = action.payload.resultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
    },
  },
})

export const planSubscription =
  (selectedPatient: R4.IPatient, encounterId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: DoctorPlan = {
      adding: true,
      additionSuccessful: false,
      error: false,
      errorMessage: '',
      resultsAvailable: false,
    }
    dispatch(doctorPlanSubscribeSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response: any = await fhirClient.doGetResource(
        `/ServiceRequest??code=chronic-care-enrolment&subject=${
          selectedPatient.id ?? ''
        }`
      )
      logger.info('Patient invitation Response')
      logger.info(response)
      const respDecoded: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (respDecoded._tag === 'Right') {
        logger.info('Response Patient un decoded', respDecoded.right)
        const responseBundel: R4.IBundle = respDecoded.right
        if (responseBundel.entry) {
          const entryObj = responseBundel.entry?.[0]
            .resource as R4.IServiceRequest
          const serviceRequestId =
            entryObj && entryObj.status === 'active' ? entryObj.id ?? ' ' : ''
          if (serviceRequestId.length > 0) {
            const status = await updateEnrolment(serviceRequestId, encounterId)
            const disbaledButton: DoctorPlan = {
              adding: false,
              additionSuccessful: true,
              error: false,
              errorMessage: '',
              resultsAvailable: false,
            }
            dispatch(showSuccessAlert('Payment link has been sent to patient'))

            dispatch(
              doctorPlanSubscribeSlice.actions.updatedStatus(disbaledButton)
            )
            return
          }
        }
      }
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: DoctorPlan = {
        adding: false,
        additionSuccessful: false,
        error: false,
        errorMessage: 'Error while sending request',
        resultsAvailable: false,
      }
      dispatch(
        doctorPlanSubscribeSlice.actions.updatedStatus(errorSearchDoctor)
      )
    }
  }

async function updateEnrolment(
  serviceRequestId: string,
  encounterId: string
): Promise<string> {
  const data = {
    carePlan: 'hypertension',
    subscriptionPlan: 'platinum',
    startDate: moment(),
    endDate: moment().add(6, 'M'),
    encounterId,
    serviceRequestId,
  }

  const enRolClient: EnrolCient = new EnrolCient()
  const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
    `/enrolment/doctor-plan`,
    data
  )
  return response.BPCategory
}

export default doctorPlanSubscribeSlice.reducer
