import {
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Divider,
  Grid,
} from '@material-ui/core'
import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { ConfirmationDialogProps } from 'views/components/common/deleteConfirmationDialog'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { StyledTableCell, StyledTableRow } from '../styled-components'
import {
  CartItem,
  cartItemDiscountInRs,
  cartItemPrice,
} from '../../../../../../models/medicine-delivery/cart/cart-models'
import {
  cartOrderForCachedState,
  cartState,
} from '../../../../../../models/medicine-delivery/cart/cart-states'
import { CartHeaderTile } from './cart-header'

interface Props {
  onDelete: (item: CartItem) => void
  onUpdateQuantity: (item: CartItem, quantity: number) => void
}

export const CartItemsForClinic: React.FC<Props> = ({
  onDelete,
  onUpdateQuantity,
}: Props) => {
  const cart = useRecoilValue<any>(cartState)
  const orderFor = useRecoilValue(cartOrderForCachedState)
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })
  const { height } = useWindowDimensions()
  if (orderFor === 'patient') {
    return <>Please use component for patient</>
  }

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      p={1}
      style={{ overflow: 'auto' }}
      minHeight={`${height - 500}px`}
      maxHeight={`${height - 285}px`}
    >
      {cart.items.map((i: any, index: number) => (
        <Box
          width='100%'
          display='flex'
          flexDirection='column'
          key={i.product.title}
          px={2}
          py={2}
        >
          <Divider />
          <Box width='100%' display='flex' flexDirection='row'>
            <Box width='10%' display='flex' flexDirection='column'>
              <Box
                width='50%'
                style={{ backgroundColor: 'gray' }}
                height='20%'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
              >
                <Typography variant='subtitle2' style={{ color: 'white' }}>
                  {i.product.sku.discount}%
                </Typography>
              </Box>
              <Box py={0.3} />

              <Box
                width='50%'
                height='100%'
                display='flex'
                flexDirection='column'
                alignItems='center'
              >
                <img
                  src={i.product.image}
                  height={50}
                  width={60}
                  style={{ display: 'inline-block' }}
                  alt={i.product.title}
                />
              </Box>
            </Box>

            <Box width='90%' display='flex' flexDirection='row'>
              <Box
                display='flex'
                justifyContent='flex-start'
                px={1}
                py={1}
                width='5%'
              >
                <Divider
                  orientation='vertical'
                  style={{
                    height: '80px',
                    width: '2px',
                  }}
                />
              </Box>
              <Box
                display='flex'
                justifyContent='flex-start'
                width='95%'
                flexDirection='column'
                py={1}
              >
                <Box
                  width='100%'
                  display='flex'
                  flexDirection='row'
                  paddingBottom={0.5}
                >
                  <Box
                    width='50%'
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-start'
                    py={1}
                  >
                    <Typography
                      variant='subtitle1'
                      style={{ fontWeight: 'bold' }}
                    >
                      {i.product.title}
                    </Typography>
                  </Box>
                  <Box
                    width='50%'
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-end'
                  >
                    <ButtonGroup
                      size='small'
                      aria-label='small outlined button group'
                    >
                      <Button
                        disabled={i.quantity === 1}
                        onClick={() => onUpdateQuantity(i, -1)}
                      >
                        -
                      </Button>
                      <Button disabled>
                        <Typography variant='body1'>{i.quantity}</Typography>
                      </Button>
                      <Button onClick={() => onUpdateQuantity(i, 1)}>+</Button>
                    </ButtonGroup>
                    <Box paddingLeft={4}>
                      <Typography
                        variant='h5'
                        align='right'
                        color='primary'
                        onClick={() => onDelete(i)}
                        style={{ cursor: 'pointer', fontWeight: 'lighter' }}
                      >
                        x
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <CartHeaderTile />
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                  width='100%'
                >
                  <Grid container direction='row' spacing={1}>
                    {/* ------------------------------------------------------------------------------- */}

                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      flexGrow={1}
                    >
                      <Grid container direction='row' id='vitals'>
                        <Grid item xs={4}>
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                            flexGrow={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  paddingLeft={2}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                  >
                                    {i.product.sku.price}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            paddingLeft={5}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                              px={1}
                            >
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                              >
                                {(
                                  i.product.sku.price - cartItemDiscountInRs(i)
                                ).toFixed(2)}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={4}>
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            justifyContent='center'
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={30}
                              alignItems='center'
                              px={1}
                            >
                              <Typography
                                variant='subtitle2'
                                // color='initial'
                              >
                                {cartItemPrice(i)}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box py={0.3} />
          <Divider />
        </Box>
      ))}
    </Box>
  )
}
