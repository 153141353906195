/* eslint-disable jsx-a11y/label-has-associated-control */
import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { CloseOutlined } from '@material-ui/icons'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { PatientProfile } from 'models/patientProfile'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiVideo } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPatientDetails } from 'redux/patient/addPatient/addPatientSlice'
import { updatePatientData } from 'redux/patient/editPatient/editPatientSlice'
import { resndInvite } from 'redux/patient/resendInvite/resendInviteSlice'
import { RootState } from 'redux/rootReducer'
import { nationalityValueSet } from 'utils/constants'
import {
  AddressProofType,
  AddressProofTypeForForeign,
} from 'utils/constants/proof_types'
import {
  getAgeOfPatientInYearsForData,
  getIdentifierValueBySystem,
  getIdentifierValueBySystemType,
  getIdentifierValueBySystemTypeCoding,
  getPatientProfileData,
  getValueAttachmentFromExtension,
  getValueAttachmentFromExtensionContentType,
} from 'utils/fhirResourcesHelper'
import {
  validateCity,
  validateDateForPassportIssueDate,
  validateDateForPassportValidTill,
  validateMaritalStatus,
  validateTemporaryAddress,
  visaExpiryDateValidateForNewPatient,
  visaFormForPatientDateValidation,
} from 'utils/formValidators'
import {
  validateAddress,
  validateEmailForLab,
  validateLRNumber,
  validateOtherData,
} from 'utils/patientHelper/patientEditHelper'
import { WelloMaritalStatusSelector } from 'views/components/LeftMenu/WelloMaritalStatusSelector'
import { WelloOccupationSelector } from 'views/components/LeftMenu/WelloOccupation'
import { WelloPhoneNumberField } from 'views/components/LeftMenu/WelloPhoneNumberField'
import { WelloPlaceFinder } from 'views/mapComponent/placeAutoComplete'
import { entryPatient, formatContactPoint } from 'wello-fhir-l10n/dist/in/en/'
import { entryPatientHi } from 'wello-fhir-l10n/dist/in/hi/entryoverlay-partials'
import {
  validateDob,
  validateFathersName,
  validateHusbandsName,
  validateLastName,
  validateMiddleName,
  validateMobileNumber,
  validateName,
  WelloDateInput,
  WelloFormItemLabel,
  WelloGenderSelector,
  WelloTextField,
} from 'wello-web-components'
import { WebCamCapturePhoto } from '../administration/actors/micro-components/webcam_capture'
import { AttachmentImage } from '../common/attachementImageViewer'
import { LogoViewer } from '../common/logoViewer'
import { WelloSelectFHIRCoding } from '../LeftMenu/WelloSelectFHIRCoding'
import { WelloCountrySelector } from '../LeftMenu/WelloStateSelector'
import { WelloAddressTypeSelector } from '../LeftMenu/WelloType'

const emailFormat =
  /^([a-zA-Z0-9_\\.\\-])+\\@(([a-zA-Z0-9\\-])+\\.)+([a-zA-Z0-9]{2,4})+$/

interface Props {
  isLab: boolean
  patientData: R4.IPatient
  fathersData?: R4.IRelatedPerson
  husbandDetails?: R4.IRelatedPerson
  patProfile: PatientProfile
  status: boolean
  isPrimary: boolean
  unitType: string
  isOdd?: boolean
  onCloseClicked: () => void
  onEditSucsess: () => void
  dense?: boolean
}

interface SelectedFile {
  name?: string
  path?: string | ArrayBuffer
  type?: 'image' | 'document'
  local?: boolean
}

const occupations = [
  { id: 'govtOfficer', title: 'Govt. Officer' },
  { id: 'Politician', title: 'Politician' },
  { id: 'Self Employed', title: 'Self Employed' },
  { id: 'businessman', title: 'Businessman' },
  { id: 'Doctor', title: 'Doctor' },
  { id: 'Hospitality service', title: 'Hospitality service' },
  { id: 'Pvt Sector Executive', title: 'Pvt Sector Executive' },
  { id: 'Chartered Accountant', title: 'Chartered Accountant' },
  { id: 'Finance Professional', title: 'Finance Professional' },
  { id: 'Housewife', title: 'Housewife' },
].sort((a, b) => (a.title! > b.title! ? 1 : b.title! > a.title! ? -1 : 0))

export const EditPatient: React.FC<Props> = ({
  isLab,
  patientData,
  fathersData,
  husbandDetails,
  patProfile,
  status,
  isPrimary,
  unitType,
  isOdd = false,
  onCloseClicked,
  onEditSucsess,
  dense = false,
}: Props) => {
  const patientAddSlice = useSelector((state: RootState) => state.patientSlice)
  const editPatientSlice = useSelector(
    (state: RootState) => state.editPatientSlice
  )
  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['common', 'patient'])
  const genderData: string[] = language
    ? language === 'en'
      ? entryPatient.attr_entries['Patient.gender']
      : entryPatientHi.attr_entries['Patient.gender']
    : entryPatient.attr_entries['Patient.gender']

  const dispatch = useDispatch()
  const [patientDetails, setpatientDetails] = useState<R4.IPatient>(patientData)
  const [hasErrors, setHasErrors] = useState<boolean>(false)

  const [existingPatient, setExistingPatient] =
    useState<PatientProfile>(patProfile)
  const preselectedFIle: SelectedFile = {
    path: existingPatient.photoUri,
    type: 'image',
  }

  const [openWebCam, setOpenWebCam] = useState<boolean>(false)
  const [aspectRatio, setAspectRatio] = useState<number>(1)

  const [callBackForCapturePhoto, setCallBackForCapturePhoto] = React.useState(
    () => (imageName: any, imageDataType: any, imageUri: any, data?: any) => {}
  )

  const [valueData, setValueData] = useState('file')
  const [photoType, setPhotoType] = useState('both')

  const uploadPic = (e: any) => {
    const { name, value } = e.target
    let img: any

    if (e.target.files[0].size / 1024 <= 60) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onloadend = function () {
        if (reader.result !== null) {
          img = reader.result

          setExistingPatient({
            ...existingPatient,
            imgName: img.split(',')[1],
            type: file.type,
            [name]: reader.result,
          })
        }
      }
      reader.readAsDataURL(file)
    } else {
      alert('Please choose file less than 60 KB')
    }
  }

  function handleSubmit() {
    let nameValidate: boolean = false
    let nameMidlleValidate: boolean = false
    let lNameValidate: boolean = false
    let dobValidate: boolean = false
    let emailValidate: boolean = false
    let addressValidate: boolean = false
    let tempAddressValidate: boolean = false
    let lrNumberValidate: boolean = false
    let fatherNameValidate: boolean = false
    let husbandNameValidate: boolean = false
    let permanentCity: boolean = false
    let passporterrStart: boolean = false
    let passportExpiryError: boolean = false
    let visaNumber: boolean = false
    let visaType: boolean = false
    let visaIssueCity: boolean = false
    let visaIssueDate: boolean = false
    let visaExpiryDate: boolean = false

    if (validateName(existingPatient.name, true).length > 0) {
      setHasErrors(true)
      nameValidate = true
    }
    if (validateMiddleName(existingPatient.middleName, false).length > 0) {
      setHasErrors(true)
      nameMidlleValidate = true
    }
    if (validateLastName(existingPatient.lastName, true).length > 0) {
      setHasErrors(true)
      lNameValidate = true
    } else {
      setHasErrors(false)
      lNameValidate = false
    }

    if (validateDob(existingPatient.dob, true).length > 0) {
      setHasErrors(true)
      dobValidate = true
    } else {
      dobValidate = false
      setHasErrors(false)
    }

    if (getAgeOfPatientInYearsForData(existingPatient.dob) < 12) {
      setHasErrors(true)
      dobValidate = true
    } else {
      dobValidate = false
      setHasErrors(false)
    }

    if (
      validateEmailForLab(emailFormat, existingPatient.email, false).length > 0
    ) {
      setHasErrors(true)
      emailValidate = true
    } else {
      emailValidate = false
    }

    if (validateAddress(existingPatient.address, isLab === false).length > 0) {
      addressValidate = true
      setHasErrors(true)
    } else {
      addressValidate = false
      setHasErrors(false)
    }

    if (
      existingPatient.nationalityCoding.code &&
      existingPatient.nationalityCoding.code !== 'IN'
    ) {
      if (
        validateTemporaryAddress(existingPatient.tempAddress, true).length > 0
      ) {
        tempAddressValidate = true
        setHasErrors(true)
      } else {
        tempAddressValidate = false
        setHasErrors(false)
      }

      if (
        validateDateForPassportValidTill(
          moment(existingPatient.passportEndDate).format('YYYY-MM-DD'),
          moment(existingPatient.passportStartDate).format('YYYY-MM-DD'),
          true
        ).length > 0
      ) {
        passportExpiryError = true
        setHasErrors(true)
      } else {
        passportExpiryError = false
        setHasErrors(false)
      }

      if (
        validateDateForPassportIssueDate(
          moment(existingPatient.passportStartDate).format('YYYY-MM-DD'),
          '',
          true
        ).length > 0
      ) {
        passporterrStart = true
        setHasErrors(true)
      } else {
        passporterrStart = false
        setHasErrors(false)
      }

      validateDateForPassportIssueDate(
        moment(existingPatient.passportStartDate).format('YYYY-MM-DD'),
        '',
        true
      )

      if (validateCity(existingPatient.city, isLab).length > 0) {
        permanentCity = true
        setHasErrors(true)
      } else {
        permanentCity = false
        setHasErrors(false)
      }
    }

    if (validateLRNumber(existingPatient.labReference, false).length > 0) {
      lrNumberValidate = true
      setHasErrors(true)
    } else {
      lrNumberValidate = false
      setHasErrors(false)
    }

    if (validateFathersName(existingPatient.fathersName, false).length > 0) {
      fatherNameValidate = true
      setHasErrors(true)
    } else {
      fatherNameValidate = false
      setHasErrors(false)
    }

    if (validateHusbandsName(existingPatient.husbandName, false).length > 0) {
      husbandNameValidate = true
      setHasErrors(true)
    } else {
      husbandNameValidate = false
      setHasErrors(false)
    }

    if (
      existingPatient.nationalityCoding &&
      existingPatient.nationalityCoding.code &&
      existingPatient.nationalityCoding.code !== 'IN'
    ) {
      console.log(existingPatient)
      if (existingPatient.visaNo.length === 0) {
        visaNumber = true
        setHasErrors(true)
      } else {
        visaNumber = false
        setHasErrors(false)
      }

      if (existingPatient.visaTypeCoding === undefined) {
        visaType = true
        setHasErrors(true)
      } else {
        visaType = false
        setHasErrors(false)
      }
      if (existingPatient.visaIssueCity.length === 0) {
        visaIssueCity = true
        setHasErrors(true)
      } else {
        visaIssueCity = false
        setHasErrors(false)
      }

      if (
        visaFormForPatientDateValidation(
          moment(existingPatient.passportStartDate).format('YYYY-MM-DD'),
          moment(existingPatient.passportEndDate).format('YYYY-MM-DD'),
          true,
          existingPatient.visaIssueDate
            ? moment(existingPatient.visaIssueDate).format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD')
        ).length > 0
      ) {
        visaIssueDate = true
        setHasErrors(true)
      } else {
        visaIssueDate = false
        setHasErrors(false)
      }

      if (
        visaExpiryDateValidateForNewPatient(
          moment(existingPatient.passportStartDate).format('YYYY-MM-DD'),
          true,
          existingPatient.visaIssueDate
            ? moment(existingPatient.visaIssueDate).format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD'),
          existingPatient.visaExpiryDate
            ? moment(existingPatient.visaExpiryDate).format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD')
        ).length > 0
      ) {
        visaExpiryDate = true
        setHasErrors(true)
      } else {
        visaExpiryDate = false
        setHasErrors(false)
      }
    }

    if (
      nameValidate ||
      visaExpiryDate ||
      visaIssueCity ||
      visaType ||
      lNameValidate ||
      dobValidate ||
      emailValidate ||
      addressValidate ||
      tempAddressValidate ||
      lrNumberValidate ||
      husbandNameValidate ||
      fatherNameValidate ||
      permanentCity ||
      passportExpiryError ||
      passporterrStart ||
      visaNumber ||
      visaIssueDate ||
      existingPatient.addressProofNumber.length
    ) {
      setHasErrors(true)
    }
    if (
      !nameValidate &&
      !visaIssueDate &&
      !visaExpiryDate &&
      !visaNumber &&
      !visaType &&
      !visaIssueCity &&
      !nameMidlleValidate &&
      !lNameValidate &&
      !dobValidate &&
      !emailValidate &&
      !addressValidate &&
      !tempAddressValidate &&
      !lrNumberValidate &&
      !husbandNameValidate &&
      !fatherNameValidate &&
      !permanentCity &&
      !passportExpiryError &&
      !passporterrStart
    ) {
      dispatch(
        updatePatientData(
          patientData,
          existingPatient,
          fathersData,
          husbandDetails
        )
      )
    }
  }

  useEffect(() => {
    i18n.changeLanguage(language ?? 'en')
    dispatch(fetchPatientDetails(patientData.id ?? '', patProfile.status))
    return () => {}
  }, [])

  useEffect(() => {
    if (patientAddSlice.resultsAvailable) {
      if (patientAddSlice.patientProfile)
        setExistingPatient(patientAddSlice.patientProfile)
    }

    if (editPatientSlice.updatedSuccessfully) {
      onEditSucsess()
      //   dispatch(resetState())
    }
    return () => {}
  }, [
    editPatientSlice.updatedSuccessfully,
    onEditSucsess,
    patientAddSlice.patientProfile,
    patientAddSlice.resultsAvailable,
  ])

  return (
    <Box width='100%' px={1} maxHeight='100%' overflow='auto'>
      <Card
        elevation={0}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 8,
          backgroundColor: isOdd ? '#FFFFFFAF' : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 4,
            paddingLeft: 16,
            paddingRight: 16,
            border: 1,
          }}
        >
          <Box flexDirection='row' width='100%' display='flex' p={1}>
            {patientAddSlice.fetchingPatientDetails && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            )}
          </Box>

          {patientAddSlice.resultsAvailable && (
            <Box height='50%'>
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                justifyContent='space-between'
                alignContent='center'
                alignItems='center'
              >
                <Box display='flex' flexDirection='column'>
                  <Typography variant='h6' color='initial'>
                    {t('labelCommon:edit_patient')} #{' '}
                    {t('labelCommon:reference')} : {patientData.id}
                  </Typography>
                  <Box py={0.5}>
                    <Typography variant='subtitle2' color='initial'>
                      Patient Creation Date :{' '}
                      {existingPatient.patientCreatedDate}
                    </Typography>
                  </Box>
                </Box>

                {isPrimary === true && (
                  <Typography variant='h6' color='primary'>
                    Primary Contact
                  </Typography>
                )}

                <Box display='flex' flexDirection='row'>
                  <Tooltip title={'Send Invite' ?? 'Send Invite'} id='edit_1'>
                    <IconButton
                      aria-label='btn_ord_reschedule'
                      color='primary'
                      id='edit_2'
                      onClick={() => {
                        dispatch(resndInvite(existingPatient.id))
                      }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/Send_Invite.ico`}
                        alt='delete'
                        id='edit_3'
                      />
                    </IconButton>
                  </Tooltip>

                  {/* <Tooltip title={'Send APk Link' ?? 'Send APk Link'}>
                    <IconButton
                      aria-label='btn_ord_reschedule'
                      color='primary'
                      onClick={() => {
                        dispatch(sendDownlinkInvite(existingPatient.id))
                      }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/Send_APK_Link.ico`}
                        alt='delete'
                      />
                    </IconButton>
                  </Tooltip> */}

                  <Tooltip
                    title={t('labelCommon:close') ?? 'Re-Schedule'}
                    id='edit_4'
                  >
                    <IconButton
                      aria-label='btn_ord_detail_menu'
                      onClick={onCloseClicked}
                      id='edit_5'
                    >
                      <CloseOutlined color='primary' id='edit_6' />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              {/* <AttachmentImage
                contentType='image/jpeg'
                decodingRequired={false}
                imagePath="Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front').value.as(Attachment)"
                resourceId={existingPatient.id}
                resourceType='Patient'
              /> */}
              <Divider
                orientation='horizontal'
                variant='fullWidth'
                flexItem
                style={{ width: '100%', height: '2px' }}
                color='primary'
              />
              <Box display='flex' flexDirection='column'>
                <Box display='flex' justifyContent='center' p={1} width='100%'>
                  <Box
                    display='flex'
                    flexGrow={1}
                    alignItems='flex-start'
                    justifyContent='center'
                    px={1}
                    width='20%'
                    paddingTop={2.5}
                  >
                    <Box>
                      {existingPatient.photoUri.length === 0 && (
                        <Box
                          display='flex'
                          flexDirection='row'
                          style={{ cursor: 'pointer' }}
                          alignItems='center'
                          id='edit_7'
                          onClick={() => {
                            setCallBackForCapturePhoto(
                              () =>
                                (
                                  imageName: any,
                                  dataType: any,
                                  image: any,
                                  data?: any
                                ) => {
                                  setExistingPatient({
                                    ...existingPatient,
                                    imgName: imageName,
                                    type: data,
                                    photoUri: image,
                                  })
                                }
                            )
                            setAspectRatio(1)
                            setOpenWebCam(true)
                          }}
                        >
                          <Box>
                            <h5>
                              {' '}
                              <FiVideo />{' '}
                            </h5>
                          </Box>
                          <Box p={1} display='flex' flexDirection='column'>
                            <Box>
                              <Typography
                                variant='subtitle2'
                                color='primary'
                                id='2'
                              >
                                Open Device Camera
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      )}
                      {existingPatient.photoUri.length !== 0 && (
                        <>
                          <Box px={1}>
                            <Avatar
                              alt='avatar'
                              src={existingPatient.photoUri}
                              style={{
                                height: 60,
                                width: 60,
                              }}
                            />
                            <Button
                              color='primary'
                              id='edit_8'
                              onClick={() => {
                                setCallBackForCapturePhoto(
                                  () =>
                                    (
                                      imageName: any,
                                      dataType: any,
                                      image: any,
                                      data?: any
                                    ) => {
                                      setExistingPatient({
                                        ...existingPatient,
                                        imgName: imageName,
                                        type: data,
                                        photoUri: image,
                                      })
                                    }
                                )
                                setAspectRatio(1)
                                setOpenWebCam(true)
                              }}
                            >
                              Change
                            </Button>
                            {/* <label>
                              <Typography
                                variant='subtitle2'
                                color='primary'
                                style={{
                                  textTransform: 'uppercase',
                                }}
                              >
                                change
                              </Typography>
                              <input
                                id='file-input'
                                type='file'
                                name='photoUri'
                                style={{ display: 'none' }}
                                onChange={uploadPic}
                                accept='image/*'
                              />
                            </label> */}
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                  <Box p={0.5} width='35%'>
                    <WelloTextField
                      title={t('labelCommon:first_name_label')}
                      textProps={{
                        id: `edit_9`,
                        value: existingPatient.name ?? '',
                        defaultValue: 'test',
                        disabled: patientAddSlice.updating,
                        error:
                          hasErrors &&
                          validateName(existingPatient.name, true).length > 0,
                        helperText:
                          hasErrors &&
                          validateName(existingPatient.name, true).length > 0
                            ? `${validateName(existingPatient.name, true)}`
                            : '',
                        onChange: (changedValue) => {
                          setExistingPatient({
                            ...existingPatient,
                            name: changedValue.target.value,
                          })
                        },
                      }}
                    />
                  </Box>
                  <Box p={0.5}>
                    <WelloTextField
                      title={t('labelCommon:middle_name_label')}
                      textProps={{
                        id: `edit_10`,
                        value: existingPatient.middleName
                          ? existingPatient.middleName
                          : '',
                        disabled: patientAddSlice.updating,
                        error:
                          hasErrors &&
                          validateMiddleName(existingPatient.middleName, false)
                            .length > 0,
                        helperText:
                          hasErrors &&
                          validateMiddleName(existingPatient.middleName, false)
                            .length > 0
                            ? `${validateMiddleName(
                                existingPatient.middleName,
                                true
                              )}`
                            : '',
                        onChange: (changedValue) => {
                          setExistingPatient({
                            ...existingPatient,
                            middleName: changedValue.target.value,
                          })
                        },
                      }}
                    />
                  </Box>
                  <Box width='35%' p={0.5}>
                    <WelloTextField
                      title={t('labelCommon:last_name_label')}
                      //   value=lab.labName
                      textProps={{
                        id: `edit_11`,
                        value: existingPatient.lastName,
                        defaultValue: 'test',
                        disabled: patientAddSlice.updating,
                        error:
                          hasErrors &&
                          validateLastName(existingPatient.lastName, true)
                            .length > 0,
                        helperText:
                          hasErrors &&
                          validateLastName(existingPatient.lastName, true)
                            .length > 0
                            ? `${validateLastName(
                                existingPatient.lastName,
                                true
                              )}`
                            : '',
                        onChange: (changedValue) => {
                          setExistingPatient({
                            ...existingPatient,
                            lastName: changedValue.target.value,
                          })
                        },
                      }}
                    />
                  </Box>
                </Box>

                <Box display='flex' flexDirection='row' width='100%'>
                  <Box
                    display='flex'
                    alignItems='flex-start'
                    px={0.5}
                    width='33%'
                  >
                    <WelloDateInput
                      title={t('labelCommon:doblabelForKey')}
                      textProps={{
                        id: `edit_12`,
                        value: existingPatient.dob,

                        disabled: patientAddSlice.updating,
                        inputProps: {
                          min: '1871-01-01',
                          max: moment().format('YYYY-MM-DD'),
                        },
                        error:
                          hasErrors &&
                          (validateDob(existingPatient.dob, true).length > 0 ||
                            getAgeOfPatientInYearsForData(existingPatient.dob) <
                              12),
                        helperText:
                          hasErrors &&
                          validateDob(existingPatient.dob, true).length > 0
                            ? `${validateDob(existingPatient.dob, true)}`
                            : getAgeOfPatientInYearsForData(
                                existingPatient.dob
                              ) < 12
                            ? 'dob must be greater than equal to 12 years'
                            : '',
                        onChange: (changedValue) => {
                          setExistingPatient({
                            ...existingPatient,
                            dob: changedValue.target.value,
                          })
                        },
                      }}
                    />
                  </Box>

                  <Box
                    display='flex'
                    flexDirection='row'
                    paddingRight={0.5}
                    width='32%'
                  >
                    <WelloGenderSelector
                      title={t('labelPatient:attr_labels.Patient.gender')}
                      genderListData={genderData}
                      textFieldProps={{
                        value: existingPatient.gender,
                        id: 'unit_actor_select_gender',
                        required: true,
                        name: 'gender',
                        // error: !!errors.gender,
                        // helperText: errors.gender,
                      }}
                      onChanged={(selectedGender) => {
                        if (selectedGender?.code) {
                          setExistingPatient({
                            ...existingPatient,
                            gender: selectedGender?.code,
                          })
                        }
                      }}
                    />
                  </Box>
                  <Box display='flex' flexDirection='row' width='33%'>
                    <WelloMaritalStatusSelector
                      title={t(
                        'labelPatient:attr_labels.Patient.maritalStatus'
                      )}
                      textFieldProps={{
                        value: existingPatient.maritalStaTus,
                        id: 'marital_status',
                        required: true,
                        name: 'maritalStaTus',
                        error:
                          validateMaritalStatus(
                            existingPatient.maritalStaTus,
                            isLab === false
                          ).length > 0,
                        helperText: validateMaritalStatus(
                          existingPatient.maritalStaTus,
                          isLab === false
                        ),
                      }}
                      onChanged={(selectedMaritalStatus) => {
                        if (selectedMaritalStatus?.code) {
                          setExistingPatient({
                            ...existingPatient,
                            maritalStaTus: selectedMaritalStatus?.code,
                          })
                        }
                      }}
                    />
                  </Box>
                </Box>

                <Box display='flex' justifyContent='center' py={0.5}>
                  <Box
                    display='flex'
                    flexGrow={1}
                    alignItems='flex-start'
                    justifyContent='center'
                    px={0.5}
                    width='20%'
                    py={0.5}
                  >
                    <WelloSelectFHIRCoding
                      title='Nationality'
                      id='edit_14'
                      duration='Select Nationality'
                      availableCodings={nationalityValueSet}
                      // onChanged={(e) => {
                      //    if (e) {
                      //      handleInputChangeNationality(e, 'nationality')
                      //    }
                      //  }}
                      onChanged={(val) => {
                        setExistingPatient(
                          getPatientProfileData(existingPatient, val)
                        )
                        // setExistingPatient({
                        //   ...existingPatient,
                        //   nationalityCoding: val,
                        //   nationality: val.code
                        //     ? val.code === 'IN'
                        //       ? 'India'
                        //       : val.code
                        //     : '',
                        // })
                      }}
                      textFieldProps={{
                        fullWidth: true,
                        // required: true,
                        variant: 'outlined',
                        size: 'small',
                        // value: values.nationality,
                        // style: {
                        //   //   maxWidth: '480px',
                        //   width: '200px',
                        // },
                        disabled: false,
                        // onChange: handleInputChange,
                        name: 'nationality',
                        //   error: errors.nationality,
                        //   helperText: errors.nationality,

                        id: 'patient_nationality',
                      }}
                      preSelectedCoding={existingPatient.nationalityCoding}
                    />
                  </Box>
                  <Box
                    display='flex'
                    flexGrow={1}
                    alignItems='flex-start'
                    justifyContent='center'
                    px={0.5}
                    width='20%'
                    py={0.5}
                  >
                    <WelloPhoneNumberField
                      textProps={{
                        id: `edit_15`,
                        value: existingPatient.phone,
                        countryCodeEditable: false,
                        defaultCountry: 'in',
                        fullWidth: true,
                        name: t('phone_name_label'),
                        inputMode: 'tel',
                        onlyCountries: ['in'],
                        disableDropdown: false,
                        inputProps: { maxLength: 13 },
                        disabled: true,
                        onChange: (
                          e:
                            | React.ChangeEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >
                            | string
                        ) => {
                          if (typeof e === 'string') {
                            setExistingPatient({
                              ...existingPatient,
                              phone: e,
                            })
                          }
                        },
                        regions: ['asia'],
                        autoFormat: false,
                        size: 'small',
                        variant: 'outlined',
                        error:
                          hasErrors &&
                          validateMobileNumber(existingPatient.phone, true)
                            .length > 0,
                        helperText:
                          hasErrors &&
                          validateMobileNumber(existingPatient.phone, true)
                            .length > 0
                            ? 'Enter valid phone number'
                            : '',

                        required: true,
                      }}
                      title={t('labelCommon:phone_number_label')}
                    />
                  </Box>

                  <Box
                    display='flex'
                    flexGrow={1}
                    alignItems='flex-start'
                    justifyContent='center'
                    px={0.5}
                    width='20%'
                    py={0.5}
                  >
                    <WelloTextField
                      title={t('labelCommon:email_address')}
                      textProps={{
                        id: `edit_16`,
                        type: 'email',
                        value: existingPatient.email,
                        disabled: true,
                        error:
                          hasErrors &&
                          validateEmailForLab(
                            emailFormat,
                            existingPatient.email,
                            false
                          ).length > 0,
                        helperText:
                          hasErrors &&
                          validateEmailForLab(
                            formatContactPoint.attr_formats[
                              'ContactPoint.value.email'
                            ],
                            existingPatient.email,
                            false
                          ).length > 0
                            ? `${validateEmailForLab(
                                emailFormat,
                                existingPatient.email,
                                false
                              )}`
                            : '',
                        onChange: (changeEmail) => {
                          setExistingPatient({
                            ...existingPatient,
                            email: changeEmail.target.value,
                          })
                        },
                      }}
                    />
                  </Box>
                </Box>

                <Box
                  display='flex'
                  flexGrow={1}
                  alignItems='flex-end'
                  justifyContent='center'
                  width='100%'
                >
                  <Box
                    display='flex'
                    flexGrow={1}
                    alignItems='flex-end'
                    justifyContent='center'
                    p={0.5}
                    width='20%'
                  >
                    {/* <Autocomplete
                      style={{
                        borderRadius: '4px',
                      }}
                      fullWidth
                      size='small'
                      id='free-solo-2-demo'
                      disableClearable
                      defaultValue={existingPatient.occupation}
                      getOptionSelected={(option, value) =>
                        option.title === value.title
                      }
                      getOptionLabel={(option) => option.title ?? ''}
                      options={occupations}
                      onChange={(e, changedValue, reason) => {
                        if (changedValue) {
                          setExistingPatient({
                            ...existingPatient,
                            occupation: changedValue.id,
                          })
                        }
                        // handleInputChangeForOccupation(
                        //   'occupation',
                        //   changedValue.title
                        // )
                      }}
                      autoComplete
                      ChipProps={{
                        deleteIcon: (
                          <ClearOutlined
                            style={{
                              height: '15px',
                              color: 'white',
                            }}
                          />
                        ),
                        style: {
                          backgroundColor: kPrimaryLight,
                          borderRadius: '4px',
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder='Select Occupation (Optional)'
                          fullWidth
                          variant='outlined'
                          size='small'
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                          }}
                        />
                      )}
                    /> */}
                    <WelloOccupationSelector
                      title={t('labelCommon:occupation_label')}
                      textFieldProps={{
                        value: existingPatient.occupation,
                        placeholder: 'Select',
                        id: 'occupation',
                        required: false,
                        name: 'occupation',
                      }}
                      onChanged={(selectedOccupation) => {
                        if (selectedOccupation?.id) {
                          setExistingPatient({
                            ...existingPatient,
                            occupation: selectedOccupation?.id,
                          })
                        }
                      }}
                    />
                  </Box>

                  <Box
                    display='flex'
                    flexGrow={1}
                    alignItems='flex-end'
                    justifyContent='center'
                    p={0.5}
                    width='20%'
                  >
                    <WelloTextField
                      title={t('labelCommon:placeHolder_reference')}
                      textProps={{
                        id: `labRefId`,
                        value: existingPatient.labReference,
                        disabled: patientAddSlice.updating,
                        error:
                          hasErrors &&
                          validateLRNumber(existingPatient.labReference, isLab)
                            .length > 0,
                        helperText:
                          hasErrors &&
                          validateLRNumber(existingPatient.labReference, isLab)
                            .length > 0
                            ? `${validateLRNumber(
                                existingPatient.labReference,
                                isLab
                              )}`
                            : '',
                        onChange: (select) => {
                          setExistingPatient({
                            ...existingPatient,
                            labReference: select.target.value,
                          })
                        },
                      }}
                    />
                  </Box>

                  <Box
                    display='flex'
                    flexGrow={1}
                    alignItems='flex-end'
                    justifyContent='center'
                    p={0.5}
                    width='20%'
                  >
                    {fathersData && (
                      <WelloTextField
                        title={t('labelCommon:fathers_name_label')}
                        textProps={{
                          id: `fId`,
                          value: existingPatient.fathersName,
                          disabled: patientAddSlice.updating,
                          error:
                            hasErrors &&
                            validateFathersName(
                              existingPatient.fathersName,
                              false
                            ).length > 0,
                          helperText:
                            hasErrors &&
                            validateFathersName(
                              existingPatient.fathersName,
                              false
                            ).length > 0
                              ? `${validateFathersName(
                                  existingPatient.fathersName,
                                  false
                                )}`
                              : '',
                          onChange: (select) => {
                            setExistingPatient({
                              ...existingPatient,
                              fathersName: select.target.value,
                            })
                          },
                        }}
                      />
                    )}
                    {husbandDetails && (
                      <WelloTextField
                        title={t('labelCommon:husbands_name_label')}
                        textProps={{
                          id: `edit_17`,
                          value: existingPatient.husbandName,
                          disabled: patientAddSlice.updating,
                          error:
                            hasErrors &&
                            validateHusbandsName(
                              existingPatient.husbandName,
                              false
                            ).length > 0,
                          helperText:
                            hasErrors &&
                            validateHusbandsName(
                              existingPatient.husbandName,
                              false
                            ).length > 0
                              ? `${validateHusbandsName(
                                  existingPatient.husbandName,
                                  false
                                )}`
                              : '',
                          onChange: (select) => {
                            setExistingPatient({
                              ...existingPatient,
                              husbandName: select.target.value,
                            })
                          },
                        }}
                      />
                    )}
                  </Box>
                </Box>
                {existingPatient.occupation === 'other' && (
                  <Box
                    display='flex'
                    flexGrow={1}
                    alignItems='flex-end'
                    justifyContent='center'
                    width='100%'
                  >
                    <Box
                      display='flex'
                      flexGrow={1}
                      alignItems='flex-start'
                      justifyContent='center'
                      p={0.5}
                      width='20%'
                    >
                      <WelloTextField
                        title='OTHER DATA'
                        textProps={{
                          id: `occupastionOther`,
                          value: existingPatient.occuptionOtherData,
                          error:
                            validateOtherData(
                              existingPatient.occuptionOtherData,
                              true
                            ).length > 0,
                          helperText:
                            validateOtherData(
                              existingPatient.occuptionOtherData,
                              true
                            ).length > 0
                              ? `${validateOtherData(
                                  existingPatient.occuptionOtherData,
                                  true
                                )}`
                              : '',
                          disabled: patientAddSlice.updating,
                          onChange: (selectedAddress) => {
                            setExistingPatient({
                              ...existingPatient,
                              occuptionOtherData: selectedAddress.target.value,
                            })
                          },
                        }}
                      />
                    </Box>
                  </Box>
                )}

                <Box
                  width='100%'
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-start'
                >
                  <Box p={0.5} width='50%'>
                    <WelloSelectFHIRCoding
                      title={t('labelCommon:select_proof_type')}
                      id='1addressProfType'
                      availableCodings={
                        existingPatient.nationalityCoding.code &&
                        existingPatient.nationalityCoding.code !== 'IN'
                          ? AddressProofTypeForForeign
                          : AddressProofType
                      }
                      preSelectedCoding={existingPatient.addressProofTypeCoding}
                      onChanged={(val) => {
                        setExistingPatient({
                          ...existingPatient,
                          addressProofTypeCoding: val,
                        })
                      }}
                      textFieldProps={{
                        fullWidth: true,
                        required: false,
                        size: 'small',
                        disabled: false,
                        // style: {
                        //   maxWidth: '480px',
                        //   width: '300px',
                        // },

                        id: 'patient_address_proof_type',
                      }}
                    />
                  </Box>

                  <Box width='30%' p={0.5}>
                    <WelloTextField
                      title='Proof Number'
                      textProps={{
                        id: `proofId`,
                        value: existingPatient.addressProofNumber,
                        error: existingPatient.addressProofNumber.length === 0,
                        helperText:
                          existingPatient.addressProofNumber.length === 0
                            ? 'Proof Number required'
                            : '',
                        disabled: false,
                        onChange: (selectedAddress) => {
                          setExistingPatient({
                            ...existingPatient,
                            addressProofNumber: selectedAddress.target.value,
                          })
                        },
                      }}
                    />
                  </Box>
                  <Box p={0.5} width='20%'>
                    <WelloAddressTypeSelector
                      title={t('labelCommon:placeHolder_addressType')}
                      textFieldProps={{
                        value: existingPatient.addressType,
                        placeholder: 'Select',
                        id: 'addressType',
                        required: false,
                        name: 'addressType',
                      }}
                      onChanged={(selectedOccupation) => {
                        const selectedOccupationData: any = selectedOccupation
                        if (
                          selectedOccupationData &&
                          selectedOccupationData.code
                        ) {
                          setExistingPatient({
                            ...existingPatient,
                            addressType: selectedOccupationData.code
                              ? selectedOccupationData.code
                              : undefined,
                          })
                        }
                      }}
                    />
                  </Box>
                </Box>
                {existingPatient.addressProofTypeCoding !== undefined &&
                  existingPatient.addressProofTypeCoding.code !== undefined &&
                  (existingPatient.addressProofTypeCoding.code === 'PPN' ||
                    existingPatient.addressProofTypeCoding.code ===
                      'passport') && (
                    <Box
                      display='flex'
                      flexGrow={1}
                      alignItems='flex-end'
                      justifyContent='center'
                      width='100%'
                    >
                      <Box
                        display='flex'
                        flexGrow={1}
                        // alignItems='flex-end'
                        // justifyContent='center'
                        flexDirection='column'
                        p={0.5}
                        width='20%'
                      >
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <WelloFormItemLabel title='Passport issue Date' />
                          <KeyboardDatePicker
                            clearable
                            id='date'
                            maxDate={moment()}
                            minDate={existingPatient.dob}
                            //   minDate={moment(
                            //     getAgeOfPatientForChecking(
                            //       fhirAppointmentDetails.patient
                            //     )
                            //   )}
                            value={existingPatient.passportStartDate}
                            format='DD-MM-YYYY'
                            name='passportIssueDate'
                            // style={{
                            //   maxWidth: '480px',
                            //   width: '285px',
                            // }}
                            onChange={(e) =>
                              setExistingPatient({
                                ...existingPatient,
                                passportStartDate:
                                  e !== null ? moment(e).toDate() : new Date(),
                              })
                            }
                            error={
                              validateDateForPassportIssueDate(
                                moment(
                                  existingPatient.passportStartDate
                                ).format('YYYY-MM-DD'),
                                '',
                                true
                              ).length > 0
                            }
                            helperText={validateDateForPassportIssueDate(
                              moment(existingPatient.passportStartDate).format(
                                'YYYY-MM-DD'
                              ),
                              '',
                              true
                            )}
                            size='small'
                            inputVariant='outlined'
                          />
                        </MuiPickersUtilsProvider>
                      </Box>

                      <Box
                        display='flex'
                        flexGrow={1}
                        // alignItems='flex-end'
                        // justifyContent='center'
                        p={0.5}
                        width='20%'
                        flexDirection='column'
                      >
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <WelloFormItemLabel title='Passport expiry Date' />
                          <KeyboardDatePicker
                            clearable
                            id='date_expiry'
                            minDate={moment()}
                            //   minDate={moment(
                            //     getAgeOfPatientForChecking(
                            //       fhirAppointmentDetails.patient
                            //     )
                            //   )}
                            value={existingPatient.passportEndDate}
                            format='DD-MM-YYYY'
                            name='passportEndDate'
                            // style={{
                            //   maxWidth: '480px',
                            //   width: '285px',
                            // }}
                            onChange={(e) =>
                              setExistingPatient({
                                ...existingPatient,
                                passportEndDate:
                                  e !== null ? moment(e).toDate() : new Date(),
                              })
                            }
                            error={
                              validateDateForPassportValidTill(
                                moment(existingPatient.passportEndDate).format(
                                  'YYYY-MM-DD'
                                ),
                                moment(
                                  existingPatient.passportStartDate
                                ).format('YYYY-MM-DD'),
                                true
                              ).length > 0
                            }
                            helperText={validateDateForPassportValidTill(
                              moment(existingPatient.passportEndDate).format(
                                'YYYY-MM-DD'
                              ),
                              moment(existingPatient.passportStartDate).format(
                                'YYYY-MM-DD'
                              ),
                              true
                            )}
                            size='small'
                            inputVariant='outlined'
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Box>
                  )}

                {existingPatient.nationalityCoding.code &&
                  existingPatient.nationalityCoding.code !== 'IN' && (
                    <Box width='100%' display='flex' flexDirection='row'>
                      <Box width='30%' p={0.5}>
                        <WelloTextField
                          title='Visa Number'
                          textProps={{
                            id: `visaNo`,
                            value: existingPatient.visaNo,
                            error: existingPatient.visaNo.length === 0,
                            helperText:
                              existingPatient.visaNo.length === 0
                                ? 'Visa Number required'
                                : '',
                            disabled: false,
                            onChange: (selectedAddress) => {
                              setExistingPatient({
                                ...existingPatient,
                                visaNo: selectedAddress.target.value,
                              })
                            },
                          }}
                        />
                      </Box>

                      <Box width='70%' p={0.5} flexDirection='column'>
                        <WelloSelectFHIRCoding
                          title='Visa type'
                          id='visaType'
                          availableCodings={
                            t(
                              'formCEntry:entry_attrs.visaType.value-set'
                            ) as R4.ICoding[]
                          }
                          preSelectedCoding={existingPatient.visaTypeCoding}
                          onChanged={(val) => {
                            setExistingPatient({
                              ...existingPatient,
                              visaTypeCoding: val,
                            })
                          }}
                          textFieldProps={{
                            fullWidth: true,
                            required: false,
                            size: 'small',
                            disabled: false,
                            // style: {
                            //   maxWidth: '480px',
                            //   width: '300px',
                            // },

                            id: 'patient_visa_proof_type',
                          }}
                        />
                        {existingPatient.visaTypeCoding === undefined && (
                          <Box px={0.5}>
                            <Typography variant='subtitle2' color='error'>
                              Vis Type is required
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}

                {existingPatient.nationalityCoding.code &&
                  existingPatient.nationalityCoding.code !== 'IN' && (
                    <Box width='100%' display='flex' flexDirection='row'>
                      <Box width='50%' px={0.5}>
                        <WelloPlaceFinder
                          title='Place of issue (Visa)'
                          nationality={
                            existingPatient.nationalityCoding.code ?? ''.trim()
                          }
                          textProps={{
                            required: true,

                            id: 'patient_visa_city',
                            placeholder: 'Visa City',
                            name: 'visaIssueCity',
                            value:
                              existingPatient.visaIssueCity.length > 0
                                ? existingPatient.visaIssueCity
                                : '',
                            onChange: (changedValue: any) => {
                              //   handleLabAddressChange(changedValue.target.value)
                            },
                          }}
                          valueData={
                            existingPatient.visaIssueCity.length > 0
                              ? existingPatient.visaIssueCity
                              : ''
                          }
                          mapkey=''
                          onUpdated={(
                            address: string,
                            mapLatlog: {
                              lat: number
                              lng: number
                            }
                          ) => {
                            if (address.split(',').length > 2) {
                              setExistingPatient({
                                ...existingPatient,
                                visaIssueCity: address
                                  .split(',')[0]
                                  .replaceAll(',', '')
                                  .trim(),
                              })

                              if (address.split(',').length === 4) {
                                setExistingPatient({
                                  ...existingPatient,
                                  visaIssueCity: address
                                    .split(',')[3]
                                    .replaceAll(',', '')
                                    .trim(),
                                })
                              } else {
                                setExistingPatient({
                                  ...existingPatient,
                                  visaIssueCity: address
                                    .split(',')[2]
                                    .replaceAll(',', '')
                                    .trim(),
                                })
                              }
                            } else {
                              setExistingPatient({
                                ...existingPatient,
                                visaIssueCity: address
                                  .split(',')[0]
                                  .replaceAll(',', '')
                                  .trim(),
                              })
                            }
                          }}
                        />
                      </Box>
                      <Box width='50%' px={0.5}>
                        <WelloTextField
                          title='Visa Issue Country'
                          textProps={{
                            id: `visaCountry`,
                            value:
                              existingPatient.nationalityCoding.display ?? '',
                            disabled: true,
                            // error:
                            //   hasErrors &&
                            //   validateLRNumber(
                            //     existingPatient.labReference,
                            //     false
                            //   ).length > 0,
                            // helperText:
                            //   hasErrors &&
                            //   validateLRNumber(
                            //     existingPatient.labReference,
                            //     false
                            //   ).length > 0
                            //     ? `${validateLRNumber(
                            //         existingPatient.labReference,
                            //         false
                            //       )}`
                            //     : '',
                            onChange: (select) => {
                              //   setExistingPatient({
                              //     ...existingPatient,
                              //     labReference: select.target.value,
                              //   })
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  )}

                {existingPatient.nationalityCoding.code &&
                  existingPatient.nationalityCoding.code !== 'IN' && (
                    <Box
                      display='flex'
                      flexGrow={1}
                      alignItems='flex-end'
                      justifyContent='center'
                      width='100%'
                    >
                      <Box
                        display='flex'
                        flexGrow={1}
                        // alignItems='flex-end'
                        // justifyContent='center'
                        flexDirection='column'
                        p={0.5}
                        width='20%'
                      >
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <WelloFormItemLabel title='Visa Issue Date' />
                          <KeyboardDatePicker
                            clearable
                            id='VisaIssueDate'
                            maxDate={moment()}
                            minDate={existingPatient.dob}
                            //   minDate={moment(
                            //     getAgeOfPatientForChecking(
                            //       fhirAppointmentDetails.patient
                            //     )
                            //   )}
                            value={existingPatient.visaIssueDate}
                            format='DD-MM-YYYY'
                            name='visaIssueDate'
                            // style={{
                            //   maxWidth: '480px',
                            //   width: '285px',
                            // }}
                            onChange={(e) =>
                              setExistingPatient({
                                ...existingPatient,
                                visaIssueDate:
                                  e !== null ? moment(e).toDate() : new Date(),
                              })
                            }
                            error={
                              visaFormForPatientDateValidation(
                                moment(
                                  existingPatient.passportStartDate
                                ).format('YYYY-MM-DD'),
                                moment(existingPatient.passportEndDate).format(
                                  'YYYY-MM-DD'
                                ),
                                true,
                                existingPatient.visaIssueDate
                                  ? moment(
                                      existingPatient.visaIssueDate
                                    ).format('YYYY-MM-DD')
                                  : moment().format('YYYY-MM-DD')
                              ).length > 0
                            }
                            helperText={visaFormForPatientDateValidation(
                              moment(existingPatient.passportStartDate).format(
                                'YYYY-MM-DD'
                              ),
                              moment(existingPatient.passportEndDate).format(
                                'YYYY-MM-DD'
                              ),
                              true,
                              existingPatient.visaIssueDate
                                ? moment(existingPatient.visaIssueDate).format(
                                    'YYYY-MM-DD'
                                  )
                                : moment().format('YYYY-MM-DD')
                            )}
                            size='small'
                            inputVariant='outlined'
                          />
                        </MuiPickersUtilsProvider>
                      </Box>

                      <Box
                        display='flex'
                        flexGrow={1}
                        // alignItems='flex-end'
                        // justifyContent='center'
                        p={0.5}
                        width='20%'
                        flexDirection='column'
                      >
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <WelloFormItemLabel title='Visa Expiry Date' />
                          <KeyboardDatePicker
                            clearable
                            id='date_expiry'
                            minDate={moment()}
                            //   minDate={moment(
                            //     getAgeOfPatientForChecking(
                            //       fhirAppointmentDetails.patient
                            //     )
                            //   )}
                            value={existingPatient.visaExpiryDate}
                            format='DD-MM-YYYY'
                            name='visaEndDate'
                            // style={{
                            //   maxWidth: '480px',
                            //   width: '285px',
                            // }}
                            onChange={(e) =>
                              setExistingPatient({
                                ...existingPatient,
                                visaExpiryDate:
                                  e !== null ? moment(e).toDate() : new Date(),
                              })
                            }
                            error={
                              visaExpiryDateValidateForNewPatient(
                                moment(
                                  existingPatient.passportStartDate
                                ).format('YYYY-MM-DD'),
                                true,
                                existingPatient.visaIssueDate
                                  ? moment(
                                      existingPatient.visaIssueDate
                                    ).format('YYYY-MM-DD')
                                  : moment().format('YYYY-MM-DD'),
                                existingPatient.visaExpiryDate
                                  ? moment(
                                      existingPatient.visaExpiryDate
                                    ).format('YYYY-MM-DD')
                                  : moment().format('YYYY-MM-DD')
                              ).length > 0
                            }
                            helperText={visaExpiryDateValidateForNewPatient(
                              moment(existingPatient.passportStartDate).format(
                                'YYYY-MM-DD'
                              ),
                              true,
                              existingPatient.visaIssueDate
                                ? moment(existingPatient.visaIssueDate).format(
                                    'YYYY-MM-DD'
                                  )
                                : moment().format('YYYY-MM-DD'),
                              existingPatient.visaExpiryDate
                                ? moment(existingPatient.visaExpiryDate).format(
                                    'YYYY-MM-DD'
                                  )
                                : moment().format('YYYY-MM-DD')
                            )}
                            size='small'
                            inputVariant='outlined'
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Box>
                  )}

                <Box display='flex' justifyContent='center' width='100%'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    p={0.5}
                    width='50%'
                  >
                    <WelloTextField
                      title={
                        existingPatient.nationalityCoding.code &&
                        existingPatient.nationalityCoding.code !== 'IN'
                          ? 'Permanent Address'
                          : t('labelPatient:attr_labels.Patient.address')
                      }
                      textProps={{
                        id: `address`,
                        multiline: true,
                        rows: 3,
                        value: existingPatient.address,
                        error:
                          hasErrors &&
                          validateAddress(
                            existingPatient.address,
                            isLab === false
                          ).length > 0,
                        helperText:
                          hasErrors &&
                          validateAddress(
                            existingPatient.address,
                            isLab === false
                          ).length > 0
                            ? `${validateAddress(
                                existingPatient.address,
                                isLab === false
                              )}`
                            : '',
                        disabled: patientAddSlice.updating,
                        onChange: (selectedAddress) => {
                          setExistingPatient({
                            ...existingPatient,
                            address: selectedAddress.target.value,
                          })
                        },
                      }}
                    />
                  </Box>
                  <Box
                    display='flex'
                    flexGrow={1}
                    alignItems='flex-start'
                    justifyContent='center'
                    p={0.5}
                    width='50%'
                  >
                    {getIdentifierValueBySystemType(
                      patientData.identifier ?? [],
                      'address-proof'
                    )!.length > 0 &&
                      getValueAttachmentFromExtension(
                        patientData.extension,
                        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
                      ) === '0' && (
                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='column'
                          justifyContent='flex-start'
                          py={1}
                        >
                          <Box px={1} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              Address Proof Front
                            </Typography>
                          </Box>
                          <Box
                            px={2}
                            display='flex'
                            width='50%'
                            flexDirection='row'
                            py={1}
                          >
                            <LogoViewer
                              contentType='image/png'
                              decodingRequired={true}
                              imagePath="Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front').value.as(Attachment)"
                              resourceType='Patient'
                              resourceId={patientData.id ?? ''}
                              onSelectionChanged={(value: SelectedFile) => {
                                setExistingPatient({
                                  ...existingPatient,
                                  frontFile: value,
                                })
                              }}
                              update={true}
                              purpose='tile8'
                            />
                            {/* <AttachmentImage
                              contentType='image/jpeg'
                              decodingRequired={false}
                              imagePath="Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-front').value.as(Attachment)"
                              resourceId={patientData.id ?? ''}
                              resourceType='Patient'
                            /> */}
                          </Box>
                        </Box>
                      )}
                    {getIdentifierValueBySystemType(
                      patientData.identifier ?? [],
                      'address-proof'
                    )!.length > 0 &&
                      getValueAttachmentFromExtension(
                        patientData.extension,
                        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
                      ) === '0' && (
                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='column'
                          justifyContent='flex-start'
                          py={1}
                        >
                          <Box px={1} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              Address Proof Back
                            </Typography>
                          </Box>
                          <Box
                            px={2}
                            display='flex'
                            width='50%'
                            flexDirection='row'
                            py={1}
                          >
                            <AttachmentImage
                              contentType='image/jpeg'
                              decodingRequired={false}
                              imagePath="Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-back').value.as(Attachment)"
                              resourceId={patientData.id ?? ''}
                              resourceType='Patient'
                            />
                          </Box>
                        </Box>
                      )}
                    {getIdentifierValueBySystemType(
                      patientData.identifier ?? [],
                      'address-proof'
                    )!.length > 0 &&
                      getValueAttachmentFromExtension(
                        patientData.extension,
                        'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
                      ) === '1' && (
                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='column'
                          py={1}
                        >
                          <Box px={1} width='50%' justifyContent='flex-start'>
                            <Typography
                              color='initial'
                              variant='body1'
                              style={{ wordWrap: 'break-word' }}
                            >
                              Address Proof
                            </Typography>
                          </Box>
                          <Box
                            px={2}
                            display='flex'
                            width='50%'
                            flexDirection='row'
                          >
                            <LogoViewer
                              contentType={getValueAttachmentFromExtensionContentType(
                                patientData.extension,
                                'http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both'
                              )}
                              decodingRequired={true}
                              imagePath="Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both').value.as(Attachment)"
                              resourceType='Patient'
                              name='Select Proof'
                              resourceId={patientData.id ?? ''}
                              onSelectionChanged={(value: SelectedFile) => {
                                setExistingPatient({
                                  ...existingPatient,
                                  bothFile: value,
                                })
                              }}
                              update={true}
                              purpose='tile8'
                            />

                            {/* <AttachmentImage
                              contentType='image/jpeg'
                              decodingRequired={false}
                              imagePath="Patient.extension('http://wellopathy.com/fhir/india/core/CodeSystem/address-proof-attachment-both').value.as(Attachment)"
                              resourceId={patientData.id ?? ''}
                              resourceType='Patient'
                            /> */}
                          </Box>
                        </Box>
                      )}
                  </Box>
                </Box>

                {existingPatient.nationalityCoding.code &&
                  existingPatient.nationalityCoding.code === 'IN' && (
                    <Box
                      display='flex'
                      flexGrow={1}
                      alignItems='flex-end'
                      justifyContent='center'
                      width='100%'
                    >
                      <Box
                        display='flex'
                        flexGrow={1}
                        alignItems='flex-start'
                        justifyContent='center'
                        p={0.5}
                        width='20%'
                      >
                        <WelloPlaceFinder
                          title='Select City'
                          nationality={
                            existingPatient.nationalityCoding.code ?? ''.trim()
                          }
                          textProps={{
                            required: true,
                            id: 'unit_actor_current_address',
                            placeholder: 'Your Home address',
                            name: 'city',
                            value:
                              existingPatient.city.length > 0
                                ? existingPatient.city
                                : '',
                            onChange: (changedValue: any) => {
                              //   handleLabAddressChange(changedValue.target.value)
                            },
                          }}
                          valueData={
                            existingPatient.city.length > 0
                              ? existingPatient.city
                              : ''
                          }
                          mapkey=''
                          onUpdated={(
                            address: string,
                            mapLatlog: {
                              lat: number
                              lng: number
                            }
                          ) => {
                            if (address.split(',').length > 2) {
                              setExistingPatient({
                                ...existingPatient,
                                city: address
                                  .split(',')[0]
                                  .replaceAll(',', '')
                                  .trim(),
                                country: address
                                  .split(',')[2]
                                  .replaceAll(',', '')
                                  .trim(),
                              })
                            } else {
                              setExistingPatient({
                                ...existingPatient,
                                city: address
                                  .split(',')[0]
                                  .replaceAll(',', '')
                                  .trim(),
                                country: address
                                  .split(',')[0]
                                  .replaceAll(',', '')
                                  .trim(),
                              })
                            }
                          }}
                        />
                      </Box>
                      {existingPatient.country && (
                        <Box
                          display='flex'
                          flexGrow={1}
                          alignItems='flex-end'
                          justifyContent='center'
                          p={0.5}
                          width='20%'
                        >
                          <WelloTextField
                            title='Country'
                            textProps={{
                              id: `country`,
                              value: existingPatient.country,
                              disabled: true,
                              // error:
                              //   hasErrors &&
                              //   validateLRNumber(
                              //     existingPatient.labReference,
                              //     false
                              //   ).length > 0,
                              // helperText:
                              //   hasErrors &&
                              //   validateLRNumber(
                              //     existingPatient.labReference,
                              //     false
                              //   ).length > 0
                              //     ? `${validateLRNumber(
                              //         existingPatient.labReference,
                              //         false
                              //       )}`
                              //     : '',
                              onChange: (select) => {
                                //   setExistingPatient({
                                //     ...existingPatient,
                                //     labReference: select.target.value,
                                //   })
                              },
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  )}

                {existingPatient.nationalityCoding.code &&
                  existingPatient.nationalityCoding.code !== 'IN' && (
                    <Box
                      display='flex'
                      justifyContent='flex-start'
                      width='100%'
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='50%'
                        p={0.5}
                      >
                        {existingPatient.city.length > 0 && (
                          <WelloPlaceFinder
                            title='Permanent Address City'
                            nationality={existingPatient.nationalityCoding.code}
                            textProps={{
                              required: true,
                              id: 'unit_actor_current_address_city',
                              placeholder: 'Your Home address',
                              name: 'city',
                              value: 'Bangalore',
                              onChange: (changedValue: any) => {
                                //   handleLabAddressChange(changedValue.target.value)
                              },
                            }}
                            valueData={
                              existingPatient.city.length > 0
                                ? existingPatient.city
                                : ''
                            }
                            mapkey=''
                            onUpdated={(
                              address: string,
                              mapLatlog: {
                                lat: number
                                lng: number
                              }
                            ) => {
                              if (address.split(',').length > 2) {
                                setExistingPatient({
                                  ...existingPatient,
                                  city: address
                                    .split(',')[0]
                                    .replaceAll(',', '')
                                    .trim(),
                                  state: address
                                    .split(',')[1]
                                    .replaceAll(',', '')
                                    .trim(),
                                })
                              } else {
                                setExistingPatient({
                                  ...existingPatient,
                                  city: address
                                    .split(',')[0]
                                    .replaceAll(',', '')
                                    .trim(),
                                  state: address
                                    .split(',')[0]
                                    .replaceAll(',', '')
                                    .trim(),
                                })
                              }
                            }}
                          />
                        )}
                        {existingPatient.city.length === 0 && (
                          <WelloPlaceFinder
                            title='Permanent Address City'
                            nationality={existingPatient.nationalityCoding.code}
                            textProps={{
                              required: true,
                              id: 'unit_actor_current_address_city',
                              placeholder: 'Your Home address',
                              name: 'city',
                              value: '',
                              onChange: (changedValue: any) => {
                                //   handleLabAddressChange(changedValue.target.value)
                              },
                            }}
                            valueData=''
                            mapkey=''
                            onUpdated={(
                              address: string,
                              mapLatlog: {
                                lat: number
                                lng: number
                              }
                            ) => {
                              if (address.split(',').length > 2) {
                                setExistingPatient({
                                  ...existingPatient,
                                  city: address
                                    .split(',')[0]
                                    .replaceAll(',', '')
                                    .trim(),
                                  state: address
                                    .split(',')[1]
                                    .replaceAll(',', '')
                                    .trim(),
                                })
                              } else {
                                setExistingPatient({
                                  ...existingPatient,
                                  city: address
                                    .split(',')[0]
                                    .replaceAll(',', '')
                                    .trim(),
                                  state: address
                                    .split(',')[0]
                                    .replaceAll(',', '')
                                    .trim(),
                                })
                              }
                            }}
                          />
                        )}
                        {existingPatient.city.length === 0 && (
                          <Box px={0.5} py={0.2}>
                            {' '}
                            <Typography variant='subtitle2' color='error'>
                              City is required
                            </Typography>
                          </Box>
                        )}
                      </Box>

                      {existingPatient.country && (
                        <Box
                          display='flex'
                          justifyContent='flex-start'
                          p={0.5}
                          width='50%'
                          flexDirection='row'
                        >
                          <WelloTextField
                            title='Country'
                            textProps={{
                              id: `country`,
                              value: existingPatient.country,
                              disabled: true,
                              // error:
                              //   hasErrors &&
                              //   validateLRNumber(
                              //     existingPatient.labReference,
                              //     false
                              //   ).length > 0,
                              // helperText:
                              //   hasErrors &&
                              //   validateLRNumber(
                              //     existingPatient.labReference,
                              //     false
                              //   ).length > 0
                              //     ? `${validateLRNumber(
                              //         existingPatient.labReference,
                              //         false
                              //       )}`
                              //     : '',
                              onChange: (select) => {
                                //   setExistingPatient({
                                //     ...existingPatient,
                                //     labReference: select.target.value,
                                //   })
                              },
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                {existingPatient.nationalityCoding.code &&
                  existingPatient.nationalityCoding.code !== 'IN' && (
                    <Box
                      display='flex'
                      flexGrow={1}
                      alignItems='flex-end'
                      justifyContent='center'
                      width='100%'
                    >
                      <Box
                        display='flex'
                        flexGrow={1}
                        alignItems='flex-start'
                        justifyContent='center'
                        p={0.5}
                        width='20%'
                      >
                        <WelloTextField
                          title='Address In India'
                          textProps={{
                            id: `address_in_india`,
                            multiline: true,
                            rows: 3,
                            value: existingPatient.tempAddress,
                            error:
                              hasErrors &&
                              validateTemporaryAddress(
                                existingPatient.tempAddress,
                                true
                              ).length > 0,
                            helperText:
                              hasErrors &&
                              validateTemporaryAddress(
                                existingPatient.tempAddress,
                                true
                              ).length > 0
                                ? `${validateTemporaryAddress(
                                    existingPatient.tempAddress,
                                    true
                                  )}`
                                : '',
                            disabled: patientAddSlice.updating,
                            onChange: (selectedAddress) => {
                              setExistingPatient({
                                ...existingPatient,
                                tempAddress: selectedAddress.target.value,
                              })
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  )}

                <WebCamCapturePhoto
                  id='patient_profile'
                  open={openWebCam}
                  onBackClick={() => {
                    setOpenWebCam(false)
                  }}
                  cropAspectRatio={aspectRatio}
                  onContinueClick={(imgSrc) => {
                    const data = imgSrc
                      .toString()
                      .split(',')[0]
                      .replace('data:', '')
                      .replace(';base64', '')
                    callBackForCapturePhoto(
                      imgSrc.toString().split(',')[1],
                      imgSrc!.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)![0]!,
                      imgSrc,
                      data
                    )
                  }}
                />
                <Box
                  display='flex'
                  alignItems='flex-start'
                  justifyContent='flex-start'
                  width='100%'
                  paddingTop={1}
                >
                  <Button
                    variant='contained'
                    color='primary'
                    id='submit'
                    disabled={patientAddSlice.updating}
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    {t('labelCommon:save_patient')}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}
