import { R4 } from '@ahryman40k/ts-fhir-types'
import { CircularProgress, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import {
  getCatalogForTimeOfDay,
  getCatalogName,
} from 'utils/fhirResoureHelpers/catalogHelper'

export interface CatalogEntrySelectorProps {
  isInEditMode: boolean
  id: string
  searchType: string
  onRadioOptionsChanged: (changedValue: R4.ICatalogEntry[]) => void
  selectedValue?: R4.ICatalogEntry[]
  placeHolderString?: string
  catalogEntryPath?: string
  detachLabel?: boolean
  labelString?: string
  selectedId?: string
}

export const CatalogSelector: React.FC<CatalogEntrySelectorProps> = ({
  selectedValue: selectedValues,
  isInEditMode,
  placeHolderString,
  detachLabel,
  labelString,
  id,
  searchType,
  onRadioOptionsChanged,
  selectedId,
}: CatalogEntrySelectorProps) => {
  const addDietSlice = useSelector(
    (state: RootState) => state.nutritionCatalogueValueSetSlice
  )
  const [loading, setLoading] = React.useState(addDietSlice.searchingTreatment)
  const [options, setOptions] = React.useState<R4.ICatalogEntry[]>(
    addDietSlice.searchingTreatment === true
      ? []
      : getCatalogForTimeOfDay(
          addDietSlice.catalogueEntries ?? [],
          searchType,
          true
        )
  )

  useEffect(() => {
    if (!addDietSlice.searchingTreatment) {
      setLoading(false)
      setOptions(
        getCatalogForTimeOfDay(
          addDietSlice.catalogueEntries ?? [],
          searchType,
          true
        )
      )
    }
  }, [addDietSlice.searchingTreatment])

  return (
    <Autocomplete
      multiple
      id={id}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => getCatalogName(option)}
      fullWidth
      options={options}
      loading={loading}
      onChange={(e, changedValue) => {
        if (changedValue != null) {
          onRadioOptionsChanged(changedValue)
        }
      }}
      value={selectedValues}
      renderInput={(params) => (
        <TextField
          {...params}
          id={`${id}search`}
          label={labelString}
          variant='outlined'
          size='small'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
            style: {
              borderRadius: 6,
              //   maxWidth: '480px',
              width: '145px',
            },
          }}
        />
      )}
    />
  )
}
