import MaterialTable, { Column, MTableToolbar } from '@material-table/core'
import {
  Box,
  Button,
  makeStyles,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import {
  kBackgroundDefault,
  kDialogueBackground,
} from 'configs/styles/muiThemes'
import _ from 'lodash'
import { diff } from 'json-diff'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import { searchActivePractitionersForCrossCare } from 'redux/practitioner/crossCarePractitionerSearchHandler/crossCarepractitionerSearchSlice'
import { RootState } from 'redux/rootReducer'
import { getRolesAsStringFromPractRole } from 'services/userDetailsService'
import {
  getCrossFunctionalStatusForPractitionerRole,
  updateCareStatusOfSelectedPractitioners,
} from 'utils/admisnistration/crossFunctional/crossFunctinalUtils'
import { getSpecializationsList } from 'utils/fhirResourcesHelper'
import OverflowTwoLineTypography from 'views/components/consultation/plan/ayurveda/overflowTwoLineTypography'
import OverflowTypography from 'views/components/consultation/plan/ayurveda/overflowTypography'
import WelloSwitch from 'views/components/WelloSwitch'
import { WelloLoadingIndicator } from 'wello-web-components'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '2px',
      margin: '0px',
    },

    '& .MuiTableRow': {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(33, 150, 243, 0.5)',
        },
      },
    },
  },
})

interface ProviderBasedCrossFunctionalListTableProps {
  crossFunctionalHCSId: string
}

export const ProviderBasedCrossFunctionalListTable: React.FC<ProviderBasedCrossFunctionalListTableProps> =
  ({ crossFunctionalHCSId }: ProviderBasedCrossFunctionalListTableProps) => {
    const ref = React.useRef<null | HTMLDivElement>(null)
    const dispatch = useDispatch()
    const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })

    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const practitionerSearchState = useSelector(
      (state: RootState) => state.crossCarePractitionerSearchSlice
    )

    const [userCareStatus, setCareStatus] = useState<{
      [key: string]: boolean
    }>({})

    const [userCurrentCareStatus, setCurrentCareStatus] = useState<{
      [key: string]: boolean
    }>({})

    useLayoutEffect(() => {
      if (ref !== null && ref.current !== null && ref && ref.current) {
        setWidth(ref.current.offsetWidth)
        setHeight(ref.current.offsetHeight)
      }
    }, [])
    const classes = useStyles()

    const [selectedRowId, setSelectedRowId] = React.useState('')
    const [selectedRow, setSelectedRow] = React.useState(false)

    useEffect(() => {
      dispatch(searchActivePractitionersForCrossCare())
    }, [])

    useEffect(() => {
      if (
        practitionerSearchState.resultsAvailable &&
        practitionerSearchState.doctorRoleList &&
        practitionerSearchState.doctorRoleList.length > 0
      ) {
        const newMap: any = {}
        practitionerSearchState.doctorRoleList.forEach((doctor) => {
          if (doctor.roleObject.id) {
            newMap[doctor.roleObject.id] =
              getCrossFunctionalStatusForPractitionerRole(
                doctor.roleObject,
                crossFunctionalHCSId
              )
          }
        })
        setCareStatus(newMap)
        setCurrentCareStatus(newMap)
      }
    }, [practitionerSearchState])

    function updateCareStatus() {
      const dif = diff(userCareStatus, userCurrentCareStatus, {
        outputNewOnly: true,
      })
      console.log('--------dif--------', dif)
      const arrRep: {
        practitionerRoleId: string
        interest: boolean
      }[] = Object.keys(dif).map((key) => ({
        practitionerRoleId: key,
        interest: dif[key],
      }))

      console.log('--------dif--------', arrRep)

      setUpdateStatus({ requesting: true })
      updateCareStatusOfSelectedPractitioners({
        hcsId: crossFunctionalHCSId,
        practitionerListWithInterest: arrRep,
      }).then((e) => {
        if (e !== false) {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(showSuccessAlert('Care status updated successfully'))
          dispatch(searchActivePractitionersForCrossCare())
        } else {
          setUpdateStatus({
            requesting: false,
            requestError: false,
          })
          dispatch(
            showErrorAlert('Error while updating care status. Please try again')
          )
        }
      })
    }

    const columns: Column<PractitionerWithRole>[] = [
      {
        title: (
          <Box minWidth='150px' minHeight='12px' maxHeight='12px'>
            <Typography
              variant='subtitle1'
              style={{
                fontSize: 14,

                fontWeight: 'bold',
                lineHeight: 'normal',
              }}
              color='primary'
            >
              User Name
            </Typography>
          </Box>
        ),

        width: '30%',
        field: 'fullName',
        customSort: (a: PractitionerWithRole, b: PractitionerWithRole) =>
          a.fullName > b.fullName ? 1 : b.fullName > a.fullName ? -1 : 0,

        hiddenByColumnsButton: false,
        defaultFilter: '',
        //   defaultSort: 'asc',
        render: (rowData: PractitionerWithRole) => (
          <Box display='flex' alignItems='center'>
            <OverflowTypography
              text={rowData.fullName ?? ''}
              typographyProps={{
                variant: 'subtitle2',
                noWrap: true,
              }}
            />
          </Box>
        ),
        filtering: true,
      },

      {
        title: (
          <Box>
            <Typography
              variant='subtitle1'
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 'normal',
              }}
              color='primary'
            >
              Roles
            </Typography>
          </Box>
        ),

        field: 'roleObject',
        customSort: (a: PractitionerWithRole, b: PractitionerWithRole) =>
          (getRolesAsStringFromPractRole(a.roleObject) ?? '') >
          (getRolesAsStringFromPractRole(b.roleObject) ?? '')
            ? 1
            : (getRolesAsStringFromPractRole(b.roleObject) ?? '') >
              (getRolesAsStringFromPractRole(a.roleObject) ?? '')
            ? -1
            : 0,

        hiddenByColumnsButton: false,
        filtering: false,
        width: '50%',
        render: (rowData: PractitionerWithRole) => (
          <Box display='flex' alignItems='center'>
            <OverflowTwoLineTypography
              text={getRolesAsStringFromPractRole(rowData.roleObject) ?? ''}
              typographyProps={{
                variant: 'subtitle2',
                noWrap: false,
              }}
            />
          </Box>
        ),
      },
      {
        title: (
          <Box>
            <Typography
              variant='subtitle1'
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 'normal',
              }}
              color='primary'
            >
              Specialization
            </Typography>
          </Box>
        ),

        field: 'roleObject',
        customSort: (a: PractitionerWithRole, b: PractitionerWithRole) =>
          (getSpecializationsList(a.roleObject.specialty ?? []) ?? '') >
          (getSpecializationsList(b.roleObject.specialty ?? []) ?? '')
            ? 1
            : (getSpecializationsList(b.roleObject.specialty ?? []) ?? '') >
              (getSpecializationsList(a.roleObject.specialty ?? []) ?? '')
            ? -1
            : 0,

        hiddenByColumnsButton: false,
        filtering: false,
        width: '50%',
        render: (rowData: PractitionerWithRole) => (
          <Box display='flex' alignItems='center'>
            <OverflowTwoLineTypography
              text={
                getSpecializationsList(rowData.roleObject.specialty ?? []) ?? ''
              }
              typographyProps={{
                variant: 'subtitle2',
                noWrap: false,
              }}
            />
          </Box>
        ),
      },
      {
        title: (
          <Box>
            <Typography
              variant='subtitle1'
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 'normal',
              }}
              color='primary'
            >
              Visible/Hidden
            </Typography>
          </Box>
        ),

        field: 'roleObject',
        customSort: (a: PractitionerWithRole, b: PractitionerWithRole) =>
          (getSpecializationsList(a.roleObject.specialty ?? []) ?? '') >
          (getSpecializationsList(b.roleObject.specialty ?? []) ?? '')
            ? 1
            : (getSpecializationsList(b.roleObject.specialty ?? []) ?? '') >
              (getSpecializationsList(a.roleObject.specialty ?? []) ?? '')
            ? -1
            : 0,

        hiddenByColumnsButton: false,
        filtering: false,
        width: '50%',
        render: (rowData: PractitionerWithRole) => (
          <Box display='flex' alignItems='center'>
            <WelloSwitch
              disabled={updateStatus.requesting}
              size='medium'
              checked={
                userCurrentCareStatus !== undefined
                  ? userCurrentCareStatus[rowData.roleObject.id!] ?? false
                  : false
              }
              onChange={(event, checked) => {
                if (userCurrentCareStatus) {
                  const currentUStatus = { ...userCurrentCareStatus }
                  currentUStatus[rowData.roleObject.id!] = checked

                  setCurrentCareStatus(currentUStatus)
                }
                // setSearchableStatus(checked)
              }}
              name='Searchable'
            />
          </Box>
        ),
      },
    ]

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
        ref={ref}
      >
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }) => (
            <div role='alert'>
              <div>Error while fetching provider list</div>
              <pre>{error.message}</pre>
              <Button
                onClick={() => {
                  dispatch(searchActivePractitionersForCrossCare())
                }}
              >
                Try again
              </Button>
            </div>
          )}
        >
          <div>
            {practitionerSearchState.searching && (
              <WelloLoadingIndicator message='Searching doctors' />
            )}
            {practitionerSearchState.error && (
              <Typography>
                {' '}
                {practitionerSearchState.errorMessage ??
                  'Error while searching'}
              </Typography>
            )}
            {practitionerSearchState.noResultsAvailable && (
              <Typography> No Results available</Typography>
            )}
            {practitionerSearchState.resultsAvailable &&
              practitionerSearchState.doctorRoleList && (
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  height='100%'
                >
                  <MaterialTable
                    components={{
                      Toolbar: (props) => (
                        <div
                          /* className={classes.toolbarWrapper} */
                          style={{
                            minHeight: '0px',
                          }}
                        >
                          <MTableToolbar {...props} />
                        </div>
                      ),
                      Container: (props) => (
                        <Paper
                          {...props}
                          elevation={0}
                          style={{
                            maxHeight: '100%',
                            height: '100%',
                            backgroundColor:
                              useTheme().palette.background.paper,
                          }}
                          classes={classes}
                        />
                      ),
                    }}
                    columns={columns}
                    data={practitionerSearchState.doctorRoleList}
                    onOrderChange={(orderBy, orderDirection) => {
                      const key = orderBy < 1 ? 0 : orderBy
                    }}
                    onRowClick={(event, rowData) => {
                      /* if (rowData && onOrgSelected) {
            onOrgSelected(rowData.orgDetails, rowData.mainOrgId ?? '')
            setSelectedRowId(rowData ? rowData.orgName ?? '' : '')
          } */
                    }}
                    options={{
                      toolbar: false,
                      search: false,
                      showTitle: false,
                      columnResizable: false,
                      paginationPosition: 'bottom',
                      minBodyHeight: height - 120,
                      maxBodyHeight: height - 120,
                      padding: 'dense',
                      searchFieldVariant: 'outlined',
                      filtering: false,
                      pageSize: 20,
                      emptyRowsWhenPaging: false,
                      pageSizeOptions: [20, 30, 40],
                      //   columnsButton: true,
                      // maxBodyHeight: `${height - 190}px`,
                      //   minBodyHeight: `${height - 260}px`,
                      tableLayout: 'fixed',
                      sorting: true,
                      thirdSortClick: false,

                      headerStyle: {
                        backgroundColor: kBackgroundDefault,
                        width: 100,
                        minHeight: '12px',
                        maxHeight: '12px',
                        position: 'sticky',
                        top: 0,
                        zIndex: 100,
                        // height: 20,
                      },
                      selectionProps: (_rowData: any) => ({
                        color: 'primary',
                      }),
                      actionsColumnIndex: -1,
                      searchFieldAlignment: 'left',
                      rowStyle: (
                        _data: PractitionerWithRole,
                        index: number,
                        _level: number
                      ) =>
                        index % 2
                          ? selectedRow && _data.id === selectedRowId
                            ? {
                                backgroundColor: '#9E9DDC',

                                '&:hover': { backgroundColor: 'red' },
                              }
                            : {
                                backgroundColor: kDialogueBackground,
                                '&:hover': { backgroundColor: 'red' },
                              }
                          : selectedRow && _data.id === selectedRowId
                          ? {
                              backgroundColor: '#9E9DDC',

                              '&:hover': { backgroundColor: 'red' },
                            }
                          : { '&:hover': { backgroundColor: 'red' } },
                    }}
                    localization={{
                      pagination: {
                        labelDisplayedRows: '{from}-{to} of {count}',
                      },
                      toolbar: {
                        nRowsSelected: '{0} row(s) selected',
                      },
                      header: {
                        actions: '',
                      },
                      body: {
                        emptyDataSourceMessage: 'No records to display',
                        filterRow: {
                          filterTooltip: 'Filter',
                        },
                      },
                    }}
                  />
                  <Box display='flex' flexDirection='row'>
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={_.isEqual(
                        userCareStatus,
                        userCurrentCareStatus
                      )}
                      onClick={() => {
                        updateCareStatus()
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              )}
          </div>
        </ErrorBoundary>
      </div>
    )
  }
