import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography,
  Radio,
  useTheme,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'

import React, { useState } from 'react'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getDisplayOfSystemFromCodableConcept,
} from 'utils/fhirResourcesHelper'

interface Props {
  code: R4.ICodeableConcept
  id: string
  options: R4.ICodeableConcept[]
  onSelectionChanges?: (e: R4.IObservation_Component) => void
  preSelectedCode?: string
  isOdd?: boolean
}
export const RadioSelectorForObservationComponent: React.FC<Props> = ({
  code,
  id,
  options,
  onSelectionChanges,
  preSelectedCode,
  isOdd = false,
}) => {
  const [selectedOptionsCode, setSelectedOptionsCode] =
    useState<string | undefined>(preSelectedCode)
  const theme = useTheme()
  return (
    <Grid
      container
      spacing={0}
      style={{
        padding: theme.spacing(1),
        backgroundColor: isOdd ? '#f5f5f5' : kDialogueBackground,
      }}
    >
      <Grid
        item
        xs={3}
        style={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          paddingLeft: 1,
        }}
      >
        <Typography variant='body1' color='initial'>
          {getDisplayOfSystemFromCodableConcept(code)}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <FormControl component='fieldset'>
          <RadioGroup
            aria-label=''
            name=''
            id={`${id}radio`}
            value={selectedOptionsCode}
            onChange={(value) => {
              setSelectedOptionsCode(value.target.value)

              if (onSelectionChanges) {
                onSelectionChanges({
                  code,
                  valueCodeableConcept: options.find(
                    (option) =>
                      getDefaultCodeOfSystemFromCodableConcept(option) ===
                      value.target.value
                  ),
                })
              }
            }}
          >
            <Grid container spacing={0}>
              {options.map((option, index: number) => (
                <Grid
                  item
                  xs={options.length === 3 ? 4 : 3}
                  key={getDefaultCodeOfSystemFromCodableConcept(option)}
                >
                  <FormControlLabel
                    value={getDefaultCodeOfSystemFromCodableConcept(option)}
                    label={
                      <Typography variant='body1' color='initial'>
                        {getDisplayOfSystemFromCodableConcept(option)}
                      </Typography>
                    }
                    control={
                      <Radio
                        id={`${id}${index}`}
                        color='secondary'
                        size='small'
                        value={getDefaultCodeOfSystemFromCodableConcept(option)}
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  )
}
