/* eslint-disable react/display-name */
import MaterialTable, { MTableToolbar } from '@material-table/core'
import {
  Box,
  makeStyles,
  Paper,
  TablePagination,
  Typography,
  useTheme,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { Package } from 'models/package'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getRolesAsStringFromPractRole } from 'services/userDetailsService'
import { titleCase } from 'utils/fhirResourcesHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { AddPartnerLabHandler } from 'views/components/administration/actors/add_partner_lab'
import { EditPackage } from './editPackage'

const useStyles = makeStyles({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
      // padding: 4,
    },
  },
})

interface ICardProperties {
  usersData: Package[]
  onPackageEdit: (selectedDetail: Package) => void
  onDelete: (selectedDetail: Package) => void
}

export const PackageDataTable: React.FC<ICardProperties> = ({
  usersData,
  onPackageEdit,
  onDelete,
}: //   onUserSelected,
ICardProperties) => {
  const classes = useStyles()
  const convertedData = usersData

  const [data, setData] = useState([...convertedData])
  const { height, width } = useWindowDimensions()
  const [selectedRowId, setSelectedRowId] = React.useState('')
  const [selectedRow, setSelectedRow] = React.useState(false)
  const [nameFilter, setNameFilter] = useState('')
  const [nameFocus, setNameFocus] = useState(true)

  const [cohortFilter, setCohortFilter] = useState('')
  const [cohortFocus, setCohortFocus] = useState(true)
  const [priceFilter, setPriceFilter] = useState('')
  const [priceFocus, setPriceFocus] = useState(false)

  const [descFilter, setDescFilter] = useState('')
  const [descFocus, setDescFocus] = useState(false)
  const [statusFilter, setStatusFilter] = useState('')
  const [statusDesc, setStatusDesc] = useState(false)

  const { t } = useTranslation()
  const [showPopup, setShowPopup] = React.useState<boolean>(false)
  const handleDarkModeChange = (event: any, rowData: any) => {
    onPackageEdit(rowData)
  }

  const handleDelete = (event: any, rowData: any) => {
    onDelete(rowData)
  }

  const filterStatus = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.status
          .replace(/\s+/g, '')
          .toLowerCase()
          .includes(value.replace(/\s+/g, '').toLowerCase())
      )

      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setStatusFilter(value)
    setStatusDesc(true)
    setDescFocus(false)
    setNameFocus(false)
    setCohortFocus(false)
    setPriceFocus(false)
  }

  const filterDesc = (value: any, filed: string) => {
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.testList
          .replace(/\s+/g, '')
          .toLowerCase()
          .includes(value.replace(/\s+/g, '').toLowerCase())
      )

      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setStatusDesc(false)
    setDescFilter(value)
    setDescFocus(true)
    setNameFocus(false)
    setCohortFocus(false)
    setPriceFocus(false)
  }

  const filterPrice = (value: any, filed: string) => {
    setPriceFocus(true)
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.packagePrice.toString().includes(value)
      )
      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setCohortFocus(false)
    setNameFocus(false)
    setDescFocus(false)
    setPriceFilter(value)
    setStatusDesc(false)
  }

  const filterName = (value: any, filed: string) => {
    setNameFocus(true)
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.packageName
          .replace(/\s+/g, '')
          .toLowerCase()
          .includes(value.replace(/\s+/g, '').toLowerCase())
      )

      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setPriceFocus(false)
    setNameFilter(value)
    setCohortFocus(false)
    setDescFocus(false)
    setStatusDesc(false)
  }

  const filterCohort = (value: any, filed: string) => {
    setCohortFocus(true)
    setPriceFocus(false)
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.cohortInfo
          .replace(/\s+/g, '')
          .toLowerCase()
          .includes(value.replace(/\s+/g, '').toLowerCase())
      )

      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setCohortFilter(value)
    setNameFilter('')
    setDescFocus(false)
    setNameFocus(false)
    setStatusDesc(false)
  }

  const columns = [
    {
      title: (
        <Box minWidth='150px' minHeight='15px' maxHeight='15px'>
          <Typography variant='subtitle1' color='primary'>
            Package Name
          </Typography>
        </Box>
      ),
      field: 'packageName',
      customSort: (a: any, b: any) =>
        a.packageName > b.packageName
          ? 1
          : b.packageName > a.packageName
          ? -1
          : 0,

      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (rowData: any) => (
        <Box display='flex' alignItems='center' p={0.5}>
          <Typography variant='subtitle2' noWrap>
            {titleCase(rowData.packageName)}
          </Typography>
        </Box>
      ),
      width: 250,
      filterComponent: (props: any) => (
        <Box>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={nameFocus}
            placeholder='Package Name'
            value={nameFilter}
            onChange={(e) => filterName(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },
    {
      title: (
        <Box>
          <Typography variant='subtitle1' color='primary' noWrap>
            Cohort
          </Typography>
        </Box>
      ),
      field: 'cohortInfo',
      //   cellStyle: {
      //     width: 250,
      //     maxWidth: 250,
      //   },
      render: (rowData: any) => (
        <Box>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            {rowData.cohortInfo}
          </Typography>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={cohortFocus}
            placeholder='Cohort Info'
            value={cohortFilter}
            onChange={(e) =>
              filterCohort(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
      width: 150,
    },

    {
      title: (
        <Box>
          <Typography variant='subtitle1' color='primary' noWrap>
            Price
          </Typography>
        </Box>
      ),
      field: 'packagePrice',

      render: (rowData: any) => (
        <Box paddingY={1}>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            &#x20b9; {`${rowData.packagePrice}`}
          </Typography>
        </Box>
      ),
      width: 100,
      filterComponent: (props: any) => (
        <Box width={100} display='flex' justifyContent='flex-start'>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={priceFocus}
            placeholder='Price'
            value={priceFilter}
            onChange={(e) => filterPrice(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
    },

    {
      title: (
        <Box minHeight='15px' maxHeight='15px'>
          <Typography variant='subtitle1' color='primary' noWrap>
            Tests
          </Typography>
        </Box>
      ),
      field: 'listOfTest',
      render: (rowData: any) => (
        <Box paddingY={1} maxWidth={250}>
          <Box paddingLeft={0.5} display='flex' alignItems='center'>
            <Typography variant='subtitle2' color='textPrimary'>
              {rowData.testList}
            </Typography>
          </Box>
        </Box>
      ),
      filterComponent: (props: any) => (
        <Box>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={descFocus}
            placeholder='Tests'
            value={descFilter}
            onChange={(e) => filterDesc(e.target.value, props.columnDef.field)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
      width: 250,
    },
    {
      title: (
        <Box>
          <Typography variant='subtitle1' color='primary' noWrap>
            Status
          </Typography>
        </Box>
      ),
      field: 'status',
      filterComponent: (props: any) => (
        <Box>
          <TextField
            variant='outlined'
            size='small'
            autoFocus={statusDesc}
            placeholder='Status'
            value={statusFilter}
            onChange={(e) =>
              filterStatus(e.target.value, props.columnDef.field)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={`${process.env.PUBLIC_URL}/search.png`} alt='s' />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ),
      render: (rowData: any) => (
        <Box paddingY={1}>
          <Typography variant='subtitle2' color='textPrimary' noWrap>
            {rowData.status}
          </Typography>
        </Box>
      ),

      width: 100,
    },
  ]

  return (
    <Box style={{ overflow: 'none', width: `${width - 320}px` }}>
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                minHeight: '0px',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
          Container: (props) => <Paper {...props} elevation={0} />,
          Pagination: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                display: 'flex',
                justifyContent: 'left',
                // padding: '0px',
              }}
            >
              <TablePagination
                {...props}
                labelRowsSelect={<div style={{ fontSize: 14 }}> rows</div>}
                SelectProps={{
                  style: {
                    fontSize: 14,
                    minHeight: '5px',
                    backgroundColor: useTheme().palette.background.default,
                  },
                }}
              />
            </div>
          ),
        }}
        actions={[
          {
            icon: () => (
              <Tooltip title='Edit'>
                <IconButton
                  aria-label='btn_ord_reschedule'
                  color='primary'
                  //   onClick={() => {
                  //     setShowPopup(true)
                  //     // setPackageDetail(item)
                  //   }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/editIcon.png`}
                    alt='edit'
                    height={20}
                  />
                </IconButton>
              </Tooltip>
            ),

            onClick: handleDarkModeChange,
          },

          {
            icon: () => (
              <Tooltip title='Delete'>
                <IconButton
                  aria-label='btn_ord_cancel'
                  color='primary'
                  onClick={() => {
                    // setDeletePackage(true)
                    // setPackageDetail(item)
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/remove.png`}
                    alt='remove'
                    height={20}
                  />
                </IconButton>
              </Tooltip>
            ),

            onClick: handleDelete,
          },
        ]}
        columns={columns}
        data={data}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        onRowClick={(event, rowData) => {
          //   if (rowData) onUserSelected(rowData)
          //   setSelectedRow(true)
          //   setSelectedRowId(rowData?.id ?? '')
        }}
        options={{
          searchFieldStyle: { padding: 4 },
          toolbar: false,
          search: false,
          showTitle: false,
          padding: 'dense',
          searchFieldVariant: 'outlined',
          filtering: true,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30],
          //   columnsButton: true,
          maxBodyHeight: `${height - 169}px`,
          minBodyHeight: `${height - 240}px`,
          tableLayout: 'auto',
          sorting: true,
          thirdSortClick: false,
          headerStyle: {
            backgroundColor: useTheme().palette.background.default,
            color: '#FFF',
            width: 100,
            minHeight: '15px',
            maxHeight: '15px',
            position: 'sticky',
            alignContent: 'center',

            top: 0,
            // height: 20,
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          rowStyle: (_data: any, index: number, _level: number) =>
            index % 2
              ? selectedRow && _data.id === selectedRowId
                ? { backgroundColor: '#9E9DDC', height: 50 }
                : { backgroundColor: kDialogueBackground }
              : selectedRow && _data.id === selectedRowId
              ? { backgroundColor: '#9E9DDC', height: 50 }
              : {},
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />
    </Box>
  )
}
