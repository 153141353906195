import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import React from 'react'
import { getStartAndEndTimeWithDateInString } from 'utils/dateUtil'
import {
  getNameFromHumanName,
  getProfilePicPractitioner,
  getTelecomFromContactPoints,
} from 'utils/fhirResourcesHelper'

interface Props {
  slotDetails: FhirSlotDetail
  onTap?: () => void
  onChangeClicked?: () => void
}

export const AgentSlotTile: React.FC<Props> = ({
  slotDetails,
  onChangeClicked,
  onTap,
}: Props) => (
  <Box width='100%'>
    <Card
      elevation={0}
      style={{
        padding: 0,
        margin: 0,
      }}
    >
      <CardContent
        style={{
          padding: 8,
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <List
          id={`option_slot_${slotDetails.slot.id}` ?? ''}
          style={{ flexGrow: 1, padding: 0 }}
        >
          <ListItem style={{ flexGrow: 1, padding: 0 }}>
            <ListItemAvatar>
              <Avatar
                alt={
                  getNameFromHumanName(slotDetails.practitioner?.name ?? []) ??
                  ''
                }
                src={getProfilePicPractitioner(slotDetails.practitioner) ?? ''}
              />
            </ListItemAvatar>
            <ListItemText
              color='primary'
              primary={
                <Box py={0.25}>
                  <Typography
                    variant='subtitle1'
                    // color='primary'
                    style={{ fontWeight: 'bolder', color: 'black' }}
                  >
                    {`${getStartAndEndTimeWithDateInString(
                      slotDetails.slot.start ?? '',
                      slotDetails.slot.end ?? ''
                    )}  `}
                  </Typography>
                </Box>
              }
              secondary={
                <Box display='flex' flexDirection='column'>
                  <Box flex='flex' flexDirection='row' py={0.25}>
                    <Typography
                      component='span'
                      variant='subtitle2'
                      color='textPrimary'
                    >
                      {`With ${getNameFromHumanName(
                        slotDetails.practitioner?.name ?? []
                      )}` ?? ''}
                    </Typography>
                  </Box>
                  <Box flex='flex' flexDirection='row' py={0.25}>
                    <Typography
                      component='span'
                      variant='body2'
                      color='textPrimary'
                    >
                      {`Mob: ${getTelecomFromContactPoints(
                        slotDetails.practitioner?.telecom ?? [],
                        R4.ContactPointSystemKind._phone
                      )}`}
                    </Typography>
                  </Box>
                  {/* <Box flex='flex' flexDirection='row' py={0.25}>
                    <Link
                      component='button'
                      variant='body2'
                      onClick={() => {
                        console.info("I'm a button.")
                      }}
                    >
                      Change Agent
                    </Link>
                  </Box> */}
                </Box>
              }
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  </Box>
)
