/* eslint-disable react/jsx-key */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { TherapyDetail } from 'models/therapyDetail'
import { TherapyPerDay } from 'models/therapyPerDay'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import {
  addPhysioTherapiesScheduleDetails,
  resetAddVitalsState,
} from 'redux/ipd/physioTherapy/addPhysioTherapiesSlice/addPhysioTherapiesSlice'
import { requestForTherapiesForIpd } from 'redux/ipd/physioTherapy/viewPhysioTherapiesOfEncounter/viewPhysioTherapiesOfEncounterSlice'
import { resetProdcedureState } from 'redux/ipd/procedure/addProcedureIpdSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { isUnitAdmin } from 'services/userDetailsService'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  getNotes,
  getOrderStatusText,
} from 'utils/fhirResoureHelpers/ipdTreatmentHelper'
import { getTherapiesForSelectedTime } from 'utils/fhirResoureHelpers/therapiesDetailHelper'
import { ConfirmationDialog } from 'views/components/common/confirmationDialog'
import { ConfirmationDialogProps } from 'views/components/common/deleteConfirmationDialog'
import { TherapiesSelector } from 'views/components/common/therapies_entry_selector'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { WelloFormItemLabel, WelloTextField } from 'wello-web-components'
import { CancelTherapy } from './cancelTherapy'
import { DeleteTherapy } from './deleteTherapy'
import { TherapyPerform } from './therapyDetails'

interface TherapiesCardOfIPDForPTProps {
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
  status: string
  isReadonly?: boolean
  id: string
}

export const TherapiesCardOfIPDForPT: React.FC<TherapiesCardOfIPDForPTProps> =
  ({
    fhirAppointmentDetails,
    status,
    isReadonly = false,
    id,
  }: TherapiesCardOfIPDForPTProps) => {
    let checkinDate = moment(
      fhirAppointmentDetails.mainServiceRequest.occurrencePeriod?.start
    ).format('YYYY-MM-DD')
    let checkOutDate = moment(
      fhirAppointmentDetails.mainServiceRequest.occurrencePeriod?.end
    ).format('YYYY-MM-DD')

    const endDateForCheck = moment(moment().format('YYYY-MM-DD'))
    const duration = moment.duration(endDateForCheck.diff(moment(checkinDate)))
    const days = duration.asDays()
    if (days >= 5) {
      const newDate = moment().add(1, 'days')
      const checkoutDateDurtation = moment.duration(
        moment(checkOutDate).diff(endDateForCheck)
      )
      const checkOutDays = checkoutDateDurtation.asDays()
      if (checkOutDays < 0) {
        checkOutDate = moment().format('YYYY-MM-DD')
      }
      const newCheckinDate = newDate.subtract(5, 'days')
      checkinDate = moment(newCheckinDate).format('YYYY-MM-DD')
    }

    const [value1, setValue1] = React.useState(new Date('2020-01-01 12:00'))

    const { t } = useTranslation(['common'])
    const dispatch = useDispatch()
    const [fullText, setFullText] = useState<string>('')
    const addtreatmentSlice = useSelector(
      (state: RootState) => state.addPhysioTherapiesSlice
    )

    const reason: R4.ICoding = {
      system: 'http://wellopathy.com/appointment-cancel-codes',
      code: 'prov-pers',
      display: 'Therapist Not Available',
    }

    const treatmentSearchSlice = useSelector(
      (state: RootState) => state.viewPhysioTherapiesOfEncounterSlice
    )

    const threapyCatalogSearchSlice = useSelector(
      (state: RootState) => state.threapyCatalogSearchSlice
    )
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)

    const handleClose = () => {
      setAnchorEl(null)
    }
    const [showText, setShowText] = useState<boolean>(false)
    const [plan, setPlan] = useState<TreatmentPlanData>()
    const selectedPlan = useRef<TreatmentPlanData>()
    const [showTherapyDetails, setShowTherapyDetails] = useState<boolean>(false)

    const [showDeleteConfirmation, setShowDeleteConfirmation] =
      useState<ConfirmationDialogProps>({
        open: false,
      })

    const [showCancelTherapyDetails, setShowCancelTherapyDetails] =
      useState<boolean>(false)
    const [showDeleteTherapyDetails, setShowDeleteTherapyDetails] =
      useState<boolean>(false)
    const [pricePopUp, setPricePricePopUp] = useState<boolean>(false)
    const [therapyName, setTherapyName] = useState<string>('')
    const [treatMent, setTreatMent] = useState<TherapyPerDay[]>([
      {
        id: getUniqueTempId(),
        date: moment(moment()).format('YYYY-MM-DD'),
        morning: {
          id: getUniqueTempId(),
          date: moment(moment()).format('YYYY-MM-DD'),
          startTime: '09:00',
          endTime: '12:30',
          start: new Date('2020-01-01 05:00'),
          end: new Date('2020-01-01 08:00'),
          machineTherapies: [],
          manualTherapies: [],
        },

        afterNoon: {
          id: getUniqueTempId(),
          date: moment(moment()).format('YYYY-MM-DD'),
          startTime: '14:30',
          endTime: '17:00',
          start: new Date('2020-01-01 12:00'),
          end: new Date('2020-01-01 18:00'),
          machineTherapies: [],
          manualTherapies: [],
        },
      },
    ])

    function handleDateChange(date: any, index: number) {
      const values: TherapyPerDay[] = [...treatMent]
      values[index].date = date.target.value
      setTreatMent(values)
    }

    function handleEarlyMorningTherapy(
      th: TherapyDetail[],
      index: number,
      isManual: boolean
    ) {
      const values: TherapyPerDay[] = [...treatMent]
      if (isManual) {
        values[index].morning.manualTherapies = th
      } else {
        values[index].morning.machineTherapies = th
      }

      setTreatMent(values)
    }

    function hasSelectedData() {
      let hasData = false
      if (
        treatMent[0].afterNoon.machineTherapies &&
        treatMent[0].afterNoon.machineTherapies.length > 0
      ) {
        hasData = true
      }

      if (
        treatMent[0].afterNoon.manualTherapies &&
        treatMent[0].afterNoon.manualTherapies.length > 0
      ) {
        hasData = true
      }
      if (
        treatMent[0].morning.manualTherapies &&
        treatMent[0].morning.manualTherapies.length > 0
      ) {
        hasData = true
      }
      if (
        treatMent[0].morning.machineTherapies &&
        treatMent[0].morning.machineTherapies.length > 0
      ) {
        hasData = true
      }
      return hasData
    }

    function handleAfterNoonTherapy(
      th: TherapyDetail[],
      index: number,
      isManual: boolean
    ) {
      const values: TherapyPerDay[] = [...treatMent]
      if (isManual) {
        values[index].afterNoon.manualTherapies = th
      } else {
        values[index].afterNoon.machineTherapies = th
      }

      setTreatMent(values)
    }

    function handleAfternoonStart(time: any, index: number) {
      const values: TherapyPerDay[] = [...treatMent]
      values[index].afterNoon.startTime = moment(time).format('HH:mm')
      values[index].afterNoon.start = time
      // values[index].afterNoon.startTime = time.target.value
      setTreatMent(values)
    }

    function handleAfternoonEnd(time: any, index: number) {
      const values: TherapyPerDay[] = [...treatMent]

      values[index].afterNoon.endTime = moment(time).format('HH:mm')
      values[index].afterNoon.end = time
      // values[index].afterNoon.endTime = time.target.value
      setTreatMent(values)
    }

    const handleAddMore = () => {
      const values: TherapyPerDay[] = [...treatMent]
      values.push({
        id: getUniqueTempId(),
        date: moment(moment()).format('DD-MM-YYYY'),
        morning: {
          id: getUniqueTempId(),
          date: moment(moment()).format('YYYY-MM-DD'),
          startTime: '09:00',
          endTime: '12:30',
          start: new Date('2020-01-01 09:00'),
          end: new Date('2020-01-01 12:30'),
          machineTherapies: [],
          manualTherapies: [],
        },

        afterNoon: {
          id: getUniqueTempId(),
          date: moment(moment()).format('YYYY-MM-DD'),
          startTime: '14:30',
          endTime: '17:00',
          start: new Date('2020-01-01 14:30'),
          end: new Date('2020-01-01 17:00'),
          machineTherapies: [],
          manualTherapies: [],
        },
      })
      setTreatMent(values)
    }

    function resetValues() {
      setTreatMent([
        {
          id: getUniqueTempId(),
          date: moment(moment()).format('YYYY-MM-DD'),
          morning: {
            id: getUniqueTempId(),
            date: checkinDate,
            startTime: '09:00',
            endTime: '12:30',
            start: new Date('2020-01-01 05:00'),
            end: new Date('2020-01-01 08:00'),
            machineTherapies: [],
            manualTherapies: [],
          },

          afterNoon: {
            id: getUniqueTempId(),
            date: moment(moment()).format('YYYY-MM-DD'),
            startTime: '14:30',
            endTime: '17:00',
            start: new Date('2020-01-01 12:00'),
            end: new Date('2020-01-01 18:00'),
            machineTherapies: [],
            manualTherapies: [],
          },
        },
      ])
    }

    useEffect(() => {
      if (addtreatmentSlice.additionSuccessful) {
        resetValues()

        dispatch(resetAddVitalsState())
      }
    }, [dispatch, addtreatmentSlice])

    useEffect(() => {
      dispatch(
        requestForTherapiesForIpd(fhirAppointmentDetails.mainServiceRequest.id!)
      )
    }, [dispatch])

    return (
      <Box style={{ paddingLeft: '4px' }} display='flex' flexDirection='row'>
        <Box width='100%'>
          <Grid container direction='column'>
            <Grid item>
              <ReactVisibilitySensor
                onChange={(isVisible) => {
                  if (isVisible) {
                    dispatch(
                      setSelectedSection({ selectedSection: 'diagnosis' })
                    )
                  }
                }}
              >
                <Grid container direction='column'>
                  <Grid item id='diagnosis'>
                    <Grid
                      container
                      direction='row'
                      style={{ padding: 0, margin: 0 }}
                    >
                      <Box
                        sx={{ width: '100%' }}
                        display='flex'
                        alignItems='center'
                        flexDirection='row'
                        bgcolor='#999999'
                        minHeight={45}
                      >
                        <Box p={2}>
                          <Typography
                            variant='body1'
                            style={{ color: 'white', fontWeight: 600 }}
                          >
                            {' '}
                            Therapies Plan
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid item xl>
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='flex-start'
                      width='100%'
                      border={4}
                      style={{
                        boxShadow: '0px 0px 4px #ccc',
                        margin: '2px 0px',
                        borderRadius: '1px',
                        borderColor: '#F8F8F8',
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#F8F8F8',
                      }}
                    >
                      <Grid container direction='row' spacing={1}>
                        <Grid
                          container
                          direction='row'
                          id='vitals'
                          style={{
                            backgroundColor: kDialogueBackground,
                          }}
                        >
                          <Grid item xs={1}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={0.5}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 10,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Date
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    minHeight: '30px',
                                    maxHeight: '100px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={2}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={0.5}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 10,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Therapy
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    minHeight: '30px',
                                    maxHeight: '100px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={1}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={0.5}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 10,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Start Time
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    minHeight: '30px',
                                    maxHeight: '100px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={1}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={0.5}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 10,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    End Time
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    minHeight: '30px',
                                    maxHeight: '100px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={1}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={0.5}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 10,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Actual Start
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    minHeight: '30px',
                                    maxHeight: '100px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={1}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={0.5}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 10,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Actual End
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    minHeight: '30px',
                                    maxHeight: '100px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={2}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={0.5}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 10,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Notes
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    minHeight: '30px',
                                    maxHeight: '100px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={1}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={0.5}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 10,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Performed By
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    minHeight: '30px',
                                    maxHeight: '100px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={1}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  justifyContent='flex-start'
                                  display='flex'
                                  flexGrow
                                  height={30}
                                  alignItems='center'
                                  px={0.5}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    // color='initial'
                                    style={{
                                      fontFamily: 'Open Sans',
                                      fontSize: 10,
                                      color: 'black',

                                      fontWeight: 'bold',
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    Status
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                width='2%'
                                justifyContent='flex-end'
                                flexGrow
                              >
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    minHeight: '30px',
                                    maxHeight: '100px',

                                    width: '2px',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={1}>
                            <Box
                              display='flex'
                              width='100%'
                              flexDirection='row'
                              justifyContent='flex-start'
                            >
                              <Typography
                                variant='body1'
                                color='primary'
                                style={{ fontWeight: 600 }}
                              >
                                {' '}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  {(treatmentSearchSlice.searchingTreatment ||
                    addtreatmentSlice.adding) && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='100%'
                      height='100%'
                      alignContent='center'
                    >
                      <Typography
                        variant='subtitle1'
                        color='initial'
                        align='center'
                      >
                        <CircularProgress size={25} />
                      </Typography>
                    </Box>
                  )}

                  {treatmentSearchSlice.errorWhileSearchingTreatment && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='100%'
                      height='100%'
                      alignContent='center'
                    >
                      <Typography
                        variant='subtitle1'
                        color='initial'
                        align='center'
                      >
                        Some Error has occured
                      </Typography>
                    </Box>
                  )}

                  {treatmentSearchSlice.noResultsAvailable && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='100%'
                      height='100%'
                      alignContent='center'
                    >
                      <Typography
                        variant='subtitle1'
                        color='initial'
                        align='center'
                      >
                        Treatment Plans not available
                      </Typography>
                    </Box>
                  )}

                  {treatmentSearchSlice.resultsAvailable &&
                    treatmentSearchSlice.treatmentList &&
                    treatmentSearchSlice.treatmentList.length === 0 && (
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        width='100%'
                        height='100%'
                        alignContent='center'
                      >
                        <Typography
                          variant='subtitle1'
                          color='initial'
                          align='center'
                        >
                          {isUnitAdmin() ||
                          fhirAppointmentDetails.mainServiceRequest.status ===
                            'completed'
                            ? 'No data available'
                            : 'Add Treatment Plan'}
                        </Typography>
                      </Box>
                    )}

                  {treatmentSearchSlice.resultsAvailable &&
                    treatmentSearchSlice.treatmentList &&
                    treatmentSearchSlice.treatmentList.length > 0 && (
                      <Grid item xl>
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='flex-start'
                          width='100%'
                          border={4}
                          style={{
                            boxShadow: '0px 0px 4px #ccc',
                            margin: '2px 0px',
                            borderRadius: '1px',
                            borderColor: '#F8F8F8',
                            width: '100%',
                            height: 'auto',
                            backgroundColor: '#F8F8F8',
                          }}
                        >
                          <Grid container direction='row' spacing={1}>
                            {treatmentSearchSlice.treatmentList.map(
                              (
                                treatmentData: TreatmentPlanData,
                                index: number
                              ) => (
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                  // style={{
                                  //   backgroundColor:
                                  //     index % 2 ? kDialogueBackground : 'white',
                                  // }}
                                >
                                  {treatmentData.treatmentPlan &&
                                    treatmentData.treatmentPlan.status !==
                                      'unknown' && (
                                      <Box
                                        width='100%'
                                        //   style={{
                                        //     backgroundColor:
                                        //       index % 2 ? kDialogueBackground : 'white',
                                        //   }}
                                      >
                                        <Grid
                                          container
                                          direction='row'
                                          id='vitals'
                                          style={{
                                            backgroundColor:
                                              index % 2
                                                ? kDialogueBackground
                                                : 'white',
                                          }}
                                        >
                                          <Grid item xs={1}>
                                            <Box
                                              display='flex'
                                              flexDirection='row'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                                flexGrow={1}
                                              >
                                                <Box
                                                  justifyContent='flex-start'
                                                  display='flex'
                                                  flexGrow
                                                  height={30}
                                                  alignItems='center'
                                                  px={0.5}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                    style={{
                                                      cursor: 'pointer',
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 10,

                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    {moment(
                                                      treatmentData.date
                                                    ).format('DD-MM-YYYY')}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                              <Box
                                                display='flex'
                                                width='2%'
                                                justifyContent='flex-end'
                                                flexGrow
                                              >
                                                <Divider
                                                  orientation='vertical'
                                                  flexItem
                                                  style={{
                                                    minHeight: '30px',
                                                    maxHeight: '100px',

                                                    width: '2px',
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Grid>
                                          <Grid item xs={2}>
                                            <Box
                                              display='flex'
                                              flexDirection='row'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                                flexGrow={1}
                                              >
                                                <Box
                                                  justifyContent='flex-start'
                                                  display='flex'
                                                  flexGrow
                                                  height={30}
                                                  alignItems='center'
                                                  px={0.5}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                    style={{
                                                      cursor: 'pointer',
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 10,

                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    {treatmentData.therapy}{' '}
                                                    {treatmentData.treatmentType
                                                      ? `- ${treatmentData.treatmentType}`
                                                      : ''}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                              <Box
                                                display='flex'
                                                width='2%'
                                                justifyContent='flex-end'
                                                flexGrow
                                              >
                                                <Divider
                                                  orientation='vertical'
                                                  flexItem
                                                  style={{
                                                    minHeight: '30px',
                                                    maxHeight: '100px',

                                                    width: '2px',
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Grid>

                                          <Grid item xs={1}>
                                            <Box
                                              display='flex'
                                              flexDirection='row'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                                flexGrow={1}
                                              >
                                                <Box
                                                  justifyContent='flex-start'
                                                  display='flex'
                                                  flexGrow
                                                  height={30}
                                                  alignItems='center'
                                                  px={0.5}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                    style={{
                                                      cursor: 'pointer',
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 10,

                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    {treatmentData.startTime}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                              <Box
                                                display='flex'
                                                width='2%'
                                                justifyContent='flex-end'
                                                flexGrow
                                              >
                                                <Divider
                                                  orientation='vertical'
                                                  flexItem
                                                  style={{
                                                    minHeight: '30px',
                                                    maxHeight: '100px',

                                                    width: '2px',
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Grid>

                                          <Grid item xs={1}>
                                            <Box
                                              display='flex'
                                              flexDirection='row'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                                flexGrow={1}
                                              >
                                                <Box
                                                  justifyContent='flex-start'
                                                  display='flex'
                                                  flexGrow
                                                  height={30}
                                                  alignItems='center'
                                                  px={0.5}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                    style={{
                                                      cursor: 'pointer',
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 10,

                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    {treatmentData.endTime}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                              <Box
                                                display='flex'
                                                width='2%'
                                                justifyContent='flex-end'
                                                flexGrow
                                              >
                                                <Divider
                                                  orientation='vertical'
                                                  flexItem
                                                  style={{
                                                    minHeight: '30px',
                                                    maxHeight: '100px',

                                                    width: '2px',
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Grid>

                                          <Grid item xs={1}>
                                            <Box
                                              display='flex'
                                              flexDirection='row'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                                flexGrow={1}
                                              >
                                                <Box
                                                  justifyContent='flex-start'
                                                  display='flex'
                                                  flexGrow
                                                  height={30}
                                                  alignItems='center'
                                                  px={0.5}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                    style={{
                                                      paddingLeft: 2,
                                                      cursor: 'pointer',
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 10,

                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    {treatmentData.actualStart &&
                                                    treatmentData.actualStart !==
                                                      'Invalid Date'
                                                      ? treatmentData.actualStart
                                                      : ''}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                              <Box
                                                display='flex'
                                                width='2%'
                                                justifyContent='flex-end'
                                                flexGrow
                                              >
                                                <Divider
                                                  orientation='vertical'
                                                  flexItem
                                                  style={{
                                                    minHeight: '30px',
                                                    maxHeight: '100px',

                                                    width: '2px',
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Grid>

                                          <Grid item xs={1}>
                                            <Box
                                              display='flex'
                                              flexDirection='row'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                                flexGrow={1}
                                              >
                                                <Box
                                                  justifyContent='flex-start'
                                                  display='flex'
                                                  flexGrow
                                                  height={30}
                                                  alignItems='center'
                                                  px={0.5}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                    style={{
                                                      paddingLeft: 2,
                                                      cursor: 'pointer',
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 10,

                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    {treatmentData.actualEnd &&
                                                    treatmentData.actualEnd !==
                                                      'Invalid Date'
                                                      ? treatmentData.actualEnd
                                                      : ''}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                              <Box
                                                display='flex'
                                                width='2%'
                                                justifyContent='flex-end'
                                                flexGrow
                                              >
                                                <Divider
                                                  orientation='vertical'
                                                  flexItem
                                                  style={{
                                                    minHeight: '30px',
                                                    maxHeight: '100px',

                                                    width: '2px',
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Grid>
                                          <Grid item xs={2}>
                                            <Box
                                              display='flex'
                                              flexDirection='row'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                                flexGrow={1}
                                              >
                                                <Box
                                                  justifyContent='flex-start'
                                                  display='flex'
                                                  flexGrow
                                                  height={30}
                                                  alignItems='center'
                                                  px={0.5}
                                                >
                                                  {treatmentData.treatmentPlan &&
                                                    treatmentData.treatmentPlan
                                                      .status === 'revoked' && (
                                                      <Typography
                                                        variant='subtitle2'
                                                        color='initial'
                                                        style={{
                                                          cursor: 'pointer',
                                                          fontFamily:
                                                            'Open Sans',
                                                          fontSize: 10,

                                                          lineHeight: 'normal',
                                                        }}
                                                      >
                                                        {' '}
                                                        {treatmentData
                                                          .treatmentPlan.note
                                                          ? treatmentData
                                                              .treatmentPlan
                                                              .note[0].text ??
                                                            ''
                                                          : treatmentData
                                                              .treatmentPlan
                                                              .reasonCode
                                                          ? treatmentData
                                                              .treatmentPlan
                                                              .reasonCode[0]
                                                            ? treatmentData
                                                                .treatmentPlan
                                                                .reasonCode[0]
                                                                .coding
                                                              ? treatmentData
                                                                  .treatmentPlan
                                                                  .reasonCode[0]
                                                                  .coding[0]
                                                                ? treatmentData
                                                                    .treatmentPlan
                                                                    .reasonCode[0]
                                                                    .coding[0]
                                                                    .display ??
                                                                  ''
                                                                : ''
                                                              : ''
                                                            : ''
                                                          : ''}
                                                      </Typography>
                                                    )}

                                                  {treatmentData.procedure && (
                                                    <Box>
                                                      <Typography
                                                        variant='body2'
                                                        color='initial'
                                                        style={{
                                                          cursor: 'pointer',
                                                          fontFamily:
                                                            'Open Sans',
                                                          fontSize: 10,

                                                          lineHeight: 'normal',
                                                        }}
                                                        onClick={() => {
                                                          if (
                                                            getNotes(
                                                              treatmentData.procedure!
                                                            ).length > 60
                                                          ) {
                                                            setFullText(
                                                              getNotes(
                                                                treatmentData.procedure!
                                                              )
                                                            )
                                                            setShowText(true)
                                                          }
                                                        }}
                                                      >
                                                        {getNotes(
                                                          treatmentData.procedure!
                                                        ).slice(0, 60)}
                                                        {getNotes(
                                                          treatmentData.procedure!
                                                        ).length > 60
                                                          ? '...'
                                                          : null}
                                                      </Typography>

                                                      {/* <Typography
                                                      variant='body1'
                                                      color='initial'
                                                      style={{
                                                        paddingLeft: 2,
                                                        cursor: 'pointer',
                                                      }}
                                                      //   component=''
                                                      onClick={() => {
                                                        if (
                                                          getNotes(
                                                            treatmentData.procedure
                                                          ).length > 60
                                                        ) {
                                                          setFullText(
                                                            ipdAppointmentDetails.notes
                                                          )
                                                          setShowText(true)
                                                        }
                                                      }}
                                                    >
                                                      {ipdAppointmentDetails.notes.slice(
                                                        0,
                                                        60
                                                      )}
                                                    </Typography> */}
                                                    </Box>
                                                  )}
                                                </Box>
                                              </Box>
                                              <Box
                                                display='flex'
                                                width='2%'
                                                justifyContent='flex-end'
                                                flexGrow
                                              >
                                                <Divider
                                                  orientation='vertical'
                                                  flexItem
                                                  style={{
                                                    minHeight: '30px',
                                                    maxHeight: '100px',

                                                    width: '2px',
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Grid>

                                          <Grid item xs={1}>
                                            <Box
                                              display='flex'
                                              flexDirection='row'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                                flexGrow={1}
                                              >
                                                <Box
                                                  justifyContent='flex-start'
                                                  display='flex'
                                                  flexGrow
                                                  height={30}
                                                  alignItems='center'
                                                  px={0.5}
                                                >
                                                  {treatmentData.treatmentPlan &&
                                                    treatmentData.treatmentPlan
                                                      .status === 'revoked' && (
                                                      <Typography
                                                        variant='subtitle2'
                                                        color='initial'
                                                        style={{
                                                          fontFamily:
                                                            'Open Sans',
                                                          fontSize: 10,

                                                          fontWeight: 600,
                                                          lineHeight: 'normal',
                                                        }}
                                                      >
                                                        {treatmentData
                                                          .treatmentPlan
                                                          .performer
                                                          ? treatmentData
                                                              .treatmentPlan
                                                              .performer[0]
                                                              .display ?? ''
                                                          : ''}
                                                      </Typography>
                                                    )}
                                                  {treatmentData.procedure && (
                                                    <Typography
                                                      variant='subtitle1'
                                                      color='initial'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 10,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {' '}
                                                      {treatmentData.procedure.performer
                                                        ?.map(
                                                          (e) =>
                                                            e.actor.display ??
                                                            ''
                                                        )
                                                        .join(', ')}
                                                    </Typography>
                                                  )}
                                                </Box>
                                              </Box>
                                              <Box
                                                display='flex'
                                                width='2%'
                                                justifyContent='flex-end'
                                                flexGrow
                                              >
                                                <Divider
                                                  orientation='vertical'
                                                  flexItem
                                                  style={{
                                                    minHeight: '30px',
                                                    maxHeight: '100px',

                                                    width: '2px',
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Grid>

                                          <Grid item xs={1}>
                                            <Box
                                              display='flex'
                                              flexDirection='row'
                                              width='100%'
                                              flexGrow={1}
                                            >
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                                flexGrow={1}
                                              >
                                                <Box
                                                  justifyContent='flex-start'
                                                  display='flex'
                                                  flexGrow
                                                  height={30}
                                                  alignItems='center'
                                                  paddingLeft={0.4}
                                                  paddingRight={0.7}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color={
                                                      (treatmentData.treatmentPlan &&
                                                        treatmentData
                                                          .treatmentPlan
                                                          .status ===
                                                          'active') ||
                                                      (treatmentData.treatmentPlan &&
                                                        treatmentData
                                                          .treatmentPlan
                                                          .status ===
                                                          'completed')
                                                        ? 'primary'
                                                        : 'error'
                                                    }
                                                    style={{
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 10,

                                                      fontWeight: 600,
                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    {treatmentData.procedure ===
                                                      undefined && (
                                                      <Typography
                                                        variant='body2'
                                                        color={
                                                          (treatmentData.treatmentPlan &&
                                                            treatmentData
                                                              .treatmentPlan
                                                              .status ===
                                                              'active') ||
                                                          (treatmentData.treatmentPlan &&
                                                            treatmentData
                                                              .treatmentPlan
                                                              .status ===
                                                              'completed') ||
                                                          treatmentData.procedure
                                                            ? 'primary'
                                                            : 'error'
                                                        }
                                                        style={{
                                                          fontFamily:
                                                            'Open Sans',
                                                          fontSize: 10,

                                                          fontWeight: 600,
                                                          lineHeight: 'normal',
                                                        }}
                                                      >
                                                        {' '}
                                                        {treatmentData.treatmentPlan &&
                                                          treatmentData.procedure ===
                                                            undefined &&
                                                          getOrderStatusText(
                                                            treatmentData.treatmentPlan
                                                          )}
                                                      </Typography>
                                                    )}

                                                    {treatmentData.procedure && (
                                                      <Typography
                                                        variant='body2'
                                                        color='primary'
                                                        style={{
                                                          fontFamily:
                                                            'Open Sans',
                                                          fontSize: 10,

                                                          fontWeight: 600,
                                                          lineHeight: 'normal',
                                                        }}
                                                      >
                                                        {' '}
                                                        Completed
                                                      </Typography>
                                                    )}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                              <Box
                                                display='flex'
                                                width='2%'
                                                justifyContent='flex-end'
                                                flexGrow
                                              >
                                                <Divider
                                                  orientation='vertical'
                                                  flexItem
                                                  style={{
                                                    minHeight: '30px',
                                                    maxHeight: '100px',

                                                    width: '2px',
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Grid>

                                          {treatmentData.procedure ===
                                            undefined &&
                                            treatmentData.treatmentPlan &&
                                            treatmentData.treatmentPlan
                                              .status !== 'revoked' && (
                                              <Grid item xs={1}>
                                                <Box
                                                  display='flex'
                                                  width='100%'
                                                  flexDirection='row'
                                                  justifyContent='center'
                                                >
                                                  <Button
                                                    id='popup-button'
                                                    style={{ padding: 0 }}
                                                    aria-controls={
                                                      open
                                                        ? 'fade-menu'
                                                        : undefined
                                                    }
                                                    aria-haspopup='true'
                                                    aria-expanded={
                                                      open ? 'true' : undefined
                                                    }
                                                    onClick={(event: any) => {
                                                      setAnchorEl(
                                                        event.currentTarget
                                                      )
                                                    }}
                                                  >
                                                    <IconButton
                                                      style={{ padding: 0 }}
                                                      color='primary'
                                                      id='long-button'
                                                      onClick={() => {
                                                        setPlan(treatmentData)
                                                        selectedPlan.current =
                                                          treatmentData
                                                      }}
                                                    >
                                                      <MoreVert />
                                                    </IconButton>
                                                  </Button>
                                                  <Menu
                                                    id='pt_menu'
                                                    MenuListProps={{
                                                      'aria-labelledby':
                                                        'fade-button',
                                                    }}
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    TransitionComponent={Fade}
                                                  >
                                                    <MenuItem
                                                      id='complete_button'
                                                      onClick={() => {
                                                        setShowTherapyDetails(
                                                          true
                                                        )
                                                        setAnchorEl(null)
                                                      }}
                                                    >
                                                      Mark as Complete
                                                    </MenuItem>
                                                    <MenuItem
                                                      id='cancel_button'
                                                      onClick={() => {
                                                        setShowCancelTherapyDetails(
                                                          true
                                                        )
                                                        setAnchorEl(null)
                                                      }}
                                                    >
                                                      Cancel Therapy
                                                    </MenuItem>

                                                    <MenuItem
                                                      id='delete_button'
                                                      onClick={() => {
                                                        setShowDeleteTherapyDetails(
                                                          true
                                                        )
                                                        setAnchorEl(null)
                                                      }}
                                                    >
                                                      Delete Therapy
                                                    </MenuItem>
                                                  </Menu>
                                                </Box>
                                              </Grid>
                                            )}
                                        </Grid>
                                        <Grid
                                          container
                                          direction='row'
                                          id='vitals'
                                        >
                                          <Grid item xs={12}>
                                            <Box width='100%' paddingLeft={0.2}>
                                              <Divider
                                                orientation='horizontal'
                                                flexItem
                                                style={{
                                                  height: '2px',
                                                }}
                                              />
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    )}
                                </Box>
                              )
                            )}
                          </Grid>
                        </Box>
                      </Grid>
                    )}
                  {!isUnitAdmin() && status !== 'completed' && (
                    <Grid item xs={12}>
                      <Box display='flex' flexDirection='column'>
                        {treatMent.map(
                          (treatmentRow: TherapyPerDay, index: number) => (
                            <Box
                              display='flex'
                              flexDirection='column'
                              alignItems='flex-start'
                              key={`unit_actor_small_details_${treatmentRow.morning.id}`}
                            >
                              <Box px={0.5} width='20%'>
                                <WelloTextField
                                  title='Date'
                                  textProps={{
                                    id: `treatmentDate`,
                                    value: treatmentRow.date,
                                    inputProps: {
                                      min: checkinDate,
                                      max: checkOutDate,
                                    },

                                    type: 'date',
                                    onChange: (changeData) => {
                                      handleDateChange(changeData, index)
                                    },
                                  }}
                                />
                              </Box>

                              <Box
                                sx={{ width: '30%' }}
                                display='flex'
                                alignItems='center'
                                flexDirection='row'
                              >
                                <Box px={4} py={1}>
                                  <Typography
                                    variant='body1'
                                    color='primary'
                                    style={{ fontWeight: 600 }}
                                  >
                                    {' '}
                                    Morning (9 AM - 12:30 PM)
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                width='100%'
                                display='flex'
                                flexDirection='row'
                              >
                                <Box px={4} width='40%'>
                                  <WelloFormItemLabel title='Manual Therapy' />
                                  <TherapiesSelector
                                    id='morning_manual'
                                    //   isInEditMode={
                                    //     !(
                                    //       isCurrentDate(treatmentRow.date) &&
                                    //       !isBeforeTime('12:30')
                                    //     )
                                    //   }
                                    isInEditMode={true}
                                    preSelectedCodes={getTherapiesForSelectedTime(
                                      treatmentSearchSlice.treatmentList ?? [],
                                      treatmentRow.date,
                                      '09:00 AM'
                                    )}
                                    placeHolderString='Select Items'
                                    onRadioOptionsChanged={(a) => {
                                      handleEarlyMorningTherapy(a, index, true)
                                    }}
                                    searchType='manualTherapiesByPT'
                                  />
                                </Box>

                                <Box px={4} width='40%'>
                                  <WelloFormItemLabel title='Machine Therapy' />
                                  <TherapiesSelector
                                    id='morning_machine'
                                    //   isInEditMode={
                                    //     !(
                                    //       isCurrentDate(treatmentRow.date) &&
                                    //       !isBeforeTime('12:30')
                                    //     )
                                    //   }
                                    isInEditMode={true}
                                    placeHolderString='Select Items'
                                    onRadioOptionsChanged={(a) => {
                                      handleEarlyMorningTherapy(a, index, false)
                                    }}
                                    preSelectedCodes={getTherapiesForSelectedTime(
                                      treatmentSearchSlice.treatmentList ?? [],
                                      treatmentRow.date,
                                      '09:00 AM'
                                    )}
                                    searchType='machineTherapiesByPT'
                                  />
                                </Box>
                              </Box>

                              <Box
                                sx={{ width: '30%' }}
                                display='flex'
                                alignItems='center'
                                flexDirection='row'
                              >
                                <Box px={4} py={1}>
                                  <Typography
                                    variant='body1'
                                    color='primary'
                                    style={{ fontWeight: 600 }}
                                  >
                                    {' '}
                                    Afternoon (2:30 PM - 5 PM)
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                width='100%'
                                display='flex'
                                flexDirection='row'
                              >
                                <Box px={4} width='40%'>
                                  <WelloFormItemLabel title='Manual Therapy' />
                                  <TherapiesSelector
                                    id='afternoon_manual'
                                    //   isInEditMode={
                                    //     !(
                                    //       isCurrentDate(treatmentRow.date) &&
                                    //       !isBeforeTime('17:00')
                                    //     ) || !addtreatmentSlice.adding
                                    //   }
                                    isInEditMode={true}
                                    placeHolderString='Select Items'
                                    onRadioOptionsChanged={(a) => {
                                      handleAfterNoonTherapy(a, index, true)
                                    }}
                                    preSelectedCodes={getTherapiesForSelectedTime(
                                      treatmentSearchSlice.treatmentList ?? [],
                                      treatmentRow.date,
                                      '02:30 PM'
                                    )}
                                    searchType='manualTherapiesByPT'
                                  />
                                </Box>
                                <Box px={4} width='40%'>
                                  <WelloFormItemLabel title='Machine Therapy' />
                                  <TherapiesSelector
                                    id='afternoon_machine'
                                    //   isInEditMode={
                                    //     !(
                                    //       isCurrentDate(treatmentRow.date) &&
                                    //       !isBeforeTime('17:00')
                                    //     ) || !addtreatmentSlice.adding
                                    //   }
                                    isInEditMode={true}
                                    placeHolderString='Select Items'
                                    onRadioOptionsChanged={(a) => {
                                      handleAfterNoonTherapy(a, index, false)
                                    }}
                                    preSelectedCodes={getTherapiesForSelectedTime(
                                      treatmentSearchSlice.treatmentList ?? [],
                                      treatmentRow.date,
                                      '02:30 PM'
                                    )}
                                    searchType='machineTherapiesByPT'
                                  />
                                </Box>
                              </Box>
                            </Box>
                          )
                        )}
                        <Box
                          display='flex'
                          alignItems='center'
                          flexDirection='row'
                          paddingTop={1}
                        >
                          <Box>
                            <Button
                              variant='contained'
                              size='small'
                              color='primary'
                              id='treatment_save'
                              disabled={
                                !hasSelectedData() || addtreatmentSlice.adding
                              }
                              onClick={() => {
                                dispatch(
                                  addPhysioTherapiesScheduleDetails(
                                    treatMent,
                                    fhirAppointmentDetails
                                  )
                                )
                              }}
                            >
                              <Typography variant='subtitle2'> Save</Typography>
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </ReactVisibilitySensor>
            </Grid>
          </Grid>
        </Box>

        <NoteDialog
          id='treatment_notes_therapist'
          open={showText}
          onClose={() => {
            setFullText('')
            setShowText(false)
          }}
          notesData={fullText!}
          dialogText='Notes'
        />
        <ConfirmationDialog
          open={pricePopUp}
          displayText={therapyName}
          title='Therapy Info'
          doneButtonText='Yes'
          cancelButtonText='No'
          onClose={() => {
            setPricePricePopUp(false)
          }}
          onDiscard={() => {
            setPricePricePopUp(false)
          }}
        />
        {selectedPlan.current && (
          <TherapyPerform
            open={showTherapyDetails}
            onLabOrderCreated={() => {
              selectedPlan.current = undefined
              setAnchorEl(null)
              dispatch(resetProdcedureState())
              setPlan(undefined)
              setShowTherapyDetails(false)
            }}
            onClose={() => {
              setAnchorEl(null)
              selectedPlan.current = undefined
              setShowTherapyDetails(false)
              setPlan(undefined)
            }}
            fhirAppointmentDetails={fhirAppointmentDetails}
            treatmentPlanData={selectedPlan.current!}
          />
        )}

        <CancelTherapy
          open={showCancelTherapyDetails}
          onLabOrderCreated={() => {
            dispatch(
              requestForTherapiesForIpd(
                fhirAppointmentDetails.mainServiceRequest.id!
              )
            )
            setPlan(undefined)
            setShowCancelTherapyDetails(false)
          }}
          onClose={() => {
            setShowCancelTherapyDetails(false)
            selectedPlan.current = undefined

            setPlan(undefined)
          }}
          fhirAppointmentDetails={fhirAppointmentDetails}
          treatmentPlanData={plan}
        />

        <DeleteTherapy
          open={showDeleteTherapyDetails}
          onLabOrderCreated={() => {
            dispatch(
              requestForTherapiesForIpd(
                fhirAppointmentDetails.mainServiceRequest.id!
              )
            )
            setPlan(undefined)
            setShowDeleteTherapyDetails(false)
            // setShowCancelTherapyDetails(false)
          }}
          onClose={() => {
            setShowDeleteTherapyDetails(false)
            selectedPlan.current = undefined

            setPlan(undefined)
          }}
          fhirAppointmentDetails={fhirAppointmentDetails}
          treatmentPlanData={plan}
        />
      </Box>
    )
  }
