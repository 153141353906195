import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from 'redux/store'
import { EbmClinet } from 'services/ebmServiceClient'
import { getGraphData } from 'utils/graphData_handle/graphDataHelper'
import { logger } from 'utils/logger'
import { GraphDataSearchStatus } from './graphDataSearchStatus'

const initialState: GraphDataSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const graphDataSearchSlice = createSlice({
  name: 'graph',
  initialState,
  reducers: {
    searchingGraphData(state, action: PayloadAction<GraphDataSearchStatus>) {},

    searchResults(state, action: PayloadAction<GraphDataSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.grapDataSet = action.payload.grapDataSet
    },

    noDataFoundForSearch(state, action: PayloadAction<GraphDataSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.grapDataSet = action.payload.grapDataSet
    },

    errorWhileSearching(state, action: PayloadAction<GraphDataSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.grapDataSet = action.payload.grapDataSet
    },
    resetState(state, action: PayloadAction<GraphDataSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.grapDataSet = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: GraphDataSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      grapDataSet: undefined,
    }
    dispatch(graphDataSearchSlice.actions.resetState(state))
  }

export const searchingGraphData =
  (patientId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: GraphDataSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      graphDataSearchSlice.actions.errorWhileSearching(errorSearchPatient)
    )
    try {
      const fhirClient: EbmClinet = new EbmClinet()
      const response: any = await fhirClient.doGetResource(
        `patient/measurements?patientId=${patientId}`
      )

      if (response.measurements.length > 0) {
        const graphData = getGraphData(response)

        const searchPatientResult: GraphDataSearchStatus = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          grapDataSet: graphData,
        }
        dispatch(
          graphDataSearchSlice.actions.searchResults(searchPatientResult)
        )
        return
      }

      const noSearchResults: GraphDataSearchStatus = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(
        graphDataSearchSlice.actions.noDataFoundForSearch(noSearchResults)
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: GraphDataSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        graphDataSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(graphDataSearchSlice.actions.resetState(initialState))
}

export default graphDataSearchSlice.reducer
